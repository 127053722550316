export const ERROR_EMAIL_MESSAGE = {
  EMPTY_EMAIL: 'Please provide a valid email address.',
  INVALID_EMAIL: 'Invalid email.',
};

export const ERROR_PHONE_MESSAGE = {
  INVALID_MOBILE_PHONE: 'Please provide a valid mobile number.',
};

export const ERROR_OKTA = 'Okta error';
