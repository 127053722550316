import template from './lmiLenderMortgage.html';
import './style.scss';

export default {
  template,
  bindings: {
    mortgageObj: '=',
    brokerEventId: '<',
    calculatorOptions: '<',
    onCapitalizeLmiChange: '&',
    parentToChildNotificationRegistration: '&',
    showLmiFromAirtable: '<',
  },
  controller: 'LmiLenderMortgageCtrl',
  controllerAs: 'vm',
};
