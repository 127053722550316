import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('InsAppFinancialAssetsCtrl', function InsAppFinancialAssetsCtrl(
      $rootScope,
      $scope,
      $timeout,
      $interval,
      $compile,
      contactService,
      loanScenarioService,
      loanApplicationServices,
      toaster,
      $state,
      $stateParams,
      utilitiesService,
      $uibModal,
    ) {
      $scope.loanAppId = $stateParams.loanAppId;

      /** Assets code below */
      $scope.assetsInit = function () {
        $scope.listAssets = [];

        $scope.assetTypes = [
          { Value: 'Boat', Group: 'Assets', Desc: 'Boat' },
          {
            Value: 'Business Equity',
            Group: 'Assets',
            Desc: 'Business Equity',
          },
          { Value: 'Cash', Group: 'Assets', Desc: 'Cash' },
          { Value: 'Collections', Group: 'Assets', Desc: 'Collections' },
          { Value: 'Home Contents', Group: 'Assets', Desc: 'Home Contents' },
          { Value: 'Life Insurance', Group: 'Assets', Desc: 'Life Insurance' },
          { Value: 'Managed Funds', Group: 'Assets', Desc: 'Managed Funds' },
          { Value: 'Shares', Group: 'Assets', Desc: 'Shares' },
          {
            Value: 'Stock and Machinery',
            Group: 'Assets',
            Desc: 'Stock and Machinery',
          },
          { Value: 'Superannuation', Group: 'Assets', Desc: 'Superannuation' },
          { Value: 'Tools of Trade', Group: 'Assets', Desc: 'Tools of Trade' },

          {
            Value: 'Cash Management Account',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            Group: 'Bank Accounts',
            Desc: 'Cash Management Account',
          },
          {
            Value: 'Cheque Account',
            Group: 'Bank Accounts',
            Desc: 'Cheque Account',
          },
          {
            Value: 'Investment Savings',
            Group: 'Bank Accounts',
            Desc: 'Investment Savings',
          },
          {
            Value: 'Pension Account',
            Group: 'Bank Accounts',
            Desc: 'Pension Account',
          },
          {
            Value: 'Savings Account',
            Group: 'Bank Accounts',
            Desc: 'Savings Account',
          },
          {
            Value: 'Term Deposit - Other',
            Group: 'Bank Accounts',
            Desc: 'Term Deposit - Other',
          },
          {
            Value: 'Term Deposit - WBC',
            Group: 'Bank Accounts',
            Desc: 'Term Deposit - WBC',
          },

          { Value: 'Motor Vehicle', Group: 'Vehicle', Desc: 'Motor Vehicle' },

          {
            Value: 'Real State',
            Group: 'Real State Assets',
            Desc: 'Real State',
          },
        ];

        // Temporary / should call get api
        $scope.listAssets = [
          {
            Type: $scope.assetTypes[3],
            OwnershipInitials: utilitiesService.filterInitial('Test', 'Client'),
            // eslint-disable-next-line sonarjs/no-duplicate-string
            Ownership: 'Stanley Massey',
            Value: 120000,
          },
          {
            Type: $scope.assetTypes[4],
            OwnershipInitials: utilitiesService.filterInitial('Test', 'Client'),
            Ownership: 'Stanley Massey',
            Value: 80000,
          },
        ];

        $scope.displayAssetsForm = false;
      };
      $scope.assetsInit();

      $scope.delAsset = function (asset) {
        const idx = $scope.listAssets.indexOf(asset);
        if (idx > -1) {
          $scope.listAssets.splice(idx, 1);
        }
      };

      $scope.getNewAssetModel = function () {
        return {
          Type: '',
          OwnershipInitials: utilitiesService.filterInitial('Test', 'Client'),
          Ownership: 'Stanley Massey',
          Value: null,
        };
      };

      $scope.resetAssetModel = function () {
        $scope.AssetsSet = $scope.getNewAssetModel();
      };

      $scope.changeAsset = function () {
        if (
          _.size($scope.AssetsSet.Type) > 0 &&
          $scope.AssetsSet.Type.Group !== 'Assets'
        ) {
          $scope.newAssetsModal();
        }
      };

      // Save asset inline
      $scope.addAsset = function () {
        $scope.listAssets.push($scope.AssetsSet);
        $scope.resetAssetModel();
      };

      // Add Assets Modal
      $scope.newAssetsModal = function (/* type */) {
        $scope.displayAssetsForm = false;
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/loanApplication/clientAssess/modal/asset.add.html',
          controller: 'FinPosAssetsModalCtrl',
          size: 'sm',
          scope: $scope,
        });

        modalInstance.result.then(
          () => {
            $scope.addAsset();
            $scope.displayAssetsForm = false;
          },
          () => {
            $scope.addAsset();
            $scope.displayAssetsForm = false;
          },
        );
      };

      $scope.modalType = false;

      //* ************************************/
      //  owners multi Select                /
      //* ************************************/
      $scope.localLang = {
        selectAll: '<span>Select All</span>',
        selectNone: '<span>Deselect All </span>',
        reset: "<i class='fa fa-refresh'></i>",
        search: 'Search',
        nothingSelected: "<div class='buttonLabel'>All Owners</div>", // default-label is deprecated and replaced with this.
      };
      $scope.ownersList = [];
      $scope.ownersListSelected = [];

      // eslint-disable-next-line unicorn/consistent-function-scoping
      function isOtherTicked(data) {
        let bool = false;
        data.forEach((val) => {
          if (val.ClientEntityId > -1 && val.ticked) {
            bool = true;
          }
        });

        return bool;
      }
      $scope.onItemSelect = function (data) {
        //
        if (parseInt(data.ClientEntityId, 10) === -1) {
          for (let i = 0; i < $scope.ownersList.length; i++) {
            if ($scope.ownersList[i].ClientEntityId > -1) {
              $scope.ownersList[i].ticked = false;
            } else {
              $scope.ownersList[i].ticked = true;
            }
          }
        } else {
          for (let i = 0; i < $scope.ownersList.length; i++) {
            if (
              parseInt($scope.ownersList[i].ClientEntityId, 10) === -1 &&
              !isOtherTicked($scope.ownersList)
            ) {
              $scope.ownersList[i].ticked = true;
              $scope.ownersListSelected.push($scope.ownersList[i]);
            } else if (
              parseInt($scope.ownersList[i].ClientEntityId, 10) === -1
            ) {
              $scope.ownersList[i].ticked = false;
            }
          }
        }
      };

      $scope.ownersListSelectedArray = [];

      $scope.onSelectAll = function () {
        for (let i = 0; i < $scope.ownersList.length; i++) {
          if ($scope.ownersList[i].ClientEntityId > -1) {
            $scope.ownersList[i].ticked = false;
          } else {
            $scope.ownersList[i].ticked = true;
          }
        }
      };
    });
