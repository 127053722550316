const DISABLED_FOR_AU = 'Disabled for Australia';

export default class LoanSubmissionUIService {
  constructor(configService, currentUserService, loanAppSharedData) {
    'ngInject';

    this.configService = configService;
    this.currentUserService = currentUserService;
    this.loanAppSharedData = loanAppSharedData;
  }

  useComponentLenderSelection() {
    const { currentUserService, loanAppSharedData } = this;
    const defaultLogic = currentUserService.isNZ;
    const isUseAssetFinanceLogic = loanAppSharedData.isAssetFinanceLoan;
    return isUseAssetFinanceLogic || defaultLogic;
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  hideLenderBranch() {
    const { currentUserService, loanAppSharedData } = this;
    const defaultLogic = currentUserService.isNZ;
    const isUseAssetFinanceLogic = loanAppSharedData.isAssetFinanceLoan;
    return isUseAssetFinanceLogic || defaultLogic;
  }

  showLenderWorksheet() {
    const { currentUserService, loanAppSharedData } = this;
    const defaultLogic = !currentUserService.isAU;
    const isUseAssetFinanceLogic = loanAppSharedData.isAssetFinanceLoan;
    return isUseAssetFinanceLogic ? false : defaultLogic;
  }

  getSubmissionTitle() {
    const { currentUserService, loanAppSharedData } = this;
    const emailTitle = 'Email';
    const defaultTitle = currentUserService.isAU ? DISABLED_FOR_AU : emailTitle;
    const isUseAssetFinanceLogic = loanAppSharedData.isAssetFinanceLoan;
    return isUseAssetFinanceLogic ? emailTitle : defaultTitle;
  }

  getPrintTitle() {
    const { currentUserService, loanAppSharedData } = this;
    const printTitle = 'Print / Other';
    const defaultTitle = currentUserService.isAU ? DISABLED_FOR_AU : printTitle;
    const isUseAssetFinanceLogic = loanAppSharedData.isAssetFinanceLoan;
    return isUseAssetFinanceLogic ? printTitle : defaultTitle;
  }

  hideBrokerNotePurpose(questionContent) {
    const { currentUserService, loanAppSharedData } = this;
    const isPurposeQuestion = questionContent === 'Purpose';
    const defaultLogic = isPurposeQuestion && currentUserService.isID;
    const isUseAssetFinanceLogic = loanAppSharedData.isAssetFinanceLoan;
    return (isUseAssetFinanceLogic && isPurposeQuestion) || defaultLogic;
  }

  showCopyToClient(submissionMethod) {
    const { currentUserService, loanAppSharedData } = this;
    const isEmail = submissionMethod === 'email';
    const defaultLogic = !currentUserService.isAU && isEmail;
    const isUseAssetFinanceLogic = loanAppSharedData.isAssetFinanceLoan;
    return (isUseAssetFinanceLogic && isEmail) || defaultLogic;
  }

  showEmailToLender(submissionMethod) {
    const { currentUserService, loanAppSharedData } = this;
    const isEmailOrPrint =
      submissionMethod === 'email' || submissionMethod === 'print';
    const defaultLogic = isEmailOrPrint || currentUserService.isNZ;
    const isUseAssetFinanceLogic = loanAppSharedData.isAssetFinanceLoan;
    return isUseAssetFinanceLogic ? false : defaultLogic;
  }

  showAssetFinanceSubmission(submissionMethod) {
    const isEmailOrPrint =
      submissionMethod === 'email' || submissionMethod === 'print';
    const isUseAssetFinanceLogic = this.loanAppSharedData.isAssetFinanceLoan;
    return isUseAssetFinanceLogic && isEmailOrPrint;
  }

  isSubmissionEmailOrPrint() {
    const { currentUserService, loanAppSharedData } = this;
    return currentUserService.isAU || loanAppSharedData.isAssetFinanceLoan;
  }

  getEmailMessage() {
    const { loanAppSharedData } = this;
    const isAssetFinance = loanAppSharedData.isAssetFinanceLoan;
    const lenderWorkSheet = `Lender Worksheet Spreadsheet (Excel Spreadsheet)<br/>`;
    const documents = `Diary Note / Checklist / Application Form / Supporting Documents (PDF document)<br/>`;

    const originalContent = `
    <p>
      1) ${lenderWorkSheet}
      2) ${documents}
    </p>`;

    const assetFinanceContent = `
    <p>
      1) ${documents}
    </p>`;

    return `<p>Please find attached:</p>
    ${isAssetFinance ? assetFinanceContent : originalContent}
    <p>Please refer to the checklist for included items in supporting documents.</p>`;
  }

  isAcceptingEmail(isAcceptingEmailSubmission) {
    const { isAU } = this.currentUserService;
    const { isAssetFinanceLoan } = this.loanAppSharedData;
    const defaultLogic = !isAU;
    return isAssetFinanceLoan ? isAcceptingEmailSubmission : defaultLogic;
  }

  isValidLenderWorkSheet({ $scope }) {
    const { isAssetFinanceLoan } = this.loanAppSharedData;
    const defaultLogic =
      !$scope.isMainLender($scope.loanAppSharedData.lenderId) ||
      $scope.LenderSubmissionSet.isGenerateWorksheet === true ||
      ($scope.LenderSubmissionSet.isGenerateWorksheet === false &&
        $scope.loanAppSharedData.documentListLenderWorksheet.length > 0);
    return isAssetFinanceLoan || defaultLogic;
  }
}
