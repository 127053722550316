export function aggregatorBuilderForUI(aggregator = {}) {
  return {
    id: aggregator.ID || 0,
    companyNumber: aggregator.CompanyNumber || '',
    corporateNumber: aggregator.CorporateNumber || '',
    licenseNumber: aggregator.LicenceNumber || '',
    name: aggregator.Name || '',
    telephoneNumber: aggregator.TelephoneNumber || '',
  };
}
