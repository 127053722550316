import angular from 'angular';
import { toastError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    .controller('AddDocumentsModalCtrl', function AddDocumentsModalCtrl(
      $scope,
      $uibModalInstance,
      lenderList,
      selectedLender,
      selectedKeyContact,
      documentThumbnail,
      docID,
      docName,
      dashboardService,
      loanToolsService,
      $rootScope,
      toaster,
    ) {
      if (typeof docName === 'string' && docName) {
        $scope.title = docName.replace(/\.[^./]+$/, '');
      }
      $scope.documentThumbnail = documentThumbnail;
      const providerID = selectedLender.ProviderID;
      const lender = selectedLender;
      dashboardService.getBrokerBasicInfo().then((response) => {
        $scope.brokerId = response.data.BrokerId;
      });
      $scope.saveDocument = function (docu) {
        const title = docu;
        $scope.document = {
          title,
          ID: 0,
          providerID,
          brokerID: $scope.brokerId,
          fIleName: docName,
          documentID: docID,
        };
        loanToolsService
          .LoanProviderDocumentsSlimSet($scope.document)
          .then(() => {
            $uibModalInstance.dismiss('cancel');
            $rootScope.$broadcast('refreshIdentificationGrid', lender);

            toaster.pop(
              'success',
              'Added',
              'Document has been uploaded successfully.',
            );
          })
          .catch(toastError);
      };
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
    });
