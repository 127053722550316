import template from './loanSecurityDetails.html';
export default {
  template,
  controllerAs: 'vm',
  bindings: {
    loanDetails: '<',
    lendingDetailScope: '<',
    loanDetailScope: '<',
  },
};
