import get from 'lodash/get';
import {
  PIPELINE_STATUS,
  CONVERSION_PIPELINE_OPTIONS,
} from 'Common/constants/pipelineStatus';

export default class StatusDropdownLeadsCtrl {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;
  }

  $onInit() {
    this.PIPELINE_STATUS = PIPELINE_STATUS;
    this.LEADS_CONVERSION = CONVERSION_PIPELINE_OPTIONS.LEADS;
    const { showLendingTools } = this.uiService;
    this.showLendingTools = showLendingTools;
  }

  $onChanges(changes) {
    const isValid =
      changes.pipelineItem &&
      !get(changes, 'pipelineItem.currentValue.subStatusList.length') &&
      this.pipelineItem &&
      this.onReady;
    if (isValid) {
      this.onReady({ pipelineItem: this.pipelineItem });
    }
  }
}
