export const getTitle = ($scope, rateType) =>
  `${rateType} ${$scope.isRates || $scope.isDiscountRates ? 'Rate' : 'Fee'}`;

export const getShortTitle = ($scope) =>
  ($scope.isRates && 'Rate') || ($scope.isDiscountRates && 'Discount') || 'Fee';

export const toLower = (s) => s.toLowerCase();

export const update = (
  $scope,
  rateType,
  isRates,
  isDiscountRates,
  isFees,
  discountOrFeeType,
  save,
  $timeout,
) => () => {
  const {
    LoanStructureID: loanStructureId,
    ProductID: productFavouriteId,
    TempNewValue: newValue,
    TempNewDiscountYear: newYear,
  } = $scope.selectedProduct;

  if (isRates) {
    save(loanStructureId, productFavouriteId, `${rateType}Rate`, newValue);
  } else if (isDiscountRates) {
    save(
      loanStructureId,
      productFavouriteId,
      newValue,
      newYear,
      discountOrFeeType,
      true,
    );
  } else if (isFees) {
    save(
      loanStructureId,
      productFavouriteId,
      newValue,
      discountOrFeeType,
      true,
    );
  } else {
    return false;
  }

  $timeout(() => {
    $scope.cancel();
  }, 1000);
};

export const revert = ($scope) => () => {
  $scope.selectedProduct.TempNewValue = 0;
  $scope.selectedProduct.TempNewDiscountYear = 0;
  $scope.update();
};
