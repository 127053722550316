import './style.scss';
import template from './dealTemplateTask.html';

export default {
  template,
  require: {
    taskTemplatedCtrl: '^taskTemplated',
  },
  controllerAs: 'vm',
};
