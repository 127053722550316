class imagePreviewThumbCtrl {
  constructor(generalService, configService) {
    'ngInject';

    this.generalService = generalService;
    this.configService = configService;
  }

  toggleAlertBanner() {
    const { turnOffThumbnails } = this.configService.feature;
    if (!turnOffThumbnails) {
      return;
    }
    this.item.showAlertBanner = !this.item.showAlertBanner;
  }

  getThumb() {
    const { turnOffThumbnails } = this.configService.feature;
    if (
      !this.item.DocumentId ||
      this.item.isGettingThumbnail ||
      turnOffThumbnails
    ) {
      turnOffThumbnails && this.toggleAlertBanner();
      return;
    }

    if (!this.item.PageNumber) {
      this.item.PageNumber = 1;
    }

    const data = {
      documentId: this.item.DocumentId,
      pageNumber: this.item.PageNumber,
    };
    this.item.isGettingThumbnail = true;
    this.generalService.documentThumbnail(data).then(
      (response) => {
        this.item.ThumbNail = response.data;
        this.item.isGettingThumbnail = false;
      },
      () => {
        this.item.isGettingThumbnail = false;
      },
    );
  }

  $onInit() {
    if (!this.item.ThumbNail) {
      this.item.ThumbNail = null;
    }
  }
}

export default imagePreviewThumbCtrl;
