import angular from 'angular';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COLOR } from 'Common/constants/colors';
import { USERS_TAB } from 'Common/constants/users';
import { showSocialMedia } from 'Common/utilities/showFeature';
import { isOwnBrand, isAllBrand } from 'Common/utilities/brandingCategory';
import {
  onInit,
  checkUserProfileManagementAccess,
  checkMyLeadGeneratorAccess,
} from './util/usersCtrl';

const MAX_TAB_LENGTH = 4;
class UsersCtrl {
  constructor(
    $state,
    $localStorage,
    $stateParams,
    $timeout,
    contactService,
    configService,
    toaster,
    corporateService,
    contactSharedData,
    stateService,
    currentUserService,
  ) {
    'ngInject';

    this.contactService = contactService;
    this.configService = configService;
    this.$localStorage = $localStorage;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.toaster = toaster;
    this.corporateService = corporateService;
    this.contactSharedData = contactSharedData;
    this.stateService = stateService;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    onInit(this);
    this.familyId = null;
    this.adviserInfo = {};
    this.checkUserProfileManagementAccess = checkUserProfileManagementAccess({
      feature: this.configService.feature,
      currentUserService: this.currentUserService,
    });
    this.getBrokerInfo();
    this.activeTab = 0;
    this.refreshCount = 0;
    this.USERS_TAB = USERS_TAB;

    const { myLeadGenerator, accreditationTable } = this.configService.feature;
    const { hasAdviserAccess, hasAssistantAccess } = this.currentUserService;
    this.showAccreditation =
      !!accreditationTable && (hasAdviserAccess || hasAssistantAccess);
    this.showEmailSignature = this.configService.feature.showEmailSignature;
    this.showSocialMedia = showSocialMedia(this.currentUserService);
    this.checkSendEmailClientLinker();
    this.showMyLeadGenerator = checkMyLeadGeneratorAccess(
      myLeadGenerator,
      this.currentUserService,
    );
  }

  $onDestroy() {
    this.waitTabTimeOut && this.$timeout.cancel(this.waitTabTimeOut);
  }

  checkSendEmailClientLinker() {
    this.isSendEmailLinkerShow = false;
    this.sendEmailLinker = this.$localStorage.sendEmailClientLinker;
    if (!this.sendEmailLinker || !this.sendEmailLinker.familyId) {
      return;
    }
    this.getClientInfo();
    this.getActiveTab('email-signature');
    this.isSendEmailLinkerShow = true;
    this.gradientBannerObject = {
      startColor: COLOR.DARKER_BLUE,
      endColor: COLOR.BLUE_VIOLET,
      paddingLeftRight: '3%',
    };
  }

  getClientInfo() {
    if (!this.sendEmailLinker || !this.sendEmailLinker.familyId) {
      return;
    }
    this.contactService
      .clientInformGet(this.sendEmailLinker.familyId)
      .then((response) => {
        if (!response || !response.data || !response.data.length) {
          return;
        }
        this.clientFullName = response.data
          .reduce((accum, item) => {
            if (item.Role === 'Adult') {
              accum.push(`${item.FirstName} ${item.LastName}`);
            }
            return accum;
          }, [])
          .join(' & ');
        this.gradientBannerObject.text = `You were sending an email to Contact ${this.clientFullName}. Click here to go back`;
      });
  }

  addRefreshCount() {
    this.refreshCount++;
  }

  goBackToSendEmailClient() {
    this.$localStorage.sendEmailClientLinker = null;
    this.$state.go('app.sendEmail', {
      contactType: 'client',
      familyId: this.sendEmailLinker.familyId,
    });
  }

  getActiveTab(activeTab) {
    this.active = this.contactSharedData.getUsersActiveIndexTab(
      activeTab,
      USERS_TAB,
    );
    this.stateTransition(activeTab);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  setTabFlags() {
    const {
      userProfileManagement,
      userProfile,
      userReferral,
      userSubscription,
      nZSubscriptions,
      helloBookCorp,
    } = this.configService.feature;
    this.showUserProfile =
      (userProfileManagement &&
        userProfile &&
        (!this.currentUserService.isNZ || helloBookCorp)) ||
      isAllBrand(this.currentUserService) ||
      0;
    this.showUserReferral =
      !this.isAssistant() &&
      ((userProfileManagement &&
        userReferral &&
        !this.currentUserService.isNZ) ||
        0);
    this.showUserSubscription =
      !this.isAssistant() &&
      ((userProfileManagement && userSubscription) ||
        (this.currentUserService.isNZ && nZSubscriptions) ||
        0);

    if (this.$stateParams.activeTab) {
      this.waitTabTimeOut = this.$timeout(() => {
        const tabIndex = parseInt(this.$stateParams.activeTab, 10);
        this.activeTab = tabIndex < MAX_TAB_LENGTH ? tabIndex : 0;
        if (
          this.checkUserProfileManagementAccess &&
          this.showUserSubscription &&
          nZSubscriptions &&
          !this.showUserProfile
        ) {
          this.$state.go('app.users', { activeTab: 'subscriptions' });
        }
      }, 0);
    }
  }

  leadsManagementTabDisplay() {
    const clientId = 0;
    this.corporateService
      .corporateContactGet(clientId, this.familyId)
      .then((response) => {
        const { data } = response;
        if (!data || !data.AdviserOrganizationId) {
          this.showLeadsManagement = 0;
          return;
        }
        const complianceId = 0;
        return this.corporateService.adviserOrganizationGet(
          complianceId,
          data.AdviserOrganizationId,
        );
      })
      .then((response) => {
        if (!response || !response.data || !response.data.length) {
          this.showLeadsManagement = 0;
          return;
        }
        this.showLeadsManagement = !isOwnBrand(
          response.data[0].BrandingCategoryId,
        );
      });
  }

  getBrokerInfo() {
    this.contactService.getUserInfo().then((response) => {
      if (response.data && response.data.FamilyId) {
        this.familyId = response.data.FamilyId;
        this.adviserInfo = response.data;
        this.checkSubscriptionAccess();
        this.setTabFlags();
        this.showLeadsManagement =
          !this.isAssistant() &&
          this.adviserInfo.CountryId === COUNTRY_TYPE.AUSTRALIA &&
          this.configService.feature &&
          this.configService.feature.userLeadsManagement;
        if (!this.showLeadsManagement) {
          return;
        }
        this.leadsManagementTabDisplay();
      }
    });
  }

  isAssistant() {
    const assistantList = [ACCESS_TYPE.ADMIN_ASSISTANT, ACCESS_TYPE.ASSISTANT];
    return assistantList.includes(this.currentUserService.accessType);
  }

  checkSubscriptionAccess() {
    if (this.checkUserProfileManagementAccess) {
      return;
    }
    this.toaster.pop(
      'warning',
      'Page is still under development',
      'Come back soon!!!',
    );
    this.$state.go('app.dashboard');
  }
}

export const loadModule = () =>
  angular.module('app').controller('UsersCtrl', UsersCtrl);
