import angular from 'angular';
import $ from 'jquery';

export const loadModule = () =>
  angular.module('app').controller('UserLendersCtrl', [
    '$scope',
    '$stateParams',
    'userService',
    'configService',
    '$timeout',
    'imageDataURI',
    'toaster',
    function UserLendersCtrl(
      $scope,
      $stateParams,
      userService,
      configService,
      $timeout,
      imageDataURI,
      toaster,
    ) {
      userService.ProviderInfoGet().then((response) => {
        $scope.lenders = response.data;

        angular.forEach($scope.lenders, (value) => {
          // convert image to datauri
          imageDataURI(
            `/assets/images/company/${value.ProviderName.toLowerCase().replace(
              / /g,
              '_',
            )}.png`,
            (dataUri) => {
              value.Logo = dataUri;
            },
          );
        });

        $timeout(() => {
          $('.lender-box .panel').matchHeight();
        }, 500);
      });

      $scope.alertSave = function () {
        toaster.pop(
          'success',
          'Lender Updated',
          'Lender info has been updated',
        );
      };
    },
  ]);
