import template from './markCompletedPreApprovalModal.html';
import controller from './markCompletedPreApprovalModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    preApproval: '<',
  },
};
