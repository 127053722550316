import { ASSET_TYPE, STEPS } from 'Common/constants/securityValues';
import { appendEmptySelect } from 'Common/utilities/options';

export default class AssetFinanceVehicleModalCtrl {
  constructor(
    $timeout,
    loanProfilerService,
    optionsService,
    currentUserService,
    iFinanceAppService,
    toaster,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.loanProfilerService = loanProfilerService;
    this.currentUserService = currentUserService;
    this.iFinanceAppService = iFinanceAppService;
    this.optionsService = optionsService;
    this.yearManualList = [];
    this.monthFirstSearch = true;
    this.toaster = toaster;
    this.STEPS = STEPS;
    this.ASSET_TYPE = ASSET_TYPE;
  }

  $onInit() {
    this.assetStepNumber = 0;
    this.iFinanceParams = {};
    this.showOtherDetails = false;
    this.isDetailsFetching = false;

    this.updateConditions();
    this.getSecurityOptions();
    if (this.asset.vehicleDetails.redBookCode) {
      this.enterManually(true);
    }
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }

  saveVehicle() {
    this.modalInstance.close(this.asset);
  }

  updateConditions() {
    this.forVehicleOnly = true;
    this.showSteps =
      !this.isManualInput &&
      this.currentUserService.isAU &&
      this.forVehicleOnly;
    this.isVehicleAuto = this.currentUserService.isAU && !this.isManualInput;
    this.showMakeManual = true;
    this.showModelManual = true;
    this.showMake = this.assetStepNumber > 0;
    this.showModel = this.assetStepNumber > this.STEPS.THIRD;
    this.showFamily =
      this.forVehicleOnly && this.assetStepNumber > this.STEPS.FIRST;
    this.showVehiclePreview = this.forVehicleOnly;
    this.showManualInputs = this.isManualInput || !this.currentUserService.isAU;
    this.showOtherVehicleInput =
      !this.currentUserService.isAU || this.isManualInput;
    this.formValid =
      this.asset.vehicleDetails.redBookCode && this.asset.vehicleDetails.model;
    this.showManualLink = !this.isManualInput && this.forVehicleOnly;
  }

  enterManually(isManual = true) {
    this.isManualInput = isManual;
    this.updateConditions();
  }

  stepChanged(value, stepNumber) {
    this.assetStepNumber = value ? stepNumber : stepNumber - 1;
    this.updateConditions();
  }

  resetVehicleValues() {
    this.asset.vehicleDetails.vehicleClassID = 0;
    this.asset.vehicleDetails.make = '';
    this.asset.vehicleDetails.family = '';
    this.asset.vehicleDetails.month = '';
    this.asset.vehicleDetails.year = '';
    this.asset.vehicleDetails.redBookCode = '';
    this.asset.vehicleDetails.model = '';
  }

  startOver() {
    this.showOtherDetails = false;
    this.resetVehicleValues();
    this.assetStepNumber = 0;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  findVehicle(input, stepNumber, collectionName, dateType = 'month') {
    const vehicleType = input === 1 ? 4 : 3;
    const { vehicleClassID, make } = this.asset.vehicleDetails;
    this.iFinanceParams = {
      vehicleType: vehicleClassID,
    };
    switch (stepNumber) {
      case STEPS.SECOND:
        this.iFinanceParams.make = input;
        break;
      case STEPS.THIRD:
        this.iFinanceParams.make = make;
        this.iFinanceParams.family = input;
        this.monthFirstSearch = true;
        break;
      case STEPS.FORTH:
        this.setIFinanceParamsByYear();
        this.iFinanceParams[dateType] = input;
        break;
      default:
        this.iFinanceParams = {
          vehicleType,
        };
        break;
    }
    this.isDetailsFetching = true;
    this.iFinanceAppService
      .vehicleFinder(this.iFinanceParams)
      .then((res) => {
        if (!res || !res.data || !res.data.data) {
          if (
            !this.monthFirstSearch ||
            (stepNumber !== STEPS.FORTH && dateType === 'month')
          ) {
            this.toaster.pop(
              'info',
              'No result found.',
              'Enter manually instead',
            );
          }
          if (stepNumber === STEPS.FORTH && dateType === 'month') {
            this.monthFirstSearch = false;
          }

          this[collectionName] = [];
          this.stepChanged('', stepNumber);
          return;
        }
        if (stepNumber === STEPS.THIRD) {
          this.yearList = res.data.YearList;
          this.monthList = res.data.MonthList;
        } else {
          this[collectionName] = res.data.data;
        }
        this.stepChanged(input, stepNumber);
      })
      .finally(() => {
        this.isDetailsFetching = false;
      });
  }

  setIFinanceParamsByYear() {
    const { make, family, month, year } = this.asset.vehicleDetails;
    this.iFinanceParams.make = make;
    this.iFinanceParams.family = family;
    this.iFinanceParams.month = month;
    this.iFinanceParams.year = year;
  }

  modelChanged(rbc) {
    const { vehicleClassID } = this.asset.vehicleDetails;
    this.vehicleModelList.filter((data) => {
      if (data.rbc === rbc) {
        this.asset.vehicleDetails.model = data.description;
      }
      return data;
    });
    this.vehicleClassList.filter((data) => {
      if (data.value === vehicleClassID) {
        this.SelectedVehicleClass = data.name;
      }
      return data;
    });
    this.stepChanged(rbc, STEPS.FIFTH);
  }

  getSecurityOptions() {
    this.optionsService.getAssetFinanceVehicleClass().then((response) => {
      if (!response || !response.length) {
        return;
      }
      this.vehicleClassList = appendEmptySelect(response);
    });

    this.iFinanceAppService.getYearManualList().then((response) => {
      if (!response) {
        return;
      }
      this.yearManualList = response;
    });
  }
}
