import { SCREEN_SIZE } from 'Common/constants/screenSize';
import { PROVIDER_NAME } from 'Common/constants/lenderConstants';

const MIN_SEARCH_TEXT_LENGTH = 3;

export default class ProviderInformationListCtrl {
  constructor(
    $window,
    $timeout,
    providerInformationService,
    currentUserService,
  ) {
    'ngInject';

    this.$window = $window;
    this.$timeout = $timeout;
    this.providerInformationService = providerInformationService;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.providerName = this.currentUserService.isAU
      ? PROVIDER_NAME.AU
      : PROVIDER_NAME.DEFAULT;
    this.pageHeader = `${this.providerName} Information`;
    this.loadProviders();
    this.onLoadingProvidersFailed = this.loadProvidersFailedHandler.bind(this);
  }

  $onDestroy() {
    const { onFilterToggledTimeout, searchTimeout } = this;
    const timers = [
      ...(onFilterToggledTimeout && { onFilterToggledTimeout }),
      ...(searchTimeout && { searchTimeout }),
    ];

    if (timers.length) {
      this.$timeout.cancel(timers);
    }
  }

  getSearchProviderPlaceholder() {
    return this.currentUserService.isNZ ? `Search Providers` : `Search Lenders`;
  }

  loadProviders() {
    this.isLoadingProviders = true;
    this.providerInformationService
      .getProvidersInformation()
      .then((response) => {
        this.unfilteredProviders = [...response];
        this.loadCategoryFilters();
      })
      .catch(this.onLoadingProvidersFailed);
  }

  loadCategoryFilters() {
    this.providerInformationService
      .getProviderCategories()
      .then((response) => {
        this.categoryFilters = response;
        this.loadUserCategoryFilters();
      })
      .catch(this.onLoadingProvidersFailed);
  }

  loadUserCategoryFilters() {
    this.providerInformationService
      .getUserProviderCategories()
      .then(({ data }) => {
        const categoryIds =
          data && data.length
            ? data
            : this.categoryFilters.map((filter) => filter.id);
        this.setSelectedCategoryFilters(categoryIds);
      })
      .catch(() => {
        const categoryIds = this.categoryFilters.map((filter) => filter.id);
        this.setSelectedCategoryFilters(categoryIds);
        this.onLoadingProvidersFailed();
      });
  }

  loadProvidersFailedHandler() {
    this.isLoadingProviders = false;
  }

  isCategoryEnabled(categoryId) {
    const category = this.categoryFilters.find(
      (filter) => filter.id === categoryId,
    );
    return category && category.enabled;
  }

  setSelectedCategoryFilters(categoryFilters) {
    if (!categoryFilters || !categoryFilters.length) {
      return;
    }
    this.selectedCategoryFilters = categoryFilters.filter((filter) =>
      this.isCategoryEnabled(filter),
    );
    this.providers = this.getFilteredProviders();
    this.isLoadingProviders = false;
  }

  isMobile() {
    return this.$window.innerWidth <= SCREEN_SIZE.SM - 1;
  }

  onFilterToggled(filter, isSelected) {
    this.$timeout.cancel(this.onFilterToggledTimeout);
    this.isLoadingProviders = true;
    if (isSelected) {
      this.selectedCategoryFilters = [...this.selectedCategoryFilters, filter];
    } else {
      this.removeFilter(filter);
    }
    this.onFilterToggledTimeout = this.$timeout(() => {
      this.providers = this.getFilteredProviders();
      this.isLoadingProviders = false;
      this.saveSelectedFilters();
    }, 1000);
  }

  removeFilter(filter) {
    const indexOfFilter = this.selectedCategoryFilters.indexOf(filter);
    this.selectedCategoryFilters = [
      ...this.selectedCategoryFilters.slice(0, indexOfFilter),
      ...this.selectedCategoryFilters.slice(indexOfFilter + 1),
    ];
  }

  getFilteredProviders() {
    return this.unfilteredProviders.filter((provider) => {
      const categorySelected = provider.categories.reduce((accum, elem) => {
        const isInCategory = this.selectedCategoryFilters.includes(elem.id);
        return accum || isInCategory;
      }, false);
      return categorySelected && this.hasSearchTextInName(provider);
    });
  }

  hasSearchTextInName(provider) {
    if (!this.searchFilter) {
      return true;
    }
    const searchFilter = this.searchFilter.toLowerCase();
    const providerName = provider.providerName.toLowerCase();
    const providerCode = provider.providerCode.toLowerCase();
    return (
      providerCode.includes(searchFilter) || providerName.includes(searchFilter)
    );
  }

  saveSelectedFilters() {
    const categoryIds = this.selectedCategoryFilters.join(',');
    this.providerInformationService.setUserProviderCategories(categoryIds);
  }

  toggleFilter() {
    this.isFilterShown = !this.isFilterShown;
  }

  filterByProviderName() {
    if (
      this.searchFilter &&
      this.searchFilter.length < MIN_SEARCH_TEXT_LENGTH
    ) {
      return;
    }

    this.searchTimeout && this.$timeout.cancel(this.searchTimeout);
    this.isLoadingProviders = true;
    this.searchTimeout = this.$timeout(() => {
      this.providers = this.getFilteredProviders();
      this.isLoadingProviders = false;
    }, 1000);
  }
}
