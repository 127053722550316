import template from './commissionEnquiryFormModal.html';
import controller from './commissionEnquiryFormModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    advisers: '<',
    lenders: '<',
  },
};
