import template from './eSignSupportingDocuments.html';
import controller from './eSignSupportingDocumentsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanId: '<',
    packageSent: '<',
    showSendToLenderCheckbox: '<?',
    setDocInclusion: '&?',
  },
};
