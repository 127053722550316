import template from './leadGeneratorLink.html';
import LeadGeneratorLinkCtrl from './leadGeneratorLinkCtrl';
import './style.scss';

export default {
  template,
  controller: LeadGeneratorLinkCtrl,
  controllerAs: 'vm',
  bindings: {
    userLeadTypes: '<',
    isColumn: '<',
    isByobUser: '<',
    onCopyLink: '&',
    onOpenImageModal: '&',
    onOpenNewTab: '&',
  },
};
