import angular from 'angular';
import taskTemplated from './taskTemplated';
import dealTemplateTask from './dealTemplateTask/dealTemplateTask';
import taskTemplateItem from './taskTemplateItem/taskTemplateItem';
import taskTemplateViewer from './taskTemplateViewer/taskTemplateViewer';
import taskTemplateEditor from './taskTemplateEditor/taskTemplateEditor';

export default angular
  .module('components.taskTemplated', [])
  .component('taskTemplated', taskTemplated)
  .component('taskTemplateItem', taskTemplateItem)
  .component('dealTemplateTask', dealTemplateTask)
  .component('taskTemplateViewer', taskTemplateViewer)
  .component('taskTemplateEditor', taskTemplateEditor).name;
