import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('InsAppProdSelCtrl', function InsAppProdSelCtrl(
      $rootScope,
      $scope,
      $timeout,
      $interval,
      $compile,
      toaster,
      $state,
      $stateParams,
      utilitiesService,
      $uibModal,
      colorService,
      $document,
      insuranceAppService,
      insuranceQuoteService,
    ) {
      // MODELS - Setters
      angular.extend($scope, {
        savedQuoteOptionsList: [],
        savedQuotesList: [],
        insuranceQuoteData: [],
        selectedBenefit: 0,
        peopleList: [],
      });

      // MODELS - Getters
      angular.extend($scope, {});

      // Functionalities
      angular.extend($scope, {
        raw: {
          savedQuoteOptionsList: [],
        },
        listDownPeople(quoteId) {
          $scope.peopleList = _.filter($scope.savedQuoteOptionsList, (o) => {
            return o.QuoteID === quoteId;
          });

          const title = [];
          _.forEach($scope.peopleList, (pl) => {
            _.forEach(pl.people, (p) => {
              if (p) {
                title.push(
                  `${p.Gender} - ${p.Age} years old${
                    p.Smoker ? ' smoker,' : ' non-smoker,'
                  } Occupation Class ${p.Occupation}`,
                );
              }
            });
            pl.Title = title;
          });
        },
        createNewQuote() {
          $state.go('app.quickQuote');
        },
      });

      // Methods

      // strip unnecessary data
      function dataStripper(obj) {
        const id = angular.copy(obj.BenefitId);
        const tempBenefit = {
          BenefitId: id,
          Benefit: {},
          displayName: '',
          iconName: '',
        };

        switch (id) {
          case 1:
            tempBenefit.displayName = 'Health Cover';
            tempBenefit.Benefit = obj.HealthCover;
            tempBenefit.iconName = 'health-cover-small';
            break;
          case 2:
            tempBenefit.displayName = 'Life Cover';
            tempBenefit.Benefit = obj.LifeCover;
            tempBenefit.iconName = 'life-cover-small';
            break;
          case 3:
            tempBenefit.displayName = 'Family Protection';
            tempBenefit.Benefit = obj.FamilyProtection;
            tempBenefit.iconName = 'family-protection-small';
            break;
          case 4:
            tempBenefit.displayName = 'Trauma Cover';
            tempBenefit.Benefit = obj.TraumaCover;
            tempBenefit.iconName = 'trauma-small';
            break;
          case 5:
            tempBenefit.displayName = 'Total and Permanent Disability';
            tempBenefit.Benefit = obj.TotalAndPermanentDisability;
            tempBenefit.iconName = 'total-permanent-small';
            break;
          case 6:
            tempBenefit.displayName = 'Income Protection';
            tempBenefit.Benefit = obj.IncomeProtection;
            tempBenefit.iconName = 'income-protection-small';
            break;
          case 7:
            tempBenefit.displayName = 'Mortgage Repayment Cover';
            tempBenefit.Benefit = obj.MortgageRepaymentCover;
            tempBenefit.iconName = 'mortage-repayment-small';
            break;
          case 8:
            tempBenefit.displayName = 'Redundancy Cover';
            tempBenefit.Benefit = obj.RedundancyCover;
            tempBenefit.iconName = 'redundancy-cover-small';
            break;
          case 9:
            tempBenefit.displayName = 'Waiver Premium';
            tempBenefit.Benefit = obj.WaiverOfPremium;
            tempBenefit.iconName = 'premium-waiver-small';
            break;
          default:
            tempBenefit.displayName = 'Unknown Premium';
            tempBenefit.Benefit = {};
            tempBenefit.iconName = 'unknown-small';
            break;
        }

        return tempBenefit;
      }

      // eslint-disable-next-line unicorn/consistent-function-scoping
      function dateParser(dateString, format) {
        let dateArray = dateString.split('-');
        dateArray = dateString.split(' ');
        let parsedDate = '';

        // eslint-disable-next-line sonarjs/no-small-switch
        switch (format) {
          default:
            parsedDate = `${dateArray[2]} ${dateArray[1]} ${dateArray[3]}`;
            break;
        }

        return parsedDate;
      }

      function savedQuotesOptionsListGet(data) {
        _.forEach(data, (o) => {
          let info = {};
          info = {
            QuoteID: o.QuoteID,
            DateCreated: o.DateCreated,
            FamilyID: o.FamilyID,
            NoOfClients: o.NoOfClients,
            NoOfDependents: o.NoOfDependents,
            benefits: [],
            displayText: '',
            people: [],
          };
          _.forEach(o.PeopleEntity, (p) => {
            info.people.push({
              Gender: p.Gender === 1 ? 'Male' : 'Female',
              Age: p.Age,
              Smoker: p.Smoker,
              Occupation: p.Occupation,
            });
            _.forEach(p.BenefitList, (b) => {
              const d = dataStripper(b);
              if (info.displayText === '') {
                info.displayText = d.displayName;
              } else if (info.displayText.length < 20) {
                info.displayText += `/${d.displayName}`;
                const l = info.displayText.length;
                if (l > 20) {
                  const str = info.displayText;
                  const tdate = new Date(info.DateCreated);
                  info.displayText = `${str.slice(0, 16)}...(${dateParser(
                    tdate.toDateString(),
                    'dd MMM yyyy',
                  )})`;
                }
              }
              info.benefits.push(d);
            });
          });
          $scope.savedQuoteOptionsList.push(info);
        });
      }

      function insuranceQuoteGet() {
        insuranceQuoteService
          .insuranceQuoteGet(0, parseInt($state.params.familyId, 10))
          .then((response) => {
            $scope.insuranceQuoteData = response.data;
          });
      }

      // watchers
      $scope.$watch('insuranceQuoteData', () => {
        if ($scope.insuranceQuoteData.length > 0) {
          $scope.raw.savedQuoteOptionsList = angular.copy(
            $scope.insuranceQuoteData,
          );
        }
      });
      $scope.$watch('raw.savedQuoteOptionsList', () => {
        if ($scope.raw.savedQuoteOptionsList.length > 0) {
          savedQuotesOptionsListGet($scope.raw.savedQuoteOptionsList);
          $scope.selectedBenefit = _.last($scope.savedQuoteOptionsList).QuoteID;
        }
      });

      $scope.$watch('selectedBenefit', () => {
        $scope.listDownPeople($scope.selectedBenefit);
      });
      // initializers
      function init() {
        insuranceQuoteGet();
      }

      init();
    });
