import { loanStatusBuilderForUI } from 'Common/mappers/options';
import { MAIN_STATUS_LENGTH } from 'Common/constants/mainLoanStatusLength';

export function appendEmptySelect(data, placeHolderCustom = {}) {
  const defaults = { name: 'Please select', value: 0 };
  const placeHolder = Object.keys(placeHolderCustom).length
    ? placeHolderCustom
    : defaults;

  return [placeHolder, ...data];
}

export function appendAnyOption(data, option) {
  return [...option, ...data];
}

export const getOptionName = (list, value) => {
  const invalidParameter = !list || !list.length || !value;
  if (invalidParameter) {
    return;
  }
  const item = list.find((opt) => opt.Value === value.toString());
  return item && item.Name;
};

export const groupLoanStatuses = (loanStatuses) => {
  const groups = {
    LoanStatusList: [],
    LoanStatusMainGroup: [],
    LoanStatusMiscGroup: [],
  };

  if (loanStatuses && loanStatuses.length) {
    loanStatuses.forEach((status, index) => {
      const statusValue = loanStatusBuilderForUI(status);
      if (index < MAIN_STATUS_LENGTH.STATUS_INDEX) {
        groups.LoanStatusList.push(statusValue);
      }
      if (index < MAIN_STATUS_LENGTH.STATUS_DIVIDER) {
        groups.LoanStatusMainGroup.push(statusValue);
      } else {
        groups.LoanStatusMiscGroup.push(statusValue);
      }
    });
  }

  return groups;
};

export const groupOrgLoanStatuses = (orgLoanStatuses) => {
  const groups = {
    LoanStatusList: [],
    LoanStatusMainGroup: [],
    LoanStatusMiscGroup: [],
  };

  if (orgLoanStatuses && orgLoanStatuses.length) {
    orgLoanStatuses.forEach((status) => {
      const statusValue = loanStatusBuilderForUI(status);
      groups.LoanStatusList.push(statusValue);
      if (status.IsInactive) {
        groups.LoanStatusMiscGroup.push(statusValue);
      } else {
        groups.LoanStatusMainGroup.push(statusValue);
      }
    });
  }

  return groups;
};

export const areOrgPipelineStatuses = (loanStatuses) => {
  if (!loanStatuses || !loanStatuses.length) {
    return false;
  }
  return loanStatuses.every((status) => !!status.OrgPipelineStatusID);
};
