export const FILE_EXTENSION = {
  SVG: 'svg',
  PNG: 'png',
};
export const LOGO_FILE_TYPE_FORMAT = {
  svg: [FILE_EXTENSION.SVG],
  png: [FILE_EXTENSION.PNG],
};

export const CPD_QUALIFICATIONS_FILE_EXTENSIONS = ['pdf', 'jpg', 'jpeg', 'png'];
export const INVALID_PDF_JPEG_PNG_ONLY = {
  title: 'Invalid',
  description:
    'This file format is not supported, please try uploading using PDF, JPEG or PNG format',
};
