import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('renderHtmlData', function renderHtmlData($compile) {
      return {
        link: (scope, element, attrs) => {
          attrs.$observe('template', (tpl) => {
            if (angular.isDefined(tpl)) {
              const el = $compile(tpl)(scope);
              element.html('');
              element.append(el);
            }
          });
        },
      };
    });
