import template from './targetSettingsTile.html';
import controller from './targetSettingsTileCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    widgetInfo: '<',
    showCloseIcon: '<',
    onClose: '&',
    isFlipped: '<',
  },
};
