import template from './mycrmPopover.html';

export default {
  template,
  bindings: {
    callerData: '<',
    popoverMessage: '<',
    popoverConfirmMessage: '<',
    popoverConfirmTheme: '<',
    popoverCancelMessage: '<',
    onConfirm: '&',
    onDismiss: '&',
  },
  controllerAs: 'vm',
};
