import angular from 'angular';
import dateYearJumper from './dateYearJumper';
import mycrmDateRange from './mycrmDateRange';
import mycrmToggle from './mycrmToggle';
import mycrmSearchInput from './mycrmSearchInput';
import mycrmCheckbox from './mycrmCheckbox';
import mycrmGroupOption from './mycrmGroupOption';
import mycrmCustomSelect from './mycrmCustomSelect';
import mycrmMiniToggle from './mycrmMiniToggle';
import mycrmToggleText from './mycrmToggleText';
import mycrmSelectDateRange from './mycrmSelectDateRange';
import mycrmChecklistDropdown from './mycrmChecklistDropdown';
import mycrmDateSpan from './mycrmDateSpan';

export default angular
  .module('components.mycrmForms', [])
  .component('dateYearJumper', dateYearJumper)
  .component('mycrmDateRange', mycrmDateRange)
  .component('mycrmToggle', mycrmToggle)
  .component('mycrmSearchInput', mycrmSearchInput)
  .component('mycrmCheckbox', mycrmCheckbox)
  .component('mycrmCustomSelect', mycrmCustomSelect)
  .component('mycrmMiniToggle', mycrmMiniToggle)
  .component('mycrmToggleText', mycrmToggleText)
  .component('mycrmSelectDateRange', mycrmSelectDateRange)
  .component('mycrmChecklistDropdown', mycrmChecklistDropdown)
  .component('mycrmDateSpan', mycrmDateSpan)
  .component('mycrmGroupOption', mycrmGroupOption).name;
