import swal from 'sweetalert';
import moment from 'moment';
import { isFunction } from 'lodash';
import { toastError } from 'Common/utilities/alert';
import { getDatePickerConfig } from 'Common/utilities/date';

const LENDER_AREA = {
  EXISTING_PREV_LOANS: 2,
};

const ADVISER_ORG = {
  SAME_ORG: 1,
};

class CommissionEnquiryCtrl {
  constructor(
    loanInformationService,
    dashboardService,
    contactService,
    uiService,
  ) {
    'ngInject';

    this.loanInformationService = loanInformationService;
    this.dashboardService = dashboardService;
    this.contactService = contactService;
    this.uiService = uiService;
  }

  $onInit() {
    this.datePickerConfig = getDatePickerConfig({
      dateOptions: {
        formatYear: 'yy',
        showWeeks: false,
      },
    });

    if (this.loanId) {
      this.formData = {};
      this.loadLoanInformation();
    } else {
      this.formData = this.initializeFormData();
    }

    this.loadLenderList();
    this.loadBorrowerEntity();
    this.loadAdvisers();
  }

  initializeFormData() {
    if (this.loanInformation) {
      return this.initializeWithLoanData();
    } else if (this.commission) {
      return this.initializeWithCommissionData();
    }
    return {};
  }

  initializeWithLoanData() {
    const {
      SettlementDate: settlementDate,
      ReferenceNumber: referenceNumber,
      Lender: lender,
      loanAmount,
    } = this.loanInformation;

    return {
      settlementDate: settlementDate
        ? moment(settlementDate).toDate()
        : new Date(),
      lenderId: lender ? parseInt(lender.LenderId, 10) : 0,
      dateIsOpen: false,
      referenceNumber,
      loanAmount,
    };
  }

  initializeWithCommissionData() {
    const {
      clientName,
      loanAmount,
      referenceNumber,
      lenderId,
    } = this.commission;
    return {
      settlementDate: new Date(),
      dateIsOpen: false,
      referenceNumber,
      clientName,
      loanAmount,
      lenderId,
    };
  }

  loadLoanInformation() {
    this.contactService
      .loanDetailsGet(this.loanId)
      .then((response) => {
        const { data } = response;
        if (!data) {
          return;
        }

        const { loan, TotalLoanAmount: loanAmount } = data;
        if (!loan) {
          return;
        }

        this.loanInformation = { ...loan, loanAmount };
      })
      .finally(() => {
        this.formData = { ...this.formData, ...this.initializeFormData() };
      });
  }

  loadLenderList() {
    const isCommercial = true;
    this.contactService
      .lenderListGet(isCommercial, LENDER_AREA.EXISTING_PREV_LOANS)
      .then((response) => {
        this.lenderList =
          response.data &&
          response.data.map((lender) => {
            return {
              lenderId: parseInt(lender.ProviderId, 10),
              lenderName: lender.ProviderName,
              category: lender.Category,
            };
          });
      });
  }

  loadAdvisers() {
    this.dashboardService
      .GetAdvisersList(this.familyId, ADVISER_ORG.SAME_ORG)
      .then((response) => {
        this.advisers = response.data
          ? response.data.filter((adviser) => !!adviser.FullName)
          : [];

        if (this.loanInformation && this.loanInformation.OwnedByAdviserID) {
          this.formData.adviserId = this.loanInformation.OwnedByAdviserID;
        } else if (this.uiService.userFamilyId()) {
          this.formData.adviserId = this.uiService.userFamilyId();
        } else if (this.advisers.length) {
          this.formData.adviserId = this.advisers[0].FamilyId;
        }

        this.formData.adviserEmail = this.getSelectedAdviserEmailAddress();
      });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  loadBorrowerEntity() {
    if (!this.familyId || !this.loanId) {
      return;
    }

    this.contactService
      .borrowersDetailsGet(this.familyId, this.loanId, true)
      .then((response) => {
        if (!response.data || !response.data.length) {
          return;
        }

        const borrowers = response.data;
        const entities =
          borrowers && borrowers.filter((borrower) => borrower.IsEntity);
        this.clientsList =
          borrowers && borrowers.length
            ? borrowers.reduce((accum, borrower) => {
                if (!borrower.IsEntity) {
                  accum.push({
                    PersonId: borrower.BorrowerID,
                    FullName: `${borrower.FirstName || ''} ${
                      borrower.LastName || ''
                    }`.trim(),
                  });
                }
                return accum;
              }, [])
            : [];

        this.formData.borrowerEntity = entities
          .map((entity) => {
            return `${entity.FirstName || ''} ${entity.LastName || ''}`.trim();
          })
          .join(', ');

        if (this.clientsList && this.clientsList.length) {
          this.formData.clientId = this.clientsList[0].PersonId;
        }
      });
  }

  getSelectedAdviserEmailAddress() {
    if (!this.formData.adviserId) {
      return;
    }

    const selectedAdviser = this.advisers.find(
      (adviser) => adviser.FamilyId === this.formData.adviserId,
    );
    return selectedAdviser && selectedAdviser.EmailAddress;
  }

  onAdviserSelected() {
    this.formData.adviserEmail = this.getSelectedAdviserEmailAddress();
  }

  toggleCommissionEnquiry(commissionType) {
    this.loanInformationService.openCommissionEnquiry(commissionType);
  }

  submit(isFormValid) {
    if (!isFormValid) {
      return;
    }

    this.isSubmitting = true;
    this.contactService
      .loansExpectedCommissionsQuerySet(this.formData)
      .then(() => {
        swal({
          title: 'Enquiry Submitted',
          type: 'success',
          timer: 2000,
          showConfirmButton: false,
        });

        this.loanInformationService.commissionEnquiryIsOpen = false;
        this.formData = {};

        if (isFunction(this.onSubmitted)) {
          this.onSubmitted();
        }
      })
      .catch(() => {
        toastError('Commission enquiry submission failed');
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  }
}

export default CommissionEnquiryCtrl;
