import template from './loanDetailsImportantDates.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    loanDetails: '<',
    loanStatusList: '<',
    lendingDetailScope: '<',
    loanDetailScope: '<',
  },
};
