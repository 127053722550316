import { aggregatorBuilderForUI } from 'Common/mappers/aggregator';
import { normalizeData } from 'Common/utilities/array';

class AggregatorService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'aggregator';
  }

  getAggregators() {
    const cached = true;
    return this.httpClient
      .get(`${this.apiBaseUrl}`, {}, cached)
      .then(({ data }) => data.map(aggregatorBuilderForUI))
      .then((data) => normalizeData(data, 'id'));
  }
}

export default AggregatorService;
