import { COLOR } from 'Common/constants/colors';

class NavBreadCrumbCtrl {
  $onInit() {
    const { TRENDY_PINK_DARKER } = COLOR;
    this.defaultBgColor = TRENDY_PINK_DARKER;
  }
}

export default NavBreadCrumbCtrl;
