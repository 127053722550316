import {
  STATS_VIEWS,
  TIME_RANGES,
  STATS_VIEW_VALUE,
} from 'Common/constants/businessPlanningDashboard';
import { BUSINESS_PLANNING_WIDGETS } from 'Common/constants/widgetComponent';
import { recommendedWidgetBuilderForUI } from 'Common/mappers/dashboardWidget';

export default class YourStatsCtrl {
  constructor(dashboardService, configService) {
    'ngInject';

    this.dashboardService = dashboardService;
    this.configService = configService;
    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    const { statisticalDashboard } = this.configService.feature;
    this.showStatisticalDashboard = statisticalDashboard;
    this.widgetMapping = BUSINESS_PLANNING_WIDGETS;
    this.statsViews = STATS_VIEWS;
    this.statsView = STATS_VIEWS[0];
    this.timeRanges = TIME_RANGES;
    this.timeRange = TIME_RANGES[0];
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
    this.updateStatsView();
  }

  processParentNotification(eventHandler, selectedUserValues) {
    if (eventHandler === 'initYourStats') {
      this.selectedUserValues = selectedUserValues;
      this.updateStatsView();
    }
  }

  updateStatsView() {
    this.toggleShowContent();
    const { value } = this.statsView;
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (value) {
      case STATS_VIEW_VALUE.RECOMMENDED:
        this.getRecommendedWidgets();
        break;
      default:
    }
  }

  toggleShowContent(showContent) {
    this.showContent = !!showContent;
  }

  getRecommendedWidgets() {
    if (
      this.statsView &&
      this.statsView.value !== STATS_VIEW_VALUE.RECOMMENDED
    ) {
      return;
    }
    this.dashboardService.getRecommendedWidgets().then((data) => {
      this.toggleShowContent(true);
      this.recommendedWidgets = data || [];
      this.processWidgetInit();
    });
  }

  processWidgetInit() {
    const shouldInitialize = this.recommendedWidgets.every(
      (item) => !item.recommendedWidgetId,
    );
    if (!shouldInitialize) {
      return;
    }

    const widgetIds = this.recommendedWidgets
      .map((item) => item.widgetId)
      .join(',');
    this.dashboardService
      .setRecommendedWidgetsAuto({ widgetIds })
      .then(({ data }) => {
        this.recommendedWidgets =
          (data && data.map(recommendedWidgetBuilderForUI)) || [];
      });
  }
}
