import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('maxlength', function maxlength() {
    return {
      restrict: 'A',
      link(scope, element, attributes) {
        let limit = attributes.maxlength;
        element.bind('keyup', () => {
          const el = element.closest('.form-group');

          el.toggleClass('has-warning', limit - element.val().length <= 10);
          el.toggleClass('has-error', element.val().length >= limit);
        });

        element.bind('keypress', (event) => {
          // Once the limit has been met or exceeded, prevent all keypresses from working
          if (!limit) {
            limit = Number.MAX_VALUE;
          }
          if (element.val().length >= Number(limit) && event.keyCode !== 8) {
            event.preventDefault();
          }
        });
      },
    };
  });
