/**
 * Email Icon Field is an email type input element
 * with the email or warning icon on the left side
 * and edit icon on the right side
 * Depending on the property combinations passed the view should adjust
 * This can be read-only (disabled) and editable
 * states: warning (required, empty value, read-only), edit invalid email, edit valid email, disabled
 */

import { KEY_CODE } from 'Common/constants/keyCode';
import angular from 'angular';

export default class EditableTextFieldCtrl {
  constructor($timeout, $document) {
    'ngInject';

    this.$timeout = $timeout;
    this.$document = $document;
  }

  $onInit() {
    this.escapeKeyCode = KEY_CODE.ESC;
    this.setWarningState(false);
    if (this.isFastLoad) {
      this.resetField();
      return;
    }
    this.resetTimeout = this.$timeout(() => this.resetField(), 2000);
  }

  $onDestroy() {
    this.$timeout.cancel(this.resetTimeout);
    this.labelTimeout && this.$timeout.cancel(this.labelTimeout);
  }

  $onChanges(props) {
    if (props.txModel) {
      this.initTxModel = this.txModel;
      this.resetField();
    }
  }

  resetField(includeEditState = true) {
    includeEditState && this.setEditState(false);
    this.setSavingState(false);
    this.setInvalidState(false);
    this.showWarning();
    this.showDisabled();
    this.setConfirmPopover(false);
    this.updateParentState();
    this.isInvalidMinLength = false;
    this.isInvalidMinLength = false;
    this.isInvalidPattern = false;
    this.isInvalidWords = false;
  }

  setDisabledState(state) {
    this.isDisabledState = state;
  }

  setWarningState(state) {
    this.isWarningState = state;
  }

  setInvalidState(state) {
    this.isInvalidState = state;
  }

  setSavingState(state) {
    this.isSavingState = state;
  }

  setConfirmPopover(state) {
    this.showConfirmPopover = state;
  }

  setEditState(state) {
    this.isEdit = state;
    this.setDisabledState(!state);
    this.updateParentState();
  }

  getOpenState() {
    return this.isEdit || this.isInvalidState || this.isWarningState;
  }

  showWarning() {
    this.setWarningState(!!this.isRequired && !this.emailModel);
  }

  showDisabled() {
    this.setDisabledState(!this.isEdit && !this.isWarningState);
  }

  isValid(event, isKeyEvent = false) {
    if (event.keyCode === KEY_CODE.ESC) {
      this.cancel();
      event.preventDefault();
      return;
    }
    this.isKeyEvent = isKeyEvent;
    this.setEditState(true);

    this.isInvalidMinLength =
      this.minlength &&
      this.minlength >= 0 &&
      this.txModel.length < this.minlength;

    this.isInvalidMaxLength =
      this.maxlength &&
      this.maxlength > 0 &&
      this.txModel.length > this.maxlength;
    const reg = new RegExp(this.pattern);
    this.isInvalidPattern = this.pattern && !reg.test(this.txModel);

    if (this.restrictedWords) {
      this.isInvalidWords = this.restrictedWords.some((word) =>
        this.txModel.toLowerCase().includes(word),
      );
    }

    this.setInvalidState(
      this.isInvalidMaxLength ||
        this.isInvalidMinLength ||
        this.isInvalidPattern ||
        this.isInvalidWords,
    );

    this.updateParentState();
  }

  invalidMinMax(isInvalid) {
    this.setInvalidState(isInvalid);
    return isInvalid;
  }

  hasClass(className) {
    angular.element(this.$document.find(`#${this.id}`)).hasClass(className);
  }

  setDataSavedState() {
    this.dataIsSaved = !this.dataIsSaved;
  }

  editMode() {
    this.setDisabledState(false);
  }

  onRemoveFocus() {
    const isNotPristine =
      (this.txModel && this.txModel.trim()) !== this.initTxModel &&
      this.isEdit &&
      !this.isInvalidState &&
      !this.isWarningState;

    if (isNotPristine) {
      !this.showConfirmPopover && this.setConfirmPopover(true);
      // this.onFocusOut && this.onFocusOut();
    } else {
      this.cancel();
    }
  }

  save() {
    this.setSavingState(true);
    return this.onSave({
      txModel: this.txModel,
    })
      .then(() => {
        this.initTxModel = this.txModel;
        this.setDataSavedState();
        this.resetField(false);
        this.labelTimeout = this.$timeout(() => {
          this.setDataSavedState();
          this.setEditState(false);
        }, 1000);
        this.updateParentState();
      })
      .catch(() => this.cancel());
  }

  cancel(reset = true) {
    reset && (this.txModel = this.initTxModel);
    this.resetField();
    this.setConfirmPopover(false);
    this.updateParentState();
  }

  updateParentState() {
    this.onUpdate({
      txModel: this.txModel,
      isTextEdit: this.getOpenState(),
      cancelCallback: () => {
        this.cancel();
      },
    });
  }

  onRemovePopoverFocus(event) {
    if (event.keyCode === this.escapeKeyCode) {
      event.preventDefault();
    }
  }
}
