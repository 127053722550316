import template from './tasksTableFilter.html';
import controller from './tasksTableFilterCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    loanApplicationId: '<',
    showColumnsFilter: '<',
    showAdvisorFilter: '<',
    showCreatedDateFilter: '<',
    onTaskFilterChange: '&',
    onTableColumnsChange: '&',
    onListSearch: '&',
    onNewTask: '&',
    onNewTemplatedTask: '&',
  },
};
