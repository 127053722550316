export const setFocus = (element) => () => {
  const span =
    (element &&
      element.length &&
      element[0].childNodes &&
      element[0].childNodes.length &&
      element[0].childNodes[0]) ||
    false;
  if (!span) {
    return;
  }
  const button =
    (span.childNodes && span.childNodes.length && span.childNodes[0]) || false;
  if (!button) {
    return;
  }
  button.focus();
};
