import { TABLE_SIZE } from 'Common/constants/pageTable';
import { parseToInt10 } from 'Common/utilities/parse';
import { getFirstItem, getLastItem } from 'Common/utilities/pagination';
import { getNextPage, getPreviousPage } from './util/tablePaginationCtrl';

export default class TablePaginationCtrl {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.tableSizes = this.customTableSize || TABLE_SIZE;
    this.pageSize = parseToInt10(this.itemsPerPage);
    this.checkPage();
    this.setPageRange();
  }

  $onChanges() {
    this.checkPage();
    this.setPageRange();
  }

  changePageSize() {
    this.onPageChange({
      $e: { pageNumber: 1, pageSize: this.pageSize, isPageSizeChanged: true },
    });
  }

  changePage(pageDirection) {
    if (pageDirection === 'next') {
      this.currentPage = getNextPage(this.currentPage);
    } else if (pageDirection === 'previous') {
      this.currentPage = getPreviousPage(this.currentPage);
    }
    this.onPageChange({
      $e: { pageNumber: this.currentPage, pageSize: this.pageSize },
    });
  }

  checkPage() {
    this.isFirstPage = parseToInt10(this.currentPage) === 1;
    const pageResult = Math.ceil(
      parseToInt10(this.totalRecords) / parseToInt10(this.pageSize),
    );
    this.isLastPage = pageResult === parseToInt10(this.currentPage);
  }

  setPageRange() {
    this.firstItem = getFirstItem(this.currentPage, this.pageSize);
    this.lastItem = getLastItem(
      this.currentPage,
      this.pageSize,
      this.pageLength,
      this.totalRecords,
    );
  }
}
