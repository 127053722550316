export default class FilterComplaintModal {
  dismiss() {
    this.modalInstance.dismiss();
  }

  save() {
    const { filterList } = this;

    if (!filterList) {
      return;
    }

    this.modalInstance.close({ filterList });
  }
}
