import template from './insuranceFactFindGoals.html';
import controller from './insuranceFactFindGoalsCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    question: '<',
    answer: '<',
  },
};
