import template from './sendGamePlanToEsign.html';
import controller from './sendGamePlanToEsignCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanId: '<',
    isSendCqp: '<',
    isSendCpa: '<',
    showEsignApplicantOnly: '<',
  },
};
