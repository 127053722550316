import template from './cessationModal.html';
import cessationModalCtrl from './cessationModalCtrl';
import './style.scss';

export default {
  template,
  bindings: {
    modalInstance: '<',
    familyId: '<',
  },
  controller: cessationModalCtrl,
  controllerAs: 'vm',
};
