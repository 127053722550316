import { reject } from 'lodash';
import moment from 'moment-timezone';
import {
  DD_MMM_YYYY_FORMAT,
  DD_MM_YYYY_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { getDatePickerConfig } from 'Common/utilities/date';
import {
  alertError,
  toastError,
  toastSaveSuccess,
} from 'Common/utilities/alert';
import { EDITOR_OPTIONS_NO_TABLE } from 'Common/config/wysiwigEditor';
import {
  getPlainValueFromHtml,
  formatNewlineToHtmlBreak,
  formatHtmlBreakToNewline,
} from 'Common/utilities/string';

const ALL_INVOLVED_PARTIES = 'All Involved Parties';

class TaskFormModalCtrl {
  constructor(
    toaster,
    contactService,
    contactModelService,
    dashboardService,
    tasksService,
    tasksSharedData,
    clientInfoService,
    generalSearchService,
    currentUserService,
    taskDetailService,
    leadOpportunitiesService,
    uiService,
  ) {
    'ngInject';

    this.toaster = toaster;
    this.contactService = contactService;
    this.contactModelService = contactModelService;
    this.dashboardService = dashboardService;
    this.tasksService = tasksService;
    this.taskDetailService = taskDetailService;
    this.clientInfoService = clientInfoService;
    this.generalSearchService = generalSearchService;
    this.currentUserService = currentUserService;
    this.tasksSharedData = tasksSharedData;
    this.leadOpportunitiesService = leadOpportunitiesService;
    this.uiService = uiService;
  }

  $onInit() {
    this.editorOptions = EDITOR_OPTIONS_NO_TABLE;
    this.taskSet = {
      FamilyId: 0,
      TaskType: 'Business',
      Notes: '',
      LoanId: this.loanId || 0,
      QuoteID: this.quoteId,
      Title: this.taskName || '',
    };

    this.mainObject = {};
    this.mainObject.isSearchEnable = false;
    this.mainObject.datePopUp = false;
    this.format = DD_MMM_YYYY_FORMAT;
    this.dueDateFormat = DD_MM_YYYY_FORMAT;
    this.dateOptions = getDatePickerConfig();
    this.updateFamilyOnAdviserChange = this.tasksSharedData.updateFamilyOnAdviserChange;
    this.isLoading = false;
    this.hasInvolvedParties = !this.quoteId;
    this.setLinkToOpportunityBtnLabel();

    if (this.uiService.viewOfAFileTurnedOn) {
      require('./override.scss');
    }

    this.taskInput = {
      familyValue: '',
    };
    this.NOTE_DEFAULT_HEIGHT = {
      ADD_MODE: 130,
      EDIT_MODE: 190,
    };
    this.loadData();
    this.setEmptyOpportunityListTitle();
    this.isViewOfAFileTurnedOn = this.uiService.viewOfAfileTurnedOn;
    this.involvePartiesOptionInstance = {
      getSelectedValues: () => {
        return this.viewOfAFileTask.sharedToContactFamilyId.length
          ? this.viewOfAFileTask.sharedToContactFamilyId
          : [this.familyId];
      },
    };
    !this.uiService.newTaskDealEnabled && this.getTaskLinkToLoan();
    this.uiService.newTaskDealEnabled && this.setViewOfAFileTaskModel();
  }

  setViewOfAFileTaskModel() {
    this.viewOfAFileTask = {
      taskLoanLinkId: 0,
      loanId: this.loanId || 0,
      taskId: this.taskId,
      isActive: false,
      sharedToContactFamilyId: [],
      sharedToContactFamilyIdCsv: '',
    };
  }

  getTaskLinkToLoan() {
    if (!this.isViewOfAFileTurnedOn || !this.taskId) {
      return this.setViewOfAFileTaskModel();
    }
    const params = {
      taskId: this.taskId,
    };
    return this.tasksService.getTaskLinkToLoan(params).then((data = []) => {
      if (!data.length) {
        return this.setViewOfAFileTaskModel();
      }
      const [viewOfAFile = {}] = data;
      const sharedToContactFamilyId = data.map((contact) =>
        contact.sharedToContactFamilyId.toString(),
      );
      this.viewOfAFileTask = {
        ...viewOfAFile,
        loanIdRaw: viewOfAFile.loanId,
        sharedToContactFamilyId,
        sharedToContactFamilyIdCsv: '',
      };
    });
  }

  setTaskLinkToLoan(data = {}) {
    const loanId =
      this.viewOfAFileTask.loanId || this.viewOfAFileTask.loanIdRaw;
    const invalidLinkToLoan =
      !this.isViewOfAFileTurnedOn || !data.TaskID || !loanId;
    if (invalidLinkToLoan) {
      return Promise.resolve({ data });
    }
    const sharedToContactFamilyIdCsv = this.involvePartiesOptionInstance
      .getSelectedValues()
      .join();
    const params = {
      taskLoanLinkId: this.viewOfAFileTask.taskLoanLinkId,
      loanId,
      taskId: data.TaskID || 0,
      sharedToContactFamilyIdCsv,
      isActive: !!this.viewOfAFileTask.loanId,
    };
    return this.tasksService.setTaskLinkToLoan(params).then(() => {
      return { data };
    });
  }

  onContactFilesOptionChange({ value }) {
    this.viewOfAFileTask.loanId = value;
  }

  setEmptyOpportunityListTitle() {
    if (!this.opportunityList) {
      return;
    }

    this.opportunityList = this.opportunityList.map((data) => {
      if (!data.title) {
        return { ...data, title: '' };
      }
      return data;
    });
  }

  setLinkToOpportunityBtnLabel() {
    this.linkToOpportunityLabel = `Link to ${this.leadOpportunitiesService.getLabel(
      true,
    )}`;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  loadData() {
    this.loadingTaskDetails = true;
    if (this.taskId) {
      this.tasksService.taskDetailsGet(this.taskId).then(({ data }) => {
        if (!data || !data.TaskID) {
          alertError(`Can't seem to find task.`, false, 'Something went wrong');
          this.cancel();
          return;
        }

        this.taskSet = data;

        this.selectFamily(this.taskSet.FamilyID, this.taskSet.ClientFamilyName);
        this.taskSet.DueDate = new Date(this.taskSet.DueDate);
        if (this.taskSet && this.taskSet.CreatedByUser) {
          this.taskSet.CreatedByUser = this.taskSet.CreatedByUser.toString();
        }

        this.showNotes = true;
        if (!this.taskSet.Notes || !this.taskSet.CreatedByUser.trim()) {
          this.showNotes = false;
          this.selectFamily(
            this.taskSet.FamilyID,
            this.taskSet.ClientFamilyName,
          );
        }
        this.viewOfAFileTask.sharedToContactFamilyId = [data.FamilyID];
        this.viewOfAFileTask.loanId = data.LoanId;
        this.loadingTaskDetails = false;

        if (!this.uiService.ckeditorTaskEnabled) {
          const formattedLineBreak = formatHtmlBreakToNewline(
            this.taskSet.Notes,
          );
          this.taskSet.Notes = getPlainValueFromHtml(formattedLineBreak);
        } else {
          this.taskSet.Notes = formatNewlineToHtmlBreak(this.taskSet.Notes);
        }
      });
    } else {
      this.taskSet.DueDate = new Date();
      if (this.quoteId) {
        const family = this.familyList && this.familyList[0];
        family && this.selectFamily(family.FamilyId, family.FamilyFullName);
      }
      this.loadingTaskDetails = false;
    }

    if (this.familyId && !this.loanId && !this.quoteId) {
      this.contactService.clientInformGet(this.familyId).then((response) => {
        if (!response || !response.data) {
          return;
        }
        const data = response.data;
        this.taskInput.familyValue = this.clientInfoService.getFullName(data);
        this.taskSet.FamilyId = this.familyId;
      });
    }

    this.isCorporateUser =
      this.currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    const isLoanWriter =
      this.isCorporateUser && this.currentUserService.isNZ ? 0 : null;

    this.contactModelService
      .taskAdviserListGet(
        isLoanWriter,
        this.taskId || null,
        this.isFromTaskList,
        false,
      )
      .then((respond) => {
        if (!respond || !respond.data) {
          return;
        }
        this.taskAdviserList = respond.data;
        this.taskAdviserList = reject(respond.data, ['BrokerID', 0]);
        respond.data.forEach((item) => {
          if (!item) {
            return;
          }
          item.BrokerID = item.BrokerID && item.BrokerID.toString();
        });
        if (parseInt(this.taskId, 10) === 0) {
          this.dashboardService.getBrokerBasicInfo().then((response) => {
            if (!response || !response.data) {
              return;
            }
            this.taskSet.assignedClientID = response.data.BrokerId;
            this.taskSet.CreatedByUser = response.data.BrokerId;
          });
        }
      });

    this.contactService.getAllByFilter('*').then((respond) => {
      if (!respond || !respond.data) {
        return;
      }
      respond.data.FamilyList.forEach((value) => {
        if (!value) {
          return;
        }
        if (value.FamilyID === this.familyId) {
          this.taskInput.familyValue = value.FamilyFullName;
          if (parseInt(this.taskId, 10) === 0) {
            this.taskSet.FamilyId = value.FamilyID;
          }
        }
      });
    });
  }

  openDate() {
    this.mainObject.datePopUp = !this.mainObject.datePopUp;
  }

  showCalendar() {
    this.mainObject.datePopUp = true;
  }

  closeFamilyList() {
    this.familyDetailsList = this.taskDetailService.closeFamilyList(
      this.taskInput,
      this.mainObject,
    );
  }

  selectFamily(id, contactFullName = ALL_INVOLVED_PARTIES) {
    this.familyDetailsList = this.taskDetailService.selectFamily(
      id,
      contactFullName,
      this.taskInput,
      this.taskSet,
      this.mainObject,
    );
  }

  addNotes() {
    if (!this.taskSet.Notes || !this.taskSet.CreatedByUser.trim()) {
      this.showNotes = true;
    }
  }

  taskCompletionSet(task) {
    this.tasksService
      .taskCompletionSet(task.FamilyID, task.TaskID, task.IsCompleted)
      .then((response) => {
        if (response && response.data !== 1) {
          task.IsCompleted = !task.IsCompleted;
        } else {
          this.modalInstance.close('saved');
        }
      });
  }

  toggleOneComplete(task) {
    task.IsCompleted = !task.IsCompleted;
    if (!task.IsCompleted) {
      this.taskCompletionSet(task);
      return;
    }

    this.tasksSharedData.completionConfirmation(
      () => {
        this.taskCompletionSet(task);
      },
      () => {
        task.IsCompleted = false;
      },
    );
  }

  filterFamily(query) {
    const result = this.familyList.filter((el) => {
      return query
        ? el.FamilyFullName.toLowerCase().includes(query.toLowerCase())
        : el;
    });

    if (this.hasInvolvedParties) {
      const involvedParties = [
        {
          FamilyId: 0,
          FamilyFullName: ALL_INVOLVED_PARTIES,
          IsClient: true,
        },
      ];
      return [...result, ...involvedParties];
    }
    return [...result];
  }

  searchFamilyInput(searchString) {
    if (this.familyList) {
      this.mainObject.isSearchEnable = true;
      this.familyDetailsList = this.filterFamily(searchString);
      return;
    }

    if (searchString) {
      this.mainObject.isSearchEnable = true;
      this.dashboardService
        .getGenralSearchResult(searchString)
        .then((response) => {
          if (!response) {
            return;
          }
          this.getClientResult(response, searchString);
        });
    }
  }

  getClientResult(searchResult, searchString) {
    this.generalSearchService
      .getClientResult(searchResult, searchString)
      .then((res) => {
        if (!res || !res.allResults) {
          return;
        }
        this.familyDetailsList = this.generalSearchService.processClientSearch(
          res.allResults,
        );
      });
  }

  saveNewTask(bValue) {
    if (this.uiService.newTaskDealEnabled) {
      return this.saveTaskForDeal();
    }
    this.taskSet.FamilyId = this.taskInput.familyValue
      ? this.taskSet.FamilyId
      : 0;
    const {
      FamilyId,
      Title,
      CreatedByUser,
      Notes,
      ConnectID,
      DueDate,
      assignedClientID,
      QuoteID,
    } = this.taskSet;
    const { loanId, loanIdRaw } = this.viewOfAFileTask;
    const taskData = {
      FamilyID: FamilyId || '',
      Title,
      CreatedByUser,
      LoanId: this.loanId || loanId || loanIdRaw,
      assignedClientID,
      Notes,
      ConnectID,
      DueDate: `${moment(DueDate).format('YYYY-MM-DD')} 23:59:59`,
      QuoteID,
    };

    let msg;
    let serviceMethod;
    if (this.taskId) {
      taskData.TaskID = this.taskId;
      msg = 'Updated';
      serviceMethod = 'taskUpdate';
    } else {
      msg = 'Added';
      serviceMethod = 'taskSet';
    }

    this.isLoading = true;
    this.tasksService[serviceMethod](taskData)
      .then(({ data }) => {
        return this.setTaskLinkToLoan(data);
      })
      .then(() => {
        this.isLoading = false;
        this.toaster.pop(
          'success',
          msg,
          `Task successfully ${msg.toLowerCase()}`,
        );
        if (!bValue) {
          this.modalInstance.close('saved');
        }
      });
  }

  createTaskForDealRequest(familyId) {
    const {
      Title,
      CreatedByUser,
      Notes,
      ConnectID,
      DueDate,
      assignedClientID,
      QuoteID,
      FamilyID,
    } = this.taskSet;

    const isExisting = this.taskId && FamilyID === familyId;
    const serviceMethod = isExisting ? 'taskUpdate' : 'taskSet';

    const taskData = {
      TaskID: isExisting ? this.taskId : null,
      FamilyID: familyId || '',
      Title,
      CreatedByUser,
      LoanId: this.viewOfAFileTask.loanId,
      assignedClientID,
      Notes,
      ConnectID,
      DueDate: moment(DueDate).format(TO_MYCRM_DATE_FORMAT),
      QuoteID,
    };

    return this.tasksService[serviceMethod](taskData);
  }

  saveTaskForDeal() {
    this.isLoading = true;
    const clients = this.involvePartiesOptionInstance.getSelectedValues();
    const taskForDealRequests = clients.reduce((requests, familyId) => {
      return [...requests, this.createTaskForDealRequest(familyId)];
    }, []);
    return Promise.all(taskForDealRequests)
      .then(() => {
        toastSaveSuccess();
        this.modalInstance.close('saved');
      })
      .catch(toastError)
      .finally(() => {
        this.isLoading = false;
      });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  isSaveDisable() {
    const isClientValid =
      !this.selectedOpportunityId ||
      (this.selectedOpportunityId && this.involvePartiesOptionInstance?.valid);
    return (
      !isClientValid ||
      (this.mainObject &&
        this.mainObject.type &&
        this.mainObject.type === 'view') ||
      (this.taskSet && !this.taskSet.Title) ||
      (this.taskSet && !this.taskSet.DueDate)
    );
  }

  onSelectClient(familyId, fullName) {
    if (this.uiService.newTaskDealEnabled) {
      this.viewOfAFileTask.loanId = 0;
      this.familyId = familyId;
      this.viewOfAFileTask.sharedToContactFamilyId = [familyId];
    }
    this.selectFamily(familyId, fullName);
  }
}

export default TaskFormModalCtrl;
