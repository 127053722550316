class AsicAppointmentPreviewCtrl {
  constructor(
    currentUserService,
    onboardingService,
    onboardingDataService,
    corporateService,
  ) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.onboardingService = onboardingService;
    this.onboardingDataService = onboardingDataService;
    this.corporateService = corporateService;
  }

  getAsicDetails() {
    this.onboardingService
      .onboardingAdviserOrganisationASICDetailsGet(this.familyId, this.clientId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        data.HasASICAppoinment =
          data.ASICId || (this.currentUserService.isAU && data.ASICType);
        this.asicDetails = data;
        return this.corporateService.recruitmentPreferencesGet(
          this.familyId,
          this.clientId,
        );
      })
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }
        this.asicDetails.keepACL = data[0].KeepACL;
      });
  }

  $onInit() {
    this.familyId = this.onboardingDataService.getFamilyId();
    this.clientId = this.onboardingDataService.getClientId();
    this.getAsicDetails();
    this.asicAppointmentLabel = this.currentUserService.isNZ
      ? 'Does your entity have an existing FSPR number?'
      : 'Do you or your entity have an existing ASIC appointment?';
    this.asicAppointmentNumberLabel = this.currentUserService.isNZ
      ? 'FSPR Number'
      : 'ASIC Appointment Number';
  }
}

export default AsicAppointmentPreviewCtrl;
