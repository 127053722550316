export const DEFAULT_EXISTING_INSURANCE = {
  providerId: 0,
  policyId: '',
};

export const DEFAULT_INSURANCE_FILTER = {
  policyNumber: 'Show All Policies',
  providerId: 0,
};

export const otherPolicyOwnership = () => {
  return {
    clientId: 0,
    isDependent: false,
    isOwnership: false,
    fullName: 'Other',
    isInsured: false,
    other: '',
  };
};

export const defaultPolicyOwnership = () => {
  return {
    id: null,
    clientId: 0,
    isDependent: true,
    isOwnership: true,
    isInsured: true,
    other: '',
  };
};

export const defaultLoadingExlcusion = () => {
  return {
    benefitId: 0,
    familyId: 0,
    policyId: 0,
    benefitTypeId: '',
    clientId: 0,
    isStandardRates: true,
    isLoadingExclusion: false,
    loading: 0,
    details: '',
    date: '',
  };
};

export const defaultHealthCover = () => {
  return [
    {
      clientId: 0,
      isBaseCover: true,
      loading: 0,
      excess: '',
      isSpecialistsTest: true,
      isGpPrescriptions: true,
      isDentalOptical: true,
    },
  ];
};

export const defaultLifeCover = () => {
  return {
    isIndexed: true,
    isFutureInsurability: false,
    loading: 0,
    amount: '',
    calcPeriod: '',
  };
};

export const defaultFamilyProtection = () => {
  return {
    calcPeriod: '',
    isFutureInsurability: false,
    isIndexed: true,
    loading: 0,
    amount: '',
    benefitPeriodTerm: null,
    benefitPeriodYear: '',
  };
};

export const defaultTraumaCover = () => {
  return {
    isComprehensive: false,
    isEarlyCancer: false,
    isEarlyTrauma: false,
    isTpdExtension: false,
    calcPeriod: '',
    isOwnOccupation: false,
    isIndexed: false,
    includeChildren: false,
    isLifeBuyBack: false,
    isTraumaBuyBack: false,
    isTpdAddOn: false,
    isChildTrauma: false,
    loading: 0,
    amount: '',
    seriousTraumaCoverAmount: '',
    isStandalone: false,
    isAccelerated: false,
    isTpdAnyOccupation: false,
    isSeriousTrauma: false,
    childAmount: null,
  };
};

export const defaultTpdCover = () => {
  return {
    calcPeriod: '',
    isIndexed: false,
    loading: 0,
    amount: '',
    isOwnOccupation: true,
    isAnyOccupation: false,
    isStandalone: true,
    isAccelerated: false,
  };
};

export const defaultIncomeProtectionCover = () => {
  return {
    isPremier: true,
    type: '',
    calcPeriod: '',
    isIndexed: true,
    isBooster: true,
    amount: '',
    loading: 0,
    weekWaitPeriod: '',
    benefitPeriod: '',
    isTaxable: 0,
  };
};

export const defaultMortgageRepaymentCover = () => {
  return {
    calcPeriod: '',
    isIndexed: true,
    loading: 0,
    amount: '',
    weekWaitPeriod: '',
    benefitPeriod: '',
  };
};

export const defaultRedundancyCover = () => {
  return {
    calcPeriod: '',
    isIndexed: true,
    loading: 0,
    amount: '',
    hasMpCover: true,
    mrpCoverAmount: 0,
    weekWaitPeriod: '',
  };
};

// eslint-disable-next-line sonarjs/no-identical-functions
export const defaultMortgageLifeCover = () => {
  return {
    isIndexed: true,
    isFutureInsurability: false,
    loading: 0,
    amount: '',
    calcPeriod: '',
  };
};

export const defaultInsuranceDetails = (familyId) => {
  return {
    benefitId: 0,
    familyId,
    policyId: 0,
    benefitTypeId: '',
    qprBenefitId: 0,
    providerId: '',
    productId: '',
    clientId: 0,
    isCurrentClaim: false,
    isInArrears: false,
    policyNumber: '',
    statusId: '',
    commencementDate: '',
    paymentFrequency: '',
    waivePolicy: false,
    isPremiumAutoCalc: true,
    isPolicyFeeAutoCalc: true,
    totalPremium: 0,
    policyFee: 0,
    notes: '',
    policyOwnership: [defaultPolicyOwnership()],
    loadingExclusions: [defaultLoadingExlcusion()],
    benefits: {
      healthCover: defaultHealthCover(),
      lifeCover: defaultLifeCover(),
      familyProtection: defaultFamilyProtection(),
      traumaCover: defaultTraumaCover(),
      tpdCover: defaultTpdCover(),
      incomeProtectionCover: defaultIncomeProtectionCover(),
      mortgageRepaymentCover: defaultMortgageRepaymentCover(),
      redundancyCover: defaultRedundancyCover(),
      mortgageLifeCover: defaultMortgageLifeCover(),
      waiverOfPremium: {
        weekWaitPeriod: '',
      },
    },
  };
};
