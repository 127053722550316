export const PROVIDERS = {
  FIDELITY: 'Fidelity Nib',
  SBS: 'Southland Building Society',
  NIB: 'NIB',
  PROSPA: 'PROSPA',
  ASTERON: 'Asteron',
  RESIMAC: 'RESIMAC',
  AMP: 'AMP',
};

export const PROVIDER_LOGO = {
  [PROVIDERS.FIDELITY]: 'fidelity',
  [PROVIDERS.SBS]: 'SBS',
  [PROVIDERS.NIB]: 'nib',
  [PROVIDERS.PROSPA]: 'prospa',
  [PROVIDERS.ASTERON]: 'asteron',
  [PROVIDERS.RESIMAC]: 'Resimac',
  [PROVIDERS.AMP]: 'amp',
};
