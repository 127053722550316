import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('leadsChart', function leadsChart() {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/leadsChart.html',

      link(scope) {
        scope.labels = [
          'JAN',
          'FEB',
          'MAR',
          'APR',
          'MAY',
          'JUN',
          'AUG',
          'SEP',
          'OCT',
        ];
        scope.series = ['First', 'Second'];
        scope.data = [[65, 59, 80, 81, 56, 55, 40, 55, 20]];
        scope.colors = [
          {
            fillColor: '#e7dbea',
            highlightFill: '#d7b1e0',
          },
        ];
        // Chart.js Options - complete list at http://www.chartjs.org/docs/
        scope.options = {
          maintainAspectRatio: false,
          tooltipFontSize: 11,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          tooltipFontFamily: "'Helvetica', 'Arial', sans-serif",
          responsive: true,
          scaleFontFamily: "'Helvetica', 'Arial', sans-serif",
          scaleFontSize: 11,
          scaleFontColor: '#aaa',
          scaleBeginAtZero: true,
          tooltipTitleFontFamily: "'Helvetica', 'Arial', sans-serif",
          tooltipTitleFontSize: 12,
          scaleShowGridLines: true,
          scaleLineColor: 'transparent',
          scaleShowVerticalLines: false,
          scaleGridLineColor: 'rgba(0,0,0,.05)',
          scaleGridLineWidth: 1,
          barShowStroke: false,
          barStrokeWidth: 2,
          barValueSpacing: 5,
          barDatasetSpacing: 1,
        };

        //

        scope.labelsSent = [
          'JAN',
          'FEB',
          'MAR',
          'APR',
          'MAY',
          'JUN',
          'AUG',
          'SEP',
          'OCT',
        ];
        scope.seriesSent = ['First', 'Second'];
        scope.dataSent = [[55, 20, 80, 70, 88, 55, 40, 55, 77]];
        scope.colorsSent = [
          {
            fillColor: '#e7dbea',
            highlightFill: '#d7b1e0',
          },
        ];
        // Chart.js Options - complete list at http://www.chartjs.org/docs/
        scope.optionsSent = {
          maintainAspectRatio: false,
          tooltipFontSize: 11,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          tooltipFontFamily: 'Arial, Helvetica, sans-serif',
          responsive: true,
          scaleFontFamily: 'Arial, Helvetica, sans-serif',
          scaleFontSize: 11,
          scaleFontColor: '#aaa',
          scaleBeginAtZero: true,
          tooltipTitleFontFamily: 'Arial, Helvetica, sans-serif',
          tooltipTitleFontSize: 12,
          scaleShowGridLines: true,
          scaleLineColor: 'transparent',
          scaleShowVerticalLines: false,
          scaleGridLineColor: 'rgba(0,0,0,.05)',
          scaleGridLineWidth: 1,
          barShowStroke: false,
          barStrokeWidth: 2,
          barValueSpacing: 5,
          barDatasetSpacing: 1,
        };

        scope.interval = 'allDates';
        // eslint-disable-next-line unicorn/consistent-function-scoping
        function init() {}

        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'commissionReceivedChartPopover.html',
          title: 'Title',
        };

        scope.doAction = function (msg) {
          if (msg === 'Customise') {
            scope.customizeMode = true;
          } else {
            scope.customizeMode = false;
          }
        };

        init();
      },
    };
  });
