import { toastSuccess } from 'Common/utilities/alert';

class CorpPrintSummaryCtrl {
  constructor(
    $uibModal,
    $stateParams,
    downloadDataService,
    corpActionsAPIService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$stateParams = $stateParams;
    this.downloadDataService = downloadDataService;
    this.corpActionsAPIService = corpActionsAPIService;
  }

  printSummary() {
    const familyId = this.$stateParams.familyId;

    const modalInstance = this.$uibModal.open({
      template: `<actions-modal
        modal-instance="vm.modalInstance"
      ></actions-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      keyboard: false,
      backdrop: 'static',
      size: 'md',
      resolve: {
        props: () => {},
      },
      windowClass: 'print-loading-modal',
    });

    this.corpActionsAPIService
      .getPrintSummary(familyId)
      .then(({ contentType, documentContent }) => {
        this.downloadDataService.download(
          `data:${contentType};base64,${documentContent}`,
          `summary.pdf`,
          contentType,
        );
        toastSuccess({ messages: ['Summary Report generated successfully!'] });
        modalInstance.close();
      });
  }
}

export default CorpPrintSummaryCtrl;
