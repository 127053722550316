import template from './onboardingNavigation.html';
import OnboardingNavigationCtrl from './onboardingNavigationCtrl';

export default {
  template,
  bindings: {
    activeTab: '<',
    tabs: '<',
    lastTabOpen: '<',
    getActiveTab: '&',
  },
  controller: OnboardingNavigationCtrl,
  controllerAs: 'vm',
};
