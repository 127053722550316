export const setCountNotification = ({
  $rootScope,
  notificationSidebarService,
}) => {
  notificationSidebarService.countUnReadNotification().then((response) => {
    if (!response || !response.data) {
      return;
    }
    $rootScope.countNotification = response.data;
  });
};
