import { ADDRESS_TYPE } from 'Common/constants/addressModules';

export const getFilteredAddressTemplate = () => {
  return {
    [ADDRESS_TYPE.CURRENT_ADDRESS]: [],
    [ADDRESS_TYPE.POSTAL_ADDRESS]: [],
    [ADDRESS_TYPE.POST_SETTLEMENT_ADDRESS]: [],
    [ADDRESS_TYPE.PREVIOUS_ADDRESS]: [],
    [ADDRESS_TYPE.OTHER]: [],
  };
};
