import {
  noteTypeBuilderForUI,
  noteTypeBuilderForMyCRM,
  noteLinkToLoanBuilderForUI,
} from 'Common/mappers/contact';

class ContactsNotesService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'contacts-notes';
  }

  getDisplayCount() {
    return this.httpClient.get(`${this.apiBaseUrl}/display-count`);
  }

  setDisplayCount(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/display-count`, data);
  }

  getNoteTypes(communicationId = 0) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/note-types`, { communicationId })
      .then(({ data }) => data && data.map(noteTypeBuilderForUI));
  }

  postNoteTypes(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/note-types`,
      {},
      noteTypeBuilderForMyCRM(params),
    );
  }

  deleteNoteTypes(noteTypeId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/note-types`, {
      noteTypeId,
    });
  }

  getNotesLinkToLoan(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/notes-link-to-loan`, params)
      .then(({ data }) => data && data.map(noteLinkToLoanBuilderForUI));
  }

  setNotesLinkToLoan(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/notes-link-to-loan`, data);
  }

  setAdviserComment(familyID, comment) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/AdviserCommentSet?familyId=${familyID}&comment=${comment}`,
    );
  }

  noteSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/NoteSet`, data);
  }

  noteDelete(id) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/NoteDelete?communicationId=${id}`,
    );
  }

  notesListCount(familyID, brokerBrandingId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/NoteListCount?familyId=${familyID}&brokerbrandingId=${brokerBrandingId}`,
    );
  }

  notesList(params) {
    const {
      familyID,
      count,
      brokerbrandingId,
      pageNumber,
      isReferrer,
      loanId,
    } = params || {};

    return this.httpClient.get(
      `${this.apiBaseUrl}/NoteList?familyId=${familyID}&count=${count}&brokerBrandingId=${brokerbrandingId}&pageNumber=${pageNumber}&isReferrer=${isReferrer}&loanId=${loanId}`,
    );
  }

  noteGet(familyID, commId, brokerbrandingId, isOpportunity) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/NoteGet?communicationId=${commId}&familyId=${familyID}&brokerBrandingId=${brokerbrandingId}&isOpportunity=${isOpportunity}`,
    );
  }
}

export default ContactsNotesService;
