import {
  ANNUAL_REVIEW_DATE_FILTER,
  INSURANCE_TAB_ID,
} from 'Common/constants/customerCareModules';
import { D_MMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';
import moment from 'moment';

export const dateFilter = (dateRange, currentDate) => {
  const returnObj = {};
  switch (dateRange) {
    case ANNUAL_REVIEW_DATE_FILTER.PREVIOUS_MONTH:
      returnObj.reviewDateStart = moment(currentDate)
        .subtract(1, 'months')
        .startOf('month')
        .format(D_MMM_YYYY_FORMAT);
      returnObj.reviewDateEnd = moment(currentDate)
        .subtract(1, 'months')
        .endOf('month')
        .format(D_MMM_YYYY_FORMAT);
      break;
    case ANNUAL_REVIEW_DATE_FILTER.CURRENT_MONTH:
      returnObj.reviewDateStart = moment(currentDate)
        .startOf('month')
        .format(D_MMM_YYYY_FORMAT);
      returnObj.reviewDateEnd = moment(currentDate)
        .endOf('month')
        .format(D_MMM_YYYY_FORMAT);
      break;
    case ANNUAL_REVIEW_DATE_FILTER.NEXT_2_MONTHS:
      returnObj.reviewDateStart = moment(currentDate)
        .add(1, 'months')
        .startOf('month')
        .format(D_MMM_YYYY_FORMAT);
      returnObj.reviewDateEnd = moment(currentDate)
        .add(ANNUAL_REVIEW_DATE_FILTER.NEXT_2_MONTHS, 'months')
        .endOf('month')
        .format(D_MMM_YYYY_FORMAT);
      break;
    case ANNUAL_REVIEW_DATE_FILTER.NEXT_3_MONTHS:
      returnObj.reviewDateStart = moment(currentDate)
        .add(1, 'months')
        .startOf('month')
        .format(D_MMM_YYYY_FORMAT);
      returnObj.reviewDateEnd = moment(currentDate)
        .add(ANNUAL_REVIEW_DATE_FILTER.NEXT_3_MONTHS, 'months')
        .endOf('month')
        .format(D_MMM_YYYY_FORMAT);
      break;
    case ANNUAL_REVIEW_DATE_FILTER.ALL:
      returnObj.reviewDateStart = moment(currentDate)
        .startOf('year')
        .format(D_MMM_YYYY_FORMAT);
      returnObj.reviewDateEnd = moment(currentDate)
        .endOf('year')
        .format(D_MMM_YYYY_FORMAT);
      break;
    default:
      break;
  }

  return returnObj;
};

export const tabTypes = (tab) => {
  return {
    isAnnualReviews: tab.id === INSURANCE_TAB_ID.ANNUAL_REVIEWS,
    isIssuedFollowUp: tab.id === INSURANCE_TAB_ID.ISSUED_FOLLOWUP,
    isCurrentClaims: tab.id === INSURANCE_TAB_ID.CURRENT_CLAIMS,
    isInArrears: tab.id === INSURANCE_TAB_ID.IN_ARREARS,
  };
};
