import {
  collectionCamelizeBuilderForUI,
  collectionCapitalizeBuilderForUI,
} from 'Common/mappers/genericMapper';
import {
  singleAnswerBuilderForUI,
  singleAnswerBuilderForMyCRM,
} from 'Common/mappers/questionAnswer';

// TODO: move to loanScenarioService
import { factFindHeadingsBuilderForUI } from 'Common/mappers/factFind';
import { proposedLendingBuilderForUI } from 'Common/mappers/proposedLending';
import {
  fundingDetailsObjectiveBuilderForUI,
  reqAndObjValidationBuilderForUI,
} from 'Common/mappers/customerObjectives';

import {
  possibleAdverseChangesBuilderForUI,
  possibleAnticipatedBuilderForUI,
  possiblePlanOrAnticipateBuilderForUI,
  natureOfChangeBuilderForUI,
} from 'Common/mappers/loanScenario';

import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import { hasNewPostLink } from 'Common/utilities/handleObsoleteURL';
import { getCustomerObjQuestions } from 'Common/utilities/objectivesQuestion';

class LoanApplicationServices {
  constructor($q, httpClient, httpCacheService) {
    'ngInject';

    this.$q = $q;
    this.httpClient = httpClient;
    this.httpCacheService = httpCacheService;
    this.apiBaseUrl = 'LoanScenario';
    this.defaultOptions = [null, true, false];
  }

  getEmploymentType() {
    // TODO: move to optionsService
    return this.httpClient.get(
      'options/General/EmploymentTypeGet',
      ...this.defaultOptions,
    );
  }

  getEmploymentStatus() {
    // TODO: move to optionsService
    return this.httpClient.get(
      'options/General/EmploymentStatusGet',
      ...this.defaultOptions,
    );
  }

  getEmploymentBasis() {
    // TODO: move to optionsService
    return this.httpClient.get(
      'options/General/EmploymentBasisGet',
      ...this.defaultOptions,
    );
  }

  getEmployerType() {
    // TODO: move to optionsService
    return this.httpClient.get(
      'options/General/EmployerTypeGet',
      ...this.defaultOptions,
    );
  }

  setEmployment(data) {
    const obsoleteOrNewLink = hasNewPostLink(data, 'EmploymentSet');
    return this.httpClient.post(
      `${this.apiBaseUrl}/${obsoleteOrNewLink}`,
      data,
    );
  }

  setApplicantClientEntityDetail(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ApplicantClientEntityDetailSet`,
      data,
    );
  }

  getApplicantClientEntityDetail(loanScenarioId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ApplicantClientEntityDetailGet`,
      { loanScenarioId },
    );
  }

  getEmployment(loanApplicationId, employmentId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ApplicantEmployment`, {
      loanApplicationId,
      employmentId,
    });
  }

  getEmploymentCache(loanApplicationId, employmentId) {
    const defer = this.$q.defer();
    this.httpCacheService.get(
      `${this.apiBaseUrl}/ApplicantEmployment?loanApplicationId=${loanApplicationId}&employmentId=${employmentId}`,
      (data) => defer.resolve(data),
      (error) => defer.reject(error),
    );
    return defer.promise;
  }

  deleteEmployment(employmentId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/EmploymentDelete`, {
      employmentId,
    });
  }

  deleteAddress(addressValuationExtendedDetailsId, familyId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/AddressDelete`, {
      addressValuationExtendedDetailsId,
      familyId,
    });
  }

  getDepositType() {
    // TODO: move to optionsService
    return this.httpClient.get(
      'options/LoanApplication/DepositType',
      ...this.defaultOptions,
    );
  }

  getLoanDeposit(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanDepositGet`, {
      loanApplicationId,
    });
  }

  setLoanDeposit(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanDepositSet`, data);
  }

  getLoanDepositBreakDown(loanApplicationId, depositId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanDepositBreakDownGet`, {
      loanApplicationId,
      depositId,
    });
  }

  setLoanDepositBreakDown(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/LoanDepositBreakDownSet`,
      data,
    );
  }

  deleteLoanDepositBreakDown(loanScenarioId, depositBreakDownId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/LoanDepositBreakDownDelete`,
      { loanScenarioId, depositBreakDownId },
    );
  }

  getLoanSecurity(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanSecurityGet`, {
      loanApplicationId,
    });
  }

  getLoanSecurityInfo(loanApplicationId, loanSecurityInfoId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanSecurityInfoGet`, {
      loanApplicationId,
      loanSecurityInfoId,
    });
  }

  getLoanSecurityMortgage() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanSecurityMortgageGet`);
  }

  setLoanSecurityMortgage(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/LoanSecurityMortgageSet`,
      data,
    );
  }

  setLoanSecurityInfo(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanSecurityInfoSet`, data);
  }

  setLoanSecurity(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanSecuritySet`, data);
  }

  deleteLoanSecurityMortgage(loanApplicationId, SecurityMortgageId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/LoanSecurityMortgageDelete`,
      { loanApplicationId, SecurityMortgageId },
    );
  }

  deleteLoanSecurityInfo(
    loanApplicationId,
    securityInfoId,
    fundingCalParametersID,
  ) {
    return this.httpClient.delete(`${this.apiBaseUrl}/LoanSecurityInfoDelete`, {
      loanApplicationId,
      securityInfoId,
      fundingCalParametersID,
    });
  }

  getAvailableDocumentList(
    loanScenarioId,
    searchName,
    count = 100,
    pageNumber = 1,
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/AvailableDocumentListGet`, {
      loanScenarioId,
      searchName,
      count,
      pageNumber,
    });
  }

  getDocumentList(loanScenarioId) {
    return this.httpClient.get(`${this.apiBaseUrl}/Documents`, {
      loanScenarioId,
    });
  }

  getDocumentDetails(
    loanScenarioId,
    documentId,
    thumbWidth,
    generateThumbnail,
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/DocumentDetailsGet`, {
      loanScenarioId,
      documentId,
      thumbWidth,
      generateThumbnail,
    });
  }

  getDocumentPageCount(documentId) {
    return this.httpClient.get(`${this.apiBaseUrl}/DocumentPageCountGet`, {
      documentId,
    });
  }

  getIdentificationDocumentsType() {
    // TODO: move to optionsService
    return this.httpClient.get(
      'options/General/IdentificationDocumentsTypeGet',
      ...this.defaultOptions,
    );
  }

  setDocument(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/DocumentSet`, data);
  }

  setDocumentPage(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/DocumentPageSet`, data);
  }

  deleteDocument(loanScenarioId, documentId, loanScenarioDocumentId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/Documents/${loanScenarioDocumentId}`,
      { loanScenarioId, documentId },
    );
  }

  deleteAllDocument(loanScenarioId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/AllDocuments/${loanScenarioId}`,
    );
  }

  getImportantDate(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ImportantDateGet`, {
      loanApplicationId,
    });
  }

  putImportantDate(loanApplicationId, dateTypeId, date) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/ImportantDate`,
      {},
      { loanApplicationId, dateTypeId, date },
    );
  }

  getLoanObjectives(loanApplicationId, questionTypeId = '') {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanObjectivesGet`, {
      loanApplicationId,
      questionTypeId,
    });
  }

  postLoanObjectives(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/LoanObjectivesSet`, data);
  }

  cloneLoan(loanApplicationId, familyId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/CloneableResource/LoanApp/${loanApplicationId}`,
      {},
      { familyId },
    );
  }

  loanSummaryGet(loanApplicationId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanSummary`,
      { loanApplicationId },
      true,
    );
  }

  postMultipleApplicants(param) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ScenarioMultiApplicantSet`,
      param,
    );
  }

  getFundingDetailsAnswers(loanApplicationId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/questions/funding-details`, {
        loanApplicationId,
      })
      .then(({ data }) => data && data.map(singleAnswerBuilderForUI));
  }

  postFundingDetailsAnswer(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/questions/funding-details`,
      singleAnswerBuilderForMyCRM(data),
    );
  }

  getFundingDetailsObjectives(loanApplicationId, questionId) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/${loanApplicationId}/funding-details-objectives`,
        { questionId },
      )
      .then(
        (response) =>
          response.data &&
          response.data.map(fundingDetailsObjectiveBuilderForUI),
      );
  }

  getReqAndObjValidation(loanId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${loanId}/req-and-obj-validation`, null, true)
      .then((response) => reqAndObjValidationBuilderForUI(response.data));
  }

  postFundingDetailsObjectives(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/funding-details-objectives`,
      data,
    );
  }

  deleteFundingDetailsObjectives(data) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/funding-details-objectives`,
      data,
    );
  }

  getProposedLending(loanApplicationId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/proposed-lending`, {
        loanApplicationId,
      })
      .then(({ data }) => data && data.map(proposedLendingBuilderForUI));
  }

  partialShare(data, eMail, sms) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/share/partial`,
      data,
      {
        userInitiated: true,
        eMail,
        sms,
      },
      false,
    );
  }

  setProposedLending(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/proposed-lending`, data);
  }

  deleteProposedLending(data) {
    return this.httpClient.delete(`${this.apiBaseUrl}/proposed-lending`, data);
  }

  getFactFindHeadings() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/fact-find-headings`)
      .then((response) => response.data.map(factFindHeadingsBuilderForUI));
  }

  getPossibleAdverseChanges(params) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/${params.loanApplicationId}/possible-adverse-changes`,
        params,
      )
      .then(({ data }) => data && data.map(possibleAdverseChangesBuilderForUI));
  }

  setPossibleAdverseChanges(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${data.loanApplicationId}/possible-adverse-changes`,
      data,
    );
  }

  getPossibleAnticipated(params) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/${params.loanApplicationId}/possible-adverse-changes/possible-anticipated`,
        params,
      )
      .then(({ data }) => data && data.map(possibleAnticipatedBuilderForUI));
  }

  deletePossibleAnticipated(params) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/${params.loanApplicationId}/possible-adverse-changes/possible-anticipated`,
      params,
    );
  }

  setPossibleAnticipated(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${data.loanApplicationId}/possible-adverse-changes/possible-anticipated`,
      data,
    );
  }

  getPossiblePlanOrAnticipate(params) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/${params.loanApplicationId}/possible-adverse-changes/possible-plan-or-anticipate`,
        params,
      )
      .then(
        ({ data }) => data && data.map(possiblePlanOrAnticipateBuilderForUI),
      );
  }

  setPossiblePlanOrAnticipate(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${data.loanApplicationId}/possible-adverse-changes/possible-plan-or-anticipate`,
      data,
    );
  }

  getNatureOfChange(params) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/${params.loanApplicationId}/possible-adverse-changes/nature-of-change`,
        params,
      )
      .then(({ data }) => data && data.map(natureOfChangeBuilderForUI));
  }

  setNatureOfChange(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${data.loanApplicationId}/possible-adverse-changes/nature-of-change`,
      data,
    );
  }

  getAssociatedRisks({ isAU, isNZ }) {
    const LIST_OF_QUESTIONS = getCustomerObjQuestions({ isAU, isNZ });
    return this.$q.resolve({
      data: [
        {
          name: 'fixed rate',
          value: 'fixed',
          questionId: LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE,
          list: [
            'Rate is fixed for a specified term giving certainty of interest and repayments for that term.',
            'Ensure each applicant understands each of the following risks:',
            'Rate is fixed at a point in time and applicant(s) will not benefit from subsequent market interest rate reductions during fixed rate period.',
            'Rate may change between the time of approval and the time of drawdown if rate guarantee has not been obtained.',
            'Limited or no ability to make additional repayments when the interest rate is fixed.',
            'May not have the ability to redraw or utilise an offset account to reduce interest.',
            'Possibility of expensive break costs if, during the fixed interest rate period, applicant(s):',
          ],
          subList: [
            'Repay loan in full;',
            'Switch to another product or loan type;',
            'Make additional repayments;',
            'Sell the property; or',
            'Seek further funds.',
          ],
        },
        {
          name: 'variable rate',
          value: 'variable',
          questionId: LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE,
          list: [
            'Interest rate and repayment amount may increase while the loan is on a variable rate.',
          ],
        },
        {
          name: 'fixed and variable rate',
          value: 'split',
          questionId: LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE,
          list: [
            'Applicant(s) will not obtain the full benefit of rate decreases and will still have some exposure to the risk of rate increases.',
            'Applicant(s) will generally not be able to change the ratio of the fixed and variable portions.',
            'Applicant(s) will be required to make separate repayments for each portion.',
            'Fixed rate may change between the time of approval and the time of drawdown if rate guarantee has not been obtained.',
            'Limited or no flexibility in relation to the fixed rate portion concerning making additional repayments, redraws and offset accounts during the fixed rate period.',
            'Possibility of expensive break costs in relation to the fixed rate portion if during the fixed rate period, the applicant(s):',
          ],
          subList: [
            'Repay loan in full;',
            'Switch to another product or loan type;',
            'Make additional repayments;',
            'Sell the property; or',
            'Seek further funds.',
          ],
        },
        {
          name: 'interest only',
          value: '2',
          questionId: LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE,
          list: [
            'Higher interest rates may apply to interest only loans.',
            'Interest only payments will not reduce the loan principal.',
            'Not repaying loan principal will result in the applicant paying more interest over the loan term.',
            'After the end of the interest only period, principal and interest repayments will be required and these will be higher than they would have been if the loan had principal and interest repayments throughout the loan term.',
            'The amount of equity that is built-up in the property securing the loan will be less with an interest only loan.',
          ],
        },
        {
          name: 'interest in advance',
          value: '3',
          questionId: LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE,
          list: [
            `Rate must be fixed and all the risks set out above in relation to 'Fixed Interest' rate are applicable. Please explain each of these risks to the applicant(s).`,
            'Explain any different risks applicable to specific lender products (e.g. limitations on refunds for interest paid in advance).',
          ],
        },
        {
          name: 'line of credit',
          value: '4',
          questionId: LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE,
          list: [
            'Higher interest rates may apply to a line of credit as compared to a principal and interest loan.',
            'Not paying off principal may result in more interest being paid over the loan term.',
            'Usually no formal repayment structure exists to the facility and so financial discipline is required to repay the loan.',
            'If lender has discretion to reduce or cancel the limit, applicant(s) may be required to pay off the amount owing at any time and would need a plan for doing so.',
          ],
        },
        {
          name: 'offset account',
          value: '1',
          questionId: LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE,
          list: [
            'Feature may not be available when the loan is on a fixed rate.',
            'May only be a partial interest rate offset.',
            'Fees may apply.',
            'Explain any different risks applicable to specific lender products, including a higher interest rate on the loan.',
          ],
        },
        {
          name: 'redraw',
          value: '2',
          questionId: LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE,
          list: [
            'Lender may charge fees for each redraw.',
            `Each redraw may be subject to the lender's discretion.`,
          ],
        },
      ],
    });
  }

  getProductFeesList() {
    return this.$q.resolve({
      data: [
        {
          description: 'Establishment Fee',
          // eslint-disable-next-line sonarjs/no-duplicate-string
          payableWhen: 'At Settlement',
        },
        {
          description: 'Application Fee',
          payableWhen: 'At Settlement',
        },
        {
          description: 'Valuation Fee',
          payableWhen: 'At Settlement',
        },
        {
          description: 'Legal Fee',
          payableWhen: 'At Settlement',
        },
        {
          description: 'Settlement Fee',
          payableWhen: 'At Settlement',
        },
        {
          description: 'Ongoing Annual Fee',
          payableWhen: 'Annually',
        },
        {
          description: 'Ongoing Monthly Fee',
          payableWhen: 'Monthly',
        },
      ],
    });
  }

  getLoanImportantDates(isIncludeVaried = false) {
    const dateList = [
      [
        {
          id: 5,
          title: 'Lodged',
          field: 'SubmittedDate',
          isMain: true,
          requiredStatuses: [
            LOAN_STATUS.IN_PROGRESS.LODGED,
            LOAN_STATUS.IN_PROGRESS.PRE_APPROVED,
            LOAN_STATUS.EXISTING.SETTLED,
            LOAN_STATUS.IN_PROGRESS.CONDITIONAL_APPROVAL,
            LOAN_STATUS.IN_PROGRESS.UNCONDITIONAL_APPROVAL,
            LOAN_STATUS.IN_PROGRESS.PENDING_SETTLEMENT,
          ],
          defaulForStatus: LOAN_STATUS.IN_PROGRESS.LODGED,
          typeId: IMPORTANT_DATE_TYPES.IN_PROGRESS.SUBMITTED,
        },
        {
          id: 2,
          title: 'Finance Clause',
          field: 'FinanceDate',
          isMain: false,
          requiredStatuses: [],
          defaulForStatus: 0,
          typeId: IMPORTANT_DATE_TYPES.IN_PROGRESS.FINANCE,
        },
        {
          id: 4,
          title: 'Not Proceeding',
          field: 'NotProceedingDate',
          isMain: false,
          requiredStatuses: [],
          defaulForStatus: LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH,
          typeId: 0,
        },
      ],
      [
        {
          id: 8,
          title: 'Approved',
          field: 'ApprovalDate',
          isMain: true,
          requiredStatuses: [
            LOAN_STATUS.EXISTING.SETTLED,
            LOAN_STATUS.IN_PROGRESS.UNCONDITIONAL_APPROVAL,
            LOAN_STATUS.IN_PROGRESS.PENDING_SETTLEMENT,
          ],
          defaulForStatus: LOAN_STATUS.IN_PROGRESS.UNCONDITIONAL_APPROVAL,
          typeId: IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVAL,
        },
        {
          id: 6,
          title: 'Pre-Approved',
          field: 'PreApprovedDate',
          isMain: false,
          requiredStatuses: [LOAN_STATUS.IN_PROGRESS.PRE_APPROVED],
          defaulForStatus: LOAN_STATUS.IN_PROGRESS.PRE_APPROVED,
          typeId: IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVAL,
        },
        {
          id: 7,
          title: 'Conditionally Approved',
          field: 'ConditionallyApprovedDate',
          isMain: false,
          requiredStatuses: [LOAN_STATUS.IN_PROGRESS.CONDITIONAL_APPROVAL],
          defaulForStatus: LOAN_STATUS.IN_PROGRESS.CONDITIONAL_APPROVAL,
          typeId: 0,
        },
        {
          id: 9,
          title: 'Pre-Approved Expiry',
          field: 'ApprovalExpiry',
          isMain: false,
          requiredStatuses: [LOAN_STATUS.IN_PROGRESS.PRE_APPROVED],
          defaulForStatus: LOAN_STATUS.IN_PROGRESS.PRE_APPROVED,
          typeId: IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVALEXPIRY,
        },
      ],
      [
        {
          id: 1,
          title: 'Settled',
          field: 'SettlementDate',
          isMain: true,
          requiredStatuses: [LOAN_STATUS.EXISTING.SETTLED],
          defaulForStatus: LOAN_STATUS.EXISTING.SETTLED,
          typeId: IMPORTANT_DATE_TYPES.IN_PROGRESS.SETTLEMENT,
        },
        {
          id: 12,
          title: 'Estimated Settled',
          field: 'EstimatedSettlementDate',
          isMain: false,
          requiredStatuses: [],
          defaulForStatus: 0,
          typeId: IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT,
        },
        {
          id: 22,
          title: 'Repaid',
          field: 'RepaidDate',
          isMain: false,
          requiredStatuses: [],
          defaulForStatus: 0,
          typeId: IMPORTANT_DATE_TYPES.DELETE.REPAID,
        },
      ],
    ];

    if (isIncludeVaried) {
      const group3 = dateList[2];
      group3.push({
        id: 63,
        title: 'Varied',
        field: 'VariedDate',
        isMain: false,
        requiredStatuses: [LOAN_STATUS.EXISTING.VARIED],
        defaulForStatus: LOAN_STATUS.EXISTING.VARIED,
        typeId: IMPORTANT_DATE_TYPES.DELETE.VARIED,
      });
    }

    return this.$q.resolve(dateList);
  }

  getStatementOfAdviceAnswer(param) {
    return this.httpClient
      .get('loanapp-statement-of-advice/answer', param)
      .then(({ data }) => collectionCamelizeBuilderForUI(data));
  }

  setStatementOfAdviceAnswer(body) {
    return this.httpClient.post(
      'loanapp-statement-of-advice/answer',
      collectionCapitalizeBuilderForUI(body),
    );
  }
}

export default LoanApplicationServices;
