export function importantDocumentBuilderForUI(doc) {
  return {
    id: doc.ID,
    documentFlagId: doc.DocumentFlagId,
    documentFlagDesc: doc.DocumentFlagDesc,
    fileTitle: doc.FileTitle,
    fileDescription: doc.FileDescription,
    lastUpdated: doc.LastUpdated,
    currentVersion: doc.CurrentVersion,
    documentKey: doc.DocumentKey,
    isCreditGuide: doc.IsCreditGuide,
    categoryId: doc.CategoryID,
    labelId: doc.LabelID,
    labelName: doc.LabelName,
    isFavourite: doc.IsFavourite || false,
    updateStatusId: doc.UpdateStatusID || 0,
    accessibilityId: doc.AccessibilityID || 0,
    currentVersionDate: new Date(doc.CurrentVersion),
    brandingCategories: doc.BrandingCategories || '',
    isMigratedFromMyDocs: doc.IsMigratedFromMyDocs || false,
    documentId: doc.DocumentID,
    isDownloadFromSecuredBucket: !!doc.DocumentID,
    isDownloadFromPublicBucket: !!(!doc.DocumentID && doc.DocumentKey),
  };
}
export function importantDocumentDetailsBuilderForUI(doc) {
  const details = doc.DocumentDetails;
  const thumbnail = doc.Thumbnail;
  return {
    documentDetails: {
      id: details.Id,
      fileTitle: details.FileTitle,
      fileDescription: details.FileDescription,
      currentVersion: details.CurrentVersion,
      documentKey: details.DocumentKey,
      orderBy: details.OrderBy,
      isActive: details.IsActive,
      regionId: details.RegionId,
      contentType: details.ContentType,
      isCreditGuide: details.IsCreditGuide,
      documentFlagId: details.DocumentFlagId,
      accessibilityId: details.AccessibilityID || 0,
      brandingCategoryIds: details.BrandingCategoryIds || '',
    },
    thumbnail: {
      documentId: thumbnail.DocumentId,
      page: thumbnail.Page,
      thumbnailContent: thumbnail.ThumbnailContent,
      dataUrl: thumbnail.DataUrl,
      size: thumbnail.Size,
      contentType: thumbnail.ContentType,
    },
  };
}

export function importantDocumentDetailsBuilderForMyCRM(details) {
  return {
    Id: details.id,
    FileTitle: details.fileTitle,
    DocumentFlagId: details.documentFlagId,
    FileDescription: details.fileDescription,
    CurrentVersion: details.currentVersion,
    DocumentKey: details.documentKey,
    OrderBy: details.orderBy,
    IsActive: details.isActive,
    RegionId: details.regionId,
    ContentType: details.contentType,
    IsCreditGuide: details.isCreditGuide,
    CategoryID: details.categoryId,
    LabelID: details.labelId,
    UpdateStatusID: details.updateStatusId,
    AccessibilityID: details.accessibilityId || 0,
    BrandingCategoryIds: details.brandingCategoryIds || '',
  };
}
