function ngIntlTelInput(ngIntlTelInputProvider) {
  ngIntlTelInputProvider.set({
    initialCountry: 'nz',
    formatOnDisplay: true,
    nationalMode: true,
    autoHideDialCode: true,
    separateDialCode: false,
    preferredCountries: ['nz', 'au'],
  });
}

ngIntlTelInput.$inject = ['ngIntlTelInputProvider'];

export default ngIntlTelInput;
