import template from './opportunitiesTable.html';
import controller from './opportunitiesTableCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    searchString: '<',
    filterUpdateCount: '<',
    onTableDataLoaded: '&',
  },
};
