class OtherExpenseFormCtrl {
  updateFormValue(prop, value) {
    if (!prop) {
      return;
    }
    this.form[prop] = value;
  }
}

export default OtherExpenseFormCtrl;
