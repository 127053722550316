import angular from 'angular';

export const loadModule = () =>
  angular.module('app').filter('checkNoteEmpty', function checkNoteEmpty() {
    return function (input, emptyStringMessage) {
      if (
        angular.isString(input) &&
        !(angular.equals(input, null) || angular.equals(input, ''))
      ) {
        return input;
      }
      return emptyStringMessage;
    };
  });
