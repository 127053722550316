import { PIPELINE_CATEGORIES } from 'Common/constants/pipelineCategories';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';

export function getInvolvedPartyBasicModel(isClient) {
  return {
    ClientEntityID: 0,
    FamilyID: 0,
    DisplayName: '',
    IsClient: isClient,
    AddRelatedChildren: true,
  };
}

export function getLoanLeadBasicModel() {
  return {
    AllocatedAdviserID: 0,
    Title: '',
    Borrowers: [],
    Guarantors: [],
  };
}

export function goToLead($state) {
  $state.go('app.leads');
}

export function getLeadStatus(pipelineService) {
  return pipelineService.SettingsStatusGetFiltered().then((res) => {
    const { data } = res;
    if (!res || !data) {
      return [];
    }

    const statusList =
      data.filter(
        (obj) => obj.PipelineCategoryID === PIPELINE_CATEGORIES.LEAD,
      ) || [];
    if (statusList.length) {
      statusList.push(
        {
          PipelineStatus: 'Convert to Application',
          PipelineStatusID: PIPELINE_STATUS.APPLICATION,
          IsConversionStatus: true,
        },
        {
          PipelineStatus: 'Convert to Opportunity',
          PipelineStatusID: PIPELINE_STATUS.OPPORTUNITY,
          IsConversionStatus: true,
        },
        {
          PipelineStatus: 'Not Proceeded',
          PipelineStatusID: PIPELINE_STATUS.NOT_PROCEEDED,
          IsConversionStatus: true,
        },
      );
    }
    return statusList;
  });
}
