import controller from './comingSoonCtrl.js';
import './style.scss';

export default {
  controller,
  controllerAs: 'vm',
  bindings: {
    label: '<',
    feature: '<',
  },
  template: `
  <label ng-if="vm.isNZ" class="feature" ng-click="vm.onSendModal();">{{::vm.feature}} <span class="label-message" >{{::vm.label}}</span></label>
  `,
};
