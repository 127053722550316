export const entityDetailsInstance = (propertyOf) => {
  return {
    validateNZBNcharacter: (data) => {
      propertyOf.invalidCharacters = false;
      const NZBNChar = (data && data.toString()) || '';
      if (
        NZBNChar &&
        NZBNChar.length !== propertyOf.NZBN_PROPERTY.NUMERICAL_LENGTH
      ) {
        propertyOf.invalidCharacters = !propertyOf.invalidCharacters;
      }
    },
  };
};
