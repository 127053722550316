import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { toastError, toastSuccess, alertError } from 'Common/utilities/alert';
import { REFERRAL_TYPE } from 'Common/constants/referralType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { REFERRAL_CONTACT_TYPE } from 'Common/constants/referralContactTypes';
import { isMobileValid, isMobileNumberValid } from 'Common/utilities/mobile';
import { showWealthMarketLoanApps } from 'Common/utilities/showFeature';
import { phoneType } from 'Common/constants/phoneType';
import { setupNewPostURL } from 'Common/utilities/handleObsoleteURL';
import {
  companyEmailPhoneValidation,
  emailAndPhoneValidation,
  getNextGenLoanList,
  formSetDataForWealthMarket,
  toggleLoader,
  setIsShowClientContactDetails,
  formatClientInfoForKiwi,
  isValidContactDetail,
  initializeAddressOverhaul,
  saveAddress,
  validateNumber,
  formatAdviser,
} from './sendReferralModalUtil';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('SendReferralModalCtrl', function SendReferralModalCtrl(
      $scope,
      $uibModalInstance,
      toaster,
      sendReferralService,
      dashboardService,
      contactService,
      corporateService,
      wmComplianceService,
      errorMessageHandler,
      generalService,
      referralService,
      $timeout,
      $state,
      $window,
      currentUserService,
      configService,
      loanAppSharedData,
      addressLookupService,
      uiService,
      contactModelService,
      mycrmLookupService,
    ) {
      const { wealthMarketLoanApps } = configService.feature;
      initializeAddressOverhaul({ $scope, configService });
      $scope.showWealthMarketLoanApps = showWealthMarketLoanApps({
        wealthMarketLoanApps,
        currentUserService,
      });
      $scope.validateNumber = validateNumber({ $scope });
      $scope.currentUserService = currentUserService;
      $scope.isPhoneNumberCountryCodeNZ =
        configService.feature.phoneNumberCountryCodeNZ &&
        currentUserService.isNZ;
      $scope.isIntlPhoneNumbers = uiService.isInternationalPhoneNumber;
      $scope.nextGenLoanList = [];
      $scope.showLoader = false;
      $scope.REFERRAL_CONTACT_TYPE = REFERRAL_CONTACT_TYPE;
      $scope.contactType = REFERRAL_CONTACT_TYPE.EXISTING;
      $scope.PHONE_TYPE = phoneType;
      $scope.toaster = toaster;
      $scope.REFERRAL_TYPE = REFERRAL_TYPE;
      $scope.isClickedSendBtn = false;
      $scope.isEmailContactValid = true;
      $scope.intlPhoneChildrenSelectors = ['.selected-flag', '.form-control'];
      /* MODELS */
      $scope.isShowClientContactDetails = false;
      $scope.isShowPhoneCountBanner = false;
      let contactType = REFERRAL_CONTACT_TYPE.EXISTING;
      angular.extend($scope, {
        currentUserInfo: {},
        formObj: { $valid: false, $invalid: true },
        selectedAdviserInformation: {},
        adviserOrgStatus: true,
        leadProfile: { WMReferralAlloc: false },
        agencyList: $scope.agencyList,
        formModel: {
          BTTC_Anytime: true,
          BTTC_Morning: true,
          BTTC_Afternoon: true,
          BTTC_Evening: true,
          adviser: { FamilyId: 0, hasAgreement: false },
          errorObj: {
            hasError: false,
            errorInstance: '',
          },
          clientContact: null,
        },
        selectedContact: {},
        showSearch: false,
        selectedAgency: $scope.refType || 0,
        selectedClientName: '',
        contactName: '',
        office: [],
        adviser: [],
        propAddressList: [],
        propAddress: {
          formatted_address: '',
        },
        form: {
          selectedRayWhiteOffice: '',
          displayItems: false,
        },
        rayWhiteAdvisers: null,
        corpContactInfo: [],
        adviserOrgList: [],
        advisersUnderOrgList: [],
        searchString: '',
        contact: {},
        sendTo: {
          uniqueOrgList: [],
          uniqueSendToList: [],
          adviserOrgList: [],
        },
        selectedMemberAdviser: null,
      });
      $scope.runningMethods = {};

      // FUNCTIONALITIES.
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getNewRayWhiteModel = (data) => {
        return {
          OfficeID: data.OfficeID,
          OfficeName: data.OfficeName,
          FamilyID: data.FamilyID,
          ClientID: data.ClientID,
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.Email,
          Mobile: data.Mobile,
          Postal: data.Postal,
          Notes: data.Notes,
          BTTC_Anytime: data.BTTC_Anytime,
          BTTC_Morning: data.BTTC_Morning,
          BTTC_Afternoon: data.BTTC_Afternoon,
          BTTC_Evening: data.BTTC_Evening,
          HouseAppraisal: data.HouseAppraisal,
          PropertyManagement: data.PropertyManagement,
          Buyer: data.Buyer,
          AddressID: data.AddressID,
          SendToID: data.SendToID,
          SendToFullName: data.SendToFullName,
          AdviserID: data.AdviserID,
          AdviserName: data.adviser.FullName,
          adviser: data.adviser,
          HasConsent: data.HasConsent,
          WMAdviceTypeID: data.WMAdviceTypeID,
          WMAdviceType: data.WMAdviceType,
        };
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getUKPensionModel = (data) => {
        return {
          OfficeID: data.OfficeID,
          FamilyID: data.FamilyID,
          ClientID: data.ClientID,
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.Email,
          Mobile: data.Mobile,
          Postal: data.Postal,
          PensionValue: data.PensionValue,
          NZResident: data.NZResident,
          Notes: data.Notes,
          BTTC_Anytime: data.BTTC_Anytime,
          BTTC_Morning: data.BTTC_Morning,
          BTTC_Afternoon: data.BTTC_Afternoon,
          BTTC_Evening: data.BTTC_Evening,
          HouseAppraisal: data.HouseAppraisal,
          PropertyManagement: data.PropertyManagement,
          Buyer: data.Buyer,
          AddressID: data.AddressID,
          SendToID: data.SendToID,
          SendToFullName: data.SendToFullName,
          AdviserID: data.AdviserID,
          AdviserName: data.adviser.FullName,
          adviser: data.adviser,
          HasConsent: data.HasConsent,
          WMAdviceTypeID: data.WMAdviceTypeID,
          WMAdviceType: data.WMAdviceType,
        };
      };
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getNewFnGModel = (data) => {
        return {
          OfficeID: data.OfficeID,
          FamilyID: data.FamilyID,
          ClientID: data.ClientID,
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.Email,
          Mobile: data.Mobile,
          Postal: data.Postal,
          Notes: data.Notes,
          BTTC_Anytime: data.BTTC_Anytime,
          BTTC_Morning: data.BTTC_Morning,
          BTTC_Afternoon: data.BTTC_Afternoon,
          BTTC_Evening: data.BTTC_Evening,
          HouseAppraisal: data.HouseAppraisal,
          PropertyManagement: data.PropertyManagement,
          Buyer: data.Buyer,
          House: data.HouseAppraisal,
          Mortgage: data.Mortgage,
          Vehicle: data.Vehicle,
          Farm: data.Farm,
          Business: data.Business,
          Contents: data.Contents,
          Boats: data.Boats,
          Travel: data.Travel,
          AddressID: data.AddressID,
          SendToID: data.SendToID,
          SendToFullName: data.SendToFullName,
          AdviserID: data.AdviserID,
          AdviserName: data.adviser.FullName,
          adviser: data.adviser,
          HasConsent: data.HasConsent,
          WMAdviceTypeID: data.WMAdviceTypeID,
          WMAdviceType: data.WMAdviceType,
        };
      };
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getNewOFXandXEModel = (data) => {
        return {
          OfficeID: data.OfficeID,
          FamilyID: data.FamilyID,
          ClientID: data.ClientID,
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.Email,
          Mobile: data.Mobile,
          Postal: data.Postal,
          Notes: data.Notes,
          BTTC_Anytime: data.BTTC_Anytime,
          BTTC_Morning: data.BTTC_Morning,
          BTTC_Afternoon: data.BTTC_Afternoon,
          BTTC_Evening: data.BTTC_Evening,
          HouseAppraisal: data.HouseAppraisal,
          PropertyManagement: data.PropertyManagement,
          Buyer: data.Buyer,
          AddressID: data.AddressID,
          SendToID: data.SendToID,
          SendToFullName: data.SendToFullName,
          AdviserID: data.AdviserID,
          AdviserName: data.adviser.FullName,
          adviser: data.adviser,
          HasConsent: data.HasConsent,
          WMAdviceTypeID: data.WMAdviceTypeID,
          WMAdviceType: data.WMAdviceType,
        };
      };
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getNewWealthMarketModel = (data) => {
        return {
          OfficeID: data.OfficeID,
          FamilyID: data.FamilyID,
          ClientID: data.ClientID,
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.Email,
          Mobile: data.Mobile,
          Postal: data.Postal,
          Notes: data.Notes,
          BTTC_Anytime: data.BTTC_Anytime,
          BTTC_Morning: data.BTTC_Morning,
          BTTC_Afternoon: data.BTTC_Afternoon,
          BTTC_Evening: data.BTTC_Evening,
          HouseAppraisal: data.HouseAppraisal,
          PropertyManagement: data.PropertyManagement,
          Buyer: data.Buyer,
          AddressID: data.AddressID,
          SendToID: data.SendToID,
          SendToFullName: data.SendToFullName,
          AdviserID: data.AdviserID,
          AdviserName: data.adviser.FullName,
          adviser: data.adviser,
          HasConsent: data.HasConsent,
          WMAdviceTypeID: data.WMAdviceTypeID,
          WMAdviceType: data.WMAdviceType,
          IsAutoAllocation: data.IsAutoAllocation,
        };
      };
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getNewOtherModel = (data) => {
        return {
          OfficeID: data.OfficeID,
          FamilyID: data.FamilyID,
          ClientID: data.ClientID,
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.Email,
          Mobile: data.Mobile,
          Postal: data.Postal,
          Notes: data.Notes,
          BTTC_Anytime: data.BTTC_Anytime,
          BTTC_Morning: data.BTTC_Morning,
          BTTC_Afternoon: data.BTTC_Afternoon,
          BTTC_Evening: data.BTTC_Evening,
          HouseAppraisal: data.HouseAppraisal,
          PropertyManagement: data.PropertyManagement,
          Buyer: data.Buyer,
          AddressID: data.AddressID,
          SendToID: data.SendToID,
          SendToFullName: data.SendToFullName,
          AdviserID: data.AdviserID,
          AdviserName: data.adviser.FullName,
          adviser: data.adviser,
          HasConsent: data.HasConsent,
          WMAdviceTypeID: data.WMAdviceTypeID,
          WMAdviceType: data.WMAdviceType,
          SendTo_FirstName: data.SendTo_FirstName,
          SendTo_LastName: data.SendTo_LastName,
          SendTo_Email: data.SendTo_Email,
        };
      };
      // eslint-disable-next-line unicorn/consistent-function-scoping, sonarjs/no-identical-functions
      const getNewLMModel = (data) => {
        return {
          OfficeID: data.OfficeID,
          FamilyID: data.FamilyID,
          ClientID: data.ClientID,
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.Email,
          Mobile: data.Mobile,
          Postal: data.Postal,
          Notes: data.Notes,
          BTTC_Anytime: data.BTTC_Anytime,
          BTTC_Morning: data.BTTC_Morning,
          BTTC_Afternoon: data.BTTC_Afternoon,
          BTTC_Evening: data.BTTC_Evening,
          HouseAppraisal: data.HouseAppraisal,
          PropertyManagement: data.PropertyManagement,
          Buyer: data.Buyer,
          AddressID: data.AddressID,
          SendToID: data.SendToID,
          SendToFullName: data.SendToFullName,
          AdviserID: data.AdviserID,
          AdviserName: data.adviser.FullName,
          adviser: data.adviser,
          HasConsent: data.HasConsent,
          WMAdviceTypeID: data.WMAdviceTypeID,
          WMAdviceType: data.WMAdviceType,
        };
      };
      const formModelHub = (data, type) => {
        let model = {};
        if (
          _.isUndefined(data.FamilyID) ||
          _.isUndefined(data.FirstName) ||
          _.isUndefined(data.LastName) ||
          _.isUndefined(type)
        ) {
          data.OfficeID = 0;
          data.Officename = null;
          data.SendToID = 0;
          data.SendToID = 0;
          data.FamilyID = 0;
          data.ClientID = 0;
          data.FirstName = '';
          data.LastName = '';
          data.Email = '';
          data.Mobile = '';
          data.Postal = '';
          data.Notes = '';
          data.HouseAppraisal = false;
          data.PropertyManagement = false;
          data.Buyer = false;
          data.AddressID = null;
          data.SendToFullName = '';
          data.adviser = data.adviser || {};
          data.adviser.FamilyId = data.adviser.FamilyId || 0;
          data.adviser.FullName = data.adviser.FullName || '';
          data.adviser.Postal = data.adviser.Postal || '';
          data.adviser.hasAgreement = data.adviser.hasAgreement || false;
          data.HasConsent = false;
          data.WMAdviceTypeID = 0;
          data.WMAdviceType = '';
          data.IsAutoAllocation = false;
          // specific for UK Pension
          data.PensionValue = 0;
          data.NZResident = false;
          // specific for FnG
          data.HouseAppraisal = false;
          data.Mortgage = '';
          data.Vehicle = false;
          data.Farm = false;
          data.Business = false;
          data.Contents = false;
          data.Boats = false;
          data.Travel = false;
          // specific for Other
          data.SendTo_FirstName = data.SendTo_FirstName || '';
          data.SendTo_LastName = data.SendTo_LastName || '';
          data.SendTo_Email = data.SendTo_Email || '';
        }
        switch (parseInt(type, 10)) {
          case 1:
            model = getNewRayWhiteModel(data);
            break;
          case 2:
            model = getUKPensionModel(data);
            break;
          case 3:
            model = getNewFnGModel(data);
            break;
          case 4:
          case 8:
            model = getNewOFXandXEModel(data);
            break;
          case 5:
            model = getNewWealthMarketModel(data);
            break;
          case 6:
            model = getNewOtherModel(data);
            break;
          case 7:
            model = getNewLMModel(data);
            break;
          case 9:
            model = {
              ...data,
              AdviserID:
                $scope.currentUserInfo.AccessType ===
                ACCESS_TYPE.ADMIN_ASSISTANT
                  ? data.AdviserID
                  : data.adviser.FamilyId,
              LoanAmountRequired: parseFloat(data.LoanAmountRequired),
              Deposit: parseFloat(data.Deposit),
            };
            break;
          default:
            toaster.pop('error', 'Cannot find models for this selection');
            break;
        }
        return model;
      };
      const getFormModel = (refTypeID) => {
        switch (parseInt(refTypeID, 10)) {
          case 1:
            $scope.formModel = formModelHub($scope.formModel, refTypeID);
            if ($scope.office.length === 0) {
              sendReferralService
                .rayWhiteOrganisationGet(10000, 0)
                .then((response) => {
                  $scope.rayWhiteOrganisation = response.data;
                });
            }
            break;
          case 9:
            $scope.formModel = formModelHub($scope.formModel, refTypeID);
            break;
          case 2:
            $scope.formModel = formModelHub($scope.formModel, refTypeID);
            break;
          case 3:
            $scope.formModel = formModelHub($scope.formModel, refTypeID);
            break;
          case 4:
          case 8:
            $scope.formModel = formModelHub($scope.formModel, refTypeID);
            break;
          case 5:
            $scope.formModel = formModelHub($scope.formModel, refTypeID);
            wmComplianceService.adviceTypeGet().then((response) => {
              $scope.adviceTypeList =
                response.data.length !== 0
                  ? response.data
                  : ['No advice type in the list'];
            });
            break;
          case 6:
            $scope.formModel = formModelHub($scope.formModel, refTypeID);
            break;
          case 7:
            $scope.formModel = formModelHub($scope.formModel, refTypeID);
            break;
          default:
            // todo: create/initiate initial must be here
            break;
        }
      };

      const advisersListGet = () => {
        if (!$scope.runningMethods.advisersListGet) {
          $scope.runningMethods.advisersListGet = true;
          const sameAdvOrg = 1;
          if ($scope.currentUserInfo.isCorp) {
            // no default adviser
            $scope.formModel.AdviserID = $scope.formModel.AdviserID
              ? $scope.formModel.AdviserID
              : null;
            $scope.formModel.adviser = {
              FamilyId: '',
              FullName: '',
              Postal: '',
            };
          } else {
            $scope.formModel.AdviserID = $scope.currentUserInfo.FamilyId
              ? $scope.currentUserInfo.FamilyId
              : null;
            $scope.formModel.adviser = {
              FamilyId: $scope.currentUserInfo.FamilyId,
              FullName: $scope.currentUserInfo.FullName,
              Postal: '',
            };
          }
          const userInfo = $scope.currentUserInfo;
          const adviserGetParams = {
            familyId: userInfo.FamilyId,
            searchSameAdviserOrg: sameAdvOrg,
          };
          if (
            userInfo.AccessType &&
            userInfo.AccessType !== ACCESS_TYPE.ADMIN_ASSISTANT
          ) {
            mycrmLookupService
              .getAdvisers(adviserGetParams)
              .then((response) => {
                const advisers = response.map((adviser) =>
                  formatAdviser(adviser),
                );
                if (advisers && advisers.length) {
                  $scope[
                    contactType === 'existing'
                      ? 'existingContactAdviserList'
                      : 'nonMycrmAdviserList'
                  ] = advisers.filter((data) => {
                    return data.FullName.length;
                  });
                } else {
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  toaster.pop('error', 'Did not find any adviser');
                }
                $scope.runningMethods.advisersListGet = false;
                if (!$scope.currentUserInfo.isCorp) {
                  $scope.selectAdviser(null, $scope.isClientSearchReadOnly);
                }
              });
          } else {
            mycrmLookupService
              .getAdvisers(adviserGetParams)
              .then((response) => {
                const data = response.map((adviser) => formatAdviser(adviser));
                if (data && data.length) {
                  $scope.filteredAdviserList = data.filter((obj) => {
                    return obj.FullName && obj.FullName.length;
                  });
                  const adviserListPropertyName =
                    contactType === 'existing'
                      ? 'existingContactAdviserList'
                      : 'nonMycrmAdviserList';
                  $scope[adviserListPropertyName] = $scope.filteredAdviserList;
                  if (
                    $scope.currentUserInfo.AccessType ===
                      ACCESS_TYPE.ADMIN_ASSISTANT &&
                    $scope.filteredAdviserList &&
                    $scope.filteredAdviserList.length
                  ) {
                    $scope.formModel.AdviserID = '';
                  }
                } else {
                  toaster.pop('error', 'Did not find any adviser');
                }
                $scope.runningMethods.advisersListGet = false;
                if (!$scope.currentUserInfo.isCorp) {
                  $scope.selectAdviser(null, $scope.isClientSearchReadOnly);
                }
              });
          }
        }
      };

      const getUserInfo = () => {
        return new Promise((resolve) => {
          if (!$scope.currentUserInfo.FamilyId) {
            contactService.getUserInfo().then((response) => {
              if (!_.isUndefined(response.data)) {
                resolve(response.data);
              }
            });
          } else {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            resolve('userFetch-error');
          }
        });
      };

      $scope.blankSendToForOther = () => {
        if ($scope.selectedAgency === REFERRAL_TYPE.OTHER) {
          $scope.formModel.SendTo_FirstName = '';
          $scope.formModel.SendTo_LastName = '';
          $scope.formModel.SendTo_Email = '';
        }
      };

      const selectContactType = (obj) => {
        contactType = obj || contactType;
        $scope.isExisting = contactType && contactType !== 'nonMyCRM';
        !$scope.isExisting && toggleLoader({ $scope });
        $scope.isOtherExistingContact = $scope.isExisting;
        $scope.selectedContactFamilyId =
          ($scope.selectedContact && $scope.selectedContact.FamilyID) || 0;
        advisersListGet();
        if (!$scope.isExisting) {
          $scope.formModel.FirstName = '';
          $scope.formModel.LastName = '';
          $scope.formModel.Mobile = '';
          $scope.formModel.Email = '';
          $scope.formModel.AdviserID = '';
          $scope.blankSendToForOther();
          $scope.selectedContactFamilyId = 0;
        }
        // check user
        if (_.isUndefined($scope.currentUserInfo.FamilyId)) {
          getUserInfo();
        }
        // initial model
        getFormModel();
        if (typeof $scope.reloadExistingAddress === 'function') {
          $scope.reloadExistingAddress($scope.selectedContactFamilyId);
        }
      };

      const accessTypeGet = () => {
        if ($scope.currentUserInfo.AccessType === ACCESS_TYPE.CORPORATE) {
          $scope.currentUserInfo.isCorp = true;
        } else {
          $scope.currentUserInfo.isCorp = false;
        }
      };

      const initialDataLoader = () => {
        accessTypeGet();
        advisersListGet();
        selectContactType();
      };

      const init = () => {
        getUserInfo().then((data) => {
          if (data === 'userFetch-error') {
            errorMessageHandler.sendReferralError('userFetch-error');
          } else {
            $scope.currentUserInfo = data;
            accessTypeGet();
            advisersListGet();
            if (
              !$scope.initialClientInfo ||
              !$scope.initialClientInfo.FamilyID
            ) {
              return;
            }
            $scope.userInfo = data;
            $scope.isClientSearchReadOnly = true;
            $scope.selectContact($scope.initialClientInfo);
          }
          selectContactType();
        });
        if (!$scope.userInfo) {
          getUserInfo().then((data) => {
            if (data === 'userFetch-error') {
              errorMessageHandler.sendReferralError('userFetch-error');
            } else {
              $scope.currentUserInfo = data;
              initialDataLoader();
            }
          });
        } else {
          $scope.currentUserInfo = $scope.userInfo;
          initialDataLoader();
        }
      };
      const clientInformGet = (id) => {
        return new Promise((resolve) => {
          contactService.clientInformGet(id).then((response) => {
            if (response.data.length > 0) {
              resolve({
                status: 'success',
                data: response.data,
              });
            } else {
              resolve({
                status: 'error',
                data: 'clientInformGet-error',
              });
            }
          });
        });
      };
      const getAdviserUnderOrg = (adviserOrgId) => {
        corporateService
          .advisersUnderOrganizationGet(adviserOrgId)
          .then((response) => {
            if (
              !_.isUndefined(response.data.length) &&
              response.data.length > 0
            ) {
              $scope.adviserUnderOrgList = response.data;
            }
          });
      };
      const getReferralOrgList = () => {
        return new Promise((resolve) => {
          if (
            $scope.adviserOrgStatus &&
            !_.isUndefined($scope.formModel.adviser.FamilyId) &&
            $scope.formModel.adviser.FamilyId !== 0
          ) {
            let brandId = 0;
            let orgname = '';
            switch ($scope.selectedAgency) {
              case 5: // Wealth Market
                brandId = 3;
                orgname = 'Wealth Market';
                break;
              case 7: // Loan Market
                brandId = 1;
                orgname = 'Loan Market';
                break;
              default:
                break;
            }
            sendReferralService
              .referralAdviserOrgList(
                brandId,
                $scope.formModel.adviser.FamilyId,
              )
              .then((response) => {
                const returnVal = {
                  adviserId: $scope.formModel.adviser.FamilyId,
                  hasAgreement: false,
                };
                $scope.formModel.adviser.hasAgreement = false;
                if (_.size(response.data) > 0) {
                  $scope.formModel.adviser.hasAgreement = true;
                  returnVal.hasAgreement = true;
                  $scope.sendTo.adviserOrgList = response.data;
                  $scope
                    .corpContactGetInfo(
                      $scope.formModel.adviser.FamilyId,
                      $scope.selectedAdviserInformation.PersonId,
                    )
                    .then((corpContactGetInfoResponse) => {
                      if (corpContactGetInfoResponse.status === 'success') {
                        $scope.corpContactInfo =
                          corpContactGetInfoResponse.data;
                        const tempOrg = _.filter(
                          $scope.sendTo.adviserOrgList,
                          (value) => {
                            return (
                              value.AdviserOrgId ===
                              $scope.corpContactInfo.AdviserOrganizationId
                            );
                          },
                        );
                        if (_.size(tempOrg) > 0) {
                          $scope.selectedAdviserOrg = _.first(
                            tempOrg,
                          ).AdviserOrgId;
                          $scope.formModel.OfficeID = $scope.selectedAdviserOrg;
                          // populate send to dropdown based on org
                          getAdviserUnderOrg($scope.selectedAdviserOrg);
                        }
                      }
                    });
                } else {
                  // make form invalid
                  $scope.formModel.Postal = null;
                  $scope.formModel.adviser.Postal = null;
                  $scope.sendTo.adviserOrgList = [];
                  $scope.adviserUnderOrgList = [];
                  returnVal.hasAgreement = false;
                  $scope.formModel.adviser.hasAgreement = false;
                  $scope.adviserOrgStatus = false;
                  const objParams = { orgname };
                  errorMessageHandler.sendReferralError(
                    'adviserAgreement',
                    objParams,
                  );
                }
                resolve({
                  status: 'success',
                  data: returnVal,
                });
              });
          }
        });
      };
      const adviserPostalError = () => {
        $scope.formModel.errorObj.hasError = true;
        $scope.formModel.errorObj.errorInstance = 'adviserPostal';
        errorMessageHandler.sendReferralError('adviserPostal');
      };
      const getAdviserInfo = (advId) => {
        return new Promise((resolve) => {
          clientInformGet(advId).then((response) => {
            if (response.status === 'error') {
              errorMessageHandler.sendReferralError(response.data);
            } else if (response.data.length > 0) {
              $scope.selectedAdviserInformation = _.first(response.data);
              const advInfo = _.first(response.data);
              if ($scope.selectedAgency === 5 || $scope.selectedAgency === 7) {
                // Get Adviser (Lead Information)
                corporateService
                  .leadsProfileGet(advId, advInfo.PersonId)
                  .then((leadsProfileGetResponse) => {
                    const leadProfile = leadsProfileGetResponse.data;
                    $scope.formModel.IsAutoAllocation =
                      leadProfile.WMReferralAlloc;
                    // eslint-disable-next-line sonarjs/no-extra-arguments
                    getReferralOrgList('getAdviserInfo').then(
                      (getReferralOrgListResponse) => {
                        if (getReferralOrgListResponse.status === 'success') {
                          const data = getReferralOrgListResponse.data;
                          if (data.hasAgreement) {
                            // for WM - Selectd Adviser should
                            // have a valid Address PostCode if AutoAllocation = true
                            if ($scope.formModel.IsAutoAllocation) {
                              corporateService
                                .familyAddressGet(data.adviserId)
                                .then((familyAddressGetResponse) => {
                                  const addresses =
                                    familyAddressGetResponse.data;
                                  if ($scope.formModel.errorObj) {
                                    $scope.formModel.errorObj.hasError = false;
                                    $scope.formModel.errorObj.errorInstance =
                                      '';
                                  } else {
                                    $scope.formModel.errorObj = {};
                                  }
                                  if (addresses.length > 0) {
                                    let hasPostal = false;
                                    _.forEach(addresses, (address) => {
                                      if (address.isBusiness) {
                                        hasPostal =
                                          !_.isNil(address.postal_code) &&
                                          !_.isEmpty(address.postal_code);
                                        $scope.formModel.Postal =
                                          address.postal_code;
                                        $scope.formModel.adviser.Postal =
                                          address.postal_code;
                                      }
                                    });
                                    if (!hasPostal) {
                                      adviserPostalError();
                                    }
                                  } else {
                                    adviserPostalError();
                                  }
                                });
                            } else {
                              $scope.formModel.Postal = 0;
                              $scope.formModel.adviser.Postal = 0;
                            }
                          }
                        }
                      },
                    );
                    resolve({
                      status: 'success',
                      data: {
                        adviserInfo: advInfo,
                        leadProfile,
                      },
                    });
                  });
              } else {
                resolve({
                  status: 'success',
                  data: {
                    adviserInfo: advInfo,
                  },
                });
              }
            } else {
              resolve({
                status: 'error',
                data: 'adviserInfoFetch-error',
              });
            }
          });
        });
      };

      const getAdviserInformation = (adviserId) => {
        getAdviserInfo(adviserId).then((response) => {
          $scope.adviserOrgStatus = true;
          if (response && response.status === 'success' && response.data) {
            if (response.data.adviserInfo) {
              $scope.selectedAdviserInformation = response.data.adviserInfo;
              if (
                parseInt($scope.userInfo.CountryId, 10) ===
                  COUNTRY_TYPE.NEW_ZEALAND &&
                $scope.selectedAgency !== REFERRAL_TYPE.OTHER
              ) {
                $scope.formModel.SendTo_FirstName =
                  response.data.adviserInfo.FirstName;
                $scope.formModel.SendTo_LastName =
                  response.data.adviserInfo.LastName;
                if (
                  response.data.adviserInfo.Email &&
                  response.data.adviserInfo.Email.length
                ) {
                  $scope.formModel.SendTo_Email =
                    response.data.adviserInfo.Email[0].EmailAddress;
                }
              }
              if ($scope.selectedAgency === REFERRAL_TYPE.OTHER) {
                $scope.formModel.AdviserName = `${response.data.adviserInfo.FirstName} ${response.data.adviserInfo.LastName}`;
              }
              parseInt($scope.userInfo.CountryId, 10) ===
                COUNTRY_TYPE.NEW_ZEALAND && $scope.blankSendToForOther();
            }
            if (response.data.leadProfile) {
              $scope.leadProfile = response.data.leadProfile;
            }
          }
        });
      };
      const selectAgency = (obj) => {
        $scope.selectedAgency = parseInt(obj, 10);
        getFormModel($scope.selectedAgency);
        if ($scope.formModel.AdviserID) {
          $scope.adviserOrgStatus = true;
          getAdviserInformation($scope.formModel.AdviserID);
        }
        if ($scope.formModel.OfficeID) {
          $scope.selectAdvOrg($scope.formModel.OfficeID);
        }
        const validToGetNextGenLoans =
          $scope.contactFamilyID &&
          $scope.showWealthMarketLoanApps &&
          $scope.selectedAgency === REFERRAL_TYPE.WEALTH_MARKET;
        validToGetNextGenLoans &&
          getNextGenLoanList({
            $scope,
            contactService,
            loanAppSharedData,
            familyId: $scope.contactFamilyID,
          });
      };
      const selectReferralInfo = (refInfo) => {
        switch (refInfo) {
          case 'house':
            $scope.formModel.HouseAppraisal = true;
            $scope.formModel.PropertyManagement = false;
            $scope.formModel.Buyer = false;
            break;
          case 'property':
            $scope.formModel.HouseAppraisal = false;
            $scope.formModel.PropertyManagement = true;
            $scope.formModel.Buyer = false;
            break;
          case 'buyer':
            $scope.formModel.HouseAppraisal = false;
            $scope.formModel.PropertyManagement = false;
            $scope.formModel.Buyer = true;
            break;
          default:
            break;
        }
      };
      const limitChar = (value) => {
        if (!_.isUndefined($scope.contact.Addresses)) {
          $scope.contact.Addresses[0].postal_code = value;
        } else {
          $scope.contact = {
            Addresses: [
              {
                postal_code: value,
              },
            ],
          };
        }
        if (!_.isUndefined(value) && value.length > 5) {
          $scope.formModel.Postal = value.slice(0, 5);
          toaster.pop('error', 'Limit your Postal Code to 5 digits.');
        }
      };

      const searchContacts = (searchstring, searchType) => {
        if (searchstring && searchstring.length > 2) {
          $scope.isSearchingContacts = true;
          $scope.isShowSearchedContactResult = false;
          $timeout.cancel($scope.searchTimeout);
          const partyType = parseInt(searchType, 10);
          $scope.searchTimeout = $timeout(() => {
            $scope.searchString = searchstring;
            sendReferralService
              .searchClientGet(searchstring, partyType)
              .then((response) => {
                $scope.contactsList = [];
                $scope.isSearchingContacts = false;
                $scope.isShowSearchedContactResult = true;
                if (response.data && response.data.length) {
                  $scope.contactsList = response.data;
                }
              });
          }, 300);
          $scope.$on('$destroy', () => {
            $timeout.cancel($scope.searchTimeout);
          });
        }
        $scope.isContactSelected = false;
      };

      const showOffices = () => {
        $scope.form.displayItems = !$scope.form.displayItems;
      };

      const selectRayWhiteOffice = (office) => {
        $scope.formModel.OfficeID = office.id;
        sendReferralService
          .rayWhiteAdvisersGet(office.id, 10000, 0)
          .then((response) => {
            $scope.rayWhiteAdvisers = response.data;
          });
        $scope.form.selectedRayWhiteOffice = office.name;
        $scope.formModel.OfficeName = office.name;
        showOffices();
      };

      const selectRayWhiteAdvisers = (adviser) => {
        if (adviser) {
          $scope.sendTo = adviser;
        }
        $scope.formModel.SendToID = $scope.sendTo.id;
        $scope.formModel.SendToFullName = $scope.sendTo.name;
      };

      $scope.searchClients = function (searchstring) {
        if (_.size(searchstring) >= 3) {
          $scope.isSearchingContacts = true;
          $scope.isShowSearchedContactResult = false;
          // Delay for more input
          $timeout(() => {
            sendReferralService
              .searchClientGet(searchstring)
              .then((response) => {
                $scope.contactsList = response.data;
                $scope.isSearchingContacts = false;
                $scope.isShowSearchedContactResult = true;
              });
          }, 500);
        }
      };
      const wmProcessor = (processType, contact) => {
        clientInformGet($scope.selectedContact.FamilyID).then((response) => {
          if (response.status === 'error') {
            errorMessageHandler.sendReferralError(response.data);
          } else if (processType === 'contact') {
            let hasContact = false;
            // detect contact info details
            _.forEach(response.data, (i) => {
              if (i.PersonId === $scope.selectedContact.ClientEntityId) {
                // check for phone
                _.forEach(i.Phone, (p) => {
                  if (p.Number) {
                    hasContact = true;
                    $scope.formModel.clientContact = p.Number;
                  }
                });
                // check for email
                _.forEach(i.Email, (e) => {
                  if (e.EmailAddress) {
                    hasContact = true;
                    $scope.formModel.clientContact = e.EmailAddress;
                  }
                });
              }
            });
            if (!hasContact && !_.isUndefined($scope.formModel.AdviserID)) {
              // make the form invalid
              $scope.isEmailContactValid = false;
              $scope.formObj.$valid = false;
              $scope.formObj.$invalid = true;
              $scope.formModel.Postal = null;
              $scope.formModel.clientContact = null;
              errorMessageHandler.sendReferralError(
                // eslint-disable-next-line sonarjs/no-duplicate-string
                'referral-invalid-contactDetail',
              );
            } else {
              // make the form valid
              $scope.isEmailContactValid = true;
              $scope.formObj.$valid = true;
              $scope.formObj.$invalid = false;
              $scope.formModel.Postal = 0;
              $scope.formModel.clientContact =
                $scope.formModel.clientContact || 'hasContact';
              if (
                !_.isUndefined($scope.formModel.AdviserID) &&
                $scope.formModel.AdviserID !== 0
              ) {
                // eslint-disable-next-line sonarjs/no-extra-arguments
                getAdviserInfo($scope.formModel.AdviserID, 'select-contact');
              } else {
                $scope.getAdviserLeadInfo(contact.FamilyID, 'select-contact');
              }
            }
          } else {
            $scope.formModel.FirstName = _.first(response.data).FirstName;
            $scope.formModel.clientContact = 'hasContact';
          }
        });
      };

      const getAssignedAdvisersList = (familyId) => {
        mycrmLookupService
          .getAdvisers({ familyId, searchSameAdviserOrg: 0 })
          .then((response) => {
            if (response && response.length) {
              $scope.existingContactAdviserList = response.reduce(
                (acc, cur) => {
                  const adviser = formatAdviser(cur);
                  return adviser.FullName ? [...acc, adviser] : acc;
                },
                [],
              );
            } else {
              toaster.pop('error', 'Did not find any adviser');
            }
          });
      };

      $scope.closeSuggestedContacts = () => {
        $scope.isSearchingContacts = false;
        $scope.isShowSearchedContactResult = false;
      };

      const selectContact = (contact) => {
        setIsShowClientContactDetails({ $scope });
        toggleLoader({ $scope, value: contact });
        $scope.isSearchingContacts = false;
        $scope.isShowSearchedContactResult = false;
        $scope.selectedContact = contact;
        $scope.formModel.IsAutoAllocation = false;
        const isUserHasChosenContact =
          !!$scope.selectedContact && !!$scope.selectedClientName;
        // reset for orglisting
        $scope.adviserOrgStatus = true;
        const isAssistant =
          $scope.currentUserInfo.AccessType === ACCESS_TYPE.ADMIN_ASSISTANT;
        $scope.selectedContactFamilyId = contact.FamilyID;
        if (typeof $scope.reloadExistingAddress === 'function') {
          $scope.reloadExistingAddress(contact.FamilyID);
        }
        contactService
          .contactFamilyInfoGet(contact.FamilyID)
          .then((familyResponse) => {
            if (!familyResponse || !familyResponse.data) {
              toaster.pop(
                'error',
                'Selected Contact',
                'There was an error in fetching selected contact information.',
              );
              return;
            }
            $scope.contactFamilyID = contact.FamilyID;
            if (contact.IsClient) {
              $scope.emailPhoneValidation(
                contact.FamilyID,
                contact.ClientEntityId,
              );
              return familyResponse.data;
            }
            return companyEmailPhoneValidation({
              $scope,
              contactService,
              contactModelService,
              currentUserService,
              familyId: contact.FamilyID,
              familyData: familyResponse.data,
            });
          })
          .then((data) => {
            if (
              $scope.currentUserInfo.isCorp ||
              isAssistant ||
              currentUserService.isNZ
            ) {
              $scope.formModel.AdviserID = data.BrokerID;
            }
            if ($scope.selectedAgency === REFERRAL_TYPE.F_AND_G) {
              $scope.selectAdviser(data.BrokerID, true);
            }
            const value =
              isUserHasChosenContact || $scope.isClientSearchReadOnly;
            if ($scope.currentUserInfo.isCorp || isAssistant) {
              $scope.formModel.adviser = {
                FamilyId: data.BrokerID,
                FullName: data.AdviserFullName,
              };
              $scope.formModel.Email = data.MainEmail;
              $scope.formModel.Mobile = data.BusinessPhone;
              if (
                $scope.selectedAgency === REFERRAL_TYPE.WEALTH_MARKET ||
                $scope.selectedAgency === REFERRAL_TYPE.LOAN_MARKET
              ) {
                getAdviserInfo($scope.formModel.AdviserID).then(
                  (getAdviserInfoResponse) => {
                    if (getAdviserInfoResponse.status === 'error') {
                      errorMessageHandler.sendReferralError(
                        getAdviserInfoResponse.data,
                      );
                    } else {
                      if (getAdviserInfoResponse.data) {
                        const adviserInfo =
                          getAdviserInfoResponse.data.adviserInfo;
                        if (adviserInfo) {
                          $scope.formModel.adviser.FullName =
                            adviserInfo.FullName;
                        }

                        $scope.showWealthMarketLoanApps &&
                          getNextGenLoanList({
                            $scope,
                            contactService,
                            loanAppSharedData,
                            familyId: $scope.contactFamilyID,
                          });
                        $scope.showWealthMarketLoanApps &&
                          setIsShowClientContactDetails({
                            $scope,
                            value: !!$scope.showWealthMarketLoanApps,
                          });
                      }
                      // todo: create wm-processor method and route this to it
                      wmProcessor('contact', contact);
                    }
                  },
                );
              }
              setIsShowClientContactDetails({ $scope, value });
            } else if (
              $scope.selectedAgency === REFERRAL_TYPE.WEALTH_MARKET ||
              $scope.selectedAgency === REFERRAL_TYPE.LOAN_MARKET
            ) {
              $scope.formModel.adviser = {
                FamilyId: $scope.formModel.AdviserID,
              };
              getAdviserInfo($scope.formModel.AdviserID).then((response) => {
                if (response.status === 'error') {
                  errorMessageHandler.sendReferralError(response.data);
                } else {
                  if (response.data) {
                    const adviserInfo = response.data.adviserInfo;
                    if (adviserInfo) {
                      $scope.formModel.adviser.FullName = adviserInfo.FullName;
                    }

                    $scope.showWealthMarketLoanApps &&
                      getNextGenLoanList({
                        $scope,
                        contactService,
                        loanAppSharedData,
                        familyId: $scope.contactFamilyID,
                      });
                    $scope.showWealthMarketLoanApps &&
                      setIsShowClientContactDetails({
                        $scope,
                        value: !!$scope.showWealthMarketLoanApps,
                      });
                  }
                  wmProcessor('contact', contact);
                }
              });
            } else if ($scope.selectedAgency === REFERRAL_TYPE.F_AND_G) {
              if (data) {
                $scope.formModel.Email = data.MainEmail;
                $scope.formModel.Mobile = data.BusinessPhone;
                $scope.checkForValidationPhoneOnly($scope.formModel.Mobile);
              } else {
                toaster.pop(
                  'error',
                  'Selected Contact',
                  'There was an error in fetching selected contact information.',
                );
              }
              setIsShowClientContactDetails({ $scope, value });
            } else {
              $scope.formModel.Postal =
                $scope.formModel.Postal !== '' ? $scope.formModel.Postal : 0;
              setIsShowClientContactDetails({ $scope, value });
            }
          });

        if ($scope.currentUserInfo.AccessType !== ACCESS_TYPE.ADMIN_ASSISTANT) {
          getAssignedAdvisersList(contact.FamilyID);
        }

        $scope.isShowSearchedContactResult = false;
        $scope.selectedClientName = `${contact.FirstName} ${contact.LastName}`;
        $scope.formModel.FamilyID = contact.FamilyID;
        $scope.formModel.ClientID = parseInt(contact.ClientEntityId, 10);
        $scope.formModel.FirstName = contact.FirstName;
        $scope.formModel.LastName = contact.LastName;

        if ($scope.selectedAgency !== 1) {
          $scope.formModel.AgentName = contact.selectedClientName;
        }
        $scope.isContactSelected = true;
      };

      $scope.emailPhoneValidation = (FamilyID, ClientEntityId) => {
        contactService
          .clientInformGet(FamilyID, ClientEntityId)
          .then((response) => {
            $scope.clientData = response.data[0];
            if (!$scope.clientData) {
              return;
            }
            emailAndPhoneValidation($scope);
            formatClientInfoForKiwi({ $scope });
          });
      };
      $scope.toggleEdit = (type, isEditActive) => {
        $scope.contactDetailList = $scope.contactDetailList.map((contact) => {
          if (contact.Type === type) {
            contact.isEditActive = !isEditActive;
          }
          contact.CountryCode = _.get(
            contact,
            'CountryCode',
            currentUserService.countryCode,
          ).toLowerCase();
          return contact;
        });
      };
      $scope.isEmailType = (type) => {
        return type === 'Email' || type === 'WorkEmail';
      };
      const separateTypes = (contact) => {
        const model = { Email: [], Phone: [] };

        contact.forEach((data) => {
          if ($scope.isEmailType(data.Type)) {
            model.Email.push(data);
          } else {
            model.Phone.push(data);
          }
        });

        return model;
      };

      $scope.updateClientContactDetails = (
        type,
        contactValue,
        numberType = 'mobile',
      ) => {
        const isValid =
          (type === 'number' && $scope.isIntlPhoneNumbers) ||
          isValidContactDetail({ $scope, type, contactValue, numberType });
        if (!isValid) {
          return;
        }

        const clearedAndSeparatedTypes = separateTypes([
          ...$scope.contactDetailList,
        ]);
        $scope.clientData = {
          ...$scope.clientData,
          ...clearedAndSeparatedTypes,
        };
        const finalData = $scope.clientData.PersonId
          ? [$scope.clientData]
          : $scope.clientData;
        const updateMethod = $scope.clientData.PersonId
          ? 'addContact'
          : 'organisationInfoSet';
        const requestData = $scope.clientData.PersonId
          ? finalData
          : setupNewPostURL(finalData, 'organisation');
        contactService[updateMethod](requestData)
          .then(() => {
            if (!contactValue && type) {
              toaster.pop(
                'success',
                'Deleted',
                `${
                  type === 'number' ? 'Phone Number' : 'Email'
                } successfully deleted`,
              );
            } else {
              toaster.pop('success', 'Saved', 'Contact successfully updated');
            }
            selectContact($scope.selectedContact);
          })
          .catch((error) => {
            toastError(error.data.Message);
          });
      };

      $scope.checkForValidationMsg = (email, phone) => {
        if (!email || !phone) {
          $scope.isEmailContactValid = false;
          errorMessageHandler.sendReferralError(
            'referral-invalid-contactDetail',
          );
        } else {
          $scope.isEmailContactValid = true;
        }
      };
      $scope.checkForValidationPhoneOnly = (phone) => {
        if (!phone) {
          $scope.isEmailContactValid = false;
          errorMessageHandler.sendReferralError(
            'referral-invalid-contactDetail',
          );
        } else {
          $scope.isEmailContactValid = true;
        }
      };

      if (
        $scope.xeAutoSend &&
        $scope.xeAutoSend.familyId &&
        $scope.xeAutoSend.clientId
      ) {
        contactService
          .clientInformGet(
            $scope.xeAutoSend.familyId,
            $scope.xeAutoSend.clientId,
          )
          .then((response) => {
            const client = response.data[0];
            if (!client) {
              return;
            }

            const getEmail =
              _.find(client.Email, (email) => email.Type === 'Email') || {};
            const getMobile =
              _.find(client.Phone, (phone) => phone.Type === 'Mobile') || {};

            $scope.formModel.Email = getEmail.EmailAddress || '';
            $scope.formModel.Mobile = getMobile.Number || '';

            const data = {
              BrokerEventID: 0,
              ClientEntityId: client.PersonId,
              FamilyID: parseInt(client.FamilyId, 10),
              FirstName: client.FirstName,
              LastName: client.LastName,
              PreferedName: client.PreferredName,
            };
            $scope.formModel.Notes = `${parseFloat(
              $scope.xeAutoSend.value,
            )} / ${$scope.xeAutoSend.currencyCode}`;
            $scope.selectContact(data);
          });
      }

      const nameSelect = () => {
        const fn =
          _.size($scope.formModel.FirstName) > 0
            ? $scope.formModel.FirstName
            : '';
        const ln =
          _.size($scope.formModel.LastName) > 0
            ? $scope.formModel.LastName
            : '';

        $scope.selectedClientName = (fn !== '' ? `${fn} ` : '') + ln;
      };
      // Get Adviser (Lead Information)
      $scope.getAdviserLeadInfo = function (familyID, caller) {
        contactService.getContactAssignedAdviser(familyID).then((response) => {
          if (
            parseInt(response.data, 10) !== 0 ||
            response.data !== undefined
          ) {
            const advId = response.data;
            // Get Adviser Information
            // eslint-disable-next-line sonarjs/no-extra-arguments
            getAdviserInfo(advId, caller);
          } else {
            toaster.pop('error', 'Error No assigned adviser for this client');
          }
        });
      };
      // select Assigned Adviser
      $scope.selectAdviser = function (familyId, isContactSelected) {
        if (!isContactSelected) {
          $scope.formModel.AgentName = '';
          $scope.selectedClientName = '';
          setIsShowClientContactDetails({ $scope });
        }
        const adviserId = familyId || $scope.formModel.AdviserID;
        if (familyId && $scope.filteredAdviserList) {
          const filtered = $scope.filteredAdviserList.filter((adviser) => {
            return parseInt(adviser.FamilyId, 10) === parseInt(familyId, 10);
          });
          if (filtered && filtered.length) {
            $scope.formModel.adviser = filtered[0];
          }
        }
        if (adviserId) {
          getAdviserInformation(adviserId);
        }
        if (!$scope.isExisting) {
          nameSelect();
        }
      };

      $scope.corpContactGetInfo = function (advId, personId) {
        return new Promise((resolve) => {
          corporateService
            .corporateContactGet(personId, advId)
            .then((response) => {
              if (!_.isUndefined(response.data)) {
                if (response.data) {
                  resolve({
                    status: 'success',
                    data: response.data,
                  });
                }
                // populate Adviser's Organization
              } else {
                toaster.pop(
                  'error',
                  'Error',
                  'Unable to get adviser information.',
                );
              }
            });
        });
      };
      // RayWhite Specific Functionalitis
      // ///////////////////////////////
      const referralAdviserOrgMemberListGet = (refAdvId, refAdvOrgId) => {
        sendReferralService
          .getReferralAdviserOrgMemberList(refAdvId, refAdvOrgId)
          .then((response) => {
            if (!_.isUndefined(response.data)) {
              $scope.adviserUnderOrgList = response.data;
            }
          });
      };
      // select Adviser Organization
      $scope.selectAdvOrg = function (advOrg) {
        // get Send to Advisers List
        const advOrgInt = parseInt(advOrg, 10);
        $scope.formModel.OfficeID = advOrgInt;
        $scope.selectedAdviserOrg = advOrgInt;
        const adviserID =
          $scope.currentUserInfo.AccessType === ACCESS_TYPE.ADMIN_ASSISTANT
            ? $scope.formModel.AdviserID
            : $scope.formModel.adviser.FamilyId;
        referralAdviserOrgMemberListGet(adviserID, $scope.formModel.OfficeID);
      };
      $scope.selectAdviceType = function (selectedAdviceType) {
        $scope.formModel.WMAdviceTypeID = selectedAdviceType.WMAdviceTypeId;
        $scope.formModel.WMAdviceType = selectedAdviceType.WMAdviceTypeDesc;
      };
      // select Adviser Organization
      $scope.selectAdvSendto = function (sendTo) {
        const sendToSelected = _.first(
          _.filter($scope.adviserUnderOrgList, (s) => {
            return (
              s.FamilyID === parseInt(sendTo, 10) ||
              s.AFamilyId === parseInt(sendTo, 10)
            );
          }),
        );
        if (!_.isUndefined(sendToSelected)) {
          $scope.formModel.SendToID =
            sendToSelected.FamilyID || sendToSelected.AFamilyId;
          $scope.formModel.SendToFullName =
            sendToSelected.FullName || sendToSelected.AName;
        } else {
          toaster.pop(
            'error',
            'Send To Adviser',
            'There was error in selecting send to adviser',
          );
        }
      };
      $scope.bestTimeToCall = function (chk) {
        switch (chk) {
          case 1:
            $scope.formModel.BTTC_Morning = true;
            $scope.formModel.BTTC_Afternoon = true;
            $scope.formModel.BTTC_Evening = true;
            break;
          case 2:
          case 3:
          case 4:
          case 8:
            $scope.formModel.BTTC_Anytime = false;
            break;
          default:
            break;
        }
      };
      $scope.selectAddress = function (address) {
        $scope.formModel.Postal = '0';
        // SaveAddress and get returned AddressID
        sendReferralService.addressDetailsSet(address).then((response) => {
          $scope.formModel.AddressID = response.data;
          if ($scope.formModel.AddressID == null) {
            toaster.pop(
              'error',
              'Error Getting Geocoded AddressID',
              'AddressID is returns empty.',
            );
          } else {
            $scope.propAddress = address;
            $scope.searchAddress = false;
          }
        });
      };
      $scope.searchAddresss = function (search) {
        generalService.placeSearch(search).then((respond) => {
          $scope.propAddressList = (respond && respond.data) || [];
          $scope.searchAddress = !!(
            $scope.propAddressList && $scope.propAddressList.length
          );
        });
        const validAddress =
          $scope.propAddress &&
          $scope.propAddress.formatted_address &&
          $scope.formModel &&
          !$scope.formModel.AddressID;
        if (validAddress) {
          $scope.formModel.AddressID = 0;
        }
      };
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.closeModal = () => {
        $uibModalInstance.close('close');
      };

      $scope.saveReferral = function () {
        switch ($scope.selectedAgency) {
          case 1:
            sendReferralService
              .referralRayWhiteSet($scope.formModel)
              .then((response) => {
                if (response.status === 200) {
                  toaster.pop(
                    'success',
                    'Success',
                    "Ray White's Referral has been saved",
                  );
                  $uibModalInstance.close('close');
                } else {
                  $scope.isSendingReferral = false;
                  toaster.pop(
                    'error',
                    'Error',
                    "Ray White's Referral not saved",
                  );
                }
              });
            break;
          case 2:
            sendReferralService
              .referralUKPensionSet($scope.formModel)
              .then((response) => {
                if (response.status === 200) {
                  toaster.pop(
                    'success',
                    'Success',
                    'UK Pension Referral has been saved',
                  );
                  $uibModalInstance.close('close');
                } else {
                  $scope.isSendingReferral = false;
                }
              });
            break;
          case 3:
            // eslint-disable-next-line sonarjs/no-collapsible-if
            if (
              !$scope.isExisting &&
              parseInt($scope.userInfo.CountryId, 10) !==
                COUNTRY_TYPE.NEW_ZEALAND
            ) {
              if (!$scope.formModel.Email && !$scope.formModel.Mobile) {
                swal(
                  'Invalid Field',
                  'Contact Email or Mobile is Empty.',
                  'error',
                );
                $scope.isClickedSendBtn = false;
                return false;
              }
            }
            $scope.formModel.Mortgage = $scope.formModel.Mortgage || '';

            $scope.hasSpecificReferralInfo =
              $scope.formModel.House ||
              $scope.formModel.Vehicle ||
              $scope.formModel.Contents ||
              $scope.formModel.Farm ||
              $scope.formModel.Boats ||
              $scope.formModel.Business ||
              $scope.formModel.Travel;

            $scope.validateHouseInput =
              ($scope.formModel.House && !!$scope.formModel.Mortgage) ||
              !$scope.formModel.House;

            $scope.isClickedSendBtn = true;
            if (!$scope.validateHouseInput) {
              toastError('Please input mortgagee');
              $scope.isClickedSendBtn = false;
              return;
            }
            $scope.finalValidation =
              $scope.hasSpecificReferralInfo && $scope.validateHouseInput;

            if ($scope.finalValidation) {
              sendReferralService
                .referralFnGSet($scope.formModel)
                .then((res) => {
                  if (res.succeeded) {
                    toastSuccess('F&G Referral has been saved');
                    $uibModalInstance.close('close');
                  } else {
                    $scope.formModel.Mortgage = $scope.formModel.Mortgage || '';
                    $scope.isClickedSendBtn = false;
                    res.messages.forEach((message) => {
                      toastError(message);
                    });
                  }
                });
            } else {
              $scope.isClickedSendBtn = false;
              toastError('Please select Specific Referral Info');
            }
            break;
          case 4:
            sendReferralService
              .referralOFXSet($scope.formModel)
              .then((response) => {
                if (response.status === 200) {
                  toaster.pop(
                    'success',
                    'Success',
                    'OFX Referral has been saved',
                  );
                  $uibModalInstance.close('close');
                }
              });
            break;
          case 5:
            // please make sure pass in  null for SendToID and SendToFullName if it is auto allocated
            if (
              typeof $scope.leadProfile !== 'undefined' &&
              typeof $scope.leadProfile.WMReferralAlloc !== 'undefined' &&
              $scope.leadProfile.WMReferralAlloc
            ) {
              $scope.formModel.SendToFullName = null;
              $scope.formModel.SendToID = null;
            }
            if (
              typeof $scope.leadProfile !== 'undefined' &&
              typeof $scope.leadProfile.WMReferralAlloc !== 'undefined' &&
              !$scope.leadProfile.WMReferralAlloc &&
              parseInt($scope.formModel.SendToID, 10) === 0
            ) {
              swal(
                'Required Issue',
                'Send To is required please select person.',
              );
              $scope.isClickedSendBtn = false;
              return false;
            }
            $scope.formModel.IsAutoAllocation =
              $scope.leadProfile.WMReferralAlloc;
            if ($scope.showWealthMarketLoanApps) {
              formSetDataForWealthMarket({ $scope });
              if (
                !$scope.formModel.NextGenLoans.length &&
                $scope.nextGenLoanList.length
              ) {
                alertError(
                  'Please select loan application to be shared',
                  false,
                  'Sorry',
                );
                $scope.isClickedSendBtn = false;
                return;
              }
            }
            sendReferralService
              .referralWealthMarketSet($scope.formModel)
              .then((response) => {
                if (response.status === 200) {
                  toaster.pop(
                    'success',
                    'Success',
                    'Wealth Market Referral has been saved',
                  );
                  $uibModalInstance.close('close');
                }
              });
            break;
          case 6: {
            const postDataObj = _.omit($scope.formModel, ['adviser', 'Postal']);
            sendReferralService
              .referralOtherSet(postDataObj)
              .then((response) => {
                if (response.status === 200) {
                  toaster.pop(
                    'success',
                    'Success',
                    'Other Referral has been saved',
                  );
                  $uibModalInstance.close('close');
                }
              });
            break;
          }
          case 7:
            sendReferralService
              .referralLoanMarketSet($scope.formModel)
              .then((response) => {
                if (response.status === 200) {
                  toaster.pop(
                    'success',
                    'Success',
                    'Loan Market Referral has been saved',
                  );
                  $uibModalInstance.close('close');
                }
              });
            break;
          case 8:
            sendReferralService.referralXESet($scope.formModel).then(() => {
              toaster.pop('success', 'Success', 'XE Referral has been saved');
              if ($scope.xeAutoSend) {
                const {
                  loanAppId,
                  familyId,
                  sourceFamilyId,
                } = $scope.xeAutoSend;
                let state;
                let params;
                if (loanAppId) {
                  state = 'app.loanApplicationDetailsV3';
                  params = {
                    familyId: sourceFamilyId || familyId,
                    loanAppId,
                  };
                  $window.sessionStorage.setItem(
                    'loanApplicationSection',
                    'financialPosition',
                  );
                  $window.sessionStorage.setItem(
                    'loanApplicationPanel',
                    'loanAppFinancial',
                  );
                } else {
                  state = 'app.contactsSingle';
                  params = {
                    familyId,
                    activeTab: 'financials',
                  };
                }
                $state.go(state, params);
              }
              $uibModalInstance.close('close');
            });
            break;
          case REFERRAL_TYPE.ASSETS_FINANCE:
            $scope.formModel = {
              ...$scope.formModel,
              LoanAmountRequired: parseFloat(
                $scope.formModel.LoanAmountRequired,
              ),
              Deposit: parseFloat($scope.formModel.Deposit),
            };
            referralService
              .referralAssetsFinanceSet($scope.formModel)
              .then((response) => {
                if (response.status === 200) {
                  toaster.pop(
                    'success',
                    'Success',
                    'Assets Finance Referral has been saved',
                  );
                  $uibModalInstance.close('close');
                }
              });
            break;
          default:
            toaster.pop('warning', 'Warning!', 'Unable to save entries');
            break;
        }
      };

      $scope.sendReferral = (form) => {
        if ($scope.selectedAgency) {
          $scope.isClickedSendBtn = true;
        }
        saveAddress({ $scope, addressLookupService, generalService })
          .then(() => {
            $scope.saveReferral(form);
          })
          .catch(() => {
            $scope.isClickedSendBtn = false;
          });
      };

      const phoneValidation = (data) => {
        return (
          (!$scope.isEmailType(data.Type) &&
            ((data.Number &&
              data.Type !== 'Mobile' &&
              !$scope.isPhoneNumberCountryCodeNZ &&
              isMobileValid(data.Number)) ||
              /\+?\d+\n?X{3}\n?\d+/g.test(data.Number))) ||
          (data.Number &&
            $scope.isPhoneNumberCountryCodeNZ &&
            data.Type === 'Mobile' &&
            isMobileNumberValid(data.Number))
        );
      };

      const checkIfHasAtLeastOneContactNumber = () => {
        if (!$scope.contactDetailList || !$scope.contactDetailList.length) {
          return;
        }
        return (
          $scope.contactDetailList.filter((data) => phoneValidation(data))
            .length >= 1
        );
      };
      $scope.isSendingReferral = (form) => {
        const contactSelection =
          !$scope.isContactSelected &&
          contactType === REFERRAL_CONTACT_TYPE.EXISTING;
        const contactEmailSelection =
          !$scope.isEmailContactValid &&
          contactType === REFERRAL_CONTACT_TYPE.EXISTING;

        return (
          $scope.isClickedSendBtn ||
          !form.$valid ||
          contactSelection ||
          contactEmailSelection ||
          (!checkIfHasAtLeastOneContactNumber() && $scope.isExisting)
        );
      };

      // assign methods to scope
      $scope.selectReferralInfo = selectReferralInfo;
      // init
      // METHODS
      angular.extend($scope, {
        init,
        selectContactType,
        selectReferralInfo,
        selectAgency,
        limitChar,
        searchContacts,
        showOffices,
        selectRayWhiteOffice,
        selectRayWhiteAdvisers,
        selectContact,
        nameSelect,
      });
      $scope.init();
    });
