import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import {
  modifyDueDateTypes,
  saveTemplateTaskUtil,
} from './util/addEditTaskTemplate';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('AddEditTaskTemplateCtrl', function AddEditTaskTemplateCtrl(
      $scope,
      $uibModalInstance,
      currentUserService,
      contactModelService,
      businessService,
      toaster,
      configService,
    ) {
      const taskTemplateData =
        _.find(
          $scope.taskTemplateDetailList,
          (item) => item.id === $scope.templateId,
        ) || null;
      const init = () => {
        $scope.dueDateTypes = taskTemplateData.list.length
          ? $scope.dueDateTypes
          : modifyDueDateTypes($scope.dueDateTypes);
      };
      $scope.taskSet = {
        DueDate: moment().format('D MMM YYYY h:mma'),
        FirstName: '',
        LastName: '',
        DueDateOptionID: '1',
        IsAutoAssigned: true,
      };
      if (!$scope.taskId) {
        $scope.taskSet.AssignedAdvisorID = $scope.activeUserId;
      }
      $scope.otherTemplateTasks = [];
      $scope.taskAutomationTaskDetailsFeatureFlag = !!configService.feature
        .taskAutomationTaskDetails;

      let taskSettingData = {};

      if (taskTemplateData && taskTemplateData.list) {
        taskSettingData =
          _.find(
            taskTemplateData.list,
            (item) => item.TaskSettingsID === $scope.taskId,
          ) || {};
        $scope.otherTemplateTasks =
          _.filter(
            taskTemplateData.list,
            (item) => item.TaskSettingsID !== $scope.taskId,
          ) || [];
      }

      const {
        TaskName,
        AssignedAdvisorID,
        IsAutoAssigned,
        DueDate,
        FirstName,
        LastName,
        DueDateOptionID,
        RelativeTaskSettingsID,
        RelativeNoOfDays,
        TaskDetails,
      } = taskSettingData;

      if ($scope.taskId && taskSettingData) {
        $scope.taskSet.Title = TaskName;
        $scope.taskSet.AssignedAdvisorID = AssignedAdvisorID;
        $scope.taskSet.TaskDetails = TaskDetails;
        $scope.taskSet.IsAutoAssigned = AssignedAdvisorID
          ? IsAutoAssigned
          : true;
        $scope.taskSet.DueDate = DueDate;
        $scope.taskSet.FirstName = FirstName;
        $scope.taskSet.LastName = LastName;

        if (DueDateOptionID) {
          $scope.taskSet.DueDateOptionID = DueDateOptionID.toString();
          $scope.taskSet.RelativeTaskSettingsID = RelativeTaskSettingsID;
          $scope.taskSet.RelativeNoOfDays = RelativeNoOfDays;
        }
      }
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.isCorporateUser =
        currentUserService.accessType === ACCESS_TYPE.CORPORATE;
      $scope.isNzBased =
        COUNTRY_TYPE.NEW_ZEALAND === currentUserService.countryId;
      const isLoanWriter =
        $scope.isCorporateUser && $scope.isNzBased ? 0 : null;
      contactModelService
        .taskAdviserListGet(isLoanWriter, null, false, false)
        .then(({ data }) => {
          $scope.taskAdviserList = data;
          $scope.taskAdviserList = _.reject(data, ['BrokerID', 0]);
        });

      const proceedSave = () => {
        $scope.hideMainDiv();
        const postData = {
          TaskSettingsID: $scope.taskId,
          TaskTemplateID: $scope.templateId,
          TaskName: $scope.taskSet.Title,
          AssignedAdvisorID: $scope.taskSet.AssignedAdvisorID,
          IsAutoAssigned: $scope.taskSet.IsAutoAssigned,
          DueDate: $scope.taskSet.DueDate,
          FirstName: $scope.taskSet.FirstName,
          LastName: $scope.taskSet.LastName,
          DueDateOptionID: $scope.taskSet.DueDateOptionID,
          RelativeTaskSettingsID: $scope.taskSet.RelativeTaskSettingsID,
          RelativeNoOfDays: $scope.taskSet.RelativeNoOfDays,
        };

        if ($scope.taskAutomationTaskDetailsFeatureFlag) {
          postData.TaskDetails = $scope.taskSet.TaskDetails;
        }

        businessService.TaskSettingsSet(postData).then(
          (response) => {
            if (parseInt(response.data, 10)) {
              if (postData.TaskSettingsID) {
                toaster.pop('success', 'Updated', 'Task updated successfully');
              } else {
                toaster.pop('success', 'Added', 'Task added successfully');
              }
              $scope.getTaskTemplateListFullDetails();
              $scope.cancel();
            } else {
              toaster.pop('error', 'Error', 'Yikes! Something is wrong');
            }
          },
          () => {
            toaster.pop(
              'error',
              'Error',
              `Could not update Task Settings (Looping)`,
            );
          },
        );
      };

      $scope.saveTemplateTask = function () {
        const dependencies = {
          scope: $scope,
          toaster,
          proceedSave,
        };
        saveTemplateTaskUtil(dependencies);
      };
      init();
    });
