import { COMPANY_TYPES } from 'Common/constants/partyTypes';

class EntityContactDetailsCtrl {
  constructor(
    $stateParams,
    broadcastDataService,
    currentUserService,
    configService,
  ) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.broadcastDataService = broadcastDataService;
    this.currentUserService = currentUserService;
    this.configService = configService;
  }

  handleUpdateAddress({ address }) {
    this.entityData.Address = address;
  }

  $onInit() {
    this.isAddressOverhaulEnabled = this.configService.feature.addressOverhaul;
    this.familyId = this.$stateParams.familyId;
    this.phoneMask = this.broadcastDataService.getPhoneFormat(
      this.currentUserService.countryId,
    );
    this.COMPANY_TYPES = COMPANY_TYPES;
    this.partyType = parseInt(this.partyType, 10);
  }
}

export default EntityContactDetailsCtrl;
