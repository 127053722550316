import template from './topNavbar.html';
import './topNavbar.scss';

export default {
  template,
  bindings: {
    uiService: '<',
    currentUser: '<',
    configService: '<',
    isEnvNotifDismissed: '<',
    brandingCategoryType: '<',
    isOnboarding: '<',
    viewOfFileModalService: '<',
    isProductionBanner: '&',
    onDismiss: '&',
    fetchRecentlyViewed: '&',
    hideNav: '<',
  },
};
