import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('setElementFocus', function setElementFocus($timeout) {
      return function (scope, element, attrs) {
        const setElementFocusFn = (newValue) => {
          const setElementFocusTimeout = $timeout(() => {
            newValue && element.focus();
          });
          scope.$on('$destroy', () => {
            $timeout.cancel(setElementFocusTimeout);
          });
        };
        scope.$watch(attrs.setElementFocus, setElementFocusFn, true);
      };
    });
