import {
  getGoogleMapInstance,
  mapCustomConfig,
  mycrmCustomGMapStyles,
} from 'Common/utilities/gMap';

class GoogleMapService {
  constructor($q, $window, $timeout, $document, configService, $cacheFactory) {
    'ngInject';

    this.$q = $q;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$document = $document;
    this.configService = configService;
    this.$cacheFactory = $cacheFactory;
  }

  cacheGoogleMap() {
    this.cacheGoogleMapService =
      this.get('googleMapServiceCache') ||
      this.$cacheFactory('googleMapServiceCache');
  }

  validateMapOptions(lat = 0, long = 0, type, address) {
    const cacheCall = this.cacheGoogleMapService.get(`${type}_${address}`);
    if ((!lat || !long) && !cacheCall && address) {
      this.cacheGoogleMapService.put(`${type}_${address}`, 1);
      this.googleMapTimer = this.$timeout(() => {
        this.cacheGoogleMapService.remove(`${type}_${address}`);
      }, 3000);
    }
    return lat && long;
  }

  setGoogleMapOptions(options) {
    this.googleMapWindow = getGoogleMapInstance();
    const { latitude, longitude, mapId, address, map } = options;
    const invalidValue =
      !this.googleMapWindow && !address && !mapId && !latitude && !longitude;
    if (invalidValue) {
      return;
    }
    const mapOptions = {
      center: new this.googleMapWindow.LatLng(latitude, longitude),
      styles: mycrmCustomGMapStyles(),
    };
    const mapOverrideOptions = { ...mapOptions, ...map };
    const mapElement =
      this.$document &&
      this.$document.length &&
      // eslint-disable-next-line unicorn/prefer-query-selector
      this.$document[0].getElementById(mapId);
    return new this.googleMapWindow.Map(mapElement, mapOverrideOptions);
  }

  addGMapMarker(map, options) {
    if (!this.googleMapWindow) {
      return;
    }
    const { latitude, longitude, address, marker } = options;
    const markerOptions = {
      map,
      position: new this.googleMapWindow.LatLng(latitude, longitude),
      title: address,
      ...marker,
    };
    return new this.googleMapWindow.Marker(markerOptions);
  }

  addGMapInfoWindow(options) {
    if (!this.googleMapWindow) {
      return;
    }
    const { address, infowindow } = options;
    const infoWindowOptions = { content: address, ...infowindow };
    return new this.googleMapWindow.InfoWindow(infoWindowOptions);
  }

  mapCenterChanged(googleMap, latitude, longitude) {
    googleMap.addListener('center_changed', () => {
      this.googleMapWindow.event.trigger(googleMap, 'resize');
      this.googleMapWindow.event.clearListeners(googleMap, 'center_changed');
      googleMap.setCenter(new this.googleMapWindow.LatLng(latitude, longitude));
    });
  }

  mapCorporate(mapRequiredConfig, overrideMapCustomConfig = mapCustomConfig()) {
    const { latitude, longitude } = mapRequiredConfig;
    const { hasInfoWindow } = overrideMapCustomConfig;
    const invalidValue =
      !this.googleMapWindow && !mapRequiredConfig && !overrideMapCustomConfig;
    if (invalidValue) {
      return;
    }

    const mapConfig = { ...mapRequiredConfig, ...overrideMapCustomConfig };
    const googleMap = this.setGoogleMapOptions(mapConfig);
    const marker = this.addGMapMarker(googleMap, mapConfig);

    if (!hasInfoWindow) {
      return googleMap;
    }
    this.mapCenterChanged(googleMap, latitude, longitude);
    const infoWindow = this.addGMapInfoWindow(mapConfig);
    infoWindow.open(googleMap, marker);
    return googleMap;
  }

  $onDestroy() {
    this.$timeout.cancel(this.googleMapTimer);
  }
}

export default GoogleMapService;
