import {
  ADDRESS_TYPE,
  ADDRESS_TYPE_NAME,
} from 'Common/constants/addressModules';

class PostSettlementAddressModalCtrl {
  constructor(
    $timeout,
    contactService,
    configService,
    timeoutService,
    loanFinancialsService,
  ) {
    'ngInject';

    this.contactService = contactService;
    this.$timeout = $timeout;
    this.configService = configService;
    this.timeoutService = timeoutService;
    this.loanFinancialsService = loanFinancialsService;
  }

  updatePostSettlementAddress() {
    this.isSubmitting = true;
    const updatedAddress = {
      ...this.addressData.postSettlement,
      Type: ADDRESS_TYPE_NAME.CURRENT_ADDRESS,
      TypeId: ADDRESS_TYPE.CURRENT_ADDRESS,
      familyId: this.familyId,
    };
    const previousAddress = {
      ...this.addressData.current,
      Type: ADDRESS_TYPE_NAME.PREVIOUS_ADDRESS,
      TypeId: ADDRESS_TYPE.PREVIOUS_ADDRESS,
    };
    this.contactService
      .contactAddressSet([updatedAddress, previousAddress])
      .then(() => {
        this.showSuccessMessage = true;
        this.isSubmitting = false;
        this.$timeout(() => {
          this.closeModal();
        }, 1000);
        this.loanFinancialsService.showAutoCreatedFinancialsOnSettlement({
          params: {
            familyId: this.familyId,
            loanAppId: this.loanAppId,
          },
        });
      })
      .catch(() => {
        this.isSubmitting = false;
      });
  }

  closeModal() {
    this.modalInstance.close();
  }

  $onInit() {
    this.showSuccessMessage = false;
    this.isSubmitting = false;
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('PostSettlementAddressModalCtrl');
  }
}

export default PostSettlementAddressModalCtrl;
