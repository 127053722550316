import { contact, task, pipeline } from 'Common/default/route';
import { showBusinessPlanningDashboard } from 'Common/utilities/showFeature';
import { DEFAULT_DASHBOARD_TAB } from 'Common/constants/businessPlanningDashboard';
import {
  TIME_GREETINGS_RANGES,
  TIME_GREETINGS_DEFAULT,
} from 'Common/constants/timePeriod';

class MycrmHomeCtrl {
  constructor(
    configService,
    currentUserService,
    TECHNOLOGY,
    $location,
    uiService,
    $rootScope,
    viewOfFileModalService,
  ) {
    'ngInject';

    this.configService = configService;
    this.currentUserService = currentUserService;
    this.technology = TECHNOLOGY;
    this.location = $location;
    this.uiService = uiService;
    this.rootScope = $rootScope;
    this.viewOfFileModalService = viewOfFileModalService;
  }

  $onInit() {
    this.isAngular = this.technology.isAngular;
    this.showNewPipeline =
      !this.isAngular && this.configService.feature.newPipeline;
    const {
      businessPlanningDashboard,
      bidViewOfAFile,
    } = this.configService.feature;
    this.bidViewOfAFile = bidViewOfAFile;
    this.routeBusinessPlanningDashboard = showBusinessPlanningDashboard(
      businessPlanningDashboard,
      this.currentUserService,
    );
    this.dashboardDefaultTab = DEFAULT_DASHBOARD_TAB;
    this.routeParams = {
      contact,
      task,
      pipeline,
    };
    this.getUserGreeting();
    this.isUrlDialogue();
  }

  isUrlDialogue() {
    if (
      this.location.search()['new-application'] &&
      (!this.uiService.isCountry('New Zealand') ||
        (this.uiService.isCountry('New Zealand') &&
          !this.currentUserService.isCorporate))
    ) {
      if (!this.bidViewOfAFile) {
        return this.uiService.openAddNewLoanApplicationModal();
      }
      this.viewOfFileModalService.launchCreateNewFile({ familyId: 0 });
    } else if (
      this.location.search()['adviser-map'] &&
      this.currentUserService.isCorporate &&
      !this.currentUserService.isID
    ) {
      this.rootScope.openModalAdviserMapNearby();
    } else if (
      this.location.search()['customise-theme'] &&
      this.uiService.isShowCustomizeTheme &&
      !this.uiService.isCountry('Indonesia')
    ) {
      this.uiService.customizeTheme();
    }
  }

  getUserGreeting() {
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    const currentTime = parseFloat(`${hours}.${minutes}`, 10);
    const currentUser =
      this.currentUserService.preferredName ||
      this.currentUserService.firstName ||
      'User';
    this.currentTimeGreeting = TIME_GREETINGS_RANGES.reduce(
      (accum, greeting) => {
        if (currentTime >= greeting.start && currentTime <= greeting.end) {
          greeting.userGreeting = `Good ${greeting.value}, ${currentUser}`;
          return greeting;
        }
        return accum;
      },
      TIME_GREETINGS_DEFAULT,
    );
  }
}
export default MycrmHomeCtrl;
