import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('DemoVideoModalCtrl', function DemoVideoModalCtrl(
      $scope,
      $uibModalInstance,
      videoId,
    ) {
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.videoId = videoId;
    });
