import { KEY_CODE } from 'Common/constants/keyCode';

export const handleKeyDownEvent = ({ $timeout, attrs, scope }) => (event) => {
  const allowedKeys = [
    KEY_CODE.WIN_ENT,
    KEY_CODE.SPACE,
    KEY_CODE.ARROW_KEY_DOWN,
  ];
  if (!allowedKeys.includes(event.which)) {
    return;
  }
  if (
    event.which === KEY_CODE.ARROW_KEY_DOWN &&
    typeof attrs.arrowDown === 'undefined'
  ) {
    return;
  }
  scope.eventTimeout = $timeout(() => scope.$eval(attrs.onTrigger));
  event.preventDefault();
};

export const onTriggerInit = ({ scope, element, attrs, $timeout }) => {
  const eventHandler = handleKeyDownEvent({ $timeout, attrs, scope });
  element.on('keydown', eventHandler);
  scope.$on('$destroy', () => {
    element.off('keydown', eventHandler);
    $timeout.cancel(scope.eventTimeout);
  });
};
