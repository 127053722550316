import angular from 'angular';
import mathService from './mathService';
import importantDatesService from './importantDatesService';
import financialsSharedService from './financialsSharedService';
import onboardingDataService from './onboardingDataService';
import opportunityLoanAppService from './opportunityLoanAppService';

export default angular.module('app.services', [
  mathService,
  financialsSharedService,
  onboardingDataService,
  importantDatesService,
  opportunityLoanAppService,
]).name;
