import { getToken } from 'Common/utilities/token';

export const buildCommonHttpHeader = (
  token,
  isAuthorizationOnly = false,
  headers = {},
) => {
  const httpHeaders = isAuthorizationOnly
    ? {}
    : { 'X-SourcePlatform': 'MyCRM', ...headers };
  return {
    Authorization: token,
    ...httpHeaders,
  };
};

export const httpHeaders = {
  common: () => buildCommonHttpHeader(getToken()),
};
