export const COVER_TYPE = {
  HEALTH_COVER: 1,
  LIFE_COVER: 2,
  FAMILY_PROTECTION: 3,
  TRAUMA_COVER: 4,
  TOTAL_PERMANENT_DISABILITY: 5,
  INCOME_PROTECTION: 6,
  MORTGAGE_REPAYMENT: 7,
  REDUNDANCY_COVER: 8,
  WAIVER_OF_PREMIUM: 9,
  WAITING_PERIOD: 10,
  REPAYMENT_PERIOD: 11,
  GENERAL_NOTES: 0,
  TEMPLATE_NOTES_MANAGER: -1,
};

export const BENEFIT_LIST = {
  1: 'HealthCover',
  6: 'IncomeProtection',
  2: 'LifeCover',
  7: 'MortgageRepaymentCover',
  5: 'TotalAndPermanentDisability',
  4: 'TraumaCover',
  [COVER_TYPE.GENERAL_NOTES]: 'Summary Advice Notes',
};

export const FREQUENCY_VALUES = {
  MONTHLY: 12,
  YEARLY: 1,
};

export const ACTIVE_TAB = {
  HEALTH: 'Health',
  INCOME: 'Income',
  TRAUMA: 'Trauma',
  TPD: 'TPD',
  LIFE: 'Life',
  MORTGAGE: 'Mortgage',
};

export const COVER_NOTE_TYPES = {
  healthNote: 'Health Insurance',
  iPNote: 'Income Protection Insurance',
  traumaNote: 'Trauma Insurance',
  tPDNote: 'Total Permanent Disablement Insurance',
  lifeNote: 'Life Insurance',
};
export const GENERAL_PREMIUM = 2;
