import _ from 'lodash';
import { toastError, toastSuccess } from 'Common/utilities/alert';
import { formatDate } from 'Common/utilities/date';

export function generateProductComparisonPDFReportGamePlanFormat({
  productFinderModelService,
  downloadDataService,
  params,
}) {
  if (!params) {
    return;
  }

  return productFinderModelService
    .download(params.brokerEventId, params)
    .then((response) => {
      if (!response || !response.documentContent) {
        toastError();
        return;
      }

      const currentDate = formatDate('YYYY-MM-DD')(new Date());
      const { contentType, documentContent } = response;
      downloadDataService.download(
        `data:${contentType};base64,${documentContent}`,
        `productComparison_${currentDate}.pdf`,
        contentType,
      );

      toastSuccess('Product Comparison downloaded successfully!');
    });
}

export function isTabHeaderForFamilyServiceability(currentSourceLinker = {}) {
  const familyId = _.get(currentSourceLinker, 'familyId');
  const tabName = _.get(
    currentSourceLinker,
    'previousRouteStateParams.tabName',
  );
  return familyId && familyId !== '0' && tabName !== 'details';
}
