import { countryListBuilderForUI } from 'Common/mappers/options';
import { appendEmptySelect } from 'Common/utilities/options';

export const existingAddressBar = (propertyOf) => {
  const NZ = 'New Zealand';
  const AU = 'Australia';
  return {
    isCountryNZ: () => {
      const { isNZ } = propertyOf.currentUserService;
      return isNZ;
    },
    isManualAddressValidations: () => {
      propertyOf.isManualAddressValidationsNZ =
        propertyOf.addressModel.country === NZ &&
        propertyOf.configService.feature.manualAddressValidations;
      propertyOf.isManualAddressValidationsAU =
        propertyOf.addressModel.country === AU &&
        propertyOf.configService.feature.manualAddressValidations;
    },
    populateCountryList: () => {
      propertyOf.optionsService.countryListGet().then(({ data }) => {
        const countryList = data.map(countryListBuilderForUI);
        propertyOf.countryList = appendEmptySelect(countryList, {
          name: 'Please select',
          value: 'Please select',
        });
      });
    },
    clearManualFields: () => {
      if (propertyOf.addressModel) {
        propertyOf.addressModel.street_address = '';
        propertyOf.addressModel.locality = '';
        propertyOf.addressModel.postal_code = '';
        propertyOf.addressModel.stateShort_Name = '';
        propertyOf.addressModel.country = '';
        propertyOf.addressModel.formatted_address = '';
      }
    },
  };
};
