import template from './termTableView.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    termData: '<',
    showFixedRate: '<',
    showInterestOnly: '<',
  },
};
