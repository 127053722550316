import angular from 'angular';
import ProductAndLenderRationale from './productAndLenderRationale';
import ProductAndLenderRationaleHint from './productAndLenderRationaleHint';
import TargetMarketDetermination from './targetMarketDetermination';

export default angular
  .module('components.productAndLenderRationale', [])
  .component('productAndLenderRationale', ProductAndLenderRationale)
  .component('productAndLenderRationaleHint', ProductAndLenderRationaleHint)
  .component('targetMarketDetermination', TargetMarketDetermination).name;
