export const CALCULATION_TYPE = {
  FUNDING_CALCULATOR: 'FundingCalculator',
  SERVICEABILITY: 'Serviceability',
  PRODUCT_FINDER: 'ProductFinder',
  PRODUCT_COMPARISON: 'ProductComparison',
};

export const CALCULATION_TITLE = {
  [CALCULATION_TYPE.FUNDING_CALCULATOR]: 'Funding Calculator',
  [CALCULATION_TYPE.SERVICEABILITY]: 'Serviceability',
  [CALCULATION_TYPE.PRODUCT_FINDER]: 'Product Finder',
  [CALCULATION_TYPE.PRODUCT_COMPARISON]: 'Product Comparison',
};
