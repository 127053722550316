import {
  ADVISER_DEFAULT_TAB,
  ASSISTANT_DEFAULT_TAB,
  CLIENT_DEFAULT_TAB,
} from 'Common/constants/contactTab';
import { toastSuccess } from 'Common/utilities/alert';

export const redirectToContact = ({ $window, $scope, $state }) => {
  toastSuccess('Email Sent Successfully', 'Email Notification');
  const lastTabOpened = $window.sessionStorage.getItem('lastTabOpened');
  if ($scope.emailContactType === 'adviser') {
    $state.go('app.contactsAdviser', {
      familyId: $scope.familyId,
      clientId: 0,
      activeTab: lastTabOpened || ADVISER_DEFAULT_TAB,
    });
  } else if ($scope.emailContactType === 'assistant') {
    $state.go('app.contactsAssistant', {
      familyId: $scope.familyId,
      clientId: 0,
      activeTab: lastTabOpened || ASSISTANT_DEFAULT_TAB,
    });
  } else if ($scope.emailContactType === 'client') {
    $state.go('app.contactsSingle', {
      familyId: $scope.familyId,
      activeTab: lastTabOpened || CLIENT_DEFAULT_TAB,
    });
  }
  $window.sessionStorage.removeItem('lastTabOpened');
};

export const mapAssistantsToDropdown = (assistant) => {
  if (!assistant) {
    return {};
  }

  const { emailAddress: email, familyId, fullName } = assistant;

  return {
    email,
    familyId,
    fullName,
  };
};
