import {
  DEFAULT_VALUES,
  PRIMARY_PURPOSE_VALUE,
} from 'Common/constants/securityValues';

export function assetFinanceIgnore(status) {
  return DEFAULT_VALUES.ASSET_FINANCE_IGNORE_SECURITY_TYPES.includes(
    parseInt(status, 10),
  );
}

export function checkIfInvestment(id) {
  return PRIMARY_PURPOSE_VALUE.INVESTMENT === parseInt(id, 10);
}
