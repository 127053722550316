import { toastError } from 'Common/utilities/alert';

class OnboardingQuestionnaireCtrl {
  constructor($stateParams, onboardingService, $timeout, timeoutService) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.onboardingService = onboardingService;
    this.$timeout = $timeout;
    this.timeoutService = timeoutService;
  }

  getQuestionnaireList(familyId) {
    this.onboardingService.questionaireListGet(familyId).then(({ data }) => {
      if (!data || !data.length) {
        return;
      }
      const list = data.filter((question) => question.IsActive);
      this.questionnaireList =
        list &&
        list.map((question) => {
          question.answer = question.YesOrNoAnswer || 0;
          question.additionalAnswer = question.AdditionalAnswer || '';
          return question;
        });
    });
  }

  getQuestionnaireString(key) {
    const isInvalid =
      !this.questionnaireList || !this.questionnaireList.length || !key;
    if (isInvalid) {
      return '';
    }
    const questionnaireArray = this.questionnaireList.reduce((accum, value) => {
      return [...accum, value[key]];
    }, []);
    return questionnaireArray.join('|');
  }

  setAnswer(question) {
    if (!question.answer) {
      this.setQuestionnaire();
      this.$timeout(() => {
        this.questionnaireField.isEmpty = this.questionnaireForm.$invalid;
      }, 100);
      return;
    }
    this.questionnaireField.isEmpty = this.questionnaireForm.$invalid;
    if (this.questionnaireForm.$invalid) {
      question.answer = question.answer ? 0 : 1;
      this.questionnaireField.isEmpty = true;
    }
  }

  setQuestionnaire() {
    this.questionnaireList &&
      this.questionnaireList.map((questionnaire) => {
        if (questionnaire.answer) {
          return questionnaire;
        }
        questionnaire.additionalAnswer = '';
        return questionnaire;
      });
    const question = this.getQuestionnaireString('QuestionNo');
    const answer = this.getQuestionnaireString('answer');
    const additionalAnswer = this.getQuestionnaireString('additionalAnswer');
    this.onboardingService
      .questionaireAnswersSet(this.familyId, question, answer, additionalAnswer)
      .catch(() => {
        toastError('Error on updating questionnaire.');
      });
  }

  $onInit() {
    this.familyId = this.$stateParams.familyId;
    this.questionnaireForm = {};
    this.getQuestionnaireList(this.familyId);
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('OnboardingQuestionnaireCtrl');
  }
}

export default OnboardingQuestionnaireCtrl;
