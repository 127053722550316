import angular from 'angular';
import targetBasedTile from './targetBasedTile';
import targetSettingsTile from './targetSettingsTile';
import lenderSplitSmall from './lenderSplitSmall';
import widgetGeneralHeader from './widgetGeneralHeader';
import widgetGeneralFooter from './widgetGeneralFooter';
import averageLoanSize from './averageLoanSize';
import settlementVolumes from './settlementVolumes';
import enquirySourceTile from './enquirySourceTile';
import referralsTile from './referralsTile';
import upcomingSettlementDates from './upcomingSettlementDates';
import insurancePipelineStatus from './insurancePipelineStatus';
import insuranceNotifications from './insuranceNotifications';
import esignUsageReport from './esignUsageReport';

export default angular
  .module('dashboardWidget.components', [])
  .component('targetBasedTile', targetBasedTile)
  .component('targetSettingsTile', targetSettingsTile)
  .component('lenderSplitSmall', lenderSplitSmall)
  .component('widgetGeneralHeader', widgetGeneralHeader)
  .component('widgetGeneralFooter', widgetGeneralFooter)
  .component('averageLoanSize', averageLoanSize)
  .component('settlementVolumes', settlementVolumes)
  .component('enquirySourceTile', enquirySourceTile)
  .component('upcomingSettlementDates', upcomingSettlementDates)
  .component('insurancePipelineStatus', insurancePipelineStatus)
  .component('insuranceNotifications', insuranceNotifications)
  .component('esignUsageReport', esignUsageReport)
  .component('referralsTile', referralsTile).name;
