import {
  alertSucess,
  showCustomConfirmation,
  toastError,
} from 'Common/utilities/alert';

export default class FundingDetailsService {
  constructor(
    $uibModal,
    loanAppSharedData,
    loanApplicationServices,
    financialsSharedService,
    fundingCalculatorService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.loanAppSharedData = loanAppSharedData;
    this.loanApplicationServices = loanApplicationServices;
    this.financialsSharedService = financialsSharedService;
    this.fundingCalculatorService = fundingCalculatorService;
  }

  openAssetFinanceModal(props, onSuccessCallback) {
    const modalInstance = this.$uibModal.open({
      template: `<asset-finance-modal
                    modal-instance="vm.modalInstance"
                    loan-app-id="vm.props.loanAppId" 
                    loan-security-info-id="vm.props.loanSecurityInfoId" 
                    family-id="vm.props.familyId"
                    lending-category-id="vm.props.lendingCategoryId">
                  </asset-finance-modal>`,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'asset-finance-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props: () => props,
      },
    });
    return modalInstance.result.then(() => {
      alertSucess('Success', 'Asset Finance successfully added.');
      onSuccessCallback && onSuccessCallback();
    });
  }

  onConfirmDeleteSecurity(item, loanAppId, onDeleteSuccessCallback) {
    return this.loanApplicationServices
      .deleteLoanSecurityInfo(
        loanAppId,
        item.SecurityInfoId,
        item.FundingCalParametersId ? item.FundingCalParametersId : 0,
      )
      .then(({ data }) => {
        if (!data) {
          return toastError();
        }
        onDeleteSuccessCallback && onDeleteSuccessCallback();
        this.financialsSharedService.AssetInfoGet();
        this.financialsSharedService.IncomeInfoGet();
        this.loanAppSharedData.refreshLoanDetailsSet = 1;
      })
      .catch(toastError);
  }

  deleteSecurity({ item, loanAppId, onDeleteSuccessCallback }) {
    const onConfirmCallback = () =>
      this.onConfirmDeleteSecurity(item, loanAppId, onDeleteSuccessCallback);
    showCustomConfirmation(
      'Are you sure?',
      'This information will be removed from your list',
      'Yes, remove it!',
      'No',
      'confirm-button',
      onConfirmCallback,
    );
  }

  openLMIModal({
    loanDetails,
    securityBrokerEventId,
    isFromFundingDetails,
    onUpdateLMIValueOnTxt,
    onSuccessCallback,
  }) {
    const props = {
      loanDetails,
      brokerEventId: securityBrokerEventId,
      updateLMIValueOnTxt: onUpdateLMIValueOnTxt,
      isFromFundingDetails,
    };
    const modalInstance = this.$uibModal.open({
      template: `
        <lmi-airtable-proposed-lending
          modal-instance="vm.modalInstance"
          broker-event-id="vm.props.brokerEventId"
          loan-splits="vm.props.loanSplits"
          loan-details="vm.props.loanDetails"
          is-from-funding-details="vm.props.isFromFundingDetails"
          update-lmi-value-on-txt="vm.props.updateLMIValueOnTxt(LMIPremium)"
          >
        </lmi-airtable-proposed-lending>`,
      size: 'sm',
      backdrop: 'static',
      windowClass: 'lmi-airtable-prposed-lending',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });

    return modalInstance.result.then(() => {
      onSuccessCallback && onSuccessCallback();
    });
  }
}
