/**
 * @param legacyAngularHost {value: string}
 * @param technology {isReact: boolean}
 * @param attr
 * @return {function(src: string): void}
 */
const srcFormatter = (legacyAngularHost, technology, attr) => (src) => {
  if (technology.isReact && legacyAngularHost.value) {
    const newSrc = src.replace(/(\.{1,2}\/)+assets/, '/assets');
    let formattedValue = newSrc;
    if (newSrc.startsWith('/assets/')) {
      formattedValue = `${legacyAngularHost.value}${newSrc}`;
    } else if (newSrc.startsWith('assets/')) {
      formattedValue = `${legacyAngularHost.value}/${newSrc}`;
    } else if (newSrc.startsWith('./assets/')) {
      formattedValue = `${legacyAngularHost.value}${newSrc.slice(1)}`;
    }
    if (formattedValue !== newSrc) {
      attr.$set('src', formattedValue);
    }
  }
};
export { srcFormatter };
