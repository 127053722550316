import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('UserAccountCtrl', function UserAccountCtrl($scope) {
      $scope.infoUploaded = function (info) {
        $scope.infoType = info;
      };
    });
