import { ONBOARDING_ROLE_ID, TABS_IDX } from 'Common/constants/onboarding';
import { MOBILE, PHONE } from 'Common/constants/phoneMobileFormat';
import { EMAIL_PATTERN } from 'Common/constants/pattern';
import { EMAIL } from 'Common/constants/emailTypes';
import { ADDRESS_TYPE } from 'Common/constants/addressType';
import { getDatePickerConfig } from 'Common/utilities/date';
import { autoFormatUsername, roleIdsHasRole } from 'Common/utilities/contact';
import { extractUsername } from 'Common/utilities/onboarding';
import { isOwnBrand } from 'Common/utilities/brandingCategory';
import { getLanguagesSpoken } from './util/onboardingIndividualDetailsCtrl';

class OnboardingIndividualDetailsCtrl {
  constructor(
    currentUserService,
    optionsService,
    corporateModelService,
    onboardingDataService,
    modelDataService,
    onboardingService,
    addressModalService,
    corporateService,
    commonFnService,
    configService,
  ) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.optionsService = optionsService;
    this.corporateModelService = corporateModelService;
    this.onboardingDataService = onboardingDataService;
    this.modelDataService = modelDataService;
    this.onboardingService = onboardingService;
    this.addressModalService = addressModalService;
    this.corporateService = corporateService;
    this.commonFnService = commonFnService;
    this.configService = configService;
  }

  getTitlesList() {
    this.optionsService.getTitleList().then((response) => {
      this.titlesList = response;
    });
  }

  getMortgageBrokerYears() {
    this.corporateModelService
      .getOptionsForMortgageBrokerYears()
      .then(({ data }) => {
        this.mortgageBrokerYears = data;
      });
  }

  setInitialValues() {
    this.EMAIL_PATTERN = EMAIL_PATTERN;
    this.lmEmailDomain = EMAIL.LOANMARKET_DOMAIN;
    this.ADDRESS_TYPE = ADDRESS_TYPE;

    this.familyId = this.onboardingDataService.getFamilyId();
    this.clientId = this.onboardingDataService.getClientId();
    this.isAU = this.currentUserService.isAU;
    this.phoneMask = this.isAU ? PHONE.AU : PHONE.NZ;
    this.mobileMask = this.isAU ? MOBILE.AU : MOBILE.NZ;
    this.isOwnBrand = isOwnBrand(this.currentUserService.brandingCategoryID);
    this.reportingCountryId = this.onboardingDataService.getCountryId();
    this.adviserOrgId = this.onboardingDataService.getAdviserOrgId();

    this.datePickerConfig = getDatePickerConfig({
      dateOptions: {
        formatYear: 'yy',
        showWeeks: false,
      },
      isOpenDate: false,
    });
  }

  initializeAddressFields() {
    const addressObj = {
      homeAddress: this.modelDataService.addressModelData(),
      businessAddress: this.modelDataService.addressModelData(),
      mailingAddress: this.modelDataService.addressModelData(),
    };
    this.contactAddress = { ...addressObj };
    this.addressOverhaulValue();
  }

  openDate() {
    this.datePickerConfig.isOpenDate = true;
  }

  setLoanMarketEmail() {
    if (this.isOwnBrand) {
      return;
    }

    const { FirstName, LastName } = this.individualData;
    const nameArr = [FirstName, LastName];
    this.individualData.FormattedLMEmail = autoFormatUsername(nameArr);
    this.updateDetails(this.individualData);
  }

  validateSecondaryEmail(secondaryEmail) {
    if (this.isOwnBrand) {
      return;
    }

    const guarantorId = ONBOARDING_ROLE_ID.GUARANTOR;
    const isGuarantor = roleIdsHasRole(guarantorId, this.individualData.RoleId);
    if (isGuarantor) {
      return;
    }

    if (!secondaryEmail) {
      this.setLoanMarketEmail();
      return;
    }
    const username = extractUsername(secondaryEmail, this.lmEmailDomain);
    this.individualData.FormattedLMEmail = username;
    if (!username) {
      this.setLoanMarketEmail();
    }
  }

  launchAddressModal(addressType) {
    const size = 'md';
    const modalInstance = this.addressModalService.launchAddressModal(
      size,
      this.familyId,
      false,
      this.contactAddress,
      true,
      addressType,
    );
    modalInstance.result.then((result) => {
      this.contactAddress = result;
      this.setSameAsBusinessValues(this.contactAddress);
    });
  }

  setSameAsBusinessValues(address) {
    if (!address) {
      return;
    }
    const { homeAddress, businessAddress, mailingAddress } = address;
    if (!businessAddress.formatted_address) {
      return;
    }

    if (mailingAddress.formatted_address) {
      this.contactAddress.postalSameAsBusiness =
        businessAddress.formatted_address === mailingAddress.formatted_address;
    }
    if (homeAddress.formatted_address) {
      this.contactAddress.homeSameAsBusiness =
        businessAddress.formatted_address === homeAddress.formatted_address;
    }
  }

  updateAddressSameAsBusiness(newBusinessAddress) {
    const {
      homeAddress,
      mailingAddress,
      businessAddress,
    } = this.contactAddress;

    const updateHomeAddress =
      this.contactAddress.homeSameAsBusiness &&
      businessAddress.formatted_address !== homeAddress.formatted_address;
    if (updateHomeAddress) {
      const newHomeAddress = newBusinessAddress || businessAddress;
      this.updateAddress(newHomeAddress, ADDRESS_TYPE.HOME);
      this.contactAddress.homeAddress = { ...newHomeAddress };
    }

    const updateMailingAddress =
      this.contactAddress.postalSameAsBusiness &&
      businessAddress.formatted_address !== mailingAddress.formatted_address;
    if (updateMailingAddress) {
      const newMailingAddress = newBusinessAddress || businessAddress;
      this.updateAddress(newMailingAddress, ADDRESS_TYPE.MAILING);
      this.contactAddress.mailingAddress = { ...newMailingAddress };
    }
    this.addressOverhaulValue();
  }

  showMentorDetails() {
    const invalidValues =
      !this.individualData ||
      !this.mortgageBrokerYears ||
      !this.mortgageBrokerYears.length;
    if (invalidValues) {
      return false;
    }
    const { NumberOfYearsMortgageAdviser } = this.individualData;
    return NumberOfYearsMortgageAdviser === this.mortgageBrokerYears[0];
  }

  getAdviserOrgBusinessAddress(businessAddress) {
    const hasBusinessAdd = businessAddress && businessAddress.formatted_address;
    if (hasBusinessAdd || !this.adviserOrgId) {
      return;
    }

    this.corporateService
      .organizationAddressGet(this.adviserOrgId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        const addressTmp = this.commonFnService.setAddressTypes(data);
        if (addressTmp) {
          const entityBusinessAddress = { ...addressTmp.home };
          this.contactAddress.businessAddress = entityBusinessAddress;
          if (!entityBusinessAddress.formatted_address) {
            return;
          }
          this.updateAddress(entityBusinessAddress, ADDRESS_TYPE.BUSINESS);
        }
      });
  }

  getIndividualDetails() {
    this.onboardingService
      .getRecruitmentContact(this.familyId, this.clientId)
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }
        const details = data[0] || {};
        details.FormattedDOB = details.DOB ? new Date(details.DOB) : '';
        details.LanguagesSpoken = getLanguagesSpoken(details.LanguagesSpoken);
        details.IndustryExpYears = !!details.HaveTwoYrsIndustryExp;

        this.individualData = details;
        this.validateSecondaryEmail(details.SecondaryEmail);
      });
  }

  getAddress() {
    this.corporateService.familyAddressGet(this.familyId).then(({ data }) => {
      const address = this.commonFnService.setAddressTypes(data);
      if (!address) {
        return;
      }
      this.contactAddress.homeAddress = { ...address.home };
      this.contactAddress.businessAddress = { ...address.business };
      this.contactAddress.mailingAddress = { ...address.mailing };
      this.getAdviserOrgBusinessAddress(this.contactAddress.businessAddress);
      this.setSameAsBusinessValues(this.contactAddress);
      this.addressOverhaulValue();
    });
  }

  updateDetails(detail) {
    const defaultDate = '1900-01-01';
    detail.DOB = detail.FormattedDOB
      ? this.commonFnService.formatDateString(detail.FormattedDOB)
      : defaultDate;
    if (!this.isOwnBrand) {
      detail.SecondaryEmail = detail.FormattedLMEmail
        ? `${detail.FormattedLMEmail}${this.lmEmailDomain}`
        : '';
    }
    detail.HaveTwoYrsIndustryExp = detail.IndustryExpYears ? 1 : 0;

    this.onboardingService.setRecruitmentContact(this.familyId, detail);
  }

  updateAddress(address, type) {
    const isMailing = type === ADDRESS_TYPE.MAILING;
    const isBusiness = type === ADDRESS_TYPE.BUSINESS;

    this.commonFnService.saveAddress(
      this.familyId,
      0,
      address,
      isMailing,
      isBusiness,
      false,
      address.isCustomAddress,
    );

    if (isBusiness) {
      this.updateAddressSameAsBusiness(address);
    }
  }

  $onChanges(changes) {
    const { activeTab } = changes;

    if (activeTab && typeof activeTab.currentValue !== 'undefined') {
      if (!this.individualData) {
        return;
      }
      const franchiseeId = ONBOARDING_ROLE_ID.FRANCHISEE;
      const copyEntityAddress =
        roleIdsHasRole(franchiseeId, this.individualData.RoleId) &&
        activeTab.currentValue === TABS_IDX.INDIVIDUAL - 1;
      if (!copyEntityAddress) {
        return;
      }

      const busAdd = this.contactAddress && this.contactAddress.businessAddress;
      this.getAdviserOrgBusinessAddress(busAdd);
    }
  }

  addressOverhaulValue() {
    const defaultAddress = {
      home: {},
      postal: {
        isSameAsHome: true,
      },
      homeForm: {},
      postalForm: {},
      homeLabel: 'Business Address',
      postalLabel: '',
      isDisabled: true,
    };

    const businessAddress = this.adviserBusinessAddress || defaultAddress;
    this.adviserBusinessAddress = {
      ...businessAddress,
      home: this.contactAddress.businessAddress,
    };

    const homeAddress = this.adviserHomeAddress || defaultAddress;
    this.adviserHomeAddress = {
      ...homeAddress,
      homeLabel: `Home Address`,
      home: this.contactAddress.homeAddress,
      isDisabled: this.contactAddress.homeSameAsBusiness,
    };

    const postalAddress = this.adviserPostalAddress || defaultAddress;
    this.adviserPostalAddress = {
      ...postalAddress,
      homeLabel: `Postal Address`,
      home: this.contactAddress.mailingAddress,
      isDisabled: this.contactAddress.postalSameAsBusiness,
    };
  }

  handleUpdateAddress(address, key) {
    switch (key) {
      case 'business':
        this.contactAddress.businessAddress = address;
        this.updateAddress(address, this.ADDRESS_TYPE.BUSINESS);
        this.updateAddressSameAsBusiness(address);
        break;
      case 'mailing':
        this.contactAddress.mailingAddress = address;
        this.updateAddress(address, this.ADDRESS_TYPE.MAILING);
        break;
      default:
        this.contactAddress.homeAddress = address;
        this.updateAddress(address, this.ADDRESS_TYPE.HOME);
        break;
    }
  }

  $onInit() {
    this.individualData = {};
    this.isAddressOverhaulEnabled = this.configService.feature.addressOverhaul;
    this.getTitlesList();
    this.getMortgageBrokerYears();

    this.setInitialValues();
    this.initializeAddressFields();
    this.getIndividualDetails();
    this.getAddress();
  }
}

export default OnboardingIndividualDetailsCtrl;
