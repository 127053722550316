import { CONTENT_TYPE } from 'Common/constants/contentType';
import { DOCUMENT_UPLOAD_URL, AWS_S3_ROOT } from 'Common/constants/document';

export const getDocumentTypeIcon = (docType) => {
  const contentType = docType && docType.toLowerCase();
  switch (contentType) {
    case CONTENT_TYPE.PDF:
      return 'assets/images/icon/pdf.png';
    case CONTENT_TYPE.WORD:
      return 'assets/images/icon/word.png';
    case CONTENT_TYPE.POWERPOINT:
      return '/assets/images/icon/ppt.png';
    case CONTENT_TYPE.SPREAD_SHEET:
    case CONTENT_TYPE.EXCEL:
      return 'assets/images/icon/xls.png';
    default:
      return '/assets/images/icon/generic.png';
  }
};

export function isThumbnailObjValid(thumbObj) {
  if (thumbObj && thumbObj.ContentType && thumbObj.ThumbnailContent) {
    return true;
  }
}

export function getDataURI(file) {
  const invalidFile = !file || !file.ContentType || !file.DocumentContent;
  if (invalidFile) {
    return '';
  }
  return `data:${file.ContentType};base64,${file.DocumentContent}`;
}

export const getDocumentUploadUrl = (params) => {
  if (!params || !params.length) {
    return DOCUMENT_UPLOAD_URL;
  }
  const strParams = params
    .filter((item) => item.key && typeof item.value !== 'undefined')
    .map((item) => {
      return `${item.key}=${item.value}`;
    })
    .join('&');
  return strParams
    ? `${DOCUMENT_UPLOAD_URL}?${strParams}`
    : DOCUMENT_UPLOAD_URL;
};

export const getUploadUrl = () => {
  const params = [{ key: 'uploadType', value: AWS_S3_ROOT.BYOB }];
  return getDocumentUploadUrl(params);
};

export const logoPath = (data) => {
  return data ? `data:${data.ContentType};base64,${data.DocumentContent}` : '';
};
