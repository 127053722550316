export const getOccupancyType = (occupancyType) => {
  const occupiedTypes = [
    'PurchaseOwnerOccupied',
    'ReleaseOwnerOccupied',
    'LandOwnerOccupied',
  ];
  const guaranteeType = 'Guarantee';
  if (occupancyType === guaranteeType) {
    return 'Guarantee';
  }
  return occupiedTypes.includes(occupancyType) ? 'Owner Occupied' : 'Rental';
};
