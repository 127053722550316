import template from './addressField.html';
import AddressFieldCtrl from './addressFieldCtrl';

export default {
  template,
  bindings: {
    addressModel: '=',
    required: '<',
    disableField: '<',
    placeholder: '@',
    hideMapPin: '<',
    onAddressSelection: '&',
  },
  controller: AddressFieldCtrl,
  controllerAs: 'vm',
};
