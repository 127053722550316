import moment from 'moment';

export const clientColumnContent = (clientData) => {
  return clientData.reduce((letterIconHtml, client) => {
    const clientLetterIcon = `<span class="crm-letter-icon text-transform-uppercase" ng-attr-style="background: #${client.background};">
    ${client.initials}
                              </span>`;
    return letterIconHtml.concat(' ', clientLetterIcon);
  }, '');
};

export const addressColumnContent = ({
  formattedAddress,
  addressValuationExtendedDetailsId,
  hasMap,
}) => {
  return `
    <div style="display: flex; align-items: center;">
    <p style="flex: 1; margin: 0;">
    ${formattedAddress}
    </p>
      <div google-address-thumbnail class="map" style="width: 100px; display: flex; justify-content: flex-end;">
        <google-address-thumbnail-icon ng-if="${hasMap}"/>
        <div
        ng-if="${hasMap}"
        id="map-${addressValuationExtendedDetailsId}"
        class="addresses-map-list opacity-0"
        style="height: 40px;
        width: 40px;
        border-radius: 2px;
        margin: 0 10px;
        box-shadow: 0 0 1px 2px #71AFBC;"
        ></div>
      </div>
      
    </div>
    `;
};

export const arrangementColumnContent = (ownershipType) => {
  return `
    <p style="margin: 0;">${ownershipType}</p>
    `;
};

export const timeFrameColumnContent = ({
  startDate,
  endDate,
  isCurrent,
  duration,
}) => {
  const { Year, Month } = duration;
  return `
  <div ng-if="${!!startDate}">
  <div>${moment(startDate).format('MMM YYYY')} - ${
    isCurrent ? 'Current' : moment(endDate).format('MMM YYYY')
  }</div>
  <div>(${Year} ${Year > 1 ? 'years' : 'year'} ${Month} ${
    Month > 1 ? 'months' : 'month'
  })</div>
  </div>
  `;
};

export const createAddressColumnContent = (addresses) => {
  return addresses.map((address) => {
    return {
      client: clientColumnContent(address.ContactLivingIn),
      address: addressColumnContent({
        formattedAddress: address.formatted_address,
        addressValuationExtendedDetailsId:
          address.AddressValuationExtendedDetailsId,
        hasMap: address.latitude && address.longitude,
      }),
      arrangement: arrangementColumnContent(
        address.OwnershipType || address.Type,
      ),
      timeframe: timeFrameColumnContent({
        startDate: address.StartDateTime,
        endDate: address.EndDateTime,
        isCurrent: address.isCurrent,
        duration: address.AddressDuration,
      }),
      editParams: address,
      deleteParams: address.AddressValuationExtendedDetailsId,
    };
  });
};
