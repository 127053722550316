import { adviserAllocateSwal } from 'Common/utilities/adviserAllocateSwal';

export default class AllocatedAdviserTooltipCtrl {
  constructor(utilitiesService, contactService, userService) {
    'ngInject';

    this.utilitiesService = utilitiesService;
    this.contactService = contactService;
    this.userService = userService;
  }

  $onInit() {
    this.showChangeAdviser = false;
    if (this.adviser && this.adviser.adviserId) {
      this.adviserId = this.adviser.adviserId;
    }

    if (this.adviserId) {
      this.getAdviserInfo(true);
    } else {
      this.getUserInfo();
    }
  }

  $onChanges(changes) {
    const { adviserId } = changes;
    if (!adviserId) {
      return;
    }
    this.getAdviserInfo(adviserId.currentValue);
  }

  getUserInfo() {
    this.userService.GetUserInfo().then((response) => {
      const userData = response.data;
      this.selectedAdviser = {
        ...userData,
        originalObject: { ...userData },
      };
      this.setAdviser(this.selectedAdviser, true);
    });
  }

  getAdviserInfo(isInitial = false) {
    if (!this.adviserId) {
      return;
    }
    this.contactService.clientInformGet(this.adviserId).then((response) => {
      const { data } = response;
      if (!data || !data.length) {
        return;
      }
      const { FullName, FamilyId } = data[0];
      const adviserData = {
        FullName,
        FamilyId: FamilyId || this.adviserId,
      };

      const setData = {
        ...adviserData,
        originalObject: { ...adviserData },
      };
      this.setAdviser(setData, isInitial);
    });
  }

  toggleShowChangeAdviser() {
    this.showChangeAdviser = !this.showChangeAdviser;
  }

  setAdviser(
    adviser,
    isInitial = false,
    isShowConfirmationModal,
    adviserBak = {},
  ) {
    if (!adviser) {
      return;
    }
    this.selectedAdviser = adviser;
    const AdviserFamilyId =
      this.selectedAdviser.BrokerId || this.selectedAdviser.FamilyId;
    this.selectedAdviser = {
      ...this.selectedAdviser,
      AdviserFamilyId,
    };
    this.showChangeAdviser = false;

    if (typeof this.onAdviserChange !== 'undefined' && !isInitial) {
      if (isShowConfirmationModal) {
        adviserAllocateSwal(this.selectedAdviser.FullName, (confirmed) => {
          if (confirmed) {
            this.onAdviserChange({ adviserId: AdviserFamilyId });
          } else {
            this.selectedAdviser = { ...adviserBak };
          }
        });
      } else {
        this.onAdviserChange({ adviserId: AdviserFamilyId });
      }
    }
  }

  setAssistantAdviser(assistantFamilyId) {
    this.onAssistantChange({ assistantFamilyId });
  }
}
