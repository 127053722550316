import angular from 'angular';
import moment from 'moment';

export const loadModule = () =>
  angular.module('app').filter('dateTZ', function dateTZ() {
    return function (date, pFormat) {
      let format = pFormat;
      if (typeof format === 'undefined') {
        format = 'MMMM Do YYYY, h:mm:ss a';
      }
      return moment.parseZone(date).format(format);
    };
  });
