import template from './mycrmSearchInput.html';
import controller from './mycrmSearchInputCtrl';
import './style.scss';

export default {
  template,
  controller,
  bindings: {
    value: '<',
    iconPosition: '@',
    size: '@',
    placeholder: '@',
    onSubmit: '&',
  },
  controllerAs: 'vm',
};
