import { REFERRAL_ITEM } from 'Common/constants/enquirySource';

export const getUtmDetails = ({ corporateService, $scope }) => {
  corporateService
    .clientUTMDetailsGet($scope.familyId, 0)
    .then(({ data }) => {
      $scope.utmCampaigns = data || [];
      return corporateService.corporateContactGet(0, $scope.familyId);
    })
    .then(({ data }) => {
      $scope.symmetryIdCorp = data || {};
    });
};

export const setUtmDetailsDisplay = ({ currentUserService, $scope }) => (
  referralItemId = 0,
) => {
  if (currentUserService.isCorporate) {
    $scope.displayUtmDetails = true;
    return;
  }
  const validReferralItemIds = [
    ...(currentUserService.isAU ? [REFERRAL_ITEM.MY_LEAD_GENERATOR] : []),
    REFERRAL_ITEM.MY_LEAD_GENERATOR_BYOB,
  ];
  $scope.displayUtmDetails = validReferralItemIds.includes(referralItemId);
};

export const initializeUtmFunc = ({
  currentUserService,
  $scope,
  contactSharedDataService,
}) => {
  $scope.isUtmDetailsReadOnly = !currentUserService.isCorporate;
  $scope.displayUtmDetails =
    currentUserService.isCorporate && $scope.isClientView;
  contactSharedDataService.setUtmDetailsDisplay = setUtmDetailsDisplay({
    currentUserService,
    $scope,
  });
};
