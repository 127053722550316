import { TOOLTIP, LAYOUT, TICKS } from 'Common/constants/chartOptions';
import { RGBA } from 'Common/constants/colors';
import _ from 'lodash';

export const loadModule = () =>
  app.factory('utilitiesService', [
    '$q',
    'currentUserService',
    function ($q, currentUserService) {
      var factory = this;

      const roundFigure = (val) => {
        const { brokerRegionalization } = currentUserService;
        return (
          brokerRegionalization.currencySign + Math.round(val).toLocaleString()
        );
      };

      const getTooltipLabel = (value, datasets) => {
        if (datasets && datasets.length > 1) {
          return `${datasets[value.datasetIndex].label} : ${roundFigure(
            value.yLabel,
          )}`;
        } else {
          return roundFigure(value.yLabel);
        }
      };

      const loanOptionData = {
        responsive: true,
        scaleLabel(valuePayload) {
          return roundFigure(parseInt(valuePayload.value, 10));
        },
        tooltipTemplate(v) {
          return roundFigure(v.value);
        },
        multiTooltipTemplate(label) {
          return `${label.datasetLabel} : ${roundFigure(label.value)}`;
        },
        scaleShowGridLines: true,
        scaleGridLineColor: 'rgba(0,0,0,.05)',
        scaleGridLineWidth: 1,
        bezierCurve: true,
        bezierCurveTension: 0.4,
        pointDot: true,
        pointDotRadius: 3,
        pointDotStrokeWidth: 2,
        pointHitDetectionRadius: 5,
        datasetStroke: true,
        datasetStrokeWidth: 2,
        datasetFill: true,
        elements: {
          line: {
            tension: 0.4,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                precision: TICKS.PRECISION,
                maxTicksLimit: TICKS.MAX_TICK,
                callback: (value) => roundFigure(value),
              },
              gridLines: {
                color: RGBA.GREY,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                color: RGBA.GREY,
              },
            },
          ],
        },
        tooltips: {
          mode: TOOLTIP.MODE_INDEX,
          intersect: false,
          caretSize: TOOLTIP.CARET_SIZE,
          caretPadding: TOOLTIP.CARET_PADDING,
          titleFontSize: TOOLTIP.FONT_NORMAL,
          bodyFontSize: TOOLTIP.FONT_NORMAL,
          callbacks: {
            label: (value, { datasets }) => getTooltipLabel(value, datasets),
          },
        },
        hover: {
          mode: TOOLTIP.MODE_INDEX,
          intersect: false,
        },
        layout: {
          padding: {
            right: LAYOUT.PADDING_RIGHT,
            top: LAYOUT.PADDING_TOP,
          },
        },
        maintainAspectRatio: false,
      };

      factory.getCustomYaxis = function () {
        return [
          {
            ticks: {
              precision: TICKS.PRECISION,
              callback: (value) => roundFigure(value),
            },
            afterBuildTicks: (data) => {
              const { ticks } = data;
              if (!ticks || !ticks.length) return;
              const maxVal = ticks[0];
              const newLength = (ticks.length - 1) * 2;
              if (newLength > maxVal) return;
              const stepSize = maxVal / newLength;
              data.ticks = [...Array(newLength + 1)].map(
                (tickVal, index) => stepSize * index,
              );
            },
            gridLines: {
              color: RGBA.GREY,
            },
          },
        ];
      };

      factory.filterInitial = function () {
        var i;
        var initials = '';
        for (i = 0; i < arguments.length; i++) {
          if (!_.isNil(arguments[i]) && _.isString(arguments[i])) {
            initials = `${initials}${arguments[i].charAt(0).toUpperCase()}`;
          }
        }
        return initials;
      };

      factory.getHeaderName = function (lastName, firstName, lastName2) {
        const headerName = `${lastName} & ${firstName} ${lastName2}`;
        return headerName;
      };

      factory.filterInitialOneString = function (str) {
        var initials = '';

        if (str) {
          var stringArray = str.split(/(\s+)/);

          var i = 0; // first word
          initials = stringArray[i].charAt(0).toUpperCase();

          i = stringArray.length - 1; // last word
          if (i > 0) initials += stringArray[i].charAt(0).toUpperCase();
        }

        return initials;
      };

      factory.formatDate = function (dateValue, IsyyyymmddToddmmyyyy) {
        if (!dateValue) return '';

        if (IsyyyymmddToddmmyyyy)
          return dateValue.split('/').reverse().join('-');
      };

      factory.getLoanOptions = () => {
        factory.data = {};
        factory.data = loanOptionData;
        return { ...factory.data };
      };

      factory.groupList = (list, groupSize) => {
        var shallowList = _.clone(list);
        var newList = _.reduce(
          shallowList,
          (result, value, index) => {
            if (index % groupSize === 0) {
              result.push(shallowList.slice(index, index + groupSize));
            }
            return result;
          },
          [],
        );

        return newList;
      };

      return factory;
    },
  ]);
