import {
  getDefaultPastDateRanges,
  getTodayObj,
} from 'Common/config/calendarRangePicker';
import { isDateRangeValid, formatRangeFilter } from 'Common/utilities/date';
import { INVALID_DATE } from 'Common/constants/promptMessages';
import { PICKER_RANGE } from 'Common/constants/pickerRange';
import { DATE_TEXT_FORMAT } from 'Common/constants/dateConfigs';

class MycrmDateRangeCtrl {
  $onInit() {
    this.PICKER_RANGE = PICKER_RANGE;
    this.DATE_TEXT_FORMAT = DATE_TEXT_FORMAT;
    this.hasValidationIssue = false;
    this.validationMsg = INVALID_DATE.START_END;
    this.ranges = this.customRanges || getDefaultPastDateRanges();
    this.datePickerOptions = {
      showWeeks: false,
      formatMonth: 'MMM',
    };
    this.dateRangeCustomDateIndex =
      this.customIndex == null ? PICKER_RANGE.CUSTOM : this.customIndex;
  }

  onChangeDate() {
    const { dateStart, dateEnd } = { ...this.model };
    this.isValidRange = isDateRangeValid(dateStart, dateEnd);
    this.hasValidationIssue = !!(this.strictValidation && !this.isValidRange);
    this.onDetectChanges({ models: formatRangeFilter(this.model) });
  }

  onApply() {
    if (this.hasValidationIssue) {
      return;
    }
    this.onUpdate({
      filterData: {
        ...formatRangeFilter(this.model),
        selectedIndex: this.selectedIndex,
      },
    });
  }

  clearOption() {
    this.model = getTodayObj();
    this.onRangeSelected({ activeRangeIndex: null });
    this.onUpdate({
      filterData: {
        dateStart: '',
        dateEnd: '',
      },
    });
  }

  selectRange(selected, index) {
    this.onRangeSelected({ activeRangeIndex: index });
    this.selectedIndex = index;
    if (index !== this.dateRangeCustomDateIndex) {
      const range = {
        dateStart: selected.range[0],
        dateEnd: selected.range[1],
      };
      this.onUpdate({
        filterData: { ...formatRangeFilter(range), selectedIndex: index },
      });
      return;
    }

    const { dateStart, dateEnd } = { ...this.preselectModel };
    const isValidPreselect = isDateRangeValid(dateStart, dateEnd);
    this.model = isValidPreselect ? this.preselectModel : getTodayObj();
    this.onChangeDate();
  }
}
export default MycrmDateRangeCtrl;
