import template from './noteModal.html';
import controller from './noteModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    note: '<',
    modalInstance: '<',
  },
};
