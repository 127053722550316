import angular from 'angular';

function configUibModal($uibModalProvider) {
  $uibModalProvider.options = {
    ...$uibModalProvider.options,
    appendTo: angular.element('.app-body'),
  };
}

configUibModal.$inject = ['$uibModalProvider'];

export default configUibModal;
