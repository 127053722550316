class MergeFieldsSelectionCtrl {
  constructor(corporateModelService, templateService) {
    'ngInject';

    this.corporateModelService = corporateModelService;
    this.templateService = templateService;
  }

  $onInit() {
    this.getMergeFields();
  }

  getMergeFields() {
    const params = {
      isSMS: this.type === 'sms',
      ...(this.appendMergeFields ? { ExcludeInsurance: true } : {}),
    };

    this.corporateModelService.mergeFieldsGet(params).then((response) => {
      if (response.data && response.data.length) {
        this.mergeFieldList = response.data;

        const { appendMergeFields } = this;
        this.mergeFieldList = appendMergeFields
          ? this.mergeFieldList.map((item) => {
              const matched = item.Category === appendMergeFields.Category;
              const updatedItem = {
                ...item,
                MergeFields: [
                  ...item.MergeFields,
                  ...appendMergeFields.MergeFields,
                ],
              };
              return matched ? updatedItem : item;
            })
          : this.mergeFieldList;

        this.mergeFields = response.data[0];
        this.populateMergeFieldsSelection();
      }
    });
  }

  populateMergeFieldsSelection() {
    if (this.mergeFields) {
      this.mergeValues = this.mergeFields.MergeFields;
    }
  }
}

export default MergeFieldsSelectionCtrl;
