import swal from 'sweetalert';

export function validCustomAPIResponse(response) {
  if (!response || !response.data) {
    return false;
  }
  const defaultProperty = ['Data', 'Messages', 'Succeeded'];
  const responseList = Object.keys(response.data).filter((key) =>
    defaultProperty.includes(key),
  );
  return responseList.length === 3;
}

export function showCurrentError(e) {
  if (e && !e.Succeeded && e.Messages) {
    e.Message.forEach((message) => {
      swal('Error', message, 'error');
    });
  }
}

export function validateAndGetArrayData(data) {
  return data && data.length && data[0];
}
