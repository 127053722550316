import { filterInitialOneString } from 'Common/utilities/contact';
import { getReferralTypeDetails } from 'Common/utilities/referral';

export function referralNoteBuilderForUI(note) {
  return (
    note && {
      dateCreated: note.ActivityDate,
      content: note.ActivityDetails,
      owner: note.ActivityOwner,
      title: note.ActivityTitle,
      id: note.AdviserId,
      name: note.AdviserName,
      communicationId: note.CommunicationID,
      createdById: note.CreatedByUserID,
      isReferrer: note.IsReferrer,
      isSticky: note.IsSticky,
      senderPhoto: note.AdviserProfilePicture,
    }
  );
}

export function referralTypeBuilerForUI(referralTypeDetails) {
  return (
    referralTypeDetails && {
      logo: referralTypeDetails.Logo,
      referralTypeId: referralTypeDetails.ReferralTypeId,
      referralType: referralTypeDetails.ReferralType,
    }
  );
}

export function referralBuilderForUI(referral, type) {
  const details = referralTypeBuilerForUI(getReferralTypeDetails(type));
  return (
    referral && {
      id: referral.ReferralID,
      dateReferred: referral.DateReferred,
      contact: referral.Contact,
      sendToName: referral.SendToFullName || '',
      status: referral.StatusName,
      adviserProfilePicture: referral.AdviserProfilePicture,
      adviserName: referral.AdviserName,
      familyId: referral.FamilyID,
      clientId: referral.ClientID,
      connectId: referral.ConnectID,
      adviserId: referral.AdviserID,
      isAutoAllocated: referral.IsAutoAllocation,
      ...details,
      nextGenLoans: referral.NextGenLoans || [],
    }
  );
}

export function sendReferralForUI(referral) {
  const details = getReferralTypeDetails(referral.ReferralType);
  return (
    referral && {
      Contact: referral.Contact,
      DateReferred: referral.DateReferred,
      ReferralID: referral.ReferralID,
      FamilyID: referral.FamilyID,
      ClientID: referral.ClientID,
      ReferralType: referral.ReferralType || '',
      StatusName: referral.StatusName,
      SendToName: referral.SendToFullName || '',
      AdviserName: referral.AdviserName,
      WithNote: referral.WithNote,
      Notes: referral.Notes,
      TotalCount: referral.TotalCount,
      ContactInitials: filterInitialOneString(referral.Contact),
      SendToInitials: filterInitialOneString(referral.SendToFullName),
      Note: referral.WithNote && {
        dateCreated: referral.DateReferred,
        content: referral.Notes,
        owner: referral.AdviserName,
        ownerInitials: filterInitialOneString(referral.AdviserName),
        title: '',
        isReferrer: true,
      },
      ...details,
    }
  );
}

export function loanReferralCommissionBuilderForUI(data) {
  return (
    data && {
      loanApplicationId: data.LoanApplicationID,
      adviserId: data.AdviserId,
      referrerTypeId: data.ReferrerTypeID,
      commissionTier: data.CommissionTier,
      upfrontCommission: data.UpfrontCommission || 0,
      upfrontReferral: data.UpfrontReferral || 0,
      initialReferralFee: data.InitialReferralFee?.toFixed(2) || 0,
      trailCommission: data.TrailCommission || 0,
      trailReferral: data.TrailReferral || 0,
      ongoingReferralFee: data.OngoingReferralFee?.toFixed(2) || 0,
    }
  );
}
