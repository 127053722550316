/* eslint-disable angular/window-service */
const TOKEN_KEY = 'myCRM_jwt';

export function getToken() {
  return window.localStorage.getItem(TOKEN_KEY);
}

export function removeToken() {
  window.localStorage.removeItem(TOKEN_KEY);
}

export function setToken(token) {
  window.localStorage.setItem(TOKEN_KEY, token);
}
