import {
  E_SIGN_ACOUNT_TYPE,
  RECOMMENDATION_MODAL_CHOICE,
} from 'Common/constants/eSign';
import { MODAL_SECTION } from 'Common/constants/generateReports';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { toastInfo } from 'Common/utilities/alert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import { getListGuarantors } from '../../../util/application';

export const mapEsignAccountCheck = ({
  $scope,
  loanAppSharedData,
  selectedSection,
  isSubscriptionActive,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => (eSignService) => {
  const { LoanApplicationDetails, eSignAccountType } = loanAppSharedData;
  if (selectedSection !== MODAL_SECTION.E_SIGN) {
    selectedSection === RECOMMENDATION_MODAL_CHOICE.CQP &&
      $scope.getCPADocument();
    $scope.activeModalSection = selectedSection;
    return eSignAccountType;
  }
  const { AdviserFamilyId, CountryID: countryId } = LoanApplicationDetails;
  if (!AdviserFamilyId || !isSubscriptionActive) {
    return eSignAccountType;
  }
  eSignService.getAccountCheck(AdviserFamilyId).then((response) => {
    const { status } = response;
    loanAppSharedData.eSignAccountType = status;
    const isSubscribe =
      selectedSection === MODAL_SECTION.E_SIGN &&
      isSubscriptionActive &&
      $scope.isEsignEnabled &&
      countryId === COUNTRY_TYPE.AUSTRALIA;
    const isSubscribeYetNotActive =
      isSubscribe && status === E_SIGN_ACOUNT_TYPE.INVITED;
    const isSubscribeYetMemberOfAnother =
      isSubscribe && status === E_SIGN_ACOUNT_TYPE.MEMBER_OF_ANOTHER;
    const isSubscribeYetLocked =
      isSubscribe && status === E_SIGN_ACOUNT_TYPE.LOCKED;
    const isSubscribeWithEmailIssue = isSubscribe && !status;
    if (isSubscribeYetNotActive) {
      const message =
        'A while back you subscribed to E-Sign and havent verified your email. Please verify your esign to continue using this service';
      toastInfo(message);
      return status;
    }
    if (isSubscribeYetMemberOfAnother) {
      const message =
        'Seems your email is member of another company that uses esign. Please contact administrator for assistance.';
      toastInfo(message);
      return status;
    }
    if (isSubscribeYetLocked) {
      const message =
        'Your esign account is locked. Please contact administrator for assistance.';
      toastInfo(message);
      return status;
    }
    if (isSubscribeWithEmailIssue) {
      return status;
    }
    $scope.activeModalSection = selectedSection;
    if ($scope.activeModalSection === RECOMMENDATION_MODAL_CHOICE.CQP) {
      $scope.getCPADocument();
    }
    return status;
  });
};

export const checkEsignAccountVisibility = (
  eSignSubscription,
  currentUserService,
) => {
  const isSubscriptionActive = !!(
    eSignSubscription && eSignSubscription.isActive
  );
  const { accessType } = currentUserService;
  const accountCheckForValidSubscription = [
    ACCESS_TYPE.PRINCIPAL_ADVISER,
    ACCESS_TYPE.ADVISER,
  ].includes(accessType);
  return !accountCheckForValidSubscription
    ? isSubscriptionActive
    : accountCheckForValidSubscription;
};

export const hasGuarantor = (loanAppSharedData) => {
  const involvedPartyPerson =
    isValidObjectValue(
      () => loanAppSharedData.loanAppApplicants.InvolvedPartyPerson,
    ) || {};
  const involvedPartyEntity =
    isValidObjectValue(
      () => loanAppSharedData.loanAppApplicants.InvolvedPartyEntity,
    ) || [];

  return !!getListGuarantors(involvedPartyPerson, involvedPartyEntity).length;
};
