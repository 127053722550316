import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('FinPosIncomeModalCtrl', function FinPosIncomeModalCtrl(
      $scope,
      $uibModalInstance,
    ) {
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.saveBreakdown = function () {
        $scope.cancel();
      };
    });
