import { filterInitialOneString } from 'Common/utilities/contact';

export default class ImageCircleCtrl {
  $onInit() {
    this.setInitials();
    this.showInitials = !this.imageSource;
  }

  setInitials() {
    if (!this.initials && this.fullName) {
      this.initials = filterInitialOneString(this.fullName.trim());
      this.showInitials = !this.imageSource;
    }
  }

  handleError(angular, elem) {
    angular.element(elem).remove();
    this.setInitials();
    this.showInitials = true;
  }
}
