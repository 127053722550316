export const istevenSelectAllHandler = ({
  list = [],
  objId = '',
  allItemsId = 0,
  tickProperty = 'ticked',
}) => {
  if (!objId) {
    return list;
  }
  return list.reduce((accum, elem) => {
    return accum.concat({
      ...elem,
      [tickProperty]: elem[objId] === allItemsId,
    });
  }, []);
};

export const istevenItemSelectHandler = ({
  list = [],
  objId = '',
  allItemsId = 0,
  tickProperty = 'ticked',
}) => {
  if (!objId) {
    return list;
  }
  const newList = list.reduce((accum, elem) => {
    if (elem[objId] === allItemsId) {
      return accum.concat({ ...elem, [tickProperty]: false });
    }
    return accum.concat(elem);
  }, []);

  const optionsCount = list.length - 1;
  const tickedCounter = newList.filter((elem) => elem[tickProperty]).length;
  const untickedCounter = optionsCount - tickedCounter;

  const isAllSameTickProperty =
    untickedCounter === optionsCount || tickedCounter === optionsCount;

  if (isAllSameTickProperty) {
    return istevenSelectAllHandler({
      list,
      objId,
      allItemsId,
      tickProperty,
    });
  }

  return newList;
};

export const getBusinessUnitLastFilter = (filter, unitList) => {
  if (!filter || filter === '0') {
    return unitList;
  }
  const filterArray = filter.split('|');
  return unitList.map((unit) => {
    return { ...unit, ticked: filterArray.includes(`${unit.BusinessUnitID}`) };
  });
};
