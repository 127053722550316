import template from './helloPackFileInvite.html';
import controller from './helloPackFileInviteCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    model: '<',
    onChange: '&',
  },
};
