import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import {
  ANNUAL_REVIEWS_ID,
  CUSTOMER_CARE_TYPES,
  TAB_ANNUAL_REVIEWS,
} from 'Common/constants/customerCareModules';
import {
  sortColumn,
  sortType,
  addSelectedCustomerCareIds,
  isRetainAllAdviser,
  adviserChanged,
} from 'Assets/js/controllers/customerCare/util/customerCareCtrl';
import {
  onActionSelectedUtil,
  sendingResultUtil,
  modalParamsUtil,
  initValuesUtil,
  onDateRangeChange,
} from './util/customerCareTabsCtrl';
import {
  setAnnualReviewCompilationUtil,
  initializeAnnualReviewDateRangeSetting,
} from './util/annualReviewCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('AnnualReviewCtrl', function annualReviewCtrlFun(
      $scope,
      $uibModal,
      $timeout,
      NgTableParams,
      customerCareService,
      currentUserService,
      customerCareTabsService,
      configService,
    ) {
      // Annual Reviews
      // --------------------------------------------------------------------------
      $scope.unlockingCondition = () => {
        return (
          ($scope.showLockScreen &&
            $scope.unlockingCustomerCare &&
            $scope.autoMarketingSubscription
              .subscribe_StayInTouchAnnualReview) ||
          (currentUserService.isAssistantType && $scope.unlockingCustomerCare)
        );
      };
      $scope.reloadTimeout = null;
      $scope.isUnlockingAnnualReview = $scope.unlockingCondition();
      $scope.customerCareAdviserList = isRetainAllAdviser(
        $scope.customerCareAdviserList,
        $scope.isUnlockingAnnualReview,
      );
      $scope.mainObj = {};
      $scope.mainObj.adviserObject =
        $scope.customerCareAdviserList && $scope.customerCareAdviserList.length
          ? $scope.customerCareAdviserList[0]
          : 0;
      $scope.customerCareAdviserList = [...$scope.customerCareAdviserList];
      $scope.mainObj.dateRange = 'this';
      $scope.mainObj.ShowCompleted = false;
      $scope.currentUserService = currentUserService;
      $scope.customerCareTabsService = customerCareTabsService;
      $scope.initValues = initValuesUtil($scope);
      $scope.exportService = 'exportAnnualReview';
      $scope.selectedAnnualReviewIds = [];
      $scope.currentPage = 1;
      $scope.totalCount = 0;
      $scope.sortColumn = '';
      $scope.sortType = '';
      $scope.requiredParams = {
        get: () => ({
          pageSize: $scope.totalCount,
          sortType: $scope.sortType,
          sortColumn: $scope.sortColumn,
          toDate: $scope.mainObj.toDate,
          fromDate: $scope.mainObj.formDate,
          adviserId: $scope.mainObj.adviserObject.familyId,
          selectedRowIds: $scope.selectedAnnualReviewIds,
          isIncludeSubscribersToMktg: true,
        }),
      };
      $scope.addSelectedAnnualReviewIds = addSelectedCustomerCareIds(
        $scope.selectedAnnualReviewIds,
      );

      $scope.reloadTable = _.noop();
      $scope.showUnlockedAnnualReview = () => {
        const isAdviserReadOnly = _.get(
          $scope.mainObj,
          'adviserObject.isSubscribedToCustomerCareType',
          false,
        );
        return $scope.isUnlockingAnnualReview && isAdviserReadOnly;
      };
      $scope.customerCareTabsService.reloadCustomerCareAnnualValues = ({
        showLockScreen,
        customerCareAdviserList,
      }) => {
        $scope.reloadTimeout = $timeout(() => {
          $scope.showLockScreen = showLockScreen;
          $scope.isUnlockingAnnualReview = $scope.unlockingCondition();
          $scope.customerCareAdviserList = isRetainAllAdviser(
            customerCareAdviserList,
            $scope.isUnlockingAnnualReview,
          );
          $scope.mainObj.adviserObject = _.get(
            $scope.customerCareAdviserList,
            '[0]',
            {},
          );
          $timeout.cancel($scope.reloadTimeout);
        });
      };
      $scope.loadAnnualReviewTable = () => {
        $scope.annualReviewTableParams = new NgTableParams(
          {
            page: 1,
            count: 10,
            sorting: {
              reviewDate: 'asc',
            },
          },
          {
            counts: [],
            total: 0,
            getData(params) {
              const adviserFamilyId = _.get(
                $scope.mainObj,
                'adviserObject.familyId',
              );
              if (!adviserFamilyId && adviserFamilyId !== 0) {
                return;
              }
              $scope.isLoading = true;
              $scope.sortColumn = sortColumn(params.orderBy);
              $scope.sortType = sortType(params.sorting);
              $scope.currentPage = params.page();

              return customerCareService
                .ClientsAnnualReviewListGet(
                  adviserFamilyId,
                  $scope.mainObj.formDate,
                  $scope.mainObj.toDate,
                  params.page(),
                  params.count(),
                  $scope.sortColumn,
                  $scope.sortType,
                  $scope.mainObj.ShowCompleted,
                  !!$scope.isUnlockingAnnualReview,
                )
                .then(
                  (response) => {
                    if (
                      typeof response.data !== 'undefined' &&
                      typeof response.data.ClientsAnnualReviewList !==
                        'undefined'
                    ) {
                      $scope.annualReviewListTable =
                        response.data.ClientsAnnualReviewList;
                      _.map($scope.annualReviewListTable, (item) => {
                        const $date = `${item.ReviewMonth}-1-${item.ReviewYear}`;
                        item.reviewDate = moment($date).format('MMMM YYYY');

                        const today = moment().format('MMMM YYYY');

                        if (today === item.reviewDate) {
                          item.IsDueThisMonth = true;
                        } else {
                          item.IsDueThisMonth = false;
                        }
                        item.isChecked = $scope.selectedAnnualReviewIds.includes(
                          item.FamilyID,
                        );
                      });
                      if (typeof response.data.TotalCount !== 'undefined') {
                        params.total(response.data.TotalCount);
                      } else {
                        params.total(0);
                      }
                      $scope.checkedTableData = [];
                      $scope.isLoading = false;
                      $scope.totalCount = response.data.TotalCount;
                      return $scope.annualReviewListTable;
                    }
                    $scope.isLoading = false;
                  },
                  () => {},
                );
            },
          },
        );

        $scope.reloadTable = () => {
          $scope.annualReviewTableParams.reload();
        };
      };
      $scope.adviserChanged = () => {
        adviserChanged(
          $scope.selectedAnnualReviewIds,
          $scope.annualReviewTableParams,
        );
      };
      $scope.onActionSelected = onActionSelectedUtil($scope);

      // Date Range Filter
      $scope.dateRangeFilter = () => {
        if (!$scope.mainObj.dateRange) {
          return $scope.loadAnnualReviewTable();
        }
        if ($scope.mainObj.dateRange === 'this') {
          $scope.mainObj.formDate = moment()
            .startOf('month')
            // eslint-disable-next-line sonarjs/no-duplicate-string
            .format('D MMMM YYYY');
          $scope.mainObj.toDate = moment().endOf('month').format('D MMMM YYYY');
        } else if ($scope.mainObj.dateRange === 'next') {
          const $date = moment().add(1, 'months');
          $scope.mainObj.formDate = moment($date)
            .startOf('month')
            .format('D MMMM YYYY');
          $scope.mainObj.toDate = moment($date)
            .endOf('month')
            .format('D MMMM YYYY');
        }
        $scope.selectedAnnualReviewIds.splice(
          0,
          $scope.selectedAnnualReviewIds.length,
        );
        $scope.loadAnnualReviewTable();
      };

      // Set Annual Review as Complete
      $scope.setAnnualReviewCompilation = (annualReview) => {
        setAnnualReviewCompilationUtil({
          annualReview,
          customerCareService,
          table: $scope.annualReviewTableParams,
        });
      };

      // Open modal For Get Annual Review Detail in Mobile
      $scope.openModalForGetAnnualReviewDetail = (annualReview, size) => {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/annual_review_modal.html',
          controller: 'GetAnnualReviewDetailModalCtrl',
          size,
          resolve: {
            annualReview: () => annualReview,
          },
        });
      };

      $scope.openSendEmailModal = (size, annualReviewObj, source) => {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_email_modal.html',
          resolve: {
            annualReviewListTable() {
              return $scope.annualReviewListTable;
            },
            loadAnnualReviewTable() {
              return $scope.reloadTable;
            },
            annualReviewObj: () => annualReviewObj,
            source: () => source,
          },
          controller: 'AnnualReviewSendEmailModalCtrl',
          size,
        });
      };

      $scope.sendingResult = sendingResultUtil($scope);

      $scope.openSendMailModal = (size, annualReviewObj, source) => {
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_mail_modal.html',
          resolve: {
            listTableData: () => {
              return $scope.annualReviewListTable;
            },
            sendMailObj: () => annualReviewObj,
            source: () => source,
            currentModuleId: () => {
              return ANNUAL_REVIEWS_ID;
            },
            currentModuleType: modalParamsUtil.currentModuleType(
              CUSTOMER_CARE_TYPES.ANNUAL_REVIEWS,
            ),
            currentModuleName: modalParamsUtil.currentModuleName(
              'Annual Reviews',
            ),
            isInsurance: modalParamsUtil.isInsurance(false),
            tab: modalParamsUtil.tab(null),
          },
          controller: 'OpenSendMailModalCtrl',
          size,
        });
        $scope.sendingResult(modalInstance, 'reloadTable');
      };
      $scope.openSMSModal = (size, annualReviewObj, source) => {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_sms_modal.html',
          // CLIENT_ANNUAL_REVIEW
          resolve: {
            annualReviewListTable() {
              return $scope.annualReviewListTable;
            },
            loadAnnualReviewTable() {
              return $scope.reloadTable;
            },
            annualReviewObj: () => annualReviewObj,
            source: () => source,
          },
          controller: 'AnnualReviewSMSModalCtrl',
          size,
        });
      };

      initializeAnnualReviewDateRangeSetting($scope);
      const init = () => {
        $scope.reloadCallback = $scope.loadAnnualReviewTable;
        $scope.onDateRangeChange = onDateRangeChange($scope);
        $scope.dateRangeFilter();
        $scope.loadAnnualReviewTable();
        $scope.initValues({ configService, TAB_DETAILS: TAB_ANNUAL_REVIEWS });
      };

      init();
      $scope.$on('$destroy', () => {
        $timeout.cancel($scope.reloadTimeout);
        $scope.reloadTimeout = null;
      });
    });
