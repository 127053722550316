import template from './timeline.html';
import controller from './timelineCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    onRefreshNotes: '&',
    onRefreshContactTimeline: '&',
    parentToChildNotificationRegistration: '&',
    isBusiness: '<',
    isMobileDevice: '<',
  },
};
