import template from './emailEditor.html';
import controller from './emailEditorCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isRecipient: '<',
    isCc: '<',
    isSubject: '<',
    isSend: '<',
    isSending: '<',
    btnSendText: '<',
    emailObj: '<',
    onEmailChange: '&',
    onSend: '&',
    recipientsChoices: '<',
    isEmailObjects: '<',
  },
};
