import template from './insurancePipelineStatus.html';
import controller from './insurancePipelineStatusCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    myParam: '<',
  },
};
