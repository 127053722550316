import { NOTES_TABLE_SIZE } from 'Common/constants/pageTable';
import { splitByCapitalLetters } from 'Common/utilities/string';

export default class MycrmTableCtrl {
  constructor(contactsNotesService) {
    'ngInject';

    this.contactsNotesService = contactsNotesService;
  }

  $onInit() {
    this.tableSizes = NOTES_TABLE_SIZE;
  }

  onTablePaginationChange({ pageNumber, pageSize, isPageSizeChanged = false }) {
    this.onPageChange({ pageNumber, pageSize });
    if (isPageSizeChanged) {
      this.contactsNotesService.setDisplayCount({ displayCount: pageSize });
    }
  }

  onSort(column) {
    if (!column.isSortable) {
      return;
    }

    const isCurrentColumn = column.value === this.sortColumn;
    const currentColumn = this.sortOrder === 'asc' ? 'desc' : 'asc';
    const sortOrder = isCurrentColumn
      ? currentColumn
      : column.defaultSort || 'asc';
    this.onSortColumn({ sortColumn: column.value, sortOrder });
  }

  getSortClass(column) {
    const isSortedColumn =
      column.isSortable && column.value === this.sortColumn;
    return isSortedColumn ? `sort-${this.sortOrder || 'asc'}` : '';
  }

  getColumnWidthSize() {
    const columnCount = this.columns?.length || 0;
    if (columnCount < 4) {
      return 'xl';
    } else if (columnCount < 6) {
      return 'l';
    } else if (columnCount < 8) {
      return 'm';
    } else if (columnCount < 10) {
      return 's';
    } else {
      return 'xs';
    }
  }

  getClassName(value) {
    const columnValue = splitByCapitalLetters(value, '-', true);
    const columnWidth = `col-width-${this.getColumnWidthSize()}`;
    return `${columnValue} ${columnWidth}`;
  }
}
