import template from './existingAssetList.html';
import controller from './existingAssetListCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
  },
};
