export const USERS_TAB = {
  profile: 0,
  subscriptions: 1,
  'referral-options': 2,
  'email-signature': 3,
  templates: 4,
  'leads-management': 5,
  branding: 6,
  'my-lead-generator': 7,
  accreditation: 8,
};

export const DEFAULT_USERS_TAB = 'profile';
