import angular from 'angular';
import get from 'lodash/get';

function clickMapObject(googleMapDiv) {
  const [fullscreenMapButton] = googleMapDiv.querySelectorAll(
    '.gm-control-active.gm-fullscreen-control',
  );
  const clickObject = get(fullscreenMapButton, '__e3_.click', null);
  if (!clickObject) {
    return;
  }
  const [objectIndex] = Object.keys(clickObject);
  const validClickObject = objectIndex && typeof objectIndex.g === 'function';
  if (!validClickObject) {
    return;
  }
  clickObject[objectIndex].g();
}

export const loadModule = () =>
  angular
    .module('app')
    .directive('googleAddressThumbnail', function googleAddressThumbnail(
      $timeout,
    ) {
      return {
        restrict: 'A',
        link(scope, element) {
          const timeout = $timeout(() => {
            const childNodes = element[0].children;
            const thumbnail = childNodes[0];

            if (thumbnail) {
              thumbnail.addEventListener('click', () => {
                const [, googleMapDiv] = childNodes;
                if (!googleMapDiv) {
                  return;
                }
                clickMapObject(googleMapDiv);
              });
            }
            $timeout.cancel(timeout);
          });
        },
      };
    });
