import { PICKER_RANGE } from 'Common/constants/pickerRange';
import { calendarDateFormatter, mapDateRange } from 'Common/utilities/date';
import { get } from 'lodash';

class CommissionFilterInlineCtrl {
  constructor(modalRenderService, commissionHelperService) {
    'ngInject';

    this.modalRenderService = modalRenderService;
    this.commissionHelperService = commissionHelperService;
  }

  $onInit() {
    this.filterRange = PICKER_RANGE;
  }

  onCalendarUpdated({ filterData }) {
    const mappedFilterData = mapDateRange(filterData);
    this.filterSettings.date.displayFilterDate = calendarDateFormatter(
      this.activeRangeIndex,
      mappedFilterData,
    );
    this.filterSettings.date.filter = mappedFilterData;
    this.filterSettings.date.isOpen = false;

    this.proceedFilter();
  }

  onRangeSelected(activeRangeIndex) {
    this.filterSettings.date = {
      ...this.filterSettings.date,
      activeRangeIndex,
    };
    this.activeRangeIndex = activeRangeIndex;
  }

  onToggleProviderFilter(isSelectAll) {
    this.lenderList = this.lenderList.map((lender) => {
      const { ProviderId } = lender;
      lender.ticked = isSelectAll ? !!ProviderId : !ProviderId;
      return lender;
    });
    const [allType] = this.lenderList;
    this.lenders = [...[allType]];
    this.filterSettings.lenderId = null;
    this.proceedFilter();
  }

  onUpdateProviderFilter(data) {
    const { ProviderId, ticked } = data;
    if (!ProviderId || (!ticked && !get(this.lenders, 'length', 0))) {
      return this.onToggleProviderFilter();
    }
    this.lenderList[0].ticked = false;
    let selectedLenderIds = [];
    this.lenders = this.lenders.reduce((initial, lender) => {
      const { ProviderId: value } = lender;
      if (!value) {
        return initial;
      }
      selectedLenderIds = [...selectedLenderIds, ...[value]];
      return [...initial, ...[lender]];
    }, []);
    this.filterSettings.lenderId = selectedLenderIds.join(',');
    this.proceedFilter();
  }

  onToggleTypeFilter(isSelectAll) {
    this.commissionTypeList = this.commissionTypeList.map((type) => {
      const { Value } = type;
      type.ticked = isSelectAll ? !!Value : !Value;
      return type;
    });
    const [allType] = this.commissionTypeList;
    this.commissionTypes = [...[allType]];
    this.filterSettings.commissionTypeIds = null;
    this.proceedFilter();
  }

  onUpdateTypeFilter(data) {
    const { Value, ticked } = data;
    if (!Value || (!ticked && !get(this.commissionTypes, 'length', 0))) {
      return this.onToggleTypeFilter();
    }
    this.commissionTypeList[0].ticked = false;
    let selectedTypeValues = [];
    this.commissionTypes = this.commissionTypes.reduce((initial, type) => {
      const { Value: value } = type;
      if (!value) {
        return initial;
      }
      selectedTypeValues = [...selectedTypeValues, ...[value]];
      return [...initial, ...[type]];
    }, []);
    this.filterSettings.commissionTypeIds = selectedTypeValues.join(',');
    this.proceedFilter();
  }

  onToggleAdviserFilter(isSelectAll) {
    this.adviserList = this.adviserList.map((broker) => {
      const { BrokerID } = broker;
      broker.ticked = isSelectAll ? !!BrokerID : !BrokerID;
      return broker;
    });
    const [allBroker] = this.adviserList;
    this.advisers = [...[allBroker]];
    this.filterSettings.adviserIds = null;
    this.proceedFilter();
  }

  onUpdateAdviserFilter(data) {
    const { BrokerID, ticked } = data;
    if (!BrokerID || (!ticked && !get(this.advisers, 'length', 0))) {
      return this.onToggleAdviserFilter();
    }
    this.adviserList[0].ticked = false;
    let selectedBrokerIds = [];
    this.advisers = this.advisers.reduce((initial, adviser) => {
      const { BrokerID: brokerId } = adviser;
      if (!brokerId) {
        return initial;
      }
      selectedBrokerIds = [...selectedBrokerIds, ...[brokerId]];
      return [...initial, ...[adviser]];
    }, []);
    this.filterSettings.adviserIds = selectedBrokerIds.join(',');
    this.proceedFilter();
  }

  onTogglePayeeFilter(isSelectAll) {
    this.payeeList = this.payeeList.map((payee) => {
      const { BrokerID } = payee;
      payee.ticked = isSelectAll ? !!BrokerID : !BrokerID;
      return payee;
    });
    const [allBroker] = this.payeeList;
    this.payees = [...[allBroker]];
    this.filterSettings.payeeIds = null;
    this.proceedFilter();
  }

  onUpdatePayeeFilter(data) {
    const { BrokerID, ticked } = data;
    if (!BrokerID || (!ticked && !get(this.payees, 'length', 0))) {
      return this.onTogglePayeeFilter();
    }
    this.payeeList[0].ticked = false;
    let selectedBrokerIds = [];
    this.payees = this.payees.reduce((initial, payee) => {
      const { BrokerID: brokerId } = payee;
      if (!brokerId) {
        return initial;
      }
      selectedBrokerIds = [...selectedBrokerIds, ...[brokerId]];
      return [...initial, ...[payee]];
    }, []);
    this.filterSettings.payeeIds = selectedBrokerIds.join(',');
    this.proceedFilter();
  }

  proceedFilter() {
    !this.commissionHelperService.isMobileDevice &&
      this.onFilterChange({ filterSettings: this.filterSettings });
  }

  onFilterCommissionModal(filterSettings) {
    this.onFilterChange(filterSettings);
    this.filterModal && this.filterModal.close();
  }

  onShowMobileFilter() {
    const props = {
      filterSettings: this.filterSettings,
      lenders: this.lenders,
      advisers: this.advisers,
      payees: this.payees,
      commissionTypes: this.commissionTypes,
      lenderList: this.lenderList,
      adviserList: this.adviserList,
      payeeList: this.payeeList,
      commissionTypeList: this.commissionTypeList,
      onFilterCommission: (filterSettings) =>
        this.onFilterCommissionModal(filterSettings),
      onCalendarUpdated: (filterData) => this.onCalendarUpdated(filterData),
      onRangeSelected: (activeRangeIndex) =>
        this.onRangeSelected(activeRangeIndex),
      onToggleProviderFilter: (isSelectAll) =>
        this.onToggleProviderFilter(isSelectAll),
      onUpdateProviderFilter: ({ data }) => {
        this.onUpdateProviderFilter(data);
      },
      onToggleAdviserFilter: () => this.onToggleAdviserFilter(),
      onUpdateAdviserFilter: ({ data }) => {
        this.onUpdateAdviserFilter(data);
      },
      onTogglePayeeFilter: () => this.onTogglePayeeFilter(),
      onUpdatePayeeFilter: ({ data }) => {
        this.onUpdatePayeeFilter(data);
      },
      onToggleTypeFilter: () => this.onToggleTypeFilter(),
      onUpdateTypeFilter: ({ data }) => {
        this.onUpdateTypeFilter(data);
      },
    };

    this.filterModal = this.modalRenderService.openCommissionMobileFilter(
      props,
    );
    this.filterModal.result.catch((error) => console.warn(error.message));
  }
}

export default CommissionFilterInlineCtrl;
