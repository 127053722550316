import _ from 'lodash';
import {
  toastSaveSuccess,
  toastSuccess,
  toastError,
  showActionConfirmation,
} from 'Common/utilities/alert';

const MODULE_PROFILER = 2;
export default class InsuranceOpportunitiesTableCtrl {
  constructor(
    $state,
    NgTableParams,
    insurancePipelineService,
    insuranceQuoteService,
    currentUserService,
  ) {
    'ngInject';

    this.$state = $state;
    this.NgTableParams = NgTableParams;
    this.userInfo = currentUserService;
    this.toastSaveSuccess = toastSaveSuccess;
    this.insurancePipelineService = insurancePipelineService;
    this.insuranceQuoteService = insuranceQuoteService;
    this.reportTypeCounting = MODULE_PROFILER;
  }

  $onInit() {
    this.isLoading = true;
    this.isSorting = false;
    this.searchString = null;
    this.totalRecords = 0;
    this.currentPage = 1;
    this.tableData = [];

    this.defaultTableSettings = {
      page: 1,
      count: 10,
      sorting: {
        lastUpdated: 'desc',
      },
    };

    this.loadTableData();
  }

  $onChanges(changes) {
    if (changes.searchString) {
      this.isLoading = true;
      this.currentPage = 1;
      if (this.defaultTableSettings) {
        this.defaultTableSettings.page = 1;
      }
      if (this.tableParams) {
        this.tableParams.reload();
      }
    }
  }

  onPageChange(event) {
    this.isLoading = true;
    this.defaultTableSettings.page = event.$e.pageNumber;
    this.currentPage = event.$e.pageNumber;
    this.tableParams.reload();
  }

  viewClient(clientId) {
    this.$state.go('app.contactsSingle', {
      familyId: clientId,
    });
  }

  viewQuote(quote) {
    if (quote.module === MODULE_PROFILER) {
      this.$state.go('app.insuranceProfilerDetails', {
        insProfilerId: quote.id,
        familyId: quote.familyId,
      });
      return;
    }
    this.$state.go('app.quickQuoteDetails', {
      insQuoteId: quote.id,
      familyId: quote.familyId,
    });
  }

  deleteQuote(quote) {
    if (quote.module === MODULE_PROFILER) {
      this.removeSavedProfiler(quote.id);
      return;
    }
    this.removeSavedQuote(quote.id);
  }

  insuranceServiceDeleteQuote(quoteId) {
    return this.insuranceQuoteService
      .insuranceQuoteDelete(quoteId)
      .then(({ data }) => {
        if (data === 1) {
          toastSuccess('Success', 'Insurance Quote successfully removed', true);
          this.tableParams.reload();
        } else {
          toastError('Insurance Profiler was not removed');
        }
      });
  }

  removeSavedQuote(quoteId) {
    showActionConfirmation(
      'Are you sure?',
      'Insurance Quote will be removed.',
      () => this.insuranceServiceDeleteQuote(quoteId),
    );
  }

  insuranceServiceDeleteProfiler(profilerQuoteId) {
    return this.insuranceQuoteService
      .deleteInsuranceProfilerByProfilerID(profilerQuoteId)
      .then(({ data }) => {
        if (data === 1) {
          toastSuccess(
            'Success',
            'Insurance Profiler successfully removed',
            true,
          );
          this.isLoading = true;
          this.tableParams.reload();
        } else {
          toastError('Insurance Profiler was not removed');
        }
      });
  }

  removeSavedProfiler(profilerQuoteId) {
    showActionConfirmation(
      'Are you sure?',
      'Insurance Profiler will be removed.',
      () => this.insuranceServiceDeleteProfiler(profilerQuoteId),
    );
  }

  loadTableData() {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const getInitials = (initial = '') => {
      return (
        initial
          .split(' ')
          .map((n) => n[0])
          .join('') || ''
      );
    };
    const ifSortReportType = (sortObj) => {
      const isReportType = _.has(sortObj, 'reportType');
      if (this.reportTypeCounting === MODULE_PROFILER && isReportType) {
        this.reportTypeCounting = this.defaultTableSettings.page;
        return 'ASC';
      }
    };

    const tableCtrl = this;
    this.tableParams = new this.NgTableParams(tableCtrl.defaultTableSettings, {
      counts: [],
      total: 0,
      getData(params) {
        const sortBy = params.orderBy().join('').slice(1) || '';
        const sortOrderValues = _.values(params.sorting());
        const hasSortingValues = sortOrderValues.join('').toUpperCase() || '';
        const sortOrder =
          ifSortReportType(params.sorting()) || hasSortingValues;

        const newFilter = {
          searchString: tableCtrl.searchString,
          sortBy,
          sortOrder,
          pageNumber: tableCtrl.currentPage,
          pageSize: 10,
        };

        tableCtrl.isSorting = !tableCtrl.isLoading;

        return tableCtrl.insurancePipelineService
          .getInsurancePipelineOpportunities(newFilter)
          .then(({ items, pageNumber, pageSize, totalCount }) => {
            const quoteData = items.map((quote) => {
              quote.initials = getInitials(quote.adviserName);
              return quote;
            });
            tableCtrl.isSorting = false;
            tableCtrl.isLoading = false;
            tableCtrl.tableData = quoteData;
            tableCtrl.totalRecords = totalCount;
            tableCtrl.currentPage = pageNumber;
            tableCtrl.pageSize = pageSize;

            tableCtrl.onTableDataLoaded({
              $event: {
                tableData: tableCtrl.tableData,
              },
            });

            if (tableCtrl.totalRecords) {
              params.total(tableCtrl.totalRecords);
            } else {
              params.total(0);
            }
            return tableCtrl.tableData;
          });
      },
    });
  }
}
