class AirTableModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'air-table';
  }

  getLenderInsurers(lenderId) {
    return this.httpClient.get(`${this.apiBaseUrl}/lmi/${lenderId}/insurers`);
  }

  getInsurerProducts({ lenderId, insurerId, includeDefault }) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/lmi/${lenderId}/insurers/${insurerId}/products`,
      { includeDefault },
    );
  }
}

export default AirTableModelService;
