import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('InsAppApplicationCtrl', function InsAppApplicationCtrl(
      $rootScope,
      $scope,
      $timeout,
      $interval,
      $compile,
      contactService,
      loanScenarioService,
      loanApplicationServices,
      toaster,
      $state,
      $stateParams,
      utilitiesService,
      $uibModal,
      colorService,
      $document,
      insuranceAppService,
    ) {
      /*
        * @WORKFLOW: insAppId from start of this page needs to come from a page that contains
        *           list of applications for insurance. For the meantime we will use
                    insurance application ID = 5-8. This is necessary to start insurance application.
        */

      const PARTYTYPE_APPLICANTS = '1'; // check
      const PARTYTYPE_GUARANTORS = '3'; // use 1
      const PARTYTYPE_DEPENDANTS = '4'; // use 1
      const PARTYTYPE_SOLICITOR = '5'; // use 3
      const PARTYTYPE_ACCOUNTANT = '6'; // use 3

      // GETTERS
      $scope.resetSearch = (selectedPartyType) => {
        if (selectedPartyType === $scope.selectedPartyType) {
          $scope.selectedPartyType = 0;
        }
        // default ~ data should be PARTYTYPES
        else {
          $scope.selectedPartyType = selectedPartyType;
        }

        $scope.selectedClientName = '';
        $scope.selectedClient = null;
        $scope.getClientList = [];
      };

      $scope.resetSearch(0); // runs at init of page

      $scope.$watch('selectedClientName', (newVal) => {
        if (
          $scope.selectedClient &&
          newVal !==
            `${$scope.selectedClient.FirstName} ${$scope.selectedClient.LastName}`
        ) {
          $scope.selectedClient = null;
        }
      });

      function clientGetFormatter(obj) {
        let background;
        let initials;
        let FirstName;
        let LastName;
        let ClientID;
        let ClientTypeID;
        let FamilyID;
        const formatted = [];
        for (let i = 1; i <= obj.length; i++) {
          background = colorService.getRandomColor();
          FirstName =
            obj[i - 1].FirstName != null
              ? obj[i - 1].FirstName
              : 'No FirstName';
          LastName =
            obj[i - 1].LastName != null ? obj[i - 1].LastName : 'No LastName';
          initials = utilitiesService.filterInitial(FirstName, LastName);
          ClientID = obj[i - 1].ClientID;
          ClientTypeID = obj[i - 1].ClientTypeID;
          FamilyID = obj[i - 1].FamilyID;

          formatted.push({
            background,
            initials,
            FirstName,
            LastName,
            ClientID,
            ClientTypeID,
            FamilyID,
          });
        }
        return formatted;
      }

      function getNotes(section) {
        insuranceAppService
          .applicationSectionNotesGet($scope.insAppId, section)
          .then((response) => {
            const data = response.data;

            if (section === 1) {
              $scope.invPartiesNotes = data;
            }
          });
      }

      // eslint-disable-next-line sonarjs/cognitive-complexity
      function applicationClientGetMethod(appId, clientTypeParam) {
        let clientType = clientTypeParam;
        const originalClientType = clientType;
        if (parseInt(clientType, 10) === 3) {
          clientType = 1;
        }

        insuranceAppService
          .applicationClientGet(appId, clientType)
          .then((response) => {
            clientType = originalClientType;

            $scope.listClients = response.data;

            switch (clientType) {
              case 1: {
                $scope.listApplicants = clientGetFormatter($scope.listClients);

                const list = $scope.listClients;
                const obj = [];

                if (_.size(list) > 0) {
                  for (const element of list) {
                    obj.push({
                      FamilyId: element.FamilyID,
                      FamilyFullName: `${element.FirstName} ${element.LastName}`,
                    });
                  }
                }
                $scope.DependantsFamilyDropdown = obj;
                break;
              }
              case 3:
                $scope.listDependants = [];
                for (let i = 0; i < $scope.listClients.length; i++) {
                  contactService
                    .clientInformGet($scope.listClients[i].FamilyID)
                    .then((res) => {
                      const newData = res.data;

                      for (const element of newData) {
                        if (element && element.Role === 'Child') {
                          $scope.listDependants.push({
                            initials: utilitiesService.filterInitialOneString(
                              element.FirstName,
                            ),
                            FirstName: element.FirstName,
                            background: colorService.getRandomColor(),
                          });
                        }
                      }
                    });
                }
                break;

              case 4:
                $scope.listOwners = clientGetFormatter($scope.listClients);
                break;
              default:
                toaster.pop(
                  'error',
                  'Sorry',
                  'Unable to process your request.',
                );
                break;
            }
          });
      }

      $scope.searchClient = function (searchString) {
        $scope.clientNotFound = false;

        if (searchString) {
          // Override : Some part type are equivalent to the same party type
          let partyType = $scope.selectedPartyType;

          if (
            parseInt(partyType, 10) === parseInt(PARTYTYPE_GUARANTORS, 10) ||
            parseInt(partyType, 10) === parseInt(PARTYTYPE_DEPENDANTS, 10)
          ) {
            partyType = PARTYTYPE_APPLICANTS;
          }

          if (
            parseInt(partyType, 10) === parseInt(PARTYTYPE_SOLICITOR, 10) ||
            parseInt(partyType, 10) === parseInt(PARTYTYPE_ACCOUNTANT, 10)
          ) {
            partyType = '4';
          }

          loanScenarioService
            .scenarioSearchClientGet(searchString, partyType)
            .then((response) => {
              const data = response.data;

              _.map(data, (o) => {
                o.initials = utilitiesService.filterInitial(
                  o.FirstName,
                  o.LastName,
                );
                return o;
              });

              $scope.getClientList = data;
            });
        }
      };

      // Select a client or loop through client from family id you've created from the create contact
      $scope.selectClient = function (client) {
        $scope.selectedClient = client;
        $scope.selectedClientName = `${$scope.selectedClient.FirstName} ${$scope.selectedClient.LastName}`;
        $scope.saveSelectClient();
      };

      function applicationClientSetMethod(appId, clientId, clientType) {
        insuranceAppService
          .applicationClientSet(appId, clientId, clientType)
          .then(
            () => {
              toaster.pop(
                'success',
                'Success!',
                'New applicant were added successfully!',
              );
              applicationClientGetMethod($scope.insAppId, clientType);
            },
            () => {
              toaster.pop(
                'warning',
                'Unable to Add',
                'Parties were not added, please try again',
              );
            },
          );
      }

      function clientInformGet(obj) {
        contactService.clientInformGet(obj.FamilyId).then((response) => {
          const data = response.data;

          switch (parseInt(obj.PartyType, 10)) {
            case 1:
              applicationClientSetMethod(
                $scope.insAppId,
                parseInt(data[0].PersonId, 10),
                parseInt(obj.PartyType, 10),
              );
              break;
            case 3:
              applicationClientGetMethod($scope.insAppId, 3);
              break;
            // eslint-disable-next-line sonarjs/no-duplicated-branches
            case 4:
              applicationClientSetMethod(
                $scope.insAppId,
                parseInt(data[0].PersonId, 10),
                parseInt(obj.PartyType, 10),
              );
              break;
            default:
              break;
          }
        });
      }

      // SETTERS
      function applicationSectionNotesSet(appId, sectionId, note) {
        insuranceAppService
          .applicationSectionNotesSet(appId, sectionId, note)
          .then(
            (response) => {
              const data = response.data;
              $scope.notesSet = data;
            },
            (error) => {
              console.log(error);
            },
          );
      }

      $scope.saveSectionNotes = function (section) {
        switch (section) {
          case 1:
            applicationSectionNotesSet(
              $scope.insAppId,
              section,
              $scope.invPartiesNotes[0].SectionNotes,
            );
            break;
          case 2:
            break;
          case 3:
            break;
          default:
            break;
        }
      };

      $scope.saveSelectClient = function () {
        const cType = $scope.selectedPartyType;

        switch (cType) {
          case 1:
            applicationClientSetMethod(
              $scope.insAppId,
              parseInt($scope.selectedClient.ClientEntityId, 10),
              cType,
            );
            break;
          case 2:
            toaster.pop('warning', 'Warning!', 'Cannot add client type.');
            break;
          case 3:
            toaster.pop('success', 'Success!', 'New dependent added');
            applicationClientGetMethod($scope.insAppId, cType);
            break;
          // eslint-disable-next-line sonarjs/no-duplicated-branches
          case 4:
            applicationClientSetMethod(
              $scope.insAppId,
              parseInt($scope.selectedClient.ClientEntityId, 10),
              cType,
            );
            break;
          default:
            toaster.pop('warning', 'Warning!', 'Failed to add new applicant.');
            break;
        }
        $scope.resetSearch(0);
      };

      $scope.countApplicants = function () {
        return _.size($scope.listApplicants);
      };

      $scope.countOwners = function () {
        return _.size($scope.listOwners);
      };

      // Delete client
      $scope.deleteClient = function (client) {
        _.remove($scope.listApplicants, (o) => {
          return _.isEqual(o, client);
        });

        // eslint-disable-next-line sonarjs/no-duplicate-string
        toaster.pop('info', 'Please Wait...', '');
        insuranceAppService
          .applicationClientDelete(
            $scope.insAppId,
            client.ClientID,
            client.ClientTypeID,
          )
          .then((response) => {
            if (parseInt(response.data, 10) === 1) {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              toaster.pop('success', 'Deleted', 'Deleted successfully');
            } else {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              toaster.pop('error', 'Error', 'Yikes! Something is wrong');
            }
          });
      };

      // Delete owners
      $scope.deleteOwner = function (owner) {
        _.remove($scope.listOwners, (o) => {
          return _.isEqual(o, owner);
        });

        toaster.pop('info', 'Please Wait...', '');
        insuranceAppService
          .applicationClientDelete(
            $scope.insAppId,
            owner.ClientID,
            owner.ClientTypeID,
          )
          // eslint-disable-next-line sonarjs/no-identical-functions
          .then((response) => {
            if (parseInt(response.data, 10) === 1) {
              toaster.pop('success', 'Deleted', 'Deleted successfully');
            } else {
              toaster.pop('error', 'Error', 'Yikes! Something is wrong');
            }
          });
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      function getNewContactModel(role) {
        return {
          FamilyId: '0',
          LastName: '',
          FirstName: '',
          MiddleName: '',
          PreferredName: '',
          Title: '',
          Gender: '',
          DOB: '',
          Role: role,
          Employment: [
            {
              Occupation: '',
              OccupationClass: 0,
              Employer: '',
            },
          ],
          SmokerStatus: '',
          Deceased: false,
          DOBReminder: false,
          BestTimeToCall: '',
          IsResident: true,
          Phone: [
            {
              Type: 'Home',
              Number: '',
            },
            {
              Type: 'Work',
              Number: '',
            },
            {
              Type: 'Mobile',
              Number: '',
            },
          ],
          Email: [
            {
              Type: 'Email',
              EmailAddress: '',
            },
          ],
          Notes: '',
        };
      }

      // // Add Related Modal
      $scope.personCompanyModal = function (type) {
        // $scope.accountType = type;
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/insuranceTools/insApp/modal/personCompany.add.html',
          controller: 'PreAssessPersonCompanyModalCtrl',
          scope: $scope,
          size: 'sm',
          resolve: {
            selectedPartyType() {
              return type;
            },
            clientInformGet() {
              return clientInformGet;
            },
            getNewContactModel() {
              return getNewContactModel;
            },
          },
        });

        modalInstance.result.then(
          (response) => {
            $scope.selectClient(
              {},
              true,
              response.FamilyId,
              response.PartyType,
            );
            // $rootScope.$broadcast('REFRESH_RELATED_CLIENTS');
          },
          () => {
            $rootScope.$broadcast('REFRESH_RELATED_CLIENTS');
          },
        );
      };

      $scope.Dependants = {};
      // Add Dependants button
      $scope.addDependants = function (obj) {
        obj.PartyType = '3';

        $scope.ContactSet = getNewContactModel('Child');
        $scope.ContactSet.FamilyId = obj.FamilyId;
        $scope.ContactSet.FirstName = obj.FirstName;

        contactService.addContact([$scope.ContactSet]).then(() => {
          clientInformGet(obj);
        });

        // $scope.Dependants = {};
      };

      $scope.deleteDependant = function (dependant) {
        _.remove($scope.listDependants, (o) => {
          return _.isEqual(o, dependant);
        });

        toaster.pop('info', 'Please Wait...', '');
        insuranceAppService
          .applicationClientDelete(
            $scope.insAppId,
            dependant.ClientID,
            dependant.ClientTypeID,
          )
          // eslint-disable-next-line sonarjs/no-identical-functions
          .then((response) => {
            if (parseInt(response.data, 10) === 1) {
              toaster.pop('success', 'Deleted', 'Deleted successfully');
            } else {
              toaster.pop('error', 'Error', 'Yikes! Something is wrong');
            }
          });
      };

      $scope.appInit = function () {
        $scope.uibReturnedValue = {};
        $scope.IdentificationSet = {};
        $scope.AddressHistorySet = {};
        $scope.EmploymentSet = {};

        $scope.DependantsSet = {};
        $scope.DependantsSet.NoDependants = false;

        $scope.FamilyList = {};
        $scope.DependantsFamilyDropdown = {};

        // Temporary
        $scope.listDependants = [];
        $scope.listApplicants = [];
        $scope.listOwners = [];

        getNotes(1);
        applicationClientGetMethod($scope.insAppId, 1); // Get list of Applicants
        applicationClientGetMethod($scope.insAppId, 3); // Get list of dependents
        applicationClientGetMethod($scope.insAppId, 4); // Get list of Owners
      };

      $scope.appInit();
    });
