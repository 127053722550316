import moment from 'moment';
import {
  NZ_DATE_INPUT_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';

export function insurerFileBuilderForUI(thumbnailUrl, fileUrl, type, fileName) {
  return {
    fileName,
    thumbnailUrl,
    fileUrl,
    type,
  };
}

export function insuranceQuoteBrochureBuilderForUI(insuranceQuoteBrochure) {
  const policyBrochures =
    insuranceQuoteBrochure.Brochures &&
    insuranceQuoteBrochure.Brochures.map((file) => {
      return insurerFileBuilderForUI(
        file.BrochureThumbnailUrl,
        file.BrochureUrl,
        'Policy Brochure',
        file.BrochureName,
      );
    });
  const policyDocuments =
    insuranceQuoteBrochure.Documents &&
    insuranceQuoteBrochure.Documents.map((file) => {
      return insurerFileBuilderForUI(
        file.DocumentThumbnailUrl,
        file.DocumentUrl,
        'Policy Document',
        file.DocumentName,
      );
    });
  const downloadableFiles = [
    ...(policyBrochures || []),
    ...(policyDocuments || []),
  ];
  return {
    insurerId: insuranceQuoteBrochure.ProviderID,
    insurerName: insuranceQuoteBrochure.ProviderName,
    insurerLogoUrl: insuranceQuoteBrochure.ProviderLogoUrl,
    policyBrochures,
    policyDocuments,
    downloadableFiles,
  };
}

export function sendReportViaEmailBuilderForMyCRM(email) {
  return {
    BenefitIDs: email.benefitIds,
    ProviderIDs: email.providerIds,
    IncludeBrochure: email.includeBrochures,
    IncludeDocuments: email.includePolicyDocuments,
    SendToCurrentUser: email.sendToCurrentUser,
    FamilyID: parseInt(email.familyId, 10),
    QuoteID: parseInt(email.quoteId, 10),
    ReportOptions: email.reportOptions,
    Subject: email.subject,
    Content: email.body,
    HasAttachment: true,
  };
}

export function insuranceQuoteBenefitColorBuilderForUI(benefitColor) {
  return {
    benefitType: benefitColor.ID,
    color: benefitColor.ColorCode,
  };
}

export function policyOwnershipBuilderForUI(policyOwnership) {
  return {
    id: policyOwnership.POID,
    clientId: policyOwnership.ClientID,
    fullName: policyOwnership.FullName,
    isDependent: policyOwnership.IsDependent,
    isOwnership: policyOwnership.IsOwnership,
    isInsured: policyOwnership.IsInsured,
    other: policyOwnership.Other,
  };
}

export function policyOwnershipBuilderForMyCRM(policyOwnership) {
  return {
    POID: policyOwnership.id,
    ClientID: policyOwnership.clientId,
    FullName: policyOwnership.fullName,
    IsDependent: policyOwnership.isDependent,
    IsOwnership: policyOwnership.isOwnership,
    IsInsured: policyOwnership.isInsured,
    Other: policyOwnership.other,
  };
}

export function loadingExclusionBuilderForUI(loadingExclusion) {
  return {
    benefitId: loadingExclusion.BenefitID,
    familyId: loadingExclusion.FamilyID,
    policyId: loadingExclusion.PolicyID,
    benefitTypeId: loadingExclusion.BenefitTypeID,
    clientId: loadingExclusion.ClientID,
    isChild: loadingExclusion.IsChild,
    isStandardRates: !loadingExclusion.LoadingExclusion,
    isLoadingExclusion: !!loadingExclusion.LoadingExclusion,
    selectedOptions: loadingExclusion.LoadingExclusionDate ? 0 : 1,
    loading: loadingExclusion.Loading,
    details: loadingExclusion.LoadingExclusionDetails,
    fullName: loadingExclusion.FullName,
    date:
      loadingExclusion.LoadingExclusionDate &&
      moment(loadingExclusion.LoadingExclusionDate).format(
        NZ_DATE_INPUT_FORMAT,
      ),
    tempDate:
      loadingExclusion.LoadingExclusionDate &&
      moment(loadingExclusion.LoadingExclusionDate).format(
        NZ_DATE_INPUT_FORMAT,
      ),
  };
}

export function loadingExclusionBuilderForMyCRM(loadingExclusion) {
  return {
    BenefitID: loadingExclusion.benefitId,
    FamilyID: loadingExclusion.familyId,
    FullName: loadingExclusion.fullName,
    PolicyID: loadingExclusion.policyId,
    BenefitTypeID: loadingExclusion.benefitTypeId,
    ClientID: loadingExclusion.clientId,
    StandardRates: loadingExclusion.isStandardRates,
    LoadingExclusion: loadingExclusion.isLoadingExclusion,
    Loading: loadingExclusion.isLoadingExclusion ? loadingExclusion.loading : 0,
    LoadingExclusionDetails: loadingExclusion.isLoadingExclusion
      ? loadingExclusion.details
      : '',
    LoadingExclusionDate:
      loadingExclusion.isLoadingExclusion && loadingExclusion.tempDate
        ? moment(loadingExclusion.tempDate, NZ_DATE_INPUT_FORMAT).format(
            TO_MYCRM_DATE_FORMAT,
          )
        : '',
  };
}

export function healthCoverBuilderForUI(healthCover) {
  return {
    clientId: healthCover.ClientID,
    fullName: healthCover.ClientName,
    isChild: healthCover.IsChild,
    isBaseCover: healthCover.BaseCover,
    loading: healthCover.Loading,
    excess: healthCover.Excess,
    isSpecialistsTest: healthCover.SpecialistsTest,
    isGpPrescriptions: healthCover.GpPrescriptions,
    isDentalOptical: healthCover.DentalOptical,
  };
}

export function healthCoverBuilderForMyCRM(healthCover) {
  return {
    ClientID: healthCover.clientId,
    BaseCover: healthCover.isBaseCover,
    Loading: healthCover.loading,
    Excess: healthCover.excess,
    SpecialistsTest: healthCover.isSpecialistsTest,
    GpPrescriptions: healthCover.isGpPrescriptions,
    DentalOptical: healthCover.isDentalOptical,
  };
}

export function lifeCoverBuilderForUI(lifeCover) {
  return {
    isIndexed: lifeCover.Indexed,
    isFutureInsurability: lifeCover.FutureInsurability,
    loading: lifeCover.Loading,
    amount: lifeCover.CoverAmount,
    calcPeriod: lifeCover.CalcPeriod,
  };
}

export function lifeCoverBuilderForMyCRM(lifeCover) {
  return {
    Indexed: lifeCover.isIndexed,
    FutureInsurability: lifeCover.isFutureInsurability,
    Loading: lifeCover.loading,
    CoverAmount: lifeCover.amount,
    CalcPeriod: lifeCover.calcPeriod,
  };
}

export function familyProtectionBuilderForUI(familyProtection) {
  return {
    calcPeriod: familyProtection.CalcPeriod,
    isFutureInsurability: familyProtection.FutureInsurability,
    isIndexed: familyProtection.Indexed,
    loading: familyProtection.Loading,
    amount: familyProtection.CoverAmount,
    benefitPeriodTerm: familyProtection.BenefitPeriodTerm,
    benefitPeriodYear: familyProtection.BenefitPeriodYear,
  };
}

export function familyProtectionBuilderForMyCRM(familyProtection) {
  return {
    CalcPeriod: familyProtection.calcPeriod,
    FutureInsurability: familyProtection.isFutureInsurability,
    Indexed: familyProtection.isIndexed,
    Loading: familyProtection.loading,
    CoverAmount: familyProtection.amount,
    BenefitPeriodTerm: familyProtection.benefitPeriodTerm,
    BenefitPeriodYear: familyProtection.benefitPeriodYear,
  };
}

export function traumaCoverBuilderForUI(traumaCover) {
  return {
    isComprehensive: traumaCover.Comprehensive,
    isEarlyCancer: traumaCover.EarlyCancer,
    isEarlyTrauma: traumaCover.EarlyTrauma,
    isTpdExtension: traumaCover.TpdExtension,
    isOwnOccupation: traumaCover.TraumaOwnOccupation,
    isIndexed: traumaCover.Indexed,
    includeChildren: traumaCover.IncludeChildren,
    isLifeBuyBack: traumaCover.LifeBuyBack,
    isTraumaBuyBack: traumaCover.TraumaBuyBack,
    isTpdAddOn: traumaCover.TpdAddOn,
    isChildTrauma: traumaCover.ChildTrauma,
    loading: traumaCover.Loading,
    amount: traumaCover.CoverAmount,
    isStandalone: traumaCover.Standalone,
    isAccelerated: traumaCover.Accelerated,
    isTpdAnyOccupation: traumaCover.TpdAnyOccupation,
    isTpdOwnOccupation: traumaCover.TpdOwnOccupation,
    childAmount: traumaCover.ChildCoverAmount,
    calcPeriod: traumaCover.CalcPeriod,
    seriousTraumaCoverAmount: traumaCover.SeriousTraumaCoverAmount,
    isSeriousTrauma: traumaCover.IsSeriousTrauma,
  };
}

export function traumaCoverBuilderForMyCRM(traumaCover) {
  return {
    Comprehensive: traumaCover.isComprehensive,
    EarlyCancer: traumaCover.isEarlyCancer,
    EarlyTrauma: traumaCover.isEarlyTrauma,
    TpdExtension: traumaCover.isTpdExtension,
    TraumaOwnOccupation: traumaCover.isOwnOccupation,
    Indexed: traumaCover.isIndexed,
    IncludeChildren: traumaCover.includeChildren,
    LifeBuyBack: traumaCover.isLifeBuyBack,
    TraumaBuyBack: traumaCover.isTraumaBuyBack,
    TpdAddOn: traumaCover.isTpdAddOn,
    ChildTrauma: traumaCover.isChildTrauma,
    Loading: traumaCover.loading,
    CoverAmount: traumaCover.amount,
    Standalone: traumaCover.isStandalone,
    Accelerated: traumaCover.isAccelerated,
    TpdAnyOccupation: traumaCover.isTpdAnyOccupation,
    TpdOwnOccupation: traumaCover.isTpdOwnOccupation,
    ChildCoverAmount: traumaCover.childAmount,
    CalcPeriod: traumaCover.calcPeriod,
    SeriousTraumaCoverAmount: traumaCover.seriousTraumaCoverAmount,
    IsSeriousTrauma: traumaCover.isSeriousTrauma,
    TraumaChild: [0],
  };
}

export function tpdCoverBuilderForUI(tpdCover) {
  return {
    calcPeriod: tpdCover.CalcPeriod,
    isIndexed: tpdCover.Indexed,
    loading: tpdCover.Loading,
    amount: tpdCover.CoverAmount,
    isOwnOccupation: tpdCover.OwnOccupation,
    isAnyOccupation: tpdCover.AnyOccupation,
    isStandalone: tpdCover.Standalone,
    isAccelerated: tpdCover.Accelerated,
  };
}

export function tpdCoverBuilderForMyCRM(tpdCover) {
  return {
    CalcPeriod: tpdCover.calcPeriod,
    Indexed: tpdCover.isIndexed,
    Loading: tpdCover.loading,
    CoverAmount: tpdCover.amount,
    OwnOccupation: tpdCover.isOwnOccupation,
    AnyOccupation: tpdCover.isAnyOccupation,
    TpdOwnOccupation: tpdCover.isOwnOccupation,
    TpdAnyOccupation: tpdCover.isAnyOccupation,
    Standalone: tpdCover.isStandalone,
    Accelerated: tpdCover.isAccelerated,
  };
}

export function incomeProtectionCoverBuilderForUI(incomeProtectionCover) {
  return {
    isPremier: incomeProtectionCover.IPPremier,
    type: incomeProtectionCover.IPCoverType,
    calcPeriod: incomeProtectionCover.CalcPeriod,
    isIndexed: incomeProtectionCover.Indexed,
    isBooster: incomeProtectionCover.Booster,
    amount: incomeProtectionCover.CoverAmount,
    loading: incomeProtectionCover.Loading,
    weekWaitPeriod: incomeProtectionCover.WeekWaitPeriod,
    benefitPeriod: incomeProtectionCover.BenefitPeriod,
    isTaxable: incomeProtectionCover.IsTaxable,
    frequency: incomeProtectionCover.IPFrequency,
  };
}

export function incomeProtectionCoverBuilderForMyCRM(incomeProtectionCover) {
  return {
    IPPremier: incomeProtectionCover.isPremier,
    IPCoverType: incomeProtectionCover.type,
    CalcPeriod: incomeProtectionCover.calcPeriod,
    Indexed: incomeProtectionCover.isIndexed,
    Booster: incomeProtectionCover.isBooster,
    CoverAmount: incomeProtectionCover.amount,
    Loading: incomeProtectionCover.loading,
    WeekWaitPeriod: incomeProtectionCover.weekWaitPeriod,
    BenefitPeriod: incomeProtectionCover.benefitPeriod,
    IsTaxable: incomeProtectionCover.isTaxable,
    IPFrequency: incomeProtectionCover.frequency,
  };
}

export function mortgageRepaymentCoverBuilderForUI(mortgageRepaymentCover) {
  return {
    calcPeriod: mortgageRepaymentCover.CalcPeriod,
    isIndexed: mortgageRepaymentCover.Indexed,
    loading: mortgageRepaymentCover.Loading,
    amount: mortgageRepaymentCover.CoverAmount,
    weekWaitPeriod: mortgageRepaymentCover.WeekWaitPeriod,
    benefitPeriod: mortgageRepaymentCover.BenefitPeriod,
  };
}

export function mortgageRepaymentCoverBuilderForMyCRM(mortgageRepaymentCover) {
  return {
    CalcPeriod: mortgageRepaymentCover.calcPeriod,
    Indexed: mortgageRepaymentCover.isIndexed,
    Loading: mortgageRepaymentCover.loading,
    CoverAmount: mortgageRepaymentCover.amount,
    WeekWaitPeriod: mortgageRepaymentCover.weekWaitPeriod,
    BenefitPeriod: mortgageRepaymentCover.benefitPeriod,
  };
}

export function redundancyCoverBuilderForUI(redundancyCover) {
  return {
    calcPeriod: redundancyCover.CalcPeriod,
    weekWaitPeriod: redundancyCover.WeekWaitPeriod,
    isIndexed: redundancyCover.Indexed,
    loading: redundancyCover.Loading,
    amount: redundancyCover.CoverAmount,
    hasMpCover: redundancyCover.HasMpCover,
    mrpCoverAmount: redundancyCover.MRPCoverAmount,
  };
}

export function redundancyCoverBuilderForMyCRM(redundancyCover) {
  return {
    CalcPeriod: redundancyCover.calcPeriod,
    WeekWaitPeriod: redundancyCover.weekWaitPeriod,
    Indexed: redundancyCover.isIndexed,
    Loading: redundancyCover.loading,
    CoverAmount: redundancyCover.amount,
    HasMpCover: redundancyCover.hasMpCover,
    MRPCoverAmount: redundancyCover.mrpCoverAmount,
  };
}

export function mortgageLifeCoverBuilderForUI(mortgageLifeCover) {
  return {
    isIndexed: mortgageLifeCover.Indexed,
    isFutureInsurability: mortgageLifeCover.FutureInsurability,
    loading: mortgageLifeCover.Loading,
    amount: mortgageLifeCover.CoverAmount,
    calcPeriod: mortgageLifeCover.CalcPeriod,
  };
}

export function mortgageLifeCoverBuilderForMyCRM(mortgageLifeCover) {
  return {
    Indexed: mortgageLifeCover.isIndexed,
    FutureInsurability: mortgageLifeCover.isFutureInsurability,
    Loading: mortgageLifeCover.loading,
    CoverAmount: mortgageLifeCover.amount,
    CalcPeriod: mortgageLifeCover.calcPeriod,
  };
}

export function mortgageInterruptionBuilderForUI(mortgageInterruptionCover) {
  return {
    coverAmount: mortgageInterruptionCover.CoverAmount,
    repaymentAmount: mortgageInterruptionCover.RepaymentAmount,
    calculationPeriod: mortgageInterruptionCover.CalculationPeriod,
  };
}
export function insuranceDetailsClientBenefitsForUI(benefits) {
  return {
    healthCover:
      benefits.HealthCover && benefits.HealthCover.map(healthCoverBuilderForUI),
    lifeCover: benefits.LifeCover && lifeCoverBuilderForUI(benefits.LifeCover),
    familyProtection:
      benefits.FamilyProtection &&
      familyProtectionBuilderForUI(benefits.FamilyProtection),
    traumaCover:
      benefits.TraumaCover && traumaCoverBuilderForUI(benefits.TraumaCover),
    tpdCover:
      benefits.TotalAndPermanentDisability &&
      tpdCoverBuilderForUI(benefits.TotalAndPermanentDisability),
    incomeProtectionCover:
      benefits.IncomeProtection &&
      incomeProtectionCoverBuilderForUI(benefits.IncomeProtection),
    mortgageRepaymentCover:
      benefits.MortgageRepaymentCover &&
      mortgageRepaymentCoverBuilderForUI(benefits.MortgageRepaymentCover),
    redundancyCover:
      benefits.RedundancyCover &&
      redundancyCoverBuilderForUI(benefits.RedundancyCover),
    mortgageLifeCover:
      benefits.MortgageLifeCover &&
      mortgageLifeCoverBuilderForUI(benefits.MortgageLifeCover),
    mortgageInterruptionCover:
      benefits.MortgageInterruption &&
      mortgageInterruptionBuilderForUI(benefits.MortgageInterruption),
    waiverOfPremium: {
      weekWaitPeriod: benefits.WaiverOfPremium.WeekWaitPeriod,
    },
  };
}

export function insuranceDetailsClientBenefitsForMyCRM(benefits) {
  return {
    HealthCover:
      benefits.healthCover &&
      benefits.healthCover.map(healthCoverBuilderForMyCRM),
    LifeCover:
      benefits.lifeCover && lifeCoverBuilderForMyCRM(benefits.lifeCover),
    FamilyProtection:
      benefits.familyProtection &&
      familyProtectionBuilderForMyCRM(benefits.familyProtection),
    TraumaCover:
      benefits.traumaCover && traumaCoverBuilderForMyCRM(benefits.traumaCover),
    TotalAndPermanentDisability:
      benefits.tpdCover && tpdCoverBuilderForMyCRM(benefits.tpdCover),
    IncomeProtection:
      benefits.incomeProtectionCover &&
      incomeProtectionCoverBuilderForMyCRM(benefits.incomeProtectionCover),
    MortgageRepaymentCover:
      benefits.mortgageRepaymentCover &&
      mortgageRepaymentCoverBuilderForMyCRM(benefits.mortgageRepaymentCover),
    RedundancyCover:
      benefits.redundancyCover &&
      redundancyCoverBuilderForMyCRM(benefits.redundancyCover),
    MortgageLifeCover:
      benefits.mortgageLifeCover &&
      mortgageLifeCoverBuilderForMyCRM(benefits.mortgageLifeCover),
    WaiverOfPremium: {
      WeekWaitPeriod: (benefits.waiverOfPremium || { weekWaitPeriod: null })
        .weekWaitPeriod,
    },
  };
}

export function insuranceDetailsBuilderForUI(data) {
  const { InsuranceDetails: insuranceDetails, ClientBenefits: benefits } = data;
  return {
    benefitId: data.BenefitID,
    familyId: data.FamilyID,
    policyId: insuranceDetails.PolicyID,
    benefitTypeId: insuranceDetails.BenefitTypeID,
    qprBenefitId: insuranceDetails.QPRBenefitID,
    providerId: insuranceDetails.ProviderID,
    productId: insuranceDetails.ProductID,
    clientId: insuranceDetails.ClientID,
    isCurrentClaim: insuranceDetails.CurrentClaim,
    isInArrears: insuranceDetails.InArears,
    policyNumber: insuranceDetails.PolicyNumber,
    statusId: insuranceDetails.StatusID,
    vitalityDiscountSelected: insuranceDetails.VitalityDiscountSelected,
    vitalityFee: insuranceDetails.VitalityFee,
    vitalityFeeSelected: insuranceDetails.VitalityFeeSelected,
    commencementDate:
      insuranceDetails.CommencementDate &&
      moment(insuranceDetails.CommencementDate).format(NZ_DATE_INPUT_FORMAT),
    tempCommencementDate:
      insuranceDetails.CommencementDate &&
      moment(insuranceDetails.CommencementDate).format(NZ_DATE_INPUT_FORMAT),
    deferralReviewDate:
      insuranceDetails.DeferralReviewDate &&
      moment(insuranceDetails.DeferralReviewDate).format(NZ_DATE_INPUT_FORMAT),
    tempDeferralReviewDate:
      insuranceDetails.DeferralReviewDate &&
      moment(insuranceDetails.DeferralReviewDate).format(NZ_DATE_INPUT_FORMAT),
    paymentFrequency: insuranceDetails.PaymentFrequencyID,
    waivePolicy: insuranceDetails.WaivePolicy,
    isPremiumAutoCalc: insuranceDetails.AutoPremium,
    isPolicyFeeAutoCalc: insuranceDetails.AutoPolicyFee,
    totalPremium: insuranceDetails.TotalPremium,
    policyFee: insuranceDetails.PolicyFee,
    notes: insuranceDetails.BenefitNotes,
    policyOwnership: insuranceDetails.PolicyOwnership.map(
      policyOwnershipBuilderForUI,
    ),
    loadingExclusions:
      insuranceDetails.LoadingExclusions &&
      insuranceDetails.LoadingExclusions.map(loadingExclusionBuilderForUI),
    benefits: insuranceDetailsClientBenefitsForUI(benefits),
  };
}

export function insuranceDetailsBuilderForMyCRM(insuranceDetails) {
  const { benefits } = insuranceDetails;
  return {
    BenefitID: insuranceDetails.benefitId,
    FamilyID: insuranceDetails.familyId,
    InsuranceDetails: {
      PolicyID: insuranceDetails.policyId,
      BenefitTypeID: insuranceDetails.benefitTypeId,
      QPRBenefitID: insuranceDetails.qprBenefitId,
      ProviderID: insuranceDetails.providerId,
      ProductID: insuranceDetails.productId,
      ClientID: insuranceDetails.clientId,
      CurrentClaim: insuranceDetails.isCurrentClaim,
      InArears: insuranceDetails.isInArrears,
      PolicyNumber: insuranceDetails.policyNumber,
      StatusID: insuranceDetails.statusId,
      VitalityDiscountSelected: insuranceDetails.vitalityDiscountSelected,
      VitalityFee: insuranceDetails.vitalityFee,
      VitalityFeeSelected: insuranceDetails.vitalityFeeSelected,
      CommencementDate:
        insuranceDetails.commencementDate &&
        moment(insuranceDetails.commencementDate, NZ_DATE_INPUT_FORMAT).format(
          TO_MYCRM_DATE_FORMAT,
        ),
      DeferralReviewDate:
        insuranceDetails.deferralReviewDate &&
        moment(
          insuranceDetails.deferralReviewDate,
          NZ_DATE_INPUT_FORMAT,
        ).format(TO_MYCRM_DATE_FORMAT),
      PaymentFrequencyID: insuranceDetails.paymentFrequency,
      WaivePolicy: insuranceDetails.waivePolicy,
      AutoPremium: insuranceDetails.isPremiumAutoCalc,
      AutoPolicyFee: insuranceDetails.isPolicyFeeAutoCalc,
      TotalPremium: insuranceDetails.totalPremium,
      PolicyFee: insuranceDetails.policyFee,
      BenefitNotes: insuranceDetails.notes,
      PolicyOwnership: insuranceDetails.policyOwnership.map(
        policyOwnershipBuilderForMyCRM,
      ),
      LoadingExclusions:
        insuranceDetails.loadingExclusions &&
        insuranceDetails.loadingExclusions.map(loadingExclusionBuilderForMyCRM),
    },
    ClientBenefits: insuranceDetailsClientBenefitsForMyCRM(benefits),
  };
}

export function benefitDetailsBuilderForUI(benefitDetails) {
  return {
    id: benefitDetails.ID,
    familyClientId: benefitDetails.FamilyClientID,
    clientId: benefitDetails.ClientID,
    firstName: benefitDetails.FirstName,
    lastName: benefitDetails.LastName,
    benefitId: benefitDetails.BenefitID,
    benefitName: benefitDetails.BenefitName,
    coverAmount: benefitDetails.CoverAmount,
    statusName: benefitDetails.StatusName,
    policyNumber: benefitDetails.PolicyNumber,
    policyId: benefitDetails.PolicyID,
    benefitTotalPremium: benefitDetails.BenefitTotalPremium,
    frequency: benefitDetails.Frequency,
    calculationPeriod: benefitDetails.CalculationPeriod,
    deferralReviewDate: benefitDetails.DeferralReviewDate,
    dateStarted: benefitDetails.DateStarted,
    hasLoadingExclusion: benefitDetails.HasLoadingExclusion,
    policyFee: benefitDetails.PolicyFee,
    statusId: benefitDetails.StatusId,
    isDisabled: benefitDetails.IsDisabled,
  };
}

export function insuranceApplicationBuilderForUI(insuranceDetails) {
  return {
    providerId: insuranceDetails.ProviderID,
    providerName: insuranceDetails.ProviderName,
    statusId: insuranceDetails.StatusID,
    statusName: insuranceDetails.StatusName,
    policyNumber: insuranceDetails.PolicyNumber,
    totalPolicyFee: insuranceDetails.TotalPolicyFee,
    totalPremiums: insuranceDetails.TotalPremiums,
    vitalityDiscountSelected: insuranceDetails.VitalityDiscountSelected,
    vitalityFee: insuranceDetails.VitalityFee,
    vitalityFeeSelected: insuranceDetails.VitalityFeeSelected,
    applicationId: insuranceDetails.ApplicationID,
    isApplication: insuranceDetails.IsApplication,
    isEsigned: insuranceDetails.IsEsigned,
    isFromFnGTable: insuranceDetails.IsFromFnGTable,
    applicationUrl: insuranceDetails.ApplicationUrl,
    quoteId: insuranceDetails.QuoteID,
    statusCategory: insuranceDetails.StatusCategory,
    isInviteClientToCompleteSent: insuranceDetails.IsInviteClientToCompleteSent,
    totalMonthlyPolicyFee: insuranceDetails.TotalMonthlyPolicyFee,
    totalMonthlyPremiums: insuranceDetails.TotalMonthlyPremiums,
    invitedClientId: insuranceDetails.InvitedClientID,
    clientFirstName: insuranceDetails.ClientFirstName,
    clientLastName: insuranceDetails.ClientLastName,
    isCompletedAndESigned: insuranceDetails.IsEsigned,
    notCompletedAndESigned: !insuranceDetails.IsEsigned,
    benefitDetails:
      insuranceDetails.BenefitDetails &&
      insuranceDetails.BenefitDetails.map(benefitDetailsBuilderForUI),
  };
}
