import template from './generateCqpOptions.html';
import controller from './generateCqpOptionsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    loanDetails: '<',
    cpaCqpSet: '<',
    calculatorFundsSummary: '<',
    isAssetFinance: '<',
    isAppendedOnly: '<',
  },
};
