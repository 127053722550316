import template from './marketingAutomationSubs.html';
import controller from './marketingAutomationSubsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    showMarketingAutomation: '<',
    nurtureSub: '<',
    stayInTouchSub: '<',
    isNurtureSubbed: '<',
    isNurtureDisabled: '<',
    isStayInTouchAll: '<',
    stayInTouchSubList: '<',
    setNurtureSubscription: '&',
    selectStayInTouchItem: '&',
    changeAllStayInTouch: '&',
    openStayInTouchModal: '&',
    showTransactionalEmailsOnly: '&',
  },
};
