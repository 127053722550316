import template from './submissionWarningBanner.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    validation: '<',
    errorCount: '<',
    errorToggle: '&',
  },
};
