import template from './onboardingAuthorityAndConsentSummary.html';
import OnboardingAuthorityAndConsentCtrl from './onboardingAuthorityAndConsentCtrl';

export default {
  template,
  controller: OnboardingAuthorityAndConsentCtrl,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    onTick: '&',
  },
};
