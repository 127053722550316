import moment from 'moment';
import { remove } from 'lodash';
import { DATE_COUNT } from 'Common/constants/dateRange';
import { EMPLOYMENT } from 'Common/constants/employment';
import { TO_MYCRM_DATE_FORMAT } from 'Common/constants/dateConfigs';

class EmploymentService {
  constructor(configService, loanApplicationServices, currentUserService) {
    'ngInject';

    this.configService = configService;
    this.loanApplicationServices = loanApplicationServices;
    this.currentUserService = currentUserService;
    this.validityInMonths =
      DATE_COUNT.MONTHS_IN_YEAR * EMPLOYMENT.VALID_YEAR_LENGTH;
  }

  isCurrentEmploymentStatusShown() {
    return (
      this.configService.feature &&
      this.configService.feature.currentEmploymentStatus
    );
  }

  getEmployment(loanAppId, EmpId) {
    return this.loanApplicationServices
      .getEmployment(loanAppId, EmpId)
      .then((response) => {
        return response && response.data ? response.data : [];
      });
  }

  countYears(family) {
    const yearsEmployed = [];
    let months = 0;
    family.Employment &&
      family.Employment.forEach((person) => {
        if (!person.DateStarted) {
          return;
        }
        const dateEnded =
          person.DateEnded && person.DateEnded.Month && person.DateEnded.Year
            ? moment(
                `${person.DateEnded.Year}/${person.DateEnded.Month}`,
                TO_MYCRM_DATE_FORMAT,
              )
            : moment(Date.now());
        const start = moment(
          `${person.DateStarted.Year}/${person.DateStarted.Month}`,
          TO_MYCRM_DATE_FORMAT,
        );
        months += dateEnded.diff(start, 'months');
      });
    const frequency = months;
    yearsEmployed.push({
      familyId: family.FamilyId,
      name: family.FamilyName,
      frequency,
    });
    return { yearsEmployed, compareFrequency: this.validityInMonths };
  }

  countEmploymentYears(loanAppId, EmpId) {
    return this.getEmployment(loanAppId, EmpId).then((response) => {
      const Employment = {
        AllList: response,
      };
      let countedYears = {};
      let yearsEmployed = [];
      Employment.AllList &&
        Employment.AllList.forEach((family) => {
          countedYears = this.countYears(family);
          yearsEmployed = [...countedYears.yearsEmployed, ...yearsEmployed];
        });
      remove(yearsEmployed, (o) => {
        return o.frequency >= countedYears.compareFrequency;
      });
      return yearsEmployed;
    });
  }
}

export default EmploymentService;
