import moment from 'moment';
import { DATE_TEXT_FORMAT } from 'Common/constants/dateConfigs';
import { getDatePickerConfig, validateDate } from 'Common/utilities/date';

export default class LinkedTermDateCtrl {
  $onInit() {
    const commonConfig = {
      formatDay: 'dd',
      formatMonth: 'MMM',
      formatYear: 'yy',
      showWeeks: false,
    };
    this.dateConfig = {
      ...getDatePickerConfig(),
      dateOptions: {
        ...commonConfig,
        maxMode: 'day',
      },
    };
    this.datePickerConfig = {
      ...getDatePickerConfig(),
      dateOptions: commonConfig,
    };
  }

  updateFinishDate() {
    const months = this.useDynamicList
      ? this.model.termSelected
      : this.model.termSelected * 12;
    this.model.dateEnd = moment(this.model.dateStart)
      .add(months, 'months')
      .format(DATE_TEXT_FORMAT);
  }

  toggleLinker() {
    this.model.isLinked = !this.model.isLinked;
    this.model.isLinked && this.updateFinishDate();
    this.onUpdateField({ data: this.model });
  }

  updateModel(key, data) {
    this.model[key] = data;
    const shouldUpdateFinishDate =
      (key === 'dateStart' || key === 'termSelected') &&
      this.model.isLinked &&
      this.model.dateStart &&
      this.model.termSelected;

    const isDateKey = key === 'dateStart' || key === 'dateEnd';
    const updatedProp = isDateKey ? validateDate(data) : data;
    shouldUpdateFinishDate && this.updateFinishDate();
    this.onUpdateField({ data: { ...this.model, [key]: updatedProp } });
  }
}
