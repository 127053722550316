import angular from 'angular';

export const loadModule = () =>
  angular.module('app').factory('taskModel', [
    'httpRequest',
    function (httpRequest) {
      var apiBaseUrl = 'tasks/';

      function taskModel() {}

      taskModel.prototype = {
        taskCompletionSet(familyID, taskID, isComplete, callback) {
          httpRequest.post(
            `${apiBaseUrl}TaskCompletionSet?familyID=${familyID}&taskID=${taskID}&isComplete=${isComplete}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },

        taskSet($postData, callback) {
          httpRequest.post(apiBaseUrl, $postData, (response) => {
            return callback(response);
          });
        },
        taskDetailsGet(taskId, callback) {
          httpRequest.get(`${apiBaseUrl}${taskId}`, (response) => {
            return callback(response);
          });
        },
        taskTemplateListGet(callback) {
          httpRequest.get(`${apiBaseUrl}TaskTemplateListGet`, (response) => {
            return callback(response);
          });
        },
        scenarioListGet(familyID, callback) {
          httpRequest.get(
            `LoanScenario/ScenarioListGet?familyId=${familyID}`,
            (response) => {
              return callback(response);
            },
          );
        },
        taskDelete(familyId, taskId, callback) {
          httpRequest.delete(
            `${apiBaseUrl}${taskId}?FamilyID=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getTaskTemplateSettings(taskTemplateId, callback) {
          httpRequest.get(
            `${apiBaseUrl}templates/${taskTemplateId}/settings`,
            (response) => {
              return callback(response);
            },
          );
        },
        updateDueDateSubsequently($postData, taskId, callback) {
          httpRequest.post(
            `${apiBaseUrl}due-estimate?taskSettingsId=${taskId}`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },
        storeTask($postData, callback) {
          httpRequest.post(apiBaseUrl, $postData, (response) => {
            return callback(response);
          });
        },
        updateTask($putData, callback) {
          httpRequest.put(
            `${apiBaseUrl}${$putData.TaskID}`,
            $putData,
            (response) => {
              return callback(response);
            },
          );
        },
        TaskSetv3Multiple($postData, callback, errorCallback) {
          httpRequest.post(
            `${apiBaseUrl}templated`,
            $postData,
            (response) => {
              return callback(response);
            },
            (err) => {
              return errorCallback(err);
            },
          );
        },
        searchFamily(searchCriteria, contactType, assignToAdviserID, callback) {
          var $url = `${apiBaseUrl}TaskFamilySearchGet?searchCriteria=${searchCriteria}`;
          if (contactType != undefined) {
            $url += `&contactType=${contactType}`;
          }
          if (assignToAdviserID != undefined) {
            $url += `&assignToAdviserID=${assignToAdviserID}`;
          }
          httpRequest.get($url, (response) => {
            return callback(response);
          });
        },
        TaskColumnSettingsGet(callback) {
          httpRequest.get(`${apiBaseUrl}TaskColumnSettingsGet`, (response) => {
            return callback(response);
          });
        },
        TaskColumnSettingsSet($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}TaskColumnSettingsSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },
        taskBatchCompletion($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}batch/completion`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },
        taskSingleCompletion(taskId, familyID, isComplete, callback) {
          httpRequest.put(
            `${apiBaseUrl}${taskId}/completion?id=${taskId}&familyID=${familyID}&isComplete=${isComplete}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        taskPdf(
          adviserFilter = 0,
          dateFilter = 'all',
          createdbyFilter = 0,
          familyId = 0,
          isShowCompleted = false,
          sortColumn = 'ActivityDate',
          sortType = 'ASC',
          taskSearch = '',
          callback,
          errorCallback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}pdf?adviserFilter=${adviserFilter}&dateFilter=${dateFilter}&createdbyFilter=${createdbyFilter}&familyId=${familyId}&isShowCompleted=${isShowCompleted}&sortColumn=${sortColumn}&sortType=${sortType}&taskSearch=${taskSearch}`,
            (response) => {
              return callback(response);
            },
            (err) => {
              return errorCallback(err);
            },
          );
        },
      };

      return taskModel;
    },
  ]);
