import { get } from 'lodash';
import { PICKER_RANGE } from 'Common/constants/pickerRange';
import { SORT_TYPE } from 'Common/constants/sort';
import { textLimit } from 'Common/utilities/string';
import { YYYY_MM_DD_MOMENT_FORMAT } from 'Common/constants/dateConfigs';
import moment from 'moment';
import { calendarDateFormatter } from 'Common/utilities/date';
import {
  COMMISSION_TAB_INDEX,
  DEFAULT_COMMISSION_TAB,
  COMMISSION_TAB_URL,
} from 'Common/constants/commission';

export default class CommissionManagementCtrl {
  constructor(
    $state,
    stateService,
    contactSharedData,
    commissionService,
    optionsService,
    currentUserService,
    commissionModalService,
    commissionHelperService,
  ) {
    'ngInject';

    this.$state = $state;
    this.stateService = stateService;
    this.commissionModalService = commissionModalService;
    this.contactSharedData = contactSharedData;
    this.commissionService = commissionService;
    this.optionsService = optionsService;
    this.currentUserService = currentUserService;
    this.commissionHelperService = commissionHelperService;
    this.childComponentNotificationRegistration = this.childComponentNotificationRegistration.bind(
      this,
    );
    this.childComponentEventHandler = {
      commissionLandingEventHandler: null,
      commissionSearchEventHandler: null,
      commissionPaymentsEventHandler: null,
    };
  }

  $onInit() {
    this.getActiveTab(this.$state.params.activeTab || DEFAULT_COMMISSION_TAB);
    this.setDefaults();
    this.getCommissionTypes();
    this.getLenderList();
    this.getBrokers();
    this.shortNameLimit = 10;
  }

  setDefaults() {
    const { familyId, hasAdviserAccess } = this.currentUserService;
    const adviserId = hasAdviserAccess
      ? familyId
      : get(this.brokers, '[1].BrokerID') || '';
    const dateEnd = moment().format(YYYY_MM_DD_MOMENT_FORMAT);
    const dateStart = moment()
      .subtract(30, 'days')
      .format(YYYY_MM_DD_MOMENT_FORMAT);
    this.default = {
      date: {
        isOpen: false,
        displayFilterDate: calendarDateFormatter(PICKER_RANGE.CUSTOM, {
          DateStart: dateStart,
          DateEnd: dateEnd,
        }),
        activeRangeIndex: PICKER_RANGE.LAST_30_DAYS,
        filter: {
          DateStart: dateStart,
          DateEnd: dateEnd,
        },
      },
      adviserId,
      adviserIds: adviserId,
      searchPlaceHolder: 'Search...',
      sortType: SORT_TYPE.DESC,
    };
    this.commissionHelperService.isMobileDevice = this.isMobileDevice;
    this.commissionHelperService.exportIcon =
      '<excel-icon class="icon"></excel-icon>';
  }

  getCommissionTypes() {
    this.commissionService.getTypeList().then(({ data }) => {
      this.types = [...data];
      return this.types;
    });
  }

  getLenderList() {
    this.commissionService.getProviderList().then(({ data }) => {
      this.lenders = data.map((lender) => {
        const { ProviderId, ProviderName } = lender;
        return {
          shortName: ProviderId
            ? textLimit(ProviderName, this.shortNameLimit, true)
            : ProviderName,
          ...lender,
        };
      });
    });
  }

  getBrokers() {
    this.commissionService.getAllBrokers().then(({ data }) => {
      const formattedBrokers = data.map((broker) => {
        return {
          shortName: textLimit(
            broker.BrokerFullName,
            this.shortNameLimit,
            true,
          ),
          ...broker,
        };
      });
      this.currentUserService.isCorporate && formattedBrokers.shift();
      this.brokers = [...formattedBrokers];
      return this.setDefaults();
    });
  }

  childComponentNotificationRegistration(handler, handlerType) {
    this.childComponentEventHandler[handlerType] = handler;
  }

  initLandingCommissions() {
    this.childComponentEventHandler.commissionLandingEventHandler &&
      this.childComponentEventHandler.commissionLandingEventHandler(
        'initLandingCommissions',
      );
  }

  initSearchCommissions() {
    this.childComponentEventHandler.commissionSearchEventHandler &&
      this.childComponentEventHandler.commissionSearchEventHandler(
        'initSearchCommissions',
      );
  }

  initPaymentsCommissions() {
    this.childComponentEventHandler.commissionPaymentsEventHandler &&
      this.childComponentEventHandler.commissionPaymentsEventHandler(
        'initPaymentsCommissions',
      );
  }

  getActiveTab(activeTab) {
    if (!activeTab) {
      return;
    }
    this.active = this.contactSharedData.getUsersActiveIndexTab(
      activeTab,
      COMMISSION_TAB_INDEX,
    );
    this.selectedTab = activeTab;
    this.stateTransition(activeTab);
  }

  stateTransition(tab) {
    if (!tab) {
      return;
    }
    const url =
      (Object.values(COMMISSION_TAB_URL).some((path) => path === tab) && tab) ||
      DEFAULT_COMMISSION_TAB;
    this.$state.params.activeTab = url;
    this.stateService.transition(this.$state.current, this.$state.params);
    switch (url) {
      case COMMISSION_TAB_URL.COMMISSION:
        this.initLandingCommissions();
        break;
      case COMMISSION_TAB_URL.SEARCH:
        this.initSearchCommissions();
        break;
      case COMMISSION_TAB_URL.PAYMENT_ADVICE:
        this.initPaymentsCommissions();
        break;
      default:
    }
  }

  onClickTab() {
    const selectedTabName =
      Object.keys(COMMISSION_TAB_INDEX).find(
        (key) => COMMISSION_TAB_INDEX[key] === this.active,
      ) || DEFAULT_COMMISSION_TAB;
    const { activeTab } = this.$state.params;
    if (selectedTabName === activeTab) {
      return;
    }
    this.selectedTab = activeTab;
    this.stateTransition(selectedTabName);
  }

  launchCommissionEnquiry() {
    this.commissionModalService.launchCommissionEnquiryModal(
      this.brokers,
      this.lenders,
    );
  }
}
