import { get } from 'lodash';
import { PIPELINE_CATEGORY } from 'Common/constants/pipelineStatus';
import { LOAN_STATUS_CATEGORY } from 'Common/constants/loanStatusCategory';
import {
  INSURANCE_PACK_TYPE,
  PACK_MODE,
  GUIDE_TYPE,
} from 'Common/constants/helloBook';
import { PHONE_TYPE } from 'Common/constants/phoneMobileFormat';

export const setupDynamicRenderHtmlContent = ({ vm }) => {
  vm.renderFactFindContent = () => {
    if (vm.model.sections.onlineFactFind && vm.isBrandingReady) {
      const full =
        vm.model.factFindType === vm.factFindType.FULL &&
        `<p">
          I’ve invited you to complete an online fact find. Filling this out lets me come up with a solution that
          best suits your needs.
          Click the Get Started button below.
        </p>`;
      const partial =
        vm.model.factFindType === vm.factFindType.PARTIAL &&
        `<div class="fact-find-selected-items">
          <p>I’ve invited you to answer the following questions in the online fact find:</p>
          <ul>
            ${
              vm.factFindSections &&
              vm.factFindSections
                .filter((section) => section.selected)
                .map(
                  (section) => `
              <li>${section.name}</li>
            `,
                )
                .join('')
            }
          </ul>
        </div>`;
      return `
        <div class="fact-find-content">
          ${full || ''}
          ${partial || ''}
          <div class="btn-container disabled">
            <div class="button btn-dummy-link" style="background-color:${
              vm.adviserTheme.sidebarBgColor
            };">Get Started</div>
          </div>
        </div>
      `;
    }

    return '';
  };

  vm.getThumbnailHtml = (fileName, alt) => {
    return fileName
      ? `
      <div class="pdf-thumbnail">
        <img ng-src="/assets/images/helloBook/${fileName}.png" alt="${alt}">
      </div>
    `
      : '';
  };

  vm.renderCreditGuideThumbnail = () => {
    if (!vm.adviserTheme) {
      return '';
    }

    return vm.adviserTheme && vm.isBYOB
      ? vm.getThumbnailHtml('credit_guide_byob_thumb', 'Credit Guide BYOB')
      : vm.getThumbnailHtml('credit_guide_lm_thumb', 'Credit Guide LM');
  };

  vm.renderHelloBookThumbnail = () => {
    if (!vm.adviserTheme) {
      return '';
    }

    return vm.adviserTheme && vm.isBYOB
      ? vm.getThumbnailHtml('hello_book_byob_thumb', 'Hello Book BYOB')
      : vm.getThumbnailHtml('hello_book_lm_thumb', 'Hello Book LM');
  };

  vm.renderCalendyThumbnail = () => {
    if (!vm.adviserTheme) {
      return '';
    }

    return vm.adviserTheme && vm.isBYOB
      ? vm.getThumbnailHtml('calendar_byob_thumb', 'Calendly BYOB')
      : vm.getThumbnailHtml('calendar_lm_thumb', 'Calendly LM');
  };

  vm.renderAttachmentThumbnail = () => {
    if (!vm.adviserTheme) {
      return '';
    }

    return vm.adviserTheme && vm.isBYOB
      ? vm.getThumbnailHtml('attachment_byob_thumb', 'Attachment BYOB')
      : vm.getThumbnailHtml('attachment_lm_thumb', 'Attachment LM');
  };

  vm.renderHelloBookAndCreditGuideContent = () => {
    const guideType = vm.currentUserService.isAU
      ? GUIDE_TYPE.AU
      : GUIDE_TYPE.NZ;
    const helloCreditGuideAboutUs = `
      <p>
        <a class="dummy-link" style="color:${vm.adviserTheme.sidebarBgColor};">Learn about us</a>
        Find out who we are & how we help in your Hello Book & ${guideType}
      </p>
    `;
    const helloBookAndCreditGuide = !vm.adviserTheme
      ? ''
      : `<div class="hbox">
      ${vm.renderHelloBookThumbnail()}  
      ${helloCreditGuideAboutUs}
    </div>`;

    const helloBookAboutUs = `
      <p>
        <a class="dummy-link" style="color:${vm.adviserTheme.sidebarBgColor};">Learn about us</a>
        Find out who we are & how we help in your Hello Book
      </p>
    `;
    const helloBook = !vm.adviserTheme
      ? ''
      : `
    <div class="hbox">
      ${vm.renderHelloBookThumbnail()}  
      ${helloBookAboutUs}
    </div>`;

    const creditGuideAboutUs = `
      <p>
        <a class="dummy-link" style="color:${vm.adviserTheme.sidebarBgColor};">Things you should know</a>
        Our ${guideType} explains our obligations to you
      </p>
    `;
    const creditGuide = !vm.adviserTheme
      ? ''
      : `
    <div class="hbox">
      ${vm.renderCreditGuideThumbnail()}  
      ${creditGuideAboutUs}
    </div>`;

    const {
      helloBook: isHelloBook,
      creditGuide: isCreditGuide,
      disclosureGuide: isDisclosureGuide,
    } = vm.model.sections;
    const {
      showCreditGuide: isShowCreditGuide,
      showDisclosureGuide: isShowDisclosureGuide,
    } = vm;
    const isGuideIncluded = isCreditGuide || isDisclosureGuide;
    const isShowGuide = isShowCreditGuide || isShowDisclosureGuide;

    let content;
    if (isHelloBook && isGuideIncluded && isShowGuide) {
      content = helloBookAndCreditGuide;
      vm.helloBookCreditMessage = helloCreditGuideAboutUs;
    }
    if (isHelloBook && !isGuideIncluded) {
      content = helloBook;
      vm.helloBookCreditMessage = helloBookAboutUs;
    }
    if (!isHelloBook && isGuideIncluded) {
      content = creditGuide;
      vm.helloBookCreditMessage = creditGuideAboutUs;
    }
    if (!isHelloBook && !isGuideIncluded) {
      vm.helloBookCreditMessage = '';
    }
    return content
      ? `<div class="hello-pack-credit section-content">${content}</div>`
      : '';
  };

  vm.renderCalendlyContent = () => {
    return vm.model.sections.calendly && vm.showCalendly && vm.adviserTheme
      ? `<div class="calendly section-content">
        <div class="hbox">
          ${vm.renderCalendyThumbnail()}  
          <p>
            <a class="dummy-link" style="color:${
              vm.adviserTheme.sidebarBgColor
            };">Book a meeting</a>
            Find a time that suits you
          </p>
        </div>
      </div>`
      : '';
  };

  vm.renderFileAttachmentContent = () => {
    if (!(vm.attachedFiles && vm.attachedFiles.length)) {
      return '';
    }

    const attachments = vm.attachedFiles.reduce((accum, file) => {
      const fileHtml = `<div class="hbox">
        <attachement-icon class="${
          vm.isBYOB ? 'byob' : 'lm'
        } preview-attachment-icon"></attachement-icon>
        <span class="preview-file-name">Download ${file.name}</span>
      </div>`;

      return `${accum}${fileHtml}`;
    }, '');

    return `<div class="attachments section-content">
        <div class="hbox">
          ${vm.renderAttachmentThumbnail()}  
          <div class="flex">
            <a class="dummy-link" style="color:${
              vm.adviserTheme.sidebarBgColor
            };">Attachments</a>
           ${attachments}         
          </div>
        </div>
      </div>`;
  };

  vm.getDisplayTitle = () => {
    const { marketingDisplayTitle, profileDisplayTitle } = vm.adviserInfo || {};
    const defaultDisplayTitle = vm.currentUserService.isAU
      ? 'Mortgage Broker'
      : 'Financial Adviser';
    return vm.configService.feature.usersProfileBio
      ? profileDisplayTitle || defaultDisplayTitle
      : marketingDisplayTitle || defaultDisplayTitle;
  };

  vm.renderInsuranceAdviserDetailsContent = () => {
    const { preferredFullName, mobile, email } = vm.adviserInfo || {};

    return `
    <div class="details-content">
      <div><strong>${preferredFullName}</strong></div>
      <div><a style="color:${vm.adviserTheme.sidebarBgColor};">${email}</a> ${
      email && mobile ? '| ' : ''
    } ${mobile}</div>
    </div>`;
  };

  vm.renderDefaultAdviserDetailsContent = () => {
    const { preferredFullName, mobile, website } = vm.adviserInfo || {};
    return `
    <div class="details-content">
      <div><strong>${preferredFullName}</strong></div>
      <div>${vm.getDisplayTitle()}</div>
      ${
        website
          ? `<div>
          <a style="color:${vm.adviserTheme.sidebarBgColor};">${website}</a>
        </div>`
          : ``
      }
      <div><a style="color:${
        vm.adviserTheme.sidebarBgColor
      };">${mobile}</a></div>
    </div>`;
  };

  vm.renderAdviserPhotoContent = () => {
    const { fullBodyPhotoUrl } = vm.adviserInfo || {};
    return (
      (fullBodyPhotoUrl &&
        `<div class="photo-content">
        <img src="${fullBodyPhotoUrl}" alt="Photo">
      </div>`) ||
      ''
    );
  };

  vm.renderSignatureContent = () => {
    return vm.activePackMode === PACK_MODE.INSURANCE_APP
      ? `<div class="signature-content">
      ${vm.renderInsuranceAdviserDetailsContent()}
    </div>`
      : `<div class="signature-content">
        ${vm.renderDefaultAdviserDetailsContent()}
        ${vm.renderAdviserPhotoContent()}
      </div>`;
  };

  vm.renderLoadingPreview = () => {
    return `<preloader-dot></preloader-dot>`;
  };

  vm.renderSectionContents = () => {
    const applicationContent =
      vm.activePackMode === PACK_MODE.INSURANCE_APP &&
      vm.activePackType === INSURANCE_PACK_TYPE.APPLICATION &&
      vm.renderInsuranceApplicationContents();

    const quoteContent =
      vm.activePackMode === PACK_MODE.INSURANCE_APP &&
      vm.activePackType === INSURANCE_PACK_TYPE.QUOTE &&
      vm.renderInsuranceQuoteContents();

    const helloPackContent = vm.renderHelloPackContents();

    const content =
      vm.adviserTheme &&
      vm.adviserInfo &&
      (applicationContent || quoteContent || helloPackContent);

    return content || vm.renderLoadingPreview();
  };

  vm.renderInsuranceApplicationContents = () => {
    const adviserPhoto = vm.renderAdviserPhotoContent();
    return vm.adviserTheme
      ? `<div class="insurance-content">
        <div class="application-content">
          <div class="btn-container disabled">
              <div class="button btn-dummy-link" style="background-color:${
                vm.adviserTheme.sidebarBgColor
              };">Complete online application</div>
          
              <div class="button btn-dummy-link ${
                !vm.model.sections.isViewQuote ? 'quote-hidden' : null
              }" style="background-color:${
          vm.adviserTheme.sidebarBgColor
        };">View your quote</div>
          </div>

          <p>
            This email address will be used to create an account for you to access your online application.
            If you would like to change your email address or have any other questions, please let me know!
          </p>

          <p>
            Regards,
          </p>
          ${vm.renderSignatureContent()}
        </div>
        ${adviserPhoto}
      </div>
    `
      : '';
  };

  vm.renderInsuranceQuoteContents = () => {
    const adviserPhoto = vm.renderAdviserPhotoContent();
    return vm.adviserTheme
      ? `<div class="insurance-content">
        ${vm.renderQuote()}
        ${adviserPhoto}
      </div>      
      `
      : '';
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  vm.renderQuote = () => {
    const includeSections =
      vm.model.sections.includeBrochures ||
      vm.model.sections.includePolicyDocuments;
    const optionalContent =
      includeSections && vm.provider
        ? ` and applicable ${
            vm.model.sections.includeBrochures ? `brochure` : ''
          } 
  ${
    vm.model.sections.includeBrochures &&
    vm.model.sections.includePolicyDocuments
      ? `and `
      : ``
  }
  ${vm.model.sections.includePolicyDocuments ? `policy documents` : ''} for ${
            vm.provider.name
          }`
        : '';

    return vm.adviserTheme
      ? `<div class="application-content">
        ${
          vm.provider && vm.provider.name
            ? `<p>
            Please click the below links to view your quote report along${optionalContent}.
          </p>`
            : ''
        }

        <div class="btn-container disabled">
          <div class="button btn-dummy-link" style="background-color:${
            vm.adviserTheme.sidebarBgColor
          };">View your quote</div>

        ${
          vm.provider && vm.provider.name && includeSections
            ? `<div class="button btn-dummy-link" style="background-color:${vm.adviserTheme.sidebarBgColor};">View your policy documents</div>`
            : ``
        }

        </div>

        <p>
          If you have any questions, please do not hesitate to ask.
        </p>

        <p>
          Regards,
        </p>
        ${vm.renderSignatureContent()}           
      </div>`
      : '';
  };

  vm.renderHelloPackContents = () => {
    return (
      (vm.adviserTheme &&
        vm.adviserInfo &&
        ` ${vm.renderFactFindContent()}
      ${vm.renderHelloBookAndCreditGuideContent()}
      ${vm.renderFileAttachmentContent()}
      ${vm.renderCalendlyContent()}
      ${vm.renderSignatureContent()}
    `) ||
      vm.renderLoadingPreview()
    );
  };

  vm.renderHelloPackMobileContents = () => {
    const html =
      vm.adviserTheme &&
      vm.adviserInfo &&
      ` ${vm.renderFactFindContent()}
    ${vm.renderHelloBookAndCreditGuideContent()}
    ${vm.renderFileAttachmentContent()}
    ${vm.renderCalendlyContent()}
    ${vm.renderSignatureContent()}
  `;
    vm.renderLoadingPreview();

    return html;
  };

  vm.renderMobileSectionContents = () => {
    const applicationContent =
      vm.activePackMode === PACK_MODE.INSURANCE_APP &&
      vm.activePackType === INSURANCE_PACK_TYPE.APPLICATION &&
      vm.renderInsuranceApplicationContents();

    const quoteContent =
      vm.activePackMode === PACK_MODE.INSURANCE_APP &&
      vm.activePackType === INSURANCE_PACK_TYPE.QUOTE &&
      vm.renderInsuranceQuoteContents();

    const helloPackContent = vm.renderHelloPackMobileContents();

    const content =
      vm.adviserTheme &&
      vm.adviserInfo &&
      (applicationContent || quoteContent || helloPackContent);

    return content || vm.renderLoadingPreview();
  };

  vm.renderBrandingWarning = () => `<p>
    Your account isn't quite ready to send Online Fact Finds.
    Not to worry - <a href="mailto:${vm.factFindEmail.email}">${vm.factFindEmail.description}</a>
    will be able to get this fixed for you.
  </p>`;

  vm.renderCalendlyWarning = () => `<p>
    Your account isn't quite ready to turn on Calendly bookings.
    Not to worry - <a href="mailto:${vm.itSupportEmail.email}">${vm.itSupportEmail.description}</a>
    will be able to get this fixed for you.
  </p>`;

  vm.renderFactFindItems = (group) => {
    const factFindHeadingItemHtml = () => `<div class="group group-${group}"><div
    class="request-item checkbox right clip-check check-info"
    ng-repeat="section in vm.factFindSections | filter: {group: ${group}} track by $index "
  >
    <input
      type="checkbox"
      name="section-${group}-{{$index}}"
      ng-attr-id="section-${group}-{{$index}}"
      ng-model="section.selected"
      ng-change="vm.isValid()"
    />
    <label ng-attr-for="section-${group}-{{$index}}" ng-click="vm.isValid()">
      <p class="text-default">{{section.name}}</p>
    </label>
  </div></div>`;

    return factFindHeadingItemHtml();
  };
};

export const setHelloBookLoanCategory = ({
  loan,
  isMergeLeadAndOpportunities,
}) => {
  if (loan.isLead) {
    return isMergeLeadAndOpportunities ? 'Leads' : loan.pipelineCategory;
  }
  return PIPELINE_CATEGORY.APPLICATION;
};

export const addHelloBookPipelineCategory = ({
  loan,
  isMergeLeadAndOpportunities,
}) => {
  loan.isLead = loan.categoryName !== LOAN_STATUS_CATEGORY.IN_PROGRESS;
  loan.pipelineType = setHelloBookLoanCategory({
    loan,
    isMergeLeadAndOpportunities,
  });

  return loan;
};

export const getMobileCountryCode = (client = {}) => {
  const mobile =
    (client.phone &&
      client.phone.find((phone) => phone.type === PHONE_TYPE.MOBILE)) ||
    {};
  return get(mobile, 'countryCode', '').toLowerCase();
};
