import { MERGE_FIELD_CATEGORY } from 'Common/constants/mergeFieldCategory';

export const MERGE_FIELDS = {
  LENDER_NAME: 'Lender Name',
  LOAN_REF_NUMBER: 'Loan Reference Number',
  TOTAL_LOAN_AMOUNT: 'Total Loan Amount',
  PRE_APPROVAL_EXPIRY: 'Pre-Approval Expiry',
  FULL_ADDR_BLOCK_HOME: 'Full Address block - Home',
  FULL_ADDR_BLOCK_MAILING: 'Full Address block - Mailing',
  PREFERRED_NAME: 'Preferred Name',
  ADVISER_NAME: 'Adviser Name',
  BUSINESS_NAME: 'Business Name',
  LOAN_STRUCTURE: {
    INTEREST_RATE: 'Loan Structure Interest Rate',
    REPAYMENT_AMOUNT: 'Loan Structure Repayment Amount',
    LOAN_AMOUNT: 'Loan Structure Loan Amount',
  },
  FLOATING_RATE: 'Floating Interest Rate',
  FULL_NAME: 'Full Name',
  FULL_NAME_INITIAL: 'Full Name Initial',
  CURRENT_LONG_DATE: 'Current date - long date',
  CURRENT_DATE: 'Current Date',
  ADVISER_EMAIL: 'Adviser Email address',
  APPLICANT_EMAIL: 'Applicant Email Address',
  ASSISTANT_EMAIL: 'Assistant Email Address',
};

export const MERGE_FIELDS_CATEGORIES = {
  LOAN: 'LOAN',
  OTHER: 'OTHER',
  CONTACT: 'CONTACT',
  INSURANCE: 'INSURANCE',
};

export const MERGE_FIELDS_V1_MAPPINGS = {
  [MERGE_FIELD_CATEGORY.OTHER]: {
    [MERGE_FIELDS.CURRENT_DATE]: MERGE_FIELDS.CURRENT_LONG_DATE,
  },
  [MERGE_FIELD_CATEGORY.CONTACT]: {
    [MERGE_FIELDS.FULL_NAME_INITIAL]: MERGE_FIELDS.FULL_NAME,
  },
};
