import template from './loanSplitForm.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    viewOnly: '<',
    inline: '<',
    formMode: '<',
    viewData: '<?',
    isCreate: '<',
    currencyLabel: '@',
    currencyClass: '@',
    currentUserService: '<',
    setLoanStructureDetails: '<',
    componentSplitFn: '<',
    repaymentLegend: '<',
    borrowingEntity: '<',
    borrowingEntityListSelected: '<',
    params: '<',
    selectedRate: '<',
    selectRateType: '<',
    selectLoanType: '<',
    loanTermsList: '<',
    selectRepaymentFreq: '<',
    totalLoanAmount: '<',
    data: '<',
    productDiscountRate: '<',
    inlineSplitDetails: '=',
  },
};
