export const setAllRoleLogics = ((realLogic) => {
  return {
    addAliases: ({ vm, feature, user }) => {
      user
        .aliasRolesAs('isNZAdviser')
        .withRoleValuesOf(user.getTypeThat('isNzAdviser'));

      user
        .aliasRolesAs('isNZAssistant')
        .withRoleValuesOf(user.getTypeThat('isNzAssistant'));

      realLogic({ vm, feature, user });
    },
  };
})(({ vm, feature, user }) => {
  const setDefault = () => {
    vm.showLendingTools = true;
    vm.showInsuranceTools = true;
  };

  if (!feature.nzLendingTools) {
    setDefault();
  } else if (user.getTypeThat('isNZAdviser')) {
    vm.showLendingTools = user.getTypeThat('isLoanWriter');
    vm.showInsuranceTools = user.getTypeThat('isRiskWriter');
  } else if (user.getTypeThat('isNZAssistant')) {
    vm.showLendingTools = user.getTypeThat('orgMemberHasLoanWriter');
    vm.showInsuranceTools = user.getTypeThat('orgMemberHasRiskWriter');
  } else {
    setDefault();
  }
});
