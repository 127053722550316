import { toastSaveSuccess } from 'Common/utilities/alert';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';

export default class InsuranceOpportunitiesCtrl {
  constructor(
    NgTableParams,
    customerCareInsuranceService,
    currentUserService,
    $state,
  ) {
    'ngInject';

    this.NgTableParams = NgTableParams;
    this.userInfo = currentUserService;
    this.toastSaveSuccess = toastSaveSuccess;
    this.$state = $state;
  }

  $onInit() {
    this.pageSource = PAGE_SOURCE.INSURANCE_QUOTE;
    this.isLoading = false;
    this.tableData = [];
  }

  onTableDataLoaded(event) {
    this.tableData = event.tableData;
  }

  createNewApplication(url) {
    const redirectUrl =
      url === 'quote' ? 'app.quickQuote' : 'app.insuranceProfiler';
    this.$state.go(redirectUrl);
  }

  $onChanges(changes) {
    if (changes.filterUpdateCount) {
      this.currentPage = 1;
      if (this.defaultTableSettings) {
        this.defaultTableSettings.page = 1;
      }
      this.isFromCompleted = false;
      if (this.tableParams) {
        this.tableParams.reload();
      }
    }
  }
}
