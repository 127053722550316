export const NO_BRANDING_CATEGORY_WARNING = {
  ADVISER: `Sorry! We can’t activate this Adviser 
    because “Branding Category” is not set. 
    Please update this field to proceed with activation.`,
  ADVISER_ORG: `Sorry! We can’t activate this Adviser Organisation because “Branding Category” is not set. 
    Please update this field to proceed with activation.`,
  ADVISER_ORG_OF_ADVISER: `Sorry! We can’t activate this Adviser
    because the Adviser Organisation they belong to, 
    does not have a valid “Branding Category”.
    Please update this field to proceed with activation.`,
};

export const GENERIC_CONTACT_SUPPORT = 'Please contact support';

export const ADDRESS_ERROR = {
  CLIENT: 'There was an error on processing and saving address',
};

export const HELLO_BOOK = {
  FILE_LIMIT: 'Only up to 3 items are allowed for attachment.',
  INVALID_FILE_EXT:
    'Invalid File Extension : Please select pdf, doc, png, jpg, ppt, xls files',
  LOAN_NOT_BORROWER_WARNING: `This client isn’t a part of the Loan Application.`,
};

export const INVALID_DATE = {
  START: 'Please check that Start Date is correct',
  END: 'Please check that End Date is correct',
  START_END: 'Please check that Start and End Dates are correct',
};

export const ERROR_LMI_NOT_MATCHED = `No matching premiums were found for the lender and LVR you have entered.`;

export const MERGE_CLIENT_MESSAGES = {
  SUCCESS: 'Clients successfully merged',
  ERROR: 'Clients not merged successfully',
};
export const INVALID_EMAIL = 'Please provide a valid email address.';
export const INVALID_WORKSHEET_LOAN_AMOUNT =
  "Max loan needs to be more than $0 to download this lender's worksheet";
