import angular from 'angular';

class MailTemplatesManagementCtrl {
  constructor(
    $uibModalInstance,
    uiService,
    contentToSaveAsTemplate,
    showDefaultTemplatesSettings,
  ) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.uiService = uiService;
    this.contentToSaveAsTemplate = contentToSaveAsTemplate;
    this.isCorporateUser = uiService.isCorporateUser;
    this.contentToSaveAsTemplate = contentToSaveAsTemplate;
    this.showDefaultTemplatesSettings = showDefaultTemplatesSettings;
  }

  closeModal() {
    this.$uibModalInstance.close(this.hasChanges);
  }

  onTemplatesUpdated() {
    this.hasChanges = true;
  }

  $onInit() {
    this.tabs = [
      {
        name: 'Mail Templates',
        page:
          'assets/views/contacts/mailTemplatesManagement/mail_templates.html',
        value: 'mailTemplates',
        disable: false,
        isCorporateOnly: false,
      },
      {
        name: 'Sharing and Privacy',
        page:
          'assets/views/contacts/mailTemplatesManagement/mail_sharing_privacy.html',
        value: 'sharingAndPrivacy',
        disable: false,
        isCorporateOnly: true,
      },
    ];
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .controller('MailTemplatesManagementCtrl', MailTemplatesManagementCtrl);
