import angular from 'angular';

class SetDefaultTemplateModalCtrl {
  constructor($uibModalInstance, template, methodType, isInsurance) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.template = template;
    this.methodType = methodType;
    this.isInsurance = isInsurance;
  }

  cancel() {
    this.$uibModalInstance.dismiss('cancel');
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .controller('SetDefaultTemplateModalCtrl', SetDefaultTemplateModalCtrl);
