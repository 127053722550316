import angular from 'angular';
import _ from 'lodash';
import { CONTACT_TAB } from 'Common/constants/contactTab';
import { SYNC_STATUS } from 'Common/constants/gmailSyncStatus';
import {
  EMAIL_TYPES,
  EMAIL_CTYPES,
  EMAIL_VALUE,
  EMAIL_ID,
} from 'Common/constants/emailTypes';
import swal from 'sweetalert';
import { getClientAdviser } from 'Common/utilities/emailDetailUtility';
import { displayError } from 'Common/utilities/alert';
import { COLOR } from 'Common/constants/colors';
import { listDeleteHide } from './util/emailsDetailCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('EmailsDetailCtrl', function EmailsDetailCtrl(
      $scope,
      $timeout,
      $uibModal,
      $stateParams,
      contactService,
      $q,
      $window,
      $state,
      configService,
      $interval,
      contactModelService,
      contactSharedDataService,
      gmailSyncModalService,
      currentUserService,
    ) {
      $scope.emailCtypes = EMAIL_CTYPES;
      $scope.letterIconColor = COLOR.TRENDY_PINK;
      $scope.SYNC_STATUS = SYNC_STATUS;
      $scope.familyId = $stateParams.familyId;
      $scope.chosenFilter = 'All';
      $scope.isNoEmail = true;
      $scope.gmailObject = [];
      $scope.filterModel = {};
      $scope.syncStatus = 0;
      $scope.isLoadingEmails = true;
      $scope.isActiveInputSearch = false;
      $scope.gmailSyncLink = '';
      $scope.paginationObject = {
        currentPage: 0,
        maxSize: 5,
        totalItems: 0,
      };
      $scope.showCustomerIOEmailImport =
        configService.feature.customerIOEmailImport || 0;
      /* gmail models */
      $scope.gmailObject.models = null;
      $scope.gmailObject.models = {
        emailList: null,
        UserAuthUrl: null,
        isAuthenticated: false,
      };
      $scope.gmailSyncFlag = configService.feature.gmailSyncUIImprovements;
      /* end of gmail models */
      /* gmail functions and proceedures */
      const stopSyncInterval = () => {
        if ($scope.postInterval) {
          $interval.cancel($scope.postInterval);
          $scope.postInterval = null;
        }
      };
      const stopTimeout = (timeoutToStop) => {
        if (!timeoutToStop) {
          return;
        }

        $timeout.cancel(timeoutToStop);
      };

      const addIsShowRemoveGmail = (emailList) => {
        return emailList.map((object) => {
          if (object.CType === 'Gmail') {
            if (currentUserService.isAdviser) {
              object.showGmailRemove = currentUserService.isAdviser;
            } else {
              object.showGmailRemove =
                object.Recipients &&
                object.Recipients.some((gmail) =>
                  gmail.EmailAddress.includes(currentUserService.email),
                );
            }
          }

          return object;
        });
      };

      $scope.removeGmailMail = (googleMailId) => {
        const params = {
          familyId: $scope.familyId,
          googleMailId,
        };
        contactModelService.gmailSyncDeactivate(params).then(() => {
          $scope.loadEmailList();
        });
      };
      const syncStatusInterval = () => {
        if ($scope.postInterval) {
          return;
        }

        $scope.postInterval = $interval(() => {
          if ($scope.syncStatus === SYNC_STATUS.SYNCING) {
            contactService
              .getGmailSyncStatus($state.params.familyId)
              .then((syncStatusResponse) => {
                if (!syncStatusResponse.data) {
                  $scope.syncStatus = SYNC_STATUS.SYNCED;
                  $scope.loadEmailList();
                  stopSyncInterval();
                }
              });
          } else {
            stopSyncInterval();
          }
        }, 10000);
      };
      const postSyncProcedure = (familyId) => {
        const defer = $q.defer();

        $scope.syncStatus = SYNC_STATUS.SYNCING;

        contactService.postGmailSync({ familyId }).then((postSyncResponse) => {
          $scope.gmailSyncLink = postSyncResponse.data.UserAuthUrl || '';
          if (
            postSyncResponse.data &&
            Object.keys(postSyncResponse.data).length
          ) {
            /* user hasn't authenticated the gmail yet */
            if (
              postSyncResponse.data.UserAuthUrl &&
              !postSyncResponse.data.AuthSuccess
            ) {
              $scope.gmailObject.models.UserAuthUrl =
                postSyncResponse.data.UserAuthUrl;
              $scope.syncStatus = SYNC_STATUS.NOT_SYNCED;
            } else if (
              typeof postSyncResponse.data.NoOfMailsToSync !== 'undefined'
            ) {
              if (postSyncResponse.data.NoOfMailsToSync) {
                // NoOfMailsToSync is greater than, 0 it means we are still syncing the gmail emails
                $scope.syncStatus = SYNC_STATUS.SYNCING;
              } else {
                // NoOfMailsToSync is <= 0, it means we are done syncing
                $scope.syncStatus = SYNC_STATUS.SYNCED;
              }
            }
          }

          return defer.resolve();
        });

        return defer.promise;
      };
      /* procedure for syncing when the communication tab is clicked */
      const onInitCommunicationSync = () => {
        const defer = $q.defer();

        /* getGoogleEmail API call is to check whether the user has gmail authenticated */
        const familyId = $state.params.familyId;
        contactService.getGoogleEmail(familyId).then((getGmailResponse) => {
          $scope.gmailObject.models.isAuthenticated =
            getGmailResponse.data.AuthSuccess;
          contactService.getGmailSync(familyId).then(() => {
            postSyncProcedure(familyId).then(() => {
              return defer.resolve();
            });
          });
        });

        return defer.promise;
      };
      $scope.gmailObject.methods = null;
      $scope.gmailObject.methods = {
        isShowGmailFeatureMethod: () => {
          return (
            (configService.feature &&
              configService.feature.gmailCommunication) ||
            0
          );
        },

        setGmailValuesIfUserSynced: () => {
          $scope.isNoEmail = false;
        },

        init: () => {
          $scope.isBannerOnlyClosed = true;
          onInitCommunicationSync().then(() => {
            if ($scope.gmailObject.models.isAuthenticated) {
              $scope.gmailObject.methods.setGmailValuesIfUserSynced();
              syncStatusInterval();
            } else {
              $scope.showBannerAtStart = true;
            }
          });

          $scope.isShowGmailFeature = $scope.gmailObject.methods.isShowGmailFeatureMethod();
        },
        openNewWindowForAuth: () => {
          const userAuthUrl =
            $scope.gmailObject.models.UserAuthUrl || $scope.gmailSyncLink;
          const params = {
            gmailSyncUrl: userAuthUrl,
            isGmailFeatureOn: $scope.gmailSyncFlag,
            isGmailSync: $scope.isBannerVisible,
            onUnlinkSuccess: () => $scope.gmailObject.methods.init(),
          };

          gmailSyncModalService.openGmailSyncWindow(params);
        },
        addGmailType: (data) => {
          _.forEach(data, (object) => {
            object.CType = EMAIL_TYPES.GMAIL;
          });
          return data;
        },
      };

      const setPaginationTotalItems = (length) => {
        $scope.paginationObject.totalItems = length;
      };
      $scope.manualSync = () => {
        postSyncProcedure($scope.familyId).then(() => {
          $scope.loadEmailList();
        });
      };

      contactService.getUserInfo().then((response) => {
        if (response.data) {
          $scope.userInfo = response.data;
        }
      });
      /* end of gmail functions and proceedures */
      function getSummaryInfo() {
        contactService.clientInformGet($scope.familyId).then((response) => {
          $scope.clientsDataObject = response.data;
          getClientAdviser({
            contactModelService,
            $scope,
            isAdviserBYOB: currentUserService.isBYOB,
          });
          // Summary Data;
          if ($scope.getSummaryData) {
            $scope.getSummaryData($scope.clientsDataObject);
          }
        });
      }
      getSummaryInfo();

      $scope.emailDetail = {};
      $scope.emailDetailModel = {};

      $scope.emailDetail.communicationTypes = [
        {
          id: 0,
          type: EMAIL_TYPES.CRM_EMAIL,
          value: $scope.showCustomerIOEmailImport
            ? EMAIL_VALUE.CRM_EMAIL
            : 'Email',
        },
        {
          id: 1,
          type: EMAIL_TYPES.SMS,
          value: $scope.showCustomerIOEmailImport ? EMAIL_VALUE.SMS : 'SMS',
        },
        {
          id: 2,
          type: EMAIL_TYPES.GMAIL,
          value: $scope.showCustomerIOEmailImport ? EMAIL_VALUE.GMAIL : 'Gmail',
        },
        {
          id: EMAIL_ID.NPS_PROMOTER,
          type: EMAIL_TYPES.NPS_PROMOTER,
          value: $scope.showCustomerIOEmailImport
            ? EMAIL_VALUE.NPS_PROMOTER
            : 'NPS Promoter',
        },
      ];
      if (currentUserService.isAU || currentUserService.isNZ) {
        const customerIOSms = {
          id: $scope.emailDetail.communicationTypes.length,
          type: EMAIL_TYPES.CUSTOMERIO_SMS,
          value: $scope.showCustomerIOEmailImport
            ? EMAIL_VALUE.CUSTOMERIO_SMS
            : 'Marketing Automation',
        };

        $scope.emailDetail.communicationTypes = [
          ...$scope.emailDetail.communicationTypes,
          customerIOSms,
        ];
      }
      $scope.emailDetailModel.communicationType =
        $scope.emailDetail.communicationTypes[0];

      // Any code below was the original code for Emails Tab
      // --------------------------------------------------------------------------------

      // Emails List
      // -------------------------------------------------------------
      $scope.emailsList = [];
      $scope.EmailsListLength = 0;
      $scope.selectedFilterInfo = {
        isGmailChecked: true,
        selected: 'multiple',
      };
      $scope.isShowNoGmailBanner = true;
      $scope.isBannerClosed = false;
      $scope.isBannerOnlyClosed = true;

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const removeGmailsEmails = (emailList) => {
        return emailList.filter((email) => {
          return email.CType !== 'Gmail';
        });
      };
      $scope.hideNogmailBanner = (event) => {
        if ($scope.gmailSyncFlag) {
          $scope.isBannerOnlyClosed = false;
        }
        event.stopPropagation();
        $scope.showBannerAtStart = false;
        $scope.isBannerClosed = false;
      };
      $scope.loadEmailList = (
        searchCriteria = '',
        pageNumber = 1,
        pageSize = 10,
      ) => {
        if (!$scope.filterModel && Object.keys($scope.filterModel).length < 1) {
          return;
        }

        $scope.isLoadingEmails = true;
        const {
          gmail,
          email,
          phone,
          customerIOSms,
          npsPromoterCertificate,
        } = $scope.filterModel;
        contactService
          .getEmailList(
            $scope.familyId,
            pageNumber,
            pageSize,
            gmail,
            email,
            phone,
            searchCriteria,
            customerIOSms,
            npsPromoterCertificate,
          )
          .then((result) => {
            $scope.isLoadingEmails = false;

            if (!result.data || result.data.length < 1) {
              $scope.isNoEmail = true;
              $scope.emailsList = [];
              setPaginationTotalItems(0);
            } else {
              const tempEmailList = !$scope.gmailObject.methods.isShowGmailFeatureMethod()
                ? removeGmailsEmails(result.data)
                : result.data;

              $scope.emailsList = listDeleteHide(
                addIsShowRemoveGmail(tempEmailList),
                configService.feature.hideDeleteTransaction,
              );

              const paginationLength =
                ($scope.emailsList.length &&
                  $scope.emailsList[0].TotalRecords) ||
                0;
              setPaginationTotalItems(paginationLength);
              $scope.paginationObject.currentPage = pageNumber;

              $scope.isNoEmail = false;
            }
          });
      };
      contactSharedDataService.loadContactCommunication = () =>
        $scope.loadEmailList();
      $scope.showManualSync = () => {
        return (
          $scope.gmailObject.models.isAuthenticated &&
          $scope.gmailObject.methods.isShowGmailFeatureMethod() &&
          $scope.selectedFilterInfo.isGmailChecked
        );
      };
      $scope.isShowPaginate = () => {
        return $scope.paginationObject.totalItems > 10;
      };
      $scope.isShowNoGmailBannerConditions = () => {
        const atStartConditions =
          (($scope.isShowNoGmailBanner &&
            $scope.selectedFilterInfo.isGmailChecked) ||
            $scope.showBannerAtStart) &&
          $scope.gmailObject.models.UserAuthUrl;
        const otherConditions =
          !$scope.gmailObject.models.isAuthenticated &&
          $scope.selectedFilterInfo.selected !== EMAIL_TYPES.GMAIL &&
          !$scope.isFilterGmailFeatureCondition();
        $scope.isBannerVisible = !!(atStartConditions && otherConditions);
        return $scope.isBannerVisible;
      };

      $scope.isContainGmail = () => {
        return !!$scope.emailsList.find(
          (object) => object.CType === EMAIL_CTYPES.GMAIL,
        );
      };

      $scope.isShowNoGmailLinked = () => {
        return (
          $scope.selectedFilterInfo.selected === EMAIL_TYPES.GMAIL &&
          !$scope.gmailObject.models.isAuthenticated &&
          !$scope.isLoadingEmails &&
          !$scope.isContainGmail()
        );
      };
      $scope.isShowNoMail = () => {
        return (
          !$scope.isLoadingEmails &&
          !$scope.emailsList.length &&
          ((!$scope.gmailObject.models.isAuthenticated &&
            $scope.selectedFilterInfo.selected !== EMAIL_TYPES.GMAIL) ||
            $scope.gmailObject.models.isAuthenticated)
        );
      };

      const selectedFilterInfo = () => {
        let selected = 'none';
        let trueCounter = 0;
        let isGmailChecked = false;

        _.forOwn($scope.filterModel, (value, key) => {
          if (value) {
            selected = key;
            trueCounter++;

            isGmailChecked = key === EMAIL_TYPES.GMAIL;
          }
        });
        return {
          isMultiSelected: trueCounter > 1,
          selected: trueCounter > 1 ? 'multiple' : selected,
          isGmailChecked,
        };
      };
      $scope.isCertainMailExist = (CType) => {
        const toReturnVal = _.find(
          $scope.emailsList,
          (object) => object.CType === CType,
        );

        if (toReturnVal) {
          return true;
        }

        return false;
      };

      $scope.updateFilter = () => {
        stopTimeout($scope.updateFilterTimeout);
        $scope.updateFilterTimeout = $timeout(() => {
          $scope.loadEmailList();
          $scope.selectedFilterInfo = selectedFilterInfo();
          $scope.showBannerAtStart = false;
        }, 500);
      };

      $scope.removeEmail = function (emailId, event) {
        if (event) {
          event.stopPropagation();
        }

        swal(
          {
            title: 'Please confirm action',
            text:
              'Are you sure you want to delete this email? This action cannot be undone',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FA8E91',
            confirmButtonText: 'Delete Email',
            closeOnConfirm: false,
          },
          (isConfirm) => {
            if (isConfirm) {
              contactService
                .removeEmail($scope.familyId, emailId)
                .then(() => {
                  $scope.loadEmailList();
                  swal('Deleted', 'Email has been deleted.', 'success');
                })
                .catch(displayError);
            }
          },
        );
      };
      $scope.viewEmail = function (
        emailId = null,
        size = 'lg',
        gmailEmail = null,
      ) {
        $uibModal.open({
          templateUrl: '/assets/views/contacts/partials/view_email_modal.html',
          controller: 'ViewEmailCtrl',
          size,
          resolve: {
            familyId() {
              return $scope.familyId;
            },
            emailId() {
              return emailId;
            },
            removeEmail() {
              return $scope.removeEmail;
            },
            loadEmailList() {
              return $scope.loadEmailList;
            },
            gmailEmail() {
              return gmailEmail;
            },
          },
        });
      };
      $scope.isFilterGmailFeatureCondition = () => {
        return $scope.isShowGmailFeature ? '' : '!gmail';
      };

      $scope.searchInputChanged = (typedValue = '') => {
        $scope.typedValueInSearch = typedValue;

        stopTimeout($scope.emailSearchTimeout);
        $scope.emailSearchTimeout = $timeout(() => {
          $scope.loadEmailList(typedValue);
        }, 300);
      };

      $scope.isActiveInputStatus = (isActive) => {
        $scope.isActiveInputSearch = isActive;
      };

      const initFilterSection = () => {
        $scope.emailDetail.communicationTypes.forEach((object) => {
          $scope.filterModel[object.type] = true;
        });
      };

      const loadAllEmailsProcedure = () => {
        $scope.loadEmailList();
        $scope.gmailObject.methods.init();
      };
      $scope.$watch('selectedContacts', (newValue) => {
        const communicationValue = [
          CONTACT_TAB.EMAILS,
          CONTACT_TAB.COMMUNICATIONS,
        ];
        if (communicationValue.includes(newValue)) {
          initFilterSection();
          loadAllEmailsProcedure();
        }
      });

      // Load Transactions in Adviser
      if ($stateParams.clientId) {
        initFilterSection();
        loadAllEmailsProcedure();
      }
      $window.authGoogle = function (code) {
        contactService.authGoogle(code).then(() => {
          loadAllEmailsProcedure();
        });
      };

      $scope.$on('$destroy', () => {
        stopSyncInterval();
        stopTimeout($scope.emailSearchTimeout);
        stopTimeout($scope.updateFilterTimeout);
      });
    });
