export const RATE_TYPES = {
  INITIAL_RATE: 1,
  ONGOING_RATE: 2,
};

export const FEE_TYPES = {
  UPFRONT_FEE: 1,
  ONGOING_MONTHLY_FEE: 2,
  ONGOING_ANNUAL_FEE: 3,
};
