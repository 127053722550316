import template from './referralExistingClientSearch.html';
import controller from './referralExistingClientSearchCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    initialValue: '<',
    onChange: '&',
  },
};
