import { logoPath } from 'Common/utilities/document';
import { COLOR } from 'Common/constants/colors';
import { PACK_MODE } from 'Common/constants/helloBook';

const DEFAULT_LM_LOGO_PATH = './login/img/lm-stacked-blue-reversed.svg';
const DEFAULT_INSURANCE_LOGO_PATH =
  './assets/images/defaultBrandingLogo/insurance-market-logo.svg';

class GamePlanEmailMessageViewCtrl {
  constructor(
    $state,
    gamePlanSharedDataService,
    generalService,
    contactModelService,
  ) {
    'ngInject';

    this.gamePlanSharedDataService = gamePlanSharedDataService;
    this.generalService = generalService;
    this.contactModelService = contactModelService;
    this.$state = $state;
  }

  $onInit() {
    this.bannerMessage = 'Game Plan';
    const { familyId, sourceFamilyId } = this.$state.params;
    this.familyId = familyId || sourceFamilyId;
    this.adviserInfo = this.gamePlanSharedDataService.adviserInfo;
    this.isBYOB = this.adviserInfo.isBYOB;
    this.getFamilyAdviserTheme();
    this.displayTitle = this.gamePlanSharedDataService.getDisplayTitle();
  }

  isUseDefaultBranding() {
    const isLMBrand = !this.adviserTheme.logoDocId && this.adviserInfo.isLM;

    const isInsuranceBrand =
      this.activePackMode === PACK_MODE.INSURANCE_APP &&
      this.adviserInfo.isInsuranceMarket;

    return isLMBrand || isInsuranceBrand;
  }

  getFamilyAdviserTheme() {
    this.contactModelService
      .getFamilyAdviserTheme(this.familyId)
      .then((data) => {
        const isUseDefaultBranding = this.isUseDefaultBranding();
        this.isBrandingReady =
          !this.isBYOB || !!(this.adviserInfo.tradingName && data.logoDocId);

        if (this.isBYOB && !data.logoDocId) {
          this.logoPath = '';
          return;
        }

        if (isUseDefaultBranding) {
          this.setDefaultBranding();
          return;
        }

        if (!data.logoDocId) {
          return;
        }
        this.generalService.documentGet(data.logoDocId).then(({ data }) => {
          if (!data || !data.ContentType || !data.DocumentContent) {
            return;
          }
          this.logoPath = logoPath(data);
        });
      });
  }

  setDefaultBranding() {
    if (this.activePackMode === PACK_MODE.HELLO_PACK) {
      this.logoPath = DEFAULT_LM_LOGO_PATH;
    } else {
      this.logoPath = DEFAULT_INSURANCE_LOGO_PATH;
      this.adviserTheme.sidebarBgColor = COLOR.SUSHI;
    }
  }
}

export default GamePlanEmailMessageViewCtrl;
