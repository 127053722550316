export default class TextAreaHeightAutoCtrl {
  constructor($timeout, $window) {
    'ngInject';

    this.$timeout = $timeout;
    this.$window = $window;
    if (typeof this.id === 'undefined') {
      this.id = 'default';
    }
  }

  $onInit() {
    this.defaultHeight = 50;
    if (typeof this.isBordered === 'undefined') {
      this.isBordered = true;
    }
    if (typeof this.enableTextArea === 'undefined') {
      this.enableTextArea = true;
    }
  }

  $onChanges(changes) {
    const { ngModel } = changes;
    if (ngModel && typeof ngModel.currentValue !== 'undefined') {
      this.updateHiddenDiv();
    }
  }

  updateHiddenDiv() {
    this.content = this.ngModel && this.ngModel.replace(/\n/g, '<br>');
    this.onNgChange({ textAreaModel: this.ngModel });
  }

  get divHeight() {
    // eslint-disable-next-line unicorn/prefer-query-selector
    this.hiddenDiv = this.$window.document.getElementById(
      `hidden-div-${this.id}`,
    );
    return this.hiddenDiv && this.hiddenDiv.clientHeight;
  }
}
