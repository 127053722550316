import { ENTITY_TYPE } from 'Common/constants/entityType';
import { PHONE } from 'Common/constants/phoneMobileFormat';
import { NUMBER_PATTERN } from 'Common/constants/pattern';
import { ADDRESS_TYPE } from 'Common/constants/addressType';
import { concatenateName, getNamePartsObject } from 'Common/utilities/contact';
import { formatEntityDetailsForUI } from './util/onboardingEntityDetailsCtrl';

class OnboardingEntityDetailsCtrl {
  constructor(
    onboardingDataService,
    onboardingService,
    corporateService,
    commonFnService,
    currentUserService,
    modelDataService,
    addressModalService,
    configService,
  ) {
    'ngInject';

    this.onboardingDataService = onboardingDataService;
    this.onboardingService = onboardingService;
    this.corporateService = corporateService;
    this.commonFnService = commonFnService;
    this.currentUserService = currentUserService;
    this.modelDataService = modelDataService;
    this.addressModalService = addressModalService;
    this.configService = configService;
    this.NUMBER_PATTERN = NUMBER_PATTERN;
    this.ADDRESS_TYPE = ADDRESS_TYPE;
  }

  getOrgAddress(orgId) {
    this.corporateService
      .organizationAddressGet(orgId)
      .then(({ data }) => {
        this.isOrgAddressLoaded = true;
        if (!data) {
          return;
        }
        const addressTmp = this.commonFnService.setAddressTypes(data);
        if (addressTmp) {
          this.contactAddress.businessAddress = { ...addressTmp.business };
          this.contactAddress.mailingAddress = { ...addressTmp.mailing };
          this.contactAddress.homeAddress = { ...addressTmp.home };
          if (!addressTmp.home || !addressTmp.home.formatted_address) {
            this.contactAddress.homeAddress = { ...addressTmp.business };
          }

          this.addressOverhaulValue();
        }
        const isInvalidAddress =
          !this.contactAddress ||
          !this.contactAddress.businessAddress ||
          !this.contactAddress.mailingAddress ||
          !this.contactAddress.businessAddress.formatted_address ||
          !this.contactAddress.mailingAddress.formatted_address;
        this.entityDetails.isSameAsPostal = false;

        if (isInvalidAddress) {
          return false;
        }
        this.entityDetails.isSameAsPostal =
          this.contactAddress.businessAddress.formatted_address ===
          this.contactAddress.mailingAddress.formatted_address;
      })
      .then(() => {
        this.entityDetails.address = this.contactAddress.businessAddress;
      });
  }

  getOnboardingAdviserOrg() {
    this.onboardingDataService.getAdviserOrgDetails().then((response) => {
      if (!response) {
        return;
      }

      this.entityDetails = formatEntityDetailsForUI(response);
      this.principalDetails = getNamePartsObject(
        response.FranchisePrincipalFamilyName,
      );

      const adviserOrgId = response.AdviserOrganisationId;
      if (typeof adviserOrgId !== 'undefined') {
        this.adviserOrganisationId = adviserOrgId;
        if (adviserOrgId) {
          this.getOrgAddress(adviserOrgId);
        }
      }

      const companyTypes = [ENTITY_TYPE.COMPANY, ENTITY_TYPE.COMPANY_TRUST];
      const entityType = this.entityDetails.entityType;
      this.isEntityCompanyType = companyTypes.includes(entityType);
    });
  }

  saveEntity() {
    const isPatnership =
      this.entityDetails.entityType === ENTITY_TYPE.PARTNERSHIP;
    const familyIds = this.onboardingDataService.getFamilyIds().toString();
    const entityAdviserOrgData = {
      MainOrganisationId: isPatnership ? this.adviserOrganisationId : 0,
      AdviserOrganizationId: this.adviserOrganisationId,
      LeadAdviser_FamilyId: this.onboardingDataService.getFamilyId(),
      AdviserOrganizationName: this.entityDetails.entityName,
      Phone: this.entityDetails.businessPhone || '',
      ABN: this.entityDetails.abn || '',
      ACN: this.entityDetails.acn || '',
      Fax: this.entityDetails.fax || '',
      FranchisePrincipalFamilyName: concatenateName(this.principalDetails, '|'),
      OnboardingContactsFamilyIds: familyIds,
    };
    this.onboardingService.setRecruitmentAdviserOrganization(
      entityAdviserOrgData,
    );
  }

  updateAddress(address, type) {
    const isMailing = type === ADDRESS_TYPE.MAILING;
    const isBusiness = type === ADDRESS_TYPE.BUSINESS;

    this.commonFnService.saveAddress(
      this.adviserOrganisationId,
      0,
      address,
      isMailing,
      isBusiness,
      true,
      address.isCustomAddress,
    );

    if (isBusiness) {
      this.updatePostalSameAsBusiness(address);
    }
  }

  updatePostalSameAsBusiness(newHomeAddress) {
    const {
      businessAddress,
      mailingAddress,
      homeAddress,
    } = this.contactAddress;
    const updateMailingAddress =
      this.entityDetails.isSameAsPostal &&
      ((businessAddress.formatted_address !==
        mailingAddress.formatted_address &&
        this.isAddressOverhaulEnabled) ||
        (homeAddress.formatted_address !== mailingAddress.formatted_address &&
          !this.isAddressOverhaulEnabled));
    if (updateMailingAddress) {
      const theMailToUse = this.isAddressOverhaulEnabled
        ? businessAddress
        : homeAddress;
      const newMailingAddress = newHomeAddress || theMailToUse;
      this.updateAddress(newMailingAddress, ADDRESS_TYPE.MAILING);
      this.contactAddress.mailingAddress = { ...newMailingAddress };
    }
    this.addressOverhaulValue();
  }

  launchAddressModal(addressType) {
    const size = 'md';
    const modalInstance = this.addressModalService.launchAddressModal(
      size,
      this.adviserOrganisationId,
      true,
      this.contactAddress,
      true,
      addressType,
    );
    modalInstance.result.then((result) => {
      this.contactAddress = result;
      this.entityDetails.isSameAsPostal =
        this.contactAddress.businessAddress.formatted_address ===
        this.contactAddress.mailingAddress.formatted_address;
    });
  }

  addressOverhaulValue() {
    const defaultAddress = {
      home: {},
      postal: {
        isSameAsHome: true,
      },
      homeForm: {},
      postalForm: {},
      homeLabel: 'Business Address',
      postalLabel: '',
      isDisabled: true,
    };

    const businessAddress = this.adviserBusinessAddress || defaultAddress;
    this.adviserBusinessAddress = {
      ...businessAddress,
      home: this.contactAddress.businessAddress,
    };

    const postalAddress = this.adviserPostalAddress || defaultAddress;
    this.adviserPostalAddress = {
      ...postalAddress,
      homeLabel: `Postal Address`,
      home: this.contactAddress.mailingAddress,
      isDisabled: this.entityDetails.isSameAsPostal,
    };
  }

  handleUpdateAddress(address, key) {
    if (!this.isOrgAddressLoaded) {
      return;
    }
    if (key === `business`) {
      this.contactAddress.businessAddress = address;
      this.updateAddress(address, this.ADDRESS_TYPE.BUSINESS);
      this.updatePostalSameAsBusiness(address);
    } else {
      this.contactAddress.mailingAddress = address;
      this.updateAddress(address, this.ADDRESS_TYPE.MAILING);
    }
  }

  $onInit() {
    this.entityDetails = {};
    this.principalDetailsForm = {};
    this.adviserOrganisationId = 0;
    this.isAddressOverhaulEnabled = this.configService.feature.addressOverhaul;
    this.phoneMask = this.currentUserService.isAU ? PHONE.AU : PHONE.NZ;
    this.contactAddress = {
      mailingAddress: this.modelDataService.addressModelData(),
      homeAddress: this.modelDataService.addressModelData(),
      businessAddress: this.modelDataService.addressModelData(),
    };
    this.addressOverhaulValue();
    this.getOnboardingAdviserOrg();
  }
}

export default OnboardingEntityDetailsCtrl;
