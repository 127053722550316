import template from './lenderSelection.html';
import LenderSelectionCtrl from './lenderSelectionCtrl';
import './style.scss';

export default {
  template,
  controller: LenderSelectionCtrl,
  controllerAs: 'vm',
  bindings: {
    class: '@',
    selectedLender: '<',
    lenderList: '<',
    onOtherLenderSelected: '&',
    onLenderSelected: '&',
    hideOther: '<',
  },
};
