import {
  CONTENT_VIEWS,
  HELP_NUMBER,
  REDIRECT_LINKS,
  MENU_TITLE,
} from 'Common/constants/floatingHelper';
import { getConfigFromEnv } from 'Common/utilities/config';

export default class FloatingHelperCtrl {
  constructor($window, $state, currentUserService, configService) {
    'ngInject';

    this.$window = $window;
    this.$state = $state;
    this.currentUserService = currentUserService;
    this.configService = configService;
  }

  $onInit() {
    this.showMenu = false;
    this.hideMenuContent = false;
    this.showWidget = true;
    this.widgetTitle = 'Help';
    this.contentView = CONTENT_VIEWS.DEFAULT;
    this.CONTENT_VIEWS = CONTENT_VIEWS;
    this.isAU = this.currentUserService.isAU;
    this.showFreshChatAU = this.configService.feature.freshChatAU;
    this.supportPhone = this.configService.feature.supportPhone;
    this.showKbHelpMenuAU = this.configService.feature.kbHelpMenuAU;
    this.envConfig = getConfigFromEnv();
    this.setMenuSettings();
  }

  setMenuSettings() {
    this.menuSettings = [
      {
        mainText: 'Help Center / Knowledge Base',
        subText:
          'Looking for a quick answers or to review your open tickets? Find them and more in the MyCRM Help center.',
        action: () => this.openHelpCenter(),
        isShow: this.showKbHelpMenuAU,
      },
      {
        mainText: this.showFreshChatAU
          ? 'Speak to a Support Team Member'
          : 'Phone',
        subText: this.isAU
          ? 'Available weekdays 8:30am to 6pm AEST'
          : 'Available weekdays 8:30am to 5pm',
        action: () => {
          return this.showFreshChatAU
            ? this.openFcWidget()
            : this.switchContentView(CONTENT_VIEWS.PHONE);
        },
        isShow: true,
      },
      {
        mainText: 'Report an issue via video',
        subText: "Record a video of the issue you're experiencing",
        action: () => this.openVideoReportUserback(),
        isShow: this.configService.feature.userbackHelpMenu,
      },
      {
        mainText: 'Report an issue via screenshot',
        subText: 'Capture a screenshot and annotate it',
        action: () => this.openScreenshotReportUserback(),
        isShow: this.configService.feature.userbackHelpMenu,
      },
      {
        mainText: 'Submit an enhancement',
        subText: 'Make, track and vote on ideas for improvements',
        action: () => this.pageRedirect(CONTENT_VIEWS.ENHANCEMENT),
        isShow: this.isAU,
      },
      {
        mainText: 'Broker support request',
        subText: 'Available weekdays 7am to 7pm AEDT',
        action: () => this.pageRedirect(CONTENT_VIEWS.SUPPORT),
        isShow: false,
      },
    ];

    this.menuHeader = MENU_TITLE;
    this.getSupportNumber();
  }

  getSupportNumber() {
    this.isShowLmHelp =
      this.supportPhone &&
      this.envConfig.mycrmSupportNumber === HELP_NUMBER.AU_NEW;

    const auPhoneNumber =
      this.supportPhone && this.envConfig.mycrmSupportNumber
        ? this.envConfig.mycrmSupportNumber
        : HELP_NUMBER.AU;

    this.phoneNumber = this.isAU ? auPhoneNumber : HELP_NUMBER.NZ;
    this.phoneHref = `tel:${this.phoneNumber}`;
  }

  openFcWidget() {
    this.$window.fcWidget.open();
    this.showMenu = false;
  }

  openHelpCenter() {
    this.$window.open(this.envConfig.helpCenterLink, '_blank');
  }

  openVideoReportUserback() {
    this.$window.Userback.open('video');
  }

  openScreenshotReportUserback() {
    this.$window.Userback.open('capture');
  }

  openMenu() {
    if (!this.showMenu) {
      this.widgetTitle = 'Help';
    }
    this.showMenu = !this.showMenu;
  }

  switchContentView(key) {
    this.contentView = key;
    this.showBack = key !== CONTENT_VIEWS.DEFAULT;
    this.showMinimize = ![CONTENT_VIEWS.DEFAULT, CONTENT_VIEWS.PHONE].includes(
      key,
    );

    if (key !== CONTENT_VIEWS.DEFAULT) {
      this.menuHeader = `${MENU_TITLE}: ${key}`;
      return;
    }
    this.menuHeader = MENU_TITLE;
  }

  pageRedirect(key) {
    this.$state.go(REDIRECT_LINKS[key]);
  }

  onMinimizeClick() {
    this.widgetTitle = this.contentView;
    this.openMenu();
  }

  closeMenu() {
    this.switchContentView(CONTENT_VIEWS.DEFAULT);
    this.openMenu();
  }
}
