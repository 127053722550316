import template from './complaintsRegisterTable.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    filterList: '<',
    headerList: '<',
    rowItems: '<',
    isLoading: '<',
    preloaderText: '<',
    sortOrder: '<',
    sortColumn: '<',
    onSort: '&',
    onFilterChange: '&',
    onRowClick: '&',
    onEditCommentClick: '&',
    onSaveCorpComments: '&',
  },
};
