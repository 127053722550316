export default class EsignUsageReportCtrl {
  constructor($location, optionsService, dashboardService, currentUserService) {
    'ngInject';

    this.$location = $location;
    this.optionsService = optionsService;
    this.dashboardService = dashboardService;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.widgetTitle = this.myParam.DisplayName;
    this.tableData = [];
    this.dataLoaded = false;
    this.showWidgetSettings = true;
    this.subTitle = 'Signed vs Pending (Last 30 days)';
    this.errorMessage = '';
    this.selectedBroker = {
      brokerId: null,
      fullName: `All Brokers`,
    };
    this.brokers = [];
    this.getEsignOverview();
    const { isAdminAssistant, isAssistant } = this.currentUserService;
    this.userIsAssistant = isAdminAssistant || isAssistant;
    if (this.userIsAssistant) {
      this.getContactsUnderOrganization();
    }
  }

  setView(isSettings) {
    this.isSettings = isSettings;
  }

  onTitleClick() {
    this.$location.path('/app/users/subscriptions');
  }

  getContactsUnderOrganization() {
    const param = {
      familyId: this.currentUserService.familyId,
    };
    this.optionsService.getEsignAvailableBrokers(param).then((res) => {
      this.brokers = [this.selectedBroker, ...res];
    });
  }

  getEsignOverview() {
    this.dataLoaded = false;
    const param = {
      duration: 30,
    };
    if (this.selectedBroker.brokerId) {
      param.familyId = this.selectedBroker.brokerId;
    }
    this.dashboardService.getEsignOverview(param).then((res) => {
      this.dataLoaded = true;
      if (res) {
        this.tableData = res;
      } else {
        this.errorMessage = `No data`;
      }
    });
  }

  goToTab(index) {
    this.$location.path(`/app/communicate/customer-care-insurance/${index}`);
  }
}
