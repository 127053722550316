import { mapColumnContent } from './util/tableList';

class TableListCtrl {
  $onInit() {
    this.mappedColumnContent = mapColumnContent(this.columnContent);
  }

  $onChanges(changeObject) {
    const { columnContent } = changeObject;
    const { previousValue, currentValue } = columnContent;

    if (columnContent.isFirstChange()) {
      return;
    }
    if (previousValue !== currentValue) {
      this.mappedColumnContent = mapColumnContent(this.columnContent);
    }
  }
}

export default TableListCtrl;
