export const ASSET_DESCRIPTIONS = {
  BOAT: 'Boat',
  MOTOR_VEHICLE: 'Motor Vehicle',
};

export const ASSET_FORM_GROUPS = {
  ASSET: 'Asset',
};

export const ASSET_CATEGORY_ID = {
  REAL_ESTATE: 1,
  VEHICLE: 2,
  CASH_SAVINGS: 4,
};

export const LOAN_TERM = {
  PRINCIPAL_INTEREST: 2,
  INTEREST_ONLY: 1,
};

export const ACCESS_OPTION = {
  LOAN_PARTY: 0,
  OTHER: 1,
};
