import './style.scss';
import template from './pipelineImportantDateModal.html';
import controller from '../importantDatesPopoverCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanDetails: '<',
    importantDateField: '<',
    familyId: '<',
  },
};
