import { displayError } from 'Common/utilities/alert';
import {
  CPD_QUALIFICATIONS_FILE_EXTENSIONS,
  INVALID_PDF_JPEG_PNG_ONLY,
} from 'Common/constants/fileType';

export const $onInit = (vm, itemProperty, workingProperty) => {
  vm[workingProperty] = { id: 0 };
  vm.isDateOpen = false;
  vm.dateFormat = 'dd MMM yyyy';
  vm.dateOptions = {
    formatYear: 'yy',
    showWeeks: false,
  };
  vm.dateAltInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
  vm.extensions = CPD_QUALIFICATIONS_FILE_EXTENSIONS;
  vm.invalidFileInfo = INVALID_PDF_JPEG_PNG_ONLY;

  if (vm[itemProperty] && !!vm[itemProperty].id) {
    const { date, documentId, documentName, ...props } = vm[itemProperty];

    const hasDocument = !!(documentId && documentName);

    if (hasDocument) {
      vm.attachment = {
        DocumentId: documentId,
        Name: documentName,
      };
    }

    vm[workingProperty] = { ...props, date: new Date(date), documentId };
  }
};

export const initializeCommonProperties = (vm, workingProperty, saveFn) => {
  vm.close = (isRefresh = false) => {
    vm.modalInstance.close({ isRefresh });
  };

  vm.dismiss = () => {
    vm.modalInstance.dismiss();
  };

  vm.onDateClicked = () => {
    vm.isDateOpen = !vm.isDateOpen;
  };

  vm.onRemoveAttachment = () => {
    vm.attachment = null;
    vm[workingProperty].documentId = 0;
  };

  vm.onUploading = (isUploading) => {
    vm.isUploading = isUploading;
  };

  vm.uploadSuccess = (files) => {
    if (!files || !files[0]) {
      return;
    }
    const [file] = files;

    vm.attachment = file;
    vm[workingProperty].documentId = file.DocumentId;
  };

  vm.save = (formIsValid) => {
    if (!vm[workingProperty] || !formIsValid) {
      displayError();
      return;
    }

    vm.isSaving = true;
    return vm.loanToolsService[saveFn](vm[workingProperty]).then((r) => {
      const failed = r.status === -1;

      if (failed) {
        displayError();
      } else {
        vm.close(true);
      }
    });
  };
};
