import angular from 'angular';
import commissionUnmatchedDetails from './commissionUnmatchedDetails';
import commissionUnmatchedSummary from './commissionUnmatchedSummary';
import commissionExpectedSummary from './commissionExpectedSummary';
import commissionExpectedDetails from './commissionExpectedDetails';

export default angular.module(
  'commission.scenes.commissionManagement.scenes.commissionLanding.scenes',
  [
    commissionUnmatchedDetails,
    commissionUnmatchedSummary,
    commissionExpectedSummary,
    commissionExpectedDetails,
  ],
).name;
