import template from './mycrmCustomSelect.html';
import controller from './mycrmCustomSelectCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    value: '<',
    list: '<',
    showAddItem: '<',
    valueKey: '@',
    labelKey: '@',
    placeholder: '@',
    addItemLabel: '@',
    size: '@',
    onChange: '&',
    onAddNewItem: '&',
  },
};
