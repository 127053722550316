import template from './mycrmGender.html';
import controller from './mycrmGenderCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    value: '=',
    disabledValues: '<',
    size: '@',
    onChange: '&',
  },
};
