import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';
import { TIMEOUT } from 'Common/constants/timeout';
import { isFunction } from 'lodash';
import { getFullName } from 'Common/utilities/contact';

class SearcheableContactSelectorCtrl {
  constructor(contactService, timeoutService, $timeout) {
    'ngInject';

    this.contactService = contactService;
    this.timeoutService = timeoutService;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.minSearchKeyLength = 3;
    this.hasClientsList = this.clientsList?.length;
    if (isFunction(this.onInitialized)) {
      this.api = {};
      this.api.setSelectedContact = (contact) => {
        this.setSelectedContact(contact);
        this.isContactReadonly = true;
      };
      this.onInitialized({ api: this.api });
    }
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts(
      WATCHER_CONTEXT.SEARCHEABLE_CONTACT_SELECTOR_CTRL,
    );
  }

  hasSearchResults() {
    return this.searchResults && this.searchResults.length;
  }

  hasMultipleClients() {
    return (
      this.selectedContactClients && this.selectedContactClients.length > 1
    );
  }

  searchContacts(searchKey) {
    const trimmedSearchKey = (searchKey && searchKey.trim()) || '';
    const isValidKeyLength =
      trimmedSearchKey && trimmedSearchKey.length >= this.minSearchKeyLength;
    const isNewSearchKey = this.prevSearchKey !== trimmedSearchKey;
    if (!isValidKeyLength || !isNewSearchKey) {
      if (!trimmedSearchKey || !trimmedSearchKey.length) {
        this.resetSearchResults();
        this.selectedContactClients = null;
      }
      return;
    }

    this.prevSearchKey = trimmedSearchKey;
    if (this.searchTimeout) {
      this.$timeout.cancel(this.searchTimeout);
    }
    this.searchTimeout = this.$timeout(() => {
      const startWith = '*';
      const page = 1;
      const count = 40;
      const sortColumn = 'FamilyFullName';
      const sortMode = 'ASC';
      const contactTypes = this.contactType || 'Client,Entity';

      this.contactService
        .getAllByFilter(
          startWith,
          page,
          count,
          sortColumn,
          sortMode,
          trimmedSearchKey,
          contactTypes,
          0,
          0,
          0,
        )
        .then((response) => {
          this.isShowResults = true;
          this.searchResults =
            response && response.data && response.data.FamilyList;
        });
    }, TIMEOUT.SEARCH);

    this.timeoutService.addTimeout(
      this.searchTimeout,
      WATCHER_CONTEXT.SEARCHEABLE_CONTACT_SELECTOR_CTRL,
    );
  }

  closeResults() {
    this.isShowResults = false;
  }

  setSelectedContact(contact) {
    if (!contact) {
      return;
    }

    if (this.hasClientsList) {
      this.selectedContactClients = this.clientsList.map((client) => {
        const { FirstName, MiddleName, LastName } = client;
        return {
          ...client,
          FullName: getFullName(FirstName, LastName, MiddleName),
        };
      });
      this.resetSearchResults(!this.hasMultipleClients());
      this.setSelectedClient(this.selectedContactClients[0]);
      return;
    }

    this.searchKey = `${contact.ClientFirstName} ${contact.ClientLastName}`;
    this.contactService
      .clientInformGet(contact.FamilyID, 0, true)
      .then((response) => {
        this.selectedContactClients =
          response &&
          response.data &&
          response.data.filter((person) => {
            return person.Role === 'Adult';
          });
        this.resetSearchResults(!this.hasMultipleClients());
        if (!this.hasMultipleClients()) {
          this.setSelectedClient(this.selectedContactClients[0]);
        }
      });
  }

  setSelectedClient(client) {
    this.selectedClient = client;
    if (client) {
      this.prevSearchKey = client.FullName;
      this.searchKey = client.FullName;
    }
    if (isFunction(this.onClientSelected)) {
      this.onClientSelected({ client });
    }
  }

  resetSearchResults(clearSearchKey = true) {
    if (clearSearchKey) {
      this.searchKey = null;
    }
    this.searchResults = null;
    this.isShowResults = false;
  }
}

export default SearcheableContactSelectorCtrl;
