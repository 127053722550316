export const BROKER_STATUS = {
  0: 'Onboarding',
  1: 'Active',
  2: 'Terminated by LM',
  3: 'Prospect',
  4: 'Ceased by Adviser',
  5: 'Suspended',
  6: 'Onboarding On Hold',
  7: 'Application Not Proceeding',
  8: 'Deceased',
};
