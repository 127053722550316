import template from './workbench.html';
import WorkbenchCtrl from './workbenchCtrl';

export default {
  template,
  bindings: {
    loanId: '<',
    loanStatusId: '<',
    orgPipelineStatusId: '<',
    loanPipelineCardsId: '<',
    loanScenarioId: '<',
    familyId: '<',
    modalInstance: '<',
    enquirySource: '<',
    clientNames: '<',
  },
  controller: WorkbenchCtrl,
  controllerAs: 'vm',
};
