import template from './emailTemplateFields.html';
import controller from './emailTemplateFieldsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    onSaveTemplate: '&',
    modalAppendTitle: '<',
    email: '<',
    appendMergeFields: '<',
    disableBusy: '<?',
  },
};
