import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

export const loadModule = () =>
  angular
    .module('app')
    .controller('PipelineTimelineCtrl', function PipelineTimelineCtrl(
      $scope,
      pipelineService,
      toaster,
      $timeout,
    ) {
      $timeout(() => {
        angular.element('.pipeline-timeline-month .flex-2').css({
          'min-width': angular
            .element('.pipeline-timeline-item .lead-contacts')
            .width(),
        });
      }, 3000);

      /*
       * Should be integrated as one with card and list view
       * */

      pipelineService
        .PipelineItemGet($scope.selectedPipelineType)
        .then((response) => {
          $scope.timeline = response.data;
          $scope.processLead();
        });

      $scope.processLead = () => {
        angular.forEach($scope.timeline, (val) => {
          /*
           * Determine the Status
           * */
          switch (val.Status[0].currentStatusId) {
            case '1':
              val.Category = 1; // Not Proceeding
              break;
            case '2':
              val.Category = 2; // New Leads
              break;
            case '3':
              val.Category = 3; // Appointment Made
              break;
            case '4':
              val.Category = 4; // Pre-Approved
              break;
            case '5':
              val.Category = 5; // To Application
              break;
            default:
              break;
          }

          /*
           * Determine Earliest Enter and Latest Exit Date
           * */

          const oldEnterDate = new Date();
          const oldExitDate = new Date();
          angular.forEach(val.Status, (date) => {
            const EnterDate = new Date(date.EnterDate);
            const ExitDate = new Date(date.ExitDate);

            if (EnterDate < oldEnterDate) {
              val.EnterDateMonth = moment(EnterDate).format('MM');
              val.EnterDateDate = moment(EnterDate).format('DD');
              val.EnterDateYear = moment(EnterDate).format('YYYY');
            }

            if (ExitDate < oldExitDate) {
              val.ExitDateMonth = moment(ExitDate).format('MM');
              val.ExitDateDate = moment(ExitDate).format('DD');
              val.ExitDateYear = moment(ExitDate).format('YYYY');
            }
          });

          val.LoanAmount = Math.floor(Math.random() * 20421 + 1);
        });

        $scope.notProceeding = _.filter(
          $scope.timeline,
          (o) => o.Category === 1,
        );
        $scope.newLeads = _.filter($scope.timeline, (o) => o.Category === 2);
        $scope.appointmentMade = _.filter(
          $scope.timeline,
          (o) => o.Category === 3,
        );
        $scope.preApproved = _.filter($scope.timeline, (o) => o.Category === 4);
        $scope.toApplication = _.filter(
          $scope.timeline,
          (o) => o.Category === 5,
        );
      };

      $scope.showData = (user) => {
        return `${user.Status[0].StatusName}      $${user.LoanAmount}`;
      };

      $scope.getTotalAmount = (item) => {
        let total = 0;
        for (const leads of item) {
          total += leads.LoanAmount;
        }
        return total;
      };
    });
