import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import {
  POST_SETTLEMENT_FOLLOW_UP_ID,
  CUSTOMER_CARE_TYPES,
  TAB_POST_SETTLEMENT_FOLLOW_UP,
} from 'Common/constants/customerCareModules';
import {
  sortColumn,
  sortType,
  addSelectedCustomerCareIds,
  isRetainAllAdviser,
  adviserChanged,
} from 'Assets/js/controllers/customerCare/util/customerCareCtrl';
import {
  onActionSelectedUtil,
  sendingResultUtil,
  modalParamsUtil,
  initValuesUtil,
} from './util/customerCareTabsCtrl';
import {
  setActiveSubTab,
  onChangeDateRange,
  getActiveTab,
  setConfigMinMaxDate,
  updateFilterFormat,
  updateSettlementFormat,
  onDateRangeChange,
} from './util/postSettlementCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('PostSettlementCtrl', function PostSettlementCtrl(
      $scope,
      $uibModal,
      NgTableParams,
      $document,
      $timeout,
      customerCareService,
      customerCareTabsService,
      currentUserService,
      configService,
      $stateParams,
      $state,
      stateService,
    ) {
      // Fixed Post Settlement
      // --------------------------------------------------------------------------
      $scope.unlockingCondition = () => {
        return (
          ($scope.showLockScreen &&
            $scope.unlockingCustomerCare &&
            $scope.autoMarketingSubscription.subscribe_StayInTouchSettlement) ||
          (currentUserService.isAssistantType && $scope.unlockingCustomerCare)
        );
      };
      $scope.reloadTimeout = null;
      $scope.isUnlockingPostSettlement = $scope.unlockingCondition();
      $scope.customerCareAdviserList = isRetainAllAdviser(
        $scope.customerCareAdviserList,
        $scope.isUnlockingPostSettlement,
      );

      $scope.mainObj = {};
      $scope.mainObj.adviserObject = _.get(
        $scope.customerCareAdviserList,
        '[0]',
        {},
      );
      $scope.customerCareAdviserList = [...$scope.customerCareAdviserList];
      $scope.mainObj.ShowCompleted = false;
      $scope.currentUserService = currentUserService;
      $scope.customerCareTabsService = customerCareTabsService;
      $scope.onDateRangeChange = onDateRangeChange({ $scope });
      $scope.initValues = initValuesUtil($scope);

      $scope.exportService = 'exportPostSettlement';
      $scope.selectedSettlementlIds = [];
      $scope.currentPage = 1;
      $scope.totalCount = 0;
      $scope.sortColumn = '';
      $scope.sortType = '';
      $scope.requiredParams = {
        get: () => ({
          pageSize: $scope.totalCount,
          sortType: $scope.sortType,
          sortColumn: $scope.sortColumn,
          toDate: $scope.mainObj.formDate,
          fromDate: $scope.mainObj.toDate,
          adviserId: $scope.mainObj.adviserObject.familyId,
          selectedRowIds: $scope.selectedSettlementlIds,
          filterTab: '',
          postSettlementTypeID: getActiveTab($state.params.activeSubTab).typeId,
        }),
      };
      $scope.addSelectedSettlementIds = addSelectedCustomerCareIds(
        $scope.selectedSettlementlIds,
      );

      $scope.reloadTable = _.noop();

      $scope.showUnlockedSettlement = () => {
        const isAdviserReadOnly = _.get(
          $scope.mainObj,
          'adviserObject.isSubscribedToCustomerCareType',
          false,
        );
        return $scope.isUnlockingPostSettlement && isAdviserReadOnly;
      };
      $scope.customerCareTabsService.reloadCustomerCarePostSettlementValues = ({
        showLockScreen,
        customerCareAdviserList,
      }) => {
        $scope.reloadTimeout = $timeout(() => {
          $scope.showLockScreen = showLockScreen;
          $scope.isUnlockingPostSettlement = $scope.unlockingCondition();
          $scope.customerCareAdviserList = isRetainAllAdviser(
            customerCareAdviserList,
            $scope.isUnlockingPostSettlement,
          );
          $scope.mainObj.adviserObject = _.get(
            $scope.customerCareAdviserList,
            '[0]',
            {},
          );
          $timeout.cancel($scope.reloadTimeout);
        });
      };
      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.loadPostSettlementTable = function () {
        setConfigMinMaxDate({ $scope });
        updateFilterFormat({ $scope });
        $scope.postSettlementTableParams = new NgTableParams(
          {
            page: 1,
            count: 10,
            sorting: {
              SettlementDate: 'asc',
            },
          },
          {
            counts: [],
            total: 0,
            getData(params) {
              const adviserFamilyId = _.get(
                $scope.mainObj,
                'adviserObject.familyId',
              );
              if (!adviserFamilyId && adviserFamilyId !== 0) {
                return;
              }
              $scope.isTableDataLoaderOverlayDisplay = true;
              $scope.sortColumn = sortColumn(params.orderBy);
              $scope.sortType = sortType(params.sorting);
              $scope.currentPage = params.page();
              const {
                toDate,
                formDate,
                ShowCompleted: showCompleted,
                settlementName: filterTab,
              } = $scope.mainObj;

              return customerCareService
                .SettlementmentDateListGet(
                  adviserFamilyId,
                  toDate,
                  formDate,
                  params.page(),
                  params.count(),
                  $scope.sortColumn,
                  $scope.sortType,
                  showCompleted,
                  filterTab,
                  getActiveTab($state.params.activeSubTab).typeId,
                )
                .then(
                  (response) => {
                    if (
                      typeof response.data !== 'undefined' &&
                      typeof response.data.SettlementmentDateList !==
                        'undefined'
                    ) {
                      $scope.postSettlementListTable =
                        response.data.SettlementmentDateList;
                      _.map($scope.postSettlementListTable, (item) => {
                        item.SettlementDate = new Date(item.SettlementDate);
                        // eslint-disable-next-line sonarjs/no-duplicate-string
                        const today = moment().format('D MMMM YYYY');
                        const stlDate = moment(item.SettlementDate).format(
                          'D MMMM YYYY',
                        );

                        if (today === stlDate) {
                          item.IsDueToday = true;
                        } else {
                          item.IsDueToday = false;
                        }
                        item.isChecked = $scope.selectedSettlementlIds.includes(
                          item.LoanID,
                        );
                      });
                      /* $scope.postSettlementListTable = $scope.postSettlementListTable.filter(function (o) {
                                    return o.isComplete === $scope.mainObj.ShowCompleted;
                                    }); */
                      if (typeof response.data.TotalCount !== 'undefined') {
                        params.total(response.data.TotalCount);
                      } else {
                        params.total(0);
                      }
                      $scope.checkedTableData = [];
                      $scope.isTableDataLoaderOverlayDisplay = false;
                      $scope.totalCount = response.data.TotalCount;
                      return $scope.postSettlementListTable;
                    }
                  },
                  () => {},
                );
            },
          },
        );

        $scope.reloadTable = () => {
          $scope.postSettlementTableParams.reload();
        };
      };

      $scope.adviserChanged = () => {
        adviserChanged(
          $scope.selectedSettlementlIds,
          $scope.postSettlementTableParams,
        );
      };

      $scope.onActionSelected = onActionSelectedUtil($scope);

      // Get Post Settlement
      $scope.getPostSettlementList = function () {
        $scope.loadPostSettlementTable();
      };

      // Date Range Filter
      $scope.dateRangeFilter = (tab) => {
        onChangeDateRange({ $scope, $state, stateService, $stateParams })(tab);
        $scope.isTableDataLoaderOverlayDisplay = true;
        if ($scope.mainObj.dateRange === 'tab1') {
          $scope.mainObj.toDate = moment()
            .subtract(6, 'days')
            .format('D MMMM YYYY');
          $scope.mainObj.formDate = moment().format('D MMMM YYYY');
          $scope.mainObj.settlementName = 'Settlement Day - SMS / Email';
        } else if ($scope.mainObj.dateRange === 'tab2') {
          const tempDate = moment().subtract(7, 'days').format('D MMMM YYYY');
          $scope.mainObj.toDate = moment(tempDate)
            .subtract(6, 'days')
            .format('D MMMM YYYY');
          $scope.mainObj.formDate = tempDate;
          $scope.mainObj.settlementName = '7 Days - Settlement Gift';
        } else if ($scope.mainObj.dateRange === 'tab3') {
          const tempDate = moment().subtract(14, 'days').format('D MMMM YYYY');
          $scope.mainObj.toDate = moment(tempDate)
            .subtract(6, 'week')
            .format('D MMMM YYYY');
          $scope.mainObj.formDate = tempDate;
          $scope.mainObj.settlementName = '6 Week Account Check Up';
        } else if ($scope.mainObj.dateRange === 'tab4') {
          const tempDate1 = moment().subtract(6, 'week').format('D MMMM YYYY');
          const tempDate = moment(tempDate1)
            .subtract(15, 'day')
            .format('D MMMM YYYY');
          $scope.mainObj.toDate = moment(tempDate)
            .subtract(3, 'months')
            .format('D MMMM YYYY');
          $scope.mainObj.formDate = tempDate;
          $scope.mainObj.settlementName = '3 Months - How is it going?';
        } else if ($scope.mainObj.dateRange === 'tab5') {
          const tempDate1 = moment().subtract(6, 'week').format('D MMMM YYYY');
          const tempDate2 = moment(tempDate1)
            .subtract(3, 'months')
            .format('D MMMM YYYY');
          const tempDate = moment(tempDate2)
            .subtract(16, 'day')
            .format('D MMMM YYYY');
          $scope.mainObj.toDate = moment(tempDate)
            .subtract(6, 'months')
            .format('D MMMM YYYY');
          $scope.mainObj.formDate = tempDate;
          $scope.mainObj.settlementName = '6 Months - Asset Finance';
        }
        updateSettlementFormat({ $scope });
        $scope.getPostSettlementList();
      };

      // Set Post Settlement as Complete
      $scope.setPostSettlementCompilation = function (postSettlement) {
        const notiHeader = `Post Settlement • ${$scope.mainObj.settlementName} Completed`;
        const $postData = [
          {
            CustomerCareType: CUSTOMER_CARE_TYPES.POST_SETTLEMENT_FOLLOW_UP,
            FamilyID: 0,
            LoanID: postSettlement.LoanID,
            IsComplete: postSettlement.isComplete,
            NotificationHeader: notiHeader,
            SettlementFilterTab: $scope.mainObj.settlementName,
            PostSettlementTypeId: getActiveTab($state.params.activeSubTab)
              .typeId,
          },
        ];
        customerCareService.setCompeletion($postData).then(
          () => {
            $scope.postSettlementTableParams.reload();
          },
          () => {},
        );
      };

      // Filter Through Day
      $scope.selectDayFilter = function (tab) {
        $timeout(() => {
          const el = $document.find(`#${tab} .nav-link`);
          angular.element(el).triggerHandler('click');
        }, 0);
        $scope.dateRangeFilter(tab);
      };

      $scope.changeDaySelection = (tab) => {
        $scope.selectedSettlementlIds.splice(
          0,
          $scope.selectedSettlementlIds.length,
        );
        $scope.dateRangeFilter(tab);
      };

      // Open modal For Get Post Settlement Detail in Mobile
      $scope.openModalForGetPostSettlementDetail = function (
        postSettlement,
        size,
      ) {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/post_settlement_filter_modal.html',
          controller: 'PostSettlementDetailsModalCtrl',
          size,
          resolve: {
            postSettlement() {
              return postSettlement;
            },
          },
        });
      };

      const modalResolve = (postSettlementObj, source) => {
        return {
          postSettlementListTable() {
            return $scope.postSettlementListTable;
          },
          postSettlementObj() {
            return postSettlementObj;
          },
          source() {
            return source;
          },
          loadPostSettlementTable() {
            return $scope.reloadTable;
          },
          mainObj: () => $scope.mainObj,
          isInsurance: () => false,
          tab: () => null,
        };
      };

      $scope.sendingResult = sendingResultUtil($scope);

      $scope.openSendEmailModal = function (size, postSettlementObj, source) {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_email_modal.html',
          resolve: modalResolve(postSettlementObj, source),
          controller: 'OpenPostSettlementSendEmailModalCtrl',
          size,
        });
      };
      $scope.openSendMailModal = function (size, postSettlementObj, source) {
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_mail_modal.html',
          resolve: {
            listTableData: () => {
              return $scope.postSettlementListTable;
            },
            sendMailObj: () => {
              return postSettlementObj;
            },
            source: () => {
              return source;
            },
            currentModuleId: () => {
              return POST_SETTLEMENT_FOLLOW_UP_ID;
            },
            currentModuleType: modalParamsUtil.currentModuleType(
              CUSTOMER_CARE_TYPES.POST_SETTLEMENT_FOLLOW_UP,
            ),
            currentModuleName: modalParamsUtil.currentModuleName(
              'Post Settlement Follow Up',
            ),
            isInsurance: modalParamsUtil.isInsurance(false),
            tab: modalParamsUtil.tab(null),
          },
          controller: 'OpenSendMailModalCtrl',
          size,
        });
        $scope.sendingResult(modalInstance, 'reloadTable');
      };
      $scope.openSMSModal = function (size, postSettlementObj, source) {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_sms_modal.html',
          resolve: modalResolve(postSettlementObj, source),
          controller: 'OpenPostSettlementSMSModalCtrl',
          size,
        });
      };

      const init = () => {
        setActiveSubTab({ $scope, $stateParams });
        $scope.selectDayFilter($scope.mainObj.dateRange);
        $scope.loadPostSettlementTable();
        $scope.initValues({
          configService,
          TAB_DETAILS: TAB_POST_SETTLEMENT_FOLLOW_UP,
        });
      };

      init();
      $scope.$on('$destroy', () => {
        $timeout.cancel($scope.reloadTimeout);
        $scope.reloadTimeout = null;
      });
    });
