import template from './yourTargets.html';
import controller from './yourTargetsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    parentToChildNotificationRegistration: '&',
  },
};
