import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';
import { ADVISER_STATUS } from 'Common/constants/adviserStatus';
import { toastInfo, toastError } from 'Common/utilities/alert';
import { pluralize } from 'Common/utilities/string';

export const trimTags = (tagsArray) => {
  if (!tagsArray || !tagsArray.length) {
    return [];
  }
  return tagsArray.map((tag) => tag.trim());
};
export const allowCorporateTeam = (userInfo, contactInfo) => {
  const allowedCorpTeam = [
    CORPORATE_TEAM.AGREEMENT_AND_ONBOARDING,
    CORPORATE_TEAM.SUPER_ADMIN,
  ];
  const corpTeamId = userInfo && userInfo.CorporateTeamId;
  const isOnboarding =
    contactInfo && contactInfo.BrokerStatusId === ADVISER_STATUS.ONBOARDING;
  return !!(isOnboarding && allowedCorpTeam.includes(corpTeamId));
};

export const getContactData = ($scope, corporateService) => {
  corporateService
    .corporateContactGet($scope.clientId, $scope.familyId)
    .then(({ data }) => {
      $scope.contactInfo = data;
    });
};

export const previewInsurancePDF = ({
  downloadDataService,
  insuranceApplicationService = {},
  InsuranceAppId = 0,
}) => {
  insuranceApplicationService.getApplicationPDF(InsuranceAppId).then((data) => {
    if (!data) {
      return;
    }
    const [fileName] = data.fileName;

    downloadDataService.download(
      `data:application/${data.fileExtension};base64,${data.content}`,
      fileName,
      data.fileExtension,
    );
  });
};

export const documentGet = ({
  documentID,
  generalService,
  downloadDataService,
}) => {
  generalService.documentGet(documentID).then(({ data }) => {
    if (data) {
      const { ContentType, DocumentContent, Name } = data;
      downloadDataService.download(
        `data:${ContentType};base64,${DocumentContent}`,
        Name,
        ContentType,
      );
    }
  });
};

export const downloadDocument = ({
  $scope = {},
  generalService,
  insuranceApplicationService,
  downloadDataService,
}) => {
  $scope.downloadDocument = (
    documentID = 0,
    { DocFromInsuranceApp, InsuranceAppId },
  ) => {
    if (DocFromInsuranceApp) {
      previewInsurancePDF({
        downloadDataService,
        insuranceApplicationService,
        InsuranceAppId,
      });
      return;
    }
    documentGet({ documentID, generalService, downloadDataService });
  };
};

export const downloadAllAttachment = ({
  $scope,
  contactModelService,
  downloadDataService,
  loanApplicationId,
  insuranceFileId,
  isUseAccordionDownload,
}) => () => {
  const downloadZipService = isUseAccordionDownload
    ? 'downloadAllAccordionDocuments'
    : 'getDocumentsZip';
  $scope.isAttachmentDownloading = true;
  const title = 'Sit back .. Relax ..';
  const message = `We are currently collecting and downloading ${pluralize(
    $scope.downloadAllDocsCount,
    'document',
  )} for you. It might take a little while so please be patient.`;
  toastInfo(message, title);
  const documentParams = {
    familyId: $scope.familyId,
    ...(isUseAccordionDownload
      ? { loanApplicationId, insuranceFileId }
      : { forLoanApp: true }),
  };
  contactModelService[downloadZipService](documentParams)
    .then((data) => {
      $scope.isAttachmentDownloading = false;
      if (!data) {
        return;
      }
      const { documentContent, name, contentType } = data;
      const content = `data:${contentType};base64,${documentContent}`;
      downloadDataService.download(content, name, contentType);
    })
    .catch(() => {
      $scope.isAttachmentDownloading = false;
      const errorMessage = `There's an error while downloading the files.`;
      toastError(errorMessage);
    });
};

export const countDownloadAllDocs = (documentsList) => {
  const filteredList = documentsList.filter(
    (obj) => obj.tag === 'others' || obj.tag === 'loanApp',
  );
  return filteredList.length;
};

export const showDownloadAll = ({ $scope }) => () => {
  return (
    !$scope.isLoadingEmails.isTrue() &&
    $scope.filterModel.loanApp &&
    $scope.filterModel.others &&
    $scope.downloadAllDocsCount > 0
  );
};
