import { NUMBER_PATTERN } from 'Common/constants/pattern';
import { KEY_CODE } from 'Common/constants/keyCode';
import { employmentTypeHasAbn } from 'Common/utilities/employment';
import { formatDate } from 'Common/utilities/date';

class EmploymentDetailsCtrl {
  constructor(
    loanApplicationServices,
    employmentModalService,
    generalService,
    uiService,
    $timeout,
    countryCodeService,
    currentUserService,
    contactModelService,
    configService,
  ) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.employmentModalService = employmentModalService;
    this.generalService = generalService;
    this.countryCodeService = countryCodeService;
    this.uiService = uiService;
    this.$timeout = $timeout;
    this.currentUserService = currentUserService;
    this.contactModelService = contactModelService;
    this.configService = configService;
  }

  $onInit() {
    this.NUMBER_PATTERN = NUMBER_PATTERN;
    this.getEmployerType();
    this.formatDate = formatDate('MMM YYYY');
  }

  $onChanges(changes) {
    const { addedDetails } = changes;
    if (addedDetails && addedDetails.currentValue) {
      this.getABNDetails(addedDetails.currentValue.abn);
    }
  }

  setErrorMessageOnKeyUp({ phoneNumber }) {
    this.setErrorMessage && this.setErrorMessage({ phoneNumber });
  }

  getEmployerType() {
    this.loanApplicationServices.getEmployerType().then((response) => {
      const { data } = response;
      if (!data) {
        return;
      }
      const newData = data.map((obj) => {
        obj.Value = parseInt(obj.Value, 10);
        return obj;
      });
      this.employment.EmployerTypeList = [
        { Name: 'Please select', Value: '' },
        ...newData,
      ];
    });
  }

  getFormattedAddress() {
    this.$timeout.cancel(this.searchTimeout);
    const { Address } = this.employment;
    const isSearchQueryValid = Address && Address.formatted_address;
    this.searchTimeout = this.$timeout(() => {
      if (isSearchQueryValid) {
        this.generalService
          .placeSearch(this.employment.Address.formatted_address)
          .then((response) => {
            this.addressSuggestions = response.data;
            this.showSuggestionModal = true;
          });
      }
    }, 500);
  }

  toggleSearch() {
    this.showSuggestionModal = !this.showSuggestionModal;
  }

  pickAddressSuggestion(homeAddress) {
    this.employment.Address = homeAddress;
    this.employment.Details.EmployerAddress = homeAddress;
    this.addressSuggestions = [];
    this.showSuggestionModal = false;
  }

  onInputChange({ data }) {
    const { name, value } = data;
    this.employment[name] = value;

    if (name === 'ABN') {
      this.employerDetails = null;
    }
  }

  getABNDetails(abn) {
    if (!abn) {
      return;
    }
    this.employment.checkingABN = true;
    this.contactModelService
      .getABNDetails(abn)
      .then((response) => {
        if (response) {
          const { gstRegisteredFrom, abnEffectiveFrom } = response;

          this.employment.abnError = null;
          this.employerDetails = {
            ...response,
            gstRegisteredDate:
              gstRegisteredFrom &&
              `GST Registered from ${this.formatDate(gstRegisteredFrom)}`,
            abnActiveDate:
              abnEffectiveFrom &&
              `ABN active from ${this.formatDate(abnEffectiveFrom)}`,
          };
        } else {
          this.employment.abnError = {
            type: 'error',
            msg: 'Oops, no businesses are registered with this ABN.',
          };
          this.employerDetails = null;
        }
        this.employment.checkingABN = false;
      })
      .catch(() => {
        this.employment.checkingABN = false;
      });
  }

  checkABNValidity({ data: abn }) {
    this.employment.ABN = abn;
    if (!abn) {
      this.employment.abnError = false;
      return;
    }

    const abnLength = this.NUMBER_PATTERN.ABN.replace(/ /g, '').length;
    if (abn.length !== abnLength) {
      this.employment.abnError = { type: 'error', msg: 'Invalid ABN format' };
      return;
    }

    this.getABNDetails(abn);
  }

  showAbnAcn() {
    const { EmploymentTypeId } = this.employment;
    return (
      this.configService.feature.abnValidation &&
      this.currentUserService.isAU &&
      employmentTypeHasAbn(EmploymentTypeId)
    );
  }

  onAbnKeyDown({ $e, data }) {
    const isKeySubmit = ($e && $e.keyCode) === KEY_CODE.WIN_ENT;
    if (isKeySubmit) {
      $e && $e.preventDefault();
      this.checkABNValidity({ data });
    }
  }
}

export default EmploymentDetailsCtrl;
