class LoanAppPdfService {
  constructor(httpClient, downloadDataService) {
    'ngInject';

    this.httpClient = httpClient;
    this.downloadDataService = downloadDataService;
    this.baseUrl = 'loan-app-pdf';
  }

  download(loanAppId) {
    return this.httpClient
      .post(`${this.baseUrl}/${loanAppId}/download`)
      .then(({ data }) => {
        this.downloadDataService.download(
          `data:${data.ContentType};base64,${data.DocumentContent}`,
          `${data.Name}`,
          data.ContentType,
        );
      });
  }
}

export default LoanAppPdfService;
