export function taskPdfBuilderForUI(data) {
  return {
    name: data.Name,
    contentType: data.ContentType,
    documentContent: data.DocumentContent,
    documentExists: data.DocumentExists,
    isSvg: data.IsSvg,
  };
}

export function taskFilterBuilderForUI(parameter) {
  return (
    parameter && {
      dueDateType: parameter.DueDateType,
      assignedBrokerId: parameter.AssignedBrokerId,
      createdByBrokerId: parameter.CreatedByBrokerId,
      clientAdviserId: parameter.ClientAdviserID,
    }
  );
}

export function taskAdviserListForUI(data) {
  return (
    data && {
      displayName: data.DisplayName,
      brokerId: data.BrokerID,
      firstName: data.FirstName,
      lastName: data.LastName,
      accessType: data.AccessType,
      profilePicture: data.ProfilePicture || '',
    }
  );
}

export function taskFilterBuilderForMyCRM(parameter) {
  return (
    parameter && {
      DueDateType: parameter.dueDateType,
      AssignedBrokerId: parameter.assignedBrokerId,
      CreatedByBrokerId: parameter.createdByBrokerId,
      ClientAdviserID: parameter.clientAdviserId,
    }
  );
}

export function taskListGetBuilderForUI(data) {
  return (
    data && {
      category: data.ActivityCategory,
      data: data.ActivityDate,
      details: data.ActivityDetails,
      id: data.ActivityID,
      note: data.ActivityNote,
      type: data.ActivityType,
      adviserId: data.AdviserId,
      adviserFirstName: data.AdviserFirstName,
      adviserLastName: data.AdviserLastName,
      adviserName: data.AdviserName,
      clientContact: data.ClientContact,
      clientContactnumbers: data.ClientContactnumbers,
      clientFirstName: data.ClientFirstName,
      clientLastName: data.ClientLastName,
      clientName: data.ClientName,
      contactNumber: data.ContactNumber,
      contactType: data.ContactType,
      createdByUser: data.CreatedByUser,
      createdByUserName: data.CreatedByUserName,
      createdDate: data.CreatedDate,
      dueDateDay: data.DueDateDay,
      dueDateMonth: data.DueDateMonth,
      familyId: data.FamilyID,
      isClient: data.IsClient,
      isCompleted: data.IsCompleted,
      isDueToday: data.IsDueToday,
      isOverDue: data.IsOverDue,
      createdByUserProfilePicture: data.CreatedByUserProfilePicture,
    }
  );
}

export const taskTemplateBuilderForUI = (data) => {
  return {
    name: data.TemplateName || data.TaskTemplateName || data.name,
    id: data.TemplateID || data.TaskTemplateID || data.id,
    list: data.TaskSettingsList || data.list || [],
  };
};

export const taskLinkToLoanBuilderForUI = (data) => {
  return (
    data && {
      taskLoanLinkId: data.TaskLoanLinkId || 0,
      loanId: data.LoanId || 0,
      taskId: data.TaskId || 0,
      isActive: data.IsActive || false,
      sharedToContactFamilyId: data.SharedToContactFamilyId || '',
      sharedToContactFamilyIdCsv: data.SharedToContactFamilyIdCsv || '',
      sharedToContactName: data.SharedToContactName || '',
    }
  );
};
