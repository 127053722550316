import moment from 'moment';
import dotProp from 'dot-prop-immutable';
import { get } from 'lodash';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import { typelessEqual } from 'Common/utilities/math';
import { LOAN_APP_STATUS_TYPES } from 'Common/constants/loanAppStatusTypes';
import { EXTRA_STATUS } from 'Common/constants/extraLoanStatus';
import {
  LOAN_STATUS,
  LOAN_STATUS_CATEGORY,
} from 'Common/constants/loanStatusCategory';
import { getOptionName } from 'Common/utilities/options';
import { toastSuccess, toastWarning } from 'Common/utilities/alert';
import { formatDateOffsetTimeToUTC } from 'Common/utilities/date';

export const updateRefNoOnlyLoanInfoUtil = (contactModelService) => (
  referenceNo,
  loanId,
) => {
  const isShowWarning = !loanId || !referenceNo || !contactModelService;
  if (isShowWarning) {
    return;
  }
  contactModelService.putLoanReferenceNo(loanId, referenceNo).then((res) => {
    if (res.data !== 1) {
      toastWarning('Reference number failed to update.', 'Warning');
    } else {
      toastSuccess('Reference number updated successfully.');
    }
  });
};

export const setLoanInfoLockUtil = (dependencies) => (params) => (
  toModifyLoanInformationService,
) => {
  const { loanScenarioService, currentUserService } = dependencies;
  const { familyId, loanStatusId, loanId } = params;

  if (
    !loanScenarioService ||
    !currentUserService ||
    !familyId ||
    !loanStatusId ||
    !loanId
  ) {
    return;
  }

  loanScenarioService.scenarioListGet(familyId).then((response) => {
    if (!response) {
      return;
    }

    const loanToCheck = response.data.find((loan) => loan.LoanId === loanId);
    const foundLoanScenarioId = loanToCheck && loanToCheck.LoanScenarioId;

    toModifyLoanInformationService.isLoanInfoLock =
      loanStatusId !== LOAN_STATUS.EXISTING.SETTLED &&
      !foundLoanScenarioId &&
      currentUserService.isAU;
  });
};

export const setDatesValue = ({ $scope, loanDetails }) => {
  if (!loanDetails || !loanDetails.loan) {
    return;
  }
  const {
    NotProceedingDate,
    ConditionallyApprovedDate,
    PreApprovedDate,
    EstimatedSettlementDate,
    RepaidDate,
    VariedDate,
  } = loanDetails.loan;
  $scope.setLoanDetails.NotProceedingDate = NotProceedingDate
    ? new Date(NotProceedingDate)
    : '';
  $scope.setLoanDetails.ConditionallyApprovedDate = ConditionallyApprovedDate
    ? new Date(ConditionallyApprovedDate)
    : '';
  $scope.setLoanDetails.PreApprovedDate = PreApprovedDate
    ? new Date(PreApprovedDate)
    : '';
  $scope.setLoanDetails.EstimatedSettlementDate = EstimatedSettlementDate
    ? new Date(EstimatedSettlementDate)
    : '';
  $scope.setLoanDetails.RepaidDate = RepaidDate ? new Date(RepaidDate) : '';
  $scope.setLoanDetails.VariedDate = VariedDate ? new Date(VariedDate) : '';
};
export const updateNotProceedingDate = ({
  $scope,
  loanScenarioModelService,
  loanSelected,
  result,
}) => {
  if (!result || !result.notProceedingDate) {
    return;
  }
  const dateParams = {
    loanApplicationId: 0,
    loanId: loanSelected.LoanID,
    dateTypeId: IMPORTANT_DATE_TYPES.DELETE.NOT_PROCEEDED,
    date: result.notProceedingDate,
  };
  loanScenarioModelService.updateImportantDate(dateParams).then(() => {
    $scope.setLoanDetails.NotProceedingDate = moment.parseZone(
      result.notProceedingDate,
    )._d;
  });
};

export const isImportedLoan = (details) => {
  const isImported = details && details.loan && details.loan.IsImportedLoan;
  return !!isImported;
};

export const formatImportantLoanDates = (loanDetails) => {
  if (!loanDetails) {
    return;
  }

  const importantDatesProps = [
    'ApprovalDate',
    'ApprovalExpiry',
    'ConditionallyApprovedDate',
    'EstimatedSettlementDate',
    'FinanceDate',
    'NotProceedingDate',
    'PreApprovalExpiry',
    'PreApprovedDate',
    'SettlementDate',
    'SubmittedDate',
    'RepaidDate',
  ];

  const formattedDates = importantDatesProps.reduce((accum, type) => {
    accum[type] =
      (loanDetails[type] && formatDateOffsetTimeToUTC(loanDetails[type])) ||
      loanDetails[type];
    return { ...accum };
  }, {});

  return { ...loanDetails, ...formattedDates };
};

export const onChangeDate = ({ $scope, loanService }) => ({
  defaultStatusId,
  dateValue,
}) => {
  if ($scope.isLoadingDetails || !$scope.setLoanDetails) {
    return;
  }
  $scope.updateImportantDates();
  const currentLoanStatusId =
    ($scope.setLoanDetails.LoanStatus &&
      $scope.setLoanDetails.LoanStatus.LoanStatusID) ||
    0;
  const parsedStatusId = parseInt(currentLoanStatusId, 10);
  const dontShowSelector =
    !dateValue || (parsedStatusId && parsedStatusId === defaultStatusId);
  if (dontShowSelector) {
    return;
  }
  const currentSelection =
    ($scope.statusList &&
      $scope.statusList.find(
        (status) => status.Value === currentLoanStatusId,
      )) ||
    {};
  $scope.setLoanDetails.LoanStatus.LoanStatusName = currentSelection.Name || '';
  const resp = loanService.setDefaultLoanStatusForImportantDates(
    $scope.setLoanDetails,
    defaultStatusId,
  );
  onSetDefaultCallback({ resp, $scope });
};

export const onSetDefaultCallback = ({ resp, $scope }) => {
  resp &&
    resp.then(({ isOk }) => {
      if (!isOk) {
        return;
      }
      const selectedStatus =
        ($scope.setLoanDetails.LoanStatusList &&
          $scope.setLoanDetails.LoanStatusList.find(
            (loan) => loan.Value === $scope.setLoanDetails.defaultStatusLoanId,
          )) ||
        {};
      $scope.setLoanDetails.LoanStatus.LoanStatusName =
        selectedStatus.Name || '';
      $scope.setLoanDetails.LoanStatus.LoanStatusID =
        $scope.setLoanDetails.defaultStatusLoanId;
      const isNotProceeded = typelessEqual(
        $scope.setLoanDetails.defaultStatusLoanId,
        LOAN_APP_STATUS_TYPES.NOT_PROCEEDED,
      );
      $scope.updateImportantDates(false, !isNotProceeded);
    });
};

export const onInit = ({ $scope, optionsService }) => {
  $scope.loanModalTab = $scope.params.loanModalTab || $scope.loanModalTab;
  $scope.isHideModalSidebar = !!$scope.params.hideSidebar;

  const included = [EXTRA_STATUS.CANCELLED, EXTRA_STATUS.LOAN_REPAID].join();
  optionsService.getLoanStatus(included).then(({ data }) => {
    $scope.statusList = data || [];
  });
};

export const setParamsLendingCategory = ({ $scope }) => {
  const categoryObject =
    $scope.LOAN_LENDING_CATEGORY[$scope.setLoanDetails.LendingCategoryId];
  const categoryName = categoryObject && categoryObject.LABEL;
  const { LendingCategoryId: lendingCategoryId } = $scope.setLoanDetails;
  const { loanInformation } = $scope.params;
  $scope.params.loanInformation = {
    ...loanInformation,
    LendingCategoryName: categoryName,
    LendingCategoryId: lendingCategoryId,
  };
};

export const callSettlementAddressModal = ({
  $scope,
  $state,
  importantDatesService,
}) => {
  const isSettled =
    $scope.setLoanDetails.LoanStatus &&
    $scope.setLoanDetails.LoanStatus.LoanStatusID ===
      LOAN_STATUS.EXISTING.SETTLED.toString();
  if (!isSettled) {
    return;
  }
  importantDatesService.callPostSettlementModal(
    $state.params.familyId,
    $scope.setLoanDetails.LoanScenarioID,
  );
};

export const sendNPSSurvey = ({ $scope = {}, currentUserService }) => {
  $scope.currentUserService = currentUserService;
  $scope.LOAN_SETTLE = (LOAN_STATUS.EXISTING.SETTLED || '').toString();
  $scope.onUpdateNPSSurvey = (isNPS) => {
    $scope.setLoanDetails.IsNPSSurvey = isNPS;
    $scope.saveLoanData();
  };

  $scope.isSettle =
    $scope.setLoanDetails.LoanStatus.LoanStatusID === $scope.LOAN_SETTLE;
};

export const getAssistantsList = ({ $scope, optionsService }) => (
  adviserId,
) => {
  if (!adviserId) {
    return;
  }
  optionsService.adviserAssistantsGet(adviserId).then(({ data }) => {
    $scope.assistantsList = data;
    const asstId =
      $scope.setLoanDetails && $scope.setLoanDetails.AssistantFamilyID;
    if (!asstId) {
      return;
    }
    $scope.setLoanDetails.AssistantName = getOptionName(data, asstId);
  });
};

export const formatSelectedAdviser = (adviserId, adviserList) => {
  if (!adviserList || !adviserList.length) {
    return;
  }
  const adviser = adviserList.find((adv) => adv.familyId === adviserId);
  if (!adviser) {
    return;
  }
  return { title: adviser.fullName, originalObject: { ...adviser } };
};

export const directAssistantUpdate = ({ $scope, loanScenarioService }) => (
  assistantFamilyId,
  showSuccess = true,
) => {
  const param = { loanId: $scope.params.loanId, assistantFamilyId };
  loanScenarioService.assignAssistant(0, param).then(() => {
    $scope.setLoanDetails.AssistantFamilyID = assistantFamilyId;
    $scope.setLoanDetails.AssistantName = getOptionName(
      $scope.assistantsList,
      assistantFamilyId,
    );
    if (showSuccess) {
      toastSuccess('Assistant successfully saved!');
    }
  });
};

export const allocateAssistant = ({ $scope }) => (data) => {
  const assistantId = data && dotProp.get(data, 'originalObject.Value');
  if (!assistantId) {
    return;
  }
  $scope.setLoanDetails.AssistantFamilyID = assistantId;
  $scope.directAssistantUpdate(assistantId);
};

export const showOriginalLoanWriter = ({ $scope, configService, loan }) => {
  const originalLoanWriter = loan.OriginalLoanWriter;
  const isLoanExisting =
    $scope.StatusCategoryName === LOAN_STATUS_CATEGORY.EXISTING;
  const isSameLoanWriterAndAdviser =
    originalLoanWriter === loan.OwnedByAdviserName;
  $scope.setLoanDetails.OriginalLoanWriter = originalLoanWriter;
  $scope.showOriginalLoanWriter =
    isLoanExisting &&
    !isSameLoanWriterAndAdviser &&
    configService.feature.originatingAdviserField;
  $scope.loanWriterDummyArray = [{ fullName: originalLoanWriter }];
};

export const getNPSSurveyToSave = ({ $scope }) => {
  if (!$scope) {
    return false;
  }
  const loanStatus = get($scope, 'setLoanDetails.LoanStatus.LoanStatusID');
  const isSettled = loanStatus === LOAN_STATUS.EXISTING.SETTLED.toString();
  const isFromLoanApp = get(
    $scope,
    'params.loanInformation.IsItFromLoanProgress',
  );
  return $scope.loanAdviserNPSEnabled && isSettled && isFromLoanApp;
};
