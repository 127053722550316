import template from './phoneIconField.html';
import controller from './phoneIconFieldCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    id: '<',
    phoneModel: '<',
    name: '@',
    isRequired: '<',
    onSave: '&',
    onUpdate: '&',
    onFocusOut: '&',
    isShowWarning: '<',
    isInternationalPhone: '<',
    phoneCountryCode: '<',
    selectedPhoneCountryCode: '<',
    inputName: '<',
  },
};
