export const NEXT_THREE_MONTHS = {
  WIDGET_OPTION_ID: '4',
  DISPLAY_VALUE: 'Next 3 Months',
  OPTION_NAME: 'TimePeriod 2',
  WIDGET_OPTION_VALUE: '14',
  DB_VALUE: '5',
  SELECTED_USER_VALUES: '0',
  SELECTED_USER_DISPLAY_VALUES: '',
};

export const TIME_GREETINGS_RANGES = [
  {
    start: 0,
    end: 12,
    value: 'morning',
    icon: '<icon-home-morning></icon-home-morning>',
    userGreeting: 'Good morning, User',
  },
  {
    start: 12.01,
    end: 17,
    value: 'afternoon',
    icon: '<icon-home-afternoon></icon-home-afternoon>',
    userGreeting: 'Good afternoon, User',
  },
  {
    start: 17.01,
    end: 23.59,
    value: 'evening',
    icon: '<icon-home-evening></icon-home-evening>',
    userGreeting: 'Good evening, User',
  },
];

export const TIME_GREETINGS_DEFAULT = TIME_GREETINGS_RANGES[0];
