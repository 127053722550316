import swal from 'sweetalert';
import {
  BENEFIT_ICONS,
  INSURANCE_QUOTE_REPORT_SECTION,
} from 'Common/constants/insuranceOptions';
import { COLOR } from 'Common/constants/colors';
import {
  INSURANCE_PACK_TYPE,
  LAUNCH_LOCATION,
} from 'Common/constants/helloBook';
import { toastError } from 'Common/utilities/alert';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import { triggerGA } from 'Common/utilities/googleAnalytics';

export const setupHelloPackInsurance = ({
  vm,
  insurancePipelineService,
  insuranceApplicationService,
}) => {
  vm.initInsuranceApp = () => {
    vm.BENEFIT_ICONS = BENEFIT_ICONS;
    vm.isProviderSelected = !!(
      vm.provider &&
      vm.provider.name &&
      vm.provider.id
    );
    vm.model = {
      ...vm.model,
      sections: {
        ccToAdviser: false,
        isViewQuote: true,
        includeBrochures: vm.isProviderSelected,
        includePolicyDocuments: vm.isProviderSelected,
      },
    };
    vm.quote = {};
    vm.insuranceSharedData.isHelloPackApplicationSent = false;
    vm.isQuoteType = vm.activePackType === INSURANCE_PACK_TYPE.QUOTE;
    !vm.isQuoteType && vm.getApplicationQuote();
  };

  vm.setDefaultQuoteCustomPlaceholder = () => {
    return vm.isQuoteType && vm.isProviderSelected
      ? `<p>As discussed, ${vm.provider.name} is the insurance company that I recommend best suits your situation.
    </p>`
      : `<p>
      Please click the below link to view your quote report.
    </p>
    <p>As discussed, I have not yet recommended an insurer.</p>`;
  };

  vm.getApplicationQuote = () => {
    return insuranceApplicationService
      .getApplicationQuote(vm.quoteId)
      .then((response) => {
        vm.quote = response;
      });
  };

  vm.triggerGA = (sendType) => {
    const param = vm.getMappedInsuranceApplicationData(sendType);
    const gaEvent = {
      event: 'send',
      category: 'Online Insurance',
      action: 'Send Application Invite',
      label: param.Insurer,
    };
    triggerGA(gaEvent);
  };

  const emailSendCallback = (response, sendType) => {
    vm.setButtonLoading(sendType, false);
    if (response && !response.succeeded) {
      toastError();
      return;
    }

    vm.isQuoteType && vm.quoteSaveSuccess();
    !vm.isQuoteType && vm.applicationSaveSuccess();
  };

  const doSendEmail = (sendType) => {
    const attachments = [
      ...(vm.documentsUploaded || []),
      ...(vm.attachedFiles || []),
    ]
      .filter((doc) => doc.DocumentId || doc.documentId)
      .map((doc) => ({
        DocumentID: doc.DocumentId || doc.documentId,
        DocumentKey: doc.documentKey || doc.DocumentKey,
        Name: doc.name || doc.Name,
      }));

    const mapper = vm.isQuoteType
      ? 'getMappedInsuranceQuoteData'
      : 'getMappedInsuranceApplicationData';

    const apiRoute = vm.isQuoteType
      ? 'sendInsuranceQuotePack'
      : 'sendApplication';

    const service = vm.isQuoteType
      ? insurancePipelineService
      : insuranceApplicationService;

    return service[apiRoute](
      vm[mapper](sendType, attachments),
    ).then((response) => emailSendCallback(response, sendType));
  };

  vm.sendInsuranceApplication = (sendType) => {
    vm.setButtonLoading(sendType, true);
    if (vm.activePackType === INSURANCE_PACK_TYPE.APPLICATION) {
      vm.triggerGA(sendType);
    }

    if (vm.attachedFiles.length) {
      return vm.onUploadFiles(() => doSendEmail(sendType));
    } else {
      return doSendEmail(sendType);
    }
  };

  vm.getMappedInsuranceApplicationData = (sendType, attachments = []) => {
    return {
      QuoteID: vm.quoteId,
      FamilyID: vm.familyId,
      ClientID: vm.clientId,
      Email: vm.model.email,
      MobileNumber: vm.model.phone,
      Message: vm.editorMessage,
      SendType: sendType,
      IsCCtoAdviser: vm.model.sections.ccToAdviser,
      IsViewQuote: vm.model.sections.isViewQuote,
      Insurer: vm.provider.name,
      Attachments: attachments,
    };
  };

  vm.getMappedInsuranceQuoteData = (sendType, attachments = []) => {
    const {
      PREMIUM_COMPARISON_GRAPH,
      INSURER_RECOMMENDATION,
      WOP_BENEFITS,
      HEALTH_INSURANCE_EXCESS,
      ONLINE_APP_LINK,
    } = INSURANCE_QUOTE_REPORT_SECTION;

    return {
      QuoteID: vm.quoteId,
      FamilyID: vm.familyId,
      ClientID: vm.clientId,
      Email: vm.model.email,
      MobileNumber: vm.model.phone,
      Message: vm.editorMessage,
      SendType: sendType,
      Documents: attachments,
      IsCCtoAdviser: vm.model.sections.ccToAdviser,
      IsViewQuote: vm.model.sections.isViewQuote,
      Insurer: vm.provider.name,
      InsurerId: vm.provider.id,
      IsBrochureIncluded: vm.model.sections.includeBrochures,
      IsPolicyDocumentIncluded: vm.model.sections.includePolicyDocuments,
      SaveToClientDocuments: vm.options.saveClientDocument,
      IsImplementationReportIncluded: vm.options.implementationReport,
      InsuranceFileId: vm.options.insuranceFileId,
      Recommendation: {
        Insurer: vm.provider.name,
        Reason: vm.options.recommendationContent,
      },
      ClientEmails: [
        ...(
          vm.clients || [
            { personId: '', formattedEmail: '', isSelected: false },
          ]
        )
          .filter(({ isSelected }) => isSelected)
          .map(({ personId, formattedEmail }) => ({
            ClientId: personId,
            Email: formattedEmail,
          })),
      ],
      Sections: vm.options &&
        vm.options.sectionsIncluded && {
          IsLinkToOnlineApplication: !!vm.options.sectionsIncluded[
            ONLINE_APP_LINK
          ],
          IsIncomeRepaymentTableIncluded: !!vm.options.sectionsIncluded[
            WOP_BENEFITS
          ],
          IsInsurerRecommendationIncluded: !!vm.options.sectionsIncluded[
            INSURER_RECOMMENDATION
          ],
          IsPremiumComparisonGraphIncluded: !!vm.options.sectionsIncluded[
            PREMIUM_COMPARISON_GRAPH
          ],
          IsHealthInsuranceExcessTableIncluded: !!vm.options.sectionsIncluded[
            HEALTH_INSURANCE_EXCESS
          ],
        },
    };
  };

  vm.applicationSaveSuccess = () => {
    const message =
      !vm.isQuoteType &&
      vm.options &&
      vm.options.location === LAUNCH_LOCATION.INSURANCE_TAB
        ? 'Link sent to client.'
        : 'Insurance application successfully created.';

    const props = {
      title: 'Success.',
      text: message,
      type: 'success',
      showCancelButton: false,
      confirmButtonColor: COLOR.BUTTERFLY_BUSH,
      confirmButtonText: 'OK',
      closeOnConfirm: true,
    };

    swal(props, (confirm) => {
      if (confirm) {
        vm.insuranceSharedData.isHelloPackApplicationSent = true;
        vm.modalInstance && vm.modalInstance.close();
      }
    });
  };

  vm.quoteSaveSuccess = () => {
    vm.crmConfirmation.notify({
      title: 'Quote Sent',
      message: 'An email has been sent to your client',
    });
    vm.modalInstance && vm.modalInstance.close();
    isValidObjectValue(vm.options.successCallback);
  };
};
