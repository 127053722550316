import angular from 'angular';
import _ from 'lodash';
import {
  getCommissionUpdateData,
  listDataFromObject,
} from 'Common/utilities/commission';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';
import {
  formatNotRecievedItem,
  getExpectedDate,
} from './util/expectedCommissionCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('ExpectedCommissionCtrl', function ExpectedCommissionCtrl(
      $scope,
      commissionService,
      contactService,
      optionsService,
      utilitiesService,
      SweetAlert,
      $uibModal,
      toaster,
      NgTableParams,
      $filter,
      uiService,
    ) {
      const vm = this;
      vm.pageSource = PAGE_SOURCE.COMMISSIONS;
      /*
       * @desc array of objects for desktop table list
       */
      vm.notReceivedList = [];
      /*
       *@desc mobile/tablet list, should be grouped by 5
       */
      vm.notReceivedMobileList = [];

      /*
       *@desc array of objects for desktop table list
       */
      vm.futureExpectedList = [];
      /*
       *@desc mobile/tablet list, should be grouped by 5
       */
      vm.futureExpectedMobileList = [];

      vm.typeList = [];

      /*
       *@desc date settings
       */
      vm.dateSettings = {
        format: 'dd MMM yyyy',
        dateOptions: {
          formatYear: 'yy',
          showWeeks: false,
        },
        altInputFormats: ['M!/d!/yyyy'],
        openDate: (obj) => {
          obj.dateIsOpen = !obj.dateIsOpen;
        },
      };

      /*
       *@desc list of brokers
       */
      vm.brokersList = [];

      /*
       *@desc selected not received broker
       */
      vm.notReceivedBroker = 0;

      /*
       *@desc selected future expected broker
       */
      vm.futureExpectedBroker = 0;

      /*
       *@desc selected broker filter
       */
      vm.brokerFilter = 0;

      vm.lendersList = [];
      vm.futureExpectedLender = 0;

      /*
       *@name commissionDetails
       *@desc show Commission Details modal
       *@param obj data
       */
      vm.commissionDetails = (obj, isFutureExpected) => {
        const modalInstance = $uibModal.open({
          templateUrl: 'commissionDetails.html',
          size: 'sm',
          backdrop: 'static',
          keyboard: false,
          controller: 'ExpectedCommissionDetailsCtrl as commissionDetailsVm',
          resolve: {
            commissionDetails() {
              return obj;
            },
            typeList() {
              return vm.typeList;
            },
            dateSettings() {
              return vm.dateSettings;
            },
            commissionTypesList() {
              return vm.commissionTypesList;
            },
            isFutureExpected: () => {
              return isFutureExpected;
            },
          },
          windowClass: 'commission-details-modal',
        });

        modalInstance.result.then((response) => {
          if (
            response &&
            response.commission &&
            response.actionDone === 'delete'
          ) {
            vm.removeCommissionFromList(response.commission);
          }
        });
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      vm.loadSummary = (type) => {
        /* avoid calling get api if it's corporate and either broker dropdown is 0 */
        if (uiService.isCorporateUser && vm.brokerFilter === 0) {
          return;
        }

        if (type === 'not_received') {
          vm.isLoadingNotReceivedList = true;

          vm.notReceivedListTableParams = new NgTableParams(
            {
              page: 1,
              count: 10,
            },
            {
              getData(params) {
                vm.isLoadingNotReceivedList = true;
                vm.notReceivedListTableParams.CurrentPage = 1;
                return commissionService
                  .commissionsSummaryGet({
                    adviserId: vm.brokerFilter || 0,
                    noOfDays: null,
                    pageNumber: params.page(),
                    pageSize: params.count(),
                  })
                  .then(
                    (response) => {
                      vm.notReceivedList.length = 0;
                      vm.notReceivedMobileList.length = 0;
                      _.each(response.data, (obj) => {
                        vm.notReceivedList.push({
                          ...formatNotRecievedItem(obj),
                        });
                      });
                      vm.notReceivedMobileList = vm.groupList(
                        vm.notReceivedList,
                      );
                      if (vm.notReceivedList.length > 0) {
                        params.total(vm.notReceivedList[0].TotalCount);
                      } else {
                        params.total(0);
                      }
                      vm.isLoadingNotReceivedList = false;
                      return vm.notReceivedList;
                    },
                    () => {
                      vm.isLoadingNotReceivedList = false;
                    },
                  );
              },
            },
          );
        } else if (type === 'future_expected') {
          vm.isLoadingFutureExpectedList = true;
          vm.futureExpectedListTableParams = new NgTableParams(
            {
              page: 1,
              count: 10,
            },
            {
              getData(params) {
                vm.isLoadingFutureExpectedList = true;
                vm.futureExpectedListTableParams.CurrentPage = 1;
                return commissionService
                  .commissionsSummaryGet({
                    adviserId: vm.brokerFilter || 0,
                    noOfDays: vm.futureExpectedDaysCount,
                    pageNumber: params.page(),
                    pageSize: params.count(),
                    providerId: vm.futureExpectedLender,
                  })
                  .then(
                    (response) => {
                      vm.futureExpectedList.length = 0;
                      vm.futureExpectedMobileList.length = 0;
                      _.each(response.data, (obj) => {
                        vm.futureExpectedList.push({
                          ...listDataFromObject(obj),
                          expectedDate: getExpectedDate(obj),
                        });
                      });
                      vm.futureExpectedMobileList = vm.groupList(
                        vm.futureExpectedList,
                      );
                      if (vm.futureExpectedList.length > 0) {
                        params.total(vm.futureExpectedList[0].TotalCount);
                      } else {
                        params.total(0);
                      }
                      vm.isLoadingFutureExpectedList = false;
                      return vm.futureExpectedList;
                    },
                    () => {
                      vm.isLoadingFutureExpectedList = false;
                    },
                  );
              },
            },
          );
        } else {
          vm.loadSummary('not_received');
          vm.loadSummary('future_expected');
        }
      };

      vm.getInitials = (name) => {
        return utilitiesService.filterInitialOneString(name);
      };

      vm.triggerDelete = (commission) => {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This commission will be removed from the list.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              contactService
                .loansExpectedCommissionsDelete(commission.CommissionId)
                .then(() => {
                  toaster.pop(
                    'success',
                    'Deleted',
                    'Commission successfully deleted',
                  );
                  vm.removeCommissionFromList(commission);
                });
            }
          },
        );
      };

      vm.isPercentageUp = (percentage) => {
        return percentage > 0;
      };

      vm.abs = (number) => {
        return number ? Math.abs(number) : 0;
      };

      vm.updateCommission = (commission) => {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This record will be removed from your commission list',
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, confirm it!',
            closeOnConfirm: true,
          },
          (confirm) => {
            if (confirm) {
              const type = _.find(vm.commissionTypesList, (obj) => {
                return obj.Name === commission.TypeName;
              });
              const commissionUpdate = getCommissionUpdateData(
                commission,
                type,
              );

              contactService
                .loansExpectedCommissionsSet(commissionUpdate)
                .then(() => {
                  toaster.pop(
                    'success',
                    'Updated',
                    'Commission successfully updated',
                  );
                  vm.notReceivedListTableParams.reload();
                  vm.notReceivedListTableParams.page(
                    vm.notReceivedListTableParams.CurrentPage,
                  );
                });
            } else {
              commission.isConfirm = false;
            }
          },
        );
      };

      /*
       *@name groupList
       *@desc will group the array by array of 5 objects for mobile view slick design
       *@param list
       *@return the new list of array with array of 5 objects
       */
      vm.groupList = (list) => {
        const shallowList = _.clone(list);
        return _.reduce(
          shallowList,
          (result, value, index) => {
            if (index % 5 === 0) {
              result.push(shallowList.slice(index, index + 5));
            }
            return result;
          },
          [],
        );
      };

      vm.removeCommissionFromList = (commission) => {
        const predicate = (obj) => {
          return obj.CommissionId === commission.CommissionId;
        };
        if (vm.notReceivedList.includes(commission)) {
          _.remove(vm.notReceivedList, predicate);
          _.each(vm.notReceivedMobileList, (group) => {
            if (group.includes(commission)) {
              _.remove(group, predicate);
              return false;
            }
          });
        } else {
          _.remove(vm.futureExpectedList, predicate);
          // eslint-disable-next-line sonarjs/no-identical-functions
          _.each(vm.futureExpectedMobileList, (group) => {
            if (group.includes(commission)) {
              _.remove(group, predicate);
              return false;
            }
          });
        }
        vm.notReceivedListTableParams.reload();
        vm.notReceivedListTableParams.page(
          vm.notReceivedListTableParams.CurrentPage,
        );

        vm.futureExpectedListTableParams.reload();
        vm.futureExpectedListTableParams.page(
          vm.futureExpectedListTableParams.CurrentPage,
        );

        vm.loadCommissionsStatistics();
      };

      /*
       *@name getAllBrokers
       *@desc get brokerslist
       */
      function getAllBrokers() {
        commissionService.getAllBrokers().then((response) => {
          if (uiService.isCorporateUser) {
            response.data.shift();
            vm.brokersList = [
              { BrokerFullName: 'Please select broker', BrokerID: 0 },
              ...response.data,
            ];
          } else {
            vm.brokersList = response.data;
          }

          vm.loadSummary('not_received');
          vm.loadSummary('future_expected');
        });
      }

      function getAllLenders() {
        optionsService.getLenderList().then((response) => {
          response.data.splice(0, 0, {
            ProviderId: 0,
            ProviderName: 'All Lenders',
          });
          vm.lendersList = response.data;
        });
      }

      function getCommissionTypes() {
        commissionService.commissionsTypeGet().then((response) => {
          vm.commissionTypesList = response.data;
        });
      }

      function getSummaryDaysOptions() {
        commissionService.commissionsSummaryDaysGet().then((response) => {
          vm.summaryDays = response.data;

          if (vm.summaryDays && vm.summaryDays.length) {
            vm.futureExpectedDaysCount = vm.summaryDays[0].Value;
          }
        });
      }

      vm.loadCommissionsStatistics = () => {
        contactService.expectedCommissionSummaryTotalGet().then((response) => {
          if (response && response.length) {
            $scope.totalCommission = {};

            response.forEach((stats) => {
              switch (stats.type) {
                case 'THIS_WEEK':
                  $scope.totalCommission.thisWeek = stats;
                  break;
                case 'NEXT_WEEK':
                  $scope.totalCommission.nextWeek = stats;
                  break;
                case 'THIS_MONTH':
                  $scope.totalCommission.thisMonth = stats;
                  break;
                case 'NEXT_MONTH':
                  $scope.totalCommission.nextMonth = stats;
                  break;
                default:
                // do nothing
              }
            });
          }
        });
      };

      vm.launchCommissionEnquiry = (expectedCommission) => {
        if (!expectedCommission) {
          return;
        }

        $uibModal.open({
          templateUrl:
            'assets/views/commission/modal/commissionEnquiryModal.html',
          controller: 'CommissionEnquiryModalCtrl',
          controllerAs: 'vm',
          size: 'lg',
          resolve: {
            commission: null,
            familyId: () => expectedCommission.FamilyID,
            loanId: () => expectedCommission.LoanId,
          },
        });
      };

      /*
       *@name init
       *@desc run blocks of initializations
       */
      vm.$onInit = () => {
        getAllBrokers();
        getAllLenders();
        getCommissionTypes();
        getSummaryDaysOptions();
        vm.loadCommissionsStatistics();
      };
    });
