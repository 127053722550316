import { createEmploymentColumnContent } from './util/contactEmploymentTable';

export default class ContactEmploymentTableCtrl {
  $onInit() {
    this.gridTemplate = {
      'grid-template-columns': '70px 180px 1fr 2fr 2fr 27px 27px',
      'grid-column-gap': '5px',
    };
    this.columnHeader = [
      {
        key: 'client',
        title: 'Client',
      },
      {
        key: 'employer',
        title: 'Employer',
      },
      {
        key: 'type',
        title: 'Type',
      },
      {
        key: 'role',
        title: 'Role',
      },
      {
        key: 'timeframe',
        title: 'Timeframe',
      },
      {
        key: 'edit',
        title: '',
      },
      {
        key: 'delete',
        title: '',
      },
    ];
    this.columnContent = [];
    this.processEmployment(this.employmentList);
  }

  $onChanges(changeObject) {
    const { employmentList } = changeObject;
    const { previousValue, currentValue } = employmentList;

    if (employmentList.isFirstChange()) {
      return;
    }
    if (previousValue !== currentValue) {
      this.processEmployment(currentValue);
    }
  }

  processEmployment(employment) {
    this.columnContent = createEmploymentColumnContent(employment);
  }
}
