import template from './productComparisonItem.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    product: '<',
    facility: '<',
    countryCode: '<',
    onDeleteFavoriteProduct: '&',
    onSetFacilityFavoriteProduct: '&',
  },
};
