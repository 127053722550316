import { parseToInt10 } from 'Common/utilities/parse';
import get from 'lodash/get';

export const parentBuilderForAPI = (data) => {
  return {
    dependantClientID: parseToInt10(data.dependantClientID),
    parentGuardianClientID: parseToInt10(data.parentGuardianClientID),
  };
};

export const toggleBuilderForAPI = (data) => {
  return {
    loanScenarioID: parseToInt10(data.loanScenarioID),
    familyID: parseToInt10(data.familyID),
    clientID: parseToInt10(data.clientID),
  };
};

export const dependantBuilderForUI = (data) => {
  return {
    Age: data.Age,
    ClientId: data.ClientID,
    DOB: data.DOB,
    DateOfBirth: data.DateOfBirth,
    FaceToFaceIdentityCheck: data.FaceToFaceIdentityCheck,
    FamilyFullName: data.FamilyFullName,
    FamilyId: data.FamilyID,
    FirstName: data.FirstName,
    Gender: data.Gender,
    GenderIntValue: data.GenderIntValue,
    IsExistingCustomer: !!data.IsExistingCustomer,
    LastName: data.LastName,
    LegalName: data.LegalName,
    MiddleName: data.MiddleName,
    IncludedInLoanApp: data.IncludedInLoanApp,
    PersonId: data.PersonId,
    PreferredName: data.PreferredName,
    Role: `${data.RoleID}`,
    Title: data.Title,
    ParentGuardianId: get(data, 'ParentGuardian.ClientID', null),
    ParentGuardian: get(data, 'ParentGuardian.FullName', null),
  };
};
