import angular from 'angular';
import { forEach } from 'lodash';
import { DEFAULT_INCOME_TYPES } from 'Common/constants/incomeTypes';
import { EMPLOYMENT_TYPE } from 'Common/constants/employmentType';
import EmploymentData from 'Common/services/employmentData';
import { DEFAULT_ASSETS } from 'Common/constants/financialAssets';
import { countryCodeInputElement } from 'Common/utilities/countryCodeUtility';
import { isStartEndDateDropdownsInvalid } from 'Common/utilities/date';
import { getFormErrorMessage } from 'Common/utilities/formValidation';
import { employmentTypeHasAbn } from 'Common/utilities/employment';
import { toastInfo, toastError } from 'Common/utilities/alert';
import {
  employmentModalInstance,
  getEmploymentDays,
} from './util/employmentModal';

const DEFAULT_INCOME = {
  TYPE: {
    SALARY_WAGE: 19,
  },
  FREQUENCY: {
    YEARLY: 1,
  },
  VERIFICATION: {
    CURRENT_PAYSLIPS: 1,
  },
};

class EmploymentModalCtrl {
  constructor(
    $timeout,
    $document,
    loanApplicationServices,
    employmentModalService,
    currentUserService,
    employmentService,
    dashboardService,
    timeoutService,
    contactService,
    configService,
    uiService,
    countryCodeService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$document = $document;
    this.loanApplicationServices = loanApplicationServices;
    this.employmentModalService = employmentModalService;
    this.currentUserService = currentUserService;
    this.employmentService = employmentService;
    this.dashboardService = dashboardService;
    this.timeoutService = timeoutService;
    this.contactService = contactService;
    this.configService = configService;
    this.uiService = uiService;
    this.employmentModalUtilityInstance = employmentModalInstance(this);
    this.countryCodeService = countryCodeService;
    this.employmentType = EMPLOYMENT_TYPE;
    this.angular = angular;
    this.countryCodeInputElement = countryCodeInputElement;
  }

  initInternationalPhoneFlags() {
    this.isInternationalPhoneEnabled = this.uiService.isInternationalPhoneNumber;
  }

  initializeCountryCode(countryCode) {
    this.employment.CountryCode =
      countryCode ||
      this.countryCodeService.setDefaultCountryCode({
        isInternationalPhoneNumbers: this.isInternationalPhoneEnabled,
        defaultCountryCode: this.currentUserService.isAU,
      });
    this.selectedCountryCode = this.employment.CountryCode;
  }

  getDefaultValuesAU() {
    this.defaultValuesAU = {
      frequency:
        this.uiService.isCountry('Australia') &&
        DEFAULT_INCOME.FREQUENCY.YEARLY,
      verification:
        this.uiService.isCountry('Australia') &&
        DEFAULT_INCOME.VERIFICATION.CURRENT_PAYSLIPS,
      incomeType:
        this.uiService.isCountry('Australia') &&
        DEFAULT_INCOME.TYPE.SALARY_WAGE,
    };
    return this.defaultValuesAU;
  }

  currentEmploymentChanged() {
    const isValidForPoppingIncome =
      !this.newIncomeDetails &&
      !this.employment.IsCurrent &&
      this.employment.Income &&
      this.employment.Income.length === 1 &&
      this.uiService.isCountry('New Zealand');
    if (isValidForPoppingIncome) {
      this.employment.deleteFirstIncomeForNZ();
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  addIncome(data = {}, isOnLoad = false) {
    const { TypeId, FrequencyID, Amount, Id } = data;
    const {
      SelectedClient,
      EmployerContactFirstName,
      EmployerContactLastName,
    } = this.employment;

    const isPAYG =
      this.uiService.isCountry('Australia') &&
      parseInt(this.employment.EmploymentTypeId, 10) ===
        parseInt(EMPLOYMENT_TYPE.PAYE, 10);
    const newIncomeType = isPAYG ? DEFAULT_INCOME_TYPES.SALARY : '';

    const setDefaultValueForNZ = (fieldType) => {
      const isNZUser = this.currentUserService.isNZ;
      switch (fieldType) {
        case 'frequencyType':
          if (isNZUser) {
            return DEFAULT_ASSETS.FORTNIGHTLY;
          }
          return this.getDefaultValuesAU().frequency || FrequencyID || '';
        case 'incomeType':
          if (isNZUser) {
            return DEFAULT_ASSETS.SALARY_WAGE;
          }
          return (
            this.getDefaultValuesAU().incomeType || TypeId || newIncomeType
          );
        case 'payslip':
          if (isNZUser) {
            return DEFAULT_ASSETS.PAYSLIPS;
          }
          return this.getDefaultValuesAU().verification || '';
        default:
          return null;
      }
    };

    const incomeDetail = {
      IsEvidenceOfTenancy: true,
      Id: Id || 0,
      FinancialId: 0,
      Type: '',
      TypeId: setDefaultValueForNZ('incomeType'),
      Description: '',
      DepositId: 0,
      Amount: Amount || 0,
      CurrencyCode: this.uiService.getCrmCurrency(),
      Category: 'string',
      CurrencyCodeId: this.uiService.getCrmCountryID(),
      FrequencyID: setDefaultValueForNZ('frequencyType'),
      FrequencyName: '',
      Provider: '',
      Borrowers: [
        {
          Percentage: 100,
          BorrowerID: SelectedClient,
          FirstName: EmployerContactFirstName,
          LastName: EmployerContactLastName,
          IsInclude: true,
          IsEntity: false,
        },
      ],
      IncomeVerification: [
        {
          IncomeVerificationTypeId: setDefaultValueForNZ('payslip'),
        },
      ],
      IsGross: true,
    };
    this.employment.newIncome(incomeDetail);
    this.focusLastIncomeType(isOnLoad);
  }

  incomeTypeFocus() {
    const elementSelector = '.income-types';
    const incomeTypes = this.$document.find(elementSelector);
    if (!incomeTypes || !incomeTypes.length) {
      return;
    }
    incomeTypes[incomeTypes.length - 1].focus();
  }

  focusLastIncomeType(isOnLoad) {
    if (isOnLoad) {
      return;
    }
    this.$timeout(() => {
      this.incomeTypeFocus();
    }, 200);
  }

  postEmployment(employmentSet) {
    const employementData = this.employmentModalUtilityInstance.appendFields(
      employmentSet,
    );
    if (!this.isLoanApp) {
      this.employmentModalUtilityInstance.clientEntityEmploymentSet(
        employementData,
      );
    } else {
      this.employmentModalUtilityInstance.setEmployment(employementData);
    }
  }

  isEmploymentTypePayOrSelfEmployed(employmentId) {
    return (
      employmentId === this.employmentType.PAYE ||
      employmentId === this.employmentType.SELF_EMPLOYED
    );
  }

  setEmploymentType({ employmentId }) {
    if (!employmentId) {
      this.isPhoneNumberRequired = true;
      return;
    }
    this.isPhoneNumberRequired = this.isEmploymentTypePayOrSelfEmployed(
      employmentId,
    );
    this.phoneErrorMessage = '';
  }

  validateNumberOnKeyUp({ phoneNumber }) {
    this.phoneErrorMessage = '';
    const isMobileRequiredAndValid =
      this.isInternationalPhoneEnabled &&
      !this.validatePhoneNumber(phoneNumber).isValid &&
      this.isPhoneNumberRequired;
    if (isMobileRequiredAndValid) {
      this.setErrorMessage(phoneNumber);
    }
  }

  validatePhoneNumber(phoneNumber) {
    return this.countryCodeService.countryInputSelectorValidation({
      element: this.countryCodeInputElement({
        angular: this.angular,
        elementId: 'employerNumberId',
      }),
      isPhoneRequired:
        this.isPhoneNumberRequired && !this.currentUserService.isNZ,
      message: 'Please provide a valid phone number.',
      phoneNumber,
      isLandline: true,
    });
  }

  setErrorMessage(employerNumber) {
    if (!employerNumber) {
      this.phoneErrorMessage = '';
    }
    this.phoneErrorMessage = this.validatePhoneNumber(employerNumber).message;
  }

  checkDateRangeValidity() {
    this.invalidDateRange = false;
    const isPreviousEmployment = this.employmentModalService.isPreviousEmployment(
      this.employment,
    );
    if (
      isPreviousEmployment &&
      isStartEndDateDropdownsInvalid(
        this.employment.DateStarted,
        this.employment.DateEnded,
      )
    ) {
      this.invalidDateRange = true;
      return false;
    }
    return true;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  saveEmployment(form) {
    const isValid = form.$valid;

    if (!employmentTypeHasAbn(this.employment.EmploymentTypeId)) {
      this.employment.ABN = null;
      this.employment.ACN = null;
      this.employment.abnError = null;
    }

    const {
      EmployerContactFirstName,
      EmployerPhoneCountryCode,
      EmployerContactLastName,
      EmployerContactTitle,
      EmploymentBasisName,
      EmploymentStatusId,
      EmploymentBasisId,
      EmploymentRollId,
      EmploymentTypeId,
      EmploymentRole,
      EmployerTypeId,
      EmployerNumber,
      EmployerName,
      EmploymentId,
      IsProbation,
      NumberOfYears,
      NumberOfMonths,
      DateStarted,
      DateEnded,
      IsCurrent,
      AddressId,
      ClientId,
      EntityId,
      FamilyId,
      Income,
      ACN,
      ABN,
      checkingABN,
      abnError,
    } = this.employment;

    this.isFormInvalid = !isValid;
    if (this.isInternationalPhoneEnabled) {
      this.isFormInvalid =
        !isValid ||
        (this.validatePhoneNumber(EmployerNumber) &&
          !this.validatePhoneNumber(EmployerNumber).isValid &&
          this.isEmploymentTypePayOrSelfEmployed(EmploymentTypeId));
    }

    if (this.isFormInvalid) {
      this.errorMessage = getFormErrorMessage(form);
      this.phoneErrorMessage = '';
      if (
        this.isInternationalPhoneEnabled &&
        !this.validatePhoneNumber(EmployerNumber).isValid
      ) {
        this.setErrorMessage(EmployerNumber);
        this.isSavingEmployment = false;
        return;
      }
      this.isSavingEmployment = false;
      this.checkDateRangeValidity();
      return;
    }

    if (checkingABN) {
      toastInfo('ABN validation is ongoing. Please wait for a moment.');
      this.isSavingEmployment = false;
      return;
    }

    if (abnError) {
      this.errorMessage = 'Please input valid ABN';
      this.isFormInvalid = true;
      this.isSavingEmployment = false;
      return;
    }

    if (!this.checkDateRangeValidity()) {
      return;
    }

    this.isSavingEmployment = true;

    forEach(this.employment.Income, (val) => {
      val.TypeId = parseInt(val.TypeId, 10);
      val.FrequencyID = parseInt(val.FrequencyID, 10);
      if (val.TypeId !== DEFAULT_INCOME_TYPES.SALARY) {
        val.IsGross = true;
      } else if (val.TypeId === DEFAULT_INCOME_TYPES.OTHER_NET_INCOME) {
        val.IsGross = false;
      }
    });

    Income &&
      Income.forEach((income) => {
        income.FamilyId = FamilyId;
      });

    const employmentSet = {
      DateEnded: this.employmentModalService.isPreviousEmployment(
        this.employment,
      )
        ? DateEnded
        : null,
      IsCurrent: this.isEmploymentStatusModsShown ? IsCurrent : null,
      NumberOfMonths: NumberOfMonths && parseInt(NumberOfMonths, 10),
      NumberOfYears: NumberOfYears && parseInt(NumberOfYears, 10),
      EmployerContactFirstName,
      EmployerPhoneCountryCode,
      EmployerContactLastName,
      EmployerContactTitle,
      EmploymentBasisName,
      EmploymentStatusId,
      EmploymentBasisId,
      EmploymentTypeId,
      EmploymentRollId,
      EmploymentRole,
      EmployerTypeId,
      EmployerNumber,
      EmployerName,
      EmploymentId,
      IsProbation,
      DateStarted,
      AddressId,
      ClientId,
      EntityId,
      Income,
      ACN,
      ABN,
    };

    const isValidEmploymentAddress =
      this.employment &&
      this.employment.Address &&
      !this.employment.Address.formatted_address;
    if (isValidEmploymentAddress) {
      this.postEmployment(employmentSet);
    } else {
      const addressId = 0;
      const isMailing = false;
      const addressValuationExtendedDetailsId = 0;
      const addressDetails = this.employmentModalService.buildEmploymentAddrSetData(
        this.employment.Address,
        addressId,
        isMailing,
        this.employment.FamilyId,
        addressValuationExtendedDetailsId,
      );
      this.dashboardService
        .addressDetailsSet(addressDetails)
        .then((postAddressResponse) => {
          if (!postAddressResponse) {
            return;
          }
          employmentSet.AddressId = postAddressResponse.data;
          this.postEmployment(employmentSet);
        })
        .catch(toastError);
    }
  }

  onLoadIncome() {
    const isOnLoad = true;
    if (!this.newIncomeDetails && this.action === 'Add') {
      this.addIncome({}, isOnLoad);
    } else if (this.newIncomeDetails && this.isFromIncome) {
      const { TypeId, FrequencyID, Amount, Id } = this.newIncomeDetails;
      const incomeData = {
        TypeId: parseInt(TypeId, 10),
        FrequencyID,
        Amount,
        Id,
      };
      this.addIncome(incomeData, isOnLoad);
    }
  }

  prepareEmploymentEditData(employmentSet) {
    employmentSet.DateStarted = employmentSet.DateStarted
      ? employmentSet.DateStarted
      : null;
    employmentSet.DateEnded = employmentSet.DateEnded
      ? employmentSet.DateEnded
      : null;

    employmentSet.Address = { formatted_address: employmentSet.Address ?? '' };

    getEmploymentDays(this.employment, employmentSet);
    this.employment.setEmploymentData(employmentSet);
    this.onLoadIncome();
  }

  getEmployment() {
    if (!this.isLoanApp) {
      this.contactService
        .clientEntityEmploymentGet(this.familyId, this.employmentId)
        .then((response) => {
          const { data } = response;
          const isValidEmployment = data && data.length;
          if (!isValidEmployment) {
            return;
          }
          this.setEmploymentType({ employmentId: data[0].EmploymentTypeId });
          this.addedDetails = { abn: data[0].ABN };
          const employmentSet = data[0];
          this.prepareEmploymentEditData(
            this.employmentModalUtilityInstance.getEmploymentappendFields(
              employmentSet,
            ),
          );
        });
    } else {
      this.loanApplicationServices
        .getEmployment(this.loanAppId, this.employmentId)
        .then((response) => {
          const { data } = response;
          const isValidEmployment =
            data &&
            data.length &&
            data[0].Employment &&
            data[0].Employment.length;
          if (!isValidEmployment) {
            return;
          }
          this.setEmploymentType({ employmentId: data[0].EmploymentTypeId });
          const employmentSet = data[0].Employment[0];
          this.addedDetails = { abn: employmentSet.ABN };
          this.prepareEmploymentEditData(
            this.employmentModalUtilityInstance.getEmploymentappendFields(
              employmentSet,
            ),
          );
        });
    }
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  $onInit() {
    if (!this.action) {
      this.action = 'Add';
    }
    this.isLoanApp = this.familyId && this.loanAppId;
    this.isSavingEmployment = false;
    this.isFormInvalid = true;
    this.errorMessage = 'Please check required fields';

    this.employment = new EmploymentData();

    this.employment.openDate = (dateField = '') => {
      this.employment.datePopUp[dateField] = !this.employment.datePopUp[
        dateField
      ];
    };

    this.employment.setEmploymentData = (data = []) => {
      Object.keys(data).forEach((key) => {
        this.employment[key] = data[key];
      });
    };

    this.employment.setSelectedClient = (ClientId = 0) => {
      this.employment.setEmploymentData({ ClientId });
    };

    this.employment.newIncome = (data = {}) => {
      this.employment.Income.push(data);
    };

    this.employment.deleteFirstIncomeForNZ = () => {
      this.employment.Income.pop();
    };

    this.employment.setSelectedClient(this.clientId);
    this.setEmploymentType({ employmentId: this.employment.EmploymentTypeId });
    this.isEmploymentStatusModsShown = this.employmentService.isCurrentEmploymentStatusShown();
    this.isShowEmployerType = this.employmentModalService.isEmployerTypeShown();

    if (this.employmentId) {
      this.getEmployment();
    } else {
      this.onLoadIncome();
    }

    this.initInternationalPhoneFlags();

    if (this.isInternationalPhoneEnabled && !this.employment.CountryCode) {
      this.initializeCountryCode(this.configService.isAU);
    }
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('EmploymentModalCtrl');
  }
}

export default EmploymentModalCtrl;
