import template from './mycrmDateSpan.html';
import controller from './mycrmDateSpanCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    label: '<',
    dateObj: '<',
    onDateSpanChange: '&',
  },
};
