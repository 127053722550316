import get from 'lodash/get';
import { INVOLVED_PARTIES_TYPE } from 'Common/constants/partyTypes';
import { mapEnquiryTitleField } from 'Common/utilities/referral';

class ReferralExistingClientSearchCtrl {
  constructor($q, $timeout, loanProfilerService) {
    'ngInject';

    this.$q = $q;
    this.$timeout = $timeout;
    this.loanProfilerService = loanProfilerService;
    this.clientSearchApi = this.clientSearchApi.bind(this);
    this.onSelectClient = this.onSelectClient.bind(this);
  }

  onSelectClient(clientObj = {}) {
    const client = {
      ReferrerClientId: get(clientObj, 'originalObject.ClientEntityId', 0),
      ClientReferrerFamilyId: get(clientObj, 'originalObject.FamilyID', 0),
    };
    this.onChange({ client });
  }

  clientSearchApi(queryString) {
    if (!queryString) {
      return Promise.resolve([]);
    }
    return this.$q
      .when(
        this.loanProfilerService.getSearchClient(
          queryString,
          INVOLVED_PARTIES_TYPE.CLIENT,
        ),
      )
      .then(mapEnquiryTitleField);
  }
}

export default ReferralExistingClientSearchCtrl;
