export const ENTITY_PARTY_TYPES = {
  TRUST: 1,
  COMPANY: 2,
  PARTNERSHIP: 7,
};

export const PERSON_PARTY_TYPES = {
  APPLICANT: 1,
  GUARANTOR: 3,
  DEPENDENT: 4,
  SOLICITOR: 5,
  ACCOUNTANT: 6,
  ASSET_SUPPLIER: 7,
  SOLE_TRADER: 8,
  FINANCIAL_PLANNER: 9,
  REAL_ESTATE_AGENT: 10,
};

export const INVOLVED_PARTIES_TYPE = {
  ENTITY: 1,
  CLIENT_ENTITY: 2,
  CLIENT: 3,
};

export const CLIENT_PARTY_TYPES = {
  COMPANY: 3,
};

export const BORROWER_PARTY_TYPES = {
  PERSON_BUSINESS: 2,
  PERSON: 3,
};

export const COMPANY_TYPES = {
  COMPANY: 1,
  TRUST: 2,
  PARTNERSHIP: 3,
  SMSF: 4,
  COMPANY_STRING: 'Company',
  TRUST_STRING: 'Trust',
  PARTNERSHIP_STRING: 'Partnership',
  SMSF_STRING: 'SMSF',
};

export const CONTACT_SEARCH_PARTY_TYPES = {
  TRUST_COMPANIES: 1,
  PROFESSIONAL: 3,
};

export default {
  ENTITY_PARTY_TYPES,
  PERSON_PARTY_TYPES,
  CLIENT_PARTY_TYPES,
  BORROWER_PARTY_TYPES,
  INVOLVED_PARTIES_TYPE,
  COMPANY_TYPES,
  CONTACT_SEARCH_PARTY_TYPES,
};

export const TRUST_TYPES = [
  { value: COMPANY_TYPES.COMPANY, label: COMPANY_TYPES.COMPANY_STRING },
  { value: COMPANY_TYPES.TRUST, label: COMPANY_TYPES.TRUST_STRING },
];

export const PROFESSIONAL_PARTY_TYPES = [
  PERSON_PARTY_TYPES.SOLICITOR,
  PERSON_PARTY_TYPES.ACCOUNTANT,
  PERSON_PARTY_TYPES.FINANCIAL_PLANNER,
  PERSON_PARTY_TYPES.REAL_ESTATE_AGENT,
];

export const PARTY_TYPES_STRING = {
  APPLICANT: 'Applicant',
  GUARANTOR: 'Guarantor',
  SOLICITOR: 'Solicitor',
  ACCOUNTANT: 'Accountant',
  FINANCIAL_PLANNER: 'Financial Planner',
  REAL_ESTATE_AGENT: 'Real Estate Agent',
};

export const PARTY_TYPES_LIST = [
  {
    id: PERSON_PARTY_TYPES.APPLICANT,
    name: PARTY_TYPES_STRING.APPLICANT,
    field: 'IsApplicant',
  },
  {
    id: PERSON_PARTY_TYPES.GUARANTOR,
    name: PARTY_TYPES_STRING.GUARANTOR,
    field: 'IsGuarantor',
  },
  {
    id: PERSON_PARTY_TYPES.SOLICITOR,
    name: PARTY_TYPES_STRING.SOLICITOR,
    field: 'IsSolicitor',
  },
  {
    id: PERSON_PARTY_TYPES.ACCOUNTANT,
    name: PARTY_TYPES_STRING.ACCOUNTANT,
    field: 'IsAccountant',
  },
  {
    id: PERSON_PARTY_TYPES.FINANCIAL_PLANNER,
    name: PARTY_TYPES_STRING.FINANCIAL_PLANNER,
  },
  {
    id: PERSON_PARTY_TYPES.REAL_ESTATE_AGENT,
    name: PARTY_TYPES_STRING.REAL_ESTATE_AGENT,
  },
];

export const PARTY_GROUPING = {
  APPLICANT_CLIENT: 1,
  APPLICANT_ENTITY: 2,
  GUARANTOR_CLIENT: 3,
  GUARANTOR_ENTITY: 4,
  SOLICITOR: 5,
  ACCOUNTANT: 6,
};

export const CONTACT_CLIENT_TYPE_ID = {
  PERSON: 1,
  ENTITY: 2,
};
