import {
  ENTITY_PARTY_TYPES,
  PERSON_PARTY_TYPES,
  PARTY_TYPES_STRING,
} from 'Common/constants/partyTypes';

class PartyTypeService {
  getPartyType(typeId) {
    this.partyType = '';
    if (typeId) {
      const id = parseInt(typeId, 10);
      switch (id) {
        case ENTITY_PARTY_TYPES.COMPANY:
          this.partyType = 'Company';
          break;
        case ENTITY_PARTY_TYPES.PARTNERSHIP:
          this.partyType = 'Partnership';
          break;
        case ENTITY_PARTY_TYPES.TRUST:
          this.partyType = 'Trust';
          break;
        case PERSON_PARTY_TYPES.GUARANTOR:
          this.partyType = 'Guarantor';
          break;
        case PERSON_PARTY_TYPES.DEPENDENT:
          this.partyType = 'Dependant';
          break;
        case PERSON_PARTY_TYPES.SOLICITOR:
          this.partyType = 'Solicitor';
          break;
        case PERSON_PARTY_TYPES.ACCOUNTANT:
          this.partyType = 'Accountant';
          break;
        case PERSON_PARTY_TYPES.FINANCIAL_PLANNER:
          this.partyType = PARTY_TYPES_STRING.FINANCIAL_PLANNER;
          break;
        case PERSON_PARTY_TYPES.REAL_ESTATE_AGENT:
          this.partyType = PARTY_TYPES_STRING.REAL_ESTATE_AGENT;
          break;
        default:
          break;
      }
    }
    return this.partyType;
  }
}

export default PartyTypeService;
