import { cloneDeep, get } from 'lodash';
import { toastError } from 'Common/utilities/alert';
import { CLIENT_ROLE_TYPE } from 'Common/constants/roleType';

export function stripPhoneFromContactSetPayload(contacts) {
  return contacts.map((contact) => {
    const newContact = cloneDeep(contact);
    newContact.Phone && delete newContact.Phone;

    return newContact;
  });
}

export function getPhoneSavePayload({ contact, familyId = 0, clientId }) {
  const phoneNumbers = (contact && contact.Phone) || [];
  const newPhone = {
    familyId,
    clientId,
  };

  phoneNumbers.forEach((phone) => {
    const type = (phone.Type && phone.Type.toLowerCase()) || '';
    newPhone[type] = {
      number: phone.Number,
      countryCode: phone.CountryCode,
    };
  });

  return newPhone;
}

export const saveStandAloneClientContactNumber = ({
  familyId,
  contacts,
  contactService,
  contactModelService,
  successAddContactCallback = () => {},
}) => {
  return contactService
    .clientInformGet(familyId)
    .then(({ data }) => {
      const clientsWithPhone =
        data &&
        data.map((client, index) => {
          client.Phone = get(contacts, `${index}.Phone`, []);
          return client;
        });
      const requestPromises = clientsWithPhone.reduce((accum, contact) => {
        if (contact.Role !== CLIENT_ROLE_TYPE.ADULT) {
          return accum;
        }
        const phonePayload = getPhoneSavePayload({
          contact,
          familyId,
          clientId: contact.PersonId,
        });
        return accum.concat(
          contactModelService.setClientContactNumber(phonePayload),
        );
      }, []);
      return Promise.all(requestPromises).then((response) => {
        const isInvalid = !!get(response, '0.Message', false);
        if (isInvalid) {
          toastError();
          return;
        }
        return successAddContactCallback();
      });
    })
    .catch(() => toastError());
};

export const saveStandAloneEntityContactNumber = ({
  contact,
  familyId,
  contactModelService,
  successAddContactCallback = () => {},
  isAdd = false,
}) => {
  if (typeof contact.Phone === 'undefined') {
    successAddContactCallback();
    return Promise.resolve();
  }
  const [workPhone] = contact.Phone;
  if (isAdd && !workPhone.Number) {
    successAddContactCallback();
    return Promise.resolve();
  }

  const phonePayload = getPhoneSavePayload({
    contact,
    familyId,
  });

  return contactModelService
    .setEntityContactNumber(phonePayload)
    .then((data) => {
      if (!data) {
        toastError();
        return;
      }
      successAddContactCallback();
    })
    .catch(() => toastError());
};
