class MycrmLookupService {
  constructor(
    generalService,
    currentUserService,
    configService,
    contactModelService,
  ) {
    'ngInject';

    this.generalService = generalService;
    this.currentUserService = currentUserService;
    this.configService = configService;
    this.contactModelService = contactModelService;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getAdvisers(args = {}) {
    const familyId = args.familyId || this.currentUserService.familyId;
    const clientFamilyId = this.currentUserService.hasAdviserAccess
      ? args.clientFamilyId || 0
      : 0;
    const searchSameOrgDefault = this.currentUserService.isCorporate ? 0 : 1;
    const searchSameAdviserOrg =
      args.searchSameAdviserOrg == null
        ? searchSameOrgDefault
        : args.searchSameAdviserOrg;
    const loanWritersOnly =
      typeof args.loanWritersOnly !== 'undefined' ? args.loanWritersOnly : true;
    const includeRiskWriters =
      typeof args.includeRiskWriters !== 'undefined'
        ? args.includeRiskWriters
        : true;
    const customerCareTypeId = args.customerCareTypeId || 0;
    const includeInactive = args.includeInactive || false;
    const params = {
      familyId,
      clientFamilyId,
      searchSameAdviserOrg,
      loanWritersOnly,
      includeRiskWriters,
      customerCareTypeId,
      includeInactive,
      isRemoveFilteringCustomerCare:
        this.configService.feature.unlockingCustomerCare ||
        (this.currentUserService.isAssistantType &&
          this.configService.feature.unlockingCustomerCare),
    };
    return this.generalService.getAdviserList(params).then((data) => {
      const allAdvisers = {
        familyId: args.allAdviserValue || 0,
        fullName: args.allAdviserLabel || 'All Advisers',
        ticked: (!args.selectCurrentUser && args.allAdviserTicked) || false,
        brandingCategoryId: 0,
      };
      const advisers =
        (data && data.length && data.filter((adviser) => adviser.fullName)) ||
        [];
      if (args.selectCurrentUser) {
        advisers.map((adviser) => {
          adviser.ticked =
            adviser.familyId === this.currentUserService.familyId;
          return adviser;
        });
      }
      const showAllOption =
        (args.allAdviserLabel && advisers.length > 1) ||
        args.alwaysShowAllOption;
      return showAllOption ? [allAdvisers, ...advisers] : advisers;
    });
  }

  getAdviserListByLoanId({ loanId }) {
    return this.contactModelService
      .getAdviserListByLoanId(loanId)
      .then((data) => {
        return data;
      });
  }
}

export default MycrmLookupService;
