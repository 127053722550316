export const LMI_PREDOMINANT_PURPOSE = {
  PURCHASE_OR_CONSTRUCTION: 'PurchaseOrConstruction',
  HOME_IMPROVEMENTS: 'Renovation',
  INTERNAL_REFINANCE: 'InternalRefinance',
  EXTERNAL_REFINANCE: 'DebtConsolidation',
  EQUITY_RELEASE: 'EquityRelease',
};

export const WARNING_LIMIT = 95;
export const LMI_NAME = 'Lenders Mortgage Insurance';
