import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .factory('wmComplianceModel', function wmComplianceModel(httpRequest) {
      var apiBaseUrl = 'wmcompliance/';
      function wmComplianceModel() {}

      wmComplianceModel.prototype = {
        AppointmentDetailGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}AppointmentDetailGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        AppointmentDetailSet(
          familyID,
          appointedAsId,
          splitsId,
          authLevelId,
          citizenshipStatusId,
          recruitmentSource,
          adviserAdviseOSUserName,
          authorizationTypeId,
          AFSL,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}AppointmentDetailSet?familyID=${familyID}&appointedAsId=${appointedAsId}&splitsId=${splitsId}&authLevelId=${authLevelId}&citizenshipStatusId=${citizenshipStatusId}&recruitmentSource=${recruitmentSource}&adviserAdviseOSUserName=${adviserAdviseOSUserName}&authorizationTypeId=${authorizationTypeId}&AFSL=${AFSL}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },
        AppointedAsGet(callback) {
          httpRequest.get(`${apiBaseUrl}AppointedAsGet`, (response) => {
            return callback(response);
          });
        },
        AuthorizationTypeGet(callback) {
          httpRequest.get(`${apiBaseUrl}AuthorizationTypeGet`, (response) => {
            return callback(response);
          });
        },
        CitizenshipStatusGet(callback) {
          httpRequest.get(`${apiBaseUrl}CitizenshipStatusGet`, (response) => {
            return callback(response);
          });
        },
        EmploymentDetailGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}EmploymentDetailGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        EmploymentDetailSet(
          familyID,
          employmentRoleId,
          employmentStartDate,
          employmentEndDate,
          resignationDate,
          reasonForLeaving,
          reHire,
          educationProvider,
          highestLevelCompleted,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}EmploymentDetailSet?familyID=${familyID}&employmentRoleId=${employmentRoleId}&employmentStartDate=${employmentStartDate}&employmentEndDate=${employmentEndDate}&resignationDate=${resignationDate}&reasonForLeaving=${reasonForLeaving}&reHire=${reHire}&educationProvider=${educationProvider}&highestLevelCompleted=${highestLevelCompleted}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },
        EmploymentRoleGet(callback) {
          httpRequest.get(`${apiBaseUrl}EmploymentRoleGet`, (response) => {
            return callback(response);
          });
        },
        EducationDetailGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}EducationDetailGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        EducationDetailSet(
          familyID,
          hscRrEquivalent,
          relatedDegree,
          unRelatedDegree,
          details,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}EducationDetailSet?familyID=${familyID}&HSC_or_Equivalent=${hscRrEquivalent}&relatedDegree=${relatedDegree}&unRelatedDegree=${unRelatedDegree}&details=${details}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },
        QualificationDetailsGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}QualificationDetailsGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        QualificationDetailsSet(
          familyID,
          pWMQualificationIDs,
          pSpecialistCourseID,
          otherQualifications,
          pWMAdviceTypeIDs,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}QualificationDetailsSet?familyID=${familyID}&pWMQualificationIDs=${pWMQualificationIDs}&pSpecialistCourseID=${pSpecialistCourseID}&otherQualifications=${otherQualifications}&pWMAdviceTypeIDs=${pWMAdviceTypeIDs}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },

        QualificationsGet(callback) {
          httpRequest.get(`${apiBaseUrl}QualificationsGet`, (response) => {
            return callback(response);
          });
        },
        SpecialistCourseGet(callback) {
          httpRequest.get(`${apiBaseUrl}SpecialistCourseGet`, (response) => {
            return callback(response);
          });
        },
        AdviceTypeGet(callback) {
          httpRequest.get(`${apiBaseUrl}AdviceTypeGet`, (response) => {
            return callback(response);
          });
        },

        RecentAuditGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}RecentAuditGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        RecentAuditResultSet(
          familyID,
          auditRating,
          recommendedForPreVet,
          noSOAsToBeReviewed,
          SOAsReviewed,
          remediationRequired,
          remediationRequiredDate,
          recommendedForParaPlanning,
          paraPlanningStartDate,
          paraPlanningEndDate,
          last5YearsAdviserHistory,
          auditDate,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}RecentAuditResultSet?familyID=${familyID}&auditRating=${auditRating}&recommendedForPreVet=${recommendedForPreVet}&noSOAsToBeReviewed=${noSOAsToBeReviewed}&SOAsReviewed=${SOAsReviewed}&remediationRequired=${remediationRequired}&remediationRequiredDate=${remediationRequiredDate}&recommendedForParaPlanning=${recommendedForParaPlanning}&paraPlanningStartDate=${paraPlanningStartDate}&paraPlanningEndDate=${paraPlanningEndDate}&last5YearsAdviserHistory=${last5YearsAdviserHistory}&auditDate=${auditDate}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },

        StdPreVetGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}StdPreVetGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        StdPreVetSet(
          familyID,
          newAdviser,
          noStdSOAsToBeReviewed,
          preVetStatus,
          preVetComments,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}StdPreVetSet?familyID=${familyID}&newAdviser=${newAdviser}&noStdSOAsToBeReviewed=${noStdSOAsToBeReviewed}&preVetStatus=${preVetStatus}&preVetComments=${preVetComments}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },

        SpecialistAdviceSRGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}SpecialistAdviceSRGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        SpecialistAdviceSRSet(
          familyID,
          ETF_SR,
          directEquitySR,
          marginLendingSR,
          SMSF_SR,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}SpecialistAdviceSRSet?familyID=${familyID}&ETF_SR=${ETF_SR}&directEquity_SR=${directEquitySR}&marginLending_SR=${marginLendingSR}&SMSF_SR=${SMSF_SR}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },
        GeneralGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}GeneralGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        GeneralSet(
          familyID,
          industryMemberships,
          legacyBlanketApprovals,
          morningStarSubscription,
          lonsecResearchSubs,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}GeneralSet?familyID=${familyID}&industryMemberships=${industryMemberships}&legacyBlanketApprovals=${legacyBlanketApprovals}&morningStarSubscription=${morningStarSubscription}&lonsecResearchSubs=${lonsecResearchSubs}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },
      };

      return wmComplianceModel;
    });
