export default class SubmitEnhancementsCtrl {
  constructor(currentUserService) {
    'ngInject';

    !!currentUserService.cannyToken &&
      (() => {
        this.cannyToken = currentUserService.cannyToken;
      })();
  }
}
