import { TASK_OPPORTUNITY_TABLE_COLUMNS } from 'Common/constants/tasks';

export default class OpportunityTasksCtrl {
  constructor($uibModal, configService) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.configService = configService;

    this.childComponentNotificationRegistration = this.childComponentNotificationRegistration.bind(
      this,
    );
  }

  $onInit() {
    this.showRedesignedTasks = this.configService.feature.tasksRedesign;
    this.tableColumns = TASK_OPPORTUNITY_TABLE_COLUMNS;

    this.childComponentEventHandler = { taskEventHandler: null };
  }

  onTaskModalResult(modalInstance) {
    modalInstance.result.then((isSaved) => {
      if (isSaved) {
        this.childComponentEventHandler.taskEventHandler &&
          this.childComponentEventHandler.taskEventHandler('reloadTasksList');
      }
    });
  }

  onOpenTaskModal({ data }) {
    const { taskId } = data;
    const props = {
      familyId: this.familyId,
      familyList: this.familyList,
      loanId: this.loanId,
      taskId,
      fileName: this.opportunityTitle,
      isLinkToOpportunity: false,
      selectedOpportunityId: this.opportunityId,
      opportunityList: [
        { opportunityId: this.opportunityId, title: this.opportunityTitle },
      ],
    };

    const modalInstance = this.$uibModal.open({
      template: `<task-form-modal
        modal-instance="vm.modalInstance"
        family-id="vm.props.familyId"
        family-list="vm.props.familyList"
        loan-id="vm.props.loanId"
        task-id="vm.props.taskId"
        file-name="vm.props.fileName"
        is-link-to-opportunity="vm.props.isLinkToOpportunity"
        selected-opportunity-id="vm.props.selectedOpportunityId"
        opportunity-list="vm.props.opportunityList"
      ></task-form-modal>`,
      windowClass: 'edit-task-window',
      size: 'md',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props: () => props,
      },
    });
    this.onTaskModalResult(modalInstance);
  }

  onOpenTemplatedTaskModal({ data }) {
    const { taskId = 0 } = data;
    const propsCopy = {
      familyId: this.familyId,
      loanId: this.loanId,
      taskId,
      familyList: this.familyList,
      quoteId: this.quoteId,
      fileName: this.opportunityTitle,
      selectedOpportunityId: this.opportunityId,
    };
    const modalInstance = this.$uibModal.open({
      template: `<task-templated modal-instance="vm.modalInstance" quote-id="vm.props.quoteId" family-id="vm.props.familyId" family-list="vm.props.familyList" loan-id="vm.props.loanId" task-id="vm.props.taskId" selected-opportunity-id="vm.props.selectedOpportunityId" file-name="vm.props.fileName"></task-templated>`,
      size: 'lg',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      windowClass: 'view-task-window',
      resolve: {
        props: () => propsCopy,
      },
    });
    this.onTaskModalResult(modalInstance);
  }

  childComponentNotificationRegistration(handler, handlerType) {
    this.childComponentEventHandler[handlerType] = handler;
  }
}
