class LeadStatusCtrl {
  constructor(corporateService) {
    'ngInject';

    this.corporateService = corporateService;
  }

  getLeadStatusList() {
    this.corporateService.leadsReceiveStatusDropdownGet().then((response) => {
      const { data } = response;
      if (!data || !data.length) {
        return;
      }
      const currentStatus = data.find(
        (x) => x.LeadReceiveStatusId === this.statusId,
      );
      if (!currentStatus || !currentStatus.LeadReceiveStatus) {
        return;
      }
      this.leadStatus.statusName = currentStatus.LeadReceiveStatus;
      this.leadStatus.statusClass = currentStatus.LeadReceiveStatus.toLowerCase().replace(
        / /g,
        '-',
      );
    });
  }

  setPopover(value) {
    this.isPopoverOpen = value;
  }

  $onInit() {
    this.isPopoverOpen = false;
    this.leadStatus = {
      statusName: 'None',
      statusClass: 'temporarily-inactive',
    };
    this.getLeadStatusList();
  }
}

export default LeadStatusCtrl;
