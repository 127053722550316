import template from './contactFilesOption.html';
import controller from './contactFilesOptionCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    value: '<',
    familyId: '<',
    feature: '@',
    selectType: '@',
    onSelectorChange: '&',
  },
};
