import template from './noteTypeBadge.html';
import controller from './noteTypeBadgeCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    noteType: '<',
  },
};
