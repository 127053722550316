export const contactFamilyInfoGet = ({ contactService, familyId }) => {
  return contactService.familyInfoGet(familyId, undefined, true);
};

export const assignBGsUtil = ({ clientBGs, colorService, clientId }) => {
  if (!clientBGs[clientId]) {
    clientBGs[clientId] = colorService.getRandomColor();
  }
  return clientBGs[clientId];
};

export const setContactInitialAndBackgroundUtil = (row, utilitiesService) => {
  if (!row || !utilitiesService) {
    return;
  }
  const filterInitialMethod = row.ClientLastName
    ? 'filterInitial'
    : 'filterInitialOneString';
  const filterParams = row.ClientLastName
    ? [row.ClientFirstName, row.ClientLastName]
    : [row.ClientFirstName];
  row.initials = utilitiesService[filterInitialMethod](...filterParams);

  return row;
};
