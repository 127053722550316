import { showBusinessPlanningDashboard } from 'Common/utilities/showFeature';
import {
  DASHBOARD_TAB_INDEX,
  DEFAULT_DASHBOARD_TAB,
  DASHBOARD_TAB_URL,
} from 'Common/constants/businessPlanningDashboard';
import {
  mapSelectedItem,
  mapOptionAll,
  getSelectionCSV,
} from 'Common/utilities/dashboardWidget';
import { textLimit } from 'Common/utilities/string';
import { ALL_VALUE, OPTION_ALL } from 'Common/constants/dashboardWidget';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

export default class DashboardContainerCtrl {
  constructor(
    $state,
    currentUserService,
    configService,
    stateService,
    contactSharedData,
    optionsService,
    dashboardService,
  ) {
    'ngInject';

    this.$state = $state;
    this.currentUserService = currentUserService;
    this.configService = configService;
    this.stateService = stateService;
    this.contactSharedData = contactSharedData;
    this.optionsService = optionsService;
    this.dashboardService = dashboardService;
    this.childComponentNotificationRegistration = this.childComponentNotificationRegistration.bind(
      this,
    );
  }

  $onInit() {
    this.memberList = [];
    this.childComponentEventHandler = {
      yourStatsEventHandler: null,
      activeFilesEventHandler: null,
      yourTargetsEventHandler: null,
    };
    this.checkFeatureFlag();
    this.getActiveTab(this.$state.params.activeTab || DEFAULT_DASHBOARD_TAB);
    this.initMemberFilter();
    this.getMemberList();
  }

  childComponentNotificationRegistration(handler, handlerType) {
    this.childComponentEventHandler[handlerType] = handler;
  }

  initYourStats(selectedUserValues) {
    this.childComponentEventHandler.yourStatsEventHandler &&
      this.childComponentEventHandler.yourStatsEventHandler(
        'initYourStats',
        selectedUserValues,
      );
  }

  initYourTargets() {
    this.childComponentEventHandler.yourTargetsEventHandler &&
      this.childComponentEventHandler.yourTargetsEventHandler(
        'initYourTargets',
      );
  }

  initActiveFiles() {
    this.childComponentEventHandler.activeFilesEventHandler &&
      this.childComponentEventHandler.activeFilesEventHandler(
        'initActiveFiles',
      );
  }

  initMemberFilter() {
    const hasAccessOnFilter = [
      ACCESS_TYPE.PRINCIPAL_ADVISER,
      ACCESS_TYPE.ADMIN_ASSISTANT,
      ACCESS_TYPE.ASSISTANT,
    ];
    const canViewFilter = [...hasAccessOnFilter, ACCESS_TYPE.ADVISER];

    this.showMemberFilter = canViewFilter.includes(
      this.currentUserService.accessType,
    );
    this.allowMemberFilter = hasAccessOnFilter.includes(
      this.currentUserService.accessType,
    );
  }

  getActiveTab(activeTab) {
    if (!activeTab) {
      return;
    }
    this.active = this.contactSharedData.getUsersActiveIndexTab(
      activeTab,
      DASHBOARD_TAB_INDEX,
    );
    this.stateTransition(activeTab);
  }

  stateTransition(tab) {
    const { activeTab } = this.$state.params;
    if (!tab || activeTab === tab) {
      return;
    }
    this.$state.params.activeTab = tab;
    this.stateService.transition(this.$state.current, this.$state.params);
    this.initChildTab(tab);
  }

  initChildTab(tab, selectedUserValues) {
    switch (tab) {
      case DASHBOARD_TAB_URL.YOUR_STATS:
        this.initYourStats(selectedUserValues);
        break;
      case DASHBOARD_TAB_URL.ACTIVE_FILES:
        this.initActiveFiles();
        break;
      case DASHBOARD_TAB_URL.YOUR_TARGETS:
        this.initYourTargets();
        break;
      default:
    }
  }

  onClickTab() {
    const selectedTabName =
      Object.keys(DASHBOARD_TAB_INDEX).find(
        (key) => DASHBOARD_TAB_INDEX[key] === this.active,
      ) || DEFAULT_DASHBOARD_TAB;
    this.stateTransition(selectedTabName);
  }

  checkFeatureFlag() {
    const { businessPlanningDashboard } = this.configService.feature;
    this.showBusinessPlanningDashboard = showBusinessPlanningDashboard(
      businessPlanningDashboard,
      this.currentUserService,
    );
    !this.showBusinessPlanningDashboard && this.$state.go('app.dashboard');
  }

  getMemberList() {
    const { isAssistant, isAdminAssistant } = this.currentUserService;
    const isLoanWriterOnly = isAssistant || isAdminAssistant;

    this.optionsService.getAdvisers(isLoanWriterOnly, true).then((data) => {
      if (!data || !data.length) {
        return;
      }
      this.structureMemberList(data);
    });
  }

  structureMemberList(data) {
    const { fullName, familyId } = this.currentUserService;
    const currentUser = { name: fullName, value: familyId, ticked: true };
    const allMemberSelector = { ...OPTION_ALL.MEMBERS, ticked: true };
    const rawMemberList = this.allowMemberFilter
      ? [allMemberSelector, ...data]
      : [currentUser];
    this.memberList = rawMemberList.map((item) => ({
      ...item,
      shortName: textLimit(item.name, 22),
    }));
  }

  selectAll(varName) {
    this[varName] = mapOptionAll(this[varName]);
    this.updateWidgetFilter(varName, true);
  }

  onItemSelect(item, varName, selection) {
    if (item.value === ALL_VALUE || !selection.length) {
      this.selectAll(varName, this[varName]);
      return;
    }

    this[varName] = mapSelectedItem(this[varName]);
    this.updateWidgetFilter(varName);
  }

  updateWidgetFilter(varName) {
    const selectedUserValues = getSelectionCSV(this[varName]);
    const params = { selectedUserValues };
    this.dashboardService.setRecommendedFilter(params).then(() => {
      this.initChildTab(this.$state.params.activeTab, selectedUserValues);
    });
  }
}
