import {
  DEFAULT_INCOME_TYPES,
  RENTAL_SUB_INCOME_TYPES,
} from 'Common/constants/incomeTypes';
import { PRIMARY_PURPOSE_VALUE } from 'Common/constants/securityValues';

export function isEmploymentIncome(incomeId) {
  const defaultEmploymentIncome = [
    DEFAULT_INCOME_TYPES.SALARY,
    DEFAULT_INCOME_TYPES.BONUS,
    DEFAULT_INCOME_TYPES.COMMISSION,
    DEFAULT_INCOME_TYPES.FOREIGN,
  ];
  return defaultEmploymentIncome.includes(parseInt(incomeId, 10));
}

export function checkIfRemoveIncome(params) {
  const { object, isNZ, incomeInfoDeleteFunction } = params;

  if (!object || !incomeInfoDeleteFunction) {
    return;
  }
  const { incomeList, primaryPurposeId, loanAppId } = object;
  const isRemoveIncome =
    primaryPurposeId === PRIMARY_PURPOSE_VALUE.OWNER_OCCUPIED &&
    incomeList.length &&
    !isNZ;
  if (!isRemoveIncome) {
    return false;
  }

  incomeList.forEach((income) => {
    incomeInfoDeleteFunction(loanAppId, income.Id);
  });
  return true;
}

export const isRentalSubIncome = (rentalTypeId) => {
  return RENTAL_SUB_INCOME_TYPES.includes(rentalTypeId);
};

export const getIsGross = ({ isGross, typeId = 0, incomeSharedService }) => {
  switch (typeId) {
    case DEFAULT_INCOME_TYPES.SALARY:
      return incomeSharedService.incomeServices.convertNetGross(isGross, true);
    case DEFAULT_INCOME_TYPES.OTHER_NET_INCOME:
      return false;
    default:
      return true;
  }
};
