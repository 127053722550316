export default class NoteTypeBadgeCtrl {
  constructor(configService) {
    'ngInject';

    this.configService = configService;
  }

  $onInit() {
    this.displayNoteType = this.configService.feature.noteType && this.noteType;
  }
}
