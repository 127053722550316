import { toastError } from 'Common/utilities/alert';
import { showSwalAfterSending } from 'Common/utilities/loanOpportunity';
import { sendEmailBuilderForMyCRM } from 'Common/mappers/loanOpportunity';

export default class OpportunitySendEmailModalCtrl {
  constructor(loanOpportunityService, currentUserService, contactService) {
    'ngInject';

    this.loanOpportunityService = loanOpportunityService;
    this.contactService = contactService;
    this.currentUserService = currentUserService;
  }

  changeEmailObj(emailModel) {
    this.email = {
      ...emailModel,
    };
  }

  sendEmail() {
    const isValidEmail = this.validateEmailObject();
    if (!isValidEmail) {
      return;
    }

    this.isEmailSending = true;
    const params = sendEmailBuilderForMyCRM({
      ...this.email,
      replyTo: {
        ...this.currentUserService,
      },
      loanId: this.loanId,
    });

    this.loanOpportunityService
      .sendEmail(this.loanId, params)
      .then((response) => {
        showSwalAfterSending(response.succeeded, 'Email');
        this.closeModal(true);
      });
  }

  closeModal(isRefresh = false) {
    this.isEmailSending = false;
    this.modalInstance.close({ isRefresh });
  }

  validateEmailObject() {
    const { body, recipientsEmailAddresses, subject } = this.email;

    if (!body) {
      toastError('Please put a content');
    }
    if (!recipientsEmailAddresses) {
      toastError('Please put a recipient');
    }
    if (!subject) {
      toastError('Please put a subject');
    }

    return (
      recipientsEmailAddresses &&
      recipientsEmailAddresses.length &&
      body &&
      subject
    );
  }
}
