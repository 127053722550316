import template from './commissionSearch.html';
import controller from './commissionSearchCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isMobileDevice: '<',
    parentToChildNotificationRegistration: '&',
    types: '<',
    lenders: '<',
    brokers: '<',
    default: '<',
  },
};
