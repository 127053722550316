export const DASHBOARD_TAB_URL = {
  YOUR_STATS: 'your-stats',
  ACTIVE_FILES: 'active-files',
  YOUR_TARGETS: 'your-targets',
};

export const DASHBOARD_TAB_INDEX = {
  [DASHBOARD_TAB_URL.YOUR_STATS]: 0,
  [DASHBOARD_TAB_URL.ACTIVE_FILES]: 1,
  [DASHBOARD_TAB_URL.YOUR_TARGETS]: 2,
};

export const STATS_VIEW_VALUE = {
  RECOMMENDED: 1,
  PERSONAL: 2,
};

export const STATS_VIEWS = [
  {
    value: STATS_VIEW_VALUE.RECOMMENDED,
    name: 'Recommended',
  },
  {
    value: STATS_VIEW_VALUE.PERSONAL,
    name: 'Personal',
  },
];

export const TIME_RANGES = [
  {
    value: 1,
    name: 'Monthly',
  },
];

export const DEFAULT_DASHBOARD_TAB = DASHBOARD_TAB_URL.YOUR_STATS;
