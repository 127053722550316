import { TIMEOUT } from 'Common/constants/timeout';
import { BORROWER_PARTY_TYPES } from 'Common/constants/partyTypes';

export default class ClientLookupCtrl {
  constructor(
    $state,
    $timeout,

    optionsService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.optionsService = optionsService;
  }

  $onInit() {
    this.minSearchKeyLength = 3;
    this.initSearchKey();
  }

  $onDestroy() {
    this.searchTimeout && this.$timeout.cancel(this.searchTimeout);
  }

  initSearchKey() {
    if (!this.selectedClient) {
      this.searchKey = null;
      return;
    }
    const { FirstName: firstName, LastName: lastName } = this.selectedClient;
    this.searchKey = `${firstName} ${lastName}`;
  }

  clearSelectedClient() {
    this.selectedClient = null;
    this.onSelect && this.onSelect({ client: this.selectedClient });
  }

  resetSearchResults() {
    this.searchResults = null;
    this.doShowResults = false;
    this.prevSearchKey = null;
  }

  resetSelectedClient() {
    if (this.isSearching) {
      return;
    }
    const trimmedSearchKey = (this.searchKey && this.searchKey.trim()) || '';
    const isInvalidSearchKey =
      trimmedSearchKey.length < this.minSearchKeyLength;
    if (isInvalidSearchKey) {
      this.clearSelectedClient();
    }
  }

  closeResults() {
    this.resetSearchResults();
    this.resetSelectedClient();
  }

  setSelectedClient(client) {
    this.selectedClient = client;
    this.resetSearchResults();
    this.initSearchKey();
    this.onSelect && this.onSelect({ client });
  }

  searchClients(searchKey) {
    const trimmedSearchKey = (searchKey || '').trim();
    const isValidKeyLength = trimmedSearchKey.length >= this.minSearchKeyLength;
    const isNewSearchKey = this.prevSearchKey !== trimmedSearchKey;
    if (!isValidKeyLength || !isNewSearchKey) {
      if (!trimmedSearchKey) {
        this.resetSearchResults();
        this.selectedClient = null;
      }
      return;
    }

    this.clearSelectedClient();
    this.prevSearchKey = trimmedSearchKey;
    this.searchTimeout && this.$timeout.cancel(this.searchTimeout);
    this.searchTimeout = this.$timeout(() => {
      this.doShowResults = false;
      this.isSearching = true;
      this.optionsService
        .scenarioSearchClientGet(trimmedSearchKey, BORROWER_PARTY_TYPES.PERSON)
        .then(({ data }) => {
          this.searchResults = data;
          this.hasSearchResults = !!data && !!data.length;
          this.doShowResults = true;
          this.isSearching = false;
        });
    }, TIMEOUT.SEARCH);
  }
}
