import angular from 'angular';
import yourStats from './yourStats';
import activeFiles from './activeFiles';
import yourTargets from './yourTargets';

export default angular
  .module('businessPlanningDashboard.scenes.dashboardContainer.scenes', [])
  .component('yourStats', yourStats)
  .component('activeFiles', activeFiles)
  .component('yourTargets', yourTargets).name;
