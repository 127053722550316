import template from './helloBookLoadingModal.html';

export default {
  template,
  bindings: {
    modalInstance: '<',
    showClose: '<',
    action: '<',
  },
};
