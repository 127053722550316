const searchResultBuilderForUI = (data) => ({
  clientId: data.ClientId,
  elasticScore: data.ElastcScore,
  providerId: data.ProviderId,
  providerName: data.ProviderName,
  resultContent: data.ResultContent,
  resultFamilyId: data.ResultFamilyID,
  resultId: data.ResultID,
  resultName: data.ResultName,
  resultType: data.ResultType,
  deceased: data.Deceased,
});

const matchResultTypeForUI = {
  Name: 'Customers',
  Entity: 'Company',
};

export const generalMobileSearchBuilderForUI = (data) => ({
  resultType:
    matchResultTypeForUI[data.MatchResultType] || data.MatchResultType,
  result: data.Result ? data.Result.map(searchResultBuilderForUI) : [],
});
