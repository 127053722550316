import {
  productRateBuilderForUI,
  productRateBuilderForMyCRM,
  productRateSetResponseBuilderForUI,
} from 'Common/mappers/loanSplit';
class LoanSplitModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'loan-split';
  }

  getProductRate(brokerEventId, productId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${brokerEventId}/rates`, {
        productId,
      })
      .then(({ data }) => data && productRateBuilderForUI(data));
  }

  setProductRate(payload) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/rates`, productRateBuilderForMyCRM(payload))
      .then(({ data }) => productRateSetResponseBuilderForUI(data));
  }
}

export default LoanSplitModelService;
