import get from 'lodash/get';

const defaultSelector = '.scroll-element';

class ElementService {
  constructor($document, $timeout, timeoutService) {
    'ngInject';

    this.$document = $document;
    this.$timeout = $timeout;
    this.timeoutService = timeoutService;
  }

  setFocusLastElement(elementSelector) {
    const selector = this.$document.find(elementSelector);
    if (!selector || !selector.length) {
      return;
    }
    selector[selector.length - 1].focus();
  }

  setFocusLastElementWithTimeout(elementSelector, time = 200) {
    this.$timeout(() => {
      this.setFocusLastElement(elementSelector);
    }, time);
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('ElementService');
  }

  scrollToElement(elementSelector = defaultSelector) {
    const isDocumentReady = get(this, `$document[0].querySelector`, false);
    const documentElement =
      isDocumentReady && this.$document[0].querySelector(elementSelector);
    if (documentElement) {
      documentElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }
}

export default ElementService;
