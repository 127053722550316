import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { DEFAULT_PIPELINE_FILTER } from 'Common/default/pipeline';
import { PICKER_RANGE } from 'Common/constants/pickerRange';
import { getFutureDateRanges } from 'Common/config/calendarRangePicker';

export const formatDateFilter = (dateFilter, activeRangeIndex) => {
  if (!dateFilter) {
    return '';
  }
  const { DateCreatedStart: dateStart, DateCreatedEnd: dateEnd } = dateFilter;
  if (!dateStart) {
    return;
  }

  const isToday =
    activeRangeIndex === PICKER_RANGE.TODAY ||
    moment().diff(dateEnd, 'days') === 0;
  if (isToday) {
    return `Today, ${moment(dateStart).format('DD MMM YYYY')}`;
  }
  return `From ${moment.utc(dateStart).format('DD MMM')} to ${moment
    .utc(dateEnd)
    .format('DD MMM YYYY')}`;
};

export default class InsurancePipelieFiltersCtrl {
  constructor(currentUserService, pipelineSharedData) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.pipelineSharedData = pipelineSharedData;
    this.filterRange = PICKER_RANGE;
    this.dateActiveRangeIndex = null;
    this.dateFilterRanges = getFutureDateRanges();
  }

  $onInit() {
    this.isCorporateUser =
      this.currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    this.adviserFilter = this.adviserFilter || 0;
    this.providerFilter = this.providerFilter || 0;
    this.labelFilter = this.labelFilter || 0;
  }

  $onChanges(changes) {
    const { labels, advisers, statusFilters, providers } = changes;
    if (labels && labels.currentValue) {
      this.labels = [DEFAULT_PIPELINE_FILTER, ...labels.currentValue];
    }
    if (advisers && advisers.currentValue) {
      this.advisers = [
        { brokerId: 0, displayName: 'All' },
        ...advisers.currentValue,
      ];
    }
    if (providers && providers.currentValue) {
      this.providers = [DEFAULT_PIPELINE_FILTER, ...providers.currentValue];
    }
    if (statusFilters) {
      this.statusFilters = statusFilters.currentValue || [];
    }
    this.filterDateDisplay = formatDateFilter(
      this.pipelineSharedData.leadFilterData,
      this.dateActiveRangeIndex,
    );
  }

  onDateRangeSelected(activeRangeIndex) {
    if (typeof this.dateActiveRangeIndex === 'undefined') {
      return;
    }
    this.dateActiveRangeIndex = activeRangeIndex;
    if (activeRangeIndex !== PICKER_RANGE.CUSTOM) {
      this.isDateFilterOpen = false;
    }
  }

  onCalendarUpdated(filterData) {
    if (!filterData) {
      return;
    }

    if (this.dateActiveRangeIndex === PICKER_RANGE.CUSTOM) {
      this.isDateFilterOpen = false;
    }

    this.pipelineSharedData.leadFilterData.DateCreatedStart = filterData.DateCreatedStart
      ? filterData.DateCreatedStart
      : '';
    this.pipelineSharedData.leadFilterData.DateCreatedEnd = filterData.DateCreatedEnd
      ? filterData.DateCreatedEnd
      : '';
    const filter = {
      dateStart: filterData.DateCreatedStart,
      dateEnd: filterData.DateCreatedEnd,
    };
    this.onDateFilterChange({ filter });
    this.filterDateDisplay = formatDateFilter(
      filterData,
      this.dateActiveRangeIndex,
    );
  }
}
