import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { convertToLowerCase } from 'Common/utilities/string';
import { displayMap, displayCustomAddress } from 'Common/utilities/gMap';
import { getNumberView, removeCountryCode } from 'Common/utilities/phone';
import { setCurrrentDate, setDateFormat } from 'Common/utilities/date';
import { toastSaveSuccess } from 'Common/utilities/alert';
import { ASSISTANT_STATUS_NAME_LIST } from 'Common/constants/assistantConstant';
import { sendEmail, getLastLogin } from 'Common/utilities/contact';
import { ASSISTANT_TAB_NAMES } from 'Common/constants/contactTab';
import {
  setMyCRMActiveToFalse,
  setIsActive,
  updateAssistantStatus,
  getAssistantStatusName,
  setClasses,
} from './util/assistant';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('AssistantCtrl', function AssistantCtrl(
      $timeout,
      $state,
      $window,
      $stateParams,
      $scope,
      toaster,
      corporateModelService,
      broadcastDataService,
      addressModalService,
      corporateService,
      modelDataService,
      commonFnService,
      contactService,
      queryService,
      stateService,
      dataService,
      noteService,
      currentUserService,
      configService,
    ) {
      const { bccEmailSync } = configService.feature;
      $scope.enableSendEmailToAssistantContact = !!configService.feature
        .sendEmailToAssistantContact;
      $scope.enableSendSms = !currentUserService.isNZ;
      $scope.notesAction = noteService.action;
      const stateTransition = (tab) => {
        $state.params.activeTab = tab;
        stateService.transition($state.current, $state.params);
      };
      /* State Params */
      $scope.clientId = $state.params.clientId || 0;
      $scope.familyId = $state.params.familyId || 0;
      /*   VARIABLE(s)        */
      /* Data Type: {Object} */
      $scope.familyBrokerSupportGet = {};
      $scope.commissionAccessObj = {};
      $scope.assistantDetails = {};
      $scope.showAddField = {};
      $scope.editMode = {};
      /* Data Type: {Array} */
      $scope.commissionAssistantAccessArr = [];
      $scope.accessAssisstantTypesArr = [];
      $scope.selectedAdviserOrg = [];
      $scope.operatingCountries = [];
      $scope.brokerStatusLists = [];
      $scope.contactTypeRoles = [];
      $scope.reportingRegions = [];
      $scope.altInputFormats = [];
      $scope.contactRoleName = [];
      $scope.levelAccessList = [];
      $scope.selectedRoles = [];
      $scope.addressTypes = [];
      $scope.genderLists = [];
      $scope.statusLists = [];
      $scope.adviser_org = [];
      $scope.setterType = [];
      $scope.saveType = [];
      $scope.business = {};
      $scope.mailing = {};
      $scope.homeAddress = {};
      $scope.accessManagement = {};

      /* Data Type: {String} */
      $scope.userCurrentCountyId = 0;
      $scope.isHomeAddressMapId = 'map-assistant-address-home';
      // eslint-disable-next-line sonarjs/no-duplicate-string
      $scope.addressTypeValue = 'Business Address';
      $scope.editBusinessAdd = false;
      $scope.isEmailNotValid = false;
      $scope.dateStaticValue = '1900-01-01';
      $scope.isBusinessMapId = 'map-assistant-address';
      $scope.isMailingMapId = 'map-assistant-address-mailing';
      $scope.isProfileAdmin = false;
      $scope.editMailingAdd = false;
      $scope.currentDate = '';
      $scope.editHomeAdd = false;
      $scope.dateFormat = '';

      $scope.getLastLogin = getLastLogin;
      $scope.routeContactList = stateService.routeToContactList;
      $scope.updateAssistantStatus = updateAssistantStatus({
        $scope,
        corporateService,
      });
      $scope.setClasses = setClasses;
      $scope.statusNameList = ASSISTANT_STATUS_NAME_LIST;

      /*  FUNCTION DEFINITION(S)  */
      /*
       *   @name reportingRegionsLists
       *   @desc This will load data from Api: `ReportingRegionsGet`.
       */
      const reportingRegionsLists = (countryId) => {
        dataService.getReportingRegions(countryId).then((response) => {
          $scope.reportingRegions = response;
        });
      };
      /*
       *   @name getAsssistantAddress
       *   @desc This will load data from Api: `familyAddressGet`.
       */
      function getAsssistantAddress() {
        corporateService.familyAddressGet($scope.familyId).then((response) => {
          $scope.familyAddressGet = response.data;
          const assistantAddressTmp = commonFnService.setAddressTypes(
            response.data,
          );

          if (response && assistantAddressTmp) {
            $scope.business = angular.extend({}, assistantAddressTmp.business);
            $scope.mailing = angular.extend({}, assistantAddressTmp.mailing);
            $scope.homeAddress = angular.extend({}, assistantAddressTmp.home);
          }
          if (_.isUndefined($scope.business)) {
            $scope.business = modelDataService.addressModelData();
          }
          if (_.isUndefined($scope.mailing)) {
            $scope.mailing = modelDataService.addressModelData();
          }
          if (_.isUndefined($scope.homeAddress)) {
            $scope.homeAddress = modelDataService.addressModelData();
          }
          /* check if mailing is same with business address reference variable `$scope.isSameinBussinessAddress` */
          if (
            !_.isEmpty($scope.business) &&
            !_.isEmpty($scope.mailing) &&
            $scope.business.formatted_address ===
              $scope.mailing.formatted_address
          ) {
            $scope.isSameinBussinessAddress = true;
          }
        });
      }
      /*
       *   @name onRetrieveCorporateContactGetSuccess
       *   @desc This will load data from Api: `corporateContactGet` on success.
       *   @params {Object} response - data from the api result.
       */
      // eslint-disable-next-line sonarjs/cognitive-complexity
      function onRetrieveCorporateContactGetSuccess(response, tabValue) {
        if (response && response.data) {
          $scope.contactRoleName = [];
          $scope.selectedRoles = [];
          $scope.contactTypeRoles = [];

          if (tabValue === ASSISTANT_TAB_NAMES.ACCESS_MANAGEMENT) {
            const { Email, FamilyId } = response.data;
            corporateModelService
              .getOktaLastLogin(Email, FamilyId)
              .then((data) => {
                if (data) {
                  response.data.OktaLastLoginDate = data.lastLogin;
                }
              });
          }

          dataService.getRecentViewed();
          dataService
            .getAdviserOrgLists(response.data.AdviserOrganizationId)
            .then((adviserOrgData) => {
              $scope.adviser_org = [];
              $scope.selectedAdviserOrg = [];
              $scope.adviser_org = adviserOrgData.data;
              $scope.selectedAdviserOrg = adviserOrgData.selected;
            });

          response.data.GenderName = queryService.getArrValue(
            response.data.Gender,
            null,
            'id',
            'value',
            $scope.genderLists,
          );
          response.data.ReqdTeamAcessLevelName = queryService.getArrValue(
            response.data.ReqdTeamAcessLevel,
            null,
            'id',
            'value',
            $scope.levelAccessList,
          );

          dataService.getOperatingCountries().then((countries) => {
            if (countries && countries.length > 0) {
              $scope.operatingCountries = countries;
              response.data.ReportingCountryName = queryService.getArrValue(
                response.data.ReportingCountryId,
                null,
                'CountryId',
                'CountryName',
                $scope.operatingCountries,
              );
              response.data.ReportingCountryCode = queryService.getArrValue(
                response.data.ReportingCountryId,
                null,
                'CountryId',
                'CountryCode',
                $scope.operatingCountries,
              );
              response.data.ReportingCountryPhoneCode = queryService.getArrValue(
                response.data.ReportingCountryId,
                null,
                'CountryId',
                'CallingCode',
                $scope.operatingCountries,
              );
              $scope.mobileMask = broadcastDataService.getMobileFormat(
                response.data.ReportingCountryId,
              );
              $scope.phoneMask = broadcastDataService.getPhoneFormat(
                response.data.ReportingCountryId,
              );
            }
          });

          queryService.getAllArrayDetails(
            $scope,
            corporateService,
            'businessUnitGet',
            [],
            'businessUnits',
            response.data,
            'BusinessUnitName',
            'BusinessUnitID',
            null,
            null,
          );

          dataService.getContactTypeRoles().then((roles) => {
            if (roles && roles.length > 0) {
              let contactRoleName = [];
              contactRoleName = response.data.RoleId
                ? response.data.RoleId.split(',')
                : [];

              roles.forEach((role) => {
                if (role.ContactTypeRoleName !== 'Assistant') {
                  return;
                }
                if (!$scope.contactTypeRoles.includes(role)) {
                  $scope.contactTypeRoles.push(role);
                }

                contactRoleName.forEach((rolename) => {
                  const { ContactTypeRoleDesc, ContactTypeRoleId } = role;
                  const isRoleExist = !!$scope.selectedRoles.find(
                    (obj) => obj.ContactTypeRoleDesc === ContactTypeRoleDesc,
                  );
                  if (Number(ContactTypeRoleId) !== Number(rolename)) {
                    return;
                  }
                  if (!isRoleExist) {
                    $scope.selectedRoles.push(role);
                  }
                  if (
                    !$scope.contactRoleName.includes(role.ContactTypeRoleDesc)
                  ) {
                    $scope.contactRoleName.push(role.ContactTypeRoleDesc);
                  }
                });
              });
              response.data.RoleDesc =
                $scope.contactRoleName.length > 0
                  ? $scope.contactRoleName.join(',  ')
                  : '';
              response.data.RoleArr = $scope.selectedRoles;
            }
          });

          queryService.getAllArrayDetails(
            $scope,
            corporateService,
            'reportingStatesGet',
            [],
            'reportingStates',
            response.data,
            'ReportingStateName',
            'ReportingStateId',
            null,
            null,
          );
          if (
            response.data.ReportingCountryId &&
            response.data.ReportingCountryId < 3
          ) {
            $scope.userCurrentCountyId = response.data.ReportingCountryId;
          }
          reportingRegionsLists($scope.userCurrentCountyId);
          queryService.getAllArrayDetails(
            $scope,
            corporateService,
            'reportingRegionsGet',
            [$scope.userCurrentCountyId],
            'reportingRegions',
            response.data,
            'ReportingRegionName',
            'ReportingRegionId',
            null,
            null,
          );

          $scope.assistantDetails = response.data;
          $scope.originalEmail = $scope.assistantDetails.Email;
          $scope.assistantDetails.Mobile = removeCountryCode(
            $scope.assistantDetails.Mobile,
          );
          $scope.assistantDetails.WorkNumber = removeCountryCode(
            $scope.assistantDetails.WorkNumber,
          );
          $scope.assistantDetails.BrokerStatusName = getAssistantStatusName(
            $scope.assistantDetails.BrokerStatusId,
            $scope.brokerStatusLists,
            queryService,
          );
        }
      }
      $scope.getNumberView = getNumberView;
      /*  FUNCTION DEFINITION(S)  */
      const loadAssistantData = (tabValue) => {
        corporateService
          .corporateContactGet($scope.clientId, $scope.familyId)
          .then((response) => {
            onRetrieveCorporateContactGetSuccess(response, tabValue);
          }, commonFnService.onRetrieveError);
      };

      const getAssistantCommissionAccess = () => {
        $scope.accessManagement.commissionAssistantAccessList = [];
        contactService.commissionAccess($scope.familyId).then((response) => {
          if (response && response.data) {
            $scope.accessManagement.commissionAssistantAccessList =
              response.data;
          }
        });
      };
      /*
       *   @name onRetrieveCorporateFamilyBrokerSupportGet
       *   @desc This will load data from Api: `corporateContactGet` on success.
       *   @params {Object} response - data from the api result.
       */
      const onRetrieveCorporateFamilyBrokerSupportGet = (response) => {
        if (response && response.data) {
          $scope.unlockingAccount = false;
          if (
            $scope.accessAssisstantTypesArr &&
            $scope.accessAssisstantTypesArr.length > 0
          ) {
            response.data.AccessTypeName = queryService.getArrValue(
              response.data.AccessTypeID,
              null,
              'AccessTypeId',
              'AccessType',
              $scope.accessAssisstantTypesArr,
            );
          }

          response.data.FamilyId = response.data.FamilyId
            ? response.data.FamilyId
            : $scope.familyId;
          $scope.familyBrokerSupportGet = response.data;
        }
      };

      const loadAssistantBrokerSupportData = () => {
        corporateService
          .familyBrokerSupportGet($scope.familyId, $scope.clientId)
          .then(
            onRetrieveCorporateFamilyBrokerSupportGet,
            commonFnService.onRetrieveError,
          );
      };
      /**
       *   @name initialize
       *   @desc This will load/initialize data needed on load.
       */
      const getAsssistantTabDetails = (tabValue) => {
        switch (tabValue) {
          case 'Assistant Details':
            loadAssistantData();
            $scope.genderLists = dataService.getGenderTypes();
            $scope.dateFormat = setDateFormat();
            $scope.addressTypes = dataService.getAddressTypes();
            $scope.altInputFormats = dataService.getDateValidityFormats();
            $scope.currentDate = setCurrrentDate();
            $scope.levelAccessList = dataService.getLevelAccessList();
            getAsssistantAddress();
            break;
          case ASSISTANT_TAB_NAMES.ACCESS_MANAGEMENT:
            dataService.getAccessType('Assistant').then((response) => {
              $scope.accessAssisstantTypesArr = response;
            });
            loadAssistantData(tabValue);
            getAssistantCommissionAccess();
            dataService.getAdminUsers().then((users) => {
              if (_.size(users) > 0) {
                $scope.adminUsers = users;
              }
            });
            loadAssistantBrokerSupportData();
            break;
          default:
            break;
        }
      };

      function initialize() {
        dataService.getUserInfo(true).then((response) => {
          if (response) {
            $scope.isProfileAdmin =
              response.data.CorpAccessTypeID === 1 ? 'true' : 'false';
            $scope.userCurrentCountyId = response.data.CountryId;
          }
        });
        corporateService
          .getAdvisersDropdown($scope.familyId, 0, 0)
          .then((response) => {
            $scope.adviserAssitantLists = [];
            $scope.adviserAssitantLists = response.data;
          });
        $scope.setterType = dataService.getSetterTypes('Adviser');
        $scope.saveType = dataService.getSaveTypes('Adviser');
        $scope.$watch('selectedContacts', (newValue) => {
          if (!$scope.assistantTabs || !$scope.assistantTabs[newValue]) {
            return;
          }
          getAsssistantTabDetails($scope.assistantTabs[newValue].name);
          stateTransition($scope.assistantTabs[newValue].value);
        });
        dataService.getBrokerStatus().then((data) => {
          $scope.brokerStatusLists = data;
        });
      }
      /*
       *   @name querySet
       *   @desc This will save the value from api.
       *   @params {String} setter
       *   @params {String} key
       *   @params {Array} arrays
       *   @params {Boolean} isCorporate
       */
      function querySet(setter, key, field, arrays) {
        $scope.editMode[key] = false;
        commonFnService.setEditMode($scope.editMode, field);
        corporateService[setter]
          .apply(null, arrays)
          .then((response) => {
            if (response) {
              loadAssistantData();
              loadAssistantBrokerSupportData();
              toaster.pop('success', 'Update', 'successfully updated!');
            }
          })
          .catch(() => {
            if (setter === 'contactSet') {
              $scope.assistantDetails.Email = $scope.originalEmail;
            }
          });
        toaster.clear();
      }
      /* ------------------------------------------ [ PUBLIC FUNCTION ] ------------------------------------------- */

      $scope.dateEditMode = function (obj, key) {
        return commonFnService.dateEditMode(obj, key, $scope.editMode);
      };

      $scope.formatDateString = function (date) {
        return commonFnService.formatDateString(date);
      };

      $scope.formatDate = function (date) {
        return commonFnService.formatDate(date);
      };

      $scope.showAddress = function (addressValue) {
        if (addressValue === 'Business Address') {
          $scope.editHomeAdd = false;
          $scope.editMailingAdd = false;
        } else if (addressValue === 'Home Address') {
          $scope.editBusinessAdd = false;
          $scope.editMailingAdd = false;
          // eslint-disable-next-line sonarjs/no-duplicate-string
        } else if (addressValue === 'Mailing Address') {
          $scope.editBusinessAdd = false;
          $scope.editHomeAdd = false;
        }
        getAsssistantAddress();
      };

      $scope.editAddressModal = function (assitantTypeAddress) {
        if (assitantTypeAddress === 'Business Address') {
          $scope.editBusinessAdd = !$scope.editBusinessAdd;
        } else if (assitantTypeAddress === 'Home Address') {
          $scope.editHomeAdd = !$scope.editHomeAdd;
        } else if (assitantTypeAddress === 'Mailing Address') {
          $scope.editMailingAdd = !$scope.editMailingAdd;
        }
      };

      $scope.saveAddress = function (type, address) {
        const isBusiness = type === 'Business Address';
        const isMailing = type === 'Mailing Address';
        commonFnService
          .saveAddress(
            $scope.familyId,
            0,
            address,
            isMailing,
            isBusiness,
            false,
          )
          .then(() => {
            switch (type) {
              case 'Mailing Address':
                $scope.editMailingAdd = false;
                break;
              case 'Business Address':
                $scope.editBusinessAdd = false;
                break;
              default:
                $scope.editHomeAdd = false;
                break;
            }
            getAsssistantAddress();
          });
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.updateAssistantDetails = function (
        setterType,
        obj,
        key,
        saveType,
        field,
        formName,
        inputName,
      ) {
        let dataArray = [];
        const roleName = [];
        const roleId = [];
        const invalidAssistantNumber =
          (key === 'Mobile' && !obj.Mobile) ||
          (key === 'WorkNumber' && !obj.WorkNumber);
        if (invalidAssistantNumber) {
          toaster.pop('error', 'Error', 'Invalid Number.');
          return false;
        }
        switch (setterType) {
          case 'contactSet':
            if (formName && inputName) {
              if (
                !commonFnService.checkFormDateValidation(
                  formName,
                  inputName,
                  obj,
                  key,
                )
              ) {
                return false;
              }
            } else {
              switch (key) {
                case 'BrokerStatusId':
                  obj.BrokerStatusId = field;
                  obj.BrokerStatusName = getAssistantStatusName(
                    field,
                    $scope.brokerStatusLists,
                    queryService,
                  );
                  obj.IsActive = setIsActive(field);
                  setMyCRMActiveToFalse(obj, obj.IsActive);
                  break;
                case 'IsActive':
                  obj.IsActive = field;
                  setMyCRMActiveToFalse(obj, field);
                  break;
                case 'ReportingCountryCode':
                  obj.ReportingCountryId = queryService.getArrValue(
                    null,
                    obj.ReportingCountryCode,
                    'CountryId',
                    'CountryCode',
                    $scope.operatingCountries,
                  );
                  if (obj.ReportingCountryId && obj.ReportingCountryId < 3) {
                    reportingRegionsLists(obj.ReportingCountryId);
                  } else {
                    reportingRegionsLists($scope.userCurrentCountyId);
                  }
                  obj.ReportingCountryName = queryService.getArrValue(
                    obj.ReportingCountryId,
                    null,
                    'CountryId',
                    'CountryName',
                    $scope.operatingCountries,
                  );
                  obj.ReportingCountryCode = queryService.getArrValue(
                    obj.ReportingCountryId,
                    null,
                    'CountryId',
                    'CountryCode',
                    $scope.operatingCountries,
                  );
                  obj.ReportingCountryPhoneCode = queryService.getArrValue(
                    obj.ReportingCountryId,
                    null,
                    'CountryId',
                    'CallingCode',
                    $scope.operatingCountries,
                  );
                  break;
                case 'ReportingStateName':
                  obj.ReportingStateId = queryService.getArrValue(
                    null,
                    obj.ReportingStateName,
                    'ReportingStateId',
                    'ReportingStateName',
                    $scope.reportingStates,
                  );
                  obj.ReportingStateName = queryService.getArrValue(
                    obj.ReportingStateId,
                    null,
                    'ReportingStateId',
                    'ReportingStateName',
                    $scope.reportingStates,
                  );
                  break;
                case 'ReportingRegionName':
                  obj.ReportingRegionId = queryService.getArrValue(
                    null,
                    obj.ReportingRegionName,
                    'ReportingRegionId',
                    'ReportingRegionName',
                    $scope.reportingRegions,
                  );
                  obj.ReportingRegionName = queryService.getArrValue(
                    obj.ReportingRegionId,
                    null,
                    'ReportingRegionId',
                    'ReportingRegionName',
                    $scope.reportingRegions,
                  );
                  break;
                case 'GenderName':
                  obj.Gender = queryService.getArrValue(
                    null,
                    obj.GenderName,
                    'id',
                    'value',
                    $scope.genderLists,
                  );
                  obj.GenderName = queryService.getArrValue(
                    obj.Gender,
                    null,
                    'id',
                    'value',
                    $scope.genderLists,
                  );
                  break;
                case 'ReqdTeamAcessLevelName':
                  obj.ReqdTeamAcessLevel = queryService.getArrValue(
                    null,
                    obj.ReqdTeamAcessLevelName,
                    'id',
                    'value',
                    $scope.levelAccessList,
                  );
                  obj.ReqdTeamAcessLevelName = queryService.getArrValue(
                    obj.ReqdTeamAcessLevel,
                    null,
                    'id',
                    'value',
                    $scope.levelAccessList,
                  );
                  break;
                case 'RoleDesc':
                  obj.RoleArr.forEach((value) => {
                    roleName.push(value.ContactTypeRoleDesc);
                    roleId.push(value.ContactTypeRoleId);
                  });
                  obj.RoleDesc =
                    roleName && roleName.length > 0 ? roleName.join(',  ') : '';
                  obj.RoleId =
                    roleId && roleId.length > 0 ? roleId.join(',') : '';
                  break;
                case 'MyCRMActive':
                  obj.myCRMActiveIsUpdated = key === 'MyCRMActive';
                  break;
                case 'BusinessUnitName':
                  obj.BusinessUnitID = queryService.getArrValue(
                    null,
                    obj.BusinessUnitName,
                    'BusinessUnitID',
                    'BusinessUnitName',
                    $scope.businessUnits,
                  );
                  obj.BusinessUnitName = queryService.getArrValue(
                    obj.BusinessUnitID,
                    null,
                    'BusinessUnitID',
                    'BusinessUnitName',
                    $scope.businessUnits,
                  );
                  break;
                default:
                  break;
              }
            }
            dataArray = [$scope.saveType[saveType], obj];
            break;
          case 'familyBrokerSupportSet':
            // eslint-disable-next-line sonarjs/no-small-switch
            switch (key) {
              case 'AccessTypeName':
                obj.AccessTypeID = queryService.getArrValue(
                  null,
                  obj.AccessTypeName,
                  'AccessTypeId',
                  'AccessType',
                  $scope.accessAssisstantTypesArr,
                );
                break;
              default:
                break;
            }
            dataArray = [$scope.familyId, $scope.clientId, obj];
            break;
          default:
            break;
        }

        if (dataArray && dataArray.length > 0) {
          querySet(setterType, key, field, dataArray);
        }
      };

      $scope.updateEmailAssistant = function (
        setterType,
        obj,
        key,
        saveType,
        field,
      ) {
        if (key === 'Email') {
          commonFnService
            .checkEmailValidity(obj.Email, $scope.familyId, true)
            .then((prom) => {
              if (prom) {
                swal(
                  'Email Validation',
                  'Email is already used by other user.',
                  'error',
                );
              } else {
                $scope.updateAssistantDetails(
                  setterType,
                  obj,
                  key,
                  saveType,
                  field,
                );
              }
            });
        }
      };

      $scope.resetFailedLoginAttemps = () => {
        $scope.unlockingAccount = true;
        corporateService
          .resetLoginTotalFailedAttemptsSet($scope.familyId)
          .then((response) => {
            if (response) {
              getAsssistantTabDetails(ASSISTANT_TAB_NAMES.ACCESS_MANAGEMENT);
            }
          });
      };
      $scope.setCommissionAccess = (
        commissionAssistantAccess,
        idAnguComplete,
      ) => {
        if (!idAnguComplete) {
          contactService.commissionAccessSet(
            commissionAssistantAccess.FamilyID,
            $scope.familyId,
            commissionAssistantAccess.HasCommissionAccess,
          );
          return false;
        }
        if (
          commissionAssistantAccess &&
          commissionAssistantAccess.BrokerFullNameTmp
        ) {
          commissionAssistantAccess.BrokerFullName =
            commissionAssistantAccess.BrokerFullNameTmp.originalObject.FullName;
          contactService
            .commissionAccessSet(
              commissionAssistantAccess.BrokerFullNameTmp.originalObject
                .FamilyId,
              $scope.familyId,
              commissionAssistantAccess.isActive,
            )
            .then((response) => {
              if (response) {
                $scope.showAddField.show = false;
                getAssistantCommissionAccess();
                $scope.commissionAssistantAccessArr = [];
                commissionAssistantAccess.BrokerFullNameTmp = {};
                commissionAssistantAccess.isActive = false;
                $scope.$broadcast(
                  'angucomplete-alt:clearInput',
                  idAnguComplete,
                );
              }
            });
        } else if (idAnguComplete) {
          toaster.pop('error', 'Field required!', 'Adviser field');
        }
      };
      $scope.cancelCommissionAccess = (commissionAssitantAccess) => {
        $scope.showAddField.show = false;
        if (commissionAssitantAccess) {
          commissionAssitantAccess.BrokerFullNameTmp = null;
          commissionAssitantAccess.isActive = false;
        }
      };
      $scope.deleteCommissionAccess = (commissionAssistantAccess) => {
        contactService
          .brokerAccessDelete(
            commissionAssistantAccess.FamilyID,
            $scope.familyId,
          )
          .then((response) => {
            if (response) {
              $scope.commissionAssistantAccessArr = [];
              getAssistantCommissionAccess();
            }
          });
      };
      $scope.selectedAdviser = function (
        setterType,
        key,
        selectedAdviserOrg,
        obj,
        field,
      ) {
        if (selectedAdviserOrg && selectedAdviserOrg.length > 0) {
          obj.AdviserOrganizationId =
            selectedAdviserOrg[0].AdviserOrganizationId;
          $scope.updateAssistantDetails(setterType, obj, key, 'all', field);
        }
      };
      $scope.isDateValid = function (date) {
        const d = new Date(date);
        return parseInt(d.getFullYear(), 10) > 1;
      };

      $scope.isMyCrmActive = (setterType, obj, key, field, saveType) => {
        if (key === 'MyCRMActive') {
          swal(
            {
              title: 'Confirmation',
              text:
                'Are you absolutely sure that this user has completed the verification process and is ready to move to MyCRM? This will impact all existing system integrations and this change cannot be reversed.',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#F68F8F',
              confirmButtonText: 'Yes!',
              closeOnConfirm: false,
            },
            (confirm) => {
              if (confirm) {
                $scope.updateAssistantDetails(
                  setterType,
                  obj,
                  key,
                  saveType,
                  field,
                );
                swal('Updated!', 'myCRM Active updated.', 'success');
              }
            },
          );
        }
      };
      $scope.convertToLowerCase = convertToLowerCase;

      $scope.launchAddressModal = () => {
        const size = 'md';
        const isOrgAddress = false;
        const modalInstance = addressModalService.launchAddressModal(
          size,
          $scope.familyId,
          isOrgAddress,
        );
        modalInstance.result.then(() => {
          $scope.timeOutAssistantInstance = $timeout(() => {
            getAsssistantAddress();
            loadAssistantData();
            toastSaveSuccess();
          }, 1000);
        });
      };

      $scope.sendEmail = sendEmail({ $window, $state, $stateParams, $scope });

      $scope.sendSms = (state, familyId, contactType) => {
        $state.go(state, { familyId, contactType });
      };

      $scope.$on('$destroy', () => {
        $timeout.cancel($scope.timeOutAssistantInstance);
      });
      $scope.displayMap = displayMap;
      $scope.displayCustomAddress = displayCustomAddress;
      /**
       *   Function Call
       */

      const getAssistantTabs = () => {
        corporateModelService.getAssistantTabs().then((tabs) => {
          $scope.assistantTabs = tabs;
          const tabArray = tabs.map((tab) => tab.value);
          $scope.selectedContacts =
            ($state.params.activeTab &&
              tabArray &&
              tabArray.indexOf($state.params.activeTab)) ||
            0;
          getAsssistantTabDetails($scope.assistantTabs[0].name);
        });
      };
      getAssistantTabs();
      initialize();
      bccEmailSync &&
        corporateModelService.setGmailSyncOnDemand($scope.familyId);
    });
