import { EXTRA_STATUS } from 'Common/constants/extraLoanStatus';

class LoanStatusSelectorCtrl {
  constructor(optionsService) {
    'ngInject';

    this.optionsService = optionsService;
  }

  getLoanStatus() {
    const includeStatus = [
      EXTRA_STATUS.CANCELLED,
      EXTRA_STATUS.LOAN_REPAID,
    ].join();
    this.optionsService.getLoanStatus(includeStatus).then(({ data }) => {
      this.loanDetails.LoanStatusList = data || [];
    });
  }

  $onInit() {
    this.getLoanStatus();
  }
}

export default LoanStatusSelectorCtrl;
