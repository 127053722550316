import { pdfBuilderForUI } from 'Common/mappers/document';

export default class GamePlanService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.baseUrl = 'game-plan';
  }

  download(loanScenarioId, payload = { IsApplicant: true }) {
    return this.httpClient
      .post(`${this.baseUrl}/${loanScenarioId}/download`, payload)
      .then(({ data }) => data && pdfBuilderForUI(data));
  }

  send(loanScenarioId, payload = { IsApplicant: true }) {
    return this.httpClient.post(
      `${this.baseUrl}/${loanScenarioId}/send`,
      payload,
    );
  }

  saveDefaultSettings({ loanAdviserId, payload }) {
    return this.httpClient.post(
      `${this.baseUrl}/adviser/${loanAdviserId}/settings`,
      payload,
    );
  }

  getDefaultSettings({ loanAdviserId, params }) {
    return this.httpClient.get(
      `${this.baseUrl}/adviser/${loanAdviserId}/settings`,
      params,
    );
  }
}
