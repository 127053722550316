import { MERGE_FIELD_CATEGORY } from 'Common/constants/mergeFieldCategory';
import { escapeCharactersToHTMLTag } from 'Common/utilities/escapeCharactersToHTMLTag';
import {
  MERGE_FIELDS,
  MERGE_FIELDS_V1_MAPPINGS,
} from 'Common/constants/mergeFields';

export const processV1Mappings = (mergeFields, mappings) => {
  if (!mergeFields) return;

  return mergeFields.map((mergeFieldCategory) => {
    const fieldsToMap = mappings[mergeFieldCategory.Category];
    const mappedFields = [];
    Object.keys(fieldsToMap || []).forEach((field) => {
      const v3Field = mergeFieldCategory.MergeFields.find(
        (m) => m.Name === fieldsToMap[field],
      );
      if (v3Field) mappedFields.push({ ...v3Field, Name: field });
    });

    return {
      ...mergeFieldCategory,
      MergeFields: [...mergeFieldCategory.MergeFields, ...mappedFields],
    };
  });
};

export const replaceDataPlaceholder = (
  mergeFieldData,
  defer,
  actualData,
  vm,
) => {
  const { client, loan } = mergeFieldData;

  let { templateContent, templateSubject } = mergeFieldData;

  const mappedData = processV1Mappings(actualData, MERGE_FIELDS_V1_MAPPINGS);

  if (mappedData) {
    templateContent = vm.translateContactsMergeFields(
      client,
      vm.getMergeFieldsValues(mappedData, MERGE_FIELD_CATEGORY.CONTACT),
      templateContent,
    );
    templateContent = vm.translateOtherMergeFields(
      vm.getMergeFieldsValues(mappedData, MERGE_FIELD_CATEGORY.OTHER),
      templateContent,
    );

    templateContent = vm.translateInsuranceMergeFields(
      vm.getMergeFieldsValues(mappedData, MERGE_FIELD_CATEGORY.INSURANCE),
      templateContent,
    );
    templateSubject = vm.translateSubjectMergeFields(
      templateSubject,
      mappedData,
      client,
    );

    const loanMergeFields = vm.getMergeFieldsValues(
      mappedData,
      MERGE_FIELD_CATEGORY.LOANS,
    );
    const templateHasLoanMergeFields = vm.templateHasLoanMergeFields(
      loanMergeFields,
      templateContent,
    );
    if (templateHasLoanMergeFields && loan) {
      templateContent = vm.translateLoanMergeFields(
        loan,
        loanMergeFields,
        templateContent,
      );
      templateSubject = vm.translateLoanMergeFields(
        loan,
        loanMergeFields,
        templateSubject,
      );
      const translationResponse = templateSubject
        ? { templateContent, templateSubject }
        : templateContent;
      return defer.resolve(translationResponse);
    } else if (templateHasLoanMergeFields && !loan) {
      return defer.reject({
        data: 'No loan selected',
        templateContent,
        templateSubject,
      });
    }
  }
  return templateSubject
    ? defer.resolve({ templateSubject, templateContent, mappedData })
    : defer.resolve(templateContent);
};

export const translateLoanMergeFields = (
  loan,
  mergeFieldsValue,
  templateContent,
  vm,
) => {
  const isMergeFieldsValid =
    loan &&
    mergeFieldsValue &&
    vm.isTemplateContentValid(templateContent) &&
    vm.templateHasLoanMergeFields(mergeFieldsValue, templateContent);
  if (isMergeFieldsValid) {
    mergeFieldsValue.map((mergeField) => {
      mergeField.Value = escapeCharactersToHTMLTag(mergeField.Value);
      const isValidAmount =
        (mergeField.Name === MERGE_FIELDS.TOTAL_LOAN_AMOUNT ||
          mergeField.Name === MERGE_FIELDS.LOAN_STRUCTURE.REPAYMENT_AMOUNT ||
          mergeField.Name === MERGE_FIELDS.LOAN_STRUCTURE.LOAN_AMOUNT) &&
        mergeField.Value;
      if (isValidAmount) {
        const amount = mergeField.Value && `$${mergeField.Value}`;
        mergeField.Value = amount;
      } else if (
        mergeField.Name === MERGE_FIELDS.LOAN_STRUCTURE.INTEREST_RATE &&
        mergeField.Value
      ) {
        const loanStructureInterestRate = `${mergeField.Value}%`;
        mergeField.Value = loanStructureInterestRate;
      } else if (
        mergeField.Name === MERGE_FIELDS.FLOATING_RATE &&
        mergeField.Value
      ) {
        const floatingRate = `${mergeField.Value}%`;
        mergeField.Value = floatingRate;
      }
      templateContent = vm.translateTemplateContentByField(
        templateContent,
        mergeField,
      );
    });
  }
  return templateContent;
};
