export const getCSSValues = (length, attrs) => {
  const multiplyBy = attrs.multiplyBy ? parseInt(attrs.multiplyBy, 10) : 7.7;
  const calculatedChar =
    attrs.maxCharLimit && parseInt(attrs.maxCharLimit, 10) < length
      ? attrs.maxCharLimit
      : length;
  const calculatedValue =
    (parseInt(calculatedChar.length, 10) + 1) * multiplyBy;
  const currentValue =
    attrs.minWidth && parseInt(attrs.minWidth, 10) > calculatedValue
      ? parseInt(attrs.minWidth, 10)
      : calculatedValue;
  return `width:${currentValue}px !important; max-width: ${currentValue}px !important; min-width: ${currentValue}px !important`;
};

export const resizeByStyle = (elem, attrs) => (length) => {
  const cssValue = getCSSValues(length, attrs);
  if (attrs.isParent) {
    elem.parent().attr('style', cssValue);
  } else {
    elem.attr('style', cssValue);
  }

  // will make the root component 100% width
  if (attrs.isParent) {
    elem.attr('style', 'width: 80% !important');
  }
};
