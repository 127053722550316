import { DATE_HOUR_TEXT_FORMAT } from 'Common/constants/dateConfigs';
import template from './referralNoteItem.html';
import './style.scss';

export default {
  template,
  controller: function ReferralNoteItemCtrl() {
    this.dateHourFormat = DATE_HOUR_TEXT_FORMAT;
  },
  controllerAs: 'vm',
  bindings: {
    note: '<',
    initialsIconColor: '<',
    onClick: '&',
  },
};
