export const LIABILITY_TYPE = {
  STUDENT_LOAN: 8,
  CREDIT_CARD: 7,
  PERSONAL_LOAN: 18,
  HOME_LOAN: 22,
  BUSINESS_LOAN: 23,
  MORTGAGE: 21,
  CAR_LOAN: 19,
  LINE_OF_CREDIT: 11,
  LOAN_AS_GUARANTOR: 12,
  OVERDRAFT: 17,
  STORE_CARD: 20,
  CHARGE_CARD: 2,
  LEASE: 10,
  LOAN: 15,
};

export const LIABILITY_LABEL = {
  CREDIT_CARD: 'Credit Card',
  PERSONAL_LOAN: 'Personal Loan',
  HOME_LOAN: 'Home Loan',
  BUSINESS_LOAN: 'Business Loan',
  MORTGAGE: 'Mortgage',
  CAR_LOAN: 'Car Loan',
  LINE_OF_CREDIT: 'Line of Credit',
  OVERDRAFT: 'Overdraft',
  STORE_CARD: 'Store Card',
  CHARGE_CARD: 'Charge Card',
  LOAN_AS_GUARANTOR: 'Loan as Guarantor',
};

export const IS_LOANS_LABEL = [
  LIABILITY_LABEL.PERSONAL_LOAN,
  LIABILITY_LABEL.HOME_LOAN,
  LIABILITY_LABEL.BUSINESS_LOAN,
  LIABILITY_LABEL.MORTGAGE,
  LIABILITY_LABEL.CAR_LOAN,
  LIABILITY_LABEL.LINE_OF_CREDIT,
  LIABILITY_LABEL.LOAN_AS_GUARANTOR,
];

export const IS_LOANS = [
  LIABILITY_TYPE.PERSONAL_LOAN,
  LIABILITY_TYPE.HOME_LOAN,
  LIABILITY_TYPE.BUSINESS_LOAN,
  LIABILITY_TYPE.MORTGAGE,
  LIABILITY_TYPE.CAR_LOAN,
  LIABILITY_TYPE.LINE_OF_CREDIT,
  LIABILITY_TYPE.LOAN_AS_GUARANTOR,
];

export const IS_LIMITS = [
  LIABILITY_TYPE.CHARGE_CARD,
  LIABILITY_TYPE.CREDIT_CARD,
  LIABILITY_TYPE.STORE_CARD,
];

export const IS_AU_LIMITS = [
  ...IS_LIMITS,
  LIABILITY_TYPE.MORTGAGE,
  LIABILITY_TYPE.STUDENT_LOAN,
  LIABILITY_TYPE.LOAN,
];

export const IS_INTERESTS = [LIABILITY_TYPE.OVERDRAFT];

export const IS_NEXT_PROP = [
  LIABILITY_TYPE.LEASE,
  LIABILITY_TYPE.LINE_OF_CREDIT,
  LIABILITY_TYPE.LOAN,
  LIABILITY_TYPE.PERSONAL_LOAN,
  LIABILITY_TYPE.CAR_LOAN,
  LIABILITY_TYPE.MORTGAGE,
];
