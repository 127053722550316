import { COLOR_PATTERN } from 'Common/constants/pattern';
import { toastWarning } from 'Common/utilities/alert';

class BrandColorSelectorCtrl {
  constructor($timeout, $rootScope) {
    'ngInject';

    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
  }

  $onInit() {
    this.display = {};
    this.colorPickerOptions = {
      showInput: false,
      showInitial: false,
      allowEmpty: false,
      preferredFormat: 'hex',
      containerClassName: 'custom-spectrum-color-picker',
      appendTo: this.$rootScope.appBodyElement,
    };
  }

  $onChanges(changes) {
    const { color } = changes;

    if (color && color.currentValue) {
      this.initialColor = color.currentValue;
      this.displayColor = color.currentValue;
    }
  }

  validateInput(color) {
    const isHex = new RegExp(COLOR_PATTERN.HEX).test(color);
    if (!isHex) {
      this.displayColor = this.initialColor;
      toastWarning('Input should be hex', 'Invalid pattern');
      return;
    }
    this.selectColor(color);
  }

  selectColor(color) {
    this.color = color;
    this.updateColorDisplay(color);
    const allowSave = color && this.initialColor && color !== this.initialColor;
    if (!allowSave) {
      return;
    }
    this.initialColor = color;
    this.onSelect({ color });
  }

  updateColorDisplay(color) {
    this.colorTimeout = this.$timeout(() => {
      this.displayColor = color;
    }, 300);
  }

  $onDestroy() {
    this.colorTimeout && this.$timeout.cancel(this.colorTimeout);
  }
}

export default BrandColorSelectorCtrl;
