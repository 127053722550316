import template from './loanDependant.html';
import controller from './loanDependantCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    dependants: '=',
    selectedClientName: '=',
    listDependants: '<',
    dependantsFamilyDropdown: '<',
    involvedPartyPerson: '<',
    selectedPartyType: '<',
    isAddingDependants: '<',
    loanAppId: '<',
    dependentsWithOneClientAsParent: '<',
    companyRelatedPartyModal: '&',
    resetSearch: '&',
    calculateAge: '&',
    calculateDob: '&',
    addDependants: '&',
    refreshOnDeleteDependant: '&',
  },
};
