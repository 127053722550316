export const BUSINESS_UNIT = {
  EMOCA: 8,
  LM_BROKER_SERVICES: 10,
};

export const ORG_TYPE = {
  MEMBER: 3,
};

export const ADVISER_CONTACT_PANEL = {
  ADDRESS: 4,
  ADDITIONAL_DETAILS: 5,
};
