import template from './importantDocuments.html';
import controller from './importantDocumentsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {},
};
