export const appendPropertyToExistingObject = (data, propName, value) => {
  const isCompleteParams = data && propName && value;
  return isCompleteParams ? { ...data, [propName]: value } : data;
};

export const getFirstElementProperty = (list, propName) => {
  const validArray = list && list.length;
  return validArray ? list[0][propName] : undefined;
};

export const getListDefaultValue = (list, propName, defaultValue) => {
  const validArray = list && list.length;
  if (!validArray) {
    return defaultValue;
  }

  const isDefaultInList = list.some((item) => item[propName] === defaultValue);
  return isDefaultInList ? defaultValue : list[0][propName];
};
