export default class LeadOpportunitiesService {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;
  }

  getLabel(isSingular) {
    if (isSingular) {
      return this.uiService.viewOfAfileTurnedOn ? 'Deal' : 'Opportunity';
    }
    return this.uiService.viewOfAfileTurnedOn ? 'Deals' : 'Opportunities';
  }
}
