import { BROKER_NOTES_MAX_LENGTH } from 'Common/constants/loanAppBrokerNotes';

class ToggleNotesCtrl {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;
    this.BROKER_NOTES_MAX_LENGTH = BROKER_NOTES_MAX_LENGTH;
  }

  changeTitle() {
    if (typeof this.isShow === 'undefined') {
      this.isShow = true;
    }
    this.title = ` ${this.isShow ? 'Show' : 'Hide'} ${this.label}`;
  }

  toggle() {
    this.isShow = !this.isShow;
    this.changeTitle();
  }

  $onInit() {
    this.isShow = true;
  }

  $onChanges(changes) {
    const { label } = changes;
    if (label) {
      this.changeTitle();
    }
  }
}

export default ToggleNotesCtrl;
