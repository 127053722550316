import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import {
  DEFAULT_FILTERS,
  TASK_PAGE_TABLE_COLUMNS,
  TASK_COLUMN_KEYS,
  MULTI_TASK_STATUS,
  DUE_DATE_TYPES,
} from 'Common/constants/tasks';
import { DATE_PICKER_UTC_MOMENT_FORMAT } from 'Common/constants/dateConfigs';
import {
  defaultMultiselectItemValues,
  tickAllOptions,
  getAllTicked,
  getSelectedValues,
  appendAllOption,
  appendClearOption,
  tickSavedOptions,
} from 'Common/utilities/multiselect';
import { convertPartiesToGrouped } from 'Common/utilities/involvedParties';
import { validateDate, formatDate } from 'Common/utilities/date';

export default class TasksTableFilterCtrl {
  constructor(
    mycrmLookupService,
    contactService,
    currentUserService,
    dealViewTaskService,
    loanScenarioModelService,
    clientInfoService,
    taskDetailService,
  ) {
    'ngInject';

    this.mycrmLookupService = mycrmLookupService;
    this.contactService = contactService;
    this.currentUserService = currentUserService;
    this.dealViewTaskService = dealViewTaskService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.clientInfoService = clientInfoService;
    this.taskDetailService = taskDetailService;
  }

  $onInit() {
    this.multiSelectKey = {
      STATUS: 'taskStatus',
      ADVISER: 'adviser',
      ASSIGNED: 'assignedTo',
    };

    this.newTaskDropdownOptions = [
      { text: 'New Task', action: 'onNewTaskSelect' },
      { text: 'New Templated Task', action: 'onNewTemplatedTaskSelect' },
    ];

    this.taskStatus = { list: [], selected: [] };
    this.assignedTo = { list: [], selected: [] };
    this.adviser = { list: [], selected: [] };
    this.columnsChecklist = [];
    this.isCorporate = this.currentUserService.isCorporate;
    this.allowAllAdviserFilter = !this.isCorporate;
    this.taskTableColumns = TASK_PAGE_TABLE_COLUMNS.map((col) => ({
      ...col,
      isSortable: true,
    }));

    this.getFiltersList();
    this.getInvolvedParties();
    this.taskDetailService.handler.reloadClients = () =>
      this.getInvolvedParties();
    this.taskDetailService.handler.saveSortData = (sortData) =>
      this.saveSortData(sortData);
  }

  getFiltersList() {
    const GET = { ADVISER: 0, ASSIGNED: 1, FILTER: 2, COLUMN: 3, LENDER: 4 };

    const getAdviserParam = { includeInactive: true };
    const getTaskFilterParam = { isDealTaskList: !!this.loanApplicationId };

    const adviserListGet = this.mycrmLookupService.getAdvisers(getAdviserParam);
    const assignedToListGet = this.contactService.taskAdviserListGet(true);
    const taskFilterGet = this.dealViewTaskService.getFiltersSettings(
      getTaskFilterParam,
    );
    const columnSettingsGet = this.dealViewTaskService.getColumnsSettings();
    const lenderListGet = this.contactService.lenderListGet(undefined, 1);

    Promise.all([
      adviserListGet,
      assignedToListGet,
      taskFilterGet,
      columnSettingsGet,
      lenderListGet,
    ])
      .then((res) => {
        const adviserList = res[GET.ADVISER] || [];
        const taskAdvisers = get(res, `[${[GET.ASSIGNED]}].data`, []);
        this.savedFilters =
          get(res, `[${[GET.FILTER]}].data`) || DEFAULT_FILTERS;
        const savedColumnSettings = get(res, `[${[GET.COLUMN]}].data`, []);
        const lendersList = get(res, `[${[GET.LENDER]}].data`, []);

        this.createdByList = taskAdvisers;
        this.setStatusList();
        this.setAdviserList(adviserList);
        this.setAssignedToList(taskAdvisers);
        this.setColumnChecklist(savedColumnSettings);
        this.setLendersList(lendersList);

        this.filters = {
          taskStatus: this.taskStatus.selected.map((item) => item.value),
          assignedTo: this.assignedTo.selected.map((item) => item.value),
          adviser: this.adviser.selected.map((item) => item.value),
          createdBy: this.getCreatedByValue(),
          lenderId: this.getInitialLenderValue(),
          dueDate: this.getInitialDueDateValues(),
          createdDate: this.getInitialCreatedDateValues(),
          clientFamilyId: 0,
          sortColumn:
            this.savedFilters.sortColumn || DEFAULT_FILTERS.sortColumn,
          sortOrder: this.savedFilters.sortOrder || DEFAULT_FILTERS.sortOrder,
        };
        this.onTaskFilterChange({ data: this.filters });
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }

  setStatusList() {
    const dueDateTypes = this.savedFilters.dueDateType.split(',');
    const savedStatus = dueDateTypes.filter((type) =>
      MULTI_TASK_STATUS.some((item) => item.value === type),
    );
    this.taskStatus.list = tickSavedOptions(
      [...MULTI_TASK_STATUS],
      savedStatus,
      true,
    );
    this.taskStatus.selected = getAllTicked(this.taskStatus.list);
    this.completedStatusIdx = MULTI_TASK_STATUS.findIndex(
      (item) => item.value === DUE_DATE_TYPES.COMPLETED,
    );
  }

  setAdviserList(adviserList = []) {
    const formattedList = adviserList.map((adviser) =>
      defaultMultiselectItemValues(adviser.fullName, adviser.familyId),
    );
    const completeAdviserList = this.allowAllAdviserFilter
      ? appendAllOption(formattedList)
      : appendClearOption(formattedList, 'Select');

    this.adviser.list = tickSavedOptions(
      completeAdviserList,
      this.savedFilters.clientAdviserIds,
    );
    this.adviser.selected = getAllTicked(this.adviser.list);
  }

  setAssignedToList(assignedToList = []) {
    const formattedList = assignedToList.map((assigned) =>
      defaultMultiselectItemValues(assigned.DisplayName, assigned.BrokerID),
    );

    const { assignedToAdviserIds = '' } = this.savedFilters;
    const savedAssigned = assignedToAdviserIds.split(',').filter((id) => id);

    const tickedSavedList = formattedList.map((adviser) => {
      const adviserId = adviser.value;
      const useSavedFilter = !!savedAssigned.length && !this.familyId;

      if (useSavedFilter) {
        const isAllSaved = Number(savedAssigned[0]) === 0 && adviserId === 0;
        adviser.ticked =
          isAllSaved ||
          !!savedAssigned.find((item) => Number(item) === adviserId);
      } else {
        adviser.ticked = adviserId === this.currentUserService.familyId;
      }
      return adviser;
    });

    const tickedItemsOnly = getAllTicked(tickedSavedList);
    this.assignedTo.list = this.isCorporate ? tickedItemsOnly : tickedSavedList;
    this.assignedTo.selected = tickedItemsOnly;
  }

  setColumnChecklist(savedColumnSettings) {
    if (!this.showColumnsFilter) {
      return;
    }

    this.columnsChecklist = this.taskTableColumns.map((col) => {
      const savedData =
        savedColumnSettings.find((item) => item.columnValue === col.value) ||
        {};
      return { ...col, checked: savedData.isShow };
    });
    this.onTableColumnsChange({ data: this.columnsChecklist });
  }

  setLendersList(lendersList) {
    const allOption = { ProviderId: '0', ProviderName: 'ALL' };
    this.lendersList = [allOption, ...lendersList];
  }

  getCreatedByValue() {
    const createdBy =
      this.familyId || this.isCorporate || !this.savedFilters.createdByBrokerId
        ? DEFAULT_FILTERS.createdByBrokerId
        : this.savedFilters.createdByBrokerId;

    return this.isColumnChecked(TASK_COLUMN_KEYS.CREATED_BY) ? createdBy : '';
  }

  getInitialLenderValue() {
    const savedLender = this.lendersList.find(
      (lender) => lender.ProviderId === this.savedFilters.lenderId,
    );
    return this.isColumnChecked(TASK_COLUMN_KEYS.LENDER)
      ? get(savedLender, 'ProviderId', DEFAULT_FILTERS.lenderId)
      : DEFAULT_FILTERS.lenderId;
  }

  getInitialDueDateValues() {
    return {
      start: validateDate(this.savedFilters.dueDateStart),
      end: validateDate(this.savedFilters.dueDateEnd),
    };
  }

  getInitialCreatedDateValues() {
    return {
      start: validateDate(this.savedFilters.createdDateFrom),
      end: validateDate(this.savedFilters.createdDateTo),
    };
  }

  onColumnItemCheck({ data }) {
    const item = { ...data, checked: !data.checked };
    this.columnsChecklist = this.columnsChecklist.map((col) =>
      col.value === item.value ? { ...col, checked: item.checked } : col,
    );

    this.saveColumnItemSettings(item);
    this.onTableColumnsChange({ data: this.columnsChecklist });
  }

  saveColumnItemSettings(item) {
    const data = { columnValue: item.value, isShow: item.checked };
    this.dealViewTaskService.setColumnsSettings(data);
  }

  getFormattedDate(date) {
    if (!date) {
      return null;
    }
    return formatDate(DATE_PICKER_UTC_MOMENT_FORMAT)(date);
  }

  saveSortData(sortData = {}) {
    this.filters = { ...this.filters, ...sortData };
    this.saveFilterData();
  }

  getFilterDataToSave() {
    const isDealTaskList = !!this.loanApplicationId;

    const defaultData = {
      dueDateType: this.filters.taskStatus.join(),
      assignedToAdviserIds: this.filters.assignedTo.join(),
      clientAdviserIds: this.filters.adviser.join(),
      createdByBrokerId: this.filters.createdBy,
      lenderId: this.filters.lenderId,
      dueDateStart: this.getFormattedDate(this.filters.dueDate.start),
      dueDateEnd: this.getFormattedDate(this.filters.dueDate.end),
      isDealTaskList,
      sortColumn: this.filters.sortColumn,
      sortOrder: this.filters.sortOrder,
    };
    const dealFilterData = {
      ...defaultData,
      createdDateFrom: this.getFormattedDate(this.filters.createdDate.start),
      createdDateTo: this.getFormattedDate(this.filters.createdDate.end),
    };

    return isDealTaskList ? dealFilterData : defaultData;
  }

  saveFilterData() {
    const filtersData = this.getFilterDataToSave();
    this.dealViewTaskService.setFiltersSettings(filtersData);
  }

  updateMultiselectFilter(key) {
    this.filters[key] = getSelectedValues(this[key].list);
    this.updateFilter();
  }

  updateFilter(savedFilterKey, value) {
    if (this.filters && savedFilterKey) {
      this.savedFilters[savedFilterKey] = value;
    }
    this.saveFilterData();
    this.onTaskFilterChange({ data: this.filters });
  }

  isColumnChecked(columnKey) {
    const column = this.columnsChecklist.find((col) => col.value === columnKey);
    return column ? column.checked : false;
  }

  onMultiSelectAll(key) {
    const mselectObject = this[key];
    mselectObject.list = tickAllOptions(mselectObject.list, false);
    mselectObject.list[0].ticked = true;
    mselectObject.selected = mselectObject.list[0];
    this.updateMultiselectFilter(key);
  }

  onMultiSelectItem(selection, key) {
    const mselectObject = this[key];
    const list = mselectObject.list;

    const selectDefault =
      !list.length ||
      !selection.value ||
      selection.value === DUE_DATE_TYPES.ALL;
    if (selectDefault) {
      this.onMultiSelectAll(key);
      return;
    }

    if (key === this.multiSelectKey.STATUS) {
      const multiselect = this.taskStatus;
      if (selection.value === DUE_DATE_TYPES.COMPLETED) {
        multiselect.list = tickAllOptions(multiselect.list, false);
        multiselect.list[this.completedStatusIdx].ticked = true;
        multiselect.selected = multiselect.list[this.completedStatusIdx];
        return this.updateMultiselectFilter(key);
      } else {
        multiselect.list[this.completedStatusIdx].ticked = false;
        const completeIdx = multiselect.selected.findIndex(
          (item) => item.value === DUE_DATE_TYPES.COMPLETED,
        );
        if (completeIdx !== -1) {
          multiselect.selected[completeIdx].ticked = false;
        }
      }
    }

    const allIdx = list.findIndex(
      (item) => !item.value || item.value === DUE_DATE_TYPES.ALL,
    );
    const isAllSelected = allIdx !== -1;
    if (isAllSelected) {
      if (get(mselectObject, `selected[${allIdx}]`)) {
        mselectObject.selected[allIdx].ticked = false;
      }
      list[allIdx].ticked = false;
    }

    const isNoneSelected = !getAllTicked(list).length;
    if (isNoneSelected) {
      this.onMultiSelectAll(key);
      return;
    }

    this.updateMultiselectFilter(key);
  }

  onColumnItemChange({ data }) {
    this.onColumnItemCheck({ data });

    const currentCheckedValue = !data.checked;
    if (data.value === TASK_COLUMN_KEYS.CREATED_BY) {
      this.filters.createdBy = currentCheckedValue
        ? this.savedFilters.createdByBrokerId ||
          DEFAULT_FILTERS.createdByBrokerId
        : DEFAULT_FILTERS.createdByBrokerId;
      this.onTaskFilterChange({ data: this.filters });
    } else if (data.value === TASK_COLUMN_KEYS.LENDER) {
      this.filters.lenderId = currentCheckedValue
        ? this.savedFilters.lenderId
        : DEFAULT_FILTERS.lenderId;
      this.onTaskFilterChange({ data: this.filters });
    }
  }

  onMenuItemClick({ data }) {
    typeof this[data] === 'function' && this[data]();
  }

  onNewTaskSelect() {
    this.onNewTask();
  }

  onNewTemplatedTaskSelect() {
    this.onNewTemplatedTask();
  }

  onDateSpanChange({ data, key }) {
    if (!data) {
      return;
    }

    const { start, end } = data;
    const completeDateRange = !!(start && end);
    const clearedDateRange = !start && !end;
    if (completeDateRange || clearedDateRange) {
      this.filters[key] = data;
      this.savedFilters.dueDateStart = start;
      this.savedFilters.dueDateEnd = end;
      this.saveFilterData();
      this.onTaskFilterChange({ data: this.filters });
    }
  }

  getInvolvedParties() {
    const clientDefault = {
      familyId: 0,
      displayName: 'All clients',
    };
    if (!this.loanApplicationId) {
      this.clients = [clientDefault];
      return;
    }
    return this.loanScenarioModelService
      .getApplicants({
        loanScenarioId: this.loanApplicationId,
      })
      .then(({ data: involvedParties }) => {
        const { borrowers = [], guarantors = [] } = convertPartiesToGrouped({
          clientInfoService: this.clientInfoService,
          involvedParties,
        });
        const clientDefault = {
          familyId: 0,
          displayName: 'All clients',
        };
        this.clients = [clientDefault, ...borrowers, ...guarantors];
      });
  }
}
