import template from './linkGmailSyncModal.html';
import controller from './linkGmailSyncModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    userAuthUrl: '<',
    gmailSyncStatusFlag: '<',
    onUnlinkSuccess: '&',
  },
};
