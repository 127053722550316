import SmsEditor from 'Common/services/smsEditor';
import {
  getTextCharacterLimit,
  baseCharacterLimit,
} from 'Common/utilities/smsCharacterLimit';

export default class smsEditorCtrl {
  $onInit() {
    if (typeof this.isRecipient === 'undefined') {
      this.isRecipient = true;
    }
    if (typeof this.isSubject === 'undefined') {
      this.isSubject = true;
    }
    if (typeof this.isSend === 'undefined') {
      this.isSend = true;
    }
    if (typeof this.btnSendText === 'undefined') {
      this.btnSendText = 'Send';
    }

    this.smsEditor = new SmsEditor(this.smsObj);

    this.characterCount = `0 / ${baseCharacterLimit}`;

    if (this.autoSelectAll) {
      this.recipientsChoices.forEach((recipient) =>
        this.selectRecipient(recipient),
      );
    }

    if (typeof this.enableTextArea === 'undefined') {
      this.enableTextArea = true;
    }
  }

  selectRecipient(recipient) {
    this.smsEditor.onSelectRecipient(recipient);
    this.onSmsChange({ smsModel: this.smsEditor.smsObj });

    if (!this.allowCustom && typeof recipient === 'string') {
      this.removeRecipient(recipient);
    }
  }

  removeRecipient(recipient) {
    this.smsEditor.onRemoveRecipient(recipient);
    this.onSmsChange({ smsModel: this.smsEditor.smsObj });
  }

  updateBody(body) {
    this.smsEditor.onUpdateBody(body);

    this.onSmsChange({ smsModel: this.smsEditor.smsObj });
    this.getCharacterCount();
  }

  getCharacterCount() {
    const currentCharacterCount =
      (this.smsEditor &&
        this.smsEditor.smsObj.body &&
        this.smsEditor.smsObj.body.length) ||
      0;
    const characterLimit = getTextCharacterLimit(
      baseCharacterLimit,
      currentCharacterCount,
    );
    this.characterCount = `${currentCharacterCount} / ${characterLimit}`;
  }
}
