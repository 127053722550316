import template from './questionConfirmMessage.html';
import controller from './questionConfirmMessageCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    riskList: '<',
    riskData: '<',
    loanId: '<',
    familyId: '<',
    onChangeAnswer: '&',
    parentToChildNotificationRegistration: '&',
  },
};
