import template from './securityTransactionTypeSelection.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    transactionType: '<',
    onDeleteSecurity: '&',
    onOpenSecurityModal: '&',
    onChangeTransactionType: '&',
  },
};
