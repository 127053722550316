import moment from 'moment';
import { LOAN_STATUS_CATEGORY } from 'Common/constants/loanStatusCategory';
import { DD_MM_YYYY_MOMENT_FORMAT } from 'Common/constants/dateConfigs';
import { customStringShortDate } from 'Common/utilities/date';
import { displayError } from 'Common/utilities/alert';
import { CONFLICT } from '../constants/httpStatusCodes';

export function processDates(value) {
  /**
   * Null and TRUE  = Estimated Date
   * FALSE = Actual Date
   * */
  value.ExpiryHumanize =
    value.ExpiryDate && value.NextFixedExpiry ? 'Next Fixed Expiry: ' : null;

  value.IsEstimated =
    typeof value.IsEstimated === 'undefined' || value.IsEstimated;
  if (value.SettlementDate) {
    value.SettlementDate = moment.parseZone(value.SettlementDate)._d;
  }
  if (value.ApprovalDate) {
    value.ApprovalDate = moment.parseZone(value.ApprovalDate)._d;
  }
  if (value.SubmittedDate) {
    value.SubmittedDate = moment.parseZone(value.SubmittedDate)._d;
  }
  if (value.FinanceDate) {
    value.FinanceDate = moment.parseZone(value.FinanceDate)._d;
  }
  if (value.PreApprovalExpiry) {
    value.PreApprovalExpiry = moment.parseZone(value.PreApprovalExpiry)._d;
  }
  if (value.EstimatedSettlementDate) {
    value.EstimatedSettlementDate = moment.parseZone(
      value.EstimatedSettlementDate,
    )._d;
  }
}

export function getLoanCategory(value) {
  const { LoanStatusCategory } = value.LoanStatus;
  if (LoanStatusCategory) {
    const isLead = LoanStatusCategory === LOAN_STATUS_CATEGORY.LEAD;
    const isExisting = LoanStatusCategory === LOAN_STATUS_CATEGORY.EXISTING;
    const isInProgress =
      LoanStatusCategory === LOAN_STATUS_CATEGORY.IN_PROGRESS;
    const isOpportunity =
      LoanStatusCategory === LOAN_STATUS_CATEGORY.OPPORTUNITY ||
      !!value.IsOpportunity;
    const isPrevious = LoanStatusCategory === LOAN_STATUS_CATEGORY.PREVIOUS;

    // This function also implies you can be in multiple categories at once you can't
    return {
      isExisting,
      isInProgress,
      isOpportunity,
      isPrevious,
      isLead,
    };
  }
}

export function checkLender(loan) {
  return loan.Lender.LenderName && loan.Lender.LenderId;
}

export function processLoanPurpose(value) {
  const tempText = value.LoanPurpose;
  value.LoanPurpose = [];
  value.LoanPurpose = tempText.split(',');
}

export function processExpiryWarningMessage(loan, startDate) {
  const expiryDate = loan.ExpiryDate || loan.NextFixedExpiry;

  if (expiryDate) {
    const baseDate = moment(
      customStringShortDate(startDate || new Date()),
      DD_MM_YYYY_MOMENT_FORMAT,
    );
    const newExpiryDate = moment(
      customStringShortDate(new Date(expiryDate)),
      DD_MM_YYYY_MOMENT_FORMAT,
    );
    const days = newExpiryDate.diff(baseDate, 'days');

    if (days <= 90) {
      loan.expiryWarning = 'You have an upcoming expiry for this loan service';
    }
  }

  return loan;
}

export const movingStatusError = (
  statusCode,
  previousStatus = '[Unknown]',
  currentStatus = '[Unknown]',
) => {
  let message = `Please Contact Support as moving from ${previousStatus} to ${currentStatus} failed.`;
  if (statusCode === CONFLICT) {
    message =
      'Your pipeline has not been fully configured yet, please ask your Principal Adviser to set it up for you.';
  }
  displayError(message, false, 'Moving status failed!');
};
