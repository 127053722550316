export default class PrettyImgCtrl {
  $onChanges(changes) {
    const lendingDetails = changes?.lendingDetails?.currentValue;
    if (lendingDetails) {
      this.setDisplayAmount(lendingDetails);
    }
  }

  setDisplayAmount(lendingDetails) {
    const { LoanAmount, ProposedLoanAmount } = lendingDetails;
    this.lendingDetails = {
      ...this.lendingDetails,
      DisplayAmount: LoanAmount || ProposedLoanAmount,
    };
  }
}
