import angular from 'angular';
import { COLOR } from 'Common/constants/colors';

class CKEditorConfigService {
  constructor() {}

  getCommonEmailEditorConfig() {
    return {
      language: 'en',
      allowedContent: true,
      uiColor: '#DFD6E4',
      height: 300,
      resize_enabled: false,
      line_height: '1px',
      removePlugins: 'elementspath',
      toolbar: [
        { items: ['BGColor'] },
        { items: ['JustifyLeft'] },
        { items: ['BulletedList', '-'] },
        { items: ['Link'] },
        { items: ['Image'] },
        { items: ['Table'] },
        { items: ['Paste', '-'] },
        { items: ['Smiley'] },
        { items: ['-'] },
        '/',
        { items: ['Format'] },
        { items: ['Font'] },
        { items: ['FontSize', '-'] },
        { items: ['Bold'] },
        { items: ['Italic'] },
        { items: ['Underline'] },
        { items: ['Subscript'] },
        { items: ['Superscript'] },
      ],
    };
  }

  getCustomerCareSelectConfig(height = 487) {
    return {
      language: 'en',
      allowedContent: true,
      line_height: '1px',
      uiColor: COLOR.CK_EDITOR_BUTTONS,
      height,
      resize_enabled: false,
      removePlugins: 'elementspath',
      toolbar: [
        { items: ['Format'] },
        { items: ['Font'] },
        {
          items: ['FontSize', 'Bold', 'Italic', 'JustifyLeft', 'BulletedList'],
        },
        { items: ['Link', 'Image', 'Table', 'Paste'] },
      ],
    };
  }

  getDefaultContent() {
    return `<div style="font-family: Arial;"><div><div><span style="font-family: Arial; font-size: 10pt;">Hi </span><span style="font-family: Arial; font-size: 10pt;">[Preferred Name]</span></div></div><br /><div>&nbsp;</div><div><span style="font-family: Arial; font-size: 10pt;">Best regards,</span></div><div><span style="font-family: Arial; font-size: 10pt;">[Your Name]</span></div></div><div>&nbsp;</div><div>[Email Signature]</div>`;
  }

  getCustomerCareEmailEditorConfig() {
    return {
      language: 'en',
      allowedContent: true,
      uiColor: '#DFD6E4',
      line_height: '1px',
      height: 300,
      resize_enabled: false,
      removePlugins: 'elementspath',
      toolbar: [
        { items: ['BGColor'] },
        { items: ['JustifyLeft'] },
        { items: ['BulletedList', '-'] },
        { items: ['Link'] },
        { items: ['Image'] },
        { items: ['Table'] },
        { items: ['-'] },
        '/',
        { items: ['Format'] },
        { items: ['Font'] },
        { items: ['FontSize', '-'] },
        { items: ['Bold'] },
        { items: ['Italic'] },
        { items: ['Underline'] },
      ],
    };
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .factory('ckEditorConfigService', () => new CKEditorConfigService());
