import { COLOR } from 'Common/constants/colors';

const toolBarElements = [
  {
    items: ['Bold'],
  },
  {
    items: ['BulletedList'],
  },
  {
    items: ['Link'],
  },
  {
    items: ['Image'],
  },
  {
    items: ['Table'],
  },
  {
    items: ['Paste'],
  },
  {
    items: ['Maximize'],
  },
];

const toolBarElementsNoTable = [
  {
    items: ['Bold'],
  },
  {
    items: ['BulletedList'],
  },
  {
    items: ['Link'],
  },
  {
    items: ['Image'],
  },
  {
    items: ['Paste'],
  },
  {
    items: ['Maximize'],
  },
];

export const EDITOR_OPTIONS = {
  language: 'en',
  allowedContent: true,
  uiColor: COLOR.CK_EDITOR_BUTTONS,
  height: 200,
  resize_enabled: false,
  removePlugins: 'elementspath',
  toolbar: toolBarElements,
};

export const EDITOR_OPTIONS_NO_TABLE = {
  ...EDITOR_OPTIONS,
  toolbar: toolBarElementsNoTable,
};

export const NOTES_EDITOR_OPTIONS = {
  ...EDITOR_OPTIONS,
  toolbar: [
    {
      items: [
        'BulletedList',
        '|',
        'NumberedList',
        '|',
        'Outdent',
        '|',
        'Indent',
        '-',
      ],
    },
    { items: ['Bold', '|', 'Italic'] },
    { items: ['Format'] },
    { items: ['Table'] },
    { items: ['Paste'] },
    { items: ['Maximize'] },
  ],
};
