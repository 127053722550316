export const getLoanAmount = (amount) => {
  return amount < 1 ? null : amount;
};
export const getLenderId = (lenderId) => {
  return lenderId ? String(lenderId) : null;
};
export const getABSPurposeId = (purposeId) => {
  return purposeId ? String(purposeId) : null;
};
export const getReasonForSelectedLender = (reason) => {
  return !reason ? null : reason;
};
export const getReasonForLoanFacility = (loanFacility) => {
  return !loanFacility ? null : loanFacility;
};
