import { COMPANY_TYPES } from 'Common/constants/partyTypes';

export const getCompanyId = (type) => {
  switch (type) {
    case COMPANY_TYPES.COMPANY_STRING:
      return COMPANY_TYPES.COMPANY;
    case COMPANY_TYPES.TRUST_STRING:
      return COMPANY_TYPES.TRUST;
    case COMPANY_TYPES.PARTNERSHIP_STRING:
      return COMPANY_TYPES.PARTNERSHIP;
    case COMPANY_TYPES.SMSF_STRING:
      return COMPANY_TYPES.SMSF;
    default:
  }
};
