import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('onDragFiles', [
    '$window',
    function onDragFiles($window) {
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const onDragFilesEnter = (show = false, scope) => {
        scope.showDragBox = show;
      };
      return {
        link(scope) {
          $window.addEventListener('dragenter', () => {
            onDragFilesEnter(true, scope);
          });
        },
      };
    },
  ]);
