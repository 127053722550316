import { REPAYMENT_FREQUENCY } from 'Common/constants/repayment';

export const HALF_MONTHLY_INITIALS = 'hm';

export const getFrequencyInitials = (frequencyName) => {
  if (frequencyName === REPAYMENT_FREQUENCY.HALF_MONTHLY) {
    return HALF_MONTHLY_INITIALS;
  }
  return frequencyName.charAt(0);
};
