import template from './mycrmInput.html';
import controller from './mycrmInputCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    name: '<',
    label: '<',
    initialValue: '<',
    mask: '<',
    loading: '<',
    error: '<',
    onInputChange: '&',
    onInputBlur: '&',
    onKeyDown: '&',
  },
};
