import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';

const INACTIVE_GROUP_ID = 2;

export const orgPipelineStatusForUI = (entity) => {
  const pipelineCategory = entity.PipelineCategory || {};
  const loanStatusGroup = entity.LoanStatus || {};
  const pipelineType = entity.PipelineType || {};
  return {
    id: entity.StatusId,
    groupId: entity.StatusGroupId,
    name: entity.StatusName,
    groupName: entity.StatusGroupName,
    sortOrder: entity.SortOrder,
    isActive: entity.IsActive,
    isSystemStatus: entity.IsSystemStatus,
    isApplication: entity.IsApplication,
    isTriggerMoveCardToApp: entity.IsTriggerMoveCardToApp,
    isTriggerMoveCardToLeadOppNPW:
      entity.LoanStatusId === LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH,
    isConversionStatus: entity.IsConversionStatus,
    loanStatusId: entity.LoanStatusId,
    pipelineStatusId: entity.PipelineStatusId,
    pipelineCategoryId: pipelineCategory.Id,
    pipelineCategoryName: pipelineCategory.Name,
    pipelineTypeName: pipelineType.Name,
    loanStatusGroupName: loanStatusGroup.LoanStatusCategory,
    brokerBrandingId: entity.BrokerBrandingId,
  };
};

export const orgPipelineStatusToLoanStatusEntity = (orgStatus) => ({
  Name: orgStatus.name,
  Value: orgStatus.loanStatusId.toString(),
  GroupId:
    orgStatus.loanStatusGroupId && orgStatus.loanStatusGroupId.toString(),
  GroupName: orgStatus.loanStatusGroupName,
  OrgPipelineStatusID: orgStatus.id,
  IsInactive: orgStatus.groupId === INACTIVE_GROUP_ID,
});

export const orgPipelineStatusToFileStatusEntity = (orgStatus) => ({
  StatusName: orgStatus.name,
  PipelineCategory: orgStatus.pipelineCategoryName,
  PipelineCategoryID: orgStatus.pipelineCategoryId,
  LoanStatusId: orgStatus.loanStatusId,
  PipelineStatusId: orgStatus.pipelineStatusId,
  OrgPipelineStatusID: orgStatus.id,
  isApplication: orgStatus.isApplication,
  isTriggerMoveCardToApp: orgStatus.isTriggerMoveCardToApp,
  isTriggerMoveCardToLeadOppNPW: orgStatus.isTriggerMoveCardToLeadOppNPW,
  StatusSequence: orgStatus.sortOrder,
});

export const orgPipelineStatusToPipelineStatusEntity = (orgStatus) => {
  let pipelineStatusId = orgStatus.pipelineStatusId;
  if (!pipelineStatusId) {
    if (orgStatus.loanStatusId === LOAN_STATUS.EXISTING.SETTLED) {
      pipelineStatusId = PIPELINE_STATUS.SETTLED;
    } else if (
      orgStatus.loanStatusId === LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH
    ) {
      pipelineStatusId = PIPELINE_STATUS.NOT_PROCEEDED;
    }
  }
  return {
    PipelineStatusID: pipelineStatusId,
    OrgPipelineStatusID: orgStatus.id,
    PipelineCategoryID: orgStatus.pipelineCategoryId,
    CategoryName: orgStatus.pipelineCategoryName,
    PipelineStatus: orgStatus.name,
    BrokerBrandingID: orgStatus.brokerBrandingId,
    TypeName: orgStatus.pipelineTypeName,
    IsConversionStatus: orgStatus.isConversionStatus,
    IsSystemStatus: orgStatus.isSystemStatus,
  };
};
