import template from './leadMenu.html';

export default {
  template,
  bindings: {
    card: '<',
    isCorporateUser: '<',
    labelsSettings: '<',
    onLeadAddNote: '&',
    onDeletePipelineItem: '&',
    onRefreshLabelsSettings: '&',
    isShowTimeline: '<',
    conversionOptions: '<',
    onConvertCard: '&',
    hideManageLabels: '<',
    onCardClick: '&',
    onViewBorrower: '&',
    isMergeLeadAndOpportunities: '<',
    isBidViewFileEnabled: '<?',
  },
};
