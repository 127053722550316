export const onOtherBenefitSelected = ({ $scope }) => ({
  $timeout,
  toastWarning,
  OTHER,
}) => {
  $scope.autoCalcMode = (init = false) => {
    const {
      isPremiumAutoCalc,
      isPolicyFeeAutoCalc,
      providerId,
    } = $scope.insuranceDetailsInput;
    const autoCalcOn = isPremiumAutoCalc && isPolicyFeeAutoCalc;
    const otherInsurer = providerId === OTHER.INSURER;
    const quoteAndOther = autoCalcOn && otherInsurer;

    $scope.insurerTypeOther = otherInsurer;
    $scope.autoFocusMode = otherInsurer && !init;
    onFocusedFeeInputs();
    return () => {
      if (quoteAndOther && $scope.autoFocusMode) {
        toastWarning(
          `Need to Enter Amounts Manually for Insurer Type Other`,
          `auto-alculate disabled`,
        );

        $scope.setOverViewTab = $timeout(() => {
          $scope.insuranceModalTab = 'overview';
          $scope.insuranceDetailsInput.productId = OTHER.PRODUCT;
          $timeout.cancel($scope.setOverViewTab);
        }, 300);
      }
    };
  };

  const onFocusedFeeInputs = () => {
    $scope.policyFeeAutoFocus = $scope.autoFocusMode;
    $scope.totalPremiumAutoFocus = $scope.autoFocusMode;

    $scope.removedFocus = (input, value) => {
      $scope[`${input}AutoFocus`] = !!value;
    };
  };
};
