import { COLOR } from 'Common/constants/colors';

export const openAddModal = ($uibModal, props) => {
  return $uibModal.open({
    template: `<trade-reference-modal
                modal-instance="vm.modalInstance"
                reference-details="vm.props">
              </trade-reference-modal>`,
    size: 'md',
    backdrop: 'static',
    controller: 'CommonModalPlaceholderCtrl',
    controllerAs: 'vm',
    resolve: {
      props,
    },
  });
};

export const getDeleteModalSettings = () => {
  return {
    title: 'Are you sure?',
    text: 'This reference will be removed.',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: COLOR.GERALDINE,
    confirmButtonText: 'Yes, remove it!',
    closeOnConfirm: true,
  };
};
