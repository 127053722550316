class PassportService {
  mapPassportValue(data) {
    this.list = [];
    if (data && data.length) {
      this.list = data.map((item) => {
        item.isPassport = item.Name.includes('Passport');
        return item;
      });
    }
    return this.list;
  }

  checkIfDocumentIsPassport(id) {
    let isPassport = false;
    if (this.DocumentsType && this.DocumentsType.length) {
      const typeObj = this.DocumentsType.find((type) => {
        return String(type.Value) === String(id);
      });

      if (typeObj) {
        isPassport = typeObj.isPassport;
      }
    }
    return isPassport;
  }
}

export default PassportService;
