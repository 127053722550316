import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular.module('app').controller(
    'PipelineAppDetailModalCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function PipelineAppDetailModalCtrl(
      $scope,
      $uibModal,
      $uibModalInstance,
      contactService,
      utilitiesService,
      pipelineService,
      $state,
      toaster,
      appsStatusList,
      labels,
      list,
      workbench,
      pipelineSharedData,
    ) {
      $scope.workbench = workbench;
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.card = list;
      $scope.currentModalContent = 'card_info';

      $scope.lblObj = {};
      $scope.statusObj = {};

      $scope.createdStatuses = appsStatusList;
      $scope.labels = labels;
      $scope.card.list = `${$scope.card.AdviserFirstName} ${$scope.card.AdviserLastName}`;
      $scope.toggleDropdown = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.expandDroqdown = !$scope.expandDroqdown;
      };

      $scope.selectStatus = function (statusObj) {
        $scope.statusObj = statusObj;
        $scope.statusName = statusObj.PipelineStatus;
        $scope.card.PipelineStatus = $scope.statusName;
        $scope.pipelineConversionStatus = undefined;
      };
      $scope.setLabelForCard = function (lblObj, listObj) {
        $scope.card = listObj;
        $scope.lblObj = lblObj;
        $scope.lbl = {};

        $scope.lbl.labelColor = lblObj.ColorName;
        $scope.lbl.labelName = lblObj.LabelName;

        $scope.card.LabelArray.push($scope.lbl);
      };
      $scope.selectConversionStatus = function (conversionStatus) {
        $scope.pipelineConversionStatus = conversionStatus;
        $scope.convertCardStatus();
      };

      $scope.save = function () {
        if ($scope.pipelineConversionStatus) {
          $scope.convertCardStatus();
        }

        const { statusObj, lblObj, pipelineConversionStatus, card } = $scope;
        $uibModalInstance.close({
          statusObj,
          lblObj,
          pipelineConversionStatus,
          card,
        });
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.convertCardStatus = () => {
        $scope.familyId = $scope.card.clientFamilyId;

        const $postData = {
          pipelineCardsID: $scope.card.PipelineItemId,
          conversionStatus: $scope.pipelineConversionStatus,
          clientFamilyId: $scope.familyId,
        };

        pipelineService
          .PipelineCardsConversionSet($postData)
          .then((response) => {
            if (parseInt(response.data, 10) === 1) {
              toaster.pop('success', 'Moved', 'Item successfully moved');

              const conversionStatus =
                response.config && response.config.data
                  ? response.config.data.conversionStatus
                  : undefined;

              if (parseInt(conversionStatus, 10) === 2) {
                contactService
                  .contactAddressGet($scope.familyId)
                  .then((contactAddrResponse) => {
                    $scope.postSettlementAddress = contactAddrResponse.data.filter(
                      (item) => {
                        if (item.Type === 'Post-Settlement Address') {
                          return item;
                        }
                        return false;
                      },
                    );

                    if ($scope.postSettlementAddress.length) {
                      $scope.openMovedToSettledModal(
                        'modalLinkLoanToPipeline',
                        $scope.card.clientFamilyId,
                        $scope.card.PipelineItemId,
                        $scope.card.ClientName,
                      );
                    }
                  });
              }
            } else {
              toaster.pop('error', 'Error', 'Moving item went wrong');
            }

            $uibModalInstance.close('saved');
          });
      };

      $scope.openMovedToSettledModal = function (size, familyID, cardID) {
        $uibModal.open({
          templateUrl:
            '/assets/views/pipeline/leads/partials/moved_settled_modal.html',
          controller: 'PipelineAppMoveToSettledModalCtrl',
          resolve: {
            familyID: () => familyID,
            cardID: () => cardID,
          },
          size,
        });
      };

      $scope.getInitials = function (string1, string2) {
        return _.isEmpty(string2)
          ? utilitiesService.filterInitialOneString(string1)
          : utilitiesService.filterInitial(string1, string2);
      };
      $scope.openLabelsList = function () {
        $scope.currentModalContent = 'add_labels';
      };
      $scope.goBackToPrevModalContent = function () {
        $scope.currentModalContent = 'card_info';
      };
      $scope.addlabel = function () {
        $scope.card.addLabelShown = true;
        $scope.defineColors();
      };
      $scope.defineColors = function () {
        $scope.labelColor1 = false;
        $scope.labelColor2 = false;
        $scope.labelColor3 = false;
        $scope.labelColor4 = false;
        $scope.labelColor5 = false;
        $scope.labelColor6 = false;
        $scope.labelColor7 = false;
        $scope.labelColor8 = false;
        $scope.labelColor9 = false;
        $scope.labelColor10 = false;
      };
      $scope.colorPicked = function (color) {
        $scope.pickedColorCode = color;
      };
      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.updateLabel = function () {
        $scope.lblToUpdate = {};
        $scope.lblsToUpdate = [];
        if ($scope.card.lblObjToEdit) {
          $scope.labelObj = $scope.card.lblObjToEdit;

          $scope.lblToUpdate.LabelID = $scope.labelObj.pipelineSettingsLabelID;
          $scope.lblToUpdate.LabelName = $scope.card.labelTitle;
          $scope.lblToUpdate.ColorName = !_.isEmpty($scope.pickedColorCode)
            ? $scope.pickedColorCode
            : $scope.labelObj.ColorName;
          $scope.lblsToUpdate.push($scope.lblToUpdate);
        } else {
          $scope.lblToUpdate.LabelID = 0;
          $scope.lblToUpdate.LabelName = $scope.card.newLabelTitle;
          $scope.lblToUpdate.ColorName = $scope.pickedColorCode;
          $scope.lblsToUpdate.push($scope.lblToUpdate);
        }

        pipelineService.PipelineSettingsLabelSet($scope.lblsToUpdate).then(
          () => {
            toaster.pop(
              'success',
              $scope.card.lblObjToEdit ? 'Updated' : 'Added',
              $scope.card.lblObjToEdit
                ? 'Label has been updated successfully.'
                : 'Label has been added successfully.',
            );
            $scope.card.editLabelShown = false;
            $scope.card.addLabelShown = false;
            if (!$scope.card.lblObjToEdit) {
              $scope.labels.push($scope.lblToUpdate);
            } else {
              $scope.card.lblObjToEdit.LabelName = $scope.card.labelTitle;
              if ($scope.pickedColorCode) {
                $scope.card.lblObjToEdit.ColorName = $scope.pickedColorCode;
              }
              const labelInCard = _.find($scope.card.labels, (cardLabel) => {
                return (
                  cardLabel.labelID ===
                  $scope.card.lblObjToEdit.pipelineSettingsLabelID
                );
              });
              if (labelInCard) {
                labelInCard.labelName = $scope.card.labelTitle;
                labelInCard.labelColor = $scope.card.lblObjToEdit.ColorName;
              }
            }
          },
          () => {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          },
        );
      };
      $scope.performAction = function (lbl, index) {
        $scope.result = $scope.isIncluded(lbl, index);

        if ($scope.result === false) {
          $scope.setLabelForCard(lbl);
        } else {
          $scope.deleteLabelFromCard(lbl);
        }
      };
      $scope.isIncluded = function (lbl) {
        if ($scope.card && $scope.card.labels) {
          const isMatch = $scope.card.labels.filter((l) => {
            return l.labelID === lbl.pipelineSettingsLabelID;
          });

          return isMatch.length > 0;
        }
        return false;
      };
      $scope.deleteLabel = function (labelObj) {
        if (labelObj.pipelineSettingsLabelID) {
          $scope.deletelbl = {};
          $scope.deletelbl.LabelID = labelObj.pipelineSettingsLabelID;
          pipelineService.PipelineSettingLabelDelete($scope.deletelbl).then(
            () => {
              toaster.pop(
                'success',
                'Deleted',
                'Label has been deleted successfully.',
              );
              _.remove($scope.labels, (label) => {
                return (
                  label.pipelineSettingsLabelID ===
                  labelObj.pipelineSettingsLabelID
                );
              });
            },
            () => {
              toaster.pop('error', 'Error', 'Yikes! Something is wrong');
            },
          );
        }
      };
      $scope.setLabelForCard = function (lblObj) {
        $scope.pipelineCardsLabelSet = {};
        $scope.pipelineCardsLabelSet.cardID = $scope.card.PipelineItemId;
        $scope.pipelineCardsLabelSet.labelsID = lblObj.pipelineSettingsLabelID;
        $scope.pipelineCardsLabelSet.colorCode = lblObj.ColorName;

        if (_.isEmpty($scope.card.labels)) {
          $scope.card.labels = [];
        }
        $scope.card.labels.push({
          labelID: lblObj.pipelineSettingsLabelID,
          labelColor: lblObj.ColorName,
          labelName: lblObj.LabelName,
        });

        pipelineService
          .PipelineCardsLabelsSet($scope.pipelineCardsLabelSet)
          .then(
            () => {
              toaster.pop(
                'success',
                'Added',
                'Label  has been added successfully.',
              );
            },
            () => {
              toaster.pop('error', 'Error', 'Yikes! Something is wrong');
            },
          );
      };
      $scope.editLabel = function (lblAppDtlModal) {
        $scope.defineColors();
        const labelToTrue = pipelineSharedData.editLabel(
          lblAppDtlModal,
          $scope.card,
        );
        _.forEach(labelToTrue, (labelKey) => {
          $scope[labelKey] = true;
        });
      };

      $scope.deleteLabelFromCard = function (lblObj) {
        $scope.deleteLblFromCard = {};
        $scope.deleteLblFromCard.cardsId = $scope.card.PipelineItemId;
        $scope.deleteLblFromCard.labelsID = lblObj.pipelineSettingsLabelID;
        pipelineService
          .PipelineCardsLabelsDelete($scope.deleteLblFromCard)
          .then(
            () => {
              _.remove($scope.card.labels, (cardLabel) => {
                return cardLabel.labelID === lblObj.pipelineSettingsLabelID;
              });
              toaster.pop(
                'success',
                'Deleted',
                'Label has been deleted from your list.',
              );
            },
            () => {
              toaster.pop('error', 'Error', 'Yikes! Something is wrong');
            },
          );
      };

      $scope.checkLabelExistInList = (label) =>
        pipelineSharedData.isLabelInList(label, $scope.labels);
    },
  );
