import template from './annualReviews.html';
import controller from './annualReviewsCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    tab: '<',
    filterData: '<',
    dateSentFeature: '<',
    filterUpdateCount: '<',
    refreshCount: '<',
    onTableDataLoaded: '&',
    onSendModal: '&',
    viewContact: '&',
  },
};
