/*
* Please use any of these valid types
* Sample code on using the crmConfirmation
*
* crmConfirmation.open({
      type: 'success',
      title: 'Reminder Set',
      description: `We will remind you to take the survey again in the next 24 hours`,
      buttonText: 'Okay, got it!',
    });
* the type of the crmConfirmation corresponds to the swal type. You can use, warning, success, etc.
*
 For Component Rendering consider these two properties
    -> renderAsComponent: true,
    -> description: `<component></component>`

 * e.g  crmConfirmation.open({
          type: 'success',
          title: 'Reminder Set',
          description: `<component></component>`,  <-
          buttonText: 'Okay, got it!',
          renderAsComponent: true <-
        });

*/
const validTypes = ['info', 'success', 'warning', 'simple-warning'];

export default class CrmConfirmation {
  constructor($uibModal) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  open(settings) {
    const {
      type,
      title,
      description,
      showConfirmButton,
      buttonText,
      buttonClass,
      showCancelButton,
      showCloseButton,
      renderAsComponent,
      commonObject,
      modalSize,
      onConfirm,
      cancelButtonText,
      cancelButtonClass,
      transcludeHtml,
      displayDontShowAgain,
      showCustomButton,
      customButtonText,
      customButtonClass,
      confirmationIcon,
      isButtonMaxWidth,
      isButtonMdWidth,
    } = settings;
    if (!validTypes.includes(type)) {
      throw new Error('Invalid Type');
    }

    const props = {
      type,
      title,
      description,
      showConfirmButton:
        typeof showConfirmButton === 'undefined' ? true : showConfirmButton,
      buttonText: buttonText || 'Okay, got it!',
      buttonClass,
      showCancelButton,
      modalSize: modalSize || 'sm',
      showCloseButton,
      renderAsComponent,
      commonObject,
      onConfirm,
      cancelButtonText: cancelButtonText || 'Cancel',
      cancelButtonClass,
      transcludeHtml,
      displayDontShowAgain,
      showCustomButton,
      customButtonText,
      customButtonClass,
      confirmationIcon,
      isButtonMaxWidth,
      isButtonMdWidth,
    };

    return this.$uibModal.open({
      template: `<crm-confirmation
                      c-title='vm.props.title'
                      c-description='vm.props.description'
                      c-show-confirm-button='vm.props.showConfirmButton'
                      c-button-text='vm.props.buttonText'
                      c-button-class="vm.props.buttonClass"
                      c-modal-instance='vm.modalInstance'
                      c-show-cancel-button='vm.props.showCancelButton'
                      c-type='vm.props.type'
                      c-modal-size='vm.props.modalSize'
                      c-show-close-button="vm.props.showCloseButton"
                      c-render-as-component="vm.props.renderAsComponent"
                      c-common-object="vm.props.commonObject"
                      c-on-confirm="vm.props.onConfirm"
                      c-cancel-button-text="vm.props.cancelButtonText"
                      c-cancel-button-class="vm.props.cancelButtonClass"
                      c-transclude-html="vm.props.transcludeHtml"
                      c-display-dont-show-again="vm.props.displayDontShowAgain"
                      c-show-custom-button="vm.props.showCustomButton"
                      c-custom-button-text="vm.props.customButtonText"
                      c-custom-button-class="vm.props.customButtonClass"
                      c-confirmation-icon="vm.props.confirmationIcon"
                      c-is-button-max-width="vm.props.isButtonMaxWidth"
                      c-is-button-md-width="vm.props.isButtonMdWidth">
                </crm-confirmation>`,
      size: 'lg',
      windowClass: 'crm-confirmation-window',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        props: () => props,
      },
    });
  }

  notify(settings) {
    const {
      icon,
      title,
      message,
      timeout,
      allowClose,
      disableBackdrop,
    } = settings;
    const props = {
      icon: icon || `<icon-plane class='icon'></icon-plane>`,
      title: title || 'Success',
      message: message || '',
      timeout: timeout || 2000,
      allowClose: allowClose || false,
    };

    return this.$uibModal.open({
      template: `<crm-notify
          icon="vm.props.icon"
          title="vm.props.title"
          message="vm.props.message"
          timeout="vm.props.timeout"
          allow-close="vm.props.allowClose"
          modal-instance="vm.modalInstance"
        >
        </crm-notify>`,
      windowClass: `crm-notify-window ${
        disableBackdrop ? 'disable-backdrop' : ''
      }`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        props: () => props,
      },
    });
  }
}
