import { insuranceApplication } from 'Common/mappers/insuranceApplication';
import { insuranceApplicationBuilderForUI } from 'Common/mappers/insuranceQuote';

export default class InsuranceApplicationService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'insurance-application';
    this.documentBaseUrl = 'lm-insurance';
  }

  getInsuranceApplication(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}`, params)
      .then(({ data }) => {
        return data && data.map(insuranceApplicationBuilderForUI);
      });
  }

  getApplicationQuote(quoteId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/complete/${quoteId}`)
      .then(({ data }) => data && insuranceApplication(data));
  }

  sendApplication(model) {
    return this.httpClient.post(`${this.apiBaseUrl}/send`, model);
  }

  getApplicationPDF(appId) {
    return this.httpClient
      .get(`${this.documentBaseUrl}/${appId}/application/pdf`)
      .then(({ data }) => data);
  }

  validateContactsInsurance(primaryFamilyId, secondaryFamilyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/validate-contacts-insurance`, {
        primaryFamilyId,
        secondaryFamilyId,
      })
      .then(({ data }) => data);
  }
}
