import moment from 'moment';
import {
  getMonths,
  getYears,
  addNextYearGivenMonth,
} from 'Common/utilities/calendar';
import { lengthOfTime } from 'Common/utilities/lengthOfTime';
import { ADDRESS_TYPE } from 'Common/constants/addressModules';
import {
  getContactLivingIn,
  addIstevenLabelMap,
} from 'Common/utilities/addressHistory';
import { fieldInvalid } from 'Common/utilities/formValidation';
import { OWNERSHIP_WITH_RENTAL } from 'Common/constants/ownershipTypes';
import { OWNERSHIP_TYPE } from 'Common/constants/addressOwnership';
import { FINANCIAL_FREQUENCY } from 'Common/constants/financialFrequency';

const defaultOwnerId = -1;
const defaultLengthOfTime = '0 months';
const defaultOwnerOption = {
  ClientEntityId: defaultOwnerId,
  PersonId: defaultOwnerId,
  FirstName: 'All',
  LastName: 'Clients',
  Role: 'Adult',
  ticked: true,
  PreferredName: 'All Clients',
  istevenLabel: 'All Clients',
};
export default class ClientAddressDetailsCtrl {
  constructor(
    $timeout,
    optionsService,
    contactService,
    broadcastDataService,
    timeoutService,
    configService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.optionsService = optionsService;
    this.contactService = contactService;
    this.broadcastDataService = broadcastDataService;
    this.timeoutService = timeoutService;
    this.configService = configService;
  }

  getAddressTypes() {
    this.optionsService.addressTypeGet().then(({ data }) => {
      this.addressTypes = data || [];
    });
  }

  getOwnershipType() {
    this.optionsService.ownershipTypeGet().then(({ data }) => {
      this.ownershipTypes =
        (data &&
          data.map((type) => {
            type.Value = parseInt(type.Value, 10);
            return type;
          })) ||
        [];
    });
  }

  getFinancialFrequency() {
    this.optionsService.FinancialFrequency().then(({ data }) => {
      this.financialFrequencies = data || [];
    });
  }

  getOwners() {
    this.localLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel'>No Clients Selected</div>",
    };
    this.owners = [];

    this.contactService.clientInformGet(this.familyId).then(({ data }) => {
      const validRole = ['Adult', 'Guarantor'];
      const list =
        (data && data.filter((owner) => validRole.includes(owner.Role))) || [];
      defaultOwnerOption.ticked = !this.details.AddressID;
      const owners = [{ ...defaultOwnerOption }, ...list].map(
        addIstevenLabelMap,
      );
      if (this.details.AddressID) {
        const { ContactLivingIn } = this.details;
        this.selectedOwners =
          ContactLivingIn &&
          ContactLivingIn.map((contact) => {
            return {
              ClientEntityId: contact.BorrowerID,
              FirstName: contact.FirstName,
              LastName: contact.LastName,
              PersonId: contact.BorrowerID,
              IsEntity: contact.IsEntity || false,
              PreferredName:
                (`${contact.FirstName} ` || '') + (contact.LastName || ''),
              Role: 'Adult',
              ticked: true,
              istevenLabel:
                (`${contact.FirstName} ` || '') + (contact.LastName || ''),
            };
          });

        this.owners = owners.map((value) => {
          value.ClientEntityId = value.PersonId;
          value.ticked = !!this.selectedOwners.find(
            (obj) =>
              parseInt(obj.ClientEntityId, 10) ===
              parseInt(value.ClientEntityId, 10),
          );
          return value;
        });
      } else {
        this.owners = owners.map((value) => {
          value.ClientEntityId = value.PersonId;
          value.ticked = !!value.ticked;
          return value;
        });
        this.selectedOwners = [defaultOwnerOption];
      }
      this.setOwnership();
    });
  }

  getLengthOfTime() {
    const defaultDateObj = { Day: 1 };
    const startDateValid = this.startDateValid.includes(this.details.TypeId);
    const endDateValid = this.endDateValid.includes(this.details.TypeId);
    if (!startDateValid) {
      this.details.StartDate = null;
    }
    if (!endDateValid) {
      this.details.EndDate = null;
    }
    if (!startDateValid && !endDateValid) {
      this.occupancyLengthOfTime = defaultLengthOfTime;
      return;
    }
    if (startDateValid && !this.details.StartDate) {
      this.details.StartDate = { ...defaultDateObj };
    }
    if (endDateValid && !this.details.EndDate) {
      this.details.EndDate = { ...defaultDateObj };
    }
    const { StartDate: startDate, EndDate: endDate } = this.details;
    const dateEndedLengthOfTime = moment(
      `${startDate.Year || 0}/${startDate.Month || 0}/${startDate.Day}`,
    );
    const dateStartedLengthOfTime = endDateValid
      ? moment(`${endDate.Year || 0}/${endDate.Month || 0}/${endDate.Day}`)
      : moment(Date.now());
    this.occupancyLengthOfTime = lengthOfTime(
      dateStartedLengthOfTime,
      dateEndedLengthOfTime,
    );
  }

  setFrequencyType(value) {
    this.details.FrequencyTypeId = value;
    this.dropdown.isFrequencyTypeOpen = false;
  }

  setOwnership() {
    this.details.Ownership = [];
    this.details.ContactLivingIn = [];
    this.clientForm && this.clientForm.$setValidity('clients', false);
    if (!this.selectedOwners || !this.selectedOwners.length) {
      return;
    }
    const isAllOwner =
      (this.selectedOwners &&
        this.selectedOwners.length &&
        this.selectedOwners.find(
          (owner) => owner.ClientEntityId === defaultOwnerId,
        )) ||
      false;

    if (isAllOwner) {
      this.details.Ownership =
        (this.owners &&
          this.owners.filter(
            (owner) => owner.ClientEntityId !== defaultOwnerId,
          )) ||
        [];
    } else {
      this.details.Ownership = this.selectedOwners;
    }
    this.details.ContactLivingIn = this.details.Ownership.map(
      getContactLivingIn,
    );
    this.clientForm && this.clientForm.$setValidity('clients', true);
  }

  onOwnerSelect(owner) {
    if (!owner.ticked) {
      this.setOwnership();
      return;
    }
    if (owner.PersonId === defaultOwnerId) {
      this.owners.map((value) => {
        value.ticked = value.PersonId === defaultOwnerId;
        return value;
      });
    } else {
      this.owners.map((value) => {
        value.ticked = value.PersonId !== defaultOwnerId ? value.ticked : false;
        return value;
      });
    }
    this.$timeout(() => {
      this.setOwnership();
    });
  }

  onChangeAddressType() {
    this.yearsList =
      this.details.TypeId === ADDRESS_TYPE.CURRENT_ADDRESS.toString()
        ? this.currentAddressYears
        : this.years;

    this.getLengthOfTime();
    this.details.isUsePostalAddress = false;
    this.displayUseAsPostal =
      this.useAsPostalAddressFlag &&
      this.details.TypeId === ADDRESS_TYPE.CURRENT_ADDRESS.toString();
  }

  commonOwnerSelector(isSelectAll = true) {
    const owners = [...this.owners];
    this.owners = owners.map((owner) => {
      owner.ticked = isSelectAll && owner.ClientEntityId === defaultOwnerId;
      return owner;
    });
    this.$timeout(() => {
      this.setOwnership();
    });
  }

  onOwnershipTypeChange() {
    this.details.FrequencyTypeId =
      this.OWNERSHIP_TYPE.RENT === this.details.OwnershipTypeId
        ? this.FINANCIAL_FREQUENCY.WEEKLY
        : this.FINANCIAL_FREQUENCY.MONTHLY;
  }

  $onInit() {
    this.getAddressTypes();
    this.getOwnershipType();
    this.getOwners();
    this.getFinancialFrequency();
    this.months = getMonths();
    this.years = getYears();
    this.currentAddressYears = addNextYearGivenMonth(this.years);
    this.startDateValid = [
      ADDRESS_TYPE.CURRENT_ADDRESS.toString(),
      ADDRESS_TYPE.PREVIOUS_ADDRESS.toString(),
    ];
    this.endDateValid = [ADDRESS_TYPE.PREVIOUS_ADDRESS.toString()];
    this.onChangeAddressType();
    this.validOwnershipTypeForRental = OWNERSHIP_WITH_RENTAL;
    this.dropdown = {
      isFrequencyTypeOpen: false,
    };
    this.useAsPostalAddressFlag = this.configService.feature.useAsPostalAddress;
    this.validateField = fieldInvalid(this.clientForm);
    if (typeof this.onUpdateClientDetails === 'function') {
      this.onUpdateClientDetails({ details: this.details });
    }
    this.OWNERSHIP_TYPE = OWNERSHIP_TYPE;
    this.FINANCIAL_FREQUENCY = FINANCIAL_FREQUENCY;
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('ClientAddressDetailsCtrl');
  }
}
