import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';
import { DATA_BLUE_BELL } from 'Common/constants/chartColors';
import { BORDER_RADIUS_MEDIUM, TOOLTIP } from 'Common/constants/chartOptions';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('commissionReceivedTable', function commissionReceivedTable(
      $timeout,
      $rootScope,
      overviewDashboardService,
      overviewDashboardSharedService,
      dashboardCategoryService,
      utilitiesService,
    ) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: { myParam: '=' },
        templateUrl:
          'assets/views/overviewDashboard/directiveTemplates/commissionReceivedTable.html',

        // eslint-disable-next-line sonarjs/cognitive-complexity
        link($scope) {
          $scope.labels = [];
          $scope.data = [];
          $scope.tableMode = true;
          function CommissionRecievedChartGet() {
            $scope.labelData = [];
            $scope.commissionAmount = [];

            overviewDashboardService
              .CommissionRecievedChartGet($scope.myParam.Widget_UserID)
              .then(
                (response) => {
                  $scope.commissionRecievedChartGet = response.data;

                  for (
                    let i = 0;
                    i < $scope.commissionRecievedChartGet.length;
                    i++
                  ) {
                    const label =
                      $scope.commissionRecievedChartGet[i].MonthNameVal;

                    $scope.labelData.push(label);
                    const commissionData =
                      $scope.commissionRecievedChartGet[i]
                        .TotalCommissionAmount;

                    $scope.commissionAmount.push(commissionData);
                  }
                  $scope.labels = $scope.labelData;
                  $scope.data = [];
                  $scope.data.push($scope.commissionAmount);

                  $scope.showChart = true;
                },
                () => {},
              );
          }

          $scope.series = ['First dataset'];

          $scope.colors = [DATA_BLUE_BELL];
          $scope.datasetOverride = $scope.series.map(
            () => BORDER_RADIUS_MEDIUM,
          );
          $scope.options = utilitiesService.getLoanOptions();
          $scope.options.tooltips = {
            ...$scope.options.tooltips,
            displayColors: false,
            yAlign: TOOLTIP.Y_BOTTOM,
            xAlign: TOOLTIP.X_CENTER,
            caretSize: TOOLTIP.CARET_SIZE_NORMAL,
          };

          $scope.options.tooltips.callbacks.title = () => {
            '';
          };
          $scope.options.scales.yAxes.map((item) => {
            item.ticks.suggestedMin = 0;
            return item;
          });

          $scope.interval = 'allDates';

          function CommissionRecievedListGet() {
            overviewDashboardService
              .CommissionRecievedListGet($scope.myParam.Widget_UserID)
              .then(
                (response) => {
                  $scope.showSlick = false;
                  $scope.commissionRecievedListData = [];
                  const totalData = response.data.length;
                  const round = Math.floor(totalData / 4) * 4 - 1;
                  const modStart = Math.floor(totalData / 4) * 4;
                  const mod = Math.floor(totalData % 4);
                  for (let i = 0; i < round; i += 4) {
                    const testData = [];
                    testData.push(response.data[i]);
                    testData.push(response.data[i + 1]);
                    testData.push(response.data[i + 2]);
                    testData.push(response.data[i + 3]);

                    $scope.commissionRecievedListData.push(testData);
                  }

                  if (parseInt(mod, 10) !== 0) {
                    const lastItem = [];
                    for (let i = modStart; i < totalData; i++) {
                      lastItem.push(response.data[i]);
                    }
                    $scope.commissionRecievedListData.push(lastItem);
                  }

                  $scope.dataLoaded = true;
                  $scope.showSlick = true;

                  if ($scope.commissionRecievedListData.length > 0) {
                    $timeout(() => {
                      try {
                        $scope.slickConfig.method.slickGoTo(0);
                      } catch (error) {
                        // Continue regardless of error
                      }
                    }, 1000);
                  }
                },
                () => {},
              );
          }

          function widgetOptionGet() {
            overviewDashboardService
              .WidgetOptionGet($scope.myParam.Widget_UserID)
              .then(
                (response) => {
                  $scope.WidgetOptionGetData = response.data;
                  $scope.Advisers = [];
                  $scope.Lenders = [];
                  let Flag = 0;
                  let LFlag;

                  for (let i = 0; i < $scope.WidgetOptionGetData.length; i++) {
                    if (
                      parseInt(
                        $scope.WidgetOptionGetData[i].WidgetOptionId,
                        10,
                      ) === 5
                    ) {
                      const widgetOptionValue =
                        $scope.WidgetOptionGetData[i].Widget_OptionValue;
                      $scope.filteredCommissionReceivedTableDropDownList = $scope.commissionReceivedTableDropDownList.filter(
                        (item) =>
                          parseInt(item.OptionValueId, 10) ===
                          parseInt(widgetOptionValue, 10),
                      );

                      $scope.commissionReceivedTableDropDowns =
                        $scope.filteredCommissionReceivedTableDropDownList[0];
                    }
                    if (
                      parseInt(
                        $scope.WidgetOptionGetData[i].WidgetOptionId,
                        10,
                      ) === 6
                    ) {
                      LFlag = 1;
                      $scope.switchsetting2 = 'Yes';
                      $scope.Lenders =
                        $scope.WidgetOptionGetData[i].SelectedUerValues;
                      const LendersArray = $scope.Lenders.split(',');

                      $scope.commissionReceivedMultiLenderList = $scope.commissionReceivedMultiLenderList.map(
                        (obj) => {
                          if (LendersArray.includes(obj.ProviderId)) {
                            obj.ticked = true;
                            return obj;
                          }

                          return obj;
                        },
                      );
                    } else if (
                      $scope.WidgetOptionGetData[i].OptionName !== 'Lender' &&
                      $scope.switchsetting2 !== 'Yes' &&
                      LFlag !== 1
                    ) {
                      $scope.switchsetting2 = 'No';
                      LFlag = 0;
                    }
                    if (
                      parseInt(
                        $scope.WidgetOptionGetData[i].WidgetOptionId,
                        10,
                      ) === 9
                    ) {
                      Flag = 1;
                      $scope.switchsetting = 'Yes';
                      $scope.Advisers =
                        $scope.WidgetOptionGetData[i].SelectedUerValues;
                      const AdvisersArray = $scope.Advisers.split(',');

                      $scope.commissionReceivedMultiAdviserList = $scope.commissionReceivedMultiAdviserList.map(
                        (obj) => {
                          if (AdvisersArray.includes(obj.FamilyId)) {
                            obj.ticked = true;
                            return obj;
                          }

                          return obj;
                        },
                      );
                    } else if (
                      $scope.WidgetOptionGetData[i].OptionName !==
                        'Commission Adviser' &&
                      $scope.switchsetting !== 'Yes' &&
                      Flag !== 1
                    ) {
                      $scope.switchsetting = 'No';
                      Flag = 0;
                    }
                  }
                },
                () => {},
              );
          }

          function WidgetOptionTimePeriodListv3Get() {
            overviewDashboardService.WidgetOptionTimePeriodListv3Get().then(
              (response) => {
                $scope.commissionReceivedTableDropDownList = response.data;
                widgetOptionGet();
              },
              () => {},
            );
          }

          function WidgetOptionAdviserCommissionList() {
            overviewDashboardService.WidgetOptionAdviserCommissionList().then(
              (response) => {
                $scope.widgetOptionAdviserCommissionList = response.data;

                for (
                  let i = 0;
                  i < $scope.widgetOptionAdviserCommissionList.length;
                  i++
                ) {
                  $scope.widgetOptionAdviserCommissionList[i].ticked = false;
                  $scope.commissionReceivedMultiAdviserList.push(
                    $scope.widgetOptionAdviserCommissionList[i],
                  );
                }

                widgetOptionGet();
              },
              () => {},
            );
          }

          function WidgetOptionLenderListGet() {
            overviewDashboardService.WidgetOptionLenderListGet().then(
              (response) => {
                $scope.WidgetOptionLenderList = response.data;

                for (let i = 0; i < $scope.WidgetOptionLenderList.length; i++) {
                  $scope.WidgetOptionLenderList[i].ticked = false;
                  $scope.commissionReceivedMultiLenderList.push(
                    $scope.WidgetOptionLenderList[i],
                  );
                }

                widgetOptionGet();
              },
              () => {},
            );
          }

          function init() {
            CommissionRecievedChartGet();
            CommissionRecievedListGet();
            WidgetOptionTimePeriodListv3Get();
            WidgetOptionAdviserCommissionList();
            WidgetOptionLenderListGet();
          }
          $scope.dynamicPopover = {
            content: ' ',
            templateUrl: 'commissionReceivedTablePopover.html',
            title: 'Title',
          };

          function CategorySet(categoryObj) {
            dashboardCategoryService.CategorySet(categoryObj, () => {
              init();
            });
          }

          $scope.doAction = function (msg) {
            $scope.categoryObj = {};
            $scope.customizeMode = false;
            $scope.graphMode = false;
            const setObj = {
              categoryObj: $scope.categoryObj,
              customizeMode: $scope.customizeMode,
              graphMode: $scope.graphMode,
              myParam: $scope.myParam,
            };
            const {
              categoryObj,
              customizeMode,
              graphMode,
            } = overviewDashboardSharedService.doActionCommision(
              msg,
              CategorySet,
              setObj,
            );
            $scope.customizeMode = customizeMode;
            $scope.categoryObj = categoryObj;
            $scope.graphMode = graphMode;
          };

          // TABLE

          $scope.interval = 'allDates';
          $scope.slickConfig = { method: {} };
          $scope.commissionRecievedListData = [];
          $scope.commissionReceivedTableDropDownList = [];
          $scope.commissionReceivedTableDropDowns = {};
          $scope.widgetOptionAdviserCommissionList = [];
          $scope.WidgetOptionAdviserCommission = {};
          $scope.WidgetOptionLenderList = [];
          $scope.widgetOptionLender = {};

          $scope.commissionReceivedMultiLenderList = [];
          $scope.commissionReceivedMultiLenderObj = {};

          $scope.commissionReceivedMultiAdviserList = [];
          $scope.commissionReceivedMultiAdviserObj = {};
          $scope.WidgetOptionGetData = [];
          $scope.outpLender = [];

          $scope.filteredCommissionReceivedTableDropDownList = [];

          function saveForTimePeriod(widgetSetObj) {
            $scope.OptionToSet = widgetSetObj;
            overviewDashboardService
              .WidgetOptionSet($scope.OptionToSet)
              .then(() => {
                CommissionRecievedListGet();
                CommissionRecievedChartGet();
                widgetOptionGet();
              })
              .catch(displayError);
          }

          $scope.updateMultiselectFilterDataAdviser = function (clickedItem) {
            let dataToSend = [];
            $scope.MultiOptionSet = {};
            $scope.MultiOptionSetForAll = {};
            const itemData = _.find($scope.commissionReceivedMultiAdviserList, [
              'ticked',
              true,
            ]);
            if (_.isUndefined(itemData)) {
              _.map($scope.commissionReceivedMultiAdviserList, (item) => {
                if (item.FamilyName === clickedItem.FamilyName) {
                  item.ticked = true;
                }
              });
            }
            if (clickedItem.FamilyName !== 'All') {
              if (_.size($scope.commissionReceivedMultiAdviserList) !== 0) {
                $scope.commissionReceivedMultiAdviserList[0].ticked = false;
              }

              dataToSend = $scope.commissionReceivedMultiAdviserList
                .filter(
                  (item) => item.FamilyName !== 'All' && item.ticked === true,
                )
                .map((obj) => {
                  return { PersonId: obj.FamilyId };
                });

              $scope.MultiOptionSet.SelectedUser = dataToSend;
              $scope.MultiOptionSet.WidgetOptionId =
                $scope.widgetOptionAdviserCommissionList[0].OptionId;
              $scope.MultiOptionSet.Widget_UserID =
                $scope.myParam.Widget_UserID;
              $scope.MultiOptionSet.Widget_OptionValue =
                $scope.widgetOptionAdviserCommissionList[1].OptionValueId;

              saveForTimePeriod($scope.MultiOptionSet);
            } else {
              const all = $scope.outp.filter(
                (item) => item.FamilyName === 'All',
              );
              if (all.length > 0 && $scope.outp.length > 1) {
                $scope.commissionReceivedMultiAdviserList = $scope.commissionReceivedMultiAdviserList.map(
                  (obj) => {
                    if (obj.FamilyName === 'All') {
                      return obj;
                    }

                    obj.ticked = false;
                    return obj;
                  },
                );
              }
              dataToSend.push({
                PersonId: $scope.commissionReceivedMultiAdviserList[0].FamilyId,
              });

              $scope.MultiOptionSetForAll.SelectedUser = dataToSend;
              $scope.MultiOptionSetForAll.WidgetOptionId =
                $scope.widgetOptionAdviserCommissionList[0].OptionId;
              $scope.MultiOptionSetForAll.Widget_UserID =
                $scope.myParam.Widget_UserID;
              $scope.MultiOptionSetForAll.Widget_OptionValue =
                $scope.widgetOptionAdviserCommissionList[0].OptionValueId;

              saveForTimePeriod($scope.MultiOptionSetForAll);
            }
          };
          $scope.updateMultiselectFilterDataLender = function (clickedItem) {
            let dataToSend = [];
            $scope.MultiOptionSetLender = {};
            $scope.MultiOptionSetForAllLender = {};
            const itemData = _.find($scope.commissionReceivedMultiLenderList, [
              'ticked',
              true,
            ]);
            if (_.isUndefined(itemData)) {
              _.map($scope.commissionReceivedMultiLenderList, (item) => {
                if (item.ProviderName === clickedItem.ProviderName) {
                  item.ticked = true;
                }
              });
            }
            if (clickedItem.ProviderName !== 'All') {
              if (_.size($scope.commissionReceivedMultiLenderList) !== 0) {
                $scope.commissionReceivedMultiLenderList[0].ticked = false;
              }

              dataToSend = $scope.commissionReceivedMultiLenderList
                .filter(
                  (item) => item.ProviderName !== 'All' && item.ticked === true,
                )
                .map((obj) => {
                  return { PersonId: obj.ProviderId };
                });

              $scope.MultiOptionSetLender.SelectedUser = dataToSend;
              $scope.MultiOptionSetLender.WidgetOptionId =
                $scope.WidgetOptionLenderList[0].OptionId;
              $scope.MultiOptionSetLender.Widget_UserID =
                $scope.myParam.Widget_UserID;
              $scope.MultiOptionSetLender.Widget_OptionValue =
                $scope.WidgetOptionLenderList[1].OptionValueId;

              saveForTimePeriod($scope.MultiOptionSetLender);
            } else {
              const all = $scope.outpLender.filter(
                (item) => item.ProviderName === 'All',
              );
              if (all.length > 0 && $scope.outpLender.length > 1) {
                $scope.commissionReceivedMultiLenderList = $scope.commissionReceivedMultiLenderList.map(
                  (obj) => {
                    if (obj.ProviderName === 'All') {
                      return obj;
                    }

                    obj.ticked = false;
                    return obj;
                  },
                );
              }
              dataToSend.push({
                PersonId:
                  $scope.commissionReceivedMultiLenderList[0].ProviderId,
              });

              $scope.MultiOptionSetForAllLender.SelectedUser = dataToSend;
              $scope.MultiOptionSetForAllLender.WidgetOptionId =
                $scope.WidgetOptionLenderList[0].OptionId;
              $scope.MultiOptionSetForAllLender.Widget_UserID =
                $scope.myParam.Widget_UserID;
              $scope.MultiOptionSetForAllLender.Widget_OptionValue =
                $scope.WidgetOptionLenderList[0].OptionValueId;

              saveForTimePeriod($scope.MultiOptionSetForAllLender);
            }
          };

          $scope.updateTimePeriod = function (
            commissionReceivedTableDropDowns,
          ) {
            overviewDashboardSharedService.updateTimePeriod(
              commissionReceivedTableDropDowns,
              saveForTimePeriod,
              $scope.myParam,
            );
          };

          $scope.isFiltered = function (filteredOption) {
            $scope.val = filteredOption;
            $scope.setIsFiltered = {};

            if ($scope.val === 'No') {
              $scope.makeSelectNone();
            } else {
              $scope.switchsetting = 'Yes';
            }
            // FixedRateReviewTableGet();
          };
          $scope.makeSelectNone = function () {
            for (
              let i = 0;
              i < $scope.widgetOptionAdviserCommissionList.length;
              i++
            ) {
              $scope.widgetOptionAdviserCommissionList[i].ticked = false;
              $scope.commissionReceivedMultiAdviserList.push(
                $scope.widgetOptionAdviserCommissionList[i],
              );
            }
            $scope.setIsFiltered.Widget_OptionValue = '';
            $scope.setIsFiltered.WidgetOptionId = 9;
            $scope.setIsFiltered.Widget_UserID = $scope.myParam.Widget_UserID;
            $scope.setIsFiltered.SelectedUser = [];
            saveForTimePeriod($scope.setIsFiltered);
          };

          $scope.isFilteredForLender = function (filteredOption) {
            $scope.val = filteredOption;
            $scope.setIsFiltered = {};

            if ($scope.val === 'No') {
              $scope.makeSelectNoneLender();
            } else {
              $scope.switchsetting2 = 'Yes';
            }
            // FixedRateReviewTableGet();
          };
          $scope.makeSelectNoneLender = function () {
            const setObj = {
              WidgetOptionLenderList: $scope.WidgetOptionLenderList,
              myParam: $scope.myParam,
              setIsFiltered: $scope.setIsFiltered,
            };
            const {
              commissionReceivedMultiLenderList,
              WidgetOptionLenderList,
            } = overviewDashboardSharedService.makeSelectNoneLender(
              setObj,
              saveForTimePeriod,
            );
            $scope.WidgetOptionLenderList = WidgetOptionLenderList;
            $scope.commissionReceivedMultiLenderList = commissionReceivedMultiLenderList;
          };

          // END TABLE

          init();
        },
      };
    });
