class CommissionSummaryWidgetCtrl {
  initSummary() {
    if (!this.summary) {
      return;
    }

    this.summary.isPercentageUp = this.summary.percent > 0;
    this.summary.formattedPercentage = Math.abs(
      this.summary.percent.toFixed(0),
    );
  }

  $onChanges() {
    this.initSummary();
  }
}

export default CommissionSummaryWidgetCtrl;
