class MarketingAutomationSubsCtrl {
  $onInit() {
    this.transactionalNote =
      'These emails will still be sent if a client has opted out of marketing communications. To opt a customer out of any of these emails, untick the relevant box below.';
  }

  tickStayInTouchItem(item) {
    item.selected = !item.selected;
    this.selectStayInTouchItem(item);
  }
}

export default MarketingAutomationSubsCtrl;
