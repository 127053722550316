import { sortBy } from 'lodash';
import { getParameterByName } from 'Common/utilities/api';
import { MERGE_CLIENT_MESSAGES } from 'Common/constants/promptMessages';

export default class MarryContactsModalCtrl {
  constructor(
    $q,
    $state,
    $window,
    toaster,
    contactService,
    mergeContactsService,
    currentUserService,
    mergeService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$window = $window;
    this.toaster = toaster;
    this.contactService = contactService;
    this.mergeContactsService = mergeContactsService;
    this.currentUserService = currentUserService;
    this.mergeService = mergeService;
  }

  $onInit() {
    this.mergeability = {};
    this.activate();
  }

  activate() {
    this.mergeContactsService
      .initializeClientsToMergeFamilyInfo(this.clientsToMerge)
      .then(() => this.getClientsLoanApps());
  }

  viewContact(client) {
    const url = this.$state.href('app.contactsSingle', {
      familyId: client.FamilyID,
    });
    this.$window.open(url, '_blank');
  }

  getClientsLoanApps() {
    const promises = [];

    this.clientsToMerge.forEach((client) => {
      promises.push(this.contactService.loanListGet(client.FamilyID));
    });

    this.$q.all(promises).then((responses) => {
      if (!responses) {
        return;
      }

      responses.forEach((response) => {
        const familyID = getParameterByName('familyID', response.config.url);
        const client = this.clientsToMerge.find((obj) => {
          return obj.FamilyID === familyID;
        });

        if (client) {
          const { data } = response;
          if (!data) {
            return;
          }

          client.loanApps = sortBy(
            data.filter((loanApp) => {
              return loanApp.Lender && loanApp.Lender.LenderName;
            }),
            (loanApp) => {
              return loanApp.CreatedDate;
            },
          );
        }
      });
    });
  }

  cancel() {
    this.clientsToMerge.forEach((client) => {
      client.keepRecords = false;
    });
    this.modalInstance.dismiss('cancelled');
  }

  merge() {
    this.merging = true;
    const families = this.clientsToMerge.reduce((accum, curr) => {
      if (curr.keepRecords) {
        accum.primaryFamilyId = curr.FamilyID;
      } else {
        accum.secondaryFamilyId = curr.FamilyID;
      }

      return accum;
    }, {});

    const validateParams = {
      ...families,
      onConfirmCb: () => {
        this.merging = false;
      },
    };
    const validate = this.currentUserService.isNZ
      ? this.mergeService.validateInsurance(validateParams)
      : Promise.resolve(true);

    validate.then((valid) => {
      if (!valid) {
        return;
      }

      this.mergeContactsService
        .mergeDuplicateContacts(this.clientsToMerge)
        .then(
          (response) => {
            if (response.data) {
              this.modalInstance.close();
              this.mergeService.showMergeSuccess({
                onConfirmCb: () =>
                  this.openMergedClient(response.data.FamilyID),
                onCloseCb: () => this.refreshContactList(),
              });
            } else {
              this.toaster.pop('error', MERGE_CLIENT_MESSAGES.ERROR);
            }

            this.merging = false;
          },
          () => {
            this.merging = false;
            this.toaster.pop('error', MERGE_CLIENT_MESSAGES.ERROR);
          },
        );
    });
  }

  openMergedClient(mainFamilyId) {
    this.$state.go('app.contactsSingle', { familyId: mainFamilyId });
  }

  toggleClientKeepRecords(client) {
    this.clientsToMerge.forEach((obj) => {
      obj.keepRecords = obj.FamilyID === client.FamilyID;
    });

    this.mergeContactsService
      .checkClientsMergeability(this.clientsToMerge, client.FamilyID)
      .then((response) => {
        if (!response) {
          return;
        }

        this.mergeability.mergeable = response.mergeable;
        this.mergeability.warning = response.warning;
      });
  }
}
