import {
  ASSISTANT_BTN_CLASS,
  ASSISTANT_FONT_CLASS,
  ASSISTANT_STATUS_LIST,
} from 'Common/constants/assistantConstant';
import { toastError } from 'Common/utilities/alert';
import _ from 'lodash';

export const setMyCRMActiveToFalse = (obj, field) => {
  if (field || !obj) {
    return;
  }
  obj.MyCRMActive = false;
  obj.myCRMActiveIsUpdated = true;
};

export const setIsActive = (statusId) => {
  return ASSISTANT_STATUS_LIST.ACTIVE === statusId ? 1 : 0;
};

export const updateAssistantStatus = ({ $scope, corporateService }) => (
  setterType,
  statusId,
) => {
  const {
    TERMINATED,
    ACTIVE,
    SUSPENDED,
    CEASED,
    PROSPECT,
    ONBOARDING,
  } = ASSISTANT_STATUS_LIST;
  const adviserOrgRequiredStatusList = [TERMINATED, ACTIVE, SUSPENDED, CEASED];
  const emailRequiredStatusList = [PROSPECT, ONBOARDING];
  const updateAssistant = () => {
    $scope.updateAssistantDetails(
      setterType,
      $scope.assistantDetails,
      'BrokerStatusId',
      'all',
      statusId,
    );
  };

  if (adviserOrgRequiredStatusList.includes(statusId)) {
    const adviserOrgId = _.get(
      $scope.selectedAdviserOrg,
      '0.AdviserOrganizationId',
    );
    if (!adviserOrgId) {
      toastError(
        'Please check that there is an Adviser Organization for this Assistant before changing status',
      );
      return;
    }
    updateAssistant();
  } else if (emailRequiredStatusList.includes(statusId)) {
    corporateService
      .checkIfFamilyHasValidEmailGet(
        $scope.assistantDetails.FamilyId,
        $scope.assistantDetails.ClientID,
      )
      .then(({ data }) => {
        if (!data) {
          toastError('Email is not valid!');
          return;
        }
        updateAssistant();
      });
  } else {
    updateAssistant();
  }
};

export const getAssistantStatusName = (statusId, statusList, queryService) => {
  return queryService.getArrValue(
    statusId,
    null,
    'BrokerStatusId',
    'BrokerStatusName',
    statusList,
  );
};

export const setClasses = function (statusId, isbtn) {
  return isbtn ? ASSISTANT_BTN_CLASS[statusId] : ASSISTANT_FONT_CLASS[statusId];
};
