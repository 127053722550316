export const ioffLinksBuilderForUI = (factFind) => {
  if (!factFind) {
    return [];
  }
  const { Clients } = factFind;
  return (
    Clients?.map((client) => ({
      fullName: client.FullName,
      urlLink: client.UrlLink,
    })) || []
  );
};
