import { cloneDeep, get } from 'lodash';
import {
  ASSET_PERSONAL_CATEGORY,
  ASSET_TYPE,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_ORDER,
  CONSTRUCTION_TRANSACTION_TYPES,
  NONEXISTING_ASSET_TRANSACTION_TYPES,
} from 'Common/constants/securityValues';
import { LMI_NAME } from 'Common/constants/lmi';
import { INNER_FUNDING } from 'Common/constants/fundsConstants';
import { showActionConfirmation } from 'Common/utilities/alert';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  loadLoanDetails,
  updateLoanDetailsLMIPremium,
} from 'Assets/js/controllers/loanApp/util/clientAccess/loanFundingDetails';

export const showAddAssetSecurityModal = ({ $scope }) => () => {
  if (ASSET_PERSONAL_CATEGORY.includes($scope.lendingCategoryId)) {
    $scope.showAddSecurityModal();
    return;
  }
  $scope.showPropertyTypeSelectionModal();
};

export const setDisplayOtherFundsReq = ({ $scope }) => (isShow) => {
  if ($scope.displayOtherFundsReq && isShow) {
    return;
  }
  $scope.otherFundsReqObj = {};
  $scope.displayOtherFundsReq = isShow;
};

export const getOtherFundsReq = ({
  $scope,
  loanAppSharedData,
  loanScenarioModelService,
}) => () => {
  const params = { loanApplicationId: $scope.loanAppId };
  loanScenarioModelService.getOtherFundingRequirement(params).then((data) => {
    $scope.otherFundsReqList = data;
    !$scope.getFundingCalculatorFundsSummary &&
      (loanAppSharedData.refreshSecurityFundsSummary = 1);
    $scope.getFundingCalculatorFundsSummary &&
      $scope.getFundingCalculatorFundsSummary();
  });
};

export const setOtherFundsReq = ({ $scope, loanScenarioModelService }) => (
  data,
  isDelete = false,
) => {
  const { description, price } = data;
  if (!description || !price) {
    $scope.invalidOtherFunds = true;
    return;
  }
  $scope.invalidOtherFunds = false;
  $scope.setDisplayOtherFundsReq(false);
  const params = {
    ...data,
    loanApplicationId: $scope.loanAppId,
    assetTypeId: ASSET_TYPE.OTHER,
    isActive: !isDelete,
  };
  loanScenarioModelService.setOtherFundingRequirement(params).then(() => {
    $scope.getOtherFundsReq();
  });
};

export const showEditOtherFund = (item, isEdit = true) => {
  item.isEdit = isEdit;
};

export const showDeleteFundConfirmation = ({ $scope }) => (data) => {
  showActionConfirmation(
    'Are you sure?',
    'This information will be removed from your list.',
    (confirm) => {
      if (!confirm) {
        return;
      }
      $scope.setOtherFundsReq(data, true);
    },
  );
};

export const onWatchOtherFunds = ({ $scope, nv }) => {
  if (nv === 1) {
    $scope.getOtherFundsReq();
    $scope.loanAppSharedData.refreshAssetFinanceOtherFunds = 0;
  }
};

export const isConstructionTransaction = ({
  isConstructionLoanApp,
  transactionId,
}) => {
  return (
    isConstructionLoanApp &&
    CONSTRUCTION_TRANSACTION_TYPES.includes(transactionId)
  );
};

export const isShowSecurityTable = ({ $scope }) => () => {
  const originalLogic =
    !$scope.currentUserService.isID &&
    $scope.isAssetFinance &&
    !$scope.isAssetLoading &&
    $scope.securityList.length;
  const otherFundsLogic =
    $scope.otherFundsReqList.length || $scope.displayOtherFundsReq;
  return !!(originalLogic || otherFundsLogic);
};

export const isOpenSecurityModal = ({ $scope, item, value }) => {
  const { name } = value;
  const { SecurityInfoId, TransactionTypeList, TransactionId } = item;
  let innerFundingToOpenModal = [];
  switch (TransactionId) {
    case TRANSACTION_TYPE.OWNS:
    case TRANSACTION_TYPE.GUARANTEE:
    case TRANSACTION_TYPE.REFINANCE:
      innerFundingToOpenModal = [INNER_FUNDING.LAND_VALUE];
      break;
    case TRANSACTION_TYPE.REFINANCE_CONSTRUCTION:
    case TRANSACTION_TYPE.OWNS_CONSTRUCTION:
      innerFundingToOpenModal = [INNER_FUNDING.BUILD_AMOUNT];
      break;
    case TRANSACTION_TYPE.PURCHASING_CONSTRUCTION:
      innerFundingToOpenModal = [
        INNER_FUNDING.BUILD_AMOUNT,
        INNER_FUNDING.LAND_VALUE,
      ];
      break;
    case TRANSACTION_TYPE.PURCHASING:
      innerFundingToOpenModal = [INNER_FUNDING.TOTAL_SECURITY_AMOUNT];
      break;
    default:
  }
  if (innerFundingToOpenModal.includes(name)) {
    return $scope.showEditSecurityModal(SecurityInfoId, TransactionTypeList);
  }
  return (value.isEditing = !value.isEditing);
};

export const setValidTransactionTypePerSecurity = ({
  transactionTypeList,
  securityTransactionId,
  isConstructionLoanApp = false,
}) => {
  const security = {};
  const newTransactionTypeList = cloneDeep(transactionTypeList);
  const transactionId =
    (securityTransactionId && parseToInt10(securityTransactionId)) || 0;
  const isExistingSecurity =
    transactionId !== 0 &&
    !NONEXISTING_ASSET_TRANSACTION_TYPES.includes(transactionId);
  const exludeConstructionTypes = (transactionTypeId) =>
    (!isConstructionLoanApp &&
      CONSTRUCTION_TRANSACTION_TYPES.includes(transactionTypeId)) ||
    !!(
      isConstructionLoanApp &&
      TRANSACTION_TYPE.CONSTRUCTION === transactionTypeId
    );

  const isSetToDisabled = (index, transactionTypeId) => {
    return (
      index === 0 ||
      TRANSACTION_TYPE.SOLD === transactionTypeId ||
      exludeConstructionTypes(transactionTypeId) ||
      NONEXISTING_ASSET_TRANSACTION_TYPES.includes(transactionTypeId)
    );
  };
  security.isExistingSecurity = isExistingSecurity;
  security.TransactionTypeList = newTransactionTypeList
    .map((transaction, index) => {
      const value = parseToInt10(transaction.Value);
      transaction.disabled = isExistingSecurity
        ? isSetToDisabled(index, value)
        : (!NONEXISTING_ASSET_TRANSACTION_TYPES.includes(value) &&
            index !== 0) ||
          exludeConstructionTypes(value);

      transaction.orderNo =
        (!!TRANSACTION_TYPE_ORDER[value] && TRANSACTION_TYPE_ORDER[value]) || 0;
      return transaction;
    })
    .sort((curr, next) => curr.orderNo - next.orderNo);
  return security;
};

export const getApplicantsAndGuarantorsCount = (
  involvedPartyEntity = [],
  involvedPartyPerson = {},
) => {
  let countOfApplicants = 0;
  countOfApplicants += involvedPartyEntity.length;
  Object.keys(involvedPartyPerson).forEach((familyId) => {
    countOfApplicants += involvedPartyPerson[familyId].length;
  });
  return countOfApplicants;
};

export const getOwnershipPercentage = (countOfApplicants = 1) => {
  if (!countOfApplicants) {
    return 0;
  }
  return (100 / countOfApplicants).toFixed(2);
};

export const mapSecurityModalInvolvedPartyPerson = ({
  involvedPartyPerson,
  countOfApplicants,
  getBGcolor,
  filterInitialOneString,
}) => {
  const isIncompleteData =
    !involvedPartyPerson ||
    !Object.keys(involvedPartyPerson).length ||
    !countOfApplicants;
  if (isIncompleteData) {
    return [];
  }

  let mappedPartyPerson = [];
  Object.keys(involvedPartyPerson).forEach((familyId) => {
    const family = involvedPartyPerson[familyId];

    const mappedFamily = family.map((client) => {
      const ownershipPercentage = getOwnershipPercentage(countOfApplicants);
      const parsedPersonId = parseToInt10(client.PersonId);
      return {
        FamilyId: parseToInt10(familyId),
        BorrowerID: parsedPersonId,
        ClientID: parsedPersonId,
        FirstName: client.FirstName,
        ClientType: client.Role,
        LastName: client.LastName,
        PreferedName: `${client.FirstName} ${client.LastName}`,
        Percentage: ownershipPercentage,
        OwnershipPercentage: ownershipPercentage,
        IsInclude: true,
        IsTicked: true,
        ticked: true,
        IsEntity: false,
        IsClient: true,
        initials: filterInitialOneString(client.FirstName),
        background: getBGcolor(client.PersonId),
      };
    });
    mappedPartyPerson = [...mappedPartyPerson, ...mappedFamily];
  });
  return mappedPartyPerson;
};

export const mapSecurityModalInvolvedPartyEntity = ({
  involvedPartyEntity,
  countOfApplicants,
  getBGcolor,
  filterInitialOneString,
}) => {
  const isIncompletData =
    !involvedPartyEntity || !involvedPartyEntity.length || !countOfApplicants;
  if (isIncompletData) {
    return [];
  }

  return involvedPartyEntity.map((entity) => {
    const ownershipPercentage = getOwnershipPercentage(countOfApplicants);
    const parsedOrgId = parseToInt10(entity.OrganisationId);
    return {
      FamilyId: entity.FamilyId,
      BorrowerID: parsedOrgId,
      EntityID: parsedOrgId,
      ClientType: entity.EntityTypeName,
      PreferedName: entity.FamilyFullName,
      Percentage: ownershipPercentage,
      OwnershipPercentage: ownershipPercentage,
      IsInclude: true,
      IsEntity: true,
      IsTicked: true,
      ticked: true,
      initials: filterInitialOneString(entity.FamilyFullName),
      background: getBGcolor(entity.OrganisationId),
    };
  });
};

export const getOwnerGroupProperty = (applicantList = []) => {
  const isOneFamily =
    applicantList.length === 1 ||
    (applicantList.length === 2 &&
      applicantList[0].familyId === applicantList[1].familyId) ||
    false;

  return isOneFamily ? '' : 'familyId';
};

export const mapREASecurityList = ({
  isConstructionLoanApp,
  securityList,
  transactionTypeList,
}) => {
  return securityList.map((security) => {
    const transactionTypes = setValidTransactionTypePerSecurity({
      securityTransactionId: security.TransactionId,
      transactionTypeList,
      isConstructionLoanApp,
    });

    return {
      ...security,
      ...transactionTypes,
      isConstructionTransaction: isConstructionTransaction({
        isConstructionLoanApp,
        transactionId: security.TransactionId,
      }),
    };
  });
};

export const getApplicantsFromLoanAppSharedData = ({
  loanAppSharedData,
  utilitiesService,
  financialsSharedService,
}) => {
  if (!loanAppSharedData) {
    return [];
  }

  const involvedPartyPerson = get(
    loanAppSharedData,
    'loanAppSummary.ApplicantGuarantorList.InvolvedPartyPerson',
    {},
  );

  const involvedPartyEntity = get(
    loanAppSharedData,
    'loanAppSummary.ApplicantGuarantorList.InvolvedPartyEntity',
    [],
  );

  const countOfApplicants = getApplicantsAndGuarantorsCount(
    involvedPartyEntity,
    involvedPartyPerson,
  );

  const mappedEntity = mapSecurityModalInvolvedPartyEntity({
    involvedPartyEntity,
    countOfApplicants,
    filterInitialOneString: utilitiesService.filterInitialOneString,
    getBGcolor: financialsSharedService.getBGcolor,
  });

  const mappedPerson = mapSecurityModalInvolvedPartyPerson({
    involvedPartyPerson,
    countOfApplicants,
    filterInitialOneString: utilitiesService.filterInitialOneString,
    getBGcolor: financialsSharedService.getBGcolor,
  });

  return [...mappedPerson, ...mappedEntity];
};

export const validateFundingDetailsSection = ({
  configService,
  loanAppSharedData,
}) => {
  configService.feature.loanAppSplitFundingAndStructure &&
    loanAppSharedData.validateFundingDetails &&
    loanAppSharedData.validateFundingDetails();
};

export const onUpdateLMIValueOnTxt = (lmiPremium, $scope) => {
  if (!lmiPremium) {
    return;
  }
  const lmiFundParam = {
    fundsId: 0,
    fundingCalParametersId: 0,
    name: LMI_NAME,
    value: lmiPremium,
  };
  $scope.saveFundingParameters(lmiFundParam, true);
};

export const handleUpdateLoanDetailsLMIFromSecurity = ({
  $scope,
  $timeout,
  loanScenarioService,
}) => (LMIPremium) => {
  if ($scope.loanAppSharedData.isLoanDetailsSet) {
    $scope.loanAppSharedData.refreshLoanDetailsSet = 1;
    $scope.reloadLoanDetailsTimeOut = $timeout(() => {
      $scope.loanAppSharedData.refreshLoanDetails = 1;
    }, 200);
  } else if (!$scope.loanAppSharedData.loanDetailsSet) {
    const dummyScope = {};
    loadLoanDetails({
      $scope: dummyScope,
      loanScenarioService: loanScenarioService,
      loanAppId: $scope.loanAppId,
    }).then(() => {
      $scope.loanAppSharedData.loanDetailsSet = dummyScope.loanDetails;
      updateLoanDetailsLMIPremium({
        LMIPremium,
        loanAppId: $scope.loanAppId,
        loanDetails: $scope.loanAppSharedData.loanDetailsSet,
        loanAppSharedData: $scope.loanAppSharedData,
        loanScenarioService: loanScenarioService,
      });
    });
  } else {
    updateLoanDetailsLMIPremium({
      LMIPremium,
      loanAppId: $scope.loanAppId,
      loanDetails: $scope.loanAppSharedData.loanDetailsSet,
      loanAppSharedData: $scope.loanAppSharedData,
      loanScenarioService: loanScenarioService,
    });
  }
};
