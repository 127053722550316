import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import { SELECTION_VALUE } from 'Common/constants/referralType';

export const loadModule = () =>
  angular.module('app').controller(
    'AddReferralAgreementModalCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function AddReferralAgreementModalCtrl(
      referralAgreementService,
      $scope,
      $uibModalInstance,
      $uibModal,
      referralsService,
      $state,
      $window,
      currentState,
      referralType,
      currentUserService,
      configService,
    ) {
      $scope.isByob = currentUserService.isBYOB;
      $scope.enableReferralPCF =
        configService.feature.showReferralsPCF &&
        configService.feature.hasReferralAgreements;
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

      // Load Agreement List In Advance
      referralsService.getReferrerOrgAgreementList().then((response) => {
        _.map(response.data, (data) => {
          data.AgreementLabel = `${data.AdviserOrgName} - ${data.Name}`;
        });
        $scope.agreementList = response.data;
      });

      $scope.referralModel = {};
      $scope.referralModel.selectionValue = referralType;
      $scope.referralModel.currentView = 2;
      $scope.referralModel.inputRequired = false;

      $scope.selectedItem = {};
      $scope.selectedItem.agreement = {};
      $scope.isOrgTypeIdEmpty = false;
      $scope.agreement = {};

      $scope.nextStep = function () {
        if (
          $scope.referralModel.currentView === 2 ||
          $scope.referralModel.selectionValue !== 1
        ) {
          if ($scope.referralModel.selectionValue === 1) {
            const orgId =
              $scope.referralModel.agreementType === 3
                ? $scope.referralModel.selectedOrgType
                : 0;
            $scope.cancel();
            $window.sessionStorage.setItem(
              'currentState',
              JSON.stringify(currentState),
            );
            // eslint-disable-next-line sonarjs/no-duplicate-string
            $state.go('app.newReferral', {
              referralType: orgId,
              agreementId: 0,
              agreementTypeId: $scope.referralModel.agreementType,
              requestId: $scope.referralModel.selectionValue,
            });
          } else if ($scope.agreement) {
            if ($scope.agreement.AgreementTypeId !== 3) {
              $scope.cancel();
              $window.sessionStorage.setItem(
                'currentState',
                JSON.stringify(currentState),
              );
              $state.go('app.newReferral', {
                referralType: 0,
                agreementId: $scope.agreement.AgreementId,
                agreementTypeId: $scope.agreement.AgreementTypeId,
                requestId: $scope.referralModel.selectionValue,
              });
            } else if (
              $scope.agreement &&
              typeof $scope.agreement.OrganisationTypeId !== 'undefined' &&
              $scope.agreement.OrganisationTypeId > 0
            ) {
              $scope.cancel();
              $window.sessionStorage.setItem(
                'currentState',
                JSON.stringify(currentState),
              );
              $state.go('app.newReferral', {
                referralType: $scope.agreement.OrganisationTypeId,
                agreementId: $scope.agreement.AgreementId,
                agreementTypeId: $scope.agreement.AgreementTypeId,
                requestId: $scope.referralModel.selectionValue,
              });
            } else {
              $scope.referralModel.inputRequired = true;
            }
          }
        } else {
          $('#carousel').slick('slickNext');
          $scope.referralModel.currentView++;
        }
      };

      $scope.requestAgreement = () => {
        const newAgreement =
          $scope.referralModel.selectionValue === SELECTION_VALUE.AGREEMENT;
        const isValidExisting =
          $scope.agreement && $scope.agreement.AgreementTypeId;
        if (!newAgreement && !isValidExisting) {
          $scope.referralModel.inputRequired = true;
          return;
        }
        $scope.nextStep();
      };

      $scope.prevStep = function () {
        if ($scope.referralModel.currentView === 2) {
          $('#carousel').slick('slickPrev');
          $scope.referralModel.currentView = 1;
        } else {
          $scope.referralModel.inputRequired = false;
          $scope.referralModel.selectionValue = 1;
        }
      };
      referralAgreementService.getOrganizationTypes($scope);
      $scope.referralModel.agreementType = 3;
      $scope.setAgreementType = (value) => {
        $scope.referralModel.agreementType = value;
      };
      $scope.setSelection = (value) => {
        $scope.referralModel.selectionValue = value;
      };
      $scope.selectAgreement = (result) => {
        $scope.agreement = result;
        $scope.referralModel.isSearching = false;
      };
      $scope.displayRequest =
        $scope.referralModel.selectionValue === SELECTION_VALUE.AGREEMENT;
    },
  );
