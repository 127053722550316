import template from './mycrmCheckbox.html';
import controller from './mycrmCheckboxCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    value: '=',
    key: '<',
    tabbableRemoved: '<',
    disabled: '<',
    label: '@',
    size: '@',
    onChange: '&',
  },
};
