import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('GdriveCtrl', function GdriveCtrl($window, $location) {
      function init() {
        $window.opener.authGdrive($location.search().code);
        $window.close();
      }
      init();
    });
