import { AU_STATES } from 'Common/constants/addressState';

export const DEFAULT_FUND_NAMES = {
  TOTAL_PROPERTY_AMOUNT: 'Total Property Amount',
  REFINANCE_AMOUNT: 'Refinance Amount',
  LAND_VALUE: 'Land Value',
  BUILD_AMOUNT: 'Build Amount',
  LIMIT_OF_GUARANTEE: 'Limit of Guarantee',
};

export const FUNDING_PURPOSE_IDS = {
  OWNER_OCCUPIED: 1,
  RENTAL: 2,
  OTHER: 3,
  PURCHASE_OWNER_OCCUPIED: 4,
  PURCHASE_INVESTMENT: 5,
  REFI_EQUITY_OWNER_OCCUPIED: 6,
  REFI_EQUITY_INVESTMENT: 7,
  CONSTRUCTION_INVESTMENT: 8,
  CONSTRUCTION_OWNER_OCCUPIED: 9,
  AU_GUARANTEE: 17,
  NZ_GUARANTEE: 16,
};

export const REFINANCE_PURPOSE_IDS = [
  FUNDING_PURPOSE_IDS.REFI_EQUITY_OWNER_OCCUPIED,
  FUNDING_PURPOSE_IDS.REFI_EQUITY_INVESTMENT,
];

export const CONSTRUCTION_PURPOSE_IDS = [
  FUNDING_PURPOSE_IDS.CONSTRUCTION_INVESTMENT,
  FUNDING_PURPOSE_IDS.CONSTRUCTION_OWNER_OCCUPIED,
];

export const FUNDING_STATUS_IDS = {
  NEW_BUILDING: 12,
  TO_BE_BUILT: 13,
  ESTABLISHED: 14,
  VACANT_LAND: 15,
};

export const STATE_WITH_FOREIGN_PURCHASER = [
  'NSW',
  'QLD',
  'SA',
  'TAS',
  'VIC',
  'WA',
];

export const STATE_WITH_VARYING_TRANSFER_TRANSACTION = [AU_STATES.VICTORIA];

export const VICTORIA_CITIES = {
  MELBOURNE: 'Melbourne',
  REGIONAL: 'Regional Victoria',
};

export const CONSTRUCTION_LAND = {
  ALREADY_OWNED: 'isAlreadyOwned',
  EQUITY_RELEASE: 'isEquityRelease',
};

export const INNER_FUNDING = {
  TOTAL_SECURITY_AMOUNT: 'Total Security Amount',
  BUILD_AMOUNT: 'Build Amount',
  LAND_VALUE: 'Land Value',
};

export const EPICTENET_TRANSACTION_TYPE = {
  ELECTRONIC: 'electronicTransaction',
  PAPER: 'paperTransaction',
};
