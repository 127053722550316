import { dynamicQuestionHelper } from 'Common/utilities/objectivesQuestion';

export default class QuestionConfirmMessageCtrl {
  constructor(
    $uibModal,
    loanApplicationServices,
    objectivesQuestionService,
    currentUserService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.loanApplicationServices = loanApplicationServices;
    this.objectivesQuestionService = objectivesQuestionService;
    this.confirmationLabel = dynamicQuestionHelper(
      currentUserService,
    ).getConfirmationLabel();
    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
  }

  $onChanges(changes) {
    const { riskList, riskData } = changes;
    this.riskList = riskList && riskList.currentValue;
    this.riskData = (riskData && riskData.currentValue) || this.riskData;
  }

  processParentNotification(action) {
    if (action === 'untick-associated-risk' && this.riskData.answerValue) {
      this.riskData.answerValue = false;
      this.questionExplained(false);
    }
  }

  questionExplained(callChangeAnswer = true) {
    const sendData = this.objectivesQuestionService.setObjectivesAnswer(
      {
        LoanScenarioId: this.loanId,
        FamilyId: this.familyId,
        QuestionSectionId: 0,
        QuestionId: this.riskData.QuestionId,
        DisplayOptionId: this.riskData.QuestionDisplayTypeId,
      },
      this.riskData,
    );
    this.loanApplicationServices.postLoanObjectives(sendData).then(() => {
      callChangeAnswer && this.onChangeAnswer();
    });
  }

  openMessageModal() {
    const props = {
      list: this.riskList,
    };

    this.$uibModal.open({
      template: `<question-message-modal
          risk-list="vm.props.list"
          modal-instance="vm.modalInstance">
        </question-confirm-message>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      size: 'md',
      resolve: {
        props: () => props,
      },
    });
  }
}
