import template from './opportunitySummary.html';
import controller from './opportunitySummaryCtrl';

export default {
  template,
  controller,
  bindings: {
    summaryNotes: '<',
    onChangeGeneralInfo: '&',
  },
};
