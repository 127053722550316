import angular from 'angular';
import _ from 'lodash';
import { isMobileValid } from 'Common/utilities/mobile';
import { clientWindowWatcher } from 'Common/utilities/clientWindowWatcher';
import { toastError, toastSuccess } from 'Common/utilities/alert';
import { PHONE_TYPE_INDEX } from 'Common/constants/phoneType';
import { RESIDENCY_STATUS_IDS } from 'Common/constants/contactStatus';
import { getDatePickerConfig } from 'Common/utilities/date';
import { getMonths, getYears } from 'Common/utilities/calendar';
import { showBetterEntity } from 'Common/utilities/showFeature';
import { dobToAgeGet } from 'Common/utilities/dob';
import { emailValidation } from 'Common/utilities/emailUtilities';
import {
  onOpenDate,
  getMonthAndYear,
  createExperienceDate,
  getStartDate,
  toggleActiveSoleTrader,
  setSoleTraderInfoDetails,
  mobileCountryDropdown,
  assignSelectedCountryValue,
  setCountryCode,
  getPhoneInputId,
  isFormInputValid,
  setContactAgeValue,
  onDeceasedClick,
  intializeSubscriptions,
  setNurtureSubscription,
  openStayInTouchModal,
  getMarketingAutomationString,
  isMobileInvalid,
  setClientPhoneForInternationalNumber,
  showTransactionalEmailsOnly,
} from './util/addClientModal';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('AddClientModalCtrl', function AddClientModalCtrl(
      $scope,
      $window,
      $uibModalInstance,
      isChild,
      optionsService,
      contactService,
      $rootScope,
      clientId,
      applicantFamilyId,
      toaster,
      $timeout,
      $uibModal,
      utilitiesService,
      colorService,
      uiService,
      countryList,
      currentUserService,
      configService,
      countryCodeService,
      contactModelService,
      crmConfirmation,
    ) {
      const {
        betterEntity,
        marketingAutomationClientSubscribe,
      } = configService.feature;
      const { isAU } = currentUserService;
      $scope.marketingAutomationClientSubscribe = marketingAutomationClientSubscribe;
      $scope.showBetterEntity = showBetterEntity({
        betterEntity,
        currentUserService,
      });
      $scope.onDeceasedClick = onDeceasedClick({ $scope });
      $scope.isAU = isAU;
      $scope.showTransactionalEmailsOnly = showTransactionalEmailsOnly({
        $scope,
      });

      $scope.PHONE_TYPE_INDEX_CONSTANT = PHONE_TYPE_INDEX;

      mobileCountryDropdown({ $scope, currentUserService, configService });

      $scope.isInternationalPhoneNumbers = uiService.isInternationalPhoneNumber;
      $scope.isPhoneNumberCountryCodeNZ =
        currentUserService.isNZ &&
        configService.feature.phoneNumberCountryCodeNZ;
      $scope.isInternationalMobileNumber =
        $scope.isInternationalPhoneNumbers || $scope.isPhoneNumberCountryCodeNZ;

      $scope.dateConfig = getDatePickerConfig({
        dateOptions: {
          maxDate: new Date(),
          showWeeks: false,
        },
      });
      $scope.currentDate = new Date();
      $scope.months = getMonths();
      $scope.years = getYears();
      $scope.datePopUp = {
        tradingStarDate: false,
        gstRegistrationDate: false,
      };
      $scope.onOpenDate = (dateField) => {
        onOpenDate({
          $scope,
          dateField,
        });
      };
      $scope.getMonthAndYear = (date) => {
        getMonthAndYear({
          $scope,
          date,
        });
      };

      $scope.createExperienceDate = () => {
        createExperienceDate($scope);
      };

      $scope.getStartDate = (date) => {
        getStartDate({
          $scope,
          date,
        });
      };

      if (countryList) {
        $scope.countryList = countryList;
      }
      if (applicantFamilyId) {
        $scope.familyId = applicantFamilyId;
      }
      const familyId = $scope.familyId || applicantFamilyId;
      $scope.titles = {};
      $scope.experienceDate = {};
      $scope.$watch('contact[0].Title', () => {
        const maleTitle = ['Mr', 'Master'];
        const femaleTitle = ['Mrs', 'Ms', 'Miss'];
        if (
          $scope.contact &&
          $scope.contact.length > 0 &&
          typeof $scope.contact[0].Title !== 'undefined' &&
          $scope.contact[0].Title !== ''
        ) {
          if (maleTitle.includes($scope.contact[0].Title)) {
            $scope.contact[0].Gender = 'Male';
            $scope.disabledGenderValues = ['Female'];
          } else if (femaleTitle.includes($scope.contact[0].Title)) {
            $scope.contact[0].Gender = 'Female';
            $scope.disabledGenderValues = ['Male'];
          } else {
            $scope.disabledGenderValues = [];
          }
        }
      });
      $scope.dobToAgeGet = dobToAgeGet;
      $scope.setContactAgeValue = setContactAgeValue({ $scope });
      $scope.isCorporate = currentUserService.isCorporate;
      $scope.setNurtureSubscription = setNurtureSubscription({
        $scope,
        crmConfirmation,
        $window,
      });
      $scope.openStayInTouchModal = openStayInTouchModal({
        $scope,
        $uibModal,
        currentUserService,
      });

      optionsService.MaritalStatusGet().then(
        (response) => {
          $scope.maritalStatus = response.data;
        },
        () => {
          $scope.maritalStatus = [];
        },
      );

      optionsService.ResidencyStatusGet().then(
        (response) => {
          $scope.residencyStatus = response.data;
        },
        () => {
          $scope.residencyStatus = [];
        },
      );

      $scope.toggleActiveSoleTrader = (isSoleTrader) => {
        toggleActiveSoleTrader({
          $scope,
          isSoleTrader,
        });
      };

      $scope.contactDOB = {
        openDate: (dateField) => {
          $scope.contactDOB.datePopUp[dateField] = !$scope.contactDOB.datePopUp[
            dateField
          ];
        },
        format: 'dd/MM/yyyy',
        altInputFormats: ['d!/M!/yyyy', 'd!M!yyyy'],
        dateOptions: {
          formatYear: 'yy',
          showWeeks: false,
        },
        datePopUp: {
          DOB: false,
        },
      };

      contactService.titleType().then(
        (response) => {
          $scope.titles = response.data;
        },
        () => {
          $scope.titles = {};
        },
      );
      if (typeof clientId === 'undefined') {
        $scope.hasClientDetailRespose = true;
        let country;
        switch ($rootScope.crmCountry) {
          case 'Australia':
            country = 'AU';
            break;
          case 'New Zealand':
            country = 'NZ';
            break;
          default:
            country = '-1';
        }
        $scope.Title = 'Add';
        $scope.contact = [
          {
            FamilyId: familyId,
            LastName: '',
            FirstName: '',
            MiddleName: '',
            PreferredName: '',
            MothersMaidenName: '',
            Title: '',
            Gender: '',
            DOB: '',
            Employment: [
              {
                Occupation: '',
                OccupationClass: 0,
                Employer: '',
              },
            ],
            SmokerStatus: '',
            Deceased: false,
            DOBReminder: false,
            BestTimeToCall: '',
            Phone: [
              {
                Type: 'Home',
                Number: '',
              },
              {
                Type: 'Work',
                Number: '',
              },
              {
                Type: 'Mobile',
                Number: '',
              },
            ],
            Email: [
              {
                Type: 'Email',
                EmailAddress: '',
              },
            ],
            Notes: '',
            IsResident: true,

            CountryCode: country,
            customerConsentOptions: { ConsentMarketing: true },
          },
        ];
        intializeSubscriptions({
          $scope,
          contactService,
          currentUserService,
          optionsService,
          $window,
          $timeout,
        });
      } else {
        $scope.Title = 'Edit';
        contactService.clientInformGet(familyId, clientId).then((response) => {
          $scope.hasClientDetailRespose = true;
          $scope.contact = response.data;
          const contactPhoneCopy = angular.copy($scope.contact[0].Phone);
          if (_.isUndefined($scope.contact[0].IsResident)) {
            $scope.contact[0].IsResident = false;
          }
          setSoleTraderInfoDetails($scope);
          if ($scope.contact[0].MaritalStatusId !== undefined) {
            $scope.maritalStatus = Array.isArray($scope.maritalStatus)
              ? $scope.maritalStatus
              : [];
            const maritalStatus = $scope.maritalStatus.filter((item) => {
              return (
                parseInt(item.Value, 10) ===
                parseInt($scope.contact[0].MaritalStatusId, 10)
              );
            });
            if (maritalStatus && maritalStatus.length) {
              $scope.contact[0].MaritalStatusId = maritalStatus[0].Value;
            }
          }

          // find country codes
          let CitizenshipObj = _.find($scope.countryList, (o) => {
            return o.CountryID === $scope.contact[0].CitizenshipId;
          });
          let CountryOfResidencyObj = _.find($scope.countryList, (o) => {
            return o.CountryID === $scope.contact[0].CountryOfResidencyId;
          });
          if (!CitizenshipObj) {
            CitizenshipObj = {
              CountryCode: '',
            };
          }
          if (!CountryOfResidencyObj) {
            CountryOfResidencyObj = {
              CountryCode: '',
            };
          }
          $scope.contact[0].Phone = [];

          // Modify the sequence of phone
          $scope.contact[0].Phone[0] = _.find(contactPhoneCopy, (obj) => {
            return obj.Type === 'Home';
          }) || { Type: 'Home', Number: null };

          $scope.contact[0].Phone[1] = _.find(contactPhoneCopy, (obj) => {
            return obj.Type === 'Work';
          }) || { Type: 'Work', Number: null };

          $scope.contact[0].Phone[2] = _.find(contactPhoneCopy, (obj) => {
            return obj.Type === 'Mobile';
          }) || { Type: 'Mobile', Number: null };
          if (
            typeof $scope.contact[0].DOB === 'undefined' ||
            $scope.contact[0].DOB === ''
          ) {
            $scope.contact[0].DOB = '';
            $scope.contact[0].AgeValue = '';
          } else {
            $scope.contact[0].DOB = new Date($scope.contact[0].DOB);
            $scope.contact[0].AgeValue = dobToAgeGet($scope.contact[0].DOB);
          }
          if (typeof $scope.contact[0].MothersMaidenName === 'undefined') {
            $scope.contact[0].MothersMaidenName = '';
          }
          angular.extend($scope.contact[0], {
            FamilyId: familyId,
            CountryCode: CitizenshipObj.CountryCode,
            CountryOfResidencyCode: CountryOfResidencyObj.CountryCode,
            CountryOfBirthCode: $scope.getCountryOfBirthCode(),
            ResidencyId: String(
              $scope.contact[0].ResidencyId === 0
                ? RESIDENCY_STATUS_IDS.RESIDENT
                : $scope.contact[0].ResidencyId,
            ),
            MaritalStatusId: String($scope.contact[0].MaritalStatusId),
          });

          if (_.size($scope.contact[0])) {
            $scope.contact[0].Employment[0].OccupationClass = String(
              $scope.contact[0].Employment[0].OccupationClass,
            );
          }

          $scope.contact[0].Phone = $scope.contact[0].Phone.map((value) => {
            return Object.assign(
              {},
              {
                ...value,
                Number: countryCodeService.formatExistingMobileAuNzNumber({
                  isInterNationalPhoneNumbers:
                    $scope.isInternationalMobileNumber,
                  phoneNumber: value.Number,
                }),
                CountryCode: $scope.assignPhoneCountryCodeValues(value),
                SelectedCountryCode: $scope.assignPhoneCountryCodeValues(value),
              },
            );
          });
          intializeSubscriptions({
            $scope,
            contactService,
            currentUserService,
            optionsService,
            $window,
            $timeout,
          });
        });
      }

      $scope.assignPhoneCountryCodeValues = (value) => {
        return setCountryCode({
          countryCode: value.CountryCode || '',
          defaultCountryCode: countryCodeService.setDefaultCountryCode({
            isInternationalPhoneNumbers: $scope.isInternationalMobileNumber,
            defaultCountryCode: $scope.isAU,
          }),
        });
      };

      $scope.getClientByFamilyId = function (isHttpRequest) {
        contactService
          .clientInformGet($scope.familyId, null, isHttpRequest)
          .then((clientInformGetResponse) => {
            $scope.clientsAdultData = [];
            $scope.clientsChildData = [];
            $scope.numberOfAdults = 0;
            $scope.clientsDataObject = clientInformGetResponse.data;

            if ($scope.clientsDataObject && $scope.clientsDataObject.length) {
              $scope.clientsAdultData = $scope.clientsDataObject.reduce(
                (initial, currentObj) => {
                  if (currentObj.Role !== 'Child') {
                    initial.push(currentObj);
                  }
                  return initial;
                },
                [],
              );
              $scope.clientsChildData = $scope.clientsDataObject.reduce(
                (initial, currentObj) => {
                  if (currentObj.Role === 'Child') {
                    const ageDifMs = Date.now() - new Date(initial.DOB);
                    const ageDate = new Date(ageDifMs); // miliseconds from epoch
                    initial.background = colorService.getRandomColor();
                    initial.initials = utilitiesService.filterInitial(
                      initial.FirstName,
                      initial.LastName,
                    );
                    initial.Age = Math.abs(ageDate.getUTCFullYear() - 1970);
                    initial.push(currentObj);
                  }
                  return initial;
                },
                [],
              );
            }
            angular.element('.clients-list-box').matchHeight();
            $scope.numberOfAdults = $scope.clientsAdultData.length;
            $uibModalInstance.close({ updateApplicant: true });
            $scope.clientsData = $scope.clientsDataObject;
          });
      };
      $scope.updatePhoneDetailsValue = ({ value, key }) => {
        const assignSelectedCountryCode = assignSelectedCountryValue({
          inputElement: angular.element(`#${getPhoneInputId(key)}`),
          flagCondition: $scope.isInternationalMobileNumber,
          currentUserService,
        }).iso2;

        return Object.assign(
          {},
          {
            ...value,
            SelectedCountryCode: assignSelectedCountryCode,
            CountryCode: _.get(
              value,
              'SelectedCountryCode.iso2',
              assignSelectedCountryCode,
            ),
          },
        );
      };
      $scope.checkFormAndMobileValidation = function (phoneNumbers, form) {
        const { homeNumber, workNumber, mobilePhoneNumber } = phoneNumbers;
        const isIntlPhoneInputHome = angular.element('#homePhone');
        const isIntlPhoneInputBusiness = angular.element('#businessPhone');
        const isIntlPhoneInputMobile = angular.element('#mobileNumber-dc');

        const isIntlTelInputPhoneValid =
          !countryCodeService.validatePhoneNumber({
            element: isIntlPhoneInputMobile,
            isPhoneRequired: true,
            message: 'Mobile field is required',
            phoneNumber: mobilePhoneNumber,
            isOtherFieldInvalid: isFormInputValid(form),
            isLandline:
              !$scope.isInternationalPhoneNumbers &&
              $scope.isPhoneNumberCountryCodeNZ,
          }) ||
          !countryCodeService.validatePhoneNumber({
            element: isIntlPhoneInputHome,
            isPhoneRequired: false,
            message: '',
            phoneNumber: homeNumber,
            isOtherFieldInvalid: isFormInputValid(form),
            isLandline: true,
          }) ||
          !countryCodeService.validatePhoneNumber({
            element: isIntlPhoneInputBusiness,
            isPhoneRequired: false,
            message: '',
            phoneNumber: workNumber,
            isOtherFieldInvalid: isFormInputValid(form),
            isLandline: true,
          });

        if (isIntlTelInputPhoneValid) {
          return false;
        }

        return true;
      };
      $scope.addClient = function (form) {
        let mobileNumber = null;
        const isMobileAvailable =
          $scope.contact &&
          $scope.contact.length &&
          $scope.contact[0].Phone &&
          $scope.contact[0].Phone.length;
        if (isMobileAvailable) {
          if ($scope.isInternationalMobileNumber) {
            $scope.contact[0].Phone = $scope.contact[0].Phone.map(
              (value, key) => {
                if (value.Number) {
                  return $scope.updatePhoneDetailsValue({ value, key });
                }
                return value;
              },
            );
            const mobileObject = $scope.contact[0].Phone.find(
              (obj) => obj.Type === 'Mobile',
            );
            mobileNumber = mobileObject.Number;
          } else {
            const mobileObject = $scope.contact[0].Phone.find(
              (obj) => obj.Type === 'Mobile',
            );
            if (mobileObject.Number) {
              mobileNumber = mobileObject.Number;
              $scope.contact[0].Phone = $scope.contact[0].Phone.map(
                (value, key) => {
                  return $scope.updatePhoneDetailsValue({ value, key });
                },
              );
            }
          }
        }
        if (
          isMobileInvalid({
            $scope,
            mobileNumber,
            isMobileValid,
            toastError,
          })
        ) {
          return false;
        }
        let firstError = null;
        const phoneList = _.get($scope.contact[0], 'Phone');
        const phoneNumbers = {
          homeNumber: _.get(phoneList, `[${PHONE_TYPE_INDEX.HOME}].Number`),
          workNumber: _.get(phoneList, `[${PHONE_TYPE_INDEX.WORK}].Number`),
          mobilePhoneNumber: _.get(
            phoneList,
            `[${PHONE_TYPE_INDEX.MOBILE}].Number`,
          ),
        };
        const primaryEmail = _.get($scope.contact[0], 'Email[0].EmailAddress');
        const isEmailValid = !primaryEmail || emailValidation(primaryEmail);
        if (
          $scope.isInternationalMobileNumber &&
          !$scope.checkFormAndMobileValidation(phoneNumbers, form)
        ) {
          return;
        } else if (form.$invalid || !isEmailValid) {
          firstError = null;
          form &&
            Object.keys(form).forEach((field) => {
              if (field[0] !== '$') {
                if (firstError === null && !form[field].$valid) {
                  firstError = form[field].$name;
                }

                if (form[field].$pristine) {
                  form[field].$dirty = true;
                }
              }
            });

          if (!isEmailValid) {
            toastError('Invalid Form - Please provide a valid email address.');
            return;
          }

          angular.element(`.ng-invalid[name=${firstError}]`).focus();
          toastError('Invalid Form - Please fill up the form properly');
          return;
        }

        //
        $timeout(() => {
          $scope.contact[0].CountryOfBirth = $scope.contact[0].CountryCode;

          // let just pass in the 3 new properties
          /*
                        @CitizenshipId
                        @CountryOfResidencyId
                        @ResidencyId
                    */
          let CitizenshipObj = _.find($scope.countryList, (o) => {
            return o.CountryCode === $scope.contact[0].CountryCode;
          });
          let CountryOfResidencyObj = _.find($scope.countryList, (o) => {
            return o.CountryCode === $scope.contact[0].CountryOfResidencyCode;
          });
          if (!CitizenshipObj) {
            CitizenshipObj = {
              CountryID: 0,
            };
          }
          if (!CountryOfResidencyObj) {
            CountryOfResidencyObj = {
              CountryID: 0,
            };
          }

          angular.extend($scope.contact[0], {
            CitizenshipId: parseInt(CitizenshipObj.CountryID, 10),
            CountryOfResidencyId: parseInt(CountryOfResidencyObj.CountryID, 10),
            CountryOfBirthId: $scope.getCountryOfBirthId(),
            ResidencyId: parseInt($scope.contact[0].ResidencyId, 10),
            MaritalStatusId: parseInt($scope.contact[0].MaritalStatusId, 10),
            DOB: $scope.contact[0].DOB,
          });

          if (
            $scope.contact[0].customerConsentOptions.ConsentMarketing !== true
          ) {
            $scope.contact[0].customerConsentOptions.ConsentMarketing = false;
          }

          if (
            !_.isUndefined($scope.contact[0].Address) &&
            !_.isUndefined($scope.contact[0].Address[0]) &&
            $scope.contact[0].Address[0].Type === 'Current Address'
          ) {
            $scope.contact[0].Address[0].Type = 'Home';
          }

          $scope.contact[0].MarketingAutomationFlags = getMarketingAutomationString(
            { $scope },
          );
          $scope.isLoading = true;
          contactService
            .addContact($scope.contact)
            .then(() => {
              return setClientPhoneForInternationalNumber({
                $scope,
                contactModelService,
                clientId,
              });
            })
            .then(() => {
              $scope.isLoading = false;
              $scope.getClientByFamilyId();
              if (typeof clientId !== 'undefined') {
                toastSuccess('Updated - client successfully updated');
              } else {
                toastSuccess('Added - client successfully added');
              }
            })
            .catch((error) => {
              $scope.isLoading = false;
              toastError(error.data.Message);
            });
        }, 500);
      };

      $scope.isCountryOfBirthShown = () => {
        return uiService.isCountry('New Zealand');
      };

      $scope.getCountryOfBirthCode = () => {
        const contact = $scope.contact ? $scope.contact[0] : null;
        if (!$scope.countryList || !contact) {
          return '';
        }
        const contactCountryOfBirthId = contact.CountryOfBirthId;
        const CountryOfBirthObj = $scope.countryList.find(
          (country) => country.CountryID === contactCountryOfBirthId,
        );
        return CountryOfBirthObj ? CountryOfBirthObj.CountryCode : '';
      };

      $scope.getCountryOfBirthId = () => {
        const contact = $scope.contact ? $scope.contact[0] : null;
        if (!$scope.countryList || !contact) {
          return 0;
        }
        const contactCountryOfBirthCode = contact.CountryOfBirthCode;
        const CountryOfBirthObj = $scope.countryList.find(
          (country) => country.CountryCode === contactCountryOfBirthCode,
        );
        return CountryOfBirthObj
          ? parseInt(CountryOfBirthObj.CountryID, 10)
          : 0;
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

      // Change Page View On Change Screen Size
      // -------------------------------------------------------------
      $scope.screenSize = $window.innerWidth;
      if ($scope.screenSize < 992) {
        $scope.isNotesPanelDisplayed = false;
        $scope.isAdditionalPanelDisplayed = false;
      } else {
        $scope.isNotesPanelDisplayed = true;
        $scope.isAdditionalPanelDisplayed = true;
      }
      const viewport = function () {
        let e = window;
        let a = 'inner';
        if (!('innerWidth' in window)) {
          a = 'client';
          e = $window.document.documentElement || $window.document.body;
        }
        return {
          width: e[`${a}Width`],
        };
      };

      $scope.getWindowWidth = function () {
        return {
          w: viewport().width,
        };
      };

      $scope.$watch(
        $scope.getWindowWidth,
        (newValue) => {
          const addClientWindowWatcherObj = clientWindowWatcher(newValue);
          Object.keys(addClientWindowWatcherObj).forEach((key) => {
            $scope[key] = addClientWindowWatcherObj[key];
          });
        },
        true,
      );

      // equalize code provided by Edward
      function equalize(target) {
        // manifest
        const columnHeights = [];
        const columns = $window.document.querySelectorAll(target);
        // loop through targets and fetch heights
        for (const [i, element] of columns.entries()) {
          // fetch column heights
          const colHeight = element.offsetHeight;
          // append values into array
          columnHeights[i] = colHeight;
        }
        // sort heights from tallest to shortest
        columnHeights.sort((a, b) => {
          return a > b ? -1 : 1;
        });
        // set a hard code height to equalize
        if (_.size(columns) > 0) {
          _.forEach(columns, (el) => {
            el.style.setProperty('height', `${columnHeights[0]}px`);
          });
        }
      }

      $scope.reevaluateEqualize = function () {
        equalize('[data-equalize]');
      };
      function equalizeCaller() {
        equalize('[data-equalize]');
      }

      $timeout(equalizeCaller, 1000);
    });
