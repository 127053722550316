import template from './moneySelectField.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    inputModel: '<',
    inputId: '@',
    inputChange: '&',
    selectModel: '<',
    selectId: '@',
    selectChange: '&',
    selectOptions: '<',
    isOptionObject: '@',
    currency: '@',
  },
};
