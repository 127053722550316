import { get } from 'lodash';
import { DROPDOWN_DISPLAY } from 'Common/constants/accessTypes';

class AssistantSelectCtrl {
  constructor(optionsService, currentUserService) {
    'ngInject';

    this.optionsService = optionsService;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.onAutoCompleteSelect = this.onAutoCompleteSelect.bind(this);
    this.setFieldDisplayType();
  }

  $onChanges(changes) {
    const { assistantsList, selectedAssistant } = changes;

    const updateAssistantModel =
      get(assistantsList, 'currentValue') ||
      get(selectedAssistant, 'currentValue');
    updateAssistantModel && this.updateAssistantModel();
  }

  onCustomSelectChange({ item = {} }) {
    this.assistantModel = item;
    this.onAssistantSelect();
  }

  updateAssistantModel() {
    const assistantId = get(this.selectedAssistant, 'AssistantFamilyId', 0);
    const list = get(this, 'assistantsList', []);
    const found = list.find((asst) => asst.Value === assistantId.toString());
    this.assistantModel = found;
  }

  setFieldDisplayType() {
    const { accessType } = this.currentUserService;
    this.showDropdown = DROPDOWN_DISPLAY.includes(accessType);
  }

  onAssistantSelect() {
    if (!this.simpleSelection) {
      return;
    }
    this.setAssistantModel();
  }

  onAutoCompleteSelect(data) {
    if (!data) {
      return;
    }
    this.assistantModel = { ...data };

    if (!this.simpleSelection) {
      return;
    }
    this.setAssistantModel();
  }

  setAssistantModel() {
    if (!this.assistantModel) {
      this.onAssistantChange();
      return;
    }

    if (this.showDropdown) {
      const assistantData = {
        ...this.assistantModel,
        originalObject: { ...this.assistantModel },
      };
      this.onAssistantChange({ $e: assistantData });
      return;
    }

    const { originalObject } = this.assistantModel;
    const assistantData = {
      ...this.assistantModel,
      Value: originalObject && originalObject.Value,
      Name: originalObject && originalObject.Name,
    };
    this.onAssistantChange({ $e: assistantData });
  }

  getPlaceholder() {
    const adviserName = get(this.assistantModel, 'Name');
    return adviserName || 'Search Assistant';
  }
}

export default AssistantSelectCtrl;
