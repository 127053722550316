import template from './totalLendingRequired.html';
import controller from './totalLendingRequiredCtrl';
import './style.scss';

export default {
  template,
  bindings: {
    loanAppId: '<',
    showSubtitle: '<',
  },
  controller,
  controllerAs: 'vm',
};
