import template from './contactEmploymentTable.html';
import ContactEmploymentTableCtrl from './contactEmploymentTableCtrl';
import './style.scss';

export default {
  template,
  controller: ContactEmploymentTableCtrl,
  controllerAs: 'vm',
  bindings: {
    employmentTitle: '@?',
    employmentList: '<',
    editHandler: '<?',
    deleteHandler: '<?',
  },
};
