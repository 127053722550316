import angular from 'angular';
import _ from 'lodash';
import { toastError } from 'Common/utilities/alert';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { LOAN_APP_FINANCIAL_POSITION } from 'Common/constants/performance';
import { performance } from 'Common/utilities/perfume';
import { loanAppSectionData } from 'Common/utilities/dataLayer';
import { showLivingExpenseV2 } from 'Common/utilities/showFeature';
import { setCompletionStatus } from './../util/loanAppCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanAppFinancialCtrl', function LoanAppFinancialCtrl(
      $scope,
      loanScenarioService,
      $timeout,
      $stateParams,
      utilitiesService,
      financialsSharedService,
      insuranceFinancialsSharedService,
      loanAppSharedData,
      lodgementSubmissionService,
      uiService,
      configService,
      blueStoneSubmissionService,
      currentUserService,
    ) {
      // eslint-disable-next-line sonarjs/cognitive-complexity
      const initializeFinancialPosition = () => {
        const { livingExpenseV2 } = configService.feature;
        $scope.showLivingExpenseV2 = showLivingExpenseV2({
          livingExpenseV2,
          currentUserService,
        });
        $scope.isFinancialPositionLoaded = false;
        $scope.currentUserService = currentUserService;
        let clickCounter = 0;
        $scope.financialsSharedService.loanAppId = $stateParams.loanAppId || 0;
        $scope.uiService = uiService;
        const isAssetFinanceFeatureOn = configService.feature.assetFinance;
        blueStoneSubmissionService.assetsSection = $scope;
        lodgementSubmissionService.assetsSection = $scope;
        $scope.toggle = uiService.dualLodgementEnabled
          ? lodgementSubmissionService.isFeatureOnAndOff()
          : blueStoneSubmissionService.isFeatureOnAndOff();
        // eslint-disable-next-line unicorn/consistent-function-scoping
        const financialObjectTOShowInSection = (familyId, financialList) => {
          let toReturnObject = {};

          financialList.forEach((object) => {
            if (parseInt(object.FamilyID, 10) === parseInt(familyId, 10)) {
              toReturnObject = object;
            }
          });

          return toReturnObject;
        };
        /* listeners */
        $scope.$on('isLodged', (event, data) => {
          $scope.isLodged = data;
        });
        /* end of listeners */
        const {
          ApplicantGuarantorList,
          ApplicantsNetworth,
          LoanDetails,
        } = loanAppSharedData.loanAppSummary;
        const isAssetFinance =
          LoanDetails &&
          (LoanDetails.LendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE ||
            LoanDetails.LendingCategoryId === LENDING_CATEGORY.PERSONAL_LOAN);
        const hasPersonApplicant =
          ApplicantGuarantorList &&
          ApplicantGuarantorList.InvolvedPartyPerson &&
          Object.keys(ApplicantGuarantorList.InvolvedPartyPerson).length > 0;
        const hasEntityApplicant =
          ApplicantGuarantorList &&
          ApplicantGuarantorList.InvolvedPartyEntity &&
          ApplicantGuarantorList.InvolvedPartyEntity.length > 0;
        const isCommercialAssetLoan =
          isAssetFinance && hasEntityApplicant && !hasPersonApplicant;
        $scope.showNoFinancialsQuestions =
          isAssetFinanceFeatureOn && isCommercialAssetLoan;

        const isStartedUnopenFinancials = () => {
          if (
            ApplicantGuarantorList &&
            Object.keys(ApplicantGuarantorList).length
          ) {
            const firstFamilyKey = Object.keys(
              ApplicantGuarantorList.InvolvedPartyPerson,
            )[0];
            if (_.isUndefined(firstFamilyKey)) {
              return { isStarted: false };
            }
            $scope.familyId =
              ApplicantGuarantorList.InvolvedPartyPerson[
                firstFamilyKey
              ][0].FamilyId;
            if (
              Object.keys(
                ApplicantGuarantorList.InvolvedPartyPerson[firstFamilyKey],
              ).length
            ) {
              const sectionFinancialObject = financialObjectTOShowInSection(
                $scope.familyId,
                ApplicantsNetworth,
              );
              const hasNetworth =
                typeof sectionFinancialObject.NetWorth !== 'undefined' &&
                sectionFinancialObject.NetWorth !== null;
              const hasTotalIncome =
                typeof sectionFinancialObject.TotalIncome !== 'undefined' &&
                sectionFinancialObject.TotalIncome !== null;
              return {
                NetWorth: sectionFinancialObject.NetWorth,
                TotalIncome: sectionFinancialObject.TotalIncome,
                isBothFilled:
                  sectionFinancialObject.NetWorth > 0 &&
                  sectionFinancialObject.TotalIncome > 0,
                isStarted: hasNetworth || hasTotalIncome,
              };
            }
          }
        };

        $scope.processFinancialSection = (sections = '') => {
          if (!$scope.isLoanAppLoaded) {
            return;
          }
          $scope.isFinancialPositionLoaded = true;
          performance.start(LOAN_APP_FINANCIAL_POSITION);
          blueStoneSubmissionService.toggleBySections(sections);
          if (clickCounter) {
            $scope.validateApplicantsList();
            return;
          }

          clickCounter++;

          $scope.callerApp = '';

          if ($stateParams.insAppId) {
            $scope.callerApp = 'insurance';
          }

          $scope.financialsSharedService =
            $scope.callerApp !== 'insurance'
              ? financialsSharedService
              : insuranceFinancialsSharedService;
          $scope.financialsSharedService.updateFinancialApplicantList();
          $scope.financialsSharedService.loanAppId =
            $stateParams.loanAppId || 0;
          $scope.financialsSharedService.familyId = $stateParams.familyId;

          $scope.loanAppId = $stateParams.loanAppId;
          $scope.familyId = $stateParams.familyId;

          const getLoanApplicationNotesList = () => {
            loanScenarioService
              .brokerNotesGet($scope.loanAppId)
              .then((response) => {
                $scope.assess = {
                  financials: {},
                };

                angular.forEach(response.data, (value) => {
                  switch (value.SubsectionId) {
                    case 7:
                      $scope.assess.financials.realEstateNotes =
                        value.BrokerNotes;
                      break;
                    case 8:
                      $scope.assess.financials.assetsNotes = value.BrokerNotes;
                      break;
                    case 9:
                      $scope.assess.financials.liabilitiesNotes =
                        value.BrokerNotes;
                      break;
                    case 10:
                      $scope.assess.financials.incomeNotes = value.BrokerNotes;
                      break;
                    case 11:
                      $scope.assess.financials.expensesNotes =
                        value.BrokerNotes;
                      break;
                    default:
                      break;
                  }
                });
              });
          };

          $scope.noteListInit = () => {
            if ($scope.callerApp !== 'insurance') {
              getLoanApplicationNotesList();
            }
          };

          $scope.noteListInit();

          if (_.isUndefined($scope.financialsSharedService.selectedFamily)) {
            $scope.financialsSharedService.selectedFamily = {
              FamilyId: $scope.familyId.toString(),
              initials: '',
            };
          }

          $scope.changeSelectedFamily = () => {
            let family = _.find($scope.listApplicants, (o) => {
              return _.isEqual(
                o.FamilyId,
                $scope.financialsSharedService.selectedFamily.FamilyId,
              );
            });
            if (!family) {
              family = {
                FamilyName: '',
              };
            }
            angular.extend($scope.financialsSharedService.selectedFamily, {
              initials: utilitiesService.filterInitialOneString(
                family.FamilyName,
              ),
              background: $scope.getBGcolor(family.FamilyId),
              FamilyName: family.FamilyName,
            });
          };
          const duration = performance.endPaint(LOAN_APP_FINANCIAL_POSITION);
          loanAppSectionData({
            label: LOAN_APP_FINANCIAL_POSITION,
            value: duration,
          });
        };

        $scope.validateApplicantsList = () => {
          loanAppSharedData
            .getLoanAppApplicants($scope.financialsSharedService.loanAppId)
            .then((response) => {
              if (response && response.data) {
                $scope.financialsSharedService.FinancialApplicantList(
                  response.data,
                );
              }
            });
        };

        const sectionObject = loanAppSharedData.getSectionObjectById(
          loanAppSharedData.completionListObject,
          SECTION_ID.FINANCIAL_POSITION,
        );
        $scope.countValids(sectionObject.IsCompleted, 'financials');

        const validateFinancialOpen = () => {
          $scope.isValidFinancials =
            sectionObject.IsCompleted ||
            financialsSharedService.isValidFinancials;
          const financialSectionObject = isStartedUnopenFinancials();
          $scope.isStartedFinancials =
            $scope.isStartedFinancials || financialSectionObject.isStarted;
          if (!$scope.isStartedFinancials) {
            return;
          }
          $scope.netWorth = financialSectionObject.NetWorth;
          $scope.incomeTotal = financialSectionObject.TotalIncome;
          if (sectionObject.IsCompleted !== $scope.isValidFinancials) {
            setCompletionStatus({
              $scope,
              $timeout,
              sectionId: SECTION_ID.FINANCIAL_POSITION,
              loanAppSharedData,
              isValidSection: $scope.isValidFinancials,
            });
          }
        };
        $scope.isValidFinancials = sectionObject.IsCompleted;
        financialsSharedService.updateFinancialApplicantList().then(() => {
          Promise.all([
            financialsSharedService.IncomeInfoGet(),
            financialsSharedService.ExpenseInfoGet(),
          ])
            .then(() => {
              $scope.isStartedFinancials =
                financialsSharedService.isStartedFinancials;
              validateFinancialOpen();
              $scope.initWatchers();
            })
            .catch(toastError);
        });

        $scope.initWatchers = () => {
          const financialsSharedServiceWatcher = $scope.$watch(
            'financialsSharedService',
            (nv, ov) => {
              $scope.listApplicants = nv.listApplicants;
              financialsSharedService.updateOnApplicantsChange({
                previousValue: ov.involvedParties,
                currentValue: nv.involvedParties,
              });

              $scope.assetLength = nv.assetLength;
              $scope.liabilityLength = nv.liabilityLength;
              $scope.incomeLength = nv.incomeLength;
              $scope.expenseLength = nv.expenseLength;

              $scope.assetTotal = nv.assetTotal;
              $scope.NonREATotalAmount = nv.NonREATotalAmount;
              $scope.RealEstateTotalAmount = nv.RealEstateTotalAmount;
              $scope.liabilityTotal = nv.liabilityTotal;
              $scope.incomeTotal = nv.incomeTotal;
              $scope.expenseTotal = nv.expenseTotal;

              $scope.netWorth = $scope.assetTotal - $scope.liabilityTotal;

              $scope.isValidFinancials = nv.isValidFinancials;
              $scope.isStartedFinancials = nv.isStartedFinancials;

              setCompletionStatus({
                $scope,
                $timeout,
                sectionId: SECTION_ID.FINANCIAL_POSITION,
                loanAppSharedData,
                isValidSection: $scope.isValidFinancials,
              });

              $scope.countValids($scope.isValidFinancials, 'financials');
            },
            true,
          );

          const loanAppApplicantsWatcher = $scope.$watch(
            'loanAppSharedData.loanAppApplicants',
            (nv, ov) => {
              const newApplicants = nv || {};
              const oldApplicants = ov || {};
              const newList = _.concat(
                newApplicants.InvolvedPartyPerson,
                newApplicants.InvolvedPartyEntity,
              );
              const oldList = _.concat(
                oldApplicants.InvolvedPartyPerson,
                oldApplicants.InvolvedPartyEntity,
              );
              if (newList.length !== oldList.length) {
                $scope.validateApplicantsList();
              }
            },
            true,
          );

          $scope.$on('$destroy', () => {
            if (financialsSharedServiceWatcher) {
              financialsSharedServiceWatcher();
            }
            if (loanAppApplicantsWatcher) {
              loanAppApplicantsWatcher();
            }
          });
        };

        $scope.onExpensesChange = () => {
          financialsSharedService
            .ExpenseInfoGet()
            .then(() => validateFinancialOpen());
        };
      };

      $scope.$watch('isLoanAppLoaded', () => {
        if (!$scope.isLoanAppLoaded) {
          return;
        }
        initializeFinancialPosition();
      });
    });
