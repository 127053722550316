import template from './insuranceReferralsSection.html';
import controller from './insuranceReferralsSectionCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppFormWarning: '<',
    isLoanAppLoaded: '<',
    loanAppId: '<',
    familyId: '<',
    isCompleted: '<',
    isLodged: '<',
    onChangeStatus: '&',
    notesLabel: '<',
    brokerNotesId: '<',
    onSaveNote: '&',
  },
};
