import { getDatePickerConfig } from 'Common/utilities/date';
import { DD_MMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';
import { toastSaveSuccess } from 'Common/utilities/alert';

export default class ProviderTablesCtrl {
  constructor(
    $window,
    $uibModal,
    constants,
    accreditationService,
    accreditationUtilityService,
    currentUserService,
    configService,
  ) {
    'ngInject';

    this.$window = $window;
    this.$uibModal = $uibModal;
    this.constants = constants;
    this.accreditationService = accreditationService;
    this.accreditationUtilityService = accreditationUtilityService;
    this.currentUserService = currentUserService;
    this.configService = configService;
    this.processLenderSearch = this.processLenderSearch.bind(this);
    this.processRefresh = this.processRefresh.bind(this);
  }

  $onInit() {
    this.providerList = [];
    this.lenderOriginalList = [];
    this.selectedLender = null;
    this.mainObject = {};
    this.dateOptions = getDatePickerConfig();
    this.dateFormatted = DD_MMM_YYYY_FORMAT;
    this.isCorpAccreditationTableEnabled = this.configService.feature.corpAccreditationTable;

    const { hasAssistantAccess } = this.currentUserService;
    const assistantWithNoFamilyIdProvided =
      hasAssistantAccess && !this.familyId;

    if (!assistantWithNoFamilyIdProvided) {
      this.getAccreditations(this.familyId);
    }

    this.onSearchLenderFromParent({
      handler: this.processLenderSearch,
    });

    this.onRefreshList({
      handler: this.processRefresh,
    });
  }

  getAccreditations(familyId) {
    this.fetching = true;
    return this.accreditationService
      .getAccreditations(
        familyId,
        this.accreditationType,
        !!this.isIndividualProfile,
      )
      .then(({ data }) => {
        this.fetching = false;
        this.providerList = [...data];
        this.lenderOriginalList = [...data];
      });
  }

  processLenderSearch({ type, payload }) {
    if (type === 'searchLenders') {
      this.onSearchLenders(payload);
    } else if (type === 'searchAdviser') {
      this.getAccreditations(payload);
    }
  }

  processRefresh({ payload }) {
    this.getAccreditations(payload);
  }

  onSearchLenders(payload) {
    if (!payload) {
      this.providerList = this.lenderOriginalList;
      return;
    }
    this.providerList = this.lenderOriginalList.filter((lender) =>
      lender.providerName.toLowerCase().includes(payload.toLowerCase()),
    );
  }

  showCalendar(index, key = 'datePopUp') {
    this.providerList[index][key] = true;
  }

  onRowRefresh({ providerId }) {
    this.selectedLender = providerId;
  }

  onChangeStatus(_status, lender, _statusId) {
    const status = _statusId
      ? lender.accreditationStatus.find((s) => s.statusId === _statusId)
      : _status;
    const defaultDates = this.accreditationUtilityService.setDate();

    this.lenderOriginalList = [...this.providerList];
    const { statusId, isCancelled, isAccredited } = status;
    this.onSaveLender({
      ...lender,
      ...{
        status: statusId,
        statusId,
        ...(isCancelled && { cancelledDate: defaultDates }),
        ...(isAccredited && { dateAccredited: defaultDates }),
      },
    });
  }

  onAccreditationChange(lender) {
    this.onSaveLender(lender);
  }

  onSaveLender(providers = {}) {
    providers.familyId = this.familyId;
    this.onRowRefresh(providers);
    this.accreditationService.setLender(providers, (onPromise, mapped) => {
      onPromise.then(({ data }) => {
        const filterProvider = mapped(data);
        const currentProvider = this.providerList.findIndex(
          (provider) => provider.providerId === providers.providerId,
        );

        if (!this.isCorpAccreditationTableEnabled) {
          this.providerList[currentProvider] = filterProvider;
        }
        this.selectedLender = null;
        toastSaveSuccess();
      });
    });
  }

  openAccreditationContact(accreditation) {
    const {
      providerName,
      status,
      adviserId,
      issueCodes,
      dateAccreditedStatus,
      dateReceivedStatus,
      signatureDetails,
    } = accreditation;
    const { isNZ } = this.currentUserService;
    const email = isNZ
      ? 'team@nzfsg.co.nz'
      : 'accreditations@loanmarket.com.au';
    const modalTitle = isNZ ? 'Helpdesk' : 'Accreditations';
    const asterisk = `<strong>*************************************************************************************************</strong><br />`;
    const noBorderStyle = 'border: 0 !important';
    const firstColumnStyle = 'padding-right: 40px !important';

    const headerTemplate = `
      <div>
        ${asterisk}
        <table style="${noBorderStyle}">
          <tr>
            <th style="border:0 !important; text-align: left" colspan="2"><strong>Accreditations Info</strong></th>
          </tr>
          <tr>
            <td style="${noBorderStyle}; ${firstColumnStyle}">
              ${this.mainRowCellContent()}
            </td>
            <td style="${noBorderStyle}">
              ${providerName || 'N/A'}
            </td>
          </tr>
          <tr>
            <td style="${noBorderStyle}; ${firstColumnStyle}">
              Accreditation Status
            </td>
            <td style="${noBorderStyle}">
              ${status || 'N/A'}
            </td>
          </tr>
          <tr>
            <td style="${noBorderStyle}; ${firstColumnStyle}">
              ${isNZ ? 'Adviser ID' : 'Adviser Code'}
            </td>
            <td style="${noBorderStyle}">
              ${(isNZ ? adviserId : issueCodes) || 'N/A'}
            </td>
          </tr>
          <tr>
            <td style="${noBorderStyle}; ${firstColumnStyle}">
              Date ${isNZ ? 'Accredited' : 'Received'}
            </td>
            <td style="${noBorderStyle}">
              ${(isNZ ? dateAccreditedStatus : dateReceivedStatus) || 'N/A'}
            </td>
          </tr>
          <tr>
            <td style="${noBorderStyle}; ${firstColumnStyle}">
              ${isNZ ? 'Adviser' : 'Broker'} Name
            </td>
            <td style="${noBorderStyle}">
              ${this.adviserName} [${signatureDetails.email}]
            </td>
          </tr>
        </table>
        ${asterisk}
      </div>`;
    const title = signatureDetails.contactType
      ? `${signatureDetails.contactType} <br />`
      : '';
    const footerTemplate = `
      Best Regards,
      <p>
        ${signatureDetails.brokerName}<br />
        <strong>
          ${title}
          ${signatureDetails.mobilePhone}
        </strong>
      </p>
    `;

    const template = `
      ${headerTemplate}
      <br />
      <br />
      ${footerTemplate}
    `;

    this.$uibModal.open({
      animation: true,
      template: `
        <accreditation-contact-modal
          modal-instance="vm.modalInstance"
          email="vm.props.email"
          template="vm.props.template"
          modal-title="vm.props.modalTitle"
        >
        </accreditation-contact-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      size: 'md',
      resolve: {
        props: () => ({
          email,
          template,
          modalTitle,
        }),
      },
    });
  }

  mainRowCellContent() {
    return this.currentUserService.isAU ? 'Lender' : this.accreditationLabel;
  }
}
