import angular from 'angular';
import _ from 'lodash';
import { PHONE } from 'Common/constants/phoneMobileFormat';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { getFullName } from 'Common/utilities/contact';
import {
  adviserDetailsBuilderForUI,
  adviserBrokerSupportBuilderForUI,
  adviserLeadsBuilderForUI,
  adviserLeadsBuilderForAPI,
  adviserBrokerSupportBuilderForAPI,
  adviserOrgDetailsBuilderForUI,
} from 'Common/mappers/corporate';
import { mapCustomConfig } from 'Common/utilities/gMap';
import { isLMBranded, isOwnBrand } from 'Common/utilities/brandingCategory';

export const loadModule = () =>
  angular
    .module('app')
    .factory('commonFnService', function commonFnService(
      corporateService,
      dashboardService,
      generalService,
      utilitiesService,
      colorService,
      userService,
      mapService,
      SweetAlert,
      googleMapService,
      toaster,
      $q,
      $uibModal,
    ) {
      /*
       *   =====================================
       *
       *           FUNCTION REFERENCE
       *   =====================================
       */
      const dateStaticValue = '1900-01-01';

      /**
       * @param {integer} familyId - adviser familyId
       * @param {integer} clientId - client familyId
       * @param {integer} loanAppId  - Pass Id in individual loan app zero if not specified
       * @param {string} clientName  - client name
       * @description Re-allocate Loan
       */
      const reallocateLoans = (
        familyId,
        clientId,
        loanAppId,
        clientName,
        isMessageShow = true,
      ) => {
        return corporateService
          .reallocateLoanPipelineSet(familyId, clientId, loanAppId)
          .then(
            (response) => {
              if (response.data > 0) {
                if (!isMessageShow) return false;
                toaster.pop(
                  'success',
                  'Successfully',
                  `${clientName}'s loans successfully reallocated.`,
                );
              } else {
                if (!isMessageShow) return;
                toaster.pop(
                  'error',
                  'Reallocation Failed',
                  `${clientName}'s loans failed to reallocate.`,
                );
              }
            },
            () => {
              if (!isMessageShow) return;
              toaster.pop(
                'error',
                'Reallocation Failed',
                `${clientName}'s loans failed to reallocate.`,
              );
            },
          );
      };
      /**
       * @param {integer} familyId - adviser familyId
       * @param {integer} clientId - client familyId
       * @param {string} adviserName - adviser name
       * @param {string} clientName  - client name
       * @param {integer} loanAppId  - Pass Id in individual loan app zero if not specified
       * @description Check if Loan Exist
       */
      const tranferLoanApp = (
        familyId,
        clientId,
        adviserName,
        clientName,
        loanAppId,
        callback,
      ) => {
        if (loanAppId) {
          return reallocateLoans(
            familyId,
            clientId,
            loanAppId,
            clientName,
            true,
          );
        }

        corporateService.checkLoanPipelineGet(clientId).then((response) => {
          const loanIds = response.data;
          if (!loanIds || !loanIds.length) return;

          SweetAlert.swal(
            {
              title: 'Re-allocate Loans',
              text: `Do you wish to also reallocate the loans for ${clientName} to ${adviserName}?`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Reallocate',
              closeOnConfirm: true,
            },
            (confirmed) => {
              if (!confirmed) return;

              Promise.all(
                loanIds.map((id, idx) =>
                  reallocateLoans(
                    familyId,
                    clientId,
                    id,
                    clientName,
                    idx === loanIds.length - 1,
                  ),
                ),
              ).then(callback);
            },
          );
        });
      };

      /**
       * @description Save Contact Address including Adviser, Assistant
       * @param {integer} familyId
       * @param {integer} clientId
       * @param {integer} addressId
       * @param {boolean} isMailing
       * @param {boolean} isBusiness
       */
      const saveContactAddress = (
        familyId,
        clientId,
        addressId,
        isMailing,
        isBusiness,
        isCustomAddress,
      ) => {
        const defer = $q.defer();
        corporateService
          .familyAddressSet(
            familyId,
            clientId,
            addressId,
            isMailing,
            isBusiness,
            false,
            isCustomAddress,
          )
          .then(
            (adviserResponse) => {
              if (adviserResponse) {
                defer.resolve(adviserResponse.data);
              }
            },
            (error) => {
              defer.reject(error);
            },
          );
        return defer.promise;
      };
      /**
       * Description  : Save Organisation Address including Adviser Org, Referrer Org
       * @param {integer} organisationId
       * @param {integer} complianceId
       * @param {integer} addressId
       * @param {boolean} isMailing
       * @param {boolean} isBusiness
       */
      const saveOrganisationAddress = (
        organisationId,
        complianceId,
        addressId,
        isMailing,
        isCustomAddress,
      ) => {
        const defer = $q.defer();
        corporateService
          .organizationAddressSet(
            organisationId,
            complianceId,
            addressId,
            isMailing,
            false,
            isCustomAddress,
          )
          .then(
            (response) => {
              if (response) {
                defer.resolve(response.data);
              }
            },
            (error) => {
              defer.reject(error);
            },
          );
        return defer.promise;
      };

      /**
       *
       * @param {integer} id - FamilyId or OrganisationId
       * @param {integer} subId - ClientId or ComplianceId
       * @param {object} addressObj
       * @param {boolean} isMailing
       * @param {boolean} isBusiness
       * @param {boolean} isOrgAddress - Determine if Organisation Address
       * @param {boolean} isCustomAddress - Determine if manually added
       */
      const saveAddress = (
        id,
        subId,
        addressObj,
        isMailing,
        isBusiness,
        isOrgAddress,
        isCustomAddress = false,
      ) => {
        const defer = $q.defer();
        if (
          addressObj &&
          addressObj.formatted_address &&
          addressObj.formatted_address.trim()
        ) {
          addressObj.isCustomAddress = isCustomAddress;
          dashboardService.addressDetailsSet(addressObj).then(
            (response) => {
              if (response.data) {
                if (isOrgAddress) {
                  saveOrganisationAddress(
                    id,
                    subId,
                    response.data,
                    isMailing,
                    isCustomAddress,
                  ).then((promise) => {
                    defer.resolve(promise);
                  });
                } else {
                  saveContactAddress(
                    id,
                    subId,
                    response.data,
                    isMailing,
                    isBusiness,
                    isCustomAddress,
                  ).then((promise) => {
                    defer.resolve(promise);
                  });
                }
              }
            },
            (error) => {
              toaster.pop('error', 'Failed to Updated', 'Address');
              defer.reject(error);
            },
          );
        } else if (isOrgAddress) {
          saveOrganisationAddress(
            id,
            subId,
            0,
            isMailing,
            isCustomAddress,
          ).then((promise) => {
            defer.resolve(promise);
          });
        } else {
          saveContactAddress(
            id,
            subId,
            0,
            isMailing,
            isBusiness,
            isCustomAddress,
          ).then((promise) => {
            defer.resolve(promise);
          });
        }
        return defer.promise;
      };
      /*
       *   =====================================
       *         FUNCTION DEFINITION(S)
       *   =====================================
       */
      function checkFormDateValidation(formName, inputName, object, key) {
        if (formName[inputName].$invalid) {
          toaster.pop(
            'error',
            'Error',
            'Invalid date format, it must be dd-MM-yyyy or dd/MM/yyyy!',
          );
          return false;
        }
        if (!object[key]) {
          object[key] = dateStaticValue;
        } else {
          object[key] = object[key].toDateString();
        }
        return true;
      }

      const checkInputPattern = (formName, inputName) => {
        if (formName[inputName].$invalid)
          toaster.pop(
            'error',
            'Error',
            'Invalid phone format, it must not be maximum of 25 characters',
          );
        return formName[inputName].$invalid;
      };

      function sendEmailNotification(setType, date) {
        corporateService[setType](date).then(() => {});
      }

      function checkEmailValidity(email, familyId, excludeWorkEmail) {
        const defer = $q.defer();
        generalService
          .getCheckIfEmailExists(email, familyId, 0, '', excludeWorkEmail)
          .then(
            (response) => {
              defer.resolve(response.data > 0);
            },
            (err) => {
              defer.reject(err);
            },
          );
        return defer.promise;
      }

      function formatDateString(date) {
        const tmpDate = new Date(date);
        let month = 0;
        let day = 0;

        month = tmpDate.getMonth() + 1;
        day = tmpDate.getDate();

        if (month < 10) month = `0${month}`;
        if (day < 10) day = `0${day}`;
        return `${tmpDate.getFullYear()}-${month}-${day}`;
      }

      function convertPipeToCSV(str) {
        if (str) {
          return str.replace(/\|/g, ', ');
        }
        return str;
      }

      function setBooleanToInt(value) {
        return value ? 1 : 0;
      }

      function setAddressTypes(address) {
        const tmp = {};
        angular.forEach(address, (value) => {
          if (value.isBusiness) {
            tmp.business = value;
          } else if (value.isMailing) {
            tmp.mailing = value;
          } else {
            tmp.home = value;
          }
        });
        return tmp;
      }

      function onRetrieveError() {
        toaster.error(
          'There was a problem retrieving the feedbacks. Please try again.',
          'Network Error',
        );
      }

      function dateEditMode(obj, key, editMode) {
        if (formatDateString(obj[key]) === dateStaticValue) {
          obj[key] = new Date();
        } else if (!obj[key] || _.isUndefined(obj[key])) {
          obj[key] = new Date();
        } else {
          obj[key] = new Date(obj[key]);
        }
        editMode[key] = true;
        return editMode[key];
      }

      function setEditMode(object, key) {
        object[key] = false;
        return object[key];
      }

      function formatDate(date) {
        return new Date(date);
      }

      function setMapInit(addressObj, mapId) {
        if (!addressObj) return;
        const address = addressObj.formatted_address
          ? addressObj.formatted_address
          : '';
        const latitude = addressObj.latitude ? addressObj.latitude : '';
        const longitude = addressObj.longitude ? addressObj.longitude : '';
        const mapConfig = mapCustomConfig();
        const customMapConfig = { map: { mapTypeControl: false } };
        const map = { ...mapConfig.map, ...customMapConfig.map };
        const overrideMapCustomConfig = {
          ...mapConfig,
          map,
          hasInfoWindow: true,
        };
        const mapRequiredConfig = { mapId, latitude, longitude, address };
        googleMapService.mapCorporate(
          mapRequiredConfig,
          overrideMapCustomConfig,
        );
      }

      function processBorrowerNames(value) {
        if (value.LastName === '') {
          value.initials = utilitiesService.filterInitialOneString(
            value.FirstName,
          );
          value.DisplayName = value.FirstName;
        } else {
          value.initials = utilitiesService.filterInitial(
            value.FirstName,
            value.LastName,
          );
          value.background = colorService.getRandomColor();
          value.DisplayName = getFullName(value.FirstName, value.LastName);
        }
        return value;
      }
      function processTickedBorrower(response, borrowers, temp) {
        const tmp = [];
        if (_.size(borrowers) <= _.size(response.data)) {
          response.data &&
            Object.keys(response.data).forEach((x) => {
              const value = response.data[x];
              value.ticked = !!_.find(borrowers, (o) => {
                return (
                  parseInt(o.BorrowerID, 10) === parseInt(value.BorrowerID, 10)
                );
              });
              value.PreferedName = `${value.FirstName || ''} ${
                value.LastName || ''
              }`;
              tmp.push(value);
            });
        } else {
          response.data &&
            Object.keys(response.data).forEach((x) => {
              const value = response.data[x];
              value.ticked = parseInt(value.BorrowerID, 10) === -1;
              value.PreferedName = `${value.FirstName || ''} ${
                value.LastName || ''
              }`;
              tmp.push(value);
            });

          // $scope.borrowingEntityListSelected.push(temp);
        }
        if (response.data.length > 1) {
          const isUntickedExists = tmp.some((object) => !object.ticked);

          if (!isUntickedExists) {
            temp.ticked = true;

            tmp.map((object) => {
              object.ticked = false;
              return object;
            });
          }

          tmp.unshift(temp);
        }
        return tmp;
      }
      /**
       * This function use to validate some NZ requirements
       */
      const hiddenClientForNz = () => {
        const defer = $q.defer();
        userService.GetUserInfo().then(
          (response) => {
            const userInfo = response.data;
            if (userInfo) {
              defer.resolve(
                userInfo.CountryId === 1 && userInfo.AccessType === 5,
              );
            } else {
              defer.resolve(false);
            }
          },
          (error) => {
            defer.reject(error);
          },
        );
        return defer.promise;
      };

      const getButtonClassNamePerStatusName = (
        isbtn,
        statusName,
        isFontColor,
      ) => {
        const buttonClass = [
          {
            name: 'Active',
            class: 'btn-light-blue',
            icon: 'fa fa-check',
            color: 'text-white',
          },
          {
            name: 'Onboarding',
            class: 'btn-success',
            icon: 'fa fa-paper-plane',
            color: 'text-white',
          },
          {
            name: 'Suspended',
            class: 'btn-danger',
            icon: 'fa fa-ban',
            color: 'text-white',
          },
          { name: 'Prospect', class: 'btn-warning', icon: 'fa fa-times' },
          { name: 'Terminated', class: 'btn-default', icon: '' },
          {
            name: 'Ceased by Adviser',
            class: 'btn-info',
            icon: 'fa fa-hand-paper-o',
            color: 'text-white',
          },
          {
            name: 'Inactive',
            class: 'btn-danger',
            icon: 'fa fa-times',
            color: 'text-white',
          },
          { name: '', class: 'btn-default', icon: '', color: 'crm-text' },
        ];
        let finalBtnClass = '';
        _.forEach(buttonClass, (value) => {
          if (statusName === value.name) {
            if (isbtn) {
              finalBtnClass = value.class;
            } else if (!isbtn) {
              if (!isFontColor) {
                finalBtnClass = `${value.color} ${value.icon}`;
              } else {
                finalBtnClass = `${value.color}`;
              }
            }
            return false;
          }
        });
        return finalBtnClass;
      };

      const emailValidator = (email) => {
        let isValid = false;
        if (typeof email === 'string') {
          const emailRegex = /^(([^<>()\x5b\]\\.,;:\s@"]+(\.[^<>()\x5b\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          isValid = emailRegex.test(email.toLowerCase());
        }
        return isValid;
      };

      const getAllObjectFieldKey = (setterType) => {
        let editFieldObjects = {};
        switch (setterType) {
          case 'familyBrokerSupportSet':
            editFieldObjects = {
              mortgageToolAccessName: 'MortgageToolAccessName',
              insuranceToolsAccessName: 'InsuranceToolsAccessName',
              fnGInsurance: 'FnGInsurance',
              accessTypeName: 'AccessTypeName',
              offshoring: 'Offshoring',
              micAccess: 'MICAccess',
              agreementSigned: 'AgreementSigned',
              customerContact: 'CustomerContact',
            };
            break;
          case 'familyMarketingDetailSet':
            editFieldObjects = {
              campaignBreezeEnabled: 'CampaignBreezeEnabled',
            };
            break;
          default:
            break;
        }

        return editFieldObjects;
      };

      const attachFileMgmt = (familyId) => {
        return $uibModal.open({
          templateUrl:
            'assets/views/corporate/attachFileMgmt/attachFileMgmt.html',
          size: 'lg',
          keyboard: false,
          controller: 'AttachFileMgmtCtrl',
          resolve: {
            familyId() {
              return familyId;
            },
          },
        });
      };

      const getAdviserOrgMobileFormat = (countryId) => {
        const id = parseInt(countryId, 10);
        switch (id) {
          case COUNTRY_TYPE.NEW_ZEALAND:
            return new RegExp(PHONE.ADVISER_ORG_PATTERN);
          case COUNTRY_TYPE.AUSTRALIA:
            return new RegExp(PHONE.ADVISER_ORG_PATTERN);
          default:
            break;
        }
      };

      const saveDefaultBrokerActive = (data, defaults) => {
        const { brokerSupport, familyId, clientId } = data;
        const brokerDataObj = adviserBrokerSupportBuilderForAPI({
          ...brokerSupport,
          ...defaults,
        });
        corporateService.familyBrokerSupportSet(
          familyId,
          clientId,
          brokerDataObj,
        );
      };

      const saveDefaultLeadsActive = (data, defaults) => {
        const { leadsData, familyId, clientId } = data;
        const leadDataObj = adviserLeadsBuilderForAPI({
          ...leadsData,
          ...defaults,
        });
        corporateService.leadsProfileSet(familyId, clientId, leadDataObj);
      };

      const getAdviserOrgBrandingCategoryId = (adviserOrganizationId) => {
        return corporateService
          .adviserOrganizationGet(0, adviserOrganizationId)
          .then((adviserOrg) => {
            if (!adviserOrg.data.length) return;
            const { brandingCategoryId } = adviserOrgDetailsBuilderForUI(
              adviserOrg.data[0],
            );
            return brandingCategoryId;
          });
      };

      const getBrokerSupportData = (familyId, clientId) => {
        return corporateService
          .familyBrokerSupportGet(familyId, clientId)
          .then((support) => {
            if (!support.data) return;
            return adviserBrokerSupportBuilderForUI(support.data);
          });
      };

      const getLeadsData = (familyId, clientId) => {
        return corporateService
          .leadsProfileGet(familyId, clientId)
          .then((lead) => {
            if (!lead.data) return;
            return adviserLeadsBuilderForUI(lead.data);
          });
      };

      const setDefaultBrokerActive = (data) => {
        const {
          adviserOrganizationId,
          familyId,
          clientId,
        } = adviserDetailsBuilderForUI(data);
        getBrokerSupportData(familyId, clientId).then((brokerSupport) => {
          if (!brokerSupport) return;
          getLeadsData(familyId, clientId).then((leadsData) => {
            if (!leadsData) return;
            getAdviserOrgBrandingCategoryId(adviserOrganizationId).then(
              (res) => {
                const brandingCategoryId = res;

                if (isLMBranded(brandingCategoryId)) {
                  brokerSupport.autoReferralConcierge = true;
                  brokerSupport.autoReferralHome = true;
                  brokerSupport.autoReferralWM = true;
                  saveDefaultBrokerActive(
                    { brokerSupport, familyId, clientId },
                    { nPSEnabled: true },
                  );
                  saveDefaultLeadsActive(
                    { leadsData, familyId, clientId },
                    {
                      sendNotificationBroker: true,
                      sendNotificationClient: true,
                    },
                  );
                } else if (isOwnBrand(brandingCategoryId)) {
                  brokerSupport.autoReferralConcierge = false;
                  brokerSupport.autoReferralHome = false;
                  brokerSupport.autoReferralWM = false;
                  saveDefaultBrokerActive(
                    { brokerSupport, familyId, clientId },
                    { nPSEnabled: false },
                  );
                  saveDefaultLeadsActive(
                    { leadsData, familyId, clientId },
                    {
                      sendNotificationBroker: true,
                      sendNotificationClient: false,
                    },
                  );
                } else {
                  saveDefaultLeadsActive(
                    { leadsData, familyId, clientId },
                    { sendNotificationBroker: true },
                  );
                }
              },
            );
          });
        });
      };

      const adviserStatus = {
        isProspect: false,
      };

      const service = {
        getButtonClassNamePerStatusName,
        checkFormDateValidation,
        checkInputPattern,
        sendEmailNotification,
        processBorrowerNames,
        processTickedBorrower,
        checkEmailValidity,
        hiddenClientForNz,
        formatDateString,
        convertPipeToCSV,
        setBooleanToInt,
        setAddressTypes,
        onRetrieveError,
        tranferLoanApp,
        dateEditMode,
        saveAddress,
        setEditMode,
        formatDate,
        setMapInit,
        emailValidator,
        getAllObjectFieldKey,
        attachFileMgmt,
        getAdviserOrgMobileFormat,
        setDefaultBrokerActive,
        saveDefaultBrokerActive,
        saveDefaultLeadsActive,
        getAdviserOrgBrandingCategoryId,
        adviserStatus,
      };
      return service;
    });
