import template from './headerMainDetails.html';
import controller from './headerMainDetailsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    fileTitle: '<',
    applicants: '<',
    onTitleEdit: '&',
    isPcfAdviser: '<',
    isAutoCommissionClaimEnabled: '<',
    isCommissionClaimLocked: '<',
    onAutoCommissionEnabledUpdate: '&',
  },
};
