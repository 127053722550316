class LoanThreeDotsMenuCtrl {
  openInDealView() {
    this.lendingDetailScope.getLoanAppLink({
      familyId: this.lendingDetailScope.familyId,
      loanAppId: this.loanDetails.LoanScenarioID,
      loanId: this.loanDetails.LoanID,
    });
  }
}

export default LoanThreeDotsMenuCtrl;
