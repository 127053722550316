import { CONTENT_TYPE } from 'Common/constants/contentType';
import { formatDate } from 'Common/utilities/date';
import { catchError } from 'Common/utilities/promise';
import { toastError } from 'Common/utilities/alert';
import { parseToInt10 } from 'Common/utilities/parse';
import { SUPPORTING_DOCS_NZ } from 'Common/constants/document';

export const dynamicSort = (a, b) => {
  if (a.SortOrderNumber < b.SortOrderNumber) {
    return -1;
  }
  if (a.SortOrderNumber > b.SortOrderNumber) {
    return 1;
  }
  return 0;
};

export const isSignedDeclarationForm = (item, isNZ) => {
  if (!item || !isNZ) {
    return false;
  }

  return parseToInt10(item.Type) === SUPPORTING_DOCS_NZ.SIGNED_DECLARATION_FORM;
};

export const groupFixedSupportingDocs = (docs = [], isNZ) => {
  if (!isNZ) {
    return docs;
  }

  const fixedTopDocs = docs.filter((doc) => isSignedDeclarationForm(doc, isNZ));
  const sortableDocs = docs.filter(
    (doc) => !isSignedDeclarationForm(doc, isNZ),
  );
  return [...fixedTopDocs, ...sortableDocs];
};

export const groupDocuments = ({ $scope }) => (docs) => {
  $scope.sortableDocs = docs.filter(
    (item) => item.StatusName !== 'Forthcoming',
  );
  $scope.notSortableDocs = docs.filter(
    (item) => item.StatusName === 'Forthcoming',
  );

  $scope.sortableDocs.sort(dynamicSort);
  if ($scope.supportingDocSorting) {
    $scope.sortableDocs = groupFixedSupportingDocs(
      $scope.sortableDocs,
      $scope.currentUserService.isNZ,
    );
  }

  return $scope.sortableDocs;
};

export const removeWorksheetDocs = (data) => {
  if (!data) {
    return [];
  }
  return data.filter((doc) => doc.Description !== 'Worksheet');
};

export const docParams = ($scope) => (item) => {
  return {
    loanScenarioId: $scope.loanAppId,
    documentId: item.DocumentId,
    pageNumber: item.PageNumber,
  };
};

export const sequentialThumbnailGeneration = ({ $scope, generalService }) => (
  item,
) => {
  const getParams = docParams($scope);
  const { pages } = item;
  const appendThumnail = (index, res) => {
    pages[index] = {
      ...pages[index],
      isGettingThumbnail: false,
      ThumbNail: { ...res }.data,
    };
  };

  return pages
    .reduce((cur, next, index) => {
      return cur.then((res) => {
        index > 0 && appendThumnail(index - 1, res);
        const params = getParams(next);
        return generalService.documentThumbnail(params);
      });
    }, Promise.resolve({}))
    .then((res) => {
      appendThumnail(pages.length - 1, res);
      item.isGettingThumbnail = false;
      $scope.inProgressDocDownload = false;
    })
    .catch(catchError);
};

export const downloadDocumentUtil = ({
  documentId,
  contentType,
  downloading,
  downloadDataService,
  loanappId,
}) => {
  if (!downloadDataService || !loanappId) {
    return;
  }
  const param =
    contentType === CONTENT_TYPE.PDF
      ? {
          documentID: documentId,
          loanScenarioID: loanappId,
        }
      : documentId;
  downloadDataService.documentDownload(param, downloading);
};

export const checkIfCanDownloadDocument = ($scope) => () => {
  $scope.canDownloadDocument =
    ($scope.documentsList &&
      $scope.documentsList.length &&
      $scope.documentsList.some(
        (doc) => !doc.IsDocumentBroken && doc.DocumentId,
      )) ||
    false;
};

export const getDocumentOptionType = ($scope) => (docTypeId) => {
  if (!docTypeId) {
    return '';
  }
  const type =
    $scope.DocumentsTypeList &&
    $scope.DocumentsTypeList.length &&
    $scope.DocumentsTypeList.find((docType) => docType.Value === docTypeId);

  return (type && type.Name) || 'Document unsupported';
};

export const getDateUploadedOn = (dateUploaded) => {
  if (!dateUploaded) {
    return '';
  }
  return `Uploaded ${formatDate('hh:mma')(dateUploaded)} on ${formatDate(
    'd MMM YYYY',
  )(dateUploaded)}`;
};

export const saveSendAllToLenderTick = (eSignService) => (
  selectedDocsId,
  isSendToLender,
  isEsign,
) => {
  if (!eSignService || !selectedDocsId || !selectedDocsId.length) {
    toastError('Missing dependency');
    return;
  }

  const params = {
    SupportingDocumentIds: selectedDocsId,
    IsSendToLender: isSendToLender,
    IsEsign: isEsign,
  };

  eSignService
    .setIncludeDocument(params)
    .then(({ data }) => !data && toastError());
};

export const setNonEsignDocumentsSendAllToLenderTick = (eSignService) => (
  documentList,
  isSendToLender,
) => {
  if (!documentList || !documentList.length || !eSignService) {
    return;
  }

  const selectedDocsId = [];
  documentList
    .filter((doc) => !doc.IsDocumentBroken)
    .forEach((doc) => {
      doc.IsSendToLender = !!isSendToLender;
      selectedDocsId.push(parseToInt10(doc.DocumentId));
    });

  if (!selectedDocsId.length) {
    return;
  }

  saveSendAllToLenderTick(eSignService)(selectedDocsId, isSendToLender, false);
};

export const setEsignDocumentsSendAllToLenderTick = (
  esignDocuments,
  eSignService,
) => (isSendToLender) => {
  if (!esignDocuments || !eSignService) {
    toastError('Missing dependency');
    return;
  }

  const selectedDocsId = [];
  esignDocuments.forEach((esignPackage) => {
    esignPackage.documents.length &&
      esignPackage.documents.forEach((doc) => {
        doc.isSendToLender = isSendToLender;
        selectedDocsId.push(doc.documentId);
      });
  });

  saveSendAllToLenderTick(eSignService)(selectedDocsId, isSendToLender, true);
};

export const selectAllLoanDocuments = ({
  sortableDocs,
  esignDocuments,
  eSignService,
}) => (isSendToLender) => {
  setNonEsignDocumentsSendAllToLenderTick(eSignService)(
    sortableDocs,
    isSendToLender,
    false,
  );

  esignDocuments &&
    esignDocuments.length &&
    setEsignDocumentsSendAllToLenderTick(
      esignDocuments,
      eSignService,
    )(isSendToLender);
};
