import { FINANCIAL_FREQUENCY } from 'Common/constants/financialFrequency';

export const SINGLE_OWNERSHIP = 1;

export const COLLAPSE = {
  CATEGORY: {
    SHOW_CONTENT: false,
  },
  TYPE: {
    SHOW_OWNER: false,
    SHOW_FACT_FIND: false,
    SHOW_BANK_CONNECT: false,
  },
};

export const FILLER_FINANCIAL_ID = -1;
export const JOINT_OWNERSHIP = -1;
export const UNEQUAL_OWNERSHIP = -2;

export const SEPARATE_AMOUNT_UNEQUALFF = {
  FirstName: 'Separate Amounts',
  BorrowerID: UNEQUAL_OWNERSHIP,
};

export const JOINT_OWNERSHIP_UNEQUALFF = {
  FirstName: 'Joint 50/50',
  BorrowerID: JOINT_OWNERSHIP,
};

export const JOINT_OWNERSHIP_OPTION = {
  FirstName: 'Joint',
  BorrowerID: JOINT_OWNERSHIP,
};

export const MONTHLY_MULTIPLIER = [
  {
    VALUE: FINANCIAL_FREQUENCY.YEARLY,
    MULTIPLIER: 1 / 12,
  },
  {
    VALUE: FINANCIAL_FREQUENCY.HALF_YEARLY,
    MULTIPLIER: 2 / 12,
  },
  {
    VALUE: FINANCIAL_FREQUENCY.QUARTERLY,
    MULTIPLIER: 4 / 12,
  },
  {
    VALUE: FINANCIAL_FREQUENCY.MONTHLY,
    MULTIPLIER: 1,
  },
  {
    VALUE: FINANCIAL_FREQUENCY.FORTNIGHTLY,
    MULTIPLIER: 26 / 12,
  },
  {
    VALUE: FINANCIAL_FREQUENCY.WEEKLY,
    MULTIPLIER: 52 / 12,
  },
];

export const DEFAULT_OTHER_TYPE = {
  financialId: 0,
  id: 0,
  typeId: 0,
  type: 'Other',
  isContinuingAfterSettlement: false,
  isMainExpense: false,
};
