import template from './nzbnPartyTypeFields.html';
import NZbnPartyTypeFieldsCtrl from './nzbnPartyTypeFieldsCtrl';

export default {
  template,
  controller: NZbnPartyTypeFieldsCtrl,
  controllerAs: 'vm',
  bindings: {
    partyType: '<',
    trustTypes: '<',
    onTypeChange: '&',
    organisationType: '<',
  },
};
