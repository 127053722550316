import template from './helloPackEmailContent.html';
import controller from './helloPackEmailContentCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    clients: '<',
    handler: '<',
    onUpdate: '&',
    onlyFactFind: '<?',
    packMode: '<?',
  },
};
