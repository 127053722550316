import _ from 'lodash';

class formErrorHandlerServices {
  sendEmailFormValidation(formData) {
    if (formData.$invalid) {
      this.firstError = null;
      _.each(formData, (field) => {
        if (field && field[0] !== '$') {
          if (this.firstError === null && !field.$valid) {
            this.firstError = field.$name;
          }
          if (field.$pristine) {
            field.$dirty = true;
          }
        }
      });
      return false;
    }
    return true;
  }
}

export default formErrorHandlerServices;
