function configLocationRoute($locationProvider) {
  // in config stage $window doesn't work
  // eslint-disable-next-line angular/window-service
  if (window.technology === 'angular') {
    $locationProvider.hashPrefix('');
  } else {
    $locationProvider
      .html5Mode({
        enabled: true,
        requireBase: false,
      })
      .hashPrefix('');
  }
}

configLocationRoute.$inject = ['$locationProvider'];

export default configLocationRoute;
