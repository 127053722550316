class NpsService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'nps';
  }

  getSurveyResponse(
    period,
    page,
    count,
    noResponseOnly,
    familyID = 0,
    generateForSelectedUserOnly,
    paramFamilyId = 0,
    searchText = '',
    sortBy,
    sortOrder,
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/SurveyResponseGet`, {
      period,
      page,
      count,
      noResponseOnly,
      familyID,
      generateForSelectedUserOnly,
      paramFamilyId,
      searchText,
      sortBy,
      sortOrder,
    });
  }

  getSurveyResponseCsv(
    period,
    noResponseOnly,
    familyID,
    generateForSelectedUserOnly,
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/SurveyResponseCsvGet`, {
      period,
      noResponseOnly,
      familyID,
      generateForSelectedUserOnly,
    });
  }

  setCMQueueReset(queueid, email) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/SetCMQueueReset`,
      {},
      { queueid, email },
    );
  }

  adviserLeaderBoardGet(statusId, showBy, areaId, referralSourceId, period) {
    return this.httpClient.get(`${this.apiBaseUrl}/AdviserLeaderBoardGet`, {
      statusId,
      showBy,
      areaId,
      referralSourceId,
      period,
    });
  }

  getDataForCustomerMonitorSubmission() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/DataForCustomerMonitorSubmissionGet`,
    );
  }

  getAdvisersList(pFamilyID, pSearchSameAdviserOrg, pMode) {
    return this.httpClient.get(`/GetAdvisersList`, {
      pFamilyID,
      pSearchSameAdviserOrg,
      pMode,
    });
  }

  dashboardScoreGet(reportingStateId, dateRangeFilterMode) {
    return this.httpClient.get(`${this.apiBaseUrl}/DashboardScoreGet`, {
      reportingStateId,
      dateRangeFilterMode,
    });
  }

  resend(queueId, newClientEmail, loanId = 0) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/resend`,
      {},
      { queueId, newClientEmail, loanId },
    );
  }
}

export default NpsService;
