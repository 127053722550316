export default class ComingSoonCtrl {
  constructor(currentUserService, crmConfirmation) {
    'ngInject';

    const { isNZ } = currentUserService;
    this.isNZ = isNZ;
    this.crmConfirmation = crmConfirmation;
  }

  onSendModal() {
    const message = `${this.feature} ${this.label}`;
    this.crmConfirmation.open({
      type: 'info',
      title: message,
      description: ``,
      modalSize: 'sm',
      cancelButtonClass: 'colored',
    });
  }
}
