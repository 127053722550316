// Put all the repeated modal open code here please
export const loadModule = () =>
  angular
    .module('app')
    .factory('modalRenderService', function (
      $uibModal,
      $state,
      crmConfirmation,
      configService,
    ) {
      // check add block
      const factory = {};

      const commonProps = {
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        size: 'md',
        keyboard: false,
        backdrop: 'static',
      };

      factory.renderPipelineStatusMoveConfirmationModal = function (data) {
        const modalContent = {
          title: 'Are you sure?',
          content: 'This card will now be moved to Not Proceeding',
          ...data,
        };

        return $uibModal.open({
          animation: true,
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          templateUrl:
            '/assets/views/pipeline/modals/pipelineStatusMoveConfirmation.html',
          windowTopClass: 'not-proceeding-modal',
          controller: 'PipelineStatusMoveConfirmationCtrl',
          size: 'md',
          keyboard: 'static',
          backdrop: false,
          resolve: {
            modalContent,
          },
        });
      };

      factory.openMovedToSettledModal = function ({
        size,
        familyID,
        loanId,
        loanScenarioId,
      }) {
        const $modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/pipeline/leads/partials/moved_settled_modal.html',
          controller: 'PipelineAppMoveToSettledModalCtrl',
          resolve: {
            familyID: () => familyID,
            LoanScenarioID: () => loanId,
            loanAppId: () => loanScenarioId,
          },
          size,
        });
        return $modalInstance.result;
      };

      factory.cardHiddenInApplication = function (firstContactName) {
        try {
          return crmConfirmation.open({
            type: 'info',
            title: 'Just a heads up',
            description: `This converted opportunity will not show in the application pipeline as the first contact added to this opportunity (${firstContactName}), already has existing cards in the application pipeline.<br/><br/>We\'re working hard to fix this issue, but in the meantime, you can view this and any other applications in the Lending tab of the involved parties.`,
            buttonText: 'Okay, got it!',
          });
        } catch (e) {
          console.log(`Developer message: ${e.message}`);
        }
      };

      factory.openNewOpportunityModal = function (
        familyId,
        clients,
        pipelineItemId,
        isLeadsToOpportunity,
      ) {
        const props = {
          familyId,
          clients,
          pipelineItemId,
          isLeadsToOpportunity,
        };
        const isAssetFinanceActive =
          configService.feature && configService.feature.assetFinance;

        return $uibModal.open({
          animation: true,
          template: `<opportunity-modal
                    modal-instance="vm.modalInstance"
                    family-id="vm.props.familyId"
                    clients="vm.props.clients"
                    pipeline-item-id="vm.props.pipelineItemId"
                    is-leads-to-opportunity="vm.props.isLeadsToOpportunity"
                  >
                  </opportunity-modal>`,
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          backdrop: 'static',
          keyboard: false,
          size: isAssetFinanceActive ? 'lg' : 'md',
          windowClass: 'opportunity-modal-window-class',
          resolve: {
            props: () => props,
          },
        });
      };

      factory.leadAddNote = (familyId) => {
        if (!familyId) return;

        const note = {};

        return $uibModal.open({
          templateUrl: '/assets/views/contacts/partials/create_note_modal.html',
          controller: 'CreateNoteModalCtrl',
          resolve: {
            familyId: () => familyId,
            note: () => note,
          },
        }).result;
      };

      factory.kanbanCustomItemMoveConfirmation = (pipelineName) => {
        try {
          return crmConfirmation.open({
            type: 'warning',
            title: 'Are you sure?',
            description: `This record will be moved to ${pipelineName}.<br/>This action is not reversible.`,
            buttonText: 'Yes, I wish to proceed',
            showCloseButton: true,
          });
        } catch (e) {
          console.log(`Developer message: ${e.message}`);
        }
      };

      factory.kanbanReasonForNotProceeding = (reasonsList) => {
        const props = {
          selectedReasonId: reasonsList[0].PipelineSubStatusID,
          reasonsList,
        };
        return $uibModal.open({
          template: `<pipeline-reason-not-proceeding-modal
        modal-instance="vm.modalInstance"
        selected-reason-id="vm.props.selectedReasonId"
        reasons-list="vm.props.reasonsList"
      ></pipeline-reason-not-proceeding-modal>`,
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          windowClass: 'pipeline-reason-not-proceeding-modal',
          keyboard: 'static',
          backdrop: false,
          resolve: {
            props: () => props,
          },
        });
      };

      factory.openCreditCheckModal = (contact) => {
        return $uibModal.open({
          templateUrl: 'assets/views/creditChecks/set_credit_checks_modal.html',
          controller: 'SetCreditChecksModalCtrl',
          controllerAs: 'vm',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'credit-checks-modal-window',
          resolve: {
            selectedContact: () => contact,
            clientsList: () => null,
          },
        });
      };

      factory.openPipelineImportantDate = (props) => {
        return $uibModal.open({
          template: `<pipeline-important-date-modal 
        modal-instance="vm.modalInstance"
        loan-details="vm.props.loanDetails"
        important-date-field="vm.props.importantDateField"
        family-id="vm.props.familyId">
        </pipeline-important-date-modal>`,
          controller: 'CommonModalPlaceholderCtrl',
          resolve: {
            props: () => props,
          },
          controllerAs: 'vm',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
        });
      };

      factory.openPostSettlementAddressModal = (props) => {
        return $uibModal.open({
          template: `<post-settlement-address-modal
          modal-instance="vm.modalInstance"
          family-id="vm.props.familyId"
          loan-app-id="vm.props.loanAppId"
          address-data="vm.props.addressData">
        </post-settlement-address-modal>`,
          controller: 'CommonModalPlaceholderCtrl',
          resolve: {
            props: () => props,
          },
          controllerAs: 'vm',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
        });
      };

      factory.openChooseProductsModal = (props) => {
        return $uibModal.open({
          template: `<lending-choose-products
          modal-instance="vm.modalInstance"
          involved-party-person="vm.props.involvedPartyPerson"
          facility="vm.props.facility"
          cb-product-finder="vm.props.cbProductFinder(facility)"
        ></lending-choose-products>`,
          controller: 'CommonModalPlaceholderCtrl',
          resolve: {
            props: () => props,
          },
          controllerAs: 'vm',
          size: 'md',
          windowClass: 'choose-products-window-class',
          backdrop: 'static',
          keyboard: false,
        });
      };

      factory.openSavedProductComparisonModal = (props) => {
        return $uibModal.open({
          template: `<lending-existing-product-comparisons
          modal-instance="vm.modalInstance"
          family-list="vm.props.familyList"
          facility="vm.props.facility"
          delete-all-favorite-products="vm.props.deleteAllFavoriteProducts"
        ></lending-existing-product-comparisons>`,
          resolve: {
            props: () => props,
          },
          ...commonProps,
          windowClass: 'saved-product-comparisons-window-class',
        });
      };

      factory.renderLeadsToApplicationFactFindModal = (loans, leadData) => {
        try {
          const commonObject = {
            loans,
            leadData,
          };
          return crmConfirmation.open({
            type: 'warning',
            title: 'This client has existing applications',
            description: `<leads-to-application-modal
              loans="vm.cCommonObject.loans"
              lead-data="vm.cCommonObject.leadData"
              modal-object="vm"
            ></leads-to-application-modal>`,
            renderAsComponent: true,
            showCustomButton: true,
            customButtonText: 'Cancel',
            customButtonClass: 'crm-confirmation-modal-action',
            showConfirmButton: false,
            isButtonMdWidth: true,
            modalSize: 'md',
            commonObject,
          });
        } catch (e) {
          console.log(`Developer message: ${e.message}`);
        }
      };

      factory.renderSendingStyledContainer = (props, customClass = '') => {
        return $uibModal.open({
          template: `<sending-styled-container
          modal-instance="vm.modalInstance"
          header-component="vm.props.headerComponent"
          sidebar-component="vm.props.sidebarComponent"
          action-buttons-component="vm.props.actionButtonsComponent"
          main-component="vm.props.mainComponent"
          common-props="vm.props.commonProps"
          on-cancel="vm.props.onCancel"
        ></sending-styled-container>`,
          resolve: {
            props: () => props,
          },
          ...commonProps,
          size: 'lg',
          windowClass: `sending-styled-container-modal ${customClass}`,
        });
      };

      factory.launchAddressLookupModal = (familyId, details) => {
        const props = { familyId, details };
        return $uibModal.open({
          template: `<address-lookup-modal
            family-id="vm.props.familyId"
            address-id="vm.props.details.addressId"
            details="vm.props.details"
            modal-instance="vm.modalInstance">
          </address-lookup-modal>`,
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: { props: () => props },
          keyboard: false,
          backdrop: 'static',
          size: 'md',
          windowClass: 'address-lookup-modal-container',
        });
      };

      factory.openNoteTypeModal = () => {
        return $uibModal.open({
          template: `<note-type-modal
          modal-instance="vm.modalInstance"
        ></note-type-modal>`,
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            props: () => {},
          },
        });
      };

      factory.openNoteModal = (props) => {
        const modalSize = configService.feature.noteType ? 'wide' : '';
        return $uibModal.open({
          template: `<opportunity-notes-modal
          modal-instance="vm.modalInstance"
          note-obj="vm.props.noteObj"
          is-add="vm.props.isAdd"
          referral-id="vm.props.referralId"
          is-sms-enabled="vm.props.isSmsEnabled"
          is-not-opportunity-module="vm.props.isNotOpportunityModule">
        </opportunity-notes-modal>`,
          ...commonProps,
          windowClass: `opportunity-notes-modal ${modalSize}`,
          size: modalSize,
          resolve: {
            props: () => props,
          },
        });
      };

      factory.openContactDetails = (props) => {
        return $uibModal.open({
          template: `<contact-details-preview
          modal-instance="vm.modalInstance"
          family-id="vm.props.familyId"
          contact-type="vm.props.contactType"
          contact="vm.props.contact"
          route-contact="vm.props.routeContact">
        </contact-details-preview>`,
          ...commonProps,
          keyboard: true,
          backdrop: true,
          windowClass: 'contact-details-preview-modal',
          resolve: {
            props: () => props,
          },
        });
      };

      factory.openCommissionUnmatchedDetails = (props) => {
        return $uibModal.open({
          template: `<commission-unmatched-details
          modal-instance="vm.modalInstance"
          commission="vm.props.commission"  
        >
        </commission-unmatched-details>`,
          ...commonProps,
          keyboard: true,
          backdrop: true,
          windowClass: 'commission-unmatched-modal',
          resolve: {
            props: () => props,
          },
        });
      };

      factory.openCommissionExpectedDetails = (props) => {
        return $uibModal.open({
          template: `<commission-expected-details
          modal-instance="vm.modalInstance"
          commission="vm.props.commission"  
          on-delete-commission="vm.props.onDeleteExpectedCommission()"  
          on-send-enquiry="vm.props.onSendEnquiry()"  
        >
        </commission-expected-details>`,
          ...commonProps,
          keyboard: true,
          backdrop: true,
          windowClass: 'commission-expected-modal',
          resolve: {
            props: () => props,
          },
        });
      };

      factory.openCPDPointsModal = (props) => {
        return $uibModal.open({
          template: `<education-modal
          modal-instance="vm.modalInstance"
          education="vm.props.education"
        ></education-modal>`,
          ...commonProps,
          size: 'md',
          resolve: { props },
        });
      };

      factory.openQualificationsModal = (props) => {
        return $uibModal.open({
          template: `<qualifications-modal
          modal-instance="vm.modalInstance"
          qualification="vm.props.qualification"
          preset-list="vm.props.presetList"
        ></qualifications-modal>`,
          ...commonProps,
          size: 'md',
          resolve: { props },
        });
      };

      factory.openCommissionMobileFilter = (props) => {
        return $uibModal.open({
          template: `<commission-filter-modal
          modal-instance="vm.modalInstance"
          filter-settings="vm.props.filterSettings"  
          adviser-list="vm.props.adviserList"
          lender-list="vm.props.lenderList"
          commission-type-list="vm.props.commissionTypeList" 
          lenders="vm.props.lenders"
          advisers="vm.props.advisers"
          payees="vm.props.payees"
          payee-list="vm.props.payeeList"
          commissionTypes="vm.props.commissionTypes"
          on-filter-commission="vm.props.onFilterCommission({ filterSettings })"  
          on-calendar-updated="vm.props.onCalendarUpdated({ filterData })"  
          on-range-selected="vm.props.onRangeSelected(activeRangeIndex)"  
          on-toggle-provider-filter="vm.props.onToggleProviderFilter()"  
          on-update-provider-filter="vm.props.onUpdateProviderFilter({ data })"
          on-toggle-adviser-filter="vm.props.onToggleAdviserFilter()"  
          on-update-adviser-filter="vm.props.onUpdateAdviserFilter({ data })"
          on-toggle-payee-filter="vm.props.onTogglePayeeFilter()"  
          on-update-payee-filter="vm.props.onUpdatePayeeFilter({ data })"
          on-toggle-type-filter="vm.props.onToggleTypeFilter()"  
          on-update-type-filter="vm.props.onUpdateTypeFilter({ data })" 
        >
        </commission-filter-modal>`,
          ...commonProps,
          keyboard: true,
          backdrop: true,
          windowClass: 'commission-filter-modal',
          resolve: {
            props: () => props,
          },
        });
      };

      factory.openCPDPointsModal = (props) => {
        return $uibModal.open({
          template: `<education-modal
          modal-instance="vm.modalInstance"
          education="vm.props.education"
        ></education-modal>`,
          ...commonProps,
          size: 'md',
          resolve: { props },
        });
      };

      factory.openQualificationsModal = (props) => {
        return $uibModal.open({
          template: `<qualifications-modal
          modal-instance="vm.modalInstance"
          qualification="vm.props.qualification"
          preset-list="vm.props.presetList"
        ></qualifications-modal>`,
          ...commonProps,
          size: 'md',
          resolve: { props },
        });
      };

      factory.openFilterComplaintModal = (props) => {
        return $uibModal.open({
          template: `<filter-complaint-modal
          modal-instance="vm.modalInstance"
          filter-list="vm.props.filterList"
        ></filter-complaint-modal>`,
          ...commonProps,
          size: 'sm',
          resolve: { props },
        });
      };

      factory.openComplaintModal = (complaintId = null, isReadOnly) => {
        return $uibModal.open({
          templateUrl: '/assets/views/modals/resource/complaintsModal.html',
          controller: 'ComplaintsModalCtrl',
          controllerAs: 'modal',
          windowClass: 'complaints-modal-class',
          size: 'lg',
          resolve: {
            successUploadResponse: () => complaintId,
            isReadOnly,
          },
        });
      };

      factory.openComplaintCorpCommentsModal = (props) => {
        return $uibModal.open({
          template: `<complaint-corp-comments-modal
          modal-instance="vm.modalInstance"
          corp-comments="vm.props.corpComments"
        ></complaint-corp-comments-modal>`,
          ...commonProps,
          size: 'sm',
          resolve: { props },
        });
      };

      factory.openAccreditationEditMultiProvider = (props) => {
        return $uibModal.open({
          template: `<edit-multi-provider-modal
          modal-instance="vm.modalInstance"
          providers="vm.props.providers"
          status-list="vm.props.statusList"
          is-au="vm.props.isAu"
          on-submit="vm.props.onSubmit(form, successCb)"
        ></edit-multi-provider-modal>`,
          ...commonProps,
          size: 'sm',
          windowClass: 'mycrm-modal edit-multi-provider-modal',
          resolve: { props },
        });
      };

      return factory;
    });
