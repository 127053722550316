/**
 * A simple but useful and efficient directive to toggle a class to an element.
 */
import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .factory('ToggleHelper', function ToggleHelper($rootScope) {
      // eslint-disable-next-line sonarjs/cognitive-complexity
      return {
        events: {
          toggle: 'clip-two.toggle',
          toggleByClass: 'clip-two.toggleByClass',
          togglerLinked: 'clip-two.linked',
          toggleableToggled: 'clip-two.toggled',
        },

        commands: {
          alternate: 'toggle',
          activate: 'on',
          deactivate: 'off',
        },

        toggle(target, pCommand) {
          let command = pCommand;
          if (!command) {
            command = 'toggle';
          }
          $rootScope.$emit(this.events.toggle, target, command);
        },

        toggleByClass(targetClass, pCommand) {
          let command = pCommand;
          if (!command) {
            command = 'toggle';
          }
          $rootScope.$emit(this.events.toggleByClass, targetClass, command);
        },

        notifyToggleState(elem, attrs, toggleState) {
          $rootScope.$emit(
            this.events.toggleableToggled,
            attrs.id,
            toggleState,
            attrs.exclusionGroup,
          );
        },

        toggleStateChanged(elem, attrs, toggleState) {
          this.updateElemClasses(elem, attrs, toggleState);
          this.notifyToggleState(elem, attrs, toggleState);
        },

        applyCommand(command, oldState) {
          switch (command) {
            case this.commands.activate:
              return true;
            case this.commands.deactivate:
              return false;
            case this.commands.alternate:
              return !oldState;
            default:
            // do nothing
          }
        },

        // eslint-disable-next-line sonarjs/cognitive-complexity
        updateElemClasses(elem, attrs, active) {
          if (active) {
            if (attrs.activeClass) {
              elem.addClass(attrs.activeClass);
            }
            if (attrs.inactiveClass) {
              elem.removeClass(attrs.inactiveClass);
            }
            const parent = elem.parent();
            if (attrs.parentActiveClass) {
              parent.addClass(attrs.parentActiveClass);
            }
            if (attrs.parentInactiveClass) {
              parent.removeClass(attrs.parentInactiveClass);
            }
          } else {
            if (attrs.inactiveClass) {
              elem.addClass(attrs.inactiveClass);
            }
            if (attrs.activeClass) {
              elem.removeClass(attrs.activeClass);
            }
            const parent = elem.parent();
            if (attrs.parentInactiveClass) {
              parent.addClass(attrs.parentInactiveClass);
            }
            if (attrs.parentActiveClass) {
              parent.removeClass(attrs.parentActiveClass);
            }
          }
        },
      };
    })
    /* eslint-disable angular/no-run-logic */
    .run(function ToggleHelperRun($rootScope, ToggleHelper) {
      $rootScope.toggle = function (target, pCommand) {
        let command = pCommand;
        if (!command) {
          command = 'toggle';
        }
        ToggleHelper.toggle(target, command);
      };

      $rootScope.toggleByClass = function (targetClass, pCommand) {
        let command = pCommand;
        if (!command) {
          command = 'toggle';
        }
        ToggleHelper.toggleByClass(targetClass, command);
      };
    })
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('ctToggle', function ctToggleDirective(
      $rootScope,
      ToggleHelper,
    ) {
      return {
        restrict: 'A',
        // eslint-disable-next-line sonarjs/cognitive-complexity
        link(scope, elem, attrs) {
          const command = attrs.ctToggle || ToggleHelper.commands.alternate;
          let target = attrs.target;
          const targetClass = attrs.targetClass;
          const bubble =
            attrs.bubble === 'true' ||
            attrs.bubble === '1' ||
            attrs.bubble === 1 ||
            attrs.bubble === '' ||
            attrs.bubble === 'bubble';

          if (!target && attrs.href) {
            target = attrs.href.slice(1);
          }

          if (!(target || targetClass)) {
            throw new Error(
              "'target' or 'target-class' attribute required with 'ct-toggle'",
            );
          }
          elem.on('click tap', (e) => {
            const angularElem = angular.element(e.target);
            if (!angularElem.hasClass('disabled')) {
              if (target != null) {
                ToggleHelper.toggle(target, command);
              }
              if (targetClass != null) {
                ToggleHelper.toggleByClass(targetClass, command);
              }
              if (!bubble) {
                e.preventDefault();
                return false;
              }
              return true;
            }
          });
          const unbindUpdateElemClasses = $rootScope.$on(
            ToggleHelper.events.toggleableToggled,
            (e, id, newState) => {
              if (id === target) {
                ToggleHelper.updateElemClasses(elem, attrs, newState);
              }
            },
          );

          if (target != null) {
            $rootScope.$emit(ToggleHelper.events.togglerLinked, target);
          }

          scope.$on('$destroy', unbindUpdateElemClasses);
        },
      };
    })
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('toggleable', function toggleableDirective(
      $rootScope,
      ToggleHelper,
    ) {
      return {
        restrict: 'A',
        // eslint-disable-next-line sonarjs/cognitive-complexity
        link(scope, elem, attrs) {
          let toggleState = false;

          if (attrs.default) {
            switch (attrs.default) {
              case 'active':
                toggleState = true;
                break;
              case 'inactive':
                toggleState = false;
                break;
              default:
              // do nothing
            }
            ToggleHelper.toggleStateChanged(elem, attrs, toggleState);
          }

          const unbindToggle = $rootScope.$on(
            ToggleHelper.events.toggle,
            (e, target, command) => {
              let oldState;
              if (target === attrs.id) {
                oldState = toggleState;
                toggleState = ToggleHelper.applyCommand(command, oldState);
                if (oldState !== toggleState) {
                  ToggleHelper.toggleStateChanged(elem, attrs, toggleState);
                }
              }
            },
          );

          const unbindToggleByClass = $rootScope.$on(
            ToggleHelper.events.toggleByClass,
            (e, targetClass, command) => {
              let oldState;
              if (elem.hasClass(targetClass)) {
                oldState = toggleState;
                toggleState = ToggleHelper.applyCommand(command, oldState);
                if (oldState !== toggleState) {
                  ToggleHelper.toggleStateChanged(elem, attrs, toggleState);
                }
              }
            },
          );

          const unbindToggleableToggled = $rootScope.$on(
            ToggleHelper.events.toggleableToggled,
            (e, target, newState, sameGroup) => {
              if (
                newState &&
                attrs.id !== target &&
                attrs.exclusionGroup === sameGroup &&
                attrs.exclusionGroup != null
              ) {
                toggleState = false;
                ToggleHelper.toggleStateChanged(elem, attrs, toggleState);
              }
            },
          );

          const unbindTogglerLinked = $rootScope.$on(
            ToggleHelper.events.togglerLinked,
            (e, target) => {
              if (attrs.id === target) {
                ToggleHelper.notifyToggleState(elem, attrs, toggleState);
              }
            },
          );

          scope.$on('$destroy', () => {
            unbindToggle();
            unbindToggleByClass();
            unbindToggleableToggled();
            unbindTogglerLinked();
          });
        },
      };
    });
