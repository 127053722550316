import {
  LENDING_CATEGORY,
  ASSET_PERSONAL_STRING,
} from 'Common/constants/securityValues';

export function isAssetFinanceCategory({
  currentUserService,
  lendingCategoryId,
}) {
  const displayForCategories = [
    LENDING_CATEGORY.ASSET_FINANCE,
    LENDING_CATEGORY.PERSONAL_LOAN,
  ];
  const countryAccessAssetFinance =
    currentUserService.isNZ || currentUserService.isAU;
  return (
    countryAccessAssetFinance &&
    displayForCategories.includes(lendingCategoryId)
  );
}

export function getLendingCategoryId(loanAppSharedData) {
  const lendingCategoryId =
    loanAppSharedData &&
    loanAppSharedData.LoanApplicationDetails &&
    loanAppSharedData.LoanApplicationDetails.LendingCategoryId;

  return lendingCategoryId || null;
}

export function isAssetFinanceFlagOn({ configService }) {
  const { assetFinance: assetFinanceFlag } = configService.feature;
  return assetFinanceFlag;
}

export function isAssetFinanceLoan({
  currentUserService,
  configService,
  loanAppSharedData,
}) {
  const lendingCategoryId = getLendingCategoryId(loanAppSharedData);
  const isFlagOn = isAssetFinanceFlagOn({ configService });
  const isAssetFinance = isAssetFinanceCategory({
    currentUserService,
    lendingCategoryId,
  });
  return isFlagOn && isAssetFinance;
}

export function filterAssetFinanceLenders(lenderList) {
  if (!lenderList) {
    return [];
  }
  return lenderList.filter((lender) =>
    ASSET_PERSONAL_STRING.includes(lender.Category),
  );
}

export function getLenderEmail({ loanAppSharedData, data }) {
  const lenderId = loanAppSharedData.lenderId
    ? parseInt(loanAppSharedData.lenderId, 10)
    : 0;
  const lenderMatch = data.find((lender) => lender.lenderId === lenderId);
  return (lenderMatch && lenderMatch.email) || null;
}
