import { postCompletionBuilderForMyCRM } from 'Common/mappers/customerCare';

export const setCompletionUtil = (vm) => (item) => {
  const postData = [postCompletionBuilderForMyCRM(item, vm.tab.type)];
  item.isLoading = true;
  vm.customerCareInsuranceService.postCompletion(postData).then(() => {
    item.updateCompleted = true;
    vm.toastSaveSuccess();
    vm.isFromCompleted = true;
    vm.tableParams.reload();
  });
};
