import { TEXT_PLACEHOLDERS } from 'Common/constants/placeHolder';
import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';

export const getTextPlaceHolder = (currentUserService) => {
  const SUPER_ADMINS = [CORPORATE_TEAM.IT_SUPPORT, CORPORATE_TEAM.SUPER_ADMIN];

  if (currentUserService.isCorporate) {
    return SUPER_ADMINS.includes(currentUserService.corporateTeamId)
      ? TEXT_PLACEHOLDERS.SUPER_ADMIN
      : TEXT_PLACEHOLDERS.CORPORATE;
  }
  return TEXT_PLACEHOLDERS.ADVISER;
};
