import angular from 'angular';
import lendingTab from './lendingTab';
import activeLoanList from './activeLoanList';
import tabularLoanList from './tabularLoanList';
import loanSavedCalculationList from './loanSavedCalculationList';

export default angular.module('contactLendingTab.scenes', [
  lendingTab,
  activeLoanList,
  tabularLoanList,
  loanSavedCalculationList,
]).name;
