import angular from 'angular';
import complaintCorpCommentsModal from './complaintCorpCommentsModal';
import filterComplaintModal from './filterComplaintModal';
import complaintsRegisterTable from './complaintsRegisterTable';

export default angular
  .module('complaintsRegister.components', [])
  .component('complaintCorpCommentsModal', complaintCorpCommentsModal)
  .component('filterComplaintModal', filterComplaintModal)
  .component('complaintsRegisterTable', complaintsRegisterTable).name;
