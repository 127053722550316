import { remove } from 'lodash';
import { INCOME_GROUP } from 'Common/constants/incomeGroupId';
import { DEFAULT_INCOME_TYPES } from 'Common/constants/incomeTypes';
import swal from 'sweetalert';
import { getIncomeErrors } from 'Common/utilities/bluestone';

class IncomeCtrl {
  constructor(
    blueStoneSubmissionService,
    financialsSharedService,
    loanScenarioService,
    contactService,
    optionsService,
    uiService,
    $timeout,
  ) {
    'ngInject';

    this.blueStoneSubmissionService = blueStoneSubmissionService;
    this.financialsSharedService = financialsSharedService;
    this.loanScenarioService = loanScenarioService;
    this.contactService = contactService;
    this.optionsService = optionsService;
    this.uiService = uiService;
    this.$timeout = $timeout;
  }

  getFrequency() {
    this.optionsService.FinancialFrequency().then((response) => {
      const { data } = response;
      if (!data) {
        return;
      }
      const newData = data.map((obj) => {
        obj.Value = parseInt(obj.Value, 10);
        return obj;
      });

      this.frequencyList = [{ Name: 'Please select', Value: '' }, ...newData];
    });
  }

  getIncomeType() {
    this.loanScenarioService.IncomeTypeGet().then((response) => {
      const { data } = response;
      if (!data) {
        return;
      }
      const isIncomeEmploymentGroup = [
        INCOME_GROUP.BONUS_COMMISSION,
        INCOME_GROUP.ALLOWANCE,
        INCOME_GROUP.REGULAR_OVERTIME,
        INCOME_GROUP.SALARY_WAGES,
        INCOME_GROUP.TAX,
      ];
      remove(data, (o) => {
        return !isIncomeEmploymentGroup.includes(o.GroupId);
      });
      const newData = data.map((obj) => {
        obj.Value = parseInt(obj.Value, 10);
        return obj;
      });
      this.incomeTypeList = [{ Name: 'Please select', Value: '' }, ...newData];
    });
  }

  getIncomeVerificationType() {
    const verificationType = 'employment';
    this.optionsService
      .IncomeVerificationType(verificationType)
      .then((response) => {
        const { data } = response;
        if (!data) {
          return;
        }
        const newData = data.map((obj) => {
          obj.Value = parseInt(obj.Value, 10);
          return obj;
        });
        this.incomeVerficationTypeList = [{ Name: '', Value: '' }, ...newData];
      });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  deleteIncome(income, index) {
    if (income && income.Id) {
      swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your income list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        (confirm) => {
          if (confirm) {
            let service;
            let method;
            let param = [];
            if (this.loanAppId) {
              service = this.loanScenarioService;
              method = 'IncomeInfoDelete';
              param = [this.loanAppId, income.Id];
            } else {
              service = this.contactService;
              method = 'deleteIncome';
              param = [income.Id, this.familyId];
            }
            service[method](param[0], param[1]).then(() => {
              this.$timeout(() => {
                if (this.loanAppId) {
                  this.financialsSharedService.IncomeInfoGet();
                }

                this.employment.Income.splice(index, 1);

                swal({
                  title: 'Success',
                  text: 'Income has been been successfully deleted.',
                  type: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                });
              }, 100);
            });
          }
        },
      );
    } else {
      this.employment.Income.splice(index, 1);
    }
  }

  amountDropdown(typeId = 0, isShow = true) {
    let response;
    if (isShow) {
      response =
        // eslint-disable-next-line sonarjs/no-duplicate-string
        this.uiService.isCountry('New Zealand') &&
        typeId === DEFAULT_INCOME_TYPES.SALARY;
    } else {
      response =
        (this.uiService.isCountry('New Zealand') &&
          typeId !== DEFAULT_INCOME_TYPES.SALARY) ||
        !this.uiService.isCountry('New Zealand');
    }
    return response;
  }

  getIncomeError() {
    return new Promise((resolve) => {
      getIncomeErrors(
        this.blueStoneSubmissionService,
        (hassError, { borrowers, guarantors, verificationError }) => {
          hassError &&
            (() => {
              // eslint-disable-next-line unicorn/consistent-function-scoping
              const mapIncomeErrors = (income) => {
                income.hasError =
                  borrowers.some((error) => error.IncomeID === income.Id) ||
                  guarantors.some((error) => error.IncomeID === income.Id);
                income.isVerificationError = verificationError.some(
                  (error) => error.IncomeID === income.Id,
                );
              };
              this.employment.Income.forEach(mapIncomeErrors);
              resolve(this.employment.Income);
            })();
        },
      );
    });
  }

  $onInit() {
    this.getFrequency();
    this.getIncomeType();
    this.getIncomeVerificationType();
    const incomeErrors = this.$timeout(() => {
      this.getIncomeError().then(() => {
        this.$timeout.cancel(incomeErrors);
      });
    }, 800);

    this.grossNetDropdown = [
      { value: true, label: 'Gross' },
      { value: false, label: 'Net' },
    ];
  }
}

export default IncomeCtrl;
