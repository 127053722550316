import { showActionConfirmation, alertSucess } from 'Common/utilities/alert';

export const onActionConfirmDeleteProcedure = (confirm, args) => {
  const { businessService, templateId, settingsId, scope, displayError } = args;

  if (
    !businessService ||
    !templateId ||
    !settingsId ||
    !scope ||
    !displayError
  ) {
    return;
  }

  if (confirm) {
    businessService
      .TaskSettingsDelete(templateId, settingsId)
      .then(() => {
        const alertTitle = 'Deleted';
        const message = 'Task delete successfully';
        alertSucess(alertTitle, message);
        scope.getTaskTemplateListFullDetails();
      })
      .catch(displayError);
  } else {
    scope.isShowDataDiv = true;
  }
};
export const deleteTemplateTaskUtil = (utilParams, $event) => {
  const { dependenciesParams, params } = utilParams;

  const { scope, toaster, businessService, displayError } = dependenciesParams;
  const { hasRelativeTask, templateId, settingsId } = params;
  if (!templateId || !settingsId || !scope || !toaster || !businessService) {
    return;
  }

  scope.isShowDataDiv = false;
  if (hasRelativeTask) {
    toaster.pop(
      'warning',
      'Warning',
      'Change dependent task due date before deleting',
    );
  } else {
    const confirmationTitle = 'Are you sure?';
    const confirmationMessage =
      'This task will be removed from your template task list';
    const args = {
      businessService,
      templateId,
      settingsId,
      scope,
      displayError,
    };
    const onActionConfirmDeleteTemplateTask = (confirm) => {
      onActionConfirmDeleteProcedure(confirm, args);
    };
    showActionConfirmation(
      confirmationTitle,
      confirmationMessage,
      onActionConfirmDeleteTemplateTask,
    );
  }
  $event.stopPropagation();
};
