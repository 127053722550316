import { toastError, toastSuccess, alertInfo } from 'Common/utilities/alert';
import swal from 'sweetalert';

export default class HandoverPackService {
  constructor(downloadDataService, handoverPackModelService) {
    'ngInject';

    this.downloadDataService = downloadDataService;
    this.handoverPackModelService = handoverPackModelService;
  }

  download(loanAppId, familyNames) {
    if (!loanAppId || !familyNames) {
      toastError('Required data is missing');
      return;
    }
    const downloadPendingMessage =
      'Generating your Handover Pack. This may take up to 30 seconds.';
    alertInfo(downloadPendingMessage);
    return this.handoverPackModelService
      .download(loanAppId)
      .then((response) => {
        if (!response || !response.documentContent) {
          toastError();
          return;
        }
        const finalFileName = `${response.documentName}.pdf`;

        const { contentType, documentContent } = response;
        this.downloadDataService.download(
          `data:${contentType};base64,${documentContent}`,
          finalFileName,
          contentType,
        );

        toastSuccess('Handover Pack downloaded successfully!');
        swal.close();
      });
  }
}
