export const kiwiSelectFundBuilderForUI = (kiwi) => {
  return {
    fundTypeId: kiwi.KiwiSelectFundTypeId,
    fundType: kiwi.KiwiSelectFundType,
  };
};

export const kiwiSelectProviderBuilderForUI = (kiwi) => {
  return {
    providerId: kiwi.KiwiSelectProviderId,
    providerName: kiwi.KiwiSelectProvider,
  };
};

export const kiwiSelectProviderNonExistingClientBuilderForAPI = (kiwi) => {
  const { clientData, formModel } = kiwi;

  return {
    FamilyID: '',
    ClientID: '',
    FirstName: formModel.FirstName,
    LastName: formModel.LastName,
    Email: formModel.Email,
    Mobile: formModel.Mobile,
    MobilePhoneCountryCode: formModel.MobilePhoneCountryCode,
    DOB: clientData.dateOfBirth,
    AddressID: clientData.addressId || 0,
    AdviserID: clientData.adviserId,
    IRDNumber: clientData.irdNumber,
    KiwiProviderID: clientData.kiwiProvider,
    KiwiFundTypeID: clientData.kiwiFund,
    FormattedAddress: clientData.formattedAddress,
    Home: '',
    Work: '',
  };
};

export const kiwiSelectProviderExistingClientBuilderForAPI = (kiwi) => {
  const { clientData, contactDetailList, formModel = {} } = kiwi;
  return {
    FamilyID: kiwi.FamilyId,
    ClientID: kiwi.PersonId,
    FirstName: kiwi.FirstName,
    LastName: kiwi.LastName,
    DOB: clientData.dateOfBirth,
    AdviserID: clientData.adviserId,
    IRDNumber: clientData.irdNumber,
    KiwiProviderID: clientData.kiwiProvider,
    KiwiFundTypeID: clientData.kiwiFund,
    Email: kiwi.existingEmail,
    Mobile: contactDetailList.Mobile,
    Home: contactDetailList.Home,
    Work: contactDetailList.Work,
    FormattedAddress: clientData.formattedAddress || '',
    AddressID: clientData.addressId || 0,
    MobilePhoneCountryCode: formModel.MobilePhoneCountryCode,
  };
};
