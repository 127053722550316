export default class EmailAttachmentsFieldCtrl {
  $onInit() {
    this.attachments = this.selectedAttachments && [
      ...this.selectedAttachments,
    ];
  }

  $onChanges(changes) {
    const { selectedAttachments } = changes;
    if (selectedAttachments && selectedAttachments.currentValue) {
      this.attachments = selectedAttachments.currentValue;
    }
  }
}
