import { values } from 'lodash';
import { toastSaveSuccess } from 'Common/utilities/alert';
import { FIRE_GENERAL_ID } from 'Common/constants/customerCareModules';

export default class IssuedFollowUpCtrl {
  constructor(
    NgTableParams,
    customerCareInsuranceService,
    customerCareTabsService,
  ) {
    'ngInject';

    this.NgTableParams = NgTableParams;
    this.toastSaveSuccess = toastSaveSuccess;
    this.customerCareInsuranceService = customerCareInsuranceService;
    this.customerCareTabsService = customerCareTabsService;
    this.FIRE_GENERAL_ID = FIRE_GENERAL_ID;
  }

  $onInit() {
    this.isBulkEmailActive = this.customerCareTabsService.isBulkEmailActive();
    this.isLoading = false;
    this.isFromCompleted = false;
    this.totalRecords = 0;
    this.currentPage = 1;
    this.tableData = [];

    this.defaultTableSettings = {
      page: 1,
      count: 10,
      sorting: {
        CommencementDate: 'asc',
      },
    };

    this.loadTableData();
  }

  $onChanges(changes) {
    if (changes.filterUpdateCount) {
      this.currentPage = 1;
      if (this.defaultTableSettings) {
        this.defaultTableSettings.page = 1;
      }
      this.isFromCompleted = false;
      if (this.tableParams) {
        this.tableParams.reload();
      }
    }

    if (changes.refreshCount && this.tableParams) {
      this.tableParams.reload();
    }
  }

  onActionSelected($event) {
    this.onSendModal({ $event });
  }

  markCompleted(item) {
    item.isComplete = !item.isComplete;
    this.setCompletion(item);
  }

  onItemCheckboxToggled(item) {
    if (this.isBulkEmailActive) {
      this.onTableDataLoaded({ $event: { tableData: this.tableData } });
    } else {
      item.isComplete = item.isChecked;
      this.setCompletion(item);
    }
  }

  gotoContact(familyId) {
    this.viewContact({ $event: { familyId } });
  }

  setCompletion(item) {
    const postData = [
      {
        HeaderRecordID: item.headerRecordID,
        SourceTable: item.sourceTable,
        TableRecordIDCSV: item.tableRecordIDCSV,
        IsCompleted: item.isComplete,
        FamilyID: item.familyID,
      },
    ];
    item.isLoading = true;
    this.customerCareInsuranceService
      .postCompletionIssuedfollowup(postData)
      .then(() => {
        item.updateCompleted = true;
        this.toastSaveSuccess();
        this.isFromCompleted = true;
        this.tableParams.reload();
      });
  }

  onPageChange(event) {
    this.defaultTableSettings.page = event.$e.pageNumber;
    this.currentPage = event.$e.pageNumber;
    this.isFromCompleted = false;
    this.tableParams.reload();
  }

  loadTableData() {
    const ctrl = this;
    this.tableParams = new this.NgTableParams(ctrl.defaultTableSettings, {
      counts: [],
      total: 0,
      getData(params) {
        const sortBy = params.orderBy()[0].slice(1);
        const sortOrder = values(params.sorting())[0].toUpperCase();
        if (!ctrl.isFromCompleted) {
          ctrl.isLoading = true;
        }

        const {
          reviewDateStart,
          reviewDateEnd,
          ...newFilterData
        } = ctrl.filterData;
        const newFilter = {
          ...newFilterData,
          sortBy,
          sortOrder,
          commencementDateStart: reviewDateStart,
          commencementDateEnd: reviewDateEnd,
          pageNumber: ctrl.currentPage,
        };
        return ctrl.customerCareInsuranceService
          .getIssuedFollowupList(newFilter)
          .then((response) => {
            ctrl.isLoading = false;
            ctrl.tableData = response.map((item) => {
              item.isComplete = newFilter.isCompleted;
              item.benefitTypeIdArr = item.benefitTypeIDCSV.split(',');
              item.benefitTypeArr = item.benefitTypeCSV
                .split(',')
                .map((benefit, index) => {
                  return {
                    id: parseInt(item.benefitTypeIdArr[index], 10),
                    name: benefit,
                  };
                });
              return item;
            });
            ctrl.totalRecords =
              ctrl.tableData.length && ctrl.tableData[0].totalCount;
            ctrl.onTableDataLoaded({ $event: { tableData: ctrl.tableData } });

            if (ctrl.totalRecords) {
              params.total(ctrl.totalRecords);
            } else {
              params.total(0);
            }
            return ctrl.tableData;
          });
      },
    });
  }
}
