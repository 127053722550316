import { find, map } from 'lodash';
import moment from 'moment';

class ConnectIncomeEmploymentCtrl {
  constructor(
    contactService,
    loanScenarioService,
    loanAppSharedData,
    $uibModal,
  ) {
    'ngInject';

    this.contactService = contactService;
    this.loanScenarioService = loanScenarioService;
    this.loanAppSharedData = loanAppSharedData;
    this.$uibModal = $uibModal;
  }

  launchEmployment(type = 'Add') {
    if (
      type !== 'Add' &&
      (!this.employment ||
        !this.employment.EmploymentId ||
        !this.employment.ClientId)
    ) {
      return;
    }

    const { employment, income, familyId, loanAppId } = this;
    const clientId = employment.ClientId || income.ClientId;

    this.loanAppSharedData.changeEmploymentOwnership(clientId);

    const { TypeId, FrequencyID, Amount, Id } = income;
    const newIncomeDetails = {
      TypeId,
      FrequencyID,
      Amount,
      ClientId: clientId,
      Id,
    };
    const modalInstance = this.$uibModal.open({
      backdrop: 'static',
      keyboard: false,
      templateUrl: '/assets/views/modals/employment.html',
      controller: 'EmploymentModalCtrl',
      controllerAs: 'vm',
      size: 'lg',
      windowClass: 'modal-larger',
      resolve: {
        newIncomeDetails: () => newIncomeDetails,
        isFromIncome: () => true,
        familyId: () => familyId,
        employmentId: () => employment.EmploymentId || 0,
        clientId: () => clientId && clientId.toString(),
        loanAppId: () => loanAppId,
        action: () => type,
        items: () => {},
      },
    });

    modalInstance.result.then(
      () => {
        this.callback && this.callback();
      },
      () => {
        this.callback && this.callback();
      },
    );

    this.onCancel();
  }

  fillEmployment(employmentData) {
    const employmentPlaceholder = {
      EmploymentId: 0,
      Summary: 'Select Employment',
    };

    let employment;

    if (this.loanAppId) {
      const familyEmployment = find(employmentData, (data) => {
        return parseInt(data.FamilyId, 10) === parseInt(this.familyId, 10);
      });

      employment = familyEmployment && familyEmployment.Employment;
    } else {
      employment = employmentData;
    }

    employment = map(employment, (obj) => {
      let { DateStarted, DateEnded } = obj;

      DateStarted = moment(DateStarted).format('D MMM YYYY');
      DateEnded =
        (DateEnded && moment(DateEnded).format('D MMM YYYY')) || 'Current';

      obj.Summary = `${obj.ClientFirstName} ${obj.ClientLastName} - ${obj.EmployerName} (${DateStarted} - ${DateEnded})`;
      return obj;
    });

    this.familyEmploymentList = [employmentPlaceholder, ...employment];

    this.employment = this.familyEmploymentList[0];
  }

  getLoanAppEmployment() {
    this.loanScenarioService
      .EmploymentGet(this.loanAppId, 0)
      .then((response) => {
        this.fillEmployment(response.data);
      });
  }

  getClientEmployment() {
    this.contactService
      .clientEntityEmploymentGet(this.familyId)
      .then((response) => {
        this.fillEmployment(response.data);
      });
  }

  $onInit() {
    this.employment = {};
    this.familyEmploymentList = [];
    if (this.loanAppId) {
      this.getLoanAppEmployment();
    } else {
      this.getClientEmployment();
    }
  }
}

export default ConnectIncomeEmploymentCtrl;
