export default class QuestionMessageModalCtrl {
  constructor() {
    'ngInject';
  }

  $onChanges(changes) {
    const { riskList } = changes;
    this.riskList = riskList && riskList.currentValue;
  }

  gotIt() {
    this.modalInstance.dismiss();
  }
}
