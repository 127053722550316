import template from './onboardingBranding.html';
import controller from './onboardingBrandingCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isSummary: '<',
  },
};
