import { stringFormatter } from 'Common/utilities/string';
import { isEntityPartOfType } from 'Common/utilities/onboarding';
import { NUMBER_PATTERN } from 'Common/constants/pattern';
import { getNamePartsObject } from 'Common/utilities/contact';

class OnboardingCompletePreviewCtrl {
  constructor(
    broadcastDataService,
    onboardingDataService,
    currentUserService,
    onboardingService,
    corporateService,
    commonFnService,
  ) {
    'ngInject';

    this.broadcastDataService = broadcastDataService;
    this.onboardingDataService = onboardingDataService;
    this.currentUserService = currentUserService;
    this.onboardingService = onboardingService;
    this.corporateService = corporateService;
    this.commonFnService = commonFnService;
  }

  getRecruitmentContact() {
    this.onboardingService
      .getRecruitmentContact(
        this.onboardingDataService.getFamilyId(),
        this.onboardingDataService.getClientId(),
      )
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }
        const entity = data[0] || {};
        this.entityDetails.isGstRegistered = entity.GSTRegistered || false;
        this.entityDetails.gstNumber = entity.IRDGSTNumber || 0;
      });
  }

  getAdviserOrgAddress(orgId) {
    if (!orgId) {
      return;
    }
    this.corporateService.organizationAddressGet(orgId).then(({ data }) => {
      if (!data) {
        return;
      }
      const addressObj = this.commonFnService.setAddressTypes(data);
      if (!addressObj) {
        return;
      }
      this.homeAddress =
        (addressObj.home && addressObj.home.formatted_address) || '';
      this.mailingAddress =
        (addressObj.mailing && addressObj.mailing.formatted_address) || '';
      if (!this.homeAddress && !this.mailingAddress) {
        return;
      }
      this.isSameAsPostal = this.homeAddress === this.mailingAddress;
    });
  }

  getEntityDetails() {
    this.onboardingDataService.getAdviserOrgDetails().then((data) => {
      if (!data) {
        return;
      }
      this.entityDetails = data;
      this.entityTypeIsCompany = isEntityPartOfType('Company', data.EntityType);
      this.principalName = '';
      if (data.FranchisePrincipalFamilyName) {
        const principalObj = getNamePartsObject(
          data.FranchisePrincipalFamilyName,
        );
        this.principalName = `${principalObj.firstName} ${principalObj.lastName}`;
      }
      this.getAdviserOrgAddress(data.AdviserOrganisationId);
    });
  }

  $onInit() {
    this.stringFormatter = stringFormatter;
    this.detailsConstant = NUMBER_PATTERN;
    this.phoneFormat = this.broadcastDataService.getPhoneFormat(
      this.onboardingDataService.getCountryId(),
    );
    this.getEntityDetails();
    this.getRecruitmentContact();
  }
}

export default OnboardingCompletePreviewCtrl;
