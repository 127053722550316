import { ADDRESS_TYPE } from 'Common/constants/addressModules';
import swal from 'sweetalert';
import toastr from 'toastr';
import { toastSuccess } from 'Common/utilities/alert';
import { getFilteredAddressTemplate } from './util/contactAddresses';

export default class ContactAddressesCtrl {
  constructor(
    $state,
    $interval,
    $window,
    $uibModal,
    currentUserService,
    contactService,
    colorService,
    utilitiesService,
    mapService,
    configService,
    addressHistoryService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$interval = $interval;
    this.$window = $window;
    this.$uibModal = $uibModal;
    this.currentUserService = currentUserService;
    this.contactService = contactService;
    this.colorService = colorService;
    this.utilitiesService = utilitiesService;
    this.mapService = mapService;
    this.configService = configService;
    this.addressHistoryService = addressHistoryService;
  }

  $onInit() {
    this.propertyList = [];
    this.ADDRESS_TYPE = ADDRESS_TYPE;
    this.showAllClients = { FirstName: 'Show all clients', LastName: '' };
    this.selectedClientAddressFilter = this.showAllClients;
    this.clientList = [];
    this.filteredAddress = getFilteredAddressTemplate();
    this.is_map_loaded = false;
    this.familyId = this.$state.params.familyId;
    this.isAddressHistoryRefreshEnabled = this.configService.feature.addressHistoryRefresh;

    if (this.familyId) {
      this.getAddresses();
    }
    this.editHandler = {
      run: (param) => this.editAddressesModal(param),
    };
    this.deleteHandler = {
      run: (param) => this.deleteAddress(param),
    };
  }

  googleMapInterval(property) {
    const googleInterval = this.$interval(() => {
      if (!this.$window.google) {
        return false;
      }
      if (property.latitude && property.longitude) {
        property.hasMap = true;
        const args = [
          property.latitude,
          property.longitude,
          property.formatted_address,
          undefined,
          false,
          `map-${property.AddressValuationExtendedDetailsId}`,
          '30',
        ];
        this.mapService.mapDesign(...args);
      } else {
        property.hasMap = false;
      }
      this.$interval.cancel(googleInterval);
    }, 500);
  }

  changeSelectedClientAddress(selectedClient) {
    this.filteredAddress = this.propertyList.reduce((accum, property) => {
      if (
        selectedClient.FirstName === 'Show all clients' ||
        property.ContactLivingIn.find(
          (contact) => contact.BorrowerID === selectedClient.BorrowerID,
        )
      ) {
        this.googleMapInterval(property);
        accum[property.TypeId].push(property);
        return accum;
      }
      return accum;
    }, getFilteredAddressTemplate());
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getAddresses() {
    this.filteredAddress = getFilteredAddressTemplate();
    this.contactService.contactAddressGet(this.familyId).then(({ data }) => {
      const propertyList = data || [];
      propertyList.map((property) => {
        this.clientList =
          this.clientList.length < property.ContactLivingIn.length
            ? property.ContactLivingIn
            : this.clientList;
        this.clientDropdown = [this.showAllClients, ...this.clientList];
        property.ContactLivingIn &&
          property.ContactLivingIn.map((contact) => {
            contact.background = this.colorService.getRandomColor();

            const filterInitialMethod = contact.LastName
              ? 'filterInitial'
              : 'filterInitialOneString';
            const filterParams = contact.LastName
              ? [contact.FirstName, contact.LastName]
              : [contact.FirstName];
            contact.initials = this.utilitiesService[filterInitialMethod](
              ...filterParams,
            );
            contact.displayName = `${contact.FirstName} ${
              contact.LastName || ''
            }`;

            return contact;
          });

        property.isCurrent = +property.TypeId === +ADDRESS_TYPE.CURRENT_ADDRESS;
        property.isOther = +property.TypeId === +ADDRESS_TYPE.OTHER;
        property.isPostSettlement =
          +property.TypeId === +ADDRESS_TYPE.POST_SETTLEMENT_ADDRESS;
        property.isPostal = +property.TypeId === +ADDRESS_TYPE.POSTAL_ADDRESS;
        property.isPrevious =
          +property.TypeId === +ADDRESS_TYPE.PREVIOUS_ADDRESS;

        property.isShowDates = property.isCurrent || property.isPrevious;
        this.filteredAddress[property.TypeId].push(property);
        this.googleMapInterval(property);
        return property;
      });

      this.propertyList = propertyList;
    });
    if (typeof this.onUpdateAddress !== 'function') {
      return;
    }
    this.onUpdateAddress();
  }

  openAddressModal(modalType, propertySet) {
    const props = { modalType, familyId: +this.familyId, propertySet };
    this.$uibModal
      .open({
        template: `<address-history-modal
        modal-instance="vm.modalInstance"
        modal-type="vm.props.modalType"
        family-id="vm.props.familyId"
        property-set="vm.props.propertySet"
      ></address-history-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: { props: () => props },
        keyboard: false,
        backdrop: 'static',
        size: 'sm',
      })
      .result.then(() => {
        this.getAddresses();
      });
  }

  newAddressesModal() {
    if (this.launchAddressLookup()) {
      return;
    }
    this.openAddressModal('new');
  }

  launchAddressLookup(address) {
    const { addressOverhaul } = this.configService.feature;
    if (!addressOverhaul) {
      return false;
    }
    this.addressHistoryService
      .launchAddressModal({ familyId: this.familyId, detailsObj: address })
      .then(() => {
        toastSuccess('Address successfully saved', 'Address Saved');
        this.getAddresses();
      });
    return true;
  }

  editAddressesModal(property) {
    this.contactService
      .contactAddressDetailsGet(
        property.AddressValuationExtendedDetailsId,
        this.familyId,
      )
      .then(({ data }) => {
        const propertySet = data || {};
        if (this.launchAddressLookup(propertySet)) {
          return;
        }
        this.openAddressModal('edit', propertySet);
      });
  }

  deleteAddress(addressValuationExtendedDetailsId) {
    swal(
      {
        title: 'Are you sure?',
        text: 'This record will be removed from your addresses list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      },
      (confirm) => {
        if (confirm) {
          this.contactService
            .contactAddressDelete(
              addressValuationExtendedDetailsId,
              this.familyId,
            )
            .then(() => {
              this.getAddresses();
              toastr.success(
                'Property has been been successfully deleted.',
                'Deleted',
              );
            });
        }
      },
    );
  }

  openAddressLookup() {
    const { familyId } = this;
    const props = { familyId };
    this.$uibModal
      .open({
        template: `<address-lookup-modal
          family-id="vm.props.familyId"
          modal-instance="vm.modalInstance">
        </address-lookup-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: { props: () => props },
        keyboard: false,
        backdrop: 'static',
        size: 'md',
      })
      .result.then(() => {
        this.getAddresses();
      });
  }
}
