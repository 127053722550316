import template from './gamePlanSendingMomentSidebar.html';
import controller from './gamePlanSendingMomentSidebarCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    applicants: '<',
    loanAppId: '<',
    loanAdviserId: '<',
  },
};
