class CurrencyInputDropdownCtrl {
  constructor(uiService, rateService) {
    'ngInject';

    this.uiService = uiService;
    this.rateService = rateService;
  }

  openDropdown() {
    this.isDropdownOpened = true;
  }

  changeCurrencyCode(code, countryId, countryCode, currencyCodeId) {
    this.countryCode = countryCode;
    this.xeCountryId = countryId;
    this.currencyCode = code;
    this.currencyCodeId = String(currencyCodeId);
    this.isDropdownOpened = false;
  }

  xeCurrencyListGet() {
    this.rateService.xeSupportedCurrenciesGet().then((response) => {
      this.xeCurrencyList = response.data.Currency;
    });
  }

  $onInit() {
    this.xeCurrencyList = [];
    this.xeCurrencyListGet();
  }
}

export default CurrencyInputDropdownCtrl;
