import { NZ_LENDER } from 'Common/constants/commonLenderIds';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { setCompletionStatus } from './../util/loanAppCtrl';
import { parseToInt10 } from 'Common/utilities/parse';
import isUndefined from 'lodash/isUndefined';

export const hasDefinedValue = (getValue) => (key) => {
  const value = getValue(key);
  return !isUndefined(value) && value !== '' && value !== null;
};

export const setIsStartedSupportingDocs = ($scope) => () => {
  if (!$scope) {
    return;
  }
  $scope.isStartedSupportingDocs = !!(
    $scope.documentsList && $scope.documentsList.length
  );
};

export const isValidDocument = (doc) => {
  return !!doc && !!doc.DocumentId && !doc.IsDocumentBroken;
};

export const setIsValidSupportingDocs = ({ $scope, configService }) => () => {
  if (!$scope || !configService) {
    return;
  }

  $scope.settings && ($scope.settings.uploadSucceeded = true);
  const isDocumentRequired = configService.feature.bidLoanAppChangesValidation;

  if ($scope.hasInvalidSupportingDocs) {
    $scope.isValidSupportingDocs = false;
  } else {
    $scope.isValidSupportingDocs = isDocumentRequired
      ? !!($scope.documentsList && $scope.documentsList.length)
      : true;
  }
};

export const validateSupportingDocs = ({
  $scope,
  $timeout,
  loanAppSharedData,
}) => () => {
  if (!$scope || !$timeout || !loanAppSharedData) {
    return;
  }

  if ($scope.hasInvalidSupportingDocs) {
    $scope.hasInvalidSupportingDocs =
      !!$scope.documentsList && !$scope.documentsList.every(isValidDocument);
  }
  $scope.setIsValidSupportingDocs();
  $scope.countValids($scope.isValidSupportingDocs, 'documents');

  const sectionObject = loanAppSharedData.getSectionObjectById(
    loanAppSharedData.completionListObject,
    SECTION_ID.UPLOAD_VIEW,
  );

  if (sectionObject.IsCompleted === $scope.isValidSupportingDocs) {
    return;
  }

  sectionObject.IsCompleted = $scope.isValidSupportingDocs;
  setCompletionStatus({
    $scope,
    $timeout,
    sectionId: SECTION_ID.UPLOAD_VIEW,
    loanAppSharedData,
    isValidSection: sectionObject.IsCompleted,
  });
};

export const setLoanAppSupportingDocsValidationMethods = ({
  $scope,
  $timeout,
  configService,
  loanAppSharedData,
}) => {
  $scope.fnIsStartedSupportingDocs = setIsStartedSupportingDocs($scope);
  $scope.setIsValidSupportingDocs = setIsValidSupportingDocs({
    $scope,
    configService,
  });
  $scope.validateSupportingDocs = validateSupportingDocs({
    $scope,
    $timeout,
    loanAppSharedData,
  });
};

export const bypassLoanSelectValidationWhenLodged = ({
  isNZ,
  isProviderBluestone,
  loanAppSharedData,
  $scope,
  $timeout,
}) => {
  const isValidationBypass = !!(
    isNZ &&
    loanAppSharedData?.LoanApplicationDetails?.LockedDate &&
    loanAppSharedData?.loanAppSummary?.LoanDetails?.LenderId &&
    (parseToInt10(loanAppSharedData.loanAppSummary.LoanDetails.LenderId) ===
      NZ_LENDER.SELECT ||
      isProviderBluestone)
  );

  if (isValidationBypass) {
    $scope.isValidLenderSubmission = true;
    $scope.countValids($scope.isValidLenderSubmission, 'submit');
    setCompletionStatus({
      $scope,
      $timeout,
      sectionId: SECTION_ID.LENDER_SUBMISSION,
      loanAppSharedData,
      isValidSection: !!$scope.isValidLenderSubmission,
    });
  }

  return isValidationBypass;
};
