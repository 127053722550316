import { DOC_TYPE_ID } from 'Common/constants/document';

export const switchParseDocType = (identificationSet) => {
  const {
    DocumentTypeId: documentTypeId,
    PlaceOfissue: placeOfIssue,
  } = identificationSet;

  const parsedDocTypeId = parseInt(documentTypeId, 10);
  if (!parsedDocTypeId) {
    return true;
  }

  const isTypeMedicare = parsedDocTypeId === DOC_TYPE_ID.MEDICARE_CARD;
  const isDriverLicenceType =
    parsedDocTypeId === DOC_TYPE_ID.DRIVERS_LICENCE_AUSTRALIA ||
    parsedDocTypeId === DOC_TYPE_ID.DRIVERS_LICENCE_INTERNATIONAL;
  const isQLDPlaceOfIssue = placeOfIssue === 'QLD';

  switch (parsedDocTypeId) {
    case DOC_TYPE_ID.MEDICARE_CARD:
      return !isTypeMedicare;
    case DOC_TYPE_ID.DRIVERS_LICENCE_AUSTRALIA:
      return !(isDriverLicenceType && !isQLDPlaceOfIssue);
    case DOC_TYPE_ID.DRIVERS_LICENCE_INTERNATIONAL:
      return !(isDriverLicenceType && !isQLDPlaceOfIssue);
    default:
      return true;
  }
};

export const fieldDateOfIssueRequirementsUtil = (identificationSet) => {
  if (!identificationSet) {
    return true;
  }

  return switchParseDocType(identificationSet);
};

export const getBirthCertificateList = () => {
  return [
    DOC_TYPE_ID.BIRTH_CERTIFICATE,
    DOC_TYPE_ID.BIRTH_CERTIFICATE_AU,
    DOC_TYPE_ID.BIRTH_CERTIFICATE_INTL,
  ];
};

export const fieldExpiryRequirementsUtil = (documentTypeId) => {
  const exemptedDocList = [
    DOC_TYPE_ID.AU_CITIZENSHIP_CERTIFICATE,
    DOC_TYPE_ID.MARRIAGE_CERTIFICATE,
    ...getBirthCertificateList(),
  ];
  return !exemptedDocList.includes(documentTypeId);
};
