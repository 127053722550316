import template from './helloPackEditorItem.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  transclude: true,
  bindings: {
    value: '<',
    description: '<',
    disabled: '<',
    hiddenDescription: '<',
    key: '<',
    invalid: '<',
    errorMessage: '<',
    hideToggle: '<',
    header: '@',
    headerExtension: '@',
    onChange: '&',
  },
};
