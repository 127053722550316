import angular from 'angular';
import { getDocumentTypeIcon } from 'Common/utilities/document';
import { EVENT_HANDLER } from 'Common/constants/fileUploadEventHandler';
import { VALID_EXTENSIONS, INVALID_FILE_INFO } from 'Common/constants/document';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('AttachFileMgmtCtrl', function AttachFileMgmtCtrl(
      $scope,
      $rootScope,
      $http,
      $timeout,
      $uibModalInstance,
      $stateParams,
      contactService,
      $filter,
      configService,
      userService,
      toaster,
      $state,
      familyId,
    ) {
      $scope.attachment = {
        isUploading: false,
        fileAttached: [],
      };
      $scope.emailInterface = {};
      $scope.uploadCount = 0;
      $scope.currentUploadIndex = 0;
      $scope.success = false;
      $scope.error = false;
      $scope.familyId = familyId || $state.params.familyId;
      $scope.hideDocumentProgress = false;
      $scope.documentLists = [];
      $scope.userDetails = {};
      $scope.files_added = [];
      $scope.activeTab = 0;
      $scope.files_attached = [];
      $scope.filesAddedQueue = [];
      $scope.lastCommit = 0;
      $scope.isUploadedFile = false;
      $scope.hasFileQueue = false;
      $scope.filesAddedTmp = [];

      $scope.extensions = VALID_EXTENSIONS;
      $scope.invalidFileInfo = INVALID_FILE_INFO;

      const loadAllRelatedDocuments = () => {
        contactService.getDocumentList($scope.familyId).then((response) => {
          const tmp = [];
          if (!response || !response.data) {
            return;
          }
          angular.forEach(response.data, (value) => {
            value.showIcon = false;
            tmp.push(value);
          });
          $scope.documentsLists = tmp;
        });
      };

      $scope.closeModal = () => {
        $scope.childComponentEventHandler.fileUploadEventHandler(
          EVENT_HANDLER.ON_CANCEL_ALL,
        );
        $uibModalInstance.dismiss('cancel');
      };
      $scope.uploadViewSuccess = ({ files }) => {
        $scope.attachment.fileAttached = [
          ...$scope.attachment.fileAttached,
          ...files,
        ];
      };

      $scope.getDocumentType = getDocumentTypeIcon;

      $scope.disableAttachment = () => {
        return (
          $scope.attachment.isUploading ||
          !(
            $scope.attachment.fileAttached.length ||
            $scope.filesAddedQueue.length
          )
        );
      };

      $scope.addFileOnQueue = (file) => {
        $scope.hasFileQueue = true;
        file.showIcon = !file.showIcon;
        if (!file) {
          return;
        }
        const fileAddQueue = () => {
          $scope.filesAddedQueue &&
            Object.keys($scope.filesAddedQueue).forEach((i) => {
              if (!$scope.filesAddedQueue[i]) {
                return false;
              }
              if (
                parseInt($scope.filesAddedQueue[i].DocumentId, 10) ===
                parseInt(file.DocumentId, 10)
              ) {
                $scope.filesAddedQueue.splice(i, 1);
              }
            });
        };
        if (file.showIcon) {
          $scope.filesAddedQueue.push(file);
        } else {
          fileAddQueue();
        }
        $scope.isUploadedFile = !!$scope.filesAddedQueue.length;
      };
      $scope.attachedtoEmail = () => {
        const fileAttached = [
          ...$scope.attachment.fileAttached,
          ...$scope.filesAddedQueue,
        ];
        $uibModalInstance.close(fileAttached);
      };
      const init = () => {
        userService.GetUserInfo().then((response) => {
          if (!response || !response.data) {
            return;
          }
          $scope.userDetails = response.data;
        });
        loadAllRelatedDocuments();
        $scope.childComponentEventHandler = { fileUploadEventHandler: null };
      };
      init();

      $scope.childComponentNotificationRegistration = (
        handler,
        handlerType,
      ) => {
        $scope.childComponentEventHandler[handlerType] = handler;
      };
      $scope.onUploading = ({ isUploading }) => {
        $scope.attachment.isUploading = isUploading;
      };
    });
