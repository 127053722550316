import { PICKER_RANGE } from 'Common/constants/pickerRange';
import { formatDate } from 'Common/utilities/date';
import { getDefaultPastDateRanges } from 'Common/config/calendarRangePicker';

export default class CalendarRangePickerCtrl {
  constructor($filter) {
    'ngInject';

    this.$filter = $filter;
  }

  $onInit() {
    this.filterRange = PICKER_RANGE;

    this.selectedDates = [];

    this.dateOptions = {
      showWeeks: false,
      maxMode: 'day',
      customClass: (data) => {
        const currentDate = this.selectedDates.indexOf(
          data.date.setHours(0, 0, 0, 0),
        );
        if (currentDate === 1 || currentDate === 0) {
          this.activeRangeIndex = PICKER_RANGE.CUSTOM;
          if (
            (this.selectedDates.length > 1 && currentDate === 0) ||
            this.selectedDates.length > 1
          ) {
            this.selectRange(null, PICKER_RANGE.CUSTOM, true);
          }
          return 'full';
        } else if (currentDate > -1) {
          return 'partially';
        }
        return '';
      },
    };

    this.rangeActive = true;
    this.activeRangeIndex = null;
    this.selectedRange = {
      startDate: '',
      endDate: '',
    };

    this.ranges = this.customRanges || getDefaultPastDateRanges();

    if (!this.pipelineSharedData) {
      this.calendarRangePickerData = {};
    }
  }

  selectRange(range, index, triggerCustom = false) {
    this.activeRangeIndex = index;
    this.onRangeSelected({ activeRangeIndex: this.activeRangeIndex });
    if (index === this.filterRange.CUSTOM && this.selectedDates.length) {
      this.selectedRange.startDate = this.$filter('orderBy')(
        this.selectedDates,
      )[0];
      this.selectedRange.endDate = this.$filter('orderBy')(
        this.selectedDates,
        '-',
      )[0];
    } else {
      if (!range || !range.range || range.range.length < 2) {
        return;
      }
      this.selectedRange.startDate = range.range[0];
      this.selectedRange.endDate = range.range[1];
    }

    if (index !== this.filterRange.CUSTOM || triggerCustom) {
      const startDateFormat = formatDate('YYYY-MM-DDT00:00:00.SSS[Z]');
      const endDateFormat = formatDate('YYYY-MM-DDT00:00:00.SSS[Z]');

      if (this.pipelineSharedData) {
        this.pipelineSharedData.leadFilterData.DateCreatedStart = startDateFormat(
          this.selectedRange.startDate,
        );
        this.pipelineSharedData.leadFilterData.DateCreatedEnd = endDateFormat(
          this.selectedRange.endDate,
        );
        this.onUpdate({ filterData: this.pipelineSharedData.leadFilterData });
      } else {
        this.calendarRangePickerData.DateStart = startDateFormat(
          this.selectedRange.startDate,
        );
        this.calendarRangePickerData.DateEnd = endDateFormat(
          this.selectedRange.endDate,
        );
        this.onUpdate({ filterData: this.calendarRangePickerData });
      }
    }
  }

  clearDateFilter() {
    this.activeRangeIndex = null;
    if (this.pipelineSharedData) {
      this.pipelineSharedData.leadFilterData.DateCreatedStart = ``;
      this.pipelineSharedData.leadFilterData.DateCreatedEnd = ``;
      this.onRangeSelected({ activeRangeIndex: this.activeRangeIndex });
      this.onUpdate({ filterData: this.pipelineSharedData.leadFilterData });
    } else {
      this.calendarRangePickerData.DateStart = ``;
      this.calendarRangePickerData.DateEnd = ``;
      this.onRangeSelected({ activeRangeIndex: this.activeRangeIndex });
      this.onUpdate({ filterData: this.calendarRangePickerData });
    }
  }
}
