import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { isCancelled as subscriptionIsCancelled } from 'Common/utilities/subscription';

export const getEsignActiveUser = (esign, data) => {
  const esignSubscription = data.find(
    (obj) => obj.subscriptionProductId === SUBSCRIPTIONTYPE.E_SIGN,
  );
  return (
    esign &&
    esignSubscription &&
    (esignSubscription.isActive || subscriptionIsCancelled(esignSubscription))
  );
};

export const getAssignedAdviserEsignStatus = (
  eSignService,
  loanAppSharedData,
  familyId,
) => {
  if (!familyId) {
    return;
  }
  eSignService.getAccountCheck(familyId).then((response) => {
    if (!response || !response.status) {
      return;
    }
    const { status, hasExistingSignature } = response;
    loanAppSharedData.eSignAccountType = status;
    loanAppSharedData.eSignHasExistingSignature = hasExistingSignature;
  });
};

export const getAssignedAdviserSubscription = (
  contactService,
  loanAppSharedData,
  configService,
  eSignService,
) => (familyId) => {
  if (!familyId) {
    return;
  }
  contactService.subscriptionInfo(familyId).then((response) => {
    if (!response || !response.length) {
      return;
    }
    loanAppSharedData.adviserSubscription = response;
    const { esign } = configService.feature;
    const isEsignActiveuser = getEsignActiveUser(esign, response);
    if (!isEsignActiveuser) {
      return;
    }
    getAssignedAdviserEsignStatus(eSignService, loanAppSharedData, familyId);
  });
};
