import template from './addressLookupModal.html';
import controller from './addressLookupModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    addressId: '<',
    details: '<',
    modalInstance: '<',
  },
};
