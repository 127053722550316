export const loadModule = () =>
  app.factory('regionalizationService', [
    '$q',
    'regionalizationModel',
    function ($q, regionalizationModel) {
      var factory = this;

      var regionalizationModel = new regionalizationModel();

      factory.getBrokerRegionalization = function (brokerId) {
        var defer = $q.defer();

        regionalizationModel.GetBrokerRegionalization(
          brokerId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );

        return defer.promise;
      };

      factory.GetMembershipRoles = function () {
        var defer = $q.defer();
        regionalizationModel.MembershipRolesGet(
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );

        return defer.promise;
      };

      factory.addressGet = function (addressID) {
        var defer = $q.defer();
        regionalizationModel.AddressGet(
          addressID,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );

        return defer.promise;
      };

      return factory;
    },
  ]);
