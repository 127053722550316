import { SECTION_STRING } from 'Common/constants/opportunity';
import {
  CLIENT_REFERRAL,
  ADDITIONAL_REFERRAL,
  REFERRED_BY_ACCESS,
} from 'Common/constants/enquirySource';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { get } from 'lodash';
import { INVOLVED_PARTIES_TYPE } from 'Common/constants/partyTypes';
import { openEditClientModal } from 'Components/opportunity/opportunityMain/opportunityDetails/util/clientEditModal';
import { openDeleteClientModal } from 'Components/opportunity/opportunityMain/opportunityDetails/util/clientDeleteModal';
import {
  mapEnquiryTitleField,
  formatReferralItemId,
  setReferralItemId,
} from 'Common/utilities/referral';
import { getAllInvolvedParties } from 'Common/utilities/involvedParties';
import ManagePartiesViewCtrl from '../manageParties/managePartiesViewCtrl';
import { isAssetFinanceCategory } from 'Common/utilities/lendingCategory';
import { getConfigFromEnv } from 'Common/utilities/config';
import { ASSET_PRODUCT_TYPE } from 'Common/constants/nodifiIntegration';
import { INTEGRATION_TYPE } from 'Common/constants/integrationType';

const NODIFI_STEP_ASSET = 1;
const NODIFI_STEP_PERSONAL_LOAN = 5;

class OpportunityDetailsCtrl {
  constructor(
    contactService,
    opportunityCalculationService,
    referencedReferralService,
    opportunityLoanAppService,
    loanOpportunityService,
    nodifiIntegrationService,
    enquirySourceService,
    loanScenarioService,
    currentUserService,
    optionsService,
    $uibModal,
    toaster,
    $stateParams,
    $timeout,
    uiService,
    configService,
    $q,
    $state,
    loanProfilerService,
    clientInfoService,
    helloBookService,
    $window,
  ) {
    'ngInject';

    this.contactService = contactService;
    this.opportunityCalculationService = opportunityCalculationService;
    this.referencedReferralService = referencedReferralService;
    this.opportunityLoanAppService = opportunityLoanAppService;
    this.loanOpportunityService = loanOpportunityService;
    this.nodifiIntegrationService = nodifiIntegrationService;
    this.enquirySourceService = enquirySourceService;
    this.loanScenarioService = loanScenarioService;
    this.currentUserService = currentUserService;
    this.SECTION_STRING = SECTION_STRING;
    this.optionsService = optionsService;
    this.$uibModal = $uibModal;
    this.$timeout = $timeout;
    this.toaster = toaster;
    this.$stateParams = $stateParams;
    this.uiService = uiService;
    this.configService = configService;
    this.$q = $q;
    this.$state = $state;
    this.$window = $window;
    this.loanProfilerService = loanProfilerService;
    this.clientInfoService = clientInfoService;
    this.helloBookService = helloBookService;

    this.clientSearchApi = this.clientSearchApi.bind(this);
    this.selectClient = this.selectClient.bind(this);
  }

  $onInit() {
    this.clientEnquiryList = CLIENT_REFERRAL;
    this.additionalEnquiryList = ADDITIONAL_REFERRAL;
    this.displayApplicationSection = false;
    this.involvedPartiesSummary = [];
    this.purposeList = [];
    this.referralOrgList = [];
    this.referrerObj = {};
    this.textAreaMinHeight = '210';
    if (this.summary.ClientReferrerFamilyId) {
      const LastName = this.summary.ReferrerLastName || '';
      const FirstName = this.summary.ReferrerFirstName || '';
      const PreferedName = `${FirstName} ${LastName}`;
      this.defaultClient = {
        FamilyID: this.summary.ClientReferrerFamilyId,
        FamilyFullName: this.summary.ClientReferrerName,
        PreferedName,
        ClientEntityId: this.summary.ReferrerClientId,
      };
    }
    const { LendingCategoryId } = this.summary;
    this.isNotAssetFinance =
      LendingCategoryId !== LENDING_CATEGORY.ASSET_FINANCE;

    this.getPurposeList();
    this.getEnquirySourceList();
    this.getLendingCategoryList();
    this.calculateLvr();
    this.getApplicantSummary();
    this.setupCalculations();

    const {
      assetFinance,
      oneTouchPrivacy,
      nodifi,
      nodifiPhase2,
    } = this.configService.feature;
    this.showNofidi =
      nodifi &&
      !this.currentUserService.hasBrandFeature('HasNonLMNodifi') &&
      isAssetFinanceCategory({
        currentUserService: this.currentUserService,
        lendingCategoryId: LendingCategoryId,
      });
    this.isAssetFinanceActive = assetFinance;
    this.oneTouchPrivacy = oneTouchPrivacy;

    if (this.showNofidi) {
      this.nodifiLink = getConfigFromEnv().nodifiUrl;
      this.nodifiApiUrl = getConfigFromEnv().nodifiApiUrl;
      this.showNodifiPhase2 = this.showNofidi && nodifiPhase2;
      if (this.showNodifiPhase2) {
        this.assetProductTypes = [
          ASSET_PRODUCT_TYPE.COMMERCIAL,
          ASSET_PRODUCT_TYPE.CONSUMER,
        ];
        if (!this.summary.AssetProductType) {
          this.summary.AssetProductType = ASSET_PRODUCT_TYPE.CONSUMER;
        }
        this.IsNodifiAppExisting =
          this.summary.IntegrationReferences?.find(
            (x) => x.IntegrationType === INTEGRATION_TYPE.NODIFI,
          ) !== undefined;
      }
    }

    this.isSaving = false;

    this.openEditClientModal = openEditClientModal({
      $uibModal: this.$uibModal,
      countryList: this.uiService.countryList,
      callback: this.onRefreshDetails,
    }).bind(this);
  }

  $onChanges(changes) {
    const { involvedParties, selectedAdviserId, summary, applicants } = changes;
    if (involvedParties) {
      const { currentValue: involvedPartiesValues } = involvedParties;
      if (involvedPartiesValues) {
        this.formatInvolvedPartyPerson();
        this.openDeleteClientModal = openDeleteClientModal({
          toaster: this.toaster,
          loanScenarioService: this.loanScenarioService,
          opportunityCalculationService: this.opportunityCalculationService,
          loanOpportunityService: this.loanOpportunityService,
          loanAppId: this.opportunityId,
          involvedPartyPerson: involvedPartiesValues?.InvolvedPartyPerson,
          callback: this.onRefreshDetails,
        });
      }
    }

    if (selectedAdviserId) {
      const { currentValue: selectedAdviserIdValue } = selectedAdviserId;
      if (selectedAdviserIdValue) {
        this.getReferrerOrgList(selectedAdviserIdValue);
      }
      this.allocatedAdviserId = selectedAdviserIdValue || 0;
    }

    if (summary) {
      const { currentValue: opportunityDetailsValues } = summary;
      if (opportunityDetailsValues) {
        this.calculateLvr();
      }
      this.isNotAssetFinance =
        opportunityDetailsValues.LendingCategoryId !==
        LENDING_CATEGORY.ASSET_FINANCE;

      this.updateEnquiryDetails();
    }

    if (applicants && applicants.currentValue) {
      const { oneTouchPrivacy } = this.configService.feature;
      this.allInvolvedParties = getAllInvolvedParties(
        this.applicants,
        this.clientInfoService,
        true,
        oneTouchPrivacy,
      );
    }

    this.getApplicantSummary();
  }

  updateEnquiryDetails() {
    this.getEnquirySourceList();
    this.setReferrer();
    const { EnquirySource } = this.summary;
    const isReferral = REFERRED_BY_ACCESS.includes(
      EnquirySource.ReferralItemId,
    );
    this.referrerRequired = isReferral && !EnquirySource.ReferrerId;
  }

  toggleApplicationSection() {
    this.displayApplicationSection = !this.displayApplicationSection;
  }

  getApplicantSummary() {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const mapInvolvedParty = (arrData, type, keyProp = '') => {
      const MAX_CHAR_LENGTH = 35;
      return (
        arrData.map((data) => {
          const labelField = `${data.FirstName} ${data.LastName}`;
          if (!keyProp) {
            const charLengthValue = labelField.length;
            if (charLengthValue < MAX_CHAR_LENGTH) {
              return {
                label: labelField,
                type,
              };
            }
            const excessNumberOfChar = charLengthValue - MAX_CHAR_LENGTH;
            const indexOfNotExcessChar =
              labelField.length - excessNumberOfChar - 1;
            const extractTextBasedOnLimit = `${labelField.slice(
              0,
              indexOfNotExcessChar,
            )}...`;
            return {
              label: extractTextBasedOnLimit,
              type,
            };
          }
          const label = get(data, keyProp);
          return {
            label,
            type,
          };
        }) || []
      );
    };
    this.involvedPartiesSummary = Object.keys(this.involvedParties).reduce(
      (accum, key) => {
        const involvedPartyCollection = this.involvedParties[key] || null;
        if (key === 'PersonBorrowers') {
          return [
            ...mapInvolvedParty(involvedPartyCollection, 'person'),
            ...accum,
          ];
        }
        if (key === 'InvolvedPartyEntity') {
          return [
            ...accum,
            ...mapInvolvedParty(
              involvedPartyCollection,
              'office',
              'FamilyFullName',
            ),
          ];
        }
        if (key === 'RelatedParty') {
          return [
            ...mapInvolvedParty(involvedPartyCollection, 'shield'),
            ...accum,
          ];
        }
        return accum;
      },
      [],
    );
  }

  setupCalculations() {
    this.opportunityCalculationService.setOpportunityDetails(
      this.opportunityId,
      this.opportunityTitle,
      this.summary,
      'details',
    );
    this.isCalculationReady = false;
    this.opportunityCalculationService
      .getCalculations()
      .then((calculations) => {
        const { opportunityId } = this.$stateParams;
        if (calculations && !calculations.length) {
          this.loanScenarioService
            .getFundingCalculatorNewBrokerEventForScenario(opportunityId)
            .then(() => this.opportunityCalculationService.getCalculations());
        }
        this.isCalculationReady = true;
      });
  }

  getEnquirySourceList() {
    const { loanId } = this;
    this.enquirySourceService
      .getEnquirySourceList({}, { loanId })
      .then((res) => {
        this.enquiryList = res;
        const { EnquirySource } = this.summary;
        if (EnquirySource) {
          const referralItemId = formatReferralItemId(EnquirySource);
          this.enquirySource =
            this.enquiryList.find(
              (obj) =>
                obj.ReferralItemId === referralItemId &&
                obj.ReferralCategoryId === EnquirySource.ReferralCategoryId,
            ) || {};
        }
      });
  }

  getLendingCategoryList() {
    this.optionsService.getLendingCategories().then((response) => {
      this.lendingCategoryList = response || [];
    });
  }

  deriveNodifiTenantName(businessUnitId) {
    switch (businessUnitId) {
      case 13:
        return 'plan';
      case 14:
        return 'choice';
      case 15:
        return 'fast';
      default:
        return 'loanmarket';
    }
  }

  formatInvolvedPartyPerson() {
    const { InvolvedPartyEntity, PersonBorrowers } = this.involvedParties;
    this.isEnableDeleteParty =
      InvolvedPartyEntity.length + PersonBorrowers.length - 1;

    Object.keys(this.involvedParties).forEach((partyType) => {
      if (!Array.isArray(this.involvedParties[partyType])) {
        return;
      }
      this.involvedParties[partyType] = this.involvedParties[partyType].map(
        (clientEntity) => {
          clientEntity.Phone =
            clientEntity.Phone &&
            clientEntity.Phone.filter(
              (phone) => phone.Type === 'Home' || phone.Type === 'Mobile',
            );
          return clientEntity;
        },
      );
    });
  }

  toggleOtherInfo(section, idx) {
    if (!this.involvedParties[section] || !this.involvedParties[section][idx]) {
      return;
    }

    this.involvedParties[section][idx].showOtherInfo = !this.involvedParties[
      section
    ][idx].showOtherInfo;
  }

  openManagePartiesModal() {
    const { opportunityId } = this.$stateParams;
    const { involvedParties, selectedAdviserId } = this;

    this.$uibModal
      .open({
        template: `<manage-parties
                  modal-instance="vm.modalInstance"
                  opportunity-id="vm.opportunityId"
                  involved-parties="vm.involvedParties"
                  selected-adviser-id="vm.selectedAdviserId"
                  show-select-adviser="vm.showSelectAdviser"
                >
                </manage-parties>`,
        controller: ManagePartiesViewCtrl,
        windowClass: 'manage-parties-modal',
        controllerAs: 'vm',
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          opportunityId: () => opportunityId,
          involvedParties: () => involvedParties,
          selectedAdviserId: () => selectedAdviserId,
          showSelectAdviser: () => false,
        },
      })
      .result.then(
        () => {
          this.onRefreshDetails();
        },
        () => {
          this.onRefreshDetails();
        },
      );
  }

  openAddPartiesModal() {
    const props = {
      opportunityId: this.$stateParams.opportunityId,
      adviserId: this.selectedAdviserId,
    };
    this.$uibModal
      .open({
        template: `<file-add-parties-modal
            modal-instance="vm.modalInstance"
            opportunity-id="vm.props.opportunityId"
            adviser-id="vm.props.adviserId"
          >
          </file-add-parties-modal>`,
        windowClass: 'mycrm-modal add-parties-modal',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        size: 'sm',
        backdrop: 'static',
        keyboard: false,
        resolve: { props },
      })
      .result.then(() => {
        this.opportunityLoanAppService.executeCallback(
          'refreshOpportunityDetails',
        );
      });
  }

  calculateLvr() {
    const { ProposedLoanAmount, SecurityAmount } = this.summary;
    const isInvalidAmounts =
      !parseInt(ProposedLoanAmount, 10) || !parseInt(SecurityAmount, 10);
    this.summary.lvr = isInvalidAmounts
      ? 0
      : ((ProposedLoanAmount / SecurityAmount) * 100).toFixed(2);
  }

  /*
   * everything that is changed in the summary panel should update the this.summary property
   * please refer to loanOpportunityService.setLoanOpportunitySummary api in swagger for the model structure
   */
  reassignSummary(newObj = {}) {
    this.summary = Object.assign({}, this.summary, { ...newObj });
  }

  summaryChange(
    isEnquiryAndReferralChanged = false,
    showToaster = false,
    changedPropertyKey,
  ) {
    this.isSaving = true;
    this.loanOpportunityService
      .setLoanOpportunitySummary(this.opportunityId, { ...this.summary })
      .then(() => {
        this.isSaving = false;
        showToaster &&
          this.toaster.pop('success', 'Success', 'Successfully updated!');
      })
      .then(() => {
        isEnquiryAndReferralChanged && this.onRefreshDetails();
      });
    this.calculateLvr();
  }

  checkIfReferrer() {
    return this.enquirySource
      ? REFERRED_BY_ACCESS.includes(this.enquirySource.ReferralItemId)
      : false;
  }

  getPurposeList() {
    this.optionsService.loanpurpose().then((res) => {
      const { data } = res;
      const purposeList = data.map((obj) => {
        obj.Value = parseInt(obj.Value, 10);
        return obj;
      });

      this.purposeList = purposeList;
    });
    this.purpose = {};
  }

  updateEnquiry() {
    this.summary.EnquirySource.ReferrerId = 0;
    this.summary.EnquirySource.ReferrerOrgId = 0;
    this.summary.EnquirySource.ReferrerName = '';
    this.summary.EnquirySource.ReferrerOrgName = '';
    this.enquiryChanged(true);
  }

  adviserLeadChanged(isAdviserLead) {
    if (!this.enquirySource) {
      return;
    }
    this.reassignSummary({
      EnquirySource: {
        ...this.summary.EnquirySource,
        IsAdviserLead: isAdviserLead,
      },
    });
    this.summaryChange(false, true);
  }

  enquiryChanged(showSuccessToaster, isDetails) {
    if (!this.enquirySource) {
      return;
    }
    this.summary.ClientReferrerFamilyId = 0;
    this.defaultClient = {
      FamilyID: 0,
      FamilyFullName: '',
      FirstName: '',
      LastName: '',
    };
    const { ReferralItemId, ReferralCategoryId } = this.enquirySource;
    const isReferral = REFERRED_BY_ACCESS.includes(ReferralItemId);
    this.referrerRequired =
      isReferral && !this.summary.EnquirySource.ReferrerId;
    if (this.referrerRequired) {
      this.referrerObj.ReferralMemberName = '';
      return;
    }
    const clientObj =
      this.displayClient(ReferralItemId) &&
      this.clientModel &&
      this.clientModel.originalObject;
    this.summary = clientObj ? { ...this.summary, ...clientObj } : this.summary;
    this.summary.ClientReferrerFamilyId = clientObj.FamilyID || 0;
    this.summary.ReferrerClientId = clientObj.ClientEntityId || 0;
    this.summary.OtherContactReferrerName =
      isDetails && this.displayDetails(ReferralItemId)
        ? this.summary.OtherContactReferrerName
        : '';
    const ReferrerId = isReferral ? this.summary.EnquirySource.ReferrerId : 0;
    const ReferrerOrgId = isReferral
      ? this.summary.EnquirySource.ReferrerOrgId
      : 0;
    const ReferrerName = isReferral
      ? this.summary.EnquirySource.ReferrerName
      : '';
    const ReferrerOrgName = isReferral
      ? this.summary.EnquirySource.ReferrerOrgName
      : '';
    const ReferrerAgreementTypeId = isReferral
      ? this.summary.EnquirySource.ReferrerAgreementTypeId
      : 0;
    const referralItemId = setReferralItemId(this.enquirySource);

    const isEnquiryAndReferralChanged = isReferral && ReferrerId;
    this.reassignSummary({
      EnquirySource: {
        ...this.summary.EnquirySource,
        ReferrerId,
        ReferrerOrgId,
        ReferralItemId: referralItemId,
        ReferralCategoryId,
        ReferrerName,
        ReferrerOrgName,
        ReferrerAgreementTypeId,
      },
    });
    this.summaryChange(isEnquiryAndReferralChanged, showSuccessToaster);
  }

  updateLendingCategory(lendingCategoryId) {
    this.reassignSummary({ LendingCategoryId: lendingCategoryId });
    this.summaryChange(true, true);
  }

  getReferrerOrgList(adviserId) {
    this.referencedReferralService
      .getReferencedReferralList(adviserId, null)
      .then((res) => {
        this.referralOrgList = res.map((o) => {
          const ReferralOrganizationName = o.ReferralOrganizationName
            ? ` - ${o.ReferralOrganizationName}`
            : ``;
          o.ReferralMemberNameBak = `${o.ReferralMemberName}`;
          o.ReferralMemberName = `${o.ReferralMemberName}${ReferralOrganizationName}`;
          return o;
        });

        this.setReferrer();
      });
  }

  setReferrer() {
    const { EnquirySource } = this.summary;
    if (EnquirySource) {
      const {
        ReferrerId,
        ReferrerOrgId,
        ReferrerName,
        ReferrerOrgName,
        ReferrerAgreementTypeId,
      } = EnquirySource;
      const hasPreviousReferrer = !!ReferrerId;
      const orgName = EnquirySource.ReferrerOrgName
        ? ` - ${EnquirySource.ReferrerOrgName}`
        : ``;
      const referredBy = `${EnquirySource.ReferrerName}${orgName}`;
      this.referrerObj = {
        AgreementTypeId: ReferrerAgreementTypeId,
        ReferralMemberId: ReferrerId,
        ReferralMemberName: referredBy,
        ReferralMemberNameBak: ReferrerName,
        ReferralOrganizationId: ReferrerOrgId,
        ReferralOrganizationName: ReferrerOrgName,
      };

      if (hasPreviousReferrer && !Object.keys(this.referrerObj).length) {
        this.enquiryChanged();
      }
    }
  }

  searchReferrer() {
    const { ReferralMemberName } = this.referrerObj;
    this.referrerObj = { ReferralMemberName };
    this.showReferrerList = true;
  }

  selectReferrer(referrer) {
    this.showReferrerList = false;
    this.referrerObj = { ...referrer };
    const {
      ReferralMemberId: ReferrerId,
      ReferralOrganizationId: ReferrerOrgId,
      ReferralMemberNameBak: ReferrerName,
      ReferralOrganizationName: ReferrerOrgName,
      AgreementTypeId: ReferrerAgreementTypeId,
    } = this.referrerObj;
    this.reassignSummary({
      EnquirySource: {
        ...this.summary.EnquirySource,
        ReferrerId,
        ReferrerOrgId,
        ReferrerName,
        ReferrerOrgName,
        ReferrerAgreementTypeId,
      },
    });
    this.enquiryChanged(true, true);
  }

  displayClient() {
    if (!this.enquirySource || !this.enquirySource.ReferralItemId) {
      return false;
    }
    return this.clientEnquiryList.includes(this.enquirySource.ReferralItemId);
  }

  displayDetails() {
    if (!this.enquirySource || !this.enquirySource.ReferralItemId) {
      return false;
    }
    return this.additionalEnquiryList.includes(
      this.enquirySource.ReferralItemId,
    );
  }

  clientSearchApi(userInputString) {
    const defer = this.$q.defer();
    defer.resolve([]);
    if (!userInputString || !this.allocatedAdviserId) {
      return defer.promise;
    }
    return this.loanProfilerService
      .getSearchClient(userInputString, INVOLVED_PARTIES_TYPE.CLIENT)
      .then(mapEnquiryTitleField);
  }

  selectClient(client) {
    if (!client) {
      return;
    }
    this.clientModel = client;
    this.enquiryChanged();
  }

  handleViewClient({ accountDetails }) {
    if (!accountDetails) {
      return;
    }
    const { EntityTypeName, FamilyId } = accountDetails;
    const isBusinessAccount = EntityTypeName === 'Company';
    this.route = `app.${
      isBusinessAccount ? 'BusinessAccount' : 'contactsSingle'
    }`;
    this.activeTab = isBusinessAccount ? 'entity' : 'clients';
    const params = {
      familyId: FamilyId,
      activeTab: this.activeTab,
    };
    this.$state.go(this.route, params);
  }

  openNodifiInNewTab(applicationId, redirectUrl) {
    if (!applicationId) {
      this.$window.open(this.nodifiLink);
    } else {
      const urlStep =
        this.summary.LendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE
          ? NODIFI_STEP_ASSET
          : NODIFI_STEP_PERSONAL_LOAN;

      const nodifiUrl = this.uiService.switchToNodifiV2
        ? `https://${this.deriveNodifiTenantName(
            this.currentUserService.businessUnitId,
          )}.${this.nodifiApiUrl}/login/`
        : `${this.nodifiLink}/loanapp/${urlStep}/${applicationId}`;

      this.$window.open(redirectUrl || nodifiUrl);
    }
  }

  submitNodifiApplication() {
    if (!this.showNodifiPhase2) {
      this.openNodifiInNewTab();
      return;
    }

    if (this.isSaving) {
      this.toaster.pop(
        'error',
        'Validation Error',
        'Saving in process, try again.',
      );
      return;
    }

    if (
      this.summary.AssetProductType !== ASSET_PRODUCT_TYPE.COMMERCIAL &&
      this.summary.AssetProductType !== ASSET_PRODUCT_TYPE.CONSUMER
    ) {
      this.toaster.pop(
        'error',
        'Validation Error',
        'Select a asset product type',
      );
      return;
    }

    var existingLink = this.summary.IntegrationReferences?.find(
      (x) => x.IntegrationType === INTEGRATION_TYPE.NODIFI,
    );

    if (existingLink?.IntegrationReference) {
      this.openNodifiInNewTab(existingLink.IntegrationReference);
      return;
    }

    this.nodifiIntegrationService
      .CreateApplication(this.opportunityId, this.summary.AssetProductType)
      .then((response) => {
        if (response.data) {
          const { ApplicationId, RedirectUrl } = response.data;
          if (!this.summary.IntegrationReferences) {
            this.summary.IntegrationReferences = [];
          }
          this.summary.IntegrationReferences.push({
            IntegrationType: INTEGRATION_TYPE.NODIFI,
            IntegrationReference: ApplicationId,
          });
          this.openNodifiInNewTab(ApplicationId, RedirectUrl);
        }
      })
      .catch((error) => {
        this.toaster.pop('error', error.statusText, error.data);
      });
  }
}

export default OpportunityDetailsCtrl;
