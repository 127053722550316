import template from './insuranceOnlineFactFind.html';
import controller from './insuranceOnlineFactFindCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
  },
};
