import { CONTACT_TYPE } from 'Common/constants/contactType';
import { ADVISER_STATUS } from 'Common/constants/adviserStatus';
import { toastSuccess, toastError } from 'Common/utilities/alert';
import { getRoleIdByContactType } from 'Common/utilities/contact';
import {
  getFormattedPartyData,
  getPartyDetailsByClientId,
  displayWritingOption,
  isSaveOnlyNoInvite,
  isEntityPartOfType,
} from 'Common/utilities/onboarding';

class OnboardingAdditionalParties {
  constructor(
    $stateParams,
    onboardingService,
    onboardingDataService,
    currentUserService,
    corporateService,
    corporateModelService,
    commonFnService,
  ) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.onboardingService = onboardingService;
    this.onboardingDataService = onboardingDataService;
    this.currentUserService = currentUserService;
    this.corporateService = corporateService;
    this.corporateModelService = corporateModelService;
    this.commonFnService = commonFnService;
    this.displayWritingOption = displayWritingOption;
    this.isSaveOnlyNoInvite = isSaveOnlyNoInvite;
    this.CONTACT_TYPE = CONTACT_TYPE;

    this.editing = { status: false, partyType: '', index: -1 };
    this.tabValue = 'additionalParties';
  }

  setToInt(value) {
    return this.commonFnService.setBooleanToInt(value);
  }

  getNewPartyIndex(partyType) {
    switch (partyType) {
      case this.CONTACT_TYPE.DIRECTOR:
        return this.directorsList.length ? this.directorsList.length + 1 : 1;
      case this.CONTACT_TYPE.SHARE_HOLDER:
        return this.shareholdersList.length
          ? this.shareholdersList.length + 1
          : 1;
      case this.CONTACT_TYPE.PARTNER_STR:
        return this.partnersList.length ? this.partnersList.length + 1 : 1;
      default:
        return 1;
    }
  }

  showParty(type, idx) {
    const { status, partyType, index } = this.editing;
    if (!status) {
      return true;
    }
    return partyType === type && index === idx;
  }

  showNewPartyComponent(show) {
    this.showNewParty = show;
    this.hideButtons = show;
  }

  showUnsavedWarning() {
    return this.onboardingDataService.hasUnsavedChanges(this.tabValue);
  }

  setEntityType(entityType) {
    this.isEntityCompanyType = isEntityPartOfType('Company', entityType);
    this.isEntityPartnershipType = isEntityPartOfType(
      'Partnership',
      entityType,
    );
  }

  getPartyList(party) {
    if (party.isCompanyDirector) {
      return this.directorsList;
    }
    if (party.isShareHolder) {
      return this.shareholdersList;
    }
    if (party.isBusinessPartner) {
      return this.partnersList;
    }
    return [];
  }

  getAddedParties() {
    this.onboardingService
      .getRecruitmentContact(
        this.familyId,
        this.onboardingDataService.getClientId(),
      )
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }
        this.directorsList = data.filter(
          (contact) => contact.isCompanyDirector,
        );
        this.formattedDirectors = this.directorsList.map(getFormattedPartyData);
        this.shareholdersList = data.filter((contact) => contact.isShareHolder);
        this.formattedShareholders = this.shareholdersList.map(
          getFormattedPartyData,
        );
        this.partnersList = data.filter((contact) => contact.isBusinessPartner);
        this.formattedPartners = this.partnersList.map(getFormattedPartyData);
      });
  }

  addParty(partyType) {
    this.saveCompleted = false;
    this.partyToAdd = partyType;
    this.showNewPartyComponent(true);
  }

  cancelPartyAddition() {
    this.showNewPartyComponent(false);
    this.onboardingDataService.setUnsavedChanges(this.tabValue, false);
  }

  saveParty(party, partyType) {
    const detail = {
      ClientID: 0,
      FamilyId: 0,
      FirstName: party.firstName,
      MiddleName: party.middleName,
      LastName: party.lastName,
      Mobile: party.mobile,
      Email: party.email,
      ContactType: CONTACT_TYPE.ADVISER_STR,
      RoleId: getRoleIdByContactType(partyType),
      AdviserOrganizationId: this.onboardingDataService.getAdviserOrgId(),
      isWriting: this.setToInt(party.isWritingValue),
      BrokerStatusId: ADVISER_STATUS.ONBOARDING,
    };

    this.onboardingService
      .setRecruitmentContact(this.familyId, detail)
      .then(({ data }) => {
        detail.clientID = data;
        return this.onboardingService.getRecruitmentContact(0, detail.clientID);
      })
      .then(({ data }) => {
        if (!data || !data.length) {
          return Promise.reject(new Error(`Error in saving ${partyType}`));
        }
        detail.partnerFamilyId = data[0] && data[0].FamilyId;

        return this.onboardingService.familyPartnershipSet(
          this.familyId,
          detail.partnerFamilyId,
          detail.clientID,
          detail.isWriting,
          this.setToInt(partyType === CONTACT_TYPE.PARTNER_STR),
          this.setToInt(partyType === CONTACT_TYPE.DIRECTOR),
          this.setToInt(partyType === CONTACT_TYPE.SHARE_HOLDER),
          1,
          0,
        );
      })
      .then(() => {
        toastSuccess(`${partyType} successfully saved`);
        this.getAddedParties();
        this.saveCompleted = true;
        this.showNewPartyComponent(false);
        this.resetEditStatus();
      })
      .catch((error) => {
        toastError(error.message);
        this.saveCompleted = true;
        this.showNewPartyComponent(false);
        this.resetEditStatus();
      });
  }

  editParty(index, partyType) {
    this.editing = { status: true, partyType, index };
    this.hideButtons = true;
  }

  resetEditStatus() {
    this.editing = { status: false, partyType: '', index: -1 };
    this.hideButtons = false;
    this.onboardingDataService.setUnsavedChanges(this.tabValue, false);
  }

  updateParty(party, partyType) {
    const partyList = this.getPartyList(party);
    const partyDetails = getPartyDetailsByClientId(partyList, party.clientId);
    const updatedDetails = {
      ...partyDetails,
      FirstName: party.firstName,
      MiddleName: party.middleName,
      LastName: party.lastName,
      Mobile: party.mobile,
      Email: party.email,
      isWriting: this.setToInt(party.isWritingValue),
    };

    this.onboardingService
      .setRecruitmentContact(this.familyId, updatedDetails)
      .then(() => {
        return this.onboardingService.familyPartnershipSet(
          this.familyId,
          updatedDetails.FamilyId,
          updatedDetails.ClientID,
          updatedDetails.isWriting,
          updatedDetails.isBusinessPartner,
          updatedDetails.isCompanyDirector,
          updatedDetails.isShareHolder,
          1,
          updatedDetails.FamilyPartnershipID,
        );
      })
      .then(() => {
        toastSuccess(`${partyType} successfully saved`);
        this.resetEditStatus();
        this.getAddedParties();
      })
      .catch((error) => {
        toastError(error.message);
        this.resetEditStatus();
      });
  }

  deleteParty(party, partyType) {
    this.onboardingService
      .familyPartnershipSet(
        this.familyId,
        party.familyId,
        party.clientId,
        party.isWritingValue,
        party.isBusinessPartner,
        party.isCompanyDirector,
        party.isShareHolder,
        0,
        party.familyPartnershipId,
      )
      .then(() => {
        return this.corporateModelService.deleteAdditionalStaff(party.familyId);
      })
      .then(() => {
        toastSuccess(`${partyType} successfully removed!`);
        this.resetEditStatus();
        this.getAddedParties();
      });
  }

  displayEmptySection() {
    const isDirectorAvailable =
      this.isEntityCompanyType &&
      this.formattedDirectors &&
      this.formattedDirectors.length;
    const isShareHolderAvailable =
      this.isEntityCompanyType &&
      this.formattedShareholders &&
      this.formattedShareholders.length;
    const isPartnersAvailable =
      this.isEntityPartnershipType &&
      this.formattedPartners &&
      this.formattedPartners.length;
    return (
      this.readOnly &&
      !(isDirectorAvailable || isShareHolderAvailable || isPartnersAvailable)
    );
  }

  $onInit() {
    this.familyId = this.$stateParams.familyId;
    this.showNewParty = false;
    this.getAddedParties();

    this.entityType = this.onboardingDataService.getUserCurrentEntityType();
    this.setEntityType(this.entityType);
  }
}

export default OnboardingAdditionalParties;
