class SelectRadioCtrl {
  $onInit() {
    if (!this.values || !this.values.length) {
      this.values = [];
    }
  }

  toggleRadio(obj) {
    if (obj && !this.values.includes(obj)) {
      this.values.push(obj);
    } else if (obj && this.values.includes(obj)) {
      const idx = this.values.indexOf(obj);
      this.values.splice(idx, 1);
    }

    this.valuesTextArray = [];
    this.options &&
      this.options.forEach((optionsGroup) => {
        optionsGroup &&
          optionsGroup.map((option) => {
            if (this.values.includes(this.options && option)) {
              option.show = true;
              this.valuesTextArray.push(option.PipelineStatus);
            }
            return option;
          });
        return optionsGroup;
      });
    this.valuesText = this.valuesTextArray.join(', ');

    if (obj) {
      this.onChange({ values: this.values });
    }
  }

  $onChanges() {
    this.toggleRadio();
  }
}

export default SelectRadioCtrl;
