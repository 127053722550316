import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('mycrmAddressField', function mycrmAddressField(generalService) {
      return {
        restrict: 'E' /* element  */,
        link(scope) {
          scope.homeAutoAddressDetails = [];
          scope.homeAutoAddresss = function () {
            generalService.placeSearch(scope.address).then((response) => {
              scope.homeAutoAddressDetails = response.data;
            });
          };

          scope.closeAutoHomeAddress = function () {
            scope.homeAutoAddressDetails = [];
          };

          scope.selectHomeAddress = function (type, address, addressObj) {
            if (type === scope.addressType) {
              scope.address = address;
            }
            let addressTmp = {};
            addressTmp = { ...addressObj };
            if (scope.selectedAddressMethod) {
              scope.selectedAddressMethod({ addressObj: addressTmp });
            }
            scope.closeAutoHomeAddress();
          };

          scope.addressModelValue = (address) => {
            const addressTmp = address;
            if (scope.addressValueOnBlurMethod) {
              scope.addressValueOnBlurMethod({ addressObj: addressTmp });
            }
          };
        },
        scope: {
          /*  (isolatedScope) - defining scope means that 'no inheritance from parent'. */
          /* NOTE: Normally I would set my attributes and bindings
            to be the same name but I wanted to delineate between
            parent and isolated scope. */
          address:
            '=addressModelValue' /* (two-way binding) which means any modification in
        childScope will also update the value in parentScope, and vice versa.
        This rule works for both primitives and objects.
        If you change the binding type of parentObj to be =, you will
         find that you can modify the value of parentObj.x.
        A typical example is ngModel. */,
          addressType:
            '@addressTypeValue' /* (one way binding) simply passes the property from parentScope
        to isolatedScope. It is called one-way binding,
        which means you cannot modify the
        value of parentScope properties. */,
          selectedAddressMethod: '&?' /* optional function */,
          addressValueOnBlurMethod: '&?' /* optional function */,
          requiredField: '@?',
          isReadOnly: '@?',
        },
        templateUrl: 'assets/views/addressField/addressField.tpl.html',
        replace: true,
      };
    });
