import {
  commonProps,
  viewOfAFileTheme,
  mycrmConfirmationDefault,
} from 'Common/default/modal';
import { getLogger } from '@loanmarket/logger-core';
import { sendHelloBook } from 'Common/utilities/contact';

class ViewOfFileModalService {
  constructor(
    $uibModal,
    uiService,
    helloBookPreviewService,
    contactModelService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.uiService = uiService;
    this.helloBookPreviewService = helloBookPreviewService;
    this.contactModelService = contactModelService;
    this.logger = getLogger('ViewOfFileModalService');
  }

  launchCreateFileConfirmation({
    familyId,
    allocatedAdviserId = 0,
    clientName,
    personIds,
    isClient,
  }) {
    if (!this.uiService.viewOfAfileTurnedOn) {
      return;
    }
    const props = {
      modalTitle: 'Contact created!',
      message: `Would you also like to create a new deal for ${clientName}?`,
      confirmationTitle: 'Create deal',
      discardTitle: 'Not now',
    };
    return this.launchConfirmation(props).then(({ isCancelled }) => {
      if (isCancelled) {
        return;
      }
      const params = {
        familyId,
        allocatedAdviserId,
        personIds,
        isClient,
      };
      this.launchCreateNewFile(params).then(({ loanApplicationId }) =>
        this.launchHelloPackConfirmation({ ...params, loanApplicationId }),
      );
    });
  }

  launchHelloPackConfirmation(params) {
    if (!params.isClient) {
      return;
    }
    if (!params || !params.familyId) {
      this.logger.error('Launching Hello Pack failed - No family ID found');
      return;
    }
    const props = {
      modalTitle: 'File created!',
      message: `Would you also like to send a Hello Pack?`,
      confirmationTitle: 'Send Hello Pack',
      discardTitle: 'Not now',
    };
    return this.launchConfirmation(props).then(({ isCancelled }) => {
      if (isCancelled) {
        return;
      }
      this.launchHelloPack(params);
    });
  }

  launchCreateFactFindConfirmation({
    familyId,
    allocatedAdviserId,
    personIds,
  }) {
    const props = {
      modalTitle: 'Hello Pack sent!',
      message: `Please create a new file to view the client's fact find and capture other key information.`,
      confirmationTitle: 'Create file',
      discardTitle: null,
    };
    return this.launchConfirmation(props).then(({ isCancelled }) => {
      if (isCancelled) {
        return;
      }
      this.launchCreateNewFile({
        familyId,
        allocatedAdviserId,
        personIds,
        isClient: true,
        factFindEnabled: true,
      });
    });
  }

  launchCreateNewFile({
    familyId = 0,
    allocatedAdviserId = 0,
    personIds = [],
    isClient = true,
    factFindEnabled = false,
    disableRouting = false,
  }) {
    return this.$uibModal.open({
      template: `<create-new-file-modal
                    modal-instance="vm.modalInstance"
                    family-id="vm.props.familyId"
                    allocated-adviser-id="vm.props.allocatedAdviserId"
                    person-ids="vm.props.personIds"
                    is-client="vm.props.isClient"
                    fact-find-enabled="vm.props.factFindEnabled"
                    disable-routing="vm.props.disableRouting"
                    theme="vm.props.theme">
                </create-new-file-modal>`,
      ...commonProps,
      windowClass: `mycrm-modal new-file-modal`,
      resolve: {
        props: () => ({
          theme: viewOfAFileTheme,
          familyId,
          allocatedAdviserId,
          personIds,
          isClient,
          factFindEnabled,
          disableRouting,
        }),
      },
    }).result;
  }

  launchConfirmation(props = mycrmConfirmationDefault) {
    return this.$uibModal.open({
      template: `<mycrm-confirmation
                    modal-instance="vm.modalInstance"
                    data-theme="{{vm.props.theme}}" 
                    modal-title="{{vm.props.modalTitle}}"
                    message="{{vm.props.message}}"
                    confirmation-title="{{vm.props.confirmationTitle}}"
                    discard-title="{{vm.props.discardTitle}}"
                    on-confirm="vm.props.onConfirm">
                </mycrm-confirmation>`,
      ...commonProps,
      windowClass: 'mycrm-modal mycrm-confirmation-modal',
      resolve: {
        props: () => ({ theme: viewOfAFileTheme, ...props }),
      },
    }).result;
  }

  launchHelloPack(params) {
    if (!params || !params.familyId) {
      return;
    }
    const openSendHelloBook = (additionalProps) => {
      const props = {
        ...additionalProps,
        familyId: params.familyId,
        isNewModal: this.uiService.newHelloModalEnabled,
        loanApplicationId: params.loanApplicationId,
      };
      this.helloBookPreviewService.open(props);
    };
    this.getDesignatedFamilyBrokerInfo(params.familyId).then(
      (designatedBrokerInfo) => {
        sendHelloBook({
          vm: { designatedBrokerInfo, openSendHelloBook },
          helloBookPreviewService: this.helloBookPreviewService,
        });
      },
    );
  }

  getDesignatedFamilyBrokerInfo(familyId) {
    if (!familyId) {
      return Promise.reject(new Error('Family ID is required'));
    }
    return this.contactModelService.getFamilyAdviserInfo(familyId);
  }
}

export default ViewOfFileModalService;
