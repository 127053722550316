import template from './loanBasicDetails.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    loanDetails: '<',
    loanStatusList: '<',
    lendingDetailScope: '<',
    loanDetailScope: '<',
    loadingPropertyKey: '<',
    isNewPipeline: '<',
  },
};
