export function pipelineLabelBuilder(labelString) {
  const labelsArray =
    (labelString && labelString.length && labelString.split(',')) || [];
  return labelsArray.reduce((labelAccum, labelCurrent) => {
    const labelSplit = labelCurrent.split(':');
    const LABEL_SPLIT_CONTENTS = {
      COLOR: 0,
      NAME: 1,
      ID: 2,
    };
    const labelData = {
      labelId: labelSplit[LABEL_SPLIT_CONTENTS.ID],
      labelName: labelSplit[LABEL_SPLIT_CONTENTS.NAME],
      labelColor: labelSplit[LABEL_SPLIT_CONTENTS.COLOR],
    };
    return labelAccum.concat(labelData);
  }, []);
}
