import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .filter('monthName', [
      function monthName() {
        return function (monthNumber) {
          // 1 = January
          const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];
          return monthNames[monthNumber - 1];
        };
      },
    ])
    .filter('monthNameSm', [
      function monthNameSm() {
        return function (monthNumber) {
          // 1 = January
          const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ];
          return monthNames[monthNumber - 1];
        };
      },
    ]);
