import angular from 'angular';

export const loadModule = () =>
  angular.module('app').controller(
    'PipelineAppAddCardModalCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function PipelineAppAddCardModalCtrl(
      $scope,
      $uibModalInstance,
      $localStorage,
      $stateParams,
      contactService,
      $state,
      pipelineService,
      toaster,
      appsStatusList,
      familyId,
      subtool,
      pipelineSharedData,
      dashboardService,
      generalSearchService,
    ) {
      $scope.statusList = [];
      $scope.addedFamilyList = [];
      if (familyId) {
        contactService.clientInformGet(familyId).then((response) => {
          $scope.familyList = {};

          $scope.familyList.FamilyId = familyId;
          if (response.data.length) {
            $scope.familyList.FamilyFullName = response.data[0].FullName;
          }
          $scope.familyInput = $scope.familyList;
        });
      }
      $scope.searchFamilyInput = (inputFamily) => {
        generalSearchService.searchFamilyInput($scope, inputFamily);
      };

      $scope.closeFamilyList = function () {
        $scope.familyDetailsList = [];
      };
      $scope.getInitials = function (name) {
        if (!name) {
          return;
        }
        return name
          .replace(/[^ A-Za-z]/g, '')
          .replace(/\W*(\w)\w*/g, '$1')
          .toUpperCase();
      };
      $scope.selectFamily = function (familyDetails) {
        $scope.familyInput = familyDetails;
        $scope.familyDetailsList = [];
      };

      const goToContact = () => {
        if (!$scope.isCorporateUser) {
          $state.go('app.createContact', {
            fromTool: 'pipeline',
          });
        } else {
          $state.go('app.createContactCorporate', {
            fromTool: 'pipeline',
          });
        }
      };

      $scope.gotoCreateContact = function () {
        $uibModalInstance.dismiss('cancel');
        goToContact();
      };
      $scope.createNewContact = function (familyName, pipelineStatus) {
        $uibModalInstance.dismiss('cancel');
        $localStorage.pipelineCreateNewContact = '';
        $localStorage.previousState = '';
        $localStorage.previousStateParams = '';
        $localStorage.pipelineFamilyInput = '';
        $localStorage.pipelineStatus = '';

        const pipelineStatusObj = $scope.appsStatusListCopy.filter((item) => {
          if (
            parseInt(item.PipelineStatusID, 10) ===
            parseInt(pipelineStatus.PipelineStatusID, 10)
          ) {
            return item;
          }
          return false;
        });

        if ($scope.addedFamilyList.length > 0) {
          $localStorage.addedPipelinesContact = $scope.addedFamilyList;
        }
        pipelineSharedData.checkFamilyNameLocalStorage(
          familyName,
          pipelineStatusObj[0],
        );
        $localStorage.pipelineCreateNewContact = true;
        $localStorage.previousState = 'app.application';
        $localStorage.previousStateParams = {
          familyId,
        };

        goToContact();
      };
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.type = subtool;

      function getStatus() {
        if (appsStatusList && appsStatusList.length) {
          $scope.leadsStatus = true;
          $scope.appsStatusList = [];
          $scope.appsStatusList = appsStatusList;
          $scope.appsStatusListCopy = [...$scope.appsStatusList];
          $scope.initSelectedStatus();
        } else {
          pipelineService.SettingsStatusGetFiltered().then(
            (response) => {
              $scope.statusList = response.data;
              $scope.appsStatusList = [];
              for (let i = 0; i < $scope.statusList.length; i++) {
                if ($scope.statusList[i].TypeName !== 'Lead') {
                  $scope.appsStatusList.push($scope.statusList[i]);
                }
              }
              $scope.appsStatusListCopy = [...$scope.appsStatusList];
              $scope.initSelectedStatus();
            },
            () => {},
          );
        }
      }

      $scope.initSelectedStatus = () => {
        if ($scope.type !== 'leads') {
          $scope.leadsStatus = false;
          if ($scope.appsStatusList.length > 0) {
            $scope.selectedStatus = { ...$scope.appsStatusList[0] };
          }
        }
      };

      $scope.addCard = (familyInput) => {
        if (!$scope.selectedStatus || !familyInput) {
          return;
        }

        const status = $scope.selectedStatus.PipelineStatusID;
        $scope.addCardObj = {};
        $scope.addCardObj.clientFamilyId = parseInt(familyInput.FamilyId, 10);
        $scope.addCardObj.pipelineStatusID = parseInt(status, 10);

        pipelineService
          .PipelineCardsSet($scope.addCardObj)
          .then((response) => {
            const newCard = response && response.data;
            if (newCard && newCard > 0) {
              toaster.pop(
                'success',
                'Added',
                'Card has been added successfully.',
              );
              $stateParams.createdContact = '';
              $uibModalInstance.close(newCard);
            } else {
              $scope.handleError(newCard);
            }
          })
          .catch((error) => {
            $scope.handleError(error.data);
          });
      };

      $scope.handleError = (error) => {
        const errorMessage = error
          ? error.Message
          : 'Yikes! Something is wrong';
        toaster.pop('error', 'Error', errorMessage);
      };

      function init() {
        getStatus();
      }

      init();
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    },
  );
