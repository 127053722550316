import moment from 'moment';
import { MERGE_FIELDS_CATEGORIES } from 'Common/constants/mergeFields';
import { D_MMMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';

export function addMergeFieldsCategory(emailObj) {
  return { ...emailObj, MergeFieldsCategory: MERGE_FIELDS_CATEGORIES.OTHER };
}

export const checkIsSending = ({ $scope, isSending }) => {
  $scope.isSendingEmail = isSending;
};

export const formatExpiryList = (list, selectedExpiries) => {
  if (!list || !list.length) {
    return [];
  }
  const today = moment().format(D_MMMM_YYYY_FORMAT);
  return list.map((item) => {
    const expiry = moment(item.ExpiryDate).format(D_MMMM_YYYY_FORMAT);
    item.IsDueToday = today === expiry;
    if (selectedExpiries) {
      item.isChecked = selectedExpiries.includes(item.loanStructureID);
    }

    return item;
  });
};

export const checkAnnualReviews = ({
  $scope,
  apiService,
  configService,
  currentUserService,
}) => {
  apiService.isAnnualReviewAllowed().then((res) => {
    $scope.isAnnualReviewAllowed = res.data;
    if (configService && currentUserService) {
      $scope.showAnnualReview =
        (currentUserService.isNZ &&
          configService.feature.annualReviewMonthNZ) ||
        $scope.isAnnualReviewAllowed;
    }

    if ($scope.getInsuranceTabs) {
      $scope.getInsuranceTabs();
    }
  });
};

export const annualReviewBannerUtil = ({
  $scope,
  apiService,
  currentUserService,
}) => {
  $scope.showAnnualReviewBanner = false;
  $scope.isAnnualReviewInsuranceAllowed = false;
  $scope.isAnnualreviewLoanAllowed = false;

  $scope.checkAnnualReviewInsuranceAllowed = () => {
    apiService.isAnnualReviewAllowed().then((res) => {
      $scope.isAnnualReviewInsuranceAllowed = res.data;
      $scope.checkAnnualReviewLoanAllowed();
    });
  };

  $scope.checkAnnualReviewLoanAllowed = () => {
    apiService.isAnnualReviewLoanAllowed().then((res) => {
      $scope.isAnnualreviewLoanAllowed = res.data;
      $scope.showAnnualReviewBanner =
        $scope.isAnnualReviewInsuranceAllowed &&
        $scope.isAnnualreviewLoanAllowed &&
        currentUserService.isNZ &&
        $scope.isAdviser;
    });
  };

  $scope.checkAnnualReviewInsuranceAllowed();
};
