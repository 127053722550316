class LenderRatesModalService {
  constructor($uibModal) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  launchLenderListModal(size, list, repay) {
    this.$uibModal.open({
      templateUrl: '/assets/views/loanTools/partials/lender_rate_modal.html',
      resolve: {
        lenderList() {
          return list;
        },
        repayObj() {
          return repay;
        },
      },
      controller: 'LenderRatesModalCtrl',
      size,
    });
  }
}

export default LenderRatesModalService;
