import template from './template.html';
import TaskFormModalCtrl from './taskFormModalCtrl';
import './style.scss';

export default {
  template,
  controller: TaskFormModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    familyList: '<',
    loanId: '<',
    familyId: '<',
    taskId: '<',
    quoteId: '<',
    hasFilter: '<',
    taskName: '<',
    completedBy: '<',
    isLinkToOpportunity: '<',
    selectedOpportunityId: '<',
    opportunityList: '<',
    isFromTaskList: '<',
    fileName: '<',
  },
};
