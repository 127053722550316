/* eslint-disable angular/window-service */
import decorator from '../directives/ImageSource/ngSrcDecorator';

const configNgSrc = [
  '$provide',
  ($provide) => {
    $provide.decorator('ngSrcDirective', [
      '$delegate',
      'LEGACY_ANGULAR_HOST',
      'TECHNOLOGY',
      decorator,
    ]);
  },
];

export default configNgSrc;
