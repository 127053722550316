import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('ModifyRefixCtrl', function ModifyRefixCtrl(
      $scope,
      contactService,
      imageDataURI,
      SweetAlert,
      $timeout,
      $uibModalInstance,
      $uibModal,
      $filter,
      toaster,
    ) {
      $scope.modifyRefixDataField = function () {
        // Rate Type select options
        $scope.RateExpireInMonths = ['Year(s)', 'Month(s)'];

        // Terms select options
        $scope.selectTerms = [];
        for (let i = 1; i < 31; i++) {
          $scope.selectTerms.push(i);
        }

        $scope.setFixedRate = {};
        $scope.setFixedRate.RateId = 0;
        $scope.setFixedRate.LoanStructureId = $scope.params.structureId;
        $scope.setFixedRate.RateBegin = new Date();
        $scope.setFixedRate.RateEnd = new Date();
        $scope.setFixedRate.InterestPeriod = 1;
        $scope.setFixedRate.PeriodType = $scope.RateExpireInMonths[0];
        $scope.setFixedRate.InterestRate = 0;
        $scope.setFixedRate.IsRefix = true;

        // $scope.data = {};

        $scope.FixedRateHistory = [];

        contactService
          .CurrentFixedRateGet($scope.params.structureId)
          .then((response) => {
            const data = response.data;
            $scope.setFixedRate.RateBegin = data.RateBegin;
            $scope.setFixedRate.RateEnd = data.RateEnd;
            $scope.setFixedRate.InterestPeriod = data.InterestPeriod;
            $scope.setFixedRate.PeriodType = data.PeriodType;
            $scope.setFixedRate.InterestRate = data.InterestRate;
            $scope.setFixedRate.IsRefix = false;
          });

        contactService
          .FixedRateHistoryGet($scope.setFixedRate.LoanStructureId)
          .then((response) => {
            $scope.FixedRateHistory = response.data;
            //
          });
      };
      $scope.modifyRefixDataField();
      $scope.saveFixRate = function () {
        contactService.FixedRatesSet($scope.setFixedRate).then(() => {
          if ($scope.setFixedRate.IsRefix) {
            toaster.pop(
              'success',
              'Added',
              'Successfully added new interest rate',
            );
          } else {
            toaster.pop(
              'success',
              'Updated',
              'Successfully updated interest rate',
            );
          }
        });
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    });
