import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('modalRender', function modalRender(
      modalGoogleAnalyticsService,
    ) {
      return {
        restrict: 'A',
        link(scope, element) {
          const modalContent = element.find('.modal-content')[0];

          const { firstChild, children } = modalContent;
          const isInvalidChildren = modalGoogleAnalyticsService.checkIfChildContentIsInvalid(
            children.length,
            firstChild,
          );

          if (isInvalidChildren) {
            return;
          }

          const contentFirstChild = modalGoogleAnalyticsService.checkIfFirstChildIsAStyle(
            firstChild,
          )
            ? children[1]
            : children[0];

          modalGoogleAnalyticsService.checkElement(contentFirstChild);
        },
      };
    });
