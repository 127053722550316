import template from './overView.html';
import controller from './overViewCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    clientInfo: '<',
    insuranceData: '<',
    modalInstance: '<',
    overViewForm: '&',
  },
};
