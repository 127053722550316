class LeadWorkingDaysCtrl {
  constructor(toaster, corporateService, corporateModelService) {
    'ngInject';

    this.toaster = toaster;
    this.corporateService = corporateService;
    this.corporateModelService = corporateModelService;
  }

  getDefaultLimit(limit) {
    if (!this.isCompact) {
      return this.defaultLimit;
    }
    return limit;
  }

  getSelectAllValue(selectedList) {
    if (!this.leadsWorkingDays || !this.leadsWorkingDays.length) {
      return;
    }
    this.selectAll = selectedList.length === this.leadsWorkingDays.length;
  }

  parseWorkingDays(workingDaysModel) {
    if (!this.allocationWorkingDays || !this.allocationWorkingDays.length) {
      this.leadsWorkingDays = workingDaysModel;
      return;
    }
    this.leadsWorkingDays = workingDaysModel.map((day) => {
      day.isChecked = this.allocationWorkingDays.includes(day.label);
      return day;
    });
    const allChecked = this.leadsWorkingDays.filter((x) => x.isChecked);
    if (!allChecked || !allChecked.length) {
      return;
    }
    this.getSelectAllValue(allChecked);
  }

  checkAll(isChecked) {
    if (!this.leadsWorkingDays || !this.leadsWorkingDays.length) {
      return;
    }
    const days = this.leadsWorkingDays.map((day) => {
      day.isChecked = isChecked;
      return day;
    });
    this.allocationWorkingDays = days.reduce((accum, value) => {
      if (!value.isChecked) {
        return accum;
      }
      accum.push(value.label);
      return accum;
    }, []);
    this.leadsProfile.AllocationWorkingdays =
      this.allocationWorkingDays && this.allocationWorkingDays.length
        ? this.allocationWorkingDays.join()
        : '';
    this.saveLeadProfile(this.leadsProfile);
  }

  checkDay(day) {
    if (!this.isEditable) {
      return;
    }
    const dayIndex = this.allocationWorkingDays.indexOf(day);
    if (dayIndex !== -1) {
      this.allocationWorkingDays.splice(dayIndex, 1);
    } else {
      this.allocationWorkingDays.push(day);
    }
    this.getSelectAllValue(this.allocationWorkingDays);
    this.leadsProfile.AllocationWorkingdays =
      this.allocationWorkingDays && this.allocationWorkingDays.length
        ? this.allocationWorkingDays.join()
        : '';
    this.saveLeadProfile(this.leadsProfile);
  }

  saveLeadProfile(data) {
    const clientId = 0;
    this.corporateService
      .leadsProfileSet(this.familyId, clientId, data)
      .then((response) => {
        if (response.data) {
          this.toaster.pop(
            'success',
            'Updated Successfully',
            'Allocation working days successfully updated',
          );
        } else {
          this.toaster.pop(
            'error',
            'Failed to Update',
            'Allocation working days failed to updated. Please contact administrator',
          );
        }
      })
      .catch(() => {
        this.toaster.pop(
          'error',
          'Failed to Update',
          'Allocation working days failed to updated. Please contact administrator',
        );
      });
  }

  $onInit() {
    this.defaultLimit = 3;
    this.leadsProfile.FamilyId = this.leadsProfile.FamilyId || this.familyId;
    this.selectAll = false;
    const workingDays = this.leadsProfile.AllocationWorkingdays
      ? this.leadsProfile.AllocationWorkingdays.split(',')
      : [];
    this.allocationWorkingDays =
      workingDays &&
      workingDays.reduce((accum, value) => {
        if (!value) {
          return accum;
        }
        accum.push(value.trim());
        return accum;
      }, []);
    this.corporateModelService.getLeadAllocationWorkingDays().then((data) => {
      if (!data || !data.length) {
        return;
      }
      this.parseWorkingDays(data);
    });
  }
}

export default LeadWorkingDaysCtrl;
