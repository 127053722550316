import template from './navigation.html';
import './navigation.scss';

export default {
  template,
  bindings: {
    uiService: '<',
    currentUser: '<',
    configService: '<',
    showPriceFinder: '<',
    priceFinderClick: '&',
    waitPriceFinderResponse: '<',
    newPipeline: '<',
    showNewPipeline: '<',
    nodifiLink: '<',
    showCustomAggregator: '<',
    showAutomation: '<',
  },
};
