import './style.scss';
import template from './employmentDetails.html';
import EmploymentDetailsCtrl from './employmentDetailsCtrl';

export default {
  template,
  bindings: {
    formReference: '<',
    items: '<',
    employment: '=',
    addedDetails: '<',
    isInternationalPhoneEnabled: '<',
    selectedCountryCode: '<',
    errorMessage: '<',
    isPhoneNumberRequired: '<',
    setErrorMessage: '&?',
  },
  controller: EmploymentDetailsCtrl,
  controllerAs: 'vm',
};
