import { isSubstatus } from 'Common/utilities/leadCardUtility';
import { get, set } from 'lodash';

class LeadCardCtrl {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.checkDisplayForSubStatus();
    set(this, 'card.checkDisplayForSubStatus', () =>
      this.checkDisplayForSubStatus(),
    );
  }

  isSubstatus() {
    const isSubstatusExisting = this.timelineStatusList.filter(
      (data) =>
        data.pipelineStatusId === this.card.pipelineStatusId ||
        data.pipelineStatusId === this.card.pipelineStatusID,
    );

    return get(isSubstatusExisting, '[0].subStatus.length', 0);
  }

  checkDisplayForSubStatus() {
    const isSubStatus = isSubstatus({
      timelineStatusList: this.timelineStatusList,
      card: this.card,
    });
    set(this, 'card.isSubStatusExist', isSubStatus);
    this.isShowSubstatus =
      (this.card.probability || this.card.isSubStatusExist) && this.isPipeline;
  }

  onUpdateStatus($e) {
    this.onChangeCardStatus($e);
    this.checkDisplayForSubStatus();
  }
}

export default LeadCardCtrl;
