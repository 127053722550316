import template from './dobInputInlineSave.html';
import controller from './dobInputInlineSaveCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    id: '<',
    inputName: '<',
    index: '<',
    personData: '<',
    baseModule: '<',
    onUpdated: '&',
  },
};
