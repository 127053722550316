import { SORT_TYPE } from 'Common/constants/sort';

export function sortArray(data, sortCol, sortType) {
  if (!data) {
    return;
  }

  const asc = (a, b) => {
    if (!a[sortCol] || !b[sortCol]) {
      return;
    }
    return a[sortCol].toString().localeCompare(b[sortCol].toString());
  };
  const desc = (a, b) => {
    if (!a[sortCol] || !b[sortCol]) {
      return;
    }
    return b[sortCol].toString().localeCompare(a[sortCol].toString());
  };

  data.sort(sortType === 'ASC' ? asc : desc);
  return data;
}

export function changeSorting(tableParams, $column) {
  if (!$column || !tableParams) {
    return;
  }
  $column.sortType = $column.sortType === 'asc' ? 'desc' : 'asc';
  tableParams.sorting($column.sortable(), $column.sortType);
  tableParams.reload();
}

export const fieldSorter = (fields) => (current, next) => {
  const fieldMapped =
    fields &&
    fields.map((field) => {
      // eslint-disable-next-line sonarjs/prefer-object-literal
      const sorterObj = {};
      sorterObj.dir = field[0] === '-' ? -1 : 1;
      sorterObj.value = field.slice(1);
      const currentValue = current[sorterObj.value];
      const currentLowered =
        typeof currentValue === 'string'
          ? currentValue.toLowerCase()
          : currentValue;
      const nextValue = next[sorterObj.value];
      const nextLowered =
        typeof nextValue === 'string' ? nextValue.toLowerCase() : nextValue;

      if (currentLowered > nextLowered) {
        return sorterObj.dir;
      } else if (currentLowered < nextLowered) {
        return -sorterObj.dir;
      }
      return 0;
    });
  if (!fieldMapped || !fieldMapped.length) {
    return [];
  }
  return fieldMapped.reduce((accum, value) => {
    return accum || value;
  }, 0);
};

export const setTableSortOrder = (sortColumn, filterSettings) => {
  if (filterSettings.sortColumn !== sortColumn) {
    filterSettings.sortColumn = sortColumn;
    filterSettings.sortType = SORT_TYPE.ASC;
  } else if (filterSettings.sortType === SORT_TYPE.ASC) {
    filterSettings.sortType = SORT_TYPE.DESC;
  } else {
    filterSettings.sortType = SORT_TYPE.ASC;
  }

  return filterSettings;
};

export const sortDate = (list, key, sortOrder) => {
  return list.slice().sort((a, b) => {
    if (sortOrder === SORT_TYPE.ASC) {
      return new Date(a[key]) - new Date(b[key]);
    }

    return new Date(b[key]) - new Date(a[key]);
  });
};
