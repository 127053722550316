export const CATEGORY_ID = {
  CLIENT_DOCUMENTS: 1,
  BUSINESS_OPERATIONS: 2,
  YOUR_LIBRARY: 3,
};

export const DOCUMENT_FLAG = {
  NONE: 0,
  NEW: 1,
  UPDATED: 2,
};

export const ACCESSIBILITY = [
  {
    id: 1,
    name: 'Only me',
  },
  {
    id: 2,
    name: 'My team',
  },
];

export const IMPORTANT_DOCUMENT_TAB = [
  {
    index: 0,
    id: 'clientDocuments',
    categoryId: CATEGORY_ID.CLIENT_DOCUMENTS,
    heading: 'Client Documents',
    urlValue: 'client-documents',
  },
  {
    index: 1,
    categoryId: CATEGORY_ID.BUSINESS_OPERATIONS,
    id: 'businessOperations',
    heading: 'Business Operations',
    urlValue: 'business-operations',
  },
  {
    index: 2,
    categoryId: CATEGORY_ID.YOUR_LIBRARY,
    id: 'yourLibrary',
    heading: 'My Documents',
    urlValue: 'my-documents',
  },
];

export const DEFAULT_BRANDING_CATEGORY = {
  BrandingCategoryId: 0,
  BrandingCategory: 'All Branding Category',
  ticked: true,
};

export const SEARCH_URL_VALUE = 'search-documents';
export const DEFAULT_TAB = IMPORTANT_DOCUMENT_TAB[0].index;
export const UPLOAD_API_URL = '/corporate/ImportantDocumentUpload';
