import './style.scss';
import template from './entityDetails.html';
import EntityDetailsCtrl from './entityDetailsCtrl';

export default {
  template,
  controller: EntityDetailsCtrl,
  controllerAs: 'vm',
  bindings: {
    partyType: '<',
    entityData: '=',
    formReference: '<',
    showBetterEntity: '<',
  },
};
