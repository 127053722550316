import template from './onboardingIndividualDetails.html';
import controller from './onboardingIndividualDetailsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    activeTab: '<',
  },
};
