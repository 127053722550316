/**
 * Password-check directive.
 */
import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('compareTo', function compareTo() {
    return {
      require: 'ngModel',
      scope: {
        otherModelValue: '=compareTo',
      },
      link(scope, element, attributes, ngModel) {
        ngModel.$validators.compareTo = function (modelValue) {
          return modelValue === scope.otherModelValue;
        };

        scope.$watch('otherModelValue', () => {
          ngModel.$validate();
        });
      },
    };
  });
