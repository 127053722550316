import template from './tasksTableDisplay.html';
import controller from './tasksTableDisplayCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    loanId: '<',
    loanApplicationId: '<',
    onlyShowColumns: '<',
    isDealTask: '<',
    onOpenTaskModal: '&',
    onOpenTemplatedTaskModal: '&',
    parentToChildNotificationRegistration: '&',
  },
};
