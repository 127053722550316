export function getOptionalQueryString(params) {
  let optionalQuery = '';
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      optionalQuery += `&${key}=${params[key]}`;
    }
  });

  return optionalQuery;
}

export function buildQuery(data) {
  if (!data) {
    return {};
  }
  const mappedKeys = Object.keys(data).map((key) => `${key}=${data[key]}`);
  return `?${mappedKeys.join('&')}`;
}

export const queryStringifyId = (paramName, dataArray) => {
  if (!paramName || !dataArray) {
    return;
  }
  let queryString = '';

  dataArray.forEach((element, key) => {
    queryString += `${!key ? '' : '&'}${paramName}=${element}`;
  });
  return queryString;
};
