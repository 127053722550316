import template from './customerCareAction.html';
import controller from './customerCareActionCtrl';
import './styles.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    filterData: '<',
    showMarkAsIncompleteBtn: '<?',
    onActionSelected: '&',
    onMarkCompleted: '&',
  },
};
