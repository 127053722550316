class AddNoteLauncherCtrl {
  constructor($state, configService, noteService) {
    'ngInject';

    this.$state = $state;
    this.configService = configService;
    this.noteService = noteService;
  }

  $onInit() {
    this.isClient =
      this.$state.includes('app.contactsSingle') ||
      this.$state.includes('app.BusinessAccount');
    this.displayNoteLauncher = this.configService.feature.addNotesAnywhereInContact;
  }

  newNote() {
    this.noteService.addNewNote(this);
  }
}

export default AddNoteLauncherCtrl;
