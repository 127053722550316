import moment from 'moment';
import { COMPANY_TYPES } from 'Common/constants/partyTypes';
import { getDatePickerConfig } from 'Common/utilities/date';
import { getMonths, getYears } from 'Common/utilities/calendar';
import { NZBN_PROPERTY } from 'Common/constants/employment';
import { entityDetailsInstance } from './util/entityDetails';

const TRUST_TYPES = [
  {
    label: 'Individual',
    value: 1,
  },
  {
    label: 'Company',
    value: 2,
  },
];

class EntityDetailsCtrl {
  constructor(contactService, currentUserService, configService) {
    'ngInject';

    this.contactService = contactService;
    this.currentUserService = currentUserService;
    const { feature } = configService;
    this.featureService = feature;
    this.NZBN_PROPERTY = NZBN_PROPERTY;
    this.entityDetailsUtility = entityDetailsInstance(this);
  }

  $onInit() {
    this.partyType = parseInt(this.partyType, 10);
    this.dateConfig = getDatePickerConfig();
    this.COMPANY_TYPES = COMPANY_TYPES;
    this.datePopUp = {
      tradingStarDate: false,
      gSTRegistrationDate: false,
    };
    this.trustTypes = TRUST_TYPES;
    this.getCompanyTypes();
    this.months = getMonths();
    this.years = getYears();
  }

  getMonthAndYear(date) {
    if (!date) {
      return;
    }
    const dateConverted = moment(date, 'YYYY/MM/DD');
    const month = dateConverted.format('M');
    const year = dateConverted.format('YYYY');
    this.experienceDate = {
      month: parseInt(month, 10),
      year: parseInt(year, 10),
    };
    this.createExperienceDate();
  }

  createExperienceDate() {
    const { month, year } = this.experienceDate;
    if (!month || !year) {
      return;
    }
    const monthString = month >= 10 ? month : `0${month}`;
    this.entityData.IndustryExperienceStartDate = moment(
      `${year}-${monthString}-01T00:00:00.000Z`,
    );
    this.getStartDate(this.entityData.IndustryExperienceStartDate);
  }

  getCompanyTypes() {
    this.contactService.getCompanyTypes().then((response) => {
      const { data } = response;
      this.companyTypes = data;
      this.getMonthAndYear(this.entityData.IndustryExperienceStartDate);
    });
  }

  companyTypeChange() {
    this.entityData.CompanyTypeId = this.entityData.chosenCompany.Id;
  }

  onOpenDate(dateField) {
    this.datePopUp[dateField] = !this.datePopUp[dateField];
  }

  updateIsGSTRegistered(isGSTRegistered) {
    if (typeof isGSTRegistered === 'undefined') {
      return;
    }
    this.entityData.GSTRegistrationDate = isGSTRegistered
      ? this.entityData.GSTRegistrationDate
      : '';
  }

  getStartDate(date) {
    if (!date) {
      this.startDateTotalYears = null;
      return;
    }
    const years = moment().diff(date, 'years', false);
    if (years > 1) {
      this.startDateTotalYears = ` - ${years} years`;
      return;
    }
    this.startDateTotalYears = ` - ${years} year`;
  }
}

export default EntityDetailsCtrl;
