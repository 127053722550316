export const KEY_CODE = {
  MAC_CTRL: 91,
  WIN_ALT: 18,
  WIN_ENT: 13,
  ESC: 27,
  TAB: 9,
  SPACE: 32,
  ARROW_KEY_DOWN: 40,
  ARROW_KEY_UP: 38,
  ARROW_KEY_LEFT: 37,
  ARROW_KEY_RIGHT: 39,
};
