import dotProp from 'dot-prop-immutable';
import {
  assetSupplierBuilderForMyCRM,
  assetSupplierBuilderForUI,
  cqpDynamicFeeBuilderForMyCRM,
  cqpDynamicFeeBuilderForUI,
  documentZipBuilderForUI,
  taxInvoiceBuilderForUI,
  taxInvoicePDFBuilderForUI,
  taxInvoiceEmailBuilderForUI,
  tradeReferenceBuilderForUI,
  referenceRatesBuilderForUI,
  productReferenceRatesBuilderForUI,
  loansForPipelineLinkingBuilderForUI,
  brokerEventIdBuilderForUI,
  brokerNotesBuilderForUI,
  otherFundsReqBuilderForUI,
  rationaleQuestionBuilderForUI,
  rationaleQuestionBuilderForMyCRM,
  loanScenarioSimplifiedListBuilderForUI,
} from 'Common/mappers/loanScenario';

export default class LoanScenarioModelService {
  constructor($filter, httpClient) {
    'ngInject';

    this.$filter = $filter;
    this.httpClient = httpClient;
    this.apiBaseUrl = 'LoanScenario';
  }

  getBrokerEventId(loanScenarioId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${loanScenarioId}/broker-event`)
      .then(({ data }) => brokerEventIdBuilderForUI(data));
  }

  cpaDocument(param) {
    return this.httpClient.get(`${this.apiBaseUrl}/cpa-document`, param);
  }

  share(params, userInitiated = true) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/Share`,
      {
        ...params,
        userInitiated,
      },
      {},
      false,
    );
  }

  postAssetSuplier(loanApplicationId, assetSupplier) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`,
      assetSupplierBuilderForMyCRM(assetSupplier),
    );
  }

  getAssetSupplier(loanApplicationId, assetSupplierId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`, {
        assetSupplierId,
      })
      .then((response) => assetSupplierBuilderForUI(response.data));
  }

  updateAssetSuplier(loanApplicationId, assetSupplier) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`,
      assetSupplierBuilderForMyCRM(assetSupplier),
    );
  }

  deleteAssetSupplier(loanApplicationId, assetSupplierId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`,
      { assetSupplierId },
    );
  }

  getApplicantEmployment(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ApplicantEmployment`, {
      loanApplicationId,
    });
  }

  deleteCommunication(params) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/WorkbenchRequirementLog`,
      params,
    );
  }

  addCqpDynamicFee(loanApplicationId, params) {
    return this.httpClient
      .post(
        `${this.apiBaseUrl}/${loanApplicationId}/cqp-dynamic-fee`,
        cqpDynamicFeeBuilderForMyCRM(params),
      )
      .then(
        ({ data }) => data && data.map((res) => cqpDynamicFeeBuilderForUI(res)),
      );
  }

  getCqpDynamicFee(loanApplicationId, isUncheckIncluded) {
    return this.httpClient
      .get(
        `${
          this.apiBaseUrl
        }/${loanApplicationId}/cqp-dynamic-fee?isUncheckIncluded=${!!isUncheckIncluded}`,
      )
      .then(
        ({ data }) => data && data.map((res) => cqpDynamicFeeBuilderForUI(res)),
      );
  }

  deleteCqpDynamicFee(loanApplicationId, feeId, isActiveItem) {
    return this.httpClient
      .delete(
        `${
          this.apiBaseUrl
        }/${loanApplicationId}/cqp-dynamic-fee/${feeId}?isActiveItem=${!!isActiveItem}`,
      )
      .then(
        ({ data }) => data && data.map((res) => cqpDynamicFeeBuilderForUI(res)),
      );
  }

  updateCqpDynamicFee(loanApplicationId, feeId, params) {
    return this.httpClient
      .put(
        `${this.apiBaseUrl}/${loanApplicationId}/cqp-dynamic-fee/${feeId}`,
        cqpDynamicFeeBuilderForMyCRM(params),
      )
      .then(
        ({ data }) => data && data.map((res) => cqpDynamicFeeBuilderForUI(res)),
      );
  }

  getRationaleQuestions(param) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/lender-product-selection`, param)
      .then(
        ({ data }) =>
          (data && data.map((res) => rationaleQuestionBuilderForUI(res))) || [],
      );
  }

  postRationaleQuestions(payload) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/lender-product-selection`,
      rationaleQuestionBuilderForMyCRM(payload),
    );
  }

  postDocumentPageSortingSet(params, familyId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/DocumentPageSortingSet`,
      params,
      { familyId },
    );
  }

  toggleCqpDynamicFee(loanApplicationId, feeId, isChecked) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/${loanApplicationId}/cqp-dynamic-fee/${feeId}/tick`,
      isChecked,
    );
  }

  postDocumentSorting(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/DocumentSortingSet`,
      params,
    );
  }

  getDocumentsZip(loanScenarioId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/DocumentsZip`, { loanScenarioId })
      .then(({ data }) => data && documentZipBuilderForUI(data));
  }

  updateImportantDate(params) {
    return this.httpClient.put(`${this.apiBaseUrl}/ImportantDate`, {}, params);
  }

  setManualCqpState(loanApplicationId, CQPManuallyUpdated) {
    return this.httpClient.post(`${this.apiBaseUrl}/cqp-manual`, {
      loanApplicationId,
      CQPManuallyUpdated,
    });
  }

  getManualCqpState(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/cqp-manual`, {
      loanApplicationId,
    });
  }

  getTaxInvoiceDetails(loanAppId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${loanAppId}/tax-invoice-details`)
      .then(({ data }) => data && taxInvoiceBuilderForUI(data));
  }

  getTaxInvoiceDetailsPdf(loanAppId, recipientId, params) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/${loanAppId}/tax-invoice-details-pdf/${recipientId}`,
        params,
      )
      .then(({ data }) => data && taxInvoicePDFBuilderForUI(data));
  }

  setEmailTaxInvoice(params) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/email-tax-invoice`, params)
      .then(
        ({ data }) =>
          data && data.map((res) => taxInvoiceEmailBuilderForUI(res)),
      );
  }

  getTradeReference(loanApplicationId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${loanApplicationId}/trade-reference`)
      .then(
        ({ data }) =>
          data && data.map((res) => tradeReferenceBuilderForUI(res)),
      );
  }

  setTradeReference(loanApplicationId, params) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/${loanApplicationId}/trade-reference`, params)
      .then(({ data }) => data);
  }

  updateTradeReference(loanApplicationId, params) {
    return this.httpClient
      .put(`${this.apiBaseUrl}/${loanApplicationId}/trade-reference`, params)
      .then(({ data }) => data);
  }

  deleteTradeReference(loanApplicationId, tradeReferenceId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/${loanApplicationId}/trade-reference`,
      { tradeReferenceId },
    );
  }

  getReferenceRates(productId, param) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${productId}/reference-rates`, param)
      .then(({ data }) => (data && referenceRatesBuilderForUI(data)) || {});
  }

  getProductReferenceRate(productId, param) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${productId}/reference-rate`, param)
      .then(
        ({ data }) => (data && productReferenceRatesBuilderForUI(data)) || {},
      );
  }

  setReferenceRate(productId, params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${productId}/reference-rate`,
      params,
    );
  }

  assetFinanceEmailLoanApp(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/asset-finance/email-loanapp`,
      params,
    );
  }

  getCompletionStatus(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/CompletionStatus`, {
      loanApplicationId,
    });
  }

  getLoansForPipelineLinking(familyID) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/loans-for-pipeline-linking`, { familyID })
      .then(
        ({ data }) => data && data.map(loansForPipelineLinkingBuilderForUI),
      );
  }

  getBrokerNotes(loanApplicationId) {
    const isUseCache = true;
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/BrokerNotesGet`,
        { loanApplicationId },
        isUseCache,
      )
      .then(
        (response) =>
          dotProp.get(response, 'data', []) &&
          [...response.data].map(brokerNotesBuilderForUI),
      );
  }

  getOtherFundingRequirement(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/other-funding-requirement`, params)
      .then(
        ({ data }) =>
          (data && data.map((item) => otherFundsReqBuilderForUI(item))) || [],
      );
  }

  setOtherFundingRequirement(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/other-funding-requirement`,
      params,
    );
  }

  setProbability(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/loan-probability`, params);
  }

  setSubStatus(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/loan-substatus`, params);
  }

  getApplicants(param) {
    return this.httpClient.get(`${this.apiBaseUrl}/Applicants`, param);
  }

  getSimplifiedList(familyId) {
    const mapper = loanScenarioSimplifiedListBuilderForUI(this.$filter);
    return this.httpClient
      .get(`${this.apiBaseUrl}/simplified-list`, {
        familyId,
      })
      .then(({ data }) => data && data.map(mapper));
  }

  getSimplifiedListAllStatus(familyId) {
    const mapper = loanScenarioSimplifiedListBuilderForUI(this.$filter);
    return this.httpClient
      .get(`${this.apiBaseUrl}/simplified-list-all-status`, {
        familyId,
      })
      .then(({ data }) => data && data.map(mapper));
  }
}
