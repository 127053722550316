import angular from 'angular';
import existingInsurance from './existingInsurance';
import benefitListTable from './benefitListTable';
import insuranceOnlineFactFind from './insuranceOnlineFactFind';
import insuranceFiles from './insuranceFiles';
import insuranceFile from './insuranceFile';
import insurancePipelineStatuses from './insurancePipelineStatuses';

export default angular
  .module('clientInsurance.scenes.insuranceList.scenes', [])
  .component('benefitListTable', benefitListTable)
  .component('existingInsurance', existingInsurance)
  .component('insuranceOnlineFactFind', insuranceOnlineFactFind)
  .component('insuranceFiles', insuranceFiles)
  .component('insurancePipelineStatuses', insurancePipelineStatuses)
  .component('insuranceFile', insuranceFile).name;
