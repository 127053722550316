import template from './taxInvoiceModal.html';
import controller from './taxInvoiceModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanDetails: '<',
  },
};
