/* eslint-disable sonarjs/no-duplicate-string, sonarjs/cognitive-complexity */
import { defaultTermFormat } from 'Common/utilities/loanFundingDetails';
import {
  LIST_OF_QUESTIONS,
  REPAYMENT_PREFERENCE_SUBQUESTIONS,
  FEATURE_PREFERENCE_SUBQUESTIONS,
} from 'Common/constants/customerObjectives';

import { formatHtmlBreakToNewline } from 'Common/utilities/string';
import { last } from 'lodash';

const NOT_SPECIFIED = 'Not Specified';

export const stringAnswer = (
  answerStringArray = [NOT_SPECIFIED],
  isMultiple,
) => {
  return formatHtmlBreakToNewline(
    (isMultiple ? answerStringArray.join(', ') : last(answerStringArray)) ||
      NOT_SPECIFIED,
  );
};

export const answersFromDropdown = (
  answers = [],
  mainQuestionId,
  objectivesQuestionService,
) => {
  const options = objectivesQuestionService.getOptionByQuestion(mainQuestionId);
  return options
    .filter(
      (option) =>
        Number(option.value) > 0 && answers.includes(Number(option.value)),
    )
    .map((answer) => answer.name)
    .join(', ');
};

export const boolAnswer = (answerBool = [false]) => {
  return last(answerBool) ? '“Yes”' : '“No”';
};

export const renderHeader = (title) => {
  return `<div class="level-4-heading padding-left-0 padding-bottom-5">
    ${title}
  </div>`;
};

export const renderList = (list = [], isUnordered) => {
  if (!list.length) {
    return '';
  }
  const tag = isUnordered ? 'ul' : 'ol';
  return `<${tag} class="padding-left-15">
    ${list
      .map((li) => {
        const title = typeof li === 'string' ? li : li.title;
        return `<li>${title}</li>`;
      })
      .join('')}
  </${tag}>`;
};

export const productDataSummary = (product, initialRate, ongoingRate) => {
  return `<currency-formatting
      amount="${product.TotalLoanAmount}"
      precision="0"
    ></currency-formatting>, 
    ${defaultTermFormat(product)}, 
    ${product.InterestOnly ? 'Interest Only' : 'Principal & Interest'}
    ${
      product.LMIPremium
        ? `, LMI <currency-formatting
      amount="${product.LMIPremium}"
      precision="2"
    ></currency-formatting>`
        : ''
    }
    ${
      initialRate && ongoingRate
        ? `, Initial rate: ${initialRate}%, Ongoing rate: ${ongoingRate}%`
        : ''
    }
  `;
};

export const selectedProductDiscountRate = (rateData) => {
  const productOff = rateData.productName
    ? ` off the ${rateData.productName}`
    : '';
  const discountDetails = rateData.isDetailShow
    ? ` (Base rate of ${rateData.baseRate}% with discount of ${rateData.discountRate}%${productOff})`
    : '';
  return `${rateData.label}: ${rateData.actualRate}%${discountDetails}`;
};

export const renderProductRepayment = (
  repaymentId,
  objectivesQuestionService,
) => {
  const objectiveQuestionAnswers =
    objectivesQuestionService.objectivesDataAsObjects;
  const questionTree =
    REPAYMENT_PREFERENCE_SUBQUESTIONS[repaymentId.toString()];
  const repaymentDetails = [];
  repaymentDetails.push(
    answersFromDropdown(
      [repaymentId],
      LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE,
      objectivesQuestionService,
    ),
  );

  if (questionTree.FREQUENCY) {
    const frequencyId =
      last(objectiveQuestionAnswers[questionTree.FREQUENCY]?.answerString) ||
      null;
    const frequency = frequencyId
      ? `, ${answersFromDropdown(
          [Number(frequencyId)],
          questionTree.FREQUENCY,
          objectivesQuestionService,
        ).toLowerCase()}`
      : '';
    repaymentDetails.push(frequency);
  }

  if (questionTree.PERIOD) {
    const periodId =
      last(objectiveQuestionAnswers[questionTree.PERIOD]?.answerString) || null;
    const period = periodId
      ? `, period: ${answersFromDropdown(
          [Number(periodId)],
          questionTree.PERIOD,
          objectivesQuestionService,
        ).toLowerCase()}`
      : '';
    repaymentDetails.push(period);
  }

  if (questionTree.OPTIONS) {
    const optionsId =
      last(objectiveQuestionAnswers[questionTree.OPTIONS]?.answerString) ||
      null;
    const optionAnswer = optionsId
      ? answersFromDropdown(
          [Number(optionsId)],
          questionTree.OPTIONS,
          objectivesQuestionService,
        )
      : '';
    const option = optionAnswer
      ? `, ${answersFromDropdown(
          [Number(optionsId)],
          questionTree.OPTIONS,
          objectivesQuestionService,
        ).toLowerCase()}`
      : '';
    repaymentDetails.push(option);
  }

  if (questionTree.IMPORTANT) {
    const important = answersFromDropdown(
      objectiveQuestionAnswers[questionTree.IMPORTANT]?.answerInt || [],
      questionTree.IMPORTANT,
      objectivesQuestionService,
    );
    important && repaymentDetails.push(` - ${important}`);
  }

  if (questionTree.IMPORTANT_OTHER) {
    const importantOther =
      last(
        objectiveQuestionAnswers[questionTree.IMPORTANT_OTHER]?.answerString,
      ) || null;
    importantOther && repaymentDetails.push(`, “${importantOther}”`);
  }

  return `Repayment: ${repaymentDetails.join('')}`;
};

export const renderProductFeatures = (featureId, objectivesQuestionService) => {
  const objectiveQuestionAnswers =
    objectivesQuestionService.objectivesDataAsObjects;
  const questionTree = FEATURE_PREFERENCE_SUBQUESTIONS[featureId.toString()];
  const repaymentDetails = [];
  repaymentDetails.push(
    answersFromDropdown(
      [featureId],
      LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE,
      objectivesQuestionService,
    ),
  );

  if (questionTree.IMPORTANT) {
    const important = answersFromDropdown(
      objectiveQuestionAnswers[questionTree.IMPORTANT]?.answerInt || [],
      questionTree.IMPORTANT,
      objectivesQuestionService,
    );
    important && repaymentDetails.push(` - ${important}`);
  }

  if (questionTree.IMPORTANT_OTHER) {
    const importantOther =
      last(
        objectiveQuestionAnswers[questionTree.IMPORTANT_OTHER]?.answerString,
      ) || null;
    importantOther && repaymentDetails.push(`, “${importantOther}”`);
  }

  return `Features: ${repaymentDetails.join('')}`;
};

export const filterObjectiveLenderNames = (ids, loanAppSharedData) => {
  if (last(ids) === -2) {
    return 'No Preferred Lenders';
  }
  return loanAppSharedData.bankingLenderList
    ?.filter((lender) => ids.includes(Number(lender.ProviderId)))
    .map((lender) => lender.ProviderName)
    .join(', ');
};

export const renderBorrowingCapacity = ({
  header,
  total,
  list = [],
  titleKey = 'firstName',
  valueKey,
  frequency,
}) => {
  if (!valueKey) {
    return '';
  }

  const values = list.map((borrower) => {
    const displayFrequency =
      frequency && borrower[valueKey] > 0 ? `${frequency}` : '';
    return `${borrower[titleKey]}: <currency-formatting
      amount="${borrower[valueKey]}"
      precision="2"
    ></currency-formatting>${displayFrequency}`;
  });

  return `<div class="flex padding-right-15">
    ${renderHeader(`${header}: <currency-formatting
      amount="${total}"
      precision="2"
    ></currency-formatting>`)}
    ${renderList(values, true)}
  </div>`;
};
