import template from './loanCompactDetails.html';
import controller from './loanCompactDetailsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanDetails: '<',
    loanStatusList: '<',
    lendingDetailScope: '<',
    loanDetailScope: '<',
    loadingPropertyKey: '<',
  },
};
