import moment from 'moment';
import {
  FIXED_RATE_EXPIRES_ID,
  SEND_TYPE,
} from 'Common/constants/customerCareModules';
import { D_MMM_FORMAT } from 'Common/constants/dateConfigs';
import { splitString } from 'Common/utilities/string';
import {
  sendFixedRateEmailBuilderForUI,
  sendInsuranceEmailBuilderForUI,
} from 'Common/mappers/customerCare';

export const checkSingleOrBulkEmailUtil = ({
  $scope,
  currentUserService,
  fixedRateObj,
  isInsurance,
  source,
  tab,
  fixedRateListTable,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => () => {
  $scope.selectAllEmailList = [];
  $scope.all = source === SEND_TYPE.ALL;

  $scope.isComplete = !currentUserService.isNZ;
  if (source === SEND_TYPE.SINGLE) {
    if (fixedRateObj === '' && fixedRateObj.email === '') {
      $scope.recipients.email = [
        {
          EmailAddress: '',
          FamilyFullname: '',
        },
      ];
    }
    if (fixedRateObj.email) {
      const expDate = !isInsurance
        ? moment(fixedRateObj.expiryDate).format(D_MMM_FORMAT)
        : moment(fixedRateObj[tab.dateName]).format(D_MMM_FORMAT);
      const notiHeader = !isInsurance
        ? `Fixed Rate Expires (${expDate}) Completed via Email`
        : `${tab.label} (${expDate}) Completed via Email`;

      const emails = fixedRateObj.email
        ? splitString(fixedRateObj.email)
        : [''];

      emails.forEach((email) => {
        const eachContact = {
          ...fixedRateObj,
          email,
        };
        const contact = !isInsurance
          ? sendFixedRateEmailBuilderForUI(eachContact, notiHeader)
          : sendInsuranceEmailBuilderForUI(eachContact, notiHeader);
        $scope.selectAllEmailList.push(contact);
      });

      $scope.recipients.email = $scope.selectAllEmailList;
    }

    $scope.recipients.CustomerCareType = isInsurance
      ? tab.type
      : 'FIXED_RATE_EXPIRY';
  }
  $scope.fixedRateListTable = fixedRateListTable;
  $scope.currentModule = FIXED_RATE_EXPIRES_ID;

  if (source === SEND_TYPE.ALL) {
    $scope.fixedRateListTable.forEach((item) => {
      const emails = item.email ? splitString(item.email) : [''];

      emails.forEach((email) => {
        const eachContact = {
          ...item,
          email,
        };
        const contact = !isInsurance
          ? sendFixedRateEmailBuilderForUI(eachContact, '')
          : sendInsuranceEmailBuilderForUI(eachContact, '');
        $scope.selectAllEmailList.push(contact);
      });
    });
    if ($scope.selectAllEmailList.length) {
      $scope.recipients.email = $scope.selectAllEmailList;
      $scope.noOfPeople = $scope.selectAllEmailList.length;
    }
  }
  return isInsurance;
};
