import angular from 'angular';
import reaSecurities from './reaSecurities';
import fundingRequired from './fundingRequired';
import fundingAvailable from './fundingAvailable';
import assetFinanceSecurities from './assetFinanceSecurities';

export default angular.module('scenes.fundingDetailsSection.scenes', [
  reaSecurities,
  fundingRequired,
  fundingAvailable,
  assetFinanceSecurities,
]).name;
