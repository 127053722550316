import angular from 'angular';

class BenefitTypeDescriptionCtrl {
  constructor($uibModalInstance, benefitType) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.benefitType = benefitType;
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .controller('BenefitTypeDescriptionCtrl', BenefitTypeDescriptionCtrl);
