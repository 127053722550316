import template from './commissionPie.html';
import controller from './commissionPieCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    pieData: '<',
    isLoading: '<',
    optionList: '<',
    selected: '<',
    onSelect: '&',
  },
};
