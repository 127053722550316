const TASKS_INDEX = {
  OVERDUE: 0,
  TODAY: 1,
  TOMORROW: 2,
  THIS_WEEK: 3,
  LATER: 4,
};

/**
 * @property opportunityTitle
 */

export default class TasksListCtrl {
  constructor(
    $uibModal,
    tasksSharedData,
    currentUserService,
    tasksService,
    opportunityLoanAppService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.tasksSharedData = tasksSharedData;
    this.currentUserService = currentUserService;
    this.tasksService = tasksService;
    this.opportunityLoanAppService = opportunityLoanAppService;
    this.toggleTaskDetails = (task) => {
      task.showDetails = !task.showDetails;
    };
  }

  $onInit() {
    this.taskList = [];
    this.showCompleted = false;
    this.isLoadingTask = false;
    this.taskGroup = [
      {
        name: 'Overdue',
        tableClass: 'table-overdue',
      },
      {
        name: 'Today',
        tableClass: 'table-today',
      },
      {
        name: 'Tomorrow',
        tableClass: 'table-tomorrow',
      },
      {
        name: 'This Week',
        tableClass: 'table-this-week',
      },
      {
        name: 'Later',
        tableClass: 'table-later',
      },
    ];

    this.TASKS_INDEX = TASKS_INDEX;

    this.props = {
      familyId: this.familyId,
      loanId: this.loanId,
    };

    const reloadTasks = this.getTaskList.bind(this);
    this.opportunityLoanAppService.setCallback(
      'refreshOpportunityTasks',
      reloadTasks,
    );

    this.getTaskList();
  }

  taskCompletionSet(task) {
    this.tasksService
      .taskSingleCompletion(task.ActivityID, task.FamilyID, task.IsCompleted)
      .then(() => {
        this.getTaskList(this.showCompleted);
      });
  }

  toggleOneComplete(task) {
    if (!task.IsCompleted) {
      this.taskCompletionSet(task);
      return;
    }

    this.tasksSharedData.completionConfirmation(
      () => {
        this.taskCompletionSet(task);
      },
      () => {
        task.IsCompleted = false;
      },
    );
  }

  getTaskList(isShowCompleted = false) {
    this.isLoadingTask = true;
    const adviserFilter = this.currentUserService.familyId;
    const dateFilter = 'all';
    const createdByFilter = '';
    const familyId = 0;
    const getParams = {
      adviserFilter,
      dateFilter,
      familyId,
      createdByFilter,
      isShowCompleted,
      loanId: this.loanId,
      quoteId: this.quoteId,
    };

    this.tasksService.getTaskList(getParams).then((res) => {
      this.isLoadingTask = false;
      if (!res || !res.data) {
        return;
      }

      this.taskList = res.data.TaskList ? res.data.TaskList : [];
      if (!isShowCompleted) {
        this.taskGroup[TASKS_INDEX.OVERDUE].tasks = this.taskList.filter(
          (o) => o.IsOverDue,
        );
        this.taskGroup[TASKS_INDEX.TODAY].tasks = this.taskList.filter(
          (o) => o.IsDueToday,
        );
        this.taskGroup[TASKS_INDEX.TOMORROW].tasks = this.taskList.filter(
          (o) => o.IsDueTomorrow,
        );
        this.taskGroup[TASKS_INDEX.THIS_WEEK].tasks = this.taskList.filter(
          (o) => o.IsDueThisWeek,
        );
        this.taskGroup[TASKS_INDEX.LATER].tasks = this.taskList.filter(
          (o) => o.IsDueLater,
        );
      }
    });
  }

  openTaskTemplatedModal(taskId = 0) {
    const propsCopy = {
      ...this.props,
      taskId,
      familyList: this.familyList,
      quoteId: this.quoteId,
      selectedOpportunityId: this.opportunityId,
      fileName: this.opportunityTitle,
    };
    const modalInstance = this.$uibModal.open({
      template: `<task-templated modal-instance="vm.modalInstance" quote-id="vm.props.quoteId" family-id="vm.props.familyId" family-list="vm.props.familyList" loan-id="vm.props.loanId" task-id="vm.props.taskId" selected-opportunity-id="vm.props.selectedOpportunityId" file-name="vm.props.fileName"></task-templated>`,
      size: 'lg',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      windowClass: 'view-task-window',
      resolve: {
        props: () => propsCopy,
      },
    });
    this.taskResult(modalInstance);
  }

  openTaskFormModal(taskId = 0) {
    const props = {
      familyId: this.familyId,
      familyList: this.familyList,
      loanId: this.loanId,
      taskId,
      fileName: this.opportunityTitle,
      isLinkToOpportunity: false,
      selectedOpportunityId: this.opportunityId,
      opportunityList: [
        { opportunityId: this.opportunityId, title: this.opportunityTitle },
      ],
    };

    this.$uibModal
      .open({
        template: `<task-form-modal
        modal-instance="vm.modalInstance"
        family-id="vm.props.familyId"
        family-list="vm.props.familyList"
        loan-id="vm.props.loanId"
        task-id="vm.props.taskId"
        file-name="vm.props.fileName"
        is-link-to-opportunity="vm.props.isLinkToOpportunity"
        selected-opportunity-id="vm.props.selectedOpportunityId"
        opportunity-list="vm.props.opportunityList"
      ></task-form-modal>`,
        windowClass: 'edit-task-window',
        size: 'md',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props: () => props,
        },
      })
      .result.then((response) => {
        if (response === 'saved') {
          this.showCompleted = false;
          this.getTaskList();
        }
      });
  }

  taskResult(modalInstance) {
    modalInstance.result.then((response) => {
      if (response === 'saved') {
        this.getTaskList();
      }
    });
  }
}
