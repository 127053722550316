/**
 *   needs initials as value
 */
import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular.module('app').directive('crmThumbSelect', function crmThumbSelect() {
    return {
      restrict: 'A',
      scope: {
        crmThumbSelect: '=',
        bgColor: '=',
      },
      link($scope, $element, $attributes) {
        let newColor = '999999';
        $scope.$watch('bgColor', (color) => {
          newColor = color;
        });

        $scope.$watch('crmThumbSelect', (newVal) => {
          $element
            .removeAttr('data-before-content')
            .removeAttr('data-before-background');
          let crmThumbSelectBg = '999999';

          if (newColor !== '999999') {
            crmThumbSelectBg = newColor;
          }

          if (!_.isUndefined(crmThumbSelectBg)) {
            // $element.attr('data-before-content', $attributes.crmThumbSelect);
            $element.attr('data-before-content', newVal);
            $element.attr('data-before-background', crmThumbSelectBg);

            const style = `<style>.crm-thumb-select#${$attributes.id}:before{background:#${crmThumbSelectBg}}</style>`;

            angular.element('head').append(style);
          }
        });
      },
    };
  });
