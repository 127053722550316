import template from './gmailModalDescription.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    iconComponent: '<',
    description: '<',
  },
};
