import template from './crmNotify.html';
import controller from './crmNotifyCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    icon: '<',
    title: '<',
    message: '<',
    timeout: '<',
    modalInstance: '<',
    allowClose: '<',
  },
};
