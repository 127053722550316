import angular from 'angular';
import { link } from './util/ellipsis';

export const loadModule = () =>
  angular
    .module('app')
    .directive('ellipsis', function ellipsis($timeout, $compile) {
      return {
        restrict: 'A',
        scope: {
          content: '<',
        },
        link(scope, elem, attr) {
          link(scope, elem, attr, { $timeout, $compile });
        },
      };
    });
