import get from 'lodash/get';
import {
  PACK_MODE,
  DEFAULT_LM_LOGO_PATH,
  DEFAULT_INSURANCE_LOGO_PATH,
  SERVICE_OPTION,
} from 'Common/constants/helloBook';
import { COLOR } from 'Common/constants/colors';

export default class HelloPackViewerCtrl {
  constructor(
    $document,
    $timeout,
    contactModelService,
    currentUserService,
    generalService,
    configService,
  ) {
    'ngInject';

    this.$document = $document;
    this.$timeout = $timeout;
    this.contactModelService = contactModelService;
    this.currentUserService = currentUserService;
    this.generalService = generalService;
    this.configService = configService;
  }

  $onInit() {
    this.activePackMode = this.activePackMode || PACK_MODE.HELLO_PACK;
    this.isInsuranceProfilerPackMode =
      this.activePackMode === PACK_MODE.INSURANCE_PROFILER;
    this.inviteIOFFPackMode = this.activePackMode === PACK_MODE.INVITE_IOFF;
    this.imageBrandingCategory = 'byob';
    this.isNZLMInsuranceAdviser = false;
    this.onlineFFbtnText = this.inviteIOFFPackMode
      ? 'Complete your profile'
      : 'Get Started';
  }

  $onChanges({ adviserInfo }) {
    const isAdviserInfoValid = !!get(adviserInfo, 'currentValue');
    if (!isAdviserInfoValid) {
      return;
    }
    this.initializeTheme();
  }

  initializeTheme() {
    this.getFamilyAdviserTheme();
    this.getDisplayTitle();
    switch (true) {
      case this.adviserInfo.isBYOB:
        this.imageBrandingCategory = 'byob';
        break;
      case this.adviserInfo.isInsuranceMarket ||
        this.isInsuranceProfilerPackMode ||
        this.inviteIOFFPackMode:
        this.imageBrandingCategory = 'im';
        break;
      default:
        this.imageBrandingCategory = 'lm';
        break;
    }
  }

  setThemeColor() {
    const elementDefault = {
      style: {
        setProperty: () => {},
      },
    };
    const root = get(this, '$document[0].documentElement', elementDefault);
    root.style.setProperty(
      '--hello-pack-primary-color',
      this.adviserTheme.sidebarBgColor,
    );
  }

  isUseDefaultBranding() {
    const isLMBrand =
      [
        PACK_MODE.HELLO_PACK,
        PACK_MODE.INSURANCE_PROFILER,
        PACK_MODE.INVITE_IOFF,
      ].includes(this.activePackMode) &&
      (this.adviserInfo.isLM || this.adviserInfo.isWM);
    return isLMBrand || this.adviserInfo.isInsuranceMarket;
  }

  getBanner() {
    if (this.isInsuranceProfilerPackMode) {
      return 'Your tailored insurance profile';
    } else if (this.inviteIOFFPackMode) {
      return `Hi, ${this.model.content.clientDisplayName} <br>
        We've got some questions for you
      `;
    } else {
      return 'Hello.';
    }
  }

  getFamilyAdviserTheme() {
    return this.contactModelService
      .getFamilyAdviserTheme(this.familyId)
      .then((data) => {
        this.adviserTheme = data;
        this.setThemeColor();
        this.model.adviserTheme = data;
        this.isNZLMInsuranceAdviser =
          this.model.selectedService === SERVICE_OPTION.INSURANCE &&
          this.adviserInfo.isNZ &&
          this.adviserInfo.isLM;

        if (this.adviserInfo.isBYOB && !this.adviserTheme.logoDocId) {
          this.logoPath = '';
          return;
        }

        if (this.isUseDefaultBranding()) {
          if (
            this.adviserInfo.isInsuranceMarket ||
            this.isInsuranceProfilerPackMode ||
            this.isNZLMInsuranceAdviser ||
            this.inviteIOFFPackMode
          ) {
            this.logoPath = DEFAULT_INSURANCE_LOGO_PATH;
            this.adviserTheme.sidebarBgColor = COLOR.SUSHI;
            this.imageBrandingCategory = 'im';
            this.setThemeColor();
          } else {
            this.logoPath = DEFAULT_LM_LOGO_PATH;
          }
          return;
        }

        if (!this.adviserTheme.logoDocId) {
          return;
        }

        return this.generalService
          .documentGet(this.adviserTheme.logoDocId)
          .then(({ data: doc }) => {
            const isValidDoc = doc && doc.ContentType && doc.DocumentContent;
            if (!isValidDoc) {
              this.logoPath = '';
              return;
            }
            this.logoPath = `data:${doc.ContentType};base64,${doc.DocumentContent}`;
          });
      });
  }

  renderAdviserPhotoContent() {
    const { fullBodyPhotoUrl } = this.adviserInfo || {};
    return (
      (fullBodyPhotoUrl &&
        `<div class="photo-content">
        <img src="${fullBodyPhotoUrl}" alt="Photo">
      </div>`) ||
      ''
    );
  }

  getDisplayTitle() {
    const { marketingDisplayTitle, profileDisplayTitle } =
      this.adviserInfo || {};
    const defaultDisplayTitle = this.currentUserService.isAU
      ? 'Mortgage Broker'
      : 'Financial Adviser';
    this.adviserInfo.displayTitle = this.configService.feature.usersProfileBio
      ? profileDisplayTitle || defaultDisplayTitle
      : marketingDisplayTitle || defaultDisplayTitle;
  }
}
