class LoanLenderSubmissionService {
  constructor(httpClient, downloadDataService) {
    'ngInject';

    this.httpClient = httpClient;
    this.downloadDataService = downloadDataService;
    this.baseUrl = 'loan-lender-submission';
  }

  setEmailContent(data) {
    return this.httpClient.post(`${this.baseUrl}/email`, data);
  }
}

export default LoanLenderSubmissionService;
