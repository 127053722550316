import getValue from 'lodash/get';
import {
  getOtherFundsReq,
  setOtherFundsReq,
  showEditOtherFund,
  setDisplayOtherFundsReq,
  showDeleteFundConfirmation,
} from 'Assets/js/controllers/loanApp/util/clientAccess/security';

export default class AssetFinanceSecuritiesCtrl {
  constructor(
    $rootScope,
    loanAppSharedData,
    fundingDetailsService,
    loanApplicationServices,
    loanScenarioModelService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.loanAppSharedData = loanAppSharedData;
    this.fundingDetailsService = fundingDetailsService;
    this.loanApplicationServices = loanApplicationServices;
    this.loanScenarioModelService = loanScenarioModelService;
  }

  $onInit() {
    this.loanSecurityDetails = {};
    this.securityList = [];
    this.otherFundsReqList = [];
    this.otherFundsReqObj = {};

    this.bindUtilityMethods();
    this.populateSecurityList().then(() => this.populateOtherFundList());
  }

  setLoanDetailValues() {
    this.lendingCategoryId = getValue(
      this.loanAppSharedData,
      'loanAppSummary.LoanDetails.lendingCategoryId',
    );
  }

  bindUtilityMethods() {
    this.getFundingCalculatorFundsSummary = () =>
      this.loanAppSharedData.getFundingCalculatorFundsSummary({
        securityBrokerEventId: this.loanAppSharedData.securityBrokerEventId,
        loanAppId: this.loanAppId,
      });
    this.setOtherFundsReq = setOtherFundsReq({
      $scope: this,
      loanScenarioModelService: this.loanScenarioModelService,
    });
    this.showEditOtherFund = showEditOtherFund;
    this.setDisplayOtherFundsReq = setDisplayOtherFundsReq({ $scope: this });
    this.showDeleteFundConfirmation = showDeleteFundConfirmation({
      $scope: this,
    });
    this.getOtherFundsReq = getOtherFundsReq({
      $scope: this,
      loanAppSharedData: this.loanAppSharedData,
      loanScenarioModelService: this.loanScenarioModelService,
    });
  }

  populateSecurityList() {
    this.setAssetLoadingStatus(true);
    return this.loanApplicationServices
      .getLoanSecurity(this.loanAppId)
      .then(({ data }) => {
        this.loanSecurityDetails = data;
        this.securityList = data.SecurityList;
        this.$rootScope.loanAppSecurityListCount = this.securityList.length;
      })
      .then(() => this.setAssetLoadingStatus(false))
      .catch(() => this.setAssetLoadingStatus(false));
  }

  setAssetLoadingStatus(status) {
    this.isAssetLoading = status;
  }

  populateOtherFundList() {
    this.getOtherFundsReq();
  }

  onSuccessCallback() {
    this.populateSecurityList();
    this.loanAppSharedData.validateFundingDetails();
  }

  openAssetFinanceModal(loanSecurityInfoId = 0) {
    const props = {
      loanSecurityInfoId,
      familyId: this.familyId,
      loanAppId: this.loanAppId,
      lendingCategoryId: this.lendingCategoryId,
    };
    const onAddSuccessCallback = () => this.onSuccessCallback();
    this.fundingDetailsService.openAssetFinanceModal(
      props,
      onAddSuccessCallback,
    );
  }

  deleteSecurity(item) {
    this.fundingDetailsService.deleteSecurity({
      item,
      loanAppId: this.loanAppId,
      onDeleteSuccessCallback: () => this.onSuccessCallback(),
    });
  }
}
