import { get as getDataFromObject } from 'lodash';
import { LIABILITY_LABEL } from 'Common/constants/liabilityType';

export function getLiabilityInfo(data) {
  if (!data && !data.liabilityInfo) {
    return null;
  }
  return getDataFromObject(data.liabilityInfo, 'data.FinancialInfo', []);
}

export function getCreditCardList(data) {
  return data.filter(
    (liability) => liability.Type === LIABILITY_LABEL.CREDIT_CARD,
  );
}

export function getOtherLiabilityList(data) {
  return data.filter(
    (liability) => liability.Type !== LIABILITY_LABEL.CREDIT_CARD,
  );
}

export function validateSameRepaymentFrequency(data) {
  const repaymentList = data.reduce((accum, item) => {
    if (!accum.includes(item.RepaymentFrequencyId)) {
      accum.push(item.RepaymentFrequencyId);
    }
    return accum;
  }, []);
  return repaymentList.length <= 1;
}

export function validateSameFrequencyID(data) {
  const repaymentList = data.reduce((accum, item) => {
    if (!accum.includes(item.FrequencyID)) {
      accum.push(item.FrequencyID);
    }
    return accum;
  }, []);
  return repaymentList.length <= 1;
}

export function mapCreditCard(data, model) {
  const creditCardList = getCreditCardList(data);
  const validSameFreq = validateSameRepaymentFrequency(creditCardList);
  const showError = !validSameFreq;

  if (showError) {
    return { ...model, showError };
  }
  // Credit Card Limit
  const limit = creditCardList.reduce(
    (accum, liability) => accum + liability.Limit,
    0,
  );

  // Repayments
  const repayments = creditCardList.reduce(
    (accum, liability) => accum + liability.Repayments,
    0,
  );

  // Frequency Id
  const repaymentFrequencyId = getDataFromObject(
    creditCardList,
    '0.RepaymentFrequencyId',
    0,
  ).toString();
  return {
    ...model,
    limit,
    repayments,
    repaymentFrequencyId,
    showError,
  };
}

export function mapOtherLiability(data, model) {
  const otherLiabilityList = getOtherLiabilityList(data);
  const showError = !validateSameRepaymentFrequency(otherLiabilityList);
  if (showError) {
    return { ...model, showError };
  }

  // Balance
  const balance = otherLiabilityList.reduce(
    (accum, liability) => accum + liability.Amount,
    0,
  );

  // Repayments
  const repayments = otherLiabilityList.reduce(
    (accum, liability) => accum + liability.Repayments,
    0,
  );

  // Frequency Id
  const repaymentFrequencyId = getDataFromObject(
    otherLiabilityList,
    '0.RepaymentFrequencyId',
    0,
  ).toString();
  return {
    ...model,
    balance,
    repayments,
    repaymentFrequencyId,
    showError,
  };
}

const singleClientIncomeMap = (income, model) => {
  const totalIncomeValue = getDataFromObject(
    income,
    'data.TotalAmountPerMonth',
    [],
  );
  return {
    data: model.map((incomeItem) => {
      incomeItem.income.income = totalIncomeValue;
      return incomeItem;
    }),
  };
};

const multipleClientIncomeMap = (income, model) => {
  const incomeList = getDataFromObject(income, 'data.FinancialInfo', []);

  const mappedByClient = (incomeItem) => {
    const filterIncomeByClient = incomeList.filter((income) => {
      const clientIdList = income.Borrowers.map(
        (borrower) => borrower.BorrowerID,
      );
      return clientIdList.includes(incomeItem.clientId);
    });

    const showError = !validateSameFrequencyID(filterIncomeByClient);
    if (showError) {
      return { ...incomeItem, showError };
    }

    incomeItem.income.income = filterIncomeByClient.reduce(
      (accum, item) => accum + item.Value,
      0,
    );

    incomeItem.income.incomeFrequencyId = getDataFromObject(
      filterIncomeByClient,
      '0.FrequencyID',
      0,
    ).toString();
    return incomeItem;
  };
  return {
    data: model.map(mappedByClient),
  };
};

export function mapIncome(income, model) {
  const hasOneClient = model.length <= 1;
  if (hasOneClient) {
    return singleClientIncomeMap(income, model);
  } else {
    return multipleClientIncomeMap(income, model);
  }
}
