import { AMOUNT_ABBREVIATION } from 'Common/constants/amountAbbreviation';
import { parseToInt10 } from 'Common/utilities/parse';

export function shortenAmount(amount, decimalPlaces = 0) {
  if (!amount && amount !== 0) {
    return '';
  }
  let shortenedAmount = parseInt(amount, 10);
  const afterDotDigits = 10 ** decimalPlaces;
  const amountAbbrev = [
    AMOUNT_ABBREVIATION.THOUSAND,
    AMOUNT_ABBREVIATION.MILLION,
    AMOUNT_ABBREVIATION.BILLION,
    AMOUNT_ABBREVIATION.TRILLION,
  ];
  let i = amountAbbrev.length;
  amountAbbrev.reverse().forEach((abbrev) => {
    const amountAbbrevRange = 10 ** (i * 3);
    const isAmountWithinRange = shortenedAmount >= amountAbbrevRange;
    if (isAmountWithinRange) {
      shortenedAmount =
        Math.round((shortenedAmount * afterDotDigits) / amountAbbrevRange) /
        afterDotDigits;

      // Handle special case where we round up to the next abbreviation
      if (shortenedAmount === 1000 && i < amountAbbrev.length) {
        shortenedAmount = 1;
        i++;
      }

      // Add the letter for the abbreviation
      shortenedAmount = `${shortenedAmount}${abbrev}`;
      return true;
    }
    i--;
  });
  return shortenedAmount;
}

export function getMoneyForm(amount) {
  const defaultAmount = 0;
  if (!amount) {
    return defaultAmount;
  }
  return parseFloat(parseFloat(amount).toFixed(2));
}

export const formatNumberWithComma = (number) => {
  if (!number) {
    return 0;
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeDecimals = (amountStr) => {
  if (!amountStr) {
    return '$0';
  }
  const removedSpecialCharacters = amountStr.toString().replace(/[$,]/g, '');
  const parsed = parseToInt10(Math.round(removedSpecialCharacters));
  return `$${formatNumberWithComma(parsed)}`;
};

export const formatToCurrency = (value) => {
  if (!value) {
    return value;
  }

  const strValue = value + '';
  const checkForDots = strValue.match(/\./g) || [];

  const isDotLastPositionOrDotZero =
    checkForDots.length === 1 &&
    (strValue.indexOf('.') === strValue.length - 1 ||
      strValue.indexOf('.0') === strValue.length - 2);

  if (isDotLastPositionOrDotZero) {
    return value;
  }

  let dottedValue;
  if (checkForDots.length > 1) {
    const splitValueByDot = strValue.split('.');
    dottedValue = `${splitValueByDot[0]}.${splitValueByDot[1]}`;
  }

  const actualValue = dottedValue || value;

  const trim =
    (actualValue.replace &&
      // eslint-disable-next-line no-useless-escape
      actualValue.replace(/,/g, '').replace(/[^\d+.|\-]/g, '')) ||
    value;
  if (isNaN(trim) || isNaN(parseFloat(trim))) {
    return value;
  }

  const currencyFormattedByLocale =
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(trim) || '';

  return currencyFormattedByLocale
    .replace('$', '')
    .replace('US', '')
    .replace('.00', '');
};

export const getCursorPositionIndex = (
  formattedValue,
  rawValue,
  currentCursorPosition = 0,
) => {
  if (!formattedValue || !rawValue) {
    return currentCursorPosition;
  }

  const commaOccurenceOnNewFormat = (String(formattedValue).match(/,/g) || [])
    .length;
  const commaOccurenceOnOldFormat = (String(rawValue).match(/,/g) || []).length;

  const isCommaRemoved = commaOccurenceOnOldFormat > commaOccurenceOnNewFormat;
  const isCommaAdded = commaOccurenceOnNewFormat > commaOccurenceOnOldFormat;
  const isCurrentCursorFirst = parseToInt10(currentCursorPosition) === 0;
  const isCurrentCursorLast =
    parseToInt10(currentCursorPosition) === formattedValue.length;

  if (isCommaRemoved && isCurrentCursorLast) {
    return currentCursorPosition - 1;
  } else if (isCurrentCursorFirst || isCurrentCursorLast) {
    return currentCursorPosition;
  } else if (isCommaRemoved) {
    return currentCursorPosition - 1;
  } else if (isCommaAdded) {
    return currentCursorPosition + 1;
  } else {
    return currentCursorPosition;
  }
};

export const setCursorPosition = ({
  elem,
  formatted,
  rawValue,
  startPosition,
}) => {
  const cursorPosition = getCursorPositionIndex(
    formatted,
    rawValue,
    startPosition,
  );
  elem.selectionStart = cursorPosition;
  elem.selectionEnd = cursorPosition;
};

const _hasDecimal = (num) => num && parseFloat(num) % 1 !== 0;
const _sanitizeValue = (formatted) => formatted && formatted.replace(/,/g, '');
const _unformat = (formatted, sanitize) =>
  formatted &&
  ((_hasDecimal(sanitize) && parseFloat(sanitize).toFixed(2)) ||
    parseToInt10(sanitize));

export const directiveParseToCurrency = ({ rawValue, elem, ctrl }) => {
  if (!elem || !ctrl) {
    return;
  }

  const firstElem = elem[0];
  const startPosition = firstElem.selectionStart;

  const formatted = formatToCurrency(rawValue);
  const sanitize = _sanitizeValue(formatted);
  const unformatted = _unformat(formatted, sanitize);

  if (rawValue === formatted) {
    return unformatted;
  }

  ctrl.$setViewValue(formatted);

  elem.val(formatted);
  setCursorPosition({ elem: firstElem, formatted, rawValue, startPosition });
  return unformatted;
};

export const directiveFormatToCurrency = ({ rawValue, elem }) => {
  if (!elem) {
    return;
  }

  const firstElem = elem[0];
  const startPosition = firstElem.selectionStart;
  const formatted = formatToCurrency(rawValue);

  elem.val(formatted);

  // set cursor position
  firstElem.selectionStart = startPosition;
  firstElem.selectionEnd = startPosition;

  return formatted;
};
