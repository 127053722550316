import { flatMap } from 'lodash';
import { paymentFrequenciesForUI } from 'Common/utilities/insurance';

class InsurerComparisonModalCtrl {
  constructor(contactInsuranceService, insuranceQuoteService, toaster) {
    'ngInject';

    this.contactInsuranceService = contactInsuranceService;
    this.insuranceQuoteService = insuranceQuoteService;
    this.toaster = toaster;

    this.comparisonView = 'premium';
  }

  $onInit() {
    this.insurerIds = this.getInsurerIds();
    this.initializePaymentFrequencies();
    this.initializeBenefits();
  }

  getInsurerIds() {
    return (this.insurers || []).map((insurer) => insurer.ProviderId).join(',');
  }

  initializePaymentFrequencies() {
    this.insuranceQuoteService.insuranceQuoteFrequencyGet().then((response) => {
      this.paymentFrequencies =
        response && paymentFrequenciesForUI(response.data);
    });
  }

  initializeBenefits() {
    this.contactInsuranceService.insuranceBenefitsGet().then((response) => {
      if (!response.data) {
        return;
      }
      this.quoteBenefitTypes = this.filterQuoteBenefitTypes(response.data);
      this.benefitType = this.quoteBenefitTypes[0].BenefitID;
      this.displayBrochures();
    });
  }

  filterQuoteBenefitTypes(benefitTypes) {
    const peopleEntities = flatMap(
      this.insurers,
      (insurer) => insurer.PeopleEntity,
    );
    if (!peopleEntities.length) {
      return;
    }

    const peopleBenefits = flatMap(
      peopleEntities,
      (person) => person.BenefitList,
    ).map((benefit) => benefit.BenefitId);
    if (!peopleBenefits.length) {
      return;
    }

    return benefitTypes.filter((type) => {
      const indexInPeopleBenefits = peopleBenefits.indexOf(type.BenefitID);
      return indexInPeopleBenefits !== -1;
    });
  }

  close() {
    this.modalInstance.dismiss();
  }

  onInsurerPremiumComparisonInit(api) {
    this.insurerPremiumComparisonApi = api;
  }

  onInsurerBrochuresInit(api) {
    this.insurerBrochuresApi = api;
    if (this.quoteBenefitTypes && this.quoteBenefitTypes.length) {
      this.benefitType = this.quoteBenefitTypes[0].BenefitID;
      this.displayBrochures();
    }
  }

  onQuoteDataUpdated(quoteData) {
    this.quoteData = quoteData;
  }

  refreshPremiumComparison() {
    this.insurerPremiumComparisonApi.refreshPremiumComparison();
  }

  displayBrochures() {
    this.insurerBrochuresApi.displayBrochures(this.benefitType);
  }
}

export default InsurerComparisonModalCtrl;
