import {
  lmiProductMapper,
  isToggledLmiProductMatch,
} from 'Common/utilities/lmiCalculator';
import OpenLMISelectedLenderModalCtrl from './OpenLMISelectedLenderModalCtrl';
import { WARNING_LIMIT } from 'Common/constants/lmi';

class lmiTableCtrl {
  constructor(fundingCalculatorService, $uibModal) {
    'ngInject';

    this.status = {
      empty: 'empty',
      waiting: 'waiting',
      done: 'done',
      ascending: 1,
      descending: 2,
    };
    this.sortColumn = {
      Lender: 'Lender',
      LVRValue: 'LVRValue',
      LoanAmountExLMI: 'LoanAmountExLMI',
      LMIPremium: 'LMIPremium',
    };
    this.sortBy = {
      Lender: 1,
      LVR: 0,
      LoanAmount: 0,
      LMIPremium: 0,
      Value: false,
    };
    this.columnToSort = '';
    this.uibModal = $uibModal;
    this.fundingCalculatorService = fundingCalculatorService;
  }

  $onInit() {
    this.warningLimit = WARNING_LIMIT;
  }

  setLenderIsFavorite(item) {
    this.setQuickLMIFavorite(
      this.mortgageObj.BrokerEventID,
      item.LenderId,
      !item.IsFavorite,
      item.LMIProductId,
    );

    if (item.IsFavorite) {
      this.selectedLenderObj = this.selectedLenderObj.filter(
        (itemObj) =>
          itemObj.LenderId !== item.LenderId &&
          itemObj.LMIProductId === item.LMIProductId,
      );
      if (!this.mortgageObj.IsQuickCalc) {
        this.resetSelectedLender();
      }
    } else {
      const lender = lmiProductMapper(item);
      if (this.mortgageObj.IsQuickCalc) {
        this.selectedLenderObj.push(lender);
      } else {
        this.selectedLenderObj = [lender];
      }
    }
    this.selectedLenderShow = !!this.selectedLenderObj.length;
    this.isSaveToFundingCalcDisabled = !this.selectedLenderShow;
    this.lmiPremiumObj.data.map((itemGet) => {
      if (isToggledLmiProductMatch(itemGet, item)) {
        itemGet.IsFavorite = !item.IsFavorite;
      } else if (!this.mortgageObj.IsQuickCalc) {
        itemGet.IsFavorite = false;
      }
      return itemGet;
    });
  }

  setAllSelectedLender() {
    this.selectedLenderObj = [];
    this.lmiPremiumObj.data.forEach((itemGet) => {
      if (itemGet.IsFavorite) {
        const lender = lmiProductMapper(itemGet);
        this.selectedLenderObj.push(lender);
      }
    });
  }

  removeLenderIsFavorite(item) {
    if (item) {
      this.setQuickLMIFavorite(
        this.mortgageObj.BrokerEventID,
        item.LenderId,
        false,
        item.LMIProductId,
      );
      this.selectedLenderObj = this.selectedLenderObj.filter(
        (itemObj) => itemObj.LenderId !== item.LenderId,
      );
      this.selectedLenderShow = !!this.selectedLenderObj.length;
      this.isSaveToFundingCalcDisabled = !this.selectedLenderShow;

      this.lmiPremiumObj.data.map((itemGet) => {
        if (isToggledLmiProductMatch(itemGet, item)) {
          itemGet.IsFavorite = false;
        }
        return itemGet;
      });
      this.resetSelectedLender();
    }
  }

  setQuickLMIFavorite(BrokerEventID, LenderId, IsFavorite, LMIProductId) {
    const isAllowToSetFavoriteLender =
      this.mortgageObj.IsQuickCalc || this.selectedLenderShow;
    if (isAllowToSetFavoriteLender) {
      this.fundingCalculatorService.setFundingCalculatorFundsQuickLMIFavorite(
        BrokerEventID,
        LenderId,
        IsFavorite,
        LMIProductId,
      );
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  sortTableBy(column) {
    this.columnToSort = column;
    if (column === this.sortColumn.LVRValue) {
      if (this.sortBy.LVR === this.status.ascending) {
        this.sortBy.LVR = this.status.descending;
      } else {
        this.sortBy.LVR = this.status.ascending;
        this.sortBy.Value = true;
      }
      this.sortBy.Value = !this.sortBy.Value;
      this.sortBy.Lender = 0;
      this.sortBy.LoanAmount = 0;
      this.sortBy.LMIPremium = 0;
    } else if (column === this.sortColumn.LoanAmountExLMI) {
      if (this.sortBy.LoanAmount === this.status.ascending) {
        this.sortBy.LoanAmount = this.status.descending;
      } else {
        this.sortBy.LoanAmount = this.status.ascending;
        this.sortBy.Value = true;
      }
      this.sortBy.Value = !this.sortBy.Value;
      this.sortBy.Lender = 0;
      this.sortBy.LVR = 0;
      this.sortBy.LMIPremium = 0;
    } else if (column === this.sortColumn.LMIPremium) {
      if (this.sortBy.LMIPremium === this.status.ascending) {
        this.sortBy.LMIPremium = this.status.descending;
      } else {
        this.sortBy.LMIPremium = this.status.ascending;
        this.sortBy.Value = true;
      }
      this.sortBy.Value = !this.sortBy.Value;
      this.sortBy.Lender = 0;
      this.sortBy.LVR = 0;
      this.sortBy.LoanAmount = 0;
    } else {
      if (this.sortBy.Lender === this.status.ascending) {
        this.sortBy.Lender = this.status.descending;
      } else {
        this.sortBy.Lender = this.status.ascending;
        this.sortBy.Value = true;
      }
      this.sortBy.Value = !this.sortBy.Value;
      this.sortBy.LVR = 0;
      this.sortBy.LoanAmount = 0;
      this.sortBy.LMIPremium = 0;
    }
  }

  displayTableHeader(bool) {
    return (
      this.lmiPremiumObj.status === this.status.done &&
      this.selectedLenderShow === bool
    );
  }

  openSelectedLenderModal() {
    const modalInstance = this.uibModal.open({
      templateUrl:
        '/assets/views/loanCalculator/partials/openLMISelectedLenderModal.html',
      controller: OpenLMISelectedLenderModalCtrl,
      controllerAs: 'vm',
      size: 'sm',
      resolve: {
        selectedLenderObj: () => this.selectedLenderObj,
        lmiPremiumObj: () => this.lmiPremiumObj,
        removeLenderIsFavorite: () => this.removeLenderIsFavorite,
      },
    });

    modalInstance.result.then(
      () => {
        this.setAllSelectedLender();
      },
      () => {
        this.setAllSelectedLender();
      },
    );
  }

  $onChanges(changes) {
    if (changes && changes.goalType.currentValue) {
      this.sortTableBy('LoanAmountExLMI');
      this.sortBy.LoanAmount = this.status.descending;
      this.sortBy.Value = true;
    } else {
      this.sortTableBy('LMIPremium');
      this.sortBy.LMIPremium = this.status.ascending;
      this.sortBy.Value = false;
    }
  }
}

export default lmiTableCtrl;
