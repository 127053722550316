import template from './mergeFieldsSelection.html';
import MergeFieldsSelectionCtrl from './mergeFieldsSelectionCtrl';

export default {
  template,
  controller: MergeFieldsSelectionCtrl,
  controllerAs: 'vm',
  bindings: {
    class: '@',
    type: '@',
    descriptionText: '@',
    textareaEditField: '@',
    onMergeFieldSelected: '&',
    hideHeader: '<',
    appendMergeFields: '<?',
  },
};
