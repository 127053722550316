import angular from 'angular';
import { toastError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('TaskSidebarCtrl', function TaskSidebarCtrl(
      $scope,
      $http,
      $timeout,
      $uibModal,
      $stateParams,
      loanToolsService,
      dashboardService,
      $filter,
      $element,
      $window,
      toaster,
      SweetAlert,
      configService,
      contactService,
      tasksService,
      $rootScope,
    ) {
      $scope.tasksTableDropDowns = {};
      $scope.alltasksTableData = [];
      $scope.brokerId = 0;
      $scope.AssignedTo = '';
      $scope.dueDate = 'today';

      const init = () => {
        $scope.getSignedInBroker();
        $scope.taskName = '';
      };

      $scope.taskResult = (modalInstance) => {
        modalInstance.result.then((isSaved) => {
          if (!isSaved) {
            return;
          }
          init();
        });
      };

      $scope.familyIdObject = (taskFamilyId) => {
        return { familyId: taskFamilyId };
      };
      $scope.openCreateTaskModal = function (taskId) {
        const props = {
          taskId,
          taskName: $scope.taskName,
          familyId: 0,
          isFromTaskList: true,
        };
        const modalInstance = $uibModal.open({
          template: `<task-form-modal 
          modal-instance="vm.modalInstance"
          has-filter="vm.props.hasFilter"
          task-name="vm.props.taskName"
          family-id="vm.props.familyId"
          task-id="vm.props.taskId"
          is-from-task-list="vm.props.isFromTaskList">
        </task-form-modal>`,
          windowClass: 'edit-task-window',
          size: 'md',
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: {
            props: () => props,
          },
        });
        $scope.taskResult(modalInstance);
      };
      $scope.openCreateTaskTemplateModal = function (taskId = 0) {
        const props = {
          taskId,
          familyId: 0,
          isFromTaskList: true,
        };

        const modalInstance = $uibModal.open({
          template: `<task-templated modal-instance="vm.modalInstance" family-id="vm.props.familyId" task-id="vm.props.taskId" is-from-task-list="vm.props.isFromTaskList">`,
          size: 'lg',
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          windowClass: 'view-task-window',
          resolve: {
            props: () => props,
          },
        });
        $scope.taskResult(modalInstance);
      };
      const getTasks = (brokerID) => {
        const getParams = {
          adviserFilter: brokerID,
          dateFilter: $scope.dueDate,
          familyId: 0,
          isShowCompleted: false,
        };

        tasksService.getTaskList(getParams).then((response) => {
          if (!response || !response.data) {
            return;
          }

          $scope.tasksTableData = [];
          $scope.alltasksTableData = response.data.TaskList;
          $scope.tasksTableData = [];
          for (let i = 0; i < $scope.alltasksTableData.length; i++) {
            if ($scope.alltasksTableData[i].IsCompleted === false) {
              $scope.tasksTableData.push($scope.alltasksTableData[i]);
            }
          }
          $scope.allTasks = false;
          if ($scope.tasksTableData.length < 10) {
            $scope.lessThanExpected = true;
          } else {
            $scope.lessThanExpected = false;
          }
        });
      };
      $scope.toggleOneComplete = function (task) {
        tasksService
          .taskCompletionSet(task.FamilyID, task.ActivityID, task.IsCompleted)
          .then(() => {
            getTasks(task.AdviserId);
            toaster.pop(
              'success',
              'Completed',
              'Task has been updated successfully.',
            );
          })
          .catch(toastError);
      };
      $scope.changeDueDate = function (dueDate) {
        $scope.dueDate = dueDate;
        init();
      };

      $scope.getSignedInBroker = function () {
        dashboardService.getBrokerBasicInfo().then((response) => {
          if (!response.data) {
            return;
          }
          const { BrokerId: brokerId } = response.data;
          $scope.AssignedTo = { BrokerID: parseInt(brokerId, 10) };
          $scope.brokerId = $scope.AssignedTo.BrokerID;
          getTasks($scope.brokerId);
        });
      };
      $rootScope.$on('reloadTaskData', (event, args) => {
        if (args.reloadTask === true) {
          getTasks($scope.brokerId);
        }
      });
      $scope.minnumber = 0;
      $scope.maxnumber = 10;
      $scope.toggleTasks = function () {
        let length = $scope.alltasksTableData.length;
        if ($scope.alltasksTableData.length > $scope.maxnumber) {
          $scope.minnumber += 10;
          $scope.maxnumber += 10;
          length -= 10;
        }
        if (length < 10) {
          $scope.lessThanExpected = true;
        }
        $scope.allTasks = true;
      };
      $scope.openModal = function (size, task) {
        $uibModal.open({
          templateUrl:
            '/assets/views/overviewDashboard/partials/update_task_details.html',
          resolve: {
            tasksTableData() {
              return $scope.tasksTableData;
            },
            allTasksTableData() {
              return $scope.alltasksTableData;
            },
            task() {
              return task;
            },
            getTasks(param) {
              return getTasks(param);
            },
          },
          controller: 'OpenModalCtrl',
          size,
        });
      };
      init();
    });
