import template from './contactIdentificationTable.html';
import ContactIdentificationTableCtrl from './contactIdentificationTableCtrl';
import './style.scss';

export default {
  template,
  controller: ContactIdentificationTableCtrl,
  controllerAs: 'vm',
  bindings: {
    identificationTitle: '@?',
    identificationList: '<',
    editHandler: '<?',
    deleteHandler: '<?',
  },
};
