import { createAddressColumnContent } from './util/contactAddressTable';

class ContactAddressTableCtrl {
  $onInit() {
    this.gridTemplate = {
      'grid-template-columns': 'minmax(70px, 80px) 2fr 1fr 1fr 30px 30px',
      'grid-column-gap': '10px',
    };
    this.columnHeader = [
      {
        key: 'client',
        title: 'Client',
      },
      {
        key: 'address',
        title: 'Address',
      },
      {
        key: 'arrangement',
        title: 'Arrangement',
      },
      {
        key: 'timeframe',
        title: 'Timeframe',
      },
      {
        key: 'edit',
        title: '',
      },
      {
        key: 'delete',
        title: '',
      },
    ];
    this.columnContent = [];
    this.processAddress(this.addressList);
  }

  $onChanges(changeObject) {
    const { addressList } = changeObject;
    const { previousValue, currentValue } = addressList;

    if (addressList.isFirstChange()) {
      return;
    }
    if (previousValue !== currentValue) {
      this.processAddress(currentValue);
    }
  }

  processAddress(addresses) {
    this.columnContent = createAddressColumnContent(addresses);
  }
}

export default ContactAddressTableCtrl;
