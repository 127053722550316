import angular from 'angular';
import crmTableView from './crmTableView';
import crmTableViewOpportunity from './crmTableViewOpportunity';
import crmTablePagination from './crmTablePagination';
import tableViewApplications from './tableViewApplications';
import tableViewLeads from './tableViewLeads';
import tableTotalAmount from './tableTotalAmount';
import tablePagination from './tablePagination';

export default angular
  .module('components.crmTableView', [])
  .component('crmTableView', crmTableView)
  .component('crmTableViewOpportunity', crmTableViewOpportunity)
  .component('crmTablePagination', crmTablePagination)
  .component('tableViewApplications', tableViewApplications)
  .component('tableViewLeads', tableViewLeads)
  .component('tableTotalAmount', tableTotalAmount)
  .component('tablePagination', tablePagination).name;
