/**
 * Make element 100% height of browser window.
 */
import angular from 'angular';
import $ from 'jquery';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('ctFullheight', function ctFullheight(
      $window,
      $rootScope,
      $timeout,
    ) {
      return {
        scope: {
          ctFullheightIf: '&',
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        link(scope, elem, attrs) {
          const $win = $($window);
          const $document = $($window.document);
          let exclusionHeight;
          let setHeight = true;
          let page;

          scope.initializeWindowSize = function () {
            $timeout(() => {
              exclusionHeight = 0;
              if (attrs.ctFullheightIf) {
                scope.$watch(scope.ctFullheightIf, (newVal, oldVal) => {
                  if (newVal && !oldVal) {
                    setHeight = true;
                  } else if (!newVal) {
                    $(elem).css('height', 'auto');
                    setHeight = false;
                  }
                });
              }

              if (attrs.ctFullheightExclusion) {
                const exclusionItems = attrs.ctFullheightExclusion.split(',');
                angular.forEach(exclusionItems, (_element) => {
                  exclusionHeight += $(_element).outerHeight(true);
                });
              }
              if (attrs.ctFullheight === 'window') {
                page = $win;
              } else {
                page = $document;
              }

              scope.$watch(() => {
                scope.__height = page.height();
              });
              if (setHeight) {
                $(elem).css('height', 'auto');
                if (page.innerHeight() < $win.innerHeight()) {
                  page = $win;
                }
                $(elem).css('height', page.innerHeight() - exclusionHeight);
              }
            }, 300);
          };

          scope.initializeWindowSize();
          scope.$watch('__height', () => {
            scope.initializeWindowSize();
          });
          $win.on('resize', () => {
            scope.initializeWindowSize();
          });
        },
      };
    });
