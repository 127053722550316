import template from './assetFundDetails.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    assetTypes: '<',
    selectedAssetType: '<',
    assetVariants: '<',
    selectedAssetVariant: '<',
    redBook: '<',
    vehicleDetails: '<',
    onOpenVehicleModal: '&',
    onDeleteVehicle: '&',
    vehicle: '<',
    onChangeBinding: '&',
    isValidDetails: '<',
  },
};
