import { isValidEmailAddress } from 'Common/utilities/string';

class CannyEnhancementsNzCtrl {
  constructor(currentUserService) {
    'ngInject';

    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.isEmailValid = isValidEmailAddress(this.currentUserService.email);
  }
}

export default CannyEnhancementsNzCtrl;
