import getValue from 'lodash/get';
import {
  SECTION_ID,
  LOANAPP_SECTIONS,
} from 'Common/constants/loanappSectionIds';
import { ASSET_PERSONAL_CATEGORY } from 'Common/constants/securityValues';
import { BLUESTONE_SECTION_ERROR_PROPS } from 'Common/constants/blueStoneStatusCodes';
import { setCompletionStatus } from 'Assets/js/controllers/loanApp/util/loanAppCtrl';
export default class FundingDetailsSectionCtrl {
  constructor(
    $timeout,
    $rootScope,
    configService,
    loanAppSharedData,
    loanScenarioService,
    lodgementSubmissionService,
    blueStoneSubmissionService,
    uiService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.configService = configService;
    this.loanAppSharedData = loanAppSharedData;
    this.loanScenarioService = loanScenarioService;
    this.blueStoneSubmissionService = blueStoneSubmissionService;
    this.lodgementSubmissionService = lodgementSubmissionService;
    this.uiService = uiService;
  }

  $onChanges(changes) {
    if (changes.isLoanAppLoaded && changes.isLoanAppLoaded.currentValue) {
      this.setIsAssetFinance();
      this.validateFundingDetails();
    }
  }

  $onInit() {
    this.isCompleted = false;
    this.isAssetFinance = false;
    this.isSectionActive = false;
    this.loanAppSharedData.validateFundingDetails = () => {
      this.validateFundingDetails.bind(this) && this.validateFundingDetails();
    };
    this.setupBluestoneHandlers();
  }

  setupBluestoneHandlers() {
    this.lodgementSubmissionService.toggleBySections(
      BLUESTONE_SECTION_ERROR_PROPS.FUNDING_DETAILS,
    );

    this.blueStoneSubmissionService.toggleBySections(
      BLUESTONE_SECTION_ERROR_PROPS.FUNDING_DETAILS,
    );
    this.blueStoneSubmissionService.extentFunction.fundingDetailsSection = this;
    this.lodgementSubmissionService.extentFunction.fundingDetailsSection = this;
  }

  $onDestroy() {
    this.loanAppSharedData && this.resetLoanAppSharedData();
  }

  setIsAssetFinance() {
    this.lendingCategoryId = getValue(
      this.loanAppSharedData,
      'loanAppSummary.LoanDetails.LendingCategoryId',
    );

    this.isAssetFinance =
      this.configService.feature.assetFinance &&
      ASSET_PERSONAL_CATEGORY.includes(this.lendingCategoryId);
  }

  processFundingDetails() {
    this.isSectionActive = true;
    this.getFundingCalculatorBrokerEventId();
    this.blueStoneSubmissionService.toggleBySections(
      'fundingDetailsErrorProps',
    );
  }

  getFundingCalculatorBrokerEventId() {
    if (!this.loanAppSharedData.securityBrokerEventId) {
      this.loanScenarioService
        .getFundingCalculatorNewBrokerEventForScenario(this.loanAppId)
        .then((data) => {
          this.loanAppSharedData.securityBrokerEventId = data;
        });
    }
  }

  setIsStarted() {
    const checkValues = [
      'totalSecurityValue',
      'totalFundingRequired',
      'totalFundingAvailable',
    ];
    this.isStarted = checkValues.some(
      (propertyName) =>
        !!getValue(this.loanAppSharedData, `fundingSummary.${propertyName}`, 0),
    );
  }

  validateFundingDetails() {
    this.totalSecurityValue = getValue(
      this.loanAppSharedData,
      'fundingSummary.totalSecurityValue',
      0,
    );

    this.isCompleted = !!this.totalSecurityValue;
    this.setIsStarted();

    this.countValids &&
      this.countValids({
        valid: this.isCompleted,
        section: LOANAPP_SECTIONS.FUNDING_DETAILS,
      });

    setCompletionStatus({
      $timeout: this.$timeout,
      sectionId: SECTION_ID.FUNDING_DETAILS,
      loanAppSharedData: this.loanAppSharedData,
      isValidSection: this.isCompleted,
    });
  }

  resetLoanAppSharedData() {
    this.loanAppSharedData.securityBrokerEventId = null;
    this.loanAppSharedData.loanDetailsSet = null;
    this.loanAppSharedData.isLoanDetailsSet = null;
    this.loanAppSharedData.isLmiFundUpdated = null;
    this.loanAppSharedData.lmiFundValue = null;
    this.loanAppSharedData.fundingSummary = null;
    this.loanAppSharedData.isFundingDetailsLoaded = null;
  }
}
