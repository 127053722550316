import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import { catchError } from 'Common/utilities/promise';
import { commonProps } from 'Common/default/modal';
import {
  activeFactFindCrmConfirmation,
  inactiveFactFindSweetAlert,
} from 'Common/utilities/contactsDeleteFactFindWarnings';
import {
  crmOptionSettings,
  swalOptionSettings,
} from 'Common/constants/loanDeleteWarningSettings';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { toastError } from 'Common/utilities/alert';
import { parseToInt10 } from 'Common/utilities/parse';
import { typelessEqual } from 'Common/utilities/math';
import { getFileStatusList } from 'Common/utilities/loanFileHelper';
import { moveLoanOpportunityItem } from 'Common/utilities/kanbanHelper';

export const getImportantDates = ({ $scope }) => (loanApplicationServices) => {
  $scope.importantDateFieldList = [];
  loanApplicationServices.getLoanImportantDates().then((data) => {
    if (!data || !data.length) {
      return;
    }
    $scope.importantDateFieldList = data.reduce((accum, value) => {
      if (!value || !value.length) {
        return accum;
      }
      return [...accum, ...value];
    }, []);
  });
};

export const getImportantDateFields = ({ $scope }) => (status) => {
  $scope.importantDateField = [];
  if (!status || !status.LoanStatusID) {
    return;
  }
  const statusId = parseInt(status.LoanStatusID, 10);
  $scope.importantDateField = $scope.importantDateFieldList.filter(
    (field) =>
      field.requiredStatuses && field.requiredStatuses.includes(statusId),
  );
};
export const settledAndEstimatedEmpty = (loan) =>
  !loan?.SettlementDate && !loan?.EstimatedSettlementDate;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const loanDetail = ({ $scope }) => (uiService) => {
  $scope.isSettled = (loan) => {
    const isSettledAndEstimatedFalsy = settledAndEstimatedEmpty(loan);
    const isSettled = !loan || loan.SettlementDate;

    if (isSettledAndEstimatedFalsy) {
      return false;
    }

    return isSettled || !loan.EstimatedSettlementDate;
  };

  $scope.getSettlementTitle = (loan) => {
    const isSettledAndEstimatedFalsy = settledAndEstimatedEmpty(loan);
    if (!loan || loan.SettlementDate) {
      return 'Settled';
    }
    if (
      (loan.EstimatedSettlementDate && uiService.viewOfAfileTurnedOn) ||
      isSettledAndEstimatedFalsy
    ) {
      return 'Est. Settlement';
    }
    if (loan.EstimatedSettlementDate) {
      return 'Estimated Settlement';
    }
    return 'Settled';
  };

  $scope.displayDeleteButton = (loan) => {
    return loan && (loan.SettlementDate || loan.EstimatedSettlementDate);
  };

  $scope.getImportantDateType = (loan) => {
    return $scope.isSettled(loan)
      ? IMPORTANT_DATE_TYPES.DELETE.ACTUAL_SETTLEMENT
      : IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT;
  };
};

export const updateLoanStatusCallback = ({
  $scope,
  loanScenarioModelService,
  status,
  inprogressLoan,
}) => (result) => {
  if (!result.isOk) {
    return;
  }
  if (result.notProceedingDate) {
    const dateParams = {
      loanApplicationId: 0,
      loanId: inprogressLoan.LoanID,
      dateTypeId: IMPORTANT_DATE_TYPES.DELETE.NOT_PROCEEDED,
      date: result.notProceedingDate,
    };
    loanScenarioModelService.updateImportantDate(dateParams);
  }
  $scope.callLoanStatusSet(
    status,
    inprogressLoan,
    result.shouldSendNurtureCampaigns,
    result.selectedReasonId,
  );
};

export const openLoanViewer = ({ $scope, $state }) => (
  loanDetails,
  isLoanApp = false,
) => {
  const isValidLoan = $scope.familyId && loanDetails && loanDetails.LoanID;
  if (!isValidLoan) {
    return;
  }
  const familyId = $scope.familyId;
  if (isLoanApp && loanDetails.LoanScenarioID) {
    $state.go('app.loanApplicationDetailsV3', {
      familyId,
      loanAppId: loanDetails.LoanScenarioID,
    });
    return;
  }
  $scope.loanInformationModal(
    'edit',
    familyId,
    loanDetails.LoanID,
    loanDetails.LoanScenarioID,
    loanDetails,
  );
};

export const lendingSwalSetOptions = ({
  lendingSwalOptionSettings,
  properties,
}) => {
  return Object.assign({}, { ...lendingSwalOptionSettings, ...properties });
};

export const deleteLoanModal = ({
  isLockable,
  isLocked,
  loanDeleteAction,
  loanId,
  crmConfirmation,
  swal,
  openTab,
}) => {
  const isFactFindActive = isLockable;
  const isFactFindLocked = !isLocked;

  const deleteAction = () => {
    loanDeleteAction(loanId);
  };

  let existingLoanOpenTab = openTab;

  if (existingLoanOpenTab) {
    existingLoanOpenTab = !existingLoanOpenTab;
  }

  if (isFactFindActive && isFactFindLocked) {
    return activeFactFindCrmConfirmation({
      crmConfirmation,
      deleteAction,
      crmOptionSettings,
    });
  }
  const lendingOptions = lendingSwalSetOptions({
    lendingSwalOptionSettings: swalOptionSettings,
    properties: { text: 'This record will be removed from your loan list' },
  });
  inactiveFactFindSweetAlert({
    swal,
    deleteAction,
    swalOptionSettings: lendingOptions,
  });
};

export const redirectDuplicatedLoanToDealView = ({
  loanScenarioService,
  $scope,
  loansSectionLoadingStatus,
}) => {
  const loanAppId = $scope.newlyClonedLoanApp.loanAppId;
  return loanScenarioService
    .loanDetailsGet(loanAppId)
    .then(({ data }) => {
      const params = {
        loanAppId,
        loanId: data.LoanID,
        familyId: $scope.familyId,
      };
      $scope.getLoanAppLink(params);
    })
    .catch((error) => {
      $scope[loansSectionLoadingStatus] = false;
      catchError(error, 'Lending Tab : Loan duplicate failed');
      toastError('Duplicating application failed');
    });
};

export const getPipelineStatusBasedOnLoanStatus = (fileStatusList) => (
  loanStatusId,
) => {
  return fileStatusList.find(
    (status) =>
      parseToInt10(status.LoanStatusId) === parseToInt10(loanStatusId),
  );
};

export const getPipelineStatusByOrgPipelineStatus = (
  fileStatusList,
  orgPipelineStatusId,
) => {
  return (
    orgPipelineStatusId &&
    fileStatusList.find(
      (status) =>
        parseToInt10(status.OrgPipelineStatusID) ===
        parseToInt10(orgPipelineStatusId),
    )
  );
};

export const getFileStatusUpdatePayload = (fileStatusList) => (loanDetails) => {
  const previousLoanStatus =
    getPipelineStatusByOrgPipelineStatus(
      fileStatusList,
      loanDetails.PreviousLoanStatus.OrgPipelineStatusID,
    ) ||
    getPipelineStatusBasedOnLoanStatus(fileStatusList)(
      loanDetails.PreviousLoanStatus.LoanStatusID,
    );
  const currentLoanStatus =
    getPipelineStatusByOrgPipelineStatus(
      fileStatusList,
      loanDetails.LoanStatus.OrgPipelineStatusID,
    ) ||
    getPipelineStatusBasedOnLoanStatus(fileStatusList)(
      loanDetails.LoanStatus.LoanStatusID,
    );
  const isConversion = currentLoanStatus.IsConversionStatus;
  return {
    isConversion,
    loanId: loanDetails.LoanID + '',
    loanScenarioId: loanDetails.LoanScenarioID + '',
    toPipelineStatusId: currentLoanStatus.PipelineStatusId,
    fromPipelineStatusId: previousLoanStatus.PipelineStatusId,
    conversionStatusId:
      (isConversion && currentLoanStatus.ConversionStatusId) || null,
    toOrgPipelineStatusId: currentLoanStatus.OrgPipelineStatusID,
    fromOrgPipelineStatusId: previousLoanStatus.OrgPipelineStatusID,
  };
};

export const updateFileLoanStatus = (
  fileStatusList,
  loanOpportunityService,
) => (loanDetails) => {
  const params = getFileStatusUpdatePayload(fileStatusList)(loanDetails);

  return moveLoanOpportunityItem({
    loanOpportunityService,
    card: params,
    isConversion: params.isConversion,
  }).catch(catchError);
};

export const updateLoanStatusCallToService = ({
  uiService,
  contactService,
  loanFileService,
  loanOpportunityService,
}) => ({
  status,
  loanDetails,
  shouldSendNurtureCampaigns,
  reasonSubstatusID,
}) => {
  if (uiService.viewOfAfileTurnedOn) {
    return getFileStatusList(loanFileService)(loanDetails.LoanID)
      .then((fileStatusList) => {
        return fileStatusList;
      })
      .then((fileStatusList) => {
        return updateFileLoanStatus(
          fileStatusList,
          loanOpportunityService,
        )(loanDetails);
      });
  }

  const skipUpdateDate = !!typelessEqual(
    status.LoanStatusID,
    LOAN_STATUS.EXISTING.SETTLED,
  );
  return contactService.LoanStatusSet(
    loanDetails.LoanID,
    status.LoanStatusID,
    shouldSendNurtureCampaigns,
    reasonSubstatusID,
    skipUpdateDate,
    status.OrgPipelineStatusID,
  );
};

export const getBorrowersToolTipValue = (loanDetails, value) => {
  if (!loanDetails || !loanDetails.Borrower) {
    return '';
  }
  const borrowerCountToolTipLimit = 3;
  return loanDetails.Borrower.length > borrowerCountToolTipLimit ? value : '';
};

export const showLoanVariationModal = ($uibModal, familyId) => (
  loanId,
  loanAppId,
) => {
  const props = {
    familyId,
    loanId,
    loanAppId,
  };

  return $uibModal.open({
    template: `<loan-variation-modal
        modal-instance="vm.modalInstance"
        family-id="vm.props.familyId"
        loan-id="vm.props.loanId"
        loan-app-id="vm.props.loanAppId"
      ></loan-variation-modal>`,
    ...commonProps,
    resolve: {
      props: () => props,
    },
    windowClass: 'mycrm-modal add-loan-variation-modal',
  });
};

export const isLoanAmountModified = ({
  NewPrincipalAmount,
  OldPrincipalAmount,
}) => {
  return NewPrincipalAmount !== OldPrincipalAmount;
};

export const loanVariationAmountDifference = ({
  NewPrincipalAmount,
  OldPrincipalAmount,
}) => {
  return NewPrincipalAmount - OldPrincipalAmount;
};

export const showVariationValue = (uiService) => (loanDetails) => {
  return (
    uiService.showTopUpLoanVariation &&
    loanDetails.LoanAmount &&
    (loanDetails.isTopUpLoanVariation || loanDetails.IsTopUpOrVariation) &&
    loanDetails.TopUpOrVariationPrincipalAmount &&
    isLoanAmountModified(loanDetails.TopUpOrVariationPrincipalAmount)
  );
};

export const showWorkbenchModal = (modalProvider, contactSharedDataService) => (
  { inprogressLoan, familyId, enquirySource, loanStatusList },
  onDismiss,
) => {
  const modalInstance = modalProvider.open({
    templateUrl: 'assets/views/loanApplication/components/workbench.html',
    size: 'lg',
    backdrop: 'static',
    keyboard: false,
    resolve: {
      cLoanId: () => inprogressLoan.LoanID,
      CLoanStatusId: () => inprogressLoan.LoanStatus.LoanStatusID,
      COrgPipelineStatusId: () => inprogressLoan.LoanStatus.OrgPipelineStatusID,
      CPipelineCardsID: () => inprogressLoan.PipelineCardsID,
      cLoanScenarioId: () => inprogressLoan.LoanScenarioID,
      cFamilyId: () => familyId,
      cEnquirySource: () => enquirySource,
    },
    controller: 'WorkbenchModalCtrl',
    controllerAs: 'vm',
    windowTopClass: 'workbench-modal',
  });
  modalInstance.result.then(
    () => {},
    (error) => {
      if (error.loanStatusId && loanStatusList) {
        const newLoanStatus = loanStatusList.find((stat) => {
          if (error.orgPipelineStatusId) {
            return (
              String(stat.OrgPipelineStatusID) ===
              String(error.orgPipelineStatusId)
            );
          } else {
            return String(stat.LoanStatusID) === String(error.loanStatusId);
          }
        });

        if (newLoanStatus) {
          inprogressLoan.LoanStatus.LoanStatusID = error.loanStatusId;
          inprogressLoan.LoanStatus.LoanStatusName =
            newLoanStatus.LoanStatusName;
          inprogressLoan.LoanStatus.OrgPipelineStatusID =
            error.orgPipelineStatusId;
          inprogressLoan.LoanStatus.OrgPipelineStatusName =
            newLoanStatus.OrgPipelineStatusName;
        }
      }

      // TODO: Workbench should return loanId
      contactSharedDataService.reloadContactLoanTimeline();
      onDismiss && onDismiss();
    },
  );
};

export const showMobileWorkbench = (stateService, $state) => ({
  inprogressLoan,
  familyId,
  enquirySource,
}) => {
  stateService.savePrevState(
    $state.$current.self,
    $state.$current.locals.globals.$stateParams,
  );
  $state.go('app.mobileWorkbench', {
    LoanID: inprogressLoan.LoanID,
    LoanStatusID: inprogressLoan.LoanStatus.LoanStatusID,
    OrgPipelineStatusID: inprogressLoan.LoanStatus.OrgPipelineStatusID,
    PipelineCardsID: inprogressLoan.PipelineCardsID,
    LoanScenarioID: inprogressLoan.LoanScenarioID,
    clientNames: inprogressLoan.Applictants.trim(),
    familyId,
    enquirySource,
  });
};
