import template from './customerCareSendEmail.html';
import controller from './customerCareSendEmailCtrl';
import './styles.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    tableData: '<',
    isInsurance: '<',
    singleData: '<',
    tab: '<',
    adviserList: '<?  ',
  },
};
