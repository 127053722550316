import get from 'lodash/get';
import { NZBN_PROPERTY } from 'Common/constants/employment';
import { COMPANY_TYPES } from 'Common/constants/partyTypes';
import dotProp from 'dot-prop-immutable';
import { emailValidation } from 'Common/utilities/emailUtilities';
import { countryCodeInputElement } from 'Common/utilities/countryCodeUtility';
import { toastError, toastSuccess, toastInfo } from 'Common/utilities/alert';
import { isNotRegisteredNZBNChar } from 'Common/utilities/nzbnChecker';
import { setupNewPostURL } from 'Common/utilities/handleObsoleteURL';
import {
  stripPhoneFromContactSetPayload,
  saveStandAloneEntityContactNumber,
} from 'Common/utilities/contactNumber';
import { ADDRESS_ERROR } from 'Common/constants/promptMessages';
import { dobToAgeGet } from 'Common/utilities/dob';
import { DD_MM_YYYY_MOMENT_FORMAT } from 'Common/constants/dateConfigs';
import { CLIENT_ROLE_TYPE } from 'Common/constants/roleType';
import { processClientAddress } from './clientAddress';
import { restrictAccessibleEnquirySource } from './contactsNewCtrl';

export const validateNZBNfield = ({
  $scope,
  configService,
  currentUserService,
}) => () => {
  const { feature } = configService;
  $scope.bluesStoneApplication =
    currentUserService.isNZ && !!feature.bluestoneOnlineApplication;
  $scope.hideNZBNNumber = !!feature.hideNZBNNumber;
  $scope.validateNZBNcharacter = (data) => {
    $scope.invalidCharacters = false;
    const NZBNChar = (data && data.toString()) || '';
    if (NZBNChar && NZBNChar.length !== NZBN_PROPERTY.NUMERICAL_LENGTH) {
      $scope.invalidCharacters = !$scope.invalidCharacters;
    }
  };
};

export const validateNZBNfieldOnSubmit = (
  { isNZ, feature },
  { $scope = {} },
) => {
  const validateNZBN =
    ($scope.business.employmentNZBN &&
      $scope.business.employmentNZBN.toString()) ||
    '';
  const isCompany = $scope.trustType === COMPANY_TYPES.COMPANY_STRING;
  const featureOn = isNZ && !!feature.bluestoneOnlineApplication;
  const isValidLength = validateNZBN.length !== NZBN_PROPERTY.NUMERICAL_LENGTH;
  return featureOn && isValidLength && isCompany && !$scope.hideNZBNNumber;
};

export const ifNotValidNZBNChar = ({ $scope }) => () => {
  $scope.prevStep();
  toastError('NZBN Should be 13 numerical characters');
  $scope.canSave = true;
};

export const toggleTrustType = ({
  $scope = {},
  configService,
  currentUserService,
}) => {
  $scope.toggleTrustType = ({ label }) => {
    $scope.isTrust = label === COMPANY_TYPES.COMPANY_STRING || false;
    $scope.trustType = label;
  };
  const isOnlineInsuranceApplication = dotProp.get(
    configService,
    'feature.onlineInsuranceApplication',
    false,
  );
  $scope.blueStoneAppAndisNZ =
    isOnlineInsuranceApplication && !!currentUserService.isNZ;
  $scope.trustType = $scope.blueStoneAppAndisNZ
    ? COMPANY_TYPES.TRUST_STRING
    : '';
};

export const onCreateContactOrganisationInfoSet = ({
  $scope = {},
  $state = {},
  contactService,
  contactModelService,
  myCRMconfigs,
  org,
  viewOfFileModalService,
  uiService,
}) => {
  org.OrganisationType = $scope.trustType;

  const contact = $scope.isInternationalPhoneNumbers
    ? stripPhoneFromContactSetPayload([org])[0]
    : org;

  contactService
    .organisationInfoSet(setupNewPostURL(contact, 'organisation'))
    .then((response) => {
      const successAddContactCallback = () => {
        const validateNZBN = isNotRegisteredNZBNChar(
          { ...response, myCRMconfigs },
          'organisation',
        );
        $scope.canSave = true;
        if (validateNZBN) {
          return;
        }
        const { Message } = response.data;
        if (Message) {
          toastInfo(Message);
          return;
        }

        if (!uiService.viewOfAfileTurnedOn) {
          toastSuccess('Business has been Added Successfully.', 'Added!');
        } else {
          contactService
            .clientInformGet(response.data)
            .then(({ data = [] }) => {
              const personIds = data.map((client) => client.PersonId);
              const allocatedAdviserId = get(data, '0.AllocatedAdviserID', 0);
              viewOfFileModalService.launchCreateFileConfirmation({
                familyId: response.data,
                clientName: org.Name,
                personIds,
                allocatedAdviserId,
                isClient: false,
              });
            });
        }
        $state.go('app.BusinessAccount', { familyId: response.data });
      };

      $scope.isInternationalPhoneNumbers &&
        saveStandAloneEntityContactNumber({
          contact: org,
          isAdd: true,
          familyId: response.data,
          contactModelService,
          successAddContactCallback,
        });

      !$scope.isInternationalPhoneNumbers && successAddContactCallback();
    });
};

export const initBusinessAddressSaving = ({
  $scope,
  generalService,
  addressLookupService,
}) => {
  if ($scope.isAddressOverhaulEnabled) {
    processClientAddress({
      $scope,
      generalService,
      addressLookupService,
      contactAddress: $scope.businessAddress,
    })
      .then((address) => {
        $scope.saveBusinessAccount(address);
      })
      .catch(() => {
        toastError(ADDRESS_ERROR.CLIENT);
        $scope.canSave = true;
      });
    return;
  }
  const address = [
    {
      formatted_address: $scope.business.Address,
      geoCoded: $scope.geoCodedAddress,
    },
  ];
  $scope.saveBusinessAccount(address);
};

export const initClientAddressSaving = ({
  $scope,
  generalService,
  addressLookupService,
  setAdviser,
}) => {
  if ($scope.isAddressOverhaulEnabled) {
    processClientAddress({
      $scope,
      generalService,
      addressLookupService,
      contactAddress: $scope.clientAddress,
    })
      .then((address) => {
        setAdviser.Addresses = address;
        $scope.saveContactAddress(setAdviser);
      })
      .catch(() => {
        toastError(ADDRESS_ERROR.CLIENT);
        $scope.canSave = true;
      });
    return;
  }
  $scope.saveContactAddress(setAdviser);
};

export const restrictEnquirySourceForAdviser = ({ $scope, familyId }) => {
  const selectedAdviser =
    $scope.advisersList &&
    $scope.advisersList.find((adviser) => adviser.familyId === familyId);
  if (!selectedAdviser) {
    return;
  }
  restrictAccessibleEnquirySource({
    $scope,
    brandingCategoryId: selectedAdviser.brandingCategoryId,
  });
};

export const getAdvisersList = ({
  $scope,
  mycrmLookupService,
  currentUserService,
}) => {
  mycrmLookupService.getAdvisers().then((data) => {
    $scope.advisersList = data || [];
    const [defaultAdviser] = $scope.advisersList;
    $scope.AssignedAdviserId = currentUserService.hasAdviserAccess
      ? currentUserService.familyId
      : (defaultAdviser && defaultAdviser.familyId) || 0;
    $scope.getAssistantsList($scope.AssignedAdviserId);
    restrictAccessibleEnquirySource({
      $scope,
      brandingCategoryId: defaultAdviser.brandingCategoryId,
    });
  });
};

export const isPhoneNumberExisting = ({
  phoneNumber,
  isInternationalPhoneEnabled,
  isMobileValid,
}) =>
  !phoneNumber && !isInternationalPhoneEnabled
    ? true
    : isMobileValid(phoneNumber);

export const isMobileNumberValid = ({
  countryCodeService,
  mobileNumber,
  isPhone,
  isInternationalPhoneEnabled,
  isInternationalPhoneNumbers,
  angular,
}) => {
  if (isPhone) {
    return true;
  }
  const elementId = 'mobileNumber-dc';
  return countryCodeService.countryInputSelectorValidation({
    element: countryCodeInputElement({ angular, elementId }),
    isPhoneRequired: isInternationalPhoneEnabled,
    message: 'Invalid - Please provide a valid mobile number.',
    phoneNumber: mobileNumber,
    isLandline: isInternationalPhoneNumbers,
  });
};

export const isValidateEmail = ({ isSendFactFind, personEmail }) => {
  if (isSendFactFind) {
    if (!personEmail) {
      return false;
    }

    return emailValidation(personEmail);
  }

  return !personEmail || emailValidation(personEmail);
};

export const isFormInvalid = ({
  form,
  isInternationalPhoneEnabled,
  phoneNumber,
  countryCodeService,
  isMobileValid,
  isSendFactFind,
  isPhone,
  personEmail,
  $scope,
  angular,
}) => {
  const isMobilePhoneValid = isPhoneNumberExisting({
    phoneNumber,
    isInternationalPhoneEnabled,
    isMobileValid,
  });
  const isInvalidForm =
    form.formDataDOB.$invalid ||
    form.formDataFirstName.$invalid ||
    form.formDataLastName.$invalid ||
    !isMobilePhoneValid ||
    !isValidateEmail({ isSendFactFind, personEmail });
  if (isInternationalPhoneEnabled) {
    return (
      form.$invalid ||
      !isMobileNumberValid({
        countryCodeService,
        mobileNumber: phoneNumber,
        isPhone,
        isInternationalPhoneEnabled,
        isInternationalPhoneNumbers:
          !$scope.isInternationalPhoneNumbers &&
          $scope.isPhoneNumberCountryCodeNZ,
        angular,
      }).isValid ||
      !isValidateEmail({ isSendFactFind, personEmail })
    );
  }
  return isInvalidForm;
};

export const setContactAgeValue = (obj) => {
  obj.AgeValue = dobToAgeGet(obj.DOB, DD_MM_YYYY_MOMENT_FORMAT);
};

export const showCreateNewFileConfirmation = ({
  contactService,
  uiService,
  viewOfFileModalService,
  familyId,
  allocatedAdviserId,
}) => {
  if (!uiService.viewOfAfileTurnedOn) {
    return;
  }
  return contactService.clientInformGet(familyId).then(({ data = [] }) => {
    const adults = data.filter(
      (person) => person.Role === CLIENT_ROLE_TYPE.ADULT,
    );
    const personIds = adults.map((client) => client.PersonId);
    const clientName = adults.map((client) => client.FirstName).join(' & ');
    viewOfFileModalService.launchCreateFileConfirmation({
      familyId,
      allocatedAdviserId,
      clientName,
      personIds,
      isClient: true,
    });
  });
};
