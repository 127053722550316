import template from './commissionTable.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    type: '<',
    headerList: '<',
    rowItems: '<',
    isLoading: '<',
    preloaderText: '<',
    filterSettings: '<',
    sendIconTooltipText: '<',
    onSend: '&',
    onDelete: '&',
    onMatchSuccess: '&',
    onRowItemClick: '&',
    onSortTableData: '&',
    onExport: '&',
  },
};
