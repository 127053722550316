export default class InsuranceAppCoverDetailsCtrl {
  constructor(insuranceProfilerService) {
    'ngInject';

    this.insuranceProfilerService = insuranceProfilerService;
  }

  $onInit() {
    this.clientLists = [];
    this.totalPremiums = {};
    this.populateListOfCover();
  }

  populateListOfCover() {
    this.insuranceProfilerService
      .getInsuranceSummaryCover(this.profilerData.profilerId)
      .then(({ clients, totalPremium }) => {
        this.totalPremiums = totalPremium;
        this.clientLists = clients;
      });
  }

  modalClose() {
    this.modalInstance.close();
  }
}
