import { OPTION_ALL } from 'Common/constants/dashboardWidget';
import { shortenAmount } from 'Common/utilities/currency';

export default class CommonWidgetService {
  constructor(
    $state,
    dashboardService,
    overviewDashboardService,
    currentUserService,
    optionsService,
  ) {
    'ngInject';

    this.$state = $state;
    this.dashboardService = dashboardService;
    this.overviewDashboardService = overviewDashboardService;
    this.currentUserService = currentUserService;
    this.optionsService = optionsService;
    this.roundFigure = this.roundFigure.bind(this);
  }

  goToReport(params) {
    this.$state.go('app.report', params);
  }

  getWidgetOptions(widgetOptionId) {
    return this.dashboardService.getWidgetOptions({ widgetOptionId });
  }

  getWidgetUserOptions(widgetUserId) {
    return this.overviewDashboardService.WidgetOptionGet(widgetUserId);
  }

  getAdviserDropdown(isLoanWriterOnly) {
    return this.optionsService
      .getAdvisers(isLoanWriterOnly, true)
      .then((data) => {
        if (!data || !data.length) {
          return [];
        }
        return [{ ...OPTION_ALL.ADVISERS, ticked: true }, ...data];
      });
  }

  getCategoryLendersList() {
    return this.optionsService.getCategoryLendersList().then((data) => {
      if (!data || !data.length) {
        return [];
      }
      return [{ ...OPTION_ALL.LENDER_CATEGORY, ticked: true }, ...data];
    });
  }

  roundFigure(val) {
    const { brokerRegionalization } = this.currentUserService;
    const roundedVal = Math.round(val) || 0;
    return `${
      brokerRegionalization.currencySign
    }${roundedVal.toLocaleString()}`;
  }

  getShortenAmount(val) {
    const { brokerRegionalization } = this.currentUserService;
    return `${brokerRegionalization.currencySign}${shortenAmount(val, 2)}`;
  }

  getCustomLabel(valuePayload, datasets) {
    const isValid = valuePayload && datasets && datasets.length;
    if (!isValid) {
      return;
    }

    const { index, datasetIndex } = valuePayload;
    const titleSeries = datasets[datasetIndex].label;
    const value = datasets[datasetIndex].data[index];
    return `${titleSeries}: ${this.roundFigure(value)}`;
  }
}
