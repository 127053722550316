import angular from 'angular';

export const loadModule = () =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  angular.module('app').directive('leadsTable', function leadsTable($timeout) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/leadsTable.html',

      // eslint-disable-next-line sonarjs/cognitive-complexity
      link(scope) {
        scope.interval = 'week';
        scope.slickConfig = { method: {} };
        const imgSrcArr = [14, 24, 72, 13, 53];
        scope.testDataContainer = [];
        function init() {
          const totalData = 6;
          const round = Math.floor(totalData / 4) * 4 - 1;
          const modStart = Math.floor(totalData / 4) * 4;
          const mod = Math.floor(totalData % 4);
          for (let i = 0; i < round; i += 4) {
            const testData = [];
            testData.push({
              amount: '$104.8',
              imgSrc: imgSrcArr[Math.floor(Math.random() * 5)],
            });
            testData.push({
              amount: '$242.54',
              imgSrc: imgSrcArr[Math.floor(Math.random() * 5)],
            });
            testData.push({
              amount: '$242.54',
              imgSrc: imgSrcArr[Math.floor(Math.random() * 5)],
            });
            testData.push({
              amount: '$369.28',
              imgSrc: imgSrcArr[Math.floor(Math.random() * 5)],
            });
            // testData.push({amount:"$594.4",imgSrc:imgSrcArr[Math.floor(Math.random()*(5))]});

            scope.testDataContainer.push(testData);
          }

          if (parseInt(mod, 10) !== 0) {
            const lastItem = [];
            for (let i = modStart; i < totalData; i++) {
              lastItem.push({
                amount: '$104.8',
                imgSrc: imgSrcArr[Math.floor(Math.random() * 5)],
              });
            }
            scope.testDataContainer.push(lastItem);
          }

          scope.dataLoaded = true;

          if (scope.testDataContainer.length > 0) {
            $timeout(() => {
              try {
                scope.slickConfig.method.slickGoTo(0);
              } catch (error) {
                // Continue regardless of error
              }
            }, 1000);
          }
        }

        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'commissionReceivedChartPopover.html',
          title: 'Title',
        };

        scope.doAction = function (msg) {
          if (msg === 'Customise') {
            scope.customizeMode = true;
          } else {
            scope.customizeMode = false;
          }
        };

        init();
      },
    };
  });
