import { get } from 'lodash';
import { RATE_PERIOD } from 'Common/constants/loanAppDiscount';

export const labelBuilderForUI = (productName, rate) => {
  return rate ? `${productName} (${rate}%)` : '';
};

export const rateLabelBuilderForUI = (data, ratePeriod) => {
  const { initialRate, ongoingRate, productName } = data;
  return ratePeriod === RATE_PERIOD.INITIAL.VALUE
    ? { label: labelBuilderForUI(productName, initialRate), rate: initialRate }
    : { label: labelBuilderForUI(productName, ongoingRate), rate: ongoingRate };
};

export const productRateBuilderForUI = (data) => {
  const initialProductRate = get(data, 'recommendedProduct.initialRate', 0);
  const initialReferenceRate = get(
    data,
    'initialReferenceProduct.initialRate',
    0,
  );
  const initialReadOnlyBaseRate = initialReferenceRate || initialProductRate;
  const ongoingProductRate = get(data, 'recommendedProduct.ongoingRate', 0);
  const ongoingReferenceRate = get(
    data,
    'ongoingReferenceProduct.ongoingRate',
    0,
  );
  return {
    initial: {
      actualRate: data.initialActualRate,
      baseRate: data.initialBaseRate,
      discountRate: data.initialDiscountRate || 0,
      isDetailShow: !!data.initialDiscountRate,
      ratePeriod: RATE_PERIOD.INITIAL.VALUE,
      recommendedProductName: get(data, 'recommendedProduct.productName'),
      productRate: initialProductRate,
      readOnlyBaseRate: initialReadOnlyBaseRate,
      isOverrideBaseRate: data.initialBaseRate !== initialReadOnlyBaseRate,
    },
    initialReferenceProduct: {
      isActive: !!initialReferenceRate,
      initialRate: initialReferenceRate,
      ongoingRate: data.initialReferenceProduct.ongoingRate,
      ratePeriod: data.initialReferenceProduct.ratePeriod,
      referenceProductId: data.initialReferenceProduct.referenceRateProductID,
      selectedProductId: data.initialReferenceProduct.selectedProductID,
      productName: data.initialReferenceProduct.productName || '',
      ...rateLabelBuilderForUI(
        data.initialReferenceProduct,
        RATE_PERIOD.INITIAL.VALUE,
      ),
    },
    ongoing: {
      actualRate: data.ongoingActualRate,
      baseRate: data.overrideOngoingBaseRate || data.ongoingBaseRate,
      discountRate: data.ongoingDiscountRate || 0,
      isDetailShow: !!+data.ongoingDiscountRate,
      ratePeriod: RATE_PERIOD.ONGOING.VALUE,
      recommendedProductName: get(data, 'recommendedProduct.productName'),
      isOverrideBaseRate: !!data.overrideOngoingBaseRate,
      isOngoingRate: true,
      productRate: ongoingProductRate,
      readOnlyBaseRate: ongoingReferenceRate || ongoingProductRate,
    },
    ongoingReferenceProduct: {
      isActive: !!ongoingReferenceRate,
      initialRate: data.ongoingReferenceProduct.initialRate,
      ongoingRate: ongoingReferenceRate,
      ratePeriod: data.ongoingReferenceProduct.ratePeriod,
      referenceProductId: data.ongoingReferenceProduct.referenceRateProductID,
      selectedProductId: data.ongoingReferenceProduct.selectedProductID,
      productName: data.ongoingReferenceProduct.productName || '',
      ...rateLabelBuilderForUI(
        data.ongoingReferenceProduct,
        RATE_PERIOD.ONGOING.VALUE,
      ),
    },
    initialPeriod: get(data, 'recommendedProduct.initialPeriod', 0),
    productLoanStructureId: data.productLoanStructureID,
    favoriteProductId: get(data, 'recommendedProduct.productID'),
  };
};

export const productRateBuilderForMyCRM = (data) => {
  return {
    loanId: data.loanId,
    productLoanStructureId: data.productLoanStructureId,
    favoriteProductId: data.favoriteProductId,
    brokerEventId: data.brokerEventId,
    ratePeriod: data.ratePeriod,
    ...(!data.isOngoingRate
      ? { initialDiscountRate: data.discountRate }
      : { ongoingDiscountRate: data.discountRate }),
    ...((data.isFixed || (data.isOverrideBaseRate && !data.isOngoingRate)) && {
      newInitialRate: data.actualRate,
      newInitialBaseRate: data.baseRate,
    }),
    ...(data.isOngoingRate &&
      data.isOverrideBaseRate && {
        overrideOngoingBaseRate: data.baseRate || 0,
      }),
    referenceRate: {
      selectedProductId: data.referenceProduct.selectedProductId,
      referenceRateProductId: data.referenceProduct.referenceProductId,
      isActive: !!data.referenceProduct.isActive,
    },
  };
};

export const productRateSetResponseBuilderForUI = (data) => {
  return {
    referenceProductId: data.referenceRateProductId,
    selectedProductId: data.selectedProductId || 0,
  };
};

export const loanSplitSetBuilderForMyCRM = (data) => {
  return {
    AccountNumber: data.AccountNumber,
    Borrowers: data.Borrowers,
    BrokerEventId: data.BrokerEventId,
    FixedRateBegin: data.FixedRateBegin,
    FixedRateExpiry: data.FixedRateExpiry,
    ImportantDates: data.ImportantDates,
    InterestOnlyTerm: data.InterestOnlyTerm,
    InterestRateType: data.InterestRateType,
    IsCapitaliseLMI: data.IsCapitaliseLMI,
    IsItFromCustomerCare: data.IsItFromCustomerCare,
    IsLinkRateExpiry: data.IsLinkRateExpiry,
    LMI: data.LMI,
    LenderDetails: data.LenderDetails,
    LoanId: data.LoanId,
    LoanID: data.LoanId,
    LoanInterestRate: data.LoanInterestRate,
    LoanStructureId: data.LoanStructureId,
    LoanStructureType: data.LoanStructureType,
    LoanTerm: data.LoanTerm,
    ProductID: data.ProductID,
    RateTerm: data.RateTerm,
    RepaymentAmount: data.RepaymentAmount,
    RepaymentFrequency: data.RepaymentFrequency,
    Value: data.Value,
  };
};
