export const ADDRESS_FIELD = {
  LEVEL: 'Level',
  BUILDING_NAME: 'BuildingName',
  UNIT: 'Unit',
  STREET_NUMBER: 'StreetNumber',
  STREET_NAME: 'StreetName',
  STREET_TYPE: 'StreetType',
  ADDRESS_LINE_1: 'AddressLine1',
  ADDRESS_LINE_2: 'AddressLine2',
  POST_OFFICE_TYPE: 'PostOfficeType',
  NUMBER: 'Number',
  EXCHANGE: 'Exchange',
  SUBURB: 'Suburb',
  STATE: 'State',
  POST_CODE: 'PostCode',
  COUNTRY: 'Country',
};

export const REGEX = {
  POST_CODE: '\\d+',
};

export const ADDRESS_TYPES = {
  STANDARD: 1,
  NON_STANDARD: 2,
  PO_BOX: 3,
};

export const ADDRESS_TYPE_LIST = [
  {
    id: ADDRESS_TYPES.STANDARD,
    name: 'Standard address',
  },
  {
    id: ADDRESS_TYPES.NON_STANDARD,
    name: 'Non-Standard address',
  },
  {
    id: ADDRESS_TYPES.PO_BOX,
    name: 'PO Box address',
  },
];
