import template from './opportunitySendSmsModal.html';
import controller from './opportunitySendSmsModalCtrl';
import './style.scss';

export default {
  template,
  bindings: {
    sms: '<',
    loanId: '<',
    familyId: '<',
    modalInstance: '<',
    recipientsChoices: '<',
  },
  controller,
  controllerAs: 'vm',
};
