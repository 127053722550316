export const NEXT_WORK_DATE = 13;

export const INPUT_DATE_FORMAT = 'dd MMM yyyy';
export const ALT_INPUT_FORMATS = ['d!/M!/yyyy', 'd!M!yyyy'];
export const DATE_OPTIONS = {
  formatYear: 'yy',
  showWeeks: false,
};
export const DATE_MODEL_OPTIONS = {
  timezone: 'utc',
};

/* Take note that this is the correct order */
export const WORK_BENCH_STATES = {
  IS_MAIN: 0,
  IS_EMAIL: 1,
  IS_MAIL: 2,
  IS_NOTE: 3,
  IS_TASK: 4,
  IS_TASK_TEMPLATED: 5,
  ACTIONS: [
    'workbench-send-email',
    'workbench-send-mail',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    'workbench-add-note',
    'workbench-add-note',
    'workbench-add-note',
  ],
};
