export const updateTemplateTaskSettings = (id, data) => (task) => {
  if (task.id === id) {
    return { ...task, list: data };
  }
  return task;
};

export const calculatePrevNext = (current, total, maxSize) => {
  const prev = (current - 1) * maxSize;
  const next = current * maxSize;
  return { prev, next };
};
