/* rename component when old table retires */
import template from './newCorpAccreditationTable.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    providerList: '<',
    mainObject: '<',
    dateOptions: '<',
    dateFormatted: '<',
    fetching: '<',
    isNZ: '<',
    isAU: '<',
    selectedLender: '<',
    showCalendar: '&',
    onChangeStatus: '&',
    onAccreditationChange: '&',
    onSelectProviders: '&',
  },
};
