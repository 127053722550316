import angular from 'angular';
import addressLookup from './addressLookup';
import clientAddressDetails from './clientAddressDetails';
import addressLookupClient from './addressLookupClient';
import addressLookupSearchAgain from './addressLookupSearchAgain';

export default angular
  .module('addressLookupModal.scenes.components', [])
  .component('addressLookupClient', addressLookupClient)
  .component('clientAddressDetails', clientAddressDetails)
  .component('addressLookupSearchAgain', addressLookupSearchAgain)
  .component('addressLookup', addressLookup).name;
