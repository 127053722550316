import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('LoanDetailsModalCtrl', function LoanDetailsModalCtrl(
      $scope,
      $uibModalInstance,
      toaster,
      loanInfo,
      action,
    ) {
      $scope.loanDetailInfo = loanInfo;
      $scope.action = action;

      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.close = () => {
        $uibModalInstance.close('close');
      };
    });
