export const POSSIBLE_ADVERSE_CHANGES = {
  FIELD: {
    RADIOBOX_ON_YES: 12,
    TEXT_DROPDOWN: 9,
    YES_NO: 1,
  },
  TITLE: 'Possible Adverse Changes',
};

export const LIST_OF_QUESTIONS_AU = {
  RETIREMENT_AGE: 172,
  LOAN_REPAYMENT: 209,
  APPLICANT_RETIRING: 175,
  APPLICANT_RETIRING_PROPOSED_PAYMENT: 176,
  ANTICIPATE_CHANGES: 177,
  ANTICIPATE_CHANGES_NATURE: 186,
  REPAYMENT_OTHER: 208,
  REACH_RETIREMENT: 227,
};

export const LIST_OF_QUESTIONS_NZ = {
  RETIREMENT_AGE: 248,
  LOAN_REPAYMENT: 253,
  APPLICANT_RETIRING: 249,
  APPLICANT_RETIRING_PROPOSED_PAYMENT: 250,
  ANTICIPATE_CHANGES: 251,
  ANTICIPATE_CHANGES_NATURE: 252,
  REPAYMENT_OTHER: 267,
  REACH_RETIREMENT: 258,
};

export const MAIN_QUESTIONS = {
  AU: [
    LIST_OF_QUESTIONS_AU.RETIREMENT_AGE,
    LIST_OF_QUESTIONS_AU.APPLICANT_RETIRING,
    LIST_OF_QUESTIONS_AU.ANTICIPATE_CHANGES,
    LIST_OF_QUESTIONS_AU.REACH_RETIREMENT,
  ],
  NZ: [
    LIST_OF_QUESTIONS_NZ.RETIREMENT_AGE,
    LIST_OF_QUESTIONS_NZ.APPLICANT_RETIRING,
    LIST_OF_QUESTIONS_NZ.ANTICIPATE_CHANGES,
    LIST_OF_QUESTIONS_NZ.REACH_RETIREMENT,
  ],
};

export const DEFAULT_ANTICIPATE_FIELDS = {
  financialImpactOperator: '=',
  startDate: new Date(),
};
