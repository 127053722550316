import { COLOR_PATTERN } from 'Common/constants/pattern';
import { BRANDING_DEFAULT } from 'Common/constants/colors';
import { isOwnBrand as utilIsOwnBrand } from 'Common/utilities/brandingCategory';
import { getDataURI } from 'Common/utilities/document';
import { getFullName } from 'Common/utilities/contact';
import { toastSuccess } from 'Common/utilities/alert';

export const isOwnBrand = (adviserOrg) => {
  if (!adviserOrg) {
    return;
  }
  return utilIsOwnBrand(adviserOrg.BrandingCategoryId);
};

export const displayLogoPreview = ({ $scope }) => (flag = true) => {
  $scope.showLogo = flag;
};

export const setBrandingColor = ({ $scope }) => (adviserOrg) => {
  $scope.showBrandingColor = true;
  const brandingColor = (adviserOrg && adviserOrg.BrandingColour) || '';
  const isHex = new RegExp(COLOR_PATTERN.HEX).test(brandingColor);
  $scope.brandingColor = brandingColor && isHex ? brandingColor : '';
  $scope.hasBrandingColor = !!$scope.brandingColor;
};

export const saveLogo = ({ $scope }) => (files) => {
  if (!files || !files.length) {
    return;
  }
  const logo = files[0] || {};
  const docId = logo.DocumentId || '';

  $scope.adviserOrganizationGet.BrandingLogoDocId = docId;
  $scope.adviserOrganizationGet.brandingLogo = logo;
  $scope.saveMarketingDetails('BrandLogo');
};

export const saveColor = ({ $scope }) => (color) => {
  $scope.adviserOrganizationGet.BrandingColour = color;
  $scope.saveMarketingDetails('BrandingColour');
};

export const getLogo = ({ $scope, generalService }) => (adviserOrg) => {
  const docId = adviserOrg && adviserOrg.BrandingLogoDocId;
  if (!docId) {
    return;
  }
  generalService.documentGet(docId).then((response) => {
    if (!response || !response.data) {
      return;
    }
    $scope.logoSrc = getDataURI(response.data);
    $scope.showLogoPreview();
  });
};

export const setDetailsAfterSaveByKey = ({ $scope }) => (details, key) => {
  if (key === 'NominatedPrincipal') {
    const { firstName, middleName, lastName } = details.NominatedPrincipal;
    const fullname = getFullName(firstName, lastName, middleName);
    $scope.adviserOrganizationGet.NominatedPrincipal.fullName = fullname;
  } else if (key === 'BrandLogo') {
    $scope.logoSrc = getDataURI(details.brandingLogo);
    $scope.showLogoPreview();
  } else if (key === 'BrandingColour') {
    $scope.setBrandingColorFields(details);
  } else if (key === 'BrandingCategory') {
    $scope.setBrandingValues();
  }
};

export const getAdviserOrgBranding = ({ $scope, corporateModelService }) => (
  adviserOrgId,
  displayWhileRetrieving = true,
) => {
  $scope.isThemeLoaded = displayWhileRetrieving;
  corporateModelService
    .getAdviserOrganizationTheme(adviserOrgId)
    .then((data) => {
      $scope.branding = data && {
        ...data,
        adviserOrganizationId: adviserOrgId,
      };
      $scope.brandColor =
        (data && data.brandingColour) || BRANDING_DEFAULT.SIDEBAR_BG;
      $scope.isThemeLoaded = true;
    })
    .catch(() => {
      $scope.isThemeLoaded = true;
    });
};

export const saveAdviserOrgBrandColor = ({ $scope, corporateModelService }) => (
  color,
) => {
  const params = { ...$scope.branding, brandingColour: color };
  corporateModelService.setAdviserOrganizationTheme(params).then(() => {
    $scope.branding.brandingColour = color;
    $scope.brandColor = color;
    $scope.getAdviserOrgBranding($scope.adviserOrgId);
    toastSuccess('Branding color successfully saved!');
  });
};

export const showBrandingFields = (bYOBDoneRightFlag) => (
  adviserOrg,
  byobDoneRightField,
) => {
  if (!isOwnBrand(adviserOrg)) {
    return false;
  }
  const showOldBrandingFields = !byobDoneRightField && !bYOBDoneRightFlag;
  const showByobDoneRightFields = byobDoneRightField && bYOBDoneRightFlag;
  return showOldBrandingFields || showByobDoneRightFields;
};
