import angular from 'angular';
import helloPackEditorItem from './helloPackEditorItem';
import helloPackEmailContent from './helloPackEmailContent';
import helloPackInvolvedParties from './helloPackInvolvedParties';
import helloPackEmailField from './helloPackEmailField';
import helloPackPhoneField from './helloPackPhoneField';
import helloPackFactFind from './helloPackFactFind';
import helloPackDocumentRequest from './helloPackDocumentRequest';
import helloPackFileInvite from './helloPackFileInvite';
import helloPackAttachment from './helloPackAttachment';

export default angular
  .module('helloPack.scenes.helloPackEditor.components', [])
  .component('helloPackEditorItem', helloPackEditorItem)
  .component('helloPackInvolvedParties', helloPackInvolvedParties)
  .component('helloPackEmailField', helloPackEmailField)
  .component('helloPackPhoneField', helloPackPhoneField)
  .component('helloPackFactFind', helloPackFactFind)
  .component('helloPackDocumentRequest', helloPackDocumentRequest)
  .component('helloPackFileInvite', helloPackFileInvite)
  .component('helloPackAttachment', helloPackAttachment)
  .component('helloPackEmailContent', helloPackEmailContent).name;
