import { get } from 'lodash';
import { parseToInt10 } from 'Common/utilities/parse';
import { toastWarning, toastSuccess, toastError } from 'Common/utilities/alert';
import {
  loadLoanDetails,
  updateLoanDetailsLMIPremium,
} from 'Assets/js/controllers/loanApp/util/clientAccess/loanFundingDetails.js';

const LMI_INVEST = 6;

class LmiAirtableProposedLendingCtrl {
  constructor(
    fundingCalculatorService,
    airTableModelService,
    broadcastDataService,
    loanAppSharedData,
    loanScenarioService,
  ) {
    'ngInject';

    this.fundingCalculatorService = fundingCalculatorService;
    this.airTableModelService = airTableModelService;
    this.broadcastDataService = broadcastDataService;
    this.loanAppSharedData = loanAppSharedData;
    this.loanScenarioService = loanScenarioService;
  }

  $onInit() {
    this.loanAppId = this.loanAppSharedData.currentLoanAppId;
    this.baseLoanAmount = 0;
    this.loadLoanDetails().then(() => this.getLenderList());
  }

  generateLoanSplitsName() {
    if (!get(this.loanSplits, 'length', 0)) {
      this.getTotalFunding();
      return;
    }
    this.loanSplits =
      (this.loanSplits &&
        this.loanSplits.length &&
        this.loanSplits.map((loanSplit, index) => {
          this.baseLoanAmount += loanSplit.BaseLoanAmount || 0;
          return {
            ...loanSplit,
            Name: `Split ${index + 1}`,
          };
        })) ||
      [];
    this.getFundingCalculatorFundsQuickLMI();
  }

  getTotalFunding() {
    if (!this.brokerEventId || !this.loanAppId) {
      return;
    }
    this.fundingCalculatorService
      .getFundingCalculatorFundsSummary(this.brokerEventId, this.loanAppId)
      .then(({ totalProposedLendingUserDefined, lmiAmount }) => {
        this.baseLoanAmount =
          totalProposedLendingUserDefined -
          (lmiAmount || get(this.loanDetails, 'LMI', 0));
        this.getFundingCalculatorFundsQuickLMI();
      });
  }

  getLenderList() {
    if (!this.brokerEventId) {
      return;
    }
    this.fundingCalculatorService.getLenderList(true).then(({ data }) => {
      this.lenderCountry = data.Country;
      this.lenderList = (data && data.Lenders) || [];
      this.generateLoanSplitsName();
    });
  }

  getFundingCalculatorFundsQuickLMI() {
    if (!this.brokerEventId) {
      return;
    }
    this.fundingCalculatorService
      .getFundingCalculatorFundsQuickLMI(this.brokerEventId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        const { LoanStructureID } = data;
        const loanSplit = this.getSelectedLoanSplit(
          LoanStructureID,
          this.loanSplits,
        );
        this.quickLmi = {
          ...data,
          NewLoanAmount: this.baseLoanAmount,
          Insurer: parseToInt10(data.Insurer || 0),
          ...((!loanSplit || !loanSplit.LoanStructureId) && {
            LoanStructureID: null,
          }),
        };
        this.getSelectedLender();
        this.getInsurerProducts();
      });
  }

  getSelectedLoanSplit(LoanStructureID, loanSplits = []) {
    if (!LoanStructureID || !get(loanSplits, 'length')) {
      return;
    }
    return this.loanSplits.find(
      (split) => split.LoanStructureId === LoanStructureID,
    );
  }

  getSelectedLender(updateInsurer) {
    const lenderId = get(this.quickLmi, 'Lenders[0]');
    if (!lenderId || lenderId === 'ALL') {
      return;
    }
    this.insurers = [];
    this.insurerProducts = [];
    this.selectedLender = this.lenderList.find(
      (lender) => lender.Value === lenderId,
    );
    this.quickLmi = {
      ...this.quickLmi,
      ...(updateInsurer && { Insurer: null }),
      ...(updateInsurer && { LMIProductID: null }),
    };
    this.airTableModelService.getLenderInsurers(lenderId).then(({ data }) => {
      this.insurers = data || [];
    });
  }

  checkPremiumAvailabilty() {
    const { LMIProductID, LoanStructureID, CapitalizeLMI } = this.quickLmi;
    if (!LMIProductID || !LoanStructureID || !CapitalizeLMI) {
      return;
    }
    const loanSplit = this.getSelectedLoanSplit(
      LoanStructureID,
      this.loanSplits,
    );
    const isOwnerOccupied =
      get(loanSplit, 'SelectedFavouriteProductData.ProductTypes', '').includes(
        'Owner Occupied',
      ) && LMI_INVEST === LMIProductID;
    if (!isOwnerOccupied) {
      return;
    }
    this.quickLmi = {
      ...this.quickLmi,
      LMIProductID: null,
    };
    toastWarning('No matching premium was found.');
  }

  getInsurerProducts(updateInsurerProduct) {
    const lenderId = get(this.quickLmi, 'Lenders[0]');
    const insurerId = get(this.quickLmi, 'Insurer');
    if (!lenderId || lenderId === 'ALL' || !insurerId) {
      return;
    }
    this.insurerProducts = [];
    this.airTableModelService
      .getInsurerProducts({ lenderId, insurerId, includeDefault: true })
      .then(({ data }) => {
        this.insurerProducts = data || [];
        if (!data || !data.length) {
          return;
        }
        this.quickLmi = {
          ...this.quickLmi,
          ...(updateInsurerProduct && {
            LMIProductID: this.insurerProducts[0].LMIProductID,
          }),
        };
      });
  }

  saveQuickLmi(form) {
    if (!this.brokerEventId || !form || form.$invalid) {
      return;
    }
    const {
      LoanStructureID: loanStructureId,
      IsTopUp,
      PremiumAlreadyPaid,
    } = this.quickLmi;
    this.quickLmi = {
      ...this.quickLmi,
      BrokerEventID: this.brokerEventId,
      LoanTerm: get(
        this.getSelectedLoanSplit(loanStructureId, this.loanSplits),
        'LoanTerm',
      ),
      PremiumAlreadyPaid: (IsTopUp && PremiumAlreadyPaid) || 0,
    };
    const { SecurityAmount, ...postData } = this.quickLmi;
    this.fundingCalculatorService
      .setFundingCalculatorFundsQuickLMI(postData)
      .then(({ data }) => {
        if (!data) {
          return toastError();
        }
        this.close();
        this.broadcastDataService.InlinePreloader = {
          preLoaderText: 'Calculating LMI',
          preLoaderSize: '',
          showPreLoader: true,
        };
        this.getFundingCalculatorFundsQuickLMILoanApp();
      })
      .catch(toastError);
  }

  getFundingCalculatorFundsQuickLMILoanApp() {
    if (!this.brokerEventId) {
      return;
    }
    this.fundingCalculatorService
      .getFundingCalculatorFundsQuickLMILoanApp(this.brokerEventId)
      .then(({ data }) => {
        const { LMIPremium } = data;
        this.broadcastDataService.InlinePreloader = {
          preLoaderText: 'Calculating LMI',
          preLoaderSize: '',
          showPreLoader: false,
        };
        this.loanAppSharedData = {
          ...this.loanAppSharedData,
          sectionSelected: 'loandetails',
          subSectionSelected: 'lmi-section',
        };
        this.updateLmiValueOnTxt({ LMIPremium });
        this.isFromFundingDetails && this.updateLoanDetails(LMIPremium);
        if (LMIPremium) {
          this.updateSelectedSplit(LMIPremium);
          toastSuccess(
            { messages: ['LMI has been successfully Updated'] },
            'Updated',
          );
        } else {
          toastWarning(
            'No Results found, please refine your LMI search criteria.',
            'Try Again',
          );
        }
      });
  }

  updateSelectedSplit(lmiPremium) {
    const { CapitalizeLMI, LoanStructureID } = this.quickLmi;
    if (typeof lmiPremium === 'undefined' || !LoanStructureID) {
      return;
    }
    this.loanScenarioService
      .loanFacilityGet(this.loanAppId, LoanStructureID)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        const loanSplit = {
          ...data,
          CapitaliseLMI: CapitalizeLMI,
          IsAddLMI: CapitalizeLMI,
          LMIPremium: lmiPremium,
          TotalLoanAmount: data.BaseLoanAmount + lmiPremium,
          loanScenarioId: this.loanAppId,
        };
        this.loanScenarioService.loanFacilitySet(loanSplit);
      });
  }

  getBaseLoanAmount() {
    const loanStructureId = get(this.quickLmi, 'LoanStructureID');
    if (!loanStructureId) {
      return;
    }
    const loanStructure = this.getSelectedLoanSplit(
      loanStructureId,
      this.loanSplits,
    );
    if (!loanStructure) {
      return;
    }
    const { BaseLoanAmount: NewLoanAmount, LoanTerm } = loanStructure;
    this.quickLmi = {
      ...this.quickLmi,
      LoanTerm,
      NewLoanAmount,
    };
  }

  loadLoanDetails() {
    return loadLoanDetails({
      $scope: this,
      loanScenarioService: this.loanScenarioService,
      loanAppId: this.loanAppId,
    });
  }

  updateLoanDetails(LMIPremium) {
    return updateLoanDetailsLMIPremium({
      LMIPremium,
      loanAppId: this.loanAppId,
      loanDetails: this.loanDetails,
      loanAppSharedData: this.loanAppSharedData,
      loanScenarioService: this.loanScenarioService,
    });
  }

  close() {
    this.modalInstance.dismiss('cancel');
  }
}
export default LmiAirtableProposedLendingCtrl;
