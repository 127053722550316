import template from './insuranceFireAndGeneralModal.html';
import controller from './insuranceFireAndGeneralModalCtrl.js';

import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    insuranceData: '<',
  },
};
