import angular from 'angular';
import { get } from 'lodash';
import {
  QUESTION_ID_AU,
  REQUIRED_RATIONALE_QUESTION_ID_AU,
  REQUIRED_RATIONALE_QUESTION_ID_NZ,
} from 'Common/constants/questionId';
import { LIST_OF_QUESTIONS } from 'Common/constants/customerObjectives';
import { removeMultipleWhiteSpace } from 'Common/utilities/string';
import { toastError } from 'Common/utilities/alert';
import { INPUT_FIELDS_DEBOUNCE_TIMEOUT } from 'Common/constants/formSettings';
import { WORD_COUNT_AU, WORD_COUNT_NZ } from 'Common/constants/rationale';
import { getIndexedResponses } from 'Common/utilities/loanFundingDetails';

const RATIONALE_QUESTION_TYPE_ID = 15;
const TMD_QUESTION_TYPE_ID = 18;

class ProductAndLenderRationaleCtrl {
  constructor(
    loanScenarioModelService,
    loanAppSharedData,
    currentUserService,
    uiService,
    configService,
  ) {
    'ngInject';

    this.loanScenarioModelService = loanScenarioModelService;
    this.loanAppSharedData = loanAppSharedData;
    this.currentUserService = currentUserService;
    this.uiService = uiService;
    this.configService = configService;
  }

  $onInit() {
    this.bidNotes = this.uiService.bidNotes;
    this.QUESTION_ID_AU = QUESTION_ID_AU;
    this.inputDebounceTimeout = INPUT_FIELDS_DEBOUNCE_TIMEOUT;
    this.wordCount = 0;
    this.isAU = this.currentUserService.isAU;
    this.isNZ = this.currentUserService.isNZ;
    this.wordCountLabel = this.isAU
      ? '(200 to submit, 500 recommended)'
      : '(200+ recommended)';
    this.requiredQuestionIds = this.isAU
      ? REQUIRED_RATIONALE_QUESTION_ID_AU
      : REQUIRED_RATIONALE_QUESTION_ID_NZ;
    this.wordCountLimit = this.isAU ? WORD_COUNT_AU : WORD_COUNT_NZ;
    this.getRationaleQuestions();
    this.showTargetMarketDetermination = !!this.configService?.feature
      ?.targetMarketDetermination;
    if (this.showTargetMarketDetermination) {
      this.getTmdModel();
    } else {
      this.isTmdLoading = false;
    }
  }

  $onChanges(changes) {
    const fundingQuestionChange = get(
      changes,
      'fundingQuestionList.currentValue',
      '',
    );

    if (
      fundingQuestionChange &&
      this.uiService.showGamePlanInterestAndCommission
    ) {
      this.showTurnAroundTimesPlaceholderSetup();
    }
  }

  getTmdModel() {
    const params = {
      loanApplicationId: this.loanAppId,
      questionTypeId: TMD_QUESTION_TYPE_ID,
    };
    this.loanScenarioModelService
      .getRationaleQuestions(params)
      .then((responses) => {
        this.loanAppSharedData.tmdModel = getIndexedResponses(responses);
      })
      .catch(toastError)
      .finally(() => {
        this.isTmdLoading = false;
      });
  }

  getRationaleQuestions() {
    const params = {
      loanApplicationId: this.loanAppId,
      questionTypeId: RATIONALE_QUESTION_TYPE_ID,
    };
    this.loanScenarioModelService
      .getRationaleQuestions(params)
      .then((data) => {
        this.questionList = data.map((question) => {
          question.required = this.requiredQuestionIds.includes(
            question.questionId,
          );
          return question;
        });
        this.isAnswersValid();
      })
      .catch(toastError);
  }

  submitAnswer(question) {
    if (!question) {
      return;
    }
    const formattedAnswer = angular.copy(question);
    formattedAnswer.answerString = formattedAnswer.answerString.replace(
      /\n/g,
      '<br>',
    );
    this.loanScenarioModelService
      .postRationaleQuestions(formattedAnswer)
      .then(() => {
        return this.isAnswersValid(true);
      })
      .catch(toastError);
  }

  keyUpHandler() {
    this.isAnswersValid();
  }

  getWordCount() {
    return (
      (this.questionList &&
        this.questionList.reduce((count, question) => {
          const text = get(question, 'answerString', '') || '';
          const answer = removeMultipleWhiteSpace(text.replace(/\n/g, ' '));
          if (!answer) {
            return count;
          }
          return count + answer.split(' ').length;
        }, 0)) ||
      0
    );
  }

  setWordCountStatus() {
    this.isDanger = this.wordCount <= this.wordCountLimit.RED.MAX;
    this.isSuccess = this.wordCount >= this.wordCountLimit.GREEN.MIN;
    this.isThumbsUp = this.wordCount >= this.wordCountLimit.THUMBS_UP.MIN;
  }

  isAnswersValid(validate) {
    if (!get(this.questionList, 'length')) {
      return;
    }

    this.wordCount = this.getWordCount();
    this.setWordCountStatus();

    const isRequiredSectionFilled = !this.questionList.some((question) => {
      const { questionId, answerString } = question;
      return this.requiredQuestionIds.includes(questionId) && !answerString;
    });

    this.loanAppSharedData.validRationaleAnswers = isRequiredSectionFilled;
    if (this.isAU) {
      this.loanAppSharedData.validRationaleAnswers =
        this.loanAppSharedData.validRationaleAnswers && this.isSuccess;
      if (this.showTargetMarketDetermination) {
        this.loanAppSharedData.validRationaleAnswers =
          this.loanAppSharedData.validRationaleAnswers &&
          this.loanAppSharedData.isTmdValid();
      }
    }
    validate && this.validateLoanDetails();
  }

  showTurnAroundTimesPlaceholderSetup() {
    this.turnAroundTimePlaceholder =
      'Remember to explain any potential tiered servicing conflicts of interest';

    this.referenceTieredServicingQuestion = this.fundingQuestionList.find(
      (question) => question.QuestionId === LIST_OF_QUESTIONS.TIERED_SERVICING,
    );
  }
}
export default ProductAndLenderRationaleCtrl;
