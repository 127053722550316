export default class EmailTemplateFieldsCtrl {
  constructor($window) {
    'ngInject';

    this.$window = $window;
  }

  insertValue(value) {
    this.$window.CKEDITOR.instances.ckeditorEmailBody.insertText(`{${value}}`);
  }

  updateEmailProp(key, data) {
    this.email = { ...this.email, [key]: data };
  }

  setBusy(status = true) {
    this.isBusy = !this.disableBusy && status;
  }

  save() {
    this.setBusy();
    this.onSaveTemplate({
      data: this.email,
      callback: (status, data) => {
        this.setBusy(status);
        this.modalInstance.close(data);
      },
    });
  }
}
