import template from './applicationWidgets.html';
import controller from './applicationWidgetsCtrl';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  controller,
  bindings: {
    loanAppId: '<',
    fileGeneralDetails: '<',
    scenarioDetails: '<',
    applicants: '<',
    onGamePlanValidate: '&',
  },
};
