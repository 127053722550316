import { compareSections } from 'Common/utilities/bluestone';
import moment from 'moment';

export function validateInnerSubsectionsForUI(innerSection) {
  return (
    (innerSection && {
      name: innerSection.Name,
      code: innerSection.Code,
      attributes: {
        errorMessage: innerSection.Attributes.ErrorMessage.Title,
        ids: innerSection.Attributes.Ids,
      },
    }) ||
    false
  );
}

export function loanAppSections(innerSection, uiService = {}) {
  return {
    sectionName: innerSection.SectionName,
    sectionCode: innerSection.Code,
    applicantErrorProps: compareSections(
      'applicantSections',
      innerSection.Code,
    ) && {
      borrowerAge: validateInnerSubsectionsForUI(innerSection.BorrowerAge),
      borrowers: validateInnerSubsectionsForUI(innerSection.Borrowers),
      borrowerAddress: validateInnerSubsectionsForUI(
        innerSection.BorrowerAddress,
      ),
      borrowerContact: validateInnerSubsectionsForUI(
        innerSection.BorrowerContact,
      ),
      borrowerEmployment: validateInnerSubsectionsForUI(
        innerSection.BorrowerEmployment,
      ),
      borrowerResidence: validateInnerSubsectionsForUI(
        innerSection.BorrowerResidence,
      ),
      borrowerNZResidence: validateInnerSubsectionsForUI(
        innerSection.BorrowerNZResidence,
      ),
      guarantorContact: validateInnerSubsectionsForUI(
        innerSection.GuarantorContact,
      ),
      previousEmploymentPast: validateInnerSubsectionsForUI(
        innerSection.PreviousEmploymentPast,
      ),
      guarantorEmployment: validateInnerSubsectionsForUI(
        innerSection.GuarantorEmployment,
      ),
      guarantorPreviousEmploymentPast: validateInnerSubsectionsForUI(
        innerSection.GuarantorPreviousEmploymentPast,
      ),
      guarantorAddress: validateInnerSubsectionsForUI(
        innerSection.GuarantorAddress,
      ),
      borrowerMobilePhone: validateInnerSubsectionsForUI(
        innerSection.BorrowerMobilePhone,
      ),
      borrowerName: validateInnerSubsectionsForUI(innerSection.BorrowerName),
      guarantorName: validateInnerSubsectionsForUI(innerSection.GuarantorName),
      dependentName: validateInnerSubsectionsForUI(innerSection.DependentName),
      borrowerTitle: validateInnerSubsectionsForUI(innerSection.BorrowerTitle),
      guarantorTitle: validateInnerSubsectionsForUI(
        innerSection.GuarantorTitle,
      ),
      guarantorMobilePhone: validateInnerSubsectionsForUI(
        innerSection.GuarantorMobilePhone,
      ),
      NZBN: validateInnerSubsectionsForUI(innerSection.NZBN),
      countryEmploymentHistory: validateInnerSubsectionsForUI(
        innerSection.CountryEmploymentHistory,
      ),
      countryRelatedParties: validateInnerSubsectionsForUI(
        innerSection.CountryRelatedParties,
      ),
      countryAddressHistory: validateInnerSubsectionsForUI(
        innerSection.CountryAddressHistory,
      ),
      countryFinancialPosition: validateInnerSubsectionsForUI(
        innerSection.CountryFinancialPosition,
      ),
      borrowerEmploymentDates: validateInnerSubsectionsForUI(
        innerSection.BorrowerEmploymentDates,
      ),
      borrowerEmploymentCurrent: validateInnerSubsectionsForUI(
        innerSection.BorrowerEmploymentCurrent,
      ),
      guarantorEmploymentCurrent: validateInnerSubsectionsForUI(
        innerSection.GuarantorEmploymentCurrent,
      ),
      guarantorEmploymentDates: validateInnerSubsectionsForUI(
        innerSection.GuarantorEmploymentDates,
      ),
      borrowerTrustMultiple: validateInnerSubsectionsForUI(
        innerSection.BorrowerTrustMultiple,
      ),
      borrowerCompanyMultiple: validateInnerSubsectionsForUI(
        innerSection.BorrowerCompanyMultiple,
      ),
      borrowerCompanyNoGuarantor: validateInnerSubsectionsForUI(
        innerSection.BorrowerCompanyNoGuarantor,
      ),
      borrowerTrustNoGuarantor: validateInnerSubsectionsForUI(
        innerSection.BorrowerTrustNoGuarantor,
      ),
      borrowerCompanyTrust: validateInnerSubsectionsForUI(
        innerSection.BorrowerCompanyTrust,
      ),
      borrowerEmail: validateInnerSubsectionsForUI(innerSection.BorrowerEmail),
      guarantorEmail: validateInnerSubsectionsForUI(
        innerSection.GuarantorEmail,
      ),
      incompleteApplicantAddress: validateInnerSubsectionsForUI(
        innerSection.IncompleteApplicantAddress,
      ),
      incompleteEmployerAddress: validateInnerSubsectionsForUI(
        innerSection.IncompleteEmployerAddress,
      ),
      incompleteAssetAddress: validateInnerSubsectionsForUI(
        innerSection.IncompleteAssetAddress,
      ),
      borrowerEmploymentIncome: validateInnerSubsectionsForUI(
        innerSection.BorrowerEmploymentIncome,
      ),
      guarantorEmploymentIncome: validateInnerSubsectionsForUI(
        innerSection.GuarantorEmploymentIncome,
      ),
      borrowerEmploymentNZBN: validateInnerSubsectionsForUI(
        innerSection.BorrowerEmploymentNZBN,
      ),
      guarantorEmploymentNZBN: validateInnerSubsectionsForUI(
        innerSection.GuarantorEmploymentNZBN,
      ),
      dependentDateOfBirth: validateInnerSubsectionsForUI(
        innerSection.DependentDateOfBirth,
      ),
      dependentMaximumAge: validateInnerSubsectionsForUI(
        innerSection.DependentMaximumAge,
      ),
      applicantMultipleCurrentAddress: validateInnerSubsectionsForUI(
        innerSection.ApplicantMultipleCurrentAddress,
      ),
      employmentEmployerName: validateInnerSubsectionsForUI(
        innerSection.EmploymentEmployerName,
      ),
      employmentIncomeVerification: validateInnerSubsectionsForUI(
        innerSection.EmploymentIncomeVerification,
      ),
    },
    fundingErrorProps: getLoanStructureFundingDetailsSectionValidationObject(
      innerSection,
      uiService,
    ),
    fundingDetailsErrorProps: getFundingDetailsSectionValidationObject(
      innerSection,
      uiService,
    ),
    declarationErrorPops: compareSections(
      'declarationSections',
      innerSection.Code,
    ) && {
      consentCreditCheck: validateInnerSubsectionsForUI(
        innerSection.ConsentCreditCheck,
      ),
    },
    fatalErrors:
      compareSections('fatalErrors', innerSection.Code) &&
      innerSection.SubSection.map(validateInnerSubsectionsForUI),
  };
}

export function getLoanStructureFundingDetailsSectionValidationObject(
  innerSection,
  uiService = {},
) {
  return (
    (!uiService.loanAppSplitFundingAndStructure &&
      compareSections('fundingSections', innerSection.Code) && {
        securityType: validateInnerSubsectionsForUI(innerSection.SecurityType),
        security: validateInnerSubsectionsForUI(innerSection.Security),
        interestRateType: validateInnerSubsectionsForUI(
          innerSection.InterestRateType,
        ),
        interestOnlyTerm: validateInnerSubsectionsForUI(
          innerSection.InterestOnlyTerm,
        ),
        loanTerm: validateInnerSubsectionsForUI(innerSection.LoanTerm),
        loanSplits: validateInnerSubsectionsForUI(innerSection.LoanSplits),
        totalLendingRequired: validateInnerSubsectionsForUI(
          innerSection.TotalLendingRequired,
        ),
        lvr: validateInnerSubsectionsForUI(innerSection.LVR),
        ownerOccupiedPurposeMaximumLVR: validateInnerSubsectionsForUI(
          innerSection.OwnerOccupiedPurposeMaximumLVR,
        ),
        investmentPurposeMaximumLVR: validateInnerSubsectionsForUI(
          innerSection.InvestmentPurposeMaximumLVR,
        ),
        loanSplitAmount: validateInnerSubsectionsForUI(
          innerSection.LoanSplitAmount,
        ),
        loanSplitProducts: validateInnerSubsectionsForUI(
          innerSection.LoanSplitProducts,
        ),
        SecurityAddress: validateInnerSubsectionsForUI(
          innerSection.SecurityAddress,
        ),
        securityPreApproval: validateInnerSubsectionsForUI(
          innerSection.SecurityPreApproval,
        ),
        proposedLendingExceedsMaxLoan: validateInnerSubsectionsForUI(
          innerSection.ProposedLendingExceedsMaxLoan,
        ),
        incompleteSecurityAddress: validateInnerSubsectionsForUI(
          innerSection.IncompleteSecurityAddress,
        ),
      }) ||
    (!!uiService.loanAppSplitFundingAndStructure &&
      compareSections('fundingSections', innerSection.Code) && {
        interestRateType: validateInnerSubsectionsForUI(
          innerSection.InterestRateType,
        ),
        interestOnlyTerm: validateInnerSubsectionsForUI(
          innerSection.InterestOnlyTerm,
        ),
        loanTerm: validateInnerSubsectionsForUI(innerSection.LoanTerm),
        loanSplits: validateInnerSubsectionsForUI(innerSection.LoanSplits),
        totalLendingRequired: validateInnerSubsectionsForUI(
          innerSection.TotalLendingRequired,
        ),
        lvr: validateInnerSubsectionsForUI(innerSection.LVR),
        ownerOccupiedPurposeMaximumLVR: validateInnerSubsectionsForUI(
          innerSection.OwnerOccupiedPurposeMaximumLVR,
        ),
        investmentPurposeMaximumLVR: validateInnerSubsectionsForUI(
          innerSection.InvestmentPurposeMaximumLVR,
        ),
        loanSplitAmount: validateInnerSubsectionsForUI(
          innerSection.LoanSplitAmount,
        ),
        loanSplitProducts: validateInnerSubsectionsForUI(
          innerSection.LoanSplitProducts,
        ),
        proposedLendingExceedsMaxLoan: validateInnerSubsectionsForUI(
          innerSection.ProposedLendingExceedsMaxLoan,
        ),
      })
  );
}

export function getFundingDetailsSectionValidationObject(
  innerSection,
  uiService = {},
) {
  return (
    (!uiService.loanAppSplitFundingAndStructure && {}) ||
    (!!uiService.loanAppSplitFundingAndStructure &&
      compareSections('fundingDetailsSections', innerSection.Code) && {
        securityType: validateInnerSubsectionsForUI(innerSection.SecurityType),
        security: validateInnerSubsectionsForUI(innerSection.Security),
        SecurityAddress: validateInnerSubsectionsForUI(
          innerSection.SecurityAddress,
        ),
        securityPreApproval: validateInnerSubsectionsForUI(
          innerSection.SecurityPreApproval,
        ),
        incompleteSecurityAddress: validateInnerSubsectionsForUI(
          innerSection.IncompleteSecurityAddress,
        ),
      })
  );
}

export function blueStoneValidationForUI(validations, uiService = {}) {
  const { Sections } = validations;
  return {
    lenderId: validations.LenderID,
    familyId: validations.FamilyID,
    errorCount: validations.ErrorCount,
    isLocked: validations.IsSubmitted,
    advisersServiceEmail: validations.EmailAddress || '',
    sections:
      Sections &&
      Sections.map((section) => loanAppSections(section, uiService)),
  };
}

export function dateTestedValidationForUI(date) {
  return {
    dateTested:
      date.BlueStoneDateTested &&
      moment(date.BlueStoneDateTested).format('DD/MM/YYYY'),
  };
}
