import angular from 'angular';
import {
  setEmailObject,
  initEmailRecipients,
} from './util/customerCareEmailUtil';
import { checkSingleOrBulkEmailUtil } from './util/openSendEmailModalCtrl';

export const loadModule = () =>
  angular.module('app').controller(
    'OpenSendEmailModalCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function openSendMailModalCtrl(
      $uibModalInstance,
      $scope,
      $controller,
      customerCareObj,
      listTableData,
      tab,
      contactService,
      corporateService,
      customerCareInsuranceService,
      customerCareTemplateService,
      formErrorHandlerServices,
      ckEditorConfigService,
      currentModuleId,
      currentModuleType,
      currentModuleName,
      currentUserService,
    ) {
      const vm = this;
      angular.extend(
        vm,
        $controller('CustomerCareSendEmailModalBaseCtrl', { $scope }),
      );

      $scope.mergeFieldsParams = customerCareObj;
      $scope.recipients = { email: initEmailRecipients(customerCareObj) };
      $scope.listTableData = listTableData;
      $scope.currentModule = currentModuleId;
      $scope.customerCareType = currentModuleType;

      $scope.checkSingleOrBulkEmail = checkSingleOrBulkEmailUtil({
        $scope,
        currentUserService,
        customerCareObj,
        currentModuleName,
        tab,
        currentModuleType,
        listTableData,
      });

      $scope.selectEmailList = [];
      $scope.refreshResults = function (select) {
        const search = select.search;

        $scope.getRecipientAutoSuggestion(search);
      };

      $scope.getRecipientAutoSuggestion = function (userInput) {
        contactService
          .getRecipientAutoSuggestion(userInput)
          .then((response) => {
            $scope.withEmail = response.data;
            $scope.selectEmailList = !$scope.withEmail
              ? []
              : $scope.withEmail.reduce((accum, recipient) => {
                  if (
                    !recipient.EmailAddress ||
                    !recipient.EmailAddress.trim()
                  ) {
                    return accum;
                  }
                  $scope.selectEmail = {};
                  $scope.selectEmail.FamilyFullname = recipient.Name;
                  $scope.selectEmail.EmailAddress = recipient.EmailAddress;
                  return accum.concat($scope.selectEmail);
                }, []);
          });
      };

      $scope.sendEmail = function (form) {
        const customerCareFormValid = formErrorHandlerServices.sendEmailFormValidation(
          form,
        );
        if (!customerCareFormValid) {
          return;
        }

        setEmailObject($scope);
        $scope.email.CustomerCareType = $scope.customerCareType;
        $scope.email.IsComplete = $scope.isComplete;

        const insuranceParam = {
          tableRecordCsvId: customerCareObj.tableRecordIDCSV,
        };

        customerCareInsuranceService
          .sendEmail($scope.email, insuranceParam)
          .then((emailResponse) => {
            if (emailResponse.data) {
              const emailValid = customerCareTemplateService.customerCareResponseMsg(
                emailResponse.data,
                'EMAIL',
              );
              if (emailValid) {
                $scope.email.EmailSubject = '';
                $scope.email.EmailBody = '';
                form.$setPristine(true);

                $uibModalInstance.close({
                  success: true,
                });
              }
            }
          });
      };

      $scope.selectedTemplate = {
        UserTemplateID: 0,
        UserTemplateName: 'Blank',
        UserTemplateEmailSubject: '',
      };

      function getMergeFields() {
        $scope.familyId = customerCareObj.familyID;
        $scope.policyId = customerCareObj.policyId;
        $scope.clientId = 0;
        $scope.mergeFieldContext = 'InsuranceCustomerCare';

        corporateService
          .MergeFieldsGet(
            $scope.familyId,
            $scope.clientId,
            0,
            $scope.policyId,
            false,
            0,
            $scope.mergeFieldContext,
          )
          .then((response) => {
            $scope.emailMergeFieldList = response.data;
            $scope.mergeFieldsObj = response.data[0];
            $scope.mergeFields = [];
            $scope.mergeFields = $scope.mergeFieldsObj.MergeFields;
            for (let k = 0; k < $scope.mergeFields.length; k++) {
              if ($scope.mergeFields[k].Name === 'Full Name') {
                $scope.preferredName = $scope.mergeFields[k].Value;
              }
            }
            $scope.getEmailTemplates($scope.preferredName);
            $scope.getDefaultTemplate();
          });
      }
      getMergeFields();

      function init() {
        $scope.familyId = customerCareObj ? customerCareObj.familyID : null;
        $scope.customerCareObj = customerCareObj;

        // Ckeditor Tollbar Configuration
        $scope.options = ckEditorConfigService.getCustomerCareEmailEditorConfig();

        $scope.subject = '';
        $scope.content = '';
        $scope.checkSingleOrBulkEmail();
      }

      init();
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    },
  );
