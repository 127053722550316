import { COLOR } from 'Common/constants/colors';

export const CONTACT_STATUS = {
  LEAD: 1,
  APPLICATION: 2,
  EXISTING_CLIENT: 3,
  PROFESSIONAL_PARTNER: 4,
  PREVIOUS_CLIENT: 5,
  OPPORTUNITY: 6,
  NEW_CLIENT: 7,
};

export const CONTACT_STATUS_COLOR = {
  LEAD: '#9a86a8',
  APPLICATION: '#42b4b4',
  EXISTING_CLIENT: '#579bcb',
  PROFESSIONAL_PARTNER: '#54478e',
  PREVIOUS_CLIENT: '#cdc3d2',
  NEW_CLIENT: COLOR.BROWN,
};

export const CONTACT_STATUS_LIST = [
  {
    id: CONTACT_STATUS.LEAD,
    name: 'Lead',
    color: CONTACT_STATUS_COLOR.LEAD,
  },
  {
    id: CONTACT_STATUS.APPLICATION,
    name: 'Application',
    color: CONTACT_STATUS_COLOR.APPLICATION,
  },
  {
    id: CONTACT_STATUS.EXISTING_CLIENT,
    name: 'Existing Client',
    color: CONTACT_STATUS_COLOR.EXISTING_CLIENT,
  },
  {
    id: CONTACT_STATUS.PROFESSIONAL_PARTNER,
    name: 'Professional Partner',
    color: CONTACT_STATUS_COLOR.PROFESSIONAL_PARTNER,
  },
  {
    id: CONTACT_STATUS.PREVIOUS_CLIENT,
    name: 'Previous Client',
    color: CONTACT_STATUS_COLOR.PREVIOUS_CLIENT,
  },
  {
    id: CONTACT_STATUS.NEW_CLIENT,
    name: 'New Client',
    color: CONTACT_STATUS_COLOR.NEW_CLIENT,
  },
];

export const RESIDENCY_STATUS_IDS = {
  RESIDENT: 1,
};
