import { getSpecificBrokerNotes } from 'Common/utilities/loanApplication';
import { BROKER_NOTES_ID } from 'Common/constants/loanAppBrokerNotes';
import { BROKER_DECLARATIONS } from 'Common/constants/questions';

export const assignBrokerDeclarationNotesUtil = (
  scope,
  brokerNotesResponse,
) => {
  if (!scope || !brokerNotesResponse) {
    return;
  }
  scope.assess = {
    DeclarationOverview: {},
  };

  scope.assess.DeclarationOverview.brokerNotes = getSpecificBrokerNotes(
    brokerNotesResponse,
    BROKER_NOTES_ID.BROKER_DECLARATION,
  );
};

export const isCompletedFaceToFace = ({ $scope }) => {
  const faceToFaceAnswer =
    $scope.declarationObjSet[BROKER_DECLARATIONS.FACE_TO_FACE.QUESTION_ID];
  const validNoSubQuestion = faceToFaceAnswer === true;
  const validWithSubQuestion =
    faceToFaceAnswer === false &&
    !!$scope.declarationObjSet[
      BROKER_DECLARATIONS.FACE_TO_FACE_DETAILS.QUESTION_ID
    ];
  return validNoSubQuestion || validWithSubQuestion;
};

export const validateConflictInterest = ({
  isValueDefined,
  getValue,
}) => () => {
  return (
    isValueDefined(
      BROKER_DECLARATIONS.IDENTIFIED_CONFLICT_INTEREST.QUESTION_ID,
    ) &&
    ((getValue(BROKER_DECLARATIONS.IDENTIFIED_CONFLICT_INTEREST.QUESTION_ID) ===
      true &&
      isValueDefined(
        BROKER_DECLARATIONS.IDENTIFIED_CONFLICT_INTEREST.SUBQUESTION.DETAILS,
      )) ||
      getValue(BROKER_DECLARATIONS.IDENTIFIED_CONFLICT_INTEREST.QUESTION_ID) ===
        false)
  );
};
