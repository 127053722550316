import template from './referralDetails.html';
import controller from './referralDetailsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    referral: '<',
  },
};
