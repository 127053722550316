import template from './addressLookup.html';
import controller from './addressLookupCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    address: '<',
    addressForm: '<',
    isExistingAddress: '=',
    searchLabel: '<?',
    callerId: '@?',
    disableAutoFocus: '<?',
    withAddressDivider: '<?',
    addressRequired: '<?',
    isMobileView: '<?',
    isFormXs: '<?',
    isDisabled: '<?',
    showSearchAgain: '<?',
    onUpdateAddress: '&',
    handler: '&',
  },
};
