export default class NoteTypeModalLauncherCtrl {
  constructor(modalRenderService, configService) {
    'ngInject';

    this.configService = configService;
    this.modalRenderService = modalRenderService;
  }

  openNoteTypeModal() {
    const modalInstance = this.modalRenderService.openNoteTypeModal();
    modalInstance.result.then((updatedNoteTypes) => {
      this.onClose({ updatedNoteTypes });
    });
  }

  $onInit() {
    this.isNoteTypeEnabled = this.configService.feature.noteType;
  }
}
