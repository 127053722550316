import { WORKBENCH_REQUIREMENT_TEMPLATE_TYPE } from 'Common/constants/workbenchRequirementTemplateType';

class WorkbenchRequirementTemplatesModalCtrl {
  $onInit() {
    this.tabs = [
      {
        id: WORKBENCH_REQUIREMENT_TEMPLATE_TYPE.LOAN,
        name: 'LOAN REQUIREMENTS',
      },
      {
        id: WORKBENCH_REQUIREMENT_TEMPLATE_TYPE.INSURANCE,
        name: 'INSURANCE REQUIREMENTS',
      },
    ];
  }

  close() {
    if (!this.modalInstance) {
      return;
    }
    this.modalInstance.close();
  }
}

export default WorkbenchRequirementTemplatesModalCtrl;
