import angular from 'angular';
import _ from 'lodash';

import { saveEmailSignature } from './util/emailSignatureModalCtrl';

export const loadModule = () =>
  angular
    .module('app')
    .controller('EmailSignatureModalCtrl', function EmailSignatureModalCtrl(
      $scope,
      $uibModalInstance,
      generalService,
      toaster,
      broadcastDataService,
      loginUseInfo,
      email,
      options,
    ) {
      $scope.signatureContent = '';
      $scope.loginUseInfo = loginUseInfo;
      $scope.email = email;
      $scope.options = options;
      /* Methods */
      angular.extend($scope, {
        closeSignatureModal() {
          $uibModalInstance.dismiss('cancel');
        },

        removeBackgroundInSignature(signatureToTrimParam) {
          let signatureToTrim = signatureToTrimParam;
          signatureToTrim = _.replace(signatureToTrim, '<div></div>', ' ');
          signatureToTrim = _.replace(
            signatureToTrim,
            "style='display:inline-block; vertical-align: top; margin-left: 0px;'",
            "style='position: relative; bottom: 35px'",
          );

          return signatureToTrim;
        },

        saveEmailSignature() {
          saveEmailSignature({ $scope, generalService, broadcastDataService });
        },

        getEmailSignature() {
          generalService
            .getEmailSignature($scope.loginUseInfo.BrokerId)
            .then((response) => {
              if (response.data != null) {
                const signature = response.data;
                $scope.email.Content = `<p> </br>${signature}</br></p>`;
                $scope.signatureContent = `<p>${$scope.removeBackgroundInSignature(
                  signature,
                )}</p>`;
              }
            });
        },
      });
      /* initializing */
      $scope.signatureContent = `<p>${$scope.removeBackgroundInSignature(
        broadcastDataService.userSignature,
      )}</p>`;
    });
