import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import {
  FIXED_RATE_EXPIRES_ID,
  CUSTOMER_CARE_TYPES,
  TAB_FIXED_RATE,
  COMPLETED_EVENT_TYPE,
} from 'Common/constants/customerCareModules';
import {
  onActionSelectedUtil,
  sendingResultUtil,
  modalParamsUtil,
  initValuesUtil,
  processCustomDateRange,
} from './util/customerCareTabsCtrl';
import {
  sortColumn,
  sortType,
  addSelectedCustomerCareIds,
  isRetainAllAdviser,
  adviserChanged,
} from 'Assets/js/controllers/customerCare/util/customerCareCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('FixedRateCtrl', function fixedRateCtrlFun(
      $scope,
      $uibModal,
      $timeout,
      NgTableParams,
      $localStorage,
      customerCareService,
      customerCareTabsService,
      downloadDataService,
      currentUserService,
      configService,
      loanService,
    ) {
      $scope.unlockingCondition = () => {
        return (
          ($scope.showLockScreen &&
            $scope.unlockingCustomerCare &&
            $scope.autoMarketingSubscription.subscribe_FixedRateExpiry) ||
          (currentUserService.isAssistantType && $scope.unlockingCustomerCare)
        );
      };
      $scope.reloadTimeout = null;
      $scope.isUnlockingFixedRate = $scope.unlockingCondition();
      $scope.customerCareAdviserList = isRetainAllAdviser(
        $scope.customerCareAdviserList,
        $scope.isUnlockingFixedRate,
      );
      $scope.mainObj = {};
      $scope.mainObj.adviserObject =
        $scope.customerCareAdviserList && $scope.customerCareAdviserList.length
          ? $scope.customerCareAdviserList[0]
          : 0;
      $scope.customerCareAdviserList = [...$scope.customerCareAdviserList];
      $scope.mainObj.dateRange = 60;
      $scope.mainObj.ShowCompleted = false;
      $scope.currentUserService = currentUserService;
      $scope.customerCareTabsService = customerCareTabsService;
      $scope.initValues = initValuesUtil($scope);
      $scope.exportService = 'exportFixedRateExpiry';
      $scope.selectedFixedRateIds = [];
      $scope.currentPage = 1;
      $scope.totalCount = 0;
      $scope.sortColumn = '';
      $scope.sortType = '';
      $scope.requiredParams = {
        get: () => ({
          pageSize: $scope.totalCount,
          sortType: $scope.sortType,
          sortColumn: $scope.sortColumn,
          toDate: $scope.mainObj.toDate,
          fromDate: $scope.mainObj.formDate,
          adviserId: $scope.mainObj.adviserObject.familyId,
          selectedRowIds: $scope.selectedFixedRateIds,
        }),
      };
      $scope.addSelectedFixedRateIds = addSelectedCustomerCareIds(
        $scope.selectedFixedRateIds,
      );
      $scope.reloadTable = _.noop();

      $scope.showUnlockedFixedRate = () => {
        const isAdviserReadOnly = _.get(
          $scope.mainObj,
          'adviserObject.isSubscribedToCustomerCareType',
          false,
        );
        return $scope.isUnlockingFixedRate && isAdviserReadOnly;
      };
      $scope.customerCareTabsService.reloadCustomerCareFixedRateValues = ({
        showLockScreen,
        customerCareAdviserList,
      }) => {
        $scope.reloadTimeout = $timeout(() => {
          $scope.showLockScreen = showLockScreen;
          $scope.isUnlockingFixedRate = $scope.unlockingCondition();
          $scope.customerCareAdviserList = isRetainAllAdviser(
            customerCareAdviserList,
            $scope.isUnlockingFixedRate,
          );
          $scope.mainObj.adviserObject = _.get(
            $scope.customerCareAdviserList,
            '[0]',
            {},
          );
          $timeout.cancel($scope.reloadTimeout);
        });
      };
      $scope.loadFixedRateTable = () => {
        $scope.fixedRateTableParams = new NgTableParams(
          {
            page: 1,
            count: 10,
            sorting: {
              ExpiryDate: 'asc',
            },
          },
          {
            counts: [],
            total: 0,
            getData(params) {
              const adviserFamilyId = _.get(
                $scope.mainObj,
                'adviserObject.familyId',
              );
              if (!adviserFamilyId && adviserFamilyId !== 0) {
                return;
              }
              $scope.isLoading = true;
              $scope.sortColumn = sortColumn(params.orderBy);
              $scope.sortType = sortType(params.sorting);
              $scope.currentPage = params.page();
              return customerCareService
                .FixedRateExpiryListGet(
                  adviserFamilyId,
                  $scope.mainObj.formDate,
                  $scope.mainObj.toDate,
                  params.page(),
                  params.count(),
                  $scope.sortColumn,
                  $scope.sortType,
                  $scope.mainObj.ShowCompleted,
                )
                .then(
                  (response) => {
                    if (response && response.fixedRateExpiryList) {
                      $scope.fixedRateListTable = response.fixedRateExpiryList;
                      _.map($scope.fixedRateListTable, (item) => {
                        // eslint-disable-next-line sonarjs/no-duplicate-string
                        const today = moment().format('D MMMM YYYY');
                        const exDate = moment(item.ExpiryDate).format(
                          'D MMMM YYYY',
                        );

                        if (today === exDate) {
                          item.IsDueToday = true;
                        } else {
                          item.IsDueToday = false;
                        }
                        item.isChecked = $scope.selectedFixedRateIds.includes(
                          item.loanStructureID,
                        );
                      });
                      if (typeof response.totalCount !== 'undefined') {
                        params.total(response.totalCount);
                      } else {
                        params.total(0);
                      }
                      $scope.checkedTableData = [];
                      $scope.isLoading = false;
                      $scope.totalCount = response.totalCount;
                      return $scope.fixedRateListTable;
                    }
                    $scope.isLoading = false;
                  },
                  () => {},
                );
            },
          },
        );

        $scope.reloadTable = () => {
          $scope.fixedRateTableParams.reload();
        };
      };

      $scope.onActionSelected = onActionSelectedUtil($scope);
      $scope.adviserChanged = () => {
        adviserChanged(
          $scope.selectedFixedRateIds,
          $scope.fixedRateTableParams,
        );
      };
      // Date Range Filter
      $scope.dateRangeFilter = () => {
        if (!$scope.mainObj.dateRange) {
          return $scope.loadFixedRateTable();
        }
        $scope.mainObj.formDate = moment().format('D MMMM YYYY');
        $scope.mainObj.toDate = moment()
          .add(Number($scope.mainObj.dateRange), 'days')
          .format('D MMMM YYYY');
        $scope.selectedFixedRateIds.splice(
          0,
          $scope.selectedFixedRateIds.length,
        );
        $scope.loadFixedRateTable();
      };

      // Set Fixed Rate as Complete
      $scope.setFixedRateCompilation = (fixedRate) => {
        if (!fixedRate || !Object.keys(fixedRate).length) {
          return;
        }
        const expDate = moment(fixedRate.expiryDate).format('D MMM');
        const notificationHeader = `Fixed Rate Expires (${expDate}) Completed`;
        const $postData = [
          {
            CustomerCareType: 'FIXED_RATE_EXPIRY',
            FamilyID: 0,
            LoanID: fixedRate.loanID,
            LoanStructureId: fixedRate.loanStructureID,
            IsComplete: fixedRate.isComplete,
            NotificationHeader: notificationHeader,
          },
        ];

        if (fixedRate.isComplete) {
          const forFixedRateCustomerCare = {
            loanModalTab: 'loan-splits',
            fixedRateCompilation: $postData,
            hideSidebar: true,
            isFromCustomerCare: true,
            completedEventTypeId: COMPLETED_EVENT_TYPE.FIXED_RATE_EXPIRY,
            loanStructureIdFromCC: fixedRate.loanStructureID,
          };
          const params = {
            size: 'lg',
            windowClass: 'customer-care-trigger',
            familyId: fixedRate.familyID,
            loanId: fixedRate.loanID,
            forFixedRateCustomerCare,
          };

          loanService.openLoanInformationModal(params).then(() => {
            $scope.fixedRateTableParams.reload();
          });
          return;
        }

        customerCareService.setCompeletion($postData).then(
          () => {
            $scope.fixedRateTableParams.reload();
          },
          () => {},
        );
      };

      // Open modal For Get Fixed Rate Detail in Mobile
      $scope.openModalForGetFixedRateDetail = (fixedRate, size) => {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/fixed_rate_filter_modal.html',
          controller: 'GetFixedRateDetailModalCtrl',
          size,
          resolve: {
            fixedRate: () => fixedRate,
          },
        });
      };
      // Manage Template

      $scope.sendingResult = sendingResultUtil($scope);

      // openEmail
      $scope.openSendEmailModal = (size, fixedRateObj, source) => {
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_email_modal.html',
          resolve: {
            fixedRateListTable() {
              return $scope.fixedRateListTable;
            },
            fixedRateObj: () => fixedRateObj,
            source: () => source,
            isInsurance: () => false,
            tab: () => null,
          },
          controller: 'FixedRateSendEmailModalCtrl',
          size,
        });
        $scope.sendingResult(modalInstance, 'reloadTable');
      };
      $scope.openSendMailModal = (size, fixedRateObj, source) => {
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_mail_modal.html',
          resolve: {
            listTableData() {
              return $scope.fixedRateListTable;
            },
            sendMailObj: () => fixedRateObj,
            source: () => source,
            currentModuleId: modalParamsUtil.currentModuleType(
              FIXED_RATE_EXPIRES_ID,
            ),
            currentModuleType: modalParamsUtil.currentModuleType(
              CUSTOMER_CARE_TYPES.FIXED_RATE_EXPIRES,
            ),
            currentModuleName: modalParamsUtil.currentModuleName(
              'Fixed Rate Expires',
            ),
            isInsurance: modalParamsUtil.isInsurance(false),
            tab: modalParamsUtil.tab(null),
          },
          controller: 'OpenSendMailModalCtrl',
          size,
        });

        $scope.sendingResult(modalInstance, 'reloadTable');
      };
      $scope.openSMSModal = (size, fixedRateObj, source) => {
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_sms_modal.html',
          resolve: {
            fixedRateListTable() {
              return $scope.fixedRateListTable;
            },
            fixedRateObj: () => fixedRateObj,
            source: () => source,
            isInsurance: () => false,
            tab: () => null,
          },
          controller: 'FixedRateSMSModalCtrl',
          size,
        });

        $scope.sendingResult(modalInstance, 'reloadTable');
      };
      const loadDdlFromDashboardWidget = () => {
        if (
          typeof $localStorage.dropdownVal === 'undefined' ||
          $localStorage.dropdownVal === ''
        ) {
          return;
        }
        $scope.dashboardBigDdlObj = $localStorage.dropdownVal;
        if ($scope.dashboardBigDdlObj.OptionDisplayName === 'Next 90 Days') {
          $scope.mainObj.dateRange = '90';
        }
        if ($scope.dashboardBigDdlObj.OptionDisplayName === 'Next 30 Days') {
          $scope.mainObj.dateRange = '30';
        }
        if ($scope.dashboardBigDdlObj.OptionDisplayName === 'Next 60 Days') {
          $scope.mainObj.dateRange = '60';
        }
        if ($scope.dashboardBigDdlObj.OptionDisplayName === 'Next 7 Days') {
          $scope.mainObj.dateRange = '60';
        }

        $localStorage.dropdownVal = '';
      };

      const init = () => {
        $scope.reloadCallback = $scope.loadFixedRateTable;
        loadDdlFromDashboardWidget();
        processCustomDateRange($scope);
        $scope.dateRangeFilter();
        $scope.loadFixedRateTable();
        $scope.initValues({ configService, TAB_DETAILS: TAB_FIXED_RATE });
      };

      init();
      $scope.$on('$destroy', () => {
        $timeout.cancel($scope.reloadTimeout);
        $scope.reloadTimeout = null;
      });
    });
