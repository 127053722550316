import angular from 'angular';
import swal from 'sweetalert';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('AccountAccreditationCtrl', function AccountAccreditationCtrl(
      $scope,
      SweetAlert,
      corporateService,
      onboardingService,
      $stateParams,
      $filter,
      toaster,
      uiService,
      corporateModelService,
      configService,
      currentUserService,
    ) {
      const familyId = $stateParams.familyId;
      $scope.showHelloBookCorp = uiService.showHelloBookCorp;
      $scope.currentUserService = currentUserService;
      $scope.isCorpAccreditationTableEnabled =
        configService.feature.corpAccreditationTable;
      $scope.dateArray = [];

      const getLenderAccreditation = () => {
        onboardingService
          .getFamilyLenderAccreditation(familyId)
          .then((response) => {
            $scope.lenderList = response.data;
          });
      };
      getLenderAccreditation();
      corporateModelService.getAdviserAccreditationStatusList().then((data) => {
        $scope.accreditationStatus = data;
      });
      $scope.lendersUnique = [];
      $scope.lendersUniqueInputs = [];
      $scope.lendersUniqueInputsEdit = [];
      $scope.tableHover = function (lenderId) {
        $scope.lendersUnique = [];
        $scope.lendersUnique[lenderId] = true;
      };
      $scope.lenderDynamicTemplate = {
        templateUrl: 'lenderDates.html',
      };
      $scope.showDates = false;
      $scope.editAcceditation = function (lender) {
        $scope.currentLenderId = lender.LenderID;
        $scope.lendersUniqueInputsEdit[lender.LenderID] = true;
        $scope.showDates = true;
        $scope.dateArray[lender.LenderID].dateSent =
          parseInt($filter('date')(lender.DateSent, 'yyyy'), 10) === 1
            ? ''
            : new Date(lender.DateSent);
        $scope.dateArray[lender.LenderID].dateReceived =
          parseInt($filter('date')(lender.DateReceived, 'yyyy'), 10) === 1
            ? ''
            : new Date(lender.DateReceived);
      };
      $scope.cancelEdit = function (id) {
        $scope.showDates = false;
        $scope.lendersUniqueInputsEdit[id] = false;
      };
      $scope.setFamilyLenderAccreditation = function (lenderData) {
        const lenderId = lenderData.LenderID;
        const data = {
          LenderID: lenderId,
          LenderName: lenderData.LenderName,
          Lender_IsActive: lenderData.Lender_IsActive,
          IssueCodes: $scope.lendersUniqueInputs[lenderId].issueCodes || '',
          TransferCodes:
            $scope.lendersUniqueInputs[lenderId].transferCodes || '',
          Type: lenderData.Type,
          StatusId: $scope.lendersUniqueInputs[lenderId].statusId || '',
          DateSent: $filter('date')(
            $scope.dateArray[lenderId].dateSent,
            'MM-dd-yyyy',
          ),
          DateReceived: $filter('date')(
            $scope.dateArray[lenderId].dateReceived,
            'MM-dd-yyyy',
          ),
        };

        onboardingService
          .setFamilyLenderAccreditation(familyId, data)
          .then((response) => {
            if (response.data > 0) {
              $scope.lendersUniqueInputsEdit = [];
              $scope.showDates = false;
              getLenderAccreditation();
              swal('Success', 'Accreditation successfully saved!', 'success');
            } else {
              swal(
                'Failed',
                'Failed in saving accreditation! Please try again.',
                'error',
              );
            }
          });
      };
      $scope.convertDate = function (date) {
        return parseInt($filter('date')(date, 'yyyy'), 10) === 1
          ? ''
          : `<img ng-src="assets/images/icon/icon-cal.svg" /> ${$filter('date')(
              date,
              'dd MM yyyy',
            )}`;
      };
      const deleteLender = (lenderId, lenderName) => {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: `Remove ${lenderName} from accreditation list.`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              corporateService
                .deleteFamilyLenderAccreditation(familyId, lenderId)
                .then((response) => {
                  if (response.data > 0) {
                    toaster.pop(
                      'success',
                      'Client Deleted',
                      'Client successfully deleted from contacts.',
                    );
                    getLenderAccreditation();
                  }
                });
            }
          },
        );
      };
      $scope.deleteLender = deleteLender;
    });
