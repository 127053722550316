import template from './sendTaxInvoiceModal.html';
import SendTaxInvoiceModalCtrl from './sendTaxInvoiceModalCtrl';
import './style.scss';

export default {
  template,
  controller: SendTaxInvoiceModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanAppId: '<',
    lenderEmail: '<',
    attachmentLabel: '<',
    taxInvoiceDetails: '<',
    invoiceDetails: '<',
  },
};
