import { getDatePickerConfig } from 'Common/utilities/date';
import { LOAN_TERM } from 'Common/constants/assets';
import { LIABILITY_TYPE } from 'Common/constants/liabilityType';
import { parseToInt10 } from 'Common/utilities/parse';

export const setUpNZServiceability = ({
  $scope,
  loanStructureService,
  optionsService,
  isAssetLiability,
}) => {
  $scope.getDropdownTerms = () => {
    const noMonths = {
      name: '0 Months',
      value: 0,
    };
    const noYears = {
      name: '0 Years',
      value: 0,
    };
    !isAssetLiability &&
      optionsService.getLoanTerms().then((res) => {
        $scope.yearlyTerms = [noYears].concat(res);
      });

    loanStructureService.getLoanTermMonthly().then((res) => {
      $scope.monthlyTerms = [noMonths].concat(res);
    });

    $scope.loanTermOptions = [
      {
        id: LOAN_TERM.PRINCIPAL_INTEREST,
        label: 'Principal & Interest',
      },
      {
        id: LOAN_TERM.INTEREST_ONLY,
        label: 'Interest Only',
      },
    ];
  };
  $scope.getDropdownTerms();

  $scope.dateConfig = getDatePickerConfig({
    dateOptions: {
      showWeeks: false,
    },
  });

  $scope.toggleCalendar = (newLiabilities) => {
    return (newLiabilities.showDateCalendar = !newLiabilities.showDateCalendar);
  };

  $scope.showRepaymentButtonGroup = (liabilityTypeID) => {
    const liabilities = [
      LIABILITY_TYPE.HOME_LOAN,
      LIABILITY_TYPE.MORTGAGE,
      LIABILITY_TYPE.LINE_OF_CREDIT,
      LIABILITY_TYPE.LOAN_AS_GUARANTOR,
    ];
    return liabilities.includes(parseToInt10(liabilityTypeID));
  };
};

export const liabilityDatesMapper = (liability = {}) => {
  const { InterestOnlyStartDate, LoanStartDate } = liability;
  return {
    InterestOnlyStartDate: InterestOnlyStartDate
      ? new Date(InterestOnlyStartDate)
      : null,
    LoanStartDate: LoanStartDate ? new Date(LoanStartDate) : null,
  };
};
