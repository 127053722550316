import template from './linkedTermDate.html';
import controller from './linkedTermDateCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    model: '<',
    termList: '<',
    useDynamicList: '<',
    onUpdateField: '&',
  },
};
