import { toastError, toastSuccess } from 'Common/utilities/alert';
import {
  getModalTitle,
  getSaveFundsObj,
  getModalButtonTitle,
} from 'Common/utilities/loanFundingDetails';

import { mapDepositSourceModel } from 'Common/utilities/depositSource';

const MODAL_TYPE = {
  NEW: 'new',
  EDIT: 'edit',
};

export default class FundFormCtrl {
  constructor(
    optionsService,
    loanScenarioService,
    loanAppSharedData,
    fundingCalculatorService,
  ) {
    'ngInject';

    this.optionsService = optionsService;
    this.loanAppSharedData = loanAppSharedData;
    this.loanScenarioService = loanScenarioService;
    this.fundingCalculatorService = fundingCalculatorService;
  }

  $onInit() {
    this.modalType = this.fund ? MODAL_TYPE.EDIT : MODAL_TYPE.NEW;
    this.title = getModalTitle(this.modalType);
    this.buttonTitle = getModalButtonTitle(this.modalType);

    this.fundModel = {
      brokerEventId: this.securityBrokerEventId,
      id: 0,
      fundingType: 'FUNDS_AVAILABLE',
      fundingParticulars: '',
      fundingValue: 0,
      isOnlyValueEditable: false,
      description: '',
    };

    this.getDepositTypeList();
  }

  getDepositTypeList() {
    this.optionsService.DepositType(this.lendingCategoryId).then((res) => {
      if (!res || !res.data) {
        return;
      }
      this.depositSourceList = res.data;
      this.fundModel = mapDepositSourceModel({
        model: this.fundModel,
        fund: this.fund,
        despositList: this.depositSourceList,
        isEdit: this.modalType === MODAL_TYPE.EDIT,
      });
    });
  }

  saveFunds() {
    const payload = getSaveFundsObj(this.modalType, this.fundModel) || {};
    this.fundingCalculatorService
      .setFundingCalculatorFunds(payload)
      .then(({ data }) => {
        if (!data) {
          return toastError();
        }
        toastSuccess('Funds saved sucessfully');
        this.loanAppSharedData.refreshSecurityFundsSummary = 1;
        this.close();
      })
      .catch(toastError);
  }

  close() {
    this.modalInstance.close();
  }
}
