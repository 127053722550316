export const SECTION_CODE = {
  applicantSections: '100',
  fundingSections: '200', // refers to loan / funding section or loan structure section
  declarationSections: '300',
  fatalErrors: '400',
  fundingDetailsSections: '500',
};

export const ONLINE_LODGEMENT_SECTION_ERROR_PROPS = {
  APPLICANT: 'applicantErrorProps',
  LOAN_STRUCTURE_FUNDING: 'fundingErrorProps',
  FUNDING_DETAILS: 'fundingDetailsErrorProps',
  DECLARATION: 'declarationErrorPops',
};

export const LOAN_SECTION = [
  ONLINE_LODGEMENT_SECTION_ERROR_PROPS.APPLICANT,
  ONLINE_LODGEMENT_SECTION_ERROR_PROPS.LOAN_STRUCTURE_FUNDING,
  ONLINE_LODGEMENT_SECTION_ERROR_PROPS.FUNDING_DETAILS,
  ONLINE_LODGEMENT_SECTION_ERROR_PROPS.DECLARATION,
];

export const ERRORS_EXCLUDED = ['bannerColor', 'hasErrors'];

export const CODE_PARTIES_ERROR = [
  '102',
  '103',
  '104',
  '112',
  '113',
  '119',
  '120',
  '140',
];
export const CODE_GUARANTORS_ERROR = ['130', '114', '105', '141'];
export const ERROR_CODE = {
  AGE: '102',
  CITIZENSHIP_RESIDENCY: '103',
  TEMPORARY_RESIDENCE: '112',
  BORROWER_NAME_CONTAIN_NUMBERS: '113',
  INVALID_TITLE: '119',
  GUARANTORS_TITLE: '130',
  MOBILE_GUARANTORS: '114',
  MOBILE_BORROWERS: '120',
  CONTACT_POINTS_BORROWERS: '104',
  CONTACT_POINTS_GUARANTORS: '105',
  BORROWERS_EMAIL: '140',
  GUARANTORS_EMAIL: '141',
  SECURITY_ADDRESS: '213',
  SECURITY_SAVE_ADDRESS: '216',
  ASSET_ADDRESS: '146',
  FUNDING_REQUIRED: ['207', '208', '214', '213', '216'],
  PROPOSED_LENDING: [
    '201',
    '202',
    '203',
    '204',
    '205',
    '206',
    '209',
    '210',
    '211',
    '215',
  ],
  GUARANTORS: ['114', '105', '109', '114', '130', '129', '141'],
  BORROWERS: [
    '101',
    '102',
    '103',
    '104',
    '112',
    '113',
    '115',
    '119',
    '120',
    '122',
    '126',
    '123',
    '124',
    '127',
    '140',
  ],
  DEPENDENTS: ['128', '147', '148'],
  EMPLOYMENT: [
    '106',
    '107',
    '108',
    '116',
    '117',
    '118',
    '131',
    '132',
    '142',
    '143',
    '145',
    '138',
    '139',
    '150',
    '151',
  ],
  ADDRESS: ['110', '111', '135', '144', '149'],
  EXISTING_REAL_ESTATE_ASSETS: ['137', '146'],
  LOAN_FACILITIES: ['212'],
  RELATED_PARTIES: ['136'],
};

export const VALIDATE_ATTRIBUTES = {
  BORROWERS: [
    {
      attributeName: 'borrowerAge',
      propName: 'hasErrorAge',
    },
    {
      attributeName: 'borrowerResidence',
      propName: 'hasErrorResidency',
    },
    {
      attributeName: 'borrowerNZResidence',
      propName: 'hasErrorNZResidence',
    },
    {
      attributeName: 'borrowerName',
      propName: 'hasErrorCannotContainNumbers',
    },
    {
      attributeName: 'borrowerTitle',
      propName: 'hasErrorTitle',
    },
    {
      attributeName: 'borrowerMobilePhone',
      propName: 'hasErrorMobile',
    },
    {
      attributeName: 'borrowerContact',
      propName: 'hasErrorContactPoints',
    },
    {
      attributeName: 'borrowerEmail',
      propName: 'hasErrorEmail',
    },
  ],
  GUARANTORS: [
    {
      attributeName: 'guarantorName',
      propName: 'hasErrorCannotContainNumbers',
    },
    {
      attributeName: 'guarantorTitle',
      propName: 'hasErrorTitle',
    },
    {
      attributeName: 'guarantorMobilePhone',
      propName: 'hasErrorMobile',
    },
    {
      attributeName: 'guarantorContact',
      propName: 'hasErrorContactPoints',
    },
    {
      attributeName: 'guarantorEmail',
      propName: 'hasErrorEmail',
    },
  ],
};
