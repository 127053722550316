import { COLOR, RGBA } from 'Common/constants/colors';

export const BASIC_PIE_OPTION = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  tooltips: {
    displayColors: false,
  },
};

export const BAR_LINE_OPTION = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          display: false,
        },
        barPercentage: 0.5,
        ticks: {
          fontColor: COLOR.RUM,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          fontFamily: 'sans-serif',
          fontStyle: 'normal',
        },
        beginAtZero: true,
      },
    ],
    yAxes: [
      {
        id: 'main-y-axis',
        type: 'linear',
        display: true,
        position: 'left',
        gridLines: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          fontColor: COLOR.RUM,
          beginAtZero: true,
          precision: 0,
          padding: 10,
          fontFamily: 'sans-serif',
          fontStyle: 'normal',
        },
        beginAtZero: true,
      },
      {
        id: 'sub-y-axis',
        type: 'linear',
        display: true,
        position: 'right',
        lineWidth: 0,
        gridLines: {
          drawBorder: false,
        },
        ticks: {
          fontColor: COLOR.RUM,
          beginAtZero: true,
          precision: 0,
          fontFamily: 'sans-serif',
          fontStyle: 'normal',
        },
      },
    ],
  },
  tooltips: {
    displayColors: false,
  },
};

export const SETTLEMENT_BAR_CONFIG = {
  cornerRadius: 2,
  responsive: true,
  maintainAspectRatio: false,
  borderWidth: 0,
  title: {
    display: true,
    text: 'SUM of TOTAL AMOUNT',
    position: 'left',
    fontColor: COLOR.RUM,
    fontSize: 11,
  },
  scales: {
    xAxes: [
      {
        categoryPercentage: 0.45,
        barPercentage: 1,
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          fontColor: COLOR.RUM,
          fontFamily: 'sans-serif',
          fontStyle: 'normal',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
          zeroLineColor: COLOR.MISCHKA,
          color: COLOR.MISCHKA,
          lineWidth: 0.6,
          zeroLineWidth: 0.6,
        },
        ticks: {
          beginAtZero: true,
          precision: 0,
          fontColor: COLOR.RUM,
          fontFamily: 'sans-serif',
          fontStyle: 'normal',
          padding: 10,
        },
      },
    ],
  },
  tooltips: {
    displayColors: false,
    intersect: false,
    position: 'nearest',
  },
};

export const THREE_YEAR_COLOR = [
  COLOR.VICTORIA,
  COLOR.JUNGLE_GREEN,
  COLOR.CURIOUS_BLUE,
];

export const THREE_YEARCHART = [
  {
    backgroundColor: COLOR.VICTORIA,
    borderColor: COLOR.VICTORIA,
  },
  {
    backgroundColor: COLOR.JUNGLE_GREEN,
    borderColor: COLOR.JUNGLE_GREEN,
  },
  {
    backgroundColor: COLOR.CURIOUS_BLUE,
    borderColor: COLOR.CURIOUS_BLUE,
  },
];

export const COMMON_PIE_COLORS = [
  COLOR.VICTORIA,
  COLOR.BUTTER_CUP,
  COLOR.TERRACOTTA,
  COLOR.MARINER,
];

export const STACKED_CHART_CONFIG = {
  responsive: true,
  maintainAspectRatio: false,
  borderWidth: 0,
  scales: {
    xAxes: [
      {
        stacked: true,
        categoryPercentage: 0.85,
        barPercentage: 0.5,
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          fontColor: COLOR.RUM,
          fontFamily: 'sans-serif',
          fontStyle: 'normal',
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          drawBorder: false,
          zeroLineColor: COLOR.MISCHKA,
          color: COLOR.MISCHKA,
          lineWidth: 0.6,
          zeroLineWidth: 0.6,
        },
        ticks: {
          fontColor: COLOR.RUM,
          beginAtZero: true,
          precision: 0,
          padding: 10,
          fontFamily: 'sans-serif',
          fontStyle: 'normal',
        },
      },
    ],
  },
  tooltips: {
    mode: 'nearest',
    intersect: false,
  },
};

export const COMMON_CHART_COLORS = [
  COLOR.VICTORIA,
  COLOR.JUNGLE_GREEN,
  COLOR.CURIOUS_BLUE,
  COLOR.BUTTER_CUP,
  COLOR.TERRACOTTA,
  COLOR.MARINER,
];

export const TARGET_TYPE_CHARTS = {
  responsive: true,
  maintainAspectRatio: false,
  borderWidth: 0,
  tooltips: {
    mode: 'nearest',
    intersect: false,
    caretSize: 0,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          fontColor: COLOR.RUM,
          fontFamily: 'sans-serif',
          fontStyle: 'normal',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          tickMarkLength: 0,
          drawBorder: false,
          zeroLineColor: COLOR.MISCHKA,
          color: COLOR.MISCHKA,
          lineWidth: 0.6,
          zeroLineWidth: 0.6,
        },
        ticks: {
          beginAtZero: true,
          fontColor: COLOR.RUM,
          fontFamily: 'sans-serif',
          fontStyle: 'normal',
          padding: 10,
        },
      },
    ],
  },
  elements: {
    line: {
      tension: 0,
    },
  },
};

export const TARGET_TYPE_OVERRIDE = [
  {
    type: 'line',
    spanGaps: true,
    fill: false,
    borderWidth: 1.5,
    backgroundColor: COLOR.HIPPIE_BLUE,
    borderColor: COLOR.HIPPIE_BLUE,
    borderDash: [4, 5],
    pointBackgroundColor: COLOR.HIPPIE_BLUE,
    pointBorderColor: COLOR.HIPPIE_BLUE,
    pointHoverBackgroundColor: COLOR.HIPPIE_BLUE,
    pointHoverBorderColor: COLOR.HIPPIE_BLUE,
  },
  {
    type: 'line',
    fill: true,
    borderWidth: 1.5,
    borderColor: COLOR.TRENDY_PINK,
    backgroundColor: RGBA.GRAY_SUIT_OPAQUE,
    pointBackgroundColor: COLOR.TRENDY_PINK,
    pointBorderColor: COLOR.TRENDY_PINK,
    pointHoverBackgroundColor: COLOR.TRENDY_PINK,
    pointHoverBorderColor: COLOR.TRENDY_PINK,
  },
];

export const BASIC_DOUGHNUT_OPTION = {
  responsive: true,
  maintainAspectRatio: false,
  cutoutPercentage: 70,
  layout: {
    padding: {
      left: 15,
      right: 15,
      top: 15,
      bottom: 15,
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};
