import { capitalizeTextFromCamelCase } from 'Common/utilities/string';
import { toastSaveSuccess } from 'Common/utilities/alert';

export default class ProductsCtrl {
  constructor($window, accreditationService) {
    'ngInject';

    this.$window = $window;
    this.accreditationService = accreditationService;
  }

  $onInit() {
    this.insurance = [];
    this.lending = [];
    this.products = {};
    this.loadingLending = false;
    this.loadingInsurance = false;
    this.onPopulateProducts();
  }

  getProductState(stateType) {
    const insurance = !this.insurance.length || this.loadingInsurance;
    const lending = !this.lending.length || this.loadingLending;
    const setStateType = { insurance, lending };
    return !!setStateType[stateType];
  }

  resetState() {
    this.loadingLending = false;
    this.loadingInsurance = false;
  }

  onPopulateProducts() {
    this.accreditationService.getProducts(this.familyId).then(({ data }) => {
      const { insurance, lending } = data;
      this.products = data;
      this.insurance = Object.entries(insurance).map((product) => ({
        prodType: product[0],
        label: capitalizeTextFromCamelCase(product[0]),
        value: product[1],
      }));
      // eslint-disable-next-line sonarjs/no-identical-functions
      this.lending = Object.entries(lending).map((product) => ({
        prodType: product[0],
        label: capitalizeTextFromCamelCase(product[0]),
        value: product[1],
      }));
    });
  }

  onProductChange(setProdType = '', prodData = {}) {
    const { prodType, value } = prodData;
    const currenValues = this.products;
    const updatedValues = {
      ...currenValues[setProdType],
      ...{
        [prodType]: value,
      },
    };
    this.products = {
      ...currenValues,
      ...{
        [setProdType]: updatedValues,
      },
    };
    this.loadingLending = setProdType === 'lending';
    this.loadingInsurance = setProdType === 'insurance';
    this.accreditationService.setProducts(this.products).then(({ data }) => {
      data &&
        (() => {
          toastSaveSuccess();
          this.resetState();
        })();
    });
  }
}
