import template from './brandingUploads.html';
import controller from './brandingUploadsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    userAdviserOrgId: '<',
    className: '<',
    autoUpdateNav: '<',
    showSummaryPreview: '<',
  },
};
