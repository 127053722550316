const configDocumentTitle = [
  '$rootScope',
  ($rootScope) => {
    $rootScope.$watch(
      () => {
        const pageTitle = $rootScope.pageTitle;
        if (pageTitle) {
          return pageTitle();
        }
        return undefined;
      },
      (title) => {
        if (title) {
          // eslint-disable-next-line angular/document-service
          window.document.title = title;
        }
      },
    );
  },
];
export default configDocumentTitle;
