import template from './generalMobileSearch.html';
import controller from './generalMobileSearchCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    searchText: '<',
  },
};
