export default class ComplaintCorpCommentsModal {
  dismiss() {
    this.modalInstance.dismiss();
  }

  save() {
    const { corpComments } = this;

    this.modalInstance.close({ corpComments });
  }
}
