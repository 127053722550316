import { QUESTION_DISPLAY } from 'Common/constants/questionAnswerType';

export const ASSET_INSURANCE_DETAILS = {
  INSURER: {
    QUESTION_ID: 160,
    QUESTION_ID_NZ: 156,
    DISPLAY_OPTION: QUESTION_DISPLAY.TEXT_ONLY,
  },
  INSURANCE_BROKER: {
    QUESTION_ID: 161,
    QUESTION_ID_NZ: 157,
    DISPLAY_OPTION: QUESTION_DISPLAY.TEXT_ONLY,
  },
  POLICY_NUMBER: {
    QUESTION_ID: 162,
    QUESTION_ID_NZ: 158,
    DISPLAY_OPTION: QUESTION_DISPLAY.TEXT_ONLY,
  },
  EXPIRY_DATE: {
    QUESTION_ID: 163,
    QUESTION_ID_NZ: 159,
    DISPLAY_OPTION: QUESTION_DISPLAY.TEXT_ONLY,
  },
};

export const NO_FINANCIALS_QUESTIONS = {
  IS_NO_FINANCIALS: {
    QUESTION_ID: 143,
    DISPLAY_OPTION: QUESTION_DISPLAY.YES_NO,
  },
  CLIENT_CREDIT_WORTHINESS: {
    QUESTION_ID: 144,
    DISPLAY_OPTION: QUESTION_DISPLAY.TEXT_ONLY,
  },
  PROPOSAL_MEET_TERMS: {
    QUESTION_ID: 145,
    DISPLAY_OPTION: QUESTION_DISPLAY.YES_NO,
  },
};

export const BROKER_DECLARATIONS = {
  IDENTIFIED_CONFLICT_INTEREST: {
    QUESTION_ID: 113,
    SUBQUESTION: {
      DETAILS: 114,
    },
  },
  PRODUCT_NOT_SUITABLE: {
    QUESTION_ID: 115,
    DISPLAY_OPTION: QUESTION_DISPLAY.YES_NO,
  },
  FACE_TO_FACE: {
    QUESTION_ID: 228,
    DISPLAY_OPTION: QUESTION_DISPLAY.YES_NO,
  },
  FACE_TO_FACE_DETAILS: {
    QUESTION_ID: 229,
    DISPLAY_OPTION: QUESTION_DISPLAY.TEXT_ONLY,
  },
};

export const INSURANCE_REFERRALS_QUESTIONS = {
  LIFE_HEALTH: 297,
  LH_DETAILS: 298,
  LH_DECLINE: 299,
  GENERAL_INSURANCE: 300,
  GI_DETAILS: 301,
  GI_DECLINE: 302,
  PENSION_TRANSFERS: 303,
  ASSET_FINANCE: 304,
  CURRENCY_EXCHANGE: 305,
  KIWI_SAVER: 306,
};
