/* global $ */
import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { assetFinanceIgnore } from 'Common/utilities/security';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { toastError, toastWarning } from 'Common/utilities/alert';
import { LENDER_FILTER_CATEGORY } from 'Common/constants/lenderConstants';
import {
  QUESTION_TYPE,
  LIST_OF_QUESTIONS,
} from 'Common/constants/customerObjectives';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  generateFamilyList as utilGenerateFamilyList,
  generateBorrowerList as utilGenerateBorrowerList,
} from 'Common/utilities/loanApplication';
import { mapDepositSourceModel } from 'Common/utilities/depositSource';
import {
  getLoanFacilitiesTitle,
  getInterestOnlyTerm,
  getLoanStructureSet,
  getModalTitle,
  getModalButtonTitle,
  getLenderName,
  getAssetFinanceCategory,
  getLoanOptions,
  getSecurityList,
  getnewLoanAmount,
  getTotalLoanAmount,
  getLaonDetailLmi,
  getSaveFundsObj,
  getFundingDownpayment,
  getLoanAppLenderDetailObject,
  getTermNameFromLoanTermList,
  isTermLessThanThirty,
} from 'Common/utilities/loanFundingDetails';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import { LOAN_APP_LOAN_FUNDING } from 'Common/constants/performance';
import { performance } from 'Common/utilities/perfume';
import { loanAppSectionData } from 'Common/utilities/dataLayer';
import * as ctrlUtils from '../util/clientAccess/loanFundingDetails';
import { setCompletionStatus } from './../util/loanAppCtrl';
import { AU_LENDER } from 'Common/constants/commonLenderIds';

export const loadModule = () =>
  angular.module('app').controller(
    'LoanAppLoanFundingDetailsCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function LoanAppLoanFundingDetailsCtrl(
      $rootScope,
      $scope,
      $timeout,
      $interval,
      contactService,
      loanScenarioService,
      lodgementSubmissionService,
      loanApplicationServices,
      watcherService,
      timeoutService,
      toaster,
      $state,
      $stateParams,
      $uibModal,
      optionsService,
      SweetAlert,
      loanAppSharedData,
      fundingCalculatorService,
      loanProfilerService,
      broadcastDataService,
      financialsSharedService,
      uiService,
      fundingCalculatorSharedData,
      loanAppDetailsService,
      objectivesQuestionService,
      currentUserService,
      configService,
      loanScenarioModelService,
      opportunityLoanAppService,
      blueStoneSubmissionService,
      crmConfirmation,
      modalRenderService,
      $q,
      nextGenLoanAppService,
      $window,
    ) {
      // eslint-disable-next-line sonarjs/cognitive-complexity
      const initializeLoanDetails = () => {
        $scope.conflictInterest = {
          isQuestionExist: true,
        };
        $scope.isLoanDetailsLoaded = false;
        $scope.currentUserService = currentUserService;
        $scope.isNZ = currentUserService.isNZ;
        $scope.clickCounter = 0;
        $scope.uiService = uiService;
        $scope.loanFacilitiesPanelTitle = 'Loan Facilities';
        $scope.financialsSharedService = financialsSharedService;
        $scope.LIST_OF_QUESTIONS = LIST_OF_QUESTIONS;
        const LOAN_TERM = '30';
        $scope.lenderListFilter = '';
        const {
          productDiscountRate,
          requirementsAndObjectives,
          productFinderEditEnhancements,
          lmiFromAirtable,
          bidLoanAppChangesRationale,
          bidLoanAppChangesProducts,
          bidLoanAppChangesDeclarations,
          assetFinance,
          lendersByAccreditation,
          aolServicingCalc,
          targetMarketDetermination,
        } = configService.feature;

        $scope.launchingCBA = false;
        $scope.showBidLoanAppChangesDeclarations = bidLoanAppChangesDeclarations;
        $scope.inOpportunityPage = $state.includes('app.opportunity');
        $scope.getLoanAppFormWarningValue = () => {
          return $scope.inOpportunityPage
            ? $rootScope.file.LoanAppFormWarning
            : $rootScope.LoanAppFormWarning;
        };

        $scope.loanFacilityHasProducts = false;
        $scope.productDiscountRate = productDiscountRate || 0;
        $scope.productFinderEditEnhancements = !!productFinderEditEnhancements;
        $scope.isLoanAppSplitFundingAndStructure =
          uiService.loanAppSplitFundingAndStructure;
        $scope.isReqAndObjShow = requirementsAndObjectives;
        $scope.showLendingBreakdown =
          requirementsAndObjectives && !currentUserService.isNZ;
        $scope.showLmiFromAirtable = lmiFromAirtable;
        $scope.showBidLoanAppChangesRationale = bidLoanAppChangesRationale;

        ctrlUtils.setLoanAppProductFinderFavoriteLimit(
          uiService,
          bidLoanAppChangesProducts,
        );
        $scope.isFundingQuestionsLoaded = false;
        $scope.loanTermList = [];
        $scope.loanAppSharedData = loanAppSharedData;
        $scope.borrowingCapacity = {
          totalFamilyMonthlyIncome: 0,
          totalFamilyMonthlyExpense: 0,
          totalFamilyAsset: 0,
          totalFamilyLiability: 0,
          totalContribution: 0,
          borrowerList: [],
          contributionList: [],
        };
        $scope.bidLoanAppChangesValidation =
          uiService.bidLoanAppChangesValidation;
        ctrlUtils
          .getLoanTerms({
            $scope,
            optionsService,
            isIncludeMonths: true,
          })
          .then(() => {
            $scope.getTermNameFromLoanTermList = getTermNameFromLoanTermList({
              loanTermList: $scope.loanTermList,
            });
          });
        const initializeProposedLendingFunc = () => {
          optionsService
            .question(QUESTION_TYPE.FUNDING_DETAILS.name)
            .then((response) => {
              $scope.fundingQuestions = (response && response.data) || [];
              $scope.consolidationQuestion = $scope.fundingQuestions.filter(
                (o) =>
                  o.QuestionId === LIST_OF_QUESTIONS.EXPLAINED_CREDIT_CARD ||
                  o.QuestionId === LIST_OF_QUESTIONS.DISCUSSED_CREDIT_CARD,
              );
              $scope.getDebtConsolidation();
              $scope.isFundingQuestionsLoaded = true;
            });

          $scope.getDebtConsolidation = () => {
            loanApplicationServices
              .getLoanObjectives($scope.loanAppId)
              .then((response) => {
                const objectivesData = response.data || [];
                $scope.consolidationQuestion.map((item) => {
                  const answerData = objectivesData.find(
                    (o) => o.QuestionId === item.QuestionId,
                  );
                  item.answer = objectivesQuestionService.getAnswer(answerData);
                  return item;
                });
              });
          };

          $scope.debtConsolidationDone = () => {
            return (
              !!(
                $scope.consolidationQuestion &&
                $scope.consolidationQuestion.length &&
                $scope.consolidationQuestion.find((o) => o.answer)
              ) || false
            );
          };

          $scope.setDebtConsolidation = (item) => {
            const sendData = {
              LoanScenarioId: $scope.loanAppId,
              FamilyId: $scope.familyId,
              QuestionSectionId: 0,
              QuestionId: item.QuestionId,
              DisplayOptionId: item.QuestionDisplayTypeId,
              answerBool: [item.answer],
            };
            loanApplicationServices.postLoanObjectives(sendData);
          };
        };

        $scope.GetMonthlyValue = (amount, frequencyName) => {
          let amt;

          switch (frequencyName) {
            case 'Yearly':
              amt = amount / 12;
              break;
            case 'Half-yearly':
              amt = amount / 6;
              break;
            case 'Quarterly':
              amt = amount / 3;
              break;
            case 'Fortnightly':
              amt = amount * 2;
              break;
            case 'Weekly':
              amt = amount * 4;
              break;
            default:
              amt = amount;
          }
          return Number(amt.toFixed(2));
        };

        $scope.getBorrowersIndex = (borrowers) => {
          let index = -1;
          if (borrowers.length > 0) {
            if (borrowers.length === 1) {
              _.forEach(borrowers, (borrower) => {
                index = $scope.borrowingCapacity.borrowerList.findIndex(
                  (borrowerItem) =>
                    borrowerItem.personId === borrower.BorrowerID,
                );
              });
            } else {
              index = $scope.borrowingCapacity.borrowerList.findIndex(
                (borrowerItem) =>
                  borrowerItem.personId === '0' &&
                  borrowerItem.firstName === 'Joint',
              );
            }
          }
          return index;
        };

        $scope.resetBorrowersDetails = () => {
          $scope.borrowingCapacity = {
            totalFamilyMonthlyIncome: 0,
            totalFamilyMonthlyExpense: 0,
            totalFamilyAsset: 0,
            totalFamilyLiability: 0,
            totalContribution: 0,
            borrowerList: [],
            contributionList: [],
          };
        };

        $scope.createBorrowersList = () => {
          $scope.resetBorrowersDetails();

          const involvedPartyPerson =
            isValidObjectValue(
              () => loanAppSharedData.loanAppApplicants.InvolvedPartyPerson,
            ) || null;

          const borrowers = utilGenerateBorrowerList(involvedPartyPerson);

          if (borrowers.length > 1) {
            borrowers.push({
              firstName: 'Joint',
              familyID: $scope.familyId,
              personId: '0',
            });
          }
          _.forEach(borrowers, (borrower) => {
            borrower.income = 0;
            borrower.expense = 0;
            borrower.asset = 0;
            borrower.liability = 0;
          });
          return borrowers;
        };

        $scope.getLiabilityDetails = () => {
          loanScenarioService
            .LiabilityInfoGet($stateParams.loanAppId)
            .then(({ data }) => {
              if (!data) {
                return;
              }
              _.forEach(data, (liability) => {
                if (!liability?.overview?.FinancialInfo) {
                  return;
                }
                $scope.borrowingCapacity.totalFamilyLiability +=
                  liability.overview.TotalAmount;
                _.forEach(liability.overview.FinancialInfo, (financialInfo) => {
                  const index = $scope.getBorrowersIndex(
                    $scope.financialsSharedService.mapBorrowers(
                      financialInfo?.Borrowers,
                    ),
                  );
                  if (index < 0) {
                    return;
                  }
                  $scope.borrowingCapacity.borrowerList[index].liability +=
                    financialInfo.Amount;
                });
              });
            });
        };

        $scope.getIncomeDetails = () => {
          loanScenarioService
            .IncomeInfoGet($scope.loanAppId)
            .then(({ data }) => {
              if (!data) {
                return;
              }
              _.forEach(data, (income) => {
                if (!income?.overview?.FinancialInfo) {
                  return;
                }

                $scope.borrowingCapacity.totalFamilyMonthlyIncome +=
                  income.overview.TotalAmountPerMonth;
                _.forEach(income.overview.FinancialInfo, (financialInfo) => {
                  const index = $scope.getBorrowersIndex(
                    $scope.financialsSharedService.mapBorrowers(
                      _.get(financialInfo, 'Borrowers'),
                    ),
                  );
                  if (index < 0) {
                    return;
                  }
                  const currIncome = $scope.GetMonthlyValue(
                    financialInfo.Amount,
                    financialInfo.FrequencyName,
                  );
                  $scope.borrowingCapacity.borrowerList[
                    index
                  ].income += currIncome;
                });
              });
            });
        };

        $scope.getAssetDetails = () => {
          loanScenarioService
            .AssetInfoGet($stateParams.loanAppId)
            .then(({ data }) => {
              if (!data) {
                return;
              }
              _.forEach(data, (asset) => {
                if (!asset?.overview?.FinancialInfo) {
                  return;
                }

                $scope.borrowingCapacity.totalFamilyAsset +=
                  asset.overview.TotalAmount;

                _.forEach(asset.overview.FinancialInfo, (financialInfo) => {
                  const index = $scope.getBorrowersIndex(
                    $scope.financialsSharedService.mapBorrowers(
                      _.get(financialInfo, 'Borrowers'),
                    ),
                  );
                  if (index < 0) {
                    return;
                  }
                  $scope.borrowingCapacity.borrowerList[index].asset +=
                    financialInfo.Value;
                });
              });
            });
        };

        $scope.getExpenseDetails = () => {
          loanScenarioService
            .ExpenseInfoGet($stateParams.loanAppId)
            .then(({ data }) => {
              if (!data) {
                return;
              }

              _.forEach(data, (expenses) => {
                if (!expenses?.overview?.FinancialInfo) {
                  return;
                }

                $scope.borrowingCapacity.totalFamilyMonthlyExpense +=
                  expenses.overview.TotalAmountPerMonth;
                const financialExpenses = expenses.overview.FinancialInfo.filter(
                  (o) => o.Amount > 0,
                );

                _.forEach(financialExpenses, (financialInfo) => {
                  const index = $scope.getBorrowersIndex(
                    $scope.financialsSharedService.mapBorrowers(
                      _.get(financialInfo, 'Borrowers'),
                    ),
                  );
                  if (index < 0) {
                    return;
                  }
                  const currExpense = $scope.GetMonthlyValue(
                    financialInfo.Amount,
                    financialInfo.FrequencyName,
                  );
                  $scope.borrowingCapacity.borrowerList[
                    index
                  ].expense += currExpense;
                });
              });
            });
        };

        $scope.addContribution = (contributionName, contributionValue) => {
          $scope.borrowingCapacity.contributionList.push({
            fundType: contributionName,
            value: contributionValue,
          });
        };
        $scope.getFundingCalculatorDetails = () => {
          loanScenarioService
            .getFundingCalculatorNewBrokerEventForScenario($scope.loanAppId)
            .then((cusObjectivesCalcBrokerEventResponse) => {
              const brokerEventID = cusObjectivesCalcBrokerEventResponse.data;

              fundingCalculatorService
                .getFundingCalculatorFunds(brokerEventID)
                .then((fundsResult) => {
                  const fundsData = fundsResult;
                  _.remove(fundsData, (fund) => {
                    return fund.fundingType === 'FUNDING_REQUIRED';
                  });

                  _.forEach(fundsData, (fund) => {
                    $scope.borrowingCapacity.totalContribution +=
                      fund.fundingValue;
                    if ($scope.borrowingCapacity.contributionList.length > 0) {
                      const index = $scope.borrowingCapacity.contributionList.findIndex(
                        (contribution) =>
                          contribution.fundType === fund.fundingParticulars,
                      );

                      if (index < 0) {
                        $scope.addContribution(
                          fund.fundingParticulars,
                          fund.fundingValue,
                        );
                      } else {
                        $scope.borrowingCapacity.contributionList[
                          index
                        ].value += fund.fundingValue;
                      }
                    } else {
                      $scope.addContribution(
                        fund.fundingParticulars,
                        fund.fundingValue,
                      );
                    }
                  });
                });
            });
        };

        $scope.getBorrowersDetails = () => {
          const borrowers = $scope.createBorrowersList();
          $scope.borrowingCapacity.borrowerList = [...borrowers];
          $scope.getLiabilityDetails();
          $scope.getIncomeDetails();
          $scope.getAssetDetails();
          $scope.getExpenseDetails();
          $scope.getFundingCalculatorDetails();
        };
        $scope.generateClass = (idx) => {
          return `FeeGroup-${Math.ceil((idx + 1) / 2)}`;
        };

        blueStoneSubmissionService.extentFunction.fundingSection = $scope;
        lodgementSubmissionService.extentFunction.fundingSection = $scope;
        const { LoanDetails: loanDetails } = loanAppSharedData.loanAppSummary;
        if (!loanDetails) {
          return;
        }

        const { LendingCategoryId: lendingCategoryId } = loanDetails;
        $scope.isPersonalLoan =
          lendingCategoryId === LENDING_CATEGORY.PERSONAL_LOAN;
        $scope.isAssetFinanceOrPersonal =
          lendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE ||
          $scope.isPersonalLoan;
        $scope.lenderListFilter =
          (currentUserService.isAU &&
            LENDER_FILTER_CATEGORY[lendingCategoryId]) ||
          '';
        $scope.loanAppSharedData = loanAppSharedData;
        $scope.applicantGuarantorList =
          loanAppSharedData.loanAppSummary &&
          loanAppSharedData.loanAppSummary.ApplicantGuarantorList;
        $scope.loanAppSharedData.fundingSummary = ctrlUtils.fundingSummaryDefaultMapper(
          loanAppSharedData.loanAppSummary.FundingSummary,
        );

        const sectionObject = loanAppSharedData.getSectionObjectById(
          loanAppSharedData.completionListObject,
          SECTION_ID.LOAN_FUNDING_DETAILS,
        );
        $scope.countValids(sectionObject.IsCompleted, 'loan details');
        $scope.processLoanFundingDetailsSection = (sections = '') => {
          $scope.isLoanDetailsLoaded = true;
          performance.start(LOAN_APP_LOAN_FUNDING);
          blueStoneSubmissionService.toggleBySections(sections);

          lodgementSubmissionService.toggleBySections(sections);

          if ($scope.clickCounter !== 0) {
            return;
          }

          initializeProposedLendingFunc();

          $scope.clickCounter++;

          $scope.assetFinanceIgnore = assetFinanceIgnore;
          /*
              Below are for Security
              @NOTE : We'll use security.js for this
            */

          /*
              Below are for fees
            */
          const lenderPlace = 1;
          angular.extend($scope, {
            LoanApplicationCQPFeeStructureGet: [],
            FeesSetting: {
              displayFeesForm: false,
            },
            selectCQPFreq: ['Weekly', 'Fortnightly', 'Monthly'],
            LoanDetailsSet: {},
            selectedLender: '0',
            facility: {
              FavouriteProductData: [{ SelectedProduct: [] }],
            },
          });

          angular.extend($scope, {
            getLoanApplicationCQP: () => {
              loanScenarioService
                .CQPCommissionInfoGet($scope.loanAppId)
                .then((response) => {
                  $scope.CQP.CQPGet = response.data;
                  if (!angular.isUndefined($scope.CQP.CQPGet.LenderID)) {
                    $scope.CQP.CQPGet.LenderID = $scope.CQP.CQPGet.LenderID.toString();
                    $scope.LoanDetailsSet.Lender = $scope.CQP.CQPGet.LenderID.toString();
                  } else {
                    $scope.CQP.CQPGet.LenderID = '';
                    $scope.LoanDetailsSet.Lender = '';
                  }
                });
            },
            getLoanApplicationCQPFeeStructure: () => {
              loanScenarioService
                .getLoanApplicationCQPFeeStructure($scope.loanAppId)
                .then((response) => {
                  $scope.LoanApplicationCQPFeeStructureGet = response.data;
                });
            },
            showAddEditStructureFeesForm: (action, obj, platform) => {
              $scope.FeesSetting.displayFeesForm = !$scope.FeesSetting
                .displayFeesForm;
              if (platform === 'desktop') {
                if (action === 'edit') {
                  obj.isEditing = !obj.isEditing;
                  $scope.isFeeStructureEditingMode = true;

                  angular.extend($scope.CQP.FeeStructure, obj);
                  $scope.CQP.FeeStructure.FeeTypeID = $scope.CQP.FeeStructure.FeeTypeID.toString();
                } else if (action === 'cancel') {
                  const editingObj = _.find(
                    $scope.LoanApplicationCQPFeeStructureGet,
                    'isEditing',
                  );
                  if (editingObj) {
                    editingObj.isEditing = false;
                  }

                  $scope.isFeeStructureEditingMode = false;
                  $scope.CQP.FeeStructure = $scope.initFeeStructure();
                  $scope.FeesSetting.displayFeesForm = false;
                }
              } else if (platform === 'mobile') {
                obj.isEditing = !obj.isEditing;
              }
            },
            initFeeStructure: () => {
              return {
                LoanApplicationID: $scope.loanAppId,
                LoanApplicationCQPFeeID: 0,
                FeeTypeID: 0,
                Amount: null,
              };
            },
            FeesInit: () => {
              $scope.CQP = {
                FeeStructure: $scope.initFeeStructure(),
              };
              // lender
              contactService
                .lenderListGet(undefined, lenderPlace)
                .then((response) => {
                  $scope.lenderListGet = response.data;
                  $scope.CQP.LenderLogo = '';
                });

              $scope.getIsRefinance = () => {
                if ($scope.loanDetailsSet) {
                  $scope.loanDetailsSet.IsRefinance =
                    loanAppSharedData.isRefinance;
                }

                return requirementsAndObjectives
                  ? loanAppSharedData.isRefinancePrimaryPurpose
                  : loanAppSharedData.isRefinance;
              };

              $scope.onChangeReasonRefinance = (data) => {
                loanAppSharedData.reasonsRefinance = data;
                $scope.validateLoanDetails();
              };

              // Loan Application
              $scope.getLoanApplicationCQP();

              $scope.getLoanApplicationCQPFeeStructure();

              $scope.FundingCalculatorDetails = {};
              $scope.hasFundingCalc = false;
              // Funding Calculator
              loanScenarioService
                .linkedFundingCalculatorGet($scope.loanAppId)
                .then((linked) => {
                  const brokerEventId = linked.data;
                  if (brokerEventId) {
                    loanScenarioService
                      .fundingCalculatorDetailsGet(brokerEventId)
                      .then((response) => {
                        $scope.FundingCalculatorDetails = response.data;
                        $scope.hasFundingCalc = true;
                      });
                  }
                });
            },
            submitNewStructureFees: (form, advancedOptions, platform, obj) => {
              if (form.$valid) {
                if (platform === 'mobile') {
                  obj.Amount = parseInt(obj.Amount, 10);
                  obj.LoanApplicationID = $scope.loanAppId;
                }

                loanScenarioService
                  .setLoanApplicationCQPFeeStructure(
                    platform === 'desktop' ? $scope.CQP.FeeStructure : obj,
                  )
                  .then((response) => {
                    if (response.data) {
                      $scope.getLoanApplicationCQPFeeStructure();

                      if (platform === 'desktop') {
                        $scope.showAddEditStructureFeesForm(
                          'cancel',
                          {},
                          'desktop',
                        );
                      } else {
                        obj.isEditing = false;
                      }
                    } else {
                      toaster.pop(
                        'error',
                        'Error',
                        'Error Adding Fee Structure.',
                      );
                    }
                  })
                  .catch(toastError);
              }
            },
            deleteStructure: (type, id) => {
              SweetAlert.swal(
                {
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  title: 'Are you sure?',
                  text: `This record will be removed from your ${
                    type === 'FeeStructure' ? 'Fee' : 'Referral Fee Structure'
                  }`,
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#F68F8F',
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  confirmButtonText: 'Yes, remove it!',
                  closeOnConfirm: true,
                },
                (confirm) => {
                  if (confirm) {
                    if (type === 'FeeStructure') {
                      loanScenarioService
                        .deleteLoanApplicationCQPFeeStructure(
                          $scope.loanAppId,
                          id,
                        )
                        .then(() => {
                          $scope.getLoanApplicationCQPFeeStructure();
                        })
                        .catch(toastError);
                    } else {
                      loanScenarioService
                        .deleteLoanApplicationCQPReferralFeeStructure(
                          $scope.loanAppId,
                          id,
                        )
                        .then(() => {
                          $scope.getLoanApplicationCQPReferralFeeStructure();
                        })
                        .catch(toastError);
                    }
                  }
                },
              );
            },
          });
          // caller
          $scope.FeesInit();

          /**
           * Below are for funds available/deposit
           */
          angular.extend($scope, {
            fundsAvailable: [],
          });

          angular.extend($scope, {
            getFundsAvailable: () => {
              // Let's get the funds first for easier object manipulation
              fundingCalculatorService
                .getFundingCalculatorFunds($scope.securityBrokerEventId)
                .then((fundsResult) => {
                  // These funds will be used after parameters, using FUNDING_REQUIRED FundingType
                  const fundsData = fundsResult;
                  // remove the funds available types
                  _.remove(fundsData, (fund) => {
                    return fund.fundingType === 'FUNDING_REQUIRED';
                  });

                  $scope.fundsAvailable = fundsData;
                  $scope.fundingDownpayment = getFundingDownpayment(fundsData);

                  $rootScope.$broadcast('REFRESH_OBJECTIVES_DEPOSIT_SECTION', {
                    fundsAvailable: $scope.fundsAvailable,
                    isNew: false,
                  });
                });
            },
            saveFundsDownpayment: () => {
              $timeout.cancel($scope.paymentDetailsSetTimeout);
              $scope.paymentDetailsSetTimeout = $timeout(() => {
                const postObj = {
                  brokerEventId: $scope.securityBrokerEventId,
                  fundsId: $scope.fundingDownpayment.id || 0,
                  fundingType: 'FUNDS_AVAILABLE',
                  fundingParticulars: 'Deposit Paid',
                  fundingValue: $scope.fundingDownpayment.fundingValue,
                  description: '',
                };
                fundingCalculatorService
                  .setFundingCalculatorFunds(postObj)
                  .then(() => {
                    $scope.getFundsAvailable();
                    $scope.loanDetailsSetMethod();
                    $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                  })
                  .catch(toastError);
              }, 2000);
            },
            saveFundsAvailable: (fund) => {
              const obj = {
                brokerEventId: $scope.securityBrokerEventId,
                fundsId: fund.fundsId,
                fundingCalParametersId: fund.fundingCalParametersId,
                fundingType: 'FUNDS_AVAILABLE',
                fundingParticulars: fund.name,
                fundingValue: fund.value,
                isOnlyValueEditable: false,
              };

              fundingCalculatorService
                .setFundingCalculatorFunds(obj)
                // eslint-disable-next-line sonarjs/no-identical-functions
                .then(() => {
                  // Saved
                  $scope.getFundsAvailable();
                  $scope.loanDetailsSetMethod();
                  $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                })
                .catch(toastError);
            },
            fundsAvailableInit: () => {
              loanScenarioService
                .getFundingCalculatorNewBrokerEventForScenario($scope.loanAppId)
                .then((loanFundingCalcBrokerEventResponse) => {
                  $scope.securityBrokerEventId =
                    loanFundingCalcBrokerEventResponse.data;
                  $scope.loanAppSharedData.securityBrokerEventId =
                    $scope.securityBrokerEventId;
                  $scope.getFundsAvailable();
                });
            },
          });
          $scope.fundsAvailableInit();

          const onRefreshFundsAvailableListener = $rootScope.$on(
            'REFRESH_FUNDS_AVAILABLE_SECTION',
            (e, args) => {
              if (!args.isNew && args.fundsAvailable) {
                $scope.fundsAvailable = args.fundsAvailable;
              } else {
                $scope.getFundsAvailable();
              }
            },
          );
          $scope.$on('$destroy', () => {
            if (onRefreshFundsAvailableListener) {
              onRefreshFundsAvailableListener();
            }
          });
          $scope.setLoanFacilityHasProducts = ctrlUtils.setLoanFacilityHasProducts(
            {
              $scope,
              currentUserService,
            },
          );

          $scope.isTmdValid = () =>
            targetMarketDetermination && !currentUserService.isNZ
              ? loanAppSharedData.isTmdValid()
              : true;

          $scope.validateLoanDetails = () => {
            if ($scope.loanDetailsSet) {
              $scope.setLoanFacilityHasProducts();
              const isValidSecurity = $scope.isLoanAppSplitFundingAndStructure
                ? true
                : !!$rootScope.loanAppSecurityListCount;
              $scope.isValidLoanDetails =
                $scope.loanDetailsSet.LoanFacility.length > 0 &&
                $scope.loanFacilityHasProducts &&
                isValidSecurity;

              if ($scope.isReqAndObjShow && $scope.getIsRefinance()) {
                const hasReasonsRefinance = !!(
                  loanAppSharedData.reasonsRefinance &&
                  loanAppSharedData.reasonsRefinance.length
                );
                $scope.isValidLoanDetails =
                  $scope.isValidLoanDetails && hasReasonsRefinance;
              }
              $scope.showBidLoanAppChangesRationale &&
                ($scope.isValidLoanDetails =
                  $scope.isValidLoanDetails &&
                  !!loanAppSharedData.validRationaleAnswers);
            } else {
              $scope.isValidLoanDetails = false;
            }
            $scope.countValids($scope.isValidLoanDetails, 'loan details');

            setCompletionStatus({
              $scope,
              $timeout,
              sectionId: SECTION_ID.LOAN_FUNDING_DETAILS,
              loanAppSharedData,
              isValidSection: $scope.isValidLoanDetails && $scope.isTmdValid(),
            });

            $scope.setIsLoanDetailsStarted();
          };

          const loanDetailsSecurityWatcher = $scope.$watchGroup(
            ['loanDetailsSet', 'loanAppSecurityListCount'],
            () => {
              if ($scope.loanDetailsSet) {
                loanAppSharedData.lenderId = $scope.loanDetailsSet.LenderId;
              }
              $scope.validateLoanDetails();
            },
            true,
          );
          watcherService.addWatcher(
            loanDetailsSecurityWatcher,
            WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
          );

          const loanDetailsSecurityCountWatcher = $scope.$watchGroup(
            ['loanDetailsSet.LenderId', 'loanAppSecurityListCount'],
            (newVal, oldVal) => {
              $timeout.cancel($scope.generateLoanFacilityTimeout);
              $scope.generateLoanFacilityTimeout = $timeout(() => {
                if (
                  $rootScope.crmCountry === 'New Zealand' &&
                  newVal[1] > 0 &&
                  $scope.loanAppSecurityListCount &&
                  newVal[0] &&
                  newVal[0] !== oldVal[0] &&
                  _.isEmpty($scope.loanDetailsSet.LoanFacility) &&
                  $scope.loanAppSharedData.fundingSummary
                    .totalProposedLendingUserDefined
                ) {
                  const totalLendingRequired =
                    $scope.loanAppSharedData.fundingSummary
                      .totalProposedLendingUserDefined;

                  broadcastDataService
                    .addLoanstructureShared(
                      $stateParams.loanAppId,
                      $stateParams.familyId,
                      totalLendingRequired,
                    )
                    .then(() => {
                      $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                      $scope.loanDetailsGet();
                      $scope.updateLoanDetailsField();
                      $scope.validateLoanDetails();
                    })
                    .catch(toastError);
                }
              }, 2000);
              timeoutService.addTimeout(
                $scope.generateLoanFacilityTimeout,
                WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
              );
            },
          );
          watcherService.addWatcher(
            loanDetailsSecurityCountWatcher,
            WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
          );

          // extend models
          angular.extend($scope, {
            loanAppId: $stateParams.loanAppId,
            DepositDetails: {
              LoanApplicationId: $scope.loanAppId,
              LoanApplication_DepositId: 0,
            },
            DepositTypeList: [],
            getTotalSavings: 0,
          });

          // extend methods
          angular.extend($scope, {
            /**
             * Validates deposits section
             */
            validateDeposits: () => {
              $scope.isValidDeposits =
                _.size($scope.DepositDetails.DepositBreakDown) > 0;
              $scope.getDepositTotalSavings();
            },
            DepositGet: () => {
              loanApplicationServices
                .getLoanDeposit($scope.loanAppId)
                .then((loanDepositReponse) => {
                  angular.extend(
                    $scope.DepositDetails,
                    loanDepositReponse.data,
                  );
                });
            },

            deleteFund: (fundsId) => {
              SweetAlert.swal(
                {
                  title: 'Are you sure?',
                  text: 'This record will be removed from your fund list',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#F68F8F',
                  confirmButtonText: 'Yes, remove it!',
                  closeOnConfirm: false,
                },
                (confirm) => {
                  if (!confirm) {
                    return;
                  }
                  const params = {
                    FundsID: fundsId,
                  };
                  $scope.deleteFundingCalculatorFunds(params, true);
                },
              );
            },
            deleteFundingCalculatorFunds: (params, isDeleteFund) => {
              fundingCalculatorService
                .deleteFundingCalculatorFunds(params)
                .then(({ data }) => {
                  if (!data) {
                    return toastError();
                  }
                  const deleteFundTimeout = $timeout(() => {
                    $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                    if (isDeleteFund) {
                      $scope.getFundsAvailable();
                      SweetAlert.swal({
                        title: 'Success',
                        text: 'Fund has been been successfully deleted.',
                        type: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                      });
                    }
                  }, 100);

                  timeoutService.addTimeout(
                    deleteFundTimeout,
                    WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
                  );
                })
                .catch(toastError);
            },
            DepositInit: () => {
              loanApplicationServices.getDepositType().then((response) => {
                $scope.DepositTypeList = response.data;
              });

              $scope.DepositGet();
            },
            getDepositTotalSavings: () => {
              $scope.getTotalSavings = _.sum(
                _.map($scope.DepositDetails.DepositBreakDown, 'TotalAmount'),
              );
            },

            showAddDepositModal: (type, fundObj) => {
              $uibModal.open({
                templateUrl:
                  '/assets/views/loanApplication/clientAssess/contributions/modal/add.html',
                controller: ($uibModalInstance, modalType, fund) => {
                  $scope.modalType = modalType;
                  $scope.Title = getModalTitle(modalType);
                  $scope.SaveTitle = getModalButtonTitle(modalType);

                  $scope.getScenarioDetails = () => {
                    loanScenarioService
                      .scenarioDetailsGet($scope.loanAppId)
                      .then((res) => {
                        if (!res || !res.data) {
                          return;
                        }
                        $scope.lendingCategoryId =
                          res.data.LendingCategoryId || 0;
                        $scope.getDepositTypeList();
                      });
                  };
                  $scope.getDepositTypeList = () => {
                    optionsService
                      .DepositType($scope.lendingCategoryId)
                      .then((res) => {
                        if (!res || !res.data) {
                          return;
                        }
                        $scope.DepositSourceList = res.data;
                        $scope.FundingSet = mapDepositSourceModel({
                          model: $scope.FundingSet,
                          fund,
                          despositList: $scope.DepositSourceList,
                          isEdit: modalType === 'edit',
                        });
                      });
                  };
                  $scope.getScenarioDetails();

                  $scope.FundingSet = {
                    brokerEventId: $scope.securityBrokerEventId,
                    id: 0,
                    fundingType: 'FUNDS_AVAILABLE',
                    fundingParticulars: '',
                    fundingValue: 0,
                    isOnlyValueEditable: false,
                    description: '',
                  };

                  $scope.modalSaveFunds = () => {
                    let setObj = {};
                    setObj = getSaveFundsObj(modalType, $scope.FundingSet);

                    fundingCalculatorService
                      .setFundingCalculatorFunds(setObj)
                      .then(({ data }) => {
                        if (!data) {
                          return toastError();
                        }
                        $scope.getFundsAvailable();
                        $scope.loanDetailsSetMethod();
                        $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                        $scope.cancel();
                      })
                      .catch(toastError);
                  };

                  $scope.cancel = () => {
                    $uibModalInstance.dismiss('cancel');
                  };
                },
                size: 'sm',
                scope: $scope,
                resolve: {
                  modalType: () => {
                    return type;
                  },
                  fund: () => {
                    return type === 'edit' ? fundObj : {};
                  },
                },
              });
            },
            selectFund: (fund) => {
              $scope.showAddDepositModal('edit', fund);
            },
          });

          // caller
          $scope.DepositInit();

          /**
           *  Validates every update on object
           */
          const depositDetailsWatcher = $scope.$watch(
            'DepositDetails',
            () => {
              $scope.validateDeposits();
            },
            true,
          );
          watcherService.addWatcher(
            depositDetailsWatcher,
            WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
          );

          /*
                Below are for Proposed Lending modules
              */
          // extend models
          angular.extend($scope, {
            ProposedLendingSet: {},
          });

          // extend methods
          angular.extend($scope, {
            loanDetailsGet: (feesAlreadySet = false) => {
              return loanScenarioService
                .loanDetailsGet($scope.loanAppId)
                .then((response) => {
                  $scope.loanDetailsSet = (response && response.data) || {};

                  $scope.chooseProductButtonText = ctrlUtils.chooseProductButtonText(
                    $scope.loanDetailsSet.HasSavedProductComparison,
                  );

                  $scope.showLoadSavedComparisonButton = ctrlUtils.showLoadSavedComparisonButton(
                    $scope.loanDetailsSet.HasSavedProductComparison,
                  );

                  /*
                          Below codes are temporary
                        */
                  _.map($scope.loanDetailsSet.LoanFacility, (o) => {
                    o.SelectedProductId = null;
                    return o;
                  });

                  $scope.loanDetailsSet.LoanScenarioId = $scope.loanAppId;
                  if ($scope.loanDetailsSet.LoanAmount < 1) {
                    $scope.loanDetailsSet.LoanAmount = null;
                  }

                  if (
                    $scope.loanDetailsSet.LenderId &&
                    !_.isUndefined($scope.loanDetailsSet.LenderId)
                  ) {
                    $scope.loanDetailsSet.LenderId = String(
                      $scope.loanDetailsSet.LenderId,
                    );
                    $rootScope.loanAppLenderDetailObject = {
                      LenderId: $scope.loanDetailsSet.LenderId,
                      LenderName: $scope.loanDetailsSet.LenderName,
                      OtherLenderName: $scope.loanDetailsSet.OtherLenderName,
                      IsOnlineAvailable:
                        $scope.loanDetailsSet.IsOnlineAvailable,
                    };
                  } else {
                    $scope.loanDetailsSet.LenderId = null;
                    $rootScope.loanAppLenderDetailObject = getLoanAppLenderDetailObject();
                  }
                  if (
                    $scope.loanDetailsSet.ABSPurposeId &&
                    !_.isUndefined($scope.loanDetailsSet.ABSPurposeId)
                  ) {
                    $scope.loanDetailsSet.ABSPurposeId = String(
                      $scope.loanDetailsSet.ABSPurposeId,
                    );
                  } else {
                    $scope.loanDetailsSet.ABSPurposeId = null;
                  }

                  // Set ReasonForSelectedLender
                  if (
                    _.isUndefined($scope.loanDetailsSet.ReasonForSelectedLender)
                  ) {
                    $scope.loanDetailsSet.ReasonForSelectedLender = null;
                  }
                  // Set ReasonForLoanFacility
                  if (
                    _.isUndefined($scope.loanDetailsSet.ReasonForLoanFacility)
                  ) {
                    $scope.loanDetailsSet.ReasonForLoanFacility = null;
                  }

                  $scope.getAllProductOfAllFacilities(
                    $scope.loanDetailsSet.LoanFacility,
                    feesAlreadySet,
                  );

                  $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                  loanAppSharedData.loanAppSummary.LoanDetails = {
                    ...loanAppSharedData.loanAppSummary.LoanDetails,
                    ...$scope.loanDetailsSet,
                  };
                  loanAppSharedData.lenderId = $scope.loanDetailsSet.LenderId;
                  loanAppSharedData.otherLenderName =
                    $scope.loanDetailsSet.OtherLenderName;

                  $scope.isLoanAppAssetFinance = $scope.getLoanAppAssetFinanceStatus();
                  $scope.loanFacilitiesPanelTitle = getLoanFacilitiesTitle(
                    $scope.isLoanAppAssetFinance,
                  );
                  $scope.hideAddFacilityButton =
                    ($scope.isLoanAppAssetFinance &&
                      $scope.loanDetailsSet.LoanFacility.length > 0) ||
                    !loanAppSharedData.fundingSummary.totalSecurityValue;
                  loanAppSharedData.loanDetailsSet = $scope.loanDetailsSet;
                  loanAppSharedData.loanAppSummary.LoanDetails =
                    $scope.loanDetailsSet;
                  loanAppSharedData.isLoanDetailsSet = true;
                  $scope.loanDetailsInit();
                  $scope.validateChanges && $scope.validateChanges();
                  $scope.validateLoanDetails();
                  $scope.setIsLoanDetailsStarted();
                })
                .catch(toastError);
            },
            feesGet: ctrlUtils.feesGet(
              $scope,
              productFinderEditEnhancements,
              loanScenarioService,
              loanAppSharedData,
              loanScenarioModelService,
              loanApplicationServices,
            ),
            getAllProductOfAllFacilities: ctrlUtils.getAllProductOfAllFacilities(
              $scope,
              productFinderEditEnhancements,
              loanScenarioModelService,
              loanProfilerService,
            ),
            setTBA: (facility) => {
              loanScenarioService
                .LoanFacilityTBASet(
                  $scope.loanAppId,
                  facility.LoanStructureId,
                  facility.IsTBA,
                )
                .then(() => {})
                .catch(toastError);
            },
            loanDetailsSetMethod: (
              type,
              isOverrideTotalProposedLendingUserDefined,
            ) => {
              if (!$scope.loanDetailsSet) {
                return;
              }
              $timeout.cancel($scope.detailsSetTimeout);
              $scope.detailsSetTimeout = $timeout(() => {
                if (
                  $scope.loanDetailsSet &&
                  !$scope.loanDetailsSet.ABSPurposeId
                ) {
                  $scope.loanDetailsSet.ABSPurposeId = null;
                }
                const loanDetailsParam = _.omit(
                  $scope.loanDetailsSet,
                  'LoanFacility',
                );
                loanDetailsParam.TotalProposedLendingUserDefined =
                  $scope.loanAppSharedData.fundingSummary.totalProposedLendingUserDefined;
                loanDetailsParam.StopAutoCalc = isOverrideTotalProposedLendingUserDefined
                  ? true
                  : loanDetailsParam.StopAutoCalc;
                if ($scope.loanAppSharedData.isLmiFundUpdated) {
                  loanDetailsParam.LMI = $scope.loanAppSharedData.lmiFundValue;
                  $scope.loanAppSharedData.isLmiFundUpdated = null;
                  $scope.loanAppSharedData.lmiFundValue = null;
                } else {
                  loanDetailsParam.LMI = $scope.loanAppSharedData.isDeleteLMI
                    ? 0
                    : getLaonDetailLmi(loanDetailsParam);
                }
                loanDetailsParam.IsLMI = $scope.loanAppSharedData.isDeleteLMI
                  ? false
                  : loanDetailsParam.IsLMI;
                $scope.loanAppSharedData.isDeleteLMI = false;
                loanScenarioService
                  .loanDetailsSet(loanDetailsParam)
                  .then(() => {
                    $scope.loanDetailsGet(true);
                    $scope.validateChanges && $scope.validateChanges();
                    type === 'Lender' &&
                      opportunityLoanAppService.executeCallback(
                        'refreshOpportunityDetails',
                      );
                  })
                  .catch(toastError);
              }, 100);

              timeoutService.addTimeout(
                $scope.detailsSetTimeout,
                WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
              );
            },
            lmiSetMethodV2: (value) => {
              $scope.loanDetailsSet.IsLMI = !!value;
              $scope.lmiSetMethod();
            },
            lmiSetMethod: () => {
              $scope.loanDetailsSet.LMI = getLaonDetailLmi(
                $scope.loanDetailsSet,
              );
              const loanDetailsParam = _.omit(
                $scope.loanDetailsSet,
                'LoanFacility',
              );
              loanScenarioService
                .loanDetailsSet(loanDetailsParam)
                .then(() => {
                  if ($scope.loanDetailsSet.IsLMI) {
                    return;
                  }
                  const params = {
                    loanApplicationId: $scope.loanAppId,
                  };
                  $scope.deleteFundingCalculatorFunds(params);
                  $scope.saveFunding(0);
                })
                .catch(toastError);
            },
            deleteFacility: (loanStructureId) => {
              SweetAlert.swal(
                {
                  title: 'Are you sure?',
                  text: 'This record will be removed from your loan facility',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#F68F8F',
                  confirmButtonText: 'Yes, remove it!',
                  closeOnConfirm: true,
                },
                (confirm) => {
                  if (!confirm) {
                    return;
                  }
                  const successCallback = () => {
                    ctrlUtils.removeLMIAttachedToLoan({
                      loanAppSharedData,
                      loanScenarioService,
                      isFromLoanFacility: true,
                      loanAppId: $scope.loanAppId,
                    });

                    loanAppSharedData.isGenerateGamePlanValidationFailed &&
                      ctrlUtils.updateGPValidationProductReference({
                        nzBidLoanAppChangesValidation:
                          uiService.nzBidLoanAppChangesValidation,
                        loanAppSharedData,
                        facilityList: $scope.loanDetailsSet.LoanFacility.filter(
                          (facility) =>
                            facility.LoanStructureId !== loanStructureId,
                        ),
                      });
                  };
                  loanScenarioService
                    .loanFacilityDelete($scope.loanAppId, loanStructureId)
                    .then(({ data }) => {
                      if (!data) {
                        return toastError();
                      }
                      opportunityLoanAppService.executeCallback(
                        'refreshOpportunityDetails',
                      );
                      $scope.setCQPManuallyUpdated();
                      if (productFinderEditEnhancements) {
                        ctrlUtils
                          .deleteAllFees(
                            $scope,
                            loanScenarioModelService,
                            loanStructureId,
                          )
                          .then(() => {
                            successCallback();
                          })
                          .catch(toastError);
                      } else {
                        successCallback();
                      }

                      $scope.validateLoanDetails();
                    })
                    .catch(toastError);
                },
              );
            },
            showCBACalculator: (lenderId = 0) => {
              return (
                parseToInt10(lenderId) === AU_LENDER.COMMONWEALTH_BANK &&
                aolServicingCalc
              );
            },
            launchCBACalculator: () => {
              if (!aolServicingCalc || $scope.launchingCBA) {
                return;
              }
              $scope.launchingCBA = true;
              nextGenLoanAppService
                .aolServiceability($scope.loanAppId)
                .then(({ data }) => {
                  $window.open(data, '_blank', 'noopener, noreferrer');
                })
                .catch(toastError)
                .finally(() => ($scope.launchingCBA = false));
            },
            updateLoanDetailsField: (type, lenderId, otherLenderName) => {
              $scope.getLoanApplicationCQP();
              if (lenderId) {
                $scope.loanDetailsSet.LenderId = lenderId;
                $scope.loanDetailsSet.OtherLenderName = otherLenderName;
                const hasLoanAppSummaryDetails =
                  loanAppSharedData.loanAppSummary &&
                  loanAppSharedData.loanAppSummary.LoanDetails;
                if (hasLoanAppSummaryDetails) {
                  const {
                    LenderName,
                    IsOnlineAvailable,
                  } = $scope.loanDetailsSet;
                  const LoanDetails = {
                    LenderId: lenderId,
                    OtherLenderName: otherLenderName,
                    LenderName,
                    IsOnlineAvailable,
                  };
                  loanAppSharedData.loanAppSummary.LoanDetails = {
                    ...loanAppSharedData.loanAppSummary.LoanDetails,
                    ...LoanDetails,
                  };
                }
              }
              $scope.loanDetailsSetMethod(type);
            },
            CQPSet: () => {
              contactService
                .setLoanApplicationCQP(
                  $scope.loanAppId,
                  $scope.LoanDetailsSet.Lender,
                )
                .then((response) => {
                  if (response) {
                    $scope.getLoanApplicationCQP();
                  } else {
                    toaster.pop('error', 'Error', 'Something went wrong');
                  }
                })
                .catch(toastError);
            },
            loanDetailsInit: () => {
              $scope.listLoanFacilities = [];
              const accreditationBrokerId = _.get(
                loanAppSharedData,
                'LoanApplicationDetails.AdviserFamilyId',
                0,
              );
              contactService
                .lenderListGet(
                  true,
                  lenderPlace,
                  lendersByAccreditation,
                  accreditationBrokerId,
                )
                .then((response) => {
                  $scope.lenderList = response.data;
                  if (lendersByAccreditation) {
                    const selectedLenderId = _.get(
                      $scope,
                      'loanDetailsSet.LenderId',
                      '',
                    );
                    const isSelectedLenderExist = $scope.lenderList.some(
                      (lender) =>
                        lender && lender.ProviderId === selectedLenderId,
                    );
                    selectedLenderId &&
                      !isSelectedLenderExist &&
                      ($scope.lenderList = [
                        {
                          Category: 'Not Accredited',
                          ProviderId: selectedLenderId,
                          ProviderName: $scope.loanDetailsSet.LenderName,
                        },
                        ...$scope.lenderList,
                      ]);
                  }
                });

              optionsService.absPurpose().then((response) => {
                $scope.absPurposeList = response.data;
              });
            },
            changeSelectedLenderValue: (lender) => {
              $scope.selectedLender = lender;
            },
            getSearchProduct: (searchString) => {
              if (searchString.length >= 3) {
                return loanProfilerService
                  .getServiceabilityProductSearch(searchString)
                  .then((response) => {
                    $scope.SearchProductList = response.data;
                    $scope.productSearchList = [];
                    angular.forEach(response.data, (item) => {
                      item.FullName = `${item.FirstName} ${item.LastName}`;
                      if (
                        $scope.selectedLender !== 0 &&
                        typeof $scope.selectedLender !== 'undefined'
                      ) {
                        if (item.LenderID === $scope.selectedLender) {
                          $scope.productSearchList.push(item);
                        }
                      } else {
                        $scope.productSearchList.push(item);
                      }
                    });
                    return $scope.productSearchList;
                  })
                  .catch(toastError);
              }

              $scope.productSearchList = [];
              return $scope.productSearchList;
            },
            setFavoriteProductFromSearch: (facility, product) => {
              product.isAddedForComparison = true;
              const loanStructureID =
                facility &&
                facility.FavouriteProductData &&
                facility.FavouriteProductData.length &&
                facility.FavouriteProductData[0] &&
                facility.FavouriteProductData[0].LoanStructureID;
              if (!loanStructureID) {
                return;
              }
              const postData = {
                brokerEventID: facility.BrokerEventId,
                ProductID: product.ProductID,
                LenderID: product.LenderID,
                loanStructureID:
                  facility.FavouriteProductData[0].LoanStructureID,
                IsFavourite: product.isAddedForComparison,
              };
              loanProfilerService
                .setFavouriteProduct(postData)
                .then((response) => {
                  if (response.data === 1) {
                    $scope.getFacilityFavoriteProductList(facility);
                  } else {
                    toastError();
                  }
                })
                .catch(toastError);
            },
            openAddLoanStructureModal: (loanStructureId) => {
              if ($scope.isLoanAppAssetFinance) {
                const props = {
                  loanAppId: $scope.loanAppId,
                  loanDetails: $scope.loanDetailsSet,
                };
                const loanStructureModal = $uibModal.open({
                  template: `<new-loan-structure
                                loan-details="vm.props.loanDetails"
                                modal-instance="vm.modalInstance"
                                loan-app-id="vm.props.loanAppId">
                              </new-loan-structure>`,
                  controller: 'CommonModalPlaceholderCtrl',
                  controllerAs: 'vm',
                  size: 'md',
                  backdrop: 'static',
                  windowClass: 'new-loan-structure',
                  keyboard: false,
                  backdropClass: 'newLoanStructure-modal',
                  openedClass: 'modal-w700 modal-open',
                  resolve: {
                    props,
                  },
                });

                loanStructureModal.result.then(() => {
                  $scope.loanDetailsGet();
                  $scope.updateAssetFinanceOtherFundsReq();
                  $scope.validateLoanDetails();
                });
                return;
              }
              $scope.TotalLoanAmountCalculation = null;
              $scope.loanStructureId = loanStructureId;
              $scope.loanFacilityLmiValue =
                ($scope.loanDetailsSet && $scope.loanDetailsSet.LMI) || 0;
              $uibModal.open({
                templateUrl:
                  '/assets/views/loanApplication/clientAssess/modal/newLoanStructureModal.html',
                controller($uibModalInstance, selectOptionsService) {
                  $scope.loanProductList = [];
                  $scope.cancel = () => {
                    $uibModalInstance.dismiss('cancel');
                  };

                  optionsService.getLoanTerms(true).then((loanTermResponse) => {
                    $scope.loanTermList = loanTermResponse;
                    $scope.loanTermSelectOptions = loanTermResponse.filter(
                      (term) => isTermLessThanThirty(term),
                    );
                  });

                  optionsService.FinancialFrequency(false).then((response) => {
                    $scope.loanRepaymentFreqSelectOptions = response.data;
                  });

                  $scope.LoanStructureSet = getLoanStructureSet();
                  $scope.LoanStructureSet.LoanStructureId =
                    typeof $scope.loanStructureId === 'undefined'
                      ? 0
                      : $scope.loanStructureId;

                  if ($scope.loanDetailsSet) {
                    $scope.LoanStructureSet.loanScenarioId =
                      $scope.loanDetailsSet.LoanScenarioId;
                  }

                  if ($scope.loanFacilityLmiValue) {
                    $scope.LoanStructureSet.LMIPremium =
                      $scope.loanFacilityLmiValue;
                  }
                  const isAddLoanStructure =
                    $scope.isLoanAppSplitFundingAndStructure &&
                    $scope.loanAppSharedData.fundingSummary.deficit &&
                    !$scope.LoanStructureSet.LoanStructureId &&
                    !$scope.LoanStructureSet.BaseLoanAmount;
                  if (isAddLoanStructure) {
                    const loanAmount = parseFloat(
                      $scope.loanAppSharedData.fundingSummary.deficit,
                    ).toFixed(2);
                    $scope.LoanStructureSet.BaseLoanAmount = loanAmount;
                    $scope.TotalLoanAmountCalculation = loanAmount;
                  }
                  // For Interest Only switch selection Change
                  $scope.ChangeInterestOnlySelection = () => {
                    if (uiService.isCountry('New Zealand')) {
                      return;
                    }

                    if (
                      $scope.loanStructureId !== 0 &&
                      typeof $scope.loanStructureId !== 'undefined'
                    ) {
                      SweetAlert.swal(
                        {
                          title: 'Are you sure?',
                          text:
                            'All saved product will be removed from your list',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#F68F8F',
                          confirmButtonText: 'Yes, remove it!',
                          closeOnConfirm: false,
                        },
                        (confirm) => {
                          if (confirm) {
                            swal(
                              'Deleted',
                              'Saved product will be deleted after update loan facility.',
                              'success',
                            );
                          } else {
                            $scope.LoanStructureSet.InterestOnly = !$scope
                              .LoanStructureSet.InterestOnly;
                          }
                        },
                      );
                    }
                  };

                  $scope.getLoanFacility = () => {
                    loanScenarioService
                      .loanFacilityGet($scope.loanAppId, $scope.loanStructureId)
                      .then((response) => {
                        $scope.LoanStructureSet = response.data;
                        $scope.LoanStructureSet.StatementCycleId =
                          $scope.LoanStructureSet.StatementCycleId || 4;

                        $scope.LoanStructureSet.loanScenarioId =
                          $scope.loanDetailsSet.LoanScenarioId;
                        if ($scope.loanFacilityLmiValue) {
                          $scope.LoanStructureSet.LMIPremium = $scope
                            .LoanStructureSet.LMIPremium
                            ? $scope.LoanStructureSet.LMIPremium
                            : $scope.loanFacilityLmiValue;
                        }
                        $scope.baseAndLMIChange(true);
                        // select the owner
                        _.each($scope.ownersList, (owner) => {
                          owner.ticked = false;
                          const ownerData = _.find(
                            $scope.LoanStructureSet.Owner,
                            (obj) => {
                              return owner.BorrowerID === obj.BorrowerID;
                            },
                          );
                          if (ownerData) {
                            owner.ticked = true;
                          }
                        });
                      });
                  };

                  $scope.LoanStructureSet.loanScenarioId =
                    $scope.loanDetailsSet.LoanScenarioId;
                  $scope.baseAndLMIChange = (onload) => {
                    $scope.saveLmiToFundingParms = false;
                    $scope.LoanStructureSet.CapitaliseLMI =
                      $scope.LoanStructureSet.IsAddLMI;
                    if ($scope.LoanStructureSet.IsAddLMI && !onload) {
                      $scope.LoanStructureSet.CapitaliseLMI = true;
                    }
                    if (
                      $scope.LoanStructureSet.CapitaliseLMI &&
                      $scope.LoanStructureSet.IsAddLMI
                    ) {
                      $scope.TotalLoanAmountCalculation = getTotalLoanAmount(
                        $scope.LoanStructureSet,
                      );
                      if (!onload) {
                        swal(
                          {
                            title: 'Are you sure?',
                            text: `Would you like the additional LMI amount of $${$scope.loanDetailsSet.LMI} to be added to this split?`,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#F68F8F',
                            confirmButtonText: 'Yes',
                            closeOnConfirm: true,
                          },
                          (confirm) => {
                            if (confirm) {
                              $scope.saveLmiToFundingParms = true;
                            }
                          },
                        );
                      }
                    } else {
                      $scope.TotalLoanAmountCalculation = parseFloat(
                        $scope.LoanStructureSet.BaseLoanAmount,
                      );
                    }
                  };

                  $scope.addLoanStructure = () => {
                    $scope.LoanStructureSet.Owner = _.filter(
                      $scope.ownersList,
                      (obj) => {
                        return obj.ticked === true;
                      },
                    );
                    $scope.cancel();

                    $scope.LoanStructureSet.InterestOnlyTerm = getInterestOnlyTerm(
                      $scope.LoanStructureSet.InterestOnlyTerm,
                    );

                    if (!$scope.LoanStructureSet.IsAddLMI) {
                      $scope.LoanStructureSet.LMIPremium = 0;
                    }
                    $scope.saveLoanStructure();
                  };
                  $scope.saveLoanStructure = () => {
                    loanScenarioService
                      .loanFacilitySet($scope.LoanStructureSet)
                      .then(({ data }) => {
                        if (!data) {
                          return toastError();
                        }
                        $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                        $scope.loanDetailsSet.LMI = $scope.saveLmiToFundingParms
                          ? $scope.LoanStructureSet.LMIPremium
                          : $scope.loanDetailsSet.LMI;
                        $scope.saveLmiToFundingParms &&
                          $scope.saveFunding($scope.loanDetailsSet.LMI);
                        opportunityLoanAppService.executeCallback(
                          'refreshOpportunityDetails',
                        );
                        $scope.loanDetailsSetMethod();
                      })
                      .catch(toastError);
                  };

                  $scope.getFundingCalculatorFundsQuickLMI = () => {
                    const {
                      IsAddLMI,
                      BaseLoanAmount,
                      LoanTerm,
                      CapitaliseLMI,
                    } = $scope.LoanStructureSet;
                    if (
                      !$scope.securityBrokerEventId ||
                      !$scope.loanStructureId ||
                      !IsAddLMI ||
                      !CapitaliseLMI
                    ) {
                      $scope.saveLoanStructure();
                      return;
                    }
                    fundingCalculatorService
                      .getFundingCalculatorFundsQuickLMI(
                        $scope.securityBrokerEventId,
                      )
                      .then(({ data }) => {
                        if (!data) {
                          return;
                        }
                        const { Lenders, Insurer, LMIProductID } = data;
                        const validToSet =
                          Number(BaseLoanAmount) &&
                          Lenders &&
                          Lenders.length &&
                          Insurer &&
                          LMIProductID;
                        if (!validToSet) {
                          $scope.saveLoanStructure();
                          return;
                        }
                        const quickLmi = {
                          ...data,
                          NewLoanAmount: Number(BaseLoanAmount),
                          LoanTerm,
                        };
                        $scope.saveLmi(quickLmi);
                      })
                      .catch(toastError);
                  };

                  $scope.saveLmi = (quickLmi) => {
                    if (!quickLmi) {
                      return;
                    }
                    const { SecurityAmount, ...postData } = quickLmi;
                    fundingCalculatorService
                      .setFundingCalculatorFundsQuickLMI(postData)
                      .then(({ data }) => {
                        data &&
                          $scope.getFundingCalculatorFundsQuickLMILoanApp();
                      })
                      .catch(toastError);
                  };
                  $scope.getFundingCalculatorFundsQuickLMILoanApp = () => {
                    fundingCalculatorService
                      .getFundingCalculatorFundsQuickLMILoanApp(
                        $scope.securityBrokerEventId,
                      )
                      .then(({ data }) => {
                        const { LMIPremium } = data;
                        $scope.LoanStructureSet.LMIPremium = LMIPremium;
                        $scope.saveLmiToFundingParms = true;
                        $scope.saveLoanStructure();
                      });
                  };

                  $scope.modalType = false;
                  //* ************************************/
                  //  owners multi Select                /
                  //* ************************************/
                  $scope.localLang = {
                    selectAll: '<span>Select All</span>',
                    selectNone: '<span>Deselect All </span>',
                    reset: "<i class='fa fa-refresh'></i>",
                    search: 'Search',
                    nothingSelected: 'None Selected', // default-label is deprecated and replaced with this.
                  };
                  $scope.ownersList = [];
                  $scope.ownersListSelected = [];
                  $scope.OwnersGet = () => {
                    loanAppDetailsService
                      .ownersGet($state.params.loanAppId)
                      .then((response) => {
                        $scope.ownersListSelected = [];
                        $scope.ownersList = response;
                        if ($scope.loanStructureId) {
                          $scope.getLoanFacility();
                        } else {
                          $scope.LoanStructureSet.LoanTerm = 30;
                          $scope.LoanStructureSet.StatementCycleId = 4;
                          $scope.onSelectAll();
                        }
                      });
                  };

                  $scope.OwnersGet();

                  $scope.ownersListSelectedArray = [];

                  $scope.onSelectAll = () => {
                    for (let i = 0; i < $scope.ownersList.length; i++) {
                      if ($scope.ownersList[i].ClientEntityId > -1) {
                        $scope.ownersList[i].ticked = false;
                      } else {
                        $scope.ownersList[i].ticked = true;
                      }
                    }
                  };

                  $scope.onSelectNone = () => {
                    for (let i = 0; i < $scope.ownersList.length; i++) {
                      $scope.ownersList[i].ticked = false;
                    }
                  };

                  const ownersListSelectedWatcher = $scope.$watch(
                    'ownersListSelected',
                    () => {
                      $('.hide-in-mobile .multiSelect')
                        .nextAll('div.buttonLabel')
                        .remove();
                      if ($scope.ownersListSelected.length >= 3) {
                        $('.multiSelect .buttonLabel:last-child').html(
                          `${$scope.ownersListSelected.length - 2} more ...` +
                            `<span class="caret"></span>`,
                        );
                      }
                      $(
                        '.hide-in-mobile .multiSelect .buttonLabel:not(:nth-child(3))',
                      ).each((e) => {
                        $(e.currentTarget).html(
                          `${$(
                            e.currentTarget,
                          ).text()}<i class="fa fa-close"></i> <span class="caret"></span>`,
                        );
                      });
                    },
                  );
                  watcherService.addWatcher(
                    ownersListSelectedWatcher,
                    WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
                  );
                },
                scope: $scope,
                size: 'sm',
              });
            },
            updateLMIValueOnTxt: (value = 0) => {
              $scope.loanDetailsSet.LMI = value;
              $scope.loanDetailsSetMethod();
              $scope.updateLMIValue();
              $scope.loanAppSharedData.refreshSecurityFundingParameters = 1;
            },
            saveFunding: (value) => {
              loanAppSharedData.SAVE_FUNDING_PARAMETER = {
                fundsId: 0,
                fundingCalParametersId: 0,
                name: 'Lenders Mortgage Insurance',
                value,
              };
            },
            updateLMIValue: () => {
              if (
                !$scope.loanDetailsSet.LMI ||
                !parseToInt10($scope.loanDetailsSet.LMI)
              ) {
                $scope.lmiSetMethodV2();
                return;
              }
              if ($scope.showLmiFromAirtable) {
                $scope.saveFunding($scope.loanDetailsSet.LMI);
                $scope.setIsLoanDetailsStarted();
                return;
              }
              swal(
                {
                  title: 'Are you sure?',
                  text: `Would you like the additional LMI amount of $${$scope.loanDetailsSet.LMI} to be added to this split?`,
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#F68F8F',
                  confirmButtonText: 'Yes',
                  closeOnConfirm: true,
                },
                (confirm) => {
                  if (confirm) {
                    $scope.saveFunding($scope.loanDetailsSet.LMI);
                  }
                },
              );
            },
            // eslint-disable-next-line unicorn/consistent-function-scoping
            updateLMIValueOnTxtV2: () => (LMIPremium) => {
              $scope.updateLMIValueOnTxt(LMIPremium);
            },
            openModalForAddLMIV2: () => {
              const props = {
                brokerEventId: $scope.securityBrokerEventId,
                loanSplits: $scope.loanDetailsSet.LoanFacility,
                updateLMIValueOnTxt: $scope.updateLMIValueOnTxtV2(),
                loanDetails: $scope.loanDetailsSet,
              };
              $uibModal.open({
                template: `<lmi-airtable-proposed-lending
                          modal-instance="vm.modalInstance"
                          broker-event-id="vm.props.brokerEventId"
                          loan-splits="vm.props.loanSplits"
                          loan-details="vm.props.loanDetails"
                          update-lmi-value-on-txt="vm.props.updateLMIValueOnTxt(LMIPremium)"
                          >
                        </lmi-airtable-proposed-lending>`,
                size: 'sm',
                backdrop: 'static',
                windowClass: 'lmi-airtable-prposed-lending',
                controller: 'CommonModalPlaceholderCtrl',
                controllerAs: 'vm',
                resolve: {
                  props,
                },
              });
            },
            openModalForAddLMI: () => {
              $uibModal.open({
                templateUrl:
                  '/assets/views/loanApplication/clientAssess/loanDetails.v2/modal/lmi_modal.html',
                scope: $scope,
                controller($uibModalInstance, NewLoanAmount) {
                  $scope.cancel = () => {
                    $uibModalInstance.dismiss('cancel');
                  };

                  fundingCalculatorService
                    .getLenderList(true)
                    .then((response) => {
                      $scope.LMILenserList = response.data;
                    });

                  optionsService.getLoanTerms().then((proposedLendingTerm) => {
                    $scope.proposedLendingTermList = proposedLendingTerm;
                  });

                  $scope.getInsurerList =
                    fundingCalculatorSharedData.getInsurerList;

                  // Get Funding Calculator Proposed Lending
                  // ----------------------------------------------------------------------------
                  $scope.fundingCalculatorProposedLending = [];
                  $scope.fundingCalculatorLMIDeatils = {};

                  $scope.getFundingCalculatorLMIDeatils = () => {
                    fundingCalculatorService
                      .getFundingCalculatorFundsLMI(
                        $scope.securityBrokerEventId,
                      )
                      .then((response) => {
                        $scope.fundingCalculatorLMIDeatils = response;
                        $scope.fundingCalculatorLMIDeatils.newLoanTerm = $scope.fundingCalculatorLMIDeatils.newLoanTerm.toString();
                        $scope.fundingCalculatorLMIDeatils.newLoanAmount = getnewLoanAmount(
                          NewLoanAmount,
                        );
                        $scope.securityList = getSecurityList(
                          $scope.fundingCalculatorLMIDeatils.security,
                        );
                        $scope.getLoanOption();
                        if (
                          $scope.fundingCalculatorLMIDeatils.fundsLmiId === 0
                        ) {
                          $scope.fundingCalculatorLMIDeatils.isSelfEmployed = false;
                          $scope.fundingCalculatorLMIDeatils.capitalizeLmi = true;
                          $scope.fundingCalculatorLMIDeatils.newLoanTerm = LOAN_TERM;
                          $scope.fundingCalculatorLMIDeatils.lenderId =
                            $scope.loanDetailsSet.LenderId;
                        }

                        if ($scope.fundingCalculatorLMIDeatils.lenderId) {
                          $scope.getInsurerList(
                            $scope.fundingCalculatorLMIDeatils.lenderId,
                          );
                        }
                      });
                  };
                  $scope.getFundingCalculatorLMIDeatils();

                  // Set Funding Calculator LMI
                  // ----------------------------------------------------------------------------
                  $scope.setLMI = () => {
                    broadcastDataService.InlinePreloader = {
                      preLoaderText: 'Calculating LMI',
                      preLoaderSize: '',
                      showPreLoader: true,
                    };
                    let loanOptionData = [];
                    const data = _.filter($scope.loanOptions, (itemGet) => {
                      return itemGet.isCheked === true;
                    });
                    if (typeof data !== 'undefined') {
                      loanOptionData = _.toArray(data);
                    }
                    const postData = {
                      fundsLmiId: $scope.fundingCalculatorLMIDeatils.fundsLmiId,
                      brokerEventId: $scope.securityBrokerEventId,
                      lenderId: Number(
                        $scope.fundingCalculatorLMIDeatils.lenderId,
                      ),
                      insurer: $scope.fundingCalculatorLMIDeatils.insurer,
                      isSelfEmployed:
                        $scope.fundingCalculatorLMIDeatils.isSelfEmployed,
                      newLoanAmount:
                        $scope.fundingCalculatorLMIDeatils.newLoanAmount,
                      newLoanTerm:
                        $scope.fundingCalculatorLMIDeatils.newLoanTerm,
                      predominantLoanPurpose:
                        $scope.fundingCalculatorLMIDeatils
                          .predominantLoanPurpose,
                      loanOption: loanOptionData,
                      security: $scope.securityList,
                      capitalizeLmi:
                        $scope.fundingCalculatorLMIDeatils.capitalizeLmi,
                    };
                    fundingCalculatorService
                      .setFundingCalculatorFundsLMI(postData)
                      .then(() => {
                        fundingCalculatorService
                          .getLMICalculatorReport($scope.securityBrokerEventId)
                          .then(({ data: lmiData }) => {
                            broadcastDataService.InlinePreloader = {
                              preLoaderText: 'Calculating LMI',
                              preLoaderSize: '',
                              showPreLoader: false,
                            };

                            loanAppSharedData.sectionSelected = 'loandetails';
                            loanAppSharedData.subSectionSelected =
                              'lmi-section';

                            if (!lmiData) {
                              toaster.pop(
                                'error',
                                'Error',
                                'No Results found, please refine your LMI search criteria.',
                              );
                            } else {
                              const { LMIPremium } = lmiData;
                              if (LMIPremium) {
                                $scope.updateLMIValueOnTxt(LMIPremium);
                              }
                              toaster.pop(
                                'success',
                                'Updated',
                                'LMI has been successfully Updated',
                              );
                            }
                          })
                          .catch(toastError);
                      })
                      .catch(toastError);
                  };

                  // Get Funding Calculator Proposed Lending Interest Type List
                  // ----------------------------------------------------------------------------
                  $scope.proposedLendingInterestTypeList = [];
                  $scope.getProposedLendingInterestType = () => {
                    fundingCalculatorService
                      .getProposedLendingInterestType()
                      .then((response) => {
                        $scope.proposedLendingInterestTypeList = response;
                      });
                  };
                  $scope.getProposedLendingInterestType();

                  $scope.loanOptions = getLoanOptions();

                  $scope.getLoanOption = () => {
                    const $updatedData = [];
                    angular.forEach($scope.loanOptions, (item) => {
                      item.isCheked = false;
                      angular.forEach(
                        $scope.fundingCalculatorLMIDeatils.loanOption,
                        (itemGet) => {
                          if (itemGet.value === item.value) {
                            item.isCheked = true;
                            return item;
                          }
                        },
                      );
                      $updatedData.push(item);
                    });
                    $scope.loanOptions = $updatedData;
                  };

                  // Lenders Mortgage Insurance -> Security
                  // ----------------------------------------------------------------------------

                  $scope.isSecurityVisible = false;
                  $scope.newSecurityList = [];

                  // Add Security Div
                  $scope.addSecurity = () => {
                    $scope.isSecurityVisible = true;
                    const newSecurity = {
                      id: Math.random(),
                      stateOfSecurity: '',
                      occupancyType: '',
                      value: '',
                      isEditMode: false,
                    };
                    $scope.newSecurityList.push(newSecurity);
                  };

                  // Add Or Update Security
                  $scope.saveSecurity = (security) => {
                    let index = $scope.newSecurityList.indexOf(security);
                    if (index > -1) {
                      $scope.newSecurityList.splice(index, 1);
                    }
                    const postData = security;
                    if (!security.isEditMode) {
                      postData.id = 0;
                      $scope.securityList.push(postData);
                    } else {
                      index = $scope.securityList.indexOf(security);
                      if (index > -1) {
                        $scope.securityList.splice(index, 1);
                      }
                      $scope.securityList.push(postData);
                    }
                    security.isEditMode = false;
                  };

                  // Delete Security
                  $scope.deleteSecurity = (security) => {
                    if (Math.floor(security.id) === 0) {
                      const index = $scope.newSecurityList.indexOf(security);
                      if (index > -1) {
                        $scope.newSecurityList.splice(index, 1);
                      }
                      const indexOne = $scope.securityList.indexOf(security);
                      if (indexOne > -1) {
                        $scope.securityList.splice(indexOne, 1);
                      }
                    } else {
                      const index = $scope.securityList.indexOf(security);
                      if (index > -1) {
                        $scope.securityList.splice(index, 1);
                      }
                    }
                  };
                },
                size: 'md',
                resolve: {
                  NewLoanAmount: () => {
                    return $scope.loanAppSharedData.fundingSummary
                      .totalProposedLendingUserDefined;
                  },
                },
              });
            },
            getLoanAppAssetFinanceStatus: () => {
              const isAssetFinanceCategory = getAssetFinanceCategory(
                $scope.loanDetailsSet,
              );
              return assetFinance && isAssetFinanceCategory;
            },
          });

          // caller
          $scope.loanDetailsGet();

          /**
           * Below are for product Finder codes
           */
          // models
          angular.extend($scope, {
            isSelectedProductRecommend: false,
            temporaryProducts: [
              {
                ProductID: 3625,
                Description:
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  'NAB Home Plus Standard 2 Yr Fixed Rate $250K-$749K LVR >90% (Principal & Interest) (Owner Occupied)',
              },
              {
                ProductID: 3624,
                Description:
                  'NAB Home Plus Standard 2 Yr Fixed Rate $250K-$749K LVR >90% (Principal & Interest) (Owner Occupied)',
              },
              {
                ProductID: 3626,
                Description:
                  'NAB Home Plus Standard 2 Yr Fixed Rate $250K-$749K LVR >90% (Principal & Interest) (Owner Occupied)',
              },
              {
                ProductID: 3627,
                Description:
                  'NAB Home Plus Standard 2 Yr Fixed Rate $250K-$749K LVR >90% (Principal & Interest) (Owner Occupied)',
              },
            ],
          });

          // methods
          angular.extend($scope, {
            searchProductChange: (facility) => {
              facility.ProductsList = [];
              facility.searchInterval = 2;

              if (_.size(facility.searchProduct) > 2) {
                const stop = $interval(() => {
                  //

                  if (facility.searchInterval > 1) {
                    facility.searchInterval -= 1;
                  } else {
                    loanProfilerService
                      .getServiceabilityProductSearch(facility.searchProduct)
                      .then((response) => {
                        facility.ProductsList = response.data;
                      });
                    facility.searchInterval = 2;
                    $interval.cancel(stop);
                  }
                }, 1000);
              }
            },
            deleteFavoriteProduct: ctrlUtils.deleteFavoriteProduct(
              $scope,
              loanProfilerService,
              loanScenarioModelService,
              productFinderEditEnhancements,
              ctrlUtils,
              toastError,
            ),
            deleteAllFavoriteProducts: ctrlUtils.deleteAllFavoriteProducts(
              $scope,
              loanProfilerService,
              loanScenarioModelService,
              productFinderEditEnhancements,
              ctrlUtils,
            ),
            setFavoriteProduct: (facility, product) => {
              const toBeDeletedProducts = _.clone(facility.selectedProduct);
              const countProductsToBeDeleted = _.size(toBeDeletedProducts);
              let counter = 0;
              _.each(toBeDeletedProducts, (o) => {
                const postData = {
                  brokerEventID: facility.BrokerEventId,
                  ProductID: o.ProductID,
                  LenderID: o.LenderID,
                  loanStructureID: facility.LoanStructureId,
                  IsFavourite: false,
                };

                // Remove the other product (Put false to their IsFavourite property)
                loanProfilerService
                  .setFavouriteProduct(postData)
                  .then(() => {
                    _.remove(facility.selectedProduct, (currentProd) => {
                      return _.isEqual(currentProd.LenderID, o.LenderID);
                    });

                    counter++;

                    // Check if every product are now changed
                    if (counter === countProductsToBeDeleted) {
                      product.isAddedForComparison = !product.isAddedForComparison;
                      const productPostData = {
                        brokerEventID: facility.BrokerEventId,
                        ProductID: product.ProductID,
                        LenderID: product.LenderID,
                        loanStructureID: facility.LoanStructureId,
                        IsFavourite: product.isAddedForComparison,
                      };

                      // Add the newly selected product to be favourite
                      loanProfilerService
                        .setFavouriteProduct(productPostData)
                        .then((response) => {
                          if (response.data === 1) {
                            // Set loan structure / loan details (facility)
                            angular.extend(facility, {
                              ProductId: product.ProductID,
                              LenderId: product.LenderID,
                              Owner: [],
                              loanScenarioId: $scope.loanAppId,
                            });
                            loanScenarioService
                              .loanFacilitySet(facility)
                              .then(() => {
                                $scope.loanAppSharedData.refreshSecurityFundsSummary = 1;
                                $scope.getFacilityFavoriteProductList(facility);
                                $scope.validateLoanDetails();
                              })
                              .catch(toastError);
                          } else {
                            toastError();
                          }
                        })
                        .catch(toastError);
                    }
                  })
                  .catch(toastError);
              });
            },

            getFavoriteProduct: (facility) => {
              facility.selectedProduct = [];
              loanProfilerService
                .getFavouriteProduct(
                  facility.BrokerEventId,
                  facility.LoanStructureId,
                )
                .then((response) => {
                  facility.selectedProduct = response.data;
                });
            },
            getFacilityFavoriteProductList: (
              facility,
              autoSelectFavourite = true,
            ) => {
              facility.FavouriteProductData = [];
              facility.SelectedFavouriteProductData = {};
              facility.TotalFees = 0;
              if (!facility.BrokerEventId) {
                return;
              }
              loanProfilerService
                .getSelectedProductDetailsGetByLoanStructure(
                  facility.BrokerEventId,
                )
                .then((response) => {
                  facility.FavouriteProductData = response.data;

                  loanAppSharedData.isGenerateGamePlanValidationFailed &&
                    ctrlUtils.updateGPValidationProductReference({
                      nzBidLoanAppChangesValidation:
                        uiService.nzBidLoanAppChangesValidation,
                      loanAppSharedData,
                      facilityList: $scope.loanDetailsSet.LoanFacility,
                    });

                  if (typeof facility.FavouriteProductData[0] !== 'undefined') {
                    const filterData = _.find(
                      facility.FavouriteProductData[0].SelectedProduct,
                      (product) => {
                        return product.IsRecommended === true;
                      },
                    );
                    $scope.isSelectedProductRecommend = !_.isEmpty(filterData);
                    if (
                      typeof filterData === 'undefined' &&
                      autoSelectFavourite
                    ) {
                      if (
                        typeof facility.FavouriteProductData[0]
                          .SelectedProduct[0] !== 'undefined'
                      ) {
                        const postData = {
                          BrokerEventID: Number(facility.BrokerEventId),
                          LoanStructureID: Number(
                            facility.FavouriteProductData[0].LoanStructureID,
                          ),
                          ProductID:
                            facility.FavouriteProductData[0].SelectedProduct[0]
                              .ProductID,
                          IsRecommended: true,
                          IsToolTipShown: false,
                        };

                        loanProfilerService
                          .setFavouriteProductRecommendation(postData)
                          .then((productResponse) => {
                            if (productResponse.data === 1) {
                              facility.FavouriteProductData[0].SelectedProduct[0].IsRecommended = true;
                              if (
                                facility.FavouriteProductData[0]
                                  .SelectedProduct[0].IsRecommended === true
                              ) {
                                facility.SelectedFavouriteProductData =
                                  facility.FavouriteProductData[0].SelectedProduct[0];
                                if (
                                  facility.SelectedFavouriteProductData
                                    .NewInitialRate === 0
                                ) {
                                  facility.SelectedFavouriteProductData.NewInitialRate =
                                    '';
                                }
                                if (
                                  facility.SelectedFavouriteProductData
                                    .NewOngoingRate === 0
                                ) {
                                  facility.SelectedFavouriteProductData.NewOngoingRate =
                                    '';
                                }
                                $scope.feesGet(
                                  facility.FavouriteProductData[0]
                                    .SelectedProduct[0].ProductID,
                                  facility.BrokerEventId,
                                  facility.FavouriteProductData[0]
                                    .LoanStructureID,
                                  facility.LoanStructureId,
                                  $scope.loanAppId,
                                  true,
                                  facility,
                                );
                                $scope.updateLenderFromSplit(
                                  facility.SelectedFavouriteProductData,
                                  facility.LoanStructureId,
                                );
                                $scope.validateLoanDetails();
                              }
                            } else {
                              toastError();
                            }
                            $scope.validateLoanDetails();
                          })
                          .catch(toastError);
                      }
                    } else {
                      angular.forEach(
                        facility.FavouriteProductData[0].SelectedProduct,
                        (item) => {
                          if (!item.IsRecommended) {
                            return;
                          }
                          facility.SelectedFavouriteProductData = item;
                          if (
                            facility.SelectedFavouriteProductData
                              .NewInitialRate === 0
                          ) {
                            facility.SelectedFavouriteProductData.NewInitialRate =
                              '';
                          }
                          if (
                            facility.SelectedFavouriteProductData
                              .NewOngoingRate === 0
                          ) {
                            facility.SelectedFavouriteProductData.NewOngoingRate =
                              '';
                          }
                        },
                      );
                    }
                  }
                  $scope.validateLoanDetails();
                })
                .catch(toastError);
              $scope.validateLoanDetails();
            },
            setFavouriteProductRates: (
              loanStructureId,
              brokerEventId,
              productId,
              rateType,
              newRate,
            ) => {
              const postData = {
                BrokerEventID: brokerEventId,
                LoanStructureID: loanStructureId,
                ProductID: productId,
                RateType: rateType,
                NewRate: newRate,
              };
              loanProfilerService
                .setFavouriteProductRates(postData)
                .then((response) => {
                  if (response.data !== 1) {
                    toastError();
                  }
                })
                .catch(toastError);
            },
            setCQPManuallyUpdated: () => {
              return loanScenarioModelService
                .setManualCqpState($scope.loanAppId, true)
                .catch(toastError);
            },
            toggleFee: ctrlUtils.toggleFee(
              $scope,
              productFinderEditEnhancements,
              loanScenarioService,
              loanScenarioModelService,
              loanAppSharedData,
            ),
            feeChanges: ctrlUtils.feeChanges(
              $scope,
              loanScenarioModelService,
              loanAppSharedData,
            ),
            updateLenderFromSplit: ctrlUtils.updateLenderFromSplit($scope),
            setFacilityFavoriteProduct: (facility, product) => {
              const invalidParams = !facility || !product;
              const isSelectedSelf =
                facility &&
                product &&
                facility.SelectedFavouriteProductData &&
                facility.SelectedFavouriteProductData.ProductID ===
                  product.ProductID;

              if (invalidParams || isSelectedSelf) {
                return;
              }

              const { hasManuallyChanged } = facility;

              const showConfirmation =
                productFinderEditEnhancements && hasManuallyChanged;

              return new Promise((resolve) => {
                if (showConfirmation) {
                  ctrlUtils
                    .confirmChangeProduct(crmConfirmation)
                    .then((response) => {
                      resolve(response);
                    })
                    .catch(toastError);
                } else {
                  resolve(true);
                }
              })
                .then((continueSaving) => {
                  if (!continueSaving) {
                    return;
                  }

                  return new Promise((resolve) => {
                    if (productFinderEditEnhancements) {
                      ctrlUtils
                        .deleteAllFees(
                          $scope,
                          loanScenarioModelService,
                          facility.LoanStructureId,
                        )
                        .then(() => {
                          facility.structureGeneralFeeObject = {
                            TotalFee: 0,
                          };
                          $scope.validateLoanDetails();
                          resolve();
                        })
                        .catch(toastError);
                    } else {
                      resolve();
                    }
                  });
                })
                .then(() => {
                  const {
                    FavouriteProductData: favouriteProductData,
                    BrokerEventId: brokerEventId,
                  } = facility;
                  if (
                    favouriteProductData &&
                    favouriteProductData.length &&
                    !product.IsRecommended
                  ) {
                    $scope.isSelectedProductRecommend = !product.IsRecommended;
                    const {
                      SelectedProduct: selectedProduct,
                      LoanStructureID: loanStructureId,
                    } = favouriteProductData[0];
                    selectedProduct &&
                      selectedProduct.forEach((item) => {
                        item.IsRecommended =
                          parseInt(item.ProductID, 10) !==
                          parseInt(product.ProductID, 10)
                            ? false
                            : !product.IsRecommended;
                        const postData = {
                          BrokerEventID: parseInt(brokerEventId, 10),
                          LoanStructureID: parseInt(loanStructureId, 10),
                          ProductID: item.ProductID,
                          IsRecommended: item.IsRecommended,
                          IsToolTipShown: false,
                        };

                        loanProfilerService
                          .setFavouriteProductRecommendation(postData)
                          .then((response) => {
                            if (
                              response.data === 1 &&
                              item.ProductID === product.ProductID
                            ) {
                              $scope.updateLenderFromSplit(
                                item,
                                facility.LoanStructureId,
                              );
                              $scope.getFacilityFavoriteProductList(facility);
                              $scope.loanDetailsGet();
                            } else if (response.data !== 1) {
                              product.IsRecommended = !product.IsRecommended;
                              toastError();
                            }
                            $scope.validateLoanDetails();
                          })
                          .catch(toastError);
                      });

                    $scope.setCQPManuallyUpdated();
                  }
                  $scope.validateChanges && $scope.validateChanges();
                })
                .catch(toastError);

              // check if recommend is present
            },
            checkIfFavorite: (facility, product) => {
              if (
                _.find(facility.selectedProduct, (o) => {
                  return o.ProductID === product.ProductID;
                })
              ) {
                return true;
              }
              return false;
            },
            chooseComparisonModal: (facility) => {
              const involvedPartyPerson =
                isValidObjectValue(
                  () => loanAppSharedData.loanAppApplicants.InvolvedPartyPerson,
                ) || null;
              const familyList = utilGenerateFamilyList(involvedPartyPerson);

              return modalRenderService
                .openSavedProductComparisonModal({
                  familyList,
                  facility,
                  deleteAllFavoriteProducts: $scope.deleteAllFavoriteProducts,
                })
                .result.then(
                  ctrlUtils.productComparisonModalResultCallback(
                    $scope,
                    facility,
                  ),
                );
            },
            chooseProductsModal: (facility) => {
              const countFavoriteProducts =
                isValidObjectValue(
                  () => facility.FavouriteProductData[0].SelectedProduct.length,
                ) || false;
              const {
                HasSavedProductComparison: hasSavedProductComparison,
              } = $scope.loanDetailsSet;

              if (
                ctrlUtils.isOpenChooseProductsModal(
                  hasSavedProductComparison,
                  countFavoriteProducts,
                )
              ) {
                const involvedPartyPerson =
                  isValidObjectValue(
                    () =>
                      loanAppSharedData.loanAppApplicants.InvolvedPartyPerson,
                  ) || null;

                return !involvedPartyPerson
                  ? toastWarning(
                      'Waiting for list of applicants',
                      'Please wait',
                    )
                  : modalRenderService
                      .openChooseProductsModal({
                        facility,
                        involvedPartyPerson,
                        cbProductFinder: $scope.openProductFinderModal,
                      })
                      .result.then((result) => {
                        if (result && result.callback) {
                          return result.callback;
                        }
                      })
                      .then(
                        ctrlUtils.productComparisonModalResultCallback(
                          $scope,
                          facility,
                        ),
                      );
              }

              return $scope.openProductFinderModal(facility);
            },
            openProductFinderModal: (facility) => {
              if ($scope.loanAppSharedData.fundingSummary.lvr > 95) {
                toaster.pop(
                  'warning',
                  'Important',
                  'No results will appear in product finder for >95% LVR',
                );
              }
              facility.tempShowProducts = true;
              facility.loanScenarioId = $scope.loanAppId;
              facility.Owner = [];

              if (facility.BrokerEventId) {
                const modalInstance = $uibModal.open({
                  templateUrl:
                    '/assets/views/loanApplication/clientAssess/loanDetails.v2/modal/productFinder.html',
                  controller: 'LoanAppProductFinderCtrl',
                  scope: $scope,
                  size: 'wide',
                  backdrop: false,
                  resolve: {
                    facility: () => {
                      return facility;
                    },
                    loanDetail: () => {
                      return $scope.loanDetailsSet;
                    },
                    fundingSummary: () => {
                      const fundingSummary = _.clone(
                        $scope.loanAppSharedData.fundingSummary,
                      );
                      fundingSummary.totalProposedLendingUserDefined =
                        $scope.loanDetailsSet.TotalProposedLendingUserDefined ||
                        0;

                      return fundingSummary;
                    },
                    comparisonPeriodList: () => {
                      return (
                        $scope.loanTermList.filter(
                          (term) =>
                            Number.isInteger(term.value) &&
                            term.value < facility.LoanTerm,
                        ) ?? []
                      );
                    },
                  },
                });

                modalInstance.result.then(
                  () => $scope.productFinderModalResultCallback(facility, true),
                  () =>
                    $scope.productFinderModalResultCallback(facility, false),
                );
              } else {
                toaster.pop(
                  'error',
                  'Error',
                  'Something did not save due to internet issue , please re-save it again',
                );
              }
            },
            productFinderModalResultCallback: ctrlUtils.productFinderModalResultCallback(
              $scope,
              loanScenarioModelService,
              productFinderEditEnhancements,
              ctrlUtils,
            ),
            updateAssetFinanceOtherFundsReq: () => {
              loanAppSharedData.refreshAssetFinanceOtherFunds = 1;
            },
          });

          $scope.isCountry = (country) => {
            return uiService.isCountry(country);
          };

          $scope.onOtherLenderSelected = (lender) => {
            if (!lender) {
              return;
            }

            loanAppSharedData.lenderId = lender.ProviderId;
            loanAppSharedData.otherLenderName = lender.ProviderName;
            $scope.updateLoanDetailsField(
              'Lender',
              lender.ProviderId,
              lender.ProviderName,
            );
          };

          $scope.onLenderSelected = (lender) => {
            if (!lender) {
              return;
            }
            uiService.dualLodgementEnabled
              ? lodgementSubmissionService.isOnlineLodgement(
                  lender.ProviderId,
                  $scope.loanAppId,
                )
              : blueStoneSubmissionService.isProviderBlueStone(
                  lender.ProviderId,
                );
            loanAppSharedData.lenderId = lender.ProviderId;
            loanAppSharedData.otherLenderName = '';
            $scope.loanDetailsSet.LenderName = lender.ProviderName;
            $scope.updateLoanDetailsField('Lender', lender.ProviderId, '');

            const deferred = $q.defer();
            deferred.resolve(true);
            return deferred.promise;
          };

          $scope.getCountryCode = () => {
            return uiService.getCountryCode();
          };

          // Listens for reseting loandetailsset
          const refreshLoanDetailsWatcher = $scope.$watch(
            'loanAppSharedData.refreshLoanDetailsSet',
            (nv) => {
              if (nv === 1) {
                $scope.loanDetailsSetMethod();

                $scope.loanAppSharedData.refreshLoanDetailsSet = 0;
              }
            },
            true,
          );

          const refreshLoanDetails = $scope.$watch(
            'loanAppSharedData.refreshLoanDetails',
            (nv) => {
              if (nv === 1) {
                $scope.loanDetailsGet();
                $scope.validateLoanDetails();

                $scope.loanAppSharedData.refreshLoanDetails = 0;
              }
            },
          );

          const lenderIdWatcher = $scope.$watch(
            () => {
              return loanAppSharedData.lenderId;
            },
            (nv) => {
              if (!$scope.loanDetailsSet || !nv) {
                return;
              }

              const isNewLender = nv !== $scope.loanDetailsSet.LenderId;
              if (isNewLender) {
                $scope.loanDetailsSet.LenderId = loanAppSharedData.lenderId;
                $scope.loanDetailsSet.LenderName = getLenderName(
                  loanAppSharedData,
                );
              }
            },
          );

          const otherLenderNameWatcher = $scope.$watch(
            () => {
              return loanAppSharedData.otherLenderName;
            },
            (nv) => {
              if (!$scope.loanDetailsSet) {
                return;
              }

              const isNewOtherLenderName =
                nv !== $scope.loanDetailsSet.OtherLenderName;
              if (isNewOtherLenderName) {
                $scope.loanDetailsSet.OtherLenderName =
                  loanAppSharedData.otherLenderName;
              }
            },
          );

          watcherService.addWatcher(
            refreshLoanDetailsWatcher,
            WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
          );
          watcherService.addWatcher(
            refreshLoanDetails,
            WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
          );
          watcherService.addWatcher(
            lenderIdWatcher,
            WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
          );
          watcherService.addWatcher(
            otherLenderNameWatcher,
            WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
          );
          const duration = performance.endPaint(LOAN_APP_LOAN_FUNDING);
          loanAppSectionData({
            label: LOAN_APP_LOAN_FUNDING,
            value: duration,
          });
        };

        if (typeof sectionObject.IsCompleted === 'undefined') {
          $scope.processLoanFundingDetailsSection();
        } else {
          const {
            totalProposedLending: sectionProposedLending,
            lvr: sectionLVR,
          } = $scope.loanAppSharedData.fundingSummary;
          $scope.isValidLoanDetails = sectionObject.IsCompleted;

          $scope.loanAppSharedData.fundingSummary.totalProposedLending = sectionProposedLending;
          $scope.loanAppSharedData.fundingSummary.lvr = sectionLVR;
          $scope.setIsLoanDetailsStarted();
        }

        $scope.$on('$destroy', () => {
          watcherService.destroyWatchers(
            WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
          );
          timeoutService.cancelTimeouts(
            WATCHER_CONTEXT.LOAN_FUNDING_DETAILS_CTRL,
          );
        });

        ctrlUtils.extendProductFinderLoanFacility($scope);
      };

      $scope.getFundingCalculatorFundsSummary = () =>
        loanAppSharedData.getFundingCalculatorFundsSummary({
          securityBrokerEventId: $scope.securityBrokerEventId,
          loanAppId: $scope.loanAppId,
        });

      $scope.setIsLoanDetailsStarted = ctrlUtils.setIsLoanDetailsStarted({
        $scope,
        uiService,
        loanAppSharedData,
      });

      // Listens for refreshing summary
      $scope.$watch(
        'loanAppSharedData.refreshSecurityFundsSummary',
        (nv) => {
          if (nv === 1 && !$scope.loanAppSharedData.isFundingDetailsLoaded) {
            $scope.getFundingCalculatorFundsSummary();
            $scope.loanAppSharedData.refreshSecurityFundsSummary = 0;
          }
        },
        true,
      );

      $scope.$watch('isLoanAppLoaded', () => {
        if (!$scope.isLoanAppLoaded) {
          return;
        }
        initializeLoanDetails();
      });

      $scope.$watch(
        () => angular.element('#loanAppLoanDetails').hasClass('is-active'),
        (isOpen) => {
          if (isOpen) {
            $scope.getBorrowersDetails();
          }
        },
      );
    },
  );
