import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('ngLongTouch', function ngLongTouch($timeout, $parse) {
      return {
        link: (scope, elem, attrs) => {
          let timeoutHandler;
          const fn = $parse(attrs.ngLongTouch);

          // disable text selection
          elem.unselectable = 'on';
          elem.addClass('unselectable');

          elem.bind('touchstart', (event) => {
            scope.longTouchTriggered = false;
            timeoutHandler = $timeout(() => {
              scope.longTouchTriggered = true;
              fn(scope, { $event: event });
            }, 600);
          });

          elem.bind('touchend', (event) => {
            if (scope.longTouchTriggered) {
              event.preventDefault();
            }
            $timeout.cancel(timeoutHandler);
          });
        },
      };
    });
