import angular from 'angular';

class LoanAmountForAliCtrl {
  constructor($uibModalInstance, familyId, clientId) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.familyId = familyId;
    this.clientId = clientId;
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .controller('LoanAmountForAliCtrl', LoanAmountForAliCtrl);
