import template from './contactAddressTable.html';
import ContactAddressTableCtrl from './contactAddressTableCtrl';
import './style.scss';

export default {
  template,
  controller: ContactAddressTableCtrl,
  controllerAs: 'vm',
  bindings: {
    addressTitle: '@?',
    addressList: '<',
    editHandler: '<?',
    deleteHandler: '<?',
  },
};
