import template from './assistantSelect.html';
import controller from './assistantSelectCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    selectedAdviser: '<',
    selectedAssistant: '<',
    assistantsList: '<',
    simpleSelection: '<',
    onAssistantChange: '&',
    onClose: '&',
    isUseUibDropdown: '<?',
  },
};
