import template from './pipelineFilters.html';
import controller from './pipelineFiltersCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    pipelineType: '<',
    pipelineTypes: '<',
    isCorporateUser: '<',
    statusListRadio: '<',
    settings: '<',
    substatusList: '<',
    advisersList: '<',
    assistantAdviserList: '<',
    showAssistantFilter: '<',
    labelsList: '<',
    probabilityList: '<',
    enquirySourceList: '<',
    referrerList: '<',
    isSmallDevice: '<',
    onChangeSettings: '&',
    onChangeExcludeColumnsSettings: '&',
    onChangeStatusSettings: '&',
  },
};
