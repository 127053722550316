import angular from 'angular';
import swal from 'sweetalert';

class ResendSurveyModalCtrl {
  constructor(
    $uibModalInstance,
    surveyData,
    toaster,
    commonFnService,
    npsService,
  ) {
    this.$uibModalInstance = $uibModalInstance;
    this.surveyData = surveyData;
    this.toaster = toaster;
    this.commonFnService = commonFnService;
    this.npsService = npsService;
  }

  resendSurvey() {
    const email = this.surveyEmail;
    const isValidEmail = this.commonFnService.emailValidator(email);
    if (isValidEmail) {
      swal(
        {
          title: 'Resend NPS Survey?',
          text: `Please confirm you wish to resend the NPS survey to ${email}?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
        },
        (isConfirm) => {
          if (isConfirm) {
            this.npsService.resend(this.queueId, email).then(() => {
              this.toaster.pop(
                'success',
                'Successfully',
                'NPS survey successfully send',
              );
              this.$uibModalInstance.close();
            });
          }
        },
      );
    } else {
      swal('Oppss', 'Invalid email address. Please use other address', 'error');
    }
  }

  cancel() {
    this.$uibModalInstance.dismiss('cancel');
  }

  $onInit() {
    this.queueId = this.surveyData.QueueId || 0;
    this.surveyEmail = this.surveyData.Email || '';
  }
}
export const loadModule = () =>
  angular
    .module('app')
    .controller('ResendSurveyModalCtrl', ResendSurveyModalCtrl);
