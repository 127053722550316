import template from './editableTextField.html';
import controller from './editableTextFieldCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    id: '@',
    txModel: '<',
    name: '@',
    isRequired: '<',
    onSave: '&',
    onUpdate: '&',
    onFocusOut: '&',
    isFastLoad: '<',
    isInvalid: '<',
    minlength: '<?',
    maxlength: '<?',
    pattern: '@',
    patternMsg: '@',
    restrictedWords: '<',
  },
};
