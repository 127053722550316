export const emailType = 'email';
export const mailType = 'mail';
export const types = [
  {
    value: emailType,
    name: 'Email',
  },
  {
    value: mailType,
    name: 'Mail',
  },
];
