export function getAccountCheckBuilderForUI(data) {
  return {
    hasExistingAccount: data.HasExistingAccount,
    hasExistingSignature: data.HasExistingSignature,
    status: data.Status,
    displayName: data.DisplayName,
    redirectURL: data.RedirectURL,
  };
}
export function getSignDocumentBuilderForUI(data) {
  return {
    documents: data.Documents,
    signers: data.Signers,
  };
}

export function getDocumentsBuilderForUI(data) {
  return {
    status: data.Status,
    taggedDocumentType: data.TaggedDocumentType,
  };
}

export function esignSignaturesBuilderForUI(data) {
  return {
    fullName: data.FullName,
    email: data.Email,
    hasSigned: data.HasSigned,
  };
}

export function esignSignaturesFormat(data) {
  return (
    data &&
    data.filter((item) => !item.HasSigned).map(({ FullName }) => FullName)
  );
}

export function getPackageDocumentsBuilderForUI(data) {
  return {
    dateCreated: data.DateCreated,
    documentName: data.DocumentName,
    eSignId: data.ESignId,
    eSignPackageId: data.ESignPackageId,
    loanApplicationId: data.LoanApplicationId,
    signedDocumentId: data.SignedDocumentId,
    status: data.Status,
    unsignedDocumentId: data.UnsignedDocumentId,
    userId: data.UserId,
    documentId: data.DocumentId,
    isSendToLender: data.IsSendToLender,
    signatures:
      (data.Signatures && data.Signatures.map(esignSignaturesBuilderForUI)) ||
      [],
    notSignedNames: esignSignaturesFormat(data.Signatures) || [],
  };
}

export function getPackagesBuilderForUI(data) {
  return {
    brokerId: data.BrokerId,
    dateSent: data.DateSent,
    loanApplicationId: data.LoanApplicationId,
    packageId: data.PackageId,
    name: data.PackageName,
    senderId: data.SenderId,
    status: data.Status,
    taggedDocumentType: data.TaggedDocumentType,
    evidenceSummaryDocumentReference: data.EvidenceSummaryDocumentReference,
    isOpportunity: data.IsOpportunity,
    opportunityTitle: data.OpportunityTitle,
    adviserFullName: data.FullName,
    docFromContact: data.DocFromContact,
    docFromESign: data.DocFromESign,
    docFromLoanApp: data.DocFromLoanApp,
    documents:
      (data.Documents && data.Documents.map(getPackageDocumentsBuilderForUI)) ||
      [],
  };
}
