import template from './logoUploader.html';
import controller from './logoUploaderCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    extensions: '<',
    allowDelete: '<',
    backgroundColor: '<',
    brandingKey: '<',
    brandingData: '<',
    className: '<',
    autoUpdateNav: '<',
    onLogoSaved: '&',
  },
};
