import { LOAN_STRUCTURE_TYPE } from 'Common/constants/loanAppDiscount';
import { RATE_TYPE } from 'Common/constants/loan';
import { get } from 'lodash';
import {
  getInitialLabel,
  getOngoingLabel,
} from 'Common/utilities/discountRate';
import { catchError } from 'Common/utilities/promise';
class LoanAppDiscountsRateCtrl {
  constructor(
    $timeout,
    loanSplitModelService,
    loanScenarioModelService,
    currentUserService,
    loanAppSharedData,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.currentUserService = currentUserService;
    this.loanSplitModelService = loanSplitModelService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.loanAppSharedData = loanAppSharedData;
  }

  $onInit() {
    this.showLoader = true;
    this.rateTypes = RATE_TYPE;
    this.isNz = this.currentUserService.isNZ;
    if (!this.loanAppSharedData?.loanDetailsSet?.discountRates) {
      this.loanAppSharedData = {
        loanDetailsSet: {
          ...(this.loanAppSharedData?.loanDetailsSet ?? {}),
          discountRates: {},
        },
      };
    }
  }

  $onDestroy() {
    this.loadingTimeout && this.$timeout.cancel(this.loadingTimeout);
  }

  $onChanges(changes) {
    const { productId, interestRateType, loanStructureType } = changes;
    get(productId, 'currentValue') && this.getProductRate();
    get(interestRateType, 'currentValue') && this.getShowVariableInput();
    get(loanStructureType, 'currentValue') && this.getShowVariableInput();
    this.getLabels();
  }

  getLabels() {
    if (!this.product) {
      return;
    }
    const {
      fixedRateTerm,
      interestOnlyTerm,
      interestRateType,
      loanStructureType,
      loanTerm,
    } = this;
    const productInitialPeriod = this.product.initialPeriod / 12;
    const isFixed = interestRateType === RATE_TYPE.FIXED;
    const isPandI =
      loanStructureType === LOAN_STRUCTURE_TYPE.PRINCIPAL_AND_INTEREST;
    let introRate = 'Rate';
    let initialRateTerm = isPandI
      ? fixedRateTerm || productInitialPeriod
      : interestOnlyTerm || fixedRateTerm || productInitialPeriod;
    let interestRateTypeLabel = RATE_TYPE.FIXED;
    if (!isFixed) {
      interestRateTypeLabel = RATE_TYPE.VARIABLE;
      productInitialPeriod && (introRate = 'Intro Rate');
      initialRateTerm = productInitialPeriod || 0;
      if (!isPandI) {
        initialRateTerm = productInitialPeriod
          ? interestOnlyTerm || productInitialPeriod
          : interestOnlyTerm || 0;
      }
    }
    this.assignLabel(
      getInitialLabel({
        interestRateTypeLabel,
        isPandI,
        introRate,
        initialRateTerm,
      }),
      getOngoingLabel({ loanTerm, initialRateTerm }),
    );
  }

  assignLabel(initialLabel, ongoingLabel) {
    this.loanAppSharedData.loanDetailsSet.discountRates[
      this.brokerEventId
    ].initial.label = this.showVariableInput ? ongoingLabel : initialLabel;
    this.loanAppSharedData.loanDetailsSet.discountRates[
      this.brokerEventId
    ].ongoing.label = ongoingLabel;
  }

  updateRepayment() {
    if (this.loanId && this.loanStructureId) {
      return this.sendUpdatedRate({
        loanStructureId: this.loanStructureId,
        loanId: this.loanId,
      });
    }
  }

  getProductRate() {
    if (!this.productId || !this.brokerEventId) {
      return;
    }
    this.loanSplitModelService
      .getProductRate(this.brokerEventId, this.productId)
      .then((data) => {
        if (!data) {
          return;
        }
        const {
          initial,
          ongoing,
          initialReferenceProduct,
          ongoingReferenceProduct,
          ...props
        } = data;
        this.loanAppSharedData.loanDetailsSet.discountRates[
          this.brokerEventId
        ] = {
          initial,
          ongoing,
        };
        this.ratesToSave = [
          { ...initial, referenceProduct: initialReferenceProduct },
          { ...ongoing, referenceProduct: ongoingReferenceProduct },
        ];
        this.ongoingReferenceRate = ongoingReferenceProduct;
        this.initialReferenceRate = initialReferenceProduct;
        this.product = props;
        this.getProductReferenceRate();
        this.getShowVariableInput();
        this.getShowFloatingInput();
        this.getLabels();
      })
      .catch(catchError);
  }

  getProductReferenceRate() {
    if (!this.productId || !this.loanAmount || !this.loanId) {
      return;
    }
    const param = {
      loanStructureId: this.product.productLoanStructureId,
      loanId: this.loanId,
      loanAmount: this.loanAmount,
    };
    this.loanScenarioModelService
      .getReferenceRates(this.productId, param)
      .then((data) => {
        if (!data) {
          return;
        }
        const {
          initialReferenceRateProducts,
          ongoingReferenceRateProducts,
        } = data;
        this.initialReferenceRates = initialReferenceRateProducts.filter(
          (rate) =>
            rate.rate >
            this.loanAppSharedData.loanDetailsSet.discountRates[
              this.brokerEventId
            ].initial.productRate,
        );
        this.ongoingReferenceRates = ongoingReferenceRateProducts.filter(
          (rate) =>
            rate.rate >
            this.loanAppSharedData.loanDetailsSet.discountRates[
              this.brokerEventId
            ].ongoing.productRate,
        );
        this.showLoader = false;
      })
      .catch(catchError);
  }

  getShowFloatingInput() {
    if (!this.product) {
      return;
    }
    const { initialPeriod } = this.product;
    this.isFloating =
      this.interestRateType === RATE_TYPE.FLOATING ||
      this.interestRateType === RATE_TYPE.REVOLVING_CREDIT;

    this.loanAppSharedData.loanDetailsSet.discountRates[
      this.brokerEventId
    ].initial = {
      ...this.loanAppSharedData.loanDetailsSet.discountRates[this.brokerEventId]
        .initial,
      isFloating: this.isFloating,
    };
    this.showFloatingInput = this.isFloating && !initialPeriod && this.isNz;
  }

  getShowVariableInput() {
    if (!this.product) {
      return;
    }
    const { initialPeriod } = this.product;
    this.isFixed = this.interestRateType === RATE_TYPE.FIXED;
    this.loanAppSharedData.loanDetailsSet.discountRates[
      this.brokerEventId
    ].initial = {
      ...this.loanAppSharedData.loanDetailsSet.discountRates[this.brokerEventId]
        .initial,
      isFixed: this.isFixed,
    };
    this.showVariableInput =
      !this.isFixed &&
      !initialPeriod &&
      this.loanStructureType === LOAN_STRUCTURE_TYPE.PRINCIPAL_AND_INTEREST;
  }
}

export default LoanAppDiscountsRateCtrl;
