import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'GetAnnualReviewDetailModalCtrl',
      function GetAnnualReviewDetailModalCtrl(
        $scope,
        $uibModalInstance,
        annualReview,
      ) {
        $scope.annualReview = annualReview;
        $scope.cancel = () => {
          $uibModalInstance.dismiss('cancel');
        };
      },
    );
