import template from './activeLoanList.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    leadList: '<',
    applicationList: '<',
    loanStatusList: '<',
    loanDetailScope: '<',
    lendingDetailScope: '<',
    activeDeals: '<',
    isNewPipelineEnabled: '<',
  },
};
