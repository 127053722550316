import angular from 'angular';

export const loadModule = () =>
  angular.module('app').factory('simpologyLoanAppModel', [
    'httpRequest',
    function (httpRequest) {
      var apiBaseUrl = 'SimpologyLoanApp/';

      function simpologyLoanApp() {}

      simpologyLoanApp.prototype = {
        SimpologyLoanApplicationSubmission(
          loanApplicationId,
          callback,
          errorCallback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}SimpologyLoanApplicationSubmission?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
            (error) => {
              errorCallback(error);
            },
          );
        },
        SimpologyLoanApplicationSubmissionStatus(
          loanApplicationId,
          callback,
          errorCallback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}SimpologyLoanApplicationSubmissionStatus?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
            (error) => {
              errorCallback(error);
            },
          );
        },
        SimpologyLoanApplicationView(
          loanApplicationId,
          callback,
          errorCallback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}SimpologyLoanApplicationView?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
            (error) => {
              if (!errorCallback) return;
              errorCallback(error);
            },
          );
        },
      };

      return simpologyLoanApp;
    },
  ]);
