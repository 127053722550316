import {
  clientColumnContent,
  timeFrameColumnContent,
} from '../../contactAddressTable/util/contactAddressTable';

export const employerColumnContent = (employerName) => {
  return `
    <div style="
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ">${employerName || ''}</div>
  `;
};
export const typeColumnContent = (type) => {
  return `
    <div>${type || ''}</div>
  `;
};
export const roleColumnContent = (role) => {
  return `
    <div>${role || ''}</div>
  `;
};

export const createEmploymentColumnContent = (employment) => {
  return employment.map((employmentRow) => {
    return {
      client: clientColumnContent([{ ...employmentRow }]),
      employer: employerColumnContent(`${employmentRow.EmployerName || ''}`),
      type: typeColumnContent(employmentRow.EmploymentType),
      role: roleColumnContent(employmentRow.EmploymentRole),
      timeframe: timeFrameColumnContent({
        startDate: employmentRow.StartDateTime,
        endDate: employmentRow.EndDateTime,
        isCurrent: employmentRow.isCurrent,
        duration: employmentRow.EmploymentDuration,
      }),
      editParams: { items: employmentRow, action: 'Edit' },
      deleteParams: {
        id: employmentRow.EmploymentId,
        index: employmentRow.key,
      },
    };
  });
};
