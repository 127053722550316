import template from './educationQualificationsTable.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    type: '<',
    headerList: '<',
    rowItems: '<',
    isLoading: '<',
    preloaderText: '<',
    sortOrder: '<',
    onSortDate: '&',
    onDownloadDocument: '&',
    onEdit: '&',
    onDelete: '&',
  },
};
