import angular from 'angular';
import { hasFirstnameOrLastname, getFullName } from 'Common/utilities/contact';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import { AU_STATE_FOR_ASSET } from 'Common/constants/australianState';
import { VEHICLE_TYPE } from 'Common/constants/iFinance';
import {
  DEFAULT_VALUES,
  ASSET_TYPE,
  STEPS,
} from 'Common/constants/securityValues';

class AssetFinanceDetailsCtrl {
  constructor(
    loanScenarioService,
    loanApplicationServices,
    optionsService,
    utilitiesService,
    colorService,
    iFinanceAppService,
    configService,
    dashboardService,
    currentUserService,
    loanAppSharedData,
    toaster,
    SweetAlert,
  ) {
    'ngInject';

    this.loanScenarioService = loanScenarioService;
    this.loanApplicationServices = loanApplicationServices;
    this.optionsService = optionsService;
    this.utilitiesService = utilitiesService;
    this.colorService = colorService;
    this.iFinanceAppService = iFinanceAppService;
    this.configService = configService;
    this.dashboardService = dashboardService;
    this.currentUserService = currentUserService;
    this.loanAppSharedData = loanAppSharedData;
    this.toaster = toaster;
    this.sweetAlert = SweetAlert;
    this.hasFirstnameOrLastname = hasFirstnameOrLastname;
    this.getFullName = getFullName;
    this.australianStates = AU_STATE_FOR_ASSET;
    this.ASSET_TYPE = ASSET_TYPE;
    this.STEPS = STEPS;

    this.CONDITION_MAX = {
      MOTOR_VEHICLE: 3,
      OTHERS: 2,
    };
  }

  $onInit() {
    this.toggleAssetDisplay();
    this.isLoading = false;
    this.isShowSucess = false;
    this.isSubmitDisabled = false;
    this.conditionMaxLength = this.CONDITION_MAX.OTHERS;
    this.assetStepNumber = 0;
    this.iFinanceParams = {};
    this.showOtherDetails = false;
    this.isDetailsFetching = false;
    this.isManualInput = false;
    this.vehicleModelList = [];
    this.vehicleClassList = [];
    this.selectedAddress = '';
    this.monthFirstSearch = true;

    this.getAssetSecurity();
    this.getSecurityOptions();
    this.getApplicants();
    this.getEmptySecurityInfo();
    this.getSecurityOptions();

    this.setDefaultValues();
    this.setOwnersValues();
    this.getYearList();
  }

  getAssetSecurity() {
    this.assetsList = [];
    this.loanScenarioService
      .getLoanSecurity(this.loanAppId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        this.assetsList = data.SecurityList;
      });
  }

  getEmptySecurityInfo() {
    this.asset = {
      LoanApplicationId: this.loanAppId,
      SecurityInfoId: 0,
      IsPrimary: true,
      SecurityAssetTypeID: '',
      TransactionId: '',
      Address: [],
      IsRefinance: false,
      PropertyDetails: {
        EstimatedPrice: '',
        EstimatedBasesId: '',
        LoanSecurityId: 0,
        StatusId: '',
        PropertyPrimaryPurposeId: '1',
        HoldingId: '',
        ZoningId: '',
        PropertyTypeId: '',
        CurrentValue: '',
        LandValue: '',
        ContratctPrice: '',
        ContractDate: '',
        LicensedRealEstateAgentContract: true,
        IsPreApproved: true,
        AddressId: 0,
        TitleTypeId: '1',
        TitleId: '',
        PropertyState: '',
        Suburb: '',
        IdentifierList: [
          {
            Security_TitleIdentityId: 0,
            IdentityTypeId: '',
            Value: '',
          },
        ],
        ContactForAccess: {
          ContactForAccess: 0,
          CompanyName: '',
          ContactTitle: '',
          ContactFirstName: '',
          ContactLastName: '',
          PhoneAreaCode: '',
          PhoneNumber: '',
          FaxAreaCode: '',
          FaxNumber: '',
          ContactTypeId: '',
        },
      },
      Ownership: [],
      MortgageList: [],
      VehicleDetails: {},
      FinanceAssetTypeId: 0,
      SaleTypeId: 0,
      FinanceCategoryId: 0,
      FinanceLenderId: 0,
      FinanceAmount: 0,
      IsAssetFinance: true,
    };
  }

  setDefaultValues() {
    this.asset.PropertyDetails.StatusId = DEFAULT_VALUES.ESTABLISHED_STATUSID;
    this.asset.PropertyDetails.PropertyPrimaryPurposeId =
      DEFAULT_VALUES.OWNEROCCUPIED_PRIMARYPURPOSE;
    this.asset.PropertyDetails.HoldingId =
      this.loanAppSharedData.applicantsLength(
        this.loanAppSharedData.listApplicants,
      ) === 1
        ? DEFAULT_VALUES.SOLE_HOLDINGID
        : DEFAULT_VALUES.JOINT_HOLDINGID;
    this.asset.PropertyDetails.ZoningId = DEFAULT_VALUES.RESIDENTIAL_ZONINGID;
  }

  getSecurityOptions() {
    this.optionsService.getAssetFinanceSecurityType().then((response) => {
      if (!response.length) {
        return;
      }
      // eslint-disable-next-line sonarjs/no-duplicate-string
      this.assetTypeList = [{ name: 'Please select', value: 0 }, ...response];
    });

    this.optionsService.getAssetFinanceSaleType().then((response) => {
      if (!response.length) {
        return;
      }
      this.saleTypeList = [{ name: 'Please select', value: 0 }, ...response];
    });

    this.optionsService.getAssetFinanceVehicleCondition().then((response) => {
      if (!response.length) {
        return;
      }
      this.vehicleConditionList = response;
    });

    this.optionsService.getAssetFinanceCategory().then((response) => {
      if (!response.length) {
        return;
      }
      this.financeCategoryList = [
        { name: 'Please select', value: 0 },
        ...response,
      ];
    });

    this.optionsService.getAssetFinanceVehicleClass().then((response) => {
      if (!response.length) {
        return;
      }
      this.vehicleClassList = [
        { name: 'Please select', value: 0 },
        ...response,
      ];
    });

    const LENDER_PLACE = 1;
    this.optionsService.getLenderList(LENDER_PLACE, true).then((response) => {
      if (!response.length) {
        return;
      }
      this.FinanceLenderList = [
        { providerName: 'Please select', providerId: 0 },
        ...response,
      ];
    });

    this.optionsService.getAssetValueEstimatedBasis(true).then((response) => {
      if (!response.length) {
        return;
      }
      this.valuationBasisList = [
        { name: 'Please select', value: 0 },
        ...response,
      ];
    });
  }

  getYearList() {
    this.iFinanceAppService.getYearManualList().then((response) => {
      this.yearManualList = response;
    });
  }

  getApplicants() {
    this.loanScenarioService
      .scenarioApplicantListGet(this.loanAppId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        const { InvolvedPartyPerson, InvolvedPartyEntity } = data;

        this.scenarioApplicantList = [];
        let countApplicants = 0;
        countApplicants += Object.keys(InvolvedPartyEntity).length;
        Object.keys(InvolvedPartyPerson).forEach((familyId) => {
          countApplicants += InvolvedPartyPerson[familyId].length;
        });
        const percentage = (100 / countApplicants).toFixed(2);

        angular.forEach(InvolvedPartyPerson, (family, familyId) => {
          const applicant = family.map((client) => {
            return {
              Percentage: percentage,
              BorrowerID: parseInt(client.PersonId, 10),
              familyId,
              ClientID: parseInt(client.PersonId, 10),
              FirstName: client.FirstName,
              ClientType: client.Role,
              LastName: client.LastName,
              PreferedName: `${client.FirstName} ${client.LastName}`,
              IsInclude: true,
              IsTicked: true,
              OwnershipPercentage: percentage,
              ticked: true,
              IsEntity: false,
              IsClient: true,
              initials: this.utilitiesService.filterInitialOneString(
                client.FirstName,
              ),
            };
          });
          this.scenarioApplicantList = this.scenarioApplicantList.concat(
            applicant,
          );
        });

        const applicant = InvolvedPartyEntity.map((client) => {
          return {
            Percentage: percentage,
            familyId: client.FamilyId,
            BorrowerID: parseInt(client.OrganisationId, 10),
            EntityID: parseInt(client.OrganisationId, 10),
            ClientType: client.EntityTypeName,
            PreferedName: client.FamilyFullName,
            IsInclude: true,
            IsEntity: true,
            IsTicked: true,
            OwnershipPercentage: percentage,
            ticked: true,
            initials: this.utilitiesService.filterInitialOneString(
              client.FirstName,
            ),
            background: this.colorService.getRandomColor(),
          };
        });
        this.scenarioApplicantList = this.scenarioApplicantList.concat(
          applicant,
        );

        let isOneFamily = false;
        if (this.scenarioApplicantList.length === 1) {
          isOneFamily = true;
        } else if (this.scenarioApplicantList.length === 2) {
          isOneFamily =
            this.scenarioApplicantList[0].familyId ===
            this.scenarioApplicantList[1].familyId;
        }

        this.ownerGroupProperty = isOneFamily ? '' : 'familyId';
      });
  }

  getSecurityInfo(securityInfoId) {
    this.loanApplicationServices
      .getLoanSecurityInfo(this.loanAppId, securityInfoId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        this.asset = data;
        this.asset.IsAssetFinance = true;

        this.enterManually();
        this.preselectOwners();
        this.assetTypeChanged(this.asset.FinanceAssetTypeId);
        this.formatAddress();

        const selectedVehicleClass = this.vehicleClassList.find(
          (element) =>
            element.value === this.asset.VehicleDetails.VehicleClassID &&
            element.value !== 0,
        );
        this.SelectedVehicleClass =
          selectedVehicleClass && selectedVehicleClass.name;
      });
  }

  preselectOwners() {
    const collectionBorrowerID = [];

    this.asset.Ownership.forEach((obj) => {
      collectionBorrowerID.push(obj.BorrowerID);
    });

    this.scenarioApplicantList = this.scenarioApplicantList.map((obj) => {
      if (collectionBorrowerID.includes(obj.BorrowerID)) {
        obj.IsTicked = true;
        obj.ticked = true;
        const findOwner = this.asset.Ownership.find(
          (owner) => owner.BorrowerID === obj.BorrowerID,
        );
        obj.Percentage = findOwner ? findOwner.Percentage : obj.Percentage;
      } else {
        obj.Percentage = 0;
        obj.IsTicked = false;
        obj.ticked = false;
      }
      return obj;
    });
  }

  setOwnersValues() {
    this.ownersButtonLabels = [{ label: 'PreferedName' }];
    this.ownersItemLabels = [
      { label: 'PreferedName', isEdit: false, isText: true },
    ];

    if (
      this.configService.feature &&
      this.configService.feature.securityOwnersPercentage
    ) {
      this.ownersButtonLabels.push({ label: 'Percentage', isPercentage: true });
      this.ownersItemLabels.push({
        label: 'Percentage',
        isEdit: true,
        containerClass: 'input-type-percentage',
        inputClass: 'text-input',
        isPercentage: true,
        inputWidth: '60px',
      });
      this.ownersDisableEdit = false;
    }
  }

  updateConditions() {
    this.forVehicleOnly =
      this.asset.FinanceAssetTypeId === this.ASSET_TYPE.MOTOR_VEHICLE;
    this.showSteps =
      !this.isManualInput &&
      this.currentUserService.isAU &&
      this.forVehicleOnly;
    this.isVehicleAuto =
      !this.showOtherDetails &&
      this.currentUserService.isAU &&
      !this.isManualInput;

    this.isNotOthersTertiary =
      this.asset.FinanceAssetTypeId !== this.ASSET_TYPE.TERTIARY_ASSETS &&
      this.asset.FinanceAssetTypeId !== this.ASSET_TYPE.OTHER;
    this.showMakeManual = this.isNotOthersTertiary;
    this.showModelManual = this.isNotOthersTertiary;
    this.showMake = this.isNotOthersTertiary && this.assetStepNumber > 0;
    this.showModel =
      this.isNotOthersTertiary && this.assetStepNumber > this.STEPS.THIRD;
    this.showFamily =
      this.forVehicleOnly && this.assetStepNumber > this.STEPS.FIRST;
    this.showVehiclePreview = this.showOtherDetails && this.forVehicleOnly;
    this.showManualInputs =
      this.isManualInput ||
      !this.currentUserService.isAU ||
      this.asset.FinanceAssetTypeId !== this.ASSET_TYPE.MOTOR_VEHICLE;
    this.showOtherVehicleInput =
      this.showOtherDetails ||
      !this.currentUserService.isAU ||
      this.isManualInput ||
      this.asset.FinanceAssetTypeId !== this.ASSET_TYPE.MOTOR_VEHICLE;
    this.showOdometer =
      this.forVehicleOnly ||
      this.asset.FinanceAssetTypeId === this.ASSET_TYPE.EQUIPMENT;
    this.showSaveBtn =
      !this.showOtherDetails &&
      !this.isManualInput &&
      this.asset.VehicleDetails.Model &&
      this.assetStepNumber === this.STEPS.FIFTH;
    this.showManualLink =
      !this.isManualInput && !this.showOtherDetails && this.forVehicleOnly;
  }

  enterManually(bool = true) {
    this.isManualInput = bool;
    if (!bool) {
      this.resetVehicleValues();
    }
    this.updateConditions();
  }

  resetVehicleValues() {
    this.asset.VehicleDetails.VehicleClassID = 0;
    this.asset.VehicleDetails.Make = '';
    this.asset.VehicleDetails.Family = '';
    this.asset.VehicleDetails.Month = '';
    this.asset.VehicleDetails.Year = '';
    this.asset.VehicleDetails.RedBookCode = '';
    this.asset.VehicleDetails.Model = '';
    this.assetStepNumber = 0;
  }

  assetTypeChanged(financeAssetTypeId) {
    this.conditionMaxLength = this.CONDITION_MAX.OTHERS;
    if (financeAssetTypeId === this.ASSET_TYPE.MOTOR_VEHICLE) {
      this.conditionMaxLength = this.CONDITION_MAX.MOTOR_VEHICLE;
    }
    this.updateConditions();
  }

  formatAddress() {
    if (!isValidObjectValue(() => this.asset.PropertyDetails.AddressId)) {
      return;
    }
    this.dashboardService
      .addressDetailsGet(this.asset.PropertyDetails.AddressId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        this.asset.Address = data;
        this.selectedAddress = data.formatted_address;
      });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  findVehicle(input, stepNumber, collectionName, dateType = 'month') {
    const vehicleType =
      input === 1
        ? VEHICLE_TYPE.LIGHT_COMMERCIAL
        : VEHICLE_TYPE.HEAVY_COMMERCIAL;
    const { VehicleClassID, Make } = this.asset.VehicleDetails;
    this.iFinanceParams = {
      vehicleType: VehicleClassID,
    };

    switch (stepNumber) {
      case STEPS.SECOND:
        this.iFinanceParams.make = input;
        break;
      case STEPS.THIRD:
        this.iFinanceParams.make = Make;
        this.iFinanceParams.family = input;
        this.monthFirstSearch = true;
        break;
      case STEPS.FORTH:
        this.setIFinanceParamsByYear();
        this.iFinanceParams[dateType] = input;
        break;
      default:
        this.iFinanceParams = { vehicleType };
        break;
    }
    this.isDetailsFetching = true;
    this.iFinanceAppService
      .vehicleFinder(this.iFinanceParams)
      .then(({ data }) => {
        if (!data || !data.data) {
          if (
            !this.monthFirstSearch ||
            (stepNumber !== STEPS.FORTH && dateType === 'month')
          ) {
            this.toaster.pop(
              'info',
              'No result found.',
              'Enter manually instead',
            );
          }
          if (stepNumber === STEPS.FORTH && dateType === 'month') {
            this.monthFirstSearch = false;
          }

          this[collectionName] = [];
          this.stepChanged('', stepNumber);
          return;
        }
        if (stepNumber === STEPS.THIRD) {
          this.yearList = data.YearList;
          this.monthList = data.MonthList;
        } else {
          this[collectionName] = data.data;
        }
        this.stepChanged(input, stepNumber);
      })
      .finally(() => {
        this.isDetailsFetching = false;
      });
  }

  setIFinanceParamsByYear() {
    const { Make, Family, Month, Year } = this.asset.VehicleDetails;
    this.iFinanceParams.make = Make;
    this.iFinanceParams.family = Family;
    this.iFinanceParams.month = Month;
    this.iFinanceParams.year = Year;
  }

  stepChanged(value, stepNumber) {
    this.assetStepNumber =
      value || this.loanSecurityInfoId ? stepNumber : stepNumber - 1;
    this.updateConditions();
  }

  modelChanged(rbc) {
    const { VehicleClassID } = this.asset.VehicleDetails;
    this.vehicleModelList.filter((data) => {
      if (data.rbc === rbc) {
        this.asset.VehicleDetails.Model = data.description;
      }
      return data;
    });

    this.vehicleClassList.filter((data) => {
      if (data.value === VehicleClassID) {
        this.SelectedVehicleClass = data.name;
      }
      return data;
    });

    this.stepChanged(rbc, STEPS.FIFTH);
  }

  previewVehicle(preview = true) {
    this.showOtherDetails = preview;
    this.updateConditions();
  }

  addAsset() {
    this.toggleAssetDisplay(false);
    this.getEmptySecurityInfo();
    this.enterManually();
  }

  updateAsset(asset) {
    this.toggleAssetDisplay(false);
    this.getSecurityInfo(asset.SecurityInfoId);
  }

  isAddressChanged() {
    return (
      isValidObjectValue(() => this.asset.Address.formatted_address) &&
      this.selectedAddress !== this.asset.Address.formatted_address
    );
  }

  saveAsset() {
    const { FinanceAssetTypeId, SaleTypeId, Ownership } = this.asset;
    if (!FinanceAssetTypeId || !SaleTypeId || !Ownership.length) {
      this.toaster.pop('error', 'Please fill all required fields.');
      return;
    }

    this.isLoading = true;
    if (!this.isAddressChanged()) {
      this.proceedAddSecurity();
      return;
    }
    this.saveAddress();
  }

  saveAddress() {
    this.dashboardService.addressDetailsSet(this.asset.Address).then((res) => {
      this.asset.PropertyDetails.AddressId = res.data || 0;
      this.proceedAddSecurity();
    });
  }

  proceedAddSecurity() {
    const { Address, ...securityInfo } = this.asset;
    const newSecurityInfo = {
      ...securityInfo,
      LoanApplicationId: this.loanAppId,
    };

    this.loanApplicationServices
      .setLoanSecurityInfo(newSecurityInfo)
      .then(() => {
        this.toaster.pop('info', 'Asset successfully saved!');
        this.resetAssetList();
      })
      .catch(() => {
        this.toaster.pop('error', 'Something went wrong. Please try again.');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  deleteSecurity(asset) {
    this.sweetAlert.swal(
      {
        title: 'Are you sure?',
        text: 'This asset will be removed from your list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      },
      (confirm) => {
        if (confirm) {
          this.loanApplicationServices
            .deleteLoanSecurityInfo(
              this.loanAppId,
              asset.SecurityInfoId,
              asset.FundingCalParametersId ? asset.FundingCalParametersId : 0,
            )
            .then(() => {
              this.resetAssetList();
            });
        }
      },
    );
  }

  resetAssetList() {
    this.getAssetSecurity();
    this.getEmptySecurityInfo();
    this.toggleAssetDisplay();
  }

  cancel() {
    this.toggleAssetDisplay();
  }

  toggleAssetDisplay(showTable = true) {
    this.showAssetsTable = showTable;
    this.showAssetDetails = !showTable;
  }
}

export default AssetFinanceDetailsCtrl;
