import moment from 'moment';

class IdentificationService {
  constructor(toaster, $uibModal) {
    'ngInject';

    this.toaster = toaster;
    this.$uibModal = $uibModal;
  }

  isIdentificationValid(data) {
    this.isValid = false;
    if (data && data.IssueDate && !moment(data.IssueDate).isValid()) {
      this.toaster.pop(
        'error',
        'Invalid Issue Date',
        'Please fill up the form correctly',
      );
    } else {
      this.isValid = true;
    }
    return this.isValid;
  }

  addEditIdentificationModal(args = {}) {
    const {
      modalType,
      identification,
      familyId,
      loanAppId,
      documentTypeList,
      clientsList,
    } = args;

    const identificationSet = {
      IdentificationId: 0,
      ClientEntityId: null,
      IsClient: true,
      NameOnDoc: '',
      DocumentName: '',
      DocumentTypeId: null,
      DocumentTypeName: '',
      DocumentNumber: '',
      IssueDate: null,
      ExpiryDate: null,
      IssueBy: '',
      PlaceOfissue: '',
      CountryCode: 'AU',
      CountryName: '',
      ClientFirstName: '',
      ClientLastName: '',
      IsVerified: false,
      CopyType: '',
    };

    if (modalType !== 'new') {
      if (!identification) {
        return;
      }

      Object.assign(identification, {
        DocumentTypeId:
          (identification.DocumentTypeId &&
            identification.DocumentTypeId.toString()) ||
          '',
        ClientEntityId:
          (identification.ClientEntityId &&
            identification.ClientEntityId.toString()) ||
          '',
        IssueDate:
          (identification.IssueDate && new Date(identification.IssueDate)) ||
          null,
        ExpiryDate:
          (identification.ExpiryDate && new Date(identification.ExpiryDate)) ||
          null,
      });
      Object.assign(identificationSet, identification);
    }

    const props = {
      modalType,
      familyId,
      loanAppId,
      identificationSet,
      documentTypeList,
      clientsList,
    };

    return this.$uibModal.open({
      template: `<identification-modal
        modal-instance="vm.modalInstance"
        modal-type="vm.props.modalType"
        family-id="vm.props.familyId"
        loan-app-id="vm.props.loanAppId"
        identification-set="vm.props.identificationSet"
        document-type-list="vm.props.documentTypeList"
        clients-list="vm.props.clientsList"
      ></identification-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      size: 'sm',
      resolve: {
        props: () => props,
      },
      keyboard: false,
      backdrop: 'static',
    }).result;
  }
}

export default IdentificationService;
