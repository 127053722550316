import swal from 'sweetalert';
import {
  isEsignPackagePending,
  isEsignPackageCancelled,
  isEsignPackageCompleted,
  packageDocumentDownload,
} from 'Common/utilities/eSignDocument';
import { alertSucess } from 'Common/utilities/alert';
import { COLOR } from 'Common/constants/colors';
import { objectLength } from 'Common/utilities/objectValidation';
import { PACKAGE_STATUS, DOCUMENT } from 'Common/constants/eSign';
import { getPackagesBuilderForUI } from 'Common/mappers/eSign';

class ESignDocumentCtrl {
  constructor($timeout, downloadDataService, eSignService) {
    'ngInject';

    this.$timeout = $timeout;
    this.downloadDataService = downloadDataService;
    this.eSignService = eSignService;

    this.utilPackageDocumentDownload = packageDocumentDownload;
  }

  $onInit() {
    if (this.document) {
      if (this.remapDocument) {
        this.document = getPackagesBuilderForUI(this.document);
      }

      const { docFromESign, status } = this.document;

      this.document.isEsignPackagePending = isEsignPackagePending(
        docFromESign,
        status,
      );
      this.document.isEsignPackageCancelled = isEsignPackageCancelled(
        docFromESign,
        status,
      );
      this.document.isEsignPackageCompleted = isEsignPackageCompleted(
        docFromESign,
        status,
      );
    }
  }

  $onDestroy() {
    this.waitCallBack && this.$timeout.cancel(this.waitCallBack);
  }

  packageDocumentDownload(doc = {}, loadingProp, documentId) {
    doc[loadingProp] = true;

    this.utilPackageDocumentDownload(this.downloadDataService)(documentId).then(
      () => {
        doc[loadingProp] = false;
      },
    );
  }

  reSendPackage(esignPackage) {
    if (
      !esignPackage ||
      !esignPackage.packageId ||
      !esignPackage.loanApplicationId
    ) {
      return;
    }
    const { packageId, loanApplicationId } = esignPackage;
    swal(
      {
        title: 'Please confirm action',
        text: 'Are you sure you want to resend this package?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: COLOR.SCOOTER,
        confirmButtonText: 'Resend',
        closeOnConfirm: false,
      },
      (isConfirm) => {
        if (isConfirm) {
          alertSucess('Package successfully resent.');
          this.eSignService.getNotifySigners(packageId, loanApplicationId);
        }
      },
    );
  }

  checkIfCancelAvailable(eSignPackage) {
    if (!eSignPackage || !objectLength(eSignPackage)) {
      return;
    }
    const { status, documents, packageId } = eSignPackage;
    const checkSignedDocument = documents.filter(
      (eSignDocument) => eSignDocument.status === DOCUMENT.SIGNED,
    );
    const isCancellable =
      status !== PACKAGE_STATUS.PACKAGE_COMPLETE &&
      status !== PACKAGE_STATUS.PACKAGE_TRASHED &&
      (!checkSignedDocument || !checkSignedDocument.length);
    if (isCancellable) {
      this.cancelPackage(packageId);
      return;
    }
    swal({
      title: 'Unable to cancel/delete signed packages',
      text:
        'At least one document in this package has been signed by the client, and therefore, can not be cancelled or deleted.  Possible options include; detach the document from the loan application, or, generate a new document to be sent for signing by the client.',
      type: 'info',
      confirmButtonText: 'Ok',
      closeOnConfirm: true,
    });
  }

  cancelPackage(packageId) {
    if (!packageId) {
      return;
    }
    swal(
      {
        title: 'Cancel Signature Request',
        text:
          'By cancelling this request, client(s) will no longer be able to view or sign the document(s).  The generated PDF(s) will be removed from the MyCRM and E-Sign platforms and will need to be regenerated.  If this is the desired action, please confirm to proceed.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: COLOR.GERALDINE,
        confirmButtonText: 'Cancel & Delete',
        closeOnConfirm: false,
      },
      (isConfirm) => {
        if (isConfirm) {
          this.eSignService.deletePackage(packageId).then((response) => {
            if (!response || !response.data) {
              return;
            }
            swal('Success', 'Package successfully cancelled.', 'success');
            this.waitCallBack = this.$timeout(() => {
              this.onRefreshDocumentsList();
            }, 2000);
          });
        }
      },
    );
  }
}

export default ESignDocumentCtrl;
