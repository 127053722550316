import template from './helloPackDeal.html';
import controller from './helloPackDealCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    model: '<',
    handler: '<',
    oneTouchPrivacy: '<?',
    getPrivacyConsents: '&',
  },
};
