import { LOAN_CATEGORIES } from 'Common/constants/loanCategories';
import { MODAL_SECTION } from 'Common/constants/generateReports';

export default class MultiLoanCqpModalCtrl {
  constructor($state, contactService, loanAppSharedData, $uibModal) {
    'ngInject';

    this.$state = $state;
    this.contactService = contactService;
    this.loanAppSharedData = loanAppSharedData;
    this.$uibModal = $uibModal;
  }

  $onInit() {
    this.maxLoansDownloable = 3;
    this.selectedLoanScenarioID = null;
    this.isLoading = true;

    const familyId = this.$state.params.familyId;
    this.contactService.loanListGet(familyId, false).then(({ data }) => {
      this.isLoading = false;
      if (!data) {
        return;
      }

      const inprogressLoans =
        data.filter(
          (loan) =>
            loan.LoanStatus.LoanStatusCategory === LOAN_CATEGORIES.INPROGRESS,
        ) || [];

      inprogressLoans.map((loan) => {
        if (loan.Lender) {
          loan.Lender = {
            ...loan.Lender,
            LenderId: loan.Lender.LenderId
              ? parseInt(loan.Lender.LenderId, 10)
              : 0,
          };
        }
        loan.LoanPurpose = loan.LoanPurpose ? loan.LoanPurpose.split(',') : '';
        return loan;
      });

      this.inprogressLoans = this.loanAppSharedData.distinguishPartyForLendingTab(
        inprogressLoans,
      );
    });
  }

  nextStep() {
    if (!this.selectedLoanScenarioID) {
      return;
    }

    this.$uibModal.open({
      templateUrl:
        '/assets/views/loanApplication/sidebar/modal/recommendationReport.html',
      controller: 'RecommendationModalCtrl',
      windowClass: 'mc-recommendation-report-modal',
      size: 'md',
      resolve: {
        multiLoanCpaIds: () => null,
        activeModalSection: () => MODAL_SECTION.CQP,
        eSignSubscription: () => false,
        loanAppId: () => this.selectedLoanScenarioID,
      },
    });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}
