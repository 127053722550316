import { checkHideDeleteTransaction } from 'Assets/js/controllers/contacts/client/util/viewEmailCtrl.js';

export const listDeleteHide = (emailList, isHideDeleteFlagEnabled) => {
  return emailList.map((email) => {
    return {
      ...email,
      isHideDeleteTransaction: checkHideDeleteTransaction({
        emailSubject: email.Subject,
        isHideDeleteFlagEnabled,
      }),
    };
  });
};
