class SMSTemplatesManagementCtrl {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;
    this.isCorporateUser = uiService.isCorporateUser;
  }

  $onInit() {
    this.tabs = [
      {
        name: 'SMS Templates',
        value: 'smsTemplates',
        disable: false,
        isCorporateOnly: false,
      },
      {
        name: 'Sharing and Privacy',
        value: 'sharingAndPrivacy',
        disable: false,
        isCorporateOnly: true,
      },
    ];
  }

  closeModal() {
    if (!this.modalInstance) {
      return;
    }
    this.modalInstance.close(this.hasChanges);
  }

  onTemplatesUpdated() {
    this.hasChanges = true;
  }
}

export default SMSTemplatesManagementCtrl;
