import template from './marryContactsModal.html';
import controller from './marryContactsModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    clientsToMerge: '<',
    countryCode: '<',
    refreshContactList: '&',
  },
};
