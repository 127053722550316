import angular from 'angular';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import {
  APPROVAL_EXPIRIES_ID,
  BIRTHDAYS_ID,
  ANNUAL_REVIEWS_ID,
  POST_SETTLEMENT_FOLLOW_UP_ID,
} from 'Common/constants/customerCareModules';
import {
  settlementFollowUpListBuilderForUI,
  approvalExpiresListBuilderForUI,
  birthdaysListBuilderForUI,
  annualReviewLoanListBuilderForUI,
} from 'Common/mappers/customerCare';
import { formatDate } from 'Common/utilities/date';
import { CAPS_DD_MMMM_YYYY } from 'Common/constants/dateConfigs';

export const onActionSelectedUtil = ($scope) => ($event) => {
  $scope[$event.sendVia]('lg', $event.item, 'single');
  return $event.sendVia;
};

export const sendingResultUtil = ($scope) => (modalInstance, loadTable) => {
  return modalInstance.result.then((result) => {
    if (result.success && isValidObjectValue(() => $scope[loadTable])) {
      $scope[loadTable]();
    }
  });
};

export const isDateSentActive = (configService) => {
  return configService.feature.dateSentCustomerCare;
};

export const modalParamsUtil = {
  currentModuleType: (type) => () => type,
  currentModuleName: (name) => () => name,
  isInsurance: (isInsurance) => () => isInsurance,
  tab: (tab) => () => tab,
};

export const filterCheckedItems = ($scope) => (tableDataName) => {
  return isValidObjectValue(() => $scope[tableDataName])
    ? $scope[tableDataName].filter((item) => item.isChecked)
    : [];
};

export const markCompleted = ($scope) => (item, setCompletionName) => {
  item.isComplete = !item.isComplete;
  item.isLoading = true;
  if (isValidObjectValue(() => $scope[setCompletionName])) {
    $scope[setCompletionName](item);
  }
  return item;
};

export const sendEmailToSelected = ($scope) => (
  toSelected = true,
  tableDataName,
  tableName,
) => {
  const validatedAllTableData = isValidObjectValue(() => $scope[tableDataName])
    ? $scope[tableDataName]
    : [];

  const tableDataSelected = toSelected
    ? angular.copy($scope.checkedTableData)
    : angular.copy(validatedAllTableData);

  let tableData = tableDataSelected;
  switch ($scope.tab.id) {
    case POST_SETTLEMENT_FOLLOW_UP_ID:
      tableData = tableDataSelected.map(settlementFollowUpListBuilderForUI);
      break;
    case APPROVAL_EXPIRIES_ID:
      tableData = tableDataSelected.map(approvalExpiresListBuilderForUI);
      break;
    case BIRTHDAYS_ID:
      tableData = tableDataSelected.map(birthdaysListBuilderForUI);
      break;
    case ANNUAL_REVIEWS_ID:
      tableData = tableDataSelected.map(annualReviewLoanListBuilderForUI);
      break;
    default:
      tableData = tableDataSelected;
      break;
  }

  const props = {
    adviserList: $scope.customerCareAdviserList,
    tableData,
    isInsurance: false,
    singleData: {},
    tab: $scope.tab,
  };

  if ($scope.isBulkEmailActive) {
    const modalInstance = $scope.customerCareTabsService.openSendEmailModal(
      props,
    );
    $scope.sendingResult(modalInstance, tableName);
  } else {
    $scope.openSendEmailModal('lg', '', 'all');
  }
  return toSelected;
};
export const onItemCheckboxToggled = ($scope) => (
  item,
  setCompletionName,
  tableDataName,
) => {
  if ($scope.isBulkEmailActive) {
    $scope.checkedTableData = $scope.filterCheckedItems(tableDataName);
  } else {
    item.isComplete = item.isChecked;
    if (isValidObjectValue(() => $scope[setCompletionName])) {
      $scope[setCompletionName](item);
    }
  }
  return item;
};
export const initValuesUtil = ($scope) => ({ configService, TAB_DETAILS }) => {
  $scope.isBulkEmailActive = $scope.customerCareTabsService.isBulkEmailActive();
  $scope.checkedTableData = [];
  $scope.tab = TAB_DETAILS;

  $scope.isDateSentActive = isDateSentActive(configService);
  $scope.actionColumnTitle = $scope.isDateSentActive
    ? 'Communication Sent'
    : '';

  $scope.filterCheckedItems = filterCheckedItems($scope);
  $scope.markCompleted = markCompleted($scope);
  $scope.sendEmailToSelected = sendEmailToSelected($scope);
  $scope.onItemCheckboxToggled = onItemCheckboxToggled($scope);
};

export const onDateRangeChange = ($scope) => ({
  dateFrom,
  dateTo,
  rangeValue,
}) => {
  const formatRangeDate = formatDate(CAPS_DD_MMMM_YYYY);
  $scope.mainObj.formDate = formatRangeDate(dateFrom);
  $scope.mainObj.toDate = formatRangeDate(dateTo);
  $scope.mainObj.dateRange = rangeValue;
  if (typeof $scope.reloadCallback === 'function') {
    $scope.reloadCallback();
  }
};

export const processCustomDateRange = ($scope) => {
  if (!$scope.unlockingCustomerCare) {
    return;
  }
  $scope.defaultIndex =
    $scope.customerCareDateRange &&
    $scope.customerCareDateRange
      .map((range) => range.value)
      .indexOf(+$scope.mainObj.dateRange);
  $scope.onDateRangeChange = onDateRangeChange($scope);
};

export const statusCompleted = ($scope) => {
  return !!(
    $scope.filterData.isCompleted ||
    $scope.filterData.ShowCompleted ||
    $scope.filterData.showCompleted
  );
};

export const hideMarkBtn = ($scope) => {
  return !!(
    $scope.item.updateCompleted ||
    ($scope.statusCompleted() && !$scope.showMarkAsIncompleteBtn)
  );
};
