import _ from 'lodash';

export const loadModule = () =>
  app.factory('mapService', function mapService(
    configService,
    $window,
    $q,
    $http,
    SweetAlert,
    toaster,
    $cacheFactory,
    $timeout,
  ) {
    function generateToolTip(
      contentString,
      map,
      marker,
      removeViewableDiv,
      leftPos,
    ) {
      var infoWindow = new google.maps.InfoWindow({
        content: contentString,
        hidden: false,
        enableEventPropagation: true,
        zIndex: 999,
      });

      if (
        !angular.isUndefined(removeViewableDiv) &&
        removeViewableDiv == true
      ) {
        google.maps.event.addListener(infoWindow, 'domready', () => {
          // Reference to the DIV which receives the contents of the infowindow using jQuery
          var iwOuter = angular.element('.gm-style-iw');

          /* The DIV we want to change is above the .gm-style-iw DIV.
           * So, we use angular and create a iwBackground variable,
           * and took advantage of the existing reference to .gm-style-iw for the previous DIV with .prev().
           */
          var iwBackground = iwOuter.prev();

          // Remove the background shadow DIV
          iwBackground.children(':nth-child(2)').css({ display: 'none' });

          // Remove the white background DIV
          iwBackground.children(':nth-child(4)').css({ display: 'none' });

          // Make the first div on container to go 100% h/w
          iwOuter
            .children(':nth-child(1)')
            .css({ height: '100%', width: '100%' });

          var iwParent = iwOuter.parent();

          // Move the content to go up on the marker
          iwParent.parent().css({ left: `${leftPos}px`, top: '60px' });

          // Remove the pointer like
          iwParent.children(':nth-child(1)').css({ display: 'none' });

          // Remove 'x' button
          iwParent.children(':nth-child(3)').css({ display: 'none' });
        });
      }

      infoWindow.open(map, marker);
    }

    function calculateAndDisplayRoute(
      directionsService,
      directionsDisplay,
      origin,
      destination,
    ) {
      var waypts = [];
      // Uncomment if you have a list of other waypoints along the direction
      /* var checkboxArray = document.getElementById('waypoints');
         for (var i = 0; i < checkboxArray.length; i++) {
         if (checkboxArray.options[i].selected) {
         waypts.push({
         location: checkboxArray[i].value,
         stopover: true
         });
         }
         } */

      directionsService.route(
        {
          origin,
          destination,
          // waypoints: waypts,
          // optimizeWaypoints: true,
          travelMode:
            google.maps.TravelMode
              .DRIVING /* ,
             suppressMarkers: true */,
        },
        (response, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);

            /* Uncomment this if you want to show a detailed direction to a container */
            /* var route = response.routes[0];
                 var summaryPanel = document.getElementById('directions-panel');
                 summaryPanel.innerHTML = '';
                 // For each route, display summary information.
                 for (var i = 0; i < route.legs.length; i++) {
                 var routeSegment = i + 1;
                 summaryPanel.innerHTML += '<b>Route Segment: ' + routeSegment +
                 '</b><br>';
                 summaryPanel.innerHTML += route.legs[i].start_address + ' to ';
                 summaryPanel.innerHTML += route.legs[i].end_address + '<br>';
                 summaryPanel.innerHTML += route.legs[i].distance.text + '<br><br>';
                 } */
          } else {
            swal(
              'error',
              'Sorry, no available possible directions for the map',
            );
          }
        },
      );
    }

    return {
      loadAPI() {
        var deferred = $q.defer();
        deferred.resolve();
        this.cacheMapService =
          $cacheFactory.get('mapServiceCache') ||
          $cacheFactory('mapServiceCache');
        return deferred.promise;
      },
      mapAutoCompletePlace(id, callback) {
        var mapElement = document.getElementById(id);
        var map = new google.maps.places.Autocomplete(mapElement);
        map.addListener('place_changed', () => {
          var place = map.getPlace();
          callback(place);
        });
        return map;
      },
      mapCalculateAndDisplayRoute(SaddressLatLng, DaddressLatLng, map) {
        var directionsService = new google.maps.DirectionsService();
        var directionsDisplay = new google.maps.DirectionsRenderer();

        directionsDisplay.setMap(map);
        SaddressLatLng = new google.maps.LatLng(
          SaddressLatLng[0],
          SaddressLatLng[1],
        );
        DaddressLatLng = new google.maps.LatLng(
          DaddressLatLng[0],
          DaddressLatLng[1],
        );

        calculateAndDisplayRoute(
          directionsService,
          directionsDisplay,
          SaddressLatLng,
          DaddressLatLng,
        );
      },

      mapRemoveMarker(marker) {
        marker.setMap(null);
      },
      validMapValue(lat, long, type, address) {
        const cacheCall = this.cacheMapService.get(`${type}_${address}`);
        if ((!lat || !long) && !cacheCall && address) {
          this.cacheMapService.put(`${type}_${address}`, 1);
          $timeout(() => {
            this.cacheMapService.remove(`${type}_${address}`);
          }, 3000);
        }
        return lat && long;
      },
      mapDesign(
        lat,
        long,
        address,
        content,
        removeViewableDiv,
        mapId,
        leftPos,
      ) {
        if (!this.validMapValue(lat, long, 'mapDesign', address)) {
          return false;
        }
        if (angular.isUndefined(mapId)) mapId = 'contactHomeAddressMap';
        if (angular.isUndefined(leftPos)) leftPos = '30';
        if (_.trim(address) !== '') {
          var mapOptions = {
            zoom: 11,
            center: new google.maps.LatLng(lat, long),

            styles: [
              {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#444444' }],
              },
              {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [{ color: '#f2f2f2' }],
              },
              {
                featureType: 'poi',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.business',
                elementType: 'geometry.fill',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'road',
                elementType: 'all',
                stylers: [{ saturation: -100 }, { lightness: 45 }],
              },
              {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [{ visibility: 'simplified' }],
              },
              {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'water',
                elementType: 'all',
                stylers: [{ color: '#b4d4e1' }, { visibility: 'on' }],
              },
            ],
          };

          var mapElement = document.getElementById(mapId);
          if (mapElement) {
            var map = new google.maps.Map(mapElement, mapOptions);
            var marker = new google.maps.Marker({
              position: new google.maps.LatLng(lat, long),
              map,
              icon: '/assets/images/icon/pin.png',
              title: address,
            });

            if (!angular.isUndefined(content))
              generateToolTip(content, map, marker, removeViewableDiv, leftPos);

            return [map, marker];
          }
        } else {
        }
      },
      mapDesignForSecurity(lat, long, address, id) {
        if (!this.validMapValue(lat, long, 'mapDesignForSecurity', address)) {
          return false;
        }
        if (_.trim(address) !== '') {
          var mapOptions = {
            zoom: 11,

            center: new google.maps.LatLng(lat, long), // New York

            styles: [
              {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#444444' }],
              },
              {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [{ color: '#f2f2f2' }],
              },
              {
                featureType: 'poi',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.business',
                elementType: 'geometry.fill',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'road',
                elementType: 'all',
                stylers: [{ saturation: -100 }, { lightness: 45 }],
              },
              {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [{ visibility: 'simplified' }],
              },
              {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'water',
                elementType: 'all',
                stylers: [{ color: '#b4d4e1' }, { visibility: 'on' }],
              },
            ],
          };

          var mapElement = document.getElementById(id);
          var map = new google.maps.Map(mapElement, mapOptions);

          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, long),
            map,
            icon: '/assets/images/icon/pin.png',
            title: address,
          });
        } else {
        }
      },
      mapDesignForReferrer(lat, long, address, id) {
        if (!this.validMapValue(lat, long, 'mapDesignForReferrer', address)) {
          return false;
        }
        if (_.trim(address) !== '') {
          var mapOptions = {
            zoom: 11,

            center: new google.maps.LatLng(lat, long), // New York

            styles: [
              {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#444444' }],
              },
              {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [{ color: '#f2f2f2' }],
              },
              {
                featureType: 'poi',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.business',
                elementType: 'geometry.fill',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'road',
                elementType: 'all',
                stylers: [{ saturation: -100 }, { lightness: 45 }],
              },
              {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [{ visibility: 'simplified' }],
              },
              {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'water',
                elementType: 'all',
                stylers: [{ color: '#b4d4e1' }, { visibility: 'on' }],
              },
            ],
          };

          var mapElement = document.getElementById(id);
          var map = new google.maps.Map(mapElement, mapOptions);

          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, long),
            map,
            icon: '/assets/images/icon/pin.png',
            title: address,
          });
        } else {
        }
      },
      inititalizeMapWithDefaults(stateCoordinates) {
        var mapCenter = new google.maps.LatLng(-31.2532, 146.9211);
        var zoom = 3;
        if (stateCoordinates && stateCoordinates.lat) {
          mapCenter = new google.maps.LatLng(
            stateCoordinates.lat,
            stateCoordinates.long,
          );
          zoom = 6;
        }
        var map = new google.maps.Map(
          document.getElementById('searchAddressMap'),
          {
            zoom,

            center: mapCenter,

            styles: [
              {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#444444' }],
              },
              {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [{ color: '#f2f2f2' }],
              },
              {
                featureType: 'poi',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.business',
                elementType: 'geometry.fill',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'road',
                elementType: 'all',
                stylers: [{ saturation: -100 }, { lightness: 45 }],
              },
              {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [{ visibility: 'simplified' }],
              },
              {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'water',
                elementType: 'all',
                stylers: [{ color: '#b4d4e1' }, { visibility: 'on' }],
              },
            ],
          },
        );
      },
      mapDesignForFundingCalculator2(addressList) {
        var bounds = new google.maps.LatLngBounds();
        var map = new google.maps.Map(
          document.getElementById('searchAddressMap'),
          {
            zoom: 14,

            // center: new google.maps.LatLng(addressList[0].latitude,addressList[0].longitude), // New York

            styles: [
              {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#444444' }],
              },
              {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [{ color: '#f2f2f2' }],
              },
              {
                featureType: 'poi',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.business',
                elementType: 'geometry.fill',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'road',
                elementType: 'all',
                stylers: [{ saturation: -100 }, { lightness: 45 }],
              },
              {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [{ visibility: 'simplified' }],
              },
              {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'water',
                elementType: 'all',
                stylers: [{ color: '#b4d4e1' }, { visibility: 'on' }],
              },
            ],
          },
        );

        var infowindow = new google.maps.InfoWindow();

        var marker, i;

        for (i = 0; i < addressList.length; i++) {
          var pos = new google.maps.LatLng(
            addressList[i].latitude,
            addressList[i].longitude,
          );
          marker = new google.maps.Marker({
            position: new google.maps.LatLng(
              addressList[i].latitude,
              addressList[i].longitude,
            ),
            map,
            icon: '/assets/images/icon/pin.png',
            title: address,
          });

          bounds.extend(marker.getPosition());

          // google.maps.event.addListener(marker, 'click', (function(marker, i) {
          //    return function() {
          //        infowindow.setContent(locations[i][0], locations[i][6]);
          //        infowindow.open(map, marker);
          //    }
          // })(marker, i));
        }

        map.fitBounds(bounds);

        return;
        for (var j = 0; j < 1; j++) {
          var lat = addressList[0].latitude;
          var long = addressList[0].longitude;
          var address = addressList[0].formatted_address;
          if (_.trim(address) !== '') {
            var mapOptions = {
              zoom: 14,

              center: new google.maps.LatLng(lat, long), // New York

              styles: [
                {
                  featureType: 'administrative',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#444444' }],
                },
                {
                  featureType: 'landscape',
                  elementType: 'all',
                  stylers: [{ color: '#f2f2f2' }],
                },
                {
                  featureType: 'poi',
                  elementType: 'all',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: 'poi.business',
                  elementType: 'geometry.fill',
                  stylers: [{ visibility: 'on' }],
                },
                {
                  featureType: 'road',
                  elementType: 'all',
                  stylers: [{ saturation: -100 }, { lightness: 45 }],
                },
                {
                  featureType: 'road.highway',
                  elementType: 'all',
                  stylers: [{ visibility: 'simplified' }],
                },
                {
                  featureType: 'road.arterial',
                  elementType: 'labels.icon',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: 'transit',
                  elementType: 'all',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: 'water',
                  elementType: 'all',
                  stylers: [{ color: '#b4d4e1' }, { visibility: 'on' }],
                },
              ],
            };

            var marker = new google.maps.Marker({
              position: new google.maps.LatLng(lat, long),
              map,
              icon: '/assets/images/icon/pin.png',
              title: address,
            });
          } else {
          }
        }
      },
      mapDesignForFundingCalculator(lat, long, address) {
        if (
          !this.validMapValue(
            lat,
            long,
            'mapDesignForFundingCalculator',
            address,
          )
        ) {
          return false;
        }
        if (_.trim(address) !== '') {
          var mapOptions = {
            zoom: 14,

            center: new google.maps.LatLng(lat, long), // New York

            styles: [
              {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#444444' }],
              },
              {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [{ color: '#f2f2f2' }],
              },
              {
                featureType: 'poi',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.business',
                elementType: 'geometry.fill',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'road',
                elementType: 'all',
                stylers: [{ saturation: -100 }, { lightness: 45 }],
              },
              {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [{ visibility: 'simplified' }],
              },
              {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'water',
                elementType: 'all',
                stylers: [{ color: '#b4d4e1' }, { visibility: 'on' }],
              },
            ],
          };

          var mapElement = document.getElementById('searchAddressMap');

          var map = new google.maps.Map(mapElement, mapOptions);

          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, long),
            map,
            icon: '/assets/images/icon/pin.png',
            title: address,
          });
        } else {
        }
      },
      mapDesignForFundingCalc(lat, long, address, id) {
        if (
          !this.validMapValue(lat, long, 'mapDesignForFundingCalc', address)
        ) {
          return false;
        }
        if (_.trim(address) !== '') {
          var mapOptions = {
            zoom: 11,

            center: new google.maps.LatLng(lat, long), // New York

            styles: [
              {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#444444' }],
              },
              {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [{ color: '#f2f2f2' }],
              },
              {
                featureType: 'poi',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.business',
                elementType: 'geometry.fill',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'road',
                elementType: 'all',
                stylers: [{ saturation: -100 }, { lightness: 45 }],
              },
              {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [{ visibility: 'simplified' }],
              },
              {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'water',
                elementType: 'all',
                stylers: [{ color: '#b4d4e1' }, { visibility: 'on' }],
              },
            ],
          };
          var mapElement = document.getElementById(id);

          if (mapElement != null)
            var map = new google.maps.Map(mapElement, mapOptions);

          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, long),
            map,
            icon: '/assets/images/icon/pin.png',
            title: address,
          });
        } else {
        }
      },
      getLatitudeLongitude(address, callback) {
        if (_.trim(address) !== '') {
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({ address }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              callback(results[0]);
            } else {
              callback(null);
            }
          });
        } else {
        }
      },
      mapCorporate(lat, long, address, id, contentString) {
        if (!this.validMapValue(lat, long, 'mapCorporate', address)) {
          return false;
        }
        const infoWindowCenter = 0.002;
        if (address && address.trim() !== '') {
          var mapOptions = {
            zoom: 15,
            center: new google.maps.LatLng(lat, long),
            styles: [
              {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#444444' }],
              },
              {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [{ color: '#f2f2f2' }],
              },
              {
                featureType: 'poi',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.business',
                elementType: 'geometry.fill',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'road',
                elementType: 'all',
                stylers: [{ saturation: -100 }, { lightness: 45 }],
              },
              {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [{ visibility: 'simplified' }],
              },
              {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'water',
                elementType: 'all',
                stylers: [{ color: '#b4d4e1' }, { visibility: 'on' }],
              },
            ],
          };
          if (id) {
            var mapElement = document.getElementById(id);
            var map = new google.maps.Map(mapElement, mapOptions);

            map.addListener('center_changed', () => {
              google.maps.event.trigger(map, 'resize');
              google.maps.event.clearListeners(map, 'center_changed');
              map.setCenter(
                new google.maps.LatLng(lat + infoWindowCenter, long),
              );
            });

            var marker = new google.maps.Marker({
              map,
              position: new google.maps.LatLng(lat, long),
              icon: '/assets/images/icon/pin.png',
              title: address,
            });

            var infoWindow = new google.maps.InfoWindow({
              content: contentString,
              hidden: false,
              enableEventPropagation: true,
              zIndex: 999,
              maxWidth: 150,
              maxHeight: 150,
              boxStyle: {
                backgroundColor: '#3f2e85',
                opacity: 0.75,
              },
            });

            var marker = new google.maps.Marker({
              position: new google.maps.LatLng(lat, long),
              map,
              icon: '/assets/images/icon/pin.png',
              title: address,
            });
            infoWindow.open(map, marker);
          }
        }
      },
      infowindowClose(infowindow) {
        return infowindow.close();
      },
      mapInitialization(
        mapId,
        lat,
        long,
        zoomLevel,
        address,
        isMultipleMarker,
        markers,
        hasInfoWindow,
        content,
      ) {
        if (!this.validMapValue(lat, long, 'mapInitialization', address)) {
          return false;
        }
        var center = new google.maps.LatLng(lat, long);

        if (_.trim(address) !== '') {
          var mapOptions = {
            zoom: zoomLevel,
            center, // New York
            styles: [
              {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#444444' }],
              },
              {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [{ color: '#f2f2f2' }],
              },
              {
                featureType: 'poi',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.business',
                elementType: 'geometry.fill',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'road',
                elementType: 'all',
                stylers: [{ saturation: -100 }, { lightness: 45 }],
              },
              {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [{ visibility: 'simplified' }],
              },
              {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'all',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'water',
                elementType: 'all',
                stylers: [{ color: '#b4d4e1' }, { visibility: 'on' }],
              },
            ],
          };
          var mapElement = document.getElementById(mapId);
          var map = new google.maps.Map(mapElement, mapOptions);
          // var marker = new google.maps.Marker({
          //        position: new google.maps.LatLng(lat, long),
          //        map: map,
          //        icon: '/assets/images/icon/pin.png',
          //        title: address
          //    });

          //
          //
          //

          var i;
          if (isMultipleMarker) {
            var marker = new google.maps.Marker({
              position: new google.maps.LatLng(lat, long),
              map,
              icon: '/assets/images/icon/pin.png',
              title: address,
            });
          }

          if (!isMultipleMarker) {
            var marker = new google.maps.Marker({
              position: new google.maps.LatLng(lat, long),
              map,
              icon: '/assets/images/icon/pin.png',
              title: address,
            });

            if (hasInfoWindow) {
              var infowindow = new google.maps.InfoWindow({
                content,
                maxWidth: 300,
                hidden: false,
                closeBoxURL: '',
              });
              infowindow.open(map, marker);

              // google.maps.event.addListener(marker, 'click', (function(marker, infowindow) {
              //     return function () {

              //     }
              // })(marker, infowindow));
            }
          } else {
            for (i = 0; i < markers.length; i++) {
              var marker = new google.maps.Marker({
                position: new google.maps.LatLng(markers[i][1], markers[i][2]),
                map,
                icon: '/assets/images/icon/icon-map-light-default.svg',
                title: markers[i][0],
              });

              if (hasInfoWindow) {
                var infowindow = new google.maps.InfoWindow({
                  content: markers[i][3],
                  maxWidth: 250,
                });

                google.maps.event.addListener(
                  marker,
                  'click',
                  (function (marker, infowindow) {
                    return function () {
                      infowindow.open(map, marker);
                    };
                  })(marker, infowindow),
                );
              }
            }
          }

          return [map, marker, infowindow];
        }
      },
    };
  });
