import swal from 'sweetalert';

class ComplianceReviewRatingModalCtrl {
  constructor(corporateModelService, corporateService, toaster) {
    'ngInject';

    this.corporateModelService = corporateModelService;
    this.corporateService = corporateService;
    this.toaster = toaster;
  }

  saveComplianceReview() {
    const comment = this.comment;
    const isValidComment = comment && comment.rating && comment.comments;
    if (isValidComment) {
      this.corporateModelService.complianceReviewSet(comment).then(() => {
        this.modalInstance.close();
      });
    } else {
      swal(
        'Required Field',
        'Rating and Comment are required in this review. Please provide rating and comment.',
        'error',
      );
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  $onInit() {
    this.isRating = false;
  }
}

export default ComplianceReviewRatingModalCtrl;
