export const getSoleTraderIncludedFamily = ({
  $scope,
  person,
  loanAppFamily,
}) => {
  if (!$scope || !person || !loanAppFamily) {
    return loanAppFamily;
  }
  const { listSoleTraders } = $scope;
  const { FamilyId } = person;
  if (!listSoleTraders || !listSoleTraders.length) {
    return loanAppFamily;
  }
  let family = [...loanAppFamily];
  listSoleTraders.forEach((soleTrader) => {
    if ((soleTrader && soleTrader[0].FamilyId) === FamilyId) {
      family = [...family, ...soleTrader];
    }
  });
  return family;
};
