export const ADVISER_STATUS = {
  ONBOARDING: 0,
  ACTIVE: 1,
  TERMINATED: 2,
  PROSPECT: 3,
};

export const ADVISER_STATUS_NAME = {
  ONBOARDING: 'Onboarding',
  ACTIVE: 'Active',
  PROSPECT: 'Prospect',
  CEASED_BY_ADVISER: 'Ceased by Adviser',
};

export const LEADS_STATUS = {
  ACTIVE: 1,
  TEMPORARILY_INACTIVE: 2,
  INACTIVE: 3,
  SUSPENDED: 4,
};
