import './style.scss';
import template from './readMore.html';
import controller from './readMoreCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    label: '<',
    text: '<',
    limit: '<',
    onClick: '&',
  },
};
