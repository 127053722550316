import template from './loanStatusSelector.html';
import LoanStatusSelectorCtrl from './loanStatusSelectorCtrl';

export default {
  template,
  bindings: {
    loanDetails: '<',
  },
  controller: LoanStatusSelectorCtrl,
  controllerAs: 'vm',
};
