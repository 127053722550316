import template from './opportunityFactFind.html';
import controller from './opportunityFactFindCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    opportunityId: '<',
    familyId: '<',
    tabTitle: '<',
    fileGeneralDetails: '<',
    applicants: '<',
  },
};
