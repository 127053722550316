export function optionListBuilder(list) {
  return {
    name: list.Name,
    value: list.Value,
    helpText: list.HelpText || '',
  };
}

export function objectivesOptionBuilderForUI(option) {
  return {
    questionId: option.QuestionId,
    options: option.ObjectiveList.map(optionListBuilder),
  };
}

export function fundingDetailsObjectiveBuilderForUI(list) {
  return {
    fundingDetailsObjId: list.FundingDetailsObjId,
    details: list.HelpTextValue,
    value: list.ObjectiveListValue,
  };
}

export function reqAndObjValidationBuilderForUI(data) {
  return data && data.DisplayOldLoanFeatures;
}
