import template from './opportunityTasks.html';
import controller from './opportunityTasksCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    opportunityId: '<',
    loanId: '<',
    familyId: '<',
    familyList: '<',
    opportunityTitle: '<',
  },
};
