import { EMAIL_CTYPES } from 'Common/constants/emailTypes';
import {
  getEmailByType,
  setContactEmailByType,
} from 'Common/utilities/contact';

export const onUpdateEmailValue = (params) => {
  const { applicant, email: updatedEmail } = params;

  applicant.updatedEmail = updatedEmail;
};

export const updateApplicantEmailModel = ({ applicant, newEmail }) => {
  if (!applicant) {
    return;
  }

  const emailType = EMAIL_CTYPES.CRM_EMAIL;
  const updatedEmail = applicant.updatedEmail || newEmail;

  setContactEmailByType({
    contact: applicant,
    newEmail: updatedEmail,
    emailType,
  });

  applicant.emailAddress = updatedEmail;
  return applicant;
};

export const updateApplicantFromList = ({ applicant, list }) => {
  if (!applicant || !list) {
    return;
  }

  const selectedApplicantIndex = list.findIndex(
    (contact) => contact.id === applicant.id,
  );

  if (typeof selectedApplicantIndex === 'undefined') {
    return;
  }

  list[selectedApplicantIndex] = applicant;
};

export const filterApplicantByType = ({
  partyList,
  isApplicant,
  isGuarantor,
}) => {
  return partyList.filter((party) => {
    return (
      party.IsApplicant === isApplicant && party.IsGuarantor === isGuarantor
    );
  });
};

export const flattenPersonList = (partyList) => {
  let personList = [];
  Object.entries(partyList).forEach((family) => {
    personList = [...personList, ...family[1]];
  });

  return personList;
};

export const extendApplicantModel = (applicant) => {
  const email = getEmailByType(applicant.Email, EMAIL_CTYPES.CRM_EMAIL);

  const applicantModel = {
    id: applicant.PersonId || applicant.OrganisationId,
    name: applicant.PersonId
      ? `${applicant.FirstName} ${applicant.LastName}`
      : applicant.FamilyFullName,
    emailAddress: email || '',
    isSelected: applicant.isSelected || true,
    isEmailEdit: applicant.isEmailEdit || false,
  };
  Object.assign(applicant, applicantModel);

  return applicant;
};

export const getSectionName = (name, isApplicant = true) => {
  if (Array.isArray(name)) {
    const [applicantTypeName, guarantorTypeName] = name;
    return isApplicant ? applicantTypeName : guarantorTypeName;
  }

  return name;
};
