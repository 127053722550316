import { get } from 'lodash';
import { removeElementOnIndex } from 'Common/utilities/array';
import {
  defaultMultiselectItemValues,
  appendAllOption,
  appendClearOption,
  tickSavedOptions,
} from 'Common/utilities/multiselect';
import { CONTACT_TYPE_NAME } from 'Common/constants/contactType';
import { DEFAULT_DUE_DATE_TYPE } from 'Common/constants/tasks';
import {
  ADVISER_DEFAULT_TAB,
  ASSISTANT_DEFAULT_TAB,
  REFERRER_DEFAULT_TAB,
  CLIENT_DEFAULT_TAB,
} from 'Common/constants/contactTab';
import { parseToInt10 } from 'Common/utilities/parse';

export const getSavedAdviserFilter = (
  adviserListResponse,
  savedAdviserId,
  showAllOption,
) => {
  const formattedAdviserList = adviserListResponse.map((item) => {
    return defaultMultiselectItemValues(item.fullName, item.familyId);
  });
  const completeAdviserList = showAllOption
    ? appendAllOption(formattedAdviserList)
    : appendClearOption(formattedAdviserList);
  return tickSavedOptions(completeAdviserList, savedAdviserId);
};

export const onAdviserItemSelect = ({ $scope }) => (selection) => {
  const advisersList = $scope.selectedAdvisers;

  const selectDefault = !advisersList.length || !selection.value;
  if (selectDefault) {
    $scope.onAdviserSelectDefault();
    return;
  }

  const allIdx = advisersList.findIndex((item) => !item.value);
  const isAllSelected = allIdx !== -1;
  if (isAllSelected) {
    $scope.selectedAdvisers = removeElementOnIndex(allIdx, advisersList);
    $scope.adviserList[0].ticked = false;
  }
  $scope.setFilterData();
};

export const getContactStateParams = ({ ContactType, IsClient }) => {
  const {
    ADVISER,
    PRINCIPAL_ADVISER,
    CORPORATE_USER,
    ADMINISTRATOR,
    SUPER_ADMINISTRATOR,
    REFERRER,
  } = CONTACT_TYPE_NAME;

  switch (ContactType) {
    case ADVISER:
    case PRINCIPAL_ADVISER:
    case CORPORATE_USER:
      return { state: 'app.contactsAdviser', activeTab: ADVISER_DEFAULT_TAB };
    case ADMINISTRATOR:
    case SUPER_ADMINISTRATOR:
      return {
        state: 'app.contactsAssistant',
        activeTab: ASSISTANT_DEFAULT_TAB,
      };
    case REFERRER:
      return { state: 'app.contactsReferrer', activeTab: REFERRER_DEFAULT_TAB };
    default:
      return {
        state: IsClient ? 'app.contactsSingle' : 'app.BusinessAccount',
        activeTab: CLIENT_DEFAULT_TAB,
      };
  }
};

export const setDefaultParams = ({ $scope, $stateParams }) => {
  const { page, search } = $stateParams;
  $scope.currentPage = page || 1;
  $scope.mainObj.searchTask = search || '';
};

export const setStateTransition = ({ $scope, $state, stateService }) => () => {
  $state.params.page = $scope.currentPage || 1;
  $state.params.search = $scope.mainObj.searchTask || '';
  $state.params.dateFilter = $scope.mainObj.dueDate;
  stateService.transition($state.current, $state.params);
};

export const onSearchTaskCallback = ({ $scope, $state, stateService }) => {
  $scope.currentPage = 1;
  setStateTransition({ $scope, $state, stateService })();
  if (!$scope.tasksTableParams) {
    return;
  }
  $scope.tasksTableParams.reload();
};

export const setCurrentPage = ({ $scope, $state, stateService }) => () => {
  $scope.tasksTableParams.reload();
  setStateTransition({ $scope, $state, stateService })();
};

export const setTaskFilter = ({
  $scope,
  $stateParams,
  taskFilterData,
  defaultFilterData,
}) => {
  const invalidParam =
    !$scope ||
    !$scope.mainObj ||
    !$stateParams ||
    !taskFilterData ||
    !defaultFilterData;
  if (invalidParam) {
    return;
  }

  const { createdByBrokerId, dueDateType } = taskFilterData;
  const familyId = parseToInt10($scope.familyId);

  $scope.mainObj.createdByFilter =
    familyId || $scope.isCorporateUser
      ? defaultFilterData.createdByBrokerId
      : createdByBrokerId;
  $scope.mainObj.clientAdviserFilter = taskFilterData.clientAdviserId || [];

  const dateType = $scope.dueDateTypeList.find(
    (type) => type.value === dueDateType,
  )
    ? dueDateType
    : DEFAULT_DUE_DATE_TYPE;
  const dateFilter = familyId ? 'all' : dateType;
  const dateFilterQueryStr = $stateParams.dateFilter;
  $scope.mainObj.dueDate = dateFilterQueryStr || dateFilter;
};

export const clearTaskIdQueryString = ({ $state, stateService }) => {
  if (!$state || !stateService) {
    return;
  }
  $state.params.taskId = null;
  stateService.transition($state.current, $state.params);
};

export const getAssignedToMultiselectValues = ({
  $scope,
  taskAdviser,
  taskFilterData,
  $stateParams,
  $state,
}) => {
  const invalidParams =
    !$scope || !get(taskAdviser, 'length') || !taskFilterData || !$stateParams;
  if (invalidParams) {
    return [];
  }

  return taskAdviser.map((adviser) => {
    const { DisplayName, BrokerID } = adviser;
    const adviserData = defaultMultiselectItemValues(DisplayName, BrokerID);
    const adviserId = parseToInt10(adviserData.value);

    const savedAssignedTo = taskFilterData.assignedBrokerId;
    const useSavedFilter =
      !!get(savedAssignedTo, 'length') && !parseToInt10($scope.familyId);
    if (useSavedFilter && !$stateParams.assignedToMe) {
      const isAllSaved =
        parseToInt10(savedAssignedTo[0]) === 0 && adviserId === 0;
      adviserData.ticked =
        isAllSaved ||
        !!savedAssignedTo.find((item) => parseToInt10(item) === adviserId);
    } else {
      adviserData.ticked =
        adviserId === parseToInt10(get($scope.mainObj, 'AssignedTo'));
    }
    if ($state) {
      $state.params.assignedToMe = null;
    }
    return adviserData;
  });
};

export const setAssignedToLists = ({ $scope, formattedAssignedToList }) => {
  if (!$scope || !formattedAssignedToList) {
    return;
  }

  $scope.assignedToListStored = formattedAssignedToList;
  const tickedAssignedToList = formattedAssignedToList.filter(
    (item) => item.ticked,
  );
  $scope.assignedToListSet = tickedAssignedToList;
  $scope.assignedToListSetStored = tickedAssignedToList;
  $scope.assignedToList = !$scope.isCorporateUser
    ? formattedAssignedToList
    : tickedAssignedToList;
};
