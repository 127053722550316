import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('OffsetPdfPreviewModalCtrl', function OffsetPdfPreviewModalCtrl(
      $scope,
      $window,
      options,
      labels,
      colors,
      graphData,
      datasetOverride,
      loanAmount,
      years,
      interestRate,
      frequency,
      interestSaved,
      timeSaved,
      customThemeService,
      series,
      userService,
      loanCalculatorSharedData,
    ) {
      $scope.data = graphData;
      $scope.datasetOverride = datasetOverride;
      $scope.options = options;
      $scope.labels = labels;
      $scope.colors = colors;
      $scope.series = series;
      $scope.loanAmount = loanAmount;
      $scope.years = years;
      $scope.interestRate = interestRate;
      $scope.frequency = frequency;
      $scope.interestSaved = interestSaved;
      $scope.timeSaved = timeSaved;
      $scope.brandingLogo = loanCalculatorSharedData.brandingLogo;
      $scope.getUserInfo = loanCalculatorSharedData.getUserInfo;
      $scope.getUserInfo();
      $scope.date = new Date();
    });
