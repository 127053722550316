import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

export const loadModule = () =>
  angular.module('app').factory('queryService', [
    function () {
      var factory = this;
      /*
       * This is for querying page based on number of items per page
       * @params count - (item count per page)
       * @params parentArr - (array you wanted to split items per page)
       */
      factory.queryPerPage = function (count, parentArr) {
        var COUNT_PER_PAGE = count;
        var testData = [];
        var each = [];
        if (!parentArr) return [];
        for (var i = 0; i < parentArr.length; i++) {
          each.push(parentArr[i]);
          if ((i + 1) % COUNT_PER_PAGE == 0) {
            testData.push(each);
            each = [];
          }
        }
        if (each.length) {
          testData.push(each);
        }

        return testData;
      };

      /*
       * This is for array need to get id and name
       * @params id - id on the array on load, on saving this will be null
       * @params name - name equated on the first load pass on saving, null on first load
       * @params idKey - key of the list array
       * @params idKey - key  name of the list array
       * @params idKey - array
       */
      factory.getArrValue = function (id, name, idKey, nameKey, arrays) {
        var arrValue;
        angular.forEach(arrays, (array) => {
          if (id != null) {
            if (id == array[idKey]) {
              arrValue = array[nameKey];
            }
          }

          if (name != null) {
            if (name == array[nameKey]) {
              arrValue = array[idKey];
            }
          }
        });

        return arrValue;
      };

      /*
       * @desc This calculation of tab
       * @params classname - class name determining the current tabs
       * @params callback - call other function
       */
      factory.calculateWidth = function (classname, callback) {
        var navwidth = 0;
        var morewidth = $(`${classname} ul.nav-tabs .more`).outerWidth(true);

        $(`${classname} .nav-tabs > li.contact-parent-tab`).each(function () {
          // find for possible duplicates, then delete the element
          // duplicates are caused by searching for client using the general search
          var fromElement = $(this);
          // should always be found ONCE!
          var elementCount = 0;

          $('.nav-tabs > li.contact-parent-tab').each(function () {
            if ($(this)[0].id === fromElement[0].id) {
              elementCount++;
            }

            // if there are 2 elements, remove this element
            if (elementCount > 1) {
              $(this).remove();
            }
          });
          navwidth += $(this).outerWidth(true);
        });

        var availablespace =
          $(`${classname} ul.nav-tabs`).outerWidth(true) - morewidth;

        if (navwidth > availablespace) {
          var lastItem = $(
            `${classname} .nav-tabs > li.contact-parent-tab`,
          ).last();
          lastItem.attr('data-width', lastItem.outerWidth(true));
          lastItem.prependTo($(`${classname} .nav-tabs .more ul`));

          if (callback) {
            callback();
          }
        } else {
          var firstMoreElement = $(`${classname}.nav-tabs li.more li`).first();

          if (navwidth + firstMoreElement.data('width') < availablespace) {
            firstMoreElement.insertBefore($(`${classname}.nav-tabs .more`));
          }
        }
      };

      factory.getAllArrayDetails = function (
        scope,
        service,
        getter,
        params,
        arrayContainer,
        objSetter,
        keySetName,
        keySetId,
        arrayKey,
        arrayName,
      ) {
        service[getter].apply(null, params).then((response) => {
          scope[arrayContainer] = [];
          if (response) {
            angular.forEach(response.data, (value) => {
              scope[arrayContainer].push(value);
            });
          }
          if (arrayKey && arrayKey) {
            if (_.size(scope[arrayContainer]) > 0) {
              objSetter[keySetName] = factory.getArrValue(
                objSetter[keySetId],
                null,
                arrayKey,
                arrayName,
                scope[arrayContainer],
              );
            }
          } else if (_.size(scope[arrayContainer]) > 0) {
            objSetter[keySetName] = factory.getArrValue(
              objSetter[keySetId],
              null,
              keySetId,
              keySetName,
              scope[arrayContainer],
            );
          }
        });
      };

      factory.queryGet = function (scope, service, getter, ids, callback) {
        var argsLen = arguments.length;
        var argsCount = 5; // count of arguments in this function
        var argsLimit = argsCount;
        var callback;
        if (argsLen > argsLimit) {
          callback = arguments[argsLen - 2];
        } else {
          callback = argsLen == argsLimit ? arguments[argsLen - 1] : null;
        }
        var args = argsLen > argsLimit ? arguments[argsLen - 1] : [];

        var queryCallback = function (value) {
          scope[getter] = [];
          scope[getter] = value.data;

          if (callback) {
            callback(...args);
          }
        };

        service[getter].apply(null, ids).then(queryCallback);
      };

      factory.querySet = function (service, setter, arrays, callback) {
        var queryCallback = function (response) {
          if (callback) {
            callback(response);
          }
        };
        service[setter].apply(null, arrays).then(queryCallback);
      };

      factory._setEditMode = function (scope, key) {
        scope.editMode[key] = false;
        return scope.editMode[key];
      };

      /*
       * @desc This is for formatting date on load in the field
       */
      factory.dateFieldFormat = function (objData, key) {
        if (objData.data) {
          if (objData.data[key]) {
            if (objData.data[key] == '1900-01-01') {
              objData.data[key] = new Date();
            }
            if (
              objData.data[key] == '0001-01-01T00:00:00+00:00' ||
              !objData.data[key]
            ) {
              objData.data[`${key}Tmp`] = 'None';
              objData.data[key] = new Date();
            } else {
              objData.data[key] = new Date(objData.data[key]);
              objData.data[`${key}Tmp`] = objData.data[key];
            }
          } else {
            objData.data[key] = new Date();
            objData.data[`${key}Tmp`] = 'None';
          }
        }
      };

      /*
       * @desc This is for validating format for dates
       */
      factory.validateDateFormat = function (formName, inputName) {
        if (formName[inputName].$invalid) {
          return true;
        }
        return false;
      };

      /* used in future ellen comment for now
    * var _multiSelectInit = function (data, array, key) {
    *   var tmp = [];
    *   if (data[key] == undefined) {
    *     data[key] = 'Select Roles ';
    *   }

    *   if (data[key].replace(/\s+/, ""))
    *   var value = data[key].replace(/\s+/, "").split(',');
    *   for (var x in value) {
    *     value.ticked = true;
    *     for (var i in array) {
    *       if(array[i].name == value[x].trim()) {
    *         array[i].ticked = true;
    *       }
    *     }
    *     tmp.push({ name: value[x], ticked: value.ticked });
    *   }
    *   return tmp;
    * }
    */

      return factory;
    },
  ]);
