import template from './questionCheckRow.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    label: '@',
    content: '@',
    value: '<',
    key: '<',
    onChange: '&',
    onEdit: '&?',
    showEdit: '@?',
  },
};
