import { getFirstSecurityWithAddress } from '../expandableTable/util';

export default class LoanCompactDetailsCtrl {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;
  }

  $onInit() {
    this.loanDetails &&
      (this.loanDetails.firstSecurityWithAddress = getFirstSecurityWithAddress(
        this.loanDetails.SecurityInfo,
      ));
    this.isNewPipeline = this.uiService.newPipeline;
  }
}
