import { contactToLoanBuilderForMyCRM } from 'Common/mappers/contact';
import { documentPresignedUrlBuilderForUI } from 'Common/mappers/document';

class DocumentService {
  constructor(contactService, $q, httpClient, contactModelService) {
    'ngInject';

    this.contactService = contactService;
    this.httpClient = httpClient;
    this.$q = $q;
    this.baseUrl = 'document';
    this.contactModelService = contactModelService;
  }

  getDocumentThumbnail(documentObj) {
    const deferred = this.$q.defer();
    if (documentObj && documentObj.DocumentId) {
      this.contactService
        .documentThumbnailGet({
          documentID: documentObj.DocumentId,
          startPage: 1,
          pageNumber: 1,
          thumbWidth: 300,
        })
        .then((respond) => {
          const thumbObj = respond && respond.data && respond.data[0];
          const isValidThumbnail =
            thumbObj && thumbObj.ContentType && thumbObj.ThumbnailContent;
          const thumbnail = isValidThumbnail
            ? `data:${thumbObj.ContentType};base64,${thumbObj.ThumbnailContent}`
            : false;
          deferred.resolve({ thumbnail });
        });
    } else {
      deferred.reject({ error: 'Invalid document' });
    }
    return deferred.promise;
  }

  setDocumentProperty(property, isDocumentFromLoanApp = false) {
    if (!isDocumentFromLoanApp) {
      return this.contactService.documentPropertySet([property]);
    }
    return this.contactModelService.setLoanDocumentProperty(
      contactToLoanBuilderForMyCRM(property),
    );
  }

  getDocumentPresignedUrl(params) {
    return this.httpClient
      .post(`${this.baseUrl}/upload/presignedurl`, params)
      .then(({ data }) => documentPresignedUrlBuilderForUI(data));
  }

  activatePresignedDocument(documentId) {
    return this.httpClient.post(
      `${this.baseUrl}/upload/${documentId}/activate`,
    );
  }
}

export default DocumentService;
