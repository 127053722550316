import { REFERRAL_TYPE } from 'Common/constants/referralType';
import { find } from 'lodash';

class CurrencyConvertionCtrl {
  constructor(
    uiService,
    $state,
    $localStorage,
    contactService,
    $timeout,
    rateService,
  ) {
    'ngInject';

    this.uiService = uiService;
    this.$state = $state;
    this.$localStorage = $localStorage;
    this.contactService = contactService;
    this.$timeout = $timeout;
    this.rateService = rateService;
  }

  xeConvert() {
    this.$timeout.cancel(this.convertTimeout);
    this.convertTimeout = this.$timeout(() => {
      if (this.currencyCode === this.crmCurrencyCode) {
        this.XE.Amount = this.value;
        this.xeConvertedAmount = this.value;
        return;
      }

      if (this.onFirstLoad && this.xeConvertedAmount) {
        this.XE.Amount = this.xeConvertedAmount;
        this.onFirstLoad = false;
        return;
      }

      const data = {
        currencyCodeFrom: this.currencyCode,
        currencyCodeTo: this.crmCurrencyCode,
        amount: this.value || 0,
      };
      this.rateService.xeConvertCurrencyGet(data).then((response) => {
        this.XE = response.data;
        this.xeConvertedAmount = this.XE.Amount;
        this.onFirstLoad = false;
      });
    }, 800);
  }

  $onChanges(changes) {
    const { value, currencyCode } = changes;
    if (value || currencyCode) {
      this.xeConvert();
    }
  }

  proceedToReferral(clientId) {
    const { familyId, value, currencyCode, loanAppId } = this;

    this.$localStorage.xeAutoSend = {
      newReferral: true,
      referralType: REFERRAL_TYPE.XE,
      familyId,
      loanAppId,
      clientId,
      value,
      currencyCode,
    };
    this.$state.go('app.sendReferrals');
  }

  goToReferral() {
    if (this.ownersSelected[0]) {
      if (this.ownersSelected[0].BorrowerID === 0) {
        this.contactService.clientInformGet(this.familyId).then((response) => {
          const primaryContact = find(response.data, (obj) => obj.isPrimary);
          this.proceedToReferral(parseInt(primaryContact.PersonId, 10));
        });
      } else {
        this.proceedToReferral(this.ownersSelected[0].BorrowerID);
      }
    }
  }

  $onInit() {
    this.convertTimeout = false;
    this.XE = {};
    this.onFirstLoad = true;
    this.crmCurrencyCode = this.uiService.getCrmCurrencyCode();
  }
}

export default CurrencyConvertionCtrl;
