import { capitalizeFirstLetter } from 'Common/utilities/string';
import { MODAL_SERVICE } from 'Common/constants/helloBook';

export default class OpenServiceOptionModal {
  $onInit() {
    this.selectedService = '';
    this.capitalizedAction = capitalizeFirstLetter(this.action);
    this.modalSection = MODAL_SERVICE;
  }

  generateHelloBook() {
    this.modalInstance.close({ selectedService: this.selectedService });
  }
}
