import { get } from 'lodash';

class MycrmCustomSelectCtrl {
  $onInit() {
    this.setConfig();
    this.selectedItemLabel = this.config.placeholder;
  }

  $onChanges({ list = {} }) {
    const items = list.currentValue;
    if (!get(items, 'length', 0) && this.value) {
      this.onChange({});
      return;
    }
    const isValidListValue = !!(
      get(items, 'length', 0) && typeof this.value !== 'undefined'
    );
    const { key = 'id', labelKey = 'name', placeholder = 'Select item' } =
      this.config || this.setConfig();
    if (!isValidListValue) {
      this.selectedItemLabel = placeholder;
      return;
    }
    const selectedItem = items.find((item) => item[key] === this.value) || {};
    this.selectedItemLabel = selectedItem[labelKey] || placeholder;
    if (!selectedItem[key]) {
      this.onChange({});
    }
  }

  setConfig() {
    this.config = {
      isOpen: false,
      placeholder: this.placeholder || 'Select item',
      key: this.valueKey || 'id',
      labelKey: this.labelKey || 'name',
      addItemLabel: this.addItemLabel || 'Add new item',
    };
    return this.config;
  }

  onSelectItem(item) {
    this.selectedItemLabel =
      item[this.config.labelKey] || this.config.placeholder;
    this.config.isOpen = false;
    this.onChange({ item });
  }
}

export default MycrmCustomSelectCtrl;
