import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('netPromoterScore', function netPromoterScore() {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: { myParam: '=' },
        templateUrl:
          'assets/views/overviewDashboard/directiveTemplates/netPromoterScore.html',

        link() {
          // eslint-disable-next-line unicorn/consistent-function-scoping
          function init() {}

          init();
        },
      };
    });
