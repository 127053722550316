import angular from 'angular';
/**
 * Remove space in string
 */
export const loadModule = () =>
  angular.module('app').filter('removeSpace', function removeSpace() {
    return function (value) {
      return !value ? '' : value.replace(/ /g, '');
    };
  });
