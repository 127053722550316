import { getLogger } from '@loanmarket/logger-core';
import { parseToInt10 } from 'Common/utilities/parse';
import { removeFromArray } from 'Common/utilities/array';
import { toastError } from 'Common/utilities/alert';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import {
  LIST_OF_QUESTIONS_NZ,
  STRING_OPTION,
  BOOL_OPTION,
  INT_OPTION,
  DECIMAL_OPTION,
  ANSWER_TYPE,
  DISPLAY_OPTIONS,
} from 'Common/constants/customerObjectives';
import { getQuestion } from 'Common/utilities/objectivesQuestion';

export function setupCurrentlyBankingLenders({
  $scope,
  configService,
  currentUserService,
  contactService,
  loanObjectivesService,
  loanAppSharedData,
  objectivesQuestionService,
}) {
  $scope.populateBankingLenderList = () => {
    if (!configService.feature.currentlyBankingWithLenders) {
      return;
    }
    const LENDER_PLACE = 1;
    return contactService
      .lenderListNoPanelFilterGet(null, LENDER_PLACE)
      .then(({ data = [] }) => {
        $scope.bankingLenderList = cloneDeep(data).map((lender) => {
          lender.show = true;
          lender.Category = $scope.lenderListFilter;
          return lender;
        });
        $scope.bankingLenderList = $scope.addOthersOptionToTopList(
          $scope.bankingLenderList,
        );
        $scope.removeDuplicateOtherEntryFromApi();
        loanAppSharedData.bankingLenderList = [...$scope.bankingLenderList];
      });
  };

  $scope.removeDuplicateOtherEntryFromApi = () => {
    if (currentUserService.isNZ) {
      const OTHER_OPTION_ID_FROM_API = 64;
      const otherLenderFromAPI = $scope.getLenderFromList(
        $scope.bankingLenderList,
        OTHER_OPTION_ID_FROM_API,
      );
      otherLenderFromAPI &&
        removeFromArray(otherLenderFromAPI, $scope.bankingLenderList);
    }
  };

  $scope.addOthersOptionToTopList = (list, isShown = true) => {
    if (!configService.feature.currentlyBankingWithLenders) {
      return;
    }
    return [
      {
        ProviderId: $scope.OTHER_OPTION_ID,
        ProviderName: 'Other',
        Category: $scope.lenderListFilter,
        show: isShown,
      },
      ...list,
    ];
  };

  $scope.selectBankingWithLender = () => {
    if (!configService.feature.currentlyBankingWithLenders) {
      return;
    }

    $scope.ObjectivesSet.BankingWithLender.Id =
      $scope.ObjectivesSet.BankingWithLender.ProviderId;
    $scope.listBankingWithLenders.push($scope.ObjectivesSet.BankingWithLender);

    $scope.setLenderVisibility(
      $scope.bankingLenderList,
      $scope.ObjectivesSet.BankingWithLender.Id,
      false,
    );

    const isOtherSelected =
      $scope.ObjectivesSet.BankingWithLender.ProviderId ===
      $scope.OTHER_OPTION_ID;
    if (isOtherSelected) {
      $scope.isOtherBanking = true;
    }

    if (isOtherSelected) {
      $scope.ObjectivesSet.BankingWithLender = '';
      return;
    }
    $scope.updateCurrentBankingLenders();
    $scope.ObjectivesSet.BankingWithLender = '';
  };

  $scope.getLenderFromList = (list, id) =>
    list.find((lender) => parseToInt10(lender.ProviderId) === parseToInt10(id));

  $scope.isSelectedLender = (obj, lenderId) => {
    return (
      obj.answerInt &&
      obj.answerInt.some(
        (answer) => parseToInt10(answer) === parseToInt10(lenderId),
      )
    );
  };

  $scope.removeBankingLender = (lender) => {
    if (!configService.feature.currentlyBankingWithLenders) {
      return;
    }

    const { ProviderId: providerId } = lender;
    removeFromArray(lender, $scope.listBankingWithLenders);
    $scope.setLenderVisibility($scope.bankingLenderList, providerId, true);

    const isOtherBankingRemoved = providerId === $scope.OTHER_OPTION_ID;
    if (isOtherBankingRemoved) {
      $scope.removeOtherBanking();
      return;
    }

    deleteLoanObjectiveAnswer(loanObjectivesService)({
      questionId: $scope.LIST_OF_QUESTIONS.BANKING_WITH_SELECT_LENDER,
      loanAppId: $scope.loanAppId,
      answerInt: providerId,
    }).then(() => {
      objectivesQuestionService.removeObjectiveMultiDropdownValue({
        QuestionId: $scope.LIST_OF_QUESTIONS.BANKING_WITH_SELECT_LENDER,
        answerInt: providerId,
      });
    });
  };

  $scope.updateCurrentBankingLenders = () => {
    const bankingLenders = $scope.listBankingWithLenders.filter(
      (lender) =>
        parseToInt10(lender.ProviderId) !==
        parseToInt10($scope.OTHER_OPTION_ID),
    );
    $scope.ObjectivesSet.ListBankingWithLenders = bankingLenders;
    $scope.updateObjective(
      $scope.LIST_OF_QUESTIONS.BANKING_WITH_SELECT_LENDER,
      'MultiDropDown',
      0,
      parseToInt10($scope.ObjectivesSet.BankingWithLender.Id),
    );
  };

  $scope.setLenderVisibility = (list, lenderId, isVisible = true) => {
    if (!list || !lenderId) {
      return;
    }
    const foundLenderInList = $scope.getLenderFromList(list, lenderId);
    foundLenderInList && (foundLenderInList.show = isVisible);
  };

  $scope.removeOtherBanking = () => {
    $scope.isOtherBanking = false;

    $scope.ObjectivesSet.BankingWith = '';
    $scope.updateObjective(46, 'TextOnly', 0, $scope.ObjectivesSet.BankingWith);
  };

  $scope.filterSelectedBankingLenders = (obj, listBankingWithLenders) => {
    $scope.bankingLenderList = $scope.bankingLenderList.map((lender) => {
      if ($scope.isSelectedLender(obj, lender.ProviderId)) {
        lender.Id = lender.ProviderId;
        listBankingWithLenders.push(lender);
        lender.show = false;
      } else {
        lender.show = true;
      }

      return lender;
    });
  };

  $scope.isOtherBankingAdded = () => {
    if (!configService.feature.currentlyBankingWithLenders) {
      return;
    }

    const foundLenderInList = $scope.getLenderFromList(
      $scope.listBankingWithLenders,
      $scope.OTHER_OPTION_ID,
    );

    $scope.isOtherBanking = !!foundLenderInList;

    if ($scope.ObjectivesSet.BankingWith && !foundLenderInList) {
      $scope.isOtherBanking = true;
      $scope.listBankingWithLenders = $scope.addOthersOptionToTopList(
        $scope.listBankingWithLenders,
        false,
      );
      $scope.setLenderVisibility(
        $scope.bankingLenderList,
        $scope.OTHER_OPTION_ID,
        false,
      );
    }
  };

  $scope.isCurrentlyBankingWithCompleted = () => {
    if (currentUserService.isNZ && !$scope.bidLoanAppChangesValidation) {
      return true;
    }

    const bankingWith = $scope.ObjectivesSet.BankingWith;
    const hasBankingLenders =
      $scope.listBankingWithLenders.filter(
        (lender) =>
          parseToInt10(lender.ProviderId) !==
          parseToInt10($scope.OTHER_OPTION_ID),
      ) || [];

    const hasOtherBanking = $scope.listBankingWithLenders.find(
      (lender) =>
        parseToInt10(lender.ProviderId) ===
        parseToInt10($scope.OTHER_OPTION_ID),
    );

    const isFeatureOn =
      configService.feature.currentlyBankingWithLenders &&
      !!((hasOtherBanking && !!bankingWith) || hasBankingLenders.length);

    const isFeatureOff =
      !configService.feature.currentlyBankingWithLenders && !!bankingWith;

    return isFeatureOn || isFeatureOff;
  };
}

export const getReqAndObjValidation = ({
  $scope,
  loanApplicationServices,
  loanAppId,
}) => {
  loanApplicationServices.getReqAndObjValidation(loanAppId).then((response) => {
    $scope.displayOldLoanFeatures = response;
  });
};

export const setAdditionalQuestions = ($scope) => {
  const { DIRECTOR, CONDUCT } = LIST_OF_QUESTIONS_NZ;
  $scope.directorForYearsNZ = getQuestion($scope.objectiveQuestions, DIRECTOR);
  $scope.goodConductNZ = getQuestion($scope.objectiveQuestions, CONDUCT);
};

export const mapAdditionalAnswers = ($scope) => {
  const { DIRECTOR, CONDUCT } = LIST_OF_QUESTIONS_NZ;
  const directorAnswer = getQuestion($scope.loanObjectivesData, DIRECTOR);
  const conductAnswer = getQuestion($scope.loanObjectivesData, CONDUCT);

  $scope.directorForYearsNZ = {
    ...$scope.directorForYearsNZ,
    answer: get(directorAnswer, 'answerBool[0]'),
  };
  $scope.goodConductNZ = {
    ...$scope.goodConductNZ,
    answer: get(conductAnswer, 'answerBool[0]'),
  };
};

export const deleteLoanObjectiveAnswer = (loanObjectivesService) => ({
  loanAppId,
  questionId,
  answerInt,
}) => {
  if (!loanObjectivesService || !loanAppId || !questionId || !answerInt) {
    const logger = getLogger('DeleteLoanObjectiveAnswer');
    logger.error(
      `Oops missing delete parameters loanAppId: ${loanAppId}, questionId: ${questionId}, answerInt: ${answerInt}`,
    );
    toastError();
    return;
  }

  const payload = {
    loanApplicationId: loanAppId,
    questionId,
    answerInt,
  };
  return loanObjectivesService.deleteLoanObjectiveAnswer(payload);
};

export const isCustomerObjectivesPayloadValid = (payload) => {
  const requiredPayloadProperties = [
    'LoanScenarioId',
    'FamilyId',
    'QuestionId',
    'DisplayOptionId',
  ];

  const { DisplayOptionId: displayOptionId } = payload;

  if (STRING_OPTION.includes(displayOptionId)) {
    requiredPayloadProperties.push(ANSWER_TYPE.ANSWER_STRING);
  }
  if (BOOL_OPTION.includes(displayOptionId)) {
    requiredPayloadProperties.push(ANSWER_TYPE.ANSWER_BOOL);
  }
  if (INT_OPTION.includes(displayOptionId)) {
    requiredPayloadProperties.push(ANSWER_TYPE.ANSWER_INT);
  }
  if (DECIMAL_OPTION.includes(displayOptionId)) {
    requiredPayloadProperties.push(ANSWER_TYPE.ANSWER_DECIMAL);
  }

  if (parseToInt10(displayOptionId) === DISPLAY_OPTIONS.TEXTBOX_ON_CHECKED) {
    requiredPayloadProperties.push(ANSWER_TYPE.ANSWER_STRING);
    requiredPayloadProperties.push(ANSWER_TYPE.ANSWER_BOOL);
  }

  const isValid = requiredPayloadProperties.every(
    (property) => payload[property] !== undefined,
  );

  if (!isValid) {
    const logger = getLogger('CustomerObjectives');
    logger.error(
      `isCustomerObjectivesPayloadValid: require property is missing in ${JSON.stringify(
        payload,
      )}`,
    );
  }

  return isValid;
};
