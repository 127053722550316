import angular from 'angular';
import {
  getDateSelection,
  getCurrentDateParts,
} from 'Common/utilities/calendar';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('commissionCalendar', function commissionCalendar() {
      return {
        restrict: 'E',
        templateUrl: 'assets/views/commission/commissionCalendar.html',
        scope: {
          selectedDate: '=',
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        controller: /* @ngInject */ function commisionCalendarController(
          $scope,
          commissionService,
          $filter,
          $rootScope,
        ) {
          $scope.currentDate = new Date();

          $scope.calendarDays = [
            'Sun',
            'Mon',
            'Tue',
            'Wed',
            'Thu',
            'Fri',
            'Sat',
          ];
          $scope.availableStatement = [];
          $scope.calendarDateNumber = [];
          $scope.isLoading = false;
          $scope.calendarSelectorActive = 'Month';
          $scope.isSelectMonth = false;

          function setDateSelection() {
            Object.assign(
              $scope,
              getDateSelection(
                $scope.currentMonth,
                $scope.currentDay,
                $scope.currentYear,
              ),
            );
          }

          function initializeCurrentDates() {
            Object.assign($scope, getCurrentDateParts($scope.currentDate));
            setDateSelection();
          }

          initializeCurrentDates();

          function getLastDayOfMonth(dateParam) {
            const year = dateParam.getFullYear();
            const month = dateParam.getMonth();
            const temp = new Date(year, month + 1, 0);
            return temp.getDate();
          }

          function getFistDay(dateParam) {
            const year = dateParam.getFullYear();
            const month = dateParam.getMonth();
            const temp = new Date(year, month, 1);
            return temp.getDay();
          }

          function isToday(dateParam) {
            const temp = new Date();
            const date = temp.getDate();
            const year = temp.getFullYear();
            const month = temp.getMonth();

            const today = `${month + 1}/${date}/${year}`;

            return today === dateParam;
          }

          function isStatement(date) {
            if (typeof $scope.availableStatement !== 'undefined') {
              return $scope.availableStatement.includes(
                $filter('date')(new Date(date), 'yyyy-MM-dd'),
              );
            }
            return false;
          }

          function fillCalendar(dateValue) {
            let dateParam = dateValue;
            $scope.calendarDateNumber = [];
            dateParam = new Date(dateParam);
            const year = dateParam.getFullYear();
            const month = dateParam.getMonth();
            const lastDay = getLastDayOfMonth(dateParam);
            const startingDay = getFistDay(dateParam);
            let isFirst = true;
            let day = 1;
            while (day <= lastDay) {
              const weekData = [];
              for (let week = 0; week < 7; week++) {
                let data = {};
                if ((startingDay !== week && isFirst) || day > lastDay) {
                  data = {
                    day: '',
                    fullDate: '',
                    isToday: false,
                    isThisMonth: false,
                    isStatementAvailable: false,
                  };
                } else {
                  data = {
                    day,
                    fullDate: `${month + 1}/${day}/${year}`,
                    isToday: isToday(`${month + 1}/${day}/${year}`),
                    isThisMonth: true,
                    isStatementAvailable: isStatement(
                      `${year}-${month + 1}-${day}`,
                    ),
                  };
                  isFirst = false;
                  day++;
                }
                weekData.push(data);
              }
              $scope.calendarDateNumber.push(weekData);
            }
          }

          function getStatementForMonth() {
            $scope.isLoading = true;
            commissionService
              .availableStatementGet(
                $filter('date')(new Date($scope.selectedDate), 'yyyy-MM-dd'),
                0,
              )
              .then((response) => {
                $scope.availableStatement = response.data;
                fillCalendar($scope.selectedDate);
                $scope.isLoading = false;
                // $scope.availableStatement = ["2016-10-20T15:45:03.288Z", "2016-25-20T15:45:03.288Z"];
              });
          }

          const resestCalendatDate = $rootScope.$on(
            'getStatementForMonth',
            () => {
              initializeCurrentDates();
            },
          );

          $scope.nextMonth = function () {
            const currentMonth = $scope.currentDate;
            if (currentMonth.getMonth() === 11) {
              $scope.currentDate = new Date(
                currentMonth.getFullYear() + 1,
                0,
                1,
              );
              initializeCurrentDates();
              getStatementForMonth();
            } else {
              $scope.currentDate = new Date(
                currentMonth.getFullYear(),
                currentMonth.getMonth() + 1,
                1,
              );
              initializeCurrentDates();
              getStatementForMonth();
            }
          };
          $scope.previousMonth = function () {
            const currentMonths = $scope.currentDate;
            if (currentMonths.getMonth() === 0) {
              $scope.currentDate = new Date(
                currentMonths.getFullYear() - 1,
                11,
                1,
              );
              initializeCurrentDates();
              getStatementForMonth();
            } else {
              $scope.currentDate = new Date(
                currentMonths.getFullYear(),
                currentMonths.getMonth() - 1,
                1,
              );
              initializeCurrentDates();
              getStatementForMonth();
            }
          };
          $scope.selectDate = function (selectedDate) {
            if (selectedDate !== '') {
              $scope.selectedDate = selectedDate;
              $rootScope.$broadcast('weekSliderCalendarDate', {
                calendarDatePkt: selectedDate,
              });
            }
          };
          $scope.setCalendarSelector = function (value) {
            $scope.calendarSelectorActive = value;
          };
          $scope.selectMonth = function () {
            $scope.isSelectMonth = true;
          };
          $scope.setMonth = function (month) {
            $scope.calendarSelectorActive = 'Year';
            $scope.currentMonth = month + 1;
            setDateSelection();
            getStatementForMonth();
          };
          $scope.setYear = function (year) {
            $scope.isSelectMonth = false;
            $scope.currentYear = year;
            setDateSelection();
            getStatementForMonth();
          };
          $scope.closeDateSelection = function () {
            $scope.isSelectMonth = false;
            $scope.calendarSelectorActive = 'Month';
          };
          $scope.upYear = function () {
            $scope.yearSelectionValue += 4;
          };
          $scope.downYear = function () {
            $scope.yearSelectionValue -= 4;
          };
          $scope.$on('$destroy', resestCalendatDate);

          getStatementForMonth();
        },
      };
    });
