import { get as getPropValue, flow as flowDash } from 'lodash';
import {
  COLLAPSE,
  JOINT_OWNERSHIP_OPTION,
  JOINT_OWNERSHIP,
  SINGLE_OWNERSHIP,
  UNEQUAL_OWNERSHIP,
  DEFAULT_OTHER_TYPE,
} from 'Common/constants/livingExpense';
import { INPUT_FIELDS_DEBOUNCE_TIMEOUT } from 'Common/constants/formSettings';
import { BROKER_NOTES_MAX_LENGTH } from 'Common/constants/loanAppBrokerNotes';
import {
  FINANCIAL_FREQUENCY,
  FINANCIAL_TYPE,
} from 'Common/constants/financialFrequency';
import {
  calculateTotalGroupAmount,
  calculateTotalMonthlyExpense,
  getExpenseTypeIndex,
  createTypes,
  getOwnerNameInitial,
  isValidToUpdate,
  checkExpensesAge,
  setBorrowerId,
  addType,
  removeType,
  resetDefault,
  getBorrowersDropdown,
  resetDeletePopover,
  setUnequalBorrowerIfAny,
  mapUnequalExpenseTypes,
  getNewTypes,
  formatTypesFromUnequal,
  setTypeValueLookup,
  mapTypeToAdd,
} from 'Common/utilities/livingExpense';
import { INFO_EXPIRATION_MSG } from 'Common/config/infoExpiration';
import { toastInfo, toastSuccess, toastError } from 'Common/utilities/alert';
import { parseToInt10 } from 'Common/utilities/parse';

class LivingExpenseCtrl {
  constructor(
    optionsService,
    contactService,
    contactModelService,
    currentUserService,
    loanScenarioService,
    configService,
    $uibModal,
  ) {
    'ngInject';

    this.optionsService = optionsService;
    this.contactService = contactService;
    this.jointOwnership = JOINT_OWNERSHIP;
    this.singleOwnership = SINGLE_OWNERSHIP;
    this.unequalOwnership = UNEQUAL_OWNERSHIP;
    this.contactModelService = contactModelService;
    this.currentUserService = currentUserService;
    this.loanScenarioService = loanScenarioService;
    this.configService = configService;
    this.$uibModal = $uibModal;
    this.BROKER_NOTES_MAX_LENGTH = BROKER_NOTES_MAX_LENGTH;
    this.INFO_EXPIRATION_MSG = INFO_EXPIRATION_MSG;
    this.addType = addType;
    this.resetDeletePopover = resetDeletePopover;
  }

  $onInit() {
    this.typeValueLookup = {};
    this.inputNoteTimeout = INPUT_FIELDS_DEBOUNCE_TIMEOUT;
    this.expenseTypes = [];
    this.originalExpenseTypes = [];
    this.showContent = true;
    this.showNotes = false;
    this.hasExpiredInfo = false;
    this.getFrequencyTypes();
    this.confirmFinancials = this.configService.feature.confirmFinancials;
    this.unequalShares = this.configService.feature.unequalSharesFinancialsExpense;
    this.setIsCurrentFamilyHasExpense();
  }

  $onChanges(changes) {
    const { familyId } = changes;
    if (familyId && !familyId.currentValue) {
      return;
    }
    this.getBorrowers();
    this.setIsCurrentFamilyHasExpense();
  }

  getFrequencyTypes() {
    this.frequencyTypes = [];
    this.optionsService.FinancialFrequency().then(({ data }) => {
      this.frequencyTypes = data;
      this.mapFrequencyTypes(data);
    });
  }

  mapFrequencyTypes(data) {
    this.mappedFrequencyTypes = data.map((freq) => {
      return { label: freq.Name.toLowerCase(), value: freq.Value };
    });
  }

  getBorrowers() {
    if (!this.familyId) {
      return;
    }
    this.borrowers = [];
    this.contactService
      .borrowersDetailsGet(this.familyId, 0)
      .then(({ data }) => {
        this.borrowers = data;
        this.borrowersDropdown = getBorrowersDropdown(data, this.unequalShares);
        this.modalBorrowersDropdown = getBorrowersDropdown(data);
        this.getExpenseTypes();
      });
  }

  getFinancialIds() {
    const ids = this.expenseTypes.reduce(
      (initial, expense) => [
        ...initial,
        ...expense.types.reduce((initialType, type) => {
          if (!type || !type.id) {
            return initialType;
          }
          return [...initialType, type.id];
        }, ''),
      ],
      [],
    );
    return ids.toString();
  }

  confirmFinancialExpense() {
    const expenseIds = this.getFinancialIds();
    if (!expenseIds) {
      return;
    }
    return this.contactModelService
      .confirmContactExpenses(expenseIds)
      .then(() => {
        this.hasExpiredInfo = false;
        toastSuccess(
          `Successfully confirmed financials ${FINANCIAL_TYPE.EXPENSE} info!`,
        );
        this.getExpenseTypes();
        this.isLoanApp && this.onExpenseChange();
      })
      .catch(toastError);
  }

  getExpenseTypes(refresh) {
    this.isLoadingTypes = true;
    this.contactModelService
      .getExpenseTypes({ familyId: this.familyId })
      .then((data) => {
        const { financialInfo, totalAmount } = data;
        this.totalLivingExpense = totalAmount;
        this.expenseTypes = this.unequalShares
          ? flowDash([
              (financials) =>
                setUnequalBorrowerIfAny(financials, this.borrowers),
              (financials) =>
                mapUnequalExpenseTypes(financials, this.borrowers),
            ])(financialInfo)
          : [...financialInfo];
        this.originalExpenseTypes = [...financialInfo];
        this.isLoadingTypes = false;
        if (refresh) {
          return;
        }
        this.hasExpiredInfo = checkExpensesAge(
          this.expenseTypes,
          this.confirmFinancials,
        );
      });
  }

  openExpenseGridModal() {
    if (!this.familyId) {
      return;
    }
    const props = {
      familyId: this.familyId,
      borrowers: this.borrowers,
      frequencyTypes: this.frequencyTypes,
    };
    const modalInstance = this.$uibModal.open({
      template: `<expense-grid-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  borrowers="vm.props.borrowers"
                  frequency-types="vm.props.frequencyTypes">
                </expense-grid-modal>`,
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'expense-grid-le2',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then((response) => {
      this.getExpenseTypes(!!response);
      this.isLoanApp && this.onExpenseChange();
    });
  }

  toggleShowContent() {
    this.showContent = !this.showContent;
  }

  setIsCurrentFamilyHasExpense() {
    const familyExpense =
      (this.expenseList || []).find(
        (family) =>
          parseToInt10(family.FamilyId) === parseToInt10(this.familyId),
      ) || {};

    this.isCurrentFamilyHasExpense = !!getPropValue(
      familyExpense,
      'overview.FinancialInfo.length',
      0,
    );
  }

  toggleShowNotes() {
    this.showNotes = !this.showNotes;
  }

  toggleShowGroupContent(expenseType, isSaved) {
    if (!expenseType) {
      return;
    }
    this.expenseTypes = this.expenseTypes.map((obj) => {
      obj.SHOW_CONTENT =
        expenseType.categoryId === obj.categoryId
          ? !expenseType.SHOW_CONTENT
          : false;
      const updatedObject =
        isSaved && expenseType.categoryId === obj.categoryId
          ? isValidToUpdate(obj, this.currentUserService.preferredName)
          : obj;
      this.totalLivingExpense = isSaved
        ? calculateTotalMonthlyExpense(
            this.totalLivingExpense,
            obj.groupTotalAmountPerMonth,
          )
        : this.totalLivingExpense;
      return { ...obj, ...updatedObject };
    });
  }

  saveBrokerNote(subsect, content) {
    this.loanScenarioService
      .brokerNotesSet(this.loanAppId, subsect, content)
      .then(() => {
        toastInfo('', 'Note in this section was updated.');
      })
      .catch(toastError);
  }

  saveExpense(expenseType) {
    if (!expenseType) {
      return;
    }
    resetDefault(expenseType);
    const isSaved = true;
    this.totalLivingExpense = 0;
    this.toggleShowGroupContent(expenseType, isSaved);
    this.hasExpiredInfo = checkExpensesAge(
      this.expenseTypes,
      this.confirmFinancials,
    );
    const { types } = expenseType;
    const newTypes = this.unequalShares
      ? flowDash([
          (types) => formatTypesFromUnequal(types, this.originalExpenseTypes),
          (types) => createTypes(types, this.borrowers),
        ])(types)
      : createTypes(types, this.borrowers);
    const postData = {
      ...expenseType,
      familyId: this.familyId,
      types: newTypes,
    };
    if (!postData.types || !postData.types.length) {
      return;
    }
    this.contactModelService
      .setExpenseType(postData)
      .then((response) => {
        if (!response) {
          return;
        }
        this.getExpenseTypes(!!response);
        this.isLoanApp && this.onExpenseChange();
      })
      .catch(toastError);
  }

  calculateTotalGroupAmount(expenseType) {
    const index = getExpenseTypeIndex(this.expenseTypes, expenseType);
    this.expenseTypes[index] = calculateTotalGroupAmount(expenseType);
  }

  removeType(expenseType, type, index) {
    removeType(expenseType, type, index);
    this.calculateTotalGroupAmount(expenseType);
  }

  updateOwnerName(type) {
    const { borrowerId } = type;
    if (!borrowerId) {
      type.owner = null;
      return type;
    }
    type.borrowerId = setBorrowerId(this.borrowers, borrowerId);
    type.owner = getOwnerNameInitial(this.borrowers, type.borrowerId);
  }

  toggleDetails({ expenseType, $index, propertyName, value }) {
    const index = getExpenseTypeIndex(this.expenseTypes, expenseType);
    const { types } = this.expenseTypes[index];
    if (!types[$index].SHOW_OWNER) {
      types[$index].borrowerId = setBorrowerId(
        this.borrowers,
        types[$index].borrowerId,
      );
      types[$index].owner = getOwnerNameInitial(
        this.borrowers,
        types[$index].borrowerId,
      );
    }
    types[$index] = {
      ...types[$index],
      ...COLLAPSE.TYPE,
    };
    types[$index][propertyName] = !value;
    this.expenseTypes[index].types[$index] = {
      ...types[$index],
    };
  }

  onShowConfirmDelete(expenseType, type) {
    this.resetDeletePopover(expenseType);
    type.showConfirmDeletePopover = true;
  }

  addOtherType(expenseType, data) {
    if (!getPropValue(expenseType, 'types.length')) {
      return;
    }
    const defaultTypeToAdd = {
      ...DEFAULT_OTHER_TYPE,
      borrowerId: data.owner,
      description: data.description,
      value: parseFloat(data.value),
      frequencyId: parseToInt10(data.frequencyId),
    };
    const typeToPush = this.unequalOwnership
      ? mapTypeToAdd(
          data,
          defaultTypeToAdd,
          this.borrowers,
          this.typeValueLookup,
        )
      : [defaultTypeToAdd];

    expenseType.types.push(...typeToPush);
    this.calculateTotalGroupAmount(expenseType);
  }

  openOtherExpenseModal(expenseType) {
    const owner = getPropValue(
      this.modalBorrowersDropdown,
      '[0].BorrowerID',
      '',
    );
    const props = {
      borrowersDropdown: this.modalBorrowersDropdown,
      frequencyTypes: this.mappedFrequencyTypes,
      form: {
        owner,
        frequencyId: `${FINANCIAL_FREQUENCY.MONTHLY}`,
      },
    };

    const modalInstance = this.$uibModal.open({
      template: `<other-expense-form
        modal-instance="vm.modalInstance"
        borrowers-dropdown="vm.props.borrowersDropdown"
        frequency-types="vm.props.frequencyTypes"
        form="vm.props.form"
      ></other-expense-form>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      keyboard: false,
      backdrop: 'static',
      size: 'sm',
      windowClass: 'other-expense-form-modal',
      resolve: {
        props: () => props,
      },
    });

    modalInstance.result.then((res) => {
      const shouldAddType = res && res.isSubmitted && res.data;
      shouldAddType && this.addOtherType(expenseType, res.data);
    });
  }

  changeOwnership({ types, typeId, index, borrowerId, description }) {
    if (!this.unequalShares) {
      return;
    }
    const correctedTypes = types.map((type) => {
      if (type.owner === JOINT_OWNERSHIP_OPTION.FirstName) {
        return {
          ...type,
          borrowerId: JOINT_OWNERSHIP,
        };
      } else {
        return type;
      }
    });
    this.typeValueLookup = {
      ...this.typeValueLookup,
      ...setTypeValueLookup(correctedTypes),
    };
    this.expenseTypes[index].types = getNewTypes({
      types: correctedTypes,
      typeId,
      borrowerId,
      pureBorrowersList: this.borrowers,
      typeValueLookup: this.typeValueLookup,
      description,
    });
    this.calculateTotalGroupAmount(this.expenseTypes[index]);
  }
}

export default LivingExpenseCtrl;
