import template from './leadGeneratorChannel.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    leadGeneratorChannel: '<',
    onCopyLink: '&',
    onOpenImageModal: '&',
    onOpenNewTab: '&',
  },
};
