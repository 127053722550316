export const getCreditGuideDisplay = ({ $scope }) => {
  const showCreditGuideToggle = $scope.isAU && !$scope.onlyFactFind;
  const displayCreditGuide =
    showCreditGuideToggle && !$scope.showCreditGuideWithHelloPack;
  const displayCreditGuideWithHelloPack =
    showCreditGuideToggle && $scope.showCreditGuideWithHelloPack;

  return { displayCreditGuide, displayCreditGuideWithHelloPack };
};

export const showHelloPackToggle = ({
  $scope,
  displayCreditGuideWithHelloPack,
}) => {
  return $scope.isAU
    ? !$scope.onlyFactFind && !displayCreditGuideWithHelloPack
    : !$scope.onlyFactFind;
};
