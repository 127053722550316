import dotProp from 'dot-prop-immutable';

export const validateEmploymentYears = ({
  $scope,
  employmentService,
}) => () => {
  const employmentList = dotProp.get(
    $scope,
    'loanAppSharedData.Employment.AllList',
    [],
  );
  employmentList.forEach((employment) => {
    const data = employmentService.countYears(employment);
    const monthsEmployed = data.yearsEmployed.reduce(
      (accum, employmentInfo) => accum + employmentInfo.frequency,
      0,
    );
    const messageBasedOnMonths = monthsEmployed
      ? `Currently ${monthsEmployed} months only have been supplied`
      : 'Currently no history has been supplied';
    const message = `Please note - most lenders require a 3 year history. ${messageBasedOnMonths}`;
    $scope.invalidClientEmploymentMessage = {
      ...$scope.invalidClientEmploymentMessage,
      [employment.FamilyId]: message,
    };
    $scope.showInvalidClientEmployment = monthsEmployed < data.compareFrequency;
  });
};
