import { appendEmptySelect } from 'Common/utilities/options';
import { DEFERRED_STATUS_ID } from 'Common/constants/insuranceApplicationPipeline';
import { DD_MM_YYYY_FORMAT, UI_MASK_DATE } from 'Common/constants/dateConfigs';
import { paymentFrequenciesForUI } from 'Common/utilities/insurance';

export default class OverViewCtrl {
  constructor(insuranceFireAndGeneralService, insuranceQuoteService) {
    'ngInject';

    this.insuranceFireAndGeneralService = insuranceFireAndGeneralService;
    this.insuranceQuoteService = insuranceQuoteService;
  }

  $onInit() {
    this.loadStatusAndFrequency();
    this.DD_MM_YYYY_FORMAT = DD_MM_YYYY_FORMAT.toLowerCase();
    this.UI_MASK_DATE = UI_MASK_DATE;

    this.DEFERRED_STATUS_ID = DEFERRED_STATUS_ID.V1;
  }

  loadStatusAndFrequency() {
    this.insuranceQuoteService
      .insuranceQuoteFrequencyGet(true)
      .then(({ data }) => {
        const frequencyList = paymentFrequenciesForUI(data);
        this.frequencyList = [
          { name: 'Select Frequency', value: 0 },
          ...frequencyList,
        ];
      });

    this.insuranceFireAndGeneralService
      .insuranceStatusGet()
      .then((response) => {
        this.statusList = appendEmptySelect(response, {
          statusId: 0,
          statusName: 'Select Status',
        });
      });

    this.loadDefault();
    this.getOverviewValues();
  }

  getOverviewValues() {
    this.overViewForm({ $e: { overViewForm: this.insuranceData } });
  }

  loadDefault() {
    if (!this.insuranceData) {
      this.insuranceData = {
        statusId: 0,
        paymentFrequencyId: 0,
      };
    }
  }
}
