export default class GmailSyncModalService {
  constructor($uibModal, $window) {
    'ngInject';

    this.gmailModal = $uibModal;
    this.windowFunction = $window;
  }

  openGmailSyncWindow({
    gmailSyncUrl,
    isGmailFeatureOn,
    isGmailSync,
    onUnlinkSuccess,
  }) {
    if (isGmailFeatureOn) {
      this.openGmailSyncModal(gmailSyncUrl, isGmailSync, onUnlinkSuccess);
      return;
    }
    this.windowFunction.open(gmailSyncUrl);
  }

  openGmailSyncModal(gmailSyncUrl, gmailSyncStatusFlag, onUnlinkSuccess) {
    const props = { gmailSyncUrl, gmailSyncStatusFlag, onUnlinkSuccess };
    return this.gmailModal.open({
      animation: true,
      template: `<link-gmail-sync-modal
         modal-instance="vm.modalInstance" 
         user-auth-url="vm.props.gmailSyncUrl" 
         gmail-sync-status-flag="vm.props.gmailSyncStatusFlag" 
         on-unlink-success="vm.props.onUnlinkSuccess()">
        </link-gmail-sync-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      size: 'md',
      resolve: {
        props: () => props,
      },
    });
  }

  openConfirmUnlinkGmailSyncModal() {
    const props = {};
    return this.gmailModal.open({
      animation: true,
      template: `<confirm-unlinked-gmail-sync-modal modal-instance="vm.modalInstance"></confirm-unlinked-gmail-sync-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      size: 'md',
      resolve: {
        props: () => props,
      },
    });
  }
}
