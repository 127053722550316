import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('ReferralsCtrl', function ReferralsCtrl(
      $scope,
      $http,
      configService,
      $filter,
      referralsService,
      $timeout,
      $uibModal,
      moment,
    ) {
      // init Function
      $scope.init = function () {
        $scope.referrers = {};
        $scope.referrers.status = 'Active';
        $scope.referrers.isVisible = true;
        $scope.referrers.isActive = true;
        $scope.referrers.isReferrers = true;
        // eslint-disable-next-line sonarjs/no-duplicate-string
        $scope.referrers.dateCreated = moment().format('DD MMM YYYY');
        $scope.referrers.createDateFrom = moment().format('DD MMM YYYY');
        $scope.referrers.createDateTo = moment().format('DD MMM YYYY');

        // Get Organisaton Members
        $scope.orgMembersList = [];
        referralsService.getReferrerOrgMembers().then((response) => {
          $scope.orgMembersList = response.data;
          const $data = _.first(response.data);
          $scope.referrers.mermberId = $data.FamilyID.toString();
        });

        // Get Organisation Owner Group
        $scope.orgOwnerGroupList = [];
        referralsService.getReferrerOrgOwnerGroup().then((response) => {
          $scope.orgOwnerGroupList = response.data;
          const $data = _.first(response.data);
          $scope.referrers.groupId = $data.OrganizationID.toString();

          // Get Agreement Types
          $scope.agreementTypesList = [];
          referralsService
            .getOrganizationTypes()
            .then((getOrganizationTypesResponse) => {
              $scope.agreementTypesList = getOrganizationTypesResponse.data;
              const $dataGetOrganizationTypes = _.first(
                getOrganizationTypesResponse.data,
              );
              $scope.referrers.groupTypeId =
                $dataGetOrganizationTypes.OrganizationTypeId;
              $scope.getReferrers();
            });
        });

        // Get Agreement Types
        $scope.getReferrers = function () {
          $scope.referrersList = [];
          $scope.groupsList = [];
          if ($scope.referrers.isReferrers === true) {
            referralsService
              .getReferrerOrgAgreementsByReferrers(
                $scope.referrers.status,
                $scope.referrers.createDateFrom,
                $scope.referrers.createDateTo,
                $scope.referrers.mermberId,
                $scope.referrers.groupId,
                $scope.referrers.isVisible,
                $scope.referrers.isActive,
              )
              .then((response) => {
                $scope.referrersList = response.data;
              });
          } else {
            referralsService
              .getReferrerOrgAgreementsByGroup(
                $scope.referrers.status,
                $scope.referrers.createDateFrom,
                $scope.referrers.createDateTo,
                $scope.referrers.groupTypeId,
                $scope.referrers.mermberId,
                $scope.referrers.groupId,
                $scope.referrers.isVisible,
                $scope.referrers.isActive,
              )
              .then((response) => {
                $scope.groupsList = response.data;
              });
          }
        };
      };

      $scope.init();

      // Get And Set Date
      $scope.getSetDate = function (type) {
        let startOfWeek;
        let endOfWeek;
        let startOfMonth;
        let endOfMonth;
        if (type === 'Today') {
          $scope.referrers.dateCreated = moment().format('DD MMM YYYY');
          $scope.referrers.createDateFrom = moment().format('DD MMM YYYY');
          $scope.referrers.createDateTo = moment().format('DD MMM YYYY');
        }
        if (type === 'Yesterday') {
          const Yesterday = moment().subtract(1, 'day');
          $scope.referrers.dateCreated = moment(Yesterday).format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateFrom = moment(Yesterday).format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateTo = moment(Yesterday).format(
            'DD MMM YYYY',
          );
        }

        if (type === 'This Week') {
          startOfWeek = moment().startOf('isoWeek').toDate();
          endOfWeek = moment().endOf('isoWeek').toDate();
          $scope.referrers.dateCreated = `${moment(startOfWeek).format(
            'DD MMM YYYY',
          )} - ${moment(endOfWeek).format('DD MMM YYYY')}`;
          $scope.referrers.createDateFrom = moment(startOfWeek).format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateTo = moment(endOfWeek).format(
            'DD MMM YYYY',
          );
        }

        if (type === 'Last Week') {
          startOfWeek = moment()
            .subtract(1, 'weeks')
            .startOf('isoWeek')
            .toDate();
          endOfWeek = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
          $scope.referrers.dateCreated = `${moment(startOfWeek).format(
            'DD MMM YYYY',
          )} - ${moment(endOfWeek).format('DD MMM YYYY')}`;
          $scope.referrers.createDateFrom = moment(startOfWeek).format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateTo = moment(endOfWeek).format(
            'DD MMM YYYY',
          );
        }
        if (type === 'This Month') {
          startOfMonth = moment().startOf('month').toDate();
          endOfMonth = moment().endOf('month').toDate();
          $scope.referrers.dateCreated = `${moment(startOfMonth).format(
            'DD MMM YYYY',
          )} - ${moment(endOfMonth).format('DD MMM YYYY')}`;
          $scope.referrers.createDateFrom = moment(startOfMonth).format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateTo = moment(endOfMonth).format(
            'DD MMM YYYY',
          );
        }

        if (type === 'Last Month') {
          startOfMonth = moment()
            .subtract(1, 'months')
            .startOf('month')
            .toDate();
          endOfMonth = moment().subtract(1, 'months').endOf('month').toDate();
          $scope.referrers.dateCreated = `${moment(startOfMonth).format(
            'DD MMM YYYY',
          )} - ${moment(endOfMonth).format('DD MMM YYYY')}`;
          $scope.referrers.createDateFrom = moment(startOfMonth).format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateTo = moment(endOfMonth).format(
            'DD MMM YYYY',
          );
        }
        if (type === 'Last 3 Months') {
          startOfMonth = moment()
            .subtract(3, 'months')
            .startOf('month')
            .toDate();
          endOfMonth = moment().subtract(1, 'months').endOf('month').toDate();
          $scope.referrers.dateCreated = `${moment(startOfMonth).format(
            'DD MMM YYYY',
          )} - ${moment(endOfMonth).format('DD MMM YYYY')}`;
          $scope.referrers.createDateFrom = moment(startOfMonth).format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateTo = moment(endOfMonth).format(
            'DD MMM YYYY',
          );
        }
        if (type === 'On') {
          $scope.referrers.dateCreated = moment($scope.onDate).format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateFrom = moment($scope.onDate).format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateTo = moment($scope.onDate).format(
            'DD MMM YYYY',
          );
        }
        if (type === 'Before') {
          $scope.referrers.dateCreated = `Before ${moment(
            $scope.beforeDate,
          ).format('DD MMM YYYY')}`;
          const beforeDay = moment($scope.beforeDate).subtract(1, 'day');
          $scope.referrers.createDateFrom = moment('1 Jan 2015').format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateTo = moment(beforeDay).format(
            'DD MMM YYYY',
          );
        }
        if (type === 'After') {
          $scope.referrers.dateCreated = `After ${moment(
            $scope.afterDate,
          ).format('DD MMM YYYY')}`;
          const afterDay = moment($scope.afterDate).add(1, 'day');
          $scope.referrers.createDateFrom = moment(afterDay).format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateTo = moment().format('DD MMM YYYY');
        }
        if (type === 'Range') {
          $scope.referrers.dateCreated = `${moment($scope.fromDate).format(
            'DD MMM YYYY',
          )} - ${moment($scope.toDate).format('DD MMM YYYY')}`;
          $scope.referrers.createDateFrom = moment($scope.fromDate).format(
            'DD MMM YYYY',
          );
          $scope.referrers.createDateTo = moment($scope.toDate).format(
            'DD MMM YYYY',
          );
        }
        $scope.getReferrers();
      };

      // Show Filter In Mobile
      $scope.showfilter = function () {
        $scope.isFilterDiv = true;
      };

      // Hide Filter In Mobile
      $scope.hidefilter = function () {
        $scope.isFilterDiv = false;
      };

      // Open Model For Add Referral
      // -----------------------------------------------------------------------------
      $scope.openModelForAddReferral = function (size) {
        $uibModal.open({
          templateUrl:
            '/assets/views/referrals/partials/add_referral_modal.html',
          controller: 'OpenModelForAddReferralModalCtrl',
          size,
          windowClass: 'referral_type',
          resolve: {},
        });
      };

      // Open Model For Select Date In Mobile
      // -----------------------------------------------------------------------------
      $scope.openModelForSelectDate = function (size) {
        $uibModal.open({
          templateUrl:
            '/assets/views/referrals/partials/date_select_modal.html',
          controller: 'OpenModelForSelectDateModalCtrl',
          size,
          windowClass: 'referral_date',
          resolve: {
            getSetDate() {
              return $scope.getSetDate;
            },
            referrer() {
              return $scope.referrers;
            },
            getReferrers() {
              return $scope.getReferrers;
            },
          },
        });
      };
    });
