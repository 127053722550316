import template from './involvedPartiesOption.html';
import controller from './involvedPartiesOptionCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    instance: '<',
    opportunityId: '<',
    selectedValues: '<',
    isSelectAllByDefault: '<',
    required: '<',
    familyId: '<?',
    direction: '@',
    feature: '@',
    label: '@',
  },
};
