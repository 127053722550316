import angular from 'angular';
import {
  checkSingleOrBulkEmailUtil,
  saveMailLogUtil,
} from './util/openSendMailModalCtrl';

export const loadModule = () =>
  angular
    .module('app')
    .controller('OpenSendMailModalCtrl', function openSendMailModalCtrl(
      $uibModalInstance,
      $scope,
      $state,
      $window,
      $controller,
      $timeout,
      source,
      sendMailObj,
      listTableData,
      isInsurance,
      tab,
      contactService,
      customerCareService,
      customerCareInsuranceService,
      ckEditorConfigService,
      currentModuleId,
      currentModuleType,
      currentModuleName,
      currentUserService,
    ) {
      const vm = this;
      angular.extend(
        vm,
        $controller('CustomerCareSendMailModalBaseCtrl', { $scope }),
      );

      $scope.recipients = {};
      $scope.recipients.email = [];
      $scope.listTableData = listTableData;
      $scope.currentModule = currentModuleId;

      $scope.checkSingleOrBulkEmail = checkSingleOrBulkEmailUtil({
        $scope,
        currentUserService,
        source,
        isInsurance,
        sendMailObj,
        currentModuleName,
        tab,
        currentModuleType,
        listTableData,
      });

      $scope.selectEmailList = [];
      $scope.getRecipientAutoSuggestion = function (userInput) {
        contactService
          .getRecipientAutoSuggestion(userInput)
          .then((response) => {
            $scope.selectEmailList = response.data;
          });
      };

      $scope.saveMailLog = saveMailLogUtil({
        $scope,
        currentModuleType,
        isInsurance,
        tab,
        customerCareInsuranceService,
        customerCareService,
        sendMailObj,
        $uibModalInstance,
        $window,
        $timeout,
      });

      $scope.saveEmailContentHtmlToRTF = function (htmlStr) {
        if (!htmlStr || !htmlStr.trim()) {
          return;
        }
        contactService
          .convertContentHtmlToRTF({ htmlString: htmlStr })
          .then((response) => {
            if (!response) {
              return;
            }
            $scope.saveMailLog();
          });
      };

      $scope.refreshResults = function (select) {
        const search = select.search;

        $scope.getRecipientAutoSuggestion(search);
      };
      function init() {
        $scope.familyId = sendMailObj ? sendMailObj.familyID : null;
        $scope.sendMailObj = sendMailObj;

        // Ckeditor Tollbar Configuration
        $scope.options = ckEditorConfigService.getCustomerCareEmailEditorConfig();

        $scope.subject = 'Happy Birthday!';
        $scope.content = '';
        $scope.checkSingleOrBulkEmail();
      }

      init();
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    });
