import angular from 'angular';
import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';
import {
  SERVICE_TAB_INDEX,
  SERVICE_ID,
  SUBSCRIPTION_MESSAGES,
  ORG_SUBSCRIPTION,
  CUSTOMER_CARE_TABS,
  SERVICE_ID_NZ,
} from 'Common/constants/customerCareModules';
import { objectLength } from 'Common/utilities/objectValidation';
import { openVideoModal } from 'Common/utilities/trainingVideoModal';
import {
  checkAnnualReviews,
  annualReviewBannerUtil,
} from 'Common/utilities/customerCare';
import { dateRangeOptionBuilderForUI } from 'Common/mappers/customerCare';
import {
  onTabClick,
  getActiveTabIndex,
  onChangeTab,
  onGoToContact,
  getTabBrokers,
} from './util/customerCareCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('CustomerCareCtrl', function CustomerCareCtrl(
      $scope,
      $uibModal,
      SweetAlert,
      NgTableParams,
      $window,
      $location,
      $state,
      $stateParams,
      toaster,
      cfpLoadingBar,
      $timeout,
      $rootScope,
      $filter,
      $localStorage,
      $document,
      customerCareService,
      customerCareInsuranceService,
      contactService,
      subscriptionService,
      commonFnService,
      currentUserService,
      stateService,
      mycrmLookupService,
      configService,
      customerCareTabsService,
    ) {
      $scope.isFirstLoading = true;
      $scope.customerCareTabs = CUSTOMER_CARE_TABS;
      $scope.activeTab = getActiveTabIndex({ $scope, $stateParams });
      $scope.isAnnualReviewAllowed = true;
      $scope.onTabClick = onTabClick({
        $scope,
        $state,
        stateService,
        $stateParams,
      });
      $scope.unlockingCustomerCare =
        configService.feature.unlockingCustomerCare;
      $scope.currentUserService = currentUserService;
      $rootScope.CustomerCareMainObj = {};
      $rootScope.CustomerCareMainObj.selectedTab = 'fixedRateExpires';

      $scope.customerCareAdviserList = [];

      $scope.serviceId = currentUserService.isAU
        ? SERVICE_ID.FIXED_RATE_EXPIRES
        : SERVICE_ID_NZ.FIXED_RATE_EXPIRES;
      $scope.getTabBrokers = getTabBrokers({
        $scope,
        mycrmLookupService,
        currentUserService,
        customerCareTabsService,
      });

      customerCareService.customerCareDateRange().then((data) => {
        if (!data) {
          return;
        }
        $scope.customerCareDateRange = data;
        const dateRange = data.map(dateRangeOptionBuilderForUI);
        $scope.customDateRange = [
          ...dateRange,
          {
            text: 'Custom',
            range: [moment(), moment()],
          },
        ];
        if ($scope.unlockingCustomerCare) {
          $scope.customerCareDateRange = $scope.customDateRange;
        }
      });

      checkAnnualReviews({
        $scope,
        apiService: customerCareService,
        currentUserService,
      });

      $scope.selectTab = function (selectedTab) {
        $timeout(() => {
          const el = $document.find(`#${selectedTab} .nav-link`);
          angular.element(el).triggerHandler('click');
        }, 0);
        onChangeTab({ $scope })(selectedTab);
      };

      $scope.userInfo = {};
      $scope.isAdviser = true;
      $scope.someBrokersSubscribed = false;
      $scope.isAssistantUnlocked = false;
      $scope.showLockScreen = false;
      $scope.autoMarketingMessage = SUBSCRIPTION_MESSAGES.NONE;

      const messageToDisplay = (orgSubscribedType, isSubscribed) => {
        $scope.autoMarketingMessage = isSubscribed
          ? SUBSCRIPTION_MESSAGES.NONE
          : '';
        $scope.isAdviser =
          $scope.userInfo.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER ||
          $scope.userInfo.AccessType === ACCESS_TYPE.ADVISER;

        if (orgSubscribedType === ORG_SUBSCRIPTION.SOME) {
          $scope.autoMarketingMessage = !$scope.isAdviser
            ? SUBSCRIPTION_MESSAGES.SOME
            : $scope.autoMarketingMessage;
          $scope.someBrokersSubscribed = true;
        } else if (orgSubscribedType === ORG_SUBSCRIPTION.ALL) {
          $scope.autoMarketingMessage = !$scope.isAdviser
            ? SUBSCRIPTION_MESSAGES.ALL
            : $scope.autoMarketingMessage;
          $scope.someBrokersSubscribed = false;
        }
        $scope.isAssistantUnlocked =
          !$scope.autoMarketingMessage ||
          (!$scope.isAdviser && $scope.someBrokersSubscribed);
        $scope.showLockScreen =
          ($scope.autoMarketingMessage && $scope.isAdviser) ||
          ($scope.autoMarketingMessage &&
            !$scope.isAdviser &&
            !$scope.someBrokersSubscribed);
        $scope.customerCareData = {
          isAssistantUnlocked: $scope.isAssistantUnlocked,
          showLockScreen: $scope.showLockScreen,
          autoMarketingMessage: $scope.autoMarketingMessage,
        };
        if (!$scope.showLockScreen || $scope.unlockingCustomerCare) {
          return $scope.getTabBrokers();
        }
        $scope.isFirstLoading = false;
      };

      annualReviewBannerUtil({
        $scope,
        apiService: customerCareInsuranceService,
        currentUserService,
      });

      $scope.checkTabView = () => {
        if (!objectLength($scope.autoMarketingSubscription)) {
          return;
        }
        const {
          subscribe_FixedRateExpiry: subscribeFixedRateExpires,
          subscribe_HappyBirthday: subscribeBirthdays,
          subscribe_PreApprovalExpiry: subscribePreApprovalExpiry,
          subscribe_StayInTouchAnnualReview: subscribeAnnualReview,
          subscribe_StayInTouchSettlement: subscribeSettlement,
          subscribe_InterestOnly: subscribeInterestOnly,
          organization_FixedRateExpiry: fixedRateExpires,
          organization_HappyBirthday: birthdays,
          organization_PreApprovalExpiry: preApprovalExpiry,
          organization_StayInTouchAnnualReview: annualReview,
          organization_StayInTouchSettlement: settlement,
          organization_InterestOnly: interestOnly,
        } = $scope.autoMarketingSubscription;

        switch ($scope.activeTab) {
          case SERVICE_TAB_INDEX.APPROVAL_EXPIRIES:
            $scope.serviceId = currentUserService.isAU
              ? SERVICE_ID.APPROVAL_EXPIRIES
              : SERVICE_ID_NZ.APPROVAL_EXPIRIES;
            messageToDisplay(preApprovalExpiry, subscribePreApprovalExpiry);
            break;
          case SERVICE_TAB_INDEX.BIRTHDAYS:
            $scope.serviceId = currentUserService.isAU
              ? SERVICE_ID.BIRTHDAYS
              : SERVICE_ID_NZ.BIRTHDAYS;
            messageToDisplay(birthdays, subscribeBirthdays);
            break;
          case SERVICE_TAB_INDEX.INTEREST_ONLY_EXPIRIES:
            $scope.serviceId = currentUserService.isAU
              ? SERVICE_ID.INTEREST_ONLY_EXPIRIES
              : SERVICE_ID_NZ.INTEREST_ONLY_EXPIRIES;
            messageToDisplay(interestOnly, subscribeInterestOnly);
            break;
          case SERVICE_TAB_INDEX.ANNUAL_REVIEWS:
            $scope.serviceId = currentUserService.isAU
              ? SERVICE_ID.ANNUAL_REVIEWS
              : SERVICE_ID_NZ.ANNUAL_REVIEWS;
            messageToDisplay(annualReview, subscribeAnnualReview);
            break;
          case SERVICE_TAB_INDEX.POST_SETTLEMENT:
            $scope.serviceId = currentUserService.isAU
              ? SERVICE_ID.POST_SETTLEMENT
              : SERVICE_ID_NZ.POST_SETTLEMENT;
            messageToDisplay(settlement, subscribeSettlement);
            break;
          default:
            $scope.serviceId = currentUserService.isAU
              ? SERVICE_ID.FIXED_RATE_EXPIRES
              : SERVICE_ID_NZ.FIXED_RATE_EXPIRES;
            messageToDisplay(fixedRateExpires, subscribeFixedRateExpires);
            break;
        }
      };

      $scope.getUserSubscription = () => {
        subscriptionService.marketingAutomation().then((response) => {
          if (!response) {
            return;
          }
          $scope.autoMarketingSubscription = response;
          $scope.checkTabView();
        });
      };

      $scope.getUserInfo = () => {
        contactService.getUserInfo().then((response) => {
          if (!response || !response.data) {
            return;
          }
          const { data } = response;
          $scope.userInfo = data;
          $scope.getUserSubscription();
        });
      };
      $scope.getUserInfo();

      $scope.selectTabUpdate = function (tab) {
        $rootScope.CustomerCareMainObj.selectedTab = tab;
        $scope.checkTabView();
      };

      $scope.gotoContact = (familyId, isEntity) => {
        onGoToContact({ $state, currentUserService, familyId, isEntity });
      };

      $scope.openVideoModal = () => {
        openVideoModal($uibModal, PAGE_SOURCE.CUSTOMER_CARE);
      };
    });
