import template from './cqpCpaSidebar.html';
import controller from './cqpCpaSidebarCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    id: '<', // either loanAppId or opportunityId
    isLoanApp: '@',
    onCloseAll: '&',
    applicants: '<',
    loanAdviserId: '<',
    onValidate: '&',
  },
};
