import angular from 'angular';
import onboardingCompletePreview from './onboardingCompletePreview';
import entityDetailsPreview from './entityDetailsPreview/entityDetailsPreview';
import asicAppointmentPreview from './asicAppointmentPreview/asicAppointmentPreview';
import individualDetailsPreview from './individualDetailsPreview/individualDetailsPreview';
import questionnairePreview from './questionnairePreview/questionnairePreview';
import requiredDocumentsPreview from './requiredDocumentsPreview/requiredDocumentsPreview';
import onboardingCommonPreview from './onboardingCommonPreview/onboardingCommonPreview';

import './style.scss';

export default angular
  .module('components.onboardingCompletePreview', [])
  .component('onboardingCommonPreview', onboardingCommonPreview)
  .component('entityDetailsPreview', entityDetailsPreview)
  .component('asicAppointmentPreview', asicAppointmentPreview)
  .component('individualDetailsPreview', individualDetailsPreview)
  .component('questionnairePreview', questionnairePreview)
  .component('requiredDocumentsPreview', requiredDocumentsPreview)
  .component('onboardingCompletePreview', onboardingCompletePreview).name;
