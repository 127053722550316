class RateService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Rate';
  }

  xeConvertCurrencyGet(data) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/XE/ConvertCurrencyGet`,
      data,
    );
  }

  xeSupportedCurrenciesGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/XE/SupportedCurrenciesGet`);
  }
}

export default RateService;
