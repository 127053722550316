import template from './yourStats.html';
import controller from './yourStatsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    parentToChildNotificationRegistration: '&',
  },
};
