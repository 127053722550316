import template from './npsSurvey.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    onNpsSurveyYes: '&',
    onNpsSurveyNo: '&',
    npsModel: '<',
  },
};
