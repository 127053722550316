import { toastSuccess } from 'Common/utilities/alert';
import { LOGO_FILE_TYPE_FORMAT } from 'Common/constants/fileType';
import { BRANDING_DEFAULT } from 'Common/constants/colors';
import { DOCUMENT_LOGO_KEY } from 'Common/constants/document';

class BrandingUploadsCtrl {
  constructor(customThemeService, corporateModelService) {
    'ngInject';

    this.customThemeService = customThemeService;
    this.corporateModelService = corporateModelService;
  }

  $onInit() {
    this.fileFormat = LOGO_FILE_TYPE_FORMAT;
    this.DOCUMENT_LOGO_KEY = DOCUMENT_LOGO_KEY;
    this.getUserBranding(false);
  }

  getUserBranding(displayWhileRetrieving = true) {
    this.isThemeLoaded = displayWhileRetrieving;
    this.corporateModelService
      .getAdviserOrganizationTheme(this.userAdviserOrgId)
      .then((data) => {
        this.branding = data && {
          ...data,
          adviserOrganizationId: this.userAdviserOrgId,
        };
        this.brandColor =
          (data && data.brandingColour) || BRANDING_DEFAULT.SIDEBAR_BG;
        this.isThemeLoaded = true;
      })
      .catch(() => {
        this.isThemeLoaded = true;
      });
  }

  saveBrandColor({ color }) {
    const params = { ...this.branding, brandingColour: color };
    this.corporateModelService.setAdviserOrganizationTheme(params).then(() => {
      this.branding.brandingColour = color;
      this.brandColor = color;
      this.autoUpdateNav && this.customThemeService.initGlobalCSS();
      this.getUserBranding();
      toastSuccess('Branding color successfully saved!');
    });
  }
}

export default BrandingUploadsCtrl;
