export const COMMON_LENDER = {
  ASB: 13,
  WESTPAC: 16,
};

export const NZ_LENDER = {
  WESTPAC: COMMON_LENDER.WESTPAC,
  ANZ: 14,
  ASB: COMMON_LENDER.ASB,
  BNZ: 24,
  COOP: 17,
  RESIMAC: 77,
  SBS: 53,
  SELECT: 103,
  SOVEREIGN: 1,
};

export const AU_LENDER = {
  COMMONWEALTH_BANK: 3624,
};
