import { httpInterceptor } from './interceptors/httpInterceptor';

const configHttpProvider = [
  '$httpProvider',
  'LEGACY_ANGULAR_HOST',
  'TECHNOLOGY',
  ($httpProvider, legacyAngularHost, technology) => {
    if (technology.isReact && legacyAngularHost.value) {
      const interceptor = ['$sce', 'LEGACY_ANGULAR_HOST', httpInterceptor];
      $httpProvider.interceptors.push(interceptor);
    }
  },
];
export default configHttpProvider;
