import template from './commissionCalendarModal.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    datePickerOptions: '<',
    preselectDate: '<',
  },
};
