import angular from 'angular';
import { isMobileNumberValid } from 'Common/utilities/mobile';
import { EMAIL_CTYPES } from 'Common/constants/emailTypes';
import {
  setContactEmailAddressByType,
  setContactPhoneByType,
  getClientEmailMobilePayload,
} from 'Common/utilities/contact';

export default class ClientEmailMobileInput {
  constructor(uiService, helloBookService, countryCodeService) {
    'ngInject';

    this.angular = angular;
    this.uiService = uiService;
    this.helloBookService = helloBookService;
    this.countryCodeService = countryCodeService;
  }

  $onInit() {
    this.isShowWarningPhone = true;
    this.isInternationalPhoneNumbers = this.uiService.isInternationalPhoneNumber;
    this.phoneCountryCode = this.countryCodeService.homePhoneCountryCode();
    this.selectedPhoneCountryCode = this.phoneCountryCode;
    this.workPhoneInputName = 'workPhoneInput';
    this.isMobileNumberValid = isMobileNumberValid;
    this.isSendToEsignDisabled = false;
    this.contactUpdated = false;
  }

  onSaveContact({ email, applicant }) {
    const newUserEmailValue = setContactEmailAddressByType({
      contact: applicant,
      email,
      emailType: EMAIL_CTYPES.CRM_EMAIL,
    });
    return this.saveClient(newUserEmailValue);
  }

  onSaveContactPhoneParam({ applicant, id, phone }) {
    const countryCode = this.countryCodeService.getAssignedCountryCode({
      isInternationalPhoneNumbers: this.isInternationalPhoneNumbers,
      selectorElement: this.angular.element(
        `#${this.workPhoneInputName}${id}Id`,
      ),
    }).iso2;

    const newUserPhoneValue = setContactPhoneByType({
      contact: applicant,
      phone,
      countryCode,
      phoneType: 'Mobile',
    });
    return this.saveClient(newUserPhoneValue);
  }

  saveClient(applicant) {
    const payload = getClientEmailMobilePayload(applicant);
    if (!payload) {
      return;
    }
    return this.helloBookService
      .setClient(payload)
      .then(() => this.onContactSaved({ succeeded: true }))
      .catch(() => this.onContactSaved({ succeeded: false }));
  }
}
