import angular from 'angular';
const dragEnter = (angularEvent) => {
  const event = angularEvent.originalEvent || angularEvent;
  event && event.preventDefault();
};
export const loadModule = () =>
  angular
    .module('app')
    .directive('preventDefaultOnFiledrag', function preventDefaultOnFiledrag() {
      return {
        restrict: 'A',
        link: (scope, elem) => {
          elem.bind('dragover', dragEnter);
          elem.bind('dragenter', dragEnter);
        },
      };
    });
