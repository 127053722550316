const OTHER = 'Other';

export const mapDepositSourceModel = ({
  model,
  fund,
  despositList,
  isEdit,
}) => {
  if (!isEdit) {
    return model;
  }
  const mergedModel = { ...model, ...fund };
  const { fundingParticulars, description } = mergedModel;
  const fundExist = despositList.find(
    (source) => source.Name === fundingParticulars,
  );
  return fundExist
    ? mergedModel
    : {
        ...mergedModel,
        fundingParticulars: OTHER,
        description: `${fundingParticulars}. ${description}`,
      };
};
