import template from './editableText.html';
import controller from './editableTextCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    text: '<',
    showToolTip: '<?',
    onTextSave: '&',
  },
};
