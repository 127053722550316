export const INCOME_FREQUENCY = {
  ANNUALLY: 1,
  MONTHLY: 12,
  FORTNIGHTLY: 26,
  WEEKLY: 52,
  DAILY: 365,
  'HOURLY/WEEK': 0,
  'HOURLY/DAY': 0,
};

export const INCOME_FREQUENCY_TEXT = {
  ANNUALLY: 'ANNUALLY',
  MONTHLY: 'MONTHLY',
  FORTNIGHTLY: 'FORTNIGHTLY',
  WEEKLY: 'WEEKLY',
  DAILY: 'DAILY',
  'HOURLY/WEEK': 'HOURLY/WEEK',
  'HOURLY/DAY': 'HOURLY/DAY',
};

// TODO : transfer to api
export const MIN_MAX_INCOME_TAX = {
  2020: {
    LOW_INCOME_OFFSET: {
      minThreshold: 37500,
      maxThreshold: 66667,
    },
  },
  2021: {
    LOW_INCOME_OFFSET: {
      minThreshold: 37500,
      maxThreshold: 66667,
    },
  },
};
export const INCOME_TAX = {
  2021: {
    medicareDeductionRate: 2,
    lowIncomeTaxOffset: {
      useBracket: true,
      ...MIN_MAX_INCOME_TAX[2021].LOW_INCOME_OFFSET,
      brackets: [
        {
          from: 0,
          to: 37500,
          reductionRate: 0,
          baseAmount: 700,
          ...MIN_MAX_INCOME_TAX[2021].LOW_INCOME_OFFSET,
        },
        {
          from: 37501,
          to: 45000,
          reductionRate: 5,
          baseAmount: 700,
          ...MIN_MAX_INCOME_TAX[2021].LOW_INCOME_OFFSET,
        },
        {
          from: 45001,
          to: 66667,
          reductionRate: 1.5,
          baseAmount: 325,
          ...MIN_MAX_INCOME_TAX[2021].LOW_INCOME_OFFSET,
        },
      ],
    },
    lowAndMiddleIncomeTaxOffset: {
      minThreshold: 37000,
      maxThreshold: 126000,
      minYearEffective: 2018,
      maxYearEffective: 2022,
      brackets: [
        { from: 0, to: 37000, multiplier: 0, baseAmount: 255 },
        { from: 37001, to: 48000, multiplier: +7.5, baseAmount: 255 },
        { from: 48001, to: 90000, multiplier: 0, baseAmount: 1080 },
        { from: 90001, to: 126000, multiplier: -3, baseAmount: 1080 },
      ],
    },
    taxBrackets: [
      { from: 0, to: 18200, multiplier: 0, baseTax: 0 },
      { from: 18201, to: 45000, multiplier: 19, baseTax: 0 },
      { from: 45001, to: 120000, multiplier: +32.5, baseTax: 5092 },
      { from: 120001, to: 180000, multiplier: +37, baseTax: 29467 },
      { from: 180001, to: 100000000000, multiplier: 45, baseTax: 51667 },
    ],
  },
  2020: {
    medicareDeductionRate: 2,
    lowIncomeTaxOffset: {
      useBracket: true,
      ...MIN_MAX_INCOME_TAX[2020].LOW_INCOME_OFFSET,
      brackets: [
        {
          from: 0,
          to: 37500,
          reductionRate: 0,
          baseAmount: 700,
          ...MIN_MAX_INCOME_TAX[2020].LOW_INCOME_OFFSET,
        },
        {
          from: 37501,
          to: 45000,
          reductionRate: 5,
          baseAmount: 700,
          ...MIN_MAX_INCOME_TAX[2020].LOW_INCOME_OFFSET,
        },
        {
          from: 45001,
          to: 66667,
          reductionRate: 1.5,
          baseAmount: 325,
          ...MIN_MAX_INCOME_TAX[2020].LOW_INCOME_OFFSET,
        },
      ],
    },
    lowAndMiddleIncomeTaxOffset: {
      minThreshold: 37000,
      maxThreshold: 126000,
      minYearEffective: 2018,
      maxYearEffective: 2022,
      brackets: [
        { from: 0, to: 37000, multiplier: 0, baseAmount: 255 },
        { from: 37001, to: 48000, multiplier: +7.5, baseAmount: 255 },
        { from: 48001, to: 90000, multiplier: 0, baseAmount: 1080 },
        { from: 90001, to: 126000, multiplier: -3, baseAmount: 1080 },
      ],
    },
    taxBrackets: [
      { from: 0, to: 18200, multiplier: 0, baseTax: 0 },
      { from: 18201, to: 45000, multiplier: 19, baseTax: 0 },
      { from: 45001, to: 120000, multiplier: +32.5, baseTax: 5092 },
      { from: 120001, to: 180000, multiplier: +37, baseTax: 29467 },
      { from: 180001, to: 100000000000, multiplier: 45, baseTax: 51667 },
    ],
  },
  2019: {
    medicareDeductionRate: 2,
    lowIncomeTaxOffset: {
      reductionRate: 1.5,
      baseAmount: 445,
      minThreshold: 37000,
      maxThreshold: 66667,
    },
    lowAndMiddleIncomeTaxOffset: {
      reductionRate: 1.5,
      baseAmount: 530,
      minThreshold: 37000,
      maxThreshold: 66667,
      minYearEffective: 2018,
      maxYearEffective: 2022,
      brackets: [
        { from: 0, to: 37000, multiplier: 0, baseAmount: 200 },
        { from: 37001, to: 47999, multiplier: 3, baseAmount: 200 },
        { from: 48000, to: 90000, multiplier: 0, baseAmount: 530 },
        { from: 90001, to: 125333, multiplier: -1.5, baseAmount: 530 },
      ],
    },
    taxBrackets: [
      { from: 0, to: 18200, multiplier: 0, baseTax: 0 },
      { from: 18201, to: 37000, multiplier: 19, baseTax: 0 },
      { from: 37001, to: 90000, multiplier: 32.5, baseTax: 3572 },
      { from: 90001, to: 180000, multiplier: 37, baseTax: 20797 },
      { from: 180001, to: 100000000000, multiplier: 45, baseTax: 54097 },
    ],
  },
  2018: {
    medicareDeductionRate: 2,
    lowIncomeTaxOffset: {
      reductionRate: 1.5,
      baseAmount: 445,
      minThreshold: 37000,
      maxThreshold: 66667,
    },
    taxBrackets: [
      { from: 0, to: 18200, multiplier: 0, baseTax: 0 },
      { from: 18201, to: 37000, multiplier: 19, baseTax: 0 },
      { from: 37001, to: 87000, multiplier: 32.5, baseTax: 3572 },
      { from: 87001, to: 180000, multiplier: 37, baseTax: 19822 },
      { from: 180001, to: 100000000000, multiplier: 45, baseTax: 54232 },
    ],
  },
};

export const YEAR_COUPLE = {
  TWENTY_ONE: '2020/2021',
  TWENTY: '2019/2020',
  NINETEEN: '2018/2019',
};

export const YEAR_TEXT = {
  [YEAR_COUPLE.TWENTY_ONE]: '2020',
  [YEAR_COUPLE.TWENTY]: '2019',
  [YEAR_COUPLE.NINETEEN]: '2018',
};

export const REPAYMENT_THRESHOLD_RATE = {
  2021: [
    { from: 0, to: 47014, rate: 0 },
    { from: 47014, to: 54281, rate: 1 },
    { from: 54282, to: 57538, rate: 2 },
    { from: 57539, to: 60991, rate: 2.5 },
    { from: 60992, to: 64650, rate: 3 },
    { from: 64651, to: 68529, rate: 3.5 },
    { from: 68530, to: 72641, rate: 4 },
    { from: 72642, to: 77000, rate: 4.5 },
    { from: 77001, to: 81620, rate: 5 },
    { from: 81621, to: 86518, rate: 5.5 },
    { from: 86519, to: 91709, rate: 6 },
    { from: 91710, to: 97212, rate: 6.5 },
    { from: 97213, to: 103044, rate: 7 },
    { from: 103045, to: 109226, rate: 7.5 },
    { from: 109227, to: 115678, rate: 8 },
    { from: 115679, to: 122728, rate: 8.5 },
    { from: 122729, to: 130091, rate: 9 },
    { from: 130092, to: 137897, rate: 9.5 },
    { from: 137898, to: 100000000000, rate: 10 },
  ],
  2020: [
    { from: 0, to: 46619, rate: 0 },
    { from: 46620, to: 53826, rate: 1 },
    { from: 53827, to: 57055, rate: 2 },
    { from: 57056, to: 60479, rate: 2.5 },
    { from: 60480, to: 64108, rate: 3 },
    { from: 64109, to: 67954, rate: 3.5 },
    { from: 67955, to: 72031, rate: 4 },
    { from: 72032, to: 76354, rate: 4.5 },
    { from: 76355, to: 80935, rate: 5 },
    { from: 80936, to: 85792, rate: 5.5 },
    { from: 85793, to: 90939, rate: 6 },
    { from: 90940, to: 96396, rate: 6.5 },
    { from: 96397, to: 102179, rate: 7 },
    { from: 102180, to: 108309, rate: 7.5 },
    { from: 108310, to: 114707, rate: 8 },
    { from: 114708, to: 121698, rate: 8.5 },
    { from: 121699, to: 128999, rate: 9 },
    { from: 129000, to: 136739, rate: 9.5 },
    { from: 136740, to: 100000000000, rate: 10 },
  ],
  2019: [
    { from: 0, to: 45880, rate: 0 },
    { from: 45881, to: 52973, rate: 1 },
    { from: 52974, to: 56151, rate: 2 },
    { from: 56152, to: 59521, rate: 2.5 },
    { from: 59522, to: 63092, rate: 3 },
    { from: 63093, to: 66877, rate: 3.5 },
    { from: 66878, to: 70890, rate: 4 },
    { from: 70891, to: 75144, rate: 4.5 },
    { from: 75145, to: 79652, rate: 5 },
    { from: 79653, to: 84432, rate: 5.5 },
    { from: 84433, to: 89498, rate: 6 },
    { from: 89499, to: 94868, rate: 6.5 },
    { from: 94869, to: 100560, rate: 7 },
    { from: 100561, to: 106593, rate: 7.5 },
    { from: 106594, to: 112989, rate: 8 },
    { from: 112990, to: 119769, rate: 8.5 },
    { from: 119770, to: 126955, rate: 9 },
    { from: 126956, to: 134572, rate: 9.5 },
    { from: 134573, to: 100000000000, rate: 10 },
  ],
  2018: [
    { from: 0, to: 51956, rate: 0 },
    { from: 51957, to: 57729, rate: 2 },
    { from: 57730, to: 64306, rate: 4 },
    { from: 64307, to: 70881, rate: 4.5 },
    { from: 70882, to: 74607, rate: 5 },
    { from: 74608, to: 80197, rate: 5.5 },
    { from: 80198, to: 86855, rate: 6 },
    { from: 86856, to: 91425, rate: 6.5 },
    { from: 91426, to: 100613, rate: 7 },
    { from: 100614, to: 107213, rate: 7.5 },
    { from: 107214, to: 100000000000, rate: 8 },
  ],
};
