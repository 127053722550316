import _ from 'lodash';
import {
  DEFAULT_ASSETS,
  FINANCIAL_TYPE_ID,
} from 'Common/constants/financialAssets';
import {
  FINANCIAL_TYPE,
  FINANCIAL_FREQUENCY,
} from 'Common/constants/financialFrequency';
import { CURRENCY_CODE } from 'Common/constants/currency';
import { appendEmptySelect } from 'Common/utilities/options';
import { getContactFullname } from 'Common/utilities/contact';
import { checkIfRemoveIncome } from 'Common/utilities/income';
import { parseIntValue, parseToInt10 } from 'Common/utilities/parse';
import { addressLookup } from 'Common/mappers/address';
import {
  findAndReplace,
  mergeSubArray,
  oneLevelDeepCopy,
} from 'Common/utilities/array';
import { objectLength } from 'Common/utilities/objectValidation';
import { toastError } from 'Common/utilities/alert';

export const mapOwnersList = (owner) => {
  if (!owner.BorrowerID) {
    owner.ticked = false;
  }
  return owner;
};

export const addPreferedNameAndTickall = (owner) => {
  owner.PreferedName = `${owner.FirstName || ''} ${owner.LastName || ''}`;
  owner.ticked = true;
  return owner;
};
export const untickMap = (applicant) => {
  applicant.ticked = false;
  return applicant;
};
export const excludeApplicantsAndJoint = (applicants) => {
  if (!applicants) {
    return [];
  }

  return applicants.filter(
    (owner) => owner.IsGuarantor && owner.BorrowerID > 0,
  );
};
export const excludeGuarantorAndJoint = (applicants) => {
  if (!applicants) {
    return [];
  }

  return applicants.filter(
    (owner) => !owner.IsGuarantor && owner.BorrowerID > 0,
  );
};
export const evaluateTickedValuesOnLoad = (listWithJoint) => {
  if (!listWithJoint) {
    return listWithJoint;
  }
  const joint = listWithJoint.find((row) => row.BorrowerID === 0) || {};
  const applicants = excludeGuarantorAndJoint(listWithJoint);
  const guarantors = excludeApplicantsAndJoint(listWithJoint);
  const isAllApplicantsTicked = applicants.every(
    (applicant) => applicant.ticked,
  );
  if (objectLength(joint)) {
    if (isAllApplicantsTicked) {
      joint.ticked = true;
      applicants.map(untickMap);
    } else {
      joint.ticked = false;
    }
  }

  return [joint, ...guarantors, ...applicants];
};
export const isMortgageOwnersUtil = (assetsSet, mortgageIndex) => {
  return _.get(assetsSet, `MortgageLiability[${mortgageIndex}]`);
};
export const assignOwners = (scope) => {
  if (!scope) {
    return;
  }
  scope.ownersList.map(mapOwnersList);
  scope.ownersListSelected =
    scope.AssetsSet.Borrowers.map(addPreferedNameAndTickall) || [];
  const mergedOwnersList = mergeSubArray(
    {
      mainArray: scope.ownersList,
      subArray: scope.ownersListSelected,
      propToCompare: 'BorrowerID',
    },
    true,
  );
  scope.ownersList = evaluateTickedValuesOnLoad(mergedOwnersList);
  scope.AssetsSet.MortgageLiability.forEach((mortgage, index) => {
    const ownersList = scope.ownersList.reduce(
      (accum, owner) => accum.concat(owner.BorrowerID),
      [],
    );
    const borrowers = mortgage.Borrowers.filter((borrower) =>
      ownersList.includes(borrower.BorrowerID),
    );
    const mergedMortgageOwners = mergeSubArray(
      {
        mainArray: oneLevelDeepCopy(scope.ownersList).map(untickMap),
        subArray: borrowers.map(addPreferedNameAndTickall),
        propToCompare: 'BorrowerID',
      },
      true,
    );
    const mortgageLiability = isMortgageOwnersUtil(scope.AssetsSet, index);
    if (mortgageLiability) {
      mortgageLiability.dropdownOwnersList = evaluateTickedValuesOnLoad(
        mergedMortgageOwners,
      );
    }
  });
};

export const populatePropertyType = ({
  $scope,
  financeService,
  currentUserService,
}) => (apiService, propertyType, fieldName = 'default') => {
  if (!apiService.getPropertyType) {
    return;
  }
  if (!propertyType) {
    return;
  }
  const defaultReaValuesAU = financeService.getDefaultREAAU();
  apiService.getPropertyType(propertyType).then((response) => {
    if (!response || !response.data) {
      return;
    }

    const types = [...response.data].map((o) => {
      o.Name = o.AddressPropertyType;
      o.Value = o.AddressPropertyTypeID;
      return o;
    });
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const moveToplistproperties = (propertylList) => {
      const topPropertyList = [
        DEFAULT_ASSETS.STD_RESIDENTIAL,
        DEFAULT_ASSETS.CROSS_LEASE,
        DEFAULT_ASSETS.RESIDENTIAL_LEASEHOLD,
        DEFAULT_ASSETS.STD_APARTMENT,
      ];
      const resultList = propertylList.filter((f) =>
        topPropertyList.includes(f.Value),
      );
      const copyOriginal = propertylList.filter(
        (f) => !topPropertyList.includes(f.Value),
      );
      const sortPurpose = (a, b) => {
        return (
          topPropertyList.indexOf(a.Value) - topPropertyList.indexOf(b.Value)
        );
      };
      return [...resultList.sort(sortPurpose), ...copyOriginal];
    };
    const propertyLists = currentUserService.isNZ
      ? moveToplistproperties(types)
      : types;

    $scope.ListPropertyType = appendEmptySelect(propertyLists, {
      Name: 'Please select',
      Value: null,
    });

    $scope.AssetsSet.PropertyTypeId = null;

    if (
      $scope.ListPropertyType.find((o) => {
        return (
          parseInt(o.AddressPropertyTypeID, 10) ===
          parseInt($scope.AssetsSet.PropertyTypeId_original, 10)
        );
      })
    ) {
      $scope.AssetsSet.PropertyTypeId =
        $scope.AssetsSet.PropertyTypeId_original;
    }

    if (
      propertyType === defaultReaValuesAU.zoning &&
      currentUserService.isAU &&
      !$scope.AssetsSet.PropertyTypeId
    ) {
      $scope.setDefaultValuesMethod('propertyTypeId');
    }

    if (fieldName === 'all') {
      $scope.AssetsSet.ZoningId = DEFAULT_ASSETS.RESIDENTIALS;
      $scope.AssetsSet.PropertyTypeId = DEFAULT_ASSETS.STD_RESIDENTIAL;
      $scope.AssetsSet.PrimaryPurposeId = DEFAULT_ASSETS.OWNER_OCCUPIED;
    }
  });
};

export const fileterAssetList = (assetList, familyId) => {
  return (
    assetList.find((obj) => {
      return obj.FamilyId === parseInt(familyId, 10);
    }) || { overview: { FinancialInfo: [] } }
  );
};

export const setDefaultValues = ({
  $scope,
  optionsService,
  financeService,
  isNZ,
}) => (fieldName) => {
  const defaultReaValuesAU = financeService.getDefaultREAAU();
  const { primaryPurposeId, zoning, propertyTypeId } = defaultReaValuesAU;

  switch (fieldName) {
    case 'all':
      if (isNZ) {
        $scope.populatePropertyTypeCall(
          optionsService,
          DEFAULT_ASSETS.RESIDENTIALS,
          fieldName,
        );
      } else {
        $scope.AssetsSet.ZoningId = parseToInt10(zoning);
        $scope.AssetsSet.PrimaryPurposeId = parseToInt10(primaryPurposeId);
        $scope.populatePropertyTypeCall(
          optionsService,
          $scope.AssetsSet.ZoningId,
        );
      }
      break;
    case 'zoning':
      $scope.AssetsSet.ZoningId = parseToInt10(zoning);
      break;
    case 'primaryPurposeId':
      $scope.AssetsSet.PrimaryPurposeId = parseToInt10(primaryPurposeId);
      break;
    case 'propertyTypeId':
      $scope.AssetsSet.PropertyTypeId = parseToInt10(propertyTypeId);
      break;
    default:
      break;
  }
};

export const getOwnerPreferredName = (client) => {
  return client.IsEntity ? client.FirstName : getContactFullname(client);
};

export const getOwnerTickedValue = ({ selectedOwners, clients, client }) => {
  return selectedOwners.length <= clients.length
    ? !!selectedOwners.find((o) => o.BorrowerID === client.BorrowerID)
    : client.BorrowerID === -1;
};
export const mapClientsData = (client) => {
  if (!client) {
    return client;
  }
  client.PreferedName = getOwnerPreferredName(client);
  return client;
};

export const addScenarioOwnerList = ({
  jointOption,
  mappedClientsData,
  applicantsOnly,
  guarantorsOnly,
}) => {
  if (applicantsOnly.length === 1) {
    const tickedFirstApplicant = [{ ...applicantsOnly[0], ticked: true }];
    return [...guarantorsOnly, ...tickedFirstApplicant];
  }
  return [jointOption, ...mappedClientsData];
};

export const editScenarioOwnerList = ({
  jointOption,
  mappedClientsData,
  ownersListSelected,
  applicantsOnly,
}) => {
  jointOption.ticked = false;
  const owners = mappedClientsData.map((client) => {
    return {
      ...client,
      ticked: getOwnerTickedValue({
        selectedOwners: ownersListSelected,
        clients: mappedClientsData,
        client,
      }),
    };
  });
  return applicantsOnly.length > 1
    ? evaluateTickedValuesOnLoad([jointOption, ...owners])
    : owners;
};

export const getAssetClient = ({
  $scope,
  loanScenarioService,
  currentUserService = {},
}) => (familyId, borrowers) => {
  if (!familyId) {
    return Promise.resolve();
  }
  const loanAppId = currentUserService.isNZ ? 0 : $scope.loanAppId;

  return loanScenarioService
    .AssetClientGet(familyId, loanAppId)
    .then(({ data }) => {
      const jointOption = {
        Percentage: 0,
        BorrowerID: 0,
        FirstName: 'All',
        LastName: 'Clients',
        PreferedName: 'Joint',
        ticked: true,
        disabled: true,
      };

      $scope.ownersListSelected = borrowers || [];
      const selectedCount = $scope.ownersListSelected.length;
      const clients = data || [];
      const applicantsOnly = excludeGuarantorAndJoint(clients);
      const guarantorsOnly = excludeApplicantsAndJoint(clients);
      const mappedClientsData = [...guarantorsOnly, ...applicantsOnly].map(
        mapClientsData,
      );

      if (!selectedCount) {
        $scope.ownersList = addScenarioOwnerList({
          mappedClientsData,
          jointOption,
          applicantsOnly,
          guarantorsOnly,
        });
        return;
      }
      $scope.ownersList = editScenarioOwnerList({
        jointOption,
        mappedClientsData,
        ownersListSelected: $scope.ownersListSelected,
        applicantsOnly,
      });
    });
};

export const onSelectAll = ({ $scope }) => () => {
  const findJoint = $scope.ownersList.find((obj) => obj.BorrowerID === 0);
  if (findJoint) {
    $scope.ownersList = $scope.ownersList.map((obj) => {
      obj.ticked = obj.BorrowerID === 0;
      return obj;
    });
    return;
  }
  $scope.ownersList = $scope.ownersList.reduce((initial, owner) => {
    if (!owner || typeof owner.BorrowerID === 'undefined') {
      return initial;
    }
    return [...initial, { ...owner, ticked: owner.BorrowerID <= -1 }];
  }, []);
};

export const evaluateTickedValuesOnClick = (owners) => {
  if (!owners) {
    return;
  }
  const applicants = excludeGuarantorAndJoint(owners);
  const guarantor = excludeApplicantsAndJoint(owners);
  const tickedOwner = owners.filter((owner) => owner.ticked);
  const clickedByUserObject = owners.find((owner) => owner.clickedByUser);
  const tickedApplicants = excludeGuarantorAndJoint(tickedOwner);

  const joint = _.get(owners, '[0].BorrowerID') === 0 ? owners[0] : {};
  const isApplicantsTickSameLength =
    tickedApplicants.length === applicants.length;
  const jointNotTickedAndAllApplicantsTicked =
    joint.ticked === false &&
    applicants.length > 1 &&
    isApplicantsTickSameLength;

  if (joint.ticked) {
    const atLeastOneApplicantTicked =
      objectLength(tickedApplicants) && !isApplicantsTickSameLength;
    if (isApplicantsTickSameLength || clickedByUserObject.BorrowerID === 0) {
      tickedApplicants.map(untickMap);
    } else if (atLeastOneApplicantTicked) {
      joint.ticked = false;
    }
  } else if (jointNotTickedAndAllApplicantsTicked) {
    joint.ticked = true;
    tickedApplicants.map(untickMap);
  }

  const mergedApplicants = mergeSubArray(
    {
      mainArray: applicants,
      subArray: tickedApplicants,
      propToCompare: 'BorrowerID',
    },
    true,
  );
  const evaluatedOwnersList = [
    ...(objectLength(joint) ? [joint] : []),
    ...guarantor,
    ...mergedApplicants,
  ];
  const { clickedByUser, ...removedClickedByUserProp } = clickedByUserObject;
  return findAndReplace({
    list: evaluatedOwnersList,
    objectToSwap: removedClickedByUserProp,
    searchProp: 'BorrowerID',
    searchValue: removedClickedByUserProp.BorrowerID,
  });
};

export const getOwnersListOnItemSelect = ({ ownersList }) => (data) => {
  if (!ownersList || !data) {
    return;
  }
  if (ownersList.every((owner) => !owner.ticked)) {
    return findAndReplace({
      list: ownersList,
      objectToSwap: { ...data, ticked: true },
      searchProp: 'BorrowerID',
      searchValue: data.BorrowerID,
    });
  }
  const mergeOwnersListAndData = findAndReplace({
    list: ownersList,
    objectToSwap: { ...data, clickedByUser: true },
    searchProp: 'BorrowerID',
    searchValue: data.BorrowerID,
  });
  return evaluateTickedValuesOnClick(mergeOwnersListAndData);
};

export const hasPassedValidation = ({ $scope, modalType }) => {
  if (!$scope) {
    return;
  }
  const isEmptyAssetType = $scope.AssetsSet.Type === '';
  const isNumber = !Number.isNaN(parseInt($scope.AssetsSet.Value, 10));
  const isUndefinedModal = typeof modalType === 'undefined';
  const failedValidation = (isEmptyAssetType || !isNumber) && isUndefinedModal;
  if (failedValidation) {
    $scope.isInvalidAssetType = isEmptyAssetType;
    $scope.isInvalidAssetAmount = !isNumber;
    $scope.formValidation = true;
  }
  return !failedValidation;
};
export const evaluateOwnersListOnSave = (ownersList) => {
  if (!ownersList) {
    return;
  }
  const ownersListCopy = [...ownersList];
  let evaluatedOwnersList = [];
  const joint =
    _.get(ownersListCopy, '[0].BorrowerID') === 0 ? ownersListCopy[0] : {};

  if (joint.ticked) {
    ownersList.forEach((owner) => {
      const conditionsToPushList =
        (!owner.IsGuarantor || owner.ticked) && owner.BorrowerID !== 0;
      if (conditionsToPushList) {
        evaluatedOwnersList.push(owner);
      }
    });
  } else {
    evaluatedOwnersList = ownersListCopy.filter((owner) => owner.ticked);
  }

  return evaluatedOwnersList;
};
export const getBorrowers = (ownersList) => {
  const borrowerList = ownersList || [];
  const ownersListSelected = evaluateOwnersListOnSave(borrowerList);

  return ownersListSelected.reduce((acc, cur) => {
    const details = {
      Percentage: 0,
      BorrowerID: cur.BorrowerID,
      IsEntity: cur.IsEntity,
    };
    return cur.BorrowerID ? [...acc, details] : acc;
  }, []);
};

export const setAssetData = ({ $scope }) => {
  const { Type, Value, RentalIncome, MortgageLiability } = $scope.AssetsSet;
  $scope.AssetsSet.TypeId = Type.Value;
  $scope.AssetsSet.Type = Type.Desc;
  $scope.AssetsSet.Amount = Value;
  $scope.AssetsSet.Year = parseToInt10($scope.AssetsSet.Year);

  const hasRentalIncome = !!(RentalIncome && RentalIncome.length);
  $scope.AssetsSet.IsRentalIncome = hasRentalIncome;
  if (hasRentalIncome) {
    const addressDesc = $scope.AssetsSet.FullAddress.formatted_address;
    $scope.AssetsSet.RentalIncome = RentalIncome.map((rental) => {
      return { ...rental, Description: addressDesc };
    });
  }
  $scope.AssetsSet.IsExistingMortgages = !!(
    MortgageLiability && MortgageLiability.length
  );

  $scope.AssetsSet.FamilyId = _.get(
    $scope.financialsSharedService,
    'selectedFamily.FamilyId',
    $scope.familyId,
  ).toString();
};

const setAssetDetails = ({ assetsSet, loanScenarioService, callBack }) => {
  loanScenarioService
    .AssetDetailsSet(assetsSet)
    .then(() => {
      if (typeof callBack === 'function') {
        callBack();
      }
    })
    .catch(toastError);
};

export const saveAssetAddress = ({
  $scope,
  dashboardService,
  addressLookupService,
  assetDetails,
}) => {
  if (!$scope.isAddressOverhaulEnabled) {
    return dashboardService.addressDetailsSet(assetDetails.FullAddress);
  }
  return addressLookupService
    .convertToAddressFormat(assetDetails.FullAddress)
    .then((address) => {
      assetDetails.FullAddress = address;
      return dashboardService.addressDetailsSet(address);
    })
    .catch(toastError);
};

const saveAssetDetails = ({
  $scope,
  loanScenarioService,
  addressLookupService,
  dashboardService,
  loanAppSharedData,
  isModal,
  callBack,
}) => {
  const assetsSet = { ...$scope.AssetsSet };
  if (isModal && $scope.AssetsSet.FullAddress) {
    const getType = $scope.assetTypes.find(
      (type) => type.Value === parseToInt10($scope.AssetsSet.TypeId),
    );

    if (getType && getType.FormGroup === FINANCIAL_TYPE.REAL_ESTATE_ASSET) {
      $scope.AssetsSet.Address = {};
      saveAssetAddress({
        $scope,
        dashboardService,
        addressLookupService,
        assetDetails: $scope.AssetsSet,
      })
        .then(({ data }) => {
          $scope.AssetsSet.FullAddress.AddressID = data;
          $scope.AssetsSet.Address = { ...$scope.AssetsSet.FullAddress };
          return loanScenarioService.AssetDetailsSet($scope.AssetsSet);
        })
        .then(() => {
          $scope.financialsSharedService.IncomeInfoGet();
          $scope.financialsSharedService.LiabilityInfoGet();
          loanAppSharedData.REFRESH_FUNDING_REQUIRED_SECTION = { status: true };
          if (typeof callBack === 'function') {
            callBack();
          }
        })
        .catch(toastError);
    } else {
      setAssetDetails({ assetsSet, loanScenarioService, callBack });
    }
  } else {
    setAssetDetails({ assetsSet, loanScenarioService, callBack });
  }
};

export const mapMortgageBorrowers = (mortgage) => {
  const { dropdownOwnersList, RepaymentFrequencyId } = mortgage;
  mortgage.Borrowers = evaluateOwnersListOnSave(dropdownOwnersList);
  mortgage.FrequencyID = RepaymentFrequencyId;
  return mortgage;
};
export const addAsset = ({
  $scope,
  currentUserService,
  loanScenarioService,
  dashboardService,
  loanAppSharedData,
  addressLookupService,
}) => (isModal, modalType, callBack) => {
  const removeIncomeObject = {
    incomeList: $scope.AssetsSet.RentalIncome,
    primaryPurposeId: $scope.AssetsSet.PrimaryPurposeId,
    loanAppId: $scope.loanAppId,
  };
  const removeIncomeParam = {
    object: removeIncomeObject,
    isNZ: currentUserService.isNZ,
    incomeInfoDeleteFunction: loanScenarioService.IncomeInfoDelete,
  };
  checkIfRemoveIncome(removeIncomeParam);

  if (!hasPassedValidation({ $scope, modalType })) {
    return;
  }
  $scope.hideAssetForm();

  $scope.AssetsSet.Borrowers = getBorrowers($scope.ownersList);
  $scope.AssetsSet.MortgageLiability.map(mapMortgageBorrowers);
  setAssetData({ $scope });
  saveAssetDetails({
    $scope,
    loanScenarioService,
    addressLookupService,
    dashboardService,
    loanAppSharedData,
    isModal,
    callBack,
  });
};

const getCurrencyCodeId = (isAU) => {
  return isAU ? CURRENCY_CODE.AU : CURRENCY_CODE.NZ;
};

export const getNewRentalIncomeModel = ({ $scope, currentUserService }) => (
  index,
) => {
  return {
    IsEvidenceOfTenancy: true,
    Id: 0,
    LoanScenarioId: $scope.loanAppId,
    FamilyId: $scope.familyId,
    FinancialId: 0,
    Type: '',
    Description: '',
    Amount: null,
    CurrencyCode: currentUserService.brokerRegionalization.code,
    Category: '',
    CurrencyCodeId: getCurrencyCodeId(currentUserService.isAU),
    Borrowers: [],
    FrequencyID: FINANCIAL_FREQUENCY.WEEKLY,
    FrequencyName: '',
    Provider: '',
    IncomeSubTypeId: 1,
    RentalVerificationID: '',
    index,
  };
};

export const getNewMortgageLiabilityModel = ({
  $scope,
  currentUserService,
  defaultMortgageValuesAU,
}) => (index) => {
  const defaultLoanTerm = 30;
  const defaultPrincipalInterest = 2;
  const { mortgagePriorityId, principalInterest } = defaultMortgageValuesAU;
  return {
    IsRefinance: false,
    Ownership: [],
    AccountName: '',
    AccountNumber: '',
    BSB: '',
    Limit: null,
    CreditorId: null,
    InterestTaxDeductible: null,
    RepaymentFrequencyId: FINANCIAL_FREQUENCY.MONTHLY,
    Repayments: null,
    InterestRate: null,
    IsAddToExpenses: true,
    MortgagePriorityId: mortgagePriorityId || null,
    Expense: {},
    Id: 0,
    LoanScenarioId: $scope.loanAppId,
    FamilyId: $scope.familyId,
    FinancialId: 0,
    Type: '',
    Description: '',
    Amount: null,
    CurrencyCode: currentUserService.brokerRegionalization.code,
    Category: '',
    CurrencyCodeId: getCurrencyCodeId(currentUserService.isAU),
    Borrowers: [],
    FrequencyID: 0,
    FrequencyName: '',
    Provider: '',
    LoanTerm: defaultMortgageValuesAU.loanTerm || defaultLoanTerm,
    PrincipalInterest: principalInterest || defaultPrincipalInterest,
    index,
    isOpenRepaymentDropdown: false,
    dropdownOwnersList: oneLevelDeepCopy($scope.ownersList),
  };
};

export const getNewAssetModel = ({ $scope, currentUserService }) => (
  isSecurity,
) => {
  return {
    AssetSubtypeId: 1,
    ValueBasisId: 1,
    Value: null,
    AccountName: '',
    AccountNumber: '',
    BSB: '',
    Make: '',
    Year: 0,
    PrimaryPurposeId: null,
    PropertyType: '',
    Zoning: '',
    PropertyTypeId: 0,
    ZoningId: null,
    IsRentalIncome: false,
    IsExistingMortgages: false,
    IsAccountDetailsKnown: false,
    IsSecurity: !!isSecurity,
    Address: {
      IsMailing: true,
      AddressID: '0',
      Type: '',
      TypeId: '',
      formatted_address: '',
      AddressValuationExtendedDetailsId: 0,
    },
    RentalIncome: [],
    MortgageLiability: [],
    Id: 0,
    LoanScenarioId: $scope.loanAppId,
    FamilyId: $scope.familyId,
    FinancialId: 0,
    Type: '',
    TypeId: 0,
    Description: '',
    CurrencyCode: currentUserService.brokerRegionalization.code,
    XECountryCode: currentUserService.isAU ? 'au' : 'nz',
    Category: '',
    CurrencyCodeId: getCurrencyCodeId(currentUserService.isAU),
    Borrowers: [],
    Provider: '',
    ShowCurrencyConversion: false,
  };
};

export const getOptionsList = ({
  $scope,
  optionsService,
  currentUserService,
  financeService,
  contactService,
}) => (isFull, lenderPlace = 1) => {
  optionsService.AssetValueBasisGet().then(({ data }) => {
    if (!data || !data.length) {
      return;
    }
    $scope.ValueBasisList = parseIntValue(data);
    $scope.AssetsSet.ValueBasisId = $scope.ValueBasisList[0].Value.toString();
  });

  if (!isFull) {
    return;
  }
  const isAU = currentUserService.isAU;

  optionsService.getZoningType().then(({ data }) => {
    const ZoningList = parseIntValue(data);
    $scope.ZoningList = [{ Name: 'Please select', Value: null }, ...ZoningList];
    $scope.AssetsSet.ZoningId = null;
    if (isAU) {
      $scope.setDefaultValuesMethod('zoning');
    }
  });

  $scope.ListPropertyType = [{ Name: 'Please select Zoning first', Value: '' }];
  $scope.AssetsSet.PropertyTypeId = '';

  optionsService.getPrimaryPurposeType().then(({ data }) => {
    $scope.LoanPurposeList = parseIntValue(data);
    if (isAU) {
      $scope.setDefaultValuesMethod('primaryPurposeId');
    }
  });

  optionsService.MortgagePriorityGet().then(({ data }) => {
    $scope.MortgagePriorityList = parseIntValue(data);
  });

  optionsService.countryListGet().then(({ data }) => {
    $scope.countryListGet = data;
  });

  optionsService.AssetTypeGet().then(({ data }) => {
    $scope.assetTypes = financeService.mapAssetType(data);
  });

  contactService
    .lenderListNoPanelFilterGet(undefined, lenderPlace)
    .then(({ data }) => {
      $scope.CreditorList = data;
    });
};

export const getOptionsOnInit = ({
  $scope,
  contactModelService,
  optionsService,
  configService,
}) => {
  const getIncomeTypeEndpoint = configService.feature.loanAppCaching
    ? 'getSubIncomeTypeCache'
    : 'getSubIncomeType';
  contactModelService[getIncomeTypeEndpoint]().then((response) => {
    if (!response) {
      return;
    }
    $scope.incomeSubTypeSample = response;
  });

  $scope.listRepaymentFrequency = [];
  optionsService.FinancialFrequency(false).then(({ data }) => {
    if (!data || !data.length) {
      return;
    }
    $scope.listRepaymentFrequency = data.map((item) => {
      return { Desc: item.Name, Value: parseToInt10(item.Value) };
    });
  });
};

export const getOptionsObj = ({ $scope }) => {
  const {
    ValueBasisList,
    ZoningList,
    ListPropertyType,
    LoanPurposeList,
    MortgagePriorityList,
    countryListGet,
    assetTypes,
    CreditorList,
    incomeSubTypeSample,
    listRepaymentFrequency,
    ownersList,
    ownersListSelected,
  } = $scope;

  return {
    ValueBasisList,
    ZoningList,
    ListPropertyType,
    LoanPurposeList,
    MortgagePriorityList,
    countryListGet,
    assetTypes,
    CreditorList,
    incomeSubTypeSample,
    listRepaymentFrequency,
    ownersList,
    ownersListSelected,
  };
};

export const setOptionsList = ({ $scope, optionsObj }) => {
  const {
    ValueBasisList,
    ZoningList,
    ListPropertyType,
    LoanPurposeList,
    MortgagePriorityList,
    countryListGet,
    assetTypes,
    CreditorList,
    incomeSubTypeSample,
    listRepaymentFrequency,
    ownersList,
    ownersListSelected,
  } = optionsObj;

  $scope.ValueBasisList = ValueBasisList;
  $scope.ZoningList = ZoningList;
  $scope.ListPropertyType = ListPropertyType;
  $scope.LoanPurposeList = LoanPurposeList;
  $scope.MortgagePriorityList = MortgagePriorityList;
  $scope.countryListGet = countryListGet;
  $scope.assetTypes = assetTypes;
  $scope.CreditorList = CreditorList;
  $scope.incomeSubTypeSample = incomeSubTypeSample;
  $scope.listRepaymentFrequency = listRepaymentFrequency;
  $scope.ownersList = ownersList;
  $scope.ownersListSelected = ownersListSelected;
};

export const getLocalLang = () => {
  return {
    selectAll: '<span>Select All</span>',
    selectNone: '<span>Deselect All</span>',
    reset: "<i class='fa fa-refresh'></i>",
    search: 'Search',
    nothingSelected: '',
  };
};

export const searchCreditorInstitution = ({ $scope }) => () => {
  $scope.AssetsSet.InstitutionID = 0;
  $scope.toggleSearchCreditor(true);
  $scope.listLenderSearch = $scope.financialsSharedService.filterLender(
    $scope.CreditorList,
    $scope.AssetsSet.Institution,
  );
};

export const pickedCreditorLookup = ({ $scope }) => (lender) => {
  $scope.toggleSearchCreditor(false);
  $scope.AssetsSet.Institution = lender.ProviderName;
  $scope.AssetsSet.InstitutionID = lender.ProviderId;
};

export const checkCreditorsList = ({
  $scope,
  contactService,
  lenderPlace = 1,
}) => {
  if ($scope.CreditorList) {
    return;
  }
  contactService
    .lenderListNoPanelFilterGet(undefined, lenderPlace)
    .then(({ data }) => {
      $scope.CreditorList = data;
    });
};

export const initializeAddressLookup = ({ $scope, configService }) => {
  $scope.isAddressOverhaulEnabled = configService.feature.addressOverhaul;
  if (!$scope.isAddressOverhaulEnabled) {
    return;
  }
  if (
    $scope.AssetsSet.FullAddress &&
    $scope.AssetsSet.FullAddress.formatted_address
  ) {
    $scope.AssetsSet.FullAddress = {
      ...addressLookup($scope.AssetsSet.FullAddress),
      displayDetails: true,
    };
    $scope.isExistingAddress = !!_.get(
      $scope,
      'AssetsSet.Address.AddressID',
      0,
    );
    $scope.isAddressRequired =
      _.get($scope, 'AssetsSet.TypeId', 0) ===
      FINANCIAL_TYPE_ID.REAL_ESTATE_ASSET;
  }
  $scope.handleUpdateAddress = ({ address }) => {
    $scope.AssetsSet.FullAddress = address;
  };
};
