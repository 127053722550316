import template from './guaranteePledgeLimit.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    pledgeValueType: '<',
    onUpdateSecurity: '&',
    onUpdatePledgeValues: '&',
  },
};
