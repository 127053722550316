export const SOURCE_PLATFORM = {
  MY_CRM: {
    ID: 0,
    DESCRIPTION: 'MyCRM',
    DISPLAY_TEXT: 'MyCRM',
  },
  CONSUMER_APP: {
    ID: 1,
    DESCRIPTION: 'ConsumerApp',
    DISPLAY_TEXT: 'Fact Find',
  },
  GOAL_SETTER_APP: {
    ID: 2,
    DESCRIPTION: 'GoalSetterApp',
    DISPLAY_TEXT: 'Goal Setter',
  },
};
