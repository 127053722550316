import angular from 'angular';
import mergeContact from './mergeContact';
import clientInsurance from './clientInsurance';
import businessPlanningDashboard from './businessPlanningDashboard';
import insuranceAppWorkbench from './insuranceAppWorkbench';
import insuranceCustomerCare from './insuranceCustomerCare';
import incomeCalculator from './incomeCalculator';
import referral from './referral';
import insuranceSummaryOfCover from './insuranceSummaryOfCover';
import helloBook from './helloBook';
import dashboardWidget from './dashboardWidget';
import insuranceFireAndGeneral from './insuranceFireAndGeneral';
import assetFinanceWidgets from './assetFinanceWidgets';
import loanAppSections from './loanAppSections';
import loanInformationFields from './loanInformationFields';
import insuranceQuoteOpportunities from './insuranceQuoteOpportunities';
import blueStoneSubmission from './blueStoneSubmission';
import creditHistoryImproved from './creditHistoryImproved';
import customerCareTab from './customerCareTab';
import importantDocuments from './importantDocuments';
import lendingChooseProducts from './lendingChooseProducts';
import linkGmailSync from './linkGmailSync';
import addressLookupModal from './addressLookupModal';
import noteType from './noteType';
import mycrmThemeBranding from './mycrmThemeBranding';
import emailTemplateFields from './emailTemplateFields';
import gamePlan from './gamePlan';
import adviserAccreditationTabs from './adviserAccreditationTabs';
import contactAddressTable from './contactAddressTable';
import contactEmploymentTable from './contactEmploymentTable';
import commission from './commission';
import educationQualifications from './educationQualifications';
import contactIdentificationTable from './contactIdentificationTable';
import corpActionsMenus from './corpActionsMenus';
import documentAccordion from './documentAccordion';
import complaintsRegister from './complaintsRegister';
import viewOfFile from './viewOfFile';
import helloPack from './helloPack';
import contactLendingTab from './contactLendingTab';
import loanVariations from './loanVariations';
import tasks from './tasks';
import businessEmailMailTemplates from './businessEmailMailTemplates';

export default angular.module('app.scenes', [
  mergeContact,
  clientInsurance,
  businessPlanningDashboard,
  insuranceAppWorkbench,
  insuranceCustomerCare,
  insuranceQuoteOpportunities,
  incomeCalculator,
  referral,
  insuranceSummaryOfCover,
  helloBook,
  dashboardWidget,
  insuranceFireAndGeneral,
  assetFinanceWidgets,
  loanAppSections,
  loanInformationFields,
  blueStoneSubmission,
  creditHistoryImproved,
  customerCareTab,
  importantDocuments,
  lendingChooseProducts,
  linkGmailSync,
  addressLookupModal,
  noteType,
  mycrmThemeBranding,
  emailTemplateFields,
  gamePlan,
  adviserAccreditationTabs,
  contactAddressTable,
  contactEmploymentTable,
  commission,
  educationQualifications,
  contactIdentificationTable,
  corpActionsMenus,
  complaintsRegister,
  viewOfFile,
  documentAccordion,
  helloPack,
  contactLendingTab,
  loanVariations,
  tasks,
  businessEmailMailTemplates,
]).name;
