export const QUESTION_LIST = {
  CUSTOMER_NUMBER: 330,
  REFERENCE_NUMBER: 331,
  BANK_CASH_CONTRIBUTION: 332,
  PREFERRED_BRANCH: 333,
  DECLARATION_COMPLIED_LENDER: 334,
  DECLARATION_COMPLIED_LENDER_MORE_DETAILS: 335,
  DECLARATION_DISCUSSED_PRODUCTS: 336,
  DECLARATION_DISCUSSED_PRODUCTS_MORE_DETAILS: 337,
  NEED_EXTENSIVE_INQUIRIES: 338,
  NEED_EXTENSIVE_INQUIRIES_MORE_DETAILS: 339,
  ADOPT_LOAN_STRUCTURE: 340,
  ADOPT_LOAN_STRUCTURE_MORE_DETAILS: 341,
  GREATER_THAN_CURRENT_ARRANGEMENTS: 342,
  GREATER_THAN_CURRENT_ARRANGEMENTS_MORE_DETAILS: 343,
};

export const HANDOVER_PACK_REQUIRED = {
  [QUESTION_LIST.DECLARATION_COMPLIED_LENDER]: true,
  [QUESTION_LIST.DECLARATION_DISCUSSED_PRODUCTS]: true,
  [QUESTION_LIST.NEED_EXTENSIVE_INQUIRIES]: false,
  [QUESTION_LIST.ADOPT_LOAN_STRUCTURE]: false,
};

export const QUESTION_OBJECT_DEFAULT = () => ({
  [QUESTION_LIST.CUSTOMER_NUMBER]: {
    questionId: QUESTION_LIST.CUSTOMER_NUMBER,
  },
  [QUESTION_LIST.REFERENCE_NUMBER]: {
    questionId: QUESTION_LIST.REFERENCE_NUMBER,
  },
  [QUESTION_LIST.BANK_CASH_CONTRIBUTION]: {
    questionId: QUESTION_LIST.BANK_CASH_CONTRIBUTION,
  },
  [QUESTION_LIST.PREFERRED_BRANCH]: {
    questionId: QUESTION_LIST.PREFERRED_BRANCH,
  },
  [QUESTION_LIST.DECLARATION_COMPLIED_LENDER]: {
    questionId: QUESTION_LIST.DECLARATION_COMPLIED_LENDER,
  },
  [QUESTION_LIST.DECLARATION_COMPLIED_LENDER_MORE_DETAILS]: {
    questionId: QUESTION_LIST.DECLARATION_COMPLIED_LENDER_MORE_DETAILS,
  },
  [QUESTION_LIST.DECLARATION_DISCUSSED_PRODUCTS]: {
    questionId: QUESTION_LIST.DECLARATION_DISCUSSED_PRODUCTS,
  },
  [QUESTION_LIST.DECLARATION_DISCUSSED_PRODUCTS_MORE_DETAILS]: {
    questionId: QUESTION_LIST.DECLARATION_DISCUSSED_PRODUCTS_MORE_DETAILS,
  },
  [QUESTION_LIST.NEED_EXTENSIVE_INQUIRIES]: {
    questionId: QUESTION_LIST.NEED_EXTENSIVE_INQUIRIES,
  },
  [QUESTION_LIST.NEED_EXTENSIVE_INQUIRIES_MORE_DETAILS]: {
    questionId: QUESTION_LIST.NEED_EXTENSIVE_INQUIRIES_MORE_DETAILS,
  },
  [QUESTION_LIST.ADOPT_LOAN_STRUCTURE]: {
    questionId: QUESTION_LIST.ADOPT_LOAN_STRUCTURE,
  },
  [QUESTION_LIST.ADOPT_LOAN_STRUCTURE_MORE_DETAILS]: {
    questionId: QUESTION_LIST.ADOPT_LOAN_STRUCTURE_MORE_DETAILS,
  },
  [QUESTION_LIST.GREATER_THAN_CURRENT_ARRANGEMENTS]: {
    questionId: QUESTION_LIST.GREATER_THAN_CURRENT_ARRANGEMENTS,
  },
  [QUESTION_LIST.GREATER_THAN_CURRENT_ARRANGEMENTS_MORE_DETAILS]: {
    questionId: QUESTION_LIST.GREATER_THAN_CURRENT_ARRANGEMENTS_MORE_DETAILS,
  },
});

export const CHOSEN_LOAN_PURPOSE = {
  REFINANCE: 34,
  TOP_UP: 18,
  RESTRUCTURE: 19,
  DEBT_CONSOLIDATION: 35,
};
