import template from './clientSearch.html';
import controller from './clientSearchCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    selectedClient: '<',
    isRequired: '<',
    placeholder: '@',
    onChange: '&',
  },
};
