import { INPUT_FIELDS_DEBOUNCE_TIMEOUT } from 'Common/constants/formSettings';

class QuestionTextAreaCtrl {
  $onInit() {
    this.inputDebounceTimeout = INPUT_FIELDS_DEBOUNCE_TIMEOUT;
  }

  onChange(value) {
    this.onChangeFn()(value);
  }
}

export default QuestionTextAreaCtrl;
