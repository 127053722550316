export function absCodesBuilder(list) {
  return {
    code: list.ABSCode,
    description: list.ABSCodeDescription,
  };
}

export function absPurposeListBuilderForUI(option) {
  return {
    typeId: option.ABSPurposeTypeId,
    purposeDescription: option.PurposeDescription,
    absCodes: option.ABSCodes.map(absCodesBuilder),
  };
}

export function proposedLendingBuilderForUI(list) {
  return {
    absCode: list.ABSCode,
    absCodeDescription: list.ABSCodeDescription,
    absPurposeDescription: list.ABSPurposeDescription,
    abspurposeTypeId: list.ABSpurposeTypeId,
    lendingAmount: list.LendingAmount,
    totalLendingId: list.TotalLendingId,
  };
}
