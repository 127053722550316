import './style.scss';
import template from './personList.html';

export default {
  template,
  bindings: {
    onSelectClient: '&',
    employment: '=',
    showPersonList: '<',
    personList: '<',
    onSetShowPersonList: '&',
  },
};
