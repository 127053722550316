class LoanObjectivesService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.baseUrl = 'loanapp-objectives';
  }

  deleteLoanObjectiveAnswer(data) {
    return this.httpClient.delete(`${this.baseUrl}/answer`, data);
  }
}

export default LoanObjectivesService;
