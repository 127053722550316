import { showActionConfirmation, displaySuccess } from 'Common/utilities/alert';
import { getRecipients } from 'Common/utilities/loanOpportunity';
import { addColorAndInitials, sendHelloBook } from 'Common/utilities/contact';

export default class OpportunityCommunicationsCtrl {
  constructor(
    $uibModal,
    $timeout,
    loanScenarioModelService,
    utilitiesService,
    colorService,
    loanOpportunityService,
    smsService,
    contactModelService,
    contactService,
    currentUserService,
    creditCheckSubscriptionService,
    helloBookPreviewService,
    contactSharedDataService,
    opportunityLoanAppService,
    uiService,
    creditCheckService,
    configService,
    helloPackService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.loanScenarioModelService = loanScenarioModelService;
    this.loanOpportunityService = loanOpportunityService;
    this.smsService = smsService;
    this.utilitiesService = utilitiesService;
    this.colorService = colorService;
    this.contactModelService = contactModelService;
    this.contactService = contactService;
    this.currentUserService = currentUserService;
    this.creditCheckSubscriptionService = creditCheckSubscriptionService;
    this.helloBookPreviewService = helloBookPreviewService;
    this.contactSharedDataService = contactSharedDataService;
    this.opportunityLoanAppService = opportunityLoanAppService;
    this.uiService = uiService;
    this.creditCheckService = creditCheckService;
    this.configService = configService;
    this.helloPackService = helloPackService;

    this.onDeleteTransaction = this.onDeleteTransaction.bind(this);
    this.deleteTransaction = this.deleteTransaction.bind(this);
  }

  $onInit() {
    this.oneTouchPrivacy = this.configService.feature.oneTouchPrivacy;
    this.pageNumber = 1;
    this.pageSize = 10;
    this.totalRecords = 0;

    const reloadCommunications = this.getCommunications.bind(this);
    this.opportunityLoanAppService.setCallback(
      'refreshOpportunityCommunications',
      reloadCommunications,
    );

    this.getCommunications();

    this.smsService.isSmsEnabled(this.adviserId).then((isSmsEnabled) => {
      this.smsEnableSetting = isSmsEnabled;
      this.smsEnableView = this.hasAtleastOnePhone() && this.smsEnableSetting;
    });
    this.getDesignatedFamilyBrokerInfo();
    this.bidViewOfAFileEnabled = this.uiService.viewOfAfileTurnedOn;
  }

  $onChanges(changes = {}) {
    const { involvedParties } = changes;
    if (involvedParties) {
      const { currentValue: involvedPartiesValues } = involvedParties;
      if (involvedPartiesValues) {
        this.smsEnableView = this.hasAtleastOnePhone() && this.smsEnableSetting;
      }
    }
  }

  $onDestroy() {
    this.timer && this.$timeout.cancel(this.timer);
  }

  hasAtleastOnePhone() {
    const { InvolvedPartyPerson = [], InvolvedPartyEntity = [] } =
      this.involvedParties || {};

    const parties = [...InvolvedPartyPerson, ...InvolvedPartyEntity];

    return parties.some((o) => o.Phone && o.Phone.length);
  }

  openCommunicationList(bool) {
    this.isCommunicationListOpen = bool;
  }

  onPageChange({ pageNumber, pageSize }) {
    this.pageSize = pageSize;
    this.getCommunications(pageNumber);
  }

  getCommunications(pageNumber = 1) {
    this.pageNumber = pageNumber;
    this.isLoadingEmails = true;
    const params = {
      familyId: this.familyId,
      loanId: this.loanId,
      pageSize: this.pageSize,
      gmail: false,
      email: true,
      phone: true,
      searchCriteria: '',
    };
    const { pageSize } = params;

    const args = {
      pageNumber,
      pageSize,
    };

    this.loanOpportunityService
      .getCommunicationsList(this.loanId, args)
      .then(({ communications, totalRecords }) => {
        this.isLoadingEmails = false;
        this.communications = communications || [];
        this.totalRecords = totalRecords || 0;
      });
  }

  getRecipientsChoices() {
    const recipients = getRecipients(this.involvedParties);
    return addColorAndInitials({
      utilitiesService: this.utilitiesService,
      colorService: this.colorService,
    })(recipients);
  }

  openEmailSmsModal(isEmail, obj) {
    const recipientsChoices = this.getRecipientsChoices();
    const props = {
      obj,
      familyId: this.familyId,
      loanId: this.loanId,
      recipientsChoices,
    };

    const component = isEmail ? 'email' : 'sms';
    this.$uibModal
      .open({
        template: `<opportunity-send-${component}-modal
                  modal-instance="vm.modalInstance"
                  ${component}="vm.props.obj"
                  family-id="vm.props.familyId"
                  loan-id="vm.props.loanId"
                  recipients-choices="vm.props.recipientsChoices"
                ></opportunity-send-${component}-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        windowClass: `opportunity-send-${component}-modal`,
        size: 'lg',
        resolve: {
          props: () => props,
        },
      })
      .result.then((res) => {
        if (res.isRefresh) {
          this.timer = this.$timeout(() => {
            this.$timeout.cancel(this.timer);
            this.getCommunications();
          }, 2000);
        }
      });
  }

  onDeleteTransaction(transaction) {
    const transactionType =
      transaction.type !== 'Email' ? 'SMS' : transaction.type;
    showActionConfirmation(
      `Please confirm action`,
      `Are you sure you want to delete this ${transactionType}? This action cannot be undone.`,
      (confirm) => (confirm ? this.deleteTransaction(transaction) : null),
    );
  }

  deleteTransaction(transaction) {
    const { emailId: communicationId } = transaction;
    if (!communicationId) {
      return;
    }
    const params = {
      workbenchLogID: 0,
      communicationId,
      loanId: this.loanId,
    };

    this.loanScenarioModelService.deleteCommunication(params).then(() => {
      if (transaction.modalInstance) {
        transaction.modalInstance.dismiss('');
      }
      displaySuccess(`Transaction deleted successfully`);
      this.timer = this.$timeout(() => {
        this.getCommunications();
        this.$timeout.cancel(this.timer);
      }, 2000);
    });
  }

  viewEmailSmsModal(isEmail, obj) {
    const props = {
      transaction: obj,
      isEmail,
      onDeleteTransaction: this.onDeleteTransaction,
    };

    this.$uibModal.open({
      template: `<opportunity-view-transaction-modal
        transaction="vm.props.transaction"
        is-email="vm.props.isEmail"
        on-delete-transaction="vm.props.onDeleteTransaction($e.transaction)"
        modal-instance="vm.modalInstance"
      >
      </opportunity-view-transaction-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      windowClass: `opportunity-view-transaction-modal`,
      size: 'lg',
      resolve: {
        props: () => props,
      },
    });
  }

  getDesignatedFamilyBrokerInfo() {
    if (!this.familyId) {
      return;
    }
    this.contactModelService
      .getFamilyAdviserInfo(this.familyId)
      .then((response) => {
        if (!response) {
          return;
        }
        this.creditCheckService.creditCheckValidation(response.familyId);
        this.designatedBrokerInfo = response;
      });
  }

  updateClientList(data) {
    if (data && data.isContactUpdated) {
      this.contactSharedDataService.reloadClients &&
        this.contactSharedDataService.reloadClients();
    }
  }

  openSendHelloBook(additionalProps = {}) {
    const props = {
      ...additionalProps,
      familyId: this.familyId,
      ...(!this.oneTouchPrivacy && { hideDealOption: true }),
    };
    this.helloBookPreviewService.open(props).then(
      (response) => {
        this.contactSharedDataService.reloadContactSummary &&
          this.contactSharedDataService.reloadContactSummary();
        this.updateClientList(response);
        this.getCommunications();

        if (!response || !response.sent) {
          return;
        }

        this.contactSharedDataService.reloadContactLending();
        this.contactSharedDataService.reloadContactLoanTimeline();
      },
      (error) => {
        this.updateClientList(error);
      },
    );
  }

  sendHelloBook() {
    if (this.helloPackService.isDealHelloPackDisabled) {
      return;
    }
    sendHelloBook({
      vm: {
        designatedBrokerInfo: this.designatedBrokerInfo,
        openSendHelloBook: this.openSendHelloBook.bind(this),
      },
      helloBookPreviewService: this.helloBookPreviewService,
      isNewModal: true,
      loanApplicationId: this.loanApplicationId,
    });
  }
}
