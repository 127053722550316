import template from './fundForm.html';
import controller from './fundFormCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    fund: '<',
    loanAppId: '<',
    modalInstance: '<',
    lendingCategoryId: '<',
    securityBrokerEventId: '<',
  },
};
