import angular from 'angular';
import {
  BIRTHDAYS_ID,
  CUSTOMER_CARE_TYPES,
} from 'Common/constants/customerCareModules';
import moment from 'moment';
import { setEmailObject } from './util/customerCareEmailUtil';

export const loadModule = () =>
  angular.module('app').controller(
    'BirthdaysSendEmailModalCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function BirthdaysSendEmailModalCtrl(
      $scope,
      $rootScope,
      $uibModalInstance,
      $controller,
      contactService,
      corporateService,
      customerCareService,
      birthdayBatchList,
      source,
      birthdayObj,
      formErrorHandlerServices,
      loadBirthdaysTable,
      customerCareTemplateService,
    ) {
      const vm = this;
      angular.extend(
        vm,
        $controller('CustomerCareSendEmailModalBaseCtrl', { $scope }),
      );

      $scope.recipients = {};
      $scope.isComplete = true;
      $scope.currentModule = BIRTHDAYS_ID;
      $scope.mergeFieldsParams = birthdayObj;

      if (source === 'single') {
        if (birthdayObj.Email !== '') {
          const birthDate = moment(birthdayObj.DateOfBirth).format('D MMM');
          const notiHeader = `Birthdays (${birthDate}) Completed via Email`;
          $scope.recipients.email = [
            {
              FamilyID: birthdayObj.FamilyID,
              FamilyFullname: birthdayObj.FamilyFullName,
              LoanID: birthdayObj.LoanID,
              EmailAddress: birthdayObj.Email,
              NotificationHeader: notiHeader,
            },
          ];
        }
        $scope.recipients.CustomerCareType = 'BIRTH_DAY';
      }

      $scope.birthdayBatchList = birthdayBatchList;

      if (source === 'selected') {
        $scope.all = true;
        if ($scope.birthdayBatchList && $scope.birthdayBatchList.length > 0) {
          $scope.selectEmailList = [];

          for (let i = 0; i < $scope.birthdayBatchList.length; i++) {
            $scope.emailAdd = {};
            if ($scope.birthdayBatchList[i].Email !== '') {
              $scope.emailAdd.FamilyID = $scope.birthdayBatchList[i].FamilyID;
              $scope.emailAdd.FamilyFullname =
                $scope.birthdayBatchList[i].FamilyFullName;
              $scope.emailAdd.LoanID = $scope.birthdayBatchList[i].LoanID;

              $scope.emailAdd.EmailAddress = $scope.birthdayBatchList[i].Email;
              $scope.selectEmailList.push($scope.emailAdd);
            }
          }
          if ($scope.selectEmailList.length > 0) {
            $scope.recipients.email = $scope.selectEmailList;
            $scope.noOfPeople = $scope.selectEmailList.length;
          }
        }
      }
      $scope.getRecipientAutoSuggestion = (userInput) => {
        contactService
          .getRecipientAutoSuggestion(userInput)
          .then((response) => {
            $scope.withEmail = response.data;
            $scope.selectEmailList = !$scope.withEmail
              ? []
              : $scope.withEmail.reduce((accum, recipient) => {
                  if (
                    !recipient.EmailAddress ||
                    !recipient.EmailAddress.trim()
                  ) {
                    return accum;
                  }
                  $scope.selectEmail = {};
                  $scope.selectEmail.FamilyFullname = recipient.Name;
                  $scope.selectEmail.EmailAddress = recipient.EmailAddress;
                  return accum.concat($scope.selectEmail);
                }, []);
          });
      };

      // EmailTemplates
      const getMergeFields = () => {
        $scope.preferredName = '';
        if (typeof birthdayObj.FamilyID !== 'undefined') {
          $scope.familyId = birthdayObj.FamilyID;
        } else {
          $scope.familyId = 0;
        }
        if (typeof birthdayObj.LoanID !== 'undefined') {
          $scope.loanId = birthdayObj.LoanID;
        } else {
          $scope.loanId = 0;
        }

        $scope.clientId = birthdayObj.ClientID || 0;
        corporateService
          .MergeFieldsGet(
            $scope.familyId,
            $scope.clientId,
            $scope.loanId,
            0,
            false,
            0,
          )
          .then((response) => {
            $scope.emailMergeFieldList = response.data;
            $scope.mergeFieldsObj = response.data[0];
            $scope.mergeFields = [];
            $scope.mergeFields = $scope.mergeFieldsObj.MergeFields;
            for (let k = 0; k < $scope.mergeFields.length; k++) {
              if ($scope.mergeFields[k].Name === 'Full Name') {
                $scope.preferredName = $scope.mergeFields[k].Value;
              }
            }

            $scope.getEmailTemplates($scope.preferredName);
            $scope.getDefaultTemplate();
          });
      };

      getMergeFields();

      $rootScope.getEmailTemplatesFromEmailPage = () => {
        $scope.getEmailTemplates('');
      };
      $scope.selectedTemplate = {
        UserTemplateID: 0,
        UserTemplateName: 'Blank',
        UserTemplateEmailSubject: '',
      };

      $scope.refreshResults = (select) => {
        const search = select.search;
        $scope.getRecipientAutoSuggestion(search);
      };

      const init = () => {
        $scope.subject = 'Happy Birthday!';
      };

      init();

      const markBirthDayCompleted = () => {
        if (!$scope.isComplete) {
          loadBirthdaysTable();
          return;
        }
        const payload = {
          Birthday: birthdayObj.NewDOB,
          IsCompleted: $scope.isComplete,
          ClientId: birthdayObj.ClientID,
        };
        customerCareService.setBirthdayCompletion(payload).then(() => {
          loadBirthdaysTable();
        });
      };

      $scope.sendEmail = (form) => {
        const customerCareFormValid = formErrorHandlerServices.sendEmailFormValidation(
          form,
        );
        if (customerCareFormValid) {
          setEmailObject($scope);
          $scope.email.CustomerCareType = CUSTOMER_CARE_TYPES.BIRTHDAYS;
          $scope.email.IsComplete = $scope.isComplete;

          customerCareService
            .CustomerCareEmailSendv2($scope.email)
            .then((birthdayRes) => {
              if (birthdayRes.data && birthdayRes.data.length) {
                const birthdayEmailValid = customerCareTemplateService.customerCareResponseMsg(
                  birthdayRes.data,
                  'EMAIL',
                );
                if (birthdayEmailValid) {
                  $scope.email.EmailSubject = '';
                  $scope.email.EmailBody = '';
                  form.$setPristine(true);
                  markBirthDayCompleted();
                }
                $scope.cancel();
              }
            });
        }
      };

      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
    },
  );
