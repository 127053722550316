import { toastError } from 'Common/utilities/alert';

class OnboardingAuthorityAndConsentCtrl {
  constructor(corporateModelService) {
    'ngInject';

    this.corporateModelService = corporateModelService;
  }

  $onInit() {
    this.consentWithDates = [];
    this.getAuthorityConsent();
  }

  checkEmptyConsent() {
    const emptyConsent =
      this.onboardingConsents &&
      this.onboardingConsents.filter((consent) => !consent.isChecked);
    const isConsentChecked = !(emptyConsent && emptyConsent.length);
    if (this.onTick) {
      this.onTick({ isConsentChecked });
    }
  }

  getAuthorityConsent() {
    this.corporateModelService
      .getAuthorityConsent(this.familyId)
      .then((data) => {
        if (!data || !data.length) {
          return;
        }
        this.onboardingConsents = data;
        this.consentWithDates = data.filter((consent) => consent.dateChecked);
        this.checkEmptyConsent();
      });
  }

  setAuthorityConsent(item) {
    const { isChecked, consentId } = item;
    if (!consentId) {
      return;
    }
    const params = {
      isChecked,
      consentId,
    };
    this.corporateModelService
      .setAuthorityConsent(params)
      .then(() => {
        this.checkEmptyConsent();
      })
      .catch(() => {
        item.isChecked = !item.isChecked;
        toastError('There is an error on updating authority and consent');
      });
  }
}

export default OnboardingAuthorityAndConsentCtrl;
