import { toastError } from 'Common/utilities/alert';

export const removeRecipientUtil = (scope) => (recipient) => {
  if (!scope || !recipient) {
    return;
  }

  const minimumNumberOfRecipient = 1;
  if (scope.smsModel.selectedContacts.length <= minimumNumberOfRecipient) {
    toastError('Cannot remove main recipient');
    return;
  }

  const recipientIndex = scope.smsModel.selectedContacts.indexOf(recipient);
  scope.smsModel.selectedContacts.splice(recipientIndex, 1);
};
