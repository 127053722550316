export const CONTACT_TAB = {
  COMMUNICATIONS: 'communications',
  EMAILS: 'emails',
};
export const ADVISER_DEFAULT_TAB = 'adviser-details';
export const CLIENT_DEFAULT_TAB = 'summary';
export const ADVISER_ORG_DEFAULT_TAB = 'business-details';
export const ASSISTANT_DEFAULT_TAB = 'assistant-details';
export const REFERRER_DEFAULT_TAB = 'details';
export const REFERRER_ORG_DEFAULT_TAB = 'org-details';

export const ASSISTANT_TAB_NAMES = {
  ACCESS_MANAGEMENT: 'Access Management',
};
