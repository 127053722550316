class CreditCheckHistoryCtrl {
  constructor(
    modalRenderService,
    creditCheckSubscriptionService,
    usersSubscriptionService,
    uiService,
  ) {
    'ngInject';

    this.uiService = uiService;
    this.modalRenderService = modalRenderService;
    this.creditCheckSubscriptionService = creditCheckSubscriptionService;
    this.usersSubscriptionService = usersSubscriptionService;
  }

  setCreditCheckListFlag() {
    this.displayCreditCheckList = !this.displayCreditCheckList;
  }

  onCreditChecksListInit(api) {
    this.listApi = api;
  }

  openCreditCheck() {
    const contactForCreditCheck = {
      FamilyID: this.familyId,
      ClientFirstName: this.adviserDetails.FirstName,
      ClientLastName: this.adviserDetails.LastName,
      isAdviser: true,
    };
    const modalInstance = this.modalRenderService.openCreditCheckModal(
      contactForCreditCheck,
    );
    modalInstance.result.then(() => {
      this.listApi.refresh();
    });
  }

  openSubscription() {
    if (this.isCreditCheckDisabled()) {
      return;
    }
    if (
      !this.checkSubscriber ||
      this.creditCheckSubscriptionService.creditCheck
        .isAllocatedAdviserSubscribed
    ) {
      this.openCreditCheck();
      return;
    }
    const props = {
      familyId: this.creditCheckSubscriptionService.creditCheck.adviserFamilyId,
      subscriptionData: this.creditCheckSubscriptionService.creditCheck
        .creditCheckSubscriptionInfo,
      orderedById: this.creditCheckSubscriptionService.creditCheck
        .adviserFamilyId,
    };
    this.usersSubscriptionService.subscribeToService(props).then((response) => {
      const isSubscribe = !!response;
      this.creditCheckSubscriptionService.creditCheck.isAllocatedAdviserSubscribed = isSubscribe;
      if (!isSubscribe) {
        return;
      }
      this.openCreditCheck();
    });
  }

  isCreditCheckDisabled() {
    return (
      this.checkSubscriber &&
      this.creditCheckSubscriptionService.creditCheck.isCreditCheckDisabled
    );
  }

  $onInit() {
    this.displayCreditCheckList = true;
  }
}

export default CreditCheckHistoryCtrl;
