import { get } from 'lodash';
import { formatNumberWithComma } from 'Common/utilities/currency';
import { BASIC_DOUGHNUT_OPTION } from 'Common/config/chart';
import { DOUGHNUT_COLORS } from '../../constants';

export default class CommissionPieCtrl {
  $onChanges(changes) {
    const { pieData } = changes;
    if (pieData && pieData.currentValue) {
      this.structureCommissionPie();
    }
  }

  structureCommissionPie() {
    this.chartData = [];
    this.chartColors = [];
    this.chartLegend = [];
    this.chartLabels = [];
    if (!this.pieData || !this.pieData.length) {
      return;
    }

    const colorSplit = DOUGHNUT_COLORS.length;

    this.pieData.forEach((item, index) => {
      this.chartData = [...this.chartData, item.TotalCommissions];
      this.chartColors = [
        ...this.chartColors,
        DOUGHNUT_COLORS[index % colorSplit],
      ];
      this.chartLegend = [
        ...this.chartLegend,
        {
          label: item.CommissionTypeDesc,
          color: DOUGHNUT_COLORS[index % colorSplit],
        },
      ];
      this.chartLabels = [...this.chartLabels, item.CommissionTypeDesc];
      this.chartOptions = {
        ...BASIC_DOUGHNUT_OPTION,
        tooltips: {
          callbacks: {
            label: (value, { datasets, labels }) => {
              const { index: valIndex } = value;
              const dataVal = get(datasets, `[0].data[${valIndex}]`, 0);
              const dataLabel = get(labels, `[${valIndex}]`);
              return `${dataLabel}: $${formatNumberWithComma(
                dataVal.toFixed(2),
              )}`;
            },
          },
        },
      };
    });
  }
}
