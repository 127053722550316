import swal from 'sweetalert';

export function gmailSyncSwal(color, callback) {
  swal(
    {
      title: 'Are you sure?',
      text: 'Do you want to unlink Gmail & MyCRM',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: color,
      confirmButtonText: 'Yes, unlink!',
      cancelButtonText: 'No',
      closeOnConfirm: true,
    },
    callback,
  );
}
