import template from './relatedEntities.html';
import RelatedEntitiesCtrl from './relatedEntitiesCtrl';

export default {
  template,
  bindings: {
    relatedSoleTraders: '<',
  },
  controller: RelatedEntitiesCtrl,
  controllerAs: 'vm',
};
