import { isValidObjectValue } from 'Common/utilities/objectValidation';

export const updateBrokerageValues = ({ cqp }) => {
  cqp.BrokeragePercent = cqp.Brokerage || 0;
  cqp.BrokerageAmount = cqp.Brokerage_amt || 0;
};

export const copyBrokerageDocumentValues = ({ cpaCqpSetCopy, facility }) => {
  cpaCqpSetCopy.CQP.Brokerage =
    isValidObjectValue(() => facility.AssetFinance.BrokeragePercent) || 0;
  cpaCqpSetCopy.CQP.Brokerage_amt =
    isValidObjectValue(() => facility.AssetFinance.BrokerageAmount) || 0;
  cpaCqpSetCopy.CQP.DocumentFee = facility.DocumentFeePercent || 0;
  cpaCqpSetCopy.CQP.DocumentFee_amt = facility.DocumentFee || 0;
};

export const roundToNearestDecimals = ({ numberToRound, numberOfDecimals }) => {
  return parseInt(
    `${Math.round(`${numberToRound}e2`)`e-${numberOfDecimals}`}`,
    10,
  );
};
