import angular from 'angular';

class AddressModalCtrl {
  constructor(
    $uibModalInstance,
    tempAddress,
    contactId,
    isOrg,
    defaultManual,
    addressTypeToShow,
    addressModalCaller,
  ) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.tempAddress = tempAddress;
    this.contactId = contactId;
    this.isOrg = isOrg;
    this.defaultManual = defaultManual;
    this.addressTypeToShow = addressTypeToShow;
    this.addressModalCaller = addressModalCaller;
  }
}
export const loadModule = () =>
  angular.module('app').controller('AddressModalCtrl', AddressModalCtrl);
