export function segmentFilterOptionsForUI(option) {
  return {
    name: option.Name,
    value: option.Value,
  };
}

export function segmentFilterClientTypesForUI(option) {
  return {
    value: option.ClientTypeID,
    name: option.ClientTypeName,
    orderBy: option.OrderBy,
    isExclusiveForCorpUser: option.isExclusiveForCorpUser,
  };
}
