import template from './lmPdfFooter.html';
import controller from './lmPdfFooterCtrl';
import './styles.scss';

export default {
  template,
  controller,
  bindings: {
    pageNumber: '@',
    maxPageNumber: '@',
    squareSize: '@',
  },
};
