export default class OpportunitySendSmsModalCtrl {
  constructor(contactService) {
    'ngInject';

    this.contactService = contactService;
    this.closeModal = this.closeModal.bind(this);
  }

  deleteTransaction(event) {
    event && event.stopPropagation();
    this.onDeleteTransaction({
      $e: {
        transaction: { ...this.transaction, modalInstance: this.modalInstance },
      },
    });
  }

  closeModal() {
    this.modalInstance.close();
  }
}
