import template from './inputCurrency.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    id: '@',
    model: '<',
    placeholder: '<',
    onChange: '&',
    onBlur: '&',
    isDisabled: '<',
  },
};
