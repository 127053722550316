export function insuranceApplicationClient(client) {
  return (
    client && {
      quoteClientId: client.QuoteClientID,
      familyClientId: client.FamilyClientID,
      firstName: client.FirstName,
      lastName: client.LastName,
      roleId: client.RoleID,
      isKeyRecord: client.IsKeyRecord,
      email: client.Email,
      mobileNumber: client.MobileNumber,
    }
  );
}

export function insuranceApplicationBenefits(benefit) {
  return (
    benefit && {
      benefitId: benefit.BenefitID,
      benefitName: benefit.BenefitName,
      coverAmount: benefit.CoverAmount,
    }
  );
}

export function insuranceApplication(application) {
  return (
    application && {
      quoteId: application.QuoteID,
      familyId: application.FamilyID,
      providerId: application.ProviderId,
      provider: application.Provider,
      allowSendApplication: application.AllowSendApplication,
      isPolicyHolderSignatory: application.IsPolicyHolderSignatory,
      sendTo: {
        clients:
          application.SendTo &&
          application.SendTo.Clients &&
          application.SendTo.Clients.map(insuranceApplicationClient),
        email: application.SendTo.Email,
        mobileNumber: application.SendTo.MobileNumber,
      },
      benefitSummaries:
        application.BenefitSummaries &&
        application.BenefitSummaries.map(insuranceApplicationBenefits),
    }
  );
}
