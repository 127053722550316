class MycrmGenderCtrl {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;
  }

  $onInit() {
    this.value = this.value || null;
    this.selectSize = this.size === 'xs' ? 'type-select' : 'clip-select-caret';
    this.genders = [
      {
        id: 1,
        value: 'Male',
        label: 'Male',
      },
      {
        id: 2,
        value: 'Female',
        label: 'Female',
      },
      {
        id: 3,
        value: 'Undisclosed',
        label: 'Undisclosed',
      },
    ];
  }
}

export default MycrmGenderCtrl;
