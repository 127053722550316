import { ASC } from '../../constants/pageConstants';
import * as listUtils from '../../utils/listUtils';

export default class Qualifications {
  constructor(
    modalRenderService,
    loanToolsService,
    generalService,
    downloadDataService,
  ) {
    'ngInject';

    this.modalRenderService = modalRenderService;
    this.loanToolsService = loanToolsService;
    this.generalService = generalService;
    this.downloadDataService = downloadDataService;

    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });

    this.currentPage = 1;
    this.headerList = [
      { label: 'Date', key: 'date' },
      { label: 'Qualification', key: 'qualification' },
      { label: 'Provider', key: 'provider' },
      { label: 'Comments', key: 'comments' },
      { label: 'Attachment', key: 'attachment' },
    ];

    this.getQualificationList();

    this.sortOrder = ASC;
  }

  processParentNotification(eventHandler) {
    listUtils.processParentNotification(
      this,
      eventHandler,
      'openQualificationsModal',
    );
  }

  getQualificationList() {
    return listUtils.getList(this, 'resourceEducationQualificationGet');
  }

  onSortDate() {
    listUtils.onSortDate(this, 'getQualificationList');
  }

  setDownloading(id, isDownloading) {
    listUtils.setDownloading(this, id, isDownloading);
  }

  onDownloadDocument($e, qualification) {
    listUtils.onDownloadDocument(this, $e, qualification);
  }

  openQualificationsModal(qualification = { id: 0 }) {
    const props = { qualification, presetList: this.presetList };
    return listUtils.openModal({
      vm: this,
      props,
      modalFn: 'openQualificationsModal',
      successMessage: 'Qualification Saved.',
      getFn: 'getQualificationList',
    });
  }

  onEditClick($e, qualification) {
    listUtils.onEditClick(this, $e, qualification, 'openQualificationsModal');
  }

  onDeleteClick($e, id) {
    listUtils.onDeleteClick({
      vm: this,
      $e,
      id,
      deleteFn: 'resourceEducationQualificationDelete',
      successMessage: 'Qualification Deleted.',
      getFn: 'getQualificationList',
    });
  }
}
