import './style.scss';
import template from './expenseGridModal.html';
import controller from './expenseGridModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    familyId: '<',
    borrowers: '<',
    frequencyTypes: '<',
  },
};
