import template from './quoteDetails.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    productType: '<',
    selectedProductType: '<',
    loanAmount: '<',
    loanTerms: '<',
    selectedLoanTerm: '<',
    residualPercent: '<',
    rateDiscount: '<',
    financeTypes: '<',
    selectedFinanceType: '<',
    originationFee: '<',
    productTypes: '<',
    repaymentFrequencies: '<',
    onChangeBinding: '&',
    onChangeProduct: '&',
    isConsumerPersonal: '<',
    selectedFrequency: '<',
    isValidDetails: '<',
  },
};
