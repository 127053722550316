import template from './crmCalendar.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    preselectDate: '<',
    datePickerOptions: '<',
    onChange: '&',
  },
};
