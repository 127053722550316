import { saveAssetAddress } from 'Common/utilities/assetsModal';

export const saveAssetDetails = ({
  $scope,
  $rootScope,
  contactService,
  dashboardService,
  addressLookupService,
  copyAssetSetTemp,
}) => {
  saveAssetAddress({
    $scope,
    dashboardService,
    addressLookupService,
    assetDetails: copyAssetSetTemp,
  }).then(({ data }) => {
    copyAssetSetTemp.Description =
      copyAssetSetTemp.FullAddress.formatted_address;
    copyAssetSetTemp.FullAddress.AddressID = data;
    copyAssetSetTemp.Address = copyAssetSetTemp.FullAddress;
    $scope.AssetsSet = copyAssetSetTemp;

    contactService.assetDetailsSet($scope.AssetsSet).then(() => {
      $rootScope.$broadcast('REFRESH_INCOME');
      $rootScope.$broadcast('REFRESH_LIABILITY');
      $scope.assetsInit(false);
      $scope.displayAssetsFormWrapper.displayAssetsForm = false;
    });
  });
};
