import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';
import { capitalizeFirstLetter } from 'Common/utilities/string';
import {
  isDateSentActive,
  statusCompleted,
  hideMarkBtn,
} from 'Assets/js/controllers/customerCare/partial/util/customerCareTabsCtrl';

export default class CustomerCareActionCtrl {
  constructor(configService, customerCareTabsService, currentUserService) {
    'ngInject';

    this.configService = configService;
    this.customerCareTabsService = customerCareTabsService;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.SEND_METHOD = SEND_METHOD_TYPES;
    this.isNZ = this.currentUserService.isNZ;
    this.isDateSentActive = isDateSentActive(this.configService);
    this.statusCompleted = () => statusCompleted(this);
    this.hideMarkBtn = () => hideMarkBtn(this);
    this.isBulkEmailActive = this.customerCareTabsService.isBulkEmailActive();

    this.autoText('email');
    this.autoText('mail');
    this.autoText('sms');

    this.dateSentVia =
      this.item.CommunicationSent || this.item.communicationSent;
    this.sentDate = this.dateSentVia && this.dateSentVia.slice(0, 11);
    this.sentVia =
      this.dateSentVia && capitalizeFirstLetter(this.dateSentVia.slice(16));

    this.showMarkAsIncompleteBtn =
      typeof this.showMarkAsIncompleteBtn === 'undefined'
        ? true
        : this.showMarkAsIncompleteBtn;
  }

  sendNow(sendVia, sendType) {
    this.onActionSelected({ $event: { item: this.item, sendVia, sendType } });
  }

  autoText(type) {
    this.autoText[type] = `This customer has communications sent <br>
    via marketing automation. Manual sending <br>
    of ${type} is disabled.`;
  }

  sendSMS() {
    if (this.isNZ) {
      return;
    }
    this.sendNow('openSMSModal', this.SEND_METHOD.SMS);
  }
}
