import { parseToInt10 } from 'Common/utilities/parse';
import {
  showActionConfirmation,
  toastError,
  toastSuccess,
} from 'Common/utilities/alert';
import { OK } from 'Common/constants/httpStatusCodes';

export default class InsuranceFilesCtrl {
  constructor(
    insuranceModalApplicationService,
    insuranceFileService,
    configService,
    contactService,
    pipelineApiService,
  ) {
    'ngInject';

    this.insuranceModalApplicationService = insuranceModalApplicationService;
    this.insuranceFileService = insuranceFileService;
    this.configService = configService;
    this.contactService = contactService;
    this.pipelineApiService = pipelineApiService;

    // The adviser is on the contact and not the insurance files; we only need to
    // read the contact's assigned adviser once.
    this.adviserPreferredFullName = '';

    this.statuses = [];
  }

  $onInit() {
    this.loadLookups();
  }

  loadLookups() {
    this.getInsuranceFiles();
    this.getInsurancePipelineStatuses();
    this.getAdviser();
  }

  openInsuranceFile(file) {
    this.insuranceModalApplicationService
      .openInsuranceFile({
        familyId: parseToInt10(this.familyId),
        file,
        files: this.files,
        statuses: this.statuses,
      })
      .result.then(({ refresh } = {}) => {
        refresh && this.getInsuranceFiles();
      });
  }

  setFileStatus(file, newStatus) {
    const toastTitle = 'Insurance File Status';

    if (!file || !newStatus?.id) {
      toastError('The file or new status was not set', toastTitle);
      return;
    }

    this.pipelineApiService
      .updateInsuranceFileStatus(file.id, newStatus.id)
      .then(({ status }) => {
        if (status === OK) {
          file.statusName = newStatus.name;

          toastSuccess(
            `Insurance file successfully moved to ${newStatus.name} status`,
            toastTitle,
          );

          return;
        }

        toastError('No pipeline exists for this insurance file', toastTitle);

        this.loadLookups();
      });
  }

  getInsurancePipelineStatuses() {
    this.pipelineApiService
      .getInsurancePipelineStatuses()
      .then((statuses = []) => {
        this.statuses = [...statuses];
      });
  }

  getAdviser() {
    this.contactService.contactFamilyInfoGet(this.familyId).then((response) => {
      this.adviserPreferredFullName = response?.data?.AdviserFullName ?? '';
    });
  }

  getInsuranceFiles() {
    this.isLoading = true;
    this.insuranceFileService
      .getInsuranceFiles(this.familyId)
      .then((data = []) => {
        Promise.all(
          data.map((x) => {
            return this.weaveInsuranceFileStatus(x);
          }),
        ).then(() => {
          this.files = [...data];
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  deleteInsuranceFile(fileId) {
    showActionConfirmation(
      'Are you sure?',
      'This insurance file will be removed from your list',
      () =>
        this.insuranceFileService
          .deleteInsuranceFile(fileId)
          .then(() => this.getInsuranceFiles()),
    );
  }

  weaveInsuranceFileStatus(file) {
    return this.pipelineApiService
      .getInsuranceFileStatus(file.id)
      .then((stageDefinition) => {
        file.statusId = stageDefinition?.id;
        file.statusName = stageDefinition?.name;
      });
  }
}
