export default class InsurancePipelineLabelsMenuCtrl {
  $onChanges() {
    this.canGoBack = this.checkCanGoBack();
  }

  toggleAddLabel() {
    this.isAddMode = !this.isAddMode;
    if (this.isAddMode) {
      this.isEditMode = false;
    }
    this.labelToSave = { id: 0 };
    this.isSaveButtonVisible = this.checkSaveButtonVisibility();
    this.canGoBack = this.checkCanGoBack();
  }

  toggleEditLabel(label) {
    this.isEditMode = !this.isEditMode;
    if (this.isEditMode) {
      this.isAddMode = false;
    }
    this.labelToSave = { ...label };
    this.isSaveButtonVisible = this.checkSaveButtonVisibility();
    this.canGoBack = this.checkCanGoBack();
  }

  onChangeLabelName(name) {
    this.labelToSave.name = name;
    this.isSaveButtonVisible = this.checkSaveButtonVisibility();
  }

  onSelectLabelColor(color) {
    this.labelToSave.color = color;
    this.isSaveButtonVisible = this.checkSaveButtonVisibility();
  }

  checkSaveButtonVisibility() {
    const isValidForm =
      this.labelToSave && this.labelToSave.name && this.labelToSave.color;
    return (this.isAddMode || this.isEditMode) && !!isValidForm;
  }

  saveLabel(label) {
    if (this.isAddMode) {
      this.onAddLabel && this.onAddLabel({ label });
    } else if (this.isEditMode) {
      this.onUpdateLabel && this.onUpdateLabel({ label });
    }
    this.goBack();
  }

  goBack() {
    if (this.isAddMode) {
      this.toggleAddLabel();
    } else if (this.isEditMode) {
      this.toggleEditLabel();
    } else {
      this.onBack && this.onBack();
    }
    this.canGoBack = this.checkCanGoBack();
  }

  checkCanGoBack() {
    return this.isAddMode || this.isEditMode || !!this.onBack;
  }
}
