import template from './commissionPayments.html';
import controller from './commissionPaymentsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isMobileDevice: '<',
    parentToChildNotificationRegistration: '&',
    brokers: '<',
    default: '<',
  },
};
