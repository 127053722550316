import angular from 'angular';

export const loadModule = () =>
  angular.module('app').controller('CommissionTransCtrl', [
    '$scope',
    '$routeParams',
    function CommissionTransCtrl($scope, $routeParams) {
      const vm = this;
      vm.keyword = $routeParams.keyword;
    },
  ]);
