import {
  FINANCIAL_FREQUENCY,
  FINANCIAL_REPAYMENT_FREQUENCY,
  FINANCIAL_FREQUENCY_TEXT,
} from 'Common/constants/financialFrequency';
import { parseToInt10 } from 'Common/utilities/parse';
import { OPPORTUNITY } from 'Common/constants/paramTypes';
import { RATE_TYPES } from 'Common/constants/productRateType';

export const mapLoanOpportunitiesData = ({ $scope, $state, uiService }) => (
  apiService,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  if (
    $state.params.paramType !== OPPORTUNITY ||
    !uiService.viewOfAfileTurnedOn
  ) {
    return;
  }
  apiService
    .getLoanOpportunityDetails($state.params.paramId)
    .then(({ data }) => {
      const validProductType = !!(
        data.CalculatorDetails && data.CalculatorDetails.ProductType
      );
      const validProductTypeValue = !!(
        validProductType && data.CalculatorDetails.ProductType.Value
      );
      const validLoanAmount = !!(
        data.Summary && data.Summary.ProposedLoanAmount
      );
      const validSecurityAmount = !!(
        data.Summary && data.Summary.SecurityAmount
      );
      const loanAmount =
        (validLoanAmount && data.Summary.ProposedLoanAmount) || null;
      const security =
        (validSecurityAmount && data.Summary.SecurityAmount) || null;
      const productType = validProductTypeValue
        ? data.CalculatorDetails.ProductType.Value
        : '';

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const mapData = (object, value, key) => {
        if (value) {
          object[key] = value;
        }
      };

      if ($scope.loanStructureList) {
        $scope.loanStructureList.map((loanStructure) => {
          !loanStructure.getFilterDataList.LoanAmount &&
            mapData(loanStructure.getFilterDataList, loanAmount, 'LoanAmount');
          mapData(loanStructure.getFilterDataList, productType, 'ProductType');

          return loanStructure;
        });
      }

      !$scope.defaultSelection.loanAmount &&
        mapData($scope.defaultSelection, loanAmount, 'loanAmount');
      !$scope.defaultSelection.security &&
        mapData($scope.defaultSelection, security, 'security');

      $scope.setDefaultFilterSelection();
      if (!parseToInt10($scope.defaultSelection.LVR)) {
        $scope.calculateLvrOption();
        $scope.updateLVR();
      }
    });
};

export const getSelectedProductDetailsGetByLoanStructureMobile = (
  $scope,
  loanProfilerService,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => () => {
  $scope.selectedProductCount = 0;
  loanProfilerService
    .getSelectedProductDetailsGetByLoanStructure($scope.brokerEventId)
    .then((loanStructureResponse) => {
      loanStructureResponse.data.forEach((item) => {
        $scope.selectedProductCount += item.SelectedProduct.length;

        const tollTipState = item.SelectedProduct.find(
          (tooltipItem) => !tooltipItem.IsToolTipShown,
        );
        item.SelectedProduct = item.SelectedProduct.map((itemValue) => {
          itemValue.IsToolTipShownPopOver = false;
          return itemValue;
        });
        if (typeof tollTipState === 'undefined') {
          let isAllow = true;
          const recommendState = item.SelectedProduct.find(
            (tooltipItem) => tooltipItem.IsRecommended,
          );
          if (typeof recommendState === 'undefined') {
            item.SelectedProduct = item.SelectedProduct.map((itemValue) => {
              if (isAllow && itemValue.IsToolTipShown) {
                itemValue.IsToolTipShownPopOver = true;
                isAllow = false;
              }
              return itemValue;
            });
          }
        }
      });
      $scope.loanStructureWiseSelectedProductsMobile = [
        ...loanStructureResponse.data,
      ];
      if (typeof $scope.recommendInMobile !== 'undefined') {
        const itemMobile = $scope.itemMobileFn();
        if (typeof itemMobile !== 'undefined') {
          const itemMobileData = itemMobile.SelectedProduct.find((item) => {
            return (
              parseInt($scope.recommendInMobile.ProductID, 10) ===
              parseInt(item.ProductID, 10)
            );
          });
          if (typeof itemMobileData !== 'undefined') {
            $scope.recommendInMobile = itemMobileData;
          }
        }
      }
      const loanStructureData = $scope.itemMobileFn();
      const isProductSelected =
        $scope.selectedProductInMobile &&
        $scope.selectedProductInMobile.SelectedProduct &&
        $scope.selectedProductInMobile.SelectedProduct.length &&
        loanStructureData &&
        loanStructureData.SelectedProduct &&
        loanStructureData.SelectedProduct.length &&
        $scope.currentIndex;
      if (isProductSelected) {
        $scope.selectedProductInMobile.SelectedProduct[$scope.currentIndex] =
          loanStructureData.SelectedProduct[$scope.currentIndex];
      }
      $scope.slickConfig.enabled = true;
      $scope.isCompareDetail = true;
    });
};

export const setFavouriteProductDiscountRates = (
  $scope,
  loanProfilerService,
) => (
  loanStructureId,
  productId,
  value,
  year,
  rateType,
  isVariable,
  isMobile,
) => {
  const urlParams = {
    brokerEventId: $scope.brokerEventId,
    loanStructureId,
    productId,
  };
  const postData = {
    RateType: rateType,
    Value: value,
    Year: year,
  };
  loanProfilerService
    .setFavouriteProductDiscountRates(urlParams, postData)
    .then(({ succeeded }) => {
      if (!succeeded) {
        return;
      }
      if (isVariable) {
        $scope.setFavouriteProductDiscountRates(
          loanStructureId,
          productId,
          value,
          year,
          RATE_TYPES.INITIAL_RATE,
          false,
          isMobile,
        );
      }
      (isMobile &&
        $scope.getSelectedProductDetailsGetByLoanStructureMobile()) ||
        $scope.getSelectedProductDetailsGetByLoanStructure(true);
    });
};

export const setFavouriteProductFees = ($scope, loanProfilerService) => (
  loanStructureId,
  productId,
  value,
  feeType,
  isMobile,
) => {
  const urlParams = {
    brokerEventId: $scope.brokerEventId,
    loanStructureId,
    productId,
  };
  const postData = {
    FeesType: feeType,
    Value: value,
  };
  loanProfilerService
    .setFavouriteProductFees(urlParams, postData)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then(({ succeeded }) => {
      succeeded &&
        ((isMobile &&
          $scope.getSelectedProductDetailsGetByLoanStructureMobile()) ||
          $scope.getSelectedProductDetailsGetByLoanStructure(true));
    });
};

export const mapSelectedProducts = (itemValue) => {
  return {
    IsToolTipShownPopOver: false,
    TempNewInitialRate: itemValue.NewInitialRate || itemValue.InitialRate,
    TempNewInitialDiscountRate: parseFloat(
      typeof itemValue.NewInitialDiscountRate === 'undefined'
        ? itemValue.InitialDiscountRate
        : itemValue.NewInitialDiscountRate,
    ).toFixed(2),
    TempNewInitialDiscountYear:
      itemValue.NewInitialDiscountRateYear || itemValue.InitialDiscountRateYear,
    TempNewOngoingRate: itemValue.NewOngoingRate || itemValue.OngoingRate,
    TempNewOngoingDiscountRate: parseFloat(
      typeof itemValue.NewOngoingDiscountRate === 'undefined'
        ? itemValue.OngoingDiscountRate
        : itemValue.NewOngoingDiscountRate,
    ).toFixed(2),
    TempNewUpfrontFee: parseFloat(
      typeof itemValue.NewUpfrontFee === 'undefined'
        ? itemValue.UpFrontFee
        : itemValue.NewUpfrontFee,
    ).toFixed(2),
    TempNewOngoingPerMonthFee: parseFloat(
      typeof itemValue.NewOngoingPerMonthFee === 'undefined'
        ? itemValue.OngoingMonthlyFee
        : itemValue.NewOngoingPerMonthFee,
    ).toFixed(2),
    TempNewOngoingAnnualFee: parseFloat(
      typeof itemValue.NewOngoingAnnualFee === 'undefined'
        ? itemValue.AnnualFees
        : itemValue.NewOngoingAnnualFee,
    ).toFixed(2),
    ...itemValue,
  };
};

export const isInterestTermEnabled = (
  repaymentTypeValue,
  isAU,
  isProductFinderEditEnhancementsEnabled,
) => {
  return (
    (repaymentTypeValue === 'InterestInAdvance' ||
      repaymentTypeValue === 'IO' ||
      false) &&
    isAU &&
    isProductFinderEditEnhancementsEnabled
  );
};

export const setInterestTermValue = (
  isShowTermOfInterest,
  isProductFinderEditEnhancementsEnabled,
  valueToBeAssigned,
) => {
  const isInterestTerm = isShowTermOfInterest ? valueToBeAssigned : null;
  return isProductFinderEditEnhancementsEnabled ? isInterestTerm : null;
};

export const resetInterestTermValue = (currentValue) => {
  return currentValue || 1;
};

export const checkValidInterestTerm = (interestTermValue) => {
  return Number(interestTermValue) === 0 || !Number(interestTermValue);
};

export const setInterestTermValueOnSearch = (loanStructure) => {
  const interestTermDefaultValue = '1';
  if (!loanStructure.getFilterDataList.isShowTermOfInterest) {
    loanStructure.getFilterDataList.InterestTerm = interestTermDefaultValue;
  }
};

export const setProductFinderSelectRepaymentFrequencySetup = ({
  $scope,
  optionsService,
}) => {
  $scope.FINANCIAL_FREQUENCY_TEXT = FINANCIAL_FREQUENCY_TEXT;
  $scope.repaymentFrequencyList = [];

  if (!$scope.isProductFinderEditEnhancementsEnabled) {
    return;
  }
  $scope.loadFinancialRepaymentFrequency = () => {
    if (
      !$scope.repaymentFrequencyList ||
      !$scope.repaymentFrequencyList.length
    ) {
      const filterFrequencies = [
        FINANCIAL_FREQUENCY.WEEKLY,
        FINANCIAL_FREQUENCY.FORTNIGHTLY,
        FINANCIAL_FREQUENCY.MONTHLY,
      ];

      $scope.isLoadedRepaymentFrequencyList = true;
      optionsService.FinancialFrequency().then(({ data }) => {
        if (!data) {
          return;
        }

        const mappedFrequencyList = data.map((frequency) => {
          return {
            ...frequency,
            Value: parseToInt10(frequency.Value),
          };
        });
        $scope.repaymentFrequencyList = mappedFrequencyList.filter(
          (frequency) => filterFrequencies.includes(frequency.Value),
        );
      });
    }
  };
  !$scope.isLoadedRepaymentFrequencyList &&
    $scope.loadFinancialRepaymentFrequency();

  $scope.updateLoanStructureRepaymentFrequency = (
    loanStructureWiseSelectedProduct,
  ) => {
    const loanStructure = $scope.loanStructureList.find(
      (split) =>
        split.LoanStructureID ===
        loanStructureWiseSelectedProduct.LoanStructureID,
    );
    const { getFilterDataList: splitFilters } = loanStructure;

    loanStructure.getFilterDataList = {
      ...splitFilters,
      RepaymentFrequency: loanStructureWiseSelectedProduct.RepaymentFrequency,
    };

    $scope.updateFilterData(loanStructure, false);
    $scope.setSelectedProductsVaryingRepayment(
      loanStructureWiseSelectedProduct,
      loanStructureWiseSelectedProduct.RepaymentFrequency,
    );
  };

  $scope.setProductRepaymentDefaultFrequency = () => {
    const findLoanStructure = (loanStructureId) =>
      $scope.loanStructureList.find(
        (split) => split.LoanStructureID === loanStructureId,
      );

    $scope.loanStructureWiseSelectedProducts.forEach((loanSelected) => {
      const loanStructure = findLoanStructure(loanSelected.LoanStructureID);
      loanSelected.RepaymentFrequency =
        loanStructure.getFilterDataList.RepaymentFrequency ||
        FINANCIAL_FREQUENCY.MONTHLY;

      $scope.setSelectedProductsVaryingRepayment(
        loanSelected,
        loanSelected.RepaymentFrequency,
      );
    });
  };

  $scope.setSelectedProductsVaryingRepayment = (
    loanSelected,
    repaymentFrequency = FINANCIAL_FREQUENCY.MONTHLY,
  ) => {
    $scope.setProductRepaymentBasedOnFrequency({
      repaymentInitialReference: 'RepaymentMonthlyInitial',
      repaymentOngoingReference: 'RepaymentMonthlyOngoing',
      repaymentFrequency,
      loanSelected,
    });
  };

  $scope.setProductRepaymentBasedOnFrequency = ({
    repaymentInitialReference,
    repaymentOngoingReference,
    repaymentFrequency,
    loanSelected,
  }) => {
    const setVariableRepayments = (repaymentReference, product) => {
      const key = `${repaymentReference}Variable`;
      product[key] = $scope.getRepaymentForSelectedFrequency(
        product[repaymentReference],
        repaymentFrequency,
      );
    };

    loanSelected.SelectedProduct.forEach((product) => {
      setVariableRepayments(repaymentInitialReference, product);
      setVariableRepayments(repaymentOngoingReference, product);
    });
  };

  $scope.getRepaymentForSelectedFrequency = (monthlyRepayment, frequency) => {
    const calculation =
      (monthlyRepayment * 12) / FINANCIAL_REPAYMENT_FREQUENCY[frequency];
    return parseFloat(calculation.toFixed(2));
  };
};

export const isSortableColumn = (loanStructure, $index) => {
  const sortByLenderInitialRateColIndex = 4;
  return (
    !loanStructure.IsSearchByLender ||
    (loanStructure.IsSearchByLender &&
      $index === sortByLenderInitialRateColIndex)
  );
};
