import { LOAN_TERM } from 'Common/constants/assets';

export const setToggleValue = (obj, key) => {
  if (!obj || !key) {
    return;
  }
  obj[key] = !obj[key];
};

export const setPrincipaInterest = (obj) => {
  if (!obj) {
    return;
  }
  obj.PrincipalInterest =
    obj.PrincipalInterest === LOAN_TERM.PRINCIPAL_INTEREST
      ? LOAN_TERM.INTEREST_ONLY
      : LOAN_TERM.PRINCIPAL_INTEREST;
};

export const triggerDropdown = ($scope) => (key, value = true) => {
  if (!$scope.dropdown || !key) {
    return;
  }
  $scope.dropdown[key] = value;
};

export const selectExistingAddress = ($scope) => (formattedAddress = '') => {
  if (!$scope.AssetsSet.FullAddress) {
    $scope.AssetsSet.FullAddress = {};
  }
  $scope.AssetsSet.FullAddress.formatted_address = formattedAddress;
  $scope.dropdown.existingAddress = false;
};

export const selectRepayment = ($scope) => (obj, value) => {
  $scope.dropdown.repayment = false;
  obj.RepaymentFrequencyId = value;
};

export const initializeAssetsDependents = ($scope, currentUserService = {}) => {
  $scope.dropdown = {
    existingAddress: false,
    repayment: false,
  };

  $scope.setToggleValue = setToggleValue;
  $scope.setPrincipaInterest = setPrincipaInterest;
  $scope.triggerDropdown = triggerDropdown($scope);
  $scope.selectExistingAddress = selectExistingAddress($scope);
  $scope.selectRepayment = selectRepayment($scope);

  $scope.formatYearMakeType = ({ Make, Year, AssetSubtypeId, Description }) => {
    const { Name } = $scope.subAssetTypes.find(
      (types) => types.Value === AssetSubtypeId,
    ) || { Name: false };
    const isSatisfiedAssets = Year && Make && Name && !!currentUserService.isNZ;
    isSatisfiedAssets &&
      (() => {
        const concatFormattedType = `${Year} ${Make} (${Name})`;
        if (!Description) {
          $scope.AssetsSet.Description = concatFormattedType;
          return;
        }
        if (Description) {
          $scope.AssetsSet.Description = `${concatFormattedType}${$scope.assetsDescription}`;
        }
      })();
  };

  $scope.placeHolderYearMakeType = () => {
    $scope.AssetsSet &&
      currentUserService.isNZ &&
      (() => {
        const { Make, Year, AssetSubtypeId, Description } = $scope.AssetsSet;
        const { Name } = $scope.subAssetTypes.find(
          (types) => types.Value === AssetSubtypeId,
        ) || { Name: '' };
        $scope.concatFormattedType = `${Year} ${Make} (${Name})`;
        $scope.assetsDescription = Description.replace(
          $scope.concatFormattedType,
          '',
        );
      })();
  };
};
