import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'MobileFamilyFilterModalCtrl',
      function MobileFamilyFilterModalCtrl($scope, $uibModalInstance) {
        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
      },
    );
