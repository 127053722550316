import template from './corpPrintSummary.html';
import controller from './corpPrintSummaryCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    brokerBrandingId: '<',
  },
};
