import { AGREEMENT_TYPE } from 'Common/constants/agreementType';
import { notesBuilderForUI } from 'Common/mappers/loanOpportunity';

class NoteService {
  constructor(
    currentUserService,
    corporateService,
    rayWhiteService,
    contactService,
    modalRenderService,
    uiService,
    configService,
    referralModelService,
    $uibModal,
  ) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.corporateService = corporateService;
    this.rayWhiteService = rayWhiteService;
    this.contactService = contactService;
    this.modalRenderService = modalRenderService;
    this.uiService = uiService;
    this.configService = configService;
    this.referralModelService = referralModelService;
    this.$uibModal = $uibModal;
    this.action = {
      refreshNoteList: () => {},
    };
  }

  getReferrerInfo(referrerId, agreementTypeId) {
    if (!referrerId) {
      return null;
    }
    if (agreementTypeId === AGREEMENT_TYPE.RAY_WHITE) {
      return this.rayWhiteService.getMembers(referrerId);
    }
    return this.corporateService
      .corporateContactGet(0, referrerId)
      .then(({ data }) => {
        if (!data) {
          return null;
        }
        return data;
      });
  }

  getReferrerDetails(familyId) {
    return this.contactService
      .contactFamilyInfoGet(familyId)
      .then(({ data }) => {
        if (!data) {
          return null;
        }
        const { ReferrerID, ReferrerAgreementTypeId, AdviserFullName } = data;
        this.adviserFullName = AdviserFullName;
        return this.getReferrerInfo(ReferrerID, ReferrerAgreementTypeId);
      });
  }

  getAssignedAdviserDetails(familyId, props) {
    return this.contactService
      .getContactAssignedAdviser(familyId)
      .then(({ data }) => {
        if (!data) {
          return props;
        }
        return this.corporateService.familyBrokerSupportGet(data);
      })
      .then(({ data }) => {
        if (!data) {
          return props;
        }
        return {
          ...props,
          isSmsEnabled: data.SMSEnabled,
        };
      });
  }

  emailDefaultObject(referrerInfo, props) {
    const emailSubj = `New Note Created for ${referrerInfo.FirstName} ${referrerInfo.LastName}`;
    return {
      subject: emailSubj,
      referrerEmail: referrerInfo.Email,
      body: props.emailBody || '',
      replyTo: {
        EmailAddress: this.currentUserService.email,
      },
      sentDate: new Date(),
      recipients: [
        {
          EmailAddress: referrerInfo.Email,
        },
      ],
    };
  }

  setUpEmailAndSmsObj({ notesData, referrerInfo, props, clientName }) {
    const smsObj = {
      mobile: referrerInfo.Mobile,
      body: props.smsBody || '',
    };
    const emailObj = { ...this.emailDefaultObject(referrerInfo, props) };
    const isSmsEnabled = this.currentUserService.sMSEnabled || false;
    const referralId = referrerInfo.FamilyId || 0;
    const referrerShareEmail = !!props.emailBody;
    const referrerShareSms = !!props.smsBody;
    const noteObj = {
      ...notesData,
      emailObj,
      smsObj,
      referrerShareEmail,
      referrerShareSms,
      clientName,
      brokerName: this.adviserFullName || '',
    };
    return {
      ...props,
      isSmsEnabled,
      referralId,
      noteObj,
    };
  }

  openNoteModal(props) {
    props.isNotOpportunityModule =
      typeof props.isNotOpportunityModule === 'undefined'
        ? true
        : props.isNotOpportunityModule;
    const modalInstance = this.modalRenderService.openNoteModal(props);
    if (typeof this.onModalClose !== 'function') {
      return modalInstance.result;
    }
    modalInstance.result.then((note) => {
      this.onModalClose({ note });
    });
    return modalInstance.result;
  }

  addNewNote(args = {}) {
    const {
      familyId,
      brokerBrandingId,
      clientFullName,
      isClient,
      onModalClose,
    } = args;
    this.onModalClose = onModalClose;
    const notes = {
      FamilyID: familyId || 0,
      BrokerBrandingID: brokerBrandingId || 0,
    };
    const notesData = notesBuilderForUI(notes);
    const props = {
      isAdd: true,
      noteObj: notesData,
      referralId: 0,
      isSmsEnabled: false,
      emailBody: '',
      smsBody: '',
    };
    if (!isClient || !familyId) {
      return this.openNoteModal(props);
    }

    return this.getReferrerDetails(familyId).then((referrerInfo) => {
      if (!referrerInfo) {
        return this.openNoteModal(props);
      }
      const propsWithEmailSmsData = this.setUpEmailAndSmsObj({
        notesData,
        referrerInfo,
        props,
        clientName: clientFullName || '',
      });
      if (this.currentUserService.isCorporate) {
        return this.openNoteModal(propsWithEmailSmsData);
      }
      return this.getAssignedAdviserDetails(
        familyId,
        propsWithEmailSmsData,
      ).then((propsWithAdviserDetails) => {
        return this.openNoteModal(propsWithAdviserDetails);
      });
    });
  }

  getClientNames(clients = []) {
    return clients.reduce((accum, client) => {
      return accum ? `${accum}, ${client.fullName}` : client.fullName;
    }, '');
  }

  newOpportunityNotesModal(data = {}) {
    const { card: note = {}, familyId: familyIdParam } = data;
    const { loanId, familyId, familyInfo } = note;
    const [clientDetails = {}] = familyInfo || [];
    const clientFamilyId = familyId || clientDetails.familyId || familyIdParam;
    const clientName = this.getClientNames(familyInfo);

    return this.contactService
      .getContactAssignedAdviser(clientFamilyId)
      .then(({ data: adviserId = 0 }) => {
        if (!loanId) {
          return { succeeded: false, adviserId };
        }
        return this.referralModelService
          .getReferrerContactInfo(loanId)
          .then(({ succeeded, data: referrerInfo }) => {
            return { adviserId, succeeded, referrerInfo };
          });
      })
      .then(({ adviserId, succeeded, referrerInfo }) => {
        if (!succeeded || !referrerInfo) {
          return { adviserId };
        }
        const smsObj = {
          mobile: referrerInfo.mobile,
          body: '',
        };
        const subject = clientName
          ? `New Note Created for ${clientName}`
          : 'New Note';
        const emailObj = {
          recipients: [{ EmailAddress: referrerInfo.email }],
          subject,
          referrerEmail: referrerInfo.email,
        };

        return { smsObj, emailObj, referralId: referrerInfo.id, adviserId };
      })
      .then(({ smsObj = {}, emailObj = {}, referralId = 0, adviserId = 0 }) => {
        const noteObj = {
          ...note,
          loanId,
          familyId: clientFamilyId,
          adviserId,
          emailObj,
          smsObj,
          clientName,
          brokerName: note.adviserFullName,
        };
        const props = {
          noteObj,
          isAdd: !note,
          referralId,
          isSmsEnabled: this.currentUserService.sMSEnabled,
          isNotOpportunityModule: false,
        };

        return this.openNoteModal(props);
      });
  }
}

export default NoteService;
