export const disableChildren = ({ scope, elem, attrs }) => (disable) => {
  const invalidParams = !scope || !elem || !elem.length || !attrs;
  if (invalidParams) {
    return;
  }
  const attribute = attrs.childrenTabSelectors;

  if (!attribute) {
    return;
  }
  const tabIndexValue = disable === 'true' ? -1 : 0;
  const childrenSelectors = scope[attribute];
  childrenSelectors.forEach((selector) => {
    const childElement = elem[0].querySelector(selector);
    childElement && childElement.setAttribute('tabindex', tabIndexValue);
  });
  return tabIndexValue;
};
