import template from './settlementVolumes.html';
import controller from './settlementVolumesCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    myParam: '<',
  },
};
