export const ACCESS_TYPE = {
  PRINCIPAL_ADVISER: 1,
  ADVISER: 2,
  ADMIN_ASSISTANT: 3,
  ASSISTANT: 4,
  CORPORATE: 5,
  REFERRER: 9,
};

export const ACCESS_TYPE_TEXT = {
  ADMIN_ASSISTANT: 'Admin Assistant',
  ASSISTANT: 'Assistant',
};

export const CORP_ACCESS_TYPE = {
  PROFILE_ADMIN: 1,
  STANDARD: 2,
};

export default { CORP_ACCESS_TYPE, ACCESS_TYPE };

export const TEAM_ACCESS_LEVEL = {
  EMAIL_ONLY: 1,
  FULL_SYSTEM: 2,
  MYCRM_NO_CHARGE: 3,
};

export const DROPDOWN_DISPLAY = [
  ACCESS_TYPE.ADVISER,
  ACCESS_TYPE.PRINCIPAL_ADVISER,
  ACCESS_TYPE.ASSISTANT,
  ACCESS_TYPE.ADMIN_ASSISTANT,
];
