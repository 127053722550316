import { parseToInt10 } from 'Common/utilities/parse';
import {
  QUESTION_OBJECT_DEFAULT,
  CHOSEN_LOAN_PURPOSE,
  QUESTION_LIST,
  HANDOVER_PACK_REQUIRED,
} from './util/statementOfAdvice';

export default class StatementOfAdvice {
  constructor(loanApplicationServices, loanAppSharedData) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.loanAppSharedData = loanAppSharedData;
  }

  $onInit() {
    this.validToGenerateHandoverPack = false;
    this.QUESTION_LIST = QUESTION_LIST;
    this.isCompleted = true;
    this.questionObject = QUESTION_OBJECT_DEFAULT();
    this.toShowRefinanceOrDebtIds = Object.keys(CHOSEN_LOAN_PURPOSE).map(
      (key) => {
        return CHOSEN_LOAN_PURPOSE[key];
      },
    );
    this.getStatementOfAdviceAnswer();
  }

  getStatementOfAdviceAnswer() {
    this.loanApplicationServices
      .getStatementOfAdviceAnswer({ LoanApplicationId: this.loanAppId })
      .then((result) => {
        if (!result) {
          return;
        }
        result.map((question) => {
          this.questionObject[question.questionId] = {
            ...this.questionObject[question.questionId],
            ...question,
          };
        });

        this.validateToGenerateHandover();
      });
  }

  postStatementOfAdviceAnswer(questionObject) {
    if (!questionObject) {
      return;
    }
    this.loanApplicationServices.setStatementOfAdviceAnswer({
      ...questionObject,
      loanApplicationId: this.loanAppId,
    });

    this.validateToGenerateHandover();
  }

  isShowRefinanceOrDebt() {
    const loanPurpose = this.loanAppSharedData.getLoanPurpose();
    return loanPurpose.some((purpose) => {
      return this.toShowRefinanceOrDebtIds.includes(purpose.Value);
    });
  }

  validateToGenerateHandover() {
    const modifiedPackRequiredList = {
      ...HANDOVER_PACK_REQUIRED,
      ...(this.isShowRefinanceOrDebt() && {
        [QUESTION_LIST.GREATER_THAN_CURRENT_ARRANGEMENTS]: false,
      }),
    };
    const isValidToGenerate = Object.keys(modifiedPackRequiredList).every(
      (key) => {
        const value = modifiedPackRequiredList[key];
        const questionValue = this.questionObject[key].answerBool;
        if (typeof questionValue === 'boolean') {
          if (value === questionValue) {
            return true;
          } else {
            const correspondingMoreDetailKey = parseToInt10(key) + 1;
            return !!this.questionObject[correspondingMoreDetailKey]
              ?.answerString;
          }
        } else {
          return false;
        }
      },
    );

    this.loanAppSharedData.setValidToGenerateHandover(isValidToGenerate);
  }

  isSectionCompleted() {
    const declarationCompliedCompleted =
      this.questionObject[this.QUESTION_LIST.DECLARATION_COMPLIED_LENDER]
        .answerBool ||
      (!this.questionObject[this.QUESTION_LIST.DECLARATION_COMPLIED_LENDER]
        .answerBool &&
        this.questionObject[
          this.QUESTION_LIST.DECLARATION_COMPLIED_LENDER_MORE_DETAILS
        ].answerString);
    const declarationDiscussedLoansCompleted =
      this.questionObject[this.QUESTION_LIST.DECLARATION_DISCUSSED_PRODUCTS]
        .answerBool ||
      (!this.questionObject[this.QUESTION_LIST.DECLARATION_DISCUSSED_PRODUCTS]
        .answerBool &&
        this.questionObject[
          this.QUESTION_LIST.DECLARATION_DISCUSSED_PRODUCTS_MORE_DETAILS
        ].answerString);
    const extensiveEnquiriesCompleted =
      this.questionObject[this.QUESTION_LIST.NEED_EXTENSIVE_INQUIRIES]
        .answerBool === false ||
      (this.questionObject[this.QUESTION_LIST.NEED_EXTENSIVE_INQUIRIES]
        .answerBool &&
        this.questionObject[
          this.QUESTION_LIST.NEED_EXTENSIVE_INQUIRIES_MORE_DETAILS
        ].answerString);
    const applicantAdoptLoanCompleted =
      this.questionObject[this.QUESTION_LIST.ADOPT_LOAN_STRUCTURE]
        .answerBool === false ||
      (this.questionObject[this.QUESTION_LIST.ADOPT_LOAN_STRUCTURE]
        .answerBool &&
        this.questionObject[
          this.QUESTION_LIST.ADOPT_LOAN_STRUCTURE_MORE_DETAILS
        ].answerString);
    const refinanceOfDebtCompleted =
      !this.isShowRefinanceOrDebt() ||
      (this.isShowRefinanceOrDebt() &&
        (this.questionObject[
          this.QUESTION_LIST.GREATER_THAN_CURRENT_ARRANGEMENTS
        ].answerBool === false ||
          (this.questionObject[
            this.QUESTION_LIST.GREATER_THAN_CURRENT_ARRANGEMENTS
          ].answerBool &&
            this.questionObject[
              this.QUESTION_LIST.GREATER_THAN_CURRENT_ARRANGEMENTS_MORE_DETAILS
            ].answerString)));

    return (
      declarationCompliedCompleted &&
      declarationDiscussedLoansCompleted &&
      extensiveEnquiriesCompleted &&
      applicantAdoptLoanCompleted &&
      refinanceOfDebtCompleted
    );
  }
}
