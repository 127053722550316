import { pdfBuilderForUI } from 'Common/mappers/document';

export default class CorpActionsAPIService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'corporate';
  }

  getPrintSummary(familyId) {
    const url = `${this.apiBaseUrl}/print-summary?familyId=${familyId}`;
    return this.httpClient.get(url).then(({ data }) => pdfBuilderForUI(data));
  }
}
