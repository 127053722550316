export const copyToClipboard = (windowObj, textToCopy) => {
  const copyElement = windowObj.document.createElement('textarea');
  copyElement.style.position = 'fixed';
  copyElement.style.opacity = '0';
  copyElement.textContent = textToCopy;
  const body = windowObj.document.querySelectorAll('body')[0];
  body.append(copyElement);
  copyElement.select();
  windowObj.document.execCommand('copy');
  copyElement.remove();
};
