import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import {
  toastError,
  toastWarning,
  displayWarning,
} from 'Common/utilities/alert';
import { paymentFrequenciesForUI } from 'Common/utilities/insurance';
import {
  OLD_BENEFIT_TYPE,
  INSURANCE_TYPES,
  IS_LOADING_EXCLUSION,
  WAIT_PERIOD,
} from 'Common/constants/insuranceOptions';
import {
  otherPolicyOwnership,
  defaultInsuranceDetails,
} from 'Common/default/insurance';
import {
  DEFERRED_STATUS_ID,
  NOT_PROCEEDED_ID,
  PROVIDER_ID,
  OTHER,
} from 'Common/constants/insuranceApplicationPipeline';
import {
  NZ_DATE_INPUT_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';
import {
  editDataSetUtil,
  addLoadingExclusionUtil,
  getLoadingListUtil,
  onInsuranceSavedUtil,
  frequencyDisableAutoCalc,
  commencementDate,
} from './util/insuranceExistingModalCtrl';

import { onOtherBenefitSelected } from './util/autoCalcModeOther';

const mapPipelineStatusesForModal = (statuses) => {
  return (
    statuses &&
    statuses.map((status) => ({ StatusID: status.id, StatusName: status.name }))
  );
};

const mapGroupedBenefitTypes = (types) => {
  return (
    types &&
    types.map((type) => {
      return {
        BenefitTypeName: type.name,
        BenefitTypeID: type.oldId,
        InsuranceType: type.groupName,
        QPRBenefitID: type.id,
      };
    })
  );
};

const policyOwnership = (owner, selectedInsuredPerson, quoteId) => {
  return {
    quoteId,
    clientId: owner.PersonId,
    isDependent: owner.Role !== 'Adult',
    isOwnership: owner.Role === 'Adult',
    fullName: owner.FullName,
    isInsured: owner.PersonId === selectedInsuredPerson,
  };
};

const clientBenefitsHealthCover = (client) => {
  return {
    clientId: client.PersonId,
    fullName: client.FullName,
    role: client.Role,
    isBaseCover: false,
    loading: 0,
    excess: '',
    isSpecialistsTest: false,
    isGpPrescriptions: false,
    isDentalOptical: false,
  };
};

export const loadModule = () =>
  angular.module('app').controller(
    'InsuranceExistingModalCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function InsuranceExistingModalCtrl(
      $scope,
      $timeout,
      $uibModalInstance,
      $state,
      contactInsuranceService,
      currentUserService,
      configService,
      pipelineCardsService,
      insurancePipelineService,
      items,
      insuranceQuoteService,
    ) {
      /*
    Table of Contents
    1. MODELS
    2. Functions
    3. METHODS
    4. Shared Data
    */

      // 1. MODELS
      commencementDate({ $scope });
      $scope.isFrequencyDisableAutoCalc = false;
      $scope.isCommencementRequired = false;
      $scope.DEFERRED_STATUS_ID = DEFERRED_STATUS_ID.V1;
      $scope.NOT_PROCEEDED_ID = NOT_PROCEEDED_ID.V1;

      $scope.items = items;
      $scope.currentUserService = currentUserService;
      $scope.configService = configService;
      $scope.pipelineCardsService = pipelineCardsService;

      $scope.formData = {
        isSaving: false,
        isEditing: false,
        hasExcess: false,
      };
      $scope.TRAUMA_COVERS = INSURANCE_TYPES.TRAUMA_COVERS;
      $scope.OLD_BENEFIT_TYPE = OLD_BENEFIT_TYPE;
      $scope.PROVIDER_ID = PROVIDER_ID;
      $scope.invalidDateText = 'Invalid date';

      const tempInsuranceDetailsInput = defaultInsuranceDetails(
        $state.params.familyId,
      );

      insuranceQuoteService
        .insuranceQuoteFrequencyGet(true)
        .then(({ data }) => {
          const frequencyList = paymentFrequenciesForUI(data);
          $scope.frequencyList = [
            { name: 'Select Frequency', value: '' },
            ...frequencyList,
          ];
          $scope.init();
        });

      const tempFPBenefitPeriodList = [
        { benefitPeriodID: '', Description: 'Select Period', Value: '' },
        { benefitPeriodID: 1, Description: '1 Year', Value: 1 },
        { benefitPeriodID: 2, Description: '2 Years', Value: 2 },
        { benefitPeriodID: 3, Description: '5 Years', Value: 3 },
        { benefitPeriodID: 4, Description: 'To Age 65', Value: 4 },
        { benefitPeriodID: 5, Description: 'To Age 70', Value: 5 },
      ];

      const benefitPeriodListGet = () => {
        contactInsuranceService
          .insuranceQuoteIPBenefitPeriodGet()
          .then((response) => {
            if (response.data) {
              $scope.benefitPeriodList = [
                { Key: 'Select benefit period', Value: '' },
                ...response.data,
              ];
            }
          });
      };

      const getLoadingList = getLoadingListUtil({
        $scope,
        contactService: contactInsuranceService,
      });

      const weekWaitPeriodListGet = () => {
        contactInsuranceService
          .insuranceQuoteWeekWaitPeriodGet()
          .then((response) => {
            if (response.data) {
              $scope.weekWaitPeriodList = [
                { Key: 'Select wait period', Value: '' },
                ...response.data,
              ];
            }
          });
      };

      const insuranceQuoteExcessGet = () => {
        contactInsuranceService.insuranceQuoteExcessGet().then((response) => {
          if (response.data) {
            $scope.excessList = [
              { Key: 'Select Excess', Value: '' },
              ...response.data,
            ];
          }
        });
      };

      const appInfoGet = () => {
        const { quoteId, familyId, providerId, paymentFrequency } = items.data;
        $scope.quoteId = quoteId;
        $scope.DEFERRED_STATUS_ID = quoteId
          ? DEFERRED_STATUS_ID.V3
          : DEFERRED_STATUS_ID.V1;
        $scope.NOT_PROCEEDED_ID = quoteId
          ? NOT_PROCEEDED_ID.V3
          : NOT_PROCEEDED_ID.V1;
        $scope.onChangeStatus();

        $scope.familyId = familyId;
        $scope.providerId = providerId;
        $scope.insuranceDetailsInput.providerId = providerId;
        $scope.insuranceDetailsInput.paymentFrequency = paymentFrequency;
      };

      angular.extend($scope, {
        benefitTypeList: [],
        insuranceModalTab: items.defaultTab ?? 'overview',
        insuranceDetailsInput: tempInsuranceDetailsInput,
        excessList: [],
        clientId: null,
        familyAdultList: [],
        familyList: [],
        selectedInsuredPerson: '',
        insurersList: [],
        productList: [],
        statusList: [],
        coverTypeList: [],
        loadingList: [],
        insuranceCalcPeriodList: [],
        fpBenefitPeriodList: tempFPBenefitPeriodList,
        benefitPeriodList: [],
        weekWaitPeriodList: [],
      });

      // 2. Functions
      function checkDate() {
        const dateChecker = {
          countInvalid: 0,
          message: 'Commencement Date is invalid.',
        };

        const formattedDate = moment(
          $scope.insuranceDetailsInput.tempCommencementDate,
          NZ_DATE_INPUT_FORMAT,
        ).format(TO_MYCRM_DATE_FORMAT);

        if (
          $scope.isCommencementRequired &&
          formattedDate === $scope.invalidDateText
        ) {
          dateChecker.countInvalid++;
          dateChecker.message = 'Commencement Date is invalid.';
        }

        const deferralReviewDate = moment(
          $scope.insuranceDetailsInput.tempDeferralReviewDate,
          NZ_DATE_INPUT_FORMAT,
        ).format(TO_MYCRM_DATE_FORMAT);

        if (
          $scope.insuranceDetailsInput.statusId === $scope.DEFERRED_STATUS_ID &&
          deferralReviewDate === $scope.invalidDateText
        ) {
          dateChecker.countInvalid++;
          dateChecker.message = 'Deferral Review Date is invalid.';
        }

        return dateChecker;
      }

      function insuranceDetailsSet() {
        $scope.insuranceDetailsInput = $scope.formatInput(
          $scope.insuranceDetailsInput,
        );

        contactInsuranceService
          .insuranceDetailsSet($scope.insuranceDetailsInput)
          .then(
            onInsuranceSavedUtil({
              $scope,
              $uibModalInstance,
              familyId: $scope.insuranceDetailsInput.familyId,
              statusId: $scope.insuranceDetailsInput.statusId,
            }),
          );
      }

      function insuranceProductGet(initCall = true) {
        const { providerId, benefitTypeId } = $scope.insuranceDetailsInput;
        if (!providerId || !benefitTypeId) {
          return;
        }

        const defaultProviderId = $scope.providerId || providerId;
        contactInsuranceService
          .insuranceProductGet(defaultProviderId, benefitTypeId)
          .then(({ data = [] }) => {
            $scope.productList = [
              { ProductName: 'Select product', ProductID: '' },
              ...data,
            ];

            $scope.autoCalcMode(initCall)();
          });
      }

      function insuranceCalcPeriodGet() {
        contactInsuranceService
          .insuranceCalculationPeriodGet()
          .then((response) => {
            response.data.unshift({
              Key: 'Select calculation period',
              Value: '',
            });
            $scope.insuranceCalcPeriodList = response.data;
          });
      }

      function insuranceQuoteProviderGet() {
        const { providerId, benefitTypeId } = $scope.insuranceDetailsInput;
        contactInsuranceService
          .insuranceExistingProviderGet(providerId, benefitTypeId)
          .then((response) => {
            response.data.unshift({
              ProviderName: 'Select Insurer',
              ProviderId: '',
            });

            const isFidelity = (providerIdFidelity) => {
              return (
                providerIdFidelity === INSURANCE_TYPES.FIDELITY_LIFE ||
                providerIdFidelity === INSURANCE_TYPES.FIDELITY_HEALTH
              );
            };

            if ($scope.providerId === INSURANCE_TYPES.FIDELITY_NIB) {
              $scope.insurersList = response.data.filter((provider) =>
                isFidelity(provider.ProviderId),
              );
              $scope.insuranceDetailsInput.providerId =
                $scope.insurersList[0].ProviderId;
            } else {
              $scope.insurersList = $scope.providerId
                ? response.data.filter(
                    (provider) => provider.ProviderId === $scope.providerId,
                  )
                : response.data;
            }
            setProviderName($scope.providerId);
          });
      }

      function setProviderName(providerId) {
        if (providerId) {
          const provider = $scope.insurersList.find((insurer) => {
            return insurer.ProviderId === Number(providerId);
          });
          $scope.insuranceDetailsInput.providerName = provider?.ProviderName;
        }
      }

      function insuranceBenefitsGet() {
        const { providerId } = $scope.insuranceDetailsInput;
        const promise = $scope.quoteId
          ? insurancePipelineService.getInsuranceBenefits(providerId)
          : contactInsuranceService.insuranceBenefitTypesGet();

        promise.then((response) => {
          const types = $scope.quoteId
            ? mapGroupedBenefitTypes(response)
            : response.data;
          $scope.benefitTypeList = [
            {
              BenefitTypeName: 'Select benefit',
              BenefitTypeID: '',
              InsuranceType: '',
              QPRBenefitID: null,
            },
            ...types,
          ].filter(
            (benefit) => benefit.BenefitTypeID !== WAIT_PERIOD.TWENTY_SIX_WEEKS,
          );

          if ($scope.quoteId) {
            const selectedBenefitType = $scope.benefitTypeList.find(
              (benefit) =>
                benefit.QPRBenefitID &&
                benefit.QPRBenefitID === items.data.benefitTypeId,
            );
            $scope.insuranceDetailsInput.benefitTypeId = selectedBenefitType
              ? selectedBenefitType.BenefitTypeID
              : '';
          }
          insuranceQuoteProviderGet();
          insuranceProductGet();
        });
      }

      function insuranceStatusGet() {
        const promise = $scope.quoteId
          ? insurancePipelineService
              .getInsurancePipelineStatus()
              .then((data) => mapPipelineStatusesForModal(data))
          : contactInsuranceService.insuranceStatusGet();

        promise.then((response) => {
          $scope.statusList = [
            { StatusName: 'Select Status', StatusID: '' },
            ...(response.data ? response.data : response),
          ];
        });
      }

      function iPCoverTypeGet() {
        contactInsuranceService
          .insuranceQuoteIPCoverTypeGet()
          .then((response) => {
            response.data.unshift({ Key: 'Select cover type', Value: '' });
            $scope.coverTypeList = response.data;
          });
      }

      const initOwnershipForExistingInsurance = () => {
        $scope.insuranceDetailsInput.policyOwnership = $scope.familyAdultList
          .reduce((accum, adult) => {
            return accum.concat(
              policyOwnership(
                adult,
                $scope.selectedInsuredPerson,
                $scope.quoteId,
              ),
            );
          }, [])
          .concat(otherPolicyOwnership());
      };

      const initOwnershipForApplication = () => {
        insurancePipelineService
          .getInsurancePolicyOwnership($scope.quoteId)
          .then((response) => {
            $scope.insuranceDetailsInput.policyOwnership = response.map(
              (ownership) => {
                return { ...ownership, isOwnership: !ownership.isDependent };
              },
            );
          });
      };

      const addPolicyOwnership = () => {
        if (!$scope.formData.isEditing) {
          if ($scope.quoteId) {
            initOwnershipForApplication();
          } else {
            initOwnershipForExistingInsurance();
          }
        } else {
          const { policyOwnership: poList } = $scope.insuranceDetailsInput;
          $scope.insuranceDetailsInput.policyOwnership = poList.map((po) => {
            if (po.clientId) {
              const adult = $scope.familyAdultList.find((member) => {
                return parseInt(member.PersonId, 10) === po.clientId;
              });

              return {
                ...po,
                isDependent: false,
                fullName: (adult && adult.FullName) || po.fullName,
                isInsured:
                  adult && adult.PersonId === $scope.selectedInsuredPerson,
              };
            }
            return {
              ...po,
              isDependent: true,
              fullName: 'Other',
              isInsured: !!po.other,
            };
          });
        }
      };

      const addLoadingExclusion = addLoadingExclusionUtil({ $scope });

      const addClientBenefitsHealthCover = () => {
        const { benefits } = $scope.insuranceDetailsInput;
        if (!$scope.formData.isEditing) {
          benefits.healthCover = $scope.familyList.reduce((accum, member) => {
            return accum.concat(clientBenefitsHealthCover(member));
          }, []);
        } else {
          benefits.healthCover = !$scope.quoteId
            ? $scope.getExistingHealthCover()
            : $scope.getApplicationHealthCover();
        }
      };

      $scope.onChangeStatus = () => {
        $scope.isCommencementRequired =
          !$scope.quoteId &&
          $scope.insuranceDetailsInput.statusId !== $scope.DEFERRED_STATUS_ID &&
          $scope.insuranceDetailsInput.statusId !== $scope.NOT_PROCEEDED_ID;
        $scope.isInforce();
      };

      $scope.getExistingHealthCover = () => {
        const healthCover =
          $scope.insuranceDetailsInput.benefits.healthCover || [];
        return $scope.familyList.reduce((accum, member) => {
          const memberHealthCover = healthCover.find(
            (cover) => cover.clientId === parseInt(member.PersonId, 10),
          );
          return memberHealthCover
            ? accum.concat({
                ...memberHealthCover,
                fullName: member.FullName,
                role: member.Role,
              })
            : accum.concat(clientBenefitsHealthCover(member));
        }, []);
      };

      $scope.vitalityDiscountChange = (vitalityDiscountSelected) => {
        $scope.insuranceDetailsInput.vitalityFeeSelected = vitalityDiscountSelected
          ? $scope.insuranceDetailsInput.vitalityFeeSelected
          : false;
      };

      $scope.getApplicationHealthCover = () => {
        const healthCover =
          $scope.insuranceDetailsInput.benefits.healthCover || [];
        return (
          healthCover &&
          healthCover.map((cover) => {
            const role = cover.isChild ? 'Child' : 'Adult';
            return { ...cover, role };
          })
        );
      };

      function clientInformGet() {
        const familyId = $scope.familyId || $scope.$state.params.familyId;
        contactInsuranceService
          .clientInformGet(familyId, $scope.clientId)
          .then((response) => {
            $scope.familyList = response.data;

            if ($scope.familyList.length >= 1) {
              $scope.familyAdultList = _.filter($scope.familyList, (o) => {
                if (o.Role === 'Adult') {
                  return o;
                }
              });

              if ($scope.familyAdultList.length >= 1) {
                $scope.selectedInsuredPerson =
                  $scope.familyAdultList[0].PersonId;
              }

              insuranceBenefitsGet();
              insuranceStatusGet();
              insuranceCalcPeriodGet();
              iPCoverTypeGet();

              const benefitTypeId = editDataSetUtil({
                $scope,
                items,
              })();

              if (benefitTypeId) {
                appInfoGet();
                $scope.benefitTypeChange(benefitTypeId);
              }

              addPolicyOwnership();
              addLoadingExclusion();
              addClientBenefitsHealthCover();
            }
          });
      }

      const setQPRBenefitID = (value) => {
        const selectedBenefitType = $scope.benefitTypeList.find(
          (benefit) => benefit.BenefitTypeID === value,
        );
        if (!selectedBenefitType) {
          return;
        }
        $scope.insuranceDetailsInput.qprBenefitId =
          selectedBenefitType.QPRBenefitID;
      };

      // 2. METHODS
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.init = () => {
        appInfoGet();
        clientInformGet();
        benefitPeriodListGet();
        getLoadingList();
        weekWaitPeriodListGet();
        insuranceQuoteExcessGet();
      };

      $scope.baseCoverChange = (indx) => {
        const { healthCover } = $scope.insuranceDetailsInput.benefits;
        const clientHealthCover = healthCover[indx];
        if (clientHealthCover.Excess === '') {
          clientHealthCover.isBaseCover = false;
        } else {
          const baseCover = clientHealthCover.isBaseCover;
          if (!baseCover) {
            clientHealthCover.isSpecialistsTest = baseCover;
            clientHealthCover.isGpPrescriptions = baseCover;
            clientHealthCover.isDentalOptical = baseCover;
          }
        }
      };

      $scope.hcExcessChange = (indx) => {
        const { healthCover } = $scope.insuranceDetailsInput.benefits;
        const clientHealthCover = healthCover[indx];
        clientHealthCover.isBaseCover = !!clientHealthCover.excess;
        if (!clientHealthCover.isBaseCover) {
          $scope.baseCoverChange(indx);
          $scope.formData.hasExcess = false;
        }
        $scope.excessChecker();
      };

      $scope.excessChecker = () => {
        const { benefits } = $scope.insuranceDetailsInput;
        $scope.formData.hasExcess = !!benefits.healthCover.find(
          (cover) => cover.excess > 0,
        );
      };

      $scope.benefitTypeChange = (value) => {
        insuranceQuoteProviderGet();
        insuranceProductGet();
        addLoadingExclusion();
        setQPRBenefitID(value);
        if (value === OLD_BENEFIT_TYPE.MORTGAGE_INTERRUPTION) {
          $scope.insuranceDetailsInput.isPremiumAutoCalc = false;
          $scope.insuranceDetailsInput.isPolicyFeeAutoCalc = false;
        }
      };
      onOtherBenefitSelected({ $scope })({ $timeout, toastWarning, OTHER });
      $scope.providerChange = () => {
        insuranceProductGet(false);
        setProviderName($scope.insuranceDetailsInput.providerId);
      };

      $scope.insuredPersonChange = (personId) => {
        $scope.selectedInsuredPerson = personId;
        addLoadingExclusion();
      };

      $scope.standardRatesChange = (exclusion) => {
        if ($scope.insuranceDetailsInput.benefitTypeId) {
          exclusion.isLoadingExclusion =
            exclusion.selectedOptions !== IS_LOADING_EXCLUSION;
          exclusion.isStandardRates =
            exclusion.selectedOptions === IS_LOADING_EXCLUSION;
        } else {
          if (exclusion.selectedOptions) {
            exclusion.selectedOptions = IS_LOADING_EXCLUSION;
          }
          displayWarning(
            'Please select Benefit Type first before adding Loading or Exclusion.',
            'Required Field',
          );
        }
      };

      $scope.formatInput = (input) => {
        const benefitType = $scope.benefitTypeList.find((benefit) => {
          return benefit.BenefitTypeID === input.benefitTypeId;
        });
        const { policyOwnership: policyOwners } = $scope.insuranceDetailsInput;
        const policyOwner = policyOwners.find((ownership) => {
          return (
            ownership.clientId === parseInt($scope.selectedInsuredPerson, 10)
          );
        });
        const healthCover = $scope.formatHealthCoverInput(input);
        return {
          ...input,
          quoteId: $scope.quoteId,
          clientId: $scope.quoteId
            ? policyOwner.quoteClientId
            : $scope.selectedInsuredPerson,
          commencementDate: input.tempCommencementDate,
          deferralReviewDate: input.tempDeferralReviewDate,
          benefitTypeId: $scope.quoteId
            ? benefitType.QPRBenefitID
            : benefitType.BenefitTypeID,
          benefits: { ...input.benefits, healthCover },
          providerResultId: items.data.providerResultId,
          loadingExclusions: $scope.getLoadingExclusionsToSave(input),
        };
      };

      $scope.formatHealthCoverInput = (input) => {
        const { healthCover } = input.benefits;
        if (!$scope.quoteId) {
          return healthCover;
        }
        if (!healthCover) {
          return null;
        }

        const { policyOwnership: policyOwners } = input;
        return healthCover.map((cover) => {
          const policyOwner = policyOwners.find(
            (owner) => owner.clientId === parseInt(cover.clientId, 10),
          );
          return policyOwner
            ? { ...cover, clientId: policyOwner.quoteClientId }
            : cover;
        });
      };

      $scope.getLoadingExclusionsToSave = (input) => {
        return $scope.quoteId
          ? input.loadingExclusions.map((exclusion) => {
              const policyOwner = input.policyOwnership.find(
                (owner) => owner.clientId === exclusion.clientId,
              );
              return { ...exclusion, clientId: policyOwner.quoteClientId };
            })
          : input.loadingExclusions;
      };

      $scope.saveNewExistingInsurance = () => {
        const commenceDateField =
          $scope.insuranceDetailsInput.tempCommencementDate;
        if (!commenceDateField && $scope.statusInForceDate && !$scope.quoteId) {
          $scope.commenceMessage();
          return;
        }

        if ($scope.isApplicationAndInforceStatus() && !commenceDateField) {
          $scope.commenceMessage();
          return;
        }
        const dateChecker = checkDate();
        if (dateChecker.countInvalid) {
          $scope.formData.isSaving = false;
          toastError(dateChecker.message);
          return;
        }

        $scope.formData.isSaving = true;
        if ($scope.quoteId) {
          $scope.saveAppBenefitCover();
        } else {
          insuranceDetailsSet();
        }
      };

      $scope.saveAppBenefitCover = () => {
        insurancePipelineService
          .saveBenefitCover($scope.formatInput($scope.insuranceDetailsInput))
          .then(
            onInsuranceSavedUtil({
              $scope,
              $uibModalInstance,
              familyId: $scope.insuranceDetailsInput.familyId,
              statusId: $scope.insuranceDetailsInput.statusId,
            }),
          );
      };

      $scope.otherOwnershipChange = (value) => {
        if (value.fullName === 'Other' && !value.isOwnership) {
          value.other = '';
        }
      };

      $scope.waivePolicyChange = (waivePolicyValue) => {
        $scope.insuranceDetailsInput.policyFee = 0;
        $scope.insuranceDetailsInput.isPolicyFeeAutoCalc = waivePolicyValue
          ? false
          : $scope.insuranceDetailsInput.isPolicyFeeAutoCalc;
        $scope.disablePolicyFee = !!waivePolicyValue;
        $scope.policyFeeInput = !waivePolicyValue;
      };

      $scope.autoPolicyFeeChange = (autoPolicyFeeValue) => {
        if (autoPolicyFeeValue) {
          $scope.insuranceDetailsInput.waivePolicy = false;
          $scope.disablePolicyFee = false;
          $scope.policyFeeInput = true;
        } else {
          $scope.insuranceDetailsInput.policyFee = 0;
        }
      };

      $scope.onFrequencyChange = (frequency) => {
        $scope.isFrequencyDisableAutoCalc = frequencyDisableAutoCalc(frequency);
        $scope.insuranceDetailsInput.isPremiumAutoCalc = !$scope.isFrequencyDisableAutoCalc;
        $scope.insuranceDetailsInput.isPolicyFeeAutoCalc = !$scope.isFrequencyDisableAutoCalc;
        if (!$scope.isFrequencyDisableAutoCalc) {
          $scope.isCrossProvider();
        }
      };

      // 4. Shared Data
    },
  );
