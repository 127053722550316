import { get, cloneDeep } from 'lodash';
import { setTableSortOrder } from 'Common/utilities/sort';
import { COMMISSION_TAB_URL } from 'Common/constants/commission';
import { copyAdviserList } from 'Common/utilities/commission';

export default class CommissionPaymentsCtrl {
  constructor(
    $state,
    $timeout,
    commissionHelperService,
    commissionService,
    downloadDataService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.commissionHelperService = commissionHelperService;
    this.commissionService = commissionService;
    this.downloadDataService = downloadDataService;
    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
    if (
      get(this.$state, 'params.activeTab') !== COMMISSION_TAB_URL.PAYMENT_ADVICE
    ) {
      return;
    }
    this.isPageLoaded = true;
    this.isExportingPaymentAdvice = false;
    this.initPaymentsCommissions();
  }

  $onChanges(changes) {
    if (!changes) {
      return;
    }
    changes.brokers &&
      (this.adviserList = copyAdviserList(this.brokers, this.default)) &&
      this.initPaymentFilterSettings();
  }

  $onDestroy() {
    this.loadPaymentTimeout && this.$timeout.cancel(this.loadPaymentTimeout);
    this.loadingTimeout && this.$timeout.cancel(this.loadingTimeout);
  }

  processParentNotification(eventHandler) {
    if (eventHandler === 'initPaymentsCommissions' && !this.isPageLoaded) {
      this.initPaymentsCommissions();
      this.isPageLoaded = true;
    }
  }

  initPaymentsCommissions() {
    this.paymentCommissionsHeader = this.commissionHelperService.getPaymentAdviceColumns();
    this.paymentCommissionsTableParams = {
      currentPage: 1,
      countPerPage: 10,
    };
    this.totalPaymentCommissions = 0;
    this.loadPaymentCommissions();
  }

  initPaymentFilterSettings() {
    this.paymentFilterSettings = {
      tableSrc: 'payment',
      ...cloneDeep(this.default),
      searchPlaceHolder: 'Search debit reference',
      sortColumn: 'Date',
      filterFields: {
        date: true,
        adviserMultiple: true,
        search: true,
      },
    };
  }

  onPaymentFilterChange({ filterSettings }) {
    this.paymentFilterSettings = { ...filterSettings };
    this.loadPaymentTimeout && this.$timeout.cancel(this.loadPaymentTimeout);

    this.loadPaymentTimeout = this.$timeout(() => {
      this.loadPaymentCommissions();
    }, 1000);
  }

  getPaymentRequestParams() {
    return {
      StartDate: get(this.paymentFilterSettings, 'date.filter.DateStart', null),
      EndDate: get(this.paymentFilterSettings, 'date.filter.DateEnd', null),
      BrokerId: this.paymentFilterSettings.adviserIds || null,
      SearchString: this.paymentFilterSettings.searchString,
      SortColumn: this.paymentFilterSettings.sortColumn,
      SortType: this.paymentFilterSettings.sortType,
      PageNumber: this.paymentCommissionsTableParams.currentPage,
      PageSize: this.paymentCommissionsTableParams.countPerPage,
    };
  }

  loadPaymentCommissions() {
    this.isLoadingPaymentCommissions = true;
    const params = this.getPaymentRequestParams();
    return this.commissionService.getPaymentCommissions(params).then(
      (response) => {
        if (!response) {
          return;
        }
        this.paymentCommissions = response;
        let totalCount = response.length;
        totalCount && (totalCount = get(response, '[0].totalCount', 0));
        this.totalPaymentCommissions = totalCount;
        this.loadingTimeout = this.$timeout(() => {
          this.isLoadingPaymentCommissions = false;
        }, 100);
      },
      () => {
        this.isLoadingPaymentCommissions = false;
        return this.isLoadingPaymentCommissions;
      },
    );
  }

  onSortTableData({ sortColumn, filterSettings }) {
    const filter = setTableSortOrder(sortColumn, filterSettings);
    this.onPaymentFilterChange({ filterSettings: filter });
  }

  onExportPaymentCommission({ commission }) {
    if (!commission) {
      return;
    }
    const { datePaid, adviserId } = commission;
    this.commissionService
      .PDFStatementGet(datePaid, adviserId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        const pdfContent = `data:${data.ContentType};base64,${data.DocumentContent}`;
        this.downloadDataService.download(
          pdfContent,
          `Commission Statement.pdf`,
          data.ContentType,
        );
      });
  }

  onExportPaymentAdviceCommission() {
    const fileName = `PaymentAdviceCommissions.xls`;
    const params = {
      type: 'Payment Advice',
      fileName,
      methodName: 'exportPaymentAdviceCommissions',
      exportParams: this.getPaymentRequestParams(),
    };
    this.isExportingPaymentAdvice = true;
    this.commissionHelperService
      .exportCommissionTableData(params)
      .then(() => {
        this.isExportingPaymentAdvice = false;
      })
      .catch(() => {
        this.isExportingPaymentAdvice = false;
      });
  }
}
