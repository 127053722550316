import './style.scss';
import template from './postSettlementAddressModal.html';
import controller from './postSettlementAddressModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    loanAppId: '<',
    addressData: '<',
    modalInstance: '<',
  },
};
