import { getLogger } from '@loanmarket/logger-core';
import { CONVERSION_PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';

export const notProceededModalContentMapper = (content, data) => {
  return {
    ...content,
    reasonList: data || [],
    isNotProceeded: true,
    showNurtureCampaignsQuestion: false,
  };
};

export const isAlwaysConversionStatus = (status) => {
  const conversionStatuses = [
    LOAN_STATUS.EXISTING.SETTLED,
    LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH,
  ];
  return conversionStatuses.includes(status.LoanStatusId);
};

export const getConversionFlag = (status) => {
  return (
    isAlwaysConversionStatus(status) ||
    status.isTriggerMoveCardToLeadOppNPW ||
    (status.isApplication && status.isTriggerMoveCardToApp)
  );
};

export const getConversionStatusId = (status) => {
  if (status.LoanStatusId === LOAN_STATUS.EXISTING.SETTLED) {
    return CONVERSION_PIPELINE_STATUS.TO_SETTLED;
  }
  if (status.isApplication) {
    return CONVERSION_PIPELINE_STATUS.TO_APPLICATION;
  }
  return CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED;
};

export const getFileStatusList = (loanFileService) => (loanId) => {
  if (!loanFileService) {
    const logger = getLogger('LoanFileHelper');
    logger.error('getFileStatusList: Missing service');
    return;
  }
  return loanFileService.getStatusList(loanId);
};
