export const setupTabProperties = ({ vm }) => {
  vm.tabProperties = {
    flags: {
      loadOpportunityFactFindTab: false,
      loadDocumentsTab: false,
    },
  };

  const { tabProperties } = vm;
  const { flags } = tabProperties;

  tabProperties.resetTabs = () => {
    flags.loadOpportunityFactFindTab = false;
    flags.loadDocumentsTab = false;
  };

  tabProperties.selectFactFindTab = () => {
    flags.loadOpportunityFactFindTab = true;
  };

  tabProperties.selectDocumentsTab = () => {
    flags.loadDocumentsTab = true;
  };
};
