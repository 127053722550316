export const RATE_PERIOD = {
  INITIAL: {
    NAME: 'Initial',
    VALUE: 1,
  },
  ONGOING: {
    NAME: 'Ongoing',
    VALUE: 2,
  },
  ANOTHER: {
    NAME: 'Another',
    VALUE: 2,
  },
};

export const SHORT_NAME = {
  PRINCIPAL_AND_INTEREST: 'P&I',
};

export const RATE_TYPE = {
  FIXED: 'Fixed',
  VARIABLE: 'Variable',
  FLOATING: 'Floating',
};

export const LOAN_STRUCTURE_TYPE = {
  INTEREST_ONLY: 'Interest Only',
  PRINCIPAL_AND_INTEREST: 'Principal and Interest',
  REVOLVING_CREDIT: 'Revolving Credit',
};

export const MAX_DISCOUNT_MONTH = 60;

export const LIFE_OF_LOAN = {
  VALUE: 0,
  NAME: 'Life of Loan',
};
