import { getOpportunityStatus } from 'Common/utilities/loanOpportunity';
import { pipelineStatusBuilderForUI } from 'Common/mappers/pipeline';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import {
  opportunityConvert,
  getNotProceededReasonList,
} from 'Common/utilities/opportunityConversion';
import { moveLoanOpportunityItem } from 'Common/utilities/kanbanHelper';
import moment from 'moment';
import toastr from 'toastr';

export default class StatusDropdownCtrl {
  constructor(
    $state,
    pipelineService,
    pipelineSharedData,
    modalRenderService,
    loanOpportunityService,
    uiService,
  ) {
    'ngInject';

    this.$state = $state;
    this.pipelineService = pipelineService;
    this.pipelineSharedData = pipelineSharedData;
    this.modalRenderService = modalRenderService;
    this.loanOpportunityService = loanOpportunityService;
    this.uiService = uiService;
  }

  $onInit() {
    const { showLendingTools } = this.uiService;
    this.showLendingTools = showLendingTools;

    this.PIPELINE_STATUS = PIPELINE_STATUS;
    getOpportunityStatus(this.pipelineService).then((statusList) => {
      this.statusList = statusList.map(pipelineStatusBuilderForUI);
    });

    getNotProceededReasonList({
      loanOpportunityService: this.loanOpportunityService,
      isMergeLeadAndOpportunities: this.pipelineSharedData
        .isMergeLeadAndOpportunities,
    }).then((res) => {
      if (!res.data) {
        return;
      }
      const { data } = res;
      this.statusReasonList = data;
    });
  }

  setStatus(status) {
    if (!status.isConversionStatus) {
      this.updatedStatusDate = moment(new Date()).format('YYYY MM DD hh:mm:ss');
      this.opportunityStatus = status.pipelineStatus;

      const onSuccessCallback = () => {
        this.onSuccess(status);
        toastr.success('Card successfully moved!', 'Success');
      };
      if (this.pipelineSharedData.isMergeLeadAndOpportunities) {
        const params = {
          card: {
            toPipelineStatusId: status.pipelineStatusId,
            fromPipelineStatusId: this.pipelineItem.pipelineStatusId,
            loanId: this.loanId,
            loanScenarioId: this.pipelineItem.loanScenarioId,
            pipelineCardsId: this.pipelineItem.pipelineCardsId,
          },
          isConversion: false,
          loanOpportunityService: this.loanOpportunityService,
        };
        return moveLoanOpportunityItem(params).then(() => onSuccessCallback());
      }

      this.loanOpportunityService
        .setLoanOpportunityStatus(this.loanId, status.pipelineStatusId)
        .then(() => onSuccessCallback());
    } else {
      const payload = {
        loanId: this.loanId,
        loanScenarioId: this.opportunityId,
        conversionStatusId: status.pipelineStatusId,
        pipelineCardsId: this.pipelineItem.pipelineCardsID,
        fromPipelineStatusId: this.pipelineItem.pipelineStatusId,
      };

      opportunityConvert(
        this.pipelineSharedData,
        this.modalRenderService,
        this.loanOpportunityService,
        status.pipelineStatusId,
        this.opportunityId,
        this.statusReasonList,
        payload,
      ).then((res) => {
        if (res && res.isOk) {
          toastr.success('Card successfully converted!', 'Success');
          this.onRemoveRow({ rowId: this.rowId });
        }
      });
    }
  }

  onSuccess(status) {
    if (this.onSelectStatus && this.pipelineItem) {
      this.pipelineItem.pipelineStatus = status.pipelineStatus;
      this.pipelineItem.pipelineStatusId = status.pipelineStatusId;
      this.onSelectStatus({ $e: { pipelineItem: this.pipelineItem } });
    }
  }
}
