import template from './linkedTermTable.html';
import controller from './linkedTermTableCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    importantDates: '<',
    showFixedRate: '<',
    showInterestOnly: '<',
    termList: '<',
    termHoliday: '<',
    isInline: '<',
    onUpdate: '&',
  },
};
