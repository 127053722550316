import template from './frame.html';
import controller from './frameCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    component: '<',
    value: '=',
    height: '<',
    width: '<',
  },
};
