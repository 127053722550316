import { pipelineApplicationTableMap } from 'Common/utilities/pipelineTable';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

export default class PipelineTableApplication {
  constructor(services) {
    const { pipelineService, contactService, $filter } = services;

    this.getHeaders = () => {
      return contactService.getUserInfo().then(({ data }) => {
        if (!data) {
          return;
        }
        const { AccessType } = data;
        const isCorporateUser = AccessType === ACCESS_TYPE.CORPORATE;

        const headerSettings = { isHidden: 0, isSortable: 1 };
        return [
          {
            offlineSortObjKey: '',
            dataSortObjectExcludedKey: 'ClientName',
            isExclusionIncluded: true,
            dataSortObjectKey: 'ClientName',
            label: 'Client Names',
            ...headerSettings,
          },
          {
            offlineSortObjKey: '',
            dataSortObjectExcludedKey: 'Lender',
            isExclusionIncluded: true,
            dataSortObjectKey: 'Lender',
            label: 'Lender',
            ...headerSettings,
          },
          {
            offlineSortObjKey: '',
            dataSortObjectExcludedKey: 'LoanAmount',
            isExclusionIncluded: true,
            dataSortObjectKey: 'LoanAmount',
            label: 'Amount',
            ...headerSettings,
          },
          {
            offlineSortObjKey: '',
            dataSortObjectExcludedKey: 'LoanDescription',
            isExclusionIncluded: true,
            dataSortObjectKey: 'LoanDescription',
            label: 'Loan Description',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'stage',
            dataSortObjectExcludedKey: 'PipelineStatus',
            isExclusionIncluded: true,
            dataSortObjectKey: 'Stage',
            label: 'Stage',
            ...headerSettings,
          },
          {
            offlineSortObjKey: '',
            dataSortObjectExcludedKey: 'EnquirySource',
            isExclusionIncluded: true,
            dataSortObjectKey: 'EnquirySource',
            label: 'Enquiry Source',
            ...headerSettings,
          },
          {
            offlineSortObjKey: '',
            dataSortObjectExcludedKey: 'AdvisorName',
            isExclusionIncluded: true,
            dataSortObjectKey: 'AdvisorName',
            label: 'Adviser',
            ...headerSettings,
          },
          {
            offlineSortObjKey: '',
            dataSortObjectExcludedKey: 'SettlementDate',
            isExclusionIncluded: false,
            dataSortObjectKey: 'SettlementDate',
            label: 'Settlement Date',
            ...headerSettings,
          },
          {
            offlineSortObjKey: '',
            dataSortObjectExcludedKey: 'SubStatusUpdated',
            isExclusionIncluded: true,
            dataSortObjectKey: 'StatusUpdated',
            label: 'Last Updated',
            ...headerSettings,
          },
          {
            offlineSortObjKey: '',
            dataSortObjectExcludedKey: 'Labels',
            isExclusionIncluded: false,
            dataSortObjectKey: 'Labels',
            label: 'Labels',
            ...headerSettings,
            isHidden: 1,
          },
          {
            offlineSortObjKey: '',
            dataSortObjectExcludedKey: 'Labels',
            isExclusionIncluded: !isCorporateUser,
            dataSortObjectKey: 'LabelsList',
            label: 'Labels',
            ...headerSettings,
            isHidden: isCorporateUser,
          },
          { label: 'familyId', isHidden: 1 },
          { label: 'id', isHidden: 1 },
          { label: 'loanScenarioId', isHidden: 1 },
          { label: 'pipelineCardsId', isHidden: 1 },
          { label: 'pipelineStatusId', isHidden: 1 },
          { label: 'lenderID', isHidden: 1 },
          { label: 'country', isHidden: 1 },
          { label: 'pipelineCardsID', isHidden: 1 },
          { label: 'adviserProfilePicture', isHidden: 1 },
          { label: 'isEstimated', isHidden: 1 },
          { label: 'familyInfo', isHidden: 1 },
        ];
      });
    };

    this.getTableData = (pageNumber, pageSize, searchClientName) => {
      const params = {
        pageNumber,
        pageSize,
        searchClientName,
      };
      return pipelineService
        .getPipelineApplicationTable(params)
        .then((response) => {
          const { data } = response;
          if (!data || !data.length) {
            return {
              totalRecords: 0,
              tableData: 0,
              totalAmountPerPage: 0,
              totalAmountOverall: 0,
            };
          }
          const {
            totalRecords,
            totalAmountPerPage,
            totalAmountOverall,
          } = data[0];
          const tableData = data.reduce((accum, elem) => {
            const mappedRowData = pipelineApplicationTableMap(elem, $filter);
            return accum.concat(mappedRowData);
          }, []);
          return {
            totalRecords,
            tableData,
            totalAmountPerPage,
            totalAmountOverall,
          };
        });
    };
  }
}
