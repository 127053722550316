import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('dropdownValidation', function dropdownValidation() {
      return {
        require: '?ngModel',
        scope: {
          options: '<dropdownValidation',
        },
        link(scope, elem, attrs, ngModel) {
          if (!attrs.dropdownValidationOptionKey) {
            return;
          }
          ngModel.$validators.dropdownValidation = (modelValue) => {
            if (!scope.options) {
              return false;
            }
            return !!scope.options.find((option) => {
              return option[attrs.dropdownValidationOptionKey] === modelValue;
            });
          };
        },
      };
    });
