import template from './editMultiProviderModal.html';
import controller from './editMultiProviderModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    providers: '<',
    statusList: '<',
    isAu: '<',
    onSubmit: '&',
  },
};
