import template from './helloPackFactFind.html';
import controller from './helloPackFactFindCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    factFindDefaultSetting: '<',
    handler: '<',
    toggleValue: '<',
    onChange: '&',
  },
};
