export const loadModule = () =>
  app.factory('nodifiIntegrationService', [
    '$q',
    'nodifiIntegrationModel',
    function ($q, nodifiIntegrationModel) {
      var factory = this;

      var nodifiIntegration = new nodifiIntegrationModel();

      factory.CreateApplication = function (loanScenarioId, assetProductType) {
        var defer = $q.defer();
        nodifiIntegration.CreateApplication(
          loanScenarioId,
          assetProductType,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      return factory;
    },
  ]);
