import angular from 'angular';
import assetWidgetHeader from './assetWidgetHeader';
import assetFundDetails from './assetFundDetails';
import applicantDetails from './applicantDetails';
import quoteDetails from './quoteDetails';

export default angular
  .module('assetFinanceWidgets.components', [])
  .component('assetWidgetHeader', assetWidgetHeader)
  .component('assetFundDetails', assetFundDetails)
  .component('applicantDetails', applicantDetails)
  .component('quoteDetails', quoteDetails).name;
