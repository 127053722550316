import template from './duplicateContactsModal.html';
import controller from './duplicateContactsModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    clientsToMerge: '<',
    countryCode: '<',
    refreshContactList: '&',
  },
};
