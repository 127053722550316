export const COMMISSION_TYPE = {
  ONE_OFF: 'one-off',
  RECURRING: 'recurring',
};

export const ONE_OFF_TYPES = {
  UPFRONT: 1,
  REFIX: 2,
  BONUS: 3,
};
