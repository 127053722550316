import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { initializeAssetsDependents } from 'Assets/js/controllers/loanApp/clientAssess/modal/util/loanAppAssetModalCtrl';
import { initializeAddressLookup } from 'Common/utilities/assetsModal';
import { toastWarning } from 'Common/utilities/alert';
import { FINANCIAL_TYPE_ID } from 'Common/constants/financialAssets';
import { setUpNZServiceability } from 'Common/utilities/financialLiabilities';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('AssetsModalCtrl', function AssetsModalCtrl(
      $scope,
      $uibModalInstance,
      modalType,
      contactService,
      $rootScope,
      optionsService,
      $timeout,
      generalService,
      SweetAlert,
      loanAppSharedData,
      configService,
      loanStructureService,
      currentUserService,
    ) {
      $scope.isNZ = currentUserService.isNZ;
      initializeAssetsDependents($scope);

      currentUserService.isNZ &&
        setUpNZServiceability({
          $scope,
          loanStructureService,
          optionsService,
          isAssetLiability: true,
        });

      $scope.loanAppSharedData = loanAppSharedData;
      $scope.isContactModal = true;
      $scope.frequencyTypes = [];
      optionsService.FinancialFrequency().then((response) => {
        _.map(response.data, (o) => {
          o.Desc = o.Name;
          o.Value = parseInt(o.Value, 10);

          return o;
        });
        $scope.frequencyTypes = response.data;
      });
      contactService.contactAddressGet($scope.familyId).then((response) => {
        $scope.propertyList = response.data;
      });

      $scope.rentalVerificationTypeList = [];
      if ($rootScope.crmCountry === 'New Zealand') {
        optionsService.RentalVerificationTypeGet().then((response) => {
          $scope.rentalVerificationTypeList = response.data;
        });
      }

      // Create a loan term list
      optionsService.getLoanTerms().then((loanTermResponse) => {
        $scope.loanTermList = loanTermResponse;
      });

      // Models
      angular.extend($scope, {
        Title: modalType === 'new' ? 'Add' : 'Edit',
        SaveTitle: modalType === 'new' ? 'Add' : 'Edit',
        Years: [],
      });
      for (let i = moment().year(); i >= 1930; i--) {
        // loop for years
        $scope.Years.push(i);
      }

      // Methods
      angular.extend($scope, {
        cancel() {
          $uibModalInstance.dismiss('cancel');
        },
        fullAutoAddress() {
          // setter
          $timeout.cancel($scope.searchTimeout);
          // added timeout to avoid multiple request
          $scope.searchTimeout = $timeout(() => {
            if (_.isUndefined($scope.AssetsSet.FullAddress)) {
              $scope.AssetsSet.FullAddress = {};
            }

            generalService
              .placeSearch($scope.AssetsSet.FullAddress.formatted_address)
              .then((respond) => {
                if (respond && respond.data) {
                  $scope.fullAutoAddressDetails = respond.data;
                }
              });
          }, 500);
        },
        selectFullAddress(fullAddress) {
          $scope.AssetsSet.FullAddress = fullAddress;
          $scope.closeAutoFullAddress();
        },
        closeAutoFullAddress() {
          $scope.fullAutoAddressDetails = [];
        },

        saveBreakdown(isFormValid = false) {
          if (!isFormValid) {
            return;
          }
          $scope.displayAssetsFormWrapper.displayAssetsForm = false;
          let assetType = _.find($scope.assetTypes, (o) => {
            return o.Desc === $scope.AssetsSet.Type.Desc;
          });
          if (!assetType) {
            assetType = {
              Group: '',
            };
          }
          const isValidBorrower =
            $scope.ownersListSelected.some(
              (borrower) => borrower && borrower.BorrowerID,
            ) ||
            $scope.ownersList.some(
              (borrower) => borrower && borrower.BorrowerID,
            );
          if (
            assetType.Group === 'Real Estate' &&
            (_.isUndefined($scope.AssetsSet.FullAddress) ||
              _.size($scope.AssetsSet.FullAddress) < 1)
          ) {
            return false;
          }
          if (!isValidBorrower) {
            toastWarning('Please select ownership');
            return false;
          }
          $scope.formSubmittedValidation = true;
          $scope.addAsset(true, modalType);
          $uibModalInstance.close();
          $scope.displayAssetsFormWrapper.displayAssetsForm = false;
        },
        addRentalIncome() {
          $scope.AssetsSet.RentalIncome.push(
            $scope.getNewRentalIncomeModel(
              _.size($scope.AssetsSet.RentalIncome) + 1,
            ),
          );
        },
        removeRentalIncome(income) {
          if (parseInt(income, 10) === 0) {
            $scope.AssetsSet.RentalIncome = [];
          } else if (parseInt(income.Id, 10) === 0) {
            // remove rentalIncome
            _.remove($scope.AssetsSet.RentalIncome, (o) => {
              return _.isEqual(o, income);
            });

            _.forEach($scope.AssetsSet.RentalIncome, (o, key) => {
              o.index = key + 1; // putting index 1 to n
            });
          } else {
            SweetAlert.swal(
              {
                title: 'Are you sure?',
                text: 'This record will be removed from your income list',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F68F8F',
                confirmButtonText: 'Yes, remove it!',
                closeOnConfirm: false,
              },
              (confirm) => {
                if (confirm) {
                  contactService
                    .deleteIncome(income.Id, $scope.familyId)
                    .then(() => {
                      $timeout(() => {
                        $rootScope.$broadcast('REFRESH_INCOME');

                        _.remove($scope.AssetsSet.RentalIncome, (o) => {
                          return _.isEqual(o, income);
                        });

                        _.forEach($scope.AssetsSet.RentalIncome, (o, key) => {
                          o.index = key + 1; // putting index 1 to n
                        });

                        SweetAlert.swal({
                          title: 'Success',
                          text: 'Income has been been successfully deleted.',
                          type: 'success',
                          timer: 2000,
                          showConfirmButton: false,
                        });
                      }, 100);
                    });
                }
              },
            );
          }
        },
        addMortgageLiability() {
          $scope.AssetsSet.MortgageLiability.push(
            $scope.getNewMortgageLiabilityModel(
              _.size($scope.AssetsSet.MortgageLiability) + 1,
            ),
          );
        },
        removeMortgageLiability(mortgage) {
          if (parseInt(mortgage, 10) === 0) {
            $scope.AssetsSet.MortgageLiability = [];
          } else {
            mortgage.removeLiability = function () {
              _.remove($scope.AssetsSet.MortgageLiability, (o) => {
                // will return if isEqual
                return _.isEqual(o, mortgage);
              });

              _.forEach($scope.AssetsSet.MortgageLiability, (o, key) => {
                o.index = key + 1; // putting index 1 to n
              });
            };

            if (!mortgage.Id) {
              mortgage.removeLiability();
            } else {
              SweetAlert.swal(
                {
                  title: 'Are you sure?',
                  text: 'This record will be removed from your mortgage list',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#F68F8F',
                  confirmButtonText: 'Yes, remove it!',
                  closeOnConfirm: false,
                },
                (confirm) => {
                  if (confirm) {
                    contactService
                      .deleteLiability(mortgage.Id, $scope.familyId)
                      .then(() => {
                        $timeout(() => {
                          $rootScope.$broadcast('REFRESH_LIABILITY');

                          mortgage.removeLiability();

                          SweetAlert.swal({
                            title: 'Success',
                            text:
                              'Liability has been been successfully deleted.',
                            type: 'success',
                            timer: 2000,
                            showConfirmButton: false,
                          });
                        }, 100);
                      });
                  }
                },
              );
            }
          }
        },
      });

      $scope.$watch('AssetsSet.RentalIncome', () => {
        if (_.size($scope.AssetsSet.RentalIncome) > 0) {
          $scope.AssetsSet.IsRentalIncome = true;
        } else {
          $scope.AssetsSet.IsRentalIncome = false;
        }
      });

      $scope.$watch('AssetsSet.MortgageLiability', () => {
        if (_.size($scope.AssetsSet.MortgageLiability) > 0) {
          $scope.AssetsSet.IsExistingMortgages = true;
        } else {
          $scope.AssetsSet.IsExistingMortgages = false;
        }
      });
      initializeAddressLookup({ $scope, configService });
      $scope.onChangeFinancialType = (type) => {
        $scope.isAddressRequired =
          _.get(type, 'Value', 0) === FINANCIAL_TYPE_ID.REAL_ESTATE_ASSET;
      };
    });
