export const SEND_TO_TABS = {
  APPLICANTS: 'APPLICANTS',
  GUARANTORS: 'GUARANTORS',
};

export const AU_GAME_PLAN_SECTIONS_CONCIERGE = 6;
export const AU_GAME_PLAN_SECTIONS_WEALTHMARKET = 8;
export const AU_GAME_PLAN_SECTIONS_HOMENOW = 14;

export const AU_GAME_PLAN_SECTIONS = [
  {
    id: 1,
    name: 'Cover page',
    isEditableText: true,
    apiKey: 'CoverPage',
    isHidden: false,
    isToggleable: false,
    isIncluded: true,
  },

  {
    id: 2,
    name: 'Broker message',
    apiKey: 'BrokerMessage',
    isEditable: true,
    isDefaultActive: true,
    isHidden: false,
    isToggleable: true,
    isIncluded: true,
  },
  {
    id: 3,
    name: 'Your goals',
    apiKey: 'Goals',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 10,
    name: 'Selected product',
    apiKey: 'SelectedProduct',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 11,
    name: 'Rates, fees and commissions',
    apiKey: 'RateFeesCommission',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 7,
    name: 'Your funds position',
    apiKey: 'FundsPosition',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: AU_GAME_PLAN_SECTIONS_CONCIERGE,
    name: 'Concierge',
    apiKey: 'Concierge',
    isHidden: true,
    isToggleable: true,
    isRemoved: true,
  },
  {
    id: AU_GAME_PLAN_SECTIONS_HOMENOW,
    name: 'HomeNow',
    apiKey: 'HomeNow',
    isHidden: true,
    isToggleable: true,
    isRemoved: true,
  },
  {
    id: AU_GAME_PLAN_SECTIONS_WEALTHMARKET,
    name: 'Wealth Market',
    apiKey: 'WealthMarket',
    isHidden: true,
    isToggleable: true,
    isRemoved: true,
  },
  {
    id: 9,
    name: 'Product comparison',
    apiKey: 'ProductComparison',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 5,
    name: 'Client details',
    apiKey: 'ClientDetails',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 13,
    name: 'Terms, disclaimers, acknowledgements',
    apiKey: 'Appendix',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 12,
    name: 'Time to sign',
    apiKey: 'TimeToSign',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
];

export const NZ_GAME_PLAN_SECTIONS = [
  {
    id: 1,
    name: 'Cover page',
    isEditableText: true,
    apiKey: 'CoverPage',
    isHidden: false,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 2,
    name: 'Adviser message',
    apiKey: 'BrokerMessage',
    isEditable: true,
    isDefaultActive: true,
    isHidden: false,
    isToggleable: true,
    isIncluded: true,
  },
  {
    id: 3,
    name: ['Your goals', 'Applicant goals'],
    apiKey: 'Goals',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 10,
    name: 'Selected product',
    apiKey: 'SelectedProduct',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 11,
    name: 'Rates, fees and commissions',
    apiKey: 'RateFeesCommission',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 7,
    name: 'Your funds position',
    apiKey: 'FundsPosition',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 6,
    name: 'Other considerations',
    apiKey: 'Concierge',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 9,
    name: 'Product comparison',
    apiKey: 'ProductComparison',
    isHidden: false,
    isToggleable: true,
    isIncluded: true,
  },
  {
    id: 5,
    name: ['Client details', 'Guarantor details'],
    apiKey: 'ClientDetails',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
  {
    id: 13,
    name: 'Terms, disclaimers, acknowledgements',
    apiKey: 'Appendix',
    isHidden: true,
    isToggleable: false,
    isIncluded: true,
  },
];

export const GAME_PLAN_SECTIONS = {
  AU: AU_GAME_PLAN_SECTIONS,
  NZ: NZ_GAME_PLAN_SECTIONS,
};

export const GAME_PLAN_COVER_PAGE_TITLE_RESTRICTED_WORDS = {
  AU: [
    // Basic swear
    'fuck',
    'shit',
    'cunt',
    'crap',

    // prohibited words for AU compliance
    'advise',
    'advice',
    'free',
    'independent',
    'impartial',
    'unbiased',
    'financial counsellor',
    'financial counselling',
    'reverse mortgage',
    'debt management',
    'approval',
    'approved',
    'stockbroker',
    'sharebroker',
    'futures broker',
    'insurance broker',
    'insurance broking',
    'general insurance broker',
    'life insurance broker',
    'financial adviser',
    'bank',
    'banker',
    'banking',
    'building society',
    'credit union',
    'credit society',
  ],
};
