import { taskPdfBuilderForUI } from 'Common/mappers/tasks.js';

export const loadModule = () =>
  app.factory('taskService', [
    '$q',
    'taskModel',
    function ($q, taskModel) {
      var factory = this;

      var task = new taskModel();

      factory.taskCompletionSet = function (familyID, taskID, isComplete) {
        var defer = $q.defer();

        task.taskCompletionSet(
          familyID,
          taskID,
          isComplete,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );

        return defer.promise;
      };

      factory.taskSet = function ($postData) {
        var defer = $q.defer();

        task.taskSet(
          $postData,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );

        return defer.promise;
      };

      factory.taskDetailsGet = function (taskId) {
        var defer = $q.defer();

        task.taskDetailsGet(
          taskId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );

        return defer.promise;
      };

      factory.taskTemplateListGet = function () {
        var defer = $q.defer();

        task.taskTemplateListGet(
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );

        return defer.promise;
      };

      factory.scenarioListGet = function (familyID) {
        var defer = $q.defer();
        task.scenarioListGet(
          familyID,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.taskDelete = function (familyId, taskId) {
        var defer = $q.defer();
        task.taskDelete(
          familyId,
          taskId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.getTaskTemplateSettings = function (taskTemplateId) {
        var defer = $q.defer();
        task.getTaskTemplateSettings(
          taskTemplateId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.updateDueDateSubsequently = function ($postData, taskId) {
        var defer = $q.defer();
        task.updateDueDateSubsequently(
          $postData,
          taskId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.storeTask = function ($postData) {
        var defer = $q.defer();
        task.storeTask(
          $postData,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.updateTask = function ($postData) {
        var defer = $q.defer();
        task.updateTask(
          $postData,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.TaskSetv3Multiple = function ($postData) {
        var defer = $q.defer();
        task.TaskSetv3Multiple(
          $postData,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.searchFamily = function (
        searchCriteria,
        contactType,
        assignToAdviserID,
      ) {
        var defer = $q.defer();

        task.searchFamily(
          searchCriteria,
          contactType,
          assignToAdviserID,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );

        return defer.promise;
      };

      factory.TaskColumnSettingsGet = function () {
        var defer = $q.defer();

        task.TaskColumnSettingsGet(
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );

        return defer.promise;
      };

      factory.TaskColumnSettingsSet = function ($postData) {
        var defer = $q.defer();
        task.TaskColumnSettingsSet(
          $postData,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.taskBatchCompletion = function ($postData) {
        var defer = $q.defer();
        task.taskBatchCompletion(
          $postData,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.taskSingleCompletion = function (taskId, familyID, isComplete) {
        var defer = $q.defer();

        task.taskSingleCompletion(
          taskId,
          familyID,
          isComplete,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );

        return defer.promise;
      };

      factory.taskPdf = (
        adviserFilter,
        dateFilter,
        createdbyFilter,
        familyId,
        isShowCompleted,
        sortColumn,
        sortType,
        taskSearch,
      ) => {
        var defer = $q.defer();

        task.taskPdf(
          adviserFilter,
          dateFilter,
          createdbyFilter,
          familyId,
          isShowCompleted,
          sortColumn,
          sortType,
          taskSearch,
          (response) => {
            factory.data = response.data;
            return defer.resolve(taskPdfBuilderForUI(response.data));
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );

        return defer.promise;
      };
      return factory;
    },
  ]);
