import swal from 'sweetalert';
import { isOnlyPersonInFamily } from 'Components/opportunity/opportunityMain/opportunityDetails/util/opportunityCalculation';

export const deleteClientFinancials = ({
  opportunityCalculationService,
  loanOpportunityService,
  loanAppId,
  involvedPartyPerson,
  clientId,
  isClient,
  familyId,
}) => {
  const isOnlyPerson =
    !isClient || isOnlyPersonInFamily(familyId, involvedPartyPerson);
  const data = {
    clientId,
    familyId,
    isClient,
    isDeleteFamilyRecord: isOnlyPerson,
  };

  if (isOnlyPerson) {
    opportunityCalculationService.removeFamilyCalculation(familyId);
  }
  return loanOpportunityService.deleteFinancials(loanAppId, data);
};

export const openDeleteClientModal = ({
  toaster,
  loanScenarioService,
  opportunityCalculationService,
  loanOpportunityService,
  loanAppId,
  involvedPartyPerson,
  callback,
}) => (e, clientId, isClient, familyId) => {
  e && e.stopPropagation();
  swal(
    {
      title: 'Are you sure?',
      text: 'This record will be removed from your involved party list',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F68F8F',
      confirmButtonText: 'Yes, remove it!',
      closeOnConfirm: true,
    },
    (confirm) => {
      if (confirm) {
        loanScenarioService
          .scenarioClientDelete(loanAppId, clientId, isClient)
          .then((response) => {
            const { data: result } = response;
            deleteClientFinancials({
              opportunityCalculationService,
              loanOpportunityService,
              loanAppId,
              involvedPartyPerson,
              clientId,
              isClient,
              familyId,
            });
            if (result) {
              toaster.pop('success', 'Success', 'Successfully deleted!');
              callback();
            }
          });
      }
    },
  );
};
