import template from './creditHistoryTable.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    isLoadingList: '<',
    historicalData: '<',
    onViewCreditCheck: '&',
  },
};
