import { DD_MM_YYYY_FORMAT } from 'Common/constants/dateConfigs';

export default class TaskTemplateEditorCtrl {
  $onInit() {
    this.dateConfig = {
      format: DD_MM_YYYY_FORMAT,
      options: {
        formatYear: 'yy',
        showWeeks: false,
      },
      isOpen: false,
    };
    this.assignTaskTemplate();
  }

  assignTaskTemplate() {
    const { TaskSettingsID, TaskName, AssignedAdvisorID, DueDate } =
      this.template ?? {};
    this.taskTemplate = {
      TaskSettingsID,
      TaskName,
      AssignedAdvisorID,
      DueDate: new Date(DueDate),
    };
  }

  onDateOpen() {
    this.dateConfig.isOpen = true;
  }

  onSaveTemplate() {
    const { FirstName, LastName } =
      this.advisers.find(
        (adviser) => adviser.BrokerID === this.taskTemplate.AssignedAdvisorID,
      ) ?? {};
    const template = {
      ...this.taskTemplate,
      FirstName,
      LastName,
    };
    this.onSave({ template });
  }
}
