export const OKTA_OAUTH_ERRORS = {
  jwt_expired: 'The JWT expired and is no longer valid',
  jwt_future: 'The JWT was issued in the future',
};

export const OKTA_ERROR_NAMES = {
  OAUTH: 'OAuthError',
};

export const OKTA_ERROR_CODES = {
  LOGIN_REQUIRED: 'login_required',
};
