import { insurancePipelineStatusBuilderForUI } from '../mappers/pipeline';

export default class PipelineApiService {
  constructor(httpClient, configService) {
    'ngInject';

    this.httpClient = httpClient;
    this.configService = configService;

    this.apiBaseUrl = 'api-pipelines';
  }

  getInsurancePipelineStatuses() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/stage-definition`, {
        filter: `equals(pipelineDefinition.externalReferenceSource,'API_Insurance_File')`,
        sort: 'sequenceOrder',
        'page[size]': 30,
      })
      .then(({ data: { data } }) =>
        data?.map(insurancePipelineStatusBuilderForUI),
      );
  }

  getInsuranceFileStatusId(insuranceFileId) {
    return this.getInsuranceFileStatus(insuranceFileId).then((data) => data.id);
  }

  getInsuranceFileStatus(insuranceFileId) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/external-reference?include=pipeline.status.stageDefinitions&fields[stage-definition]=name&filter=and(equals(externalReferenceSource,'API_Insurance_File'),equals(externalReferenceId,'${insuranceFileId}'))`,
      )
      .then(({ data: { data, included } }) => {
        const { pipelineId } =
          data.find(
            (file) =>
              parseInt(file?.attributes?.externalReferenceId, 10) ===
              insuranceFileId,
          )?.attributes ?? {};

        const statusId = included.find(
          (item) => item.id === pipelineId && item.type === 'pipeline',
        )?.relationships?.status?.data?.id;

        const stageDefinitionId = included.find(
          (item) => item.id === statusId && item.type === 'status',
        )?.relationships?.stageDefinitions?.data?.[0]?.id;

        const stageDefinition = included.find(
          (item) =>
            item.id === stageDefinitionId && item.type === 'stage-definition',
        );

        return !stageDefinition
          ? undefined
          : {
              id: stageDefinition.id,
              name: stageDefinition.attributes?.name,
            };
      });
  }

  updateInsuranceFileStatus(insuranceFileId, newStatusId) {
    return this.httpClient
      .post(
        `${this.apiBaseUrl}/action/update-stage`,
        {
          externalReferenceSource: 'API_Insurance_File',
          externalReferenceId: `${insuranceFileId}`,
          nextStageDefinitionId: newStatusId,
        },
        {},
        false,
      )
      .then((response) => {
        return {
          ...response,
          fileId: insuranceFileId,
        };
      });
  }
}
