import { appendEmptySelect } from 'Common/utilities/options';
import { toastSuccess, toastWarning } from 'Common/utilities/alert';
import {
  kiwiSelectProviderExistingClientBuilderForAPI,
  kiwiSelectProviderNonExistingClientBuilderForAPI,
} from 'Common/mappers/kiwiSelect';
import moment from 'moment';
import { DD_MM_YYYY_MOMENT_FORMAT } from 'Common/constants/dateConfigs';

const DEFAULT_SELECTED = 0;
export default class ReferralKiwiSelectCtrl {
  constructor(
    $timeout,
    crmConfirmation,
    generalService,
    kiwiService,
    configService,
    addressLookupService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.crmConfirmation = crmConfirmation;
    this.kiwiService = kiwiService;
    this.generalService = generalService;
    this.configService = configService;
    this.addressLookupService = addressLookupService;
    this.clientData = {};
    this.isLoading = false;
  }

  addressLookupHandler({ reloadExistingAddress }) {
    this.handler({ reloadExistingAddress });
  }

  $onInit() {
    this.isAddressOverhaulEnabled = this.configService.feature.addressOverhaul;
    this.address = {};
    this.kiwiService.getkiwiSelectProvider().then((response) => {
      this.kiwiProvider = appendEmptySelect(response || [], {
        providerName: 'Please select',
        providerId: 0,
      });
    });

    this.kiwiService.getkiwiSelectFundType().then((response) => {
      this.kiwiFund = appendEmptySelect(response || [], {
        fundType: 'Please select',
        fundTypeId: 0,
      });
    });

    !!this.isClientSearchReadOnly &&
      (() => {
        this.clientData = {
          formattedAddress:
            this.clientPostData.Address.map(
              (address) => address.formatted_address,
            ).join(',') || '',
          dateOfBirth:
            moment(this.clientPostData.DateOfBirth).format(
              DD_MM_YYYY_MOMENT_FORMAT,
            ) || '',
        };
        this.clientData.kiwiFund = DEFAULT_SELECTED;
        this.clientData.kiwiProvider = DEFAULT_SELECTED;
      })();
  }

  getFormattedAddress() {
    this.$timeout.cancel(this.searchTimeout);
    const { formattedAddress } = this.clientData;
    this.searchTimeout = this.$timeout(() => {
      if (formattedAddress) {
        this.generalService.placeSearch(formattedAddress).then((response) => {
          this.addressSuggestions = response.data;
          this.showSuggestionModal = true;
        });
      }
    }, 500);
  }

  toggleSearch() {
    this.showSuggestionModal = !this.showSuggestionModal;
  }

  pickAddressSuggestion(homeAddress) {
    this.clientData.formattedAddress = homeAddress.formatted_address;
    this.addressSuggestions = [];
    this.showSuggestionModal = false;
  }

  postData() {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const useExistingEmail = (data = []) => {
      const [emailAddress] = data.map((email) => email.EmailAddress) || [];
      return emailAddress;
    };
    return {
      clientData: this.clientData,
      ...this.clientPostData,
      formModel: this.formModel,
      contactDetailList:
        !!this.contactDetailList &&
        this.contactDetailList.reduce((contactType, current) => {
          contactType[current.Type] = current.Number || '';
          return contactType;
        }, {}),
      existingEmail: useExistingEmail((this.clientPostData || {}).Email),
    };
  }

  isValidToSubmitReferral() {
    return (
      this.referralForm.$invalid ||
      !this.clientData.kiwiProvider ||
      !this.clientData.irdNumber ||
      !this.clientData.adviserId
    );
  }

  validateNonExistingClient() {
    const { Email, FirstName, LastName, Mobile } = this.formModel;
    const isEmpty = !Email || !FirstName || !LastName || !Mobile;
    return isEmpty || this.isValidToSubmitReferral();
  }

  formatPayLoadRequest() {
    const existingClient = kiwiSelectProviderExistingClientBuilderForAPI(
      this.postData(),
    );
    const nonExistingClient = kiwiSelectProviderNonExistingClientBuilderForAPI(
      this.postData(),
    );
    return this.isExisting ? existingClient : nonExistingClient;
  }

  sendReferral() {
    this.referralForm.$setSubmitted();
    if (this.isExisting && this.isValidToSubmitReferral()) {
      toastWarning('Please provide all required fields');
      return;
    }
    if (!this.isExisting && this.validateNonExistingClient()) {
      toastWarning('Please provide all required fields');
      return;
    }
    const isValidAddress =
      this.isAddressOverhaulEnabled && this.address.displayDetails;
    if (isValidAddress) {
      return this.saveAddress();
    }
    this.sendKiwiReferral();
  }

  saveAddress() {
    return this.addressLookupService
      .convertToAddressFormat(this.address)
      .then((address) => {
        return this.generalService.addressDetailsSet(address);
      })
      .then(({ data }) => {
        this.clientData.addressId = data;
        return this.sendKiwiReferral();
      });
  }

  handleUpdateAddress({ address }) {
    this.address = address;
  }

  sendKiwiReferral() {
    this.isLoading = true;
    return this.kiwiService
      .postKiwiReferral(this.formatPayLoadRequest())
      .then(({ succeeded, messages }) => {
        succeeded &&
          (() => {
            toastSuccess('Kiwi Select Referral has been saved');
            this.closeModal();
            this.isLoading = !this.isLoading;
          })();
        return this.fatalErrors(messages, succeeded);
      });
  }

  fatalErrors(sections = {}, succeeded) {
    this.isLoading = false;
    !succeeded &&
      this.crmConfirmation.open({
        type: 'warning',
        title: 'Oops, something went wrong!',
        description: `<referral-kiwi-select-error fatal-errors-data="vm.cCommonObject" ></referral-kiwi-select-error>`,
        buttonText: `Close`,
        onConfirm: () => {},
        showCloseButton: true,
        showCancelButton: false,
        modalSize: 'md',
        cancelButtonClass: 'colored',
        renderAsComponent: true,
        commonObject: sections,
        isButtonMaxWidth: true,
      });
  }
}
