import { COLOR } from 'Common/constants/colors';

export const STAT_TYPE = {
  FINANCIAL_YEAR: 4,
  MONTH: 1,
  WEEK: 2,
  DAY: 3,
};

export const COMMISSION_STATS = {
  [STAT_TYPE.FINANCIAL_YEAR]: {
    PROP: 'financialYear',
    TITLE: 'Financial Year to Date',
    MOBILE_TITLE: 'Financial YTD',
    TEXTCOLOR: COLOR.WILD_BLUE_YONDER,
  },
  [STAT_TYPE.MONTH]: {
    PROP: 'month',
    TITLE: 'Month to Date',
    TEXTCOLOR: COLOR.SUMMER_GREEN,
  },
  [STAT_TYPE.WEEK]: {
    PROP: 'week',
    TITLE: 'This Week',
    TEXTCOLOR: COLOR.MAMBA,
  },
  [STAT_TYPE.DAY]: {
    PROP: 'day',
    TITLE: 'Today',
    TEXTCOLOR: COLOR.HIPPIE_BLUE,
  },
};

export const DEFAULT_BROKER_FILTER = 0;

export const MULTI_SELECT_LANG = {
  selectAll: "<span><i class='fa fa-check'></i> Select All</span>",
  reset: "<i class='fa fa-refresh'></i>",
  search: 'Search',
};

export const DOUGHNUT_COLORS = [
  COLOR.VICTORIA,
  COLOR.BALI_HAI,
  COLOR.ROBIN_EGG_BLUE,
  COLOR.MARINER,
  COLOR.BUTTER_CUP,
  COLOR.TERRACOTTA,
  COLOR.PIGEON_POST,
  COLOR.BLUE_VIOLET,
  COLOR.FRUIT_SALAD,
  COLOR.CANNON_PINK,
  COLOR.LOCHMARA,
  COLOR.SUMMER_GREEN,
];

export const FINANCIAL_YEAR_FILTER = 3;

export const HEADER_COUNT = {
  MOBILE: 3,
  DESKTOP: 7,
};
