import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('scrollshadow', function scrollshadow() {
    return {
      restrict: 'A',
      link(scope, element) {
        const el = element[0];

        element.bind('scroll', () => {
          const parent = el.parentElement;
          const tableWrapper = parent.querySelector('.table-column-wrapper');

          if (tableWrapper.classList) {
            if (el.scrollLeft >= 1) {
              tableWrapper.classList.add('active');
            } else {
              tableWrapper.classList.remove('active');
            }
          }
        });
      },
    };
  });
