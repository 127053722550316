export const LEAD_TYPES = {
  FACEBOOK: {
    NAME: 'Facebook',
    DESCRIPTION:
      'Post these images to your Facebook page and link to My Lead Generator using the link below.',
    DOWNLOAD_LINK: {
      LM:
        'https://loanmarket.easyprintui.com/index.php?category=My+Lead+Generator&subcategory=Facebook',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      BYOB: 'https://campaignhub.applynow.finance/',
    },
    IMAGE_SRC: {
      LM: 'assets/images/usersLeadGenerator/lmFacebookExample.PNG',
      BYOB: 'assets/images/usersLeadGenerator/byobFacebookExample.jpg',
    },
    LINK_APPEND:
      '#/get-started?utm_source=facebook&utm_medium=organic&utm_term=myleadgenerator',
    IMAGE_ICON: 'assets/images/usersLeadGenerator/fb.svg',
  },
  LINKEDIN: {
    NAME: 'LinkedIn',
    DESCRIPTION:
      'Post these images to your LinkedIn page and link to My Lead Generator using the link below.',
    DOWNLOAD_LINK: {
      LM:
        'https://loanmarket.easyprintui.com/index.php?category=My+Lead+Generator&subcategory=LinkedIn',
      BYOB: 'https://campaignhub.applynow.finance/',
    },
    IMAGE_SRC: {
      LM: 'assets/images/usersLeadGenerator/lmLinkedinExample.JPG',
      BYOB: 'assets/images/usersLeadGenerator/byobLinkedinExample.PNG',
    },
    LINK_APPEND:
      '#/get-started?utm_source=linkedin&utm_medium=organic&utm_term=myleadgenerator',
    IMAGE_ICON: 'assets/images/usersLeadGenerator/linkedIn.svg',
  },
  GOOGLE_BUSINESS: {
    NAME: 'Google My Business',
    DESCRIPTION:
      'Post these images to your Google My Business listing and link to My Lead Generator using the link below.',
    DOWNLOAD_LINK: {
      LM:
        'https://loanmarket.easyprintui.com/index.php?category=My+Lead+Generator&subcategory=Google+My+Business+post+tiles',
      BYOB: 'https://campaignhub.applynow.finance/',
    },
    IMAGE_SRC: {
      LM: 'assets/images/usersLeadGenerator/lmGmbExample.png',
      BYOB: 'assets/images/usersLeadGenerator/byobGmbExample.jpg',
    },
    LINK_APPEND:
      '#/get-started?utm_source=google&utm_medium=organic&utm_campaign=gmb&utm_term=myleadgenerator',
    IMAGE_ICON: 'assets/images/usersLeadGenerator/google.svg',
  },
  MY_LEAD_GENERATOR: {
    NAME: 'My Lead Generator',
    DESCRIPTION:
      "Use this link across any other channels that aren't shown above.",
    DOWNLOAD_LINK: {},
    IMAGE_SRC: {
      LM: 'assets/images/usersLeadGenerator/lmMyLeadGeneratorExample.PNG',
      BYOB: 'assets/images/usersLeadGenerator/byobMyLeadGeneratorExample.PNG',
    },
    LINK_APPEND: '#/get-started',
    IMAGE_ICON: 'assets/images/usersLeadGenerator/mlg.svg',
  },
};

export const CAMPAIGN_BREEZE = {
  NAME: 'Campaign Breeze',
  PUBLICATIONS: 'https://app.campaignbreeze.com/publications.aspx',
  LINK_APPEND:
    '#/get-started?utm_source=campaignbreeze&utm_medium=email&utm_term=myleadgenerator',
  TUTORIAL_LINK: 'https://www.youtube.com/watch?v=WL211hwHWM8&feature=youtu.be',
  IMAGE_SRC: {
    LM: 'assets/images/usersLeadGenerator/lmCampaignBreezeExample.PNG',
    BYOB: '',
  },
};

export const MY_LEAD_GENERATOR_GUIDE = {
  LM: 'https://www.youtube.com/watch?v=0HT2iAFRtmE&feature=youtu.be',
  BYOB: 'https://www.youtube.com/watch?v=GkLKDFI-884&feature=youtu.be',
};
export const NEW_LEAD_CHANNEL_LINK = '';
export const FAQ_LINK =
  'https://springboard.training/learn/course/312/play/1998/my-lead-generator-faqs';
export const MY_LEAD_GENERATOR_LAUNCH =
  'https://springboard.training/learn/course/312/play/1994/my-lead-generator-launch-video';
