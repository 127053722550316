import angular from 'angular';
import noteTypeModal from './noteTypeModal';
import noteTypeForm from './noteTypeForm';
import noteTypeBadge from './noteTypeBadge';
import noteTypeSelector from './noteTypeSelector';
import noteTypeModalLauncher from './noteTypeModalLauncher';

export default angular
  .module('components.noteTypeModal', [])
  .component('noteTypeModal', noteTypeModal)
  .component('noteTypeForm', noteTypeForm)
  .component('noteTypeBadge', noteTypeBadge)
  .component('noteTypeSelector', noteTypeSelector)
  .component('noteTypeModalLauncher', noteTypeModalLauncher).name;
