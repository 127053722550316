import template from './applicantDetails.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    isPropertyOwner: '<',
    entityTypes: '<',
    onChangeBinding: '&',
    isConsumerPersonal: '<',
    isValidDetails: '<',
    timeGstRegistered: '<',
    tradingYears: '<',
    industryExperience: '<',
    selectedEntityType: '<',
    timeAtAddress: '<',
    timeInEmployment: '<',
    vedaScore: '<',
    residentialStatusId: '<',
  },
};
