import { setupNewPostURL } from 'Common/utilities/handleObsoleteURL';
import { isNotRegisteredNZBNChar } from 'Common/utilities/nzbnChecker';
import { getDays } from 'Common/utilities/calendar';
import { toastError } from 'Common/utilities/alert';

export const employmentModalInstance = (propertyOf) => {
  const validateNZBN = (response) => {
    const myCRMconfigs = {
      ...propertyOf.currentUserService,
      ...propertyOf.configService,
    };
    return isNotRegisteredNZBNChar({ ...response, myCRMconfigs }, 'employment');
  };

  return {
    appendFields: (employmentSet) => {
      const {
        employmentNZBN = '',
        employmentNature = '',
      } = propertyOf.employment;
      return {
        NZBN: employmentNZBN.toString() || '',
        EmploymentNatureID: employmentNature,
        ...employmentSet,
      };
    },
    getEmploymentappendFields: (employmentSet) => {
      const { NZBN = '', EmploymentNatureID = '' } = employmentSet;
      return {
        employmentNZBN: parseInt(NZBN, 10),
        employmentNature: EmploymentNatureID.toString(),
        ...employmentSet,
      };
    },
    clientEntityEmploymentSet: (employementData) => {
      propertyOf.contactService
        .clientEntityEmploymentSet(
          setupNewPostURL(employementData, 'employment'),
        )
        .then((response) => {
          propertyOf.isSavingEmployment = false;
          if (validateNZBN(response)) {
            return;
          }
          propertyOf.modalInstance.close({ isRefreshEmployment: true });
        })
        .catch(toastError);
    },
    setEmployment: (employementData) => {
      propertyOf.loanApplicationServices
        .setEmployment(setupNewPostURL(employementData, 'employment'))
        .then((response) => {
          propertyOf.isSavingEmployment = false;

          if (validateNZBN(response)) {
            return;
          }
          propertyOf.modalInstance.close({
            isRefreshIncome: true,
            FamilyId: propertyOf.employment.FamilyId,
          });
        })
        .catch(toastError);
    },
  };
};

export const getEmploymentDays = (employment, data) => {
  const { DateStarted, DateEnded } = data || employment || {};
  employment.dateStartedDays = getDays({
    month: DateStarted && DateStarted.Month,
    year: DateStarted && DateStarted.Year,
  });
  employment.dateEndedDays = getDays({
    month: DateEnded && DateEnded.Month,
    year: DateEnded && DateEnded.Year,
  });
};
