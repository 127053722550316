export const MONTHS = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

export const MONTH_NAMES = [
  {
    value: 1,
    name: 'January',
  },
  {
    value: 2,
    name: 'Febuary',
  },
  {
    value: 3,
    name: 'March',
  },
  {
    value: 4,
    name: 'April',
  },
  {
    value: 5,
    name: 'May',
  },
  {
    value: 6,
    name: 'June',
  },
  {
    value: 7,
    name: 'July',
  },
  {
    value: 8,
    name: 'August',
  },
  {
    value: 9,
    name: 'September',
  },
  {
    value: 10,
    name: 'October',
  },
  {
    value: 11,
    name: 'November',
  },
  {
    value: 12,
    name: 'December',
  },
];
