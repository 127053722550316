import angular from 'angular';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import './style.scss';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('SatisfactionSurveyCtrl', function SatisfactionSurveyCtrl(
      $scope,
      $window,
      $uibModal,
      NgTableParams,
      npsService,
      userService,
      mycrmLookupService,
      configService,
      jagoIntegrationService,
      toaster,
    ) {
      // Open Model For Demo Video
      $scope.openModalForDemoVideo = function (size) {
        $uibModal.open({
          templateUrl: '/assets/views/partials/demo_video_modal.html',
          controller: 'OpenModalForVideoCtrl',
          size,
          windowClass: 'demo-video-modal-window-class',
        });
      };

      $scope.useJago = configService.feature.jagoNPS;

      $scope.surveyModel = {};
      $scope.surveyModel.noResponse = false;

      $scope.isCorporateUser = false;
      $scope.isPrincipalAdviser = false;
      $scope.isAdviser = false;
      const getTableList = () => {
        $scope.tableParams = new NgTableParams(
          {
            page: 1,
            count: 10, // count per page
            sorting: {
              ResponseReceived: 'desc',
            },
          },
          {
            total: 0, // length of data
            getData(params) {
              $scope.surveyModel.isLoading = true;
              const isSelectedUser = $scope.surveyModel.selectedAdviser > 0;
              const noResponse = $scope.surveyModel.noResponse ? 1 : 0;
              const timePeriod = $scope.surveyModel.timePeriod.OptionDB_value;
              const pageNumber = params.page();
              const countPerPage = params.count();
              const selectedUser = $scope.surveyModel.selectedAdviser;
              const searchText = $scope.surveyModel.searchSurvey;
              const orderBy = params.sorting();
              const orderByKeys = Object.keys(orderBy);
              const sortBy = orderByKeys.length
                ? orderByKeys[0]
                : 'ResponseReceived';
              const sortOrder = (orderBy && orderBy[sortBy]) || 'desc';
              return npsService
                .getSurveyResponse(
                  timePeriod,
                  pageNumber,
                  countPerPage,
                  noResponse,
                  selectedUser,
                  isSelectedUser,
                  0,
                  searchText,
                  sortBy,
                  sortOrder,
                )
                .then((response) => {
                  if (response && response.data) {
                    const data = response.data.SurveyResponses || [];
                    params.total(response.data.SurveyCount);
                    $scope.surveyModel.npsScore = response.data.NpsScore;
                    $scope.surveyModel.promoters = response.data.Promoters;
                    $scope.surveyModel.passives = response.data.Passives;
                    $scope.surveyModel.detractors = response.data.Detractors;
                    $scope.surveyModel.isLoading = false;
                    return data;
                  }
                });
            },
          },
        );
      };
      const getAdviserList = () => {
        const params = {
          allAdviserLabel: !$scope.isAdviser ? 'All Advisers' : '',
        };
        mycrmLookupService.getAdvisers(params).then((data) => {
          $scope.surveyModel.selectedAdviser = 0;
          if ($scope.isAdviser) {
            $scope.surveyModel.selectedAdviser =
              $scope.surveyModel.userFamilyId;
          }
          $scope.surveyModel.adviserList = data;
        });
      };
      const getUserInfo = () => {
        userService.GetUserInfo().then((response) => {
          const data = response.data;
          if (data) {
            $scope.isCorporateUser = data.AccessType === ACCESS_TYPE.CORPORATE;
            $scope.isPrincipalAdviser =
              data.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER;
            $scope.isAdviser = data.AccessType === ACCESS_TYPE.ADVISER;
            getAdviserList();
            getTableList();
            $scope.surveyModel.userFamilyId = data.FamilyId;

            if ($scope.timePeriod && $scope.timePeriod.length) {
              $scope.surveyModel.timePeriod = $scope.timePeriod[0];
            }
          }
        });
      };
      getUserInfo();

      $scope.surveyTableModel = [];

      $scope.timePeriod = [
        {
          OptionID: 15,
          OptionDisplayName: 'All',
          OptionDB_value: '0',
          Option_ValueID: 41,
        },
        {
          OptionID: 15,
          OptionDisplayName: 'This Year',
          OptionDB_value: '1',
          Option_ValueID: 41,
        },
        {
          OptionID: 15,
          OptionDisplayName: 'This Month',
          OptionDB_value: '2',
          Option_ValueID: 41,
        },
        {
          OptionID: 15,
          OptionDisplayName: 'Last Month',
          OptionDB_value: '3',
          Option_ValueID: 41,
        },
        {
          OptionID: 15,
          OptionDisplayName: 'Last 2 Months',
          OptionDB_value: '4',
          Option_ValueID: 41,
        },
        {
          OptionID: 15,
          OptionDisplayName: 'Last 3 Months',
          OptionDB_value: '5',
          Option_ValueID: 41,
        },
        {
          OptionID: 15,
          OptionDisplayName: 'Last Year',
          OptionDB_value: '6',
          Option_ValueID: 41,
        },
      ];

      $scope.timePeriodChange = function () {
        getTableList();
      };

      $scope.isSurveyEdit = [];
      $scope.editSurvey = function (id) {
        $scope.isSurveyEdit[id] = !$scope.isSurveyEdit[id];
      };

      $scope.tickNoResponse = function () {
        getTableList();
      };

      $scope.resendSurvey = function (size, data) {
        const modalInstance = $uibModal.open({
          templateUrl: '/assets/views/communicate/modal/resendSurveyModal.html',
          controller: 'ResendSurveyModalCtrl',
          controllerAs: 'vm',
          size,
          windowClass: '',
          resolve: {
            surveyData: () => data,
          },
        });
        modalInstance.result.then(() => {
          getTableList();
        });
      };
      $scope.searchSurvey = () => {
        getTableList();
      };
      $scope.openJago = () => {
        jagoIntegrationService
          .GetJagoUrl()
          .then((response) => {
            if (response?.data?.Result?.data.fullRedirectUrl) {
              $window.open(response.data.Result.data.fullRedirectUrl, '_blank');
            }
          })
          .catch((error) => {
            toaster.pop('error', error.statusText, error.data);
          });
      };
    });
