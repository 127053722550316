import {
  collectionCamelize,
  collectionCapitalize,
} from 'Common/utilities/collectionConversion';

export function collectionCamelizeBuilderForUI(collection) {
  return collectionCamelize(collection);
}

export const collectionCapitalizeBuilderForUI = (collection) => {
  return collectionCapitalize(collection);
};
