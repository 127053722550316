/* eslint-disable no-undef */
/* eslint-disable angular/document-service */
/* eslint-disable angular/window-service */

// see https://github.com/webpack/webpack/pull/11258 for webpack usage

const getUrl = () => {
  const scriptSrc = document?.currentScript?.src;

  if (scriptSrc) {
    console.log('scriptSrc', scriptSrc);
    return new URL(scriptSrc)?.origin;
  }

  const scriptElement = document.querySelector('#scope-legacyAngular');

  if (!scriptElement) {
    console.log('!scriptElement', window.location.origin);
    return window.location.origin;
  }

  console.log('scriptElement', new URL(scriptElement.src).origin);
  return new URL(scriptElement.src).origin;
};

const LEGACY_ANGULAR_HOST = [
  'LEGACY_ANGULAR_HOST',
  {
    value: getUrl(),
  },
];

export default LEGACY_ANGULAR_HOST;
