import { get } from 'lodash';
import {
  CLIENT_TYPES,
  CONTACT_RECORD_TYPES,
  GENDER_OPTIONS,
  MARKETING_TICKED_OPTIONS,
} from 'Common/constants/options';
import {
  CLIENT_TYPE_IDS,
  CONTACT_RECORD_TYPE_IDS,
} from 'Common/constants/contactType';
import { toastError } from 'Common/utilities/alert';

const defaultValue = -1;

export const multiSelectActions = ({ $scope }) => {
  $scope.multiSelect = {
    onItemClick: (data, scopeType, values = 'value') => {
      const isSelectAll = data[values] === defaultValue;
      if (isSelectAll) {
        $scope[scopeType] = $scope[scopeType].map((list) => {
          list.ticked = list[values] === data[values];
          return list;
        });
      } else {
        $scope.multiSelect.selectAllDefault(scopeType, values);
      }
    },
    selectAllDefault: (scopeType, values = 'value') => {
      const { ticked } = $scope[scopeType].find(
        (list) => list[values] === defaultValue,
      ) || { ticked: false };
      ticked &&
        $scope[scopeType].forEach((list) => {
          if (list[values] === defaultValue) {
            list.ticked = false;
          }
        });
    },
    onSelectAll: (scopeType, values = 'value') => {
      $scope[scopeType] = $scope[scopeType].map((list) => {
        return { ...list, ticked: list[values] === defaultValue };
      });
    },
    onDeselectAll: (scopeType) => {
      $scope[scopeType].forEach((list) => {
        list.ticked = false;
      });
    },
  };
};

export const setFilters = ({ $scope }) => {
  const selectOnlyTicked = (filterType, values = 'value') => {
    const polishFilter =
      ($scope[filterType] &&
        $scope[filterType].filter(
          (types) => types.ticked && types[values] !== defaultValue,
        )) ||
      [];
    return polishFilter
      .map((filtered) => {
        return filtered[values];
      })
      .join(',');
  };

  $scope.employmentReviewClient = () => {
    return {
      employmentType: selectOnlyTicked('employmentType'),
      reviewMonth: selectOnlyTicked('reviewMonth'),
      clientType: selectOnlyTicked('clientType'),
      contactType: selectOnlyTicked('contactType'),
      gender: selectOnlyTicked('genderList'),
      adviser: selectOnlyTicked('adviserList', 'familyId'),
      categories: selectOnlyTicked('categoriesList', 'CategoryID'),
      rating: selectOnlyTicked('ratingsList', 'RatingID'),
      lender: selectOnlyTicked('lenderList', 'ProviderId'),
      loanStatus: selectOnlyTicked('loanStatusList', 'LoanStatusID'),
      loanRateType: selectOnlyTicked('loanRateTypeList', 'type'),
      contactRecordType: $scope.placeholderFilter.contactRecordType,
      marketingConsent: $scope.placeholderFilter.marketingTicked,
    };
  };
};

export const exportRecipientsToCSVUtil = ({
  $scope,
  communicateService,
  query,
}) => {
  const {
    pageSize,
    referralCategoryIds,
    enquirySource,
    ...newCampaignRecipientParam
  } = $scope.campaignRecipientParam;
  communicateService
    .exportRecipientsToCSV(query, newCampaignRecipientParam)
    .then((response) => {
      if (response.data) {
        $scope.downloadCSVFile(response.data);
      } else {
        toastError('No file to download.');
      }
    });
};

export const selectedDefaultValues = (
  label,
  name = 'name',
  value = 'value',
) => {
  return {
    [value]: -1,
    [name]: label,
    ticked: true,
  };
};

export const getDropdownFilters = ({ $scope }) => {
  $scope.contactRecordTypeList = CONTACT_RECORD_TYPES;
  $scope.marketingTickedList = MARKETING_TICKED_OPTIONS;
};

export const getMultiselectFilters = ({
  $scope,
  segmentExportFilterService,
}) => {
  const getFilters = {
    employmentType: () => {
      segmentExportFilterService.getEmploymentTypes().then((response) => {
        $scope.employmentType = [
          selectedDefaultValues('All Employment Types'),
          ...response,
        ];
      });
    },
    reviewMonth: () => {
      segmentExportFilterService.getReviewMonths().then((response) => {
        $scope.reviewMonth = [
          selectedDefaultValues('All Review Months'),
          ...response,
        ];
      });
    },
    clientType: () => {
      segmentExportFilterService.getClientTypes().then((response) => {
        $scope.clientType = [
          selectedDefaultValues('All Client Types'),
          ...response,
        ];
      });
    },
    contactType: () => {
      $scope.contactType = [
        selectedDefaultValues('All Contact Types'),
        ...CLIENT_TYPES,
      ];
    },
    genderList: () => {
      $scope.genderList = [
        selectedDefaultValues('All Gender'),
        ...GENDER_OPTIONS,
      ];
    },
  };

  getFilters.employmentType();
  getFilters.reviewMonth();
  getFilters.clientType();
  getFilters.contactType();
  getFilters.genderList();
};

export const newSegmentFilters = ({ $scope, segmentExportFilterService }) => {
  $scope.placeholderFilter = {
    employmentTypesSelected: [],
    reviewMonthsSelected: [],
    clientTypesSelected: [],
    contactTypeSelected: [],
    contactRecordType: CONTACT_RECORD_TYPE_IDS.INDIVIDUAL,
    marketingTicked: null,
  };

  getDropdownFilters({ $scope });
  getMultiselectFilters({ $scope, segmentExportFilterService });
  multiSelectActions({ $scope });
  setFilters({ $scope });
};

export const getAdvisers = ({ $scope, mycrmLookupService, refreshResults }) => {
  const params = {
    allAdviserLabel: 'All Adviser',
    allAdviserValue: -1,
    alwaysShowAllOption: true,
    allAdviserTicked: true,
    selectCurrentUser: $scope.isAutoLoadingDisabled,
  };
  mycrmLookupService.getAdvisers(params).then((data) => {
    $scope.adviserList = data;
    if ($scope.isAutoLoadingDisabled) {
      return;
    }
    refreshResults('init');
  });
};

export const disableContactRecordType = ({ $scope }) => () => {
  const { placeholderFilter } = $scope;
  const contactTypes = get(placeholderFilter, 'contactTypeSelected', []);
  const entity = contactTypes.find((o) => o.value === CLIENT_TYPE_IDS.ENTITIES);
  const isEntityOnly = contactTypes.length === 1 && entity;
  return !!isEntityOnly;
};

export const disableGenerateButton = ($scope) => () => {
  const isClient = get($scope, 'campaignModel.filterBy', '') === 'client';
  const isAdviserEmpty = !get($scope, 'adviserListSelected.length', 0);
  return (
    $scope.inProgress ||
    ($scope.isAutoLoadingDisabled && isClient && isAdviserEmpty)
  );
};

export const initializeEmailCampaign = ({ $scope, configService }) => {
  $scope.isAutoLoadingDisabled =
    configService.feature.segmentAndExportAutoLoadingDisable;
  $scope.displayEmptyReport = $scope.isAutoLoadingDisabled;
  $scope.adviserEmptyLocalLang = {
    selectAll: '<span>Select All</span>',
    selectNone: '<span>Deselect All </span>',
    reset: '<span>Reset</span>',
    search: 'Search',
    nothingSelected: `<div class='buttonLabel padding-left-10'>Select Adviser</div>`,
  };
  $scope.refreshButtonLabel = $scope.isAutoLoadingDisabled
    ? 'Generate Report'
    : 'Refresh Results';
  $scope.emptyReportMessage =
    'Please click Generate Report button to view results.';
  $scope.disableGenerateButton = disableGenerateButton($scope);
};

export const hideMarketingConsent = ({ $scope }) => () => {
  if (!$scope) {
    return;
  }
  const contactTypes = get($scope, 'placeholderFilter.contactTypeSelected', []);
  const entities = contactTypes.find(
    (item) => item.value === CLIENT_TYPE_IDS.ENTITIES,
  );
  return !!(entities && contactTypes.length === 1);
};
