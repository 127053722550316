import { KEY_CODE } from 'Common/constants/keyCode';

export const handleKeyDownEvent = ({
  $document,
  $timeout,
  scope,
  element,
  attrs,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => (event) => {
  const code = event.keyCode || event.which;
  const arrowKeys = [KEY_CODE.ARROW_KEY_DOWN, KEY_CODE.ARROW_KEY_UP];
  if (arrowKeys.includes(code)) {
    const querySelector = `[tabindex]:not([disabled]):not([tabindex='-1']`;
    const tabbableElements =
      (element &&
        element.length &&
        element[0].querySelectorAll(querySelector)) ||
      [];
    if (!tabbableElements || !tabbableElements.length) {
      return;
    }
    const arrayElements = [...tabbableElements];
    const activeDocument =
      ($document && $document.length && $document[0].activeElement) || false;
    if (!activeDocument) {
      return;
    }
    const index = arrayElements.indexOf(activeDocument);
    const targetIndex =
      code === KEY_CODE.ARROW_KEY_DOWN ? index + 1 : index - 1;
    arrayElements[targetIndex] && arrayElements[targetIndex].focus();
    event.preventDefault();
  } else if (code === KEY_CODE.ESC) {
    if (!attrs.onEscape) {
      return;
    }
    scope.eventTimeout = $timeout(() => scope.$eval(attrs.onEscape));
    event.preventDefault();
  }
};

export const navigateArrowKeysFn = ({ $document, $timeout }) => (
  scope,
  element,
  attrs,
) => {
  const eventHandler = handleKeyDownEvent({
    $document,
    $timeout,
    scope,
    element,
    attrs,
  });
  element.on('keydown', eventHandler);
  scope.$on('$destroy', () => {
    element.off('keydown', eventHandler);
    $timeout.cancel(scope.eventTimeout);
  });
};
