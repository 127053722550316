import { REPAYMENT_FREQUENCY } from '../constants/repayment';

export const getRepaymentFrequency = (isAU) => {
  return [
    { Name: REPAYMENT_FREQUENCY.YEARLY, id: REPAYMENT_FREQUENCY.YEARLY },
    {
      Name: REPAYMENT_FREQUENCY.HALF_YEARLY,
      id: REPAYMENT_FREQUENCY.HALF_YEARLY,
    },
    { Name: REPAYMENT_FREQUENCY.QUARTERLY, id: REPAYMENT_FREQUENCY.QUARTERLY },
    { Name: REPAYMENT_FREQUENCY.MONTHLY, id: REPAYMENT_FREQUENCY.MONTHLY },
    ...(isAU
      ? [
          {
            Name: REPAYMENT_FREQUENCY.HALF_MONTHLY,
            id: REPAYMENT_FREQUENCY.HALF_MONTHLY,
          },
        ]
      : []),
    {
      Name: REPAYMENT_FREQUENCY.FORTNIGHTLY,
      id: REPAYMENT_FREQUENCY.FORTNIGHTLY,
    },
    { Name: REPAYMENT_FREQUENCY.WEEKLY, id: REPAYMENT_FREQUENCY.WEEKLY },
  ];
};
