import template from './customWarning.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    visible: '<',
  },
};
