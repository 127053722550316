import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    .controller('ForLenderDetailModalCtrl', function ForLenderDetailModalCtrl(
      $scope,
      $uibModalInstance,
      lendersSummaryList,
      lenderId,
      productId,
    ) {
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.lenderSummary = _.find(lendersSummaryList, (item) => {
        return parseInt(item.LenderID, 10) === parseInt(lenderId, 10);
      });
      $scope.productDetail = _.find($scope.lenderSummary.products, (item) => {
        return parseInt(item.ProductID, 10) === parseInt(productId, 10);
      });
    });
