export default class ContactInfoLink {
  $onInit() {
    this.isWebsiteOrFax = this.type === 'fax' || this.type === 'web';
    this.initialize();
  }

  $onChanges(changes) {
    const { link } = changes;
    if (!link) {
      return;
    }

    if (link.currentValue !== link.previousValue) {
      this.initialize();
    }
  }

  initialize() {
    this.displayData = this.getDisplayData();
    const phoneTypes = ['phone', 'mobile', 'fax'];
    this.isPhoneType = !!phoneTypes.find((phone) => phone === this.type);

    const formattedData = this.getFormattedLink(this.displayData.contactLink);
    this.formattedLink = formattedData.link;
    this.contactDisplayValue = formattedData.formattedDisplay;
    this.refixText = formattedData.refixText;
  }

  getFormattedLink(link) {
    const emailSplit = link.split('<').map((str) => str.trim());
    const refixText =
      emailSplit.length > 1 && this.type === 'email'
        ? emailSplit[1].slice(0, -1)
        : '';

    const formatted = {
      link,
      refixText,
      formattedDisplay: `${this.displayData.contactLink} ${this.displayData.additionalText}`,
    };

    switch (this.type) {
      case 'email':
        formatted.link = `mailto:${emailSplit[0]}`;
        formatted.formattedDisplay = emailSplit[0];
        return formatted;
      case 'fax':
        formatted.link = `tel:${link}`;
        formatted.formattedDisplay = this.link;
        return formatted;
      case 'phone':
        formatted.link = `tel:${link}`;
        return formatted;
      case 'web':
        formatted.formattedDisplay = this.link;
        return formatted;
      default:
        formatted.link = link;
        return formatted;
    }
  }

  getDisplayData() {
    const splitData = this.link.split('|').map((str) => str.trim());
    return {
      contactLink: splitData[0],
      additionalText: splitData.length > 1 ? `(${splitData[1]})` : '',
    };
  }
}
