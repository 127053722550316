import { get } from 'lodash';
import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';
import { FACT_FIND_TYPE } from 'Common/constants/factFind';

class FileFactFindWidgetCtrl {
  constructor(
    $window,
    contactModelService,
    onlineFactFindModal,
    onlineFactFindService,
    opportunityLoanAppService,
    helloPackService,
    uiService,
    helloBookPreviewService,
    $state,
  ) {
    'ngInject';

    this.$window = $window;
    this.onlineFactFindModal = onlineFactFindModal;
    this.contactModelService = contactModelService;
    this.onlineFactFindService = onlineFactFindService;
    this.opportunityLoanAppService = opportunityLoanAppService;
    this.helloPackService = helloPackService;
    this.uiService = uiService;
    this.helloBookPreviewService = helloBookPreviewService;
    this.$state = $state;
  }

  $onChanges(changes) {
    const { fileGeneralDetails, scenarioDetails, applicants } = changes;

    if (fileGeneralDetails && fileGeneralDetails.currentValue) {
      this.getSubscriptionPopUp();
    }

    if (scenarioDetails && scenarioDetails.currentValue) {
      this.isLockable = this.scenarioDetails.IsLockable;
      this.isActive = !this.scenarioDetails.IsLocked;
    }

    if (applicants && applicants.currentValue) {
      this.getClientInfo();
    }
  }

  getClientInfo() {
    if (!this.loanAppId) {
      return;
    }

    this.onlineFactFindModal
      .getInvolvePartyToReview(this.loanAppId)
      .then((response) => {
        if (!response) {
          return;
        }

        const clients = get(response, 'clients', []);
        this.clientDropdown = clients
          .sort((a, b) => (a.isGuarantor > b.isGuarantor ? 1 : -1))
          .map((client) => ({
            text: client.fullName,
            action: client.clientId,
          }));
        this.isReviewFactFindShow = !!clients.length;
        this.redirectionURL = response.link;
      });
  }

  getSubscriptionPopUp() {
    const params = {
      brokerId: this.fileGeneralDetails.adviserId,
      tickedItemId: TICKABLE_ITEMS.INTERCEPT,
    };
    this.contactModelService.getSubscriptionPopUp(params).then((data) => {
      this.showPopUp = !!data.showPopUp;
    });
  }

  viewFactFind({ data }) {
    const validFields = this.redirectionURL && data && this.loanAppId;
    if (validFields) {
      this.$window.open(`${this.redirectionURL}${data}/${this.loanAppId}`);
    }
  }

  openInterceptModal(type = FACT_FIND_TYPE.GENERAL) {
    const props = {
      familyId: this.fileGeneralDetails.adviserId,
    };

    this.onlineFactFindModal.openInterceptModal(props).then((response) => {
      if (response) {
        this.openFactFind = response;
        this.openGenerateFactFindModal(type);
      }
    });
  }

  factFindModalNextStep(modalResponse) {
    if (!modalResponse) {
      return;
    }

    const { factFindSend, factFindType } = modalResponse;
    if (factFindSend) {
      this.getClientInfo();
      this.opportunityLoanAppService.executeCallback('refreshScenarioDetails');
    } else if (!factFindSend && factFindType) {
      this.openGenerateFactFindModal(factFindType);
    }
  }

  openGenerateFactFindModal(type = FACT_FIND_TYPE.GENERAL, clientId = 0) {
    const openInterceptModal =
      this.showPopUp &&
      !this.openFactFind &&
      (type === FACT_FIND_TYPE.GENERAL || type === FACT_FIND_TYPE.INVITE);
    if (openInterceptModal) {
      this.openInterceptModal(type);
      return;
    }

    const params = {
      loanId: this.loanAppId,
      type,
      isShowReviewButton: this.isReviewFactFindShow,
      clientId,
    };
    if (this.uiService.newHelloModalEnabled) {
      this.helloBookPreviewService.open({
        familyId:
          this.$state.params.familyId || this.$state.params.sourceFamilyId,
        isForLoanWriters: null,
        isNewModal: true,
        loanApplicationId: this.loanAppId,
        selectedService: '',
        onlyFactFind: true,
      });
    } else {
      this.onlineFactFindModal.open(params).then((response) => {
        this.factFindModalNextStep(response);
      });
    }
  }

  inviteToFactFind({ data }) {
    this.openGenerateFactFindModal(FACT_FIND_TYPE.INVITE, data);
  }

  toggleLockStatus() {
    this.onlineFactFindService.toggleLockStatus(this.scenarioDetails);
  }
}

export default FileFactFindWidgetCtrl;
