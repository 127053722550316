class ShowDocumentListOnHoverCtrl {
  constructor() {
    'ngInject';

    this.showDocumentList = false;
  }

  iconClick(event) {
    this.showDocumentList = !this.showDocumentList;
    event.stopPropagation();
  }

  offClick() {
    this.showDocumentList = false;
  }
}

export default ShowDocumentListOnHoverCtrl;
