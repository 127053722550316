import template from './lenderLogo.html';
import LenderLogoCtrl from './lenderLogoCtrl';

export default {
  template,
  controller: ['uiService', 'LEGACY_ANGULAR_HOST', '$element', LenderLogoCtrl],
  controllerAs: 'vm',
  bindings: {
    lenderName: '<?',
    lenderId: '<?',
    countryCode: '<?',
    shownOnError: '<?',
    width: '<?',
    height: '<?',
    isLarge: '<?',
    isBordered: '<?',
    showAltOnError: '<?',
    src: '<?',
    ngStyle: '<?',
    isWrapped: '<?',
    forceRenderSrc: '<?',
  },
};
