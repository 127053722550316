import { toastError, toastSuccess } from 'Common/utilities/alert';
import {
  LEAD_TYPES,
  CAMPAIGN_BREEZE,
  NEW_LEAD_CHANNEL_LINK,
  FAQ_LINK,
  MY_LEAD_GENERATOR_GUIDE,
  MY_LEAD_GENERATOR_LAUNCH,
} from 'Common/constants/leadLinks';
import { copyToClipboard } from 'Common/utilities/clipboard';

class UserMyLeadGeneratorCtrl {
  constructor(
    $state,
    $window,
    $uibModal,
    configService,
    crmConfirmation,
    currentUserService,
    corporateService,
    corporateModelService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$window = $window;
    this.$uibModal = $uibModal;
    this.configService = configService;
    this.crmConfirmation = crmConfirmation;
    this.currentUserService = currentUserService;
    this.corporateService = corporateService;
    this.corporateModelService = corporateModelService;
  }

  $onInit() {
    this.isShownLink = false;
    this.familyId = this.currentUserService.familyId;
    this.clientId = this.currentUserService.clientId;
    this.isByobUser = this.currentUserService.isBYOB;
    this.getUserInfo();
    this.userLeadTypes = LEAD_TYPES;
    this.isShowToolkit = !this.isByobUser;
    this.leadGeneratorChannel = CAMPAIGN_BREEZE;
    this.myLeadGeneratorGuide = MY_LEAD_GENERATOR_GUIDE;
    this.newLeadChannelLink = NEW_LEAD_CHANNEL_LINK;
    this.faqLink = FAQ_LINK;
    this.myLeadGeneratorLaunch = MY_LEAD_GENERATOR_LAUNCH;
    this.isGmailSignatureAdditionalLinksEnable = this.configService.feature.gmailSignatureAdditionalLinks;
  }

  subscribeClick() {
    this.corporateModelService
      .setMyLeadGeneratorLink(this.familyId)
      .then((response) => {
        this.myLeadGeneratorUrl = response.data || '';
        if (this.myLeadGeneratorUrl === '') {
          toastError();
        } else {
          this.isSubscribed = response.succeeded;
          this.openSuccessModal();
        }
      });
  }

  redirectToGmailSignature(state) {
    if (!this.isGmailSignatureAdditionalLinksEnable) {
      return;
    }
    return () => {
      state.go('app.users', {
        activeTab: 'email-signature',
        isActivateLeadGeneratorSwitch: true,
      });
    };
  }

  openSuccessModal() {
    this.crmConfirmation.open({
      type: 'success',
      title: 'Your My Lead Generator link has been activated',
      buttonText: 'Okay, got it!',
      modalSize: 'md',
      isButtonMaxWidth: '150px',
      onConfirm: this.redirectToGmailSignature(this.$state),
    });
  }

  getUserInfo() {
    this.corporateService
      .familyMarketingDetailGet(this.familyId, this.clientId)
      .then(({ data }) => {
        this.isSubscribed = data.MyLeadGenActivated || false;
        this.myLeadGeneratorUrl = data.MyLeadGenLink || '';
      });
  }

  onCopyLink(stringLink) {
    const copyLink = `${this.myLeadGeneratorUrl}${stringLink}`;
    copyToClipboard(this.$window, copyLink);
    toastSuccess('Link has been copied to clipboard');
  }

  onOpenImageModal(imageSrc, leadIcon, leadName) {
    const imageFile = this.isByobUser ? imageSrc.BYOB : imageSrc.LM;
    const props = {
      imageModal: imageFile,
      iconModal: leadIcon || '',
      nameModal: leadName,
    };
    this.$uibModal.open({
      animation: true,
      template: `<lead-image-modal
                        modal-instance="vm.modalInstance"
                        image-src="vm.props.imageModal"
                        lead-icon="vm.props.iconModal"
                        lead-name="vm.props.nameModal">
                      </lead-image-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'lead-image-modal',
      resolve: {
        props,
      },
      size: 'sm',
    });
  }

  onOpenNewTab(tabLink) {
    this.$window.open(tabLink, '_blank');
  }

  openGuideVideo() {
    const videoLink = this.isByobUser
      ? this.myLeadGeneratorGuide.BYOB
      : this.myLeadGeneratorGuide.LM;
    this.onOpenNewTab(videoLink);
  }
}

export default UserMyLeadGeneratorCtrl;
