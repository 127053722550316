import angular from 'angular';
import { get } from 'lodash';

class CountrySelectorInputCtrl {
  constructor(
    countryCodeService,
    configService,
    currentUserService,
    $window,
    $timeout,
  ) {
    'ngInject';

    this.countryCodeService = countryCodeService;
    this.configService = configService;
    this.currentUserService = currentUserService;
    this.angular = angular;
    this.window = $window;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.isAU = this.currentUserService.isAU;
    this.isNumberValid = true;
    this.isLandline = !!this.isLandline;
    this.setCountryCodeOnNullPhoneNumber();
    this.isCountrySelectorActive = false;
    this.previousNumber = '';
    this.setStyleVariables();
    this.setModelOptions();
  }

  $onDestroy() {
    this.onSaveFocusTimeout && this.$timeout.cancel(this.onSaveFocusTimeout);
  }

  setStyleVariables() {
    this.countrySelectorContainerRight =
      this.countrySelectorContainerRight || 0;
  }

  setFormValidity(phoneNumber) {
    if (!this.useManualValidation) {
      return;
    }
    const isFormValid =
      !this.isRequired ||
      (this.isRequired && phoneNumber && this.isNumberValid);
    const isValidatorValid =
      this.selectorForm &&
      this.selectorForm[this.countrySelectorName] &&
      typeof this.selectorForm[this.countrySelectorName].$setValidity ===
        'function';
    isValidatorValid &&
      this.selectorForm[this.countrySelectorName].$setValidity(
        '$valid',
        isFormValid,
      );
  }

  isMobileValidation({ phoneNumber, inputId }) {
    this.getCurrentNumber && this.getCurrentNumber({ phoneNumber });

    this.isNumberValid = true;

    if (!phoneNumber) {
      if (typeof phoneNumber === 'undefined') {
        this.isNumberValid = false;
      }
      return;
    }

    const countryCode = this.countryCodeService.getSelectedCountryCode(inputId);
    this.isNumberValid =
      this.isLandline ||
      this.countryCodeService.isNumberMobile({
        mobileNumber: phoneNumber,
        countryCode,
      });
    this.phoneCountryCode = get(
      countryCode,
      'iso2',
      this.currentUserService.countryCode.toLowerCase(),
    );
    this.setFormValidity(phoneNumber);
  }

  setModelOptions() {
    const defaultDebounceValue = 1800;
    const debounce = this.onChange ? defaultDebounceValue : 0;
    this.modelOptions = {
      debounce,
    };
  }

  setCountryCodeOnNullPhoneNumber() {
    if (!this.countrySelectorModel) {
      this.phoneSelectedCountry = this.initializeCountryCode();
      this.phoneCountryCode = this.initializeCountryCode();
    }
  }

  initializeCountryCode() {
    return this.isAU ? 'au' : 'nz';
  }

  keepNumberOnCountryChange() {
    const inputId = this.countrySelectorId;
    const countryCode = this.countryCodeService.getSelectedCountryCode(inputId);
    const input = this.countryCodeService.getInputElement(inputId);

    const isInvalidPreviousCode =
      this.previousCountryCode &&
      this.previousCountryCode.dialCode === undefined &&
      countryCode.dialCode !== undefined;

    if (isInvalidPreviousCode) {
      const prevNumber = this.previousNumber.replace(/^\+[\dX]/, '');
      input.value = `+${countryCode.dialCode}${prevNumber}`;
    }

    this.previousNumber = input.value;
    this.previousCountryCode = countryCode;
  }

  checkSelectedCountryCode() {
    this.keepNumberOnCountryChange();

    const countrySelectorClassName = 'selected-flag';
    const activeSelectorClass = this.angular
      .element(this.window.document.activeElement)
      .attr('class');
    this.isCountrySelectorActive = false;

    if (activeSelectorClass === countrySelectorClassName) {
      this.isCountrySelectorActive = true;
    }
  }

  onValidateFocus(functionCall) {
    this.checkSelectedCountryCode();

    this.onSaveFocusTimeout = this.$timeout(() => {
      if (!this.isCountrySelectorActive) {
        const phoneNumber = this.countrySelectorModel;
        const inputId = this.countrySelectorId;
        this.isMobileValidation({ phoneNumber, inputId });
      }

      const data = { hasError: this.isError || !this.isNumberValid };
      this[functionCall] && this[functionCall]({ data });
    }, 100);
  }
}

export default CountrySelectorInputCtrl;
