export const DATE_PATTERN =
  '(0[1-9]|1[0-9]|2[0-9]|3[01])\\s\\w{3}\\s\\d{4}|\\d{1,2}/\\d{1,2}/\\d{4}|([0-9]{8})';
export const DD_MMM_YY_FORMAT = 'DD MMM YY';
export const UI_MASK_DATE = '99/99/9999';
export const NZ_DATE_INPUT_FORMAT = 'DD-MM-YYYY';
export const TO_MYCRM_DATE_FORMAT = 'YYYY-MM-DD';
export const D_MMM_YYYY_FORMAT = 'D MMM YYYY';
export const D_MMM_FORMAT = 'D MMM';
export const ALT_DATE_INPUT_FORMATS = ['d!/M!/yyyy', 'd!M!yyyy'];
export const DEFAULT_NULL_DATE = '1/1/1900';
export const DD_MMM_YYYY_FORMAT = 'dd MMM yyyy';
export const DD_MM_YYYY_FORMAT = 'dd/MM/yyyy';
export const YYYY_MM_DD_DESERIALIZED = 'YYYY-MM-DDT00:00:00.SSS[Z]';
export const DD_MM_YYYY_HH_MM_A_FORMAT = 'DD/MM/YYYY hh:mma';
export const DATE_HOUR_TEXT_FORMAT = 'ddd DD MMM YYYY, hh:mm a';
export const DATE_TEXT_FORMAT = 'DD MMM YYYY';
export const DD_MM_YYYY_DASHED_FORMAT = 'dd-MM-yyyy';
export const DD_MMMM_YYYY_FORMAT = 'dd MMMM yyyy';
export const D_MMMM_YYYY_FORMAT = 'D MMMM YYYY';
export const DD_MM_YYYY_MOMENT_FORMAT = 'DD/MM/YYYY';
export const MM_DD_YYYY_MOMENT_FORMAT = 'MM/DD/YYYY';
export const YYYY_MM_DD_MOMENT_FORMAT = 'YYYY-MM-DD hh:mm';
export const DATE_PICKER_UTC_MOMENT_FORMAT = 'DD MMM YYYY 00:00+00:00';
export const UNIX_MULTIPLIER = 1000;
export const DDMMMYYYY_FORMAT = 'DDMMMYYYY';
export const CAPS_DD_MMMM_YYYY = 'DD MMMM YYYY';
export const FULL_TIME_AND_DATE_FORMAT = 'hh:mm a, D MMM, YYYY';
export const DATE_TIME_TZ_FORMAT = 'DD/MM/YYYY hh:mma (z Z)';
export const D_MMM_YY_HH_MM_FORMAT = 'D MMM YY, hh:mm';
