import template from './benefitsNotes.html';
import controller from './benefitsNotesCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    getBenefitsNotes: '&',
    modalInstance: '<',
    insuranceData: '<',
  },
};
