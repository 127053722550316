import angular from 'angular';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';
import { getTrustedHtml } from 'Common/utilities/string';
import {
  downloadGmailAttachment,
  checkHideDeleteTransaction,
} from './util/viewEmailCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('ViewEmailCtrl', function ViewEmailCtrl(
      $scope,
      $sce,
      $uibModalInstance,
      familyId,
      emailId,
      removeEmail,
      loadEmailList,
      contactService,
      $window,
      generalService,
      gmailEmail,
      $timeout,
      contactModelService,
      downloadDataService,
      configService,
    ) {
      $scope.emailIdModal = emailId;
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      const { hideDeleteTransaction } = configService.feature;

      if ($scope.emailIdModal) {
        contactService
          .getEmail(familyId, $scope.emailIdModal)
          .then((response) => {
            if (response.data && Object.keys(response.data).length) {
              $scope.emailDetail = response.data;
              getTrustedHtml({
                scope: $scope,
                sce: $sce,
              });
              $scope.isHideDeleteTransaction = checkHideDeleteTransaction({
                emailSubject: $scope.emailDetail.Subject,
                isHideDeleteFlagEnabled: hideDeleteTransaction,
              });
            }
          });

        $scope.downloadDocument = function (documentID) {
          generalService.documentGet(documentID).then((response) => {
            if (response.data) {
              const eventTimeout = $timeout(() => {
                const data = response.data;

                // Convert the Base64 string back to text.
                const byteString = $window.atob(data.DocumentContent);
                const a = $window.document.createElement('a');

                // Convert that text into a byte array.
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                  ia[i] = byteString.charCodeAt(i);
                }

                const blob = new $window.Blob([ia], { type: data.ContentType });

                a.href = $window.URL.createObjectURL(blob);
                a.download = data.Name;
                a.click();
                $timeout.cancel(eventTimeout);
              });
            }
          });
        };

        $scope.removeEmail = function (removeEmailId) {
          swal(
            {
              title: 'Please confirm action',
              text:
                'Are you sure you want to delete this transaction? This action cannot be undone',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#FA8E91',
              confirmButtonText: 'Delete Email',
              closeOnConfirm: false,
            },
            (isConfirm) => {
              if (isConfirm) {
                contactService
                  .removeEmail(familyId, removeEmailId)
                  .then(() => {
                    swal('Deleted', 'Transaction has been deleted.', 'success');
                    loadEmailList();
                    $scope.cancel();
                  })
                  .catch(displayError);
              }
            },
          );
        };
      } else {
        $scope.emailDetail = gmailEmail;
        getTrustedHtml({
          scope: $scope,
          sce: $sce,
        });
        $scope.downloadDocument = downloadGmailAttachment({
          contactModelService,
          downloadDataService,
          gmailEmail,
          familyId,
        });
      }
    });
