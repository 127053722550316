import { toastError } from 'Common/utilities/alert';
import {
  DESKTOP_MAX_SLIDE,
  TABLET_MAX_SLIDE,
  PHONE_MAX_SLIDE,
  TABLET_SLICK_BP,
  PHONE_SLICK_BP,
} from 'Common/constants/slickConfig';

class LendingExistingProductComparisonsCtrl {
  constructor($timeout, serviceabilityService) {
    'ngInject';

    this.$timeout = $timeout;
    this.serviceabilityService = serviceabilityService;
  }

  $onInit() {
    if (!this.familyList || !this.familyList[0]) {
      return;
    }

    this.selectedLoanStructureId = null;
    this.cacheComparison = {};

    this.selectedFamilyId = this.familyList[0].familyId;
    this.getSavedProductComparisons();
  }

  getSavedProductComparisons() {
    if (this.saving) {
      return;
    }

    this.selectedLoanStructureId = null;

    if (this.cacheComparison[this.selectedFamilyId]) {
      this.comparisons = [...this.cacheComparison[this.selectedFamilyId]];
      this.setSlickSettings();

      return;
    }

    return this.serviceabilityService
      .getSavedProductComparisons(this.selectedFamilyId)
      .then((result) => {
        this.comparisons = result;

        this.cacheComparison[this.selectedFamilyId] = result;

        this.setSlickSettings();
      });
  }

  selectComparison(comparison) {
    if (this.saving) {
      return;
    }

    this.selectedComparison = comparison;
  }

  save() {
    this.saving = true;

    return new Promise((resolve) =>
      this.deleteAllFavoriteProducts
        ? resolve(this.deleteAllFavoriteProducts(this.facility))
        : resolve(true),
    )
      .then((result) => {
        if (!result) {
          this.saving = false;
          throw new Error(
            'Something went wrong when deleting your product, please try again',
          );
        }

        const targetModel = {
          BrokerEventId: this.selectedComparison.brokerEventId,
          ProductLoanStructureId: this.selectedComparison.loanStructureId,
        };

        const sourceModel = {
          brokerEventId: this.facility.BrokerEventId,
          loanStructureId: this.facility.LoanStructureId,
          productLoanStructureId: this.facility.FavouriteProductData[0]
            .LoanStructureID,
        };

        return this.serviceabilityService.setCopyProductComparison(
          sourceModel,
          targetModel,
        );
      })
      .then((result) => {
        if (result) {
          this.closeModal({ isRefresh: !!result.succeeded });
        }
      })
      .catch((error) => {
        toastError(error);
      });
  }

  setSlickSettings() {
    const desktopCountSlides =
      this.comparisons.length < DESKTOP_MAX_SLIDE
        ? this.comparisons.length
        : DESKTOP_MAX_SLIDE;
    const mdCountSlides =
      this.comparisons.length < TABLET_MAX_SLIDE
        ? this.comparisons.length
        : TABLET_MAX_SLIDE;

    this.slickConfig = null;
    if (this.searchingTimeOut) {
      this.$timeout.cancel(this.searchingTimeOut);
    }

    this.searchingTimeOut = this.$timeout(() => {
      this.slickConfig = {
        enabled: true,
        draggable: true,
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: desktopCountSlides,
        slidesToScroll: desktopCountSlides,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: TABLET_SLICK_BP,
            settings: {
              slidesToShow: mdCountSlides,
              slidesToScroll: mdCountSlides,
            },
          },
          {
            breakpoint: PHONE_SLICK_BP,
            settings: {
              slidesToShow: PHONE_MAX_SLIDE,
              slidesToScroll: PHONE_MAX_SLIDE,
            },
          },
        ],
      };
    }, 200);
  }

  closeModal(data) {
    this.modalInstance.close(data);
  }

  $onDestroy() {
    this.$timeout.cancel(this.searchingTimeOut);
  }
}

export default LendingExistingProductComparisonsCtrl;
