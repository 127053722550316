import { unmatchedCommissionBuilderForAPI } from 'Common/mappers/commission';
import { catchError } from 'Common/utilities/promise';

export const searchClient = (vm) => (commission, delay) => {
  if (vm.searchClientFilter && vm.searchClientFilter.length >= 3) {
    if (delay) {
      vm.$timeout(() => {
        vm.getClientSearchResults(commission, vm.searchClientFilter, true);
      }, 1500);
    } else {
      vm.getClientSearchResults(commission, vm.searchClientFilter);
    }
  }
};

export const getClientSearchResults = (vm) => (commission, fromAutoSearch) => {
  if (
    !fromAutoSearch ||
    !vm.isSearchingClient ||
    vm.searchClientFilter !== vm.previousFilter
  ) {
    const params = {
      searchString: vm.searchClientFilter,
      providerId: commission.ProviderId || commission.providerId,
      adviserId: vm.brokerFilter || 0,
    };

    vm.isSearchingClient = true;
    vm.previousFilter = vm.searchClientFilter;
    vm.contactModelService
      .commissionContactsList(params)
      .then((response) => {
        vm.isSearchingClient = false;
        if (!response) {
          return;
        }
        vm.searchClientResults = response;
        commission.showSearchResults = true;
      })
      .catch(catchError);
  }
};

export const clearSearchResultsFor = (vm) => (commission) => {
  if (commission) {
    commission.showSearchResults = false;

    if (vm.searchClientResults && vm.searchClientResults.length) {
      vm.searchClientResults.length = 0;
    }
  }
  vm.searchClientFilter = '';
};

export const onClientSelected = ({ vm, onSuccessCallback }) => (
  commission,
  selectedClient,
) => {
  if (selectedClient) {
    vm.clearSearchResultsFor(commission);
    const mapCommission = unmatchedCommissionBuilderForAPI(commission);
    const modalInstance = vm.$uibModal.open({
      templateUrl: 'assets/views/commission/modal/matchCommission.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'MatchCommissionCtrl as vm',
      windowClass: 'match-commissions-modal',
      resolve: {
        commission: () => mapCommission,
        familyIdToMatch: () => selectedClient.familyId,
      },
    });

    modalInstance.result
      .then((response) => {
        if (response && response.success && response.matchedCommission) {
          onSuccessCallback && onSuccessCallback();
        }
      })
      .catch(catchError);
  }
};
