import template from './futureGoals.html';
import controller from './futureGoalsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
  },
};
