export const ADDRESS_TYPE = {
  CURRENT_ADDRESS: 1,
  OTHER: 5,
  POST_SETTLEMENT_ADDRESS: 3,
  POSTAL_ADDRESS: 2,
  PREVIOUS_ADDRESS: 4,
};

export const ADDRESS_TYPE_NAME = {
  POST_SETTLEMENT_ADDRESS: 'Post-Settlement Address',
  CURRENT_ADDRESS: 'Current Address',
  PREVIOUS_ADDRESS: 'Previous Address',
};

export const ADDRESS_VALIDITY = {
  YEAR: 12,
  YEARS: 3,
  MONTHS: 36,
};

export const ADDRESS_MODAL_CALLER = {
  USER_PROFILE: 'user-profile',
};
