import moment from 'moment';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  DD_MMM_YYYY_FORMAT,
  ALT_DATE_INPUT_FORMATS,
} from 'Common/constants/dateConfigs';

export function assignDefaultDate(args) {
  if (!args) {
    return {};
  }
  const { importantDateField, loanDetails } = args;
  const isInvalid =
    !importantDateField || !importantDateField.length || !loanDetails;
  if (isInvalid) {
    return {};
  }
  importantDateField.map((dateField) => {
    if (
      !loanDetails[dateField.field] &&
      dateField.id === IMPORTANT_DATE_TYPES.DELETE.APPROVALEXPIRY
    ) {
      const preApprovedDate = loanDetails.PreApprovedDate
        ? moment.parseZone(new Date(args.loanDetails.PreApprovedDate))._d
        : moment.parseZone(new Date())._d;
      preApprovedDate.setDate(
        preApprovedDate.getDate() +
          IMPORTANT_DATE_TYPES.PRE_APPROVED_EXPIRY_DEFAULT_LENGTH,
      );
      loanDetails[dateField.field] = preApprovedDate;
    } else if (!loanDetails[dateField.field]) {
      loanDetails[dateField.field] = moment.parseZone(new Date())._d;
    } else {
      loanDetails[dateField.field] = moment.parseZone(
        loanDetails[dateField.field],
      )._d;
    }
    return dateField;
  });
  return loanDetails;
}

export const isModalClose = (vm) => {
  const isValid = vm && (vm.modalInstance || vm.onClose);
  if (!isValid) {
    return false;
  }
  if (vm.modalInstance) {
    vm.modalInstance.close();
    return true;
  }
  vm.onClose({ key: vm.loanDetails.LoanID });
  return false;
};

export const confirmDatesFunc = ({ vm, $timeout }) => (
  importantDatesService,
) => {
  const isInvalidCondition =
    !vm.loanDetails || !vm.importantDateField || !vm.importantDateField.length;
  if (isInvalidCondition) {
    return;
  }
  const { loanDetails, importantDateField, familyId } = vm;
  const params = {
    loanDetails,
    importantDateField,
    familyId,
  };

  vm.isSubmittingDates = true;
  importantDatesService
    .confirmDates(params)
    .then((response) => {
      vm.isSubmittingDates = false;
      if (!response) {
        return;
      }

      const isLoanSettled =
        parseToInt10(loanDetails.LoanStatus.LoanStatusID) ===
          LOAN_STATUS.EXISTING.SETTLED && loanDetails.SettlementDate;
      if (isLoanSettled) {
        vm.loanFinancialsService.showAutoCreatedFinancialsOnSettlement({
          params: {
            familyId,
            loanAppId: loanDetails.LoanScenarioID,
          },
        });
      }

      vm.showSuccessMessage = true;
      $timeout(() => {
        isModalClose(vm);
      }, 1000);
    })
    .catch(() => {
      vm.isSubmittingDates = false;
    });
};

export const isRequired = ({ vm }) => (key, requiredStatuses) => {
  const overrideRequired =
    vm.overrideRequiredDates || !key || !requiredStatuses;
  if (vm.loanDetails && vm.overrideRequiredDates) {
    vm.loanDetails.isInvalidForm = false;
  }
  if (overrideRequired) {
    return false;
  }
  const value = (vm.loanDetails && vm.loanDetails[key]) || '';
  const loanStatusId =
    (vm.loanDetails &&
      vm.loanDetails.LoanStatus &&
      vm.loanDetails.LoanStatus.LoanStatusID) ||
    0;
  const corporateExemptedDateForSettled = ['SubmittedDate', 'ApprovalDate'];
  const isExemptedDate =
    vm.currentUserService.isCorporate &&
    parseInt(loanStatusId, 10) === LOAN_STATUS.EXISTING.SETTLED &&
    corporateExemptedDateForSettled.includes(key);
  if (isExemptedDate) {
    return false;
  }
  if (vm.loanDetails) {
    vm.loanDetails.isInvalidForm = !!(
      vm.importantDateForm && vm.importantDateForm.$invalid
    );
  }
  return (
    !value &&
    requiredStatuses &&
    requiredStatuses.includes(parseInt(loanStatusId, 10))
  );
};

export function setReverseOrderDates({ vm }) {
  const isImportantDateValid =
    vm.importantDateField && vm.importantDateField.length;
  const isLoanDetailsValid =
    vm.loanDetails &&
    vm.loanDetails.LoanStatus &&
    vm.loanDetails.LoanStatus.LoanStatusID;
  if (!isImportantDateValid || !isLoanDetailsValid) {
    return;
  }
  const reverseOrderStatus = [
    LOAN_STATUS.EXISTING.SETTLED,
    LOAN_STATUS.IN_PROGRESS.CONDITIONAL_APPROVAL,
    LOAN_STATUS.IN_PROGRESS.UNCONDITIONAL_APPROVAL,
    LOAN_STATUS.IN_PROGRESS.PENDING_SETTLEMENT,
  ];
  if (
    reverseOrderStatus.includes(
      parseInt(vm.loanDetails.LoanStatus.LoanStatusID, 10),
    )
  ) {
    vm.importantDateField =
      (vm.importantDateField && vm.importantDateField.reverse()) || [];
  }
}

export const initializePopover = (vm) => {
  setReverseOrderDates({ vm });
  const args = {
    loanDetails: vm.loanDetails,
    importantDateField: vm.importantDateField,
  };
  vm.loanDetails = assignDefaultDate(args);
  vm.isOpenDate = {};
  vm.format = DD_MMM_YYYY_FORMAT;
  vm.dateOptions = {
    formatYear: 'yy',
    showWeeks: false,
  };
  vm.dateInputFormats = ALT_DATE_INPUT_FORMATS;
  vm.importantDateTypes = IMPORTANT_DATE_TYPES;
  vm.confirmDatesFunc = confirmDatesFunc({
    vm,
    $timeout: vm.$timeout,
  });
};
