import get from 'lodash/get';
import { HELLOBOOK_VALID_EXTENSIONS } from 'Common/constants/document';
import { FILE_ATTACHMENT_LIMIT } from 'Common/constants/helloBook';
import { extractFileExtension } from 'Common/utilities/downloadHelper';
import { HELLO_BOOK } from 'Common/constants/promptMessages';
import { toastError } from 'Common/utilities/alert';
import { getTemporaryUniqueId } from 'Common/utilities/math';

export default class HelloPackAttachmentCtrl {
  $onInit() {
    this.validDocumentExtensions = `.${HELLOBOOK_VALID_EXTENSIONS.join(',.')}`;
    this.currentDeleteSelection = '';
  }

  $onChanges({ attachedFiles }) {
    this.filesLimitExceed =
      get(attachedFiles, 'currentValue.length', 0) >= FILE_ATTACHMENT_LIMIT;
  }

  onSelectFileAttachments({ $e }) {
    const files = [...get($e, 'target.files', [])];
    const isValidFileUpload =
      this.isValidFilesExtension(files) && !this.isFilesLimitExceed(files);
    if (!isValidFileUpload) {
      return;
    }

    const attachedFiles = files.reduce((accum, file, index) => {
      const newFile = {
        id: getTemporaryUniqueId(index),
        name: file.name,
        showConfirmDeletePopover: false,
        index,
        file,
      };
      return accum.concat(newFile);
    }, this.attachedFiles || []);

    this.onChange && this.onChange({ attachedFiles });
  }

  isValidFilesExtension(files) {
    if (!files) {
      return true;
    }
    const invalidFiles = files.reduce((accum, file) => {
      const ext = extractFileExtension(file.name);
      return HELLOBOOK_VALID_EXTENSIONS.includes(ext)
        ? accum
        : accum.concat(file);
    }, []);

    if (invalidFiles.length) {
      toastError(HELLO_BOOK.INVALID_FILE_EXT);
    }
    return !invalidFiles.length;
  }

  isFilesLimitExceed(files = []) {
    const isFileLimitExceed =
      files.length > FILE_ATTACHMENT_LIMIT ||
      files.length + get(this, 'attachedFiles.length', 0) >
        FILE_ATTACHMENT_LIMIT;
    if (isFileLimitExceed) {
      toastError(HELLO_BOOK.FILE_LIMIT);
    }
    return isFileLimitExceed;
  }

  onDeleteFile(file = {}) {
    this.currentDeleteSelection = file.id;
  }

  onDismissPopover() {
    this.currentDeleteSelection = '';
  }

  onRemoveAttachedFile(id = '') {
    const attachedFiles = this.attachedFiles.filter((file) => file.id !== id);
    this.onChange && this.onChange({ attachedFiles });
  }
}
