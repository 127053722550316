import swal from 'sweetalert';
import { COLOR } from 'Common/constants/colors';
import { displaySuccess } from 'Common/utilities/alert';
import { EDUCATION_ID, PAGE_SIZE, DESC, ASC } from '../constants/pageConstants';

export const processParentNotification = (vm, eventHandler, modalFn) => {
  if (eventHandler === 'openModal') {
    vm[modalFn]();
  }
};

export const getList = (vm, getFn) => {
  vm.isLoading = true;

  return vm.loanToolsService[getFn](
    EDUCATION_ID,
    PAGE_SIZE,
    vm.currentPage,
    vm.sortOrder,
  ).then(({ data, totalRecordCount }) => {
    vm.rowItems = data;
    vm.totalRecordCount = totalRecordCount;
    vm.isLoading = false;
  });
};

export const onSortDate = (vm, getFn) => {
  vm.sortOrder = vm.sortOrder === DESC ? ASC : DESC;
  vm[getFn]();
};

export const setDownloading = (vm, id, isDownloading) => {
  vm.rowItems = vm.rowItems.map((item) => {
    if (item.id === id) {
      item.isDownloading = isDownloading;
    }
    return item;
  });
};

export const onDownloadDocument = (vm, $e, item) => {
  $e && $e.preventDefault();

  if (!item || !item.id || !item.documentId) {
    return;
  }

  vm.setDownloading(item.id, true);
  return vm.generalService.documentGet(item.documentId).then(({ data }) => {
    if (!data) {
      return;
    }

    vm.downloadDataService.download(
      `data:${data.ContentType};base64,${data.DocumentContent}`,
      data.Name,
      data.ContentType,
    );
    vm.setDownloading(item.id, false);
  });
};

export const openModal = ({ vm, props, modalFn, successMessage, getFn }) => {
  return vm.modalRenderService[modalFn](props).result.then(({ isRefresh }) => {
    displaySuccess(successMessage);
    isRefresh && vm[getFn]();
  });
};

export const onEditClick = (vm, $e, item, modalFn) => {
  $e && $e.preventDefault();

  if (!item) {
    return;
  }

  vm[modalFn](item);
};

export const onDeleteClick = ({
  vm,
  $e,
  id,
  deleteFn,
  successMessage,
  getFn,
}) => {
  $e && $e.preventDefault();

  if (!id) {
    return;
  }

  swal(
    {
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: COLOR.SWEET_PINK,
      confirmButtonText: 'Yes, delete it!',
    },
    (confirm) => {
      if (confirm) {
        vm.loanToolsService[deleteFn](id).then(() => {
          displaySuccess(successMessage);
          vm[getFn]();
        });
      }
    },
  );
};
