import { createIdentificationColumnContent } from './util/contactIdentificationTable';

export default class ContactIdentificationTableCtrl {
  constructor(utilitiesService) {
    'ngInject';

    this.utilitiesService = utilitiesService;
  }

  $onInit() {
    this.gridTemplate = {
      'grid-template-columns': '70px 1fr 247px 27px 27px',
      'grid-column-gap': '5px',
    };

    this.columnHeader = [
      {
        key: 'client',
        title: 'Client',
      },
      {
        key: 'documentType',
        title: 'Document Type',
      },
      {
        key: 'expiryDate',
        title: 'Expiry Date',
      },
      {
        key: 'edit',
        title: '',
      },
      {
        key: 'delete',
        title: '',
      },
    ];
    this.columnContent = [];
    this.processIdentification(this.identificationList);
  }

  $onChanges(changeObject) {
    const { identificationList } = changeObject;
    const { previousValue, currentValue } = identificationList;

    if (identificationList.isFirstChange()) {
      return;
    }
    if (previousValue !== currentValue) {
      this.processIdentification(currentValue);
    }
  }

  processIdentification(identification) {
    this.columnContent = createIdentificationColumnContent(
      identification,
      this.utilitiesService.filterInitialOneString,
    );
  }
}
