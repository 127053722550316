import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';

export default class LoanFinancialsService {
  constructor(
    $state,
    configService,
    generalService,
    crmConfirmation,
    contactModelService,
  ) {
    'ngInject';

    this.$state = $state;
    this.configService = configService;
    this.generalService = generalService;
    this.crmConfirmation = crmConfirmation;
    this.contactModelService = contactModelService;
  }

  getFinancialsTranscludedHtml(financials) {
    const { assets, liabilities } = financials || {};
    if (
      !financials ||
      (assets && !assets.length && liabilities && !liabilities.length)
    ) {
      return '';
    }

    const getLiabilities = () => {
      return (
        liabilities.map((liability) => {
          return `<div class="hbox">
        <div class="hbox flex">
          <div>
            <lender-logo
              height="21"
              width="38"
              lender-id="${liability.lenderName}"
              lender-name="'${liability.lenderName}'"
              is-large="true"
              country-code="'${liability.countryCode}'"
            >
            </lender-logo>
          </div>
          <div class="type">${liability.type}</div>
        </div>
        <div class="text-bold">
          <currency-formatting amount="${liability.repayments}" precision="2" symbol="$">
          </currency-formatting>
          / ${liability.shortRepaymentFrequency}
        </div>
      </div>`;
        }) || ''
      );
    };

    const getAssets = () => {
      return (
        assets.map((asset) => {
          return `<div class="hbox">
        <div class="icon-map-pin"><map-pin-icon></map-pin-icon></div>
        <div class="flex text-left">
          ${asset.address}
        </div>
        <div class="text-bold">
          <currency-formatting amount="${asset.value}" precision="0" symbol="$">
          </currency-formatting>
        </div>
      </div>`;
        }) || ''
      );
    };

    this.transcludeHtml = `    
    <div class="auto-added-financials-modal">
      <div class="header">Financials</div>
      <div class="item-list">
        ${
          assets && assets.length
            ? `<div class="assets">
            <div>Real Estate Asset</div>
            ${getAssets()}
          </div>`
            : ''
        }
        ${
          liabilities && liabilities.length
            ? `<div>
            <div>Liabilities</div>
            ${getLiabilities()}
          </div>`
            : ''
        }
      </div>
    </div>
    `;
    return this.transcludeHtml;
  }

  setFinancialCreationDontShowAgain() {
    return this.generalService.postTickableItems({
      itemId: TICKABLE_ITEMS.SETTLEMENT_FINANCIALS,
    });
  }

  showAutoCreatedFinancialsOnSettlement({ params }) {
    const { familyId, loanAppId } = params;
    if (
      !this.configService.feature.settledLoanCreateLiabilityAndAsset ||
      !familyId ||
      !loanAppId
    ) {
      return;
    }

    params.activeTab = 'financials';
    const redirect = () => this.$state.go('app.contactsSingle', params);
    const onDontShowAgainTick = (isRedirect) => {
      this.setFinancialCreationDontShowAgain().then(() => {
        isRedirect && redirect();
      });
    };
    const onConfirm = ({ dontShowAgain }) => {
      if (dontShowAgain) {
        onDontShowAgainTick(true);
      } else {
        redirect();
      }
    };

    this.contactModelService
      .getNewFinancials(familyId, loanAppId)
      .then((response) => {
        const { assets, liabilities } = response;
        if (!assets.length && !liabilities.length) {
          return;
        }

        const transcludeHtml = this.getFinancialsTranscludedHtml(response);
        const result = this.crmConfirmation.open({
          type: 'success',
          title: 'Nice Work!',
          description: `We've automatically created these entries for you:`,
          buttonText: `Open Financials`,
          onConfirm: (resp) => onConfirm(resp),
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: 'Close',
          modalSize: 'md',
          cancelButtonClass: 'colored',
          displayDontShowAgain: true,
          transcludeHtml,
        });

        result &&
          result.result.then(
            ({ dontShowAgain }) => dontShowAgain && onDontShowAgainTick(false),
          );
      });
  }
}
