import template from './mycrmTable.html';
import controller from './mycrmTableCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    data: '<',
    columns: '<',
    isLoading: '<',
    isTableLoading: '<',
    itemsPerPage: '<',
    showCheckbox: '<',
    sortColumn: '<',
    sortOrder: '<',
    totalRecords: '<',
    currentPage: '<',
    loadingMessage: '@',
    uniqueKey: '@',
    showDeleteAction: '<',
    onPageChange: '&',
    onCheckItem: '&',
    onSortColumn: '&',
    onSelectItem: '&',
    onDeleteItem: '&?',
  },
};
