import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import * as Sentry from '@sentry/browser';
import {
  TRANSACTION_TYPE,
  LENDING_CATEGORY,
  NO_LMI_TRANSACTION_TYPES,
} from 'Common/constants/securityValues';
import { LMI_NAME } from 'Common/constants/lmi';
import { ADD_PROPERTY_TYPE } from 'Common/constants/options';
import { clientTitles } from 'Common/utilities/client';
import { parseIntValue } from 'Common/utilities/parse';
import { getMaxValue } from 'Common/utilities/math';
import { toastError } from 'Common/utilities/alert';
import {
  showAddAssetSecurityModal,
  getOtherFundsReq,
  setOtherFundsReq,
  setDisplayOtherFundsReq,
  showEditOtherFund,
  showDeleteFundConfirmation,
  onWatchOtherFunds,
  isShowSecurityTable,
  setValidTransactionTypePerSecurity,
  isConstructionTransaction,
  isOpenSecurityModal,
  mapREASecurityList,
  getOwnerGroupProperty,
  getApplicantsFromLoanAppSharedData,
  validateFundingDetailsSection,
  onUpdateLMIValueOnTxt,
  handleUpdateLoanDetailsLMIFromSecurity,
} from './../util/clientAccess/security';
import {
  removeLMIAttachedToLoan,
  saveLMIFundingFromLoanDetails,
  reloadLoanVariationDetails,
} from '../util/clientAccess/loanFundingDetails';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanAppSecurityCtrl', function LoanAppSecurityCtrl(
      $scope,
      $timeout,
      configService,
      loanScenarioService,
      loanApplicationServices,
      optionsService,
      SweetAlert,
      toaster,
      $stateParams,
      utilitiesService,
      $uibModal,
      fundingCalculatorService,
      $rootScope,
      loanAppSharedData,
      generalService,
      currentUserService,
      loanScenarioModelService,
      financialsSharedService,
      fundingDetailsService,
    ) {
      //  this will be used under loan funding details's Property / Security section under Funding Requied
      $scope.loanAppSharedData = loanAppSharedData;
      $scope.financialsSharedService = financialsSharedService;
      $scope.loanAppId = $stateParams.loanAppId;
      $scope.familyId = $stateParams.familyId;
      $scope.isAssetFinance = false;
      $scope.currentUserService = currentUserService;
      $scope.isAssetLoading = false;
      $scope.transactionType = TRANSACTION_TYPE;
      $scope.noLmiTransactionTypes = NO_LMI_TRANSACTION_TYPES;
      $scope.configService = configService;
      $scope.isConstructionLoanApps =
        configService.feature.constructionLoanApps;
      $scope.getMaxValue = getMaxValue;
      $scope.pledgeValueType = {
        amount: 1,
        percentage: 2,
      };
      $scope.otherFundsReqList = [];
      $scope.showAddAssetSecurityModal = showAddAssetSecurityModal({ $scope });
      $scope.getOtherFundsReq = getOtherFundsReq({
        $scope,
        loanAppSharedData,
        loanScenarioModelService,
      });
      $scope.setOtherFundsReq = setOtherFundsReq({
        $scope,
        loanScenarioModelService,
      });
      $scope.setDisplayOtherFundsReq = setDisplayOtherFundsReq({ $scope });
      $scope.showEditOtherFund = showEditOtherFund;
      $scope.showDeleteFundConfirmation = showDeleteFundConfirmation({
        $scope,
      });
      $scope.isShowSecurityTable = isShowSecurityTable({ $scope });

      $scope.ownersButtonLabels = [{ label: 'PreferedName' }];
      $scope.ownersItemLabels = [
        { label: 'PreferedName', isEdit: false, isText: true },
      ];

      $scope.ownersDisableEdit = true;
      const isLMIFundItem = (fund) => fund.name === LMI_NAME;

      $scope.isOpenSecurityModal = (item, value) => {
        isOpenSecurityModal({
          $scope,
          item,
          value,
        });
      };

      if (
        configService.feature &&
        configService.feature.securityOwnersPercentage
      ) {
        $scope.ownersButtonLabels.push({
          label: 'Percentage',
          isPercentage: true,
        });
        $scope.ownersItemLabels.push({
          label: 'Percentage',
          isEdit: true,
          containerClass: 'input-type-percentage',
          inputClass: 'text-input',
          isPercentage: true,
          inputWidth: '60px',
        });

        $scope.ownersDisableEdit = false;
      }

      // Create a loan term list
      optionsService.getLoanTerms().then((loanTermResponse) => {
        $scope.loanTermList = loanTermResponse;
      });

      $scope.listRepaymentFrequency = [];
      optionsService.FinancialFrequency().then((response) => {
        _.map(response.data, (o) => {
          o.Desc = o.Name;
          o.Value = parseInt(o.Value, 10);

          return o;
        });

        $scope.listRepaymentFrequency = response.data;
      });

      // Get Summary Data
      $scope.getFundingCalculatorFundsSummary = () => {
        if (!$scope.securityBrokerEventId) {
          return false;
        }

        fundingCalculatorService
          .getFundingCalculatorFundsSummary(
            $scope.securityBrokerEventId,
            $scope.loanAppId,
          )
          .then((summary) => {
            _.extend($scope.loanAppSharedData.fundingSummary, summary);

            $scope.loanAppSharedData.fundingSummary.unrefinancedExistingLending = 0;
            if (
              $scope.loanAppSharedData &&
              $scope.loanAppSharedData.fundingSummary &&
              $scope.loanAppSharedData.fundingSummary
                .totalProposedLendingUserDefined
            ) {
              $scope.loanAppSharedData.fundingSummary.totalProposedLendingUserDefined = $scope.loanAppSharedData.fundingSummary.totalProposedLendingUserDefined.toFixed(
                2,
              );
            }

            if (!summary.lvrPercentage) {
              if (
                parseInt(
                  $scope.loanAppSharedData.fundingSummary.totalSecurityValue,
                  10,
                ) > 0
              ) {
                $scope.loanAppSharedData.fundingSummary.totalExistingLendingvalue = 0;

                if (_.size($scope.securityList) > 0) {
                  _.forEach($scope.securityList, (o) => {
                    if (o.MortgageList) {
                      _.forEach(o.MortgageList, (mortgage) => {
                        $scope.loanAppSharedData.fundingSummary.unrefinancedExistingLending += !mortgage.IsRefinance
                          ? mortgage.Amount
                          : 0;
                      });
                    }
                  });
                }

                $scope.loanAppSharedData.fundingSummary.lvrPercentage =
                  ((parseInt(
                    $scope.loanAppSharedData.fundingSummary
                      .totalProposedLending,
                    10,
                  ) +
                    parseInt(
                      $scope.loanAppSharedData.fundingSummary
                        .unrefinancedExistingLending,
                      10,
                    )) /
                    parseInt(
                      $scope.loanAppSharedData.fundingSummary
                        .totalSecurityValue,
                      10,
                    )) *
                    100 || 0;
              } else {
                $scope.loanAppSharedData.fundingSummary.lvrPercentage = 0;
              }
            }
            validateFundingDetailsSection({
              configService,
              loanAppSharedData,
            });
            reloadLoanVariationDetails(loanAppSharedData);
          });
      };

      // Listens for refreshing summary
      $scope.$watch(
        'loanAppSharedData.refreshSecurityFundsSummary',
        (nv) => {
          if (nv === 1) {
            $scope.getFundingCalculatorFundsSummary();

            $scope.loanAppSharedData.refreshSecurityFundsSummary = 0;
          }
        },
        true,
      );

      const onUpdateLoanDetailsLMI = handleUpdateLoanDetailsLMIFromSecurity({
        $scope,
        $timeout,
        loanScenarioService,
      });

      $scope.saveFundingParameters = (fund, isFromAddLmi = false) => {
        fund.isNew = false;
        fund.isEditing = false;
        if (!fund.id && !fund.fundsId && !fund.fundingCalParametersId) {
          const firstSecurity = _.first($scope.securityList);

          if (!firstSecurity || !firstSecurity.fundingParameters) {
            return false;
          }

          const checkExist = _.find(
            firstSecurity.fundingParameters.innerFundingRequired,
            (o) => {
              return o.fundingParticulars === fund.name;
            },
          );
          if (checkExist) {
            fund.fundsId = checkExist.fundsId;
          } else {
            fund.fundsId = 0;
          }

          fund.fundingCalParametersId = firstSecurity.FundingCalParametersId;
        }
        const securityBrokerEventId =
          $scope.securityBrokerEventId ||
          loanAppSharedData.securityBrokerEventId;
        const obj = {
          brokerEventId: securityBrokerEventId,
          fundsId: fund.fundsId,
          fundingCalParametersId: fund.fundingCalParametersId,
          fundingType: 'FUNDING_REQUIRED',
          fundingParticulars: fund.name,
          fundingValue: fund.value,
          isOnlyValueEditable: true,
        };

        if (!securityBrokerEventId) {
          Sentry.captureException(
            new Error(
              `Missing brokerEventId in method saveFundingParameters loanAppID ${$scope.loanAppId}`,
            ),
          );
          return;
        }
        fundingCalculatorService
          .setFundingCalculatorFunds(obj)
          .then(({ data }) => {
            // Saved
            if (!data) {
              return toastError();
            }
            $scope.getParameters();
            $scope.getFundingCalculatorFundsSummary();
            if (fund.name !== 'Lenders Fees' && fund.name !== LMI_NAME) {
              $scope.loanAppSharedData.refreshLoanDetailsSet = 1;
            }

            if (fund.name === LMI_NAME && data) {
              if (isFromAddLmi) {
                $scope.loanAppSharedData.refreshLoanDetails = 1;
              } else {
                $scope.loanAppSharedData.isLmiFundUpdated = true;
                $scope.loanAppSharedData.lmiFundValue = fund.value;
                onUpdateLoanDetailsLMI(fund.value);
              }
              toaster.pop(
                'success',
                'Updated',
                'Funding required has been successfully Updated',
              );
            }
          })
          .catch(toastError);
      };

      $scope.$watch(
        'loanAppSharedData.refreshSecurityFundingParameters',
        (nv) => {
          if (nv === 1) {
            $scope.getParameters();
            $scope.loanAppSharedData.refreshSecurityFundingParameters = 0;
          }
        },
        true,
      );

      $scope.$watch(
        'loanAppSharedData.SAVE_FUNDING_PARAMETER',
        (nv) => {
          if (nv && _.size(nv) > 0) {
            $scope.saveFundingParameters(angular.copy(nv));
          }
        },
        true,
      );
      $scope.addGuaranteeFundsFees = (security, dataFromTemplate) => {
        const isGuarantee =
          security.TransactionId === TRANSACTION_TYPE.GUARANTEE;
        return {
          ...security,
          PledgeAmount: isGuarantee ? dataFromTemplate.PledgeAmount || 0 : 0,
          PledgePercentage: isGuarantee
            ? dataFromTemplate.PledgePercentage || 0
            : 0,
        };
      };
      $scope.removeRemovableFunds = (innerFunds) => {
        innerFunds.map((fund) => {
          if (fund.name === 'Refinance Amount') {
            return fund;
          }
          const params = {
            FundsID: fund.fundsId,
          };
          fundingCalculatorService.deleteFundingCalculatorFunds(params);
          return fund;
        });
      };
      $scope.changeTransactionType = (security) => {
        if (
          security.TransactionId === TRANSACTION_TYPE.GUARANTEE &&
          configService.feature.guarantorLoanFixes
        ) {
          $scope.removeRemovableFunds(
            security.fundingParameters.innerFundingRequired,
          );
        }
        security.isConstructionTransaction = isConstructionTransaction({
          isConstructionLoanApp: $scope.isConstructionLoanApps,
          transactionId: security.TransactionId,
        });
        $scope.updateSecurity(security);
      };
      $scope.deleteFund = (item, id, name) => {
        SweetAlert.swal(
          {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            title: 'Are you sure?',
            text: 'This fund will be removed',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: false,
          },
          (confirm) => {
            if (!confirm) {
              return;
            }
            const params = {
              FundsID: id,
            };
            fundingCalculatorService
              .deleteFundingCalculatorFunds(params)
              .then(({ data }) => {
                if (!data) {
                  return toastError();
                }
                _.remove(item.fundingParameters.innerFundingRequired, (o) => {
                  return _.isEqual(o.fundsId, id);
                });
                $rootScope.$broadcast('getFundingCalculatorFundsSummary');

                $scope.hasLMIFund = item.fundingParameters.innerFundingRequired.some(
                  (fund) => isLMIFundItem(fund),
                );
                if (name === LMI_NAME) {
                  $scope.loanAppSharedData.isLoanDetailsSet &&
                    ($scope.loanAppSharedData.isDeleteLMI = true);

                  !$scope.loanAppSharedData.isLoanDetailsSet &&
                    onUpdateLoanDetailsLMI(0);
                }
                $scope.loanAppSharedData.refreshLoanDetailsSet = 1;

                SweetAlert.swal({
                  title: 'Success',
                  text: 'Fund has been been successfully deleted.',
                  type: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                });
              })
              .catch(toastError);
          },
        );
      };

      // Get parameters
      $scope.getParameters = () => {
        const securityBrokerEventId =
          $scope.securityBrokerEventId ||
          loanAppSharedData.securityBrokerEventId;
        if (!securityBrokerEventId) {
          return;
        }

        fundingCalculatorService
          .getFundingCalculatorParameters(securityBrokerEventId)
          .then((param) => {
            const fundingParams = param;
            $scope.hasLMIFund = false;
            // Map the params and put isNew and isEditing properties
            _.map(fundingParams, (paramFundings) => {
              _.map(paramFundings.innerFundingRequired, (fund) => {
                fund.isNew = false;
                fund.isEditing = false;

                if (isLMIFundItem(fund)) {
                  $scope.hasLMIFund = true;
                }
                return fund;
              });
              paramFundings.purchaseInfo.propertyType = parseInt(
                paramFundings.purchaseInfo.propertyType,
                10,
              );
              return paramFundings;
            });

            _.forEach($scope.securityList, (item) => {
              item.fundingParameters = {};
              item.fundingParameters = _.find(
                fundingParams,
                (paramFundings) => {
                  if (
                    parseInt(paramFundings.fundingCalParametersId, 10) ===
                    parseInt(item.FundingCalParametersId, 10)
                  ) {
                    paramFundings.purchaseInfo.purposeName =
                      item.PropertyDetails.PropertyPrimaryPurpose;
                    paramFundings.purchaseInfo.propertyStatusName =
                      item.PropertyDetails.Status;
                    paramFundings.purchaseInfo.propertyValue =
                      item.PropertyDetails.CurrentValue;
                    return paramFundings;
                  }
                },
              );

              item.addFundingParameters = () => {
                item.fundingParameters.innerFundingRequired.push({
                  fundsId: 0,
                  fundingCalParametersId: item.FundingCalParametersId,
                  name: '',
                  value: 0,
                  isOnlyValueEditable: true,
                  isNew: true,
                  isEditing: false,
                });
              };

              item.deleteFundingCalculatorFunds = (value) => {
                // This is for new item only
                if (!value.FundsID || !parseInt(value.FundsID, 10)) {
                  _.remove(item.fundingParameters.innerFundingRequired, (o) => {
                    return _.isEqual(o, value);
                  });
                } else {
                  SweetAlert.swal(
                    {
                      title: 'Are you sure?',
                      text: 'This record will be removed from your fund list',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#F68F8F',
                      confirmButtonText: 'Yes, remove it!',
                      closeOnConfirm: false,
                    },
                    (confirm) => {
                      if (!confirm) {
                        return;
                      }
                      const params = {
                        FundsID: value.FundsID,
                      };
                      fundingCalculatorService
                        .deleteFundingCalculatorFunds(params)
                        .then(({ data }) => {
                          if (!data) {
                            return toastError();
                          }
                          $timeout(() => {
                            $rootScope.$broadcast(
                              'getFundingCalculatorFundsSummary',
                            );
                            _.remove(
                              item.fundingParameters.innerFundingRequired,
                              (o) => {
                                return _.isEqual(o, value);
                              },
                            );
                            SweetAlert.swal({
                              title: 'Success',
                              text: 'Fund has been been successfully deleted.',
                              type: 'success',
                              timer: 2000,
                              showConfirmButton: false,
                            });
                          }, 100);
                        })
                        .catch(toastError);
                    },
                  );
                }
              };
            });

            $scope.getFundingCalculatorFundsSummary();
          })
          .catch(toastError);
      };

      // get linked brokerEventId per loan app
      $scope.getFundingCalculatorInfo = () => {
        $scope.securityBrokerEventId = null;
        $scope.fundingParams = [];
        $scope.fundingSummary = {};
        loanScenarioService
          .getFundingCalculatorNewBrokerEventForScenario($scope.loanAppId)
          .then((response) => {
            $scope.securityBrokerEventId = response.data;
            $scope.loanAppSharedData.securityBrokerEventId =
              $scope.securityBrokerEventId;
            // Get parameters
            $scope.getParameters();
          });
      };

      $scope.getEmptySecurityInfo = () => {
        $scope.security = {
          add: {
            LoanApplicationId: $scope.loanAppId,
            SecurityInfoId: '0',
            IsPrimary: true,
            SecurityTypeId: '', // SecurityType
            TransactionId: '', // TransactionType
            Address: [],
            IsRefinance: false,
            PropertyDetails: {
              LoanSecurityId: 0,
              StatusId: '', // StatusType
              PropertyPrimaryPurposeId:
                $rootScope.crmCountry === 'New Zealand' ? '1' : '', // PrimaryPurposeType
              HoldingId: '', // HoldingType
              ZoningId: '', // ZoningType
              PropertyTypeId: '', // PropertyType
              CurrentValue: '',
              LandValue: '',
              EstimatedPrice: '',
              EstimatedBasesId: '', // AssetValueEstimatedBasis
              ContratctPrice: '',
              ContractDate: '',
              LicensedRealEstateAgentContract: true,
              IsPreApproved: true,
              AddressId: 0,
              TitleTypeId: $rootScope.crmCountry === 'New Zealand' ? '15' : '', // TitleTenureType
              TitleId: '', // TitleType
              PropertyState: '',
              Suburb: '',
              IdentifierList: [
                {
                  Security_TitleIdentityId: 0, // TitleTenureType
                  IdentityTypeId: '', // TitleIdentificationType
                  Value: '',
                },
              ],
              ContactForAccess: {
                ContactForAccess: 0,
              },
            },
            Ownership: [],
            MortgageList: [],
          },
          edit: [],
          titles: clientTitles(),
        };
        $scope.mortgageTemp = [];
        $scope.showAddMortgage = false;
      };

      $scope.getEmptySecurityInfo();

      angular.copy($scope.security.add, $scope.security.reset);

      $scope.getApplicants = () => {
        $scope.scenarioApplicantList = getApplicantsFromLoanAppSharedData({
          loanAppSharedData,
          utilitiesService,
          financialsSharedService,
        });
        $scope.ownerGroupProperty = getOwnerGroupProperty(
          $scope.scenarioApplicantList,
        );
        $scope.OwnershipListInit = $scope.scenarioApplicantList;
        return Promise.resolve();
      };
      $scope.getOwners = $scope.getApplicants;

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getEqualPercentage = (list, isAllOwners = true) => {
        let countApplicants = 0;
        if (isAllOwners) {
          countApplicants += list.length;
        } else {
          const listOfAllTicked = list.filter((obj) => obj.ticked) || [];
          countApplicants += listOfAllTicked.length;
        }
        return (100 / countApplicants).toFixed(2);
      };

      $scope.ownerChange = (data, list, sourceIsTick) => {
        if (
          !sourceIsTick &&
          (!data.Percentage || parseInt(data.Percentage, 10) === 0)
        ) {
          data.Percentage = 0;
          list.map((obj) => {
            if (obj.BorrowerID === data.BorrowerID) {
              obj.ticked = false;
            }
            return obj;
          });
        } else if (sourceIsTick) {
          const percentage = getEqualPercentage(list, false);
          list.map((obj) => {
            obj.Percentage = obj.ticked ? percentage : 0;
            return obj;
          });
        }

        $scope.scenarioApplicantList = [...list];
      };

      $scope.selectAll = (isSelectAll = true, list) => {
        let percentage = 0;
        if (isSelectAll) {
          percentage = getEqualPercentage(list);
        }

        $scope.scenarioApplicantList = list.map((obj) => {
          obj.Percentage = percentage;
          return obj;
        });
      };

      $scope.resetOwners = (list) => {
        $scope.selectAll(true, list);
      };

      $scope.randomColors = [];

      $scope.setOwnerRandomColor = (owner) => {
        _.find($scope.randomColors, (o) => {
          return parseInt(o.BorrowerID, 10) === parseInt(owner.BorrowerID, 10);
        });
      };
      $scope.updatePledgeValues = (item, pledgeType) => {
        const { PledgeAmount = 0, PledgePercentage = 0, PropertyDetails } =
          item || {};
        const { CurrentValue = 0 } = PropertyDetails || {};

        const calculatedAmount = CurrentValue * (PledgePercentage / 100);
        const calculatePercentage = (PledgeAmount / CurrentValue) * 100;

        const newPercentage =
          pledgeType === $scope.pledgeValueType.amount
            ? calculatePercentage
            : PledgePercentage;
        const newAmount =
          pledgeType === $scope.pledgeValueType.percentage
            ? calculatedAmount
            : PledgeAmount;

        if (newPercentage !== PledgePercentage || newAmount !== PledgeAmount) {
          item.PledgeAmount = parseFloat(newAmount).toFixed(2);
          item.PledgePercentage = parseFloat(newPercentage).toFixed(2);
        }
      };
      $scope.updateSecurity = (item, isFromCheckbox) => {
        if (isFromCheckbox) {
          const {
            MortgageList,
            isConstructionTransaction: isConstructionTransactionType,
          } = item;
          const isRefinance = !!(
            MortgageList &&
            MortgageList.some((mortgage) => mortgage.IsRefinance)
          );
          if (isRefinance) {
            item.TransactionId = isConstructionTransactionType
              ? TRANSACTION_TYPE.REFINANCE_CONSTRUCTION
              : TRANSACTION_TYPE.REFINANCE;
          } else {
            item.TransactionId = isConstructionTransactionType
              ? TRANSACTION_TYPE.OWNS_CONSTRUCTION
              : TRANSACTION_TYPE.OWNS;
          }
        }
        const obj = {
          LoanApplicationId: $scope.loanAppId,
          SecurityInfoId: item.SecurityInfoId,
          IsPrimary: item.IsPrimary,
          SecurityTypeId: parseInt(item.SecurityTypeId, 10),
          TransactionId: parseInt(item.TransactionId, 10),
          MortgagePriority: item.MortgagePriority,
          LoanSecurityId: parseInt($scope.LoanSecurityList.LoanSecurityId, 10),
          IsRefinance: item.IsRefinance,
          PropertyDetails: {
            LoanSecurityId: parseInt(item.SecurityInfoId, 10), // go back
            StatusId: parseInt(item.PropertyDetails.StatusId, 10),
            PropertyPrimaryPurposeId: parseInt(
              item.PropertyDetails.PropertyPrimaryPurposeId,
              10,
            ),
            HoldingId: parseInt(item.PropertyDetails.HoldingId, 10),
            ZoningId: parseInt(item.PropertyDetails.ZoningId, 10),
            PropertyTypeId: parseInt(item.PropertyDetails.PropertyTypeId, 10),
            Suburb: item.PropertyDetails.Suburb || '',
            PropertyState: item.PropertyDetails.PropertyState || '',
            CurrentValue: item.PropertyDetails.CurrentValue,
            LandValue: item.PropertyDetails.LandValue,
            EstimatedPrice: item.PropertyDetails.EstimatedPrice,
            EstimatedBasesId: parseInt(
              item.PropertyDetails.EstimatedBasesId,
              10,
            ),
            ContratctPrice: item.PropertyDetails.ContratctPrice,
            ContractDate: item.PropertyDetails.ContractDate,
            LicensedRealEstateAgentContract:
              item.PropertyDetails.LicensedRealEstateAgentContract,
            IsPreApproved: item.PropertyDetails.IsPreApproved,
            AddressId: item.PropertyDetails.AddressId,
            TitleTypeId: item.PropertyDetails.TitleTypeId,
            TitleId: item.PropertyDetails.TitleId,
            IdentifierList: item.PropertyDetails.IdentifierList,
            ContactForAccess: {
              ContactForAccess:
                item.PropertyDetails.ContactForAccess.ContactForAccess,
              CompanyName: item.PropertyDetails.ContactForAccess.CompanyName,
              ContactTitle: item.PropertyDetails.ContactForAccess.ContactTitle,
              ContactFirstName:
                item.PropertyDetails.ContactForAccess.ContactFirstName,
              ContactLastName:
                item.PropertyDetails.ContactForAccess.ContactLastName,
              PhoneAreaCode:
                item.PropertyDetails.ContactForAccess.PhoneAreaCode,
              PhoneNumber: item.PropertyDetails.ContactForAccess.PhoneNumber,
              FaxAreaCode: item.PropertyDetails.ContactForAccess.FaxAreaCode,
              FaxNumber: item.PropertyDetails.ContactForAccess.FaxNumber,
              ContactTypeId:
                item.PropertyDetails.ContactForAccess.ContactTypeId,
            },
          },
          MortgageList: item.MortgageList,
          Ownership: item.Ownership,
          AssetId: item.AssetId,
        };
        const modifiedObject =
          (configService.feature.guarantorLoanFixes &&
            $scope.addGuaranteeFundsFees(obj, item)) ||
          obj;

        loanApplicationServices
          .setLoanSecurityInfo(modifiedObject)
          .then(() => {
            $scope.getParameters();
            $scope.loanAppSharedData.refreshLoanDetailsSet = 1;
          })
          .catch(toastError);
      };

      $scope.updateMortgageRefinance = (mortgage, item) => {
        const mortgageTemp = {
          LoanApplicationId: $scope.loanAppId,
          LoanSecurityInfoId: item.SecurityInfoId, // reassign
          SecurityMortgageId: 0,
          edit: 0,
          MortgagePriorityId: '',
          Ownership: [], // reassign
          CreditorId: '',
          /**
           * TODO: add principalInterest on DB and API
           * */
          IsRefinance: false,
          PrincipalInterest: 2, // 2:Principal and interest; 1: Interest Only
          InterestTaxDeductible: true,
        };

        const setMortgage = angular.extend(mortgageTemp, mortgage);

        loanApplicationServices
          .setLoanSecurityMortgage(setMortgage)
          .then(() => {
            $rootScope.$broadcast('getFundingCalculatorFundsSummary');
          });
      };

      $scope.TransactionTypeList = [];
      $scope.getTransactionType = (objResponse) => {
        if (_.size($scope.TransactionTypeList) === 0) {
          optionsService.getTransactionType().then((response) => {
            const TransactionTypeList = parseIntValue(response.data);
            $scope.TransactionTypeList = _.map(TransactionTypeList, (o) => {
              o.disabled = false;
              return o;
            });
            $scope.TransactionTypeList = [
              // eslint-disable-next-line sonarjs/no-duplicate-string
              { Name: 'Please select', Value: '' },
              ...$scope.TransactionTypeList,
            ];

            $scope.getTransactionTypeCallback(objResponse);
          });
        } else {
          $scope.getTransactionTypeCallback(objResponse);
        }
      };

      $scope.getTransactionTypeCallback = (response) => {
        $scope.securityList =
          ($scope.securityList.length &&
            mapREASecurityList({
              isConstructionLoanApp: $scope.isConstructionLoanApps,
              securityList: $scope.securityList,
              transactionTypeList: $scope.TransactionTypeList,
            })) ||
          [];
        $scope.LoanSecurityList = response.data;

        $scope.getFundingCalculatorInfo();
        $scope.sumSecurityAmount();

        const filterRefinance =
          _.filter(response.data.SecurityList, (obj) => {
            const objMortgageRefinance =
              _.find(obj.MortgageList, (objMort) => {
                return objMort.IsRefinance === true;
              }) || {};
            return !_.isEmpty(objMortgageRefinance);
          }) || [];

        loanAppSharedData.isRefinance = filterRefinance.length >= 1;
      };

      $scope.getLoanSecurity = () => {
        $scope.isAssetLoading = true;
        loanScenarioService
          .getLoanSecurity($scope.loanAppId)
          .then((response) => {
            $scope.securityList = response.data.SecurityList;
            loanAppSharedData.securityList = $scope.securityList;
            $scope.setSecurityModel($scope.securityList);
            $scope.isAssetLoading = false;

            // Used in Product finder product type filter
            if (_.size(response.data.SecurityList) > 0) {
              $rootScope.loanAppSecurityProductType =
                response.data.SecurityList[0].PropertyDetails
                  .PropertyPrimaryPurposeId || '';
            } else {
              $rootScope.loanAppSecurityProductType = '';
            }

            $rootScope.loanAppSecurityListCount = $scope.securityList.length;
            validateFundingDetailsSection({
              configService,
              loanAppSharedData,
            });
            $scope.getTransactionType(response);
          });
      };

      $scope.$watch(
        () => {
          return loanAppSharedData.refreshSecurityList;
        },
        (nv) => {
          if (nv) {
            $scope.getLoanSecurity();
          }
          loanAppSharedData.refreshSecurityList = false;
        },
      );

      $scope.getLoanSecurity();

      $scope.$watch(
        'loanAppSharedData.REFRESH_FUNDING_REQUIRED_SECTION',
        (nv) => {
          if (nv && _.size(nv) > 0) {
            $scope.getLoanSecurity();
          }
        },
      );

      $scope.getLoanSecurityInfo = () => {
        loanApplicationServices.getLoanSecurityInfo().then(() => {});
      };

      $scope.sumSecurityAmount = () => {
        $scope.totalSecurity = 0;
        angular.forEach($scope.securityList, (val) => {
          $scope.totalSecurity += val.PropertyDetails.CurrentValue;
        });
      };
      $scope.getSecurityOptions = () => {
        optionsService.getSecurityType().then((response) => {
          const SecurityTypeList = parseIntValue(response.data);
          $scope.SecurityTypeList = [
            { Name: 'Please select', Value: '' },
            ...SecurityTypeList,
          ];
        });

        optionsService.getAssetValueEstimatedBasis().then((response) => {
          const BasisList = parseIntValue(response.data);
          $scope.AssetValueEstimatedBasisList = [
            { Name: 'Please select', Value: '' },
            ...BasisList,
          ];
        });

        optionsService.getHoldingType().then((response) => {
          const HoldingTypeList = parseIntValue(response.data);
          $scope.HoldingTypeList = [
            { Name: 'Please select', Value: '' },
            ...HoldingTypeList,
          ];
        });

        optionsService.getPrimaryPurposeType().then((response) => {
          $scope.PrimaryPurposeTypeList = parseIntValue(response.data);
          $scope.PrimaryPurposeTypeList = [
            { Name: 'Please select', Value: '' },
            ...$scope.PrimaryPurposeTypeList,
          ];
        });

        optionsService.getStatusType().then((response) => {
          $scope.StatusTypeList = parseIntValue(response.data);
          $scope.StatusTypeList = [
            { Name: 'Please select', Value: '' },
            ...$scope.StatusTypeList,
          ];
        });

        optionsService.getZoningType().then((response) => {
          $scope.ZoningTypeList = parseIntValue(response.data);
          $scope.ZoningTypeList = [
            { Name: 'Please select', Value: '' },
            ...$scope.ZoningTypeList,
          ];
        });

        $scope.PropertyTypeList = [
          {
            AddressPropertyType: 'Please select Zoning first',
            AddressPropertyTypeID: '',
          },
        ];

        optionsService.getTitleTenureType().then((response) => {
          $scope.TitleTenureTypeList = parseIntValue(response.data);
          $scope.TitleTenureTypeList = [
            { Name: 'Please select', Value: '' },
            ...$scope.TitleTenureTypeList,
          ];
        });

        optionsService.getTitleType().then((response) => {
          $scope.TitleTypeList = parseIntValue(response.data);
          $scope.TitleTypeList = [
            { Name: 'Please select', Value: '' },
            ...$scope.TitleTypeList,
          ];
        });

        optionsService.getTitleIdentificationType().then((response) => {
          $scope.TitleIdentificationTypeList = parseIntValue(response.data);
          $scope.TitleIdentificationTypeList = [
            { Name: 'Please select', Value: '' },
            ...$scope.TitleIdentificationTypeList,
          ];
        });

        optionsService.getContactAccessType().then((response) => {
          $scope.ContactAccessTypeList = parseIntValue(response.data);
          $scope.ContactAccessTypeList = [
            { Name: 'Please select', Value: '' },
            ...$scope.ContactAccessTypeList,
          ];
        });

        optionsService.getMortgagePriority().then((response) => {
          $scope.MortgagePriorityList = parseIntValue(response.data);
          $scope.MortgagePriorityList = [
            { Name: 'Please select', Value: '' },
            ...$scope.MortgagePriorityList,
          ];
        });
      };

      $scope.validateSecurity = () => {
        $scope.isValidSecurity = $scope.securityList.length > 0;
      };

      /*
       * Delete Security Info
       * */
      $scope.deleteSecurity = (item) => {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This information will be removed from your list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          },
          (confirm) => {
            if (!confirm) {
              return;
            }
            loanApplicationServices
              .deleteLoanSecurityInfo(
                $scope.loanAppId,
                item.SecurityInfoId,
                item.FundingCalParametersId ? item.FundingCalParametersId : 0,
              )
              .then(({ data }) => {
                if (!data) {
                  return toastError();
                }
                $scope.getLoanSecurity();
                $scope.financialsSharedService.AssetInfoGet();
                $scope.financialsSharedService.IncomeInfoGet();

                const params = {
                  loanAppSharedData: $scope.loanAppSharedData,
                  loanScenarioService,
                  isFromSecurity: true,
                  loanAppId: $scope.loanAppId,
                };
                removeLMIAttachedToLoan(params);
              })
              .catch(toastError);
          },
        );
      };

      $scope.getNewIncomeModel = (LoanSecurityInfoID) => {
        return {
          IsEvidenceOfTenancy: true,
          EmploymentId: 0,
          AssetId: 0,
          IncomeVerification: [
            {
              IncomeVerificationTypeId: 0,
              IncomeVerificationType: '',
            },
          ],
          Id: LoanSecurityInfoID,
          LoanScenarioId: $scope.loanAppId,
          FamilyId: $scope.familyId,
          FinancialId: 0,
          TypeId: 0,
          Description: '',
          Amount: 0,
          CurrencyCode: $scope.crmCurrency,
          CategoryId: 0,
          CurrencyCodeId: $scope.crmCountryID,
          Borrowers: [],
          FrequencyID: 0,
          Provider: '',
          IsAllOwner: true,
          IsLinkedSomewhereElse: true,
          LoanTerm: 0,
          NextGenValue: '',
          IncomeSubTypeId: '1',
          RentalVerificationID: '',
        };
      };

      $scope.formAreas = {
        info: {
          error: false,
          data: ['SecurityTypeId', 'TransactionId', 'owners'],
        },
        details: {
          error: false,
          data: [
            'StatusId',
            'PropertyPrimaryPurposeId',
            'HoldingId',
            'ZoningId',
            'PropertyTypeId',
          ],
        },
        value: {
          error: false,
          data: [
            'CurrentValue',
            'LandValue',
            'EstimatedPrice',
            'EstimatedBasesId',
            'ContratctPrice',
            'ContractDate',
          ],
        },
        location: {
          error: false,
          data: [
            'Suburb',
            'PropertyState',
            'formatted_address',
            'street_address',
            'TitleTypeId',
            'TitleId',
            'IdentityTypeId',
            'IdentityValue',
            'CompanyName',
            'PhoneAreaCode',
            'PhoneNumber',
            'ContactTitle',
            'ContactFirstName',
            'ContactLastName',
          ],
        },
        mortgages: {
          error: false,
          data: [
            'MortgagePriorityId',
            'CreditorId',
            'Limit',
            'MortgageAmount',
            'MortgageRepayments',
            'InterestRate',
            'AccountName',
            'BSB',
            'AccountNumber',
            'LoanTerm',
          ],
        },
        proposedRentalIncome: {
          error: false,
          data: ['IncomeGrossAmount', 'IncomeFrequency', 'rental'],
        },
      };

      optionsService.RentalVerificationTypeGet().then((response) => {
        $scope.rentalVerificationTypeList = response.data;
      });

      // this is for the security or funding required of Indonesian
      $scope.setSecurityModel = (securityData) => {
        $scope.securityModel = {};
        if (!securityData || !securityData.length) {
          return;
        }
        const {
          AssetId,
          FundingCalParametersId,
          SecurityInfoId,
          SecurityTypeId,
          PropertyDetails,
        } = securityData[0];
        $scope.securityModel.AssetId = AssetId;
        $scope.securityModel.FundingCalParametersId = FundingCalParametersId;
        $scope.securityModel.SecurityInfoId = SecurityInfoId;
        $scope.securityModel.SecurityTypeId = SecurityTypeId;
        $scope.securityModel.EstimatedPrice = PropertyDetails.EstimatedPrice;
        $scope.securityModel.formatted_address = PropertyDetails.Suburb;
      };

      $scope.setSecurity = () => {
        const setData = {
          LoanApplicationId: $scope.loanAppId,
          SecurityInfoId: $scope.securityModel.SecurityInfoId || 0,
          FundingCalParametersId:
            $scope.securityModel.FundingCalParametersId || 0,
          IsPrimary: true,
          SecurityTypeId: $scope.securityModel.SecurityTypeId
            ? parseInt($scope.securityModel.SecurityTypeId, 10)
            : '',
          LoanSecurityId: parseInt($scope.LoanSecurityList.LoanSecurityId, 10),
          TransactionId: '',
          MortgagePriority: [],
          IsRefinance: true,
          PropertyDetails: {
            LoanSecurityId: 0,
            StatusId: '',
            PropertyPrimaryPurposeId: '',
            HoldingId: '',
            ZoningId: '',
            PropertyTypeId: '',
            Suburb: $scope.securityModel.formatted_address
              ? $scope.securityModel.formatted_address
              : '',
            PropertyState: '',
            CurrentValue: '',
            LandValue: '',
            EstimatedPrice: $scope.securityModel.EstimatedPrice
              ? $scope.securityModel.EstimatedPrice
              : '',
            EstimatedBasesId: '',
            ContratctPrice: '',
            ContractDate: '',
            LicensedRealEstateAgentContract: true,
            IsPreApproved: true,
            IdentifierList: [],
            AddressId: 0,
            TitleTypeId: '',
            TitleId: '',
            ContactForAccess: {
              ContactForAccess: 0,
              CompanyName: '',
              ContactTitle: '',
              ContactFirstName: '',
              ContactLastName: '',
              PhoneAreaCode: '',
              PhoneNumber: '',
              FaxAreaCode: '',
              FaxNumber: '',
              ContactTypeId: '',
            },
          },
          MortgageList: [],
          IncomeList: [],
          Ownership: [],
          AssetId: $scope.securityModel.AssetId || 0,
        };
        $timeout.cancel($scope.securityTimeOut);
        $scope.securityTimeOut = $timeout(() => {
          loanApplicationServices.setLoanSecurityInfo(setData);
        }, 2000);
      };

      $scope.selectAddress = (address) => {
        $scope.securityModel.formatted_address = address.formatted_address;
        $scope.showSuggestionMenu = false;
        $scope.setSecurity();
      };

      $scope.getSuggestedAddress = () => {
        $timeout.cancel($scope.searchTimeOut);
        $scope.searchTimeOut = $timeout(() => {
          if ($scope.securityModel.formatted_address.length < 3) {
            return;
          }
          generalService
            .placeSearch($scope.securityModel.formatted_address)
            .then((response) => {
              if (!response || !response.data) {
                return;
              }
              $scope.showSuggestionMenu = true;
              $scope.addressSuggestions = response.data;
            });
        }, 500);
      };

      $scope.$on('$destroy', () => {
        if ($scope.searchTimeOut) {
          $timeout.cancel($scope.searchTimeOut);
        }
        if ($scope.securityTimeOut) {
          $timeout.cancel($scope.securityTimeOut);
        }
        if ($scope.reloadLoanDetailsTimeOut) {
          $timeout.cancel($scope.reloadLoanDetailsTimeOut);
        }
      });
      // Indonesian Funding Required End here.

      $scope.getScenarioDetails = () => {
        loanScenarioService.scenarioDetailsGet($scope.loanAppId).then((res) => {
          const { data } = res;
          if (!data || !data.LendingCategoryId) {
            return;
          }
          $scope.lendingCategoryId = data.LendingCategoryId;
          const { assetFinance } = configService.feature;
          $scope.isAssetFinance =
            ($scope.lendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE ||
              $scope.lendingCategoryId === LENDING_CATEGORY.PERSONAL_LOAN) &&
            assetFinance;
          $scope.isAssetFinance && $scope.getOtherFundsReq();
        });
      };
      $scope.getScenarioDetails();

      $scope.$watch(
        'loanAppSharedData.refreshAssetFinanceOtherFunds',
        (nv) => onWatchOtherFunds({ $scope, nv }),
        true,
      );

      $scope.assetFinanceModal = (loanSecurityInfoId = 0) => {
        const props = {
          familyId: $scope.familyId,
          loanAppId: $scope.loanAppId,
          loanSecurityInfoId,
          lendingCategoryId: $scope.lendingCategoryId,
        };
        const modalInstance = $uibModal.open({
          template: `<asset-finance-modal
                    modal-instance="vm.modalInstance"
                    loan-app-id="vm.props.loanAppId" 
                    loan-security-info-id="vm.props.loanSecurityInfoId" 
                    family-id="vm.props.familyId"
                    lending-category-id="vm.props.lendingCategoryId">
                  </asset-finance-modal>`,
          size: 'lg',
          backdrop: 'static',
          windowClass: 'asset-finance-modal',
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: {
            props: () => props,
          },
        });
        modalInstance.result.then(() => {
          swal({
            title: 'Success',
            text: 'Asset finance successfully added.',
            type: 'success',
            timer: 2000,
            showConfirmButton: false,
          });
          $scope.getLoanSecurity();
        });
      };

      $scope.showPropertyTypeSelectionModal = () => {
        const modalInstance = $uibModal.open({
          template: `<property-type-selection
                    modal-instance="vm.modalInstance">
                  </property-type-selection>`,
          size: 'md',
          backdrop: 'static',
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: {
            props: () => {},
          },
        });

        modalInstance.result.then((result) => {
          switch (result.selectedType) {
            case ADD_PROPERTY_TYPE.NEW_PURCHASE:
              $scope.showAddSecurityModal();
              break;
            case ADD_PROPERTY_TYPE.EXISTING:
              if (!result.isCancel) {
                $scope.getLoanSecurity();
                $scope.financialsSharedService.AssetInfoGet(true);
              }
              break;
            default:
              break;
          }
        });
      };

      $scope.showAddSecurityModal = () => {
        if ($scope.isAssetFinance) {
          $scope.assetFinanceModal();
          return;
        }
        const security = setValidTransactionTypePerSecurity({
          transactionTypeList: $scope.TransactionTypeList,
          isConstructionLoanApp: configService.feature.constructionLoanApps,
        });
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/loanApplication/clientAssess/loanDetails.v2/modal/security.html',
          controller: 'SecurityModalCtrl',
          size: 'lg',
          scope: $scope,
          resolve: {
            LoanSecurityInfoId: () => 0,
            TransactionTypeList: () => security.TransactionTypeList,
          },
        });

        modalInstance.result.then(() => {
          saveLMIFundingFromLoanDetails({
            loanAppSharedData,
            fundingCalculatorService,
            securityList: $scope.securityList,
          });
        });
      };

      $scope.showEditSecurityModal = (
        LoanSecurityInfoId,
        TransactionTypeList,
      ) => {
        if ($scope.isAssetFinance) {
          $scope.assetFinanceModal(LoanSecurityInfoId);
          return;
        }
        $uibModal.open({
          templateUrl:
            '/assets/views/loanApplication/clientAssess/loanDetails.v2/modal/security.html',
          controller: 'SecurityModalCtrl',
          size: 'lg',
          scope: $scope,
          resolve: {
            LoanSecurityInfoId: () => LoanSecurityInfoId,
            TransactionTypeList: () => TransactionTypeList,
          },
        });
      };

      $scope.addLMIModal = () => {
        fundingDetailsService.openLMIModal({
          securityBrokerEventId: loanAppSharedData.securityBrokerEventId,
          isFromFundingDetails: true,
          loanDetails: $scope.loanAppSharedData.loanDetailsSet,
          onUpdateLMIValueOnTxt: (lmiPremium) =>
            onUpdateLMIValueOnTxt(lmiPremium, $scope),
        });
      };
      loanAppSharedData.isFundingDetailsLoaded = true;
      /*
            Below code are temporary
        */
      $scope.addOther = (item) => {
        if (_.isUndefined(item.Values)) {
          item.Values = [];
        }

        item.Values.push({
          Name: '',
          Value: '',
          isEditing: false,
          isNew: true,
        });
      };

      $scope.saveOther = (other) => {
        other.isEditing = false;
        other.isNew = false;
      };

      $scope.deleteOther = (item, other) => {
        _.remove(item, (o) => {
          return _.isEqual(o, other);
        });
      };
    });
