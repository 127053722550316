import template from './profileImages.html';
import controller from './profileImagesCtrl';
import './style.scss';

export default {
  template,
  bindings: {
    familyId: '<',
    allReadOnly: '<',
    isUsersProfile: '<',
    accessType: '<',
  },
  controller,
  controllerAs: 'vm',
};
