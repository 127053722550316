import template from './eSignDocument.html';
import controller from './eSignDocumentCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    remapDocument: '<',
    document: '<',
    onRefreshDocumentsList: '&',
  },
};
