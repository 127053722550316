import dotProp from 'dot-prop-immutable';

export const employmentInstance = (propertyOf) => {
  return {
    getEmploymentNature: () => {
      return propertyOf.optionsService
        .getEmploymentNature()
        .then((response) => {
          propertyOf.employmentNature = [];
          if (response && response.length) {
            propertyOf.employmentNature = response;
          }
        });
    },

    validateNZBNcharacter: (data) => {
      propertyOf.invalidCharacters = false;
      const NZBNChar = (data || '').toString() || '';
      if (
        NZBNChar &&
        NZBNChar.length !== propertyOf.NZBN_PROPERTY.NUMERICAL_LENGTH
      ) {
        propertyOf.invalidCharacters = !propertyOf.invalidCharacters;
      }
    },

    employmentNatureType: () => {
      const isSelfEmployed =
        propertyOf.employment.EmploymentTypeId ===
        propertyOf.NZBN_PROPERTY.SELF_EMPLOYED;
      const isRegCompany =
        propertyOf.employment.employmentNature ===
        propertyOf.NZBN_PROPERTY.REGISTERED_COMPANY;
      const isSoleTrader =
        propertyOf.employment.employmentNature ===
        propertyOf.NZBN_PROPERTY.SOLE_TRADER;
      propertyOf.formReference.employmentNameAndAddress =
        (isSelfEmployed && isRegCompany) || (isSoleTrader && isSelfEmployed);
      const isPaye =
        propertyOf.employment.EmploymentTypeId ===
          propertyOf.NZBN_PROPERTY.PAYE && propertyOf.currentUserService.isNZ;
      propertyOf.formReference.employmentNameAndAddress = isPaye;
      return isSelfEmployed && isRegCompany;
    },

    employmentType: () => {
      propertyOf.employmentDefaultType();
    },
    checkRequiredFields: (fieldName = '') => {
      const isSubmitted =
        propertyOf.formReference.$submitted &&
        propertyOf.formReference.$invalid;
      return (
        propertyOf.invalidDateRange ||
        (propertyOf.formReference[fieldName].$invalid && isSubmitted)
      );
    },
  };
};

export const assignEmploymentDefaultDay = (employment, isStarted = false) => {
  if (!employment) {
    return;
  }
  if (!employment.DateStarted) {
    employment.DateStarted = {};
  }
  if (!employment.DateEnded) {
    employment.DateEnded = {};
  }
  const dateObjName = isStarted ? 'DateStarted' : 'DateEnded';
  const dayArrayName = isStarted ? 'dateStartedDays' : 'dateEndedDays';
  const selectedDay = dotProp.get(employment, `${dateObjName}.Day`, '');
  const { length: lastDay } = employment[dayArrayName] || [];
  employment[dateObjName].Day = selectedDay > lastDay ? lastDay : selectedDay;
};
