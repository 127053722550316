import { toastError, toastSuccess } from 'Common/utilities/alert';
import { OK } from 'Common/constants/httpStatusCodes';

export default class InsuranceFileCtrl {
  constructor(
    pipelineApiService,
    insuranceFileService,
    insuranceModalApplicationService,
  ) {
    'ngInject';

    this.pipelineApiService = pipelineApiService;
    this.insuranceFileService = insuranceFileService;
    this.insuranceModalApplicationService = insuranceModalApplicationService;
  }

  $onInit() {
    this.getInsuranceFiles();
  }

  getInsuranceFiles() {
    this.insuranceFileService
      .getInsuranceFiles(this.familyId)
      .then((data = []) => {
        this.insuranceFiles = data;
        this.insuranceFile = data[0];
        this.insuranceFile && this.getInsuranceFileStatusId();
      });
  }

  getInsuranceFileStatusId() {
    this.pipelineApiService
      .getInsuranceFileStatusId(this.insuranceFile.id)
      .then((statusId) => {
        this.insuranceFile.statusId = statusId;
      });
  }

  openInsuranceFile() {
    this.insuranceModalApplicationService
      .openInsuranceFile({
        familyId: this.familyId,
        file: this.insuranceFile,
        files: this.insuranceFiles,
      })
      .result.then(({ refresh }) => {
        refresh && this.getInsuranceFiles();
      });
  }

  onStatusChange(statusId, statusName) {
    if (!this.insuranceFile?.id) {
      this.openInsuranceFile();
    }
    this.pipelineApiService
      .updateInsuranceFileStatus(this.insuranceFile.id, statusId)
      .then(({ status }) => {
        if (status === OK) {
          toastSuccess(
            `Insurance file successfully moved to ${statusName} status`,
            'Insurance File Status',
          );
          return;
        }
        toastError(
          'No pipeline exists for this insurance file',
          'Insurance File Status',
        );
        this.getInsuranceFiles();
      });
  }
}
