import angular from 'angular';

/**
 * @usage: add toggler attribute with desired scope variable ie. toggler="isOpen"
 * @description: click/unclick of the element will toggle the scope var, clicking outside the document will falsify it
 */
export const loadModule = () =>
  angular.module('app').directive('toggler', function toggler($document) {
    return {
      scope: {
        toggler: '=',
      },
      link(scope, elem) {
        let count = 0;
        const toggle = (e) => {
          if (typeof scope.toggler === 'undefined') {
            return;
          }
          const validToggleOut =
            elem[0] !== e.target &&
            !elem[0].contains(e.target) &&
            scope.toggler &&
            count;
          validToggleOut && (scope.toggler = false);
          count++;
        };

        $document.on('click', toggle);

        scope.$on('$destroy', () => {
          $document.off('click', toggle);
        });
      },
    };
  });
