import { convertPartiesToGrouped } from 'Common/utilities/involvedParties';

class InvolvedPartiesOptionCtrl {
  constructor($timeout, loanScenarioModelService, clientInfoService) {
    'ngInject';

    this.$timeout = $timeout;
    this.loanScenarioModelService = loanScenarioModelService;
    this.clientInfoService = clientInfoService;
  }

  $onInit() {
    this.disabledFamilyId = 0;
    this.loanScenarioModelService
      .getApplicants({
        loanScenarioId: this.opportunityId,
      })
      .then(({ data: involvedParties }) => {
        const {
          clientInfoService,
          selectedValues = [],
          isSelectAllByDefault = false,
        } = this;
        this.involvedParties = convertPartiesToGrouped({
          clientInfoService,
          involvedParties,
          selectedValues,
          isSelectAllByDefault,
        });
        this.checkInvolvedPartiesValidity();
      });
    if (typeof this.instance !== 'object') {
      return;
    }
    this.instance.getSelectedValues = () => this.getSelectedValues();
  }

  getSelectedValues() {
    return Object.keys(this.involvedParties).reduce((accum, key) => {
      const involvedParties = this.involvedParties[key] || [];
      const selectedParties = (
        involvedParties.filter((party) => party.isSelected) || []
      ).map((selectedParty) => selectedParty.familyId);
      return accum.concat(selectedParties);
    }, []);
  }

  getAllInvolvedParties() {
    return Object.keys(this.involvedParties).reduce((accum, key) => {
      const involvedParties = this.involvedParties[key] || [];
      return accum.concat(involvedParties);
    }, []);
  }

  checkInvolvedPartiesValidity() {
    if (!this.required) {
      this.instance.valid = true;
      return;
    }
    this.validityTimeoutInstance = this.$timeout(() => {
      const selectedClient = this.getSelectedValues();
      this.instance.valid = !!selectedClient?.length;
      const allInvolvedParties = this.getAllInvolvedParties();
      this.disabledFamilyId =
        allInvolvedParties?.length === 1 ? allInvolvedParties[0].familyId : 0;
    });
  }

  $onDestroy() {
    this.validityTimeoutInstance &&
      this.$timeout.cancel(this.validityTimeoutInstance);
  }
}

export default InvolvedPartiesOptionCtrl;
