class EsignDownloadEvidenceSummary {
  constructor(downloadDataService) {
    'ngInject';

    this.downloadDataService = downloadDataService;
  }

  downloadEvidenceSummary() {
    if (!this.documentId) {
      return;
    }
    this.downloadDataService.documentDownload(this.documentId);
  }
}
export default EsignDownloadEvidenceSummary;
