import template from './widgetCurrencyInput.html';
import controller from './widgetCurrencyInputCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    fieldId: '@',
    label: '@',
    required: '<',
    minValue: '<',
    maxValue: '<',
    modelValue: '<',
    modelSubValue: '<',
    subDropdown: '<',
    defaultSubValueIndex: '<',
    onUpdate: '&',
    onSubDropdownUpdate: '&',
  },
};
