class NumberSpinnerCtrl {
  $onInit() {
    this.validateAttributes();
    if (!this.model) {
      this.model = 0;
    }
  }

  validateAttributes() {
    this.validateMinMaxValue('minValue');
    this.validateMinMaxValue('maxValue');
  }

  validateMinMaxValue(valueType) {
    const value = this[valueType];
    if (
      typeof value !== 'number' &&
      typeof value !== 'undefined' &&
      value !== null
    ) {
      console.error(
        `${valueType} set for number-spinner is invalid. The value should be a number.`,
      );
    }
  }

  increment() {
    if (!this.maxValue || this.model < this.maxValue) {
      this.updateModel(parseInt(this.model, 10) + 1);
    }
  }

  decrement() {
    if (!this.minValue || this.model > this.minValue) {
      this.updateModel(this.model - 1);
    }
  }

  updateModel(value) {
    this.numberSpinnerForm.inputField.$setViewValue(value.toString());
    this.numberSpinnerForm.inputField.$render();
  }

  validateUserInput() {
    if (this.maxValue && this.model > this.maxValue) {
      this.updateModel(this.maxValue);
    }
    if (this.minValue && this.model < this.minValue) {
      this.updateModel(this.minValue);
    }
  }
}

export default NumberSpinnerCtrl;
