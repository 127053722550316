import template from './tablePagination.html';
import controller from './tablePaginationCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    totalRecords: '<',
    pageLength: '<',
    currentPage: '<',
    itemsPerPage: '<',
    customTableSize: '<',
    onPageChange: '&',
    theme: '@',
  },
};
