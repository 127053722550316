import { formatToCurrency } from 'Common/utilities/currency';

const addEmphasizeStyle = 'class="text-bold"';
const addItalicStyle = 'class="text-italic"';
const addDotSeparator = '<div class="dot inline-block"></div>';

const appendDotSeparator = () => addDotSeparator;
const appendEmphasizeStyle = () => addEmphasizeStyle;
const appendItalicStyle = () => addItalicStyle;

export const loanVariationTypeBuilderForUI = (type) => {
  return {
    id: type.TopUpOrVariationTypeId,
    name: type.TopUpOrVariationTypeName,
  };
};

export const loanVariationBuilderForUI = (variation) => {
  return {
    loanId: variation.LoanId,
    loanAppId: variation.LoanAppId,
  };
};

export const loanVariationSummaryBuilderForUI = (
  variation,
  isCompact = false,
) => {
  if (!variation) {
    return {};
  }

  const summaryList = getLoanVariationSummaryListFormat(variation, isCompact);

  return (
    variation && {
      primaryTopUpOrVariationTypeId: variation.PrimaryTopUpOrVariationTypeId,
      secondaryTopUpOrVariationTypeIds:
        variation.SecondaryTopUpOrVariationTypeIds,
      summaryList,
    }
  );
};

export const getLoanVariationSummaryListFormat = (
  variation,
  isCompact = false,
) => {
  if (isCompact) {
    return [
      variation.PrincipalIncrease &&
        loanVariationPrincipalIncreaseCompactFormat(variation),
      variation.PrincipalDecrease &&
        loanVariationPrincipalDecreaseCompactFormat(variation),
      variation.AddedSecurities &&
        loanVariationAddSecurityCompactFormat(variation),
      variation.DischargedSecurities &&
        loanVariationSecurityDischargeCompactFormat(variation),
      variation.SecuritySwap &&
        loanVariationSecuritySwapCompactFormat(variation),
      variation.AddedNewProducts &&
        loanVariationAddNewProductCompactFormat(variation),
      variation.ProductSplit &&
        loanVariationProductSplitCompactFormat(variation),
    ].filter((summary) => !!summary);
  }

  return [
    variation.PrincipalIncrease &&
      loanVariationPrincipalIncreaseFormat(variation),
    variation.PrincipalDecrease &&
      loanVariationPrincipalDecreaseFormat(variation),
    variation.AddedSecurities && loanVariationAddSecurityFormat(variation),
    variation.DischargedSecurities &&
      loanVariationSecurityDischargeFormat(variation),
    variation.SecuritySwap && loanVariationSecuritySwapFormat(variation),
    variation.AddedNewProducts && loanVariationAddNewProductFormat(variation),
    variation.ProductSplit && loanVariationProductSplitFormat(variation),
  ].filter((summary) => !!summary);
};

export const loanVariationPrincipalIncreaseFormat = ({ PrincipalIncrease }) => {
  if (
    !PrincipalIncrease ||
    PrincipalIncrease.OldPrincipalAmount ===
      PrincipalIncrease.NewPrincipalAmount
  ) {
    return '';
  }
  return `<strong>Top Up</strong> Current Principal $${formatToCurrency(
    PrincipalIncrease.OldPrincipalAmount,
  )} - New Principal $${formatToCurrency(
    PrincipalIncrease.NewPrincipalAmount,
  )} - Increase of $${formatToCurrency(
    PrincipalIncrease.NewPrincipalAmount - PrincipalIncrease.OldPrincipalAmount,
  )}`;
};

export const loanVariationPrincipalIncreaseCompactFormat = ({
  PrincipalIncrease,
}) => {
  if (
    !PrincipalIncrease ||
    PrincipalIncrease.OldPrincipalAmount ===
      PrincipalIncrease.NewPrincipalAmount
  ) {
    return '';
  }
  return `<div><span  ${appendEmphasizeStyle()}>Top Up</span> 
  <span>Old Principal: $${formatToCurrency(
    PrincipalIncrease.OldPrincipalAmount,
  )}</span>
  ${appendDotSeparator()}
  <span><span ${appendItalicStyle()}>New</span> Principal: 
  <span >
  $${formatToCurrency(PrincipalIncrease.NewPrincipalAmount)}
  </span></span></div>`;
};

export const loanVariationPrincipalDecreaseFormat = ({ PrincipalDecrease }) => {
  if (
    !PrincipalDecrease ||
    PrincipalDecrease.OldPrincipalAmount ===
      PrincipalDecrease.NewPrincipalAmount
  ) {
    return '';
  }
  return `<strong>Principal Decrease</strong> Current Principal $${formatToCurrency(
    PrincipalDecrease.OldPrincipalAmount,
  )} - New Principal $${formatToCurrency(
    PrincipalDecrease.NewPrincipalAmount,
  )} - Decrease of $${formatToCurrency(
    PrincipalDecrease.OldPrincipalAmount - PrincipalDecrease.NewPrincipalAmount,
  )}`;
};

export const loanVariationPrincipalDecreaseCompactFormat = ({
  PrincipalDecrease,
}) => {
  if (
    !PrincipalDecrease ||
    PrincipalDecrease.OldPrincipalAmount ===
      PrincipalDecrease.NewPrincipalAmount
  ) {
    return '';
  }
  return `<div> <span ${appendEmphasizeStyle()}>Principal Decrease</span> 
      <span> Old Principal: $${formatToCurrency(
        PrincipalDecrease.OldPrincipalAmount,
      )}</span>
      ${appendDotSeparator()}
      <span><span ${appendItalicStyle()}>New</span> Principal:  
      <span>
      $${formatToCurrency(
        PrincipalDecrease.NewPrincipalAmount,
      )}</span></span></div>`;
};

export const loanVariationSecurityFormat = (security) => {
  return `${security.Address}, $${formatToCurrency(security.SecurityValue)}`;
};

export const loanVariationSecurityLabelPluralize = (securityList = []) => {
  return securityList.length > 1 ? 'securities' : 'security';
};

export const loanVariationSecuritiesMap = (securities) =>
  securities
    .map((security) => loanVariationSecurityFormat(security))
    .join('; ');

export const loanVariationAddSecurityFormat = ({ AddedSecurities }) => {
  if (!AddedSecurities || !AddedSecurities.length) {
    return '';
  }
  return `<strong>Add Security</strong> Added ${
    AddedSecurities.length > 1 ? 'securities' : 'security'
  }: ${loanVariationSecuritiesMap(AddedSecurities)}`;
};

export const loanVariationAddSecurityCompactFormat = ({ AddedSecurities }) => {
  if (!AddedSecurities || !AddedSecurities.length) {
    return '';
  }
  return `<div><span ${appendEmphasizeStyle()}>Add Security</span> <span><span ${appendItalicStyle()}>New</span> ${
    AddedSecurities.length > 1 ? 'securities' : 'security'
  }:  
  <span>
  ${loanVariationSecuritiesMap(AddedSecurities)}
  </span></span></div>`;
};

export const loanVariationSecurityDischargeFormat = ({
  DischargedSecurities,
}) => {
  if (!DischargedSecurities || !DischargedSecurities.length) {
    return '';
  }
  return `<strong>Security Discharge</strong> Discharged ${loanVariationSecurityLabelPluralize(
    DischargedSecurities,
  )}: ${loanVariationSecuritiesMap(DischargedSecurities)}`;
};

export const loanVariationSecurityDischargeCompactFormat = ({
  DischargedSecurities,
}) => {
  if (!DischargedSecurities || !DischargedSecurities.length) {
    return '';
  }
  return `<div>
  <span ${appendEmphasizeStyle()}>Security Discharge</span> 
  <span>Discharged ${loanVariationSecurityLabelPluralize(
    DischargedSecurities,
  )}:  <span>
  ${loanVariationSecuritiesMap(DischargedSecurities)}
  </span></span> </div>`;
};

export const loanVariationSecuritySwapFormat = ({ SecuritySwap }) => {
  if (!SecuritySwap) {
    return '';
  }

  const { DischargedSecurities, AddedSecurities } = SecuritySwap;

  if (
    !DischargedSecurities ||
    !AddedSecurities ||
    (!DischargedSecurities.length && !AddedSecurities.length)
  ) {
    return '';
  }

  return `<strong>Security Swap</strong> Discharged ${loanVariationSecurityLabelPluralize(
    DischargedSecurities,
  )}: ${loanVariationSecuritiesMap(
    DischargedSecurities,
  )} - Added ${loanVariationSecurityLabelPluralize(
    AddedSecurities,
  )}: ${loanVariationSecuritiesMap(AddedSecurities)}`;
};

export const loanVariationSecuritySwapCompactFormat = ({ SecuritySwap }) => {
  if (!SecuritySwap) {
    return '';
  }

  const { DischargedSecurities, AddedSecurities } = SecuritySwap;

  if (
    !DischargedSecurities ||
    !AddedSecurities ||
    (!DischargedSecurities.length && !AddedSecurities.length)
  ) {
    return '';
  }

  return `<div><span ${appendEmphasizeStyle()}>Security Swap</span> <span>Old ${loanVariationSecurityLabelPluralize(
    DischargedSecurities,
  )}: ${loanVariationSecuritiesMap(DischargedSecurities)}</span>
  ${appendDotSeparator()}
  <span><span ${appendItalicStyle()}>New</span> ${loanVariationSecurityLabelPluralize(
    AddedSecurities,
  )}:  
  <span>
  ${loanVariationSecuritiesMap(AddedSecurities)}
  </span></span></div>`;
};

export const loanVariationAddNewProductFormat = ({ AddedNewProducts }) => {
  if (!AddedNewProducts) {
    return '';
  }

  const { OldProducts, NewProducts } = AddedNewProducts;
  if (!OldProducts || !NewProducts || !NewProducts.length) {
    return '';
  }

  return `<strong>Add New Product</strong> Old ${
    OldProducts.length > 1 ? 'products' : 'product'
  }: ${OldProducts.join('; ')} - New ${
    NewProducts.length > 1 ? 'products' : 'product'
  }: ${NewProducts.join('; ')}`;
};

export const loanVariationAddNewProductCompactFormat = ({
  AddedNewProducts,
}) => {
  if (!AddedNewProducts) {
    return '';
  }

  const { OldProducts, NewProducts } = AddedNewProducts;
  if (!OldProducts || !NewProducts || !NewProducts.length) {
    return '';
  }

  return `<div><span ${appendEmphasizeStyle()}>Add New Product</span> <span><span ${appendItalicStyle()}>New</span> ${
    NewProducts.length > 1 ? 'products' : 'product'
  }:  
  <span>
  ${NewProducts.join('; ')}
  </span></span></div>`;
};

export const interestOnlyTermFormat = (interestOnlyTerm) => {
  if (!interestOnlyTerm) {
    return '';
  }
  return ` for ${interestOnlyTerm} ${interestOnlyTerm > 1 ? 'years' : 'year'}`;
};

export const loanVariationSplitFormat = (split, index) => {
  return `Split ${index + 1} $${formatToCurrency(split.TotalLoanAmount)}${
    split.RateType ? ` ${split.RateType}` : ''
  } ${split.RepaymentType}${interestOnlyTermFormat(split.InterestOnlyTerm)}`;
};

export const loanVariationSplitCompactFormat = (split, index) => {
  return `Split ${index + 1} $${formatToCurrency(split.TotalLoanAmount)}${
    split.RateType ? ` ${split.RateType}` : ''
  }`;
};

export const loanVariationSplitMap = (splits, isCompact) => {
  return splits
    .map((split, index) =>
      isCompact
        ? loanVariationSplitCompactFormat(split, index)
        : loanVariationSplitFormat(split, index),
    )
    .join(', ');
};

export const loanVariationProductSplitFormat = ({ ProductSplit }) => {
  if (!ProductSplit) {
    return '';
  }

  const { OldLoanSplits, NewLoanSplits } = ProductSplit;
  return `<strong>Product Split</strong> Current split structure: ${loanVariationSplitMap(
    OldLoanSplits,
  )} - New split structure: ${loanVariationSplitMap(NewLoanSplits)}`;
};

export const loanVariationProductSplitCompactFormat = ({ ProductSplit }) => {
  if (!ProductSplit) {
    return '';
  }

  const { OldLoanSplits, NewLoanSplits } = ProductSplit;
  if (!OldLoanSplits) {
    return '';
  }
  return `<div><span ${appendEmphasizeStyle()}>Product Split</span><span>Old split struc: ${loanVariationSplitMap(
    OldLoanSplits,
    true,
  )}</span>
  ${appendDotSeparator()}
  <span>
  <span ${appendItalicStyle()}>New</span> split struc:  
  <span>
    ${loanVariationSplitMap(NewLoanSplits, true)}
  </span></span></div>`;
};
