class WatcherService {
  constructor() {
    this.watchers = {};
  }

  addWatcher(watcher, context) {
    if (!watcher || !context) {
      return;
    }

    if (this.watchers[context]) {
      this.watchers[context] = [...this.watchers[context], watcher];
    } else {
      this.watchers[context] = [watcher];
    }
  }

  destroyWatchers(context) {
    if (!context) {
      return;
    }

    if (this.watchers[context] && this.watchers[context].length) {
      this.watchers[context].forEach((watcher) => {
        if (watcher) {
          watcher();
        }
      });

      this.watchers[context] = null;
    }
  }
}

export default WatcherService;
