import _ from 'lodash';
import { setReferralItemId } from 'Common/utilities/referral';
import {
  appendPreferredName,
  getDisplayFirstname,
} from 'Common/utilities/contact';

class ClientInfoService {
  constructor(utilitiesService, userService, $q) {
    'ngInject';

    this.utilitiesService = utilitiesService;
    this.userService = userService;
    this.$q = $q;
  }

  getFullName(data) {
    this.fullname = '';
    if (data && data.length) {
      data.forEach((family) => {
        if (family.Role === 'Adult' || parseInt(family.Role, 10) === 0) {
          this.fullname = family.FullName || '';
        }
      });
    }
    return this.fullname;
  }

  selectClient(contactInfoSetValue, referredByHelper, client) {
    const defer = this.$q.defer();
    contactInfoSetValue.ReferrerAgreementTypeId = client.AgreementTypeId;
    contactInfoSetValue.ReferrerOrgName = client.ReferralOrganizationName;
    contactInfoSetValue.ReferrerOrgID = client.ReferralOrganizationId;
    contactInfoSetValue.ReferrerID = client.ReferralMemberId;
    contactInfoSetValue.ReferralItemId = client.ReferralItemId;
    contactInfoSetValue.ReferrerName = client.ReferralMemberName;
    contactInfoSetValue.ReferrerNameLabel = `${client.ReferralMemberName} - ${client.ReferralOrganizationName}`;
    referredByHelper.showClientList = false;
    this.enquiryChanged(contactInfoSetValue).then(() => {
      defer.resolve();
    });
    return defer.promise;
  }

  enquiryChanged(contactInfoSetValue) {
    const defer = this.$q.defer();
    this.EnquirySource = contactInfoSetValue.EnquirySource;
    this.resetReferral();
    contactInfoSetValue.ReferralCategory = this.EnquirySource.ReferralCategoryName;
    contactInfoSetValue.ReferralCategoryId = this.EnquirySource.ReferralCategoryId;
    contactInfoSetValue.ReferralItem = this.EnquirySource.ReferralItemName;
    contactInfoSetValue.ReferralItemId = setReferralItemId(this.EnquirySource);
    defer.resolve();
    return defer.promise;
  }

  resetReferral() {
    const props = [
      'ReferralCategoryName',
      'ReferralCategoryId',
      'ReferralItemName',
      'ReferralItemId',
    ];
    this.EnquirySource = _.pick(this.EnquirySource, props);
  }

  updateContactStatus(ContactStatus, status) {
    this.status = status;
    ContactStatus.Name = this.status.Name;
    ContactStatus.ContactStatusId = this.status.Value;
  }

  getAccounTypeNumber() {
    const defer = this.$q.defer();
    this.userService.GetUserInfo().then((response) => {
      const accessType = response.data.AccessType;
      defer.resolve({ data: accessType });
    });
    return defer.promise;
  }

  dateFormattingList(list) {
    const defer = this.$q.defer();
    _.each(list, (item) => {
      item.DOB = item.DOB.split('/').reverse().join('-');
    });
    defer.resolve({ data: list });
    return defer.promise;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getClientHeader(info, usePreferredName) {
    let adultNames = [];
    // eslint-disable-next-line sonarjs/prefer-object-literal
    const clientInfoObj = {};
    clientInfoObj.clientHeaderName = '';
    clientInfoObj.clientInitials = '';
    if (info.information && info.information.length) {
      info.information.forEach((client, idx) => {
        const isClientRoleValid =
          client.Role &&
          (client.Role.toLowerCase() === 'adult' ||
            parseInt(client.Role, 10) === 0);
        if (isClientRoleValid) {
          const adultName = {};
          if (adultNames.length) {
            let clientInfoName = {};
            adultNames.forEach((value) => {
              if (value.LastName === client.LastName) {
                clientInfoName = value;
              }
            });

            if (clientInfoName.LastName) {
              clientInfoObj.clientHeaderName = this.utilitiesService.getHeaderName(
                getDisplayFirstname(clientInfoName, usePreferredName),
                getDisplayFirstname(client, usePreferredName),
                client.LastName,
              );
            } else {
              clientInfoObj.clientHeaderName = this.utilitiesService.getHeaderName(
                clientInfoObj.clientHeaderName,
                getDisplayFirstname(client, usePreferredName),
                client.LastName,
              );
            }
            clientInfoObj.clientInitials = this.utilitiesService.filterInitial(
              client.FirstName,
              client.LastName,
            );
          } else {
            adultName.idx = idx;
            adultName.FirstName = getDisplayFirstname(client, usePreferredName);
            adultName.LastName = client.LastName;
            adultNames = [...adultNames, adultName];
            clientInfoObj.clientHeaderName += `${getDisplayFirstname(
              client,
              usePreferredName,
            )} ${client.LastName}`;
            clientInfoObj.clientInitials = this.utilitiesService.filterInitial(
              client.FirstName,
              client.LastName,
            );
          }
        }
      });
    }
    info.headerName = clientInfoObj.clientHeaderName || '';
    info.initials = clientInfoObj.clientInitials || '';

    return info;
  }

  addAdultItem(client) {
    if (!client) {
      return;
    }
    const name =
      client.Title && client.Title.trim()
        ? `${client.Title} ${appendPreferredName(client)}`
        : appendPreferredName(client);
    const adult = {
      name,
      isPrimary: client.isPrimary,
      deceased: client.Deceased,
    };
    this.summaryData.Adults = [...this.summaryData.Adults, adult];
  }

  buildSummary(clientsData) {
    this.summaryData = {
      Adults: [],
      Children: [],
      Phone: [],
      Email: [],
      Address: [],
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    _.forEach(clientsData, (client) => {
      if (
        client.Role.toLowerCase() === 'adult' ||
        client.Role.toLowerCase() !== 'child'
      ) {
        this.addAdultItem(client);
      } else if (client.Role && client.Role.toLowerCase() === 'child') {
        if (
          client.FirstName &&
          (client.FirstName.trim() !== '' || client.LastName.trim() !== '')
        ) {
          this.summaryData.Children.push(
            `${client.FirstName} ${client.LastName}`,
          );
        }
      } else {
        this.summaryData.Children.push(
          `${client.Title}  ${client.FirstName} ${client.LastName}`,
        );
      }

      if (client.Phone && client.Phone.length >= 1) {
        _.forEach(client.Phone, (value) => {
          if (value.Number) {
            this.summaryData.Phone.push({
              number: `${value.Number}`,
              name: client.FirstName,
            });
          }
        });
      }
      if (client.Email.length >= 1) {
        _.forEach(client.Email, (value) => {
          if (value.EmailAddress) {
            this.summaryData.Email.push(value.EmailAddress);
          }
        });
      }
    });
    return this.summaryData;
  }
}

export default ClientInfoService;
