import dotProp from 'dot-prop-immutable';
import { ASSET_CATEGORY_ID } from 'Common/constants/assets';
import { getFullName } from 'Common/utilities/contact';
import {
  getNewAssetModel,
  getAssetClient,
  getOptionsOnInit,
  getOptionsList,
  getOptionsObj,
  populatePropertyType,
  setDefaultValues,
  onSelectAll,
  getOwnersListOnItemSelect,
  addAsset,
} from 'Common/utilities/assetsModal';

class ExistingAssetListCtrl {
  constructor(
    $uibModal,
    $stateParams,
    financialsSharedService,
    currentUserService,
    loanScenarioService,
    contactModelService,
    optionsService,
    financeService,
    contactService,
    dashboardService,
    loanAppSharedData,
    configService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$stateParams = $stateParams;
    this.financialsSharedService = financialsSharedService;
    this.currentUserService = currentUserService;
    this.loanScenarioService = loanScenarioService;
    this.contactModelService = contactModelService;
    this.optionsService = optionsService;
    this.financeService = financeService;
    this.contactService = contactService;
    this.dashboardService = dashboardService;
    this.loanAppSharedData = loanAppSharedData;
    this.configService = configService;
  }

  $onInit() {
    this.initializeValues();
    this.setUtilityFunctions();
    this.checkExistingAssetList();
  }

  initializeValues() {
    this.loanAppId = this.$stateParams.loanAppId;
    this.familyId = this.$stateParams.familyId;
    this.getFullName = getFullName;
    this.currency = this.currentUserService.brokerRegionalization.currencySign;
  }

  setUtilityFunctions() {
    this.getNewAssetModel = getNewAssetModel({
      $scope: this,
      currentUserService: this.currentUserService,
    });

    this.getAssetClient = getAssetClient({
      $scope: this,
      loanScenarioService: this.loanScenarioService,
    });

    this.getOptionsList = getOptionsList({
      $scope: this,
      optionsService: this.optionsService,
      currentUserService: this.currentUserService,
      financeService: this.financeService,
      contactService: this.contactService,
    });

    this.populatePropertyTypeCall = populatePropertyType({
      $scope: this,
      financeService: this.financeService,
      currentUserService: this.currentUserService,
    });

    this.setDefaultValuesMethod = setDefaultValues({
      $scope: this,
      optionsService: this.optionsService,
      financeService: this.financeService,
      isNZ: this.currentUserService.isNZ,
    });

    this.onSelectAll = onSelectAll({ $scope: this });
  }

  checkExistingAssetList() {
    const {
      assetFinancialsInitialized: isInitialized,
    } = this.financialsSharedService;

    const callback = () => {
      this.formatExistingAssetsList();
    };

    if (!isInitialized) {
      this.financialsSharedService.AssetInfoGet(true, callback);
      return;
    }
    callback();
  }

  formatExistingAssetsList() {
    const listAssets = this.financialsSharedService.listAssets || [];
    const flattenedList = listAssets.reduce((acc, cur) => {
      const assetInfo = (cur.overview && cur.overview.FinancialInfo) || [];
      const nonSecurityRealEstateAssets = assetInfo.filter((info) => {
        const realEstate = info.CategoryId === ASSET_CATEGORY_ID.REAL_ESTATE;
        return realEstate && !info.IsSecurity;
      });
      return [...acc, ...nonSecurityRealEstateAssets];
    }, []);
    const distinctIds = [...new Set(flattenedList.map((o) => o.FinancialId))];
    this.realEstateAssets = distinctIds.map((id) =>
      flattenedList.find((item) => item.FinancialId === id),
    );
  }

  selectAsset(asset) {
    this.selectedAsset = asset;
  }

  getAssetClass(assetId) {
    if (!this.selectedAsset) {
      return '';
    }
    const isSelected = this.selectedAsset.Id === assetId;
    if (isSelected) {
      return 'selected';
    }
    if (!isSelected && this.selectAsset) {
      return 'unselected';
    }
  }

  closeModal(isCancel) {
    this.modalInstance.close(isCancel);
  }

  addExistingAssets() {
    const usedAsSecurity = true;
    this.AssetsSet = this.getNewAssetModel(usedAsSecurity);
    getOptionsOnInit({
      $scope: this,
      contactModelService: this.contactModelService,
      optionsService: this.optionsService,
      configService: this.configService,
    });
    this.getOptionsList(true);
    const selectedFamilyId = dotProp.get(
      this.financialsSharedService,
      'selectedFamily.FamilyId',
    );
    const familyId = selectedFamilyId || this.familyId;
    this.getAssetClient(familyId).then(() => {
      this.openExistingAssetsModal();
    });
  }

  openExistingAssetsModal() {
    const ctrl = this;
    this.$uibModal.open({
      templateUrl:
        '/assets/views/loanApplication/clientAssess/modal/real_estate.add.html',
      controller: 'LoanappAssetModalCtrl',
      size: 'md',
      resolve: {
        modalType: () => 'new',
        isRealEstate: () => true,
        familyId: () => this.familyId,
        loanAppId: () => this.loanAppId,
        assetsSet: () => this.AssetsSet,
        optionsObj: () => getOptionsObj({ $scope: this }),
        addAssetCallback: () => {
          return function () {
            ctrl.closeModal();
          };
        },
        listLender: () => [],
        ownersList: () => ctrl.ownersList,
      },
      windowClass: 'asset-modal',
    });
  }

  onItemSelect(data) {
    this.ownersList = getOwnersListOnItemSelect({
      ownersList: this.ownersList,
    })(data);
  }

  addAsset(isModal, modalType) {
    const callBack = this.closeModal();
    addAsset({
      $scope: this,
      currentUserService: this.currentUserService,
      loanScenarioService: this.loanScenarioService,
      dashboardService: this.dashboardService,
      loanAppSharedData: this.loanAppSharedData,
    })(isModal, modalType, callBack);
  }

  addExistingAssetAsSecurity() {
    this.saving = true;
    this.loanScenarioService
      .AssetDetailsGet(this.familyId, this.loanAppId, this.selectedAsset.Id)
      .then(({ data }) => {
        const assetDetails = {
          ...data,
          FamilyId: this.familyId,
          LoanScenarioId: this.loanAppId,
          IsSecurity: true,
        };
        return this.loanScenarioService.AssetDetailsSet(assetDetails);
      })
      .then(() => {
        this.closeModal();
      });
  }
}

export default ExistingAssetListCtrl;
