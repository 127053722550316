import template from './groupOption.html';
import GroupOptionCtrl from './groupOptionCtrl';

export default {
  template,
  bindings: {
    primaryProperty: '@',
    tickProperty: '@',
    onToggleItem: '&',
    itemLabels: '<',
    list: '=',
    disableEdit: '<',
    onOptionTrigger: '&',
  },
  controller: GroupOptionCtrl,
  controllerAs: 'vm',
};
