import template from './leadImageModal.html';
import LeadImageModalCtrl from './leadImageModalCtrl';
import './style.scss';

export default {
  template,
  controller: LeadImageModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    imageSrc: '<',
    leadIcon: '<',
    leadName: '<',
  },
};
