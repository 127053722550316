import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'PipelineAppDemoVideoModalCtrl',
      function PipelineAppDemoVideoModalCtrl(
        $scope,
        $uibModalInstance,
        DEMO_VIDEO,
      ) {
        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.videoId = DEMO_VIDEO.APPLICATION_PIPELINE.Url;
      },
    );
