import { toastError } from 'Common/utilities/alert';

export default class TradeReferenceModalCtrl {
  constructor(
    broadcastDataService,
    currentUserService,
    loanScenarioModelService,
  ) {
    'ngInject';

    this.broadcastDataService = broadcastDataService;
    this.currentUserService = currentUserService;
    this.loanScenarioModelService = loanScenarioModelService;
  }

  $onInit() {
    this.isSaving = false;
    this.phoneMask = this.broadcastDataService.getPhoneFormat(
      this.currentUserService.countryId,
    );
    this.phoneMask = this.phoneMask.split(' ').join('-');
  }

  saveReference(isValid) {
    if (this.isSaving) {
      return;
    }
    if (!isValid) {
      toastError('Please fill all required fields');
      return;
    }

    const { tradeReferenceId, loanAppId } = this.referenceDetails;
    const serviceFunction = tradeReferenceId
      ? 'updateTradeReference'
      : 'setTradeReference';

    this.isSaving = true;
    this.loanScenarioModelService[serviceFunction](
      loanAppId,
      this.referenceDetails,
    ).then((data) => {
      this.isSaving = false;
      if (data) {
        this.modalInstance.close();
        return;
      }
      toastError();
    });
  }
}
