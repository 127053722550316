import template from './assetFinanceDetails.html';
import controller from './assetFinanceDetailsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    familyId: '<',
  },
};
