import template from './onboardingRelatedDocument.html';
import OnboardingRelatedDocumentCtrl from './onboardingRelatedDocumentCtrl';

export default {
  template,
  bindings: {
    familyId: '<',
    isSummary: '<',
  },
  controller: OnboardingRelatedDocumentCtrl,
  controllerAs: 'vm',
};
