import template from './submitEnhancements.html';
import controller from './submitEnhancementsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    baseState: '@',
  },
};
