import moment from 'moment';
import { SEND_TYPE } from 'Common/constants/customerCareModules';
import { D_MMM_FORMAT } from 'Common/constants/dateConfigs';
import { sendInsuranceEmailBuilderForUI } from 'Common/mappers/customerCare';

export const checkSingleOrBulkEmailUtil = ({
  $scope,
  currentUserService,
  source,
  customerCareObj,
  tab,
  currentModuleType,
  listTableData,
}) => () => {
  $scope.isComplete = !currentUserService.isNZ;
  $scope.email = {
    Body: {},
  };
  $scope.isSending = false;
  $scope.all = source === SEND_TYPE.ALL;

  if (source === SEND_TYPE.SINGLE) {
    const expDate = moment(customerCareObj[tab.dateName]).format(D_MMM_FORMAT);
    const notiHeader = `${tab.label} (${expDate}) Completed via Email`;
    $scope.recipients.email = [
      sendInsuranceEmailBuilderForUI(customerCareObj, notiHeader),
    ];

    $scope.recipients.CustomerCareType = currentModuleType;
  }
  $scope.listTableData = listTableData;

  if (source === SEND_TYPE.ALL) {
    $scope.selectAllEmailList = [];

    $scope.listTableData.forEach((item) => {
      const contact = sendInsuranceEmailBuilderForUI(item, '');
      $scope.selectAllEmailList.push(contact);
    });
    if ($scope.selectAllEmailList.length > 0) {
      $scope.recipients.email = $scope.selectAllEmailList;
      $scope.noOfPeople = $scope.selectAllEmailList.length;
    }
  }
};
