import angular from 'angular';
import moment from 'moment';
import { getDatePickerConfig, formatDate } from 'Common/utilities/date';
import {
  DD_MM_YYYY_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';
import { toastSuccess, toastError } from 'Common/utilities/alert';

class DobInputInlineSaveCtrl {
  constructor($state, contactInsuranceService) {
    'ngInject';

    this.$state = $state;
    this.contactInsuranceService = contactInsuranceService;
    this.angular = angular;
  }

  $onInit() {
    if (!this.config) {
      this.config = getDatePickerConfig();
      this.formatDate = DD_MM_YYYY_FORMAT;
      this.toggleToolTip = false;
      const clientDOB = new Date((this.personData || {}).DateOfBirth);
      this.clientDOB = Date.parse(clientDOB) ? clientDOB : '';
    }
  }

  toggleCalendar() {
    this.isCalendarOpen = !this.isCalendarOpen;
    this.toggleToolTip = !this.isCalendarOpen;
  }

  onChange() {
    if (this.clientDOB) {
      this.isCalendarOpen = false;
      this.toggleToolTip =
        !this.toggleToolTip && !!this.personData.FamilyClientID;
      this.autoPopUpFocus();
      const age = moment().diff(this.clientDOB, 'years');
      this.updateClientAge(age);
    }
  }

  cancel() {
    this.toggleToolTip = !this.toggleToolTip;
  }

  updateClientAge(age) {
    const updatedPersonData = {
      ...this.personData,
      Age: age,
      DOB: formatDate(TO_MYCRM_DATE_FORMAT)(this.clientDOB),
    };
    this.onUpdated({ updatedPersonData });
  }

  save() {
    const params = {
      familyId: this.personData.FamilyClientID,
      dob: formatDate(TO_MYCRM_DATE_FORMAT)(this.clientDOB),
    };

    this.contactInsuranceService
      .insuranceQuoteAndProfilerDOBSet(this.baseModule, params)
      .then(({ succeeded, data, messages }) => {
        this.cancel();
        if (succeeded) {
          toastSuccess('Date of birth updated successfully!');
          this.updateClientAge(data);
          return;
        }
        toastError(messages[0]);
      });
  }

  autoPopUpFocus() {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const adjustFocusScale = (divSumIndex) => parseInt(90 * divSumIndex, 10);
    const divSumIndex = this.index + 1;
    let scrollFocusScale = adjustFocusScale(divSumIndex);
    if (divSumIndex > 1) {
      scrollFocusScale = adjustFocusScale(divSumIndex * 2);
    }
    this.angular
      .element('.insurance-inner-sidebar')
      .animate({ scrollTop: scrollFocusScale }, 'slow');
  }
}

export default DobInputInlineSaveCtrl;
