import template from './questionTextArea.html';
import './style.scss';
import controller from './questionTextAreaCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isRequired: '<',
    question: '<',
    answerString: '=',
    placeholder: '<',
    onChangeFn: '&',
  },
};
