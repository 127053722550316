import angular from 'angular';
import insuranceApplicationsPipeline from './insuranceApplicationsPipeline';
import insurancePipelineKanbanBoard from './insurancePipelineKanbanBoard';
import insurancePipelineCard from './insurancePipelineCard';
import insurancePipelineCardMenu from './insurancePipelineCardMenu';
import insurancePipelineLabelsMenu from './insurancePipelineLabelsMenu';
import insurancePipelineTable from './insurancePipelineTable';
import insurancePipelineFilters from './insurancePipelineFilters';
import insurancePipelineColumnSorter from './insurancePipelineColumnSorter';
import annualReviewMonthModal from './annualReviewMonthModal';
import benefitItem from './benefitItem';
import commencementDateModal from './commencementDateModal';
import './style.scss';

export default angular
  .module('components.insuranceApplicationsPipeline', [])
  .component('insuranceApplicationsPipeline', insuranceApplicationsPipeline)
  .component('insurancePipelineKanbanBoard', insurancePipelineKanbanBoard)
  .component('insurancePipelineCard', insurancePipelineCard)
  .component('insurancePipelineCardMenu', insurancePipelineCardMenu)
  .component('insurancePipelineLabelsMenu', insurancePipelineLabelsMenu)
  .component('insurancePipelineTable', insurancePipelineTable)
  .component('insurancePipelineFilters', insurancePipelineFilters)
  .component('insurancePipelineColumnSorter', insurancePipelineColumnSorter)
  .component('annualReviewMonthModal', annualReviewMonthModal)
  .component('benefitItem', benefitItem)
  .component('commencementDateModal', commencementDateModal).name;
