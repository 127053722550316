import swal from 'sweetalert';
import * as Sentry from '@sentry/browser';
import { isEmpty } from 'Common/utilities/objectValidation';
import { responseBuilderForUI } from 'Common/mappers/response';
import {
  validCustomAPIResponse,
  showCurrentError,
} from 'Common/utilities/response';
import { buildQuery } from 'Common/utilities/query';

import { constructConfigRequest } from 'Common/utilities/request';
import { getAccessToken } from 'Common/utilities/oktaAuth';
import get from 'lodash/get';

class HttpClient {
  constructor(
    $http,
    configService,
    $window,
    $timeout,
    $q,
    $location,
    $cacheFactory,
    httpService,
  ) {
    'ngInject';

    this.$http = $http;
    this.configService = configService;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$q = $q;
    this.$location = $location;
    this.$cacheFactory = $cacheFactory;
    this.httpService = httpService;

    this.cache =
      this.$cacheFactory.get('httpRequest') ||
      this.$cacheFactory('httpRequest');

    this.handleError = this.handleError.bind(this);

    httpService.setHttpCommonHeader();
  }

  errorCallback(e) {
    const errorStatus = [404, 400, 500];
    const message =
      e.message || get(e, 'data.Message') || 'Please contact support.';
    if (validCustomAPIResponse(e)) {
      return showCurrentError(e);
    }
    if (errorStatus.includes(e.status)) {
      swal('Yikes! Something is wrong', message, 'error');
    }
  }

  handleError(url, useDefaultErrorCallback = true) {
    return (e) => {
      const response = responseBuilderForUI(e);
      if (!useDefaultErrorCallback) {
        return response;
      }
      if (e.status === 504) {
        Sentry.captureException(
          new Error(
            // eslint-disable-next-line angular/document-service
            `504 Status code on API Endpoint ${url} on page ${document.URL}`,
          ),
        );
      }
      this.errorCallback(response);
      return response;
    };
  }

  getResourceUrl(url) {
    let urlValue = url;
    const splitUrl = url.split('?');

    if (
      typeof this.configService.exclude !== 'undefined' &&
      this.configService.exclude.includes(splitUrl[0])
    ) {
      urlValue = `${this.$location.protocol()}//${this.$location.host()}/json/${url.replace(
        /\?/,
        '.json?',
      )}`;
    } else {
      urlValue = `${this.configService.resource}/${url}`;
    }

    return urlValue;
  }

  fireRequest(request, isAuthorizationOnly = false) {
    return this.$q.when(getAccessToken()).then((token) => {
      this.httpService.setHttpCommonHeader(token, isAuthorizationOnly);
      return request();
    });
  }

  removeCache(removeCache, url) {
    if (removeCache) {
      const timeoutResponse = this.$timeout(() => {
        this.cache.remove(url);
        this.$timeout.cancel(timeoutResponse);
      }, 5000);
    }
  }

  get(
    url,
    params = {},
    cache = false,
    removeCache = true,
    useDefaultErrorCallback = true,
    isCustomResourceUrl = false,
    isAuthorizationOnly = false,
  ) {
    const apiUrl = isCustomResourceUrl ? url : this.getResourceUrl(url);
    const searchParams = !isEmpty(params) ? buildQuery(params) : '';
    const cacheKey = searchParams ? `${url}${searchParams}` : url;

    const request = () => {
      return this.$http
        .get(apiUrl, constructConfigRequest({ params }))
        .then((response) => responseBuilderForUI(response))
        .catch(this.handleError(url, useDefaultErrorCallback));
    };

    if (cache) {
      this.removeCache(removeCache, cacheKey);
      let cachePromise = this.cache.get(cacheKey);
      if (cachePromise) {
        cachePromise = this.cache.get(cacheKey).then((response) => {
          const validArrayResponse =
            response && response.data && Array.isArray(response.data);
          if (validArrayResponse) {
            return { data: [...response.data] };
          }
          return response;
        });
      }
      return (
        cachePromise ||
        this.cache.put(cacheKey, this.fireRequest(request, isAuthorizationOnly))
      );
    }

    return this.fireRequest(request, isAuthorizationOnly);
  }

  post(
    url,
    data = {},
    params = {},
    useDefaultErrorCallback = true,
    isCustomResourceUrl = false,
    isAuthorizationOnly = false,
  ) {
    const apiUrl = isCustomResourceUrl ? url : this.getResourceUrl(url);

    const request = () =>
      this.$http
        .post(apiUrl, data, constructConfigRequest({ params }))
        .then((response) => responseBuilderForUI(response))
        .catch(this.handleError(url, useDefaultErrorCallback));

    return this.fireRequest(request, isAuthorizationOnly);
  }

  put(url, data = {}, params = {}, useDefaultErrorCallback = true) {
    const apiUrl = this.getResourceUrl(url);

    const request = () =>
      this.$http
        .put(apiUrl, data, constructConfigRequest({ params }))
        .then((response) => responseBuilderForUI(response))
        .catch(this.handleError(url, useDefaultErrorCallback));

    return this.fireRequest(request);
  }

  patch(url, data = {}, params = {}) {
    const apiUrl = this.getResourceUrl(url);

    const request = () =>
      this.$http
        .patch(apiUrl, data, constructConfigRequest({ params }))
        .then((response) => responseBuilderForUI(response))
        .catch(this.handleError(url));

    return this.fireRequest(request);
  }

  delete(url, params = {}) {
    const apiUrl = this.getResourceUrl(url);

    const request = () =>
      this.$http
        .delete(apiUrl, constructConfigRequest({ params }))
        .then((response) => responseBuilderForUI(response))
        .catch(this.handleError(url));

    return this.fireRequest(request);
  }
}

export default HttpClient;
