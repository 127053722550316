import template from './mycrmPopover.html';

export default {
  template,
  transclude: true,
  bindings: {
    callerData: '<',
    isOpen: '<',
    popoverMessageExpression: '<',
    themeClass: '@',
    placement: '@',
    triggerButtonComponent: '@',
    popoverMessage: '@',
    popoverConfirmMessage: '@',
    popoverConfirmTheme: '@',
    popoverCancelMessage: '@',
    onOpen: '&',
    onConfirm: '&',
    onDismiss: '&',
  },
  controllerAs: 'vm',
};
