import template from './workbenchDetails.html';
import workbenchDetailsCtrl from './workbenchDetailsCtrl';

export default {
  template,
  bindings: {
    loanId: '<',
    loanStatusId: '=',
    orgPipelineStatusId: '=',
    loanPipelineCardsId: '<',
    loanScenarioId: '<',
    familyList: '<',
    selectedParty: '=',
    onSelectParty: '&',
    clientDetails: '<',
    homePhone: '=',
    mobilePhone: '=',
    onNwdUpdate: '&',
    enquirySource: '<',
    changeTaskDueCount: '<',
  },
  controller: workbenchDetailsCtrl,
  controllerAs: 'vm',
};
