import { isFunction, forOwn, get } from 'lodash';

import { performance } from 'Common/utilities/perfume';
import { loanAppSectionData } from 'Common/utilities/dataLayer';
import { LOAN_APP_INSURANCE_REFERRALS } from 'Common/constants/performance';

import { INSURANCE_REFERRALS_QUESTIONS as QUESTIONS } from 'Common/constants/questions';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import {
  getSpecificBrokerNotes,
  noteListInitUtil,
} from 'Common/utilities/loanApplication';
import { toastError } from 'Common/utilities/alert';
import { arrayToObject } from 'Common/utilities/array';

const SECTION_QUESTION = 'Insurance & Referrals';

export default class InsuranceReferralsSectionCtrl {
  constructor(
    loanAppSharedData,
    optionsService,
    loanScenarioService,
    loanScenarioModelService,
    questionOptionService,
    uiService,
  ) {
    'ngInject';

    this.loanAppSharedData = loanAppSharedData;
    this.optionsService = optionsService;
    this.loanScenarioService = loanScenarioService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.questionOptionService = questionOptionService;
    this.uiService = uiService;
  }

  $onInit() {
    this.SECTION_QUESTION = SECTION_QUESTION;
    this.QUESTIONS = QUESTIONS;
    this.sectionBeenClicked = false;
    this.questionsObj = {};
    this.isCompleteConsiderFields = false;
  }

  initSection() {
    performance.start(LOAN_APP_INSURANCE_REFERRALS);

    if (this.sectionBeenClicked) {
      return;
    }
    this.sectionBeenClicked = true;

    this.getBrokerNotes();
    this.getQuestions({ callback: () => this.getAnswers() });

    const duration = performance.endPaint(LOAN_APP_INSURANCE_REFERRALS);
    loanAppSectionData({
      label: LOAN_APP_INSURANCE_REFERRALS,
      value: duration,
    });
  }

  getBrokerNotes() {
    const assignBrokerNotes = (brokerNotesResponse) => {
      this.brokerNotes = getSpecificBrokerNotes(
        brokerNotesResponse,
        this.brokerNotesId,
      );
    };

    noteListInitUtil({
      scope: this,
      loanScenarioModelService: this.loanScenarioModelService,
      assignBrokerNotes,
    });
  }

  getQuestions({ callback }) {
    this.questionsObj = {};
    this.isLoadingQuestions = true;

    this.optionsService
      .question(SECTION_QUESTION)
      .then(({ data }) => {
        if (!data) {
          return;
        }

        this.questionsObj = arrayToObject(data, 'QuestionId');

        isFunction(callback) && callback();
      })
      .finally(() => {
        this.isLoadingQuestions = false;
      });
  }

  getAnswers() {
    this.loanScenarioService
      .loanInsuranceGet(this.loanAppId)
      .then(({ data }) => {
        const answersObj = arrayToObject(data, 'QuestionId');

        forOwn(this.questionsObj, (val, key) => {
          this.questionsObj[key] = {
            ...this.questionsObj[key],
            answerRaw: answersObj[val.QuestionId] || {},
            answer: this.getAnswerValue(val, answersObj[val.QuestionId]),
          };

          this.updateStarted(this.questionsObj[key].answer !== null);
        });

        this.updateCompleteStatus();
      });
  }

  getAnswerValue(question, answer) {
    if (!question || !answer) {
      return null;
    }

    const answerType = this.questionOptionService.getAnswerType(
      question.QuestionId,
      [question],
    );

    return get(answer, `[${answerType}][0]`, null);
  }

  setAnswer(question, answer) {
    if (answer === null) {
      return;
    }
    question.answer = answer;
    const { QuestionId, QuestionDisplayTypeId: DisplayOptionId } = question;
    const answerType = this.questionOptionService.getAnswerType(QuestionId, [
      question,
    ]);

    const params = {
      LoanScenarioId: this.loanAppId,
      FamilyId: this.familyId,
      QuestionId,
      DisplayOptionId,
      [answerType]: [answer],
    };

    this.loanScenarioService.loanInsuranceSet(params).then((response) => {
      if (response && response.data) {
        this.updateStarted(true);
        this.updateCompleteStatus();
      } else {
        toastError();
        this.getAnswers();
      }
    });
  }

  updateStarted(isStarted) {
    this.isStarted = isStarted || this.isStarted;
  }

  getAnswerByKey(key) {
    return get(this.questionsObj, `[${key}].answer`, null);
  }

  getConsiderFieldsStatus() {
    const lifeHealthAnswer = this.getAnswerByKey(QUESTIONS.LIFE_HEALTH);
    const genInsuranceAnswer = this.getAnswerByKey(QUESTIONS.GENERAL_INSURANCE);

    const validationLogic = [
      (lifeHealthAnswer === true &&
        !!this.getAnswerByKey(QUESTIONS.LH_DETAILS)) ||
        (lifeHealthAnswer === false &&
          !!this.getAnswerByKey(QUESTIONS.LH_DECLINE)),
      (genInsuranceAnswer === true &&
        !!this.getAnswerByKey(QUESTIONS.GI_DETAILS)) ||
        (genInsuranceAnswer === false &&
          !!this.getAnswerByKey(QUESTIONS.GI_DECLINE)),
    ];

    const hasInvalidField = validationLogic.includes(false);
    return !hasInvalidField;
  }

  updateCompleteStatus() {
    this.isCompleteConsiderFields = this.getConsiderFieldsStatus();
    const updatedCompletionStatus = this.isCompleteConsiderFields;

    if (this.isCompleted === updatedCompletionStatus) {
      return;
    }

    this.isCompleted = updatedCompletionStatus;

    const currentIsComplete = this.loanAppSharedData.loanCompletionObject[
      SECTION_ID.INSURANCE
    ].IsCompleted;

    this.onChangeStatus({ updatedCompletionStatus });

    this.loanAppSharedData.completionStatusSet(
      SECTION_ID.INSURANCE,
      currentIsComplete,
      updatedCompletionStatus,
    );
  }

  isRequiredField(questionId) {
    const requiredFieldIds = [
      this.QUESTIONS.LIFE_HEALTH,
      this.QUESTIONS.GENERAL_INSURANCE,
    ];

    return requiredFieldIds.includes(questionId);
  }

  isRequiredAnswersProvided(questionId) {
    if (!this.questionsObj[questionId]) {
      return !this.isRequiredField(questionId);
    }

    switch (questionId) {
      case this.QUESTIONS.LIFE_HEALTH:
        if (this.questionsObj[this.QUESTIONS.LIFE_HEALTH].answer === true) {
          return !!this.questionsObj[this.QUESTIONS.LH_DETAILS].answer;
        } else if (
          this.questionsObj[this.QUESTIONS.LIFE_HEALTH].answer === false
        ) {
          return !!this.questionsObj[this.QUESTIONS.LH_DECLINE].answer;
        } else {
          return false;
        }
      case this.QUESTIONS.GENERAL_INSURANCE:
        if (
          this.questionsObj[this.QUESTIONS.GENERAL_INSURANCE].answer === true
        ) {
          return !!this.questionsObj[this.QUESTIONS.GI_DETAILS].answer;
        } else if (
          this.questionsObj[this.QUESTIONS.GENERAL_INSURANCE].answer === false
        ) {
          return !!this.questionsObj[this.QUESTIONS.GI_DECLINE].answer;
        } else {
          return false;
        }
      default:
        return true;
    }
  }
}
