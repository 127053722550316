import angular from 'angular';
import linkedTermDate from './linkedTermDate';
import linkedTermTable from './linkedTermTable';
import termTableView from './termTableView';
import loanSplitForm from './loanSplitForm';

export default angular
  .module('loanInformationFields.components', [])
  .component('linkedTermDate', linkedTermDate)
  .component('linkedTermTable', linkedTermTable)
  .component('termTableView', termTableView)
  .component('loanSplitForm', loanSplitForm).name;
