import { isSubstatus } from 'Common/utilities/leadCardUtility';

class LeadOpportunityCardCtrl {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.isSubStatusExist = isSubstatus({
      timelineStatusList: this.timelineStatusList,
      card: this.card,
    });
    this.isShowSubstatus =
      (this.card.probability || this.isSubStatusExist) && this.isPipeline;
  }
}

export default LeadOpportunityCardCtrl;
