import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('phoneValidation', function phoneValidation() {
      return {
        require: 'ngModel',
        link(scope, element, attr, ngModelCtrl) {
          function fromUser(text) {
            if (text) {
              let transformedInput = text.replace(/[^\d+]/g, '');
              if (transformedInput.length !== 1) {
                const lastLetter = transformedInput.charAt(
                  transformedInput.length - 1,
                );
                const lastIndex = transformedInput.lastIndexOf('+');

                if (lastLetter === '+') {
                  transformedInput = transformedInput.slice(lastLetter, -1);
                } else if (lastIndex !== 0 && lastIndex >= 1) {
                  const arr = transformedInput.split('');
                  arr.splice(lastIndex, 1, '');
                  transformedInput = arr.join('');
                }
              }
              if (transformedInput !== text) {
                ngModelCtrl.$setViewValue(transformedInput);
                ngModelCtrl.$render();
              }
              return transformedInput;
            }
            return '';
          }
          ngModelCtrl.$parsers.push(fromUser);
        },
      };
    });
