import angular from 'angular';
import helloBookLoadingModal from './helloBookLoadingModal';
import helloBookPreviewGenerator from './helloBookPreviewGenerator';
import openServiceOptionModal from './openServiceOptionModal';

export default angular.module('helloBook.scenes', [
  helloBookLoadingModal,
  helloBookPreviewGenerator,
  openServiceOptionModal,
]).name;
