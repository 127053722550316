import { COLOR, RGBA } from 'Common/constants/colors';

export const DATA_RED = {
  backgroundColor: RGBA.ALTO,
  borderColor: COLOR.ROSE_BUD,
  pointBackgroundColor: COLOR.WHITE,
  pointBorderColor: COLOR.BITTER_SWEET,
  pointHoverBackgroundColor: COLOR.BITTER_SWEET,
  pointHoverBorderColor: COLOR.BITTER_SWEET,
};

export const DATA_BLUE = {
  backgroundColor: RGBA.ROCK_BLUE,
  borderColor: COLOR.PIGEON_POST,
  pointBackgroundColor: COLOR.WHITE,
  pointBorderColor: COLOR.DANUBE,
  pointHoverBackgroundColor: COLOR.DANUBE,
  pointHoverBorderColor: COLOR.DANUBE,
};

export const DATA_BLUE_BELL = {
  backgroundColor: RGBA.ALTO,
  borderColor: COLOR.BLUE_BELL,
  pointBackgroundColor: COLOR.WHITE,
  pointBorderColor: COLOR.BLUE_BELL,
  pointHoverBackgroundColor: COLOR.WHITE,
  pointHoverBorderColor: COLOR.BLUE_BELL,
};

export const DATA_DOWNY = {
  backgroundColor: RGBA.ROCK_BLUE,
  borderColor: COLOR.DOWNY,
  pointBackgroundColor: COLOR.WHITE,
  pointBorderColor: COLOR.DOWNY,
  pointHoverBackgroundColor: COLOR.WHITE,
  pointHoverBorderColor: COLOR.DOWNY,
};
