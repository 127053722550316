import get from 'lodash/get';
import { DEFAULT_CUSTOMER_CARE_DATE_RANGE } from 'Common/constants/customerCareModules';

class MycrmSelectDateRangeCtrl {
  $onInit() {
    this.dateActiveRangeIndex =
      this.defaultIndex == null
        ? DEFAULT_CUSTOMER_CARE_DATE_RANGE
        : this.defaultIndex;
    this.defaultDate = get(
      this,
      `dateRangeOptions[${this.dateActiveRangeIndex}]`,
      {},
    );
    this.selectedItemLabel = this.defaultDate.text || '';
    this.previousRangeIndex = this.dateActiveRangeIndex;
    this.customDateRangeIndex = this.dateRangeOptions.length - 1;
  }

  onDateRangeSelected(activeIndex) {
    this.dateActiveRangeIndex =
      activeIndex == null ? this.defaultIndex : activeIndex;
    this.selectedItemLabel = this.dateRangeOptions[
      this.dateActiveRangeIndex
    ].text;
  }

  onCalendarUpdated(filterData) {
    if (!filterData) {
      return;
    }
    const dateFrom = filterData.DateCreatedStart || this.defaultDate.from;
    const dateTo = filterData.DateCreatedEnd || this.defaultDate.to;
    const rangeValue = get(
      this,
      `dateRangeOptions[${filterData.selectedIndex}]`,
      {},
    ).value;
    this.isDateFilterOpen = false;
    this.previousRangeIndex =
      filterData.selectedIndex || this.dateActiveRangeIndex;
    this.onChange({ dateFrom, dateTo, rangeValue });
  }

  onCancel() {
    this.isDateFilterOpen = false;
    this.onDateRangeSelected(this.previousRangeIndex);
  }
}
export default MycrmSelectDateRangeCtrl;
