import {
  DEFAULT_DOCUMENT_EXTENSIONS,
  INVALID_FILE_INFO,
} from 'Common/constants/defaultDocumentExtensions';
import { noop, omit } from 'lodash';
import { displayError } from 'Common/utilities/alert';
import { COLOR } from 'Common/constants/colors';
import toastr from 'toastr';
import swal from 'sweetalert';
import { DOCUMENT_BROKEN_ERROR_MSG } from 'Common/constants/document';
import * as utilESignDocument from 'Common/utilities/eSignDocument';

export default class OpportunityDocumentsCtrl {
  constructor(
    $uibModal,
    loanApplicationServices,
    loanAppSharedData,
    generalService,
    downloadDataService,
    currentUserService,
    eSignService,
    uiService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.loanApplicationServices = loanApplicationServices;
    this.loanAppSharedData = loanAppSharedData;
    this.generalService = generalService;
    this.downloadDataService = downloadDataService;
    this.currentUserService = currentUserService;
    this.eSignService = eSignService;
    this.uiService = uiService;

    this.processDoc = this.processDoc.bind(this);

    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.extensions = DEFAULT_DOCUMENT_EXTENSIONS;
    this.invalidFileInfo = INVALID_FILE_INFO;
    this.utilESignDocument = utilESignDocument;
    this.docBrokenErrorMsg = DOCUMENT_BROKEN_ERROR_MSG;
    this.getDocumentList();

    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
  }

  processParentNotification(eventHandler) {
    if (eventHandler === 'refreshDocuments') {
      this.getDocumentList();
    }
  }

  getDocumentList() {
    this.isLoadingEmails = true;
    Promise.all([
      this.loanApplicationServices.getDocumentList(this.opportunityId),
      this.eSignService.getPackages(this.opportunityId),
    ]).then((response) => {
      if (!response || !response.length) {
        this.isLoadingEmails = false;
        return;
      }

      const normalDocuments = this.loanAppSharedData.documents
        .fillStatusName(response[0].data)
        .map((doc) => {
          doc.addNotes = doc.Notes && doc.Notes.length;
          return doc;
        });

      const signedDocuments = response[1];

      this.documentsList = [...normalDocuments, ...signedDocuments];
      this.isLoadingEmails = false;
    });
  }

  uploadViewSuccess(files) {
    files.forEach(this.processDoc);
  }

  processDoc(file) {
    this.loanApplicationServices
      .getDocumentPageCount(file.DocumentId)
      .then((respond) => {
        this.setDocument(
          {
            LoanScenarioId: this.opportunityId,
            DocumentPage: 1,
            IsAdded: true,
            Notes: '',
            UploadDate: new Date(),
            UploadedByFamilyID: this.currentUserService.familyId,
            DocumentId: file.DocumentId,
            Name: file.Name,
            Size: 0,
            NumberPages: respond.data,
            DateUploaded: new Date(),
            Createddate: new Date(),
            CreatedMonth: new Date(),
          },
          false,
          'Document has been been successfully uploaded.',
          false,
          noop,
        );
      });
  }

  setDocument(obj, dontRefresh, successText, noModal, callback) {
    const doc = { ...obj };
    doc.LoanScenarioId = this.opportunityId;

    // remove unnecessary attributes
    omit(doc, ['pages', 'showPages', 'pagesLoaded', 'addNotes', 'StatusName']);

    this.loanApplicationServices
      .setDocument(doc)
      .then(() => {
        if (!noModal) {
          toastr.success('Sucess', successText);
        }
        if (!dontRefresh) {
          this.getDocumentList();
        }
        callback();
      })
      .catch(displayError);
  }

  downloadDocument(documentId) {
    this.generalService.documentGet(documentId).then(({ data }) => {
      if (!data) {
        return;
      }

      this.downloadDataService.download(
        `data:${data.ContentType};base64,${data.DocumentContent}`,
        data.Name,
        data.ContentType,
      );
    });
  }

  editDocument(obj) {
    const doc = {
      ...obj,
      Description: obj.DocumentDescription,
      DocumentID: obj.DocumentId || obj.DocumentID,
      Title: obj.Name || obj.Title,
      Tags: (obj.Tags && obj.Tags.join(',')) || '',
      FamilyID: 0,
    };

    this.openDocumentModal(doc, true);
  }

  openDocumentModal(obj, isUpdate) {
    const doc = { ...obj };
    const userInfo = {
      ...this.currentUserService,
      CorporateTeamId: this.currentUserService.corporateTeamId,
      CorporateTeam: this.currentUserService.corporateTeam,
    };

    this.$uibModal
      .open({
        templateUrl: '/assets/views/contacts/partials/add_document_modal.html',
        controller: 'DocumentModalCtrl',
        // scope: $scope,
        resolve: {
          documentDataObject: () => doc,
          isUpdate: () => isUpdate,
          userInfo: () => userInfo,
          isClientDocuments: () => true,
          currentPage: () => 0,
        },
      })
      .result.then(noop, ({ isRefresh }) => {
        isRefresh && this.getDocumentList();
      });
  }

  deleteDocument(documentId, loanScenarioDocumentId) {
    swal(
      {
        title: 'Please confirm action',
        text:
          'Are you sure you want to delete this document? This action cannot be undone',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: COLOR.GERALDINE,
        confirmButtonText: 'Delete Document',
        closeOnConfirm: false,
      },
      (isConfirm) => {
        if (!isConfirm) {
          return;
        }

        this.loanApplicationServices
          .deleteDocument(
            this.opportunityId,
            documentId,
            loanScenarioDocumentId,
          )
          .then(() => {
            this.getDocumentList();
            swal('Deleted', 'File has been deleted.', 'success');
          })
          .catch(displayError);
      },
    );
  }
}
