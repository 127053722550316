import './style.scss';
import template from './taskTemplateItem.html';
import controller from './taskTemplateItemCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    template: '<',
    advisers: '<',
    onSaveTemplate: '&',
    onCheckSelection: '&',
    onDeleteTemplate: '&',
  },
};
