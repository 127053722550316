import template from './customerCareTabFilter.html';
import controller from './customerCareTabFilterCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    dateFilter: '<',
    assignedFilter: '<',
    checkedTableData: '<',
    defaultDateRangeIndex: '<',
    onAssignedSelect: '&',
    onDateSelect: '&',
    onShowCompletedChange: '&',
    onSendToAll: '&',
    onSendToSelected: '&',
    onDateRangeChange: '&',
    showExportUi: '<?',
    requiredParams: '<?',
    selectedInterestExpiriesIds: '<?',
    exportService: '<?',
    tableLength: '<?',
  },
};
