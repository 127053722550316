import angular from 'angular';
import { isFunction } from 'lodash';
import {
  INSURANCE_OPTIONS,
  EMPLOYMENT_STATUS,
} from 'Common/constants/insuranceOptions';
import { sortPeopleEntities } from 'Common/utilities/insurance';

class selectContactTypeCtrl {
  constructor(
    $state,
    $timeout,
    $stateParams,
    contactService,
    currentUserService,
    insuranceProfilerService,
    insuranceSharedData,
    contactModelService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.$stateParams = $stateParams;
    this.insuranceProfilerService = insuranceProfilerService;
    this.insuranceSharedData = insuranceSharedData;
    this.contactService = contactService;
    this.currentUserService = currentUserService;
    this.contactModelService = contactModelService;
    this.CLIENT_TYPE_OPTIONS = INSURANCE_OPTIONS.CLIENT_TYPE;
    this.clientType = this.CLIENT_TYPE_OPTIONS.NEW;
    this.familyList = [];
    this.showResults = false;
    this.processTimeOut = null;
    this.showClientSelect = true;
  }

  $onDestroy() {
    if (this.processTimeOut) {
      this.$timeout.cancel(this.processTimeOut);
    }
  }

  clientTypeChange(typeOfClient) {
    this.clientType = typeOfClient;
    if (typeOfClient === this.CLIENT_TYPE_OPTIONS.NEW && this.reloadPage()) {
      this.$state.go('app.quickQuote');
    } else {
      this.formData = [];
      this.insurersList = [];
      this.processTimeOut = this.$timeout(() => {
        this.formData = this.insuranceSharedData.getNewQuoteEntityInput(
          'Quick Quote',
          'quote',
        );
        this.formData.isNewClient =
          this.clientType !== this.CLIENT_TYPE_OPTIONS.EXISTING;
        if (isFunction(this.onClientTypeChange)) {
          this.onClientTypeChange();
        }
      });
    }
  }

  reloadPage() {
    const { name: currentState } = this.$state.current;
    const isFromProfiler = !!this.$state.params.profilerId;
    const hasExistingClient =
      this.$state.params.familyId && this.formData && this.formData.FamilyID;
    const isCurrentStateForReload =
      currentState === 'app.contactQuickQuote' ||
      currentState === 'app.quickQuoteDetails';
    return (hasExistingClient && isCurrentStateForReload) || isFromProfiler;
  }

  searchNow(qry) {
    if (qry && qry.length > 2) {
      const { familyId: adviserFamilyId } = this.currentUserService;
      const byPassFilter = true;
      this.isSearching = byPassFilter;
      this.contactModelService
        .searchFamily({
          searchCriteria: qry,
          adviserFamilyId,
          byPassFilter,
          searchOnAdviserOrg: true,
        })
        .then(({ data }) => {
          this.isSearching = false;
          this.showResults = data && data.FamilyList && data.FamilyList.length;
          if (this.showResults) {
            this.familyList = data.FamilyList;
          }
        });
    } else {
      this.showResults = false;
    }
  }

  peopleDataMapper(people) {
    if (people) {
      const benefits = this.insuranceSharedData.getBenefitsModel();
      angular.forEach(people, (member) => {
        member.Occupation = member.OccupationID;
        member.EmployedStatus = EMPLOYMENT_STATUS.EMPLOYED;
        member.AddedBenefitCounter = 0;
        member.OpenWidgetBenefits = 0;
        member.BenefitList = angular.copy(benefits);
        member.Gender = String(member.Gender);
      });
    }
  }

  selectResult(family) {
    this.showResults = false;
    this.contactName = family.FamilyFullName;
    const familyId = parseInt(family.FamilyID, 10);
    const formDataFamId = this.formData && parseInt(this.formData.FamilyID, 10);
    const isFamilyFormDataInitialized =
      formDataFamId && formDataFamId === familyId;
    if (isFamilyFormDataInitialized) {
      this.formData.FamilyID = familyId;
      this.formData.FamilyFullName = this.contactName;
    } else if (this.reloadPage()) {
      this.$state.go('app.contactQuickQuote', {
        familyId,
        isFromInsuranceTab: null,
      });
    } else {
      this.initializeFormData({ familyId });
    }
  }

  initializeFormData(family) {
    this.insuranceProfilerService
      .profilerInsuranceProfilerFamilyGet(family)
      .then((response) => {
        const data = response.data;
        if (data) {
          const {
            prefix: fromModule,
            FamilyFullName: familyFullName,
            moduleName,
          } = this.formData;
          this.formData = [];
          this.insurersList = [];
          this.$timeout(() => {
            this.formData = {
              isNewClient: false,
              prefix: fromModule,
              FamilyFullName: familyFullName,
              moduleName,
              ...data,
            };
            this.formData.PeopleEntity = sortPeopleEntities(
              this.formData.PeopleEntity,
            );
            this.peopleDataMapper(this.formData.PeopleEntity);
          }, 10);
        }
      });

    if (isFunction(this.onNewClientSelected)) {
      this.onNewClientSelected();
    }
  }

  closeResults() {
    this.showResults = false;
  }

  populateFamilyInfo(familyId) {
    if (familyId) {
      this.clientType = this.CLIENT_TYPE_OPTIONS.EXISTING;
      const familyInfo = {
        FamilyID: familyId,
      };
      this.selectResult(familyInfo);
    }
  }

  $onInit() {
    if (this.formData.isFromProfiler && this.formData.isEdits) {
      this.showClientSelect = false;
    }

    this.populateFamilyInfo(this.$stateParams.familyId);
  }
}

export default selectContactTypeCtrl;
