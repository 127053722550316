import { getTaxPrice, getExclGST } from 'Common/utilities/gstCalculator';
import { toastError, toastSuccess } from 'Common/utilities/alert';

export default class TaxInvoiceModalCtrl {
  constructor($uibModal, loanScenarioModelService, downloadDataService) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.loanScenarioModelService = loanScenarioModelService;
    this.downloadDataService = downloadDataService;
  }

  $onInit() {
    this.isGeneratingInvoice = false;
    this.isGettingInvoiceDetails = false;
    this.gstInc = 0;
    this.gstExcl = 0;
    this.getTaxInvoiceDetails();
  }

  getTaxInvoiceDetails() {
    if (!this.loanDetails || !this.loanDetails.LoanScenarioID) {
      return;
    }
    this.isGettingInvoiceDetails = true;
    this.loanScenarioModelService
      .getTaxInvoiceDetails(this.loanDetails.LoanScenarioID)
      .then((data) => {
        this.isGettingInvoiceDetails = false;
        if (!data) {
          return;
        }
        this.structureTaxDetails(data);
      });
  }

  structureTaxDetails(data) {
    this.taxInvoiceDetails = data;
    this.brokerage = data.brokerageFee;
    this.origination = data.documentFee;
    const { id: lenderId, email } = data.lender;
    this.lenderEmail = email;
    this.recipientDropdownList = lenderId
      ? [data.lender, ...data.recipients]
      : [...data.recipients];
    this.selectedRecipient = this.recipientDropdownList[0];
    this.calculateTotal();
  }

  calculateTotal() {
    const brokerage = parseFloat(this.brokerage);
    const origination = parseFloat(this.origination);
    const computeBrokerage =
      getTaxPrice(brokerage, this.includeGstBrokerage) || 0;
    const computeOrigination =
      getTaxPrice(origination, this.includeGstOrigination) || 0;
    const sum = computeBrokerage + computeOrigination;

    this.gstInc = getTaxPrice(sum, true);
    this.gstExcl = getExclGST(this.gstInc);
  }

  downloadInvoice() {
    if (this.isGeneratingInvoice || this.isDisabledGenerate()) {
      return;
    }
    this.isGeneratingInvoice = true;
    const params = this.getParamsForInvoice();
    this.loanScenarioModelService
      .getTaxInvoiceDetailsPdf(
        this.loanDetails.LoanScenarioID,
        this.selectedRecipient.id,
        params,
      )
      .then((data) => {
        this.isGeneratingInvoice = false;
        const { contentType, documentContent } = data;
        if (!documentContent) {
          toastError();
          return;
        }

        this.downloadDataService.download(
          `data:${contentType};base64,${documentContent}`,
          this.generateFileName(),
          contentType,
        );
        toastSuccess('Tax Invoice generated successfully!');
      });
  }

  getParamsForInvoice() {
    const forClient = !this.selectedRecipient.isLender;
    return {
      brokerageFee: this.brokerage,
      originationFee: this.origination,
      inclBrokerageGST: !!this.includeGstBrokerage,
      inclOriginationGST: !!this.includeGstOrigination,
      recipientId: this.selectedRecipient.id,
      forClient,
    };
  }

  generateFileName() {
    return `tax_invoice_${this.selectedRecipient.label}.pdf`;
  }

  openSendTaxInvoiceModal() {
    const props = {
      loanAppId: this.loanDetails.LoanScenarioID,
      lenderEmail: this.lenderEmail,
      attachmentLabel: this.generateFileName(),
      invoiceDetails: this.getParamsForInvoice(),
    };
    this.$uibModal.open({
      template: `<send-tax-invoice-modal
        loan-app-id="vm.props.loanAppId"
        lender-email="vm.props.lenderEmail"
        attachment-label="vm.props.attachmentLabel"
        invoice-details="vm.props.invoiceDetails"
        modal-instance="vm.modalInstance">
      </send-tax-invoice-modal>`,
      size: 'md',
      backdrop: 'static',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
  }

  isDisabledGenerate() {
    return (
      !this.selectedRecipient || !`${this.brokerage}` || !`${this.origination}`
    );
  }

  closeModal() {
    this.modalInstance.close();
  }
}
