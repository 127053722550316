import { get } from 'lodash';
import { LOAN_TERM, ACCESS_OPTION } from 'Common/constants/assets';
import {
  CONSTRUCTION_TRANSACTION_TYPES,
  TRANSACTION_TYPE,
} from 'Common/constants/securityValues';
import { toastError } from 'Common/utilities/alert';
import { formatCustomAddress } from 'Common/utilities/addressHistory';

export const populateCountryList = ({ $scope, optionsService }) => (
  countryListBuilderForUI,
) => {
  optionsService.countryListGet().then(({ data }) => {
    $scope.countryList = data.map(countryListBuilderForUI);
  });
};

export const openRepayment = ($scope) => (key, isOpen = true) => {
  $scope.dropdown[key] = isOpen;
};
export const selectRepayment = ($scope) => (obj, value, key) => {
  obj.RepaymentFrequencyId = value;
  $scope.openRepayment(key, false);
};
export const setSaveButtonFocus = ($scope) => (isFocus = true) => {
  $scope.saveButtonFocus = isFocus;
};

export const initializeModal = ($scope) => {
  $scope.contactForAccesOptions = [
    {
      id: ACCESS_OPTION.LOAN_PARTY,
      label: 'Loan Party',
    },
    {
      id: ACCESS_OPTION.OTHER,
      label: 'Other',
    },
  ];

  $scope.loanTermOptions = [
    {
      id: LOAN_TERM.PRINCIPAL_INTEREST,
      label: 'Principal & Interest',
    },
    {
      id: LOAN_TERM.INTEREST_ONLY,
      label: 'Interest Only',
    },
  ];
  $scope.dropdown = {
    repayment: false,
  };
  $scope.openRepayment = openRepayment($scope);
  $scope.selectRepayment = selectRepayment($scope);
  $scope.setSaveButtonFocus = setSaveButtonFocus($scope);
};

export const addIncome = ({
  $scope,
  elementService,
  currentUserService,
  LoanSecurityInfoID,
}) => {
  $scope.IncomeList.push($scope.getNewIncomeModel(LoanSecurityInfoID));
  const focusElementClass = currentUserService.isNZ
    ? '.income-sub-type'
    : '.income-gross-amount';
  elementService.setFocusLastElementWithTimeout(focusElementClass);
};

export const saveSecurityAddress = ({
  $scope,
  dashboardService,
  addressLookupService,
  isAddressOverhaul,
}) => {
  if (!isAddressOverhaul) {
    return dashboardService.addressDetailsSet({
      ...$scope.security.Address,
      IsMailing: false,
      Type: $scope.security.Address.Type,
      countryCode: $scope.security.Address.CountryCode,
      TypeId: $scope.security.Address.AddressType,
      AddressID: 0,
      AddressValuationExtendedDetailsId: 0,
    });
  }
  return addressLookupService
    .convertToAddressFormat($scope.security.Address)
    .then((address) => {
      return dashboardService.addressDetailsSet({
        ...address,
        IsMailing: false,
        Type: $scope.security.Address.Type,
        countryCode: $scope.security.Address.countryCodeInTwoLetter,
        TypeId: $scope.security.Address.addressType,
        AddressID: 0,
        AddressValuationExtendedDetailsId: 0,
      });
    });
};

export const saveAddress = ({
  $scope,
  dashboardService,
  addressLookupService,
  callback,
}) => {
  const isAddressOverhaul =
    $scope.isAddressOverhaulEnabled &&
    !$scope.security.PropertyDetails.IsPreApproved;

  const isCustomAddress =
    !isAddressOverhaul &&
    $scope.security.Address &&
    $scope.security.Address.isCustomAddress;

  if (isCustomAddress) {
    $scope.security = formatCustomAddress($scope.security);
  }

  const hasAddress =
    $scope.security.Address && $scope.security.Address.formatted_address;
  const isValidAddressForm =
    $scope.securityForm &&
    $scope.securityForm.addressForm &&
    $scope.securityForm.addressForm.$valid;
  if (hasAddress || isValidAddressForm) {
    saveSecurityAddress({
      $scope,
      dashboardService,
      addressLookupService,
      isAddressOverhaul,
    })
      .then(({ data }) => {
        $scope.security.PropertyDetails.AddressId = data;
        callback();
      })
      .catch(toastError);
  } else {
    toastError('Please enter an address');
    $scope.security.sending = false;
  }
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const setupConstructionLoans = ({ $scope, optionsService }) => {
  $scope.TRANSACTION_TYPE = TRANSACTION_TYPE;
  $scope.ownershipMaxLabels = 1;

  $scope.setExistingMortgagesVisibility = () => {
    const isNotPurchasingTransaction =
      $scope.security.TransactionId !== TRANSACTION_TYPE.PURCHASING;
    const isNotPreApproved =
      $scope.security.PropertyDetails &&
      !$scope.security.PropertyDetails.IsPreApproved;
    const hasExistingMortgage = !!(
      $scope.security.MortgageList && $scope.security.MortgageList.length
    );

    $scope.isTransactionConstruction =
      $scope.isConstructionLoanApps &&
      CONSTRUCTION_TRANSACTION_TYPES.includes($scope.security.TransactionId);

    if ($scope.isTransactionConstruction && !$scope.constructionTypeList) {
      $scope.getConstructionTypes();
    }
    $scope.ownershipMaxLabels = $scope.isTransactionConstruction ? 1 : 3;

    $scope.showExistingMortgages =
      (!$scope.isConstructionLoanApps &&
        isNotPreApproved &&
        isNotPurchasingTransaction) ||
      ($scope.isConstructionLoanApps &&
        isNotPurchasingTransaction &&
        hasExistingMortgage);

    return $scope.showExistingMortgages;
  };

  $scope.isSaveProperty = (security) => {
    const IsPreApproved = !!get(
      security,
      'PropertyDetails.IsPreApproved',
      'true',
    );
    return !IsPreApproved || $scope.isConstructionLoanApps;
  };

  if (!$scope.isConstructionLoanApps) {
    return;
  }
  $scope.getConstructionTypes = () => {
    optionsService.getConstructionTypes().then((data) => {
      if (!data) {
        return;
      }
      const sortedData = data.sort((curr, next) => curr.orderNo - next.orderNo);
      $scope.constructionTypeList = sortedData;
    });
  };
};

export const isStateVictoria = (formattedAddress) => {
  return (
    !!formattedAddress &&
    (formattedAddress.includes('VIC, Australia') ||
      formattedAddress === 'Victoria, Australia')
  );
};
