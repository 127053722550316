import angular from 'angular';
import lendingExistingProductComparisons from './lendingExistingProductComparisons';
import './style.scss';

export default angular
  .module('scenes.lendingExistingProductComparisons', [])
  .component(
    'lendingExistingProductComparisons',
    lendingExistingProductComparisons,
  ).name;
