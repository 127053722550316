import template from './tabContent.html';
import TabContentCtrl from './tabContentCtrl';

export default {
  template,
  controller: TabContentCtrl,
  controllerAs: 'vm',
  bindings: {
    tab: '<',
    automationData: '<',
  },
};
