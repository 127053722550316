import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'OpenModelForSelectDateModalCtrl',
      function OpenModelForSelectDateModalCtrl(
        $scope,
        $uibModalInstance,
        $uibModal,
        getSetDate,
        referrer,
        getReferrers,
      ) {
        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.getSetDate = getSetDate;

        $scope.openMobileCalendar = function (filterType, size) {
          $uibModal.open({
            templateUrl: 'mobileCalendar.html',
            size,
            backdrop: 'static',
            keyboard: false,
            controller: 'OpenMobileCalendarModalCtrl',
            windowClass: 'referral_date',
            resolve: {
              filterType() {
                return filterType;
              },
              getReferrers() {
                return getReferrers;
              },
              cancel() {
                return $scope.cancel;
              },
              referrer() {
                return referrer;
              },
            },
          });
        };
      },
    );
