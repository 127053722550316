import template from './commencementDateModal.html';
import controller from './commencementDateModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    familyId: '<',
    quoteId: '<',
  },
};
