import { HIDE_EMAIL_SUBJECTS_KEYWORDS } from 'Common/constants/emailTypes';

export const downloadGmailAttachment = ({
  contactModelService,
  downloadDataService,
  gmailEmail,
  familyId,
}) => (attachmentId) => {
  const params = {
    gmailId: gmailEmail.GmailId || 0,
    attachmentId,
    familyId,
  };
  return contactModelService.downloadGmailAttachment(params).then((data) => {
    if (!data) {
      return;
    }
    const { contentType, documentContent, name } = data;
    downloadDataService.download(
      `data:${contentType};base64,${documentContent}`,
      name,
      contentType,
    );
  });
};

export const checkHideDeleteTransaction = ({
  emailSubject,
  isHideDeleteFlagEnabled,
}) => {
  let isHideDeleteTransaction = false;
  if (isHideDeleteFlagEnabled) {
    HIDE_EMAIL_SUBJECTS_KEYWORDS.forEach((keyword) => {
      if (emailSubject.toLowerCase().includes(keyword.toLowerCase())) {
        isHideDeleteTransaction = true;
      }
    });
  }
  return isHideDeleteTransaction;
};
