import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('onErrorSrc', function onErrorSrc() {
    return {
      link(scope, element, attrs) {
        element.bind('error', () => {
          attrs.$set('src', attrs.onErrorSrc);
        });
      },
    };
  });
