import swal from 'sweetalert';
import { CONFIG_EMAIL } from 'Common/config/email';
import { getLenderLogoHtml } from 'Common/utilities/image';

export default class ActionValidatorService {
  constructor(crmConfirmation, currentUserService) {
    'ngInject';

    this.crmConfirmation = crmConfirmation;
    this.currentUserService = currentUserService;
  }

  checkAdviserAsic(brokerBasicinfo, action) {
    this.onboardingTeamEmail = CONFIG_EMAIL.ONBOARDING_AU.email;
    const isAdviserAsicMissing =
      !brokerBasicinfo || (brokerBasicinfo && !brokerBasicinfo.asicId);
    if (isAdviserAsicMissing) {
      swal({
        title: 'Oops!',
        text: `It seems your broker profile may be missing some info necessary to ${action}. Please contact Onboarding team to correct this info on <a href="mailto:${this.onboardingTeamEmail}">${this.onboardingTeamEmail}</a>.`,
        type: 'error',
        html: true,
        showConfirmButton: true,
      });
    }
    return isAdviserAsicMissing;
  }

  getActiveFactFindTranscludeHtml(activeFactFindLoans) {
    const currency = this.currentUserService.brokerRegionalization.currencySign;
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const getActiveFactFindRowItem = (activeFactFindLoan, amountCurrency) => {
      const {
        loanAmount,
        applicants,
        referenceNumber,
        status,
        lender,
      } = activeFactFindLoan;
      const params = {
        lenderName: lender.name || '',
        countryCode: lender.countryCode || '',
      };

      const logoImg = getLenderLogoHtml(params);
      return `<div class="loan-active-item">
        ${logoImg}
        <div class="name-amount-group"><div class="name" title="${applicants}" uib-tooltip="${applicants}">${applicants}</div>
        ${
          loanAmount
            ? `- <div class="amount">${amountCurrency} ${loanAmount}</div>`
            : ''
        }
        ${
          referenceNumber
            ? `<div class="tag reference-no">Ref #: ${referenceNumber}</div>`
            : ''
        }
        </div>
        <div class="tag pipeline-status" title="${status.name}">${
        status.name
      }</div>
      </div>`;
    };

    const activeFactFindItems = activeFactFindLoans.reduce(
      (accum, currentLoan) => {
        const row = getActiveFactFindRowItem(currentLoan, currency);
        return `${accum} ${row}`;
      },
      '',
    );

    return `<div class="active-fact-find-items">${activeFactFindItems}</div>`;
  }

  confirmSendFactFind(activeFactFindLoans, personName, callback) {
    const transcludeHtml = this.getActiveFactFindTranscludeHtml(
      activeFactFindLoans,
    );

    this.crmConfirmation.open({
      type: 'warning',
      title: 'Before we send that invite...',
      description: `Sending a new fact find to ${personName} will remove them from their current fact find:`,
      buttonText: 'Yes, I wish to proceed',
      onConfirm: callback,
      showCloseButton: true,
      showCancelButton: true,
      modalSize: 'md',
      cancelButtonClass: 'colored',
      transcludeHtml,
    });
  }
}
