import { uniqBy, set } from 'lodash';
import { KEY_CODE } from 'Common/constants/keyCode';
import { COUNTRY_SHORTNAME } from 'Common/constants/countryType';
import {
  REGEX,
  ADDRESS_TYPES,
  ADDRESS_TYPE_LIST,
} from 'Common/constants/addressField';
import { fieldInvalid } from 'Common/utilities/formValidation';
import { removeSpacesOnString } from 'Common/utilities/string';
import { addressLookup } from 'Common/mappers/address';

export default class AddressLookupCtrl {
  constructor(
    $timeout,
    generalService,
    corporateService,
    optionsService,
    contactService,
    timeoutService,
    mycrmCountries,
    currentUserService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.generalService = generalService;
    this.corporateService = corporateService;
    this.optionsService = optionsService;
    this.contactService = contactService;
    this.timeoutService = timeoutService;
    this.mycrmCountries = mycrmCountries;
    this.currentUserService = currentUserService;
    this.getExistingAddresses = this.getExistingAddresses.bind(this);
  }

  getSuggestedAddresses(event) {
    if (!this.address || !this.address.searchString) {
      this.isSearchingSuggestion = false;
      return;
    }
    const {
      ARROW_KEY_DOWN,
      ARROW_KEY_UP,
      ARROW_KEY_RIGHT,
      ARROW_KEY_LEFT,
      WIN_ALT,
      ESC,
      TAB,
    } = KEY_CODE;
    const invalidKeyCode = [
      ARROW_KEY_DOWN,
      ARROW_KEY_UP,
      ARROW_KEY_RIGHT,
      ARROW_KEY_LEFT,
      WIN_ALT,
      ESC,
      TAB,
    ];
    if (invalidKeyCode.includes(event.keyCode)) {
      return;
    }
    this.displaySuggestionResult = true;
    this.isSearchingSuggestion = true;
    this.address.isEditSecurityInfo = true;
    this.$timeout.cancel(this.searchTimeOut);
    this.searchTimeOut = this.$timeout(() => {
      if (!this.address || !this.address.searchString) {
        return;
      }
      this.address.isEditSecurityInfo = true;
      this.generalService
        .placeSearch(this.address.searchString)
        .then(({ data }) => {
          this.addressSuggestions = data || [];
          this.isSearchingSuggestion = false;
          this.noSuggestionAvailable = !this.addressSuggestions.length;
        });
    }, 500);
  }

  processAddress(data) {
    if (!data.countryCodeInTwoLetter && data.country) {
      const selectedCountry = this.countries.find(
        (country) => country.name === data.country,
      );
      data.countryCodeInTwoLetter =
        (selectedCountry && selectedCountry.code) || '';
    }
    const { displayDetails, isPreview } = this.address;
    this.address = {
      ...data,
      ...this.mapAddressField(data),
      displayDetails,
      isCustomAddress: true,
      isPreview,
    };
    this.setCurrentAddressType(this.address.addressType);
    this.setPostcodeRegex(data.countryCodeInTwoLetter);
    if (typeof this.onUpdateAddress === 'function') {
      this.onUpdateAddress({ address: this.address });
    }
  }

  getGeocodeAddress(address) {
    this.address.displayDetails = true;
    this.address.isPreview = true;
    this.setSuggestionValue();
    this.generalService.geocodeSearch(address).then(({ data }) => {
      this.processAddress(data);
    });
  }

  cancelPreview() {
    this.address.isPreview = false;
  }

  setSuggestionValue(value = false) {
    this.displaySuggestionResult = value;
  }

  onChangeCountry(countryCode) {
    const selectedCountry =
      this.countries.find((country) => country.code === countryCode) || {};
    this.address.country = selectedCountry.name || '';
    this.setPostcodeRegex(countryCode);
  }

  setPostcodeRegex(countryCode) {
    const regexPatternValidCountry = [
      COUNTRY_SHORTNAME.NEW_ZEALAND,
      COUNTRY_SHORTNAME.AUSTRALIA,
    ];
    this.postCodeRegex = regexPatternValidCountry.includes(countryCode)
      ? REGEX.POST_CODE
      : '';
  }

  mapAddressField(address) {
    if (!address) {
      return {};
    }
    const {
      route,
      suburb: suburbValue,
      locality,
      neighborhood,
      countryCodeInTwoLetter,
      postOfficeType,
      streetType: streetTypeValue,
      streetName: streetNameValue,
      formatted_address: formattedAddress,
    } = address;
    const streetTypeObj =
      route && this.streetTypes.find((type) => route.endsWith(type.name));
    const streetType =
      streetTypeValue || (streetTypeObj && streetTypeObj.name) || '';
    const streetName =
      streetNameValue ||
      (route && route.slice(0, route.length - streetType.length).trim());
    const validLocalitySource = [
      COUNTRY_SHORTNAME.NEW_ZEALAND,
      COUNTRY_SHORTNAME.AUSTRALIA,
    ];
    const suburb =
      suburbValue ||
      (validLocalitySource.includes(countryCodeInTwoLetter)
        ? locality
        : neighborhood);
    return {
      suburb,
      streetName,
      streetType,
      searchString: formattedAddress,
      postOfficeType: postOfficeType || 'PO Box',
    };
  }

  clearAddressFields() {
    const { addressType, postOfficeType } = this.address;
    this.address = {
      displayDetails: true,
      AddressID: 0,
      formatted_address: '',
      addressType,
      postOfficeType: postOfficeType || 'PO Box',
    };
    if (typeof this.onUpdateAddress === 'function') {
      this.onUpdateAddress({ address: this.address });
    }
  }

  onSearchBlur() {
    this.searchFocusTimeout = this.$timeout(() => {
      this.displaySuggestionResult = false;
    }, 500);
  }

  onSearchFocus() {
    this.$timeout.cancel(this.searchFocusTimeout);
    this.displaySuggestionResult = true;
  }

  getStreetTypes() {
    return this.optionsService.getStreetTypes().then((data) => {
      this.streetTypes = data;
    });
  }

  getStates() {
    return this.optionsService.getStates().then((data) => {
      this.states = data;
    });
  }

  getPostOfficeTypes() {
    return this.optionsService.getPostOfficeTypes().then((data) => {
      this.postOfficeTypes = data;
    });
  }

  getExistingAddresses(familyId) {
    this.existingAddress = [];
    const callerFamilyId =
      typeof familyId !== 'undefined' ? familyId : this.familyId;
    if (!callerFamilyId) {
      return;
    }
    this.contactService.contactAddressGet(callerFamilyId).then(({ data }) => {
      const existingAddress = uniqBy(
        data,
        (address) => address.formatted_address,
      );
      this.existingAddress =
        (existingAddress &&
          existingAddress.length &&
          existingAddress.map(addressLookup)) ||
        [];
    });
  }

  existingAddressLookup(isOpen = true) {
    if (isOpen) {
      this.displayExistingAddressLookup = isOpen;
      this.$timeout.cancel(this.existingAddressLookupTimer);
      return;
    }
    this.existingAddressLookupTimer = this.$timeout(() => {
      this.displayExistingAddressLookup = isOpen;
    }, 100);
  }

  mapExistingAddress(address) {
    this.existingAddressLookup(false);
    this.address.displayDetails = true;
    this.address.isPreview = true;
    this.processAddress(address);
  }

  setCurrentAddressType(value = ADDRESS_TYPES.STANDARD) {
    this.address.addressType = value;
  }

  setManualAddress(addressType) {
    this.clearAddressFields();
    this.setCurrentAddressType(addressType);
    this.address.displayDetails = true;
    this.displaySuggestionResult = false;
    this.address.countryCodeInTwoLetter = this.currentUserService.countryCode;
    this.onChangeCountry(this.currentUserService.countryCode);
    this.processAddress(this.address);
  }

  toggleSearchAgainPopover() {
    this.openSearchAgainConfirmation = !this.openSearchAgainConfirmation;
  }

  onClearAddress() {
    this.isExistingAddress = false;
    this.clearAddressFields();
    set(this, 'address.displayDetails', false);
  }

  $onInit() {
    this.selectType = this.isFormXs ? 'type-select' : 'clip-select-caret';
    this.openSearchAgainConfirmation = false;
    if (!this.address) {
      this.address = {};
    }
    this.focusAddressSearch =
      typeof this.disableAutoFocus === 'undefined'
        ? true
        : !this.disableAutoFocus;
    this.addressSearchLabel =
      typeof this.searchLabel !== 'undefined'
        ? this.searchLabel
        : 'Search Address';
    this.appendInputId = removeSpacesOnString(this.addressSearchLabel);
    this.addressTypeList = ADDRESS_TYPE_LIST;
    this.getExistingAddresses();
    this.countries = this.mycrmCountries.getCountries();
    this.addressTypes = ADDRESS_TYPES;
    this.postCodeRegex = '';
    this.validateField = fieldInvalid(this.addressForm);
    if (typeof this.handler === 'function') {
      this.handler({
        reloadExistingAddress: this.getExistingAddresses,
      });
    }
    const requiredPromises = [
      this.getStreetTypes(),
      this.getStates(),
      this.getPostOfficeTypes(),
    ];
    Promise.all(requiredPromises).then(() => {
      this.processAddress(this.address);
    });
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('AddressLookupCtrl');
  }
}
