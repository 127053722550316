import template from './loanSubmissionEmailContent.html';
import LoanSubmissionEmailContentCtrl from './loanSubmissionEmailContentCtrl';

export default {
  template,
  controller: LoanSubmissionEmailContentCtrl,
  controllerAs: 'vm',
  bindings: {
    headingText: '@',
    email: '<',
    onEditClick: '&',
    isUrgent: '<',
    lenderSubmissionSet: '<',
    showEditText: '<',
  },
};
