import { ioffLinksBuilderForUI } from 'Common/mappers/insuranceFactFind';
import { factFindGetAnswerForUI } from 'Common/mappers/factFind';

class InsuranceFactFindService {
  constructor($q, httpClient) {
    'ngInject';

    this.$q = $q;
    this.httpClient = httpClient;
    this.apiBaseUrl = 'insurance-fact-find';
  }

  shareWithClient(data) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/share-with-client`, data)
      .then(({ data }) => data);
  }

  getFactFindAnswer(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/answers`)
      .then(({ data }) => factFindGetAnswerForUI(data));
  }

  getIOFFLinks(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}`)
      .then(({ data }) => ioffLinksBuilderForUI(data));
  }
}

export default InsuranceFactFindService;
