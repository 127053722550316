import { isValidEmailAddress } from 'Common/utilities/string';

class CessationModalCtrl {
  constructor(corporateService, corporateModelService) {
    'ngInject';

    this.corporateService = corporateService;
    this.corporateModelService = corporateModelService;
  }

  close() {
    this.modalInstance.dismiss('cancel');
  }

  openDatePopover() {
    this.dateIsOpen = true;
  }

  ceaseContact() {
    this.isSubmitting = true;
    const { cessationDate, secondaryEmail } = this.cessationForm;
    this.cessationForm.isDateValid = !!cessationDate;
    this.cessationForm.isEmailValid =
      secondaryEmail && isValidEmailAddress(secondaryEmail);
    if (!this.cessationForm.isEmailValid || !this.cessationForm.isDateValid) {
      this.isSubmitting = false;
      return;
    }
    const queryParams = {
      adviserId: this.familyId,
      cessationDate,
      secondaryEmail,
    };
    this.corporateModelService.ceaseAdviser(queryParams).then(() => {
      this.modalInstance.close();
    });
  }

  $onInit() {
    this.cessationForm = {
      isEmailValid: true,
      isDateValid: true,
      cessationDate: new Date(),
      secondaryEmail: '',
    };
    this.updatedList = [
      'Broker Status',
      'Offshoring',
      'Lead Profile Status',
      'Lead Notification',
      'Wealth Market Referral Auto Allocation',
      'Campaign Breeze',
      'NPS',
      'MyCRM Active',
      'Refer Customers to Wealth Market',
      'Refer Customers to Concierge',
      'Refer Customers to Home Now',
      'Opt In to Pepper Submissions',
      'Subscriptions',
      'Gmail/Gdrive/Gcal Integration',
      'Customer IO',
      'Agreements',
    ];
    const clientId = 0;
    this.corporateService
      .corporateContactGet(clientId, this.familyId)
      .then(({ data }) => {
        this.cessationForm.secondaryEmail = data.SecondaryEmail || '';
      });
  }
}

export default CessationModalCtrl;
