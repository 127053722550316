class RequiredDocumentsPreviewCtrl {
  constructor(corporateModelService, onboardingDataService) {
    'ngInject';

    this.corporateModelService = corporateModelService;
    this.onboardingDataService = onboardingDataService;
  }

  getOnlineOnboardingApplicationChecklistHeader() {
    if (!this.familyId) {
      return;
    }
    this.corporateModelService
      .getOnlineOnboardingApplicationChecklistHeader(this.familyId)
      .then((data) => {
        if (!data || !data.length) {
          return;
        }
        this.uploadedRequiredDocuments = data.filter(
          (list) => list.uploadedCount,
        );
      });
  }

  $onInit() {
    this.familyId = this.onboardingDataService.getFamilyId();
    this.getOnlineOnboardingApplicationChecklistHeader();
  }
}

export default RequiredDocumentsPreviewCtrl;
