import angular from 'angular';
import moment from 'moment';
import {
  FIXED_RATE_EXPIRES_ID,
  INSURANCE_TABS,
} from 'Common/constants/customerCareModules';
import {
  sendFixedRateSmsBuilderForUI,
  sendFixedRateSmsEmailBuilderForUI,
  sendInsuranceSmsBuilderForUI,
  sendInsuranceSmsEmailBuilderForUI,
} from 'Common/mappers/customerCare';
import { setCountryDetails } from './util/fixedRateSMSModalCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('FixedRateSMSModalCtrl', function FixedRateSMSModalCtrl(
      $scope,
      $uibModalInstance,
      $state,
      $controller,
      toaster,
      optionsService,
      contactService,
      customerCareService,
      customerCareInsuranceService,
      fixedRateListTable,
      fixedRateObj,
      customerCareTemplateService,
      source,
      tab,
      isInsurance,
      formErrorHandlerServices,
      currentUserService,
    ) {
      const vm = this;
      angular.extend(
        vm,
        $controller('CustomerCareSendMessageModalBaseCtrl', { $scope }),
      );

      $scope.recipients = [];
      $scope.smsList = [];
      $scope.isComplete = !currentUserService.isNZ;
      $scope.callingCode = '';
      $scope.tst = '';
      $scope.currentModule = FIXED_RATE_EXPIRES_ID;
      $scope.mergeFieldParams = fixedRateObj;

      const getCountryList = () => {
        optionsService.countryListGet().then(
          (response) => {
            $scope.countryList = response.data;

            if (fixedRateObj) {
              fixedRateObj.country = fixedRateObj.country
                ? fixedRateObj.country
                : 'NZ';
            }

            $scope.countryObj = $scope.countryList.filter((item) => {
              if (fixedRateObj.country === item.CountryCode) {
                return item;
              }
              return false;
            });

            setCountryDetails({ $scope, source });
          },
          () => {},
        );
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.makeObject = (selectedCountryCode) => {
        if (source === 'single') {
          fixedRateObj.mobile = fixedRateObj.mobile.split(',')[0];
          if (fixedRateObj.mobile) {
            if (typeof selectedCountryCode !== 'undefined') {
              $scope.countryObj = $scope.countryList.filter((item) => {
                if (selectedCountryCode === item.CountryCode) {
                  return item;
                }
                return false;
              });

              if (typeof $scope.countryObj !== 'undefined') {
                $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
              }
            }

            if (!isInsurance) {
              const expDate = moment(fixedRateObj.expiryDate).format('D MMM');
              const notiHeader = `Fixed Rate Expires (${expDate}) Completed via SMS`;
              $scope.smsList.sms = [
                sendFixedRateSmsBuilderForUI(
                  fixedRateObj,
                  notiHeader,
                  $scope.callingCode,
                ),
              ];
              $scope.recipients.email = [
                sendFixedRateSmsEmailBuilderForUI(
                  fixedRateObj,
                  $scope.callingCode,
                ),
              ];
            } else {
              const expDate = moment(fixedRateObj[tab.dateName]).format(
                'D MMM',
              );
              const notiHeader = `${tab.label} Expires (${expDate}) Completed via SMS`;
              $scope.smsList.sms = [
                sendInsuranceSmsBuilderForUI(
                  fixedRateObj,
                  notiHeader,
                  $scope.callingCode,
                ),
              ];
              $scope.recipients.email = [
                sendInsuranceSmsEmailBuilderForUI(
                  fixedRateObj,
                  $scope.callingCode,
                ),
              ];
            }
          }
        }
        $scope.fixedRateListTable = fixedRateListTable;
        if (source === 'all') {
          $scope.all = true;
          if (typeof selectedCountryCode !== 'undefined') {
            // eslint-disable-next-line sonarjs/no-identical-functions
            $scope.countryObj = $scope.countryList.filter((item) => {
              if (selectedCountryCode === item.CountryCode) {
                return item;
              }
              return false;
            });

            if (typeof $scope.countryObj !== 'undefined') {
              $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
            }
          }

          $scope.selectSMSList = [];
          $scope.emailRecipients = [];
          if ($scope.fixedRateListTable.length) {
            $scope.fixedRateListTable.forEach((item) => {
              if (!item.mobile) {
                return;
              }
              item.mobile = item.mobile.split(',')[0];

              const sms = !isInsurance
                ? sendFixedRateSmsBuilderForUI(item, '', $scope.callingCode)
                : sendInsuranceSmsBuilderForUI(item, '', $scope.callingCode);
              const email = !isInsurance
                ? sendFixedRateSmsEmailBuilderForUI(item, $scope.callingCode)
                : sendInsuranceSmsEmailBuilderForUI(item, $scope.callingCode);

              $scope.selectSMSList.push(sms);
              $scope.emailRecipients.push(email);
            });

            if ($scope.selectSMSList.length) {
              $scope.smsList.sms = $scope.selectSMSList;
              $scope.noOfPeople = $scope.selectSMSList.length;
            }
            if ($scope.emailRecipients.length) {
              $scope.recipients.email = $scope.emailRecipients;
            }
          }
        }
      };

      $scope.countryChanged = (selectedCountryCode) => {
        $scope.makeObject(selectedCountryCode);
      };

      $scope.selectedTemplate = [
        {
          UserTemplateID: 0,
          UserTemplateName: 'Blank',
        },
      ];

      $scope.refreshResults = (select) => {
        const search = select.search;

        $scope.getRecipientAutoSuggestion(search);
      };

      $scope.sendSMS = (form) => {
        const isFormValid = formErrorHandlerServices.sendEmailFormValidation(
          form,
        );
        if (!isFormValid) {
          return;
        }

        $scope.recipientsSMSArray = [];
        $scope.smsToSend = {};
        $scope.smsToSend.IsComplete = $scope.isComplete;

        $scope.smsToSend.Message = $scope.sms.Message;
        $scope.smsToSend.CustomerCareType = isInsurance
          ? tab.type
          : 'FIXED_RATE_EXPIRY';

        angular.forEach($scope.smsList.sms, (item) => {
          $scope.recipientsSMSArray.push(item);
        });
        $scope.smsToSend.SmsModelList = $scope.recipientsSMSArray;

        const insuranceParam =
          isInsurance && tab.type === INSURANCE_TABS[1].type
            ? { tableRecordCsvId: fixedRateObj.tableRecordIDCSV }
            : {};
        const sendSms = isInsurance
          ? customerCareInsuranceService.sendSms(
              $scope.smsToSend,
              insuranceParam,
            )
          : customerCareService.CustomerCareSMSSend($scope.smsToSend);

        sendSms.then(
          (fixedRateRes) => {
            if (fixedRateRes.data && fixedRateRes.data.length) {
              const fixedRateSMSValid = customerCareTemplateService.customerCareResponseMsg(
                fixedRateRes.data,
                'SMS',
              );
              if (fixedRateSMSValid) {
                $scope.smsToSend.Message = '';
                form.$setPristine(true);
              }
              $uibModalInstance.close({ success: true });
            }
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          },
        );
      };
      const init = () => {
        getCountryList();

        $scope.content =
          '<div style="font-family: Arial;"><div><div><span style="font-family: Arial; font-size: 10pt;">Hi </span><span style="font-family: Arial; font-size: 10pt;">[Preferred Name]</span></div></div><br /><div>&nbsp;</div><div><span style="font-family: Arial; font-size: 10pt;">Best regards,</span></div><div><span style="font-family: Arial; font-size: 10pt;">[Your Name]</span></div></div><div>&nbsp;</div><div>[Email Signature]</div>';
      };

      init();
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
    });
