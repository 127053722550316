import template from './searcheableContactSelector.html';
import controller from './searcheableContactSelectorCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    contactType: '<',
    clientsList: '<',
    onInitialized: '&',
    onClientSelected: '&',
  },
};
