import template from './mycrmToggleText.html';
import './style.scss';

export default {
  template,
  bindings: {
    value: '<',
    isFocused: '<',
    yesValue: '<',
    noValue: '<',
    yesLabel: '<',
    noLabel: '<',
    onToggle: '&',
  },
  controllerAs: 'vm',
};
