import template from './providersTab.html';
import controller from './providersTabCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    accreditationType: '<',
    accreditationLabel: '<',
    familyId: '<',
    adviserName: '<',
    isIndividualProfile: '<',
    onSearchLenderFromParent: '&',
    onRefreshList: '&',
    onSelectProviders: '&',
  },
};
