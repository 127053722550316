import moment from 'moment';
import { parseToInt10 } from 'Common/utilities/parse';
import { CAPS_DD_MMMM_YYYY } from 'Common/constants/dateConfigs';

export const setAnnualReviewCompilationUtil = ({
  annualReview,
  customerCareService,
  table,
}) => {
  if (!annualReview || !customerCareService || !table) {
    return;
  }
  const payload = {
    AnnualEventMonth: annualReview.ReviewMonth,
    AnnualEventYear: parseToInt10(annualReview.ReviewYear),
    IsCompleted: annualReview.isComplete,
    FamilyId: annualReview.FamilyID,
  };
  customerCareService.setAnnualReviewCompletion(payload).then(() => {
    table.reload();
  });
};

export const initializeAnnualReviewDateRangeSetting = ($scope) => {
  const thisMonthFrom = moment().startOf('month').format(CAPS_DD_MMMM_YYYY);
  const thisMonthTo = moment().endOf('month').format(CAPS_DD_MMMM_YYYY);
  const currentDate = moment().add(1, 'months');
  const nextMonthFrom = moment(currentDate)
    .startOf('month')
    .format(CAPS_DD_MMMM_YYYY);
  const nextMonthTo = moment(currentDate)
    .endOf('month')
    .format(CAPS_DD_MMMM_YYYY);
  $scope.annualReviewDateRangeOptions = [
    {
      text: 'This month',
      value: 'this',
      from: thisMonthFrom,
      to: thisMonthTo,
      range: [thisMonthFrom, thisMonthTo],
    },
    {
      text: 'Next month',
      value: 'next',
      from: nextMonthFrom,
      to: nextMonthTo,
      range: [nextMonthFrom, nextMonthTo],
    },
    {
      text: 'Custom',
      range: [moment(), moment()],
    },
  ];
  $scope.defaultRangeIndex = $scope.annualReviewDateRangeOptions
    .map((range) => range.value)
    .indexOf($scope.mainObj.dateRange);
};
