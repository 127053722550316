import template from './statusDropdown.html';
import controller from './statusDropdownCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    opportunityStatus: '<',
    opportunityStatusId: '<',
    loanId: '<',
    opportunityId: '<',
    updatedStatusDate: '=',
    rowId: '<',
    onRemoveRow: '&',
    onSelectStatus: '&',
    pipelineItem: '<',
  },
};
