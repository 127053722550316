import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'FinPosLiabilitiesModalCtrl',
      function FinPosLiabilitiesModalCtrl($scope, $uibModalInstance) {
        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.saveBreakdown = function () {
          $scope.cancel();
        };
      },
    );
