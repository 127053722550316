import template from './navBreadCrumb.html';
import controller from './navBreadCrumbCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    links: '<',
  },
};
