import template from './commissionByBroker.html';
import controller from './commissionByBrokerCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    slickData: '<',
    isLoading: '<',
    optionList: '<',
    selected: '<',
    onSelect: '&',
  },
};
