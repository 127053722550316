import get from 'lodash/get';
import { setMappedContactPhoneByType } from 'Common/utilities/contact';
import { PHONE_TYPE } from 'Common/constants/phoneMobileFormat';

export default class HelloPackPhoneFieldCtrl {
  constructor(
    $timeout,
    helloPackService,
    elementService,
    uiService,
    timeoutService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.helloPackService = helloPackService;
    this.elementService = elementService;
    this.uiService = uiService;
    this.timeoutService = timeoutService;
  }

  $onInit() {
    this.phoneFieldName = `phone-${this.key}`;
    this.currentValue = this.client.defaultMobile;
    this.$timeout(() => {
      this.form = get(
        this,
        `helloPackService.form.involvedPartyForm[${this.key}][${this.phoneFieldName}]`,
        {},
      );
      this.isValidField = this.form.$valid || false;
      if (!this.isValidField) {
        this.form.$setTouched(true);
      }
      if (!this.helloPackService.formError.mobileError) {
        this.setInvolvedPartiesError(this.isValidField);
      }
    });
    this.isInternationalPhoneNumberEnabled = this.uiService.isInternationalPhoneNumber;
  }

  onFieldBlur() {
    this.isValidField = this.form.$valid || false;
    this.setInvolvedPartiesError(this.isValidField);
    if (!this.isValidField) {
      return;
    }
    this.isPhoneChanged = this.currentValue !== this.client.defaultMobile;
  }

  onSaveClientPhone() {
    const client = setMappedContactPhoneByType({
      contact: this.client,
      countryCode: this.client.defaultCountryCode,
      phone: this.currentValue,
      phoneType: PHONE_TYPE.MOBILE,
    });
    this.isPhoneChanged = false;
    this.onUpdate && this.onUpdate({ client });
  }

  onDismissPopover() {
    this.isPhoneChanged = false;
    this.currentValue = this.client.defaultMobile;
  }

  setInvolvedPartiesError(isValid) {
    this.helloPackService.formError.mobileError = isValid
      ? ''
      : 'Mobile number is invalid.';
    this.helloPackService.form.$setValidity('mobileError', isValid);
    if (!isValid) {
      this.elementService.scrollToElement('#involved-party-header');
    }
    this.helloPackService.onSetInvolvedPartiesValidity(
      this.helloPackService.formError.mobileError,
    );
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('HelloPackPhoneFieldCtrl');
  }
}
