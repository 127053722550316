import { computeTotalCommission } from '../../util/commissionDashboardUtil';

export default class CommissionHistoryCtrl {
  $onInit() {
    this.sort = {};
  }

  $onChanges(changes) {
    const { isLoadingFilter } = changes;
    const shouldFilter =
      isLoadingFilter && typeof isLoadingFilter.currentValue !== 'undefined';

    shouldFilter && this.filterTransactionHistory();
  }

  changeSorting(column) {
    if (this.sort.column === column) {
      this.sort.descending = !this.sort.descending;
      return;
    }

    this.sort.column = column;
    this.sort.descending = false;
  }

  filterTransactionHistory(adviserId = '') {
    const activeHeader = this.headers.find((item) => !!item.active);
    if (!activeHeader) {
      return;
    }

    const { transactionList } = activeHeader;

    this.tableData =
      adviserId === ''
        ? transactionList
        : transactionList.filter((trans) => adviserId === trans.BrokerID);

    activeHeader.totalCommission = computeTotalCommission(
      this.tableData,
      activeHeader,
    );
  }
}
