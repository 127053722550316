import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import {
  COMMISSION_TYPE,
  ONE_OFF_TYPES,
} from 'Common/constants/commissionType';
import { FINANCIAL_FREQUENCY } from 'Common/constants/financialFrequency';
import { SOUTH_BUILDING_SOCIETY } from 'Common/constants/commissionCapping';
import {
  isIncludeCommissionType,
  getMatchedLoanStructure,
  initCommissionEnhancements,
} from './util/loanCommissisonCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanCommissionCtrl', function LoanCommissionCtrl(
      $q,
      $scope,
      $state,
      toaster,
      SweetAlert,
      loanInformationService,
      configService,
      contactService,
      optionsService,
      commissionService,
    ) {
      const vm = this;

      initCommissionEnhancements({
        vm,
        configService,
      });
      /*
       * @desc contains sample list of One Off Commission data
       */
      vm.oneOffCommissionList = [];

      /*
       * @desc contains sample list of Recurring Commission data
       */
      vm.recurringCommissionList = [];

      vm.productList = [];

      vm.typeList = [];

      vm.frequencyList = [];

      vm.oneOffCommissionRates = [];

      vm.COMMISSION_TYPE = COMMISSION_TYPE;
      vm.FINANCIAL_FREQUENCY = FINANCIAL_FREQUENCY;

      /*
       * @desc date settings
       */
      vm.dateSettings = {
        format: 'dd MMM yyyy',
        dateOptions: {
          formatYear: 'yy',
          showWeeks: false,
        },
        altInputFormats: ['d!/M!/yyyy', 'd!M!yyyy'],
        openDate: (obj) => {
          obj.dateIsOpen = !obj.dateIsOpen;
        },
        openPaidDate: (obj) => {
          if (obj.isPaid) {
            obj.paidDateIsOpen = !obj.paidDateIsOpen;
          } else {
            obj.paidDateIsOpen = false;
          }
        },
      };

      /*
       * @name triggerEdit
       * @desc will switch isEdit property of any given object
       */
      vm.triggerEdit = (obj) => {
        obj.originalData = _.clone(obj);
        obj.isEdit = !obj.isEdit;
      };

      /*
       * @name confirmEdit
       * @desc saves the obj
       */
      vm.confirmEdit = (obj, type) => {
        if (!type) {
          return;
        }
        obj.isEdit = false;
        obj.originalData = null;

        if (type === COMMISSION_TYPE.ONE_OFF) {
          const data = vm.translateFromOneOffViewModel(obj);
          contactService
            .loansExpectedCommissionsSet(data, vm.isCommissionEnhancementsV3On)
            .then(() => {
              toaster.pop(
                'success',
                'Saved',
                'Changes to commission successfully saved',
              );
            });
        } else {
          const data = vm.translateFromRecurringViewModel(obj);
          // eslint-disable-next-line sonarjs/no-identical-functions
          contactService.loansExpectedCommissionsRecurringSet(data).then(() => {
            toaster.pop(
              'success',
              'Saved',
              'Changes to commission successfully saved',
            );
          });
        }
      };

      vm.cancelEdit = (obj) => {
        angular.extend(obj, obj.originalData);
      };

      /*
       * @name triggerDelete
       */
      vm.triggerDelete = (obj, type) => {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This commission will be removed from the list.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              if (type === COMMISSION_TYPE.ONE_OFF) {
                contactService
                  .loansExpectedCommissionsDelete(obj.id)
                  .then(() => {
                    toaster.pop(
                      'success',
                      'Deleted',
                      'Commission successfully deleted',
                    );
                  });
                _.remove(vm.oneOffCommissionList, (commission) => {
                  return commission.id === obj.id;
                });
              } else {
                contactService
                  .loansExpectedCommissionsRecurringDelete(
                    loanInformationService.loanId,
                    obj.id,
                  )
                  // eslint-disable-next-line sonarjs/no-identical-functions
                  .then(() => {
                    toaster.pop(
                      'success',
                      'Deleted',
                      'Commission successfully deleted',
                    );
                  });
                _.remove(vm.recurringCommissionList, (commission) => {
                  return commission.id === obj.id;
                });
              }
            }
          },
        );
      };

      /*
       * @name toggleAdd
       * @desc will display add commission row
       */
      vm.getDefaultRate = (type) => {
        let toReturnValue = 0;
        if (type === COMMISSION_TYPE.ONE_OFF) {
          const commissionListLength = vm.oneOffCommissionList.length;
          toReturnValue = commissionListLength
            ? vm.oneOffCommissionList[commissionListLength - 1].rate
            : null;
        } else {
          const commissionListLength = vm.recurringCommissionList.length;
          toReturnValue = commissionListLength
            ? vm.recurringCommissionList[commissionListLength - 1].rate
            : null;
        }

        return toReturnValue;
      };

      vm.autoComputeoneOffCommission = (obj) => {
        vm.oneOffCommissionRates.forEach((key) => {
          if (key.commissionTypeID === obj.typeId) {
            obj.rate = key.isPercentage
              ? Number.parseFloat(key.commissionRate * 100).toFixed(3)
              : 0;
            obj.expectedAmount = !key.isPercentage
              ? key.commissionRate
              : (obj.rate / 100) * obj.rateAmount;
          }
          vm.commissionCapping(obj);
        });
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      vm.toggleAdd = (type) => {
        vm.commissionTypeAddMode =
          type !== vm.commissionTypeAddMode ? type : null;
        vm.newCommissionOneOff = [];
        vm.newCommissionRecurring = {};

        if (!vm.productList && !vm.productList.length) {
          return;
        }
        const defaultProduct = vm.productList[0];
        const defaultRate = vm.getDefaultRate(type);

        if (vm.commissionTypeAddMode === COMMISSION_TYPE.ONE_OFF) {
          const defaultTypeId =
            vm.typeList && vm.typeList.length ? vm.typeList[0].id : null;
          vm.newCommissionOneOff = vm.productList.map((product, index) => {
            const matchedLoan = getMatchedLoanStructure({
              loanInformationService,
              product,
            });
            const updatedProduct = {
              ...product,
              rate: defaultRate,
              rateAmount: product.amount,
              typeId: defaultTypeId,
            };
            vm.calculateExpectedAmount(updatedProduct);
            vm.autoComputeoneOffCommission(updatedProduct);
            const defaultSettlementDate = vm.isCommissionEnhancementsV3On
              ? null
              : new Date();
            const settlementDate = loanInformationService.settlementDate
              ? new Date(loanInformationService.settlementDate)
              : defaultSettlementDate;
            const refixDate =
              (matchedLoan.FixedRateBegin &&
                new Date(matchedLoan.FixedRateBegin)) ||
              null;
            return {
              itemId: index,
              productId: product.id,
              rateAmount: updatedProduct.rateAmount,
              rate: updatedProduct.rate,
              refixDate,
              settlementDate,
              expectedAmount: updatedProduct.expectedAmount,
              typeId: updatedProduct.typeId,
              productName: product.name,
              date: settlementDate,
            };
          });
        } else {
          vm.newCommissionRecurring = {
            productId: defaultProduct.id,
            rateAmount: defaultProduct.amount,
            rate: defaultRate,
          };
          vm.calculateExpectedAmount(vm.newCommissionRecurring);
          vm.newCommissionRecurring.frequencyId =
            vm.frequencyList && vm.frequencyList.length
              ? vm.getDefaultFrequency(vm.frequencyList)
              : null;
        }
      };
      vm.recalculateCommission = (objData) => {
        vm.setProperDatePerCommissionType(objData);
        vm.autoComputeoneOffCommission(objData);
      };
      vm.confirmAdd = (newCommission, type) => {
        if (type === COMMISSION_TYPE.ONE_OFF) {
          vm.addOneOffCommission(newCommission);
        } else {
          vm.addRecurringCommission(newCommission);
        }
      };

      vm.getDefaultFrequency = (frequencies) => {
        const defaultFrequency = frequencies.find(
          (frequency) => frequency.id === FINANCIAL_FREQUENCY.MONTHLY,
        );
        return defaultFrequency ? defaultFrequency.id : 0;
      };

      vm.onIsPaidChange = (obj, type) => {
        if (obj.isPaid) {
          obj.paidDate = moment().toDate();
        } else {
          obj.paidDate = null;
        }

        if (obj.id) {
          vm.confirmEdit(obj, type);
        }
      };

      vm.onDatePaidChange = (obj, type) => {
        vm.confirmEdit(obj, type);
      };

      vm.onProductChanged = (obj) => {
        const product = _.find(vm.productList, (prod) => {
          return prod.id === obj.productId;
        });

        if (product) {
          obj.rateAmount = product.amount;
          vm.calculateExpectedAmount(obj);
        }
      };

      vm.commissionCapping = (commission, isFirstLoad) => {
        const exceededCommissionCapForSBS =
          vm.lenderId === SOUTH_BUILDING_SOCIETY.LENDER_ID &&
          commission.expectedAmount > SOUTH_BUILDING_SOCIETY.COMMISSION_CAP &&
          commission.typeId === ONE_OFF_TYPES.UPFRONT;

        if (exceededCommissionCapForSBS) {
          commission.expectedAmount = SOUTH_BUILDING_SOCIETY.COMMISSION_CAP;
          if (isFirstLoad) {
            vm.confirmEdit(commission, COMMISSION_TYPE.ONE_OFF);
          }
        }
      };

      vm.calculateExpectedAmount = (obj) => {
        if (obj.rate && obj.rateAmount) {
          obj.expectedAmount = (obj.rate / 100) * obj.rateAmount;
        }
        vm.commissionCapping(obj);
      };

      /*
       * @name sendEnquiry
       * @desc
       * @param
       * @return
       */
      vm.toggleCommissionEnquiry = (commissionType) => {
        loanInformationService.openCommissionEnquiry(commissionType);
      };

      /*
       * @name loadCommissions
       * @desc loads the list of commission by type
       * @param type, can be 'received' (for one-off commissions) or 'expected' (for recurring commissions)
       * @return
       */
      vm.loadCommissions = (type) => {
        switch (type) {
          case COMMISSION_TYPE.ONE_OFF:
            vm.loadOneOffCommissions();
            break;
          case COMMISSION_TYPE.RECURRING:
            vm.loadRecurringCommissions();
            break;
          default:
            break;
        }
      };

      vm.addOneOffCommission = (commission) => {
        if (!commission) {
          return;
        }
        vm.updateProductCommissionList(
          commission.itemId,
          COMMISSION_TYPE.ONE_OFF,
        );
        const data = vm.translateFromOneOffViewModel(commission);

        contactService
          .loansExpectedCommissionsSet(data, vm.isCommissionEnhancementsV3On)
          .then((response) => {
            const newlyAddedCommission = vm.translateToOneOffCommissionViewModel(
              response,
            );
            if (newlyAddedCommission) {
              if (newlyAddedCommission.id) {
                newlyAddedCommission.modifiable = true;
              }

              vm.oneOffCommissionList.push(newlyAddedCommission);
              toaster.pop(
                'success',
                'Added',
                'New one-off commission successfully added',
              );
            }
          });
      };

      vm.updateProductCommissionList = (itemId, typeMode) => {
        if (typeMode === COMMISSION_TYPE.ONE_OFF) {
          const updatedCommission = vm.newCommissionOneOff.filter(
            (commission) => commission.itemId !== itemId,
          );
          vm.commissionTypeAddMode = updatedCommission.length
            ? vm.commissionTypeAddMode
            : null;
          vm.newCommissionOneOff = updatedCommission;
        }
      };

      vm.addRecurringCommission = (commission) => {
        vm.toggleAdd(COMMISSION_TYPE.RECURRING);

        if (!commission) {
          return;
        }
        vm.updateProductCommissionList(commission.itemId);
        const data = vm.translateFromRecurringViewModel(commission);

        contactService
          .loansExpectedCommissionsRecurringSet(data)
          .then((response) => {
            const newlyAddedCommission = vm.translateToRecurringCommissionViewModel(
              response,
            );
            if (newlyAddedCommission) {
              vm.recurringCommissionList.push(newlyAddedCommission);
              toaster.pop(
                'success',
                'Added',
                'New recurring commission successfully added',
              );
            }
          });
      };

      vm.translateFromOneOffViewModel = (commission) => {
        if (commission) {
          const getDateFormat = (date) => {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            return date ? moment(date).format('YYYY-MM-DDThh:mm:ss') : null;
          };
          const updatableDateSettled = vm.isCommissionEnhancementsV3On
            ? commission.settlementDate
            : getDateFormat(commission.date);

          const refixDate = getDateFormat(commission.refixDate);

          return {
            ExpectedCommissionId: commission.id,
            LoanID: loanInformationService.loanId,
            LoanStructureID: commission.productId,
            DateExpected:
              vm.isCommissionEnhancementsV3On &&
              commission.typeId === ONE_OFF_TYPES.REFIX
                ? refixDate
                : updatableDateSettled,
            DateSettled: updatableDateSettled,
            RefixDate: refixDate,
            TypeId: commission.typeId,
            LoanAmount: commission.rateAmount,
            CommissionRatio: commission.rate / 100,
            ExpectedCommission: commission.expectedAmount,
            IsPaid: commission.isPaid,
            DatePaid: commission.paidDate
              ? moment(commission.paidDate).format('YYYY-MM-DDThh:mm:ss')
              : null,
            AmountPaid: commission.paidAmount,
          };
        }
        return null;
      };

      vm.translateFromRecurringViewModel = (commission) => {
        if (commission) {
          return {
            RecurringID: commission.id,
            LoanID: loanInformationService.loanId,
            LoanStructureID: commission.productId,
            FrequencyID: commission.frequencyId,
            LoanAmount: commission.rateAmount,
            CommissionRatio: commission.rate,
            ExpectedAmount: commission.expectedAmount,
            DateStart: commission.dateStart
              ? moment(commission.dateStart).format('YYYY-MM-DDThh:mm:ss')
              : null,
            IsStillRecurring: commission.isActive,
          };
        }
        return null;
      };

      /*
       * @name loadOneOffCommissions
       * @desc loads the list of received commissions (one-off commissions)
       */
      vm.loadOneOffCommissions = () => {
        contactService
          .loansOneOffCommissionsGet(
            loanInformationService.loanId,
            vm.isCommissionEnhancementsV3On,
          )
          .then((response) => {
            vm.oneOffCommissionList = [];
            if (response && response.length) {
              _.each(response, (obj) => {
                const commission = vm.translateToOneOffCommissionViewModel(obj);
                if (commission) {
                  vm.commissionCapping(commission, true);
                  vm.oneOffCommissionList.push(commission);
                }
              });
            }
          });
      };

      /*
       * @name loadRecurringCommissions
       * @desc loads the list of expected commissions (recurring commissions)
       */
      vm.loadRecurringCommissions = () => {
        contactService
          .loansExpectedCommissionsRecurringGet(loanInformationService.loanId)
          .then((response) => {
            vm.recurringCommissionList.length = 0;
            if (response && response.length > 0) {
              _.each(response, (obj) => {
                const commission = vm.translateToRecurringCommissionViewModel(
                  obj,
                );
                if (commission) {
                  vm.recurringCommissionList.push(commission);
                }
              });
            }
          });
      };

      vm.translateToOneOffCommissionViewModel = (commission) => {
        if (commission) {
          const product = _.find(vm.productList, (obj) => {
            return obj.id === commission.loanStructureID;
          });

          const refixDate = commission.refixDate || null;

          return {
            ...commission,
            productId: product ? product.id : 0,
            refixDate,
            isEdit: false,
            rate: parseFloat(commission.rate).toFixed(3),
          };
        }
        return null;
      };

      vm.translateToRecurringCommissionViewModel = (commission) => {
        if (commission) {
          commission.isEdit = false;
          vm.recurringCommissionList.push(commission);
        } else {
          return null;
        }
      };

      const loadProducts = () => {
        return contactService
          .loanCommissionLoanStructuresGet(loanInformationService.loanId)
          .then((response) => {
            vm.productList =
              response.data &&
              response.data.map((product) => {
                return {
                  id: product.LoanStructureID,
                  name: product.ProductName || 'Others',
                  amount: product.Amount,
                };
              });
          });
      };

      const loadFrequencies = () => {
        return optionsService.FinancialFrequency().then((response) => {
          vm.frequencyList =
            response.data &&
            response.data.map((frequency) => {
              return {
                id: parseInt(frequency.Value, 10),
                name: frequency.Name,
              };
            });
        });
      };

      const loadCommissionTypes = () => {
        return commissionService.commissionsTypeGet().then((response) => {
          vm.typeListObj = response.data.reduce((accum, type) => {
            accum[parseInt(type.Value, 10)] = type.Name;
            return accum;
          }, {});

          vm.typeList =
            response.data &&
            response.data.reduce((accum, type) => {
              if (isIncludeCommissionType({ configService, type })) {
                accum.push({
                  id: parseInt(type.Value, 10),
                  name: type.Name,
                });
              }
              return accum;
            }, []);
        });
      };

      function init() {
        vm.lenderId = loanInformationService.loanInformation.Lender.LenderId;
        if (vm.lenderId) {
          contactService
            .oneOffCommissionRateGet(vm.lenderId)
            .then((response) => {
              vm.oneOffCommissionRates = response;
            });
        }

        const promises = [
          loadProducts(),
          loadFrequencies(),
          loadCommissionTypes(),
        ];

        $q.all(promises).then(() => {
          const commissionTabWatcher = $scope.$watch('commissionTab', () => {
            vm.loadCommissions($scope.commissionTab);
          });
          $scope.$on('$destroy', () => {
            commissionTabWatcher();
          });
        });
      }
      init();
    });
