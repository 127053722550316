export default class ProviderKeyContactRequest {
  constructor(dataType) {
    this.data = dataType;
  }

  populateKeyContacts(dataCallback) {
    this.data.providerInformationService
      .getKeyRelationship(this.idSwitcher())
      .then((contacts) => {
        dataCallback(contacts);
      });
  }

  populateStates(statesData) {
    this.data.providerInformationService.reportingStatesGet().then((states) => {
      statesData(states);
    });
  }

  onSaveKeyContacts(data, callBack) {
    data.providerId = this.idSwitcher();
    this.data.providerInformationService
      .saveKeyContact(data)
      .then((reponse) => {
        callBack(this.responseStatus(reponse));
      });
  }

  deleteKeyContact(keyRelationshipId, callBack) {
    this.data.providerInformationService
      .deleteKeyContact(keyRelationshipId)
      .then((reponse) => {
        callBack(this.responseStatus(reponse));
      });
  }

  deleteConfirmation() {
    this.confirm = {
      title: 'Are you sure?',
      text: 'This record will be removed from your list',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F68F8F',
      confirmButtonText: 'Yes, remove it!',
      closeOnConfirm: false,
    };
    return this.confirm;
  }

  setDefaultStateFilter(state) {
    this.defaulState = this.data.currentUserService.reportingStateName;
    if (!this.defaulState) {
      this.defaulState = state;
    }
    return this.defaulState;
  }

  responseStatus(reponse) {
    this.success = false;
    if (reponse.status === 200 && reponse.data) {
      this.success = true;
    }
    return this.success;
  }

  idSwitcher() {
    const lenderId = this.data.providerData.lenderId;
    const insurerId = this.data.providerData.insurerId;
    return insurerId === 0 ? lenderId : insurerId;
  }

  splitRegionService(paramRegion) {
    const paramReg = paramRegion.split('/') || [];
    const regList =
      this.data.unfilteredKeyContacts.map((contact) => {
        if (!contact.region) {
          return [];
        }

        return contact.region.split('/').join(', ').split(', ');
      }) || [];

    return [paramReg, regList];
  }

  filterKeyContacts(region) {
    if (!region) {
      return;
    }
    const [splitParamRegion, splitRegionList] = this.splitRegionService(region);

    return this.data.unfilteredKeyContacts.filter((contact, key) => {
      if (!contact.region) {
        return false;
      }
      return splitParamRegion.some((param) =>
        splitRegionList[key].includes(param),
      );
    });
  }

  forAUonly() {
    this.forAUuser = false;
    if (this.data.uiService.isCountry('Australia')) {
      this.forAUuser = true;
    }
    return this.forAUuser;
  }

  forCorpUseronly(corpUser) {
    return this.data.currentUserService.accessType !== corpUser;
  }
}
