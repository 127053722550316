export const MAX_TABLE_ROWS = 20;

export const DUE_DATE_TYPES = {
  ALL: 'all',
  OVERDUE: 'overdue',
  TODAY: 'today',
  TODAY_AND_OVERDUE: 'today_and_overdue',
  TOMORROW: 'tomorrow',
  LATER: 'later',
  THIS_WEEK: 'this_week',
  THIS_MONTH: 'this_month',
  COMPLETED: 'complete',
};
export const DEFAULT_DUE_DATE_TYPE = DUE_DATE_TYPES.TODAY;

export const DEFAULT_FILTERS = {
  dueDateType: DEFAULT_DUE_DATE_TYPE,
  assignedBrokerIds: '',
  clientAdviserIds: '',
  createdByBrokerId: 0,
  lenderId: '0',
  sortColumn: 'DueDate',
  sortOrder: 'asc',
};

export const DUE_DATE_TYPE = [
  { value: DUE_DATE_TYPES.ALL, name: 'All' },
  { value: DUE_DATE_TYPES.TODAY, name: 'Today' },
  { value: DUE_DATE_TYPES.TODAY_AND_OVERDUE, name: 'Today + Overdue' },
  { value: DUE_DATE_TYPES.THIS_WEEK, name: 'This Week' },
  { value: DUE_DATE_TYPES.THIS_MONTH, name: 'This Month' },
  { value: DUE_DATE_TYPES.OVERDUE, name: 'Overdue' },
];

export const TASK_STATUS = [
  { value: DUE_DATE_TYPES.ALL, name: 'All' },
  { value: DUE_DATE_TYPES.OVERDUE, name: 'Overdue' },
  { value: DUE_DATE_TYPES.TODAY, name: 'Due today' },
  { value: DUE_DATE_TYPES.TOMORROW, name: 'Due tomorrow' },
  { value: DUE_DATE_TYPES.LATER, name: 'Due later' },
  { value: DUE_DATE_TYPES.TODAY_AND_OVERDUE, name: 'Today + Overdue' },
  { value: DUE_DATE_TYPES.COMPLETED, name: 'Complete' },
];

export const MULTI_TASK_STATUS = [
  { value: DUE_DATE_TYPES.ALL, name: 'All' },
  { value: DUE_DATE_TYPES.OVERDUE, name: 'Overdue' },
  { value: DUE_DATE_TYPES.TODAY, name: 'Today' },
  { value: DUE_DATE_TYPES.TOMORROW, name: 'Tomorrow' },
  { value: DUE_DATE_TYPES.THIS_WEEK, name: 'This week' },
  { value: DUE_DATE_TYPES.THIS_MONTH, name: 'This month' },
  { value: DUE_DATE_TYPES.COMPLETED, name: 'Completed' },
];

export const STATUS_BY_DATE_KEY = [
  { key: 'IsOverDue', text: 'Overdue' },
  { key: 'IsDueToday', text: 'Today' },
  { key: 'IsDueTomorrow', text: 'Due tomorrow' },
  { key: 'IsDueThisWeek', text: 'Due later' },
  { key: 'IsDueLater', text: 'Due later' },
  { key: 'IsCompleted', text: 'Completed' },
];

export const TASK_COLUMN_KEYS = {
  CREATED_BY: 'CreatedBy',
  LENDER: 'Lender',
};

export const TASKS_COLUMNS = {
  TASK: { value: 'ActivityDetails', name: 'Task' },
  STATUS: { value: 'Status', name: 'Status', isHtml: true },
  DUE_DATE: { value: 'DueDate', name: 'Due date' },
  CLIENT_NAME: { value: 'ClientName', name: 'Client name' },
  ASSIGNED_TO: { value: 'AssignedTo', name: 'Assigned to' },
  DEAL_NAME: { value: 'DealName', name: 'Deal name' },
  CONTACT_NUM: { value: 'Contact', name: 'Contact #' },
  CREATED_BY: { value: TASK_COLUMN_KEYS.CREATED_BY, name: 'Created by' },
  CREATED_DATE: { value: 'CreatedDate', name: 'Created date' },
  LENDER: { value: TASK_COLUMN_KEYS.LENDER, name: 'Lender', isHtml: true },
};

export const TASK_PAGE_TABLE_COLUMNS = [
  TASKS_COLUMNS.STATUS,
  TASKS_COLUMNS.DUE_DATE,
  TASKS_COLUMNS.CLIENT_NAME,
  TASKS_COLUMNS.ASSIGNED_TO,
  TASKS_COLUMNS.DEAL_NAME,
  TASKS_COLUMNS.CONTACT_NUM,
  TASKS_COLUMNS.CREATED_BY,
  TASKS_COLUMNS.CREATED_DATE,
  TASKS_COLUMNS.LENDER,
];

export const TASK_OPPORTUNITY_TABLE_COLUMNS = [
  TASKS_COLUMNS.TASK,
  TASKS_COLUMNS.STATUS,
  TASKS_COLUMNS.DUE_DATE,
  TASKS_COLUMNS.CLIENT_NAME,
  TASKS_COLUMNS.ASSIGNED_TO,
  { ...TASKS_COLUMNS.CREATED_DATE, name: 'Created' },
];
