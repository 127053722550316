export const CONTENT_VIEWS = {
  DEFAULT: 'Menu',
  CHAT: 'Chat',
  PHONE: 'Phone',
  REPORT: 'Report',
  ENHANCEMENT: 'Enhancement',
  SUPPORT: 'Support',
};

export const HELP_NUMBER = {
  AU: '07 3231 2283',
  NZ: '0508 8787 88',
  AU_NEW: '1300 564 357',
};

export const REDIRECT_LINKS = {
  [CONTENT_VIEWS.ENHANCEMENT]: 'app.cannyEnhancements.list',
  [CONTENT_VIEWS.SUPPORT]: 'app.brokerSupportRequest',
};

export const MENU_TITLE = 'MyCRM Help';
