import angular from 'angular';

export const link = (scope, elem, attr, dependencies) => {
  const { $timeout, $compile } = dependencies;
  const ellipsisStyle = `
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: ${attr.ellipsis || 1};
    -webkit-box-orient: vertical;
    `;
  elem.attr('style', ellipsisStyle);

  const { content } = scope;
  const isDependenciesDeclared = content && $timeout && $compile;
  if (!isDependenciesDeclared) {
    return;
  }
  const readMoreTxt = 'Read More...';
  const showLessTxt = 'Show Less...';
  elem[0].textContent = content;

  scope.openReadMore = ($e) => {
    $e && $e.stopPropagation();

    const pElem = angular.element(elem[0].childNodes[0]);
    scope.isShowLess = pElem[0].style.webkitLineClamp;
    if (scope.isShowLess) {
      pElem.removeAttr('style');
      return;
    }
    pElem.attr('style', ellipsisStyle);
  };

  scope.appendReadMore = () => {
    elem.removeAttr('style');
    scope.isShowLess = false;
    const readMore = $compile(
      `<p style="${ellipsisStyle}"></p> <a ng-click="openReadMore($event)">{{ isShowLess ? '${showLessTxt}' : '${readMoreTxt}'  }}</a>`,
    )(scope);
    readMore[0].textContent = content;
    elem.append(readMore);
  };

  scope.init = () => {
    const timer = $timeout(() => {
      if (elem[0].offsetHeight === elem[0].scrollHeight) {
        return;
      }
      elem[0].textContent = '';
      scope.appendReadMore();
      $timeout.cancel(timer);
    }, 1000);
  };
  scope.init();
};
