export const processLabelDisplay = ({
  configService,
  currentUserService,
  element,
}) => {
  const { assetFinance: assetFinanceFlag } = configService.feature;
  const { isAU, isNZ } = currentUserService;
  const allowedCountryAccess = isAU || isNZ;
  const isShowLabel = assetFinanceFlag && allowedCountryAccess;
  const className = 'hidden';
  !isShowLabel && element.addClass(className);
};
