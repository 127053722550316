import { buildCommonHttpHeader } from 'Common/config/http';
import { getToken } from 'Common/utilities/token';

class HttpService {
  constructor($http, currentUserService) {
    'ngInject';

    this.$http = $http;
    this.currentUserService = currentUserService;
  }

  setHttpCommonHeader(token, isAuthorizationOnly = false) {
    const headers = {
      'mycrm-aggregatorId': this.currentUserService?.aggregator?.id,
      'mycrm-region': this.currentUserService?.languageRegion,
    };
    const bearerToken = token ? `Bearer ${token}` : getToken();

    this.$http.defaults.headers.common = buildCommonHttpHeader(
      bearerToken,
      isAuthorizationOnly,
      headers,
    );
  }
}

export default HttpService;
