import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('MobileSelectModalCtrl', function MobileSelectModalCtrl(
      $scope,
      $uibModalInstance,
    ) {
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    });
