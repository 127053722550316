export default class ContactNameCellCtrl {
  constructor(configService) {
    'ngInject';

    this.configService = configService;
  }

  $onInit() {
    this.pipelineV2P1 =
      this.configService.feature.pipelineV2P1 && this.isApplication;
  }

  goto({ $e }) {
    if (!this.pipelineV2P1) {
      return;
    }
    const redirectToLoanApp = !!this.cardDetails.loanScenarioID;
    this.onRowClick({ $e, redirectToLoanApp });
  }
}
