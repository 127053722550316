function requireAll(requireContext) {
  requireContext.keys().forEach((filePath) => {
    // load the component
    const module = requireContext(filePath);
    if (typeof module.loadModule === 'function') {
      module.loadModule();
    }
  });
}

export function loadModules() {
  requireAll(require.context('./models', false, /.*\.js/));
  requireAll(require.context('./directives', true, /.*\.js/));
  requireAll(require.context('./services', true, /.*\.js/));
  requireAll(require.context('./filters', true, /.*\.js/));
  requireAll(require.context('./controllers', true, /.*\.js/));
}
