import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('InsAppFinancialCtrl', function InsAppFinancialCtrl(
      $rootScope,
      $scope,
      $timeout,
      $interval,
      $compile,
      contactService,
      loanScenarioService,
      loanApplicationServices,
      toaster,
      $state,
      $stateParams,
      utilitiesService,
      $uibModal,
    ) {
      $scope.loanAppId = $stateParams.loanAppId;
      $scope.familyId = $stateParams.familyId;

      // Add Expenses Modal
      $scope.newExpensesModal = function (type) {
        $scope.accountType = type;
        $uibModal.open({
          templateUrl:
            '/assets/views/loanApplication/clientAssess/modal/newExpensesModal.html',
          controller: 'PreAssessExpensesModalCtrl',
          size: 'sm',
          scope: $scope,
        });
      };
    });
