import template from './mycrmToggle.html';

export default {
  template,
  bindings: {
    value: '<',
    isFocused: '<',
    hideLabel: '<',
    onToggle: '&',
  },
  controllerAs: 'vm',
};
