import angular from 'angular';
import scenes from './scenes';
import AccreditationAPIService from './services/accreditationAPIService';
import AccreditationUtilityService from './services/accreditationUtilityService';
import { ACCREDITATION } from './constants';

export default angular
  .module('scenes.adviserAccreditationTabs', [scenes])
  .constant('constants', ACCREDITATION)
  .service('accreditationService', AccreditationAPIService)
  .service('accreditationUtilityService', AccreditationUtilityService).name;
