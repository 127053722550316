import template from './assetWidgetHeader.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    mainTitle: '@',
  },
};
