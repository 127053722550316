import template from './addFundingBreakdownItem.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    value: '<',
    onSaveFundingParameters: '&',
  },
};
