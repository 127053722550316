import template from './customerCareExportButtons.html';
import controller from './customerCareExportButtonsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    disableSelectedExportButton: '<',
    disableExportAllButton: '<?',
    exportService: '<',
    requiredParams: '<',
    exportIdString: '@',
  },
};
