import { emailType, mailType } from '../../constants/emailMailTemplates';

export default class BusinessEmailMailTemplatesCtrl {
  $onInit() {
    this.emailType = emailType;
    this.mailType = mailType;

    if (!this.selectedType) {
      this.selectedType = emailType;
    }
    this.registerChildHandler = this.registerChildHandler.bind(this);
  }

  onChangeType({ type }) {
    this.selectedType = type;
  }

  registerChildHandler(handler) {
    this.childHandler = handler;
  }

  onSearch(search) {
    this.childHandler?.('search', search);
  }
}
