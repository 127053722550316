import { CLIENT_ROLE_TYPE } from 'Common/constants/roleType';
import { REFERRAL_TYPE } from 'Common/constants/referralType';
import { CONTACT_DETAILS_TAB_TEMPLATE } from 'Common/constants/template';
import { SERVICE_OPTION } from 'Common/constants/helloBook';
import { GOOGLE_MAP_SEARCH } from 'Common/constants/links';
import { toastError, toastWarning, toastSuccess } from 'Common/utilities/alert';
import { camelize } from 'Common/utilities/string';
import get from 'lodash/get';

export const setFamilyContactSharedData = ({
  contactSharedData,
  familyId,
  persons = [],
}) => {
  contactSharedData.familyData = {
    IsClient: true,
    FamilyId: familyId,
    Persons: persons,
    AllocatedAdviserID: persons.length && persons[0].AllocatedAdviserID,
  };
};

export const getClientInformationInContact = ({
  $scope,
  configService,
  clientInfoService,
  contactSharedData,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => (apiService, clientsData) => {
  if (!$scope.familyId) {
    return;
  }
  const VALID_DIVORCED_ADULT_COUNT = 2;
  const { contactSplit } = configService.feature;
  apiService.clientInformGet($scope.familyId).then((response) => {
    const { data } = response;
    if (!data) {
      return;
    }
    const clientsDataCopy = clientsData || data;

    setFamilyContactSharedData({
      contactSharedData,
      familyId: $scope.familyId,
      persons: data,
    });

    if (contactSplit) {
      $scope.summaryData = clientInfoService.buildSummary(clientsDataCopy);
      const { Adults } = $scope.summaryData;

      $scope.showContactSplit =
        Adults && Adults.length === VALID_DIVORCED_ADULT_COUNT;
    }

    $scope.client = {
      information: data,
    };

    const primaryClient = $scope.client.information.filter(
      (item) => item.isPrimary,
    );
    $scope.aLIQuoteEnabled = primaryClient.length
      ? primaryClient[0].IsAllocatedAdviserALIQuoteEnabled
      : false;

    clientInfoService.getClientHeader($scope.client, true);
    $scope.clientHeaderName = $scope.client.headerName;
    $scope.clientInitials = $scope.client.initials;
    const [clientInfo] = data;
    $scope.clientFullName = (clientInfo && clientInfo.FullName) || '';

    $scope.contactModel.isSmsEnabled = false;
    if ($scope.client.information.length) {
      let mobileCount = 0;
      $scope.client.information.forEach((contact) => {
        const filteredMobile = contact.Phone.filter(
          (value) => value.Type === 'Mobile',
        );
        mobileCount += filteredMobile.length;
      });
      $scope.contactModel.isSmsEnabled = mobileCount || false;
    }
    if (!$scope.showContactSplit || !data || !data.length) {
      return;
    }
    $scope.adults = data.filter(
      (client) => client.Role === CLIENT_ROLE_TYPE.ADULT,
    );
  });
};

export const checkForDeceased = (clientList, crmConfirmation, featureFlags) => {
  const { alertIfContactHasBeenMarkedDeceased } = featureFlags;
  if (!alertIfContactHasBeenMarkedDeceased) {
    return false;
  }

  const hasDeceased = clientList.filter((x) => x.Deceased);
  if (!hasDeceased.length) {
    return false;
  }

  crmConfirmation.open({
    type: 'info',
    title: 'Sensitivity Required',
    description: `There are 1 or more deceased people in this record.`,
    showCancelButton: false,
    modalSize: 'md',
    cancelButtonClass: 'colored',
    cancelButtonText: 'Return to Contact',
    isButtonMaxWidth: true,
  });
};

export const templateLoader = ({ $scope, value }) => {
  $scope.template[value] = CONTACT_DETAILS_TAB_TEMPLATE[value];
};

export const isActiveCreditContactUtil = ({
  configService,
  currentUserService,
}) => {
  const { creditCheckHistory } = configService.feature;
  const { isAU } = currentUserService;
  return creditCheckHistory && isAU;
};

export const getAgencies = ({
  $scope,
  sendReferralService,
  currentUserService,
}) => {
  sendReferralService.referralReferralTypeGet().then(({ data }) => {
    if (!data || !data.length) {
      return;
    }
    if (currentUserService.isAU) {
      const restrictedList = [
        REFERRAL_TYPE.UK_PENSION,
        REFERRAL_TYPE.F_AND_G,
        REFERRAL_TYPE.OFX,
      ];
      $scope.agencyList = data.filter(
        (agency) => !restrictedList.includes(agency.ReferralTypeID),
      );
      return;
    }
    $scope.agencyList = data;
  });
};

export const openSendReferralModal = ({
  $scope,
  $uibModal,
  crmConfirmation,
  $state,
}) => {
  const {
    ClientID,
    FamilyId,
    FirstName,
    LastName,
    PreferredName,
  } = $scope.clientInfo;
  $scope.hideNonMycrm = true;
  $scope.initialClientInfo = {
    ClientEntityId: ClientID,
    FamilyID: FamilyId,
    FirstName,
    LastName,
    PreferredName,
    IsClient: true,
  };
  const modalInstance = $uibModal.open({
    templateUrl: '/assets/views/referrals/modal/referral.main.html',
    backdrop: false,
    keyboard: false,
    windowClass: 'newReferral-modal',
    controller: 'SendReferralModalCtrl',
    scope: $scope,
  });

  modalInstance.result.then(() => {
    crmConfirmation.open({
      type: 'success',
      title: 'Referral Sent',
      description: `Thanks for supporting your business partners. To see the list of referrals that you have sent and monitor progress (where available), click below to go the Referrals section or select to return to the Contact record.`,
      buttonText: `Go to Referrals`,
      onConfirm: () => {
        $state.go('app.sendReferrals');
      },
      showCancelButton: true,
      modalSize: 'md',
      cancelButtonClass: 'colored',
      cancelButtonText: 'Return to Contact',
      isButtonMaxWidth: true,
    });
  });
};

export const sendReferral = ({
  $scope,
  $uibModal,
  contactService,
  errorMessageHandler,
  crmConfirmation,
  $state,
}) => () => {
  const { ClientID, FamilyId } = $scope.clientInfo;
  contactService.clientInformGet(FamilyId, ClientID).then(({ data }) => {
    if (!data || !data.length) {
      return;
    }
    const { Phone } = data[0] || {};
    const clientPhone = Phone && Phone.find((phone) => phone.Number);
    const validPhone = clientPhone && clientPhone.Number;
    if (!validPhone) {
      errorMessageHandler.sendReferralError('referral-invalid-contactDetail');
      return;
    }
    openSendReferralModal({ $scope, $uibModal, crmConfirmation, $state });
  });
};

export const setContactHomeAddress = ({ $scope }) => (contactAddress) => {
  if (!$scope) {
    return;
  }
  $scope.contactHomeAddress = contactAddress;
  $scope.formattedHomeAddress = contactAddress.replace(/,/g, '\n');
  const gmapLink = `${GOOGLE_MAP_SEARCH}${encodeURIComponent(contactAddress)}`;
  $scope.contactHomeAddressGmapLink = gmapLink;
};

export const setUtmValue = ({
  $scope,
  $timeout,
  contactSharedDataService,
  data,
}) => {
  $scope.overrideContactCache = false;
  const utmTimeoutInstance = $timeout(
    () => contactSharedDataService.setUtmDetailsDisplay(data.ReferralItemId),
    300,
  );
  $scope.$on('$destroy', () => $timeout.cancel(utmTimeoutInstance));
};

export const sendHelloBookUtil = ({ vm, helloBookPreviewService }) => {
  const { isLoanWriter, isRiskWriter, isNZ, isAU } = vm.designatedBrokerInfo;
  const isToShowServiceOptions = isLoanWriter && isRiskWriter && isNZ;
  if (isToShowServiceOptions) {
    helloBookPreviewService
      .openServiceOptionModal({ action: 'send' })
      .result.then((result) => {
        vm.openSendHelloBook({
          isForLoanWriters: result.selectedService === SERVICE_OPTION.LENDING,
          selectedService: result.selectedService,
          toShowFactFind: result.selectedService === SERVICE_OPTION.LENDING,
        });
      });
    return;
  }

  let selectedService = ``;
  let isForLoanWritersValue = null;
  if (isLoanWriter && !isRiskWriter && isNZ) {
    selectedService = SERVICE_OPTION.LENDING;
    isForLoanWritersValue = true;
  }
  if (isRiskWriter && !isLoanWriter && isNZ) {
    selectedService = SERVICE_OPTION.INSURANCE;
    isForLoanWritersValue = false;
  }
  vm.openSendHelloBook({
    isForLoanWriters: isForLoanWritersValue,
    selectedService,
    toShowFactFind: selectedService === SERVICE_OPTION.LENDING || isAU,
  });
};

export const generateHelloBookUtil = ({
  isForLoanWriters,
  selectedService,
  vm,
  $uibModal,
  $timeout,
  helloBookService,
  downloadDataService,
}) => {
  if (vm.generatingHelloBook) {
    toastWarning(
      'Processing...',
      `Still generating ${selectedService} Hello Book pdf`,
    );
    return;
  }

  const $modalInstance = $uibModal.open({
    template: `<hello-book-loading-modal
      modal-instance="vm.modalInstance"
      action="'generate'"
      show-close="true"
    ></hello-book-loading-modal>`,
    controller: 'CommonModalPlaceholderCtrl',
    controllerAs: 'vm',
    keyboard: false,
    backdrop: 'static',
    size: 'lg',
    resolve: {
      props: () => {},
    },
    windowClass: 'hello-book-loading-modal',
  });

  const generateTimeout = $timeout(() => {
    vm.generatingHelloBook = true;
    helloBookService
      .generate(vm.familyId, {
        isForLoanWriter: isForLoanWriters,
      })
      .then((response) => {
        if (!response || !response.documentContent) {
          vm.generatingHelloBook = false;
          $timeout.cancel(generateTimeout);
          toastError();
          return;
        }

        const { contentType, documentContent } = response;
        const clientFileName = camelize(vm.clientHeaderName).replace(' ', '');
        downloadDataService.download(
          `data:${contentType};base64,${documentContent}`,
          `hello_book_${clientFileName}.pdf`,
          contentType,
        );
        vm.generatingHelloBook = false;
        toastSuccess({ messages: ['Hello Book generated successfully!'] });
        $modalInstance.close();
      });
  });

  vm.$on('$destroy', () => $timeout.cancel(generateTimeout));
};

export const checkGenerateHelloBookUtil = ({
  vm,
  helloBookPreviewService,
  $uibModal,
  $timeout,
  helloBookService,
  downloadDataService,
}) => {
  const { isLoanWriter, isRiskWriter, isNZ } = vm.designatedBrokerInfo;
  const isToShowServiceOptions = isLoanWriter && isRiskWriter && isNZ;
  if (isToShowServiceOptions) {
    helloBookPreviewService
      .openServiceOptionModal({ action: 'generate' })
      .result.then((result) => {
        generateHelloBookUtil({
          isForLoanWriters: result.selectedService === SERVICE_OPTION.LENDING,
          selectedService: result.selectedService,
          vm,
          $uibModal,
          $timeout,
          helloBookService,
          downloadDataService,
        });
      });
    return;
  }

  let selectedService = ``;
  let isForLoanWritersValue = `null`;
  if (isLoanWriter && !isRiskWriter && isNZ) {
    selectedService = SERVICE_OPTION.LENDING;
    isForLoanWritersValue = true;
  }
  if (isRiskWriter && !isLoanWriter && isNZ) {
    selectedService = SERVICE_OPTION.INSURANCE;
    isForLoanWritersValue = false;
  }
  generateHelloBookUtil({
    isForLoanWriters: isForLoanWritersValue,
    selectedService,
    vm,
    $uibModal,
    $timeout,
    helloBookService,
    downloadDataService,
  });
};

export const createClientInformRequest = (contactService) => ({
  familyId,
  clientId,
  clientName,
}) => {
  if (!familyId || !clientId || !contactService) {
    return Promise.resolve(null);
  }
  return contactService
    .clientInformGet(familyId, clientId)
    .then(({ data = [] }) => {
      const personIds = data.map((client) => client.PersonId);
      const allocatedAdviserId = get(data, '0.AllocatedAdviserID', 0);
      return {
        familyId,
        clientName,
        personIds,
        allocatedAdviserId,
      };
    });
};

export const handleClientInformData = (params, contactService) => ({
  sent,
  onlineFactFind,
}) => {
  if (!sent || !contactService || !onlineFactFind) {
    return Promise.resolve(null);
  }
  const fetchClientInformGet = createClientInformRequest(contactService);
  return fetchClientInformGet(params);
};

export const handleFactFindModal = (viewOfFileModalService) => (params) => {
  if (!params || !viewOfFileModalService) {
    return Promise.resolve(null);
  }
  return viewOfFileModalService.launchCreateFactFindConfirmation(params);
};
