import template from './opportunityCalculationItem.html';
import controller from './opportunityCalculationItemCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    calculation: '<',
    isDeletable: '<',
  },
};
