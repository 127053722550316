import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('dynamicVideoSource', function dynamicVideoSource($window) {
      return {
        restrict: 'A',
        link: (scope, element, attr) => {
          scope.$watch(
            () => {
              return attr.dynamicVideoSourceUrl;
            },
            (newValue) => {
              const sourceDom = $window.document.createElement('source');

              sourceDom.src = newValue;
              sourceDom.type = 'video/mp4';

              element.html('');
              element.append(sourceDom);

              element.load();
            },
          );
        },
      };
    });
