import template from './opportunityMain.html';
import controller from './opportunityMainCtrl';

export default {
  template,
  controller,
  bindings: {
    parentToChildNotificationRegistration: '&',
    activeTab: '<',
    opportunityDetails: '<',
    onRefreshDetails: '&',
    opportunityId: '<',
    opportunityTitle: '<',
    selectedAdviserId: '<',
    onAdviserChange: '&',
    loanId: '<',
    familyId: '<',
    familyList: '<',
    adviserId: '<',
    opportunityGeneral: '<',
    onChangeGeneralInfo: '&',
    isNotAssetFinance: '<',
    loanAppOnOpportunities: '<',
    isFactFindReady: '<',
    tabProperties: '<',
    onChangeTab: '&',
    applicants: '<',
  },
};
