import moment from 'moment';
import { set } from 'lodash';

export const customDateUtcFormat = ({ scope, elem, attrs, ctrl }) => {
  const inputEvents = ['blur', 'keyup'];

  if (!ctrl) {
    return;
  }

  const { 0: firstElem } = elem;
  if (!firstElem) {
    return;
  }

  inputEvents.forEach((inputEvent) => {
    firstElem.addEventListener(inputEvent, () => {
      if (!ctrl.$modelValue) {
        return;
      }

      const modelKey = attrs.customDateUtcFormat || attrs.ngModel;
      const dateFormatToUTC = 'DD MMM YYYY 00:00:00.000+00:00';
      const date = moment(ctrl.$modelValue).format(dateFormatToUTC);
      const newFormattedDate = new Date(date);
      set(scope, modelKey, newFormattedDate);
    });
  });
};
