import swal from 'sweetalert';
import toastr from 'toastr';
import toastrConfig from 'Common/config/toastr';
import { splitByCapitalLetters } from 'Common/utilities/string';

export const alertError = (message, autoClose, title) => {
  swal({
    title,
    text: message,
    type: 'error',
    timer: autoClose ? 2000 : null,
    showConfirmButton: !autoClose,
  });
};

export const alertSucess = (alertTitle, message, autoClose) => {
  swal({
    title: alertTitle || 'Success',
    text: message,
    type: 'success',
    timer: autoClose ? 2000 : null,
    showConfirmButton: !autoClose,
  });
};

export const displayError = (
  e,
  autoClose = true,
  title = 'Yikes! Something is wrong',
) => {
  const messages = e && e.messages ? e.messages : ['Please try again.'];
  if (typeof e === 'string') {
    return alertError(e, autoClose, title);
  }
  return alertError(messages.join('\n'), autoClose, title);
};

export const alertInfo = (message, title = 'Just a heads up', autoClose) => {
  swal({
    title,
    text: message,
    type: 'info',
    timer: autoClose ? 2000 : null,
    showConfirmButton: !autoClose,
  });
};

export const displayInfo = (e, title, autoClose = false) => {
  const messages = e && e.messages ? e.messages : ['Please try again.'];
  if (typeof e === 'string') {
    return alertInfo(e, title, autoClose);
  }
  return alertInfo(messages.join('\n'), title, autoClose);
};

export const displayWarning = (
  message,
  alertTitle,
  autoClose = true,
  customClass,
) => {
  swal({
    title: alertTitle,
    text: message || 'Warning',
    type: 'warning',
    timer: autoClose ? 2000 : null,
    customClass: customClass || '',
    showConfirmButton: !autoClose,
  });
};

export const displaySuccess = (message, autoClose = true) => {
  if (typeof message === 'string') {
    return alertSucess(null, message, autoClose);
  }
};

export const toastError = (e, title = 'Error') => {
  toastr.options = toastrConfig;
  const messages = e && e.messages ? e.messages : ['Yikes! Something is wrong'];
  if (typeof e === 'string') {
    return toastr.error(e, title);
  }
  return messages.forEach((message) => toastr.error(message, title));
};

export const toastMsgFromObjKeys = (errorObject) => {
  if (!errorObject || !Object.keys(errorObject).length) {
    return;
  }
  return Object.keys(errorObject).reduce((keyStringList, keyString) => {
    if (!keyString.length) {
      return keyStringList;
    }

    const formattedKeyString = splitByCapitalLetters(
      keyString[0].toUpperCase() + keyString.slice(1),
    );
    const objectValue = errorObject[keyString]
      ? `: ${errorObject[keyString]}`
      : '';
    return `${keyStringList}<br>${`- ${formattedKeyString}${objectValue}`}`;
  }, '');
};

export const toastErrorUsingObject = (errorObject, title = 'Error') => {
  if (!errorObject || !Object.keys(errorObject).length) {
    return;
  }

  const message = toastMsgFromObjKeys(errorObject);
  toastError(message, title);
};

export const toastWarning = (message, title) => {
  toastr.options = toastrConfig;
  toastr.warning(message, title || 'Warning');
};

export const toastSaveSuccess = (toasterService) => {
  toastr.options = toastrConfig;
  if (toasterService) {
    toasterService.pop('success', 'Success', 'Successfully Saved!');
  } else {
    toastr.success('Successfully Saved!', 'Success');
  }
};

export const toastSaveDelete = (toasterService) => {
  toastr.options = toastrConfig;
  if (toasterService) {
    toasterService.pop('success', 'Success', 'Successfully Deleted!');
  } else {
    toastr.success('Successfully Deleted!', 'Success');
  }
};

export const showActionConfirmation = (
  confirmationTitle,
  confirmationMessage,
  onActionConfirmed,
  confirmButtonText = 'Yes',
) => {
  swal(
    {
      title: confirmationTitle,
      text: confirmationMessage,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText,
      closeOnConfirm: true,
    },
    onActionConfirmed,
  );
};

export const toastSuccess = (e, title = 'Success') => {
  toastr.options = toastrConfig;
  const messages = e && e.messages ? e.messages : ['Operation successful'];
  if (typeof e === 'string') {
    return toastr.success(e, title);
  }
  return messages.forEach((message) => toastr.success(message, title));
};

export const toastInfo = (e, title = 'Notice') => {
  const messages = e && e.messages ? e.messages : ['Operation successful'];
  if (typeof e === 'string') {
    return toastr.info(e, title);
  }
  return messages.forEach((message) => toastr.info(message, title));
};

export const showCustomConfirmation = (
  confirmationTitle,
  confirmationMessage,
  customConfirmButtonText,
  customCancelButtonText,
  customClassName,
  onActionConfirmed,
) => {
  swal(
    {
      title: confirmationTitle,
      text: confirmationMessage,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: customConfirmButtonText,
      closeOnConfirm: true,
      cancelButtonText: customCancelButtonText,
      customClass: customClassName,
    },
    onActionConfirmed,
  );
};
