import template from './dateYearJumper.html';
import controller from './dateYearJumperCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    model: '<',
    config: '<',
    isDisabled: '<',
    onChange: '&',
  },
};
