import template from './commissionExpectedDetails.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    commission: '<',
    modalInstance: '<',
    onDeleteCommission: '&',
    onSendEnquiry: '&',
  },
};
