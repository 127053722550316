import { isMobileValid } from 'Common/utilities/mobile';

export default class FormKeyContactsCtrl {
  constructor(providerInformationService) {
    'ngInject';

    this.providerInformationService = providerInformationService;
    this.keyContact = {};
    this.keyContact.keyRelationshipId = 0;
  }

  $onChanges() {
    this.keyContact = { ...this.keycontactData } || {};
    this.toggleForm = !!this.keycontactData;
    this.setDefaultState();
  }

  onSaveKeyContacts(keyContactData, form) {
    form.$setSubmitted();
    if (!form.$valid) {
      return;
    }
    if (this.onSave && typeof this.onSave === 'function') {
      this.onSave({
        keyContactData,
      });
      this.toggleHideForm(form);
    }
  }

  validateMobile(form) {
    form.mobile.$setValidity(
      'mobile',
      !this.keyContact.mobile || isMobileValid(this.keyContact.mobile),
    );
  }

  setDefaultState() {
    if (!this.keyContact.keyRelationshipId && this.auOnly) {
      this.keyContact.region = this.defaultState;
    }
  }

  toggleHideForm(form) {
    this.keyContact = {};
    this.toggleForm = false;
    this.setDefaultState();
    form.$setPristine();
    form.$setUntouched();
    if (this.onFormHidden) {
      this.onFormHidden();
    }
  }
}
