import {
  SERVICE_ID,
  SERVICE_ID_NZ,
} from 'Common/constants/customerCareModules';

export const SUBSCRIPTIONTYPE = {
  SMS: 2,
  NPS: 3,
  CAMPAIGN_BREEZE: 4,
  BROKERPEDIA: 5,
  CREDIT_CHECK: 6,
  PRICE_FINDER: 7,
  MARKETING_AUTOMATION: 8,
  E_SIGN: 9,
  BANK_CONNECT: 10,
  BANK_CONNECT_EXPENSES_ANALYSIS: 11,
  NURTURE: 12,
  NURTURE_NZ: 14,
  MARKETING_AUTOMATION_NZ: 15,
  ACTIVE_PIPE: 16,
};

export const SIT_TRANSACTIONAL_ITEMS = [
  SERVICE_ID.FIXED_RATE_EXPIRES,
  SERVICE_ID.APPROVAL_EXPIRIES,
  SERVICE_ID.INTEREST_ONLY_EXPIRIES,
  SERVICE_ID_NZ.FIXED_RATE_EXPIRES,
  SERVICE_ID_NZ.APPROVAL_EXPIRIES,
  SERVICE_ID_NZ.INTEREST_ONLY_EXPIRIES,
];
