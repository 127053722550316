import { ACCESS_TYPE } from 'Common/constants/accessTypes';

export default class CustomerCareLockedViewCtrl {
  constructor($state) {
    'ngInject';

    this.$state = $state;
    this.ACCESS_TYPE = ACCESS_TYPE;
  }

  goSubscriptionProfile() {
    this.$state.go('app.users', { activeTab: 'subscriptions' });
  }

  isBroker() {
    return (
      this.userInfo.AccessType === this.ACCESS_TYPE.PRINCIPAL_ADVISER ||
      this.userInfo.AccessType === this.ACCESS_TYPE.ADVISER
    );
  }
}
