import template from './reaSecurities.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    loanAppId: '<',
    loanAppFormWarning: '<',
  },
};
