import template from './accreditationStatusDropdown.html';
// import controller from './accreditationStatusDropdownCtrl';
import './style.scss';

export default {
  template,
  // controller,
  controllerAs: 'vm',
  bindings: {
    provider: '<',
    onChangeStatus: '&',
    showDate: '<',
  },
};
