import template from './onboardingNewParty.html';
import controller from './onboardingNewPartyCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    partyLabel: '<',
    partyIndex: '<',
    partyData: '<',
    isStaff: '<',
    isEdit: '@',
    showWritingOption: '<',
    isSaveComplete: '<',
    saveParty: '&',
    cancel: '&',
    saveOnlyNoInvite: '<',
  },
};
