import { getDatePickerConfig } from 'Common/utilities/date';
import { toastSuccess, toastError } from 'Common/utilities/alert';
import { commissionEnquiryBuilderForAPI } from 'Common/mappers/commission';
import {
  ERROR_MESSAGES,
  SUBMISSION_SUCCESS_MESSAGE,
  SUBMISSION_FAILED_MESSAGE,
} from './constants';

export default class CommissionEnquiryModalCtrl {
  constructor(
    $state,

    currentUserService,
    commissionService,
  ) {
    'ngInject';

    this.$state = $state;
    this.currentUserService = currentUserService;
    this.commissionService = commissionService;
  }

  $onInit() {
    this.datePickerConfig = getDatePickerConfig();
    this.initCommissionEnquiry();
    this.initAdviserOptions();
    this.initLenderOptions();
  }

  initCommissionEnquiry() {
    this.commissionEnquiry = {
      returnEmail: this.currentUserService.email,
      adviserId: 0,
      lenderId: 0,
    };
  }

  initAdviserOptions() {
    this.advisers = [
      { BrokerID: 0, BrokerFullName: 'Please select an adviser' },
      ...this.advisers.slice(1),
    ];
  }

  initLenderOptions() {
    this.lenders = [
      { ProviderId: 0, ProviderName: 'Please select a lender' },
      ...this.lenders.slice(1),
    ];
  }

  dismiss() {
    this.modalInstance.dismiss();
  }

  setClient(client) {
    this.commissionEnquiry.client = client;
  }

  setLender(lender) {
    this.commissionEnquiry.lenderId = lender.ProviderID;
  }

  setLoanAmount(loanAmount) {
    this.commissionEnquiry.loanAmount = loanAmount;
  }

  setNotes(notes) {
    this.commissionEnquiry.notes = notes;
  }

  isInvalidForm() {
    const fields = [
      'adviserId',
      'returnEmail',
      'client',
      'borrowingEntity',
      'lenderId',
      'settlementDate',
      'loanAmount',
      'referenceNumber',
    ];
    return fields.some((f) => this.isInvalidField(f));
  }

  isInvalidField(fieldName) {
    return !this.commissionEnquiry[fieldName];
  }

  showValidation(fieldName) {
    return (
      this.isInvalidField(fieldName) && this.commissionEnquiryForm.$submitted
    );
  }

  showValidationMessage() {
    if (this.isInvalidField('client')) {
      return toastError(ERROR_MESSAGES.MISSING_CLIENT_FIELD);
    }
    toastError(ERROR_MESSAGES.MISSING_REQUIRED_FIELDS);
  }

  sendEnquiry() {
    this.commissionEnquiryForm.$setSubmitted();
    if (this.isInvalidForm()) {
      this.showValidationMessage();
      return;
    }

    this.isSending = true;
    const enquiryToApi = commissionEnquiryBuilderForAPI({
      ...this.commissionEnquiry,
      clientIds: this.commissionEnquiry.client.ClientEntityId,
    });
    this.commissionService
      .sendCommissionEnquiry(enquiryToApi)
      .then(() => {
        toastSuccess(SUBMISSION_SUCCESS_MESSAGE);
        this.isSending = false;
        this.dismiss();
      })
      .catch(() => {
        toastError(SUBMISSION_FAILED_MESSAGE);
        this.isSending = false;
      });
  }
}
