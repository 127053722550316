import { formatRawNewLine } from 'Common/utilities/string';

export function factFindHeadingsBuilderForUI(headers) {
  return {
    id: headers.Id,
    name: headers.Value,
    group: headers.GroupId,
  };
}

export function factFindPricingBuilderForUI(data) {
  return {
    brokerNumber: data.BrokerNumber,
    price: data.Price,
  };
}

export function factFindReviewClientBuilderForUI(client) {
  return (
    client &&
    client.data && {
      clientId: client.data.PersonId,
      fullName: `${client.data.FirstName} ${client.data.LastName}`,
      firstName: client.data.FirstName,
      lastName: client.data.LastName,
      isGuarantor: client.data.IsGuarantor,
    }
  );
}

export function reviewFactFindBuilderForUI(review) {
  return {
    clients:
      (review.FactFindLogs &&
        review.FactFindLogs.map((client) => {
          return {
            clientId: client.ClientId,
            fullName: client.FullName,
            isExistOnFactFind: client.HasExistingFactFindData,
          };
        })) ||
      [],
    link: review.Link,
    goalSetterLink: review.GoalSetterLink,
  };
}

export function factFindGetAnswerForUI(data) {
  if (data.WhatsImportantSection) {
    return {
      yourGoalsSectionValueInLife: data.YourGoalsSection.ValueInLife
        ? formatRawNewLine(data.YourGoalsSection.ValueInLife)
        : '--',
      yourGoalsSectionShortTermGoals: data.YourGoalsSection.ShortTermGoals
        ? formatRawNewLine(data.YourGoalsSection.ShortTermGoals)
        : '--',
      yourGoalsSectionMediumTermGoals: data.YourGoalsSection.MediumTermGoals
        ? formatRawNewLine(data.YourGoalsSection.MediumTermGoals)
        : '--',
      yourGoalsSectionLongTermGoals: data.YourGoalsSection.LongTermGoals
        ? formatRawNewLine(data.YourGoalsSection.LongTermGoals)
        : '--',
      yourGoalsSectionReasonForAdvice: buildReasons(data.YourGoalsSection),
      health: getAnswerByQuestionId(
        data.WhatsImportantSection.WhatsImportantAnswers,
        1,
      ),
      ip: getAnswerByQuestionId(
        data.WhatsImportantSection.WhatsImportantAnswers,
        2,
      ),
      trauma: getAnswerByQuestionId(
        data.WhatsImportantSection.WhatsImportantAnswers,
        3,
      ),
      tpd: getAnswerByQuestionId(
        data.WhatsImportantSection.WhatsImportantAnswers,
        4,
      ),
      life: getAnswerByQuestionId(
        data.WhatsImportantSection.WhatsImportantAnswers,
        5,
      ),
    };
  }
}

function getAnswerByQuestionId(answers, questionNo) {
  var answer = '';

  answers.forEach((question) => {
    if (question.QuestionId === questionNo) {
      answer = question;
      answer.AnswerString = answer.AnswerString
        ? formatRawNewLine(question.AnswerString)
        : '--';
    }
  });
  return answer;
}

function buildReasons(data) {
  var reason = '';
  var goalsBooleanQuestion = [
    { 10: 'Increased debt' },
    { 11: 'Protect my family' },
    { 12: 'Purchased property' },
    { 13: 'Recent catastrophe' },
    { 14: 'Peace of mind' },
    { 15: 'Getting married' },
    { 16: 'Financial security' },
    { 17: 'Starting a family' },
    { 18: 'Secure future goals' },
    { 19: 'High risk hobby' },
    { 20: 'High risk job' },
    { 21: 'other - ' },
  ];

  for (var i in goalsBooleanQuestion) {
    var answer = GetOtherReasons(data, goalsBooleanQuestion[i]);
    if (answer) {
      reason = reason ? reason + ', ' + answer : answer;
    }
  }
  return reason;
}

function GetOtherReasons(data, questionNo) {
  var answer = data.ReasonForAdvice.find(
    (i) => i.QuestionId === JSON.parse(Object.keys(questionNo)[0]),
  );
  if (!!answer && !!answer.AnswerBool) {
    if (JSON.parse(Object.keys(questionNo)[0]) === 21) {
      return data.ReasonsForAdviceOtherDetails
        ? Object.values(questionNo)[0] +
            JSON.stringify(data.ReasonsForAdviceOtherDetails)
        : Object.values(questionNo)[0];
    }
    return Object.values(questionNo)[0];
  }
}
