import angular from 'angular';

// filter to abbreviate frequency
export const loadModule = () =>
  angular.module('app').filter('frequency', function frequency() {
    return function (frequencyVal) {
      const abbv = {
        Weekly: 'week',
        Fortnightly: 'fortnight',
        Monthly: 'mo',
        Quarterly: 'qtr',
        'Half-Yearly': '6mos',
        Yearly: 'yr',
      };
      return abbv[frequencyVal] || null;
    };
  });
