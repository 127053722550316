import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('InsurerAppCtrl', function InsurerAppCtrl(
      $rootScope,
      $scope,
      insuranceAppService,
    ) {
      $scope.insurerAppQuestionList = {};
      function getQuestions() {
        // 5 is insurance app ID, 6 is section id whic detemine on what tab the question belongs
        insuranceAppService.applicationQuestionsGet(5, 6).then((response) => {
          $scope.insurerAppQuestionList = response.data;
        });
      }
      getQuestions();

      function applicationAnswerSet(
        applicationId,
        questionId,
        answerString,
        answerBit,
        answerInt,
      ) {
        insuranceAppService
          .applicationAnswerSet(
            applicationId,
            questionId,
            answerString,
            answerBit,
            answerInt,
          )
          .then(() => {});
      }

      // answer arrangement "applicationId, questionId, answerString, answerBit, answerInt"
      $scope.saveApplicationAnswerSet = function (applicationId, answerObj) {
        if (!answerObj.AnswerBit && answerObj.DisplayOptionID !== 2) {
          answerObj.AnswerString = '';
        }
        applicationAnswerSet(
          applicationId,
          answerObj.QuestionID,
          answerObj.AnswerString,
          answerObj.AnswerBit,
          answerObj.AnswerInt,
        );
      };
    });
