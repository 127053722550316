import moment from 'moment';
import { getMonthList } from 'Common/utilities/month';
import { toastError, toastSuccess } from 'Common/utilities/alert';
import { findIndexWithAttr } from 'Common/utilities/array';
import { annualReviewFeature } from 'Common/utilities/insurancePipeline';
import { getDatePickerConfig } from 'Common/utilities/date';
import {
  DD_MM_YYYY_FORMAT,
  NZ_DATE_INPUT_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';

export default class CommencementDateModalCtrl {
  constructor(contactService, insurancePipelineService) {
    'ngInject';

    this.contactService = contactService;
    this.insurancePipelineService = insurancePipelineService;
    this.cancel = this.cancel.bind(this);
  }

  $onInit() {
    this.isReviewMonth = false;
    this.isLoading = false;
    this.reviewMonthId = 0;
    this.commencementDate = null;
    this.reviewMonth = '';
    this.currentYear = new Date().getFullYear();
    this.monthsList = [];
    this.getMonthsList();
    this.getContactFamilyInfo();
    this.config = getDatePickerConfig();
    this.formatDate = DD_MM_YYYY_FORMAT;
  }

  toggleCalendar() {
    this.isCalendarOpen = !this.isCalendarOpen;
  }

  getContactFamilyInfo() {
    this.contactService.contactFamilyInfoGet(this.familyId).then((response) => {
      const { data } = response;
      if (data) {
        return;
      }

      this.reviewMonth = data.ReviewMonth;
      this.isReviewMonth = !!data.ReviewMonth;
      this.reviewMonthId =
        findIndexWithAttr(this.monthsList, 'name', this.reviewMonth) + 1;
    });
  }

  updateApplicationStatus() {
    if (!this.commencementDate) {
      toastError('Commencement date cannot be empty.');
      return;
    }
    if (!this.reviewMonthId) {
      toastError('Annual Review Month cannot be empty.');
      return;
    }
    const commencementDate = moment(
      this.commencementDate,
      NZ_DATE_INPUT_FORMAT,
    ).format(TO_MYCRM_DATE_FORMAT);
    this.isLoading = true;
    this.insurancePipelineService
      .postCommencementDateAndReviewMonths({
        familyId: this.familyId,
        reviewMonth: this.reviewMonthId,
        quoteId: this.quoteId,
        commencementDate,
      })
      .then(() => {
        toastSuccess('Review month successfully updated.');
        this.save({});
        this.isLoading = false;
      });
  }

  getMonthsList() {
    getMonthList().map((item, index) => {
      const month = {
        name: item,
        id: index,
      };
      if (item !== 'All Months') {
        this.monthsList.push(month);
      }
      return item;
    });
    annualReviewFeature(this);
  }

  cancel() {
    this.modalInstance.close({ isOk: false });
  }

  save(params) {
    this.modalInstance.close({ isOk: true, ...params });
  }
}
