import SaveToContactCtrl from 'Components/insurancesSaveToContact/saveToContactCtrl';

export const openSaveContactModal = async ({
  scope,
  callback,
  type = 'save',
}) => {
  const saveToContactCtrl = new SaveToContactCtrl();
  saveToContactCtrl.$uibModal = scope.$uibModal;
  saveToContactCtrl.formData = scope.formData;
  saveToContactCtrl.toaster = scope.toaster;
  await callback();
  await saveToContactCtrl.clickListener(type);
  return true;
};

export const reportPdf = async ({ scope, type }) => {
  if (scope.showSaveToContactButton) {
    await openSaveContactModal({
      scope,
      type,
      callback: () => {
        scope.generateReportPDF(type);
      },
    });
  } else {
    scope.generateReportPDF(type);
  }
};

export const dynamicLabelPremiums = ({ scope }) => {
  scope.frequencyLabel = scope.frequencyList.find(
    (frequency) => frequency.Value === scope.formData.Frequency,
  ).Key || {
    Key: 'Monthly',
  };
};

export const ageExcluded = (age = []) => {
  const excluded = [50, 60, 75];
  return age.filter((value) => !excluded.includes(value.Value));
};
