import { get } from 'lodash';

export const setAssistantsList = ({ $scope, optionsService }) => (
  adviserId,
) => {
  if (!adviserId) {
    return;
  }
  optionsService.adviserAssistantsGet(adviserId).then(({ data }) => {
    $scope.assistantsList = data;
  });
};

export const setAllocatedIds = ({ $scope }) => {
  const invalidParam = !$scope || !$scope.modalObj;
  if (invalidParam || !$scope.modalObj.isCorporate) {
    return;
  }

  const { selectedAdviser, selectedAssistant } = $scope.modalObj;
  const adviserId = get(selectedAdviser, 'originalObject.FamilyId');
  $scope.ContactSet.AllocatedAdviserID = adviserId || 0;
  const assistantId = get(selectedAssistant, 'originalObject.Value');
  $scope.ContactSet.AllocatedAssistantID = assistantId || 0;
};

export const onAdviserAutocompleteSelect = ({ $scope }) => (data) => {
  const invalidParams = !$scope || !data;
  if (invalidParams) {
    return;
  }
  if ($scope.modalObj) {
    $scope.modalObj.selectedAdviser = data;
    if ($scope.modalObj.selectedAssistant) {
      $scope.modalObj.selectedAssistant = {};
    }
  }
  if ($scope.allocatedAdviser && $scope.allocatedAdviser.adviserSelected) {
    $scope.allocatedAdviser.adviserSelected.FullName = data;
  }
  if ($scope.allocation) {
    $scope.allocation.selectedAssistant = {};
  }
  $scope.$broadcast('angucomplete-alt:clearInput', 'allocated-assistant');
  const adviserId = get(data, 'originalObject.FamilyId');
  $scope.getAssistantsList(adviserId);
};

export const onAdviserSelect = ({ $scope }) => (adviserId) => {
  const invalidParams = !$scope || !adviserId;
  if (invalidParams || $scope.assistantsList.length) {
    return;
  }
  $scope.getAssistantsList(adviserId);
};

export const setAllocatedAdviser = ({ $scope }) => (obj) => {
  const invalidParams = !$scope || !$scope.modalObj || !obj;
  if (invalidParams || !$scope.modalObj.isCorporate) {
    return;
  }
  if (obj.AllocatedAdviserID) {
    const originalObject = {
      FamilyId: obj.AllocatedAdviserID,
      FullName: obj.AllocatedAdviserName,
    };
    $scope.modalObj.selectedAdviser = { originalObject };
  }
  if (obj.AllocatedAssistantID) {
    const initialValue = {
      Value: obj.AllocatedAssistantID,
      Name: obj.AllocatedAssistantName,
    };
    $scope.modalObj.initialAssistant = initialValue;
  }
};

export const getAssistantId = (client) => {
  if (!client) {
    return;
  }
  const assistantId = client.AllocatedAssistantID;
  return assistantId && assistantId.toString();
};
