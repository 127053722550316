import { INSURANCE_PACK_TYPE } from 'Common/constants/helloBook';
import { sendInsuranceApplicationPack } from 'Common/utilities/insurance';
import { DEFAULT_BROCHURE } from 'Common/constants/insuranceOptions';

export const emailQuoteReport = ({
  $scope,
  $state,
  reportPDFdata,
  crmConfirmation,
  broadcastDataService,
  helloBookPreviewService,
}) => {
  $scope.isEmailQuoteReport = reportPDFdata.type === 'quote';

  if (!$scope.isEmailQuoteReport) {
    return;
  }

  broadcastDataService.cancelEmailQuoteReport = () => {
    $scope.cancel();
  };

  $scope.confirmEmailReport = () => {
    const providerName = $scope.getInsurerProviderName(
      $scope.insurerProviderID,
    );
    const data = {
      params: {
        familyId: $state.params.familyId,
        quoteId: $scope.reportSettings.quoteId,
        provider: {
          id: $scope.insurerProviderID,
          name: providerName,
        },
        packType: INSURANCE_PACK_TYPE.QUOTE,
        options: {
          saveClientDocument:
            $scope.reportSettings.ReportOptions.SaveClientDocument,
          insuranceFileId: $scope.reportPDFdata.insuranceFileId,
          implementationReport: $scope.implementationReport,
          sectionsIncluded: $scope.tempReportSettings.Sections,
          recommendationContent: $scope.recommendationReasonsContent,
          successCallback: () => {
            $state.go('app.contactsSingle', {
              familyId: $state.params.familyId,
              activeTab: 'insurance',
            });
          },
        },
      },
      helloBookPreviewService,
    };

    const response = sendInsuranceApplicationPack(data);
    if (response) {
      response.then(() => {
        $scope.cancel();
      });
    }
  };

  $scope.nextView = () => {
    if (!$scope.insurerProviderID) {
      crmConfirmation.open({
        type: 'warning',
        title: 'Hold your horses!',
        description:
          'Are you sure you want to send quote report without recommending an insurer?',
        buttonText: `Yes, I'm sure`,
        onConfirm: $scope.confirmEmailReport,
        showCancelButton: true,
        modalSize: 'md',
        cancelButtonClass: 'colored',
      });
    } else {
      $scope.confirmEmailReport();
    }
  };
};

export const getBrochures = (insuranceQuoteService, params) => {
  const { benefitId, providerIds } = params;
  if (!benefitId || !providerIds) {
    return [];
  }
  return insuranceQuoteService
    .insuranceQuoteBrochureGet(benefitId, providerIds)
    .then((res) => {
      if (!res || !res.length) {
        return [DEFAULT_BROCHURE];
      }
      const { downloadableFiles } = res[0];
      return [DEFAULT_BROCHURE, ...downloadableFiles];
    });
};

export const getBenefitIds = (peopleForm) => {
  const benefitIds = peopleForm.reduce((accum, people) => {
    const peopleBenefits = people.benefits.map((benefit) => {
      if (!benefit.BenefitId) {
        return null;
      }
      return benefit.BenefitId;
    });
    if (!peopleBenefits) {
      return accum;
    }
    return accum.concat(...peopleBenefits);
  }, []);
  const uniqueIds = new Set(benefitIds);
  return [...uniqueIds].join(',');
};

export const getBrochureParams = (peopleEntity, providerIds) => {
  return {
    benefitId: getBenefitIds(peopleEntity),
    providerIds,
  };
};
