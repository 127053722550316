import { PACKAGE_STATUS } from 'Common/constants/eSign';

export const isEsignPackageCompleted = (docFromESign, status) => {
  return docFromESign && status === PACKAGE_STATUS.PACKAGE_COMPLETE;
};

export const isEsignPackageCancelled = (docFromESign, status) => {
  return docFromESign && status === PACKAGE_STATUS.PACKAGE_TRASHED;
};

export const isEsignPackagePending = (docFromESign, status) => {
  return (
    docFromESign &&
    status !== PACKAGE_STATUS.PACKAGE_TRASHED &&
    status !== PACKAGE_STATUS.PACKAGE_COMPLETE
  );
};

export const packageDocumentDownload = (downloadDataService) => (
  documentId,
) => {
  if (!documentId) {
    return;
  }
  return downloadDataService.documentDownload(documentId);
};
