import {
  blueStoneValidationForUI,
  dateTestedValidationForUI,
} from 'Common/mappers/blueStone';

export default class BluestoneService {
  constructor($q, httpClient, uiService) {
    'ngInject';

    this.httpClient = httpClient;
    this.uiService = uiService;
    this.apiBaseUrl = 'Lixi';
    this.$q = $q;
  }

  getLoanLoanAppValidation(params) {
    const { loanAppId, lenderId } = params;
    const url = `${this.apiBaseUrl}/loan-application/${loanAppId}/checking/${lenderId}`;
    return this.httpClient.get(url).then(({ succeeded, data }) => {
      return {
        isSucceeded: succeeded,
        apiData: (data && blueStoneValidationForUI(data, this.uiService)) || [],
      };
    });
  }

  getLoanSubmissionDateTested(loanAppId) {
    const url = `${this.apiBaseUrl}/loan-application/${loanAppId}/date-tested`;
    return this.httpClient.get(url).then(({ data }) => {
      return (data && dateTestedValidationForUI(data)) || [];
    });
  }

  setSubmitDocumentsToBluestone(params) {
    const url = `${this.apiBaseUrl}/loan-submission/`;
    return this.httpClient.post(url, params).then(({ data }) => {
      return data && blueStoneValidationForUI(data, this.uiService);
    });
  }
}
