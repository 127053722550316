import template from './opportunityServiceability.html';
import controller from './opportunityServiceabilityCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanId: '<',
    summary: '<',
    opportunityId: '<',
    onAdviserChange: '&',
    involvedParties: '<',
    onRefreshDetails: '&',
    opportunityTitle: '<',
    selectedAdviserId: '<',
  },
};
