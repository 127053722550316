import angular from 'angular';
import menus from './menus';
import printButton from './printButton';
import actionsModal from './actionsModal';

export default angular
  .module('corpActionsMenus.scenes.components', [])
  .component('printButton', printButton)
  .component('actionsModal', actionsModal)
  .component('corpActionMenus', menus).name;
