import {
  WEEKLY_ID,
  HALF_YEARLY_ID,
  QUARTERLY_ID,
  STATUSES,
} from 'Common/constants/insuranceApplicationPipeline';
import { OLD_BENEFIT_TYPE } from 'Common/constants/insuranceOptions';
import { loadingExclusion } from 'Common/utilities/insurance';
import {
  toastSuccess,
  displaySuccess,
  toastError,
} from 'Common/utilities/alert';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const addLoadingExclusionUtil = ({ $scope }) => () => {
  if (!$scope.formData.isEditing) {
    if (
      $scope.insuranceDetailsInput.benefitTypeId ===
      OLD_BENEFIT_TYPE.HEALTH_COVER
    ) {
      $scope.insuranceDetailsInput.loadingExclusions = $scope.familyList.reduce(
        (accum, member) => accum.concat(loadingExclusion(member)),
        [],
      );
    } else {
      const selectedInsuredPerson = $scope.selectedInsuredPerson
        ? $scope.familyAdultList.find(
            (member) => member.PersonId === $scope.selectedInsuredPerson,
          )
        : $scope.familyAdultList[0] || {};

      $scope.insuranceDetailsInput.loadingExclusions = [
        loadingExclusion(selectedInsuredPerson),
      ];
    }
  } else {
    const loadingExcl = $scope.insuranceDetailsInput.loadingExclusions || [];
    if (
      $scope.insuranceDetailsInput.benefitTypeId ===
      OLD_BENEFIT_TYPE.HEALTH_COVER
    ) {
      $scope.insuranceDetailsInput.loadingExclusions = $scope.familyList.reduce(
        (accum, member) => {
          const exclusion = loadingExcl.find(
            (le) => le.clientId === parseInt(member.PersonId, 10),
          );

          if (exclusion) {
            return accum.concat({
              ...exclusion,
              fullName: member.FullName,
              selectedOptions: exclusion.isLoadingExclusion ? 0 : 1,
              isLoadingExclusion: !!exclusion.isLoadingExclusion,
            });
          }

          return accum.concat(loadingExclusion(member));
        },
        [],
      );
    } else if (loadingExcl[0]) {
      const loadingExclusions = loadingExcl[0];
      if ($scope.selectedInsuredPerson) {
        loadingExclusions.clientId = parseInt($scope.selectedInsuredPerson, 10);
      }

      const insuredMember = $scope.familyList.find((member) => {
        return parseInt(member.PersonId, 10) === loadingExclusions.clientId;
      });
      if (insuredMember) {
        loadingExclusions.fullName = insuredMember.FullName;
        loadingExclusions.selectedOptions = loadingExclusions.isLoadingExclusion
          ? 0
          : 1;
        loadingExclusions.isLoadingExclusion =
          loadingExclusions.selectedOptions !== 1;
      }
    } else {
      $scope.insuranceDetailsInput.loadingExclusions = [
        loadingExclusion($scope.familyAdultList[0]),
      ];
    }
  }
  $scope.isCrossProvider = () => {
    const isCrossProvider =
      $scope.insuranceDetailsInput.providerId ===
      OLD_BENEFIT_TYPE.SOUTHERN_CROSS;
    $scope.insuranceDetailsInput.isPremiumAutoCalc = !isCrossProvider;
    $scope.insuranceDetailsInput.isPolicyFeeAutoCalc = !isCrossProvider;
    $scope.isFrequencyDisableAutoCalc = isCrossProvider;
  };
};

export const getLoadingListUtil = ({ $scope, contactService }) => () => {
  contactService.getLoadingList().then((response) => {
    $scope.loadingList = response;
  });
};

export const editDataSetUtil = ({ $scope, items }) => () => {
  if (items.data.benefitId > 0) {
    $scope.formData.isEditing = true;
    const insDetails = items.data;
    if (insDetails.clientId) {
      $scope.selectedInsuredPerson = insDetails.clientId.toString();
    }
    $scope.insuranceDetailsInput = insDetails;
    $scope.isFrequencyDisableAutoCalc = frequencyDisableAutoCalc(
      insDetails.paymentFrequency,
    );

    return insDetails.benefitTypeId;
  }
};

export const onInsuranceSavedUtil = ({
  $scope,
  $uibModalInstance,
  familyId,
  statusId,
}) => (response) => {
  if ($scope.formData) {
    $scope.formData.isSaving = false;
  }
  if ($scope.isSaving) {
    $scope.isSaving = false;
  }
  if (!response.data || response.status !== 200) {
    return;
  }
  $uibModalInstance.close('close');

  const message = 'Insurance successfully saved';
  const toShowAnnualReviewModal =
    $scope.currentUserService.isNZ &&
    $scope.configService.feature.annualReviewMonthNZ &&
    statusId === STATUSES.IN_FORCE;
  if (toShowAnnualReviewModal) {
    toastSuccess(message);
    const card = {
      client: {
        id: familyId,
      },
    };
    $scope.pipelineCardsService.showAnnualReviewMonthModal(card);
  } else {
    displaySuccess(message);
  }
};

export const frequencyDisableAutoCalc = (frequency) => {
  return [WEEKLY_ID, HALF_YEARLY_ID, QUARTERLY_ID].includes(Number(frequency));
};

export const commencementDate = ({ $scope = {} }) => {
  const getStatusData = (status) => {
    const { statusId } = $scope.insuranceDetailsInput;
    return status === statusId;
  };

  $scope.isInforce = () => {
    $scope.statusInForceDate = getStatusData(STATUSES.IN_FORCE);
    $scope.isCommencementRequired = $scope.statusInForceDate;
  };

  $scope.isApplicationAndInforceStatus = () => {
    return !!$scope.quoteId && getStatusData(STATUSES.V1_IN_FORCE);
  };

  $scope.commenceMessage = () => {
    toastError('Commencement Date is required');
  };
};
