export const preselectFamily = ({ $scope }) => {
  if (!$scope.preselectFamilyId) {
    return;
  }
  $scope.selectedFamily = {
    ...$scope.selectedFamily,
    FamilyID: $scope.preselectFamilyId,
  };
};

export const isPreselect = ({ $scope }) => {
  return !!$scope.preselectFamilyId;
};
