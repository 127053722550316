import { DEFAULT_ASSETS } from 'Common/constants/financialAssets';

export const filterPropertyListByCountry = (data, isNZ) => {
  const types = data.map((o) => {
    o.Name = o.AddressPropertyType;
    o.Value = o.AddressPropertyTypeID;
    return o;
  });
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const moveToplistproperties = (propertylList) => {
    const topPropertyList = [
      DEFAULT_ASSETS.STD_RESIDENTIAL,
      DEFAULT_ASSETS.CROSS_LEASE,
      DEFAULT_ASSETS.RESIDENTIAL_LEASEHOLD,
      DEFAULT_ASSETS.STD_APARTMENT,
    ];
    const resultList = propertylList.filter((f) =>
      topPropertyList.includes(f.Value),
    );
    const copyOriginal = propertylList.filter(
      (f) => !topPropertyList.includes(f.Value),
    );
    const sortPurpose = (a, b) => {
      return (
        topPropertyList.indexOf(a.Value) - topPropertyList.indexOf(b.Value)
      );
    };
    return [...resultList.sort(sortPurpose), ...copyOriginal];
  };
  return isNZ ? moveToplistproperties(types) : types;
};

export default filterPropertyListByCountry;
