import template from './colorSelector.html';
import controller from './colorSelectorCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    brandingColor: '<',
    onColorChange: '&',
    onCancelSelect: '&',
  },
};
