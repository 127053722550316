import angular from 'angular';
import { EPICTENET_TRANSACTION_TYPE } from 'Common/constants/fundsConstants';

export const loadModule = () =>
  angular
    .module('app')
    .controller('AddPropertyModalCtrl', function AddPropertyModalCtrl(
      $scope,
      $stateParams,
      $uibModalInstance,
      saveProperty,
      fundingCalculatorService,
      fundingCalculatorParameters,
      setBlankObject,
      getSearchAddressSuggestion,
      closeAutoHomeAddress,
      setEpictenetTransactionType,
    ) {
      $scope.brokerEventId = $stateParams.brokerEventId;
      $scope.fundingCalculatorParameters = fundingCalculatorParameters;
      $scope.setBlankObject = setBlankObject;
      $scope.getSearchAddressSuggestion = getSearchAddressSuggestion;
      $scope.closeAutoHomeAddress = closeAutoHomeAddress;
      $scope.setEpictenetTransactionType = setEpictenetTransactionType;
      $scope.epictenetTransactionType = EPICTENET_TRANSACTION_TYPE;

      // Get Property Parameters
      $scope.propertyParametersList = {};
      fundingCalculatorService
        .getPropertyParameters()
        .then((propertyParameters) => {
          $scope.propertyParametersList = propertyParameters;
        });

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.resetFundingCalculatorData = () => {
        $scope.fundingCalculatorParameters.purchaseInfo.refinanceAmount = '';
        $scope.fundingCalculatorParameters.landValue = '';
      };
      $scope.saveProperty = function (formData) {
        const data = saveProperty(formData, $scope.fundingCalculatorParameters);
        if (data !== 'Invalid') {
          $scope.cancel();
        }
      };
    });
