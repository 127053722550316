import angular from 'angular';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const loadModule = () =>
  angular
    .module('app')
    .directive('downloadPdf', function dynamicInputWidth($window) {
      return {
        restrict: 'E',
        template:
          '<button class="btn btn-info hidden-xs padding-top-5 padding-left-10 crm-btn width-350 pdf-download">\n' +
          '<icon-print></icon-print>\n' +
          '<span >Download PDF</span></button>',
        link(scope, element) {
          // eslint-disable-next-line unicorn/consistent-function-scoping
          const elementConstant = () => {
            const node = $window.document.querySelector('#makePDF');
            html2canvas(node, {
              useCORS: true,
              scale: 1,
            }).then((canvas) => {
              const imgData = canvas.toDataURL('image/jpeg', 1);
              const doc = new JsPDF('p', 'mm', 'a4');
              doc.addImage(
                imgData,
                'JPEG',
                0,
                -1,
                node.innerWidth,
                node.innerHeight,
              );
              doc.save('report.pdf');
            });
          };

          element.on('click', elementConstant);
          scope.$on('$destroy', () => {
            element.off('click', elementConstant);
          });
        },
      };
    });
