import { toastError } from 'Common/utilities/alert';

export default class ProviderContactPointsCtrl {
  constructor(insuranceQuoteService) {
    'ngInject';

    this.insuranceQuoteService = insuranceQuoteService;
    this.brochures = [];
  }

  $onInit() {
    if (this.insurerId) {
      this.displayBrochures();
    }
  }

  displayBrochures() {
    this.isLoading = true;
    this.insuranceQuoteService
      .insuranceQuoteBrochureGet(0, this.insurerId, true)
      .then(
        (response) => {
          this.brochures = response;
        },
        () => {
          toastError({
            data: {
              Message: 'Unable to load the forms at the moment.',
            },
          });
        },
      )
      .finally(() => {
        this.isLoading = false;
      });
  }

  getRawFilename(fileName = '') {
    this.strFileName = fileName;
    if (this.strFileName) {
      const pieces = this.strFileName.split('/');
      const rawName = pieces[pieces.length - 1];
      this.strFileName = rawName.split('.').slice(0, -1).join('.');
    }
    return this.strFileName;
  }
}
