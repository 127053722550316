import { WORK_BENCH_STATES } from 'Common/constants/workbench';
import { updateArrayFieldValue } from 'Common/utilities/array';

export default class WorkbenchLogsCtrl {
  $onInit() {
    this.WORK_BENCH_STATES = WORK_BENCH_STATES;
  }

  onCloseNoteTypeModal({ updatedNoteTypes }) {
    const args = {
      list: this.logList,
      lookup: updatedNoteTypes,
      listId: 'NoteTypeID',
      listField: 'NoteTypeDesc',
      lookupId: 'id',
      lookupField: 'type',
    };
    this.logList = updateArrayFieldValue(args);
  }
}
