import template from './tableList.html';
import TableListCtrl from './tableListCtrl';
import './style.scss';

export default {
  template,
  controller: TableListCtrl,
  controllerAs: 'vm',
  bindings: {
    tableTitle: '@?',
    columnHeader: '<',
    columnContent: '<',
    gridTemplate: '<',
    editHandler: '<?',
    deleteHandler: '<?',
  },
};
