export const insuranceFileBuilderForUI = (data) => {
  return {
    id: data.InsuranceFileId,
    familyId: data.FamilyId,
    name: data.Name,
  };
};

export const insuranceFileBuilderForAPI = (data) => {
  return {
    InsuranceFileId: data.InsuranceFileId,
    FamilyId: data.FamilyId,
    Name: data.FileName,
  };
};
