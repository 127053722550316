import template from './opportunityViewTransactionModal.html';
import controller from './opportunityViewTransactionModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    transaction: '<',
    isEmail: '<',
    onDeleteTransaction: '&',
    modalInstance: '<',
  },
};
