import angular from 'angular';

// format file name for dynamic including using ng-include
export const loadModule = () =>
  angular.module('app').filter('includeFile', function includeFile() {
    return function (name) {
      const file = String(name).toLowerCase().replace(/\s/g, '_');
      // eslint-disable-next-line sonarjs/no-small-switch
      switch (file) {
        case 'interest_rates':
          return file;
        default:
          return 'default';
      }
    };
  });
