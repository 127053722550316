import { get } from 'lodash';
import { PROVIDER_TYPE } from 'Common/constants/referralType';
import {
  SECTION_CODE,
  ERRORS_EXCLUDED,
  CODE_PARTIES_ERROR,
  ERROR_CODE,
  VALIDATE_ATTRIBUTES,
  CODE_GUARANTORS_ERROR,
} from 'Common/constants/blueStoneStatusCodes';
import { camelize } from 'Common/utilities/string';

export const isBluesToneFeatureOn = ({ isNZ, feature }) => {
  return !!feature.bluestoneOnlineApplication && isNZ;
};

export const isApplicationToOnlineProduct = (lenderId) => {
  const providerId = parseInt(lenderId, 10);
  const { LOAN_SELECT } = PROVIDER_TYPE;
  return providerId === LOAN_SELECT;
};

export const isFeatureBlueStoneOnAndOff = (features) => {
  return features ? !features : !!features;
};

export const loanParams = ({ loanAppLenderDetailObject, loanAppId }) => {
  const { LenderId } = loanAppLenderDetailObject;
  const myLoandId = loanAppId;
  return {
    lenderId: LenderId,
    loanAppId: myLoandId,
  };
};

export const validateFunction = (
  functionName,
  { applicantsSection, fundingSection },
) => {
  return applicantsSection[functionName] || fundingSection[functionName];
};

export const compareSections = (sectionName, code) => {
  return SECTION_CODE[sectionName] === code;
};

export const excludeErrors = (obj) => {
  const newErrors = Object.assign({}, obj);
  ERRORS_EXCLUDED.forEach((key) => {
    delete newErrors[key];
  });
  return newErrors;
};

export const sectionErrorCallback = ({ sections }, keys, errors) => {
  const propErrors = sections.filter((bySection) => bySection[keys])[0];
  const hasError = !!Object.values(propErrors[keys]).filter(
    (error) =>
      Object.keys(error).length && error.code !== ERROR_CODE.ASSET_ADDRESS,
  ).length;
  propErrors[keys].bannerColor = hasError;
  propErrors[keys].hasErrors = hasError;
  errors(propErrors[keys]);
};

export const validateOccurringErrors = (errorTypes = {}) => {
  return Object.keys(errorTypes)
    .map((key) => {
      return errorTypes[key];
    })
    .filter((x) => x);
};

export const containsAllPartyError = (occuringError = {}, CODES) =>
  occuringError.filter((items) => CODES.includes(items.code));

export const mapClientErrors = ({ ids }, errorProp, clientList) => {
  return clientList.map((familyList) => {
    return familyList.map((client) => {
      client[errorProp] = !!ids.filter(
        (list) => list.ClientID === parseInt(client.PersonId, 10),
      ).length;
      return clientList;
    });
  });
};

export const mapDependentErrors = (
  { ids },
  errorProp,
  dependents,
  { name },
) => {
  return dependents.map((dependent) => {
    dependent[errorProp] = ids.some(
      (list) => list.ClientID === parseInt(dependent.PersonId, 10),
    );
    dependent[camelize(name)] = ids.some(
      (list) => list.ClientID === parseInt(dependent.PersonId, 10),
    );
    return dependent;
  });
};

export const appendErrorsToClientParty = ({
  occurringErrors,
  listApplicants,
}) => {
  const filterErrors = (code) => {
    const errors = containsAllPartyError(occurringErrors, CODE_PARTIES_ERROR);
    return errors.find((x) => x.code === code);
  };

  return listApplicants.map((clientErrorList) => {
    return clientErrorList.map((clients) => {
      clients.myErrors = [
        clients.hasErrorAge && filterErrors(ERROR_CODE.AGE),
        clients.hasErrorResidency &&
          filterErrors(ERROR_CODE.CITIZENSHIP_RESIDENCY),
        clients.hasErrorNZResidence &&
          filterErrors(ERROR_CODE.TEMPORARY_RESIDENCE),
        clients.hasErrorCannotContainNumbers &&
          filterErrors(ERROR_CODE.BORROWER_NAME_CONTAIN_NUMBERS),
        clients.hasErrorTitle && filterErrors(ERROR_CODE.INVALID_TITLE),
        clients.hasErrorMobile && filterErrors(ERROR_CODE.MOBILE_BORROWERS),
        clients.hasErrorEmail && filterErrors(ERROR_CODE.BORROWERS_EMAIL),
        clients.hasErrorContactPoints &&
          filterErrors(ERROR_CODE.CONTACT_POINTS_BORROWERS),
      ].filter((error) => error);
      return clients;
    });
  });
};

export const appendErrorsToGuarantors = ({
  occurringErrors,
  listGuarantors,
}) => {
  const filterErrors = (code) => {
    const errors = containsAllPartyError(
      occurringErrors,
      CODE_GUARANTORS_ERROR,
    );
    return errors.find((x) => x.code === code);
  };

  return listGuarantors.map((clientErrorList) => {
    return clientErrorList.map((clients) => {
      clients.myErrors = [
        clients.hasErrorTitle && filterErrors(ERROR_CODE.GUARANTORS_TITLE),
        clients.hasErrorMobile && filterErrors(ERROR_CODE.MOBILE_GUARANTORS),
        clients.hasErrorEmail && filterErrors(ERROR_CODE.GUARANTORS_EMAIL),
        clients.hasErrorContactPoints &&
          filterErrors(ERROR_CODE.CONTACT_POINTS_GUARANTORS),
      ].filter((error) => error);
      return clients;
    });
  });
};

export const errorSegregatorSections = (occuringError = {}, props) => {
  return occuringError.filter((items) =>
    ERROR_CODE[props].includes(items.code),
  );
};

export const appendErrorToLoanFacility = (
  loanDetailsSet = {},
  occurringErrors = {},
) => {
  const { LoanFacility = [] } = loanDetailsSet;
  const [facilityError] = errorSegregatorSections(
    occurringErrors,
    'LOAN_FACILITIES',
  );
  const { attributes = {} } = facilityError || {
    attributes: {
      ids: [],
    },
  };
  return LoanFacility.map((facility) => {
    facility.hasError = attributes.ids.some(
      (list) => list.LoanStructureId === facility.LoanStructureId,
    )
      ? attributes.errorMessage
      : false;
    return facility;
  });
};

export const appendErrorToSecurityAddress = (
  occuringError = {},
  securityList = [],
) => {
  const { attributes } = occuringError.find(
    (error) =>
      error.code === ERROR_CODE.SECURITY_ADDRESS ||
      ERROR_CODE.SECURITY_SAVE_ADDRESS,
  ) || {
    attributes: {
      ids: [],
    },
  };
  return securityList.map((security) => {
    security.hasError = attributes.ids.some(
      (id) => id.SecurityInfoId === security.SecurityInfoId,
    );
    return security;
  });
};

export const appendAttributesNotificationError = (
  applicantSections,
  errors,
  clients,
) => {
  VALIDATE_ATTRIBUTES[applicantSections].forEach((attributes) => {
    !!errors[attributes.attributeName] &&
      mapClientErrors(
        errors[attributes.attributeName].attributes,
        attributes.propName,
        clients,
      );
  });
};

export const getFatalErrorCodes = (section = [], advisersServiceEmail) => {
  const [errors] = section;
  return {
    codes: errors.fatalErrors.map(({ code }) => code).join(','),
    descriptions: errors.fatalErrors.map(({ name }) => name).join(','),
    advisersServiceEmail,
  };
};

export const lockedValidation = ({ loanAppSummary = {} }, isLocked) => {
  loanAppSummary.LixiBluestoneLoanDetails.IsSubmitted = isLocked;
};

export const preventModalToOpenTwice = (vm, { modalInstance }) => {
  const toggleModal = () => {
    vm.isOpened = !vm.isOpened;
  };
  toggleModal();
  modalInstance.result.then(
    () => {
      toggleModal();
    },
    () => {
      toggleModal();
    },
  );
};

export const addErrorIconToClientIncome = (
  applicantsCtrl = {},
  handlerApplicants = {},
) => {
  const {
    borrowerEmploymentIncome,
    guarantorEmploymentIncome,
  } = applicantsCtrl.errorTypes || {
    borrowerEmploymentIncome: false,
    guarantorEmploymentIncome: false,
  };
  const hasIncomeErros =
    !!guarantorEmploymentIncome || !!borrowerEmploymentIncome;
  hasIncomeErros &&
    (() => {
      const { Employment = {} } = handlerApplicants.loanAppSharedData;
      const borrowersId =
        ((borrowerEmploymentIncome || {}).attributes || {}).ids || [];
      const guarantorsId =
        ((guarantorEmploymentIncome || {}).attributes || {}).ids || [];
      const allList = get(Employment, 'AllList', []);
      allList.forEach((employment) => {
        employment.Employment.map((list) => {
          list.hasError =
            borrowersId.some((id) => id.EmploymentID === list.EmploymentId) ||
            guarantorsId.some((id) => id.EmploymentID === list.EmploymentId);
          return list;
        });
      });
    })();
};

export const getIncomeErrors = ({ applicantsCtrl = {} }, callback) => {
  const {
    borrowerEmploymentIncome,
    guarantorEmploymentIncome,
    employmentIncomeVerification,
  } = applicantsCtrl.errorTypes || { errorTypes: {} };
  const hasIncomeErros =
    !!guarantorEmploymentIncome ||
    !!borrowerEmploymentIncome ||
    !!employmentIncomeVerification;
  const borrowers =
    ((borrowerEmploymentIncome || {}).attributes || {}).ids || [];
  const guarantors =
    ((guarantorEmploymentIncome || {}).attributes || {}).ids || [];
  const verificationError =
    ((employmentIncomeVerification || {}).attributes || {}).ids || [];
  callback(hasIncomeErros, { borrowers, guarantors, verificationError });
};
