import angular from 'angular';
// filter to convert html to plain text
export const loadModule = () =>
  angular.module('app').filter('slugify', function slugify() {
    return function (str) {
      return str
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text;
    };
  });
