import { validateDate, setCurrentYear } from 'Common/utilities/date';
import { toastSaveDelete, toastSaveSuccess } from 'Common/utilities/alert';

class AchievementFieldCtrl {
  constructor($scope, corporateService, reportService) {
    'ngInject';

    this.$scope = $scope;
    this.corporateService = corporateService;
    this.reportService = reportService;
    this.showAddFieldAchievement = false;
    this.addedFiscalYears = [];
    this.fiscalYearsArr = [];
    this.userFiscalYear = 0;
    this.limitYear = 2012;
    this.editAchievement = {};
    this.yearPerAchievement = [];
  }

  setAchievementFiscalYears(key, fiscalyear) {
    if (!key || !fiscalyear) {
      return;
    }
    this.adviserAchievementsData[this.adviserAchievementsData.length - 1][
      key
    ] = fiscalyear;
  }

  setAchievementsOptions(foundYear) {
    const tmpArray = [];
    this.yearPerAchievement = [...this.yearPerAchievement, tmpArray];
    if (!this.adviserAchievementsData.length) {
      return;
    }
    this.setAchievementFiscalYears('FiscalYear', foundYear);
    this.setAchievementFiscalYears('prevFiscalYear', foundYear);
    this.addedFiscalYears = [...this.addedFiscalYears, foundYear];
  }

  showEditAchievement(data) {
    const { FiscalYear } = data;
    this.getAdviserAchievementPerYear(FiscalYear, data);
    data.isShow = true;
  }

  delete(data, index) {
    if (!data) {
      return false;
    }
    data.isShow = false;
    const { FamilyID, AchievementID, FiscalYear, FamilyAchievementsId } = data;
    if (!AchievementID) {
      this.adviserAchievementsData.splice(index, 1);
      return false;
    }
    if (FamilyID === this.familyId) {
      this.addedFiscalYears = this.addedFiscalYears.filter(
        (addfiscalyear) => addfiscalyear !== FiscalYear,
      );
      this.adviserAchievementsData = [
        ...this.adviserAchievementsData.slice(0, index),
      ];
    }
    this.showAddDefault = this.adviserAchievementsData.length > 0;
    this.corporateService
      .familyAchievementSet(
        FamilyID,
        AchievementID,
        FiscalYear,
        true,
        FamilyAchievementsId,
      )
      .then((response) => {
        if (!response) {
          return;
        }
        this.getFamilyAchievements();
        toastSaveDelete();
      });
  }

  getAchievement(achievement) {
    this.achievement = achievement;
    if (!this.achievement || !Object.keys(this.achievement).length) {
      return;
    }
    const { originalObject } = this.achievement;
    if (!originalObject) {
      return;
    }
    return originalObject;
  }

  save(formData) {
    if (!formData) {
      return;
    }
    const {
      FiscalYear,
      selectedAchievement,
      FamilyID,
      AchievementID,
      familyAchievementsId,
      achievementName,
    } = formData;

    const familyId = FamilyID || this.familyId;
    const { AdviserAchievementId, AdviserAchievementName } =
      this.getAchievement(selectedAchievement) || {};
    const achievementId = AdviserAchievementId || AchievementID;
    const name = AdviserAchievementName || achievementName;
    if (!familyId || !achievementId || !name) {
      return true;
    }
    this.corporateService
      .familyAchievementSet(
        familyId,
        achievementId,
        FiscalYear,
        false,
        familyAchievementsId,
      )
      .then((response) => {
        if (!response) {
          return;
        }
        this.$scope.$broadcast('angucomplete-alt:clearInput');
        this.getFamilyAchievements();
        toastSaveSuccess();
      });
    formData.isShow = false;
  }

  getAchievementName(achievementId, achievements) {
    this.achievements = achievements;
    if (!this.achievements && !achievementId) {
      return;
    }
    const filterAchievementName = this.achievements.filter(
      (achievement) => achievementId === achievement.AdviserAchievementId,
    );
    if (!filterAchievementName.length) {
      return;
    }
    return filterAchievementName[0].AdviserAchievementName;
  }

  getAdviserAchievements(familyAchievements) {
    this.adviserAchievementsData = [];
    this.addedFiscalYears = [];
    familyAchievements.forEach((familyAchievement) => {
      const {
        FiscalYear,
        AchievementID,
        FamilyAchievementsId,
      } = familyAchievement;
      this.addedFiscalYears = [...this.addedFiscalYears, FiscalYear];
      this.corporateService
        .adviserAchievementsGet(FiscalYear)
        .then((adviserAchievements) => {
          if (!adviserAchievements.data) {
            return;
          }
          const { data } = adviserAchievements;
          this.adviserAchievementsData = [
            ...this.adviserAchievementsData,
            {
              ...familyAchievement,
              achievementName: this.getAchievementName(AchievementID, data),
              isShow: false,
              prevFiscalYear: FiscalYear,
              familyAchievementsId: FamilyAchievementsId,
            },
          ];
        });
    });
  }

  getFamilyAchievements() {
    this.corporateService
      .familyAchievementGet(this.familyId)
      .then((familyAchievements) => {
        if (!familyAchievements.data) {
          return;
        }
        if (familyAchievements.data.length) {
          this.isAddAchievement = true;
        }
        return familyAchievements.data;
      })
      .then((familyAchievements) => {
        this.showAddDefault = familyAchievements.length > 0;
        this.getAdviserAchievements(familyAchievements);
      });
  }

  getAdviserAchievementPerYear(fiscalYear, data, isFiscalYearChange) {
    this.achievementsDataAdd = [];
    if (isFiscalYearChange) {
      const index =
        (this.adviserAchievementsData &&
          this.adviserAchievementsData.indexOf(data)) ||
        0;
      this.$scope.$broadcast(
        'angucomplete-alt:clearInput',
        `autocomplete-${index}`,
      );
    }

    this.corporateService
      .adviserAchievementsGet(fiscalYear)
      .then((response) => {
        if (!response.data) {
          return;
        }
        this.achievementsDataAdd = response.data;
        if (isFiscalYearChange && this.adviserAchievementsData) {
          this.adviserAchievementsData.map((achievement) => {
            if (!data || data.AchievementID !== achievement.AchievementID) {
              return achievement;
            }
            achievement.achievementName = '';
            return achievement;
          });
        }

        this.refreshAchievementData(fiscalYear, data);
      });
  }

  refreshAchievementData(year, selectedData) {
    this.achievementsDataAdd = this.achievementsDataAdd.filter(
      (achievement) => {
        const result =
          this.adviserAchievementsData &&
          this.adviserAchievementsData.filter((existing) => {
            const isExisting =
              achievement.AdviserAchievementId === existing.AchievementID;
            const currentSelection =
              selectedData &&
              selectedData.AchievementID === achievement.AdviserAchievementId;
            return isExisting && !currentSelection;
          });
        return !result || !result.length;
      },
    );
  }

  addAchievement() {
    const foundYear = (this.fiscalYearsArr && this.fiscalYearsArr[0]) || '';
    this.adviserAchievementsData = [
      ...this.adviserAchievementsData,
      {
        FiscalYear: foundYear,
        achievementName: '',
        isShow: true,
        prevFiscalYear: foundYear,
        FamilyID: this.familyId,
        familyAchievementsId: 0,
      },
    ];
    this.getAdviserAchievementPerYear(foundYear);
    this.setAchievementsOptions(foundYear);
  }

  hideAddAchievementDefault() {
    this.showAddDefault = true;
    this.showAddFieldAchievement = true;
    this.addAchievement();
  }

  getListofFiscalYear() {
    this.fiscalYearsArr = new Array(this.userFiscalYear - this.limitYear)
      .fill()
      .map((val, index) => this.userFiscalYear - index);
  }

  getFiscalYear() {
    const advanceYear = 5;
    const advancedFiscalYear = setCurrentYear() + advanceYear;
    this.reportService
      .getCountryFiscalYear(advancedFiscalYear)
      .then((fiscalYear) => {
        if (!fiscalYear.data && fiscalYear.data.length) {
          return;
        }

        const filterFiscalYear = fiscalYear.data.map(
          (year) => year.FiscalYearStart,
        );
        if (!filterFiscalYear.length) {
          return;
        }
        this.userFiscalYear = validateDate(filterFiscalYear[0])
          ? validateDate(filterFiscalYear[0]).getFullYear()
          : '';
        this.getListofFiscalYear();
        this.getAdviserAchievementPerYear(this.userFiscalYear);
      });
  }

  $onInit() {
    this.getFiscalYear();
    this.getFamilyAchievements();
  }
}

export default AchievementFieldCtrl;
