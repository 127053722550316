import angular from 'angular';
import { openVideoModal } from 'Common/utilities/trainingVideoModal';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';
import { capitalizeFirstLetter } from 'Common/utilities/string';
import { CONTACT_TYPE_NAME } from 'Common/constants/contactType';

const CONTACT_PAGE_OFFSET = 150;
const CONTACT_PAGE_TIME = 1000;

const SEARCH_CONTACT_TYPE_MAPPER = {
  Client: 'Clients',
  Business: 'Businesses',
  Adviser: 'Advisers',
  'Adviser Organization': 'Adviser Orgs',
  Assistant: 'Assistants',
  Referrer: 'Referrers',
  'Referrer Organization': 'Referrer Orgs',
};

export const isContactTypeSimilar = (contact, contactType) => {
  if (!contact) {
    return;
  }
  return contact.contactType === contactType;
};

export const searchPlaceholderText = (contactType) => {
  return !contactType || !SEARCH_CONTACT_TYPE_MAPPER[contactType]
    ? 'Search Contacts'
    : `Search ${SEARCH_CONTACT_TYPE_MAPPER[contactType]}`;
};

export const scrollTop = ({ $document }) => {
  const documentElement =
    $document &&
    $document.length &&
    $document[0].querySelector('#contacts-page');
  $document.scrollToElement(
    angular.element(documentElement),
    CONTACT_PAGE_OFFSET,
    CONTACT_PAGE_TIME,
  );
};

export const setDefaultParams = ({ $scope, $stateParams }) => {
  const { index, page, search } = $stateParams;
  const capitalizeIndex = capitalizeFirstLetter(index);
  $scope.activeFilterLatter =
    capitalizeIndex === 'All' ? '*' : capitalizeIndex || '*';
  $scope.activeFilterAlphaLatter = capitalizeIndex || 'All';
  $scope.currentPage = parseInt(page, 10) || 1;
  if (search) {
    $scope.searchText = search;
    $scope.contactModel.searchContactText = search;
  }
};

export const setStateTransition = ({ $scope, $state, stateService }) => () => {
  $state.params.index =
    ($scope.activeFilterAlphaLatter &&
      $scope.activeFilterAlphaLatter.toLowerCase()) ||
    'all';

  $state.params.page = $scope.currentPage || 1;
  $state.params.search = $scope.contactModel.searchContactText || '';
  stateService.transition($state.current, $state.params);
};

export const contactDemoVideo = ({
  DEMO_VIDEO,
  currentUserService,
  $uibModal,
}) => {
  if (currentUserService.isNZ) {
    return openVideoModal($uibModal, PAGE_SOURCE.MY_CONTACTS);
  }
  $uibModal.open({
    templateUrl: '/assets/views/partials/demo_video_modal.html',
    controller: 'DemoVideoModalCtrl',
    resolve: {
      videoId: () => DEMO_VIDEO.CONTACTS.Url,
    },
    size: 'lg',
    windowClass: 'demo-video-modal-window-class',
  });
};

export const getCharacterLimit = (tableWidthCheck) => (contact) => {
  if (!contact) {
    return tableWidthCheck;
  }
  const DECEASED_ADDED_TEXT_LIMIT = 4;
  const addedLimit = contact.Deceased ? DECEASED_ADDED_TEXT_LIMIT : 0;
  return tableWidthCheck - addedLimit;
};

export const openContactDetails = ({ $scope, modalRenderService }) => (
  contact,
) => {
  const contactType = contact.FamilyType || contact.ContactType;
  const familyId = contact.key;
  const validContactType = [
    CONTACT_TYPE_NAME.CLIENT,
    CONTACT_TYPE_NAME.ENTITY,
    CONTACT_TYPE_NAME.BUSINESS,
  ];
  if (!validContactType.includes(contactType)) {
    $scope.contactDetailsOpened = familyId;
    return;
  }
  const props = {
    familyId,
    contactType,
    contact,
    routeContact: $scope.getRouteClient,
  };
  modalRenderService.openContactDetails(props);
};

export const initializeContacts = ({
  $scope,
  configService,
  modalRenderService,
}) => {
  $scope.displayClientDetailsPreviewMobile =
    configService.feature.clientDetailsPreviewMobile;
  $scope.contactDetailsOpened = 0;
  $scope.openDetails = openContactDetails({ $scope, modalRenderService });
};

export const setKeyForContactData = (data) => {
  return (
    (data &&
      data.map((contact) => {
        contact.key =
          contact.FamilyID || contact.FamilyId || contact.OrganizationId;
        return contact;
      })) ||
    []
  );
};
