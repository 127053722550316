import { ALLOWED_ACTION } from 'Common/constants/accessPermission';

class ComplianceReviewRatingCtrl {
  constructor(
    corporateService,
    userService,
    $uibModal,
    commonFnService,
    modelDataService,
    corporateModelService,
  ) {
    'ngInject';

    this.corporateService = corporateService;
    this.userService = userService;
    this.$uibModal = $uibModal;
    this.commonFnService = commonFnService;
    this.modelDataService = modelDataService;
    this.corporateModelService = corporateModelService;
  }

  paginateComplianceData(data) {
    this.otherReviewerData = [...data];
    const totalCount = this.otherReviewerData.length;
    this.totalPage = parseInt(totalCount / this.itemPerPage, 10);
    if (totalCount % this.itemPerPage > 0) {
      this.totalPage++;
    }
    this.otherCommentsPaged = this.otherReviewerData.slice(
      (this.currentPage - 1) * this.itemPerPage,
      this.currentPage * this.itemPerPage,
    );
  }

  reloadPaging() {
    this.currentPage++;
    const tempData = this.otherReviewerData.slice(
      (this.currentPage - 1) * this.itemPerPage,
      this.currentPage * this.itemPerPage,
    );
    this.otherCommentsPaged = [...this.otherCommentsPaged, ...tempData];
  }

  resetComment() {
    this.currentPage = 1;
    this.paginateComplianceData(this.otherComments);
  }

  getOwnerNewData() {
    this.ownerData = {
      ...this.modelDataService.complianceReviewModel(),
      adviserFamilyId: this.familyId,
      reviewerFamilyId: this.userInfo.FamilyId,
    };
  }

  manipulateComplianceData(comments) {
    this.currentPage = 1;
    if (comments && comments.length) {
      const { FamilyId: reviewerFamilyId } = this.userInfo;
      this.ownersComment = comments.filter((comment) => {
        return comment.reviewerFamilyId === reviewerFamilyId;
      });
      if (this.ownersComment && this.ownersComment.length) {
        this.otherComments = comments.filter((comment) => {
          return comment !== this.ownersComment[0];
        });
        this.ownerData = this.ownersComment[0];
        this.paginateComplianceData(this.otherComments);
      } else {
        this.otherComments = comments;
        this.getOwnerNewData();
        this.paginateComplianceData(this.otherComments);
      }
    } else {
      this.getOwnerNewData();
    }
    this.isLoadingReviews = false;
    this.showUserRating = true;
  }

  getComplianceComment() {
    const adviserFamilyId = this.familyId;
    this.showUserRating = false;
    this.corporateModelService
      .complianceReviewGet(adviserFamilyId)
      .then((data) => {
        if (!data) {
          return;
        }
        this.complianceReviewList = data;
        this.manipulateComplianceData(this.complianceReviewList);
      });
  }

  launchCommentModal(comment) {
    const size = 'md';
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/corporate/modals/complianceReviewRatingModal.html',
      controller: 'ComplianceReviewRatingModalCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      size,
      windowClass: '',
      resolve: {
        comment: () => comment,
      },
    });
    modalInstance.result.then(() => {
      this.isLoadingReviews = true;
      this.getComplianceComment();
    });
  }

  setRating(rating) {
    const tempData = {
      ...this.ownerData,
      rating,
      comments: '',
    };
    this.launchCommentModal(tempData);
  }

  hideSeeMore() {
    return this.currentPage >= this.totalPage;
  }

  hideOtherComment() {
    return this.currentPage <= 1;
  }

  dismissPopover() {
    this.isShowPopover = false;
  }

  getCommentDate(data) {
    this.commentDate = data.lastModifiedDate || data.createdDate;
    return this.commentDate;
  }

  getCompliancePermission() {
    this.canWrite = false;
    const pageName = 'AdviserContact';
    const tabName = 'AdviserDetails';
    const subTabName = 'ComplianceRating';
    this.corporateModelService
      .accessControlMatrixes(pageName, tabName, subTabName)
      .then((data) => {
        if (data && data.length) {
          this.canWrite =
            this.commonFnService.adviserStatus.isProspect ||
            data[0].allowedActionId === ALLOWED_ACTION.READ_AND_WRITE;
        }
        this.getComplianceComment();
      });
  }

  $onInit() {
    this.isLoadingReviews = true;
    this.itemPerPage = 2;
    this.totalPage = 1;
    this.ratingAverage = 0;
    this.isShowPopover = true;
    this.userService.GetUserInfo().then((response) => {
      this.userInfo = response.data;
      this.getCompliancePermission();
    });
    const clientId = 0;
    this.corporateService
      .corporateContactGet(clientId, this.familyId)
      .then((response) => {
        this.adviserInfo = response.data;
      });
  }
}

export default ComplianceReviewRatingCtrl;
