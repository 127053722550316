import template from './importantDocumentsTable.html';
import controller from './importantDocumentsTableCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    documentList: '<',
    categoryId: '<',
    onUpdate: '&',
    isSearch: '<',
    onDeleteItem: '&',
  },
};
