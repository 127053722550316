import { getDesignatedFamilyBrokerInfo } from 'Common/utilities/adviser';
import { sendHelloBook } from 'Common/utilities/contact';

export default class HelloBookPreviewService {
  constructor(
    $timeout,
    $uibModal,
    configService,
    crmConfirmation,
    currentUserService,
    contactModelService,
    actionValidatorService,
    helloPackService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.configService = configService;
    this.crmConfirmation = crmConfirmation;
    this.currentUserService = currentUserService;
    this.contactModelService = contactModelService;
    this.actionValidatorService = actionValidatorService;
    this.helloPackService = helloPackService;
  }

  open(props, customClass) {
    if (props.isNewModal) {
      return this.helloPackService
        .launchHelloPackModal(props)
        .result.then(() => {
          this.onSuccessSendCallback && this.onSuccessSendCallback();
        });
    }
    const modalClass = `hello-book-preview-generator-modal ${customClass}`;
    return this.$uibModal
      .open({
        template: `<hello-book-preview-generator
        modal-instance="vm.modalInstance"
        family-id="vm.props.familyId"
        quote-id="vm.props.quoteId"
        provider="vm.props.provider"
        active-pack-type="vm.props.activePackType"
        active-pack-mode="vm.props.activePackMode"
        is-for-loan-writers="vm.props.isForLoanWriters"
        selected-service="vm.props.selectedService"
        to-show-fact-find="vm.props.toShowFactFind"
        joint-contact="vm.props.jointContact"
        options="vm.props.options"
      ></hello-book-preview-generator>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        keyboard: false,
        backdrop: 'static',
        size: 'lg',
        resolve: {
          props: () => props,
        },
        windowClass: modalClass,
      })
      .result.then((response) => {
        response &&
          response.sent &&
          this.onSuccessSendCallback &&
          this.onSuccessSendCallback();

        return response;
      });
  }

  setSuccessCallback(successSendCallback) {
    this.onSuccessSendCallback = successSendCallback;
  }

  openLoadingModal(loaderProps) {
    return this.$uibModal.open({
      template: `<hello-book-loading-modal
        modal-instance="vm.modalInstance"
        action="vm.props.action"
      ></hello-book-loading-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      keyboard: false,
      backdrop: 'static',
      size: 'lg',
      resolve: {
        props: () => loaderProps,
      },
    });
  }

  openServiceOptionModal(props) {
    return this.$uibModal.open({
      template: `<open-service-option-modal
        modal-instance="vm.modalInstance"
        action="vm.props.action"
      ></open-service-option-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      windowClass: `hello-book-service-option`,
      resolve: {
        props: () => props,
      },
    });
  }

  openConfirmationModal(confirmCallback) {
    this.crmConfirmation.open({
      type: 'success',
      title:
        'Contact created. Send a Hello pack to your clients to introduce yourself?',
      buttonText: 'Send Hello Pack',
      showCancelButton: true,
      cancelButtonText: 'Not Now',
      cancelButtonClass: 'hello-book-send-cancel-button',
      onConfirm: confirmCallback,
      modalSize: 'md',
    });
  }

  launchOnClientCreateSuccess(
    scope,
    familyId,
    additionalConditions = true,
    contactModelService = {},
    helloBookPreviewService = {},
    isNewModal = false,
  ) {
    const isValidUser =
      this.currentUserService.isAU || this.currentUserService.isNZ;
    const hasHelloBookAccess =
      this.configService.feature.helloBook &&
      isValidUser &&
      additionalConditions;

    if (hasHelloBookAccess) {
      const launchHelloBook = () =>
        this.loadHelloBookPreview(
          scope,
          familyId,
          contactModelService,
          helloBookPreviewService,
          isNewModal,
        );
      getDesignatedFamilyBrokerInfo({
        scope,
        familyId,
      })(this.contactModelService);
      this.openConfirmationModal(launchHelloBook);
    }
    return hasHelloBookAccess;
  }

  loadHelloBookPreview(
    scope,
    familyId,
    contactModelService = {},
    helloBookPreviewService = {},
    isNewModal = false,
  ) {
    let loaderModalInstance = null;
    if (!isNewModal) {
      loaderModalInstance = this.openLoadingModal({
        action: 'transition',
      });
    }

    if (contactModelService.getFamilyAdviserInfo) {
      contactModelService.getFamilyAdviserInfo(familyId).then((response) => {
        scope.designatedBrokerInfo = response;
        scope.openSendHelloBook = (additionalProps = {}) => {
          const props = { ...additionalProps, familyId, isNewModal };
          helloBookPreviewService.open(props);
        };
        sendHelloBook({ vm: scope, helloBookPreviewService });
        loaderModalInstance && loaderModalInstance.close();
      });
    } else {
      const helloBookLoaderTimeout = this.$timeout(() => {
        loaderModalInstance && loaderModalInstance.close();

        this.open({ familyId });
      }, 1000);
      scope.$on('$destroy', helloBookLoaderTimeout);
    }
  }
}
