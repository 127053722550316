import {
  incomeTypeOptionBuilderForMyCRM,
  clientOptionBuilderForMyCRM,
  productsGeneralSettingsBuilderForUI,
  saveProductComparisonBuilderForUI,
} from 'Common/mappers/serviceability';

class ServiceabilityService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Serviceability';
  }

  getIncomeOptions(brokerEventId, filters) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/broker-event/${brokerEventId}/client-income-options`,
        { ...filters },
      )
      .then((response) => {
        const {
          IncomeTypeOptions: incomeTypes,
          ClientOptions: clients,
        } = (response && response.data) || { incomeTypes: [], clients: [] };

        return {
          incomeTypes:
            (incomeTypes && incomeTypes.map(incomeTypeOptionBuilderForMyCRM)) ||
            [],
          clients: (clients && clients.map(clientOptionBuilderForMyCRM)) || [],
        };
      });
  }

  getFilteringProductsGeneralSettings(brokerEventId) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/ServiceabilityFilteringProductsGeneralSettingsGet`,
        { brokerEventID: brokerEventId },
      )
      .then((response) => productsGeneralSettingsBuilderForUI(response.data));
  }

  getSavedProductComparisons(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/saved-product-comparison/${familyId}`)
      .then((response) => response.data.map(saveProductComparisonBuilderForUI));
  }

  setCopyProductComparison(params, data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${params.brokerEventId}/loan-split/${params.loanStructureId}/product-split/${params.productLoanStructureId}/copy-product-comparison`,
      data,
    );
  }
}

export default ServiceabilityService;
