import template from './interceptBankConnect.html';
import controller from './interceptBankConnectCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    familyId: '<',
  },
};
