/**
 * Directive use on typehead to redirect user to contact page
 */
import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('selectClient', function selectClient($state) {
      return {
        restrict: 'A',
        link(scope, element, attrs) {
          element.bind('click', () => {
            const selectedClient = JSON.parse(attrs.selectClient);
            const familyId = parseInt(selectedClient.model.ResultID, 10);
            angular.element('#mycrmTypehead').val(selectedClient.label);
            $state.go('app.contactsSingle', {
              familyId,
            });
          });
        },
      };
    });
