import { WIDGET_ID } from 'Common/constants/dashboardWidget';
import { parseToInt10 } from 'Common/utilities/parse';

export default class InsurancePipelineStatusCtrl {
  constructor($location, dashboardService) {
    'ngInject';

    this.$location = $location;
    this.dashboardService = dashboardService;
  }

  $onInit() {
    const widgetId = parseToInt10(this.myParam.WidgetId);
    this.isReferralsReceived = WIDGET_ID.INSURANCE_PIPELINE_STATUS === widgetId;
    this.widgetTitle = 'Insurance Pipeline';
    this.widgetSubTitle = 'Value & Clients';
    this.getInsurancePipelineStatus();
  }

  getInsurancePipelineStatus() {
    this.dashboardService.getInsurancePipelineStatus().then((data) => {
      this.insurancePipelineData = data;
    });
  }

  goToPipeline() {
    this.$location.path('/app/insurance-application/pipeline');
  }
}
