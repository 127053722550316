export default class InsurancePipelineColumnSorterCtrl {
  $onChanges(changes) {
    if (changes.sorting && changes.sorting.currentValue) {
      this.initSortingType();
    }
  }

  initSortingType() {
    const columnSorting = this.sorting[this.column.id];
    if (!columnSorting) {
      return;
    }

    if (columnSorting.field === 'Created') {
      const directionSuffix =
        columnSorting.direction === 'ASC' ? 'Asc' : 'Desc';
      this.selectedSorting = `Date${directionSuffix}`;
    } else {
      this.selectedSorting = columnSorting.field;
    }
  }

  changeSorting(type) {
    this.onUpdate && this.onUpdate(this.getSorting(type));
    this.selectedSorting = type;
  }

  getSorting(type) {
    switch (type) {
      case 'DateAsc':
      case 'DateDesc':
        return {
          column: this.column,
          field: 'Created',
          direction: type === 'DateAsc' ? 'ASC' : 'DESC',
        };
      case 'Provider':
        return { column: this.column, field: 'Provider', direction: 'ASC' };
      default:
        return { column: this.column };
    }
  }
}
