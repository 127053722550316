import { pluralize } from 'Common/utilities/string';
import { TYPE_ID } from 'Common/constants/futureFinancialGoals';

const FUTURE_GOALS_TITLE = 'Future Goal';

class FutureGoalsCtrl {
  constructor(
    $stateParams,
    contactModelService,
    configService,
    contactService,
    currentUserService,
    financialGoalModelService,
  ) {
    'ngInject';

    this.stateParams = $stateParams;
    this.contactModelService = contactModelService;
    this.configService = configService;
    this.contactService = contactService;
    this.currentUserService = currentUserService;
    this.financialGoalModelService = financialGoalModelService;
  }

  $onInit() {
    this.categories = [];
    this.familyId = this.stateParams.familyId;
    this.familyInfo = {};
    this.futureGoalsCount = 0;
    this.getCategories();
    this.collapse = true;
    const {
      goalSetter,
      goalSetterFactFindMerge,
      financialGoalSummary,
    } = this.configService.feature;
    this.isShowFinancialGoals =
      goalSetter || (goalSetterFactFindMerge && this.currentUserService.isNZ);
    this.financialGoalSummary = financialGoalSummary;
    this.getFamilyInfo();
  }

  getFamilyInfo() {
    this.contactService.clientInformGet(this.familyId).then(({ data }) => {
      if (!data) {
        return;
      }
      this.familyInfo = data[0];
    });
  }

  switchCollapse() {
    this.collapse = !this.collapse;
  }

  editCategory(isEditValue, id) {
    const category = this.categories.find((data) => {
      return data.id === id;
    });
    if (!category) {
      return;
    }
    category.isEdit = !isEditValue;
  }

  getCategories() {
    this.financialGoalModelService
      .getFutureFinancialGoalCategory(this.familyId)
      .then((data) => {
        if (data && !data.length) {
          this.getFinancialGoalType();
          this.setFutureGoalsTitle();
          return;
        }
        this.categories = this.financialGoalSummary
          ? data
          : data.filter((goal) => goal.typeId !== TYPE_ID.SUMMARY);
        this.setFutureGoalsTitle();
      });
  }

  getFinancialGoalType() {
    this.financialGoalModelService.getFinancialGoalType().then(({ data }) => {
      this.futureFinancialGoalsCategories = [...data];
      return this.assignCategoryFromConstant();
    });
  }

  assignCategoryFromConstant() {
    this.futureFinancialGoalsCategories.map((result) => {
      return this.saveDescription({
        id: 0,
        typeId: result.typeId,
        type: result.type,
        details: '',
      }).then((resultData) => {
        this.assignCategoriesValues({
          id: resultData.data,
          typeId: result.typeId,
          type: result.type,
          details: '',
          familyId: this.familyId,
        });
      });
    });
  }

  assignCategoriesValues(category) {
    this.categories = [...this.categories, category];
  }

  saveDescription(category) {
    this.editCategory(category.isEdit, category.id);
    this.updateCategoryModel(category.details, category.id);
    const data = {
      ...category,
      familyId: this.familyId,
    };
    return this.contactModelService.saveFutureFinancialGoalCategory(data);
  }

  isSaveOnBlur(isSave, category) {
    if (!isSave) {
      return;
    }
    this.saveDescription(category);
  }

  updateCategoryModel(details, id) {
    this.isStarted = false;
    if (this.categories && !this.categories.length) {
      return;
    }
    const updatedCategory = this.categories.find((category) => {
      return category.id === id;
    });
    if (updatedCategory) {
      updatedCategory.details = details;
    }
    this.setFutureGoalsTitle();
  }

  setFutureGoalsTitle() {
    const categoryCount = this.categories.filter((category) => category.details)
      .length;
    this.title = pluralize(categoryCount, FUTURE_GOALS_TITLE);
  }

  onBlurEvent(category, { data }) {
    if (!category) {
      return;
    }
    category.details = data || '';
    const isSaveAuto = false;
    this.isSaveOnBlur(isSaveAuto, category);
  }
}

export default FutureGoalsCtrl;
