import { COLOR } from 'Common/constants/colors';
import { filterInitialOneString } from 'Common/utilities/contact';

export default class NoteModalCtrl {
  $onInit() {
    this.initialsIconColor = COLOR.CERULEAN;
    this.setInitials();
  }

  closeModal() {
    this.modalInstance.close();
  }

  setInitials() {
    this.initials = filterInitialOneString(this.note.owner) || '';
  }
}
