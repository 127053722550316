import template from './loanSavedCalculationList.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    lendingDetailScope: '<',
    savedCalculationsList: '<',
  },
};
