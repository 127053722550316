import angular from 'angular';
import commissionTable from './commissionTable';
import commissionSearchMatch from './commissionSearchMatch';
import commissionFilterInline from './commissionFilterInline';
import commissionFilterModal from './commissionFilterModal';
import dropdown from './dropdown';
import clientLookup from './clientLookup';

export default angular.module('commission.components', [
  commissionTable,
  commissionSearchMatch,
  commissionFilterInline,
  commissionFilterModal,
  clientLookup,
  dropdown,
]).name;
