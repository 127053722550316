export const ONBOARDING_ROLE_ID = {
  DIRECTOR: 3,
  FRANCHISEE: 5,
  GUARANTOR: 6,
  LOAN_WRITER: 9,
  EMPLOYEE: 14,
  CONTRACTOR: 15,
  PARTNER: 16,
};

export const TABS_IDX = {
  ENTITY: 1,
  ASIC: 2,
  INDIVIDUAL: 3,
  PARTIES: 4,
  QUESTIONNAIRE: 5,
  STAFF: 6,
  BRANDING: 7,
  RELATED_DOCS: 8,
};

export const SETTINGS = {
  SCROLL_OFFSET: 60,
  TIME: 1000,
};
