import * as modalUtils from '../../utils/modalUtils';

export default class EducationModal {
  constructor(loanToolsService) {
    'ngInject';

    this.loanToolsService = loanToolsService;
  }

  $onInit() {
    modalUtils.$onInit(this, 'education', 'workingCPD');
    modalUtils.initializeCommonProperties(
      this,
      'workingCPD',
      'resourceEducationSet',
    );
  }
}
