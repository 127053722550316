import { NO_FINANCIALS_QUESTIONS } from 'Common/constants/questions';
import {
  getAnswer,
  getAnswerObjToSave,
} from 'Common/utilities/loanApplication';
import { isBoolean } from 'Common/utilities/objectValidation';

class NoFinancialsCtrl {
  constructor(loanApplicationServices, $timeout, $scope) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.QUESTIONS = NO_FINANCIALS_QUESTIONS;
    this.$timeout = $timeout;
    this.$scope = $scope;
  }

  $onInit() {
    this.isCompletedSection = false;
    this.getAnswers();
  }

  getAnswers() {
    this.loanApplicationServices
      .getFundingDetailsAnswers(this.loanScenarioId)
      .then((response) => {
        if (!response) {
          return;
        }
        const answers = response;

        this.isNoFinancialsApp = getAnswer(
          answers,
          this.QUESTIONS.IS_NO_FINANCIALS,
        );
        this.creditWorthinessComment = getAnswer(
          answers,
          this.QUESTIONS.CLIENT_CREDIT_WORTHINESS,
        );
        this.meetTerms = getAnswer(answers, this.QUESTIONS.PROPOSAL_MEET_TERMS);

        this.fixTextAreaHeightTimeout = this.$timeout(() =>
          this.$scope.$broadcast('elastic:adjust'),
        );
        this.updateCompletionStatus();
      });
  }

  saveAnswer(question, answer) {
    const answerObj = getAnswerObjToSave(question, answer);
    answerObj.loanScenarioId = this.loanScenarioId;
    answerObj.familyId = this.familyId;
    this.loanApplicationServices.postFundingDetailsAnswer(answerObj);
    this.updateCompletionStatus();
  }

  updateCompletionStatus() {
    const isBooleanFalse = this.isNoFinancialsApp === false;
    const isAnsweredSubQuestion =
      this.creditWorthinessComment && isBoolean(this.meetTerms);
    this.isCompletedSection = isBooleanFalse || isAnsweredSubQuestion;
  }

  $onDestroy() {
    this.$timeout.cancel(this.fixTextAreaHeightTimeout);
  }
}

export default NoFinancialsCtrl;
