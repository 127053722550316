import template from './isFeelingStuck.html';
import controller from './isFeelingStuckCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    openModalForDemoVideo: '&',
    pageSource: '<',
  },
};
