export default class ReviewFactFindCtrl {
  constructor(
    toaster,
    $window,
    $uibModal,
    crmConfirmation,
    loanScenarioService,
    onlineFactFindModal,
  ) {
    'ngInject';

    this.toaster = toaster;
    this.$window = $window;
    this.uibModal = $uibModal;
    this.crmConfirmation = crmConfirmation;
    this.loanScenarioService = loanScenarioService;
    this.onlineFactFindModal = onlineFactFindModal;
  }

  $onInit() {
    this.getApplicationDetails();
  }

  reviewFactFind() {
    const { clientId } = this.applicantInfo;
    if (clientId) {
      this.$window.open(`${this.redirectionURL}${clientId}/${this.loanId}`);
    }
  }

  getApplicationDetails() {
    if (this.loanId) {
      this.onlineFactFindModal
        .getInvolvePartyToReview(this.loanId)
        .then((response) => {
          if (!response || !response.clients || !response.clients.length) {
            return;
          }
          this.involvedParty = response.clients;
          this.applicantInfo = response.clients[0];
          this.redirectionURL = response.link;
        });
    }
  }

  updateApplicant() {
    if (!this.applicantInfo) {
      return;
    }
    this.applicantInfo = this.involvedParty.find(
      (obj) => obj.clientId === this.applicantInfo.clientId,
    );
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}
