import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular.module('app').controller(
    'PipelineAppEditLabelModalCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function PipelineAppEditLabelModalCtrl(
      $scope,
      $uibModalInstance,
      contactService,
      pipelineService,
      toaster,
      $state,
      labels,
      listeners,
    ) {
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.save = function () {
        $scope.cancel();
      };
      $scope.addLabelsTempArray = [];
      $scope.insertLabelsTempArray = [];
      $scope.updateLabelsTempArray = [];
      $scope.prepareLabelSetArray = [];
      $scope.makeEditable = function (labelObj, $index) {
        $scope.clickedIndex = $index;
        labelObj.editLabel = true;
      };
      $scope.addnewPanelForLabel = function () {
        $scope.addLabel = true;
        $scope.labelName = '';
      };

      $scope.updateTempLabel = function (labelObj) {
        if (labelObj.pipelineSettingsLabelID) {
          for (let i = 0; i < $scope.addLabelsTempArray.length; i++) {
            if (
              labelObj.pipelineSettingsLabelID ===
              $scope.addLabelsTempArray[i].pipelineSettingsLabelID
            ) {
              $scope.addLabelsTempArray[i].LabelName = labelObj.LabelName;
              $scope.addLabelsTempArray[i].pipelineSettingsLabelID =
                labelObj.pipelineSettingsLabelID;
              $scope.addLabelsTempArray[i].LabelID =
                labelObj.pipelineSettingsLabelID;
            }
          }
          labelObj.editLabel = false;
        } else {
          $scope.addLabel = false;
          $scope.newLabel = {};
          $scope.newLabel.LabelName = labelObj;
          $scope.newLabel.pipelineSettingsLabelID = 0;
          $scope.newLabel.temp = true;
          $scope.addLabelsTempArray.push($scope.newLabel);
        }
      };
      $scope.saveLables = function () {
        $scope.labelsToSave = [];

        for (let i = 0; i < $scope.addLabelsTempArray.length; i++) {
          $scope.lbl = {};
          $scope.lbl.LabelID =
            $scope.addLabelsTempArray[i].pipelineSettingsLabelID;
          $scope.lbl.LabelName = $scope.addLabelsTempArray[i].LabelName;
          $scope.labelsToSave.push($scope.lbl);
        }
        pipelineService.PipelineSettingsLabelSet($scope.labelsToSave).then(
          () => {
            toaster.pop(
              'success',
              'Added',
              'Label has been added successfully.',
            );
            if (listeners && listeners.onLabelsSaved) {
              listeners.onLabelsSaved();
            }
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          },
        );
      };
      $scope.deleteLabel = function (labelObj, index) {
        if (labelObj.pipelineSettingsLabelID) {
          $scope.deletelbl = {};
          $scope.deletelbl.LabelID = labelObj.pipelineSettingsLabelID;
          pipelineService.PipelineSettingLabelDelete($scope.deletelbl).then(
            () => {
              toaster.pop(
                'success',
                'Deleted',
                'Label has been deleted successfully.',
              );
              const removedIndex = _.findIndex(
                $scope.addLabelsTempArray,
                (label) =>
                  label.pipelineSettingsLabelID ===
                  labelObj.pipelineSettingsLabelID,
              );
              $scope.addLabelsTempArray.splice(removedIndex, 1);
              if (listeners && listeners.onLabelDeleted) {
                listeners.onLabelDeleted();
              }
            },
            () => {
              toaster.pop('error', 'Error', 'Yikes! Something is wrong');
            },
          );
        } else {
          $scope.addLabelsTempArray.splice(index, 1);
        }
      };
      $scope.labelList = labels;
      $scope.addLabelsTempArray = angular.copy($scope.labelList);
    },
  );
