import angular from 'angular';
import * as util from '../util/forSetRateModalCtrl';

export const loadModule = () =>
  angular
    .module('app')
    .controller('ForSetRateModalCtrl', function ForSetRateModalCtrl(
      $scope,
      $uibModalInstance,
      save,
      $state,
      rateType,
      loanStructure,
      productId,
      oldRate,
      newRate,
      $timeout,
      oldYear,
      isRates,
      isDiscountRates,
      isFees,
      discountOrFeeType,
    ) {
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.rateType = rateType;
      $scope.selectedProduct = {};
      $scope.selectedProduct.rateType = `${$scope.rateType}Rate`;
      $scope.selectedProduct.LoanStructureID = loanStructure.LoanStructureID;
      $scope.selectedProduct.ProductID = productId;
      $scope.selectedProduct.OldRate = oldRate;
      $scope.selectedProduct.NewRate = newRate;
      $scope.selectedProduct.OldYear = oldYear;

      $scope.isRates = isRates;
      $scope.isDiscountRates = isDiscountRates;
      $scope.isFees = isFees;

      $scope.title = util.getTitle($scope, rateType);
      $scope.shortTitle = util.getShortTitle($scope);

      $scope.toLower = util.toLower;

      $scope.update = util.update(
        $scope,
        rateType,
        isRates,
        isDiscountRates,
        isFees,
        discountOrFeeType,
        save,
        $timeout,
      );

      $scope.revert = util.revert($scope);
    });
