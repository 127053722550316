import angular from 'angular';

class EmploymentModalCtrl {
  constructor(
    $uibModalInstance,
    newIncomeDetails,
    isFromIncome,
    employmentId,
    $stateParams,
    loanAppId,
    clientId,
    familyId,
    action,
    items,
  ) {
    this.familyId = familyId || $stateParams.familyId;
    this.newIncomeDetails = newIncomeDetails;
    this.loanAppId = $stateParams.loanAppId;
    this.modalInstance = $uibModalInstance;
    this.employmentId = employmentId || 0;
    this.isFromIncome = isFromIncome;
    this.clientId = clientId || 0;
    this.action = action || 'Add';
    this.items = items || {};
  }
}

export const loadModule = () =>
  angular.module('app').controller('EmploymentModalCtrl', EmploymentModalCtrl);
