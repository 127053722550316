import { sortReleaseByDate, getHeading } from './util/releaseNotes';

const RELEASE_TYPE_ID = {
  NEW: 1,
  ENHANCEMENTS: 2,
  FIXES: 3,
  TECH_DEBT: 4,
  MAINTENANCE: 6,
};
const RELEASE_NOTE_KEYS = {
  TYPE_STR: {
    [RELEASE_TYPE_ID.NEW]: "What's new",
    [RELEASE_TYPE_ID.ENHANCEMENTS]: 'Enhancements',
    [RELEASE_TYPE_ID.FIXES]: 'Fixes',
    [RELEASE_TYPE_ID.TECH_DEBT]: 'Tech Debt',
    [RELEASE_TYPE_ID.MAINTENANCE]: 'Maintenance',
  },
  RELEASE_FILTER: {
    PREVIOUS: 'Previous',
    RECENT: 'Recent',
  },
};

const RELEASE_NOTES_DEFAULTS = {
  PAGE_RECENT: 1,
  PAGE_PREVIOUS: 1,
  PAGE_ALL: 1,
  PAGE_SIZE: 5,
};
class ReleaseNotesCtrl {
  constructor($state, uiService, configService, releaseNotesService) {
    'ngInject';

    this.$state = $state;
    this.uiService = uiService;
    this.configService = configService;
    this.releaseNotesService = releaseNotesService;
    this.brand = this.configService.isRW ? 'raywhite' : 'loanmarket';
    this.emptyHolder = '';
    this.currentPageRecent = RELEASE_NOTES_DEFAULTS.PAGE_RECENT;
    this.currentPagePrevious = RELEASE_NOTES_DEFAULTS.PAGE_PREVIOUS;
    this.currentPageAll = RELEASE_NOTES_DEFAULTS.PAGE_ALL;
    this.pageSize = RELEASE_NOTES_DEFAULTS.PAGE_SIZE;
    this.getHeading = getHeading;
  }

  $onInit() {
    this.RELEASE_TYPE_ID = RELEASE_TYPE_ID;
    this.allReleaseNotes = [];
    this.getReleaseNotes(RELEASE_NOTE_KEYS.RELEASE_FILTER.RECENT);
    this.getReleaseNotes(RELEASE_NOTE_KEYS.RELEASE_FILTER.PREVIOUS);
    this.logicalSortingReleaseFlag = this.configService.feature.logicalSortingRelease;
  }

  getSingularName(length, name) {
    if (length > 1 || name === 'New') {
      return name;
    }
    this.singularDictionary = {
      Improvements: 'Improvement',
      Fixes: 'Fix',
    };
    return this.singularDictionary[name];
  }

  getNoteTypeHeader(note) {
    if (!note || !note.typeName) {
      return this.emptyHolder;
    }
    return RELEASE_NOTE_KEYS.TYPE_STR[note.typeId];
  }

  getAllReleaseNotes(pageNumber = 1) {
    this.getReleaseNotes(RELEASE_NOTE_KEYS.RELEASE_FILTER.RECENT, pageNumber);
    this.getReleaseNotes(RELEASE_NOTE_KEYS.RELEASE_FILTER.PREVIOUS, pageNumber);
  }

  getReleaseNotes(filterType, pageNumber = 1) {
    const data = {
      brandName: this.brand,
      releaseFilterType: filterType,
      pageNumber,
      pageSize: this.pageSize,
    };
    this.releaseNotesService.getReleaseNotes(data).then((response) => {
      if (!response) {
        return;
      }
      if (filterType === RELEASE_NOTE_KEYS.RELEASE_FILTER.RECENT) {
        this.releaseNotesRecent =
          pageNumber === 1
            ? response
            : [...this.releaseNotesRecent, ...response];
        this.allReleaseNotes = [
          ...new Set([...this.allReleaseNotes, ...this.releaseNotesRecent]),
        ].sort(sortReleaseByDate);
      }
      if (filterType === RELEASE_NOTE_KEYS.RELEASE_FILTER.PREVIOUS) {
        this.releaseNotesPrevious =
          pageNumber === 1
            ? response
            : [...this.releaseNotesPrevious, ...response];
        this.allReleaseNotes = [
          ...new Set([...this.allReleaseNotes, ...this.releaseNotesPrevious]),
        ].sort(sortReleaseByDate);
      }

      this.uiService.newReleases = 0;
    });
  }

  isShowPager(releaseNote, isAllReleaseNotes = false) {
    let noteIndex = 0;
    if (isAllReleaseNotes) {
      noteIndex = releaseNote.length - 1;
    }
    return (
      (releaseNote &&
        releaseNote.length &&
        releaseNote[noteIndex].totalReleaseCount) > this.pageSize
    );
  }

  hasMore(releaseNote, noteFilterType) {
    let noteIndex = 0;
    if (noteFilterType === 'all') {
      noteIndex = releaseNote.length - 1;
    }
    this.hasMoreNotes = releaseNote
      ? releaseNote.length < releaseNote[noteIndex].totalReleaseCount
      : false;
    return this.hasMoreNotes;
  }

  getPageNumber(data, currentPage, noteFilterType) {
    const currentPageNumber = this.hasMore(data, noteFilterType)
      ? currentPage + 1
      : 1;
    this.currentPageAll = currentPageNumber;
    this.currentPageRecent =
      noteFilterType === 'recent' ? currentPageNumber : this.currentPageRecent;
    this.currentPagePrevious =
      noteFilterType === 'previous'
        ? currentPageNumber
        : this.currentPagePrevious;

    return currentPageNumber;
  }
}

export default ReleaseNotesCtrl;
