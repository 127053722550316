import angular from 'angular';
import { toastError } from 'Common/utilities/alert';
import { TMD_QUESTION_ID } from 'Common/constants/loanappSectionIds';

class TargetMarketDeterminationCtrl {
  constructor(loanScenarioModelService, configService, loanAppSharedData) {
    'ngInject';
    this.loanScenarioModelService = loanScenarioModelService;
    this.configService = configService;
    this.loanAppSharedData = loanAppSharedData;

    this.saveRecommendedReason = this.saveRecommendedReason.bind(this);
    this.saveOtherRecommendedReason = this.saveOtherRecommendedReason.bind(
      this,
    );
    this.saveIsClientFitTmd = this.saveIsClientFitTmd.bind(this);
    this.saveValue = this.saveAnswerForQuestionId.bind(this);
    this.save = this.saveQuestion.bind(this);
    this.isEmpty = this.isEmpty.bind(this);
    this.TMD_QUESTION_ID = TMD_QUESTION_ID;
  }

  isTmdValid() {
    return this.loanAppSharedData.isTmdValid();
  }

  isEmpty(questionId) {
    return (
      this.loanAppSharedData.tmdModel[questionId].answerString === null ||
      this.loanAppSharedData.tmdModel[questionId].answerString === undefined
    );
  }

  getQuestion(questionId) {
    return this.loanAppSharedData.tmdModel[questionId].question;
  }

  getAnswer(questionId) {
    return this.loanAppSharedData.tmdModel[questionId].answerString;
  }

  getPlaceholder(questionId) {
    return this.loanAppSharedData.tmdModel[questionId].placeholder;
  }

  saveIsClientFitTmd(value) {
    this.saveAnswerForQuestionId(value, TMD_QUESTION_ID.QUESTION1);
    if (value === true) {
      this.saveRecommendedReason(undefined);
      this.saveOtherRecommendedReason(undefined);
    }
  }

  saveRecommendedReason(value) {
    this.saveAnswerForQuestionId(value, TMD_QUESTION_ID.QUESTION2);
    if (value !== 'Other') {
      this.saveOtherRecommendedReason(undefined);
    }
  }

  saveOtherRecommendedReason(value) {
    this.saveAnswerForQuestionId(value, TMD_QUESTION_ID.QUESTION3);
  }

  saveAnswerForQuestionId(value, questionId) {
    if (
      Object.prototype.hasOwnProperty.call(
        this.loanAppSharedData.tmdModel,
        questionId,
      )
    ) {
      this.loanAppSharedData.tmdModel[questionId].answerString = value;
      return this.saveQuestion(this.loanAppSharedData.tmdModel[questionId]);
    } else {
      toastError(new Error(`cannot find model with id ${questionId}`));
    }
  }

  saveQuestion(question) {
    if (!question) {
      return;
    }
    const clonedQuestion = angular.copy(question);
    if (typeof question.answerString === 'string') {
      clonedQuestion.answerString = clonedQuestion.answerString.replace(
        /\n/g,
        '<br />',
      );
    }
    return this.loanScenarioModelService
      .postRationaleQuestions(clonedQuestion)
      .then((_) => {
        this.answerCallback();
      })
      .catch(toastError);
  }
}

export default TargetMarketDeterminationCtrl;
