export const PIPELINE_STATUS = {
  APPLICATION: 1,
  APPOINTMENT: 1,
  SETTLED: 2,
  CONTACT_MADE: 3,
  NEW_LEADS: 4,
  NEW_APPLICATION: 7,
  NOT_PROCEEDED: 9,
  TO_OPPORTUNITY: 10,
  NEW_OPPORTUNITY: 13,
  REQUIREMENTS: 14,
  RESEARCHING: 15,
};

export const PIPELINE_CATEGORY = {
  APPLICATION: 'Application',
  NEW_LEAD: 'New Lead',
  OPPORTUNITY: 'Opportunity',
};

export const LEAD_PIPELINE_STATUS = {
  APPOINTMENT: 1,
  CONTACTING: 2,
  NEW_LEADS: 4,
};

export const LEAD_STATUSES = [
  LEAD_PIPELINE_STATUS.APPOINTMENT,
  LEAD_PIPELINE_STATUS.CONTACTING,
  LEAD_PIPELINE_STATUS.NEW_LEADS,
];

export const OPPORTUNITY_PIPELINE_STATUS = {
  RESEARCHING: 15,
  PROPOSAL_SENT: 16,
};

export const OPPORTUNITY_STATUSES = [
  OPPORTUNITY_PIPELINE_STATUS.RESEARCHING,
  OPPORTUNITY_PIPELINE_STATUS.PROPOSAL_SENT,
];

export const CONVERSION_PIPELINE_STATUS = {
  TO_APPLICATION: 1,
  TO_SETTLED: 2,
  TO_OPPORTUNITY: 10,
  TO_NOT_PROCEEDED: 9,
};

export const CONVERSION_PIPELINE_OPTIONS = {
  LEADS: [
    {
      PIPELINE_STATUS: 'Application',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_APPLICATION,
    },
    {
      PIPELINE_STATUS: 'Opportunity',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_OPPORTUNITY,
    },
    {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      PIPELINE_STATUS: 'Not Proceeded',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED,
    },
  ],
  OPPORTUNITY: [
    {
      PIPELINE_STATUS: 'Application',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_APPLICATION,
    },
    {
      PIPELINE_STATUS: 'Not Proceeded',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED,
    },
  ],
  APPLICATION: [
    {
      PIPELINE_STATUS: 'Settled',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_SETTLED,
    },
    {
      PIPELINE_STATUS: 'Not Proceeded',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED,
    },
  ],
};

export const LEAD_COLUMNS_IN_OPPORTUNITY_PIPELINE = [
  'New Leads',
  'Contacting',
  'Contact Made',
  'Appointment',
  'New Lead',
  'Lead - Contacting',
  'Lead - Contact Made',
  'Lead - Appointment',
];
