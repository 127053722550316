import { toastError } from 'Common/utilities/alert';
import { EXTRA_STATUS } from 'Common/constants/extraLoanStatus';

export const isImportantDatesInvalid = ({ $scope }) => {
  $scope.isLoanSubmitted = true;
  const isInvalidForm =
    $scope.LoanDetailsSet &&
    $scope.LoanDetailsSet.Loan &&
    $scope.LoanDetailsSet.Loan.isInvalidForm;
  if (isInvalidForm) {
    toastError(
      'Updated Loan Status will not save until mandatory important dates are entered',
    );
  }
  return isInvalidForm;
};

export const sendNPSSurvey = ({
  $scope = {},
  currentUserService,
  LOAN_STATUS,
}) => {
  $scope.currentUserService = currentUserService;
  $scope.LOAN_SETTLE = (LOAN_STATUS.EXISTING.SETTLED || '').toString();
  const { isNZ, nPSEnabled } = $scope.currentUserService;
  $scope.onSaveNPSSurvey = (isNPS) => {
    $scope.LoanDetailsSet.Loan.IsNPSSurvey = isNPS;
  };

  $scope.isNPSNotSetOnSave = () => {
    const npsMandatoryForNZ = isNZ && nPSEnabled;
    const isUndefined =
      typeof $scope.LoanDetailsSet.Loan.IsNPSSurvey === 'undefined';
    const { LoanStatus } = $scope.LoanDetailsSet.Loan;
    const isSettled = $scope.LOAN_SETTLE === LoanStatus.LoanStatusID;
    if (npsMandatoryForNZ && isUndefined && isSettled) {
      toastError('Please select Send NPS Survey');
    }
    return npsMandatoryForNZ && isUndefined && isSettled;
  };
};

export const loanStatusList = () => {
  return [
    { LoanStatusMainGroup: [EXTRA_STATUS.SETTLED] },
    {
      LoanStatusMiscGroup: [EXTRA_STATUS.CANCELLED, EXTRA_STATUS.LOAN_REPAID],
    },
  ];
};

export const alterListOFLoanTools = ((showTools) => {
  return {
    forRiskWriters: ({
      $scope = {},
      expectedInScopeList = [],
      hasPropOf = '',
    }) => {
      const showLendingTools = ($scope.uiService || { showLendingTools: false })
        .showLendingTools;
      const isCreate =
        ($scope.params || { loanInformationAction: '' })
          .loanInformationAction === 'create';

      const filterForRiskWriter = (ids) => (status) => {
        return ids.includes(+status[hasPropOf]);
      };

      const filterOptions = () => {
        expectedInScopeList.forEach((options) => {
          const keys = Object.keys(options).join('');
          $scope[keys] = $scope[keys].filter(
            filterForRiskWriter(options[keys]),
          );
        });
      };
      showTools({
        isValidToShow: !showLendingTools && isCreate,
        filterOptions,
      });
    },
  };
})(({ filterOptions, isValidToShow }) => {
  isValidToShow && filterOptions();
});
