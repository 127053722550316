import { camelize, capitalizeFirstLetterOnly } from 'Common/utilities/string';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function camelizeOrCapital(collection, isCapital = false) {
  let newObject = {};
  if (collection && Array.isArray(collection)) {
    newObject = collection.map((value) => {
      return typeof value === 'object' ? camelizeOrCapital(value) : value;
    });
  } else {
    collection &&
      Object.keys(collection).forEach((origKey) => {
        if (typeof collection[origKey] !== 'undefined') {
          const newKey = isCapital
            ? capitalizeFirstLetterOnly(origKey)
            : camelize(origKey);
          const value = collection[origKey];
          const isToCamelize =
            Array.isArray(value) ||
            (value !== null && value.constructor === Object);
          newObject[newKey] = isToCamelize ? camelizeOrCapital(value) : value;
        }
      });
  }
  return newObject;
}

export const collectionCamelize = (collection) => {
  return camelizeOrCapital(collection);
};

export const collectionCapitalize = (collection) => {
  return camelizeOrCapital(collection, true);
};
