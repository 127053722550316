const httpInterceptor = ($sce, legacyAngularHost) => ({
  request: (config) => {
    const isTrustedValueHolder = typeof config.url === 'object';
    const legacyAngularHostValue = legacyAngularHost.value;
    const originalUrl = isTrustedValueHolder
      ? $sce.getTrustedUrl(config.url)
      : config.url;
    let newUrl = originalUrl.replace(
      /^(\.{1,2}\/)+(assets|src\/(components|scenes))/,
      '/$2',
    );

    const interceptWithoutSlash = /^\/(assets|src\/(components|scenes))\//.test(
      newUrl,
    );
    const interceptWithSlash = /^(assets|src\/(components|scenes))\//.test(
      newUrl,
    );
    if (interceptWithoutSlash) {
      newUrl = `${legacyAngularHostValue}${newUrl}`;
    } else if (interceptWithSlash) {
      newUrl = `${legacyAngularHostValue}/${newUrl}`;
    }
    if (newUrl !== originalUrl) {
      config.url = isTrustedValueHolder ? $sce.trustAsUrl(newUrl) : newUrl;
    }
    return config;
  },
});

export { httpInterceptor };
