import { taskListGetBuilderForUI } from 'Common/mappers/tasks.js';

export default class LoanTaskCtrl {
  constructor(
    $uibModal,
    taskService,
    tasksService,
    tasksSharedData,
    currentUserService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.taskService = taskService;
    this.tasksService = tasksService;
    this.tasksSharedData = tasksSharedData;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.maxTotalLoanRecords = 3;
    this.getTaskList();

    if (!this.loanId) {
      this.loanId = 0;
    }
  }

  viewAllLoanTask() {
    this.maxTotalLoanRecords = this.totalLoanTask;
  }

  getTaskList() {
    const dateFilter = 'all';
    const createdByFilter = '';
    const familyId = 0;
    const isShowCompleted = false;
    const { pageNumber, pageSize, sortColumn, sortType, taskSearch } = '';
    const adviserFilter = 0;
    const params = {
      adviserFilter,
      dateFilter,
      familyId,
      createdByFilter,
      isShowCompleted,
      pageNumber,
      pageSize,
      sortColumn,
      sortType,
      taskSearch,
      loanId: this.loanId,
    };
    this.tasksService.getTaskList(params).then(({ data }) => {
      const loanTaskList = data.TaskList.map(taskListGetBuilderForUI) || [];
      this.totalLoanTask = data.TotalRecords;
      this.loanTaskList = loanTaskList;
      this.totalDueToday = (
        loanTaskList.filter((o) => o.isDueToday) || []
      ).length;
    });
  }

  toggleOneComplete(task) {
    if (!task.isCompleted) {
      this.taskCompletionSet(task);
      return;
    }

    this.tasksSharedData.completionConfirmation(
      () => {
        this.taskCompletionSet(task);
      },
      () => {
        task.isCompleted = false;
      },
    );
  }

  taskCompletionSet(task) {
    this.taskService
      .taskSingleCompletion(task.id, task.familyId, task.isCompleted)
      .then(() => {
        this.getTaskList();
      });
  }

  openTaskFormModal(taskId = 0) {
    const props = {
      familyId: this.familyId,
      familyList: this.familyList,
      loanId: this.loanId,
      taskId,
      isLinkToOpportunity: true,
      selectedOpportunityId: 0,
      opportunityList: [{ opportunityId: 0, title: this.opportunityTitle }],
    };

    this.$uibModal
      .open({
        template: `<task-form-modal
        modal-instance="vm.modalInstance"
        family-id="vm.props.familyId"
        family-list="vm.props.familyList"
        loan-id="vm.props.loanId"
        task-id="vm.props.taskId"
        is-link-to-opportunity="vm.props.isLinkToOpportunity"
        selected-opportunity-id="vm.props.selectedOpportunityId"
        opportunity-list="vm.props.opportunityList"
      ></task-form-modal>`,
        windowClass: 'edit-task-window',
        size: 'md',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props: () => props,
        },
      })
      .result.then((response) => {
        if (response === 'saved') {
          this.getTaskList();
        }
      });
  }

  openTaskTemplatedModal(taskId = 0) {
    const props = {
      loanId: this.loanId,
      taskId,
      familyId: this.familyId,
      familyList: this.familyList,
      isLinkToOpportunity: true,
      selectedOpportunityId: 0,
      opportunityList: [{ opportunityId: 0, title: this.opportunityTitle }],
    };

    this.$uibModal
      .open({
        template: `<task-templated
          modal-instance="vm.modalInstance"
          family-id="vm.props.familyId"
          family-list="vm.props.familyList"
          loan-id="vm.props.loanId"
          task-id="vm.props.taskId"
          is-link-to-opportunity="vm.props.isLinkToOpportunity"
          selected-opportunity-id="vm.props.selectedOpportunityId"
          opportunity-list="vm.props.opportunityList"
        ></task-templated>`,
        size: 'lg',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        windowClass: 'view-task-window',
        resolve: {
          props: () => props,
        },
      })
      .result.then(
        () => {
          this.getTaskList();
        },
        () => {
          this.getTaskList();
        },
      );
  }
}
