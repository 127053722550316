import set from 'lodash/set';
import {
  DOCUMENT_REQUEST_METHOD_ID_DEFAULT,
  DOCUMENT_REQUEST_METHOD_FILE_INVITE,
  DOCUMENT_REQUEST_METHOD_FACT_FIND,
  DOCUMENT_REQUEST_METHOD_ID,
} from 'Common/constants/helloBook';

export default class HelloPackDocumentRequestCtrl {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;
  }

  $onInit() {
    const fileInviteMethod = this.uiService.fileInviteEnabled
      ? [DOCUMENT_REQUEST_METHOD_FILE_INVITE]
      : [];
    this.methods = [...fileInviteMethod, DOCUMENT_REQUEST_METHOD_FACT_FIND];
    this.sendClientMethodId = DOCUMENT_REQUEST_METHOD_ID.FILE_INVITE;

    var defaultRequestMethod = this.uiService.fileInviteEnabled
      ? DOCUMENT_REQUEST_METHOD_ID.FILE_INVITE
      : DOCUMENT_REQUEST_METHOD_ID_DEFAULT;
    set(this, 'model.documentRequestMethod', defaultRequestMethod);
  }
}
