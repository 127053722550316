import { QUOTE_SETTINGS_INDEX } from 'Common/constants/quickQuoteOrigin';

export const benefitValue = ({ benefitEntityList, productId, benefit }) => {
  return benefitEntityList &&
    benefitEntityList.length > QUOTE_SETTINGS_INDEX.DISCOUNT_OPTIONS &&
    benefitEntityList[QUOTE_SETTINGS_INDEX.DISCOUNT_OPTIONS]
      .ProductEntityList &&
    benefit === 'DiscountOption'
    ? benefitEntityList[
        QUOTE_SETTINGS_INDEX.DISCOUNT_OPTIONS
      ].ProductEntityList.filter((item) => item.IsSelected)
        .map((item) => item.ProductId)
        .join(',')
    : productId;
};

export const newSetting = (obj, isSelected) => {
  return {
    SettingID: obj.SettingID,
    ProviderID: obj.ProviderId,
    IsActive: isSelected || obj.IsSelected,
  };
};

export const changeSelectionUtil = ({
  $scope,
  providerObj,
  productId,
  benefit,
  index,
}) => {
  if (benefit === 'DiscountOption' && index) {
    const vitalityDiscountIndex = 0;
    const product =
      providerObj.BenefitEntityList[QUOTE_SETTINGS_INDEX.DISCOUNT_OPTIONS]
        .ProductEntityList;
    const vitalityFee = product[index].IsSelected;
    providerObj.BenefitEntityList[
      QUOTE_SETTINGS_INDEX.DISCOUNT_OPTIONS
    ].ProductEntityList[vitalityDiscountIndex].IsSelected = vitalityFee
      ? product[vitalityDiscountIndex].IsSelected
      : false;
  }
  const qouteSettings = $scope.data.quoteSettings;
  if (qouteSettings && qouteSettings.length) {
    const matchedSetting = qouteSettings
      .filter((qs) => qs.SettingID === providerObj.SettingID)
      .map((itemSetting) => {
        itemSetting[benefit] = benefitValue({
          benefitEntityList: providerObj.BenefitEntityList,
          productId,
          benefit,
        });

        itemSetting.IsActive = providerObj.IsSelected;
        return itemSetting;
      });
    if (matchedSetting.length) {
      $scope.data.quoteSettings = matchedSetting;
      return;
    }
  }
  const setting = newSetting(providerObj);
  setting[benefit] = benefitValue({
    benefitEntityList: providerObj.BenefitEntityList,
    productId,
    benefit,
  });
  $scope.data.quoteSettings.push(setting);
};
