export const MODAL_SECTION = {
  CPA: 1,
  CQP: 2,
  E_SIGN: 3,
  OPTION_WINDOW: 4,
};

export const DOC_GENERATOR = {
  CPA_CQP: 'openRecommendationModal',
  GAME_PLAN: 'launchCQPModal',
};

export const HELPTEXT_GENERATOR = {
  [DOC_GENERATOR.CPA_CQP]: 'generating CPA/CQP',
  [DOC_GENERATOR.GAME_PLAN]: 'generating Game Plan',
};
