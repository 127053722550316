import { getDatePickerConfig } from 'Common/utilities/date';

class MarkCompletedPreApprovalModalCtrl {
  constructor(customerCareService) {
    'ngInject';

    this.customerCareService = customerCareService;
  }

  $onInit() {
    this.dateConfig = getDatePickerConfig({
      dateOptions: {
        showWeeks: false,
      },
    });
    this.showDateCalendar = false;
    const { ExpiryDate } = this.preApproval;
    this.newPreApprovalDate = new Date(ExpiryDate) || '';
  }

  savePreExpiry() {
    this.isSaveDisabled = true;
    const { ExpiryDate, LoanID } = this.preApproval;
    const postBody = {
      currentEventDate: ExpiryDate,
      newEventDate: this.newPreApprovalDate,
      loanId: LoanID,
      isCompleted: true,
    };
    this.customerCareService
      .setPreApprovalCompletion(postBody)
      .then((response) => {
        this.modalInstance.close(!!response);
      });
  }

  toggleCalendar() {
    this.showDateCalendar = !this.showDateCalendar;
  }

  closeModal() {
    this.modalInstance.close(true);
  }
}
export default MarkCompletedPreApprovalModalCtrl;
