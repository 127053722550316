import angular from 'angular';
import fundForm from './fundForm';
import mortgageItem from './mortgageItem';
import securityBadgeList from './securityBadgeList';
import guaranteePledgeLimit from './guaranteePledgeLimit';
import fundingBreakdownItem from './fundingBreakdownItem';
import addFundingBreakdownItem from './addFundingBreakdownItem';
import securityTransactionTypeSelection from './securityTransactionTypeSelection';

export default angular
  .module('scenes.fundingDetailsSection.components', [])
  .component('fundForm', fundForm)
  .component('mortgageItem', mortgageItem)
  .component('securityBadgeList', securityBadgeList)
  .component('guaranteePledgeLimit', guaranteePledgeLimit)
  .component('fundingBreakdownItem', fundingBreakdownItem)
  .component('addFundingBreakdownItem', addFundingBreakdownItem)
  .component(
    'securityTransactionTypeSelection',
    securityTransactionTypeSelection,
  ).name;
