import { PIPELINE_CATEGORIES } from 'Common/constants/pipelineCategories';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { TAB_ORDER, FILE_TAB_ORDER } from 'Common/constants/opportunity';
import {
  displaySuccess,
  displayError,
  toastSuccess,
} from 'Common/utilities/alert';

export function getInvolvedPartyBasicModel(isClient) {
  return {
    ClientEntityID: 0,
    FamilyID: 0,
    DisplayName: '',
    IsClient: isClient,
    AddRelatedChildren: true,
  };
}

export function getLoanOpportunityBasicModel() {
  return {
    AllocatedAdviserID: 0,
    Title: '',
    Borrowers: [],
    Guarantors: [],
  };
}

export function goToOpportunity($state, queryParams, config = {}) {
  // just use activeTab: 'summary' prop for future use
  const { tabName, activeTab, ...extractParams } = queryParams;
  const activeTabOutput =
    tabName === 'details' && activeTab ? activeTab : 'summary';

  $state.go(
    'app.opportunity',
    { ...extractParams, activeTab: activeTabOutput },
    config,
  );
}

export function getOpportunityStatus(pipelineService) {
  return pipelineService.SettingsStatusGetFiltered().then((res) => {
    const { data } = res;
    if (!res || !data) {
      return;
    }

    const statusList =
      data.filter(
        (obj) => obj.PipelineCategoryID === PIPELINE_CATEGORIES.OPPORTUNITY,
      ) || [];
    if (statusList.length) {
      statusList.push(
        {
          PipelineStatus: 'Convert to Application',
          PipelineStatusID: PIPELINE_STATUS.APPLICATION,
          IsConversionStatus: true,
        },
        {
          PipelineStatus: 'Not Proceeded',
          PipelineStatusID: PIPELINE_STATUS.NOT_PROCEEDED,
          IsConversionStatus: true,
        },
      );
    }
    return statusList;
  });
}

export function showSwalAfterSending(isSuccessful, type) {
  if (isSuccessful) {
    displaySuccess(`${type} sent successfully`);
  } else {
    displayError(
      `There is a problem sending the ${type} right now. Try again later`,
    );
  }
}

export function getRecipientDetails(party) {
  if (!party) {
    return [];
  }
  return party.reduce((accum, current) => {
    const {
      FamilyFullName,
      FirstName,
      LastName,
      OrganisationId,
      PersonId,
      Email,
      Phone,
      FamilyId: familyId,
    } = current;
    const name = OrganisationId
      ? `${FamilyFullName}`
      : `${FirstName} ${LastName}`;
    const findEmail =
      Email && Email.length && Email.find((o) => o.Type === 'Email');
    const findMobileNumber =
      Phone && Phone.length && Phone.find((o) => o.Type === 'Mobile');

    const emailObject = {
      id: OrganisationId || PersonId,
      name,
      familyId,
      emailAddress: (findEmail && findEmail.EmailAddress) || '',
      phoneNumber: findMobileNumber && findMobileNumber.Number,
      nameWithMobile: `${name} (${
        findMobileNumber && findMobileNumber.Number
      })`,
    };
    return accum.concat(emailObject);
  }, []);
}

export function getRecipients(involvedParties) {
  const { InvolvedPartyPerson, InvolvedPartyEntity } = involvedParties || {};

  const personRecipients = getRecipientDetails(InvolvedPartyPerson);
  const entityRecipients = getRecipientDetails(InvolvedPartyEntity);
  return [...personRecipients, ...entityRecipients];
}

export function changeLoanSubStatus({
  subStatus,
  card,
  showNotification = true,
  loanScenarioModelService,
}) {
  const { loanId } = card;

  card.pipelineStatusID = subStatus.StatusID;
  card.subStatusID = subStatus.pipelineSubStatusID;
  card.subStatusName = subStatus.SubStatusName;

  const params = {
    loanId,
    subStatusId: card.subStatusID,
  };

  loanScenarioModelService.setSubStatus(params).then(({ data }) => {
    if (data && showNotification) {
      toastSuccess('Substatus Successfully Saved!');
    }
  });
}

export function changeLoanProbability({
  probability,
  card,
  loanScenarioModelService,
}) {
  const { loanId } = card;

  card.probability = probability;
  const params = {
    loanId,
    probability,
  };

  loanScenarioModelService.setProbability(params).then(({ data }) => {
    if (data) {
      toastSuccess('Probability Successfully Saved!');
    }
  });
}

export const getTabOrder = (isViewOfFileOn, tabIdentifier) => {
  const order = isViewOfFileOn
    ? FILE_TAB_ORDER[tabIdentifier]
    : TAB_ORDER[tabIdentifier];
  return order || 0;
};
