import {
  setCurrrentDate,
  computeDays,
  dateLesserThan,
} from 'Common/utilities/date';
import { range, forOwn, get } from 'lodash';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';
import {
  defaultMultiselectItemValues,
  tickSavedOptions,
  tickAllOptions,
  getTickValues,
} from 'Common/utilities/multiselect';
import { toastError } from 'Common/utilities/alert';
import { getDataURI } from 'Common/utilities/document';
import {
  formatWidgetData,
  formatDate,
  structureHistoryHeader,
  getHeaderCount,
  getClassName,
  getStatementMonths,
} from './util/commissionDashboardUtil';
import {
  COMMISSION_STATS,
  DEFAULT_BROKER_FILTER,
  MULTI_SELECT_LANG,
  FINANCIAL_YEAR_FILTER,
} from './constants';

const TRANSACTION_CALENDAR_TYPE = 0;

export default class CommissionDashboardCtrl {
  constructor(
    $q,
    commissionService,
    commissionHelperService,
    downloadDataService,
  ) {
    'ngInject';

    this.$q = $q;
    this.commissionService = commissionService;
    this.commissionHelperService = commissionHelperService;
    this.downloadDataService = downloadDataService;
  }

  $onInit() {
    this.dateToday = new Date();
    this.pageSource = PAGE_SOURCE.COMMISSIONS;
    this.totalCommission = {};
    this.commissionDateFilterList = [];
    this.brokers = [];
    this.brokerIds = [];
    this.statementMonths = [];
    this.statementDates = [];
    this.calendarLoaded = false;
    this.datePickerOptions = {
      customClass: this.getStatementClass.bind(this),
      showWeeks: false,
    };
    this.commissionAllTypeDateSelected = FINANCIAL_YEAR_FILTER;
    this.commissionBrokerDateSelected = FINANCIAL_YEAR_FILTER;
    this.localLang = MULTI_SELECT_LANG;
    this.date = formatDate(setCurrrentDate());
    this.getFilterDates();
    this.initAdviserDropdown();
    this.initWidgets();
  }

  initAdviserDropdown() {
    const initTransactionMonths = [];
    getStatementMonths(this.dateToday).map((date) => {
      this.statementMonths.push(date);
      initTransactionMonths.push(
        this.commissionService.AvailableDatesOfStatementsGet(
          date,
          TRANSACTION_CALENDAR_TYPE,
        ),
      );
    });
    const promises = [
      this.commissionService.getAllBrokers(),
      this.commissionService.assignBrokersGet(),
      ...initTransactionMonths,
    ];

    this.$q.all(promises).then((responses) => {
      if (!get(responses, 'length')) {
        return;
      }
      const [brokers, selectedBrokers, currMonth, prevMonth] = responses;

      const currMonthDates = get(currMonth, 'data');
      const prevMonthDates = get(prevMonth, 'data');
      if (currMonth && prevMonth) {
        this.statementDates = [
          ...this.statementDates,
          ...currMonthDates,
          ...prevMonthDates,
        ];
      }
      this.calendarLoaded = true;

      if (!get(brokers, 'data')) {
        return;
      }

      this.brokers = brokers.data.map((item) => {
        return defaultMultiselectItemValues(item.BrokerFullName, item.BrokerID);
      });

      if (!get(selectedBrokers, 'data')) {
        return;
      }

      this.selectedBrokers = tickSavedOptions(
        this.brokers,
        selectedBrokers.data,
      );
      this.brokerIds = selectedBrokers.data;
      this.initTotalCommissionWidgets();
    });
  }

  initWidgets(includeSummary) {
    this.initCommissionPie();
    this.initCommissionPerBroker();
    this.initTransactionHistory();
    includeSummary && this.initTotalCommissionWidgets();
  }

  onChangePieFilter(data) {
    this.commissionAllTypeDateSelected = data;
    this.initCommissionPie();
  }

  onChangeSlickFilter(data) {
    this.commissionBrokerDateSelected = data;
    this.initCommissionPerBroker();
  }

  onSlideDate(next, dates) {
    const numberOfDays = getHeaderCount(this.isMobileDevice);
    let endDate = null;
    const base = next ? dates[dates.length - 1] : dates[0];
    if (next) {
      endDate = computeDays('add')(base.date, numberOfDays);
    } else {
      endDate = computeDays('subtract')(base.date, 1);
    }

    this.initTransactionHistory(endDate);
  }

  onSendEnquiry(commission) {
    if (!commission) {
      return;
    }
    this.commissionHelperService.openCommissionEnquiry({
      commission: () => {
        return {
          clientName: commission.ClientName,
          commissionId: commission.Commission,
          commissionTypeId: commission.CommissionType,
          commissionTypeName: commission.CommissionTypeDesc,
          lenderId: commission.LenderId,
          lenderName: commission.LenderName,
          referenceNumber: commission.ReferenceNo,
          loanAmount: commission.TransactionAmount,
        };
      },
      familyId: null,
      loanId: null,
    });
  }

  getStatementClass({ date, mode }) {
    if (!date || !this.statementMonths) {
      return '';
    }
    const currentDate = formatDate(date);
    const isPastDate = dateLesserThan(currentDate, 0, 'days', this.dateToday);
    getStatementMonths(currentDate).map((date) => {
      if (!this.statementMonths.includes(date) && isPastDate) {
        this.statementMonths.push(date);
        this.commissionService
          .AvailableDatesOfStatementsGet(date, TRANSACTION_CALENDAR_TYPE)
          .then(({ data }) => {
            this.statementDates = [...this.statementDates, ...data];
            return getClassName({
              statementDates: this.statementDates,
              currentDate,
              mode,
            });
          });
      }
    });
    return getClassName({
      statementDates: this.statementDates,
      currentDate,
      mode,
    });
  }

  downloadStatement(adviserId, selectedDate) {
    !adviserId && toastError('Please select adviser');
    if (!adviserId || !selectedDate) {
      return;
    }

    this.isDownloadingStatement = true;
    this.commissionService
      .PDFStatementGet(selectedDate, adviserId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        const pdfContent = getDataURI(data);
        this.downloadDataService.download(
          pdfContent,
          `Commission Statement.pdf`,
          data.ContentType,
        );
      })
      .catch(toastError)
      .finally(() => {
        this.isDownloadingStatement = false;
      });
  }

  openTransactionCalendar() {
    const props = {
      preselectDate: this.dateToday,
      datePickerOptions: this.datePickerOptions,
    };
    const modalInstance = this.commissionHelperService.openDatePicker(props);
    modalInstance.result.then((res) => {
      const shouldRefresh = res && res.date;
      shouldRefresh && this.initTransactionHistory(res.date);
    });
  }

  getFilterDates() {
    this.commissionService.getCommissionFilterDates().then((data) => {
      this.commissionDateFilterList = data;
    });
  }

  selectAllBroker() {
    if (!get(this.brokers, 'length')) {
      return;
    }

    this.brokers = tickAllOptions(this.brokers, false);
    this.brokers[0].ticked = true;
    this.setBrokerFilter();
  }

  selectBroker(data, selection) {
    if (!get(this.brokers, 'length')) {
      return;
    }

    const isSelectAll = data.value === DEFAULT_BROKER_FILTER;
    if (isSelectAll) {
      this.selectAllBroker();
      return;
    }

    this.brokers[0].ticked = false;
    const brokerIds = getTickValues(selection)('value', DEFAULT_BROKER_FILTER);
    this.brokers = tickSavedOptions(this.brokers, brokerIds);
    this.setBrokerFilter(brokerIds);
  }

  setBrokerFilter(brokerIds) {
    const ids = get(brokerIds, 'length') ? brokerIds : [DEFAULT_BROKER_FILTER];
    this.brokerIds = ids;
    this.commissionService.assignBrokersSet(ids).then(({ data }) => {
      data && this.initWidgets(true);
    });
  }

  getTotalCommission(type, brokerIds) {
    return this.commissionService.totalCommissionGet(
      this.date,
      brokerIds,
      type,
    );
  }

  getAvailableBrokerOnly() {
    this.selectedDate = this.selectedDate || this.date;
    const params = {
      startDate: this.selectedDate,
      endDate: this.selectedDate,
    };
    this.isLoadingTransactionFilter = true;
    this.commissionService.availableBrokerOnlyGet(params).then(({ data }) => {
      const hasAdviser = data && data.length;
      const defaultOption = hasAdviser ? 'All Adviser' : 'None';
      this.adviserList = [
        { BrokerID: '', BrokerFullName: defaultOption },
        ...data,
      ];
      this.isLoadingTransactionFilter = false;
    });
  }

  selectHeader(header) {
    this.selectedDate = formatDate(header.date);
    this.historyHeader = this.historyHeader.map((item) => ({
      ...item,
      active: header.date === item.date,
    }));
    this.getAvailableBrokerOnly();
  }

  initTransactionHistory(endDate = this.date) {
    const numberOfDays = getHeaderCount(this.isMobileDevice);
    const startDate = computeDays('subtract')(endDate, numberOfDays);

    const params = {
      searchString: '',
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };

    this.historyHeader = range(numberOfDays).map((item, index) => ({
      ...item,
      date: formatDate(computeDays('add')(startDate, index + 1)),
      totalCommission: 0,
      transactionList: [],
    }));

    this.isLoadingTransaction = true;
    this.commissionService.SearchTransactionGet(params).then(({ data }) => {
      this.allTransaction = data.map((item) => ({
        ...item,
        formattedDate: formatDate(item.TransactionDate),
      }));

      this.historyHeader = structureHistoryHeader(
        this.historyHeader,
        this.allTransaction,
      );
      const defaultHeader = this.historyHeader[this.historyHeader.length - 1];
      this.selectHeader(defaultHeader);

      this.isLoadingTransaction = false;
    });
  }

  initCommissionPie() {
    this.isPieLoading = true;
    this.commissionService
      .totalCommissionByTypeGet(this.date, this.commissionAllTypeDateSelected)
      .then(({ data }) => {
        this.commissionByType = data || [];
        this.isPieLoading = false;
      });
  }

  initCommissionPerBroker() {
    this.isWidgetPerBrokerLoading = true;
    this.commissionService
      .totalCommissionOfBrokerGet(this.date, this.commissionBrokerDateSelected)
      .then(({ data }) => {
        this.commissionPerBroker = data || [];
        this.isWidgetPerBrokerLoading = false;
      });
  }

  initTotalCommissionWidgets() {
    this.totalCommission.financialYear = { isLoading: true };
    this.totalCommission.month = { isLoading: true };
    this.totalCommission.week = { isLoading: true };
    this.totalCommission.day = { isLoading: true };

    const brokerIds = this.brokerIds.join(',');

    forOwn(COMMISSION_STATS, (statType, key) => {
      this.getTotalCommission(key, brokerIds).then(({ data }) => {
        this.totalCommission[statType.PROP] = formatWidgetData(data, key);
      });
    });
  }
}
