import template from './loanAppDiscountsRate.html';
import LoanAppDiscountsRateCtrl from './loanAppDiscountsRateCtrl';

export default {
  template,
  controller: LoanAppDiscountsRateCtrl,
  controllerAs: 'vm',
  bindings: {
    productId: '<',
    loanId: '<',
    brokerEventId: '<',
    isOnLoanDetailsModal: '<',
    loanAmount: '<',
    fixedRateTerm: '<',
    interestOnlyTerm: '<',
    interestRateType: '<',
    loanStructureType: '<',
    loanTerm: '<',
    loanStructureId: '<',
    sendUpdatedRate: '&',
  },
};
