import template from './crmConfirmation.html';
import controller from './crmConfirmationCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    cTitle: '<',
    cDescription: '<',
    cButtonText: '<',
    cButtonClass: '<',
    cType: '<', // only pass either success, warning, info, simple-warning
    cModalInstance: '<',
    cShowConfirmButton: '<',
    cShowCancelButton: '<',
    cShowCloseButton: '<',
    cModalSize: '<',
    cRenderAsComponent: '<',
    cCommonObject: '<',
    cCancelButtonText: '<',
    cCancelButtonClass: '<',
    cOnConfirm: '<',
    cTranscludeHtml: '<',
    cDisplayDontShowAgain: '<',
    cShowCustomButton: '<',
    cCustomButtonText: '<',
    cCustomButtonClass: '<',
    cConfirmationIcon: '<',
    cIsButtonMaxWidth: '<',
    cIsButtonMdWidth: '<',
  },
};
