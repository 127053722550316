import template from './fullWidthDashedButton.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    size: '<',
    label: '<',
    onClick: '&',
  },
};
