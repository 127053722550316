import moment from 'moment';
import { toastError } from 'Common/utilities/alert';
import { getDatePickerConfig, formatDate } from 'Common/utilities/date';
import { D_MMMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';
import {
  POST_SETTLEMENT_FOLLOW_UP_TAB,
  POST_SETTLEMENT_FOLLOW_UP_DEFAULT_TAB,
  CUSTOMER_CARE_TAB_POST_SETTLEMENT,
} from 'Common/constants/customerCareModules';

export const setDateRangeObject = ($scope, tab) => {
  $scope.mainObj.dateRange = tab.id || POST_SETTLEMENT_FOLLOW_UP_TAB[0].id;
  $scope.mainObj.settlementName =
    tab.name || POST_SETTLEMENT_FOLLOW_UP_TAB[0].name;
  $scope.mainObj.dataRangeIndex =
    tab.index || POST_SETTLEMENT_FOLLOW_UP_TAB[0].index;
};

export const setActiveSubTab = ({ $scope, $stateParams }) => {
  const activeSubTab =
    $stateParams.activeSubTab || POST_SETTLEMENT_FOLLOW_UP_DEFAULT_TAB;
  const currentTab = POST_SETTLEMENT_FOLLOW_UP_TAB.find(
    (tab) => tab.urlValue === activeSubTab,
  );
  setDateRangeObject($scope, currentTab);
};

export const onChangeDateRange = ({
  $scope,
  $state,
  stateService,
  $stateParams,
}) => (tabId) => {
  if (!tabId) {
    return;
  }
  const currentTab = POST_SETTLEMENT_FOLLOW_UP_TAB.find(
    (tab) => tab.id === tabId,
  );
  setDateRangeObject($scope, currentTab);
  if ($stateParams.activeTab !== CUSTOMER_CARE_TAB_POST_SETTLEMENT) {
    return;
  }
  $state.params.activeSubTab = currentTab.urlValue;
  stateService.transition($state.current, $state.params);
};

export const getActiveTab = (activeSubTab) => {
  const currentTab = POST_SETTLEMENT_FOLLOW_UP_TAB.find(
    (tab) => tab.urlValue === activeSubTab,
  );
  return currentTab || POST_SETTLEMENT_FOLLOW_UP_TAB[0];
};

export const initializeDateConfig = ({ $scope }) => {
  $scope.configStartDate = getDatePickerConfig({
    dateOptions: {
      formatYear: 'yy',
      showWeeks: false,
      minDate: moment($scope.mainObj.postSettlementEndDate).subtract(1, 'year'),
      maxDate: new Date(),
    },
  });
  $scope.configEndDate = getDatePickerConfig({
    dateOptions: {
      formatYear: 'yy',
      showWeeks: false,
      minDate: $scope.mainObj.postSettlementStartDate,
      maxDate: new Date(),
    },
  });
  $scope.dateConfigInitialized = true;
};

export const setConfigMinMaxDate = ({ $scope }) => {
  if (!$scope.dateConfigInitialized) {
    initializeDateConfig({ $scope });
    return;
  }
  $scope.configStartDate.dateOptions.minDate = moment(
    $scope.mainObj.postSettlementEndDate,
  ).subtract(1, 'year');
  $scope.configEndDate.dateOptions.minDate =
    $scope.mainObj.postSettlementStartDate;
};

export const updateFilterFormat = ({ $scope }) => {
  $scope.mainObj.toDate = moment($scope.mainObj.postSettlementStartDate).format(
    D_MMMM_YYYY_FORMAT,
  );
  $scope.mainObj.formDate = moment($scope.mainObj.postSettlementEndDate).format(
    D_MMMM_YYYY_FORMAT,
  );
};

export const updateSettlementFormat = ({ $scope }) => {
  $scope.mainObj.postSettlementStartDate = new Date(
    moment.utc($scope.mainObj.toDate).format(),
  );
  $scope.mainObj.postSettlementEndDate = new Date(
    moment.utc($scope.mainObj.formDate).format(),
  );
};

export const onDateRangeChange = ({ $scope }) => (date, isStart) => {
  if (!date) {
    return;
  }
  const dateKey = isStart ? 'StartDate' : 'EndDate';
  const { minDate, maxDate } = $scope[`config${dateKey}`].dateOptions;
  const isInRange = moment(date).isBetween(minDate, maxDate);
  $scope.mainObj[`postSettlement${dateKey}`] = date;
  if (!isInRange) {
    const rangeMinDate = formatDate(D_MMMM_YYYY_FORMAT)(
      $scope.configStartDate.dateOptions.minDate,
    );
    const rangeMaxDate = formatDate(D_MMMM_YYYY_FORMAT)(maxDate);
    toastError(
      `Date range should be between:<br/> ${rangeMinDate} and ${rangeMaxDate}`,
    );
    return;
  }
  $scope.selectedSettlementlIds.splice(0, $scope.selectedSettlementlIds.length);
  $scope.loadPostSettlementTable();
};
