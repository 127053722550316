import template from './dashboardContainer.html';
import controller from './dashboardContainerCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {},
};
