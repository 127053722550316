import { AWS_S3_ROOT } from 'Common/constants/document';
import { getDataURI, getUploadUrl } from 'Common/utilities/document';
import { toastSuccess, toastError } from 'Common/utilities/alert';

class OnboardingBrandingCtrl {
  constructor(
    customThemeService,
    contactService,
    generalService,
    configService,
    onboardingDataService,
  ) {
    'ngInject';

    this.customThemeService = customThemeService;
    this.contactService = contactService;
    this.generalService = generalService;
    this.configService = configService;
    this.onboardingDataService = onboardingDataService;
    this.toastError = toastError;
  }

  logoDropletReady() {
    this.crmBrand.interface.allowedExtensions(['png', 'jpg', 'jpeg']);

    const requestUrl = `${this.configService.resource}/${getUploadUrl()}`;
    this.crmBrand.interface.setRequestUrl(requestUrl);
    this.crmBrand.interface.setRequestHeaders({
      Authorization: this.configService.token,
    });
    this.crmBrand.interface.defineHTTPSuccess([/2.{2}/]);
    this.crmBrand.interface.useArray(false);
  }

  dropletLogoAdded() {
    if (!this.crmBrand.interface.isUploading()) {
      this.crmBrand.interface.uploadFiles();
    }
  }

  dropletLogoError() {
    this.toastError('Something went wrong. Please try again.');
  }

  showLogoPreview(flag = true) {
    this.showLogo = flag;
  }

  showBrandColorSelector(flag = true) {
    this.showColorSelector = flag;
    this.showColorPreview = !flag;
  }

  getBranding() {
    this.customThemeService
      .generalThemeGet()
      .then(({ data }) => {
        if (!data) {
          return;
        }
        this.generalThemeData = { ...data };
        this.brandingColor = data.SideBarBGColour || '';
        this.showBrandColorSelector(!this.brandingColor);

        if (!data.CRM_LogoDocId) {
          return;
        }
        return this.generalService.documentGet(data.CRM_LogoDocId);
      })
      .then((response) => {
        if (!response || !response.data) {
          return;
        }
        this.logoSrc = getDataURI(response.data);
        this.showLogoPreview();
      });
  }

  saveBrandingLogo(files) {
    if (!files || !files.length) {
      return;
    }
    const logo = files[0] || {};
    const docId = logo.DocumentId || '';

    this.logoSrc = getDataURI(logo);
    this.customThemeService.customLogoSet(docId).then(() => {
      if (this.generalThemeData) {
        this.generalThemeData.CRM_LogoDocId = docId;
      }
      toastSuccess('Branding logo successfully saved.');
      this.showLogoPreview();
    });
  }

  removeBrandingLogo() {
    this.showLogoPreview(false);
    this.customThemeService.customLogoSet().then(() => {
      toastSuccess('Branding logo successfully removed.');
    });
  }

  saveBrandingColor(color) {
    if (this.isBrandingColorSaving) {
      return;
    }
    this.cancelColorSelect();
    this.isBrandingColorSaving = true;
    const themeData = { ...this.generalThemeData, SideBarBGColour: color };
    this.customThemeService
      .generalThemeSet(themeData)
      .then(() => {
        const actionType = color ? 'saved' : 'removed';
        toastSuccess(`Branding color successfully ${actionType}.`);
        this.brandingColor = color;
        this.isBrandingColorSaving = false;
        this.showBrandColorSelector(!color);
      })
      .catch(() => {
        this.isBrandingColorSaving = false;
      });
  }

  removeBrandingColor() {
    this.saveBrandingColor('');
  }

  cancelColorSelect() {
    if (!this.brandingColor) {
      return;
    }
    this.showBrandColorSelector(false);
  }

  $onInit() {
    this.crmBrand = { interface: {} };
    this.byobUploadType = AWS_S3_ROOT.BYOB;
    this.uploadOptions = {
      isMultiple: false,
      itemForUpload: 'logo',
      clickableDiv: true,
    };
    this.showLogo = false;
    this.showColorSelector = true;
    this.byobDoneRight = this.configService.feature.bYOBDoneRight;
    this.userAdviserOrgId = this.onboardingDataService.getAdviserOrgId();
    this.getBranding();
  }
}

export default OnboardingBrandingCtrl;
