import { toastError } from 'Common/utilities/alert';

export const toggleDependantLoanAppInclusion = ({
  dependantModelService,
  payload,
}) => {
  if (!payload) {
    return;
  }
  const { includedInLoanApp } = payload;
  const setMethod = includedInLoanApp
    ? 'setToIncludeDependant'
    : 'setToExcludeDependant';
  try {
    dependantModelService[setMethod](payload).catch(toastError);
  } catch (error) {
    toastError(error);
  }
};
