import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';

export default class PipelineMobileDemoModalCtrl {
  constructor($sce, generalService) {
    'ngInject';

    this.$sce = $sce;
    this.generalService = generalService;
  }

  $onInit() {
    this.currentStep = (this.videos.length && this.videos[0]) || {};
  }

  previous() {
    if (this.currentStep.step === 1) {
      return;
    }
    this.currentStep = this.videos.find(
      (o) => o.step === this.currentStep.step - 1,
    );
  }

  next() {
    if (this.currentStep.step === 3) {
      return;
    }
    this.currentStep = this.videos.find(
      (o) => o.step === this.currentStep.step + 1,
    );

    if (this.currentStep.step === 3) {
      this.isFinishTutorial = true;
    }
  }

  finishTutorial() {
    this.generalService.postTickableItems({
      itemId: TICKABLE_ITEMS.PIPELINE_MOBILE_VIEW,
    });
    this.modalInstance.close();
  }

  skip() {
    this.modalInstance.close();
  }
}
