export const onSendHelloPackInsuranceAppSuccess = ({
  $state,
  insuranceSharedData,
}) => {
  const isContactHelloPackInsuranceAppSent =
    $state.current.name !== 'app.contactsSingle' &&
    insuranceSharedData.isHelloPackApplicationSent;
  if (isContactHelloPackInsuranceAppSent) {
    return;
  }

  insuranceSharedData.isHelloPackApplicationSent = false;
  insuranceSharedData.reloadQuoteList();
  insuranceSharedData.reloadInsuranceList();
};
