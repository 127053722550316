class ContactFilesOptionCtrl {
  constructor(contactModelService, loanScenarioModelService) {
    'ngInject';

    this.contactModelService = contactModelService;
    this.loanScenarioModelService = loanScenarioModelService;
  }

  $onChanges() {
    this.familyId && this.getLoanScenarioSimplifiedList();
  }

  getLoanScenarioSimplifiedList() {
    return this.loanScenarioModelService
      .getSimplifiedList(this.familyId)
      .then((data = []) => {
        const emptyOption = {
          loanApplicationId: 0,
          loanId: 0,
          label: 'Select a deal',
        };
        this.contactFiles = [emptyOption, ...data];
        this.displayContactFile = !!data.length;
      });
  }
}

export default ContactFilesOptionCtrl;
