export function widgetOptionBuilderForMyCRM(params, widgetUserId) {
  const { widgetOptionId, value, selectedUser } = params;
  return {
    WidgetOptionId: widgetOptionId,
    Widget_UserID: widgetUserId,
    Widget_OptionValue: value || 0,
    SelectedUser: selectedUser,
  };
}

export function monthlySettlementBuilderForUI(settlement) {
  return {
    monthInt: settlement.MonthInt,
    monthStr: settlement.MonthStr,
    value: settlement.Value,
  };
}

export function eSignOverviewBuilderForUI(data) {
  return {
    drafts: data.Drafts,
    inProgress: data.InProgress,
    completed: data.Completed,
    requireMySignature: data.RequireMySignature,
    waitingOnOthers: data.WaitingOnOthers,
    aboutToExpire: data.AboutToExpire,
    expired: data.Expired,
    cancelled: data.Cancelled,
    transactions: data.Transactions,
  };
}

export function yearlySettlementBuilderForUI(settlement) {
  return {
    year: settlement.Year,
    data:
      (settlement.Data && settlement.Data.map(monthlySettlementBuilderForUI)) ||
      [],
  };
}

export function enquirySourceBuilderForUI(settlement) {
  return {
    id: settlement.ReferralCategoryID,
    category: settlement.ReferralCategory,
    count: settlement.TotalCount,
  };
}

export function dataLabelCountBuilderForUI(item) {
  return {
    label: item.Label,
    count: item.Count,
  };
}

export function referralsBuilderForUI(item) {
  return {
    category: item.ReferralCategory,
    data: (item.Data && item.Data.map(dataLabelCountBuilderForUI)) || [],
  };
}

export function upcomingSettlementDatesBuilderForUI(item) {
  return {
    loanId: item.LoanID,
    familyId: item.FamilyId,
    familyName: item.FamilyName,
    settlementDate: item.SettlementDate,
  };
}

export function upcomingFinanceDatesBuilderForUI(item) {
  return {
    loanId: item.LoanID,
    familyId: item.FamilyId,
    familyName: item.FamilyName,
    financeDate: item.FinanceDate,
  };
}

export function insuranceNotificationsBuilderForUI(item) {
  return {
    status: item.Status,
    notifications: item.Notifications,
  };
}

export function widgetDateOptionsBuilderForUI(item) {
  return {
    id: item.Id,
    displayName: item.DisplayName,
    dbValue: item.DbValue,
    valueId: item.ValueId,
  };
}

export function selectedWidgetOptionBuilderForUI(item) {
  return {
    widgetOptionId: item.WidgetOptionId,
    displayValue: item.DisplayValue,
    optionName: item.OptionName,
    widget_OptionValue: item.Widget_OptionValue,
    dB_Value: item.DB_Value,
    selectedUerValues: item.SelectedUerValues,
    selectedUserDisplayValues: item.SelectedUserDisplayValues,
  };
}
export const recommendedWidgetBuilderForUI = (widget) => {
  return {
    recommendedWidgetId: widget.RecommendedWidgetId,
    widgetId: widget.WidgetId,
    widgetName: widget.WidgetName,
    sort: widget.Sort,
  };
};

export const recommendedWidgetsOptionsBuilderForUI = (widget) => {
  return {
    recommendedWidgetOptionsId: widget.RecommendedWidgetOptionsId,
    recommendedWidgetId: widget.RecommendedWidgetId,
    widgetOptionId: widget.WidgetOptionId,
    valueId: widget.WidgetOptionValueId,
    displayName: widget.WidgetOptionValueName,
    selectedUserValues: widget.SelectedUserValues,
    targetValue: widget.TargetValue,
    widgetId: widget.WidgetId,
  };
};

export const targetBasedLoansBuilderForUI = (item) => {
  return {
    monthStr: item.MonthStr,
    monthInt: item.MonthInt,
    day: item.Day,
    year: item.Year,
    loanAmount: item.LoanAmount,
    lodgedDate: item.LodgedDate,
  };
};

export const insurancePipelineBuilderForUI = (widget) => {
  return {
    stage: widget.Stage,
    totalAmount: widget.TotalAmount,
    transactions: widget.Transactions,
  };
};
