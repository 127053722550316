import angular from 'angular';
import gamePlanSendingMomentHeader from './gamePlanSendingMomentHeader';
import gamePlanSendingMomentSidebar from './gamePlanSendingMomentSidebar';
import gamePlanSendingMomentActionButtons from './gamePlanSendingMomentActionButtons';
import gamePlanSendingMomentMain from './gamePlanSendingMomentMain';
import gamePlanEmailMessageView from './gamePlanEmailMessageView';
import brokerMessageView from './brokerMessageView';

export default angular
  .module('gamePlan.components', [])
  .component('gamePlanSendingMomentHeader', gamePlanSendingMomentHeader)
  .component('gamePlanSendingMomentSidebar', gamePlanSendingMomentSidebar)
  .component(
    'gamePlanSendingMomentActionButtons',
    gamePlanSendingMomentActionButtons,
  )
  .component('gamePlanSendingMomentMain', gamePlanSendingMomentMain)
  .component('gamePlanEmailMessageView', gamePlanEmailMessageView)
  .component('brokerMessageView', brokerMessageView).name;
