import template from './clientAddressDetails.html';
import controller from './clientAddressDetailsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    details: '<',
    clientForm: '<',
    onUpdateClientDetails: '&',
    invalidDateRange: '<',
  },
};
