import angular from 'angular';
import usersMyLeadGenerator from './usersMyLeadGenerator';
import leadGeneratorLink from './leadGeneratorLink';
import leadImageModal from './leadImageModal';
import leadGeneratorChannel from './leadGeneratorChannel';
import './style.scss';

export default angular
  .module('components.usersMyLeadGenerator', [])
  .component('usersMyLeadGenerator', usersMyLeadGenerator)
  .component('leadGeneratorLink', leadGeneratorLink)
  .component('leadImageModal', leadImageModal)
  .component('leadGeneratorChannel', leadGeneratorChannel).name;
