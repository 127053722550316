export const formatEntityDetailsForUI = (entity) => {
  return {
    entityType: entity.EntityType,
    entityName: entity.AdviserOrganisationName,
    acn: entity.ACN,
    abn: entity.ABN,
    businessPhone: entity.Phone,
    fax: entity.Fax,
    adviserOrganisationId: entity.AdviserOrganisationId || 0,
  };
};
