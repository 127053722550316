const MERGE_CONTACT_TYPES = {
  MERGE: 1,
  MARRY: 2,
};

export default class SelectMergeContactTypeModal {
  constructor($uibModal) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  $onInit() {
    this.selectedType = 0;
    this.MERGE_CONTACT_TYPES = MERGE_CONTACT_TYPES;

    if (this.areMarriedCouples) {
      this.selectType(MERGE_CONTACT_TYPES.MERGE);
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  selectType(type) {
    this.selectedType = type;
  }

  next() {
    if (!this.selectedType) {
      return;
    }

    this.modalInstance.close();

    const props = {
      clientsToMerge: this.clientsToMerge,
      countryCode: this.countryCode,
      refreshContactList: this.refreshContactList,
    };
    const component =
      this.selectedType === MERGE_CONTACT_TYPES.MERGE ? 'duplicate' : 'marry';
    this.$uibModal.open({
      template: `<${component}-contacts-modal
        modal-instance="vm.modalInstance"
        clients-to-merge="vm.props.clientsToMerge"
        country-code="vm.props.countryCode"
        refresh-contact-list="vm.props.refreshContactList()"
      ></${component}-contacts-modal>`,
      size: 'lg',
      backdrop: 'static',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      windowClass: 'marry-contacts-modal',
      resolve: {
        props: () => props,
      },
    });
  }
}
