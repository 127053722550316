import {
  toastError,
  alertSucess,
  showActionConfirmation,
} from 'Common/utilities/alert';
export default class FundingAvailableCtrl {
  constructor(
    $uibModal,
    $rootScope,
    loanAppSharedData,
    fundingCalculatorService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.loanAppSharedData = loanAppSharedData;
    this.fundingCalculatorService = fundingCalculatorService;
  }

  $onInit() {
    this.securityBrokerEventId = this.loanAppSharedData.securityBrokerEventId;
    this.getFundsAvailable();
    this.getFundingCalculatorFundsSummary = () =>
      this.loanAppSharedData.getFundingCalculatorFundsSummary({
        securityBrokerEventId: this.loanAppSharedData.securityBrokerEventId,
        loanAppId: this.loanAppId,
      });
    this.loanAppSharedData.fundingAvailableRefresh = (fundsAvailable) => {
      this.fundsAvailable = fundsAvailable;
      this.getFundingCalculatorFundsSummary();
    };
  }

  openFundModal(fund) {
    const props = {
      fund,
      loanAppId: this.loanAppId,
      lendingCategoryId: this.lendingCategoryId,
      securityBrokerEventId: this.securityBrokerEventId,
    };

    const modalInstance = this.$uibModal.open({
      template: `
        <fund-form
            modal-instance="vm.modalInstance"
            fund="vm.props.fund"
            loan-app-id="vm.props.loanAppId" 
            security-broker-event-id="vm.props.securityBrokerEventId" 
            lending-category-id="vm.props.lendingCategoryId">
        </fund-form>`,
      size: 'sm',
      backdrop: 'static',
      windowClass: 'fund-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props: () => props,
      },
    });
    return modalInstance.result.then(() => {
      this.getFundsAvailable(true);
    });
  }

  getFundsAvailable(isFormUpdate) {
    return this.fundingCalculatorService
      .getFundingCalculatorFunds(this.securityBrokerEventId)
      .then((fundsResult) => {
        const fundsData = fundsResult.filter(
          (fund) => fund.fundingType === 'FUNDS_AVAILABLE',
        );
        this.fundsAvailable = fundsData;
        isFormUpdate && this.broadcastFundingChanges();
      })
      .catch(toastError);
  }

  onConfirmDeleteFund(fundsId) {
    const params = {
      FundsID: fundsId,
    };
    this.fundingCalculatorService
      .deleteFundingCalculatorFunds(params)
      .then(({ data }) => {
        if (!data) {
          return toastError();
        }
        this.getFundsAvailable(true);
        alertSucess(
          'Success',
          'Fund has been been successfully deleted.',
          true,
        );
      })
      .catch(toastError);
  }

  showDeleteConfirmation(fundsId) {
    showActionConfirmation(
      'Are you sure?',
      'This record will be removed from your list',
      (confirm) => {
        confirm && this.onConfirmDeleteFund(fundsId);
      },
    );
  }

  broadcastFundingChanges() {
    this.getFundingCalculatorFundsSummary();
    this.$rootScope.$broadcast('REFRESH_OBJECTIVES_DEPOSIT_SECTION', {
      fundsAvailable: this.fundsAvailable,
      isNew: false,
    });
    this.loanAppSharedData.validateFundingDetails &&
      this.loanAppSharedData.validateFundingDetails();
  }
}
