import { get } from 'lodash';
import { COMPANY_TYPES } from 'Common/constants/partyTypes';
import { toastError, toastSuccess } from 'Common/utilities/alert';
import { setupNewPostURL } from 'Common/utilities/handleObsoleteURL';
import { isNotRegisteredNZBNChar } from 'Common/utilities/nzbnChecker';
import {
  stripPhoneFromContactSetPayload,
  saveStandAloneEntityContactNumber,
} from 'Common/utilities/contactNumber';

export const isBluesStoneApplication = ({ isNZ, feature }) =>
  isNZ && !!feature.bluestoneOnlineApplication;

export const organisationInfoSetNZBN = (organisationInfo, data) => {
  const { NZBN } = data;
  organisationInfo.employmentNZBN = parseInt(NZBN, 10);
};

export const isNotValidNZBNChar = (isValidNZBNChar) => {
  if (isValidNZBNChar) {
    toastError('NZBN Should be 13 numerical characters');
  }
  return isValidNZBNChar;
};

export const toggleTrustType = ({ $scope = {} }) => {
  $scope.toggleTrustType = ({ label }) => {
    $scope.isTrust = label === COMPANY_TYPES.COMPANY_STRING || false;
    $scope.trustType = label;
    $scope.business.OrganisationType = label;
  };
};

export const onOrganisationInfoSet = ({
  $scope = {},
  contactService,
  contactModelService,
  myCRMconfigs,
  org,
}) => {
  $scope.canSave = false;
  org.OrganisationType = $scope.trustType;
  const contact = $scope.isInternationalPhoneNumbers
    ? stripPhoneFromContactSetPayload([org])[0]
    : org;

  contactService
    .organisationInfoSet(setupNewPostURL(contact, 'organisation'))
    .then((response) => {
      const successAddContactCallback = () => {
        $scope.canSave = !$scope.canSave;
        const validateNZBN = isNotRegisteredNZBNChar(
          { ...response, myCRMconfigs },
          'organisation',
        );
        if (validateNZBN) {
          return;
        }
        $scope.getBusinessDetails();
        $scope.editBusiness = !$scope.editBusiness;
        toastSuccess('Contact has been Updated Successfully.', 'Updated');
      };

      $scope.isInternationalPhoneNumbers &&
        saveStandAloneEntityContactNumber({
          contact: org,
          familyId: response.data,
          contactModelService,
          successAddContactCallback,
        });

      !$scope.isInternationalPhoneNumbers && successAddContactCallback();
    });
};

export const getBusinessEntityPhoneCountryCode = ({
  $scope,
  contactModelService,
  currentUserService,
}) => {
  if (!$scope.isInternationalPhoneNumbers) {
    return;
  }
  return contactModelService
    .getEntityContactNumber({ familyId: $scope.familyId })
    .then((data) => {
      const countryCode = get(
        data,
        'work.countryCode',
        currentUserService.countryCode || '',
      ).toLowerCase();
      $scope.business.Phone = get(data, 'work.number', '');
      $scope.selectedCountryCode = countryCode;
      $scope.homePhoneCountryCode = countryCode;
    });
};
