import * as PRIMARY_PURPOSE from 'Common/constants/primaryPurpose';
import {
  FUNDING_PURPOSE_IDS,
  FUNDING_STATUS_IDS,
  STATE_WITH_FOREIGN_PURCHASER,
  REFINANCE_PURPOSE_IDS,
  CONSTRUCTION_PURPOSE_IDS,
  VICTORIA_CITIES,
  CONSTRUCTION_LAND,
  DEFAULT_FUND_NAMES,
  EPICTENET_TRANSACTION_TYPE,
  STATE_WITH_VARYING_TRANSFER_TRANSACTION,
} from 'Common/constants/fundsConstants';
import { AU_STATES } from 'Common/constants/addressState';
import { OPPORTUNITY } from 'Common/constants/paramTypes';
import dotProp from 'dot-prop-immutable';
import { parseToInt10 } from 'Common/utilities/parse';
import { GENERAL_QUESTION } from 'Common/constants/commonQuestions';
import { KEY_CODE } from 'Common/constants/keyCode';
import { ERROR_LMI_NOT_MATCHED } from 'Common/constants/promptMessages';
import get from 'lodash/get';

export const getStatusList = ({
  fullStatusList,
  fundingCalculatorParameters,
}) => {
  const purposeId = get(
    fundingCalculatorParameters,
    'purchaseInfo.purpose',
    '',
  );
  fundingCalculatorParameters.statusList = filterStatusList({
    fullStatusList,
    purposeId,
  });
};

export const filterStatusList = ({ fullStatusList, purposeId }) => {
  const filters = [];
  switch (parseToInt10(purposeId)) {
    case FUNDING_PURPOSE_IDS.PURCHASE_OWNER_OCCUPIED:
    case FUNDING_PURPOSE_IDS.PURCHASE_INVESTMENT:
      filters.push(FUNDING_STATUS_IDS.TO_BE_BUILT);
      break;
    case FUNDING_PURPOSE_IDS.REFI_EQUITY_OWNER_OCCUPIED:
    case FUNDING_PURPOSE_IDS.REFI_EQUITY_INVESTMENT:
    case FUNDING_PURPOSE_IDS.CONSTRUCTION_INVESTMENT:
    case FUNDING_PURPOSE_IDS.CONSTRUCTION_OWNER_OCCUPIED:
      filters.push(FUNDING_STATUS_IDS.NEW_BUILDING);
      break;
    case FUNDING_PURPOSE_IDS.AU_GUARANTEE:
      filters.push(
        FUNDING_STATUS_IDS.NEW_BUILDING,
        FUNDING_STATUS_IDS.TO_BE_BUILT,
      );
      break;
  }
  return fullStatusList.filter(
    (status) => !filters.includes(parseToInt10(status.value)),
  );
};

export const getPurpose = (purposeValue) => {
  const purposeValueInt = parseInt(purposeValue, 10);
  switch (purposeValueInt) {
    case PRIMARY_PURPOSE.FIRST_HOME:
    case PRIMARY_PURPOSE.NEXT_HOME:
      return FUNDING_PURPOSE_IDS.PURCHASE_OWNER_OCCUPIED.toString();
    case PRIMARY_PURPOSE.INVESTMENT_PROPERTY:
      return FUNDING_PURPOSE_IDS.PURCHASE_INVESTMENT.toString();
    case PRIMARY_PURPOSE.GET_A_BETTER_DEAL:
    case PRIMARY_PURPOSE.RENOVATE:
    case PRIMARY_PURPOSE.CONSOLIDATE_DEBT:
    case PRIMARY_PURPOSE.BORROW_EXTRA_FUNDS:
    case PRIMARY_PURPOSE.REFINANCE:
      return FUNDING_PURPOSE_IDS.REFI_EQUITY_OWNER_OCCUPIED.toString();
    default:
      return null;
  }
};

export const loanOpportunities = ({ $scope, $state, uiService }) => (
  apiService,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  if (
    $state.params.paramType !== OPPORTUNITY ||
    !uiService.viewOfAfileTurnedOn
  ) {
    return;
  }
  const ESTABLISHED = '14';
  apiService
    .getLoanOpportunityDetails($state.params.paramId)
    .then(({ data }) => {
      const validCalculatorDetailsPurpose =
        data.CalculatorDetails &&
        data.CalculatorDetails.Purpose &&
        Object.keys(data.CalculatorDetails.Purpose).length > 0;
      const validPrimaryPurpose =
        validCalculatorDetailsPurpose && data.CalculatorDetails.Purpose.Value;
      const validSecurityAmount = !!(
        data.Summary && data.Summary.SecurityAmount
      );
      const validState = !!(
        data.CalculatorDetails && data.CalculatorDetails.State
      );
      const validPurchasersTotalIncome = !!(
        data.CalculatorDetails && data.CalculatorDetails.PurchasersTotalIncome
      );
      const validPurchasersTotalDependents = !!(
        data.CalculatorDetails &&
        data.CalculatorDetails.PurchasersTotalDependents
      );

      const [primaryPurposeId] = validPrimaryPurpose
        ? data.Summary.PrimaryPurpose.answerInt
        : [null];
      const securityAmount = validSecurityAmount
        ? data.Summary.SecurityAmount
        : 0;
      const stateValue = validState ? data.CalculatorDetails.State : '';
      const purchasersTotalIncome = validPurchasersTotalIncome
        ? data.CalculatorDetails.PurchasersTotalIncome
        : 0;
      const purchasersTotalDependents = validPurchasersTotalDependents
        ? data.CalculatorDetails.PurchasersTotalDependents
        : 0;
      $scope.fundingCalculatorParameters.address.formattedAddress = 'TBA';
      if (validCalculatorDetailsPurpose) {
        $scope.fundingCalculatorParameters.purchaseInfo.propertyStatus = ESTABLISHED;
      }
      $scope.fundingCalculatorParameters.purchaseInfo.propertyValue = securityAmount;
      $scope.fundingCalculatorParameters.address.state = stateValue;
      if (stateValue === 'ACT') {
        $scope.fundingCalculatorParameters.clientInfo.totalIncomeOfPurchasers = purchasersTotalIncome;
        $scope.fundingCalculatorParameters.clientInfo.dependents = purchasersTotalDependents;
      }
      if (stateValue === AU_STATES.VICTORIA) {
        $scope.fundingCalculatorParameters.clientInfo.paymentMethod =
          EPICTENET_TRANSACTION_TYPE.ELECTRONIC;
      }

      if (primaryPurposeId) {
        $scope.fundingCalculatorParameters.purchaseInfo.purpose = getPurpose(
          primaryPurposeId,
        );
      }
    });
};

export const showCapitaliseLMIOption = (isFeatureFlagOn) => (
  proposedLending,
) => {
  if (!isFeatureFlagOn) {
    return false;
  }
  const lmiAmount = proposedLending && proposedLending.lmiAmount;
  const displayOption = lmiAmount && lmiAmount !== '0';
  return !!displayOption;
};

export const getRepaymentLMIText = (isFeatureFlagOn) => (
  proposedLendingSummary,
) => {
  if (!isFeatureFlagOn) {
    return;
  }
  if (!proposedLendingSummary || !proposedLendingSummary.length) {
    return;
  }

  const firstSplit = proposedLendingSummary[0] || {};
  const prefix = firstSplit.capitalizeLMI ? 'inc' : 'exc';
  return `(${prefix}. LMI)`;
};

export const getConvertedLendingValue = ({ $scope }) => {
  if (!$scope.fundingCalculatorFundsSummary) {
    return 0;
  }
  const {
    isCalculatedProposedLending,
    totalProposedLendingUserDefined,
    totalFundingRequired,
    totalFundingAvailable,
    fundingValue,
  } = $scope.fundingCalculatorFundsSummary;

  if (isCalculatedProposedLending) {
    return totalProposedLendingUserDefined;
  }

  const isRequiredAvailable = totalFundingRequired > totalFundingAvailable;
  const lendingValue = isRequiredAvailable
    ? totalFundingRequired - totalFundingAvailable
    : 0;
  const proposedLending = lendingValue - (fundingValue > 0 ? fundingValue : 0);
  return proposedLending > 0 ? Math.round(proposedLending * 100) / 100 : 0;
};

export const setProposedLendingValue = ({ $scope }) => () => {
  if (!$scope.fundingCalculatorFundsSummary) {
    return 0;
  }
  const {
    isCalculatedProposedLending: isCalculated,
    totalProposedLending,
    totalProposedLendingUserDefined,
    isManuallyUpdated,
  } = $scope.fundingCalculatorFundsSummary;

  if (!$scope.isFundingCalcFeatureOn) {
    const proposedLending = isCalculated
      ? totalProposedLending
      : totalProposedLendingUserDefined;
    return proposedLending || 0;
  }

  if (!$scope.fundingClcMainObj) {
    return 0;
  }
  const {
    tempTotalProposedLendingUserDefined,
    isAutoCalculationOn,
  } = $scope.fundingClcMainObj;
  if ($scope.isInitialLoad) {
    $scope.isInitialLoad = false;
    return tempTotalProposedLendingUserDefined;
  }

  if (isManuallyUpdated && !isAutoCalculationOn) {
    return totalProposedLendingUserDefined;
  }

  const lendingValueConverted = getConvertedLendingValue({ $scope });
  $scope.fundingClcMainObj.tempTotalProposedLendingUserDefined = lendingValueConverted;
  $scope.fundingCalculatorFundsSummary.totalProposedLendingUserDefined = lendingValueConverted;
  return lendingValueConverted;
};

export const setProposedLendingValueForSettings = ({
  $scope,
  updatedByUser,
}) => {
  if (updatedByUser && $scope.isFundingCalcFeatureOn) {
    if (!$scope.fundingClcMainObj) {
      return;
    }
    $scope.fundingClcMainObj.tempTotalProposedLendingUserDefined =
      $scope.fundingClcMainObj.totalProposedLendingByUser;
  } else {
    $scope.getProposedLendingValue();
  }
};

export const isPurposeConstruction = (purposeId) => {
  const constructionIds = [
    FUNDING_PURPOSE_IDS.CONSTRUCTION_INVESTMENT,
    FUNDING_PURPOSE_IDS.CONSTRUCTION_OWNER_OCCUPIED,
  ];
  return constructionIds.includes(parseInt(purposeId, 10));
};

export const isBuildAmountRequired = (fundingCalculatorObj) => {
  const defaultState = true;
  if (!fundingCalculatorObj) {
    return defaultState;
  }
  const purposeId =
    fundingCalculatorObj.purchaseInfo &&
    fundingCalculatorObj.purchaseInfo.purpose;
  if (!isPurposeConstruction(purposeId)) {
    return defaultState;
  }
  return fundingCalculatorObj.isAlreadyOwned;
};

export const getCalculatedProposedLending = (fundingSummary, capitalizeLMI) => {
  if (!fundingSummary) {
    return 0;
  }
  const { proposedLending, fundingValue, lmiAmount } = fundingSummary;
  const reduce = !capitalizeLMI && fundingValue ? lmiAmount : 0;
  return proposedLending + (fundingValue || 0) - reduce;
};

export const getProposedLendingToSave = ({
  $scope,
  manuallyUpdatedValue,
  useCurrentSplitValue,
}) => {
  if (!$scope) {
    return 0;
  }
  const {
    isCalculatedProposedLending: isCalculated,
    totalProposedLendingUserDefined,
  } = $scope.fundingCalculatorFundsSummary;
  const {
    totalProposedLendingByUser,
    tempTotalProposedLendingUserDefined,
  } = $scope.fundingClcMainObj;

  if (useCurrentSplitValue) {
    return totalProposedLendingUserDefined;
  }

  if (!isCalculated) {
    return manuallyUpdatedValue
      ? totalProposedLendingByUser
      : tempTotalProposedLendingUserDefined;
  }
  return tempTotalProposedLendingUserDefined;
};

export const amendSavedProposedLending = ({
  $scope,
  fundingCalculatorService,
}) => {
  const {
    isCalculatedProposedLending,
    totalProposedLendingUserDefined,
  } = $scope.fundingCalculatorFundsSummary;
  const amendProposedLending =
    !isCalculatedProposedLending && !totalProposedLendingUserDefined;
  if (!amendProposedLending) {
    return;
  }
  fundingCalculatorService
    .getFundingCalculatorFundsSummary($scope.brokerEventId)
    .then((response) => {
      if (!response) {
        return;
      }
      $scope.fundingCalculatorFundsSummary = {
        ...$scope.fundingCalculatorFundsSummary,
        ...response,
      };
      $scope.setProposedLendingSettings();
    });
};

export const isFirstLoanSplit = ({
  proposedLendingList,
  newProposedLendingList,
}) => (proposedLending) => {
  const validLists =
    Array.isArray(proposedLendingList) && Array.isArray(newProposedLendingList);
  if (!validLists || !proposedLending) {
    return false;
  }
  const existingIdx = proposedLendingList.findIndex(
    (item) => item.proposedLendingId === proposedLending.proposedLendingId,
  );
  const newListIdx = newProposedLendingList.indexOf(proposedLending);
  return existingIdx === 0 || (!proposedLendingList.length && newListIdx === 0);
};

export const setDefaultValue = (quickWinFlagOn) => (defaultId) => {
  if (!quickWinFlagOn || typeof defaultId === 'undefined') {
    return null;
  }
  return defaultId.toString();
};

export const getOrderedStatusList = (statusList) => {
  if (!statusList || !statusList.length) {
    return statusList;
  }
  const established = statusList.find(
    (item) => item.value === FUNDING_STATUS_IDS.ESTABLISHED.toString(),
  );
  if (!established) {
    return statusList;
  }
  const filtered = statusList.filter(
    (item) => item.value !== established.value,
  );
  return [established, ...filtered];
};

export const setFlagsAndDependentUtils = ({
  $scope,
  configService,
  currentUserService,
}) => {
  const {
    fundingCalculatorEnhancement: fundingCalcFlag,
    guarantorLoanFixes,
    fundingCalculatorQuickWinEnhancements,
  } = configService.feature;
  const { isAU, isNZ } = currentUserService;
  $scope.isFundingCalcFeatureOn = fundingCalcFlag;
  $scope.guarantorFixesFlag = fundingCalcFlag && guarantorLoanFixes;
  $scope.showCapitaliseLMIOption = showCapitaliseLMIOption(fundingCalcFlag);
  $scope.getRepaymentLMIText = getRepaymentLMIText(fundingCalcFlag);
  $scope.showFirstHomeWidget =
    isAU || (!fundingCalculatorQuickWinEnhancements && isNZ);
};

export const setBindingsBasedOnCountry = ({ $scope, currentUserService }) => {
  const { isAU, isNZ } = currentUserService;
  const { AU_GUARANTEE, NZ_GUARANTEE } = FUNDING_PURPOSE_IDS;
  $scope.GUARANTEE_PURPOSEID = isAU ? AU_GUARANTEE : NZ_GUARANTEE;
  $scope.isUserAU = isAU;
  $scope.isUserNZ = isNZ;
};

export const isPurpose = (param, type) => {
  if (!param || !type) {
    return false;
  }
  const purpose = dotProp.get(param, 'purchaseInfo.purpose');
  return parseToInt10(purpose) === type;
};

export const getPropertyParametersList = ({
  guarantorFixesFlag,
  guaranteeId,
  propertyParameters,
}) => {
  const { purposeParams = [] } = propertyParameters;
  const purposeList = guarantorFixesFlag
    ? purposeParams
    : purposeParams.filter((item) => parseToInt10(item.value) !== guaranteeId);
  return { ...propertyParameters, purposeParams: purposeList };
};

export const showForeignPurchaser = (state) => {
  return STATE_WITH_FOREIGN_PURCHASER.includes(state);
};

export const formatParameters = (parameters) => {
  if (!parameters || !parameters.length) {
    return [];
  }
  return parameters.map((param) => {
    const state = param.address.state;
    return { ...param, showForeignPurchaser: showForeignPurchaser(state) };
  });
};

export const setAddressState = (fundingCalculatorParameter, state) => {
  if (!fundingCalculatorParameter || !fundingCalculatorParameter.address) {
    return;
  }
  fundingCalculatorParameter.address.state = state;
  fundingCalculatorParameter.showForeignPurchaser = showForeignPurchaser(state);
};

export const getGeoCodeState = ({ generalService }) => (
  fundingCalculatorParameters,
  address,
) => {
  if (!generalService || !fundingCalculatorParameters) {
    return;
  }
  generalService.geocodeSearch(address).then(({ data }) => {
    if (!data) {
      return;
    }
    setAddressState(fundingCalculatorParameters, data.stateShort_Name);
  });
};

export const getValidatedPropertyParamsToSave = (
  fundingCalculatorParameters,
) => {
  const { purchaseInfo } = fundingCalculatorParameters;
  const purposeId = parseToInt10(purchaseInfo.purpose);

  if (!REFINANCE_PURPOSE_IDS.includes(purposeId)) {
    fundingCalculatorParameters.purchaseInfo.refinanceAmount = null;
  }
  if (!CONSTRUCTION_PURPOSE_IDS.includes(purposeId)) {
    fundingCalculatorParameters.landValue = null;
  }

  return fundingCalculatorParameters;
};

export const getProposedLendingRateText = (proposedLending) => {
  if (!proposedLending) {
    return '';
  }
  const interestType = proposedLending.isInterestOnly ? 'IO' : 'P&I';
  return `@ ${proposedLending.rate}% ${interestType}`;
};

export const setForeignPurchaser = (obj) => {
  if (!obj) {
    return;
  }
  obj.foreignPurchaser =
    obj.foreignPurchaser === GENERAL_QUESTION.YES
      ? GENERAL_QUESTION.NO
      : GENERAL_QUESTION.YES;
};

export const setEpictenetTransactionType = (obj, method) => {
  if (!obj) {
    return;
  }
  obj.paymentMethod = method;
};

export const setCalculatedProposedLending = (fundingCalcFlag, { $scope }) => (
  obj,
) => {
  if (!obj) {
    return;
  }
  obj.isCalculatedProposedLending = !obj.isCalculatedProposedLending;
  if (obj.isCalculatedProposedLending) {
    $scope.setProposedLendingSettings(false, true);
  } else if (fundingCalcFlag) {
    $scope.setProposedLendingSettings(true, true, false, true);
  } else {
    $scope.setProposedLendingSettings();
  }
};

export const setVictoriaCity = (obj) => {
  if (!obj) {
    return;
  }
  obj.victoriaCities =
    obj.victoriaCities === VICTORIA_CITIES.MELBOURNE
      ? VICTORIA_CITIES.REGIONAL
      : VICTORIA_CITIES.MELBOURNE;
};

export const setInterestOnly = (obj) => {
  if (!obj) {
    return;
  }
  obj.isInterestOnly = !obj.isInterestOnly;
};

export const setPropertyLocation = (obj, propertyName) => {
  if (!obj && !propertyName) {
    return;
  }
  obj.propertyLocation = propertyName;
};

export const setEligiblePensioner = (obj) => {
  if (!obj) {
    return;
  }
  obj.eligiblePensioner = !obj.eligiblePensioner;
};

export const setAutoCalculationOn = ({ $scope }) => (obj) => {
  if (!obj) {
    return;
  }
  obj.isAutoCalculationOn = !obj.isAutoCalculationOn;
  $scope.setProposedLendingSettings(!obj.isAutoCalculationOn, true);
};

export const setContructionLandToggles = ({ $scope }) => (obj, varName) => {
  if (!obj) {
    return;
  }
  if (varName === CONSTRUCTION_LAND.ALREADY_OWNED) {
    obj.isAlreadyOwned = !obj.isAlreadyOwned;
  } else if (varName === CONSTRUCTION_LAND.EQUITY_RELEASE) {
    obj.isEquityRelease = !obj.isEquityRelease;
  }
  $scope.clearRefiEquityFields(obj);
};

export const setCapitalizeLMIToggle = (obj) => {
  if (!obj) {
    return;
  }
  obj.capitalizeLMI = !obj.capitalizeLMI;
};

export const focusFirstLoanSplit = ({ $scope }) => (lmiAmount, isFirst) => {
  const focusLMI = isFirst && $scope.isFundingCalcFeatureOn && lmiAmount !== 0;
  const nextInput = focusLMI ? '.lmi-amount' : '.loan-amount';
  $scope.focusNextInput(nextInput);
};

export const focusInputElement = ({ $document, inputName, indexNumber }) => {
  const elementSelector = inputName;
  const elementTypes = $document.find(elementSelector);
  if (!elementTypes || !elementTypes.length) {
    return;
  }
  const focusIndex =
    indexNumber !== null ? indexNumber : elementTypes.length - 1;
  elementTypes[focusIndex].focus();
};

export const focusNextInput = ({ $timeout, $document }) => (
  inputName,
  indexNumber = null,
) => {
  if (!inputName) {
    return;
  }
  const timeoutValue = inputName === '.add-another-button' ? 1400 : 300;
  const searchInput = $timeout(() => {
    focusInputElement({ $document, inputName, indexNumber });
    $timeout.cancel(searchInput);
  }, timeoutValue);
};

export const removeSweetAlertFocus = ($window) => {
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  $window.onkeydown = null;
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  $window.onfocus = null;
};

export const gotoTopElement = ({ $scope }) => ($event, shouldReturn = true) => {
  if ($event.keyCode === KEY_CODE.TAB && shouldReturn) {
    $scope.focusNextInput('.property-edit', 0);
  }
};

export const setTabbingUtilities = ({ $scope, $timeout, $document }) => {
  const fundingCalcFlag = $scope.isFundingCalcFeatureOn;
  $scope.setForeignPurchaser = setForeignPurchaser;
  $scope.setEpictenetTransactionType = setEpictenetTransactionType;
  $scope.epictenetTransactionType = EPICTENET_TRANSACTION_TYPE;
  $scope.stateWithVaryingTransferTransaction = STATE_WITH_VARYING_TRANSFER_TRANSACTION;
  $scope.setCalculatedProposedLending = setCalculatedProposedLending(
    fundingCalcFlag,
    { $scope },
  );
  $scope.setVictoriaCity = setVictoriaCity;
  $scope.setInterestOnly = setInterestOnly;
  $scope.focusNextInput = focusNextInput({
    $timeout,
    $document,
  });
  $scope.setPropertyLocation = setPropertyLocation;
  $scope.setEligiblePensioner = setEligiblePensioner;
  $scope.setAutoCalculationOn = setAutoCalculationOn({ $scope });
  $scope.setContructionLandToggles = setContructionLandToggles({ $scope });
  $scope.setCapitalizeLMIToggle = setCapitalizeLMIToggle;
  $scope.focusFirstLoanSplit = focusFirstLoanSplit({ $scope });
  $scope.gotoTopElement = gotoTopElement({ $scope });
};

export const catchNoAutoComputedLMIError = (crmConfirmation) => {
  crmConfirmation.open({
    type: 'warning',
    title: 'Oops!',
    description: ERROR_LMI_NOT_MATCHED,
    showCloseButton: true,
    modalSize: 'md',
    cancelButtonClass: 'colored',
  });
};

export const reloadFundingCalcData = ({
  $scope,
  updatedByUser,
  totalProposedLending,
}) => {
  $scope.getFundingCalculatorFunds(true, updatedByUser);
  parseInt($scope.fundingCalculatorLMIDeatils.fundsLmiId, 10) &&
    ($scope.fundingCalculatorLMIDeatils.newLoanAmount = totalProposedLending);
  $scope.getFundingCalculatorLMIDeatils();
};

export const callProposedLendingSettingsSet = ({
  $scope,
  updatedByUser,
  allowAmend = false,
  manuallyUpdatedValue,
  totalProposedLending,
  isCalculatedProposedLending,
  crmConfirmation,
  fundingCalculatorService,
}) => {
  const $postData = {
    brokerEventId: $scope.brokerEventId,
    isCalculatedProposedLending,
    totalProposedLending,
    isManuallyUpdated: manuallyUpdatedValue,
  };

  return fundingCalculatorService
    .setProposedLendingSettings($postData)
    .then((response) => {
      if (!response.success && response.errorMessage) {
        catchNoAutoComputedLMIError(crmConfirmation);
        $scope.getFundingCalculatorFundsQuickLMIFavorite();
      }

      allowAmend &&
        amendSavedProposedLending({ $scope, fundingCalculatorService });

      reloadFundingCalcData({
        $scope,
        updatedByUser,
        totalProposedLending,
      });
    });
};

export const overrideTotalProposedLendingFromLMITargetLVR = ({
  $scope,
  totalProposedLending = 0,
  fundingCalculatorService,
  crmConfirmation,
}) => {
  callProposedLendingSettingsSet({
    $scope,
    updatedByUser: false,
    totalProposedLending,
    manuallyUpdatedValue: true,
    isCalculatedProposedLending: false,
    crmConfirmation,
    fundingCalculatorService,
  });
};

export const showRedirectToEditInput = ({ purpose, fundingName }) => {
  const purposeId = parseToInt10(purpose);
  let innerFundingToOpenEdit = [];
  switch (purposeId) {
    case FUNDING_PURPOSE_IDS.OWNER_OCCUPIED:
    case FUNDING_PURPOSE_IDS.RENTAL:
    case FUNDING_PURPOSE_IDS.OTHER:
    case FUNDING_PURPOSE_IDS.PURCHASE_OWNER_OCCUPIED:
    case FUNDING_PURPOSE_IDS.PURCHASE_INVESTMENT:
      innerFundingToOpenEdit = [DEFAULT_FUND_NAMES.TOTAL_PROPERTY_AMOUNT];
      break;
    case FUNDING_PURPOSE_IDS.REFI_EQUITY_OWNER_OCCUPIED:
    case FUNDING_PURPOSE_IDS.REFI_EQUITY_INVESTMENT:
      innerFundingToOpenEdit = [
        DEFAULT_FUND_NAMES.TOTAL_PROPERTY_AMOUNT,
        DEFAULT_FUND_NAMES.REFINANCE_AMOUNT,
      ];
      break;
    case FUNDING_PURPOSE_IDS.CONSTRUCTION_INVESTMENT:
    case FUNDING_PURPOSE_IDS.CONSTRUCTION_OWNER_OCCUPIED:
      innerFundingToOpenEdit = [
        DEFAULT_FUND_NAMES.LAND_VALUE,
        DEFAULT_FUND_NAMES.BUILD_AMOUNT,
      ];
      break;
    case FUNDING_PURPOSE_IDS.AU_GUARANTEE:
    case FUNDING_PURPOSE_IDS.NZ_GUARANTEE:
      innerFundingToOpenEdit = [
        DEFAULT_FUND_NAMES.TOTAL_PROPERTY_AMOUNT,
        DEFAULT_FUND_NAMES.LIMIT_OF_GUARANTEE,
      ];
      break;
    default:
  }
  return innerFundingToOpenEdit.includes(fundingName);
};

export const resetPropertyDetails = (fundingCalculatorParameters) => {
  if (!fundingCalculatorParameters) {
    return;
  }

  fundingCalculatorParameters.showPropertyMainDiv = false;
  if (fundingCalculatorParameters.backupModel) {
    fundingCalculatorParameters.address =
      fundingCalculatorParameters.backupModel.address;
    fundingCalculatorParameters.purchaseInfo =
      fundingCalculatorParameters.backupModel.purchaseInfo;
    fundingCalculatorParameters.clientInfo =
      fundingCalculatorParameters.backupModel.clientInfo;
    fundingCalculatorParameters.showForeignPurchaser =
      fundingCalculatorParameters.backupModel.showForeignPurchaser;

    fundingCalculatorParameters.backupModel = null;
  }
};
