import _ from 'lodash';
import {
  accreditationTypesForUI,
  accreditationTypesForAPI,
  accreditationEmailForAPI,
  bulkAccreditationTypesForAPI,
} from '../mappers/accreditations';

import {
  accreditationProductsForUI,
  accreditationProductsForAPI,
} from '../mappers/products';

export default class AccreditationAPIService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'corporate';
  }

  getAccreditations(familyId, accreditationTypeId, excludeBlankData) {
    const url = `${this.apiBaseUrl}/adviser-accreditation`;
    return this.httpClient
      .get(url, { familyId, accreditationTypeId })
      .then(({ succeeded, data = [] }) => {
        let filterData = data;
        if (excludeBlankData) {
          // eslint-disable-next-line angular/function-type
          filterData = _.filter(
            data,
            (row) => !!row.Status || !!row.AdviserId || !!row.DateAccredited,
          );
        }
        return {
          isSucceeded: succeeded,
          data: _.map(filterData, accreditationTypesForUI),
        };
      });
  }

  getProducts(familyId) {
    const url = `${this.apiBaseUrl}/accreditation-products?familyid=${familyId}`;
    return this.httpClient.get(url).then(({ succeeded, data }) => {
      return {
        isSucceeded: succeeded,
        data: (data && accreditationProductsForUI(data)) || [],
      };
    });
  }

  setLender(data = {}, callback) {
    const forAPIData = accreditationTypesForAPI(data);
    const url = `${this.apiBaseUrl}/adviser-accreditation`;
    const postPromise = this.httpClient.post(url, forAPIData);
    callback(postPromise, accreditationTypesForUI);
  }

  setBulkProviders(data = {}, callback) {
    const forAPIData = bulkAccreditationTypesForAPI(data);
    const url = `${this.apiBaseUrl}/adviser-accreditation`;
    return this.httpClient.put(url, forAPIData).then(callback);
  }

  setProducts(data = {}) {
    const forAPIData = accreditationProductsForAPI(data);
    const url = `${this.apiBaseUrl}/accreditation-products`;
    return this.httpClient.post(url, forAPIData);
  }

  sendAccreditationEmail(data = {}) {
    const forAPIData = accreditationEmailForAPI(data);
    const url = `${this.apiBaseUrl}/adviser-accreditation-email`;
    return this.httpClient.post(url, forAPIData);
  }
}
