import angular from 'angular';
import $ from 'jquery';
import swal from 'sweetalert';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboarding';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ENTITY_TYPE } from 'Common/constants/entityType';
import { SCREEN_SIZE } from 'Common/constants/screenSize';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';
import {
  isLMBranded,
  isWMBranded,
  isOwnBrand,
} from 'Common/utilities/brandingCategory';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { RESIZE_SUMMARY_TABS } from 'Common/constants/broadcast';
import {
  setTemplateSrc,
  getNotificationSrc,
} from 'Common/utilities/templateSrc';
import { setCountNotification } from 'Common/utilities/sidebarNotification';
import { shouldNotSetSidebarDefaultLogo } from './util/AppCtrl';
import { FETCH_RECENTLY_VIEWED } from 'Assets/js/controllers/util/constants';

/**
 * Clip-Two Main Controller
 */
export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('AppCtrl', function AppCtrl(
      $rootScope,
      $scope,
      $state,
      $localStorage,
      $window,
      $document,
      $timeout,
      cfpLoadingBar,
      Fullscreen,
      configService,
      $uibModal,
      dashboardService,
      customThemeService,
      contactService,
      regionalizationService,
      notificationSidebarService,
      onboardingService,
      mapService,
      corporateService,
      corporateModelService,
      broadcastDataService,
      uiService,
      loadAllocationModalService,
      currentUserService,
      stateService,
      TECHNOLOGY,
    ) {
      $scope.isAngular = TECHNOLOGY.isAngular;
      $scope.configService = configService;
      $scope.localStorage = $localStorage;
      // Loading bar transition
      // -----------------------------------
      const $win = $($window);
      const $body = $('.body');

      $scope.hideNav =
        $scope.configService.feature.useNavInAngular && !$scope.isAngular;

      $rootScope.$on('$stateChangeStart', (event, toState) => {
        // start loading bar on stateChangeStart
        cfpLoadingBar.start();
        if (toState.name === 'app.pagelayouts.boxedpage') {
          $body.addClass('app-boxed-page');
        } else {
          $body.removeClass('app-boxed-page');
        }
      });

      $rootScope.$on('$stateChangeSuccess', (event) => {
        // stop loading bar on stateChangeSuccess
        event.targetScope.$watch('$viewContentLoaded', () => {
          cfpLoadingBar.complete();
        });

        // scroll top the page on change state
        $('#app .main-content').css({
          position: 'relative',
          top: 'auto',
        });

        $('footer').show();

        $window.scrollTo(0, 0);

        if (angular.element('.email-reader').length) {
          angular.element('.email-reader').animate(
            {
              scrollTop: 0,
            },
            0,
          );
        }

        // Save the route title
        $rootScope.currTitle = $state.current.title;
      });

      $rootScope.pageTitle = function () {
        return `${$rootScope.app.name} - ${
          $rootScope.currTitle || $rootScope.app.description
        }`;
      };

      // save settings to local storage
      if (angular.isDefined($localStorage.lay)) {
        $scope.app.layout = angular.copy($localStorage.lay);
      }

      $scope.resetLayout = function () {
        $scope.loading_reset = true;
        // start loading
        $timeout(() => {
          delete $localStorage.lay;
          $scope.app.layout = angular.copy($rootScope.app.defaultLayout);
          $scope.loading_reset = false;
          // stop loading
        }, 500);
      };

      $scope.saveLayout = function () {
        $scope.loading_save = true;
        // start loading
        $timeout(() => {
          $localStorage.lay = angular.copy($scope.app.layout);
          $scope.loading_save = false;
          // stop loading
        }, 500);
      };

      $scope.setLayout = function () {
        $scope.app.layout.isNavbarFixed = false;
        // eslint-disable-next-line angular/window-service
        $scope.app.layout.isSidebarClosed = window.technology === 'react';
        $scope.app.layout.isSidebarFixed = false;
        $scope.app.layout.isFooterFixed = false;
        $scope.app.layout.isBoxedPage = false;
      };

      // global function to scroll page up
      $scope.toTheTop = function () {
        $document.scrollTopAnimated(0, 600);
      };

      // Fullscreen
      $scope.isFullscreen = false;
      $scope.goFullscreen = function () {
        $scope.isFullscreen = !$scope.isFullscreen;
        if (Fullscreen.isEnabled()) {
          Fullscreen.cancel();
        } else {
          Fullscreen.all();
        }

        // Set Fullscreen to a specific element (bad practice)
        // Fullscreen.enable( document.getElementById('img') )
      };

      // Function that find the exact height and width of the viewport in a cross-browser way
      const viewport = function () {
        let e = $window;
        let a = 'inner';

        if (!('innerWidth' in window)) {
          a = 'client';
          e = $document[0].documentElement || $document[0].body;
        }

        return {
          width: e[`${a}Width`],
          height: e[`${a}Height`],
        };
      };

      // function that adds information in a scope of the height and width of the page
      $scope.getWindowDimensions = function () {
        return {
          h: viewport().height,
          w: viewport().width,
        };
      };

      // Detect when window is resized and set some variables
      $scope.$watch(
        $scope.getWindowDimensions,
        (newValue) => {
          $scope.windowHeight = newValue.h;
          $scope.windowWidth = newValue.w;
          if (newValue.w >= SCREEN_SIZE.XL) {
            /* adjusted from 1600 to 2900 so this one should not be executed since it will cause arrangements issues in some pages. */

            $scope.isXLargeDevice = true;
            if (uiService.notificationSidebarObj) {
              uiService.notificationSidebarObj.showSlided = true;
              $rootScope.setNotificationView(
                uiService.notificationSidebarObj.lastClickedItem,
              );
            }
          } else {
            $scope.isXLargeDevice = false;
          }

          if (newValue.w <= SCREEN_SIZE.LG) {
            $scope.app.layout.isSidebarClosed = true;
          } else {
            // eslint-disable-next-line angular/window-service
            $scope.app.layout.isSidebarClosed = window.technology === 'react';
          }

          if (newValue.w >= SCREEN_SIZE.MD) {
            $scope.isLargeDevice = true;
          } else {
            $scope.isLargeDevice = false;
          }
          if (newValue.w < SCREEN_SIZE.MD) {
            $scope.isSmallDevice = true;
          } else {
            $scope.isSmallDevice = false;
          }
          if (newValue.w <= SCREEN_SIZE.SM) {
            $scope.isMobileDevice = true;
          } else {
            $scope.isMobileDevice = false;
          }
        },
        true,
      );
      // Apply on resize
      $win.on('resize', () => {
        $rootScope.$broadcast(RESIZE_SUMMARY_TABS);
        const resizeTimeout = $timeout(() => {
          if ($scope.isLargeDevice) {
            $('#app .main-content').css({
              position: 'relative',
              top: 'auto',
              width: 'auto',
            });
            $('footer').show();
          }
          $timeout.cancel(resizeTimeout);
        });
      });
      $scope.displayPanel = {};
      $scope.clickPanel = function (panelName) {
        if ($scope.displayPanel[panelName]) {
          $scope.displayPanel[panelName] = false;
        } else {
          $scope.displayPanel = {};
          $scope.displayPanel[panelName] = true;
        }
      };

      $scope.isDisplayPanel = function (panelName) {
        return $scope.displayPanel[panelName];
      };

      // notification sidebar start
      uiService.notificationSidebarObj = {
        lastClickedItem: 'none',
        showSlided: false,
        showSlidedBarXs: false,
        userSelection: '',
      };
      $scope.sidebarAnimation = 'Animation2';
      $scope.hidingSidebar = false;

      function getNotifications() {
        $timeout(() => {
          if (
            // eslint-disable-next-line sonarjs/no-duplicate-string
            angular.element('[ng-controller="ActivitySidebarCtrl"]').scope() !==
            undefined
          ) {
            angular
              .element('[ng-controller="ActivitySidebarCtrl"]')
              .scope()
              .activityFeed.countUnread();
          }
        }, 1000);
        $timeout(() => {
          if (
            angular.element('[ng-controller="ActivitySidebarCtrl"]').scope() !==
            undefined
          ) {
            angular
              .element('[ng-controller="ActivitySidebarCtrl"]')
              .scope()
              .activityFeed.getAll();
          }
        }, 2000);
      }

      function getSidebarPreference() {
        setCountNotification({ $rootScope, notificationSidebarService });
        notificationSidebarService.UserDefaultSelectionGet().then(
          (response) => {
            let preferredView = response.data;

            if (
              (preferredView === '' ||
                preferredView === null ||
                preferredView === undefined) &&
              $scope.isXLargeDevice
            ) {
              preferredView = 'activity';
              getNotifications();
              //  setSidebarPreference(preferredView);
            } else if (
              (preferredView === '' ||
                preferredView === null ||
                preferredView === undefined) &&
              !$scope.isXLargeDevice
            ) {
              preferredView = 'none';
            }
            $scope.setNotificationView(preferredView);
          },
          () => {},
        );
      }

      function setSidebarPreference() {
        notificationSidebarService.UserDefaultSelectionSet({
          userSelection: uiService.notificationSidebarObj.lastClickedItem,
        });
      }

      $rootScope.setNotificationView = function (view) {
        if (view !== 'none') {
          $scope.notificationSidebarSrc = getNotificationSrc();
        }
        setTemplateSrc({ $scope, view });

        if (uiService.notificationSidebarObj.lastClickedItem !== view) {
          uiService.notificationSidebarObj.showSlided = true;
          uiService.notificationSidebarObj.lastClickedItem = view;
        } else {
          uiService.notificationSidebarObj.showSlided = false;
          uiService.notificationSidebarObj.lastClickedItem = 'none';
        }

        // eslint-disable-next-line sonarjs/no-extra-arguments
        setSidebarPreference(uiService.notificationSidebarObj.lastClickedItem);

        $scope.hidingSidebar = true;
        $timeout(() => {
          $scope.hidingSidebar = false;
        }, 1000);

        $rootScope.initialView = view;
        $rootScope.$broadcast('setNotificationView', {
          view,
          showSlided: uiService.notificationSidebarObj.showSlided,
        });

        if (view === 'none') {
          return;
        }

        getNotifications();

        if (view === 'task') {
          $rootScope.$broadcast('reloadTaskData', { reloadTask: true });
        }
      };

      const expandSidebarXsHandler = $rootScope.$on(
        'expandFullSidebarXs',
        (event, args) => {
          $scope.currentView = args.view;
          if (args.expand) {
            $scope.sidebarAnimation = 'Animation3';
          } else {
            $scope.sidebarAnimation = 'Animation4';
          }
        },
      );
      $scope.$on('$destroy', expandSidebarXsHandler);

      function handleAnimation() {
        if (uiService.notificationSidebarObj.showSlidedBarXs) {
          $scope.sidebarAnimation = 'Animation1';
        } else {
          $scope.sidebarAnimation = 'Animation2';
        }
      }

      const setNotificationBarOnlyHandler = $rootScope.$on(
        'setNotificationBarOnly',
        (event, args) => {
          uiService.notificationSidebarObj.showSlidedBarXs = args.show;
          handleAnimation();
        },
      );
      $scope.$on('$destroy', setNotificationBarOnlyHandler);

      const logoutUserFromSidebarHandler = $rootScope.$on(
        'logoutUserFromSidebar',
        () => {
          uiService.logoutUser();
        },
      );
      $scope.$on('$destroy', logoutUserFromSidebarHandler);

      $rootScope.setNotificationBarOnly = function () {
        $scope.notificationSidebarXsSrc = getNotificationSrc(true);
        uiService.notificationSidebarObj.showSlidedBarXs = !uiService
          .notificationSidebarObj.showSlidedBarXs;
        handleAnimation();
      };

      $scope.convertAnimForMobile = function () {
        switch ($scope.sidebarAnimation) {
          case 'Animation1':
            return 'showSlidedBarXs';
          case 'Animation2':
            return 'hideSlidedBarXs';
          case 'Animation3':
            return 'showSlideBarFullXs';
          case 'Animation4':
            return 'hideSlideBarFullXs';
          default:
            break;
        }
      };

      getSidebarPreference();
      // notification sidebar end

      // Make First Latter Capital Of Word
      $rootScope.capitalize = function (inputValue) {
        $rootScope.userHeaderName = inputValue
          .split(' ')
          .reduce((prevValue, word) => {
            return `${
              prevValue +
              word.slice(0, 1).toUpperCase() +
              word.slice(1).toLowerCase()
            } `;
          }, ' ');
      };
      // Get Broker Info
      dashboardService.getBrokerBasicInfo().then((response) => {
        $rootScope.brokerInfo = response.data;
        if (
          $rootScope.brokerInfo &&
          $rootScope.brokerInfo.PreferredName &&
          $rootScope.brokerInfo.PreferredName.length
        ) {
          const lastName = $rootScope.brokerInfo.LastName || '';
          $rootScope.capitalize(
            `${$rootScope.brokerInfo.PreferredName} ${lastName}`,
          );
          $rootScope.brokerInfo.DisplayName = `${$rootScope.brokerInfo.PreferredName} ${lastName}`;
        } else {
          $rootScope.capitalize($rootScope.brokerInfo.FullName);
          $rootScope.brokerInfo.DisplayName = $rootScope.brokerInfo.FullName;
        }

        regionalizationService
          .getBrokerRegionalization($scope.brokerInfo.BrokerId)
          .then((res) => {
            $rootScope.crmCurrency = res.data.CurrencySign;
            $rootScope.crmCurrencyCode = res.data.Code;
            $rootScope.crmCountry = res.data.Name;
            $rootScope.crmCountryID = res.data.CountryID;
            // to fix the uiService issue on country when refresh
            $window.sessionStorage.setItem('brokerCountryInfo', res.data.Name);
          });
      });

      // open modal for tagged cases
      $scope.openTaggedModal = function (size) {
        $uibModal.open({
          templateUrl:
            '/assets/views/dashboard/partials/tagged_cases_panel.html',
          scope: $scope,
          controller($uibModalInstance) {
            dashboardService.getTaggedcases().then((response) => {
              $scope.taggedCases = response.data;
            });

            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
          size,
          resolve: {},
        });
      };

      // Retrieve Recently Viewed Converted to Service
      const fetchRecentlyViewed = () => {
        broadcastDataService.recentView.get().then((res) => {
          broadcastDataService.recentView.list = res;
          $rootScope.recentlyViewed = broadcastDataService.recentView;
        });
      };
      fetchRecentlyViewed();

      const onFetchRecentlyViewed = $scope.$on(
        FETCH_RECENTLY_VIEWED,
        fetchRecentlyViewed,
      );
      $scope.$on('$destroy', onFetchRecentlyViewed);

      $scope.onSearchItemSelect = function ($item) {
        if ($item.MatchResultType === 'Client') {
          $window.location.href = `#/app/contacts/${$item.ResultID}`;
        }
      };

      $rootScope.dynamicYear = new Date().getFullYear();

      /**
       * Replicating Inner Methods from Different Pages to create certain something
       * */
      $scope.launchControls = function () {
        /*
         * Create Contacts
         */
        $scope.openAddClientModal = function () {
          stateService.routeToContactList();
          $timeout(() => {
            angular
              .element('[ng-controller="ContactsCtrl"]')
              .scope()
              .openAddClientModal('wide');
          }, 500);
        };

        $scope.goToAdditionalRepayment = function () {
          $state.go('app.additionalRepayment');
        };

        $scope.goToCompareLoans = function () {
          $state.go('app.compareLoans');
        };

        $scope.goToSavingGoal = function () {
          $state.go('app.savingGoal');
        };
      };

      const setDefaultSidebarLogo = (brandingCategoryId) => {
        if (
          shouldNotSetSidebarDefaultLogo(
            brandingCategoryId,
            currentUserService.isOnboarding,
          )
        ) {
          return;
        }

        const DEFAULT_LOGOS_DIRECTORY = customThemeService.getDefaultLogoDirectories();

        if (!isOwnBrand(brandingCategoryId)) {
          const logoFile =
            (isLMBranded(brandingCategoryId) && 'LOAN_MARKET') ||
            (isWMBranded(brandingCategoryId)
              ? 'WEALTH_MARKET'
              : 'INSURANCE_MARKET');
          customThemeService.setDefaultSidebarLogo(
            DEFAULT_LOGOS_DIRECTORY[logoFile],
            brandingCategoryId,
          );
        }
      };
      /**
       * Initialize launch controls functions
       * */
      $scope.launchControls();
      // Auto customize theme
      // comment this for now cause this causes some issues,
      // will uncomment this if customize theme put back
      $timeout(() => {
        if (!currentUserService) {
          return;
        }
        let { brandingCategoryID } = currentUserService;
        const isNZ = COUNTRY_TYPE.NEW_ZEALAND === currentUserService.countryId;
        const isCorp = ACCESS_TYPE.CORPORATE === currentUserService.accessType;
        const isUseDefaultSidebarBG =
          ((isCorp || !isNZ) && !isOwnBrand(brandingCategoryID)) ||
          !configService.feature ||
          !configService.feature.customizeTheme ||
          currentUserService.isOnboarding;

        brandingCategoryID = isCorp
          ? BRANDING_CATEGORY_TYPE.LOAN_MARKET
          : brandingCategoryID;
        if (!isCorp) {
          setDefaultSidebarLogo(brandingCategoryID);
        }
        customThemeService.initGlobalCSS(
          isUseDefaultSidebarBG,
          brandingCategoryID,
        );
        $scope.themeBranding = customThemeService.branding;
      }, 500);

      const setCkEditorStyling = () => {
        const shouldCompactCkEditorUi =
          typeof $window.CKEDITOR !== 'undefined' &&
          configService?.feature.redesignedExpandedNotesDisplay;

        if (shouldCompactCkEditorUi) {
          $window.CKEDITOR.addCss('.cke_editable p { margin: 0 !important }');
        }
      };

      // Onboarding Welcome Screen by Elmer
      $scope.familyIdForAccreditation = 0;
      function checkIfOnboarding() {
        // rootscope use for view check to show the page for onboarding.
        $rootScope.isOnboarding = false;
        // contactService.getUserInfo().then((response) => {
        //   $rootScope.reportingCountry = response.data.CountryId;
        // });
        dashboardService.getBrokerBasicInfo().then((response) => {
          if (response && response.data) {
            const brokerInfo = response.data;
            const familyId = brokerInfo.BrokerId;
            const clientId = 0;
            const userName = !brokerInfo.PreferredName
              ? brokerInfo.FirstName
              : brokerInfo.PreferredName;
            $scope.familyIdForAccreditation = brokerInfo.BrokerId;
            $scope.isSkipScreen = 0;
            // Get Onboarding
            contactService
              .getIfOnboardingStatus(familyId, clientId)
              .then((onboardingStatus) => {
                setCkEditorStyling();
                // if status is onboarding
                if (onboardingStatus && onboardingStatus.data === 1) {
                  corporateModelService
                    .getOnlineOnboardingActive(familyId)
                    .then(({ data: dataResp }) => {
                      $rootScope.isOnboarding =
                        dataResp.OnlineOnboardingActive || false;
                      if (!dataResp) {
                        return;
                      }
                      if (!dataResp.OnlineOnboardingActive) {
                        swal(
                          {
                            title: 'Online Onboarding',
                            text: 'Online Onboarding has now been locked',
                            type: 'warning',
                            confirmButtonText: 'Ok',
                          },
                          () => {
                            $state.go('app.dashboard', { reload: true });
                          },
                        );
                      } else {
                        contactService
                          .getSkipWelcomeScreen(familyId)
                          .then((skipWelcomeScreen) => {
                            $scope.isSkipScreen = skipWelcomeScreen.data || 0;
                          })
                          .then(() => {
                            // check if not skipscreen
                            if (!$scope.isSkipScreen) {
                              $uibModal.open({
                                templateUrl:
                                  'assets/views/dashboard/onboardingWelcomeScreen.html',
                                size: 'lg',
                                backdrop: 'static',
                                scope: $scope,
                                keyboard: false,
                                windowClass: $scope.app.isMobile
                                  ? 'onboarding'
                                  : '',
                                controller: (
                                  userNameVal,
                                  $uibModalInstance,
                                  familyIdVal,
                                  clientIdVal,
                                  isSkipScreen,
                                  $filter,
                                  onboardingDataService,
                                  dataService,
                                ) => {
                                  const regularMaxStep = 2;
                                  const loanWriterMaxStep = 4;

                                  $scope.isLoanWriter = false;
                                  $scope.isContractor = false;
                                  $scope.isEmployee = false;
                                  $scope.isFranchisee = false;
                                  // $scope.isPartner = false; pending
                                  $scope.isDirector = false;
                                  $scope.isAdviser = false;
                                  $scope.isBYOB = currentUserService.isBYOB;

                                  $scope.userName = userNameVal;
                                  $scope.familyId = familyIdVal;
                                  $scope.clientId = clientId;
                                  $scope.onboardingModel = {};
                                  $scope.onboardingModel.isDontShow = false;
                                  $scope.entityDetails = {};
                                  $scope.isSkipScreen = isSkipScreen;
                                  $scope.relationship = {};
                                  $scope.currentStepLocation = 1;
                                  $scope.maxStep = regularMaxStep;
                                  $scope.reportingCountryId = 0;
                                  $scope.entity = {};
                                  $scope.relationshipList = [];

                                  $scope.logoutUser = function () {
                                    uiService.logoutUser();
                                  };

                                  $scope.nextStepOnboarding = function () {
                                    angular
                                      .element('#carousel')
                                      .slick('slickNext');
                                    $scope.currentStepLocation++;
                                  };

                                  const setSkipWelcomeScreen = () => {
                                    if ($scope.onboardingModel.isDontShow) {
                                      contactService.setSkipWelcomeScreen(
                                        familyId,
                                        1,
                                      );
                                    }
                                  };

                                  $scope.startOnboarding = function () {
                                    if ($scope.isFranchisee) {
                                      $scope.startOnboardingFranchisee();
                                    } else if (
                                      $scope.isLoanWriter &&
                                      !$scope.isFranchisee
                                    ) {
                                      $scope.startOnboardingLoanWriter();
                                    } else {
                                      $scope.startOnboardingAdviser();
                                    }
                                  };

                                  const adviserOrganizationEntityTypesGet = () => {
                                    corporateService
                                      .adviserOrganizationEntityTypesGet()
                                      .then((organisationEntityTypes) => {
                                        /* for select dropdown */
                                        $scope.entity.typeList =
                                          organisationEntityTypes.data;
                                        const found = $scope.entity.typeList.filter(
                                          (value) =>
                                            value.EntityTypeName ===
                                            onboardingDataService.getUserCurrentEntityType(),
                                        );
                                        if (!found || found.length < 1) {
                                          return;
                                        }
                                        if (
                                          $scope.reportingCountryId ===
                                            COUNTRY_TYPE.NEW_ZEALAND &&
                                          onboardingDataService.isOnboardingUserRole(
                                            $scope.adviserDetails.RoleId,
                                            ONBOARDING_ROLE_ID.FRANCHISEE,
                                          )
                                        ) {
                                          $scope.entity.typeList = $scope.entity.typeList.filter(
                                            (isNewZealand) =>
                                              isNewZealand.EntityTypeName !==
                                                ENTITY_TYPE.PARTNERSHIP &&
                                              isNewZealand.EntityTypeName !==
                                                ENTITY_TYPE.SOLE_TRADER &&
                                              isNewZealand.EntityTypeName !==
                                                ENTITY_TYPE.SOLE_TRADER_TRUST,
                                          );
                                        }
                                        $scope.entity.selection = found[0];
                                      });
                                  };

                                  const getAdviserDetails = () => {
                                    onboardingDataService
                                      .getOnboardingResponse(familyId, clientId)
                                      .then((onboardingResponse) => {
                                        if (onboardingResponse) {
                                          $scope.isLoanWriter = onboardingDataService.isOnboardingUserRole(
                                            onboardingResponse.RoleId,
                                            ONBOARDING_ROLE_ID.LOAN_WRITER,
                                          );
                                          $scope.isFranchisee = onboardingDataService.isOnboardingUserRole(
                                            onboardingResponse.RoleId,
                                            ONBOARDING_ROLE_ID.FRANCHISEE,
                                          );
                                          $scope.isContractor = onboardingDataService.isOnboardingUserRole(
                                            onboardingResponse.RoleId,
                                            ONBOARDING_ROLE_ID.CONTRACTOR,
                                          );
                                          $scope.isEmployee = onboardingDataService.isOnboardingUserRole(
                                            onboardingResponse.RoleId,
                                            ONBOARDING_ROLE_ID.EMPLOYEE,
                                          );
                                          $scope.isDirector = onboardingDataService.isOnboardingUserRole(
                                            onboardingResponse.RoleId,
                                            ONBOARDING_ROLE_ID.DIRECTOR,
                                          );
                                          $scope.isAdviser =
                                            !$scope.isFranchisee &&
                                            !$scope.isLoanWriter &&
                                            !$scope.isEmployee &&
                                            !$scope.isContractor;
                                          $scope.entityTypeId =
                                            onboardingResponse.EntityTypeId;
                                          $scope.maxStep =
                                            $scope.isLoanWriter &&
                                            !$scope.isFranchisee
                                              ? loanWriterMaxStep
                                              : regularMaxStep;
                                          if (
                                            onboardingDataService.isOnboardingUserRole(
                                              onboardingResponse.RoleId,
                                              ONBOARDING_ROLE_ID.EMPLOYEE,
                                            )
                                          ) {
                                            $scope.relationship.values =
                                              $scope.relationshipList[0];
                                          }
                                          if (
                                            onboardingDataService.isOnboardingUserRole(
                                              onboardingResponse.RoleId,
                                              ONBOARDING_ROLE_ID.CONTRACTOR,
                                            )
                                          ) {
                                            $scope.relationship.values =
                                              $scope.relationshipList[1];
                                          }
                                          $scope.adviserDetails = onboardingResponse;
                                        }
                                      })
                                      .then(() => {
                                        adviserOrganizationEntityTypesGet();
                                      });
                                  };

                                  $scope.startOnboardingLoanWriter = function () {
                                    if ($scope.relationship.values) {
                                      if (
                                        $scope.relationship.values.id !==
                                        'undefined'
                                      ) {
                                        if (
                                          !onboardingDataService.isOnboardingUserRole(
                                            $scope.adviserDetails.RoleId,
                                            ONBOARDING_ROLE_ID.EMPLOYEE,
                                          ) &&
                                          !onboardingDataService.isOnboardingUserRole(
                                            $scope.adviserDetails.RoleId,
                                            ONBOARDING_ROLE_ID.CONTRACTOR,
                                          )
                                        ) {
                                          // No existing relationship
                                          $scope.adviserDetails.RoleId = `${$scope.adviserDetails.RoleId},${$scope.relationship.values.id}`;
                                        } else if (
                                          onboardingDataService.isOnboardingUserRole(
                                            $scope.adviserDetails.RoleId,
                                            ONBOARDING_ROLE_ID.EMPLOYEE,
                                          )
                                        ) {
                                          $scope.adviserDetails.RoleId = $scope.adviserDetails.RoleId.replace(
                                            /14/g,
                                            $scope.relationship.values.id,
                                          );
                                        } else if (
                                          onboardingDataService.isOnboardingUserRole(
                                            $scope.adviserDetails.RoleId,
                                            ONBOARDING_ROLE_ID.CONTRACTOR,
                                          )
                                        ) {
                                          $scope.adviserDetails.RoleId = $scope.adviserDetails.RoleId.replace(
                                            /15/g,
                                            $scope.relationship.values.id,
                                          );
                                        }
                                        onboardingService
                                          .setRecruitmentContact(
                                            familyId,
                                            $scope.adviserDetails,
                                          )
                                          .then(() => {
                                            if (
                                              $scope.onboardingModel.isDontShow
                                            ) {
                                              contactService.setSkipWelcomeScreen(
                                                familyId,
                                                1,
                                              );
                                            }
                                            onboardingDataService.setIsRoleType(
                                              $scope.relationship.values.id,
                                            );
                                          });
                                        setSkipWelcomeScreen();
                                        $state.go(
                                          // eslint-disable-next-line sonarjs/no-duplicate-string
                                          'app.contactOnboarding',
                                          { familyId, clientId },
                                          { reload: true, inherit: false },
                                        );
                                        $uibModalInstance.dismiss('cancel');
                                      } else {
                                        swal(
                                          'Required Field',
                                          'Please select relationship type!.',
                                          'error',
                                        );
                                      }
                                    }
                                  };

                                  $scope.startOnboardingFranchisee = function () {
                                    if (
                                      typeof $scope.entity.selection !==
                                      'undefined'
                                    ) {
                                      onboardingDataService.setUserCurrentEntityType(
                                        $scope.entity.selection.EntityTypeName,
                                      );
                                      $scope.entityDetails.EntityTypeId =
                                        $scope.entity.selection.EntityTypeID;
                                      $scope.entityDetails.EntityType =
                                        $scope.entity.selection.EntityTypeName;
                                      $scope.entityDetails.MainOrganisationId =
                                        $scope.entityDetails.AdviserOrganizationId;
                                      const data = {
                                        MainOrganisationId: 0,
                                        AdviserOrganizationId:
                                          $scope.entityDetails
                                            .AdviserOrganisationId,
                                        LeadAdviser_FamilyId:
                                          $scope.familyId || 0,
                                        AdviserOrganisationName:
                                          $scope.entityDetails
                                            .AdviserOrganisationName || '',
                                        ABN: $scope.entityDetails.ABN || '',
                                        ACN: $scope.entityDetails.ACN || '',
                                        EntityType:
                                          $scope.entity.selection
                                            .EntityTypeName || '',
                                        EntityTypeId:
                                          $scope.entity.selection
                                            .EntityTypeID || '',
                                        Fax: $scope.entityDetails.Fax || '',
                                        Phone: $scope.entityDetails.Phone || '',
                                        FranchisePrincipalFamilyName:
                                          $scope.entityDetails
                                            .FranchisePrincipalFamilyName,
                                      };

                                      onboardingService
                                        .setRecruitmentAdviserOrganization(data)
                                        .then(() => {
                                          setSkipWelcomeScreen();
                                          $state.go(
                                            'app.contactOnboarding',
                                            { familyId, clientId },
                                            { reload: true, inherit: false },
                                          );
                                          $uibModalInstance.dismiss('cancel');
                                        });
                                    } else {
                                      swal(
                                        'Required Field',
                                        'Please select entity type!.',
                                        'error',
                                      );
                                    }
                                  };

                                  $scope.startOnboardingAdviser = function () {
                                    setSkipWelcomeScreen();
                                    $state.go('app.contactOnboarding', {
                                      familyId,
                                      clientId,
                                    });
                                    $uibModalInstance.dismiss('cancel');
                                  };
                                  // initialize data
                                  const initialize = () => {
                                    onboardingDataService.setFamilyId(
                                      $scope.familyId,
                                    );
                                    onboardingDataService.setClientId(
                                      $scope.clientId,
                                    );
                                    dataService
                                      .getUserInfo(true)
                                      .then((userInfo) => {
                                        if (userInfo && userInfo.data) {
                                          $scope.reportingCountryId =
                                            userInfo.data.CountryId;
                                        }
                                      })
                                      .then(() => {
                                        $scope.relationshipList = onboardingDataService.getRelationships();

                                        getAdviserDetails();
                                        onboardingDataService
                                          .getAdviserOrgDetails()
                                          .then((resp) => {
                                            if (resp) {
                                              $scope.entityDetails = resp;
                                              onboardingDataService.setUserCurrentEntityType(
                                                $scope.entityDetails.EntityType,
                                              );
                                            }
                                          });
                                      });
                                  };

                                  initialize();
                                },
                                resolve: {
                                  userNameVal() {
                                    return userName;
                                  },
                                  familyIdVal() {
                                    return familyId;
                                  },
                                  clientIdVal() {
                                    return clientId;
                                  },
                                  isSkipScreen() {
                                    return $scope.isSkipScreen;
                                  },
                                },
                              });
                            } else {
                              $state.go(
                                'app.contactOnboarding',
                                { familyId, clientId },
                                { reload: true, inherit: false },
                              );
                            }
                          });
                      }
                    });
                }
              });
          }
        });
      }
      checkIfOnboarding();
      $scope.AddressResultLimit = 3;

      // Pre Loader
      /*
       *   Sizes = `small`
       */
      $scope.preLoaderText = '';
      $scope.preLoaderSize = '';
      $scope.showPreLoader = false;

      $scope.$watch(
        () => {
          return broadcastDataService.InlinePreloader;
        },
        (nv) => {
          if (nv) {
            $scope.preLoaderText = nv.preLoaderText;
            $scope.preLoaderSize = nv.preLoaderSize;
            $scope.showPreLoader = nv.showPreLoader;
          }
        },
        true,
      );

      // $rootScope.$on('InlinePreloader', function(event, args) {
      //     $scope.preLoaderText = args.preLoaderText;
      //     $scope.preLoaderSize = args.preLoaderSize;
      //     $scope.showPreLoader = args.showPreLoader;
      // });

      // $rootScope.map = {};
      // $rootScope.map.isLoaded = false;
      $scope.mapModel = {};
      $scope.mapModel = broadcastDataService;
      mapService.loadAPI().then(() => {
        // $scope.$broadcast('IS_MAP_LOADED');

        // $rootScope.map.isLoaded = true;
        $scope.mapModel.map.isLoaded = true;
      });

      // Loading Map
      $timeout(() => {
        if (
          typeof $window.google === 'object' &&
          typeof $window.google.maps === 'object'
        ) {
          // $rootScope.map.isLoaded = true;
          $scope.mapModel.map.isLoaded = true;
        } else {
          mapService.loadAPI().then(() => {
            // $scope.$broadcast('IS_MAP_LOADED');
            // $rootScope.map.isLoaded = true;
            $scope.mapModel.map.isLoaded = true;
          });
        }
      }, 3000);

      $rootScope.openModalAdviserMapNearby = () => {
        const showAllocationButton = false;
        loadAllocationModalService.launchBrokerAllocationModal(
          showAllocationButton,
        );
      };

      $rootScope.appBodyElement = $window.document.querySelector('.app-body');
    });
