import { get } from 'lodash';

export const isSubstatus = ({ timelineStatusList, card }) => {
  const isSubstatusExisting = timelineStatusList.filter(
    (data) =>
      data.pipelineStatusId === card.pipelineStatusId ||
      data.pipelineStatusId === card.pipelineStatusID,
  );

  return get(isSubstatusExisting, '[0].subStatus.length', 0);
};
