import { toastError, toastSuccess } from 'Common/utilities/alert';

class LoanVariationSummaryCtrl {
  constructor($state, uiService, loanVariationsService, loanAppSharedData) {
    'ngInject';

    this.$state = $state;
    this.uiService = uiService;
    this.loanVariationsService = loanVariationsService;
    this.loanAppSharedData = loanAppSharedData;
  }

  $onInit() {
    this.loanVariationModel = {
      primaryTopUpOrVariationTypeId: null,
      secondaryTopUpOrVariationTypes: null,
      loanAppId: this.loanAppId,
      familyId: this.familyId,
      loanId: this.loanId,
    };

    this.multiSelectDropdownConfig = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Select None</span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: '<span class="margin-left-5">Please select...</span>',
    };

    this.getLoanVariationTypes().then(() => {
      this.getLoanVariationDetails();
      this.loanAppSharedData.getLoanVariationDetails = () =>
        this.getLoanVariationDetails();
    });
  }

  getLoanVariationTypes() {
    return this.loanVariationsService.getTypes().then((data = []) => {
      this.loanVariationTypes = data;
    });
  }

  getLoanVariationDetails() {
    this.loanAppSharedData.isLoadingVariationDetails = true;
    return this.loanVariationsService
      .getLoanVariationDetails(this.loanId)
      .then((data) => {
        if (!data || !data.primaryTopUpOrVariationTypeId) {
          this.loanAppSharedData.isLoanVariation = false;
          return;
        }
        this.loanAppSharedData.isLoanVariation = true;
        this.loanVariationDetails = data;
        this.loanVariationModel.primaryTopUpOrVariationTypeId = this.loanVariationDetails.primaryTopUpOrVariationTypeId;
        this.loanVariationDetails.secondaryTopUpOrVariationTypeIds.forEach(
          (id) => {
            const selectedType = this.loanVariationTypes.find(
              (type) => type.id === id,
            );
            selectedType.ticked = true;
          },
        );
      })
      .finally(() => {
        this.loanAppSharedData.isLoadingVariationDetails = false;
      });
  }

  getPrimaryUpdatePayload() {
    if (
      !this.loanVariationModel.loanId ||
      !this.loanVariationModel.primaryTopUpOrVariationTypeId
    ) {
      toastError('Oops missing required parameter please select value');
      return;
    }

    return {
      loanId: this.loanVariationModel.loanId,
      topUpOrVariationTypeId: this.loanVariationModel
        .primaryTopUpOrVariationTypeId,
    };
  }

  onSelectPrimaryVariation() {
    const payload = this.getPrimaryUpdatePayload();
    if (!payload) {
      return;
    }
    return this.loanVariationsService
      .savePrimaryLoanVariationType(payload)
      .then(({ data }) => {
        if (!data) {
          this.showToastError();
          return;
        }
        toastSuccess('Primary variation saved!');
        this.getLoanVariationDetails();
      })
      .catch(() => {
        toastError('Oops unable to save variation this time.');
      });
  }

  getSecondaryUpdatePayload() {
    if (!this.loanVariationModel.loanId) {
      toastError('Oops missing required parameter please select value');
      return;
    }

    return {
      loanId: this.loanVariationModel.loanId,
      topUpOrVariationTypeIds:
        this.loanVariationModel.secondaryTopUpOrVariationTypes
          .filter((type) => type.ticked)
          .map((type) => type.id) || [],
    };
  }

  onSelectNone() {
    this.loanVariationModel.secondaryTopUpOrVariationTypes.forEach(
      (type) => (type.ticked = false),
    );

    this.onMultiSelectSecondaryVariation();
  }

  onMultiSelectSecondaryVariation() {
    const payload = this.getSecondaryUpdatePayload();
    if (!payload) {
      return;
    }
    return this.loanVariationsService
      .saveSecondaryLoanVariationType(payload)
      .then(({ data }) => {
        if (!data) {
          this.showToastError();
          return;
        }
        toastSuccess('Secondary variation(s) saved!');
        this.getLoanVariationDetails();
      })
      .catch(() => {
        toastError('Oops unable to save selected variation this time.');
      });
  }

  showToastError() {
    toastError('Oops unable to save selected variation this time.');
  }
}

export default LoanVariationSummaryCtrl;
