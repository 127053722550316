import { triggerGA } from 'Common/utilities/googleAnalytics';
import { CONFIG_EMAIL } from 'Common/config/email';
export default class FatalErrorsCtrl {
  constructor($window, currentUserService) {
    'ngInject';

    this.$window = $window;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.fullURL = this.$window.location.href || '';
    const gaEvent = {
      event: 'generic-event',
      category: 'Alerted user of an error',
      action: this.fatalErrorsData.descriptions,
    };
    triggerGA(gaEvent);
    const link = this.currentUserService.isAU
      ? `<a href="mailto:${CONFIG_EMAIL.MYCRM_SUPPORT.email}"
    >Support team</a>`
      : `<a href="mailto:${CONFIG_EMAIL.TEAM_SERVICES_NZ.email}"
    >Adviser Services Team</a>`;
    this.contactLink = `Contact the ${link} and provide a screenshot of the information below:`;
  }
}
