import angular from 'angular';
import _ from 'lodash';
import { toastError } from 'Common/utilities/alert';
import { SORT_TYPE } from 'Common/constants/sort';
import {
  filterCapped,
  getLoanAmountOverride,
  structureMultipleFilter,
  preselectMultipleFilter,
  updateFilterToCSV,
  selectFilterAll,
  selectFilterItem,
  untickAllSelector,
  selectNone,
  untickAllValueSelection,
  defaultAllSelect,
} from 'Common/utilities/productFinder';
import { LOAN_APP_PRODUCT_FINDER } from 'Common/constants/performance';
import { performance } from 'Common/utilities/perfume';
import { performanceTimingData } from 'Common/utilities/dataLayer';
import { isSortableColumn } from 'Assets/js/controllers/loanProfiler/util/productFinderCtrl';
import { DEFAULT_COMPARISON_PERIOD } from 'Common/constants/productFinder';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanAppProductFinderCtrl', function LoanAppProductFinderCtrl(
      $scope,
      $uibModal,
      $uibModalInstance,
      loanProfilerService,
      NgTableParams,
      $stateParams,
      toaster,
      $timeout,
      $rootScope,
      facility,
      uiService,
      fundingSummary,
      configService,
      currentUserService,
      loanDetail,
      loanAppSharedData,
      comparisonPeriodList,
    ) {
      $scope.fundingSummary = fundingSummary;
      $scope.uiService = uiService;
      $scope.configService = configService;
      $scope.comparisonPeriodList = comparisonPeriodList;
      $scope.showComparisonPeriod =
        uiService.bidNotes && comparisonPeriodList.length > 0;
      const {
        requirementsAndObjectives,
        productFinderEditEnhancements,
        lendersByAccreditation,
      } = configService.feature;
      const accreditationBrokerId = lendersByAccreditation
        ? _.get(loanAppSharedData, 'LoanApplicationDetails.AdviserFamilyId', 0)
        : undefined;
      $scope.isReqAndObjShow = requirementsAndObjectives;
      $scope.isProductFinderEditEnhancementsEnabled = productFinderEditEnhancements;
      $scope.selectFilterAll = selectFilterAll;
      $scope.untickAllSelector = untickAllSelector({ $timeout });
      $scope.selectNone = selectNone;
      $scope.selectFilterItem = selectFilterItem;
      $scope.untickAllValueSelection = untickAllValueSelection({
        $scope,
        $timeout,
      });
      $scope.defaultAllSelect = defaultAllSelect({ $scope });
      $scope.currentCountryCode = currentUserService.countryCode;
      $scope.isSortableColumn = isSortableColumn;

      $scope.cancel = () => {
        $uibModalInstance.close();
      };

      $scope.settings = {
        isEditLVR: false,
      };
      // models
      if (
        facility &&
        facility.FavouriteProductData &&
        facility.FavouriteProductData.length
      ) {
        angular.extend($scope, {
          loanStructureList: [],
          loanAppId: $stateParams.loanAppId,
          brokerEventId: facility.BrokerEventId,
          LoanStructureID: facility.FavouriteProductData[0].LoanStructureID,
          filtersParamtersList: {},
          loanProviderLendersListGetAll: [],
          loanProviderLendersListSet: [],
          defaultSelection: {},
          lenderList: {},
          favouriteProductTempList: [],
        });
      }
      // methods
      angular.extend($scope, {
        getDefaultFilterSelection() {
          $scope.defaultSelection = {};
          loanProfilerService
            .getServiceabilityFilteringProductsGeneralSettings(
              $scope.brokerEventId,
            )
            .then((response) => {
              $scope.defaultSelection = response.data;
              if (!$scope.defaultSelection) {
                return;
              }
              $scope.defaultSelection.LVR = parseFloat(
                $scope.defaultSelection.LVR,
              );

              if ($scope.defaultSelection.loanAmount !== '') {
                $scope.defaultSelection.loanAmount = Number(
                  $scope.defaultSelection.loanAmount,
                );
              }
              if ($scope.defaultSelection.security !== '') {
                $scope.defaultSelection.security = Number(
                  $scope.defaultSelection.security,
                );
              }
              $scope.lenderSelection = response.data.Lender;
              $scope.loanProviderLendersListSet = [];
              if (
                typeof response.data.Lender !== 'undefined' &&
                response.data.Lender.length !== 0
              ) {
                angular.forEach($scope.defaultSelection.Lender, (item) => {
                  const data = _.find(
                    $scope.loanProviderLendersListGetAll,
                    (itemGet) => {
                      if (itemGet.value === item) {
                        itemGet.ticked = true;
                      }
                      return itemGet.value === item;
                    },
                  );
                  if (typeof data !== 'undefined') {
                    $scope.loanProviderLendersListSet.push(data);
                  }
                });
              }
            });
        },
        getFilterData() {
          $scope.filtersParamtersList = {};
          $scope.loanProviderLendersListGetAll = [];
          $scope.loanProviderLendersListSet = [];
          loanProfilerService
            .getServiceabilityFiltersParamters(accreditationBrokerId)
            .then((productResponse) => {
              const productResponseData = productResponse.data;
              $scope.filtersParamtersList = productResponseData;
              const lenderList =
                productResponseData && productResponseData.Lenders
                  ? productResponseData.Lenders
                  : [];
              lenderList.forEach((productItem) => {
                const length = 12;
                let trimmedString = '';
                if (typeof productItem.Name !== 'undefined') {
                  trimmedString =
                    productItem.Name.length > length
                      ? `${productItem.Name.slice(
                          0,
                          Math.max(0, length - 3),
                        )}...`
                      : productItem.Name;
                }
                if (productItem.Value !== 'FavouriteLenders') {
                  $scope.loanProviderLendersListGetAll.push({
                    name: productItem.Name,
                    shortName: trimmedString,
                    value: productItem.Value,
                    ticked: false,
                  });
                }
              });

              fundingSummary.LVR_Percentage_Override = parseFloat(
                fundingSummary.lvr,
              ).toFixed(2);
              fundingSummary.LoanAmountOverride = getLoanAmountOverride({
                $scope,
                fundingSummary,
              });

              $scope.setSelectedFilter();
              const preselectLender = loanDetail.LenderId || 'ALL';

              const obj = {
                GeneralSetting: {
                  Lender: $scope.isReqAndObjShow ? [preselectLender] : ['ALL'],
                  loanTerm: facility.LoanTerm,
                  loanAmount: fundingSummary.totalProposedLendingUserDefined,
                  security: fundingSummary.totalSecurityValue,
                  LVR: (fundingSummary.lvr ? '+' : '-') + fundingSummary.lvr,
                  splitLoan: false,
                },
              };
              loanProfilerService
                .updateServiceabilityFilteringProductsSettings(
                  $scope.brokerEventId,
                  obj,
                )
                .then(({ data }) => {
                  if (data) {
                    $scope.getDefaultFilterSelection();
                    $scope.getLoanStructure(true);
                  }
                })
                .catch(toastError);
            });
        },
        loadProductFinderList(loanStructure) {
          $scope.countPerPage = 10;
          loanStructure.IsSearchByLender = false;
          loanStructure.productFinderTableParams = new NgTableParams(
            {
              page: 1,
              count: $scope.countPerPage,
              sorting: {
                InitialRate: 'asc',
              },
              defaultSort: 'asc',
            },
            {
              total: 0,
              getData(params) {
                loanStructure.isProductLoaderOverlayDisplay = true;
                if (loanStructure.IsSearchByLender === false) {
                  const sortColumn = params.orderBy()[0].slice(1);
                  const sortType = _.values(params.sorting())[0].toUpperCase();
                  return loanProfilerService
                    .getServiceabilityProductFinder(
                      $scope.brokerEventId,
                      params.page(),
                      sortColumn,
                      sortType,
                      params.count(),
                      loanStructure.LoanStructureID,
                      undefined,
                      undefined,
                      accreditationBrokerId,
                    )
                    .then((response) => {
                      if (response.data && response.data[0]) {
                        params.total(response.data[0].TotalRecord);
                        loanStructure.CurrentPage = 1;
                      } else {
                        params.total(0);
                      }
                      loanStructure.isProductLoaderOverlayDisplay = false;
                      return response.data;
                    });
                } else {
                  const sortType = _.values(params.sorting())[0].toUpperCase();
                  return loanProfilerService
                    .lenderListWithProductCountGet(
                      $scope.brokerEventId,
                      loanStructure.LoanStructureID,
                      params.page(),
                      params.count(),
                      sortType,
                    )
                    .then((response) => {
                      if (response.data && response.data.Count) {
                        params.total(response.data.Count);
                        loanStructure.CurrentPage = 1;
                      } else {
                        params.total(0);
                      }
                      const lenderProducts = response.data.LenderProducts
                        ? response.data.LenderProducts
                        : [];
                      loanStructure.isProductLoaderOverlayDisplay = false;
                      return lenderProducts;
                    });
                }
              },
            },
          );
        },
        // Get Data When Expand Lender
        getGroupByLenderProduct(lender, loanStructure) {
          lender.$hideRows = !lender.$hideRows;
          if (lender.$hideRows === true) {
            const sortColumn = 'InitialRate';
            const sortType = SORT_TYPE.ASC;
            loanProfilerService
              .getServiceabilityProductFinder(
                $scope.brokerEventId,
                undefined,
                sortColumn,
                sortType,
                undefined,
                loanStructure.LoanStructureID,
                lender.LenderID,
                undefined,
                accreditationBrokerId,
              )
              .then((response) => {
                lender.productData = response.data;
                _.map(
                  loanStructure.productFinderTableParams.data,
                  (lenderItem) => {
                    if (
                      parseInt(lenderItem.LenderID, 10) !==
                      parseInt(lender.LenderID, 10)
                    ) {
                      lenderItem.$hideRows = false;
                    }
                  },
                );
              });
          }
        },
        getCustomerSelectionFilterData(loanStructure) {
          loanStructure.getFilterDataList = [];
          loanStructure.lenderSelection = [];
          loanProfilerService
            .getServiceabilityFiltersParamtersCustomerSelection(
              $scope.brokerEventId,
              $scope.LoanStructureID,
              true,
            )
            .then((response) => {
              if (!response.data && !response.data.AdvanceFilters) {
                return;
              }
              loanStructure.getFilterDataList = response.data;
              $scope.isProductFinderEditEnhancementsEnabled &&
                structureMultipleFilter({
                  loanStructure,
                  data: $scope.filtersParamtersList,
                });
              $scope.isProductFinderEditEnhancementsEnabled &&
                preselectMultipleFilter({ loanStructure });
              const { AdvanceFilters: advanceFilters } = response.data;
              if (currentUserService.isNZ) {
                loanStructure.getFilterDataList.AdvanceFilters = filterCapped(
                  advanceFilters,
                );
              }
              loanStructure.lenderSelection = response.data.Lender;
              const validateHasNoLoanAmount =
                typeof loanStructure.getFilterDataList.LoanAmount ===
                  'undefined' ||
                loanStructure.getFilterDataList.LoanAmount === 0;
              const validateHasLoanAmount =
                loanStructure.getFilterDataList.LoanAmount !== '';
              if (validateHasNoLoanAmount) {
                loanStructure.getFilterDataList.LoanAmount = '';
              } else if (validateHasLoanAmount) {
                loanStructure.getFilterDataList.LoanAmount = Number(
                  loanStructure.getFilterDataList.LoanAmount,
                );
              }
              $scope.isSerachdesabled = false;

              if (currentUserService.isAU) {
                let productType = 'Owner Occupied';
                if (parseInt($rootScope.loanAppSecurityProductType, 10) === 2) {
                  productType = 'Investment';
                }
                loanStructure.getFilterDataList.ProductType = productType;
              }

              const comparisonPeriodExisting = $scope.comparisonPeriodList.find(
                (period) =>
                  period.value ===
                  loanStructure.getFilterDataList.ComparisonPeriod,
              );
              if (comparisonPeriodExisting) {
                loanStructure.getFilterDataList.ComparisonPeriod = `${loanStructure.getFilterDataList.ComparisonPeriod}`;
              } else if (
                !comparisonPeriodExisting &&
                !!loanStructure.getFilterDataList.ComparisonPeriod
              ) {
                loanStructure.getFilterDataList.ComparisonPeriod = '';
              } else {
                loanStructure.getFilterDataList.ComparisonPeriod = DEFAULT_COMPARISON_PERIOD;
              }
            });
        },
        getSelectedProductList(loanStructure) {
          loanProfilerService
            .getFavouriteProduct($scope.brokerEventId, $scope.LoanStructureID)
            .then((response) => {
              loanStructure.selectedProduct = response.data;
              $scope.selectedProductCount = response.data
                ? response.data.length
                : 0;
              $scope.saveProductButton =
                response.data &&
                response.data.length >=
                  uiService.loanAppProductFinderFavoriteLimit;
            });
        },
        updateDefaultLendersListSet(LendersListSet) {
          const lendersListData = _.find(LendersListSet, (item) => {
            return item.value === 'ALL';
          });
          const loanProviderLendersListData = _.find(
            $scope.loanProviderLendersListSet,
            (item) => {
              return item.value === 'ALL';
            },
          );
          const validateHasNoLendersList =
            typeof lendersListData === 'undefined' &&
            typeof loanProviderLendersListData !== 'undefined';
          const validateHasLendersList =
            typeof lendersListData !== 'undefined' &&
            typeof loanProviderLendersListData !== 'undefined';
          const validateHasNoProviderLenderList =
            typeof lendersListData !== 'undefined' &&
            typeof loanProviderLendersListData === 'undefined';
          if (validateHasNoProviderLenderList) {
            angular.forEach(LendersListSet, (productItem) => {
              if (productItem.value !== 'ALL') {
                _.map(
                  $scope.loanProviderLendersListGetAll,
                  (productItemGet) => {
                    if (productItemGet.value === productItem.value) {
                      productItemGet.ticked = false;
                    }
                    return productItemGet;
                  },
                );
              }
            });
            $scope.loanProviderLendersListSet = [];
            $scope.loanProviderLendersListSet.push(lendersListData);
          } else if (validateHasNoLendersList || validateHasLendersList) {
            _.map($scope.loanProviderLendersListGetAll, (productItemGet) => {
              if (productItemGet.value === 'ALL') {
                productItemGet.ticked = false;
              }
              return productItemGet;
            });
            $scope.loanProviderLendersListSet = LendersListSet;
            const index = $scope.loanProviderLendersListSet.indexOf(
              lendersListData,
            );
            if (index > -1) {
              $scope.loanProviderLendersListSet.splice(index, 1);
            }
          } else {
            $scope.loanProviderLendersListSet = LendersListSet;
          }
          $scope.setDefaultFilterSelection();
          $scope.updateOverlaySearch();
        },
        updateOverlaySearch() {
          _.forEach($scope.loanStructureList, (loanStructure) => {
            loanStructure.isProductSearchOverlayDisplay = true;
          });
        },
        EditLVR() {
          $scope.settings.isEditLVR = true;
        },
        HoverLender() {
          $scope.isHoverLender = true;
        },
        toggleAdvanceFilter() {
          $scope.isAdvanceFilterShow = !$scope.isAdvanceFilterShow;
        },
        getLoanStructure(isOverlay) {
          $scope.loanStructureList = [
            {
              LoanStructureID: $scope.LoanStructureID,
              Name: '',
            },
          ];
          angular.forEach($scope.loanStructureList, (loanStructure) => {
            loanStructure.selectedProduct = [];
            loanStructure.multiParamPFList = {
              RateType: [],
              Intention: [],
              ProductType: [],
            };
            $scope.loadProductFinderList(loanStructure);
            $scope.getCustomerSelectionFilterData(loanStructure);
            $scope.getSelectedProductList(loanStructure);
            if (isOverlay) {
              $scope.updateOverlaySearch();
            }
          });
        },

        selectNoneLenderFilter() {
          if ($scope.isProductFinderEditEnhancementsEnabled) {
            $scope.defaultAllSelect();
            return;
          }
          $scope.setDefaultFilterSelection();
        },

        selectDefaultAllLender() {
          if ($scope.isProductFinderEditEnhancementsEnabled) {
            $scope.untickAllValueSelection();
            return;
          }
          _.map(
            $scope.loanProviderLendersListGetAll,
            (productSelectAllLenderItemGet) => {
              if (productSelectAllLenderItemGet.value === 'ALL') {
                $scope.loanProviderLendersListSet = [];
                $scope.loanProviderLendersListSet.push(
                  productSelectAllLenderItemGet,
                );
              } else {
                productSelectAllLenderItemGet.ticked = false;
              }
              return productSelectAllLenderItemGet;
            },
          );
          $scope.setDefaultFilterSelection();
        },
        setDefaultFilterSelection(overrideLVR, loanstructure) {
          const lenders = _.map($scope.loanProviderLendersListSet, 'value');
          $scope.defaultSelection.Lender = [];
          $scope.defaultSelection.Lender = lenders;

          const fundingSummaryCopy = { ...$scope.fundingSummary };
          $scope.defaultSelection.LVR =
            fundingSummaryCopy.LVR_Percentage_Override;
          $scope.defaultSelection.loanAmount =
            fundingSummaryCopy.LoanAmountOverride;

          // bind
          $scope.fundingSummary.lvr =
            fundingSummaryCopy.LVR_Percentage_Override;
          $scope.fundingSummary.totalProposedLendingUserDefined =
            fundingSummaryCopy.LoanAmountOverride;

          const postData = { ...$scope.defaultSelection };
          postData.LVR =
            (parseFloat(postData.LVR) > 0 ? '+' : '') + postData.LVR;

          loanProfilerService
            .updateServiceabilityFilteringProductsSettings(
              postData.brokerEventID,
              { GeneralSetting: postData },
            )
            .then(({ data }) => {
              if (!data) {
                toastError();
                return;
              }
              loanstructure &&
                $scope.setCustomerSelectionSetting(loanstructure, false);
              if (overrideLVR) {
                $scope.settings.isEditLVR = false;
              }
              $scope.updateOverlaySearch();
            })
            .catch(toastError);
        },
        setCustomerSelectionSetting(loanStructure, isLoadTable) {
          $scope.isProductFinderEditEnhancementsEnabled &&
            updateFilterToCSV({ loanStructure });
          const brokerEventID = $scope.brokerEventId
            ? $scope.brokerEventId.toString()
            : '';
          const postdata = {
            CustomSelection: {
              DepositAmount: loanStructure.getFilterDataList.DepositAmount,
              MaxRate: loanStructure.getFilterDataList.MaxRate,
              Financials: loanStructure.getFilterDataList.Financials,
              ProductType: loanStructure.getFilterDataList.ProductType,
              RateType: loanStructure.getFilterDataList.RateType,
              AdvanceFilters: loanStructure.getFilterDataList.AdvanceFilters,
              LoanAmount: facility.TotalLoanAmount,
              Repayment: loanStructure.getFilterDataList.Repayment,
              loanStructureID: $scope.LoanStructureID,
              ComparisonPeriod: $scope.showComparisonPeriod
                ? Number(loanStructure.getFilterDataList.ComparisonPeriod)
                : undefined,
            },
          };
          postdata.CustomSelection.Repayment = facility.InterestOnly
            ? 'IO'
            : 'P&I';
          postdata.CustomSelection.Intention = uiService.isCountry(
            'New Zealand',
          )
            ? 'ALL'
            : postdata.CustomSelection.Intention;

          if ($scope.isProductFinderEditEnhancementsEnabled) {
            postdata.CustomSelection = {
              ...postdata.CustomSelection,
              Intention: loanStructure.getFilterDataList.Intention,
            };
          }

          loanProfilerService
            .updateServiceabilityFilteringProductsSettings(
              brokerEventID,
              postdata,
            )
            .then(({ data }) => {
              if (!data) {
                return;
              }

              isLoadTable && $scope.loadProductFinderList(loanStructure);
            })
            .catch(toastError);
        },
        updateFilterData(loanStructure, isLoadTable) {
          performance.start(LOAN_APP_PRODUCT_FINDER);
          $scope.isSaveContactEnable = false;
          loanStructure.isProductSearchOverlayDisplay = false;
          if (isLoadTable === true) {
            loanStructure.isProductLoaderOverlayDisplay = true;
          }
          const lenders = _.map($scope.loanProviderLendersListSet, 'value');
          $scope.defaultSelection.Lender = _.clone(lenders);
          const postData = _.clone($scope.defaultSelection);
          postData.LVR =
            (parseFloat(postData.LVR) > 0 ? '+' : '') + postData.LVR;

          loanProfilerService
            .updateServiceabilityFilteringProductsSettings(
              postData.brokerEventID,
              { GeneralSetting: postData },
            )
            .then(() => {
              $scope.setCustomerSelectionSetting(loanStructure, isLoadTable);
            })
            .catch(toastError);
          const duration = performance.endPaint(LOAN_APP_PRODUCT_FINDER);
          performanceTimingData({
            action: LOAN_APP_PRODUCT_FINDER,
            value: duration,
          });
        },
        selectLoanStructureProduct(loanStructure, product, $event) {
          if ($event) {
            $event.stopPropagation();
          }
          product.isAddedForComparison = !product.isAddedForComparison;
          if (
            ($scope.selectedProductCount ===
              uiService.loanAppProductFinderFavoriteLimit &&
              product.isAddedForComparison) ||
            $scope.selectedProductCount >
              uiService.loanAppProductFinderFavoriteLimit
          ) {
            toaster.pop(
              'warning',
              'Product Selection',
              `You can select maximum ${uiService.loanAppProductFinderFavoriteLimit} products`,
            );
            product.isAddedForComparison = !product.isAddedForComparison;
            return;
          }

          if (product.isAddedForComparison) {
            $scope.selectedProductCount += 1;
          } else {
            $scope.selectedProductCount -= 1;
          }

          $scope.productInfoShow =
            $scope.selectedProductCount &&
            $scope.selectedProductCount !==
              uiService.loanAppProductFinderFavoriteLimit;
          $scope.saveProductButton =
            $scope.selectedProductCount &&
            $scope.selectedProductCount ===
              uiService.loanAppProductFinderFavoriteLimit;

          const postData = {
            brokerEventID: $scope.brokerEventId,
            ProductID: product.ProductID,
            LenderID: product.LenderID,
            loanStructureID: loanStructure.LoanStructureID,
            IsFavourite: product.isAddedForComparison,
            IsSeletedInFavourite: false,
          };

          loanProfilerService
            .setFavouriteProduct(postData)
            .then((response) => {
              if (parseInt(response.data, 10) === 1) {
                const tempProduct = _.find(
                  $scope.favouriteProductTempList,
                  (item) => {
                    return (
                      parseInt(item.ProductID, 10) ===
                      parseInt(postData.ProductID, 10)
                    );
                  },
                );
                if (typeof tempProduct === 'undefined') {
                  $scope.favouriteProductTempList.push(postData);
                } else {
                  _.map($scope.favouriteProductTempList, (item) => {
                    if (
                      parseInt(item.ProductID, 10) ===
                      parseInt(postData.ProductID, 10)
                    ) {
                      item.IsFavourite = postData.IsFavourite;
                    }
                  });
                }
              } else {
                toastError();
                product.isAddedForComparison = !product.isAddedForComparison;
                if (product.isAddedForComparison) {
                  $scope.selectedProductCount += 1;
                } else {
                  $scope.selectedProductCount -= 1;
                }
              }
            })
            .catch(toastError);
        },
        cancelProduct() {
          angular.forEach($scope.favouriteProductTempList, (item) => {
            item.IsFavourite = !item.IsFavourite;
            loanProfilerService
              .setFavouriteProduct(item)
              .then((response) => {
                if (parseInt(response.data, 10) !== 1) {
                  toastError();
                }
              })
              .catch(toastError);
          });
          $uibModalInstance.dismiss('cancel');
        },
        changeSorting(loanStructure, column) {
          if (typeof column.sortType === 'undefined') {
            column.sortType = 'asc';
          } else if (column.sortType === 'asc') {
            column.sortType = 'desc';
          } else {
            column.sortType = 'asc';
          }
          loanStructure.productFinderTableParams.sorting(
            column.sortable(),
            column.sortType,
          );
          loanStructure.productFinderTableParams.reload();
        },

        openModalForProductDetail(loanStructure, product) {
          $uibModal.open({
            templateUrl:
              '/assets/views/loanApplication/clientAssess/loanDetails.v2/modal/productFinderDetailModal.html',
            controller: 'ForQuickProductFinderDetailModalCtrl as vm',
            windowClass: 'product-detail-modal-window-class',
            size: 'lg',
            scope: $scope,
            resolve: {
              product,
              loanStructure,
              selectLoanProduct: () => {
                return $scope.selectLoanStructureProduct;
              },
              selectedProductCount: $scope.selectedProductCount,
            },
          });
        },

        setSelectedFilter() {
          if (!$scope.isReqAndObjShow) {
            return;
          }

          $scope.loanProviderLendersListGetAll.map((lender) => {
            lender.ticked = lender.value === loanDetail.LenderId;
            return lender;
          });
        },

        init() {
          $scope.productInfoShow = false;
          $scope.getFilterData();
        },
      });

      // caller
      $scope.init();
    });
