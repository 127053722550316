import template from './commissionDashboard.html';
import controller from './commissionDashboardCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isMobileDevice: '<',
  },
};
