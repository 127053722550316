import template from './onboardingDetails.html';
import OnboardingDetailsCtrl from './onboardingDetailsCtrl';

export default {
  template,
  bindings: {
    activeTab: '<',
    tabs: '<',
  },
  transclude: true,
  controller: OnboardingDetailsCtrl,
  controllerAs: 'vm',
};
