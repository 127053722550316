import { PRODUCT_TYPE, CLIENT_TYPE } from 'Common/constants/iFinance';
import { ASSET_TYPE } from 'Common/constants/securityValues';
import { COUNTRY_SHORTNAME } from 'Common/constants/countryType';
import moment from 'moment';
import dotProp from 'dot-prop-immutable';
import { parseToInt10 } from 'Common/utilities/parse';

export function productPdfBuilderForUI(data) {
  return {
    name: data.Name,
    contentType: data.ContentType,
    documentContent: data.DocumentContent,
    documentExists: data.DocumentExists,
    isSvg: data.IsSvg,
  };
}

export const selectedProductsMapperForUI = (data) => {
  return {
    ...data,
    SelectedProduct:
      (data && data.SelectedProduct.map(selectedProductMapperForUI)) || [],
  };
};

export const selectedProductMapperForUI = (data) => {
  const { InitialPeriod, InterestRateTerm, InitialRate, OngoingRate } = data;
  return {
    ...data,
    isVariable:
      !parseToInt10(InitialPeriod) &&
      !parseToInt10(InterestRateTerm) &&
      InitialRate === OngoingRate,
  };
};
export function putProductFinderSettingsBuilderForMyCRM(settings) {
  const { generalSetting, customSelection } = settings;
  return {
    GeneralSetting: {
      Lender: generalSetting.lender,
      loanTerm: generalSetting.loanTerm,
      loanAmount: generalSetting.loanAmount,
      security: generalSetting.security,
      LVR: generalSetting.lvr,
      splitLoan: generalSetting.splitLoan,
    },
    CustomSelection: {
      LoanAmount: customSelection.loanAmount,
      DepositAmount: customSelection.depositAmount,
      MaxRate: customSelection.maxRate,
      RateType: customSelection.rateType,
      Financials: customSelection.financials,
      ProductType: customSelection.productType,
      Intention: customSelection.intention,
      Repayment: customSelection.repayment,
      AdvanceFilters: customSelection.advanceFilters,
      loanStructureID: customSelection.loanStructureId,
    },
  };
}
export function assessmentDetailCalculationsBuilderForUI(data) {
  return {
    familyId: data.FamilyId,
    dateOfCalculation: data.DateOfCalculation,
    typeOfCalculation: data.TypeOfCalculation,
  };
}
export function incomeTypeOptionBuilderForMyCRM(data) {
  return {
    Name: data.Name,
    Value: data.Value,
  };
}
export function clientOptionBuilderForMyCRM(data) {
  return {
    ClientNo: data.ClientNo,
    ClientName: data.ClientName,
    ClientID: data.ClientID,
  };
}

export const calculationBuilderForMyUI = (data) => {
  return {
    Country: COUNTRY_SHORTNAME.NEW_ZEALAND,
    ExpensesValue: data.ExpensesValue,
    IncomeValue: data.IncomeValue,
    IsDocumentExists: !!data.IsDocumentExists,
    IsSelected: data.IsSelected,
    LenderID: data.LenderId,
    LenderName: data.LenderName,
    MaxLVR: data.MaxLVR,
    MaxLoanAmount: data.MaxLoanAmount,
    ServicingRate: data.ServicingRate,
    UMIValue: data.UMIValue,
    ShadedUMIValue: data.ShadedUMIValue,
    isMaxLoanInRange: data.isMaxLoanInRange,
  };
};

export function favouriteProductBuilderForUI(data) {
  return {
    country: data.Country,
    lenderID: data.LenderID,
    lenderName: data.LenderName,
    productID: data.ProductID,
    productName: data.ProductName,
    assetFinanceProduct: data.AssetFinanceProduct,
  };
}

export function assetFinanceFavouriteProductBuilderForUI(data) {
  const getAssetFinanceAttribute = (property) => {
    return (
      data.AssetFinanceProduct &&
      data.AssetFinanceProduct.attributes &&
      data.AssetFinanceProduct.attributes[property]
    );
  };

  return {
    productId: data.ProductID,
    lenderName: getAssetFinanceAttribute('lender'),
    assetVariant: getAssetFinanceAttribute('asset_variant'),
    supplierType: getAssetFinanceAttribute('supplier'),
    establishmentFee: getAssetFinanceAttribute('establishment_fee'),
    accountKeepingFee: getAssetFinanceAttribute('account_keeping_fee'),
    amountFinanced: getAssetFinanceAttribute('amount_finance'),
    repaymentAmount: getAssetFinanceAttribute('repayment'),
    repaymentFrequency: getAssetFinanceAttribute('term'),
    interestRate: getAssetFinanceAttribute('interest_rate'),
    isRecommended: data.IsRecommended,
    assetFinanceProduct: data.AssetFinanceProduct,
  };
}

export function productsGeneralSettingsBuilderForUI(data) {
  return {
    brokerEventID: data.brokerEventID,
    lender: data.Lender,
    loanTerm: data.loanTerm,
    clientType: parseInt(data.ClientType, 10),
    loanAmount: data.loanAmount,
    lVR: data.LVR,
    splitLoan: data.splitLoan,
    isAssetFinance: data.IsAssetFinance,
    assetType: parseInt(data.AssetType, 10),
    financeType: parseInt(data.FinanceType, 10),
    purchasePrice: data.PurchasePrice,
    commissionPercent: data.CommissionPercent,
    commissionAmount: data.CommissionAmount,
    residualPercent: data.ResidualPercent,
    residualAmount: data.ResidualAmount,
    documentFeePercent: data.DocumentFeePercent,
    documentFeeAmount: data.DocumentFeeAmount,
    propertyOwner: data.PropertyOwner,
    redBookCode: data.RedBookCode,
    description: data.Description,
    vehicleClassType: parseInt(data.VehicleClassType, 10),
    vehicleMake: data.VehicleMake,
    vehicleFamily: data.VehicleFamily,
    vehicleYear: parseInt(data.VehicleYear, 10),
    vehicleMonth: data.VehicleMonth,
    vehicleModel: data.VehicleModel,
    assetTypeId: data.AssetTypeId,
    assetVariantId: data.AssetVariantId,
    entityTypeId: data.EntityTypeId,
    frequency: data.Frequency,
    industryExperience: data.IndustryExperience,
    originationFee: data.OriginationFee,
    quotePurposeId: data.QuotePurposeId,
    quoteTypeId: data.QuoteTypeId,
    timeGSTRegistered: data.TimeGSTRegistered,
    tradeInAmount: data.TradeInAmount,
    tradeInPayout: data.TradeInPayout,
    tradingYears: data.TradingYears,
    rateDiscount: data.RateDiscount,
    timeAtAddress: data.TimeAtAddress,
    vedaScore: data.VedaScore,
    timeInEmployment: data.TimeInEmployment,
    residentialStatusId: data.residentialStatusId === CLIENT_TYPE.PERSONAL,
    term: data.loanTerm,
  };
}

export function quoteFilterBuilderForMyCRM(data) {
  return {
    [data.quotePurposeId === PRODUCT_TYPE.PERSONAL
      ? 'LoanAmount'
      : 'PurchasePrice']: data.loanAmount,
    ResidualPercentage: data.residualPercent,
    RedBookCode:
      data.assetTypeId === ASSET_TYPE.MOTOR_VEHICLE ? data.redBookCode : '',
    VehicleClassType: data.vehicleClassType,
    VehicleYear: data.vehicleYear,
    VehicleMonth: data.vehicleMonth,
    VehicleMake: data.vehicleMake,
    VehicleFamily: data.vehicleFamily,
    AssetTypeId: data.assetTypeId,
    AssetVariantId: data.assetVariantId,
    QuotePurposeId: data.quotePurposeId,
    QuoteTypeId: data.quoteTypeId,
    EntityTypeId: data.entityTypeId,
    TradingYears: data.tradingYears,
    TimeGSTRegistered: data.timeGSTRegistered,
    IndustryExperience: data.industryExperience,
    Deposit: 0,
    TradeInAmount: 0,
    tradeInPayOut: 0,
    Frequency: data.frequency,
    OriginationFee: data.originationFee,
    TimeAtAddress: data.timeAtAddress,
    TimeInEmployment: data.timeInEmployment,
    VedaScore: data.vedaScore,
    RateDiscount: data.rateDiscount,
    Term: data.term,
    ResidentialStatusId: data.residentialStatusId
      ? CLIENT_TYPE.PERSONAL
      : CLIENT_TYPE.COMMERCIAL,
    Description: data.description,
    RbId: data.assetTypeId === ASSET_TYPE.MOTOR_VEHICLE ? data.redBookCode : '',
  };
}

export function productFilterBuilderForMyCRM(data) {
  return {
    GeneralSetting: {
      IsAssetFinance: true,
      LoanAmount: data.loanAmount,
      ResidualPercent: data.residualPercent,
      RedBookCode: data.redBookCode,
      VehicleClassType: data.vehicleClassType,
      VehicleYear: data.vehicleYear,
      VehicleMonth: data.vehicleMonth,
      VehicleMake: data.vehicleMake,
      VehicleFamily: data.vehicleFamily,
      AssetTypeId: data.assetTypeId,
      AssetVariantId: data.assetVariantId,
      QuotePurposeId: data.quotePurposeId,
      QuoteTypeId: data.quoteTypeId,
      EntityTypeId: data.entityTypeId,
      TradingYears: data.tradingYears,
      TimeGSTRegistered: data.timeGSTRegistered,
      IndustryExperience: data.industryExperience,
      Deposit: 0,
      TradeInAmount: 0,
      TradeInPayOut: 0,
      Frequency: data.frequency,
      OriginationFee: data.originationFee,
      TimeAtAddress: data.timeAtAddress,
      TimeInEmployment: data.timeInEmployment,
      VedaScore: data.vedaScore,
      RateDiscount: data.rateDiscount,
      Lender: ['All'],
      LoanTerm: data.term,
      ResidentialStatusId: data.residentialStatusId
        ? CLIENT_TYPE.PERSONAL
        : CLIENT_TYPE.COMMERCIAL,
      Description: data.description,
    },
  };
}

export function saveProductComparisonBuilderForUI(data) {
  const createdBy = `${data.CreatedByFirstName} ${data.CreatedByLastName}`.trim();
  return {
    createdBy,
    dateCreated: `${moment(new Date(data.DateCreated)).format('DD MMM YYYY')}`,
    isOpportunity: data.IsOpportunity,
    title: data.Title,
    brokerEventId: data.BrokerEventID,
    loanStructureId: data.Lenders[0] && data.Lenders[0].LoanStructureID,
    selectedProduct: dotProp
      .get(data, 'Lenders.0.SelectedProduct', [])
      .map((product) => ({
        country: product.Country,
        lenderId: product.LenderID,
        lenderName: product.LenderName,
        productName: product.FormattedProductName,
        productAndRepaymentType: product.ProductAndRepaymentTypes,
      })),
    loanAmount: data.GeneralSettings.loanAmount,
    lvr: data.GeneralSettings.LVR,
  };
}
