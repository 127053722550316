import { toastError } from 'Common/utilities/alert';
import dotProp from 'dot-prop-immutable';

export const isNotRegisteredNZBNChar = ({ data, myCRMconfigs }, hasType) => {
  const { Messages = [], Succeeded } = data;
  const message = Messages.join() || '';
  const isNotSucceeded = typeof Succeeded !== 'undefined' && !Succeeded;
  const isFeatureBlueStoneOn = dotProp.get(
    myCRMconfigs,
    'feature.bluestoneOnlineApplication',
    false,
  );
  const isNZ = dotProp.get(myCRMconfigs, 'isNZ', false);
  const employmentOrgPropExist = {
    employment: dotProp.get(data, 'Employment', false),
    organisation: typeof data === 'string',
  };

  const isNotValid =
    !employmentOrgPropExist[hasType] &&
    isNotSucceeded &&
    isNZ &&
    isFeatureBlueStoneOn;
  isNotSucceeded && toastError(message);
  return isNotValid;
};
