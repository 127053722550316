export default class CommissionModalService {
  constructor($uibModal) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  launchCommissionEnquiryModal(advisers, lenders) {
    this.$uibModal.open({
      animation: true,
      template: `
        <commission-enquiry-form-modal
          modal-instance="vm.modalInstance"
          advisers="vm.props.advisers"
          lenders="vm.props.lenders"
        >
        </commission-enquiry-form-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      size: 'md',
      resolve: {
        props: () => ({
          advisers,
          lenders,
        }),
      },
    });
  }
}
