/* eslint-disable angular/window-service */
import { showBusinessPlanningDashboard } from 'Common/utilities/showFeature';
import { DEFAULT_DASHBOARD_TAB } from 'Common/constants/businessPlanningDashboard';

const configData = {};

export function setConfigData(data) {
  Object.assign(configData, data);
}

export function getConfigData() {
  return { ...configData };
}

export function getHashConfig() {
  // webpack define global variable
  return process.env.COMMIT_HASH || '';
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getConfigFromEnv() {
  return {
    resource: process.env.DEFAULT_API_RESOURCE,
    login:
      window.technology === 'angular'
        ? process.env.LOGIN || `${window.location.origin}/login/login.html`
        : `${window.location.origin}/login`,
    oktaLogin:
      window.technology === 'angular'
        ? process.env.IDENTITY_LOGIN_PATH ||
          `${window.location.origin}/app/login`
        : `${window.location.origin}/login`,
    authUrl:
      window.technology === 'angular'
        ? process.env.IDENTITY_AUTH_REACT_URL || window.location.origin
        : window.location.origin,
    googleApiKey: process.env.GOOGLE_API_KEY || '',
    sentry_dsn: process.env.SENTRY_DSN || '',
    sentry_env: process.env.SENTRY_ENV || 'test',
    gaAccount: process.env.GA_ACCOUNT || '',
    rolloutKey: process.env.ROLLOUT_KEY || '',
    oktaIssuer: process.env.IDENTITY_ISSUER || '',
    oktaClientId: process.env.IDENTITY_CLIENT_ID || '',
    oktaEarlyRenew: process.env.OKTA_EARLY_RENEW || 60 * 3,
    debug: process.env.DEBUG || false,
    disableOkta: process.env.DISABLE_OKTA || false, // for local development only
    nodifiUrl: process.env.NODIFI_URL,
    nodifiApiUrl: process.env.NODIFI_API_URL,
    nodifiNonLMUrl:
      process.env.NODIFI_NONLM_URL ||
      'https://nodifi.lightning-platform.com.au/auth/login',
    useCloudWatch: process.env.USE_CLOUD_WATCH || false,
    cloudWatchLogsAccessKeyId: process.env.CLOUD_WATCH_LOGS_ACCESS_KEY_ID || '',
    cloudWatchLogsSecretAccessKey:
      process.env.CLOUD_WATCH_LOGS_SECRET_ACCESS_KEY || '',
    cloudWatchLogsRegion: process.env.CLOUD_WATCH_LOGS_REGION || '',
    mycrmServicesBaseUrl:
      process.env.SERVICE_API_RESOURCE || window.location.origin,
    freshChatToken: process.env.FRESH_CHAT_TOKEN || '',
    helpCenterLink: process.env.HELP_CENTER || '',
    mycrmSupportNumber: process.env.MYCRM_SUPPORT_NUMBER || '',
  };
}

export function routeDefaultPage({ $state, feature, currentUserService }) {
  const { myCRMNewHomeLandingPage, businessPlanningDashboard } = feature;
  if (myCRMNewHomeLandingPage) {
    return $state.go('app.home');
  }
  const routeBusinessPlanningDashboard = showBusinessPlanningDashboard(
    businessPlanningDashboard,
    currentUserService,
  );
  if (routeBusinessPlanningDashboard) {
    return $state.go('app.dashboardV2', { activeTab: DEFAULT_DASHBOARD_TAB });
  }
  return $state.go('app.dashboard');
}
