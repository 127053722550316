import moment from 'moment';
import { parseToInt10 } from 'Common/utilities/parse';
import { CAPS_DD_MMMM_YYYY } from 'Common/constants/dateConfigs';

export const getTimeOnDate = (date) => {
  if (!date) {
    return '';
  }
  return moment.parseZone(date).format('h:mm a');
};

export const formatDateDocument = (date) => {
  if (!date) {
    return '';
  }
  return moment.parseZone(date).format(CAPS_DD_MMMM_YYYY);
};

export const collectDocumentId = (accum, current) => {
  if (current.DocFromESign_GroupedDocument) {
    return [...accum];
  }
  return [...accum, parseToInt10(current.DocumentId)];
};
