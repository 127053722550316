import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('fluidWidthByFontSize', function fluidWidth($timeout) {
      return {
        restrict: 'A',
        require: '?ngModel',
        link: (scope, elem, attrs, ngModelCtrl) => {
          const cursorWidth = 1;
          const fontSize = parseInt(attrs.fluidWidthByFontSize, 10) - 4;
          const adjustWidth = () => {
            const width = elem[0].value.length * fontSize + cursorWidth;
            elem[0].style.width = `${width}px`;
          };
          ngModelCtrl.$viewChangeListeners.push(adjustWidth);

          const adjustWidthTimeout = $timeout(adjustWidth, 1000);
          scope.$on('$destroy', () => {
            $timeout.cancel(adjustWidthTimeout);
          });
        },
      };
    });
