import template from './commissionStats.html';
import './style.scss';
import controller from './commissionStatsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    widgetTitle: '@',
    textColor: '<',
    percentage: '<',
    amount: '<',
    isPercentageUp: '<',
    isLoading: '<',
    frequency: '<',
  },
};
