import { isValidObjectValue } from 'Common/utilities/objectValidation';
import { addressLookup } from 'Common/mappers/address';

const NewAddressModel = () => {
  return {
    EstimatedValue: 0,
    Address: { AddressID: 0 },
    PropertyName: '',
    LocationDesc: '',
    Ownership: [],
    OwnershipTypeId: 0,
    ContactLivingIn: [],
    FloorNumber: '',
    DischargeDate: new Date(),
    PurchaseDate: new Date(),
    StartDate: null,
    EndDate: null,
    FullAddress: { formatted_address: '' },
    isUsePostalAddress: false,
    isNew: true,
  };
};
class AddressHistoryService {
  constructor(
    generalService,
    dashboardService,
    contactService,
    loanScenarioService,
    modalRenderService,
    configService,
  ) {
    'ngInject';

    this.generalService = generalService;
    this.dashboardService = dashboardService;
    this.contactService = contactService;
    this.loanScenarioService = loanScenarioService;
    this.modalRenderService = modalRenderService;
    this.configService = configService;
  }

  launchAddressModal({ familyId, loanAppId, detailsObj }) {
    if (!familyId) {
      return;
    }
    const details = detailsObj || new NewAddressModel();
    details.Address = {
      ...addressLookup(details),
      displayDetails: !!detailsObj,
    };
    details.FullAddress = { ...details.Address };
    details.isUsePostalAddress = details.isPostalAddress;

    details.StartDate = details.StartDate
      ? { ...details.StartDate, Day: 1 }
      : null;
    details.EndDate = details.EndDate ? { ...details.EndDate, Day: 1 } : null;
    const props = {
      addressId: details.AddressID || 0,
      address: details.Address,
      clientDetails: details,
    };
    return this.modalRenderService
      .launchAddressLookupModal(familyId, props)
      .result.then(({ clientDetails = {}, address = {} }) => {
        clientDetails.Address = address;
        clientDetails.FullAddress = address;
        if (clientDetails.AddressID) {
          clientDetails.FullAddress.AddressValuationExtendedDetailsId =
            clientDetails.AddressValuationExtendedDetailsId;
        }
        if (loanAppId) {
          return this.setLoanAppAddress(clientDetails, familyId);
        }
        return this.setContactAddress(clientDetails, familyId);
      });
  }

  populateAddressHistorySuggestions(addressHistory) {
    if (!addressHistory || !addressHistory.Address) {
      return;
    }

    this.generalService
      .placeSearch(addressHistory.Address.formatted_address)
      .then((response) => {
        addressHistory.addressSuggestions = response.data;
        addressHistory.showSuggestionModal = true;
      });
  }

  setAddressDetails(propertySet, familyId) {
    const { FullAddress, Address } = propertySet;
    let addressDetailsSetObject = FullAddress;
    const isCustomAddress = Address && Address.isCustomAddress;

    if (isCustomAddress) {
      const { AddressID, ...customAddressDetails } = Address;
      addressDetailsSetObject = { ...FullAddress, ...customAddressDetails };
    }

    return this.dashboardService
      .addressDetailsSet(addressDetailsSetObject)
      .then(({ data }) => {
        const AddressID = data || 0;
        const {
          CategoryId,
          FloorTypeId,
          TitleTypeId,
          TenureTypeId,
          OwnershipTypeId,
          TypeId,
          StartDate,
          EndDate,
          ContactLivingIn,
          AddressValuationExtendedDetailsId,
          RentalAmount,
          FrequencyTypeId,
          NumberOfYears,
          NumberOfMonths,
          isUsePostalAddress: IsPostalAddress,
        } = propertySet;

        return {
          ...propertySet.Address,
          FamilyId: familyId,
          IsMailing: false,
          CategoryId,
          FloorTypeId,
          TitleTypeId,
          TenureTypeId,
          OwnershipTypeId,
          TypeId,
          StartDate,
          EndDate,
          ContactLivingIn,
          AddressValuationExtendedDetailsId:
            AddressValuationExtendedDetailsId || 0,
          RentalAmount,
          FrequencyTypeId,
          NumberOfYears: NumberOfYears && +NumberOfYears,
          NumberOfMonths: NumberOfMonths && +NumberOfMonths,
          AddressID,
          IsPostalAddress,
        };
      });
  }

  setContactAddress(propertySet, familyId) {
    return this.setAddressDetails(propertySet, familyId).then(
      (addressObj = {}) => {
        const startDate = isValidObjectValue(() => addressObj.StartDate.Month)
          ? addressObj.StartDate
          : null;
        const endDate = isValidObjectValue(() => addressObj.EndDate.Month)
          ? addressObj.EndDate
          : null;
        const newAddressObj = {
          ...addressObj,
          StartDate: startDate,
          EndDate: endDate,
        };

        return this.contactService.contactAddressSet([newAddressObj]);
      },
    );
  }

  setLoanAppAddress(propertySet, familyId) {
    return this.setAddressDetails(propertySet, familyId).then(
      (addressObj = {}) => {
        return this.loanScenarioService.setAddress(addressObj);
      },
    );
  }
}

export default AddressHistoryService;
