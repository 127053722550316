import template from './mycrmAllocation.html';
import controller from './mycrmAllocationCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    selectedAdviser: '<',
    selectedAssistant: '<',
    getBrokersListOnFamilyId: '<',
    simpleSelection: '<',
    loanId: '<',
    onAdviserSelect: '&',
    onAssistantSelect: '&',
    onSearchAdviserNearby: '&?',
    resetAssistantOnAdviserChange: '&',
    isUseUibDropdown: '<?',
  },
};
