import { get, cloneDeep } from 'lodash';
import { setTableSortOrder } from 'Common/utilities/sort';
import { COMMISSION_TAB_URL } from 'Common/constants/commission';
import {
  copyLenderList,
  copyAdviserList,
  copyCommissionTypeList,
  copyPayeeList,
} from 'Common/utilities/commission';

export default class CommissionSearchCtrl {
  constructor(
    $state,
    $timeout,
    commissionHelperService,
    commissionService,
    modalRenderService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.commissionHelperService = commissionHelperService;
    this.commissionService = commissionService;
    this.modalRenderService = modalRenderService;
    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
    if (get(this.$state, 'params.activeTab') !== COMMISSION_TAB_URL.SEARCH) {
      return;
    }
    this.isPageLoaded = true;
    this.isExportingReceived = false;
  }

  $onChanges(changes) {
    if (!changes) {
      return;
    }
    const { brokers, lenders, types } = changes;
    if (brokers && brokers.currentValue) {
      this.adviserList = copyAdviserList(this.brokers, this.default);
      this.payeeList = copyPayeeList([...this.brokers] || []);
      this.initSearchFilterSettings();
      this.initSearchCommissions();
    }
    lenders && (this.lenderlist = copyLenderList(this.lenders));
    types && (this.commissionTypeList = copyCommissionTypeList(this.types));
  }

  $onDestroy() {
    this.loadSearchTimeout && this.$timeout.cancel(this.loadSearchTimeout);
    this.loadingTimeout && this.$timeout.cancel(this.loadingTimeout);
  }

  processParentNotification(eventHandler) {
    if (eventHandler === 'initSearchCommissions' && !this.isPageLoaded) {
      this.initSearchCommissions();
      this.isPageLoaded = true;
    }
  }

  initSearchCommissions() {
    this.searchCommissionsHeader = this.commissionHelperService.getSearchCommissionColumns();
    this.searchCommissionsTableParams = {
      currentPage: 1,
      countPerPage: 10,
    };
    this.totalSearchCommissions = 0;
    this.loadSearchCommissions();
  }

  initSearchFilterSettings() {
    this.searchFilterSettings = {
      tableSrc: 'search',
      ...cloneDeep(this.default),
      lenderId: '',
      payeeIds: 0,
      sortColumn: 'Date',
      filterFields: {
        date: true,
        adviserMultiple: true,
        payeeMultiple: true,
        lender: true,
        commissionTypeMultiple: true,
        search: true,
        loanTotalDisplay: true,
        commissionTotalDisplay: true,
      },
      loanTotal: {
        value: 0,
        displayTitle: 'Loan Amount',
      },
      commissionTotal: {
        value: 0,
        displayTitle: 'Commission Amount',
      },
    };
  }

  onSearchRowItemClick(commission) {
    if (!this.isMobileDevice || !commission) {
      return;
    }
    this.modalRenderService.openCommissionUnmatchedDetails({
      commission: {
        ...commission,
        type: 'search',
      },
    });
  }

  onSearchFilterChange({ filterSettings }) {
    this.searchFilterSettings = { ...filterSettings };
    this.loadSearchTimeout && this.$timeout.cancel(this.loadSearchTimeout);

    this.loadSearchTimeout = this.$timeout(() => {
      this.loadSearchCommissions();
    }, 1000);
  }

  getSearchRequestParams() {
    return {
      StartDate: get(this.searchFilterSettings, 'date.filter.DateStart', null),
      EndDate: get(this.searchFilterSettings, 'date.filter.DateEnd', null),
      CommissionTypeIds: this.searchFilterSettings.commissionTypeIds || null,
      BrokerIds: this.searchFilterSettings.adviserIds || null,
      PayeeIds: this.searchFilterSettings.payeeIds || null,
      SearchString: this.searchFilterSettings.searchString || null,
      ProviderIds: this.searchFilterSettings.lenderId || null,
      SortColumn: this.searchFilterSettings.sortColumn,
      SortType: this.searchFilterSettings.sortType,
      PageNumber: this.searchCommissionsTableParams.currentPage,
      PageSize: this.searchCommissionsTableParams.countPerPage,
    };
  }

  loadSearchCommissions() {
    this.isLoadingSearchCommissions = true;
    const params = this.getSearchRequestParams();
    return this.commissionService.getSearchCommissions(params).then(
      (response) => {
        if (!response) {
          return;
        }

        this.searchCommissions = response;
        let totalLoan = 0;
        let totalCommission = 0;
        let totalCount = response.length;

        if (totalCount) {
          const [firstItem] = this.searchCommissions;
          const {
            totalLoan: firstTotalLoan,
            totalCommission: firsttotalCommission,
            totalCount: firstTotalCount,
          } = firstItem;
          totalLoan = firstTotalLoan || 0;
          totalCommission = firsttotalCommission || 0;
          totalCount = firstTotalCount || 0;
        }

        this.searchFilterSettings = {
          ...this.searchFilterSettings,
          loanTotal: {
            ...this.searchFilterSettings.loanTotal,
            value: totalLoan,
          },
          commissionTotal: {
            ...this.searchFilterSettings.commissionTotal,
            value: totalCommission,
          },
        };
        const commisionIndex = this.searchCommissionsHeader.findIndex(
          (header) => header.key === 'commissionAmount',
        );
        const loanIndex = this.searchCommissionsHeader.findIndex(
          (header) => header.key === 'loanAmount',
        );
        this.searchCommissionsHeader[commisionIndex] = {
          ...this.searchCommissionsHeader[commisionIndex],
          subheaderValue: totalCommission,
        };
        this.searchCommissionsHeader[loanIndex] = {
          ...this.searchCommissionsHeader[loanIndex],
          subheaderValue: totalLoan,
        };
        this.totalSearchCommissions = totalCount;
        this.loadingTimeout = this.$timeout(() => {
          this.isLoadingSearchCommissions = false;
        }, 100);
      },
      () => {
        this.isLoadingSearchCommissions = false;
        return this.isLoadingSearchCommissions;
      },
    );
  }

  onSortTableData({ sortColumn, filterSettings }) {
    const filter = setTableSortOrder(sortColumn, filterSettings);
    this.onSearchFilterChange({ filterSettings: filter });
  }

  onExportReceivedCommission() {
    const fileName = `ReceivedCommissions.xls`;
    const params = {
      type: 'Received',
      fileName,
      methodName: 'exportReceivedCommissions',
      exportParams: this.getSearchRequestParams(),
    };
    this.isExportingReceived = true;
    this.commissionHelperService
      .exportCommissionTableData(params)
      .then(() => {
        this.isExportingReceived = false;
      })
      .catch(() => {
        this.isExportingReceived = false;
      });
  }
}
