export const getFilteredExpense = (data) => {
  const filteredTypes = [];
  data &&
    data.forEach((item) => {
      switch (item.Name) {
        case 'Credit Cards':
          break;
        case 'Mortgage Repayments':
          break;
        default:
          filteredTypes.push(item);
          break;
      }
    });

  return filteredTypes;
};

export const getSelectedFamilyData = (listExpense, selectedFamilyId) => {
  const selectedFamily = listExpense.find((o) => {
    return o.FamilyId === selectedFamilyId;
  });
  return selectedFamily || { FamilyName: '' };
};
