import template from './fundingRequired.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    familyId: '<',
    fundingSummary: '<',
    isAssetFinance: '<',
    loanAppFormWarning: '<',
    fundingDetailsErrorProps: '<',
    loanAppSharedData: '<',
  },
};
