import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'PreAssessExpensesModalCtrl',
      function PreAssessExpensesModalCtrl($scope, $uibModalInstance) {
        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
        $scope.saveBreakdown = function () {};
      },
    );
