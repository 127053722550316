import template from './fieldsIntendedRepayment.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    optionList: '<',
    answer: '<',
    onAdd: '&',
  },
};
