import { getLogger } from '@loanmarket/logger-core';
import { statusListBuilderForUI } from 'Common/mappers/loanFile';
import { orgPipelineStatusToFileStatusEntity } from 'Common/mappers/orgPipelineStatus';

class LoanFileService {
  constructor(httpClient, uiService, orgPipelineStatusService) {
    'ngInject';

    this.httpClient = httpClient;
    this.uiService = uiService;
    this.orgPipelineStatusService = orgPipelineStatusService;
    this.apiBaseUrl = 'loan-file';
    this.logger = getLogger('LoanFileService');
  }

  getStatusList(loanId = 0) {
    const getOldFileStatuses = () => {
      return this.httpClient
        .get(`${this.apiBaseUrl}/status-list`, { loanId })
        .then(
          ({ data }) =>
            data &&
            data.map((status) => statusListBuilderForUI(status, loanId)),
        );
    };
    if (this.uiService.newPipeline) {
      return this.orgPipelineStatusService
        .getOrgPipelineStatuses()
        .then((data) => {
          const orgStatuses = data.map((status) => {
            const statusAsLoanFileStatus = orgPipelineStatusToFileStatusEntity(
              status,
            );
            return statusListBuilderForUI(statusAsLoanFileStatus, loanId);
          });
          if (orgStatuses.length) {
            return orgStatuses;
          }
          return getOldFileStatuses();
        })
        .catch((error) => {
          this.logger.error(
            'Get Org Pipeline Status as Loan File Status failed:',
            error,
          );
          return getOldFileStatuses();
        });
    }
    return getOldFileStatuses();
  }

  setNewFile(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/new-file`, data);
  }

  searchName({ searchNameString, adviserId = 0, page = 1, count = 20 }) {
    return this.httpClient.get(`${this.apiBaseUrl}/search-name`, {
      searchNameString,
      adviserId,
      page,
      count,
    });
  }
}

export default LoanFileService;
