class FrameCtrl {
  constructor($sce) {
    'ngInject';

    this.$sce = $sce;
  }

  getFrameUrl() {
    return this.$sce.trustAsResourceUrl(
      `${process.env.REACT_APP_HOST}/frame/${this.component}/${this.value}`,
    );
  }
}

export default FrameCtrl;
