import { getLogger } from '@loanmarket/logger-core';
import { toastError, toastSuccess } from 'Common/utilities/alert';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { parseToInt10 } from 'Common/utilities/parse';
import { sortDate } from 'Common/utilities/sort';
import {
  LENDING_CATEGORY,
  ASSET_PERSONAL_CATEGORY,
} from 'Common/constants/securityValues';
import { SORT_TYPE } from 'Common/constants/sort';

export const updateRefNoOnlyUtil = (params) => {
  const { loan, $scope, contactModelService, contactSharedData } = params;
  const { ReferenceNumber, LoanID, LoanScenarioID } = loan;
  const isShowWarning =
    !ReferenceNumber || !LoanID || !LoanScenarioID || !contactModelService;
  if (isShowWarning) {
    return;
  }

  if ($scope.storedRefNo === ReferenceNumber) {
    contactSharedData.lendingSharedData.referenceUpdate.isUpdating = false;
  } else {
    contactModelService.putLoanReferenceNo(LoanID, ReferenceNumber).then(() => {
      contactSharedData.lendingSharedData.referenceUpdate = {
        loanScenarioID: LoanScenarioID,
        referenceNumber: ReferenceNumber,
        isUpdating: false,
      };
      toastSuccess('Reference number updated successfully.');
    });
  }
};

export const getLoanImportantDates = ({ $scope, loanApplicationServices }) => {
  loanApplicationServices.getLoanImportantDates().then((data) => {
    $scope.importantDatesList =
      data &&
      data.reduce((accum, value) => {
        return [...accum, ...value];
      }, []);
  });
};

export const showAutoCreatedFinancialsForSettledLoan = ({
  loanStatusId,
  loanFinancialsService,
  loanAppId,
  familyId,
}) => {
  const isLoanSetToSettled =
    parseToInt10(loanStatusId) === LOAN_STATUS.EXISTING.SETTLED;
  if (!isLoanSetToSettled) {
    return;
  }

  loanFinancialsService.showAutoCreatedFinancialsOnSettlement({
    params: {
      familyId,
      loanAppId,
    },
  });
};

export const setStatusDefault = ({
  $scope,
  loanService,
  contactService,
  loanFinancialsService,
  familyId,
  obj,
  type,
}) => {
  const dateTypeObj =
    $scope.importantDatesList &&
    $scope.importantDatesList.find(
      (importantDate) => importantDate.typeId === type,
    );
  const defaultStatusLoanId = (dateTypeObj && dateTypeObj.defaulForStatus) || 0;
  loanService
    .setDefaultLoanStatusForImportantDates(obj, defaultStatusLoanId)
    .then(({ isOk }) => {
      if (!isOk) {
        return;
      }
      contactService
        .LoanStatusSet(obj.LoanID, obj.defaultStatusLoanId, false, '', true)
        .then(() => {
          showAutoCreatedFinancialsForSettledLoan({
            loanStatusId: defaultStatusLoanId,
            loanAppId: obj.LoanScenarioID,
            loanFinancialsService,
            familyId,
          });
          $scope.getLoanListGet();
        });
    });
};

export const updateImportantDateForEstimatedSettlement = (
  loanScenarioModelService,
) => (params) => {
  const { type, obj, dateFormat, message } = params;
  const validImportantDateList = [
    IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT,
  ];
  if (!validImportantDateList.includes(type)) {
    return false;
  }
  const dateParams = {
    loanApplicationId: 0,
    loanId: obj.LoanID,
    dateTypeId: IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT,
    date: dateFormat || '',
  };
  loanScenarioModelService.updateImportantDate(dateParams).then((res) => {
    if (res.data) {
      if (message) {
        toastSuccess(message);
      }
    } else {
      toastError('Error saving important date.');
    }
  });
  return true;
};

export const getDateMessage = (type) => {
  switch (type) {
    case IMPORTANT_DATE_TYPES.IN_PROGRESS.SETTLEMENT:
      return 'Settlement Date successfully updated!';
    case IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVAL:
      return 'Approval Date successfully updated!';
    case IMPORTANT_DATE_TYPES.IN_PROGRESS.SUBMITTED:
      return 'Submitted Date successfully updated!';
    case IMPORTANT_DATE_TYPES.IN_PROGRESS.FINANCE:
      return 'Finance Date successfully updated!';
    case IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVALEXPIRY:
      return 'Pre Approval Expiry Date successfully updated!';
    case IMPORTANT_DATE_TYPES.DELETE.ESTIMATED_SETTLEMENT:
      return 'Estimated Settlement Date successfully updated!';
    default:
      return '';
  }
};

export const getCalcType = (typeOfCalculation) => {
  switch (typeOfCalculation) {
    case 'FundingCalculator':
      return 1;
    case 'Serviceability':
      return 2;
    case 'ProductFinder':
      return 3;
    default:
      toastError('Unable to delete unknown Calculation Type');
  }
};

export const showVehicleDetailsUtil = (loan) => {
  const { VehicleYear, VehicleMake, AssetDescription } = loan;
  const isShow = VehicleYear || VehicleMake || AssetDescription;
  return !!isShow;
};

export const showSecurityAddressUtil = ({
  showAssetFinance,
  inprogressLoan,
}) => {
  const isRestictCategories = ASSET_PERSONAL_CATEGORY.includes(
    inprogressLoan.LendingCategoryId,
  );
  const isShow = !showAssetFinance || !isRestictCategories;
  return !!isShow;
};

export const showSecurityAssetUtil = ({ showAssetFinance, inprogressLoan }) => {
  const { LendingCategoryId: lendingCategoryId } = inprogressLoan;
  const isAssetFinanceCategory =
    lendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE;
  return showAssetFinance && isAssetFinanceCategory;
};

export const isFactFindReady = ($scope) => (loanDetails) => {
  return !!(
    $scope.isLendingLoanAppFFImprovementsFeatureEnabled &&
    loanDetails.LoanScenarioID &&
    $scope.isBrandingReady &&
    !$scope.isLoadingAdviserTheme &&
    loanDetails.LendingCategoryId !== $scope.LENDING_CATEGORY.ASSET_FINANCE &&
    loanDetails.LendingCategoryId !== $scope.LENDING_CATEGORY.PERSONAL_LOAN
  );
};

export const sortExistingLoansList = ($scope) => (sortOrder) => {
  $scope.existingLoans = sortDate(
    $scope.existingLoans,
    'SettlementDate',
    sortOrder,
  );
};

export const sortPreviousLoansList = ($scope) => (sortOrder) => {
  $scope.previousLoans = sortDate(
    $scope.previousLoans,
    'CreatedDate',
    sortOrder,
  );
};

export const createNewDeal = ({
  contactSharedData,
  viewOfFileModalService,
}) => {
  const logger = getLogger('Lending');
  if (!contactSharedData || !contactSharedData.familyData) {
    logger.error('New Deal creation failed: missing family data');
    return;
  }

  const {
    IsClient,
    FamilyId,
    AllocatedAdviserID,
  } = contactSharedData.familyData;

  const params = {
    familyId: FamilyId,
    allocatedAdviserId: AllocatedAdviserID,
    personIds: IsClient
      ? contactSharedData.familyData.Persons.map((person) => {
          return person.PersonId;
        })
      : [contactSharedData.familyData.OrganisationId],
    isClient: !!IsClient,
  };

  const isValidParams =
    params.familyId && params.allocatedAdviserId && params.personIds.length;

  if (!isValidParams) {
    logger.error('New Deal creation failed: invalid parameters ', params);
    return;
  }
  return viewOfFileModalService.launchCreateNewFile(params);
};

export const addNewApplication = ({
  contactSharedData,
  viewOfFileModalService,
  uiService,
  familyId,
  $scope,
}) => () => {
  if (!familyId) {
    return;
  }

  if (uiService.viewOfAfileTurnedOn) {
    createNewDeal({
      contactSharedData,
      viewOfFileModalService,
    });
  } else {
    if ($scope.showAssetFinance) {
      uiService.openAddNewLoanApplicationModal(false, familyId);
    } else {
      $scope.CreateDummyLoanScenario();
    }
  }
};

export const extendLendingDetailViewOfFile = ({
  $scope,
  uiService,
  contactSharedData,
  viewOfFileModalService,
  currentUserService,
}) => {
  $scope.SORT_TYPE = SORT_TYPE;
  $scope.settledDateSortOrder = SORT_TYPE.DESC;
  $scope.sortExistingLoansList = sortExistingLoansList($scope);
  $scope.sortPreviousLoansList = sortPreviousLoansList($scope);
  $scope.isFactFindReady = isFactFindReady($scope);
  $scope.addNewApplication = addNewApplication({
    familyId: $scope.familyId,
    $scope,
    uiService,
    viewOfFileModalService,
    contactSharedData,
  });
  $scope.lendingDetailScope = {
    ...$scope,
    options: {
      previousTable: {
        title: 'Previous',
        hasFilter: true,
        isSettledTable: false,
        addButtonLabel: 'Previous Deal',
        showButton: !(currentUserService?.isRiskWriterOnly ?? false),
      },
      settledTable: {
        isSettledTable: true,
        title: 'Settled',
        hasFilter: false,
        addButtonLabel: 'Existing Deal',
        showButton: !(currentUserService?.isRiskWriterOnly ?? false),
      },
    },
  };
};

export const updateLendingDetailScope = ($scope, uiService) => () => {
  uiService.viewOfAfileTurnedOn &&
    $scope.lendingDetailScope &&
    ($scope.lendingDetailScope = {
      ...$scope.lendingDetailScope,
      ...$scope,
      lendingDetailScope: null,
    });
};

export const setLoanListLoadingValue = ($scope) => (status = false) => {
  $scope.showExistingLoansLoading = status;
  $scope.showInProgressLoansLoading = status;
  $scope.showOpportunityLoansLoading = status;
  $scope.updateLendingDetailScope();
};

export const redirectToLoanFullPageView = ({ viewOfAfileTurnedOn, $state }) => (
  params,
) => {
  const { loanAppId, loanId, familyId } = params;
  const formattedParams = viewOfAfileTurnedOn
    ? {
        opportunityId: loanAppId,
        loanId,
        sourceFamilyId: familyId || 0,
        sourceFamilyName: '',
        isContactOpener: 1,
        tabName: 'fact-find',
        activeTab: 'fact-find',
      }
    : { familyId, loanAppId };
  if (viewOfAfileTurnedOn) {
    $state.go('app.opportunity', formattedParams);
  } else {
    $state.go('app.loanApplicationDetailsV3', formattedParams);
  }
};

export const isTopUpLoanVariationQualified = (showTopUpLoanVariation) => (
  loanDetails,
) => {
  return (
    showTopUpLoanVariation &&
    loanDetails.LoanScenarioID &&
    loanDetails.LoanStatus.LoanStatusName === 'Settled'
  );
};
