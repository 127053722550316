import { splitString } from 'Common/utilities/string';
import { pipelineLabelBuilder } from 'Common/utilities/pipelineLabels';
import {
  crmOptionSettings,
  swalOptionSettings,
} from 'Common/constants/loanDeleteWarningSettings';
import {
  activeFactFindCrmConfirmation,
  inactiveFactFindSweetAlert,
} from 'Common/utilities/contactsDeleteFactFindWarnings';
import { deleteLoan } from 'Common/utilities/deleteLoanAction';
import {
  PIPELINE_CATEGORY,
  LEAD_COLUMNS_IN_OPPORTUNITY_PIPELINE,
} from 'Common/constants/pipelineStatus';

export function getCurrentStatusIndex(statusList, statusBasis, pipelineStatus) {
  if (!statusList || !statusList.length) {
    return -1;
  }
  return statusList.findIndex((card) => card[statusBasis] === pipelineStatus);
}

export function involvedPartiesBuilder(card) {
  const clientsList = splitString(card.clients).map((contactName) => {
    return { name: contactName, isClient: true };
  });
  const entitiesList = splitString(card.entities).map((contactName) => {
    return { name: contactName, isEntity: true };
  });
  const guarantorsList = splitString(card.guarantors).map((contactName) => {
    return { name: contactName, isGuarantor: true };
  });

  return [...clientsList, ...entitiesList, ...guarantorsList];
}

export function applicationTimelineCardBuilder(
  utilitiesService,
  card,
  statusList,
) {
  return {
    ...card,
    adviserInitials: utilitiesService.filterInitialOneString(
      card.adviserFullName,
    ),
    currentStatusIdx: getCurrentStatusIndex(
      statusList,
      'pipelineStatusID',
      card.pipelineStatusId,
    ),
    involvedParties: involvedPartiesBuilder(card),
    loanAmount: card.proposedLoanAmount,
    contactNamesLimit: 3,
    loanScenarioID: card.loanScenarioId,
    labelsList: pipelineLabelBuilder(card.labels),
    loanID: card.loanId,
    loanStatusID: card.loanStatusId,
    pipelineCardsID: card.pipelineCardsId,
    lenderID: card.lenderId,
    lender: card.lenderName,
    primaryPurposeCategories: card.loanPurposeCategory,
  };
}

export function isShownAsOpportunityCard({
  card,
  isMergeLeadAndOpportunities,
}) {
  return (
    card.pipelineCategory === PIPELINE_CATEGORY.OPPORTUNITY &&
    (!isMergeLeadAndOpportunities ||
      (isMergeLeadAndOpportunities &&
        !LEAD_COLUMNS_IN_OPPORTUNITY_PIPELINE.includes(card.pipelineStatus)))
  );
}

export function isShownAsLeadCard({ card, isMergeLeadAndOpportunities }) {
  return (
    card.pipelineCategory === PIPELINE_CATEGORY.OPPORTUNITY &&
    isMergeLeadAndOpportunities &&
    LEAD_COLUMNS_IN_OPPORTUNITY_PIPELINE.includes(card.pipelineStatus)
  );
}

export function opportunityTimelineCardBuilder(
  utilitiesService,
  card,
  statusList,
  isMergeLeadAndOpportunities,
) {
  return {
    ...card,
    adviserInitials: utilitiesService.filterInitialOneString(
      card.adviserFullName,
    ),
    currentStatusIdx: getCurrentStatusIndex(
      statusList,
      'pipelineStatusId',
      card.pipelineStatusId,
    ),
    involvedParties: involvedPartiesBuilder(card),
    contactNamesLimit: 3,
    loanScenarioID: card.loanScenarioId,
    labelsList: pipelineLabelBuilder(card.labels),
    loanID: card.loanId,
    dateCreated: card.createdDate,
    isOpportunityStatus: isShownAsOpportunityCard({
      card,
      isMergeLeadAndOpportunities,
    }),
    isLeadStatus: isShownAsLeadCard({
      card,
      isMergeLeadAndOpportunities,
    }),
  };
}

export function leadsTimelineCardBuilder(utilitiesService, card, statusList) {
  return {
    ...card,
    adviserInitials: utilitiesService.filterInitialOneString(
      card.adviserFullName,
    ),
    currentStatusIdx: getCurrentStatusIndex(
      statusList,
      'pipelineStatusId',
      card.pipelineStatusId,
    ),
    labelsList: pipelineLabelBuilder(card.labels),
  };
}

export const deletePipelineItemService = ({
  loanScenarioService,
  event,
  onSuccessParams,
  crmConfirmation,
  swal,
  deleteAction,
}) => {
  if (event) {
    event.stopPropagation();
  }

  const activeFactFindCrmConfirmationAction = () => {
    activeFactFindCrmConfirmation({
      crmConfirmation,
      deleteAction,
      crmOptionSettings,
    });
  };

  const inactiveFactFindSweetAlertAction = () => {
    inactiveFactFindSweetAlert({ swal, deleteAction, swalOptionSettings });
  };

  if (onSuccessParams) {
    const loanScenarioId = onSuccessParams.loanScenarioId;

    return deleteLoan({
      loanScenarioId,
      loanScenarioService,
      inactiveFactFindSweetAlertAction,
      activeFactFindCrmConfirmationAction,
    });
  }
  inactiveFactFindSweetAlertAction();
};
