const DEFAULT = {
  STARTCOLOR: '#d45d79',
  ENDCOLOR: '#55478e',
  PADDING_TOPBOTTOM: '15px',
  PADDING_LEFTRIGHT: '30px',
};

class GradientBannerCtrl {
  $onInit() {
    this.initDefaultIfValuesNull();

    this.assignFilterGradient();
  }

  assignFilterGradient() {
    this.filterGradient = {
      background: `linear-gradient(to right, ${this.startColor} 0%, ${this.endColor} 100%)`,
      filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='${this.startColor}', endColorstr='${this.endColor}', GradientType=1 )`,
      padding: `${this.paddingTopBottom} ${this.paddingLeftRight}`,
    };
  }

  initDefaultIfValuesNull() {
    this.iconName = this.iconName || 'default';
    this.startColor = this.startColor || DEFAULT.STARTCOLOR;
    this.endColor = this.endColor || DEFAULT.ENDCOLOR;
    this.paddingTopBottom = this.paddingTopBottom || DEFAULT.PADDING_TOPBOTTOM;
    this.paddingLeftRight = this.paddingLeftRight || DEFAULT.PADDING_LEFTRIGHT;
  }
}

export default GradientBannerCtrl;
