import { get } from 'lodash';
import { catchError } from 'Common/utilities/promise';
import { openEditClientModal } from 'Components/opportunity/opportunityMain/opportunityDetails/util/clientEditModal';
import { openDeleteClientModal } from 'Components/opportunity/opportunityMain/opportunityDetails/util/clientDeleteModal';
import { toastSuccess } from 'Common/utilities/alert';
import { SERVICE_OPTION } from 'Common/constants/helloBook';

export default class PrivacyConsentTableCtrl {
  constructor(
    $uibModal,
    toaster,
    loanScenarioService,
    opportunityCalculationService,
    loanOpportunityService,
    configService,
    opportunityLoanAppService,
    helloBookService,
    helloPackService,
    uiService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.toaster = toaster;
    this.loanScenarioService = loanScenarioService;
    this.opportunityCalculationService = opportunityCalculationService;
    this.loanOpportunityService = loanOpportunityService;
    this.configService = configService;
    this.opportunityLoanAppService = opportunityLoanAppService;
    this.helloBookService = helloBookService;
    this.helloPackService = helloPackService;
    this.uiService = uiService;
  }

  $onInit() {
    const {
      oneTouchPrivacy,
      showCreditGuideSent,
      sendPrivacyConsentLink,
    } = this.configService.feature;
    this.oneTouchPrivacy = oneTouchPrivacy;
    this.showCreditGuide = showCreditGuideSent;
    this.sendPrivacyConsentLink = sendPrivacyConsentLink;

    this.privacyConsents = [];
    if (typeof this.allInvolvedParties === 'undefined') {
      this.allInvolvedParties = [];
    }

    this.getPrivacyConsents();
    const privacyConsents = this.getPrivacyConsents.bind(this);
    this.opportunityLoanAppService.setCallback(
      'refreshPrivacyConsents',
      privacyConsents,
    );

    this.openEditClientModal = openEditClientModal({
      $uibModal: this.$uibModal,
      countryList: this.uiService.countryList,
      callback: this.updateClientCallback,
    }).bind(this);
  }

  $onChanges(changes) {
    const { allInvolvedParties, involvedPartyPerson } = changes;
    if (allInvolvedParties?.currentValue) {
      this.mapPrivacyConsent();
    }
    if (involvedPartyPerson?.currentValue) {
      this.openDeleteClientModal = openDeleteClientModal({
        toaster: this.toaster,
        loanScenarioService: this.loanScenarioService,
        opportunityCalculationService: this.opportunityCalculationService,
        loanOpportunityService: this.loanOpportunityService,
        loanAppId: this.loanAppId,
        involvedPartyPerson: involvedPartyPerson.currentValue,
        callback: this.updateClientCallback,
      });
    }
  }

  getPrivacyConsents() {
    if (!this.loanAppId || !this.oneTouchPrivacy) {
      return;
    }
    this.helloBookService
      .getPrivacyPolicy(this.loanAppId)
      .then((privacyConsents = []) => {
        this.privacyConsents = privacyConsents;
        this.mapPrivacyConsent();
      })
      .catch(catchError);
  }

  mapPrivacyConsent() {
    const invalidToMap =
      !this.oneTouchPrivacy ||
      !get(this, 'privacyConsents.length', 0) ||
      !get(this, 'allInvolvedParties.length', 0);
    if (invalidToMap) {
      return;
    }
    this.allInvolvedParties = this.allInvolvedParties.map((party) => {
      const { IsApplicant, IsGuarantor, OrganisationId } = party;
      const personId = +get(party, 'PersonId', 0);
      const consent = this.privacyConsents.find(
        (consent) => consent.clientId === personId,
      );
      return {
        ...party,
        privacyConsent: get(consent, 'privacyConsent', ''),
        factFindSentDate: get(consent, 'factFindSentDate', ''),
        showPrivacyConsent: (IsApplicant || IsGuarantor) && !OrganisationId,
      };
    });
  }

  updateInvolvedParty(party) {
    if (!party) {
      return;
    }
    this.openEditClientModal(
      null,
      party.FamilyId,
      party.PersonId || party.OrganisationId,
      !!party.PersonId,
      party,
    );
  }

  requestPrivacyConsent(client) {
    if (!client) {
      return;
    }
    const props = {
      selectedService: SERVICE_OPTION.PRE_VERIFY_CLIENT_SENDING_ONLY,
      loanApplicationId: this.loanAppId,
      familyId: client.FamilyId,
      clientId: client.PersonId,
      custom: {
        header: 'Request privacy consent',
        remarks:
          'Share our privacy policy and request privacy consent from your client',
        send: () => {
          return this.helloBookService
            .sendPrivacyConsentRequest({
              loanAppId: this.loanAppId,
              familyId: client.FamilyId,
              clientId: client.PersonId,
            })
            .then(() => {
              toastSuccess('Privacy Policy Sent!');
            });
        },
      },
    };

    return this.helloPackService.launchHelloPackModal(props);
  }

  showPartyDeleteIcon(party) {
    if (!party.IsApplicant) {
      return true;
    }
    const allApplicants = this.allInvolvedParties.filter((o) => o.IsApplicant);
    return allApplicants.length > 1;
  }

  deleteInvolvedParty(party) {
    if (!party) {
      return;
    }
    this.openDeleteClientModal(
      null,
      party.PersonId || party.OrganisationId,
      !!party.PersonId,
      party.FamilyId,
    );
  }
}
