import controller from './commissionSearchMatchCtrl';
import template from './commissionSearchMatch.html';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    commission: '<',
    onMatchSuccess: '&',
  },
};
