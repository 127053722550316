import { INSURANCE_TYPES } from 'Common/constants/insuranceOptions';
import quoteToContactCtrl from 'Components/insurancesSaveToContact/quoteToContactCtrl';
import profilerToContactCtrl from 'Components/saveProfilerToContact/profilerToContactCtrl';
import { getFamilyFullName } from 'Common/utilities/insurance';

export default class InsuranceReportService {
  constructor(
    $uibModal,
    insuranceQuoteService,
    insuranceProfilerService,
    crmConfirmation,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.insuranceQuoteService = insuranceQuoteService;
    this.insuranceProfilerService = insuranceProfilerService;
    this.crmConfirmation = crmConfirmation;
  }

  sendReportViaEmail(type, reportEmail) {
    switch (type) {
      case INSURANCE_TYPES.QUICK_QUOTE:
        return this.insuranceQuoteService.sendQuoteReportViaEmail(reportEmail);
      case INSURANCE_TYPES.PROFILER:
        return this.insuranceProfilerService.profilerSendEmailReport(
          reportEmail,
        );
      default:
        return null;
    }
  }

  confirmCreateClientFromQuote(formData) {
    return this.$uibModal.open({
      templateUrl: '/src/components/insurancesSaveToContact/saveToContact.html',
      controller: quoteToContactCtrl,
      controllerAs: 'vm',
      size: 'md',
      backdrop: 'static',
      windowClass: 'insurances-save-to-contact-modal',
      resolve: {
        formData: { ...formData, FamilyFullName: getFamilyFullName(formData) },
        listenFrom: { types: 'saveToClientDocs' },
      },
    });
  }

  confirmCreateClientFromProfiler(formData) {
    return this.$uibModal.open({
      templateUrl:
        '/src/components/saveProfilerToContact/profilerToContact.html',
      controller: profilerToContactCtrl,
      controllerAs: 'vm',
      size: 'md',
      backdrop: 'static',
      windowClass: 'ipSaveTocontact-modal-v2',
      resolve: {
        profilerData: formData,
        type: () => 'saveToClientDocs',
      },
    });
  }
}
