class LoanAppLendingFeeCtrl {
  edit(isShow, amount) {
    this.fee.isEdit = !!isShow;

    if (amount) {
      this.fee.editAmount = amount;
    }
  }

  update() {
    if (typeof this.fee.editAmount === 'undefined') {
      this.edit(false);
      return;
    }

    this.fee.amount = this.fee.editAmount;
    this.onFeeChanges({ e: { amount: this.fee.amount } });
    this.edit(false);
  }
}

export default LoanAppLendingFeeCtrl;
