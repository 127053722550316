import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';

class LeadsToApplicationModalCtrl {
  constructor(
    $state,
    $timeout,
    loanScenarioService,
    pipelineService,
    crmConfirmation,
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.loanScenarioService = loanScenarioService;
    this.pipelineService = pipelineService;
    this.crmConfirmation = crmConfirmation;
  }

  onSave({ loan, isNew }) {
    if (!isNew) {
      this.selectedLoanId = loan.loanAppId;
    }

    const { pipelineCardsId: pipelineCardsID, clientFamilyId } = this.leadData;
    const { loanId: loanID, pipelineStatusId: pipelineStatusID } = loan || {};

    const convertData = {
      pipelineCardsID,
      conversionStatusID: PIPELINE_STATUS.APPLICATION,
      loanID: typeof loanID === 'undefined' ? 0 : loanID,
      pipelineStatusID:
        typeof pipelineStatusID === 'undefined'
          ? PIPELINE_STATUS.NEW_APPLICATION
          : pipelineStatusID,
    };

    return new Promise((resolve) => {
      if (isNew) {
        const { adviserId } = this.leadData;
        const setLoanApp = {
          LoanScenarioId: 0,
          AdviserId: adviserId || 0,
          Title: `Loan Application`,
          StatusName: 'ongoing',
          FamilyId: clientFamilyId,
          AssignedLenderId: '0',
          IsCardMovement: true,
        };
        resolve(this.loanScenarioService.scenarioSet(setLoanApp));
      } else {
        resolve({ data: loan.loanAppId });
      }
    })
      .then(({ data: loanAppID }) => {
        convertData.loanAppID = loanAppID;
        return this.pipelineService.putPipelineConvertAndLink(
          pipelineCardsID,
          convertData,
        );
      })
      .then((result) => {
        if (!result.succeeded) {
          this.modalObject.customButtonAction({ isDone: false });
          return;
        }
        const routerParams = {
          familyId: clientFamilyId,
          loanAppId: convertData.loanAppID,
        };
        this.crmConfirmation.open({
          type: 'success',
          title: 'Loan successfully linked!',
          buttonText: 'Ok',
          isButtonMaxWidth: true,
          modalSize: 'md',
        });
        this.modalObject.customButtonAction({ isDone: true });
        this.$state.go('app.loanApplicationDetailsV3', routerParams);
      });
  }
}

export default LeadsToApplicationModalCtrl;
