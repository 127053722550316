import angular from 'angular';
import { toastInfo, toastSuccess } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('profilerToSendEmail', function profilerToSendEmail(
      toaster,
      insuranceProfilerService,
      insuranceModalApplicationService,
      crmConfirmation,
    ) {
      return {
        scope: {
          familyId: '<',
          profilerData: '=',
          insurerProviderId: '=',
          confirmClient: '<',
          onConfirm: '&',
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        link: (scope, element) => {
          scope.profilerAssignToClient = (familyId, profilerId) => {
            insuranceProfilerService
              .profilerAssignToClient(familyId, profilerId)
              .then(({ status }) => {
                if (status) {
                  toastSuccess(`Profiler successfully added to contact.`);
                  scope.onConfirm();
                }
              });
          };

          scope.saveProfilerReport = () => {
            if (!scope.familyId) {
              const modalInstance = insuranceModalApplicationService.addNewClientProfiler(
                scope.profilerData,
              );
              modalInstance.result.then(() => {
                toastInfo(`Saving profiler to client's tab...`);
                scope.profilerAssignToClient(
                  scope.familyId,
                  scope.profilerData.profilerId,
                );
              });
            } else {
              scope.profilerAssignToClient(
                scope.familyId,
                scope.profilerData.profilerId,
              );
            }
          };

          const clickListener = () => {
            if (!scope.confirmClient || !!scope.familyId) {
              scope.onConfirm();
            } else if (scope.profilerData.profilerId) {
              const modalInstance = crmConfirmation.open({
                type: 'info',
                title: `Would you like to save this contact before sending PDF report via email?`,
                buttonText: `Yes please!`,
                showCloseButton: true,
                showCancelButton: true,
                modalSize: 'md',
                cancelButtonClass: 'colored',
              });
              modalInstance.result.then(() => {
                scope.saveProfilerReport();
              });
            } else {
              toaster.pop(
                'warning',
                'Warning',
                'Please calculate first before sending PDF report via email.',
              );
            }
          };

          element.on('click', clickListener);
          scope.$on('destroy', () => {
            element.off('click', clickListener);
          });
        },
      };
    });
