import angular from 'angular';

export const loadModule = () =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  angular.module('app').directive('textFormat', function textFormat($filter) {
    return {
      require: '?ngModel',
      // eslint-disable-next-line sonarjs/cognitive-complexity
      link(scope, elem, attrs, ctrl) {
        if (!ctrl) {
          return;
        }

        if (attrs.currency === 'IDR') {
          ctrl.$formatters.unshift(() => {
            if (!ctrl.$modelValue) {
              if (attrs.needZero) {
                return $filter(attrs.textFormat)(ctrl.$modelValue).replace(
                  /[,.]/g,
                  (m) => {
                    return m === ',' ? '.' : ',';
                  },
                );
              }
              return $filter(attrs.textFormat)(null);
            }
            return $filter(attrs.textFormat)(ctrl.$modelValue).replace(
              /[,.]/g,
              (m) => {
                return m === ',' ? '.' : ',';
              },
            );
          });

          ctrl.$parsers.unshift((pViewValue) => {
            let viewValue = pViewValue;
            const checkForComma = viewValue.match(/,/g) || [];
            let tempData;
            let plainNumber;
            let splitData;

            if (checkForComma.length > 1) {
              viewValue = viewValue.slice(0, Math.max(0, viewValue.length - 1));
              elem.val(viewValue);
              ctrl.$setViewValue(viewValue);
              return viewValue.replace(/,/g, '.');
            }
            if (viewValue.slice(-1) !== ',') {
              if (viewValue.slice(-1) === '0') {
                tempData = viewValue.toString();

                if (tempData.slice(-2, -1) === ',') {
                  elem.val(viewValue);
                  return viewValue.replace(/,/g, '.');
                }
                if (checkForComma.length && tempData.split(',')[1].length > 2) {
                  viewValue = viewValue.slice(
                    0,
                    Math.max(0, viewValue.length - 1),
                  );
                  ctrl.$setViewValue(viewValue);
                  elem.val(viewValue);
                  return viewValue.replace(/,/g, '.');
                }
                // eslint-disable-next-line no-useless-escape
                plainNumber = viewValue.replace(/[^\d+,|\-]/g, '');
                elem.val(
                  plainNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                );
                return plainNumber.replace(/,/g, '.');
              } else if (Number.isNaN(viewValue.slice(-1))) {
                tempData = viewValue.slice(0, -1);
                // eslint-disable-next-line no-useless-escape
                plainNumber = tempData.replace(/[^\d+,|\-]/g, '');

                if (plainNumber !== viewValue) {
                  ctrl.$setViewValue(plainNumber);
                }

                elem.val(
                  plainNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                );
                return plainNumber.replace(/,/g, '.');
              }

              tempData = viewValue.toString();

              if (tempData.split(',')[1]) {
                splitData = tempData.split(',')[1].length;
              }

              if (splitData > 2) {
                viewValue = viewValue.slice(
                  0,
                  Math.max(0, viewValue.length - 1),
                );
              }

              // eslint-disable-next-line no-useless-escape
              plainNumber = viewValue.replace(/[^\d+,|\-]/g, '');
              elem.val(
                plainNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
              );
              ctrl.$setViewValue(plainNumber);
              return plainNumber.replace(/,/g, '.');
            }

            elem.val(viewValue);
            return viewValue.replace(/,/g, '.');
          });
        } else {
          ctrl.$formatters.unshift(() => {
            if (!ctrl.$modelValue) {
              if (attrs.needZero) {
                return $filter(attrs.textFormat)(ctrl.$modelValue);
              }
              return $filter(attrs.textFormat)(null);
            }

            if (ctrl.$modelValue && attrs.decimals) {
              elem.val(parseFloat(ctrl.$modelValue).toFixed(attrs.decimals));
              return $filter(attrs.textFormat)(
                ctrl.$modelValue,
                attrs.decimals,
              );
            }

            const value = $filter(attrs.textFormat)(ctrl.$modelValue);
            const isOneDigitAfterDecimal =
              value.split('.')[1] && value.split('.')[1].length === 1;
            if (isOneDigitAfterDecimal) {
              return $filter(attrs.textFormat)(ctrl.$modelValue, 2);
            }
            return value;
          });

          ctrl.$parsers.unshift((pViewValue) => {
            let viewValue = pViewValue;
            const checkForDots = viewValue.match(/\./g) || [];

            if (checkForDots.length > 1) {
              viewValue = viewValue.slice(0, Math.max(0, viewValue.length - 1));
              ctrl.$setViewValue(viewValue);
              elem.val(viewValue);
              return viewValue;
            }
            if (viewValue.slice(-1) !== '.') {
              // eslint-disable-next-line no-useless-escape
              viewValue = pViewValue.replace(/[^\d+.|\-]/g, '');
              const decimalCheck = viewValue.split('.');
              const negativeCheck = viewValue.split('-');
              const isNegative = negativeCheck && negativeCheck[1];
              const isDecimal = decimalCheck && decimalCheck[1];

              if (isNegative) {
                negativeCheck[1] = negativeCheck[1].slice(
                  0,
                  negativeCheck[1].length,
                );
                viewValue = `${negativeCheck[0]}-${negativeCheck[1]}`;
                if (negativeCheck[0].length > 0) {
                  viewValue = negativeCheck[0];
                }
              }
              if (isDecimal) {
                decimalCheck[1] = decimalCheck[1].slice(0, 2);
                viewValue = `${decimalCheck[0]}.${decimalCheck[1]}`;
              }
              ctrl.$setViewValue(viewValue);
              if (viewValue === '-') {
                elem.val(viewValue);
                return viewValue;
              }
              if (viewValue.slice(-1) === '0') {
                if (viewValue.slice(-2, -1) === '.') {
                  elem.val($filter(attrs.textFormat)(viewValue, 1));
                  return viewValue;
                }
                if (isDecimal) {
                  elem.val($filter(attrs.textFormat)(viewValue, 2));
                  return viewValue;
                }
              }

              if (attrs.overrideDefaultZero) {
                if (viewValue) {
                  elem.val($filter(attrs.textFormat)(viewValue));
                }
                return viewValue;
              }

              elem.val($filter(attrs.textFormat)(viewValue));
              return viewValue;
            }

            elem.val(viewValue);
            return viewValue;
          });
        }
      },
    };
  });
