import { ADVISER_STATUS_NAME } from 'Common/constants/adviserStatus';
import { CONTACT_TYPE } from 'Common/constants/contactType';

export const setAttestationMonth = ({ $scope, $state, obj }) => {
  const monthValue = obj.StartDate.getMonth() + 1;
  const objDate = $scope.familyComplianceGet;
  const { FamilyComplianceId, FamilyId, ...newObj } = objDate;
  newObj.AttestationMonth = monthValue;
  return [$state.params.familyId, $state.params.clientId, newObj];
};

export const setAttestationNotification = ({ $scope, $state, obj }) => {
  const objData = $scope.familyComplianceGet;
  if (obj.BrokerStatusName === ADVISER_STATUS_NAME.CEASED_BY_ADVISER) {
    objData.SendBrokerAttestation = false;
  }
  if (
    obj.Roles &&
    obj.Roles.includes(CONTACT_TYPE.LOAN_WRITER_STR) &&
    obj.BrokerStatusName === ADVISER_STATUS_NAME.ACTIVE
  ) {
    objData.SendBrokerAttestation = true;
  }
  return [$state.params.familyId, $state.params.clientId, objData];
};

export const getFamilyCompliance = ({ $scope, $state, corporateService }) => {
  corporateService
    .familyComplianceGet($state.params.familyId, $state.params.clientId)
    .then((response) => {
      $scope.familyComplianceGet = response.data;
    });
};
