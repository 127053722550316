import angular from 'angular';
import { toastError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('OpenAddCardModalCtrl', function OpenAddCardModalCtrl(
      $scope,
      $uibModalInstance,
      contactService,
      $state,
      pipelineService,
      leadsStatusList,
      familyId,
      subtool,
      toaster,
      $localStorage,
      pipelineSharedData,
      $stateParams,
      userService,
      generalSearchService,
    ) {
      $scope.addCardbtnDisabled = false;
      $scope.statusList = [];
      $scope.addedFamilyList = [];
      const familyID = familyId;
      if (subtool === 'leads') {
        $scope.leads = true;
        $scope.type = 'leads';
        $scope.chooseOption = 'newLead';
      }

      if (familyID !== 0) {
        contactService.clientInformGet(familyID).then((response) => {
          $scope.familyList = {};

          $scope.familyList.FamilyId = familyID;
          if (response.data.length > 0) {
            $scope.familyList.FamilyFullName = response.data[0].FullName;
          }

          $scope.familyInput = $scope.familyList;
        });
      }

      $scope.searchFamilyInput = (inputFamily) => {
        generalSearchService.searchFamilyInput($scope, inputFamily);
      };

      $scope.closeFamilyList = () => {
        $scope.familyDetailsList = [];
      };
      $scope.getInitials = (name) => {
        if (!name) {
          return;
        }
        return name
          .replace(/[^ A-Za-z]/g, '')
          .replace(/\W*(\w)\w*/g, '$1')
          .toUpperCase();
      };
      $scope.selectFamily = (familyDetails) => {
        $scope.familyInput = familyDetails;
        $scope.familyDetailsList = [];
      };
      $scope.createNewContact = (familyName, pipelineStatus) => {
        userService.GetUserInfo().then((response) => {
          if (response.data) {
            if (parseInt(response.data.AccessType, 10) === 5) {
              $scope.isCorporateUser = true;
            }
            $uibModalInstance.dismiss('cancel');
            $localStorage.pipelineCreateNewContact = '';
            $localStorage.previousState = '';
            $localStorage.previousStateParams = '';
            $localStorage.pipelineFamilyInput = '';
            $localStorage.pipelineStatus = '';

            const pipelineStatusObj = $scope.leadsStatusListCopy.filter(
              (item) => {
                if (
                  parseInt(item.PipelineStatusID, 10) ===
                  parseInt(pipelineStatus.PipelineStatusID, 10)
                ) {
                  return item;
                }
                return false;
              },
            );
            pipelineSharedData.checkFamilyNameLocalStorage(
              familyName,
              pipelineStatusObj[0],
            );
            $localStorage.pipelineCreateNewContact = true;
            $localStorage.previousState = 'app.pipeline';
            $localStorage.previousStateParams = {
              familyId,
              pipelineType: 'leads',
            };

            if ($scope.isCorporateUser !== true) {
              $state.go('app.createContact', {
                fromTool: 'pipeline',
              });
            } else {
              $state.go('app.createContactCorporate', {
                fromTool: 'pipeline',
              });
            }
          }
        });
      };
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.type = subtool;

      function getStatus() {
        if (leadsStatusList && leadsStatusList.length) {
          $scope.leadsStatus = true;
          $scope.leadsStatusList = leadsStatusList;
          $scope.leadsStatusListCopy = angular.copy($scope.leadsStatusList);
          if ($scope.type === 'leads') {
            $scope.leadsStatus = true;
            $scope.selectedStatus = { ...$scope.leadsStatusList[0] };
          }
        } else {
          pipelineService.SettingsStatusGetFiltered().then(
            (response) => {
              $scope.statusList = response.data;
              $scope.leadsStatusList = [];
              for (let i = 0; i < $scope.statusList.length; i++) {
                if ($scope.statusList[i].TypeName === 'Lead') {
                  $scope.leadsStatusList.push($scope.statusList[i]);
                }
              }
              $scope.leadsStatusListCopy = angular.copy($scope.leadsStatusList);
              if ($scope.type === 'leads') {
                $scope.leadsStatus = true;
                $scope.selectedStatus = { ...$scope.leadsStatusList[0] };
              }
            },
            () => {},
          );
        }
      }

      $scope.addCard = (familyInput) => {
        if ($scope.selectedStatus && familyInput) {
          const status = $scope.selectedStatus.PipelineStatusID;
          familyInput.addCardbtnDisabled = true;
          $scope.addCardObj = {};
          $scope.addCardObj.clientFamilyId = parseInt(familyInput.FamilyId, 10);
          $scope.addCardObj.pipelineStatusID = parseInt(status, 10);

          pipelineService
            .PipelineCardsSet($scope.addCardObj)
            .then((response) => {
              const newCard = response && response.data;

              if (newCard && newCard > 0) {
                $uibModalInstance.close(newCard);
                toaster.pop(
                  'success',
                  'Added',
                  'Card has been added successfully.',
                );
                $stateParams.createdContact = '';

                if (subtool === 'leads') {
                  pipelineSharedData.dataStatus.isUpdatePipelineLead = true;
                }
                if (subtool === 'application') {
                  pipelineSharedData.dataStatus.isUpdatePipelineApp = true;
                }
              } else {
                toastError(response.data);
              }
            })
            .catch((error) => {
              toastError(error);
            })
            .finally(() => {
              familyInput.addCardbtnDisabled = false;
            });
        }
      };

      getStatus();
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
    });
