import { isDateValid } from 'Common/utilities/date';
import { DEFAULT_BRANDING_CATEGORY } from 'Common/constants/importantDocuments';

export const getImportantDocumentFlags = (vm) => {
  vm.importantDocumentFlags = [];
  return vm.generalService.getImportantDocumentFlags().then(({ data }) => {
    if (!data || !data.length) {
      return;
    }
    vm.importantDocumentFlags = data;
  });
};

export const processBrandingCategories = (vm, data) => {
  const defaultData = [{ ...DEFAULT_BRANDING_CATEGORY }, ...data];
  vm.brandingCategoriesSelected = [];
  if (vm.isUploading) {
    vm.brandingCategories = defaultData;
    vm.brandingCategoriesSelected = [{ ...DEFAULT_BRANDING_CATEGORY }];
    return;
  }
  if (!vm.details.brandingCategoryIds) {
    defaultData[0].ticked = false;
    vm.brandingCategories = defaultData;
    return;
  }
  if (vm.details.brandingCategoryIds === 'All') {
    vm.brandingCategories = defaultData;
    vm.brandingCategoriesSelected = [{ ...DEFAULT_BRANDING_CATEGORY }];
    return;
  }
  const selectedCategories = vm.details.brandingCategoryIds.split(',');
  vm.brandingCategories = defaultData.reduce((accum, category) => {
    category.ticked = selectedCategories.includes(
      category.BrandingCategoryId.toString(),
    );
    return [...accum, category];
  }, []);
  vm.brandingCategoriesSelected = vm.brandingCategories.filter(
    (category) => category.ticked,
  );
};

export const getBrandingCategories = (vm) => {
  vm.corporateService
    .brandingCategoryListGet(vm.currentUserService.countryId)
    .then(({ data }) => {
      processBrandingCategories(vm, data);
    });
};

export const resetBrandingCategorySelection = (list, isSelectAll = true) => {
  if (!list || !list.length) {
    return list;
  }
  return list.reduce((accum, value) => {
    value.ticked = isSelectAll && !value.BrandingCategoryId;
    return [...accum, value];
  }, []);
};

export const getSelectedBrandingCategoriesCsv = (selectedCategories) => {
  if (!selectedCategories || !selectedCategories.length) {
    return '';
  }
  const selectedList = selectedCategories.reduce((accum, value) => {
    if (!value.ticked) {
      return accum;
    }
    return [...accum, value.BrandingCategoryId];
  }, []);
  if (selectedList.includes(DEFAULT_BRANDING_CATEGORY.BrandingCategoryId)) {
    return 'All';
  }
  return selectedList.join();
};

export const importantDocumentDetailsGet = (vm) => () => {
  vm.corporateModelService
    .importantDocumentDetailsGet(vm.documentId)
    .then((data) => {
      if (!data) {
        return;
      }
      vm.details = data.documentDetails || {};
      vm.thumbnail = data.thumbnail || {};
      const checkForTag =
        vm.details.documentFlagId &&
        vm.importantDocumentFlags &&
        vm.importantDocumentFlags.length;
      if (checkForTag) {
        vm.currentSelectedTag =
          vm.importantDocumentFlags.find(
            (tag) => tag.DocumentFlagId === vm.details.documentFlagId,
          ) || {};
        vm.showFlagChoices = !!vm.currentSelectedTag.DocumentFlagId;
      }
      getBrandingCategories(vm);
      if (!vm.importantDocumentCategorization) {
        return;
      }
      vm.details.tempCurrentVersion = isDateValid(vm.details.currentVersion)
        ? new Date(vm.details.currentVersion)
        : new Date();
    });
};
