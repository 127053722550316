import { toastError } from 'Common/utilities/alert';
import { UNIX_MULTIPLIER } from 'Common/constants/dateConfigs';
import { sortArray } from 'Common/utilities/sort';

const noteTypeModel = () => {
  return {
    id: 0,
    key: (new Date().getTime() / UNIX_MULTIPLIER).toFixed(0),
    type: '',
  };
};

export default class NoteTypeModalCtrl {
  constructor(contactsNotesService) {
    'ngInject';

    this.contactsNotesService = contactsNotesService;
  }

  $onInit() {
    this.getNoteTypes();
    this.config = {
      currentEditedNoteType: 0,
      currentDeleteNoteType: 0,
    };
  }

  getNoteTypes() {
    this.contactsNotesService.getNoteTypes().then((noteTypes) => {
      this.noteTypes = noteTypes || [];
    });
  }

  readOnlyField(key = 0) {
    const { currentEditedNoteType, currentDeleteNoteType } = this.config;
    if (!currentEditedNoteType && !currentDeleteNoteType) {
      return false;
    }
    const config = [currentEditedNoteType, currentDeleteNoteType];
    if (!key) {
      return !!currentEditedNoteType || !!currentDeleteNoteType;
    }
    return !config.includes(key);
  }

  addNewType() {
    if (this.readOnlyField()) {
      return;
    }
    this.newNoteType = { ...noteTypeModel() };
    this.config.currentEditedNoteType = this.newNoteType.key;
  }

  onEditNoteType({ key }) {
    if (this.readOnlyField()) {
      return;
    }
    this.config.currentEditedNoteType = key;
  }

  cancelNoteTypeChanges() {
    this.config.currentEditedNoteType = 0;
    this.newNoteType = null;
  }

  updateNoteTypes(noteType) {
    if (!noteType.id) {
      const noteTypes = [...this.noteTypes, noteType];
      this.noteTypes = sortArray(noteTypes, 'type', 'ASC');
      return;
    }
    const currentIndex = this.noteTypes
      .map((type) => type.id)
      .indexOf(noteType.id);
    noteType.isUpdated = true;
    this.noteTypes[currentIndex] = noteType;
    this.noteTypes = sortArray(this.noteTypes, 'type', 'ASC');
  }

  onSaveNote({ noteType }) {
    const previousNoteTypes = [...this.noteTypes];
    this.updateNoteTypes(noteType);
    this.newNoteType = null;
    this.config.currentEditedNoteType = 0;
    this.contactsNotesService
      .postNoteTypes(noteType)
      .then(({ data }) => {
        noteType.id = data;
      })
      .catch(() => {
        toastError('Error on saving note type.');
        this.noteTypes = previousNoteTypes;
      });
  }

  onDelete({ callerData: { key } }) {
    if (this.readOnlyField(key)) {
      return;
    }
    this.config.currentDeleteNoteType = key;
  }

  onPopoverConfirm({ callerData: { id } }) {
    if (!id) {
      return;
    }
    const previousNoteTypes = [...this.noteTypes];
    this.noteTypes = this.noteTypes.filter((type) => type.id !== id);
    this.contactsNotesService.deleteNoteTypes(id).catch(() => {
      toastError('Error on deleting note type.');
      this.noteTypes = previousNoteTypes;
    });
    this.config.currentDeleteNoteType = 0;
  }

  onPopoverDismiss() {
    this.config.currentDeleteNoteType = 0;
  }

  closeModal(isReadonly = false) {
    if (isReadonly) {
      return;
    }
    const updatedNoteTypes =
      this.noteTypes && this.noteTypes.filter((type) => type.isUpdated);
    this.modalInstance.close(updatedNoteTypes);
  }
}
