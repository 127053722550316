import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';

const specialOffer =
  '<icon-subscription-special-offer></icon-subscription-special-offer>';
const bidBundle =
  '<icon-subscription-bid-bundle></icon-subscription-bid-bundle>';
const includeBundle =
  '<icon-subscription-include-bundle></icon-subscription-include-bundle>';

export const SUBSCRIPTION_TYPE_INFO = {
  [SUBSCRIPTIONTYPE.E_SIGN]: {
    listIcon: `<icon-subscription-e-sign-list></icon-subscription-e-sign-list>`,
    modalBannerDescription: 'FREE</br>(RRP $35 p/month)',
    modalBadge: bidBundle,
  },
  [SUBSCRIPTIONTYPE.BANK_CONNECT_EXPENSES_ANALYSIS]: {
    listIcon: `<icon-subscription-bank-connect-plus></icon-subscription-bank-connect-plus>`,
    modalBannerDescription: 'FREE (RRP $30 p/month) ',
    extraDescription: 'Plus $3 (+ GST) per unique bank connection',
    modalBadge: bidBundle,
  },
  [SUBSCRIPTIONTYPE.NURTURE]: {
    listIcon: `<icon-subscription-marketing-nurture></icon-subscription-marketing-nurture>`,
    modalBannerDescription: 'FREE</br>(RRP $29 p/month, per broker)',
    modalBadge: bidBundle,
  },
  [SUBSCRIPTIONTYPE.BROKERPEDIA]: {
    modalBannerDescription: '(RRP $65 p/month)',
    modalBadge: includeBundle,
  },
  [SUBSCRIPTIONTYPE.CREDIT_CHECK]: {
    listIcon: `<icon-subscription-credit-checks></icon-subscription-credit-checks>`,
    modalBannerDescription: 'NO MONTHLY FEE</br>(RRP $50 p/month)',
    extraDescription: '$12 (+ GST) per credit check',
    modalBadge: includeBundle,
  },
  [SUBSCRIPTIONTYPE.PRICE_FINDER]: {
    listIcon: `<icon-subscription-price-finder></icon-subscription-price-finder>`,
    modalBannerDescription: 'FREE</br>(RRP $50 p/month)',
    modalBadge: includeBundle,
  },
  [SUBSCRIPTIONTYPE.MARKETING_AUTOMATION]: {
    listIcon: `<icon-subscription-marketing-stay-in-touch></icon-subscription-marketing-stay-in-touch>`,
    modalBannerDescription: 'FREE</br>(RRP $49 p/month, per broker)',
    modalBadge: bidBundle,
  },
  [SUBSCRIPTIONTYPE.NURTURE_NZ]: {
    listIcon: `<icon-subscription-free-to-subscribe></icon-subscription-free-to-subscribe>`,
    modalBannerDescription: 'FREE TO SUBSCRIBE',
    modalBadge: specialOffer,
  },
  [SUBSCRIPTIONTYPE.MARKETING_AUTOMATION_NZ]: {
    listIcon:
      '<icon-subscription-free-to-subscribe class="subscribe-purple"></icon-subscription-free-to-subscribe>',
    modalBannerDescription: 'FREE TO SUBSCRIBE',
    modalBadge: specialOffer,
  },
};
