import { ADD_PROPERTY_TYPE } from 'Common/constants/options';
import { toastSaveSuccess } from 'Common/utilities/alert';

class PropertyTypeSelectionCtrl {
  constructor(crmConfirmation, financialsSharedService) {
    'ngInject';

    this.crmConfirmation = crmConfirmation;
    this.financialsSharedService = financialsSharedService;
  }

  $onInit() {
    this.OPTION_VALUES = ADD_PROPERTY_TYPE;
  }

  selectType(type) {
    this.propertyType = type;
  }

  closeModal() {
    this.modalInstance.dismiss();
  }

  nextStep(selectedType) {
    if (selectedType === this.OPTION_VALUES.EXISTING) {
      const existingAssetsModal = this.crmConfirmation.open({
        type: 'warning',
        title: 'Which existing real estate asset would you like to add?',
        modalSize: 'md',
        renderAsComponent: true,
        description: `<existing-asset-list
            modal-instance="vm.cModalInstance">
          </existing-asset-list>`,
        buttonClass: 'hidden',
        showCloseButton: true,
      });
      existingAssetsModal.result.then((isCancel) => {
        this.modalInstance.close({ selectedType, isCancel });
        if (!isCancel) {
          toastSaveSuccess();
        }
      });
      return;
    }

    this.modalInstance.close({ selectedType });
  }
}

export default PropertyTypeSelectionCtrl;
