import { SEND_TYPE } from 'Common/constants/customerCareModules';

export const setCountryDetails = ({ $scope, source }) => {
  const { countryList, countryObj } = $scope;
  const firstCountry = countryList && countryList.length && countryList[0];
  const customerCountry = countryObj && countryObj.length && countryObj[0];
  $scope.country = source === SEND_TYPE.ALL ? firstCountry : customerCountry;
  $scope.selectedCountryCode = $scope.country && $scope.country.CountryCode;
  $scope.makeObject($scope.selectedCountryCode);
};
