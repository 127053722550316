import swal from 'sweetalert';
import { getModalName } from 'Common/utilities/subscription';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { objectLength } from 'Common/utilities/objectValidation';
import priceFinderLink from 'Common/config/priceFinder';

const DEFAULT = {
  STATE: 'NSW',
  MOBILE_NUMBER: '0400000000',
  POST_CODE: '2000',
};

class UsersSubscriptionService {
  constructor(
    $window,
    $q,
    $uibModal,
    contactService,
    corporateService,
    dataService,
    configService,
  ) {
    'ngInject';

    this.$window = $window;
    this.contactService = contactService;
    this.$uibModal = $uibModal;
    this.corporateService = corporateService;
    this.dataService = dataService;
    this.$q = $q;
    this.configService = configService;

    let brokerPediaPost = {};

    this.setHomeAddress = (familyId) => {
      if (!familyId) {
        return;
      }
      this.corporateService.familyAddressGet(familyId).then((response) => {
        const { data } = response;
        if (!data || !data.length) {
          return;
        }
        this.userHomeAddress = data.find(
          (obj) => !obj.isBusiness && !obj.isMailing,
        );
        brokerPediaPost.state =
          (this.userHomeAddress && this.userHomeAddress.stateShort_Name) ||
          DEFAULT.STATE;
        brokerPediaPost.postcode =
          (this.userHomeAddress && this.userHomeAddress.postal_code) ||
          DEFAULT.POST_CODE;
      });
    };

    this.getUserMobileNumber = (clientInfo) => {
      const mobileDefault = DEFAULT.MOBILE_NUMBER;
      if (clientInfo && clientInfo.Phone && clientInfo.Phone.length) {
        const { Phone } = clientInfo;
        const mobile = Phone.find((obj) => obj.Type === 'Mobile');
        return (mobile && mobile.Number) || mobileDefault;
      }
      return mobileDefault;
    };

    this.setBrokerPediaDataForPosting = (familyId) => {
      if (!familyId) {
        return;
      }
      brokerPediaPost = {};
      this.contactService
        .clientInformGet(familyId)
        .then((informationResponse) => {
          const { data } = informationResponse;
          if (data && data.length) {
            brokerPediaPost.firstname = data[0].FirstName;
            brokerPediaPost.lastname = data[0].LastName;
            brokerPediaPost.phone = this.getUserMobileNumber(data[0]);
          }
          this.setHomeAddress(familyId);
        });
    };

    this.getBrokerPediaPostData = () => {
      return brokerPediaPost;
    };
  }

  getSubscriptionLabels(productId = SUBSCRIPTIONTYPE.ONLINE_FACT_FIND) {
    if (productId) {
      return (
        this.subscriptionLabels &&
        this.subscriptionLabels.find(
          (obj) => obj.SubscriptionProductId === productId,
        )
      );
    }
    return null;
  }

  termsNotPresent(event, termsAndConditionsLink) {
    event && event.stopPropagation();
    this.dataService
      .getImportantDocuments('Subscription T&C')
      .then((response) => {
        const documentKey = response ? response.documentKey : '';
        const termsAndConditionsLinkTmp = documentKey || termsAndConditionsLink;
        if (!termsAndConditionsLinkTmp) {
          swal({
            title: 'Not Available',
            text:
              'We have a problem. It seems this document link is not working. Please refer directly to the Terms and Conditions document under the Important Documents list under Resources section.',
            type: 'error',
            showCancelButton: false,
          });
        } else {
          this.$window.open(termsAndConditionsLinkTmp, '_blank');
        }
      });
  }

  setBrokerPediaData(data) {
    if (!data || !objectLength(data)) {
      return;
    }
    this.brokerPediaData = data;
  }

  getBrokerPediaData() {
    return this.brokerPediaData || {};
  }

  setPriceFinderData(data) {
    if (!data || !objectLength(data)) {
      return;
    }
    this.priceFinderData = data;
  }

  getPriceFinderData() {
    return this.priceFinderData || {};
  }

  priceFinderRedirect() {
    this.$window.open(priceFinderLink, 'noopener, noreferrer');
  }

  subscribeToService(props) {
    const { newSubscriptionModal } = this.configService.feature;
    const defer = this.$q.defer();
    const { familyId, subscriptionData } = props;
    const notValidForSubscription =
      !familyId || !subscriptionData || !Object.keys(subscriptionData).length;
    if (notValidForSubscription) {
      return;
    }
    const { subscriptionProductId } = subscriptionData;
    const componentName = getModalName(
      subscriptionProductId,
      newSubscriptionModal,
    );
    if (!componentName) {
      return;
    }
    const subscriptionModal = this.$uibModal.open({
      template: `<${componentName}
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId" 
                  subscription-data="vm.props.subscriptionData" 
                  ordered-by-id="vm.props.orderedById">
                </${componentName}>`,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'mc-subscription-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    subscriptionModal.result.then((response) => {
      if (response && response.getAllSubscribe) {
        const subscribeStatus = (response && response.getAllSubscribe) || 0;
        defer.resolve(subscribeStatus);
      } else {
        defer.resolve(0);
      }
    });
    return defer.promise;
  }
}

export default UsersSubscriptionService;
