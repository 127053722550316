import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .factory(
      'InsuranceStaticModelSharedService',
      function InsuranceStaticModelSharedService() {
        const factory = this;

        const staticHealthModel = {
          Suggested_Excess: 0,
          Suggested_SpecialistsTest: true,
          Suggested_GPOption: true,
          Suggested_DentalOptical: true,
          Suggested_Premium: 0,
          Excess: 0,
          SpecialistsTest: true,
          GPOption: true,
          DentalOptical: true,
          Premium: 0,
        };

        const staticLifeModel = {
          Suggested_MortgageCover: 0,
          Suggested_DebtsCover: 0,
          Suggested_FamilyExpensesFund: 0,
          Suggested_EducationalFund: 0,
          Suggested_RetirementFund: 0,
          Suggested_FuneralFund: 0,
          Suggested_Other: 0,
          Suggested_Total: 0,
          Suggested_Premium: 0,
          MortgageCover: 0,
          DebtsCover: 0,
          FamilyExpensesFund: 0,
          EducationalFund: 0,
          RetirementFund: 0,
          FuneralFund: 0,
          Other: 0,
          Total: 0,
          Premium: 0,
        };

        const staticTraumaModel = {
          Suggested_SupportFund: 0,
          Suggested_Other: 0,
          Suggested_Total: 0,
          Suggested_Premium: 0,
          SupportFund: 0,
          Other: 0,
          Total: 0,
          Premium: 0,
        };

        const staticTPDModel = {
          Suggested_MortgageCover: 0,
          Suggested_DebtsCover: 0,
          Suggested_SupportFund: 0,
          Suggested_Other: 0,
          Suggested_Total: 0,
          Suggested_Premium: 0,
          MortgageCover: 0,
          DebtsCover: 0,
          SupportFund: 0,
          Other: 0,
          Total: 0,
          Premium: 0,
        };

        const staticIncomeProtectionModel = {
          Suggested_TypeOfCover: 0,
          Suggested_LevelOfCover: 0,
          Suggested_PaymentPeriod: 0,
          Suggested_WaitingPeriod: 0,
          Suggested_Premium: 0,
          TypeOfCover: 0,
          LevelOfCover: 0,
          PaymentPeriod: 0,
          WaitingPeriod: 0,
          Premium: 0,
        };

        const staticMortgageRepaymentsModel = {
          Suggested_LevelOfCover: 0,
          Suggested_PaymentPeriod: 0,
          Suggested_WaitingPeriod: 0,
          Suggested_Premium: 0,
          LevelOfCover: 0,
          PaymentPeriod: 0,
          WaitingPeriod: 0,
          Premium: 0,
        };

        const miscInfo = {
          indexId: 0,
          DateOfBirth: '',
          Smoker: null,
          OccupationID: 1,
          OccupationType: 1,
          EmploymentStatusID: 1,
          tempGAIType: 1,
          tempGAI: 0,
          GrossAnnualIncome: 0,
          GrossAnnualIncomeType: 1,
          tempMRType: 12,
          storedMR: 0,
          MortgageRepaymentsType: 12,
          HasHealthInsurance: true,
          HasMortgageInsurance: true,
          HasIncomeProtection: true,
          HasTraumaInsurance: true,
          HasTPD: true,
          HasLifeInsurance: true,
          HealthNote: '',
          IPNote: '',
          TraumaNote: '',
          TPDNote: '',
          LifeNote: '',
          MortgageNote: '',
        };

        factory.getBenefitsStaticModel = () => {
          const model = {
            BenefitId: 0,
            HealthCover: { ...staticHealthModel },
            LifeCover: { ...staticLifeModel },
            TraumaCover: { ...staticTraumaModel },
            TotalAndPermanentDisability: { ...staticTPDModel },
            IncomeProtection: { ...staticIncomeProtectionModel },
            MortgageRepaymentCover: { ...staticMortgageRepaymentsModel },
          };

          return model;
        };

        factory.getMiscInfoStaticModel = () => {
          return { ...miscInfo };
        };

        return factory;
      },
    );
