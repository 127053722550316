import angular from 'angular';

class InsuranceProfilerEmailReportModalCtrl {
  constructor($uibModalInstance, profilerData, familyId) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.familyId = familyId;
    this.profilerData = profilerData;
  }
}
export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'InsuranceProfilerEmailReportModalCtrl',
      InsuranceProfilerEmailReportModalCtrl,
    );
