import template from './accreditationContactModal.html';
import controller from './accreditationContactModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    email: '<',
    template: '<',
    modalTitle: '<',
    modalInstance: '<',
  },
};
