import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('SelectTemplateModalCtrl', function SelectTemplateModalCtrl(
      $scope,
      $uibModalInstance,
      $timeout,
      $window,
      toaster,
      emailRecipient,
      communicateService,
      SweetAlert,
    ) {
      // Models
      angular.extend($scope, {
        selectedTemplate: null,
        recipientList: [],
        sendListToMailChimpData: {
          Clients: [],
        },
        apiReturnValue: {},
        sendNow: true,
        ddClicked: false,
        loadingSpinner: true,
        actionSubmit: false,
        selectTemplate_init() {
          $scope.recipientList = emailRecipient;
          communicateService.templatesGet().then((response) => {
            $scope.loadingSpinner = false;
            if (response.data.length > 0 || !_.isUndefined(response.data)) {
              $scope.templateList = response.data;
              $scope.templateList.push({ Id: -1, Name: 'Use My Own' });
            } else {
              toaster.pop(
                'error',
                'Error',
                'Unable to load templates from source.',
              );
            }
          });
        },
      });

      // Functionalities
      angular.extend($scope, {
        templateSelected(template) {
          $scope.selectedTemplate = template;
          if ($scope.selectedTemplate.Id === -1) {
            $scope.sendNow = false;
          } else {
            $scope.sendNow = true;
          }
        },

        cancel() {
          $uibModalInstance.dismiss('cancel');
        },
      });

      // initialize
      $scope.selectTemplate_init();

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.sendListToMailChimp = (action) => {
        $scope.actionSubmit = true;
        $scope.sendListToMailChimpData.Clients = [];

        _.forEach($scope.recipientList, (value) => {
          if (value.selected) {
            const toSend = {
              FirstName: value.FirstName,
              LastName: value.LastName,
              Email: value.EmailAddress,
            };
            $scope.sendListToMailChimpData.Clients.push(angular.copy(toSend));
          }
        });

        $scope.campaign = {
          listName: 'Test List',
          permissionReminder: 'This is a Test Campaign',
          fromEmail: 'goyagoydeane%40yahoo.com',
          fromName: 'Dean Goyagoy',
          subject: 'Test Campaign',
          campaignIdTemplate:
            $scope.selectedTemplate.Id !== -1
              ? $scope.selectedTemplate.CampaignId
              : 0,
        };

        function sendActionCampaign(campaignId) {
          communicateService.sendActionCampaign(campaignId).then((response) => {
            if (!_.isUndefined(response.data) && response.status === 200) {
              toaster.pop('success', 'Success', 'Campaign successfully sent.');
            } else {
              toaster.pop('error', 'Error', 'Send Campaign had failed.');
              $timeout(() => {
                toaster.pop(
                  'notice',
                  'Notice',
                  'Please consider resolving your template.',
                );
              }, 2000);
              $timeout(() => {
                $window.open($scope.apiReturnValue.url, '_blank');
              }, 500);
            }
            $scope.actionSubmit = false;
          });
        }

        communicateService
          .sendListToMailChimp(
            $scope.campaign.listName,
            $scope.campaign.permissionReminder,
            $scope.campaign.fromEmail,
            $scope.campaign.fromName,
            $scope.campaign.subject,
            $scope.campaign.campaignIdTemplate,
            $scope.sendListToMailChimpData,
          )
          .then((response) => {
            if (!_.isUndefined(response.data) && response.data !== '') {
              if (response.data.isError) {
                SweetAlert.swal({
                  title: 'No MailChimp Account',
                  text: response.data.ErrorDescription,
                  type: 'info',
                  customClass: 'emailcampaign-swa',
                });
              } else {
                $scope.apiReturnValue = response.data;

                switch (action) {
                  case 'send':
                    sendActionCampaign($scope.apiReturnValue.campaign_id);
                    $scope.cancel();
                    break;
                  case 'edit':
                    $window.open($scope.apiReturnValue.url, '_blank');
                    break;
                  default:
                }
              }
            } else {
              toaster.pop(
                'error',
                'Error',
                'Unable to receive response from server',
              );
            }
          });
      };

      $scope.submitAction = () => {
        $timeout(() => {
          toaster.pop(
            'info',
            'Info',
            'Email campaign is sending, please wait for MailChimp response.',
          );
          $scope.actionSubmit = false;
        }, 5000);
      };
    });
