export const IMAGE_TYPE = {
  HEAD_SHOT: 1,
  HALF_BODY: 2,
  FULL_BODY: 3,
  FOOTER_IMAGE_1: 4,
  AWARDS: 5,
  FOOTER_IMAGE_2: 6,
};

export const ELITE_AWARDS = {
  CHAIRMAN: 'Chairman',
  PLATINUM: 'Platinum Elite',
  ELITE: 'Elite',
  EMERGING_ELITE: 'Emerging Elite',
  DEVELOPING: 'Developing',
  PREMIER: 'Premier',
};

export const AWARDS_IMG_URL = {
  CHAIRMAN: '/assets/images/emailSignature/elite-chairmans-club.png',
  PLATINUM: '/assets/images/emailSignature/elite-platinum.png',
  ELITE: '/assets/images/emailSignature/elite.png',
  EMERGING_ELITE: '/assets/images/emailSignature/elite-premier.png',
  PREMIER: '/assets/images/emailSignature/elite-premier.png',
  DEVELOPING: 'Developing',
};
