export const oneLevelDeepCopy = (array) => {
  if (!array) {
    return [];
  }
  return array.reduce((list, item) => {
    return [...list, { ...item }];
  }, []);
};
export const findAndReplace = ({
  list,
  objectToSwap,
  searchProp,
  searchValue,
}) => {
  if (!list || !objectToSwap || !searchProp) {
    return;
  }

  const listCopy = [...list];
  const idx = listCopy.findIndex((item) => item[searchProp] === searchValue);
  listCopy[idx] = objectToSwap;
  return listCopy;
};

export const mergeSubArray = (
  { mainArray, subArray, propToCompare },
  isSort = false,
) => {
  if (!mainArray || !subArray || !propToCompare) {
    return;
  }

  const jointArray = [...mainArray, ...subArray];

  const reducedArray = jointArray.reduceRight((newArray, item) => {
    if (
      newArray.find((value) => value[propToCompare] === item[propToCompare])
    ) {
      return newArray;
    }
    return [...newArray, item];
  }, []);

  return isSort
    ? reducedArray.sort((a, b) => a[propToCompare] - b[propToCompare])
    : reducedArray;
};
export function mergeList(...lists) {
  if (!lists) {
    return [];
  }
  return (
    lists.reduce((accum, list) => {
      return [...accum, ...list];
    }, []) || []
  );
}

export function spliceArray(item, list, howmany) {
  list.splice(item, howmany);
  return true;
}

export function removeFromArray(item, list) {
  if (list) {
    const index = list.indexOf(item);
    if (index > -1) {
      spliceArray(index, list, 1);
    }
  }
  return true;
}

export const removeElementOnIndex = (index, list) => {
  const invalidIndex = typeof index === 'undefined' || index < 0;
  const invalidList = !list || !list.length;
  if (invalidIndex || invalidList) {
    return [];
  }
  return list.slice(0, index).concat(list.slice(index + 1, list.length));
};

export function findIndexWithAttr(array, attr, value) {
  for (const [i, element] of array.entries()) {
    if (element[attr] === value) {
      return i;
    }
  }
  return -1;
}

export function chunkArray(myArray, chunkSize) {
  const results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize));
  }

  return results;
}

export const updateArrayFieldValue = ({
  list,
  listId,
  listField,
  lookup,
  lookupId,
  lookupField,
}) => {
  const isListValid = !!(list && list.length && listField);
  const isLookupValid = !!(lookup && lookup.length && lookupField);
  if (!isListValid || !isLookupValid) {
    return list;
  }
  return list.map((listItem) => {
    const updatedLookup = lookup.find(
      (item) => item[lookupId] === listItem[listId],
    );
    if (!updatedLookup || !updatedLookup[lookupField]) {
      return listItem;
    }
    listItem[listField] = updatedLookup[lookupField];
    return listItem;
  });
};

export const isValidArray = (data, checkLength = false) => {
  const isArray = data && Array.isArray(data);
  const isValidLength = isArray && data.length;
  return !!(checkLength ? isValidLength : isArray);
};

export const arrayToObject = (array, key) => {
  if (!isValidArray(array) || !key) {
    return {};
  }

  return array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});
};

export const groupedArraysToObjectGroup = (data = [], group = 'group') => {
  return data.reduce((accum, item) => {
    if (!accum[item[group]]) {
      accum[item[group]] = [item];
      return accum;
    }
    accum[item[group]] = [...accum[item[group]], item];
    return accum;
  }, {});
};

export const strictEvery = (collection, predicate = (i) => i) => {
  const array = isValidArray(collection)
    ? collection
    : Object.values(collection || {});
  return array.length > 0 && array.every(predicate);
};

export const assignArrayGroupByColumn = (
  data = [],
  column = 2,
  minItemPerRow = 6,
) => {
  const dataItemPerRow = Math.ceil(data.length / column);
  const itemPerRow =
    dataItemPerRow > minItemPerRow ? dataItemPerRow : minItemPerRow;
  return data.map((item, index) => {
    const pos = index + 1;
    item.group = Math.ceil(pos / itemPerRow);
    return item;
  });
};

export const normalizeData = (array = [], key = '') => {
  if (!key) {
    return {};
  }
  return array.reduce((data, item) => {
    data[item[key]] = item;
    return data;
  }, {});
};
