import template from './sendingStyledContainer.html';
import './style.scss';

export default {
  template,
  bindings: {
    modalInstance: '<',
    headerComponent: '<',
    sidebarComponent: '<',
    actionButtonsComponent: '<',
    mainComponent: '<',
    commonProps: '<',
    onCancel: '&',
  },
  controllerAs: 'vm',
};
