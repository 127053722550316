import template from './lmiAirtableProposedLending.html';
import controller from './lmiAirtableProposedLendingCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    brokerEventId: '<',
    loanSplits: '<',
    loanDetails: '<',
    isFromFundingDetails: '<',
    updateLmiValueOnTxt: '&',
  },
};
