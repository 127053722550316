export const PROVIDER_IDS = {
  qPRBenefitId: 13,
  benefitTypeId: 26,
};

export const TEMP_FREQUENCY_LIST = [
  { name: 'Select Frequency', id: 0 },
  { name: 'Fortnightly', id: 26 },
  { name: 'Weekly', id: 52 },
  { name: 'Monthly', id: 12 },
  { name: 'Yearly', id: 1 },
];

export const EXISTING_TAB = 'Existing';
export const PREVIOUS_TAB = 'Previous';

export const LEVEL = {
  FIRST: 1,
  SECOND: 2,
};
