import template from './warningBanner.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    onShowErrors: '&',
    dataErrors: '<',
    isTopBanner: '<',
    visibleArrow: '<',
    noRadius: '<',
    toggleErrors: '&',
    text: '<',
    reloading: '<',
  },
};
