import template from './tasksPage.html';
import controller from './tasksPageCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {},
};
