import angular from 'angular';
import tasksHeader from './tasksHeader';
import tasksTableFilter from './tasksTableFilter';
import tasksTableDisplay from './tasksTableDisplay';

export default angular
  .module('tasks.components', [])
  .component('tasksHeader', tasksHeader)
  .component('tasksTableFilter', tasksTableFilter)
  .component('tasksTableDisplay', tasksTableDisplay).name;
