export default class LoanRedirectCtrl {
  constructor($state, contactService) {
    this.$state = $state;
    this.contactService = contactService;
  }

  $onInit() {
    const loanId = this.$state.params.loanId;
    this.getClientDetails(loanId);
  }

  getClientDetails(loanId = 0) {
    if (!loanId) {
      this.$state.go('app.home');
    }
    return this.contactService
      .borrowersDetailsGet(0, loanId, false)
      .then(({ data }) => {
        const [borrower] = data;
        if (borrower?.FamilyId) {
          const route = borrower.IsEntity
            ? 'app.BusinessAccount'
            : 'app.contactsSingle';
          const activeTab = borrower.IsEntity ? 'loans' : 'lending';
          this.$state.go(route, {
            activeTab,
            loanId,
            familyId: borrower.FamilyId,
            inprogress: true,
          });
        } else {
          this.$state.go('app.home');
        }
      });
  }
}
