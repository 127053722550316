import swal from 'sweetalert';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import {
  CALCULATION_TYPE,
  CALCULATION_TITLE,
} from 'Common/constants/calculations';
import { serviceabilityBuilderForUI } from 'Common/mappers/loanOpportunity';
import { lowercaseFirstLetter } from 'Common/utilities/string';
import { toastError } from 'Common/utilities/alert';
import { getMaxLoanAmountLender } from 'Components/opportunity/opportunityMain/opportunityDetails/util/opportunityCalculation';
import { parseToInt10 } from 'Common/utilities/parse';

export default class OpportunityCalculationService {
  constructor(
    $state,
    $timeout,
    uiService,
    contactService,
    currentUserService,
    loanProfilerService,
    loanCalculatorLinking,
    loanOpportunityService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.uiService = uiService;
    this.contactService = contactService;
    this.currentUserService = currentUserService;
    this.loanProfilerService = loanProfilerService;
    this.loanCalculatorLinking = loanCalculatorLinking;
    this.loanOpportunityService = loanOpportunityService;

    this.LENDING_CATEGORY = LENDING_CATEGORY;
    this.CALCULATION_TYPE = CALCULATION_TYPE;
    this.CALCULATION_TITLE = CALCULATION_TITLE;

    this.calculations = [];
    this.serviceabilityMappedData = {};
  }

  setOpportunityDetails(...opportunity) {
    const [id, title, summary, tabName] = opportunity;
    this.opportunityId = id || this.$state.params.opportunityId;
    this.opportunityTitle = title;
    this.summary = summary;
    this.tabName = tabName;
  }

  getCalculationTitle(calculationType) {
    return this.CALCULATION_TITLE[calculationType];
  }

  getCalculations() {
    const opportunityId =
      this.opportunityId || this.$state.params.opportunityId;
    return this.loanOpportunityService
      .getLoanOpportunityCalculation(opportunityId)
      .then((response) => {
        if (!response) {
          return;
        }
        this.calculations = [...response];
        return this.calculations;
      });
  }

  openCalculation(calculationObj, familyData = {}) {
    this.checkCalculationsList();
    const tabName =
      calculationObj.typeOfCalculation === CALCULATION_TYPE.FUNDING_CALCULATOR
        ? 'funding'
        : lowercaseFirstLetter(calculationObj.typeOfCalculation);
    const paramType =
      calculationObj.typeOfCalculation !== CALCULATION_TYPE.SERVICEABILITY
        ? 'opportunity'
        : undefined;
    const { familyId, clientIds, familyFullName } = familyData;
    const { PrimaryPurpose: primaryPurpose } = this.summary;
    const args = {
      tabName,
      paramType,
      eventId: calculationObj.brokerEventId,
      id: this.opportunityId,
      linkerId: this.loanCalculatorLinking.LINKERS.OPPORTUNITY,
      title: this.opportunityTitle,
      isEdit: true,
      isOpportunityOrLoanapp: true,
      previousRouteStateParams: {
        ...this.$state.params,
        tabName: this.tabName || 'calculations',
        lendingCategory: this.summary.LendingCategoryId,
        familyFullName,
      },
      primaryPurposeId:
        primaryPurpose?.answerInt.length && primaryPurpose.answerInt[0],
      clientIds,
      familyId,
      loanApplicationId: this.opportunityId,
    };

    this.openCalculationsPage(args);
    return args;
  }

  removeFamilyCalculation(familyId) {
    const tempCalculations = [...this.calculations];
    const newCalculations = tempCalculations.reduce((accum, calculation) => {
      if (parseToInt10(calculation.familyId) !== parseToInt10(familyId)) {
        accum.push(calculation);
      }
      return accum;
    }, []);
    this.calculations = newCalculations;
    return newCalculations;
  }

  removeCalculation($event, calculationObj, callback) {
    $event.stopPropagation();
    const tempCalculations = [...this.calculations];

    swal(
      {
        title: 'Delete Confirmation',
        text: `Are you sure want to delete this calculation?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, Delete Calculation',
        closeOnConfirm: true,
      },
      (response) => {
        if (!response) {
          return;
        }
        const calculationIdx = this.calculations.findIndex(
          (obj) => obj === calculationObj,
        );
        this.calculations.splice(calculationIdx, 1);

        const { typeOfCalculation, brokerEventId } = calculationObj;
        const isFundingCalculatorType =
          typeOfCalculation === CALCULATION_TYPE.FUNDING_CALCULATOR;

        const FUNDING_CALCULATOR = 1;
        const SERVICEABILITY_OR_PRODUCTFINDER = 2;

        const calculationTypeParam = isFundingCalculatorType
          ? FUNDING_CALCULATOR
          : SERVICEABILITY_OR_PRODUCTFINDER;
        this.contactService
          .savedCalculationsDelete(
            calculationTypeParam,
            this.$state.params.opportunityId,
            brokerEventId,
          )
          .then((result) => {
            if (!result.data) {
              this.calculations = tempCalculations;
              toastError(`There's a problem in deleting this.`);
            }
          })
          .then(() => callback && callback());
      },
    );
  }

  checkCalculationsList() {
    if (!this.calculations || !this.calculations.length) {
      this.calculations = [];
    }
  }

  addCalculation(calculationType, sourceFamilyId, eventId = 0) {
    this.checkCalculationsList();
    const familyId = sourceFamilyId || this.$state.params.sourceFamilyId;
    const {
      ProposedLoanAmount: proposedLoanAmount,
      SecurityAmount: securityAmount,
    } = this.summary;

    const tabName =
      calculationType === CALCULATION_TYPE.FUNDING_CALCULATOR
        ? 'funding'
        : lowercaseFirstLetter(calculationType);
    const args = {
      tabName,
      eventId,
      id: this.opportunityId,
      linkerId: this.loanCalculatorLinking.LINKERS.OPPORTUNITY,
      title: this.opportunityTitle,
      isEdit: false,
      isOpportunityOrLoanapp: true,
      previousRouteStateParams: {
        ...this.$state.params,
        tabName: this.tabName || 'calculations',
        lendingCategory: this.summary.LendingCategoryId,
      },
      proposedLoanAmount,
      securityAmount,
      familyId,
      paramType: 'opportunity',
    };

    this.openCalculationsPage(args);
    return args;
  }

  openCalculationsPage(args) {
    if (args.tabName === 'funding') {
      this.loanCalculatorLinking.redirectToFundingCalculator(args);
    } else {
      this.loanCalculatorLinking.redirectToLoanProfiler(args);
    }
  }

  setServiceabilityMappedData(data) {
    this.serviceabilityMappedData = data;
  }

  getServiceabilityMappedData() {
    return this.serviceabilityMappedData;
  }

  calculateServiceability(calculation) {
    if (this.currentUserService.isNZ) {
      return this.loanProfilerService
        .getServiceabilityLendersList(calculation.brokerEventId, 0)
        .then(
          ({ data }) => {
            return (
              serviceabilityBuilderForUI(getMaxLoanAmountLender(data)) || {}
            );
          },
          () =>
            toastError(
              'Yikes! Something is wrong. Please hit Calculate again.',
            ),
        );
    }

    return this.loanProfilerService
      .getServiceabilityNextGenAccessment(calculation.brokerEventId, 0)
      .then(
        ({ data }) => {
          return serviceabilityBuilderForUI(getMaxLoanAmountLender(data)) || {};
        },
        () =>
          toastError('Yikes! Something is wrong. Please hit Calculate again.'),
      );
  }
}
