/* CLEARFIELD FOR LOAN CALCULATOR REQUIREMENTS:
  INPUTS MUST HAVE A UNIQUE ¬ID¬ IN ITS OWN HTML,
  SAMPLE HTML CODE
  <input clear-field value-to-clear="goal.initialDeposit" ng-model="goal.initalDeposit" id="thisShouldBeUniquePerPage" />
  <button clear-field>BUTTON FOR THE TRIGGER TO RESET THE INPUT WHEN FOCUSED!</button>
  made by Peter Anthony Duot
*/
import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('clearField', function clearField(
      $window,
      loanCalculatorSharedData,
    ) {
      return {
        restrict: 'A',
        scope: {
          valueToClear: '=',
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        link(scope, element) {
          angular.element($window.document).ready(() => {
            const nodeElem = element.length > 0 ? element[0] : null;
            if (!nodeElem) {
              return;
            }
            const theElementId = nodeElem.id;
            const clearFieldVals = {};
            let elementIndex = 0;
            let originalValue = 0;
            angular.extend(clearFieldVals, {
              isCalculated: false,
              isChanged: false,
              theElementId: '',
              originalValue: 0,
              newValue: 0,
            });
            /* setting the default values of clearFields */
            if (!_.isEmpty(nodeElem.id) && nodeElem.localName !== 'button') {
              clearFieldVals.isCalculated = false;
              clearFieldVals.isChanged = false;
              clearFieldVals.originalValue = scope.valueToClear;
              clearFieldVals.newValue = scope.valueToClear;
              clearFieldVals.theElementId = theElementId;
              loanCalculatorSharedData.valueSet.push(clearFieldVals);
            }
            /* checking if valueToClear is not undefined */
            if (!_.isUndefined(scope.valueToClear)) {
              nodeElem.addEventListener('focus', () => {
                elementIndex = scope.getIndex();
                if (
                  loanCalculatorSharedData.valueSet[elementIndex]
                    .isCalculated === false
                ) {
                  originalValue = scope.valueToClear;
                  scope.valueToClear = '';
                  loanCalculatorSharedData.valueSet[elementIndex].newValue = '';
                }
              });
              nodeElem.addEventListener('blur', () => {
                if (
                  loanCalculatorSharedData.valueSet[elementIndex].newValue !==
                  loanCalculatorSharedData.valueSet[elementIndex].originalValue
                ) {
                  loanCalculatorSharedData.valueSet[
                    elementIndex
                  ].isChanged = true;
                  loanCalculatorSharedData.valueSet[elementIndex].newValue =
                    scope.valueToClear;
                }
                if (
                  _.isEmpty(
                    loanCalculatorSharedData.valueSet[elementIndex]
                      .originalValue,
                  ) ||
                  !loanCalculatorSharedData.valueSet[elementIndex].originalValue
                ) {
                  loanCalculatorSharedData.valueSet[
                    elementIndex
                  ].originalValue = originalValue;
                }
                loanCalculatorSharedData.valueSet[
                  elementIndex
                ].isCalculated = true;
                if (
                  (_.isEmpty(
                    loanCalculatorSharedData.valueSet[elementIndex].newValue,
                  ) ||
                    !loanCalculatorSharedData.valueSet[elementIndex]
                      .newValue) &&
                  loanCalculatorSharedData.valueSet[elementIndex].originalValue
                ) {
                  scope.valueToClear =
                    loanCalculatorSharedData.valueSet[
                      elementIndex
                    ].originalValue;
                  loanCalculatorSharedData.valueSet[
                    elementIndex
                  ].isCalculated = false;
                }
                if (
                  loanCalculatorSharedData.valueSet[elementIndex].isChanged ===
                  false
                ) {
                  loanCalculatorSharedData.valueSet[elementIndex].newValue =
                    loanCalculatorSharedData.valueSet[
                      elementIndex
                    ].originalValue;
                }
              });
            }
            angular.extend(scope, {
              setAllIsCalculatedToFalse() {
                _.forEach(loanCalculatorSharedData.valueSet, (object) => {
                  object.isCalculated = false;
                  object.isChanged = false;
                  object.originalValue = object.newValue;
                  if (object.newValue === '' || _.isEmpty(object.newValue)) {
                    const node = angular.element(`#${object.theElementId}`);
                    const elem = node.length > 0 ? node[0] : null;
                    if (!elem) {
                      return;
                    }
                    elem.value = object.originalValue;
                  }
                });
              },
              getIndex() {
                return _.findIndex(
                  loanCalculatorSharedData.valueSet,
                  (object) => {
                    return object.theElementId === theElementId;
                  },
                );
              },
            });
            if (nodeElem.localName === 'button') {
              element.on('click', () => {
                scope.setAllIsCalculatedToFalse();
              });
            }
          });
        },
      };
    });
