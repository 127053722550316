import {
  kiwiSelectFundBuilderForUI,
  kiwiSelectProviderBuilderForUI,
} from 'Common/mappers/kiwiSelect';

export default class KiwiService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'referral';
  }

  getkiwiSelectProvider() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/kiwi-select-provider`)
      .then(
        ({ data }) => (data && data.map(kiwiSelectProviderBuilderForUI)) || [],
      );
  }

  getkiwiSelectFundType() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/kiwi-select-fund-type`)
      .then(({ data }) => (data && data.map(kiwiSelectFundBuilderForUI)) || []);
  }

  postKiwiReferral(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ReferralKiwiSelectSet`,
      data,
    );
  }
}
