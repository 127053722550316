import template from './changeColor.html';
import controller from './changeColorCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    color: '<',
    onColorChange: '&',
    isNew: '<',
    customClass: '<',
  },
};
