import angular from 'angular';
import _ from 'lodash';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { TIME_PERIODS } from 'Common/constants/brokerSupportTimePeriods';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('BrokerSupportRequestCtrl', function BrokerSupportRequestCtrl(
      $scope,
      NgTableParams,
      $filter,
      $uibModal,
      corporateService,
      $timeout,
      $rootScope,
      toaster,
      brokerSupportService,
      $localStorage,
      DEMO_VIDEO,
      $state,
      userService,
      SweetAlert,
    ) {
      $scope.openModalForDemoVideo = (size) => {
        $uibModal.open({
          templateUrl: '/assets/views/partials/demo_video_modal.html',
          controller: 'DemoVideoModalCtrl',
          resolve: {
            videoId() {
              return DEMO_VIDEO.BROKER_SUPPORT.Url;
            },
          },
          size,
          windowClass: 'demo-video-modal-window-class',
        });
      };

      $scope.brokerSupportModel = {};
      $scope.totalSpend = 0;
      $scope.totalProgress = 0;
      $scope.totalClosed = 0;

      $scope.timePeriod = [];

      let familyId = 0;
      let userAccessType = 0;
      let userInfo = {};
      $scope.brokerSupportModel.isCorporateUser = false;
      const getUserInfo = () => {
        userService.GetUserInfo().then((response) => {
          userInfo = response.data;
          familyId = userInfo.FamilyId;
          userAccessType = parseInt(userInfo.AccessType, 10);
          $scope.brokerSupportModel.isCorporateUser =
            userAccessType === ACCESS_TYPE.CORPORATE;
        });
      };
      getUserInfo();

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getSortColumnValue = (value) => {
        let returnValue = 0;
        switch (value) {
          case 'Default':
            returnValue = 0;
            break;
          case 'UpdatedAt':
            returnValue = 1;
            break;
          case 'CreatedAt':
            returnValue = 2;
            break;
          case 'Priority':
            returnValue = 3;
            break;
          case 'Status':
            returnValue = 4;
            break;
          case 'TicketType':
            returnValue = 5;
            break;
          default:
        }
        return returnValue;
      };

      const getTableList = () => {
        $scope.tableParams = new NgTableParams(
          {
            page: 1,
            count: 10, // count per page
            sorting: {
              Default: 'desc', // initial sorting
            },
          },
          {
            total: 0, // length of data
            getData(params) {
              $scope.isRequestLoading = true;
              const sortColumn = params.orderBy()[0].slice(1);
              const sortType =
                _.values(params.sorting())[0].toLowerCase() === 'desc' ? 0 : 1;
              const status = 0;
              const period = $scope.brokerSupportModel.timePeriodValue;
              const selectedAdviserId = parseInt(
                $scope.brokerSupportModel.selectedAdviser,
                10,
              );
              const adviserFamilyId =
                selectedAdviserId > 0
                  ? $scope.brokerSupportModel.selectedAdviser
                  : 0;
              let showAll = 0;
              if (selectedAdviserId > 0) {
                showAll = 0;
              } else if (userAccessType === ACCESS_TYPE.CORPORATE) {
                showAll = 1;
              }
              return brokerSupportService
                .getBrokerSupportRequestList(
                  params.count(),
                  params.page(),
                  getSortColumnValue(sortColumn),
                  sortType,
                  status,
                  period,
                  adviserFamilyId,
                  showAll,
                )
                .then((response) => {
                  $scope.isRequestLoading = false;
                  const data = response.data.Tickets;
                  params.total(response.data.Count);
                  $scope.totalSpend = response.data.TotalSupportFees;
                  $scope.totalProgress = response.data.InProgress || 0;
                  $scope.totalClosed = response.data.Closed || 0;
                  return data;
                })
                .catch(() => {
                  $scope.isRequestLoading = false;
                });
            },
          },
        );
      };

      const getAdviserList = () => {
        const timePeriod = $scope.brokerSupportModel.timePeriodValue;
        const status = 0;
        const crmFamilyId =
          userAccessType === ACCESS_TYPE.ADVISER ? familyId : 0;
        const showAll = userAccessType === ACCESS_TYPE.CORPORATE ? 1 : 0;
        brokerSupportService
          .advisersWithTicketGet(status, timePeriod, crmFamilyId, showAll)
          .then((response) => {
            if (userAccessType !== ACCESS_TYPE.ADVISER) {
              const obj = {
                Name: 'All Adviser',
                Email: '',
                CrmFamilyId: 0,
              };
              $scope.brokerSupportModel.adviserList = response.data;
              $scope.brokerSupportModel.adviserList = [
                obj,
                ...$scope.brokerSupportModel.adviserList,
              ];
              $scope.brokerSupportModel.selectedAdviser = 0;
            } else {
              $scope.brokerSupportModel.adviserList = [
                {
                  Name: userInfo.FullName,
                  Email: '',
                  CrmFamilyId: familyId,
                },
              ];
              $scope.brokerSupportModel.selectedAdviser = familyId;
            }
            getTableList();
          });
      };

      const getTimePeriods = () => {
        brokerSupportService.timePeriods().then((response) => {
          const data = response.data;
          if (data && data.length) {
            $scope.timePeriod = data;
            $scope.brokerSupportModel.timePeriodValue = TIME_PERIODS.THIS_MONTH;
            getAdviserList();
          }
        });
      };

      getTimePeriods();

      $scope.changeAdviser = () => {
        if (typeof $scope.tableParams !== 'undefined') {
          $scope.tableParams.reload();
        } else {
          getTableList();
        }
      };

      $scope.timePeriodChange = () => {
        getAdviserList();
        $scope.isRequestLoading = true;
      };

      $scope.requestBrokerSupport = () => {
        if ($scope.brokerSupportModel.isCorporateUser) {
          $state.go('app.newRequest');
        } else {
          corporateService
            .familyBrokerSupportGet(familyId, 0)
            .then((response) => {
              const offShoring = response.data
                ? response.data.Offshoring || 0
                : 0;
              if (offShoring > 0) {
                $state.go('app.newRequest');
              } else {
                SweetAlert.swal(
                  'Offshoring Error Message',
                  'Broker Support Offshoring is not enabled. Please contact brokersupport@loanmarket.com.au',
                  'error',
                );
              }
            });
        }
      };
    });
