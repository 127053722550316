import angular from 'angular';
import $ from 'jquery';

export const loadModule = () =>
  angular.module('app').directive('addTag', function addTag() {
    return {
      restrict: 'A',
      link($scope, $element) {
        $element.bind('click', function () {
          const e = $.Event('keydown');
          const inputTag = $(this)
            .parent()
            .prev('.tag-input-entity')
            .find('input.input');
          e.which = 13;
          inputTag.trigger(e).val('');
        });
      },
    };
  });
