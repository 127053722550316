import { formatUTCDate } from 'Common/utilities/date';
import { clientColumnContent } from '../../contactAddressTable/util/contactAddressTable';

export const documentTypeColumnContent = (docType) => {
  return `
    <div>${docType}</div>
  `;
};

export const expiryDateColumnContent = (expDate) => {
  return `
    <div><icon-calendar></icon-calendar>${formatUTCDate('DD MMM YYYY')(
      expDate,
    )}</div>
  `;
};

export const createIdentificationColumnContent = (
  identification,
  filterInitialOneString,
) => {
  return identification.map((identificationRow) => {
    return {
      client: clientColumnContent([
        {
          ...identificationRow,
          initials: filterInitialOneString(
            `${identificationRow.ClientFirstName} ${identificationRow.ClientLastName}`,
          ),
        },
      ]),
      documentType: documentTypeColumnContent(
        identificationRow.DocumentTypeName,
      ),
      expiryDate: expiryDateColumnContent(identificationRow.ExpiryDate),
      editParams: {
        modalType: 'edit',
        identification: identificationRow,
        familyId: identificationRow.FamilyId,
      },
      deleteParams: {
        identificationId: identificationRow.IdentificationId,
      },
    };
  });
};
