import template from './opportunitySendEmailModal.html';
import controller from './opportunitySendEmailModalCtrl';
import './style.scss';

export default {
  template,
  bindings: {
    email: '<',
    loanId: '<',
    familyId: '<',
    recipientsChoices: '<',
    modalInstance: '<',
  },
  controller,
  controllerAs: 'vm',
};
