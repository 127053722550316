import tagManager from 'Common/utilities/tagManager';

export const performanceTimingData = async ({
  event = 'timing',
  action,
  label = '',
  value = 0,
}) => {
  const asyncValue = await value;
  return tagManager({
    event,
    action,
    label,
    value: asyncValue,
  });
};

export const loanAppSectionData = ({ label, value }) =>
  performanceTimingData({
    action: 'loan-app-accordion',
    label,
    value,
  });
