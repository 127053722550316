import { uniqBy, get } from 'lodash';

export const isDataLengthChanged = (oldData, newData) => {
  const checks = oldData && oldData.length && newData && newData.length;
  if (!checks) {
    return null;
  }

  return oldData.length !== newData.length;
};

export const clearPreferredAdviser = ($scope) => {
  $scope.referrerModel.preferredAdviserList = [];
  $scope.referrerModel.preferredAdviserListRaw = [];
  $scope.newReferrer.IncomingLeadPreferredAdviserId = 0;
};

export const assignReferrerPreferredAdviser = ({
  $scope,
  corporateModelService,
}) => {
  if (!$scope.initialLoad) {
    return Promise.resolve([]);
  }
  $scope.initialLoad = false;
  const referrers = $scope.newReferrer.ReffererOrgReferrersList || [];
  const referrerRequest = referrers.reduce((accum, referrer) => {
    const existingAdviser =
      $scope.referrerModel.preferredAdviserList &&
      $scope.referrerModel.preferredAdviserList.find(
        (adviser) => adviser.adviserId === referrer.PreferredAdviserId,
      );
    if (!referrer.PreferredAdviserId || existingAdviser) {
      return accum;
    }
    const params = {
      referrerOrgId: $scope.newReferrer.pReferrerOrgId,
      referrerId: referrer.FamilyID,
      agreementTypeId: $scope.newReferrer.pAgreementTypeID,
    };
    return accum.concat(
      corporateModelService.getAgreementReferrerPreferredAdviser(params),
    );
  }, []);
  if (!referrerRequest.length) {
    return Promise.resolve([]);
  }
  return Promise.all(referrerRequest).then((data) => {
    if (!data || !data.length) {
      return [];
    }
    $scope.referrerModel.preferredAdviserList = data.reduce(
      (accum, advisers) => {
        const adviser = advisers && advisers[0];
        const existingAdviser = accum.find(
          (existing) => existing.adviserId === adviser.adviserId,
        );
        if (existingAdviser) {
          return accum;
        }
        return accum.concat(adviser);
      },
      [...$scope.referrerModel.preferredAdviserList],
    );
    $scope.referrerModel.preferredAdviserListRaw = [
      ...$scope.referrerModel.preferredAdviserList,
    ];
  });
};

export const assignPreferredAdviser = ($scope) => {
  const preferredAdviser =
    $scope.referrerModel.preferredAdviserList &&
    $scope.referrerModel.preferredAdviserList.find(
      (adviser) => adviser.referrerOrgPreferredAdviserId,
    );
  $scope.newReferrer.IncomingLeadPreferredAdviserId =
    (preferredAdviser && preferredAdviser.adviserId) || 0;
};

export const checkForPreferredAdviser = ({ $scope, corporateModelService }) => {
  const referrerId =
    ($scope.raywhiteAdviser &&
      $scope.raywhiteAdviser.selected &&
      $scope.raywhiteAdviser.selected.id) ||
    0;
  const isValid =
    $scope.newReferrer.pReferrerOrgId &&
    referrerId &&
    $scope.newReferrer.pAgreementTypeID;
  if ($scope.raywhiteAdviser.preferredAdviserId || !isValid) {
    return;
  }
  const params = {
    referrerOrgId: $scope.newReferrer.pReferrerOrgId,
    referrerId,
    agreementTypeId: $scope.newReferrer.pAgreementTypeID,
  };
  corporateModelService
    .getAgreementReferrerPreferredAdviser(params)
    .then((data) => {
      if (!data || !data.length) {
        return;
      }
      const preferredAdvisers = [
        ...$scope.referrerModel.preferredAdviserList,
        ...data,
      ];
      $scope.referrerModel.preferredAdviserList = uniqBy(
        preferredAdvisers,
        'adviserId',
      );
      $scope.raywhiteAdviser.preferredAdviserId = get(
        data,
        '0.adviserId',
        null,
      );
      $scope.referrerModel.preferredAdviserListRaw = [
        ...$scope.referrerModel.preferredAdviserList,
      ];
    });
};
