import angular from 'angular';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';
import { WIDGET_COMPONENT } from 'Common/constants/widgetComponent';
import { showBusinessPlanningDashboard } from 'Common/utilities/showFeature';
import { DEFAULT_DASHBOARD_TAB } from 'Common/constants/businessPlanningDashboard';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { NZ_USER_WIDGET_FLAG } from 'Common/constants/dashboardWidget';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('OverviewDashboardCtrl', function OverviewDashboardCtrl(
      $scope,
      $rootScope,
      configService,
      $filter,
      $timeout,
      overviewDashboardService,
      $uibModal,
      DEMO_VIDEO,
      currentUserService,
      contactService,
      $state,
    ) {
      $scope.currentUserService = currentUserService;
      $scope.contactService = contactService;
      const {
        statisticalDashboard,
        businessPlanningDashboard,
        nzLendingTools,
      } = configService.feature;
      $scope.showStatisticalDashboard = statisticalDashboard;
      $scope.showNZLendingTools = nzLendingTools;
      $scope.showBusinessPlanningDashboard = showBusinessPlanningDashboard(
        businessPlanningDashboard,
        currentUserService,
      );
      $scope.showBusinessPlanningDashboard &&
        $state.go('app.dashboardV2', { activeTab: DEFAULT_DASHBOARD_TAB });
      $scope.openModalForDemoVideo = function (size) {
        $uibModal.open({
          templateUrl: '/assets/views/partials/demo_multi_video_modal.html',
          scope: $scope,
          controller($uibModalInstance) {
            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
            $scope.headerName = DEMO_VIDEO.DASHBOARD.Header_Name;
            $scope.videoList = DEMO_VIDEO.DASHBOARD.Video_List;
            $scope.videoId = DEMO_VIDEO.DASHBOARD.Video_List[0].Url;
            $scope.isActive = function (id) {
              return id === $scope.videoId;
            };

            // For Change Video URL
            $scope.changeVideoUrl = function (url) {
              $scope.videoId = url;
              const $url = `https://player.vimeo.com/video/${$scope.videoId}?player_id=video1`;
              angular.element('#video-player iframe').attr('src', $url);
            };
          },
          size,
          windowClass: 'demo-video-modal-window-class multi-demo-video',
        });
      };
      $scope.testData = [1, 2, 3];
      $scope.isEditMode = false;
      $scope.hideAddedIndex = [];
      $scope.showDeletedIndex = [];
      const buttonHtml =
        '  <i class="fa fa-times-circle-o widgetDeleteBtn" ng-click="deleteItems(\'replace\')"></i>';
      $scope.widget = {
        widgetList: [],
      };
      const mapping = WIDGET_COMPONENT;
      $scope.deleteItems = function (items) {
        const itemsD = items.slice(0, -1);
        const itemsToRemove = itemsD.split(',');
        $scope.showDeletedIndex.push(parseInt(itemsToRemove[0], 10));
        for (const element of itemsToRemove) {
          const index = parseInt(element, 10);
          $scope.layoutsW[index].isDeleted = true;
        }
      };

      $scope.filterWidgetByUser = function (layout = []) {
        const {
          isNZ,
          isRiskWriter,
          isLoanWriter,
          isSuperAdmin,
          isCorporate,
        } = $scope.currentUserService;

        const isNZRiskLoanAdviser =
          isNZ &&
          (isRiskWriter || isLoanWriter) &&
          !isSuperAdmin &&
          !isCorporate &&
          $scope.showNZLendingTools;

        if (isNZRiskLoanAdviser) {
          layout = layout.filter((item) => {
            const foundWidget = NZ_USER_WIDGET_FLAG.find((obj) => {
              return obj.widgetId === parseInt(item.WidgetId, 10);
            });
            if (foundWidget) {
              const isFoundForUser =
                (isLoanWriter && foundWidget.showForLoanWriter) ||
                (isRiskWriter && foundWidget.showForRiskWriter);

              if (isFoundForUser) {
                return item;
              }
            } else {
              return item;
            }
          });
        }
        return layout;
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.generateHtml = function () {
        overviewDashboardService.LayoutGet().then(
          (response) => {
            $scope.layouts = $scope.filterWidgetByUser(response.data);
            $scope.layouts = $filter('orderBy')($scope.layouts, 'Position');
            let smCounter = 0;
            let lgCounter = 0;
            let htmlStr = '';
            let widgetStr = '';
            for (let i = 0; i < $scope.layouts.length; i++) {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              if ($scope.layouts[i].Category === 'Small Widget') {
                if (lgCounter !== 0) {
                  for (let m = 0; m < 2 - lgCounter; m++) {
                    htmlStr +=
                      '<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10"></div>';
                  }
                  lgCounter = 0;
                  htmlStr += '</div>';
                }
                if (smCounter === 0) {
                  htmlStr += '<div class="row stat-height">';
                }
                smCounter++;
                widgetStr = mapping[$scope.layouts[i].WidgetId];
                if (widgetStr === null || widgetStr === undefined) {
                  widgetStr = '';
                } else {
                  widgetStr = widgetStr.replace('testData', `layouts[${i}]`);
                }
                htmlStr += `<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 wrap-dashboard-widget full-widget">${widgetStr}</div>`;
                if (smCounter === 4) {
                  smCounter = 0;
                  htmlStr += '</div>';
                }
              } else if ($scope.layouts[i].Category !== 'Small Widget') {
                if (smCounter !== 0) {
                  for (let j = 0; j < 4 - smCounter; j++) {
                    htmlStr +=
                      '<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 wrap-dashboard-widget full-widget"></div>';
                  }
                  smCounter = 0;
                  htmlStr += '</div>';
                }
                if (lgCounter === 0) {
                  htmlStr += '<div class="row stat-height">';
                }
                lgCounter++;
                widgetStr = mapping[$scope.layouts[i].WidgetId];
                if (widgetStr === null || widgetStr === undefined) {
                  widgetStr = '';
                } else {
                  widgetStr = widgetStr.replace('testData', `layouts[${i}]`);
                }
                htmlStr += `<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10  full-widget">${widgetStr}</div>`;
                if (lgCounter === 2) {
                  lgCounter = 0;
                  htmlStr += '</div>';
                }
              }
            }
            $scope.bindedDir = htmlStr;
            $scope.showBinded = true;
          },
          () => {},
        );
      };
      $scope.addSmallWidgetRow = function () {
        $scope.addSmallWButtonActive = false;
        let i = $scope.layoutsW.length - 1;
        let lastItem = $scope.layoutsW[$scope.layoutsW.length - 1];
        if (lastItem === undefined) {
          lastItem = {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            Category: 'Big Widget',
            Position: 0,
            WidgetId: '13',
            WidgetName: 'Tasks',
            Widget_UserID: '0',
          };

          $scope.layoutListSm = $scope.filterWidgetByUser($scope.layoutListSm);
          lastItem.dropDownOption = $scope.layoutListSm;
          i = -1;
        }
        let posToAssign = lastItem.Position + 1;
        let deletableIndices = '';
        let newHtml = '';
        let m;
        if (
          $scope.layoutsW.length > 0 &&
          $scope.layoutsW[i].Category === 'Small Widget'
        ) {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          newHtml += '<div class="row">';
          for (m = 0; m < 2; m++) {
            newHtml +=
              // eslint-disable-next-line sonarjs/no-duplicate-string
              '<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"></div>';
          }
          newHtml += '</div>';
          $scope.rowCounter++;
          posToAssign += 2;
        }
        newHtml += `<div class="row margin-top-15" style='border: 1px dashed #b0afb2'  ng-hide="layoutsW[${
          i + 1
        }].isDeleted">`;
        $scope.rowCounter++;
        for (m = 0; m < 4; m++) {
          const newObj = {
            WidgetId: '0',
            WidgetName: 'None',
            Widget_UserID: '0',
            Position: posToAssign++,
            Category: 'Small Widget',
            dropDownOption: $scope.layoutListSm,
          };
          $scope.layoutsW.push(newObj);
          newHtml += `<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"><small-widget-settings my-param="layoutsW[${++i}]" index-in-array="0"></small-widget-settings></div>`;
          deletableIndices += `${i},`;
        }
        let htmlToPlace = buttonHtml;
        htmlToPlace = htmlToPlace.replace('replace', deletableIndices);
        newHtml += htmlToPlace;
        deletableIndices = '';
        newHtml += '</div>';
        $scope.bindedDirSettings += newHtml;
        $scope.htmlToAdd = newHtml;
        const forceCompileTimeout = $timeout(() => {
          $rootScope.$broadcast('forceCompile');
          $timeout.cancel(forceCompileTimeout);
        });

        $scope.addSmallWButtonActive = true;
      };
      $scope.addBigWidgetRow = function () {
        $scope.addBigWButtonActive = false;
        let i = $scope.layoutsW.length - 1;
        let lastItem = $scope.layoutsW[$scope.layoutsW.length - 1];
        if (lastItem === undefined) {
          lastItem = {
            Category: 'Big Widget',
            Position: 0,
            WidgetId: '13',
            WidgetName: 'Tasks',
            Widget_UserID: '0',
          };

          $scope.layoutListSm = $scope.filterWidgetByUser($scope.layoutListSm);
          $scope.layoutListLg = $scope.filterWidgetByUser($scope.layoutListLg);

          lastItem.dropDownOption = $scope.layoutListSm;
          i = -1;
        }
        let posToAssign = lastItem.Position + 1;
        let deletableIndices = '';
        let newHtml = '';
        let m;
        if (
          $scope.layoutsW.length > 0 &&
          $scope.layoutsW[i].Category !== 'Small Widget'
        ) {
          newHtml += '<div class="row">';
          for (m = 0; m < 4; m++) {
            newHtml +=
              // eslint-disable-next-line sonarjs/no-duplicate-string
              '<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"></div>';
          }
          newHtml += '</div>';
          $scope.rowCounter++;
          posToAssign += 4;
        }
        newHtml += `<div class="row margin-top-15" style='border: 1px dashed #b0afb2'  ng-hide="layoutsW[${
          i + 1
        }].isDeleted">`;
        $scope.rowCounter++;
        for (m = 0; m < 2; m++) {
          const newObj = {
            WidgetId: '0',
            WidgetName: '',
            Widget_UserID: '0',
            Position: posToAssign++,
            Category: 'Big Widget',
            dropDownOption: $scope.layoutListLg,
          };
          $scope.layoutsW.push(newObj);
          newHtml += `<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"><big-widget-settings my-param="layoutsW[${++i}]" index-in-array="0"></big-widget-settings></div>`;
          deletableIndices += `${i},`;
        }
        let htmlToPlace = buttonHtml;
        htmlToPlace = htmlToPlace.replace('replace', deletableIndices);
        newHtml += htmlToPlace;
        deletableIndices = '';
        newHtml += '</div>';
        $scope.bindedDirSettings += newHtml;
        $scope.htmlToAdd = newHtml;
        const forceCompileTimeout = $timeout(() => {
          $rootScope.$broadcast('forceCompile');
          $timeout.cancel(forceCompileTimeout);
        });
        $scope.addBigWButtonActive = true;
      };
      // eslint-disable-next-line sonarjs/cognitive-complexity
      const LayoutListGet = (layout) => {
        overviewDashboardService.LayoutListGet(1).then(
          (res) => {
            $scope.layoutListSm = res.data;
            const dropdownFirstItemSmall = {
              WidgetId: '0',
              WidgetName: 'None',
              Category: 'Small Widget',
            };

            $scope.layoutListSm.splice(0, 0, dropdownFirstItemSmall);
            $scope.layoutListSm = $scope.filterWidgetByUser(
              $scope.layoutListSm,
            );
            overviewDashboardService.LayoutListGet(5).then(
              (overviewDashboardServiceResponse) => {
                $scope.layoutListLg = overviewDashboardServiceResponse.data;
                $scope.layoutListLg = $scope.filterWidgetByUser(
                  $scope.layoutListLg,
                );
                const layoutListTimeout = $timeout(() => {
                  const dropdownFirstItemBig = {
                    WidgetId: '0',
                    WidgetName: 'None',
                    Category: 'Big Widget',
                  };
                  $scope.layoutListLg.splice(0, 0, dropdownFirstItemBig);
                  $timeout.cancel(layoutListTimeout);
                });
                let expectingWidgetRow = 'sm';
                $scope.layoutsW = layout;
                $scope.layoutsW = $filter('orderBy')(
                  $scope.layoutsW,
                  'Position',
                );
                let smCounter = 0;
                let lgCounter = 0;
                $scope.rowCounter = 0;
                let htmlStr = '';
                let deletableIndices = '';
                let m;
                let j;
                let htmlToPlace;
                for (let i = 0; i < $scope.layoutsW.length; i++) {
                  if ($scope.layoutsW[i].Category === 'Small Widget') {
                    if (lgCounter !== 0) {
                      for (m = 0; m < 2 - lgCounter; m++) {
                        htmlStr +=
                          '<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"></div>';
                      }
                      htmlToPlace = buttonHtml;
                      htmlToPlace = htmlToPlace.replace(
                        'replace',
                        deletableIndices,
                      );
                      htmlStr += htmlToPlace;
                      deletableIndices = '';
                      lgCounter = 0;
                      htmlStr += '</div>';
                      expectingWidgetRow = 'sm';
                    }
                    if (expectingWidgetRow === 'lg') {
                      htmlStr += '<div class="row">';
                      for (m = 0; m < 2; m++) {
                        htmlStr +=
                          '<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"></div>';
                      }
                      htmlStr += '</div>';
                      $scope.rowCounter++;
                      expectingWidgetRow = 'sm';
                    }
                    if (smCounter === 0) {
                      htmlStr += `<div class="row margin-top-15" style='border: 1px dashed #b0afb2'  ng-hide="layoutsW[${i}].isDeleted"   >`;
                      $scope.rowCounter++;
                    }
                    const overviewCategory = $scope.layoutListSm.filter(
                      (item) => item.WidgetId === $scope.layoutsW[i].WidgetId,
                    )[0];
                    if (overviewCategory) {
                      $scope.layoutsW[i].Category = overviewCategory.Category;
                    }

                    $scope.layoutListSm = $scope.filterWidgetByUser(
                      $scope.layoutListSm,
                    );
                    $scope.layoutsW[i].dropDownOption = $scope.layoutListSm;
                    smCounter++;
                    htmlStr += `<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"><small-widget-settings my-param="layoutsW[${i}]" index-in-array="${i}"></small-widget-settings></div>`;
                    deletableIndices += `${i},`;
                    if (smCounter === 4) {
                      htmlToPlace = buttonHtml;
                      htmlToPlace = htmlToPlace.replace(
                        'replace',
                        deletableIndices,
                      );
                      htmlStr += htmlToPlace;
                      deletableIndices = '';
                      smCounter = 0;
                      htmlStr += '</div>';
                      htmlStr += '</div>';
                      expectingWidgetRow = 'lg';
                    }
                  } else if ($scope.layoutsW[i].Category !== 'Small Widget') {
                    if (smCounter !== 0) {
                      for (j = 0; j < 4 - smCounter; j++) {
                        htmlStr +=
                          '<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"></div>';
                      }
                      htmlToPlace = buttonHtml;
                      htmlToPlace = htmlToPlace.replace(
                        'replace',
                        deletableIndices,
                      );
                      htmlStr += htmlToPlace;
                      deletableIndices = '';
                      smCounter = 0;
                      htmlStr += '</div>';
                      expectingWidgetRow = 'lg';
                    }
                    if (expectingWidgetRow === 'sm') {
                      htmlStr += '<div class="row">';
                      for (m = 0; m < 4; m++) {
                        htmlStr +=
                          '<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"></div>';
                      }
                      htmlStr += '</div>';
                      $scope.rowCounter++;
                      expectingWidgetRow = 'lg';
                    }
                    if (lgCounter === 0) {
                      htmlStr += `<div class="row margin-top-15"style='border: 1px dashed #b0afb2'  ng-hide="layoutsW[${i}].isDeleted"  >`;
                      $scope.rowCounter++;
                    }

                    const layoutListLg = $scope.layoutListLg.filter(
                      (item) => item.WidgetId === $scope.layoutsW[i].WidgetId,
                    );
                    $scope.layoutsW[i].Category =
                      (layoutListLg[0] && layoutListLg[0].Category) || '';

                    $scope.layoutListLg = $scope.filterWidgetByUser(
                      $scope.layoutListLg,
                    );
                    $scope.layoutsW[i].dropDownOption = $scope.layoutListLg;
                    lgCounter++;
                    htmlStr += `<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"><big-widget-settings my-param="layoutsW[${i}]"  index-in-array="${i}"></big-widget-settings></div>`;
                    deletableIndices += `${i},`;
                    if (lgCounter === 2) {
                      htmlToPlace = buttonHtml;
                      htmlToPlace = htmlToPlace.replace(
                        'replace',
                        deletableIndices,
                      );
                      htmlStr += htmlToPlace;
                      deletableIndices = '';
                      lgCounter = 0;
                      htmlStr += '</div>';
                      expectingWidgetRow = 'sm';
                    }
                  }
                }
                if (lgCounter !== 0) {
                  for (m = 0; m < 2 - lgCounter; m++) {
                    htmlStr +=
                      '<div class="col-md-6 col-sm-12 col-xs-12 padding-right-10 padding-left-10 margin-top-10"></div>';
                  }
                  htmlToPlace = buttonHtml;
                  htmlToPlace = htmlToPlace.replace(
                    'replace',
                    deletableIndices,
                  );
                  htmlStr += htmlToPlace;
                  deletableIndices = '';
                  lgCounter = 0;
                  htmlStr += '</div>';
                  expectingWidgetRow = 'sm';
                }
                if (smCounter !== 0) {
                  for (j = 0; j < 4 - smCounter; j++) {
                    htmlStr +=
                      '<div class="col-md-3 col-sm-6 col-xs-12 padding-right-10 padding-left-10 margin-top-10 wrap-dashboard-widget"></div>';
                  }
                  htmlToPlace = buttonHtml;
                  htmlToPlace = htmlToPlace.replace(
                    'replace',
                    deletableIndices,
                  );
                  htmlStr += htmlToPlace;
                  deletableIndices = '';
                  smCounter = 0;
                  htmlStr += '</div>';
                  expectingWidgetRow = 'lg';
                }
                $scope.bindedDirSettings = htmlStr;
                $scope.showBindedSettings = true;
                const forceCompileTimeout = $timeout(() => {
                  $rootScope.$broadcast('forceCompile');
                  $timeout.cancel(forceCompileTimeout);
                });
                $scope.settingsButtonActive = true;
              },
              () => {},
            );
          },
          () => {},
        );
      };
      const LayoutGet = () => {
        overviewDashboardService.LayoutGet().then(
          (response) => {
            $scope.layoutsW = [];
            $scope.layoutsW = $scope.filterWidgetByUser(response.data);
            const indicesToRemove = [];
            let i;
            for (i = 0; i < $scope.layoutsW.length; i++) {
              if ($scope.layoutsW[i].WidgetName === 'Outstanding Commissions') {
                indicesToRemove.push(i);
              }
            }
            for (i = indicesToRemove.length - 1; i >= 0; i--) {
              $scope.layoutsW.splice(indicesToRemove[i], 1);
            }
            LayoutListGet($scope.layoutsW);
          },
          () => {},
        );
      };
      const init = () => {
        $scope.addSmallWButtonActive = true;
        $scope.addBigWButtonActive = true;
        $scope.settingsButtonActive = true;
        $scope.showBinded = false;
        $scope.showBindedSettings = false;
        $scope.generateHtml();
        $scope.getSubscriptionInfo();
        LayoutGet();
      };
      $scope.saveChanges = function () {
        $scope.htmlToAdd = '';
        $scope.layoutsW = $scope.layoutsW.filter((item) => !item.isDeleted);
        overviewDashboardService
          .LayoutSet($scope.layoutsW)
          .then(() => {
            swal(
              'Updated',
              'Widgets have been Updated Successfully.',
              'success',
            );
            init();
            $scope.isEditMode = false;
          })
          .catch(displayError);
      };
      $scope.cancelChanges = function () {
        $scope.settingsButtonActive = false;
        $scope.isEditMode = false;
        $scope.htmlToAdd = '';
        LayoutGet();
      };
      const widgetCategoryUpdatedBroadcastHandler = $rootScope.$on(
        'categoryUpdated',
        () => {
          $scope.categoryUpdated = true;
        },
      );
      $scope.$on('$destroy', widgetCategoryUpdatedBroadcastHandler);
      $scope.toggleEditMode = function () {
        if ($scope.categoryUpdated === true) {
          $scope.categoryUpdated = false;
          init();
        }
        $scope.isEditMode = !$scope.isEditMode;
      };

      $scope.getSubscriptionInfo = () => {
        $scope.contactService
          .subscriptionInfo($scope.currentUserService.familyId)
          .then((response) => {
            $scope.isEsignEnabled =
              $scope.currentUserService.isAssistantType ||
              (response &&
                response.length &&
                response.some(
                  (item) =>
                    item.subscriptionProductId === SUBSCRIPTIONTYPE.E_SIGN &&
                    item.isActive,
                ));
          });
      };

      init();
    });
