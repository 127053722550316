import { goToOpportunity } from 'Common/utilities/loanOpportunity';

export default class OpportunityNewModalService {
  constructor(modalRenderService, $state) {
    'ngInject';

    this.modalRenderService = modalRenderService;
    this.$state = $state;
  }

  openNewOpportunityModal(
    familyIdParam,
    clients,
    redirectParams = {},
    pipelineItemId,
    isLeadsToOpportunity = false,
  ) {
    return this.modalRenderService
      .openNewOpportunityModal(
        familyIdParam,
        clients,
        pipelineItemId,
        isLeadsToOpportunity,
      )
      .result.then(
        (result) => {
          if (!result) {
            return;
          }
          const { loanId, opportunityId, familyId } = result;

          const {
            sourceFamilyId,
            sourceFamilyName,
            isContactOpener,
            tabName,
            isBusiness,
          } = redirectParams;

          const params = {
            loanId,
            familyId,
            opportunityId,
            sourceFamilyId: sourceFamilyId || 0,
            sourceFamilyName: sourceFamilyName || '',
            isContactOpener: isContactOpener || 0,
            tabName: tabName || 'details',
            isBusiness,
          };

          goToOpportunity(this.$state, params);

          return { isSuccess: true };
        },
        () => {
          return { isSuccess: false };
        },
      );
  }
}
