export const loadModule = () =>
  app.factory('jagoIntegrationService', [
    '$q',
    'jagoIntegrationModel',
    function ($q, jagoIntegrationModel) {
      var factory = this;

      var jagoIntegration = new jagoIntegrationModel();

      factory.GetJagoUrl = function () {
        var defer = $q.defer();
        jagoIntegration.GetJagoUrl(
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      return factory;
    },
  ]);
