import angular from 'angular';

export const loadModule = () =>
  angular.module('app').controller(
    'PreAssessPersonCompanyModalCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function PreAssessPersonCompanyModalCtrl(
      $scope,
      $uibModalInstance,
      utilitiesService,
      toaster,
      contactService,
      clientInformGet,
      getNewContactModel,
      selectedPartyType,
      genderService,
    ) {
      // Declaration
      $scope.ContactSet = getNewContactModel('Adult');
      $scope.BusinessSet = {};
      $scope.ContactSet.PartyType = selectedPartyType.toString();

      // Default
      $scope.saveText = 'Applicant';
      $scope.$watch('ContactSet.PartyType', (newVal) => {
        const intNewVal = parseInt(newVal, 10);
        if (intNewVal === 1) {
          $scope.saveText = 'Applicant';
        }
        if (intNewVal === 2) {
          $scope.saveText = 'Company';
        }
        if (intNewVal === 3) {
          $scope.saveText = 'Dependant';
        }
        if (intNewVal === 4) {
          $scope.saveText = 'Owner';
        }
      });

      $scope.titles = [
        { Value: 'Mr', Name: 'Mr' },
        { Value: 'Mrs', Name: 'Mrs' },
        { Value: 'Ms', Name: 'Ms' },
        { Value: 'Miss', Name: 'Miss' },
        { Value: 'Master', Name: 'Master' },
        { Value: 'Doctor', Name: 'Doctor' },
      ];

      $scope.$watch('ContactSet.Title', (newVal) => {
        genderService.changeGender(newVal, $scope.ContactSet.Gender);
      });

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.datePopUp = {
        DOB: false,
      };
      // Open Date Popup
      $scope.openDate = function (dateField) {
        $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
      };

      $scope.format = 'dd MMM yyyy';
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.dateOptions = {
        formatYear: 'yy',
        showWeeks: false,
      };

      // Save Client
      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.saveClientAccount = function (form) {
        if (form.$invalid) {
          let firstError = null;
          form &&
            Object.keys(form).forEach((field) => {
              if (field[0] !== '$') {
                if (firstError === null && !form[field].$valid) {
                  firstError = form[field].$name;
                }

                if (form[field].$pristine) {
                  form[field].$dirty = true;
                }
              }
            });

          angular.element(`.ng-invalid[name=${firstError}]`).focus();
          toaster.pop(
            'error',
            'Invalid Form',
            'Please fill up the form correctly',
          );
          return;
        }
        $scope.ContactSet.Phone = [
          { Type: 'Mobile', Number: $scope.ContactSet.MobileNumber },
        ];
        $scope.ContactSet.Email = [
          { Type: 'Work', Email: $scope.ContactSet.EmailAddress },
        ];
        $scope.ContactSet.FamilyId = '0';
        $scope.ContactSet.PersonId = '0';

        if (parseInt($scope.CountryCode, 10) === -1) {
          $scope.ContactSet.CountryOfBirth = $scope.CitizenshipCountryManual;
        } else {
          $scope.ContactSet.CountryOfBirth = $scope.CountryCode;
        }

        contactService.addContact([$scope.ContactSet]).then((response) => {
          const FamilyId = response.data;
          $uibModalInstance.close({
            FamilyId,
            PartyType: $scope.ContactSet.PartyType,
          });
          $scope.uibReturnedValue = $uibModalInstance.result.$$state.value;

          clientInformGet($scope.uibReturnedValue);
        });
      };

      $scope.saveBusinessAccount = function (form) {
        if (form.$invalid) {
          toaster.pop(
            'error',
            'Invalid Form',
            'Please fill up the form correctly',
          );
          $scope.prevStep();
          $scope.canSave = true;
          return false;
        }

        // return true;
        const org = {
          OrganisationId: '0',
          Name: $scope.BusinessSet.Name,
          OrganisationTaxNumber: $scope.BusinessSet.OrganisationTaxNumber,
          OrganisationCompanyNumber:
            $scope.BusinessSet.OrganisationCompanyNumber,
          FamilyId: '0',
          Phone: [
            {
              Type: 'Office',
              Number: $scope.BusinessSet.Phone,
            },
          ],
          Address: [
            {
              street_address: $scope.BusinessSet.Address,
            },
          ],
          Email: [
            {
              Type: 'Office',
              EmailAddress: $scope.BusinessSet.OfficeEmail,
            },
          ],
          // Notes: $scope.BusinessSet.notes,
          PersonId: '0',
        };

        contactService.organisationInfoSet(org).then((response) => {
          const FamilyId = response.data;

          if (response.data.Message) {
            toaster.pop('info', 'Information', response.data.Message);
          } else {
            toaster.pop(
              'success',
              'Added!',
              'Business has been Added Successfully.',
            );
            $uibModalInstance.close({
              FamilyId,
              PartyType: $scope.ContactSet.PartyType,
            });
          }
        });
      };

      $scope.saveAccount = function (form) {
        if (
          parseInt($scope.ContactSet.PartyType, 10) === 1 ||
          parseInt($scope.ContactSet.PartyType, 10) === 3 ||
          parseInt($scope.ContactSet.PartyType, 10) === 4
        ) {
          $scope.saveClientAccount(form);
        } else if (parseInt($scope.ContactSet.PartyType, 10) === 2) {
          $scope.saveBusinessAccount(form);
        }
      };
    },
  );
