import './style.scss';
import SaveProfilerToContactCtrl from './saveProfilerToContactCtrl';

export default {
  bindings: {
    profilerData: '<',
    toFadedLook: '<',
  },
  controller: SaveProfilerToContactCtrl,
  controllerAs: 'vm',
  template: `
  <a
    class="actions-items"
    ng-click="vm.clickListener()"
    ng-class="{'tool-faded-look': vm.toFadedLook}"
  >
    <span><i class="fa fa-plus fa-fw"></i> Save</span>
  </a>  
  `,
};
