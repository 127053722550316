import { CONTACT_TYPE } from 'Common/constants/contactType';
import { ENTITY_TYPE } from 'Common/constants/entityType';

export const getFormattedPartyData = (party) => {
  return (
    party && {
      clientId: party.ClientID,
      familyId: party.FamilyId,
      firstName: party.FirstName,
      middleName: party.MiddleName,
      lastName: party.LastName,
      mobile: party.Mobile,
      email: party.Email,
      contactType: party.ContactType,
      isBusinessPartner: party.isBusinessPartner,
      isCompanyDirector: party.isCompanyDirector,
      isShareHolder: party.isShareHolder,
      isWritingValue: party.isWriting,
      familyPartnershipId: party.FamilyPartnershipID,
      roleId: party.RoleId,
    }
  );
};

export const getPartyDetailsByClientId = (partyList, clientId) => {
  const invalidList = !partyList || !partyList.length;
  if (invalidList || !clientId) {
    return {};
  }
  const partyDetails = partyList.find((party) => {
    return party.ClientID === clientId;
  });
  return partyDetails || {};
};

export const extractUsername = (email, domain) => {
  if (!email || !domain) {
    return '';
  }
  return email.includes(domain) ? email.replace(domain, '') : '';
};

export const displayWritingOption = (partyType) => {
  const hasWritingOptions = [CONTACT_TYPE.DIRECTOR, CONTACT_TYPE.PARTNER_STR];
  return hasWritingOptions.includes(partyType);
};

export const isSaveOnlyNoInvite = (partyType) => {
  const noInviteParties = [
    CONTACT_TYPE.SHARE_HOLDER,
    CONTACT_TYPE.ASSISTANT_STR,
    CONTACT_TYPE.LOAN_WRITER_STR,
  ];
  return noInviteParties.includes(partyType);
};

export const isEntityPartOfType = (type, entity) => {
  const companyTypes = [ENTITY_TYPE.COMPANY, ENTITY_TYPE.COMPANY_TRUST];
  const partnerTypes = [ENTITY_TYPE.PARTNERSHIP, ENTITY_TYPE.PARTNERSHIP_TRUST];
  const soleTypes = [ENTITY_TYPE.SOLE_TRADER, ENTITY_TYPE.SOLE_TRADER_TRUST];

  switch (type) {
    case 'Company':
      return companyTypes.includes(entity);
    case 'Partnership':
      return partnerTypes.includes(entity);
    case 'Sole Trader':
      return soleTypes.includes(entity);
    default:
      return false;
  }
};

export const getYesNoText = (value) => {
  return value ? 'Yes' : 'No';
};

export const showAdditionalText = (partyType) => {
  const hasAdditionalText = [
    CONTACT_TYPE.DIRECTOR,
    CONTACT_TYPE.SHARE_HOLDER,
    CONTACT_TYPE.PARTNER_STR,
  ];
  return hasAdditionalText.includes(partyType);
};
