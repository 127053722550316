import { parseToInt10 } from 'Common/utilities/parse';

export const getFirstItem = (currentPage, pageSize) => {
  if (!currentPage || !pageSize) {
    return 0;
  }
  return parseToInt10(currentPage) === 1
    ? 1
    : parseToInt10(pageSize) * (parseToInt10(currentPage) - 1) + 1;
};

export const getLastItem = (
  currentPage,
  pageSize,
  pageLength,
  totalRecords,
) => {
  if (!currentPage || !pageSize || !pageLength || !totalRecords) {
    return 0;
  }
  return pageLength < pageSize
    ? totalRecords
    : parseToInt10(pageSize) * parseToInt10(currentPage);
};
