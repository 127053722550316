import angular from 'angular';
import moment from 'moment';

export const loadModule = () =>
  angular
    .module('app')
    .controller('FinPosAssetsModalCtrl', function FinPosAssetsModalCtrl(
      $scope,
      $uibModalInstance,
    ) {
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.Years = [];
      for (let i = moment().year(); i >= 2000; i--) {
        $scope.Years.push(i);
      }

      $scope.frequencyTypes = [
        { Desc: 'Weekly', Value: 'Weekly' },
        { Desc: 'Fortnightly', Value: 'Fortnightly' },
        { Desc: 'Monthly', Value: 'Monthly' },
        { Desc: 'Yearly', Value: 'Yearly' },
      ];

      $scope.saveBreakdown = function () {
        $scope.cancel();
      };
    });
