import template from './contactSplitModal.html';
import controller from './contactSplitModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    adults: '<',
    familyId: '<',
  },
};
