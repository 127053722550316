import { ADDRESS_TYPES } from 'Common/constants/addressField';
import { formatAddress } from 'Common/utilities/address';
import { addressFieldFormatMapper } from 'Common/mappers/address';

class AddressLookupService {
  constructor(generalService) {
    'ngInject';

    this.generalService = generalService;
  }

  handleAddressFieldRemove(address = {}, invalidFields = []) {
    this.address = { ...address };
    if (!invalidFields || !invalidFields.length) {
      return this.address;
    }
    invalidFields.map((field) => {
      this.address[field] = '';
      return field;
    });
    return this.address;
  }

  handleClearingUnnecessaryFields(address) {
    if (!address) {
      return;
    }
    const nonStandardFields = ['addressLine1', 'addressLine2'];
    const standardFields = [
      'level',
      'buildingName',
      'subpremise',
      'street_number',
      'streetName',
      'streetType',
    ];
    const poBoxFields = ['postOfficeType', 'number', 'exchange'];
    switch (address.addressType) {
      case ADDRESS_TYPES.STANDARD:
        return this.handleAddressFieldRemove(address, [
          ...nonStandardFields,
          ...poBoxFields,
        ]);
      case ADDRESS_TYPES.NON_STANDARD:
        return this.handleAddressFieldRemove(address, [
          ...standardFields,
          ...poBoxFields,
        ]);
      case ADDRESS_TYPES.PO_BOX:
        return this.handleAddressFieldRemove(address, [
          ...standardFields,
          ...nonStandardFields,
        ]);
      default:
        return address;
    }
  }

  handleAddressGeocode(address) {
    return this.generalService
      .geocodeSearch(address.formatted_address)
      .then(({ data }) => {
        const { latitude = 0, longitude = 0, geoCoded = false } = data || {};
        return { ...address, latitude, longitude, geoCoded };
      });
  }

  convertToAddressFormat(addressParam) {
    const address = this.handleClearingUnnecessaryFields(addressParam);
    const reformatAddress = address && address.isCustomAddress;
    if (reformatAddress) {
      const formattedAddress = formatAddress(
        addressFieldFormatMapper(address),
        true,
      ).trim();
      address.formatted_address = formattedAddress;
      address.description = formattedAddress;
    }
    if (!address || !address.formatted_address) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject();
    }
    return this.handleAddressGeocode(address);
  }
}

export default AddressLookupService;
