/* eslint-disable sonarjs/no-duplicate-string, sonarjs/cognitive-complexity */
import { QUESTION_ID_AU } from 'Common/constants/questionId';
import { LIST_OF_QUESTIONS } from 'Common/constants/customerObjectives';
import { capitalizeFirstLetter } from 'Common/utilities/string';
import { last } from 'lodash';
import moment from 'moment';

import * as util from './utils/productAndLenderRationaleHintUtil';

const NOT_SPECIFIED = 'Not Specified';

class ProductAndLenderRationaleHintCtrl {
  constructor(
    loanAppSharedData,
    objectivesQuestionService,
    currentUserService,
  ) {
    'ngInject';

    this.loanAppSharedData = loanAppSharedData;
    this.objectivesQuestionService = objectivesQuestionService;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.isAU = this.currentUserService.isAU;
    this.questionTemplate = {
      [QUESTION_ID_AU.RATIONALE_SUMMARY]: this.sectionSummary.bind(this),
      [QUESTION_ID_AU.RATIONALE_INTEREST_RATE]: this.sectionInterestRates.bind(
        this,
      ),
      [QUESTION_ID_AU.RATIONALE_PRODUCT_FEATURE]: this.sectionProductFeatures.bind(
        this,
      ),
      [QUESTION_ID_AU.TURN_AROUND_TIMES]: this.sectionTurnAroundTime.bind(this),
      [QUESTION_ID_AU.LENDER_PREFERENCES]: this.sectionLenderPreferences.bind(
        this,
      ),
      [QUESTION_ID_AU.BORROWING_CAPACITY]: this.sectionBorrowingCapacity.bind(
        this,
      ),
    };
  }

  sectionSummary() {
    const objectiveQuestionAnswers = this.objectivesQuestionService
      .objectivesDataAsObjects;
    const objectiveList = [
      `Primary Purpose: ${
        this.loanAppSharedData.loanPurposeList
          .map(({ Name }) => Name)
          .join(', ') || NOT_SPECIFIED
      }`,
      `Let us know what you would like to achieve from this loan application: “${util.stringAnswer(
        objectiveQuestionAnswers[LIST_OF_QUESTIONS.WHAT_ARE_YOU_WANTING_TO_DO]
          ?.answerString,
      )}”`,
      `${
        this.isAU
          ? 'Your detailed understanding of your client’s specific goals and objectives for this loan application'
          : 'Your detailed understanding of your client’s specific objectives for this loan application'
      }: “${util.stringAnswer(
        objectiveQuestionAnswers[LIST_OF_QUESTIONS.SPECIFIC_GOALS]
          ?.answerString,
      )}”`,
    ];
    const splits =
      this.loanAppSharedData.loanDetailsSet?.LoanFacility?.reduce(
        (accum, curr, idx) => {
          const split = `
        Split ${idx + 1}: 
        ${util.productDataSummary(curr)}
      `;
          const selectedProduct = curr.SelectedFavouriteProductData?.LenderID
            ? `
        <ul class="padding-left-15">
          <li>Selected product: ${curr.SelectedFavouriteProductData.ProductName}</li>
        </ul>
      `
            : '';
          return accum.concat(`${split}${selectedProduct}`);
        },
        [],
      ) ?? [];
    const recommendedSolutionList = [
      `Selected lender: ${
        this.loanAppSharedData.loanDetailsSet?.LenderName || NOT_SPECIFIED
      }`,
      ...splits,
    ];

    return `<div>
      <div class="margin-bottom-10 margin-top-10">
        <i>At a minimum, reference the following in your summary:</i>
      </div>
      ${util.renderHeader('Goals & objectives')}
      ${util.renderList(objectiveList, true)}
      ${util.renderHeader('Recommended solution')}
      ${util.renderList(recommendedSolutionList, true)}
    </div>`;
  }

  sectionInterestRates() {
    const { discountRates = {} } = this.loanAppSharedData.loanDetailsSet || {};
    const facilities = this.loanAppSharedData.loanDetailsSet?.LoanFacility?.reduce(
      (facilitiesAccum, facility, idx) => {
        const {
          LenderID,
          LenderName,
          ProductName,
          ProductID,
          InitialRate,
          OngoingRate,
        } = facility.SelectedFavouriteProductData || {};
        const { SelectedProduct: favoriteProducts = [] } =
          facility.FavouriteProductData?.[0] || {};

        const selectedProduct = LenderID
          ? `${LenderName} - ${ProductName}`
          : NOT_SPECIFIED;
        const { initial, ongoing } =
          discountRates[facility.BrokerEventId] || {};
        const selectedProductDetails = [
          util.productDataSummary(facility, InitialRate, OngoingRate),
        ];
        if (initial) {
          selectedProductDetails.push(
            util.selectedProductDiscountRate(initial),
          );
        }
        if (ongoing) {
          selectedProductDetails.push(
            util.selectedProductDiscountRate(ongoing),
          );
        }

        const otherFavoriteProducts = favoriteProducts.reduce(
          (productsAccum, product) => {
            if (product.ProductID === ProductID) {
              return productsAccum;
            }
            return productsAccum.concat(
              `${product.LenderName} - ${product.ProductName}, Initial rate: ${product.InitialRate}%, Ongoing rate: ${product.OngoingRate}%`,
            );
          },
          [],
        );

        facilitiesAccum.push(
          util.renderHeader(
            `Split ${idx + 1} - Selected product: ${selectedProduct}`,
          ),
        );
        facilitiesAccum.push(util.renderList(selectedProductDetails, true));
        if (otherFavoriteProducts.length) {
          facilitiesAccum.push(
            util.renderHeader(`Split ${idx + 1} - Compared products`),
          );
          facilitiesAccum.push(util.renderList(otherFavoriteProducts));
        }

        return facilitiesAccum;
      },
      [],
    );
    return `<div>
      ${
        facilities?.length
          ? `<div class="margin-bottom-10 margin-top-10">
          <i>Reference the following in this section:</i>
        </div>`
          : '“No Loan Splits Available”'
      }
      ${facilities?.join('')}
    </div>`;
  }

  sectionProductFeatures() {
    const objectiveQuestionAnswers = this.objectivesQuestionService
      .objectivesDataAsObjects;

    const ratePreference = util.stringAnswer(
      objectiveQuestionAnswers[LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE]
        ?.answerString,
      true,
    );
    const ratePreferenceDurationId =
      ratePreference !== 'variable'
        ? last(
            objectiveQuestionAnswers[LIST_OF_QUESTIONS.RATE_DURATION]
              ?.answerInt,
          )
        : null;
    const ratePreferenceDuration = ratePreferenceDurationId
      ? util.answersFromDropdown(
          [ratePreferenceDurationId],
          LIST_OF_QUESTIONS.RATE_DURATION,
          this.objectivesQuestionService,
        )
      : '';
    const ratePreferenceImportantIds =
      objectiveQuestionAnswers[LIST_OF_QUESTIONS.RATE_PREFERENCES_IMPORTANT]
        ?.answerInt || [];
    const ratePreferenceImportant = util.answersFromDropdown(
      ratePreferenceImportantIds,
      LIST_OF_QUESTIONS.RATE_PREFERENCES_IMPORTANT,
      this.objectivesQuestionService,
    );
    const ratePreferenceImportantOther = util.stringAnswer(
      objectiveQuestionAnswers[LIST_OF_QUESTIONS.RATE_IMPORTANT_DETAILS]
        ?.answerString,
    );
    const hasRates = last(
      objectiveQuestionAnswers[LIST_OF_QUESTIONS.RATE_PREFERENCES]?.answerBool,
    );
    const ratePreferenceCombined = hasRates
      ? [
          `Rate: ${capitalizeFirstLetter(
            ratePreference,
          )} ${ratePreferenceDuration} - ${
            ratePreferenceImportant || NOT_SPECIFIED
          }${
            ratePreferenceImportantOther && ratePreferenceImportant
              ? `, “${ratePreferenceImportantOther}”`
              : ''
          }`,
        ]
      : [`Rate: ${hasRates === false ? '“No”' : NOT_SPECIFIED}`];

    const hasRepayments = last(
      objectiveQuestionAnswers[LIST_OF_QUESTIONS.REPAYMENT_PREFERENCE]
        ?.answerBool,
    );
    const repayments =
      objectiveQuestionAnswers[LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE]
        ?.answerInt || [];
    const repaymentsCollectionCombined = hasRepayments
      ? repayments.map((repaymentId) =>
          util.renderProductRepayment(
            repaymentId,
            this.objectivesQuestionService,
          ),
        )
      : [`Repayment: ${hasRepayments === false ? '“No”' : NOT_SPECIFIED}`];

    const hasFeatures = last(
      objectiveQuestionAnswers[LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES]
        ?.answerBool,
    );
    const features =
      objectiveQuestionAnswers[LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE]
        ?.answerInt || [];
    const featuresCollectionCombined = hasFeatures
      ? features.map((featureId) =>
          util.renderProductFeatures(featureId, this.objectivesQuestionService),
        )
      : [`Features: ${hasFeatures === false ? '“No”' : NOT_SPECIFIED}`];

    const clientPreferences = [
      ...ratePreferenceCombined,
      ...repaymentsCollectionCombined,
      ...featuresCollectionCombined,
    ];

    const facilities = this.loanAppSharedData.loanDetailsSet?.LoanFacility?.reduce(
      (facilitiesAccum, facility, idx) => {
        const { SelectedFavouriteProductData, InterestRateType } = facility;
        const {
          LenderID,
          LenderName,
          ProductName,
          RepaymentTypeInitial,
          RepaymentTypeOngoing,
          RepaymentFrequencyTypeOption,
          OffsetAccount,
        } = SelectedFavouriteProductData || {};

        const selectedProduct = LenderID
          ? `${LenderName} - ${ProductName}`
          : NOT_SPECIFIED;

        facilitiesAccum.push(
          util.renderHeader(
            `Selected product - split ${idx + 1}: ${selectedProduct}`,
          ),
        );
        const productFeatures = [
          InterestRateType ? `Rate type: ${InterestRateType}` : null,
          LenderID
            ? `Repayment: ${
                [
                  RepaymentTypeInitial,
                  RepaymentTypeOngoing,
                  RepaymentFrequencyTypeOption,
                ].join(',') || NOT_SPECIFIED
              }`
            : null,
          LenderID ? `Offset: ${OffsetAccount ? 'Yes' : 'No'}` : null,
        ].filter((feature) => !!feature);
        facilitiesAccum.push(util.renderList(productFeatures, true));

        return facilitiesAccum;
      },
      [],
    );

    return `<div>
      ${clientPreferences.length ? util.renderHeader('Client preferences') : ''}
      ${util.renderList(clientPreferences, true)}
      ${facilities?.join('')}
    </div>`;
  }

  sectionTurnAroundTime() {
    const objectiveQuestionAnswers = this.objectivesQuestionService
      .objectivesDataAsObjects;

    const { dates = {} } = this.loanAppOptions || {};
    const importantDates = [
      `Settlement date: ${
        dates.settlementDate?.active
          ? moment(dates.settlementDate.date).format('DD MMM yyyy')
          : NOT_SPECIFIED
      }`,
      `Finance date: ${
        dates.financeDate?.active
          ? moment(dates.financeDate.date).format('DD MMM yyyy')
          : NOT_SPECIFIED
      }`,
      `Auction date: ${
        dates.auctionDate?.active
          ? moment(dates.auctionDate.date).format('DD MMM yyyy')
          : NOT_SPECIFIED
      }`,
    ];

    return `<div>
      ${util.renderHeader('Client preferences')}
      ${util.renderList(
        [
          `Any important timeframes / dates: ${util.boolAnswer(
            objectiveQuestionAnswers[LIST_OF_QUESTIONS.IMPORTANT_DATES]
              ?.answerBool,
          )}`,
        ],
        true,
      )}
      ${util.renderHeader('Important Dates')}
      ${util.renderList(importantDates, true)}
    </div>`;
  }

  sectionLenderPreferences() {
    const objectiveQuestionAnswers = this.objectivesQuestionService
      .objectivesDataAsObjects;

    const currentlyBankingWithIds =
      objectiveQuestionAnswers[LIST_OF_QUESTIONS.BANKING_WITH_SELECT_LENDER]
        ?.answerInt || [];
    const currentlyBankingWithLenders = util.filterObjectiveLenderNames(
      currentlyBankingWithIds,
      this.loanAppSharedData,
    );
    const currentlyBankingWithOther = objectiveQuestionAnswers[
      LIST_OF_QUESTIONS.BANKING_WITH
    ]?.answerString
      ? util.stringAnswer(
          objectiveQuestionAnswers[LIST_OF_QUESTIONS.BANKING_WITH]
            ?.answerString,
        )
      : null;

    const preferredLenderWithIds =
      objectiveQuestionAnswers[LIST_OF_QUESTIONS.PREFERRED_LENDER]?.answerInt ||
      [];
    const preferredLenders = util.filterObjectiveLenderNames(
      preferredLenderWithIds,
      this.loanAppSharedData,
    );
    const preferredLendersReason =
      preferredLenders &&
      last(preferredLenderWithIds) !== -2 &&
      objectiveQuestionAnswers[LIST_OF_QUESTIONS.PREFERRED_LENDER_REASON]
        ?.answerString
        ? util.stringAnswer(
            objectiveQuestionAnswers[LIST_OF_QUESTIONS.PREFERRED_LENDER_REASON]
              ?.answerString,
          )
        : null;

    return `<div>
      ${util.renderHeader('Client preferences')}
      ${util.renderList(
        [
          `Currently banking with: ${
            currentlyBankingWithLenders || NOT_SPECIFIED
          }${
            currentlyBankingWithOther ? ` - “${currentlyBankingWithOther}”` : ''
          }`,
          `Preferred lenders: ${preferredLenders || NOT_SPECIFIED}${
            preferredLendersReason ? ` - “${preferredLendersReason}”` : ''
          }`,
        ],
        true,
      )}
    </div>`;
  }

  sectionBorrowingCapacity() {
    const {
      totalFamilyMonthlyIncome,
      totalFamilyMonthlyExpense,
      totalFamilyAsset,
      totalFamilyLiability,
      totalContribution,
      borrowerList = [],
      contributionList = [],
    } = this.borrowingCapacity;

    const columns = [
      {
        header: `Monthly income`,
        total: totalFamilyMonthlyIncome,
        list: borrowerList,
        valueKey: 'income',
        frequency: ' / m',
      },
      {
        header: `Monthly expenses`,
        total: totalFamilyMonthlyExpense,
        list: borrowerList,
        valueKey: 'expense',
        frequency: ' / m',
      },
      {
        header: `Total assets`,
        total: totalFamilyAsset,
        list: borrowerList,
        valueKey: 'asset',
      },
      {
        header: `Total liabilities`,
        total: totalFamilyLiability,
        list: borrowerList,
        valueKey: 'liability',
      },
      {
        header: `Total contributions`,
        total: totalContribution,
        list: contributionList,
        titleKey: 'fundType',
        valueKey: 'value',
      },
    ].map(util.renderBorrowingCapacity);

    return `<div class="hbox">
      ${columns.join('')}
    </div>`;
  }

  renderTemplate() {
    return this.questionTemplate[this.questionId]?.();
  }
}

export default ProductAndLenderRationaleHintCtrl;
