import template from './adviserProduct.html';
import controller from './adviserProductCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    productsData: '<',
    familyId: '<',
    onSearchLenderFromParent: '&',
  },
};
