import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    .controller('OnlineTrainingCtrl', function OnlineTrainingCtrl(
      loanToolsService,
    ) {
      const vm = this;
      vm.onlineTrainingList = [];
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const addNameToDocumentUrlList = (onlineTrainingDocumentUrlList) => {
        const documentUrlWithName = [];

        _.forEach(onlineTrainingDocumentUrlList, (object) => {
          const startTrimAt = object.lastIndexOf('/') + 1;
          const endTrimAt = object.length - 1;
          // eslint-disable-next-line unicorn/prefer-string-slice
          const documentName = object
            .substr(startTrimAt, endTrimAt)
            .split('+')
            .join(' ');

          documentUrlWithName.push({
            DocumentName: documentName,
            DocumentUrl: object,
          });
        });

        return documentUrlWithName;
      };

      const populateOnlineTrainingList = () => {
        vm.onlineTrainingList = null;

        loanToolsService.resourceTrainingGet().then((response) => {
          vm.onlineTrainingList = response.data;

          _.forEach(vm.onlineTrainingList, (object) => {
            object.DocumentUrlList = addNameToDocumentUrlList(
              object.DocumentUrlList,
            );
          });
        });
      };

      const init = () => {
        populateOnlineTrainingList();
      };
      /* calling init */
      init();
    });
