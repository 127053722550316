export const ASSISTANT_BTN_CLASS = [
  'btn-success',
  'btn-light-blue',
  'btn-default',
  'btn-warning',
  'btn-info',
  'btn-danger',
];

export const ASSISTANT_FONT_CLASS = [
  'fa-paper-plane text-white',
  'fa-check text-white',
  'violet-color fa-times',
  'fa-search text-white',
  'fa-hand-paper-o text-white',
  'fa-ban text-white',
];

export const ASSISTANT_STATUS_LIST = {
  ONBOARDING: 0,
  ACTIVE: 1,
  TERMINATED: 2,
  PROSPECT: 3,
  CEASED: 4,
  SUSPENDED: 5,
  ON_HOLD: 6,
  NOT_PROCEEDING: 7,
  DECEASED: 8,
};

export const ASSISTANT_STATUS_NAME_LIST = {
  ONBOARDING: 'Onboarding',
  ACTIVE: 'Active',
  TERMINATED: 'Terminated',
  PROSPECT: 'Prospect',
  CEASED: 'Ceased by Adviser',
  SUSPENDED: 'Suspended',
};
