import angular from 'angular';
import education from './education';
import educationModal from './educationModal';
import qualifications from './qualifications';
import qualificationsModal from './qualificationsModal';
import educationQualificationsTable from './educationQualificationsTable';

export default angular
  .module('educationQualifications.components', [])
  .component('education', education)
  .component('educationModal', educationModal)
  .component('qualifications', qualifications)
  .component('qualificationsModal', qualificationsModal)
  .component('educationQualificationsTable', educationQualificationsTable).name;
