export const VEHICLE_TYPE = {
  HEAVY_COMMERCIAL: 3,
  LIGHT_COMMERCIAL: 4,
};

export const SALE_TYPE_LIST = [
  { value: 1, name: 'All' },
  { value: 2, name: 'Private Sale' },
  { value: 3, name: 'Dealer' },
  { value: 4, name: 'Sale & Leaseback' },
];

export const CLIENT_TYPE = {
  COMMERCIAL: 1,
  PERSONAL: 2,
};

export const RESIDENTIAL_STATUS_TYPE = {
  PROPERTY_OWNER: 1,
  NOT_PROPERTY_OWNER: 2,
};

export const PRODUCT_TYPE = {
  BUSINESS: 7,
  CONSUMER: 8,
  PERSONAL: 9,
};

export const DEFAULT_FILTER = {
  FREQUENCY: 12,
  MAX_TERM: 84,
};

export const FORM_DICTIONARY = {
  [PRODUCT_TYPE.BUSINESS]: 'isValidBusiness',
  [PRODUCT_TYPE.CONSUMER]: 'isValidConsumer',
  [PRODUCT_TYPE.PERSONAL]: 'isValidPersonal',
};

export const ASSET_DEFAULT_FREQUENCY = '4';
