import template from './documentAccordion.html';
import DocumentAccordionCtrl from './documentAccordionCtrl';

export default {
  template,
  controller: DocumentAccordionCtrl,
  controllerAs: 'vm',
  bindings: {
    groupName: '@',
    dealName: '@',
    loanAppId: '@?',
    insuranceFileId: '@?',
    docList: '<',
    fileUploadMiniAccordion: '<',
    noOfFiles: '@',
    editHandler: '<?',
    deleteHandler: '<?',
    isOpen: '<?',
    copyAllToData: '<',
    loadToPage: '<?',
    callAfterCopy: '&',
    isSettled: '<?',
  },
};
