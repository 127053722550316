import template from './commissionWidgetHeader.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    mainTitle: '@',
    selected: '<',
    optionList: '<',
    onSelect: '&',
  },
};
