import {
  WIDGET_TITLE,
  ALL_VALUE,
  SETTLEMENT_VOLUMES,
  WIDGET_OPTION_VAL,
} from 'Common/constants/dashboardWidget';
import {
  SETTLEMENT_BAR_CONFIG,
  THREE_YEAR_COLOR,
  THREE_YEARCHART,
} from 'Common/config/chart';
import {
  getValueOption,
  parseMultiSelectTicks,
  getCurrentCarouselData,
  mapOptionAll,
  mapSelectedItem,
  getCustomOptionValues,
  getIndexPeriod,
  getAdvisersParams,
  getFormattedAdvisers,
} from 'Common/utilities/dashboardWidget';
import { widgetOptionBuilderForMyCRM } from 'Common/mappers/dashboardWidget';

export default class SettlementVolumesCtrl {
  constructor(
    $q,
    commonWidgetService,
    currentUserService,
    overviewDashboardService,
    dashboardService,
    mycrmLookupService,
  ) {
    'ngInject';

    this.$q = $q;
    this.commonWidgetService = commonWidgetService;
    this.currentUserService = currentUserService;
    this.overviewDashboardService = overviewDashboardService;
    this.dashboardService = dashboardService;
    this.mycrmLookupService = mycrmLookupService;
  }

  $onInit() {
    this.widgetTitle = WIDGET_TITLE.SETTLEMENT_VOLUMES;
    this.widgetSubTitle = 'Performance over rolling 3 year';
    this.showWidgetSettings = true;
    this.chartOptions = SETTLEMENT_BAR_CONFIG;
    this.datasetOverride = THREE_YEARCHART;
    this.getWidgetOptionDropdown();
  }

  goToReport() {
    const params = {
      widgetId: this.myParam.WidgetId,
      widgetUserId: this.myParam.Widget_UserID,
    };
    this.commonWidgetService.goToReport(params);
  }

  setTimePeriod(isNext, currentPeriod, list) {
    const indexPeriod = getIndexPeriod(list, currentPeriod);

    this.timePeriodSelected = getCurrentCarouselData(isNext, indexPeriod, list);

    this.setWidgetOptions({
      widgetOptionId: SETTLEMENT_VOLUMES.TIME_PERIOD,
      value:
        this.timePeriodSelected.Widget_OptionValue ||
        this.timePeriodSelected.valueId,
    });
  }

  setView(isSettings) {
    this.isSettings = isSettings;
  }

  getWidgetOptionDropdown() {
    const { isAssistant, isAdminAssistant } = this.currentUserService;
    const isLoanWriterOnly = isAssistant || isAdminAssistant;

    const promises = [
      this.commonWidgetService.getWidgetOptions(SETTLEMENT_VOLUMES.TIME_PERIOD),
      this.mycrmLookupService.getAdvisers(getAdvisersParams(isLoanWriterOnly)),
    ];

    this.$q.all(promises).then((responses) => {
      if (!responses || !responses.length) {
        return;
      }
      const [timePeriodList, advisersDropdown] = responses;

      this.timePeriodList = timePeriodList;
      this.advisersDropdown = getFormattedAdvisers(advisersDropdown);
      this.getWidgetUserOptions(true);
    });
  }

  setCustomWidgetOption(variableName, isAll) {
    const optionValues = getCustomOptionValues(this[variableName]);

    const { ADVISER } = WIDGET_OPTION_VAL;
    const widgetOptionId = SETTLEMENT_VOLUMES.ADVISERS;

    const customOption = ADVISER.CUSTOM;
    const allOption = ADVISER.ALL;

    const value = isAll ? allOption : customOption;
    this.setWidgetOptions({
      widgetOptionId,
      value,
      selectedUser: optionValues,
    });
  }

  selectAll(varName, inputData) {
    this[varName] = mapOptionAll(inputData);
    this.setCustomWidgetOption(varName, true);
  }

  onItemSelect(itemClicked, varName, inputData, selection) {
    if (itemClicked.value === ALL_VALUE || !selection.length) {
      this.selectAll(varName, inputData);
      return;
    }

    this[varName] = mapSelectedItem(inputData);

    this.setCustomWidgetOption(varName);
  }

  getWidgetUserOptions(isLoad) {
    return this.commonWidgetService
      .getWidgetUserOptions(this.myParam.Widget_UserID)
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }

        const timePeriod = getValueOption(SETTLEMENT_VOLUMES.TIME_PERIOD, data);
        this.timePeriodSelected = timePeriod;

        const advisers = getValueOption(SETTLEMENT_VOLUMES.ADVISERS, data);
        this.advisersDropdown = parseMultiSelectTicks(
          advisers,
          this.advisersDropdown,
        );
        isLoad && this.getSettlementVolume();
      });
  }

  setWidgetOptions(params) {
    const payload = widgetOptionBuilderForMyCRM(
      params,
      this.myParam.Widget_UserID,
    );
    this.overviewDashboardService.WidgetOptionSet(payload).then(() => {
      params.needUpdate && this.getWidgetUserOptions(true);
      !params.needUpdate && this.getSettlementVolume();
    });
  }

  getSettlementVolume() {
    this.dashboardService
      .getSettlementVolume({ userWidgetId: this.myParam.Widget_UserID })
      .then((data) => {
        this.chartData = [];
        this.chartSeries = [];
        this.chartLegend = [];
        this.chartLabels = [];
        if (!data.length) {
          return;
        }

        data.forEach((settlement, index) => {
          this.chartSeries = [...this.chartSeries, settlement.year];
          const color = THREE_YEAR_COLOR[index] || THREE_YEAR_COLOR[0];
          this.chartLegend = [
            ...this.chartLegend,
            { label: settlement.year, color },
          ];
          const monthlyData = settlement.data.map((monthly) => monthly.value);
          this.chartLabels = settlement.data.map((monthly) => monthly.monthStr);

          this.chartData = [...this.chartData, monthlyData];

          this.updateChartConfig();
        });
      });
  }

  getCustomLabel(valuePayload, datasets) {
    const isValid = valuePayload && datasets && datasets.length;
    if (!isValid) {
      return;
    }

    const { index, datasetIndex } = valuePayload;

    const titleSeries = datasets[datasetIndex].label;
    const value = datasets[datasetIndex].data[index];
    return `${titleSeries}: ${this.commonWidgetService.roundFigure(value)}`;
  }

  updateChartConfig() {
    this.chartOptions.tooltips = {
      ...this.chartOptions.tooltips,
      callbacks: {
        label: (valuePayload, { datasets }) =>
          this.getCustomLabel(valuePayload, datasets),
      },
    };

    const loanAmountAxes = { ...this.chartOptions.scales.yAxes };
    loanAmountAxes[0].ticks = {
      ...loanAmountAxes[0].ticks,
      callback: (value) => this.commonWidgetService.getShortenAmount(value),
    };
  }
}
