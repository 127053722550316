export function emailSignatureImageBuilderForUI(data) {
  return {
    contentType: data.ContentType,
    documentExists: data.DocumentExists,
    documentId: data.DocumentId,
    documentKey: data.DocumentKey,
    downloadLink: data.DownloadLink,
    imageUploadType: data.ImageUploadType,
  };
}

export function emailSignaturAchievementsBuilderForUI(data) {
  return {
    familyID: data.FamilyID,
    achievementID: data.AchievementID,
    fiscalYear: data.FiscalYear,
    familyAchievementsId: data.FamilyAchievementsId,
    achievementName: data.AchievementName,
  };
}

export function emailSignatureSettingsBuilderForUI(data) {
  return {
    disclaimerText: data.DisclaimerText,
    adviserPhoto: data.AdviserPhoto,
    businessAddress: data.BusinessAddress,
    businessPhone: data.BusinessPhone,
    customerSatisfactionAward: data.CustomerSatisfactionAward,
    eliteAward: data.EliteAward,
    familyId: data.FamilyId,
    isAdviserPhotoExisting: data.IsAdviserPhotoExisting,
    isBusinessAddressExisting: data.IsBusinessAddressExisting,
    isBusinessPhoneExisting: data.IsBusinessPhoneExisting,
    isCustomerSatisFactionAwardExisting:
      data.IsCustomerSatisFactionAwardExisting,
    isEliteAwardExisting: data.IsEliteAwardExisting,
    isMobilePhoneExisting: data.IsMobilePhoneExisting,
    mobilePhone: data.MobilePhone,
    footerImageLink1: data.FooterImageLink1,
    footerImageLink2: data.FooterImageLink2,
    isSocialMedia: data.IsSocialMedia,
    isMyLeadGenerator: data.IsMyLeadGenerator,
    myLeadGenLink: data.MyLeadGenLink,
  };
}

export function emailSignaturePreviewDetailsBuilderForUI(data) {
  return {
    awardsAccreditation: data.AwardsAccreditation.map(
      emailSignatureImageBuilderForUI,
    ),
    footerImages: data.FooterImages.map(emailSignatureImageBuilderForUI),
    profilePhotos: data.ProfilePhotos.map(emailSignatureImageBuilderForUI),
    emailSignatureContent: {
      abn: data.EmailSignatureContent.ABN,
      acl: data.EmailSignatureContent.ACL,
      adviserPhoto: data.EmailSignatureContent.AdviserPhoto,
      businessAddress: data.EmailSignatureContent.BusinessAddress,
      businessPhone: data.EmailSignatureContent.BusinessPhone,
      eliteAward: !!data.EmailSignatureContent.EliteAward,
      customerSatisfactionAward:
        data.EmailSignatureContent.CustomerSatisfactionAward,
      disclaimerText: data.EmailSignatureContent.DisclaimerText,
      familyId: data.EmailSignatureContent.FamilyId,
      mobilePhone: data.EmailSignatureContent.MobilePhone,
      footerImageLink1: data.EmailSignatureContent.FooterImageLink1,
      footerImageLink2: data.EmailSignatureContent.FooterImageLink2,
      facebook: data.EmailSignatureContent.Facebook,
      linkedIn: data.EmailSignatureContent.LinkedIn,
      website: data.EmailSignatureContent.Website,
      youTube: data.EmailSignatureContent.YouTube,
      achievements: data.EmailSignatureContent.Achievements.map(
        emailSignaturAchievementsBuilderForUI,
      ),
    },
  };
}

export function emailSignatureSettingsBuilderForMyCRM(data) {
  return {
    DisclaimerText: data.disclaimerText,
    AdviserPhoto: data.adviserPhoto,
    BusinessAddress: data.businessAddress,
    BusinessPhone: data.businessPhone,
    CustomerSatisfactionAward: data.customerSatisfactionAward,
    EliteAward: data.eliteAward,
    FamilyId: data.familyId,
    IsAdviserPhotoExisting: data.isAdviserPhotoExisting,
    MobilePhone: data.mobilePhone,
    FooterImageLink1: data.footerImageLink1,
    FooterImageLink2: data.footerImageLink2,
    IsSocialMedia: data.isSocialMedia,
    IsMyLeadGenerator: data.isMyLeadGenerator,
  };
}
