import template from './lmiCalculator.html';
import controller from './lmiCalculatorCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    brokerEventId: '<',
    isEdit: '<',
    isModal: '<',
    fundingCalcData: '<',
    onUpdateBaseLoanAmount: '&',
  },
};
