import { updateTextUrgency } from 'Assets/js/controllers/loanApp/util/loanEmailTemplate';

class LoanSubmissionEmailContentCtrl {
  toggleContent() {
    this.isShowContent = !this.isShowContent;
  }

  $onChanges() {
    this.updateSubject();
  }

  updateSubject() {
    if (!this.email || !this.email.Subject || !this.lenderSubmissionSet) {
      return;
    }
    this.email.Subject = updateTextUrgency(this.email.Subject, this.isUrgent);
    this.lenderSubmissionSet.CustomEmailSubject = this.email.Subject;
  }
}

export default LoanSubmissionEmailContentCtrl;
