import template from './emailLenderCheck.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    lenderInfoData: '<',
  },
};
