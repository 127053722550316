import moment from 'moment';
import { get } from 'lodash';
import {
  DD_MMM_YYYY_FORMAT,
  MM_DD_YYYY_MOMENT_FORMAT,
  ALT_DATE_INPUT_FORMATS,
} from 'Common/constants/dateConfigs';
import { APPLICATION_DATE_TYPE } from 'Common/constants/customerObjectives';
import { toastSaveSuccess, toastSaveDelete } from 'Common/utilities/alert';

class FileImportantDatesCtrl {
  constructor(
    loanApplicationServices,
    contactService,
    opportunityLoanAppService,
  ) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.contactService = contactService;
    this.opportunityLoanAppService = opportunityLoanAppService;
  }

  $onInit() {
    this.setValues();
  }

  setValues() {
    this.importantDates = {};
    this.isOpenDate = {};
    this.dateFields = [
      {
        typeId: APPLICATION_DATE_TYPE.SETTLEMENT_DATE,
        name: 'Settlement',
        key: 'settlementDate',
      },
      {
        typeId: APPLICATION_DATE_TYPE.FINANCE_DATE,
        name: 'Finance',
        key: 'financeDate',
      },
      {
        typeId: APPLICATION_DATE_TYPE.AUCTION_DATE,
        name: 'Auction',
        key: 'auctionDate',
      },
    ];

    this.format = DD_MMM_YYYY_FORMAT;
    this.dateInputFormats = ALT_DATE_INPUT_FORMATS;
    this.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
  }

  $onChanges(changes) {
    const { scenarioDetails } = changes;

    if (scenarioDetails && scenarioDetails.currentValue) {
      this.setImportantDates();
    }
  }

  setImportantDates() {
    const importantDates = get(this.scenarioDetails, 'ImportantDate', []);
    this.dateFields.forEach((field) => {
      const date = importantDates.find((o) => o.TypeOfDateID === field.typeId);
      this.importantDates[field.key] = date ? new Date(date.Date) : null;
    });
  }

  openDate(key) {
    this.isOpenDate[key] = true;
  }

  onDateChange(dateField) {
    const importantDate = this.importantDates[dateField.key];
    if (importantDate === undefined) {
      return;
    }

    if (importantDate === null) {
      this.removeImportantDate(dateField);
    } else {
      this.updateImportantDate(dateField, importantDate);
    }
  }

  updateImportantDate(dateField, importantDate) {
    this.loanApplicationServices
      .putImportantDate(
        this.loanAppId,
        dateField.typeId,
        moment(importantDate).format(MM_DD_YYYY_MOMENT_FORMAT),
      )
      .then(({ data }) => {
        if (data) {
          this.opportunityLoanAppService.executeCallback(
            'refreshLoanAppImportantDates',
          );
          toastSaveSuccess();
        }
      });
  }

  removeImportantDate(dateField) {
    this.contactService
      .loanImportantDatesDelete(
        this.scenarioDetails.LoanId,
        dateField.typeId,
        false,
      )
      .then(() => {
        this.opportunityLoanAppService.executeCallback(
          'refreshLoanAppImportantDates',
        );
        toastSaveDelete();
      });
  }
}

export default FileImportantDatesCtrl;
