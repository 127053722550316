import { ACCESS_TYPE } from 'Common/constants/accessTypes';

export const getUserInfoResponse = ({ $scope, response }) => {
  $scope.accessType = response.data.AccessType;
  $scope.userData = response.data;
  $scope.familyId = response.data.FamilyId;

  const DIFFERENT_ADVISER_ORG = 2;
  const ALL_ADVISERS = 0;
  return $scope.accessType === ACCESS_TYPE.CORPORATE
    ? ALL_ADVISERS
    : DIFFERENT_ADVISER_ORG;
};
