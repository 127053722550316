const SIDEBAR_SRC = 'assets/views/notificationSidebar';
export const TEMPLATE = {
  ACTIVITY: {
    LABEL: 'activity',
    SRC: `${SIDEBAR_SRC}/activitySidebar.html`,
  },
  TASK: {
    LABEL: 'task',
    SRC: `${SIDEBAR_SRC}/taskSidebar.html`,
  },
  APPOINTMENT: {
    LABEL: 'appointment',
    SRC: `${SIDEBAR_SRC}/appointmentSidebar.html`,
  },
};

export const NOTIFICATION = {
  DESKTOP: `${SIDEBAR_SRC}/notificationSidebar.html`,
  MOBILE: `${SIDEBAR_SRC}/notificationSidebarXs.html`,
};

export const SEARCH_RESULT = {
  DESKTOP: '/assets/views/partials/searchResult.html',
};

export const setTemplateSrc = ({ $scope, view }) => {
  switch (view) {
    case TEMPLATE.ACTIVITY.LABEL:
      $scope.activitySidebarSrc = TEMPLATE.ACTIVITY.SRC;
      break;
    case TEMPLATE.TASK.LABEL:
      $scope.taskSidebarSrc = TEMPLATE.TASK.SRC;
      break;
    case TEMPLATE.APPOINTMENT.LABEL:
      $scope.appointmentSidebarSrc = TEMPLATE.APPOINTMENT.SRC;
      break;
    default:
  }
};

export const getNotificationSrc = (isMobile) =>
  isMobile ? NOTIFICATION.MOBILE : NOTIFICATION.DESKTOP;
