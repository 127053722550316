import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('CompanyProfileCtrl', function CompanyProfileCtrl(
      $scope,
      $timeout,
      $uibModal,
      userService,
      $stateParams,
      configService,
      accountService,
    ) {
      /**
       * Get Company Info
       * */
      $scope.CompanyInfoGet = function () {
        userService.CompanyInfoGet().then((response) => {
          $scope.CompanyInfo = response.data;

          $scope.CompanyInfoCopy = {};

          angular.copy($scope.CompanyInfo, $scope.CompanyInfoCopy);

          $scope.companyInfoUpdated = false;
        });
      };

      /**
       * Set data
       * */
      $scope.CompanyInfoSet = function () {
        const data = {
          CompanyLogoDocument: {
            uploadUser: {},
          },
          BrokerBrandingId: $scope.CompanyInfo.BrokerBrandingId,
          CompanyName: $scope.CompanyInfo.CompanyName,
          TradingAs: $scope.CompanyInfo.TradingAs,
          PhysicalAddress: {
            formatted_address:
              $scope.CompanyInfo.PhysicalAddress.formatted_address,
          },
          MaillingAddress: {
            formatted_address:
              $scope.CompanyInfo.MaillingAddress.formatted_address,
          },
          Website: $scope.CompanyInfo.Website,
          mainEmail: $scope.CompanyInfo.mainEmail,
        };

        if ($scope.LogoID) {
          data.CompanyLogoDocument.DocumentId = $scope.LogoID;
          data.CompanyLogoDocument.Name = $scope.LogoID;
          data.CompanyLogoDocument.DateCreated = new Date();
        }

        userService.CompanyInfoSet(data).then(() => {
          $scope.companyInfoUpdated = false;
        });
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.CompanyInfoWatchers = function () {
        $scope.$watch('CompanyLogoDocument.DocumentId', (newVal, oldVal) => {
          if (oldVal) {
            $scope.companyInfoUpdated = true;
          }
        });

        $scope.$watch('CompanyInfo.CompanyName', (newVal, oldVal) => {
          if (oldVal) {
            if (newVal === $scope.CompanyInfoCopy.CompanyName) {
              $scope.companyInfoUpdated = false;
            } else {
              $scope.companyInfoUpdated = true;
            }
          }
        });

        $scope.$watch('CompanyInfo.TradingAs', (newVal, oldVal) => {
          if (oldVal) {
            if (newVal === $scope.CompanyInfoCopy.TradingAs) {
              $scope.companyInfoUpdated = false;
            } else {
              $scope.companyInfoUpdated = true;
            }
          }
        });

        $scope.$watch('CompanyInfo.Website', (newVal, oldVal) => {
          if (oldVal) {
            if (newVal === $scope.CompanyInfoCopy.Website) {
              $scope.companyInfoUpdated = false;
            } else {
              $scope.companyInfoUpdated = true;
            }
          }
        });

        $scope.$watch('CompanyInfo.mainEmail', (newVal, oldVal) => {
          if (oldVal) {
            if (newVal === $scope.CompanyInfoCopy.mainEmail) {
              $scope.companyInfoUpdated = false;
            } else {
              $scope.companyInfoUpdated = true;
            }
          }
        });

        $scope.$watch(
          'CompanyInfo.PhysicalAddress.formatted_address',
          (newVal, oldVal) => {
            if (oldVal) {
              if (
                newVal ===
                $scope.CompanyInfoCopy.PhysicalAddress.formatted_address
              ) {
                $scope.companyInfoUpdated = false;
              } else {
                $scope.companyInfoUpdated = true;
              }
            }
          },
        );

        $scope.$watch(
          'CompanyInfo.MaillingAddress.formatted_address',
          (newVal, oldVal) => {
            if (oldVal) {
              if (
                newVal ===
                $scope.CompanyInfoCopy.MaillingAddress.formatted_address
              ) {
                $scope.companyInfoUpdated = false;
              } else {
                $scope.companyInfoUpdated = true;
              }
            }
          },
        );
      };

      $scope.CompanyInfoWatchers();

      /**
       * Uploading of photo and logo
       * */

      $scope.companyInterface = {};
      $scope.uploadCount = 0;
      $scope.success = false;
      $scope.error = false;
      $scope.familyId = $stateParams.familyId;
      $scope.hideDocumentProgress = false;

      $scope.documentProgressVisibility = function (visibility) {
        $scope.hideDocumentProgress = parseInt(visibility, 10) < 1;
      };

      $scope.openLogoModal = function (obj) {
        $scope.documentDataObject = obj;
        $uibModal.open({
          templateUrl: '/assets/views/user/partials/add_image_modal.html',
          controller: 'MyAccountModalCtrl',
          scope: $scope,
        });
      };

      $scope.$on('$dropletReady', () => {
        if ($scope.companyInterface) {
          $scope.companyInterface = accountService.accountDropletReadyOptions(
            $scope.companyInterface,
          );
        }
      });

      $scope.$on('$dropletFileAdded', () => {
        if (!$scope.companyInterface.isUploading()) {
          $scope.companyInterface.uploadFiles();
          $scope.documentProgressVisibility(1);
        }
      });

      // Listen for when the files have been successfully uploaded.
      $scope.$on('$dropletSuccess', (event, response, files) => {
        if ($scope.companyInterface.isReady) {
          $scope.companyInterface.uploadFiles();
        }

        const documentObj = response[0];
        $scope.uploadCount = files.length;
        $scope.success = true;
        if ($scope.infoType === 'company') {
          $scope.openLogoModal({
            Title: documentObj.Name,
            DocumentID: documentObj.DocumentId,
            FamilyID: $scope.familyId,
          });
        }

        $scope.LogoID = documentObj.DocumentId;

        // Hides the upload progressbar
        $scope.documentProgressVisibility(0);

        $timeout(() => {
          $scope.success = false;
        }, 5000);
      });

      // Listen for when the files have failed to upload.
      $scope.$on('$dropletError', () => {
        $scope.error = true;
      });

      /**
       * Initialize data
       * */
      $scope.CompanyInfoGet();
    });
