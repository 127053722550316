// Angular File Upload module does not include this directive
// Only for example

/**
 * The ng-thumb directive
 * @author: nerv
 * @version: 0.1.2, 2014-01-09
 */
import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('ngThumb', function ngThumb($window) {
    const helper = {
      support: !!($window.FileReader && $window.CanvasRenderingContext2D),
      isFile(item) {
        return angular.isObject(item) && item instanceof $window.File;
      },
      isImage(file) {
        const type = `|${file.type.slice(file.type.lastIndexOf('/') + 1)}|`;
        return '|jpg|png|jpeg|bmp|gif|'.includes(type);
      },
    };

    return {
      restrict: 'A',
      template: '<canvas/>',
      link(scope, element, attributes) {
        if (!helper.support) {
          return;
        }

        const params = scope.$eval(attributes.ngThumb);

        if (!helper.isFile(params.file)) {
          return;
        }
        if (!helper.isImage(params.file)) {
          return;
        }

        const canvas = element.find('canvas');
        const reader = new $window.FileReader();

        function onLoadImage() {
          const width =
            params.width || (this.width / this.height) * params.height;
          const height =
            params.height || (this.height / this.width) * params.width;
          canvas.attr({ width, height });
          canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
        }

        function onLoadFile(event) {
          const img = new $window.Image();
          img.addEventListener('load', onLoadImage);
          img.src = event.target.result;
        }

        reader.addEventListener('load', onLoadFile);
        reader.readAsDataURL(params.file);
      },
    };
  });

angular.module('app').directive('readfile', [
  function readfile() {
    return {
      restrict: 'A',
      scope: {
        onSelect: '&',
      },
      link: function link(scope, element) {
        const handleEvent = (changeEvent) => {
          scope.$apply(() => {
            scope.onSelect && scope.onSelect({ $e: changeEvent });
          });
        };

        element.on('change', handleEvent);

        scope.$on('$destroy', () => {
          element.off('change', handleEvent);
        });
      },
    };
  },
]);
