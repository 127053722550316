/* eslint-disable sonarjs/no-identical-functions */
import {
  kanbanHeight,
  kanbanItemMoved,
  kanbanCustomItemMove,
  getFirstSortItem,
  getKanbanSortItem,
  leadCardMap,
  opportunityCardMap,
  applicationCardMap,
  showImportantDatesModal,
  moveLoanOpportunityItem,
} from 'Common/utilities/kanbanHelper';
import { getNotProceededReasonList } from 'Common/utilities/opportunityConversion';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { PIPELINE_CATEGORIES } from 'Common/constants/pipelineType';
import { LOAN_AND_PIPELINE_STATUS_MAPPING } from 'Common/constants/loanAndPipelineStatusMapping';
import toastr from 'toastr';
import moment from 'moment';

const getLoanStatusId = (pipelineStatusId) => {
  const map =
    LOAN_AND_PIPELINE_STATUS_MAPPING.find(
      (o) => o.PIPELINE_STATUS === pipelineStatusId,
    ) || {};
  return map.LOAN_STATUS;
};

class Kanban {
  constructor(
    $window,
    pipelineSharedData,
    pipelineService,
    modalRenderService,
    loanOpportunityService,
    $timeout,
    name,
    columns = [],
    settings = {},
    $q,
    configService,
    contactService,
    importantDatesService,
    loanScenarioModelService,
  ) {
    this.name = name;
    this.columns = columns;
    this.$window = $window;
    this.$timeout = $timeout;
    this.settings = settings;
    this.$q = $q;
    this.configService = configService;
    this.contactService = contactService;
    this.importantDatesService = importantDatesService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.$window.addEventListener('resize', this.setHeight.bind(this));

    this.pipelineService = pipelineService;
    this.modalRenderService = modalRenderService;
    this.pipelineSharedData = pipelineSharedData;
    this.pipelineSharedData.initCardViewStates();

    this.setHeight();
    this.setWidth();
    this.setOptions();
    this.setDropboxModel();
  }

  setHeight() {
    this.height = kanbanHeight(this.$window, this.height);
  }

  setWidth() {
    const extraWidthOffset = this.columns.length > 6 ? 65 : 0;
    this.width = {
      value: !this.columns.length
        ? 100
        : this.columns.length * 220 + 280 + extraWidthOffset,
      unit: !this.columns.length ? '%' : 'px',
    };
  }

  setOptions() {
    const { pipelineSharedData } = this;
    this.options = {
      /**
       * @param event = {}
       */
      itemMoved() {},
      /**
       * @param event = {}
       */
      orderChanged() {},
      containerPositioning: 'relative',
      /**
       * this is the expected id of the general container for your kanban
       */
      containment: '#kanban-columns-container',
      accept() {
        return true;
      },
      allowDuplicates: true,
      /**
       * please see pipelineSharedData.cardViewStates during this hook
       * as there are properties in there than are ready to use for you
       */
      dragStart: pipelineSharedData.handleDragStart,
      /**
       * please see pipelineSharedData.cardViewStates during this hook
       * as there are properties in there than are ready to use for you
       */
      dragEnd: pipelineSharedData.handleDragEnd,
    };

    this.customOptions = {
      /**
       * @params itemToHighlight = {}, destinationColumn = {}
       */
      itemMovedPostCallback() {},
      /**
       * @param result = [optional, return of api post]
       * required itemMovedPostCallback for this success callback to be called
       */
      itemMovedPostSuccessCallback() {
        toastr.success('Card successfully moved!', 'Success');
      },
      /*
       * @param depends on the pipeline Type
       */
      itemConvertPostCallback() {},
      /**
       * @param result = [optional, return of api post]
       * required itemConvertPostCallback for this success callback to be called
       */
      itemConvertPostSuccessCallback() {
        toastr.success('Card successfully converted!', 'Success');
      },
    };
  }

  setDropboxModel() {
    this.dropboxModel = {
      cardApprove: [],
      cardOpportunity: [],
      cardNotProceeding: [],
    };
  }

  setColumnSortMode() {
    const kanbanSortingModeSplit =
      (this.settings.sortingMode && this.settings.sortingMode.split(',')) || [];
    const kanbanSortingModeObj = {};
    kanbanSortingModeSplit.forEach((mode) => {
      const modeObj = mode.split(':');
      const modeName = modeObj[0];
      const modeContent = modeObj[1];
      kanbanSortingModeObj[modeName] = modeContent;
    });

    this.columns.map((column) => {
      if (kanbanSortingModeObj[column.name]) {
        column.SelectedFilterStatus = kanbanSortingModeObj[column.name];
      }
      return column;
    });
  }
}

/*
 * To be used in pipeline Leads
 */
export class KanbanLeads extends Kanban {
  constructor(args = {}) {
    const {
      $window,
      pipelineSharedData,
      pipelineService,
      modalRenderService,
      loanOpportunityService,
      $timeout,
      name,
      columns,
      generalCards,
      totalLoanAmounts,
      statusList,
      settings,
      $q,
      $state,
      loanScenarioService,
      contactService,
      opportunityNewModalService,
      onRefreshStats,
      loanScenarioModelService,
    } = args;
    super(
      $window,
      pipelineSharedData,
      pipelineService,
      modalRenderService,
      loanOpportunityService,
      $timeout,
      name,
      columns,
      settings,
      $q,
      null,
      null,
      null,
      loanScenarioModelService,
    );

    this.loanOpportunityService = loanOpportunityService;
    this.setOptionItemMoved = this.setOptionItemMoved.bind(this);
    this.updateCustomerSortString = this.updateCustomerSortString.bind(this);

    this.$state = $state;
    this.loanScenarioService = loanScenarioService;
    this.contactService = contactService;
    this.opportunityNewModalService = opportunityNewModalService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.onRefreshStats = onRefreshStats;

    this.setOptionAccept();
    this.setOptionItemMoved();
    this.setReasons();
    this.convertCardsToColumns(generalCards, totalLoanAmounts, statusList);
  }

  setOptionAccept() {
    this.options.accept = (sourceItemHandleScope, destSortableScope) => {
      this.pipelineSharedData.cardViewStates.isNotProceedingDropAreaEnabled = false;
      this.pipelineSharedData.cardViewStates.isSettledDropAreaEnabled = false;
      this.$timeout(() => {
        if (
          destSortableScope &&
          destSortableScope.element &&
          destSortableScope.element.length
        ) {
          const dropAreaId = destSortableScope.element[0].id;
          const areaDragged = this.pipelineSharedData.onDropAreaMouseIn(
            dropAreaId,
          );
          if (areaDragged) {
            this.pipelineSharedData.cardViewStates[
              areaDragged
            ] = this.pipelineSharedData.cardViewStates.cardOnDrag;
          }
        }
      });
      return true;
    };
  }

  /**
   * This method is tighly coupled for oppurtunity behavior
   * You can copy this to you subclass and change the action on itemMovedPostCallback
   */
  updateCustomerSortString(sortItemData) {
    this.settings.updateCardList({
      primaryStorageKey: 'customerSort',
      sortItemData,
      isAppendSortHistory: true,
    });
  }

  setOptionItemMoved() {
    this.customOptions.itemMovedPostCallback = (
      itemToHighlight,
      destinationColumn,
    ) => {
      const { pipelineCardsID } = itemToHighlight;
      const { pipelineStatusId } = destinationColumn;
      return this.pipelineService.PipelineCards(pipelineCardsID, {
        PipelineStatusID: pipelineStatusId,
      });
    };

    this.customOptions.itemMovedPostSuccessCallback = (
      itemToHighlight,
      destinationColumn,
    ) => {
      toastr.success('Lead successfully moved!', 'Success');
      const card =
        destinationColumn.cards.find(
          (o) => o.pipelineCardsID === itemToHighlight.pipelineCardsID,
        ) || {};
      card.pipelineStatusID = destinationColumn.pipelineStatusId;
      card.subStatusID = 0;
      card.subStatusName = '';
      card.lastModifiedDate = moment(new Date()).format();
      const sortItemData = getKanbanSortItem(
        itemToHighlight,
        destinationColumn,
        'pipelineCardsID',
        true,
      );
      this.updateCustomerSortString(sortItemData);
      if (typeof card.checkDisplayForSubStatus === 'function') {
        card.checkDisplayForSubStatus();
      }
    };

    this.customOptions.itemConvertPostCallback = (
      pipelineCardsID,
      conversionStatus,
      selectedReasonId,
      shouldSendNurtureCampaigns,
      itemToHighlight,
    ) => {
      const args = {
        pipelineCardsID,
        conversionStatus,
        clientFamilyId: itemToHighlight.clientFamilyID,
        reasonSubstatusID: selectedReasonId || 0,
        shouldSendNurtureCampaigns,
      };
      return this.pipelineService.PipelineCardsConversionSet(args);
    };

    this.customOptions.itemConvertPostSuccessCallback = (
      isApproveApplication,
      response,
      itemToHighlight = {},
    ) => {
      const { data: conversionResponse } = response;

      if (!conversionResponse) {
        return;
      }

      if (isApproveApplication) {
        const {
          clientFamilyID: familyId,
          adviserId,
          pipelineCardsID,
        } = itemToHighlight;
        const setLoanAppObj = {
          LoanScenarioId: 0,
          AdviserId: adviserId || 0,
          Title: `Loan Application`,
          StatusName: 'ongoing',
          FamilyId: familyId,
          AssignedLenderId: '0',
          IsCardMovement: true,
        };

        this.loanScenarioService
          .scenarioSet(setLoanAppObj)
          .then(({ data: loanAppId }) => {
            const routerParams = { familyId, loanAppId };
            const cardsLoanScenarioParams = {
              pipelineCardsID,
              loanScenarioID: loanAppId,
            };
            toastr.success('Lead successfully converted!', 'Success');
            this.pipelineService.PipelineCardsLoanScenarioSet(
              cardsLoanScenarioParams,
            );
            this.$state.go('app.loanApplicationDetailsV3', routerParams);
          });
      } else {
        toastr.success('Lead successfully converted!', 'Success');
        this.onRefreshStats();
      }
    };

    this.options.itemMoved = (event) => {
      kanbanItemMoved({
        event,
        pipelineSharedData: this.pipelineSharedData,
        itemMovedPostCallback: this.customOptions.itemMovedPostCallback,
        itemMovedPostSuccessCallback: this.customOptions
          .itemMovedPostSuccessCallback,
        itemConvertPostCallback: this.customOptions.itemConvertPostCallback,
        itemConvertPostSuccessCallback: this.customOptions
          .itemConvertPostSuccessCallback,
        sourceModelProp: 'card',
        targetIdProp: 'pipelineCardsID',
        modalRenderService: this.modalRenderService,
        reasonList: this.reasonList,
        isShowNurtureCampaignsQuestion: true,
        contactService: this.contactService,
        opportunityNewModalService: this.opportunityNewModalService,
        sourcePipelineType: PIPELINE_CATEGORIES.LEADS,
        loanScenarioModelService: this.loanScenarioModelService,
      });
    };

    this.options.orderChanged = (event) => {
      const card =
        event &&
        event.source &&
        event.source.itemScope &&
        event.source.itemScope.card;
      const destination =
        event &&
        event.dest &&
        event.dest.sortableScope &&
        event.dest.sortableScope.$parent &&
        event.dest.sortableScope.$parent.$parent &&
        event.dest.sortableScope.$parent.$parent.$parent &&
        event.dest.sortableScope.$parent.$parent.$parent.column;
      const sortItemData = getKanbanSortItem(
        card,
        destination,
        'pipelineCardsID',
        true,
      );
      this.updateCustomerSortString(sortItemData);
    };
  }

  /*
   * will reuse itemMovedPostCallback
   */
  customItemMove(
    itemToHighlight,
    pipelineStatusId,
    isConversion,
    selectedReasonId,
    loans = [],
  ) {
    const itemMovedPostSuccessCallback = () => {
      if (!isConversion) {
        toastr.success('Lead successfully moved!', 'Success');
      }
      itemToHighlight.pipelineStatusID = pipelineStatusId;
      itemToHighlight.subStatusID = 0;
      itemToHighlight.subStatusName = '';
      itemToHighlight.lastModifiedDate = moment(new Date()).format();
      const destinationColumn = this.columns.find(
        (o) => o.pipelineStatusId === pipelineStatusId,
      );
      const sortItemData = getFirstSortItem(
        itemToHighlight,
        destinationColumn,
        'pipelineCardsID',
      );
      this.updateCustomerSortString(sortItemData);
      return { isSuccess: true };
    };

    return kanbanCustomItemMove({
      itemMovedPostCallback: this.customOptions.itemMovedPostCallback,
      itemMovedPostSuccessCallback,
      itemToHighlight,
      destinationColumn: { pipelineStatusId },
      isConversion,
      targetIdProp: 'pipelineCardsID',
      itemConvertPostCallback: this.customOptions.itemConvertPostCallback,
      itemConvertPostSuccessCallback: this.customOptions
        .itemConvertPostSuccessCallback,
      selectedReasonId,
      contactService: this.contactService,
      opportunityNewModalService: this.opportunityNewModalService,
      modalRenderService: this.modalRenderService,
      loans,
      sourcePipelineType: PIPELINE_CATEGORIES.LEADS,
    });
  }

  /**
   * this is a tightly coupled method that supports the model being used in
   * 'Pipeline/leads/views/kanban' API (Cards collection) - please see documentation
   */
  convertCardsToColumns(generalCards, totalLoanAmounts, statusList) {
    const columns = statusList.reduce((accum, current) => {
      const {
        PipelineStatus: name,
        PipelineStatusID: pipelineStatusId,
      } = current;
      const filterCards = generalCards.filter(
        (card) => card.pipelineStatusID === pipelineStatusId,
      );

      filterCards.map(leadCardMap);
      const [firstCard] = filterCards;
      return accum.concat({
        name,
        pipelineStatusId,
        cards: filterCards,
        totalRecords: (firstCard && firstCard.totalRecords) || 0,
        pageNumber: 1,
      });
    }, []);

    this.columns = columns;
    this.setWidth();
    this.setColumnSortMode();
  }

  setReasons() {
    this.pipelineService.settingsReasonGet().then((res) => {
      if (!res || !res.data) {
        return;
      }
      const { data } = res;
      this.reasonList = data;
    });
  }
}

/*
 * To be used in pipeline Opportunity
 */
export class KanbanOpportunity extends Kanban {
  constructor(args = {}) {
    const {
      $window,
      pipelineSharedData,
      pipelineService,
      contactService,
      modalRenderService,
      loanOpportunityService,
      $timeout,
      name,
      columns,
      generalCards,
      totalLoanAmounts,
      statusList,
      settings,
      $q,
      onRefreshStats,
      loanScenarioModelService,
    } = args;
    super(
      $window,
      pipelineSharedData,
      pipelineService,
      modalRenderService,
      loanOpportunityService,
      $timeout,
      name,
      columns,
      settings,
      $q,
      null,
      contactService,
      null,
      loanScenarioModelService,
    );

    this.loanOpportunityService = loanOpportunityService;
    this.setOptionItemMoved = this.setOptionItemMoved.bind(this);
    this.updateCustomerSortString = this.updateCustomerSortString.bind(this);
    this.getLoanOpportunityTotalLoanAmounts = this.getLoanOpportunityTotalLoanAmounts.bind(
      this,
    );
    this.onRefreshStats = onRefreshStats;

    this.setOptionAccept();
    this.setOptionItemMoved();
    this.setReasons();
    this.convertCardsToColumns(generalCards, totalLoanAmounts, statusList);
  }

  setOptionAccept() {
    this.options.accept = (sourceItemHandleScope, destSortableScope) => {
      this.pipelineSharedData.cardViewStates.isNotProceedingDropAreaEnabled = false;
      this.pipelineSharedData.cardViewStates.isSettledDropAreaEnabled = false;
      this.$timeout(() => {
        if (
          destSortableScope &&
          destSortableScope.element &&
          destSortableScope.element.length
        ) {
          const dropAreaId = destSortableScope.element[0].id;
          const areaDragged = this.pipelineSharedData.onDropAreaMouseIn(
            dropAreaId,
          );
          if (areaDragged) {
            this.pipelineSharedData.cardViewStates[
              areaDragged
            ] = this.pipelineSharedData.cardViewStates.cardOnDrag;
          }
        }
      });
      return true;
    };
  }

  /**
   * This method is tighly coupled for oppurtunity behavior
   * You can copy this to you subclass and change the action on itemMovedPostCallback
   */
  updateCustomerSortString(sortItemData) {
    this.settings.updateCardList({
      primaryStorageKey: 'customerSort',
      sortItemData,
      isAppendSortHistory: true,
    });
  }

  setOptionItemMoved() {
    this.customOptions.itemMovedPostCallback = (
      itemToHighlight,
      destinationColumn,
    ) => {
      const { loanId } = itemToHighlight;
      const { pipelineStatusId } = destinationColumn;
      if (this.pipelineSharedData.isMergeLeadAndOpportunities) {
        const params = {
          card: {
            toPipelineStatusId: destinationColumn.pipelineStatusId,
            fromPipelineStatusId: itemToHighlight.pipelineStatusId,
            loanId: itemToHighlight.loanId,
            loanScenarioId: itemToHighlight.loanScenarioId,
            pipelineCardsId: itemToHighlight.pipelineCardsID,
          },
          isConversion: false,
          loanOpportunityService: this.loanOpportunityService,
        };
        return moveLoanOpportunityItem(params);
      }
      return this.loanOpportunityService.setLoanOpportunityStatus(
        loanId,
        pipelineStatusId,
      );
    };

    this.customOptions.itemMovedPostSuccessCallback = (
      itemToHighlight,
      destinationColumn,
    ) => {
      toastr.success('Opportunity successfully moved!', 'Success');
      const card =
        destinationColumn.cards.find(
          (o) => o.loanId === itemToHighlight.loanId,
        ) || {};
      card.pipelineStatusId = destinationColumn.pipelineStatusId;
      card.lastModifiedDate = moment(new Date()).format();
      const sortItemData = getKanbanSortItem(
        itemToHighlight,
        destinationColumn,
        'loanId',
        true,
      );
      this.updateCustomerSortString(sortItemData);
      this.getLoanOpportunityTotalLoanAmounts();

      card.subStatusID = null;
      card.subStatusName = '';
    };

    this.customOptions.itemConvertPostCallback = (
      opportunityId,
      pipelineStatusId,
      selectedReasonId,
      shouldSendNurtureCampaigns,
      itemToHighlight,
    ) => {
      const conversionStatus = {
        ConversionStatusId: pipelineStatusId,
        ReasonSubStatusId: selectedReasonId || 0,
      };

      if (this.pipelineSharedData.isMergeLeadAndOpportunities) {
        const params = {
          card: {
            loanId: itemToHighlight.loanId,
            conversionStatusId: pipelineStatusId,
            reasonSubStatusId: selectedReasonId || 0,
            loanScenarioId: itemToHighlight.loanScenarioId,
            fromPipelineStatusId: itemToHighlight.pipelineStatusId,
            pipelineCardsId: itemToHighlight.pipelineCardsID,
          },
          isConversion: true,
          loanOpportunityService: this.loanOpportunityService,
        };
        return moveLoanOpportunityItem(params);
      }

      return this.loanOpportunityService.setConversion(
        opportunityId,
        conversionStatus,
      );
    };

    this.customOptions.itemConvertPostSuccessCallback = () => {
      toastr.success('Opportunity successfully converted!', 'Success');
      this.getLoanOpportunityTotalLoanAmounts();
      this.onRefreshStats();
    };

    this.options.itemMoved = (event) => {
      kanbanItemMoved({
        event,
        pipelineSharedData: this.pipelineSharedData,
        pipelineService: this.pipelineService,
        contactService: this.contactService,
        itemMovedPostCallback: this.customOptions.itemMovedPostCallback,
        itemMovedPostSuccessCallback: this.customOptions
          .itemMovedPostSuccessCallback,
        itemConvertPostCallback: this.customOptions.itemConvertPostCallback,
        itemConvertPostSuccessCallback: this.customOptions
          .itemConvertPostSuccessCallback,
        sourceModelProp: 'card',
        targetIdProp: 'loanScenarioId',
        modalRenderService: this.modalRenderService,
        reasonList: this.reasonList,
        sourcePipelineType: PIPELINE_CATEGORIES.OPPORTUNITY,
      });
    };

    this.options.orderChanged = (event) => {
      const card =
        event &&
        event.source &&
        event.source.itemScope &&
        event.source.itemScope.card;
      const destination =
        event &&
        event.dest &&
        event.dest.sortableScope &&
        event.dest.sortableScope.$parent &&
        event.dest.sortableScope.$parent.$parent &&
        event.dest.sortableScope.$parent.$parent.$parent &&
        event.dest.sortableScope.$parent.$parent.$parent.column;
      const sortItemData = getKanbanSortItem(card, destination, 'loanId', true);
      this.updateCustomerSortString(sortItemData);
    };
  }

  /*
   * will reuse itemMovedPostCallback
   */
  customItemMove(
    itemToHighlight,
    pipelineStatusId,
    isConversion,
    selectedReasonId,
  ) {
    const itemMovedPostSuccessCallback = () => {
      if (!isConversion) {
        toastr.success('Opportunity successfully moved!', 'Success');
      }
      itemToHighlight.pipelineStatusId = pipelineStatusId;
      itemToHighlight.lastModifiedDate = moment(new Date()).format();
      const destinationColumn = this.columns.find(
        (o) => o.pipelineStatusId === pipelineStatusId,
      );
      const sortItemData = getFirstSortItem(
        itemToHighlight,
        destinationColumn,
        'loanId',
      );
      this.updateCustomerSortString(sortItemData);
      this.getLoanOpportunityTotalLoanAmounts();
      return { isSuccess: true };
    };

    return kanbanCustomItemMove({
      itemMovedPostCallback: this.customOptions.itemMovedPostCallback,
      itemMovedPostSuccessCallback,
      itemToHighlight,
      destinationColumn: { pipelineStatusId },
      isConversion,
      targetIdProp: 'loanScenarioId',
      itemConvertPostCallback: this.customOptions.itemConvertPostCallback,
      itemConvertPostSuccessCallback: this.customOptions
        .itemConvertPostSuccessCallback,
      selectedReasonId,
      sourcePipelineType: PIPELINE_CATEGORIES.OPPORTUNITY,
    });
  }

  /**
   * this is a tightly coupled method that supports the model being used in
   * 'Pipeline/opportunity/views/cards' API (Cards & TotalLoanAmounts collection) - please see documentation
   */
  convertCardsToColumns(generalCards, totalLoanAmounts, statusList) {
    const columns = statusList.reduce((accum, current) => {
      const {
        PipelineStatus: name,
        PipelineStatusID: pipelineStatusId,
      } = current;
      const filterCards = generalCards.filter(
        (card) => card.pipelineStatusId === pipelineStatusId,
      );

      filterCards.map(opportunityCardMap);
      const [firstCard] = filterCards;
      return accum.concat({
        name,
        pipelineStatusId,
        cards: filterCards,
        totalRecords: (firstCard && firstCard.totalRecords) || 0,
        pageNumber: 1,
      });
    }, []);

    this.columns = columns;
    this.putTotalLoanAmountsToColumns(totalLoanAmounts);
    this.setWidth();
    this.setColumnSortMode();
  }

  putTotalLoanAmountsToColumns(totalLoanAmounts) {
    this.columns.map((column) => {
      const filterTotalLoanAmount = totalLoanAmounts.find(
        (o) => o.statusId === column.pipelineStatusId,
      ) || { totalLoanAmount: 0 };
      const { totalLoanAmount } = filterTotalLoanAmount;

      column.totalLoanAmount = totalLoanAmount;
      return column;
    });
  }

  getLoanOpportunityTotalLoanAmounts() {
    this.loanOpportunityService
      .getLoanOpportunityTotalLoanAmounts()
      .then((totalLoanAmounts) =>
        this.putTotalLoanAmountsToColumns(totalLoanAmounts),
      );
  }

  setReasons() {
    getNotProceededReasonList({
      isMergeLeadAndOpportunities: this.pipelineSharedData
        .isMergeLeadAndOpportunities,
      loanOpportunityService: this.loanOpportunityService,
    }).then((res) => {
      if (!res || !res.data) {
        return;
      }
      const { data } = res;
      this.reasonList = data;
    });
  }
}

/*
 * To be used in pipeline Applicationm
 */
export class KanbanApplication extends Kanban {
  constructor(args = {}) {
    const {
      $window,
      pipelineSharedData,
      pipelineService,
      modalRenderService,
      loanOpportunityService,
      $timeout,
      name,
      columns,
      generalCards,
      totalLoanAmounts,
      statusList,
      settings,
      $q,
      onRefreshStats,
      configService,
      contactService,
      importantDatesService,
      loanScenarioModelService,
    } = args;
    super(
      $window,
      pipelineSharedData,
      pipelineService,
      modalRenderService,
      loanOpportunityService,
      $timeout,
      name,
      columns,
      settings,
      $q,
      configService,
      contactService,
      importantDatesService,
      loanScenarioModelService,
    );

    this.updateCustomerSortString = this.updateCustomerSortString.bind(this);
    this.setOptionItemMoved = this.setOptionItemMoved.bind(this);
    this.getApplicationTotalLoanAmounts = this.getApplicationTotalLoanAmounts.bind(
      this,
    );
    this.onRefreshStats = onRefreshStats;

    this.setOptionAccept();
    this.setOptionItemMoved();
    this.setReasons();
    this.convertCardsToColumns(generalCards, totalLoanAmounts, statusList);
    this.showImportantDatesModal = showImportantDatesModal(this);
  }

  setOptionAccept() {
    this.options.accept = (sourceItemHandleScope, destSortableScope) => {
      this.pipelineSharedData.cardViewStates.isNotProceedingDropAreaEnabled = false;
      this.pipelineSharedData.cardViewStates.isSettledDropAreaEnabled = false;
      this.$timeout(() => {
        if (
          destSortableScope &&
          destSortableScope.element &&
          destSortableScope.element.length
        ) {
          const dropAreaId = destSortableScope.element[0].id;
          const areaDragged = this.pipelineSharedData.onDropAreaMouseIn(
            dropAreaId,
          );
          if (areaDragged) {
            this.pipelineSharedData.cardViewStates[
              areaDragged
            ] = this.pipelineSharedData.cardViewStates.cardOnDrag;
          }
        }
      });
      return true;
    };
  }

  /**
   * This method is tighly coupled for oppurtunity behavior
   * You can copy this to you subclass and change the action on itemMovedPostCallback
   */
  updateCustomerSortString(sortItemData) {
    this.settings.updateCardList({
      primaryStorageKey: 'customSort',
      sortItemData,
      isAppendSortHistory: true,
    });
  }

  setOptionItemMoved() {
    this.customOptions.itemMovedPostCallback = (
      itemToHighlight,
      destinationColumn,
    ) => {
      const { loanID } = itemToHighlight;
      const { pipelineStatusId: PipelineStatusId } = destinationColumn;
      return this.pipelineService.putApplicationLoanStatus(loanID, {
        PipelineStatusId,
        ShouldSendNurtureCampaigns: false,
      });
    };

    this.customOptions.itemMovedPostSuccessCallback = (
      itemToHighlight,
      destinationColumn,
    ) => {
      toastr.success('Application successfully moved!', 'Success');
      const card =
        destinationColumn.cards.find(
          (o) => o.loanID === itemToHighlight.loanID,
        ) || {};
      card.pipelineStatusID = destinationColumn.pipelineStatusId;
      card.lastModifiedDate = moment(new Date()).format();
      card.loanStatusID = getLoanStatusId(destinationColumn.pipelineStatusId);

      const sortItemData = getKanbanSortItem(
        itemToHighlight,
        destinationColumn,
        'loanID',
        true,
      );
      this.updateCustomerSortString(sortItemData);
      this.getApplicationTotalLoanAmounts();
      this.clientFamilyId = card.clientFamilyID;
      this.showImportantDatesModal(card.loanID, card.loanStatusID);
    };

    this.customOptions.itemConvertPostCallback = (
      loanID,
      pipelineStatusId,
      selectedReasonId,
      shouldSendNurtureCampaigns,
    ) => {
      const args = {
        LoanId: loanID,
        ConversionStatusId: pipelineStatusId,
        ReasonSubstatusId: selectedReasonId,
        ShouldSendNurtureCampaigns: shouldSendNurtureCampaigns,
      };
      return this.pipelineService.putApplicationLoanConversionStatus(
        loanID,
        args,
      );
    };

    this.customOptions.itemConvertPostSuccessCallback = (
      isApproveApplication,
      response,
      itemToHighlight,
      pipelineStatusId,
    ) => {
      this.itemConvertPostSuccessCallbackAction();
      const { loanID, clientFamilyID, loanScenarioID } = itemToHighlight;

      this.clientFamilyId = clientFamilyID;
      if (pipelineStatusId === PIPELINE_STATUS.SETTLED) {
        if (
          this.showImportantDatesModal(loanID, LOAN_STATUS.EXISTING.SETTLED)
        ) {
          return;
        }
        this.modalRenderService.openMovedToSettledModal({
          size: 'modalLinkLoanToPipeline',
          familyID: 0,
          loanId: loanID,
          loanScenarioId: loanScenarioID,
        });
      }
    };

    this.itemConvertPostSuccessCallbackAction = () => {
      toastr.success('Application successfully converted!', 'Success');
      this.getApplicationTotalLoanAmounts();
      this.onRefreshStats();
    };

    this.options.itemMoved = (event) => {
      kanbanItemMoved({
        event,
        pipelineSharedData: this.pipelineSharedData,
        itemMovedPostCallback: this.customOptions.itemMovedPostCallback,
        itemMovedPostSuccessCallback: this.customOptions
          .itemMovedPostSuccessCallback,
        itemConvertPostCallback: this.customOptions.itemConvertPostCallback,
        itemConvertPostSuccessCallback: this.customOptions
          .itemConvertPostSuccessCallback,
        sourceModelProp: 'card',
        targetIdProp: 'loanID',
        modalRenderService: this.modalRenderService,
        reasonList: this.reasonList,
        isShowNurtureCampaignsQuestion: true,
        contactService: this.contactService,
        opportunityNewModalService: this.opportunityNewModalService,
        loanScenarioModelService: this.loanScenarioModelService,
        sourcePipelineType: PIPELINE_CATEGORIES.APPLICATION,
      });
    };

    this.options.orderChanged = (event) => {
      const card =
        event &&
        event.source &&
        event.source.itemScope &&
        event.source.itemScope.card;
      const destination =
        event &&
        event.dest &&
        event.dest.sortableScope &&
        event.dest.sortableScope.$parent &&
        event.dest.sortableScope.$parent.$parent &&
        event.dest.sortableScope.$parent.$parent.$parent &&
        event.dest.sortableScope.$parent.$parent.$parent.column;
      const sortItemData = getKanbanSortItem(card, destination, 'loanID', true);
      this.updateCustomerSortString(sortItemData);
    };

    this.customOptions.forceItemMove = (
      card,
      primaryKey,
      sourcePipelineStatusId,
    ) => {
      const destinationPipelineStatusId = card.pipelineStatusID;

      const sourceColumn =
        this.columns.find(
          (o) => o.pipelineStatusId === sourcePipelineStatusId,
        ) || null;
      const destinationColumn =
        this.columns.find(
          (o) => o.pipelineStatusId === destinationPipelineStatusId,
        ) || null;
      if (!sourceColumn || !destinationColumn) {
        return;
      }

      const sourceIdx = sourceColumn.cards.findIndex(
        (o) => o[primaryKey] === card[primaryKey],
      );
      if (sourceIdx === -1) {
        return;
      }

      sourceColumn.cards.splice(sourceIdx, 1);
      destinationColumn.cards.push({ ...card });

      sourceColumn.totalRecords--;
      destinationColumn.totalRecords++;

      this.getApplicationTotalLoanAmounts();
    };
  }

  /*
   * will reuse itemMovedPostCallback
   */
  customItemMove(
    itemToHighlight,
    pipelineStatusId,
    isConversion,
    selectedReasonId,
  ) {
    const itemMovedPostSuccessCallback = () => {
      if (!isConversion) {
        toastr.success('Application successfully moved!', 'Success');
      }
      itemToHighlight.pipelineStatusID = pipelineStatusId;
      itemToHighlight.lastModifiedDate = moment(new Date()).format();
      itemToHighlight.loanStatusId = getLoanStatusId(pipelineStatusId);

      const destinationColumn = this.columns.find(
        (o) => o.pipelineStatusId === pipelineStatusId,
      );
      const sortItemData = getFirstSortItem(
        itemToHighlight,
        destinationColumn,
        'loanID',
      );
      this.updateCustomerSortString(sortItemData);
      this.getApplicationTotalLoanAmounts();
      return { isSuccess: true };
    };

    return kanbanCustomItemMove({
      itemMovedPostCallback: this.customOptions.itemMovedPostCallback,
      itemMovedPostSuccessCallback,
      itemToHighlight,
      destinationColumn: { pipelineStatusId },
      isConversion,
      targetIdProp: 'loanID',
      itemConvertPostCallback: this.customOptions.itemConvertPostCallback,
      itemConvertPostSuccessCallback: this.customOptions
        .itemConvertPostSuccessCallback,
      selectedReasonId,
      sourcePipelineType: PIPELINE_CATEGORIES.APPLICATION,
    });
  }

  /**
   * this is a tightly coupled method that supports the model being used in
   * 'Pipeline/applicatio/views/kanban' API (Cards & TotalLoanAmounts collection) - please see documentation
   */
  convertCardsToColumns(generalCards, totalLoanAmounts, statusList) {
    const columns = statusList.reduce((accum, current) => {
      const {
        PipelineStatus: name,
        PipelineStatusID: pipelineStatusId,
      } = current;
      const filterCards = generalCards.filter(
        (card) => card.pipelineStatusID === pipelineStatusId,
      );

      filterCards.map(applicationCardMap);
      const [firstCard] = filterCards;
      return accum.concat({
        name,
        pipelineStatusId,
        cards: filterCards,
        totalRecords: (firstCard && firstCard.totalRecords) || 0,
        pageNumber: 1,
      });
    }, []);

    this.columns = columns;
    this.putTotalLoanAmountsToColumns(totalLoanAmounts);
    this.setWidth();
    this.setColumnSortMode();
  }

  putTotalLoanAmountsToColumns(totalLoanAmounts) {
    this.columns.map((column) => {
      const filterTotalLoanAmount = totalLoanAmounts.find(
        (o) => o.statusId === column.pipelineStatusId,
      ) || { totalLoanAmount: 0 };
      const { totalLoanAmount } = filterTotalLoanAmount;

      column.totalLoanAmount = totalLoanAmount;
      return column;
    });
  }

  getApplicationTotalLoanAmounts() {
    const { searchClientName } = this.settings || {};

    this.pipelineService
      .getApplicationTotalLoanAmounts({ searchClientName })
      .then((totalLoanAmounts) =>
        this.putTotalLoanAmountsToColumns(totalLoanAmounts.data),
      );
  }

  getPlainApplicationTotalLoanAmounts() {
    const { searchClientName } = this.settings || {};

    return this.pipelineService.getApplicationTotalLoanAmounts({
      searchClientName,
    });
  }

  setReasons() {
    this.pipelineService
      .getPipelineCategoryReasons(PIPELINE_CATEGORIES.APPLICATION)
      .then((res) => {
        if (!res || !res.data) {
          return;
        }
        const { data } = res;
        this.reasonList = data;
      });
  }
}
