import { initializePopover } from 'Common/utilities/importantDates';
import { getMonthList } from 'Common/utilities/month';
import { findIndexWithAttr } from 'Common/utilities/array';
import { annualReviewFeature } from 'Common/utilities/insurancePipeline';

class ImportantDatesPopoverCtrl {
  constructor(
    $q,
    $timeout,
    timeoutService,
    importantDatesService,
    contactService,
    loanFinancialsService,
    insurancePipelineService,
    currentUserService,
    configService,
    loanScenarioModelService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$timeout = $timeout;
    this.timeoutService = timeoutService;
    this.importantDatesService = importantDatesService;
    this.contactService = contactService;
    this.loanFinancialsService = loanFinancialsService;
    this.insurancePipelineService = insurancePipelineService;
    this.currentUserService = currentUserService;
    this.configService = configService;
    this.loanScenarioModelService = loanScenarioModelService;
  }

  $onInit() {
    this.isReviewMonth = false;
    this.isLoading = false;
    this.reviewMonthId = 0;
    this.reviewMonth = '';
    this.currentYear = new Date().getFullYear();
    this.monthsList = [];
    this.getMonthsList();
    initializePopover(this);
  }

  getContactFamilyInfo() {
    this.contactService.contactFamilyInfoGet(this.familyId).then((response) => {
      const { data } = response;
      if (data) {
        return;
      }

      this.reviewMonth = data.ReviewMonth;
      this.isReviewMonth = !!data.ReviewMonth;
      this.reviewMonthId =
        findIndexWithAttr(this.monthsList, 'name', this.reviewMonth) + 1;
    });
  }

  getMonthsList() {
    getMonthList().map((item, index) => {
      const month = {
        name: item,
        id: index,
      };
      if (item !== 'All Months') {
        this.monthsList.push(month);
      }
      return item;
    });
    if (this.configService.feature.annualReviewMonthNZ) {
      annualReviewFeature(this);
    }
    initializePopover(this);
  }

  openDate(key) {
    this.isOpenDate[key] = true;
  }

  confirmDates() {
    if (this.configService.feature.annualReviewMonthNZ) {
      this.updateReviewMonth();
    }
    this.confirmDatesFunc(this.importantDatesService);
  }

  closeModal() {
    this.modalInstance.close();
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('ImportantDatesPopoverCtrl');
  }
}

export default ImportantDatesPopoverCtrl;
