import template from './sendSms.html';
import controller from './sendSmsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    sms: '<?',
    recipientsChoices: '<',
    showManageTemplate: '<?',
    onSendSms: '&',
    onSendSmsSuccess: '&?',
    allowCustom: '<',
    autoSelectAll: '<',
    contactPrimaryKey: '@',
  },
};
