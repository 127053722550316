import { generateFamilyList as utilGenerateFamilyList } from 'Common/utilities/loanApplication';

class LendingChooseProductsCtrl {
  constructor(serviceabilityService, modalRenderService) {
    'ngInject';

    this.serviceabilityService = serviceabilityService;
    this.modalRenderService = modalRenderService;
  }

  $onInit() {
    this.generateFamilyList();
  }

  generateFamilyList() {
    this.familyList = utilGenerateFamilyList(this.involvedPartyPerson);
  }

  closeModal(data) {
    this.modalInstance.close(data);
  }

  openProductFinderModal() {
    this.closeModal({ callback: Promise.resolve({ isRefresh: false }) });

    this.cbProductFinder({ facility: this.facility });
  }

  openSavedComparisonModal() {
    const { familyList, facility } = this;

    const savedProductComparison = this.modalRenderService.openSavedProductComparisonModal(
      {
        familyList,
        facility,
      },
    ).result;

    this.closeModal({ callback: savedProductComparison });
  }
}

export default LendingChooseProductsCtrl;
