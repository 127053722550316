import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { moveLoanOpportunityItem } from 'Common/utilities/kanbanHelper';

export function opportunityConvert(
  pipelineSharedData,
  modalRenderService,
  loanOpportunityService,
  pipelineStatusId,
  opportunityId,
  reasonList,
  payload,
) {
  const movedToStatus =
    pipelineStatusId === PIPELINE_STATUS.APPLICATION
      ? 'Application'
      : 'Not Proceeded';
  const isApproveApplication = pipelineStatusId === PIPELINE_STATUS.APPLICATION;
  const modalContent = {
    content: `This record will be moved to ${movedToStatus}`,
  };
  if (!isApproveApplication) {
    modalContent.reasonList = reasonList;
  }
  return modalRenderService
    .renderPipelineStatusMoveConfirmationModal(modalContent)
    .result.then((result) => {
      const { isOk } = result;
      if (!isOk) {
        return false;
      }

      const { selectedReasonId } = result;
      const conversionStatus = {
        ConversionStatusId: pipelineStatusId,
        ReasonSubStatusId: selectedReasonId || 0,
      };

      if (pipelineSharedData.isMergeLeadAndOpportunities) {
        payload.reasonSubStatusId = selectedReasonId || 0;
        const params = {
          card: payload,
          isConversion: true,
          loanOpportunityService,
        };
        return moveLoanOpportunityItem(params);
      }

      return loanOpportunityService.setConversion(
        opportunityId,
        conversionStatus,
      );
    })
    .then((renderResponse) => {
      if (!renderResponse) {
        return { isOk: false };
      }

      return isApproveApplication && !renderResponse.data.IsVisibleInPipeline
        ? modalRenderService.cardHiddenInApplication(
            renderResponse.data.FirstFamilyBorrowerName,
          ).result
        : { isOk: true };
    });
}

export function leadsToOpportunityConvert(
  clientFamilyId,
  contactService,
  opportunityNewModalService,
  redirectParams = {},
  pipelineItemId,
) {
  return contactService.clientInformGet(clientFamilyId).then((res) => {
    const { data } = res;
    if (!data) {
      return;
    }

    const clients =
      data.filter((obj) => obj.Role === 'Adult' || obj.isEntity) || [];

    const isLeadsToOpportunity = true;

    return opportunityNewModalService.openNewOpportunityModal(
      clientFamilyId,
      clients,
      redirectParams,
      pipelineItemId,
      isLeadsToOpportunity,
    );
  });
}

export function getNotProceededReasonList({
  isMergeLeadAndOpportunities = false,
  loanOpportunityService,
}) {
  if (isMergeLeadAndOpportunities) {
    return loanOpportunityService.getNotProceededReasons();
  }
  return loanOpportunityService.getReasonSubstatus();
}
