import angular from 'angular';
import SubscriptionBrokerPediaModal from './subscriptionBrokerPediaModal/subscriptionBrokerPediaModal';
import SubscriptionPriceFinderModal from './subscriptionPriceFinderModal/subscriptionPriceFinderModal';
import SubscriptionCreditCheckModal from './subscriptionCreditCheckModal/subscriptionCreditCheckModal';
import SubscriptionESignModal from './subscriptionESignModal/subscriptionESignModal';
import SubscriptionModal from './subscriptionModal';

export default angular
  .module('components.subscriptionModal', [])
  .component('subscriptionBrokerPediaModal', SubscriptionBrokerPediaModal)
  .component('subscriptionPriceFinderModal', SubscriptionPriceFinderModal)
  .component('subscriptionCreditCheckModal', SubscriptionCreditCheckModal)
  .component('subscriptionModal', SubscriptionModal)
  .component('subscriptionESignModal', SubscriptionESignModal).name;
