import { LEADS_STATUS } from 'Common/constants/adviserStatus';
import { allocation } from 'Common/default/leadsProfile';

class UsersLeadManagementCtrl {
  constructor(corporateService, generalService, configService) {
    'ngInject';

    this.corporateService = corporateService;
    this.generalService = generalService;
    this.configService = configService;
  }

  getLeadProfile() {
    if (!this.familyId) {
      return;
    }
    this.corporateService
      .leadsProfileGet(this.familyId, 0)
      .then((response) => {
        if (!response || !response.data) {
          this.isLoadingLeads = false;
          return;
        }
        this.leadProfileInfo = response.data;
        this.isEditable = this.leadProfileInfo.ReceiveStatus !== 1 || false;
        this.isLeadsEnable =
          this.leadProfileInfo.ReceiveStatus === LEADS_STATUS.ACTIVE;
        this.maxLeadsAllocation =
          this.leadProfileInfo.AllocationCapPerMonth > allocation.maxLeads
            ? this.leadProfileInfo.AllocationCapPerMonth
            : allocation.maxLeads;
        if (
          !this.configService.feature.alternativeLeadsLocation ||
          !this.leadProfileInfo.LRLAddressId
        ) {
          this.isLoadingLeads = false;
          return;
        }
        this.generalService
          .addressDetailsGet(this.leadProfileInfo.LRLAddressId)
          .then(({ data }) => {
            this.isLoadingLeads = false;
            if (!data) {
              return;
            }
            this.mapAddressAtrr = data;
            this.addressObj.leads = data;
          });
      })
      .catch(() => {
        this.isLoadingLeads = false;
      });
  }

  getLeadProfileAddress() {
    if (!this.familyId) {
      return;
    }
    this.mapAddressAtrr = {};
    this.corporateService.familyAddressGet(this.familyId).then((response) => {
      this.getLeadProfile();
      if (!response || !response.data) {
        return;
      }
      const bussinessAddressValue = response.data.filter(
        (address) => address.isBusiness,
      );
      const mailingAddressValue = response.data.filter(
        (address) => address.isMailing,
      );
      const homeAddressValue = response.data.filter(
        (address) => !address.isMailing && !address.isBusiness,
      );
      if (bussinessAddressValue && bussinessAddressValue.length) {
        this.mapAddressAtrr = bussinessAddressValue[0];
      } else if (mailingAddressValue && mailingAddressValue.length) {
        this.mapAddressAtrr = mailingAddressValue[0];
      } else {
        this.mapAddressAtrr =
          homeAddressValue && homeAddressValue.length
            ? homeAddressValue[0]
            : {};
      }
      this.addressObj.default = this.mapAddressAtrr;
    });
  }

  $onInit() {
    this.isLoadingLeads = true;
    this.addressObj = {
      default: {},
      leads: {},
    };
    this.getLeadProfileAddress();
  }
}

export default UsersLeadManagementCtrl;
