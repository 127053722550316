import template from './helloPackInvolvedParties.html';
import controller from './helloPackInvolvedPartiesCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    clientId: '<?',
    model: '<',
    filters: '<',
    onClientUpdate: '&',
    handler: '<',
  },
};
