import { viewOfAFileTheme } from 'Common/default/modal';
import { toastError, toastWarning } from 'Common/utilities/alert';
import { OK } from 'Common/constants/httpStatusCodes';

const saveInitialStatusFailed = {};

class CreateNewInsuranceFileModalCtrl {
  constructor(
    $state,
    $location,
    $timeout,
    insuranceFileService,
    pipelineApiService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$location = $location;
    this.$timeout = $timeout;
    this.insuranceFileService = insuranceFileService;
    this.pipelineApiService = pipelineApiService;
  }

  $onInit() {
    this.defaultTheme = viewOfAFileTheme;
    this.modalTitle = this.file?.id
      ? 'Edit insurance file'
      : 'Create new insurance file';

    this.pipelineStatuses = [...this.statuses];

    this.newFile = {
      InsuranceFileId: this.file?.id || 0,
      FamilyId: this.familyId || 0,
      FileName: this.file?.name || null,
      PipelineStatus: this.file?.statusId
        ? this.pipelineStatuses.find((x) => x.id === this.file?.statusId)
        : null,
    };

    this.isExistingFile = this.newFile.InsuranceFileId > 0;
  }

  onCancel() {
    this.modalInstance.close();
  }

  onSave() {
    const findCommonFileName = this.files.find(
      (file) =>
        file.name?.toLowerCase() === this.newFile.FileName?.toLowerCase(),
    );

    const ownFile = findCommonFileName?.id === this.newFile.InsuranceFileId;

    if (findCommonFileName && !ownFile) {
      return toastError('Insurance File Name is already in use');
    }

    this.isSaving = true;

    return this.isExistingFile ? this.onSaveExisting() : this.onSaveNew();
  }

  onSaveExisting() {
    return this.insuranceFileService
      .putInsuranceFile(this.newFile)
      .then((id) => {
        if (!id) {
          throw new Error('Save failed');
        }

        this.modalInstance.close({ refresh: true });
      })
      .catch(() => {
        return toastError();
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  onSaveNew() {
    return this.insuranceFileService
      .postInsuranceFile(this.newFile)
      .then((id) => {
        if (!id) {
          throw new Error('Save failed');
        }

        return this.saveStatusWithRetry(id, this.newFile.PipelineStatus.id);
      })
      .then((updateResult) => {
        this.modalInstance.close({ refresh: false });

        this.$location.path(`/insurance/${updateResult.fileId}/summary`);
      })
      .catch((error) => {
        if (error === saveInitialStatusFailed) {
          this.modalInstance.close({ refresh: true });
          toastWarning(
            'The new file was created but the initial file status could not be set.',
          );
        }

        toastError();
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  saveStatusWithRetry(fileId, statusId) {
    const retryIntervals = [800, 1000, 1800];
    let retryCounter = 0;

    return new Promise((resolve, reject) => {
      const triggerSave = () => {
        this.pipelineApiService
          .updateInsuranceFileStatus(fileId, statusId)
          .then((response) => {
            if (response.status !== OK) {
              throw new Error('Error setting the insurance file status');
            }

            resolve(response);
          })
          .catch(() => {
            if (retryCounter < retryIntervals.length) {
              this.$timeout(() => {
                triggerSave();
              }, retryIntervals[retryCounter]);

              retryCounter++;
            } else {
              reject(saveInitialStatusFailed);
            }
          });
      };

      triggerSave();
    });
  }
}

export default CreateNewInsuranceFileModalCtrl;
