export const ASSET_FINANCE_FEES = [
  {
    objKey: 'EstablishmentFee',
    description: 'Establishment Fees',
    payableWhen: 'at Settlement',
  },
  {
    objKey: 'AccountKeepingFee',
    description: 'Account Keeping Fees',
    payableWhen: 'Monthly',
  },
];
