import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('commonDownloadDirs', function commonDownloadDirs(
      $window,
      npsService,
      toaster,
      commissionService,
      $timeout,
    ) {
      return {
        restrict: 'A',
        scope: {
          sourceType: '@',
          paramsObject: '=',
          isDownloading: '=',
          buttonIndex: '=',
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        link(scope, element) {
          this.downloadFile = (data, currentIndex) => {
            if (data) {
              const eventTimeout = $timeout(() => {
                const byteString = $window.atob(data.DocumentContent);
                const a = $window.document.createElement('a');
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                  ia[i] = byteString.charCodeAt(i);
                }
                const blob = new $window.Blob([ia], { type: data.ContentType });
                a.href = $window.URL.createObjectURL(blob);
                a.download = data.Name;
                a.click();
                if (scope.isDownloading) {
                  scope.isDownloading[currentIndex] = false;
                }
                $timeout.cancel(eventTimeout);
              });
            }
          };
          this.customerMonitorSubmission = () => {
            npsService
              .getDataForCustomerMonitorSubmission()
              .then((response) => {
                if (response && response.data) {
                  this.downloadFile(response.data, 0);
                } else {
                  toaster.pop(
                    'info',
                    'No available data for customer monitor data.',
                  );
                }
              });
          };
          this.exportCsv = (paramObj) => {
            const generateForSelectedAdviser =
              paramObj.survModelSelectedAdviser > 0;
            const noResponse = paramObj.survModelNoResponse ? 1 : 0;
            npsService
              .getSurveyResponseCsv(
                paramObj.survModelTimeOptionDB,
                noResponse,
                paramObj.survModelSelectedAdviser,
                generateForSelectedAdviser,
              )
              .then((response) => {
                if (response && response.data) {
                  this.downloadFile(response.data, 0);
                } else {
                  toaster.pop('info', 'No available data for export.');
                }
              });
          };
          this.commissionReports = (currentIndex, key) => {
            if (key) {
              commissionService.report(key).then(
                (response) => {
                  const data = response.data;
                  if (data) {
                    this.downloadFile(data, currentIndex);
                  } else {
                    toaster.pop('info', 'No downloadable file for this key.');
                    scope.isDownloading[currentIndex] = false;
                  }
                },
                () => {
                  scope.isDownloading[currentIndex] = false;
                },
              );
            }
          };
          const eventHandler = () => {
            if (scope.isDownloading) {
              scope.isDownloading[scope.buttonIndex] = true;
            }
            switch (scope.sourceType) {
              case 'customerMonitorSubmission':
                this.customerMonitorSubmission();
                break;
              case 'exportCsv':
                this.exportCsv(scope.paramsObject);
                break;
              case 'commissionReports': {
                const key = scope.paramsObject.key;
                this.commissionReports(scope.buttonIndex, key);
                break;
              }
              default:
                break;
            }
          };
          element.on('click', eventHandler);
          scope.$on('$destroy', () => {
            element.off('click', eventHandler);
          });
        },
      };
    });
