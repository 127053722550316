import angular from 'angular';

export const loadModule = () =>
  angular.module('app').controller(
    'InsAppFinancialExpenseCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function InsAppFinancialExpenseCtrl(
      $rootScope,
      $scope,
      $timeout,
      $interval,
      $compile,
      contactService,
      loanScenarioService,
      loanApplicationServices,
      toaster,
      $state,
      $stateParams,
      utilitiesService,
      $uibModal,
    ) {
      $scope.loanAppId = $stateParams.loanAppId;

      $scope._expenseInit = function () {
        $scope.ExpenseSet = {};

        $scope.frequencyTypes = [
          { Desc: 'W', Value: 'Weekly' },
          { Desc: 'F', Value: 'Fortnightly' },
          { Desc: 'M', Value: 'Monthly' },
          { Desc: 'Y', Value: 'Yearly' },
        ];

        // Temporary / should call get api
        $scope.listExpense = [];
      };
      $scope._expenseInit();

      $scope.getNewExpenseModel = function () {
        return {
          Type: '',
          Details: '',
          OwnershipInitials: utilitiesService.filterInitial(
            'Stanley',
            'Massey',
          ),
          Ownership: 'Stanley Massey',
          Frequency: 'm',
          Value: null,
        };
      };

      $scope.resetExpenseModel = function () {
        $scope.ExpenseSet = $scope.getNewExpenseModel();
      };

      // Save expense inline
      $scope.addExpense = function () {
        $scope.listExpense.push($scope.ExpenseSet);
        $scope.resetExpenseModel();
      };

      // Add Expense Modal
      $scope.newExpenseModal = function (type) {
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/loanApplication/clientAssess/modal/expense.add.html',
          controller: 'FinPosExpenseModalCtrl',
          size: 'sm',
          scope: $scope,
          resolve: {
            modalType() {
              return type;
            },
          },
        });

        modalInstance.result.then(
          () => {
            $scope.addExpense();
          },
          () => {
            $scope.addExpense();
          },
        );
      };

      $scope.modalType = false;
      //* ************************************/
      //  owners multi Select                /
      //* ************************************/
      $scope.localLang = {
        selectAll: '<span>Select All</span>',
        selectNone: '<span>Deselect All </span>',
        reset: "<i class='fa fa-refresh'></i>",
        search: 'Search',
        nothingSelected: "<div class='buttonLabel'>All Owners</div>", // default-label is deprecated and replaced with this.
      };
      $scope.ownersList = [];
      $scope.ownersListSelected = [];
      // eslint-disable-next-line unicorn/consistent-function-scoping
      function isOtherTicked(data) {
        let bool = false;
        data.forEach((val) => {
          if (val.ClientEntityId > -1 && val.ticked) {
            bool = true;
          }
        });

        return bool;
      }
      $scope.onItemSelect = function (data) {
        //
        if (parseInt(data.ClientEntityId, 10) === -1) {
          for (let i = 0; i < $scope.ownersList.length; i++) {
            if ($scope.ownersList[i].ClientEntityId > -1) {
              $scope.ownersList[i].ticked = false;
            } else {
              $scope.ownersList[i].ticked = true;
            }
          }
        } else {
          for (let i = 0; i < $scope.ownersList.length; i++) {
            if (
              parseInt($scope.ownersList[i].ClientEntityId, 10) === -1 &&
              !isOtherTicked($scope.ownersList)
            ) {
              $scope.ownersList[i].ticked = true;
              $scope.ownersListSelected.push($scope.ownersList[i]);
              //
            } else if (
              parseInt($scope.ownersList[i].ClientEntityId, 10) === -1
            ) {
              $scope.ownersList[i].ticked = false;
            }
          }
        }
      };

      $scope.ownersListSelectedArray = [];

      $scope.onSelectAll = function () {
        for (let i = 0; i < $scope.ownersList.length; i++) {
          if ($scope.ownersList[i].ClientEntityId > -1) {
            $scope.ownersList[i].ticked = false;
          } else {
            $scope.ownersList[i].ticked = true;
          }
        }
      };
    },
  );
