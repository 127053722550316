import angular from 'angular';
import { ANNUAL_REVIEWS_ID } from 'Common/constants/customerCareModules';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('AnnualReviewSMSModalCtrl', function AnnualReviewSMSModalCtrl(
      $scope,
      $uibModalInstance,
      $state,
      $controller,
      toaster,
      optionsService,
      contactService,
      customerCareService,
      annualReviewListTable,
      annualReviewObj,
      source,
      customerCareTemplateService,
      currentUserService,
      formErrorHandlerServices,
      loadAnnualReviewTable,
    ) {
      const vm = this;
      angular.extend(
        vm,
        $controller('CustomerCareSendMessageModalBaseCtrl', { $scope }),
      );

      $scope.recipients = [];
      $scope.smsList = [];
      $scope.isComplete = !currentUserService.isNZ;
      $scope.currentModule = ANNUAL_REVIEWS_ID;
      $scope.mergeFieldParams = annualReviewObj;

      const getCountryList = () => {
        optionsService.countryListGet().then(
          (response) => {
            $scope.countryList = response.data;
            const filteredCountry = $scope.countryList.filter((item) => {
              if (annualReviewObj.Country === item.CountryCode) {
                return item;
              }
              return false;
            });
            if (Array.isArray(filteredCountry) && filteredCountry.length > 0) {
              $scope.countryObj = filteredCountry[0];
              $scope.selectedCountryCode = $scope.countryObj.CountryCode;
              $scope.makeObject($scope.selectedCountryCode);
            }
          },
          () => {},
        );
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.makeObject = (selectedCountryCode) => {
        if (source === 'single' && annualReviewObj.Mobile !== '') {
          if (typeof selectedCountryCode !== 'undefined') {
            $scope.countryObj = $scope.countryList.filter((item) => {
              if (selectedCountryCode === item.CountryCode) {
                return item;
              }
              return false;
            });

            if (typeof $scope.countryObj !== 'undefined') {
              $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
            }
          }
          const notiHeader = `Annual Reviews Completed via SMS`;
          $scope.smsList.sms = [
            {
              FamilyFullname: annualReviewObj.FamilyFullName,
              LoanID: annualReviewObj.LoanID,
              FamilyID: annualReviewObj.FamilyID,
              MobileNumber: $scope.callingCode + annualReviewObj.Mobile,
              NotificationHeader: notiHeader,
            },
          ];
          $scope.recipients.email = [
            {
              EmailAddress: annualReviewObj.EmailAddress,
              FamilyFullname: annualReviewObj.FamilyFullName,
              MobileNumber: annualReviewObj.Mobile,
            },
          ];
        }
        $scope.annualReviewListTable = annualReviewListTable;

        if (source === 'all') {
          $scope.all = true;
          if (typeof selectedCountryCode !== 'undefined') {
            // eslint-disable-next-line sonarjs/no-identical-functions
            $scope.countryObj = $scope.countryList.filter((item) => {
              if (selectedCountryCode === item.CountryCode) {
                return item;
              }
              return false;
            });

            if (typeof $scope.countryObj !== 'undefined') {
              $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
            }
          }
          if ($scope.annualReviewListTable.length > 0) {
            $scope.selectSMSList = [];
            $scope.emailRecipients = [];

            for (let i = 0; i < $scope.annualReviewListTable.length; i++) {
              $scope.smsAdd = {};
              $scope.emailAdd = {};
              if ($scope.annualReviewListTable[i].Mobile !== '') {
                $scope.smsAdd.FamilyFullname =
                  $scope.annualReviewListTable[i].FamilyFullName;
                $scope.smsAdd.LoanID = $scope.annualReviewListTable[i].LoanID;

                $scope.smsAdd.FamilyID =
                  $scope.annualReviewListTable[i].FamilyID;
                $scope.smsAdd.MobileNumber =
                  $scope.callingCode + $scope.annualReviewListTable[i].Mobile;

                $scope.selectSMSList.push($scope.smsAdd);

                $scope.emailAdd.EmailAddress =
                  $scope.annualReviewListTable[i].EmailAddress;
                $scope.emailAdd.FamilyFullname =
                  $scope.annualReviewListTable[i].FamilyFullName;
                $scope.emailAdd.MobileNumber =
                  $scope.callingCode + $scope.annualReviewListTable[i].Mobile;
                $scope.emailRecipients.push($scope.emailAdd);
              }
            }
            if ($scope.selectSMSList.length > 0) {
              $scope.smsList.sms = $scope.selectSMSList;
              $scope.noOfPeople = $scope.selectSMSList.length;
            }
            if ($scope.emailRecipients.length > 0) {
              $scope.recipients.email = $scope.emailRecipients;
            }
          }
        }
      };
      $scope.countryChanged = (selectedCountryCode) => {
        $scope.makeObject(selectedCountryCode);
      };

      $scope.selectEmailList = [];
      $scope.selectedTemplate = [
        {
          UserTemplateID: 0,
          UserTemplateName: 'Blank',
        },
      ];

      $scope.refreshResults = (select) => {
        const search = select.search;

        $scope.getRecipientAutoSuggestion(search);
      };

      $scope.sendSMS = (form) => {
        const isFormValid = formErrorHandlerServices.sendEmailFormValidation(
          form,
        );
        if (!isFormValid) {
          return;
        }

        $scope.recipientsSMSArray = [];
        $scope.smsToSend = {};
        $scope.smsToSend.IsComplete = $scope.isComplete;

        $scope.smsToSend.Message = $scope.sms.Message;
        $scope.smsToSend.CustomerCareType = 'CLIENT_ANNUAL_REVIEW';
        angular.forEach($scope.smsList.sms, (item) => {
          $scope.recipientsSMSArray.push(item);
        });
        $scope.smsToSend.SmsModelList = $scope.recipientsSMSArray;

        customerCareService.CustomerCareSMSSend($scope.smsToSend).then(
          (annualReviewRes) => {
            if (annualReviewRes.data && annualReviewRes.data.length) {
              const annualReviewSMSValid = customerCareTemplateService.customerCareResponseMsg(
                annualReviewRes.data,
                'SMS',
              );
              if (annualReviewSMSValid) {
                loadAnnualReviewTable();
                $scope.smsToSend.Message = '';
                form.$setPristine(true);
              }
              $scope.cancel();
            }
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          },
        );
      };
      const init = () => {
        getCountryList();

        $scope.phone = annualReviewObj.MobileNo;
        $scope.content =
          '<div style="font-family: Arial;"><div><div><span style="font-family: Arial; font-size: 10pt;">Hi </span><span style="font-family: Arial; font-size: 10pt;">[Preferred Name]</span></div></div><br /><div>&nbsp;</div><div><span style="font-family: Arial; font-size: 10pt;">Best regards,</span></div><div><span style="font-family: Arial; font-size: 10pt;">[Your Name]</span></div></div><div>&nbsp;</div><div>[Email Signature]</div>';
        $scope.familyId = annualReviewObj.FamilyID;
      };

      init();
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
    });
