import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('CustomerCommentModalCtrl', function CustomerCommentModalCtrl(
      $scope,
      $uibModalInstance,
    ) {
      $scope.closeModal = () => {
        $uibModalInstance.dismiss('cancel');
      };
    });
