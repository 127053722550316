import { get } from 'lodash';
import { isToggledLmiProductMatch } from 'Common/utilities/lmiCalculator';
import { isEmpty } from 'Common/utilities/objectValidation';
import { toastError } from 'Common/utilities/alert';
import { getLoadingMessage } from './util/lmiCalculatorCtrl';
import { WARNING_LIMIT, LMI_NAME } from 'Common/constants/lmi';

const GOAL_TYPE = {
  LMI_AMOUNT: 0,
  TARGET_LVR: 1,
};
class LmiCalculatorCtrl {
  constructor(
    fundingCalculatorService,
    fundingCalculatorSharedData,
    fundingCalculatorDetailService,
    loanProfilerService,
    $timeout,
    $uibModal,
    configService,
  ) {
    'ngInject';

    this.fundingCalculatorService = fundingCalculatorService;
    this.fundingCalculatorSharedData = fundingCalculatorSharedData;
    this.fundingCalculatorDetailService = fundingCalculatorDetailService;
    this.loanProfilerService = loanProfilerService;
    this.timeout = $timeout;
    this.uibModal = $uibModal;
    this.configService = configService;
    this.mortgageObj = {};
    this.goalTypeValue = GOAL_TYPE.LMI_AMOUNT;
    this.selectedLenderObj = null;
    this.status = {
      empty: 'empty',
      waiting: 'waiting',
      done: 'done',
    };

    this.childComponentNotificationRegistration = this.childComponentNotificationRegistration.bind(
      this,
    );
  }

  savePassedLMIData(fundingCalcData) {
    const { quickLmiData, funds, proposedLending } = fundingCalcData;
    this.fundingCalculatorService
      .setFundingCalculatorFundsQuickLMI(quickLmiData)
      .then(() => {
        return this.fundingCalculatorService.setFundingCalculatorFundsIsQuickLMI(
          this.brokerEventId,
          false,
        );
      })
      .then(({ data }) => {
        if (!data) {
          return;
        }

        const fundingRequiredData = funds.find((item) => {
          return (
            item.fundingParticulars === LMI_NAME &&
            item.fundingType === 'FUNDING_REQUIRED'
          );
        });

        this.fundingCalculatorDetailService.quickLmiFundingCalculatorFunds =
          fundingRequiredData || {};
        const validProposedLending = proposedLending && proposedLending.length;
        this.fundingCalculatorDetailService.quickLmiProposedLending = validProposedLending
          ? proposedLending[0]
          : {};

        this.getFundingCalculatorFundsQuickLMI();
      });
  }

  getFundingCalculatorFundsQuickLMI() {
    this.fundingCalculatorService
      .getFundingCalculatorFundsQuickLMI(this.brokerEventId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        this.mortgageObj = data;
        this.isQuickCalc = !this.mortgageObj.IsQuickCalc;
        this.mortgageObj = this.fundingCalculatorSharedData.getQuickLmiMortgageObj(
          this.mortgageObj,
          this.brokerEventId,
        );

        this.getSecurities();
        if (this.isQuickCalc) {
          this.getFundingCalculatorFundsQuickLMIFavorite();
        } else {
          this.isPreloaderHide = true;
        }
      });
  }

  getFundingCalculatorFundsQuickLMIFavorite() {
    this.fundingCalculatorService
      .getFundingCalculatorFundsQuickLMIFavorite(this.brokerEventId)
      .then(({ data }) => {
        if (data && data.length) {
          this.selectedLenderObj = data[0];
          this.initialSelectedLender = data[0];
          this.lmiPremiumObj.status = this.status.waiting;
          this.getQuickLMICalculatorReport();
          this.isPreloaderHide = true;
        } else {
          this.preloaderShowHide();
          this.isModal && this.getQuickLMICalculatorReport();
        }
      });
  }

  preloaderShowHide() {
    this.preloaderTimeout = this.timeout(() => {
      this.isPreloaderHide = true;
    }, 2000);
  }

  getSecurities() {
    this.fundingCalculatorService
      .getQuickLMISecurities(this.brokerEventId)
      .then(({ data }) => {
        this.addSecurityEnabled = data && !!data.length;
        if (!this.addSecurityEnabled) {
          return;
        }

        this.securityAmountTotal = data.reduce((acc, curr) => {
          return acc + parseInt(curr.SecurityAmount, 10);
        }, 0);

        this.mortgageObj.security = data.map((item) => {
          return {
            FundingCalParametersID: item.FundingCalParametersID,
            OccupancyType: item.OccupancyType,
            StateOfSercurity: item.StateName,
            Value: item.SecurityAmount,
            formInEditMode: false,
          };
        });

        this.mortgageObj.NewLVRValue = this.fundingCalculatorSharedData.updateQuickLmiLvrValue(
          this.mortgageObj.NewLoanAmount,
          this.securityAmountTotal,
        );
        const lvr = this.mortgageObj.NewLVRValue.replace('%', '');
        this.mortgageObj.LVRValue = lvr;
      });
  }

  setSelectedLenderObj() {
    return (
      this.selectedLenderObj &&
      this.selectedLenderObj.length &&
      this.selectedLenderObj[0]
    );
  }

  openModal(postObj) {
    this.uibModal.open({
      templateUrl:
        'assets/views/loanCalculator/partials/lmi_calculator_modal.html',
      controller: 'QuickLMICalculatorModalCtrl as vm',
      size: 'sm',
      resolve: {
        modalObj: () => postObj,
      },
    });
  }

  setLMIFavorite(lenderId, isFavorite, lmiProductId) {
    if (!lenderId || !lmiProductId) {
      return;
    }
    this.fundingCalculatorService.setFundingCalculatorFundsQuickLMIFavorite(
      this.brokerEventId,
      lenderId,
      isFavorite,
      lmiProductId,
    );
  }

  resetLenderSelection() {
    const selectedLenderId = get(this.initialSelectedLender, 'LenderId');
    const initialLenderId = get(this.setSelectedLenderObj(), 'LenderId');

    if (initialLenderId === selectedLenderId) {
      return;
    }

    const selectedLmiProductId = get(
      this.initialSelectedLender,
      'LMIProductId',
    );
    const initialLmiProductId = get(
      this.setSelectedLenderObj(),
      'LMIProductId',
    );

    this.setLMIFavorite(selectedLenderId, false, selectedLmiProductId);
    this.setLMIFavorite(initialLenderId, true, initialLmiProductId);
  }

  saveToFundingCalculator(brokerEventId) {
    const { LenderId: lenderId, LMIProductId } = this.setSelectedLenderObj();
    this.fundingCalculatorService
      .setFundingCalculatorFundsQuickLMIFavorite(
        brokerEventId,
        lenderId,
        true,
        LMIProductId,
      )
      .then(() => {
        const filter = this.lmiPremiumObj.data.filter((item) =>
          isToggledLmiProductMatch(item, { LenderId: lenderId, LMIProductId }),
        );
        const lmiPremium = this.showLmiFromAirtable
          ? get(filter, '[0].LMIPremium')
          : get(filter, '[0].TotalIncStampDuty');

        if (lmiPremium) {
          const {
            quickLmiFundingCalculatorFunds,
            quickLmiProposedLending,
          } = this.fundingCalculatorDetailService;

          if (!isEmpty(quickLmiFundingCalculatorFunds)) {
            const {
              id,
              fundingCalParametersId,
              fundingParticulars,
              isOnlyValueEditable,
            } = quickLmiFundingCalculatorFunds;

            const postData = {
              fundsId: id,
              fundingCalParametersId,
              fundingType: 'FUNDING_REQUIRED',
              fundingParticulars,
              fundingValue: lmiPremium,
              isOnlyValueEditable,
              brokerEventId,
            };
            this.fundingCalculatorService.setFundingCalculatorFunds(postData);

            if (this.mortgageObj.GoalType === GOAL_TYPE.TARGET_LVR) {
              const lmiLoanAmount = get(filter, '[0].LoanAmountExLMI');
              this.onUpdateBaseLoanAmount({ value: lmiLoanAmount });
            }
          }

          if (!isEmpty(quickLmiProposedLending)) {
            const postData = {
              proposedLendingId: quickLmiProposedLending.proposedLendingId,
              loanAmount: quickLmiProposedLending.loanAmount,
              rate: quickLmiProposedLending.rate,
              rateType: quickLmiProposedLending.rateType,
              loanTermInYears: quickLmiProposedLending.loanTermInYears,
              isInterestOnly: quickLmiProposedLending.isInterestOnly,
              rateTerm: quickLmiProposedLending.rateTerm,
              brokerEventId,
              lmiAmount: lmiPremium,
              capitalizeLMI: this.mortgageObj.CapitalizeLMI,
            };
            this.fundingCalculatorService.setProposedLending(postData);
          }
        }

        const postData = {
          brokerEventID: brokerEventId,
          isFirstBuyer: this.mortgageObj.IsFirstHomeBuyer,
        };
        this.fundingCalculatorService.setFirstHomeBuyer(postData);

        if (this.isModal) {
          this.modalInstance.close();
        } else {
          const modalObj = { status: false, brokerEventId };
          this.openModal(modalObj);
        }
      });
  }

  getLowestLoanAmount() {
    return this.selectedLenderObj.reduce((min, cur) => {
      return cur.LoanAmountExLMI < min || min < 0 ? cur.LoanAmountExLMI : min;
    }, -1);
  }

  saveToProductFinder(brokerEventIdForProductFinder) {
    this.fundingCalculatorService
      .setFundingCalculatorQuickLMIProductFinderBrokerEventId(
        this.brokerEventId,
        brokerEventIdForProductFinder,
      )
      .then(() => {
        const securityAmount = this.mortgageObj.security.reduce((acc, curr) => {
          return acc + curr.Value;
        }, 0);

        const loanAmount =
          this.mortgageObj && this.mortgageObj.GoalType
            ? this.getLowestLoanAmount()
            : this.mortgageObj.NewLoanAmount;

        const modalObj = {
          status: true,
          brokerEventIdForProductFinder,
          selectedLender: this.selectedLenderObj,
          loanAmount,
          securityAmount,
        };
        this.openModal(modalObj);
      });
  }

  checkProductFinderBroker(brokerEventId) {
    this.fundingCalculatorService
      .getFundingCalculatorQuickLMIProductFinderBrokerEventId(brokerEventId)
      .then(({ data }) => {
        if (data && data.ProductFinderBrokerEventId) {
          this.saveToProductFinder(data.ProductFinderBrokerEventId);
        } else {
          this.loanProfilerService
            .getServiceabilityNewBrokerEvent()
            .then((response) => {
              this.saveToProductFinder(response.data);
            });
        }
      });
  }

  setAllSecuritySaved(security) {
    this.securityObj = security
      .filter((item) => !item.formInEditMode)
      .map((item) => {
        return {
          FundingCalParametersID: item.FundingCalParametersID,
          BrokerEventID: this.brokerEventId,
          StateName: item.StateOfSercurity,
          SecurityAmount: item.Value,
          OccupancyType: item.OccupancyType,
        };
      });
  }

  isMortgagePropertiesValid() {
    const {
      Lenders,
      GoalType,
      NewLoanAmount,
      PredominantLoanPurpose,
      TargetLVR,
      NewLVRValue,
    } = this.mortgageObj;

    if (Lenders && !Lenders.length) {
      toastError('Please first select Lenders');
      return false;
    } else if (!GoalType && !NewLoanAmount) {
      toastError('Please first set the total loan amount');
      return false;
    } else if (this.securityObj && !this.securityObj.length) {
      toastError('Please first add/save securities');
      return false;
    } else if (!PredominantLoanPurpose) {
      toastError('Please select predominant loan purpose');
      return false;
    } else if (GoalType && !TargetLVR) {
      toastError('Please first set the target LVR');
      return false;
    } else if (GoalType && TargetLVR > 100) {
      toastError('Target LVR should be within 100%');
      return false;
    } else if (
      !GoalType &&
      !NewLVRValue &&
      NewLVRValue.replace('%', '') === '0'
    ) {
      toastError(
        'Please set security amount higher than loan amount to have LVR result',
      );
      return false;
    }

    return true;
  }

  setLenderIsFavorite(lendersObj) {
    this.selectedLenderObj = [];
    this.selectedLenderShow = false;
    lendersObj.forEach((item) => {
      if (item.IsFavorite) {
        this.selectedLenderObj = this.selectedLenderObj.concat(item);
        this.selectedLenderShow = true;
        this.isSaveToFundingCalcDisabled = false;
      }
    });
  }

  checkPremiumLimit(data) {
    const key = this.goalTypeValue ? 'LVRExLMI' : 'LVRIncLMI';
    this.lmiPremiumObj.showWarningHeader = data.some(
      (item) => item[key] > WARNING_LIMIT,
    );
  }

  getQuickLMICalculatorReport() {
    this.fundingCalculatorService
      .getLMI(this.brokerEventId)
      .then(({ data }) => {
        if (data) {
          this.lmiPremiumObj.data = data;
          this.lmiPremiumObj.status = this.status.done;
          this.checkPremiumLimit(data);
          this.setLenderIsFavorite(data);
        } else {
          this.selectedLenderObj = [];
        }
      });
  }

  setFundingCalculatorFundsQuickLMI(postData, getReport = true) {
    this.fundingCalculatorService
      .setFundingCalculatorFundsQuickLMI(postData)
      .then(() => {
        if (!getReport) {
          return;
        }
        this.getQuickLMICalculatorReport();
      })
      .catch(() => {
        this.lmiPremiumObj.data = [];
        this.lmiPremiumObj.status = this.status.done;
      });
  }

  calculateLMI(getReport = true) {
    const LMIProductId = get(this.fundingCalcData, 'quickLmiData.LMIProductId');

    const {
      security,
      Lenders,
      LVRValue,
      IsSelfEmployed,
      NewLoanAmount,
      PredominantLoanPurpose,
      IsFirstHomeBuyer,
      TargetLVR,
      GoalType,
      IsQuickCalc,
      CapitalizeLMI,
      LoanTerm,
      IsTopUp,
      PremiumAlreadyPaid,
    } = this.mortgageObj;

    this.setAllSecuritySaved(security);
    if (!this.isMortgagePropertiesValid()) {
      return;
    }

    const postData = {
      BrokerEventID: this.brokerEventId,
      Lenders,
      LVRValue,
      IsSelfEmployed,
      NewLoanAmount,
      PredominantLoanPurpose,
      IsFirstHomeBuyer,
      TargetLVR,
      GoalType,
      IsQuickCalc,
      LMIProductId,
      ...(this.showLmiFromAirtable && {
        LoanTerm,
        IsTopUp,
        PremiumAlreadyPaid: parseFloat(PremiumAlreadyPaid),
      }),
    };

    this.goalTypeValue = this.mortgageObj.GoalType;
    if (getReport) {
      this.lmiPremiumObj.status = this.status.waiting;
    }
    if (IsQuickCalc) {
      this.fundingCalculatorService.setQuickLMISecurities(this.securityObj);
    } else {
      postData.CapitalizeLMI = CapitalizeLMI;
    }
    this.setFundingCalculatorFundsQuickLMI(postData, getReport);
  }

  isQuickCalc() {
    return this.isQuickCalc;
  }

  isCalculateDisabled() {
    const isStatusWaiting =
      this.lmiPremiumObj && this.lmiPremiumObj.status === this.status.waiting;
    const hasLenders =
      this.mortgageObj &&
      this.mortgageObj.Lenders &&
      this.mortgageObj.Lenders.length;
    return isStatusWaiting || !hasLenders;
  }

  submit(isQuickCalc) {
    if (isQuickCalc) {
      this.saveToFundingCalculator(this.brokerEventId);
    } else {
      this.checkProductFinderBroker(this.brokerEventId);
    }
  }

  close() {
    if (this.isQuickCalc) {
      this.resetLenderSelection();
    }
    this.modalInstance.dismiss('cancel');
  }

  resetSelectedLender() {
    this.childComponentEventHandler.lenderEventHandler &&
      this.childComponentEventHandler.lenderEventHandler('resetSelectedLender');
  }

  childComponentNotificationRegistration(handler, handlerType) {
    this.childComponentEventHandler[handlerType] = handler;
  }

  $onInit() {
    this.isQuickCalc = false;
    this.isPreloaderHide = false;
    this.isSaveToFundingCalcDisabled = true;
    this.lmiPremiumObj = {
      data: [],
      status: this.status.empty,
      showWarningHeader: false,
    };
    this.selectedLenderShow = false;
    this.isModalOnEdit = this.isModal && this.isEdit;
    this.calculatorOptions = {
      isModal: this.isModal,
      isEdit: this.isEdit,
    };
    this.showLmiFromAirtable = this.configService.feature.lmiFromAirtable;
    this.loadingMessage = getLoadingMessage(this.isModal);
    this.childComponentEventHandler = { lenderEventHandler: null };

    if (this.isModal && this.fundingCalcData) {
      this.savePassedLMIData(this.fundingCalcData);
      return;
    }
    this.getFundingCalculatorFundsQuickLMI();
  }

  $onDestroy() {
    this.timeout.cancel(this.preloaderTimeout);
  }
}

export default LmiCalculatorCtrl;
