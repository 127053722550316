import moment from 'moment';
import {
  INSURANCE_TABS,
  SEND_TYPE,
} from 'Common/constants/customerCareModules';
import { D_MMM_FORMAT } from 'Common/constants/dateConfigs';
import {
  sendMailBuilderForUI,
  sendInsuranceEmailBuilderForUI,
} from 'Common/mappers/customerCare';

export const checkSingleOrBulkEmailUtil = ({
  $scope,
  currentUserService,
  source,
  isInsurance,
  sendMailObj,
  currentModuleName,
  tab,
  currentModuleType,
  listTableData,
}) => () => {
  $scope.isComplete = !currentUserService.isNZ;
  $scope.mail = {
    Body: {},
  };
  $scope.isSending = false;
  $scope.all = source === SEND_TYPE.ALL;

  if (source === SEND_TYPE.SINGLE) {
    if (!isInsurance) {
      const expDate = moment(sendMailObj.expiryDate).format(D_MMM_FORMAT);
      const notiHeader = `${currentModuleName} (${expDate}) Completed via Mail`;
      $scope.recipients.email = [sendMailBuilderForUI(sendMailObj, notiHeader)];
    } else {
      const expDate = moment(sendMailObj[tab.dateName]).format(D_MMM_FORMAT);
      const notiHeader = `${tab.label} (${expDate}) Completed via Mail`;
      $scope.recipients.email = [
        sendInsuranceEmailBuilderForUI(sendMailObj, notiHeader),
      ];
    }

    $scope.recipients.CustomerCareType = currentModuleType;
  }
  $scope.fixedRateListTable = listTableData;

  if (source === SEND_TYPE.ALL) {
    $scope.selectAllEmailList = [];

    $scope.fixedRateListTable.forEach((item) => {
      const contact = !isInsurance
        ? sendMailBuilderForUI(item, '')
        : sendInsuranceEmailBuilderForUI(item, '');
      $scope.selectAllEmailList.push(contact);
    });
    if ($scope.selectAllEmailList.length > 0) {
      $scope.recipients.email = $scope.selectAllEmailList;
      $scope.noOfPeople = $scope.selectAllEmailList.length;
    }
  }

  return isInsurance;
};

export const saveMailLogUtil = ({
  $scope,
  currentModuleType,
  isInsurance,
  tab,
  customerCareInsuranceService,
  customerCareService,
  sendMailObj,
  $uibModalInstance,
  $window,
  $timeout,
}) => () => {
  $scope.recipientsEmailArray = [];
  $scope.recipients.email.forEach((item) => {
    $scope.recipientsEmailArray.push(item);
  });
  $scope.mail.ClientList = $scope.recipientsEmailArray;
  $scope.mail.CustomerCareType = currentModuleType;
  $scope.mail.IsComplete = $scope.isComplete;
  $scope.mail.Body.htmlString = $scope.content;

  const insuranceParam =
    isInsurance && tab.type === INSURANCE_TABS[1].type
      ? {
          tableRecordCsvId: sendMailObj.tableRecordIDCSV,
        }
      : {};
  const sendMail = isInsurance
    ? customerCareInsuranceService.sendMail($scope.mail, insuranceParam)
    : customerCareService.CustomerCareMailSend($scope.mail);

  sendMail.then((data) => {
    const element = $window.document.createElement('a');
    element.setAttribute(
      'href',
      `data:${data.contentType};base64,${data.documentContent}`,
    );
    element.setAttribute('download', data.name);

    element.style.display = 'none';
    $window.document.body.append(element);

    const clickDelay = $timeout(() => {
      element.click();
    }, 100);

    clickDelay.then(() => {
      element.remove();
    });

    $scope.$on('$destroy', () => {
      $timeout.cancel(clickDelay);
    });

    $uibModalInstance.close({
      success: true,
    });
  });
  return isInsurance;
};
