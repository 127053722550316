import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('FactFindModalCtrl', function FactFindModalCtrl(
      $scope,
      $uibModalInstance,
      utilitiesService,
      $stateParams,
      $rootScope,
      loanScenarioService,
      $uibModal,
      SweetAlert,
    ) {
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
      // models
      angular.extend($scope, {
        currentPage: 1,
        isDownloadingFactFind: false,
        loanAppId: $stateParams.loanAppId,
        FactFindDetails: {
          cover: {
            isActive: true,
            images: ['/assets/images/loanScenario/fact-find/LM/v1/p1.png'],
          },
          personal: {
            isActive: true,
            images: [
              '/assets/images/loanScenario/fact-find/LM/v1/p2.png',
              '/assets/images/loanScenario/fact-find/LM/v1/p3.png',
            ],
          },
          needs: {
            isActive: true,
            images: ['/assets/images/loanScenario/fact-find/LM/v1/p4.png'],
          },
          financial: {
            isActive: true,
            images: [
              '/assets/images/loanScenario/fact-find/LM/v1/p5.png',
              '/assets/images/loanScenario/fact-find/LM/v1/p6.png',
              '/assets/images/loanScenario/fact-find/LM/v1/p7.png',
            ],
          },
          privacy: {
            isActive: true,
            images: ['/assets/images/loanScenario/fact-find/LM/v1/p8.png'],
          },
          doc: {
            isActive: true,
            images: ['/assets/images/loanScenario/fact-find/LM/v1/p9.png'],
          },
        },
        FactFindDetailsNZ: {
          cover: {
            isActive: true,
            images: ['/assets/images/loanScenario/fact-find/ff-nz-page-1.png'],
          },
          needs: {
            isActive: true,
            images: ['/assets/images/loanScenario/fact-find/ff-nz-page-2.png'],
          },
          personal: {
            isActive: true,
            images: ['/assets/images/loanScenario/fact-find/ff-nz-page-3.png'],
          },
          financial: {
            isActive: true,
            images: [
              '/assets/images/loanScenario/fact-find/ff-nz-page-4.png',
              '/assets/images/loanScenario/fact-find/ff-nz-page-5.png',
              '/assets/images/loanScenario/fact-find/ff-nz-page-6.png',
            ],
          },
          assessment: {
            isActive: true,
            images: [
              '/assets/images/loanScenario/fact-find/ff-nz-page-7.png',
              '/assets/images/loanScenario/fact-find/ff-nz-page-8.png',
            ],
          },
        },
        Previews: [],
        tabs: ['factFindPdf', 'applyOnline'],
        active: 'factFindPdf',
        ClientEntityList: [],
        applyOnlineOjb: {},
      });

      // methods
      angular.extend($scope, {
        selectTab(active) {
          $scope.active = active;
        },
        crmCountry: $rootScope.crmCountry,
        RefillPreviewsArray() {
          const Previews = [];
          const toPreview =
            $rootScope.crmCountry === 'New Zealand'
              ? $scope.FactFindDetailsNZ
              : $scope.FactFindDetails;
          _.forEach(toPreview, (o) => {
            if (o.isActive) {
              _.forEach(o.images, (img) => {
                Previews.push(img);
              });
            }
          });

          $scope.Previews = Previews;

          if (_.size($scope.Previews) > 0) {
            $scope.currentPage = 0;
          } else {
            $scope.currentPage = -1;
          }
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        applicantListGet() {
          loanScenarioService
            .scenarioApplicantListGet($scope.loanAppId)
            .then((response) => {
              const InvolvedPartyPerson = response.data.InvolvedPartyPerson;
              const InvolvedPartyEntity = response.data.InvolvedPartyEntity;

              const ClientEntityList = [];

              // Clients
              _.forEach(InvolvedPartyPerson, (family) => {
                _.forEach(family, (person) => {
                  ClientEntityList.push(person);
                });
              });

              // Entities
              _.forEach(InvolvedPartyEntity, (entity) => {
                ClientEntityList.push(entity);
              });

              $scope.ClientEntityList = ClientEntityList;
              const firstClientEntity = _.first($scope.ClientEntityList) || {
                ClientEntityId: '',
              };

              if (firstClientEntity) {
                let ClientEntityId = '';

                ClientEntityId = String(
                  firstClientEntity.PersonId ||
                    firstClientEntity.ClientEntityId ||
                    '',
                );

                angular.extend($scope.applyOnlineOjb, {
                  ClientEntityId,
                  FamilyId: firstClientEntity.FamilyId,
                });

                if (_.size(firstClientEntity.Email) > 0) {
                  const email = _.find(firstClientEntity.Email, (o) => {
                    return o.Type === 'Email';
                  });

                  if (email) {
                    $scope.applyOnlineOjb.EmailAddress = email.EmailAddress;
                  }
                }

                if (_.size(firstClientEntity.Phone) > 0) {
                  const mobile = _.find(firstClientEntity.Phone, (o) => {
                    return o.Type === 'Mobile';
                  });

                  if (mobile) {
                    $scope.applyOnlineOjb.MobileNumber = mobile.Number;
                  }
                }
              }
            });
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        changeApplicant() {
          $scope.applyOnlineOjb.EmailAddress = '';
          $scope.applyOnlineOjb.MobileNumber = '';
          if (_.size($scope.ClientEntityList) > 0) {
            const clientEntity = _.find($scope.ClientEntityList, (o) => {
              return (
                o.PersonId === $scope.applyOnlineOjb.ClientEntityId ||
                o.ClientEntityId === $scope.applyOnlineOjb.ClientEntityId
              );
            });

            if (clientEntity) {
              if (_.size(clientEntity.Email) > 0) {
                const email = _.find(clientEntity.Email, (o) => {
                  return o.Type === 'Email';
                });

                if (email) {
                  $scope.applyOnlineOjb.EmailAddress = email.EmailAddress;
                }
              }

              if (_.size(clientEntity.Phone) > 0) {
                const mobile = _.find(clientEntity.Phone, (o) => {
                  return o.Type === 'Mobile';
                });

                if (mobile) {
                  $scope.applyOnlineOjb.MobileNumber = mobile.Number;
                }
              }
            }
          }
        },
        _init() {
          $scope.RefillPreviewsArray();
          $scope.applicantListGet();
        },
        addPreview() {
          if ($scope.currentPage < _.size($scope.Previews) - 1) {
            $scope.currentPage++;
          }
        },
        deductPreview() {
          if ($scope.currentPage > 0) {
            $scope.currentPage--;
          }
        },
        submitApplyOnline(form) {
          if (!form.$invalid) {
            $scope.isSharingApp = true;
            const postData = {
              loanApplicationId: $scope.loanAppId,
              clientId: $scope.applyOnlineOjb.ClientEntityId,
              familyId: $scope.applyOnlineOjb.FamilyId,
              eMail: $scope.applyOnlineOjb.EmailAddress,
              sms: $scope.applyOnlineOjb.MobileNumber,
              clientMessage: '',
            };
            loanScenarioService.share(postData).then(
              (response) => {
                $scope.cancel();
                if (response.data) {
                  $uibModal.open({
                    templateUrl:
                      '/assets/views/loanApplication/sidebar/partial/applyOnlineSent.html',
                    controller: 'ApplyOnlineSentCtrl',
                    size: 'md',
                  });
                } else {
                  SweetAlert.swal({
                    title: 'Failed to send invite!',
                    text: 'Please contact support',
                    type: 'error',
                    showCancelButton: false,
                  });
                }
              },
              () => {
                $scope.cancel();
              },
            );
          }
        },
      });
      // caller
      $scope._init();
    });
