export default class PipelineMoveCardModalCtrl {
  constructor() {
    'ngInject';

    this.cancel = this.cancel.bind(this);
  }

  $onInit() {
    this.selectedColumn =
      this.columnList.find(
        (o) => o.PipelineStatusID === this.selectedColumnId,
      ) || {};
  }

  cancel() {
    this.modalInstance.dismiss();
  }
}
