export const customSortStringReplace = (regEx, string) => {
  if (!regEx || !string) {
    return string;
  }
  let regExedString = string.replace(regEx, '');
  const lastChar = regExedString.charAt(regExedString.length - 1);
  if (lastChar === ',') {
    regExedString = regExedString.slice(0, -1);
  }
  return regExedString;
};

export const removeHistorySortForColumnName = (settings) => {
  if (!settings) {
    return settings;
  }
  const regEx = new RegExp(`${settings.columnName}:[0-9]+:[-0-9]+,?`, 'g');
  if (settings.customSort) {
    settings.customSort = customSortStringReplace(regEx, settings.customSort);
  }
  if (settings.customerSort) {
    settings.customerSort = customSortStringReplace(
      regEx,
      settings.customerSort,
    );
  }

  return settings;
};
