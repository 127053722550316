import angular from 'angular';
import { navigateArrowKeysFn } from './util/navigateArrowKeys';

export const loadModule = () =>
  angular
    .module('app')
    .directive('navigateArrowKeys', function navigateArrowKeys(
      $document,
      $timeout,
    ) {
      const link = navigateArrowKeysFn({ $document, $timeout });
      return {
        restrict: 'A',
        link,
      };
    });
