import controller from '../commissionFilterInline/commissionFilterInlineCtrl';
import template from './commissionFilterModal.html';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    lenders: '<',
    advisers: '<',
    payees: '<',
    commissionTypes: '<',
    lenderList: '<',
    adviserList: '<',
    payeeList: '<',
    filterSettings: '<',
    commissionTypeList: '<',
    onFilterCommission: '&',
    onCalendarUpdated: '&',
    onRangeSelected: '&',
    onToggleProviderFilter: '&',
    onUpdateProviderFilter: '&',
    onToggleAdviserFilter: '&',
    onUpdateAdviserFilter: '&',
    onToggleTypeFilter: '&',
    onUpdateTypeFilter: '&',
    onTogglePayeeFilter: '&',
    onUpdatePayeeFilter: '&',
  },
};
