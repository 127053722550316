class MycrmGroupOptionCtrl {
  $onInit() {
    if (!this.options || !this.options.length) {
      return;
    }
    this.options.map((option) => {
      option.value = option[this.optionId] || option.id;
      option.desc = option[this.optiionLabel] || option.label;
      return option;
    });
  }

  onOptionTrigger() {
    const currentIndex = this.options
      .map((option) => option.value)
      .indexOf(this.value);
    const totalIndex = this.options.length - 1;
    const selectedIndex = currentIndex < totalIndex ? currentIndex + 1 : 0;
    this.onChange({ value: this.options[selectedIndex].value });
  }
}

export default MycrmGroupOptionCtrl;
