import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import { mapFrequencyTypesData } from 'Common/utilities/livingExpense';
import { getFilteredExpense, getSelectedFamilyData } from './util/expense';

export const loadModule = () =>
  angular.module('app').controller(
    'LoanAppFinancialExpenseCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function LoanAppFinancialExpenseCtrl(
      $scope,
      $timeout,
      loanScenarioService,
      $stateParams,
      utilitiesService,
      $uibModal,
      SweetAlert,
      optionsService,
      financialsSharedService,
      loanAppSharedData,
      incomeSharedService,
      uiService,
    ) {
      $scope.financialsSharedService = financialsSharedService;
      $scope.loanAppSharedData = loanAppSharedData;
      $scope.loanAppId = $stateParams.loanAppId;
      $scope.familyId = $stateParams.familyId;
      $scope.expenseLoading = false;
      $scope.tooltipDisplay = false;
      $scope.ExpenseTotalAmount = 0;
      $scope.uiService = uiService;

      // owners multi select
      $scope.localLang = {
        selectAll: '<span>Select All</span>',
        selectNone: '<span>Deselect All </span>',
        reset: "<i class='fa fa-refresh'></i>",
        search: 'Search',
        nothingSelected: '&nbsp;', // default-label is deprecated and replaced with this.
        // nothingSelected: "<div class='buttonLabel'>All Clients</div>"         //default-label is deprecated and replaced with this.
      };
      $scope.ownersList = [];
      $scope.ownersListSelected = [];
      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.ExpenseClientGet = (expenseId, familyId, borrowers) => {
        if (!familyId) {
          return;
        }
        loanScenarioService
          .ExpenseClientGet(familyId, $scope.loanAppId, null)
          .then((respond) => {
            $scope.ownersListSelected = borrowers || [];
            const tmp = [];
            const temp = {
              Percentage: 0,
              BorrowerID: 0,
              FirstName: 'All',
              LastName: 'Clients',
              PreferedName: 'Joint',
              ticked: true,
              disabled: true,
            };
            if (
              $scope.ownersListSelected.length < 1 ||
              $scope.ownersListSelected.length === respond.data.length
            ) {
              respond.data &&
                Object.keys(respond.data).forEach((x) => {
                  const value = respond.data[x];
                  value.PreferedName = value.IsEntity
                    ? value.FirstName
                    : `${value.FirstName} ${value.LastName}`;
                  tmp.push(value);
                });
              if (tmp.length === 1) {
                tmp[0].ticked = true;
              } else {
                tmp.unshift(temp);
              }
              $scope.ownersList = tmp;
            } else {
              temp.ticked = false;
              // Set default selected from edit
              // @TODO : Edit should match a borrowers array
              if (_.size(borrowers) <= _.size(respond.data)) {
                respond.data &&
                  Object.keys(respond.data).forEach((x) => {
                    const value = respond.data[x];
                    value.ticked = !!_.find(borrowers, (o) => {
                      return o.BorrowerID === value.BorrowerID;
                    });
                    value.PreferedName = value.IsEntity
                      ? value.FirstName
                      : `${value.FirstName} ${value.LastName}`;
                    tmp.push(value);
                  });
              } else {
                respond.data &&
                  Object.keys(respond.data).forEach((x) => {
                    const value = respond.data[x];
                    value.ticked = value.BorrowerID === -1;
                    value.PreferedName = value.IsEntity
                      ? value.FirstName
                      : `${value.FirstName} ${value.LastName}`;
                    tmp.push(value);
                  });
              }
              if (respond.data.length > 1) {
                tmp.unshift(temp);
              }

              $scope.ownersList = tmp;
            }
          });
      };

      $scope._expenseInit = (isFull) => {
        $scope.ExpenseSet = {};
        $scope.ExpenseSet.SelectedType = {};
        $scope.expenseTypes = [];
        $scope.frequencyTypes = [];
        optionsService.FinancialFrequency().then(({ data }) => {
          $scope.frequencyTypes = mapFrequencyTypesData(data);
        });

        if (isFull) {
          $scope.expenseTypes_all = [];
          loanScenarioService.ExpenseTypeGet().then(({ data }) => {
            // Filter result - removing (Loans-Credit Cards and Mortgage Repayments)
            const filteredTypes = getFilteredExpense(data);
            $scope.expenseTypes_all = financialsSharedService.financialExpeneseFilter(
              filteredTypes,
            );
            angular.extend($scope.ExpenseSet, {
              FrequencyID: 4,
            });
          });
        }

        $scope.onTypeSelect = (expenseTypeData) => {
          $scope.expenseTypes = financialsSharedService.filterExpenseTypes(
            $scope.expenseTypes,
            expenseTypeData,
          );
          $scope.ExpenseSet.SelectedType = {};
          $scope.ExpenseSet.SelectedType = { ...expenseTypeData };
          $scope.ExpenseSet.TypeId = expenseTypeData.Value;
        };

        $scope.onTypeSelectAll = () => {
          $scope.ExpenseSet.SelectedType = {};
        };

        $scope.financialsSharedService.ExpenseInfoGet();

        $scope.displayExpenseFormCallback = () => {
          $scope.expenseLoading = true;
          $timeout(() => {
            $scope.Settings.displayExpenseForm = true;
            $scope.expenseLoading = false;
            $scope.resetExpenseModel();
            $scope.tooltipDisplay = true;
            $timeout(() => {
              $scope.tooltipDisplay = false;
            }, 6500);
          }, 2000);
        };
        $scope.hideToolTip = () => {
          $scope.tooltipDisplay = false;
        };

        const expenseValidation = () => {
          const filterExpense = _.find(
            $scope.financialsSharedService.listExpense,
            (obj) => {
              return (
                obj.FamilyId ===
                parseInt(
                  $scope.financialsSharedService.selectedFamily.FamilyId,
                  10,
                )
              );
            },
          ) || { overview: { FinancialInfo: [] } };
          $scope.hasExpense = filterExpense.overview.FinancialInfo.length > 0;
        };
        $scope.$watch(
          'financialsSharedService.listExpense',
          () => {
            expenseValidation();
          },
          true,
        );

        $scope.$watch('financialsSharedService.selectedFamily.FamilyId', () => {
          expenseValidation();
        });

        $scope.changeSelectedFamily = () => {
          const family = getSelectedFamilyData(
            $scope.listExpense,
            $scope.financialsSharedService.selectedFamily.FamilyId,
          );
          angular.extend($scope.financialsSharedService.selectedFamily, {
            initials: utilitiesService.filterInitialOneString(
              family.FamilyName,
            ),
          });
        };

        $scope.ExpenseClientGet(
          undefined,
          $scope.financialsSharedService.selectedFamily.FamilyId,
        );
      };
      // first caller
      $scope._expenseInit(true);

      // watching changes of ExpenseList
      $scope.$watchGroup(
        ['financialsSharedService.listExpense', 'expenseTypes_all'],
        (nv) => {
          if (_.size(nv[1]) > 0) {
            $scope.expenseTypes = angular.copy($scope.expenseTypes_all);
          }
        },
        true,
      );

      $scope.$watch(
        'loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS',
        (nv) => {
          if (nv && _.size(nv) > 0 && nv.RefreshExpense) {
            $scope._expenseInit(true);
            nv.RefreshExpense = false;
          }
        },
      );

      $scope.ExpenseInfoDelete = (expenseId) => {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This record will be removed from your expense list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: false,
          },
          (confirm) => {
            if (confirm) {
              loanScenarioService
                .ExpenseInfoDelete($scope.loanAppId, expenseId)
                .then(() => {
                  $timeout(() => {
                    $scope._expenseInit(false);
                    SweetAlert.swal({
                      title: 'Success',
                      text: 'Expense has been been successfully deleted.',
                      type: 'success',
                      timer: 2000,
                      showConfirmButton: false,
                    });
                  }, 100);
                });
            }
          },
        );
      };

      $scope.getNewExpenseModel = () => {
        return {
          Id: 0,
          LoanScenarioId: $scope.loanAppId,
          FamilyId: $scope.familyId,
          FinancialId: 0,
          TypeId: 0,
          Description: '',
          Amount: null,
          CurrencyCode: 'USD',
          Borrowers: [],
          FrequencyID: 4,
          Provider: '',
        };
      };

      $scope.resetExpenseModel = () => {
        $scope.ExpenseSet = $scope.getNewExpenseModel();

        _.map($scope.expenseTypes, (o) => {
          o.ticked = false;
          return o;
        });
      };

      $scope.Settings = {
        displayExpenseForm: false,
      };

      $scope.addExpense = () => {
        $scope.Settings.displayExpenseForm = false;

        // action taken : change the familyIds of the model
        angular.extend($scope.ExpenseSet, {
          FamilyId: $scope.financialsSharedService.selectedFamily.FamilyId.toString(),
          TypeId: parseInt($scope.ExpenseSet.TypeId, 10),
        });

        const joinFamilyObj = _.find($scope.ownersList, (obj) => {
          return obj.BorrowerID === 0;
        });

        if (joinFamilyObj) {
          if (joinFamilyObj.ticked) {
            $scope.ownersListSelected = _.map($scope.ownersList, (obj) => {
              if (obj.BorrowerID !== 0) {
                obj.ticked = true;
                return obj;
              }
            });
          }
        } else {
          $scope.ownersListSelected = _.filter($scope.ownersList, (obj) => {
            return obj.ticked === true;
          });
        }
        $scope.ExpenseSet.Borrowers = [];
        $scope.ownersList.forEach((data) => {
          if (data.ticked && data.BorrowerID !== 0) {
            $scope.ExpenseSet.Borrowers.push({
              Percentage: 0,
              BorrowerID: data.BorrowerID,
              IsEntity: data.IsEntity,
            });
          }
        });

        loanScenarioService.ExpenseDetailsSet($scope.ExpenseSet).then(() => {
          $scope._expenseInit(false);
          $scope.resetExpenseModel();
        });
      };

      // Add Expense Modal
      $scope.newExpenseModal = (type) => {
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/loanApplication/clientAssess/modal/expense.add.html',
          controller: 'LoanapExpenseModalCtrl',
          size: 'sm',
          scope: $scope,
          resolve: {
            modalType() {
              return type;
            },
          },
          backdrop: 'static',
          keyboard: false,
        });

        modalInstance.result.then(
          () => {
            $scope.resetExpenseModel();
          },
          () => {
            $scope.resetExpenseModel();
          },
        );
      };

      $scope.ExpenseDetailsGet = (familyId, expense) => {
        $scope.resetExpenseModel();

        angular.extend($scope.ExpenseSet, expense);

        $scope.ExpenseSet.FrequencyID = parseInt(
          $scope.ExpenseSet.FrequencyID,
          10,
        );
        $scope.ExpenseSet.TypeId = $scope.ExpenseSet.TypeId.toString();

        $scope.ExpenseSet.SelectedType = incomeSharedService.incomeServices.selectedType(
          $scope.expenseTypes,
          $scope.ExpenseSet.TypeId,
        );

        _.map($scope.expenseTypes, (object) => {
          object.ticked = object.Value === $scope.ExpenseSet.TypeId;
          return object;
        });

        // Get clients of particular expense
        $scope.ExpenseClientGet(
          $scope.ExpenseSet.Id,
          familyId,
          angular.copy($scope.ExpenseSet.Borrowers),
        );

        $scope.newExpenseModal('edit');
      };

      $scope.selectExpense = (familyId, expense) => {
        $scope.ExpenseDetailsGet(familyId, expense);
      };

      // Watch for changes in selected family
      $scope.$watch('financialsSharedService.selectedFamily.FamilyId', (nv) => {
        $scope.ExpenseClientGet(undefined, nv);
        financialsSharedService.updateExpense(nv);
      });

      $scope.onItemSelect = (data) => {
        let isJointFamily = true;
        for (let i = 0; i < $scope.ownersList.length; i++) {
          if (
            (typeof $scope.ownersList[i].ticked === 'undefined' ||
              !$scope.ownersList[i].ticked) &&
            $scope.ownersList[i].BorrowerID >= 1
          ) {
            isJointFamily = false;
          }
        }

        // will be true if user clicks on joint
        if (data.BorrowerID === 0) {
          isJointFamily = true;
        }
        if (!isJointFamily) {
          $scope.ownersList = _.map($scope.ownersList, (obj) => {
            if (obj.BorrowerID === 0) {
              obj.ticked = false;
            }
            return obj;
          });
        } else {
          const jointChecker = incomeSharedService.incomeServices.findJoint(
            $scope.ownersList,
          );
          // need to check if single owner
          if (jointChecker && Object.keys(jointChecker).length) {
            $scope.ownersList = _.map($scope.ownersList, (obj) => {
              obj.ticked = obj.BorrowerID === 0;
              return obj;
            });
          }
        }
      };

      $scope.ownersListSelectedArray = [];

      $scope.onSelectAll = () => {
        const checkerJoint = incomeSharedService.incomeServices.findJoint(
          $scope.ownersList,
        );
        // need to check if single owner
        if (checkerJoint && Object.keys(checkerJoint).length) {
          $scope.ownersList = _.map($scope.ownersList, (obj) => {
            obj.ticked = obj.BorrowerID === 0;
            return obj;
          });
        } else {
          for (let i = 0; i < $scope.ownersList.length; i++) {
            if ($scope.ownersList[i].BorrowerID > -1) {
              $scope.ownersList[i].ticked = false;
            } else {
              $scope.ownersList[i].ticked = true;
            }
          }
        }
      };
      $scope.$watch('ownersListSelected', () => {
        $('.hide-in-mobile .multiSelect').nextAll('div.buttonLabel').remove();
        if ($scope.ownersListSelected.length >= 3) {
          $('.multiSelect .buttonLabel:last-child').html(
            `${$scope.ownersListSelected.length - 2} more ...` +
              `<span class="caret"></span>`,
          );
        }
        $('.hide-in-mobile .multiSelect .buttonLabel:not(:nth-child(3))').each(
          (e) => {
            $(e.currentTarget).html(
              `${$(
                e.currentTarget,
              ).text()}<i class="fa fa-close"></i> <span class="caret"></span>`,
            );
          },
        );
      });

      // Expense Grid
      angular.extend($scope, {
        openGridModal() {
          const modalInstance = $uibModal.open({
            templateUrl:
              '/assets/views/loanApplication/clientAssess/modal/expense.grid.html',
            controller: 'LoanappOpenGridModalCtrl',
            size: 'sm',
            resolve: {
              expenseTypes: () => {
                const expenseTypes = [];
                _.forEach($scope.expenseTypes_all, (o) => {
                  expenseTypes.push({
                    GroupId: o.GroupId,
                    GroupName: o.GroupName,
                    TypeName: o.Name,
                    TypeId: o.Value,
                  });
                });
                return expenseTypes;
              },

              frequencyTypes: () => {
                return $scope.frequencyTypes;
              },

              loanAppId: () => {
                return $scope.loanAppId;
              },

              familyId: () => {
                return $scope.financialsSharedService.selectedFamily.FamilyId;
              },

              ownersList: () => {
                const ownersList = [];
                _.forEach($scope.ownersList, (o) => {
                  if (o.BorrowerID) {
                    ownersList.push({
                      BorrowerID: o.BorrowerID,
                      FirstName: o.FirstName,
                      LastName: o.LastName,
                      PreferedName: o.PreferedName,
                      initials: utilitiesService.filterInitial(
                        o.FirstName ? o.FirstName : '',
                        o.LastName ? o.LastName : '',
                      ),
                      background: financialsSharedService.getBGcolor(
                        o.BorrowerID,
                      ),
                      IsEntity: o.IsEntity,
                    });
                  }
                });
                return ownersList;
              },
            },
            backdrop: 'static',
            keyboard: false,
            windowClass: 'expense-grid',
          });

          modalInstance.result.then(
            () => {
              $scope._expenseInit(false);
              $scope.resetExpenseModel();
            },
            () => {
              $scope._expenseInit(false);
              $scope.resetExpenseModel();
            },
          );
        },
      });
    },
  );
