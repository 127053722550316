const RELEASE_NOTES_TYPES = {
  NEW: 'New',
  ENHANCEMENTS: 'Enhancements',
  FIXES: 'Fixes',
  MAINTENANCE: 'Maintenance',
  TECH_DEBT: 'TechDebt',
};
const releaseTypeCountTemplate = () => ({
  [RELEASE_NOTES_TYPES.NEW]: 0,
  [RELEASE_NOTES_TYPES.ENHANCEMENTS]: 0,
  [RELEASE_NOTES_TYPES.FIXES]: 0,
  [RELEASE_NOTES_TYPES.MAINTENANCE]: 0,
  [RELEASE_NOTES_TYPES.TECH_DEBT]: 0,
});

export function releaseNoteItemsBuilderForUI(releaseNoteItem) {
  return (
    releaseNoteItem && {
      id: releaseNoteItem.ReleaseNoteItemId,
      description: releaseNoteItem.ReleaseItemDescription,
      testType: releaseNoteItem.ReleaseTestType,
    }
  );
}

export function releaseNotesBuilderForUI(releaseNote) {
  const releaseTypeCounts = releaseTypeCountTemplate();
  return {
    id: releaseNote.ReleaseNoteId,
    name: releaseNote.ReleaseName,
    age: releaseNote.ReleaseAge,
    releaseTypeCounts,
    description: releaseNote.ReleaseDescription,
    dateReleased: releaseNote.DateReleased,
    types: releaseNote.ReleaseNoteTypes.map((releaseNoteType) => {
      releaseTypeCounts[releaseNoteType.ReleaseTypeName] =
        releaseNoteType.ReleaseNoteItems.length || 0;
      return (
        releaseNoteType && {
          typeId: releaseNoteType.ReleaseTypeId,
          typeName: releaseNoteType.ReleaseTypeName,
          items: releaseNoteType.ReleaseNoteItems.map(
            releaseNoteItemsBuilderForUI,
          ),
        }
      );
    }).sort((a, b) => a.typeId - b.typeId),
    totalReleaseCount: releaseNote.TotalReleaseCount,
  };
}
