import angular from 'angular';

// remove duplicate objects from array
export const loadModule = () =>
  angular.module('app').filter('unique', function unique() {
    return function (arr, key) {
      const distinct = [];
      const uniq = [];
      angular.forEach(arr, (a) => {
        if (!uniq.includes(a[key])) {
          uniq.push(a[key]);
          distinct.push(a);
        }
      });
      return distinct;
    };
  });
