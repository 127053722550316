import get from 'lodash/get';
import moment from 'moment';
import {
  D_MMMM_YYYY_FORMAT,
  CAPS_DD_MMMM_YYYY,
} from 'Common/constants/dateConfigs';
import { formatDate } from 'Common/utilities/date';
import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';
import { FLOATING_RATE_TYPE } from 'Common/constants/countrySpecific';
import { RATE_TYPE } from 'Common/constants/loan';
import {
  DEFAULT,
  TAB_INTEREST_ONLY,
  INTEREST_ONLY_ID,
  CUSTOMER_CARE_TYPES,
  SEND_TYPE,
  COMPLETED_EVENT_TYPE,
} from 'Common/constants/customerCareModules';
import { getCountrySpecificValue } from 'Common/utilities/countrySpecific';
import {
  getFirstElementProperty,
  getListDefaultValue,
} from 'Common/utilities/dataManipulation';
import { formatExpiryList } from 'Common/utilities/customerCare';
import {
  initValuesUtil,
  sendEmailToSelected,
  modalParamsUtil,
  sendingResultUtil,
} from 'Assets/js/controllers/customerCare/partial/util/customerCareTabsCtrl';
import {
  sortColumn,
  sortType,
  addSelectedCustomerCareIds,
  isRetainAllAdviser,
} from 'Assets/js/controllers/customerCare/util/customerCareCtrl';

class CustomerCareInterestOnlyExpiryCtrl {
  constructor(
    $uibModal,
    $state,
    $timeout,
    $interval,
    NgTableParams,
    customerCareService,
    customerCareTabsService,
    configService,
    commonFnService,
    currentUserService,
    loanService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.NgTableParams = NgTableParams;
    this.customerCareService = customerCareService;
    this.customerCareTabsService = customerCareTabsService;
    this.configService = configService;
    this.commonFnService = commonFnService;
    this.currentUserService = currentUserService;
    this.loanService = loanService;
  }

  $onInit() {
    const { brokerRegionalization, countryId } = this.currentUserService;
    this.reloadTimeout = null;
    this.isFiltersLoaded = false;
    this.exportService = 'exportInterestRate';
    this.selectedInterestExpiriesIds = [];
    this.currentPage = 1;
    this.totalCount = 0;
    this.sortColumn = '';
    this.sortType = '';
    this.tableInterval = null;
    this.isUnlockingInterestOnly = false;
    this.showUnlockedInterestOnly = {
      run: () => this.isUnlockingInterestOnly && this.isAssignToSubscribed,
    };
    this.requiredParams = {
      get: () => ({
        pageSize: this.totalCount,
        sortType: this.sortType,
        sortColumn: this.sortColumn,
        toDate: this.filter.toDate,
        fromDate: this.filter.fromDate,
        adviserId: this.filter.assignedTo,
        selectedRowIds: this.selectedInterestExpiriesIds,
      }),
    };
    this.exportService = 'exportInterestOnlyExpiry';
    this.currency = brokerRegionalization.currencySign;
    this.floatingRateTypeText = getCountrySpecificValue(
      FLOATING_RATE_TYPE,
      countryId,
    );
    this.sendingResult = sendingResultUtil(this);
    this.sendEmail = sendEmailToSelected(this);
    this.filter = {
      dateRange: DEFAULT.DATE_RANGE,
    };
    this.loadTable();
    initValuesUtil(this)({
      configService: this.configService,
      TAB_DETAILS: TAB_INTEREST_ONLY,
    });
    this.initReloadValues();
  }

  $onChanges(changes) {
    const {
      dateFilter,
      assignedFilter,
      customerCareData,
      autoMarketingSubscription,
    } = changes;
    const customerCareChanged =
      customerCareData && customerCareData.currentValue;
    const autoMarketingChanged =
      autoMarketingSubscription && autoMarketingSubscription.currentValue;
    if (dateFilter && dateFilter.currentValue) {
      const filter = dateFilter.currentValue;
      this.filter = {
        ...this.filter,
        dateRange: getListDefaultValue(filter, 'value', DEFAULT.DATE_RANGE),
      };
      this.defaultDateRangeIndex = filter
        .map((range) => range.value)
        .indexOf(+this.filter.dateRange);
    }

    if (customerCareData && customerCareData.currentValue) {
      this.customerCareData = customerCareData.currentValue;
      const {
        isAssistantUnlocked,
        showLockScreen,
        autoMarketingMessage,
      } = this.customerCareData;
      this.isAssistantUnlocked = isAssistantUnlocked;
      this.showLockScreen = showLockScreen;
      this.autoMarketingMessage = autoMarketingMessage;
    }
    if (customerCareChanged && autoMarketingChanged) {
      this.isUnlockingInterestOnly = this.unlockingCondition(
        autoMarketingSubscription.currentValue.subscribe_InterestOnly,
      );
    }
    if (assignedFilter && assignedFilter.currentValue) {
      this.assignedFilter = isRetainAllAdviser(
        assignedFilter.currentValue,
        this.isUnlockingInterestOnly,
      );
      const filter = this.assignedFilter;
      this.filter = {
        ...this.filter,
        assignedTo: getFirstElementProperty(filter, 'familyId'),
      };
      this.isAssignToSubscribed = getFirstElementProperty(
        filter,
        'isSubscribedToCustomerCareType',
      );
      this.customerCareAdviserList = assignedFilter.currentValue;
    }
  }

  $onDestroy() {
    this.$timeout.cancel(this.reloadTimeout);
    this.reloadTimeout = null;
  }

  unlockingCondition(autoMarketingInterest) {
    return (
      (this.showLockScreen &&
        this.configService.feature.unlockingCustomerCare &&
        (this.autoMarketingSubscription.subscribe_InterestOnly ||
          autoMarketingInterest)) ||
      (this.currentUserService.isAssistantType &&
        this.configService.feature.unlockingCustomerCare)
    );
  }

  initReloadValues() {
    this.customerCareTabsService.reloadCustomerCareInterestValues = ({
      showLockScreen,
      customerCareAdviserList,
    }) => {
      this.reloadTimeout = this.$timeout(() => {
        this.showLockScreen = showLockScreen;
        this.isUnlockingInterestOnly = this.unlockingCondition();
        this.assignedFilter = isRetainAllAdviser(
          customerCareAdviserList,
          this.isUnlockingInterestOnly,
        );
        this.isAssignToSubscribed = get(this.assignedFilter, '[0]', {});
        this.$timeout.cancel(this.reloadTimeout);
      });
    };
  }

  addSelectedInterestRateIds() {
    return addSelectedCustomerCareIds(this.selectedInterestExpiriesIds);
  }

  onAssignedSelect(assignedAdviser) {
    if (!assignedAdviser) {
      return;
    }
    this.filter = { ...this.filter, assignedTo: assignedAdviser.familyId };
    this.isAssignToSubscribed = assignedAdviser.isSubscribedToCustomerCareType;
    this.selectedInterestExpiriesIds = [];
    this.interestExpiryTableParams.reload();
  }

  onDateSelect(dateRange) {
    this.filter = { ...this.filter, dateRange };
    this.selectedInterestExpiriesIds = [];
    this.loadTable();
  }

  onShowCompletedChange(showCompleted) {
    this.filter = { ...this.filter, showCompleted };
    this.interestExpiryTableParams.reload();
  }

  onSendToSelected() {
    this.sendEmail(true, 'interestExpiryTable', 'reloadTable');
  }

  onSendToAll(sendType) {
    switch (sendType) {
      case SEND_METHOD_TYPES.EMAIL:
        this.sendEmail(false, 'interestExpiryTable', 'reloadTable');
        break;
      case SEND_METHOD_TYPES.MAIL:
        this.openSendMailModal('', SEND_TYPE.ALL);
        break;
      case SEND_METHOD_TYPES.SMS:
        this.openSMSModal('', SEND_TYPE.ALL);
        break;
      default:
        break;
    }
  }

  openSendEmailModal(item) {
    const tableCtrl = this;
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/customerCare/partials/modal/send_email_modal.html',
      controller: 'FixedRateSendEmailModalCtrl',
      size: 'lg',
      resolve: {
        fixedRateListTable: () => {
          return tableCtrl.interestExpiryTable;
        },
        fixedRateObj: () => item,
        source: () => SEND_TYPE.SINGLE,
        isInsurance: () => false,
        tab: modalParamsUtil.tab({ type: 'INTEREST_ONLY_EXPIRY' }),
      },
    });
    this.sendingResult(modalInstance, 'reloadTable');
  }

  openSendMailModal(item, sendType) {
    const tableCtrl = this;
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/customerCare/partials/modal/send_mail_modal.html',
      controller: 'OpenSendMailModalCtrl',
      size: 'lg',
      resolve: {
        listTableData: () => {
          return tableCtrl.interestExpiryTable;
        },
        sendMailObj: () => item,
        source: () => sendType,
        currentModuleId: modalParamsUtil.currentModuleType(INTEREST_ONLY_ID),
        currentModuleType: modalParamsUtil.currentModuleType(
          CUSTOMER_CARE_TYPES.INTEREST_ONLY_EXPIRY,
        ),
        currentModuleName: modalParamsUtil.currentModuleName(
          TAB_INTEREST_ONLY.label,
        ),
        isInsurance: modalParamsUtil.isInsurance(false),
        tab: modalParamsUtil.tab(null),
      },
    });
    this.sendingResult(modalInstance, 'reloadTable');
  }

  openSMSModal(item, sendType) {
    const tableCtrl = this;
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/customerCare/partials/modal/send_sms_modal.html',
      controller: 'FixedRateSMSModalCtrl',
      size: 'lg',
      resolve: {
        fixedRateListTable: () => {
          return tableCtrl.interestExpiryTable;
        },
        fixedRateObj: () => item,
        source: () => sendType,
        isInsurance: () => false,
        tab: () => null,
      },
    });
    this.sendingResult(modalInstance, 'reloadTable');
  }

  setCompletion(item) {
    const validItem = item && Object.keys(item).length;
    if (!validItem) {
      return;
    }

    const expiry = moment(item.expiryDate).format('D MMM');
    const notificationHeader = `Interest Only Expiries (${expiry}) Completed`;
    const postData = [
      {
        CustomerCareType: CUSTOMER_CARE_TYPES.INTEREST_ONLY_EXPIRY,
        FamilyID: 0,
        LoanID: item.loanID,
        IsComplete: item.isComplete,
        NotificationHeader: notificationHeader,
        LoanStructureId: item.loanStructureID,
      },
    ];
    if (item.isComplete) {
      const forInterestOnlyExpiry = {
        loanModalTab: 'loan-splits',
        interestOnlyCompilation: postData,
        hideSidebar: true,
        isFromCustomerCare: true,
        completedEventTypeId: COMPLETED_EVENT_TYPE.INTEREST_ONLY_EXPIRY,
        loanStructureIdFromCC: item.loanStructureID,
      };
      const params = {
        size: 'lg',
        windowClass: 'customer-care-trigger',
        familyId: item.familyID,
        loanId: item.loanID,
        forInterestOnlyExpiry,
      };

      this.loanService.openLoanInformationModal(params).then(() => {
        this.interestExpiryTableParams.reload();
      });
      return;
    }
    this.customerCareService.setCompeletion(postData).then(() => {
      this.interestExpiryTableParams.reload();
    });
  }

  onActionSelected($event) {
    if (!$event) {
      return;
    }
    switch ($event.sendType) {
      case SEND_METHOD_TYPES.EMAIL:
        this.openSendEmailModal($event.item);
        break;
      case SEND_METHOD_TYPES.MAIL:
        this.openSendMailModal($event.item, SEND_TYPE.SINGLE);
        break;
      case SEND_METHOD_TYPES.SMS:
        this.openSMSModal($event.item, SEND_TYPE.SINGLE);
        break;
      default:
        break;
    }
  }

  gotoContact(familyId) {
    this.commonFnService.hiddenClientForNz().then((isHidden) => {
      if (familyId && !isHidden) {
        this.$state.go('app.contactsSingle', { familyId });
      }
    });
  }

  loadTableData() {
    const tableCtrl = this;
    this.interestExpiryTableParams = new this.NgTableParams(
      { page: 1, count: 10, sorting: { ExpiryDate: 'asc' } },
      {
        counts: [],
        total: 0,
        getData(params) {
          const adviserFamilyId = get(tableCtrl, 'filter.assignedTo');
          if (!adviserFamilyId && adviserFamilyId !== 0) {
            return;
          }
          tableCtrl.isTableLoading = true;
          tableCtrl.sortColumn = sortColumn(params.orderBy);
          tableCtrl.sortType = sortType(params.sorting);
          tableCtrl.currentPage = params.page();

          return tableCtrl.customerCareService
            .interestOnlyExpiryListGet(
              adviserFamilyId,
              tableCtrl.filter.fromDate,
              tableCtrl.filter.toDate,
              params.page(),
              params.count(),
              tableCtrl.sortColumn,
              tableCtrl.sortType,
              tableCtrl.filter.showCompleted,
              true,
            )
            .then((response) => {
              const interestExpiryList =
                response && response.interestOnlyExpiryList;
              tableCtrl.isTableLoading = false;
              if (!interestExpiryList) {
                return;
              }

              tableCtrl.interestExpiryTable = formatExpiryList(
                interestExpiryList,
                tableCtrl.selectedInterestExpiriesIds,
              );
              params.total(response.totalCount || 0);
              tableCtrl.checkedTableData = [];
              tableCtrl.totalCount = response.totalCount;
              return tableCtrl.interestExpiryTable;
            });
        },
      },
    );

    this.reloadTable = () => {
      this.interestExpiryTableParams.reload();
    };
  }

  setDateRangeFilter() {
    if (!this.filter.dateRange) {
      return;
    }
    const days = Number(this.filter.dateRange || DEFAULT.DATE_RANGE);
    const fromDate = moment().format(D_MMMM_YYYY_FORMAT);
    const toDate = moment().add(days, 'days').format(D_MMMM_YYYY_FORMAT);
    this.filter = { ...this.filter, fromDate, toDate };
  }

  onDateRangeChange({ dateFrom, dateTo, rangeValue }) {
    const dateFormatter = formatDate(CAPS_DD_MMMM_YYYY);
    this.filter = {
      ...this.filter,
      fromDate: dateFormatter(dateFrom),
      toDate: dateFormatter(dateTo),
      dateRange: rangeValue,
    };
    this.loadTableData();
  }

  stopTableInterval() {
    if (this.tableInterval) {
      this.$interval.cancel(this.tableInterval);
      this.tableInterval = undefined;
    }
  }

  loadTable() {
    this.setDateRangeFilter();
    this.tableInterval = this.$interval(() => {
      if (typeof this.filter.assignedTo === 'undefined') {
        return;
      }
      this.loadTableData();
      this.stopTableInterval();
    }, 1000);
  }

  getRateType(rateType) {
    const isFloating = rateType === RATE_TYPE.FLOATING;
    return isFloating ? this.floatingRateTypeText : rateType;
  }
}

export default CustomerCareInterestOnlyExpiryCtrl;
