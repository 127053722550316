import CrmTableViewCtrl from '../crmTableViewCtrl';

export default class CrmTableViewOpportunity extends CrmTableViewCtrl {
  constructor(pipelineService, $interval, uiService) {
    'ngInject';

    super($interval);
    this.pipelineService = pipelineService;
    this.lastSortColumn = this.filterSettings && this.filterSettings.sortColumn;
    this.uiService = uiService;
    this.currentPage = 1;
  }

  $onInit() {
    this.componentCells = [
      'pipelineStatus',
      'labelsList',
      'manageLabelOpen',
      'probability',
      'subStatusName',
      'subStatusList',
    ];
  }

  setOpportunityDataSubStatusList(pipelineItem) {
    const filteredSubStatusList = this.subStatusList.find(
      (subStatus) => subStatus.PipelineStatus === pipelineItem.pipelineStatus,
    );
    pipelineItem.subStatusList =
      (filteredSubStatusList && filteredSubStatusList.SubStatus) || [];
  }

  onSuccessResetSubStatus({ $e }) {
    if (!this.uiService.viewOfAfileTurnedOn) {
      return;
    }
    const { pipelineItem: rowData } = $e;
    rowData.subStatusName = '';
    rowData.subStatusId = '';
    this.setOpportunityDataSubStatusList(rowData, this.subStatusList);
    this.onChangeSubStatus({
      $e: {
        subStatus: {
          pipelineSubStatusID: 0,
          SubStatusName: '',
        },
        card: rowData,
        showNotification: false,
      },
    });
  }

  rowDeletion(rowData, $event) {
    const isOfflineDeleting = this.totalRecords <= this.pageSize;
    const { loanId, id: rowId } = rowData;
    const params = {
      $e: {
        loanId,
        event: $event,
        successCallback: isOfflineDeleting
          ? this.removeRow
          : this.onRefreshTable,
        successCallbackParams: isOfflineDeleting ? { rowId } : {},
      },
    };

    this.onDeletePipelineItem(params);
  }
}
