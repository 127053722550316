import template from './upcomingSettlementDates.html';
import controller from './upcomingSettlementDatesCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    myParam: '<',
    type: '<',
  },
};
