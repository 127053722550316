export default class TasksHeaderCtrl {
  constructor($uibModal) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  openModalForDemoVideo(size) {
    this.$uibModal.open({
      templateUrl: '/assets/views/partials/demo_video_modal.html',
      controller: 'TaskVideoDemoCtrl',
      size,
      windowClass: 'demo-video-modal-window-class',
    });
  }
}
