import angular from 'angular';

function OpportunityLoanAppService($state, uiService) {
  this.isLoanAppOnOpportunities =
    uiService.viewOfAfileTurnedOn && $state.params.opportunityId;

  this.callbackList = {};

  const setCallback = (key, callback) => {
    if (!key || !callback) {
      return;
    }
    this.callbackList[key] = callback;
    return this.callbackList;
  };

  const executeCallback = (key) => {
    if (!key) {
      return;
    }
    const callback = this.callbackList[key];
    this.isLoanAppOnOpportunities &&
      callback &&
      typeof callback === 'function' &&
      callback();
  };

  return {
    setCallback,
    executeCallback,
  };
}

export default angular
  .module('app.services.opportunityLoanAppService', [])
  .factory('opportunityLoanAppService', OpportunityLoanAppService).name;
