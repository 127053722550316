import { get, cloneDeep } from 'lodash';
import {
  POSSIBLE_ADVERSE_CHANGES,
  MAIN_QUESTIONS,
  DEFAULT_ANTICIPATE_FIELDS,
  LIST_OF_QUESTIONS_AU,
  LIST_OF_QUESTIONS_NZ,
} from 'Common/constants/possibleAdverseChanges';
import { INPUT_FIELDS_DEBOUNCE_TIMEOUT } from 'Common/constants/formSettings';
import { splitString } from 'Common/utilities/string';
import { isEmpty, isValidObjectValue } from 'Common/utilities/objectValidation';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { getDatePickerConfig } from 'Common/utilities/date';
import { parseToInt10 } from 'Common/utilities/parse';
import { arrayToObject } from 'Common/utilities/array';
import { showActionConfirmation } from 'Common/utilities/alert';
import { LOAN_APP_POSSIBLE_ADVERSE_CHANGES } from 'Common/constants/performance';
import { performance } from 'Common/utilities/perfume';
import { loanAppSectionData } from 'Common/utilities/dataLayer';
import {
  filterClientList,
  getOptionListByQuestion,
  stringifyMultipleAnswers,
  getFormattedAnswer,
  isBinaryYes,
  hasOtherSelected,
  isCompleteField,
  getDataPerClient,
} from './util/possibleAdverseChangesCtrl';

export default class PossibleAdverseChangesCtrl {
  constructor(
    $q,
    optionsService,
    loanApplicationServices,
    loanAppSharedData,
    currentUserService,
  ) {
    'ngInject';

    this.$q = $q;
    this.optionsService = optionsService;
    this.loanApplicationServices = loanApplicationServices;
    this.loanAppSharedData = loanAppSharedData;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.inputFieldsDebounceTimeout = INPUT_FIELDS_DEBOUNCE_TIMEOUT;
    this.mainQuestions = this.currentUserService.isAU
      ? MAIN_QUESTIONS.AU
      : MAIN_QUESTIONS.NZ;
    this.isBinaryYes = isBinaryYes;
    this.hasOtherSelected = hasOtherSelected;
    this.LIST_OF_QUESTIONS = this.currentUserService.isAU
      ? LIST_OF_QUESTIONS_AU
      : LIST_OF_QUESTIONS_NZ;
    this.sectionBeenClicked = false;
    this.clientSelected = null;
    this.rawProposedRepaymentList = [];
    this.rawMitigantAnswers = [];
    this.proposedRepaymentList = [];
    this.proposedRepaymentAnswers = [];
    this.possibleAdverseParams = {
      loanApplicationId: parseInt(this.loanAppId, 10),
    };

    this.clientList = [];
    this.adverseQuestions = [];
    this.answersAdverse = [];
    this.answersPossibleAnticipated = [];
    this.answersPossiblePlanOrAnticipate = [];
    this.defaultAnticipated = { ...DEFAULT_ANTICIPATE_FIELDS };
    this.datePickerConfig = getDatePickerConfig({
      dateOptions: {
        formatYear: 'yy',
        showWeeks: false,
      },
    });
  }

  $onChanges(changes) {
    const { loanApplicants } = changes;
    const noChanges =
      !this.sectionBeenClicked ||
      !loanApplicants ||
      !loanApplicants.currentValue;
    if (noChanges) {
      return;
    }

    const { InvolvedPartyPerson: involvedPerson } = loanApplicants.currentValue;
    this.updateClientList(involvedPerson);
  }

  updateClientList(involvedPerson) {
    this.clientList = filterClientList(involvedPerson);
    if (this.clientSelected) {
      this.getPossibleAdverseAnswers();
    } else {
      this.clientSelected = this.clientSelected || this.clientList[0];
      this.updateFamilyIdParam();
    }
    this.clientSelected && this.updateApplicantDisplayAgeText();
  }

  updateApplicantDisplayAgeText() {
    this.clientSelected.ageDisplayText = `${
      this.clientSelected.FirstName
    }'s current age: ${
      this.clientSelected.Age ? this.clientSelected.Age : 'Not available'
    }`;
  }

  updateFamilyIdParam() {
    this.possibleAdverseParams = {
      ...this.possibleAdverseParams,
      familyId: parseToInt10(this.clientSelected.FamilyId),
    };
  }

  processAdverseSection() {
    performance.start(LOAN_APP_POSSIBLE_ADVERSE_CHANGES);
    if (this.sectionBeenClicked) {
      return;
    }
    this.sectionBeenClicked = true;
    this.getAdverseChangesQuestions();
    this.updateClientList(this.loanApplicants.InvolvedPartyPerson);
    const duration = performance.endPaint(LOAN_APP_POSSIBLE_ADVERSE_CHANGES);
    loanAppSectionData({
      label: LOAN_APP_POSSIBLE_ADVERSE_CHANGES,
      value: duration,
    });
  }

  getAdverseChangesQuestions() {
    this.optionsService
      .question(POSSIBLE_ADVERSE_CHANGES.TITLE)
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }
        this.adverseQuestions = data;
        this.questionsDictionary = data.reduce((result, curr) => {
          result[curr.QuestionId] = curr;
          return result;
        }, {});
        this.getDropdownOptions();
      });
  }

  getDropdownOptions() {
    const promises = [
      this.optionsService.getObjectivesOption(),
      this.optionsService.getAdverseImpactUnits(),
      this.optionsService.getAdverseImpactOperators(),
    ];

    this.$q.all(promises).then((responses) => {
      this.setDropdownOptions(responses);
    });
  }

  prepareOptionList(id) {
    return getOptionListByQuestion(id, this.adverseOptions);
  }

  setDropdownOptions(dropdowns) {
    if (!dropdowns || !dropdowns.length) {
      return;
    }
    const [
      adverseOptions,
      anticipatedDurationList,
      anticipatedOperatorsList,
    ] = dropdowns;

    this.adverseOptions = adverseOptions;
    this.anticipatedDurationList =
      anticipatedDurationList && anticipatedDurationList.data;
    this.anticipatedOperatorsList =
      anticipatedOperatorsList && anticipatedOperatorsList.data;

    this.rawProposedRepaymentList = this.prepareOptionList(
      this.LIST_OF_QUESTIONS.APPLICANT_RETIRING_PROPOSED_PAYMENT,
    );
    this.anticipatedDescList = this.prepareOptionList(
      this.LIST_OF_QUESTIONS.ANTICIPATE_CHANGES_NATURE,
    );
    this.intendedRepaymentList = this.prepareOptionList(
      this.LIST_OF_QUESTIONS.LOAN_REPAYMENT,
    );

    this.getPossibleAdverseAnswers();
  }

  getPossibleAdverseAnswers(questionId = 0) {
    this.isLoadingAnswer = true;
    const promises = [
      this.loanApplicationServices.getPossibleAdverseChanges({
        ...this.possibleAdverseParams,
        questionId,
      }),
      this.loanApplicationServices.getNatureOfChange(
        this.possibleAdverseParams,
      ),
    ];

    this.$q.all(promises).then((responses) => {
      this.setPossibleAdverseAnswers(responses);
      this.isCompletedSection = this.isDoneSection();
      this.setCompletionStatus();
      this.isLoadingAnswer = false;
    });
  }

  isDoneSection() {
    const {
      RETIREMENT_AGE,
      APPLICANT_RETIRING,
      APPLICANT_RETIRING_PROPOSED_PAYMENT,
      REACH_RETIREMENT,
      ANTICIPATE_CHANGES,
    } = this.LIST_OF_QUESTIONS;

    if (!this.answersAdverse) {
      return false;
    }
    this.clientList.forEach((client, index) => {
      const clientAdverse = getDataPerClient(
        this.answersAdverse,
        client.PersonId,
      );

      const clientAnswerObj = arrayToObject(clientAdverse, 'questionId');
      const ageAns = get(clientAnswerObj, `${RETIREMENT_AGE}.answer`);
      const retiringAns = get(clientAnswerObj, `${APPLICANT_RETIRING}.answer`);
      const proposedAns = get(
        clientAnswerObj,
        `${APPLICANT_RETIRING_PROPOSED_PAYMENT}.answer`,
      );
      const reachAns = get(clientAnswerObj, `${REACH_RETIREMENT}.answer`);
      const anticipateAns = get(
        clientAnswerObj,
        `${ANTICIPATE_CHANGES}.answer`,
      );
      const answersPossiblePlanOrAnticipate = this.rawMitigantAnswers.filter(
        (item) => item.clientId === parseToInt10(client.PersonId),
      );

      const answerStatus = [
        !!ageAns,
        (retiringAns && !isBinaryYes(retiringAns)) ||
          (retiringAns && !!proposedAns),
        this.currentUserService.isNZ ||
          isBinaryYes(retiringAns) ||
          (reachAns && !isBinaryYes(reachAns)) ||
          (isBinaryYes(reachAns) && !!proposedAns),
        (anticipateAns && !isBinaryYes(anticipateAns)) ||
          (anticipateAns && answersPossiblePlanOrAnticipate.length),
      ];

      const isComplete = !answerStatus.some((answer) => !answer);
      this.clientList[index] = { ...client, isComplete };
    });

    return !this.clientList.some((client) => !client.isComplete);
  }

  setCompletionStatus() {
    const updatedCompletionStatus = this.isCompletedSection;
    const sectionObject = this.loanAppSharedData.getSectionObjectById(
      this.loanAppSharedData.completionListObject,
      SECTION_ID.POSSIBLE_ADVERSE,
    );

    if (this.isCompleted === updatedCompletionStatus) {
      return;
    }
    this.isCompleted = updatedCompletionStatus;
    this.onChangeStatus({ updatedCompletionStatus });
    this.loanAppSharedData.completionStatusSet(
      SECTION_ID.POSSIBLE_ADVERSE,
      sectionObject.IsCompleted,
      updatedCompletionStatus,
    );
  }

  setPossibleAdverseAnswers(answerLists) {
    const isValid =
      isValidObjectValue(() => answerLists.length) && !isEmpty(this.clientList);
    if (!isValid) {
      return;
    }
    const [answersAdverse, answersPossiblePlanOrAnticipate] = answerLists;

    this.answersAdverse = answersAdverse;
    this.answersPossiblePlanOrAnticipate = answersPossiblePlanOrAnticipate;
    this.rawMitigantAnswers = answersPossiblePlanOrAnticipate;

    this.clientSelected = this.clientSelected || this.clientList[0];
    this.changeSelectedClient(this.clientSelected);
  }

  structureDisplayAnswers() {
    if (!this.questionsDictionary) {
      return;
    }
    const clientAdverse = getDataPerClient(
      this.answersAdverse,
      this.clientSelected.PersonId,
    );
    this.answersPossiblePlanOrAnticipate = this.rawMitigantAnswers.filter(
      (item) => item.clientId === parseToInt10(this.clientSelected.PersonId),
    );

    Object.keys(this.questionsDictionary).forEach((key) => {
      const questionId = parseToInt10(key);
      this.questionsDictionary[key] = {
        ...this.questionsDictionary[key],
        answer: getFormattedAnswer(questionId, clientAdverse),
      };
      this.questionsDictionary[key] = {
        ...this.questionsDictionary[key],
        isCompleteField: isCompleteField(this.questionsDictionary[key], this),
      };
      this.updateStarted(!!this.questionsDictionary[key].answer);
    });

    const {
      APPLICANT_RETIRING_PROPOSED_PAYMENT,
      APPLICANT_RETIRING,
    } = this.LIST_OF_QUESTIONS;
    const splitAnswers = splitString(
      this.questionsDictionary[APPLICANT_RETIRING_PROPOSED_PAYMENT].answer,
    );
    this.proposedRepaymentAnswers = this.rawProposedRepaymentList.filter(
      (item) => splitAnswers.includes(item.value),
    );

    this.proposedRepaymentList = this.rawProposedRepaymentList.filter(
      (item) => !this.proposedRepaymentAnswers.includes(item),
    );

    this.questionsDictionary[APPLICANT_RETIRING] = {
      ...this.questionsDictionary[APPLICANT_RETIRING],
      isCompleteField: isCompleteField(
        this.questionsDictionary[APPLICANT_RETIRING],
        this,
      ),
    };
  }

  changeSelectedClient(person) {
    this.clientSelected = this.clientList.find(
      (client) => client.PersonId === person.PersonId,
    );
    this.updateFamilyIdParam();
    this.structureDisplayAnswers();
    this.clientSelected && this.updateApplicantDisplayAgeText();
  }

  addSelectedRepayment(data, question) {
    if (!data || !question) {
      return;
    }
    this.proposedRepaymentAnswers = [...this.proposedRepaymentAnswers, data];
    this.proposedRepaymentList = this.proposedRepaymentList.filter(
      (item) => item.value !== data.value,
    );

    this.prepareAnswers(question);
  }

  removeSelectedRepayment(data, question) {
    if (!data || !question) {
      return;
    }
    this.proposedRepaymentAnswers = this.proposedRepaymentAnswers.filter(
      (item) => item.value !== data.value,
    );
    this.proposedRepaymentList = this.rawProposedRepaymentList.filter(
      (item) => !this.proposedRepaymentAnswers.includes(item),
    );
    this.prepareAnswers(question);
  }

  prepareAnswers(question) {
    const answer = stringifyMultipleAnswers(this.proposedRepaymentAnswers);
    this.setAnswer(question, answer);
  }

  setAnswer(question, data) {
    const params = {
      ...this.possibleAdverseParams,
      clientId: this.clientSelected.PersonId,
      questionId: question.QuestionId,
      answer: data,
    };

    this.loanApplicationServices.setPossibleAdverseChanges(params).then(() => {
      this.getPossibleAdverseAnswers();
    });
  }

  showDeleteConfirmation(data, functionName) {
    showActionConfirmation(
      'Are you sure?',
      'This record will be removed from your list',
      (confirm) => {
        confirm && this[functionName](data);
      },
    );
  }

  deletePossibleAnticipated(data) {
    const params = {
      loanApplicationId: parseToInt10(this.loanAppId),
      natureOfChangeId: data.natureOfChangeId,
    };
    this.loanApplicationServices.deletePossibleAnticipated(params).then(() => {
      this.defaultAnticipated = { ...DEFAULT_ANTICIPATE_FIELDS };
      this.getPossibleAdverseAnswers();
    });
  }

  deletePossiblePlan(data) {
    this.setPossiblePlanOrAnticipate(data, true);
  }

  setPossibleAnticipatedChanges(data) {
    const answers = [
      data.natureOfChange,
      data.periodOfImpactDuration,
      data.periodOfImpactUnit,
      data.startDate,
      data.financialImpactAmount,
      data.financialImpactOperator,
      data.objectiveListValue,
      data.details,
      ...(data.natureOfChange === 'Other' ? [data.other] : []),
    ];

    data.invalidForm = answers.some((item) => !item);
    if (data.invalidForm) {
      return;
    }

    const params = {
      ...this.possibleAdverseParams,
      ...data,
      loanScenarioId: this.loanAppId,
      clientId: this.clientSelected.PersonId,
    };
    data.isSaving = true;
    this.loanApplicationServices.setNatureOfChange(params).then(() => {
      this.isShowNatureOfChanges = false;
      data.isSaving = false;
      this.defaultAnticipated = { ...DEFAULT_ANTICIPATE_FIELDS };
      this.getPossibleAdverseAnswers();
    });
  }

  setPossiblePlanOrAnticipate(data, isDeleted = false) {
    this.isShowAnticipatedChanges = false;
    const params = {
      ...this.possibleAdverseParams,
      ...data,
      clientId: this.clientSelected.PersonId,
      isDeleted,
    };

    this.loanApplicationServices
      .setPossiblePlanOrAnticipate(params)
      .then(() => {
        this.getPossibleAdverseAnswers();
      });
  }

  toggleEditDropdown(variable, data) {
    this[variable] = cloneDeep(data);
  }

  updateStarted(isStarted) {
    this.isStarted = isStarted || this.isStarted;
  }
}
