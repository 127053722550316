import { getLogger } from '@loanmarket/logger-core';
import { forEach } from 'lodash';
import { EVENT_HANDLER } from 'Common/constants/fileUploadEventHandler';
import { filenameValidation } from 'Common/utilities/fileUpload';
import { catchError } from 'Common/utilities/promise';

class FileUploadCtrl {
  constructor(
    uiService,
    FileUploader,
    configService,
    documentService,
    crmConfirmation,
    $timeout,
    toaster,
    $window,
  ) {
    'ngInject';

    this.uiService = uiService;
    this.FileUploader = FileUploader;
    this.configService = configService;
    this.documentService = documentService;
    this.$timeout = $timeout;
    this.toaster = toaster;
    this.$window = $window;
    this.processParentNotification = this.processParentNotification.bind(this);
    this.crmConfirmation = crmConfirmation;
  }

  // toggles queue list show/hide
  toggleQueue() {
    this.showQueue = !this.showQueue;
  }

  // {@files} - reference files from uploader.getNotUploadedItems()
  removeUnreadyFiles(files) {
    forEach(files, (fileItem) => {
      // will only remove cancelled and failed files
      if (fileItem.isCancel || fileItem.isError) {
        this.uploader.removeFromQueue(fileItem);
      }
    });
  }

  cancelAll() {
    this.uploader.cancelAll();
  }

  setActiveFileSelector(selector) {
    this.activeFileSelector = selector;
  }

  triggerFileSelectDialog() {
    if (!this.activeFileSelector) {
      return;
    }
    this.activeFileSelector.trigger('click');
  }

  onFileUploadError() {
    this.crmConfirmation.open({
      type: 'simple-warning',
      title: 'There was a problem uploading one or more of your files.',
      description: this.uploadFailureMessage,
      buttonText: 'Try again',
      onConfirm: this.triggerFileSelectDialog.bind(this),
      showCloseButton: false,
      showCancelButton: true,
      isButtonMaxWidth: true,
      modalSize: 'sm',
    });
  }

  onAfterAddingFile(item) {
    if (item.file.size > 0 || !this.checkForBrokenFiles) {
      this.isPresignedUrl && this.setDocumentPresignedUrl(item);
    } else {
      this.onCancelItem(item);
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  $onInit() {
    this.loggerSection = 'File Upload';
    this.logger = getLogger(this.loggerSection);
    // toggles show/hide of queue container
    this.showQueue = true;
    this.uniqueId = this.uniqueId || this.uniqueId === 0 ? this.uniqueId : '-1';
    const dragDropItem = this.itemForUpload || 'attachments';
    const uploadApi = this.customUploadApi || '/contacts/DocumentUpload';
    this.dragDropText = `Drag and Drop ${dragDropItem} here or`;
    this.dragDropTextMobile = `Select ${dragDropItem}`;
    // list of default values for FileUploader
    this.default = {
      // list of default extension if no `extensions` prop is provided
      extensions: ['pdf', 'jpg', 'jpeg', 'png'],

      // autoUpload after file has been added, default to true if no `autoUpload` prop is provided
      autoUpload: true,

      // post url for our file upload, default to contacts/DocumentUpload if no `url` prop is provided
      url: `${this.configService.resource}${uploadApi}`,
    };

    this.inputStyle = {
      height: this.height || '100%',
      width: this.width || '100%',
    };

    const autoUpload = this.isPresignedUrl
      ? false
      : this.autoUpload || this.default.autoUpload;

    // instantiates new fileUploader object to this.uploader
    this.uploader = new this.FileUploader({
      // expects autoUpload as a prop or get from the default
      autoUpload,

      // expect url as a prop or get from the default
      url: this.url || this.default.url,

      // let's just put it like this
      headers: this.isPresignedUrl
        ? null
        : { Authorization: this.configService.token },
      method: this.isPresignedUrl ? 'PUT' : 'POST',
      disableMultipart: !!this.isPresignedUrl,
    });

    // list of successfully uploaded items
    this.uploader.uploadedItems = [];

    this.uploader.onBeforeUploadItem = (item) => {
      const params = [];
      if (!this.noUrlParams && !this.isPresignedUrl) {
        const { familyId, uploadType, type, documentId } = this;
        const args = { familyId, uploadType, type, documentId };
        Object.keys(args).forEach((key) => {
          params.push(`${key}=${args[key] || 0}`);
        });
        item.url += `?${params.join('&')}`;
        this.logger.info(`Uploading ${item.file.name} to ${item.url}`);
      }

      this.checkUploading();
    };

    // filter file extensions
    this.uploader.filters.push({
      name: 'extensions',
      fn: (item) => {
        const jwt = this.$window.localStorage.getItem('myCRM_jwt');
        const didTokenChange =
          this.uploader.headers &&
          jwt &&
          this.uploader.headers.Authorization !== jwt;
        if (didTokenChange) {
          this.uploader.headers.Authorization = jwt;
        }

        if (this.isPresignedUrl) {
          this.uploader.headers = { 'Content-Type': item.type };
        }

        const ext = this.extensions
          ? this.extensions.join(`|`)
          : this.default.extensions.join(`|`);

        if (!filenameValidation(item.name, ext)) {
          if (this.invalidFileInfo) {
            this.toaster.pop(
              `error`,
              this.invalidFileInfo.title,
              this.invalidFileInfo.description,
            );
          } else {
            this.toaster.pop(
              `error`,
              `Unable to upload ${item.name}`,
              `Please try uploading using ${ext.toUpperCase()}`,
            );
          }
        } else {
          return true;
        }
      },
    });

    // needs to remove the cancelled or failed files from the queue before reuploading newly added files
    // this is to make sure that we are uploading healthy files only
    this.uploader.onAfterAddingAll = (items) => {
      const hasBrokenFile = items.some((item) => !item.file.size);
      if (this.checkForBrokenFiles && hasBrokenFile) {
        items.forEach(this.onCancelItem.bind(this));
        this.onFileUploadError();
        return;
      }
      items.forEach((item) => {
        this.onAfterAddingFile(item);
      });
      this.removeUnreadyFiles(this.uploader.getNotUploadedItems());
    };

    // completely uploaded all files
    this.uploader.onCompleteItem = (fileItem, response, status) => {
      if (status === 200) {
        const completedItem = this.isPresignedUrl
          ? { Name: fileItem.file.name, DocumentId: fileItem.documentId }
          : { ...response[0] };
        this.uploader.uploadedItems.push(completedItem);
        this.isPresignedUrl && this.activatePresignedDocument(fileItem);
      } else {
        this.logger.error(response);
      }
    };

    this.uploader.onCompleteAll = () => {
      // will call onCompleteAll only if defined
      if (this.onCompleteAll) {
        this.onCompleteAll({ files: this.uploader.uploadedItems });
      }

      // waits for a second before closing the document progress for users to see
      this.$timeout(() => {
        this.uploader.uploadedItems = []; // clears current list of items
        this.uploader.clearQueue(); // clears the whole queue
      }, 1000);
      this.checkUploading();
    };
    if (!this.parentToChildNotificationRegistration) {
      return;
    }
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
  }

  processParentNotification(eventHandler) {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (eventHandler) {
      case EVENT_HANDLER.ON_CANCEL_ALL:
        this.cancelAll();
        break;
      default:
        break;
    }
  }

  checkUploading() {
    if (!this.onUploading || typeof this.onUploading !== 'function') {
      return;
    }
    const { isUploading } = this.uploader;
    this.onUploading({ isUploading });
  }

  onCancelItem(item) {
    if (item.isUploading) {
      item.cancel();
    } else {
      item.remove();
    }
    this.checkUploading();
  }

  setDocumentPresignedUrl(item) {
    const { familyId, uploadType } = this;
    const args = {
      FamilyId: familyId,
      UploadType: uploadType || 0,
      FileName: item.file.name,
      ContentType: item.file.type,
      Size: item.file.size,
    };
    this.documentService
      .getDocumentPresignedUrl(args)
      .then(({ url, documentId }) => {
        if (!url || !documentId) {
          return;
        }
        item.url = url;
        item.documentId = documentId;
        this.logger.info(`Uploading ${item.file.name} using presigned url`);
        this.uploader.uploadItem(item);
      })
      .catch((error) =>
        catchError(error, 'File uploader : get presigned document url failed'),
      );
  }

  activatePresignedDocument(fileItem) {
    this.documentService
      .activatePresignedDocument(fileItem.documentId)
      .then(() => this.logger.info('Document successully activated!'))
      .catch((error) =>
        catchError(error, 'File uploader : activate presigned document failed'),
      );
  }
}

export default FileUploadCtrl;
