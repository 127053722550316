import { isLMBranded } from 'Common/utilities/brandingCategory';

export const getApiUrl = ({
  brandingCategoryID,
  tradingName,
  loanAppId,
  adviserOrgId,
}) => {
  return isLMBranded(brandingCategoryID)
    ? `/goal/#/broker-login/${loanAppId}`
    : `/${adviserOrgId}/${tradingName}/goal/#/broker-login/${loanAppId}`;
};

export const getGoalSetterUrl = ({
  url,
  brandingCategoryID,
  tradingName,
  loanAppId,
  adviserOrgId,
}) => {
  if (!url) {
    return;
  }
  const params = {
    brandingCategoryID,
    tradingName,
    loanAppId,
    adviserOrgId,
  };
  return `${url}${getApiUrl(params)}`;
};
