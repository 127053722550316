import { MERGE_FIELD_CATEGORY } from 'Common/constants/mergeFieldCategory';
import MergeFieldCategory from './mergeFieldCategory';

class MergeFields {
  constructor(data) {
    this.data = data;
    this.contactCategory = this.getCategory(MERGE_FIELD_CATEGORY.CONTACT);
    this.loanCategory = this.getCategory(MERGE_FIELD_CATEGORY.LOANS);
    this.otherCategory = this.getCategory(MERGE_FIELD_CATEGORY.OTHER);
  }

  getCategory(categoryName) {
    if (!this.data) {
      return;
    }
    const category = this.data.find((item) => item.Category === categoryName);
    return new MergeFieldCategory(category);
  }

  getContactFieldValue(fieldName) {
    return this.contactCategory.getFieldValue(fieldName);
  }

  getLoanFieldValue(fieldName) {
    return this.loanCategory.getFieldValue(fieldName);
  }

  getOtherFieldValue(fieldName) {
    return this.otherCategory.getFieldValue(fieldName);
  }
}

export default MergeFields;
