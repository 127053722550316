/* eslint-disable angular/function-type */
import angular from 'angular';
import { setupLogger, LogLevel } from '@loanmarket/logger-core';
import { CloudWatchLogger } from '@loanmarket/logger-cloud-watch';
import { cloudWatchKeyFunctions } from '@loanmarket/logger-cloud-watch/lib/utils/browser-fingerprint';

import { getConfigFromEnv } from 'Common/utilities/config';
import { setupRollout, getRolloutFeatureFlags } from 'Common/utilities/rollout';

import packet from './packet';
import {
  APP_MEDIAQUERY,
  JS_REQUIRES,
  DEMO_VIDEO,
  LEGACY_ANGULAR_HOST,
  TECHNOLOGY,
} from './config/config.constant';
import './config/config.calendar.js';
import ngIntlTelInput from './config/config.ngIntlTelInput';
import configIdleWatcher from './config/config.idlewatcher';
import configRouter from './config/config.router';
import configLoadingBar from './config/config.loadingbar';
import configBreadcrumb from './config/config.breadcrumb';
import configStorage from './config/config.storage';
import configBootstrapCalendar from './config/config.bootstrapcalendar';
import configLocationRoute from './config/config.locationRoute';
import configHttpProvider from './config/config.httpProvider';
import configNgSrc from './config/config.ng-src';
import configDocumentTitle from './config/config.documentTitle';
import configUibModal from './config/config.uibModal';

import main from './main';
import { runTheme, bootstrapOktaAuthentication } from './bootstrap';

import '../../../sass/styles.scss';
import '../../../sass/plugins.scss';
import '../../../sass/themes/main.scss';
import common from '../../src/common';
import components from '../../src/components';
import scenes from '../../src/scenes';
import services from '../../src/services';
import controllers from '../../src/controllers';
import PortalComponentsService from '../../react/portalComponentsService';

import { loadModules } from './load-angular-modules';

export const runApp = (setPortalComponents) => {
  /* eslint-disable angular/window-service */
  try {
    // Check to see if the app has already been registered
    const app = angular.module('app');
    const rootElement = angular.element('#bootstrapApp');
    if (app) {
      // App has already been configured
      if (!rootElement.scope()) {
        // Ensure portal service is registered with current state function
        // (new state function created after AngularNav component re-mounts)
        registerPortalService(app, setPortalComponents);

        // Add app to root node
        angular.bootstrap(rootElement, ['app']);
      }
      return;
    }
  } catch {
    // App has not been registered yet
  }

  // eslint-disable-next-line angular/function-type
  angular
    .module('app', [packet, common, components, scenes, services, controllers])
    .constant(...APP_MEDIAQUERY)
    .constant(...JS_REQUIRES)
    .constant(...DEMO_VIDEO)
    .constant(...LEGACY_ANGULAR_HOST)
    .constant(...TECHNOLOGY)
    .config(configIdleWatcher)
    .config(ngIntlTelInput)
    .config(configRouter)
    .config(configLoadingBar)
    .config(configBreadcrumb)
    .config(configStorage)
    .config(configLocationRoute)
    .config(configUibModal)
    .config(configHttpProvider)
    .config(configNgSrc)
    .run(configBootstrapCalendar)
    .run(configDocumentTitle)
    .run(main);

  // Always need to register the service, even if not running inside Portal,
  // so that Angular's DI doesn't freak out when it can't find the service.
  // setPortalComponents will be undefined in this case. Handled inside the service.
  registerPortalService(angular.module('app'), setPortalComponents);

  /* eslint-disable angular/window-service */
  window.app = angular.module('app');
  angular.element(async () => {
    loadModules();
    runTheme();
    const data = getConfigFromEnv();
    if (data.useCloudWatch === 'true') {
      setupLogger({
        minLogLevel: LogLevel.Info,
        logger: CloudWatchLogger,
        customLoggerArgs: {
          group: '/aws/frontend/mycrm-frontend',
          userAgent: window.navigator.userAgent,
          cloudWatchConfiguration: {
            accessKeyId: data.cloudWatchLogsAccessKeyId,
            secretAccessKey: data.cloudWatchLogsSecretAccessKey,
            region: data.cloudWatchLogsRegion,
          },
          maxRetries: 3,
          keyFunctions: cloudWatchKeyFunctions,
          newLogStreamPerRequest: true,
        },
      });
    } else {
      setupLogger({
        minLogLevel: LogLevel.Trace,
      });
    }

    await setupRollout(data);

    const features = getRolloutFeatureFlags();

    if (features.inMaintenance) {
      window.location.assign(data.oktaLogin);
      return;
    }
    await bootstrapOktaAuthentication();
  });
};

const registerPortalService = (app, setPortalComponents) => {
  if (!app) {
    return;
  }

  // eslint-disable-next-line angular/module-getter
  app.factory(
    // For when running inside portal only, but needs to always be added
    // so Angular's DI doesn't freak out when it can't find it.
    // Internally the service handles checking if it is running inside Portal
    'portalComponentsService',
    [
      'TECHNOLOGY',
      (TECHNOLOGY) =>
        new PortalComponentsService(setPortalComponents, TECHNOLOGY),
    ],
  );
};
