import template from './tradeReferenceModal.html';
import controller from './tradeReferenceModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    referenceDetails: '<',
    onSaveReference: '&',
  },
};
