import template from './esignDownloadEvidenceSummary.html';
import controller from './esignDownloadEvidenceSummaryCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    documentId: '<',
  },
};
