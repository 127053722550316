import template from './fileQuickActions.html';
import controller from './fileQuickActionsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    fileGeneralDetails: '<',
    scenarioDetails: '<',
    loanId: '<',
    loanAppId: '<',
    familyId: '<',
    familyList: '<',
    involvedParties: '<',
    referralId: '<',
    applicants: '<',
    onTriggerGamePlanGeneration: '&',
  },
};
