import { getOpportunityStatus } from 'Common/utilities/loanOpportunity';

class LoanFileStatusService {
  constructor(
    loanStatusConversionService,
    loanFileService,
    pipelineService,
    uiService,
  ) {
    'ngInject';

    this.loanStatusConversionService = loanStatusConversionService;
    this.loanFileService = loanFileService;
    this.pipelineService = pipelineService;
    this.uiService = uiService;
  }

  getLoanFileStatus(loanId) {
    if (!this.uiService.viewOfAfileTurnedOn) {
      return getOpportunityStatus(this.pipelineService);
    }
    return this.loanFileService.getStatusList(loanId);
  }

  setLoanFileStatusConversion({ card, status, previousStatus }) {
    card.conversionStatusId = status.ConversionStatusId;
    return this.loanStatusConversionService
      .initializeConversion({
        status,
        previousStatus,
      })
      .then((result) => {
        if (!result || !result.isOk) {
          return result;
        }
        card.reasonSubStatusId = result.selectedReasonId;
        return this.loanStatusConversionService.convertStatus({ card, result });
      });
  }
}

export default LoanFileStatusService;
