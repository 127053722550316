/*
    @author : Arnaldo Jan Ubas
    Financials Shared Services
    ---
    copied from loan application financialSharedService.js - rolandbarro
*/

import angular from 'angular';

export const loadModule = () =>
  angular.module('app').factory('insuranceFinancialsSharedService', [
    function () {
      var factory = this;

      return factory;
    },
  ]);
