import template from './applicantAddRelatedParty.html';
import controller from './applicantAddRelatedPartyCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    partyType: '@',
    settingsNoPartyText: '@',
    partyTypeId: '<',
    partyList: '<',
    settingsNoParty: '<',
    loanAppFormWarning: '<',
    displayPartyCompany: '<',
    showSearchPanel: '<',
    searchResult: '<',
    isSearchingClients: '<',
    onPartySelect: '&',
    onPartyDelete: '&',
    onAddPartyClick: '&',
    onInputSearchKeyup: '&',
    onSearchResultSelect: '&',
    onManualCreateClick: '&',
  },
};
