import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('callAddFileWithUniqueid', function callAddFileWithUniqueid(
      $timeout,
    ) {
      return {
        restrict: 'A',
        scope: {
          uniqueId: '@',
          isInputNextSibling: '<',
        },
        link(scope, element) {
          const timeout = $timeout(() => {
            const aReference = element[0];
            aReference.addEventListener('click', () => {
              const toBeClicked = scope.isInputNextSibling
                ? aReference.nextElementSibling
                : aReference;
              const element = toBeClicked.querySelector(
                `#add-file-mini-btn-${scope.uniqueId}`,
              );
              if (element) {
                element.click();
              }
            });
            $timeout.cancel(timeout);
          });
        },
      };
    });
