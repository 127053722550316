export function parseIntValue(list) {
  if (!list) {
    return [];
  }
  return list.map((option) => {
    option.Value = parseInt(option.Value, 10);
    return option;
  });
}

export function parseToInt10(value) {
  if (!value) {
    return 0;
  }
  return parseInt(value, 10);
}

export function parseFloatToPercent(value) {
  if (!value) {
    return 0;
  }
  const toRound = parseFloat(value * Math.pow(10, 4));
  const rounded = Math.round(toRound) / Math.pow(10, 2);
  return +rounded.toFixed(2);
}
