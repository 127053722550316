/* eslint-disable angular/window-service */
// Styles
require('bootstrap/dist/css/bootstrap.min.css');
require('themify-icons/css/themify-icons.css');
require('flag-icon-css/css/flag-icon.min.css');
require('animate.css/animate.min.css');

// Scripts
require('core-js/stable');
require('regenerator-runtime/runtime');
window.$ = window.jQuery = require('jquery');
window.swal = require('sweetalert');
require('sweetalert/lib/sweet-alert.css');
window.moment = require('moment');
window.html2canvas = require('html2canvas');
window.domtoimage = require('dom-to-image');
window.JsPDF = require('jspdf');

// TODO: remove this from window scope
window.hubsStatus = 'pending';

require('jquery-migrate/dist/jquery-migrate.min.js');
require('angular');
require('d3');
require('chart.js');
require('nestable');
require('bootstrap-touchspin');
require('bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css');
require('jquery-appear/build/jquery.appear.min.js');
require('selectize/dist/css/selectize.default.css');
require('RWD-Table-Patterns/dist/js/rwd-table.min.js');
require('RWD-Table-Patterns/dist/css/rwd-table.min.css');
require('select2');
require('select2/select2.css');
require('jquery-mask-plugin/dist/jquery.mask.min.js');
require('moment-timezone');
require('perfect-scrollbar/jquery')(window.$);
require('perfect-scrollbar/dist/css/perfect-scrollbar.css');
require('spin.js');
require('ladda');
require('ladda/dist/ladda-themeless.min.css');
require('slick-carousel');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');
require('ng-bs-daterangepicker/dist/ng-bs-daterangepicker.min.js');
require('file-saver');
require('jquery-match-height');
require('svg-injector');
require('waypoints/lib/jquery.waypoints.min.js');
require('waypoints/lib/shortcuts/sticky.min.js');
require('magnifier/magnifier.css');
window.MagnifierEvent = require('magnifier/demo/Event.js').Event;
window.Magnifier = require('magnifier/Magnifier.js').Magnifier;
// Previously, lazy loaded modules
require('angular-appear/build/angular-appear.min.js');
require('angular-filter-count-to/dist/angular-filter-count-to.min.js');
require('eonasdan-bootstrap-datetimepicker');
require('eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css');
require('spectrum-colorpicker/spectrum.js');
require('spectrum-colorpicker/spectrum.css');
require('angular-spectrum-colorpicker/dist/angular-spectrum-colorpicker.min.js');

// Temporary solution to bundle multiple javascript files into one. This will be replaced by ES6 import.
