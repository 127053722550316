import template from './opportunityModal.html';
import controller from './opportunityModalCtrl';

export default {
  template,
  bindings: {
    modalInstance: '<',
    familyId: '<',
    clients: '<',
    pipelineItemId: '<',
    isLeadsToOpportunity: '<',
  },
  controller,
  controllerAs: 'vm',
};
