import { get } from 'lodash';
import { parseToInt10 } from 'Common/utilities/parse';
import { DD_MMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';
import { ONE_OFF_TYPES } from 'Common/constants/commissionType';

export const setProperDatePerCommissionType = (vm) => (objData) => {
  const typeDate =
    objData.typeId === ONE_OFF_TYPES.REFIX
      ? objData.refixDate
      : objData.settlementDate;
  objData.date = !vm.isCommissionEnhancementsV3On
    ? objData.settlementDate
    : typeDate;
};

export const initCommissionEnhancements = ({ vm, configService }) => {
  vm.isCommissionEnhancementsV3On =
    configService.feature.commissionEnhancementsV3;
  vm.dateColumnHeaderLabel = vm.isCommissionEnhancementsV3On
    ? 'Settlement / Refix Date'
    : 'Settled Date';
  vm.dateFormat = DD_MMM_YYYY_FORMAT;
  vm.ONE_OFF_TYPES = ONE_OFF_TYPES;
  vm.setProperDatePerCommissionType = setProperDatePerCommissionType(vm);
  vm.noDateSetText = '(not set)';
};

export const isIncludeCommissionType = ({ configService, type }) =>
  (configService.feature.commissionEnhancementsV3 &&
    !['Bonus', 'Trail'].includes(type.Name)) ||
  (!configService.feature.commissionEnhancementsV3 && type.Name !== 'Trail');

export const getMatchedLoanStructure = ({
  loanInformationService,
  product,
}) => {
  if (!loanInformationService || !product) {
    return;
  }

  const loanList = get(loanInformationService, 'loanStructureList', []);
  return (
    loanList.find(
      (loan) => parseToInt10(loan.LoanStructureId) === product.id,
    ) || {}
  );
};
