import angular from 'angular';
import { onTriggerInit } from './util/onTrigger';

export const loadModule = () =>
  angular.module('app').directive('onTrigger', function onTrigger($timeout) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        onTriggerInit({ scope, element, attrs, $timeout });
      },
    };
  });
