import { pdfBuilderForUI } from 'Common/mappers/document';

export default class ProductFinderModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'product-finder';
  }

  download(brokerEventId, model) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/${brokerEventId}/download`, model)
      .then(({ data }) => pdfBuilderForUI(data));
  }
}
