import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('numbersOnly', function numbersOnly() {
    return {
      require: 'ngModel',
      link(scope, element, attr, ngModelCtrl) {
        function fromUser(text) {
          if (text) {
            const transformedInput = text.replace(/\D/g, '');

            if (transformedInput !== text) {
              ngModelCtrl.$setViewValue(transformedInput);
              ngModelCtrl.$render();
            }
            return transformedInput;
          }
          return undefined;
        }
        ngModelCtrl.$parsers.push(fromUser);
      },
    };
  });
