import template from './onboardingEntityDetails.html';
import controller from './onboardingEntityDetailsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {},
};
