import template from './menus.html';
import controller from './menusCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    visible: '<',
    displayActionButton: '<',
    creditVisibleAction: '<',
    showMenusAction: '<',
    pageType: '<',
    onCeaseContact: '&',
    onOpenCreditCheck: '&',
  },
};
