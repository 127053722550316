class QuestionnairePreviewCtrl {
  constructor(onboardingService, onboardingDataService) {
    'ngInject';

    this.onboardingService = onboardingService;
    this.onboardingDataService = onboardingDataService;
  }

  getFamilyQuestionnaire() {
    this.onboardingService
      .questionaireListGet(this.familyId)
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }
        this.questionnaireList = data;
      });
  }

  $onInit() {
    this.familyId = this.onboardingDataService.getFamilyId();
    this.getFamilyQuestionnaire();
  }
}

export default QuestionnairePreviewCtrl;
