import template from './commissionUnmatchedDetails.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    commission: '<',
    modalInstance: '<',
  },
};
