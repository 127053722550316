import angular from 'angular';
import { disableChildren } from './util/disableChildrenTab';

const disableChildrenTab = () => {
  return {
    restrict: 'A',
    link: (scope, elem, attrs) => {
      const disableTabbing = disableChildren({ scope, elem, attrs });
      attrs.$observe('disableChildrenTab', (disable) => {
        disableTabbing(disable);
      });
    },
  };
};

export const loadModule = () =>
  angular.module('app').directive('disableChildrenTab', disableChildrenTab);
