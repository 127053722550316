import {
  pipelineSettingsBuilderForAPI,
  pipelineSettingsBuilderForUI,
} from 'Common/mappers/pipeline';
import moment from 'moment';

class PipelineSettings {
  constructor(args = {}, services = {}) {
    'ngInject';

    const { pipelineService } = services;
    this.pipelineService = pipelineService;

    this.setSavedSettings(args);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  setSavedSettings(args) {
    const {
      isFetchAllPipelineStatusIDs,
      sortColumn,
      sortType,
      statusId,
      advisorId,
      labelId,
      numberPerPage,
      probability,
      referralItemID,
      referralCategoryID,
      referral,
      isCompact,
      sortingMode,
      isTableView,
      customSort,
      customerSort,
      tableViewExcludedColumns,
      subStatusId,
      searchClientName,
      pipelineCardsId,
      statusIds,
      conversionStatusIDs,
      isOpportunity,
      isLeads,
      dateCreatedStart,
      dateCreatedEnd,
      isNzCorp,
      assistantId,
    } = args;

    this.isFetchAllPipelineStatusIDs = isFetchAllPipelineStatusIDs || 0;
    this.sortColumn = sortColumn || 'ClientName';
    this.sortType = sortType || 'DESC';
    this.statusId = statusId || 0;
    this.advisorId = advisorId || 0;
    this.labelId = labelId || 0;
    this.numberPerPage = numberPerPage || 10;
    this.probability = probability || 'All';
    this.referralItemID = referralItemID || 0;
    this.referralCategoryID = referralCategoryID || 0;
    this.referral = referral || 'All';
    this.isCompact = isCompact || false;
    this.sortingMode = sortingMode || '';
    this.isTableView = isTableView || false;
    this.customSort = customSort || '';
    this.customerSort = customerSort || '';
    this.tableViewExcludedColumns = tableViewExcludedColumns || '';
    this.subStatusId = subStatusId || 0;
    this.searchClientName = searchClientName || '';
    this.pipelineCardsId = pipelineCardsId || 0;
    this.statusIds = statusIds || '';
    this.conversionStatusIDs = conversionStatusIDs || '';
    this.isOpportunity = isOpportunity || false;
    this.isLeads = isLeads || false;
    this.pipelineStatusIDs = null;
    this.dateCreatedStart = dateCreatedStart || '';
    this.dateCreatedEnd = dateCreatedEnd || '';
    this.isNzCorp = isNzCorp;
    this.assistantId = assistantId || 0;
  }

  updateCardList({
    primaryStorageKey = 'customerSort',
    sortItemData,
    isAppendSortHistory = false,
  }) {
    const {
      primaryKey: itemPrimaryKey,
      columnName: itemColumnName,
      itemPosition,
    } = sortItemData;
    const sortArray =
      (this[primaryStorageKey] &&
        this[primaryStorageKey].length &&
        this[primaryStorageKey].split(',')) ||
      [];
    const sortList = sortArray.reduce((accum, currentObj) => {
      const sortSplit = currentObj.split(':');
      const SORT_SPLIT_CONTENTS = {
        COLUMN_NAME: 0,
        PRIMARY_KEY: 1,
        POSITION: 2,
      };
      const sortData = {
        columnName: sortSplit[SORT_SPLIT_CONTENTS.COLUMN_NAME],
        primaryKey: sortSplit[SORT_SPLIT_CONTENTS.PRIMARY_KEY],
        position: sortSplit[SORT_SPLIT_CONTENTS.POSITION],
      };
      return accum.concat(sortData);
    }, []);

    const findExistingSortObject =
      sortList.find(
        (sort) =>
          parseInt(sort.primaryKey, 10) === parseInt(itemPrimaryKey, 10),
      ) || null;

    if (findExistingSortObject && !isAppendSortHistory) {
      findExistingSortObject.columnName = itemColumnName;
      findExistingSortObject.primaryKey = itemPrimaryKey;
      findExistingSortObject.position = itemPosition;
    } else {
      const newSortObject = {
        columnName: itemColumnName,
        primaryKey: itemPrimaryKey,
        position: itemPosition,
      };
      sortList.push(newSortObject);
    }

    const sortString = sortList
      .reduce((accum, currentObj) => {
        const columnSort = `${currentObj.columnName}:${currentObj.primaryKey}:${currentObj.position}`;
        return accum.concat(columnSort);
      }, [])
      .join(',');

    this[primaryStorageKey] = sortString;
    this.pipelineService.setPipelineCardFilters(
      pipelineSettingsBuilderForAPI(this),
    );
  }

  formatCreatedDateFilter() {
    if (!this.dateCreatedStart) {
      return;
    }

    if (!this.dateCreatedEnd) {
      return `Today, ${moment(this.dateCreatedStart).format('DD MMM YYYY')}`;
    }

    return `From ${moment(this.dateCreatedStart).format('DD MMM')} to ${moment(
      this.dateCreatedEnd,
    ).format('DD MMM YYYY')}`;
  }
}

export class PipelineLeadSettingsService extends PipelineSettings {
  constructor(args = {}, services = {}) {
    'ngInject';

    super(args, services);
    const { advisorId } = args;

    this.displayFilterDate = this.formatCreatedDateFilter();
    if (this.pipelineService && args && advisorId) {
      this.setSavedSettings(args);
      this.setPipelineSettings({ ...this });
    } else if (this.pipelineService && !args && !advisorId) {
      this.setSavedSettings({ ...this });
      this.setPipelineSettings();
    }
  }

  setPipelineSettings(args) {
    this.pipelineService
      .setPipelineCardFilters(pipelineSettingsBuilderForAPI(args))
      .then((res) => {
        const { data } = res;
        if (!data || !data.length) {
          return;
        }
        this.setSavedSettings(pipelineSettingsBuilderForUI(data[0]));
      });
  }
}

export class PipelineOpportunitySettingsService extends PipelineSettings {
  // eslint-disable-next-line sonarjs/no-identical-functions
  constructor(args = {}, services = {}) {
    'ngInject';

    super(args, services);
    const { advisorId } = args;

    this.displayFilterDate = this.formatCreatedDateFilter();
    if (this.pipelineService && args && advisorId) {
      this.setSavedSettings(args);
      this.setPipelineSettings({ ...this });
    } else if (this.pipelineService && !args && !advisorId) {
      this.setSavedSettings({ ...this });
      this.setPipelineSettings();
    }
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  setPipelineSettings(args) {
    this.pipelineService
      .setPipelineCardFilters(pipelineSettingsBuilderForAPI(args))
      // eslint-disable-next-line sonarjs/no-identical-functions
      .then((res) => {
        const { data } = res;
        if (!data || !data.length) {
          return;
        }
        this.setSavedSettings(pipelineSettingsBuilderForUI(data[0]));
      });
  }
}

export class PipelineApplicationSettingsService extends PipelineSettings {
  constructor(args = {}, services = {}) {
    'ngInject';

    super(args, services);
    const { advisorId } = args;

    this.displayFilterDate = this.formatCreatedDateFilter();

    if (this.pipelineService && args && advisorId) {
      this.customerSort = null;
      this.setSavedSettings(args);
      this.setPipelineSettings({ ...this });
    } else if (this.pipelineService && !args && !advisorId) {
      this.customerSort = null;
      this.setSavedSettings({ ...this });
      this.setPipelineSettings();
    }
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  setPipelineSettings(args) {
    this.pipelineService
      .setPipelineCardFilters(pipelineSettingsBuilderForAPI(args))
      // eslint-disable-next-line sonarjs/no-identical-functions
      .then((res) => {
        const { data } = res;
        if (!data || !data.length) {
          return;
        }
        this.setSavedSettings(pipelineSettingsBuilderForUI(data[0]));
      });
  }
}
