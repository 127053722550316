import { marketingAutomationBuilderForUI } from 'Common/mappers/subscription';

class SubscriptionService {
  constructor(httpClient, $q) {
    'ngInject';

    this.httpClient = httpClient;
    this.$q = $q;
    this.apiBaseUrl = 'subscription';
    this.defaultOptions = [null, true, false];
  }

  marketingAutomation() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/marketing-automation`)
      .then((response) => marketingAutomationBuilderForUI(response.data));
  }

  setAdviserAddressModified(adviserId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/adviser-address-modified`,
      {},
      { adviserId },
    );
  }
}

export default SubscriptionService;
