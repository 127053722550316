import template from './loanThreeDotsMenu.html';
import controller from './loanThreeDotsMenuCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isActiveLoan: '<',
    loanDetails: '<',
    loanDetailScope: '<',
    lendingDetailScope: '<',
    loadingPropertyKey: '<',
    allowOpenDeal: '<',
  },
};
