export const BORDER_RADIUS_NORMAL = {
  pointBorderWidth: 2,
  pointRadius: 3,
};

export const BORDER_RADIUS_MEDIUM = {
  pointBorderWidth: 1,
  pointRadius: 4,
};

export const TOOLTIP = {
  MODE_INDEX: 'index',
  FONT_NORMAL: 14,
  CARET_PADDING: 10,
  CARET_SIZE: 0,
  Y_BOTTOM: 'bottom',
  X_CENTER: 'center',
  CARET_SIZE_NORMAL: 5,
};

export const LAYOUT = {
  PADDING_RIGHT: 20,
  PADDING_TOP: 10,
};

export const TICKS = {
  PRECISION: 0,
  MAX_TICK: 20,
};

export const OFFSET_CALCULATOR_SERIES = ['Owning with Offset', 'Loan Balance'];
