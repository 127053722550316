import template from './commissionHistory.html';
import controller from './commissionHistoryCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    headers: '<',
    selectedHeader: '<',
    adviserList: '<',
    isLoadingFilter: '<',
    isLoadingList: '<',
    onSendEnquiry: '&',
    onSlideDate: '&',
    onSelectHeader: '&',
    openCalendar: '&',
    isDownloading: '<',
    onDownloadStatement: '&',
  },
};
