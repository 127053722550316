import template from './importantDatesPopover.html';
import ImportantDatesPopoverCtrl from './importantDatesPopoverCtrl';

export default {
  template,
  bindings: {
    loanDetails: '<',
    importantDateField: '<',
    onClose: '&',
    familyId: '<',
  },
  controller: ImportantDatesPopoverCtrl,
  controllerAs: 'vm',
};
