import { creditCheckPdfReportBuilderForUI } from 'Common/mappers/creditCheck';

class CreditChecksService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'CreditEnquiry';
  }

  getCreditChecks(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/ClientCreditCheck`, params);
  }

  setCreditChecks(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/ClientCreditCheck`, data);
  }

  getPdfReport(data) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${data}/pdf-report`)
      .then(
        (response) =>
          response.data && creditCheckPdfReportBuilderForUI(response.data),
      );
  }

  setCreditToDocument(familyId, creditCheckId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/adviser/${familyId}/credit-check/${creditCheckId}`,
    );
  }
}

export default CreditChecksService;
