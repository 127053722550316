import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('triggerClick', function triggerClickDirective($window) {
      return {
        restrict: 'A',
        scope: {
          target: '<triggerClick',
        },
        link(scope, element) {
          const clickListener = () => {
            const targetElement = $window.document.querySelectorAll(
              scope.target,
            );
            angular.element(targetElement).triggerHandler('click');
          };
          element.on('click', clickListener);
          scope.$on('$destroy', () => {
            element.off('click', clickListener);
          });
        },
      };
    });
