export const LENDER_SUBMISSION_EMAIL_TYPES = [
  {
    typeId: 1,
    type: 'MyCRM Default',
  },
  {
    typeId: 2,
    type: 'Adviser Default',
  },
  {
    typeId: 3,
    type: 'Other',
  },
];
