import template from './possibleAdverseChanges.html';
import controller from './possibleAdverseChangesCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isCompleted: '<',
    isLodged: '<',
    loanAppFormWarning: '<',
    loanApplicants: '<',
    loanAppId: '<',
    familyId: '<',
    onChangeStatus: '&',
    isLoanAppLoaded: '<',
  },
};
