import { formatRawNewLine } from 'Common/utilities/string';
import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';
import { toastWarning, toastError } from 'Common/utilities/alert';

export default class SendSmsCtrl {
  constructor($q, templateService, mailTemplateService) {
    'ngInject';

    this.$q = $q;
    this.templateService = templateService;
    this.mailTemplateService = mailTemplateService;
  }

  $onInit() {
    this.templates = {};
    this.isComplete = true;

    if (typeof this.allowCustom === 'undefined') {
      this.allowCustom = true;
    }
  }

  manageTemplate() {
    const modalInstance = this.templateService.openSMSTemplatesManagement(
      false,
    );
    if (!modalInstance || !modalInstance.result) {
      return;
    }

    return modalInstance.result.then((hasChanges) => {
      if (!hasChanges) {
        return;
      }
      this.smsTemplatesApi.refresh();
    });
  }

  onTemplatesInit(smsTemplatesApi) {
    this.smsTemplatesApi = smsTemplatesApi;
  }

  translateTemplate(templateContent) {
    const hasNoRecipients =
      !this.recipientsChoices || !this.recipientsChoices.length;
    const hasNoContent = !templateContent || !templateContent.trim();
    if (hasNoRecipients || hasNoContent) {
      this.sms.body = '';
      return;
    }

    // Note: we only want to format per client, not per family
    const hasMoreThanOneClient = this.recipientsChoices.length > 1;

    const promises = this.recipientsChoices.map((recipient) => {
      const mergeFieldData = {
        familyId: recipient.familyId,
        client: recipient.clientId,
        loan: null,
        templateContent,
        policyId: 0,
        isSMS: true,
      };

      return this.mailTemplateService.translateMailTemplate(mergeFieldData);
    });

    return this.$q.all(promises).then(
      (results) => {
        this.recipientsChoices = this.recipientsChoices.map(
          (recipient, idx) => ({
            ...recipient,
            message: formatRawNewLine(results[idx]),
          }),
        );

        this.sms.body = hasMoreThanOneClient
          ? formatRawNewLine(templateContent)
          : formatRawNewLine(results[0]);
      },
      (error) => {
        this.recipientsChoices = this.recipientsChoices.map((recipient) => ({
          ...recipient,
          message: formatRawNewLine(error.templateContent || templateContent),
        }));
      },
    );
  }

  setTemplateSelection(template) {
    if (!template) {
      return;
    }
    const { UserTemplateContent } = template;

    this.templates.selectedTemplate = template;

    if (UserTemplateContent) {
      this.translateTemplate(UserTemplateContent);
      return;
    }

    if (this.recipientsChoices) {
      this.recipientsChoices = this.recipientsChoices.map((recipient) => ({
        ...recipient,
        message: '',
      }));
    }

    this.sms.body = '';
  }

  changeSmsObj(smsModel) {
    this.sms = {
      ...smsModel,
    };
    this.isSmsSending = false;
  }

  changeIsComplete(isComplete) {
    this.isComplete = isComplete;
  }

  getRecipientMessage(clientId, familyId) {
    const recipient =
      this.recipientsChoices.find(
        (choice) =>
          clientId === choice.clientId || familyId === choice.familyId,
      ) || {};
    return recipient.message || this.sms.body;
  }

  sendSms() {
    const { recipientsPhoneNumbers } = this.sms;

    if (!recipientsPhoneNumbers || !recipientsPhoneNumbers.length) {
      toastWarning('Please select a recipient.');
      return;
    }

    const recipients = recipientsPhoneNumbers.map((selected) => {
      return typeof selected === 'object'
        ? {
            ...selected,
            customMessage: this.getRecipientMessage(
              selected.clientId,
              selected.familyId,
            ),
          }
        : {
            ...this.recipientsChoices[0],
            phoneNumber: selected,
            customMessage: this.getRecipientMessage(
              this.recipientsChoices[0].clientId,
              this.recipientsChoices[0].familyId,
            ),
          };
    });

    this.isSmsSending = true;

    const sendMessages = recipients.map((recipient) => {
      const { customMessage, ...recipientDetails } = recipient;
      return this.onSendSms({
        sms: {
          recipients: [recipientDetails],
          message: customMessage,
          isComplete: this.isComplete,
        },
      });
    });

    return this.$q
      .all(sendMessages)
      .then((results) => {
        this.onSendSmsSuccess && this.onSendSmsSuccess({ results });
        this.closeModal();
      })
      .catch(toastError);
  }

  onSaveAsDefault() {
    this.templateService.openDefaultTemplateSettings(
      this.templates.selectedTemplate,
      SEND_METHOD_TYPES.SMS,
    );
  }

  closeModal(isRefresh) {
    this.isSmsSending = false;
    this.modalInstance.close({ isRefresh });
  }
}
