import angular from 'angular';
import main from './main';
import accreditationStatusDropdown from './accreditationStatusDropdown';
import mobileAccreditation from './mobileAccreditation';
import newCorpAccreditationTable from './newCorpAccreditationTable';
import editMultiProviderModal from './editMultiProviderModal';
import products from './tabs/products';
import providersTab from './tabs/providersTab';
import accreditationContactModal from './tabs/accreditationContactModal';
import adviserProduct from './tabs/adviserProduct';

export default angular
  .module('adviserAccreditationTabs.scenes.components', [])
  .component('adviserAccreditationTabs', main)
  .component('accreditationStatusDropdown', accreditationStatusDropdown)
  .component('mobileAccreditation', mobileAccreditation)
  .component('newCorpAccreditationTable', newCorpAccreditationTable)
  .component('editMultiProviderModal', editMultiProviderModal)
  .component('providersTab', providersTab)
  .component('accreditationContactModal', accreditationContactModal)
  .component('adviserProduct', adviserProduct)
  .component('products', products).name;
