import {
  downloadDocument,
  downloadAllAttachment,
} from 'Assets/js/controllers/contacts/util/documentsDetailCtrl';
import { parseToInt10 } from 'Common/utilities/parse';
import { DOCUMENT_BROKEN_ERROR_MSG } from 'Common/constants/document';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import {
  getTimeOnDate,
  formatDateDocument,
  collectDocumentId,
} from './util/documentAccordion';

export default class DocumentAccordionCtrl {
  constructor(
    generalService,
    insuranceApplicationService,
    downloadDataService,
    contactModelService,
    $state,
    loanScenarioService,
    currentUserService,
  ) {
    'ngInject';

    this.$state = $state;
    this.generalService = generalService;
    this.contactModelService = contactModelService;
    this.insuranceApplicationService = insuranceApplicationService;
    this.contactModelService = contactModelService;
    this.downloadDataService = downloadDataService;
    this.loanScenarioService = loanScenarioService;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.settledLoanStatus = LOAN_STATUS.EXISTING.SETTLED;
    this.familyId = this.$state.params.familyId;
    this.currentPage = 1;
    this.loanDetails = null;
    this.totalLoanAmount = 0;
    this.currentTotalNumberOfFiles = this.docList.length || 0;
    this.noOfFilesParsed = parseToInt10(this.noOfFiles);
    this.loanAppIdParsed = parseToInt10(this.loanAppId);
    this.insuranceFileIdParsed = parseToInt10(this.insuranceFileId);
    this.showPaginationChecker();
    this.sizePerPage = 5;
    this.status = {
      open: this.isOpen,
    };
    this.getTimeOnDate = getTimeOnDate;
    this.formatDateDocument = formatDateDocument;
    this.downloadAllDocsCount = this.docList.length || 0;
    this.copyAllToDataSelfRemoved = this.copyAllToData.filter((section) => {
      if (!this.loanAppIdParsed && !this.insuranceFileIdParsed) {
        return !!section.loanApplicationId || !!section.insuranceFileId;
      } else if (this.loanAppIdParsed) {
        return section.loanApplicationId !== this.loanAppIdParsed;
      } else if (this.insuranceFileIdParsed) {
        return section.insuranceFileId !== this.insuranceFileIdParsed;
      } else {
        return true;
      }
    });
    this.docBrokenErrorMsg = DOCUMENT_BROKEN_ERROR_MSG;
    this.allDocsFromEsign = this.docList.every(
      (doc) => doc.DocFromESign_GroupedDocument,
    );
    this.docList = this.addTagsToEsign(this.docList);
    downloadDocument({
      $scope: this,
      generalService: this.generalService,
      insuranceApplicationService: this.insuranceApplicationService,
      downloadDataService: this.downloadDataService,
    });
    this.downloadAccordionFiles = downloadAllAttachment({
      $scope: this,
      contactModelService: this.contactModelService,
      downloadDataService: this.downloadDataService,
      loanApplicationId: this.loanAppIdParsed || null,
      insuranceFileId: this.insuranceFileIdParsed || null,
      isUseAccordionDownload: true,
    });
    this.fetchLoanDetails();
  }

  $onChanges(changes) {
    const { loadToPage } = changes;

    const loadToPageValue = loadToPage?.currentValue;
    if (loadToPageValue) {
      const loadMoreCurPage = Number(loadToPageValue) - 1;
      if (loadMoreCurPage > 0) {
        this.loadMoreGroupedDoc(loadMoreCurPage, true);
      }
    }
  }

  addTagsToEsign(docs) {
    return docs.map((doc) => {
      if (!doc.DocFromESign_GroupedDocument) {
        return doc;
      }
      return {
        ...doc,
        Tags: [...doc.Tags, 'Esign'],
      };
    });
  }

  setLoanDetails(data) {
    const loanDetails = data;
    if (!loanDetails) {
      return;
    }
    this.loanDetails = loanDetails
      .map((item) => ({
        lenderId: item.lenderId,
        lenderName: item.lenderName,
        loanApplicationId: item.loanScenarioId,
        loanAmount: item.proposedLoanAmount,
        primaryLogo: item.lenderLogo?.primaryLogo,
      }))
      .find((item) => {
        return item.loanApplicationId === this.loanAppIdParsed;
      });
  }

  fetchLoanDetails() {
    this.contactModelService
      .getLoanTimeline(this.familyId)
      .then(this.setLoanDetails.bind(this));
  }

  copyTo(section, docId) {
    const payload = {
      ...section,
      documentIds: [docId],
    };
    this.postCopyDocuments(payload).then(() => this.callAfterCopy());
  }

  copyAllTo(section) {
    if (!section) {
      return;
    }

    const isToCallDocList = this.noOfFilesParsed > this.docList.length;
    if (isToCallDocList) {
      const currentPage = 1;
      this.getMoreDocs(this.noOfFilesParsed, currentPage)
        .then((response) => {
          const payload = {
            ...section,
            documentIds: response.reduce(collectDocumentId, []),
          };
          return this.postCopyDocuments(payload);
        })
        .then(() => {
          this.callAfterCopy();
        });
    } else {
      this.postCopyDocuments({
        ...section,
        documentIds: this.docList.reduce(collectDocumentId, []),
      }).then(() => this.callAfterCopy());
    }
  }

  showPaginationChecker() {
    this.toShowPagination =
      this.currentTotalNumberOfFiles < this.noOfFilesParsed;
  }

  postCopyDocuments(section) {
    const parsedDestinationLoanAppId = parseToInt10(section.loanApplicationId);
    const parsedDestinationInsuranceFileId = parseToInt10(
      section.insuranceFileId,
    );
    const payload = {
      FamilyId: this.familyId,
      DocumentIds: section.documentIds,
      IsSourceGroupClient:
        this.loanAppIdParsed === 0 && this.insuranceFileIdParsed === 0,
      SourceLoanScenarioId: this.loanAppIdParsed || null,
      SourceInsuranceFileId: this.insuranceFileIdParsed || null,
      IsDestinationGroupClient:
        parsedDestinationLoanAppId === 0 &&
        parsedDestinationInsuranceFileId === 0,
      DestinationLoanScenarioId: parsedDestinationLoanAppId || null,
      DestinationInsuranceFileId: parsedDestinationInsuranceFileId || null,
    };
    return this.contactModelService.postCopyAllGroudDocuments(payload);
  }

  getMoreDocs(sizePerPage, currentPage) {
    const params = {
      familyId: this.familyId,
      loanApplicationId: this.loanAppIdParsed,
      insuranceFileId: this.insuranceFileIdParsed,
      pageSize: sizePerPage,
      pageNumber: currentPage,
    };
    return this.contactModelService.getGroupedLoadMore(params);
  }

  loadMoreGroupedDoc(pageNumber, jumpToPage) {
    this.currentPage = pageNumber + 1;
    const page = jumpToPage ? 1 : this.currentPage;
    const sizePerPage = jumpToPage
      ? this.currentPage * this.sizePerPage
      : this.sizePerPage;

    this.getMoreDocs(sizePerPage, page).then((response) => {
      const mappedResponse = response.map((doc) => ({
        ...doc,
        groupTitle: this.groupName,
        isSettled: this.isSettled,
      }));
      this.docList = jumpToPage
        ? mappedResponse
        : [...this.docList, ...mappedResponse];
      this.currentTotalNumberOfFiles = this.docList.length || 0;
      this.showPaginationChecker();
    });
  }
}
