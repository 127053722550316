import template from './brandColorSelector.html';
import controller from './brandColorSelectorCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    color: '<',
    className: '<',
    onSelect: '&',
  },
};
