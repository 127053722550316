import './style.scss';
import template from './clientEmailMobileInput.html';
import controller from './clientEmailMobileInputCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    applicant: '<',
    isMobileRequired: '<',
    isEmailRequired: '<',
    onContactSaved: '&',
  },
};
