import { contact, task, pipeline, client } from 'Common/default/route';

class StateService {
  constructor($localStorage, $state) {
    'ngInject';

    this.$localStorage = $localStorage;
    this.$state = $state;
    this.routeToContactList = this.routeToContactList.bind(this);
    this.routeToTask = this.routeToTask.bind(this);
  }

  savePrevState(self, parameters = null) {
    this.$localStorage.prevState = {
      self,
      param: parameters,
    };
  }

  getPreviousState() {
    return this.$localStorage.prevState;
  }

  destroyPrevState() {
    this.$localStorage.prevState = null;
  }

  transition(current, params, config) {
    const defaultConfig = {
      reload: false,
      inherit: false,
      notify: false,
      location: true,
    };
    this.$state.go(current, params, config || defaultConfig);
  }

  routeToContactList(params = contact) {
    this.$state.go('app.contacts', params);
  }

  routeToTask(params = task) {
    this.$state.go('app.tasks', params);
  }

  routeToPipeline(pipelineType, params = pipeline) {
    this.$state.go('app.pipeline', { ...params, pipelineType });
  }

  routeToClient(familyId, isClient = true, params = client) {
    const route = isClient ? 'app.contactsSingle' : 'app.BusinessAccount';
    this.$state.go(route, { ...params, familyId });
  }
}

export default StateService;
