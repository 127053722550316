import { get } from 'lodash';

export default class AdviserSelectCtrl {
  constructor(
    utilitiesService,
    generalService,
    corporateService,
    userService,
    currentUserService,
    mycrmLookupService,
  ) {
    'ngInject';

    this.utilitiesService = utilitiesService;
    this.generalService = generalService;
    this.corporateService = corporateService;
    this.userService = userService;
    this.currentUserService = currentUserService;
    this.mycrmLookupService = mycrmLookupService;
  }

  $onInit() {
    this.onAutoCompleteSelect = this.onAutoCompleteSelect.bind(this);
    this.getAdvisersList();
  }

  $onChanges(changes) {
    const { adviserModel } = changes;
    if (adviserModel && adviserModel.currentValue) {
      this.getAdvisersList();
    }
  }

  onCustomSelectChange({ item = {} }) {
    this.adviserModel = item;
    this.onAdviserSelect();
  }

  getAdvisersList() {
    this.showDropDown = !this.currentUserService.isCorporate;
    const params = {
      clientFamilyId: this.getBrokersListOnFamilyId,
      loanId: this.loanId,
    };
    const adviserGet =
      this.currentUserService.isNZ && this.loanId
        ? 'getAdviserListByLoanId'
        : 'getAdvisers';
    this.mycrmLookupService[adviserGet](params).then((data) => {
      this.advisersList = data || [];
      const adviserId = get(this.adviserModel, 'adviserId', 0);
      const found =
        data.find((adviser) => adviser.familyId === adviserId) || {};
      this.adviserModel = { ...this.adviserModel, ...found };
    });
  }

  onAdviserSelect() {
    if (!this.simpleSelection) {
      return;
    }
    this.setAdviserModel();
  }

  onAutoCompleteSelect(data) {
    if (!data) {
      return;
    }
    this.adviserModel = { ...data };

    if (this.simpleSelection) {
      return this.setAdviserModel();
    }
  }

  setAdviserModel() {
    let adviser = {};
    if (!this.adviserModel.originalObject) {
      // For non corp account
      adviser = {
        ...this.adviserModel,
        originalObject: {
          ...this.adviserModel,
        },
      };
    } else {
      // For corp account
      const {
        originalObject: { familyId, fullName },
      } = this.adviserModel;
      adviser = {
        ...this.adviserModel,
        familyId,
        fullName,
      };
    }
    this.onAdviserChange({ $e: { adviser, adviserBak: this.adviserModelBak } });
  }

  getPlaceholder() {
    const adviserName = get(this.adviserModel, 'fullname');
    return adviserName || 'Search Adviser';
  }
}
