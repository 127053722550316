export const JASPER_FILTERS = {
  DATE_RANGE_OPTION: '1',
  RELATIVE_DATE: '2',
  MONTH: '3',
  ADVISER_SINGLE: '4',
  ADVISER_MULTIPLE: '5',
  REPORTING_STATE: '6',
  LENDER: '7',
  BUSINESS_UNIT: '8',
  PIPELINE_STATUS_FULL: '9',
  PIPELINE_STATUS_PARTIAL: '10',
  REFERRER_DETAILS: '11',
  INCLUDED_MARKETING: '12',
  COUNTRY_ID: '13',
  CUSTOM_DATE: '14',
  DATE_FILTER_ON_LOAN: '15',
  LOAN_STATUS: '16',
  LENDER_ID: '17',
  LENDER_CATEGORY: '18',
  LENDER_SPLIT_BY: '19',
  SHOW_LOANS_DATA: '20',
  REFERRAL_TYPE: '21',
  DATE_FILTER_ON_OPPORTUNITY: '22',
  DATA_TO_SHOW: '23',
  REFERRER_ORG_FAMILY: '24',
  RATE_TYPE: '25',
  LOAN_STRUCTURE_TYPE: '26',
  DATE_RANGE_OPTION_V2: '27',
  DATE_FILTER_ON_LOAN_V3: '28',
  ASSISTANT_MULTIPLE: '29',
  PREVIOUS_AGGREGATOR: '30',
  INSURER_PROVIDERS: '31',
  DATE_FILTER_ON_INSURANCE: '32',
  INSURANCE_BENEFIT_STATUS_LIST: '33',
  LOAN_PURPOSE: '34',
};

export const PIPELINE_STATUS_FILTER = {
  NEW_LEADS: 4,
};

export const REPORT_TYPES = {
  COMMISSION_REPORT: 11,
};

export const LOAN_DATE_TYPES = {
  LODGED: 0,
  APPROVED: 1,
  SETTLED: 2,
};

export const DEFAULT_INVALID_VALUE = {
  SINGLE: '~NULL~',
  MULTIPLE: '~NOTHING~',
};
