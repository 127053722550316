import template from './noteTypeSelector.html';
import controller from './noteTypeSelectorCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    value: '<',
    communicationId: '<',
    useAsFilter: '<',
    hideLabel: '<',
    showAddOption: '<',
    selectType: '@',
    formClass: '@',
    textSize: '@',
    size: '@',
    onChange: '&',
  },
};
