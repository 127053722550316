export default class MergeService {
  constructor(
    crmConfirmation,
    mergeContactsService,
    insuranceApplicationService,
  ) {
    'ngInject';

    this.crmConfirmation = crmConfirmation;
    this.mergeContactsService = mergeContactsService;
    this.insuranceApplicationService = insuranceApplicationService;
  }

  showInsuranceValidationWarning({ onConfirmCb }) {
    this.crmConfirmation.open({
      type: 'warning',
      title: 'Oops, an active or existing Online Insurance Application found!',
      description:
        'There’s already an Online Insurance Application for one or both of the selected accounts so we are unable to merge them.',
      buttonText: 'OK, got it',
      buttonClass: 'width-200',
      onConfirm: onConfirmCb,
      modalSize: 'md',
      cancelButtonClass: 'colored',
    });
  }

  validateInsurance({ primaryFamilyId, secondaryFamilyId, onConfirmCb }) {
    return this.insuranceApplicationService
      .validateContactsInsurance(primaryFamilyId, secondaryFamilyId)
      .then((invalid) => {
        if (invalid) {
          this.showInsuranceValidationWarning({ onConfirmCb });
        }
        return !invalid;
      });
  }

  showMergeSuccess({ onConfirmCb, onCloseCb }) {
    this.crmConfirmation
      .open({
        type: 'success',
        title: 'Accounts merged successfully!',
        modalSize: 'md',
        buttonText: 'Open Merged Account',
        onConfirm: onConfirmCb,
        showCustomButton: true,
        customButtonText: 'Close',
        customButtonClass: 'colored',
        isButtonMaxWidth: true,
      })
      .result.then(({ isCustom }) => {
        if (isCustom) {
          onCloseCb();
        }
      });
  }
}
