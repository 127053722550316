import { AWS_S3_ROOT, DOCUMENT_LOGO_KEY } from 'Common/constants/document';
import { toastError, toastSuccess } from 'Common/utilities/alert';
import { getDataURI, getDocumentUploadUrl } from 'Common/utilities/document';

class LogoUploaderCtrl {
  constructor(
    configService,
    generalService,
    customThemeService,
    corporateModelService,
  ) {
    'ngInject';

    this.configService = configService;
    this.generalService = generalService;
    this.customThemeService = customThemeService;
    this.corporateModelService = corporateModelService;
  }

  $onInit() {
    this.crmBrand = { interface: {} };
    const defaultExtensions = ['svg', 'png', 'jpg', 'jpeg'];
    this.extensions = this.extensions || defaultExtensions;
    this.uploadOptions = {
      isMultiple: false,
      itemForUpload: 'logo',
      clickableDiv: true,
      validExtension: this.extensions,
    };
    this.showLogo = false;

    this.toastError = toastError;
    this.getLogoImage();
    this.byobUploadType = AWS_S3_ROOT.BYOB;
  }

  getLogoImage() {
    if (!this.brandingData || !this.brandingData[this.brandingKey]) {
      return;
    }
    this.generalService
      .documentGet(this.brandingData[this.brandingKey])
      .then(({ data }) => {
        if (!data || data.Message) {
          return;
        }
        this.logoSrc = getDataURI(data);
        this.showLogoPreview();
      });
  }

  saveLogo(files) {
    if (!files || !files.length) {
      return;
    }
    const logo = files[0] || {};
    const branding = {
      ...this.brandingData,
      [this.brandingKey]: logo.DocumentId,
    };
    this.corporateModelService
      .setAdviserOrganizationTheme(branding)
      .then(() => {
        this.logoSrc = getDataURI(logo);
        this.showLogoPreview();
        const updateNavLogo =
          this.autoUpdateNav && this.brandingKey === DOCUMENT_LOGO_KEY.DEFAULT;
        updateNavLogo && this.customThemeService.setBrandingLogo(this.logoSrc);
        this.onLogoSaved();
        toastSuccess('Branding logo successfully saved!');
      });
  }

  removeLogo() {
    this.logoSrc = '';
    this.showLogoPreview(false);
  }

  logoDropletReady() {
    this.crmBrand.interface.allowedExtensions(this.extensions);

    const params = [{ key: 'uploadType', value: AWS_S3_ROOT.BYOB }];
    const uploadUrl = getDocumentUploadUrl(params);
    const requestUrl = `${this.configService.resource}/${uploadUrl}`;
    this.crmBrand.interface.setRequestUrl(requestUrl);
    this.crmBrand.interface.setRequestHeaders({
      Authorization: this.configService.token,
    });
    this.crmBrand.interface.defineHTTPSuccess([/2.{2}/]);
    this.crmBrand.interface.useArray(false);
  }

  dropletLogoAdded(file) {
    if (!file) {
      return;
    }
    if (!this.extensions.includes(file.extension)) {
      const errorTitle = `Unable to upload ${file.file.name}`;
      const extensions = this.extensions.map((e) => e.toUpperCase()).join(',');
      const errorMessage = `Please try uploading using ${extensions}`;
      this.toastError(errorMessage, errorTitle);
      return;
    }
    if (!this.crmBrand.interface.isUploading()) {
      this.crmBrand.interface.uploadFiles();
    }
  }

  dropletLogoError() {
    this.toastError('Something went wrong. Please try again.');
  }

  showLogoPreview(flag = true) {
    this.showLogo = flag;
  }
}

export default LogoUploaderCtrl;
