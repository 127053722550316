import { COMMISSION_EMAIL } from 'Common/config/email';
import { toastError, toastSuccess } from 'Common/utilities/alert';

export default class SendTaxInvoiceModalCtrl {
  constructor(loanScenarioModelService) {
    'ngInject';

    this.loanScenarioModelService = loanScenarioModelService;
  }

  $onInit() {
    this.isSending = false;
    this.emailCommission = COMMISSION_EMAIL;
    this.email = {
      emailSubject: 'Tax Invoice',
      emailBody: null,
      loanAppId: this.loanAppId,
      recipientList: null,
    };
  }

  $onChanges(changes) {
    const { lenderEmail } = changes;
    if (lenderEmail && lenderEmail.currentValue) {
      this.recipientEmail = lenderEmail.currentValue;
    }
  }

  sendEmail() {
    if (!this.loanAppId || this.isSending) {
      return;
    }
    const emailParams = {
      ...this.email,
      ...this.invoiceDetails,
      recipientList: [{ EmailAddress: this.recipientEmail }],
      ccList: [{ EmailAddress: COMMISSION_EMAIL }],
    };

    this.isSending = true;
    this.loanScenarioModelService
      .setEmailTaxInvoice(emailParams)
      .then((data) => {
        this.isSending = false;
        if (!data || !data.length) {
          toastError();
          return;
        }
        this.processEmailRemarks(data);
      });
  }

  processEmailRemarks(data) {
    const hasError = data.some((item) => item.emailAddress === 'Error');
    if (hasError) {
      const errorText = data[0].remarks.replace('Error:', '');
      toastError(errorText);
      return;
    }
    toastSuccess('Tax Invoice successfully sent');
    this.modalInstance.close({ sent: true });
  }

  updateEmailProperty(key, data) {
    this.email = { ...this.email, [key]: data };
  }

  isButtonDisabled() {
    return (
      this.isSending ||
      !this.recipientEmail ||
      !this.email.emailSubject ||
      !this.email.emailBody
    );
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }
}
