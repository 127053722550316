import template from './fundingDetailsSection.html';
import controller from './fundingDetailsSectionCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    familyId: '<',
    isLodged: '<',
    isStarted: '<',
    isCompleted: '<',
    isLoanAppLoaded: '<',
    fundingDetailsErrorProps: '<',
    loanAppFormWarning: '<',
    countValids: '&',
  },
};
