import { showCustomConfirmation, toastSuccess } from 'Common/utilities/alert';
import {
  DOCUMENT_FLAG,
  UPLOAD_API_URL,
  CATEGORY_ID,
} from 'Common/constants/importantDocuments';
import { VALID_EXTENSIONS, INVALID_FILE_INFO } from 'Common/constants/document';
import { fieldSorter } from 'Common/utilities/sort';

export default class ImportantDocumentsTableCtrl {
  constructor(
    importantDocumentService,
    corporateModelService,
    NgTableParams,
    $uibModal,
    contactService,
    currentUserService,
  ) {
    'ngInject';

    this.importantDocumentService = importantDocumentService;
    this.corporateModelService = corporateModelService;
    this.NgTableParams = NgTableParams;
    this.$uibModal = $uibModal;
    this.contactService = contactService;
    this.currentUserService = currentUserService;
  }

  setIsFavorite(data) {
    data.isFavourite = !data.isFavourite;
    const params = {
      importantDocumentId: data.id,
      isFavourite: data.isFavourite,
    };
    this.corporateModelService
      .setImportantDocumentFavourite(params)
      .catch(() => {
        data.isFavourite = !data.isFavourite;
      });
  }

  loadImportantDocument() {
    const categorizedList =
      (this.documentList &&
        this.documentList.length &&
        this.documentList.filter(
          (doc) => doc.categoryId === this.categoryId,
        )) ||
      [];
    this.importantDocumentList = this.isSearch
      ? this.documentList
      : categorizedList;
    const data = this.importantDocumentList;
    this.tableParams = new this.NgTableParams(
      {
        count: 10,
        sorting: {
          fileTitle: 'asc',
        },
      },
      {
        counts: [],
        total: 0,
        getData(params) {
          const orderBy = params.orderBy()[0];
          return (
            (data && data.sort(fieldSorter(['-isFavourite', orderBy]))) || []
          );
        },
      },
    );
  }

  openUploadedDocumentModal(size, data, isUploading) {
    const categoryId = this.categoryId || data.categoryId;
    const modalInstance = this.$uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: '/assets/views/loanTools/modals/importantDocumentModal.html',
      controller: 'ImportantDocumentModalCtrl',
      controllerAs: 'modal',
      windowClass: 'upload-mydocuments-modal-class',
      backdrop: 'static',
      size,
      resolve: {
        documentId: () => {
          return data.id || data.DocumentId;
        },
        isUploading: () => {
          return isUploading;
        },
        userInfo: () => {
          return {};
        },
        importantDocs: () => {
          return this.documentList;
        },
        categoryId: () => {
          return categoryId;
        },
      },
    });
    modalInstance.result.then((isSaved) => {
      if (!isSaved) {
        return;
      }
      this.onUpdate();
    });
  }

  deleteDocument(documentId) {
    if (!documentId) {
      return;
    }
    showCustomConfirmation(
      `Are you sure?`,
      `You won't be able to revert this!`,
      `Yes, delete it!`,
      `Cancel`,
      '',
      (confirm) => {
        if (!confirm) {
          return;
        }
        this.corporateModelService
          .importantDocumentDetailsGet(documentId)
          .then((data) => {
            if (!data || !data.documentDetails) {
              return {};
            }
            const details = data.documentDetails;
            details.isActive = 0;
            return this.corporateModelService.importantDocumentSet(details);
          })
          .then(() => {
            this.documentList =
              this.documentList &&
              this.documentList.filter((doc) => doc.id !== documentId);
            this.onDeleteItem({ updatedDocuments: this.documentList });
            this.loadImportantDocument();
            toastSuccess('Successfully deleted document', 'Delete Document');
          });
      },
    );
  }

  uploadViewSuccess(files) {
    if (!files || !files.length) {
      return;
    }
    this.openUploadedDocumentModal('lg', files[0], true);
  }

  displayUploadCondition() {
    const restrictedForCorporate = [
      CATEGORY_ID.CLIENT_DOCUMENTS,
      CATEGORY_ID.BUSINESS_OPERATIONS,
    ];
    return (
      !this.isSearch &&
      !(
        !this.currentUserService.isCorporate &&
        restrictedForCorporate.includes(this.categoryId)
      )
    );
  }

  $onInit() {
    this.isSecured = this.categoryId === CATEGORY_ID.YOUR_LIBRARY;
    this.buttonComponent = `<upload-document-button></upload-document-button>`;
    this.documentFlag = DOCUMENT_FLAG;
    this.extensions = VALID_EXTENSIONS;
    this.invalidFileInfo = INVALID_FILE_INFO;
    this.customUploadApi = UPLOAD_API_URL;
    this.isDisplayUpload = this.displayUploadCondition();
    this.displayActionButton =
      this.currentUserService.isCorporate ||
      (!this.currentUserService.isCorporate &&
        this.categoryId === CATEGORY_ID.YOUR_LIBRARY);
    this.displayBrandingCategories =
      this.currentUserService.isCorporate &&
      (this.isSearch || this.categoryId !== CATEGORY_ID.YOUR_LIBRARY);
    this.loadImportantDocument();
  }
}
