class CompareViewCtrl {
  constructor($window, loanProfilerService) {
    'ngInject';

    this.$window = $window;
    this.loanProfilerService = loanProfilerService;
  }

  $onInit() {
    this.crmCurrency = '$';
    this.loanScenarioId = parseInt(this.loanScenarioId, 10);
    this.generalSettings.loanScenarioId = this.loanScenarioId;
  }

  setFavouriteProductRecommendation(productSetFavourite, loanStructureID) {
    if (!productSetFavourite || !loanStructureID) {
      return;
    }
    productSetFavourite.IsRecommended = !productSetFavourite.IsRecommended;
    const postData = {
      BrokerEventID: this.brokerEventId,
      LoanStructureID: loanStructureID,
      ProductID: productSetFavourite.ProductID,
      IsRecommended: productSetFavourite.IsRecommended,
      IsToolTipShown: productSetFavourite.IsToolTipShown,
    };

    this.loanProfilerService
      .setFavouriteProductRecommendation(postData)
      .then((response) => {
        this.updateFavourite();
        if (parseInt(response.data, 10) !== 1) {
          productSetFavourite.IsRecommended = !productSetFavourite.IsRecommended;
        }
      });
  }

  updateFavourite() {
    this.onFavouriteUpdated();
  }

  setFavouriteProductSequence(newValue, oldValue, loanStructureID) {
    const postData = {
      BrokerEventID: Number(this.brokerEventId),
      LoanStructureID: Number(loanStructureID),
      NewProductID: Number(newValue),
      OldProductID: Number(oldValue),
    };

    this.loanProfilerService
      .setFavouriteProductSequence(postData)
      .then((response) => {
        if (parseInt(response.data, 10) === 1) {
          this.updateFavourite();
        }
      });
  }
}

export default CompareViewCtrl;
