import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('pipelineStatus', function pipelineStatus() {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/pipelineStatus.html',

      link(scope) {
        scope.interval = 'week';
        // eslint-disable-next-line unicorn/consistent-function-scoping
        function init() {}

        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'commissionReceivedChartPopover.html',
          title: 'Title',
        };

        scope.doAction = function (msg) {
          if (msg === 'Customise') {
            scope.customizeMode = true;
          } else {
            scope.customizeMode = false;
          }
        };

        init();
      },
    };
  });
