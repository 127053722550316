import { accreditedLenderBuilderForUI } from 'Common/mappers/lender';

class LenderModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'lender';
  }

  getCompareEmail(lenderId, email) {
    return this.httpClient.get(`${this.apiBaseUrl}/${lenderId}/compare-email`, {
      email,
    });
  }

  getAccreditedLender(brokerId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${brokerId}/accredited`)
      .then(
        ({ data }) => (data && data.map(accreditedLenderBuilderForUI)) || [],
      );
  }
}

export default LenderModelService;
