import template from './fileFactFindWidget.html';
import controller from './fileFactFindWidgetCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    fileGeneralDetails: '<',
    scenarioDetails: '<',
    applicants: '<',
  },
};
