import angular from 'angular';
import { ENTITY_TYPE } from 'Common/constants/entityType';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ONBOARDING_ROLE_ID, SETTINGS } from 'Common/constants/onboarding';

import { stringFormatter } from 'Common/utilities/string';
import { toastError } from 'Common/utilities/alert';

class OnboardingCompletePreviewCtrl {
  constructor(
    corporateModelService,
    onboardingDataService,
    dataService,
    onboardingService,
    crmConfirmation,
    uiService,
    corporateService,
    $location,
    $anchorScroll,
    $document,
  ) {
    'ngInject';

    this.isCountryNewZealand = false;
    this.entityTypeIsCompany = false;
    this.entityTypeIsSoleTrader = false;
    this.entityTypeIsPartnership = false;
    this.isOwnBrand = false;
    this.onboardingDataService = onboardingDataService;
    this.corporateModelService = corporateModelService;
    this.dataService = dataService;
    this.onboardingService = onboardingService;
    this.crmConfirmation = crmConfirmation;
    this.uiService = uiService;
    this.corporateService = corporateService;
    this.$location = $location;
    this.$anchorScroll = $anchorScroll;
    this.$document = $document;
    this.angular = angular;
    this.currentIndex = 0;
    this.isFranchisee = false;
    this.isContractor = false;
    this.isEmployee = false;
    this.isPartner = false;
    this.isDirector = false;
    this.level_access = [];
  }

  displayTabBody(index) {
    if (!index) {
      return true;
    }
    const previousTab =
      (this.onboardingTabs &&
        this.onboardingTabs.length &&
        this.onboardingTabs[index - 1]) ||
      {};
    return previousTab.isLooksGood || false;
  }

  approveDetails(tab, currentIndex) {
    tab.isLooksGood = true;
    const params = {
      sectionId: tab.idx,
      looksGood: tab.isLooksGood,
    };
    this.corporateModelService.setOnlineOnboardingSection(params);
    this.scrollToNextSection(currentIndex);
  }

  scrollToNextSection(index) {
    if (!this.onboardingTabs || !this.onboardingTabs.length) {
      return;
    }
    const lastIndex = this.onboardingTabs.length - 1;
    if (index === lastIndex) {
      return;
    }
    const nextSection = this.onboardingTabs[index + 1] || {};
    if (!nextSection.value) {
      return;
    }
    const documentElement =
      this.$document &&
      this.$document.length &&
      // eslint-disable-next-line unicorn/prefer-query-selector
      this.$document[0].getElementById(nextSection.value);
    if (!documentElement) {
      return;
    }
    this.$document.scrollToElement(
      this.angular.element(documentElement),
      SETTINGS.SCROLL_OFFSET,
      SETTINGS.TIME,
    );
  }

  onConsentTick({ isConsentChecked }) {
    this.enableSubmitApplication = isConsentChecked;
  }

  hideConsentSection() {
    if (!this.onboardingTabs || !this.onboardingTabs.length) {
      return true;
    }
    const tabLastIndex = this.onboardingTabs.length - 1;
    const isLastIndexLooksGood =
      (this.onboardingTabs[tabLastIndex] &&
        this.onboardingTabs[tabLastIndex].isLooksGood) ||
      false;
    return !isLastIndexLooksGood;
  }

  $onInit() {
    this.enableSubmitApplication = false;
    this.stringFormatter = stringFormatter;
    this.familyId = this.onboardingDataService.getFamilyId();
    this.isCountryNewZealand = this.onboardingDataService.isUserCurrentCountry(
      COUNTRY_TYPE.NEW_ZEALAND,
    );
    this.entityTypeIsCompany =
      this.onboardingDataService.isUserCurrentEntityType(ENTITY_TYPE.COMPANY) ||
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.COMPANY_TRUST,
      );
    this.entityTypeIsSoleTrader =
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.SOLE_TRADER,
      ) ||
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.SOLE_TRADER_TRUST,
      );
    this.entityTypeIsPartnership =
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.PARTNERSHIP,
      ) ||
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.PARTNERSHIP_TRUST,
      );
    this.level_access = this.dataService.getLevelAccessList();
    this.onboardingDataService
      .getOnboardingResponse(
        this.onboardingDataService.getFamilyId(),
        this.onboardingDataService.getClientId(),
      )
      .then((response) => {
        if (response && response.RoleId) {
          this.isFranchisee = this.onboardingDataService.isOnboardingUserRole(
            response.RoleId,
            ONBOARDING_ROLE_ID.FRANCHISEE,
          );
        }
      });
  }

  setQuestionniare(index) {
    this.currentIndex = index;
  }

  modalCallback(response) {
    const validTabs = this.onboardingTabs && this.onboardingTabs.length;
    const requiredIndex =
      (validTabs &&
        this.onboardingTabs.findIndex(
          (tab) => tab.value === 'requiredDocuments',
        )) ||
      0;
    this.corporateService.currentOnboardingTabSet(this.familyId, requiredIndex);
    if (!response) {
      return;
    }
    if (response.isOk) {
      this.setTab({ index: requiredIndex });
    } else if (response.isCustom) {
      this.uiService.logoutUser();
    }
  }

  setOnlineOnboardingToActive() {
    this.corporateModelService
      .setOnlineOnboardingActive(this.familyId)
      .catch(() => {
        toastError('Erron on saving snapshot.');
      });
  }

  sendOnboardingApplication() {
    this.isSendingApplication = true;
    this.onboardingService
      .sendProcessCompletedEmailSet(this.familyId)
      .then(({ data }) => {
        this.isSendingApplication = false;
        if (!data) {
          return;
        }
        const successInstance = this.crmConfirmation.open({
          type: 'success',
          title: 'Nice Work!',
          description: `
              <p>We'll review your details and be in touch soon.</p>
            `,
          buttonText: 'OK!',
          modalSize: 'md',
          isButtonMaxWidth: true,
        });
        successInstance.result.then(() => {
          const modalInstance = this.crmConfirmation.open({
            type: 'info',
            title: 'Got all your documents?',
            description: `
                <p>We won't be able to complete your onboarding until all documents have been uploaded and compliance reviewed. You can login at anytime to upload or amend your documents.</p>
              `,
            buttonText: 'Add More Documents',
            modalSize: 'md',
            showCloseButton: true,
            showCustomButton: true,
            customButtonText: 'Logout',
            customButtonClass: 'colored',
            confirmationIcon: `<plus-icon></plus-icon>`,
            isButtonMaxWidth: true,
          });
          modalInstance.result.then((response) => {
            this.modalCallback(response);
          });
        });
        this.onboardingDataService.isOnboardingSubmitted = true;
        this.setOnlineOnboardingToActive();
      });
  }
}

export default OnboardingCompletePreviewCtrl;
