export function filterPersonBy(prop, data) {
  if (!prop) {
    return [];
  }
  return Object.keys(data)
    .map((key) => {
      return data[key].filter((people) => people[prop]);
    })
    .filter((array) => array.length);
}

export function filterClientList(involvedPerson) {
  if (!involvedPerson) {
    return [];
  }
  const applicants = filterPersonBy('IsApplicant', involvedPerson);
  const guarantors = filterPersonBy('IsGuarantor', involvedPerson);

  return [...applicants, ...guarantors].reduce(
    (accum, person) => accum.concat(person),
    [],
  );
}

export function getOptionListByQuestion(id, options) {
  if (!id || !options || !options.length) {
    return [];
  }
  const optionList = options.find((option) => option.questionId === id);
  return (optionList && optionList.options) || [];
}

export function stringifyMultipleAnswers(answers) {
  if (!answers) {
    return '';
  }
  return answers
    .map((item) => {
      return item.value;
    })
    .join(',');
}

export function getFormattedAnswer(questionId, answerList) {
  if (!questionId || !answerList || !answerList.length) {
    return null;
  }
  const clientAnswer = answerList.find(
    (item) => item.questionId === questionId,
  );
  return (clientAnswer && clientAnswer.answer) || null;
}

export function isBinaryYes(data) {
  return !!parseInt(data, 10);
}

export function hasOtherSelected(answer) {
  if (!answer) {
    return false;
  }
  return answer.split(',').includes('Other');
}

export function getDataPerClient(dataList, clientId) {
  const isValid = dataList && dataList.length && clientId;
  return isValid
    ? dataList.filter((item) => `${item.clientId}` === `${clientId}`)
    : [];
}

export function isCompleteField(question, vm) {
  const LIST_OF_QUESTIONS = vm.LIST_OF_QUESTIONS;
  if (!question) {
    return false;
  }
  let isComplete = false;
  const { QuestionId: questionId, answer } = question;
  switch (questionId) {
    case LIST_OF_QUESTIONS.RETIREMENT_AGE:
      isComplete = !!answer;
      break;

    case LIST_OF_QUESTIONS.APPLICANT_RETIRING:
      isComplete =
        (answer && !isBinaryYes(answer)) ||
        (answer && vm.proposedRepaymentAnswers.length);
      break;

    case LIST_OF_QUESTIONS.REACH_RETIREMENT:
      isComplete =
        vm.currentUserService.isNZ ||
        isBinaryYes(
          vm.questionsDictionary[`${LIST_OF_QUESTIONS.APPLICANT_RETIRING}`]
            .answer,
        ) ||
        (answer && !isBinaryYes(answer)) ||
        (isBinaryYes(answer) &&
          vm.questionsDictionary[
            `${LIST_OF_QUESTIONS.APPLICANT_RETIRING_PROPOSED_PAYMENT}`
          ].answer);
      break;

    case LIST_OF_QUESTIONS.ANTICIPATE_CHANGES:
      isComplete =
        (answer && !isBinaryYes(answer)) ||
        (answer && vm.answersPossiblePlanOrAnticipate.length);
      break;

    default:
  }
  return isComplete;
}

export function isCompleteSection(fields) {
  if (!fields || !fields.length) {
    return false;
  }
  let isComplete = true;
  fields.forEach((item) => {
    item.adverseQuestions &&
      item.adverseQuestions.forEach((question) => {
        if (!question.isCompleteField) {
          isComplete = false;
        }
      });
  });
  return isComplete;
}
