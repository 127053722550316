import { get } from 'lodash';
import { pluralize } from 'Common/utilities/string.js';
import { tickAllOptions } from 'Common/utilities/multiselect';

const VALUE_ALL = 'ALL';
/* eslint-disable no-return-assign */
export function filterCapped(filters) {
  return filters.filter(
    (filter) => filter.name.toLowerCase().trim() !== 'capped',
  );
}

export function lvrDropDownValue(defaultSelection) {
  const isDefaultSelection =
    defaultSelection &&
    defaultSelection.loanAmount &&
    defaultSelection.security;

  if (isDefaultSelection) {
    let lvrOptionvalue =
      (defaultSelection.loanAmount / defaultSelection.security) * 100;

    if (lvrOptionvalue > 100 || parseFloat(lvrOptionvalue, 2) === 0) {
      lvrOptionvalue = 0;
    }

    return lvrOptionvalue;
  }
}

export function getLoanAmount(loanStructure) {
  return loanStructure.LoanAmount === null || loanStructure.LoanAmount === ''
    ? 0
    : loanStructure.LoanAmount;
}

export function getTrimmedString(productFinderItem) {
  const length = 12;
  return productFinderItem.Name.length > length
    ? `${productFinderItem.Name.slice(0, Math.max(0, length - 3))}...`
    : productFinderItem.Name;
}
export function getUpdateLvr(dropDownLvrValue) {
  if (dropDownLvrValue !== 0) {
    return `+${parseFloat(dropDownLvrValue, 2).toFixed(2)}`;
  }
}

export function getProductFinderCol(configService = {}) {
  const columns = [
    { field: 'LenderName', title: 'Lender', show: true },
    { field: 'ProductName', title: 'Product', show: true },
    { field: 'RateType', title: 'Rate Type', show: false },
    { field: 'InitialRate', title: 'Initial Rate', show: false },
    { field: 'OngoingRate', title: 'Ongoing Rate', show: false },
    { field: 'ComparisonRate', title: 'Comparison Rate', show: false },
    { field: 'MaxLVR', title: 'Max LVR', show: false },
    { field: 'MaxLoanAmount', title: 'Max Loan Amount', show: false },
    { field: 'UpFrontFee', title: 'UpFront Fees', show: false },
    {
      field: 'OngoingMonthlyFee',
      title: 'Ongoing Monthly Fees',
      show: false,
    },
    { field: 'AnnualFees', title: 'Annual Fees', show: false },
    {
      field: 'InitialRepaymentAmount',
      title: 'Repayment',
      show: false,
    },
  ];

  if (get(configService, 'feature.productFinderLastUpdated')) {
    columns.push({ field: 'LastUpdated', title: 'Last Updated', show: false });
  }
  return columns;
}

export function getLoanAmountOverride({ $scope, fundingSummary }) {
  if (!$scope || !fundingSummary) {
    return 0;
  }
  const reqAndObjProperty = 'totalProposedLending';
  const loanAmountProperty = 'totalProposedLendingUserDefined';
  const loanAmountToUse = $scope.isReqAndObjShow
    ? reqAndObjProperty
    : loanAmountProperty;
  return (parseFloat(fundingSummary[loanAmountToUse]) || 0).toFixed(2);
}

export function getTermOfInterestList() {
  let list = [];
  const maxNumberOfList = 31;

  for (let i = 1; i < maxNumberOfList; i++) {
    list = [...list, { name: pluralize(i, 'year'), value: i }];
  }

  return list;
}

export const structureMultipleFilter = ({ data, loanStructure }) => {
  const LIST_LABEL_TRIMMED = { RateType: [], Intention: [], ProductType: [] };
  Object.keys(LIST_LABEL_TRIMMED).forEach((prop) => {
    LIST_LABEL_TRIMMED[prop] = data[prop].map((item) => ({
      ...item,
      shortName: getTrimmedString(item),
    }));
  });
  loanStructure.multiParamPFList = LIST_LABEL_TRIMMED;
};

export const preselectMultipleFilter = ({ loanStructure }) => {
  if (!loanStructure.multiParamPFList || !loanStructure) {
    return;
  }
  Object.keys(loanStructure.multiParamPFList).forEach((prop) => {
    if (!loanStructure.getFilterDataList[prop]) {
      return;
    }
    const tickedValues = loanStructure.getFilterDataList[prop].split(',');
    loanStructure.multiParamPFList[prop] = loanStructure.multiParamPFList[
      prop
    ].map((item) => ({
      ...item,
      ticked: tickedValues.includes(item.Value),
    }));
  });
};

export const getCSVparam = ({ prop, loanStructure }) => {
  return (
    loanStructure.multiParamPF[prop].map((item) => item.Value).join(',') ||
    VALUE_ALL
  );
};

export const updateFilterToCSV = ({ loanStructure }) => {
  loanStructure.getFilterDataList.RateType = getCSVparam({
    loanStructure,
    prop: 'RateType',
  });
  loanStructure.getFilterDataList.Intention = getCSVparam({
    loanStructure,
    prop: 'Intention',
  });
  loanStructure.getFilterDataList.ProductType = getCSVparam({
    loanStructure,
    prop: 'ProductType',
  });
};

export const enableProductSearchDisplay = (loanStructure) => {
  loanStructure.isProductSearchOverlayDisplay = true;
};

export const selectFilterAll = (prop, loanStructure) => {
  loanStructure.multiParamPFList[prop] = tickAllOptions(
    loanStructure.multiParamPFList[prop],
    false,
  );
  loanStructure.multiParamPFList[prop][0].ticked = true;
  enableProductSearchDisplay(loanStructure);
};

export const untickAllSelector = ({ $timeout }) => (prop, loanStructure) => {
  if (!loanStructure.multiParamPFList[prop].length) {
    return;
  }
  const eventTimeout = $timeout(() => {
    loanStructure.multiParamPFList[prop][0].ticked = false;
    $timeout.cancel(eventTimeout);
  });
  enableProductSearchDisplay(loanStructure);
};

export const selectNone = (prop, loanStructure) => {
  loanStructure.multiParamPF[prop].length &&
    selectFilterAll(prop, loanStructure);
  enableProductSearchDisplay(loanStructure);
};

export const selectFilterItem = (itemClicked, prop, loanStructure) => {
  if (itemClicked.Value === VALUE_ALL) {
    selectFilterAll(prop, loanStructure);
    return;
  }
  loanStructure.multiParamPFList[prop][0].ticked = false;
  enableProductSearchDisplay(loanStructure);
};

export const getSpecificAllOption = ({ $scope }) => (prop) => {
  return $scope[prop].find((item) => item.value === VALUE_ALL);
};

export const updateLenderSet = ({ $scope }) => {
  $scope.loanProviderLendersListSet = [];
  $scope.loanProviderLendersListGetAll.forEach((item) => {
    item.ticked && $scope.loanProviderLendersListSet.push(item);
  });
};

export const updateProviderLenderList = ({ $scope }) => {
  const allOption = getSpecificAllOption({ $scope })(
    'loanProviderLendersListGetAll',
  );
  allOption.ticked = false;
  updateLenderSet({ $scope });
  $scope.setDefaultFilterSelection();
};

export const untickAllValueSelection = ({ $scope, $timeout }) => () => {
  if (!$scope.loanProviderLendersListGetAll.length) {
    return;
  }
  const eventTimeout = $timeout(() => {
    updateProviderLenderList({ $scope });
    $timeout.cancel(eventTimeout);
  });
};

export const defaultAllSelect = ({ $scope }) => () => {
  if (!$scope.loanProviderLendersListSet.length) {
    return;
  }

  $scope.loanProviderLendersListGetAll = tickAllOptions(
    $scope.loanProviderLendersListGetAll,
    false,
  );
  const allOption = getSpecificAllOption({ $scope })(
    'loanProviderLendersListGetAll',
  );
  allOption.ticked = true;
  updateLenderSet({ $scope });
  $scope.setDefaultFilterSelection();
};
