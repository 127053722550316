class AddressFieldCtrl {
  constructor($timeout, generalService) {
    'ngInject';

    this.$timeout = $timeout;
    this.generalService = generalService;
  }

  searchAddress(searchString) {
    this.$timeout.cancel(this.searchingTimeOut);
    this.searchingTimeOut = this.$timeout(() => {
      this.generalService.placeSearch(searchString).then((response) => {
        this.addressDetails = response.data;
      });
    }, 300);
  }

  selectAddress(fullAddress) {
    this.addressModel = { ...fullAddress };
    this.onAddressSelection({ address: this.addressModel });
    this.closeSearch();
  }

  closeSearch() {
    this.addressDetails = [];
  }

  $onDestroy() {
    this.$timeout.cancel(this.searchingTimeOut);
  }
}

export default AddressFieldCtrl;
