class RelatedEntitiesCtrl {
  constructor() {
    'ngInject';

  }

  $onInit() {
    this.showRelatedEntities = false;
    this.showAddEntityForm = false;
  }

  toggleShowFlagged() {
    this.showRelatedEntities = !this.showRelatedEntities;
  }

  toggleShowForm() {
    this.showAddEntityForm = !this.showAddEntityForm;
  }
}

export default RelatedEntitiesCtrl;
