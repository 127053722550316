import template from './mycrmMiniToggle.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    value: '=',
    disabled: '<',
    onChange: '&',
  },
};
