import moment from 'moment';
import { get, cloneDeep } from 'lodash';
import { TO_MYCRM_DATE_FORMAT } from 'Common/constants/dateConfigs';

export const getCommissionUpdateData = (commission, type) => {
  const ExpectedCommissionId = commission.CommissionId;
  const LoanID = commission.LoanId;
  const LoanStructureID = commission.LoanStructureId;
  const DateExpected = commission.expectedDate
    ? moment(commission.expectedDate).format('YYYY-MM-DDThh:mm:ss')
    : null;
  const TypeId = type ? type.Value : null;
  const ExpectedCommission = commission.expectedAmount;
  const IsPaid = commission.isConfirm;
  const DatePaid = commission.confirmDate
    ? moment(commission.confirmDate).format('YYYY-MM-DDThh:mm:ss')
    : null;
  const AmountPaid = commission.isConfirm ? commission.expectedAmount : null;

  return {
    ExpectedCommissionId,
    LoanID,
    LoanStructureID,
    DateExpected,
    TypeId,
    ExpectedCommission,
    IsPaid,
    DatePaid,
    AmountPaid,
  };
};

export const listDataFromObject = (object) => {
  const {
    ExpectationID: CommissionId,
    LoanID: LoanId,
    LoanStructureID: LoanStructureId,
    AdviserName: BrokerName,
    ClientName,
    TypeName,
    ExpectedCommission: expectedAmount,
    ProviderName: Provider,
    ProviderID: ProviderId,
    FamilyID,
    LoanAmount,
    TotalCount,
    DateSettled: dateSettled,
  } = object;

  return {
    CommissionId,
    LoanId,
    LoanStructureId,
    BrokerName,
    ClientName,
    TypeName,
    expectedAmount,
    Provider,
    ProviderId,
    LoanAmount,
    FamilyID,
    TotalCount,
    dateSettled,
  };
};

export const copyLenderList = (lenders) => {
  if (!lenders || !lenders.length) {
    return [];
  }
  const lendersCopy = cloneDeep(lenders);
  const [allProvider] = lendersCopy;
  allProvider.ticked = true;
  return lendersCopy;
};

export const copyAdviserList = (advisers, defaultSetting) => {
  if (!advisers || !advisers.length || !defaultSetting) {
    return [];
  }
  const { adviserIds } = defaultSetting;
  return cloneDeep(advisers).map((adviser) => {
    const { BrokerID } = adviser;
    adviser.ticked =
      (adviserIds && BrokerID === adviserIds) || (!adviserIds && !BrokerID);
    return adviser;
  });
};

export const copyPayeeList = (advisers) => {
  if (!advisers || !advisers.length) {
    return [];
  }
  return cloneDeep(advisers).map((payee) => {
    const { BrokerID } = payee;
    !BrokerID && (payee.ticked = true);
    return payee;
  });
};

export const copyCommissionTypeList = (types) => {
  if (!types || !types.length) {
    return [];
  }
  const typeList = cloneDeep(types);
  const [allType] = typeList;
  allType.ticked = true;
  return typeList;
};

export const getFormattedCommissionFilterDate = (filterSettings, property) => {
  return (
    (filterSettings &&
      property &&
      get(filterSettings, property) &&
      moment(get(filterSettings, property)).format(TO_MYCRM_DATE_FORMAT)) ||
    ''
  );
};
