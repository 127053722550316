import template from './opportunityServiceabilityItem.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    onOpen: '&',
    onDelete: '&',
  },
};
