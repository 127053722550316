/* eslint-disable unicorn/consistent-function-scoping */
import angular from 'angular';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { isEntityPartOfType } from 'Common/utilities/onboarding';
import { ONBOARDING_ROLE_ID, TABS_IDX } from 'Common/constants/onboarding';
import { isLMBranded } from 'Common/utilities/brandingCategory';

export default angular
  .module('app.services.onboardingDataService', [])
  .factory('onboardingDataService', function onboardingDataService(
    corporateModelService,
    onboardingService,
    corporateService,
    contactService,
    dataService,
    $q,
    generalService,
  ) {
    /*
     *   =====================================
     *         FUNCTION DEFINITION(S)
     *   =====================================
     */
    let onboardingCompletedData = {
      entityDetails: {},
      asicAppointment: {},
      principalDetails: {},
      individualDetails: {},
      questionniare: {},
      additionalStaff: {},
      requiredDocuments: {},
    };
    let familyIdVal = 0;
    let clientIdVal = 0;
    let userCurrentEntityType = '';
    let userCurrentBrandingCategory = 0;
    let userCurrentCountryId = 0;
    let isRoleTypeVal = 0;
    let familyIds = [];
    const unsavedChanges = {
      additionalParties: false,
      additionalStaff: false,
    };
    const adviserOrgData = {};
    /*
     * SETTER FUNCTION: revolves in parent controller to
     * prevent calling multiple APIS on each controller.
     */
    const setFamilyId = (familyId) => {
      familyIdVal = familyId;
    };

    const setClientId = (clientId) => {
      clientIdVal = clientId;
    };

    const setUserCurrentEntityType = (entityType) => {
      userCurrentEntityType = entityType;
    };

    const setBrandingCategory = (brandingCategoryId) => {
      userCurrentBrandingCategory = brandingCategoryId;
    };

    const setUserCurrentCountry = (countryId) => {
      userCurrentCountryId = countryId;
    };

    const setFamilyIds = (familyIdArr) => {
      familyIds = [...familyIdArr];
    };

    const setUnsavedChanges = (tab, flag = true) => {
      unsavedChanges[tab] = flag;
    };

    const setAdviserOrgId = (adviserOrgId) => {
      adviserOrgData.adviserOrgId = adviserOrgId || 0;
    };
    /*
     * GETTER FUNCTION: revolves in parent controller to
     * prevent calling multiple APIS on each controller.
     */

    const getFamilyIds = () => {
      return familyIds;
    };

    const getAllOnboardingDataForPreview = () => {
      return onboardingCompletedData;
    };

    const setAllOnboardingDataForPreview = (onboardingCompleted) => {
      onboardingCompletedData = Object.assign(
        onboardingCompletedData,
        onboardingCompleted,
      );
      getAllOnboardingDataForPreview();
    };

    const setIsRoleType = (roleId) => {
      isRoleTypeVal = roleId;
    };

    /*
     * GETTER FUNCTION: revolves in parent controller to
     * prevent calling multiple APIS on each controller.
     */
    const getClientId = () => {
      return clientIdVal;
    };

    const getFamilyId = () => {
      return familyIdVal;
    };

    const getCountryId = () => {
      return userCurrentCountryId;
    };

    const getUserCurrentEntityType = () => {
      return userCurrentEntityType;
    };

    const getAdviserOrgId = () => {
      return adviserOrgData.adviserOrgId || 0;
    };
    /*
     * CHECKER FUNCTION
     */
    const isRoleType = (roleId) => {
      if (roleId === isRoleTypeVal) {
        return true;
      }
      return false;
    };

    const isUserCurrentEntityType = (entityType) => {
      if (entityType === userCurrentEntityType) {
        return true;
      }
      return false;
    };

    const isOnboardingUserRole = (userRoles, roleType) => {
      const roles = userRoles.split(',').map((item) => {
        return parseInt(item.trim(), 10);
      });
      return roles.includes(roleType);
    };

    const isUserCurrentCountry = (countryId) => {
      if (userCurrentCountryId === countryId) {
        return true;
      }
      return false;
    };

    const isCountryNewZealand = (countryId) => {
      if (countryId === COUNTRY_TYPE.NEW_ZEALAND) {
        return true;
      }
      return false;
    };

    const isOwnBrand = (ownBrand) => {
      if (userCurrentBrandingCategory === ownBrand) {
        return true;
      }
      return false;
    };

    const suggestPreferredEmail = (fname, lname) => {
      const fnameSplit = fname ? fname.split(' ') : '';
      const lnameSplit = lname ? lname.split(' ') : '';
      let suggestion = '';
      if (fname && !lname) {
        suggestion = `${fname.toLowerCase()}`;
      } else if (!fname && lname) {
        suggestion = `${lname.toLowerCase()}`;
      } else {
        suggestion = `${fnameSplit[0].toLowerCase()}.${lnameSplit[0].toLowerCase()}`;
      }
      return suggestion;
    };

    const hasUnsavedChanges = (tab) => {
      return unsavedChanges[tab];
    };
    /*
     * API CALLS in multiple controller
     */
    const getAdviserOrgDetails = () => {
      const defer = $q.defer();
      onboardingService
        .getOnboardingAdviserOrganisationDetails(getFamilyId(), getClientId())
        .then(
          (response) => {
            if (response && response.data) {
              setUserCurrentEntityType(response.data.EntityType);
              return defer.resolve(response.data);
            }
          },
          (error) => {
            return defer.reject(error);
          },
        );
      return defer.promise;
    };

    const getOnboardingResponse = (familyId, clientId) => {
      const defer = $q.defer();
      if (familyId) {
        onboardingService.getRecruitmentContact(familyId, clientId).then(
          (response) => {
            if (response && response.data) {
              return defer.resolve(response.data[0]);
            }
          },
          (error) => {
            return defer.reject(error);
          },
        );
      }
      return defer.promise;
    };

    const getFamilyBrandingCategory = () => {
      const defer = $q.defer();
      onboardingService
        .getFamilyBrandingCategory(getFamilyId(), getClientId())
        .then(
          (response) => {
            if (response && response.data) {
              setBrandingCategory(response.data.BrandingCategoryId);
              return defer.resolve(response.data);
            }
          },
          (error) => {
            return defer.reject(error);
          },
        );

      return defer.promise;
    };

    const getOnboardingTeamList = (countryId) => {
      let teamData = [];
      const IMAGE_BASE_URL = 'assets/images/team-member/';
      if (countryId === COUNTRY_TYPE.AUSTRALIA) {
        teamData = [
          {
            picture: `${IMAGE_BASE_URL}lm@2x.png`,
            title: 'Onboarding Team',
            subtitle: 'NSW, ACT, VIC, TAS, QLD',
            email: 'onboarding@loanmarket.com.au',
            phone: '(07) 3231 2167',
            is_image: true,
          },
          {
            picture: `${IMAGE_BASE_URL}holly@2x.png`,
            title: 'Holly Hammond',
            subtitle: 'SA, NT',
            email: 'holly.hammond@loanmarket.com.au',
            phone: '0404 089 127',
            is_image: true,
          },
          {
            picture: `${IMAGE_BASE_URL}shelley@2x.png`,
            title: 'Shelley Tetlaw',
            subtitle: 'WA',
            email: 'shelley.tetlaw@loanmarket.com.au',
            phone: '0478 474 701',
            is_image: true,
          },
        ];
      } else {
        teamData = [
          {
            picture: `${IMAGE_BASE_URL}logo.svg`,
            name: 'NZ Financial Services Group',
            address:
              '22 Kings Crescent, Lower Hutt PO Box 31 519, Lower Hutt 5040',
            email: 'adviserservices@nzfsg.co.nz',
            phone: '(0508) 87 87 88',
            is_image: true,
          },
        ];
      }

      return teamData;
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const getTabsRequiredField = (mainElem, activeTab) => {
      const percentages = {};

      // angular.element(`${mainElem}`).each((index, elem) => {
      if (!percentages[activeTab]) {
        percentages[activeTab] = {};
      }
      const tabsElem = angular.element(mainElem);
      percentages[activeTab] = {
        total: 0,
        filled: 0,
        valid: 0,
      };
      angular
        .element(tabsElem[0])
        .find('input, textarea')
        .each((reqIdx, reqElem) => {
          // Set the number of fields
          percentages[activeTab].total++;

          // Validate required fields
          // eslint-disable-next-line sonarjs/no-duplicate-string
          if (angular.element(reqElem).is('[required]')) {
            // Validate here
            if (!angular.element(reqElem).val()) {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              angular.element(reqElem).addClass('is-required');
            } else {
              angular.element(reqElem).removeClass('is-required');
            }

            percentages[activeTab].filled += !!angular.element(reqElem).val();
          } else {
            // Auto increment
            percentages[activeTab].filled++;
          }

          // Validate invalid fields
          if (!angular.element(reqElem).hasClass('invalid')) {
            percentages[activeTab].valid++;
          }

          angular.element(reqElem).on('keyup', () => {
            if (
              angular.element(reqElem).is('[required]') &&
              angular.element(reqElem).val()
            ) {
              angular.element(reqElem).removeClass('is-required');
            }
          });
        });
      return percentages;
    };

    const removeFieldRequiredClass = (mainElem) => {
      angular.element(`${mainElem}`).each((index, elem) => {
        const mainElemValue = angular.element(elem);
        angular
          .element(mainElemValue)
          .find('[required]')
          .each((reqIdx, reqElem) => {
            angular.element(reqElem).on('change', () => {
              if (!angular.element(reqElem).val()) {
                angular.element(reqElem).removeClass('is-required');
              }
            });
          });
      });
    };

    const getCurrentTab = (familyId) => {
      const defer = $q.defer();
      corporateService.currentOnboardingTabGet(familyId).then(
        (response) => {
          let tabValue = 0;
          if (response && response.data) {
            tabValue = response.data;
          }
          return defer.resolve(tabValue);
        },
        (error) => {
          return defer.reject(error);
        },
      );

      return defer.promise;
    };

    const getRelationships = () => {
      return [
        {
          id: ONBOARDING_ROLE_ID.EMPLOYEE,
          name: 'I am an Employee of the Agreement Holder',
        },
        {
          id: ONBOARDING_ROLE_ID.CONTRACTOR,
          name: 'I am a Contractor to the Agreement Holder',
        },
      ];
    };

    const getAsicType = () => {
      return [
        {
          name: 'ACL',
          checked: false,
        },
        {
          name: 'CR',
          checked: false,
        },
      ];
    };

    const getEntityType = () => {
      const defer = $q.defer();
      contactService.getEntityType().then(
        (response) => {
          if (response && response.data) {
            return defer.resolve(response.data);
          }
        },
        (error) => {
          return defer.reject(error);
        },
      );

      return defer.promise;
    };

    const getOnboardingHelpfulLinks = () => {
      return [
        {
          title: 'External Dispute Resolution Scheme',
          subtitle: 'Australian Financial Complaints Authority (AFCA)',
          link: 'https://www.afca.org.au/members/apply-for-membership/',
          linkName: 'www.afca.org.au/members/apply-for-membership/',
        },
        {
          title: 'Professional Associations',
          links: [
            {
              linkTitle: 'Finance Brokers Association of Australia (FBAA)',
              link: 'https://www.fbaa.com.au',
              linkName: 'www.fbaa.com.au',
            },
            {
              linkTitle: 'Mortgage & Finance Association of Australia (MFAA)',
              link: 'https://www.mfaa.com.au',
              linkName: 'www.mfaa.com.au',
            },
          ],
        },
        {
          title: 'Company Credit Checks',
          link:
            'https://www.equifax.com.au/businesscreditexpress/reports/swiftcheck-company-director-ppsr-credit-report',
          linkName:
            'https://www.equifax.com.au/businesscreditexpress/reports/swiftcheck-company-director-ppsr-credit-report',
        },
        {
          title: 'ASIC - Check your Credit Rep or ACL Number',
          link:
            'https://connectonline.asic.gov.au/RegistrySearch/faces/landing/ProfessionalRegisters.jspx?_adf.ctrl-state=pwx6qccnc_20',
          linkName:
            'https://connectonline.asic.gov.au/RegistrySearch/faces/landing/ProfessionalRegisters.jspx?_adf.ctrl-state=pwx6qccnc_20',
        },
      ];
    };

    const getOnboardingDownloads = () => {
      const byobLink =
        'https://drive.google.com/drive/folders/1radEEnlErdVGZa6Gr9Wd49Pj0Ufumv0g?usp=sharing';
      const lmLink =
        'https://drive.google.com/drive/folders/1bVNz9sR4UHP4XDtJQN6ETdy5SZJ0m0bU';
      const link = isLMBranded(userCurrentBrandingCategory) ? lmLink : byobLink;
      return [{ link, linkName: 'Google Drive folder' }];
    };

    const getOnbardingTabsIcons = () => {
      return {
        navEntity: '<icon-info-square></icon-info-square>',
        navAsic: '<icon-asic></icon-asic>',
        navIndividual: '<owned-icon></owned-icon>',
        navParties: '<icon-person-mf></icon-person-mf>',
        navStaff: '<icon-add-filled-circle></icon-add-filled-circle>',
        navBranding: '<icon-branding></icon-branding>',
        navDocs: '<icon-folder></icon-folder>',
        navQuestionnaire: '<icon-questionnaire></icon-questionnaire>',
        tabEntity: '<entity-icon></entity-icon>',
        tabAsic: '<asic-icon></asic-icon>',
        tabIndividual: '<icon-relationships></icon-relationship>',
        tabParties: '<icon-state-team></icon-state-team>',
        tabStaff: '<additional-staff-icon></additional-staff-icon>',
        tabBranding: '<icon-branding-circle></icon-branding-circle>',
        tabDocs: '<related-doc-icon></related-doc-icon>',
        tabQuestionnaire: '<questionniare-icon></questionniare-icon>',
      };
    };

    const getIsLooksGoodValue = (sectionList, id) => {
      if (!sectionList) {
        return false;
      }

      const section = sectionList.find((sec) => sec.sectionId === id) || {};
      return !!section.isLooksGood;
    };

    const getOnbardingTabs = (icons) => {
      const TMPL_BASE_URL = 'assets/views/onboarding/tabs/';
      const defer = $q.defer();
      const onboardingModel = {
        section: [],
      };
      corporateModelService
        .getOnlineOnboardingSections()
        .then((data) => {
          onboardingModel.section = data;
          return dataService.getImportantDocuments('Onboarding Manual');
        })
        .then(
          (response) => {
            const isPartner = isEntityPartOfType(
              'Partnership',
              userCurrentEntityType,
            );
            const defaultParties = 'Directors and Shareholders';
            const additionalParties = isPartner ? 'Partners' : defaultParties;

            const tabDetails = [
              {
                idx: TABS_IDX.ENTITY,
                name: 'Entity Details',
                component:
                  '<onboarding-entity-details></onboarding-entity-details>',
                value: 'entityDetails',
                disable: true,
                icon: icons.tabEntity,
                svgfill: icons.navEntity,
                preview: `<entity-details-preview></entity-details-preview>`,
                isLooksGood: getIsLooksGoodValue(
                  onboardingModel.section,
                  TABS_IDX.ENTITY,
                ),
                instruction:
                  '<p class="text-instruction">To help us prepare your agreement, please provide your legal entity details below.',
              },
              {
                idx: TABS_IDX.ASIC,
                name: 'ASIC Appointment',
                component: '<onboarding-asic></onboarding-asic>',
                value: 'asicAppointment',
                disable: true,
                icon: icons.tabAsic,
                svgfill: icons.navAsic,
                preview: `<asic-appointment-preview></asic-appointment-preview>`,
                isLooksGood: getIsLooksGoodValue(
                  onboardingModel.section,
                  TABS_IDX.ASIC,
                ),
                instruction: `<p class="text-instruction">If you have previously held ASIC accreditation through another aggregator or a lender; or you hold your own ACL, please select YES and provide your details.</p>`,
              },
              {
                idx: TABS_IDX.INDIVIDUAL,
                name: 'Individual Details',
                component: `<onboarding-individual-details 
                    active-tab="vm.activeTab">
                  </onboarding-individual-details>`,
                value: 'individualDetails',
                disable: true,
                icon: icons.tabIndividual,
                svgfill: icons.navIndividual,
                preview: `<individual-details-preview></individual-details-preview>`,
                isLooksGood: getIsLooksGoodValue(
                  onboardingModel.section,
                  TABS_IDX.INDIVIDUAL,
                ),
                instruction: 'Please complete your details below.',
              },
              {
                idx: TABS_IDX.PARTIES,
                name: 'Additional Parties',
                component:
                  '<onboarding-additional-parties></onboarding-additional-parties>',
                value: 'additionalParties',
                disable: true,
                icon: icons.tabParties,
                svgfill: icons.navParties,
                preview: `<onboarding-additional-parties read-only="true"></onboarding-additional-parties>`,
                isLooksGood: getIsLooksGoodValue(
                  onboardingModel.section,
                  TABS_IDX.PARTIES,
                ),
                instruction: `Please add ${additionalParties} if applicable`,
              },
              {
                idx: TABS_IDX.QUESTIONNAIRE,
                name: 'Questionnaire',
                component:
                  '<onboarding-questionnaire questionnaire-field="vm.questionnaireField"></onboarding-questionnaire>',
                value: 'questionniare',
                disable: true,
                icon: icons.tabQuestionnaire,
                svgfill: icons.navQuestionnaire,
                preview: `<questionnaire-preview></questionnaire-preview>`,
                isLooksGood: getIsLooksGoodValue(
                  onboardingModel.section,
                  TABS_IDX.QUESTIONNAIRE,
                ),
                instruction:
                  'Please answer the following questions carefully.</p>',
              },
              {
                idx: TABS_IDX.STAFF,
                name: 'Additional Staff',
                component:
                  '<onboarding-additional-staff></onboarding-additional-staff>',
                value: 'additionalStaff',
                disable: true,
                icon: icons.tabStaff,
                svgfill: icons.navStaff,
                preview: `<onboarding-additional-staff read-only="true"></onboarding-additional-staff>`,
                isLooksGood: getIsLooksGoodValue(
                  onboardingModel.section,
                  TABS_IDX.STAFF,
                ),
                instruction: `<p class="text-instruction">
                Please provide support staff details. Additional fees apply depending on the access level required. <br/>
                For current fees, please take a look at the <a href="${response.documentKey}" target="_blank" class="crm-link">Loan Market Operations Manual</a> </p>`,
              },
              {
                idx: TABS_IDX.BRANDING,
                name: 'Branding',
                component: '<onboarding-branding></onboarding-branding>',
                value: 'onboardingBranding',
                disable: true,
                icon: icons.tabBranding,
                svgfill: icons.navBranding,
                preview: `<onboarding-branding is-summary="true"></onboarding-branding>`,
                isLooksGood: getIsLooksGoodValue(
                  onboardingModel.section,
                  TABS_IDX.BRANDING,
                ),
                instruction:
                  '<p>Please upload your brand logo and select your primary brand colour.</p>',
              },
              {
                idx: TABS_IDX.RELATED_DOCS,
                name: 'Required Documents',
                page: `${TMPL_BASE_URL}requiredDocuments.html`,
                value: 'requiredDocuments',
                disable: true,
                icon: icons.tabDocs,
                svgfill: icons.navDocs,
                preview: `<required-documents-preview></required-documents-preview>`,
                isLooksGood: getIsLooksGoodValue(
                  onboardingModel.section,
                  TABS_IDX.RELATED_DOCS,
                ),
                instruction:
                  '<p class="text-instruction">Please upload at least one document for each category</p>',
              },
            ];
            return defer.resolve(tabDetails);
          },
          (error) => {
            return defer.reject(error);
          },
        );

      return defer.promise;
    };

    const checkEmail = (email, callerFamilyId, contactTypeId = '') => {
      const defer = $q.defer();
      generalService
        .getCheckIfEmailExists(email, callerFamilyId, 0, contactTypeId)
        .then(
          (response) => {
            if (response && response.data) {
              return defer.resolve(response.data);
            }
            return defer.resolve(response.data);
          },
          (error) => {
            return defer.reject(error);
          },
        );

      return defer.promise;
    };

    const getAddressForBusinessCards = () => {
      return [
        'Office Address',
        'Postal Address',
        'Home Address',
        'No Address',
        'I do not require business cards',
      ];
    };

    const checkFormAndInputIsValid = (formName, inputName) => {
      if (formName[inputName].$invalid) {
        angular.element(`[name="${inputName}"]`).addClass('invalid');
        return false;
      }
      angular.element(`[name="${inputName}"]`).removeClass('invalid');
      return true;
    };

    const onboardingPercentage = 0;

    const isOnboardingSubmitted = false;

    /*
     *   =====================================
     *           FUNCTION REFERENCE
     *   =====================================
     */
    return {
      getAdviserOrgDetails,
      getOnboardingHelpfulLinks,
      getOnboardingDownloads,
      getOnboardingTeamList,
      getOnboardingResponse,
      isOnboardingUserRole,
      getOnbardingTabsIcons,
      getOnbardingTabs,
      getTabsRequiredField,
      removeFieldRequiredClass,
      getAddressForBusinessCards,
      getCurrentTab,
      getRelationships,
      getAsicType,
      isCountryNewZealand,
      isUserCurrentEntityType,
      isUserCurrentCountry,
      isOwnBrand,
      getClientId,
      getFamilyId,
      getCountryId,
      getEntityType,
      isRoleType,
      getFamilyBrandingCategory,
      getUserCurrentEntityType,
      setUserCurrentCountry,
      setUserCurrentEntityType,
      setBrandingCategory,
      setFamilyId,
      setClientId,
      setIsRoleType,
      suggestPreferredEmail,
      checkEmail,
      setAllOnboardingDataForPreview,
      getAllOnboardingDataForPreview,
      checkFormAndInputIsValid,
      setFamilyIds,
      getFamilyIds,
      onboardingPercentage,
      setUnsavedChanges,
      hasUnsavedChanges,
      setAdviserOrgId,
      getAdviserOrgId,
      isOnboardingSubmitted,
    };
  }).name;
