import { WIDGET_ID } from 'Common/constants/dashboardWidget';

export const WIDGET_COMPONENT = {
  1: '<fixed-rate-reviews my-param="testData"></fixed-rate-reviews>',
  3: '<upcoming-birthdays-small my-param="testData"></upcoming-birthdays-small>',
  5: '<commissions-received my-param="testData"></commissions-received>',
  8: '<outstanding-commissions-small my-param="testData"></outstanding-commissions-small>',
  10: '<client-reviews-small my-param="testData"></client-reviews-small>',
  12: '<tasks my-param="testData"></tasks>',
  6: '<commission-received-table my-param="testData"></commission-received-table>',
  11: '<client-reviews my-param="testData"></client-reviews>',
  9: '<outstanding-commission my-param="testData"></outstanding-commission>',
  7: '<commission-received-chart my-param="testData"></commission-received-chart>',
  13: '<tasks-table my-param="testData"></tasks-table>',
  2: '<fixed-rate-reviews-table my-param="testData"></fixed-rate-reviews-table>',
  4: '<upcoming-birthdays my-param="testData"></upcoming-birthdays>',
  14: '<upcoming-events-table my-param="testData"></upcoming-events-table>',
  15: '<system-updates my-param="testData"></system-updates>',
  17: '<interest-rate-updates-table my-param="testData"></interest-rate-updates-table>',
  22: '<target-tracking my-param="testData"></target-tracking>',
  29: '<nps-score-small my-param="testData"></nps-score-small>',
  27: '<lender-split my-param="testData"></lender-split>',
  26: '<adviser-leaderboard my-param="testData"></adviser-leaderboard>',
  28: '<referral-source my-param="testData"></referral-source>',
  24: '<settlement my-param="testData"></settlement>',
  31: '<customer-comment my-param="testData"></customer-comment>',
  30: '<customer-comment-table my-param="testData"></customer-comment-table>',
  32: '<pipeline-lodged my-param="testData"></pipeline-lodged>',
  34: '<pipeline-approved my-param="testData"></pipeline-approved>',
  42: '<pipeline-settled my-param="testData"></pipeline-settled>',
  36: '<pipeline-status-small my-param="testData"></pipeline-status-small>',
  41: '<pipeline-new-leads my-param="testData"></pipeline-new-leads>',
  38: '<pipeline-stats-chart my-param="testData"></pipeline-stats-chart>',
  39: '<pipeline-stats-table my-param="testData"></pipeline-stats-table>',
  47: '<pre-approval-expiries my-param="testData"></pre-approval-expiries>',
  44: '<pre-approval-expiries-small my-param="testData"></pre-approval-expiries-small>',
  50: '<interest-rates-table-directive my-param="testData"></interest-rates-table-directive>',
  [WIDGET_ID.INSURANCE_PIPELINE_STATUS]:
    '<insurance-pipeline-status my-param="testData"></insurance-pipeline-status>',
  [WIDGET_ID.LENDER_SPLIT_SMALL]:
    '<lender-split-small my-param="testData" ng-if="showStatisticalDashboard || vm.showStatisticalDashboard"></lender-split-small>',
  [WIDGET_ID.AVG_LOAN_SIZE]:
    '<average-loan-size my-param="testData" ng-if="showStatisticalDashboard || vm.showStatisticalDashboard"></average-loan-size>',
  [WIDGET_ID.SETTLEMENT_VOLUMES]:
    '<settlement-volumes my-param="testData" ng-if="showStatisticalDashboard || vm.showStatisticalDashboard"></settlement-volumes>',
  [WIDGET_ID.ENQUIRY_SOURCE]:
    '<enquiry-source-tile my-param="testData" ng-if="showStatisticalDashboard || vm.showStatisticalDashboard"></enquiry-source-tile>',
  [WIDGET_ID.REFERRALS_SENT]:
    '<referrals-tile my-param="testData" ng-if="showStatisticalDashboard || vm.showStatisticalDashboard"></referrals-tile>',
  [WIDGET_ID.REFERRALS_RECEIVED]:
    '<referrals-tile my-param="testData" ng-if="showStatisticalDashboard || vm.showStatisticalDashboard"></referrals-tile>',
  [WIDGET_ID.UPCOMING_FINANCE_DATES]: `<upcoming-settlement-dates my-param="testData" type="'finance'" ng-if="showStatisticalDashboard || vm.showStatisticalDashboard"></upcoming-settlement-dates>`,
  [WIDGET_ID.UPCOMING_SETTLEMENT_DATES]: `<upcoming-settlement-dates my-param="testData" type="'settlement'" ng-if="showStatisticalDashboard || vm.showStatisticalDashboard"></upcoming-settlement-dates>`,
  [WIDGET_ID.INSURANCE_NOTIFICATIONS]: `<insurance-notifications my-param="testData" ng-if="showStatisticalDashboard || vm.showStatisticalDashboard"></insurance-notifications>`,
  [WIDGET_ID.ESIGN_USAGE_REPORT]: `<esign-usage-report my-param="testData" ng-if="isEsignEnabled || vm.isEsignEnabled"></esign-usage-report>`,
};

export const BUSINESS_PLANNING_WIDGETS = {
  [WIDGET_ID.LODGED]: `<target-based-tile widget-info="recommendedWidget"
    on-change-widget-list="vm.updateStatsView()" adviser-filter="vm.selectedUserValues"></target-based-tile>`,
  [WIDGET_ID.SETTLED]: `<target-based-tile widget-info="recommendedWidget"
    on-change-widget-list="vm.updateStatsView()" adviser-filter="vm.selectedUserValues"></target-based-tile>`,
};

export const YOUR_TARGETS_SETTINGS = {
  [WIDGET_ID.LODGED]: `<target-settings-tile widget-info="recommendedWidget"></target-settings-tile>`,
  [WIDGET_ID.SETTLED]: `<target-settings-tile widget-info="recommendedWidget"></target-settings-tile>`,
};
