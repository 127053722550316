import { CLIENT_ROLE_TYPE } from 'Common/constants/roleType';
import { toastInfo } from 'Common/utilities/alert';

export default class TasksPageCtrl {
  constructor(
    $uibModal,
    $stateParams,
    configService,
    contactService,
    contactSharedDataService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$stateParams = $stateParams;
    this.configService = configService;
    this.contactService = contactService;
    this.contactSharedDataService = contactSharedDataService;

    this.childComponentNotificationRegistration = this.childComponentNotificationRegistration.bind(
      this,
    );
  }

  $onInit() {
    this.showRedesignedTasksPage = this.configService.feature.tasksRedesign;
    if (!this.showRedesignedTasksPage) {
      return;
    }

    this.familyId = Number(this.$stateParams.familyId) || 0;
    if (this.familyId) {
      this.getClientDetails();
    }
    this.childComponentEventHandler = { taskEventHandler: null };
  }

  getClientDetails() {
    this.contactService.clientInformGet(this.familyId).then(({ data }) => {
      this.formatClientHeaderName(data);
    });
  }

  formatClientHeaderName(clientDetails = []) {
    const adults = clientDetails.filter(
      (client) => client.Role === CLIENT_ROLE_TYPE.ADULT,
    );
    this.clientHeaderName = adults
      .reduce((acc, cur, idx) => {
        const name =
          idx < adults.length - 1
            ? cur.FirstName
            : `${cur.FirstName} ${cur.LastName}`;
        return [...acc, name];
      }, [])
      .join(' & ');
  }

  onGeneratePdf() {
    toastInfo('Generate PDF function - upcoming!');
  }

  cancelFamilyFilter() {
    this.familyId = 0;
    this.childComponentEventHandler.taskEventHandler &&
      this.childComponentEventHandler.taskEventHandler('cancelFamilyFilter');
  }

  redirectToContactPage(familyId) {
    if (!familyId) {
      return;
    }
    this.contactSharedDataService.redirectToContactDetails(familyId, 'summary');
  }

  onTaskModalResult(modalInstance) {
    modalInstance.result.then((isSaved) => {
      if (isSaved) {
        this.childComponentEventHandler.taskEventHandler &&
          this.childComponentEventHandler.taskEventHandler('reloadTasksList');
      }
    });
  }

  onOpenTaskModal({ data }) {
    const { taskId, hasFilter, familyId } = data;
    const props = {
      taskId,
      familyId: familyId || this.familyId,
      hasFilter,
      isFromTaskList: true,
    };

    const modalInstance = this.$uibModal.open({
      template: `<task-form-modal modal-instance="vm.modalInstance" is-from-task-list="vm.props.isFromTaskList" has-filter="vm.props.hasFilter" family-id="vm.props.familyId" task-id="vm.props.taskId"></task-form-modal>`,
      windowClass: 'edit-task-window',
      size: 'md',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props: () => props,
      },
    });
    this.onTaskModalResult(modalInstance);
  }

  onOpenTemplatedTaskModal({ data }) {
    const { taskId = 0 } = data;
    const props = { taskId, familyId: this.familyId, isFromTaskList: true };

    const modalInstance = this.$uibModal.open({
      template: `<task-templated modal-instance="vm.modalInstance" family-id="vm.props.familyId" task-id="vm.props.taskId" is-from-task-list="isFromTaskList">`,
      size: 'lg',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      windowClass: 'view-task-window',
      resolve: {
        props: () => props,
      },
    });
    this.onTaskModalResult(modalInstance);
  }

  childComponentNotificationRegistration(handler, handlerType) {
    this.childComponentEventHandler[handlerType] = handler;
  }
}
