import angular from 'angular';
import { resizeByStyle } from './util/dynamicWidth';

export const loadModule = () =>
  angular
    .module('app')
    .directive('dynamicInputWidth', function dynamicInputWidth($window) {
      return {
        link(scope, elem, attrs) {
          const belowMaxViewWidth = () => {
            const currentViewWidth = $window.innerWidth;
            const maxViewWidth = parseInt(attrs.maxViewWidth, 10);
            return currentViewWidth < maxViewWidth;
          };
          const responsiveCharLimit =
            typeof attrs.responsive !== 'undefined' && attrs.maxViewWidth;
          const resize = (value) =>
            responsiveCharLimit
              ? resizeByStyle(elem, {
                  ...attrs,
                  maxCharLimit: belowMaxViewWidth()
                    ? parseInt(attrs.maxCharLimit, 10) / 2
                    : attrs.maxCharLimit,
                  belowMaxViewWidth: belowMaxViewWidth(),
                })(value)
              : resizeByStyle(elem, attrs)(value);
          if (responsiveCharLimit) {
            $window.addEventListener('resize', () => {
              resize(elem[0].value);
            });
          }
          scope.$watch(
            () => {
              return elem[0].value;
            },
            (nv) => {
              resize(nv.length);
            },
            true,
          );
        },
      };
    });
