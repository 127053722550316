import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';
import {
  yearlySettlementBuilderForUI,
  enquirySourceBuilderForUI,
  referralsBuilderForUI,
  upcomingSettlementDatesBuilderForUI,
  upcomingFinanceDatesBuilderForUI,
  widgetDateOptionsBuilderForUI,
  selectedWidgetOptionBuilderForUI,
  recommendedWidgetBuilderForUI,
  recommendedWidgetsOptionsBuilderForUI,
  targetBasedLoansBuilderForUI,
  insurancePipelineBuilderForUI,
  eSignOverviewBuilderForUI,
  insuranceNotificationsBuilderForUI,
} from 'Common/mappers/dashboardWidget';
import { isValidArray } from 'Common/utilities/array';
import { cacheObjects } from 'Assets/js/cache-objects';

class DashboardService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'dashboard';
  }

  getCategory() {
    return this.httpClient.get(`${this.apiBaseUrl}/GetCategory`);
  }

  getDashboardItem() {
    return this.httpClient.get(`${this.apiBaseUrl}/GetDashboardItem`);
  }

  getAllItemBycategory(Category) {
    return this.httpClient.get(`${this.apiBaseUrl}/GetItemAll`, { Category });
  }

  saveDashboardItem(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/SaveDashboardItem`, data);
  }

  deleteDashboardItem(ActualId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/DeleteDashboardItem`, {
      ActualId,
    });
  }

  getTaggedcases() {
    // TODO: move to generalService
    return this.httpClient.get('Taggedcases');
  }

  getRecentlyViewed() {
    // TODO: move to generalService
    return this.httpClient.get('RecentlyViewed', {}, true);
  }

  getBrokerBasicInfo() {
    // TODO: move to generalService
    // eslint-disable-next-line angular/window-service
    const getBrokerBasicInfoSession = cacheObjects?.getBrokerBasicInfo;
    if (getBrokerBasicInfoSession) {
      return Promise.resolve(getBrokerBasicInfoSession);
    } else {
      return this.httpClient
        .get('GetBrokerBasicInfo', {}, true, false)
        .then((data) => {
          cacheObjects.getBrokerBasicInfo = data;

          return data;
        });
    }
  }

  getGenralSearchResult(searchCriteria) {
    // TODO: move to generalService
    return this.httpClient.get(`GeneralSearch`, { searchCriteria });
  }

  addressDetailsGet(addressId) {
    return this.httpClient.get(`AddressDetailsGet`, { addressId });
  }

  addressDetailsSet(data) {
    // TODO: move to generalService
    return this.httpClient.post('AddressDetailsSet', data);
  }

  OFXConverter(fromCode, toCode, amount = 0) {
    return this.httpClient.get(`OFXConverter`, { fromCode, toCode, amount });
  }

  GetAdvisersList(pFamilyId = 0, pSearchSameAdviserOrg) {
    // TODO: move to generalService
    return this.httpClient.get(`GetAdvisersList`, {
      pFamilyId,
      pSearchSameAdviserOrg,
    });
  }

  generalLoanSearchGet(
    clientName,
    adviserName,
    lenderName,
    loanReferenceNo,
    loanId,
  ) {
    // TODO: move to generalService
    return this.httpClient.get(`GeneralLoanSearchGet`, {
      clientName,
      adviserName,
      lenderName,
      loanReferenceNo,
      loanId,
    });
  }

  getWidgetOptions(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/lender-splits/widget-options`, params)
      .then(({ data }) => data && data.map(collectionCamelizeBuilderForUI));
  }

  getLenderSplits(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/lender-splits`, params)
      .then(({ data }) => data && data.map(collectionCamelizeBuilderForUI));
  }

  getAverageLoanSize(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/average-loan-size`, params)
      .then(
        ({ data }) => (data && data.map(collectionCamelizeBuilderForUI)) || [],
      );
  }

  getSettlementVolume(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/settlement-volume`, params)
      .then(
        ({ data }) => (data && data.map(yearlySettlementBuilderForUI)) || [],
      );
  }

  getEnquirySource(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/enquiry-source`, params)
      .then(({ data }) => (data && data.map(enquirySourceBuilderForUI)) || []);
  }

  getReferralsSent(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/referral-sent`, params)
      .then(({ data }) => (data && data.map(referralsBuilderForUI)) || []);
  }

  getReferralsReceived(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/referral-received`, params)
      .then(({ data }) => (data && data.map(referralsBuilderForUI)) || []);
  }

  getWidgetDateOptions(id = 31) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/widget-options-values/${id}`)
      .then(
        ({ data }) => (data && data.map(widgetDateOptionsBuilderForUI)) || [],
      );
  }

  getUpcomingFinanceDates(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/upcoming-finance-dates`, params)
      .then(
        ({ data }) =>
          (data && data.length && data.map(upcomingFinanceDatesBuilderForUI)) ||
          [],
      );
  }

  getUpcomingSettlementDates(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/upcoming-settlement-dates`, params)
      .then(
        ({ data }) =>
          (data &&
            data.length &&
            data.map(upcomingSettlementDatesBuilderForUI)) ||
          [],
      );
  }

  getInsuranceNotifications() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/insurance-notifications`)
      .then(
        ({ data }) =>
          (data &&
            data.length &&
            data.map(insuranceNotificationsBuilderForUI)) ||
          [],
      );
  }

  getWidgetOption(userWidgetId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/WidgetOptionGet`, { userWidgetId })
      .then(
        ({ data }) =>
          (data && data.map(selectedWidgetOptionBuilderForUI)) || [],
      );
  }

  setWidgetOption(param) {
    return this.httpClient.post(`${this.apiBaseUrl}/WidgetOptionSet`, param);
  }

  getRecommendedWidgets() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/recommended-widgets`, {}, true)
      .then(
        ({ data }) => (data && data.map(recommendedWidgetBuilderForUI)) || [],
      );
  }

  getRecommendedWidgetsOptions() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/recommended-widgets-options`, {}, true)
      .then(
        ({ data }) =>
          (data && data.map(recommendedWidgetsOptionsBuilderForUI)) || [],
      );
  }

  setRecommendedWidgetsOptions(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/recommended-widgets-options`,
      params,
    );
  }

  putRecommendedWidgetsOptions(params) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/recommended-widgets-options`,
      params,
    );
  }

  setRecommendedWidgetsAuto(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/recommended-widgets-auto`,
      params,
    );
  }

  getLodgedLoans() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/lodged-loans`)
      .then(
        ({ data }) =>
          (isValidArray(data) && data.map(targetBasedLoansBuilderForUI)) || [],
      );
  }

  getSettledLoans() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/settled-loans`)
      .then(
        ({ data }) =>
          (isValidArray(data) && data.map(targetBasedLoansBuilderForUI)) || [],
      );
  }

  setRecommendedFilter(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/recommended-widgets-filter`,
      params,
    );
  }

  getInsurancePipelineStatus() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/insurance-pipeline`)
      .then(
        ({ data }) => (data && data.map(insurancePipelineBuilderForUI)) || [],
      );
  }

  getEsignOverview(param = { duration: 30 }) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/esign/overview`, param)
      .then(({ data }) => (data && eSignOverviewBuilderForUI(data)) || {});
  }

  storeRecentlyViewedContact(id, data = null) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/recently-viewed/contact/${id}`,
      data || {},
      {},
      false,
    );
  }

  storeRecentlyViewedLoan(id) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/recently-viewed/loan/${id}`,
      {},
      {},
      false,
    );
  }
}

export default DashboardService;
