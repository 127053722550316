import template from './smsEditor.html';
import controller from './smsEditorCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isRecipient: '<',
    isSend: '<',
    isSending: '<',
    btnSendText: '<',
    smsObj: '<',
    onSmsChange: '&',
    onSend: '&',
    recipientsChoices: '<',
    isSmsObjects: '<',
    showSaveAsDefault: '<?',
    onSaveAsDefault: '&?',
    showMarkAsComplete: '<?',
    isComplete: '<?',
    onIsCompleteChange: '&?',
    allowCustom: '<',
    autoSelectAll: '<',
    contactPrimaryKey: '@',
    enableTextArea: '<?',
  },
};
