import { ACCESS_TYPE } from 'Common/constants/accessTypes';

class SmsService {
  constructor(currentUserService, corporateService) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.corporateService = corporateService;
  }

  async isSmsEnabled(adviserFamilyId) {
    const { accessType, sMSEnabled } = this.currentUserService;
    const assistantTypeList = [
      ACCESS_TYPE.ADMIN_ASSISTANT,
      ACCESS_TYPE.ASSISTANT,
    ];
    if (assistantTypeList.includes(accessType)) {
      const { data } = await this.corporateService.familyBrokerSupportGet(
        adviserFamilyId,
      );
      return !!(data && data.SMSEnabled);
    }

    return sMSEnabled;
  }
}

export default SmsService;
