import { COLOR } from 'Common/constants/colors.js';

export const crmOptionSettings = {
  type: 'warning',
  title: 'Are you sure?',
  description: `Deleting this application could mean the client's online fact find won't display correctly.`,
  buttonText: `Yes, delete`,
  showCloseButton: true,
  showCancelButton: true,
  modalSize: 'md',
  cancelButtonClass: 'colored',
  isButtonMaxWidth: true,
};
export const swalOptionSettings = {
  title: 'Are you sure?',
  text: 'This record will be removed from your pipeline list',
  type: 'warning',
  showCancelButton: true,
  confirmButtonColor: COLOR.SWEET_PINK,
  confirmButtonText: 'Yes, remove it!',
  closeOnConfirm: false,
};
