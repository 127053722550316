import { getTermNameFromLoanTermList } from 'Common/utilities/loanFundingDetails';
export default class ProductFinderLoanFacility {
  constructor(uiService, currentUserService, $timeout) {
    'ngInject';

    this.uiService = uiService;
    this.currentUserService = currentUserService;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.divObject = { disabled: false };
    this.disabledTimeout = null;
    this.getTermNameFromLoanTermList = getTermNameFromLoanTermList({
      loanTermList: this.loanTermList,
    });
  }

  productClicked(facility, product) {
    this.divObject.disabled = true;
    this.onSetFacilityFavoriteProduct({ faci: facility, prod: product });
    this.disabledTimeout = this.$timeout(() => {
      this.divObject.disabled = false;
      this.$timeout.cancel(this.disabledTimeout);
    }, 5000);
  }

  $onDestroy() {
    this.$timeout.cancel(this.disabledTimeout);
  }
}
