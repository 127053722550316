import { ADDRESS_VALIDITY } from 'Common/constants/addressModules';
import { toastSuccess } from 'Common/utilities/alert';
import dotProp from 'dot-prop-immutable';

export const generateMutatingChangesForMessage = ($scope) => (address) => {
  const totalAddrHistoryInMonths = $scope.countAddressHistoryYears(address);
  const messageBasedOnMonths = totalAddrHistoryInMonths
    ? `Currently ${totalAddrHistoryInMonths} months only have been supplied`
    : 'Currently no history has been supplied';
  const message = `Please note - most lenders require a 3 year history. ${messageBasedOnMonths}`;
  $scope.invalidClientAddressHistoryMessage = {
    ...$scope.invalidClientAddressHistoryMessage,
    [address.FamilyId]: message,
  };
  $scope.showInvalidClientAddressHistory =
    totalAddrHistoryInMonths < ADDRESS_VALIDITY.MONTHS;
};

export const validateClientAddressHistory = ($scope) => () => {
  const addressList = dotProp.get($scope, 'AddressHistory.AllList', []);
  addressList.forEach(generateMutatingChangesForMessage($scope));
};

export const launchAddressLookup = ({
  $scope,
  addressHistoryService,
  configService,
}) => ({ familyId, loanAppId, propertySet }) => {
  const { addressOverhaul } = configService.feature;
  if (!addressOverhaul) {
    return false;
  }
  addressHistoryService
    .launchAddressModal({ familyId, loanAppId, detailsObj: propertySet })
    .then(() => {
      toastSuccess('Address successfully saved', 'Address Saved');
      $scope.getAddressHistory();
      $scope.UPDATE_ADDRESS();
      $scope.familyIdAsResponse = familyId;
    });
  return true;
};

export const updateAddressValidation = ({
  $scope,
  loanAppSharedData,
}) => () => {
  const { AddressHistory: addressHistory = {} } = $scope;
  const { AllList: allList = [] } = addressHistory;
  const lengthByFamily = allList
    .filter((a) => a.IsClient)
    .reduce((accum, familyAddressHistory) => {
      const { FamilyId: familyId, Address: addresses } = familyAddressHistory;
      return { ...accum, [familyId]: (addresses || []).length };
    }, {});
  loanAppSharedData.UPDATE_ADDRESS = { status: false, lengthByFamily };
  $scope.isInvalidClientAddressHistory();
};
