import get from 'lodash/get';
import set from 'lodash/set';
import { setMappedContactEmailByType } from 'Common/utilities/contact';
import { isValidEmailAddress } from 'Common/utilities/string';
import { EMAIL_CTYPES } from 'Common/constants/emailTypes';

export default class HelloPackEmailFieldCtrl {
  constructor($timeout, helloPackService, timeoutService) {
    'ngInject';

    this.$timeout = $timeout;
    this.helloPackService = helloPackService;
    this.timeoutService = timeoutService;
  }

  $onInit() {
    this.emailFieldName = `email-${this.key}`;
    this.currentValue = this.value;
    this.$timeout(() => {
      this.form = get(
        this,
        `helloPackService.form.involvedPartyForm[${this.key}][${this.emailFieldName}]`,
        {},
      );
      this.onFieldBlur(this.value);
    }, 100);
  }

  onFieldBlur(email) {
    const isValid = isValidEmailAddress(email);
    this.form.$setValidity &&
      this.form.$setValidity(this.emailFieldName, isValid);
    if (!isValid) {
      set(
        this,
        `helloPackService.formError[${this.emailFieldName}]`,
        'Please add a valid email address',
      );
      this.helloPackService.onSetInvolvedPartiesValidity();
      return;
    }
    this.helloPackService.onSetInvolvedPartiesValidity();
    this.isEmailChanged = this.currentValue !== this.value;
  }

  onDismissPopover() {
    this.isEmailChanged = false;
    this.currentValue = this.value;
    this.onFieldBlur(this.currentValue);
  }

  onSaveClientEmail() {
    const client = setMappedContactEmailByType({
      contact: this.client,
      email: this.currentValue,
      emailType: EMAIL_CTYPES.CRM_EMAIL,
    });
    this.isEmailChanged = false;
    this.onUpdate && this.onUpdate({ client });
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('HelloPackEmailFieldCtrl');
  }
}
