import angular from 'angular';
import _ from 'lodash';
import { toastError } from 'Common/utilities/alert';
import { changeSorting } from 'Common/utilities/sort';
import { DEFAULT_INCOME_TYPES } from 'Common/constants/incomeTypes';
import { PRIMARY_PURPOSE_TYPE } from 'Common/constants/primaryPurpose';
import { LOAN_TERM } from 'Common/constants/assets';
import { parseToInt10 } from 'Common/utilities/parse';
import { LIABILITY_TYPE } from 'Common/constants/liabilityType';
import { SOUTH_BUILDING_SOCIETY } from 'Common/constants/commissionCapping';
import {
  setLoanAppOnOpportunity,
  mapDataFromOpportunity,
  deleteIncome,
  getHeadingTooltip,
  getColumnCount,
  getIncomeType,
  setupLenderRedirect,
  setIncomeTypeModel,
  isIncomeRentalSubType,
  mapRentalSubTypeNameAsIncomeNameForIncomeList,
  getCalculation,
  getLiabilityOwner,
  getLiabilityForStudentLoan,
  getLiabilityForNzServiceabilityDates,
  initShowLiabilityInputs,
  showNzServiceabilityWarnings,
  updateDefaultForDeal,
  getLoanPurposeInServiceability,
  getPrimaryPurposeTypeOfPurpose,
} from './util/serviceabilityCtrl';
import { setUpNZServiceability } from 'Common/utilities/financialLiabilities';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('ServiceabilityCtrl', function serviceabilityCtrl(
      $scope,
      $localStorage,
      loanProfilerService,
      NgTableParams,
      $window,
      $state,
      $stateParams,
      toaster,
      cfpLoadingBar,
      $timeout,
      $rootScope,
      $filter,
      uiService,
      commonFnService,
      loanProfilerDetailService,
      serviceabilityService,
      bouncingTooltipService,
      currentUserService,
      opportunityCalculationService,
      loanOpportunityService,
      configService,
      loanCalculatorLinking,
      loanScenarioService,
      loanProfilerSharedData,
      providerInformationService,
      loanStructureService,
      optionsService,
    ) {
      $scope.brokerEventId = $stateParams.brokerEventId;
      $scope.loanScenarioId = parseToInt10($stateParams.loanScenarioId);
      $scope.familyId = parseToInt10($state.params.familyId);
      $scope.loanId = $state.params.loanId;
      $scope.banksWithSpreadForNZ = ['1', '13', '14', '16'];
      $scope.isDownloadingSpreadsheetForNZ = false;
      $scope.filtersParamtersList = {};
      $scope.settings = {};
      $scope.lenderIdSBS = SOUTH_BUILDING_SOCIETY.LENDER_ID;
      const {
        serviceabilityAdditionalFields,
        studentLoanDropdown,
        lendersByAccreditation,
      } = configService.feature;
      const accreditedOnly = lendersByAccreditation;
      const { isAU, isNZ } = currentUserService;
      $scope.isAU = isAU;
      $scope.isNZ = isNZ;
      $scope.showAdditionalFields = isAU && serviceabilityAdditionalFields;
      $scope.liabilityType = LIABILITY_TYPE;
      const COURT_RULED_CHILD_MAINTENANCE = 5;
      const SALARY_WAGE_ID = '19';
      $scope.showAdditionFieldAndNzServiceablity =
        isNZ || $scope.showAdditionalFields;
      $scope.columnCount = getColumnCount(
        $scope.showAdditionFieldAndNzServiceablity,
      );

      setupLenderRedirect({
        origin: 'Serviceability',
        $scope,
        $state,
        currentUserService,
        loanProfilerSharedData,
        providerInformationService,
      });

      initShowLiabilityInputs({
        $scope,
        isNZ,
      });

      showNzServiceabilityWarnings({
        $scope,
      });

      isNZ &&
        setUpNZServiceability({
          $scope,
          loanStructureService,
          optionsService,
        });

      getLoanPurposeInServiceability({ optionsService, $scope });

      $scope.changeSorting = changeSorting;
      $scope.getHeadingTooltip = getHeadingTooltip;
      const mapDataFromOpportunityCaller = () => {
        const opportunityCalculationData = opportunityCalculationService.getServiceabilityMappedData();
        mapDataFromOpportunity({
          $scope,
          $state,
          uiService,
        })({
          opportunityCalculationData,
          loanOpportunityService,
        });
      };
      setLoanAppOnOpportunity({
        $scope,
        $state,
        uiService,
      });
      // Init Function
      $scope.init = function () {
        if ($state.params.familyId) {
          toaster.pop(
            'info',
            '',
            'The current serviceability calculation only supports single family, the multiple families will be coming soon.',
          );
        }

        // Serviceability Matched Lender
        // ----------------------------------------------------------------------------
        $scope.loanAmount = 0;
        $scope.lendersSummaryList = {};
        $scope.MultiSelctLenderList = [];

        $scope.getFilterData = function () {
          loanProfilerService
            .getServiceabilityFiltersParamters()
            .then((productResponse) => {
              $scope.filtersParamtersList = productResponse.data;
            });
        };
        $scope.getFilterData();

        $scope.isLoanLower = (maxLoanAmount) => {
          return maxLoanAmount < $scope.familyMembersData.LoanAmount;
        };

        // Get Serviceability Lender Data List
        $scope.getLendersList = function (data, isSavedCalc) {
          $scope.lendersSummaryList = data;
          const lendersSelected = _.find($scope.lendersSummaryList, (item) => {
            return item.IsSelectedLender === true;
          });
          if (
            typeof lendersSelected !== 'undefined' &&
            lendersSelected !== null
          ) {
            $scope.lenderSelected = true;
            $scope.MultiSelctLenderList = _.pluck(lendersSelected, 'LenderID');
          }
          $scope.countPerPage = 0;
          const lendersSummaryListLength = $scope.lendersSummaryList
            ? $scope.lendersSummaryList.length
            : 0;
          $scope.lenderTableParams = new NgTableParams(
            {
              count: lendersSummaryListLength,
              sorting: {
                MaxLoanAmount: 'desc',
              },
            },
            {
              counts: [],
              total: lendersSummaryListLength,
              getData(params) {
                const filterData = $scope.lendersSummaryList;
                if (
                  $scope.loanAmount !== '' &&
                  $scope.loanAmount !== 0 &&
                  typeof $scope.loanAmount !== 'undefined' &&
                  $scope.loanAmount !== '.'
                ) {
                  _.map(filterData, (item) => {
                    if (
                      Number(item.MaxLoanAmount) >= Number($scope.loanAmount)
                    ) {
                      item.isMaxLoanInRange = true;
                    } else {
                      item.isMaxLoanInRange = false;
                    }
                    return item;
                  });
                } else {
                  _.map(filterData, (item) => {
                    item.isMaxLoanInRange = undefined;
                    return item;
                  });
                }
                const orderedData = params.sorting()
                  ? $filter('orderBy')(filterData, params.orderBy())
                  : filterData;
                const $lenderMaxLoanAmount = _.maxBy(
                  orderedData,
                  'MaxLoanAmount',
                );
                if (typeof $lenderMaxLoanAmount !== 'undefined') {
                  $scope.lenderMaxLoanAmount =
                    Math.round($lenderMaxLoanAmount.MaxLoanAmount / 1000) *
                    1000;
                  if (
                    $scope.lenderMaxLoanAmount <
                    $lenderMaxLoanAmount.MaxLoanAmount
                  ) {
                    $scope.lenderMaxLoanAmount += 1000;
                  }
                }
                const $lenderMinLoanAmount = _.minBy(
                  orderedData,
                  'MaxLoanAmount',
                );
                if (typeof $lenderMinLoanAmount !== 'undefined') {
                  $scope.lenderMinLoanAmount =
                    Math.round($lenderMinLoanAmount.MaxLoanAmount / 1000) *
                    1000;
                  if (
                    $scope.lenderMinLoanAmount >
                    $lenderMinLoanAmount.MaxLoanAmount
                  ) {
                    $scope.lenderMinLoanAmount -= 1000;
                  }
                }
                $scope.countPerPage = orderedData.slice(
                  (params.page() - 1) * params.count(),
                  params.page() * params.count(),
                ).length;
                return orderedData.slice(
                  (params.page() - 1) * params.count(),
                  params.page() * params.count(),
                );
              },
            },
          );
          if ($scope.isRefreshLender) {
            $timeout(() => {
              cfpLoadingBar.complete();
            }, 750);
            $scope.isLenderLoaderOverlayDisplay = false;
            $rootScope.isLenderLoaderOverlayDisplay =
              $scope.isLenderLoaderOverlayDisplay;
            !isSavedCalc &&
              toaster.pop(
                'success',
                'Updated',
                'Serviceability has been updated successfully',
              );
          }
        };

        // Serviceability Income List
        // ----------------------------------------------------------------------------
        $scope.frequencyTypeList = {};
        $scope.existingIncomeList = [];
        $scope.incomeTypeList = [];
        $scope.clientList = [];

        // Income Type List Data
        $scope.getIncomeTypeForRegion = function () {
          const filters = {
            ClientFamilyID: $scope.familyId,
            LoanId: $scope.loanId,
          };
          serviceabilityService
            .getIncomeOptions($scope.brokerEventId, filters)
            .then((response) => {
              if (!response) {
                return;
              }
              getIncomeType({
                $scope,
                loanScenarioService,
                includeSubTypes: isNZ,
              });
              $scope.clientList = response.clients;
            });
        };
        $scope.getIncomeTypeForRegion();

        // Frequency Type List Data
        loanProfilerService.getFrequencyType().then((response) => {
          $scope.frequencyTypeList = response.data;
        });

        // Serviceability Income List Data
        $scope.getIncomeList = function () {
          loanProfilerService
            .getServiceabilityIncome(
              $scope.brokerEventId,
              $scope.loanScenarioId,
              $state.params.familyId || 0,
              $state.params.clientIds || null,
            )
            .then(({ data }) => {
              const previousIncomes = [...$scope.existingIncomeList];
              $scope.existingIncomeList = data.map((income) => {
                const { IncomeId } = income;
                const previousIncome = previousIncomes.find(
                  (obj) => obj.IncomeId === IncomeId,
                );
                const previousEditStatus = _.get(
                  previousIncome,
                  'IsEditMode',
                  false,
                );
                if (previousEditStatus) {
                  income.Value = previousIncome.Value;
                  $scope.editIncome(income);
                }
                return isNZ
                  ? mapRentalSubTypeNameAsIncomeNameForIncomeList(income)
                  : income;
              });
            });
        };

        $scope.getIncomeList();

        // Serviceability Expenses List
        // ----------------------------------------------------------------------------
        $scope.existingExpensesList = [];
        $scope.expensesTypeList = {};

        // Expenses Type List Data
        $scope.getExpenseType = function () {
          loanProfilerService.getExpenseType().then((response) => {
            $scope.expensesTypeList = response.data;
          });
        };
        $scope.getExpenseType();

        // Serviceability Expenses List Data
        $scope.getExpensesList = function () {
          loanProfilerService
            .getServiceabilityExpense(
              $scope.brokerEventId,
              $scope.loanScenarioId,
              $state.params.familyId || null,
              $state.params.clientIds || null,
            )
            .then(({ data }) => {
              $scope.existingExpensesList = data;
            });
        };
        $scope.getExpensesList();

        // Serviceability Family Members
        // ----------------------------------------------------------------------------
        const {
          proposedLoanAmount,
          securityAmount,
          primaryPurposeId,
        } = $stateParams;
        const primaryPurposeTypeId =
          getPrimaryPurposeTypeOfPurpose(parseToInt10(primaryPurposeId)) ||
          PRIMARY_PURPOSE_TYPE.OWNER_OCCUPIED;

        $scope.familyMembersData = {
          LoanTerm: 30,
          LoanAmount: proposedLoanAmount || null,
          SecurityAmount: securityAmount || null,
          ServiceabilityPurposeTypeId: primaryPurposeTypeId,
        };

        mapDataFromOpportunityCaller();
        // get Family Members
        $scope.getFamilyMembers = function () {
          loanProfilerService
            .getServiceabilityFamilyMembers(
              $scope.brokerEventId,
              $scope.loanScenarioId,
              $state.params.familyId || null,
              $state.params.clientIds || null,
            )
            .then(({ data }) => {
              const familyMembersData = (data && data.length && data[0]) || {};

              $scope.familyMembersData = {
                ...$scope.familyMembersData,
                ...familyMembersData,
              };
              updateDefaultForDeal({
                $scope,
                familyMembersData,
                proposedLoanAmount,
                securityAmount,
              });
            });
        };
        $scope.getFamilyMembers();

        // Serviceability Income Details
        // ----------------------------------------------------------------------------
        $scope.incomeDetailsData = {};

        // get Income Details
        $scope.getIncomeDetails = function () {
          loanProfilerService
            .getServiceabilityIncomeDetails(
              $scope.brokerEventId,
              $scope.loanScenarioId,
              $state.params.familyId || null,
              $state.params.clientIds || null,
            )
            .then(({ data }) => {
              $scope.incomeDetailsData = data;
            });
        };
        !$scope.isServiceabilityFromLoanAppOnOpportunity &&
          $scope.getIncomeDetails();
      };
      if ($stateParams.tab === 'serviceability') {
        $scope.init();
      }

      // Change Page View On Change Screen Size
      // -------------------------------------------------------------
      $scope.screenSize = $window.innerWidth;
      if ($scope.screenSize > 768) {
        $scope.isLenderDisplayInMobile = false;
      }
      const viewport = function () {
        let e = $window;
        let a = 'inner';
        if (!('innerWidth' in $window)) {
          a = 'client';
          e = $window.document.documentElement || $window.document.body;
        }
        return {
          width: e[`${a}Width`],
        };
      };

      $scope.getWindowWidth = function () {
        return {
          w: viewport().width,
        };
      };

      $scope.$watch(
        $scope.getWindowWidth,
        (newValue) => {
          $scope.screenSize = newValue.w;
          if (newValue.w > 768) {
            $scope.isLenderDisplayInMobile = false;
          }
        },
        true,
      );

      // Serviceability Income List
      // ----------------------------------------------------------------------------

      $scope.isAddIncomeVisible = false;
      $scope.newIncomeList = [];

      $scope.setIncomeType = setIncomeTypeModel($scope);

      // Add Serviceability Income Div
      $scope.addIncome = function () {
        const isClientEmpty =
          $scope.familyMembersData && !$scope.familyMembersData.NoOfSpouse;
        if (isClientEmpty) {
          return toastError('Please add Number of Clients');
        }
        if (!$scope.clientList || !$scope.clientList.length) {
          return toastError(
            'You must add a client first before adding an income.',
          );
        }

        $scope.isAddIncomeVisible = true;
        const newIncome = {
          IncomeID: Math.random(),
          IncomeTypeId: '',
          FrequencyName: 'Yearly',
          IncomeOwner: $scope.clientList[0],
          IncomeType: '',
          Value: '',
          IsEditMode: false,
          submitted: false,
        };

        if (isNZ || isAU) {
          newIncome.IncomeType = SALARY_WAGE_ID;
          newIncome.IncomeTypeId = `${SALARY_WAGE_ID},${newIncome.IncomeOwner.ClientNo}`;
        }
        $scope.newIncomeList.push(newIncome);
      };

      // Add Or Update Serviceability Income
      $scope.saveIncome = function (income) {
        income.isInvalidValue = false;
        income.isInvalidType = false;
        income.isInvalidClient = false;
        // eslint-disable-next-line unicorn/consistent-function-scoping
        const isInvalidInput = (input) => {
          return input === 0 || typeof input === 'undefined' || input === '';
        };

        if (isInvalidInput(income.IncomeTypeId)) {
          $timeout(() => {
            income.isInvalidType = true;
          }, 100);
          return;
        }

        if (isInvalidInput(income.Value)) {
          $timeout(() => {
            income.isInvalidValue = true;
          }, 100);
          return;
        }

        if (
          !income.IncomeOwner ||
          isInvalidInput(income.IncomeOwner.ClientNo)
        ) {
          const invalidClientTimeout = $timeout(() => {
            income.isInvalidClient = true;
          }, 100);

          $scope.$on('$destroy', () => {
            $timeout.cancel(invalidClientTimeout);
          });

          return;
        }
        if (income.submitted) {
          return;
        }
        income.submitted = true;
        const postData = {
          frequency: income.FrequencyName,
          type:
            isNZ && isIncomeRentalSubType(income.IncomeType)
              ? income.OriginalIncomeType
              : income.IncomeType,
          id: income.IncomeId,
          value: income.Value,
          clientId: income.IncomeOwner.ClientID,
          ownership: [
            {
              numofclient: income.IncomeOwner.ClientNo,
              percentage: 0,
              clientid: income.IncomeOwner.ClientID,
              entityid: income.IncomeOwner.EntityID,
            },
          ],
          rentalTypeId:
            (isNZ &&
              parseToInt10(income.IncomeType) === DEFAULT_INCOME_TYPES.RENTAL &&
              income.RentalTypeId) ||
            undefined,
        };

        if (!income.IsEditMode) {
          postData.id = 0;
        }

        loanProfilerService
          .setServiceabilityIncome($scope.brokerEventId, postData)
          .then(
            () => {
              if (parseInt(postData.id, 10) === 0) {
                const index = $scope.newIncomeList.indexOf(income);
                if (index > -1) {
                  $scope.newIncomeList.splice(index, 1);
                }
              }
              $scope.getIncomeList();
              $scope.getIncomeDetails();

              if (parseInt(postData.id, 10) === 0) {
                toaster.pop(
                  'success',
                  'Added',
                  'Income has been Added Successfully',
                );
              } else {
                toaster.pop(
                  'success',
                  'Updated',
                  'Income has been Updated Successfully',
                );
              }

              income.IsEditMode = false;
              income.IsAnimation = true;
            },
            (error) => {
              toastError(error);
            },
          );
      };

      $scope.deleteIncome = (income) => {
        deleteIncome(income, $scope.newIncomeList, loanProfilerService).then(
          () => {
            $scope.getIncomeList();
            $scope.getIncomeDetails();
            toaster.pop('success', 'Deleted', 'Income has been deleted');
          },
          (error) => {
            toaster.pop(
              'error',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              'Yikes! Something is wrong',
              error.data.Message,
            );
          },
        );
      };

      // Edit Serviceability Income
      $scope.editIncome = function (income) {
        if (!income.IsEditMode) {
          const clientNo =
            income.Ownership.length && income.Ownership[0].numofclient;
          income.IncomeType = String(income.IncomeTypeId);

          // set default income owner
          income.IncomeOwner = $scope.clientList.find((client) => {
            if (income.ClientID) {
              return client.ClientID === income.ClientID;
            }

            return client.ClientNo === clientNo;
          });
        }

        income.IsEditMode = true;
      };

      // Serviceability Expenses List
      // ----------------------------------------------------------------------------
      $scope.isAddExpensesVisible = false;
      $scope.newExpensesList = [];

      // Add Serviceability Expenses Div
      $scope.addExpenses = function () {
        $scope.isAddExpensesVisible = true;
        const newExpenses = {
          ExpenseID: Math.random(),
          ExpenseTypeID: '',
          FrequencyName: 'Monthly',
          Value: '',
          IsEditMode: false,
          submitted: false,
        };
        $scope.newExpensesList.push(newExpenses);
      };

      // Add Or Update Serviceability Expenses
      $scope.saveExpenses = function (expenses) {
        expenses.isInvalidValue = false;
        expenses.isInvalidType = false;
        if (expenses.ExpenseTypeID === '') {
          $timeout(() => {
            expenses.isInvalidType = true;
          }, 100);
          return;
        } else if (
          expenses.Value === 0 ||
          typeof expenses.Value === 'undefined' ||
          expenses.Value === ''
        ) {
          $timeout(() => {
            expenses.isInvalidValue = true;
          }, 100);
          return;
        }
        if (expenses.submitted) {
          return;
        }
        expenses.submitted = true;

        const postData = {
          frequency: expenses.FrequencyName,
          value: expenses.Value,
          id: expenses.ExpenseID,
          type: expenses.ExpenseTypeID,
        };

        if (!expenses.IsEditMode) {
          postData.id = 0;
        }

        loanProfilerService
          .setServiceabilityExpense($scope.brokerEventId, postData)
          .then(
            () => {
              if (parseInt(postData.id, 10) === 0) {
                const index = $scope.newExpensesList.indexOf(expenses);
                if (index > -1) {
                  $scope.newExpensesList.splice(index, 1);
                }
              }

              if (parseInt(postData.id, 10) === 0) {
                toaster.pop(
                  'success',
                  'Added',
                  'Expenses has been Added Successfully',
                );
              } else {
                toaster.pop(
                  'success',
                  'Updated',
                  'Expenses has been Updated Successfully',
                );
              }
              $scope.getExpensesList();
              $scope.getIncomeDetails();

              expenses.IsEditMode = false;
              expenses.IsAnimation = true;
            },
            // eslint-disable-next-line sonarjs/no-identical-functions
            (error) => {
              toaster.pop(
                'error',
                'Yikes! Something is wrong',
                error.data.Message,
              );
            },
          );
      };

      // Delete Serviceability Expenses
      $scope.deleteExpenses = function (expenses) {
        if (Math.floor(expenses.ExpenseID) === 0) {
          const index = $scope.newExpensesList.indexOf(expenses);
          if (index > -1) {
            $scope.newExpensesList.splice(index, 1);
          }
        } else {
          loanProfilerService
            .deleteServiceabilityExpense(expenses.ExpenseID)
            .then(
              () => {
                $scope.getExpensesList();
                $scope.getIncomeDetails();
                toaster.pop('success', 'Deleted', 'Expenses has been deleted');
              },
              // eslint-disable-next-line sonarjs/no-identical-functions
              (error) => {
                toaster.pop(
                  'error',
                  'Yikes! Something is wrong',
                  error.data.Message,
                );
              },
            );
        }
      };

      // Set Expense Settings
      $scope.setServiceabilitySettings = function () {
        const postData = {
          brokerEventID: $scope.brokerEventId,
          isUseLenderDefault: $scope.incomeDetailsData.IsUseLenderDefault,
        };

        loanProfilerService.setServiceabilitySettings(postData).then(
          () => {
            $scope.getIncomeDetails();
          },
          // eslint-disable-next-line sonarjs/no-identical-functions
          (error) => {
            toaster.pop(
              'error',
              'Yikes! Something is wrong',
              error.data.Message,
            );
          },
        );
      };

      // Serviceability Liabilities List
      // ----------------------------------------------------------------------------

      // Liabilities List Data
      $scope.existingLiabilitiesList = [];
      $scope.getLiabilitiesList = function (isSavedCalc = false) {
        loanProfilerService
          .getLiabilityList(
            $scope.brokerEventId,
            $scope.loanScenarioId,
            $state.params.familyId || null,
          )
          .then(({ data }) => {
            const prevState = [...$scope.existingLiabilitiesList];
            $scope.existingLiabilitiesList =
              (data &&
                data.map((liability) => {
                  const { LiabilityTypeID } = liability;
                  liability.isLimit = loanProfilerDetailService.liabilityIsLimit(
                    LiabilityTypeID,
                    isNZ,
                  );
                  liability.isNextProp = loanProfilerDetailService.liabilityIsNextProp(
                    LiabilityTypeID,
                  );
                  const currentLiability = prevState.find(
                    (data) => data.LiabilityTypeID === LiabilityTypeID,
                  );
                  liability.IsEditMode = _.get(
                    currentLiability,
                    'IsEditMode',
                    false,
                  );
                  liability.IsAnimation = false;
                  return {
                    ...getLiabilityForStudentLoan({
                      liability,
                      studentLoanDropdown,
                      clientList: $scope.clientList,
                    }),
                    ...getLiabilityForNzServiceabilityDates({
                      liability,
                      isNZ,
                    }),
                  };
                })) ||
              [];
            if (isSavedCalc) {
              $scope.isNZ && $scope.refreshResultOfLender(isSavedCalc);
              $scope.isAU && $scope.calculateResultOfLender(isSavedCalc);
            }
          });
      };

      // Liabilities Type List Data
      $scope.liabilitiesTypeList = [];
      $scope.getLiabilitiesTypeList = function () {
        loanProfilerService.getLiabilityTypeList(0).then((response) => {
          $scope.liabilitiesTypeList = $filter('orderBy')(
            response.data,
            'TypeName',
          );
          const isSavedCalc = !!($scope.loanScenarioId || $scope.familyId);
          $scope.getLiabilitiesList(isSavedCalc);
        });
      };
      if ($stateParams.tab === 'serviceability') {
        $scope.getLiabilitiesTypeList();
      }

      $scope.isAddLiabilitiesVisible = false;
      $scope.newLiabilitiesList = [];

      // Add Serviceability Expenses Div
      $scope.addLiabilities = function () {
        $scope.isAddLiabilitiesVisible = true;
        const newLiabilities = {
          LiabilityID: Math.random(),
          LiabilityTypeID: '',
          FrequencyName: 'Monthly',
          LiabilityRepayment: '',
          LiabilityLimit: '',
          LiabilityBalance: '',
          InterestRate: '',
          IsTaxDeductible: false,
          IsEditMode: false,
          showDateCalendar: false,
          DocumentedLoanTermMonth: 0,
          DocumentedLoanTermYear: 30,
          submitted: false,
          RepaymentTypeId: LOAN_TERM.PRINCIPAL_INTEREST,
        };
        $scope.newLiabilitiesList.push(newLiabilities);
      };

      $scope.editLiability = (liability) => {
        liability.IsAnimation = true;
        liability.IsEditMode = true;
      };

      // Add Or Update Liabilities
      $scope.saveLiabilities = (Liabilities, isValid) => {
        const {
          LiabilityTypeID,
          LiabilityRepayment,
          FrequencyName,
          isLimit,
          LiabilityLimit,
          LiabilityBalance,
          InterestRate,
          IsTaxDeductible,
          LiabilityID,
          Owner,
          LoanStartDate,
          DocumentedLoanTermYear,
          DocumentedLoanTermMonth,
          IsEditMode,
          submitted,
          RepaymentTypeId,
        } = Liabilities;
        if (
          (isValid ||
            (LiabilityTypeID === COURT_RULED_CHILD_MAINTENANCE &&
              LiabilityRepayment)) &&
          !submitted
        ) {
          Liabilities.submitted = true;
          const postData = {
            Frequency: FrequencyName,
            Limit: isLimit ? LiabilityLimit : '',
            RepaymentAmount:
              LiabilityTypeID !== LIABILITY_TYPE.CREDIT_CARD
                ? LiabilityRepayment
                : '',
            Balance: $scope.showBalance(Liabilities) ? LiabilityBalance : '',
            InterestRate: Number(InterestRate),
            IsTaxDeductible: IsTaxDeductible,
            id: LiabilityID,
            type: LiabilityTypeID,
            ...(Liabilities.showStudentLoanDropDown && {
              ownership: getLiabilityOwner({
                clientList: $scope.clientList,
                owner: Owner,
              }),
            }),
            ...($scope.showDates(LiabilityTypeID) && {
              LoanStartDate,
              DocumentedLoanTermYear,
              DocumentedLoanTermMonth,
            }),
            ...($scope.showRepaymentButtonGroup &&
              $scope.showRepaymentButtonGroup(LiabilityTypeID) && {
                RepaymentTypeId,
              }),
          };
          !IsEditMode && (postData.id = 0);

          loanProfilerService.setLiability($scope.brokerEventId, postData).then(
            () => {
              if (parseInt(postData.id, 10) === 0) {
                const index = $scope.newLiabilitiesList.indexOf(Liabilities);
                if (index > -1) {
                  $scope.newLiabilitiesList.splice(index, 1);
                }
              }

              if (parseInt(postData.id, 10) === 0) {
                toaster.pop(
                  'success',
                  'Added',
                  'Liability has been Added Successfully',
                );
              } else {
                toaster.pop(
                  'success',
                  'Updated',
                  'Liability has been Updated Successfully',
                );
              }
              $scope.getLiabilitiesList();
              $scope.getIncomeDetails();

              Liabilities.IsEditMode = false;
              Liabilities.IsAnimation = true;
            },
            // eslint-disable-next-line sonarjs/no-identical-functions
            (error) => {
              toaster.pop(
                'error',
                'Yikes! Something is wrong',
                error.data.Message,
              );
            },
          );
        }
      };

      // Change Value Of Rate
      $scope.changeValueOfRate = function (liability) {
        if (!liability.IsTaxDeductible) {
          liability.InterestRate = '';
        }
      };

      // Change Value Of Rate
      $scope.changeValueOfLiabilityType = function (liability) {
        const { LiabilityTypeID, RepaymentTypeId } = liability;
        liability.IsTaxDeductible = false;
        liability.InterestRate = '';
        liability.isLimit = loanProfilerDetailService.liabilityIsLimit(
          LiabilityTypeID,
          isNZ,
        );
        liability.isNextProp = loanProfilerDetailService.liabilityIsNextProp(
          LiabilityTypeID,
        );
        liability.isInvalidLimit = false;
        liability.isInvalidBalance = false;
        if (!studentLoanDropdown) {
          return;
        }
        liability.showStudentLoanDropDown =
          LiabilityTypeID === LIABILITY_TYPE.STUDENT_LOAN &&
          _.get($scope.clientList, 'length', 0) > 1;
        liability.Owner =
          liability.Owner || _.get($scope.clientList, '[0]', null);
        liability.RepaymentTypeId =
          LIABILITY_TYPE.LINE_OF_CREDIT === LiabilityTypeID
            ? LOAN_TERM.INTEREST_ONLY
            : RepaymentTypeId;
      };

      // Delete Liabilities
      $scope.deleteLiabilities = function (Liabilities) {
        if (Math.floor(Liabilities.LiabilityID) === 0) {
          const index = $scope.newLiabilitiesList.indexOf(Liabilities);
          if (index > -1) {
            $scope.newLiabilitiesList.splice(index, 1);
          }
        } else if (Liabilities.IsEditMode) {
          Liabilities.IsEditMode = false;
        } else {
          loanProfilerService.deleteLiability(Liabilities.LiabilityID).then(
            () => {
              $scope.getLiabilitiesList();
              $scope.getIncomeDetails();
              toaster.pop('success', 'Deleted', 'Liability has been deleted');
            },
            // eslint-disable-next-line sonarjs/no-identical-functions
            (error) => {
              toaster.pop(
                'error',
                'Yikes! Something is wrong',
                error.data.Message,
              );
            },
          );
        }
      };

      // Serviceability Family Members
      // ----------------------------------------------------------------------------

      // Update Serviceability Family Members
      $scope.updateFamilyMembers = function () {
        if (!$scope.familyMembersData) {
          return toastError();
        }
        const existingIncomeList = $scope.existingIncomeList
          ? $scope.existingIncomeList
          : [];
        const dataIncome = existingIncomeList.find(
          (item) => item.ClientNo > $scope.familyMembersData.NoOfSpouse,
        );
        const dataExpense = existingIncomeList.find(
          (item) => item.ClientNo > $scope.familyMembersData.NoOfSpouse,
        );

        if (dataIncome || dataExpense) {
          $scope.familyMembersData.NoOfSpouse++;
          toastError(
            'Please first delete record from the income or expense list',
          );
        } else {
          $scope.familyMembersData.brokerEventID = $scope.brokerEventId;
          loanProfilerService
            .setServiceabilityFamilyMembers($scope.familyMembersData)
            .then((response) => {
              $scope.familyMembersData.LVR =
                response.data && response.data.length && response.data[0].LVR
                  ? response.data[0].LVR
                  : 0;
              if (response.data && response.data.length) {
                $scope.getIncomeTypeForRegion($scope.brokerEventId);
              }
            });
        }
      };

      const {
        LoanAmount: targetLoanAmount,
        SecurityAmount: targetSecurityAmount,
      } = $scope.familyMembersData || {};
      if (
        parseInt(targetLoanAmount, 10) ||
        parseInt(targetSecurityAmount, 10)
      ) {
        !$scope.isServiceabilityFromLoanAppOnOpportunity &&
          $scope.updateFamilyMembers();
      }

      // Save Serviceability Income, Expense and Liabilities Data on click of Calculate Button
      $scope.SaveAllData = function () {
        $scope.isLenderLoaderOverlayDisplay = true;
        $rootScope.isLenderLoaderOverlayDisplay =
          $scope.isLenderLoaderOverlayDisplay;
        $scope.isRefreshLender = true;
        cfpLoadingBar.start();
        cfpLoadingBar.inc();
        const formData = angular
          .element('.col-fixed-324 .serviceability-div')
          .find('form.income-expense-form .serviceability-save-btn');
        angular.forEach(formData, (item) => {
          const eventTimeout = $timeout(() => {
            item.click();
            $timeout.cancel(eventTimeout);
          }, 100);
        });
        const formDataLiability = angular
          .element('.col-fixed-324 .serviceability-div')
          .find('form.liability-form .serviceability-save-btn');
        // eslint-disable-next-line sonarjs/no-identical-functions
        angular.forEach(formDataLiability, (item) => {
          const eventTimeout = $timeout(() => {
            item.click();
            $timeout.cancel(eventTimeout);
          }, 100);
        });
        $scope.loanAmount = 0;
        angular.element('#loanamounttxt').val(0);
        bouncingTooltipService.setTooltipVisible(true);
      };

      // Refresh Result Of Lender
      // -----------------------------------------------------------------------------
      $scope.refreshResultOfLender = function (isSavedCalc) {
        const noOfClientChecker =
          !$scope.familyMembersData.NoOfSpouse ||
          $scope.familyMembersData.NoOfSpouse <= 0;
        if (noOfClientChecker) {
          toaster.pop('error', 'Error', 'Please add number of clients');
          return;
        }
        $scope.SaveAllData();
        getCalculation({
          $scope,
          loanProfilerService,
          accreditedOnly,
          isSavedCalc,
          clientIds: $state.params.clientIds,
        });
        $scope.isLenderDisplay = true;
        if ($scope.screenSize < 768) {
          $scope.isLenderDisplayInMobile = true;
        }
        $timeout(() => {
          $rootScope.disableSaveContactBtn = false;
        }, 500);
      };

      // Refresh Result Of Lender
      // -----------------------------------------------------------------------------
      $scope.calculateResultOfLender = function (isSavedCalc) {
        const noOfClientChecker =
          !$scope.familyMembersData.NoOfSpouse ||
          $scope.familyMembersData.NoOfSpouse <= 0;
        if (noOfClientChecker) {
          toaster.pop('error', 'Error', 'Please add number of clients');
          return;
        }
        $scope.SaveAllData();
        $scope.lenderSelected = false;
        loanProfilerService
          .getServiceabilityNextGenAccessment(
            $scope.brokerEventId,
            $scope.loanScenarioId,
            $state.params.familyId || null,
            $state.params.clientIds || null,
            accreditedOnly,
          )
          .then(
            (response) => {
              if (response.data === null) {
                return toastError();
              }
              $scope.getLendersList(response.data, isSavedCalc);
            },
            () => {
              toaster.pop(
                'error',
                'Error',
                'Yikes! Something is wrong. Please hit Calculate again.',
              );
            },
          );
        $scope.isLenderDisplay = true;
        if ($scope.screenSize < 768) {
          $scope.isLenderDisplayInMobile = true;
        }
        $timeout(() => {
          $rootScope.disableSaveContactBtn = false;
        }, 500);
      };

      // View Product
      $scope.viewProduct = function (productSelectionType, id) {
        $scope.defaultSelection = {};
        loanProfilerService
          .getServiceabilityFilteringProductsGeneralSettings(
            $scope.brokerEventId,
          )
          .then((response) => {
            $scope.defaultSelection = response.data;
            if ($scope.defaultSelection.Lender) {
              $scope.defaultSelection.Lender = [];
              if (productSelectionType === 'multiple') {
                $scope.defaultSelection.Lender = $scope.MultiSelctLenderList;
              }
              if (productSelectionType === 'single') {
                $scope.defaultSelection.Lender = [id];
              }
            }
            if ($scope.familyMembersData) {
              const {
                LoanAmount: loanAmount,
                SecurityAmount: securityAmount,
                LoanTerm: loanTerm,
                LVR: lvr,
              } = $scope.familyMembersData;
              $scope.defaultSelection.loanAmount =
                (loanAmount && parseInt(loanAmount, 10)) || '';
              $scope.defaultSelection.security =
                (securityAmount && parseInt(securityAmount, 10)) || '';
              $scope.defaultSelection.loanTerm =
                (loanTerm && parseInt(loanTerm, 10)) || '';
              if (lvr > 100 || parseFloat(lvr).toFixed(2) === 0) {
                $scope.defaultSelection.LVR = 0;
              }
              if (parseInt(lvr, 10) !== 0) {
                $scope.defaultSelection.LVR = `+${parseFloat(lvr).toFixed(2)}`;
              }
            }
            loanProfilerService
              .updateServiceabilityFilteringProductsSettings(
                $scope.defaultSelection.brokerEventID,
                { GeneralSetting: $scope.defaultSelection },
              )
              .then((generalSettingsResponse) => {
                if (generalSettingsResponse && generalSettingsResponse.data) {
                  const tab = 'productFinder';
                  if ($scope.familyId) {
                    const contact = loanCalculatorLinking.LINKERS.CONTACT;
                    const familyId = $scope.familyId;
                    const obj = {
                      tabName: tab,
                      familyId,
                      contact,
                      eventId: $scope.brokerEventId,
                    };
                    $localStorage.contactLoanCalculatorLinker = {
                      linkerId: contact,
                      familyId,
                    };
                    return $scope.isServiceabilityFromLoanAppOnOpportunity
                      ? opportunityCalculationService.addCalculation(
                          opportunityCalculationService.CALCULATION_TYPE
                            .PRODUCT_FINDER,
                          $scope.familyId,
                          $scope.brokerEventId,
                        )
                      : loanCalculatorLinking.redirectToLoanProfiler(obj);
                  }
                  $state.go('app.loanProfiler', {
                    brokerEventId: $scope.brokerEventId,
                    loanScenarioId: 0,
                    tab,
                  });
                } else {
                  toaster.pop('error', 'Error', 'Yikes! Something is wrong');
                }
              });
          });
      };

      // View Multiple Product on Product Finder
      $scope.viewProductFinder = function () {
        if ($scope.MultiSelctLenderList.length === 0) {
          toaster.pop(
            'warning',
            'No Lender Selected',
            'Please select lender for view in product finder',
          );
        } else {
          $scope.viewProduct('multiple', 0);
        }
      };
      $scope.openSheet = function (lenderId) {
        loanProfilerService
          .LenderInfoSpreadsheetDataGet(lenderId, $scope.brokerEventId)
          .then((response) => {
            if (response.data && response.data.DocumentContent) {
              const eventTimeout = $timeout(() => {
                const byteString = $window.atob(response.data.DocumentContent);
                const a = $window.document.createElement('a');

                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                  ia[i] = byteString.charCodeAt(i);
                }

                const blob = new $window.Blob([ia], {
                  type: response.data.ContentType,
                });

                a.href = $window.URL.createObjectURL(blob);
                a.download = response.data.Name;
                a.click();
                $timeout.cancel(eventTimeout);
              });
            }
          });
      };

      // View Single Product on Product Finder
      $scope.viewSingleProduct = function (id) {
        $scope.viewProduct('single', id);
      };

      $scope.lenderSelection = [];
      // Update Lender Selection
      // ----------------------------------------------------------------------------
      $scope.updateLender = function (item) {
        item.IsSelectedLender = !item.IsSelectedLender;
        if (item.IsSelectedLender === true) {
          $scope.MultiSelctLenderList.push(item.LenderID);
        } else {
          const index = $scope.MultiSelctLenderList.indexOf(item.LenderID);
          if (index > -1) {
            $scope.MultiSelctLenderList.splice(index, 1);
          }
        }
      };
      /** NZ Requirements By Elmer Datolayta */
      $scope.hiddenClientForNz = false;
      commonFnService.hiddenClientForNz().then((response) => {
        $scope.hiddenClientForNz = response;
      });

      $scope.hasRubikSpreadsheets = (lender) => {
        return (
          uiService.isCountry('Australia') &&
          lender.spreadSheetsArray &&
          lender.spreadSheetsArray.length
        );
      };

      $scope.hasNZSpreadsheets = (lender) => {
        if (!lender) {
          return false;
        }

        return (
          $scope.isNZ &&
          $scope.banksWithSpreadForNZ.findIndex(
            (item) => item === lender.LenderID.toString(),
          ) !== -1
        );
      };

      $scope.$on('$destroy', () => {
        !$scope.isServiceabilityFromLoanAppOnOpportunity &&
          loanCalculatorLinking.resetLinker();
      });

      if ($stateParams.primaryPurposeId) {
        $timeout(() => {
          $scope.updateFamilyMembers();
        }, 2000);
      }
    });
