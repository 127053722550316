class OpenLMISelectedLenderModalCtrl {
  constructor(
    selectedLenderObj,
    removeLenderIsFavorite,
    lmiPremiumObj,
    $uibModalInstance,
  ) {
    'ngInject';

    this.selectedLenderObj = selectedLenderObj;
    this.lmiPremiumObj = lmiPremiumObj;
    this.removeLenderIsFavorite = removeLenderIsFavorite;
    this.uibModalInstance = $uibModalInstance;
  }

  cancel() {
    this.uibModalInstance.dismiss('cancel');
  }
}

export default OpenLMISelectedLenderModalCtrl;
