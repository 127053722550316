export default class TaskTemplateItemCtrl {
  onEditTemplate() {
    this.template.isEditMode = !this.template.isEditMode;
  }

  onSave(template) {
    this.onEditTemplate();
    this.onSaveTemplate({ updatedTemplate: template });
  }
}
