export default class InsuranceNotificationsCtrl {
  constructor($location, optionsService, dashboardService) {
    'ngInject';

    this.$location = $location;
    this.optionsService = optionsService;
    this.dashboardService = dashboardService;
  }

  $onInit() {
    this.widgetTitle = this.myParam.DisplayName;
    this.tableData = [];
    this.dataLoaded = false;
    this.showWidgetSettings = true;
    this.subTitle = 'By Customer Care section';
    this.getNotifications();
  }

  setView(isSettings) {
    this.isSettings = isSettings;
  }

  onTitleClick() {
    this.$location.path('/app/communicate/customer-care-insurance/0');
  }

  getNotifications() {
    this.dataLoaded = false;
    this.dashboardService.getInsuranceNotifications().then((res) => {
      this.tableData = res;
      this.dataLoaded = true;
    });
  }

  goToTab(index) {
    this.$location.path(`/app/communicate/customer-care-insurance/${index}`);
  }
}
