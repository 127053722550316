import _ from 'lodash';

function EmailTemplatesSharingPrivacyCtrl(
  $scope,
  $rootScope,
  $http,
  $filter,
  $timeout,
  corporateService,
  contactService,
  regionalizationService,
  queryService,
  $state,
  SweetAlert,
  mapService,
  $uibModal,
  broadcastDataService,
  templateService,
  uiService,
  toaster,
  currentUserService,
) {
  'ngInject';

  $scope.emailTemplates = [];
  $scope.isCorporateUser = uiService.isCorporateUser;

  $scope.emailTemplateList = [];
  function getEmailTemplates() {
    corporateService.getEmailTemplates(0).then((response) => {
      $scope.emailTemplateList = _.filter(
        response.data,
        (category) => !templateService.isBlankFolderOrTemplate(category),
      );
    });
  }
  getEmailTemplates();

  $scope.email = {};

  $scope.email = broadcastDataService;
  $scope.$watch('email.emailTemplateView.isTriggered', () => {
    if ($scope.email.emailTemplateView.isTriggered) {
      getEmailTemplates();
      $scope.email.emailTemplateView.isTriggered = false;
    }
  });

  $scope.updateTemplate = (data, e, isCategory) => {
    if (e) {
      $scope.noProgation(e);
    }
    if (!data || (isCategory && data.IsSystemTemplate)) {
      return;
    }
    data.IsFolder = !data.UserTemplateFolderId;
    data.FolderTemplateID = data.UserTemplateFolderId;
    corporateService.setEmailTemplates(data);
  };
  const getBrandingCategoryList = () => {
    const countryId = currentUserService.countryId || 0;
    corporateService.brandingCategoryListGet(countryId).then(({ data }) => {
      if (!data || !data.length) {
        return;
      }
      const allBranding = {
        BrandingCategoryId: 0,
        BrandingCategory: 'All Branding',
      };
      $scope.brandingCategoryList = [allBranding, ...data];
    });
  };
  getBrandingCategoryList();

  $scope.noProgation = function (e) {
    e.stopPropagation();
    e.preventDefault();
  };

  $scope.deleteTemplate = function (data) {
    data.IsActive = false;
    data.FolderTemplateID = data.UserTemplateFolderId;
    corporateService.setEmailTemplates(data).then((response) => {
      if (response.data > 0) {
        toaster.pop(
          'success',
          'Delete Template',
          `${data.UserTemplateName} has been deleted.`,
        );
        getEmailTemplates();
      } else {
        toaster.pop(
          'error',
          'Delete Template',
          'There is an error in deleting template.Please try again.',
        );
      }
    });
  };
}

export default EmailTemplatesSharingPrivacyCtrl;
