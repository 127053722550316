export default class InsurancePipelineStatusesCtrl {
  constructor(pipelineApiService) {
    'ngInject';

    this.pipelineApiService = pipelineApiService;
  }

  $onInit() {
    this.pipelineApiService
      .getInsurancePipelineStatuses()
      .then((statuses = []) => {
        this.statuses = statuses;
      });
  }

  onChangeStatus(statusId) {
    const { id, name } =
      this.statuses.find((status) => status.id === statusId) ?? {};
    this.onChange({ statusId: id ?? statusId, statusName: name });
  }
}
