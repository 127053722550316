import { NUMBER_CHECKING_TYPE } from 'Common/constants/numberCheckingType';

export function setNumericValueValidator(attrs, ngModel, checkingType) {
  if (!attrs || !ngModel || !checkingType) {
    return;
  }

  ngModel.$validators[checkingType] = (modelValue, viewValue) => {
    const valueThreshold = parseInt(attrs[checkingType], 10);
    const currentValue = parseInt(modelValue || viewValue, 10);
    let isCurrentValueValid;
    if (checkingType === NUMBER_CHECKING_TYPE.MIN) {
      isCurrentValueValid = currentValue >= valueThreshold;
    } else {
      isCurrentValueValid = currentValue <= valueThreshold;
    }
    return isCurrentValueValid;
  };
}
