class AddressModalService {
  constructor($uibModal) {
    'ngInject';

    this.$uibModal = $uibModal;
  }

  launchAddressModal(
    size,
    contactId,
    isOrg = false,
    tempAddress = {},
    defaultManual = false,
    addressTypeToShow,
    addressModalCaller,
  ) {
    return this.$uibModal.open({
      templateUrl: '/assets/views/corporate/modals/addressModal.html',
      controller: 'AddressModalCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      size,
      windowClass: '',
      resolve: {
        contactId: () => contactId,
        isOrg: () => isOrg,
        tempAddress: () => tempAddress,
        defaultManual: () => defaultManual,
        addressTypeToShow: () => addressTypeToShow,
        addressModalCaller: () => addressModalCaller,
      },
    });
  }
}

export default AddressModalService;
