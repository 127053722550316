import moment from 'moment';
import { get } from 'lodash';
import { TO_MYCRM_DATE_FORMAT } from 'Common/constants/dateConfigs';
import { COUNTRY_SHORTNAME } from 'Common/constants/countryType';
import { toastInfo } from 'Common/utilities/alert';
import { pluralize } from 'Common/utilities/string';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import { getPhoneSavePayload } from 'Common/utilities/contactNumber';
import {
  SUBSCRIPTIONTYPE,
  SIT_TRANSACTIONAL_ITEMS,
} from 'Common/constants/subscriptionTypeCode';
import { NURTURE_FLAG } from 'Common/constants/customerCareModules';
import { parseToInt10 } from 'Common/utilities/parse';

export const onOpenDate = ({ $scope, dateField }) => {
  if (!dateField) {
    return;
  }
  $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
};

export const createExperienceDate = ($scope) => {
  const { month, year } = $scope.experienceDate;
  if (!month || !year) {
    return;
  }
  const { currentDate } = $scope;
  const monthString = month >= 10 ? month : `0${month}`;
  const dateString = `${year}-${monthString}-01T00:00:00.000Z`;
  if (moment(currentDate).diff(dateString) < 0) {
    $scope.experienceDate = {};
    $scope.startDateTotalYears = null;
    toastInfo('Invalid date');
    return;
  }
  $scope.contact[0].SoleTraderInfo.IndustryExperienceStartDate = moment(
    dateString,
  );
  $scope.getStartDate(
    $scope.contact[0].SoleTraderInfo.IndustryExperienceStartDate,
  );
};

export const getStartDate = ({ $scope, date }) => {
  if (!date) {
    $scope.startDateTotalYears = null;
    return;
  }
  const { currentDate } = $scope;
  const years = moment(currentDate).diff(date, 'years', false);
  const dateAdded =
    years && moment(date, TO_MYCRM_DATE_FORMAT).add(years, 'years');
  const months = dateAdded
    ? moment(currentDate).diff(dateAdded, 'months', false)
    : moment(currentDate).diff(date, 'months', false);
  const andString = years && months ? ' and ' : '';
  const monthString = pluralize(months, 'month');
  if (years) {
    const yearString = pluralize(years, 'year');
    $scope.startDateTotalYears = ` - ${yearString}`;
    $scope.startDateTotalYears = andString
      ? `${$scope.startDateTotalYears}${andString}${monthString}`
      : $scope.startDateTotalYears;
    return;
  }
  $scope.startDateTotalYears = ` - ${monthString}`;
};

export const setSoleTraderInfoDetails = ($scope) => {
  const { SoleTraderInfo } = $scope.contact[0];
  $scope.hasSoleTraderInfoDetails = !!SoleTraderInfo;
  if (!SoleTraderInfo) {
    $scope.contact[0].SoleTraderInfo = {
      IsGSTRegistered: false,
    };
  } else {
    const {
      IndustryExperienceStartDate,
      TradingStartDate,
      GSTRegistrationDate,
    } = SoleTraderInfo;
    $scope.contact[0].SoleTraderInfo.TradingStartDate = TradingStartDate
      ? new Date(TradingStartDate)
      : '';
    $scope.contact[0].SoleTraderInfo.GSTRegistrationDate = GSTRegistrationDate
      ? new Date(GSTRegistrationDate)
      : '';
    $scope.getMonthAndYear(IndustryExperienceStartDate);
  }
};

export const getMonthAndYear = ({ $scope, date }) => {
  if (!date) {
    return;
  }
  const dateConverted = moment(date, 'YYYY/MM/DD');
  const month = dateConverted.format('M');
  const year = dateConverted.format('YYYY');
  $scope.experienceDate = {
    month: parseInt(month, 10),
    year: parseInt(year, 10),
  };
  $scope.createExperienceDate();
};

export const toggleActiveSoleTrader = ({ $scope, isSoleTrader }) => {
  const { contact } = $scope;
  if (!contact || !contact.length || typeof isSoleTrader === 'undefined') {
    return;
  }
  const { SoleTraderInfo } = contact[0];
  if (isSoleTrader) {
    $scope.hasSoleTraderInfoDetails = true;
    if (!SoleTraderInfo) {
      return;
    }
    $scope.contact[0].SoleTraderInfo.ForDeletion = false;
    return;
  }
  $scope.hasSoleTraderInfoDetails = false;
  if (!SoleTraderInfo) {
    return;
  }
  const { DetailsId } = SoleTraderInfo;
  if (!DetailsId) {
    return;
  }
  $scope.contact[0].SoleTraderInfo.ForDeletion = true;
};

export const mobileCountryDropdown = ({
  $scope,
  currentUserService,
  configService,
}) => {
  $scope.countryCode = currentUserService.isNZ ? 'nz' : 'au';
  $scope.isMobileInputDropdown =
    currentUserService.isNZ && configService.feature.phoneNumberCountryCodeNZ;
  $scope.isLoading = false;
  $scope.selectedCountry = {
    iso2: $scope.countryCode,
  };
};

export const assignSelectedCountryValue = ({
  inputElement,
  flagCondition,
  currentUserService,
}) => {
  const countryShort = currentUserService.isAU
    ? COUNTRY_SHORTNAME.AUSTRALIA
    : COUNTRY_SHORTNAME.NEW_ZEALAND;

  const isCountryCodeEmpty = Object.keys(
    inputElement.intlTelInput('getSelectedCountryData'),
  ).length;

  const isCountryCodeValid =
    flagCondition && inputElement.length && isCountryCodeEmpty;
  return isCountryCodeValid
    ? inputElement.intlTelInput('getSelectedCountryData')
    : { iso2: countryShort };
};

export const includeCounryCodeToPhone = ({ $scope, contactNumbers }) => {
  return contactNumbers.map((phone) => {
    phone.CountryCode = isValidObjectValue(() => $scope.selectedCountry.iso2)
      ? $scope.selectedCountry.iso2.toUpperCase()
      : '';
    return phone;
  });
};

export const setCountryCode = ({ countryCode, defaultCountryCode }) => {
  const defaultCountry = defaultCountryCode || 'nz';
  if (!countryCode) {
    return defaultCountry.toLowerCase();
  }

  return countryCode ? countryCode.toLowerCase() : defaultCountry.toLowerCase();
};

export const getPhoneInputId = (index) => {
  const phoneInputIdCollection = [
    'homePhone',
    'businessPhone',
    'mobileNumber-dc',
  ];

  return phoneInputIdCollection.find((phoneId, key) => {
    if (key === index) {
      return phoneId;
    }
    return '';
  });
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const isFormInputValid = (form) => {
  const formValue = {
    tradingStarDate:
      (form.TradingStarDate && form.TradingStarDate.$invalid) ||
      (form.TradingStarDate &&
        form.TradingStarDate.$invalid &&
        form.TradingStarDate.$dirty),
    abn:
      (form.abn && form.abn.$invalid) ||
      (form.abn && form.abn.$invalid && form.abn.$dirty),
    businessName:
      (form.businessName && form.businessName.$invalid) ||
      (form.businessName &&
        form.businessName.$invalid &&
        form.businessName.$dirty),
    businessPhone:
      (form.businessPhone && form.businessPhone.$invalid) ||
      (form.businessPhone &&
        form.businessPhone.$invalid &&
        form.businessPhone.$dirty),
    country:
      (form.country && form.country.$invalid) ||
      (form.country && form.country.$invalid && form.country.$dirty),
    firstName:
      (form.firstName && form.firstName.$invalid) ||
      (form.firstName && form.firstName.$invalid && form.firstName.$dirty),
    homePhone:
      (form.homePhone && form.homePhone.$invalid) ||
      (form.homePhone && form.homePhone.$invalid && form.homePhone.$dirty),
    lastName:
      (form.lastName && form.lastName.$invalid) ||
      (form.lastName && form.lastName.$invalid && form.lastName.$dirty),
    maritalStatus:
      (form.maritalStatus && form.maritalStatus.$invalid) ||
      (form.maritalStatus &&
        form.maritalStatus.$invalid &&
        form.maritalStatus.$dirty),
    middleName:
      (form.middleName && form.middleName.$invalid) ||
      (form.middleName && form.middleName.$invalid && form.middleName.$dirty),
    mobileNumber:
      (form.mobileNumber && form.mobileNumber.$invalid) ||
      (form.mobileNumber &&
        form.mobileNumber.$invalid &&
        form.mobileNumber.$dirty),
    month:
      (form.month && form.month.$invalid) ||
      (form.month && form.month.$invalid && form.month.$dirty),
    mothersMaidenName:
      (form.mothersMaidenName && form.mothersMaidenName.$invalid) ||
      (form.mothersMaidenName &&
        form.mothersMaidenName.$invalid &&
        form.mothersMaidenName.$dirty),
    natureOfBusiness:
      (form.natureOfBusiness && form.natureOfBusiness.$invalid) ||
      (form.natureOfBusiness &&
        form.natureOfBusiness.$invalid &&
        form.natureOfBusiness.$dirty),
    preferredName:
      (form.natureOfBusiness && form.natureOfBusiness.$invalid) ||
      (form.preferredName &&
        form.preferredName.$invalid &&
        form.preferredName.$dirty),
    previousName:
      (form.previousName && form.previousName.$invalid) ||
      (form.previousName &&
        form.previousName.$invalid &&
        form.previousName.$dirty),
    year:
      (form.year && form.year.$invalid) ||
      (form.year && form.year.$invalid && form.year.$dirty),
  };
  return (
    formValue.tradingStarDate &&
    formValue.abn &&
    formValue.businessName &&
    formValue.businessPhone &&
    formValue.country &&
    formValue.firstName &&
    formValue.homePhone &&
    formValue.lastName &&
    formValue.maritalStatus &&
    formValue.middleName &&
    formValue.mobileNumber &&
    formValue.month &&
    formValue.mothersMaidenName &&
    formValue.natureOfBusiness &&
    formValue.preferredName &&
    formValue.previousName &&
    formValue.year
  );
};

export const setContactAgeValue = ({ $scope }) => (contactDOB) => {
  $scope.contact[0].AgeValue = $scope.dobToAgeGet(contactDOB);
};

export const onDeceasedClick = ({ $scope }) => (isDeceased) => {
  if (!$scope || !$scope.contact || !$scope.contact.length) {
    return;
  }
  $scope.contact[0].customerConsentOptions.ConsentMarketing = !isDeceased;
  if (!$scope.isCorporate) {
    $scope.setShowMarketingAutomation();
  }
  if (isDeceased) {
    $scope.contact[0].DOBReminder = true;
  }
};

export const getMarketingAutomationString = ({ $scope }) => {
  if (!$scope || !$scope.stayInTouchSub) {
    return;
  }
  const adviserSITSubs = get($scope, 'stayInTouchSubList', []);
  const filterSelectedList = adviserSITSubs.filter((data) => data.selected);
  const savedClientFlags = get($scope, 'selectedFlagsArray', []);

  const { isActive } = $scope.stayInTouchSub;
  const filteredSITFlags = isActive
    ? filterSelectedList.map((data) => data.value)
    : savedClientFlags.filter((item) => item !== NURTURE_FLAG.toString());

  const stayInTouchItemsList = get($scope, 'stayInTouchItemsList', []);
  const stayInTouchItems =
    !isActive && !$scope.selectedFlagsArray
      ? stayInTouchItemsList.map((item) => item.value)
      : filteredSITFlags;
  const marketingAutomationFlags = $scope.isNurtureSubbed
    ? [...stayInTouchItems, NURTURE_FLAG]
    : stayInTouchItems;

  return marketingAutomationFlags.toString();
};

export const checkAllStayInTouch = ({ $scope }) => () => {
  $scope.isStayInTouchAll =
    $scope.stayInTouchSubList.filter((data) => data.selected === false)
      .length === 0;
  $scope.selectCheckboxElement('stay-in-touch-all', $scope.isStayInTouchAll);
};

export const changeAllStayInTouch = ({ $scope }) => () => {
  $scope.isStayInTouchAll = !$scope.isStayInTouchAll;
  $scope.stayInTouchSubList.map((data) => {
    data.selected = $scope.isStayInTouchAll;
    $scope.selectCheckboxElement(`stay-in-touch-${data.value}`, data.selected);
    return data;
  });
};

export const selectStayInTouchItem = ({ $scope }) => (objItem) => {
  objItem.selected = !objItem.selected;
  $scope.checkAllStayInTouch();
};

export const selectCheckboxElement = ($timeout, $window) => (
  documentElementString,
  dataValue,
) => {
  const checkboxItem = $timeout(() => {
    // eslint-disable-next-line unicorn/prefer-query-selector
    const checkboxElem = $window.document.getElementById(documentElementString);
    if (checkboxElem) {
      checkboxElem.checked = dataValue;
    }
    $timeout.cancel(checkboxItem);
  }, 300);
};

export const isSubscribedToMarketingAutomation = ($scope) => {
  if (!$scope) {
    return;
  }
  const stayInTouchSub = get($scope.stayInTouchSub, 'isActive');
  const nurtureSub = get($scope.nurtureSub, 'isActive');
  return !!(nurtureSub || stayInTouchSub);
};

export const setSelectedSubscriptions = ({
  $scope,
  contactService,
  currentUserService,
  optionsService,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => () => {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getSubscription = (arrayList, subscriptionType) => {
    const filteredItem = arrayList.filter(
      (obj) => obj.subscriptionProductId === subscriptionType,
    );
    return filteredItem[0] || {};
  };
  const clientAdviserId = get($scope, 'contact[0].AllocatedAdviserID');
  if (!clientAdviserId) {
    return;
  }
  contactService.subscriptionInfo(clientAdviserId).then((response) => {
    const nurtureId = currentUserService.isNZ
      ? SUBSCRIPTIONTYPE.NURTURE_NZ
      : SUBSCRIPTIONTYPE.NURTURE;
    const stayInTouchId = currentUserService.isNZ
      ? SUBSCRIPTIONTYPE.MARKETING_AUTOMATION_NZ
      : SUBSCRIPTIONTYPE.MARKETING_AUTOMATION;
    const selectedFlags = get(
      $scope.contact,
      '0.MarketingAutomationFlags',
      null,
    );
    $scope.nurtureSub = getSubscription(response, nurtureId);
    $scope.stayInTouchSub = getSubscription(response, stayInTouchId);
    $scope.stayInTouchSelected = $scope.stayInTouchSub.subDetailIDs;
    if (!selectedFlags) {
      $scope.isNurtureSubbed = $scope.nurtureSub.isActive;
    } else {
      $scope.selectedFlagsArray = selectedFlags.split(',') || [];
      $scope.isNurtureSubbed = $scope.selectedFlagsArray.includes(
        NURTURE_FLAG.toString(),
      );
    }
    $scope.isNurtureDisabled = !$scope.isNurtureSubbed;

    $scope.showMarketingAutomation =
      $scope.marketingAutomationClientSubscribe &&
      $scope.contact[0].customerConsentOptions.ConsentMarketing &&
      !$scope.isCorporate &&
      isSubscribedToMarketingAutomation($scope);

    optionsService.getMarketingAutomation().then((obj) => {
      $scope.stayInTouchItemsList = obj;
      $scope.stayInTouchSubList = obj.filter((list) =>
        $scope.stayInTouchSelected.includes(list.value),
      );
      $scope.stayInTouchSubList.map((data) => {
        data.isTransactional = SIT_TRANSACTIONAL_ITEMS.includes(
          parseToInt10(data.value),
        );
        data.selected = !selectedFlags
          ? true
          : $scope.selectedFlagsArray.includes(data.value);
        $scope.selectCheckboxElement(
          `stay-in-touch-${data.value}`,
          data.selected,
        );
        return data;
      });
      $scope.checkAllStayInTouch();
    });
  });
};

export const setShowMarketingAutomation = ({ $scope }) => () => {
  if (!$scope.marketingAutomationClientSubscribe) {
    return;
  }
  const subscribed = isSubscribedToMarketingAutomation($scope);
  const marketingConsent = get(
    $scope,
    'contact[0].customerConsentOptions.ConsentMarketing',
  );
  $scope.showMarketingAutomation = subscribed ? marketingConsent : false;
};

export const showTransactionalEmailsOnly = ({ $scope }) => () => {
  const hasTransactionalSITEmails = $scope.stayInTouchSubList?.filter(
    (item) => item.isTransactional,
  )?.length;
  return (
    !$scope.showMarketingAutomation &&
    $scope.stayInTouchSub?.isActive &&
    hasTransactionalSITEmails
  );
};

export const intializeSubscriptions = ({
  $scope,
  contactService,
  currentUserService,
  optionsService,
  $window,
  $timeout,
}) => {
  if ($scope.isCorporate) {
    return;
  }
  $scope.changeAllStayInTouch = changeAllStayInTouch({ $scope });
  $scope.checkAllStayInTouch = checkAllStayInTouch({ $scope });
  $scope.selectStayInTouchItem = selectStayInTouchItem({ $scope });
  $scope.selectCheckboxElement = selectCheckboxElement($timeout, $window);
  $scope.setSelectedSubscriptions = setSelectedSubscriptions({
    $scope,
    contactService,
    currentUserService,
    optionsService,
  });
  $scope.setShowMarketingAutomation = setShowMarketingAutomation({ $scope });
  $scope.setSelectedSubscriptions();
};

export const setNurtureSubscription = ({ $scope, crmConfirmation }) => () => {
  if (!$scope.isNurtureSubbed) {
    $scope.isNurtureSubbed = true;
    return;
  }
  $scope.selectCheckboxElement('nurture', true);
  const unsubscribeNurture = () => {
    $scope.isNurtureSubbed = false;
    $scope.selectCheckboxElement('nurture', false);
  };

  crmConfirmation.open({
    type: 'warning',
    title: 'Are you sure?',
    description:
      'If this contact is unsubscribed from Nurture, you are not able to subscribe them again.',
    buttonText: 'Yes, I wish to proceed',
    onConfirm: unsubscribeNurture,
    showCancelButton: true,
    modalSize: 'md',
  });
};

export const openStayInTouchModal = ({
  $scope,
  $uibModal,
  currentUserService,
}) => (stayInTouchObj) => {
  const adviserFamilyId = currentUserService.familyId;
  const props = {
    familyId: adviserFamilyId,
    subscriptionData: stayInTouchObj,
    orderedById: adviserFamilyId,
  };
  const modalInstance = $uibModal.open({
    template: `<subscription-modal
                modal-instance="vm.modalInstance"
                family-id="vm.props.familyId"
                subscription-data="vm.props.subscriptionData"
                ordered-by-id="vm.props.orderedById">
              </subscription-modal>`,
    size: 'lg',
    backdrop: 'static',
    keyboard: false,
    windowClass: 'mc-subscription-modal',
    controller: 'CommonModalPlaceholderCtrl',
    controllerAs: 'vm',
    resolve: {
      props,
    },
  });
  modalInstance.result.then((response) => {
    if (response && response.getAllSubscribe) {
      $scope.setSelectedSubscriptions();
    }
  });
};

export const isMobileInvalid = ({
  $scope,
  mobileNumber,
  isMobileValid,
  toastError,
}) => {
  const isInvalid =
    !$scope.isInternationalMobileNumber &&
    (!mobileNumber || !isMobileValid(mobileNumber));
  if (isInvalid) {
    const message = mobileNumber
      ? 'Invalid mobile number'
      : 'Please provide a valid mobile number.';
    toastError(message);
    return true;
  }
  return false;
};

export const setClientPhoneForInternationalNumber = ({
  $scope,
  contactModelService,
  clientId,
}) => {
  if (!$scope.isInternationalPhoneNumbers) {
    return Promise.resolve();
  }
  const contact = get($scope, 'contact.0', {});
  const phonePayload = getPhoneSavePayload({
    contact,
    clientId,
    familyId: $scope.familyId,
  });
  return contactModelService.setClientContactNumber(phonePayload);
};
