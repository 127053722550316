import { feature, featureMapperForUI } from 'Common/config/feature';
import { getUserInfoData } from 'Common/utilities/user';
import {
  getRolloutFeatureFlags,
  currentUserInPilotRolloutFeatureFlags,
} from 'Common/utilities/rollout';
import { userInfoBuilderForUI } from 'Common/mappers/contact';
import { SETTINGS_TYPE_ID } from 'Common/constants/featureManagementSettings';
import { camelize } from './string';

const featuresList = {};
const enabledPilotFeatures = {};

const composeFeature = (value) => ({
  map: (func) => composeFeature(func(value)),
  output: () => value,
});

const constructFeatureList = (features = []) => {
  return features.reduce((accum, featureObj) => {
    accum[`${camelize(featureObj.Name)}`] = featureObj.IsEnabled;
    return accum;
  }, {});
};

const constructOverrideFeature = (features) => {
  const featureKey = Object.keys(feature);
  return featureKey.reduce((accum, value) => {
    accum[value] = features[feature[value]];
    return accum;
  }, {});
};

const featureObjectConstruct = (features) => {
  return {
    ...features,
    ...constructOverrideFeature(features),
  };
};

const featureMapping = (features) => {
  const user = userInfoBuilderForUI(getUserInfoData());

  return {
    ...features,
    ...featureMapperForUI(features, user),
  };
};

export function getFeaturesData() {
  return featuresList;
}

export function getEnabledPilotFromFeature(features = []) {
  return features.filter(
    (featureItem) =>
      featureItem.IsEnabled &&
      featureItem.SettingTypeId === SETTINGS_TYPE_ID.DEPENDENT_ON_ADVISER_ORG,
  );
}

export function getPilotFeatureKeyList() {
  return Object.keys(enabledPilotFeatures).reduce((accum, featureKey) => {
    return [...accum, featureKey];
  }, []);
}

export function setPilotFeaturesData(features) {
  const pilotFeatures = composeFeature(features)
    .map(getEnabledPilotFromFeature)
    .map(constructFeatureList)
    .output();
  Object.assign(
    enabledPilotFeatures,
    pilotFeatures,
    currentUserInPilotRolloutFeatureFlags,
  );
  return enabledPilotFeatures;
}

export function setFeaturesData(features) {
  /* eslint-disable angular/timeout-service */
  const featureListData = composeFeature(features)
    .map(constructFeatureList)
    .map(featureObjectConstruct)
    .map(featureMapping)
    .output();
  Object.assign(featuresList, featureListData, getRolloutFeatureFlags());
  setPilotFeaturesData(features);
}

export function setFeatureFlag(scope, config, variableName, flagName) {
  const isIncompleteParams =
    !scope || !config || !variableName || !flagName || !config.feature;
  if (isIncompleteParams) {
    return;
  }
  scope[variableName] = !!config.feature[flagName];
  return scope;
}
