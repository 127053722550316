import { appendEmptySelect } from 'Common/utilities/options';

export default class AllocatedAdviserCtrl {
  constructor(insuranceProfilerService) {
    'ngInject';

    this.insuranceProfilerService = insuranceProfilerService;
    this.listAllocatedAdviser = [];
  }

  $onInit() {
    this.getAllocatedAdvisers();
  }

  getAllocatedAdvisers() {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const mapOptionsAdviser = (data) => {
      return {
        name: data.fullName,
        value: data.brokerId,
      };
    };
    this.insuranceProfilerService.getAllocatedAdviser().then((response) => {
      const adviserList = response.map(mapOptionsAdviser) || [];
      this.listAllocatedAdviser = appendEmptySelect(adviserList, {
        name: 'Please select adviser',
        value: 0,
      });
      this.adviser = 0;
    });
  }
}
