import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    .controller('LinkToLoanCtrl', function LinkToLoanCtrl(
      $scope,
      $rootScope,
      $uibModal,
      SweetAlert,
      $filter,
      pipelineService,
      contactService,
      $stateParams,
      loanScenarioService,
      toaster,
      familyID,
      cardID,
      familyName,
      $uibModalInstance,
    ) {
      $scope.familyId = familyID;
      $scope.familyName = familyName;
      $scope.hasLoanApp = false;
      $scope.noLoanApp = false;

      $scope.getLoanApplications = () => {
        loanScenarioService.scenarioListGet(familyID).then((response) => {
          $scope.loanApplications = response.data;
          if ($scope.loanApplications.length > 0) {
            $scope.hasLoanApp = true;
            $scope.loanApplications = _.filter($scope.loanApplications, (l) => {
              return l.StatusName === 'Ongoing';
            });
          } else {
            $scope.noLoanApp = true;
            return;
          }

          for (let i = 0; i < $scope.loanApplications.length; i++) {
            $scope.formattedDate = $filter('date')(
              $scope.loanApplications[i].DateCreated,
              'dd MMM yyyy',
            );

            let textField;
            if (!$scope.loanApplications[i].LenderName) {
              textField = $scope.formattedDate;
            } else {
              textField = `${$scope.loanApplications[i].LenderName} ${$scope.formattedDate}`;
            }
            $scope.loanApplications[i].loanAppName = textField;
          }
          $scope.loanApplications.splice(0, 0, {
            LoanScenarioId: -1,
            loanAppName: 'Select Loan Application',
          });
          $scope.loanApp = $scope.loanApplications[0];
        });
      };
      $scope.saveLoanApp = (loanApp) => {
        if (loanApp.LoanScenarioId === '-1') {
          toaster.pop('error', 'Error', 'Yikes! Select valid Loan Application');
          return;
        }
        $scope.loanAppObj = {};
        $scope.loanAppObj.pipelineCardsID = cardID;
        $scope.loanAppObj.loanScenarioID = loanApp.LoanScenarioId;
        pipelineService.PipelineCardsLoanScenarioSet($scope.loanAppObj).then(
          () => {
            toaster.pop('success', 'Saved', 'Saved successfully.');
            $rootScope.$broadcast('getAllCards', {
              advisersId: 0,
              labelId: 0,
              probability: 'all',
            });
            $rootScope.$broadcast('getListData', {
              isLeads: false,
              statusId: 0,
              advisersId: 0,
              labelId: 0,
              probability: 'all',
            });
            $scope.cancel();
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          },
        );
      };
      $scope.getLoanApplications();
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.createLoanAppPipeline = () => {
        $rootScope.createLoanAppPipeline = true;
      };
    });
