class DealViewTaskService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'deal-view-tasks';
  }

  getTasksList(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/lists`, params);
  }

  setFiltersSettings(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/filters`, data);
  }

  getFiltersSettings(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/filters`, params);
  }

  setColumnsSettings(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/columns`, data);
  }

  getColumnsSettings() {
    return this.httpClient.get(`${this.apiBaseUrl}/columns`);
  }
}

export default DealViewTaskService;
