import { get } from 'lodash';
import { isMobileNumberValid, isMobileValid } from 'Common/utilities/mobile';
import { alertInfo, toastWarning, toastError } from 'Common/utilities/alert';
import { isValidEmailAddress } from 'Common/utilities/string';
import moment from 'moment';
import { DD_MM_YYYY_MOMENT_FORMAT } from 'Common/constants/dateConfigs';
import { ADDRESS_ERROR } from 'Common/constants/promptMessages';
import { phoneType } from 'Common/constants/phoneType';

const ONE_LOAN_APP = 1;

export const executeNewZealandCondition = ({ $scope, currentUserService }) => {
  if (currentUserService.isNZ && !$scope.isClientSearchReadOnly) {
    $scope.formModel.AdviserID = '';
    $scope.selectedClientName = '';
    $scope.blankSendToForOther();
  } else {
    $scope.isEmailContactValid = true;
  }
};

export const findAndGetTheValue = (list, findObject, propertyToGet) => {
  const findVariable = list.find(
    (item) => item[findObject.property] === findObject.value,
  );

  return (findVariable && findVariable[propertyToGet]) || '';
};

export const mapContactDetailList = (list) => {
  return list.map((contact) => {
    contact.isEditActive = false;
    return contact;
  });
};

export const mapEmail = (email) => {
  return [
    {
      Type: 'Email',
      EmailAddress:
        findAndGetTheValue(
          email,
          { property: 'Type', value: 'Email' },
          'EmailAddress',
        ) || null,
    },
  ];
};

export const mapPhone = ({ currentUserService, phone }) => {
  return [
    {
      Type: 'Mobile',
      Number:
        findAndGetTheValue(
          phone,
          { property: 'Type', value: 'Mobile' },
          'Number',
        ) || null,
      CountryCode: (
        findAndGetTheValue(
          phone,
          { property: 'Type', value: 'Mobile' },
          'CountryCode',
        ) || currentUserService.countryCode
      ).toLowerCase(),
    },
    {
      Type: 'Home',
      Number:
        findAndGetTheValue(
          phone,
          { property: 'Type', value: 'Home' },
          'Number',
        ) || null,
      CountryCode: (
        findAndGetTheValue(
          phone,
          { property: 'Type', value: 'Home' },
          'CountryCode',
        ) || currentUserService.countryCode
      ).toLowerCase(),
    },
    {
      Type: 'Work',
      Number:
        findAndGetTheValue(
          phone,
          { property: 'Type', value: 'Work' },
          'Number',
        ) || null,
      CountryCode: (
        findAndGetTheValue(
          phone,
          { property: 'Type', value: 'Work' },
          'CountryCode',
        ) || currentUserService.countryCode
      ).toLowerCase(),
    },
  ];
};

export const clientContactListProcedure = ($scope, email, phone) => {
  $scope.isShowPhoneCountBanner = !phone.length;
  const mappedEmail = mapEmail(email);
  const mappedPhone = mapPhone({
    currentUserService: $scope.currentUserService,
    phone,
  });

  $scope.contactDetailList = mapContactDetailList([
    ...mappedEmail,
    ...mappedPhone,
  ]);
};

export const emailAndPhoneValidation = ($scope) => {
  const { Email, Phone: phone } = $scope.clientData;

  clientContactListProcedure($scope, Email, phone);

  const getEmail = Email || [];
  const getMobile =
    mapPhone({
      currentUserService: $scope.currentUserService,
      phone,
    }) || [];

  const foundEmail = getEmail.find((email) => email.EmailAddress);
  $scope.formModel.Email = (foundEmail && foundEmail.EmailAddress) || '';

  const foundMobile = getMobile.find((mobile) => mobile.Number);
  $scope.formModel.Mobile = (foundMobile && foundMobile.Number) || '';
  $scope.formModel.MobilePhoneCountryCode = get(foundMobile, 'CountryCode', '');

  $scope.checkForValidationPhoneOnly(foundMobile);
};

export const processEntityPhones = ({
  $scope,
  contactModelService,
  currentUserService,
  familyId,
  data,
}) => {
  if (!$scope.isIntlPhoneNumbers) {
    return Promise.resolve(data);
  }
  return contactModelService
    .getEntityContactNumber({ familyId })
    .then((entityNumbers) => {
      data.Phone &&
        data.Phone.map((phone) => {
          const field = phone.Type && phone.Type.toLowerCase();
          const number = get(entityNumbers, `${field}.number`, '');
          const countryCode = get(
            entityNumbers,
            `${field}.countryCode`,
            currentUserService.countryCode || '',
          ).toLowerCase();
          if (number) {
            phone.Number = number;
          }
          phone.CountryCode = countryCode;
          return phone;
        });
      return data;
    });
};

export const companyEmailPhoneValidation = ({
  $scope,
  contactService,
  contactModelService,
  currentUserService,
  familyId,
  familyData,
}) => {
  return contactService
    .organisationInfoGet(familyId)
    .then(({ data }) => {
      return processEntityPhones({
        $scope,
        contactModelService,
        currentUserService,
        familyId,
        data,
      });
    })
    .then((data) => {
      $scope.clientData = data || {};
      $scope.clientData.FamilyId = familyId;
      // eslint-disable-next-line sonarjs/no-extra-arguments
      emailAndPhoneValidation($scope, clientContactListProcedure);
      const BusinessPhone =
        ($scope.clientData.Phone &&
          $scope.clientData.Phone.find(
            (phone) => phone.Type === phoneType.mobile,
          )) ||
        {};
      return {
        ...familyData,
        BusinessPhone: BusinessPhone.Number,
      };
    });
};

export const isValidContactDetail = ({
  $scope,
  type,
  contactValue,
  numberType,
}) => {
  const invalidNumberDefaultMessage = `Only numeric characters are permitted in mobile number fields. Please remove any spaces or special characters to proceed.`;

  const mobileErrorMessage =
    $scope.isPhoneNumberCountryCodeNZ && numberType.toLowerCase() === 'mobile'
      ? `Please enter a valid ${numberType.toLowerCase()} number. Remove any spaces or special characters (+ is allowed) and resubmit.`
      : invalidNumberDefaultMessage;

  const numberValid = $scope.isPhoneNumberCountryCodeNZ
    ? isMobileNumberValid(contactValue)
    : isMobileValid(contactValue);

  if (type === 'number') {
    if (contactValue && !numberValid) {
      toastWarning(mobileErrorMessage);
      return false;
    }
    $scope.isShowPhoneCountBanner = false;
  }
  if (type === 'email' && contactValue && !isValidEmailAddress(contactValue)) {
    toastError('Invalid Email.');
    return false;
  }
  return true;
};

export const toggleLoader = ({ $scope, value }) => {
  $scope.showLoader = !!value;
};

export const setIsShowClientContactDetails = ({ $scope, value }) => {
  $scope.isShowClientContactDetails = !!value;
};

export const getNextGenLoanList = ({
  $scope,
  contactService,
  loanAppSharedData,
  familyId,
}) => {
  $scope.nextGenLoanList = [];
  if (!familyId) {
    return;
  }
  $scope.isCalledGetNextGenLoan = true;
  const includeOpportunity = false;
  const excludeSecurityInfo = false;
  const showOnlyLoansWithNextGenData = true;
  contactService
    .loanListGet(
      familyId,
      includeOpportunity,
      excludeSecurityInfo,
      showOnlyLoansWithNextGenData,
    )
    .then(({ data }) => {
      toggleLoader({ $scope });
      $scope.isShowClientContactDetails = !!familyId;
      if (!data || !data.length) {
        alertInfo(
          `This contact doesn't have loan that can be shared`,
          'Warning',
        );
        return;
      }
      $scope.nextGenLoanList = loanAppSharedData.distinguishPartyForLendingTab(
        data,
      );
      if ($scope.nextGenLoanList.length !== ONE_LOAN_APP) {
        return;
      }
      $scope.nextGenLoanList[0] = {
        ...$scope.nextGenLoanList[0],
        selected: true,
      };
    });
};

export const formSetDataForWealthMarket = ({ $scope }) => {
  $scope.formModel = {
    ...$scope.formModel,
    NextGenLoans: [],
  };
  if (!$scope.nextGenLoanList || !$scope.nextGenLoanList.length) {
    return;
  }
  $scope.formModel.NextGenLoans = $scope.nextGenLoanList.reduce(
    (initial, loan) => {
      if (!loan.selected) {
        return initial;
      }
      return [
        ...initial,
        {
          LoanID: loan.LoanID,
          LoanScenarioID: loan.LoanScenarioID,
          Borrower: loan.Borrower,
          LendingCategoryName: loan.LendingCategoryName,
          LoanAmount: loan.LoanAmount,
          Lender: loan.Lender,
        },
      ];
    },
    [],
  );
};

export const formatClientInfoForKiwi = ({ $scope }) => {
  const DEFAULT_SELECTED = 0;
  const formattedClient = (clientInfo = {}) => {
    return {
      formattedAddress:
        clientInfo.Address.map((address) => address.formatted_address).join(
          ',',
        ) || '',
      dateOfBirth:
        moment(clientInfo.DateOfBirth).format(DD_MM_YYYY_MOMENT_FORMAT) || '',
      kiwiFund: DEFAULT_SELECTED,
      kiwiProvider: DEFAULT_SELECTED,
    };
  };
  $scope.clientForKiwi =
    $scope.clientData && formattedClient($scope.clientData);
};

export const initializeAddressOverhaul = ({ $scope, configService }) => {
  $scope.isAddressOverhaulEnabled = configService.feature.addressOverhaul;
  $scope.address = {};
  $scope.selectedContactFamilyId = 0;
  $scope.handleUpdateAddress = ({ address }) => {
    $scope.address = address;
  };
  $scope.addressLookupHandler = ({ reloadExistingAddress }) => {
    $scope.reloadExistingAddress = reloadExistingAddress;
  };
};

export const saveAddress = ({
  $scope,
  addressLookupService,
  generalService,
}) => {
  const isAddressAvailable =
    ($scope.isAddressOverhaulEnabled && $scope.address.displayDetails) || false;
  if (!isAddressAvailable) {
    return Promise.resolve({});
  }
  return addressLookupService
    .convertToAddressFormat($scope.address)
    .then((address) => {
      $scope.address = address;
      return generalService.addressDetailsSet($scope.address);
    })
    .then(({ data: addressId }) => {
      if (!addressId) {
        toastError(ADDRESS_ERROR.CLIENT);
        return Promise.reject(ADDRESS_ERROR.CLIENT);
      }
      $scope.formModel.AddressID = addressId;
      return addressId;
    });
};

export const validateNumber = ({ $scope }) => ({
  data,
  contact,
  allowUpdate,
}) => {
  const invalidParams = !$scope || !data || !contact;
  if (invalidParams) {
    return;
  }
  if (get(data, 'hasError')) {
    toastWarning('Invalid number');
    return;
  }

  allowUpdate &&
    $scope.updateClientContactDetails('number', contact.Number, contact.Type);
  $scope.toggleEdit(contact.Type, contact.isEditActive);
};

export const formatAdviser = (adviser) => {
  return adviser
    ? { FullName: adviser.fullName, FamilyId: adviser.familyId }
    : {};
};
