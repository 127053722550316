import template from './assetFinanceSecurities.html';
import controller from './assetFinanceSecuritiesCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    loanAppId: '<',
    loanAppFormWarning: '<',
  },
};
