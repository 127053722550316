import template from './clientLookup.html';
import controller from './clientLookupCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    name: '@',
    isRequired: '<',
    selectedClient: '<',
    onSelect: '&',
  },
};
