export default class NoteTypeFormCtrl {
  $onInit() {
    this.noteType = { ...this.currentNoteType };
  }

  onSaveNoteType() {
    this.noteTypeForm.$setSubmitted();
    if (this.noteTypeForm.$invalid) {
      return;
    }
    this.onSave({ noteType: { ...this.noteType } });
  }
}
