import angular from 'angular';
import workbenchSuccess from 'Components/workbench/successModal';

export const loadModule = () =>
  angular.module('app').directive('downloadHtmlAsWord', [
    'contactService',
    'contactModelService',
    'mailTemplateService',
    '$timeout',
    '$document',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function downloadHtmlAsWord(
      contactService,
      contactModelService,
      mailTemplateService,
      $timeout,
      $document,
    ) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          html: '<htmlContent',
          buttonText: '@',
          downloadingText: '@',
          class: '@',
          onInvalidLoan: '&',
          loanToMerge: '<',
          isWorkbench: '<',
          workbenchData: '<',
          onDownloadSuccess: '&',
        },
        template:
          '<button type="button" class="{{class}}" ng-disabled="!html"><img class="margin-right-5" class="padding-left-5" ng-src="/assets/images/icon/icon_send.svg" height="11" width="11"> {{buttonText}}</button>',
        // eslint-disable-next-line sonarjs/cognitive-complexity
        link: (scope, elem, attr) => {
          const convertHTMLtoRTF = (htmlContent, originalHtml) => {
            contactService
              .convertContentHtmlToRTF({ htmlString: htmlContent })
              .then((response) => {
                if (!response) {
                  return;
                }
                const element = angular.element('<a></a>')[0];
                element.setAttribute(
                  'href',
                  `data:${response.contentType};base64,${response.documentContent}`,
                );
                element.setAttribute('download', response.name);

                element.style.display = 'none';
                const eventTimeout = $timeout(() => {
                  const body = $document.find('body')[0];
                  body.append(element);
                  element.click();
                  element.remove();
                  attr.$set('disabled', false);
                  if (originalHtml && originalHtml.length) {
                    elem.html(originalHtml);
                  }
                  if (scope.isWorkbench) {
                    workbenchSuccess(
                      'Mail Downloaded Successfully',
                      scope.onDownloadSuccess,
                    );
                  }
                  $timeout.cancel(eventTimeout);
                }, 100);
              });
          };

          const downloadAsWord = () => {
            const originalHtml = elem.html();
            if (scope.downloadingText) {
              elem[0].lastChild.nodeValue = scope.downloadingText;
            }
            attr.$set('disabled', true);

            const htmlContent = scope.html.replace(
              new RegExp('&nbsp;', 'g'),
              ' ',
            );

            if (scope.isWorkbench) {
              const mailData = {
                familyId: scope.workbenchData.familyId,
                title: `Mail Sent - ${scope.workbenchData.UserTemplateName}`,
                templateName: scope.workbenchData.UserTemplateName,
                workbenchLoanId: scope.workbenchData.workbenchLoanId,
                workbenchQuoteID: scope.workbenchData.workbenchQuoteID,
                htmlContent: encodeURIComponent(htmlContent),
                communicationID: 0,
              };
              contactModelService.mailTemplateSet(mailData).then(() => {
                convertHTMLtoRTF(htmlContent, originalHtml);
              });
            } else {
              convertHTMLtoRTF(htmlContent, originalHtml);
            }
          };

          const clickListener = () => {
            mailTemplateService
              .isLoanForMailContentValid(scope.html, scope.loanToMerge)
              .then((isValid) => {
                if (isValid) {
                  downloadAsWord();
                } else if (scope.onInvalidLoan) {
                  scope.onInvalidLoan();
                }
              });
          };

          elem.on('click', clickListener);
          scope.$on('$destroy', () => {
            elem.off('click', clickListener);
          });
        },
      };
    },
  ]);
