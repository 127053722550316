import { ADDRESS_FIELD } from 'Common/constants/addressField';
import { ADDRESS_TYPE } from 'Common/constants/addressModules';

export function formatAddress(address, useAddressFormat = false) {
  return Object.keys(ADDRESS_FIELD).reduce((accum, key) => {
    if (key === 'COUNTRY' && !useAddressFormat) {
      return accum;
    }
    const field = ADDRESS_FIELD[key];
    const fieldValue = address[field] && address[field].trim();
    if (fieldValue) {
      const appendCommaFields = [
        ADDRESS_FIELD.BUILDING_NAME,
        ADDRESS_FIELD.ADDRESS_LINE_1,
        ADDRESS_FIELD.ADDRESS_LINE_2,
        ADDRESS_FIELD.EXCHANGE,
        address.StreetType
          ? ADDRESS_FIELD.STREET_TYPE
          : ADDRESS_FIELD.STREET_NAME,
        useAddressFormat ? ADDRESS_FIELD.POST_CODE : ADDRESS_FIELD.STATE,
      ];
      return appendCommaFields.includes(field)
        ? `${accum} ${fieldValue},`
        : `${accum} ${fieldValue}`;
    }
    return accum;
  }, '');
}
export function openDate(datePopUp, dateField) {
  if (datePopUp && dateField) {
    datePopUp[dateField] = !datePopUp[dateField];
  }
}
export function addressToVM(address) {
  if (!address) {
    return;
  }

  const {
    route,
    street_address: streetAddress,
    street_number: streetNumber,
    locality,
    stateShort_Name: stateShortName,
    postal_code: postCode,
  } = address;

  return {
    route,
    streetAddress,
    streetNumber,
    locality,
    stateShortName,
    postCode,
  };
}

export function getCurrentAddress(personAddresses) {
  return (
    personAddresses &&
    personAddresses.find((address) => {
      return parseInt(address.TypeId, 10) === ADDRESS_TYPE.CURRENT_ADDRESS;
    })
  );
}

export const mapBasicAddress = (address) => {
  if (!address) {
    return;
  }

  const hasOverhaulStreet = !!(address.StreetName && address.StreetType);
  const streetName = hasOverhaulStreet
    ? `${address.StreetName} ${address.StreetType}`
    : address.street_address || address.route;

  return {
    streetNumber: address.street_number,
    streetName,
    suburb: address.locality,
    state: address.stateShort_Name,
    postCode: address.postal_code,
    unit: address.subpremise,
  };
};
