import { pluralize } from 'Common/utilities/string';

export default class TableTotalAmountCtrl {
  constructor(currentUserService) {
    'ngInject';

    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.crmCurrency = this.currentUserService.brokerRegionalization.currencySign;
  }

  initValues() {
    this.itemsPerPage = this.pageLength || 0;
    this.totalItems = pluralize(this.totalRecords || 0, 'record');
    this.pageTotalAmount = this.totalAmountPerPage || 0;
    this.overallTotalAmount = this.totalAmountOverall || 0;
  }

  $onChanges() {
    this.initValues();
  }
}
