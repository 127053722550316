import orderBy from 'lodash/orderBy';
import { addHelloBookPipelineCategory } from 'Scenes/helloBook/scenes/helloBookPreviewGenerator/util/helloBookPreviewGeneratorCtrl';
import { helloBookFamilyLoanForUI } from 'Common/mappers/helloBook';
import { loanBuilderForUI } from 'Common/mappers/loanScenario';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';

const defaultDeal = {
  loanScenarioId: 0,
  dealName: 'Create New Loan Application',
};

export default class HelloPackDealOptionCtrl {
  constructor(
    loanOpportunityService,
    loanService,
    currentUserService,
    uiService,
  ) {
    'ngInject';

    this.loanOpportunityService = loanOpportunityService;
    this.loanService = loanService;
    this.currentUserService = currentUserService;
    this.uiService = uiService;
  }

  $onInit() {
    this.selectedDeal = null;
    this.deals = [];
    this.getLoanList();
    if (this.handler) {
      this.handler.addDeal = (deal) => {
        this.deals = [...this.deals, deal];
        this.selectedDeal = deal;
        this.onDealChange({
          loanApplicationId: this.selectedDeal.loanScenarioId,
          loanApplicationTitle: this.selectedDeal.dealName,
        });
      };
      this.handler.fetchingCallback = () => {
        this.isFetching = false;
      };
    }
  }

  onDealChange({ loanApplicationId, loanApplicationTitle }) {
    this.isFetching = !!loanApplicationId;
    this.onChange({ loanApplicationId, loanApplicationTitle });
  }

  dealOptionsMapper(validLoans) {
    return validLoans.map((loan) => {
      const isMergeLeadAndOpportunities = this.uiService.viewOfAfileTurnedOn;
      const loanWithCategory = addHelloBookPipelineCategory({
        loan,
        isMergeLeadAndOpportunities,
      });
      if (
        this.isMultiple &&
        loan.loanScenarioId === Number(this.loanScenarioId)
      ) {
        this.handleMultipleDeal(loan);
        loan.isChecked = true;
        loan.isDisabled = true;
      }
      return helloBookFamilyLoanForUI({
        loan: loanWithCategory,
        isMergeLeadAndOpportunities,
        userCountryCode: this.currentUserService.countryCode,
      });
    });
  }

  getFactFindLoanListOptions() {
    this.loanOpportunityService.getLoanList(this.familyId).then((response) => {
      if (!response.length) {
        this.handleEmptyDeal();
        return;
      }
      const disableFactFindStatuses = [
        LOAN_STATUS.IN_PROGRESS.LODGED,
        LOAN_STATUS.IN_PROGRESS.PRE_APPROVED,
        LOAN_STATUS.EXISTING.SETTLED,
        LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH,
        LOAN_STATUS.PREVIOUS.REPAID,
        LOAN_STATUS.PREVIOUS.DECLINED,
      ];
      const validLoans = response.filter(
        (loan) =>
          loan.loanScenarioId &&
          !disableFactFindStatuses.includes(loan.loanStatusId),
      );

      const loanList = this.dealOptionsMapper(validLoans);
      this.setDealOptions(loanList);
    });
  }

  setDealOptions(loanList) {
    this.deals = orderBy(loanList, ['createdDate'], ['desc']) || [];
    this.handleEmptyDeal();
    this.setDefaultLeadLoan();
  }

  getCreditGuideDealsOptions() {
    this.loanService.loanListGet(this.familyId).then((response) => {
      const activeDeals = [
        ...response.opportunityLoans,
        ...response.inprogressLoans,
      ].map(loanBuilderForUI);

      const loanList = this.dealOptionsMapper(activeDeals);
      this.setDealOptions(loanList);
    });
  }

  getLoanList() {
    if (!this.isMultiple) {
      this.getFactFindLoanListOptions();
    } else {
      this.getCreditGuideDealsOptions();
    }
  }

  handleEmptyDeal() {
    if (this.uiService.viewOfAfileTurnedOn) {
      return;
    }
    this.selectedDeal = defaultDeal;
    this.deals = [defaultDeal, ...this.deals];
    this.setDefaultLeadLoan();
  }

  setDefaultLeadLoan() {
    const [firstLoan] = this.deals;
    if (this.currentUserService.isNZ) {
      this.selectedDeal = firstLoan;
      return;
    }
    const leadLoan = this.deals.find((loan) => loan.isLead) || firstLoan || {};
    this.selectedDeal = leadLoan;
    const isEmptyLoan =
      !this.uiService.viewOfAfileTurnedOn && this.deals.length <= 1;
    this.onChange({
      loanApplicationId: leadLoan.loanScenarioId,
      loanApplicationTitle: leadLoan.dealName,
      isEmptyLoan,
    });
  }

  handleMultipleDeal(data) {
    this.onChange({ data: data, isRemove: data.isChecked });
  }
}
