import {
  maximumLVRBuilderForUI,
  resourceMyDocumentsBuilderForUI,
  resourceMyDocumentsBuilderForMyCRM,
  offsetCalculatorBuilderForUI,
  educationBuilderForUI,
  educationBuilderForMyCRM,
  qualificationBuilderForUI,
  qualificationBuilderForMyCRM,
  qualificationPresetBuilderForUI,
  corporateComplaintsBuilderForUI,
} from 'Common/mappers/loanTools';
import { importantDocumentBuilderForUI } from 'Common/mappers/importantDocument';

class LoanToolsService {
  constructor(httpClient, configService) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'LoanTools';
    this.configService = configService;
  }

  getLendersList() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanProviderLendersGet`);
  }

  getMaximumLVRList(loanAmount, repaymentType, intention) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/LenderInfoMaxLVRGet`, {
        loanAmount,
        repaymentType,
        intention,
      })
      .then((response) => response.data.map(maximumLVRBuilderForUI));
  }

  LocalizationGet(module) {
    // TODO: move to generalService
    return this.httpClient.get(`LocalizationGet`, { module });
  }

  getImportantDocs(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/LoanProviderImportantDocumentsGet`, params)
      .then((response) =>
        response.data ? response.data.map(importantDocumentBuilderForUI) : [],
      );
  }

  LoanRateGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanRateGet`);
  }

  getLenderContactDetail(providerID) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanProviderContactDetailGet`,
      { providerID },
    );
  }

  getLoanProviderKeyRelationships(providerID) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanProviderKeyRelationshipsGet`,
      { providerID },
    );
  }

  getLenderRates(providerIDs) {
    // TODO: fix the query string of providerIDs
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanProviderInterestRatesGet?${providerIDs}`,
    );
  }

  getRepaymentCalculations(params) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/RepaymentCalculationsGet`,
      params,
    );
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  getAdditionalRepaymentCalculations(params) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/RepaymentCalculationsGet`,
      params,
    );
  }

  getSavingGoalCalculations(params) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/SavingCalculationsGet`,
      params,
    );
  }

  getCompareLoanCalculations(data) {
    const {
      loanProviderMine,
      loanMine,
      loanTermMine,
      fixedRateMine,
      fixedPeriodMine,
      ongoingRateMine,
      upFrontFeesMine,
      otherFeesMine,
      otherFeesFrequencyMine,
      dischargedFeesMine,
      loanProviderOther,
      loanOther,
      loanTermOther,
      fixedRateOther,
      fixedPeriodOther,
      ongoingRateOther,
      upFrontFeesOther,
      otherFeesOther,
      otherFeesFrequencyOther,
      dischargedFeesOther,
      upFrontCashMine,
      upFrontCashOther,
    } = data;
    const params = {
      [`mortgageProviders.myLender.provider`]: loanProviderMine,
      [`mortgageProviders.myLender.loanAmount`]: loanMine,
      [`mortgageProviders.myLender.loanTerm`]: loanTermMine,
      [`mortgageProviders.myLender.fixedRate`]: fixedRateMine,
      [`mortgageProviders.myLender.fixedPeriod`]: fixedPeriodMine,
      [`mortgageProviders.myLender.onGoingRate`]: ongoingRateMine,
      [`mortgageProviders.myLender.upfrontFrees`]: upFrontFeesMine,
      [`mortgageProviders.myLender.otherFees`]: otherFeesMine,
      [`mortgageProviders.myLender.otherFeesFrequency`]: otherFeesFrequencyMine,
      [`mortgageProviders.myLender.dischargedFees`]: dischargedFeesMine,
      [`mortgageProviders.otherLender.provider`]: loanProviderOther,
      [`mortgageProviders.otherLender.loanAmount`]: loanOther,
      [`mortgageProviders.otherLender.loanTerm`]: loanTermOther,
      [`mortgageProviders.otherLender.fixedRate`]: fixedRateOther,
      [`mortgageProviders.otherLender.fixedPeriod`]: fixedPeriodOther,
      [`mortgageProviders.otherLender.onGoingRate`]: ongoingRateOther,
      [`mortgageProviders.otherLender.upfrontFrees`]: upFrontFeesOther,
      [`mortgageProviders.otherLender.otherFees`]: otherFeesOther,
      [`mortgageProviders.otherLender.otherFeesFrequency`]: otherFeesFrequencyOther,
      [`mortgageProviders.otherLender.dischargedFees`]: dischargedFeesOther,
      [`mortgageProviders.myLender.upfrontCash`]: upFrontCashMine,
      [`mortgageProviders.otherLender.upfrontCash`]: upFrontCashOther,
    };
    return this.httpClient.get(
      `${this.apiBaseUrl}/LoanComparisonCalculatorGet`,
      params,
    );
  }

  hemCalculatorInfoGet(data) {
    return this.httpClient.get(`${this.apiBaseUrl}/hem-details`, data);
  }

  hemCalculatorStatesGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/HEMCalculatorStatesGet`);
  }

  hemMaritalStatusGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/HEMMaritalStatusGet`);
  }

  saveKeyContact(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/LoanProviderKeyRelationshipsSet`,
      data,
    );
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  updateKeyContact(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/LoanProviderKeyRelationshipsSet`,
      data,
    );
  }

  deleteKeyContact(keyRelationshipID) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/LoanProviderKeyRelationshipsDelete`,
      { keyRelationshipID },
    );
  }

  LoanProviderDocumentsDelete(iD) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/LoanProviderDocumentsDelete`,
      { iD },
    );
  }

  OffsetCalculationsSet(params) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/OffsetCalculator`, params)
      .then((response) => offsetCalculatorBuilderForUI(response.data));
  }

  LoanProviderDocumentsSlimSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/LoanProviderDocumentsSlimSet`,
      data,
    );
  }

  resourceMyDocumentsSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/Resources/MyDocuments`,
      resourceMyDocumentsBuilderForMyCRM(data),
    );
  }

  resourceMyDocumentsGet(documentFileId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/Resources/MyDocuments`, { documentFileId })
      .then((response) => response.data.map(resourceMyDocumentsBuilderForUI));
  }

  resourceMyDocumentsSearch(searchName) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/Resources/MyDocuments`, { searchName })
      .then((response) => response.data.map(resourceMyDocumentsBuilderForUI));
  }

  resourceMyDocumentsDelete(documentFileId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/Resources/MyDocuments`, {
      documentFileId,
    });
  }

  resourceTemplatesGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Resources/Templates`);
  }

  resourceTemplatesSearch(searchName) {
    return this.httpClient.get(`${this.apiBaseUrl}/Resources/Templates`, {
      searchName,
    });
  }

  resourceEducationGet(educationId, pageSize, pageNumber, sortOrder) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/resources/education`, {
        educationId,
        pageSize,
        pageNumber,
        sortOrder,
      })
      .then(({ data: { education = [], totalRecordCount = 0 } }) =>
        this.configService.feature.educationCPDEnhancements
          ? { data: education.map(educationBuilderForUI), totalRecordCount }
          : { data: education },
      );
  }

  resourceEducationSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/resources/education`,
      this.configService.feature.educationCPDEnhancements
        ? educationBuilderForMyCRM(data)
        : data,
    );
  }

  resourceEducationDelete(educationId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/resources/education`, {
      educationId,
    });
  }

  resourceEducationQualificationGet(
    qualificationId,
    pageSize,
    pageNumber,
    sortOrder,
  ) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/resources/education-qualification`, {
        qualificationId,
        pageSize,
        pageNumber,
        sortOrder,
      })
      .then(({ data: { QualificationList = [], totalRecordCount = 0 } }) => ({
        data: QualificationList.map(qualificationBuilderForUI),
        totalRecordCount,
      }));
  }

  resourceEducationQualificationSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/resources/education-qualification`,
      qualificationBuilderForMyCRM(data),
    );
  }

  resourceEducationQualificationDelete(qualificationId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/resources/education-qualification`,
      {
        qualificationId,
      },
    );
  }

  resourceQualificationPresetListGet() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/resources/education-qualification-preset-list`)
      .then(({ data }) => (data ? qualificationPresetBuilderForUI(data) : []));
  }

  resourceTrainingGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/Resources/Training`);
  }

  resourceCorporateComplaintGet(complaintId, otherParams) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/resources/corporate-complaints`, {
        complaintId,
        ...otherParams,
      })
      .then(({ data: { Complaints = [], TotalCount = null } }) => ({
        data: Complaints.map(corporateComplaintsBuilderForUI),
        totalRecordCount: TotalCount,
      }));
  }

  resourceCorporateComplaintSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/resources/corporate-complaints`,
      {
        ComplaintId: data.complaintId,
        CorpComments: data.corpComments,
      },
    );
  }

  resourceCorporateComplaintExcelGet(otherParams) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/resources/corporate-complaints-spreadsheet`,
      otherParams,
    );
  }

  resourceComplaintGet(complaintId) {
    return this.httpClient.get(`${this.apiBaseUrl}/Resources/Complaint`, {
      complaintId,
    });
  }

  resourceComplaintSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/Resources/Complaint`, data);
  }

  resourceComplaintDelete(complaintId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/Resources/Complaint`, {
      complaintId,
    });
  }
}

export default LoanToolsService;
