export default class FatalErrorsListCtrl {
  constructor($window, currentUserService) {
    'ngInject';

    this.$window = $window;
    this.currentUserService = currentUserService;
  }

  $onInit() {}
}
