import template from './livingExpense.html';
import controller from './livingExpenseCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isContactSummary: '<',
    isLoanApp: '<',
    familyId: '<',
    loanAppId: '<',
    brokerNotes: '<',
    expensesCount: '<',
    expenseList: '<',
    loanAppFormWarning: '<',
    onExpenseChange: '&',
  },
};
