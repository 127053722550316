import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { isLMBranded, isOwnBrand } from './brandingCategory';

const isValidAccessType = (accessType) =>
  [
    ACCESS_TYPE.PRINCIPAL_ADVISER,
    ACCESS_TYPE.ADVISER,
    ACCESS_TYPE.ADMIN_ASSISTANT,
    ACCESS_TYPE.ASSISTANT,
  ].includes(accessType);

export const showWealthMarketLoanApps = (props) => {
  const { wealthMarketLoanApps, currentUserService } = props;
  if (!wealthMarketLoanApps) {
    return !!wealthMarketLoanApps;
  }
  return !!(currentUserService && currentUserService.isAU);
};

export const showBetterEntity = (props) => {
  const { betterEntity, currentUserService } = props;
  if (!betterEntity) {
    return false;
  }
  const {
    brandingCategoryID,
    isAU,
    isCorporate,
    accessType,
  } = currentUserService;
  const isValidBrandingCategoryId =
    isLMBranded(brandingCategoryID) || isOwnBrand(brandingCategoryID);
  return !!(
    isAU &&
    ((isValidAccessType(accessType) && isValidBrandingCategoryId) ||
      isCorporate)
  );
};

export const showSubscriptionBillingEntity = (
  subscriptionBillingEntity,
  currentUserService,
) => {
  if (!subscriptionBillingEntity) {
    return false;
  }
  const {
    brandingCategoryID,
    isAU,
    isCorporate,
    accessType,
  } = currentUserService;
  const isValidBrandingCategoryId = [
    BRANDING_CATEGORY_TYPE.LOAN_MARKET,
    BRANDING_CATEGORY_TYPE.OWN_BRAND,
  ].includes(brandingCategoryID);
  return !!(
    isAU &&
    ((isValidAccessType(accessType) && isValidBrandingCategoryId) ||
      isCorporate)
  );
};

export const showLivingExpenseV2 = (props) => {
  const { livingExpenseV2, currentUserService } = props;
  if (!livingExpenseV2) {
    return livingExpenseV2;
  }
  const { isNZ, isAU, brandingCategoryID, isCorporate } = currentUserService;
  const isValidBrandingCategoryId =
    isLMBranded(brandingCategoryID) || isOwnBrand(brandingCategoryID);
  return !!((isAU || isNZ) && (isValidBrandingCategoryId || isCorporate));
};

export const showSocialMedia = (currentUserService) => {
  const { brandingCategoryID, isCorporate, isAU } = currentUserService;
  return (
    isLMBranded(brandingCategoryID) ||
    (isAU && (isOwnBrand(brandingCategoryID) || isCorporate))
  );
};

export const showBusinessPlanningDashboard = (
  businessPlanningDashboard,
  currentUserService,
) => {
  if (!businessPlanningDashboard) {
    return !!businessPlanningDashboard;
  }
  const { brandingCategoryID, isAU, isNZ, isCorporate } = currentUserService;
  const isValidCountryCode = isAU || isNZ;
  const isValidBrandingCategory =
    isCorporate ||
    isLMBranded(brandingCategoryID) ||
    isOwnBrand(brandingCategoryID);
  return isValidCountryCode && isValidBrandingCategory;
};

export const showPriceFinder = (priceFinder, currentUserService) => {
  if (!priceFinder) {
    return false;
  }
  const { isLM, isAU, accessType } = currentUserService;
  return !!(isAU && isValidAccessType(accessType) && isLM);
};

export const showPriceFinderBYOB = (priceFinderBYOB, currentUserService) => {
  if (!priceFinderBYOB) {
    return false;
  }
  const { isBYOB, isAU, accessType } = currentUserService;
  return !!(isAU && isValidAccessType(accessType) && isBYOB);
};

export const showPriceFinderSubscription = ({
  configService,
  currentUserService,
}) => (adviserInfo) => {
  if (!configService || !currentUserService) {
    return;
  }
  const { priceFinder, priceFinderBYOB } = configService.feature;
  const { isCorporate, isLM, isBYOB, isAU, accessType } = currentUserService;

  if (!isAU) {
    return false;
  }

  if (isCorporate && adviserInfo) {
    const showIfLMAdviser =
      priceFinder && isLMBranded(adviserInfo.BrandingCategoryId);
    const showIfByobAdviser =
      priceFinderBYOB && isOwnBrand(adviserInfo.BrandingCategoryId);
    return showIfLMAdviser || showIfByobAdviser;
  }

  if (!isValidAccessType(accessType)) {
    return false;
  }
  const showIfLM = priceFinder && isLM;
  const showIfByob = priceFinderBYOB && isBYOB;
  return showIfLM || showIfByob;
};
