import angular from 'angular';
import commissionUnmatchedDetails from './commissionUnmatchedDetails';
import './style.scss';

export default angular
  .module(
    'commission.scenes.commissionManagement.scenes.commissionLanding.scenes.commissionUnmatchedDetails',
    [],
  )
  .component('commissionUnmatchedDetails', commissionUnmatchedDetails).name;
