import { PIPELINE_TYPES_STR } from 'Common/constants/pipelineType';

export const contact = {
  page: 1,
  index: 'all',
};

export const task = {
  familyId: 0,
  page: 1,
};

export const pipeline = {
  page: 1,
  search: '',
  pipelineType: PIPELINE_TYPES_STR.APPLICATION,
};

export const client = {
  activeTab: 'summary',
};
