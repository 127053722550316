import { CONTACT_TYPE_NAME, CLIENT_ROLE } from 'Common/constants/contactType';

class ContactDetailsPreviewCtrl {
  constructor(contactService) {
    'ngInject';

    this.contactService = contactService;
  }

  $onInit() {
    this.isClient = this.contactType === CONTACT_TYPE_NAME.CLIENT;
    this.isLoadingDetails = this.isClient;
    this.contactLabel = this.contactType || CONTACT_TYPE_NAME.CLIENT;
    if (!this.isClient) {
      return;
    }
    this.getClientDetails();
  }

  getClientDetails() {
    if (!this.familyId) {
      this.isLoadingDetails = false;
      return;
    }
    return this.contactService
      .clientInformGet(this.familyId)
      .then(({ data }) => {
        this.clients =
          (data &&
            data.filter((client) => client.Role === CLIENT_ROLE.ADULT)) ||
          [];
        this.isLoadingDetails = false;
      })
      .catch(() => {
        this.isLoadingDetails = false;
        this.clients = [];
      });
  }

  onModalClose() {
    this.modalInstance.close();
  }

  onRouteContact() {
    this.routeContact(this.contact);
    this.onModalClose();
  }
}
export default ContactDetailsPreviewCtrl;
