import template from './tabularLoanList.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    options: '<',
    loanList: '<',
    sortOrder: '<',
    loanDetailScope: '<',
    lendingDetailScope: '<',
  },
};
