import _ from 'lodash';
import swal from 'sweetalert';
import { WORKBENCH_REQUIREMENT_TEMPLATES_CTRL_METHOD as METHOD } from './constants';

class WorkbenchRequirementTemplatesCtrl {
  constructor(toaster, userService) {
    'ngInject';

    this.toaster = toaster;
    this.userService = userService;
    this.templatesOnEdit = {};
  }

  $onInit() {
    this.getRequirementTemplates();
  }

  getRequirementTemplates() {
    this.isLoadingList = true;
    const args = { requirementTypeId: this.requirementTemplateType };
    this.userService.UserRequirementTemplateGet(args).then(
      (response) => {
        if (!response.data) {
          return;
        }
        this.requirementTemplates = _.orderBy(
          response.data,
          ['OrderBy'],
          ['asc'],
        );
        this.isLoadingList = false;
      },
      () => {
        this.handleError(METHOD.GET_REQUIREMENT_TEMPLATES);
        this.isLoadingList = false;
      },
    );
  }

  toggleAddMode() {
    this.newTemplate = { AlwaysRequired: false };
    this.isAddMode = !this.isAddMode;
  }

  toggleEditMode(template) {
    if (!template) {
      return;
    }
    template.isEditMode = !template.isEditMode;
    if (template.isEditMode) {
      this.templatesOnEdit[template.RequirementTemplateID] = _.clone(template);
    }
  }

  triggerDelete(template) {
    if (!template) {
      return;
    }
    swal(
      {
        title: 'Are you sure?',
        text: `${template.RequirementTemplate} will be removed from the list.`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      },
      (isConfirm) => {
        if (isConfirm) {
          this.deleteTemplate(template);
        }
      },
    );
  }

  onAlwaysRequiredChanged(template) {
    if (!template || template.isEditMode) {
      return;
    }
    this.saveTemplate(template);
  }

  saveTemplate(template) {
    if (!template) {
      return;
    }
    const {
      RequirementTemplate: requirementTemplate,
      AlwaysRequired: alwaysRequired,
      RequirementTemplateID: requirementTemplateId,
    } = template;
    const postData = {
      requirementTypeId: this.requirementTemplateType,
      alwaysRequired,
      requirementTemplate,
    };
    if (requirementTemplateId) {
      postData.requirementTemplateId = requirementTemplateId;
    }

    this.userService.UserRequirementTemplateSet(postData).then(
      (response) => {
        if (!response.data) {
          return;
        }
        template.RequirementTemplateID = response.data;
        this.handleTemplateSaved(template);
      },
      () => {
        this.handleError(METHOD.SAVE_TEMPLATE);
      },
    );
  }

  deleteTemplate(template) {
    this.userService
      .UserRequirementTemplateDelete(template.RequirementTemplateID)
      .then(
        () => {
          this.toaster.pop(
            'success',
            'Deleted',
            'Template successfully deleted',
          );
          _.remove(
            this.requirementTemplates,
            (item) =>
              item.RequirementTemplateID === template.RequirementTemplateID,
          );
        },
        () => {
          this.handleError(METHOD.DELETE_TEMPLATE);
        },
      );
  }

  moveTemplateUp(template) {
    if (!template) {
      return;
    }

    const templateIndex = this.getIndexOfTemplate(template);
    if (templateIndex === 0) {
      return;
    }

    this.requirementTemplates.splice(templateIndex, 1);
    const prevTemplateIndex = templateIndex - 1;
    const prevTemplate = this.requirementTemplates.splice(
      prevTemplateIndex,
      1,
      template,
    );
    this.requirementTemplates.splice(templateIndex, 0, prevTemplate[0]);
    this.saveOrder();
  }

  moveTemplateDown(template) {
    if (!template) {
      return;
    }

    const templateIndex = this.getIndexOfTemplate(template);
    const lastIndex = this.requirementTemplates.length - 1;
    if (templateIndex === lastIndex) {
      return;
    }

    const nextTemplateIndex = templateIndex + 1;
    const nextTemplate = this.requirementTemplates.splice(
      nextTemplateIndex,
      1,
    )[0];
    this.requirementTemplates.splice(templateIndex, 1, nextTemplate);
    this.requirementTemplates.splice(nextTemplateIndex, 0, template);
    this.saveOrder();
  }

  getIndexOfTemplate(template) {
    return _.findIndex(
      this.requirementTemplates,
      (item) => item.RequirementTemplateID === template.RequirementTemplateID,
    );
  }

  saveOrder() {
    let order = 0;
    const templatesOrder = _.map(this.requirementTemplates, (template) => {
      order++;
      template.OrderBy = order;
      return { UserTemplateId: template.RequirementTemplateID, OrderBy: order };
    });
    this.userService.UserRequirementTemplateSortingSet(templatesOrder);
  }

  handleError(method) {
    let message;
    switch (method) {
      case METHOD.GET_REQUIREMENT_TEMPLATES:
        message = 'Unable to get the list of requirement templates';
        break;
      case METHOD.SAVE_TEMPLATE:
        message = 'Unable to save the template';
        break;
      case METHOD.DELETE_TEMPLATE:
        message = 'Unable to delete the template';
        break;
      default:
        message = 'Oops! Something went wrong';
    }

    this.toaster.pop('error', message);
  }

  handleTemplateSaved(template) {
    const indexInList = _.findIndex(
      this.requirementTemplates,
      (item) => item.RequirementTemplateID === template.RequirementTemplateID,
    );
    if (indexInList !== -1) {
      this.requirementTemplates[indexInList] = template;
      if (template.isEditMode) {
        this.toggleEditMode(template);
      }
    } else {
      this.requirementTemplates.push(template);
      this.toggleAddMode();
    }
    this.toaster.pop('success', 'Saved', 'Template successfully saved');
  }
}

export default WorkbenchRequirementTemplatesCtrl;
