class PrintSummaryModalCtrl {
  constructor(contactModelService) {
    'ngInject';

    this.contactModelService = contactModelService;
  }

  $onInit() {
    this.isLoading = false;
    this.sections = [];
    this.getSummarySections();
  }

  getSummarySections() {
    this.isLoading = true;
    this.contactModelService.getSummarySection().then((response) => {
      this.isLoading = false;
      this.sections = response;
    });
  }

  updateSection(section) {
    this.contactModelService.updateSummarySection({
      sectionId: section.sectionID,
    });
  }

  printSummary() {
    this.modalInstance.close('print');
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }
}
export default PrintSummaryModalCtrl;
