import {
  checkConfigsIsOn,
  sendApplicationPack,
} from 'Common/utilities/insurance';
import {
  INSURANCE_PACK_TYPE,
  LAUNCH_LOCATION,
} from 'Common/constants/helloBook';

export default class InsurancePipelineCardMenuCtrl {
  constructor(
    $state,
    helloBookPreviewService,
    currentUserService,
    configService,
  ) {
    'ngInject';

    this.$state = $state;
    this.helloBookPreviewService = helloBookPreviewService;
    this.myCRMconfigs = { ...currentUserService, ...configService };
  }

  $onInit() {
    this.toggleView = checkConfigsIsOn(this);
  }

  $onChanges(changes) {
    if (changes.doForceToMainMenu && changes.doForceToMainMenu.currentValue) {
      this.toggleMenu('main');
    }
  }

  toggleMenu(type) {
    this.menuType = this.menuType === type ? 'main' : type;
  }

  viewClient() {
    this.onViewClient && this.onViewClient();
    this.$state.go('app.contactsSingle', { familyId: this.clientId });
  }

  viewWorkbench() {
    this.onViewWorkbench &&
      this.onViewWorkbench({ quoteId: this.quoteId, clientId: this.clientId });
  }

  sendApplicationPack() {
    const data = {
      params: {
        jointContact: true,
        familyId: this.clientId,
        quoteId: this.quoteId,
        provider: {
          id: this.providerId,
          name: this.providerName,
        },
        packType: INSURANCE_PACK_TYPE.APPLICATION,
        options: {
          location: LAUNCH_LOCATION.INSURANCE_TAB,
        },
      },
      helloBookPreviewService: this.helloBookPreviewService,
    };
    sendApplicationPack(data, this);
  }
}
