import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('NewReferralTypeModalCtrl', function NewReferralTypeModalCtrl(
      $scope,
      $uibModalInstance,
      $uibModal,
      referralsService,
      $state,
      cancel,
    ) {
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      referralsService.getOrganizationTypes().then((response) => {
        $scope.agreementTypesList = response.data;
      });

      $scope.navigateOnNewReferral = function (referralType) {
        $scope.cancel();
        cancel();
        $state.go('app.newReferral', { referralType, agreementId: 0 });
      };
    });
