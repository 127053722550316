import {
  searchClient,
  getClientSearchResults,
  clearSearchResultsFor,
  onClientSelected,
} from 'Assets/js/controllers/commission/util/unmatchedCommissionCtrl';

export default class CommissionSearchMatchCtrl {
  constructor($uibModal, $timeout, contactModelService, modalRenderService) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$timeout = $timeout;
    this.modalRenderService = modalRenderService;
    this.contactModelService = contactModelService;
  }

  $onInit() {
    this.searchClient = searchClient(this);
    this.getClientSearchResults = getClientSearchResults(this);
    this.clearSearchResultsFor = clearSearchResultsFor(this);
    this.onClientSelected = onClientSelected({
      vm: this,
      onSuccessCallback: this.onMatchSuccess,
    });
  }
}
