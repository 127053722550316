import angular from 'angular';
import swal from 'sweetalert';
import _ from 'lodash';
import $ from 'jquery';
import { convertUtcToUserDate } from 'Common/utilities/date';
import { displayError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('CorporateDetailCtrl', function CorporateDetailCtrl(
      $scope,
      $rootScope,
      $http,
      $filter,
      $timeout,
      corporateService,
      contactService,
      regionalizationService,
      $sce,
      $state,
      $stateParams,
      SweetAlert,
      mapService,
      $uibModal,
      toaster,
      $compile,
      referralsService,
      queryService,
      $q,
      generalService,
      broadcastDataService,
      commonFnService,
      $window,
      referralDetailService,
      onboardingDataService,
      stateService,
    ) {
      // Filter
      $filter('lowercase')();

      // state params
      $scope.familyId = $stateParams.familyId || 0;
      $scope.clientId = $stateParams.clientId || 0;
      $scope.adviserOrgId = $stateParams.adviserOrgId || 0;
      $scope.adviserComplianceId = $stateParams.adviserComplianceId || 0;
      $scope.referrerComplianceId = $stateParams.referrerComplianceId || 0;
      $scope.referrerOrgId = $stateParams.referrerOrgId || 0;

      // Variables
      $scope.selectedCorporateContact = [];

      $scope.review_month = [
        'No Review Month',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      // objects
      $scope.adviserDetails = {};
      $scope.editMode = {};
      $scope.adviserMarketing = {};
      // array
      $scope.contactTypeRoles = [];
      $scope.entityTypes = [];
      $scope.adviserOrganizationLists = [];
      $scope.brokerStatusLists = [];
      $scope.operatingCountries = [];
      $scope.reportingRegions = [];
      $scope.reportingStates = [];
      $scope.commissionTiers = [];
      $scope.adviserAchievements = [];
      $scope.enquirySources = [];
      $scope.marketingAdviserDetail = [];
      $scope.nps = [];
      $scope.adviser_compliance = [];
      $scope.webManagement = [];

      $scope.fullAddress = [];
      $scope.statuses = [];
      $scope.adviser_org = [];
      $scope.selected_adviser_org = [];
      $scope.brokerSupport = [];
      $scope.adviserAgreements = [];
      $scope.adviserOrgAgreements = [];
      $scope.outputRoleTypes = [];
      $scope.selectedRoles = [];
      $scope.contactRoleName = [];

      $scope.localLang = {
        reset: "<i class='fa fa-plus'></i>",
        search: 'Search',
      };

      $scope.tabs_assistants = [
        {
          name: 'Assistant Details',
          page: 'assets/views/corporate/assitant/assistant-details.html',
          value: 'assistant-details',
        },
        // {  name: 'Employee Details', page: 'assets/views/contacts/assitant/employee-details.html', value: 'employee-details' },
        // {  name: 'Address Info', page: 'assets/views/contacts/assitant/address-info.html', value: 'assitant-address-info'}
      ];

      $scope.tabs_referrers_org = [
        {
          name: 'Org Details',
          page: 'assets/views/corporate/referrer-org/org-details.html',
          value: 'org-details',
        },
        {
          name: 'Org Members',
          page: 'assets/views/corporate/referrer-org/org-members.html',
          value: 'org-members',
        },
        {
          name: 'Agreements',
          page: 'assets/views/corporate/referrer-org/agreements.html',
          value: 'agreements',
        },
      ];

      $scope.genderList = [
        { id: 1, value: 'Male' },
        { id: 2, value: 'Female' },
        { id: 3, value: 'Unknown' },
      ];
      $scope.level_accessList = [
        { id: 1, value: 'Email Only' },
        { id: 2, value: 'Full system access' },
        { id: 3, value: 'MyCRM access with no charge' },
      ];
      $scope.addressTypes = [
        // eslint-disable-next-line sonarjs/no-duplicate-string
        'Business Address',
        // eslint-disable-next-line sonarjs/no-duplicate-string
        'Home Address',
        // eslint-disable-next-line sonarjs/no-duplicate-string
        'Mailing Address',
      ];
      $scope.googleAutoPlace = '';
      $scope.isSameinBussinessAddress = false;
      $scope.editBusinessAdd = false;
      $scope.editHomeAdd = false;
      $scope.editMailingAdd = false;
      $scope.addressTypeValue = 'Business Address';

      $scope.saveType = {
        all: 0,
        adviserDetails: 1,
        additionalDetails: 2,
        franchiseDetails: 3,
      };

      $scope.setterType = [
        'contactSet',
        'familyComplianceSet',
        'familyMarketingDetailSet',
        'familyWebManagementSet',
        'familyBrokerSupportSet',
        'leadsProfileSet',
        'referrerOrgSet',
        'referrerOrgDirectorSet',
        'setReferrerOrgMembership',
        'setReferrerOrgRelationship',
      ];

      $scope.deleteType = ['referrerOrgDirectorDelete'];

      $scope.gender = ['Female', 'Male'];

      $scope.leadStatus = [
        'Active',
        'Temporarily Inactive',
        'Inactive',
        'Suspended',
      ];

      $scope.addressOrg = [];
      $scope.contactHomeAddress = [];

      const btnClass = ['btn-danger', 'btn-light-blue'];
      const fontClass = ['fa-ban text-white', 'fa-check text-white'];

      $scope.statusLists = [
        { value: 1, name: 'Active' },
        { value: 0, name: 'Inactive' },
      ];
      $scope.setClasses = (value, isbtn) => {
        return isbtn ? btnClass[value] : fontClass[value];
      };
      mapService.loadAPI();

      const getRecentView = () => {
        broadcastDataService.recentView.get().then((res) => {
          broadcastDataService.recentView.list = res;
        });
      };
      const onUpdateDetails = (e, setterType, obj, key, saveType, field) => {
        $scope.updateAdviserDetails(setterType, obj, key, saveType, field);
      };

      const initData = (getter, id, id2) => {
        if (id && id2) {
          corporateService[getter](id, id2).then((value) => {
            $scope[getter] = [];
            // eslint-disable-next-line sonarjs/no-element-overwrite
            $scope[getter] = value.data;
          });
        } else if (id) {
          // eslint-disable-next-line sonarjs/no-identical-functions
          corporateService[getter](id).then((value) => {
            $scope[getter] = [];
            // eslint-disable-next-line sonarjs/no-element-overwrite
            $scope[getter] = value.data;
          });
        } else {
          corporateService[getter]().then((value) => {
            $scope[getter] = value.data;
          });
        }
      };

      const initGetTypes = (getter) => {
        corporateService[getter]().then((obj) => {
          $scope[getter] = [];
          angular.forEach(obj.data, (value) => {
            $scope[getter].push(value);
          });
        });
      };

      const loadAssistantAddress = () => {
        broadcastDataService
          .corporateLoadAddress(
            $scope.familyId,
            false,
            'map-adviser-address-assistant',
            'map-adviser-address-home-assistant',
            'map-adviser-address-mailing-assistant',
          )
          .then((promise) => {
            const data = promise;
            if (_.size(data) > 0) {
              $scope.isBusiness = data.businessAddress;
              $scope.isHomeAddress = data.homeAddress;
              $scope.isMailing = data.mailingAddress;
              $scope.isSameinBussinessAddress = data.isSameinBussinessAddress;
            }
          });
      };

      const onAddressChange = (place) => {
        if (place) {
          $scope.googleAutoPlace = place.formatted_address;
        }
      };

      $scope.$on('UPDATE_FIELD_DETAIL', onUpdateDetails);
      if ($scope.familyId && $scope.clientId) {
        $scope.selectedContacts = $scope.tabs_assistants[0]
          ? $scope.tabs_assistants[0].name
          : null;

        $scope.$watch('selectedContacts', (newValue) => {
          if ($scope.tabs_assistants[newValue]) {
            $scope.getAllAdviserDetails($scope.tabs_assistants[newValue].name);
          }
        });
      } else if ($scope.adviserOrgId && $scope.adviserComplianceId) {
        $scope.selectedContacts = 'business-details';
        corporateService
          .adviserOrganizationGet(
            $scope.adviserComplianceId,
            $scope.adviserOrgId,
          )
          .then((response) => {
            getRecentView();
            let contTmp = {};
            angular.forEach(response.data, (value) => {
              corporateService
                .adviserOrganizationEntityTypesGet()
                .then((entity) => {
                  angular.forEach(entity.data, (entval) => {
                    if (value.EntityTypeId === entval.EntityTypeID) {
                      value.Entity = entval.EntityTypeName;
                    }
                    if (!value.Entity) {
                      value.Entity = '';
                    }
                  });
                });

              corporateService
                .adviserOrganizationStatusesGet()
                .then((statuses) => {
                  angular.forEach(statuses.data, (status) => {
                    $scope.statuses.push(status);
                    if (Number(value.Status) === Number(status.StatusCode)) {
                      value.StatusName = status.Status;
                    }
                    if (!value.StatusName) {
                      value.StatusName = '';
                    }
                  });
                });

              corporateService.operatingCountriesGet().then((country) => {
                angular.forEach(country.data, (countryval) => {
                  if (value.CountryId === countryval.CountryId) {
                    value.CountryName = countryval.CountryName;
                    value.CountryCode = countryval.CountryCode;
                  }
                  if (!value.CountryName && !value.CountryCode) {
                    value.CountryName = '';
                    value.CountryCode = '';
                  }
                });
              });

              contTmp = value;

              const tmp = [];
              corporateService
                .organizationAgreementGet(0, 0, $scope.adviserOrgId, 0)
                .then((agreement) => {
                  angular.forEach(agreement.data, (row) => {
                    tmp.push(row);
                  });
                });
              $scope.adviserOrgAgreements = tmp;
            });

            $scope.accountHeader = contTmp.AccountOwner_FullName.split(
              ' ',
            ).reduce((prevValue, word) => {
              return `${
                prevValue +
                word.slice(0, 1).toUpperCase() +
                word.slice(1).toLowerCase()
              } `;
            }, ' ');

            $scope.leadHeader = contTmp.LeadAviser_FullName.split(' ').reduce(
              // eslint-disable-next-line sonarjs/no-identical-functions
              (prevValue, word) => {
                return `${
                  prevValue +
                  word.slice(0, 1).toUpperCase() +
                  word.slice(1).toLowerCase()
                } `;
              },
              ' ',
            );

            $scope.selectedCorporateContact = contTmp;
          });
      } else if ($scope.referrerComplianceId && $scope.referrerOrgId) {
        $scope.selectedContacts = $scope.tabs_referrers_org[0].name;

        $scope.$watch('selectedContacts', (newValue) => {
          if ($scope.tabs_referrers_org[newValue]) {
            $scope.getAllReferrerDetails(
              $scope.tabs_referrers_org[newValue].name,
            );
          }

          $scope.$watch('referrerOrgGet', (refOrg) => {
            if (refOrg) {
              angular.element('.hidden-xs hidden-sm .well').matchHeight();
            }
          });
        });
      }

      $scope.getAllReferrerDetails = function (selectedContacts) {
        $scope.referrerOrgGet = [];
        $scope.referrerOrganizationMembershipsByOrganizationGet = [];

        initData(
          'referrerOrgGet',
          $scope.referrerComplianceId,
          $scope.referrerOrgId,
        );

        if (selectedContacts === 'Org Details') {
          initGetTypes('organizationTypesGet');

          corporateService
            .organizationAddressGet($scope.referrerOrgId)
            .then((address) => {
              $scope.addressOrg = address.data;
            });

          $scope.$watch('addressOrg', (newValue) => {
            let tmp = [];
            if (newValue) {
              generalService
                .placeSearch(newValue.Formatted_address)
                .then((response) => {
                  $scope.geoCoded = response.data[0].geoCoded;
                  tmp = response.data[0];
                  mapService.mapCorporate(
                    response.data[0].latitude,
                    response.data[0].longitude,
                    response.data[0].formatted_address,
                    'corporateHomeAddressMap',
                  );
                  mapService.mapCorporate(
                    response.data[0].latitude,
                    response.data[0].longitude,
                    response.data[0].formatted_address,
                    'corporateMobileHomeAddressMap',
                  );
                  $scope.contactHomeAddress = tmp;
                });
            }
          });
        } else if (selectedContacts === 'Org Members') {
          $scope.membershipRoles = [];

          initData('getReferrer');
          initData(
            'referrerOrganizationMembershipsByOrganizationGet',
            $scope.referrerOrgId,
          );
          regionalizationService.GetMembershipRoles().then((response) => {
            angular.forEach(response.data, (value) => {
              $scope.membershipRoles.push(value);
            });
          });
        } else if (selectedContacts === 'Agreements') {
          initData('getReferrerOrganization');

          $scope.$watch('getReferrerOrganization', () => {
            initData('adviserOrganizationListGet');
            const tmp = [];
            angular.forEach($scope.getReferrerOrganization, (value) => {
              tmp.push(value.ReferrerOrganizationId);
            });
            $scope.referrerOrgIds = tmp.join();

            if ($scope.referrerOrgIds) {
              initData(
                'getOrganizationWithAgreement',
                $scope.referrerOrgIds,
                0,
              );
            }
          });
        }
      };

      const setEditMode = (key) => {
        $scope.editMode[key] = false;
        return $scope.editMode[key];
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getArrValue = (id, name, idKey, nameKey, arrays) => {
        let arrValue;
        angular.forEach(arrays, (array) => {
          if (id !== null && id === array[idKey]) {
            arrValue = array[nameKey];
          }

          if (name !== null && name === array[nameKey]) {
            arrValue = array[idKey];
          }
        });

        return arrValue;
      };

      const initDeleteItem = (setType, data, arrDeleteItem, idx) => {
        corporateService[setType](data).then((response) => {
          if (response) {
            toaster.pop('success', 'Delete', '"Successfully Deleted!');
          }
          arrDeleteItem.splice(idx, 1);
        });
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const convertDateToString = (obj, keys) => {
        _.map(keys, (key) => {
          if (obj[key] instanceof Date) {
            obj[key] = obj[key].toDateString();
          }
        });
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const replaceMainDateFromTmp = (obj, keys) => {
        _.map(keys, (key) => {
          if (
            typeof obj[key] !== 'undefined' &&
            typeof obj[`${key}Tmp`] !== 'undefined' &&
            obj[`${key}Tmp`] !== 'None'
          ) {
            obj[key] = obj[`${key}Tmp`];
          }
        });
      };

      const onUpdateSuccess = (field) => {
        $scope.editMode[field] = false;
        toaster.pop('success', 'Update', 'successfully updated!');
        setEditMode(field);
      };

      const setUpadateAdviser = (
        setType,
        data,
        key,
        saveType,
        id,
        id2,
        field,
      ) => {
        if (saveType !== null) {
          corporateService[setType](saveType, data).then(() => {
            $scope.DateChangeValue(key, setType);
            onUpdateSuccess(field);
            if (setType === 'contactSet') {
              replaceMainDateFromTmp(data, ['DOB', 'StartDate', 'EndDate']);
            }
          });
        } else if (id && id2) {
          corporateService[setType](id, id2, data).then(() => {
            onUpdateSuccess(field);
          });
        } else {
          corporateService[setType](data).then(() => {
            onUpdateSuccess(field);
          });
        }

        toaster.clear();
      };

      $scope.getAllAdviserDetails = () => {
        corporateService
          .corporateContactGet($scope.clientId, $scope.familyId)
          .then((response) => {
            getRecentView();
            corporateService.reportingStatesGet().then((state) => {
              $scope.reportingStates = [];
              angular.forEach(state.data, (value) => {
                $scope.reportingStates.push(value);
              });
              response.data.ReportingStateName = getArrValue(
                response.data.ReportingStateId,
                null,
                'ReportingStateId',
                'ReportingStateName',
                $scope.reportingStates,
              );
            });

            corporateService.reportingRegionsGet().then((region) => {
              $scope.reportingRegions = [];
              angular.forEach(region.data, (value) => {
                $scope.reportingRegions.push(value);
              });
              response.data.ReportingRegionName = getArrValue(
                response.data.ReportingRegionId,
                null,
                'ReportingRegionId',
                'ReportingRegionName',
                $scope.reportingRegions,
              );
            });

            corporateService.operatingCountriesGet().then((countries) => {
              $scope.operatingCountries = [];
              angular.forEach(countries.data, (value, key) => {
                if (key < 3) {
                  $scope.operatingCountries.push(value);
                }
              });
              response.data.ReportingCountryName = getArrValue(
                response.data.ReportingCountryId,
                null,
                'CountryId',
                'CountryName',
                $scope.operatingCountries,
              );
              response.data.ReportingCountryCode = getArrValue(
                response.data.ReportingCountryId,
                null,
                'CountryId',
                'CountryCode',
                $scope.operatingCountries,
              );
              response.data.ReportingCountryPhoneCode = getArrValue(
                response.data.ReportingCountryId,
                null,
                'CountryId',
                'CallingCode',
                $scope.operatingCountries,
              );
            });

            if (response.data.DOB) {
              response.data.DOB = $scope.formatDate(response.data.DOB);
              response.data.DOBTmp = response.data.DOB;
            } else {
              response.data.DOBTmp = 'None';
            }

            corporateService.contactTypeRolesGet().then((roles) => {
              $scope.contactTypeRoles = [];
              angular.forEach(roles.data, (value) => {
                $scope.contactTypeRoles.push(value);
              });

              let contactRoleName = [];
              if (response.data) {
                contactRoleName = response.data.RoleId
                  ? response.data.RoleId.split(',')
                  : [];

                $scope.contactRoleName = [];
                $scope.selectedRoles = [];

                angular.forEach($scope.contactTypeRoles, (value) => {
                  angular.forEach(contactRoleName, (roleVal) => {
                    if (value.ContactTypeRoleId === roleVal) {
                      $scope.selectedRoles.push(value);
                      $scope.contactRoleName.push(value.ContactTypeRoleDesc);
                    }
                  });
                });

                response.data.RoleDesc =
                  $scope.contactRoleName.length > 0
                    ? $scope.contactRoleName.join(',  ')
                    : '';
                response.data.RoleArr = $scope.selectedRoles;
              }
            });

            if (response.data.StartDate) {
              response.data.StartDate = $scope.formatDate(
                response.data.StartDate,
              );
              response.data.StartDateTmp = response.data.StartDate;
            } else {
              response.data.StartDateTmp = 'None';
            }

            if (response.data.EndDate) {
              response.data.EndDate = $scope.formatDate(response.data.EndDate);
              response.data.EndDateTmp = response.data.EndDate;
            } else {
              response.data.EndDateTmp = 'None';
            }

            response.data.GenderName = getArrValue(
              response.data.Gender,
              null,
              'id',
              'value',
              $scope.genderList,
            );
            response.data.ReqdTeamAcessLevelName = getArrValue(
              response.data.ReqdTeamAcessLevel,
              null,
              'id',
              'value',
              $scope.level_accessList,
            );
            $scope.getAdviserOrgLists(
              response.data.AdviserOrganizationId,
            ); /* added this line so we can get the adviser organization's values */
            $scope.adviserDetails = response.data;

            loadAssistantAddress();
          })
          .then(() => {
            mapService.mapAutoCompletePlace(
              'map-address-bus-edit-assitant',
              onAddressChange,
            );
            mapService.mapAutoCompletePlace(
              'map-address-home-edit-assitant',
              onAddressChange,
            );
            mapService.mapAutoCompletePlace(
              'map-address-mailing-edit-assitant',
              onAddressChange,
            );
          });
      };

      $scope.loadAddress = (addressType) => {
        if (addressType === 'Business Address') {
          $scope.editHomeAdd = false;
          $scope.editMailingAdd = false;
        } else if (addressType === 'Home Address') {
          $scope.editBusinessAdd = false;
          $scope.editMailingAdd = false;
        } else if (addressType === 'Mailing Address') {
          $scope.editBusinessAdd = false;
          $scope.editHomeAdd = false;
        }
        loadAssistantAddress();
      };

      $scope.hideAdviserAddress = (type) => {
        if (type === 'business') {
          $scope.isEditMode = false;
        } else if (type === 'home') {
          $scope.isMailingEditMode = false;
        }
      };

      $scope.EditAddressModal = (type) => {
        if (type === 'Business Address') {
          $scope.editBusinessAdd = !$scope.editBusinessAdd;
        } else if (type === 'Home Address') {
          $scope.editHomeAdd = !$scope.editHomeAdd;
        } else if (type === 'Mailing Address') {
          $scope.editMailingAdd = !$scope.editMailingAdd;
        }
      };

      $scope.saveAddress = (type, address) => {
        const isBusiness = type === 'Business Address';
        const isMailing = type === 'Mailing Address';

        if (typeof address !== 'undefined') {
          corporateService
            .familyAddressSet(
              $scope.familyId,
              0,
              address,
              isMailing,
              isBusiness,
              true,
            )
            .then(() => {
              loadAssistantAddress();
              $scope.editBusinessAdd = false;
              $scope.editMailingAdd = false;
              $scope.editHomeAdd = false;
              toaster.pop('success', 'Successfully Updated', 'Address');
            });
        }
      };

      $scope.getAdviserOrgLists = (id) => {
        corporateService.adviserOrganizationListGet().then((adviserOrg) => {
          $scope.adviser_org = [];
          $scope.selected_adviser_org = [];
          $scope.isEmptyOrg = true;

          angular.forEach(adviserOrg.data, (value) => {
            if (value.AdviserOrganizatioName !== '') {
              value.icon = '<i class="fa fa-cog violet-color"></i>';
              if (id === value.AdviserOrganizationId) {
                value.ticked = true;
                $scope.selected_adviser_org.push(value);
                $scope.isEmptyOrg = false;
              } else {
                value.ticked = false;
              }
              if (value.ticked) {
                $scope.adviser_org.unshift(value);
              } else {
                $scope.adviser_org.push(value);
              }
            }
          });
          if ($scope.isEmptyOrg) {
            const data = {
              AdviserOrganizationId: 0,
              AdviserOrganizatioName: 'Please Select Org',
              AddressId: 0,
              Phone: '',
              Fax: '',
              ticked: true,
            };
            data.icon = '<i class="fa fa-cog violet-color"></i>';
            $scope.adviser_org.unshift(data);
            $scope.selected_adviser_org = $scope.adviser_org[0];
          }
        });
      };

      $scope.selectContacts = function (selectedContacts) {
        $timeout(() => {
          const el = $window.document.querySelectorAll(
            `#${selectedContacts} .nav-link`,
          );
          angular.element(el).triggerHandler('click');
        }, 0);
        $scope.selectedContacts = selectedContacts;

        // $scope.getAllAdviserDetails(selectedContacts);
      };

      const updateEmailAssistant = (setterType, obj, key, saveType, field) => {
        if (key === 'Email') {
          onboardingDataService
            .checkEmail(obj.Email, $scope.familyId)
            .then((prom) => {
              if (prom) {
                $scope.getAllAdviserDetails('Contact');
                swal(
                  'Email Validation',
                  'Email is already used by other user.',
                  'error',
                );
              } else {
                $scope.updateAdviserDetails(
                  setterType,
                  obj,
                  key,
                  saveType,
                  field,
                );
              }
            });
        } else {
          $scope.updateAdviserDetails(setterType, obj, key, saveType, field);
        }
      };

      $scope.updateEmailAssistant = updateEmailAssistant;

      $scope.selectedAdviser = (tab, key, selectedAdviserOrg, obj, field) => {
        obj.AdviserOrganizationId = selectedAdviserOrg[0].AdviserOrganizationId;
        $scope.updateAdviserDetails(tab, obj, key, 'adviserDetails', field);
      };

      $scope.deleteItem = (setterType, obj, arr, idx) => {
        obj.organizationId = $scope.referrerOrgId;
        initDeleteItem(setterType, obj, arr, idx);
      };

      $scope.updateAdviserDetails = (setterType, obj, key, saveType, field) => {
        let id = $scope.familyId;
        let id2 = $scope.clientId;

        if (setterType === 'contactSet') {
          obj.ClientID = $scope.clientId;
          obj.FamilyId = $scope.familyId;

          if (key === 'DOB') {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            obj.DOB = obj.DOB ? obj.DOB : '1900-01-01';
            obj.DOBTmp =
              obj.DOB !== '1900-01-01' ? $scope.formatDate(obj.DOB) : 'None';
          }

          if (key === 'StartDate') {
            obj.StartDate = obj.StartDate ? obj.StartDate : '1900-01-01';
            obj.StartDateTmp =
              obj.StartDate !== '1900-01-01'
                ? $scope.formatDate(obj.StartDate)
                : 'None';
          }

          if (key === 'EndDate') {
            obj.EndDate = obj.EndDate ? obj.EndDate : '1900-01-01';
            obj.EndDateTmp =
              obj.EndDate !== '1900-01-01'
                ? $scope.formatDate(obj.EndDate)
                : 'None';
          }

          convertDateToString(obj, ['DOB', 'StartDate', 'EndDate']);

          // reporting state
          obj.ReportingStateId = getArrValue(
            null,
            obj.ReportingStateName,
            'ReportingStateId',
            'ReportingStateName',
            $scope.reportingStates,
          );
          obj.ReportingStateName = getArrValue(
            obj.ReportingStateId,
            null,
            'ReportingStateId',
            'ReportingStateName',
            $scope.reportingStates,
          );

          // reporting region
          obj.ReportingRegionId = getArrValue(
            null,
            obj.ReportingRegionName,
            'ReportingRegionId',
            'ReportingRegionName',
            $scope.reportingRegions,
          );
          obj.ReportingRegionName = getArrValue(
            obj.ReportingRegionId,
            null,
            'ReportingRegionId',
            'ReportingRegionName',
            $scope.reportingRegions,
          );

          obj.Gender = getArrValue(
            null,
            obj.GenderName,
            'id',
            'value',
            $scope.genderList,
          );
          obj.GenderName = getArrValue(
            obj.Gender,
            null,
            'id',
            'value',
            $scope.genderList,
          );

          obj.ReqdTeamAcessLevel = getArrValue(
            null,
            obj.ReqdTeamAcessLevelName,
            'id',
            'value',
            $scope.level_accessList,
          );
          obj.ReqdTeamAcessLevelName = getArrValue(
            obj.ReqdTeamAcessLevel,
            null,
            'id',
            'value',
            $scope.level_accessList,
          );

          obj.ReportingCountryId = getArrValue(
            null,
            obj.ReportingCountryCode,
            'CountryId',
            'CountryCode',
            $scope.operatingCountries,
          );
          obj.ReportingCountryName = getArrValue(
            obj.ReportingCountryId,
            null,
            'CountryId',
            'CountryName',
            $scope.operatingCountries,
          );
          obj.ReportingCountryCode = getArrValue(
            obj.ReportingCountryId,
            null,
            'CountryId',
            'CountryCode',
            $scope.operatingCountries,
          );
          obj.ReportingCountryPhoneCode = getArrValue(
            obj.ReportingCountryId,
            null,
            'CountryId',
            'CallingCode',
            $scope.operatingCountries,
          );

          if (key === 'IsActive') {
            obj.IsActive = field;
          }

          const roleName = [];
          const roleId = [];
          angular.forEach(obj.RoleArr, (value) => {
            roleName.push(value.ContactTypeRoleDesc);
            roleId.push(value.ContactTypeRoleId);
          });

          obj.myCRMActiveIsUpdated = key === 'MyCRMActive';

          obj.RoleDesc = roleName.length > 0 ? roleName.join(',  ') : '';
          obj.RoleId = roleId.length > 0 ? roleId.join(',') : '';
        } else if (setterType === 'referrerOrgSet') {
          id = $scope.referrerComplianceId;
          id2 = $scope.referrerOrgId;
          obj.OrganisationTypeId = getArrValue(
            null,
            obj.OrganisationType,
            'OrganizationTypeId',
            'OrganizationTypeName',
            $scope.organizationTypesGet,
          );
        } else if (setterType === 'referrerOrgDirectorSet') {
          obj.ReferrerOrgId = $scope.referrerOrgId;
        } else if (setterType === 'setReferrerOrgMembership') {
          obj.ClientId = 0;
          if (obj.Referrer) {
            obj.FamilyId = obj.Referrer.originalObject.ReferrerFamilyId;
            obj.ReferrerOrganizationId = $scope.referrerOrgId;
            obj.RoleId = getArrValue(
              null,
              obj.RoleDescription,
              'RoleId',
              'RoleDesc',
              $scope.membershipRoles,
            );
          } else {
            obj.FamilyId = obj.ReferrerFamilyId;
          }
        } else if (setterType === 'setReferrerOrgRelationship') {
          obj.ClientId = 0;
        }

        setUpadateAdviser(
          setterType,
          obj,
          key,
          $scope.saveType[saveType],
          id,
          id2,
          field,
        );
      };

      $scope.DateChangeValue = (key, setterType) => {
        if (
          setterType === 'contactSet' &&
          $scope.adviserDetails[key] === '1900-01-01'
        ) {
          $scope.adviserDetails[key] = new Date();
          $scope.adviserDetails[`${key}Tmp`] = 'None';
        }
      };

      $scope.formatDate = (date) => {
        return new Date(date);
      };

      $scope.hiddenTabs = false;
      $scope.openHiddenTabs = () => {
        $scope.hiddenTabs = $scope.hiddenTabs === false;
      };

      $scope.closeHiddenTabs = () => {
        $scope.hiddenTabs = false;
      };

      $scope.calcWidth = () => {
        const className = '.tabs';
        $scope.hiddenTabCount = $('.more li').length;
        queryService.calculateWidth(className, $scope.calcWidth);
        $scope.toggleOverflowTabs();
      };

      $scope.toggleOverflowTabs = function () {
        $scope.narrowNav = referralDetailService.toggleOverflowTabs(
          $scope.hiddenTabCount,
        );
      };

      $scope.hiddenTabCount = $('.more li').length;
      $scope.toggleOverflowTabs();

      $timeout(() => {
        $scope.calcWidth();
      });

      $(window).on('resize orientationchange load', () => {
        $scope.calcWidth();
      });

      $scope.addNewOrgMember = () => {
        $rootScope.referrerOrganizationId = $stateParams.referrerOrgId;
        $rootScope.modeForModal = 'add';
        $uibModal.open({
          templateUrl:
            'assets/views/contacts/referrer-org/referrer_member.html',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          controller: 'ReferrerAsMemberNewCtrl',
        });
      };

      $rootScope.loadMemberWhenModalClose = () => {
        initData(
          'referrerOrganizationMembershipsByOrganizationGet',
          $scope.referrerOrgId,
        );
      };

      $scope.addNewOrgMemberExisting = () => {
        $rootScope.referrerOrganizationId = $stateParams.referrerOrgId;
        $rootScope.modeForModal = 'existing';
        $uibModal.open({
          templateUrl:
            'assets/views/contacts/referrer-org/referrer_member.html',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          controller: 'ReferrerAsMemberNewCtrl',
        });
      };

      $scope.addAgreement = () => {
        $uibModal.open({
          templateUrl:
            '/assets/views/referrals/partials/referral_agreement_modal.html',
          controller: 'AddReferralAgreementModalCtrl',
          size: 'lg',
          windowClass: 'referral_type',
          resolve: {},
        });
      };

      $scope.addAgreementAdviser = () => {
        corporateService
          .corporateContactGet($scope.clientId, $scope.familyId)
          .then((responseAddAgreementAdviser) => {
            $rootScope.callerId =
              responseAddAgreementAdviser.data.AdviserOrganizationId;
            $rootScope.modeForModal = 'addAdviser';
            $uibModal.open({
              templateUrl: 'assets/views/contacts/referrer/agreement_new.html',
              size: 'md',
              backdrop: 'static',
              keyboard: false,
              controller: 'AgreementNewCtrl',
            });
          });
      };
      $scope.addAgreementAdviserOrg = function () {
        const size = 'lg';

        $uibModal.open({
          templateUrl:
            '/assets/views/referrals/partials/referral_agreement_modal.html',
          controller: 'AddReferralAgreementModalCtrl',
          size,
          windowClass: 'referral_type',
          resolve: {},
        });
      };

      $rootScope.loadAgreemnt = () => {
        initData('getOrganizationWithAgreement', $scope.referrerOrgIds);
      };

      mapService.loadAPI();

      $scope.isAddDirector = false;
      $scope.director = {};
      $scope.saveDirector = function () {
        if (
          typeof $scope.director.name !== 'undefined' ||
          $scope.director.name !== ''
        ) {
          const corpDetailDirectorData = {
            DirectorId: 0,
            Name: $scope.director.name || '',
            Contact: $scope.director.number || '',
            Email: $scope.director.email || '',
          };
          corporateService
            .referrerOrgDirectorSet(
              $scope.referrerOrgId,
              corpDetailDirectorData,
            )
            .then((response) => {
              if (response.data > 0) {
                $scope.cancelDirector();
                initData(
                  'referrerOrgGet',
                  $scope.referrerComplianceId,
                  $scope.referrerOrgId,
                );
                swal('Success', 'Successfully added the director', 'success');
              }
            });
        }
      };
      $scope.addDirector = () => {
        $scope.isAddDirector = true;
      };
      $scope.cancelDirector = () => {
        $scope.director = {};
        $scope.isAddDirector = false;
      };

      $scope.deleteContact = () => {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This contact will be removed from your list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: false,
          },
          (isConfirm) => {
            if (isConfirm) {
              contactService
                .deleteContact($scope.familyId)
                .then(() => {
                  swal('Deleted', 'Contact has been deleted.', 'success');
                  stateService.routeToContactList();
                })
                .catch(displayError);
            }
          },
        );
      };
      contactService.getUserInfo().then((response) => {
        const userInfo = response.data;
        if (userInfo) {
          $scope.isProfileAdmin = userInfo.CorpAccessTypeID === 1;
          $scope.mobileMask = broadcastDataService.getMobileFormat(
            userInfo.CountryId,
          );
          $scope.phoneMask = broadcastDataService.getPhoneFormat(
            userInfo.CountryId,
          );
        }
      });

      $scope.isDateValid = (date) => {
        const d = new Date(date);
        return parseInt(d.getFullYear(), 10) > 1;
      };

      $scope.convertToDate = (date) => {
        return new Date(date);
      };

      $scope.convertUtcToUserDate = (date) => convertUtcToUserDate(date);
    });
