export const DEFAULT_VALUES = {
  EXISTING_STATUSID: 1,
  ESTABLISHED_STATUSID: 3,
  OWNEROCCUPIED_PRIMARYPURPOSE: 1,
  JOINT_HOLDINGID: 2,
  SOLE_HOLDINGID: 1,
  RESIDENTIAL_ZONINGID: 2,
  STDRESIDENTIAL_PROPERTYTYPE: 68,
  FULLY_DETACHED_HOUSE: 13,
  ASSET_FINANCE_IGNORE_SECURITY_TYPES: [3, 4, 5], // Term Deposit, Vehicle, Other
};

export const TRANSACTION_TYPE = {
  PURCHASING: 1,
  OWNS: 2,
  SOLD: 3,
  REFINANCE: 4,
  GUARANTEE: 5,
  CONSTRUCTION: 6,
  PURCHASING_CONSTRUCTION: 7,
  OWNS_CONSTRUCTION: 8,
  REFINANCE_CONSTRUCTION: 9,
};

export const NO_LMI_TRANSACTION_TYPES = [
  TRANSACTION_TYPE.GUARANTEE,
  TRANSACTION_TYPE.OWNS,
  TRANSACTION_TYPE.OWNS_CONSTRUCTION,
];

export const TRANSACTION_TYPE_ORDER = {
  [TRANSACTION_TYPE.PURCHASING]: 1,
  [TRANSACTION_TYPE.PURCHASING_CONSTRUCTION]: 2,
  [TRANSACTION_TYPE.CONSTRUCTION]: 3,
  [TRANSACTION_TYPE.OWNS]: 4,
  [TRANSACTION_TYPE.OWNS_CONSTRUCTION]: 5,
  [TRANSACTION_TYPE.REFINANCE]: 6,
  [TRANSACTION_TYPE.REFINANCE_CONSTRUCTION]: 7,
  [TRANSACTION_TYPE.GUARANTEE]: 8,
  [TRANSACTION_TYPE.SOLD]: 9,
};

export const CONSTRUCTION_TYPES = {
  HOUSE_AND_LAND_PACKAGE: 1,
  MINOR_STRUCTURAL_REPAIRS: 11,
  FULL_CONSTRUCTION: 12,
  HOME_EXTENSION: 13,
  RENOVATION: 14,
  WEATHER_TIGHTNESS_REPAIRS: 15,
};

export const CONSTRUCTION_TYPE_ORDER_NZ = {
  [CONSTRUCTION_TYPES.FULL_CONSTRUCTION]: 1,
  [CONSTRUCTION_TYPES.HOME_EXTENSION]: 2,
  [CONSTRUCTION_TYPES.HOUSE_AND_LAND_PACKAGE]: 3,
  [CONSTRUCTION_TYPES.MINOR_STRUCTURAL_REPAIRS]: 4,
  [CONSTRUCTION_TYPES.RENOVATION]: 5,
  [CONSTRUCTION_TYPES.WEATHER_TIGHTNESS_REPAIRS]: 6,
};

export const NONEXISTING_ASSET_TRANSACTION_TYPES = [
  TRANSACTION_TYPE.PURCHASING,
  TRANSACTION_TYPE.CONSTRUCTION,
  TRANSACTION_TYPE.PURCHASING_CONSTRUCTION,
];

export const CONSTRUCTION_TRANSACTION_TYPES = [
  TRANSACTION_TYPE.PURCHASING_CONSTRUCTION,
  TRANSACTION_TYPE.OWNS_CONSTRUCTION,
  TRANSACTION_TYPE.REFINANCE_CONSTRUCTION,
];

export const PRIMARY_PURPOSE_VALUE = {
  OWNER_OCCUPIED: 1,
  INVESTMENT: 2,
};

export const ASSET_TYPE = {
  MOTOR_VEHICLE: 1,
  RECREATIONAL: 2,
  EQUIPMENT: 3,
  TERTIARY_ASSETS: 4,
  OTHER: 5,
};

export const LENDING_CATEGORY = {
  RESIDENTIAL: 1,
  COMMERCIAL: 2,
  SMALL_BUSINESS: 3,
  ASSET_FINANCE: 4,
  PERSONAL_LOAN: 5,
  DEPOSIT_BOND: 6,
};

export const ASSET_PERSONAL_STRING = ['Asset Finance', 'Personal Loans'];

export const ASSET_PERSONAL_CATEGORY = [
  LENDING_CATEGORY.ASSET_FINANCE,
  LENDING_CATEGORY.PERSONAL_LOAN,
];

export const STEPS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FORTH: 4,
  FIFTH: 5,
};
