import template from './productAndLenderRationaleHint.html';
import controller from './productAndLenderRationaleHintCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    questionId: '<',
    loanAppOptions: '<',
    borrowingCapacity: '<',
    isAU: '<?',
  },
};
