class MycrmInputCtrl {
  $onChanges(changes) {
    const { initialValue } = changes;
    if (
      initialValue &&
      initialValue.currentValue &&
      !this.hasInitializedValue
    ) {
      this.hasInitializedValue = true;
      this.inputValue = initialValue.currentValue;
    }
  }
}

export default MycrmInputCtrl;
