const ALL_OWNERS = -1;

export const ownerListMapper = ({ item, ownershipList, isInit }) => {
  const isAllOwners = item.BorrowerID === ALL_OWNERS;
  return ownershipList.map((person) => {
    if (person.BorrowerID === ALL_OWNERS) {
      return { ...person, ticked: isAllOwners };
    }
    if (isAllOwners) {
      return { ...person, ticked: false };
    }
    if (isInit && item.BorrowerID === person.BorrowerID) {
      return { ...person, ticked: true };
    }
    return person;
  });
};

export const onItemSelect = (scope) => (item, isInit = false) => {
  const mappedOwnerList = ownerListMapper({
    item,
    ownershipList: scope.ownershipList,
    isInit,
  });
  scope.ownershipList = [...mappedOwnerList];
  if (item.BorrowerID === ALL_OWNERS) {
    scope.ownerListSelected = [{ ...scope.ownershipList[0] }];
  } else {
    scope.ownerListSelected = scope.ownerListSelected.filter(
      (owner) => owner.BorrowerID !== ALL_OWNERS,
    );
  }

  if (isInit && item.BorrowerID !== ALL_OWNERS) {
    scope.ownerListSelected = [...scope.ownerListSelected, item];
  }
  scope.securityStructure.Ownership = [...scope.ownerListSelected];
};

export const initializeOwnerList = ($scope, owners) => {
  const borrowerCount = $scope.ownershipList.length - 1;

  if (borrowerCount !== owners.length) {
    owners.forEach((owner) => $scope.onItemSelect(owner, true));
  } else {
    $scope.onItemSelect({ BorrowerID: ALL_OWNERS }, true);
  }
};
