import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('addComma', function addComma($filter) {
    return {
      require: '?ngModel',
      link(scope, elem, attrs, ctrl) {
        if (!ctrl) {
          return;
        }
        ctrl.$formatters.unshift(() => {
          if (ctrl.$modelValue === '') {
            return ctrl.$modelValue;
          }

          if (!Number.isNaN(ctrl.$modelValue)) {
            return $filter('number')(ctrl.$modelValue);
          }
          return $filter('number')(ctrl.$modelValue, 2);
        });

        ctrl.$parsers.unshift((viewValue) => {
          const plainNumber = viewValue.replace(/[,.]/g, '');
          const b = $filter('number')(plainNumber);
          elem.val(b);

          return plainNumber;
        });
      },
    };
  });
