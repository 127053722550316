import template from './helloPackAttachment.html';
import controller from './helloPackAttachmentCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    attachedFiles: '<',
    onChange: '&',
  },
};
