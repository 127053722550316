import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'BatchActionMobileModalCtrl',
      function BatchActionMobileModalCtrl($scope, $uibModalInstance) {
        $scope.closeBatchActionMobileModal = () => {
          $uibModalInstance.dismiss('cancel');
        };
      },
    );
