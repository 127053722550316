import template from './benefitListTable.html';
import './style.scss';

export default {
  template,
  bindings: {
    benefits: '<',
    insurance: '<',
    isInActive: '<',
    title: '<',
    level: '<',
    fireAndGeneralBenefit: '<',
    onDelete: '&',
    onEdit: '&',
  },
};
