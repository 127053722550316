import { showActionConfirmation } from 'Common/utilities/alert';

class TotalLendingRequiredCtrl {
  constructor(optionsService, loanApplicationServices, loanAppSharedData) {
    'ngInject';

    this.optionsService = optionsService;
    this.loanApplicationServices = loanApplicationServices;
    this.loanAppSharedData = loanAppSharedData;
  }

  $onInit() {
    this.isFormShow = false;
    this.totalLendingAmount = 0;
    this.lendingData = [];
    this.lendingFormData = {};
    this.lendingFormEdit = {};
    this.lendingPurposes = [];
    this.getProposedLending();
    this.getLendingPurposeList();
  }

  getLendingPurposeList() {
    this.optionsService.getLendingPurposeList().then((response) => {
      if (!response || !response.length) {
        return;
      }
      this.lendingPurposes = response.reduce((accum, obj) => {
        const { typeId, purposeDescription, absCodes } = obj;
        const absCode = absCodes.map((item) => {
          return { GroupName: purposeDescription, typeId, ...item };
        });
        return [...accum, ...absCode];
      }, []);
    });
  }

  getProposedLending() {
    this.loanApplicationServices
      .getProposedLending(this.loanAppId)
      .then((response) => {
        this.lendingFormData = {};
        this.lendingFormEdit = {};
        this.lendingData = response;
        this.resetProposedLendingData();
        this.setTotalBreakdown();
      });
  }

  setTotalBreakdown() {
    if (!this.lendingData || !this.lendingData.length) {
      return;
    }

    this.totalLendingBreakdown = this.lendingData
      .map((item) => item.lendingAmount)
      .reduce((prevAmount, nextAmount) => prevAmount + nextAmount);
    const { fundingSummary } = this.loanAppSharedData;
    this.resultBreakdown =
      fundingSummary.totalProposedLending - this.totalLendingBreakdown;
    this.absoluteBreakdown = Math.abs(this.resultBreakdown);
  }

  resetProposedLendingData(id) {
    this.lendingData.map((item) => {
      item.isEdit = item.totalLendingId === id;
      return item;
    });
  }

  openForm(status) {
    this.isFormShow = !status;
  }

  editLending(lendingId) {
    this.resetProposedLendingData(lendingId);
    if (!this.lendingData || !this.lendingData.length || !lendingId) {
      return;
    }
    const { totalLendingId, lendingAmount, absCode } = this.lendingData.find(
      (o) => o.totalLendingId === lendingId,
    );
    const absPurpose = this.lendingPurposes.find((o) => o.code === absCode);
    this.lendingFormEdit = {
      totalLendingId,
      lendingAmount,
      absPurpose,
    };
  }

  updateABSPurpose() {
    if (!this.lendingFormData || !this.lendingFormData.absPurpose) {
      return;
    }
    this.lendingFormData.absCode = this.lendingFormData.absPurpose.code;
  }

  setLendingData(update = false) {
    if (!update) {
      const { absCode, amount } = this.lendingFormData;
      if (!amount || !absCode) {
        return;
      }
      const postData = {
        LoanApplicationId: this.loanAppId,
        ABSCode: absCode,
        LendingAmount: amount,
      };
      this.setProposedLending(postData);
    } else {
      const {
        absPurpose,
        lendingAmount,
        totalLendingId,
      } = this.lendingFormEdit;
      if (!lendingAmount || !totalLendingId) {
        return;
      }
      const postData = {
        LoanApplicationId: this.loanAppId,
        TotalLendingId: totalLendingId,
        ABSCode: absPurpose.code,
        LendingAmount: lendingAmount,
      };
      this.setProposedLending(postData);
    }
  }

  setProposedLending(data) {
    this.loanApplicationServices.setProposedLending(data).then((response) => {
      if (response.data) {
        this.getProposedLending();
        this.isFormShow = false;
      }
    });
  }

  deleteProposedLending(lending) {
    showActionConfirmation(
      'Are you sure?',
      'This information will be removed from your list',
      (confirm) => {
        if (confirm) {
          const data = {
            loanApplicationId: this.loanAppId,
            totalLendingId: lending.totalLendingId,
          };
          this.loanApplicationServices
            .deleteProposedLending(data)
            .then((response) => {
              if (response.data) {
                this.getProposedLending();
              }
            });
        }
      },
    );
  }
}

export default TotalLendingRequiredCtrl;
