class OnboardingContentCtrl {
  constructor(onboardingDataService) {
    'ngInject';

    this.activeTab = 0;
    this.tabs = [];
    this.onboardingDataService = onboardingDataService;
  }

  $onChanges(changes) {
    if (changes && changes.activeTab) {
      this.activeTab = parseInt(changes.activeTab.currentValue, 0);
    }
    if (changes && changes.tabs) {
      this.tabs = changes.tabs.currentValue;
    }
  }

  $onInit() {
    this.onboardingDataService.getOnbardingTabs({}).then((tabs) => {
      this.requiredDocumentIndex = tabs
        .map((tab) => tab.value)
        .indexOf('requiredDocuments');
    });
  }
}

export default OnboardingContentCtrl;
