import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';

class CreditCheckService {
  constructor(
    $uibModal,
    contactService,
    currentUserService,
    creditCheckSubscriptionService,
    usersSubscriptionService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.contactService = contactService;
    this.currentUserService = currentUserService;
    this.creditCheckSubscriptionService = creditCheckSubscriptionService;
    this.usersSubscriptionService = usersSubscriptionService;
  }

  creditCheckValidation(familyId) {
    this.contactService.subscriptionInfo(familyId).then((data) => {
      this.creditCheckSubscriptionService.creditCheck.creditCheckSubscriptionInfo =
        data &&
        data.find(
          (sub) => sub.subscriptionProductId === SUBSCRIPTIONTYPE.CREDIT_CHECK,
        );

      const {
        creditCheckSubscriptionInfo,
      } = this.creditCheckSubscriptionService.creditCheck;

      this.creditCheckSubscriptionService.creditCheck.isAllocatedAdviserSubscribed =
        (creditCheckSubscriptionInfo && creditCheckSubscriptionInfo.isActive) ||
        false;
      this.creditCheckSubscriptionService.creditCheck.isCreditCheckDisabled =
        !this.creditCheckSubscriptionService.creditCheck
          .isAllocatedAdviserSubscribed &&
        this.currentUserService.familyId !== familyId;
      this.creditCheckSubscriptionService.creditCheck.adviserFamilyId = familyId;
    });
  }

  openNewCreditCheckModalUtil({
    familyId,
    clientHeaderName,
    clientsList,
    onModalClose,
  }) {
    const contactForCreditCheck = {
      FamilyID: familyId,
      ClientFirstName: clientHeaderName,
      ClientLastName: '',
    };

    const modalInstance = this.$uibModal.open({
      templateUrl: 'assets/views/creditChecks/set_credit_checks_modal.html',
      controller: 'SetCreditChecksModalCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'credit-checks-modal-window',
      resolve: {
        selectedContact: () => contactForCreditCheck,
        clientsList: () => clientsList,
      },
    });

    if (modalInstance && modalInstance.result) {
      modalInstance.result.then((response) => {
        typeof onModalClose === 'function' && onModalClose(response);
      });
    }
  }

  openNewCreditCheckModal({
    designatedBrokerInfo,
    familyId,
    clientHeaderName = '',
    clientsList,
    onModalClose,
  }) {
    const {
      isCreditCheckDisabled,
      isAllocatedAdviserSubscribed,
    } = this.creditCheckSubscriptionService.creditCheck;

    const invalidCreditCheck =
      isCreditCheckDisabled ||
      !(designatedBrokerInfo && designatedBrokerInfo.familyId);

    if (invalidCreditCheck) {
      return;
    }

    if (isAllocatedAdviserSubscribed) {
      this.openNewCreditCheckModalUtil({
        familyId,
        clientHeaderName,
        clientsList,
        onModalClose,
      });
    } else {
      const props = {
        familyId: designatedBrokerInfo.familyId,
        subscriptionData: this.creditCheckSubscriptionService.creditCheck
          .creditCheckSubscriptionInfo,
        orderedById: designatedBrokerInfo.familyId,
      };
      this.usersSubscriptionService
        .subscribeToService(props)
        .then((response) => {
          this.creditCheckSubscriptionService.creditCheck.isAllocatedAdviserSubscribed = !!response;
          if (isAllocatedAdviserSubscribed) {
            this.openNewCreditCheckModalUtil({
              familyId,
              clientHeaderName,
              clientsList,
              onModalClose,
            });
          }
        });
    }
  }
}

export default CreditCheckService;
