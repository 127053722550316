import { forEach, get } from 'lodash';
import { COMPANY_TYPES } from 'Common/constants/partyTypes';
import { addTrustExtent } from './util/addTrust';

const TRUST_TYPES = [
  { value: COMPANY_TYPES.COMPANY, label: COMPANY_TYPES.COMPANY_STRING },
  { value: COMPANY_TYPES.TRUST, label: COMPANY_TYPES.TRUST_STRING },
];

class AddTrustModalCtrl {
  constructor(
    companyTrustSharedDataService,
    dashboardService,
    contactService,
    toaster,
    configService,
    currentUserService,
    optionsService,
    addressLookupService,
  ) {
    'ngInject';

    this.companyTrustSharedDataService = companyTrustSharedDataService;
    this.dashboardService = dashboardService;
    this.contactService = contactService;
    this.toaster = toaster;
    this.configService = configService;
    this.currentUserService = currentUserService;
    this.optionsService = optionsService;
    this.addressLookupService = addressLookupService;
    this.addTrustExtent = addTrustExtent(this);
  }

  $onInit() {
    const { betterEntity } = this.configService.feature;
    const { isAU } = this.currentUserService;
    this.showBetterEntity = !!(betterEntity && isAU);
    this.entityData = this.companyTrustSharedDataService.getCompanyTrustModel();
    this.buttonLabel = this.showBetterEntity
      ? 'Add New Entity'
      : 'Add New Company/Trust';
    this.isSaving = false;
    this.modalTitle = 'Add New';
    this.isSubmitted = false;
    this.formHasError = false;
    this.onPartyTypeChange(this.partyType);
    this.getEntityInfo();
    this.getPartyCompanyType();
  }

  getPartyCompanyType() {
    if (!this.showBetterEntity) {
      this.trustTypes = TRUST_TYPES;
      return;
    }
    this.optionsService.getPartyCompanyType().then((data) => {
      this.trustTypes = data;
    });
  }

  back() {
    this.modalInstance.dismiss('cancel');
  }

  isSubmitError(errMessage) {
    this.isSubmitted = true;
    this.formHasError = true;
    this.errorMessage = errMessage;
    this.isSaving = false;
  }

  setOrganisation(sendData) {
    if (sendData.Address && sendData.Address.length) {
      sendData.Address[0].Type = 'Home';
    }
    this.addTrustExtent.onContactServiceSet(sendData);
  }

  setAddress(sendData) {
    this.dashboardService
      .addressDetailsSet(sendData.Address[0])
      .then((postAddressResponse) => {
        if (!postAddressResponse) {
          return;
        }
        const addressId = postAddressResponse.data;
        sendData.Address[0] = { AddressID: addressId };
        this.setOrganisation(sendData);
      })
      .catch(() => {
        this.isSaving = false;
      });
  }

  handleAddressOverhaul(trustDetails) {
    const trustAddressDetails = get(
      trustDetails,
      'Address.0.displayDetails',
      '',
    );
    const isValidAddressOverhaul = !!(
      this.configService.feature.addressOverhaul && trustAddressDetails
    );
    if (!isValidAddressOverhaul) {
      return false;
    }
    this.addressLookupService
      .convertToAddressFormat(trustDetails.Address[0])
      .then((address) => {
        trustDetails.Address = [{ ...address }];
        this.setAddress(trustDetails);
      });
    return true;
  }

  isSubmitValid(sendData) {
    if (!sendData) {
      this.isSaving = false;
      this.isSubmitted = true;
      this.formHasError = true;
      this.errorMessage = 'There seems to be an error. Please try again later';
      return false;
    }
    this.isSubmitted = false;
    this.formHasError = false;
    sendData.Email[0].Type = 'Email';
    sendData.Phone[0].Type = 'Work';
    const dataAddressSet = sendData;
    if (this.handleAddressOverhaul(dataAddressSet)) {
      return;
    }
    const trimAddress =
      sendData.Address.length &&
      typeof sendData.Address[0].formatted_address !== 'undefined' &&
      sendData.Address[0].formatted_address.trim();
    if (trimAddress) {
      dataAddressSet.Address[0] = { formatted_address: trimAddress };
      this.setAddress(dataAddressSet);
    } else {
      dataAddressSet.Address[0] = { AddressID: 0 };
      this.setOrganisation(sendData);
    }
  }

  submitNewEntity(isValid, entityForm) {
    this.isSaving = true;
    if (!isValid) {
      this.isSaving = false;
      this.isSubmitError('Please check required fields.');
      this.getErrorMessage(entityForm);
      return false;
    }
    switch (this.partyType) {
      case COMPANY_TYPES.COMPANY:
        this.submitNewCompany();
        break;
      case COMPANY_TYPES.TRUST:
        this.submitNewTrust(COMPANY_TYPES.TRUST_STRING);
        break;
      case COMPANY_TYPES.PARTNERSHIP:
        this.submitNewPartnership();
        break;
      case COMPANY_TYPES.SMSF:
        this.submitNewTrust(COMPANY_TYPES.SMSF_STRING);
        break;
      default:
    }
  }

  submitNewPartnership() {
    this.entityData.OrganisationType = COMPANY_TYPES.PARTNERSHIP_STRING;
    const partnershipSubmitData = this.companyTrustSharedDataService.getPartnershipSubmitModel(
      this.entityData,
    );
    this.isSubmitValid(partnershipSubmitData);
  }

  getErrorMessage(entityForm) {
    if (entityForm.email.$invalid) {
      this.errorMessage = 'Please fill a valid email address.';
    } else if (
      !entityForm.dateIncorporated &&
      entityForm.dateIncorporated.$invalid
    ) {
      this.errorMessage = 'Please use an appropriate date.';
    } else {
      this.errorMessage = 'Please check required fields.';
    }
  }

  validateTrustBeneficiaries() {
    let isValid = true;
    forEach(this.entityData.TrustBeneficiary, (beneficiary) => {
      if (!beneficiary.isSubmitted) {
        isValid = false;
        return false;
      }
    });

    return isValid;
  }

  submitNewTrust(organisationType) {
    if (!organisationType) {
      return;
    }
    const isBenefeciariesValid = this.validateTrustBeneficiaries();
    if (isBenefeciariesValid) {
      this.entityData.OrganisationType = organisationType;
      const trustSubmitData = this.companyTrustSharedDataService.getTrustSubmitModel(
        this.entityData,
      );
      this.isSubmitValid(trustSubmitData);
    } else {
      this.isSubmitError('Please submit the beneficiaries.');
    }
  }

  submitNewCompany() {
    this.entityData.OrganisationType = COMPANY_TYPES.COMPANY_STRING;
    const companySubmitData = this.companyTrustSharedDataService.getCompanySubmitModel(
      this.entityData,
    );
    this.isSubmitValid(companySubmitData);
  }

  onPartyTypeChange(partyType, entityForm = null) {
    this.partyType = !partyType ? this.partyType : partyType;
    this.addLabel =
      this.partyType === COMPANY_TYPES.TRUST ? 'Trust' : 'Company';

    if (entityForm) {
      entityForm.$setPristine();
      entityForm.$setUntouched();
    }
  }

  getEntityInfo() {
    if (this.entity) {
      this.modalTitle = 'Edit';
      this.buttonLabel = 'Save Changes';
      this.contactService
        .organisationInfoGet(this.entity.FamilyId)
        .then((response) => {
          const { data } = response;
          data.FamilyId = this.entity.FamilyId;
          data.companyNZBN = parseInt(data.NZBN, 10);
          this.entityData = this.companyTrustSharedDataService.getCompanyTrustModel(
            data,
            this.configService.feature.addressOverhaul,
          );
          this.entityData.isExistingAddress = !!get(
            this,
            'entityData.Address.formatted_address',
            '',
          );
        });
    }
  }
}

export default AddTrustModalCtrl;
