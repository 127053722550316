import angular from 'angular';
import objectivesQuestion from './objectivesQuestion';
import questionConfirmMessage from './questionConfirmMessage';
import questionMessageModal from './questionMessageModal';

export default angular
  .module('components.objectivesQuestion', [])
  .component('objectivesQuestion', objectivesQuestion)
  .component('questionMessageModal', questionMessageModal)
  .component('questionConfirmMessage', questionConfirmMessage).name;
