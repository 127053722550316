export const countryCodeInputElement = ({ angular, elementId }) => {
  return angular.element(`#${elementId}`);
};

export const dynamicCountryCodeInputIdNameSetter = ({ name, index, isId }) => {
  const inputName = name.toLowerCase();
  if (isId) {
    return `${inputName}Id-${index}`;
  }
  return `${inputName}-${index}`;
};
