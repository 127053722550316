export default class ManagePartiesViewCtrl {
  constructor(
    $uibModalInstance,
    opportunityId,
    involvedParties,
    selectedAdviserId,
    showSelectAdviser,
  ) {
    'ngInject';

    this.modalInstance = $uibModalInstance;
    this.opportunityId = opportunityId;
    this.involvedParties = involvedParties;
    this.selectedAdviserId = selectedAdviserId;
    this.showSelectAdviser = showSelectAdviser;
  }
}
