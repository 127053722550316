import set from 'lodash/set';
import get from 'lodash/get';
import {
  DOCUMENT_REQUEST_METHOD_ID,
  FILE_ATTACHMENT_LIMIT,
  GUIDE_TYPE,
  HELLO_PACK_KEY,
  PACK_MODE,
  HELLO_PACK_OPTIONS_TOGGLE,
  MODAL_SERVICE,
} from 'Common/constants/helloBook';
import { getTemporaryUniqueId } from 'Common/utilities/math';
import {
  showHelloPackToggle,
  getCreditGuideDisplay,
} from 'Common/utilities/helloBook';
import { CONFIG_EMAIL } from 'Common/config/email';
import { catchError } from 'Common/utilities/promise';
import { grammaticallySeparateByComma } from 'Common/utilities/string';

const FACT_FIND_DESCRIPTION = `<p>Obtain privacy consent and collect critical client information to make a recommendation in the client's best interests</p>`;
const FACT_FIND_DESCRIPTION_OLD = `<p>Collect critical client information to make a recommendation in the customer's best interests</p>`;
const FACT_FIND_PRIVACY_CONSENT_DESCRIPTION = `<p>Send the Online Fact Find to request privacy consent. Otherwise you must send the standalone Privacy Disclosure and 
Consent form to all applicants and upload a record of this signed document to MyCRM.</p>`;
const ONLINE_FF_MODEL_VALUE = 'model.options.onlineFactFind.value';
const FACT_FIND_VALID = 'model.factFind.isFactFindValid';

export default class HelloPackEditorCtrl {
  constructor(
    currentUserService,
    helloBookService,
    helloPackService,
    configService,
    uiService,
  ) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.helloBookService = helloBookService;
    this.helloPackService = helloPackService;
    this.configService = configService;
    this.uiService = uiService;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  $onInit() {
    this.factFindEmail = this.currentUserService.isNZ
      ? CONFIG_EMAIL.ADVISER_SERVICES_NZ
      : CONFIG_EMAIL.MARKETING_AU;
    this.packMode = this.packMode || PACK_MODE.HELLO_PACK;
    this.isInsuranceHelloPackService =
      this.selectedService === MODAL_SERVICE.insurance;
    this.isInviteIOFF = this.packMode === PACK_MODE.INVITE_IOFF;
    this.isAU = !!this.currentUserService.isAU;
    this.showCreditGuideWithHelloPack = this.configService.feature.showCreditGuideWithHelloPack;
    this.factFindHandler = {
      onToggle: () => {},
    };
    this.dealsForCreditGuide = [];
    this.isInsuranceHelloPackService &&
      this.helloPackService.handler.skipDealView();

    const {
      displayCreditGuide,
      displayCreditGuideWithHelloPack,
    } = getCreditGuideDisplay({ $scope: this });
    const displayHelloPack = showHelloPackToggle({
      $scope: this,
      displayCreditGuideWithHelloPack,
    });

    const inviteIOFFOption = {
      value: this.uiService.showIOFF,
      title: this.oneTouchPrivacy
        ? 'Privacy Consent and Online Fact Find'
        : 'Online Fact Find',
      description: this.oneTouchPrivacy
        ? FACT_FIND_DESCRIPTION
        : FACT_FIND_DESCRIPTION_OLD,
      isVisible: this.uiService.showIOFF,
      disabled: true,
    };

    const defaultOptions = {
      [HELLO_PACK_KEY.DEFAULT]: {
        value: displayHelloPack,
        defaultSettingKey: 'isHelloBookEnabled',
        apiKey: 'helloBook',
        title: 'Hello Book',
        description: `<p>A friendly marketing document to introduce yourself and your services.</p>`,
        thumbnail: 'hello_book',
        thumbnailAlt: 'Hello Book',
        thumbnailTitle: 'Learn about us',
        thumbnailDescription:
          'Find out who we are & how we help in your Hello Book',
        isVisible: displayHelloPack,
      },
      [HELLO_PACK_KEY.CREDIT_GUIDE_ONLY]: {
        value: displayCreditGuide,
        defaultSettingKey:
          displayCreditGuide && HELLO_PACK_OPTIONS_TOGGLE.CREDIT_GUIDE,
        apiKey: 'creditGuide',
        title: GUIDE_TYPE.AU,
        description: `<p>A document that outlines the terms and conditions associated with your services. Remember, each client requires a copy.</p>`,
        thumbnail: 'credit_guide',
        thumbnailAlt: GUIDE_TYPE.AU,
        thumbnailTitle: 'Things you should know',
        thumbnailDescription:
          'Our Credit Guide explains our obligations to you',
        isVisible: displayCreditGuide,
        component: `<hello-pack-deal-option is-multiple='true' loan-scenario-id="vm.model.loanApplicationId" family-id="vm.familyId" handler="vm.dealOptionHandler" on-change="vm.setDealsForCreditGuide({ data, isRemove })"></hello-pack-deal-option>`,
      },
      [HELLO_PACK_KEY.WIH_CREDIT_GUIDE]: {
        value: displayCreditGuideWithHelloPack,
        defaultSettingKey:
          displayCreditGuideWithHelloPack && 'isHelloBookEnabled',
        apiKey: 'helloBook',
        title: 'Hello Book & Credit Guide',
        description: `<p>A friendly marketing document to introduce yourself, your services and the associated terms and conditions of engagement.</p>`,
        thumbnail: 'hello_book',
        thumbnailAlt: 'Hello Book & Credit Guide',
        thumbnailTitle: 'Learn about us',
        thumbnailDescription:
          'Find out who we are & how we help in your Hello Book & Credit Guide',
        isVisible: displayCreditGuideWithHelloPack,
        component: `<hello-pack-deal-option is-multiple='true' loan-scenario-id="vm.model.loanApplicationId" family-id="vm.familyId" handler="vm.dealOptionHandler" on-change="vm.setDealsForCreditGuide({ data, isRemove })"></hello-pack-deal-option>`,
      },
      disclosureGuide: {
        value: this.uiService.showDisclosureGuide,
        defaultSettingKey: 'isDisclosureEnabled',
        apiKey: 'disclosureGuide',
        disabled: !this.uiService.showDisclosureGuide,
        title: GUIDE_TYPE.NZ,
        description: `<p>A document that outlines the terms and conditions associated with your services. Remember, each client requires a copy.</p>`,
        thumbnail: 'credit_guide',
        thumbnailAlt: GUIDE_TYPE.NZ,
        thumbnailTitle: 'Things you should know',
        thumbnailDescription:
          'Our Disclosure Guide explains our obligations to you',
        isVisible: this.currentUserService.isNZ && !this.onlyFactFind,
      },
      calendlyInvite: {
        value: !this.onlyFactFind,
        defaultSettingKey: 'isCalendlyInviteEnabled',
        apiKey: 'calendly',
        title: 'Calendly Invite',
        description: `<p>Your account isn’t quite ready to turn on Calendly bookings. Not to worry - <a href="mailto:mycrmsupport@loanmarket.com.au">MyCRM Support</a> will be able to get this fixed for you.</p>`,
        thumbnail: 'calendar',
        thumbnailAlt: 'Calendly',
        thumbnailTitle: 'Book a meeting',
        thumbnailDescription: 'Find a time that suits you',
        isVisible: this.currentUserService.isAU && !this.onlyFactFind,
        disabled: false,
      },
      uploadedAttachments: {
        value: !this.onlyFactFind,
        defaultSettingKey: 'isUploadedAttachments',
        title: 'Uploaded attachments',
        description: `<p>Upload ${FILE_ATTACHMENT_LIMIT} document (max) as attachments to the email.</p>`,
        component: `<hello-pack-attachment attached-files="vm.model.attachedFiles" on-change="vm.onUploadAttachmentChanged(attachedFiles)"></hello-pack-attachment>`,
        thumbnail: 'attachment',
        thumbnailAlt: 'Attachment',
        thumbnailTitle: 'Attachments',
        displayAttachment: true,
        isVisible: !this.onlyFactFind,
      },
      onlineFactFind: this.isInsuranceHelloPackService
        ? {
            ...inviteIOFFOption,
            disabled: false,
            defaultSettingKey: 'isFactFindEnabled',
          }
        : {
            value: true,
            defaultSettingKey: 'isFactFindEnabled',
            apiKey: 'onlineFactFind',
            title: this.oneTouchPrivacy
              ? 'Privacy Consent and Online Fact Find'
              : 'Online Fact Find',
            description: this.oneTouchPrivacy
              ? FACT_FIND_DESCRIPTION
              : FACT_FIND_DESCRIPTION_OLD,
            component: `<hello-pack-fact-find toggle-value="item.value" fact-find-default-setting="vm.factFindDefaultSetting" on-change="vm.onFactFindChange(props)" handler="vm.factFindHandler"></hello-pack-fact-find>`,
            isVisible: true,
            errorMessage: `Your account isn't quite ready to send Online Fact Finds.
            Not to worry - <a href="mailto:${this.factFindEmail.email}">${this.factFindEmail.description}</a>
            will be able to get this fixed for you.`,
          },
      deal: {
        component: `<hello-pack-deal family-id="vm.familyId" model="vm.model" one-touch-privacy="vm.oneTouchPrivacy" get-privacy-consents="vm.getPrivacyConsents()" ng-if="vm.factFindDefaultSetting"></hello-pack-deal>`,
        isVisible:
          !this.model.loanApplicationId &&
          !this.isInsuranceHelloPackService &&
          !this.hideDealOption,
        hideToggle: true,
        value: true,
      },
      documentRequest: {
        value: true,
        defaultSettingKey: !this.isInsuranceHelloPackService
          ? 'factFindSections.supportingdocuments'
          : '',
        title: 'Document request',
        description: `<p>Choose how to request documents from your client.</p>`,
        component: `<hello-pack-document-request model="vm.model"></hello-pack-document-request>`,
        isVisible: !this.isInsuranceHelloPackService,
      },
    };

    const insuranceProfilerOptions = {
      ccToAdviser: {
        value: true,
        defaultSettingKey: 'isCCToAdviser',
        apiKey: 'isCCToAdviser',
        title: 'CC to adviser',
        description: `<p>Send a copy of this email to the adviser.</p>`,
        isVisible: true,
      },
      uploadedAttachments: {
        value: false,
        title: 'Uploaded attachments',
        defaultSettingKey: 'isUploadedAttachments',
        description: `<p>Upload ${FILE_ATTACHMENT_LIMIT} document (max) as attachments to the email.</p>`,
        component: `<hello-pack-attachment attached-files="vm.model.attachedFiles" on-change="vm.onUploadAttachmentChanged(attachedFiles)"></hello-pack-attachment>`,
        thumbnail: 'attachment',
        thumbnailAlt: 'Attachment',
        thumbnailTitle: 'Attachments',
        displayAttachment: true,
        isVisible: true,
      },
      saveToInsuranceFile: {
        value: false,
        title: 'Save to Insurance File',
        defaultSettingKey: 'saveToInsuranceFile',
        description: `<p>If no insurance file is selected, this will be saved in the client folder</p>`,
        component: `<insurance-file-dropdown
          on-select="vm.onSelectInsuranceFile({ $e })"
          family-id="vm.familyId"
          class-name="['width-300']"
        ></insurance-file-dropdown>`,
        isVisible: this.uiService.insuranceFile,
      },
    };

    const inviteIOFFOptions = {
      onlineFactFind: { ...inviteIOFFOption },
    };

    let options = {};
    switch (true) {
      case this.packMode === PACK_MODE.INSURANCE_PROFILER: {
        options = insuranceProfilerOptions;
        break;
      }
      case this.packMode === PACK_MODE.INVITE_IOFF: {
        options = inviteIOFFOptions;
        break;
      }
      case this.preVerifyClient:
        break;
      default: {
        options = defaultOptions;
        break;
      }
    }

    this.editorHandler = {
      setDefaultTemplate: () => {},
    };
    this.privacyConsentHandler = {
      setClientsByLoanId: () => {},
      getPrivacyConsents: this.preVerifyClient
        ? this.getPrivacyConsents.bind(this)
        : () => {},
    };

    set(this, 'model.options', options);

    const isGuarantorAccepted =
      this.onlyFactFind || this.packMode === PACK_MODE.HELLO_PACK;
    this.acceptableInvolvedParties = {
      IsApplicant: true,
      ...(isGuarantorAccepted
        ? {
            IsGuarantor: true,
          }
        : {}),
    };
  }

  $onChanges() {
    if (this.adviserInfo && this.adviserTheme) {
      this.getDefaultSettings();
    }
  }

  setDealsForCreditGuide({ data, isRemove }) {
    if (!data) {
      return;
    }
    if (isRemove) {
      this.dealsForCreditGuide = this.dealsForCreditGuide.filter(
        (x) => x !== data.loanScenarioId,
      );
    } else {
      this.dealsForCreditGuide = [
        ...this.dealsForCreditGuide,
        data.loanScenarioId,
      ];
    }
    this.model.dealsForCreditGuide = this.dealsForCreditGuide;
  }

  getPrivacyConsents() {
    const loanApplicationId = get(this.model, 'loanApplicationId', 0);
    if (!loanApplicationId) {
      return;
    }
    this.helloBookService
      .getPrivacyPolicy(loanApplicationId)
      .then((privacyConsents = []) => {
        this.privacyConsentHandler.setClientsByLoanId(privacyConsents);
      })
      .catch(catchError);
  }

  updateFactFindDescription(privacyConsents, factFindEnabled) {
    const withoutPolicyNames = privacyConsents.reduce((initial, current) => {
      const { privacyConsent, displayName, isSelected } = current;
      if (privacyConsent || !isSelected) {
        return initial;
      }
      return [...initial, displayName];
    }, []);
    const privacypolicy = !!withoutPolicyNames.length;
    this.factFindDefaultSetting = {
      ...this.factFindDefaultSetting,
      privacypolicy,
    };
    const factFindDescription = this.oneTouchPrivacy
      ? FACT_FIND_DESCRIPTION
      : FACT_FIND_DESCRIPTION_OLD;
    const additionalDescription = privacypolicy
      ? `${
          factFindEnabled
            ? `${FACT_FIND_DESCRIPTION}<p class="additional-description">Privacy consent will${
                withoutPolicyNames.length === 1 ? ' ONLY' : ''
              } be requested from ${grammaticallySeparateByComma(
                withoutPolicyNames,
                'and',
              )}</p>`
            : FACT_FIND_PRIVACY_CONSENT_DESCRIPTION
        }`
      : factFindDescription;
    set(
      this,
      'model.options.onlineFactFind.description',
      additionalDescription,
    );
  }

  setCommunicationAppendMessage(title, description) {
    const color = get(this, 'adviserTheme.sidebarBgColor', '');
    const style = color ? `style="color:${color}"` : '';
    this.model.communicationAppendMessage = `
    <p>
      <a class="dummy-link" ${style}>${title}</a>
      ${description}
    </p>
  `;
  }

  getGuideText() {
    const isCreditGuideEnabled = get(
      this,
      'model.options.creditGuide.value',
      false,
    );
    const isDisclosureGuideEnabled = get(
      this,
      'model.options.disclosureGuide.value',
      false,
    );

    const { isAU, isNZ } = this.currentUserService;
    const guideText = isAU ? GUIDE_TYPE.AU : GUIDE_TYPE.NZ;
    const isGuideEnabled =
      (isAU && isCreditGuideEnabled) || (isNZ && isDisclosureGuideEnabled);

    return isGuideEnabled ? `& ${guideText}` : '';
  }

  getPdfAttachmentTemplates() {
    this.model.communicationAppendMessage = '';
    const templates = Object.keys(this.model.options).reduce((accum, key) => {
      const item = get(this, `model.options[${key}]`, {});
      const isValidTemplate = item.value && item.thumbnail;
      if (!isValidTemplate || !item.isVisible) {
        return accum;
      }

      if (key === HELLO_PACK_KEY.DEFAULT) {
        const helloPack = {
          ...item,
          thumbnailDescription: `${
            item.thumbnailDescription
          } ${this.getGuideText()}`,
        };
        this.setCommunicationAppendMessage(
          helloPack.thumbnailTitle,
          helloPack.thumbnailDescription,
        );
        return accum.concat(helloPack);
      }

      const isHelloPackEnabled = get(
        this,
        'model.options.helloPack.value',
        false,
      );

      const isGuideKey =
        key === HELLO_PACK_KEY.CREDIT_GUIDE_ONLY || key === 'disclosureGuide';
      if (isGuideKey && !isHelloPackEnabled) {
        this.setCommunicationAppendMessage(
          item.thumbnailTitle,
          item.thumbnailDescription,
        );
      }
      return isGuideKey && isHelloPackEnabled ? accum : accum.concat(item);
    }, []);
    set(this, 'model.templates', templates);
  }

  onClientUpdate({ clients }) {
    this.model.clients = clients;
    this.oneTouchPrivacy &&
      !this.isInsuranceHelloPackService &&
      this.updateFactFindDescription(
        clients,
        this.model.options?.onlineFactFind?.value ?? true,
      );
    this.helloPackService.handler.getClientExcludedInDeal();
  }

  onUploadAttachmentChanged(files = []) {
    set(this, 'model.attachedFiles', [...files]);
  }

  onSelectInsuranceFile({ $e }) {
    set(this, 'model.insuranceFileId', $e.fileId);
  }

  onUpdateContent(props = {}) {
    this.model.content = { ...props };
  }

  factFindOnlyValidate() {
    const isFactFindValid = get(this, FACT_FIND_VALID, false);
    const factFindToggledOn = get(this, ONLINE_FF_MODEL_VALUE, false);
    const documentRequestToggledOn = get(
      this,
      'model.options.documentRequest.value',
      false,
    );

    return factFindToggledOn ? isFactFindValid : documentRequestToggledOn;
  }

  onCheckFactFindValidation() {
    if (this.isInviteIOFF || this.isInsuranceHelloPackService) {
      this.model.isFactFindValid = true;
      this.helloPackService.onSetValidityFactFind(this.model.isFactFindValid);
      return;
    }

    const isFactFindToggleOn = get(this, ONLINE_FF_MODEL_VALUE, false);

    const isFactFindValid = this.oneTouchPrivacy
      ? get(this, FACT_FIND_VALID, false)
      : get(this, FACT_FIND_VALID, false) &&
        typeof this.model.loanApplicationId !== 'undefined';
    this.model.isFactFindValid = this.onlyFactFind
      ? this.factFindOnlyValidate()
      : !isFactFindToggleOn || (isFactFindToggleOn && isFactFindValid);
    this.helloPackService.onSetValidityFactFind(this.model.isFactFindValid);
    !this.onlyFactFind && this.onCheckFactFindSkip();
  }

  onCheckFactFindSkip() {
    const factFindToggledOn =
      this.model?.options?.onlineFactFind?.value ?? false;
    const documentRequestToggledOn =
      this.model?.options?.documentRequest?.value ?? false;
    const dealValue = !(factFindToggledOn || documentRequestToggledOn);
    const skipDealView = dealValue || !this.oneTouchPrivacy;
    set(this, 'model.options.deal.value', !dealValue);
    this.helloPackService.handler.skipDealView(skipDealView);
  }

  checkValidation() {
    this.onCheckFactFindValidation();
  }

  onFactFindChange(props = {}) {
    this.model.factFind = { ...props };
    this.onCheckFactFindValidation();
  }

  onChangeItemValue({ value, item, key }) {
    set(this, `model.options[${key}].value`, value);
    if (item.thumbnail) {
      this.getPdfAttachmentTemplates();
    }
    this.checkValidation();
    if (key === 'onlineFactFind') {
      this.factFindHandler.onToggle(value);
      this.updateFactFindDescription(this.model.clients, value);
    }

    const isToggleFileInvite =
      key === 'documentRequest' &&
      this.model.documentRequestMethod ===
        DOCUMENT_REQUEST_METHOD_ID.FILE_INVITE &&
      value;
    isToggleFileInvite && this.helloPackService.handler.onToggleFileInvite();
  }

  parseFileInviteData(data) {
    if (!data) {
      return {
        reminders: {},
        templates: [],
        dueDate: '',
      };
    }
    const dueDate = data.dueDate ? new Date(data.dueDate) : '';
    return {
      ...data,
      dueDate,
    };
  }

  getDefaultSettings() {
    return this.helloBookService
      .getDefaultSettings(this.adviserInfo.familyId)
      .then((data = {}) => {
        data.isDisclosureEnabled = this.uiService.showDisclosureGuide
          ? data.isDisclosureEnabled
          : false;
        const isBrandingReady =
          !this.adviserInfo.isBYOB ||
          !!(this.adviserInfo.tradingName && this.adviserTheme.logoDocId);
        this.model.documentRequestMethod = data.documentRequestMethod
          ? data.documentRequestMethod
          : DOCUMENT_REQUEST_METHOD_ID.FACT_FIND;
        this.model.fileInvite = this.parseFileInviteData(data.fileInvite);
        this.model.isBrandingReady = isBrandingReady;
        this.model.insuranceFileId = 0;
        data.isFactFindEnabled = isBrandingReady && data.isFactFindEnabled;

        this.model.attachedFiles =
          this.packMode === PACK_MODE.INSURANCE_PROFILER
            ? []
            : get(data, 'attachments', []).map((file, index) => ({
                ...file,
                index,
                id: getTemporaryUniqueId(index),
                showConfirmDeletePopover: false,
                documentId: file.documentId,
              }));
        this.factFindDefaultSetting = data.factFindSections || {};
        const editorMessage = data.message;
        this.editorHandler.setDefaultTemplate(editorMessage);
        const options = get(this, 'model.options', {});
        Object.keys(options).forEach((key) => {
          const defaultKey = get(
            this,
            `model.options[${key}].defaultSettingKey`,
            '',
          );
          const defaultValue = get(data, `${defaultKey}`, true);
          set(this, `model.options[${key}].value`, defaultValue);
        });

        // set uploaded attachments
        set(
          this.model,
          'options.uploadedAttachments.value',
          get(data, 'isUploadedAttachments', true),
        );

        this.model.calendlyLink = data.calendlyLink;
        const isDisableCalendly =
          this.currentUserService.isNZ || !this.model.calendlyLink;
        if (isDisableCalendly) {
          set(this, 'model.options.calendlyInvite.disabled', isDisableCalendly);
          set(this, 'model.options.calendlyInvite.value', !isDisableCalendly);
        }
        if (!this.isInviteIOFF) {
          set(this, 'model.options.onlineFactFind.disabled', !isBrandingReady);
          set(this, 'model.options.onlineFactFind.invalid', !isBrandingReady);
          set(this, 'model.options.documentRequest.disabled', !isBrandingReady);
        }
        if (this.isInsuranceHelloPackService) {
          set(this, ONLINE_FF_MODEL_VALUE, this.uiService.showIOFF);
        }
      })
      .finally(() => {
        this.getPdfAttachmentTemplates();
        const loanApplicationId = get(this.model, 'loanApplicationId', 0);
        loanApplicationId && this.getPrivacyConsents();
        this.showEditorItem = true;
      });
  }
}
