import angular from 'angular';
import { customDateUtcFormat } from './util/customDateUtcFormat';
import {
  directiveParseToCurrency,
  directiveFormatToCurrency,
} from 'Common/utilities/currency';

export const loadModule = () =>
  angular.module('app').directive('format', function format($filter) {
    return {
      require: '?ngModel',
      link(scope, elem, attrs, ctrl) {
        if (!ctrl) {
          return;
        }

        ctrl.$formatters.unshift(() => {
          return $filter(attrs.format)(ctrl.$modelValue);
        });

        ctrl.$parsers.unshift((viewValue) => {
          // eslint-disable-next-line no-useless-escape
          const plainNumber = viewValue.replace(/[^\d+.|\-]/g, '');
          elem.val($filter(attrs.format)(plainNumber));
          return plainNumber;
        });
      },
    };
  });

const dateFormat = () => {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: (scope, elem, attrs, ctrl) =>
      customDateUtcFormat({ scope, elem, attrs, ctrl }),
  };
};
angular.module('app').directive('customDateUtcFormat', dateFormat);

const currencyFormat = () => {
  return {
    require: '?ngModel',
    link(scope, elem, attrs, ctrl) {
      if (!ctrl) {
        return;
      }

      ctrl.$parsers.unshift((rawValue) =>
        directiveParseToCurrency({ rawValue, elem, ctrl }),
      );
      ctrl.$formatters.unshift((rawValue) =>
        directiveFormatToCurrency({ rawValue, elem, ctrl }),
      );
    },
  };
};
angular.module('app').directive('currencyFormat', currencyFormat);
