/*
NOTE: This directive is dependent on the CSS made for the general search loader


EXAMPLE FOR DIRECTIVE APPLICATION:

<input loaders loaders-show="showSpinner" loader-type="circle" type="search"/>

showSpinner is a value of either true or false
*/
import angular from 'angular';

export const loadModule = () =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  angular.module('app').directive('loaders', function loaders() {
    return {
      restrict: 'A',
      scope: {
        loadersShow: '=',
      },
      link(scope, element, attributes) {
        let loaderOpacity = scope.loadersShow === true ? 1 : 0.01;
        let loaderCircle = '';
        const loaderType = attributes.loaderType;

        if (loaderType === 'circle') {
          const elementWrapper = angular
            .element('<div/>')
            .attr('class', 'generalSearch position-relative');
          const wrapToCircle = angular
            .element('<div/>')
            .attr('class', 'advanced-search-container advanced-search-loader')
            .attr('style', 'right: 2%!important; top: 25%!important');

          element.wrap(elementWrapper); /* wrapping the input with DIV */

          loaderOpacity = scope.loadersShow === true ? 1 : 0.01;
          loaderCircle = angular.element(
            `<div style="opacity: ${loaderOpacity}" id="circularG"> <div id="circularG_1" class="circularG"></div> <div id="circularG_2" class="circularG"></div> <div id="circularG_3" class="circularG"></div> <div id="circularG_4" class="circularG"></div> <div id="circularG_5" class="circularG"></div> <div id="circularG_6" class="circularG"></div> <div id="circularG_7" class="circularG"></div> <div id="circularG_8" class="circularG"></div> </div>`,
          ); /* setting an element for the animation */

          loaderCircle.insertAfter(
            element,
          ); /* inserting the loaderCircle after the element */
          loaderCircle.wrap(wrapToCircle);

          // detect outside changes and update our input
          scope.$watch('loadersShow', (loaderOpacityAtwatch) => {
            loaderOpacity = loaderOpacityAtwatch === true ? 1 : 0.01;
            if (element.context) {
              element.context.nextSibling.children.circularG.style.opacity = loaderOpacity; /* editing the opacity of the loader if the value of loadersShow changes */
            } else {
              element.next().children().css('opacity', loaderOpacity);
            }
          });
        }
      },
    };
  });
