import { toastError } from 'Common/utilities/alert';
import { personToSetContactModel } from 'Common/utilities/insurance';

const MODAL_TYPE = {
  QUOTE: 'quote',
  SAVE: 'save',
  SAVE_TO_CLIENT_DOCS: 'saveToClientDocs',
};
class QuoteToContactCtrl {
  constructor(
    $state,
    $timeout,
    $window,
    $uibModalInstance,
    $uibModal,
    broadcastDataService,
    insuranceProfilerService,
    insuranceQuoteService,
    insuranceFileService,
    contactService,
    toaster,
    formData,
    listenFrom,
    insuranceModalApplicationService,
    configService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModal = $uibModal;
    this.insuranceProfilerService = insuranceProfilerService;
    this.insuranceQuoteService = insuranceQuoteService;
    this.insuranceFileService = insuranceFileService;
    this.contactService = contactService;
    this.configService = configService;
    this.toaster = toaster;
    this.formData = formData;
    this.isOnlineInsuranceAppActive =
      configService.feature.onlineInsuranceApplication;
    this.broadcastDataService = broadcastDataService;

    this.searchContactText = '';
    this.selectedFamily = {};
    this.getFamilyList = [];
    this.searchContactText = '';
    this.selectedFamily = {};
    this.showContactList = false;
    this.prefix = this.formData.prefix;
    this.listenFrom = listenFrom;
    this.insuranceCreateApp = insuranceModalApplicationService;
    this.allocatedAdviserId = '';
    this.isConfirming = false;
  }

  $onInit() {
    this.insuranceFileId =
      parseInt(this.$state.params.insuranceFileId, 10) || 0;
    this.displayInsuranceFiles =
      this.listenFrom.types === MODAL_TYPE.SAVE &&
      this.configService.feature.newPipelineInsurance &&
      this.formData.quoteId &&
      this.formData.FamilyID;
  }

  onSelectFile({ fileId }) {
    this.insuranceFileId = fileId;
  }

  // 3. Angular Functions
  cancel() {
    this.$uibModalInstance.dismiss('cancel');

    if (
      this.listenFrom.types === MODAL_TYPE.QUOTE &&
      this.broadcastDataService.cancelEmailQuoteReport
    ) {
      this.broadcastDataService.cancelEmailQuoteReport();
    }
  }

  close(response = 'close') {
    this.$uibModalInstance.close(response);
  }

  insuranceAssignToClient(familyId, insuranceId) {
    if (this.prefix !== 'quote') {
      this.insuranceProfilerService
        .profilerAssignToClient(familyId, insuranceId)
        .then((response) => {
          if (response.status === 200) {
            this.toaster.pop(
              'success',
              'Success',
              'Profiler successfully added to contact.',
            );
          } else {
            this.toaster.pop(
              'error',
              'Error',
              'Profiler failed to add in contact',
            );
          }
          this.close({ familyId });
        });
    } else {
      this.insuranceQuoteSet(familyId, insuranceId);
    }
  }

  insuranceQuoteSet(familyId, insuranceID) {
    this.linkQuoteToInsuranceFile(insuranceID);
    this.insuranceQuoteService
      .insuranceQuoteSet(
        insuranceID,
        familyId,
        this.formData.NumberOfClients,
        this.formData.NumberOfChildren,
        this.formData.favoriteProviderId,
      )
      .then((response) => {
        if (response.data) {
          const insuranceId = response.data;
          this.formData[`${this.prefix}Id`] = insuranceId;
          this.formData.savingNew = true;
          this.toaster.pop(
            'success',
            'Success',
            'Quote was successfully saved to contact.',
          );
          this.$state.go('app.quickQuoteDetails', {
            insQuoteId: insuranceId,
            familyId,
            insuranceFileId: this.$state.params.insuranceFileId || null,
          });

          if (
            this.formData.favoriteProviderId &&
            this.isOnlineInsuranceAppActive
          ) {
            this.insuranceQuoteService.favoriteProvider({
              quoteId: insuranceId,
              providerId: this.formData.favoriteProviderId,
            });
          }

          const eventTimeout = this.$timeout(() => {
            this.isConfirming = false;
            this.close({ familyId });
            if (this.listenFrom.types === 'app') {
              this.createNewLoanAppFromExisting(insuranceId);
            }
            this.$timeout.cancel(eventTimeout);
          }, 6000);
        } else {
          this.toaster.pop('error', 'Error', 'Error saving quote!');
        }
      });
  }

  createNewLoanAppFromExisting(paramQuoteId) {
    this.insuranceCreateApp.onCreate({
      typeNew: false,
      familyId: this.formData.FamilyID,
      quoteId: paramQuoteId,
      fullName: this.formData.FamilyFullName,
    });
  }

  getAdviserData(adviserData) {
    const familyMember = this.formData.familyInfo.map((member) => {
      member.allocatedAdviserId = parseInt(adviserData, 10);
      return member;
    });
    this.formData.familyInfo = familyMember;
    this.allocatedAdviserId = parseInt(adviserData, 10);
  }

  isTypeIncluded() {
    return [
      MODAL_TYPE.QUOTE,
      MODAL_TYPE.SAVE,
      MODAL_TYPE.SAVE_TO_CLIENT_DOCS,
    ].includes(this.listenFrom.types);
  }

  linkQuoteToInsuranceFile(quoteId) {
    if (!quoteId || !this.insuranceFileId) {
      return;
    }

    this.insuranceFileService.linkQuoteToInsuranceFile(
      this.insuranceFileId,
      quoteId,
    );
  }

  saveToContacts() {
    this.isConfirming = true;

    if (!this.allocatedAdviserId && !this.formData.FamilyID) {
      toastError('Allocated Adviser is Required');
      return;
    }
    if (!this.formData.FamilyID) {
      const familyMembers = this.formData.familyInfo.map((member) =>
        personToSetContactModel(member),
      );
      this.contactService.addContact(familyMembers).then((response) => {
        if (response.data) {
          this.formData.FamilyID = response.data;
          this.formData.savingNew = !this.formData.FamilyID;

          if (this.listenFrom.types === MODAL_TYPE.QUOTE) {
            this.close({ familyId: this.formData.FamilyID });
          }
          this.insuranceAssignToClient(
            this.formData.FamilyID,
            this.formData[`${this.prefix}Id`],
          );
        }
      });
    } else {
      this.insuranceAssignToClient(
        this.formData.FamilyID,
        this.formData[`${this.prefix}Id`],
      );
    }
  }
}

export default QuoteToContactCtrl;
