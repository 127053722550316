export const REQUESTER = {
  NEW_LOAN_APP_MODAL: 1,
  STANDARD: 2,
  VIEW_OF_FILE: 3,
};

export const REQUESTER_TYPE = {
  BORROWER: 1,
  GUARANTOR: 2,
};

export const SHOW_EXISTING_PANEL = {
  BORROWER: {
    PERSON: 3,
    COMPANY: 1,
  },
  GUARANTOR: {
    PERSON: 2,
    COMPANY: 4,
  },
};

export default { REQUESTER, REQUESTER_TYPE, SHOW_EXISTING_PANEL };
