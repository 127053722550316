import { PROVIDER_NAME } from 'Common/constants/lenderConstants';

class NavigationMobileCtrl {
  constructor(currentUserService) {
    'ngInject';

    this.currentUserService = currentUserService;
  }

  $onInit() {
    const providerName = this.currentUserService.isAU
      ? PROVIDER_NAME.AU
      : PROVIDER_NAME.DEFAULT;
    this.pageHeader = `${providerName} Information`;
  }

  showSubMenu(headerName) {
    this.sidebarMainObj = {};
    this.sidebarMainObj.isSubMenuEnabled = true;
    this.sidebarMainObj.subMenuHeaderName = headerName;
  }
}

export default NavigationMobileCtrl;
