import template from './lmiPredominant.html';
import './style.scss';

export default {
  template,
  bindings: {
    mortgageObj: '=',
    calculatorOptions: '<',
    showLmiFromAirtable: '<',
  },
  controller: 'LmiPredominantCtrl',
  controllerAs: 'vm',
};
