export default class CrmConfirmationCtrl {
  ok() {
    this.cOnConfirm && this.cOnConfirm({ dontShowAgain: this.dontShowAgain });
    this.cModalInstance.close({ isOk: true });
  }

  cancel() {
    if (this.dontShowAgain) {
      this.cModalInstance.close({ dontShowAgain: this.dontShowAgain });
    } else {
      this.cModalInstance.dismiss();
    }
  }

  customButtonAction(data) {
    this.cModalInstance.close({ data, isCustom: true });
  }
}
