import { sum, flatten, head } from 'lodash';
import {
  processDates,
  getLoanCategory,
  processLoanPurpose,
  checkLender,
  processExpiryWarningMessage,
} from 'Common/utilities/loan';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { parseToInt10 } from 'Common/utilities/parse';

class LoanService {
  constructor(
    $q,
    $state,
    $uibModal,
    contactSharedData,
    contactService,
    configService,
    utilitiesService,
    loanAppSharedData,
    crmConfirmation,
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.contactSharedData = contactSharedData;
    this.contactService = contactService;
    this.configService = configService;
    this.utilitiesService = utilitiesService;
    this.loanAppSharedData = loanAppSharedData;
    this.crmConfirmation = crmConfirmation;
  }

  removeBorrowerApplicant(applicants, valueIndex, partyType) {
    const defer = this.$q.defer();

    if (partyType === 'guarantor') {
      applicants.guarantors.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else if (partyType === 'solicitor') {
      applicants.solicitors.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else if (partyType === 'accountant') {
      applicants.accountants.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else if (partyType === 'dependant') {
      applicants.dependants.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else {
      const borrowers = flatten(applicants.borrowers);
      const soleTraders = flatten(applicants.soleTraders);
      const applicantCount = sum([
        applicants.entities.length,
        borrowers.length,
        soleTraders.length,
      ]);
      if (applicantCount > 1) {
        if (partyType === 'applicant') {
          applicants.borrowers.splice(valueIndex, 1);
        } else if (partyType === 'soleTrader') {
          applicants.soleTraders.splice(valueIndex, 1);
        } else {
          applicants.entities.splice(valueIndex, 1);
        }
        defer.resolve({ data: applicants });
      } else {
        defer.reject();
      }
    }
    return defer.promise;
  }

  loanListGet(familyId) {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    return this.contactService.loanListGet(familyId, true).then((response) => {
      const loanData = {};
      const { data } = response;
      const loans = {
        opportunityLoans: [],
        existingLoans: [],
        previousLoans: [],
        inprogressLoans: [],
        activeDeals: [],
      };

      data &&
        data.forEach((value) => {
          if (value) {
            processDates(value);
            value.hasLender = checkLender(value);

            const loanCategory = getLoanCategory(value);
            const { LoanStatusID } = value.LoanStatus;
            const validInvolvedParties = value.Borrower?.reduce(
              (validParties, borrower) => {
                if (borrower.IsEntity) {
                  return validParties;
                }
                return [...validParties, borrower];
              },
              [],
            );
            value.isHelloPackDisabled = !validInvolvedParties?.length;
            if (loanCategory?.isExisting) {
              loans.existingLoans.push(value);
            } else if (loanCategory?.isInProgress) {
              loans.inprogressLoans.push(value);
            } else if (loanCategory?.isOpportunity || loanCategory?.isLead) {
              value.IsPrevious =
                LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH ===
                parseInt(LoanStatusID, 10);
              loans.opportunityLoans.push(value);
            } else if (loanCategory?.isPrevious) {
              value.IsPrevious = true;
              loans.previousLoans.push(value);
            } else if (
              this.configService.feature.newPipeline &&
              value.loanId &&
              value.loanAppId
            ) {
              loans.activeDeals.push(value);
            } else {
              loans.previousLoans.push(value);
            }

            if (value.LoanPurpose) {
              processLoanPurpose(value);
            }
            const family = head(value.FamilyList);
            if (family && family.AdviserFullName) {
              family.Initials = this.utilitiesService.filterInitialOneString(
                family.AdviserFullName,
              );
            }
          }
        });

      loanData.opportunityLoans = this.loanAppSharedData.distinguishPartyForLendingTab(
        loans.opportunityLoans,
      );
      loanData.existingLoans = this.loanAppSharedData
        .distinguishPartyForLendingTab(loans.existingLoans)
        .map(processExpiryWarningMessage);
      loanData.previousLoans = this.loanAppSharedData.distinguishPartyForLendingTab(
        loans.previousLoans,
      );
      loanData.inprogressLoans = this.loanAppSharedData.distinguishPartyForLendingTab(
        loans.inprogressLoans,
      );
      loanData.activeDeals = this.loanAppSharedData.distinguishPartyForLendingTab(
        loans.activeDeals,
      );
      return loanData;
    });
  }

  setDefaultLoanStatusForImportantDates(loanDetails, defaultStatusLoanId) {
    if (!loanDetails || !defaultStatusLoanId) {
      return;
    }
    loanDetails.defaultStatusLoanId = `${defaultStatusLoanId}`;
    const currentLoanStatus =
      (loanDetails.LoanStatus && loanDetails.LoanStatus.LoanStatusID) || '';
    if (currentLoanStatus === loanDetails.defaultStatusLoanId) {
      return;
    }
    const modalInstance = this.crmConfirmation.open({
      type: 'success',
      title: 'Do you want to update the pipeline status too?',
      description: `<loan-status-selector loan-details="vm.cCommonObject"></loan-status-selector>`,
      buttonText: 'Update Loan Status',
      modalSize: 'md',
      showCloseButton: true,
      renderAsComponent: true,
      showCancelButton: true,
      isButtonMaxWidth: true,
      cancelButtonClass: 'colored',
      cancelButtonText: `Don't update`,
      commonObject: loanDetails,
    });
    return modalInstance.result;
  }

  openLoanInformationModal({
    familyId,
    loanId,
    forFixedRateCustomerCare,
    forInterestOnlyExpiry,
    action = 'edit',
    type = 'npw',
    size = 'wide',
    windowClass = '',
  }) {
    if (!familyId || !loanId) {
      return;
    }
    const params = {
      loanInformationAction: action,
      familyId: parseToInt10(familyId),
      loanId,
      ...forFixedRateCustomerCare,
      ...forInterestOnlyExpiry,
    };
    const modalInstance = this.$uibModal.open({
      templateUrl: '/assets/views/contacts/partials/loan_modal_v2.html',
      controller: 'LoanInformationCtrl',
      size,
      backdrop: 'static',
      keyboard: false,
      windowClass,
      resolve: {
        params: () => params,
        loanType: () => type,
      },
    });
    return modalInstance.result;
  }
}

export default LoanService;
