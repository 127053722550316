import { taskPdfBuilderForUI } from 'Common/mappers/tasks.js';
import {
  brokerBuilderForUI,
  dateRangeBuilderForUI,
  sendMailDocBuilderForUI,
  fixedRateExpiryListBuilderForUI,
  interestOnlyExpiryListBuilderForUI,
  preApprovalCompletionBuilderForMyCRM,
} from 'Common/mappers/customerCare';

class CustomerCareService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'CustomerCare';
  }

  exportPreApprovalExpiry(queryParams, ids) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/export/pre-approval-expiry?${ids}`, queryParams)
      .then((response) => taskPdfBuilderForUI(response.data));
  }

  exportInterestOnlyExpiry(queryParams, loanStructureIdsString) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/export/interest-only-expiry?${loanStructureIdsString}`,
        queryParams,
      )
      .then((response) => taskPdfBuilderForUI(response.data));
  }

  exportAnnualReview(queryParams, loanStructureIdsString) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/export/annual-review?${loanStructureIdsString}`,
        queryParams,
      )
      .then((response) => taskPdfBuilderForUI(response.data));
  }

  exportPostSettlement(queryParams, ids) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/export/post-settlement?${ids}`, queryParams)
      .then(({ data }) => taskPdfBuilderForUI(data));
  }

  FixedRateExpiryListGet(
    searchedAdvisorID,
    fromDate,
    toDate,
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    isCompleted,
  ) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/FixedRateExpiryListGet`, {
        searchedAdvisorID,
        fromDate,
        toDate,
        pageNumber,
        pageSize,
        sortColumn,
        sortType,
        isCompleted,
      })
      .then(({ data }) => data && fixedRateExpiryListBuilderForUI(data));
  }

  interestOnlyExpiryListGet(
    searchedAdvisorID,
    fromDate,
    toDate,
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    isCompleted,
  ) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/InterestOnlyExpiryListGet`, {
        searchedAdvisorID,
        fromDate,
        toDate,
        pageNumber,
        pageSize,
        sortColumn,
        sortType,
        isCompleted,
      })
      .then(({ data }) => data && interestOnlyExpiryListBuilderForUI(data));
  }

  PreApprovalExpiresListGet(
    searchedAdvisorID,
    fromDate,
    toDate,
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    isCompleted,
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/PreApprovalExpiresListGet`, {
      searchedAdvisorID,
      fromDate,
      toDate,
      pageNumber,
      pageSize,
      sortColumn,
      sortType,
      isCompleted,
    });
  }

  BirthDayListGet(
    searchedAdvisorID,
    fromDate,
    toDate,
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    isCompleted,
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/BirthDayListGet`, {
      searchedAdvisorID,
      fromDate,
      toDate,
      pageNumber,
      pageSize,
      sortColumn,
      sortType,
      isCompleted,
    });
  }

  ClientsAnnualReviewListGet(
    searchedAdvisorID,
    fromDate,
    toDate,
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    isCompleted,
    isIncludeSubscribersToMktg = false,
  ) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ClientsAnnualReviewListGet`,
      {
        searchedAdvisorID,
        fromDate,
        toDate,
        pageNumber,
        pageSize,
        sortColumn,
        sortType,
        isCompleted,
        isIncludeSubscribersToMktg,
      },
    );
  }

  SettlementmentDateListGet(
    searchedAdvisorID,
    fromDate,
    toDate,
    pageNumber,
    pageSize,
    sortColumn,
    sortType,
    isCompleted,
    filterTab,
    postSettlementTypeId,
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/SettlementmentDateListGet`, {
      searchedAdvisorID,
      fromDate,
      toDate,
      pageNumber,
      pageSize,
      sortColumn,
      sortType,
      isCompleted,
      filterTab,
      postSettlementTypeId,
    });
  }

  exportFixedRateExpiry(queryParams, loanStructureIdsString) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/export/fixed-rate-expiry?${loanStructureIdsString}`,
        queryParams,
      )
      .then((response) => taskPdfBuilderForUI(response.data));
  }

  exportBirthdays(queryParams, ids) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/export/birthday-list?${ids}`, queryParams)
      .then(({ data }) => taskPdfBuilderForUI(data));
  }

  getBrokers(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/brokers`, params, true)
      .then(
        (response) => response.data && response.data.map(brokerBuilderForUI),
      );
  }

  birthdaySpreadsheet(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/birthday-spreadsheet`, params)
      .then((response) => taskPdfBuilderForUI(response.data));
  }

  customerCareDateRange() {
    return this.httpClient
      .post(`${this.apiBaseUrl}/date-range`)
      .then(({ data }) => data && data.map(dateRangeBuilderForUI));
  }

  setCompeletion(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/CompeletionSet`, data);
  }

  CustomerCareEmailSendv2(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/CustomerCareEmailSendv2`,
      data,
    );
  }

  CustomerCareSMSSend(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/CustomerCareSMSSend`, data);
  }

  isAnnualReviewAllowed() {
    return this.httpClient.get(`${this.apiBaseUrl}/is-annualreview-allowed`);
  }

  CustomerCareMailSend(data) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/CustomerCareMailSend`, data)
      .then((response) => sendMailDocBuilderForUI(response.data));
  }

  setPreApprovalCompletion(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/PreApprovalCompletionSet
      `,
      preApprovalCompletionBuilderForMyCRM(data),
    );
  }

  setBirthdayCompletion(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/BirthDayCompletionSet`,
      data,
    );
  }

  setAnnualReviewCompletion(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/AnnualReviewCompletionSet`,
      data,
    );
  }
}

export default CustomerCareService;
