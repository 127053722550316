import get from 'lodash/get';
import { PERSON_PARTY_TYPES } from 'Common/constants/partyTypes';
import { parseToInt10 } from 'Common/utilities/parse';
import { toggleDependantLoanAppInclusion } from 'Common/utilities/dependant';
import { showCustomConfirmation, toastError } from 'Common/utilities/alert';

class LoanDependantCtrl {
  constructor(dependantModelService, crmConfirmation, uiService) {
    'ngInject';

    this.dependantModelService = dependantModelService;
    this.crmConfirmation = crmConfirmation;
    this.uiService = uiService;
    this.PERSON_PARTY_TYPES = PERSON_PARTY_TYPES;
  }

  $onInit() {
    this.guardianList = [];
  }

  $onChanges(change) {
    const { selectedPartyType } = change;
    selectedPartyType &&
      typeof selectedPartyType.currentValue !== 'undefined' &&
      (this.selectedPartyType = parseToInt10(selectedPartyType.currentValue));
  }

  filterGuardianList(familyId) {
    if (!familyId) {
      return;
    }
    this.guardianList = [...this.involvedPartyPerson[familyId]];
    const personId = get(this.guardianList, '[0].PersonId', null);
    this.dependants.ParentGuardian = personId;
  }

  addDependant(event) {
    event.stopPropagation();
    this.selectedClientName = '';
    this.resetSearch({ type: this.PERSON_PARTY_TYPES.DEPENDENT });
    this.filterGuardianList(this.dependants.FamilyId);
  }

  deleteDependant(dependant) {
    if (!dependant) {
      return;
    }
    const { PreferredName, PersonId } = dependant;
    const name = PreferredName || 'this dependent';
    const applicationName = this.uiService.viewOfAfileTurnedOn
      ? 'deal'
      : 'loan application';

    showCustomConfirmation(
      'Are you sure?',
      `Are you sure you want to delete ${name} from MyCRM?
      (To remove from this ${applicationName} only, use the toggle)`,
      'Yes, remove it!',
      'Cancel',
      '',
      (confirm) => confirm && this.confirmDelete(PersonId),
    );
  }

  confirmDelete(personId) {
    if (!personId) {
      return;
    }
    try {
      this.dependantModelService
        .deleteDependant(this.loanAppId, personId)
        .then(() => {
          this.refreshOnDeleteDependant();
        })
        .catch(toastError);
    } catch (error) {
      toastError(error);
    }
  }

  toggleDependant(dependant) {
    if (!dependant) {
      return;
    }
    const { FamilyId, PersonId, IncludedInLoanApp } = dependant;
    const payload = {
      loanScenarioID: this.loanAppId,
      familyID: FamilyId,
      clientID: PersonId,
      includedInLoanApp: IncludedInLoanApp,
    };
    toggleDependantLoanAppInclusion({
      dependantModelService: this.dependantModelService,
      payload,
    });
  }
}
export default LoanDependantCtrl;
