import {
  CLIENT_TYPE_IDS,
  CONTACT_RECORD_TYPE_IDS,
} from 'Common/constants/contactType';

export const GENDER_OPTIONS = [
  { value: 1, name: 'Male' },
  { value: 2, name: 'Female' },
];

export const ADD_PROPERTY_TYPE = {
  NEW_PURCHASE: 1,
  EXISTING: 2,
};

export const CLIENT_TYPES = [
  { value: CLIENT_TYPE_IDS.CLIENTS, name: 'Clients' },
  { value: CLIENT_TYPE_IDS.ENTITIES, name: 'Entities' },
];

export const CONTACT_RECORD_TYPES = [
  { value: CONTACT_RECORD_TYPE_IDS.INDIVIDUAL, name: 'Individual' },
  { value: CONTACT_RECORD_TYPE_IDS.FAMILY, name: 'Family' },
];

export const MARKETING_TICKED_OPTIONS = [
  { value: null, name: 'All Consent Types' },
  { value: true, name: 'Yes' },
  { value: false, name: 'No' },
];
