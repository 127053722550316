import template from './communicationBox.html';
import controller from './communicationBoxCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    communication: '<',
    onViewEmail: '&',
    onDeleteCommunication: '&',
  },
};
