import { emailExistBuilderForUI } from 'Common/mappers/userAccount';
import { getLogger } from '@loanmarket/logger-core';

class UserAccountModelService {
  constructor(httpClient) {
    'ngInject';

    this.apiBaseUrl = `identity/users/account`;
    this.logger = getLogger('UserAccountModelService');
    this.httpClient = httpClient;
  }

  async getEmailExist(email) {
    try {
      const { data } = await this.httpClient.get(
        `${this.apiBaseUrl}/availability?email=${email}`,
        {},
      );
      return emailExistBuilderForUI({ ...data, email });
    } catch (error) {
      this.logger.error('Get email exist failed:', error);
      return error;
    }
  }
}

export default UserAccountModelService;
