export const CONFIG_EMAIL = {
  ONBOARDING_AU: {
    email: 'onboarding@loanmarket.com.au',
    description: 'Onboarding',
  },
  MARKETING_AU: {
    email: 'marketing@loanmarket.com.au',
    description: 'Marketing',
  },
  ADVISER_SERVICES_NZ: {
    email: 'adviserservices@nzfsg.co.nz',
    description: 'Adviser Services',
  },
  IT_SUPPORT: {
    email: 'itsupport@loanmarket.com.au',
    description: 'IT Support',
  },
  MYCRM_SUPPORT: {
    email: 'mycrmsupport@loanmarket.com.au',
    description: 'MyCRM Support',
  },
  TEAM_SERVICES_NZ: {
    email: 'team@nzfsg.co.nz',
    description: 'Team Services',
  },
};

export const COMMISSION_EMAIL = 'commissions@loanmarket.com.au';
