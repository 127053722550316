class CurrencyFormattingCtrl {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;
  }

  $onInit() {
    this.amountValue = '';
    this.currencyFormat();
  }

  $onChanges(changes) {
    const { amount } = changes;

    if (this.acceptZero) {
      this.amount = amount.currentValue;
      this.currencyFormat();
      return;
    }

    this.amount = amount.currentValue;
    this.currencyFormat();
  }

  currencyFormat() {
    if (this.testAmount) {
      return;
    }
    if (this.uiService.isCountry('Indonesia')) {
      const currencyCode =
        typeof this.symbol === 'undefined' ? 'Rp' : this.symbol;
      const splitAmount = this.setDecimal().split('.');
      let integerAmount = splitAmount && splitAmount.length && splitAmount[0];
      integerAmount = integerAmount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      const decimalAmount =
        splitAmount && splitAmount.length > 1 && splitAmount[1];
      if (parseInt(decimalAmount, 10) || this.precision) {
        this.amountValue = `${currencyCode} ${integerAmount},${decimalAmount}`;
      } else {
        this.amountValue = `${currencyCode} ${integerAmount}`;
      }
    } else {
      const currencyCode =
        typeof this.symbol === 'undefined' ? '$' : this.symbol;
      const amount = this.setDecimal();
      const amountSign = Math.sign(amount).toString().replace(/\d/g, '');
      const absoluteAmount = Math.abs(amount).toFixed(this.getDecimalPlaces());
      const commaSeparatedAmount = absoluteAmount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.amountValue = `${amountSign}${currencyCode}${commaSeparatedAmount}`;
    }
  }

  setDecimal() {
    const amount = this.amount || 0;
    const places = typeof this.precision === 'undefined' ? 2 : this.precision;
    const decimalValue = new Array(+(places > 0 && places + 1)).join('0');
    const factor = `1${decimalValue}`;
    return parseFloat(Math.round(amount * factor) / factor).toFixed(places);
  }

  getDecimalPlaces() {
    return typeof this.precision === 'undefined' ? 2 : this.precision;
  }
}

export default CurrencyFormattingCtrl;
