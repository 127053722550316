import { findIndex } from 'lodash';
import { showActionConfirmation, toastError } from 'Common/utilities/alert';
import swal from 'sweetalert';

const updateDocInList = (list, updatedDoc) => {
  const indexOfDoc = findIndex(
    list,
    (docInList) => docInList.listId === updatedDoc.listId,
  );
  return [
    ...list.slice(0, indexOfDoc),
    { ...list[indexOfDoc], ...updatedDoc },
    ...list.slice(indexOfDoc + 1),
  ];
};

const sortDocCategories = (categories) => {
  if (!categories) {
    return;
  }
  const orderedCategories = categories.sort((left, right) => {
    if (left.name < right.name) {
      return -1;
    }
    if (left.name > right.name) {
      return 1;
    }
    return 0;
  });
  const everythingElseIndex = findIndex(
    orderedCategories,
    (category) => category.name === 'Everything Else',
  );
  if (everythingElseIndex === -1) {
    return orderedCategories;
  }
  return [
    ...orderedCategories.slice(0, everythingElseIndex),
    ...orderedCategories.slice(everythingElseIndex + 1),
    orderedCategories[everythingElseIndex],
  ];
};

export default class ProviderInfoToolsDownloadsCtrl {
  constructor(
    providerUploadDocsService,
    providerInformationService,
    currentUserService,
  ) {
    'ngInject';

    this.providerUploadDocsService = providerUploadDocsService;
    this.providerInformationService = providerInformationService;
    this.currentUserService = currentUserService;
    this.tabs = true;
  }

  $onInit() {
    this.downloadablesType = this.currentUserService.isAU
      ? 'categorised'
      : 'uncategorised';
    this.loadDocuments();
  }

  loadDocuments(showLoading = true) {
    this.favoritesLoading = showLoading;
    this.downloadablesLoading = showLoading;
    this.providerInformationService
      .getProviderDocuments(
        this.providerId,
        this.currentUserService.isNZ,
        this.isInsurer,
      )
      .then((response) => {
        this.downloadables = this.currentUserService.isAU
          ? sortDocCategories(response.categories)
          : response.documents;
        this.favorites = response.favorites;
        this.unfilteredDownloadables = [...this.downloadables];
        this.unfilteredFavorites = [...this.favorites];
      })
      .finally(() => {
        this.favoritesLoading = false;
        this.downloadablesLoading = false;
      });
  }

  flattenDocumentCategories() {
    return (
      (this.downloadables &&
        this.downloadables.map((category) => category.name)) ||
      []
    );
  }

  uploadDocuments() {
    const documentCategories = this.flattenDocumentCategories();
    this.providerUploadDocsService
      .onUploadFiles({
        providerId: this.providerId,
        service: this.providerInformationService,
        documentCategories,
      })
      .result.then(() => {
        this.loadDocuments(false);
      });
  }

  toggleTabs(state) {
    this.tabs = state;
  }

  changeFavoriteStatus(doc) {
    if (!doc) {
      return;
    }
    doc.isFavorite = doc.isFavorite === 1 ? 0 : 1;
    const { isFavorite, isRubik, fileName, id: providerDocId } = doc;
    return this.providerInformationService
      .setProviderDocFavoriteState(doc.id, {
        isFavourite: !!isFavorite,
        providerDocId,
        providerId: this.providerId,
        isRubik: !!isRubik,
        fileName,
      })
      .catch(toastError);
  }

  delete(doc) {
    if (!doc) {
      return;
    }
    showActionConfirmation(
      'Are you sure?',
      `This will remove ${doc.title} from the list.`,
      (confirm) => {
        if (!confirm) {
          return;
        }
        this.providerInformationService
          .deleteProviderDoc(doc.id)
          .then(() => {
            swal(
              'Success',
              `${doc.title} has been successfully deleted.`,
              'success',
            );
            if (this.currentUserService.isAU) {
              this.deleteFromCategory(doc);
            } else {
              this.deleteFromUncategorisedList(doc);
            }
            if (doc.isFavorite) {
              this.updateFavorites(doc, 'delete');
            }
          })
          .catch(toastError);
      },
    );
  }

  deleteFromCategory(doc) {
    const docCategory = this.findDocCategory(doc);
    const indexOfDoc = findIndex(
      docCategory.documents,
      (docInCategory) => docInCategory.listId === doc.listId,
    );
    docCategory.documents = [
      ...docCategory.documents.slice(0, indexOfDoc),
      ...docCategory.documents.slice(indexOfDoc + 1),
    ];
    this.downloadables = this.filterDownloadables(this.searchKeyword);
  }

  deleteFromUncategorisedList(doc) {
    const indexOfDoc = findIndex(
      this.unfilteredDownloadables,
      (docInList) => docInList.listId === doc.listId,
    );
    this.unfilteredDownloadables = [
      ...this.unfilteredDownloadables.slice(0, indexOfDoc),
      ...this.unfilteredDownloadables.slice(indexOfDoc + 1),
    ];
    this.downloadables = this.filterDownloadables(this.searchKeyword);
  }

  removeFromFavorites(doc) {
    const promise = this.changeFavoriteStatus(doc);
    promise &&
      promise.then(() => {
        this.updateFavorites(doc, 'delete');
        this.updateDownloadables(doc);
      });
  }

  updateFavorites(doc, updateType) {
    if (updateType === 'delete') {
      const indexOfDoc = findIndex(
        this.unfilteredFavorites,
        (favoriteDoc) => favoriteDoc.listId === doc.listId,
      );
      this.unfilteredFavorites = [
        ...this.unfilteredFavorites.slice(0, indexOfDoc),
        ...this.unfilteredFavorites.slice(indexOfDoc + 1),
      ];
    } else {
      this.unfilteredFavorites = [...this.unfilteredFavorites, doc];
    }
    this.favorites = this.filterFavorites(this.searchKeyword);
  }

  updateDownloadables(doc) {
    if (this.currentUserService.isAU) {
      const category = this.findDocCategory(doc);
      category.documents = updateDocInList(category.documents, doc);
    } else {
      this.unfilteredDownloadables = updateDocInList(
        this.unfilteredDownloadables,
        doc,
      );
      this.downloadables = this.filterDownloadables(this.searchKeyword);
    }
  }

  findDocCategory(doc) {
    return this.unfilteredDownloadables.find((category) => {
      return (
        category.documents &&
        !!category.documents.find((docInCategory) => {
          return docInCategory.listId === doc.listId;
        })
      );
    });
  }

  filterFavorites(keyword) {
    const isValidKeyword = keyword && keyword.length >= 3;
    if (!isValidKeyword) {
      return [...this.unfilteredFavorites];
    }
    const keywordLowerCase = keyword.toLowerCase();
    return this.unfilteredFavorites.filter((doc) => {
      return doc.title.toLowerCase().includes(keywordLowerCase);
    });
  }

  filterDownloadables(keyword) {
    const isValidKeyword = keyword && keyword.length >= 3;
    const keywordLowerCase = keyword && keyword.toLowerCase();
    if (this.currentUserService.isAU) {
      return isValidKeyword
        ? this.unfilteredDownloadables.reduce((accum, category) => {
            const categoryDocs =
              category.documents &&
              category.documents.filter((doc) => {
                return doc.title.toLowerCase().includes(keywordLowerCase);
              });
            if (!categoryDocs || !categoryDocs.length) {
              return accum;
            }
            return [...accum, { ...category, documents: [...categoryDocs] }];
          }, [])
        : [...this.unfilteredDownloadables];
    }
    return isValidKeyword
      ? this.unfilteredDownloadables.filter((doc) => {
          return doc.title.toLowerCase().includes(keywordLowerCase);
        })
      : [...this.unfilteredDownloadables];
  }

  searchDocs(keyword) {
    if (keyword && keyword.length < 3) {
      return;
    }
    if (this.unfilteredFavorites) {
      this.favorites = this.filterFavorites(keyword);
    }
    if (this.unfilteredDownloadables) {
      this.downloadables = this.filterDownloadables(keyword);
    }
  }

  onChangeFavoriteStatus(doc) {
    const promise = this.changeFavoriteStatus(doc);
    promise &&
      promise.then(() => {
        const updateType = doc.isFavorite ? 'insert' : 'delete';
        this.updateFavorites(doc, updateType);
      });
  }
}
