class LoanVariationsDetailsCtrl {
  constructor(loanVariationsService) {
    'ngInject';
    this.loanVariationsService = loanVariationsService;
  }

  $onInit() {
    this.loanVariationDetails = {};
    this.getLoanVariationDetails();
  }

  getLoanVariationDetails() {
    return this.loanVariationsService
      .getLoanVariationDetails(this.loanDetails.LoanID, true)
      .then((data) => {
        if (!data || !data.primaryTopUpOrVariationTypeId) {
          return;
        }
        this.loanVariationDetails = data;
      });
  }
}

export default LoanVariationsDetailsCtrl;
