import { objectLength } from 'Common/utilities/objectValidation';
import { E_SIGN_ACOUNT_TYPE } from 'Common/constants/eSign';
import { LOAN_APP_ESIGN } from 'Common/constants/performance';
import { performance } from 'Common/utilities/perfume';
import { performanceTimingData } from 'Common/utilities/dataLayer';

import {
  getInvolvePartyUtil,
  checkWarningsUtil,
  onContactSavedUtil,
  getRecentPackageUtil,
  checkRequiredParameterUtil,
  checkEmailValidUtil,
  checkDuplicateUtil,
  mapApplicantUtil,
  updateWarningLimitUtil,
  sendEsignInitUtil,
} from 'Common/utilities/sendViaEsign';

const SEND = {
  CQP: 1,
  CPA: 2,
  BOTH: 3,
};

class SendReportsToEsign {
  constructor(
    $uibModal,
    eSignService,
    uiService,
    countryCodeService,
    loanScenarioService,
    crmConfirmation,
    configService,
    loanAppSharedData,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.eSignService = eSignService;
    this.uiService = uiService;
    this.countryCodeService = countryCodeService;
    this.loanScenarioService = loanScenarioService;
    this.crmConfirmation = crmConfirmation;
    this.configService = configService;
    this.loanAppSharedData = loanAppSharedData;
  }

  $onInit() {
    sendEsignInitUtil({ vm: this });
  }

  isEsignEnabled() {
    const isEsignEnabled =
      (this.configService.feature && this.configService.feature.esign) || 0;
    if (isEsignEnabled) {
      return;
    }
    this.closeModal();
  }

  getInvolveParty() {
    getInvolvePartyUtil({ vm: this });
  }

  updateWarningLimit() {
    updateWarningLimitUtil({ vm: this });
  }

  checkWarnings() {
    checkWarningsUtil({ vm: this });
  }

  mapApplicant(data) {
    return mapApplicantUtil({ vm: this, data });
  }

  checkDuplicate() {
    checkDuplicateUtil({ vm: this });
  }

  checkEmailValid(signee) {
    checkEmailValidUtil({ vm: this, signee });
  }

  getRecentPackage() {
    getRecentPackageUtil({ vm: this });
  }

  checkRequiredParameter() {
    checkRequiredParameterUtil({ vm: this });
  }

  reviewedCpa() {
    this.isCPAReviewed = true;
    this.disabledSendToEsign();
  }

  reviewedCqp() {
    this.isCQPReviewed = true;
    this.disabledSendToEsign();
  }

  removeCpa() {
    this.disabledSendToEsign();
    if (!this.isSendCpa) {
      return;
    }
    this.isSendCpa = false;
  }

  removeCqp() {
    this.disabledSendToEsign();
    if (!this.isSendCqp) {
      return;
    }
    this.isSendCqp = false;
  }

  disabledSendToEsign() {
    this.isSendToEsignDisabled =
      (!this.isSendCqp && !this.isSendCpa) ||
      this.isWaitSubmitResponse ||
      (this.warnings && this.warnings.length) ||
      !this.isCheckedTerms ||
      (this.isSendCqp && !this.isCQPReviewed) ||
      (this.isSendCpa && !this.isCPAReviewed) ||
      this.eSignAccountType !== E_SIGN_ACOUNT_TYPE.ACTIVE;
  }

  sendToEsign() {
    performance.start(LOAN_APP_ESIGN);
    if (this.isSendToEsignDisabled) {
      return;
    }
    this.isWaitSubmitResponse = true;
    this.sendButtonText = `Sending ...`;
    this.type = this.isSendCqp && this.isSendCpa ? SEND.BOTH : 0;
    this.type = this.isSendCqp && !this.isSendCpa ? SEND.CQP : this.type;
    this.type = !this.isSendCqp && this.isSendCpa ? SEND.CPA : this.type;
    const {
      eSignHasExistingSignature: hasExistingSignature,
      LoanApplicationDetails: loanApplicationDetails,
    } = this.loanAppSharedData;
    const { AdviserId: brokerId } = loanApplicationDetails;
    const param = {
      type: this.type,
      hasExistingSignature,
      brokerId,
    };

    this.eSignService.getSignDocument(this.loanId, param).then((response) => {
      this.isWaitSubmitResponse = false;
      if (!response || !objectLength(response)) {
        return;
      }
      this.closeAllModals();
      this.crmConfirmation.open({
        type: 'success',
        title: 'Documents Sent',
        description:
          'The documents you have requested to be signed by your client(s) have now been sent to eSign to manage.  Your client(s) will soon receive an email inviting them to provide a signature on the relevant documents.  You will be updated via email when this has been completed.',
        buttonText: 'Okay got it!',
        modalSize: 'md',
      });
    });
    const duration = performance.endPaint(LOAN_APP_ESIGN);
    const label = this.type ? Object.keys(SEND)[this.type - 1] || '' : '';
    performanceTimingData({
      action: LOAN_APP_ESIGN,
      label: label.toLowerCase(),
      value: duration,
    });
  }

  onContactSaved({ succeeded }) {
    onContactSavedUtil({ vm: this, succeeded });
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }

  closeAllModals() {
    this.modalInstance.close({ closeAll: true });
  }
}
export default SendReportsToEsign;
