import { orderBy } from 'lodash';
import {
  OPTION_FEATURE,
  DISPLAY_OPTIONS,
  SPECIAL_RATE_PREFERENCES,
  SPECIAL_RATE_PREFERENCES_IMPORTANT,
  SPECIAL_RATE_IMPORTANT,
  REPAYMENT_PREFERENCE,
  REPAYMENT_PREFERENCE_IMPORTANT,
  REPAYMENT_IMPORTANT,
  LIST_OF_QUESTIONS,
  LIST_OF_QUESTIONS_NZ,
  QUESTIONS_WITH_ASSOCIATED_RISKS,
  QUESTIONS_WITH_TEXTBOX,
  OPTIONAL_QUESTIONS,
  ASSOCIATED_QUESTION,
} from 'Common/constants/customerObjectives';

export const getCustomerObjQuestions = ({ isAU, isNZ }) => {
  if (isAU) {
    return LIST_OF_QUESTIONS;
  }
  if (isNZ) {
    return LIST_OF_QUESTIONS_NZ;
  }
  return {};
};

export const isQuestionAssociatedRisks = ({ countryId }) => (questionId) => {
  return QUESTIONS_WITH_ASSOCIATED_RISKS[countryId].includes(questionId);
};

export const isQuestionTextbox = ({ countryId }) => (questionId) => {
  return QUESTIONS_WITH_TEXTBOX[countryId].includes(questionId);
};

export const isAssociatedQuestion = ({ countryId }) => (questionId) => {
  return ASSOCIATED_QUESTION[countryId].includes(questionId);
};

export const isOptionalQuestion = ({ countryId }) => (questionId) => {
  return OPTIONAL_QUESTIONS[countryId].includes(questionId);
};

export function getAssociateQuestion(question, isAssociated) {
  const isQuestionCheckbox =
    question.QuestionDisplayTypeId === DISPLAY_OPTIONS.CHECK_BOX;
  if (!isAssociated || !isQuestionCheckbox) {
    return null;
  }
  if (isQuestionCheckbox) {
    return question;
  }
}

export function getSpecialImportantOption(answerList, importantOption) {
  const specialImportantOption = [];
  let filteredData = [];
  answerList.forEach((obj) => {
    const answer = obj.value || `${obj}`;
    switch (answer) {
      case SPECIAL_RATE_PREFERENCES.OFFSET_ACCOUNT:
        filteredData = importantOption.filter((item) =>
          SPECIAL_RATE_PREFERENCES_IMPORTANT.OFFSET_ACCOUNT.includes(
            item.value,
          ),
        );
        specialImportantOption.push(...filteredData);
        break;

      case SPECIAL_RATE_PREFERENCES.REDRAW:
        filteredData = importantOption.filter((item) =>
          SPECIAL_RATE_PREFERENCES_IMPORTANT.REDRAW.includes(item.value),
        );
        specialImportantOption.push(...filteredData);
        break;

      default:
    }
  });

  filteredData = importantOption.filter((item) => {
    return item.value === SPECIAL_RATE_IMPORTANT.OTHER;
  });
  specialImportantOption.push(...filteredData);
  return specialImportantOption;
}

export function getRepaymentOption(repaymentAnswers, importantOption) {
  const repaymentImportantOption = [];
  let filteredData = [];

  repaymentAnswers.forEach((obj) => {
    const answer = obj.value || `${obj}`;
    switch (answer) {
      case REPAYMENT_PREFERENCE.PRINCIPAL_AND_INTEREST:
        filteredData = importantOption.filter((item) =>
          REPAYMENT_PREFERENCE_IMPORTANT.PRINCIPAL_AND_INTEREST.includes(
            item.value,
          ),
        );
        repaymentImportantOption.push(...filteredData);
        break;

      case REPAYMENT_PREFERENCE.INTEREST_ONLY_REPAYMENTS:
        filteredData = importantOption.filter((item) =>
          REPAYMENT_PREFERENCE_IMPORTANT.INTEREST_ONLY_REPAYMENTS.includes(
            item.value,
          ),
        );
        repaymentImportantOption.push(...filteredData);
        break;

      case REPAYMENT_PREFERENCE.INTEREST_IN_ADVANCE:
        filteredData = importantOption.filter((item) =>
          REPAYMENT_PREFERENCE_IMPORTANT.INTEREST_IN_ADVANCE.includes(
            item.value,
          ),
        );
        repaymentImportantOption.push(...filteredData);
        break;

      case REPAYMENT_PREFERENCE.LINE_OF_CREDIT:
        filteredData = importantOption.filter((item) =>
          REPAYMENT_PREFERENCE_IMPORTANT.LINE_OF_CREDIT.includes(item.value),
        );
        repaymentImportantOption.push(...filteredData);
        break;

      default:
    }
  });

  filteredData = importantOption.filter((item) => {
    return item.value === REPAYMENT_IMPORTANT.OTHER;
  });
  repaymentImportantOption.push(...filteredData);

  return repaymentImportantOption;
}

export function getOptions(optionList, questionId) {
  const optionData =
    optionList.find((option) => option.questionId === questionId) || {};

  const isInterestOnlyPeriod =
    questionId === LIST_OF_QUESTIONS.INTEREST_ONLY_PERIOD ||
    questionId === LIST_OF_QUESTIONS_NZ.INTEREST_ONLY_PERIOD;
  if (isInterestOnlyPeriod) {
    optionData.options = orderBy(optionData.options, ['value']);
  }

  return optionData.options || null;
}

export const getOptionQuestions = (QUESTION_LIST) => {
  return {
    [QUESTION_LIST.SPECIAL_RATE_PREFERENCES_IMPORTANT]: {
      [OPTION_FEATURE.OFFSET_ACCOUNT]: QUESTION_LIST.OFFSET_ACCOUNT,
      [OPTION_FEATURE.REDRAW]: QUESTION_LIST.REDRAW,
    },
    [QUESTION_LIST.SPECIAL_CHOOSE_RATE_PREFERENCE]: {
      [OPTION_FEATURE.OFFSET_ACCOUNT]: QUESTION_LIST.OFFSET_ACCOUNT,
      [OPTION_FEATURE.REDRAW]: QUESTION_LIST.REDRAW,
    },
    [QUESTION_LIST.CHOOSE_REPAYMENT_PREFERENCE]: {
      [REPAYMENT_PREFERENCE.PRINCIPAL_AND_INTEREST]:
        QUESTION_LIST.PRINCIPAL_AND_INTEREST,
      [REPAYMENT_PREFERENCE.INTEREST_ONLY_REPAYMENTS]:
        QUESTION_LIST.INTEREST_ONLY_REPAYMENTS,
      [REPAYMENT_PREFERENCE.INTEREST_IN_ADVANCE]:
        QUESTION_LIST.INTEREST_IN_ADVANCE,
      [REPAYMENT_PREFERENCE.LINE_OF_CREDIT]: QUESTION_LIST.LINE_OF_CREDIT,
    },
  };
};

export const getOptionQuestionId = (OPTION_QUESTION) => (key, questionKey) => {
  return (
    (OPTION_QUESTION[questionKey] && OPTION_QUESTION[questionKey][key]) || null
  );
};

export function getQuestion(questions, questionId) {
  return questions.find((item) => item.QuestionId === questionId) || null;
}

export function isFilledQuestion(item) {
  return item.QuestionDisplayTypeId === DISPLAY_OPTIONS.MULTI_DROPDOWN
    ? !!item.answerList.length
    : !!item.answerValue;
}

export function isAnsweredAllNested(questions) {
  if (!questions || !questions.length) {
    return false;
  }
  const findUnansweredValue = questions
    .map(
      (question) =>
        question.subQuestion &&
        question.subQuestion.some((item) => !isFilledQuestion(item)),
    )
    .some((nestedItem) => nestedItem);
  return !findUnansweredValue;
}

export function hasOtherQuestion(question) {
  return (
    question.answerList &&
    question.answerList.some((item) => item.name === 'Other')
  );
}

export const appendQuestionData = (currentUserService) => ({
  question,
  questionList,
  answerList,
}) => {
  const QUESTION_LIST = getCustomerObjQuestions(currentUserService);
  const OPTION_QUESTION = getOptionQuestions(QUESTION_LIST);
  return answerList.map((item) => ({
    ...item,
    ...getQuestion(
      questionList,
      getOptionQuestionId(OPTION_QUESTION)(item.value, question.QuestionId),
    ),
  }));
};

export const getConfirmationLabel = (QUESTION_LIST) => () => {
  return {
    [QUESTION_LIST.RATE_RISKS]: 'rate preference',
    [QUESTION_LIST.REASON_FOR_REFINANCE_DETAILS]: 'repayment preference',
    [QUESTION_LIST.SPECIAL_FEATURE_RISKS]: 'special feature preference',
  };
};

export const getRiskChecks = (QUESTION_LIST) => () => {
  return [
    QUESTION_LIST.RATE_RISKS,
    QUESTION_LIST.REASON_FOR_REFINANCE_DETAILS,
    QUESTION_LIST.SPECIAL_FEATURE_RISKS,
  ];
};

export const dynamicQuestionHelper = (currentUserService) => {
  const QUESTION_LIST = getCustomerObjQuestions(currentUserService);
  const OPTION_QUESTION = getOptionQuestions(QUESTION_LIST);
  return {
    isQuestionAssociatedRisks: isQuestionAssociatedRisks(currentUserService),
    isQuestionTextbox: isQuestionTextbox(currentUserService),
    getOptionQuestionId: getOptionQuestionId(OPTION_QUESTION),
    isAssociatedQuestion: isAssociatedQuestion(currentUserService),
    isOptionalQuestion: isOptionalQuestion(currentUserService),
    getConfirmationLabel: getConfirmationLabel(QUESTION_LIST),
  };
};
