import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('RequiredDocumentsCtrl', function RequiredDocumentsCtrl(
      $scope,
      $stateParams,
    ) {
      const familyId = $stateParams.familyId;
      $scope.familyId = familyId;
    });
