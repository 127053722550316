import template from './tradePersonalReferencesSection.html';
import controller from './tradePersonalReferencesSectionCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    notesLabel: '<',
    brokerNotes: '<',
    brokerNotesId: '<',
    loanAppId: '<',
    onSaveNote: '&',
  },
};
