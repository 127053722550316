import { handoverPackDisableMessage } from 'Common/constants/handoverPack';

class HandoverPackSidebar {
  constructor(loanAppSharedData, handoverPackService) {
    'ngInject';

    this.loanAppSharedData = loanAppSharedData;
    this.handoverPackService = handoverPackService;
  }

  $onInit() {
    this.handoverPackDisableMessage = handoverPackDisableMessage;
    this.disableGenerate = true;
    this.isGenerating = false;
  }

  generateHandoverPack() {
    if (!this.familyList || !this.familyList.length) {
      return;
    }
    this.isGenerating = true;
    const familyNames = this.familyList.reduce((accum, family) => {
      return `${accum}${family.FamilyFullName}`;
    }, '');
    this.handoverPackService.download(this.loanAppId, familyNames).then(() => {
      this.isGenerating = false;
    });
  }
}

export default HandoverPackSidebar;
