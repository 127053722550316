import { gmailSyncSwal } from 'Common/utilities/gmailSyncSwal';
import { alertSucess, alertError } from 'Common/utilities/alert';
import { COLOR } from 'Common/constants/colors';

class linkGmailSyncModal {
  constructor($window, contactModelService, crmConfirmation) {
    'ngInject';

    this.windowFunction = $window;
    this.contactModelService = contactModelService;
    this.gmailSyncSwal = gmailSyncSwal;
    this.alert = {
      alertSuccess: alertSucess,
      alertError,
    };
    this.crmConfirmation = crmConfirmation;
  }

  $onInit() {
    this.modalHeader = {
      link: 'Link Gmail & MyCRM',
      unlink: 'Gmail Settings',
    };
    this.cancelDisplay = {
      synced: 'Cancel',
      unsynced: 'Unlink Gmail & MyCRM',
    };
    this.confirmDisplay = {
      synced: 'Sounds good!',
      unsynced: 'Save and Close',
    };
    this.gmailLinkDescriptions = [
      {
        paragraph: `<p>Emails <b>to</b> either the client's primary, secondary or work email address</p>`,
        // eslint-disable-next-line sonarjs/no-duplicate-string
        icon: '<quick-find-check-icon></quick-find-check-icon>',
      },
      {
        paragraph: `<p>Emails <b>from</b> either the client's primary, secondary or work email address</p>`,
        icon: '<quick-find-check-icon></quick-find-check-icon>',
      },
      {
        paragraph: `<p>Emails sent or received with a <b>Loan Reference</b> for one of your clients.</p>`,
        icon: '<quick-find-check-icon></quick-find-check-icon>',
      },
    ];
    this.modalFooterDescription = {
      link: {
        paragraph: `<p><b>Who can read linked emails</b><br />Emails can be read by <b>your team</b> and <b>Loan Market support</b>.</p>`,
        icon: '<icon-light-bulb></icon-light-bulb>',
      },
      unlink: {
        paragraph: `<p><b>Want to remove an email?</b><br /> If <b>you</b> sent or receive it, you can remove it! If a <b>colleague</b> sent or received it, they'll need to remove it.</p>`,
        icon: '<icon-light-bulb></icon-light-bulb>',
      },
    };
    this.contentHeader = {
      link: `Here's what we'll link:`,
      unlink: `Here's what's linked:`,
    };
  }

  setModalTitle(flag) {
    return flag ? this.modalHeader.link : this.modalHeader.unlink;
  }

  setContentHeader(flag) {
    return flag ? this.contentHeader.link : this.contentHeader.unlink;
  }

  setModalFooter(flag) {
    return flag
      ? this.modalFooterDescription.link
      : this.modalFooterDescription.unlink;
  }

  modalCloseIconMethod() {
    this.modalInstance.dismiss();
  }

  confirm(gmailSyncFlag) {
    if (gmailSyncFlag) {
      this.windowFunction.open(this.userAuthUrl);
    }
    this.modalCloseIconMethod();
  }

  confirmButtonDisplay(gmailSyncFlag) {
    return gmailSyncFlag
      ? this.confirmDisplay.synced
      : this.confirmDisplay.unsynced;
  }

  confirmUnlink(isConfirm) {
    if (!isConfirm) {
      return;
    }
    this.contactModelService.gmailSyncUnlink().then((data) => {
      if (data) {
        this.crmConfirmation.open({
          type: 'success',
          title: 'Unlinking Successful!',
          showCancelButton: false,
          buttonClass: 'confirm-gmail-unlink-button-modal',
          showCloseButton: false,
          showConfirmButton: true,
          buttonText: 'Ok, will do!',
          renderAsComponent: true,
          description: `<confirm-unlink-gmail-sync></confirm-unlink-gmail-sync>`,
        });
        this.onUnlinkSuccess && this.onUnlinkSuccess();
        this.modalInstance.dismiss();
        return;
      }
      this.alert.alertError('Somethings gone wrong!', false, 'Error');
    });
  }

  unlinkConfirmSwal() {
    this.gmailSyncSwal(COLOR.SWEET_PINK, (isConfirm) =>
      this.confirmUnlink(isConfirm),
    );
  }

  cancel(gmailSyncFlag) {
    if (!gmailSyncFlag) {
      this.unlinkConfirmSwal();
    } else {
      this.modalInstance.dismiss();
    }
  }

  cancelButtonDisplay(gmailSyncFlag) {
    return gmailSyncFlag
      ? this.cancelDisplay.synced
      : this.cancelDisplay.unsynced;
  }
}

export default linkGmailSyncModal;
