import {
  parentBuilderForAPI,
  toggleBuilderForAPI,
  dependantBuilderForUI,
} from 'Common/mappers/dependant';
class DependantModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'dependant';
  }

  setToIncludeDependant(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/loan-scenario/include`,
      toggleBuilderForAPI(data),
    );
  }

  setToExcludeDependant(data) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/loan-scenario/exclude`,
      toggleBuilderForAPI(data),
    );
  }

  setDependantParent(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/parent-guardian`,
      parentBuilderForAPI(data),
    );
  }

  deleteDependant(loanScenarioID, clientID) {
    return this.httpClient.delete(`${this.apiBaseUrl}`, {
      loanScenarioID,
      clientID,
    });
  }

  getDependants(loanScenarioID) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${loanScenarioID}/loan-scenario`)
      .then(({ data }) => (data && data.map(dependantBuilderForUI)) || []);
  }
}

export default DependantModelService;
