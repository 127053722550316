import { viewOfAFileTheme } from 'Common/default/modal';
import {
  PERSON_PARTY_TYPES,
  ENTITY_PARTY_TYPES,
  BORROWER_PARTY_TYPES,
  INVOLVED_PARTIES_TYPE,
  COMPANY_TYPES,
  CONTACT_CLIENT_TYPE_ID,
} from 'Common/constants/partyTypes';
import { CLIENT_ROLE } from 'Common/constants/contactType';
import { REQUESTER } from 'Common/constants/addNewContactRequesters';
import { getContactFullname } from 'Common/utilities/contact';
import { updateClientDescription } from 'Assets/js/controllers/loanApp/util/application.js';

class FileAddPartiesModalCtrl {
  constructor(
    $uibModal,
    loanScenarioService,
    opportunityLoanAppService,
    contactService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.loanScenarioService = loanScenarioService;
    this.opportunityLoanAppService = opportunityLoanAppService;
    this.contactService = contactService;
  }

  $onInit() {
    this.defaultTheme = viewOfAFileTheme;
    this.entityType = ENTITY_PARTY_TYPES.COMPANY;

    this.partyTypeList = [
      { name: 'Applicant (person)', value: PERSON_PARTY_TYPES.APPLICANT },
      { name: 'Applicant (company)', value: ENTITY_PARTY_TYPES.COMPANY },
      { name: 'Guarantor', value: PERSON_PARTY_TYPES.GUARANTOR },
      { name: 'Solicitor', value: PERSON_PARTY_TYPES.SOLICITOR },
      { name: 'Accountant', value: PERSON_PARTY_TYPES.ACCOUNTANT },
    ];
    this.partyType = this.partyTypeList[0].value;
  }

  getPartyTypeSearch() {
    switch (this.partyType) {
      case PERSON_PARTY_TYPES.APPLICANT:
      case PERSON_PARTY_TYPES.SOLICITOR:
      case PERSON_PARTY_TYPES.ACCOUNTANT:
        return BORROWER_PARTY_TYPES.PERSON;
      case PERSON_PARTY_TYPES.GUARANTOR:
        return INVOLVED_PARTIES_TYPE.CLIENT_ENTITY;
      default:
        return null;
    }
  }

  searchClient(searchString) {
    this.selectedClient = null;
    if (!searchString) {
      return;
    }
    this.displaySearchList = true;

    this.loanScenarioService
      .scenarioSearchClientGet(
        searchString,
        this.getPartyTypeSearch(),
        this.opportunityId,
      )
      .then(({ data }) => {
        this.clientList = data.map((client) => ({
          ...client,
          fullName: getContactFullname(client),
        }));
      });
  }

  selectClient(client) {
    this.selectedClient = client;
    this.searchString = client.fullName;
    this.displaySearchList = false;
  }

  onTypeChange() {
    this.searchString = '';
    this.selectedClient = null;
    this.displaySearchList = false;
  }

  addSelectedClientToDeal(modalPartyType, closeModal = true) {
    this.isSaving = true;
    const partyType = modalPartyType || this.partyType;
    const selectedClient = {
      AssignedLoanScenarioId: this.opportunityId,
      ...updateClientDescription(this.selectedClient, partyType),
    };

    this.loanScenarioService.scenarioApplicantSet(selectedClient).then(() => {
      closeModal && this.modalInstance.close();
    });
  }

  createNewContact() {
    switch (this.partyType) {
      case PERSON_PARTY_TYPES.APPLICANT:
      case PERSON_PARTY_TYPES.GUARANTOR:
        this.openApplicantModal(this.partyType);
        break;
      case ENTITY_PARTY_TYPES.COMPANY:
        this.openCompanyModal();
        break;
      case PERSON_PARTY_TYPES.SOLICITOR:
      case PERSON_PARTY_TYPES.ACCOUNTANT:
        this.openRelatedPartyModal(this.partyType);
        break;
      default:
        break;
    }
  }

  setContacts(modalResponse) {
    const familyId = modalResponse.familyId || modalResponse.FamilyId;
    this.contactService.clientInformGet(familyId).then(({ data }) => {
      const clientsToAdd = data.filter(
        (client) =>
          client.Role !== CLIENT_ROLE.CHILD ||
          !modalResponse.excludedIds.includes(client.PersonId),
      );

      clientsToAdd.forEach((client, idx) => {
        this.selectedClient = {
          ClientEntityId: client.PersonId,
          ClientTypeDesc: '',
          ClientTypeId: String(CONTACT_CLIENT_TYPE_ID.PERSON),
          FirstName: client.FirstName,
          LastName: client.LastName,
          PreferedName: client.PreferredName,
          IsClient: true,
          ClientID: 0,
        };

        this.addSelectedClientToDeal(
          modalResponse.PartyType,
          idx + 1 === clientsToAdd.length,
        );
      });
    });
  }

  openApplicantModal(partyType) {
    this.$uibModal
      .open({
        templateUrl:
          '/assets/views/loanApplication/clientAssess/modal/addApplicants_v2.html',
        controller: 'ApplicantModalCtrl',
        size: 'lg',
        resolve: {
          selectedPartyType: () => String(partyType),
          person: () => null,
          type: () => partyType,
          requester: () => null,
          listApplicants: () => null,
          listSoleTraders: () => [],
          assignedAdviserIdFromRequester: () => this.adviserId,
        },
        backdrop: 'static',
        keyboard: false,
      })
      .result.then((res) => {
        this.setContacts(res);
      });
  }

  openCompanyModal() {
    this.$uibModal
      .open({
        templateUrl:
          '/assets/views/loanApplication/clientAssess/modal/addTrust.html',
        controller: 'AddTrustCtrl',
        controllerAs: 'vm',
        size: 'md',
        resolve: {
          selectedPartyType: () => COMPANY_TYPES.COMPANY,
          entity: () => null,
        },
        backdrop: 'static',
        keyboard: false,
      })
      .result.then((res) => {
        this.contactService
          .organisationInfoGet(res.FamilyId)
          .then(({ data }) => {
            this.selectedClient = {
              ClientEntityId: data.OrganisationId,
              ClientTypeDesc: data.OrganisationType,
              ClientTypeId: String(CONTACT_CLIENT_TYPE_ID.ENTITY),
              PreferedName: data.Name,
              IsClient: false,
              ClientID: 0,
            };
            this.addSelectedClientToDeal();
          });
      });
  }

  openRelatedPartyModal(partyType) {
    this.$uibModal
      .open({
        templateUrl:
          '/assets/views/loanApplication/clientAssess/modal/personCompany.add.html',
        controller: 'AddCompanyRelatedModalCtrl',
        size: 'md',
        resolve: {
          selectedPartyType: () => String(partyType),
          person: () => null,
          requester: () => REQUESTER.VIEW_OF_FILE,
          guardianList: () => [],
        },
        backdrop: 'static',
        keyboard: false,
      })
      .result.then((res) => {
        this.setContacts(res);
      });
  }

  onCancel() {
    this.modalInstance.dismiss();
  }
}

export default FileAddPartiesModalCtrl;
