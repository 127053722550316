import { SUBSCRIPTION_TYPE_INFO } from 'Common/constants/subscriptionType';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import {
  isCancelled,
  getModalName,
  videoNotFoundAlert,
} from 'Common/utilities/subscription';
import { objectLength } from 'Common/utilities/objectValidation';
import { E_SIGN_ACOUNT_TYPE } from 'Common/constants/eSign';
import {
  showSubscriptionBillingEntity,
  showPriceFinderSubscription,
} from 'Common/utilities/showFeature';
import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboarding';

class UsersSubscriptionComponentCtrl {
  constructor(
    $state,
    $window,
    contactService,
    $uibModal,
    configService,
    usersSubscriptionService,
    eSignService,
    crmConfirmation,
    accountService,
    currentUserService,
    contactModelService,
    optionsService,
    corporateService,
    uiService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$window = $window;
    this.contactService = contactService;
    this.$uibModal = $uibModal;
    this.subscriptionType = SUBSCRIPTIONTYPE;
    this.configService = configService;
    this.usersSubscriptionService = usersSubscriptionService;
    this.isCancelledWithActiveDate = isCancelled;
    this.videoNotFoundAlert = videoNotFoundAlert;
    this.eSignService = eSignService;
    this.crmConfirmation = crmConfirmation;
    this.accountService = accountService;
    this.currentUserService = currentUserService;
    this.contactModelService = contactModelService;
    this.optionsService = optionsService;
    this.corporateService = corporateService;
    this.uiService = uiService;

    this.showPriceFinderSubscription = showPriceFinderSubscription({
      configService,
      currentUserService,
    });
  }

  $onInit() {
    this.checkUserSubsLength = 0;
    this.userSubscriptions = [];
    this.accountService.refreshConfigFeature();
    const {
      creditChecks: featureCreditChecks,
      marketingAutomation: featureMarketingAutomation,
      nZSubscriptions,
      nZNurture,
      nZStayInTouch,
      esign: featureEsign,
      bankConnectExpensesAnalysis: featureBankConnect,
      nurture: featureNurture,
      esignSignature,
      newSubscriptionModal,
      'subscriptions.activePipe': subscriptionsActivePipe,
    } = this.configService.feature;

    this.showEsignSignature = esignSignature;
    this.showUpdatedSubscriptions = featureMarketingAutomation || false;
    this.showNewSubscriptionModal = newSubscriptionModal || false;
    const {
      BROKERPEDIA,
      CREDIT_CHECK,
      PRICE_FINDER,
      MARKETING_AUTOMATION,
      MARKETING_AUTOMATION_NZ,
      NURTURE_NZ,
      E_SIGN,
      BANK_CONNECT_EXPENSES_ANALYSIS,
      BANK_CONNECT,
      NURTURE,
      ACTIVE_PIPE,
    } = this.subscriptionType;
    const {
      isAU,
      corporateTeamId,
      isCorporate,
      accessType,
    } = this.currentUserService;
    this.isAssistant = [
      ACCESS_TYPE.ADMIN_ASSISTANT,
      ACCESS_TYPE.ASSISTANT,
    ].includes(accessType);
    this.canEditSubscriptionBilling =
      isCorporate &&
      [
        CORPORATE_TEAM.FINANCE,
        CORPORATE_TEAM.AGREEMENT_AND_ONBOARDING,
        CORPORATE_TEAM.IT_SUPPORT,
        CORPORATE_TEAM.SUPER_ADMIN,
      ].includes(corporateTeamId);
    const brokerPedia = isAU && BROKERPEDIA;
    const creditCheck = featureCreditChecks && CREDIT_CHECK;
    const eSign = featureEsign && E_SIGN;
    const priceFinder =
      this.showPriceFinderSubscription(this.adviserInfo) && PRICE_FINDER;
    const marketingAutomation =
      featureMarketingAutomation && MARKETING_AUTOMATION;
    const marketingAutomationNZ =
      nZSubscriptions && nZStayInTouch && MARKETING_AUTOMATION_NZ;
    const nurtureNZ = nZSubscriptions && nZNurture && NURTURE_NZ;
    const bankConnect = isAU && featureBankConnect && BANK_CONNECT;
    const bankConnectExpensesAnalysis =
      isAU && featureBankConnect && BANK_CONNECT_EXPENSES_ANALYSIS;
    const nurture = isAU && featureNurture && NURTURE;
    const activePipe = isAU && subscriptionsActivePipe && ACTIVE_PIPE;
    this.subscriptionItems = [
      brokerPedia,
      creditCheck,
      priceFinder,
      marketingAutomation,
      marketingAutomationNZ,
      nurtureNZ,
      eSign,
      bankConnectExpensesAnalysis,
      bankConnect,
      nurture,
      activePipe,
    ];
    this.interceptModalItems = [bankConnectExpensesAnalysis, bankConnect];
  }

  $onChanges(changes) {
    const { adviserInfo, orderedById } = changes;
    this.adviserInfo = adviserInfo && adviserInfo.currentValue;
    const updatePriceFinderSubscriptionValue =
      this.adviserInfo &&
      this.showPriceFinderSubscription &&
      this.subscriptionItems;
    if (updatePriceFinderSubscriptionValue) {
      const priceFinderSubscriptionIdx = 2;
      this.subscriptionItems[priceFinderSubscriptionIdx] =
        this.showPriceFinderSubscription(this.adviserInfo) &&
        this.subscriptionType.PRICE_FINDER;
    }
    this.orderedById =
      (orderedById && orderedById.currentValue) || this.orderedById;
    if (
      !this.adviserInfo ||
      !this.adviserInfo.FamilyId ||
      this.familyId === this.adviserInfo.FamilyId
    ) {
      return;
    }
    this.showSubscriptionBillingEntity = showSubscriptionBillingEntity(
      this.configService.feature.subscriptionBillingEntity,
      this.currentUserService,
    );
    this.familyId = this.adviserInfo.FamilyId;
    this.getSubscriptionBillingEntity();
    this.corporateContactGet();
    this.getSubscriptionBillingEntityOption();
  }

  checkTypeValid(subscriptionProductId) {
    let subscriptionTypeValid = null;
    if (!this.interceptModal) {
      subscriptionTypeValid = this.subscriptionItems.includes(
        subscriptionProductId,
      );
    } else {
      subscriptionTypeValid = this.interceptModalItems.includes(
        subscriptionProductId,
      );
    }
    return subscriptionTypeValid;
  }

  corporateContactGet() {
    if (!this.familyId || !this.showSubscriptionBillingEntity) {
      return;
    }
    const { clientId: stateClient } = this.$state.params;
    const clientId = this.currentUserService.isCorporate
      ? stateClient
      : this.currentUserService.clientId;
    this.corporateService
      .corporateContactGet(clientId, this.familyId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        const roles = data.RoleId.split(',').map((item) => parseInt(item, 10));
        const adviserRoles = roles && !roles[0] ? [] : roles;
        this.validRoleToShowBilling = adviserRoles.some((role) =>
          [
            ONBOARDING_ROLE_ID.LOAN_WRITER,
            ONBOARDING_ROLE_ID.CONTRACTOR,
          ].includes(role),
        );
        this.getSubscriptionBillingEntity();
      });
  }

  getSubscriptionBanner(currentObj) {
    const newBannerSubscription =
      SUBSCRIPTION_TYPE_INFO[currentObj.subscriptionProductId] || {};

    if (currentObj.subscriptionProductId === SUBSCRIPTIONTYPE.BROKERPEDIA) {
      newBannerSubscription.listIcon = `<broker-pedia-icon banner-text="${currentObj.additionalCostDescription}"></broker-pedia-icon>`;
    }

    const displayNewSubscriptionBanner =
      this.uiService.displayNewSubscriptionBanner &&
      newBannerSubscription.listIcon;

    return {
      ...newBannerSubscription,
      displayNewSubscriptionBanner,
    };
  }

  getSubscription() {
    if (!this.familyId) {
      return;
    }
    this.contactService.subscriptionInfo(this.familyId).then((response) => {
      if (!response || !response.length) {
        return;
      }
      this.userSubscriptions = response.reduce((initial, currentObj) => {
        const { subscriptionProductId } = currentObj;
        if (this.checkTypeValid(subscriptionProductId)) {
          const subscription = this.showSubscriptionBillingEntity
            ? this.setBillingInfo(currentObj)
            : currentObj;

          initial.push({
            ...subscription,
            ...this.getSubscriptionBanner(currentObj),
          });
        }
        return initial;
      }, []);
      const brokerPedia = this.userSubscriptions.find(
        (obj) => obj.subscriptionProductId === SUBSCRIPTIONTYPE.BROKERPEDIA,
      );
      const priceFinder = this.userSubscriptions.find(
        (obj) => obj.subscriptionProductId === SUBSCRIPTIONTYPE.PRICE_FINDER,
      );
      this.usersSubscriptionService.setBrokerPediaData(brokerPedia);
      this.usersSubscriptionService.setPriceFinderData(priceFinder);
    });
  }

  setBillingInfo(subscription) {
    const {
      previousSubscriptionId,
      isTrial,
      isActive,
      billingEntityId,
    } = subscription;
    const activeSubscription = isTrial || isActive;
    subscription.showBillingEntity = !!(
      this.validRoleToShowBilling &&
      this.showSubscriptionBillingEntity &&
      !this.isAssistant &&
      (activeSubscription || (!activeSubscription && previousSubscriptionId))
    );
    if (previousSubscriptionId) {
      subscription.billingDetails = this.subscriptionBillingEntities.find(
        (billing) => billing.subscriptionId === previousSubscriptionId,
      );
    }
    if (billingEntityId) {
      subscription.billingDetails = this.subscriptionBillingEntities.find(
        (billing) => billing.billingEntityId === billingEntityId,
      );
    }
    return subscription;
  }

  getSubscriptionBillingEntity() {
    this.userSubscriptions = [];
    if (!this.familyId || !this.showSubscriptionBillingEntity) {
      this.getSubscription();
      return;
    }
    this.subscriptionBillingEntities = [];
    this.contactModelService
      .getSubscriptionBillingEntity({ adviserId: this.familyId })
      .then((data) => {
        this.subscriptionBillingEntities = data || [];
        this.getSubscription();
      });
  }

  getSubscriptionBillingEntityOption() {
    if (
      !this.familyId ||
      !this.showSubscriptionBillingEntity ||
      this.billingOptions
    ) {
      return;
    }
    this.optionsService
      .getSubscriptionBillingEntityOption(this.familyId)
      .then((data) => {
        this.billingOptions = data;
      });
  }

  updateBillingEntity(subs) {
    if (!this.showSubscriptionBillingEntity) {
      return;
    }
    const {
      billingEntityType,
      subscriptionId,
      billingEntityId,
      previousSubscriptionId,
    } = subs;
    const billingDetails = this.billingOptions.find(
      (billing) => billing.billingEntityType === billingEntityType,
    );
    const previousBillingDetails = this.subscriptionBillingEntities.find(
      (billing) => billing.subscriptionId === previousSubscriptionId,
    );
    const { billingEntityName } = billingDetails;
    subs.disabled = true;
    const params = {
      subscriptionId: subscriptionId || previousSubscriptionId,
      adviserId: this.familyId,
      billingEntityType,
      billingEntityName,
      billingEntityId:
        billingEntityId || previousBillingDetails.billingEntityId,
    };
    this.contactModelService
      .putSubscriptionBillingEntity(params)
      .then((response) => {
        if (!response) {
          return;
        }
        subs.disabled = false;
      });
  }

  openESignModal() {
    const props = {
      familyId: this.familyId,
    };
    this.$uibModal.open({
      template: `<e-sign-upload-signature-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId">
                </e-sign-upload-signature-modal>`,
      size: 'md',
      backdrop: 'static',
      windowClass: 'mc-e-sign-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
  }

  openAutoMarketingModal(props) {
    const modalInstance = this.$uibModal.open({
      template: `<subscription-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  subscription-data="vm.props.subscriptionData"
                  ordered-by-id="vm.props.orderedById">
                </subscription-modal>`,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'mc-subscription-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then((response) => {
      if (response && response.getAllSubscribe) {
        !this.interceptModal && this.getSubscriptionBillingEntity();
        this.interceptModal && this.openFactFindModal();
        this.validToCheckSignature(
          props.subscriptionData.subscriptionProductId,
        );
      }
    });
  }

  isValidForNewModal(subscriptionProductId) {
    if (this.showNewSubscriptionModal) {
      return this.subscriptionItems.includes(subscriptionProductId);
    }
    return !!(
      subscriptionProductId === this.subscriptionType.MARKETING_AUTOMATION ||
      subscriptionProductId ===
        this.subscriptionType.BANK_CONNECT_EXPENSES_ANALYSIS ||
      subscriptionProductId === this.subscriptionType.BANK_CONNECT ||
      subscriptionProductId === this.subscriptionType.NURTURE
    );
  }

  openSubscribeModal(subs) {
    const { subscriptionProductId } = subs;
    if (!subscriptionProductId) {
      return;
    }
    const isTypeValid = this.checkTypeValid(subscriptionProductId);
    if (!isTypeValid) {
      return;
    }
    const isNewSubscriptionModal = this.isValidForNewModal(
      subscriptionProductId,
    );
    if (isNewSubscriptionModal) {
      const props = {
        familyId: this.familyId,
        subscriptionData: subs,
        orderedById: this.orderedById,
      };
      this.openAutoMarketingModal(props);
    } else {
      const props = {
        familyId: this.familyId,
        subscriptionData: subs,
        orderedById: this.orderedById,
      };
      const componentName = getModalName(subscriptionProductId);
      if (!componentName) {
        return;
      }
      const modalInstance = this.$uibModal.open({
        template: `<${componentName}
                    modal-instance="vm.modalInstance"
                    family-id="vm.props.familyId"
                    subscription-data="vm.props.subscriptionData"
                    ordered-by-id="vm.props.orderedById">
                  </${componentName}>`,
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        windowClass: 'mc-subscription-modal',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props,
        },
      });
      modalInstance.result.then((response) => {
        if (!response) {
          return;
        }
        this.getSubscriptionBillingEntity();
        this.validToCheckSignature(subscriptionProductId);
      });
    }
  }

  validToCheckSignature(subscriptionProductId) {
    if (subscriptionProductId !== SUBSCRIPTIONTYPE.E_SIGN) {
      return;
    }
    if (this.showEsignSignature) {
      this.openESignModal();
      return;
    }
    this.esignAccountCheck(subscriptionProductId);
  }

  esignAccountCheck(subscriptionProductId) {
    if (subscriptionProductId !== SUBSCRIPTIONTYPE.E_SIGN || !this.familyId) {
      return;
    }
    this.eSignService.getAccountCheck(this.familyId).then((response) => {
      if (response && response.status === E_SIGN_ACOUNT_TYPE.INVITED) {
        this.crmConfirmation.open({
          type: 'success',
          title: 'Subscription Successful',
          description: `Your eSign account has now been created, please confirm your email address`,
          buttonText: 'Okay, got it!',
          modalSize: 'md',
        });
      }
    });
  }

  cancelSubscription(subs) {
    const { subscriptionProductId, isTrial } = subs;
    const isTypeValid = this.checkTypeValid(subscriptionProductId);
    if (!isTypeValid) {
      return;
    }
    const isCancelOptionsModal = this.isValidForNewModal(subscriptionProductId);
    if (isCancelOptionsModal && !isTrial) {
      this.cancelCampaignSubscription(subs);
      return;
    }
    subs.isImmediately = isTrial;
    const props = {
      familyId: this.familyId,
      subscriptionData: subs,
    };
    const modalInstance = this.$uibModal.open({
      template: `<subscription-cancel-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  subscription-data="vm.props.subscriptionData">
                </subscription-cancel-modal>`,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'cancel-subscription',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then((response) => {
      if (!response || !response.getSubscription) {
        return;
      }
      this.getSubscriptionBillingEntity();
    });
  }

  cancelCampaignSubscription(subs) {
    const props = {
      familyId: this.familyId,
      subscriptionData: subs,
    };
    const modalInstance = this.$uibModal.open({
      template: `<subscription-cancel-now-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  subscription-data="vm.props.subscriptionData">
                </subscription-cancel-now-modal>`,
      backdrop: 'static',
      keyboard: false,
      windowClass: 'cancel-subscription-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then(() => {
      this.getSubscriptionBillingEntity();
    });
  }

  isInActiveSubscription(subscripton) {
    return (
      this.subscriptionEnabled &&
      !subscripton.isActive &&
      !this.isCancelledWithActiveDate(subscripton)
    );
  }

  isCancelledSubscription(subscripton) {
    return (
      this.subscriptionEnabled && this.isCancelledWithActiveDate(subscripton)
    );
  }

  isActiveProduct(subscription, product) {
    return (
      this.subscriptionEnabled &&
      subscription.isActive &&
      subscription.subscriptionProductId === product
    );
  }

  isMarketingAutomation(subscription) {
    return (
      this.isActiveProduct(
        subscription,
        this.subscriptionType.MARKETING_AUTOMATION,
      ) ||
      this.isActiveProduct(
        subscription,
        this.subscriptionType.MARKETING_AUTOMATION_NZ,
      )
    );
  }

  updateSubscription(subscription) {
    const marketingAutomation = this.isMarketingAutomation(subscription);

    if (this.showModifyEsignSubscription(subscription)) {
      this.openESignModal();
    } else if (marketingAutomation) {
      this.openSubscribeModal(subscription);
    }
  }

  isShowUpdateSubscription(subscription) {
    const marketingAutomation = this.isMarketingAutomation(subscription);

    return (
      this.showUpdatedSubscriptions &&
      (this.showModifyEsignSubscription(subscription) || marketingAutomation)
    );
  }

  isShowTrialBanner(subscripton) {
    return (
      this.showUpdatedSubscriptions &&
      !subscripton.isTrial &&
      !subscripton.isActive &&
      subscripton.hasTrial &&
      !this.isCancelledSubscription(subscripton)
    );
  }

  subscriptionLearnMore(subscription) {
    if (this.showUpdatedSubscriptions) {
      this.openSubscribeModal(subscription);
    } else {
      this.videoNotFoundAlert();
    }
  }

  showModifyEsignSubscription(subscripton) {
    if (!subscripton || !objectLength(subscripton)) {
      return false;
    }
    return (
      this.showEsignSignature &&
      (this.isActiveSubscription(subscripton) ||
        this.isCancelledSubscription(subscripton)) &&
      subscripton.subscriptionProductId === this.subscriptionType.E_SIGN
    );
  }

  isActiveSubscription(subscription) {
    return (
      this.subscriptionEnabled && subscription.isActive && !subscription.isTrial
    );
  }

  isTrialSubscription(subscription) {
    return (
      this.subscriptionEnabled && subscription.isTrial && subscription.isActive
    );
  }
}

export default UsersSubscriptionComponentCtrl;
