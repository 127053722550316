import template from './loanInformationImportantDates.html';
import LoanInformationImportantDatesCtrl from './loanInformationImportantDatesCtrl';

export default {
  template,
  bindings: {
    loanDetails: '<',
    onDateChange: '&',
    isSmallModal: '<',
    overrideRequiredDates: '<',
    validateOnSubmit: '<',
    isIncludeVaried: '<',
  },
  controller: LoanInformationImportantDatesCtrl,
  controllerAs: 'vm',
};
