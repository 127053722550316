import get from 'lodash/get';

export function showFactFindLendingBrandWarning(
  scope,
  loanScenarioId,
  featureFlagVariable,
) {
  return (
    scope &&
    scope[featureFlagVariable] &&
    !scope.isBrandingReady &&
    !scope.isLoadingAdviserTheme &&
    loanScenarioId
  );
}

export function showFactFindLendingSection(
  scope,
  loanScenarioId,
  featureFlagVariable,
) {
  return (
    scope &&
    scope[featureFlagVariable] &&
    scope.isBrandingReady &&
    !scope.isLoadingAdviserTheme &&
    loanScenarioId
  );
}

export function searchSameLoanFromList(loanItem, loanList) {
  if (!loanItem || !loanList) {
    return;
  }
  return loanList.find(
    (inprogLoanObj) => loanItem.LoanId === inprogLoanObj.LoanID,
  );
}

export const factFindSectionItemHtml = (group) => {
  return `<div class="group group-${group}"><div
class="request-item checkbox clip-check check-info"
ng-repeat="section in vm.requestSections | filter: {group: ${group}} track by $index "
>
<input
  type="checkbox"
  name="section-${group}-{{$index}}"
  ng-attr-id="section-${group}-{{$index}}"
  ng-model="section.selected"
  ng-change="vm.updateRequestSections(section.id, section.selected)"
/>
<label ng-attr-for="section-${group}-{{$index}}">
  <p class="text-default">{{section.name}}</p>
</label>
</div></div>`;
};

export const getSelectedHeadings = (headings) => {
  const selectedHeadingIds = headings.reduce((accum, section) => {
    const { selected, id } = section;
    selected && accum.push(id);
    return accum;
  }, []);
  return {
    SelectedHeadingIds: selectedHeadingIds,
    IsPartialFactFind:
      get(selectedHeadingIds, 'length') !== get(headings, 'length'),
  };
};
