import template from './brokerMessageView.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    message: '<',
    photoUrl: '<',
    isLoading: '<',
    adviserTheme: '<',
    editorOptions: '<',
    onUpdateMessage: '&',
    isSaveDefaultEdited: '<',
    saveDefaultMessage: '&',
    isSaving: '<',
    isOnSaveDefaultEdited: '<',
    isSettingsOnDefault: '<',
    resetOnDefault: '&',
    isReverting: '<',
    saveButtonText: '<',
    userTerm: '<',
    textCounter: '<',
    isTextCountMax: '<',
    messageMaxCount: '<',
  },
};
