import angular from 'angular';

angular
  .module('app')
  .directive('onElementInit', function onElementInit($parse) {
    return {
      restrict: 'A',
      scope: { method: '&onElementInit' },
      link(scope, element, attrs) {
        const listener = scope.method && scope.method();
        if (listener && typeof listener === 'function') {
          listener(element);
        } else {
          throw new Error(
            `Invalid directive value: directive on-element-init expecting a function, instead, got ${listener}.`,
          );
        }
      },
    };
  });
