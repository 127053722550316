import { SCREEN_SIZE } from 'Common/constants/screenSize';
import { ASC, DESC, PAGE_SIZE } from './constants/pageConstants';

export default class ComplaintsRegister {
  constructor(
    $state,
    $window,
    currentUserService,
    loanToolsService,
    modalRenderService,
    downloadDataService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$window = $window;
    this.currentUserService = currentUserService;
    this.loanToolsService = loanToolsService;
    this.modalRenderService = modalRenderService;
    this.downloadDataService = downloadDataService;
  }

  $onInit() {
    this.currentPage = 1;
    this.filterList = [
      { label: 'Adviser Org', key: 'adviserOrg' },
      { label: 'Complainant', key: 'complainant' },
      { label: 'Search Against', key: 'searchAgainst' },
      { label: 'Adviser', key: 'adviser' },
    ];

    this.headerList = [
      { label: 'Logged By', isSortable: true, key: 'loggedBy' },
      { label: 'Adviser Org', isSortable: true, key: 'adviserOrg' },
      { label: 'Complaint', key: 'complaint' },
      { label: 'Complainant', key: 'complainant' },
      { label: 'Complaint Against', key: 'complaintAgainst' },
      { label: 'Status', isSortable: true, key: 'status' },
      { label: 'Corp Comments', key: 'corpComments' },
    ];

    this.getComplaintsRegisterList();

    this.sortOrder = null;
    this.sortColumn = null;
  }

  getFilterAsParams() {
    return this.filterList.reduce(
      (accum, curr) => {
        if (curr.value) {
          accum[curr.key] = curr.value;
        }

        return accum;
      },
      {
        pageNumber: this.currentPage,
        pageSize: PAGE_SIZE,
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder,
      },
    );
  }

  getComplaintsRegisterList() {
    this.isLoading = true;

    const filter = this.getFilterAsParams();

    return this.loanToolsService
      .resourceCorporateComplaintGet(0, filter)
      .then(({ data, totalRecordCount }) => {
        this.rowItems = data;
        this.totalRecordCount = totalRecordCount;
        this.isLoading = false;
      });
  }

  onSort(sortColumn) {
    if (sortColumn !== this.sortColumn) {
      this.sortOrder = ASC;
    } else {
      this.sortOrder = this.sortOrder === DESC ? ASC : DESC;
    }

    this.sortColumn = sortColumn;
    this.getComplaintsRegisterList();
  }

  onFilterChange(filterList) {
    this.filterList = filterList;
    this.getComplaintsRegisterList();
  }

  openFilterComplaintModal() {
    const props = { filterList: this.filterList };

    return this.modalRenderService
      .openFilterComplaintModal(props)
      .result.then(({ filterList }) => {
        this.filterList = filterList;
        this.getComplaintsRegisterList();
      });
  }

  onRowClick(complaint) {
    if (!complaint) {
      return;
    }

    this.modalRenderService.openComplaintModal(complaint.complaintId, true);
  }

  shouldOpenCommentsModal() {
    return this.$window.innerWidth < SCREEN_SIZE.SM;
  }

  openCorpCommentsModal(complaint) {
    if (!complaint) {
      return;
    }

    const props = { corpComments: complaint.corpComments };

    return this.modalRenderService
      .openComplaintCorpCommentsModal(props)
      .result.then(({ corpComments }) => {
        complaint.corpComments = corpComments;
        this.onSaveCorpComments(complaint);
      });
  }

  onEditCommentClick($e, complaint) {
    $e && $e.stopPropagation();

    if (!complaint) {
      return;
    }

    if (this.shouldOpenCommentsModal()) {
      this.openCorpCommentsModal(complaint);
    } else {
      complaint.isInlineEdit = true;
    }
  }

  onSaveCorpComments(complaint) {
    if (!complaint) {
      return;
    }

    const { complaintId, corpComments } = complaint;

    this.isLoading = true;

    return this.loanToolsService
      .resourceCorporateComplaintSet({ complaintId, corpComments })
      .then(({ succeeded }) => {
        if (succeeded) {
          this.rowItems = this.rowItems.map((item) => {
            if (item.complaintId !== complaintId) {
              return { ...item };
            }
            return { ...complaint, isInlineEdit: false };
          });
        }
        this.isLoading = false;
      });
  }

  onExcelClick() {
    this.isDownloadingExcel = true;
    const { pageNumber, pageSize, ...filter } = this.getFilterAsParams();

    return this.loanToolsService
      .resourceCorporateComplaintExcelGet(filter)
      .then(({ data }) => {
        if (!data) {
          this.isDownloadingExcel = false;
          return;
        }

        const { ContentType, DocumentContent, Name } = data;
        this.downloadDataService.download(
          `data:${ContentType};base64,${DocumentContent}`,
          Name,
          ContentType,
        );

        this.isDownloadingExcel = false;
      });
  }
}
