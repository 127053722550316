import { parseToInt10 } from 'Common/utilities/parse';

export const validateErrorsAddressNZBN = ({
  incompleteEmployerAddress,
  guarantorEmploymentNZBN,
  borrowerEmploymentNZBN,
  employmentEmployerName,
  employmentIncomeVerification,
}) => {
  const employeerAddress =
    ((incompleteEmployerAddress || {}).attributes || {}).ids || [];

  const guarantorsNZBN =
    ((guarantorEmploymentNZBN || {}).attributes || {}).ids || [];

  const borrowerNZBN =
    ((borrowerEmploymentNZBN || {}).attributes || {}).ids || [];

  const employerName =
    ((employmentEmployerName || {}).attributes || {}).ids || [];

  const incomeVerification =
    ((employmentIncomeVerification || {}).attributes || {}).ids || [];

  return {
    hasAnErros: () => {
      return (
        !!incompleteEmployerAddress ||
        !!guarantorEmploymentNZBN ||
        !!borrowerEmploymentNZBN ||
        !!employerName ||
        !!incomeVerification
      );
    },
    employeerAddress,
    guarantorsNZBN,
    borrowerNZBN,
    employerName,
    incomeVerification,
  };
};

export const mapSomeErrorsAddressNZBN = (
  { guarantorsNZBN, borrowerNZBN },
  list,
) => {
  return (
    guarantorsNZBN.some(
      (gaurantor) => gaurantor.EmploymentID === list.EmploymentId,
    ) ||
    borrowerNZBN.some((borrower) => borrower.EmploymentID === list.EmploymentId)
  );
};

export const checkEachErrors = (errorProp, attributes, list) => {
  return attributes[errorProp].some(
    (id) => id.EmploymentID === list.EmploymentId,
  );
};

export const getAllPropErrors = (list = {}) => {
  return (
    list.hasErrorAddress ||
    list.hasErrorNZBN ||
    list.hasErrorEmpoyerName ||
    list.hasErrorIncomeVerification ||
    list.hasError
  );
};

export const employmentListConcatErrors = ({
  attributes,
  handlerApplicants,
}) => {
  const defaultProperty = {
    Employment: {
      AllList: [],
    },
  };
  const { Employment = defaultProperty } =
    handlerApplicants.loanAppSharedData || defaultProperty;
  const notEmpty =
    !!(Employment || {}).AllList &&
    !!(
      Employment || {
        AllList: [],
      }
    ).AllList.length;

  notEmpty &&
    (() => {
      Employment.AllList.forEach((employment) => {
        employment.Employment.map((list) => {
          list.hasErrorAddress = checkEachErrors(
            'employeerAddress',
            attributes,
            list,
          );
          list.hasErrorNZBN = mapSomeErrorsAddressNZBN(attributes, list);
          list.hasErrorEmpoyerName = checkEachErrors(
            'employerName',
            attributes,
            list,
          );
          list.hasErrorIncomeVerification = checkEachErrors(
            'incomeVerification',
            attributes,
            list,
          );
          list.allErrors = getAllPropErrors(list);
          return list;
        });
      });
    })();
};

export const mapdAddresses = (applicantsCtrl = {}, handlerApplicants = {}) => {
  const {
    incompleteEmployerAddress = false,
    guarantorEmploymentNZBN = false,
    borrowerEmploymentNZBN = false,
    employmentEmployerName = false,
    employmentIncomeVerification = false,
  } = applicantsCtrl.errorTypes || {};

  const attributes = validateErrorsAddressNZBN({
    incompleteEmployerAddress,
    guarantorEmploymentNZBN,
    borrowerEmploymentNZBN,
    employmentEmployerName,
    employmentIncomeVerification,
  });

  attributes.hasAnErros() &&
    employmentListConcatErrors({ attributes, handlerApplicants });
};

export const addressHistory = (applicantsCtrl = {}, handlerApplicants = {}) => {
  const { incompleteApplicantAddress } = applicantsCtrl.errorTypes || {
    incompleteApplicantAddress: false,
  };
  incompleteApplicantAddress &&
    (() => {
      const { allAddressList = [] } = handlerApplicants.loanAppSharedData;
      const addressIds =
        ((incompleteApplicantAddress || {}).attributes || {}).ids || [];

      allAddressList.forEach((address) => {
        address.Address.map((listAdrresses) => {
          listAdrresses.errorAddress = addressIds.some(
            (id) => id.AddressID === parseToInt10(listAdrresses.AddressID),
          );
          return listAdrresses;
        });
      });
    })();
};

export const mapFinancialAddress = (
  applicantsCtrl = {},
  financialsSharedService = {},
) => {
  const { incompleteAssetAddress } = applicantsCtrl.errorTypes || {
    incompleteAssetAddress: false,
  };
  incompleteAssetAddress &&
    (() => {
      const { listAssets = [] } = financialsSharedService;
      const financialIds =
        ((incompleteAssetAddress || {}).attributes || {}).ids || [];

      listAssets.forEach((address) => {
        const { FinancialInfo = [] } = address.overview || {};
        FinancialInfo.map((listAdrresses) => {
          listAdrresses.errorAddress = financialIds.some(
            (id) => id.FinancialId === listAdrresses.FinancialId,
          );
          return listAdrresses;
        });
      });
    })();
};
