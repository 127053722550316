/**
 * A directive used for "close buttons" (eg: alert box).
 * It hides its parent node that has the class with the name of its value.
 */
import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('ctDismiss', function ctDismiss() {
    return {
      restrict: 'A',
      link(scope, elem, attrs) {
        elem.on('click', (e) => {
          elem.parent(`.${attrs.ctDismiss}`).hide();
          e.preventDefault();
        });
      },
    };
  });
