import template from './noteContent.html';
import controller from './noteContentCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    notes: '<',
    notesCount: '<',
    itemPerPage: '<',
    currentPage: '<',
    tableSizes: '<',
    hideDeal: '<',
    onPageChange: '<',
    onOpenNote: '&',
    onDeleteNote: '&',
  },
};
