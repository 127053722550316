import template from './addNoteLauncher.html';
import controller from './addNoteLauncherCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    brokerBrandingId: '<',
    clientFullName: '@',
    onModalClose: '&',
  },
};
