import angular from 'angular';
import commissionStats from './commissionStats';
import commissionPie from './commissionPie';
import commissionByBroker from './commissionByBroker';
import commissionWidgetHeader from './commissionWidgetHeader';
import commissionStatsRow from './commissionStatsRow';
import commissionHistory from './commissionHistory';
import commissionCalendarModal from './commissionCalendarModal';

export default angular
  .module('commission.scenes.commissionDashboard.components', [])
  .component('commissionStats', commissionStats)
  .component('commissionPie', commissionPie)
  .component('commissionByBroker', commissionByBroker)
  .component('commissionWidgetHeader', commissionWidgetHeader)
  .component('commissionStatsRow', commissionStatsRow)
  .component('commissionHistory', commissionHistory)
  .component('commissionCalendarModal', commissionCalendarModal).name;
