import template from './insurancePipelineStatuses.html';
import controller from './insurancePipelineStatusesCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    value: '=',
    selectType: '<?',
    disabled: '<?',
    onChange: '&?',
  },
};
