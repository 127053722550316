import cloneDeep from 'lodash/cloneDeep';
import {
  TARGET_BASED_TILE,
  TARGET_BASED_PERIOD,
  TARGET_DD_MM,
  CHART_SERIES,
  DATE_INT,
} from 'Common/constants/dashboardWidget';
import { TARGET_TYPE_CHARTS, TARGET_TYPE_OVERRIDE } from 'Common/config/chart';
import {
  getCurrentCarouselData,
  getIndexPeriod,
  getTargetEndpoint,
  hideWidgetSettings,
} from 'Common/utilities/dashboardWidget';

export default class TargetBasedTileCtrl {
  constructor(
    commonWidgetService,
    currentUserService,
    overviewDashboardService,
    dashboardService,
  ) {
    'ngInject';

    this.commonWidgetService = commonWidgetService;
    this.currentUserService = currentUserService;
    this.overviewDashboardService = overviewDashboardService;
    this.dashboardService = dashboardService;
  }

  $onInit() {
    this.datasetOverride = TARGET_TYPE_OVERRIDE;
    this.chartSeries = CHART_SERIES.LOAN_AMT;
    this.chartLabels = [];
    this.chartData = [[], []];
    this.setupChart();
    this.getWidgetOptionDropdown();
  }

  $onChanges(changes) {
    this.hideWidgetSettings = hideWidgetSettings({
      currentUserService: this.currentUserService,
    });

    const { adviserFilter } = changes;
    if (!adviserFilter.currentValue) {
      return;
    }
    const adviserFilterIds = adviserFilter.currentValue.split(',');
    const isSelectedOwn =
      adviserFilterIds.length === 1 &&
      adviserFilterIds[0] === `${this.currentUserService.familyId}`;
    const overrideHideWidgetSettings =
      this.currentUserService.isPrincipalAdviser && isSelectedOwn;
    this.hideWidgetSettings = overrideHideWidgetSettings
      ? false
      : this.hideWidgetSettings;
  }

  setupChart() {
    this.chartOptions = cloneDeep(TARGET_TYPE_CHARTS);
    this.chartOptions.tooltips = {
      ...this.chartOptions.tooltips,
      callbacks: {
        label: (valuePayload, { datasets }) =>
          this.commonWidgetService.getCustomLabel(valuePayload, datasets),
      },
    };

    this.chartOptions.scales.yAxes[0].ticks = {
      ...this.chartOptions.scales.yAxes[0].ticks,
      callback: (value) => this.commonWidgetService.getShortenAmount(value),
    };
  }

  setView(isFlipped) {
    this.isFlipped = isFlipped;
  }

  onCloseSettings() {
    this.getWidgetOptionDropdown();
    this.setView(false);
  }

  getWidgetOptionDropdown() {
    this.commonWidgetService
      .getWidgetOptions(TARGET_BASED_TILE.TIME_PERIOD)
      .then((data) => {
        this.timePeriodList = data;
        this.getWidgetFilters();
      });
  }

  getWidgetFilters() {
    this.dashboardService.getRecommendedWidgetsOptions().then((data) => {
      this.timePeriodFilter = data.find(
        (item) =>
          item.widgetOptionId === TARGET_BASED_TILE.TIME_PERIOD &&
          item.recommendedWidgetId === this.widgetInfo.recommendedWidgetId,
      );
      this.memberFilter = data.find(
        (item) => item.widgetOptionId === TARGET_BASED_TILE.ADVISERS,
      );
      this.timePeriodSelected = { ...this.timePeriodFilter };
      this.memberSelected = { ...this.memberFilter };
      this.rawTargetValue = this.timePeriodSelected.targetValue || 0;
      this.getChartData();
    });
  }

  structureTargetLine() {
    const isValid = !!(this.timePeriodSelected.valueId && this.chartLabels);

    if (!isValid) {
      this.chartData = [[], []];
      return;
    }
    this.chartData[0] = [...new Array(this.chartLabels.length)];
    switch (this.timePeriodSelected.valueId) {
      case TARGET_BASED_PERIOD.THIS_WEEK:
        this.targetValue =
          (this.rawTargetValue * DATE_INT.MONTH) / DATE_INT.WEEK;
        break;
      case TARGET_BASED_PERIOD.THIS_MONTH:
        this.targetValue = this.rawTargetValue;
        break;
      case TARGET_BASED_PERIOD.THIS_FY:
      case TARGET_BASED_PERIOD.THIS_CY:
        this.targetValue = this.rawTargetValue * DATE_INT.MONTH;
        break;
      default:
    }

    this.chartData[0][0] = this.targetValue / this.chartLabels.length;
    this.chartData[0][this.chartLabels.length - 1] = this.targetValue;
    this.computeLegendInfo();
  }

  setTimePeriod(isNext, currentPeriod, list) {
    const indexPeriod = getIndexPeriod(list, currentPeriod);

    this.timePeriodSelected = getCurrentCarouselData(isNext, indexPeriod, list);
    this.setWidgetOptions(
      {
        ...this.timePeriodFilter,
        widgetOptionValueName: this.timePeriodSelected.displayName,
        recommendedWidgetId: this.widgetInfo.recommendedWidgetId,
        widgetOptionId: TARGET_BASED_TILE.TIME_PERIOD,
        widgetOptionValueId: this.timePeriodSelected.valueId,
      },
      true,
    );
  }

  setWidgetOptions(params, onlyUpdateChart) {
    const endpointToUse = params.recommendedWidgetOptionsId
      ? 'putRecommendedWidgetsOptions'
      : 'setRecommendedWidgetsOptions';

    this.dashboardService[endpointToUse](params).then(() => {
      if (onlyUpdateChart) {
        this.getChartData();
        return;
      }
      this.onChangeWidgetList();
    });
  }

  getChartData() {
    const endpointToUse = getTargetEndpoint(this.widgetInfo.widgetId);
    if (!endpointToUse) {
      return;
    }
    this.dashboardService[endpointToUse]().then((data) => {
      const timePeriod = this.timePeriodSelected.valueId;
      const isMonthly = !TARGET_DD_MM.includes(timePeriod);
      this.rawTargetData = data;
      this.chartLabels = data.map((item) =>
        isMonthly ? item.monthStr : `${item.day}/${item.monthInt}`,
      );
      this.chartData[1] = data.map((item) => item.loanAmount);

      this.structureTargetLine();
    });
  }

  computeLegendInfo() {
    this.legendAmount = this.chartData[1].reduce(
      (prev, next) => prev + next,
      0,
    );
    this.legendTotal = this.legendAmount - this.targetValue;
  }
}
