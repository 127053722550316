export const DATE_RANGE = {
  Month1: '1Month',
  Months3: '3Months',
  Month6: '6Months',
  Months12: '12Months',
};

export const DATE_COUNT = {
  DAYS_IN_YEAR: 365,
  DAYS_IN_MONTH: 30,
  MONTHS_IN_YEAR: 12,
  WEEKS_IN_YEAR: 52,
  FORTNIGHTS_IN_YEAR: 26,
  WEEKS_IN_MONTH: 52 / 12,
  FORTNIGHTS_IN_MONTH: 2,
};

export const OFFSET_FREQUENCY = {
  Monthly: DATE_COUNT.MONTHS_IN_YEAR,
  Weekly: DATE_COUNT.WEEKS_IN_YEAR,
  Fortnightly: DATE_COUNT.FORTNIGHTS_IN_YEAR,
};

export const FREQUENCY_COUNT_MONTH = {
  Monthly: 1,
  Weekly: DATE_COUNT.WEEKS_IN_MONTH,
  Fortnightly: DATE_COUNT.FORTNIGHTS_IN_MONTH,
};
