import template from './datePicker.html';
import controller from './datePickerCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    model: '=',
    isRequired: '<',
    config: '<',
    onChange: '&',
    disableTooltip: '<',
    iconRight: '<',
    placeholder: '<',
    useCalendarGridIcon: '<',
  },
};
