import template from './loanVariationModal.html';
import controller from './loanVariationModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    loanId: '<',
    modalInstance: '<',
    loanAppId: '<',
  },
};
