import { DATE_TEXT_FORMAT } from 'Common/constants/dateConfigs';
import { COLOR } from 'Common/constants/colors';
import { getLoanSecurityMarkUp, getFirstSecurityWithAddress } from './util';

export default class ExpandableTableCtrl {
  constructor(loanVariationsService, currentUserService, uiService) {
    'ngInject';

    this.loanVariationsService = loanVariationsService;
    this.currentUserService = currentUserService;
    this.uiService = uiService;
  }

  $onInit() {
    this.headerList = [
      {
        label: 'Status',
        key: 'status',
      },
      {
        label: 'Amount',
        key: 'amount',
      },
      {
        label: 'Purpose',
        key: 'purpose',
      },
      {
        label: 'Lender',
        key: 'lender',
      },
      {
        label: 'Sec. Address',
        key: 'security',
      },
      {
        label: 'Involved Parties',
        key: 'involved-parties',
      },
    ];
    this.DATE_TEXT_FORMAT = DATE_TEXT_FORMAT;
    this.getLoanSecurityMarkUp = getLoanSecurityMarkUp;
    this.COLOR = COLOR;
  }

  $onChanges(changes) {
    changes.loanList && this.setLoanDetailsFirstSecurityAddress();
    changes.loanList && this.getLoanVariationDetails();
  }

  setLoanDetailsFirstSecurityAddress() {
    this.loanList = this.loanList.map((loanDetails) => {
      return {
        ...loanDetails,
        firstSecurityWithAddress: getFirstSecurityWithAddress(
          loanDetails.SecurityInfo,
        ),
      };
    });
  }

  getLoanVariationDetails() {
    this.loanList &&
      this.loanList.forEach((loan) => {
        if (loan.IsTopUpOrVariation) {
          this.loanVariationsService
            .getLoanVariationDetails(loan.LoanID, true)
            .then((response) => {
              loan.variationDetails = response;
            });
        }
      });
  }

  allowOpenDeal() {
    return (
      this.currentUserService.isCorporate && this.uiService.viewOfAfileTurnedOn
    );
  }
}
