import { KEY_CODE } from 'Common/constants/keyCode';

class ClientSearchCtrl {
  constructor($timeout, loanFileService, timeoutService) {
    'ngInject';

    this.$timeout = $timeout;
    this.loanFileService = loanFileService;
    this.timeoutService = timeoutService;
  }

  checkSearchValidity(event) {
    if (!this.searchNameString) {
      this.isSearching = false;
      this.displaySearchResult = false;
      this.selectedClient = {};
      if (typeof this.onChange === 'function') {
        this.onChange({ client: {} });
      }
      return false;
    }
    const {
      ARROW_KEY_DOWN,
      ARROW_KEY_UP,
      ARROW_KEY_RIGHT,
      ARROW_KEY_LEFT,
      WIN_ALT,
      ESC,
      TAB,
    } = KEY_CODE;
    const invalidKeyCode = [
      ARROW_KEY_DOWN,
      ARROW_KEY_UP,
      ARROW_KEY_RIGHT,
      ARROW_KEY_LEFT,
      WIN_ALT,
      ESC,
      TAB,
    ];
    return !invalidKeyCode.includes(event.keyCode);
  }

  onSearchClient(event) {
    this.$timeout.cancel(this.searchTimeoutInstance);
    if (!this.checkSearchValidity(event)) {
      return;
    }
    this.searchTimeoutInstance = this.$timeout(() => {
      this.isSearching = true;
      this.displaySearchResult = true;
      this.loanFileService
        .searchName({
          searchNameString: this.searchNameString,
        })
        .then(({ data = [] }) => {
          this.$timeout(() => {
            this.results = data.filter((client) => client.ClientName);
            this.noSuggestionAvailable = !data.length;
            this.isSearching = false;
          });
        });
    }, 200);
  }

  onSelectClient(client = {}) {
    this.selectedClient = { ...client };
    this.searchNameString = client.ClientName;
    this.displaySearchResult = false;
    if (typeof this.onChange === 'function') {
      this.onChange({ client });
    }
  }

  onSearchBlur() {
    this.searchFocusTimeout = this.$timeout(() => {
      this.displaySearchResult = false;
    }, 500);
  }

  onSearchEscape() {
    this.displaySearchResult = false;
  }

  onSearchFocus(override) {
    this.$timeout.cancel(this.searchFocusTimeout);
    this.displaySearchResult = typeof override === 'boolean' ? override : true;
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('ClientSearchCtrl');
  }
}

export default ClientSearchCtrl;
