export const getOptionsForMortgageYears = ({ $scope, apiService }) => () => {
  apiService.getOptionsForMortgageBrokerYears().then(({ data }) => {
    $scope.mortgageBrokerYearsOptions = data;
  });
};

export const getOptionsForAggregatorEmployer = ({
  $scope,
  apiService,
}) => () => {
  apiService.getOptionsForAggregatorEmployer().then(({ data }) => {
    $scope.aggregatorEmployerOptions = data;
  });
};

export const getOptionsForCurrentPainPoints = ({
  $scope,
  apiService,
}) => () => {
  apiService.getCurrentPainPointsList().then(({ data }) => {
    $scope.currentPainPointTypesList = data;
  });
};

export const getOptionsForEnquirySources = ({ $scope, apiService }) => () => {
  apiService.inquirySourceListGet().then(({ data }) => {
    $scope.EnquirySourceList = data;
  });
};

export const mapPainPointTypeListOnUpdate = (obj) => {
  obj.PainPointTypeIds =
    obj &&
    obj.PainPointTypes &&
    obj.PainPointTypes.map((item) => item && parseInt(item.Value, 10));
};

export const displayPainPointTypes = (data = []) =>
  data.map((obj) => obj.Name).join(' , ');

export const mapQualificationDataCapturedOnUpdate = ({
  key,
  obj,
  queryService,
  $scope,
}) => {
  if (key !== 'QualificationDataCapturedBy_FullName') {
    return;
  }
  if (obj.QualificationDataCapturedBy_FullName) {
    if (typeof obj.QualificationDataCapturedBy_FullName === 'object') {
      obj.QualificationDataCapturedBy_FullName =
        obj.QualificationDataCapturedBy_FullName.originalObject.FullName;
      obj.QualificationDataCapturedByFamilyID = queryService.getArrValue(
        null,
        obj.QualificationDataCapturedBy_FullName,
        'FamilyId',
        'FullName',
        $scope.adminUsers,
      );
      obj.QualificationDataCapturedBy_FullNameTmp = queryService.getArrValue(
        obj.QualificationDataCapturedByFamilyID,
        null,
        'FamilyId',
        'FullName',
        $scope.adminUsers,
      );
    }
  } else {
    obj.QualificationDataCapturedBy_FullName = '';
    obj.QualificationDataCapturedByFamilyID = 0;
    obj.QualificationDataCapturedBy_FullNameTmp = '';
  }
};

export const painPointsHasOther = (data = []) => {
  const dataWithPainPointsOther = data.filter(
    (item) => item && item.Name === 'Other',
  );
  return dataWithPainPointsOther.length > 0;
};

export const mapQualificationDataCapturedOnGet = ({
  response,
  queryService,
  $scope,
}) => {
  response.data.QualificationDataCapturedBy_FullName = queryService.getArrValue(
    response.data.QualificationDataCapturedByFamilyID,
    null,
    'FamilyId',
    'FullName',
    $scope.adminUsers,
  );
  response.data.QualificationDataCapturedBy_FullNameTmp = queryService.getArrValue(
    response.data.QualificationDataCapturedByFamilyID,
    null,
    'FamilyId',
    'FullName',
    $scope.adminUsers,
  );
};
