import {
  AVERAGE_LOAN_SIZE,
  ALL_VALUE,
  WIDGET_OPTION_VAL,
  WIDGET_TITLE,
  LOAN_WEEKLY_DATA,
} from 'Common/constants/dashboardWidget';
import { BAR_LINE_OPTION } from 'Common/config/chart';
import {
  getValueOption,
  parseMultiSelectTicks,
  getCurrentCarouselData,
  mapOptionAll,
  mapSelectedItem,
  getCustomOptionValues,
  getIndexPeriod,
  getAdvisersParams,
  getFormattedAdvisers,
} from 'Common/utilities/dashboardWidget';
import { RGBA } from 'Common/constants/colors';

export default class AverageLoanSizeCtrl {
  constructor(
    $q,
    corporateService,
    currentUserService,
    optionsService,
    overviewDashboardService,
    dashboardService,
    commonWidgetService,
    mycrmLookupService,
  ) {
    'ngInject';

    this.$q = $q;
    this.corporateService = corporateService;
    this.currentUserService = currentUserService;
    this.optionsService = optionsService;
    this.overviewDashboardService = overviewDashboardService;
    this.dashboardService = dashboardService;
    this.commonWidgetService = commonWidgetService;
    this.mycrmLookupService = mycrmLookupService;
  }

  $onInit() {
    this.AVERAGE_LOAN_SIZE = AVERAGE_LOAN_SIZE;
    this.widgetTitle = WIDGET_TITLE.AVG_LOAN_SIZE;
    this.widgetSubTitle = '';
    this.showWidgetSettings = true;
    this.timePeriodList = [];
    this.loanStatusList = [];
    this.advisersDropdown = [];
    this.chartOptions = BAR_LINE_OPTION;
    this.loanYears = [];

    this.datasetOverride = [
      {
        label: 'Total Loans',
        yAxisID: 'sub-y-axis',
        borderWidth: 1,
        type: 'line',
        lineTension: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: true,
        borderColor: RGBA.GRAY_SUIT_OPAQUE,
        backgroundColor: RGBA.GRAY_SUIT_OPAQUE,
      },
      {
        label: 'Loan Size',
        borderWidth: 0,
        yAxisID: 'main-y-axis',
        backgroundColor: RGBA.GRAY_SUIT,
        type: 'bar',
      },
    ];

    this.getWidgetOptionDropdown();
  }

  goToReport() {
    const params = {
      widgetId: this.myParam.WidgetId,
      widgetUserId: this.myParam.Widget_UserID,
    };
    this.commonWidgetService.goToReport(params);
  }

  getWidgetOptionDropdown() {
    const { isAssistant, isAdminAssistant } = this.currentUserService;
    const isLoanWriterOnly = isAssistant || isAdminAssistant;

    const promises = [
      this.commonWidgetService.getWidgetOptions(AVERAGE_LOAN_SIZE.TIME_PERIOD),
      this.commonWidgetService.getWidgetOptions(AVERAGE_LOAN_SIZE.LOAN_STATUS),
      this.mycrmLookupService.getAdvisers(getAdvisersParams(isLoanWriterOnly)),
    ];

    this.$q.all(promises).then((responses) => {
      if (!responses || !responses.length) {
        return;
      }
      const [timePeriodList, loanStatusList, advisersDropdown] = responses;

      this.loanStatusList = loanStatusList;
      this.timePeriodList = timePeriodList;
      this.advisersDropdown = getFormattedAdvisers(advisersDropdown);
      this.getWidgetUserOptions(true);
    });
  }

  setCustomWidgetOption(variableName, isAll) {
    const optionValues = getCustomOptionValues(this[variableName]);

    const { ADVISER } = WIDGET_OPTION_VAL;
    const widgetOptionId = AVERAGE_LOAN_SIZE.ADVISERS;

    const customOption = ADVISER.CUSTOM;
    const allOption = ADVISER.ALL;

    const value = isAll ? allOption : customOption;
    this.setWidgetOptions({
      widgetOptionId,
      value,
      selectedUser: optionValues,
    });
  }

  selectAll(varName, inputData) {
    this[varName] = mapOptionAll(inputData);
    this.setCustomWidgetOption(varName, true);
  }

  onItemSelect(itemClicked, variableName, inputData, selection) {
    if (itemClicked.value === ALL_VALUE || !selection.length) {
      this.selectAll(variableName, inputData);
      return;
    }

    this[variableName] = mapSelectedItem(inputData);
    this.setCustomWidgetOption(variableName);
  }

  getWidgetUserOptions(isLoad) {
    return this.commonWidgetService
      .getWidgetUserOptions(this.myParam.Widget_UserID)
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }

        const timePeriod = getValueOption(AVERAGE_LOAN_SIZE.TIME_PERIOD, data);
        this.timePeriodSelected = timePeriod;

        const loanStatus = getValueOption(AVERAGE_LOAN_SIZE.LOAN_STATUS, data);
        this.loanStatusSelected = parseInt(loanStatus.Widget_OptionValue, 10);
        this.widgetSubTitle = `Average value and total count of ${loanStatus.DisplayValue.toLowerCase()} loans`;

        const advisers = getValueOption(AVERAGE_LOAN_SIZE.ADVISERS, data);
        this.advisersDropdown = parseMultiSelectTicks(
          advisers,
          this.advisersDropdown,
        );

        isLoad && this.getAverageLoanSize();
      });
  }

  setWidgetOptions(params) {
    const { widgetOptionId, value, selectedUser, needUpdate } = params;
    const payload = {
      WidgetOptionId: widgetOptionId,
      Widget_UserID: this.myParam.Widget_UserID,
      Widget_OptionValue: value || 0,
      SelectedUser: selectedUser,
    };
    this.overviewDashboardService.WidgetOptionSet(payload).then(() => {
      needUpdate && this.getWidgetUserOptions(true);
      !needUpdate && this.getAverageLoanSize();
    });
  }

  setTimePeriod(isNext, currentPeriod, list) {
    const indexPeriod = getIndexPeriod(list, currentPeriod);

    this.timePeriodSelected = getCurrentCarouselData(isNext, indexPeriod, list);

    this.setWidgetOptions({
      widgetOptionId: AVERAGE_LOAN_SIZE.TIME_PERIOD,
      value:
        this.timePeriodSelected.Widget_OptionValue ||
        this.timePeriodSelected.valueId,
    });
  }

  setView(isSettings) {
    this.isSettings = isSettings;
  }

  getAverageLoanSize() {
    const timeValueId =
      this.timePeriodSelected.Widget_OptionValue ||
      this.timePeriodSelected.valueId;
    const timePeriod = this.timePeriodSelected && parseInt(timeValueId, 10);
    const isMonthly = !LOAN_WEEKLY_DATA.includes(timePeriod);

    this.chartOptions.tooltips = {
      ...this.chartOptions.tooltips,
      callbacks: {
        label: (valuePayload, { datasets }) => {
          const isInvalidData =
            !datasets || !datasets.length || datasets.length < 2;
          if (isInvalidData) {
            return;
          }
          const loanCount = datasets[0].data[valuePayload.index];
          const loanSize = datasets[1].data[valuePayload.index];
          return valuePayload.datasetIndex
            ? `Loan Size: ${this.commonWidgetService.roundFigure(loanSize)}`
            : `Total Loan: ${loanCount}`;
        },
      },
    };

    const loanAmountAxes = { ...this.chartOptions.scales.yAxes };
    loanAmountAxes[0].ticks = {
      ...loanAmountAxes[0].ticks,
      callback: (value) => this.commonWidgetService.getShortenAmount(value),
    };
    loanAmountAxes[1].ticks = {
      ...loanAmountAxes[0].ticks,
      callback: (value) => {
        if (value % 1 === 0) {
          return value;
        }
      },
    };

    this.dashboardService
      .getAverageLoanSize({ userWidgetId: this.myParam.Widget_UserID })
      .then((data) => {
        if (!data.length) {
          return;
        }

        const lenderAmounts = data.map((item) => item.lenderTotalAmount);
        const lenderCounts = data.map((item) => item.lenderTotalCount);
        this.loanYears = data.map((item) => item.year);
        this.chartData = [lenderCounts, lenderAmounts];
        this.chartLabels = data.map((item) =>
          isMonthly ? item.monthStr : `${item.day}/${item.monthInt}`,
        );

        this.chartOptions.tooltips.callbacks = {
          ...this.chartOptions.tooltips.callbacks,
          title: (value) => {
            if (!value || !value.length) {
              return '';
            }
            const indexYr = value[0].index;
            return `${value[0].xLabel} ${
              this.loanYears.length && this.loanYears[indexYr]
            }`;
          },
        };
      });
  }
}
