import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';
import { ADVISER_TAB } from 'Common/constants/contactTabIndex';
import { displayError } from 'Common/utilities/alert';

export const openCreditCheck = ({
  $scope,
  $state,
  modalRenderService,
}) => () => {
  const contactForCreditCheck = {
    FamilyID: $scope.familyId,
    ClientFirstName: $scope.adviserDetails.FirstName,
    ClientLastName: $scope.adviserDetails.LastName,
    isAdviser: true,
  };
  const modalInstance = modalRenderService.openCreditCheckModal(
    contactForCreditCheck,
  );
  modalInstance.result.then(() => {
    $state.reload();
  });
};

export const displayCreditCheck = (currentUserService) => () => {
  const corporateAccess = [
    CORPORATE_TEAM.AGREEMENT_AND_ONBOARDING,
    CORPORATE_TEAM.SUPER_ADMIN,
  ];
  return corporateAccess.includes(currentUserService.corporateTeamId);
};

export const isRefreshSection = ({ $scope }) => (tabIndex) => {
  const tabRefreshSection = [
    ADVISER_TAB.ONBOARDING,
    ADVISER_TAB.DOCUMENTS,
    ADVISER_TAB.MARKETING,
    ADVISER_TAB.SUBSCRIPTIONS,
  ];
  return (
    !tabRefreshSection.includes(tabIndex) ||
    $scope.contactModel.selectedContacts === tabIndex
  );
};

export const downloadSnapshot = ({
  $scope,
  corporateModelService,
  downloadDataService,
}) => () => {
  if (!$scope.familyId) {
    return;
  }
  $scope.isDownloadingSnapShot = true;
  corporateModelService
    .getOnlineOnboardingSnapshot($scope.familyId)
    .then((data) => {
      const invalidDocument =
        !data || !data.contentType || !data.documentContent;
      if (invalidDocument) {
        displayError(
          'No available snapshot.',
          false,
          'Onboarding Summary Error',
        );
        $scope.isDownloadingSnapShot = false;
        return;
      }
      downloadDataService.download(
        `data:${data.contentType};base64,${data.documentContent}`,
        data.name,
        data.contentType,
      );
      $scope.isDownloadingSnapShot = false;
    })
    .catch(() => {
      $scope.isDownloadingSnapShot = false;
    });
};

export const loadSelectedTab = ({ $scope = {} }) => {
  $scope.loadSelectedTab = ({ index, page, isActive }) => {
    !isActive &&
      (() => {
        $scope.tabs_advisers = $scope.tabs_advisers.map((tab) => {
          if (tab.index === index && !isActive) {
            tab.pageToRender = page;
            tab.isActive = !isActive;
          }
          return tab;
        });
      })();
  };

  $scope.loadCurrentTab = (currentTab) => {
    $scope.tabs_advisers = $scope.tabs_advisers.map((tab) => {
      if (tab.index === currentTab) {
        tab.pageToRender = tab.page;
        tab.isActive = true;
      }
      return tab;
    });
  };
};
