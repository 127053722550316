import angular from 'angular';

export const loadModule = () =>
  angular.module('app').factory('apiRefactorData', function () {
    const factory = this;

    angular.extend(factory, {
      getUser: {
        key: 0,
        data: {},
        oldArgs: [],
      },
      settingsStatusGet: {
        key: 0,
        data: {},
        oldArgs: [],
      },
      getRecentlyViewed: {
        key: 0,
        data: {},
        oldParams: [],
      },
      contactFamilyInfoGet: {
        key: 0,
        data: {},
        oldArgs: [],
      },
    });

    return factory;
  });
