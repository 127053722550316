export default class ActiveFilesCtrl {
  constructor() {
    'ngInject';

    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
  }

  processParentNotification(eventHandler) {
    if (eventHandler === 'initActiveFiles') {
      this.initActiveFiles();
    }
  }

  initActiveFiles() {
    this.toggleShowContent();
  }

  toggleShowContent(showContent) {
    this.showContent = !!showContent;
  }
}
