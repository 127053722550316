import template from './customerCareInterestOnlyExpiry.html';
import controller from './customerCareInterestOnlyExpiryCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    dateFilter: '<',
    assignedFilter: '<',
    customerCareData: '<',
    userInfo: '<',
    autoMarketingSubscription: '<',
  },
};
