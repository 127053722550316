export default class ReferralDetailsCtrl {
  constructor($state, referralService) {
    'ngInject';

    this.$state = $state;
    this.referralService = referralService;
  }

  $onInit() {
    this.referral = {
      ReferralID: this.$state.params.referralId,
      contact: this.$state.params.contact,
    };

    if (!this.referral.contact) {
      this.loadReferral();
    }
  }

  loadReferral() {
    this.referralService
      .getReferralWealthMarket(this.referral.ReferralID)
      .then((response) => {
        if (!response) {
          return;
        }

        this.referral = {
          ...this.referral,
          ...response,
        };
      });
  }
}
