import { ADDRESS_TYPE_LIST } from 'Common/constants/addressField';

export default class AddressLookupClientCtrl {
  handleUpdateAddress(address, key) {
    if (key === 'postal') {
      this.address[key] = {
        ...address,
        isSameAsHome: this.address.postal.isSameAsHome,
      };
      return;
    }
    this.address[key] = address;
  }

  handleSameAsHomeSetting(isOnTrigger = false) {
    if (isOnTrigger) {
      this.address.postal.isSameAsHome = !this.address.postal.isSameAsHome;
    }
    this.address.postal = {
      ...this.address.home,
      isSameAsHome: this.address.postal.isSameAsHome,
      displayDetails: false,
    };
  }

  $onInit() {
    this.addressTypeList = ADDRESS_TYPE_LIST;
    this.displayPostalAddress = false;
  }
}
