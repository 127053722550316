import template from './onboardingCommonPreview.html';

export default {
  template,
  bindings: {
    title: '@',
    value: '@',
  },
  controllerAs: 'vm',
};
