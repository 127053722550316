import _ from 'lodash';

import {
  BORROWER_PARTY_TYPES,
  PERSON_PARTY_TYPES,
  ENTITY_PARTY_TYPES,
  INVOLVED_PARTIES_TYPE,
} from 'Common/constants/partyTypes';
import { ROLE_TYPE } from 'Common/constants/roleType';
import { SEARCH_TYPES } from 'Common/constants/searchType';
import { getSpecificBrokerNotes } from 'Common/utilities/loanApplication';
import { BROKER_NOTES_ID } from 'Common/constants/loanAppBrokerNotes';
import { toastError } from 'Common/utilities/alert';

export const assignApplicantBrokerNotesUtil = (scope, brokerNotesResponse) => {
  if (!scope || !brokerNotesResponse) {
    return;
  }
  scope.assess = {
    borrowers: {},
  };
  scope.assess.borrowers.brokerNotes = getSpecificBrokerNotes(
    brokerNotesResponse,
    BROKER_NOTES_ID.BORROWERS,
  );
};
export const mapSoleTraderList = ({
  $scope,
  utilitiesService,
  searchString,
}) => (contactModelService) => {
  const { loanAppId } = $scope;
  if (!searchString || !loanAppId) {
    return;
  }
  const params = {
    searchString,
    loanAppId,
  };
  $scope.isSearchingSoleTrader = true;
  contactModelService.getSoleTraderList(params).then(({ data }) => {
    $scope.isSearchingSoleTrader = false;
    if (!data || !data.length) {
      return;
    }
    $scope.soleTraderList = data.map((obj) => {
      obj.initials = obj.ClientName
        ? utilitiesService.filterInitialOneString(obj.ClientName)
        : '';
      return obj;
    });
  });
};

export const setDependantParent = ({
  $scope,
  dependantModelService,
  payload,
  opportunityLoanAppService,
  dependentsWithOneClientAsParent,
}) => {
  if (!payload) {
    return;
  }
  const resolvedFn = () => {
    $scope.resetSearch(0);
    $scope.getScenarioClientList();
    $scope.loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS = refreshApplicantDepentSection();
    opportunityLoanAppService.executeCallback();
    $scope.updateLoanAppSummary();
  };
  if (!dependentsWithOneClientAsParent) {
    return resolvedFn();
  }
  try {
    dependantModelService
      .setDependantParent(payload)
      .then(() => {
        resolvedFn();
      })
      .catch(toastError);
  } catch (error) {
    toastError(error);
  }
};

export const getPartyType = (searchtype, selectedPartyType) => {
  const partyType = parseInt(selectedPartyType, 10);
  const isBorrowerSearch = searchtype === 'borrowers';
  const isGuarantor = searchtype === 'guarantors';
  const isSolicitorOrAccountantType =
    partyType === PERSON_PARTY_TYPES.SOLICITOR ||
    partyType === PERSON_PARTY_TYPES.ACCOUNTANT;
  const isGurantorOrDependentType =
    partyType === PERSON_PARTY_TYPES.GUARANTOR ||
    partyType === PERSON_PARTY_TYPES.DEPENDENT;

  if (isGuarantor) {
    return INVOLVED_PARTIES_TYPE.CLIENT_ENTITY;
  }
  if (isBorrowerSearch || isSolicitorOrAccountantType) {
    return BORROWER_PARTY_TYPES.PERSON;
  }
  if (isGurantorOrDependentType) {
    return PERSON_PARTY_TYPES.APPLICANT;
  }
};

export const updateClientDescription = (selectedClient, selectedPartyType) => {
  selectedClient.IsApplicant = false;
  selectedClient.IsGuarantor = false;
  selectedClient.IsSolicitor = false;
  selectedClient.IsAccountant = false;
  selectedClient.AddRelatedChildren = false;
  selectedClient.IsClientSoleTrader = false;
  const parsedSelectedPartyType = parseInt(selectedPartyType, 10);
  // Override the same party type but different Desc
  if (parsedSelectedPartyType === PERSON_PARTY_TYPES.APPLICANT) {
    selectedClient.ClientTypeDesc = 'Borrower';
    selectedClient.IsApplicant = true;
    selectedClient.AddRelatedChildren = true;
  }

  if (parsedSelectedPartyType === ENTITY_PARTY_TYPES.COMPANY) {
    selectedClient.ClientTypeDesc = 'Trust';
    selectedClient.IsApplicant = true;
  }

  if (parsedSelectedPartyType === ENTITY_PARTY_TYPES.PARTNERSHIP) {
    selectedClient.ClientTypeDesc = 'Partnership';
    selectedClient.IsClient = false;
    selectedClient.IsApplicant = true;
  }

  if (parsedSelectedPartyType === PERSON_PARTY_TYPES.GUARANTOR) {
    selectedClient.ClientTypeDesc = 'Guarantor';
    selectedClient.IsGuarantor = true;
  }

  if (parsedSelectedPartyType === PERSON_PARTY_TYPES.SOLICITOR) {
    selectedClient.ClientTypeDesc = 'Solicitor';
    selectedClient.IsSolicitor = true;
  }

  if (parsedSelectedPartyType === PERSON_PARTY_TYPES.ACCOUNTANT) {
    selectedClient.ClientTypeDesc = 'Accountant';
    selectedClient.IsAccountant = true;
  }

  if (parsedSelectedPartyType === PERSON_PARTY_TYPES.SOLE_TRADER) {
    selectedClient.ClientTypeDesc = 'Sole Trader';
    selectedClient.IsClientSoleTrader = true;
    selectedClient.IsApplicant = true;
    selectedClient.IsClient = true;
  }

  return selectedClient;
};

export const setSearchTypeToggles = ($scope, searchType) => {
  switch (searchType) {
    case SEARCH_TYPES.BORROWER:
      $scope.isShowBorrowerContact = !$scope.isShowBorrowerContact;
      $scope.isShowGuarantorContats = false;
      $scope.isShowComapniesContact = false;
      $scope.isShowSoleTradersContact = false;
      break;
    case SEARCH_TYPES.GUARANTOR:
      $scope.isShowGuarantorContats = !$scope.isShowGuarantorContats;
      $scope.isShowBorrowerContact = false;
      $scope.isShowComapniesContact = false;
      $scope.isShowSoleTradersContact = false;
      break;
    case SEARCH_TYPES.COMPANY:
      $scope.isShowComapniesContact = !$scope.isShowComapniesContact;
      $scope.isShowGuarantorContats = false;
      $scope.isShowBorrowerContact = false;
      $scope.isShowSoleTradersContact = false;
      break;
    case SEARCH_TYPES.SOLE_TRADER:
      $scope.isShowSoleTradersContact = !$scope.isShowSoleTradersContact;
      $scope.isShowGuarantorContats = false;
      $scope.isShowBorrowerContact = false;
      $scope.isShowComapniesContact = false;
      break;
    default:
      $scope.isShowComapniesContact = false;
      $scope.isShowGuarantorContats = false;
      $scope.isShowBorrowerContact = false;
      $scope.isShowSoleTradersContact = false;
      break;
  }
};

export const refreshApplicantDepentSection = () => {
  return {
    RefreshIncome: true,
    RefreshExpense: true,
    RefreshLiability: true,
    RefreshLoanapp: true,
    RefreshAddress: true,
    RefreshEmployment: true,
    RefreshApplication: true,
    RefreshAsset: true,
  };
};

export const linkedSoleTrader = ({ $scope }) => (loanScenarioService) => {
  const { selectedClient } = $scope;
  if (!selectedClient) {
    return;
  }
  const params = updateClientDescription(
    selectedClient,
    PERSON_PARTY_TYPES.SOLE_TRADER,
  );
  loanScenarioService.scenarioApplicantSet(params).then(() => {
    $scope.resetSearch(0);
    $scope.getScenarioClientList();
    $scope.loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS = refreshApplicantDepentSection();
  });
};

export const getListDependents = (dependents) => {
  return dependents.filter((obj) => {
    return obj.Role === ROLE_TYPE.DEPENDENT;
  });
};

export const getListSolicitors = (solicitors) => {
  return solicitors.filter((obj) => {
    return obj.IsSolicitor;
  });
};

export const getListAccountants = (accountants) => {
  return accountants.filter((obj) => {
    return obj.IsAccountant;
  });
};

export const getListAssetSuppliers = (assetSuppliers) => {
  return assetSuppliers.filter((obj) => {
    return obj.IsAssetSupplier;
  });
};

export const getApplicantList = (listApplicants) => {
  const tempApplicantList = [];

  Object.keys(listApplicants).forEach((key) => {
    listApplicants[key].forEach((familyObject) => {
      if (familyObject.IsApplicant) {
        tempApplicantList.push(familyObject);
      }
    });
  });

  return tempApplicantList;
};

export const getGuarantorList = (listApplicants) => {
  const tempGuarantorList = [];

  Object.keys(listApplicants).forEach((key) => {
    listApplicants[key].forEach((familyObject) => {
      if (familyObject.IsGuarantor) {
        tempGuarantorList.push(familyObject);
      }
    });
  });

  return tempGuarantorList;
};

export const getClientFullName = (clientEntity) => {
  if (!clientEntity) {
    return '';
  }
  const clientName = clientEntity.PersonId
    ? `${clientEntity.FirstName} ${clientEntity.MiddleName} ${clientEntity.LastName}`
    : clientEntity.Name || '';

  return clientName.trim();
};
export const getListApplicants = (involvedPartyPerson) => {
  return Object.keys(involvedPartyPerson)
    .map((key) => {
      return involvedPartyPerson[key].filter(
        (people) => people.IsApplicant && !people.IsClientSoleTrader,
      );
    })
    .filter((array) => array.length);
};

export const getListSoleTraders = (involvedPartyPerson) => {
  return Object.keys(involvedPartyPerson)
    .map((key) => {
      return involvedPartyPerson[key].filter(
        (people) => people.IsApplicant && people.IsClientSoleTrader,
      );
    })
    .filter((array) => array.length);
};

export const getListGuarantors = (
  involvedPartyPerson,
  involvedPartyEntity = [],
) => {
  const filteredListGuarantors = Object.keys(involvedPartyPerson)
    .map((key) => {
      return involvedPartyPerson[key].filter((people) => people.IsGuarantor);
    })
    .filter((array) => array.length);

  const filterListGuarantorForEntity = involvedPartyEntity.filter(
    (entity) => entity.IsGuarantor,
  );
  if (filterListGuarantorForEntity.length) {
    filteredListGuarantors.push(filterListGuarantorForEntity);
  }
  return filteredListGuarantors;
};

export const getLendingCategoryId = (data) => {
  if (!data) {
    return null;
  }
  return (
    data.loanAppSummary &&
    data.loanAppSummary.LoanDetails &&
    data.loanAppSummary.LoanDetails.LendingCategoryId
  );
};

export const isChangeLoanAppURL = (
  listApplicants,
  state,
  isLoanAppOnOpportunities = false,
) => {
  const urlChangeLength = 1;
  const isValidfamily =
    listApplicants && listApplicants.length && listApplicants[0].length;
  const family = isValidfamily && listApplicants[0][0];
  if (!isValidfamily || listApplicants.length !== urlChangeLength) {
    return;
  }

  !isLoanAppOnOpportunities &&
    state.go(
      'app.loanApplicationDetailsV3',
      {
        familyId: family.FamilyId,
        loanAppId: state.params.loanAppId,
      },
      {
        location: true,
        reload: true,
      },
    );
};
export const getMappedEnityForGuarantors = ({
  $scope,
  utilitiesService,
  colorService,
}) => (data) =>
  data &&
  data
    .filter((entity) => entity.IsGuarantor)
    .map((entity) => ({
      ...entity,
      FirstName: entity.FullName,
      initials: utilitiesService.filterInitialOneString(entity.FamilyFullName),
      background:
        $scope.loanAppSharedData.backgroundList[entity.FamilyId.toString()] ||
        $scope.getBGcolor(entity.FamilyId) ||
        colorService.getRandomColor(),
    }));

export const refreshApplicantSection = ($scope, $state) => {
  if (!$scope || !$state) {
    return;
  }
  $scope.getScenarioClientList().then(() => {
    isChangeLoanAppURL(
      $scope.listApplicants,
      $state,
      $scope.isLoanAppOnOpportunities,
    );
    $scope.updateLoanAppSummary();
  });
  $scope.loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS = {
    RefreshAddress: true,
    RefreshEmployment: true,
    RefreshApplication: true,
    RefreshAsset: true,
    RefreshIncome: true,
    RefreshExpense: true,
    RefreshLiability: true,
    RefreshLoanapp: true,
  };
};

export const getAddressHistory = ({
  loanAppId,
  loanScenarioService,
  loanAppSharedData,
}) => {
  loanScenarioService.getAddress(loanAppId).then(({ data }) => {
    if (!data) {
      return [];
    }
    loanAppSharedData.allAddressList = data;
  });
};

export const updateLoanAppSummary = ({ $scope, loanApplicationServices }) => {
  loanApplicationServices.loanSummaryGet($scope.loanAppId).then(({ data }) => {
    if (!data) {
      return;
    }
    $scope.loanAppSharedData.loanAppSummary = data;
  });
};

export const hasFullApplicantsAddressHistory = (loanAppSharedData) => {
  const {
    UPDATE_ADDRESS: addressHistorySummary,
    entityOnlyApplicant,
  } = loanAppSharedData;
  if (entityOnlyApplicant) {
    return true;
  }
  if (!addressHistorySummary || !addressHistorySummary.lengthByFamily) {
    return false;
  }
  const addressHistoryLengths = Object.values(
    addressHistorySummary.lengthByFamily,
  );
  return (
    !!addressHistoryLengths.length &&
    addressHistoryLengths.every((length) => length > 0)
  );
};

export const hasFullApplicantsIdentification = ({
  entityOnlyApplicant,
  applicantsToCheck,
  allIdentifications,
  country,
}) => {
  if (entityOnlyApplicant || country === 'New Zealand') {
    return true;
  }
  if (!applicantsToCheck || !applicantsToCheck.length) {
    return false;
  }

  const identificationsToCheck = _.intersectionBy(
    allIdentifications,
    applicantsToCheck,
    (a) => Number(a.FamilyId),
  );
  if (identificationsToCheck.length !== applicantsToCheck.length) {
    return false;
  }
  return identificationsToCheck.every((famIds) => {
    const { Identification: familyIdentification } = famIds;
    return familyIdentification && familyIdentification.length > 0;
  });
};

export const getLoanDependants = ({
  $scope,
  dependantModelService,
  involvedParties,
  utilitiesService,
}) => {
  try {
    dependantModelService
      .getDependants($scope.loanAppId)
      .then((data) => {
        $scope.listDependants = data.map((dependant) => {
          dependant.initials = utilitiesService.filterInitialOneString(
            dependant.PreferredName,
          );
          dependant.background =
            $scope.loanAppSharedData.backgroundList[
              dependant.FamilyId.toString()
            ] || $scope.getBGcolor(dependant.FamilyId);
          return dependant;
        });
        return {
          ...involvedParties,
          listDependants: $scope.listDependants,
        };
      })
      .catch(toastError);
  } catch (error) {
    toastError(error);
  }
};
