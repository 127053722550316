import template from './helloPackPhoneField.html';
import controller from './helloPackPhoneFieldCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    client: '<',
    key: '<',
    isRequired: '<',
    onUpdate: '&',
  },
};
