import {
  segmentFilterOptionsForUI,
  segmentFilterClientTypesForUI,
} from 'Common/mappers/segmentExportFilter';

export default class SegmentExportFilterService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.contactsBaseUrl = 'contacts';
    this.multiOptionBaseUrl = 'options';
  }

  getEmploymentTypes() {
    return this.httpClient
      .get(`${this.multiOptionBaseUrl}/General/EmploymentTypeGet`)
      .then(({ data }) => (data && data.map(segmentFilterOptionsForUI)) || []);
  }

  getReviewMonths() {
    return this.httpClient
      .get(`${this.multiOptionBaseUrl}/review-month`)
      .then(({ data }) => (data && data.map(segmentFilterOptionsForUI)) || []);
  }

  getClientTypes() {
    return this.httpClient
      .get(`${this.contactsBaseUrl}/ClientTypeListGet`)
      .then(
        ({ data }) => (data && data.map(segmentFilterClientTypesForUI)) || [],
      );
  }
}
