import { YOUTUBE_LINKS } from 'Common/constants/youtubeLinks';

export default class VideoModalForYoutubeCtrl {
  constructor($sce) {
    'ngInject';

    this.$sce = $sce;
  }

  $onInit() {
    this.videoUrl = this.trustVideo();
  }

  onClose() {
    return this.modalInstance.dismiss('cancel');
  }

  trustVideo() {
    return this.$sce.trustAsResourceUrl(
      `${YOUTUBE_LINKS[this.pageSource]}?autoplay=1`,
    );
  }
}
