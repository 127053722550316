import template from './targetBasedTile.html';
import controller from './targetBasedTileCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    widgetInfo: '<',
    onChangeWidgetList: '&',
    adviserFilter: '<',
  },
};
