import template from './lendingChooseProducts.html';
import controller from './lendingChooseProductsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    facility: '<',
    involvedPartyPerson: '<',
    cbProductFinder: '&',
  },
};
