import angular from 'angular';
import generateCqpOptions from './generateCqpOptions';
import creditQuoteDetailsModal from './creditQuoteDetailsModal';
import './style.scss';
import './creditQuoteDetailsModal.scss';

export default angular
  .module('components.generateCqpOptions', [])
  .component('creditQuoteDetailsModal', creditQuoteDetailsModal)
  .component('generateCqpOptions', generateCqpOptions).name;
