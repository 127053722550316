import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('customerComment', function customerComment(
      $timeout,
      overviewDashboardService,
      $uibModal,
      npsService,
      currentUserService,
    ) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: { myParam: '=' },
        templateUrl:
          'assets/views/overviewDashboard/directiveTemplates/customerComment.html',

        link($scope) {
          $scope.slickConfig = {};
          $scope.dataLoaded = false;
          const getSurveyResponse = () => {
            $scope.commentList = [];
            npsService
              .getSurveyResponse(0, 1, 0, 0, $scope.adviserFamilyId, true)
              .then((response) => {
                if (response && response.data) {
                  let data = response.data.SurveyResponses
                    ? response.data.SurveyResponses
                    : [];
                  if (data.length) {
                    data = data.filter((item) => {
                      return item.ResponseReceived;
                    });
                    $scope.commentList = data;
                    $scope.dataLoaded = true;
                  } else {
                    $scope.commentList = data;
                    $scope.dataLoaded = true;
                  }
                }
              });
          };

          const getUserInfo = () => {
            $scope.adviserFamilyId = currentUserService.familyId;
            getSurveyResponse();
          };

          getUserInfo();

          $scope.viewComments = function (data) {
            $uibModal.open({
              templateUrl:
                'assets/views/overviewDashboard/modals/customerCommentSingleModal.html',
              size: 'lg',
              backdrop: 'static',
              keyboard: false,
              controller: 'CustomerCommentModalSingleCtrl',
              resolve: {
                comments() {
                  return data;
                },
                alldata() {
                  return $scope.commentList;
                },
              },
            });
          };
        },
      };
    });
