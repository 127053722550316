export const getSelectedAdviser = (data, list) => {
  const filteredList =
    list &&
    list.filter((o) => {
      return parseInt(o.FamilyId, 10) === parseInt(data, 10);
    });
  return filteredList ? filteredList[0] : { FamilyId: data };
};

export const getAdviserName = (selectedAdviser, name) => {
  return selectedAdviser && selectedAdviser.FullName
    ? selectedAdviser.FullName
    : name;
};
