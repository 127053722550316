import moment from 'moment';
import { D_MMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';
import { capitalizeTextFromCamelCase } from 'Common/utilities/string';
export default class AccreditationUtilityService {
  constructor() {
    'ngInject';

    this.deafaultDates = moment;
  }

  getDefaultDates() {
    return this.deafaultDates().format(D_MMM_YYYY_FORMAT);
  }

  setDate() {
    return this.deafaultDates().format('YYYY-MM-DD hh:mm:ss');
  }

  setCreateObjectEntity(providers) {
    return Object.entries(providers)
      .map((product) => ({
        prodType: product[0],
        label: capitalizeTextFromCamelCase(product[0]),
        value: product[1],
      }))
      .filter(({ value }) => !!value);
  }
}
