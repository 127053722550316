import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';

export const isLMBranded = (branding) =>
  branding === BRANDING_CATEGORY_TYPE.LOAN_MARKET;

export const isOwnBrand = (branding) =>
  branding === BRANDING_CATEGORY_TYPE.OWN_BRAND;

export const isWMBranded = (branding) =>
  branding === BRANDING_CATEGORY_TYPE.WEALTH_MARKET;

export const isIMBranded = (branding) =>
  branding === BRANDING_CATEGORY_TYPE.INSURANCE_MARKET;

export const isAllBrand = ({ isLM, isInsuranceMarket, isBYOB }) =>
  isLM || isInsuranceMarket || isBYOB;
