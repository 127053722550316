import moment from 'moment';
import { toastInfo } from 'Common/utilities/alert';
import { TO_MYCRM_DATE_FORMAT } from 'Common/constants/dateConfigs';
import { pluralize } from 'Common/utilities/string';
import { getDatePickerConfig } from 'Common/utilities/date';
import { getMonths, getYears } from 'Common/utilities/calendar';

class SoleTraderInfoCtrl {
  constructor() {
    'ngInject';

    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
    this.currentDate = new Date();
    this.months = getMonths();
    this.years = getYears();
    this.dateConfig = getDatePickerConfig({
      dateOptions: {
        maxDate: new Date(),
        showWeeks: false,
      },
    });
    this.datePopUp = {
      tradingStartDate: false,
      gstRegistrationDate: false,
    };
    this.experienceDate = {};
  }

  $onChanges(changes) {
    const { contactData } = changes;
    if (
      contactData &&
      contactData.currentValue &&
      contactData.currentValue.PersonId !== this.personId
    ) {
      this.setSoleTraderInfo(contactData.currentValue);
    }
  }

  processParentNotification(eventHandler) {
    if (eventHandler === 'getSoleTraderInfo') {
      this.getSoleTraderInfo();
    }
  }

  getSoleTraderInfo() {
    if (this.soleTraderInfo) {
      const { ForDeletion, DetailsId } = this.soleTraderInfo;
      this.soleTraderInfo =
        ForDeletion && DetailsId
          ? { ForDeletion, DetailsId }
          : this.soleTraderInfo;
      this.soleTraderInfo =
        ForDeletion && !DetailsId ? null : this.soleTraderInfo;
    }
    const { soleTraderInfo, contactData } = this;
    this.returnSoleTraderInfo({
      contactData,
      soleTraderInfo,
    });
  }

  openDate(dateField) {
    if (!dateField) {
      return;
    }
    this.datePopUp[dateField] = !this.datePopUp[dateField];
  }

  toggleActiveSoleTrader(isSoleTrader = false) {
    this.isSoleTrader = isSoleTrader;
    this.soleTraderInfo = {
      ...this.soleTraderInfo,
      ...{ IsGSTRegistered: !isSoleTrader },
    };
    this.soleTraderInfo = {
      ...this.soleTraderInfo,
      ...(this.soleTraderInfo && { ForDeletion: !isSoleTrader }),
    };
  }

  setSoleTraderInfo(soleTrader) {
    const { PersonId, SoleTraderInfo } = soleTrader;
    this.soleTraderInfo = SoleTraderInfo;
    this.personId = PersonId;
    this.isSoleTrader = !!SoleTraderInfo;
    if (!this.soleTraderInfo) {
      return;
    }
    const { ForDeletion, DetailsId } = this.soleTraderInfo;
    if (ForDeletion && DetailsId) {
      this.soleTraderInfo = {
        ForDeletion,
        DetailsId,
      };
      this.isSoleTrader = !ForDeletion;
      return;
    }
    const {
      IndustryExperienceStartDate,
      TradingStartDate,
      GSTRegistrationDate,
    } = this.soleTraderInfo;
    this.soleTraderInfo.TradingStartDate = TradingStartDate
      ? new Date(TradingStartDate)
      : '';
    this.soleTraderInfo.GSTRegistrationDate = GSTRegistrationDate
      ? new Date(GSTRegistrationDate)
      : '';
    this.getMonthAndYear(IndustryExperienceStartDate);
  }

  getMonthAndYear(date) {
    if (!date) {
      return;
    }
    const dateConverted = moment(date, 'YYYY/MM/DD');
    const month = dateConverted.format('M');
    const year = dateConverted.format('YYYY');
    this.experienceDate = {
      month: parseInt(month, 10),
      year: parseInt(year, 10),
    };
    this.createExperienceDate();
  }

  createExperienceDate() {
    const { month, year } = this.experienceDate;
    if (!month || !year) {
      return;
    }
    const { currentDate } = this;
    const monthString = month >= 10 ? month : `0${month}`;
    const dateString = `${year}-${monthString}-01T00:00:00.000Z`;
    if (moment(currentDate).diff(dateString) < 0) {
      this.experienceDate = {};
      this.startDateTotalYears = null;
      toastInfo('Invalid date');
      return;
    }
    this.soleTraderInfo.IndustryExperienceStartDate = moment(dateString);
    this.getStartDate(this.soleTraderInfo.IndustryExperienceStartDate);
  }

  getStartDate(date) {
    if (!date) {
      this.startDateTotalYears = null;
      return;
    }
    const { currentDate } = this;
    const years = moment(currentDate).diff(date, 'years', false);
    const dateAdded =
      years && moment(date, TO_MYCRM_DATE_FORMAT).add(years, 'years');
    const months = dateAdded
      ? moment(currentDate).diff(dateAdded, 'months', false)
      : moment(currentDate).diff(date, 'months', false);
    const andString = years && months ? ' and ' : '';
    const monthString = pluralize(months, 'month');
    if (years) {
      const yearString = pluralize(years, 'year');
      this.startDateTotalYears = ` - ${yearString}`;
      this.startDateTotalYears = andString
        ? `${this.startDateTotalYears}${andString}${monthString}`
        : this.startDateTotalYears;
      return;
    }
    this.startDateTotalYears = ` - ${monthString}`;
  }
}

export default SoleTraderInfoCtrl;
