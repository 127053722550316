import './style.scss';
import template from './newLoanStructure.html';
import controller from './newLoanStructureCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanAppId: '<',
    loanStructureId: '<',
    useFirstLoanStructure: '@',
    usePanelWhite: '@',
    showAssetFinanceFields: '@',
    loanLender: '<',
    loanDetails: '<',
  },
};
