import template from './mycrmGroupOption.html';
import controller from './mycrmGroupOptionCtrl';
import './style.scss';

export default {
  template,
  bindings: {
    value: '<',
    options: '<',
    isFocused: '<',
    optionId: '@',
    optionLabel: '@',
    onChange: '&',
  },
  controller,
  controllerAs: 'vm',
};
