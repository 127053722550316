import {
  getDatePickerConfig,
  computeDays,
  formatDate,
} from 'Common/utilities/date';
import { DATE_TEXT_FORMAT } from 'Common/constants/dateConfigs';

const DEFAULT_YEAR = 30;
const DEFAULT_MONTH = 0;
export default class LiabilityDates {
  $onInit() {
    this.dateConfig = getDatePickerConfig({
      dateOptions: {
        showWeeks: false,
      },
    });
    this.showStartDateCalendar = false;
    this.showInterestOnlyDateCalendar = false;
    this.loanTermYear = this.loanTermYear || DEFAULT_YEAR;
    this.loanTermMonth = this.loanTermMonth || DEFAULT_MONTH;
    this.interestOnlyTermYear = this.interestOnlyTermYear || DEFAULT_MONTH;
    this.interestOnlyTermMonth = this.interestOnlyTermMonth || DEFAULT_MONTH;
    this.getInterestOnlyEndDate();
    this.getLoanEndDate();
  }

  getLoanEndDate() {
    if (!this.loanStartDate) {
      return (this.loanEndDate = 'Please provide start date');
    }
    const count = this.loanTermMonth + this.loanTermYear * 12;
    this.loanEndDate = formatDate(DATE_TEXT_FORMAT)(
      computeDays('add', 'months')(this.loanStartDate, count),
    );
  }

  getInterestOnlyEndDate() {
    if (!this.interestOnlyStartDate) {
      return (this.interestOnlyEndDate = null);
    }
    const count = this.interestOnlyTermMonth + this.interestOnlyTermYear * 12;
    this.interestOnlyEndDate = formatDate(DATE_TEXT_FORMAT)(
      computeDays('add', 'months')(this.interestOnlyStartDate, count),
    );
  }

  toggleStartDateCalendar() {
    this.showStartDateCalendar = !this.showStartDateCalendar;
  }

  toggleInterestOnlyEndDate() {
    this.showInterestOnlyDateCalendar = !this.showInterestOnlyDateCalendar;
  }
}
