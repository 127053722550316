import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import moment from 'moment';
import { DATE_COUNT } from 'Common/constants/dateRange';
import {
  validateClientAddressHistory,
  launchAddressLookup,
  updateAddressValidation,
} from './util/application.address';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanAppAddressCtrl', function LoanAppAddressCtrl(
      $scope,
      contactService,
      loanScenarioService,
      loanApplicationServices,
      optionsService,
      $filter,
      utilitiesService,
      $uibModal,
      loanAppSharedData,
      broadcastDataService,
      uiService,
      currentUserService,
      configService,
      addressHistoryService,
    ) {
      $scope.loanAppSharedData = loanAppSharedData;
      $scope.uiService = uiService;
      const { isNZ } = currentUserService;
      $scope.isInvalidClientAddressHistory = validateClientAddressHistory(
        $scope,
      );
      const addressLookupFn = launchAddressLookup({
        $scope,
        addressHistoryService,
        configService,
      });

      /**
       * Models
       * */
      angular.extend($scope, {
        AddressHistory: {
          Address: [],
          List: {
            Address: [],
          },
        },
        localLang: {
          selectAll: '<span>Select All</span>',
          selectNone: '<span>Deselect All </span>',
          reset: "<i class='fa fa-refresh'></i>",
          search: 'Search',
          nothingSelected:
            "<div class='buttonLabel'>&nbsp;No Client Selected</div>",
        },
        ownersList: [],
        ownersListSelected: [],
        ownersListSelectedArray: [],
        addAnotherAddress: false,
        item: {},
        saveAddClicked: false,
      });

      /**
       * Methods
       * */

      angular.extend($scope, {
        AddressHistory: {
          SetFamily(familyId) {
            $scope.AddressHistory.SetSelectedFamily(familyId);
            $scope.AddressHistory.List.Address = $filter('orderBy')(
              $scope.AddressHistory.List.Address,
              'EndDate',
              true,
            );
            /**
             * Connected to the Real Estate Existing Address
             * */
            loanAppSharedData.addressList =
              $scope.AddressHistory.List.Address || [];

            $scope.AddressHistory.ListInitials();

            $scope.UPDATE_ADDRESS();
          },

          SetSelectedFamily(familyId) {
            $scope.AddressHistory.List = _.find(
              $scope.AddressHistory.AllList,
              (o) => {
                return o.FamilyId === familyId;
              },
            );
          },

          ListInitials() {
            angular.forEach($scope.AddressHistory.AllList, (val) => {
              val.initials = utilitiesService.filterInitialOneString(
                val.FamilyName,
              );
              val.background = $scope.getBGcolor(val.FamilyId);
              contactService.clientInformGet(val.FamilyId).then((response) => {
                const filterPerson = _.filter(response.data, { Role: 'Adult' });
                val.clientId =
                  _.size(filterPerson) > 0 ? filterPerson[0].PersonId : null;
                val.background = $scope.getBGcolor(val.FamilyId);
              });
            });

            if ($scope.AddressHistory.List) {
              _.forEach($scope.AddressHistory.List.Address, (o) => {
                _.forEach(o.ContactLivingIn, (obj) => {
                  obj.background = $scope.getBGcolor(obj.BorrowerID);
                  if (
                    typeof obj.LastName !== 'undefined' &&
                    obj.LastName !== ''
                  ) {
                    obj.initials = utilitiesService.filterInitial(
                      obj.FirstName,
                      obj.LastName,
                    );
                  } else {
                    obj.initials = utilitiesService.filterInitialOneString(
                      obj.FirstName,
                    );
                  }
                });
              });
            }
            if ($scope.familyIdAsResponse) {
              $scope.AddressHistory.SelectedClient = $scope.familyIdAsResponse;
              $scope.AddressHistory.SetSelectedFamily(
                $scope.familyIdAsResponse,
              );
              $scope.familyIdAsResponse = 0;
            }
          },

          changeFamily() {
            $scope.AddressHistory.SetFamily(
              $scope.AddressHistory.SelectedClient,
            );
          },

          changeAddressType() {
            switch ($scope.AddressHistory.AddressType) {
              case '1': // Current Address
                $scope.showStartDate = true;
                $scope.showEndDate = false;
                $scope.AddressHistory.EndDate = '';
                break;

              case '2': // Postal Address
                $scope.showStartDate = false;
                $scope.AddressHistory.StartDate = '';

                $scope.showEndDate = false;
                $scope.AddressHistory.EndDate = '';
                break;

              // eslint-disable-next-line sonarjs/no-duplicated-branches
              case '3': // Post-Settlement Address
                $scope.showStartDate = false;
                $scope.AddressHistory.StartDate = '';

                $scope.showEndDate = false;
                $scope.AddressHistory.EndDate = '';

                break;

              case '4': // Previous Address
                $scope.showStartDate = true;
                $scope.showEndDate = true;
                break;

              // eslint-disable-next-line sonarjs/no-duplicated-branches
              case '5': // Other
                $scope.showStartDate = true;
                $scope.showEndDate = true;
                break;

              // eslint-disable-next-line sonarjs/no-duplicated-branches
              default:
                $scope.showStartDate = false;
                $scope.AddressHistory.StartDate = '';

                $scope.showEndDate = false;
                $scope.AddressHistory.EndDate = '';
            }
          },

          delete(id, index) {
            swal(
              {
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
              },
              (confirm) => {
                if (confirm) {
                  swal('Deleted!', 'Address has been removed.', 'success');
                  $scope.AddressHistory.List.Address.splice(index, 1);
                  loanApplicationServices.deleteAddress(
                    id,
                    $scope.AddressHistory.SelectedClient,
                  );
                  $scope.UPDATE_ADDRESS();
                }
              },
            );
          },
        },

        getListOfApplicants() {
          $scope.listApplicants = [];
          loanAppSharedData
            .getLoanAppApplicants($scope.loanAppId)
            .then((response) => {
              const tempList = [];
              angular.forEach(response.data.InvolvedPartyPerson, (family) => {
                angular.forEach(family, (client) => {
                  client.Background = $scope.getBGcolor(client.FamilyId);
                  client.listPriority = client.IsApplicant ? 0 : 1;
                  tempList.push(client);
                });
              });
              $scope.listApplicants = _.orderBy(
                tempList,
                ['listPriority'],
                ['asc'],
              );
              $scope.isInvalidClientAddressHistory();
            });
        },

        getAddressOwnership() {
          optionsService.ownershipTypeGet().then((respond) => {
            const defaultOwnership = { Name: 'Please select', Value: null };
            $scope.ownerTypeGetOptions = [defaultOwnership, ...respond.data];
          });
        },

        // eslint-disable-next-line sonarjs/cognitive-complexity
        getAddressHistory() {
          loanScenarioService.getAddress($scope.loanAppId).then((response) => {
            $scope.personList = [];
            let addressHistories = [];
            if (!response && !response.data) {
              return;
            }
            _.forEach(response.data, (data) => {
              const { Address } = data;
              let livingAddress = _.omit(data, ['Address']);
              const person = _.filter($scope.listApplicants, (o) => {
                return livingAddress.FamilyId === parseInt(o.FamilyId, 10);
              });
              const aliveAddressHistory = Address.map((address) => {
                address.ContactLivingIn = address.ContactLivingIn.filter(
                  (livingIn) => !livingIn.Deceased,
                );
                return address;
              });
              livingAddress = {
                ...livingAddress,
                Address: aliveAddressHistory,
              };
              if (person.length > 0 && typeof person[0].listPriority) {
                livingAddress.listPriority =
                  person[0].listPriority === 0 ? 0 : 1;
              } else {
                livingAddress.listPriority = 1;
              }
              addressHistories = [...addressHistories, livingAddress];
            });

            /**
             * Dropdown options for multiple applicants
             * */
            $scope.AddressHistory.AllList = _.orderBy(
              addressHistories,
              ['listPriority'],
              ['asc'],
            );
            loanAppSharedData.allAddressList = addressHistories;

            /**
             * Initialize the first selected client
             * */
            $scope.AddressHistory.List = _.first($scope.AddressHistory.AllList);

            /**
             * Connected to the Real Estate Existing Address
             * */
            loanAppSharedData.addressList = $scope.AddressHistory.List
              ? $scope.AddressHistory.List.Address
              : [];

            $scope.AddressHistory.ListInitials();
            $scope.AddressHistory.SelectedClient = $scope.AddressHistory.List
              ? $scope.AddressHistory.List.FamilyId
              : null;

            if ($scope.AddressHistory.List) {
              angular.forEach($scope.AddressHistory.List.Address, (val) => {
                switch (val.TypeId) {
                  case '1': // Current Address
                    val.EndDate = '';
                    break;

                  case '2': // Postal Address
                    break;

                  case '3': // Post-Settlement Address
                    val.StartDate = '';
                    val.EndDate = '';

                    break;

                  case '4': // Previous Address
                    break;

                  case '5': // Other
                    break;

                  default:
                    break;
                }
              });
            }

            $scope.UPDATE_ADDRESS();
            $scope.$parent.validateChanges && $scope.$parent.validateChanges();
          });
        },

        getAddressType() {
          optionsService.addressTypeGet().then((response) => {
            const defaultAddressType = { Name: 'Please select', Value: null };
            $scope.AddressHistory.addressTypeList = [
              defaultAddressType,
              ...response.data,
            ];
          });
        },

        // eslint-disable-next-line sonarjs/cognitive-complexity
        getApplicants(action) {
          loanScenarioService
            .scenarioApplicantListGet($scope.loanAppId)
            .then((response) => {
              $scope.scenarioApplicantList = [];
              /**
               * check if the selected client is an entity
               * */
              const applicantsListPerson = _.pickBy(
                response.data.InvolvedPartyPerson,
                (value, key) => {
                  return _.startsWith(
                    key,
                    $scope.AddressHistory.SelectedClient,
                  );
                },
              );
              const applicantsListEntity = _.pickBy(
                response.data.InvolvedPartyEntity,
                (value) => {
                  return _.startsWith(
                    parseInt(value.FamilyId, 10),
                    $scope.AddressHistory.SelectedClient,
                  );
                },
              );

              if (!_.isEmpty(applicantsListPerson)) {
                angular.forEach(response.data.InvolvedPartyPerson, (family) => {
                  let passCtr = 0;
                  angular.forEach(family, (client) => {
                    const clientObj = client;

                    /**
                     * check if the client familyid belongs to the selected client on parent screen
                     * */
                    if (
                      String(clientObj.FamilyId) ===
                      String($scope.AddressHistory.SelectedClient)
                    ) {
                      if (
                        typeof $scope.item.ContactLivingIn !== 'undefined' &&
                        $scope.item.ContactLivingIn.length > 1
                      ) {
                        if (
                          $scope.item.ContactLivingIn.length > 1 &&
                          passCtr <= $scope.item.ContactLivingIn.length
                        ) {
                          clientObj.isTicked =
                            $scope.item.ContactLivingIn[passCtr].BorrowerID ===
                            parseInt(client.PersonId, 10);
                          passCtr++;
                        } else {
                          clientObj.isTicked = true;
                        }
                      } else if (action === 'Add') {
                        clientObj.isTicked = true;
                      } else {
                        clientObj.isTicked =
                          $scope.item.ContactLivingIn[0].BorrowerID ===
                          parseInt(client.PersonId, 10);
                      }
                      clientObj.background = $scope.getBGcolor(
                        clientObj.FamilyId,
                      );
                      $scope.scenarioApplicantList.push(
                        $scope.scenarioApplicantListModel(clientObj),
                      );
                    }
                  });
                });
              } else if (!_.isEmpty(applicantsListEntity)) {
                angular.forEach(response.data.InvolvedPartyEntity, (obj) => {
                  obj.isTicked = true;
                  $scope.scenarioApplicantList.push(
                    $scope.scenarioApplicantListModel(obj, true),
                  );
                });
              }
            });
        },

        countAddressHistoryYears(family) {
          let total = 0;
          return (
            family &&
            family.Address &&
            family.Address.reduce((accum, addr) => {
              total = accum;
              if (
                addr.Type === 'Previous Address' ||
                addr.Type === 'Current Address'
              ) {
                if (isNZ) {
                  total +=
                    addr.NumberOfMonths +
                    addr.NumberOfYears * DATE_COUNT.MONTHS_IN_YEAR;
                } else {
                  const start = moment(addr.StartDate);
                  const end = addr.EndDate ? moment(addr.EndDate) : moment();
                  const diff = end.diff(start, 'months');
                  total += diff;
                }
              }
              return total;
            }, 0)
          );
        },
        /**
         * Prepare Data for Owners Select List
         * */
        scenarioApplicantListModel(clientObj, isEntity) {
          let data;
          if (isEntity) {
            data = {
              Percentage: 100,
              BorrowerID: parseInt(clientObj.OrganisationId, 10),
              ClientID: parseInt(clientObj.PersonId, 10),
              FirstName: clientObj.FamilyFullName,
              ClientType: clientObj.Role,
              LastName: clientObj.LastName,
              PreferedName: clientObj.FamilyFullName,
              IsInclude: true,
              IsTicked: clientObj.isTicked,
              OwnershipPercentage: 100,
              ticked: clientObj.isTicked,
              IsEntity: true,
              IsClient: false,
            };
          } else {
            data = {
              Percentage: 100,
              BorrowerID: parseInt(clientObj.PersonId, 10),
              ClientID: parseInt(clientObj.PersonId, 10),
              FirstName: clientObj.FirstName,
              ClientType: clientObj.Role,
              LastName: clientObj.LastName,
              PreferedName: `${clientObj.FirstName} ${clientObj.LastName}`,
              IsInclude: true,
              IsTicked: clientObj.isTicked,
              OwnershipPercentage: 100,
              ticked: clientObj.isTicked,
              IsEntity: false,
              IsClient: true,
            };
          }

          data.initials = utilitiesService.filterInitialOneString(
            clientObj.FirstName,
          );
          data.background = $scope.getBGcolor(clientObj.PersonId);

          return data;
        },

        UPDATE_ADDRESS: updateAddressValidation({ $scope, loanAppSharedData }),

        isWithRental(id) {
          return _.find(broadcastDataService.addressWithRentalIds, (o) => {
            return o === id;
          });
        },

        listeners() {
          $scope.$watch(
            'loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS',
            (nv) => {
              if (nv && _.size(nv) > 0 && nv.RefreshAddress) {
                $scope.getAddressHistory();
                nv.RefreshAddress = false;
              }
            },
          );
        },

        init() {
          $scope.listeners();
          $scope.getListOfApplicants();
          $scope.getAddressType();
          $scope.getAddressHistory();

          $scope.listRentalFrequency = [];
          optionsService.FinancialFrequency().then((response) => {
            _.map(response.data, (o) => {
              o.Desc = o.Name;
              o.Value = parseInt(o.Value, 10);

              return o;
            });

            $scope.listRentalFrequency = response.data;
          });
        },
      });

      const openAddressModal = (modalType, propertySet) => {
        const props = {
          modalType,
          familyId: +$scope.AddressHistory.SelectedClient,
          propertySet,
          loanAppId: $scope.loanAppId,
        };
        if (addressLookupFn(props)) {
          return;
        }
        $uibModal
          .open({
            template: `<address-history-modal
          modal-instance="vm.modalInstance"
          modal-type="vm.props.modalType"
          family-id="vm.props.familyId"
          property-set="vm.props.propertySet"
          loan-app-id="vm.props.loanAppId"
        ></address-history-modal>`,
            controller: 'CommonModalPlaceholderCtrl',
            controllerAs: 'vm',
            resolve: { props: () => props },
            keyboard: false,
            backdrop: 'static',
            size: 'sm',
          })
          .result.then((modalInstanceAddAddressResponse) => {
            $scope.getAddressHistory();
            $scope.UPDATE_ADDRESS();
            if (
              $scope.validation.AddressHistoryError &&
              $scope.validation.AddressHistoryError.length === 1
            ) {
              $scope.validation.AddressHistoryError = [];
            }
            if (
              modalInstanceAddAddressResponse &&
              modalInstanceAddAddressResponse.FamilyId
            ) {
              $scope.familyIdAsResponse =
                modalInstanceAddAddressResponse.FamilyId;
            }
          });
      };

      $scope.openAddAddressHistory = () => {
        openAddressModal('new');
      };

      $scope.openEditAddressHistory = (propertySet = {}) => {
        propertySet.FullAddress = propertySet.Address;
        openAddressModal('edit', propertySet);
      };

      $scope.init();
    });
