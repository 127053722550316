import { types } from '../../constants/emailMailTemplates';

export default class businessEmailMailHeaderCtrl {
  $onInit() {
    this.types = types;
    this.searchFilter = '';
  }

  handleChangeType(type) {
    this.searchFilter = '';
    this.onChangeType({ type });
  }
}
