import angular from 'angular';
import { processLabelDisplay } from './util/lendingCategoryLabel';

export const loadModule = () =>
  angular
    .module('app')
    .directive('lendingCategoryLabel', function lendingCategoryLabel(
      configService,
      currentUserService,
    ) {
      return {
        link(scope, element) {
          processLabelDisplay({ configService, currentUserService, element });
        },
      };
    });
