export const WORD_COUNT_AU = {
  RED: {
    MAX: 199,
  },
  GREEN: {
    MIN: 200,
  },
  THUMBS_UP: {
    MIN: 500,
  },
};

export const WORD_COUNT_NZ = {
  RED: {
    MAX: 99,
  },
  GREEN: {
    MIN: 100,
  },
  THUMBS_UP: {
    MIN: 200,
  },
};
