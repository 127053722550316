import { SECTION_CODE } from 'Common/constants/validationStatusCodes';

export function validateInnerSubsectionsForUI(innerSection) {
  return (
    (innerSection && {
      name: innerSection.ErrorType ?? '',
      code: `${innerSection.Code}`,
      attributes: {
        errorMessage: innerSection.Attributes.ErrorMessage.Title,
        ids: innerSection.Attributes.Ids,
      },
    }) ||
    false
  );
}

function createSubSection(subSections) {
  var subSectionsObj = {};
  var counter = '0';
  subSections.forEach((section) => {
    subSectionsObj[
      section.ErrorType ?? counter
    ] = validateInnerSubsectionsForUI(section);

    counter = (Number(counter) + 1).toString();
  });
  return subSectionsObj;
}

function getSubSections(sections, code) {
  switch (code) {
    case SECTION_CODE.applicantSections:
      return sections.filter(
        (section) => Number(section.Code) >= 100 && section.Code < 200,
      );
    case SECTION_CODE.fundingSections:
      return sections.filter(
        (section) => Number(section.Code) >= 200 && section.Code < 300,
      );
    case SECTION_CODE.declarationSections:
      return sections.filter(
        (section) => Number(section.Code) >= 300 && section.Code < 400,
      );
    case SECTION_CODE.fatalErrors:
      return sections.filter(
        (section) => Number(section.Code) >= 400 && section.Code < 500,
      );
    case SECTION_CODE.fundingDetailsSections:
      return sections.filter(
        (section) => Number(section.Code) >= 500 && section.Code < 600,
      );
  }
}

export function loanAppSections(
  innerSection,
  sectionName,
  sectionCode,
  uiService = {},
) {
  return {
    sectionName: sectionName, // innerSection.SectionName,
    sectionCode: sectionCode, // innerSection.Code,

    applicantErrorProps:
      sectionCode === SECTION_CODE.applicantSections &&
      createSubSection(
        getSubSections(innerSection, SECTION_CODE.applicantSections),
      ),
    fundingErrorProps:
      sectionCode === SECTION_CODE.fundingSections &&
      createSubSection(
        getSubSections(innerSection, SECTION_CODE.fundingSections),
      ),
    fundingDetailsErrorProps:
      sectionCode === SECTION_CODE.fundingDetailsSections &&
      createSubSection(
        getSubSections(innerSection, SECTION_CODE.fundingDetailsSections),
      ),
    declarationErrorPops:
      sectionCode === SECTION_CODE.declarationSections &&
      createSubSection(
        getSubSections(innerSection, SECTION_CODE.declarationSections),
      ),
    fatalErrors:
      sectionCode === SECTION_CODE.fatalErrors &&
      createSubSection(getSubSections(innerSection, SECTION_CODE.fatalErrors)),
  };
}
function loanSections(innerSection, uiService = {}) {
  var sectionsList = [];
  sectionsList.push(
    loanAppSections(innerSection, 'Applicants', '100', uiService),
  );
  sectionsList.push(
    loanAppSections(innerSection, 'Funding Details', '500', uiService),
  );
  sectionsList.push(
    loanAppSections(innerSection, 'Loan Details', '200', uiService),
  );
  sectionsList.push(
    loanAppSections(innerSection, 'Loan Declarations', '300', uiService),
  );
  sectionsList.push(
    loanAppSections(innerSection, 'Fatal Errors', '400', uiService),
  );
  return sectionsList;
}

export function validateLoanForUI(validations) {
  const { ValidationErrors } = validations;
  return {
    errorCount: validations.ErrorCount,
    sections: ValidationErrors && loanSections(ValidationErrors),
  };
}
