export const saveToProfile = async ({
  insuranceProfilerService,
  profilerId,
  familyId,
}) => {
  await insuranceProfilerService.profilerAssignToClient(familyId, profilerId);
};

export const saveProfilerReportAndPDF = ({
  scope,
  insuranceReportService,
  generatePdf,
  insuranceProfilerService,
}) => async (isConfirm) => {
  if (isConfirm && !scope.familyId) {
    insuranceReportService
      .confirmCreateClientFromProfiler(scope.profilerData)
      .result.then(({ familyId, profilerId }) => {
        if (!familyId) {
          return;
        }
        scope.familyId = familyId;
        scope.profilerId = profilerId;
        generatePdf(isConfirm);
      });
    return false;
  }

  if (isConfirm) {
    await saveToProfile({
      insuranceProfilerService,
      profilerId: scope.profilerData.profilerId,
      familyId: scope.familyId,
    });
  }
  generatePdf(isConfirm, scope.insuranceFileId);
};
