import { YOUR_TARGETS_SETTINGS } from 'Common/constants/widgetComponent';

export default class YourTargetsCtrl {
  constructor(dashboardService) {
    'ngInject';

    this.dashboardService = dashboardService;
    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.widgetMapping = YOUR_TARGETS_SETTINGS;
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
    this.updateView();
  }

  processParentNotification(eventHandler) {
    if (eventHandler === 'initYourTargets') {
      this.updateView();
    }
  }

  updateView() {
    this.toggleShowContent();
    this.getRecommendedWidgets();
  }

  toggleShowContent(showContent) {
    this.showContent = !!showContent;
  }

  getRecommendedWidgets() {
    this.dashboardService.getRecommendedWidgets().then((data) => {
      this.toggleShowContent(true);
      this.recommendedWidgets = data || [];
    });
  }
}
