import angular from 'angular';
import fundingDetailsSection from './fundingDetailsSection';
import scenes from './scenes';
import services from './services';
import components from './components';

export default angular
  .module('scenes.loanAppSections.scenes.fundingDetailsSection', [
    scenes,
    services,
    components,
  ])
  .component('fundingDetailsSection', fundingDetailsSection).name;
