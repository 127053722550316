import template from './loanSectionStatus.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    isCompleted: '<',
    isLodged: '<',
    loanAppFormWarning: '<',
  },
};
