import swal from 'sweetalert';
import { toastSuccess } from 'Common/utilities/alert';
import {
  openAddModal,
  getDeleteModalSettings,
} from './util/tradePersonalReferencesSectionCtrl';

export default class TradePersonalReferencesSectionCtrl {
  constructor($uibModal, loanScenarioModelService, crmConfirmation) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.loanScenarioModelService = loanScenarioModelService;
    this.crmConfirmation = crmConfirmation;
  }

  $onChanges(changes) {
    const { loanAppId } = changes;
    if (loanAppId && loanAppId.currentValue) {
      this.getTradeReference();
    }
  }

  getTradeReference() {
    if (!this.loanAppId) {
      return;
    }
    this.loanScenarioModelService
      .getTradeReference(this.loanAppId)
      .then((data) => {
        this.referenceList = data;
      });
  }

  openAddModal(editData) {
    const props = { ...editData, loanAppId: this.loanAppId };
    const modalInstance = openAddModal(this.$uibModal, props);
    modalInstance.result.then(() => {
      this.getTradeReference();
    });
  }

  openDeleteModal(selected) {
    swal(getDeleteModalSettings(), (confirm) => {
      if (confirm) {
        this.deleteTradeReference(selected);
      }
    });
  }

  deleteTradeReference(selected) {
    this.loanScenarioModelService
      .deleteTradeReference(this.loanAppId, selected.tradeReferenceId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        toastSuccess({
          messages: ['Reference has been been successfully deleted.'],
        });
        this.getTradeReference();
      });
  }
}
