import moment from 'moment';
import {
  PERSON_PARTY_TYPES,
  PROFESSIONAL_PARTY_TYPES,
  PARTY_TYPES_LIST,
  PARTY_TYPES_STRING,
  COMPANY_TYPES,
} from 'Common/constants/partyTypes';
import {
  HAS_LEADBILLING_CATEGORY,
  DEFAULT_LEAD_BILLING,
} from 'Common/constants/enquirySource';
import { DD_MM_YYYY_MOMENT_FORMAT } from 'Common/constants/dateConfigs';
import { KEY_CODE } from 'Common/constants/keyCode';
import { ageToDOBGet } from 'Common/utilities/dob';
import {
  getSpecificPartyField,
  getPhoneNumberByType,
  getEmailByType,
} from 'Common/utilities/contact';

export const getDefaultLeadBillingId = (referralCategoryId) => {
  const invalidData =
    !HAS_LEADBILLING_CATEGORY || !HAS_LEADBILLING_CATEGORY.length;
  if (invalidData) {
    return null;
  }
  const hasLeadBilling = HAS_LEADBILLING_CATEGORY.includes(referralCategoryId);
  return hasLeadBilling ? DEFAULT_LEAD_BILLING : null;
};

export const calculateDependentAge = ($scope) => (dob) => {
  const isDateValid = moment(dob, DD_MM_YYYY_MOMENT_FORMAT, true).isValid();
  if (isDateValid) {
    $scope.Dependants.Age = moment().diff(
      moment(dob, DD_MM_YYYY_MOMENT_FORMAT),
      'years',
    );
  } else {
    $scope.Dependants.Age = 0;
  }
};

export const calculateDependentDOB = ($scope) => ($event, age) => {
  if ($event.keyCode === KEY_CODE.TAB) {
    return;
  }
  $scope.Dependants.DOBTemp = ageToDOBGet(age);
};

export const getPartiesToShow = (partyType) => {
  switch (partyType) {
    case PERSON_PARTY_TYPES.SOLICITOR:
      return PROFESSIONAL_PARTY_TYPES;
    case PERSON_PARTY_TYPES.GUARANTOR:
      return [PERSON_PARTY_TYPES.GUARANTOR];
    default:
      return [];
  }
};

export const showHideSearch = ($scope) => (searchType) => {
  if (typeof $scope !== 'object') {
    return;
  }
  const searchTypes = {
    CompaniesSearch: 'isShowCompaniesSearch',
    ProfessionalSearch: 'isShowProfessionalSearch',
    GuarantorSearch: 'isShowGuarantorSearch',
  };

  $scope.isShowCompaniesSearch = false;
  $scope.isShowProfessionalSearch = false;
  $scope.isShowGuarantorSearch = false;

  const search = searchTypes[searchType];
  $scope[search] = !$scope[search];
};

export const setPartyTypeDetails = (contact) => {
  if (!contact) {
    return;
  }
  const partyTypeInfo =
    getSpecificPartyField(contact) ||
    PARTY_TYPES_LIST.find((type) => type.id === contact.PartyTypeID) ||
    {};
  contact.partyType = partyTypeInfo.id && partyTypeInfo.id.toString();
  contact.clientType = partyTypeInfo.name;
};

export const entityContactHandler = (scope, utilitiesService) => (contact) => {
  contact.initials = utilitiesService.filterInitialOneString(contact.Name);
  const { COMPANY_STRING, TRUST_STRING } = COMPANY_TYPES;
  const isCompanyTrust = [COMPANY_STRING, TRUST_STRING].includes(
    contact.EntityTypeName,
  );

  if (!contact.EntityTypeName || isCompanyTrust) {
    scope.relationshipCompany = [...scope.relationshipCompany, contact];
    return;
  }
  if (contact.EntityTypeName === PARTY_TYPES_STRING.SOLICITOR) {
    const formatContact = {
      ...contact,
      FirstName: contact.Name,
    };
    scope.relationshipPro = [...scope.relationshipPro, formatContact];
  }
};

export const setContactDetails = (contact) => {
  if (!contact) {
    return;
  }
  contact.phone = getPhoneNumberByType(contact.Phone, 'Mobile');
  contact.email = getEmailByType(contact.Email, 'Primary');
};

export const setSearchTimeout = ({ $scope, $timeout }) => (searchString) => {
  if (!$scope || !$timeout) {
    return;
  }
  $scope.searchClientTimeout && $timeout.cancel($scope.searchClientTimeout);
  $scope.searchClientTimeout = $timeout(() => {
    $scope.searchClient(searchString);
  }, 500);
};
