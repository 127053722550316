import {
  OFFSET_FREQUENCY,
  FREQUENCY_COUNT_MONTH,
} from 'Common/constants/dateRange';

export const savedTimeMonth = (periodDifference, frequency) => {
  const frequencyCount = FREQUENCY_COUNT_MONTH[frequency];
  const extraPeriod = periodDifference % OFFSET_FREQUENCY[frequency];

  return Math.round(extraPeriod / frequencyCount);
};

export const savedTimeCalculator = (periodDifference, frequency) => {
  const month = savedTimeMonth(periodDifference, frequency);
  if (periodDifference < OFFSET_FREQUENCY[frequency]) {
    return `${month} Months`;
  }

  const year = parseInt(periodDifference / OFFSET_FREQUENCY[frequency], 10);
  return `${year} Year(s) ${month} Month(s)`;
};
