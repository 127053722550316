import moment from 'moment';
import { pluralize } from './string';

export function ageToDOBGet(age) {
  return `01/01/${parseInt(moment().year(), 10) - parseInt(age, 10)}`;
}

export function checkValidDOB(dateValue, format = 'MM DD YYYY') {
  const newDateValue = moment(dateValue, format);
  const checkDaysDiff = moment().diff(newDateValue, 'days');
  return newDateValue.isValid() && checkDaysDiff >= 0;
}

export function dobToAgeGet(dob, format = 'MM DD YYYY') {
  if (!checkValidDOB(dob, format)) {
    return '';
  }
  const birthDate = moment(dob, format);
  const age = moment().diff(birthDate, 'years');
  return pluralize(age, 'year');
}
