import { get } from 'lodash';
import dotProp from 'dot-prop-immutable';
import { BUSINESS_TYPES } from 'Common/constants/businessTypes';
import {
  HAS_LEADBILLING_CATEGORY,
  DEFAULT_LEAD_BILLING,
  REFERRAL_ITEM,
} from 'Common/constants/enquirySource';
import {
  BUSINESS_UNIT,
  ORG_TYPE,
  ADVISER_CONTACT_PANEL,
} from 'Common/constants/corporate';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';
import { ADDRESS_ERROR } from 'Common/constants/promptMessages';
import { toastError } from 'Common/utilities/alert';
import { dobToAgeGet } from 'Common/utilities/dob';
import {
  DD_MM_YYYY_MOMENT_FORMAT,
  MM_DD_YYYY_MOMENT_FORMAT,
} from 'Common/constants/dateConfigs';
import { ASSISTANT_STATUS_LIST } from 'Common/constants/assistantConstant';
import { isOwnBrand } from 'Common/utilities/brandingCategory';
import { processClientAddress } from './clientAddress';

export const isReferrerOrgFieldsValid = (referrer) => {
  if (!referrer) {
    return false;
  }
  const { legalName, businessTypeId, businessActivities } = referrer;
  const isValidLegalName = legalName && legalName.trim() !== '';
  const isValidBusinessDesc =
    businessTypeId !== BUSINESS_TYPES.OTHERS || businessActivities;
  return !!(isValidLegalName && businessTypeId && isValidBusinessDesc);
};

export const isBusinessTypeOthers = (businessType) => {
  return businessType === BUSINESS_TYPES.OTHERS;
};

export const getDefaultLeadBillingId = (referralCategoryId) => {
  const noLeadBilling = null;
  const invalidData =
    !HAS_LEADBILLING_CATEGORY || !HAS_LEADBILLING_CATEGORY.length;
  if (invalidData) {
    return noLeadBilling;
  }
  const hasLeadBilling = HAS_LEADBILLING_CATEGORY.includes(referralCategoryId);
  return hasLeadBilling ? DEFAULT_LEAD_BILLING : noLeadBilling;
};

export const getCurrentScheme = (countryCode, edrScheme) => {
  switch (countryCode.toLowerCase()) {
    case 'au':
      return edrScheme.au;
    case 'nz':
      return edrScheme.nz;
    default:
      return [{ id: 1, value: 'No Scheme' }];
  }
};

export const getAccountOwnerLabel = (isCountryNZ) => {
  return isCountryNZ ? 'Sales Manager' : 'Account Owner';
};

export const getHasRole = (role) => {
  return (
    role &&
    role
      .map((elem) => {
        return elem.ContactTypeRoleId;
      })
      .join(',') !== ''
  );
};

export const hasValue = (value) => {
  if (value && value.trim() !== '') {
    return true;
  }
  return false;
};

export const onSetBusinessUnit = ({ $scope }) => (businessUnitObj) => {
  const isEMoca =
    businessUnitObj && businessUnitObj.BusinessUnitID === BUSINESS_UNIT.EMOCA;
  if (!isEMoca) {
    return;
  }
  const ownBrandCategory =
    $scope.brandingCategory.adviser &&
    $scope.brandingCategory.adviser.find((category) => {
      return isOwnBrand(category.BrandingCategoryId);
    });
  $scope.adviserMarketing.brandingCategorySelected = ownBrandCategory || {};
};

export const onSetOrgType = ({ $scope }) => (orgTypeObj) => {
  const isMember =
    orgTypeObj && orgTypeObj.AdviserOrgTypeID === ORG_TYPE.MEMBER;
  if (!isMember) {
    return;
  }
  const ownBrandCategory =
    $scope.brandingCategory.adviserOrg &&
    $scope.brandingCategory.adviserOrg.find((category) => {
      return isOwnBrand(category.BrandingCategoryId);
    });
  $scope.adviserOrg.brandingCategorySelected = ownBrandCategory || {};
};

export const isEMocaUnit = ({ businessUnitSelected }) => {
  return (
    businessUnitSelected &&
    businessUnitSelected.BusinessUnitID === BUSINESS_UNIT.EMOCA
  );
};

export const displayTab = ({ $scope }) => (isMobileView, panel) => {
  if (!isMobileView) {
    return true;
  }
  return $scope.currentMobileTab === panel;
};

export const validateAdviserBusinessAddress = ({ $scope }) => {
  $scope.isAdviserBusinessAddressValid = true;
  if ($scope.isOrgAddress) {
    return true;
  }
  $scope.isAdviserBusinessAddressValid = !!dotProp.get(
    $scope.address,
    'businessAddressModelObj.formatted_address',
    false,
  );
  return $scope.isAdviserBusinessAddressValid;
};

export const setContactAgeValue = (obj, format = MM_DD_YYYY_MOMENT_FORMAT) => {
  obj.AgeValue = dobToAgeGet(obj.DOB, format);
};

export const isInputRequired = (statusId, fieldName) => {
  return (
    (ASSISTANT_STATUS_LIST.ACTIVE === statusId &&
      fieldName === 'assistantAdviserOrg') ||
    ((ASSISTANT_STATUS_LIST.PROSPECT === statusId ||
      ASSISTANT_STATUS_LIST.ONBOARDING === statusId) &&
      fieldName === 'assistantEmail')
  );
};

export const initAccessType = ($scope, dataService) => {
  dataService.getAccessType().then((response) => {
    $scope.accessAssistantTypes = response.filter(
      (type) => type.AccessTypeId > 2,
    );
    $scope.accessAdviserTypes = response.filter(
      (type) => type.AccessTypeId < 3,
    );
  });
};

export const onInit = ({ $scope, uiService, dataService }) => {
  $scope.onSetBusinessUnit = onSetBusinessUnit({ $scope });
  $scope.onSetOrgType = onSetOrgType({ $scope });
  $scope.displayTab = displayTab({ $scope });
  $scope.businessUnitConstant = BUSINESS_UNIT;
  $scope.orgTypeConstant = ORG_TYPE;
  $scope.adviserContactPanel = ADVISER_CONTACT_PANEL;
  $scope.isOrgAddress = false;
  $scope.setContactAgeValue = setContactAgeValue;
  $scope.DD_MM_YYYY_MOMENT_FORMAT = DD_MM_YYYY_MOMENT_FORMAT;
  $scope.MM_DD_YYYY_MOMENT_FORMAT = MM_DD_YYYY_MOMENT_FORMAT;
  $scope.isInputRequired = isInputRequired;
  $scope.isInternationalPhoneNumbers = uiService.isInternationalPhoneNumber;
  initAccessType($scope, dataService);
  $scope.businessEntity = {
    countryCode: '',
    selectedCountry: '',
  };
};

export const initBusinessAddressSaving = ({
  $scope,
  generalService,
  addressLookupService,
  isAdviser,
}) => {
  if ($scope.isAddressOverhaulEnabled) {
    processClientAddress({
      $scope,
      generalService,
      addressLookupService,
      contactAddress: $scope.businessAddress,
    })
      .then((address) => {
        $scope.saveBusinessAccount(address, isAdviser);
      })
      .catch(() => {
        toastError(ADDRESS_ERROR.CLIENT);
        $scope.contacts.isSaving = false;
      });
    return;
  }
  const address = [
    {
      formatted_address: $scope.business.Address,
      geoCoded: $scope.geoCodedAddress,
    },
  ];
  $scope.saveBusinessAccount(address, isAdviser);
};

export const initClientAddressSaving = ({
  $scope,
  generalService,
  addressLookupService,
  familyInfo,
  familyId,
}) => {
  if ($scope.isAddressOverhaulEnabled) {
    processClientAddress({
      $scope,
      generalService,
      addressLookupService,
      contactAddress: $scope.clientAddress,
    })
      .then((address) => {
        familyInfo.Addresses = address;
        $scope.saveContactAddress(familyInfo, familyId);
      })
      .catch(() => {
        toastError(ADDRESS_ERROR.CLIENT);
        $scope.contacts.isSaving = false;
      });
    return;
  }
  $scope.saveContactAddress(familyInfo, familyId);
};

export const onAdviserSelect = ({ $scope }) => (data) => {
  if (!$scope || !data) {
    return;
  }
  $scope.client.adviserSelected = data;
  const adviserId = get(data, 'originalObject.familyId');
  $scope.getAssistantsList(adviserId);
};

export const restrictAccessibleEnquirySource = ({
  $scope,
  brandingCategoryId,
}) => {
  if (brandingCategoryId !== BRANDING_CATEGORY_TYPE.OWN_BRAND) {
    $scope.EnquirySourceList = [...$scope.enquirySourceRaw];
    return;
  }
  $scope.EnquirySourceList =
    $scope.EnquirySourceList &&
    $scope.EnquirySourceList.filter(
      (item) => item.ReferralItemId !== REFERRAL_ITEM.RAYWHITE_LOAN_CALCULATOR,
    );
};

export const onAdviserAutocompleteSelect = ({ $scope }) => (data) => {
  const invalidParams = !$scope || !data;
  if ($scope && !data) {
    $scope.EnquirySourceList = [...$scope.enquirySourceRaw];
  }
  if (invalidParams) {
    return;
  }
  if ($scope.client) {
    $scope.client.adviserSelected = data;
    $scope.client.selectedAssistant = {};
  }
  $scope.$broadcast('angucomplete-alt:clearInput', 'allocated-assistant');
  const adviserId = get(data, 'originalObject.familyId');
  $scope.getAssistantsList(adviserId);
  const brandingCategoryId = get(data, 'originalObject.brandingCategoryId', 0);
  restrictAccessibleEnquirySource({ $scope, brandingCategoryId });
};

export const checkBusinessFormValidity = ({ $scope, isAdviser }) => {
  const isBusinessNameValid = !!get($scope, 'business.Name', '');
  const isPhoneNumberValid = get($scope, 'form.business.$valid', true);
  return isBusinessNameValid && isPhoneNumberValid && isAdviser;
};

export const saveClientContact = ({
  $scope,
  contactService,
  saveUtmCampaign,
  contacts,
  contactsNotesService,
}) => {
  return contactService
    .addContact(contacts)
    .then(({ data }) => {
      $scope.contactFamilyInfoGet(data);
      contactsNotesService.setAdviserComment(
        data,
        $scope.contactInfoSetValue.notes || '',
      );
      saveUtmCampaign(data, data, $scope.utmModel);
    })
    .catch((error) => {
      toastError(error.data.Message);
    });
};
