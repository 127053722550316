import { get } from 'lodash';
import { FACT_FIND_TYPE } from 'Common/constants/factFind';
import { ACTION_MENU_ID } from 'Common/constants/deal';
import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';
import { getRecipients } from 'Common/utilities/loanOpportunity';
import { addColorAndInitials, sendHelloBook } from 'Common/utilities/contact';
import { getGoalSetterUrl } from 'Common/utilities/goalSetter';
import { openNotesModal } from 'Common/utilities/notes';
import {
  isAssetFinanceCategory,
  isAssetFinanceFlagOn,
} from 'Common/utilities/lendingCategory';
import {
  alertInfo,
  toastSaveSuccess,
  toastSuccess,
} from 'Common/utilities/alert';
import { BROKER_NOT_SUBSCRIBED_MSG } from 'Common/constants/creditCheck';
import { handoverPackDisableMessage } from 'Common/constants/handoverPack';

const ITEM_DISABLED_CLASS = 'item-disabled';

class FileQuickActionsCtrl {
  constructor(
    $state,
    $window,
    $uibModal,
    uiService,
    colorService,
    configService,
    contactService,
    helloPackService,
    utilitiesService,
    currentUserService,
    onlineFactFindModal,
    handoverPackService,
    contactModelService,
    referralModelService,
    helloBookPreviewService,
    gamePlanGeneratorService,
    opportunityLoanAppService,
    cardRedirectionsService,
    creditCheckSubscriptionService,
    creditCheckService,
    loanAppSharedData,
    loanAppPdfService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$window = $window;
    this.$uibModal = $uibModal;
    this.uiService = uiService;
    this.colorService = colorService;
    this.configService = configService;
    this.contactService = contactService;
    this.helloPackService = helloPackService;
    this.utilitiesService = utilitiesService;
    this.currentUserService = currentUserService;
    this.onlineFactFindModal = onlineFactFindModal;
    this.handoverPackService = handoverPackService;
    this.contactModelService = contactModelService;
    this.referralModelService = referralModelService;
    this.helloBookPreviewService = helloBookPreviewService;
    this.gamePlanGeneratorService = gamePlanGeneratorService;
    this.opportunityLoanAppService = opportunityLoanAppService;
    this.cardRedirectionsService = cardRedirectionsService;
    this.creditCheckSubscriptionService = creditCheckSubscriptionService;
    this.creditCheckService = creditCheckService;
    this.loanAppSharedData = loanAppSharedData;
    this.loanAppPdfService = loanAppPdfService;
  }

  $onInit() {
    this.opportunityId = this.loanAppId;

    this.menuItems = this.getMenuItems({ factFindText: 'Invite to' });
    this.getClientInfo();
    this.helloPackService.handler.setDealHelloPackDisabled = (isDisabled) => {
      this.setDealHelloPackDisabled(isDisabled);
      this.helloPackService.isDealHelloPackDisabled = isDisabled;
    };
  }

  $onDestroy() {}

  $onChanges(changes) {
    const { fileGeneralDetails, familyId, involvedParties } = changes;
    const isGeneralDetailsUpdated = get(fileGeneralDetails, 'currentValue');
    const isFamilyIdUpdated = get(familyId, 'currentValue');
    const isInvolvedPartiesUpdated = get(involvedParties, 'currentValue');

    if (isGeneralDetailsUpdated) {
      this.getSubscriptionPopUp();
    }

    if (isGeneralDetailsUpdated || isFamilyIdUpdated) {
      this.getDesignatedFamilyBrokerInfo();
    }

    if (isInvolvedPartiesUpdated) {
      this.menuItems = this.getMenuItems({ factFindText: 'Invite to' });
    }
  }

  onGenerateLoanAppPdf() {
    alertInfo('Generating your Loan App PDF. This may take up to 30 seconds.');
    this.loanAppId &&
      this.loanAppPdfService.download(this.loanAppId).then(() => {
        toastSuccess('Loan App PDF downloaded successfully!');
      });
  }

  getMenuItems({ factFindText }) {
    const {
      goalSetterFactFindMerge,
      loanAppLikeGamePlan,
    } = this.configService.feature;
    const hideCompleteCreditCheck =
      !(this.currentUserService.isAU && this.uiService.showCreditCheck) ||
      !this.involvedParties?.InvolvedPartyPerson?.length;
    return [
      { text: 'Add note', action: 'onAddNoteSelect' },
      { text: 'Add task', action: 'onAddTaskSelect' },
      { text: 'Send email', action: 'onSendEmailSelect' },
      { isDivider: true },
      {
        id: ACTION_MENU_ID.HELLO_PACK,
        text: `Send hello pack${!loanAppLikeGamePlan ? ' & credit guide' : ''}`,
        action: 'onSendHelloPackSelect',
        class: this.helloPackService.isDealHelloPackDisabled
          ? ITEM_DISABLED_CLASS
          : '',
      },
      {
        id: ACTION_MENU_ID.FACT_FIND,
        text: `${factFindText} online fact find`,
        action: 'onInviteFactFindSelect',
        class: this.helloPackService.isDealHelloPackDisabled
          ? ITEM_DISABLED_CLASS
          : '',
      },
      {
        text: 'Launch goal setter',
        action: 'onLaunchGoalSetterSelect',
        hidden: goalSetterFactFindMerge && this.currentUserService.isNZ,
      },
      { isDivider: loanAppLikeGamePlan },
      {
        text: 'Generate Loan App PDF',
        action: 'onGenerateLoanAppPdf',
        hidden: !loanAppLikeGamePlan || !this.currentUserService.isNZ,
      },
      { text: 'Generate Game Plan PDF', action: 'onGenerateGamePlanValidate' },
      {
        text: 'Generate Handover Pack',
        action: 'onGenerateHandoverPackValidate',
        hidden:
          !this.configService.feature.statementOfAdvice ||
          !this.currentUserService.isNZ ||
          true,
        functionDisabled: () =>
          !this.loanAppSharedData.getValidToGenerateHandover(),
        disabledMessage: handoverPackDisableMessage,
      },
      {
        text: 'Open in apply online',
        action: 'onOpenInApplyOnline',
        hidden: !this.scenarioDetails?.HasBeenSentToNextGen,
      },
      {
        text: 'Complete credit check ',
        action: 'onCompleteCreditCheck',
        hidden: hideCompleteCreditCheck,
        disabled: this.creditCheckSubscriptionService.creditCheck
          .isCreditCheckDisabled,
        disabledMessage: BROKER_NOT_SUBSCRIBED_MSG,
      },
    ];
  }

  getIsFactFindSent(noOfClients) {
    const familyId =
      this.$state.params.familyId || this.$state.params.sourceFamilyId;
    const loanApplicationId =
      this.$state.params.loanAppId || this.$state.params.opportunityId;
    if (!familyId || !loanApplicationId) {
      return;
    }
    this.contactModelService
      .getIsFactFindSent({
        familyId,
        loanApplicationId,
      })
      .then((sentClientsArray) => {
        const factFindText =
          noOfClients === sentClientsArray.length ? 'Review' : 'Invite to';
        this.menuItems = this.getMenuItems({ factFindText });
        this.isFactFindReview = factFindText === FACT_FIND_TYPE.REVIEW;
      });
  }

  getDesignatedFamilyBrokerInfo() {
    if (!this.familyId) {
      return;
    }
    this.contactModelService
      .getFamilyAdviserInfo(this.familyId)
      .then((response) => {
        if (!response) {
          return;
        }
        this.designatedBrokerInfo = response;
        this.adviserInfo = response;
        this.creditCheckService.creditCheckValidation(response.familyId);
      });
  }

  onMenuItemClick({ data }) {
    typeof this[data] === 'function' && this[data]();
  }

  callOpenModal(props) {
    const modalSize = this.configService.feature.noteType ? 'wide' : '';
    this.$uibModal
      .open({
        template: `<opportunity-notes-modal
                  modal-instance="vm.modalInstance"
                  note-obj="vm.props.noteObj"
                  is-add="vm.props.isAdd"
                  referral-id="vm.props.referralId"
                  is-sms-enabled="vm.props.isSmsEnabled">
                </opportunity-notes-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        windowClass: `opportunity-notes-modal ${modalSize}`,
        size: modalSize,
        keyboard: false,
        resolve: {
          props: () => props,
        },
      })
      .result.then(() => {
        this.disableOpenModal = false;
        toastSaveSuccess();
        this.opportunityLoanAppService.executeCallback(
          'refreshOpportunityNotes',
        );
      });
  }

  onAddNoteSelect() {
    openNotesModal({ vm: this });
  }

  onAddTaskSelect() {
    const props = {
      familyId: this.familyId,
      familyList: this.familyList,
      loanId: this.loanId,
      taskId: 0,
      isLinkToOpportunity: false,
      selectedOpportunityId: this.opportunityId,
      opportunityList: [
        {
          opportunityId: this.opportunityId,
          title: this.fileGeneralDetails.title,
        },
      ],
    };

    this.$uibModal
      .open({
        template: `<task-form-modal
        modal-instance="vm.modalInstance"
        family-id="vm.props.familyId"
        family-list="vm.props.familyList"
        loan-id="vm.props.loanId"
        task-id="vm.props.taskId"
        is-link-to-opportunity="vm.props.isLinkToOpportunity"
        selected-opportunity-id="vm.props.selectedOpportunityId"
        opportunity-list="vm.props.opportunityList"
      ></task-form-modal>`,
        windowClass: 'edit-task-window',
        size: 'md',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props: () => props,
        },
      })
      .result.then(() => {
        this.opportunityLoanAppService.executeCallback(
          'refreshOpportunityTasks',
        );
      });
  }

  getRecipientsChoices() {
    const recipients = getRecipients(this.involvedParties);
    return addColorAndInitials({
      utilitiesService: this.utilitiesService,
      colorService: this.colorService,
    })(recipients);
  }

  onSendEmailSelect() {
    const recipientsChoices = this.getRecipientsChoices();
    const props = {
      familyId: this.familyId,
      loanId: this.loanId,
      recipientsChoices,
    };

    this.$uibModal
      .open({
        template: `<opportunity-send-email-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  loan-id="vm.props.loanId"
                  recipients-choices="vm.props.recipientsChoices"
                ></opportunity-send-email-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        windowClass: `opportunity-send-email-modal`,
        size: 'lg',
        resolve: {
          props: () => props,
        },
      })
      .result.then(() => {
        this.opportunityLoanAppService.executeCallback(
          'refreshOpportunityCommunications',
        );
      });
  }

  onSendHelloPackSelect() {
    if (this.helloPackService.isDealHelloPackDisabled) {
      return;
    }
    sendHelloBook({
      vm: this,
      helloBookPreviewService: this.helloBookPreviewService,
      isNewModal: this.uiService.newHelloModalEnabled,
      loanApplicationId: this.opportunityId,
    });
  }

  openSendHelloBook(additionalProps) {
    const props = { ...additionalProps, familyId: this.familyId };
    this.helloBookPreviewService.open(props).then(() => {
      this.opportunityLoanAppService.executeCallback(
        'refreshOpportunityCommunications',
      );
      this.opportunityLoanAppService.executeCallback(
        'refreshOpportunityDetails',
      );
      this.opportunityLoanAppService.executeCallback('refreshPrivacyConsents');
    });
  }

  launchGoalSetterUrlOnNewTab(url) {
    if (!url) {
      return;
    }
    this.$window.open(url, '_blank', 'noopener, noreferrer');
  }

  onLaunchGoalSetterSelect() {
    if (!this.goalSetterUrl) {
      this.contactModelService
        .getFamilyAdviserInfo(this.familyId)
        .then((response) => {
          if (!response.consumerUrl) {
            return;
          }
          const params = {
            url: response.consumerUrl,
            brandingCategoryID: response.brandingCategoryID,
            tradingName: response.tradingName,
            loanAppId: this.loanAppId,
            adviserOrgId: response.userAdviserOrgId,
          };
          this.goalSetterUrl = getGoalSetterUrl(params);
          this.launchGoalSetterUrlOnNewTab(this.goalSetterUrl);
        });
      return;
    }

    this.launchGoalSetterUrlOnNewTab(this.goalSetterUrl);
  }

  onGenerateGamePlanSelect(isValid) {
    if (!isValid) {
      return;
    }
    return this.gamePlanGeneratorService
      .loadCQPData({ loanAppId: this.loanAppId })
      .then((response) => {
        const [cpaCQPResponse, loanDetailsResponse] = response;

        const props = {
          loanAppId: this.loanAppId,
          isEditEmailMessage: false,
          applicants: this.applicants,
          loanAdviserId: this.fileGeneralDetails.adviserId,
          loanDetails: loanDetailsResponse.loanDetails,
          cpaCqpSet: cpaCQPResponse,
          calculatorFundsSummary: loanDetailsResponse.calculatorFundsSummary,
          isAssetFinance:
            isAssetFinanceFlagOn({ configService: this.configService }) &&
            isAssetFinanceCategory({
              currentUserService: this.currentUserService,
              lendingCategoryId:
                loanDetailsResponse.loanDetails.LendingCategoryId,
            }),
          isAppendedOnly: true,
        };

        this.gamePlanGeneratorService.openCQP(props);
      });
  }

  onGenerateGamePlanValidate() {
    this.onTriggerGamePlanGeneration();
  }

  onGenerateHandoverPackValidate() {
    if (!this.familyList || !this.familyList.length) {
      return;
    }
    const familyNames = this.familyList.reduce((accum, family) => {
      return `${accum}${family.FamilyFullName}`;
    }, '');
    this.handoverPackService.download(this.loanAppId, familyNames);
  }

  onOpenInApplyOnline() {
    const cardDetails = { loanID: this.loanId, loanScenarioID: this.loanAppId };
    this.cardRedirectionsService.viewSubmission(null, cardDetails);
  }

  onCompleteCreditCheck() {
    this.creditCheckService.openNewCreditCheckModal({
      designatedBrokerInfo: this.designatedBrokerInfo,
      familyId: this.familyId,
      clientsList: this.involvedParties.InvolvedPartyPerson,
      onModalClose: () => toastSuccess('Successfully sent'),
    });
  }

  getClientInfo() {
    this.onlineFactFindModal
      .getInvolvePartyToReview(this.loanAppId)
      .then((response) => {
        const noOfClients = get(response, 'clients.length');
        const hasClients = !!noOfClients;
        this.isReviewFactFindShow = hasClients;
        this.getIsFactFindSent(noOfClients);
      });
  }

  getSubscriptionPopUp() {
    const params = {
      brokerId: this.fileGeneralDetails.adviserId,
      tickedItemId: TICKABLE_ITEMS.INTERCEPT,
    };
    this.contactModelService.getSubscriptionPopUp(params).then((data) => {
      this.showPopUp = !!data.showPopUp;
    });
  }

  openInterceptModal(type = FACT_FIND_TYPE.GENERAL) {
    const props = {
      familyId: this.fileGeneralDetails.adviserId,
    };
    this.onlineFactFindModal.openInterceptModal(props).then((response) => {
      if (response) {
        this.openFactFind = response;
        this.openGenerateFactFindModal(type);
      }
    });
  }

  openGenerateFactFindModal(type = FACT_FIND_TYPE.GENERAL) {
    const openInterceptModal =
      this.showPopUp &&
      !this.openFactFind &&
      (type === FACT_FIND_TYPE.GENERAL || type === FACT_FIND_TYPE.INVITE);
    if (openInterceptModal) {
      this.openInterceptModal(type);
      return;
    }

    const params = {
      loanId: this.loanAppId,
      type,
      isShowReviewButton: this.isReviewFactFindShow,
    };
    if (this.uiService.newHelloModalEnabled && type !== FACT_FIND_TYPE.REVIEW) {
      this.helloBookPreviewService
        .open({
          familyId:
            this.$state.params.familyId || this.$state.params.sourceFamilyId,
          isForLoanWriters: null,
          isNewModal: true,
          loanApplicationId: this.loanAppId,
          selectedService: '',
          onlyFactFind: true,
        })
        .then(() => {
          this.getClientInfo();
        });
    } else {
      this.onlineFactFindModal.open(params).then((response) => {
        if (!response) {
          return;
        }

        const { factFindSend, factFindType } = response;
        if (factFindSend) {
          this.getClientInfo();
          this.opportunityLoanAppService.executeCallback(
            'refreshScenarioDetails',
          );
        } else if (!factFindSend && factFindType) {
          this.openGenerateFactFindModal(factFindType);
        }
      });
    }
  }

  onInviteFactFindSelect() {
    if (this.helloPackService.isDealHelloPackDisabled) {
      return;
    }
    const type = this.isFactFindReview ? FACT_FIND_TYPE.REVIEW : null;
    this.openGenerateFactFindModal(type);
  }

  setDealHelloPackDisabled(isDisabled = true) {
    const disabledMenus = [ACTION_MENU_ID.HELLO_PACK, ACTION_MENU_ID.FACT_FIND];
    this.menuItems = this.menuItems.map((item) => {
      if (disabledMenus.includes(item.id)) {
        item.class = isDisabled ? 'item-disabled' : '';
      }
      return item;
    });
  }
}

export default FileQuickActionsCtrl;
