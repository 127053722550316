import template from './questionRadioButtons.html';
import './style.scss';
import controller from './questionRadioButtonsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    selectedOption: '=',
    titleValue: '<',
    options: '<',
    onChangeFn: '&',
  },
};
