class LeadGeneratorLinkCtrl {
  openNewTab(printUILink) {
    const tabLink = this.isByobUser ? printUILink.BYOB : printUILink.LM;
    this.onOpenNewTab({ tabLink });
  }

  checkHasDownload(printUILink) {
    return !!(this.isByobUser ? printUILink.BYOB : printUILink.LM);
  }
}

export default LeadGeneratorLinkCtrl;
