export const QUICKQOUTE_ORIGIN = {
  INSURANCE_TABS: 1,
  PROFILER: 2,
};

export const QUOTE_SETTINGS_INDEX = {
  HEALTH: 0,
  TRAUMA: 1,
  IP75: 2,
  IP55: 3,
  MORTGAGE: 4,
  DISCOUNT_OPTIONS: 5,
};

export const VITALITY_OPTIONS = {
  FEE: 0,
  DISCOUNT: 1,
};
