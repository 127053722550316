import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('GoogleAuthCodeCtrl', function GoogleAuthCodeCtrl(
      $scope,
      $window,
    ) {
      function getUrlVars() {
        const vars = [];
        let hash;
        const hashes = $window.location.href
          .slice($window.location.href.indexOf('?') + 1)
          .split('&');
        for (const element of hashes) {
          hash = element.split('=');
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
        }
        return vars;
      }

      function init() {
        $window.opener.calendarauth(getUrlVars().code, 'getcalendar');
        $window.close();
      }

      init();
    });
