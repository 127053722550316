import template from './fundingBreakdownItem.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    value: '<',
    loanAppId: '<',
    onDeleteFund: '&',
    onOpenSecurityModal: '&',
    onSaveFundingParameters: '&',
  },
};
