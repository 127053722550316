import template from './otherExpenseForm.html';
import controller from './otherExpenseFormCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    borrowersDropdown: '<',
    frequencyTypes: '<',
    form: '<',
  },
};
