import angular from 'angular';

export const loadModule = () =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  angular.module('app').filter('propsFilter', function propsFilter() {
    return function (objs, props) {
      let out = [];

      if (angular.isArray(objs)) {
        const keys = Object.keys(props);

        objs.forEach((item) => {
          let itemMatches = false;

          for (const prop of keys) {
            const text = props[prop].toLowerCase();
            if (
              item[prop] &&
              item[prop].toString().toLowerCase().includes(text)
            ) {
              itemMatches = true;
              break;
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = objs;
      }

      return out;
    };
  });
