class OnboardingDetailsCtrl {
  constructor(corporateService, $state, toaster) {
    'ngInject';

    this.corporateService = corporateService;
    this.toaster = toaster;
    this.$state = $state;
    this.onboardingDetails = {
      onboardingStatus: '',
      mothersMaidenName: '',
      summary: '',
    };
    this.onboardingStatusList = [];
  }

  setOnboardingStatusList({ data }) {
    if (!data) {
      return;
    }
    this.onboardingStatusList = data.map((item) => ({
      label: item && item.RecruitmentOnboardingStatus,
      value: item && item.RecruitmentOnboardingStatusID,
    }));
  }

  setOnboardingDetails({ data }) {
    this.onboardingDetails = {
      ...data,
      onboardingStatus: data && data.OnboardingStatusId,
      mothersMaidenName: data && data.MothersMaidenName,
      summary: data && data.Description,
    };
  }

  mapOnboardingDetails() {
    const data = this.onboardingDetails;
    return {
      ...data,
      Description: data.summary,
      MothersMaidenName: data.mothersMaidenName,
      OnboardingStatusId: data.onboardingStatus,
    };
  }

  $onInit() {
    this.getOnboardingStatusList();
    this.getOnboardingDetails();
  }

  getOnboardingStatusList() {
    this.corporateService
      .recruitmentOnboardingStatusListGet()
      .then(this.setOnboardingStatusList.bind(this));
  }

  getOnboardingDetails() {
    const { params } = this.$state;
    if (!params) {
      return;
    }
    this.corporateService
      .familyRecruitmentDetailsGet(params.familyId, params.clientId)
      .then(this.setOnboardingDetails.bind(this));
  }

  updateOnboardingDetails() {
    const { params } = this.$state;
    if (!params) {
      return;
    }
    this.corporateService
      .familyRecruitmentDetailsSet(
        params.familyId,
        params.clientId,
        this.mapOnboardingDetails(),
      )
      .then(() =>
        this.toaster.pop('success', 'Update', 'successfully updated!'),
      );
  }
}

export default OnboardingDetailsCtrl;
