(function () {
  app.service('loanInformationService', loanInformationService);

  function loanInformationService() {
    var service = {
      commissionEnquiryIsOpen: false,
      openCommissionEnquiry,
    };

    return service;

    // ///////////////////
    /*
     * @name openCommissionEnquiry
     * @desc
     * @param commissionType
     * @return
     */
    function openCommissionEnquiry(commissionType, loanId) {
      service.commissionEnquiryIsOpen = !service.commissionEnquiryIsOpen;
    }
  }
})();
