import template from './mortgageItem.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    mortgage: '<',
    countryCode: '<',
    onUpdateSecurity: '&',
    onGetMaxValue: '&',
  },
};
