import { ANSWER_TYPE } from 'Common/constants/questionAnswerType';
import { APPLICATION_DATE_TYPE } from 'Common/constants/customerObjectives';
import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { BROKER_NOTES_ID } from 'Common/constants/loanAppBrokerNotes';
import { flatMap, uniqBy } from 'lodash';
import { toastSuccess } from 'Common/utilities/alert';
import { removeDecimals } from 'Common/utilities/currency';
import { convertUtcToUserDate } from 'Common/utilities/date';

export const saveBrokerNotesUtil = (params, dependencies) => {
  const { loanAppId, subsect, content } = params;
  const { loanScenarioService, toaster } = dependencies;
  if (!loanAppId || !loanScenarioService || !toaster) {
    return;
  }
  loanScenarioService.brokerNotesSet(loanAppId, subsect, content).then(() => {
    toaster.pop('info', 'Note in this section was updated.');
  });
};
export const saveBrokerNotesInTemplateUtil = (params) => {
  const { scope, services, brokerNotes } = params;

  if (!scope || !services || !brokerNotes) {
    return;
  }
  const { loanScenarioService, toaster } = services;
  const brokerNotesParams = {
    loanAppId: scope.loanAppId,
    subsect: BROKER_NOTES_ID.BROKER_DECLARATION,
    content: brokerNotes,
  };
  const dependencies = {
    loanScenarioService,
    toaster,
  };
  return saveBrokerNotesUtil(brokerNotesParams, dependencies);
};

export const getBrokerNotesUtil = (params) => {
  const { loanScenarioModelService, loanAppId, assignBrokerNotes } = params;
  if (!loanScenarioModelService || !loanAppId || !assignBrokerNotes) {
    return;
  }

  loanScenarioModelService.getBrokerNotes(loanAppId).then((response) => {
    if (!response) {
      return;
    }
    assignBrokerNotes(response);
  });
};

export const noteListInitUtil = (params) => {
  const { scope, loanScenarioModelService, assignBrokerNotes } = params;
  if (!scope || !loanScenarioModelService || !assignBrokerNotes) {
    return;
  }

  const args = {
    loanAppId: scope.loanAppId,
    loanScenarioModelService,
    assignBrokerNotes,
  };
  getBrokerNotesUtil(args);
};

export const getSpecificBrokerNotes = (brokerNotes, brokerNoteId) => {
  if (!brokerNotes || !brokerNoteId) {
    return;
  }
  const note = brokerNotes.find(
    (noteBroker) => noteBroker.subsectionId === brokerNoteId,
  );

  return (note && note.brokerNotes) || '';
};

export function getBorrowerFullName(borrower, isGuarantor) {
  if (!borrower || !borrower.length || typeof isGuarantor === 'undefined') {
    return '';
  }
  return borrower
    .reduce((accum, item) => {
      if (
        (!isGuarantor && !item.IsGuarantor) ||
        (isGuarantor && item.IsGuarantor === isGuarantor)
      ) {
        accum.push(`${item.FirstName} ${item.LastName}`);
      }
      return accum;
    }, [])
    .join(', ');
}

export function getAnswer(answersList, question) {
  const answer = answersList.find(
    (ans) => ans.questionId === question.QUESTION_ID,
  );
  if (!answer || !answer.displayOptionId) {
    return;
  }

  switch (question.DISPLAY_OPTION.ANSWER_TYPE) {
    case ANSWER_TYPE.BOOLEAN:
      return answer.answerBool;
    case ANSWER_TYPE.STRING:
      return answer.answerString;
    case ANSWER_TYPE.INTEGER:
      return answer.answerInt;
    default:
      break;
  }
}

export function getAnswerObjToSave(question, answer) {
  if (!question) {
    return;
  }
  const answerObj = {
    questionId: question.QUESTION_ID,
    displayOptionId: question.DISPLAY_OPTION.ID,
    questionSectionId: 0,
  };

  switch (question.DISPLAY_OPTION.ANSWER_TYPE) {
    case ANSWER_TYPE.BOOLEAN:
      answerObj.answerBool = answer;
      break;
    case ANSWER_TYPE.STRING:
      answerObj.answerString = answer;
      break;
    case ANSWER_TYPE.INTEGER:
      answerObj.answerInt = answer;
      break;
    default:
      break;
  }

  return answerObj;
}

export function getApplicationStatus(pipelineService) {
  return pipelineService.SettingsStatusGetFiltered().then(({ data }) => {
    if (!data) {
      return [];
    }
    const statusList = collectionCamelizeBuilderForUI(data);
    return statusList.reduce((accum, status) => {
      if (status.typeName !== 'Application') {
        return accum;
      }
      return accum.concat(status);
    }, []);
  });
}

export function getApplicationDateType(dateType) {
  switch (dateType) {
    case APPLICATION_DATE_TYPE.SETTLEMENT_DATE:
      return {
        key: 'settlementDate',
        name: 'Estimated Settlement Date',
        value: 12,
        order: 1,
      };
    case APPLICATION_DATE_TYPE.FINANCE_DATE:
      return {
        key: 'financeDate',
        name: 'Finance Date',
        value: 2,
        order: 2,
      };
    case APPLICATION_DATE_TYPE.AUCTION_DATE:
      return {
        key: 'auctionDate',
        name: 'Auction Date',
        value: 10,
        order: 3,
      };
    default:
      return null;
  }
}

export function showAssetInsuranceSection({
  currentUserService,
  configService,
  lendingCategoryId,
}) {
  const { assetFinance } = configService.feature;
  const displayAssetInsuranceFor = [
    LENDING_CATEGORY.ASSET_FINANCE,
    LENDING_CATEGORY.PERSONAL_LOAN,
  ];
  const countryAccess = currentUserService.isAU || currentUserService.isNZ;
  return (
    assetFinance &&
    countryAccess &&
    displayAssetInsuranceFor.includes(lendingCategoryId)
  );
}

export function isValidAdverse({ $scope }) {
  const shouldValidate =
    $scope.displayPossibleAdverseChanges && !$scope.showAssetInsuranceSection;
  const { isPossibleAdverseCompleted: isComplete } = $scope.loanAppSharedData;
  return shouldValidate ? isComplete : true;
}

export function isChangeToNextGen({ $scope }) {
  const { isAU } = $scope.currentUserService;
  const { isAssetFinanceLoan } = $scope.loanAppSharedData;
  const isValid = isAU && $scope.LenderSubmissionSet && !isAssetFinanceLoan;
  return !!isValid;
}

export function isAdviserEmailAddress({ $scope }) {
  const { isNZ } = $scope.currentUserService;
  const { AdviserEmailAddress } = $scope.LenderSubmissionSet;
  const { isAssetFinanceLoan } = $scope.loanAppSharedData;
  const isValid = !AdviserEmailAddress && (isNZ || isAssetFinanceLoan);
  return !!isValid;
}

export function generateFamilyList(involvedPartyPerson) {
  const flatten = flatMap(involvedPartyPerson);

  return uniqBy(flatten, 'FamilyId').reduce((accum, curr) => {
    if (!curr || !Object.keys(curr).length) {
      return accum;
    }

    const { FamilyFullName: familyFullName, FamilyId: familyId } = curr;
    return accum.concat({ familyFullName: familyFullName.trim(), familyId });
  }, []);
}

export function generateBorrowerList(involvedPartyPerson) {
  const flatten = flatMap(involvedPartyPerson);

  return uniqBy(flatten, 'PersonId').reduce((accum, curr) => {
    if (!curr || !Object.keys(curr).length) {
      return accum;
    }
    if (curr.IsApplicant) {
      const {
        FirstName: firstName,
        FamilyId: familyId,
        PersonId: personId,
      } = curr;
      return accum.concat({
        firstName: firstName.trim(),
        familyId: Number(familyId),
        personId: Number(personId),
      });
    }
    return accum;
  }, []);
}

export const setLoanAppAssistant = ({
  $state,
  corporateService,
  loanScenarioService,
  dummyScenario,
}) => (loanScenarioId) => {
  const invalidParams = !$state || !corporateService || !loanScenarioService;
  if (invalidParams || !loanScenarioId) {
    return;
  }
  const familyId = dummyScenario && dummyScenario.FamilyId;
  const adviserId = dummyScenario && dummyScenario.AdviserId;
  if (!familyId || !adviserId) {
    return;
  }
  corporateService
    .assignAssistantToClientGet(adviserId, familyId)
    .then(({ data }) => {
      if (!data || !data.length) {
        return;
      }
      const { AssistantFamilyID: assistantFamilyId } = data[0];
      if (!assistantFamilyId) {
        return;
      }
      return loanScenarioService.assignAssistant(loanScenarioId, {
        assistantFamilyId,
      });
    })
    .then(() => {
      toastSuccess('Loan application successfully created');
      $state.go('app.loanApplicationDetailsV3', {
        familyId,
        loanAppId: loanScenarioId,
      });
    });
};

export function isSaveSectionCompletionStatus({
  factory,
  sectionID,
  postData,
  isCompleted,
}) {
  const sectionStatus = factory.sectionsCompletionStatus[sectionID];
  if (!sectionStatus) {
    factory.sectionsCompletionStatus[sectionID] = postData;
  }

  if (sectionStatus) {
    const { isCompleted: oldIsCompleted } = sectionStatus;
    if (
      typeof oldIsCompleted !== 'undefined' &&
      isCompleted === oldIsCompleted
    ) {
      return false;
    }

    factory.sectionsCompletionStatus[sectionID] = postData;
  }

  return true;
}

export const getLoanScenarioSimplifiedListFormattedValue = ({
  $filter,
  data,
  key,
}) => {
  const value = data[key];
  if (!value) {
    return '';
  }
  switch (key) {
    case 'LoanAmount':
      return removeDecimals(value);
    case 'LastModifiedDate':
      return `last updated ${$filter('amTimeAgo')(
        convertUtcToUserDate(value),
      )}`;
    default:
      return value.trim();
  }
};

export const loanScenarioSimplifiedListLabelFormatter = ({ $filter, data }) => {
  const fields = [
    'LoanScenarioTitle',
    'LoanStatusName',
    'LoanCategory',
    'LoanAmount',
    'LenderName',
    'LastModifiedDate',
  ];
  return fields.reduce((accum, key, index) => {
    const value = getLoanScenarioSimplifiedListFormattedValue({
      $filter,
      data,
      key,
    });
    if (!value) {
      return accum;
    }
    if (!index) {
      return value;
    }
    return `${accum}, ${value}`;
  }, '');
};
