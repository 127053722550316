export default class AdviserProductsCtrl {
  constructor($window, accreditationService, accreditationUtilityService) {
    'ngInject';

    this.$window = $window;
    this.accreditationService = accreditationService;
    this.accreditationUtilityService = accreditationUtilityService;
    this.processLenderSearch = this.processLenderSearch.bind(this);
  }

  $onInit() {
    this.insurance = [];
    this.lending = [];
    this.products = {};
    this.isLoading = true;
    this.onPopulateProducts();
    this.onSearchLenderFromParent({
      handler: this.processLenderSearch,
    });
  }

  processLenderSearch({ payload }) {
    this.isLoading = true;
    this.familyId = payload;
    this.onPopulateProducts();
  }

  onPopulateProducts() {
    this.accreditationService.getProducts(this.familyId).then(({ data }) => {
      const { insurance, lending } = data;
      this.products = data;
      this.insurance = this.accreditationUtilityService.setCreateObjectEntity(
        insurance,
      );
      this.lending = this.accreditationUtilityService.setCreateObjectEntity(
        lending,
      );
      this.isLoading = false;
    });
  }
}
