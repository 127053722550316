import { MONTHS } from 'Common/constants/months';

const DEFAULT_LAST_DAY = 30;

export function getDateSelection(currentMonth, currentDay, currentYear) {
  const selectedDate = `${currentMonth}/${currentDay}/${currentYear}`;
  const currentDate = new Date(selectedDate);
  const calendarTitle = `${
    [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ][currentMonth - 1]
  } ${currentYear}`;
  const yearSelectionValue = currentYear;

  return {
    selectedDate,
    currentDate,
    calendarTitle,
    yearSelectionValue,
  };
}

export function getCurrentDateParts(currentDate) {
  return (
    currentDate && {
      currentYear: currentDate.getFullYear(),
      currentMonth: currentDate.getMonth() + 1,
      currentDay: currentDate.getDate(),
    }
  );
}

export function getMonths() {
  return [
    { Name: 'January', Value: 1 },
    { Name: 'February', Value: 2 },
    { Name: 'March', Value: 3 },
    { Name: 'April', Value: 4 },
    { Name: 'May', Value: 5 },
    { Name: 'June', Value: 6 },
    { Name: 'July', Value: 7 },
    { Name: 'August', Value: 8 },
    { Name: 'September', Value: 9 },
    { Name: 'October', Value: 10 },
    { Name: 'November', Value: 11 },
    { Name: 'December', Value: 12 },
  ];
}

export function getYears() {
  const currentYear = new Date().getFullYear();
  const years = [];
  let startYear = 1970;

  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  years.reverse();
  return years;
}

export const getDays = (args = {}) => {
  const today = new Date();
  const month = args.month || today.getMonth() + 1;
  const year = args.year || today.getFullYear();
  const lastDay = new Date(year, month, 0).getDate() || DEFAULT_LAST_DAY;
  return Array.from({ length: lastDay }, (value, index) => index + 1);
};

export const addNextYearGivenMonth = (
  yearsList = [],
  startMonth = MONTHS.DECEMBER,
) => {
  const current = new Date();
  const currentMonth = current.getMonth() + 1;
  const currentYear = current.getFullYear();
  const addNextYear = currentMonth >= startMonth;
  return addNextYear ? [currentYear + 1, ...yearsList] : yearsList;
};
