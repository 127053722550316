import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('LoanCommissionEnquiryCtrl', function LoanCommissionEnquiryCtrl(
      loanInformationService,
    ) {
      const vm = this;

      vm.toggleCommissionEnquiry = (commissionType) => {
        loanInformationService.openCommissionEnquiry(commissionType);
      };
    });
