import template from './submitOnlineButton.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    onSubmitLenderSubmission: '&',
    isProcessingValidation: '<',
    dateTested: '<',
    lenderId: '<',
    lixiSummary: '<',
  },
};
