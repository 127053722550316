import { toastError } from 'Common/utilities/alert';
import { personToSetContactModel } from 'Common/utilities/insurance';

const SAVE_MODAL_TYPE = 'save';

class ProfilerToContactCtrl {
  constructor(
    $timeout,
    $window,
    $state,
    $uibModalInstance,
    $uibModal,
    insuranceProfilerService,
    contactService,
    toaster,
    profilerData,
    type,
    configService,
    insuranceFileService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$window = $window;
    this.$state = $state;
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModal = $uibModal;
    this.insuranceProfilerService = insuranceProfilerService;
    this.contactService = contactService;
    this.toaster = toaster;
    this.profilerData = profilerData;
    this.type = type;
    this.configService = configService;
    this.insuranceFileService = insuranceFileService;

    this.searchContactText = '';
    this.selectedFamily = {};
    this.getFamilyList = [];
    this.searchContactText = '';
    this.selectedFamily = {};
    this.showContactList = false;
    this.allocatedAdviserId = '';
    this.insuranceFileId = '';
    this.insuranceFile = {};
  }

  $onInit() {
    const family = this.profilerData?.familyInfo?.[0] || null;
    if (family) {
      this.profilerData.familyFullName = `${family.FirstName} ${family.LastName}`;
    }
    this.insuranceFileId =
      parseInt(this.$state.params.insuranceFileId, 10) || 0;
    if (!this.insuranceFileId) {
      this.getInsuranceFileForProfiler(this.profilerData.profilerId);
    }
    this.displayInsuranceFiles =
      this.type === SAVE_MODAL_TYPE &&
      this.configService.feature.newPipelineInsurance &&
      this.profilerData.familyId;
  }

  onSelectFile({ fileId }) {
    this.insuranceFileId = fileId;
  }

  cancel() {
    this.$uibModalInstance.dismiss('cancel');
  }

  close(response = 'close') {
    this.$uibModalInstance.close(response);
  }

  profilerAssignToClient(familyId, profilerId) {
    this.insuranceProfilerService
      .profilerAssignToClient(familyId, profilerId, this.insuranceFileId)
      .then((response) => {
        if (response.status === 200) {
          this.toaster.pop(
            'success',
            'Success',
            'Profiler successfully added to contact.',
          );
        } else {
          this.toaster.pop(
            'error',
            'Error',
            'Profiler failed to add in contact',
          );
        }

        if (this.isInsuranceLinkingRequired()) {
          this.insuranceProfilerSet(profilerId, this.insuranceFileId);
        }

        this.close({ familyId, profilerId });
        if (this.type !== 'saveToClientDocs') {
          this.$state.go('app.insuranceProfilerDetails', {
            insProfilerId: profilerId,
            familyId,
            insuranceFileId: this.$state.params.insuranceFileId || null,
          });
        }
      });
  }

  insuranceProfilerSet(profilerId, insFileId) {
    if (profilerId && insFileId) {
      this.insuranceFileService.linkProfilerToInsuranceFile(
        insFileId,
        profilerId,
      );
    }
  }

  getInsuranceFileForProfiler(profilerId) {
    if (profilerId) {
      // Retrieve the InsuranceFile if the profiler is already linked
      this.insuranceFileService
        .getInsuranceFileForProfiler(profilerId)
        .then((insuranceFile) => {
          this.insuranceFile = insuranceFile;
          this.insuranceFileId = insuranceFile.id;
          this.onSelectFile({ fileId: insuranceFile.id });
        });
    }
  }

  isInsuranceLinkingRequired() {
    if (this.profilerData?.profilerId) {
      return this.displayInsuranceFiles;
    }
    return this.insuranceFileId !== this.insuranceFile?.insuranceFileId;
  }

  getAdviserData(adviserData) {
    const familyMember = this.profilerData.familyInfo.map((member) => {
      member.allocatedAdviserId = parseInt(adviserData, 10);
      return member;
    });
    this.profilerData.familyInfo = familyMember;
    this.allocatedAdviserId = parseInt(adviserData, 10);
  }

  selectInsuranceFile(insuranceFileId) {
    this.insuranceFileId = insuranceFileId;
  }

  saveProfiler() {
    if (!this.allocatedAdviserId && !this.profilerData.familyId) {
      toastError('Allocated Adviser is Required');
      return;
    }
    if (!this.profilerData.familyId) {
      const familyMember = this.profilerData.familyInfo.map((member) =>
        personToSetContactModel(member),
      );
      this.contactService.addContact(familyMember).then((response) => {
        if (response.data) {
          this.profilerData.familyId = response.data;
          this.profilerAssignToClient(
            response.data,
            this.profilerData.profilerId,
          );
        }
      });
    } else {
      this.profilerAssignToClient(
        this.profilerData.familyId,
        this.profilerData.profilerId,
      );
    }
  }
}

export default ProfilerToContactCtrl;
