import angular from 'angular';

export default angular
  .module('app.services.mathService', [])
  .factory('mathService', function mathService() {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const addNumbers = (...numbers) =>
      numbers.reduce((accum, val) => accum + val, 0);

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const plusTwo = (num) => num + 2;

    return {
      addNumbers,
      plusTwo,
    };
  }).name;
