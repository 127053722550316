import { pipelineLabelBuilder } from 'Common/utilities/pipelineLabels';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import moment from 'moment';

export default class PipelineTableLeads {
  constructor(services) {
    const { pipelineService, contactService } = services;

    this.getHeaders = () => {
      return contactService.getUserInfo().then(({ data }) => {
        if (!data) {
          return [];
        }
        const { AccessType } = data;
        const isCorporateUser = AccessType === ACCESS_TYPE.CORPORATE;
        const headerSettings = { isHidden: 0, isSortable: 1 };

        return [
          {
            offlineSortObjKey: 'clientName',
            isExclusionIncluded: false,
            dataSortObjectExcludedKey: '',
            dataSortObjectKey: 'ClientName',
            label: 'Client Name',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'dateCreated',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'CreateDate',
            dataSortObjectKey: 'CreateDate',
            label: 'Date Created',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'postCode',
            isExclusionIncluded: isCorporateUser,
            dataSortObjectExcludedKey: 'PostCode',
            dataSortObjectKey: 'PostCode',
            label: 'Post Code',
            ...headerSettings,
            isHidden: !isCorporateUser,
          },
          {
            offlineSortObjKey: 'noAutoAllocationReason',
            isExclusionIncluded: isCorporateUser,
            dataSortObjectExcludedKey: 'NoAutoAllocationReason',
            dataSortObjectKey: 'NoAutoAllocationReason',
            label: 'No Auto Allocation Reason',
            ...headerSettings,
            isHidden: !isCorporateUser,
          },
          {
            offlineSortObjKey: 'probability',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'Probability',
            dataSortObjectKey: 'Probability',
            label: 'Probability',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'stage',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'PipelineStatus',
            dataSortObjectKey: 'Stage',
            label: 'Stage',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'enquirySource',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'EnquirySource',
            dataSortObjectKey: 'EnquirySource',
            label: 'Enquiry Source',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'adviserName',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'AdvisorName',
            dataSortObjectKey: 'AdvisorName',
            label: 'Adviser',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'referrerName',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'Referrer',
            dataSortObjectKey: 'ReferrerName',
            label: 'Referrer',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'subStatusName',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'Substatus',
            dataSortObjectKey: 'SubStatusName',
            label: 'Substatus',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'lastUpdated',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'SubStatusUpdated',
            dataSortObjectKey: 'StatusUpdated',
            label: 'Status Updated',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'labelsList',
            isExclusionIncluded: !isCorporateUser,
            dataSortObjectExcludedKey: 'Label',
            dataSortObjectKey: 'Labels',
            label: 'Labels',
            ...headerSettings,
            isHidden: isCorporateUser,
          },
          { label: 'PipelineCardsId', isHidden: 1 },
          { label: 'ClientFamilyId', isHidden: 1 },
          { label: 'pipelineStatusId', isHidden: 1 },
          { label: 'isopenSort', isHidden: 1 },
          { label: 'adviserProfilePicture', isHidden: 1 },
          { label: 'lastModifiedProfilePicture', isHidden: 1 },
          { label: 'subStatusId', isHidden: 1 },
          { label: 'lastModifier', isHidden: 1 },
          { label: 'adviserId', isHidden: 1 },
        ];
      });
    };

    this.getTableData = (pageNumber, pageSize, searchClientName) => {
      return pipelineService
        .getPipelineLeadsTableView({ pageNumber, pageSize, searchClientName })
        .then((response) => {
          if (!response || !response.length) {
            return { totalRecords: 0, tableData: 0 };
          }

          const { totalRecords } = response[0];
          const tableData = response.reduce((accum, current) => {
            const {
              clientName,
              dateCreated,
              postCode,
              noAutoAllocationReason,
              probability,
              pipelineStatus,
              enquirySource,
              adviserName,
              referrerName,
              subStatusName,
              lastUpdated,
              labels,
              pipelineCardsId,
              clientFamilyId,
              adviserProfilePicture,
              lastModifiedProfilePicture,
              subStatusId,
              lastModifier,
              adviserId,
            } = current;
            if (!pipelineCardsId) {
              return accum;
            }
            return accum.concat({
              clientName,
              dateCreated: dateCreated
                ? moment(new Date(dateCreated)).format('DD MMM YYYY')
                : '',
              postCode,
              noAutoAllocationReason,
              probability,
              pipelineStatus,
              enquirySource,
              adviserName,
              referrerName,
              subStatusName,
              lastUpdated: lastUpdated
                ? moment(new Date(lastUpdated)).format('DD MMM YYYY hh:mm:ss A')
                : '',
              labelsList: pipelineLabelBuilder(labels),
              pipelineCardsId,
              clientFamilyId,
              adviserProfilePicture,
              lastModifiedProfilePicture,
              subStatusId,
              lastModifier,
              adviserId,
            });
          }, []);

          return { totalRecords, tableData };
        });
    };
  }
}
