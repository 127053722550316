import angular from 'angular';

class BrokerAllocationToolModalCtrl {
  constructor($uibModalInstance, postalCode, showAllocationButton) {
    this.$uibModalInstance = $uibModalInstance;
    this.postalCode = postalCode;
    this.showAllocationButton = showAllocationButton;
  }
}
export const loadModule = () =>
  angular
    .module('app')
    .controller('BrokerAllocationToolModalCtrl', BrokerAllocationToolModalCtrl);
