export function getTextCharacterLimit(
  baseCharacterLimit,
  currentCharacterCount,
) {
  const isExactCharacterLimit =
    currentCharacterCount % baseCharacterLimit === 0 &&
    currentCharacterCount > 0;
  const limitMultiplier =
    Math.floor(currentCharacterCount / baseCharacterLimit) +
    (isExactCharacterLimit ? 0 : 1);
  return baseCharacterLimit * limitMultiplier;
}

export const baseCharacterLimit = 140;
