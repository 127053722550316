export const MOBILE = {
  NZ: '9999 999 99?9?9',
  AU: '9999 999 999',
};

export const PHONE = {
  NZ: '99 99999?9?9?9?9',
  AU: '99 9999 9999',
  ADVISER_ORG_PATTERN: '^((\\s*)\\S){0,25}\\s?$',
};

export const PHONE_TYPE = {
  MOBILE: 'Mobile',
};
