export const loadModule = () =>
  app.factory('financeChartFactory', [
    '$q',
    function ($q) {
      return {
        financialTypeList() {
          var types = [
            {
              assets_liabilities: [
                /*
                 * Asset Types
                 * */

                {
                  type: 'Art',
                  color: '#614a9c',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-art.svg',
                },
                {
                  type: 'Business',
                  color: '#6d4dbc',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-business.svg',
                },
                {
                  type: 'Cash/Deposits',
                  color: '#9e81dd',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-deposit.svg',
                },
                {
                  type: 'Family Loan being Repaid',
                  color: '#9278c2',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-repaid-loan.svg',
                },
                {
                  type: 'Life Insurance',
                  color: '#5b458d',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-home-insurance.svg',
                },
                {
                  type: 'Shares',
                  color: '#305292',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-shares.svg',
                },
                {
                  type: 'Total (Company Trust Only)',
                  color: '#4760a0',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-total.svg',
                },
                {
                  type: 'Boat',
                  color: '#6d89bb',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-boat.svg',
                },
                {
                  type: 'Car(s)',
                  color: '#739cd2',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-car.svg',
                },
                {
                  type: 'Deposit Paid',
                  color: '#87b8f2',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-deposit.svg',
                },
                {
                  type: 'Furniture',
                  color: '#4d4479',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-furniture.svg',
                },
                {
                  type: 'Other',
                  color: '#6971ba',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-other.svg',
                },
                {
                  type: 'Superannuation',
                  color: '#7486d2',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-superannuation.svg',
                },
                {
                  type: 'Bonds',
                  color: '#a4a5c4',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-bonds.svg',
                },
                {
                  type: 'Caravan',
                  color: '#89a0ee',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-caravan.svg',
                },
                {
                  type: 'Effects/Contents',
                  color: '#27467f',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-contents.svg',
                },
                {
                  type: 'Jewellery',
                  color: '#1d5dbf',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-jewelry.svg',
                },
                {
                  type: 'Real Estate',
                  color: '#2378ec',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-real-estate.svg',
                },
                {
                  type: 'Time Share',
                  color: '#619fea',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-time-share.svg',
                },

                /*
                 * Liability Types
                 * */

                {
                  type: 'Card - American Express / MC / Visa / Diners / Other',
                  color: '#95add3',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-cc.svg',
                },
                {
                  type: 'Loan - Other Loan',
                  color: '#08b0b9',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-loan.svg',
                },
                {
                  type: 'Loan - Term Loan',
                  color: '#37c2c7',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-loan.svg',
                },
                {
                  type: 'Other - Hire Purchase',
                  color: '#5fd0d8',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-other.svg',
                },
                {
                  type: 'Other - Monthly Rent Payments',
                  color: '#68cedc',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-other.svg',
                },
                {
                  type: 'Other - Total (Company / Trust Only)',
                  color: '#a3c7a1',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-other.svg',
                },
                {
                  type: 'Card - Bank Card',
                  color: '#f5657c',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-cc.svg',
                },
                {
                  type: 'Card - Other Card (limit)',
                  color: '#ef646b',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-cc.svg',
                },
                {
                  type: 'Loan - HECS Payment',
                  color: '#ec84b9',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-loan.svg',
                },
                {
                  type: 'Loan - Personal Loan',
                  color: '#e98c96',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-loan.svg',
                },
                {
                  type: 'Other - Lease',
                  color: '#fbbd66',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-other.svg',
                },
                {
                  type: 'Other - Outstanding Taxation',
                  color: '#614a9c',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-other.svg',
                },
                {
                  type: 'Loan - Loan as Guarantor',
                  color: '#6d4dbc',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-loan.svg',
                },
                {
                  type: 'Loan - Student Loan',
                  color: '#9e81dd',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-loan.svg',
                },
                {
                  type: 'Other - Commercial Bill',
                  color: '#9278c2',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-other.svg',
                },
                {
                  type: 'Other - Maintenance Payments',
                  color: '#5b458d',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-other.svg',
                },
                {
                  type: 'Other - Overdraft',
                  color: '#305292',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/a-l/icon-other.svg',
                },
              ],
            },
            {
              income_expenses: [
                /*
                 * Expenses
                 * */

                {
                  type: 'Food / Drinks',
                  color: '#4760a0',
                  category: 'Entertainment',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-entertainment.svg',
                },
                {
                  type: 'Gifts',
                  color: '#6d89bb',
                  category: 'Entertainment',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-entertainment.svg',
                },
                {
                  type: 'Holidays',
                  color: '#739cd2',
                  category: 'Entertainment',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-entertainment.svg',
                },
                {
                  type: 'Sports / Hobby',
                  color: '#87b8f2',
                  category: 'Entertainment',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-entertainment.svg',
                },
                {
                  type: 'TV/Movies/DVDs/Books',
                  color: '#4d4479',
                  category: 'Entertainment',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-entertainment.svg',
                },
                {
                  type: 'Child Care',
                  color: '#6971ba',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Child Maintenance',
                  color: '#7486d2',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Clothing',
                  color: '#a4a5c4',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Council Rates',
                  color: '#89a0ee',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Education',
                  color: '#27467f',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Household Purchases',
                  color: '#1d5dbf',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Medical/Pharmaceutical',
                  color: '#2378ec',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Mobile Phone',
                  color: '#619fea',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Pet Care',
                  color: '#95add3',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Property Maintenance',
                  color: '#08b0b9',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Rent',
                  color: '#37c2c7',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Supermarket',
                  color: '#5fd0d8',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Telephone / Internet',
                  color: '#68cedc',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Utilities',
                  color: '#a3c7a1',
                  category: 'General Expenses',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-gen-expenses.svg',
                },
                {
                  type: 'Car',
                  color: '#f5657c',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-insurances.svg',
                },
                {
                  type: 'Health',
                  color: '#ef646b',
                  category: 'Insurances',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-insurances.svg',
                },
                {
                  type: 'Home and Contents',
                  color: '#ec84b9',
                  category: 'Insurances',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-insurances.svg',
                },
                {
                  type: 'Life/Income',
                  color: '#e98c96',
                  category: 'Insurances',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-insurances.svg',
                },
                {
                  type: 'Superannuation',
                  color: '#fbbd66',
                  category: 'Insurances',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-insurances.svg',
                },
                {
                  type: 'Fees',
                  color: '#614a9c',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-taxes.svg',
                },
                {
                  type: 'Investments',
                  color: '#6d4dbc',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-taxes.svg',
                },
                {
                  type: 'Car Maintenance / Registration',
                  color: '#9e81dd',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-transport.svg',
                },
                {
                  type: 'Parking',
                  color: '#9278c2',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-transport.svg',
                },
                {
                  type: 'Petrol',
                  color: '#5b458d',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-transport.svg',
                },
                {
                  type: 'Public Transport',
                  color: '#305292',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-transport.svg',
                },

                /*
                 * Income
                 * */

                {
                  type: 'Base Income',
                  color: '#4760a0',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Regular Overtime',
                  color: '#6d89bb',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Secondary - Small Business Income',
                  color: '#739cd2',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Secondary - DIrectors Fees',
                  color: '#87b8f2',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Secondary - Once off Non-Primary Product Income',
                  color: '#4d4479',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Govt - Allowance - Parenting',
                  color: '#6971ba',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Govt - Allowance - Widow',
                  color: '#7486d2',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Govt - Allowance - Unemployment',
                  color: '#a4a5c4',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Rental Income',
                  color: '#89a0ee',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Addback - Interest Refinanced',
                  color: '#27467f',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Shift Allowance',
                  color: '#1d5dbf',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Secondary - Second Job - PAYG',
                  color: '#2378ec',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Secondary - Partnership Distributions',
                  color: '#619fea',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Secondiary - Share Dividends',
                  color: '#95add3',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Secondary - Once-Off Tax Loss',
                  color: '#08b0b9',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Govt - Allowance - Other',
                  color: '#37c2c7',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Govt - Allowance - Defence Force',
                  color: '#5fd0d8',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Other - Maintenance from Divorce',
                  color: '#68cedc',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-other.svg',
                },
                {
                  type: 'Current Net Profit',
                  color: '#a3c7a1',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Addback - Superannuation',
                  color: '#f5657c',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Regular Commission',
                  color: '#ef646b',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Secondary - Casual job',
                  color: '#ec84b9',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Secondary - Trust Distributions',
                  color: '#e98c96',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Secondary - Interest Income',
                  color: '#fbbd66',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-base-income.svg',
                },
                {
                  type: 'Govt - Allowance - Family Tax',
                  color: '#614a9c',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-other.svg',
                },
                {
                  type: 'Govt - Allowance - Disability',
                  color: '#6d4dbc',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-other.svg',
                },
                {
                  type: 'Govt - Allowance - Pension',
                  color: '#9e81dd',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-other.svg',
                },
                {
                  type: 'Other - Non-Recurring Expense',
                  color: '#9278c2',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-other.svg',
                },
                {
                  type: 'Addback - Depreciation',
                  color: '#5b458d',
                  category: '',
                  icon:
                    '/assets/images/loanScenario/financials/i-e/icon-other.svg',
                },
              ],
            },
            {
              categories: [
                {
                  type: 'Investment',
                  color: '#305292',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Personal',
                  color: '#4760a0',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Cash',
                  color: '#6d89bb',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Other',
                  color: '#739cd2',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Vehicle',
                  color: '#87b8f2',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Real Estate',
                  color: '#4d4479',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Loan',
                  color: '#6971ba',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Credit Card',
                  color: '#7486d2',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Entertainment',
                  color: '#a4a5c4',
                  category: '',
                  icon: '',
                },
                {
                  type: 'General Expenses',
                  color: '#89a0ee',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Insurances',
                  color: '#27467f',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Taxes',
                  color: '#1d5dbf',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Transport',
                  color: '#2378ec',
                  category: '',
                  icon: '',
                },
                {
                  type: 'Income',
                  color: '#619fea',
                  category: '',
                  icon: '',
                },
              ],
            },
          ];

          return types;
        },

        categoryColor(type, finance) {
          var color = '';

          angular.forEach(this.financialTypeList(), (types) => {
            // Default color

            if (finance == 'assets_liabilities') {
              angular.forEach(types.assets_liabilities, (item) => {
                if (item.type == type) {
                  color = item.color;
                }
              });
            } else if (finance == 'income_expenses') {
              angular.forEach(types.income_expenses, (item) => {
                if (item.type == type) {
                  color = item.color;
                }
              });
            } else if (finance == 'categories') {
              angular.forEach(types.categories, (item) => {
                if (item.type == type) {
                  color = item.color;
                }
              });
            }
          });

          return color;
        },

        typeIcon(type, finance) {
          var icon = '';

          angular.forEach(this.financialTypeList(), (types) => {
            // Default color

            if (finance == 'assets_liabilities') {
              angular.forEach(types.assets_liabilities, (item) => {
                if (item.type == type) {
                  icon = item.icon;
                }
              });
            } else if (finance == 'income_expenses') {
              angular.forEach(types.income_expenses, (item) => {
                if (item.type == type) {
                  icon = item.icon;
                }
              });
            }
          });

          return icon;
        },
      };
    },
  ]);
