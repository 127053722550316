import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { openVideoModal } from 'Common/utilities/trainingVideoModal';
import { scrollToTop } from 'Common/utilities/windowLocation';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';
import { toastSaveSuccess, toastError } from 'Common/utilities/alert';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { get, isUndefined } from 'lodash';
import { parseToInt10 } from 'Common/utilities/parse';
import { catchError } from 'Common/utilities/promise';
import { isValidDocument } from './../util/loanAppValidation';
import { DISPLAY_DOCUMENTS } from 'Components/opportunity/util/constants';

export const isCompleteCPA = ({ $scope, completion }) => {
  const { isAU, isNZ } = $scope.currentUserService;
  const CPA = [
    SECTION_ID.APPLICANTS,
    SECTION_ID.CUSTOMER_OBJECTIVES,
    SECTION_ID.FINANCIAL_POSITION,
    SECTION_ID.LOAN_FUNDING_DETAILS,
    SECTION_ID.INSURANCE,
  ];
  if (isAU || isNZ) {
    const hasNotCompleted = CPA.some((id) => {
      return !completion[id].IsCompleted;
    });
    const isValidSectionsWithFlag =
      !$scope.displayPossibleAdverseChanges ||
      completion[SECTION_ID.POSSIBLE_ADVERSE].IsCompleted;
    const isValidNZDownloadCondition =
      $scope.uiService.nzBidLoanAppChangesValidation &&
      checkGamePlanNzDownloadCondition({ $scope, completion });

    return (
      !hasNotCompleted &&
      isValidSectionsWithFlag &&
      (!$scope.uiService.bidLoanAppChangesValidation ||
        isValidNZDownloadCondition)
    );
  }

  return true;
};

export const checkGamePlanNzDownloadCondition = ({ $scope, completion }) => {
  const {
    loanAppSummary,
    isFutureFinancialGoalsCompleted,
  } = $scope.loanAppSharedData;

  const isProductAdded = !!(
    loanAppSummary?.LoanDetails?.LoanFacility?.length &&
    loanAppSummary.LoanDetails.LoanFacility.every((split) => {
      return split && !!get(split, 'AssetFinance.ProductName', '');
    })
  );
  $scope.loanAppSharedData.hasLoanFacilityProduct = isProductAdded;

  const isLenderSubmissionSectionCompleted =
    completion[SECTION_ID.LENDER_SUBMISSION].IsCompleted;

  return !!(
    isFutureFinancialGoalsCompleted &&
    isProductAdded &&
    isLenderSubmissionSectionCompleted
  );
};

export const checkIsGPDocumentValid = ($scope) => {
  const { loanAppSummary } = $scope.loanAppSharedData;
  const { DocumentList: documentList } = loanAppSummary;
  $scope.loanAppSharedData.hasInvalidSupportingDocs = !!(
    documentList?.length && !documentList.some(isValidDocument)
  );
  $scope.loanAppSharedData.hasNoSupportingDocs = !documentList?.length;

  return (
    !$scope.loanAppSharedData.hasNoSupportingDocs &&
    !$scope.loanAppSharedData.hasInvalidSupportingDocs
  );
};

export const showInvalidDocumentWarning = ({ $rootScope, $scope, route }) => {
  $rootScope.LoanAppFormWarning = true;
  $rootScope.file.LoanAppFormWarning = true;
  $rootScope.showWarningCPA = false;
  $rootScope.file.showWarningCPA = false;
  $scope.validation.showMissedFieldsMessage = null;
  $scope.loanAppSharedData.isValidSupportingDocs = false;
  const isFromLoanApp = route === 'app.loanApplicationDetailsV3';

  const validationHandler = {
    goToError: isFromLoanApp
      ? () => scrollToTop(700)
      : () => $scope.$emit(DISPLAY_DOCUMENTS),
    isFromLoanApp,
  };

  if ($scope.loanAppSharedData.hasInvalidSupportingDocs) {
    $scope.validation.documents = validationHandler;
  }
  if ($scope.loanAppSharedData.hasNoSupportingDocs) {
    $scope.validation.noDocument = validationHandler;
  }
  scrollToTop(0);
};

export const unsetGamePlanNZValidationProp = ({
  nzBidLoanAppChangesValidation,
  loanAppSharedData,
}) => {
  const isGamePlanNzValidationActivated =
    nzBidLoanAppChangesValidation &&
    !isUndefined(loanAppSharedData.isGenerateGamePlanValidationFailed);

  if (isGamePlanNzValidationActivated) {
    delete loanAppSharedData.isGenerateGamePlanValidationFailed;
    delete loanAppSharedData.hasNoSupportingDocs;
    delete loanAppSharedData.hasInvalidSupportingDocs;
    delete loanAppSharedData.hasLoanFacilityProduct;
  }
};

export const getInvestment = (data) => {
  const selectedPurpose = data.filter((item) => {
    return item.QuestionId === 5;
  });

  if (selectedPurpose.length) {
    if (!selectedPurpose[0].answerInt) {
      return false;
    }
    const checkInvestment = selectedPurpose[0].answerInt.filter((o) => {
      return o === 3;
    });
    return checkInvestment.length > 0;
  }
  return false;
};

export const getDateType = (dateType) => {
  const selectOptions = {
    [`Estimated Settlement Date`]: 'settlementDate',
    [`Finance Date`]: 'financeDate',
    [`Auction Date`]: 'auctionDate',
  };
  return dateType ? selectOptions[dateType] : '';
};

export const getShowSidebarWidgets = (obj) => {
  const { assetFinance, currentUserService, response } = obj;
  const { isID } = currentUserService;
  const lendingCategoryId = get(response, 'data.LendingCategoryId');

  return assetFinance
    ? !isID && lendingCategoryId !== LENDING_CATEGORY.ASSET_FINANCE
    : !isID;
};

export const openDemoVideoModal = (size, $uibModal, currentUserService) => {
  if (currentUserService.isNZ) {
    openVideoModal($uibModal, PAGE_SOURCE.LOAN_APP_1);
    return true;
  }

  $uibModal.open({
    templateUrl: '/assets/views/partials/demo_multi_video_modal.html',
    controller: 'LoanAppVideoDemoCtrl',
    size,
    windowClass: 'demo-video-modal-window-class multi-demo-video',
  });
  return false;
};

export function embedLoanAppOnOpportunities({
  $scope,
  $state,
  $rootScope,
  $stateParams,
  uiService,
  currentUserService,
}) {
  const isMissingRequireParams =
    uiService.viewOfAfileTurnedOn &&
    (!$state.params.opportunityId || !$state.params.sourceFamilyId);

  if (isMissingRequireParams || !uiService.viewOfAfileTurnedOn) {
    return;
  }

  $scope.crmCountry =
    $scope.crmCountry ||
    $rootScope.crmCountry ||
    currentUserService.brokerRegionalization.name;
  $scope.crmCountryID =
    $scope.crmCountryID ||
    $rootScope.crmCountryID ||
    currentUserService.brokerRegionalization.countryID;

  $scope.loanAppId = $state.params.opportunityId;
  $scope.familyId = $state.params.sourceFamilyId;

  $stateParams.loanAppId = $scope.loanAppId;
  $stateParams.familyId = $scope.familyId;

  $state.params.loanAppId = $scope.loanAppId;
  $state.params.familyId = $scope.familyId;
}

export const onAssistantSelect = ({
  $rootScope,
  $scope,
  $state,
  loanScenarioService,
}) => (assistantFamilyId) => {
  const invalidParams = !$scope || !$state || !loanScenarioService;
  if (invalidParams) {
    return;
  }
  if (!assistantFamilyId && assistantFamilyId !== 0) {
    return;
  }
  loanScenarioService
    .assignAssistant($state.params.loanAppId, { assistantFamilyId })
    .then(() => {
      $scope.LoanApplication = {
        ...$scope.LoanApplication,
        AssistantFamilyId: assistantFamilyId,
      };
      toastSaveSuccess();
      $scope.loanAppEmailTemplateNZ && $rootScope.$emit('EDIT_ADVISER_EMAIL');
    })
    .catch(toastError);
};

export const setCompletionStatus = ({
  $timeout,
  sectionId,
  loanAppSharedData,
  isValidSection,
}) => {
  const sectionStatus = loanAppSharedData.sectionsCompletionStatus[sectionId];
  if (sectionStatus && sectionStatus.isCompleted === isValidSection) {
    return;
  }

  const timerDelay = sectionStatus ? 500 : 1500;

  loanAppSharedData.setCompletionStatusTimeout[sectionId] &&
    $timeout.cancel(loanAppSharedData.setCompletionStatusTimeout[sectionId]);

  const completionStatusCallback = () => {
    const sectionObject = loanAppSharedData.getSectionObjectById(
      loanAppSharedData.completionListObject,
      sectionId,
    );

    loanAppSharedData.completionStatusSet(
      sectionId,
      sectionObject.IsCompleted,
      isValidSection,
    );
  };

  loanAppSharedData.setCompletionStatusTimeout[sectionId] = $timeout(
    () => completionStatusCallback(),
    timerDelay,
  );
};

export const getPossibleAdverseAnswers = ({
  $scope,
  loanApplicationServices,
}) => () => {
  loanApplicationServices
    .getPossibleAdverseChanges({
      familyId: $scope.familyId,
      questionId: 0,
      loanApplicationId: parseToInt10($scope.loanAppId),
    })
    .then((response) => {
      $scope.loanAppSharedData.isPossibleAdverseStarted = !!response.length;
    })
    .catch((error) => catchError(error, 'LoanAppUtil'));
};
