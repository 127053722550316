import {
  responseHasValuesOfObjects,
  confirmAction,
} from 'Common/utilities/insurance';

class addInsuranceNoteTemplatesCtrl {
  constructor(insuranceProfilerService, toaster) {
    'ngInject';

    this.toaster = toaster;
    this.benefitsData = {};
    this.benefitsNotes = {};
    this.defaultBenefits = 1;
    this.insuranceProfilerService = insuranceProfilerService;
    this.actionTypes = '';
  }

  $onInit() {
    this.benefitsData = this.benefitId;
    this.actionTypes = this.actionType;
  }

  close() {
    this.modalInstance.dismiss();
  }

  toasters() {
    this.toaster.pop({
      type: 'error',
      body: 'You must fill in all the fields.',
    });
  }

  saveNoteTemplate(isValid) {
    if (isValid) {
      this.benefitsNotes.benefitTypeId = this.benefitsData.benefitTypeId;
      this.benefitsNotes.insuranceType = this.defaultBenefits;
      this.benefitsNotes.benefitTypeName = this.benefitsData.benefitTypeName;
      this.benefitsNotes.name = this.noteTitle;
      this.benefitsNotes.content = this.noteContet;

      this.insuranceProfilerService
        .profilerNoteTemplateSet(this.benefitsNotes)
        .then((noteDetails) => {
          if (responseHasValuesOfObjects(noteDetails)) {
            this.benefitsNotes = { ...noteDetails };
            this.successAddedNote('Template is successfully added!');
          }
        });
    } else {
      this.toasters();
    }
  }

  updateNote(addTemplateNote, noteTitle, noteContent) {
    const { id, benefitTypeId, insuranceType } = this.benefitsData;
    const notesParam = {
      id,
      benefitTypeId,
      insuranceType,
      name: noteTitle,
      content: noteContent,
    };

    if (addTemplateNote) {
      this.insuranceProfilerService
        .profilerNoteTemplateUpdate(notesParam, notesParam.id)
        .then((noteDetails) => {
          if (responseHasValuesOfObjects(noteDetails)) {
            this.benefitsNotes = { ...noteDetails };
            this.successAddedNote('Template is successfully Updated!');
          }
        });
    } else {
      this.toasters();
    }
  }

  successAddedNote(message) {
    confirmAction(
      {
        title: 'Success',
        text: message,
        type: 'success',
        confirmButtonText: 'Ok',
        closeOnConfirm: true,
      },
      (confirm) => {
        if (confirm) {
          this.modalInstance.close(this.benefitsNotes);
        }
      },
    );
  }
}

export default addInsuranceNoteTemplatesCtrl;
