import angular from 'angular';
import LoanAppSubmissionValidationService from './util/loanAppSubmissionValidationService';

export const loadModule = () =>
  angular
    .module('app')
    .factory(
      'loanAppSubmissionValidationService',
      (
        $q,
        uiService,
        loanSubmissionEmailContentService,
        loanScenarioService,
        loanAppSharedData,
      ) =>
        new LoanAppSubmissionValidationService(
          $q,
          uiService,
          loanSubmissionEmailContentService,
          loanScenarioService,
          loanAppSharedData,
        ),
    );
