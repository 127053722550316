class BreadCrumbCtrl {
  constructor($timeout, contactService, $state, stateService) {
    'ngInject';

    this.$timeout = $timeout;
    this.contactService = contactService;
    this.$state = $state;
    this.stateService = stateService;
  }

  openContactSummary() {
    this.$state.go('app.contactsInsurances', {
      familyId: this.moduleData.FamilyID,
      activeTab: 'insurance',
    });
  }
}

export default BreadCrumbCtrl;
