import template from './tableTotalAmount.html';
import controller from './tableTotalAmountCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    pageLength: '<',
    totalRecords: '<',
    totalAmountPerPage: '<',
    totalAmountOverall: '<',
  },
};
