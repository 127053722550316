import './style.scss';
import template from './badge.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    label: '<?',
    value: '<?',
    currencyValue: '<?',
    isMinimal: '<?',
    isCurrency: '<?',
    isPercentage: '<?',
    isLinearGreen: '<?',
    isLinearBlue: '<?',
    isBadgeCornered: '<?',
    customClass: '<?',
    backgroundColor: '<?',
  },
};
