import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('outstandingCommission', function outstandingCommission(
      $timeout,
      overviewDashboardService,
    ) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: { myParam: '=' },
        templateUrl:
          'assets/views/overviewDashboard/directiveTemplates/outstandingCommission.html',

        // eslint-disable-next-line sonarjs/cognitive-complexity
        link(scope) {
          scope.interval = 'allDates';
          scope.slickConfig = { method: {} };
          scope.commissionExpectedListData = [];
          scope.widgetOptionAdviserCommissionList = [];
          scope.WidgetOptionAdviserCommission = {};

          scope.outstandingCommissionMultiAdviser = [];
          scope.outstandingCommissionMultiAdviserObj = {};
          scope.WidgetOptionLenderList = [];
          scope.outstandingCommissionMultiLender = [];
          scope.outstandingCommissionMultiLenderObj = {};
          scope.outpLender = [];

          function CommissionExpectedListGet() {
            overviewDashboardService
              .CommissionExpectedListGet(scope.myParam.Widget_UserID)
              .then(
                (response) => {
                  scope.commissionExpectedListData = [];
                  const totalData = response.data.length;
                  const round = Math.floor(totalData / 4) * 4 - 1;
                  const modStart = Math.floor(totalData / 4) * 4;
                  const mod = Math.floor(totalData % 4);
                  for (let i = 0; i < round; i += 4) {
                    const testData = [];
                    testData.push(response.data[i]);
                    testData.push(response.data[i + 1]);
                    testData.push(response.data[i + 2]);
                    testData.push(response.data[i + 3]);

                    scope.commissionExpectedListData.push(testData);
                  }

                  if (parseInt(mod, 10) !== 0) {
                    const lastItem = [];
                    for (let i = modStart; i < totalData; i++) {
                      lastItem.push(response.data[i]);
                    }
                    scope.commissionExpectedListData.push(lastItem);
                  }

                  scope.dataLoaded = true;

                  if (scope.commissionExpectedListData.length > 0) {
                    $timeout(() => {
                      try {
                        scope.slickConfig.method.slickGoTo(0);
                      } catch (error) {
                        // Continue regardless of error
                      }
                    }, 1000);
                  }
                },
                () => {},
              );
          }

          function widgetOptionGet() {
            overviewDashboardService
              .WidgetOptionGet(scope.myParam.Widget_UserID)
              .then(
                (response) => {
                  scope.WidgetOptionGetData = response.data;
                  scope.Advisers = [];
                  scope.Lenders = [];
                  let Flag = 0;

                  for (let i = 0; i < scope.WidgetOptionGetData.length; i++) {
                    if (
                      parseInt(
                        scope.WidgetOptionGetData[i].WidgetOptionId,
                        10,
                      ) === 6
                    ) {
                      Flag = 1;
                      scope.switchsetting = 'Yes';
                      scope.Lenders =
                        scope.WidgetOptionGetData[i].SelectedUerValues;
                      const LendersArray = scope.Lenders.split(',');

                      scope.outstandingCommissionMultiLender = scope.outstandingCommissionMultiLender.map(
                        (obj) => {
                          if (LendersArray.includes(obj.ProviderId)) {
                            obj.ticked = true;
                            return obj;
                          }

                          return obj;
                        },
                      );
                    } else if (
                      scope.WidgetOptionGetData[i].OptionName !== 'Lender' &&
                      scope.switchsetting !== 'Yes' &&
                      Flag !== 1
                    ) {
                      scope.switchsetting = 'No';
                    }
                    if (
                      parseInt(
                        scope.WidgetOptionGetData[i].WidgetOptionId,
                        10,
                      ) === 9
                    ) {
                      Flag = 1;
                      scope.switchsetting2 = 'Yes';
                      scope.Advisers =
                        scope.WidgetOptionGetData[i].SelectedUerValues;
                      const AdvisersArray = scope.Advisers.split(',');

                      scope.outstandingCommissionMultiAdviser = scope.outstandingCommissionMultiAdviser.map(
                        (obj) => {
                          if (AdvisersArray.includes(obj.FamilyId)) {
                            obj.ticked = true;
                            return obj;
                          }

                          return obj;
                        },
                      );
                    } else if (
                      scope.WidgetOptionGetData[i].OptionName !==
                        'Commission Adviser' &&
                      scope.switchsetting2 !== 'Yes' &&
                      Flag !== 1
                    ) {
                      scope.switchsetting2 = 'No';
                    }
                  }
                },
                () => {},
              );
          }

          function WidgetOptionAdviserCommissionList() {
            overviewDashboardService.WidgetOptionAdviserCommissionList().then(
              (response) => {
                scope.widgetOptionAdviserCommissionList = response.data;
                for (
                  let i = 0;
                  i < scope.widgetOptionAdviserCommissionList.length;
                  i++
                ) {
                  scope.widgetOptionAdviserCommissionList[i].ticked = false;
                  scope.outstandingCommissionMultiAdviser.push(
                    scope.widgetOptionAdviserCommissionList[i],
                  );
                }
                widgetOptionGet();
              },
              () => {},
            );
          }
          function WidgetOptionLenderListGet() {
            overviewDashboardService.WidgetOptionLenderListGet().then(
              (response) => {
                scope.WidgetOptionLenderList = response.data;

                for (let i = 0; i < scope.WidgetOptionLenderList.length; i++) {
                  scope.WidgetOptionLenderList[i].ticked = false;
                  scope.outstandingCommissionMultiLender.push(
                    scope.WidgetOptionLenderList[i],
                  );
                }

                widgetOptionGet();
              },
              () => {},
            );
          }

          function saveForTimePeriod(widgetSetObj) {
            scope.OptionToSet = widgetSetObj;
            overviewDashboardService
              .WidgetOptionSet(scope.OptionToSet)
              .then(() => {
                CommissionExpectedListGet();
                widgetOptionGet();
              })
              .catch(displayError);
          }

          scope.makeSelectNone = function () {
            for (
              let i = 0;
              i < scope.widgetOptionAdviserCommissionList.length;
              i++
            ) {
              scope.widgetOptionAdviserCommissionList[i].ticked = false;
              scope.outstandingCommissionMultiAdviser.push(
                scope.widgetOptionAdviserCommissionList[i],
              );
            }
            scope.setIsFiltered.Widget_OptionValue = '';
            scope.setIsFiltered.WidgetOptionId = 9;
            scope.setIsFiltered.Widget_UserID = scope.myParam.Widget_UserID;
            scope.setIsFiltered.SelectedUser = [];
            saveForTimePeriod(scope.setIsFiltered);
          };
          scope.makeSelectNoneLender = function () {
            for (let i = 0; i < scope.WidgetOptionLenderList.length; i++) {
              scope.WidgetOptionLenderList[i].ticked = false;
              scope.outstandingCommissionMultiLender.push(
                scope.WidgetOptionLenderList[i],
              );
            }
            scope.setIsFiltered.Widget_OptionValue = '';
            scope.setIsFiltered.WidgetOptionId = 6;
            scope.setIsFiltered.Widget_UserID = scope.myParam.Widget_UserID;
            scope.setIsFiltered.SelectedUser = [];
            saveForTimePeriod(scope.setIsFiltered);
          };
          scope.isFiltered = function (filteredOption) {
            scope.val = filteredOption;
            scope.setIsFiltered = {};

            if (scope.val === 'No') {
              scope.makeSelectNone();
            } else {
              scope.switchsetting2 = 'Yes';
            }
          };
          scope.isFilteredLender = function (filteredOption) {
            scope.val = filteredOption;
            scope.setIsFiltered = {};

            if (scope.val === 'No') {
              scope.makeSelectNoneLender();
            } else {
              scope.switchsetting = 'Yes';
            }
          };
          scope.updateMultiselectFilterDataLender = function (clickedItem) {
            let dataToSend = [];
            scope.MultiOptionSetLender = {};
            scope.MultiOptionSetForAllLender = {};
            const itemData = _.find(scope.outstandingCommissionMultiLender, [
              'ticked',
              true,
            ]);
            if (_.isUndefined(itemData)) {
              _.map(scope.outstandingCommissionMultiLender, (item) => {
                if (item.ProviderName === clickedItem.ProviderName) {
                  item.ticked = true;
                }
              });
            }
            if (clickedItem.ProviderName !== 'All') {
              if (_.size(scope.outstandingCommissionMultiLender) !== 0) {
                scope.outstandingCommissionMultiLender[0].ticked = false;
              }

              dataToSend = scope.outstandingCommissionMultiLender
                .filter(
                  (item) => item.ProviderName !== 'All' && item.ticked === true,
                )
                .map((obj) => {
                  return { PersonId: obj.ProviderId };
                });

              scope.MultiOptionSetLender.SelectedUser = dataToSend;
              scope.MultiOptionSetLender.WidgetOptionId =
                scope.WidgetOptionLenderList[0].OptionId;
              scope.MultiOptionSetLender.Widget_UserID =
                scope.myParam.Widget_UserID;
              scope.MultiOptionSetLender.Widget_OptionValue =
                scope.WidgetOptionLenderList[1].OptionValueId;

              saveForTimePeriod(scope.MultiOptionSetLender);
            } else {
              const all = scope.outpLender.filter(
                (item) => item.ProviderName === 'All',
              );
              if (all.length > 0 && scope.outpLender.length > 1) {
                scope.outstandingCommissionMultiLender = scope.outstandingCommissionMultiLender.map(
                  (obj) => {
                    if (obj.ProviderName === 'All') {
                      return obj;
                    }

                    obj.ticked = false;
                    return obj;
                  },
                );
              }
              dataToSend.push({
                PersonId: scope.outstandingCommissionMultiLender[0].ProviderId,
              });

              scope.MultiOptionSetForAllLender.SelectedUser = dataToSend;
              scope.MultiOptionSetForAllLender.WidgetOptionId =
                scope.WidgetOptionLenderList[0].OptionId;
              scope.MultiOptionSetForAllLender.Widget_UserID =
                scope.myParam.Widget_UserID;
              scope.MultiOptionSetForAllLender.Widget_OptionValue =
                scope.WidgetOptionLenderList[0].OptionValueId;

              saveForTimePeriod(scope.MultiOptionSetForAllLender);
            }
          };
          scope.updateMultiselectFilterDataAdviser = function (clickedItem) {
            let dataToSend = [];
            scope.MultiOptionSet = {};
            scope.MultiOptionSetForAll = {};
            const itemData = _.find(scope.outstandingCommissionMultiAdviser, [
              'ticked',
              true,
            ]);
            if (_.isUndefined(itemData)) {
              _.map(scope.outstandingCommissionMultiAdviser, (item) => {
                if (item.FamilyName === clickedItem.FamilyName) {
                  item.ticked = true;
                }
              });
            }
            if (clickedItem.FamilyName !== 'All') {
              if (_.size(scope.outstandingCommissionMultiAdviser) !== 0) {
                scope.outstandingCommissionMultiAdviser[0].ticked = false;
              }

              dataToSend = scope.outstandingCommissionMultiAdviser
                .filter(
                  (item) => item.FamilyName !== 'All' && item.ticked === true,
                )
                .map((obj) => {
                  return { PersonId: obj.FamilyId };
                });

              scope.MultiOptionSet.SelectedUser = dataToSend;
              scope.MultiOptionSet.WidgetOptionId =
                scope.widgetOptionAdviserCommissionList[0].OptionId;
              scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
              scope.MultiOptionSet.Widget_OptionValue =
                scope.widgetOptionAdviserCommissionList[1].OptionValueId;

              saveForTimePeriod(scope.MultiOptionSet);
            } else {
              const all = scope.outp.filter(
                (item) => item.FamilyName === 'All',
              );
              if (all.length > 0 && scope.outp.length > 1) {
                scope.outstandingCommissionMultiAdviser = scope.outstandingCommissionMultiAdviser.map(
                  (obj) => {
                    if (obj.FamilyName === 'All') {
                      return obj;
                    }

                    obj.ticked = false;
                    return obj;
                  },
                );
              }
              dataToSend.push({
                PersonId: scope.outstandingCommissionMultiAdviser[0].FamilyId,
              });

              scope.MultiOptionSetForAll.SelectedUser = dataToSend;
              scope.MultiOptionSetForAll.WidgetOptionId =
                scope.widgetOptionAdviserCommissionList[0].OptionId;
              scope.MultiOptionSetForAll.Widget_UserID =
                scope.myParam.Widget_UserID;
              scope.MultiOptionSetForAll.Widget_OptionValue =
                scope.widgetOptionAdviserCommissionList[0].OptionValueId;

              saveForTimePeriod(scope.MultiOptionSetForAll);
            }
          };
          function init() {
            CommissionExpectedListGet();
            WidgetOptionAdviserCommissionList();

            WidgetOptionLenderListGet();
          }

          scope.dynamicPopover = {
            content: ' ',
            templateUrl: 'outstandingCommissionPopover.html',
            title: 'Title',
          };

          scope.doAction = function (msg) {
            if (msg === 'Customise') {
              scope.customizeMode = true;
            } else {
              scope.customizeMode = false;
            }
          };

          init();
        },
      };
    });
