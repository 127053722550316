import angular from 'angular';

class SmsTemplatesManagementCtrl {
  constructor($uibModalInstance, showDefaultTemplateSettings) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.showDefaultTemplateSettings = showDefaultTemplateSettings;
  }
}
export const loadModule = () =>
  angular
    .module('app')
    .controller('SmsTemplatesManagementCtrl', SmsTemplatesManagementCtrl);
