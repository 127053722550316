import template from './mycrmChecklistDropdown.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    dropdownIcon: '<',
    dropdownText: '<',
    menuItems: '<',
    onMenuItemClick: '&',
  },
};
