export const internalCrmErrorBluestoneSubmission = ({
  crmConfirmation,
  closeModal,
}) => {
  crmConfirmation.open({
    type: 'warning',
    title: 'Oops something went wrong',
    description: `<internal-errors></internal-errors>`,
    buttonText: `Okay got it`,
    onConfirm: () => {
      closeModal.close();
    },
    showCloseButton: true,
    showCancelButton: false,
    modalSize: 'md',
    cancelButtonClass: 'colored',
    renderAsComponent: true,
    commonObject: {},
    isButtonMaxWidth: true,
  });
};
