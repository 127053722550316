import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('selectNormalContactsDir', function selectNormalContactsDir(
      $window,
    ) {
      return {
        restrict: 'A',
        scope: {
          selectedContacts: '@',
        },
        link(scope, element) {
          if (
            element.length >= 1 &&
            element[0] &&
            element[0].localName === 'select'
          ) {
            element.on('change', () => {
              const el = $window.document.querySelectorAll(
                `#${scope.selectedContacts} .nav-link`,
              );
              angular.element(el).triggerHandler('click');
            });
          } else {
            // eslint-disable-next-line sonarjs/no-identical-functions
            element.on('click', () => {
              const el = $window.document.querySelectorAll(
                `#${scope.selectedContacts} .nav-link`,
              );
              angular.element(el).triggerHandler('click');
            });
          }
        },
      };
    });
