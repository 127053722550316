import template from './gamePlanSendingMomentHeader.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    items: '<',
  },
};
