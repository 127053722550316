import { getDatePickerConfig } from 'Common/utilities/date';
import { DD_MMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';

export default class EditMultiProviderModalCtrl {
  $onInit() {
    this.providerNames = this.providers
      .map(({ providerName }) => providerName)
      .join(', ');
    this.dateOptions = getDatePickerConfig();
    this.dateFormatted = DD_MMM_YYYY_FORMAT;

    this.dateSubmitted = null;
    this.dateSubmittedPopUp = false;
    this.dateAccredited = null;
    this.dateAccreditedPopUp = false;

    this.statusList = this.statusList || [];
  }

  onCancel() {
    this.modalInstance.dismiss();
  }

  showCalendar(popUp) {
    this[popUp] = true;
  }

  submit() {
    const { statusId, dateSubmitted, dateAccredited } = this;
    const form = this.providers.map((provider) => ({
      ...provider,
      statusId,
      ...(this.isAu ? { dateSubmitted } : {}),
      dateAccredited,
    }));
    const successCb = () => this.modalInstance.dismiss();
    this.onSubmit({ form, successCb });
  }
}
