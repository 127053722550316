import angular from 'angular';
import helloPackEditor from './helloPackEditor';
import helloPackViewer from './helloPackViewer';
import helloPackModal from './helloPackModal';
import helloPackDeal from './helloPackDeal';

export default angular.module('helloPack.scenes', [
  helloPackEditor,
  helloPackViewer,
  helloPackModal,
  helloPackDeal,
]).name;
