export const INTERVIEW_OPTION = {
  FACE_TO_FACE: 1,
  VIDEO_CONFERENCE: 2,
  PHONE_CALL: 3,
  OTHER: 4,
};

const UPLOADED_DOCUMENTS_AU = [
  'Income supporting documents',
  'Liability statements (if applicable)',
  'Bank account and credit card statements',
  'Serviceability calculations',
  'Remaining supporting documents applicable to this application',
];

export const CONFIRM_DECLARATION_AU = {
  FIRST_HEADING: 'Please confirm:',
  confirmList: (isFullText) => [
    'A Hello Pack (inclusive of the Credit Guide) was issued before credit assistance',
    'Online fact find has been sent, accurately completed and information is consistent with the supporting documents provided',
    isFullText
      ? 'All clients on the loan application have completed the Loan Market Consent (either via one touch privacy or Privacy Consent form)'
      : 'All clients on the loan application have supplied consent (either via one touch privacy or Privacy Consent form)',
    'The Goal Setter has been completed and provides an accurate record of conversations with your client and their needs and objectives',
    'Where the clients declared living expenses are lower than HEM, there is specific commentary in MyCRM providing explanation',
    'A minimum of 3 lender/product comparisons have been recorded in the Game Plan (if less than 3 product/lender comparisons, there is specific commentary providing explanation in the Game Plan)',
    'A Game Plan was generated, signed by the client and dated prior to loan lodgement',
    'The application is being lodged within 90 days of generating the Game Plan',
    'There are sufficient, detailed notes recorded that capture all interactions with the client(s) and lender(s) throughout the home loan process',
    'All Tax File and Customer Reference numbers from government documents have been removed',
  ],
  SECOND_HEADING:
    'As per the <a class="bcc-link" rel="noopener noreferrer" target="_blank" href="http://mycrml.ink/minimum-supporting-docs">Loan Market Minimum Supporting documents requirements</a>, you’ve uploaded:',
  uploadedDocuments: (isFullText) =>
    isFullText
      ? [
          'Current ID (click <a class="bcc-link" rel="noopener noreferrer" target="_blank" href="http://mycrml.ink/verification-of-identity">here</a> to view the Loan Market Verification of Identity (VOI) policy)',
          ...[UPLOADED_DOCUMENTS_AU],
        ]
      : UPLOADED_DOCUMENTS_AU,
};

export const CONFIRM_DECLARATION_NZ = {
  FIRST_HEADING: 'Please confirm:',
  confirmList: (isFullText) => [
    'A Disclosure Guide was issued prior to lending advice being given.',
    'Customer objectives have been completed with correct, relevant information.',
    'There are detailed sufficient notes recorded that capture all interviews with the applicant(s) and lender(s) throughout the loan process.',
    'A Declaration Form, completed, signed and dated by the applicant(s) is attached.',
  ],
  SECOND_HEADING: 'Other relevant documentation is attached, such as:',
  uploadedDocuments: (isFullText) => [
    'Current certified photo ID',
    'Income documents',
    'Liability documents',
    'Bank statements with evidence of living expenses',
    'Servicing calculator',
  ],
};

export const DECLARATION_CONFIRM_WARNING = {
  title:
    'Oops! Please complete your loan submission declarations before submitting.',
  body: ' ',
  customClass: 'declarations-warning-alert',
};
