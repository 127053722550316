import { onSendHelloPackInsuranceAppSuccess } from './util/insuranceListCtrl';

export default class InsuranceListCtrl {
  constructor(
    $state,
    uiService,
    insuranceSharedData,
    insuranceModalApplicationService,
  ) {
    'ngInject';

    this.$state = $state;
    this.uiService = uiService;
    this.insuranceSharedData = insuranceSharedData;
    this.insuranceModalApplicationService = insuranceModalApplicationService;
  }

  $onInit() {
    this.changeCount = 0;
  }

  openInsuranceApplication() {
    this.insuranceModalApplicationService
      .onCreate({
        typeNew: true,
        quoteId: 0,
        familyId: parseInt(this.familyId, 10),
        fullName: this.familyFullName,
      })
      .result.then(() => {
        onSendHelloPackInsuranceAppSuccess({
          $state: this.$state,
          insuranceSharedData: this.insuranceSharedData,
        });
      });
  }

  onUpdated() {
    this.changeCount++;
  }
}
