import angular from 'angular';
import _, { get } from 'lodash';
import { MODAL_SECTION } from 'Common/constants/generateReports';
import { isCancelled } from 'Common/utilities/subscription';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { E_SIGN_ACOUNT_TYPE } from 'Common/constants/eSign';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import {
  mapEsignAccountCheck,
  checkEsignAccountVisibility,
  hasGuarantor,
} from './util/recommendationModal';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('RecommendationModalCtrl', function RecommendationModalCtrl(
      $scope,
      $window,
      $uibModal,
      $uibModalInstance,
      fundingCalculatorService,
      toaster,
      SweetAlert,
      uiService,
      loanProfilerService,
      loanScenarioService,
      activeModalSection,
      configService,
      multiLoanCpaIds,
      loanAppSharedData,
      currentUserService,
      usersSubscriptionService,
      contactService,
      eSignService,
      crmConfirmation,
      loanAppId,
    ) {
      $scope.loanAppLendingCategory =
        loanAppSharedData.loanAppSummary &&
        loanAppSharedData.loanAppSummary.LoanDetails &&
        loanAppSharedData.loanAppSummary.LoanDetails.LendingCategoryId;
      $scope.hasGuarantor = hasGuarantor(loanAppSharedData);
      $scope.isAssetFinanceActive =
        configService.feature.assetFinance &&
        ($scope.loanAppLendingCategory === LENDING_CATEGORY.ASSET_FINANCE ||
          $scope.loanAppLendingCategory === LENDING_CATEGORY.PERSONAL_LOAN);

      $scope.multiLoanCpaIds = multiLoanCpaIds;
      if ($scope.multiLoanCpaIds && $scope.multiLoanCpaIds.length) {
        $scope.loanAppId = $scope.multiLoanCpaIds[0];
      } else if (loanAppId) {
        $scope.loanAppId = loanAppId;
      }
      $scope.choosenSection = 0;
      $scope.showCqpBody = false;
      $scope.showCpaBody = false;
      $scope.isSendCPA = false;
      $scope.isSendCQP = false;

      const { userSubscription, esign, esignSignature } = configService.feature;
      const { CountryID: countryId } = loanAppSharedData.LoanApplicationDetails;
      $scope.isEsignEnabled = esign || 0;
      $scope.modalSection = MODAL_SECTION;

      $scope.isLoadingSubscription = false;
      $scope.isEsignDisabled =
        get(
          loanAppSharedData,
          'loanAppSummary.ApplicantGuarantorList.InvolvedPartyEntity.length',
          0,
        ) && currentUserService.isAU;

      if (!activeModalSection) {
        $scope.cancel();
      } else {
        $scope.activeModalSection = activeModalSection;
      }
      $scope.cancel = () => {
        $uibModalInstance.dismiss({ refreshFees: true });
      };
      $scope.openChoosenRecommendation = (selectedSection) => {
        if (!selectedSection) {
          return;
        }
        const isSubscriptionActive =
          $scope.eSignSubscription &&
          ($scope.eSignSubscription.isActive ||
            isCancelled($scope.eSignSubscription));
        const isNotSubscribe =
          selectedSection === MODAL_SECTION.E_SIGN &&
          !isSubscriptionActive &&
          $scope.isEsignEnabled &&
          countryId === COUNTRY_TYPE.AUSTRALIA;
        if (isNotSubscribe) {
          const { AdviserFamilyId } =
            loanAppSharedData && loanAppSharedData.LoanApplicationDetails;
          const { familyId } = currentUserService;
          const props = {
            familyId: AdviserFamilyId,
            subscriptionData: $scope.eSignSubscription,
            orderedById: familyId,
          };
          usersSubscriptionService
            .subscribeToService(props)
            .then((response) => {
              if (!response) {
                return;
              }
              $scope.toggleDisableNextStep(!!response);
              $scope.getEsignAccountStatus(AdviserFamilyId, selectedSection);
              $scope.getSubscription();
            });
          return;
        }
        const executeMappingEsignAccountCheck = mapEsignAccountCheck({
          $scope,
          loanAppSharedData,
          selectedSection,
          isSubscriptionActive,
        });
        loanAppSharedData.eSignAccountType = executeMappingEsignAccountCheck(
          eSignService,
        );
      };

      $scope.toggleDisableNextStep = (toggle = false) => {
        $scope.disableNextStep = toggle;
      };

      $scope.openEsignSignatureModal = () => {
        if (!esignSignature) {
          return;
        }
        const { AdviserFamilyId: familyId } =
          loanAppSharedData && loanAppSharedData.LoanApplicationDetails;
        const props = {
          familyId,
        };
        const modalInstance = $uibModal.open({
          template: `<e-sign-upload-signature-modal
                    modal-instance="vm.modalInstance"
                    family-id="vm.props.familyId">
                  </e-sign-upload-signature-modal>`,
          size: 'md',
          backdrop: 'static',
          windowClass: 'mc-e-sign-modal',
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: {
            props,
          },
        });
        modalInstance.result.then((response) => {
          loanAppSharedData.eSignHasExistingSignature = response;
        });
      };

      $scope.getSubscription = () => {
        $scope.isLoadingSubscription = true;
        const { AdviserFamilyId: familyId } =
          loanAppSharedData && loanAppSharedData.LoanApplicationDetails;
        if (!familyId) {
          return;
        }
        contactService.subscriptionInfo(familyId).then((response) => {
          $scope.isLoadingSubscription = false;
          if (!response || !response.length) {
            return;
          }
          loanAppSharedData.adviserSubscription = response;
          $scope.setEsignSubscription();
        });
      };

      $scope.getEsignAccountStatus = (familyId, selectedSection = 0) => {
        if (!familyId) {
          return;
        }
        eSignService.getAccountCheck(familyId).then((response) => {
          const { hasExistingSignature, status } = response;
          if (!status) {
            return;
          }
          loanAppSharedData.eSignAccountType = status;
          loanAppSharedData.eSignHasExistingSignature = hasExistingSignature;
          if (response.status === E_SIGN_ACOUNT_TYPE.ACTIVE) {
            $scope.activeModalSection = selectedSection;
            !hasExistingSignature && $scope.openEsignSignatureModal();
          }
          if (response.status === E_SIGN_ACOUNT_TYPE.INVITED) {
            crmConfirmation.open({
              type: 'success',
              title: 'Subscription Successful',
              description: `Your eSign account has now been created, please confirm your email address`,
              buttonText: 'Okay, got it!',
              modalSize: 'md',
            });
          }
        });
      };

      $scope.toggleShowCqpBody = () => {
        $scope.showCqpBody = !$scope.showCqpBody;
      };

      $scope.toggleShowCpaBody = () => {
        $scope.showCpaBody = !$scope.showCpaBody;
      };

      $scope.disableSubmitToEsign = () => {
        return !$scope.isSendCPA && !$scope.isSendCQP;
      };

      $scope.submitToESign = () => {
        if ($scope.disableSubmitToEsign() && !$scope.loanAppId) {
          return;
        }
        const props = {
          isSendCpa: $scope.isSendCPA,
          isSendCqp: $scope.isSendCQP,
          loanId: $scope.loanAppId,
        };
        const modalInstance = $uibModal.open({
          template: `<send-reports-to-esign
                  modal-instance="vm.modalInstance"
                  is-send-Cqp="vm.props.isSendCqp"
                  is-send-Cpa="vm.props.isSendCpa"
                  loan-id="vm.props.loanId"
                  >
                </send-reports-to-esign>`,
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'send-reports-to-esign',
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: {
            props,
          },
        });
        modalInstance.result.then((response) => {
          if (!response || !response.closeAll) {
            return;
          }
          $uibModalInstance.close({ closeAll: true });
        });
      };

      $scope.updateIsSendCPA = (isSendCPA) => {
        $scope.isSendCPA = isSendCPA;
        $scope.showCpaBody = isSendCPA;
      };

      $scope.updateIsSendCQP = (isSendCQP) => {
        $scope.isSendCQP = isSendCQP;
        $scope.showCqpBody = isSendCQP;
      };

      const getCpaCqpTitle = () => {
        const { tradingName, userBrandingCategory } = uiService;
        const baseTitle = 'About Broker';
        const secondTitle =
          userBrandingCategory === 'Own Brand'
            ? tradingName
            : userBrandingCategory;
        return secondTitle ? `${baseTitle} / ${secondTitle}` : baseTitle;
      };
      // models
      angular.extend($scope, {
        isGenerateClientPrelimiaryAssessment: true,
        isCPADownloadPdf: false,
        isCQPDownloadPdf: false,
        CQPFeesTotal: null,
        CPACQPSet: {
          CQP: {},
          CPA: {},
        },
        CQPDateformat: 'dd MMM yyyy',
        CQPAltInputFormats: ['d!/M!/yyyy', 'd!M!yyyy'],
        CQPDateOptions: {
          formatYear: 'yy',
          showWeeks: false,
        },
        CPACQPData: [
          {
            title: 'Cover Page',
            value: 'IsCoverPage',
          },
          {
            title: getCpaCqpTitle(),
            value: 'IsAboutBrokerLoanMarket',
            showChild: false,
            childData: [
              {
                placeholder: 'Content that is prepared goes here...',
                value: 'Content',
                type: 'textarea',
              },
            ],
          },
          {
            title: 'Table of Contents',
            value: 'IsTableOfContents',
          },
          {
            title: 'Executive Summary',
            value: 'IsExecutiveSummary',
          },
          {
            title: 'Situation / Preferences',
            value: 'IsSituationOrPreference',
          },
          {
            title: 'Applicant Summary',
            value: 'IsApplicantSummary',
          },
          {
            title: 'Financial Position',
            value: 'IsFinancialPosition',
          },
          {
            title: 'Securities',
            value: 'IsSecurity',
          },
          {
            title: 'Loan Options',
            value: 'IsLoanOption',
          },
          {
            title: 'Funding Position',
            value: 'IsFundingPosition',
          },

          {
            title: 'Product Comparison',
            value: 'IsProductComparison',
          },
          {
            title: 'Broker Declaration',
            value: 'IsBrokerDeclaration',
          },
          {
            title: 'Customer Acknowledgement',
            value: 'IsCustomerAcknowledgement',
          },
          {
            title: 'Document Checklist',
            value: 'IsDocumentChecklist',
          },
        ],
        selectedTab:
          $scope.multiLoanCpaIds && $scope.multiLoanCpaIds.length
            ? 'CPA'
            : 'CQP',
      });

      // methods
      if ($scope.crmCountry === 'New Zealand') {
        _.remove($scope.CPACQPData, (object) => {
          return (
            object.title === 'Customer Acknowledgement' ||
            object.title === 'Document Checklist'
          );
        });

        $scope.activeTab = 1;
      }
      if ($scope.multiLoanCpaIds && $scope.multiLoanCpaIds.length) {
        $scope.activeTab = 1;
      }
      angular.extend($scope, {
        CQPOpenDate(dateField) {
          $scope.CQPDatePopUp[dateField] = !$scope.CQPDatePopUp[dateField];
        },
        getCPADocument(callback) {
          const self = $scope;
          loanScenarioService
            .RecommendationReportStatusGet($scope.loanAppId)
            .then(
              (response) => {
                const CPACQPPageObj = response.data;

                if (
                  CPACQPPageObj &&
                  CPACQPPageObj.CPA &&
                  CPACQPPageObj.CPA.Content &&
                  typeof CPACQPPageObj.CPA.Content !== 'undefined'
                ) {
                  CPACQPPageObj.CPA.Content = CPACQPPageObj.CPA.Content.replace(
                    /<br>/g,
                    '\n',
                  );
                }
                CPACQPPageObj.LoanApplicationId = self.loanAppId;
                if (
                  CPACQPPageObj &&
                  CPACQPPageObj.CQP &&
                  typeof CPACQPPageObj.CQP !== 'undefined'
                ) {
                  const otherFeesPaidTo = CPACQPPageObj.CQP.OtherFeesPaidTo;
                  CPACQPPageObj.CQP.OtherFeesPaidTo =
                    otherFeesPaidTo === '0' ? '' : otherFeesPaidTo;
                  const referralPaidTo = CPACQPPageObj.CQP.ReferralFeePaidTo;
                  CPACQPPageObj.CQP.ReferralFeePaidTo =
                    referralPaidTo === '0' ? '' : referralPaidTo;
                }
                angular.extend(self.CPACQPSet, CPACQPPageObj);
                self.CQPFeesTotal =
                  parseInt(self.CPACQPSet.CQP.EstablishmentFee, 10) +
                  parseInt(self.CPACQPSet.CQP.ValuationFee, 10);
                self.isSaveTemplateChecked = self.CPACQPSet.CPA.IsTemplated;
                if (typeof callback !== 'undefined') {
                  callback();
                }
              },
              (error) => {
                toaster.pop('error', error.Message, error.ExceptionMessage);
              },
            );
        },
        updateCPACQP(reCalculate = true) {
          const { CPACQPSet, calculatorFundsSummary } = $scope;
          const checkTypeValid = CPACQPSet && calculatorFundsSummary;
          if (checkTypeValid) {
            if (reCalculate) {
              CPACQPSet.CQP.TrailCommission_amt = calculatorFundsSummary.totalProposedLending
                ? CPACQPSet.CQP.TrailCommission_amt
                : 0;
              CPACQPSet.CQP.UpfrontCommission_amt = calculatorFundsSummary.totalProposedLending
                ? CPACQPSet.CQP.UpfrontCommission_amt
                : 0;
            }

            const { isSaveTemplateChecked } = $scope;
            CPACQPSet.CPA.IsTemplated = isSaveTemplateChecked;
            const CPACQPSetCopy = angular.copy(CPACQPSet);
            if (
              CPACQPSetCopy &&
              CPACQPSetCopy.CPA &&
              CPACQPSetCopy.CPA.Content
            ) {
              CPACQPSetCopy.CPA.Content = CPACQPSetCopy.CPA.Content.replace(
                /\r\n|\r|\n/g,
                '<br>',
              );
            }
            loanScenarioService.RecommendationReportStatus(CPACQPSetCopy);
          }
        },
        updateCQPFeesTotal() {
          if (!$scope.CPACQPSet.CQP.EstablishmentFee) {
            $scope.CPACQPSet.CQP.EstablishmentFee = 0;
          }
          if (!$scope.CPACQPSet.CQP.ValuationFee) {
            $scope.CPACQPSet.CQP.ValuationFee = 0;
          }
          $scope.CQPFeesTotal =
            parseInt($scope.CPACQPSet.CQP.EstablishmentFee, 10) +
            parseInt($scope.CPACQPSet.CQP.ValuationFee, 10);
        },
        dataURItoBlob(dataURI, type) {
          const binary = $window.atob(dataURI.split(',')[1]);
          const array = [];
          for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
          }
          return new $window.Blob([new Uint8Array(array)], { type });
        },

        checkSaveTemplateStatus(isChecked) {
          const self = $scope;
          self.isSaveTemplateChecked = isChecked;
          $scope.updateCPACQP();
        },
        getFundsSummary(callback) {
          // eslint-disable-next-line unicorn/consistent-function-scoping
          const errorCallback = (e) => {
            toaster.pop('error', e.Message, e.ExceptionMessage);
          };

          const loanApplicationId = $scope.loanAppId;
          loanScenarioService
            .getFundingCalculatorNewBrokerEventForScenario($scope.loanAppId)
            .then((response) => {
              fundingCalculatorService
                .getFundingCalculatorFundsSummary(
                  response.data,
                  $scope.loanAppId,
                )
                .then((fundingCalculatorFundsSummary) => {
                  const summary = fundingCalculatorFundsSummary;
                  $scope.calculatorFundsSummary = summary;
                  $scope.updateCPACQP();

                  loanScenarioService
                    .loanDetailsGet(loanApplicationId)
                    .then((response3) => {
                      $scope.loanDetails = response3.data;
                      callback(
                        response3.data.LoanFacility,
                        summary,
                        loanApplicationId,
                      );
                    }, errorCallback);
                }, errorCallback);
            }, errorCallback);
        },
        updateLVR(loanFacilities, fundsSummary, loanApplicationId) {
          if (
            typeof loanFacilities !== 'undefined' &&
            loanFacilities.length > 0 &&
            typeof fundsSummary !== 'undefined' &&
            typeof loanApplicationId !== 'undefined'
          ) {
            const loanFacility = loanFacilities.splice(0, 1)[0];
            loanProfilerService
              .updateServiceabilityFilteringProductsSettings(
                loanFacility.BrokerEventId,
                {
                  GeneralSetting: {
                    lender: ['ALL'],
                    loanTerm: loanFacility.LoanTerm,
                    loanAmount: loanFacility.TotalLoanAmount,
                    security: fundsSummary.totalSecurityValue,
                    LVR: fundsSummary.lvr,
                    splitLoan: false,
                  },
                },
              )
              .then(() => {
                $scope.updateLVR(
                  loanFacilities,
                  fundsSummary,
                  loanApplicationId,
                );
              });
          }
        },
        checkCQPPDFLink(CQPPDFLink) {
          if (typeof CQPPDFLink === 'undefined') {
            SweetAlert.swal({
              title: 'Oops!',
              text:
                'PDF Commission Guideline is not available for this lender.',
              type: 'warning',
            });
          }
        },
        setEsignSubscription() {
          if (
            userSubscription &&
            esign &&
            countryId === COUNTRY_TYPE.AUSTRALIA
          ) {
            const { adviserSubscription } = loanAppSharedData;
            $scope.eSignSubscription =
              adviserSubscription &&
              adviserSubscription.find(
                (obj) => obj.subscriptionProductId === SUBSCRIPTIONTYPE.E_SIGN,
              );
          } else {
            $scope.eSignSubscription = {};
          }
          $scope.showEsign = checkEsignAccountVisibility(
            $scope.eSignSubscription,
            currentUserService,
          );
          $scope.toggleDisableNextStep();
        },
        _init() {
          $scope.getSubscription();
          $scope.getCPADocument($scope.updateCPACQP);
          $scope.getFundsSummary($scope.updateLVR);
        },
      });
      // caller
      $scope._init();
    });
