import template from './loanTask.html';
import controller from './loanTaskCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanId: '<',
    familyList: '<',
    opportunityTitle: '<',
  },
};
