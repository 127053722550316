import template from './exitCompliance.html';
import ExitComplianceCtrl from './exitComplianceCtrl';

export default {
  template,
  bindings: {
    familyId: '<',
    permission: '<',
  },
  controller: ExitComplianceCtrl,
  controllerAs: 'vm',
};
