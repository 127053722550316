import { forEach, get } from 'lodash';
import swal from 'sweetalert';
import { isMobileValid } from 'Common/utilities/mobile';
import { LOAN_APP_FACT_FIND } from 'Common/constants/performance';
import { OK } from 'Common/constants/httpStatusCodes';
import { GENERIC_CONTACT_SUPPORT } from 'Common/constants/promptMessages';
import { performance } from 'Common/utilities/perfume';
import { performanceTimingData } from 'Common/utilities/dataLayer';

class OpenOnlineFactFindForm {
  constructor(
    $q,
    $uibModalInstance,
    $state,
    loanScenarioService,
    loanScenarioModelService,
    $uibModal,
    loanId,
    selectedClientId,
    optionsService,
    configService,
    broadcastDataService,
    currentUserService,
    countryCodeService,
    userAccountModelService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$uibModalInstance = $uibModalInstance;
    this.$state = $state;
    this.loanScenarioService = loanScenarioService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.$uibModal = $uibModal;
    this.loanId = loanId;
    this.selectedClientId = selectedClientId;
    this.optionsService = optionsService;
    this.broadcastDataService = broadcastDataService;
    this.configService = configService;
    this.currentUserService = currentUserService;
    this.factFindMessageRequired =
      configService.feature.factFindMessageRequired || 0;
    this.countryCodeService = countryCodeService;
    this.userAccountModelService = userAccountModelService;
  }

  $onInit() {
    this.involvedParty = [];
    this.applicant = {};
    this.applicantEmail = {};
    this.applicantMobileNumber = {};
    this.checkEmail = null;
    this.checkMobile = null;
    this.clientMessage = '';
    this.isUpdateApplicant = false;
    this.isSending = false;
    this.initializeInternationalPhoneFlag();
    this.getInvolveParty();
    this.getCountryList();
    this.isMobileValid = isMobileValid;
    this.isOktaCheckEmailLoading = this.oktaAuthFlow;
  }

  initializeInternationalPhoneFlag() {
    this.isInternationalPhoneNumbers = this.configService.feature.internationalPhoneNumbers;
    this.isMobileInputDropdown =
      this.currentUserService.isNZ &&
      this.configService.feature.phoneNumberCountryCodeNZ;
    this.isIntlPhoneNumbersEnabled =
      this.isInternationalPhoneNumbers || this.isMobileInputDropdown;
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  getCountryList() {
    this.optionsService.countryListGet().then(
      (response) => {
        this.countryList = response.data || [];
      },
      () => {
        this.countryList = [];
      },
    );
  }

  submitApplyOnline() {
    if (this.applicant.isOktaEmailExist || this.isOktaCheckEmailLoading) {
      return;
    }
    performance.start(LOAN_APP_FACT_FIND);
    const checkIfContentExist =
      this.applicant &&
      this.applicant.data &&
      this.applicantMobileNumber &&
      this.applicantMobileNumber.Number &&
      this.applicantEmail &&
      this.applicantEmail.EmailAddress;
    if (checkIfContentExist) {
      const postData = {
        loanApplicationId: parseInt(this.loanId, 10),
        clientId: parseInt(this.applicant.data.PersonId, 10),
        familyId: parseInt(this.applicant.FamilyId, 10),
        eMail: this.applicantEmail.EmailAddress || '',
        sms: this.applicantMobileNumber.Number || '',
        clientMessage: this.clientMessage || '',
      };
      this.setSending(true);
      this.loanScenarioModelService.share(postData).then(
        (response) => {
          this.broadcastDataService.reloadLendingLoanList &&
            this.broadcastDataService.reloadLendingLoanList();

          this.setSending(false);
          this.$uibModalInstance.close({
            factFindSend: true,
            factFindType: '',
          });
          const isSuccess = response && response.data && response.status === OK;
          if (isSuccess) {
            this.$uibModal.open({
              templateUrl:
                '/assets/views/loanApplication/sidebar/partial/applyOnlineSent.html',
              controller: 'ApplyOnlineSentCtrl',
              size: 'md',
            });
          } else {
            swal({
              title: 'Failed to send invite!',
              text: get(response, 'data.Message', GENERIC_CONTACT_SUPPORT),
              type: 'error',
              showCancelButton: false,
            });
          }
        },
        () => {
          this.setSending(false);
          this.cancel();
        },
      );
    }
    const duration = performance.endPaint(LOAN_APP_FACT_FIND);
    performanceTimingData({
      action: LOAN_APP_FACT_FIND,
      label: 'full',
      value: duration,
    });
  }

  openClientEditModal(isChild) {
    const countryList = this.countryList;
    if (this.applicant && this.applicant.data && this.applicant.data.PersonId) {
      const applicantClientId = this.applicant.data.PersonId;
      const appplicantFamilyId = this.applicant.FamilyId;
      const modalInstance = this.$uibModal.open({
        templateUrl: '/assets/views/contacts/partials/add_client_modal_v2.html',
        controller: 'AddClientModalCtrl',
        size: 'lg',
        resolve: {
          countryList() {
            return countryList;
          },
          isChild() {
            return isChild;
          },
          clientId() {
            return applicantClientId;
          },
          applicantFamilyId() {
            return appplicantFamilyId;
          },
        },
      });
      modalInstance.result.then((modalResponse) => {
        if (modalResponse.updateApplicant) {
          this.isUpdateApplicant = true;
          this.getInvolveParty();
        }
      });
    }
  }

  setApplicant() {
    const isApplicant =
      this.involvedParty &&
      this.involvedParty.length &&
      !this.isUpdateApplicant;

    const getApplicantWithId = (id) => {
      const involvedParty = get(this, 'involvedParty', []);
      return involvedParty.find((obj) => obj.data.PersonId === id);
    };

    if (isApplicant) {
      this.applicant = this.selectedClientId
        ? getApplicantWithId(this.selectedClientId)
        : this.involvedParty[0];
      this.changeApplicant();
    }
    if (this.isUpdateApplicant) {
      this.applicant = getApplicantWithId(this.applicant.data.PersonId);
      this.changeApplicant();
      this.isUpdateApplicant = false;
    }
  }

  getInvolveParty() {
    if (this.loanId) {
      this.isOktaCheckEmailLoading = this.oktaAuthFlow;
      this.loanScenarioService
        .scenarioApplicantListGet(this.loanId)
        .then((response) => {
          if (response.data && response.data.InvolvedPartyPerson) {
            this.involvedParty = [];
            forEach(response.data.InvolvedPartyPerson, (val, key) => {
              val.forEach((v) => {
                this.involvedParty.push({
                  FamilyId: key,
                  data: v,
                });
              });
            });
            this.checkEmailExistInOkta();
          }
        });
    }
  }

  checkEmailExistInOkta() {
    if (!this.isUpdateApplicant) {
      const promises = this.involvedParty.reduce((initial, applicant) => {
        const email = get(applicant, 'data.Email[0].EmailAddress', null);
        return email
          ? [...initial, this.userAccountModelService.getEmailExist(email)]
          : initial;
      }, []);
      this.$q.all(promises).then((responses) => {
        if (!get(responses, 'length')) {
          return this.setApplicant();
        }
        responses.map((response) => {
          const { isOktaEmailExist, email } = response;
          this.involvedParty = this.involvedParty.map((applicant) => {
            const applicantEmail = get(applicant, 'Email.[0].EmailAddress', '');
            if (applicantEmail === email) {
              applicant.isOktaEmailExist = isOktaEmailExist;
            }
            return applicant;
          });
        });
        this.isOktaCheckEmailLoading = false;
        this.setApplicant();
      });
    } else {
      this.setApplicant();
    }
  }

  disableSubmit() {
    return (
      !this.applicant ||
      this.applicant.isOktaEmailExist ||
      this.isOktaCheckEmailLoading ||
      !this.applicantMobileNumber ||
      !this.applicantMobileNumber.Number ||
      !this.applicantEmail ||
      !this.applicantEmail.EmailAddress ||
      !this.isValidMobile ||
      this.isSending ||
      (!this.clientMessage && this.factFindMessageRequired)
    );
  }

  isInterNationalMobileNumberValid(isPhoneNumberValid) {
    const isMobileNumberValid =
      !isPhoneNumberValid || this.isIntlPhoneNumbersEnabled;
    if (isMobileNumberValid) {
      return true;
    }
    return this.isMobileValid(this.applicantMobileNumber.Number);
  }

  changeApplicant() {
    if (this.applicant) {
      this.checkEmail = null;
      this.checkMobile = null;
      this.applicantEmail =
        this.applicant.data &&
        this.applicant.data.Email &&
        this.applicant.data.Email.find((obj) => obj.Type === 'Email');
      this.applicantMobileNumber =
        this.applicant.data &&
        this.applicant.data.Phone &&
        this.applicant.data.Phone.find((obj) => obj.Type === 'Mobile');
      this.checkEmail =
        this.applicant &&
        this.applicantEmail &&
        this.applicantEmail.EmailAddress;
      const isPhoneNumberValid =
        this.applicantMobileNumber && this.applicantMobileNumber.Number;
      this.checkMobile = this.applicant && isPhoneNumberValid;
      this.isValidMobile =
        this.factFindMessageRequired ||
        this.isInterNationalMobileNumberValid(isPhoneNumberValid);
    }
  }

  setSending(bool) {
    this.isSending = bool;
  }
}
export default OpenOnlineFactFindForm;
