import angular from 'angular';
import commissionLanding from './commissionLanding';
import commissionSearch from './commissionSearch';
import commissionPayments from './commissionPayments';
import commissionEnquiryFormModal from './commissionEnquiryFormModal';

export default angular
  .module('commission.scenes.commissionManagement.scenes', [commissionLanding])
  .component('commissionSearch', commissionSearch)
  .component('commissionPayments', commissionPayments)
  .component('commissionEnquiryFormModal', commissionEnquiryFormModal).name;
