import swal from 'sweetalert';

import { COLOR } from 'Common/constants/colors';
import { catchError } from 'Common/utilities/promise';
import { toastSuccess, toastError } from 'Common/utilities/alert';

export default class CommissionHelperService {
  constructor(
    $uibModal,
    contactService,
    commissionService,
    downloadDataService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.contactService = contactService;
    this.commissionService = commissionService;
    this.downloadDataService = downloadDataService;
  }

  getUnmatchedCommissionColumns() {
    this.unmatchedCommissionColumns = [
      {
        label: 'Date Processed',
        key: 'dateProcessed',
        isSortable: true,
        sortKey: 'ProcessDate',
      },
      {
        label: 'Adviser',
        key: 'adviser',
        isSortable: true,
        sortKey: 'Adviser',
      },
      {
        label: 'Payee',
        key: 'payee',
        isSortable: true,
        sortKey: 'Payee',
      },
      {
        label: 'Client',
        key: 'client',
        isSortable: true,
        sortKey: 'Client',
      },
      {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        label: 'Loan Amount',
        key: 'loanAmount',
        isCurrency: true,
      },
      {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        label: 'Commission Amount',
        key: 'commissionAmount',
        isCurrency: true,
      },
      {
        label: 'Provider',
        key: 'provider',
        hasLogo: true,
        isSortable: true,
        sortKey: 'Provider',
      },
      {
        label: 'Type',
        key: 'type',
        isSortable: true,
        sortKey: 'Type',
      },
      {
        label: 'Payment Reference #',
        key: 'paymentReferenceNo',
        isSortable: true,
        sortKey: 'DatePaid',
      },
    ];
    return this.unmatchedCommissionColumns;
  }

  getSearchCommissionColumns() {
    this.searchCommissionColumns = [
      {
        label: 'Date Processed',
        key: 'dateProcessed',
        isSortable: true,
        sortKey: 'Date',
      },
      {
        label: 'Adviser',
        key: 'adviser',
        isSortable: true,
        sortKey: 'Adviser',
      },
      {
        label: 'Payee',
        key: 'payee',
        isSortable: true,
        sortKey: 'Payee',
      },
      {
        label: 'Client',
        key: 'client',
        isSortable: true,
        sortKey: 'Client',
      },
      {
        label: 'Loan Amount',
        key: 'loanAmount',
        subheaderValue: 0,
        isSortable: true,
        sortKey: 'LoanAmount',
        subheader: true,
        isCurrency: true,
      },
      {
        label: 'Commission Amount',
        key: 'commissionAmount',
        subheaderValue: 0,
        isSortable: true,
        sortKey: 'Commission',
        subheader: true,
        subheaderClass: 'commissionAmountSubHeader',
        isCurrency: true,
      },
      {
        label: 'Provider',
        key: 'provider',
        hasLogo: true,
        isSortable: true,
        sortKey: 'Provider',
      },
      {
        label: 'Type',
        key: 'type',
        isSortable: true,
        sortKey: 'Type',
      },
      {
        label: 'Payment Reference #',
        key: 'paymentReferenceNo',
      },
    ];
    return this.searchCommissionColumns;
  }

  getExpectedCommissionColumns() {
    this.expectedCommissionColumns = [
      {
        label: 'Settlement Date',
        key: 'dateSettled',
        isSortable: true,
        sortKey: 'DateSettled',
      },
      {
        label: 'Adviser',
        key: 'adviser',
        isSortable: true,
        sortKey: 'Adviser',
      },
      {
        label: 'Client',
        key: 'client',
        isSortable: true,
        sortKey: 'Client',
      },
      {
        label: 'Loan Amount',
        key: 'loanAmount',
        isCurrency: true,
      },
      {
        label: 'Expected Amount',
        key: 'expectedAmount',
        isCurrency: true,
      },
      {
        label: 'Provider',
        key: 'provider',
        hasLogo: true,
        isSortable: true,
        sortKey: 'Provider',
      },
      {
        label: 'Type',
        key: 'type',
        isSortable: true,
        sortKey: 'Type',
      },
      {
        label: 'Date Expected',
        key: 'dateExpected',
        isSortable: true,
        sortKey: 'DateExpected',
      },
    ];
    return this.expectedCommissionColumns;
  }

  getPaymentAdviceColumns() {
    this.paymentAdviceColumns = [
      {
        label: 'Date Paid',
        key: 'datePaid',
        isSortable: true,
        sortKey: 'Date',
      },
      {
        label: 'Description',
        key: 'description',
      },
      {
        label: 'Commission Amount',
        key: 'commissionAmount',
        isCurrency: true,
      },
      {
        label: 'Adviser Paid',
        key: 'adviser',
      },
    ];
    return this.paymentAdviceColumns;
  }

  confirmDeleteCommission({ id, onSuccessCallback }) {
    if (!id) {
      return;
    }
    swal(
      {
        title: 'Are you sure?',
        text: 'This commission will be removed from the list.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: COLOR.SWEET_PINK,
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      },
      (isConfirm) => {
        if (isConfirm) {
          return this.contactService
            .loansExpectedCommissionsDelete(id)
            .then(() => {
              toastSuccess('Commission successfully deleted!');
              onSuccessCallback && onSuccessCallback();
            })
            .catch(catchError);
        }
      },
    );
  }

  openCommissionEnquiry({ familyId, loanId, commission }) {
    return this.$uibModal.open({
      templateUrl: 'assets/views/commission/modal/commissionEnquiryModal.html',
      controller: 'CommissionEnquiryModalCtrl',
      controllerAs: 'vm',
      size: 'lg',
      resolve: {
        commission: commission || null,
        loanId: () => loanId,
        familyId: () => familyId,
      },
    });
  }

  openDatePicker(props) {
    return this.$uibModal.open({
      template: `<commission-calendar-modal
        modal-instance="vm.modalInstance"
        date-picker-options="vm.props.datePickerOptions"
        preselect-date="vm.props.preselectDate"
      >
      </commission-calendar-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-calendar-commission',
      size: 'sm',
      resolve: {
        props: () => props,
      },
    });
  }

  exportCommissionTableData(params) {
    if (
      !params ||
      !params.methodName ||
      !this.commissionService[params.methodName]
    ) {
      return;
    }

    return this.commissionService[params.methodName](params.exportParams)
      .then(({ data }) => {
        if (!data || !data.DocumentContent) {
          toastError(`No data for export found.`);
          return;
        }
        this.downloadDataService.download(
          `data:${data.ContentType};base64,${data.DocumentContent}`,
          params.fileName || data.Name,
          data.ContentType,
        );
        toastSuccess(`Sucessfully exported ${params.type} Commissions!`);
        return data;
      })
      .catch(catchError);
  }
}
