import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import { displayError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('pipelineStatusSmall', function pipelineStatusSmall(
      $timeout,
      $window,
      overviewDashboardService,
      pipelineSharedService,
      uiService,
    ) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: { myParam: '=' },
        templateUrl:
          'assets/views/overviewDashboard/directiveTemplates/pipelineStatusSmall.html',

        // eslint-disable-next-line sonarjs/cognitive-complexity
        link(scope) {
          /* Model */
          angular.extend(scope, {
            pipelineSharedService,
          });

          scope.tableMode = true;
          scope.interval = 'allDates';
          scope.slickConfig = { method: {} };
          scope.fixedRateReviewsDropDownList = [];
          scope.fixedRateReviewsDropDowns = {};
          scope.WidgetOptionAdviserList = [];
          scope.widgetOptionAdviser = {};
          scope.pipelineStatSmallData = [];
          scope.fixedRateReviewsMultiAdviserObj = {};
          scope.fixedRateReviewsMultiAdviser = [];
          scope.WidgetOptionGetData = [];
          scope.filteredFixedRateReviewsDropDownList = [];
          scope.outp = [];
          scope.switchsetting2 = {};
          scope.pipelineApplicationStatus = [];
          scope.pipelineApplicationStatusObj = {};
          scope.isNewPipeline = uiService.newPipeline;

          function WidgetOptionAdviserListGet() {
            overviewDashboardService.WidgetOptionAdviserListGet().then(
              (response) => {
                scope.WidgetOptionAdviserList = response.data;
                for (let i = 0; i < scope.WidgetOptionAdviserList.length; i++) {
                  scope.pipelineApplicationStatusObj = {
                    FamilyName: scope.WidgetOptionAdviserList[i].FamilyName,
                    FamilyId: scope.WidgetOptionAdviserList[i].FamilyId,
                    OptionValueId:
                      scope.WidgetOptionAdviserList[i].OptionValueId,
                    OptionDB_Value:
                      scope.WidgetOptionAdviserList[i].OptionDB_Value,
                    ticked: false,
                    WidgetOptionId: scope.WidgetOptionAdviserList[i].OptionId,
                  };

                  scope.pipelineApplicationStatus.push(
                    scope.pipelineApplicationStatusObj,
                  );
                }

                overviewDashboardService
                  .WidgetOptionGet(scope.myParam.Widget_UserID)
                  .then(
                    (res) => {
                      scope.WidgetOptionGetData = res.data;

                      scope.WidgetOptionGetAdviser = {};
                      scope.Advisers = [];
                      for (
                        let j = 0;
                        j < scope.WidgetOptionGetData.length;
                        j++
                      ) {
                        if (
                          parseInt(
                            scope.WidgetOptionGetData[j].WidgetOptionId,
                            10,
                          ) === 3
                        ) {
                          scope.Advisers =
                            scope.WidgetOptionGetData[j].SelectedUerValues;
                          const AdvisersArray = scope.Advisers.split(',');

                          scope.pipelineApplicationStatus = scope.pipelineApplicationStatus.map(
                            (obj) => {
                              if (AdvisersArray.includes(obj.FamilyId)) {
                                obj.ticked = true;
                                return obj;
                              }

                              return obj;
                            },
                          );
                        }
                      }
                    },
                    () => {},
                  );
              },
              () => {},
            );
          }

          function roundItems() {
            const things = $window.document.querySelectorAll('.roundMe');

            // eslint-disable-next-line unicorn/consistent-function-scoping
            function moneyFormat(value) {
              const suffixes = ['', 'K', 'M', 'B', 'T'];
              const suffixNum = Math.floor(`${value}`.length / 3);
              const shortValue = parseFloat(
                (suffixNum !== 0
                  ? value / suffixNum ** 1000
                  : value
                ).toPrecision(2),
              );
              return shortValue + suffixes[suffixNum];
            }

            function responsiveValue(el) {
              let par;
              let origContent;
              if (Array.isArray(el)) {
                _.forEach(el, (elem) => {
                  par = elem.parentNode;
                  origContent = elem.textContent;

                  if (elem.offsetWidth >= par.offsetWidth) {
                    let newSet = origContent.replace('$', '');
                    newSet = newSet.replace(/,/g, '');

                    elem.textContent = `$${moneyFormat(parseInt(newSet, 10))}`;
                  } else {
                    elem.textContent = origContent;
                  }
                });
              }
            }

            responsiveValue(things);
          }

          function FixedRateReviewSmallGet() {
            overviewDashboardService
              .WidgetPipelineStatsTableViewGet(scope.myParam.Widget_UserID)
              .then(
                (response) => {
                  scope.pipelineStatSmallData = response.data;

                  roundItems();
                },
                () => {},
              );
          }

          function widgetOptionGet() {
            overviewDashboardService
              .WidgetOptionGet(scope.myParam.Widget_UserID)
              .then(
                (response) => {
                  scope.WidgetOptionGetData = response.data;

                  for (let i = 0; i < scope.WidgetOptionGetData.length; i++) {
                    if (
                      parseInt(
                        scope.WidgetOptionGetData[i].WidgetOptionId,
                        10,
                      ) === 3
                    ) {
                      scope.Advisers =
                        scope.WidgetOptionGetData[i].SelectedUerValues;
                      const AdvisersArray = scope.Advisers.split(',');

                      scope.pipelineApplicationStatus = scope.pipelineApplicationStatus.map(
                        // eslint-disable-next-line sonarjs/no-identical-functions
                        (obj) => {
                          if (AdvisersArray.includes(obj.FamilyId)) {
                            obj.ticked = true;
                            return obj;
                          }

                          return obj;
                        },
                      );
                    }
                  }
                },
                () => {},
              );
          }

          function saveForTimePeriod(widgetSetObj) {
            scope.OptionToSet = widgetSetObj;
            overviewDashboardService
              .WidgetOptionSet(scope.OptionToSet)
              .then(() => {
                FixedRateReviewSmallGet();
                widgetOptionGet();
              })
              .catch(displayError);
          }

          scope.updateMultiselectFilterData = function (clickedItem) {
            let dataToSend = [];
            scope.MultiOptionSet = {};
            scope.MultiOptionSetForAll = {};
            const itemData = _.find(scope.pipelineApplicationStatus, [
              'ticked',
              true,
            ]);
            if (_.isUndefined(itemData)) {
              _.map(scope.pipelineApplicationStatus, (item) => {
                if (item.FamilyName === clickedItem.FamilyName) {
                  item.ticked = true;
                }
              });
            }
            if (clickedItem.FamilyName !== 'All') {
              if (_.size(scope.pipelineApplicationStatus) !== 0) {
                scope.pipelineApplicationStatus[0].ticked = false;
              }

              dataToSend = scope.pipelineApplicationStatus
                .filter(
                  (item) => item.FamilyName !== 'All' && item.ticked === true,
                )
                .map((obj) => {
                  return { PersonId: obj.FamilyId };
                });

              scope.MultiOptionSet.SelectedUser = dataToSend;
              scope.MultiOptionSet.WidgetOptionId =
                scope.WidgetOptionAdviserList[0].OptionId;
              scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
              scope.MultiOptionSet.Widget_OptionValue =
                scope.WidgetOptionAdviserList[1].OptionValueId;

              saveForTimePeriod(scope.MultiOptionSet);
            } else {
              const all = scope.outp.filter(
                (item) => item.FamilyName === 'All',
              );
              if (all.length > 0 && scope.outp.length > 1) {
                scope.pipelineApplicationStatus = scope.pipelineApplicationStatus.map(
                  (obj) => {
                    if (obj.FamilyName === 'All') {
                      return obj;
                    }

                    obj.ticked = false;
                    return obj;
                  },
                );
              }
              dataToSend.push({
                PersonId: scope.pipelineApplicationStatus[0].FamilyId,
              });

              scope.MultiOptionSetForAll.SelectedUser = dataToSend;
              scope.MultiOptionSetForAll.WidgetOptionId =
                scope.WidgetOptionAdviserList[0].OptionId;
              scope.MultiOptionSetForAll.Widget_UserID =
                scope.myParam.Widget_UserID;
              scope.MultiOptionSetForAll.Widget_OptionValue =
                scope.WidgetOptionAdviserList[0].OptionValueId;

              saveForTimePeriod(scope.MultiOptionSetForAll);
            }
          };

          scope.doAction = function (msg) {
            if (msg === 'edit') {
              scope.customizeMode = true;
            } else {
              scope.customizeMode = false;
            }
            if (msg === 'notEdit') {
              scope.customizeMode = false;
            } else {
              scope.customizeMode = true;
            }
          };

          function init() {
            FixedRateReviewSmallGet();
            WidgetOptionAdviserListGet();
          }

          scope.startRounding = function () {
            $timeout(() => {
              roundItems();
            }, 1000);
          };

          $($window.document).ready(() => {
            $timeout(() => {
              roundItems();
            }, 2000);
            $timeout(() => {
              roundItems();
            }, 5000);
            $timeout(() => {
              roundItems();
            }, 10000);
          });
          init();
        },
      };
    });
