import angular from 'angular';

class SetCreditChecksModalCtrl {
  constructor($uibModalInstance, selectedContact, clientsList) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.selectedContact = selectedContact;
    this.clientsList = clientsList;
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .controller('SetCreditChecksModalCtrl', SetCreditChecksModalCtrl);
