import template from './leadsToApplicationModal.html';
import controller from './leadsToApplicationModalCtrl';

export default {
  template,
  bindings: {
    loans: '<',
    leadData: '<',
    modalObject: '<',
  },
  controller,
  controllerAs: 'vm',
};
