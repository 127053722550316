import swal from 'sweetalert';
import { displayWarning } from 'Common/utilities/alert';

class ReferrerConnectCtrl {
  constructor(crmConfirmation, connectService) {
    'ngInject';

    this.crmConfirmation = crmConfirmation;
    this.connectService = connectService;
  }

  sendWelcomeEmail(email) {
    if (!email) {
      displayWarning(
        'No input for email address. Cannot send email.',
        'Warning',
        false,
      );
      return;
    }

    if (!this.familyId || this.isSendingEmail) {
      return;
    }
    this.isSendingEmail = true;
    this.connectService
      .welcomeEmail(this.familyId)
      .then((response) => {
        if (response.data) {
          this.crmConfirmation.open({
            type: 'success',
            title: 'Welcome Email Sent',
            description: '',
            buttonText: 'Okay got it!',
          });
        } else {
          swal('Sending Failed', 'Error in sending welcome email.', 'error');
        }
        this.isSendingEmail = false;
      })
      .catch(() => {
        swal('Sending Failed', 'Error in sending welcome email.', 'error');
        this.isSendingEmail = false;
      });
  }

  resetPassword() {
    if (!this.familyId || this.isResettingPassword) {
      return;
    }
    this.isResettingPassword = true;
    this.connectService
      .resetPassword(this.familyId)
      .then((response) => {
        if (response.data) {
          this.crmConfirmation.open({
            type: 'success',
            title: 'Password has been reset',
            description: '',
            buttonText: 'Okay got it!',
          });
        } else {
          swal('Resetting Failed', 'Error in resetting password.', 'error');
        }
        this.isResettingPassword = false;
      })
      .catch(() => {
        swal('Resetting Failed', 'Error in resetting password.', 'error');
        this.isResettingPassword = true;
      });
  }
}

export default ReferrerConnectCtrl;
