import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('ForProductDetailModalCtrl', function ForProductDetailModalCtrl(
      $scope,
      $uibModalInstance,
      product,
      loanStructure,
      loanProfilerService,
      configService,
    ) {
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.product = product;
      $scope.loanStructure = loanStructure;
      $scope.showTMD = configService?.feature?.targetMarketDetermination;

      loanProfilerService
        .getProductFullDetails(product.ProductID)
        .then((response) => {
          $scope.singleProductDetail = response.data;
        });
    });
