import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('referrals', function referrals() {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/referrals.html',
      link(scope) {
        // eslint-disable-next-line unicorn/consistent-function-scoping
        function init() {}
        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'commissionReceivedChartPopover.html',
          title: 'Title',
        };

        scope.doAction = function () {};

        init();
      },
    };
  });
