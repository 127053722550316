import template from './businessEmailMailHeader.html';
import controller from './businessEmailMailHeaderCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    selectedType: '<',
    onChangeType: '&',
    onSearch: '&',
  },
};
