import { toastSuccess } from 'Common/utilities/alert';

export default class AccreditationContactModalCtrl {
  constructor($window, ckEditorConfigService, accreditationService) {
    'ngInject';

    this.$window = $window;
    this.ckEditorConfigService = ckEditorConfigService;
    this.accreditationService = accreditationService;
  }

  $onInit() {
    this.subject = 'Accreditation Inquiry';
    this.options = this.ckEditorConfigService.getCustomerCareEmailEditorConfig();
    this.content = `${this.template}`;
  }

  dismiss() {
    this.modalInstance.dismiss();
  }

  onSendEmail() {
    this.sending = true;
    const { subject, content } = this;
    this.accreditationService
      .sendAccreditationEmail({ subject, content })
      .then(() => {
        toastSuccess('Inquiry Successfully Sent!');
        this.dismiss();
      });
  }
}
