export default class EducationQualifications {
  constructor(loanToolsService) {
    'ngInject';

    this.loanToolsService = loanToolsService;
    this.childComponentNotificationRegistration = this.childComponentNotificationRegistration.bind(
      this,
    );
  }

  $onInit() {
    this.CPDPointTab = '0';
    this.QualificationsTab = '1';

    this.activeTab = '0';

    this.childComponentEventHandler = {
      mainEventHandler: null,
    };

    this.getQualificationPresetList();
  }

  childComponentNotificationRegistration(handler, handlerType) {
    this.childComponentEventHandler[handlerType] = handler;
  }

  onTabChange(tabIdx) {
    this.activeTab = tabIdx;
  }

  onOpenModal() {
    if (!this.childComponentEventHandler.mainEventHandler) {
      return;
    }

    this.childComponentEventHandler.mainEventHandler('openModal');
  }

  getQualificationPresetList() {
    return this.loanToolsService
      .resourceQualificationPresetListGet()
      .then((data) => {
        this.presetList = data;
      });
  }
}
