import { orgPipelineStatusForUI } from 'Common/mappers/orgPipelineStatus';

class OrgPipelineStatusService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'pipeline-custom-status';
  }

  getOrgPipelineStatuses(isCached) {
    return this.httpClient
      .get(this.apiBaseUrl, null, isCached, false)
      .then(({ data }) => {
        return (data || []).map(orgPipelineStatusForUI);
      });
  }
}

export default OrgPipelineStatusService;
