import {
  NOTES_TABLE_SIZE,
  DEFAULT_NOTES_SIZE,
} from 'Common/constants/pageTable';

export const openNoteFromTable = ($scope) => ({ note, action = 'edit' }) => {
  $scope.openCreateNoteModal(action, note);
};

export const deleteNoteFromTable = ($scope) => ({ note, $event }) => {
  $event.stopPropagation();
  $scope.deleteNote(note);
};

export const onPageChange = ({ $scope, contactsNotesService }) => ({
  pageNumber,
  pageSize,
}) => {
  $scope.currentPage = pageNumber || 1;
  $scope.defaultNotesCount = pageSize || $scope.defaultPageSize;
  $scope.loadMoreHandler();
  if (!pageSize) {
    return;
  }
  contactsNotesService.setDisplayCount({ displayCount: pageSize });
};

export const search = ({ $scope, $filter }) => (searchKey) => {
  if (!$scope.displayTabledNotes) {
    return;
  }
  $scope.currentPage = 1;
  if (!searchKey) {
    $scope.noteListData = [...$scope.notesRaw];
    $scope.noteListCount = $scope.noteListCountRaw;
    return;
  }
  $scope.noteListData = $filter('filter')($scope.notesRaw, searchKey);
  $scope.noteListCount = $scope.noteListData && $scope.noteListData.length;
};
export const openNotesSearch = ($scope) => () => {
  $scope.searchNotes = '';
  $scope.openSearchBar = !$scope.openSearchBar;
  $scope.noteListData = [...$scope.notesRaw];
  $scope.noteListCount = $scope.noteListCountRaw;
};

export const reloadOnRemove = ($scope) => {
  if (!$scope.displayTabledNotes) {
    return;
  }
  $scope.notesRaw = $scope.noteListData;
  if ($scope.currentPage > 1 && !$scope.noteListData.length) {
    $scope.currentPage--;
  }
  $scope.getNotesList();
};

export const initializeNotesDetails = ({
  $scope,
  $filter,
  configService,
  contactsNotesService,
}) => {
  $scope.displayTabledNotes =
    configService.feature.redesignedExpandedNotesDisplay;
  $scope.currentPage = 1;
  $scope.openSearchBar = false;
  $scope.defaultPageSize = $scope.displayTabledNotes ? DEFAULT_NOTES_SIZE : 10;
  $scope.defaultNotesCount = $scope.defaultPageSize;
  $scope.tableSizes = NOTES_TABLE_SIZE;
  $scope.openNoteFromTable = openNoteFromTable($scope);
  $scope.deleteNoteFromTable = deleteNoteFromTable($scope);
  $scope.onPageChange = onPageChange({ $scope, contactsNotesService });
  $scope.search = search({ $scope, $filter });
  $scope.openNotesSearch = openNotesSearch($scope);
};
