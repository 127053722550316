import { ASC } from '../../constants/pageConstants';
import * as listUtils from '../../utils/listUtils';

export default class Education {
  constructor(
    modalRenderService,
    loanToolsService,
    generalService,
    downloadDataService,
  ) {
    'ngInject';

    this.modalRenderService = modalRenderService;
    this.loanToolsService = loanToolsService;
    this.generalService = generalService;
    this.downloadDataService = downloadDataService;

    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });

    this.currentPage = 1;
    this.headerList = [
      { label: 'Date', key: 'date' },
      { label: 'Course Title', key: 'title' },
      { label: 'Provider', key: 'provider' },
      { label: 'Structured Hours', key: 'structured' },
      { label: 'Unstructured', key: 'unstructured' },
      { label: 'Attachment', key: 'attachment' },
    ];

    this.getEducationList();

    this.sortOrder = ASC;
  }

  processParentNotification(eventHandler) {
    listUtils.processParentNotification(
      this,
      eventHandler,
      'openCPDPointsModal',
    );
  }

  getEducationList() {
    return listUtils.getList(this, 'resourceEducationGet');
  }

  onSortDate() {
    listUtils.onSortDate(this, 'getEducationList');
  }

  setDownloading(id, isDownloading) {
    listUtils.setDownloading(this, id, isDownloading);
  }

  onDownloadDocument($e, education) {
    listUtils.onDownloadDocument(this, $e, education);
  }

  openCPDPointsModal(education = { id: 0 }) {
    const props = { education };
    return listUtils.openModal({
      vm: this,
      props,
      modalFn: 'openCPDPointsModal',
      successMessage: 'CPD Point Saved.',
      getFn: 'getEducationList',
    });
  }

  onEditClick($e, education) {
    listUtils.onEditClick(this, $e, education, 'openCPDPointsModal');
  }

  onDeleteClick($e, id) {
    listUtils.onDeleteClick({
      vm: this,
      $e,
      id,
      deleteFn: 'resourceEducationDelete',
      successMessage: 'CPD Point Deleted.',
      getFn: 'getEducationList',
    });
  }
}
