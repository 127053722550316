import { futureFinancialGoalBuilderForUI } from 'Common/mappers/contact';

class FinancialGoalModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'financial-goal';
  }

  getFinancialGoalType() {
    return this.httpClient.get(`${this.apiBaseUrl}/type`);
  }

  getFutureFinancialGoalCategory(familyId, isAU) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/type`)
      .then(
        ({ data }) =>
          data.map((goal) => futureFinancialGoalBuilderForUI(goal, isAU)) || [],
      );
  }

  getDisclaimer() {
    return this.httpClient.get(`${this.apiBaseUrl}/disclaimer`);
  }
}

export default FinancialGoalModelService;
