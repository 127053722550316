import { get } from 'lodash';

export const fieldInvalid = (form) => (field) => {
  if (!form || !form.$submitted) {
    return false;
  }
  return !!(form[field] && form[field].$invalid);
};

export const getFormErrorMessage = (form) => {
  const defaultValidationMessage = 'Please check required fields';
  const maskOnlyValidationMessage = 'Please check invalid formats';
  const hasRequiredError = get(form, '$error.required.length');
  const hasMaskError = get(form, '$error.mask.length');
  return !hasRequiredError && hasMaskError
    ? maskOnlyValidationMessage
    : defaultValidationMessage;
};
