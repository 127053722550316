import template from './mycrmDropdown.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    dropdownText: '<',
    buttonIconClass: '<',
    menuItems: '<',
    isDisabled: '<',
    onMenuItemClick: '&',
  },
};
