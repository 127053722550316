import angular from 'angular';
import _ from 'lodash';
import {
  INSURANCE_QUOTE_REPORT_SECTION,
  INSURANCE_OPTIONS,
  INSURANCE_TYPES,
} from 'Common/constants/insuranceOptions';
import { showActionConfirmation, toastError } from 'Common/utilities/alert';
import {
  emailQuoteReport,
  getBrochureParams,
  getBrochures,
} from './util/quoteReportPDFCtrl';

const SEND_EMAIL_VIEW_STRING = 'sendViaEmailView';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('QuoteReportPDFCtrl', function QuoteReportPDFCtrl(
      $scope,
      $window,
      $state,
      $uibModalInstance,
      insuranceQuoteService,
      $stateParams,
      reportPDFdata,
      quoteData,
      crmConfirmation,
      broadcastDataService,
      helloBookPreviewService,
      configService,
      uiService,
    ) {
      /** ********************************************** */
      $scope.reportSection = INSURANCE_QUOTE_REPORT_SECTION;
      $scope.reportType = INSURANCE_TYPES.QUICK_QUOTE;
      $scope.reportPDFdata = reportPDFdata;
      $scope.quoteData = quoteData;
      $scope.uiService = uiService;
      $scope.isOnlineInsuranceAppActive =
        configService.feature.onlineInsuranceApplication;

      $scope.reportSettings = {
        ReportOptions: {
          ProviderID: 0,
          RecommendationReason: 'string',
          SaveClientDocument: 1,
          Sections: [
            INSURANCE_QUOTE_REPORT_SECTION.PREMIUM_COMPARISON_GRAPH,
            INSURANCE_QUOTE_REPORT_SECTION.ONLINE_APP_LINK,
          ],
        },
        ProviderOutput: {
          ProviderResult: [],
        },
      };

      $scope.tempReportSettings = {
        Sections: {},
      };
      let uniqByidOfReasons = [];
      /** ********************************************** */
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.storeMatchingReasons = [];
      angular.extend($scope, {
        insurerProviderID: 0,
        selectInsurerList: reportPDFdata.insurersList,
        implementationReport: true,
        recommendationReasonsGet(insurerProviderID) {
          $scope.disableInsurerRecommendation = false;

          const peopleEntityBenefits = _.flatMap(
            $scope.peopleEntity,
            (client) => client.BenefitList,
          );
          $scope.disableHealthInsuranceExcess = !peopleEntityBenefits.find(
            (benefit) =>
              parseInt(benefit.BenefitId, 10) ===
              INSURANCE_OPTIONS.BENEFIT_TYPE.HEALTH_COVER,
          );
          $scope.disableWOPBenefits = !peopleEntityBenefits.find((benefit) => {
            const benefitId = parseInt(benefit.BenefitId, 10);
            return (
              benefitId === INSURANCE_OPTIONS.BENEFIT_TYPE.MORTAGE_REPAYMENT ||
              benefitId === INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION
            );
          });
          $scope.validateRecommendationReasonsContent();

          insuranceQuoteService
            .insuranceRecommendationGet(insurerProviderID)
            .then((response) => {
              $scope.recommendationReasonsList = response.data;
              if ($scope.recommendationReasonsList.length) {
                const reasonData = uniqByidOfReasons
                  .map((uniqueReason) => {
                    const recommendationReason = $scope.recommendationReasonsList.find(
                      (reason) => reason.ID === uniqueReason.id,
                    );
                    return recommendationReason.Content || '';
                  })
                  .join('');
                $scope.recommendationReasonsContent = reasonData;
              }
            });

          const insurerRecommendationSectionEnabled = !!$scope.insurerProviderID;
          $scope.tempReportSettings.Sections[
            INSURANCE_QUOTE_REPORT_SECTION.INSURER_RECOMMENDATION
          ] = insurerRecommendationSectionEnabled;
          $scope.reportSettingsSectionsSet(
            INSURANCE_QUOTE_REPORT_SECTION.INSURER_RECOMMENDATION,
          );
        },
        recommendationReasonsContentGet() {
          $scope.validateRecommendationReasonsContent();
          _.forEach($scope.recommendationReasonsList, (data) => {
            if (data.ID === $scope.recommendationReasonsID) {
              $scope.storeMatchingReasons.push({ id: data.ID });
              $scope.appendRecommendationReasonsContent(data.Content);
            }
          });
          uniqByidOfReasons = _.uniqBy($scope.storeMatchingReasons, 'id');
        },
        appendRecommendationReasonsContent(dataContent) {
          const currentContent = (
            $scope.recommendationReasonsContent || ''
          ).trim();

          const newContent = currentContent
            ? `\n\n${dataContent}`
            : dataContent;

          $scope.recommendationReasonsContent = `${currentContent}${newContent}`;
        },
        validateRecommendationReasonsContent() {
          if (!$scope.recommendationReasonsContent) {
            $scope.clearReasonsArrays();
          }
        },
        clearReasonsArrays() {
          $scope.storeMatchingReasons = [];
          uniqByidOfReasons = [];
        },
        reportSettingsSectionsSet(correspondingValue) {
          const booleanValue =
            $scope.tempReportSettings.Sections[correspondingValue];
          const { ReportOptions: reportOptions } = $scope.reportSettings;
          if (booleanValue) {
            const hasCorrespondingValue = reportOptions.Sections.includes(
              correspondingValue,
            );
            if (!hasCorrespondingValue) {
              reportOptions.Sections.push(correspondingValue);
            }
          } else {
            reportOptions.Sections = reportOptions.Sections.filter(
              (obj) => obj !== correspondingValue,
            );
            if (
              correspondingValue ===
              INSURANCE_QUOTE_REPORT_SECTION.INSURER_RECOMMENDATION
            ) {
              $scope.disableInsurerRecommendation = true;
              $scope.disableWOPBenefits = true;
              $scope.disableHealthInsuranceExcess = true;
              $scope.tempReportSettings.Sections[
                INSURANCE_QUOTE_REPORT_SECTION.WOP_BENEFITS
              ] = false;
              $scope.tempReportSettings.Sections[
                INSURANCE_QUOTE_REPORT_SECTION.HEALTH_INSURANCE_EXCESS
              ] = false;
              $scope.preCheckReportSettings();
              $scope.clearInsurerFields();
              $scope.clearInputReasonOnly();
              $scope.clearReasonsArrays();
            }
          }
        },
        clearInputReasonOnly() {
          $scope.recommendationReasonsContent = null;
          $scope.recommendationReasonsID = null;
        },
        clearInsurerFields() {
          $scope.insurerProviderID = null;
          $scope.recommendationReasonsID = null;
          $scope.recommendationReasonsContent = null;
        },
        generateDownloadPDF() {
          $scope.reportSettings.quoteId = $scope.reportPDFdata.quoteId;
          $scope.reportSettings.ReportOptions.ProviderID =
            $scope.insurerProviderID;
          $scope.reportSettings.ReportOptions.ProviderName = $scope.getInsurerProviderName(
            $scope.insurerProviderID,
          );
          $scope.reportSettings.ReportOptions.RecommendationReason =
            $scope.recommendationReasonsContent;
          $scope.reportSettings.ProviderOutput.ProviderResult =
            $scope.selectInsurerList;
          $scope.reportSettings.ReportOptions.InsuranceFileId =
            $scope.reportPDFdata.insuranceFileId;
        },
        changeCurrentView(viewType, viewHeaderTitle, alertForUnsentEmail) {
          const isSendEmailView = viewType === SEND_EMAIL_VIEW_STRING;
          if (isSendEmailView && !$scope.insurerProviderID) {
            return toastError('Please select an insurer');
          }
          if (alertForUnsentEmail) {
            showActionConfirmation(
              'Are you sure?',
              'All the content of your email will be lost.',
              () => {
                $scope.changeCurrentView(
                  'generateReportView',
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  'Insurance Quote Report',
                );
              },
            );
          } else {
            $scope.generateDownloadPDF();
            $scope.currentFormView = viewType;
            $scope.modalHeaderTitle = viewHeaderTitle;

            if (isSendEmailView) {
              $scope.isFetchingAttachments = true;
              const params = getBrochureParams(
                $scope.reportPDFdata.peopleEntity,
                $scope.insurerProviderID,
              );
              getBrochures(insuranceQuoteService, params).then((res) => {
                $scope.generatedReport = res;
                $scope.isFetchingAttachments = false;
              });
            }
          }
        },
        getInsurerProviderName(insurerProviderId) {
          const selectedInsurer = $scope.selectInsurerList.find(
            (insurer) => insurer.ProviderId === insurerProviderId,
          );
          return selectedInsurer && selectedInsurer.ProviderName;
        },
        onSaveClientDocumentChange() {
          const { familyId } = $scope.reportPDFdata;
          $scope.reportSettings.ReportOptions = {
            ...$scope.reportSettings.ReportOptions,
            FamilyID: familyId || 0,
            InsuranceFileId: 0,
          };
          $scope.reportPDFdata.insuranceFileId = 0;
        },
        intializeReportFilters() {
          $scope.disableInsurerRecommendation = true;
          $scope.disableWOPBenefits = true;
          $scope.disableHealthInsuranceExcess = true;
          angular.forEach(
            $scope.reportSettings.ReportOptions.Sections,
            (item) => {
              $scope.tempReportSettings.Sections[item] = true;
            },
          );

          const tempSelectInsurerList = { ...$scope.selectInsurerList };
          $scope.selectInsurerList = [];
          angular.forEach(tempSelectInsurerList, (obj) => {
            if (obj.TotalPremium > 0) {
              $scope.selectInsurerList.push(obj);
            }
          });

          $scope.tempReportSettings.Sections[
            INSURANCE_QUOTE_REPORT_SECTION.INSURER_RECOMMENDATION
          ] = !!$scope.insurerProviderID;
        },
        preCheckReportSettings() {
          if ($scope.disableInsurerRecommendation) {
            const { Sections: sections } =
              $scope.reportSettings && $scope.reportSettings.ReportOptions;
            $scope.reportSettings.ReportOptions.Sections = sections.filter(
              (obj) => {
                return (
                  obj !==
                    INSURANCE_QUOTE_REPORT_SECTION.INSURER_RECOMMENDATION &&
                  obj !== INSURANCE_QUOTE_REPORT_SECTION.WOP_BENEFITS &&
                  obj !== INSURANCE_QUOTE_REPORT_SECTION.HEALTH_INSURANCE_EXCESS
                );
              },
            );
          }
        },
        onReportDownloaded(pdf) {
          const fileName = `${pdf.Name}.pdf`;
          $scope.generatedReport = { fileName };
        },
        // eslint-disable-next-line sonarjs/no-identical-functions
        onReportSent() {
          $scope.changeCurrentView(
            'generateReportView',
            'Insurance Quote Report',
          );
        },
      });

      // models
      angular.extend($scope, {
        currentPage: 1,
        isDownloadingFactFind: false,
        loanAppId: $stateParams.loanAppId,
        peopleEntity: reportPDFdata.peopleEntity,
      });

      // methods
      angular.extend($scope, {
        selectTab(active) {
          angular.extend($scope, {
            active,
          });
        },
        _init() {
          $scope.intializeReportFilters();
          $scope.changeCurrentView(
            'generateReportView',
            'Insurance Quote Report',
          );
          $scope.generatedReport = { fileName: 'Insurer Report.pdf' };

          if ($scope.isOnlineInsuranceAppActive) {
            $scope.favoriteInsurer = JSON.parse(
              $window.localStorage.getItem('favoriteInsurer'),
            );
            $scope.insurerProviderID =
              $scope.favoriteInsurer && $scope.favoriteInsurer.ProviderId;
            if ($scope.insurerProviderID) {
              $scope.recommendationReasonsGet($scope.insurerProviderID);
            }
          }
          $scope.onSaveClientDocumentChange();
        },
        addPreview() {
          if ($scope.currentPage < _.size($scope.Previews) - 1) {
            $scope.currentPage++;
          }
        },
        deductPreview() {
          if ($scope.currentPage > 0) {
            $scope.currentPage--;
          }
        },
        onSelectInsuranceFile({ $e }) {
          $scope.reportPDFdata.insuranceFileId = $e.fileId;
        },
      });

      emailQuoteReport({
        $scope,
        $state,
        reportPDFdata,
        crmConfirmation,
        broadcastDataService,
        helloBookPreviewService,
      });
      // caller
      $scope._init();
    });
