import angular from 'angular';
import $ from 'jquery';
import { get } from 'lodash';

const charAfterdot = (elementValue, $window) => {
  const charAfterdot = elementValue.length + 1 - elementValue.indexOf('.');
  if (charAfterdot > 3) {
    const withSelectedString = $window.getSelection().toString();
    if (withSelectedString) {
      return true;
    }
    return false;
  }
};

export const loadModule = () =>
  angular.module('app').directive('decimalOnly', function decimalOnly($window) {
    return {
      require: '?ngModel',
      link(scope, element) {
        element.on('keypress', function (evt) {
          const elementValue = get(element, '[0].value');
          const event = evt || $window.event;
          const charCode = event.which ? event.which : event.keyCode;
          if (charCode === 8 || charCode === 37) {
            return true;
          } else if (charCode === 46 && $(this).val().includes('.')) {
            return false;
          } else if (
            charCode > 31 &&
            charCode !== 46 &&
            (charCode < 48 || charCode > 57)
          ) {
            return false;
          } else if ($(this).val().indexOf('.') > 0) {
            return charAfterdot(elementValue, $window);
          }
          return true;
        });
      },
    };
  });
