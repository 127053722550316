import { validateLoanForUI } from 'Common/mappers/lodgement';

export default class LodgementService {
  constructor($q, httpClient, uiService) {
    'ngInject';
    this.httpClient = httpClient;
    this.uiService = uiService;
    this.$q = $q;
    this.apiBaseUrl = 'Application/';
  }

  setSubmitDocumentsToLodgement(loanAppId) {
    const url = `${this.apiBaseUrl}${loanAppId}/Lodgement/Residential`;
    return this.httpClient.post(url).then((response) => {
      return response.data;
    });
  }

  getLodgementTargets(loanAppId) {
    const url = `${this.apiBaseUrl}${loanAppId}/Lodgement/Residential`;

    return this.httpClient.get(url).then(({ data }) => {
      return data;
    });
  }

  isApplicationToOnlineProduct(params) {
    const { loanAppId, lenderId } = params;
    const url = `${this.apiBaseUrl}${loanAppId}/Lodgement/Residential`;

    return this.httpClient.get(url).then(({ data }) => {
      return !!(
        data.LodgementTargets &&
        data.LodgementTargets.find(
          (target) => target.LenderId === Number(lenderId),
        )
      );
    });
  }

  getLoanAppValidation(params) {
    const { loanAppId } = params;
    const url = `${this.apiBaseUrl}${loanAppId}/Lodgement/Residential/Validate`;

    return this.httpClient.post(url).then((response) => {
      return {
        apiData: (response.data && validateLoanForUI(response.data)) || [],
      };
    });
  }
}
