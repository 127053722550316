import angular from 'angular';
import { resizeByStyle } from './util/dynamicWidth';

export const loadModule = () =>
  angular
    .module('app')
    .directive('dynamicWidthByText', function dynamicInputWidth() {
      return {
        link(scope, elem, attrs) {
          const textValue = elem.text() || '';
          const charLength = textValue.length;
          resizeByStyle(elem, attrs)(charLength);
        },
      };
    });
