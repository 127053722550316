import template from './main.html';
import controller from './mainCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    accreditationData: '<',
    familyId: '<?',
    isIndividualProfile: '<?',
  },
};
