export const setDefaultValuesForBlankTemplates = (template) => {
  if (!template || template.UserTemplateName !== 'Blank') {
    return template;
  }

  return {
    ...template,
    IsSystemTemplate: false,
    isSharedWithAdviser: false,
  };
};
