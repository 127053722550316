import angular from 'angular';
import workBenchDetails from './workBenchDetails';
import insuranceAppCoverDetails from './insuranceAppCoverDetails';

export default angular
  .module(
    'insuranceAppWorkbench.scenes.insuranceAppWorkbenchModal.scenes.components',
    [],
  )
  .component('insuranceWorkBenchDetails', workBenchDetails)
  .component('insuranceAppCoverDetails', insuranceAppCoverDetails).name;
