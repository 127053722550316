export const CONTACT_TYPE = {
  CLIENT: 0,
  ADVISER: 1,
  ADVISER_ORG: 2,
  ASSISTANT: 3,
  ADMINISTRATOR: 4,
  REFERRER_ORG: 5,
  BUSINESS: 6,
  REFERRER: 9,
  BUSSINESS_PARTNER: 'BusinessPartner',
  DIRECTOR: 'Director',
  SHARE_HOLDER: 'Shareholder',
  PRINCIPAL_ADVISER: 'Principal Adviser',
  ADVISER_STR: 'Adviser',
  LOAN_WRITER_STR: 'Loan Writer',
  ASSISTANT_STR: 'Assistant',
  PARTNER_STR: 'Partner',
};

export const CLIENT_ROLE = {
  ADULT: 'Adult',
  CHILD: 'Child',
};

export const CLIENT_TYPE_DESC = {
  BORROWER: 'Borrower',
  ASSET_SUPPLIER: 'Asset Supplier',
};

export const CONTACT_TYPE_NAME = {
  ADVISER: 'Adviser',
  PRINCIPAL_ADVISER: 'Principal Adviser',
  CORPORATE_USER: 'Corporate User',
  ADMINISTRATOR: 'Administrator',
  SUPER_ADMINISTRATOR: 'Super Administrator',
  REFERRER: 'Referrer',
  CLIENT: 'Client',
  ENTITY: 'Entity',
  BUSINESS: 'Business',
};

export const CLIENT_TYPE_IDS = {
  CLIENTS: 0,
  ENTITIES: 1,
};

export const CONTACT_RECORD_TYPE_IDS = {
  INDIVIDUAL: 1,
  FAMILY: 2,
};
