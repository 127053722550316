class ChangeColorCtrl {
  constructor($rootScope) {
    'ngInject';

    this.$rootScope = $rootScope;
  }

  onChange(color) {
    if (!color || this.color === color) {
      return;
    }
    this.onColorChange({ color });
  }

  $onInit() {
    this.colorpickerOpts = {
      color: this.color,
      showInput: true,
      showInitial: true,
      showPalette: false,
      preferredFormat: 'hex',
      clickoutFiresChange: false,
      containerClassName: `change-color ${this.customClass}`,
      appendTo: this.$rootScope.appBodyElement,
    };
  }
}

export default ChangeColorCtrl;
