import template from './contactAddresses.html';
import controller from './contactAddressesCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    onUpdateAddress: '<',
  },
};
