import {
  MODAL_SECTION,
  DOC_GENERATOR,
  HELPTEXT_GENERATOR,
} from 'Common/constants/generateReports';
import {
  isAssetFinanceCategory,
  isAssetFinanceFlagOn,
} from 'Common/utilities/lendingCategory';
import { GAME_PLAN_SEND_ESIGN } from 'Common/constants/performance';
import { performanceTimingData } from 'Common/utilities/dataLayer';

class CqpCpaSidebarCtrl {
  constructor(
    $rootScope,
    $scope,
    $uibModal,
    $timeout,
    configService,
    loanAppSharedData,
    currentUserService,
    gamePlanGeneratorService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.configService = configService;
    this.loanAppSharedData = loanAppSharedData;
    this.currentUserService = currentUserService;
    this.gamePlanGeneratorService = gamePlanGeneratorService;
  }

  $onInit() {
    this.DOC_GENERATOR = DOC_GENERATOR;
    const {
      generateGamePlan,
      bidLoanAppChangesValidation,
      CPACQPGeneration,
    } = this.configService.feature;

    this.isUserNZ = this.currentUserService.isNZ;
    this.isGamePlanOn = this.isLoanApp && !!generateGamePlan;
    this.isCPACQPGenerationOn = CPACQPGeneration;
    this.isGamePlanOnlyForAU =
      !CPACQPGeneration && this.currentUserService.isAU;
    this.shouldValidateGeneration =
      this.isLoanApp && !!bidLoanAppChangesValidation;
    this.isLoadingCQP = false;
  }

  openRecommendationModal() {
    if (!this.loanAppSharedData.adviserSubscription) {
      return;
    }

    const activeModalSection = this.isUserNZ
      ? MODAL_SECTION.CPA
      : MODAL_SECTION.OPTION_WINDOW;

    const multiLoanCpaIds = null;
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/loanApplication/sidebar/modal/recommendationReport.html',
      controller: 'RecommendationModalCtrl',
      windowClass: 'mc-recommendation-report-modal',
      size: 'md',
      resolve: {
        activeModalSection: () => activeModalSection,
        multiLoanCpaIds: () => multiLoanCpaIds,
        loanAppId: () => this.id,
      },
    });
    modalInstance.result.then((response) => {
      if (!response || !response.closeAll) {
        return;
      }
      this.$rootScope.$broadcast('eSignPackageSent', response.closeAll);
      this.onCloseAll();
    });
  }

  loadCQPData() {
    return this.gamePlanGeneratorService
      .loadCQPData({
        loanAppId: this.id,
      })
      .then((response) => {
        const [cpaCQPResponse, loanDetailsResponse] = response;

        const props = {
          loanAppId: this.id,
          isEditEmailMessage: false,
          applicants: this.applicants,
          loanAdviserId: this.loanAdviserId,
          loanDetails: loanDetailsResponse.loanDetails,
          cpaCqpSet: cpaCQPResponse,
          calculatorFundsSummary: loanDetailsResponse.calculatorFundsSummary,
          isAssetFinance:
            isAssetFinanceFlagOn({ configService: this.configService }) &&
            isAssetFinanceCategory({
              currentUserService: this.currentUserService,
              lendingCategoryId:
                loanDetailsResponse.loanDetails.LendingCategoryId,
            }),
          isAppendedOnly: true,
        };

        this.isLoadingCQP = false;
        this.gamePlanGeneratorService.openCQP(props);
        performanceTimingData({
          event: `game-plan-generate`,
          action: GAME_PLAN_SEND_ESIGN,
        });
      })
      .catch(() => {
        this.isLoadingCQP = false;
      });
  }

  launchCQPModal() {
    this.isLoadingCQP = true;
    this.loadCQPData();
  }

  launchModal(method, isValid) {
    if (method === DOC_GENERATOR.GAME_PLAN) {
      this.isLoadingCQP = isValid;
    } else if (method === DOC_GENERATOR.CPA_CQP) {
      this.isLoadingValidation = false;
    }
    isValid && this[method]();
  }

  validateGeneration(method) {
    if (!this.shouldValidateGeneration) {
      this[method]();
      return;
    }

    if (method === DOC_GENERATOR.GAME_PLAN) {
      this.isLoadingCQP = true;
    } else if (method === DOC_GENERATOR.CPA_CQP) {
      this.isLoadingValidation = true;
    }

    this.validationTimeout = this.$timeout(() => {
      this.onValidate({
        callback: (isValid) => this.launchModal(method, isValid),
        helpText: HELPTEXT_GENERATOR[method],
      });
    }, 500);
  }

  $onDestroy() {
    this.validationTimeout && this.validationTimeout.cancel();
  }
}

export default CqpCpaSidebarCtrl;
