import template from './businessEmailMailTemplates.html';
import controller from './businessEmailMailTemplatesCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    selectedType: '<',
  },
};
