import { ENTITY_PARTY_TYPES } from 'Common/constants/partyTypes';
import { REQUESTER as CONTACT_REQUESTER } from 'Common/constants/addNewContactRequesters';

export const openEditClientModal = ({ $uibModal, countryList, callback }) => (
  e,
  familyId,
  clientId,
  isClient,
  party,
) => {
  e && e.stopPropagation();

  if (isClient) {
    $uibModal
      .open({
        templateUrl: '/assets/views/contacts/partials/add_client_modal_v2.html',
        controller: 'AddClientModalCtrl',
        size: 'lg',
        resolve: {
          countryList: () => countryList,
          isChild: () => false,
          clientId: () => clientId,
          applicantFamilyId: () => familyId,
        },
      })
      .result.then((res) => {
        res && res.updateApplicant && callback();
      });
  } else {
    $uibModal
      .open({
        templateUrl:
          '/assets/views/loanApplication/clientAssess/modal/personCompany.add.html',
        controller: 'AddCompanyRelatedModalCtrl',
        size: 'md',
        resolve: {
          selectedPartyType: () => String(ENTITY_PARTY_TYPES.COMPANY),
          person: () => party,
          requester: () => CONTACT_REQUESTER.NEW_LOAN_APP_MODAL,
          guardianList: () => [],
        },
        backdrop: 'static',
        keyboard: false,
      })
      .result.then((res) => {
        res && callback();
      });
  }
};
