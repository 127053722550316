import template from './opportunitySidebar.html';

export default {
  template,
  bindings: {
    loanId: '<',
    familyList: '<',
    requirementsList: '<',
    onCompleteRequirement: '&',
    onAddRequirementModal: '&',
    opportunityGeneral: '<',
    onChangeGeneralInfo: '&',
    opportunityId: '<',
    familyId: '<',
    currentUserService: '<',
    configService: '<',
    adviserSubscription: '<',
    isShowOpportunityFactFind: '<',
    isLoanAppOnOpportunitiesEnabled: '<',
    isNotAssetFinance: '<',
    onCloseAll: '&',
  },
};
