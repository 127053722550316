export const DEFAULT_INCOME_TYPES = {
  SALARY: 19,
  BONUS: 14,
  COMMISSION: 16,
  RENTAL: 23,
  FOREIGN: 26,
  OTHER_NET_INCOME: 27,
};

export const SUB_INCOME_TYPES = {
  RENTAL: 1,
  BOARDER: 2,
  FLATMATE: 3,
};

export const RENTAL_SUB_INCOME_TYPES = [
  SUB_INCOME_TYPES.RENTAL,
  SUB_INCOME_TYPES.BOARDER,
  SUB_INCOME_TYPES.FLATMATE,
];

export const OPPORTUNITY_SERVICEABILITY_INCOME_TYPES = {
  SALARY: 12,
  BASE_INCOME: 20,
};
