import template from './fundingAvailable.html';
import controller from './fundingAvailableCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    lendingCategoryId: '<',
  },
};
