import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('realTimeCurrency', function realTimeCurrency(
      $filter,
      $locale,
      $window,
    ) {
      const decimalSep = $locale.NUMBER_FORMATS.DECIMAL_SEP;
      const toNumberRegex = new RegExp(`[^0-9\\${decimalSep}]`, 'g');
      const filterFunc = function (value) {
        return $filter('currency')(value);
      };

      function getCaretPosition(input) {
        if (!input) {
          return 0;
        }
        if (input.selectionStart !== undefined) {
          return input.selectionStart;
        } else if ($window.document.selection) {
          // Curse you IE
          input.focus();
          const selection = $window.document.selection.createRange();
          selection.moveStart(
            'character',
            input.value ? -input.value.length : 0,
          );
          return selection.text.length;
        }
        return 0;
      }

      // eslint-disable-next-line unicorn/consistent-function-scoping
      function setCaretPosition(input, pos) {
        if (!input) {
          return 0;
        }
        if (input.offsetWidth === 0 || input.offsetHeight === 0) {
          return; // Input's hidden
        }
        if (input.setSelectionRange) {
          input.focus();
          input.setSelectionRange(pos, pos);
        } else if (input.createTextRange) {
          // Curse you IE
          const range = input.createTextRange();
          range.collapse(true);
          range.moveEnd('character', pos);
          range.moveStart('character', pos);
          range.select();
        }
      }

      function toNumber(currencyStr) {
        return parseFloat(currencyStr.replace(toNumberRegex, ''), 10);
      }

      return {
        restrict: 'A',
        require: 'ngModel',
        link: function postLink(scope, elem, attrs, modelCtrl) {
          modelCtrl.$formatters.push(filterFunc);
          modelCtrl.$parsers.push((newViewValue) => {
            const oldModelValue = modelCtrl.$modelValue;
            const newModelValue = toNumber(newViewValue);
            modelCtrl.$viewValue = filterFunc(newModelValue);
            const pos = getCaretPosition(elem[0]);
            elem.val(modelCtrl.$viewValue);
            let newPos =
              pos + modelCtrl.$viewValue.length - newViewValue.length;
            if (
              typeof oldModelValue === 'undefined' ||
              Number.isNaN(oldModelValue)
            ) {
              newPos -= 3;
            }
            setCaretPosition(elem[0], newPos);
            return newModelValue;
          });
        },
      };
    });
