import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'RepaymentPdfPreviewModalCtrl',
      function RepaymentPdfPreviewModalCtrl(
        $scope,
        $window,
        options,
        labels,
        colors,
        graphData,
        datasetOverride,
        loanAmount,
        years,
        interestRate,
        frequency,
        totalRepaymentAmount,
        resultPanelData,
        totalInterest,
        customThemeService,
        series,
        userService,
        loanCalculatorSharedData,
      ) {
        $scope.data = graphData;
        $scope.datasetOverride = datasetOverride;
        $scope.options = options;
        $scope.labels = labels;
        $scope.colors = colors;
        $scope.series = series;
        $scope.loanAmount = loanAmount;
        $scope.years = years;
        $scope.interestRate = interestRate;
        $scope.frequency = frequency;
        $scope.totalRepaymentAmount = totalRepaymentAmount;
        $scope.resultPanelData = resultPanelData;
        $scope.totalInterest = totalInterest;
        $scope.brandingLogo = loanCalculatorSharedData.brandingLogo;
        $scope.getUserInfo = loanCalculatorSharedData.getUserInfo;
        $scope.getUserInfo();
        $scope.date = new Date();
      },
    );
