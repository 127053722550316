/**
 * Phone Icon Field is an text type input element
 * with the mobile or warning icon on the left side
 * and edit icon on the right side
 * Depending on the property combinations passed the view should adjust
 * This can be read-only (disabled) and editable
 * states: warning (required, empty value, read-only), edit invalid phone nummber, edit valid phone number, disabled
 */
import angular from 'angular';
import { KEY_CODE } from 'Common/constants/keyCode';
import { isMobileNumberValid, isMobileValid } from 'Common/utilities/mobile';

export default class PhoneIconFieldCtrl {
  constructor($timeout, countryCodeService, $window) {
    'ngInject';

    this.$timeout = $timeout;
    this.countryCodeService = countryCodeService;
    this.invalidMessage = 'Please add a mobile number';
    this.angular = angular;
    this.window = $window;
    this.isMobileValid = isMobileValid;
  }

  $onInit() {
    this.setWarningState(false);
    this.resetTimeout = this.$timeout(() => this.resetField(), 2000);
    this.isCountrySelectorActive = false;
    this.onLoadModel = this.phoneModel;
  }

  $onDestroy() {
    this.$timeout.cancel(this.resetTimeout);
    this.labelTimeout && this.$timeout.cancel(this.labelTimeout);
    this.onSaveFocusTimeout && this.$timeout.cancel(this.onSaveFocusTimeout);
  }

  $onChanges(props) {
    if (props.phoneModel && props.id) {
      this.initPhoneModel = this.phoneModel;
      this.resetField();
    }
    this.showWarning();
  }

  resetField(includeEditState = true) {
    includeEditState && this.setEditState(false);
    this.setSavingState(false);
    this.setInvalidState(false);
    this.showWarning();
    this.showDisabled();
    this.setConfirmPopover(false);
    this.updateParentState();
  }

  setDisabledState(state) {
    this.isDisabledState = state;
  }

  setWarningState(state) {
    this.isWarningState = state;
  }

  setInvalidState(state) {
    this.isInvalidState = state;
  }

  setSavingState(state) {
    this.isSavingState = state;
  }

  setConfirmPopover(state) {
    this.showConfirmPopover = state;
  }

  setEditState(state) {
    this.isEdit = state;
    this.setDisabledState(!state);
    this.updateParentState();
  }

  getOpenState() {
    return this.isEdit || this.isInvalidState || this.isWarningState;
  }

  showWarning() {
    const isRequired = !!this.isRequired && !this.phoneModel;
    const warningState = this.isShowWarning ? isRequired : this.isShowWarning;

    this.setWarningState(warningState);
  }

  showDisabled() {
    this.setDisabledState(!this.isEdit && !this.isWarningState);
  }

  setInvalidMessage({ element, isPhoneRequired, message, phoneNumber }) {
    this.invalidMessage = `${
      this.countryCodeService.countryInputSelectorValidation({
        element,
        isPhoneRequired,
        message,
        phoneNumber,
      }).message
    }`;
  }

  setWarningStateOnInternationalPhone() {
    const warningStateCondition = this.isInternationalPhone
      ? false
      : !!this.isRequired && !this.phoneModel && !this.isInvalidState;
    this.setWarningState(warningStateCondition);
  }

  checkInterNationalPhoneInvalidState() {
    if (this.isInternationalPhone) {
      const inputElement = this.angular.element(`#${this.inputName}Id`);
      const isPhoneInputValid = this.countryCodeService.validatePhoneNumber({
        element: inputElement,
        phoneNumber: this.phoneModel,
        isPhoneRequired: true,
        message: 'Phone is required',
        isOtherFieldInvalid: false,
        showToaster: false,
      });
      this.setInvalidMessage({
        element: inputElement,
        isPhoneRequired: true,
        message: 'Please provide a valid phone number.',
        phoneNumber: this.phoneModel,
      });
      this.setInvalidState(!isPhoneInputValid);
    } else if (!this.isMobileValid(this.phoneModel)) {
      this.invalidMessage = 'Please provide a valid phone number.';
    }
  }

  isValid(event, isKeyEvent = false) {
    if (event.keyCode === KEY_CODE.ESC) {
      this.cancel();
      return;
    }

    this.checkInterNationalPhoneInvalidState();

    this.isKeyEvent = isKeyEvent;
    this.setEditState(true);
    this.setWarningStateOnInternationalPhone();

    if (this.phoneModel && !this.isInternationalPhone) {
      this.setInvalidState(!isMobileNumberValid(this.phoneModel));
    }

    this.updateParentState();
  }

  setDataSavedState() {
    this.dataIsSaved = !this.dataIsSaved;
  }

  editMode() {
    this.setDisabledState(false);
  }

  setCountryCodeIfSelected() {
    const countrySelectorClassName = 'selected-flag';
    const activeSelectorClass = this.angular
      .element(this.window.document.activeElement)
      .attr('class');
    this.isCountrySelectorActive = false;

    if (activeSelectorClass === countrySelectorClassName) {
      this.isCountrySelectorActive = true;
    }
  }

  onRemoveFocus() {
    const isNotPristine =
      (this.phoneModel && this.phoneModel.trim()) !== this.initPhoneModel &&
      this.isEdit &&
      !this.isInvalidState &&
      !this.isWarningState;

    if (isNotPristine) {
      this.onLoadModel = this.phoneModel;
      !this.showConfirmPopover && this.setConfirmPopover(true);
      this.onFocusOut && this.onFocusOut();
    } else {
      this.phoneModel = this.onLoadModel;
      this.cancel();
    }
  }

  onSaveFocus() {
    this.setCountryCodeIfSelected();

    this.onSaveFocusTimeout = this.$timeout(() => {
      if (!this.isCountrySelectorActive) {
        this.checkInterNationalPhoneInvalidState();
        this.onRemoveFocus();
      }
    }, 500);
  }

  save() {
    this.setSavingState(true);
    return this.onSave({
      phone: this.phoneModel,
      id: this.id,
    })
      .then(() => {
        this.initPhoneModel = this.phoneModel;
        this.setDataSavedState();
        this.resetField(false);
        this.labelTimeout = this.$timeout(() => {
          this.setDataSavedState();
          this.setEditState(false);
        }, 1000);
        this.updateParentState();
      })
      .catch(() => this.cancel());
  }

  cancel(reset = true) {
    reset && (this.phoneModel = this.initPhoneModel);
    this.resetField();
    this.setConfirmPopover(false);
    this.updateParentState();
  }

  updateParentState() {
    this.onUpdate({
      phone: this.phoneModel,
      isPhoneEdit: this.getOpenState(),
      cancelCallback: () => {
        this.cancel();
      },
    });
  }
}
