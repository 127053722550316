import template from './onboardingContent.html';
import OnboardingContentCtrl from './onboardingContentCtrl';

export default {
  template,
  bindings: {
    activeTab: '<',
    tabs: '<',
    questionnaireField: '<',
  },
  transclude: true,
  controller: OnboardingContentCtrl,
  controllerAs: 'vm',
};
