import template from './commissionExpectedSummary.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    isExporting: '<',
    exportIcon: '<',
    expectedCommissions: '<',
    totalExpectedCommissions: '<',
    expectedCommissionsHeader: '<',
    isLoadingExpectedCommissions: '<',
    tableParams: '<',
    onSendEnquiry: '&',
    onRowItemClick: '&',
    onDeleteCommission: '&',
    loadExpectedCommissions: '&',
    adviserList: '<',
    lenderList: '<',
    commissionTypeList: '<',
    filterSettings: '<',
    onFilterChange: '&',
    onSortTableData: '&',
    onExportTableData: '&',
  },
};
