import { PARTY_GROUPING } from 'Common/constants/partyTypes';
import { getAllInvolvedParties } from 'Common/utilities/involvedParties';

class HeaderMainDetailsCtrl {
  constructor($rootScope, $state, clientInfoService) {
    'ngInject';
    this.clientInfoService = clientInfoService;
    this.$rootScope = $rootScope;
    this.$state = $state;
  }

  $onInit() {
    this.PARTY_GROUPING = PARTY_GROUPING;
    this.$rootScope.currTitle = 'File';
  }

  $onChanges(changes) {
    const { applicants } = changes;

    if (applicants && applicants.currentValue) {
      this.formatList();
    }
  }

  formatList() {
    this.formattedList = getAllInvolvedParties(
      this.applicants,
      this.clientInfoService,
      false,
    );
  }
}

export default HeaderMainDetailsCtrl;
