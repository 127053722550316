export default class DocumentCollectionService {
  constructor(httpClient, configService) {
    'ngInject';

    this.httpClient = httpClient;
    this.configService = configService;

    const config = {
      cache: false,
      removeCache: true,
      useDefaultErrorCallback: true,
      isCustomResourceUrl: true,
      isAuthorizationOnly: true,
      params: {},
    };

    const enabledCacheConfig = {
      cache: true,
      removeCache: false,
    };

    this.httpDefaultConfig = [
      config.cache,
      config.removeCache,
      config.useDefaultErrorCallback,
      config.isCustomResourceUrl,
      config.isAuthorizationOnly,
    ];

    this.httpDefaultPostConfig = [
      config.params,
      config.useDefaultErrorCallback,
      config.isCustomResourceUrl,
      config.isAuthorizationOnly,
    ];

    this.httpDefaultConfigCached = [
      enabledCacheConfig.cache,
      enabledCacheConfig.removeCache,
      config.useDefaultErrorCallback,
      config.isCustomResourceUrl,
      config.isAuthorizationOnly,
    ];

    this.apiBaseUrl = `${this.configService.mycrmServicesBaseUrl}/api/document-collection`;
  }

  getReminders(params = {}) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/reminders`,
      params,
      ...this.httpDefaultConfigCached,
    );
  }

  getTemplates(params = {}) {
    const customHttpConfig = [...this.httpDefaultConfigCached];
    const useDefaultErrorCallback = false;
    customHttpConfig[2] = useDefaultErrorCallback;
    return this.httpClient.get(
      `${this.apiBaseUrl}/templates`,
      params,
      ...customHttpConfig,
    );
  }

  createFileInvite(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/sqs-invites`,
      data,
      ...this.httpDefaultPostConfig,
    );
  }

  pollFileInviteCreation(id) {
    const customHttpConfig = [...this.httpDefaultConfig];
    const useDefaultErrorCallback = false;
    customHttpConfig[2] = useDefaultErrorCallback;
    return this.httpClient.get(
      `${this.apiBaseUrl}/sqs-invites/${id}`,
      {},
      ...customHttpConfig,
    );
  }
}
