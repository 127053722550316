import './style.scss';
import template from './taskTemplateEditor.html';
import controller from './taskTemplateEditorCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    template: '<',
    advisers: '<',
    onCancel: '&',
    onSave: '&',
  },
};
