import angular from 'angular';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';
import {
  getCategoryModelTypeService,
  isCategoryNameExisting,
  isCategoryNameDuplicated,
} from '../../util/category';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('CategoryModalCtrl', function CategoryModalCtrl(
      $scope,
      $uibModalInstance,
      contactModelService,
      contactService,
      toaster,
    ) {
      const isCompliance = !!$scope.isComplianceTab;
      $scope.categoryModalType = isCompliance ? 'Select Compliance' : 'Select';
      $scope.isNewCategoryVisible = false;
      $scope.newCategoryList = [];
      $scope.existingCategoryNameList = [];
      const modelService = getCategoryModelTypeService({
        $scope,
        isCompliance,
        contactModelService,
        contactService,
      });

      function getCategory() {
        modelService.getCommonCategory();
      }

      $scope.setCorporate = function (
        categoryId,
        isExclusiveForCorpUser,
        positionIndex,
      ) {
        $scope.filteredItems[
          positionIndex
        ].isExclusiveForCorpUser = !(typeof isExclusiveForCorpUser ===
        'undefined'
          ? false
          : isExclusiveForCorpUser);
        contactService
          .setContactCategoryCorpUser(
            categoryId,
            !(typeof isExclusiveForCorpUser === 'undefined'
              ? false
              : isExclusiveForCorpUser),
          )
          .then(() => {});
      };

      $scope.categoryCheckedAdd = (category) =>
        modelService.toggleCategoryList(category);

      $scope.addCategory = function () {
        $scope.isNewCategoryVisible = true;
        const newCategory = {
          CategoryID: 0,
          CategoryName: '',
          IsChecked: true,
        };
        $scope.newCategoryList.push(newCategory);
      };

      $scope.deleteCategory = function (category) {
        swal(
          {
            title: 'Are you sure?',
            text: 'This record will be removed from your category list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          },
          (confirm) => {
            if (confirm) {
              modelService
                .categoryDelete(category.CategoryID, category)
                .then(() => {
                  getCategory();
                  toaster.pop(
                    'success',
                    'Deleted',
                    'Category has been successfully deleted',
                  );
                })
                .catch(displayError);
            }
          },
        );
      };

      $scope.saveCategory = function (category) {
        if (category.CategoryName === '') {
          swal('Empty Name', 'Category Name cannot be empty.', 'error');
          return;
        }

        const isDuplicate =
          isCategoryNameDuplicated({
            categoryName: category.CategoryName,
            categoryList: $scope.newCategoryList,
          }) ||
          isCategoryNameExisting({
            categoryName: category.CategoryName,
            categoryList: $scope.existingCategoryNameList,
          });

        if (isDuplicate) {
          displayError(
            'Category Name cannot have the same value.',
            true,
            'Duplicate Category Name',
          );
          return;
        }

        modelService
          .categorySave(category)
          .then(() => {
            const index = $scope.newCategoryList.indexOf(category);
            if (index > -1) {
              $scope.newCategoryList.splice(index, 1);
            }
            getCategory();
            toaster.pop(
              'success',
              'Added',
              'Category has been successfully added',
            );
          })
          .catch(displayError);
      };

      $scope.hideEditButton = function (category) {
        category.IsEditMode = true;
      };

      $scope.updateCategory = function (currentlyEditedCategory) {
        if (currentlyEditedCategory.CategoryName === '') {
          swal('Empty Name', 'Category Name cannot be empty.', 'error');
          return;
        }
        currentlyEditedCategory.IsEditMode = false;

        modelService
          .categoryUpdate(currentlyEditedCategory)
          .then(() => {
            toaster.pop(
              'success',
              'Updated',
              'Category has been successfully updated',
            );
          })
          .catch(displayError);
      };

      function reorder(categoryId, orderBy, category) {
        modelService.categoryReorder(categoryId, orderBy, category).then(() => {
          getCategory();
        });
      }

      $scope.reorderUp = function (category, index) {
        if (index > 0) {
          const itemAbove =
            $scope.filteredItems[$scope.filteredItems.indexOf(category) - 1];
          reorder(category.CategoryID, itemAbove.OrderBy, category);
        }
      };

      $scope.reorderDown = function (category, index) {
        if (index + 1 < $scope.existingCategoryNameList.length) {
          const itemBelow =
            $scope.filteredItems[$scope.filteredItems.indexOf(category) + 1];
          reorder(category.CategoryID, itemBelow.OrderBy, category);
        }
      };

      $scope.cancel = function () {
        $uibModalInstance.close();
      };

      $scope.done = () => modelService.onDone();

      function init() {
        getCategory();
      }

      init();
    });
