import angular from 'angular';

export const loadModule = () =>
  angular.module('app').factory('jagoIntegrationModel', [
    'httpRequest',
    function (httpRequest) {
      var apiBaseUrl = 'jago';

      function jagoIntegration() {}

      jagoIntegration.prototype = {
        GetJagoUrl(callback, errorCallback) {
          httpRequest.get(
            `${apiBaseUrl}/user/web-token`,
            (response) => {
              return callback(response);
            },
            (error) => {
              errorCallback(error);
            },
          );
        },
      };

      return jagoIntegration;
    },
  ]);
