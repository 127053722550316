import { ALLOWED_ACTION } from 'Common/constants/accessPermission';

class CorporatePermissionService {
  constructor(corporateModelService) {
    'ngInject';

    this.corporateModelService = corporateModelService;
  }

  isPermissionReadAndWrite(pageName, tabName, subTabName) {
    return this.corporateModelService
      .accessControlMatrixes(pageName, tabName, subTabName)
      .then((data) => {
        if (!data || !data.length || !data[0].allowedActionId) {
          return false;
        }
        return data[0].allowedActionId === ALLOWED_ACTION.READ_AND_WRITE;
      });
  }
}

export default CorporatePermissionService;
