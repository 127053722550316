import { USERS_TAB, DEFAULT_USERS_TAB } from 'Common/constants/users';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { isAllBrand } from 'Common/utilities/brandingCategory';

export const stateTransition = (vm) => (tab) => {
  vm.selectedTab = tab;
  vm.$state.params.activeTab = tab;
  vm.stateService.transition(vm.$state.current, vm.$state.params);
};

export const onClickTab = (vm) => () => {
  vm.selectedTabName =
    Object.keys(USERS_TAB).find((key) => USERS_TAB[key] === vm.active) ||
    DEFAULT_USERS_TAB;
  vm.stateTransition(vm.selectedTabName);
};

export const onInit = (vm) => {
  vm.stateTransition = stateTransition(vm);
  vm.onClickTab = onClickTab(vm);
  vm.getActiveTab(vm.$state.params.activeTab || DEFAULT_USERS_TAB);
  vm.displayBranding =
    vm.configService.feature.bYOBDoneRight && vm.currentUserService.isBYOB;
  vm.subscriptionHeading = vm.currentUserService.isAU
    ? 'SUBSCRIPTIONS + PLUG-INS'
    : 'SUBSCRIPTIONS';
};
export const checkUserProfileManagementAccess = ({
  feature,
  currentUserService,
}) => {
  const nzHasSubscription = feature.nZNurture || feature.nZStayInTouch;

  return (
    feature &&
    feature.userProfileManagement &&
    (currentUserService.isAU ||
      (currentUserService.isNZ &&
        feature.nZSubscriptions &&
        nzHasSubscription) ||
      isAllBrand(currentUserService)) &&
    currentUserService.accessType !== ACCESS_TYPE.CORPORATE
  );
};

export const checkMyLeadGeneratorAccess = (feature, currentUserService) => {
  return (
    feature &&
    !currentUserService.isAdminAssistant &&
    !currentUserService.isAssistant &&
    !currentUserService.isReferrer &&
    currentUserService.isAU &&
    (currentUserService.isLM || currentUserService.isBYOB) &&
    currentUserService.hasBrandFeature('HasMyLeadGenerator')
  );
};
