import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import {
  APPROVAL_EXPIRIES_ID,
  CUSTOMER_CARE_TYPES,
  TAB_APPROVAL_EXPIRIES,
} from 'Common/constants/customerCareModules';
import { D_MMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';
import {
  sortColumn,
  sortType,
  addSelectedCustomerCareIds,
  isRetainAllAdviser,
  adviserChanged,
} from 'Assets/js/controllers/customerCare/util/customerCareCtrl';
import {
  onActionSelectedUtil,
  sendingResultUtil,
  modalParamsUtil,
  initValuesUtil,
  processCustomDateRange,
} from './util/customerCareTabsCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('PreApprovalCtrl', function PreApprovalCtrl(
      $scope,
      $uibModal,
      $timeout,
      NgTableParams,
      customerCareService,
      currentUserService,
      customerCareTabsService,
      configService,
    ) {
      // Pre Approval Expires
      // --------------------------------------------------------------------------
      $scope.unlockingCondition = () => {
        return (
          ($scope.showLockScreen &&
            $scope.unlockingCustomerCare &&
            $scope.autoMarketingSubscription.subscribe_PreApprovalExpiry) ||
          (currentUserService.isAssistantType && $scope.unlockingCustomerCare)
        );
      };
      $scope.reloadTimeout = null;
      $scope.isUnlockingPreApproval = $scope.unlockingCondition();
      $scope.customerCareAdviserList = isRetainAllAdviser(
        $scope.customerCareAdviserList,
        $scope.isUnlockingPreApproval,
      );
      $scope.mainObj = {};
      $scope.mainObj.adviserObject =
        $scope.customerCareAdviserList && $scope.customerCareAdviserList.length
          ? $scope.customerCareAdviserList[0]
          : 0;
      $scope.customerCareAdviserList = [...$scope.customerCareAdviserList];
      $scope.mainObj.dateRange = 60;
      $scope.mainObj.ShowCompleted = false;
      $scope.currentUserService = currentUserService;
      $scope.customerCareTabsService = customerCareTabsService;
      $scope.initValues = initValuesUtil($scope);
      $scope.exportService = 'exportPreApprovalExpiry';
      $scope.selectedPreapprovalIds = [];
      $scope.currentPage = 1;
      $scope.totalCount = 0;
      $scope.sortColumn = '';
      $scope.sortType = '';
      $scope.requiredParams = {
        get: () => ({
          pageSize: $scope.totalCount,
          sortType: $scope.sortType,
          sortColumn: $scope.sortColumn,
          toDate: $scope.mainObj.toDate,
          fromDate: $scope.mainObj.formDate,
          adviserId: $scope.mainObj.adviserObject.familyId,
          selectedRowIds: $scope.selectedPreapprovalIds,
        }),
      };
      $scope.addSelectedPreapprovalIds = addSelectedCustomerCareIds(
        $scope.selectedPreapprovalIds,
      );
      $scope.reloadTable = _.noop();
      $scope.showUnlockedPreapproval = () => {
        const isAdviserReadOnly = _.get(
          $scope.mainObj,
          'adviserObject.isSubscribedToCustomerCareType',
          false,
        );
        return $scope.isUnlockingPreApproval && isAdviserReadOnly;
      };
      $scope.customerCareTabsService.reloadCustomerCarePreApprovalValues = ({
        showLockScreen,
        customerCareAdviserList,
      }) => {
        $scope.reloadTimeout = $timeout(() => {
          $scope.showLockScreen = showLockScreen;
          $scope.isUnlockingPreApproval = $scope.unlockingCondition();
          $scope.customerCareAdviserList = isRetainAllAdviser(
            customerCareAdviserList,
            $scope.isUnlockingPreApproval,
          );
          $scope.mainObj.adviserObject = _.get(
            $scope.customerCareAdviserList,
            '[0]',
            {},
          );
          $timeout.cancel($scope.reloadTimeout);
        });
      };
      $scope.loadPreApprovalTable = function () {
        $scope.preApprovalTableParams = new NgTableParams(
          {
            page: 1,
            count: 10,
            sorting: {
              ExpiryDate: 'asc',
            },
          },
          {
            counts: [],
            total: 0,
            getData(params) {
              const adviserFamilyId = _.get(
                $scope.mainObj,
                'adviserObject.familyId',
              );
              if (!adviserFamilyId && adviserFamilyId !== 0) {
                return;
              }
              $scope.isLoading = true;
              $scope.sortColumn = sortColumn(params.orderBy);
              $scope.sortType = sortType(params.sorting);
              $scope.currentPage = params.page();

              return customerCareService
                .PreApprovalExpiresListGet(
                  adviserFamilyId,
                  $scope.mainObj.formDate,
                  $scope.mainObj.toDate,
                  params.page(),
                  params.count(),
                  $scope.sortColumn,
                  $scope.sortType,
                  $scope.mainObj.ShowCompleted,
                )
                .then(
                  (response) => {
                    $scope.isLoading = false;
                    const hasPreAppovalList =
                      response.data && response.data.PreApprovalExpireList;
                    if (hasPreAppovalList) {
                      $scope.preApprovalListTable =
                        response.data.PreApprovalExpireList;
                      _.map($scope.preApprovalListTable, (item) => {
                        const today = moment().format('D MMMM YYYY');
                        const exDate = moment(item.ExpiryDate).format(
                          'D MMMM YYYY',
                        );

                        if (today === exDate) {
                          item.IsDueToday = true;
                        } else {
                          item.IsDueToday = false;
                        }
                        item.isChecked = $scope.selectedPreapprovalIds.includes(
                          item.LoanID,
                        );
                      });
                      if (typeof response.data.TotalCount !== 'undefined') {
                        params.total(response.data.TotalCount);
                      } else {
                        params.total(0);
                      }
                      $scope.checkedTableData = [];
                      $scope.totalCount = response.data.TotalCount;
                      return $scope.preApprovalListTable;
                    }
                  },
                  () => {
                    $scope.isLoading = false;
                  },
                );
            },
          },
        );
        $scope.reloadTable = () => {
          $scope.preApprovalTableParams.reload();
        };
      };
      $scope.adviserChanged = () => {
        adviserChanged(
          $scope.selectedPreapprovalIds,
          $scope.preApprovalTableParams,
        );
      };
      $scope.onActionSelected = onActionSelectedUtil($scope);

      // Date Range Filter
      $scope.dateRangeFilter = () => {
        if (!$scope.mainObj.dateRange) {
          return $scope.loadPreApprovalTable();
        }
        const dateFormat = D_MMM_YYYY_FORMAT;
        $scope.mainObj.formDate = moment().format(dateFormat);
        $scope.mainObj.toDate = moment()
          .add(Number($scope.mainObj.dateRange), 'days')
          .format(dateFormat);
        $scope.selectedPreapprovalIds.splice(
          0,
          $scope.selectedPreapprovalIds.length,
        );
        $scope.loadPreApprovalTable();
      };

      // Set Pre Approval as Complete
      $scope.setPreApprovalCompilation = function (preApproval) {
        const props = {
          preApproval,
        };
        const modalInstance = $uibModal.open({
          template: `<mark-completed-pre-approval-modal
                  modal-instance="vm.modalInstance"
                  pre-approval="vm.props.preApproval"
                  >
                </mark-completed-pre-approval-modal>`,
          size: 'sm',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'mark-complete-pre-approval',
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: { props: () => props },
        });
        modalInstance.result.then((response) => {
          if (response) {
            return $scope.preApprovalTableParams.reload();
          }
        });
      };

      // Open modal For Get Pre Approval Detail in Mobile
      $scope.openModalForGetPreApprovalDetail = function (preApproval, size) {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/pre_approval_filter_modal.html',
          controller: 'PreApprovalDetailsModalCtrl',
          size,
          resolve: {},
        });
      };

      $scope.openSendEmailModal = function (size, preApprovalObj, source) {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_email_modal.html',
          resolve: {
            preApprovalListTable() {
              return $scope.preApprovalListTable;
            },
            preApprovalObj() {
              return preApprovalObj;
            },
            source() {
              return source;
            },
            loadPreApprovalTable() {
              return $scope.reloadTable;
            },
          },
          controller: 'OpenPreApprovalSendEmailModalCtrl',
          size,
        });
      };

      $scope.sendingResult = sendingResultUtil($scope);

      $scope.openSendMailModal = function (size, preApprovalObj, source) {
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_mail_modal.html',
          resolve: {
            listTableData() {
              return $scope.preApprovalListTable;
            },
            sendMailObj() {
              return preApprovalObj;
            },
            source() {
              return source;
            },
            currentModuleId() {
              return APPROVAL_EXPIRIES_ID;
            },
            currentModuleType: modalParamsUtil.currentModuleType(
              CUSTOMER_CARE_TYPES.APPROVAL_EXPIRIES,
            ),
            currentModuleName: modalParamsUtil.currentModuleName(
              'Approval Expires',
            ),
            isInsurance: modalParamsUtil.isInsurance(false),
            tab: modalParamsUtil.tab(null),
          },
          controller: 'OpenSendMailModalCtrl',
          size,
        });
        $scope.sendingResult(modalInstance, 'reloadTable');
      };
      $scope.openSMSModal = function (size, preApprovalObj, source) {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_sms_modal.html',
          resolve: {
            preApprovalListTable() {
              return $scope.preApprovalListTable;
            },
            preApprovalObj() {
              return preApprovalObj;
            },
            source() {
              return source;
            },
            loadPreApprovalTable() {
              return $scope.reloadTable;
            },
          },
          controller: 'OpenPreApprovalSMSModalCtrl',
          size,
        });
      };

      const init = () => {
        $scope.reloadCallback = $scope.loadPreApprovalTable;
        processCustomDateRange($scope);
        $scope.dateRangeFilter();
        $scope.loadPreApprovalTable();
        $scope.initValues({
          configService,
          TAB_DETAILS: TAB_APPROVAL_EXPIRIES,
        });
      };

      init();
      $scope.$on('$destroy', () => {
        $timeout.cancel($scope.reloadTimeout);
        $scope.reloadTimeout = null;
      });
    });
