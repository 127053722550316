import { KEY_CODE } from 'Common/constants/keyCode';

class EditableTextCtrl {
  $onInit() {
    this.isEditing = false;
  }

  $onChanges(changes) {
    const { text } = changes;

    if (text && text.currentValue !== undefined) {
      this.isSaving = false;
      this.isEditing = false;
    }
  }

  changeText() {
    this.isEditing = true;
    this.originalText = this.text;
  }

  onKeyDown(event) {
    const keyCode = event && event.keyCode;
    if (keyCode === KEY_CODE.ESC) {
      this.isEditing = false;
      this.text = this.originalText;
      return;
    }

    if (keyCode === KEY_CODE.WIN_ENT) {
      this.saveText();
    }
  }

  saveText() {
    if (this.originalText === this.text) {
      this.isEditing = false;
      return;
    }

    this.isSaving = true;
    this.onTextSave({ data: this.text });
  }
}

export default EditableTextCtrl;
