import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'CustomerCommentModalSingleCtrl',
      function CustomerCommentModalSingleCtrl(
        $scope,
        $uibModalInstance,
        comments,
        alldata,
        $uibModal,
      ) {
        $scope.commentsArr = comments;
        $scope.customerAlldata = alldata;

        $scope.closeModal = () => {
          $uibModalInstance.dismiss('cancel');
        };
        $scope.viewAllComments = () => {
          $scope.closeModal();
          $uibModal.open({
            templateUrl:
              'assets/views/overviewDashboard/modals/customerCommentViewAllModal.html',
            size: 'md',
            backdrop: 'static',
            keyboard: false,
            controller: 'CustomerCommentViewAllModalCtrl',
            resolve: {
              viewAlldata() {
                return $scope.customerAlldata;
              },
            },
          });
        };
      },
    );
