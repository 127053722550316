import angular from 'angular';
import homeIcon from './homeIcon.svg';
import contactIcon from './contactIcon.svg';
import tasksIcon from './tasksIcon.svg';
import loanToolsIcon from './loanToolsIcon.svg';
import insuranceToolsIcon from './insuranceToolsIcon.svg';
import resourcesIcon from './resourcesIcon.svg';
import communicateIcon from './communicateIcon.svg';
import pipelineIcon from './pipelineIcon.svg';
import pipelinesIcon from './pipelines.svg';
import reportIcon from './reportIcon.svg';
import commissionIcon from './commissionIcon.svg';
import helpIcon from './helpIcon.svg';
import profileManagementIcon from './profileManagementIcon.svg';
import referralIcon from './referralIcon.svg';
import corporateIcon from './corporateIcon.svg';
import customizeThemeIcon from './customizeThemeIcon.svg';
import closeIcon from './closeIcon.svg';
import disablementGraph from './disablementGraph.svg';
import addIcon from './addIcon.svg';
import mapPinIcon from './mapPinIcon.svg';
import bigCheckGreenIcon from './bigCheckGreenIcon.svg';
import bigCloseRedIcon from './bigCloseRedIcon.svg';
import clientIcon from './clientIcon.svg';
import hideNotesIcon from './hideNotesIcon.svg';
import showNotesIcon from './showNotesIcon.svg';
import lmiGraphicsIcon from './lmiGraphicsIcon.svg';
import trophyIcon from './trophyIcon.svg';
import checkRoundBg from './checkRoundBg.svg';
import sadEmoji from './sadEmoji.svg';
import mobile from './mobile.svg';
import envelope from './envelope.svg';
import user from './user.svg';
import update from './update.svg';
import iconPdfNormal from './iconPdfNormal.svg';
import asterisk from './asterisk.svg';
import infoSquareIcon from './infoSquareIcon.svg';
import infoCircleIcon from './infoCircleIcon.svg';
import heartIcon from './heartIcon.svg';
import heartHoverIcon from './heartHoverIcon.svg';
import heartRedIcon from './heartRedIcon.svg';
import entityIcon from './entityIcon.svg';
import asicIcon from './asicIcon.svg';
import principalIcon from './principalIcon.svg';
import individualIcon from './individualIcon.svg';
import additionalStaffIcon from './additionalStaffIcon.svg';
import questionniareIcon from './questionniareIcon.svg';
import relatedDocIcon from './relatedDocIcon.svg';
import toggleDownIcon from './toggleDownIcon.svg';
import toggleUpIcon from './toggleUpIcon.svg';
import arrowNext from './arrowNext.svg';
import buttonSwap from './buttonSwap.svg';
import deletePurpleIcon from './deletePurpleIcon.svg';
import sortIcon from './sortIcon.svg';
import sortSingleIcon from './sortSingleIcon.svg';
import calculator from './calculator.svg';
import house from './house.svg';
import twoPerson from './twoPerson.svg';
import filter from './filter.svg';
import chevLeft from './chevLeft.svg';
import tabFlagCompletedIcon from './tabFlagCompletedIcon.svg';
import tabIncompleteIcon from './tabIncompleteIcon.svg';
import closeRedIcon from './closeRedIcon.svg';
import checkGreenIcon from './checkGreenIcon.svg';
import americanExpressCard from './americanExpressCard.svg';
import bankCard from './bankCard.svg';
import dinersClubCard from './dinersClubCard.svg';
import masterCard from './masterCard.svg';
import otherCard from './otherCard.svg';
import visaCard from './visaCard.svg';
import loanToolsGreenIcon from './loanToolsGreenIcon.svg';
import exclamationSquare from './exclamationSquare.svg';
import closeWhite from './closeWhite.svg';
import marketingIcon from './marketingIcon.svg';
import addSmGrayIcon from './addSmGrayIcon.svg';
import bookShelf from './bookShelf.svg';
import excelIcon from './excelIcon.svg';
import iconPin from './iconPin.svg';
import iconNote from './iconNote.svg';
import iconNotes from './iconNotes.svg';
import iconNotesCircle from './iconNotesCircle.svg';
import iconListCircle from './iconListCircle.svg';
import iconFolderCircle from './iconFolderCircle.svg';
import iconTextFile from './iconTextFile.svg';
import iconSlantBell from './iconSlantBell.svg';
import recommendationIcon from './recommendationIcon.svg';
import actionsPanelIcon from './actionsPanelIcon.svg';
import iconPassed from './iconPassed.svg';
import iconWarning from './iconWarning.svg';
import calculateIcon from './calculateIcon.svg';
import insuranceNotes from './insuranceNotes.svg';
import iconFlag from './iconFlag.svg';
import iconDependent from './iconDependent.svg';

export default angular
  .module('components.icons', [])
  .component('excelIcon', { template: excelIcon })
  .component('homeIcon', { template: homeIcon })
  .component('bookShelf', { template: bookShelf })
  .component('exclamationSquare', { template: exclamationSquare })
  .component('closeWhite', { template: closeWhite })
  .component('contactIcon', { template: contactIcon })
  .component('tasksIcon', { template: tasksIcon })
  .component('loanToolsIcon', { template: loanToolsIcon })
  .component('insuranceToolsIcon', { template: insuranceToolsIcon })
  .component('resourcesIcon', { template: resourcesIcon })
  .component('communicateIcon', { template: communicateIcon })
  .component('pipelineIcon', { template: pipelineIcon })
  .component('pipelinesIcon', { template: pipelinesIcon })
  .component('reportIcon', { template: reportIcon })
  .component('commissionIcon', { template: commissionIcon })
  .component('helpIcon', { template: helpIcon })
  .component('profileManagementIcon', { template: profileManagementIcon })
  .component('referralIcon', { template: referralIcon })
  .component('corporateIcon', { template: corporateIcon })
  .component('customizeThemeIcon', { template: customizeThemeIcon })
  .component('closeIcon', { template: closeIcon })
  .component('disablementGraph', { template: disablementGraph })
  .component('addIcon', { template: addIcon })
  .component('mapPinIcon', { template: mapPinIcon })
  .component('bigCheckGreenIcon', { template: bigCheckGreenIcon })
  .component('bigCloseRedIcon', { template: bigCloseRedIcon })
  .component('clientIcon', { template: clientIcon })
  .component('hideNotesIcon', { template: hideNotesIcon })
  .component('showNotesIcon', { template: showNotesIcon })
  .component('lmiGraphicsIcon', { template: lmiGraphicsIcon })
  .component('trophyIcon', { template: trophyIcon })
  .component('checkRoundBg', { template: checkRoundBg })
  .component('sadEmoji', { template: sadEmoji })
  .component('mobile', { template: mobile })
  .component('envelope', { template: envelope })
  .component('user', { template: user })
  .component('update', { template: update })
  .component('iconPdfNormal', { template: iconPdfNormal })
  .component('asterisk', { template: asterisk })
  .component('infoSquareIcon', { template: infoSquareIcon })
  .component('infoCircleIcon', { template: infoCircleIcon })
  .component('heartIcon', { template: heartIcon })
  .component('heartHoverIcon', { template: heartHoverIcon })
  .component('heartRedIcon', { template: heartRedIcon })
  .component('entityIcon', { template: entityIcon })
  .component('asicIcon', { template: asicIcon })
  .component('principalIcon', { template: principalIcon })
  .component('individualIcon', { template: individualIcon })
  .component('additionalStaffIcon', { template: additionalStaffIcon })
  .component('questionniareIcon', { template: questionniareIcon })
  .component('relatedDocIcon', { template: relatedDocIcon })
  .component('toggleDownIcon', { template: toggleDownIcon })
  .component('toggleUpIcon', { template: toggleUpIcon })
  .component('arrowNext', { template: arrowNext })
  .component('buttonSwap', { template: buttonSwap })
  .component('deletePurpleIcon', { template: deletePurpleIcon })
  .component('sortIcon', { template: sortIcon })
  .component('sortSingleIcon', { template: sortSingleIcon })
  .component('calculator', { template: calculator })
  .component('house', { template: house })
  .component('twoPerson', { template: twoPerson })
  .component('filter', { template: filter })
  .component('chevLeft', { template: chevLeft })
  .component('tabFlagCompletedIcon', { template: tabFlagCompletedIcon })
  .component('tabIncompleteIcon', { template: tabIncompleteIcon })
  .component('closeRedIcon', { template: closeRedIcon })
  .component('checkGreenIcon', { template: checkGreenIcon })
  .component('americanExpressCard', { template: americanExpressCard })
  .component('bankCard', { template: bankCard })
  .component('dinersClubCard', { template: dinersClubCard })
  .component('masterCard', { template: masterCard })
  .component('otherCard', { template: otherCard })
  .component('visaCard', { template: visaCard })
  .component('loanToolsGreenIcon', { template: loanToolsGreenIcon })
  .component('marketingIcon', { template: marketingIcon })
  .component('addSmGrayIcon', { template: addSmGrayIcon })
  .component('iconPin', { template: iconPin })
  .component('iconNote', { template: iconNote })
  .component('iconNotes', { template: iconNotes })
  .component('iconNotesCircle', { template: iconNotesCircle })
  .component('iconListCircle', { template: iconListCircle })
  .component('iconFolderCircle', { template: iconFolderCircle })
  .component('iconTextFile', { template: iconTextFile })
  .component('iconSlantBell', { template: iconSlantBell })
  .component('recommendationIcon', { template: recommendationIcon })
  .component('actionsPanelIcon', { template: actionsPanelIcon })
  .component('iconPassed', { template: iconPassed })
  .component('iconWarning', { template: iconWarning })
  .component('calculateIcon', { template: calculateIcon })
  .component('insuranceNotes', { template: insuranceNotes })
  .component('iconFlag', { template: iconFlag })
  .component('iconDependent', { template: iconDependent }).name;
