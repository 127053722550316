export const deleteLoan = ({
  loanScenarioId,
  loanScenarioService,
  inactiveFactFindSweetAlertAction,
  activeFactFindCrmConfirmationAction,
}) => {
  if (loanScenarioId) {
    loanScenarioService.scenarioDetailsGet(loanScenarioId).then((response) => {
      const isFactFindActive = response.data.IsLockable;
      const isFactFindLocked = !response.data.IsLocked;

      if (isFactFindActive && isFactFindLocked) {
        return activeFactFindCrmConfirmationAction();
      }
      inactiveFactFindSweetAlertAction();
    });
  } else {
    inactiveFactFindSweetAlertAction();
  }
};
