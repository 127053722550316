import template from './videoModalForYoutube.html';
import controller from './videoModalForYoutubeCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    pageSource: '<',
    modalInstance: '<',
  },
};
