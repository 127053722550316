import angular from 'angular';

export const loadModule = () =>
  angular.module('app').factory('nodifiIntegrationModel', [
    'httpRequest',
    function (httpRequest) {
      var apiBaseUrl = 'nodifi-integration';

      function nodifiIntegration() {}

      nodifiIntegration.prototype = {
        CreateApplication(
          loanScenarioId,
          assetProductType,
          callback,
          errorCallback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}/${loanScenarioId}/create-application?assetProductType=${assetProductType}`,
            null,
            (response) => {
              return callback(response);
            },
            (error) => {
              errorCallback(error);
            },
          );
        },
      };

      return nodifiIntegration;
    },
  ]);
