import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('noRedirectNzCorp', function noRedirectNzCorp(
      $timeout,
      currentUserService,
    ) {
      return {
        link: (scope, elem, attrs) => {
          const timeoutRes = $timeout(() => {
            if (!currentUserService.isNzCorp) {
              return;
            }
            attrs.$set('uiSref', ' ');
            elem.removeAttr('href');
            $timeout.cancel(timeoutRes);
          });
          // eslint-disable-next-line unicorn/consistent-function-scoping
          const eventHandler = (event) => {
            currentUserService.isNzCorp && event.preventDefault();
          };
          elem.on('click', eventHandler);
          scope.$on('$destroy', () => {
            elem.off('click', eventHandler);
          });
        },
      };
    });
