import { filter, map, find, orderBy, remove } from 'lodash';
import { EMPLOYMENT, NZBN_PROPERTY } from 'Common/constants/employment';
import { clientTitles } from 'Common/utilities/client';
import { getMonths, getYears } from 'Common/utilities/calendar';
import { KEY_CODE } from 'Common/constants/keyCode';
import {
  employmentInstance,
  assignEmploymentDefaultDay,
} from './util/employmentInfo';
import { getEmploymentDays } from '../util/employmentModal';

class EmploymentInfoCtrl {
  constructor(
    loanApplicationServices,
    employmentModalService,
    loanScenarioService,
    currentUserService,
    employmentService,
    utilitiesService,
    contactService,
    optionsService,
    configService,
    colorService,
    uiService,
    $timeout,
  ) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.employmentModalService = employmentModalService;
    this.loanScenarioService = loanScenarioService;
    this.currentUserService = currentUserService;
    this.employmentService = employmentService;
    this.utilitiesService = utilitiesService;
    this.contactService = contactService;
    this.optionsService = optionsService;
    const { feature } = configService;
    this.featureService = feature;
    this.colorService = colorService;
    this.uiService = uiService;
    this.$timeout = $timeout;
    this.NZBN_PROPERTY = NZBN_PROPERTY;
    this.months = getMonths();
    this.years = getYears();
  }

  $onInit() {
    this.employment.employmentNature = NZBN_PROPERTY.REGISTERED_COMPANY;
    this.employmentUtilityInstance = employmentInstance(this);

    this.showPersonList = false;
    this.employmentUtilityInstance.getEmploymentNature();
    this.getEmploymentStatus();
    this.getEmploymentBasis();
    this.getEmploymentType();
    this.clearRoleLookup();
    this.getPersonList();
    this.getTitleList();
    this.dateModalOpen = false;
  }

  employmentDefaultType() {
    this.employmentUtilityInstance.employmentNatureType();
  }

  selectClient(person) {
    const {
      PersonId: ClientId,
      FirstName,
      LastName,
      initials,
      background,
      FamilyId,
    } = person;
    this.employment.setEmploymentData({
      background,
      FirstName,
      ClientId,
      LastName,
      FamilyId,
      initials,
    });
    this.showPersonList = false;
  }

  closePersonList() {
    this.showPersonList = false;
  }

  setEmploymentType(id) {
    this.employmentUtilityInstance.employmentType();
    this.getSelectedEmploymentType({ employmentId: id });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getPersonList() {
    if (!this.isLoanApp) {
      this.contactService.familyInfoGet(this.familyId).then((response) => {
        const { data } = response;
        if (!data) {
          return;
        }

        const list = filter(data, (o) => o.Role === 'Adult');

        if (list && list.length) {
          const newList = map(list, (o) => {
            o.initials = this.utilitiesService.filterInitialOneString(
              o.FullName,
            );
            o.background = this.colorService.getRandomColor();
            o.FamilyId = this.familyId;
            return o;
          });

          const person =
            find(newList, (o) => this.clientId === o.PersonId) || newList[0];
          this.personList = newList;
          this.selectClient(person);
        }
      });
    } else {
      this.loanScenarioService
        .scenarioApplicantListGet(this.loanAppId)
        .then((response) => {
          const { data } = response;
          if (
            !data ||
            (data &&
              data.InvolvedPartyPerson &&
              !Object.keys(data.InvolvedPartyPerson).length)
          ) {
            return;
          }
          const list = [];
          const { InvolvedPartyPerson: involvedPersons } = data;

          Object.keys(involvedPersons).forEach((familyId) => {
            const clientObjs = involvedPersons[familyId].reduce(
              (accumulator, client) => {
                client.FullName = `${client.PreferredName} ${client.LastName}`;
                client.initials = this.utilitiesService.filterInitialOneString(
                  client.FullName,
                );
                client.background = this.colorService.getRandomColor();
                client.listPriority = client.IsApplicant ? 0 : 1;
                return accumulator.concat(client);
              },
              [],
            );
            list.push(...clientObjs);
          });

          const person = find(list, (o) =>
            !this.clientId
              ? +this.familyId === +o.FamilyId
              : +this.clientId === +o.PersonId,
          );
          this.personList = orderBy(list, ['listPriority'], ['asc']);
          const defaultPerson = person || this.personList[0];
          this.selectClient(defaultPerson);
        });
    }
  }

  getEmploymentStatus() {
    this.loanApplicationServices.getEmploymentStatus().then((response) => {
      const { data } = response;
      if (!data) {
        return;
      }

      const validStatus =
        filter(
          data,
          (status) => status.Name === EMPLOYMENT.PREVIOUS_EMPLOYMENT,
        ) || [];
      const PrevEmpId = validStatus.length
        ? validStatus.reduce((accum, status) => parseInt(status.Value, 10), 0)
        : 0;

      this.employment.setEmploymentData({ PrevEmpId });

      const newData = data.map((obj) => {
        obj.Value = parseInt(obj.Value, 10);
        return obj;
      });

      let statusList;
      if (this.employmentService.isCurrentEmploymentStatusShown()) {
        statusList = filter(
          newData,
          (status) => status.Name !== EMPLOYMENT.PREVIOUS_EMPLOYMENT,
        );
      } else {
        statusList = newData;
      }

      if (statusList && statusList.length && this.action !== 'edit') {
        const EmploymentStatusId = statusList[0].Value;
        this.employment.setEmploymentData({ EmploymentStatusId });
        this.statusList = statusList;
      }
    });
  }

  getTitleList() {
    this.employment.EmployerTitleList = [...clientTitles()];
  }

  getEmploymentType() {
    this.loanApplicationServices.getEmploymentType().then((response) => {
      const { data } = response;
      if (!data) {
        return;
      }
      const newData = data.map((obj) => {
        obj.Value = parseInt(obj.Value, 10);
        return obj;
      });
      remove(newData, (obj) => !obj.Value);
      if (newData && newData.length && this.action !== 'edit') {
        const EmploymentTypeId = newData[0].Value;
        this.employment.setEmploymentData({ EmploymentTypeId });
        this.employmentTypes = newData;
      }
    });
  }

  getEmploymentBasis() {
    this.optionsService.getEmploymentBasis().then((response) => {
      const { data } = response;
      if (!data) {
        return;
      }
      /**
       * Detect CamelCase and add space in between words
       * */
      const newData = data.map((obj) => {
        obj.Value = parseInt(obj.Value, 10);
        obj.Name = obj.Name.replace(/([a-z](?=[A-Z]))/g, '$1 ');
        return obj;
      });

      remove(newData, (obj) => !obj.Value);

      if (newData && newData.length && this.action !== 'edit') {
        const EmploymentBasisId = newData[0].Value;
        this.employment.setEmploymentData({ EmploymentBasisId });
        this.basisList = newData;
      }
    });
  }

  clearRoleLookup() {
    const data = {
      empRolesWrapper: { empRoles: [] },
      EmploymentRollId: 0,
      selectedLookup: '',
      EmploymentRole: '',
      empRoleCount: 0,
      criteria: '',
    };
    this.employment.setEmploymentData(data);
  }

  searchRoleMethod(event) {
    this.$timeout.cancel(this.searchTimeout);
    if (event.which === KEY_CODE.ESC) {
      return;
    }
    this.employment.setEmploymentData({ EmploymentRollId: 0 });
    this.showSpinner = true;
    this.searchTimeout = this.$timeout(() => {
      if (!this.employment.EmploymentRole) {
        return;
      }
      this.optionsService
        .employmentRoleGet(this.employment.EmploymentRole)
        .then((response) => {
          this.employmentRolesList =
            response && response.data ? response.data : [];
          this.$timeout.cancel(this.searchTimeout);
          this.showSpinner = false;
        });
    }, 800);
  }

  focusSetter(field, value) {
    this[field] = value;
  }

  showList() {
    return this.isFieldFocus || this.isListFocus;
  }

  clearRoleList() {
    this.employmentRolesList = [];
  }

  pickRole(role) {
    const { ProfessionTitle, ProfessionID: EmploymentRollId } = role;
    const data = {
      selectedLookup: ProfessionTitle,
      criteria: ProfessionTitle,
      EmploymentRole: ProfessionTitle,
      EmploymentRollId,
    };
    this.employment.setEmploymentData(data);
    this.clearRoleList();
  }

  updateDate() {
    this.dateModalOpen = true;
  }

  setToggleValue(field, callback) {
    if (!this.employment) {
      return;
    }
    this.employment[field] = !this.employment[field];
    if (typeof callback !== 'function') {
      return;
    }
    callback();
  }

  openDate(dateField) {
    this.employment.openDate(dateField);
    this.updateDate();
  }

  handleSetShowPersonList({ isOpen }) {
    this.showPersonList = isOpen;
  }

  onDateChange(isStarted) {
    getEmploymentDays(this.employment);
    assignEmploymentDefaultDay(this.employment, isStarted);
  }
}

export default EmploymentInfoCtrl;
