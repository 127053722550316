import template from './pipelineLeadFilter.html';

export default {
  template,
  bindings: {
    pipelineType: '<',
    pipelineTypes: '<',
    isCorporateUser: '<',
    settings: '<',
    statusListRadio: '<',
    substatusList: '<',
    advisersList: '<',
    labelsList: '<',
    probabilityList: '<',
    enquirySourceList: '<',
    referrerList: '<',
    activeRangeIndex: '<',
    displayFilterDate: '<',
    selectedRange: '<',
    filterRange: '<',
    pipelineSharedData: '<',
    onCalendarUpdated: '&',
    onRangeSelected: '&',
    filterColumns: '<',
    onHideShowColumn: '&',
    onChangeSettings: '&',
    onChangeStatusSettings: '&',
    assistantAdviserList: '<',
    showAssistantFilter: '<',
  },
};
