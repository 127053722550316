import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'ForDisplayProductFinderTableColumnCtrl',
      function ForDisplayProductFinderTableColumnCtrl(
        $scope,
        $uibModalInstance,
        colmuns,
      ) {
        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
        $scope.cols = colmuns;
        $scope.done = function () {
          $scope.cancel();
        };
      },
    );
