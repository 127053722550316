import { generalMobileSearchBuilderForUI } from 'Common/mappers/dashboard';
import _ from 'lodash';

const DEFAULT_LIST_LIMIT = 3;
const DEFAULT_RESULT_SORT = [
  'Customers',
  'Client',
  'Adviser',
  'Assistant',
  'Adviser Organisation',
  'Referrer',
  'Referrer Organisation',
  'Home Phone',
  'Business Phone',
  'Mobile Phone',
  'Company',
  'Loans',
  'Insurance',
  'Emails',
];

class GeneralMobileSearchCtrl {
  constructor($sce, $state, $stateParams, uiService, dashboardService) {
    'ngInject';

    this.$sce = $sce;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.uiService = uiService;
    this.dashboardService = dashboardService;
  }

  $onInit() {
    this.results = [];
    this.isSearching = false;
    this.defaultListLimit = DEFAULT_LIST_LIMIT;

    if (this.$stateParams.searchText) {
      this.searchText = this.$stateParams.searchText;
      this.search();
    }
  }

  $onChanges(changes) {
    if (!changes || !changes.searchText || !changes.searchText.currentValue) {
      return;
    }

    this.search();
  }

  search() {
    if (!this.searchText) {
      return;
    }

    this.isSearching = true;
    this.results = [];
    return this.dashboardService
      .getGenralSearchResult(this.searchText)
      .then(({ data = [] }) => data.map(generalMobileSearchBuilderForUI))
      .then((values) => {
        this.results = _.sortBy(
          values.map((value) => ({
            ...value,
            limit: DEFAULT_LIST_LIMIT,
            result: value.result.map((result) => ({
              ...result,
              displayName:
                result.resultName ||
                result.providerName ||
                result.resultContent,
            })),
          })),
          (value) => DEFAULT_RESULT_SORT.indexOf(value.resultType),
        );
        this.isSearching = false;
      });
  }

  highlight(text) {
    return this.$sce.trustAsHtml(
      this.searchText
        ? text.replace(new RegExp(this.searchText, 'gi'), '<strong>$&</strong>')
        : text,
    );
  }

  static setResultLimit(resultType, value) {
    if (value.resultType !== resultType) {
      return value.limit;
    }

    return value.limit === DEFAULT_LIST_LIMIT
      ? value.result.length
      : DEFAULT_LIST_LIMIT;
  }

  toggleListLimit(resultType) {
    if (!resultType) {
      return;
    }

    this.results = this.results.map((value) => ({
      ...value,
      limit: GeneralMobileSearchCtrl.setResultLimit(resultType, value),
    }));
  }

  goTo(value) {
    if (!value) {
      return;
    }

    this.uiService.searchMainObject.isGeneralSearchEnable = false;
    this.uiService.generalSearchText = null;

    switch (value.resultType.toLowerCase()) {
      case 'entity':
        this.$state.go('app.BusinessAccount', { familyId: value.resultId });
        break;
      case 'adviser organisation':
        this.$state.go('app.contactsAdviserOrg', {
          adviserOrgId: value.resultId,
          adviserComplianceId: value.clientId,
        });
        break;
      case 'adviser':
      case 'principal adviser':
        this.$state.go('app.contactsAdviser', {
          clientId: value.clientId,
          familyId: value.resultId,
        });
        break;
      case 'assistant':
        this.$state.go('app.contactsAssistant', {
          clientId: value.clientId,
          familyId: value.resultId,
        });
        break;
      case 'referrer organisation':
      case 'referrer org':
        this.$state.go('app.contactsReferrerOrg', {
          referrerComplianceId: value.clientId,
          referrerOrgId: value.resultId,
        });
        break;
      case 'referrer':
        this.$state.go('app.contactsReferrer', {
          clientId: value.clientId,
          familyId: value.resultId,
        });
        break;
      default:
        this.$state.go('app.contactsSingle', {
          familyId: value.resultId,
          activeTab: 'summary',
        });
        break;
    }
  }
}

export default GeneralMobileSearchCtrl;
