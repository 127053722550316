export const FIXED_RATE_EXPIRES_ID = 1;
export const APPROVAL_EXPIRIES_ID = 2;
export const BIRTHDAYS_ID = 3;
export const ANNUAL_REVIEWS_ID = 4;
export const POST_SETTLEMENT_FOLLOW_UP_ID = 5;
export const INTEREST_ONLY_ID = 6;
export const FIRE_GENERAL_ID = 26;
export const DEFAULT_CUSTOMER_CARE_DATE_RANGE = 4;

export const CUSTOMER_CARE_TYPES = {
  POST_SETTLEMENT_FOLLOW_UP: 'SETTLEMENT',
  FIXED_RATE_EXPIRES: 'FIXED_RATE_EXPIRY',
  APPROVAL_EXPIRIES: 'PRE_APPROVAL_EXPIRY',
  BIRTHDAYS: 'BIRTH_DAY',
  ANNUAL_REVIEWS: 'CLIENT_ANNUAL_REVIEW',
  INTEREST_ONLY_EXPIRY: 'INTEREST_ONLY_EXPIRY',
};

export const COMPLETED_EVENT_TYPE = {
  FIXED_RATE_EXPIRY: 3,
  INTEREST_ONLY_EXPIRY: 6,
};

export const SERVICE_TAB_INDEX = {
  FIXED_RATE_EXPIRES: 0,
  APPROVAL_EXPIRIES: 1,
  INTEREST_ONLY_EXPIRIES: 2,
  BIRTHDAYS: 3,
  ANNUAL_REVIEWS: 4,
  POST_SETTLEMENT: 5,
};

export const SERVICE_ID = {
  ANNUAL_REVIEWS: 1,
  POST_SETTLEMENT: 2,
  BIRTHDAYS: 3,
  FIXED_RATE_EXPIRES: 4,
  APPROVAL_EXPIRIES: 5,
  INTEREST_ONLY_EXPIRIES: 7,
};
export const SERVICE_ID_NZ = {
  ANNUAL_REVIEWS: 8,
  POST_SETTLEMENT: 9,
  BIRTHDAYS: 10,
  FIXED_RATE_EXPIRES: 11,
  APPROVAL_EXPIRIES: 12,
  INTEREST_ONLY_EXPIRIES: 13,
};
export const MARKETING_TO_CUSTOMER_ID = {
  [SERVICE_ID.ANNUAL_REVIEWS]: 'subscribe_StayInTouchAnnualReview',
  [SERVICE_ID.POST_SETTLEMENT]: 'subscribe_StayInTouchSettlement',
  [SERVICE_ID.BIRTHDAYS]: 'subscribe_HappyBirthday',
  [SERVICE_ID.FIXED_RATE_EXPIRES]: 'subscribe_FixedRateExpiry',
  [SERVICE_ID.APPROVAL_EXPIRIES]: 'subscribe_PreApprovalExpiry',
  [SERVICE_ID.INTEREST_ONLY_EXPIRIES]: 'subscribe_InterestOnly',
};

export const TAB_FIXED_RATE = {
  id: FIXED_RATE_EXPIRES_ID,
  label: 'Fixed Rate Expiries',
  type: CUSTOMER_CARE_TYPES.FIXED_RATE_EXPIRES,
  dateName: 'expiryDate',
};

export const TAB_APPROVAL_EXPIRIES = {
  id: APPROVAL_EXPIRIES_ID,
  label: 'Pre-Approval Expiries',
  type: CUSTOMER_CARE_TYPES.APPROVAL_EXPIRIES,
  dateName: 'expiryDate',
};

export const TAB_BIRTHDAYS = {
  id: BIRTHDAYS_ID,
  label: 'Birthdays',
  type: CUSTOMER_CARE_TYPES.BIRTHDAYS,
  dateName: 'dateOfBirth',
};

export const TAB_ANNUAL_REVIEWS = {
  id: ANNUAL_REVIEWS_ID,
  // eslint-disable-next-line sonarjs/no-duplicate-string
  label: 'Annual Reviews',
  type: CUSTOMER_CARE_TYPES.ANNUAL_REVIEWS,
  dateName: 'reviewYear',
};

export const TAB_POST_SETTLEMENT_FOLLOW_UP = {
  id: POST_SETTLEMENT_FOLLOW_UP_ID,
  label: 'Post Settlement Follow Up',
  type: CUSTOMER_CARE_TYPES.POST_SETTLEMENT_FOLLOW_UP,
  dateName: 'settlementName',
};

export const TAB_INTEREST_ONLY = {
  id: INTEREST_ONLY_ID,
  label: 'Interest Only Expiries',
  type: CUSTOMER_CARE_TYPES.INTEREST_ONLY_EXPIRY,
  dateName: 'expiryDate',
};

export const ORG_SUBSCRIPTION = {
  NONE: 0,
  SOME: 1,
  ALL: 2,
};

export const SUBSCRIPTION_MESSAGES = {
  NONE: `You have opted in to a subscription where these messages are being sent automatically to your clients. The associated clients have been hidden from this view to prevent accidental duplicate communications.`,
  SOME: `At least one broker has opted in to a subscription where these messages are being sent automatically. Their associated clients have been hidden from this view to prevent accidental duplicate communications.`,
  ALL: `All brokers in your business have opted in to a subscription where these messages are being sent automatically. Their associated clients have been hidden from this view to prevent accidental duplicate communications.`,
};

export const INSURANCE_TABS = [
  {
    id: 0,
    label: 'Loading / Exclusions',
    type: 'LOADING_EXCLUSION',
    dateName: 'reviewDate',
  },
  {
    id: 1,
    label: 'Issued Follow Up',
    type: 'ISSUED_FOLLOWUP',
    dateName: 'reviewDate',
  },
  {
    id: 2,
    label: 'Current Claims',
    type: 'CURRENT_CLAIM',
    dateName: 'reviewDate',
  },
  {
    id: 3,
    label: 'In Arrears',
    type: 'IN_ARREARS',
    dateName: 'reviewDate',
  },
  {
    id: 4,
    label: 'Deferral Reviews',
    type: 'DEFERRAL_REVIEWS',
    dateName: 'reviewDate',
  },
  {
    id: 5,
    label: 'Annual Reviews',
    type: 'CLIENT_ANNUAL_REVIEW',
    dateName: 'reviewDate',
  },
];

export const INSURANCE_TAB_ID = {
  LOADING_EXCLUSIONS: 0,
  ISSUED_FOLLOWUP: 1,
  CURRENT_CLAIMS: 2,
  IN_ARREARS: 3,
  DEFERRAL_REVIEWS: 4,
  ANNUAL_REVIEWS: 5,
};

export const ANNUAL_REVIEW_DATE_FILTER = {
  PREVIOUS_MONTH: 1,
  CURRENT_MONTH: 0,
  NEXT_2_MONTHS: 2,
  NEXT_3_MONTHS: 3,
  ALL: 4,
};

export const SEND_TYPE = {
  ALL: 'all',
  SINGLE: 'single',
};

export const CUSTOMER_CARE_TABS = [
  {
    index: SERVICE_TAB_INDEX.FIXED_RATE_EXPIRES,
    id: 'fixedRateExpires',
    urlValue: 'fixed-rate-expiries',
    heading: 'Fixed Rate Expiries',
    page: 'assets/views/customerCare/partials/fixed_rate.html',
  },
  {
    index: SERVICE_TAB_INDEX.APPROVAL_EXPIRIES,
    id: 'preApprovalExpiries',
    urlValue: 'pre-approval-expiries',
    heading: 'Pre-Approval Expiries',
    page: 'assets/views/customerCare/partials/pre_approval.html',
  },
  {
    index: SERVICE_TAB_INDEX.INTEREST_ONLY_EXPIRIES,
    id: 'interestOnly',
    urlValue: 'interest-only-expiries',
    heading: 'Interest Only Expiries',
    component: `<customer-care-interest-only-expiry
        date-filter="customerCareDateRange"
        assigned-filter="customerCareAdviserList"
        customer-care-data="customerCareData"
        user-info="userInfo"
        auto-marketing-subscription="autoMarketingSubscription"
      ></customer-care-interest-only-expiry>`,
  },
  {
    index: SERVICE_TAB_INDEX.BIRTHDAYS,
    id: 'birthdays',
    urlValue: 'birthdays',
    heading: 'Birthdays',
    page: 'assets/views/customerCare/partials/birthdays.html',
  },
  {
    index: SERVICE_TAB_INDEX.ANNUAL_REVIEWS,
    id: 'annualReviews',
    urlValue: 'annual-reviews',
    heading: 'Annual Reviews',
    page: 'assets/views/customerCare/partials/annual_reviews.html',
  },
  {
    index: SERVICE_TAB_INDEX.POST_SETTLEMENT,
    id: 'postSettlementFollowUp',
    urlValue: 'post-settlement-follow-up',
    heading: 'Post Settlement Follow Up',
    page: 'assets/views/customerCare/partials/post_settlement.html',
  },
];

export const POST_SETTLEMENT_FOLLOW_UP_TAB = [
  {
    index: 0,
    id: 'tab1',
    name: 'Settlement Day - SMS / Email',
    urlValue: 'settlement-day-sms-email',
    typeId: 1,
  },
  {
    index: 1,
    id: 'tab2',
    name: '7 Days - Settlement Gift',
    urlValue: '7-days-settlement-gift',
    typeId: 2,
  },
  {
    index: 2,
    id: 'tab3',
    name: '6 Week Account Check Up',
    urlValue: '6-week-account-check-up',
    typeId: 3,
  },
  {
    index: 3,
    id: 'tab4',
    name: '3 Months - How is it going?',
    urlValue: '3-months-how-is-going',
    typeId: 4,
  },
  {
    index: 4,
    id: 'tab5',
    name: '6 Months - Asset Finance',
    urlValue: '6-months-asset-finance',
    typeId: 5,
  },
];

const TAB = SERVICE_TAB_INDEX;
export const CUSTOMER_CARE_TAB_URL_VALUES = {
  FIXED_RATE_EXPIRES: CUSTOMER_CARE_TABS[TAB.FIXED_RATE_EXPIRES].urlValue,
  APPROVAL_EXPIRIES: CUSTOMER_CARE_TABS[TAB.APPROVAL_EXPIRIES].urlValue,
  BIRTHDAYS: CUSTOMER_CARE_TABS[TAB.BIRTHDAYS].urlValue,
  ANNUAL_REVIEWS: CUSTOMER_CARE_TABS[TAB.ANNUAL_REVIEWS].urlValue,
  POST_SETTLEMENT: CUSTOMER_CARE_TABS[TAB.POST_SETTLEMENT].urlValue,
  INTEREST_ONLY_EXPIRY: CUSTOMER_CARE_TABS[TAB.INTEREST_ONLY_EXPIRIES].urlValue,
};

export const CUSTOMER_CARE_TAB_ID = {
  FIXED_RATE_EXPIRES: CUSTOMER_CARE_TABS[TAB.FIXED_RATE_EXPIRES].id,
  APPROVAL_EXPIRIES: CUSTOMER_CARE_TABS[TAB.APPROVAL_EXPIRIES].id,
  BIRTHDAYS: CUSTOMER_CARE_TABS[TAB.BIRTHDAYS].id,
  ANNUAL_REVIEWS: CUSTOMER_CARE_TABS[TAB.ANNUAL_REVIEWS].id,
  POST_SETTLEMENT: CUSTOMER_CARE_TABS[TAB.POST_SETTLEMENT].id,
  INTEREST_ONLY_EXPIRY: CUSTOMER_CARE_TABS[TAB.INTEREST_ONLY_EXPIRIES].id,
};

export const POST_SETTLEMENT_FOLLOW_UP_DEFAULT_TAB =
  POST_SETTLEMENT_FOLLOW_UP_TAB[0].urlValue;
export const CUSTOMER_CARE_TAB_POST_SETTLEMENT =
  CUSTOMER_CARE_TABS[TAB.POST_SETTLEMENT].urlValue;

export const DEFAULT = {
  DATE_RANGE: 60,
};

export const NURTURE_FLAG = 6;
