import { TERM_KEYS } from 'Common/constants/loan';
import { validateDate } from 'Common/utilities/date';
import { mapTermObj } from 'Common/utilities/loanStructure';

export const defaultToPrincipalInterest = ({
  $scope,
  currentUserService,
  setLoanStructureDetailsTemporary,
}) => {
  const PRINCIPAL_INTEREST_OPTION = 6;
  if (
    currentUserService.isNZ &&
    !setLoanStructureDetailsTemporary.LoanStructureType
  ) {
    setLoanStructureDetailsTemporary.LoanStructureType = `Principal and Interest`;
    $scope.calculateRepayment(
      setLoanStructureDetailsTemporary.LoanStructureType,
      PRINCIPAL_INTEREST_OPTION,
    );
  }
};

export const loanSplitType = ({ $scope, currentUserService }) => {
  $scope.selectLoanType = [
    'Principal and Interest',
    'Interest Only',
    'Revolving Credit',
    currentUserService.isNZ && 'Offset',
  ].filter((type) => type);
};

export const setupTermTable = ({ $scope, loanStructureDetails }) => {
  const { InterestOnlyTerm } = loanStructureDetails;
  const { InterestOnlyDate, RepaymentHolidayDate, FixedRateDate } = {
    ...loanStructureDetails.ImportantDates,
  };

  $scope.termTableDates = {
    fixedRate: mapTermObj(FixedRateDate),
    repaymentHoliday: mapTermObj(RepaymentHolidayDate),
    interestOnly: {
      ...mapTermObj(InterestOnlyDate),
      termSelected: InterestOnlyTerm || null,
    },
  };
};

export const mapBorrowers = ({ $scope }) => {
  const isJoint = $scope.borrowingEntity.some(
    (val) => val.ticked && val.BorrowerID === 0,
  );
  const tickedBorrowers = $scope.borrowingEntity.filter((val) => val.ticked);
  return isJoint || !tickedBorrowers.length
    ? $scope.borrowersRawList
    : tickedBorrowers;
};

export const updateInterestOnly = ({ $scope }) => {
  const { interestOnly } = $scope.termTableDates;
  interestOnly.isLinked = true;
  interestOnly.dateStart = validateDate(interestOnly.dateStart) || new Date();
  $scope.updateImportantDates(TERM_KEYS.INTEREST_ONLY, interestOnly);
};

export const updateImportantDates = ({ $scope }) => (key, data) => {
  const importantDates = {
    ...$scope.setLoanStructureDetails.ImportantDates,
  };

  const {
    dateStart: StartDate,
    dateEnd: FinishDate,
    termSelected: Term,
    isLinked: IsLink,
  } = data;

  $scope.setLoanStructureDetails.ImportantDates = {
    ...importantDates,
    [key]: {
      StartDate,
      FinishDate,
      Term,
      IsLink,
    },
  };

  if (key === TERM_KEYS.INTEREST_ONLY) {
    $scope.setLoanStructureDetails = {
      ...$scope.setLoanStructureDetails,
      InterestOnlyTerm: Term,
    };
  } else if (key === TERM_KEYS.FIXED_RATE) {
    $scope.setLoanStructureDetails = {
      ...$scope.setLoanStructureDetails,
      FixedRateBegin: StartDate,
      FixedRateExpiry: FinishDate,
      RateTerm: Term,
      IsLinkRateExpiry: IsLink,
    };
  }
};
