import moment from 'moment';
import momentTimezone from 'moment-timezone';
import {
  DATE_PATTERN,
  ALT_DATE_INPUT_FORMATS,
  DD_MMM_YYYY_FORMAT,
  TO_MYCRM_DATE_FORMAT,
  DATE_TEXT_FORMAT,
  YYYY_MM_DD_DESERIALIZED,
  DATE_PICKER_UTC_MOMENT_FORMAT,
} from 'Common/constants/dateConfigs';
import { toastError } from 'Common/utilities/alert';
import { PICKER_RANGE } from 'Common/constants/pickerRange';
import { INVALID_DATE } from 'Common/constants/promptMessages';

export function isDateValid(date) {
  const currentDate = new Date(date);
  return parseInt(currentDate.getFullYear(), 0) > 1;
}

export function convertUtcToUserDate(date) {
  if (typeof date === 'string' && isDateValid(date)) {
    const dateConverted = date.slice(0, Math.max(0, date.indexOf('.')));
    return new Date(dateConverted);
  }
  return '';
}

/**
 * Converts the given string to the local date format. Dates are assumed to be UTC, so offsets
 * will be ignored
 * @param date The input to convert
 * @returns {string|Date} The local date if the input is a string and is Date-ish, otherwise an empty string
 */
export function convertToUserDateAssumeUtc(date) {
  if (typeof date !== 'string') {
    return '';
  }

  // Forces moment to assume the date is in UTC, ignoring any offsets that may be provided.
  const inputUtcDate = moment.utc(date, 'YYYY-MM-DDTHH:mm:ss[Z]');
  if (!inputUtcDate.isValid()) {
    return '';
  }

  return inputUtcDate.local().toDate();
}

export function setCurrrentDate() {
  return new Date();
}

export function setCurrentYear() {
  const currentDate = new Date();
  return currentDate.getFullYear();
}

export function setPreviousYear() {
  const currentDate = new Date();
  return currentDate.getFullYear() - 1;
}

export function setDateFormat(format = 'dd-MM-yyyy') {
  return format;
}

export function validateDate(date) {
  return date && isDateValid(date) ? new Date(date) : null;
}

export function getShortDatePattern() {
  return '(0[1-9]|1[0-9]|2[0-9]|3[01])\\-(0[1-9]|1[0-2])\\-\\d{4}|\\d{1,2}\\/\\d{1,2}\\/\\d{4}|([0-9]{8})';
}

export function customStringShortDate(date, delimiter = '/') {
  if (!date) {
    return;
  }
  const dateObject = new Date(date);
  const currentDate = dateObject.getDate();
  const currentMonth = dateObject.getMonth() + 1;
  const currentYear = dateObject.getFullYear();

  return `${currentDate}${delimiter}${currentMonth}${delimiter}${currentYear}`;
}

export function getDatePickerConfig(customConfig) {
  const defaultConfig = {
    pattern: DATE_PATTERN,
    altInputFormats: ALT_DATE_INPUT_FORMATS,
    format: DD_MMM_YYYY_FORMAT,
    dateOptions: { showWeeks: false },
  };

  return customConfig ? { ...defaultConfig, ...customConfig } : defaultConfig;
}

export const formatDate = (pattern) => (date) => {
  return `${moment(date).format(pattern)}`;
};

export const getStartOfMonth = (date, format = TO_MYCRM_DATE_FORMAT) => {
  return validateDate(date)
    ? moment(date).startOf('month').format(format)
    : null;
};

export const formatDateOffsetTimeToUTC = (date) => {
  return validateDate(date)
    ? formatDate(DATE_PICKER_UTC_MOMENT_FORMAT)(date)
    : null;
};

export const formatUTCDate = (pattern) => (date) => {
  return `${moment.utc(date).format(pattern)}`;
};

export function formatDateFilter(dateStart, dateEnd, isToday = true) {
  if (!dateStart) {
    return;
  }

  if (isToday) {
    return `Today, ${formatDate('DD MMM YYYY')(dateStart)}`;
  }

  return `From ${formatDate('DD MMM')(dateStart)} to ${moment(dateEnd).format(
    'DD MMM YYYY',
  )}`;
}

export function formatDateWithTimeZone(
  date,
  dateFormat,
  timezone,
  timeZoneFormat = 'z Z',
) {
  if (!date) {
    return;
  }

  const formattedDate = formatUTCDate(dateFormat)(date);
  if (formattedDate === 'Invalid date') {
    return;
  }

  const formattedTZ = momentTimezone.tz(timezone).format(timeZoneFormat);
  return `${formattedDate} ${formattedTZ}`;
}

export function humanizeDate(date) {
  if (!date || !validateDate(date)) {
    return '';
  }
  return moment(moment.utc(date).format()).from(
    moment.utc(new Date()).format(),
  );
}

export function dateLesserThan(date, unit = 4, frequency = 'week', startDate) {
  if (!date || !validateDate(date) || (startDate && !validateDate(startDate))) {
    return '';
  }
  if (startDate) {
    return moment(date) < moment(startDate).subtract(unit, frequency);
  }
  return moment(date) < moment().subtract(unit, frequency);
}

export function getStartDateOfThisWeek() {
  return moment().startOf('isoWeek').toDate();
}

export function getEndDateOfThisWeek() {
  return moment().endOf('isoWeek').toDate();
}

export function getStartDateOfLastWeek() {
  return moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
}

export function getEndDateOfLastWeek() {
  return moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
}

export function buildDateFromDropdown(day = 1, month = 1, year) {
  if (!day && !month && !year) {
    return null;
  }
  const dateString = [year, month, day].join('-');
  return validateDate(formatDate(TO_MYCRM_DATE_FORMAT)(dateString));
}

export function isStartEndDateDropdownsInvalid(startDate, endDate) {
  const startDateProps = [startDate.Day, startDate.Month, startDate.Year];
  const endDateProps = [endDate.Day, endDate.Month, endDate.Year];
  if (
    dateLesserThan(
      buildDateFromDropdown(...endDateProps),
      1,
      'day',
      buildDateFromDropdown(...startDateProps),
    )
  ) {
    toastError(INVALID_DATE.START_END);
    return true;
  }
  return false;
}

export const toDate = (date) => {
  return date ? moment(date).toDate() : null;
};

export const computeDays = (method = '', frequency = 'days') => (
  date,
  count,
) => {
  const validMethods = ['subtract', 'add'];
  const isInvalid =
    !validMethods.includes(method) || !date || !count || !validateDate(date);
  if (isInvalid) {
    return date || null;
  }
  return moment(date)[method](count, frequency);
};

export function calendarDateFormatter(activeRangeIndex, filterData) {
  const { DateStart, DateEnd } = filterData;
  if (!DateStart) {
    return;
  }
  if (activeRangeIndex === PICKER_RANGE.TODAY) {
    return `Today, ${moment(DateStart).format(DATE_TEXT_FORMAT)}`;
  }
  return `From ${moment(DateStart).format('DD MMM')} to ${moment(
    DateEnd,
  ).format(DATE_TEXT_FORMAT)}`;
}

export const isDateRangeValid = (start, end) => {
  if (!start || !end) {
    return false;
  }
  const mStart = moment(start);
  const mEnd = moment(end);
  return mStart.isSameOrBefore(mEnd);
};

export const dateWithValidator = (date, config = {}) => {
  const format = config.format || DATE_TEXT_FORMAT;
  const defaultWhenInvalid = config.default || '-';
  return isDateValid(date) ? formatDate(format)(date) : defaultWhenInvalid;
};

export const validRangeParams = (range) => {
  return range && range.dateStart && range.dateEnd;
};

export const mapDateRange = (range) => {
  if (!validRangeParams(range)) {
    return range;
  }
  return {
    DateStart: range.dateStart,
    DateEnd: range.dateEnd,
  };
};

export const formatRangeFilter = (range) => {
  if (!validRangeParams(range)) {
    return range;
  }
  const formatter = formatDate(YYYY_MM_DD_DESERIALIZED);
  return {
    dateStart: formatter(range.dateStart),
    dateEnd: formatter(range.dateEnd),
  };
};

export const formatDateTimezone = (date, format, timezone) => {
  if (!date || !timezone) {
    return '';
  }
  const formattedDate = moment(date).tz(timezone).format(format);
  return formattedDate === 'Invalid date' ? '' : formattedDate;
};

export const getDateTimestamp = (dateObj, inSeconds) => {
  const isValidDate = typeof dateObj.getMonth === 'function';
  if (!isValidDate) {
    return '';
  }
  const msTimestamp = dateObj.getTime();
  return inSeconds ? Number(msTimestamp) / 1000 : msTimestamp;
};

export const getTimestamp = (dateStr, setHours, inSeconds) => {
  if (!dateStr) {
    return '';
  }
  const date = new Date(dateStr);
  if (setHours) {
    date.setHours(setHours);
  }

  return getDateTimestamp(date, inSeconds);
};

export const getUtcTimestamp = (dateStr, inSeconds) => {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const utcDate = new Date(Date.UTC(year, month, day));
  return getDateTimestamp(utcDate, inSeconds);
};

export const dateHasMonthAndYear = (date) => {
  return !!(date?.Month && date?.Year);
};
