export const COMMISSION_TAB_URL = {
  COMMISSION: 'commission',
  SEARCH: 'search',
  PAYMENT_ADVICE: 'payments',
};

export const COMMISSION_TAB_INDEX = {
  [COMMISSION_TAB_URL.COMMISSION]: 0,
  [COMMISSION_TAB_URL.SEARCH]: 1,
  [COMMISSION_TAB_URL.PAYMENT_ADVICE]: 2,
};

export const DEFAULT_COMMISSION_TAB = COMMISSION_TAB_URL.COMMISSION;
