import { toastSuccess, toastError } from 'Common/utilities/alert';
import { NOT_FOUND, OK } from 'Common/constants/httpStatusCodes';
import { collectionCamelizeBuilderForUI } from '../mappers/genericMapper';

class AutomationRuleModelService {
  constructor(httpClient, uiService) {
    'ngInject';

    this.httpClient = httpClient;
    this.uiService = uiService;
    this.apiBaseUrl = 'automation-rule';
  }

  getAutomationRules(criteria = {}) {
    return this.httpClient
      .get(`${this.apiBaseUrl}`, criteria)
      .then(({ data }) => data.map(collectionCamelizeBuilderForUI));
  }

  applyTask({ loanId = 0, loanApplicationId = 0 }) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/apply-task`,
      {},
      { loanId, loanApplicationId },
      false,
    );
  }

  triggerAutomation(params) {
    if (!this.uiService.newPipelineTasks) {
      return Promise.resolve({});
    }
    return this.applyTask(params).then((response) => {
      const { data: message, status } = response;
      if (status === OK && !!message) {
        toastSuccess(message, '');
      } else if (status === NOT_FOUND) {
        toastError(message, 'Automation Rule Error');
      }
      return response;
    });
  }
}

export default AutomationRuleModelService;
