import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('usdInputWidth', function usdInputWidth($document) {
      return {
        require: 'ngModel',
        link(scope, el) {
          const placeholderLength =
            (el.context.getAttribute('placeholder').length + 1) * 20;
          const handler = () => {
            const textLength = (el.context.value.length + 1) * 20 + 28;
            el.context.style.width = el.context.value.length
              ? `${textLength}px`
              : `${placeholderLength}px`;
          };

          el.on('keyup', handler);
          el.on('keydown', handler);

          const cleanup = () => {
            $document.off('keydown, keyup', handler);
          };

          el.on('$destroy', cleanup);
        },
      };
    });
