import angular from 'angular';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'CompareLoanPdfPreviewModalCtrl',
      function CompareLoanPdfPreviewModalCtrl(
        $scope,
        $window,
        options,
        labels,
        colors,
        graphData,
        datasetOverride,
        loanAmountMine,
        loanAmountOther,
        loanTermMine,
        loanTermOther,
        interestRatesMine,
        interestRatesOther,
        otherFeesFrequencyMine,
        otherFeesFrequencyOther,
        interestSaved,
        timeSaved,
        loanProviderMine,
        loanProviderOther,
        totalRepaymentAmountMyLender,
        totalRepaymentAmountOtherLender,
        differenceText,
        myLenderFixedMonthlyRepayment,
        otherLenderFixedMonthlyRepayment,
        difference,
        myLenderOngoingMonthlyRepayments,
        otherLenderOngoingMonthlyRepayments,
        customThemeService,
        series,
        myLenderTableArrayXs,
        otherLenderTableArrayXs,
        loanCalculatorSharedData,
      ) {
        $scope.data = graphData;
        $scope.datasetOverride = datasetOverride;
        $scope.options = options;
        $scope.labels = labels;
        $scope.colors = colors;
        $scope.series = series;
        $scope.loanAmountMine = loanAmountMine;
        $scope.loanAmountOther = loanAmountOther;
        $scope.loanTermMine = loanTermMine;
        $scope.loanTermOther = loanTermOther;
        $scope.interestRatesMine = interestRatesMine;
        $scope.interestRatesOther = interestRatesOther;
        $scope.otherFeesFrequencyMine = otherFeesFrequencyMine;
        $scope.otherFeesFrequencyOther = otherFeesFrequencyOther;
        $scope.interestSaved = interestSaved;
        $scope.timeSaved = timeSaved;
        $scope.loanProviderMine = loanProviderMine;
        $scope.loanProviderOther = loanProviderOther;
        $scope.totalRepaymentAmountMyLender = totalRepaymentAmountMyLender;
        $scope.totalRepaymentAmountOtherLender = totalRepaymentAmountOtherLender;
        $scope.differenceText = differenceText;
        $scope.myLenderFixedMonthlyRepayment = myLenderFixedMonthlyRepayment;
        $scope.otherLenderFixedMonthlyRepayment = otherLenderFixedMonthlyRepayment;
        $scope.difference = difference;
        $scope.myLenderOngoingMonthlyRepayments = myLenderOngoingMonthlyRepayments;
        $scope.otherLenderOngoingMonthlyRepayments = otherLenderOngoingMonthlyRepayments;
        $scope.brandingLogo = loanCalculatorSharedData.brandingLogo;
        $scope.myLenderTableArrayXs = myLenderTableArrayXs;
        $scope.otherLenderTableArrayXs = otherLenderTableArrayXs;
        $scope.getUserInfo = loanCalculatorSharedData.getUserInfo;
        $scope.getUserInfo();

        $scope.date = new Date();
        $scope.downloadPdf = function () {
          const node = $window.document.querySelector('#makePDF');
          html2canvas(node, {
            useCORS: true,
            scale: 1,
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1);
            const pageHeight = 298;
            const doc = new JsPDF('p', 'mm', 'a4');
            doc.addImage(imgData, 'JPEG', -50, -12);
            doc.addPage('a4', 'l');
            doc.addImage(imgData, 'JPEG', -3, pageHeight * -1 - 16);
            doc.save('report.pdf');
          });
        };
      },
    );
