import withCredentials from 'Common/config/withCredentials';
import { getConfigData } from './config';

const getWithCredentials = () => {
  const env = getConfigData();
  if (!env || !env.sentry_env) {
    return false;
  }
  const credentialsKey = env.sentry_env;
  return withCredentials[credentialsKey] || false;
};

export function constructConfigRequest(configParam = {}) {
  const defaultConfig = {
    withCredentials: getWithCredentials(),
  };
  return { ...defaultConfig, ...configParam };
}
