import template from './recipientsList.html';
import controller from './recipientsListCtrl';
import './styles.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    label: '@',
    displayNameKey: '@',
    placeholder: '@',
    showRecipientsChoices: '<',
    contactDetail: '@',
    contactDetailKey: '@',
    contactPrimaryKey: '@',
    validationMessage: '<',
    recipientsChoices: '<',
    selectedRecipients: '<',
    onSelectRecipient: '&',
    onRemoveRecipient: '&',
  },
};
