import { MOBILE } from 'Common/constants/phoneMobileFormat';
import { CONTACT_TYPE } from 'Common/constants/contactType';
import { EMAIL_PATTERN } from 'Common/constants/pattern';
import { EMAIL } from 'Common/constants/emailTypes';
import { toastError } from 'Common/utilities/alert';
import { autoFormatUsername } from 'Common/utilities/contact';
import {
  extractUsername,
  showAdditionalText,
} from 'Common/utilities/onboarding';
import { isOwnBrand } from 'Common/utilities/brandingCategory';
import { getBasisRoleId } from './util/onboardingNewPartyCtrl';

class OnboardingNewParty {
  constructor(currentUserService, onboardingService, optionsService) {
    'ngInject';

    this.partyDetails = {};
    this.currentUserService = currentUserService;
    this.onboardingService = onboardingService;
    this.optionsService = optionsService;
    this.EMAIL_PATTERN = EMAIL_PATTERN;
    this.lmEmailDomain = EMAIL.LOANMARKET_DOMAIN;
  }

  updateHeaderText(partyLabel, partyIndex) {
    const prependText = showAdditionalText(partyLabel) ? 'Additional' : '';
    this.headerText = `${prependText} ${partyLabel} ${partyIndex}`.trim();
  }

  getIsWritingOptionText() {
    const party = this.partyLabel.toLowerCase();
    return this.partyDetails.isWritingValue
      ? `They will be a writing ${party}`
      : `They will be a non-writing ${party}`;
  }

  useLoanMarketEmail() {
    return !!(this.isStaff && !this.isOwnBranding);
  }

  setFieldsToDisplay() {
    const emailToDisplay = this.useLoanMarketEmail() ? 'lmEmail' : 'email';
    const commonFields = ['firstName', 'lastName', emailToDisplay];
    const staff = [...commonFields];
    const nonStaff = [...commonFields, 'middleName', 'mobile'];
    const displayArr = this.isStaff ? staff : nonStaff;
    this.displayArray =
      this.partyLabel === CONTACT_TYPE.LOAN_WRITER_STR
        ? displayArr.concat('loanWriterRole')
        : displayArr;
  }

  displayFormGroup(input) {
    return !!this.displayArray.includes(input);
  }

  getLoanWriterRoles() {
    this.optionsService.getOnboardingLoanWriterRole().then((response) => {
      this.loanWriterRoles = response;
      this.partyDetails.loanWriterBasisRoleId =
        this.partyDetails.loanWriterBasisRoleId || response[0].value;
    });
  }

  autoPopulateLMEmail() {
    if (!this.useLoanMarketEmail()) {
      return;
    }
    const { firstName, lastName } = this.partyDetails;
    const nameArr = [firstName, lastName];
    this.partyDetails.lmEmail = autoFormatUsername(nameArr);
  }

  getSaveButtonText() {
    return this.saveOnlyNoInvite
      ? 'Save'
      : 'Save and Invite to complete profile';
  }

  getEmailLabel() {
    return this.isStaff && this.isOwnBranding
      ? 'Preferred Email Address'
      : 'Email Address';
  }

  save(party, partyType) {
    if (this.partyForm.$invalid) {
      return;
    }
    this.saving = true;
    const familyId = this.isEdit ? party.familyId : 0;
    party.email = this.useLoanMarketEmail()
      ? `${party.lmEmail}${this.lmEmailDomain}`
      : party.email;

    this.onboardingService
      .getCheckIfEmailExists(party.email, familyId)
      .then(({ data: emailExists }) => {
        if (emailExists) {
          return Promise.reject(new Error('Email already exists'));
        }
        this.saveParty({ party, partyType });
      })
      .catch((error) => {
        toastError(error.message);
        this.saving = false;
      });
  }

  completeSave() {
    this.saving = false;
    if (!this.isEdit) {
      this.partyDetails = {};
    }
  }

  $onChanges(changes) {
    const { partyLabel, partyIndex, isSaveComplete, partyData } = changes;

    if (partyLabel && partyLabel.currentValue) {
      this.updateHeaderText(this.partyLabel, this.partyIndex);
    }

    if (partyIndex && partyIndex.currentValue) {
      this.updateHeaderText(this.partyLabel, this.partyIndex);
    }

    if (isSaveComplete && isSaveComplete.currentValue) {
      this.completeSave();
    }

    if (partyData && partyData.currentValue) {
      this.partyDetails = { ...partyData.currentValue };
      this.setFieldsToDisplay();

      if (!this.isStaff) {
        return;
      }
      const { email, roleId } = this.partyDetails;
      this.partyDetails.lmEmail = extractUsername(email, this.lmEmailDomain);
      if (this.partyLabel === CONTACT_TYPE.LOAN_WRITER_STR) {
        this.partyDetails.loanWriterBasisRoleId = getBasisRoleId(roleId);
      }
    }
  }

  $onInit() {
    this.mobileFormat = this.currentUserService.isAU ? MOBILE.AU : MOBILE.NZ;
    this.isOwnBranding = isOwnBrand(this.currentUserService.brandingCategoryID);
    this.setFieldsToDisplay();
    this.updateHeaderText(this.partyLabel, this.partyIndex);
    if (this.partyLabel === CONTACT_TYPE.LOAN_WRITER_STR) {
      this.getLoanWriterRoles();
    }
  }
}

export default OnboardingNewParty;
