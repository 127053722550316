import { getFullName, filterInitialOneString } from 'Common/utilities/contact';
import { showActionConfirmation } from 'Common/utilities/alert';
import {
  displayWritingOption,
  showAdditionalText,
} from 'Common/utilities/onboarding';
import { stringFormatter } from 'Common/utilities/string';
import { MOBILE } from 'Common/constants/phoneMobileFormat';
import { getBasisRoleId } from '../onboardingNewParty/util/onboardingNewPartyCtrl';

class OnboardingAddedParty {
  constructor(currentUserService, optionsService) {
    'ngInject';

    this.displayWritingOption = displayWritingOption;
    this.currentUserService = currentUserService;
    this.optionsService = optionsService;
  }

  cancelPartyAddition() {
    this.editMode = false;
    this.cancelEdit();
  }

  saveParty(party, partyType) {
    this.updateParty({ party, partyType });
  }

  edit() {
    this.editMode = true;
    this.saveCompleted = false;
    this.editParty({ index: this.partyIndex - 1, partyType: this.partyType });
  }

  remove() {
    showActionConfirmation(
      'Remove Party',
      'Are you sure you want to remove party?',
      (confirm) => {
        if (!confirm) {
          return;
        }
        this.deleteParty({
          party: this.partyDetails,
          partyType: this.partyType,
        });
      },
    );
  }

  getLoanWritersBasisValue() {
    if (
      !this.loanWriterList ||
      !this.loanWriterList.length ||
      !this.partyDetails.roleId
    ) {
      return '';
    }
    const basisRoleId = getBasisRoleId(this.partyDetails.roleId);
    const loanWriter = this.loanWriterList.find(
      (writer) => writer.value === basisRoleId,
    );
    return loanWriter.name || '';
  }

  initializeValues() {
    const prependText = showAdditionalText(this.partyType) ? 'Additional' : '';
    this.header = `${prependText} ${this.partyType} ${this.partyIndex}`.trim();

    const { firstName, lastName } = this.partyDetails;
    this.fullName = getFullName(firstName, lastName);
    this.initials = filterInitialOneString(this.fullName);
  }

  $onChanges(changes) {
    const { partyDetails } = changes;

    if (partyDetails && partyDetails.currentValue) {
      this.initializeValues();
      this.saveCompleted = true;
      this.editMode = false;
    }
  }

  $onInit() {
    this.stringFormatter = stringFormatter;
    this.mobileFormat = this.currentUserService.isAU ? MOBILE.AU : MOBILE.NZ;
    this.editMode = false;
  }
}

export default OnboardingAddedParty;
