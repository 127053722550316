import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('OpenTagModalCtrl', function OpenTagModalCtrl(
      $scope,
      $uibModalInstance,
      contactService,
      toaster,
      familyId,
      action,
      index,
      $timeout,
    ) {
      $scope.taggedSet = {};
      if (action === 'view' && typeof index !== 'undefined') {
        const taggedDetails = $scope.taggedListDetails[index];
        $scope.clientIsTagged = true;
        $scope.taggedSet = {
          familyId,
          taggedReason: taggedDetails.ActivityDetails,
          taggedId: taggedDetails.ActivityID,
        };
      } else {
        $scope.clientIsTagged = false;
        $scope.taggedSet = {
          familyId,
          taggedReason: '',
        };
      }

      $scope.tagFamily = function () {
        if ($scope.clientIsTagged) {
          contactService
            .taggedDelete($scope.taggedSet.familyId, $scope.taggedSet.taggedId)
            .then(() => {
              toaster.pop('success', 'Deleted', 'Tag successfully deleted');
            });
        } else {
          contactService
            .taggedSet($scope.taggedSet.familyId, $scope.taggedSet.taggedReason)
            .then(() => {
              toaster.pop('success', 'Added', 'Tag successfully added');
              $scope.getTaggedList();
            });
        }
        $timeout(() => {
          $uibModalInstance.dismiss('cancel');
        }, 100);
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    });
