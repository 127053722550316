export const REQUIRED_FIELD = [
  {
    field: 'providerId',
    name: 'Insurer',
  },
  {
    field: 'productId',
    name: 'Product',
  },
  {
    field: 'statusId',
    name: 'Status',
  },
  {
    field: 'paymentFrequencyId',
    name: 'Payment Frequency',
  },
];
