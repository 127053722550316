import template from './lendingExistingProductComparisons.html';
import controller from './lendingExistingProductComparisonsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    familyList: '<',
    facility: '<',
    deleteAllFavoriteProducts: '<',
  },
};
