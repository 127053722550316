import template from './mobileAccreditation.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    provider: '<',
    mainObject: '<',
    dateOptions: '<',
    dateFormatted: '<',
    showCalendar: '&',
    onChangeStatus: '&',
    onAccreditationChange: '&',
  },
};
