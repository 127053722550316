import { set } from 'lodash';
import { toastError } from 'Common/utilities/alert';
import { ADDRESS_TYPE } from 'Common/constants/addressModules';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  isStartEndDateDropdownsInvalid,
  dateHasMonthAndYear,
} from 'Common/utilities/date';
import { INVALID_DATE } from 'Common/constants/promptMessages';

export default class AddressLookupModalCtrl {
  constructor(generalService, addressLookupService) {
    'ngInject';

    this.generalService = generalService;
    this.addressLookupService = addressLookupService;
  }

  onClearAddress() {
    this.isExistingAddress = false;
    set(this, 'details.address.displayDetails', false);
    set(this, 'details.address.searchString', '');
  }

  toggleSearchAgainPopover() {
    this.openSearchAgainConfirmation = !this.openSearchAgainConfirmation;
  }

  dismiss() {
    this.modalInstance.dismiss();
  }

  handleUpdateAddress({ address }) {
    this.details.address = address;
  }

  handleUpdateClientDetails({ details }) {
    this.details.clientDetails = details;
  }

  isDateObjectValid(date) {
    return (!date?.Month && !date?.Year) || dateHasMonthAndYear(date);
  }

  isValidDateRange() {
    const { StartDate, EndDate, TypeId } = this.details.clientDetails;

    const invalidStart =
      (!dateHasMonthAndYear(StartDate) && dateHasMonthAndYear(EndDate)) ||
      !this.isDateObjectValid(StartDate);
    const invalidEnd = !this.isDateObjectValid(EndDate);

    if (invalidStart || invalidEnd) {
      if (invalidStart && invalidEnd) {
        toastError(INVALID_DATE.START_END);
        this.invalidDateRange = { start: true, end: true };
      } else if (invalidStart) {
        toastError(INVALID_DATE.START);
        this.invalidDateRange = { start: true, end: false };
      } else if (invalidEnd) {
        toastError(INVALID_DATE.END);
        this.invalidDateRange = { start: false, end: true };
      }

      return false;
    }

    const isPreviousAddress =
      parseToInt10(TypeId) === parseToInt10(ADDRESS_TYPE.PREVIOUS_ADDRESS);
    if (isPreviousAddress) {
      if (!dateHasMonthAndYear(EndDate)) {
        return true;
      } else if (isStartEndDateDropdownsInvalid(StartDate, EndDate)) {
        this.invalidDateRange = { start: true, end: true };
        return false;
      }
    }

    return true;
  }

  handleSubmit() {
    this.invalidDateRange = {};
    this.addressForm.$setSubmitted();
    if (this.addressForm.$invalid) {
      toastError(
        'Invalid Form or Required Field Empty. See highlighted field and fill out the correct value',
      );
      return;
    }
    if (!this.isValidDateRange()) {
      return false;
    }

    this.isGeocoding = true;
    this.addressLookupService
      .convertToAddressFormat(this.details.address)
      .then((address) => {
        this.isGeocoding = false;
        this.details = { ...this.details, address };
        this.modalInstance.close(this.details);
      })
      .catch(() => {
        this.isGeocoding = false;
        toastError('Invalid Address.');
      });
  }

  $onInit() {
    this.modalTitle = `${this.addressId ? 'Edit' : 'Add'} an Address`;
    this.isExistingAddress = !!this.addressId;
    this.openSearchAgainConfirmation = false;
  }
}
