import angular from 'angular';

export const loadModule = () =>
  angular.module('app').filter('percentage', function percentage() {
    return function (pData) {
      let data = pData;
      if (typeof data === 'undefined') {
        return '';
      }
      if (data.toString().includes('.') && Math.floor(data) !== -1) {
        const floatValue = (parseFloat(data) * 100).toFixed(2);
        data = `${floatValue}%`;
      }

      if (Math.floor(data) === -1) {
        data = 'n.a.';
      }

      if (parseInt(data, 10) < 1) {
        data = `${data.toString()}%`;
      }
      return data;
    };
  });
