import { pipelineLabelBuilder } from 'Common/utilities/pipelineLabels';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { formatDate } from 'Common/utilities/date';

export default class PipelineOpportunitySettings {
  constructor(services) {
    const {
      pipelineSharedData,
      pipelineService,
      contactService,
      $filter,
      $sce,
    } = services;
    this.pipelineService = pipelineService;
    this.contactService = contactService;
    this.$filter = $filter;
    this.$sce = $sce;
    this.isMergeLeadAndOpportunities =
      pipelineSharedData && pipelineSharedData.isMergeLeadAndOpportunities;
  }

  getHeaders() {
    const headerSettings = { isHidden: 0, isSortable: 1 };
    const leadColumnsInOpportunity = this.isMergeLeadAndOpportunities
      ? [
          {
            offlineSortObjKey: 'probability',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'Probability',
            dataSortObjectKey: 'Probability',
            label: 'Probability',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'subStatusName',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'Substatus',
            dataSortObjectKey: 'SubStatusName',
            label: 'Substatus',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'dateCreated',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'DateCreated',
            dataSortObjectKey: 'DateCreated',
            label: 'Date Created',
            ...headerSettings,
          },
          { label: 'subStatusList', isHidden: 1 },
          { label: 'pipelineCardsId', isHidden: 1 },
        ]
      : [];
    return this.contactService.getUserInfo().then(({ data }) => {
      if (!data) {
        return [];
      }
      const { AccessType } = data;
      const isCorporateUser = AccessType === ACCESS_TYPE.CORPORATE;

      return [
        {
          offlineSortObjKey: 'contactNames',
          isExclusionIncluded: false,
          dataSortObjectExcludedKey: '',
          dataSortObjectKey: 'ClientName',
          label: 'Client Name',
          ...headerSettings,
        },
        {
          offlineSortObjKey: 'title',
          isExclusionIncluded: true,
          dataSortObjectExcludedKey: 'OpportunityName',
          dataSortObjectKey: 'OpportunityName',
          label: 'Opportunity Name',
          ...headerSettings,
        },
        {
          offlineSortObjKey: 'proposedLoanAmount',
          isExclusionIncluded: true,
          dataSortObjectExcludedKey: 'ProposedLoan',
          dataSortObjectKey: 'ProposedLoan',
          label: 'Proposed Loan',
          ...headerSettings,
        },
        {
          offlineSortObjKey: 'pipelineStatus',
          isExclusionIncluded: true,
          dataSortObjectExcludedKey: 'Status',
          dataSortObjectKey: 'Status',
          label: 'Status',
          ...headerSettings,
        },
        {
          offlineSortObjKey: 'enquiry',
          isExclusionIncluded: true,
          dataSortObjectExcludedKey: 'EnquirySource',
          dataSortObjectKey: 'EnquirySource',
          label: 'Enquiry Source',
          ...headerSettings,
        },
        {
          offlineSortObjKey: 'adviserFullName',
          isExclusionIncluded: true,
          dataSortObjectExcludedKey: 'AdvisorName',
          dataSortObjectKey: 'Adviser',
          label: 'Adviser',
          ...headerSettings,
        },
        {
          offlineSortObjKey: 'referrerName',
          isExclusionIncluded: true,
          dataSortObjectExcludedKey: 'Referrer',
          dataSortObjectKey: 'Referrer',
          label: 'Referrer',
          ...headerSettings,
        },
        ...leadColumnsInOpportunity,
        {
          offlineSortObjKey: 'statusUpdated',
          isExclusionIncluded: true,
          dataSortObjectExcludedKey: 'SubStatusUpdated',
          dataSortObjectKey: 'StatusUpdated',
          label: 'Status Updated',
          ...headerSettings,
        },
        {
          offlineSortObjKey: 'labelsList',
          isExclusionIncluded: !isCorporateUser,
          dataSortObjectExcludedKey: 'Labels',
          dataSortObjectKey: 'Labels',
          label: 'Labels',
          ...headerSettings,
          isHidden: isCorporateUser,
        },
        { label: 'id', isHidden: 1 },
        { label: 'manageLabelOpen', isHidden: 1 },
        { label: 'loanId', isHidden: 1 },
        { label: 'loanScenarioId', isHidden: 1 },
        { label: 'pipelineStatusId', isHidden: 1 },
        { label: 'subStatusId', isHidden: 1 },
        { label: 'pipelineStatusID', isHidden: 1 },
        { label: 'subStatusID', isHidden: 1 },
      ];
    });
  }

  getTableData(pageNumber, pageSize, searchClientName) {
    const params = {
      pageNumber,
      pageSize,
      searchClientName,
    };
    return this.pipelineService.getOpportunityTable(params).then((response) => {
      if (!response || !response.length) {
        this.showLoader = false;
        return { totalRecords: 0, tableData: [] };
      }
      const { totalRecords } = response[0];
      const tableData = response.reduce((accum, current) => {
        const { dateCreated, probability, subStatusName, pipelineCardsId } =
          this.isMergeLeadAndOpportunities && current;

        const leadColumns = this.isMergeLeadAndOpportunities
          ? {
              probability,
              subStatusName,
              dateCreated: dateCreated
                ? formatDate('YYYY MM DD hh:mm:ss')(new Date(dateCreated))
                : '',
              subStatusList: [],
              pipelineCardsId,
            }
          : {};

        const {
          contactNames,
          title,
          proposedLoanAmount,
          pipelineStatus,
          enquiry,
          adviserFullName,
          referrerName,
          statusUpdated,
          pipelineStatusId,
          loanId,
          loanScenarioId,
          labels,
        } = current;
        if (!loanScenarioId) {
          return accum;
        }
        return accum.concat({
          contactNames,
          title,
          proposedLoanAmount: this.$filter('currency')(
            proposedLoanAmount,
            '$',
            2,
          ),
          pipelineStatus,
          enquiry,
          adviserFullName: this.$sce.trustAsHtml(`
                <span class="adviser">
                  <h4>${adviserFullName.charAt(0)}</h4>
                  <p>${adviserFullName.split(' ')[0]}</p>
                </span>
              `),
          referrerName,
          ...leadColumns,
          statusUpdated: statusUpdated
            ? formatDate('YYYY MM DD hh:mm:ss')(new Date(statusUpdated))
            : '',
          labelsList: pipelineLabelBuilder(labels),
          id: loanScenarioId,
          manageLabelOpen: false,
          loanId,
          loanScenarioId,
          pipelineStatusId,
        });
      }, []);
      return { totalRecords, tableData };
    });
  }
}
