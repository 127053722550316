export const TABLE_SIZE = [
  {
    value: 10,
    name: '10',
  },
  {
    value: 25,
    name: '25',
  },
  {
    value: 50,
    name: '50',
  },
];

export const NOTES_TABLE_SIZE = [
  {
    value: 25,
    name: '25',
  },
  {
    value: 50,
    name: '50',
  },
  {
    value: 100,
    name: '100',
  },
  {
    value: 200,
    name: '200',
  },
];

export const DEFAULT_TABLE_SIZE = 10;
export const APPLICATION_DEFAULT_SIZE = 14;
export const DEFAULT_NOTES_SIZE = NOTES_TABLE_SIZE[2].value;
