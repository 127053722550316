import { ADDRESS_TYPES } from 'Common/constants/addressField';

export function operatingCountriesBuilderForUI(operatingCountry) {
  return {
    countryId: operatingCountry.CountryId,
    countryCode: operatingCountry.CountryCode,
    countryName: operatingCountry.CountryName,
    callingCode: operatingCountry.CallingCode,
  };
}

export function reportingRegionsBuilderForUI(reportingRegion) {
  return {
    reportingRegionId: reportingRegion.ReportingRegionId,
    reportingRegionName: reportingRegion.ReportingRegionName,
  };
}

export function reportingStatesBuilderForUI(reportingState) {
  return {
    reportingStateId: reportingState.ReportingStateId,
    reportingStateName: reportingState.ReportingStateName,
  };
}

export function regionalizationBuilderForUI(region) {
  return {
    countryId: region.CountryID,
    name: region.Name,
    code: region.Code,
    currencySign: region.CurrencySign,
  };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function addressLookup(address) {
  return (
    address && {
      searchString: address.formatted_address || '',
      formatted_address: address.formatted_address || '',
      geoCoded: address.geoCoded || '',
      latitude: address.latitude || '',
      longitude: address.longitude || '',
      isCustomAddress: address.isCustomAddress || '',
      street_address: address.street_address || '',
      locality: address.locality || '',
      postal_code: address.postal_code || '',
      stateShort_Name: address.stateShort_Name || '',
      route: address.route || '',
      subpremise: address.subpremise || '',
      suburb: address.suburb || '',
      neighborhood: address.neighborhood || '',
      countryCodeInTwoLetter:
        address.countryCodeInTwoLetter || address.CountryCode || '',
      streetType: address.StreetType || '',
      streetName: address.StreetName || '',
      level: address.Level || '',
      buildingName: address.BuildingName || '',
      unitNo: address.unitNo || '',
      street_number: address.street_number || '',
      administrative_area_level_1: address.administrative_area_level_1 || '',
      country: address.country,
      addressType: address.AddressType || ADDRESS_TYPES.STANDARD,
      addressLine1: address.AddressLine1 || '',
      addressLine2: address.AddressLine2 || '',
      postOfficeType: address.PostOfficeType || '',
      number: address.Number || '',
      exchange: address.Exchange || '',
    }
  );
}

export function addressFieldFormatMapper(address) {
  return (
    address && {
      Level: address.level,
      BuildingName: address.buildingName,
      Unit: address.subpremise,
      StreetNumber: address.street_number,
      StreetName: address.streetName,
      StreetType: address.streetType,
      Suburb: address.locality,
      State: address.administrative_area_level_1,
      PostCode: address.postal_code,
      Country: address.country,
      AddressLine1: address.addressLine1,
      AddressLine2: address.addressLine2,
      PostOfficeType: address.postOfficeType,
      Number: address.number,
      Exchange: address.exchange,
    }
  );
}
