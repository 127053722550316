import { srcFormatter } from './srcFormatter';
/*
This directive decorator makes sure image sources
pointing to an asset inside the `/assets` directory
are being referenced using their full absolute path
when the app is being run under a micro-frontend environment
 */
const decorator = ($delegate, legacyAngularHost, technology) => {
  const directive = $delegate[0];
  const originalCompile = directive.compile;
  /*
  Decorate the `ngSrcDirective.compile` function
  to inject an additional observer which will do the formatting of
  relative urls into absolute ones.
  https://github.com/angular/angular.js/blob/master/src/ng/directive/attrs.js#L408
   */
  directive.compile = function compile() {
    const originalLink = Reflect.apply(originalCompile, this, arguments);
    return function link(scope, element, attr) {
      Reflect.apply(originalLink, this, [scope, element, attr]);
      attr.$observe('src', srcFormatter(legacyAngularHost, technology, attr));
    };
  };

  return $delegate;
};

export default decorator;
