const ONLINE_FACT_FIND_TYPE = {
  REQUEST: 'request',
  REVIEW: 'review',
};

export default class OnlineFactFindModalCtrl {
  constructor($uibModal, configService) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.configService = configService;
  }

  $onChanges(changes) {
    const { isReviewShow } = changes;
    this.isReviewShow = isReviewShow && isReviewShow.currentValue;
  }

  invite() {
    this.modalInstance.close({
      factFindSend: false,
      factFindType: 'Invite',
    });
  }

  showOnlineFactFind(type) {
    const props = {
      loanId: this.loanId,
    };
    const template =
      type === ONLINE_FACT_FIND_TYPE.REQUEST
        ? `<request-fact-find
          modal-instance='vm.modalInstance'
          loan-id='vm.props.loanId'>
      </request-fact-find>`
        : `<review-fact-find
          modal-instance='vm.modalInstance'
          loan-id='vm.props.loanId'>
      </review-fact-find>`;

    this.$uibModal.open({
      template,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      windowClass: 'online-fact-find-form',
      size: 'lg',
      resolve: {
        props: () => props,
      },
    });
  }

  request() {
    this.modalInstance.close({
      factFindSend: false,
      factFindType: 'Request',
    });
  }

  review() {
    this.showOnlineFactFind(ONLINE_FACT_FIND_TYPE.REVIEW);
    this.cancel();
  }

  cancel() {
    this.modalInstance.dismiss();
  }
}
