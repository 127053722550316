export const REPAYMENT_FREQUENCY = {
  MONTHLY: 'Monthly',
  WEEKLY: 'Weekly',
  FORTNIGHTLY: 'Fortnightly',
  ANNUALLY: 'Annually',
  HALF_YEARLY: 'Half-yearly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
  HALF_MONTHLY: 'Half-monthly',
};

export const REPAYMENT_PERIOD = {
  [REPAYMENT_FREQUENCY.MONTHLY]: 'Month',
  [REPAYMENT_FREQUENCY.WEEKLY]: 'Week',
  [REPAYMENT_FREQUENCY.FORTNIGHTLY]: 'Fortnight',
};
