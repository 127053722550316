import moment from 'moment';
import { listDataFromObject } from 'Common/utilities/commission';

export const getExpectedDate = (obj) => {
  return moment(obj.DateExpected).toDate();
};

export const formatNotRecievedItem = (obj) => {
  const expectedDate = getExpectedDate(obj);
  const isConfirm = Object.prototype.hasOwnProperty.call(obj, 'IsPaid')
    ? obj.IsPaid
    : false;
  const confirmDate = moment(obj.DatePaid).toDate();

  return {
    ...listDataFromObject(obj),
    expectedDate,
    isConfirm,
    confirmDate,
  };
};
