import angular from 'angular';
import gmailModalDescription from './gmailModalDescription';
import linkGmailSyncModal from './linkGmailSyncModal';
import confirmUnlinkGmailSync from './confirmUnlinkGmailSync';

export default angular.module('linkGmailSync.scenes', [
  gmailModalDescription,
  linkGmailSyncModal,
  confirmUnlinkGmailSync,
]).name;
