import { MOBILE, PHONE } from 'Common/constants/phoneMobileFormat';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { haversineInKM } from 'Common/utilities/math';
import { REPAYMENT_FREQUENCY } from 'Common/constants/repayment';
import _ from 'lodash';

export const loadModule = () =>
  app.factory('broadcastDataService', [
    'loanScenarioService',
    '$timeout',
    'financialCalculatorService',
    '$q',
    'dashboardService',
    'corporateService',
    'generalService',
    'mapService',
    'toaster',
    function (
      loanScenarioService,
      $timeout,
      financialCalculatorService,
      $q,
      dashboardService,
      corporateService,
      generalService,
      mapService,
      toaster,
    ) {
      var service = this;
      /* Email */
      service.emailTemplateView = {
        isTriggered: false,
      };

      service.emailSendingPage = {
        isTriggered: false,
      };
      service.userSignature = '';
      /* End of Email */
      service.map = {
        isLoaded: false,
        isCancelledTriggered: false,
      };

      service.recentView = {
        get() {
          var defer = $q.defer();
          dashboardService.getRecentlyViewed().then((response) => {
            defer.resolve(
              _.filter(response.data, (data) => {
                return data.DisplayName.trim() != '';
              }),
            );
          });
          return defer.promise;
        },
        list: [],
      };

      service.addressWithRentalIds = ['1', '5']; // boarding and rental

      service.addLoanstructureShared = function (
        loanScenarioId,
        familyId,
        loanAmountForLoanFacility,
      ) {
        /* basin string ang loanScenarioId, check later if mag error */
        const defer = $q.defer();
        const DefaultLoanFacilityToSet = {
          BaseLoanAmount: loanAmountForLoanFacility,
          LoanStructureId: 0,
          LoanTerm: '30',
          Owner: [],
          ProductId: 0,
          StatementCycle: 'Monthly',
          StatementCycleId: '4',
          loanScenarioId,
          InterestOnlyTerm: 0,
        };
        /* get ownerList */
        loanScenarioService.BorrowersDetailsGet(familyId).then((response) => {
          DefaultLoanFacilityToSet.Owner = response.data;
          /* setting the defaultLoanFacility */
          loanScenarioService
            .loanFacilitySet(DefaultLoanFacilityToSet)
            .then((response) => {
              toaster.pop('info', 'Auto generated a loan facility');
              return defer.resolve('success');
            });
        });

        return defer.promise;
      };

      // CALCULATING REPAYMENT
      service.calRep = {};
      // model
      angular.extend(service.calRep, {
        dontCalculate: false,
      });
      // service
      angular.extend(service.calRep, {
        translateRepFreq(value) {
          if (value) {
            switch (value) {
              case REPAYMENT_FREQUENCY.YEARLY:
                return 1;
              case REPAYMENT_FREQUENCY.MONTHLY:
                return 12;
              case REPAYMENT_FREQUENCY.HALF_MONTHLY:
                return 24;
              case REPAYMENT_FREQUENCY.FORTNIGHTLY:
                return 26;
              case REPAYMENT_FREQUENCY.WEEKLY:
                return 52;
              case REPAYMENT_FREQUENCY.HALF_YEARLY:
                return 2;
              case REPAYMENT_FREQUENCY.QUARTERLY:
                return 4;
            }
          } else return false;
        },

        translateIsInterest(value) {
          if (value) {
            switch (value) {
              case 'Principal and Interest':
                service.calRep.dontCalculate = false;
                return false;
                break;
              case 'Interest Only':
                service.calRep.dontCalculate = false;
                return true;
                break;
              case 'Revolving Credit':
                service.calRep.dontCalculate = true;
                return null;
                break;
            }
          } else return null;
        },

        calculateRepayment(
          loanAmount,
          interestRate,
          loanTerms,
          optRepaymentFrequency,
          optIsInterestOnly,
        ) {
          var calRep = service.calRep;
          var prom = $q.defer();
          if (
            !loanAmount ||
            !interestRate ||
            !loanTerms ||
            loanAmount === 0 ||
            interestRate === 0
          )
            prom.reject(null);

          if (!optRepaymentFrequency) optRepaymentFrequency = 12;
          if (!optIsInterestOnly) optIsInterestOnly = false;

          if (service.calRep.dontCalculate === false) {
            $timeout.cancel(this.calculateVar);
            this.calculateVar = $timeout(() => {
              financialCalculatorService
                .getRepaymentAmountFloatingType(
                  loanAmount,
                  interestRate,
                  loanTerms,
                  calRep.translateRepFreq(optRepaymentFrequency),
                  calRep.translateIsInterest(optIsInterestOnly),
                )
                .then((response) => {
                  const { data } = response;

                  const value = parseFloat(data) || 0;
                  const repaymentValue = (value && value.toFixed(2)) || 0;

                  prom.resolve(repaymentValue);
                });
            }, 1000);
          } else {
            prom.reject(null);
          }

          return prom.promise;
        },
      });
      // END OF CALCULATING REPAYMENT

      service.haversineInKM = haversineInKM;

      service.corporateLoadAddress = function (
        id,
        isOrg,
        mapIdBusiness,
        mapIdHome,
        mapIdMailing,
      ) {
        var defer = $q.defer();
        if (isOrg) {
          initOrganisationAddress(id, mapIdHome, mapIdMailing).then(
            (promise) => {
              defer.resolve(promise);
            },
          );
        } else {
          initIndividualAddress(
            id,
            mapIdBusiness,
            mapIdHome,
            mapIdMailing,
          ).then((promise) => {
            defer.resolve(promise);
          });
        }

        return defer.promise;
      };

      function initIndividualAddress(
        id,
        mapIdBusiness,
        mapIdHome,
        mapIdMailing,
      ) {
        var defer = $q.defer();
        corporateService.familyAddressGet(id).then((response) => {
          var addressData = response.data;

          var businessAddress = [];
          var mailingAddress = [];
          var homeAddress = [];
          var isSameinBussinessAddress = false;

          angular.forEach(addressData, (value, key) => {
            if (value.isBusiness) {
              businessAddress.push(value);
            } else if (value.isMailing) {
              mailingAddress.push(value);
            } else if (!value.isBusiness && !value.isMailing) {
              homeAddress.push(value);
            }
          });

          if (_.size(businessAddress) > 0 && _.size(mailingAddress) > 0) {
            if (
              businessAddress[0].formatted_address ===
              mailingAddress[0].formatted_address
            ) {
              isSameinBussinessAddress = true;
            }
          }

          if (_.size(businessAddress) > 0) {
            setMapInit(businessAddress, mapIdBusiness);
          }

          if (_.size(homeAddress) > 0) {
            setMapInit(homeAddress, mapIdHome);
          }

          if (_.size(mailingAddress) > 0) {
            setMapInit(mailingAddress, mapIdMailing);
          }
          var addressReturn = {
            businessAddress,
            mailingAddress,
            homeAddress,
            isSameinBussinessAddress,
          };

          defer.resolve(addressReturn);
        });
        return defer.promise;
      }

      function initOrganisationAddress(id, mapIdHome, mapIdMailing) {
        var defer = $q.defer();
        corporateService.organizationAddressGet(id).then((response) => {
          var orgAddressData = response.data;

          var mailingAddress = [];
          var homeAddress = [];

          angular.forEach(orgAddressData, (value, key) => {
            if (value.isMailing) {
              mailingAddress.push(value);
            } else if (!value.isMailing) {
              homeAddress.push(value);
            }
          });

          if (_.size(homeAddress) > 0) {
            setMapInit(homeAddress, mapIdHome);
          }

          if (_.size(mailingAddress) > 0) {
            setMapInit(mailingAddress, mapIdMailing);
          }

          var orgAddressReturn = {
            businessAddress: [],
            mailingAddress,
            homeAddress,
            isSameinBussinessAddress: false,
          };

          defer.resolve(orgAddressReturn);
        });
        return defer.promise;
      }

      function setMapInit(addressObj, mapId) {
        var mapDetails = '';
        if (_.size(addressObj) > 0) {
          var address = addressObj[0].formatted_address || '';
          var latitude = addressObj[0].latitude || 0;
          var longitude = addressObj[0].longitude || 0;

          mapService.mapCorporate(latitude, longitude, address, mapId, address);
        }
      }

      service.getMobileFormat = (countryId) => {
        const id = Number(countryId);
        let mobileFormat = '';
        switch (id) {
          case COUNTRY_TYPE.NEW_ZEALAND:
            mobileFormat = MOBILE.NZ;
            break;
          case COUNTRY_TYPE.AUSTRALIA:
            mobileFormat = MOBILE.AU;
            break;
          default:
            mobileFormat = '';
            break;
        }
        return mobileFormat;
      };

      service.getPhoneFormat = (countryId) => {
        const id = Number(countryId);
        let phoneFormat = '';
        switch (id) {
          case COUNTRY_TYPE.NEW_ZEALAND:
            phoneFormat = PHONE.NZ;
            break;
          case COUNTRY_TYPE.AUSTRALIA:
            phoneFormat = PHONE.AU;
            break;
          default:
            phoneFormat = '';
            break;
        }
        return phoneFormat;
      };

      service.adviserStatus = {
        isActive: false,
      };

      return service;
    },
  ]);
