import angular from 'angular';
import { quoteDataBuilderForUI } from 'Common/mappers/insurance';

class InsurerComparisonCtrl {
  constructor($uibModalInstance, quoteData, insurers) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.quoteData = quoteDataBuilderForUI(quoteData);
    this.insurers = insurers;
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .controller('InsurerComparisonCtrl', InsurerComparisonCtrl);
