import { checkHideDeleteTransaction } from 'Assets/js/controllers/contacts/client/util/viewEmailCtrl.js';

export default class CommunicationBoxCtrl {
  constructor(configService) {
    'ngInject';

    this.configService = configService;
    if (this.communication) {
      this.showDeleteAction();
    }
  }

  deleteCommunication($event, communication) {
    $event && $event.stopPropagation();
    this.onDeleteCommunication({ communication });
  }

  showDeleteAction() {
    const { hideDeleteTransaction } = this.configService.feature;

    const isHideDeleteTransaction = hideDeleteTransaction
      ? checkHideDeleteTransaction({
          emailSubject: this.communication.emailSubject,
          isHideDeleteFlagEnabled: hideDeleteTransaction,
        })
      : hideDeleteTransaction;

    this.communication = {
      ...this.communication,
      isHideDeleteTransaction,
    };
  }
}
