import template from './liabilityDates.html';
import controller from './liabilityDatesCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    years: '<',
    months: '<',
    loanStartDate: '=',
    loanTermYear: '=',
    loanTermMonth: '=',
    interestOnlyEndDate: '=',
    interestOnlyStartDate: '=',
    interestOnlyTermYear: '=',
    interestOnlyTermMonth: '=',
    isRevolvingCredit: '=',
    isAssetLiability: '<',
  },
};
