class InsuranceAppService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'InsuranceQuote';
  }

  insuranceAppGet(applicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ApplicationInsuranceGet`, {
      applicationId,
    });
  }

  insuranceAdviserListGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/ApplicationAdviserListGet`);
  }

  applicationInsuranceSet(adviserId, showAllNotes, applicationId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ApplicationInsuranceSet`,
      {},
      { adviserId, showAllNotes, applicationId },
    );
  }

  applicationClientGet(applicationId, clientType) {
    return this.httpClient.get(`${this.apiBaseUrl}/ApplicationClientGet`, {
      applicationId,
      clientType,
    });
  }

  applicationClientSet(applicationId, clientId, clientTypeId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ApplicationClientSet`,
      {},
      { applicationId, clientId, clientTypeId },
    );
  }

  applicationSectionNotesGet(applicationId, sectionId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ApplicationSectionNotesGet`,
      { applicationId, sectionId },
    );
  }

  applicationQuestionsGet(applicationId, sectionId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ApplicationQuestionGet`, {
      applicationId,
      sectionId,
    });
  }

  applicationSectionNotesSet(applicationId, sectionId, sectionNotes) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ApplicationSectionNotesSet`,
      {},
      {
        applicationId,
        sectionId,
        sectionNotes,
      },
    );
  }

  applicationAnswerSet(
    applicationId,
    questionId,
    answerString,
    answerBit,
    answerInt,
  ) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ApplicationAnswerSet`,
      {},
      {
        applicationId,
        questionId,
        answerString,
        answerBit,
        answerInt,
      },
    );
  }

  applicationClientDelete(applicationId, clientId, clientTypeId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/ApplicationClientDelete`,
      {
        applicationId,
        clientId,
        clientTypeId,
      },
    );
  }
}

export default InsuranceAppService;
