import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive(
      'newlyClonedLoanAppIndicator',
      function newlyClonedLoanAppIndicator() {
        return {
          restrict: 'A',
          scope: {
            newlyClonedLoanApp: '=newlyClonedLoanAppIndicator',
          },
          link: ($scope, $elem, $attr) => {
            if (
              $scope.newlyClonedLoanApp &&
              $scope.newlyClonedLoanApp.loanAppId &&
              Number($attr.loanAppId) === $scope.newlyClonedLoanApp.loanAppId
            ) {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              if (!$elem.hasClass('animate-display')) {
                $elem.addClass('animate-display');
              } else {
                $elem.removeClass('animate-display');
              }

              $scope.newlyClonedLoanApp.loanAppId = null;
            }
          },
        };
      },
    );
