export const OWNERSHIP_TYPE = {
  BOARDING: 1,
  CARAVAN: 2,
  OWN_HOME: 3,
  HOME_MORTGAGE: 4,
  RENT: 5,
  PARENTS: 6,
  TO_BE_PURCHASED: 7,
  OTHER: 8,
};
