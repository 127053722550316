import angular from 'angular';
import { adjustHeight as utilAdjustHeight } from './util/equalizeHeight';

export const loadModule = () =>
  angular
    .module('app')
    .directive('adjustHeightMaxSibling', function adjustHeightMaxSibling(
      $interval,
      $window,
    ) {
      return {
        scope: {
          identifier: '@adjustHeightMaxSibling',
        },
        restriction: 'A',
        link: (scope, baseElement, attr) => {
          const iteration = parseInt(attr.iteration, 10);

          const element =
            attr.adjustIsSetToChild && attr.adjustIsSetToChild === 'true'
              ? baseElement.parent()
              : baseElement;

          const adjustHeight = utilAdjustHeight(scope, element);

          const adjustHeightInterval = $interval(adjustHeight, 1000, iteration);
          element.on('$destroy', () => {
            $interval.cancel(adjustHeightInterval);
          });

          angular.element($window).on('resize', adjustHeight());
          adjustHeight();
        },
      };
    });
