/**
 * It's like click, but when you don't click on your element.
 */
import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('offClick', function offClick($rootScope, $parse, $window) {
      let id = 0;
      const listeners = {};
      // add variable to detect touch users moving..
      let touchMove = false;

      // eslint-disable-next-line unicorn/consistent-function-scoping
      function targetInFilter(target, elms) {
        if (!target || !elms) {
          return false;
        }
        const elmsLen = elms.length;
        for (let i = 0; i < elmsLen; ++i) {
          const currentElem = elms[i];
          let containsTarget = false;
          try {
            containsTarget = currentElem.contains(target);
          } catch (error) {
            // If the node is not an Element (e.g., an SVGElement) node.contains() throws Exception in IE,
            // see https://connect.microsoft.com/IE/feedback/details/780874/node-contains-is-incorrect
            // In this case we use compareDocumentPosition() instead.
            if (typeof currentElem.compareDocumentPosition !== 'undefined') {
              /* eslint-disable no-bitwise, no-undef */
              containsTarget =
                currentElem === target ||
                Boolean(
                  currentElem.compareDocumentPosition(target) &
                    Node.DOCUMENT_POSITION_CONTAINED_BY,
                );
            }
          }

          if (containsTarget) {
            return true;
          }
        }
        return false;
      }

      function offClickEventHandler(event) {
        // If event is a touchmove adjust touchMove state
        if (event.type === 'touchmove') {
          touchMove = true;
          // And end function
          return false;
        }
        // This will always fire on the touchend after the touchmove runs...
        if (touchMove) {
          // Reset touchmove to false
          touchMove = false;
          // And end function
          return false;
        }
        const target = event.target || event.srcElement;
        angular.forEach(listeners, (listener) => {
          if (
            !(
              listener.elm.contains(target) ||
              targetInFilter(target, listener.offClickFilter)
            )
          ) {
            $rootScope.$evalAsync(() => {
              listener.cb(listener.scope, {
                $event: event,
              });
            });
          }
        });
      }

      // Add event listeners to handle various events. Destop will ignore touch events
      $window.document.addEventListener(
        'touchmove',
        offClickEventHandler,
        true,
      );
      $window.document.addEventListener('touchend', offClickEventHandler, true);
      $window.document.addEventListener('click', offClickEventHandler, true);

      return {
        restrict: 'A',
        compile($element, attr) {
          const fn = $parse(attr.offClick);
          return function (scope, element) {
            const elmId = id++;
            let offClickFilter;
            let removeWatcher;

            function on() {
              listeners[elmId] = {
                elm: element[0],
                cb: fn,
                scope,
                offClickFilter,
              };
            }

            function off() {
              listeners[elmId] = null;
              delete listeners[elmId];
            }

            offClickFilter = $window.document.querySelectorAll(
              scope.$eval(attr.offClickFilter),
            );

            if (attr.offClickIf) {
              removeWatcher = $rootScope.$watch(
                () => {
                  return $parse(attr.offClickIf)(scope);
                },
                (newVal) => {
                  if (newVal) {
                    on();
                  } else if (!newVal) {
                    off();
                  }
                },
              );
            } else {
              on();
            }

            attr.$observe('offClickFilter', (value) => {
              offClickFilter = $window.document.querySelectorAll(
                scope.$eval(value),
              );
            });

            scope.$on('$destroy', () => {
              off();
              if (removeWatcher) {
                removeWatcher();
              }
            });
          };
        },
      };
    });
