import template from './emailTemplates.html';
import EmailTemplatesCtrl from './emailTemplatesCtrl';

export default {
  template,
  controller: EmailTemplatesCtrl,
  bindings: {
    onTemplatesUpdated: '&',
    registerParentEvent: '&',
  },
};
