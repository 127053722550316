export const CONTACT_DETAILS_TAB_TEMPLATE = {
  summary: 'assets/views/contacts/client/summary/summary_single.html',
  clients: 'assets/views/contacts/client/clients.html',
  addresses: '',
  employment: 'assets/views/contacts/client/employment.html',
  financials: 'assets/views/contacts/client/financials.html',
  lending: 'assets/views/contacts/client/lending.html',
  insurance: 'assets/views/contacts/client/insurance.html',
  notes: 'assets/views/contacts/client/notes.html',
  communications: 'assets/views/contacts/client/emails.html',
  documents: 'assets/views/contacts/client/documents.html',
};
