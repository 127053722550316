import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('GroupInfoCtrl', function GroupInfoCtrl(
      $scope,
      $http,
      SweetAlert,
      configService,
      $filter,
      referralsService,
      $timeout,
      $uibModal,
      $stateParams,
    ) {
      $scope.agreementId = $stateParams.agreementId;

      // init Function
      $scope.init = function () {
        // Get Referral Agreement's Referrer Info
        $scope.getReferrerOrgAgreementsInfo = function () {
          $scope.referrerOrgReferrerAgreement = [];
          referralsService
            .getReferrerOrgReferrerAgreement($scope.agreementId)
            .then((response) => {
              $scope.referrerOrgReferrerAgreement = response.data;
            });
        };

        $scope.getReferrerOrgAgreementsInfo();
      };

      $scope.init();
    });
