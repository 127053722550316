import angular from 'angular';

class questionRadioButtonCtrl {
  $onInit() {
    // this to convert from string  parameter to a JSON object
    this.options =
      typeof this.options === 'string'
        ? angular.fromJson(this.options.replace(/\\/g, ''))
        : this.options;
  }

  onClick(value) {
    this.onChangeFn()(value);
  }
}

export default questionRadioButtonCtrl;
