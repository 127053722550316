import template from './insuranceOpportunities.html';
import controller from './insuranceOpportunitiesCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    tab: '<',
    filterData: '<',
    filterUpdateCount: '<',
    onTableDataLoaded: '&',
    onSendModal: '&',
  },
};
