export default class PipelineLabelSelectorCtrl {
  $onChanges() {
    this.labels = this.labels.map((label) => {
      const isSelected =
        this.selectedLabelIds && this.selectedLabelIds.includes(label.id);
      return { ...label, isSelected };
    });
  }

  onStatusChange(label, isSelected, event) {
    label.isSelected = isSelected;
    if (isSelected) {
      this.onAddLabelToCard && this.onAddLabelToCard({ label });
    } else {
      this.onDeleteLabelFromCard && this.onDeleteLabelFromCard({ label });
    }
    event.stopPropagation();
  }
}
