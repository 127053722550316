import { TARGET_BASED_TILE } from 'Common/constants/dashboardWidget';
import { toastSuccess } from 'Common/utilities/alert';
import { isBoolean } from 'Common/utilities/objectValidation';

export default class TargetSettingsTileCtrl {
  constructor(dashboardService) {
    'ngInject';

    this.dashboardService = dashboardService;
  }

  $onInit() {
    this.getWidgetFilters();
  }

  $onChanges(changes) {
    const { isFlipped } = changes;
    const isChangeFlipDisplay =
      isBoolean(isFlipped.currentValue) && !isFlipped.currentValue;
    isChangeFlipDisplay && this.getWidgetFilters();
  }

  getWidgetFilters() {
    this.dashboardService.getRecommendedWidgetsOptions().then((data) => {
      this.timePeriodFilter = data.find(
        (item) =>
          item.widgetOptionId === TARGET_BASED_TILE.TIME_PERIOD &&
          item.recommendedWidgetId === this.widgetInfo.recommendedWidgetId,
      );
      this.rawTargetValue =
        (this.timePeriodFilter && this.timePeriodFilter.targetValue) || 0;
    });
  }

  setWidgetOptions(params) {
    this.isSavingTarget = true;
    const endpointToUse = params.recommendedWidgetOptionsId
      ? 'putRecommendedWidgetsOptions'
      : 'setRecommendedWidgetsOptions';

    this.dashboardService[endpointToUse](params).then(({ data }) => {
      this.isSavingTarget = false;
      data && toastSuccess('Target successfully updated');
      data && this.onClose();
    });
  }

  updateTarget() {
    const params = {
      ...this.timePeriodFilter,
      recommendedWidgetId: this.widgetInfo.recommendedWidgetId,
      widgetOptionId: TARGET_BASED_TILE.TIME_PERIOD,
      targetValue: this.rawTargetValue,
    };
    this.setWidgetOptions(params);
  }
}
