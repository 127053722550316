import angular from 'angular';

class ComplianceReviewRatingModalCtrl {
  constructor($uibModalInstance, comment) {
    this.$uibModalInstance = $uibModalInstance;
    this.comment = comment;
  }
}
export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'ComplianceReviewRatingModalCtrl',
      ComplianceReviewRatingModalCtrl,
    );
