import template from './multipleErrorBanner.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    errors: '<',
    visibleArrow: '<',
    isFromModal: '<',
    reloading: '<',
  },
};
