class PortalComponentsService {
  constructor(setPortalComponents, TECHNOLOGY) {
    'ngInject';
    this.setPortalComponents = setPortalComponents;
    this.technology = TECHNOLOGY;

    // Dynamically import the components so that Angular can run even when deployed without Portal
    // Make sure we only do this when running inside Portal
    if (this.technology?.isReact) {
      import('portal/Exposed')
        .then((exposedComponents) => {
          this.importedComponents = exposedComponents;
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error(
            'Failed to load exposed components from Portal. Check that REACT_APP_HOST is set correctly',
          );
        });
    }
  }

  renderComponent(id, componentName, props) {
    if (!this.importedComponents) {
      return;
    }

    const component = this.importedComponents[componentName];
    if (component) {
      this.setPortalComponents((prevComponents) => {
        const newComponents = { ...prevComponents };
        newComponents[id] = { component: component, props: props };
        return newComponents;
      });
    }
  }

  removeComponent(id) {
    if (!this.importedComponents) {
      return;
    }

    this.setPortalComponents((prevComponents) => {
      const newComponents = { ...prevComponents };
      delete newComponents[id];
      return newComponents;
    });
  }
}

export default PortalComponentsService;
