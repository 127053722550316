class FormattingService {
  constructor(toaster) {
    'ngInject';

    this.toaster = toaster;
  }

  calculatorFormatCurrency(amount) {
    this.amountFormatted = '';
    if (amount && amount !== '-' && !amount.match(/[a-z]/i)) {
      const parsedAmount = parseFloat(amount.replace(/,/g, ''));
      this.amountFormatted = parsedAmount
        .toFixed(0)
        .replace(/./g, (c, i, a) => {
          return i && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c;
        });
    }
    return this.amountFormatted;
  }

  calculatorFormatInterestRate(amount) {
    // if (amount === '0' || amount === '0.') return amount;
    this.validatedAmount = '';
    if (amount && amount !== '-' && !amount.match(/[a-z]/i)) {
      const parsedAmount = parseFloat(amount);
      if (parsedAmount < 0 || parsedAmount > 25) {
        this.toaster.pop('warning', 'Interest Rate Valid Range: 0% to 25%');
      } else {
        this.validatedAmount = amount;
      }
    }
    return this.validatedAmount;
  }
}

export default FormattingService;
