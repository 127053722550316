class OpportunitySummaryCtrl {
  constructor(leadOpportunitiesService) {
    'ngInject';

    this.leadOpportunitiesService = leadOpportunitiesService;
  }

  $onInit() {
    this.opportunitySummaryTitle = `${this.leadOpportunitiesService
      .getLabel(true)
      .toUpperCase()} SUMMARY`;
  }
}

export default OpportunitySummaryCtrl;
