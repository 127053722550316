import angular from 'angular';

export const loadModule = () => {
  angular.module('app').directive('ngSrcOnError', function questionField() {
    return {
      restrict: 'A',
      link: (scope, element, attr) => {
        const onError = () => {
          if (attr.src !== attr.ngSrcOnError) {
            attr.$set('src', attr.ngSrcOnError);
          }
        };
        element.bind('error', onError);
        scope.$on('$destroy', () => {
          element.unbind('error', onError);
        });
      },
    };
  });
};
