import template from './openServiceOptionModal.html';
import openServiceOptionModalCtrl from './openServiceOptionModalCtrl';

export default {
  template,
  controller: openServiceOptionModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    showClose: '<',
    action: '<',
  },
};
