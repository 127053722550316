import { toastError, toastSuccess } from 'Common/utilities/alert';
import { INSURANCE_PIPELINE_STATUS } from 'Common/constants/insuranceOptions';
import {
  D_MMM_YYYY_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';
import {
  WORKBENCH_DATES,
  DEFERRED_STATUS_ID,
} from 'Common/constants/insuranceApplicationPipeline';
import {
  INPUT_DATE_FORMAT,
  DATE_OPTIONS,
  ALT_INPUT_FORMATS,
} from 'Common/constants/workbench';
import moment from 'moment';

export default class workBenchDetailsCtrl {
  constructor(insurancePipelineService, modalRenderService) {
    'ngInject';

    this.modalRenderService = modalRenderService;
    this.insurancePipelineService = insurancePipelineService;
  }

  $onInit() {
    this.populateWorkBenchData();
    this.pipelineStatus = [];
    this.workBenchDetails = {};
    this.clientDetails = [];
    this.nextWorkDate = {
      Date: null,
    };
    this.selectedType = 0;
    this.dateFormat = WORKBENCH_DATES.FORMAT;
    this.allInputFormats = WORKBENCH_DATES.ALL_FORMAT;
    this.dateOptions = WORKBENCH_DATES.DATE_OPTIONS;
    this.nextWorkDate.isOpen = false;
    this.nextWorkDate.openDate = () => {
      this.nextWorkDate.isOpen = !this.nextWorkDate.isOpen;
    };
    this.commencementDate = null;
    this.INPUT_DATE_FORMAT = INPUT_DATE_FORMAT;
    this.DATE_OPTIONS = DATE_OPTIONS;
    this.ALT_INPUT_FORMATS = ALT_INPUT_FORMATS;
    this.isInForce = false;
    this.isRequired = false;
  }

  $onChanges(changes) {
    if (changes && changes.workBenchDetails) {
      this.workBenchDetails = changes.workBenchDetails.currentValue;
      this.clientDetails = this.workBenchDetails.clients;
      this.insuranceStatusId = this.workBenchDetails.pipelineStatusId;
      this.taskDueDate =
        this.workBenchDetails.nextWorkDate &&
        moment(this.workBenchDetails.nextWorkDate).format(D_MMM_YYYY_FORMAT);
    }
  }

  openDate() {
    this.dateStartedIsOpen = !this.dateStartedIsOpen;
  }

  getWorkBenchDetails() {
    this.insurancePipelineService
      .getClientWorkBenchDetails(this.quoteId)
      .then((res) => {
        this.workBenchDetails = res;
        this.commencementDate = res.commencementDate;
        this.clientDetails = this.workBenchDetails.clients;
        this.insuranceStatusId = this.workBenchDetails.pipelineStatusId;
        this.nextWorkDate.Date =
          this.workBenchDetails.nextWorkDate &&
          moment(this.workBenchDetails.nextWorkDate).toDate();
        this.isInForce =
          this.insuranceStatusId === INSURANCE_PIPELINE_STATUS.IN_FORCE;
      });
  }

  getInsurancePipelineStatus() {
    this.insurancePipelineService
      .getInsurancePipelineStatus(null, false)
      .then((res) => {
        this.pipelineStatus = res.filter(
          (item) => item.id !== DEFERRED_STATUS_ID.V3,
        );
      });
  }

  loadNotProceededStatuses() {
    this.insurancePipelineService.getNotProceededReasons().then((response) => {
      this.notProceedingReasons = response.map((reason) => {
        return {
          PipelineSubStatusID: reason.id,
          SubStatusName: reason.description,
        };
      });
    });
  }

  setWorkBenchDetails() {
    const data = {
      quoteId: this.quoteId,
      nextWorkDate: moment(this.nextWorkDate.Date).format('YYYY-MM-DD'),
      description: 'Updated Next Work Date',
    };
    this.insurancePipelineService.workbenchNextWorkDate(data).then(() => {
      this.onNewWorkDateChange && this.onNewWorkDateChange();
    });
  }

  convertApplication(statusId) {
    const inForce = INSURANCE_PIPELINE_STATUS.IN_FORCE === statusId;
    const isSameDate =
      this.commencementDate === this.workBenchDetails.commencementDate;
    if (this.workBenchDetails.pipelineStatusId === statusId && isSameDate) {
      return;
    }
    if (inForce && !this.isRequired) {
      this.changeWorkBenchPipelineStatus(statusId, null);
      return;
    }
    this.promptToContinueConversion(inForce).result.then((response) => {
      if (!response.isOk) {
        this.insuranceStatusId = this.workBenchDetails.pipelineStatusId;
        return;
      }
      this.changeWorkBenchPipelineStatus(statusId, response.selectedReasonId);
    });
  }

  promptToContinueConversion(isProceeding) {
    return this.modalRenderService.renderPipelineStatusMoveConfirmationModal({
      content: `This application will now be moved to ${
        isProceeding ? 'In Force' : 'Not Proceeding'
      }`,
      reasonList: isProceeding ? null : this.notProceedingReasons,
      showNurtureCampaignsQuestion: false,
    });
  }

  saveInsuranceStatus(statusId) {
    const isSameDate =
      this.commencementDate === this.workBenchDetails.commencementDate;
    if (this.workBenchDetails.pipelineStatusId === statusId && isSameDate) {
      return;
    }
    const inforceORproceeding =
      INSURANCE_PIPELINE_STATUS.IN_FORCE === statusId ||
      INSURANCE_PIPELINE_STATUS.NOT_PROCEEDED === statusId;
    if (inforceORproceeding) {
      this.convertApplication(statusId);
    } else {
      this.isInForce = false;
      this.isRequired = false;
      this.commencementDate = null;
      this.changeWorkBenchPipelineStatus(statusId);
    }
  }

  invalidateCommencementDate(statusId) {
    this.saveInsuranceStatus(statusId);
  }

  onSelectStatus(statusId) {
    if (INSURANCE_PIPELINE_STATUS.IN_FORCE === statusId) {
      this.isInForce = true;
      this.isRequired = true;
      this.commencementDate = null;
      return;
    }
    this.saveInsuranceStatus(statusId);
  }

  changeWorkBenchPipelineStatus(statusId, reasonForNotProceedingID) {
    const commencementDate = moment(this.commencementDate).format(
      TO_MYCRM_DATE_FORMAT,
    );
    this.insurancePipelineService
      .movePipelineCardToStatus({
        fromStatusId: this.workBenchDetails.pipelineStatusId,
        toStatusId: statusId,
        quoteId: this.quoteId,
        reasonForNotProceedingID,
        commenceDate: commencementDate,
      })
      .then((data) => {
        if (data.status === 200) {
          this.isRequired = false;
          toastSuccess('Status change successfully');
          this.onStatusChange && this.onStatusChange();
        }
      })
      .catch((error) => toastError(error));
  }

  populateWorkBenchData() {
    this.getWorkBenchDetails();
    this.loadNotProceededStatuses();
    this.getInsurancePipelineStatus();
  }
}
