import { UNIX_MULTIPLIER } from 'Common/constants/dateConfigs';

export function haversineInKM(lat1, lng1, lat2, lng2) {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  // const radlon1 = Math.PI * lng1 / 180;
  // const radlon2 = Math.PI * lng2 / 180;
  const theta = lng1 - lng2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  // Get in in kilometers
  dist *= 1.609344;

  return dist;
}
export function typelessEqual(a, b) {
  // eslint-disable-next-line eqeqeq
  return a == b;
}

export function getWholeNumber(n) {
  if (!n) {
    return 0;
  }
  return Math.floor(n);
}

export function getRemainder(n) {
  return (n % 1).toFixed(2).slice(1);
}

export function bitwiseXOR(a, b) {
  // algebraic expression (a * !b) + (!a * b)
  const bitwise = (a && !b) || (!a && b);
  return bitwise || false;
}

export function roundOffToNearestHundreds(num) {
  if (!num) {
    return 0;
  }
  return (Math.ceil(num / 100) || 0) * 100;
}

export function getParsed(amount) {
  if (!amount) {
    return 0;
  }
  const replaced = amount.toString().replace(/,/g, '');
  return parseFloat(replaced) || 0;
}

export function getTemporaryUniqueId(baseId = 'uid') {
  return `${baseId}-${(new Date().getTime() / UNIX_MULTIPLIER).toFixed(0)}`;
}

export function isValueNegligible(value) {
  return !!value && value > 0 && value < 1 && value.toString().includes('e');
}

export const getMaxValue = (value1, value2) => {
  if (!value1 && !value2) {
    return 0;
  }
  if (value1 && !value2) {
    return value1;
  }
  return value1 > value2 ? value1 : value2;
};
