import angular from 'angular';
import { filter, find, forEach } from 'lodash';
import { WORK_BENCH_STATES } from 'Common/constants/workbench';
import { isMobileView } from 'Common/utilities/clientWindowWatcher';
import { objectLength } from 'Common/utilities/objectValidation';

class WorkbenchCtrl {
  constructor(
    uiService,
    $window,
    contactService,
    loanScenarioService,
    $stateParams,
    $state,
    stateService,
    $uibModal,
  ) {
    'ngInject';

    this.uiService = uiService;
    this.$window = $window;
    this.contactService = contactService;
    this.loanScenarioService = loanScenarioService;
    this.WORK_BENCH_STATES = WORK_BENCH_STATES;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.stateService = stateService;
    this.$uibModal = $uibModal;
  }

  $onInit() {
    this.mobileViewInit();
    this.workbenchView = this.WORK_BENCH_STATES.IS_MAIN;
    this.logList = [];
    this.familyList = [];
    this.clientsList = [];
    this.clientDetails = [];
    this.homePhone = [];
    this.mobilePhone = [];
    this.changeTaskDueCount = 0;

    this.getWorkbenchRequirementLog();
    this.getApplicants();
  }

  mobileViewInit() {
    this.isMobileViewScreen = isMobileView(this.$window.innerWidth);
    if (this.isMobileViewScreen) {
      this.initParamsForMobile();
      this.prevState = this.stateService.getPreviousState();
    }
  }

  initParamsForMobile() {
    const {
      LoanID,
      LoanStatusID,
      OrgPipelineStatusID,
      PipelineCardsID,
      LoanScenarioID,
      familyId,
      enquirySource,
      clientNames,
    } = this.$stateParams;

    this.loanId = LoanID;
    this.loanStatusId = LoanStatusID;
    this.orgPipelineStatusId = OrgPipelineStatusID;
    this.loanPipelineCardsId = PipelineCardsID;
    this.loanScenarioId = LoanScenarioID;
    this.familyId = familyId;
    this.enquirySource = enquirySource;
    this.clientNames = clientNames;
  }

  cancel() {
    this.modalInstance.dismiss({
      loanStatusId: this.loanStatusId,
      orgPipelineStatusId: this.orgPipelineStatusId,
    });
  }

  clientInformGet(familyId) {
    this.contactService.clientInformGet(familyId, null).then((response) => {
      const { data } = response;
      if (!data) {
        return;
      }

      this.clientDetails = filter(response.data, (obj) => {
        return obj.Role === 'Adult';
      });

      forEach(this.clientDetails, (obj) => {
        const home = find(obj.Phone, (phone) => {
          return phone.Type === 'Home';
        });

        if (home) {
          this.homePhone.push(home);
        }

        const mobile = find(obj.Phone, (phone) => {
          return phone.Type === 'Mobile';
        });

        if (mobile) {
          this.mobilePhone.push(mobile);
        }
        return obj;
      });
    });
  }

  onNextWorkDateChange() {
    this.changeTaskDueCount++;
  }

  organisationInfoGet(familyId) {
    this.contactService.organisationInfoGet(familyId).then((response) => {
      const { data } = response;
      if (!data) {
        return;
      }

      const { Name: FullName } = data;
      this.clientDetails = [{ FullName }];
      this.homePhone = [];
      this.mobilePhone = [];
    });
  }

  partyChange(familyId, isClient) {
    if (!familyId) {
      return;
    }

    if (isClient) {
      this.clientInformGet(familyId);
    } else {
      this.organisationInfoGet(familyId);
    }
  }

  getApplicants() {
    this.loanScenarioService
      .scenarioApplicantListGet(this.loanScenarioId)
      .then((response) => {
        const { data } = response;
        if (!data) {
          return;
        }

        const { InvolvedPartyPerson, InvolvedPartyEntity } = data;
        const familyList = [];
        const clientsList = [];

        Object.keys(InvolvedPartyPerson).forEach((key) => {
          if (!InvolvedPartyPerson[key].length) {
            return;
          }
          const { FamilyFullName } = InvolvedPartyPerson[key][0];
          familyList.push({ FamilyId: key, FamilyFullName, IsClient: true });

          const clientsObj = InvolvedPartyPerson[key].reduce(
            (accum, current) => {
              const { PreferredName, LastName, FamilyId, PersonId } = current;
              return accum.concat({
                FullName: `${PreferredName} ${LastName}`,
                FamilyId,
                PersonId,
                IsClient: true,
              });
            },
            [],
          );
          clientsList.push(...clientsObj);
        });

        Object.keys(InvolvedPartyEntity).forEach((key) => {
          if (!Object.keys(InvolvedPartyEntity[key]).length) {
            return;
          }
          const {
            FamilyFullName,
            FamilyId,
            OrganisationId,
          } = InvolvedPartyEntity[key];
          familyList.push({ FamilyId, FamilyFullName, IsClient: false });
          clientsList.push({
            FullName: FamilyFullName,
            FamilyId,
            PersonId: OrganisationId,
            IsClient: false,
          });
        });

        this.familyList = familyList;
        this.clientsList = clientsList;

        const findFamily = find(
          familyList,
          (family) =>
            parseInt(family.FamilyId, 10) === parseInt(this.familyId, 10),
        );
        this.selectedParty = findFamily;
        if (!this.selectedParty || !this.selectedParty.FamilyId) {
          return;
        }
        this.partyChange(
          this.selectedParty.FamilyId,
          this.selectedParty.IsClient,
        );
      });
  }

  getWorkbenchRequirementLog() {
    this.loanScenarioService
      .workbenchRequirementLog(this.loanId)
      .then((response) => {
        this.logList = response.data;
      });
  }

  back(e) {
    this.workbenchView = this.WORK_BENCH_STATES.IS_MAIN;
    // below code is responsible for resizing the modal only
    const el = this.$window.document.querySelectorAll('.workbench-modal');
    angular.element(el).removeClass(this.WORK_BENCH_STATES.ACTIONS.join(' '));

    if (e && e.refresh) {
      this.getWorkbenchRequirementLog();
    }
  }

  previousLink() {
    if (
      !objectLength(this.prevState) ||
      !objectLength(this.prevState.self) ||
      !this.prevState.self.name ||
      !objectLength(this.prevState.param)
    ) {
      return;
    }
    this.$state.go(this.prevState.self.name, this.prevState.param);
  }

  showNotesFields(noteToEdit) {
    const props = {
      noteToEdit,
      loanId: this.loanId,
      familyId: this.familyId,
    };

    this.noteToEdit = noteToEdit;
    if (!this.isMobileViewScreen) {
      return;
    }
    const modalInstance = this.$uibModal.open({
      template: `<workbench-add-note
                      family-id="vm.props.familyId"
                      loan-id="vm.props.loanId"
                      note-to-edit="vm.props.noteToEdit"
                      modal-instance="vm.modalInstance"
                    ></workbench-add-note>`,
      controller: 'CommonModalPlaceholderCtrl',
      resolve: {
        props: () => props,
      },
      controllerAs: 'vm',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowTopClass: 'workbench-mobile-addnote',
    });
    modalInstance.result.then(
      () => {},
      () => {
        this.getWorkbenchRequirementLog();
      },
    );
  }
}

export default WorkbenchCtrl;
