import {
  REQUIREMENTS_OBJECTIVES_AU,
  REQUIREMENTS_OBJECTIVES_NZ,
} from 'Common/constants/placeHolder';

export const getPlaceHolderCustomerObj = ({ isAU, isNZ }) => (key) => {
  if (isAU) {
    return REQUIREMENTS_OBJECTIVES_AU[key] || '';
  }
  if (isNZ) {
    return REQUIREMENTS_OBJECTIVES_NZ[key] || '';
  }
  return '';
};
