import template from './gamePlanSendingMomentActionButtons.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    isEmailSending: '<',
    isSendEmailDisabled: '<',
    isSendingGamePlanOn: '<',
    isEsignEnabled: '<',
    isNz: '<',
    onSendEmail: '&',
    isDownloading: '<',
    onSendEsign: '&',
    onDownloadPreview: '&',
  },
};
