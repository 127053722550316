import { pdfBuilderForUI } from 'Common/mappers/document';

export default class HandoverPackModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.baseUrl = 'handover-pack';
  }

  download(loanAppId) {
    return this.httpClient
      .get(`${this.baseUrl}/${loanAppId}/download`)
      .then(({ data }) => pdfBuilderForUI(data));
  }
}
