import template from './contactDetailsPreview.html';
import controller from './contactDetailsPreviewCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    contactType: '<',
    contact: '<',
    modalInstance: '<',
    routeContact: '<',
  },
};
