import get from 'lodash/get';
import { DEFAULT } from 'Common/constants/customerCareModules';
import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';
import { getListDefaultValue } from 'Common/utilities/dataManipulation';

class CustomerCareTabFilterCtrl {
  constructor(currentUserService, configService) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.configService = configService;
  }

  $onChanges(changes) {
    const { dateFilter, assignedFilter } = changes;

    if (dateFilter && dateFilter.currentValue) {
      const filter = dateFilter.currentValue;
      this.dateRange = getListDefaultValue(filter, 'value', DEFAULT.DATE_RANGE);
    }

    if (assignedFilter && assignedFilter.currentValue) {
      this.assignedAdviser = get(assignedFilter, 'currentValue[0]', {});
    }
  }

  $onInit() {
    this.SEND_METHOD = SEND_METHOD_TYPES;
    this.unlockingCustomerCare = this.configService.feature.unlockingCustomerCare;
  }
}

export default CustomerCareTabFilterCtrl;
