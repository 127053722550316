import template from './setDefaultTemplate.html';
import SetDefaultTemplateCtrl from './setDefaultTemplateCtrl';

export default {
  template,
  controller: SetDefaultTemplateCtrl,
  controllerAs: 'vm',
  bindings: {
    templateToSet: '<',
    isInsurance: '<',
    description: '@',
    methodType: '@',
  },
};
