import moment from 'moment';
import { getDatePickerConfig } from 'Common/utilities/date';

class DateYearJumper {
  $onInit() {
    if (!this.config) {
      this.config = getDatePickerConfig();
    }
  }

  calculateJump(year) {
    if (!year) {
      return;
    }
    this.model = moment().add(year, 'year').utc().format();
    this.onChange({ date: this.model });
  }
}
export default DateYearJumper;
