import template from './assetSupplierFields.html';
import controller from './assetSupplierFieldsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    assetSupplierObj: '=',
    disableFields: '<',
    isRequired: '<',
  },
};
