import angular from 'angular';

export const loadModule = () =>
  angular.module('app').factory('referralsModel', [
    'httpRequest',
    function (httpRequest) {
      var apiBaseUrl = 'Referrer/';

      function referralsModel() {}

      referralsModel.prototype = {
        getReferrerOrgOwnerGroup(fromLMWMAgreement, agreementId = 0, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgOwnerGroupGet?fromLMWMAgreement=${fromLMWMAgreement}&agreementID=${agreementId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getReferrerOrgMembers(
          adviserOrganizationId,
          agreementId = 0,
          callback,
        ) {
          var id =
            typeof adviserOrganizationId == 'undefined'
              ? 0
              : adviserOrganizationId;
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgMembersGet?adviserOrganizationId=${id}&agreementID=${agreementId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getAgreementTypes(callback) {
          httpRequest.get('corporate/AgreementTypesGet', (response) => {
            return callback(response);
          });
        },
        getReferrerOrgAgreementsByReferrers(
          status,
          createDateFrom,
          createDateTo,
          mermberId,
          groupId,
          isVisible,
          isActive,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgAgreementsByReferrersGet?status=${status}&createDateFrom=${createDateFrom}&createDateTO=${createDateTo}&mermberID=${mermberId}&groupID=${groupId}&isVisible=${isVisible}&isActive=${isActive}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getReferrerOrgAgreementsByGroup(
          status,
          createDateFrom,
          createDateTo,
          GroupTypeId,
          mermberId,
          groupId,
          isVisible,
          isActive,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgAgreementsByGroupGet?Status=${status}&CreateDateFrom=${createDateFrom}&CreateDateTO=${createDateTo}&GroupTypeID=${GroupTypeId}&MermberID=${mermberId}&GroupID=${groupId}&IsVisible=${isVisible}&IsActive=${isActive}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getReferrerOrgAgreementsReferrerInfo(
          referrerId,
          agreementId,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgAgreementsReferrerInfoGet?referrerID=${referrerId}&agreementID=${agreementId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getReferrerOrgAgreementsInfo(agreementId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgAgreementsInfoGet?agreementID=${agreementId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getReferrerOrgAgreementsWeeklyReportSettings(
          referrerId,
          agreementId,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgAgreementsWeeklyReportSettingsGet?referrerID=${referrerId}&agreementID=${agreementId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getReferrerOrgReferrerAgreement(agreementId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgReferrerAgreementGet?agreementID=${agreementId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getOrganizationTypes(callback) {
          httpRequest.get('corporate/OrganizationTypesGet', (response) => {
            return callback(response);
          });
        },
        setReferrerOrgSummary($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}ReferrerOrgSummarySet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },
        setReferrerOrgAgreementsWeeklyReportSettings($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}ReferrerOrgAgreementsWeeklyReportSettingsSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },
        getReferrerOrgAgreementList(callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgAgreementListGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        getReferrerOrgSummary(agreementId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgSummaryGet?agreementID=${agreementId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getAddressDetails(addressId, callback) {
          httpRequest.get(
            `AddressDetailsGet?addressId=${addressId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        setAddressDetails($postData, callback) {
          httpRequest.post('AddressDetailsSet', $postData, (response) => {
            return callback(response);
          });
        },
        deleteReferrerOrgDirector(pDirectorId, callback) {
          httpRequest.delete(
            `${apiBaseUrl}ReferrerOrgReferrerOrgDirectorDelete?pDirectorId=${pDirectorId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        deleteReffererOrgReferrer(ReferrerfamilyID, callback) {
          httpRequest.delete(
            `${apiBaseUrl}ReffererOrgReferrerDelete?ReferrerfamilyID=${ReferrerfamilyID}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getReferralList(
          page,
          size,
          searchText,
          status,
          processor,
          fromDate,
          toDate,
          adviserOrgId,
          adviserId,
          referrerOrgId,
          referrerId,
          isActive,
          showAllAdvisers,
          isAdviserActive,
          createdDateFrom,
          createdDateTo,
          businessUnitId,
          referrerOrgName,
          referrerName,
          sortByColumnName,
          sortOrder,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}ReferralListGet?p.page=${page}&p.size=${size}&p.searchText=${searchText}&p.status=${status}&p.processor=${processor}&p.fromDate=${fromDate}&p.toDate=${toDate}&p.adviserOrgId=${adviserOrgId}&p.adviserId=${adviserId}&p.referrerOrgId=${referrerOrgId}&p.referrerId=${referrerId}&p.isActive=${isActive}&p.showAllAdvisers=${showAllAdvisers}&p.isAdviserActive=${isAdviserActive}&p.createdDateFrom=${createdDateFrom}&p.createdDateTo=${createdDateTo}&p.businessUnitIDs=${businessUnitId}&p.searchRefOrgName=${referrerOrgName}&p.searchRefName=${referrerName}&p.sortByColumnName=${sortByColumnName}&p.sortOrder=${sortOrder}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getReferrerOrgList(searchText = '', callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgListGet?searchText=${searchText}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getReferralReferrerList(searchText, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferralReferrerListGet?searchText=${searchText}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getReferrerOrgMembership(referrerOrgId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgMembershipGet?referrerOrgId=${referrerOrgId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getCheckAgreementExist(adviserOrgId, referrerOrgId, callback) {
          httpRequest.get(
            `${apiBaseUrl}CheckAgreementExistGet?referrerOrgId=${referrerOrgId}&adviserOrgId=${adviserOrgId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getAdviserAgreementSearchOptions(callback) {
          httpRequest.get('AdviserAgreementSearchOptionsGet', (response) => {
            return callback(response);
          });
        },
      };
      return referralsModel;
    },
  ]);
