import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { isCancelled } from 'Common/utilities/subscription';
import { isEnvironment } from 'Common/utilities/env';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { SUBSCRIPTION_TYPE_INFO } from 'Common/constants/subscriptionType';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';
import {
  showPriceFinder,
  showPriceFinderBYOB,
} from 'Common/utilities/showFeature';
import { FETCH_RECENTLY_VIEWED } from 'Assets/js/controllers/util/constants';
import { getConfigFromEnv } from 'Common/utilities/config';

class LayoutCtrl {
  constructor(
    $localStorage,
    uiService,
    configService,
    usersSubscriptionService,
    currentUserService,
    viewOfFileModalService,
    $scope,
    TECHNOLOGY,
  ) {
    'ngInject';

    this.uiService = uiService;
    this.configService = configService;
    this.localStorage = $localStorage;
    this.usersSubscriptionService = usersSubscriptionService;
    this.currentUserService = currentUserService;
    this.viewOfFileModalService = viewOfFileModalService;
    this.$scope = $scope;
    this.isAngular = TECHNOLOGY.isAngular;
    this.isProductionBanner = () => isEnvironment('uat');
    this.showNewPipeline =
      !this.isAngular && this.configService.feature.newPipeline;
    this.showCustomAggregator =
      !this.isAngular &&
      this.configService.feature.customAggregator &&
      uiService.hasAggregatorAccess;
    this.showAutomation =
      !this.isAngular &&
      this.configService.feature.newPipelineTasks &&
      this.currentUserService.isPrincipalAdviser;

    this.hideNav =
      this.configService.feature.useNavInAngular && !this.isAngular;
  }

  $onInit() {
    const isEnvNotifDefined = typeof this.localStorage.isEnvNotifDismissed;
    this.isOnboarding = this.currentUserService.isOnboarding;
    if (isEnvNotifDefined === 'undefined' || isEnvNotifDefined === 'null') {
      this.localStorage.isEnvNotifDismissed = false;
    }

    this.BRANDING_CATEGORY_TYPE = BRANDING_CATEGORY_TYPE;
    const { priceFinder, priceFinderBYOB, nodifi } = this.configService.feature;
    this.showPriceFinder =
      showPriceFinder(priceFinder, this.currentUserService) ||
      showPriceFinderBYOB(priceFinderBYOB, this.currentUserService);

    if (nodifi) {
      this.nodifiLink = this.currentUserService.hasBrandFeature(
        'HasNonLMNodifi',
      )
        ? getConfigFromEnv().nodifiNonLMUrl
        : getConfigFromEnv().nodifiUrl;
      this.nodifiApiUrl = getConfigFromEnv().nodifiApiUrl;
    }
  }

  fetchRecentlyViewed() {
    this.$scope.$emit(FETCH_RECENTLY_VIEWED, true);
  }

  dismissEnvNotif() {
    this.localStorage.isEnvNotifDismissed = true;
  }

  priceFinderClick(mobileCLick) {
    if (mobileCLick) {
      this.uiService.menuToggleInMobile();
    }
    const familyId = this.uiService.userFamilyId();
    if (!familyId) {
      return;
    }
    const priceFinderData = this.usersSubscriptionService.getPriceFinderData();
    const { AccessType } = this.uiService.loggedUserData;
    const validAccessType = [
      ACCESS_TYPE.ADMIN_ASSISTANT,
      ACCESS_TYPE.ASSISTANT,
      ACCESS_TYPE.PRINCIPAL_ADVISER,
      ACCESS_TYPE.ADVISER,
    ].includes(AccessType);
    const checkPriceFinderStatus =
      AccessType === ACCESS_TYPE.CORPORATE ||
      (validAccessType &&
        priceFinderData &&
        (priceFinderData.isActive || isCancelled(priceFinderData)));
    if (checkPriceFinderStatus) {
      this.usersSubscriptionService.priceFinderRedirect();
    } else {
      const props = {
        familyId,
        subscriptionData: {
          ...priceFinderData,
          ...SUBSCRIPTION_TYPE_INFO[SUBSCRIPTIONTYPE.PRICE_FINDER],
        },
        orderedById: familyId,
      };
      this.usersSubscriptionService
        .subscribeToService(props)
        .then((response) => {
          if (!response) {
            return;
          }
          this.usersSubscriptionService.priceFinderRedirect();
          this.uiService.getCurrentUserSubscriptions();
        });
    }
  }
}

export default LayoutCtrl;
