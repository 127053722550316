import angular from 'angular';
import { toastSuccess } from 'Common/utilities/alert';
import { complianceCategoryBuilderForAPI } from 'Common/mappers/contact';

export const filterContactCategory = ({ $scope, data, modelService }) => {
  if (!data) {
    return;
  }

  $scope.existingCategoryNameList = [];
  const listOfCategory = [];
  data.forEach((value) => {
    const { CategoryName: categoryName } = value;
    if (categoryName && !listOfCategory.includes(categoryName)) {
      listOfCategory.push(categoryName);
      value.IsChecked =
        ($scope.isCategoryPresent && $scope.isCategoryPresent(categoryName)) ||
        (modelService && modelService.isCategoryPresent(categoryName)) ||
        false;

      $scope.existingCategoryNameList.push(value);
    }
  });
};

export const isComplianceCategorySelected = ({
  concatenatedCategoryNames,
  categoryName,
}) => {
  const splitCategoryNames = concatenatedCategoryNames?.split(', ') || [];
  return splitCategoryNames.includes(categoryName);
};

export const toggleCategoryList = (list, pushObject = false) => (category) => {
  const { CategoryName, IsChecked } = category;
  const pushValue = pushObject ? category : CategoryName;
  const categoryIndex = pushObject
    ? list.findIndex((cat) => cat.CategoryName === CategoryName)
    : list.indexOf(pushValue);
  const categoryNotFound = categoryIndex < 0;

  if (IsChecked) {
    categoryNotFound && list.push(pushValue);
  } else {
    !categoryNotFound && list.splice(categoryIndex, 1);
  }
};

export const isCategoryNameExisting = ({ categoryName, categoryList = [] }) => {
  return !!categoryList.find(
    (category) => category.CategoryName === categoryName,
  );
};

export const isCategoryNameDuplicated = ({
  categoryName,
  categoryList = [],
}) => {
  const occurenceCount = 1;
  return (
    categoryList?.filter((category) => category.CategoryName === categoryName)
      .length > occurenceCount
  );
};

export const getCategoryModelTypeService = ({
  $scope,
  isCompliance,
  contactModelService,
  contactService,
}) => {
  return isCompliance
    ? initializeComplianceCategoryService({
        contactModelService,
        $scope,
      })
    : initializeClientCategoryService({
        contactService,
        $scope,
      });
};

export const initializeComplianceCategoryService = ({
  contactModelService,
  $scope,
}) => {
  const modelService = {
    instance: 'Compliance',
    categoryListGet: () => contactModelService.getComplianceCategory(),
    categoryDelete: (categoryId, category) => {
      const payload = {
        ComplianceCategoryId: categoryId,
        ComplianceCategoryName: category.CategoryName,
      };
      return contactModelService.deleteComplianceCategory(payload);
    },
    categorySave: (category) => {
      return contactModelService.saveComplianceCategory(
        complianceCategoryBuilderForAPI(category),
      );
    },
    categoryUpdate: (currentlyEditedCategory) =>
      contactModelService.saveComplianceCategory(
        complianceCategoryBuilderForAPI(currentlyEditedCategory),
      ),
    categoryReorder: (categoryId, orderBy, category) => {
      const payload = {
        ComplianceCategoryId: categoryId,
        CurrentPositionNumber: category.OrderBy,
        NewPositionNumber: parseInt(orderBy),
      };
      return contactModelService.saveComplianceCategoryReorder(payload);
    },
    categorySaveToFamily: () => {
      const payload = {
        ComplianceCategoryAdviserId:
          $scope.familyComplianceGet.ComplianceCategoryAdviserId || 0,
        FamilyComplianceId: $scope.familyComplianceGet.FamilyComplianceId,
        ComplianceCategoryIds: $scope.selectedAdviserComplianceCategories
          .map((category) => category.CategoryID)
          .join(','),
      };
      return contactModelService.saveAdviserComplianceCategory(payload);
    },
    isCategoryPresent: (category) => {
      return isComplianceCategorySelected({
        concatenatedCategoryNames: $scope.adviserComplianceCategories,
        categoryName: category,
      });
    },
    toggleCategoryList: toggleCategoryList(
      $scope.selectedAdviserComplianceCategories,
      true,
    ),
  };

  return {
    ...modelService,
    getCommonCategory: () => {
      modelService.categoryListGet().then((res) => {
        const data = res;
        if (!data) {
          return;
        }
        filterContactCategory({
          $scope,
          data,
          modelService,
        });
      });
    },
    onDone: () => {
      modelService.categorySaveToFamily().then(() => {
        toastSuccess('Category has been successfully updated', 'Updated');
        $scope.reloadFamilyCompliance && $scope.reloadFamilyCompliance();
      });
      $scope.cancel();
    },
  };
};

export const initializeClientCategoryService = ({ contactService, $scope }) => {
  const modelService = {
    instance: 'Client',
    categoryListGet: () => contactService.categoryListsGet($scope.familyId),
    categoryDelete: (categoryId) => contactService.deleteCategory(categoryId),
    categorySave: (category) =>
      contactService.categorySet(
        $scope.familyId,
        category.CategoryID,
        category.CategoryName,
      ),
    categoryUpdate: (currentlyEditedCategory) =>
      contactService.updateCategory(
        currentlyEditedCategory.CategoryID,
        currentlyEditedCategory.CategoryName,
      ),
    categoryReorder: (categoryId, orderBy) =>
      contactService.updateCategoryOrder(categoryId, orderBy),
    categorySaveToFamily: () =>
      contactService.contactFamilyInfoSet(
        angular.toJson($scope.contactInfoSetValue),
      ),
    isCategoryPresent: (category) => {
      return $scope.getContactFamilyInfo.Categories.includes(category);
    },
    toggleCategoryList: toggleCategoryList(
      $scope.contactInfoSetValue.Categories,
      false,
    ),
  };

  return {
    ...modelService,
    getCommonCategory: () => {
      modelService.categoryListGet().then((res) => {
        const data = res.data;
        if (!data) {
          return;
        }
        filterContactCategory({
          $scope,
          data,
          modelService,
        });
      });
    },
    onDone: () => {
      $scope.referralChecker('Categories');
      modelService.categorySaveToFamily().then(() => {
        $scope.contactFamilyInfoGet();
        toastSuccess('Category has been successfully updated', 'Updated');
      });
      $scope.cancel();
    },
  };
};
