import angular from 'angular';

class ForQuickProductFinderDetailModalCtrl {
  constructor(
    $uibModalInstance,
    loanStructure,
    product,
    selectedProductCount,
    selectLoanProduct,
  ) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.loanStructure = loanStructure;
    this.product = product;
    this.selectedProductCount = selectedProductCount;
    this.selectLoanProduct = selectLoanProduct;
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'ForQuickProductFinderDetailModalCtrl',
      ForQuickProductFinderDetailModalCtrl,
    );
