import { existingReferrerDetailsBuilderForUI } from 'Common/mappers/existingReferrerDetails';
import { REFERRAL_LABEL } from 'Common/constants/referralType';
import {
  referralNoteBuilderForUI,
  referralBuilderForUI,
  loanReferralCommissionBuilderForUI,
} from 'Common/mappers/referral';

class ReferralService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Referral';
  }

  existingReferrerDetails(referrerEmail) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/existing-referrer-details`, { referrerEmail })
      .then((response) =>
        response.data.map(existingReferrerDetailsBuilderForUI),
      );
  }

  referralAssetsFinanceSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/asset-finance`, data);
  }

  getReferralNoteList(referralId, pageSize, pageNumber) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${referralId}/notes`, { pageSize, pageNumber })
      .then(({ data }) => {
        return {
          noteList:
            (data.Notes && data.Notes.map(referralNoteBuilderForUI)) || [],
          totalCount: data.TotalCount || 0,
        };
      });
  }

  getReferralWealthMarket(referralId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ReferralWealthMarketGet`, { referralId })
      .then(({ data }) =>
        referralBuilderForUI(data, REFERRAL_LABEL.WEALTH_MARKET),
      );
  }

  saveLoanReferralCommission(changeTypeId = 1, payload) {
    return this.httpClient
      .post(
        `${this.apiBaseUrl}/commission?changeTypeId=${changeTypeId}`,
        payload,
      )
      .then(({ data }) => data && loanReferralCommissionBuilderForUI(data));
  }

  getLoanReferralCommission(adviserId, loanScenarioId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/commission`, {
        adviserId,
        loanScenarioId,
      })
      .then(({ data }) => data && loanReferralCommissionBuilderForUI(data));
  }

  initializeLoanReferralCommission(adviserId, loanScenarioId) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/initialize`, { adviserId, loanScenarioId })
      .then(({ data }) => data && loanReferralCommissionBuilderForUI(data));
  }
}

export default ReferralService;
