import template from './privacyConsentTable.html';
import controller from './privacyConsentTableCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    summaryTab: '<',
    loanAppId: '<',
    involvedPartyPerson: '<',
    allInvolvedParties: '<',
    countryList: '<',
    updateClientCallback: '&',
  },
};
