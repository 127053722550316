import angular from 'angular';
import { displayError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    .controller('DocumentModalCtrl', function DocumentModalCtrl(
      $scope,
      $uibModalInstance,
      SweetAlert,
      documentDataObject,
      isUpdate,
      $timeout,
      userInfo,
      isClientDocuments,
      documentService,
      currentPage,
    ) {
      $scope.documentThumbnail = documentDataObject;
      $scope.saveBtnText =
        typeof isUpdate !== 'undefined' ? 'Update Document' : 'Upload File';

      $scope.documentObject = {
        Title: documentDataObject.Title,
        Tags: documentDataObject.Tags || '',
        Description: documentDataObject.Description || '',
        DoucmentID: documentDataObject.DocumentID,
        FamilyID: documentDataObject.FamilyID,
        OrganisationId: $scope.adviserOrgId,
        SharedWithCorporateTeamId: documentDataObject.SharedWithCorporateTeamId,
        UploadedByCorporateTeamId: null,
        docFromEsign: documentDataObject.DocFromESign_GroupedDocument,
      };
      $scope.uploadDocument = () => {
        const successMessage = isUpdate
          ? 'Document has been been successfully updated'
          : 'Document has been been successfully uploaded';
        documentService
          .setDocumentProperty(
            $scope.documentObject,
            documentDataObject.DocFromLoanApp,
          )
          .then(() => {
            $scope.$parent.loadDocumentList &&
              $scope.$parent.loadDocumentList();
            SweetAlert.swal({
              title: 'Success',
              text: successMessage,
              type: 'success',
              timer: 2000,
              showConfirmButton: false,
            });
            $timeout(() => {
              $scope.cancel(true);
            }, 2000);
          })
          .catch(displayError);
      };
      $scope.cancel = function (isRefresh = false) {
        $uibModalInstance.close({
          isRefresh,
          documentGroupTitle: $scope.documentThumbnail.groupTitle,
          currentPage,
        });
      };
      $scope.isClientDocuments = isClientDocuments;
      $scope.setDocumentInfo = () => {
        $scope.documentObject.SharedWithCorporateTeamId = $scope.documentObject
          .SharedWithCorporateTeamId
          ? 0
          : userInfo.CorporateTeamId;
      };
      $scope.getDocumentInfo = () => {
        if ($scope.documentObject.SharedWithCorporateTeamId) {
          return `Just my team (${userInfo.CorporateTeam})`;
        }
        return `All Corporate Users`;
      };
    });
