export const isToggledLmiProductMatch = (listItem, lmiProduct) => {
  return !!(
    listItem &&
    lmiProduct &&
    listItem.LenderId === lmiProduct.LenderId &&
    listItem.LMIProductId === lmiProduct.LMIProductId
  );
};

export const lmiProductMapper = (item) => {
  return (
    item && {
      LenderId: item.LenderId,
      LenderName: item.LenderName,
      CountryCode: item.CountryCode,
      LoanAmountExLMI: item.LoanAmountExLMI,
      LMIProductId: item.LMIProductId,
      LenderLogo: item.LenderLogo,
    }
  );
};
