export const getSaveToOpportunityBtnName = (leadOpportunitiesService) => {
  return `Save to ${leadOpportunitiesService.getLabel(true)}`;
};

export const setOpportunityMessage = ({
  message,
  leadOpportunitiesService,
}) => {
  return `${message} ${leadOpportunitiesService.getLabel(true).toLowerCase()}`;
};

export const initializeOpportunityLabel = ({
  $scope,
  leadOpportunitiesService,
  isSingular,
}) => {
  $scope.opportunityLabel = leadOpportunitiesService.getLabel(isSingular);
};

export const initFundCalcTooltipMessage = ({
  $scope,
  leadOpportunitiesService,
}) => {
  $scope.tootltipMessage = `Just click this button and we will keep this handy within your client's`;

  $scope.saveOpportunitiesDefaultBtnTooltipMessage = setOpportunityMessage({
    message: $scope.tootltipMessage,
    leadOpportunitiesService,
  });
};
