import { CONTENT_TYPE } from 'Common/constants/contentType';

export function getFilenameExtension(contentType) {
  if (typeof contentType !== 'string') {
    return '';
  }
  switch (contentType) {
    case CONTENT_TYPE.EXCEL:
      return '.xls';
    case CONTENT_TYPE.SPREAD_SHEET:
      return '.xlsx';
    case CONTENT_TYPE.MACRO_SHEET:
      return '.xlsm';
    default:
      return '';
  }
}

export const extractFileExtension = (fileName) => {
  if (!fileName) {
    return '';
  }
  const list = fileName.split('.');
  const ext = list[list.length - 1];
  return ext.toLowerCase();
};

export const isInvalidServicabilityWorksheetLoanAmount = (loanAmount) => {
  return !loanAmount || loanAmount < 1;
};
