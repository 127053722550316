import template from './lenderSubmission.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    onInit: '&',
    onOnlineSubmission: '&',
    onEmailSubmission: '&',
    onPrintSubmission: '&',
    onOnlineBlueStone: '&',
    submissionMethod: '<',
    isSubmitOnline: '<',
    isSubmitOnlineDisabled: '<',
    lixiSummary: '<',
    lenderId: '<',
    isLodged: '<',
    enableSendEmail: '<',
  },
};
