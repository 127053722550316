/**
 * Hide navigation if there's no visible child item
 */
import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('hideNavMenu', function hideNavMenu($timeout) {
      return {
        link(scope, element, attrs) {
          const hideNav = () => {
            const classOfParentNav = attrs.hideNavMenu;
            const navList = element.querySelectorAll(classOfParentNav);
            if (!navList || !navList.length) {
              return;
            }

            const childCount = navList[0].children.length;
            const hiddenChildren = navList[0].querySelectorAll('.ng-hide');
            if (childCount === 0 || childCount === hiddenChildren.length) {
              element.addClass('hidden');
            }
          };

          const hideNavTimeout = $timeout(hideNav);
          scope.$on('$destroy', () => {
            $timeout.cancel(hideNavTimeout);
          });
        },
      };
    });
