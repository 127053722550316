import angular from 'angular';

export const loadModule = () =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  angular.module('app').directive('autogrowTxt', function autogrowTxt($window) {
    return {
      link(scope, element, attrs) {
        if (!scope || !element || !element.length || !attrs) {
          return;
        }
        // Default settings
        scope.attrs = {
          rows: 1,
          maxLines: 999,
        };
        const defaultHeight = parseInt(attrs.height, 10);

        // Merge defaults with user preferences
        Object.keys(scope.attrs).forEach((key) => {
          if (scope.attrs[key]) {
            scope.attrs[key] = parseInt(scope.attrs[key], 10);
          }
        });

        // Calculates the vertical padding of the element
        scope.getOffset = () => {
          const style = $window.getComputedStyle(element[0], null);
          const props = ['paddingTop', 'paddingBottom'];
          let offsetValue = 0;
          Object.keys(props).forEach((key) => {
            if (props[key]) {
              offsetValue += parseInt(style[props[key]], 10);
            }
          });
          return offsetValue;
        };

        const offset = scope.getOffset();
        const lineHeight =
          defaultHeight / scope.attrs.rows - offset / scope.attrs.rows;
        const maxAllowedHeight = lineHeight * scope.attrs.maxLines - offset;

        // Sets textarea height as exact height of content
        scope.autogrowFn = () => {
          let newHeight = 0;
          let hasGrown = false;
          const scrollHeight =
            element[0].scrollHeight > defaultHeight
              ? element[0].scrollHeight
              : defaultHeight;
          if (scrollHeight - offset > maxAllowedHeight) {
            element[0].style.overflowY = 'scroll';
            newHeight = maxAllowedHeight;
          } else {
            element[0].style.overflowY = 'hidden';
            element[0].style.height = 'auto';
            newHeight = scrollHeight - offset;
            hasGrown = true;
          }

          element[0].style.height = `${newHeight}px`;
          return hasGrown;
        };

        element[0].addEventListener('input', scope.autogrowFn);

        // Auto-resize when there's content on page load
        if (element[0].value !== '') {
          scope.autogrowFn();
        }
      },
    };
  });
