import template from './creditChecksList.html';
import controller from './creditChecksListCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    onInitialized: '&',
    onOpenSubscription: '&',
    isAdviserView: '<',
    familyId: '<',
    useNewLayout: '<',
  },
};
