import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'SelectOrganisationModalCtrl',
      function SelectOrganisationModalCtrl(
        $scope,
        $uibModalInstance,
        $uibModal,
        referralsService,
        $state,
        cancel,
      ) {
        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
        referralsService.getReferrerOrgAgreementList().then((response) => {
          $scope.agreementList = response.data;
        });
        $scope.onSearchItemSelect = function (item) {
          $scope.cancel();
          cancel();
          $state.go('app.newReferral', {
            referralType: item.OrganisationTypeId,
            agreementId: item.AgreementId,
          });
        };
      },
    );
