import { getMonthList } from 'Common/utilities/month';
import { toastError, toastSuccess } from 'Common/utilities/alert';
import { findIndexWithAttr } from 'Common/utilities/array';
import { annualReviewFeature } from 'Common/utilities/insurancePipeline';

export default class AnnualReviewMonthModalCtrl {
  constructor(contactService, insurancePipelineService) {
    'ngInject';

    this.contactService = contactService;
    this.insurancePipelineService = insurancePipelineService;
    this.cancel = this.cancel.bind(this);
  }

  $onInit() {
    this.isReviewMonth = false;
    this.isLoading = false;
    this.reviewMonthId = 0;
    this.reviewMonth = '';
    this.currentYear = new Date().getFullYear();
    this.monthsList = [];
    this.getMonthsList();
    this.getContactFamilyInfo();
  }

  getContactFamilyInfo() {
    this.contactService.contactFamilyInfoGet(this.familyId).then((response) => {
      const { data } = response;
      if (data) {
        return;
      }

      this.reviewMonth = data.ReviewMonth;
      this.isReviewMonth = !!data.ReviewMonth;
      this.reviewMonthId =
        findIndexWithAttr(this.monthsList, 'name', this.reviewMonth) + 1;
    });
  }

  updateReviewMonth() {
    if (!this.reviewMonthId) {
      toastError('Annual Review Month cannot be empty.');
      return;
    }
    this.isLoading = true;
    this.insurancePipelineService
      .postReviewMonth({
        familyId: this.familyId,
        reviewMonth: this.reviewMonthId,
      })
      .then(() => {
        toastSuccess('Review month successfully updated.');
        this.save({});
        this.isLoading = false;
      });
  }

  getMonthsList() {
    getMonthList().map((item, index) => {
      const month = {
        name: item,
        id: index,
      };
      if (item !== 'All Months') {
        this.monthsList.push(month);
      }
      return item;
    });
    annualReviewFeature(this);
  }

  cancel() {
    this.modalInstance.close({ isOk: false });
  }

  save(params) {
    this.modalInstance.close({ isOk: true, ...params });
  }
}
