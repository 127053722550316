import moment from 'moment';
import _ from 'lodash';

export const setBirthdayCompilationUtil = ({
  birthday,
  customerCareService,
  table,
}) => {
  if (!birthday || !Object.keys(birthday).length) {
    return;
  }
  const payload = {
    Birthday: birthday.NewDOB,
    IsCompleted: !birthday.isComplete,
    ClientId: birthday.ClientID,
  };
  customerCareService.setBirthdayCompletion(payload).then(() => {
    table.reload();
  });
};

export const mapRecipients = (utilitiesService, colorService, birthday) => {
  return {
    name: birthday.FullName,
    familyFullName: birthday.FamilyFullName,
    familyId: birthday.FamilyID,
    clientId: birthday.ClientID,
    phoneNumber: birthday.Mobile,
    nameWithMobile: `${birthday.FullName} (${birthday.Mobile})`,
    initials: utilitiesService.filterInitialOneString(birthday.FullName),
    dob: birthday.DateOfBirth,
    backgroundColor: colorService.getRandomColor(),
  };
};

export const getRecipients = (utilitiesService, colorService) => (
  source,
  birthday,
  birthdayBatchList,
) => {
  const _mapRecipients = _.partial(
    mapRecipients,
    utilitiesService,
    colorService,
  );
  switch (source) {
    case 'single': {
      return [_mapRecipients(birthday)];
    }
    case 'selected': {
      return birthdayBatchList.map(_mapRecipients);
    }
    default:
      return [];
  }
};

export const sendSms = (customerCareService, sms) => {
  const smsModel = {
    IsComplete: sms.isComplete,
    Message: sms.message,
    CustomerCareType: 'BIRTH_DAY',
    SmsModelList: sms.recipients.map((recipient) => {
      return {
        FamilyFullname: recipient.familyFullName,
        FamilyID: recipient.familyId,
        MobileNumber: recipient.phoneNumber,
        NotificationHeader: `Birthdays (${moment(recipient.dob).format(
          'D MMM',
        )}) Completed via SMS`,
      };
    }),
  };

  return customerCareService.CustomerCareSMSSend(smsModel);
};

export const sendSmsSuccess = (
  customerCareTemplateService,
  loadBirthdaysTable,
) => (results) => {
  if (!results || !results.length) {
    return;
  }

  results.forEach(({ data }) => {
    if (!data || !data.length) {
      return;
    }

    customerCareTemplateService.customerCareResponseMsg(data, 'SMS');
  });

  loadBirthdaysTable();
};

// source can be 'single' or 'selected'
export const openSMSModal = (
  customerCareService,
  customerCareTemplateService,
  $uibModal,
  $scope,
  _getRecipients,
) => (size, birthday, source) => {
  const recipients = _getRecipients(source, birthday, $scope.birthdayBatchList);

  if (!recipients || !recipients.length) {
    return;
  }

  const props = {
    sms: { recipientsPhoneNumbers: [] },
    recipientsChoices: recipients,
    sendSms: _.partial(sendSms, customerCareService),
    sendSmsSuccess: sendSmsSuccess(
      customerCareTemplateService,
      $scope.reloadTable,
    ),
    allowCustom: recipients.length === 1,
    autoSelectAll: true,
    contactPrimaryKey: 'clientId',
  };

  return $uibModal.open({
    template: `<send-sms
              modal-instance="vm.modalInstance"
              sms="vm.props.sms"
              recipients-choices="vm.props.recipientsChoices"
              show-manage-template="true"
              on-send-sms="vm.props.sendSms(sms)"
              on-send-sms-success="vm.props.sendSmsSuccess(results)"
              allow-custom="vm.props.allowCustom"
              auto-select-all="vm.props.autoSelectAll"
              contact-primary-key="{{vm.props.contactPrimaryKey}}"
            ></send-sms>`,
    controller: 'CommonModalPlaceholderCtrl',
    controllerAs: 'vm',
    backdrop: 'static',
    windowClass: 'modal-send-sms',
    size: 'lg',
    resolve: {
      props: () => props,
    },
  });
};
