import { get } from 'lodash';

export const getInvolvePartiesNames = (involvedParties) => {
  if (!involvedParties) {
    return '';
  }
  const { InvolvedPartyEntity, PersonBorrowers } = involvedParties;
  const parties = [...InvolvedPartyEntity, ...PersonBorrowers];
  return parties
    .map((obj) => {
      return obj.PersonId
        ? `${obj.FirstName} ${obj.LastName}` || obj.FamilyFullName
        : `${obj.Name}`;
    })
    .join(', ');
};

export const getNoteSubject = (involvedParties) => {
  const clientNames = getInvolvePartiesNames(involvedParties);
  return `New Note Created for ${clientNames}`;
};

export const getEmailObject = ({ vm, referrerEmail }) => {
  vm.contactService.getUserInfo().then(({ data }) => {
    if (!data) {
      return;
    }
    vm.emailObj = {
      subject: `${getNoteSubject(vm.involvedParties)}`,
      body: '',
      replyTo: { EmailAddress: data.Email },
      sentDate: new Date(),
      recipients: [{ EmailAddress: referrerEmail }],
    };
    vm.isSmsEnabled = data.SMSEnabled;
  });
};

export const openNotesModal = ({ vm, note }) => {
  if (vm.disableOpenModal) {
    return;
  }
  vm.disableOpenModal = true;
  const {
    loanId,
    familyId,
    adviserId,
    referralId,
    emailObj,
    isSmsEnabled,
    opportunityId,
    adviserInfo,
  } = vm;
  vm.referralModelService
    .getReferrerContactInfo(loanId)
    .then(({ succeeded, data: referrerInfo }) => {
      if (!succeeded && !referrerInfo) {
        return {};
      }
      const finalSmsObj = {
        mobile: referrerInfo.mobile,
        body: '',
      };
      const finalEmailObj = {
        ...emailObj,
        recipients: [{ EmailAddress: referrerInfo.email }],
        subject: getNoteSubject(vm.involvedParties),
        referrerEmail: referrerInfo.email,
      };

      return { finalSmsObj, finalEmailObj };
    })
    .then((smsAndEmailObj) => {
      const noteObj = {
        ...note,
        loanId,
        opportunityId,
        familyId,
        adviserId,
        emailObj: get(smsAndEmailObj, 'finalEmailObj', {}),
        smsObj: get(smsAndEmailObj, 'finalSmsObj', {}),
        clientName: getInvolvePartiesNames(vm.involvedParties),
        brokerName: adviserInfo.fullName,
      };
      const isAdd = !note;
      const props = { noteObj, isAdd, referralId, isSmsEnabled };

      vm.disableOpenModal = false;
      vm.callOpenModal(props);
    })
    .catch(() => {
      vm.disableOpenModal = false;
    });
};
