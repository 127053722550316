import dotProp from 'dot-prop-immutable';
import {
  getContactFullname,
  filterInitialOneString,
} from 'Common/utilities/contact';

class MycrmAllocationCtrl {
  constructor(
    contactService,
    currentUserService,
    optionsService,
    configService,
  ) {
    'ngInject';

    this.contactService = contactService;
    this.currentUserService = currentUserService;
    this.optionsService = optionsService;
    this.configService = configService;
  }

  $onInit() {
    this.showAdditionalOption =
      this.onSearchAdviserNearby && this.currentUserService.isCorporate;
  }

  $onChanges(changes) {
    const { selectedAdviser, selectedAssistant } = changes;

    if (selectedAdviser && selectedAdviser.currentValue) {
      this.selectedAdviser = selectedAdviser.currentValue;
      const { AdviserId, adviserId } = this.selectedAdviser;
      this.selectedAdviser.AdviserId = AdviserId || adviserId;
      this.hasAdviser = false;
      this.getAdviserData();
      this.showAdviserEdit(false);
      if (
        this.resetAssistantOnAdviserChange &&
        typeof this.resetAssistantOnAdviserChange === 'function'
      ) {
        this.resetAssistantOnAdviserChange({
          adviserId: this.selectedAdviser.AdviserId,
        });
      }
      this.getAssistantsList();
    }

    this.handleAssistantChange(selectedAssistant);
  }

  handleAssistantChange(selectedAssistant) {
    if (selectedAssistant && selectedAssistant.currentValue) {
      this.selectedAssistant = selectedAssistant.currentValue;
      const { AssistantFamilyId, assistantFamilyId } = this.selectedAssistant;
      this.selectedAssistant.AssistantFamilyId =
        AssistantFamilyId || assistantFamilyId;
      this.hasAssistant = false;
      this.getAssistantData();
      this.showAssistantEdit(false);
    }
  }

  getAdviserData() {
    const adviserId = dotProp.get(this.selectedAdviser, 'AdviserId');
    if (!adviserId) {
      return;
    }
    this.isAdviserLoading = true;
    this.contactService.clientInformGet(adviserId).then(({ data }) => {
      const adviserData = data && data[0];
      const fullname = getContactFullname(adviserData);
      this.selectedAdviser = {
        ...this.selectedAdviser,
        fullname,
        initials: filterInitialOneString(fullname),
        profilePicture: adviserData && adviserData.ProfilePicture,
      };
      this.hasAdviser = !!this.selectedAdviser.fullname;
      this.isAdviserLoading = false;
    });
  }

  findAssistantFromList(asstId) {
    return (
      this.assistantsList.find((a) => Number(a.Value) === Number(asstId)) || {}
    );
  }

  getAssistantData() {
    const asstId = dotProp.get(this.selectedAssistant, 'AssistantFamilyId');

    if (!asstId) {
      return;
    }
    this.isAssistantLoading = true;
    return this.contactService
      .clientInformGet(asstId)
      .then(({ data }) => {
        const assistantData = data && data[0];

        if (!assistantData || !this.assistantsList) {
          return Promise.all([assistantData, this.getAssistantsList()]);
        }
        return Promise.resolve([assistantData]);
      })
      .then(([assistantData]) => {
        const fullname = !assistantData
          ? this.findAssistantFromList(asstId).Name
          : getContactFullname(assistantData);

        this.selectedAssistant = {
          ...this.selectedAssistant,
          fullname,
          initials: filterInitialOneString(fullname),
          profilePicture: assistantData && assistantData.ProfilePicture,
        };
        this.hasAssistant = !!this.selectedAssistant.fullname;
        this.isAssistantLoading = false;
      });
  }

  getAssistantsList() {
    const adviserId = this.selectedAdviser && this.selectedAdviser.AdviserId;
    if (!adviserId) {
      return Promise.resolve();
    }
    return this.optionsService
      .adviserAssistantsGet(adviserId)
      .then(({ data }) => {
        this.assistantsList = data;
      });
  }

  showAdviserEdit(flag = true) {
    this.isEditingAdviser = flag;
  }

  showAssistantEdit(flag = true) {
    this.isEditingAssistant = flag;
  }

  onAdviserChange(data) {
    if (!data) {
      return;
    }
    data.title = data.title || data.FullName || data.fullName;
    data.FamilyId = data.familyId || data.BrokerId || data.BrokerID;
    if (data.originalObject) {
      const { familyId, BrokerId, BrokerID } = data.originalObject;
      data.originalObject.FamilyId = familyId || BrokerId || BrokerID;
    }

    this.isEditingAdviser = false;
    const adviserId = data && dotProp.get(data, 'originalObject.familyId', '');
    if (adviserId.toString() === this.selectedAdviser.AdviserId) {
      return;
    }
    this.onAdviserSelect({ data });
  }

  onAssistantChange(data) {
    const assistantId = data && data.Value;
    const unchanged = assistantId === this.selectedAssistant.AssistantFamilyId;
    if (!assistantId || unchanged) {
      this.showAssistantEdit(false);
      return;
    }
    this.onAssistantSelect({ data: assistantId });
  }

  showAllocationDisplay(isAdviser) {
    const showAdviserDisplay =
      !this.isEditingAdviser && !this.simpleSelection && !this.isAdviserLoading;
    const showAssistantDisplay =
      !this.isEditingAssistant &&
      !this.simpleSelection &&
      !this.isAssistantLoading;
    return isAdviser ? showAdviserDisplay : showAssistantDisplay;
  }

  showAllocationSelection(isAdviser) {
    const showAdviserSelection =
      (this.isEditingAdviser || this.simpleSelection) && !this.isAdviserLoading;
    const showAssistantSelection =
      (this.isEditingAssistant || this.simpleSelection) &&
      !this.isAssistantLoading;
    return isAdviser ? showAdviserSelection : showAssistantSelection;
  }
}

export default MycrmAllocationCtrl;
