import _ from 'lodash';

class fundingCalculatorDetailService {
  constructor() {
    this.quickLmiFundingCalculatorFunds = {};
    this.quickLmiProposedLending = {};
  }

  getLoanOption(loanOptions, fundingCalculatorLMIDeatilsloanOption) {
    this.tempData = [];
    _.each(loanOptions, (item) => {
      item.IsCheked = false;
      _.each(fundingCalculatorLMIDeatilsloanOption, (itemGet) => {
        if (itemGet.Value === item.Value) {
          item.IsCheked = true;
          return item;
        }
      });
      this.tempData.push(item);
    });
    return this.tempData;
  }
}

export default fundingCalculatorDetailService;
