import {
  PIPELINE_TYPES_STR,
  PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS,
} from 'Common/constants/pipelineType';
import PipelineTableApplication from 'Common/services/pipelineTableApplication';
import PipelineOpportunitySettings from 'Common/services/pipelineTableOpportunity';
import PipelineTableLeads from 'Common/services/pipelineTableLeads';
import { pipelineSettingsBuilderForAPI } from 'Common/mappers/pipeline';
import { PROBABILITIES } from 'Common/constants/probabilities';
import {
  removeLabelFromCard,
  updateLabelOfCard,
} from 'Common/utilities/kanbanHelper';
import { lowercaseFirstLetter } from 'Common/utilities/string';
import { removeDecimals } from 'Common/utilities/currency';
import { isValidArray } from 'Common/utilities/array';
import {
  DEFAULT_TABLE_SIZE,
  APPLICATION_DEFAULT_SIZE,
} from 'Common/constants/pageTable';

export default class PipelineTableCtrl {
  constructor(
    cardRedirectionsService,
    modalRenderService,
    pipelineSharedData,
    pipelineService,
    contactService,
    configService,
    stateService,
    $stateParams,
    $filter,
    $state,
    $sce,
  ) {
    'ngInject';

    this.pipelineOpportunitySettings = new PipelineOpportunitySettings({
      pipelineSharedData,
      pipelineService,
      contactService,
      $filter,
      $sce,
    });
    this.pipelineApplicationSettings = new PipelineTableApplication({
      pipelineService,
      contactService,
      $filter,
    });
    this.pipelineLeadsSettings = new PipelineTableLeads({
      pipelineService,
      contactService,
    });

    this.PIPELINE_TYPES_STR = PIPELINE_TYPES_STR;

    this.onFirstLoad = this.onFirstLoad.bind(this);
    this.processParentNotification = this.processParentNotification.bind(this);
    this.childComponentNotificationRegistration = this.childComponentNotificationRegistration.bind(
      this,
    );

    this.cardRedirectionsService = cardRedirectionsService;
    this.modalRenderService = modalRenderService;
    this.pipelineService = pipelineService;
    this.configService = configService;
    this.stateService = stateService;
    this.$stateParams = $stateParams;
    this.$state = $state;
  }

  $onInit() {
    this.pipelineType = this.$stateParams.pipelineType;
    this.probabilityList = PROBABILITIES;
    this.pipelineV2P1 =
      this.configService.feature.pipelineV2P1 &&
      this.pipelineType === PIPELINE_TYPES_STR.APPLICATION;
    this.isLockLoanappFeatureEnabled =
      (this.configService.feature && this.configService.feature.lockLoanapp) ||
      0;
    const applicationPageSize = this.pipelineV2P1
      ? DEFAULT_TABLE_SIZE
      : APPLICATION_DEFAULT_SIZE;
    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        this.freezeColumn = 2;
        this.pageSize = 10;
        this.pageNumber = this.$stateParams.page || 1;
        break;
      case PIPELINE_TYPES_STR.APPLICATION:
        this.freezeColumn = 1;
        this.pageSize = applicationPageSize;
        this.pageNumber = this.$stateParams.page || 1;
        break;
      default:
        this.freezeColumn = 1;
        this.pageSize = 15;
        this.pageNumber = this.$stateParams.page || 1;
        break;
    }

    this.childComponentEventHandler = {
      applicationTableHandler: null,
    };

    this.onToggleShowLoader({ bool: true });
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
  }

  childComponentNotificationRegistration(handler, handlerType) {
    this.childComponentEventHandler[handlerType] = handler;
  }

  getTableHeaders() {
    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        return this.pipelineOpportunitySettings.getHeaders().then((data) => {
          this.tableHeaders = data;
        });
      case PIPELINE_TYPES_STR.APPLICATION:
        return this.pipelineApplicationSettings.getHeaders().then((data) => {
          if (!data || !data.length) {
            return;
          }
          this.tableHeaders = data.map((item) => {
            return {
              ...item,
              headerClass: lowercaseFirstLetter(item.dataSortObjectKey, '-'),
            };
          });
        });
      case PIPELINE_TYPES_STR.LEADS:
        return this.pipelineLeadsSettings.getHeaders().then((data) => {
          this.tableHeaders = data;
        });
      default:
        break;
    }
  }

  onFirstLoad(tableViewExcludedColumns) {
    this.getTableHeaders().then(() => {
      this.toggleExcludeColumns(tableViewExcludedColumns);
    });
    this.refreshTable();
  }

  refreshTable() {
    if (!this.pipelineV2P1) {
      this.getTableData(this.pageNumber, this.pageSize, this.searchClientName);
      return;
    }
    const isLeads = this.pipelineType === PIPELINE_TYPES_STR.LEADS;
    const isOpportunity = this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY;
    this.pipelineService
      .getPipelineCardFilters(isLeads, isOpportunity)
      .then((res) => {
        const { data } = res;
        const userPageSize =
          isValidArray(data) && data[0].NumberPerPage
            ? data[0].NumberPerPage
            : this.pageSize;
        this.getTableData(this.pageNumber, userPageSize, this.searchClientName);
      });
  }

  setUserPageSize(pageSize) {
    if (this.settings.numberPerPage === pageSize) {
      return;
    }
    this.settings.numberPerPage = pageSize;
    this.pipelineService.setPipelineCardFilters(
      pipelineSettingsBuilderForAPI(this.settings),
    );
  }

  onPageChange(pageNumber, pageSize) {
    if (this.pipelineV2P1) {
      this.setUserPageSize(pageSize);
    }
    this.getTableData(pageNumber, pageSize, this.searchClientName);
    this.$state.params.page = pageNumber;
    this.stateService.transition(this.$state.current, this.$state.params);
  }

  getTableData(pageNumber, pageSize, searchClientName) {
    if (!pageNumber || !pageSize) {
      return;
    }
    this.onToggleShowLoader({ bool: true });
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.searchClientName = searchClientName;

    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.OPPORTUNITY: {
        this.pipelineOpportunitySettings
          .getTableData(this.pageNumber, this.pageSize, this.searchClientName)
          .then(({ totalRecords, tableData }) => {
            this.onToggleShowLoader({ bool: false });
            this.totalRecords = totalRecords;
            this.tableData = tableData;
          });
        break;
      }
      case PIPELINE_TYPES_STR.APPLICATION: {
        this.pipelineApplicationSettings
          .getTableData(this.pageNumber, this.pageSize, this.searchClientName)
          .then(
            ({
              totalRecords,
              tableData,
              totalAmountPerPage,
              totalAmountOverall,
            }) => {
              this.onToggleShowLoader({ bool: false });
              this.totalRecords = totalRecords;
              const record = tableData || [];
              this.tableData = record.map((item) => {
                return {
                  ...item,
                  loanAmount: removeDecimals(item.loanAmount),
                };
              });
              this.totalAmountPerPage = totalAmountPerPage;
              this.totalAmountOverall = totalAmountOverall;
            },
          );
        break;
      }
      case PIPELINE_TYPES_STR.LEADS: {
        this.pipelineLeadsSettings
          .getTableData(this.pageNumber, this.pageSize, this.searchClientName)
          // eslint-disable-next-line sonarjs/no-identical-functions
          .then(({ totalRecords, tableData }) => {
            this.onToggleShowLoader({ bool: false });
            this.totalRecords = totalRecords;
            this.tableData = tableData;
          });
        break;
      }
      default:
        break;
    }
  }

  columnSorting(columnObj = {}) {
    const { dataSortObjectKey } = columnObj;
    this.onToggleShowLoader({ bool: true });
    this.settings.sortColumn = dataSortObjectKey;

    if (dataSortObjectKey !== this.lastSortColumn) {
      this.settings.sortType = 'ASC';
    } else {
      this.settings.sortType =
        this.settings.sortType === 'DESC' ? 'ASC' : 'DESC';
    }
    this.pipelineService
      .setPipelineCardFilters(pipelineSettingsBuilderForAPI(this.settings))
      .then(() => {
        this.lastSortColumn = dataSortObjectKey;
        this.refreshTable();
      });
  }

  toggleExcludeColumns(excludedColumns) {
    const excludedColumnsList = excludedColumns.split(',');
    this.tableHeaders.map((o) => {
      if (o.isExclusionIncluded) {
        const isHidden = excludedColumnsList.includes(
          o.dataSortObjectExcludedKey,
        );
        o.isHidden = isHidden;
      }
      return o;
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  processParentNotification(eventHandler, data = {}) {
    const isRefreshTable =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TABLE;
    const isFirstLoad =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD;
    const isExcludeColumn =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_EXCLUDE_COLUMN;
    const isRefreshLabels =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_LABELS;
    const isRemoveCard =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REMOVE_CARD;

    if (isRefreshTable) {
      const { searchClientName } = data;
      this.searchClientName = searchClientName || '';
      this.pageNumber = this.$stateParams.page || 1;
      this.refreshTable();
    } else if (isFirstLoad) {
      const { tableViewExcludedColumns, searchClientName } = data;
      this.searchClientName = searchClientName || '';
      this.pageNumber = this.$stateParams.page || 1;
      this.onFirstLoad(tableViewExcludedColumns);
    } else if (isExcludeColumn) {
      const { tableViewExcludedColumns } = data;
      this.toggleExcludeColumns(tableViewExcludedColumns);
    } else if (isRefreshLabels) {
      const { updatedLabel, action } = data;
      if (action === 'update') {
        this.tableData = this.tableData.map((rowData) =>
          updateLabelOfCard(rowData, updatedLabel),
        );
      } else if (action === 'delete') {
        this.tableData = this.tableData.map((rowData) =>
          removeLabelFromCard(rowData, updatedLabel.labelId),
        );
      }
    } else if (isRemoveCard) {
      const { loanScenarioId } = data;
      const subChildEventHandler =
        this.pipelineType === PIPELINE_TYPES_STR.APPLICATION
          ? 'applicationTableHandler'
          : null;
      const subChildHandlerAction =
        PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REMOVE_CARD;
      if (!this.childComponentEventHandler[subChildEventHandler]) {
        return;
      }
      this.childComponentEventHandler[
        subChildEventHandler
      ](subChildHandlerAction, { loanScenarioId });
    }
  }
}
