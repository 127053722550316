import template from './mycrmDateRange.html';
import controller from './mycrmDateRangeCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    customClass: '<',
    strictValidation: '<',
    activeRangeIndex: '<',
    customRanges: '<',
    customIndex: '<',
    onRangeSelected: '&',
    onDetectChanges: '&',
    preselectModel: '<',
    onUpdate: '&',
    onCancel: '&',
  },
};
