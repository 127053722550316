import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    .controller('LoanAppDepositCtrl', function LoanAppDepositCtrl(
      $scope,
      $timeout,
      $stateParams,
      $uibModal,
      loanApplicationServices,
      SweetAlert,
      currentUserService,
    ) {
      $scope.currentUserService = currentUserService;
      // extend models
      angular.extend($scope, {
        loanAppId: $stateParams.loanAppId,
        DepositDetails: {
          LoanApplicationId: $scope.loanAppId,
          LoanApplication_DepositId: 0,
          EligibleForAFHOG: false,
        },
        DepositTypeList: [],
        getTotalSavings: 0,
      });

      // extend methods
      angular.extend($scope, {
        /**
         * Validates deposits section
         */
        validateDeposits() {
          $scope.isValidDeposits =
            _.size($scope.DepositDetails.DepositBreakDown) > 0;
          $scope.getDepositTotalSavings();
        },
        DepositGet() {
          loanApplicationServices
            .getLoanDeposit($scope.loanAppId)
            .then((response) => {
              angular.extend($scope.DepositDetails, response.data);
            });
        },
        DepositSet() {
          loanApplicationServices
            .setLoanDeposit({
              LoanApplicationId: $scope.loanAppId,
              LoanApplication_DepositId:
                $scope.DepositDetails.LoanApplication_DepositId,
              EligibleForAFHOG: $scope.DepositDetails.EligibleForAFHOG,
            })
            .then(() => {
              $scope.DepositGet();
            });
        },
        DepositBreakdownGet() {
          loanApplicationServices
            .getLoanDepositBreakDown(
              $scope.loanAppId,
              1 /* todo 2nd param should be null or not there */,
            )
            .then((response) => {
              $scope.DepositBreakdown = response.data;
            });
        },
        DepositBreakdownDelete(depositBreakDownId) {
          SweetAlert.swal(
            {
              title: 'Are you sure?',
              text: 'This record will be removed from your deposit list',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#F68F8F',
              confirmButtonText: 'Yes, remove it!',
              closeOnConfirm: false,
            },
            (confirm) => {
              if (confirm) {
                loanApplicationServices
                  .deleteLoanDepositBreakDown(
                    $scope.loanAppId,
                    depositBreakDownId,
                  )
                  .then(() => {
                    $timeout(() => {
                      $scope.DepositGet();
                      SweetAlert.swal({
                        title: 'Success',
                        text: 'Deposit has been been successfully deleted.',
                        type: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                      });
                    }, 100);
                  });
              }
            },
          );
        },
        DepositInit() {
          // todo, uncomment this if api is ready
          // $scope.DepositBreakdownGet();
          loanApplicationServices.getDepositType().then((response) => {
            $scope.DepositTypeList = response.data;
          });

          $scope.DepositGet();
        },
        getDepositTotalSavings() {
          $scope.getTotalSavings = _.sum(
            _.map($scope.DepositDetails.DepositBreakDown, 'TotalAmount'),
          );
        },
        showAddDepositModal(type, deposit) {
          $uibModal.open({
            templateUrl:
              '/assets/views/loanApplication/clientAssess/contributions/modal/add.html',
            controller: 'AddDepositModalCtrl',
            size: 'sm',
            scope: $scope,
            resolve: {
              modalType() {
                return type;
              },
              deposit() {
                return type === 'edit' ? deposit : {};
              },
            },
          });
        },
        selectDeposit(deposit) {
          $scope.showAddDepositModal('edit', deposit);
        },
      });

      // caller
      $scope.DepositInit();
      /**
       *  Validates every update on object
       */
      $scope.$watch(
        'DepositDetails',
        () => {
          $scope.validateDeposits();
        },
        true,
      );
    });
