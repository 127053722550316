import angular from 'angular';

export const loadModule = () =>
  angular.module('app').factory('regionalizationModel', [
    'httpRequest',
    'httpCacheService',
    'configService',
    function (httpRequest, httpCacheService, configService) {
      var apiBaseUrl = '/';

      function regionalizationModel() {}

      regionalizationModel.prototype = {
        GetBrokerRegionalization(brokerId, callback, errorCallback) {
          const httpService = configService.feature.loanAppCaching
            ? httpCacheService
            : httpRequest;
          httpService.get(
            `${apiBaseUrl}BrokerRegionalizationGet?brokerID=${brokerId}`,
            (response) => {
              return callback(response);
            },
            (e) => {
              return errorCallback(e);
            },
          );
        },

        MembershipRolesGet(callback) {
          httpRequest.get(`${apiBaseUrl}MembershipRolesGet`, (response) => {
            return callback(response);
          });
        },

        AddressGet(addressID, callback) {
          httpRequest.get(
            `${apiBaseUrl}AddressGet?addressID=${addressID}`,
            (response) => {
              return callback(response);
            },
          );
        },
      };

      return regionalizationModel;
    },
  ]);
