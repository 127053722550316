import template from './noteTypeForm.html';
import controller from './noteTypeFormCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    currentNoteType: '<',
    onSave: '&',
    onClose: '&',
  },
};
