import moment from 'moment';
import { pluralize } from 'Common/utilities/string';

export const sortReleaseByDate = (a, b) => {
  return (
    moment(b.dateReleased).format('YYYYMMDD') -
    moment(a.dateReleased).format('YYYYMMDD')
  );
};

export const getHeading = (releaseTypeCounts) => {
  const {
    Enhancements: enhancements,
    Fixes: fixes,
    Maintenance: maintenance,
    TechDebt: techDebt,
  } = releaseTypeCounts;

  let enhancementsLocal = enhancements || '';
  let fixesLocal = fixes || '';
  let maintenanceLocal = maintenance || '';
  let techDebtLocal = techDebt || '';

  if (enhancementsLocal) {
    enhancementsLocal = `${pluralize(enhancementsLocal, 'Enhancement', 's')}, `;
  }
  if (fixesLocal) {
    fixesLocal = `${pluralize(fixesLocal, 'Fix', 'es')}, `;
  }
  if (maintenanceLocal) {
    maintenanceLocal = `${enhancementsLocal} Maintenance, `;
  }
  if (techDebtLocal) {
    techDebtLocal = `${techDebtLocal} Tech Debt, `;
  }

  return `${enhancementsLocal} ${fixesLocal} ${maintenanceLocal} ${techDebtLocal}`.slice(
    0,
    -4,
  );
};
