import { queryStringifyId } from 'Common/utilities/query';

export default class CustomerCareExportButtons {
  constructor(customerCareService, downloadDataService) {
    'ngInject';

    this.customerCareService = customerCareService;
    this.downloadDataService = downloadDataService;
  }

  $onInit() {
    this.exportIdString = this.exportIdString || 'loanStructureIds';
  }

  exportCustomerCare(isAllRecordSelected = false) {
    const requiredParams = this.requiredParams.get();
    const isCompleted = false;

    const {
      selectedRowIds,
      adviserId: searchedAdvisorID,
      ...otherParams
    } = requiredParams;
    const param = {
      ...otherParams,
      pageNumber: 1,
      isCompleted,
      searchedAdvisorID,
      isAllRecordSelected,
    };
    const loanStructureIds = isAllRecordSelected
      ? ''
      : queryStringifyId(this.exportIdString, selectedRowIds);
    this.customerCareService[this.exportService](param, loanStructureIds).then(
      (response) => {
        const { contentType, documentContent, name } = response;
        this.downloadDataService.download(
          `data:${contentType};base64,${documentContent}`,
          `${name}`,
          contentType,
        );
      },
    );
  }
}
