export const INSURANCE_APPLICATION_STATUS = {
  FROM_STATUS_ID: 1,
  TO_STATUS_ID: 2,
};

export const WORKBENCH_DATES = {
  FORMAT: 'dd MMM yyyy',
  ALL_FORMAT: ['d!/M!/yyyy', 'd!M!yyyy'],
  DATE_OPTIONS: {
    formatYear: 'yy',
    showWeeks: false,
  },
};

export const STATUSES = {
  IN_FORCE: 1,
  V1_IN_FORCE: 6,
};

export const DEFERRED_STATUS_ID = {
  V3: 22,
  V1: 5,
};

export const NOT_PROCEEDED_ID = {
  V3: 7,
  V1: 3,
};

export const PROVIDER_ID = {
  AIA: 1,
};

export const YEARLY_ID = 1;
export const HALF_YEARLY_ID = 2;
export const QUARTERLY_ID = 4;
export const MONTHLY_ID = 12;
export const FORTNIGHTLY_ID = 26;
export const WEEKLY_ID = 52;

export const OTHER = {
  INSURER: 100,
  PRODUCT: 1,
};
