import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('questionField', function questionField() {
    return {
      scope: { fieldObj: '=', saveanswer: '&' },
      templateUrl:
        'assets/views/insuranceTools/insApp/templates/question-field.html',
    };
  });
