import template from './widgetGeneralFooter.html';
import controller from './widgetGeneralFooterCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    selectedTitle: '<',
    onPreviousClick: '&',
    onNextClick: '&',
  },
};
