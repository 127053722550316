import { COUNTRY_TYPE, COUNTRY_SHORTNAME } from 'Common/constants/countryType';

export const getCountryProperty = (countryId) => {
  switch (countryId) {
    case COUNTRY_TYPE.NEW_ZEALAND:
      return COUNTRY_SHORTNAME.NEW_ZEALAND;
    case COUNTRY_TYPE.AUSTRALIA:
      return COUNTRY_SHORTNAME.AUSTRALIA;
    case COUNTRY_TYPE.INDONESIA:
      return COUNTRY_SHORTNAME.INDONESIA;
    default:
      return COUNTRY_SHORTNAME.DEFAULT;
  }
};

export const getCountrySpecificValue = (constantObj, countryId) => {
  if (!constantObj) {
    return;
  }
  const countryProp = getCountryProperty(countryId);
  return constantObj[countryProp] || constantObj[COUNTRY_SHORTNAME.DEFAULT];
};
