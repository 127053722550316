import { goToOpportunity } from 'Common/utilities/loanOpportunity';
import { CONFIG_EMAIL } from 'Common/config/email';
import { FACT_FIND_TYPE } from 'Common/constants/factFind';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { LOAN_LENDING_CATEGORY } from 'Common/constants/lenderConstants';

import {
  showFactFindLendingBrandWarning,
  showFactFindLendingSection,
} from 'Common/utilities/factFind';

class LendingOpportunityCtrl {
  constructor(
    opportunityNewModalService,
    clientInfoService,
    contactService,
    $uibModal,
    $state,
    uiService,
    configService,
    currentUserService,
    leadOpportunitiesService,
  ) {
    'ngInject';

    this.opportunityNewModalService = opportunityNewModalService;
    this.leadOpportunitiesService = leadOpportunitiesService;
    this.clientInfoService = clientInfoService;
    this.contactService = contactService;
    this.$uibModal = $uibModal;
    this.$state = $state;
    this.uiService = uiService;
    this.configService = configService;
    this.currentUserService = currentUserService;
    this.FACT_FIND_TYPE = FACT_FIND_TYPE;
    this.LENDING_CATEGORY = LENDING_CATEGORY;
  }

  $onInit() {
    this.LOAN_LENDING_CATEGORY = LOAN_LENDING_CATEGORY;

    this.crmCurrency = this.uiService.getCrmCurrency();
    this.supportEmail = this.currentUserService.isAU
      ? CONFIG_EMAIL.MARKETING_AU
      : CONFIG_EMAIL.ADVISER_SERVICES_NZ;

    this.familyId &&
      this.contactService.clientInformGet(this.familyId).then((res) => {
        const { data } = res;
        if (!data) {
          return;
        }

        this.clients =
          data.filter((obj) => obj.Role === 'Adult' || obj.isEntity) || [];

        const isContactOpener = 1;
        const tabName = 'details';

        const clients = { information: this.clients };
        const { headerName } = this.clientInfoService.getClientHeader(clients);

        const sourceFamilyId = this.familyId;
        const sourceFamilyName = headerName || '';

        const { isBusiness } = this;

        this.redirectParams = {
          sourceFamilyId,
          sourceFamilyName,
          isContactOpener,
          isBusiness,
          tabName,
        };
      });

    this.showBrandWarning = showFactFindLendingBrandWarning;
    this.showFactFindSection = showFactFindLendingSection;
  }

  $onChanges(changes) {
    const { isShowPrevious } = changes;
    if (isShowPrevious) {
      const { currentValue } = isShowPrevious;
      this.opportunityLoansSeeAll = currentValue;
    }
  }

  openNewOpportunityModal() {
    this.opportunityNewModalService.openNewOpportunityModal(
      this.familyId,
      this.clients,
      this.redirectParams,
    );
  }

  redirectToOpportunity(opportunityId, loanId) {
    goToOpportunity(this.$state, {
      opportunityId,
      loanId,
      ...this.redirectParams,
    });
  }

  seeAllOpportunity() {
    this.opportunityLoansSeeAll = true;

    this.onSeeAllOpportunity();
  }

  countPreviousOpportunity() {
    return this.opportunityLoans.filter((loan) => loan.IsPrevious).length;
  }

  isActiveLoansNotEnough() {
    return this.opportunityLoans.filter((loan) => !loan.IsPrevious).length < 3;
  }
}

export default LendingOpportunityCtrl;
