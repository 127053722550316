import template from './onboardingQuestionnaire.html';
import controller from './onboardingQuestionnaireCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    questionnaireField: '<',
  },
};
