import swal from 'sweetalert';
import toastr from 'toastr';
import { INSURANCE_PACK_TYPE } from 'Common/constants/helloBook';
import { insuranceClientSearchResultForUI } from 'Common/mappers/insurancePipeline';
import { INSURANCE_APPLICATION_STATUS } from 'Common/constants/insuranceApplicationPipeline';
import { DEFAULT_CHOOSE_QUOTE_STATE } from 'Common/default/pipeline';
import { sendInsuranceApplicationPack } from 'Common/utilities/insurance';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';

export default class InsuranceApplicationsPipelineCtrl {
  constructor(
    $state,
    $window,
    $timeout,
    insurancePipelineService,
    helloBookPreviewService,
    dashboardService,
    configService,
    currentUserService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$window = $window;
    this.$timeout = $timeout;
    this.insurancePipelineService = insurancePipelineService;
    this.helloBookPreviewService = helloBookPreviewService;
    this.dashboardService = dashboardService;
    this.configService = configService;
    this.searchResult = [];
    this.quoteList = [];
    this.providerList = [];
    this.quoteInput = {};
    this.chooseQuote = DEFAULT_CHOOSE_QUOTE_STATE;
    this.saving = false;
    this.toaster = toastr;
    this.radio = {
      isPolicyHolderSignatory: true,
      allowSendApplication: true,
    };
    this.currentUserService = currentUserService;
    this.isLoadingQuote = false;
  }

  $onInit() {
    const { feature } = this.configService;
    this.feature = feature;
    this.onExistingClient();
    const { isOnlineInsAppReady, brandingCategoryID } = this.currentUserService;
    this.isOnlineInsAppReady =
      !isOnlineInsAppReady &&
      brandingCategoryID === BRANDING_CATEGORY_TYPE.OWN_BRAND;
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  closeSearchResult() {
    this.searchResult = [];
  }

  onChangeQuotes() {
    this.quoteInput.hasChange = true;
    this.selectInsuranceProviders();
  }

  onExistingClient() {
    if (this.modalData.quoteId === 0) {
      this.fromContactModule();
      return;
    }

    if (!this.modalData.typeNew) {
      this.quoteInput.familyId = this.modalData.familyId;
      this.selectQuote();
      this.setDefaultName();
    }
  }

  fromContactModule() {
    if (this.modalData.typeNew) {
      this.quoteInput.familyId = this.modalData.familyId;
      this.quoteInput.fullName = this.modalData.fullName;
      this.selectQuote();
    }
  }

  searchFamilyInput() {
    const params = this.quoteInput.fullName;
    this.emptySearch();
    if (params.length > 2) {
      this.dashboardService.getGenralSearchResult(params).then((response) => {
        if (response && response.data.length) {
          const filterSearch = response.data.filter(
            (item) => item.MatchResultType === 'Name',
          );
          this.searchResult = filterSearch[0].Result.map(
            insuranceClientSearchResultForUI,
          );
        }
      });
    }
  }

  selecteSearch(selectedSearch) {
    this.closeSearchResult();
    this.quoteInput.fullName = selectedSearch.familyFullName;
    this.quoteInput.familyId = selectedSearch.familyID;
    this.selectQuote();
  }

  selectQuote() {
    this.isLoadingQuote = true;
    if (!this.quoteInput.familyId) {
      return;
    }
    const params = {
      familyId: this.quoteInput.familyId,
      quoteId: this.modalData.quoteId,
    };
    this.insurancePipelineService.insuranceQuoteGet(params).then((data) => {
      this.isLoadingQuote = false;
      this.refreshSearch();
      const filterQuote = data.filter(
        (item) => item.pipelineStatusId === 1 && item.pipelineStatusId,
      );
      this.quoteList = filterQuote;
      this.setDefaultQuote();
      this.ifEmptyQuote(filterQuote);
    });
  }

  ifEmptyQuote(filterQuote) {
    if (!filterQuote.length) {
      this.quoteList = [
        {
          quoteId: 0,
          dateCreated: 'No quotes available',
        },
      ];
      this.quoteInput.clientQuoteId = this.quoteList[0].quoteId;
      this.selectInputStatus('notDisabled');
    }
  }

  selectInsuranceProviders() {
    const quoteIds = this.quoteInput.clientQuoteId;
    if (!quoteIds) {
      return;
    }
    this.insurancePipelineService
      .getInsuranceQuoteProviders(quoteIds)
      .then((data) => {
        this.providerList = data;
        this.setDefaultProviders();
      });
  }

  refreshSearch() {
    this.quoteList = [];
    this.providerList = [];
    this.selectInputStatus('isDisabled');
  }

  setDefaultQuote() {
    if (this.quoteList.length) {
      this.selectInputStatus('notDisabled');
      this.quoteInput.clientQuoteId = this.quoteList[0].quoteId;
      this.selectInsuranceProviders();
      this.setDefaultName();
    }
  }

  setDefaultName() {
    if (!this.modalData.typeNew) {
      this.selectInputStatus('notDisabled');
      this.quoteInput.fullName = this.modalData.fullName;
      return;
    }
    this.quoteInput.fullName = this.quoteList[0].familyFullName;
  }

  setDefaultProviders() {
    if (this.providerList.length) {
      this.favoriteInsurer = JSON.parse(
        this.$window.localStorage.getItem('favoriteInsurer'),
      );
      this.quoteInput.chosenProviderId =
        (this.favoriteInsurer && this.favoriteInsurer.ProviderId) ||
        this.providerList[0].id;
    }
  }

  emptySearch() {
    if (!this.quoteInput.fullName) {
      this.searchResult = [];
      this.quoteList = [];
      this.providerList = [];
      this.selectInputStatus('isDisabled');
    }
  }

  selectInputStatus(state) {
    const inputState = {
      isDisabled: true,
      notDisabled: false,
    };
    this.chooseQuote = inputState[state];
  }

  newQuote() {
    if (!this.quoteInput.familyId) {
      this.$state.go('app.quickQuote');
      this.modalInstance.dismiss();
      return;
    }
    const familyId = this.quoteInput.familyId;
    const isFromInsuranceTab = false;
    this.$state.go('app.contactQuickQuote', {
      familyId,
      isFromInsuranceTab,
    });
    this.modalInstance.dismiss();
  }

  isNotSetAdviserOrg() {
    const isYes = typeof this.radio.allowSendApplication === 'boolean';
    return !this.currentUserService.tradingName && isYes;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  nextStep() {
    if (this.isNotSetAdviserOrg()) {
      this.toaster.info(
        'Unable to send as Trading Name has not been set-up for your Adviser Org. Please report this to Adviser Services and they can update accordingly',
        'info',
      );
      return;
    }
    if (this.quoteInput.clientQuoteId === 0) {
      this.toaster.info('No Quotes available for selected client.', 'info');
      return;
    }
    if (!this.quoteInput.chosenProviderId) {
      this.toaster.info('No Provider was selected.', 'info');
      return;
    }
    this.saving = true;

    const params = {
      ...this.quoteInput,
      fromStatusId: INSURANCE_APPLICATION_STATUS.FROM_STATUS_ID,
      toStatusId: INSURANCE_APPLICATION_STATUS.TO_STATUS_ID,
    };
    this.insurancePipelineService
      .saveInsuranceApplication(this.polishPayLoad(params))
      .then((data) => {
        if (data.succeeded) {
          const isSendPackApplicationEnabled =
            this.feature.onlineInsuranceApplication &&
            this.radio.allowSendApplication !== 'false' &&
            !this.isOnlineInsAppReady;
          if (isSendPackApplicationEnabled) {
            this.sendHelloPackInsuranceApplication();
            this.modalInstance.close();
            return;
          }
          swal(this.successSave(), (confirm) => {
            if (!confirm) {
              return;
            }
            this.modalInstance.close();
            if (!this.modalData.typeNew || this.modalData.quoteId === 0) {
              this.$state.go('app.insuranceApplicationsPipeline');
            }
          });
        }
      });
  }

  polishPayLoad(data) {
    this.mapForms = {
      chosenProviderID: data.chosenProviderId,
      quoteId: data.clientQuoteId,
      fromStatusId: data.fromStatusId,
      toStatusId: data.toStatusId,
      ...this.radio,
    };
    this.mapForms.allowSendApplication = !this.isOnlineInsAppReady;
    return this.mapForms;
  }

  successSave() {
    this.swal = {
      title: 'Success.',
      text: 'Insurance application successfully created.',
      type: 'success',
      showCancelButton: false,
      confirmButtonColor: '#5b5084',
      confirmButtonText: 'OK',
      closeOnConfirm: true,
    };
    return this.swal;
  }

  resetSearch() {
    this.quoteInput.fullName = '';
    this.emptySearch();
  }

  sendHelloPackInsuranceApplication() {
    const provider =
      this.getProviderDetails(this.quoteInput.chosenProviderId) || {};

    const data = {
      params: {
        jointContact: true,
        familyId: this.quoteInput.familyId || this.modalData.familyId,
        quoteId: this.quoteInput.clientQuoteId,
        provider: {
          id: provider.id,
          name: provider.providerName,
        },
        packType: INSURANCE_PACK_TYPE.APPLICATION,
      },
      helloBookPreviewService: this.helloBookPreviewService,
    };

    const response = sendInsuranceApplicationPack(data);
    if (response) {
      response.then(() => {
        this.modalInstance.close();

        if (this.$state.current.name === 'app.contactsSingle') {
          return;
        }

        if (!this.modalData.typeNew || this.modalData.quoteId === 0) {
          this.$state.go('app.insuranceApplicationsPipeline');
        }
      });
    }
  }

  getProviderDetails(chosenProviderId) {
    return this.providerList.find(
      (provider) => provider.id === chosenProviderId,
    );
  }
}
