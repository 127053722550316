export function checkIfHideInstitution(description) {
  const hideOnTypes = ['Boat'];
  return hideOnTypes.includes(description);
}

export const mapRepaymentFrequencyId = (mortgageLiability) => {
  return (
    mortgageLiability &&
    mortgageLiability.length &&
    mortgageLiability.map((mortgage) => {
      mortgage.FrequencyID = mortgage.RepaymentFrequencyId;
      return mortgage;
    })
  );
};

export const initSelectedOwnersUtil = ({ ownersList, selectedFamilyId }) => {
  if (!ownersList) {
    return;
  }
  if (!selectedFamilyId) {
    return ownersList;
  }

  return ownersList.map((owner) => {
    return {
      ...owner,
      ticked: owner.FamilyId === selectedFamilyId,
    };
  });
};
