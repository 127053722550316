import { APPLICATION_LOADING_TIME } from 'Components/opportunity/util/constants';

export default class OpportunityFactFindCtrl {
  constructor(
    $state,
    $timeout,
    loanScenarioService,
    opportunityLoanAppService,
    blueStoneSubmissionService,
    $scope,
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.loanScenarioService = loanScenarioService;
    this.opportunityLoanAppService = opportunityLoanAppService;
    this.blueStoneSubmissionService = blueStoneSubmissionService;
    this.$scope = $scope;
    this.loadingTimeout = null;
  }

  $onInit() {
    this.opportunityId = this.$state.params.opportunityId;
    this.familyId = this.$state.params.sourceFamilyId;
    this.isLoanAppFactFindLoading = true;
    this.loadingText = `Loading ${this.tabTitle}`;

    const reloadScenarioDetails = this.getScenarioDetails.bind(this);
    this.opportunityLoanAppService.setCallback(
      'refreshScenarioDetails',
      reloadScenarioDetails,
    );

    this.loadingTimeout = this.$timeout(() => {
      this.isLoanAppFactFindLoading = false;
    }, APPLICATION_LOADING_TIME);
    this.getScenarioDetails();

    if (this.blueStoneSubmissionService.apiData) {
      this.blueStoneSubmissionService.apiData.errorCount = null;
    }
  }

  getScenarioDetails() {
    this.loanScenarioService
      .scenarioDetailsGet(this.opportunityId)
      .then(({ data }) => {
        this.scenarioDetails = data;
      });
  }

  $onDestroy() {
    this.loadingTimeout && this.$timeout.cancel(this.loadingTimeout);
  }
}
