import angular from 'angular';
import moment from 'moment';
import { toastError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    .controller('OpenModalCtrl', function OpenModalCtrl(
      $scope,
      $uibModalInstance,
      contactService,
      $timeout,
      toaster,
      tasksSharedData,
      tasksService,
      dashboardService,
      tasksTableData,
      allTasksTableData,
      task,
      $window,
      getTasks,
    ) {
      $scope.selectedTask = {};
      contactService.taskAdviserListGet().then((respond) => {
        $scope.taskAdviserList = respond.data;
      });
      dashboardService.getBrokerBasicInfo().then((response) => {
        $scope.brokerId = response.data.BrokerId;
        $scope.selectedTask.BrokerID = parseInt($scope.brokerId, 10);
      });
      $scope.addNotes = function () {
        $scope.showNotes = true;
      };
      $scope.tasksTableData = tasksTableData;
      $scope.allTasksTableData = allTasksTableData;
      let defaultDate;
      for (let i = 0; i < $scope.allTasksTableData.length; i++) {
        if ($scope.allTasksTableData[i].ActivityID === task.ActivityID) {
          $scope.selectedTask.taskTitle =
            $scope.allTasksTableData[i].ActivityDetails;
          if ($scope.allTasksTableData[i].ActivityNote !== '') {
            $scope.showNotes = true;
            $scope.selectedTask.Notes =
              $scope.allTasksTableData[i].ActivityNote;
          }
          $scope.selectedTask.FamilyID = $scope.allTasksTableData[i].FamilyID;
          $scope.selectedTask.TaskType =
            $scope.allTasksTableData[i].ActivityType;
          $scope.selectedTask.TaskCategory =
            $scope.allTasksTableData[i].ActivityCategory;
          $scope.selectedTask.TaskID = $scope.allTasksTableData[i].ActivityID;
          const date = $scope.allTasksTableData[i].DateCompeleted;
          defaultDate = $scope.allTasksTableData[i].DateCompeleted;
          $scope.selectedTask.DueDate = moment(date).format('D MMM YYYY h:mma');
        }
      }
      $scope.taskSet = function (taskObj) {
        $scope.taskObj = {};
        $scope.taskObj.FamilyID = taskObj.FamilyID;
        $scope.taskObj.Title = taskObj.taskTitle;
        $scope.taskObj.assignedClientID = taskObj.BrokerID;
        $scope.taskObj.DueDate = taskObj.DueDate;
        $scope.taskObj.TaskType = taskObj.TaskType;
        $scope.taskObj.TaskCategory = taskObj.TaskCategory;
        $scope.taskObj.Notes = taskObj.Notes;
        $scope.taskObj.TaskID = taskObj.TaskID;
        tasksService
          .taskSet($scope.taskObj)
          .then(() => {
            $timeout(() => {
              $uibModalInstance.dismiss('cancel');
            }, 100);
            toaster.pop('success', 'Updated', 'Task successfully updated');
            getTasks($scope.brokerId);
          })
          .catch(toastError);
      };
      $timeout(() => {
        angular.element('#due-date').datetimepicker({
          inline: true,
          sideBySide: true,
          debug: false,
          defaultDate,
        });
        $scope.duedateview = 'date';
        angular.element($window.document).on('dp.change', '#due-date', (e) => {
          $scope.selectedTask.DueDate = moment(e.date._d).format(
            'D MMM YYYY h:mma',
          );
        });
      });
      $scope.$watch('duedateview', (newVal) => {
        if (newVal === 'date') {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          angular.element('.datepicker').show();
          // eslint-disable-next-line sonarjs/no-duplicate-string
          angular.element('.timepicker').hide();
          angular.element('.modal-actions').clone().appendTo('.datepicker');
        } else {
          angular.element('.timepicker').show();
          angular.element('.datepicker').hide();
          angular.element('.modal-actions').clone().appendTo('.timepicker');
        }
      });
      $scope.closeModal = function () {
        $uibModalInstance.dismiss('cancel');
      };
    });
