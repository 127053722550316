export default class ManualReferralService {
  constructor($q, httpClient, httpCacheService) {
    'ngInject';

    this.$q = $q;
    this.httpClient = httpClient;
    this.httpCacheService = httpCacheService;
    this.apiBaseUrl = 'manual-referral';
    this.defaultOptions = [null, false, false];
  }

  createManualReferral(referral) {
    return this.httpClient.post(`${this.apiBaseUrl}/create`, referral);
  }

  createManualReferralCommission(referralCommission) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/create-commission`,
      referralCommission,
    );
  }

  updateManualReferral(referral) {
    return this.httpClient.patch(`${this.apiBaseUrl}/update`, referral);
  }

  updateManualReferralCommission(referralCommission) {
    return this.httpClient.patch(
      `${this.apiBaseUrl}/update-commission`,
      referralCommission,
    );
  }

  getManualReferralCommission(loanApplicationId, familyId, loanId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/get-commission?loanApplicationId=${loanApplicationId}&familyId=${familyId}&loanId=${loanId}`,
      ...this.defaultOptions,
    );
  }

  getManualReferral(loanId, familyId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/get?loanId=${loanId}&familyId=${familyId}`,
      ...this.defaultOptions,
    );
  }

  getReferralSources() {
    // TODO: this will need to be cached at some point
    return this.httpClient.get(
      `${this.apiBaseUrl}/get-referral-sources`,
      ...this.defaultOptions,
    );
  }

  deleteManualReferral(loanId, familyId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/delete?loanId=${loanId}&familyId=${familyId}`,
      ...this.defaultOptions,
    );
  }
}
