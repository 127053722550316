// Custom UI Bootstrap Calendar Popup Template
function configBootstrapCalendar($templateCache) {
  $templateCache.put(
    'uib/template/datepicker/popup.html',
    `<div>
      <ul class="uib-datepicker-popup clip-datepicker dropdown-menu" dropdown-nested ng-if="isOpen" ng-style="{top: position.top+'px', left: position.left+'px'}" ng-keydown="keydown($event)" ng-click="$event.stopPropagation()">
        <li ng-transclude></li>
        <li ng-if="showButtonBar" class="uib-button-bar">
        <span class="btn-group pull-left">
          <button type="button" class="btn btn-sm btn-primary btn-o uib-datepicker-current" ng-click="select('today')" ng-disabled="isDisabled('today')">{{ getText('current') }}</button>
          <button type="button" class="btn btn-sm btn-primary btn-o uib-clear" ng-click="select(null)">{{ getText('clear') }}</button>
        </span>
          <button type="button" class="btn btn-sm btn-primary pull-right uib-close" ng-click="close()">{{ getText('close') }}</button>
        </li>
      </ul>
    </div>`,
  );

  $templateCache.put(
    'uib/template/datepicker/year.html',
    `<table class="uib-yearpicker" role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}">
      <thead>
        <tr>
          <th><button type="button" class="btn btn-default btn-sm pull-left uib-left" ng-click="move(-1)" tabindex="-1"><i class="glyphicon glyphicon-chevron-left"></i></button></th>
          <th colspan="{{::columns - 2}}"><button id="{{::uniqueId}}-title" role="heading" aria-live="assertive" aria-atomic="true" type="button" class="btn btn-default btn-sm uib-title" ng-click="toggleMode()" ng-disabled="datepickerMode === maxMode" tabindex="-1"><strong>{{title}}</strong></button></th>
          <th><button type="button" class="btn btn-default btn-sm pull-right uib-right" ng-click="move(1)" tabindex="-1"><i class="glyphicon glyphicon-chevron-right"></i></button></th>
        </tr>
      </thead>
      <tbody>
        <tr class="uib-years" ng-repeat="row in rows track by $index">
          <td ng-repeat="dt in row" class="uib-year text-center" role="gridcell"
            id="{{::dt.uid}}"
            ng-class="::dt.customClass">
            <button type="button" class="btn btn-default"
              uib-is-class="
                'btn-current' for selectedDt,
                'active' for activeDt
                on dt"
              ng-click="select(dt.date)"
              ng-disabled="::dt.disabled"
              tabindex="-1"><span ng-class="::{'text-info': dt.current}">{{::dt.label}}</span></button>
          </td>
        </tr>
      </tbody>
    </table>`,
  );

  $templateCache.put(
    'uib/template/datepicker/month.html',
    `<table class="uib-monthpicker" role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}">
      <thead>
        <tr>
          <th><button type="button" class="btn btn-default btn-sm pull-left uib-left" ng-click="move(-1)" tabindex="-1"><i class="glyphicon glyphicon-chevron-left"></i></button></th>
          <th><button id="{{::uniqueId}}-title" role="heading" aria-live="assertive" aria-atomic="true" type="button" class="btn btn-default btn-sm uib-title" ng-click="toggleMode()" ng-disabled="datepickerMode === maxMode" tabindex="-1"><strong>{{title}}</strong></button></th>
          <th><button type="button" class="btn btn-default btn-sm pull-right uib-right" ng-click="move(1)" tabindex="-1"><i class="glyphicon glyphicon-chevron-right"></i></button></th>
        </tr>
      </thead>
      <tbody>
        <tr class="uib-months" ng-repeat="row in rows track by $index">
          <td ng-repeat="dt in row" class="uib-month text-center" role="gridcell"
            id="{{::dt.uid}}"
            ng-class="::dt.customClass">
            <button type="button" class="btn btn-default"
              uib-is-class="
                'btn-current' for selectedDt,
                'active' for activeDt
                on dt"
              ng-click="select(dt.date)"
              ng-disabled="::dt.disabled"
              tabindex="-1"><span ng-class="::{'text-info': dt.current}">{{::dt.label}}</span></button>
          </td>
        </tr>
      </tbody>
    </table>`,
  );
}

configBootstrapCalendar.$inject = ['$templateCache'];

export default configBootstrapCalendar;
