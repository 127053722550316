import angular from 'angular';

class SendMailCtrl {
  constructor(
    $stateParams,
    $state,
    contactService,
    mailTemplateService,
    stateService,
  ) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.familyId = $state.params.familyId;

    this.contactService = contactService;
    this.mailTemplateService = mailTemplateService;
    this.stateService = stateService;
  }

  getClientInfo() {
    this.contactService
      .clientInformGet(this.familyId)
      .then((contactMailResponse) => {
        const { data } = contactMailResponse;
        if (!data) {
          return;
        }

        if (data) {
          this.clientHeaderName = this.mailTemplateService.constructClientFullName(
            data,
          );
          this.primaryClient = this.mailTemplateService.getPrimaryClientFrom(
            data,
          );
        }
      });
  }

  $onInit() {
    this.getClientInfo();
  }
}

export const loadModule = () =>
  angular.module('app').controller('SendMailCtrl', SendMailCtrl);
