export const COUNTRY_TYPE = {
  NEW_ZEALAND: 1,
  AUSTRALIA: 2,
  INDONESIA: 3,
};

export const COUNTRY_TYPE_TEXT = {
  NEW_ZEALAND: 'New Zealand',
};

export const COUNTRY_SHORTNAME = {
  NEW_ZEALAND: 'NZ',
  AUSTRALIA: 'AU',
  INDONESIA: 'IN',
  DEFAULT: 'DEFAULT',
};
