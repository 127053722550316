import template from './gamePlanEmailMessageView.html';
import controller from './gamePlanEmailMessageViewCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    message: '<',
    photoUrl: '<',
    adviserInfo: '<',
    isLoading: '<',
    adviserTheme: '<',
    editorOptions: '<',
    onUpdateEmailMessage: '&',
    isSaveDefaultEdited: '<',
    saveDefaultMessage: '&',
    isSaving: '<',
    isOnSaveDefaultEdited: '<',
    isSettingsOnDefault: '<',
    resetOnDefault: '&',
    isReverting: '<',
    saveButtonText: '<',
  },
};
