import { typeIntBuilderForUI } from 'Common/mappers/sharedMappers';

class LoanStructureService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.baseUrl = 'loan-structure';
  }

  getLoanTermMonthly() {
    return this.httpClient
      .get(`${this.baseUrl}/loan-term-monthly`)
      .then(({ data }) => data.map(typeIntBuilderForUI));
  }
}

export default LoanStructureService;
