import template from './commissionManagement.html';
import controller from './commissionManagementCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isMobileDevice: '<',
  },
};
