import { VALID_EXTENSIONS } from 'Common/constants/document';
import { toastSaveDelete, toastError } from 'Common/utilities/alert';

class OnboardingRelatedDocumentCtrl {
  constructor(
    corporateModelService,
    onboardingService,
    crmConfirmation,
    generalService,
    downloadDataService,
    onboardingDataService,
  ) {
    'ngInject';

    this.corporateModelService = corporateModelService;
    this.onboardingService = onboardingService;
    this.crmConfirmation = crmConfirmation;
    this.generalService = generalService;
    this.downloadDataService = downloadDataService;
    this.onboardingDataService = onboardingDataService;
  }

  uploadDocumentSuccess(files, source, category) {
    if (!category) {
      return;
    }
    this.uploadedFiles = [];
    source &&
      source.map((item) => {
        if (item.checklistHeaderId !== category.checklistHeaderId) {
          return item;
        }
        const isMultipleCategory =
          category.checklistHeaderDropdown &&
          category.checklistHeaderDropdown.length > 1;
        const defaultCategory =
          (category.checklistHeaderDropdown &&
            category.checklistHeaderDropdown.length &&
            category.checklistHeaderDropdown[0].recruitmentCheckListId) ||
          category.checklistHeaderId;
        files &&
          files.map((file) => {
            const fileObj = {
              recruitmentCheckListId: 0,
              checklistName: '',
              category: '',
              documentId: file.DocumentId,
              filename: file.Name,
              categoryId: isMultipleCategory ? 0 : defaultCategory,
              isNewUpload: isMultipleCategory,
            };
            this.uploadedFiles = [...this.uploadedFiles, fileObj];
            if (!isMultipleCategory) {
              this.finishUpload(fileObj, category.checklistHeaderDropdown);
            }
            return file;
          });
        item.checklistHeaderDocument = [
          ...item.checklistHeaderDocument,
          ...this.uploadedFiles,
        ];
        return item;
      });
    return source;
  }

  finishUpload(doc, checklistHeaderDropdown) {
    if (!doc) {
      return;
    }
    const { categoryId, documentId } = doc;
    const headerDropdown =
      (checklistHeaderDropdown &&
        checklistHeaderDropdown.find(
          (header) => header.recruitmentCheckListId === categoryId,
        )) ||
      {};
    const category = headerDropdown.checklistName || '';
    const params = {
      familyId: this.familyId,
      recruitmentChecklistId: categoryId,
      documentId,
      sendNotification:
        this.onboardingDataService.isOnboardingSubmitted || false,
      category,
    };
    this.corporateModelService.setApplicationChecklist(params).then((data) => {
      doc.filename = data || doc.filename;
      doc.isNewUpload = false;
    });
  }

  promptDeleteDocument(doc) {
    const confirmationInstance = this.crmConfirmation.open({
      type: 'warning',
      title: 'Are you sure you want to delete this document?',
      description: `This action is final and <b>CANNOT</b> be undone`,
      buttonText: `Yes I am sure`,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      modalSize: 'md',
      cancelButtonClass: 'colored',
      isButtonMaxWidth: true,
    });
    confirmationInstance.result.then((res) => {
      if (!res.isOk) {
        return;
      }
      this.deleteDocument(doc);
    });
  }

  deleteDocument(doc) {
    if (!doc || this.isDeletingDocument) {
      return;
    }
    const { documentId } = doc;
    const params = {
      familyId: this.familyId,
      documentId,
    };
    this.isDeletingDocument = true;
    const errorMessages = 'Unable to delete this document.';
    this.corporateModelService
      .deleteOnlineOnboardingDocumentChecklist(params)
      .then((response) => {
        if (response) {
          this.getOnlineOnboardingApplicationChecklistHeader();
          toastSaveDelete();
        } else {
          toastError(errorMessages);
        }
        this.isDeletingDocument = false;
      })
      .catch(() => {
        toastError(errorMessages);
        this.isDeletingDocument = false;
      });
  }

  getOnlineOnboardingApplicationChecklistHeader() {
    if (!this.familyId) {
      return;
    }
    this.corporateModelService
      .getOnlineOnboardingApplicationChecklistHeader(this.familyId)
      .then((data) => {
        if (!data || !data.length) {
          return;
        }
        const applicationChecklistHeader = data.filter(
          (list) => !list.isIfApplicable,
        );
        const optionalChecklistHeader = data.filter(
          (list) => list.isIfApplicable,
        );
        this.applicationChecklistHeader = [
          applicationChecklistHeader,
          optionalChecklistHeader,
        ];
      });
  }

  downloadDocument(documentId) {
    if (!documentId) {
      return;
    }
    this.generalService
      .documentGet(documentId)
      .then(({ data }) => {
        if (!data) {
          toastError('Unable to download this document.');
          return;
        }
        this.downloadDataService.download(
          `data:${data.ContentType};base64,${data.DocumentContent}`,
          data.Name,
          data.ContentType,
        );
      })
      .catch(() => {
        toastError('Unable to download this document.');
      });
  }

  displayApplicationHeaderTitle(index, value) {
    return (
      index === value &&
      this.applicationChecklistHeader &&
      this.applicationChecklistHeader.length &&
      this.applicationChecklistHeader[value].length
    );
  }

  $onInit() {
    this.getOnlineOnboardingApplicationChecklistHeader();
    this.validFileExtensions = VALID_EXTENSIONS;
  }
}

export default OnboardingRelatedDocumentCtrl;
