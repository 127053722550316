import angular from 'angular';
import moment from 'moment';
import { TO_MYCRM_DATE_FORMAT } from 'Common/constants/dateConfigs';
import { initializePipelineStatusMove } from './util/pipelineStatusMoveConfirmation';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'PipelineStatusMoveConfirmationCtrl',
      function PipelineStatusMoveConfirmationCtrl(
        $uibModalInstance,
        configService,
        uiService,
        modalContent,
        $scope,
      ) {
        initializePipelineStatusMove({ $scope });
        $scope.isNewPipeline = uiService.newPipeline;
        $scope.locals = modalContent;
        $scope.ok = (params) => {
          if (modalContent.isNotProceeded) {
            params.notProceedingDate = moment(
              $scope.dateModel.notProceedingDate,
            ).format(TO_MYCRM_DATE_FORMAT);
          }
          $uibModalInstance.close({ isOk: true, ...params });
        };

        $scope.cancel = function (params) {
          $uibModalInstance.close({ isOk: false, ...params });
        };

        $scope.openDate = () => {
          $scope.dateModel.isOpenDate = true;
        };
        $scope.displayNotProceededDate = () => {
          return modalContent.isNotProceeded;
        };
      },
    );
