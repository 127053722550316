import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('ReferrerInfoCtrl', function ReferrerInfoCtrl(
      $scope,
      $http,
      SweetAlert,
      configService,
      $filter,
      referralsService,
      $timeout,
      $window,
      $uibModal,
      $stateParams,
      contactService,
      mapService,
      toaster,
      generalService,
    ) {
      $scope.referrerId = $stateParams.referrerId;
      $scope.agreementId = $stateParams.agreementId;

      // init Function
      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.init = function () {
        // Get Referral Agreement's Referrer Info
        $scope.getReferrerOrgReferrerInfo = function () {
          $scope.referrerOrgReferrerInfo = [];
          $scope.referrerOrgReferrerInfo.formatted_address = '';
          referralsService
            .getReferrerOrgAgreementsReferrerInfo(
              $scope.referrerId,
              $scope.agreementId,
            )
            .then((response) => {
              $scope.referrerOrgReferrerInfo = response.data;
              if (
                typeof $scope.referrerOrgReferrerInfo.AddressID !==
                  'undefined' &&
                $scope.referrerOrgReferrerInfo.AddressID !== 0
              ) {
                referralsService
                  .getAddressDetails($scope.referrerOrgReferrerInfo.AddressID)
                  .then((res) => {
                    $scope.referrerOrgReferrerInfo.formatted_address =
                      res.data.formatted_address;
                    mapService.loadAPI();
                    $scope.getMapAddress(res.data.AddressID);
                  });
              }
            });
        };
        $scope.getReferrerOrgReferrerInfo();

        $scope.getMapAddress = function (addressID) {
          if (!_.isUndefined(addressID) && Number(addressID) > 0) {
            generalService.addressDetailsGet(addressID).then((response) => {
              if (_.size(response.data) > 0) {
                const lat = _.isUndefined(response.data.latitude)
                  ? 0
                  : response.data.latitude;
                const lng = _.isUndefined(response.data.longitude)
                  ? 0
                  : response.data.longitude;
                mapService.mapDesignForReferrer(
                  lat,
                  lng,
                  response.data.street_address,
                  'referrerMap',
                );
                mapService.mapDesignForReferrer(
                  lat,
                  lng,
                  response.data.street_address,
                  'referrerMapMobile',
                );
              }
            });
          }
        };

        // Get Referral Agreement's Referrer Info
        $scope.getReferrerOrgAgreementsInfo = function () {
          $scope.referrerOrgAgreementsInfo = [];
          referralsService
            .getReferrerOrgAgreementsInfo($scope.agreementId)
            .then((response) => {
              $scope.referrerOrgAgreementsInfo = response.data;
            });
        };

        // Get Referral Agreement's Referrer Info
        $scope.getReferrerOrgWeeklyReportSettings = function () {
          $scope.referrerOrgWeeklyReportSettings = [];
          referralsService
            .getReferrerOrgAgreementsWeeklyReportSettings(
              $scope.referrerId,
              $scope.agreementId,
            )
            .then((response) => {
              $scope.referrerOrgWeeklyReportSettings = response.data;
            });
        };
      };

      $scope.init();

      $scope.selectTab = function (selectedreferralTab) {
        $timeout(() => {
          const el = $window.document.querySelectorAll(
            `#${selectedreferralTab} .nav-link`,
          );
          angular.element(el).triggerHandler('click');
        }, 0);
        $scope.selectedreferralTab = selectedreferralTab;
        if (selectedreferralTab === 'referrer_details') {
          $scope.getReferrerOrgReferrerInfo();
        }
        if (selectedreferralTab === 'agreement') {
          $scope.getReferrerOrgAgreementsInfo();
        }
        if (selectedreferralTab === 'weekly_report') {
          $scope.getReferrerOrgWeeklyReportSettings();
        }
      };

      $scope.setWeeklyReportSettings = function () {
        $scope.referrerOrgWeeklyReportSettings.ReferrerID = $scope.referrerId;
        $scope.referrerOrgWeeklyReportSettings.AgreementID = $scope.agreementId;
        referralsService
          .setReferrerOrgAgreementsWeeklyReportSettings(
            $scope.referrerOrgWeeklyReportSettings,
          )
          .then((response) => {
            if (parseInt(response.data, 10) === 1) {
              toaster.pop(
                'success',
                'Updated',
                'Weekly report settings has been successfully Updated',
              );
            }
          });
      };
    });
