const DEFAULT_NAME = 'Lender Logo';
export function getLenderLogoHtml({
  countryCode = '',
  lenderName,
  height = 'auto',
  width = '38',
  src = '',
  showDefault = true,
}) {
  const displayLogo = lenderName || src || showDefault;
  const lenderId = lenderName || 0;
  return displayLogo
    ? `<lender-logo
      country-code="'${countryCode}'"
      lender-id="'${lenderId}'"
      lender-name="'${lenderName || DEFAULT_NAME}'"
      height="'${height}'"
      width="'${width}'"
      src="'${src}'"
      is-large="true"
    ></lender-logo>`
    : '';
}

export function getProductLenderIdLenderLogoHtml({
  countryCode = '',
  lenderName,
  height = 'auto',
  width = '38',
  src = '',
  showDefault = true,
}) {
  const displayLogo = lenderName || src || showDefault;
  const lenderId = lenderName || 0;
  return displayLogo
    ? `<lender-logo
      country-code="'${countryCode}'"
      lender-id="'${lenderId}'"
      lender-name="'${lenderName || DEFAULT_NAME}'"
      height="'${height}'"
      width="'${width}'"
      force-render-src="true"
      src="'${src}'"
      is-large="true"
    ></lender-logo>`
    : '';
}
