import moment from 'moment';
import { groupBy, reduce } from 'lodash';
import { parseToInt10 } from 'Common/utilities/parse';

const MONTHLY_FREQUENCY_ID = 4;
export const getCalculationUniqueLenders = (calculation) => {
  const hasLenders =
    calculation.Lenders &&
    calculation.Lenders.length &&
    calculation.Lenders[0].SelectedProduct &&
    calculation.Lenders[0].SelectedProduct.length;

  if (!hasLenders) {
    return [];
  }
  return reduce(
    groupBy(calculation.Lenders[0].SelectedProduct, 'LenderID'),
    (result, value) => {
      value && value.length && result.push(value[0]);
      return result;
    },
    [],
  );
};

export const getLatestCalculation = (calculationList) => {
  if (!calculationList || !calculationList.length) {
    return {};
  }

  const sortedList = calculationList.sort((calculation1, calculation2) => {
    return (
      moment(calculation2.dateOfCalculation) -
      moment(calculation1.dateOfCalculation)
    );
  });
  return (sortedList && sortedList.length && sortedList[0]) || {};
};

export const getMaxLoanAmountLender = (lenderList) => {
  if (!lenderList || !lenderList.length) {
    return {};
  }

  const sortedList = lenderList.sort((lender1, lender2) => {
    return lender2.MaxLoanAmount - lender1.MaxLoanAmount;
  });
  return (sortedList && sortedList.length && sortedList[0]) || {};
};

export const isValidFinancialModel = (...params) => {
  const [keyName, , data] = params;
  if (keyName === 'limit' && data.limit > -1) {
    return true;
  }
  if (keyName === 'balance' && data.balance > -1) {
    return true;
  }
  if (
    ['repayments', 'repaymentFrequencyId'].includes(keyName) &&
    data.repayments > -1 &&
    data.repaymentFrequencyId
  ) {
    return true;
  }
  if (
    ['income', 'incomeFrequencyId'].includes(keyName) &&
    data.income > -1 &&
    data.incomeFrequencyId
  ) {
    return true;
  }

  return false;
};

export const getLiabilityModel = (liabilityTypeId) => {
  return {
    limit: 0,
    balance: 0,
    repayments: 0,
    liabilityTypeId: liabilityTypeId || null,
    repaymentFrequencyId: MONTHLY_FREQUENCY_ID.toString(),
  };
};

export const getIncomeModel = (isClient = true, clientId = null) => {
  return {
    clientId,
    isClient,
    income: 0,
    incomeFrequencyId: MONTHLY_FREQUENCY_ID.toString(),
  };
};

export const onModelUpdate = (...params) => {
  const [keyName, value, model] = params;
  const isMissingRequiredParam = !keyName || !value || !model;
  if (isMissingRequiredParam) {
    return {};
  }

  model[keyName] = value;
  return model;
};

export function isOnlyPersonInFamily(familyId, personList) {
  const personInFamily =
    (personList &&
      personList.filter(
        (person) => parseToInt10(person.FamilyId) === parseToInt10(familyId),
      )) ||
    [];

  return personInFamily.length === 1;
}

export function getClientIncome(client, financialList) {
  const isMissingRequiredParam =
    !client || !client.familyId || !client.clientId || !financialList;
  if (isMissingRequiredParam) {
    return getIncomeModel();
  }

  const { familyId, clientId, isClient } = client;
  const familyFinancial = financialList[familyId];
  if (!familyFinancial || !familyFinancial.income) {
    return getIncomeModel(isClient, clientId);
  }

  return (
    familyFinancial.income.find(
      (income) => parseToInt10(income.clientId) === parseToInt10(clientId),
    ) || getIncomeModel(isClient, clientId)
  );
}

export function getFamilyLiability(familyId, liabilityTypeId, financialList) {
  const isMissingRequiredParam =
    !familyId || !liabilityTypeId || !financialList;
  if (isMissingRequiredParam) {
    return getLiabilityModel();
  }

  const familyFinancial = financialList[familyId];
  if (!familyFinancial || !familyFinancial.liabilities) {
    return getLiabilityModel(parseToInt10(liabilityTypeId));
  }

  return (
    familyFinancial.liabilities.find(
      (liability) =>
        parseToInt10(liability.liabilityTypeId) ===
        parseToInt10(liabilityTypeId),
    ) || getLiabilityModel(parseToInt10(liabilityTypeId))
  );
}

export async function formatPersonParty(
  personList,
  financialList,
  liabilityTypes,
) {
  if (!personList || !personList.length) {
    return [];
  }
  const borrowerFamilies = groupBy(personList, 'FamilyId');
  return Object.keys(borrowerFamilies).reduce((accum, currentFamilyId) => {
    const familyId = parseToInt10(currentFamilyId);
    const persons = borrowerFamilies[familyId];

    const clients = persons.map((person) => {
      const client = {
        clientId: parseToInt10(person.PersonId),
        familyId: parseToInt10(person.FamilyId),
        name: person.FirstName,
        isClient: true,
      };
      client.income = getClientIncome(client, financialList);
      return client;
    });

    const newFamily = {
      familyFullName: '',
      familyId,
      clients,
      isClient: true,
      liabilities: {
        creditCard: getFamilyLiability(
          familyId,
          liabilityTypes.creditCardId,
          financialList,
        ),
        otherLiabilities: getFamilyLiability(
          familyId,
          liabilityTypes.otherId,
          financialList,
        ),
      },
    };
    if (persons.length) {
      newFamily.familyFullName = persons[0].FamilyFullName;
    }

    accum.push(newFamily);
    return accum;
  }, []);
}

export async function formatCompanyParty(
  companyList,
  financialList,
  liabilityTypes,
) {
  if (!companyList || !companyList.length) {
    return [];
  }
  const borrowerCompany = groupBy(companyList, 'FamilyId');

  return Object.keys(borrowerCompany).reduce((accum, currentFamilyId) => {
    const familyId = parseToInt10(currentFamilyId);
    const company = borrowerCompany[familyId];

    const clients = company.map((entity) => {
      const client = {
        clientId: entity.OrganisationId,
        familyId: entity.FamilyId,
        name: entity.FamilyFullName,
        isClient: false,
      };
      client.income = getClientIncome(client, financialList);
      return client;
    });
    const newFamily = {
      familyFullName: '',
      familyId,
      clients,
      isClient: false,
      liabilities: {
        creditCard: getFamilyLiability(
          familyId,
          liabilityTypes.creditCardId,
          financialList,
        ),
        otherLiabilities: getFamilyLiability(
          familyId,
          liabilityTypes.otherId,
          financialList,
        ),
      },
    };

    if (company.length) {
      newFamily.familyFullName = company[0].FamilyFullName;
    }

    accum.push(newFamily);
    return accum;
  }, []);
}
