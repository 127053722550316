import template from './targetMarketDetermination.html';
import controller from './targetMarketDeterminationCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    loanAppFormWarning: '<',
    answerCallback: '&',
    isTmdLoading: '<',
  },
};
