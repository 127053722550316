import angular from 'angular';
import { displayError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    .controller('LenderWorksheetCtrl', function LenderWorksheetCtrl(
      $scope,
      configService,
      toaster,
      loanApplicationServices,
      loanAppSharedData,
      SweetAlert,
    ) {
      /* Created a new controller for lenderWorksheet so using of droplet(uploading documetns) will work on loan submission */

      angular.extend($scope, {
        worksheetDroplet: {},
        hideDocumentProgress: false,
      });

      // Droplet Event Area
      angular.extend($scope, {
        dropletReady() {
          $scope.worksheetDroplet.allowedExtensions(['xlsx', 'xls', 'xlsm']);
          $scope.worksheetDroplet.setRequestUrl(
            `${configService.resource}/contacts/DocumentUpload`,
          );
          $scope.worksheetDroplet.setRequestHeaders({
            Authorization: configService.token,
          });
          $scope.worksheetDroplet.defineHTTPSuccess([/2.{2}/]);
          $scope.worksheetDroplet.useArray(false);
        },

        addWorksheet(file) {
          if (file.type === $scope.worksheetDroplet.FILE_TYPES.INVALID) {
            $scope.documentProgressVisibility(0);
            toaster.pop(
              'error',
              'Invalid',
              'Bad file type added, please use xlsx, xls or xlsm formatted file',
            );
          } else {
            $scope.documentProgressVisibility(1);
            $scope.worksheetDroplet.uploadFiles();
          }
        },

        successWorksheet(response) {
          if (response && response.length > 0) {
            $scope.processWorksheet({
              Title: response[0].Name,
              DocumentID: response[0].DocumentId,
              FamilyID: $scope.familyId,
            });
            $scope.worksheetDroplet.progress.percent = 0;
            $scope.documentProgressVisibility(0);
          }
        },

        errorWorksheet() {
          toaster.pop('error', 'Something went wrong', 'Please try again');
        },
      });
      // end of droplet event area
      // methods
      angular.extend($scope, {
        documentProgressVisibility: (visibility) => {
          $scope.hideDocumentProgress = parseInt(visibility, 10) < 1;
        },

        processWorksheet(object) {
          const documentDataObject = object;
          loanApplicationServices
            .getDocumentPageCount(documentDataObject.DocumentID)
            .then((respond) => {
              $scope.documentObject = {
                LoanScenarioId: $scope.loanAppId,
                Description: 'Worksheet',
                IsLenderWorksheet: true,
                DocumentPage: 1,
                IsAdded: true,
                Notes: '',
                UploadDate: new Date(),
                UploadedByFamilyID: $scope.familyId,
                DocumentId: documentDataObject.DocumentID,
                Name: documentDataObject.Title,
                Size: 0,
                NumberPages: respond.data,
                DateUploaded: new Date(),
                Createddate: new Date(),
                CreatedMonth: new Date(),
              };

              loanApplicationServices
                .setDocument($scope.documentObject)
                .then(() => {
                  $scope.loadDownloadDocuments();
                  SweetAlert.swal({
                    title: 'Success',
                    text: 'Document has been been successfully uploaded.',
                    type: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                  });
                })
                .catch(displayError);
            });
        },

        loadDownloadDocuments() {
          loanApplicationServices
            .getDocumentList($scope.loanAppId)
            .then((result) => {
              $scope.loanAppSharedData.documentListLenderWorksheet =
                result.data || [];
              $scope.validateLenderSubmission();
            });
        },
      });
    });
