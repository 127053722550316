export const accreditationProductsForUI = (product) => {
  const { Lending, Insurance, FamilyID } = product;
  const { lending, insurance } = {
    lending: Lending,
    insurance: Insurance,
  };
  return {
    familyId: FamilyID,
    lending: {
      homeLoans: lending.HomeLoans,
      investmentLoans: lending.InvestmentLoans,
      carLoans: lending.CarLoans,
      equipmentLoans: lending.EquipmentLoans,
      commercialLoans: lending.CommercialLoans,
      constructionLoans: lending.ConstructionLoans,
      other: lending.Other,
      otherText: lending.OtherText,
    },
    insurance: {
      life: insurance.Life,
      incomeProtection: insurance.IncomeProtection,
      health: insurance.Health,
      trauma: insurance.Trauma,
      permanentDisablement: insurance.PermanentDisablement,
      other: insurance.Other,
      otherText: insurance.OtherText,
    },
  };
};

export const accreditationProductsForAPI = (product) => {
  const { lending, insurance, familyId } = product;
  return {
    FamilyID: familyId,
    Lending: {
      HomeLoans: lending.homeLoans,
      InvestmentLoans: lending.investmentLoans,
      CarLoans: lending.carLoans,
      EquipmentLoans: lending.equipmentLoans,
      CommercialLoans: lending.commercialLoans,
      ConstructionLoans: lending.constructionLoans,
      Other: lending.other,
      OtherText: lending.other ? lending.otherText : '',
    },
    Insurance: {
      Life: insurance.life,
      IncomeProtection: insurance.incomeProtection,
      Health: insurance.health,
      Trauma: insurance.trauma,
      PermanentDisablement: insurance.permanentDisablement,
      Other: insurance.other,
      OtherText: insurance.other ? insurance.otherText : '',
    },
  };
};
