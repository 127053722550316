import template from './helloBookAttachments.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    attachedFiles: '<',
    fileExtensions: '<',
    attachedFileList: '<',
    onShowConfirmDelete: '&',
    onResetDeletePopover: '&',
    onConfirmPopoverKeydown: '&',
    onSelectFileAttachments: '&',
    onRemoveSelectedFileAttachment: '&',
  },
};
