import { toastError, toastSaveSuccess } from 'Common/utilities/alert';
import { GENERATE_STATUS } from 'Common/constants/exitCompliance';
import {
  ALT_DATE_INPUT_FORMATS,
  DD_MM_YYYY_DASHED_FORMAT,
  DATE_TEXT_FORMAT,
} from 'Common/constants/dateConfigs';

class ExitComplianceCtrl {
  constructor(
    $q,
    $filter,
    dataService,
    optionsService,
    commonFnService,
    corporateModelService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$filter = $filter;
    this.dataService = dataService;
    this.optionsService = optionsService;
    this.commonFnService = commonFnService;
    this.corporateModelService = corporateModelService;
  }

  showEditMode(key) {
    this.editMode[key] = !this.editMode[key];
  }

  dateEditMode(obj, key) {
    return this.commonFnService.dateEditMode(obj, key, this.editMode);
  }

  getFamilyComplianceSeparations() {
    this.corporateModelService
      .getFamilyComplianceSeparations(this.familyId)
      .then((data) => {
        this.complianceSeparationsRaw = { ...data };
        this.complianceSeparations = data;
        if (!this.complianceSeparations.familyId) {
          this.complianceSeparations.familyId = this.familyId;
        }
      });
  }

  setFamilyComplianceSeparations(fieldKey) {
    if (
      this.exitComplianceForm.$invalid &&
      this.exitComplianceForm.$error.date
    ) {
      toastError('Invalid date format, it must be dd-MM-yyyy or dd/MM/yyyy!');
      return;
    }
    const isDefaultLetterValue =
      fieldKey === 'dateGenerated' &&
      this.complianceSeparationsRaw &&
      !this.complianceSeparationsRaw.dateGenerated;
    if (isDefaultLetterValue) {
      this.complianceSeparations.generateStatusId = GENERATE_STATUS.YES;
    }
    this.corporateModelService
      .setFamilyComplianceSeparations(this.complianceSeparations)
      .then((response) => {
        if (!response) {
          toastError();
          return;
        }
        this.populateStatus();
        this.populateContent();
        toastSaveSuccess();
        this.editMode[fieldKey] = false;
        this.complianceSeparationsRaw = { ...this.complianceSeparations };
      });
  }

  populateStatus() {
    const { generateStatusId } = this.complianceSeparations;
    const separatationStatus =
      this.separationLetterStatuses &&
      this.separationLetterStatuses.find(
        (status) => status.id === generateStatusId,
      );
    if (!separatationStatus || !separatationStatus.value) {
      return;
    }
    const generateStatus = separatationStatus.value;
    this.complianceSeparations = {
      ...this.complianceSeparations,
      generateStatus,
    };
  }

  populateContent() {
    const { letterContentId } = this.complianceSeparations;
    const separatationContent =
      this.separationLetterContents &&
      this.separationLetterContents.find(
        (content) => content.id === letterContentId,
      );
    if (!separatationContent || !separatationContent.value) {
      return;
    }
    const letterContent = separatationContent.value;
    this.complianceSeparations = {
      ...this.complianceSeparations,
      letterContent,
    };
  }

  checkAccessPermission(isAllReadOnly, fieldReadOnly) {
    return this.dataService.checkAccessPermission(isAllReadOnly, fieldReadOnly);
  }

  getDateValue() {
    if (
      !this.complianceSeparations ||
      !this.complianceSeparations.dateGenerated
    ) {
      return 'None';
    }
    return this.$filter('dateTZ')(
      this.complianceSeparations.dateGenerated,
      DATE_TEXT_FORMAT,
    );
  }

  $onInit() {
    this.editMode = [];
    this.altInputFormats = ALT_DATE_INPUT_FORMATS;
    this.dateFormat = DD_MM_YYYY_DASHED_FORMAT;
    const promises = [
      this.optionsService.getSeparationLetterGenerateStatuses(),
      this.optionsService.getSeparationLetterContents(),
    ];

    this.$q.all(promises).then((responses) => {
      const [statuses, contents] = responses;
      this.separationLetterStatuses = statuses || [];
      this.separationLetterContents = contents || [];
      this.getFamilyComplianceSeparations();
    });
  }
}

export default ExitComplianceCtrl;
