import template from './statementOfAdvice.html';
import controller from './statementOfAdviceCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    isLodged: '<',
    loanAppId: '<',
  },
};
