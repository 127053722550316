import angular from 'angular';
import moment from 'moment';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('ComplaintsModalCtrl', function ComplaintsModalCtrl(
      loanToolsService,
      $uibModalInstance,
      toaster,
      successUploadResponse,
      isReadOnly,
    ) {
      const vm = this;
      vm.isReadOnly = isReadOnly;
      /* models */
      vm.dataObject = [];
      vm.providerList = [];
      vm.providerProductList = [];
      vm.isAdd = null;
      /* models date */
      vm.format = 'dd MMM yyyy';
      vm.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
      vm.dateOptions = {
        formatYear: 'yy',
        showWeeks: false,
      };
      vm.isDateTakenOpen = {
        acknowledgementSent: false,
        responseSent: false,
        closed: false,
      };
      vm.isSaving = false;

      const cancel = () => {
        $uibModalInstance.dismiss();
      };

      const checkIfAdd = () => {
        let toReturnValue;

        if (successUploadResponse === null) {
          toReturnValue = true;
        } else {
          toReturnValue = false;
        }
        return toReturnValue;
      };

      const initProcessForAdd = () => {
        vm.dataObject = {
          ComplaintID: 0,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          DateFiled: moment(
            moment().format('D MMM, YYYY'),
            // eslint-disable-next-line sonarjs/no-duplicate-string
            'D MMM YYYY',
          ).format('D MMM YYYY'),
          StatusID: '1',
          ReceivedVia: '',
          ReceivedFrom: '',
          NatureOfComplaint: '',
          Complainant: '',
          ComplaintAgainst: '',
          ProductType: '',
          ComplaintTakenBy: '',
          ComplaintDetails: '',
          OutcomeSought: '',
          AcknowledgementSent: '',
          ResponseSent: '',
          Compensation: '',
          RootCause: '',
          Notes: '',
          FurtherActionDetails: '',
          Closed: '',
        };
      };
      const initProcessForEdit = (uploadResponse) => {
        loanToolsService
          .resourceComplaintGet(uploadResponse)
          .then((response) => {
            const apiResponse = response.data;
            if (apiResponse && apiResponse.length > 0) {
              vm.dataObject = {
                ComplaintID: apiResponse[0].ComplaintID,
                // eslint-disable-next-line sonarjs/no-duplicate-string
                DateFiled: moment(
                  apiResponse[0].DateFiled,
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  'YYYY-MM-DD ',
                ).format('DD MMM YYYY'),
                StatusID: apiResponse[0].StatusID.toString(),
                ReceivedVia: apiResponse[0].ReceivedVia,
                ReceivedFrom: apiResponse[0].ReceivedFrom,
                NatureOfComplaint: apiResponse[0].NatureOfComplaint,
                Complainant: apiResponse[0].Complainant,
                ComplaintAgainst: apiResponse[0].ComplaintAgainst,
                ProductType: apiResponse[0].ProductType,
                ComplaintTakenBy: apiResponse[0].ComplaintTakenBy,
                ComplaintDetails: apiResponse[0].ComplaintDetails,
                OutcomeSought: apiResponse[0].OutcomeSought,
                AcknowledgementSent: apiResponse[0].AcknowledgementSent
                  ? new Date(apiResponse[0].AcknowledgementSent)
                  : '',
                ResponseSent: apiResponse[0].ResponseSent
                  ? new Date(apiResponse[0].ResponseSent)
                  : '',
                Compensation: apiResponse[0].Compensation,
                RootCause: apiResponse[0].RootCause,
                Notes: apiResponse[0].Notes,
                FurtherActionDetails: apiResponse[0].FurtherActionDetails,
                Closed: apiResponse[0].Closed
                  ? new Date(apiResponse[0].Closed)
                  : '',
              };
            }
          });
      };
      //
      const addComplaintForm = (isValid) => {
        if (isValid) {
          vm.isSaving = true;
          const objectToAdd = {
            ComplaintID: vm.dataObject.ComplaintID,
            DateFiled: moment(vm.dataObject.DateFiled, 'D MMM YYYY ').format(
              'YYYY-MM-DD',
            ),
            StatusID: parseInt(vm.dataObject.StatusID, 10),
            ReceivedVia: vm.dataObject.ReceivedVia,
            ReceivedFrom: vm.dataObject.ReceivedFrom,
            NatureOfComplaint: vm.dataObject.NatureOfComplaint,
            Complainant: vm.dataObject.Complainant,
            ComplaintAgainst: vm.dataObject.ComplaintAgainst,
            ProductType: vm.dataObject.ProductType,
            ComplaintTakenBy: vm.dataObject.ComplaintTakenBy,
            ComplaintDetails: vm.dataObject.ComplaintDetails,
            OutcomeSought: vm.dataObject.OutcomeSought,
            AcknowledgementSent: vm.dataObject.AcknowledgementSent
              ? moment(vm.dataObject.AcknowledgementSent, 'YYYY-MM-DD ').format(
                  'YYYY-MM-DD',
                )
              : '',
            ResponseSent: vm.dataObject.ResponseSent
              ? moment(vm.dataObject.ResponseSent, 'YYYY-MM-DD ').format(
                  'YYYY-MM-DD',
                )
              : '',
            Compensation: vm.dataObject.Compensation,
            RootCause: vm.dataObject.RootCause,
            Notes: vm.dataObject.Notes,
            FurtherActionDetails: vm.dataObject.FurtherActionDetails,
            Closed: vm.dataObject.Closed
              ? moment(vm.dataObject.Closed, 'YYYY-MM-DD ').format('YYYY-MM-DD')
              : '',
          };

          loanToolsService.resourceComplaintSet(objectToAdd).then(() => {
            loanToolsService.resourceComplaintGet(0).then((response) => {
              const theResponse = response.data;

              toaster.pop('success', 'Sucess', 'Complaint saved');
              $uibModalInstance.close(theResponse);
            });
          });
        }
      };
      const dateTakenClicked = (whichDatePicker, isOpenDatePicker) => {
        if (whichDatePicker === 1) {
          vm.isDateTakenOpen.acknowledgementSent = !isOpenDatePicker;
        }
        if (whichDatePicker === 2) {
          vm.isDateTakenOpen.responseSent = !isOpenDatePicker;
        }
        if (whichDatePicker === 3) {
          vm.isDateTakenOpen.closed = !isOpenDatePicker;
        }
      };

      const init = () => {
        vm.isAdd = checkIfAdd();
        if (vm.isAdd) {
          initProcessForAdd();
        } else {
          initProcessForEdit(successUploadResponse);
        }
      };

      vm.addComplaintForm = addComplaintForm;
      vm.dateTakenClicked = dateTakenClicked;
      vm.cancel = cancel;

      init();
    });
