import template from './insuranceFile.html';
import controller from './insuranceFileCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
  },
};
