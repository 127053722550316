import {
  insurancePipelineTableForUI,
  insurancePipelineCardForUI,
  insurancePipelineLabelForUI,
  insurancePipelineCardBenefitForUI,
  insurancePipelineFilterForUI,
  insurancePipelineStatusSortingForUI,
  insurancePipelineFiltersForMyCRM,
  insurancePipelineStatusSortingForMyCRM,
  insuranceAppCoverDetailsForUI,
  insuranceBenefitCoverDetailsForUI,
  insurancePipelineStatusForUI,
  insuranceNotProceededReasonForUI,
} from 'Common/mappers/insurancePipeline';
import { INSURANCE_PIPELINE_STATUS } from 'Common/constants/insuranceOptions';
import { getMonthList } from 'Common/utilities/month';
import { toastError, toastSuccess } from 'Common/utilities/alert';

export function formatPolicyNumbersCSV(policyNumbersCSV = '') {
  const policyNumberFormatted =
    policyNumbersCSV &&
    policyNumbersCSV.split(',').reduce((accum, policyNumber, index, splits) => {
      const isLast = index === splits.length - 1;
      const separator = isLast ? '' : ', ';
      return `${accum}#${policyNumber.trim()}${separator}`;
    }, '');
  return policyNumberFormatted || '';
}

export function mapInsurancePipelineTable(pipelineTable) {
  if (!pipelineTable) {
    return;
  }
  const mappedPipelineTable = insurancePipelineTableForUI(pipelineTable);
  mappedPipelineTable.items =
    pipelineTable.Items &&
    pipelineTable.Items.map((item) => {
      const mappedItem = insurancePipelineCardForUI(item);
      mappedItem.policyNumber = formatPolicyNumbersCSV(mappedItem.policyNumber);
      mappedItem.labels =
        item.CardLabels && item.CardLabels.map(insurancePipelineLabelForUI);
      mappedItem.benefits =
        item.Benefits && item.Benefits.map(insurancePipelineCardBenefitForUI);
      return mappedItem;
    });
  return mappedPipelineTable;
}

export function mapInsurancePipelineFiltersForUI(filters) {
  if (!filters) {
    return;
  }
  const mappedPipelineFilter = insurancePipelineFilterForUI(filters);
  mappedPipelineFilter.statusSorting = filters.StatusSorting
    ? filters.StatusSorting.map(insurancePipelineStatusSortingForUI)
    : [];
  return mappedPipelineFilter;
}

export function mapInsurancePipelineFiltersForMyCRM(filters) {
  if (!filters) {
    return;
  }
  const mappedFilters = insurancePipelineFiltersForMyCRM(filters);
  mappedFilters.StatusSorting = filters.statusSorting
    ? filters.statusSorting.map(insurancePipelineStatusSortingForMyCRM)
    : [];
  return mappedFilters;
}

export function mapInsuranceAppCoverDetails(coverDetails) {
  if (!coverDetails) {
    return { benefitDetails: [] };
  }
  const mappedCoverDetails = insuranceAppCoverDetailsForUI(coverDetails);
  const { BenefitDetails: benefitDetails } = coverDetails;
  mappedCoverDetails.benefitDetails = benefitDetails
    ? benefitDetails.map(insuranceBenefitCoverDetailsForUI)
    : [];
  return mappedCoverDetails;
}

export function mapInsurancePipelineStatus(pipelineStatus) {
  const { Reasons: reasons } = pipelineStatus;
  return {
    ...insurancePipelineStatusForUI(pipelineStatus),
    reasons: reasons ? reasons.map(insuranceNotProceededReasonForUI) : [],
  };
}

export function checkPipelineColumnHasMore(column, pageSize) {
  return (column && column.totalCards - column.page * pageSize > 0) || false;
}

export function isClientFilterValid(filter) {
  return (
    typeof filter !== 'undefined' &&
    filter !== null &&
    (filter.length >= 3 || filter.length === 0)
  );
}

export function isConversionStatus(status) {
  const { IN_FORCE, NOT_PROCEEDED } = INSURANCE_PIPELINE_STATUS;
  const isConverted =
    status && (status.id === IN_FORCE || status.id === NOT_PROCEEDED);
  return isConverted || false;
}

export function getConciseConversionSummary(conversionSummaries) {
  if (!conversionSummaries) {
    return {};
  }
  const inForce = conversionSummaries.find(
    (summary) => summary.statusId === INSURANCE_PIPELINE_STATUS.IN_FORCE,
  );
  const notProceeded = conversionSummaries.find(
    (summary) => summary.statusId === INSURANCE_PIPELINE_STATUS.NOT_PROCEEDED,
  );
  return {
    inForcePercentage: inForce && inForce.conversionPercentage,
    notProceededPercentage: notProceeded && notProceeded.conversionPercentage,
  };
}

export function checkIsInsuranceAppProceeded(event) {
  return event && event.dest.sortableScope.element[0].id === 'approve';
}

export function getStatusIdConvertedTo(event) {
  const isProceeded = checkIsInsuranceAppProceeded(event);
  return isProceeded
    ? INSURANCE_PIPELINE_STATUS.IN_FORCE
    : INSURANCE_PIPELINE_STATUS.NOT_PROCEEDED;
}

export const updateReviewMonthUtil = ($scope) => () => {
  if (!$scope.loanId) {
    if (!$scope.reviewMonthId) {
      toastError('Annual Review Month cannot be empty.');
      return;
    }
    $scope.isLoading = true;
    $scope.insurancePipelineService
      .postReviewMonth({
        familyId: $scope.familyId,
        reviewMonth: $scope.reviewMonthId,
      })
      .then(() => {
        toastSuccess('Review month successfully updated.');
        $scope.save({});
        $scope.isLoading = false;
      });
  } else {
    if (!$scope.reviewMonthId) {
      return;
    }
    $scope.insurancePipelineService.postReviewMonthPipeline({
      loanId: $scope.loanId,
      reviewMonth: $scope.reviewMonthId,
    });
  }
};

export const annualReviewFeature = ($scope) => {
  $scope.loanId = $scope.loanDetails && $scope.loanDetails.LoanID;
  $scope.isReviewMonth = false;
  $scope.reviewMonthId = 0;
  $scope.reviewMonth = '';
  $scope.currentYear = new Date().getFullYear();
  $scope.monthsList = [];

  $scope.getFamilyId = () => {
    $scope.insurancePipelineService
      .getFamilyId($scope.loanId)
      .then((result) => {
        if (!result.data) {
          return;
        }
        $scope.familyId = result.data;
        $scope.getContactFamilyInfo();
      });
  };

  $scope.getContactFamilyInfo = () => {
    $scope.contactService
      .contactFamilyInfoGet($scope.familyId)
      .then((response) => {
        const { data } = response;
        if (!data) {
          return;
        }
        $scope.reviewMonth = data.ReviewMonth;
        $scope.isReviewMonth = !!data.ReviewMonth;
        $scope.reviewMonthId =
          $scope.monthsList.map((e) => e.name).indexOf($scope.reviewMonth) + 1;
      });
  };

  $scope.getMonthsList = () => {
    getMonthList().map((item, index) => {
      const month = {
        name: item,
        id: index,
      };
      if (item !== 'All Months') {
        $scope.monthsList.push(month);
      }
      return item;
    });
  };

  $scope.updateReviewMonth = updateReviewMonthUtil($scope);

  $scope.getMonthsList();
  if ($scope.familyId) {
    $scope.getContactFamilyInfo();
  } else {
    $scope.getFamilyId();
  }
};
