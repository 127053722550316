import angular from 'angular';
import annualReviews from './annualReviews';
import deferralReviews from './deferralReviews';
import loadingExclusions from './loadingExclusions';
import issuedFollowUp from './issuedFollowUp';
import inArrears from './inArrears';
import currentClaims from './currentClaims';
import comingSoon from './comingSoon';

export default angular
  .module(
    'insuranceCustomerCare.scenes.tabContent.scenes.components.tables',
    [],
  )
  .component('annualReviews', annualReviews)
  .component('deferralReviews', deferralReviews)
  .component('loadingExclusions', loadingExclusions)
  .component('issuedFollowUp', issuedFollowUp)
  .component('inArrears', inArrears)
  .component('currentClaims', currentClaims)
  .component('comingSoon', comingSoon).name;
