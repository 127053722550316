import template from './labelToggler.html';
import './style.scss';

export default {
  template,
  bindings: {
    labelName: '<',
    labelColor: '<',
    isShrink: '<',
    isFolderIcon: '<',
    onToggle: '&',
  },
};
