import * as modalUtils from '../../utils/modalUtils';

export default class QualificationModal {
  constructor(loanToolsService) {
    'ngInject';

    this.loanToolsService = loanToolsService;
  }

  $onInit() {
    modalUtils.$onInit(this, 'qualification', 'workingQualification');
    modalUtils.initializeCommonProperties(
      this,
      'workingQualification',
      'resourceEducationQualificationSet',
    );
  }
}
