import template from './soleTraderInfo.html';
import SoleTraderInfoCtrl from './soleTraderInfoCtrl';

export default {
  template,
  bindings: {
    isLoanApp: '<',
    contactData: '<',
    formReference: '<',
    parentToChildNotificationRegistration: '&',
    returnSoleTraderInfo: '&',
  },
  controller: SoleTraderInfoCtrl,
  controllerAs: 'vm',
};
