import { get } from 'lodash';

export const onLoad = (element) => () => {
  const height = get(
    element,
    '0.contentWindow.document.body.scrollHeight',
    200,
  );
  element.css('height', `${height + 40}px`);
};

export const iframeSetDimensionFn = (scope, element) => {
  const onLoadFn = onLoad(element);
  element.on('load', onLoadFn);
  scope.$on('$destroy', () => {
    element.off('load', onLoadFn);
  });
};

export const iframeSetDimension = () => {
  return iframeSetDimensionFn;
};
