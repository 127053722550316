import angular from 'angular';
import headerMainDetails from './headerMainDetails';
import headerLendingDetails from './headerLendingDetails';
import involvedPartiesOption from './involvedPartiesOption';
import contactFilesOption from './contactFilesOption';
import fileQuickActions from './fileQuickActions';
import fileFactFindWidget from './fileFactFindWidget';
import fileImportantDates from './fileImportantDates';
import createNewFileModal from './createNewFileModal';
import fileAddPartiesModal from './fileAddPartiesModal';
import createNewInsuranceFileModal from './createNewInsuranceFileModal';

export default angular
  .module('viewOfFile.components', [])
  .component('headerMainDetails', headerMainDetails)
  .component('fileQuickActions', fileQuickActions)
  .component('headerLendingDetails', headerLendingDetails)
  .component('involvedPartiesOption', involvedPartiesOption)
  .component('contactFilesOption', contactFilesOption)
  .component('fileFactFindWidget', fileFactFindWidget)
  .component('createNewFileModal', createNewFileModal)
  .component('fileAddPartiesModal', fileAddPartiesModal)
  .component('fileImportantDates', fileImportantDates)
  .component('createNewInsuranceFileModal', createNewInsuranceFileModal).name;
