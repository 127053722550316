import './style.scss';
import template from './taskTemplateViewer.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    template: '<',
    onEdit: '&',
    onDelete: '&',
  },
};
