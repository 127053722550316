import { sendSmsBuilderForMyCRM } from 'Common/mappers/loanOpportunity';
import { showSwalAfterSending } from 'Common/utilities/loanOpportunity';

export default class OpportunitySendSmsModalCtrl {
  constructor(loanOpportunityService) {
    'ngInject';

    this.loanOpportunityService = loanOpportunityService;
  }

  changeSmsObj(smsModel) {
    this.sms = {
      ...smsModel,
    };
    this.isSmsSending = false;
  }

  sendSms() {
    const { recipientsPhoneNumbers, body: message } = this.sms;
    const params = recipientsPhoneNumbers.map((o) => {
      const recipient =
        typeof o === 'object'
          ? o
          : { ...this.recipientsChoices[0], phoneNumber: o };
      return sendSmsBuilderForMyCRM({ ...recipient, message });
    });

    this.isSmsSending = true;
    this.loanOpportunityService
      .sendSms(this.loanId, params)
      .then((response) => {
        this.closeModal(true);
        showSwalAfterSending(response.succeeded, 'SMS');
      });
  }

  closeModal(isRefresh) {
    this.isSmsSending = false;
    this.modalInstance.close({ isRefresh });
  }
}
