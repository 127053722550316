import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('tasks', function tasks($timeout, overviewDashboardService) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: { myParam: '=' },
        templateUrl:
          'assets/views/overviewDashboard/directiveTemplates/tasks.html',

        // eslint-disable-next-line sonarjs/cognitive-complexity
        link(scope) {
          scope.tasksTypes = [];
          scope.tasksType = {};
          scope.filteredtasksType = [];
          scope.tasksTableDropDownList = [];
          scope.tasksTableDropDowns = {};
          scope.filteredTasksDropDownList = [];
          scope.taskssMultiAdviser = [];
          scope.tasksMultiAdviserObj = {};
          scope.WidgetOptionGetData = [];
          scope.outp = [];
          scope.dueText = {};

          function TaskSmallGet() {
            overviewDashboardService
              .TaskSmallGet(scope.myParam.Widget_UserID)
              .then(
                (response) => {
                  scope.taskSmallGetData = response.data;
                },
                () => {},
              );
          }

          function saveForTimePeriod(widgetSetObj) {
            scope.OptionToSet = widgetSetObj;
            overviewDashboardService
              .WidgetOptionSet(scope.OptionToSet)
              .then(TaskSmallGet)
              .catch(displayError);
          }

          function dataParsing() {
            if (parseInt(scope.tasksTableDropDowns.OptionValueId, 10) === 31) {
              scope.dueText = 'Due Today';
            } else if (
              parseInt(scope.tasksTableDropDowns.OptionValueId, 10) === 32
            ) {
              scope.dueText = 'Due Tomorrow';
            } else if (
              parseInt(scope.tasksTableDropDowns.OptionValueId, 10) === 33
            ) {
              scope.dueText = 'Due This Week';
            } else if (
              parseInt(scope.tasksTableDropDowns.OptionValueId, 10) === 34
            ) {
              scope.dueText = 'Due Next Week';
            } else if (
              parseInt(scope.tasksTableDropDowns.OptionValueId, 10) === 35
            ) {
              scope.dueText = 'Due This Month';
            } else if (
              parseInt(scope.tasksTableDropDowns.OptionValueId, 10) === 36
            ) {
              scope.dueText = 'Due Next Month';
            } else {
              scope.dueText = 'Due Today';
            }

            saveForTimePeriod(scope.objForWidgetOptionSet);
          }

          scope.updateTaskType = function (tasksDropDowns) {
            scope.tasksDropDownsObj = tasksDropDowns;

            scope.tasksDropDownsObjToSet = {};

            scope.tasksDropDownsObjToSet.Widget_UserID =
              scope.myParam.Widget_UserID;
            scope.tasksDropDownsObjToSet.Widget_OptionValue =
              scope.tasksDropDownsObj.OptionValueId;
            scope.tasksDropDownsObjToSet.WidgetOptionId =
              scope.tasksDropDownsObj.OptionId;
            scope.tasksDropDownsObjToSet.SelectedUser = null;
            saveForTimePeriod(scope.tasksDropDownsObjToSet);
          };

          scope.updateMultiselectFilterData = function (clickedItem) {
            let dataToSend = [];
            scope.MultiOptionSet = {};
            scope.MultiOptionSetForAll = {};
            const itemData = _.find(scope.taskssMultiAdviser, ['ticked', true]);
            if (_.isUndefined(itemData)) {
              _.map(scope.taskssMultiAdviser, (item) => {
                if (item.FamilyName === clickedItem.FamilyName) {
                  item.ticked = true;
                }
              });
            }
            if (clickedItem.FamilyName !== 'All') {
              if (_.size(scope.taskssMultiAdviser) !== 0) {
                scope.taskssMultiAdviser[0].ticked = false;
              }

              dataToSend = scope.taskssMultiAdviser
                .filter(
                  (item) => item.FamilyName !== 'All' && item.ticked === true,
                )
                .map((obj) => {
                  return { PersonId: obj.FamilyId };
                });

              scope.MultiOptionSet.SelectedUser = dataToSend;
              scope.MultiOptionSet.WidgetOptionId =
                scope.WidgetOptionAdviserList[0].OptionId;
              scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
              scope.MultiOptionSet.Widget_OptionValue =
                scope.WidgetOptionAdviserList[1].OptionValueId;

              saveForTimePeriod(scope.MultiOptionSet);
            } else {
              const all = scope.outp.filter(
                (item) => item.FamilyName === 'All',
              );
              if (all.length > 0 && scope.outp.length > 1) {
                scope.taskssMultiAdviser = scope.taskssMultiAdviser.map(
                  (obj) => {
                    if (obj.FamilyName === 'All') {
                      return obj;
                    }

                    obj.ticked = false;
                    return obj;
                  },
                );
              }
              dataToSend.push({
                PersonId: scope.taskssMultiAdviser[0].FamilyId,
              });

              scope.MultiOptionSetForAll.SelectedUser = dataToSend;
              scope.MultiOptionSetForAll.WidgetOptionId =
                scope.WidgetOptionAdviserList[0].OptionId;
              scope.MultiOptionSetForAll.Widget_UserID =
                scope.myParam.Widget_UserID;
              scope.MultiOptionSetForAll.Widget_OptionValue =
                scope.WidgetOptionAdviserList[0].OptionValueId;

              saveForTimePeriod(scope.MultiOptionSetForAll);
            }
          };
          scope.callServerWithUpdatedvalue = function () {
            overviewDashboardService.WidgetYesNoGet().then(
              (response) => {
                scope.YesNoData = response.data;
                for (let i = 0; i < scope.YesNoData.length; i++) {
                  if (
                    scope.WidgetOptionGetYesNo.DisplayValue ===
                    scope.YesNoData[i].OptionDisplayName
                  ) {
                    scope.WidgetOptionGetYesNo.Widget_OptionValue =
                      scope.YesNoData[i].OptionValueId;
                    scope.WidgetOptionGetYesNo.WidgetOptionId =
                      scope.YesNoData[i].OptionId;
                    scope.WidgetOptionGetYesNo.Widget_UserID =
                      scope.myParam.Widget_UserID;
                    scope.WidgetOptionGetYesNo.SelectedUser = null;
                  }
                }
                saveForTimePeriod(scope.WidgetOptionGetYesNo);
              },
              () => {},
            );
          };
          scope.showNextVal = function (displayObj) {
            scope.currentObj = displayObj;
            const displayPos = scope.currentObj.OptionDB_Value;
            const arrayPos = displayPos - 1;
            if (arrayPos + 1 > scope.tasksTableDropDownList.length - 1) {
              scope.tasksTableDropDowns = scope.tasksTableDropDownList[0];

              overviewDashboardService
                .WidgetOptionGet(scope.myParam.Widget_UserID)
                .then(
                  (response) => {
                    scope.WidgetOptionGetData = response.data;
                  },
                  () => {},
                );
              scope.objForWidgetOptionSet = {
                WidgetOptionId: scope.tasksTableDropDowns.OptionId,
                Widget_UserID: scope.myParam.Widget_UserID,
                Widget_OptionValue: scope.tasksTableDropDowns.OptionValueId,
                SelectedUser: null,
              };

              dataParsing();

              return;
            }
            scope.tasksTableDropDowns =
              scope.tasksTableDropDownList[arrayPos + 1];

            overviewDashboardService
              .WidgetOptionGet(scope.myParam.Widget_UserID)
              .then(
                (response) => {
                  scope.WidgetOptionGetData = response.data;
                },
                () => {},
              );
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.tasksTableDropDowns.OptionId,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.tasksTableDropDowns.OptionValueId,
              SelectedUser: null,
            };

            dataParsing();
          };
          scope.showPrevVal = function (displayObj) {
            scope.currentObj = displayObj;
            const displayPos = scope.currentObj.OptionDB_Value;
            const arrayPos = displayPos - 1;

            if (arrayPos - 1 === -1) {
              const lastPos = scope.tasksTableDropDownList.length - 1;
              scope.tasksTableDropDowns = scope.tasksTableDropDownList[lastPos];
              overviewDashboardService
                .WidgetOptionGet(scope.myParam.Widget_UserID)
                .then(
                  (response) => {
                    scope.WidgetOptionGetData = response.data;
                  },
                  () => {},
                );
              scope.objForWidgetOptionSet = {
                WidgetOptionId: scope.tasksTableDropDowns.OptionId,
                Widget_UserID: scope.myParam.Widget_UserID,
                Widget_OptionValue: scope.tasksTableDropDowns.OptionValueId,
                SelectedUser: null,
              };

              dataParsing();
              return;
            }
            scope.tasksTableDropDowns =
              scope.tasksTableDropDownList[arrayPos - 1];
            overviewDashboardService
              .WidgetOptionGet(scope.myParam.Widget_UserID)
              .then(
                (response) => {
                  scope.WidgetOptionGetData = response.data;
                },
                () => {},
              );
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.tasksTableDropDowns.OptionId,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.tasksTableDropDowns.OptionValueId,
              SelectedUser: null,
            };
            dataParsing();
          };

          scope.doAction = function (msg) {
            if (msg === 'edit') {
              scope.customizeMode = true;
            } else {
              scope.customizeMode = false;
            }
            if (msg === 'notEdit') {
              scope.customizeMode = false;
            } else {
              scope.customizeMode = true;
            }
          };
        },
      };
    });
