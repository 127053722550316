export default class InsuranceFGModalService {
  constructor($uibModal) {
    'ngInject';

    this.createFireAndGeneralAppModal = $uibModal;
  }

  openFireAndGeneralModal(params) {
    return this.createFireAndGeneralAppModal.open({
      animation: true,
      template: `<insurance-fire-and-general-modal 
                  modal-instance="vm.modalInstance"
                  insurance-data="vm.props.dataParams"
                  >
                </insurance-fire-and-general-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'insurance-fire-and-general',
      size: 'lg',
      resolve: {
        props: () => {
          return { dataParams: params };
        },
      },
    });
  }
}
