import { textLimit, hasReadMore } from 'Common/utilities/string';

export default class ReadMoreCtrl {
  $onChanges() {
    this.showText();
    this.isReadMore();
  }

  showText() {
    this.displayText = textLimit(this.text, this.limit) || '';
  }

  isReadMore() {
    this.hasReadMore = hasReadMore(this.text, this.limit) || false;
  }

  handleClick($event) {
    this.onClick();
    $event.stopPropagation();
  }
}
