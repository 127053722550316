import {
  ENQUIRY_SOURCE,
  WIDGET_OPTION_VAL,
  ALL_VALUE,
  WIDGET_TITLE,
} from 'Common/constants/dashboardWidget';
import { BASIC_PIE_OPTION, COMMON_PIE_COLORS } from 'Common/config/chart';
import {
  getValueOption,
  getCurrentCarouselData,
  parseMultiSelectTicks,
  mapOptionAll,
  mapSelectedItem,
  getCustomOptionValues,
  getIndexPeriod,
  getAdvisersParams,
  getFormattedAdvisers,
} from 'Common/utilities/dashboardWidget';

export default class EnquirySourceTileCtrl {
  constructor(
    $q,
    currentUserService,
    overviewDashboardService,
    dashboardService,
    commonWidgetService,
    mycrmLookupService,
  ) {
    'ngInject';

    this.$q = $q;
    this.currentUserService = currentUserService;
    this.overviewDashboardService = overviewDashboardService;
    this.dashboardService = dashboardService;
    this.commonWidgetService = commonWidgetService;
    this.mycrmLookupService = mycrmLookupService;
  }

  $onInit() {
    this.ENQUIRY_SOURCE = ENQUIRY_SOURCE;
    this.chartOptions = BASIC_PIE_OPTION;
    this.timePeriodSelected = {};
    this.timePeriodList = [];
    this.advisersDropdown = [];
    this.showWidgetSettings = true;
    this.widgetTitle = WIDGET_TITLE.ENQUIRY_SOURCE;
    this.widgetSubtitle = 'Sources of New Leads';

    this.getWidgetOptionDropdown();
  }

  goToReport() {
    const params = {
      widgetId: this.myParam.WidgetId,
      widgetUserId: this.myParam.Widget_UserID,
    };
    this.commonWidgetService.goToReport(params);
  }

  getWidgetOptionDropdown() {
    const { isAssistant, isAdminAssistant } = this.currentUserService;
    const isLoanWriterOnly = isAssistant || isAdminAssistant;

    const promises = [
      this.commonWidgetService.getWidgetOptions(ENQUIRY_SOURCE.TIME_PERIOD),
      this.mycrmLookupService.getAdvisers(getAdvisersParams(isLoanWriterOnly)),
    ];

    this.$q.all(promises).then((responses) => {
      if (!responses || !responses.length) {
        return;
      }
      const [timePeriodList, advisersDropdown] = responses;

      this.timePeriodList = timePeriodList;
      this.advisersDropdown = getFormattedAdvisers(advisersDropdown);
      this.getWidgetUserOptions(true);
    });
  }

  getWidgetUserOptions(isLoadSplits) {
    return this.commonWidgetService
      .getWidgetUserOptions(this.myParam.Widget_UserID)
      .then(({ data }) => {
        isLoadSplits && this.getEnquirySource();
        if (!data || !data.length) {
          return;
        }

        const timePeriod = getValueOption(ENQUIRY_SOURCE.TIME_PERIOD, data);
        this.timePeriodSelected = timePeriod;
        const advisers = getValueOption(ENQUIRY_SOURCE.ADVISERS, data);

        this.advisersDropdown = parseMultiSelectTicks(
          advisers,
          this.advisersDropdown,
        );
      });
  }

  setTimePeriod(isNext, currentPeriod, list) {
    const indexPeriod = getIndexPeriod(list, currentPeriod);

    this.timePeriodSelected = getCurrentCarouselData(isNext, indexPeriod, list);

    this.setWidgetOptions({
      widgetOptionId: ENQUIRY_SOURCE.TIME_PERIOD,
      value:
        this.timePeriodSelected.Widget_OptionValue ||
        this.timePeriodSelected.valueId,
    });
  }

  setWidgetOptions(params) {
    const { widgetOptionId, value, selectedUser, needUpdate } = params;
    const payload = {
      WidgetOptionId: widgetOptionId,
      Widget_UserID: this.myParam.Widget_UserID,
      Widget_OptionValue: value || 0,
      SelectedUser: selectedUser,
    };
    this.overviewDashboardService.WidgetOptionSet(payload).then(() => {
      if (needUpdate) {
        this.getWidgetUserOptions(true);
        return;
      }
      this.getEnquirySource();
    });
  }

  onItemSelect(itemClicked, variableName, inputData, selection) {
    if (itemClicked.value === ALL_VALUE || !selection.length) {
      this.selectAll(variableName, inputData);
      return;
    }

    this[variableName] = mapSelectedItem(inputData);
    this.setCustomWidgetOption(variableName);
  }

  setCustomWidgetOption(variableName, isAll) {
    const optionValues = getCustomOptionValues(this[variableName]);

    const { ADVISER } = WIDGET_OPTION_VAL;
    const widgetOptionId = ENQUIRY_SOURCE.ADVISERS;

    const value = isAll ? ADVISER.ALL : ADVISER.CUSTOM;
    const needUpdate = false;

    this.setWidgetOptions({
      widgetOptionId,
      value,
      selectedUser: optionValues,
      needUpdate,
    });
  }

  selectAll(varName, inputData) {
    this[varName] = mapOptionAll(inputData);
    this.setCustomWidgetOption(varName, true);
  }

  setView(isSettings) {
    this.isSettings = isSettings;
  }

  getEnquirySource() {
    this.dashboardService
      .getEnquirySource({ userWidgetId: this.myParam.Widget_UserID })
      .then((data) => {
        this.chartData = [];
        this.chartColors = [];
        this.chartLegend = [];
        this.chartLabels = [];
        if (!data || !data.length) {
          return;
        }

        const colorSplit = COMMON_PIE_COLORS.length + 1;
        const MAX_LABEL_INDEX = 4;

        data.forEach((item, index) => {
          const piePiece = item.count;
          this.chartData = [...this.chartData, piePiece];
          this.chartColors = [
            ...this.chartColors,
            COMMON_PIE_COLORS[index % colorSplit],
          ];
          this.chartLegend = [
            ...this.chartLegend,
            {
              label: item.category,
              color: COMMON_PIE_COLORS[index % colorSplit],
            },
          ];
          this.chartLabels = [...this.chartLabels, item.category];
        });

        this.chartLegend =
          this.chartLegend.length && this.chartLegend.slice(0, MAX_LABEL_INDEX);
      });
  }
}
