import angular from 'angular';
import setDefaultTemplate from './setDefaultTemplate';
import customerCareSendEmail from './customerCareSendEmail';
import customerCareAction from './customerCareAction';

export default angular
  .module('components.customerCare', [])
  .component('setDefaultTemplate', setDefaultTemplate)
  .component('customerCareSendEmail', customerCareSendEmail)
  .component('customerCareAction', customerCareAction).name;
