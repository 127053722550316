import { get } from 'lodash';
import { isOwnBrand } from './brandingCategory';

export const getClientAdviser = ({
  contactModelService,
  $scope,
  isAdviserBYOB,
}) => {
  const clientFamilyID = get($scope.clientsDataObject, '[0].FamilyId', '');

  if ((!contactModelService && !$scope) || !clientFamilyID) {
    return;
  }

  contactModelService.getFamilyAdviserInfo(clientFamilyID).then((data) => {
    if (!data) {
      return;
    }
    $scope.isBYOBRelated =
      isAdviserBYOB || isOwnBrand(get(data, 'brandingCategoryID'));
  });
};
