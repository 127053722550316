import template from './dropdown.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    id: '@',
    name: '@',
    valueField: '@',
    displayField: '@',
    options: '<',
    selectedValue: '=',
    isRequired: '<',
  },
};
