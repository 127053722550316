import { get } from 'lodash';

export const getSecurityAddressHtml = (security) => {
  const securityAddress = get(security, 'PropertyDetails.Address');
  if (!securityAddress) {
    return '';
  }
  return `
      <div class="hbox" uib-tooltip="${security.PropertyDetails.Address}">
          <div><icon-map-pin class="left-icon"></icon-map-pin></div>
          <div>${security.PropertyDetails.Address}</div>
      </div>`;
};

export const getSecurityVehicleHtml = (loanDetails, showVehicleDetails) => {
  if (!showVehicleDetails || !loanDetails) {
    return '';
  }
  return `
    <div class="hbox" uib-tooltip="${loanDetails.VehicleYear} ${loanDetails.VehicleMake} ${loanDetails.AssetDescription}">
        <div><icon-security-asset class="left-icon"></icon-security-asset></div>
        <div>${loanDetails.VehicleYear} ${loanDetails.VehicleMake} ${loanDetails.AssetDescription}</div>
    </div>`;
};

export const getLoanSecurityMarkUp = (loanDetails, showVehicleDetails) => {
  const securityInfo = get(loanDetails, 'SecurityInfo', []);
  const isInvalidSecurity =
    !showVehicleDetails && !loanDetails && !securityInfo.length;

  if (isInvalidSecurity) {
    return '';
  }

  const addressHtml =
    (securityInfo.length &&
      securityInfo.map((security) => {
        return getSecurityAddressHtml(security);
      })) ||
    '';
  const vehicleHtml =
    (showVehicleDetails &&
      getSecurityVehicleHtml(loanDetails, showVehicleDetails)) ||
    '';
  return (
    (addressHtml && `<div class="security">${addressHtml}</div>`) +
    (vehicleHtml && `<div class="security">${vehicleHtml}</div>`)
  );
};

export const getFirstSecurityWithAddress = (securityInfo) => {
  if (!securityInfo || !securityInfo.length) {
    return '';
  }

  const addressPropertyKey = 'PropertyDetails.Address';
  const securityWithAddress =
    securityInfo.length &&
    securityInfo.find((security) => {
      const securityAddress = get(security, addressPropertyKey);
      return !!securityAddress;
    });
  return get(securityWithAddress, addressPropertyKey, '');
};
