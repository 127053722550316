import angular from 'angular';

class QuickLMICalculatorCtrl {
  constructor($state) {
    'ngInject';

    this.state = $state;
  }

  $onInit() {
    this.brokerEventId = this.state.params.brokerEventId;
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .controller('QuickLMICalculatorCtrl', QuickLMICalculatorCtrl);
