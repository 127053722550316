import { ONBOARDING_ROLE_ID } from 'Common/constants/onboarding';
import { isEntityPartOfType, getYesNoText } from 'Common/utilities/onboarding';
import { toastError } from 'Common/utilities/alert';
import { setBooleanToInt } from 'Common/utilities/typeConversion';

class OnboardingAsicCtrl {
  constructor(
    currentUserService,
    onboardingService,
    onboardingDataService,
    corporateService,
  ) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.onboardingService = onboardingService;
    this.onboardingDataService = onboardingDataService;
    this.corporateService = corporateService;
    this.getYesNoText = getYesNoText;
  }

  isAsicType(type) {
    return this.asicDetails.ASICType === type;
  }

  setTextValues() {
    const questionItem = this.isAU ? 'ASIC appointment' : 'FSPR number';
    this.mainQuestion = `Does your entity have an existing ${questionItem}?`;

    const entityType = this.onboardingDataService.getUserCurrentEntityType();
    const isEntityCompanyType = isEntityPartOfType('Company', entityType);
    const entityText = isEntityCompanyType ? 'Company' : 'Individual';
    const auAsicNumText = `ASIC Appointment Number (${entityText})`;
    this.asicNumberText = this.isAU ? auAsicNumText : 'FSPR Number';
  }

  getAppointmentEntityDetail() {
    this.onboardingDataService
      .getOnboardingResponse(this.familyId, this.clientId)
      .then((response) => {
        if (!response || !response.RoleId) {
          return;
        }
        const isContractor = this.onboardingDataService.isOnboardingUserRole(
          response.RoleId,
          ONBOARDING_ROLE_ID.CONTRACTOR,
        );
        this.appointmentEntity = setBooleanToInt(isContractor);
      });
  }

  getAsicDetails() {
    this.onboardingService
      .onboardingAdviserOrganisationASICDetailsGet(this.familyId, this.clientId)
      .then(({ data }) => {
        if (!data) {
          return;
        }

        this.asicDetails = data;
        const hasAppointment = !!(data.ASICId || (this.isAU && data.ASICType));
        this.asicDetails.HasASICAppoinment = hasAppointment;

        return this.corporateService.recruitmentPreferencesGet(
          this.familyId,
          this.clientId,
        );
      })
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }
        const keepACL = data[0].KeepACL;
        this.keepACL = keepACL;
        this.asicDetails.keepACL = keepACL;
      });
  }

  updateAsicDetails(details) {
    const asicId = details.ASICId || '';
    this.onboardingService
      .asicAppointmentDetailsSet(
        this.adviserOrgId,
        0,
        details.ASICType,
        asicId,
        this.appointmentEntity,
      )
      .then(({ data }) => {
        if (data) {
          return;
        }
        toastError('Save of ASIC details failed!');
      });
  }

  updateHasASICAppointment() {
    if (!this.asicDetails.HasASICAppoinment) {
      this.asicDetails.ASICType = '';
      this.asicDetails.ASICId = '';
      this.updateAsicDetails(this.asicDetails);
    }
  }

  updateKeepACL() {
    const keepACLToPass = setBooleanToInt(this.keepACL);
    this.corporateService.recruitmentPreferencesSet(
      this.familyId,
      this.clientId,
      keepACLToPass,
    );
  }

  $onInit() {
    this.asicDetails = {};
    this.isAU = this.currentUserService.isAU;
    this.familyId = this.onboardingDataService.getFamilyId();
    this.clientId = this.onboardingDataService.getClientId();
    this.adviserOrgId = this.onboardingDataService.getAdviserOrgId();
    this.setTextValues();

    this.asicTypesList = this.onboardingDataService.getAsicType();
    this.getAppointmentEntityDetail();
    this.getAsicDetails();
  }
}

export default OnboardingAsicCtrl;
