import _ from 'lodash';
import { PHONECODE } from 'Common/constants/countryMobileNumberCode';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  AU_MOBILE_PATTERNS,
  NZ_MOBILE_PATTERNS,
  JP_MOBILE_PATTERNS,
} from 'Common/constants/pattern';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function isMobileValid(value) {
  if (!value) {
    return false;
  }
  const regex = /^(\+6|0)((\d)\d{7,10})$/;
  const match = value.match(regex);
  if (!match || !match.length) {
    return false;
  }
  if (match && match.length === 4) {
    const international = match[1].length === 2;
    const countryIdentifier = match[3];
    if (international) {
      if (
        countryIdentifier === PHONECODE.INTERNATIONAL_AU &&
        value.length === 12
      ) {
        return true;
      }
      if (
        countryIdentifier === PHONECODE.INTERNATIONAL_NZ &&
        _.inRange(value.length, 11, 14)
      ) {
        return true;
      }
    } else {
      if (
        PHONECODE.LOCAL_AU.find((item) => item === countryIdentifier) &&
        value.length === 10
      ) {
        return true;
      }
      if (
        countryIdentifier === PHONECODE.LOCAL_NZ &&
        _.inRange(value.length, 9, 12)
      ) {
        return true;
      }
    }
  }
  return false;
}

export function isMobileNumberValid(number) {
  if (!number) {
    return false;
  }

  const validMobilePatterns = {
    ...AU_MOBILE_PATTERNS,
    ...NZ_MOBILE_PATTERNS,
    ...JP_MOBILE_PATTERNS,
  };
  return Object.keys(validMobilePatterns).some((key) =>
    number.match(validMobilePatterns[key]),
  );
}

export function formatExistingMobile(number) {
  if (!number) {
    return number;
  }

  const leadingNumber = number.slice(0, 2);
  const australiaDialCode = '+61';
  const newZealandDialCode = '+64';

  switch (leadingNumber) {
    case '04':
    case '05':
      return `${australiaDialCode}${parseToInt10(number)}`;
    case '02':
      return `${newZealandDialCode}${parseToInt10(number)}`;
    default:
      return number;
  }
}
