import template from './mycrmConfirmation.html';
import controller from './mycrmConfirmationCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    modalTitle: '@',
    message: '@',
    confirmationTitle: '@',
    discardTitle: '@',
    onConfirm: '&',
  },
};
