import { viewOfAFileTheme } from 'Common/default/modal';
import { toastError } from 'Common/utilities/alert';
import { redirectToLoanFullPageView } from 'Assets/js/controllers/contacts/client/util/lendingDetail';

class LoanVariationModalCtrl {
  constructor($state, uiService, loanVariationsService) {
    'ngInject';

    this.$state = $state;
    this.uiService = uiService;
    this.loanVariationsService = loanVariationsService;
  }

  $onInit() {
    this.defaultTheme = viewOfAFileTheme;
    this.newLoanVariationModel = {
      topUpOrVariationTypeId: null,
      loanAppId: this.loanAppId,
      familyId: this.familyId,
      loanId: this.loanId,
    };
    this.getLoanVariationTypes();
  }

  getLoanVariationTypes() {
    return this.loanVariationsService.getTypes().then((data) => {
      this.loanVariationTypes = data;
    });
  }

  onCancel() {
    this.modalInstance.close();
  }

  getPayload() {
    if (
      !this.newLoanVariationModel.loanAppId ||
      !this.newLoanVariationModel.familyId ||
      !this.newLoanVariationModel.topUpOrVariationTypeId
    ) {
      toastError('Oops missing required parameter please select value');
      return;
    }

    return this.newLoanVariationModel;
  }

  onSave() {
    const payload = this.getPayload();
    if (!payload) {
      return;
    }
    this.isSaving = true;
    return this.loanVariationsService
      .createVariation(payload)
      .then((newLoanData = {}) => {
        const { loanId, loanAppId } = newLoanData;
        if (!loanId || !loanAppId) {
          return toastError();
        }
        this.modalInstance.close();

        const params = {
          loanId,
          loanAppId,
          familyId: this.familyId,
        };
        this.redirectToLoanPageOnSuccess(params);
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  redirectToLoanPageOnSuccess(params) {
    redirectToLoanFullPageView({
      viewOfAfileTurnedOn: this.uiService.viewOfAfileTurnedOn,
      $state: this.$state,
    })(params);
  }
}

export default LoanVariationModalCtrl;
