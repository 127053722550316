import template from './fatalErrorsList.html';
import controller from './fatalErrorsListCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    fatalErrorsData: '<',
  },
};
