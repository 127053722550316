export const VALID_HELLO_BOOK_CREATE_LOCATION = [
  'topnav-dropdown',
  'contacts-page',
];

export const INSURANCE_PACK_TYPE = {
  APPLICATION: 'Application',
  QUOTE: 'Quote',
};

export const PACK_MODE = {
  HELLO_PACK: 'Hello Pack',
  INSURANCE_APP: 'Insurance App',
  INSURANCE_PROFILER: 'Insurance Profiler',
  INVITE_IOFF: 'Insurance Online Fact Find',
};

export const LAUNCH_LOCATION = {
  INSURANCE_TAB: 'Insurance Tab',
};

export const GUIDE_TYPE = {
  AU: 'Credit Guide',
  NZ: 'Disclosure Guide',
};

export const SERVICE_OPTION = {
  LENDING: 'Lending',
  INSURANCE: 'Insurance',
  PRE_VERIFY_CLIENT_SENDING_ONLY: 'Client Sending',
};

export const HELLO_PACK_CHAR_LIMIT = 5000;
export const FILE_ATTACHMENT_LIMIT = 3;

export const DOCUMENT_REQUEST_METHOD_ID = {
  FILE_INVITE: 1,
  FACT_FIND: 2,
};
export const DOCUMENT_REQUEST_METHOD_FILE_INVITE = {
  value: DOCUMENT_REQUEST_METHOD_ID.FILE_INVITE,
  name: 'Send client a File Invite request',
  methodName: 'file_invite',
};
export const DOCUMENT_REQUEST_METHOD_FACT_FIND = {
  value: DOCUMENT_REQUEST_METHOD_ID.FACT_FIND,
  name: 'Client uploads to Fact Find',
  methodName: 'fact_find',
};
export const DOCUMENT_REQUEST_METHODS = [
  DOCUMENT_REQUEST_METHOD_FILE_INVITE,
  DOCUMENT_REQUEST_METHOD_FACT_FIND,
];

export const DOCUMENT_REQUEST_METHOD_ID_DEFAULT =
  DOCUMENT_REQUEST_METHOD_ID.FACT_FIND;

export const DEFAULT_LM_LOGO_PATH = './assets/images/helloBook/defaultLogo.svg';
export const DEFAULT_INSURANCE_LOGO_PATH =
  './assets/images/defaultBrandingLogo/insurance-market-logo.svg';
export const COMMON_PLACEHOLDER = '{First Name}';

export const SEND_HELLO_BOOK_TYPE = {
  NOW: 'now',
  PREVIEW: 'preview',
  SAVE: 'save',
};

export const FACT_FIND_TYPE = {
  FULL: 'full',
  PARTIAL: 'partial',
};

export const HELLO_PACK_KEY = {
  DEFAULT: 'helloPack',
  WIH_CREDIT_GUIDE: 'helloBookWithCreditGuide',
  CREDIT_GUIDE_ONLY: 'creditGuide',
};

export const HELLO_PACK_OPTIONS_TOGGLE = {
  CREDIT_GUIDE: 'isCreditEnabled',
};

export const MODAL_SERVICE = {
  lending: 'Lending',
  insurance: 'Insurance',
};
