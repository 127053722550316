import angular from 'angular';
import { PROVIDER_LOGO } from 'Common/constants/providerLogo';

export default class LenderLogoCtrl {
  constructor(uiService, legacyAngularHost, $element) {
    'ngInject';

    this.uiService = uiService;
    this.legacyAngularHost = legacyAngularHost?.value || '';
    this.$element = $element;
    this.onLoadSrcFailed = this.onLoadSrcFailed.bind(this);
  }

  $onInit() {
    this.imgElement = angular.element(this.$element.children()[0]);
    this.imgElement.on('error', this.onLoadSrcFailed);
  }

  $onChanges(changes) {
    // to be deleted after migrating all logos to src
    const { src, lenderId, countryCode, forceRenderSrc } = changes;
    if (lenderId && lenderId.currentValue) {
      this.lenderId =
        PROVIDER_LOGO[lenderId.currentValue] || lenderId.currentValue;
    }
    if (countryCode && countryCode.currentValue) {
      this.countryCode = countryCode.currentValue;
    }
    if (
      (this.uiService.lenderLogoMigration || forceRenderSrc) &&
      src &&
      src.currentValue
    ) {
      this._src = src.currentValue;
    }
    this.imageSrc = this._src || this.createLocalImageSrc();
  }

  $onDestroy() {
    this.imgElement.off('error', this.onLoadSrcFailed);
  }

  // to be deleted after migrating all logos to src
  createLocalImageSrc() {
    const { countryCode, lenderId, shownOnError } = this;
    const _countryCode = countryCode || this.uiService.getCountryCode();
    const imgDir = `assets/images`;
    const defaultImgSrc = `${imgDir}/lenderLogos/default.png`;
    const lenderLogoDir = this.isLarge ? 'lenderLogoLg' : 'lenderLogos';
    const screenType = this.isLarge ? '@2x' : '';
    const lenderLogo = this.isBordered
      ? [imgDir, 'insurers/bordered', lenderId].join('/')
      : [imgDir, lenderLogoDir, _countryCode, lenderId].join('/');
    const isLenderLogo =
      (lenderId || lenderId > 0 || shownOnError) && _countryCode;
    const lenderLogoSrc = `${lenderLogo.trim()}${screenType}.png`;
    return isLenderLogo ? lenderLogoSrc : defaultImgSrc;
  }

  onLoadSrcFailed() {
    if (this.showAltOnError) {
      this.isFailure = true;
    }
  }
}
