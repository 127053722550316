import { COMPANY_TYPES } from 'Common/constants/partyTypes';

const TRUST_TYPES = [
  { value: COMPANY_TYPES.COMPANY, label: COMPANY_TYPES.COMPANY_STRING },
  { value: COMPANY_TYPES.TRUST, label: COMPANY_TYPES.TRUST_STRING },
];

export default class NZbnPartyTypeFieldsCtrl {
  $onInit() {
    this.selectedTrust = this.organisationType
      ? this.setType()
      : TRUST_TYPES[1];

    this.trustTypes = TRUST_TYPES;
  }

  setType() {
    return TRUST_TYPES.filter(
      (type) => type.label === this.organisationType,
    )[0];
  }
}
