import chunk from 'lodash/chunk';

export default class CommissionByBrokerCtrl {
  $onChanges(changes) {
    const { slickData } = changes;
    if (slickData && slickData.currentValue) {
      this.structureSlickData();
    }
  }

  structureSlickData() {
    const MAX_SLICK_ROW = 4;
    this.structuredData = chunk(this.slickData, MAX_SLICK_ROW);
  }
}
