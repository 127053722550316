export const loadModule = () =>
  app.factory('onboardingService', [
    '$q',
    'onboardingModel',
    function ($q, onboardingModel) {
      var factory = this;
      var onboarding = new onboardingModel();

      factory.getOnboardingAdviserOrganisationDetails = function (
        familyId,
        clientId,
      ) {
        var defer = $q.defer();

        onboarding.getOnboardingAdviserOrganisationDetails(
          familyId,
          clientId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.setRecruitmentAdviserOrganization = function (data) {
        var defer = $q.defer();

        onboarding.setRecruitmentAdviserOrganization(
          data,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.setRecruitmentOrganizationAddress = function (
        familyId,
        organizationId,
        orgComplianceId,
        addressId,
        isMailing,
      ) {
        var defer = $q.defer();

        onboarding.setRecruitmentOrganizationAddress(
          familyId,
          organizationId,
          orgComplianceId,
          addressId,
          isMailing,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.getRecruitmentContact = function (familyId, clientId) {
        var defer = $q.defer();

        onboarding.getRecruitmentContact(
          familyId,
          clientId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.getCheckIfEmailExists = function (email, familyId) {
        var defer = $q.defer();

        onboarding.getCheckIfEmailExists(
          email,
          familyId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.setRecruitmentContact = function (familyId, data) {
        var defer = $q.defer();

        onboarding.setRecruitmentContact(
          familyId,
          data,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };
      factory.onboardingAdviserOrganisationASICDetailsGet = function (
        familyId,
        clientId,
      ) {
        var defer = $q.defer();

        onboarding.OnboardingAdviserOrganisationASICDetailsGet(
          familyId,
          clientId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };
      factory.asicAppointmentDetailsSet = function (
        adviserOrganizationId,
        complianceId,
        AsicType,
        AsicId,
        appointmentEntity,
      ) {
        var defer = $q.defer();
        onboarding.AsicAppointmentDetailsSet(
          adviserOrganizationId,
          complianceId,
          AsicType,
          AsicId,
          appointmentEntity,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };
      factory.bankAccountDetailsGet = function (familyId) {
        var defer = $q.defer();

        onboarding.BankAccountDetailsGet(
          familyId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.bankAccountDetailsSet = function (familyId, data) {
        var defer = $q.defer();
        onboarding.BankAccountDetailsSet(
          familyId,
          data,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.sendProcessCompletedEmailSet = function (familyId) {
        var defer = $q.defer();
        onboarding.SendProcessCompletedEmailSet(
          familyId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.questionaireListGet = function (familyId) {
        var defer = $q.defer();

        onboarding.QuestionaireListGet(
          familyId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };
      factory.questionaireAnswersGet = function (familyId) {
        var defer = $q.defer();
        onboarding.QuestionaireAnswersGet(
          familyId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.questionaireAnswersSet = function (
        familyId,
        questionNumbers,
        yesOrNoAnswers,
        additionalAnswers,
      ) {
        var defer = $q.defer();

        onboarding.QuestionaireAnswersSet(
          familyId,
          questionNumbers,
          yesOrNoAnswers,
          additionalAnswers,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.getApplicationChecklist = function (familyId) {
        var defer = $q.defer();

        onboarding.getApplicationChecklist(
          familyId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.setApplicationChecklist = function (
        familyId,
        recruitmentChecklistId,
        documentId,
      ) {
        var defer = $q.defer();

        onboarding.setApplicationChecklist(
          familyId,
          recruitmentChecklistId,
          documentId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.uploadDocument = function (data, documentId) {
        var defer = $q.defer();

        onboarding.uploadDocument(
          data,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.getAdviserAssistants = function (familyId) {
        var defer = $q.defer();

        onboarding.getAdviserAssistants(
          familyId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.getFamilyLenderAccreditation = function (familyId) {
        var defer = $q.defer();

        onboarding.getFamilyLenderAccreditation(
          familyId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.setFamilyLenderAccreditation = function (familyId, data) {
        var defer = $q.defer();

        onboarding.setFamilyLenderAccreditation(
          familyId,
          data,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.familyPartnershipSet = function (
        familyId,
        partnerFamilyId,
        partnerClientId,
        isWriting,
        isBusinessPartner,
        isCompanyDirector,
        isShareHolder,
        isActive,
        familyPartnershipId,
      ) {
        var defer = $q.defer();

        onboarding.FamilyPartnershipSet(
          familyId,
          partnerFamilyId,
          partnerClientId,
          isWriting,
          isBusinessPartner,
          isCompanyDirector,
          isShareHolder,
          isActive,
          familyPartnershipId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      factory.getFamilyBrandingCategory = function (familyId, clientId) {
        var defer = $q.defer();

        onboarding.getFamilyBrandingCategory(
          familyId,
          clientId,
          (response) => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          (error) => {
            factory.data = [];
            return defer.reject(error);
          },
        );
        return defer.promise;
      };

      return factory;
    },
  ]);
