import template from './calendarMini.html';
import './style.scss';

export default {
  template,
  bindings: {
    monthNumber: '<',
    monthName: '<',
    day: '<',
  },
};
