export const REFERRAL_STRINGS = ['Referral'];

export const REFERRAL_ITEM = {
  REFERRAL: -1,
  RAYWHITE_LOAN_CALCULATOR: -42,
  REFERRAL_EXISTING_CLIENT: -37,
  PERSONAL_CONTACT: -17,
  FAMILY_AND_FRIENDS: -16,
  OTHER_MARKETING_ACTIVITY: -15,
  OTHER: -18,
  MY_LEAD_GENERATOR: -29,
  MY_LEAD_GENERATOR_BYOB: -38,
  BROKER_WEBSITE_BYOB: -39,
  BROKER_WEBSITE_EBOOK: -40,
};

export const LM_WEBSITE_ITEMS = {
  LM_CONTACT_US: -8,
  LM_ONLINE_MEMBER: -9,
  INBOUND_CALL: -10,
};

export const CLIENT_REFERRAL = [REFERRAL_ITEM.REFERRAL_EXISTING_CLIENT];
export const ADDITIONAL_REFERRAL = [
  REFERRAL_ITEM.PERSONAL_CONTACT,
  REFERRAL_ITEM.FAMILY_AND_FRIENDS,
  REFERRAL_ITEM.OTHER_MARKETING_ACTIVITY,
  REFERRAL_ITEM.OTHER,
];
export const REFERRED_BY_ACCESS = [
  REFERRAL_ITEM.REFERRAL,
  REFERRAL_ITEM.RAYWHITE_LOAN_CALCULATOR,
];

export const LM_WEBSITE_ENQUIRY = [
  LM_WEBSITE_ITEMS.LM_CONTACT_US,
  LM_WEBSITE_ITEMS.LM_ONLINE_MEMBER,
  LM_WEBSITE_ITEMS.INBOUND_CALL,
];

export const CATEGORY = {
  REFERRAL: -1,
  AFFILIATE: -2,
  WEBSITE: -3,
};

export const DEFAULT_LEAD_BILLING = 1;

export const HAS_LEADBILLING_CATEGORY = [CATEGORY.AFFILIATE, CATEGORY.WEBSITE];
