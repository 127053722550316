export function typeBuilderForUI(result) {
  return {
    name: result.Name,
    value: result.Value,
  };
}

export function typeIntBuilderForUI(result) {
  return {
    name: result.Name,
    value: parseInt(result.Value, 10),
  };
}

export function optionBuilderForUI(result) {
  return {
    name: result.Name,
    value: result.Value,
    isCheked: result.IsCheked,
  };
}

export function optionBuilderForMyCRM(result) {
  return {
    Name: result.name,
    Value: result.value,
    IsCheked: result.isCheked,
  };
}

export function recipientBuilderForMyCRM(recipient) {
  return {
    Name: recipient.name,
    EmailAddress: recipient.emailAddress,
  };
}

export function marketingAutomationBuilderForUI(data) {
  return {
    price: data.Price,
    description: data.Description,
    name: data.Name,
    value: data.Value,
    isAvailable: data.IsAvailable,
  };
}

export function financeOptionsBuilderForUI(data) {
  return {
    assetType: data.AssetType,
    assetVariant: data.AssetVariant,
    entityType: data.EntityType,
    quotePurpose: data.QuotePurpose,
    quoteType: data.QuoteType,
  };
}

export function filterMarketingAutomation(feature) {
  return (data) => {
    return !(
      !feature.preApprovalSITCampaign && data.Name === 'Pre-approval Sequence'
    );
  };
}
