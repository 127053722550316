import moment from 'moment';
import { isFunction } from 'lodash';
import { formatAddress } from 'Common/utilities/address';
import { DD_MMMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';
import {
  INPUT_DATE_FORMAT,
  DATE_MODEL_OPTIONS,
} from 'Common/constants/workbench';
import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';
import { toastError } from 'Common/utilities/alert';

class CreditChecksListCtrl {
  constructor(
    creditChecksService,
    generalService,
    timeoutService,
    NgTableParams,
    uiService,
    $timeout,
    $window,
  ) {
    'ngInject';

    this.creditChecksService = creditChecksService;
    this.generalService = generalService;
    this.timeoutService = timeoutService;
    this.NgTableParams = NgTableParams;
    this.uiService = uiService;
    this.$timeout = $timeout;
    this.$window = $window;
  }

  $onInit() {
    this.dateSettings = {
      timezone: DATE_MODEL_OPTIONS.timezone,
      format: this.isAdviserView ? DD_MMMM_YYYY_FORMAT : INPUT_DATE_FORMAT,
    };

    this.statusCheckInterval = 25000;

    this.initTableParams();

    if (isFunction(this.onInitialized)) {
      this.api = {};
      this.api.refresh = () => {
        this.shouldShowLoading = false;
        if (this.tableParams.page() === 1) {
          this.tableParams.reload();
        } else {
          this.tableParams.page(1);
        }
      };
      this.onInitialized({ api: this.api });
    }
    this.creditDocsObj = {};
  }

  setCreditToDocument(creditCheckId) {
    if (!this.familyId || !creditCheckId) {
      return;
    }
    this.creditDocsObj[creditCheckId] = true;
    this.creditChecksService
      .setCreditToDocument(this.familyId, creditCheckId)
      .then(() => {
        this.creditDocsObj[creditCheckId] = false;
        this.tableParams.reload();
      })
      .catch(() => {
        this.creditDocsObj[creditCheckId] = false;
        toastError(`There's an error while submitting credit check.`);
      });
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts(WATCHER_CONTEXT.CREDIT_CHECKS_LIST_CTRL);
  }

  initTableParams() {
    this.pageSize = 14;
    this.shouldShowLoading = true;
    this.tableParams = new this.NgTableParams(
      {
        page: 1,
        count: this.pageSize,
      },
      {
        total: 0,
        getData: (params) => {
          const pageNumber = params.page();
          const { pageSize, familyId } = this;
          const queryParams = { pageNumber, pageSize, familyId };
          this.isLoadingList = this.shouldShowLoading;
          this.timeoutService.cancelTimeouts(
            WATCHER_CONTEXT.CREDIT_CHECKS_LIST_CTRL,
          );
          return this.creditChecksService.getCreditChecks(queryParams).then(
            (response) => {
              const data = response && response.data;
              this.formatCreditChecksData(data);
              this.creditChecks = data;
              this.tableParams.total(this.getTotalRecords());
              this.tableParams.currentPage = this.tableParams.page();
              this.isLoadingList = false;
              this.shouldShowLoading = true;
              return this.creditChecks;
            },
            () => {
              this.tableParams.total(0);
              this.isLoadingList = false;
              this.shouldShowLoading = true;
              return [];
            },
          );
        },
      },
    );
  }

  formatCreditChecksData(creditChecks) {
    creditChecks &&
      creditChecks.forEach((creditCheck) => {
        const { ErrorReason, EquiFaxEnquiryID } = creditCheck;
        if (creditCheck.DateOfBirth) {
          creditCheck.DateOfBirth = moment(creditCheck.DateOfBirth).toDate();
        }
        if (creditCheck.DateSent) {
          creditCheck.DateSent = moment(creditCheck.DateSent).toDate();
        }

        creditCheck.FormattedAddress = formatAddress(creditCheck);
        const isSubmittedToday = moment(creditCheck.DateSent).isSame(
          moment(),
          'day',
        );
        creditCheck.isGenerating =
          !EquiFaxEnquiryID && !ErrorReason && isSubmittedToday;
        creditCheck.isFailed =
          ErrorReason || (!isSubmittedToday && !EquiFaxEnquiryID);
        const shouldCheckStatus = creditCheck.isGenerating && isSubmittedToday;
        if (shouldCheckStatus) {
          this.startStatusCheckerFor(creditCheck);
        }
        creditCheck.isStatusProcessing =
          !creditCheck.isGenerating &&
          !creditCheck.isDownloading &&
          !ErrorReason &&
          EquiFaxEnquiryID;
      });
  }

  startStatusCheckerFor(creditCheck) {
    creditCheck.generationStatusChecker = this.$timeout(
      (checkedCreditCheck) => {
        if (checkedCreditCheck.isGenerating) {
          this.checkGenerationStatus(checkedCreditCheck);
        } else {
          this.cancelStatusChecking(checkedCreditCheck);
        }
      },
      this.statusCheckInterval,
      true,
      creditCheck,
    );

    this.timeoutService.addTimeout(
      creditCheck.generationStatusChecker,
      WATCHER_CONTEXT.CREDIT_CHECKS_LIST_CTRL,
    );
  }

  cancelStatusChecking(creditCheck) {
    this.timeoutService.cancelTimeout(
      WATCHER_CONTEXT.CREDIT_CHECKS_LIST_CTRL,
      creditCheck.generationStatusChecker,
    );
    creditCheck.generationStatusChecker = null;
  }

  checkGenerationStatus(creditCheck) {
    const { CreditCheckID: creditCheckID } = creditCheck;
    this.creditChecksService
      .getCreditChecks({ creditCheckID })
      .then((response) => {
        if (!response) {
          return;
        }

        const { data } = response;
        if (!data || !data.length) {
          return;
        }

        this.cancelStatusChecking(creditCheck);
        this.formatCreditChecksData(data);
        const updatedCreditCheck = data[0];
        Object.assign(creditCheck, updatedCreditCheck);
      });
  }

  getTotalRecords() {
    if (!this.creditChecks || !this.creditChecks.length) {
      return 0;
    }

    const firstItem = this.creditChecks[0];
    return (firstItem && firstItem.TotalRecords) || 0;
  }

  viewCreditCheck(creditCheck) {
    const { CreditCheckID } = creditCheck;
    if (!CreditCheckID) {
      return;
    }

    creditCheck.isDownloading = true;
    this.creditChecksService.getPdfReport(CreditCheckID).then(
      (data) => {
        const eventTimeout = this.$timeout(() => {
          creditCheck.isDownloading = false;
          if (!data) {
            return;
          }

          const { documentContent, contentType, name } = data;
          const blob = this.convertBase64ToBlob(documentContent, contentType);
          const filePath = this.$window.URL.createObjectURL(blob);
          const a = this.$window.document.createElement('a');

          a.href = filePath;
          a.download = name || 'CreditCheck.pdf';
          a.click();
          this.$timeout.cancel(eventTimeout);
        });
      },
      () => {
        creditCheck.isDownloading = false;
      },
    );
  }

  convertBase64ToBlob(base64, contentType) {
    if (!base64 || !contentType) {
      return;
    }

    const byteString = this.$window.atob(base64);
    const buffer = new ArrayBuffer(byteString.length);
    const content = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
      content[i] = byteString.charCodeAt(i);
    }

    return new this.$window.Blob([content], { type: contentType });
  }
}

export default CreditChecksListCtrl;
