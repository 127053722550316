import { pluralize } from 'Common/utilities/string';

export const INFO_EXPIRATION_LIMIT = {
  unit: 90,
  frequency: 'day',
};

export const INFO_EXPIRATION_MSG = `This information is more than ${pluralize(
  INFO_EXPIRATION_LIMIT.unit,
  INFO_EXPIRATION_LIMIT.frequency,
)} old please confirm if all is still correct.`;
