import {
  IMPORTANT_DOCUMENT_TAB,
  SEARCH_URL_VALUE,
} from 'Common/constants/importantDocuments';

export default class ImportantDocumentsCtrl {
  constructor(loanToolsService, stateService, $stateParams, $state) {
    'ngInject';

    this.loanToolsService = loanToolsService;
    this.stateService = stateService;
    this.$stateParams = $stateParams;
    this.$state = $state;
  }

  getImportantDocuments(isOverrideLoading) {
    this.isLoadingDocuments = !isOverrideLoading;
    this.isTableReloaded = false;
    this.loanToolsService.getImportantDocs().then((data) => {
      this.isLoadingDocuments = false;
      this.isTableReloaded = true;
      this.importantDocumentList = data || [];
    });
  }

  setActiveUrl(urlTabValue) {
    this.$state.params = {
      activeTab: urlTabValue,
    };
    if (urlTabValue === SEARCH_URL_VALUE) {
      this.$state.params.s = this.searchText;
    }
    this.stateService.transition(this.$state.current, this.$state.params);
  }

  searchDocuments() {
    if (!this.searchText) {
      return;
    }
    this.lastActiveTab = this.activeTab;
    this.activeTab = -1;
    this.showSearchDocument = true;
    this.isSearching = true;
    this.showNoResult = false;
    this.setActiveUrl(SEARCH_URL_VALUE);
    const params = {
      searchString: this.searchText,
    };
    this.loanToolsService.getImportantDocs(params).then((data) => {
      this.isSearching = false;
      this.importantDocumentSearchList = [];
      if (!data || !data.length) {
        this.showNoResult = true;
        return;
      }
      this.importantDocumentSearchList = data;
    });
  }

  onChangeTab(tab) {
    if (!tab) {
      return;
    }
    if (this.showSearchDocument) {
      this.getImportantDocuments(this.showSearchDocument);
    }
    this.showSearchDocument = false;
    this.searchText = '';
    this.setActiveUrl(tab.urlValue);
  }

  onChangeSelect(tabIndex = -1) {
    if (tabIndex < 0) {
      return;
    }
    const currentTab = IMPORTANT_DOCUMENT_TAB.find(
      (tab) => tab.index === tabIndex,
    );
    this.onChangeTab(currentTab);
  }

  clearSearch() {
    this.searchText = '';
  }

  onDeleteDocumentItem({ updatedDocuments = [] }) {
    this.importantDocumentList = [...updatedDocuments];
  }

  $onInit() {
    this.isTableReloaded = true;
    const currentTab = IMPORTANT_DOCUMENT_TAB.find(
      (tab) => tab.urlValue === this.$stateParams.activeTab,
    );
    if (currentTab && currentTab.index >= 0) {
      this.activeTab = currentTab.index;
    } else {
      this.activeTab = -1;
      this.showSearchDocument = true;
      if (!this.$stateParams.s) {
        return;
      }
      this.searchText = unescape(this.$stateParams.s);
      this.searchDocuments();
    }
    this.importantDocumentTabs = IMPORTANT_DOCUMENT_TAB;
    this.getImportantDocuments();
  }
}
