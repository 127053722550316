export const loadModule = () =>
  app.factory('imageDataURI', () => {
    return function imageDataURI(url, callback) {
      var image = new Image();
      image.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;
        canvas.getContext('2d').drawImage(this, 0, 0);
        callback(canvas.toDataURL('image/png'));
      };
      image.src = url;
    };
  });
