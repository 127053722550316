import angular from 'angular';
import onboardingAuthorityAndConsent from './onboardingAuthorityAndConsent';
import onboardingAuthorityAndConsentSummary from './onboardingAuthorityAndConsentSummary';
import './style.scss';

export default angular
  .module('components.onboardingAuthorityAndConsent', [])
  .component('onboardingAuthorityAndConsent', onboardingAuthorityAndConsent)
  .component(
    'onboardingAuthorityAndConsentSummary',
    onboardingAuthorityAndConsentSummary,
  ).name;
