import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    .controller('CommunicateCtrl', function CommunicateCtrl(
      $scope,
      $rootScope,
      $http,
      SweetAlert,
      $filter,
      imageDataURI,
      $timeout,
      $window,
      $templateCache,
      communicateService,
      colorService,
    ) {
      $scope.getRandomBg = () => {
        return colorService.getRandomColor();
      };

      /** ****************************************************************** */

      angular.element($window).bind('resize', () => {
        $scope.windowWidth = $window.innerWidth;
      });

      $scope.$watch(
        () => {
          $scope.windowWidth = $window.innerWidth;
          if ($scope.windowWidth > 992) {
            $scope.isMobileDisplayView = false;
          } else if ($scope.windowWidth <= 992) {
            $scope.isMobileDisplayView = true;
          }
          return $scope.windowWidth;
        },
        () => {
          return $scope.windowWidth;
        },
        true,
      );

      /** ****************************************************************** */
      $scope.emailCampaignList = {};
      communicateService.getCampaignReports().then((response) => {
        if (response.data && !response.data.isError) {
          const { data } = response;
          $scope.emailCampaignList = data;
          $scope.emailCampaignList = _.omit($scope.emailCampaignList, [
            'ErrorDescription',
            'isError',
          ]);

          $scope.campaignStartedVal = 0;
          communicateService.numberCampaignStarted().then((res) => {
            $scope.campaignStartedVal = res.data;
          });

          $scope.numberSentEmailsVal = 0;
          communicateService.numberSentEmails().then((resp) => {
            $scope.numberSentEmailsVal = resp.data;
          });
        }
      });

      /** ****************************************************************** */
    });
