export const activeFactFindCrmConfirmation = ({
  crmConfirmation,
  deleteAction,
  crmOptionSettings,
}) => {
  crmConfirmation.open(crmOptionSettings).result.then(() => {
    deleteAction();
  });
};

export const inactiveFactFindSweetAlert = ({
  swal,
  deleteAction,
  swalOptionSettings,
}) => {
  swal(swalOptionSettings, (confirm) => {
    if (confirm) {
      deleteAction();
    }
  });
};
