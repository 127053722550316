import template from './partyType.html';
import partyTypeCtrl from './partyTypeCtrl';

export default {
  template,
  controller: partyTypeCtrl,
  controllerAs: 'vm',
  bindings: {
    partyType: '<',
    trustTypes: '<',
    onTypeChange: '&',
    formReference: '<',
    showBetterEntity: '<',
  },
};
