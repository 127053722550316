import { toastSuccess } from 'Common/utilities/alert';

export const saveEmailSignature = ({
  $scope,
  generalService,
  broadcastDataService,
}) => {
  const wrapSignatureContent = `<div style='display:inline-block; vertical-align: top; margin-left: 0px;'>${$scope.signatureContent}</div>`;
  const verticalLine = '<div></div>';
  const signatureAndVerticalLine = verticalLine + wrapSignatureContent;
  const finalSignatureForm = `<div>${signatureAndVerticalLine}</div>`;

  const data = {
    familyId: $scope.loginUseInfo.BrokerId,
    emailSignature: finalSignatureForm,
  };
  generalService.setEmailSignature(data).then(() => {
    toastSuccess('Signature successfully set');
    $scope.email.Content = `<p> </br>${finalSignatureForm}</br></p>`;
    broadcastDataService.userSignature = finalSignatureForm;
  });
};
