import angular from 'angular';

export const adjustHeight = (scope, element) => () => {
  if (!scope.identifier) {
    return;
  }
  const selector = `.${scope.identifier}`;
  const siblings = element &&
    element.length && [...element[0].querySelectorAll(selector)];

  if (!siblings) {
    return;
  }
  let maxHeight = 0;
  siblings.forEach((node) => {
    angular.element(node).css('height', `auto`);
    if (node.clientHeight > maxHeight) {
      maxHeight = node.clientHeight;
    }
  });

  if (maxHeight === 0) {
    return;
  }

  siblings.forEach((node) => {
    angular.element(node).css('height', `${maxHeight}px`);
  });
};
