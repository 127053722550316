import { addressLookup } from 'Common/mappers/address';

class CompanyTrustModel {
  formatCompanyAddress(isAddressOverhaulEnabled) {
    if (!this.data.Address || !this.data.Address.length) {
      return;
    }
    const formattedAddress = {
      ...addressLookup(this.data.Address[0]),
      displayDetails: true,
    };
    const address = isAddressOverhaulEnabled
      ? formattedAddress
      : this.data.Address[0];
    this.data.Address = { ...address };
    this.data.FullAddress = { ...address };
  }

  getCompanyTrustModel(entity, isAddressOverhaulEnabled = false) {
    this.data = {
      OrganisationId: '',
      Name: '',
      FamilyId: '',
      OrganisationType: '',

      Phone: [
        {
          Type: 'Work',
          Number: '',
        },
      ],
      Address: [{ AddressID: 0 }],
      Email: [
        {
          Type: 'Email',
          EmailAddress: '',
        },
      ],

      DateIncorporated: '',
      TrusteeName: '',
      TrustBeneficiary: [],

      CompanyTypeId: 1,
      ABN: '',
      ACN: '',
      ABBN: '',
      companyNZBN: '',
      NatureOfBusiness: '',
    };

    if (entity) {
      this.data = { ...entity };
      this.data.CompanyTypeId = !entity.CompanyTypeId
        ? 1
        : entity.CompanyTypeId;
      this.data.DateIncorporated = entity.DateIncorporated
        ? new Date(entity.DateIncorporated)
        : '';
      this.data.GSTRegistrationDate = entity.GSTRegistrationDate
        ? new Date(entity.GSTRegistrationDate)
        : '';

      this.data.IndustryExperienceStartDate = entity.IndustryExperienceStartDate
        ? new Date(entity.IndustryExperienceStartDate)
        : '';

      this.data.TradingStarDate = entity.TradingStarDate
        ? new Date(entity.TradingStarDate)
        : '';

      this.data.Email = [
        {
          Type: 'Email',
          EmailAddress: entity.Email[0] ? entity.Email[0].EmailAddress : '',
        },
      ];

      this.data.Phone = [
        {
          Type: 'Work',
          Number: entity.Phone[0] ? entity.Phone[0].Number : '',
        },
      ];

      this.data.chosenCompany = { Id: entity.CompanyTypeId };
      this.formatCompanyAddress(isAddressOverhaulEnabled);
      this.data.Directors = this.getCompanyDirectors();
    }

    return this.data;
  }

  getCompanyDirectors() {
    const { Directors: directors } = this.data;
    if (!directors) {
      const hasOldDirectorData = !!this.data.Director;
      if (hasOldDirectorData) {
        const { Director, Shares } = this.data;
        return [{ Director, Shares }];
      }
      return [];
    }
    return directors;
  }

  getTrustSubmitModel(entity) {
    this.data = entity;
    const {
      OrganisationId,
      Name,
      FamilyId,
      OrganisationType,
      Phone,
      Email,
      Address,
      DateIncorporated,
      TrustBeneficiary,
      TrusteeName,
      ABN,
      GSTRegistrationDate,
      IndustryExperienceStartDate,
      IsGSTRegistered,
      TradingStarDate,
      TrusteeTypeId,
    } = this.data;

    return {
      OrganisationId,
      Name,
      FamilyId,
      OrganisationType,
      Phone,
      Address,
      Email,
      DateIncorporated,
      TrustBeneficiary,
      TrusteeName,
      ABN,
      GSTRegistrationDate,
      IndustryExperienceStartDate,
      IsGSTRegistered,
      TradingStarDate,
      TrusteeTypeId,
    };
  }

  getPartnershipSubmitModel(entity) {
    this.data = entity;
    const {
      OrganisationId,
      Name,
      FamilyId,
      OrganisationType,
      Phone,
      Email,
      Address,
      ABN,
      NatureOfBusiness,
      GSTRegistrationDate,
      IndustryExperienceStartDate,
      IsGSTRegistered,
      TradingStarDate,
      PartnershipTypeId,
    } = this.data;

    return {
      OrganisationId,
      Name,
      FamilyId,
      OrganisationType,
      Phone,
      Address: [{ ...Address }],
      Email,
      ABN,
      NatureOfBusiness,
      GSTRegistrationDate,
      IndustryExperienceStartDate,
      IsGSTRegistered,
      TradingStarDate,
      PartnershipTypeId,
    };
  }

  getCompanySubmitModel(entity) {
    this.data = entity;
    const {
      OrganisationId,
      Name,
      FamilyId,
      OrganisationType,
      Phone,
      Email,
      Address,
      CompanyTypeId,
      ABN,
      ACN,
      ABBN,
      companyNZBN,
      OrganisationCompanyNumber,
      NatureOfBusiness,
      Directors: directors,
      GSTRegistrationDate,
      IndustryExperienceStartDate,
      IsGSTRegistered,
      TradingStarDate,
    } = this.data;
    const NZBN = companyNZBN.toString() || '';
    return {
      OrganisationId,
      Name,
      FamilyId,
      OrganisationType,
      Phone,
      Email,
      Address: [{ ...Address }],
      CompanyTypeId,
      ABN,
      ACN,
      ABBN,
      NZBN,
      OrganisationCompanyNumber,
      NatureOfBusiness,
      Directors: directors && directors.filter((director) => director.Director),
      GSTRegistrationDate,
      IndustryExperienceStartDate,
      IsGSTRegistered,
      TradingStarDate,
    };
  }
}

export default CompanyTrustModel;
