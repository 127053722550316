import moment from 'moment';
import { D_MMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';

export default (() => {
  return {
    pickerCtrl: (vm) => {
      vm.customDateRangeOptions = {
        [vm.tab.id]: {
          dateActiveRangeIndex: 0,
          isCustomRange: false,
          dateRangeDefaults: {
            dateStart: moment().format(D_MMM_YYYY_FORMAT),
            dateEnd: moment().format(D_MMM_YYYY_FORMAT),
          },
        },
      };
      vm.vmAView = (tabId) => (props = '') => {
        return vm.customDateRangeOptions[tabId][props];
      };
      vm.vmApply = (tabId) => (props = '') => {
        return {
          newValue: (newValue) =>
            (vm.customDateRangeOptions[tabId][props] = newValue),
        };
      };
    },
  };
})();
