class CustomerCareTabsService {
  constructor($uibModal, currentUserService, configService) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.userInfo = currentUserService;
    this.configService = configService;
  }

  $onInit() {
    this.reloadCustomerCareFixedRateValues = () => {};
    this.reloadCustomerCarePreApprovalValues = () => {};
    this.reloadCustomerCareInterestValues = () => {};
    this.reloadCustomerCareBirthdayValues = () => {};
    this.reloadCustomerCareAnnualValues = () => {};
    this.reloadCustomerCarePostSettlementValues = () => {};
  }

  openSendEmailModal(props) {
    return this.$uibModal.open({
      template: `<customer-care-send-email
                  modal-instance="vm.modalInstance"
                  adviser-list="vm.props.adviserList"
                  table-data="vm.props.tableData" 
                  single-data="vm.props.singleData" 
                  is-insurance="vm.props.isInsurance" 
                  tab="vm.props.tab"
                >
                </customer-care-send-email>`,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'customer-care-send-email',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
  }

  isBulkEmailActive() {
    return this.userInfo.isNZ
      ? this.configService.feature.customerCareBulkEmailNZ
      : this.configService.feature.customerCareBulkEmailAU;
  }
}

export default CustomerCareTabsService;
