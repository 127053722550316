import angular from 'angular';

export const loadModule = () =>
  angular.module('app').controller('EmailManagementCtrl', [
    '$scope',
    '$uibModalInstance',
    function EmailManagementCtrl($scope, $uibModalInstance) {
      $scope.$uibModalInstance = $uibModalInstance;
    },
  ]);
