export function creditCheckPdfReportBuilderForUI(data) {
  return {
    contentType: data.ContentType,
    documentContent: data.DocumentContent,
    documentExists: data.DocumentExists,
    documentKey: data.DocumentKey,
    imageUploadType: data.ImageUploadType,
    isSvg: data.IsSvg,
    name: data.Name,
  };
}

export function creditCheckAddress(data) {
  return (
    (data && {
      unit: data.subpremise,
      streetNumber: data.street_number,
      streetName: data.streetName,
      suburb: data.locality,
      state: data.administrative_area_level_1,
      postCode: data.postal_code,
    }) ||
    {}
  );
}
