import angular from 'angular';
import $ from 'jquery';

export const loadModule = () =>
  angular
    .module('app')
    .directive('affixerOnFlex', function affixerOnFlex($window) {
      return {
        restrict: 'A',
        link: ($scope, $element, $attrs) => {
          const win = angular.element($window);
          const topOffset = $attrs.affixerOnFlex;
          function affixElement() {
            const divWidth = $('.table-lvr.item').width();
            if ($window.pageYOffset > topOffset) {
              $element.css('position', 'fixed');
              $element.css('top', '55px');
              $element.css('width', divWidth);
              $element.css('z-index', '100');
              $('.flex-header').css('display', 'block');
            } else {
              $element.css('position', '');
              $element.css('top', '');
              $element.css('width', '');
              $('.flex-header').css('display', 'none');
            }
          }
          $scope.$on('$routeChangeStart', () => {
            win.unbind('scroll', affixElement);
          });
          win.bind('scroll', affixElement);
        },
      };
    });
