import template from './gamePlanSendingMomentMain.html';
import controller from './gamePlanSendingMomentMainCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    loanAdviserId: '<',
    applicants: '<',
  },
};
