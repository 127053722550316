import template from './referralModal.html';
import controller from './referralModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    referral: '<',
  },
};
