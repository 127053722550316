import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { stringFormatter } from 'Common/utilities/string';

class IndividualDetailsPreviewCtrl {
  constructor(
    onboardingService,
    onboardingDataService,
    currentUserService,
    corporateService,
    commonFnService,
    broadcastDataService,
  ) {
    'ngInject';

    this.onboardingService = onboardingService;
    this.onboardingDataService = onboardingDataService;
    this.currentUserService = currentUserService;
    this.corporateService = corporateService;
    this.commonFnService = commonFnService;
    this.broadcastDataService = broadcastDataService;
  }

  getIndividualAddress() {
    this.corporateService.familyAddressGet(this.familyId).then(({ data }) => {
      if (!data) {
        return;
      }
      const address = this.commonFnService.setAddressTypes(data);
      this.businessAddress =
        (address.business && address.business.formatted_address) || '';
      this.homeAddress = (address.home && address.home.formatted_address) || '';
      this.mailingAddress =
        (address.mailing && address.mailing.formatted_address) || '';
      this.isSameAsPostal = this.businessAddress === this.mailingAddress;
    });
  }

  getIndividualDetails() {
    this.onboardingService
      .getRecruitmentContact(this.familyId, this.clientId)
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }
        this.individualDetails = data[0];
        this.yearsExperienceLabel = `Do you have less than ${
          this.individualDetails.ReportingCountryId === COUNTRY_TYPE.NEW_ZEALAND
            ? '1'
            : '2'
        } year(s) industry experience?`;
      });
  }

  $onInit() {
    this.stringFormatter = stringFormatter;
    this.familyId = this.onboardingDataService.getFamilyId();
    this.clientId = this.onboardingDataService.getClientId();
    this.countryType = COUNTRY_TYPE;
    const countryId = this.onboardingDataService.getCountryId();
    this.phoneFormat = this.broadcastDataService.getPhoneFormat(countryId);
    this.mobileFormat = this.broadcastDataService.getMobileFormat(countryId);
    this.getIndividualDetails();
    this.getIndividualAddress();
  }
}

export default IndividualDetailsPreviewCtrl;
