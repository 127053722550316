import { REPAYMENT_FREQUENCY } from 'Common/constants/repayment';

export const RATE_TYPE = {
  FIXED: 'Fixed',
  FLOATING: 'Floating',
  VARIABLE: 'Variable',
  REVOLVING_CREDIT: 'Revolving Credit',
};

export const TERM_KEYS = {
  INTEREST_ONLY: 'InterestOnlyDate',
  FIXED_RATE: 'FixedRateDate',
  REPAYMENT_HOLIDAY: 'RepaymentHolidayDate',
};

export const REPAYMENT_LEGEND = {
  LOAN_AMOUNT: 2,
  INTEREST_RATE: 3,
  LOAN_TERM: 4,
  REPAYMENT: 5,
  IS_INTEREST: 6,
};

export const REPAYMENTS = [
  { Name: REPAYMENT_FREQUENCY.YEARLY, id: REPAYMENT_FREQUENCY.YEARLY },
  {
    Name: REPAYMENT_FREQUENCY.HALF_YEARLY,
    id: REPAYMENT_FREQUENCY.HALF_YEARLY,
  },
  { Name: REPAYMENT_FREQUENCY.QUARTERLY, id: REPAYMENT_FREQUENCY.QUARTERLY },
  { Name: REPAYMENT_FREQUENCY.MONTHLY, id: REPAYMENT_FREQUENCY.MONTHLY },
  {
    Name: REPAYMENT_FREQUENCY.FORTNIGHTLY,
    id: REPAYMENT_FREQUENCY.FORTNIGHTLY,
  },
  { Name: REPAYMENT_FREQUENCY.WEEKLY, id: REPAYMENT_FREQUENCY.WEEKLY },
];
