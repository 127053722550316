import template from './infoConfirmBanner.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    message: '<',
    onConfirm: '&',
  },
};
