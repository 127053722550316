import angular from 'angular';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboarding';
import { displayError } from 'Common/utilities/alert';
import {
  isLastPage,
  updateModalStateAndLogout,
  displayInfoModal,
  getTabsToShowByRole,
  additionalTabFilters,
} from './util/onboardingCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('OnboardingCtrl', function OnboardingCtrl(
      $scope,
      $timeout,
      $uibModal,
      $state,
      $element,
      $anchorScroll,
      $window,
      onboardingService,
      corporateService,
      contactService,
      dataService,
      onboardingDataService,
      uiService,
      crmConfirmation,
      generalService,
      currentUserService,
    ) {
      const familyId = $state.params.familyId;
      const clientId = $state.params.clientId;

      const mainElem = '.tab-pane';
      $scope.activeTab = 0;
      $scope.reportingCountryId = 0;
      $scope.completedOnboardingScreens = false;
      $scope.downloads = [];
      $scope.percentages = [];
      $scope.helpfulLinks = [];
      $scope.teamLinkLists = [];
      $scope.onboardingTabs = [];
      $scope.tabs = {};
      $scope.allOnboardingDetailsPreview = {};
      $scope.options = { responsive: true };
      $scope.options = {
        ...$scope.options,
        tooltips: {
          displayColors: false,
        },
      };

      $scope.tabs.selected = $scope.onboardingTabs[0];

      const timeOutCall = () => {
        $timeout(() => {
          const el = $window.document.querySelectorAll(
            `#select${$scope.onboardingTabs[1].value}`,
          );
          angular.element(el).triggerHandler('click');
        }, 0);
      };

      const setOnboardingCompletion = () => {
        if ($scope.activeTab === $scope.onboardingTabs.length) {
          $scope.completedOnboardingScreens = true;
          if ($scope.completedOnboardingScreens) {
            $scope.allOnboardingDetailsPreview = onboardingDataService.getAllOnboardingDataForPreview();
          }
        }
      };

      const getActiveTab = () => {
        onboardingDataService
          .getCurrentTab(familyId)
          .then((currentTabValue) => {
            $scope.lastTabWithData = currentTabValue || 0;
            $scope.activeTab = $scope.lastTabWithData;
          });
      };

      const checkTabsIsCompleted = (tabs) => {
        return (
          tabs &&
          tabs.map((tab, key) =>
            key < $scope.activeTab ? { ...tab, completed: true } : tab,
          )
        );
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      const initialize = () => {
        /* set family id for all tabs without changing state params */
        onboardingDataService.setFamilyId(familyId);
        onboardingDataService.setClientId(clientId);
        /* get active Tab */
        getActiveTab();

        /* set Icons */
        $scope.onboardingIcons = onboardingDataService.getOnbardingTabsIcons();
        /* get helpfulLinks */
        $scope.helpfulLinks = onboardingDataService.getOnboardingHelpfulLinks();
        /* set tmpTabs */
        onboardingDataService
          .getOnbardingTabs($scope.onboardingIcons)
          .then((tabsTmp) => {
            $scope.onboardingTabsTmp = tabsTmp;
            dataService.getUserInfo(true).then((userCountryResp) => {
              if (userCountryResp && userCountryResp.data) {
                onboardingDataService.setAdviserOrgId(
                  userCountryResp.data.AdviserOrgID,
                );
                onboardingDataService.setUserCurrentCountry(
                  userCountryResp.data.CountryId,
                );
                $scope.reportingCountryId = userCountryResp.data.CountryId;
              }

              if ($scope.reportingCountryId) {
                /* set countryId referrence for all tabs */
                $scope.teamLinkLists = onboardingDataService.getOnboardingTeamList(
                  $scope.reportingCountryId,
                );
                angular.forEach($scope.onboardingTabsTmp, (response) => {
                  if (
                    response.value === 'asicAppointment' &&
                    $scope.reportingCountryId === COUNTRY_TYPE.NEW_ZEALAND
                  ) {
                    response.name = 'FSPR Registration';
                  }
                });
                /* call list api need to use for all tabs */
                onboardingDataService.getAdviserOrgDetails();
                onboardingDataService.getFamilyBrandingCategory();

                onboardingDataService
                  .getOnboardingResponse(familyId, clientId)
                  .then((response) => {
                    if (response) {
                      onboardingDataService.isOnboardingSubmitted =
                        response.OnboardingSubmitted;
                      if (response.RoleId) {
                        $scope.isFranchisee = onboardingDataService.isOnboardingUserRole(
                          response.RoleId,
                          ONBOARDING_ROLE_ID.FRANCHISEE,
                        );
                        $scope.isContractor = onboardingDataService.isOnboardingUserRole(
                          response.RoleId,
                          ONBOARDING_ROLE_ID.CONTRACTOR,
                        );
                        $scope.isEmployee = onboardingDataService.isOnboardingUserRole(
                          response.RoleId,
                          ONBOARDING_ROLE_ID.EMPLOYEE,
                        );
                        $scope.isDirector = onboardingDataService.isOnboardingUserRole(
                          response.RoleId,
                          ONBOARDING_ROLE_ID.DIRECTOR,
                        );
                        $scope.isPartner = onboardingDataService.isOnboardingUserRole(
                          response.RoleId,
                          ONBOARDING_ROLE_ID.PARTNER,
                        );
                      } else {
                        $scope.onboardingTabs = $scope.onboardingTabsTmp;
                      }
                      const roleTabs = getTabsToShowByRole(
                        $scope.onboardingTabsTmp,
                        response.RoleId,
                        response.isWriting,
                      );
                      const entityType = onboardingDataService.getUserCurrentEntityType();
                      const brandingCatId =
                        currentUserService.brandingCategoryID;
                      $scope.onboardingTabs = additionalTabFilters(
                        roleTabs,
                        entityType,
                        brandingCatId,
                      );
                      $scope.onboardingTabs = checkTabsIsCompleted(
                        $scope.onboardingTabs,
                      );
                      setOnboardingCompletion();
                      $scope.downloads = onboardingDataService.getOnboardingDownloads();
                    }
                  });
              }
            });
          });
        $scope.onboardingDataService = onboardingDataService;
        $scope.questionnaireField = {
          isEmpty: false,
        };
      };
      $scope.setTab = function (idx) {
        $scope.activeTab = idx;
        $scope.setActiveTab(idx);
      };

      const promptMessage = (formLength) => {
        if (
          formLength &&
          $scope.onboardingTabs[$scope.activeTab] &&
          $scope.onboardingTabs[$scope.activeTab].name === 'Additional Staff'
        ) {
          displayError(
            'You have entered one or more Additional Staff without saving. Please click Save or Cancel on each Additional Staff record before proceeding.',
            true,
            'Confirm Additional Staff',
          );
          return true;
        }
        return false;
      };

      const setCurrentOnboardingTab = () => {
        if ($scope.activeTab > $scope.lastTabWithData) {
          $scope.lastTabWithData = $scope.activeTab;
          corporateService.currentOnboardingTabSet(familyId, $scope.activeTab);
        }
      };

      const hasUnsavedChanges = () => {
        let unsavedChanges = false;
        angular
          .element(mainElem)
          .find('onboarding-new-party')
          .each(() => {
            unsavedChanges = true;
            const tabKey = $scope.onboardingTabs[$scope.activeTab].value;
            onboardingDataService.setUnsavedChanges(tabKey);
          });
        $scope.questionnaireField = {
          isEmpty: false,
        };
        const isQuestionnaire =
          ($scope.onboardingTabs &&
            $scope.onboardingTabs[$scope.activeTab].value ===
              'questionniare') ||
          false;
        if (!isQuestionnaire) {
          return unsavedChanges;
        }
        const requiredTab = onboardingDataService.getTabsRequiredField(
          angular.element(mainElem).eq($scope.activeTab),
          $scope.activeTab,
        );
        const questionnaireTab =
          (requiredTab && requiredTab[$scope.activeTab]) || {};
        if (!questionnaireTab) {
          return unsavedChanges;
        }
        const isRequiredFields =
          questionnaireTab.filled < questionnaireTab.total ||
          questionnaireTab.valid < questionnaireTab.total;
        if (!isRequiredFields) {
          return unsavedChanges;
        }
        $scope.questionnaireField.isEmpty = isRequiredFields;
        return isRequiredFields;
      };

      const showSaveInfoModal = () => {
        crmConfirmation.open({
          type: 'info',
          title: `We've saved your progress!`,
          description: `Come back anytime to continue the onboarding process`,
          buttonText: 'Got it',
          buttonClass: 'wide',
          modalSize: 'md',
          displayDontShowAgain: true,
          onConfirm: (confirm) => {
            updateModalStateAndLogout(uiService, generalService, confirm);
          },
        });
      };

      $scope.exitAndFinishLater = () => {
        if (!hasUnsavedChanges()) {
          generalService.getTickableItems().then((items) => {
            if (!displayInfoModal(items)) {
              showSaveInfoModal();
              return;
            }
            uiService.logoutUser();
          });
        }
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.setNextTab = function () {
        if (hasUnsavedChanges()) {
          return;
        }
        $scope.percentages = onboardingDataService.getTabsRequiredField(
          angular.element(mainElem).eq($scope.activeTab),
          $scope.activeTab,
        );
        if ($scope.percentages) {
          const tabFillValue = $scope.percentages[`${$scope.activeTab}`].filled;
          const tabValidValue = $scope.percentages[`${$scope.activeTab}`].valid;
          const totalTabValue = $scope.percentages[`${$scope.activeTab}`].total;

          angular.forEach($scope.onboardingTabs, (value, key) => {
            if (!value) {
              return false;
            }
            if (key === $scope.activeTab) {
              if (
                tabFillValue === totalTabValue ||
                tabValidValue === totalTabValue
              ) {
                value.completed = true;
              } else {
                value.completed = false;
              }
            }
          });

          const tabElm = angular.element(mainElem).eq($scope.activeTab);
          const formAddLength = angular
            .element(tabElm[0])
            .find('form[name="assistantForm"]').length;
          if (tabFillValue < totalTabValue || tabValidValue < totalTabValue) {
            if (!formAddLength) {
              displayError(
                'Please review the fields marked as required before proceeding.',
                true,
                'Required Fields',
              );
            } else {
              displayError(
                'Please fill in all the field(s) and check invalid input!',
                true,
                'Error',
              );
            }
            return false;
          }
          if (promptMessage(formAddLength)) {
            return false;
          }

          if ($scope.onboardingTabs) {
            if ($scope.activeTab === $scope.onboardingTabs.length - 1) {
              $scope.activeTab = $scope.onboardingTabs.length;
              setOnboardingCompletion();
            } else {
              $scope.activeTab++;
            }
          }
          $anchorScroll();
          setCurrentOnboardingTab();
        }
        timeOutCall();
        return true;
      };

      $scope.setPrevTab = function (str) {
        if (hasUnsavedChanges()) {
          return;
        }
        if (str === 'end') {
          $scope.activeTab = $scope.onboardingTabs.length;
          $scope.completedOnboardingScreens = false;
        } else {
          timeOutCall();
        }
        $scope.activeTab--;
        $anchorScroll();
        setCurrentOnboardingTab();
      };

      $scope.setActiveTab = function (idx) {
        if (hasUnsavedChanges()) {
          return;
        }
        let i = 0;
        $scope.activeTab = 0;
        while (i <= idx) {
          if (i && !$scope.setNextTab()) {
            break;
          }
          i++;
        }
        $scope.completedOnboardingScreens = false;
        setCurrentOnboardingTab();
      };

      $scope.currentView = 'tabs';
      $scope.switchView = function () {
        $scope.currentView =
          $scope.currentView === 'tabs' ? 'progress' : 'tabs';
      };

      $scope.sendApplication = function () {
        $uibModal.open({
          templateUrl: 'assets/views/onboarding/modal/thankyou.html',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          scope: $scope,
          controller($uibModalInstance) {
            contactService.getUserInfo().then((response) => {
              $scope.familyId = response.data.FamilyId;
            });

            $scope.closeThakyouMsg = function () {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.submitApplicationForm = function () {
              onboardingService.sendProcessCompletedEmailSet($scope.familyId);

              corporateService
                .currentOnboardingTabSet($scope.familyId, $scope.activeTab, 1)
                .then((currentTabResponse) => {
                  if (currentTabResponse) {
                    $uibModalInstance.dismiss('cancel');
                  }
                });
            };
          },
        });
      };

      $scope.downloadDocument = function (data) {
        const timeOut = $timeout(() => {
          const element = $window.document.createElement('a');
          element.setAttribute(
            'href',
            `data:${data.ContentType};base64,${data.DocumentContent}`,
          );
          element.setAttribute('download', data.DocumentName);
          element.style.display = 'none';
          $window.document.body.append(element);
          element.click();
          element.remove();
        });
        $scope.$on('$destroy', () => {
          $timeout.cancel(timeOut);
        });
      };

      $scope.isLastPage = () => {
        return isLastPage($scope.activeTab, $scope.onboardingTabs.length);
      };

      initialize();
    });
