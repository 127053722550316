import template from './helloPackEditor.html';
import controller from './helloPackEditorCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    clientId: '<?',
    model: '<',
    selectedService: '<?',
    adviserInfo: '<',
    adviserTheme: '<',
    onlyFactFind: '<?',
    oneTouchPrivacy: '<?',
    packMode: '<?',
    uploadDocument: '<',
    hideDealOption: '<?',
    preVerifyClient: '<?',
  },
};
