import { ALL_VALUE, WIDGET_ID } from 'Common/constants/dashboardWidget';
import { REPORT_TYPE } from 'Common/constants/reportType';
import { COMMON_CHART_COLORS } from 'Common/config/chart';

export function getValueOption(widgetOptionId, data) {
  return data.find((item) => item.WidgetOptionId === widgetOptionId) || {};
}

export function getCurrentCarouselData(isNext, index, list) {
  if (!list || !list.length) {
    return {};
  }
  const newNextIndex = index === list.length - 1 ? 0 : index + 1;
  const newPrevIndex = index === 0 ? list.length - 1 : index - 1;

  return list[isNext ? newNextIndex : newPrevIndex];
}

export function parseMultiSelectTicks(selection, data) {
  const multipleSelectedVal =
    selection &&
    selection.SelectedUerValues &&
    selection.SelectedUerValues.split(',');
  const hasSelection = multipleSelectedVal && multipleSelectedVal.length;
  return hasSelection
    ? data.map((item) => ({
        ...item,
        ticked: multipleSelectedVal.includes(`${item.value}`) || false,
      }))
    : data;
}

export function mapOptionAll(data) {
  if (!data || !data.length) {
    return [];
  }
  return data.map((item) => ({
    ...item,
    ticked: item.value === ALL_VALUE,
  }));
}

export function mapSelectedItem(data) {
  if (!data || !data.length) {
    return [];
  }
  return data.map((item, index) => {
    if (item.value === ALL_VALUE && !index) {
      item.ticked = false;
    }
    return item;
  });
}

export function getCustomOptionValues(data) {
  if (!data || !data.length) {
    return [];
  }
  return data
    .filter((item) => item.ticked)
    .map((item) => ({ PersonId: item.value }));
}

export function getIndexPeriod(list, currentPeriod) {
  if (!list || !list.length || !currentPeriod) {
    return 0;
  }
  return list.findIndex(
    (item) =>
      item.valueId ===
      (parseInt(currentPeriod.Widget_OptionValue, 10) ||
        parseInt(currentPeriod.valueId, 10)),
  );
}

export function getCorrespondingReport(widgetId) {
  const isReferralsReceived = widgetId === WIDGET_ID.REFERRALS_RECEIVED;
  return isReferralsReceived
    ? REPORT_TYPE.REFERRALS_RECEIVED_REFERRER
    : REPORT_TYPE.REFERRALS_SENT;
}

export function getChartColor({ index, colors }) {
  const isValidParams = !!(index && colors && colors.length);
  if (!isValidParams) {
    return COMMON_CHART_COLORS[0];
  }
  const splitter = colors.length + 1;
  const defaultColor = colors[0];
  return colors[index % splitter] || defaultColor;
}

export function getSelectionCSV(data) {
  return data.reduce((prev, curr) => {
    const csvString = !prev ? `${curr.value}` : `${prev},${curr.value}`;
    return curr.ticked ? csvString : prev;
  }, '');
}

export function getTargetEndpoint(widgetId) {
  let endpointToUse = '';
  switch (widgetId) {
    case WIDGET_ID.LODGED:
      endpointToUse = 'getLodgedLoans';
      break;
    case WIDGET_ID.SETTLED:
      endpointToUse = 'getSettledLoans';
      break;
    default:
  }
  return endpointToUse;
}

export const hideWidgetSettings = ({ currentUserService }) => {
  const {
    isAssistant,
    isPrincipalAdviser,
    isAdminAssistant,
  } = currentUserService;
  return isAssistant || isPrincipalAdviser || isAdminAssistant;
};

export const getAdvisersParams = (isLoanWriterOnly) => ({
  loanWritersOnly: isLoanWriterOnly,
  allAdviserLabel: 'All Advisers',
  allAdviserValue: '0',
  allAdviserTicked: true,
});

export const getFormattedAdvisers = (advisers) => {
  if (!advisers || !advisers.length) {
    return [];
  }
  return advisers.map((adviser) => ({
    value: adviser.familyId,
    name: adviser.fullName,
    ticked: adviser.ticked,
  }));
};
