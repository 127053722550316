import angular from 'angular';

export const loadModule = () =>
  angular.module('app').directive('inputSuffix', function inputSuffix() {
    return {
      restrict: 'A',
      require: '?ngModel',
      link: (scope, elem, attrs, ngModelCtrl) => {
        ngModelCtrl.$formatters.unshift(() => {
          const modelValue = ngModelCtrl.$modelValue || 0;
          return `${modelValue.toFixed(2)}${attrs.inputSuffix}`;
        });

        const appendSuffixToView = () => {
          if (ngModelCtrl.$modelValue || ngModelCtrl.$modelValue === 0) {
            const value = parseFloat(ngModelCtrl.$modelValue);
            elem.val(`${value.toFixed(2)}${attrs.inputSuffix}`);
          }
        };

        elem.on('blur', appendSuffixToView);
      },
    };
  });
