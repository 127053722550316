import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import {
  BIRTHDAYS_ID,
  CUSTOMER_CARE_TYPES,
  TAB_BIRTHDAYS,
} from 'Common/constants/customerCareModules';
import {
  sortColumn,
  sortType,
  addSelectedCustomerCareIds,
  isRetainAllAdviser,
  adviserChanged,
} from 'Assets/js/controllers/customerCare/util/customerCareCtrl';
import {
  onActionSelectedUtil,
  sendingResultUtil,
  modalParamsUtil,
  isDateSentActive,
  initValuesUtil,
  processCustomDateRange,
} from './util/customerCareTabsCtrl';
import {
  getRecipients as _getRecipients,
  openSMSModal,
  setBirthdayCompilationUtil,
} from './util/birthdaysCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('BirthdaysCtrl', function birthdaysCtrlFun(
      $scope,
      $uibModal,
      $timeout,
      NgTableParams,
      $window,
      toaster,
      customerCareService,
      customerCareTabsService,
      userService,
      downloadDataService,
      currentUserService,
      configService,
      utilitiesService,
      colorService,
      customerCareTemplateService,
    ) {
      $scope.reloadTimeout = null;
      $scope.customerCareTabsService = customerCareTabsService;
      $scope.initValues = initValuesUtil($scope);
      const getRecipients = _getRecipients(utilitiesService, colorService);

      // Birthdays
      // --------------------------------------------------------------------------
      /* Model */
      $scope.unlockingCondition = () => {
        return (
          ($scope.showLockScreen &&
            $scope.unlockingCustomerCare &&
            $scope.autoMarketingSubscription.subscribe_HappyBirthday) ||
          (currentUserService.isAssistantType && $scope.unlockingCustomerCare)
        );
      };
      $scope.isUnlockingBirthdays = $scope.unlockingCondition();
      $scope.customerCareAdviserList = isRetainAllAdviser(
        $scope.customerCareAdviserList,
        $scope.isUnlockingBirthdays,
      );
      $scope.customerCareTabsService.reloadCustomerCareBirthdayValues = ({
        showLockScreen,
        customerCareAdviserList,
      }) => {
        $scope.reloadTimeout = $timeout(() => {
          $scope.showLockScreen = showLockScreen;
          $scope.isUnlockingBirthdays = $scope.unlockingCondition();
          $scope.customerCareAdviserList = isRetainAllAdviser(
            customerCareAdviserList,
            $scope.isUnlockingBirthdays,
          );
          $scope.mainObj.adviserObject = _.get(
            $scope.customerCareAdviserList,
            '[0]',
            {},
          );
          $timeout.cancel($scope.reloadTimeout);
        });
      };
      angular.extend($scope, {
        checkedDataNumber: 0,
        mainObj: {
          adviserObject: _.get($scope.customerCareAdviserList, '[0]', {}),
          dateRange: 60,
          ShowCompleted: false,
          checkAll: false,
        },
        toCompleteBatchList: [],
        birthdayBatchList: [],
        isNZUser: currentUserService.isNZ,
        isDateSentActive: isDateSentActive(configService),
        actionColumnTitle: isDateSentActive(configService)
          ? 'Communication Sent'
          : '',
      });
      $scope.exportService = 'exportBirthdays';
      $scope.selectedBirthdayIds = [];
      $scope.currentPage = 1;
      $scope.totalCount = 0;
      $scope.sortColumn = '';
      $scope.sortType = '';
      $scope.requiredParams = {
        get: () => ({
          pageSize: $scope.totalCount,
          sortType: $scope.sortType,
          sortColumn: $scope.sortColumn,
          toDate: $scope.mainObj.toDate,
          fromDate: $scope.mainObj.formDate,
          adviserId: $scope.mainObj.adviserObject.familyId,
          selectedRowIds: $scope.selectedBirthdayIds,
        }),
      };
      $scope.addSelectedBirthdayIds = addSelectedCustomerCareIds(
        $scope.selectedBirthdayIds,
      );
      $scope.latestStateIsUnlockingBirthdays = () =>
        $scope.unlockingCondition();

      /* Methods */
      angular.extend($scope, {
        unCheckAllCheckbox() {
          if ($scope.birthdayListTable && $scope.birthdayListTable.length > 0) {
            _.each($scope.birthdayListTable, (item) => {
              item.isChecked = false;
            });
            $scope.checkedDataNumber = 0;
            $scope.mainObj.checkAll = false;
          }
        },

        checkAllCheckbox() {
          if ($scope.birthdayListTable && $scope.birthdayListTable.length > 0) {
            _.each($scope.birthdayListTable, (item) => {
              item.isChecked = true;
            });
            $scope.checkedDataNumber = $scope.birthdayListTable.length;
            $scope.mainObj.checkAll = true;
          }
        },

        storeOrRemoveRowsForBatch(familyId, singleBirthday) {
          $scope.onItemCheckboxToggled(
            singleBirthday,
            'setBirthdayCompilation',
            'birthdayListTable',
          );
          if (singleBirthday.isChecked) {
            $scope.toCompleteBatchList.push({
              CustomerCareType: 'BIRTH_DAY',
              ClientID: singleBirthday.ClientID,
              FamilyID: familyId,
              LoanID: 0,
              IsComplete: singleBirthday.isComplete,
              isChecked: singleBirthday.isChecked,
            });
            $scope.birthdayBatchList.push({
              Age: singleBirthday.Age,
              ClientID: singleBirthday.ClientID,
              Country: singleBirthday.Country,
              DateOfBirth: singleBirthday.DateOfBirth,
              Email: singleBirthday.Email,
              FamilyFullName: singleBirthday.FamilyFullName,
              FamilyID: singleBirthday.FamilyID,
              FullName: singleBirthday.FullName,
              IsDueToday: singleBirthday.IsDueToday,
              Mobile: singleBirthday.Mobile,
              TotalRecord: singleBirthday.TotalRecord,
              isComplete: singleBirthday.isComplete,
              SMSEnabled: singleBirthday.SMSEnabled,
            });

            $scope.checkedDataNumber++;
            if ($scope.checkedDataNumber === $scope.birthdayListTable.length) {
              $window.document.querySelector('#checkall-id').checked = true;
            }
          } else {
            _.remove($scope.toCompleteBatchList, (object) => {
              return object.FamilyID === familyId;
            });
            _.remove($scope.birthdayBatchList, (object) => {
              return object.FamilyID === familyId;
            });
            $scope.checkedDataNumber--;
          }
        },

        storeAllRowsForBatch(checkAll) {
          $scope.mainObj.checkAll = checkAll;
          $scope.toCompleteBatchList = [];
          $scope.birthdayBatchList = [];
          if (checkAll === true) {
            $scope.updateBirthdayListForBatch();
            $scope.birthdayBatchList = angular.copy($scope.birthdayListTable);
            $scope.checkedDataNumber = $scope.birthdayListTable.length;
          } else {
            $scope.toCompleteBatchList = [];
            $scope.birthdayBatchList = [];
            $scope.unCheckAllCheckbox();
          }
          $scope.birthdayListTable.forEach((element) => {
            $scope.onItemCheckboxToggled(
              element,
              'setBirthdayCompilation',
              'birthdayListTable',
            );
          });
        },
        updateBirthdayListForBatch() {
          _.each($scope.birthdayListTable, (object) => {
            $scope.toCompleteBatchList.push({
              CustomerCareType: 'BIRTH_DAY',
              ClientID: object.ClientID,
              FamilyID: object.FamilyID,
              LoanID: 0,
              IsComplete: object.isComplete,
              isChecked: true,
            });
          });
          $scope.checkAllCheckbox();
        },
        setBatchBirthdayAsComplete(isComplete) {
          _.each($scope.toCompleteBatchList, (item) => {
            item.isChecked = isComplete;
            item.IsComplete = isComplete;
          });
          if (!_.isEmpty($scope.toCompleteBatchList)) {
            customerCareService
              .setCompeletion($scope.toCompleteBatchList)
              .then(() => {
                $scope.birthdayTableParams.reload();
                toaster.pop(
                  'success',
                  'Completed',
                  'Contact successfully set as completed.',
                );
                $scope.checkedDataNumber = 0;
              });
          }
        },

        openBatchActionMobileModal(size) {
          $uibModal.open({
            templateUrl:
              '/assets/views/customerCare/partials/modal/batch_action_mobile_modal.html',
            controller: 'BatchActionMobileModalCtrl',
            size,
            scope: $scope,
            windowClass: 'batch-action-mobile-modal-class',
            resolve: {},
          });
        },
      });

      $scope.reloadTable = _.noop();
      $scope.showUnlockedBirthday = () => {
        const isAdviserReadOnly = _.get(
          $scope.mainObj,
          'adviserObject.isSubscribedToCustomerCareType',
          false,
        );
        return $scope.isUnlockingBirthdays && isAdviserReadOnly;
      };
      $scope.loadBirthdaysTable = () => {
        $scope.birthdayTableParams = new NgTableParams(
          {
            page: 1,
            count: 10,
            sorting: {
              DateOfBirth: 'asc',
            },
          },
          {
            counts: [],
            total: 0,
            getData(params) {
              const adviserFamilyId = _.get(
                $scope.mainObj,
                'adviserObject.familyId',
              );
              if (adviserFamilyId == null) {
                return;
              }
              $scope.isLoading = true;
              $scope.sortColumn = sortColumn(params.orderBy);
              $scope.sortType = sortType(params.sorting);
              $scope.currentPage = params.page();

              return customerCareService
                .BirthDayListGet(
                  adviserFamilyId,
                  $scope.mainObj.formDate,
                  $scope.mainObj.toDate,
                  params.page(),
                  params.count(),
                  $scope.sortColumn,
                  $scope.sortType,
                  $scope.mainObj.ShowCompleted,
                )
                .then(
                  (response) => {
                    if (
                      response &&
                      response.data &&
                      response.data.BirthDayList
                    ) {
                      $scope.checkedDataNumber = 0;
                      $scope.toCompleteBatchList = [];
                      $scope.birthdayBatchList = [];
                      $scope.birthdayListTable = response.data.BirthDayList;
                      $scope.mainObj.checkAll = false;
                      _.map($scope.birthdayListTable, (item) => {
                        item.isChecked = false;
                        const today = moment().format('D MMMM');
                        const exDate = moment(item.DateOfBirth).format(
                          'D MMMM',
                        );
                        if (today === exDate) {
                          item.IsDueToday = true;
                        } else {
                          item.IsDueToday = false;
                        }
                        item.isChecked = $scope.selectedBirthdayIds.includes(
                          item.ClientID,
                        );
                      });
                      let tempStoreForAge = '';
                      _.map($scope.birthdayListTable, (object) => {
                        tempStoreForAge = object.Age;
                        object.agePlusOne =
                          _.parseInt(
                            _.replace(tempStoreForAge, 'Turning ', ''),
                          ) + 1;
                        return object.agePlusOne;
                      });

                      if (typeof response.data.TotalCount !== 'undefined') {
                        params.total(response.data.TotalCount);
                      } else {
                        params.total(0);
                      }
                      $scope.checkedTableData = [];
                      $scope.isLoading = false;
                      $scope.totalCount = response.data.TotalCount;
                      return $scope.birthdayListTable;
                    }
                    $scope.isLoading = false;
                  },
                  () => {},
                );
            },
          },
        );

        $scope.reloadTable = () => {
          $scope.birthdayTableParams.reload();
        };
      };

      $scope.adviserChanged = () => {
        adviserChanged($scope.selectedBirthdayIds, $scope.birthdayTableParams);
      };

      $scope.onActionSelected = onActionSelectedUtil($scope);

      const getDateRange = () => {
        if (!$scope.mainObj.dateRange) {
          return {};
        }
        return {
          formDate: moment().format('D MMMM YYYY'),
          toDate: moment()
            .add(Number($scope.mainObj.dateRange), 'days')
            .format('D MMMM YYYY'),
        };
      };

      $scope.exportBirthdaySheet = () => {
        $scope.mainObj = { ...$scope.mainObj, ...getDateRange() };
        const params = {
          fromDate: $scope.mainObj.formDate,
          isCompleted: $scope.mainObj.ShowCompleted,
          searchedAdvisorID: $scope.mainObj.adviserObject.familyId,
          toDate: $scope.mainObj.toDate,
        };
        customerCareService.birthdaySpreadsheet(params).then((response) => {
          if (!response || !Object.keys(response).length) {
            return;
          }
          const { contentType, documentContent, name } = response;
          downloadDataService.download(
            `data:${contentType};base64,${documentContent}`,
            `${name}`,
            contentType,
          );
          toaster.pop(
            'success',
            'Birthday List Ready',
            'Successfully generated birthday list',
          );
        });
      };

      // Date Range Filter
      $scope.dateRangeFilter = () => {
        $scope.mainObj = { ...$scope.mainObj, ...getDateRange() };
        $scope.selectedBirthdayIds.splice(0, $scope.selectedBirthdayIds.length);
        $scope.loadBirthdaysTable();
      };

      // get the signed in broker
      $scope.getSignedInBrokerId = () => {
        userService.GetUserInfo().then((response) => {
          const { data } = response;
          if (!data) {
            return false;
          }
          $scope.mainObj.adviserFullName = data.FullName;
          $scope.mainObj.accessType = data.AccessType;
          $scope.dateRangeFilter();
        });
      };
      $scope.getSignedInBrokerId();

      // Open modal For Get Birthday Detail in Mobile
      $scope.openModalForGetBirthdayDetail = (birthday, size) => {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/birthdays_filter_modal.html',
          controller: 'GetBirthdayDetailModalCtrl',
          size,
          resolve: {
            birthday: () => birthday,
          },
        });
      };

      // Set Birthday as Complete
      $scope.setBirthdayCompilation = (birthday) => {
        setBirthdayCompilationUtil({
          birthday,
          customerCareService,
          table: $scope.birthdayTableParams,
        });
      };

      $scope.openSendEmailModal = (size, birthdayObj, source) => {
        $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_email_modal.html',
          resolve: {
            birthdayBatchList() {
              return $scope.birthdayBatchList;
            },
            loadBirthdaysTable() {
              return $scope.reloadTable;
            },
            birthdayObj: () => birthdayObj,
            source: () => source,
          },
          controller: 'BirthdaysSendEmailModalCtrl',
          size,
        });
      };

      $scope.sendingResult = sendingResultUtil($scope);

      $scope.openSendMailModal = (size, birthdayObj, source) => {
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/customerCare/partials/modal/send_mail_modal.html',
          resolve: {
            listTableData: () => $scope.birthdayBatchList,
            sendMailObj: () => birthdayObj,
            source: () => source,
            currentModuleId: () => BIRTHDAYS_ID,
            currentModuleType: modalParamsUtil.currentModuleType(
              CUSTOMER_CARE_TYPES.BIRTHDAYS,
            ),
            currentModuleName: modalParamsUtil.currentModuleName('Birthdays'),
            isInsurance: modalParamsUtil.isInsurance(false),
            tab: modalParamsUtil.tab(null),
          },
          controller: 'OpenSendMailModalCtrl',
          size,
        });
        $scope.sendingResult(modalInstance, 'reloadTable');
      };
      const getRestriction = () => {
        const restrictedAccessTypeList = [
          ACCESS_TYPE.ADMIN_ASSISTANT,
          ACCESS_TYPE.ASSISTANT,
        ];
        return restrictedAccessTypeList.includes($scope.mainObj.accessType);
      };

      $scope.openSMSModal = openSMSModal(
        customerCareService,
        customerCareTemplateService,
        $uibModal,
        $scope,
        getRecipients,
      );

      $scope.displaySmsButton = (birthdayObj) => {
        if (!getRestriction()) {
          return true;
        }
        return birthdayObj.SMSEnabled || false;
      };
      $scope.reloadCallback = $scope.loadBirthdaysTable;
      processCustomDateRange($scope);
      $scope.initValues({ configService, TAB_DETAILS: TAB_BIRTHDAYS });

      $scope.$on('$destroy', () => {
        $timeout.cancel($scope.reloadTimeout);
        $scope.reloadTimeout = null;
      });
    });
