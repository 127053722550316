export const commonProps = {
  controller: 'CommonModalPlaceholderCtrl',
  controllerAs: 'vm',
  size: 'md',
  keyboard: false,
  backdrop: 'static',
};

export const viewOfAFileTheme = 'turquoise';

export const mycrmConfirmationDefault = {
  theme: viewOfAFileTheme,
  modalTitle: '',
  message: '',
  confirmationTitle: '',
  discardTitle: '',
  onConfirm: () => {},
};
