import moment from 'moment';
import {
  DD_MMM_YYYY_FORMAT,
  ALT_DATE_INPUT_FORMATS,
} from 'Common/constants/dateConfigs';

export const initializePipelineStatusMove = ({ $scope }) => {
  $scope.format = DD_MMM_YYYY_FORMAT;
  $scope.dateOptions = {
    formatYear: 'yy',
    showWeeks: false,
  };
  $scope.dateModel = {
    isOpenDate: false,
    notProceedingDate: moment.parseZone(new Date())._d,
  };
  $scope.dateInputFormats = ALT_DATE_INPUT_FORMATS;
};
