import './style.scss';
import template from './usersProfile.html';
import UsersProfileCtrl from './usersProfileCtrl';

export default {
  template,
  bindings: {
    familyId: '<',
    showEmailSignature: '<',
    showSocialMedia: '<',
  },
  controller: UsersProfileCtrl,
  controllerAs: 'vm',
};
