export default class CrmNotifyCtrl {
  constructor($timeout) {
    'ngInject';

    this.$timeout = $timeout;
  }

  $onInit() {
    if (!this.allowClose) {
      this.$timeout(() => {
        this.modalInstance.close();
      }, this.timeout);
    }
  }

  handleModalClose() {
    this.modalInstance.close();
  }
}
