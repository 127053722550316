import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';
import { ONBOARDING_ROLE_ID, TABS_IDX } from 'Common/constants/onboarding';
import { isEntityPartOfType } from 'Common/utilities/onboarding';
import { isOwnBrand } from 'Common/utilities/brandingCategory';

export const isLastPage = (currentTab, tabsCount) => {
  if (Number.isNaN(tabsCount)) {
    return;
  }
  return currentTab === tabsCount - 1;
};

export const updateModalStateAndLogout = (uiService, generalService, data) => {
  const incompleteArguments = !uiService || !generalService || !data;
  if (incompleteArguments) {
    return;
  }
  const { dontShowAgain } = data;
  if (!dontShowAgain) {
    uiService.logoutUser();
    return;
  }
  generalService
    .postTickableItems({ itemId: TICKABLE_ITEMS.ONBOARDING_SAVE_INFO })
    .then(() => {
      uiService.logoutUser();
    });
};

export const displayInfoModal = (items) => {
  if (!items || !items.length) {
    return;
  }
  const savedTickable = items.find((item) => {
    return item.id === TICKABLE_ITEMS.ONBOARDING_SAVE_INFO;
  });
  return savedTickable && savedTickable.isTicked;
};

export const getTabsToShowByRole = (allTabs, roleIds, isWriting) => {
  const roleIdArr = roleIds.split(',').map((id) => parseInt(id, 10));
  const isFranchisee = roleIdArr.includes(ONBOARDING_ROLE_ID.FRANCHISEE);
  const isContractor = roleIdArr.includes(ONBOARDING_ROLE_ID.CONTRACTOR);
  const isEmployee = roleIdArr.includes(ONBOARDING_ROLE_ID.EMPLOYEE);
  const isDirector = roleIdArr.includes(ONBOARDING_ROLE_ID.DIRECTOR);
  const isPartner = roleIdArr.includes(ONBOARDING_ROLE_ID.PARTNER);

  const commonTabs = [
    TABS_IDX.QUESTIONNAIRE,
    TABS_IDX.BRANDING,
    TABS_IDX.RELATED_DOCS,
  ];
  const contractorTabs = [TABS_IDX.ENTITY, TABS_IDX.ASIC, TABS_IDX.PARTIES];
  const employeeTabs = [TABS_IDX.ASIC];
  const directorTabs = [TABS_IDX.INDIVIDUAL];
  const partnerTabs = [TABS_IDX.INDIVIDUAL];

  const perRoleTabs = [];
  if (isContractor) {
    perRoleTabs.push(...contractorTabs);
  }
  if (isEmployee) {
    perRoleTabs.push(...employeeTabs);
  }
  if (isDirector) {
    perRoleTabs.push(...directorTabs);
    if (isWriting) {
      perRoleTabs.push(TABS_IDX.ASIC);
    }
  }
  if (isPartner) {
    perRoleTabs.push(...partnerTabs);
    if (isWriting) {
      perRoleTabs.push(TABS_IDX.ASIC);
    }
  }

  const unspecified = !isContractor && !isEmployee && !isDirector && !isPartner;
  const showAllTabs = isFranchisee || unspecified;
  const distinctIndices = [...new Set(perRoleTabs)];
  const tabDisplayIndices = [...distinctIndices, ...commonTabs];
  const tabsToDisplay = allTabs.filter((tab) =>
    tabDisplayIndices.includes(tab.idx),
  );
  return showAllTabs ? allTabs : tabsToDisplay;
};

export const additionalTabFilters = (tabs, entity, brandingCatId) => {
  const removePartySection = isEntityPartOfType('Sole Trader', entity);
  const tabsFilteredByParty = removePartySection
    ? tabs.filter((tab) => tab.idx !== TABS_IDX.PARTIES)
    : tabs;

  const removeBrandingTab = !isOwnBrand(brandingCatId);
  return removeBrandingTab
    ? tabsFilteredByParty.filter((tab) => tab.idx !== TABS_IDX.BRANDING)
    : tabsFilteredByParty;
};
