import template from './referralKiwiSelect.html';
import ReferralKiwiSelectCtrl from './referralKiwiSelectCtrl';

export default {
  template,
  bindings: {
    formModel: '<',
    contactAdviserList: '<',
    clientData: '<',
    adviserOrgList: '<',
    contactDetailList: '<',
    clientPostData: '<',
    isClientSearchReadOnly: '<',
    isExisting: '<',
    referralForm: '<',
    handler: '&',
    closeModal: '&',
  },
  controller: ReferralKiwiSelectCtrl,
  controllerAs: 'vm',
};
