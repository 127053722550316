import angular from 'angular';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { DATE_TEXT_FORMAT } from 'Common/constants/dateConfigs';
import {
  ACCESSIBILITY,
  CATEGORY_ID,
  DEFAULT_BRANDING_CATEGORY,
} from 'Common/constants/importantDocuments';

import {
  getImportantDocumentFlags,
  importantDocumentDetailsGet,
  resetBrandingCategorySelection,
  getSelectedBrandingCategoriesCsv,
} from './util/importantDocumentModalCtrl';

class ImportantDocumentModalCtrl {
  constructor(
    userInfo,
    loanToolsService,
    $uibModalInstance,
    corporateService,
    contactService,
    toaster,
    documentId,
    isUploading,
    importantDocs,
    importantDocumentService,
    corporateModelService,
    $filter,
    configService,
    categoryId,
    generalService,
    currentUserService,
  ) {
    this.userInfo = userInfo;
    this.loanToolsService = loanToolsService;
    this.$uibModalInstance = $uibModalInstance;
    this.corporateService = corporateService;
    this.contactService = contactService;
    this.toaster = toaster;
    this.documentId = documentId;
    this.isUploading = isUploading;
    this.importantDocumentService = importantDocumentService;
    this.corporateModelService = corporateModelService;
    this.importantDocs = importantDocs;
    this.$filter = $filter;
    this.configService = configService;
    this.categoryId = categoryId;
    this.generalService = generalService;
    this.currentUserService = currentUserService;
  }

  cancel() {
    this.$uibModalInstance.dismiss();
  }

  updateDocument() {
    this.details.regionId = this.userInfo.CountryId;
    if (this.importantDocumentCategorization) {
      this.details.currentVersion = this.$filter('dateTZ')(
        this.details.tempCurrentVersion,
        DATE_TEXT_FORMAT,
      );
      this.details.categoryId = this.categoryId;
    }
    this.details.brandingCategoryIds = getSelectedBrandingCategoriesCsv(
      this.brandingCategoriesSelected,
    );

    this.corporateModelService
      .importantDocumentSet(this.details)
      .then((respond) => {
        if (!respond) {
          return;
        }
        const { data } = respond;
        if (data === -1) {
          this.toaster.pop(
            'error',
            'Oops!',
            'You already have selected a credit guide.',
          );
        } else {
          this.isProgressBarVisible = false;
          this.toaster.pop(
            'success',
            'Updated Info',
            'Successfully update file information',
          );
          this.loanToolsService.getImportantDocs();
          this.$uibModalInstance.close(true);
        }
      });
  }

  successUpload(response) {
    if (response && response.length > 0) {
      this.details.documentKey = response[0].DocumentKey;
      this.updateDocument();
    } else {
      this.isProgressBarVisible = false;
    }
  }

  removeImportantDocTag() {
    this.showFlagChoices = false;
    this.showWarningMessage = false;
    this.details.documentFlagId = 0;
  }

  selectionChange(flagId) {
    this.showWarningMessage = !!this.importantDocs.find(
      (flag) => Number(flag.documentFlagId) === Number(flagId),
    );
  }

  openDatePopover() {
    this.dateIsOpen = !this.dateIsOpen;
  }

  onBrandingCategorySelect(selectedCategory) {
    if (selectedCategory.BrandingCategoryId) {
      this.brandingCategories[0].ticked = false;
      return;
    }
    this.onBrandingCategoryReset();
  }

  onBrandingCategoryReset(isSelectAll = true) {
    this.brandingCategories = resetBrandingCategorySelection(
      this.brandingCategories,
      isSelectAll,
    );
    if (!isSelectAll) {
      return;
    }
    this.brandingCategoriesSelected = [{ ...DEFAULT_BRANDING_CATEGORY }];
  }

  $onInit() {
    this.importantDocumentCategorization =
      this.configService.feature.importantDocumentCategorization || false;
    this.importantDocumentDetailsGet = importantDocumentDetailsGet(this);
    this.accessibilityList = ACCESSIBILITY;
    this.category = CATEGORY_ID;
    this.showWarningMessage = false;
    getImportantDocumentFlags(this).then(() => {
      this.importantDocumentDetailsGet();
    });
    this.contactService.getUserInfo().then((response) => {
      if (!response || !response.data || !response.data.AccessType) {
        return;
      }
      this.userInfo = response.data;
      this.isCorporateUser = this.userInfo.AccessType === ACCESS_TYPE.CORPORATE;
    });
    if (this.importantDocumentFlagId) {
      this.showFlagChoices =
        !this.importantDocumentFlagId.documentFlagId <= 0 || false;
    }
    this.saveLabel = this.isUploading ? 'Finish Uploading' : 'Update File';
    this.headerLabel = this.isUploading ? 'Upload File' : 'Edit File';
    this.toUploadReady = this.importantDocumentService.toUploadReady;
    this.uploadFile = this.importantDocumentService.uploadFile;
    this.errorUpload = this.importantDocumentService.errorUpload;
    this.displayBrandingCategories =
      this.importantDocumentCategorization &&
      this.currentUserService.isCorporate &&
      this.categoryId !== CATEGORY_ID.YOUR_LIBRARY;
    this.brandingCategoryLocalLang = {
      selectAll: `<span>Select All</span>`,
      selectNone: `<span>Deselect All </span>`,
      reset: `<i class="fa fa-refresh"></i>`,
      search: `Search`,
      nothingSelected: `<div class="buttonLabel padding-left-10">No Selected Category</div>`,
    };
  }
}
export const loadModule = () =>
  angular
    .module('app')
    .controller('ImportantDocumentModalCtrl', ImportantDocumentModalCtrl);
