import { phoneType } from 'Common/constants/phoneType';
import { toastError } from 'Common/utilities/alert';
import {
  ERROR_EMAIL_MESSAGE,
  ERROR_PHONE_MESSAGE,
} from 'Common/constants/errorMessages';
import { get, first } from 'lodash';

export const defaultCountryCode = ({
  countryCodeService,
  isInternationalPhoneNumbers,
  countryCode,
}) => {
  return countryCodeService.setDefaultCountryCode({
    isInternationalPhoneNumbers,
    defaultCountryCode: countryCode,
  });
};

export const isLandline = (numberType) => {
  return numberType.toLowerCase() !== phoneType.mobile.toLowerCase();
};

export const isNumberValid = ({
  element,
  isPhoneRequired,
  message,
  phoneNumber,
  isLandlineValid,
  countryCodeService,
}) => {
  return countryCodeService.countryInputSelectorValidation({
    element,
    isPhoneRequired,
    message,
    phoneNumber,
    isLandline: isLandlineValid,
  });
};

export const assignCountryCode = ({ phoneNumbers, countryCodeService }) => {
  return phoneNumbers.map((data) => {
    const selectedCountryCode = countryCodeService.getSelectedCountryCode(
      data.phoneId,
    );
    return {
      ...data,
      CountryCode: get(selectedCountryCode, 'iso2', ''),
    };
  });
};

export const hidePhoneType = ({ phoneList, type }) => {
  return phoneList.map((data) => data.Type).includes(type);
};

export const hideAddPhoneField = (phoneList) => {
  if (!phoneList || !phoneList.length) {
    return true;
  }
  const phoneTypeList = [phoneType.mobile, phoneType.home, phoneType.work];

  const existingPhoneListType = phoneTypeList.map((data) => {
    return phoneList.map((result) => result.Type).includes(data);
  });

  return existingPhoneListType.includes(false);
};

export const assignPhoneType = (phoneList) => {
  if (!phoneList || !phoneList.length) {
    return phoneType.mobile;
  }

  const existingPhoneList = phoneList.map((data) => data.Type);

  if (!existingPhoneList.includes(phoneType.mobile)) {
    return phoneType.mobile;
  } else if (!existingPhoneList.includes(phoneType.home)) {
    return phoneType.home;
  } else if (!existingPhoneList.includes(phoneType.work)) {
    return phoneType.work;
  }

  return '';
};

export const addIntlPhoneAndLandlineFlagToContact = ({
  contactSet,
  applicantNumber,
  countryCodeService,
  dynamicCountryCodeInputIdNameSetter,
  isNZ,
  isInternationalPhoneNumbers,
}) => {
  if (!contactSet || !contactSet.length) {
    return contactSet;
  }
  return contactSet.map((data, index) => {
    const currPhoneType = data.Type;
    return {
      ...data,
      isInternationalPhoneCountryCode: countryCodeService.isInternationalPhoneCountryCode(
        currPhoneType,
      ),
      isLandline:
        (isNZ && !isInternationalPhoneNumbers) || isLandline(currPhoneType),
      isRequired: !isLandline(currPhoneType),
      phoneId: dynamicCountryCodeInputIdNameSetter({
        name: data.Type,
        index: `${index}-${applicantNumber}`,
        isId: true,
      }),
    };
  });
};

export const updatePartyApplicantsOnEdit = ({ $scope }) => {
  $scope.partyApplicants = $scope.partyApplicants.map((data) => {
    if (
      (data.PersonId === $scope.ContactSet.PersonId &&
        $scope.Settings.isEdit) ||
      data.ApplicantNumber === $scope.ContactSet.ApplicantNumber
    ) {
      return $scope.ContactSet;
    }
    return data;
  });
};

export const addPhoneOnInit = ($scope) => {
  const isInternationalNumber =
    $scope.isInternationalPhoneNumbers || $scope.phoneNumberCountryCodeNZ;

  const isAddPhone =
    isInternationalNumber &&
    (!$scope.ContactSet.Phone || !$scope.ContactSet.Phone.length);

  if (isAddPhone) {
    $scope.addPhone();
  }
};

export const addPhone = ({
  $scope,
  dynamicCountryCodeInputIdNameSetter,
  countryCodeService,
}) => {
  const currPhoneType = assignPhoneType($scope.ContactSet.Phone) || '';
  if (!$scope.ContactSet.Phone) {
    $scope.ContactSet.Phone = [];
  }
  const currIndex = $scope.ContactSet.Phone.length;
  const isPhoneTypeLandline = isLandline(currPhoneType);
  $scope.ContactSet.Phone.push({
    Type: currPhoneType,
    Number: '',
    isInternationalPhoneCountryCode: countryCodeService.isInternationalPhoneCountryCode(
      currPhoneType,
    ),
    isLandline:
      ($scope.isNZ && !$scope.isInternationalPhoneNumbers) ||
      isPhoneTypeLandline,
    isRequired: !isPhoneTypeLandline,
    phoneId: dynamicCountryCodeInputIdNameSetter({
      name: currPhoneType,
      index: `${currIndex}-${$scope.ContactSet.ApplicantNumber}`,
      isId: true,
    }),
    CountryCode: countryCodeService.setDefaultCountryCode({}),
  });
};

export const initOktaAuthFlow = ({ $scope, $q, userAccountModelService }) => {
  $scope.oktaEmailActive = false;
  $scope.checkEmailUpdate = (contactSet) => {
    const { isOktaEmailExist, EmailAddress, Email } = contactSet;
    const originalEmail = get(Email, '[0].EmailAddress', '');
    if (isOktaEmailExist && originalEmail !== EmailAddress) {
      contactSet.isOktaEmailExist = false;
    }
  };
  $scope.checkArrayApplicantEmails = () => {
    if (!get($scope.partyApplicants, 'length')) {
      return;
    }
    const promises = $scope.partyApplicants.reduce((initial, applicant) => {
      const { EmailAddress } = applicant;
      return EmailAddress
        ? [...initial, userAccountModelService.getEmailExist(EmailAddress)]
        : initial;
    }, []);
    $q.all(promises).then((responses) => {
      if (!get(responses, 'length')) {
        return;
      }
      responses.map((response) => {
        const { isOktaEmailExist, email } = response;
        !$scope.oktaEmailActive && ($scope.oktaEmailActive = isOktaEmailExist);
        $scope.partyApplicants = $scope.partyApplicants.map((applicant) => {
          const applicantEmail = get(applicant, 'EmailAddress', '');
          if (applicantEmail === email) {
            applicant.isOktaEmailExist = isOktaEmailExist;
          }
          return applicant;
        });
        email === $scope.ContactSet.EmailAddress &&
          ($scope.ContactSet.isOktaEmailExist = isOktaEmailExist);
      });
      if ($scope.oktaEmailActive) {
        $scope.submitClicked = false;
      } else {
        $scope.saveClientAccount();
      }
    });
  };
};

export const pushPartyApplicantOnInit = ({
  $scope,
  newContactSet,
  isSamePerson,
}) => {
  isSamePerson && ($scope.ContactSet = newContactSet);
  $scope.partyApplicants.push(newContactSet);
  addPhoneOnInit($scope);
};

export const checkOktaEmail = ({
  $scope,
  newContactSet,
  isSamePerson,
  userAccountModelService,
}) => {
  const { EmailAddress } = newContactSet;
  if (!EmailAddress) {
    return pushPartyApplicantOnInit({
      $scope,
      newContactSet,
      isSamePerson,
    });
  }
  userAccountModelService
    .getEmailExist(EmailAddress)
    .then(({ isOktaEmailExist }) => {
      newContactSet.isOktaEmailExist = isOktaEmailExist;
      !$scope.oktaEmailActive && ($scope.oktaEmailActive = isOktaEmailExist);
      pushPartyApplicantOnInit({
        $scope,
        newContactSet,
        isSamePerson,
      });
    })
    .catch((error) => {
      toastError(error);
    });
};
export const addPropertiesToPartyApplicants = ({
  contactSet,
  $scope,
  countryCodeService,
  dynamicCountryCodeInputIdNameSetter,
}) => {
  const contactModel = get(contactSet, 'Phone');
  const isPhoneExisting =
    contactModel || contactModel.length ? contactModel : [];
  const applicantNumber = contactSet.ApplicantNumber;

  return {
    ...contactSet,
    Phone: addIntlPhoneAndLandlineFlagToContact({
      contactSet: isPhoneExisting,
      applicantNumber,
      countryCodeService,
      dynamicCountryCodeInputIdNameSetter,
      isNZ: $scope.isNZ,
      isInternationalPhoneNumbers: $scope.isInternationalPhoneNumbers,
    }),
  };
};

export const validateMobileList = ({ phoneList, isMobileValid, $scope }) => {
  return phoneList.map((data) => {
    if (data.Type.toLowerCase() === phoneType.mobile.toLowerCase()) {
      if (!$scope.Settings.isEdit && !data.Number) {
        return true;
      }
      return isMobileValid(data.Number);
    }
    return true;
  });
};

export const formatPhoneListSubmission = ({
  phones,
  formatExistingMobile,
  $scope,
  countryCodeService,
}) => {
  return phones.map((item) => {
    item.Number = $scope.isInternationalPhones({
      currPhoneType: item.Type,
      countryCodeService,
    })
      ? formatExistingMobile(item.Number)
      : item.Number;
    item.CountryCode = get(item, 'CountryCode', '').toLowerCase();
    return item;
  });
};

export const assignContactSetPhone = ({ $scope, countryCodeService }) => {
  const phoneList = get($scope.ContactSet, 'Phone');
  $scope.ContactSet.Phone = assignCountryCode({
    phoneNumbers: phoneList,
    countryCodeService,
  });
};

export const isPhoneExisting = ({ value, $scope }) => {
  const isMobileExisting =
    value.Phone &&
    value.Phone.filter(
      (data) => data.Type.toLowerCase() === phoneType.mobile.toLowerCase(),
    );
  const isRequiredOnInternationalPhone =
    $scope.isInternationalNumberAUNZ || ($scope.Settings.isEdit && $scope.isNZ);
  return (
    (!value.Phone || !value.Phone.length || !isMobileExisting.length) &&
    isRequiredOnInternationalPhone
  );
};

export const validatePhoneList = ({
  $scope,
  countryCodeInputElement,
  emptyNumberMessage,
  angular,
  countryCodeService,
  value,
}) => {
  if (!value || !value.Phone.length) {
    $scope.errorMessage = ERROR_PHONE_MESSAGE.INVALID_MOBILE_PHONE;
    return [false];
  }
  return value.Phone.map((data) => {
    const result = isNumberValid({
      element: countryCodeInputElement({
        angular,
        elementId: data.phoneId,
      }),
      isPhoneRequired: !isLandline(data.Type),
      message: emptyNumberMessage,
      phoneNumber: data.Number,
      isLandlineValid: data.isLandline,
      countryCodeService,
    });
    if (!result.isValid) {
      $scope.errorMessage = result.message;
    }
    return result.isValid;
  });
};

export const contactEmailValidation = ({ value, emailValidation, $scope }) => {
  const validateEmail =
    value.EmailAddress &&
    !emailValidation(value.EmailAddress) &&
    $scope.isInternationalPhoneNumbers;

  const isEmailMandatory =
    !$scope.Settings.isEdit &&
    !value.EmailAddress &&
    $scope.isInternationalPhoneNumbers;

  const isInvalid = validateEmail || isEmailMandatory;
  const message = isEmailMandatory
    ? ERROR_EMAIL_MESSAGE.EMPTY_EMAIL
    : ERROR_EMAIL_MESSAGE.INVALID_EMAIL;

  return { isInvalid, message };
};

export const initializeCountryCodePhoneVariables = ({
  dynamicCountryCodeInputIdNameSetter,
  $scope,
  currentUserService,
  configService,
  countryCodeService,
  mobileCountryDropdown,
}) => {
  const {
    internationalPhoneNumbers,
    phoneNumberCountryCodeNZ,
  } = configService.feature;

  $scope.phoneTypeField = {
    mobile: true,
    home: true,
    work: true,
  };

  $scope.emptyNumberMessage = ERROR_PHONE_MESSAGE.INVALID_MOBILE_PHONE;

  $scope.phoneTypeList = phoneType;

  $scope.dynamicCountryCodeInputIdNameSetter = dynamicCountryCodeInputIdNameSetter;
  $scope.hideAddPhoneField = hideAddPhoneField;
  $scope.hidePhoneType = hidePhoneType;
  $scope.isInternationalPhoneNumbers = internationalPhoneNumbers;
  mobileCountryDropdown({ $scope, currentUserService, configService });
  $scope.isNZ = currentUserService.isNZ;
  $scope.phoneNumberCountryCodeNZ = $scope.isNZ && phoneNumberCountryCodeNZ;
  $scope.isInternationalNumberAUNZ =
    $scope.isInternationalPhoneNumbers || $scope.phoneNumberCountryCodeNZ;
  $scope.countryCodeService = countryCodeService;
  $scope.emptyNumberMessage = ERROR_PHONE_MESSAGE.INVALID_MOBILE_PHONE;

  $scope.defaultCountryCode = defaultCountryCode({
    countryCodeService,
    isInternationalPhoneNumbers: $scope.isInternationalNumberAUNZ,
    defaultCountryCode: currentUserService.isAU,
  });
};

export const isInvalidWarning = ({ isInvalid, toastError, errorMessage }) => {
  if (isInvalid) {
    toastError(errorMessage);
  }
  return isInvalid;
};

export const initializeApplicantContactsetOnEdit = ({
  ContactSet,
  $scope,
  countryCodeService,
  dynamicCountryCodeInputIdNameSetter,
  member,
  person,
}) => {
  if (!ContactSet || !$scope) {
    return;
  }
  const newContactSet = addPropertiesToPartyApplicants({
    contactSet: ContactSet,
    $scope,
    countryCodeService,
    dynamicCountryCodeInputIdNameSetter,
  });

  $scope.partyApplicants.push(newContactSet);
  if (parseInt(member.PersonId, 10) === parseInt(person.PersonId, 10)) {
    $scope.ContactSet = newContactSet;
  }
};

export const initializeContactsetOnCreate = ($scope) => {
  if (!$scope) {
    return;
  }
  $scope.ContactSet.Phone = [];
  addPhoneOnInit($scope);
};

export const submissionErrorAction = ({ $scope, errKey }) => {
  if (!$scope) {
    return;
  }
  $scope.isSave = true;
  $scope.selectContact($scope.partyApplicants[errKey]);
  $scope.isSave = false;
};

export const removeApplicantAction = ($scope) => {
  $scope.isSave = true;
  $scope.partyApplicants.splice(1, 1);
  $scope.selectContact(first($scope.partyApplicants));
  $scope.isSave = false;
};

export const validatePhoneOnAddSelectContact = ($scope) => {
  if (!$scope) {
    return false;
  }
  return $scope.isInternationalNumberAUNZ && !$scope.isSave;
};

export const isInternationalPhoneType = ({
  currPhoneType,
  countryCodeService,
}) => {
  return countryCodeService.isInternationalPhoneCountryCode(currPhoneType);
};
