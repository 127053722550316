import {
  providerInfoBuilderForUI,
  loanRateBuilderForUI,
  providerCategoryForUI,
  providerContactPointsForUI,
  providerKeyContactsBuilderForUI,
  providerStatesBuilderForUI,
  lendersFormBuilderForMycrm,
  providerSaveDocumentsForAPI,
} from 'Common/mappers/providerInformation';
import { mapProviderDocuments } from 'Common/utilities/providerInfo';

export default class ProviderInformationService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'provider';
    this.apiBaseUrlLoanTools = 'LoanTools';
    this.corporate = 'corporate';
  }

  getProvidersInformation(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}`, params)
      .then(({ data }) => {
        if (!data || !data.Providers || !data.Providers.length) {
          return data.Providers;
        }
        return data.Providers.map((provider) => {
          const providerInfo = providerInfoBuilderForUI(provider);
          providerInfo.variableRate =
            provider.VariableRate &&
            loanRateBuilderForUI(provider.VariableRate);
          providerInfo.loanRates =
            provider.LoanRatesOverview &&
            provider.LoanRatesOverview.map(loanRateBuilderForUI);
          providerInfo.categories =
            provider.Categories &&
            provider.Categories.map(providerCategoryForUI);
          return providerInfo;
        });
      });
  }

  getProviderCategories() {
    const useCache = true;
    const clearCache = false;
    return this.httpClient
      .get(`${this.apiBaseUrl}/categories`, {}, useCache, clearCache)
      .then(({ data }) => data.map(providerCategoryForUI));
  }

  getUserProviderCategories() {
    return this.httpClient.get(`${this.apiBaseUrl}/user-categories`);
  }

  getKeyRelationship(providerId) {
    const params = { providerID: providerId };
    return this.httpClient
      .get(
        `${this.apiBaseUrlLoanTools}/LoanProviderKeyRelationshipsGet`,
        params,
      )
      .then(({ data }) => {
        if (!data || !data.length) {
          return data;
        }
        return data.map(providerKeyContactsBuilderForUI);
      });
  }

  reportingStatesGet() {
    return this.httpClient
      .get(`${this.corporate}/ReportingStatesGet`)
      .then(({ data }) => {
        if (!data || !data.length) {
          return data;
        }
        return data.map(providerStatesBuilderForUI);
      });
  }

  deleteKeyContact(keyRelationshipId) {
    const params = { keyRelationshipID: keyRelationshipId };
    return this.httpClient.delete(
      `${this.apiBaseUrlLoanTools}/LoanProviderKeyRelationshipsDelete`,
      params,
    );
  }

  saveKeyContact(data) {
    const formLenderData = lendersFormBuilderForMycrm(data);
    return this.httpClient.post(
      `${this.apiBaseUrlLoanTools}/LoanProviderKeyRelationshipsSet`,
      formLenderData,
    );
  }

  setUserProviderCategories(categoryIds) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/categories`,
      {},
      { categoryIds },
    );
  }

  getProviderContactPoints(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/contact-points`, params)
      .then(({ data }) => data.map(providerContactPointsForUI));
  }

  setProviderDocuments(params = {}) {
    const mapParams = params.providerDocument.map(providerSaveDocumentsForAPI);
    return this.httpClient.post(
      `${this.apiBaseUrl}/${params.providerId}/documents`,
      mapParams,
    );
  }

  getProviderDocuments(providerId, concatFileNameAndTitle, isInsurer = false) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${providerId}/documents`, { isInsurer })
      .then(({ data }) => mapProviderDocuments(data, concatFileNameAndTitle));
  }

  setProviderDocFavoriteState(providerDocId, params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/document/${providerDocId}`,
      null,
      params,
    );
  }

  deleteProviderDoc(providerDocId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/document/${providerDocId}`,
    );
  }
}
