export function leadsToApplicationConvert({
  modalRenderService,
  loans = [],
  leadData,
}) {
  return modalRenderService.renderLeadsToApplicationFactFindModal(
    loans,
    leadData,
  );
}
