import angular from 'angular';
import { parseToInt10 } from 'Common/utilities/parse';

export const loadModule = () =>
  angular
    .module('app')
    .directive('smallWidgetSettings', function smallWidgetSettings() {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
          myParam: '=',
          indexInArray: '=',
        },
        templateUrl:
          'assets/views/overviewDashboard/directiveTemplates/smallWidgetSettings.html',

        link(scope) {
          // eslint-disable-next-line unicorn/consistent-function-scoping
          function init() {
            scope.myParam.toShow = scope.myParam.dropDownOption.some(
              (item) =>
                parseToInt10(item.WidgetId) ===
                parseToInt10(scope.myParam.WidgetId),
            );
          }

          scope.updateWidgetName = function (widgetId) {
            scope.myParam.WidgetName = scope.myParam.dropDownOption.filter(
              (item) => parseInt(item.WidgetId, 10) === parseInt(widgetId, 10),
            )[0].WidgetName;

            scope.myParam.DisplayName =
              parseInt(widgetId, 10) === 0 ? '' : scope.myParam.WidgetName;
            scope.myParam.Widget_UserID = 0;
          };

          init();
        },
      };
    });
