import { toastSuccess } from 'Common/utilities/alert';
import { setupNewPostURL } from 'Common/utilities/handleObsoleteURL';
import { isNotRegisteredNZBNChar } from 'Common/utilities/nzbnChecker';
import { ENTITY_PARTY_TYPES } from 'Common/constants/partyTypes';

export const addTrustExtent = (propertyOf) => {
  const myCRMconfigs = {
    ...propertyOf.currentUserService,
    ...propertyOf.configService,
  };

  return {
    onContactServiceSet: (sendData) => {
      propertyOf.contactService
        .organisationInfoSet(setupNewPostURL(sendData, 'organisation'))
        .then((response) => {
          propertyOf.isSaving = !propertyOf.isSaving;
          const checkNZBNChar = isNotRegisteredNZBNChar(
            { ...response, myCRMconfigs },
            'organisation',
          );

          if (checkNZBNChar) {
            return;
          }

          toastSuccess('Contact has been Saved Successfully.', 'Saved!');
          const { data: FamilyId } = response;
          const PartyType = String(ENTITY_PARTY_TYPES.COMPANY);
          const isEdit = !!propertyOf.entity;
          propertyOf.modalInstance.close({
            isEdit,
            FamilyId,
            PartyType,
          });
        });
    },
  };
};
