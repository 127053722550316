import { toastWarning } from 'Common/utilities/alert';

export default class MycrmDateSpanCtrl {
  validateEndDate() {
    if (this.dateObj.end && this.dateObj.start > this.dateObj.end) {
      this.dateObj.end = this.dateObj.start;
      toastWarning(
        'End Date should not precede Start Date. \nEnd Date has been adjusted.',
      );
    }
  }

  onDateChange(date, key) {
    this.dateObj[key] = date;
    this.validateEndDate();
    this.onDateSpanChange({ data: this.dateObj });
  }

  clearDate() {
    this.dateObj.start = null;
    this.dateObj.end = null;
    this.onDateSpanChange({ data: this.dateObj });
  }
}
