class OfficeAddressCtrl {
  constructor($timeout, generalService) {
    'ngInject';

    this.$timeout = $timeout;
    this.generalService = generalService;
  }

  fullAutoAddress(searchString) {
    this.$timeout.cancel(this.searchTimeout);
    this.searchTimeout = this.$timeout(() => {
      this.generalService.placeSearch(searchString).then((response) => {
        this.fullAutoAddressDetails = response.data;
      });
    }, 500);
  }

  selectFullAddress(fullAddress) {
    this.addressModel.FullAddress = fullAddress;
    this.addressModel.Address = { ...fullAddress };
    this.closeAutoFullAddress();
  }

  closeAutoFullAddress() {
    this.fullAutoAddressDetails = [];
  }
}

export default OfficeAddressCtrl;
