export const LOAN_STATUS_CATEGORY = {
  EXISTING: 'Existing',
  IN_PROGRESS: 'InProgress',
  OPPORTUNITY: 'Opportunity',
  PREVIOUS: 'Previous',
  LEAD: 'Lead',
};
export const LOAN_STATUS = {
  EXISTING: {
    SETTLED: 1,
    VARIED: 63,
  },
  IN_PROGRESS: {
    NEW_APPLICATION: 8,
    LODGED: 9,
    PRE_APPROVED: 3,
    CONDITIONAL_APPROVAL: 5,
    UNCONDITIONAL_APPROVAL: 4,
    PENDING_SETTLEMENT: 20,
  },
  PREVIOUS: {
    REPAID: 13,
    NOT_PROCEEDED_WITH: 19,
    DECLINED: 11,
  },
};

export const LEAD_LOAN_STATUS = {
  NEW_LEADS: 61,
};
