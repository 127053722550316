import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .controller('MyBrandsCtrl', function MyBrandsCtrl(
      $scope,
      $timeout,
      $uibModal,
      userService,
    ) {
      /**
       * Get Brand Info
       * */
      $scope.BrandsInfoGet = function () {
        userService.BrandsInfoGet().then((response) => {
          $scope.BrandsInfo = response.data;
        });
      };

      /**
       * Initialize Brands
       * */
      $scope.BrandsInfoGet();

      /**
       * Toggle My Brands Profile
       * */
      $scope.custom_profile = false;
      $scope.toggleBrandsProfile = function () {
        $scope.custom_profile = !$scope.custom_profile;
      };
    });
