export default class WidgetCurrencyInputCtrl {
  $onInit() {
    this.initSubModelValue();
  }

  initSubModelValue() {
    const hasDefaultValueIndex =
      this.defaultSubValueIndex && this.defaultSubValueIndex >= 0;
    if (!this.modelSubValue && hasDefaultValueIndex) {
      this.modelSubValue = this.defaultSubValueIndex;
      this.onSubDropdownUpdate({ value: this.modelSubValue });
    }
  }
}
