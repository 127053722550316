import angular from 'angular';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('DashboardCtrl', function DashboardCtrl(
      $scope,
      $http,
      configService,
      $window,
      dashboardService,
      imageDataURI,
      $timeout,
    ) {
      $http.defaults.headers.common.Authorization = configService.token;

      dashboardService.getCategory().then((response) => {
        $scope.mainCategories = response.data;
        angular.forEach(response.data, (category) => {
          dashboardService
            .getAllItemBycategory(category)
            .then((catResponse) => {
              const listItems = [];
              let itemsBy5 = [];
              let idx = 0;
              angular.forEach(catResponse.data, (value, index) => {
                idx = index;
                idx += 1;
                itemsBy5.push(value);
                if (idx % 5 === 0 && idx > 1) {
                  listItems.push(itemsBy5);
                  itemsBy5 = [];
                }
              });
              $scope.mainCategories[category] =
                catResponse.data.length < 5 ? [itemsBy5] : listItems;
            });
        });
      });
      $scope.screenSize = $window.innerWidth;

      // Function that find the exact width of the viewport in a cross-browser way
      const viewport = () => {
        let e = window;
        let a = 'inner';
        if (!('innerWidth' in window)) {
          a = 'client';
          e = $window.document.documentElement || $window.document.body;
        }
        return {
          width: e[`${a}Width`],
        };
      };

      // function that adds information in a scope of the width of the page
      $scope.getWindowWidth = function () {
        return {
          w: viewport().width,
        };
      };

      $scope.getItems = {};
      // Retrieve dashboard items
      $scope.loadDashboardItems = function () {
        dashboardService.getDashboardItem().then((response) => {
          const items = response.data;
          $scope.getItems = items;
          $scope.renderDashboardItems();
          $scope.layoutId = response.data.LayoutId;
        });
      };

      // Retrieve existing dashboard items
      $scope.loadDashboardItems();

      // Detect when window is resized and set some variables
      $scope.$watch(
        $scope.getWindowWidth,
        (newValue) => {
          $scope.screenSize = newValue.w;

          if (newValue.w >= 992) {
            $scope.screenSize -= 235;
          }
          if ($scope.getItems.length > 0) {
            $scope.renderDashboardItems();
          }
        },
        true,
      );

      // get dashboard row as per item
      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.renderDashboardItems = function () {
        const items = $scope.getItems;
        let itemsRow = [];
        const itemsArray = [];
        const widgetMinWidth = 430;
        let widgetItemPerRow = Math.floor($scope.screenSize / widgetMinWidth);
        if (widgetItemPerRow <= 0) {
          widgetItemPerRow = 1;
          $scope.responsivewidget = `col-md-${Math.floor(
            12 / widgetItemPerRow,
          )}`;
        } else if (parseInt(widgetItemPerRow, 10) === 5) {
          $scope.responsivewidget = `col-md-${Math.floor(
            12 / (widgetItemPerRow - 1),
          )}`;
        } else if (widgetItemPerRow <= 11 && widgetItemPerRow > 6) {
          widgetItemPerRow = 6;
          $scope.responsivewidget = `col-md-${Math.floor(
            12 / widgetItemPerRow,
          )}`;
        } else if (parseInt(widgetItemPerRow, 10) > 11) {
          $scope.responsivewidget = 'col-lg-1';
        } else {
          $scope.responsivewidget = `col-md-${Math.floor(
            12 / widgetItemPerRow,
          )}`;
        }

        angular.forEach(items, (value, key) => {
          if (key % widgetItemPerRow === 0 && key >= 1) {
            itemsArray.push(itemsRow);
            itemsRow = [];
          }
          items[key].element = {};
          items[key].element.id = `panel_${items[key].ActualId}`;
          itemsRow.push(items[key]);

          if (items[key].Name === 'Interest Rates') {
            const itemHeader = items[key].ResultTable.DashboardResultHeader;
            const itemContent = items[key].ResultTable.DashboardResultContent;

            angular.forEach(itemContent, (itemValue, itemKey) => {
              let changeIndex = 1;
              // convert image to data uri
              imageDataURI(
                `/assets/images/company/${itemValue[0].Text.toLowerCase().replace(
                  / /g,
                  '_',
                )}.png`,
                (dataUri) => {
                  itemValue[0].Text = dataUri;
                },
              );
              angular.forEach(itemHeader, (content, index) => {
                itemContent[itemKey][index].DisplayData =
                  itemHeader[index].DisplayColumn;
                if (!itemHeader[index].DisplayColumn) {
                  switch (true) {
                    case itemContent[itemKey][index] < 0:
                      itemContent[itemKey][changeIndex].classData =
                        'text-pink text-bold';
                      break;
                    case itemContent[itemKey][index] > 0:
                      itemContent[itemKey][changeIndex].classData =
                        'text-green-light text-bold';
                      break;
                    default:
                      itemContent[itemKey][changeIndex].classData =
                        'text-info text-bold';
                      break;
                  }
                  changeIndex++;
                }
              });
            });
          }

          // check if it's last item and append to items array
          if (items.length - 1 === key) {
            itemsArray.push(itemsRow);
          }
        });
        $scope.dashboardItems = itemsArray;
      };

      // Add new item to the existing dashboard layout
      $scope.addNewItem = function (itemId) {
        dashboardService
          .saveDashboardItem({
            ItemId: itemId,
          })
          .then(() => {
            const dashboardItemsTimeout = $timeout(() => {
              swal(
                'Success',
                'New item has been added to the dashboard.',
                'success',
              );
              $scope.loadDashboardItems();
              $timeout.cancel(dashboardItemsTimeout);
            });
          })
          .catch(displayError);
      };

      // Remove item from dashboard
      $scope.removeItem = function (actualId) {
        swal(
          {
            title: 'Are you sure?',
            text: 'This item will be removed on your current layout',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: false,
          },
          (isConfirm) => {
            if (isConfirm) {
              /* $http.delete(url) */
              dashboardService
                .deleteDashboardItem(actualId)
                .then(() => {
                  angular
                    .element($window.document)
                    .find(`#panel_${actualId}`)
                    .remove();
                  swal(
                    'Deleted',
                    'Dashboard item has been deleted.',
                    'success',
                  );
                })
                .catch(displayError);
            }
          },
        );
      };
    });
