import swal from 'sweetalert';

class SetDefaultTemplateCtrl {
  constructor($timeout, userService, templateService) {
    'ngInject';

    this.$timeout = $timeout;
    this.userService = userService;
    this.templateService = templateService;
  }

  $onInit() {
    this.getCustomerCareModules();
  }

  getCustomerCareModules() {
    this.isLoadingModules = true;
    this.templateService
      .getCustomerCareModulesDefaultTemplates(this.methodType, this.isInsurance)
      .then(
        (response) => {
          this.customerCareModules = response.data;
          this.isLoadingModules = false;
        },
        () => {
          this.isLoadingModules = false;
        },
      );
  }

  hasValidSelectedTemplate() {
    return (
      this.templateToSet &&
      this.templateToSet.UserTemplateName &&
      this.templateToSet.UserTemplateID
    );
  }

  isTemplateDefaultFor(module) {
    return (
      this.templateToSet &&
      this.templateToSet.UserTemplateID === module.UserTemplateID
    );
  }

  setTemplateAsDefaultFor(module) {
    if (module && module.UserTemplateID && module.UserTemplateName) {
      swal(
        {
          title: 'Are you sure you want to change the default?',
          text: `This area is currently using ${module.UserTemplateName} as the default template. Changing this will affect all your future communications. This action cannot be undone.`,
          type: 'warning',
          allowOutsideClick: true,
          showCancelButton: false,
          confirmButtonColor: '#42B4B4',
          confirmButtonText: 'I understand',
          closeOnConfirm: true,
        },
        (isConfirm) => {
          if (isConfirm) {
            this.saveTemplateAsDefaultFor(module);
          }
        },
      );
    } else {
      this.saveTemplateAsDefaultFor(module);
    }
  }

  saveTemplateAsDefaultFor(module) {
    const defaultModuleTemplate = {
      moduleId: module.ModuleID,
      userTemplateId: this.templateToSet.UserTemplateID,
      userTemplateTypeID: this.methodType,
    };
    this.userService.UserDefaultTemplateSet(defaultModuleTemplate).then(
      () => {
        module.UserTemplateID = this.templateToSet.UserTemplateID;
        module.UserTemplateName = this.templateToSet.UserTemplateName;
        SetDefaultTemplateCtrl.onDefaultTemplateSet();
      },
      () => {
        SetDefaultTemplateCtrl.onSetDefaultTemplateFailed();
      },
    );
  }

  static onDefaultTemplateSet() {
    swal({
      title: 'Template set as default successfully',
      text: '',
      type: 'success',
      showCancelButton: false,
      confirmButtonColor: '#42B4B4',
      confirmButtonText: 'Got it',
      closeOnConfirm: true,
    });
  }

  static onSetDefaultTemplateFailed() {
    swal({
      title: 'Yikes! Something is wrong',
      type: 'error',
      showCancelButton: false,
      closeOnConfirm: true,
    });
  }
}

export default SetDefaultTemplateCtrl;
