import angular from 'angular';
import { SMS_SUBSCRIPTION } from 'Common/constants/smsSubcription';

export const loadModule = () =>
  angular
    .module('app')
    .controller(
      'OpenSMSSubscriptionModalCtrl',
      function OpenSMSSubscriptionModalCtrl(
        $scope,
        $uibModalInstance,
        $uibModal,
        familyId,
        generalService,
      ) {
        // modal scope
        angular.extend($scope, {
          cancel() {
            $uibModalInstance.dismiss('cancel');
          },
          Settings: {
            isActivating: false,
          },
          activateSubscription() {
            const vm = this;
            vm.Settings.isActivating = true;

            // Set
            generalService
              .SMSSubscriptionStatusSet(
                familyId,
                SMS_SUBSCRIPTION.SUBSCRIPTION_FOR_APPROVAL,
                0,
              )
              .then(() => {
                $uibModalInstance.close();
              });
          },
        });
      },
    );
