import moment from 'moment';
import { toastError, toastSuccess, alertError } from 'Common/utilities/alert';
import { COLOR } from 'Common/constants/colors';
import { DDMMMYYYY_FORMAT } from 'Common/constants/dateConfigs';

export default class GamePlanGeneratorService {
  constructor(
    gamePlanService,
    modalRenderService,
    currentUserService,
    downloadDataService,
    loanScenarioService,
    fundingCalculatorService,
  ) {
    'ngInject';

    this.gamePlanService = gamePlanService;
    this.modalRenderService = modalRenderService;
    this.currentUserService = currentUserService;
    this.downloadDataService = downloadDataService;
    this.loanScenarioService = loanScenarioService;
    this.fundingCalculatorService = fundingCalculatorService;
  }

  download({ loanAppId, payload, fileName }) {
    return this.gamePlanService
      .download(loanAppId, payload)
      .then((response) => {
        if (!response || !response.documentContent) {
          toastError();
          return;
        }
        const fileDate = moment().format(DDMMMYYYY_FORMAT);
        const fileType = payload.IsApplicant ? 'applicant' : 'guarantor';
        const finalFileName = `${payload.DocumentTitle}_${fileType}_${fileName}_${fileDate}.pdf`;

        const { contentType, documentContent } = response;
        this.downloadDataService.download(
          `data:${contentType};base64,${documentContent}`,
          finalFileName,
          contentType,
        );

        toastSuccess('Game Plan downloaded successfully!');
      });
  }

  send({ loanAppId, payload, crmConfirmation }) {
    return this.gamePlanService.send(loanAppId, payload).then((response) => {
      if (!response) {
        toastError();
        return;
      }
      const title = payload.IsApplicant ? 'Applicant' : 'Guarantor';
      crmConfirmation.open({
        type: 'success',
        title: `${title} Game Plan Sent!`,
        description: `Your client will receive an email and SMS notification.`,
        buttonText: 'Okay got it',
        modalSize: 'md',
      });
    });
  }

  saveDefault({ loanAdviserId, payload }) {
    return this.gamePlanService.saveDefaultSettings({ loanAdviserId, payload });
  }

  getDefaults({ loanAdviserId, params }) {
    return this.gamePlanService
      .getDefaultSettings({ loanAdviserId, params })
      .then((response) => {
        if (!response) {
          alertError('Something went wrong.', true, 'Error');
          return;
        }

        return response;
      });
  }

  loadCQPData({ loanAppId }) {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const errorCallback = (e) => toastError(e.Message || e.ExceptionMessage);

    const getCPACQP = () =>
      this.loanScenarioService
        .RecommendationReportStatusGet(loanAppId)
        .then(({ data }) => {
          return data;
        }, errorCallback);

    const getLoanDetails = () =>
      this.loanScenarioService
        .getFundingCalculatorNewBrokerEventForScenario(loanAppId)
        .then(({ data }) => {
          return this.fundingCalculatorService
            .getFundingCalculatorFundsSummary(data, loanAppId)
            .then((fundingCalculatorFundsSummary) => {
              const calculatorFundsSummary = fundingCalculatorFundsSummary;

              return this.loanScenarioService
                .loanDetailsGet(loanAppId)
                .then(({ data: loanDetails }) => {
                  return {
                    loanDetails,
                    calculatorFundsSummary,
                  };
                }, errorCallback);
            }, errorCallback);
        }, errorCallback);

    return Promise.all([getCPACQP(), getLoanDetails()]);
  }

  openCQP(props, customClass = '') {
    const creditQuoteTitleText = this.getCreditQuoteDetailsTitle();
    const SENDING_MOMENTS_HEADER_ITEMS = [
      {
        id: 'credit-quote-details',
        title: creditQuoteTitleText,
      },
      {
        id: 'customize-game-plan',
        title: '<p>Customize Game Plan</p>',
        renderAsComponent: true,
        customBgColor: COLOR.LONDON_HUE,
      },
    ];

    const modalClass = `generate-game-plan-cqp-modal ${customClass}`;

    const customProps = {
      headerComponent: `<game-plan-sending-moment-header
        items="vm.commonProps.SENDING_MOMENTS_HEADER_ITEMS"
      ></game-plan-sending-moment-header>`,
      sidebarComponent: null,
      actionButtonsComponent: null,
      mainComponent: `<credit-quote-details-modal
          id="${modalClass}"
          loan-details="vm.commonProps.loanDetails"
          loan-app-id="vm.commonProps.loanAppId"
          cpa-cqp-set="vm.commonProps.cpaCqpSet"
          calculator-funds-summary="vm.commonProps.calculatorFundsSummary"
          is-asset-finance="vm.commonProps.isAssetFinance"
          is-appended-only="vm.commonProps.isAppendedOnly"
          on-next="vm.commonProps.openSendingMoment()"
          loan-adviser-id="vm.commonProps.loanAdviserId"
        ></credit-quote-details-modal>`,
      commonProps: {
        SENDING_MOMENTS_HEADER_ITEMS,
        ...props,
        openSendingMoment: () => {
          this.modalInstance.close();
          this.openSendingMoment(props);
        },
      },
    };

    this.modalInstance = this.modalRenderService.renderSendingStyledContainer(
      customProps,
      'game-plan-cqp-modal',
    );
  }

  getCreditQuoteDetailsTitle() {
    return this.currentUserService.isNZ
      ? 'Commission and Fees'
      : 'Credit Quote Details';
  }

  openSendingMoment(props) {
    const creditQuoteTitleText = this.getCreditQuoteDetailsTitle();

    const SENDING_MOMENTS_HEADER_ITEMS = [
      {
        id: 'credit-quote-details',
        title: `<p>${creditQuoteTitleText} &nbsp;<check class="icon"></check></p>`,
        renderAsComponent: true,
        customBgColor: COLOR.SCOOTER,
      },
      {
        id: 'customize-game-plan',
        title: 'Customize Game Plan',
      },
    ];

    const customProps = {
      headerComponent: `<game-plan-sending-moment-header
          items="vm.commonProps.SENDING_MOMENTS_HEADER_ITEMS"
        ></game-plan-sending-moment-header>`,
      sidebarComponent: `<game-plan-sending-moment-sidebar 
          loan-app-id="vm.commonProps.loanAppId"
          applicants="vm.commonProps.applicants"
          loan-adviser-id="vm.commonProps.loanAdviserId"
        >
        </game-plan-sending-moment-sidebar>`,
      actionButtonsComponent: null,
      mainComponent: `<game-plan-sending-moment-main
        loan-app-id="vm.commonProps.loanAppId"
        applicants="vm.commonProps.applicants"
        loan-adviser-id="vm.commonProps.loanAdviserId"
      ></game-plan-sending-moment-main>`,
      commonProps: {
        SENDING_MOMENTS_HEADER_ITEMS,
        ...props,
      },
    };

    return this.modalRenderService.renderSendingStyledContainer(
      customProps,
      'game-plan-generate-modal',
    );
  }
}
