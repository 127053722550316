import {
  DOC_GENERATOR,
  HELPTEXT_GENERATOR,
} from 'Common/constants/generateReports';
import {
  isAssetFinanceCategory,
  isAssetFinanceFlagOn,
} from 'Common/utilities/lendingCategory';
import { GAME_PLAN_GENERATE } from 'Components/opportunity/util/constants';

export default class ApplicationWidgetsCtrl {
  constructor(
    gamePlanGeneratorService,
    currentUserService,
    configService,
    $scope,
    $timeout,
  ) {
    'ngInject';

    this.gamePlanGeneratorService = gamePlanGeneratorService;
    this.currentUserService = currentUserService;
    this.configService = configService;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.gamePlanGenerationEventCleanUp = null;
  }

  $onInit() {
    this.gamePlanGenerationEventCleanUp = this.$scope.$on(
      GAME_PLAN_GENERATE,
      () => {
        this.validate();
      },
    );
  }

  $onDestroy() {
    this.gamePlanGenerationEventCleanUp &&
      this.gamePlanGenerationEventCleanUp();
  }

  validate() {
    const { bidLoanAppChangesValidation } = this.configService.feature;
    this.isLoading = true;

    const method = DOC_GENERATOR.GAME_PLAN;
    if (!bidLoanAppChangesValidation) {
      return this.generateGamePlan(true);
    }
    this.onGamePlanValidate({
      callback: (isValid) => this.generateGamePlan(isValid),
      helpText: HELPTEXT_GENERATOR[method],
    });
  }

  generateGamePlan(isValid) {
    this.isLoading = false;
    if (!this.loanAppId || !isValid) {
      return;
    }

    this.gamePlanGeneratorService
      .loadCQPData({ loanAppId: this.loanAppId })
      .then((response) => {
        const [cpaCQPResponse, loanDetailsResponse] = response;

        const props = {
          loanAppId: this.loanAppId,
          isEditEmailMessage: false,
          applicants: this.applicants,
          loanAdviserId: this.fileGeneralDetails.adviserId,
          loanDetails: loanDetailsResponse.loanDetails,
          cpaCqpSet: cpaCQPResponse,
          calculatorFundsSummary: loanDetailsResponse.calculatorFundsSummary,
          isAssetFinance:
            isAssetFinanceFlagOn({ configService: this.configService }) &&
            isAssetFinanceCategory({
              currentUserService: this.currentUserService,
              lendingCategoryId:
                loanDetailsResponse.loanDetails.LendingCategoryId,
            }),
          isAppendedOnly: true,
        };

        this.gamePlanGeneratorService.openCQP(props);
      });
  }
}
