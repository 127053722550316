import { isMobileView } from 'Common/utilities/clientWindowWatcher';
import workbenchSuccess from '../successModal';

class WorkbenchAddNoteCtrl {
  constructor($state, contactsNotesService, $window, toaster, noteService) {
    'ngInject';

    this.$state = $state;
    this.contactsNotesService = contactsNotesService;
    this.$window = $window;
    this.toaster = toaster;
    this.noteService = noteService;
  }

  $onInit() {
    this.modalInstance = this.modalInstance || null;
    this.isMobileViewWidth = isMobileView(this.$window.innerWidth);
    this.isAdding = false;
    if (!this.noteToEdit) {
      this.setNote = this.setNoteModel();
    } else {
      this.setNote = Object.assign(
        {},
        {
          ActivityTitle: this.noteToEdit.Title,
          ActivityDetails: this.noteToEdit.Detail,
          CommunicationID: this.noteToEdit.CommunicationID,
          LoanId: this.loanId,
          WorkbenchQuoteID: this.quoteId,
          NoteTypeID: this.noteToEdit.NoteTypeID,
        },
      );
    }

    // adding familyID to our main object
    if (this.$state.params.adviserOrgId) {
      this.setNote.FamilyID = 0;
      this.setNote.BrokerBrandingID = this.$state.params.adviserOrgId;
    } else {
      this.setNote.FamilyID = this.familyId;
    }
  }

  setNoteModel() {
    return {
      IsSticky: false,
      FamilyID: 0,
      ActivityTitle: '',
      ActivityDetails: '',
      CommunicationID: 0,
      ActivityOwner: '',
      ActivityDate: new Date(),
      AdviserName: '',
      AdviserId: '',
      IsReferrer: false,
      IsPrivate: true,
      IsNotification: false,
      NotificationSMS: false,
      NotificationEmail: false,
      ActivityType: '',
      Notes: '',
      LoanId: this.loanId,
      WorkbenchQuoteID: this.quoteId,
      NoteTypeID: 0,
    };
  }

  saveCreateNote() {
    if (!this.setNote.ActivityTitle || !this.setNote.ActivityDetails) {
      this.toaster.pop('warning', 'Invalid Note', 'Please create a valid note');
    } else {
      this.isAdding = true;
      const msg = this.setNote.CommunicationID ? 'updated.' : 'created.';
      this.contactsNotesService.noteSet(this.setNote).then(() => {
        this.isAdding = false;
        this.noteService.action.refreshNoteList();
        workbenchSuccess(`Note has been successfully ${msg}`, () => {
          if (!this.isMobileViewWidth) {
            this.onBack({ $event: { refresh: true } });
          } else {
            this.closeModal();
          }
        });
      });
    }
  }

  onNoteTypeChange({ noteType }) {
    this.setNote.NoteTypeID = noteType.id;
  }

  closeModal() {
    if (!this.modalInstance) {
      return;
    }
    this.modalInstance.dismiss();
  }
}

export default WorkbenchAddNoteCtrl;
