import {
  formatDate as commonFormatter,
  getStartOfMonth,
  computeDays,
} from 'Common/utilities/date';
import { TO_MYCRM_DATE_FORMAT } from 'Common/constants/dateConfigs';
import { COMMISSION_STATS, HEADER_COUNT } from '../constants';

export const formatWidgetData = (data, type) => {
  if (!data) {
    return {};
  }

  const { Amount: amount, Percentage: percentageRaw } = data;
  const isPercentageUp = percentageRaw >= 1;

  return {
    isLoading: false,
    isPercentageUp,
    amount,
    widgetTitle: COMMISSION_STATS[type].TITLE,
    mobileTitle: COMMISSION_STATS[type].MOBILE_TITLE,
    textColor: COMMISSION_STATS[type].TEXTCOLOR,
    percentage: isPercentageUp
      ? percentageRaw.toFixed(0)
      : (percentageRaw * 100).toFixed(0),
  };
};

export const formatDate = commonFormatter(TO_MYCRM_DATE_FORMAT);

export const computeTotalCommission = (list, item) => {
  const isInvalid = !list || !list.length || !item;
  if (isInvalid) {
    return 0;
  }

  return list.reduce((sum, { TransactionDate, Commission }) => {
    return formatDate(TransactionDate) === item.date ? sum + Commission : sum;
  }, 0);
};

export const structureHistoryHeader = (headers, data) => {
  const isInvalid = !headers || !headers.length;
  if (isInvalid) {
    return [];
  }

  return headers.map((item) => ({
    ...item,
    totalCommission: computeTotalCommission(data, item),
    transactionList: data.filter((trans) => trans.formattedDate === item.date),
  }));
};

export const getHeaderCount = (isMobile) => {
  return isMobile ? HEADER_COUNT.MOBILE : HEADER_COUNT.DESKTOP;
};

export const getClassName = ({ statementDates, currentDate, mode }) => {
  return statementDates.includes(currentDate) && mode === 'day'
    ? 'with-statement'
    : '';
};

export const getStatementMonths = (date) => {
  if (!date) {
    return [];
  }
  const firstDateCurrMonth = getStartOfMonth(date, TO_MYCRM_DATE_FORMAT);
  const firstDatePrevMonth = getStartOfMonth(
    computeDays('subtract', 'months')(date, 1),
    TO_MYCRM_DATE_FORMAT,
  );
  return [firstDateCurrMonth, firstDatePrevMonth];
};
