import angular from 'angular';
import { setFocus } from './util/istevenAutofocus';

export const loadModule = () =>
  angular
    .module('app')
    .directive('istevenAutofocus', function istevenAutofocus($timeout) {
      return {
        restrict: 'A',
        link(scope, element) {
          const focus = setFocus(element);
          const autoFocusTimer = $timeout(focus);
          scope.$on('$destroy', () => {
            $timeout.cancel(autoFocusTimer);
          });
        },
      };
    });
