import angular from 'angular';
import scenes from './scenes';
import components from './components';
import services from './services';

export default angular.module('scenes.businessEmailMailTemplates', [
  components,
  scenes,
  services,
]).name;
