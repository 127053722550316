import {
  addType,
  removeType,
  selectFrequency,
  selectOwner,
  createTypes,
} from 'Common/utilities/livingExpense';
import { COLOR } from 'Common/constants/colors.js';
import { JOINT_OWNERSHIP } from 'Common/constants/livingExpense';
import swal from 'sweetalert';
import _ from 'lodash';
import { toastError } from 'Common/utilities/alert';

class ExpenseGridModalCtrl {
  constructor(contactModelService, utilitiesService, financialsSharedService) {
    'ngInject';

    this.contactModelService = contactModelService;
    this.utilitiesService = utilitiesService;
    this.financialsSharedService = financialsSharedService;
    this.addType = addType;
    this.removeType = removeType;
    this.selectOwner = selectOwner;
    this.jointOwnership = JOINT_OWNERSHIP;
  }

  $onInit() {
    this.isSavingExpense = false;
    this.settings = {
      isTheSameFrequency: false,
    };
    this.getExpenseTypes();
    this.borrowers = this.mapBorrowers(this.borrowers);
  }

  selectFrequency(type, value) {
    selectFrequency(type, value);
    this.checkSameFrequency();
  }

  getExpenseTypes() {
    if (!this.familyId) {
      return;
    }
    this.contactModelService
      .getExpenseTypes({ familyId: this.familyId })
      .then((data) => {
        const { financialInfo } = data;
        this.expenseTypes = [...financialInfo];
        this.checkSameFrequency();
      });
  }

  checkSameFrequency() {
    const baseFrequency = _.get(this.expenseTypes, '[0].types[0].frequencyId');
    this.settings.isTheSameFrequency = this.expenseTypes.reduce(
      (initial, expense) => {
        const { types } = expense;
        return types.every((type) => {
          return type.frequencyId === baseFrequency;
        });
      },
      false,
    );
    this.settings.defaultFrequencyId = this.settings.isTheSameFrequency
      ? baseFrequency
      : this.settings.defaultFrequencyId;
  }

  mapBorrowers(borrowers) {
    if (!borrowers || !borrowers.length) {
      return;
    }
    return borrowers.map((borrower) => {
      return {
        ...borrower,
        initials: this.utilitiesService.filterInitial(
          borrower.FirstName,
          borrower.LastName ? borrower.LastName : '',
        ),
        background: this.financialsSharedService.getBGcolor(
          borrower.BorrowerID,
        ),
        preferedName: `${borrower.FirstName} ${borrower.LastName}`,
      };
    });
  }

  checkModalSameFrequency(value) {
    if (!value) {
      return;
    }
    if (this.settings.isTheSameFrequency) {
      this.setDefaultFrequencyId(value);
      return;
    }
    swal(
      {
        title: 'Are you sure?',
        text:
          'This will update ALL expense frequencies. Do you really want to proceed?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: COLOR.SWEET_PINK,
        confirmButtonText: 'Yes, update it!',
        closeOnConfirm: true,
      },
      (confirm) => {
        if (!confirm) {
          return;
        }
        this.setDefaultFrequencyId(value);
      },
    );
  }

  setDefaultFrequencyId(value) {
    if (!value) {
      return;
    }
    this.settings = {
      ...this.settings,
      defaultFrequencyId: value,
      isTheSameFrequency: !!value,
    };
    return this.expenseTypes.map((expenseType) => {
      const { types } = expenseType;
      if (!types || !types.length) {
        return expenseType;
      }
      return types.map((type) => {
        type.frequencyId = value;
        return type;
      });
    });
  }

  saveGrid() {
    this.isSavingExpense = true;
    const postData = this.expenseTypes.reduce((initial, expenseType) => {
      const { types } = expenseType;
      if (!types || !types.length) {
        return initial;
      }
      const typeHolder = createTypes(types, this.borrowers);
      if (!typeHolder || !typeHolder.length) {
        return initial;
      }
      return [
        ...initial,
        {
          ...expenseType,
          familyId: this.familyId,
          types: typeHolder,
        },
      ];
    }, []);
    if (!postData || !postData.length) {
      return;
    }

    this.contactModelService
      .setExpenseGrid(postData)
      .then((response) => {
        this.modalInstance.close(!!response);
      })
      .catch(() => {
        toastError();
      });
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }
}

export default ExpenseGridModalCtrl;
