import angular from 'angular';
import _ from 'lodash';
import { toastError } from 'Common/utilities/alert';
import { checkIfInvestment } from 'Common/utilities/security';
import {
  populatePropertyType,
  fileterAssetList,
  setDefaultValues,
  getAssetClient,
  onSelectAll,
  getOwnersListOnItemSelect,
  addAsset,
  getNewRentalIncomeModel,
  getNewMortgageLiabilityModel,
  getNewAssetModel,
  getOptionsList,
  getOptionsObj,
  getLocalLang,
  getOptionsOnInit,
  searchCreditorInstitution,
  pickedCreditorLookup,
  assignOwners,
} from 'Common/utilities/assetsModal';
import { parseToInt10 } from 'Common/utilities/parse';
import { initSelectedOwnersUtil } from 'Common/utilities/assets';
import { liabilityDatesMapper } from 'Common/utilities/financialLiabilities';

export const loadModule = () =>
  angular.module('app').controller(
    'LoanAppFinancialAssetsCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function LoanAppFinancialAssetsCtrl(
      $scope,
      $timeout,
      contactService,
      loanScenarioService,
      $stateParams,
      utilitiesService,
      $uibModal,
      optionsService,
      SweetAlert,
      dashboardService,
      loanAppSharedData,
      financialsSharedService,
      insuranceFinancialsSharedService,
      currentUserService,
      uiService,
      financeService,
      loanApplicantAssetInfoService,
      contactModelService,
      configService,
      $state,
    ) {
      $scope.populatePropertyTypeCall = populatePropertyType({
        $scope,
        financeService,
        currentUserService,
      });
      $scope.currentUserService = currentUserService;
      $scope.uiService = uiService;

      const defaultMortgageValuesAU = financeService.getDefaultMortgageAU();

      const setDefaultValuesUtil = setDefaultValues({
        $scope,
        optionsService,
        financeService,
        isNZ: currentUserService.isNZ,
      });
      const addAssetUtil = addAsset({
        $scope,
        currentUserService,
        loanScenarioService,
        dashboardService,
        loanAppSharedData,
      });
      const getNewRentalIncomeModelUtil = getNewRentalIncomeModel({
        $scope,
        currentUserService,
      });
      const getNewMortgageLiabilityModelUtil = getNewMortgageLiabilityModel({
        $scope,
        currentUserService,
        defaultMortgageValuesAU,
      });
      const getOptionsListUtil = getOptionsList({
        $scope,
        optionsService,
        currentUserService,
        financeService,
        contactService,
      });

      $scope.onSelectAll = onSelectAll({ $scope });
      $scope.AssetClientGet = getAssetClient({
        $scope,
        loanScenarioService,
        currentUserService,
      });
      $scope.getNewAssetModel = getNewAssetModel({
        $scope,
        currentUserService,
      });
      $scope.searchCreditorInstitution = searchCreditorInstitution({
        $scope,
      });
      $scope.pickedCreditorLookup = pickedCreditorLookup({ $scope });

      /* NOTICE: this module is also being used by Insurance Application - rolandbarro */
      $scope.callerApp = '';
      $scope.displayAssetsForm = false;

      if ($stateParams.insAppId) {
        $scope.callerApp = 'insurance';
      }
      $scope.setDefaultValuesMethod = (fieldName) => {
        setDefaultValuesUtil(fieldName);
      };
      $scope.financialsSharedService =
        $scope.callerApp !== 'insurance'
          ? financialsSharedService
          : insuranceFinancialsSharedService;
      $scope.loanAppSharedData = loanAppSharedData;
      const lenderPlace = 1;

      // Models
      angular.extend($scope, {
        loanAppId: $stateParams.loanAppId,
        familyId: $stateParams.familyId,
        assetLoading: false,
        tooltipDisplay: false,
        hasAsset: false,
        listAssets: [],
        listLender: [],
        subAssetTypes: [],
        assetTypes: [],
        displayAssetsForm: false,
        modalType: false,
        formValidation: false,
        isInvalidAssetDescription: false,
        isInvalidAssetType: false,
        isInvalidAssetAmount: false,
        //* ************************************/
        //  owners multi Select                /
        //* ************************************/
        localLang: getLocalLang(),
        ownersList: [],
        ownersListSelected: [],
        ownersListSelectedArray: [],
        ListPropertyType: [],
        AssetTotalAmount: 0,
        OFX: {
          ConvertedAmount: null,
        },
      });

      const assetValidation = () => {
        const selectedFamilyId =
          $scope.financialsSharedService.selectedFamily.FamilyId;
        const filterAssets = fileterAssetList(
          $scope.financialsSharedService.listAssets,
          selectedFamilyId,
        );
        if (filterAssets.overview.FinancialInfo.length > 0) {
          const assetFiltered = _.filter(
            filterAssets.overview.FinancialInfo,
            (obj) => {
              return obj.Type !== 'Real Estate';
            },
          );
          const estateAssetFiltered = _.filter(
            filterAssets.overview.FinancialInfo,
            (obj) => {
              return obj.Type === 'Real Estate';
            },
          );

          $scope.hasEstateAsset = estateAssetFiltered.length > 0;
          $scope.hasAsset = assetFiltered.length > 0;
        } else {
          $scope.hasAsset = false;
          $scope.hasEstateAsset = false;
        }
      };

      $scope.$watch(
        $scope.callerApp !== 'insurance'
          ? 'financialsSharedService.listAssets'
          : 'insuranceFinancialsSharedService.listAssets',
        () => {
          assetValidation();
        },
        true,
      );

      $scope.$watch(
        $scope.callerApp !== 'insurance'
          ? 'financialsSharedService.selectedFamily.FamilyId'
          : 'insuranceFinancialsSharedService.listAssets',
        () => {
          assetValidation();
        },
      );

      $scope.getAssetModelData = () => {
        $scope.resetAssetModel(
          'edit',
          $scope.financialsSharedService.selectedFamily.FamilyId,
        );
        $scope.AssetClientGet(
          $scope.financialsSharedService.selectedFamily.FamilyId,
        );
      };

      const runReqProcess = () => {
        $scope.getAssetModelData();
      };

      // Methods
      angular.extend($scope, {
        getAddressList() {
          $scope.propertyList = _.filter(
            loanAppSharedData.addressList,
            (obj) => {
              return obj;
            },
          );
        },
        displayAssetFormCallback(isRealEst) {
          const isRealEstate =
            typeof isRealEst === 'undefined' ? false : isRealEst;
          $scope.assetLoading = true;
          $timeout(() => {
            $scope.displayAssetsForm = true;
            $scope.assetLoading = false;
            $scope.resetAssetModel();
            $scope.tooltipDisplay = true;
            if (isRealEstate && !$scope.hasEstateAsset) {
              $scope.AssetsModal('new', true);
              $scope.displayAssetsForm = false;
            }
            $timeout(() => {
              $scope.tooltipDisplay = false;
            }, 6500);
          }, 2000);
        },
        hideToolTip() {
          $scope.tooltipDisplay = false;
        },
        displayAssetsFormFunc() {
          $scope.displayAssetsForm = true;
          $scope.resetAssetModel();
        },
        hideAssetForm() {
          $scope.displayAssetsForm = false;
        },
        getNewRentalIncomeModel(index) {
          getNewRentalIncomeModelUtil(index);
        },
        getNewMortgageLiabilityModel(index) {
          getNewMortgageLiabilityModelUtil(index);
        },
        AssetInfoGet(isInitialize) {
          financialsSharedService.AssetInfoGet(!isInitialize);
        },
        changeSelectedFamily() {
          let family = _.find(
            $scope.financialsSharedService.listAssets,
            (o) => {
              return _.isEqual(
                o.FamilyId,
                $scope.financialsSharedService.selectedFamily.FamilyId,
              );
            },
          );
          if (!family) {
            family = {
              FamilyName: '',
            };
          }
          angular.extend($scope.financialsSharedService.selectedFamily, {
            initials: utilitiesService.filterInitialOneString(
              family.FamilyName,
            ),
          });
        },
        AssetInfoDelete(assetId, isFromREA) {
          SweetAlert.swal(
            {
              title: 'Are you sure?',
              text: 'You will also remove all securities for this asset.',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#F68F8F',
              confirmButtonText: 'Yes, remove it!',
              closeOnConfirm: false,
            },
            (confirm) => {
              if (confirm) {
                loanScenarioService
                  .AssetInfoDelete($scope.loanAppId, assetId)
                  .then(() => {
                    $timeout(() => {
                      $scope.assetsInit(false);
                      if (isFromREA) {
                        loanAppSharedData.REFRESH_FUNDING_REQUIRED_SECTION = {
                          status: true,
                        };
                        $scope.financialsSharedService.IncomeInfoGet();
                        $scope.financialsSharedService.LiabilityInfoGet();
                      }
                      SweetAlert.swal({
                        title: 'Success',
                        text:
                          'Asset and securities has been been successfully deleted.',
                        type: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                      });
                    }, 100);
                  })
                  .catch(toastError);
              }
            },
          );
        },
        AssetDetailsGet(familyId, assetId, isRealEstate) {
          if (_.isUndefined(assetId)) {
            return false;
          }

          loanScenarioService
            .AssetDetailsGet(familyId, $scope.loanAppId, assetId)
            .then((response) => {
              // reset the actual model
              $scope.resetAssetModel('edit', familyId);

              // patch api data to actual model
              angular.extend($scope.AssetsSet, response.data);

              // patch temporary type to actual model
              angular.extend($scope.AssetsSet, {
                Type: _.find($scope.assetTypes, (o) => {
                  return o.Desc === $scope.AssetsSet.Type;
                }),
                PrimaryPurposeId: $scope.AssetsSet.PrimaryPurposeId
                  ? parseInt($scope.AssetsSet.PrimaryPurposeId, 10)
                  : null,
                ValueBasisId: $scope.AssetsSet.ValueBasisId
                  ? parseInt($scope.AssetsSet.ValueBasisId, 10)
                  : null,
                Value: $scope.AssetsSet.Value,
                FullAddress: $scope.AssetsSet.Address,
                Year: $scope.AssetsSet.Year
                  ? parseInt($scope.AssetsSet.Year, 10)
                  : null,
                PropertyTypeId: $scope.AssetsSet.PropertyTypeId
                  ? parseInt($scope.AssetsSet.PropertyTypeId, 10)
                  : null,
                PropertyTypeId_original: parseInt(
                  $scope.AssetsSet.PropertyTypeId,
                  10,
                ),
                ZoningId: $scope.AssetsSet.ZoningId
                  ? parseInt($scope.AssetsSet.ZoningId, 10)
                  : null,
                AssetSubtypeId: $scope.AssetsSet.AssetSubtypeId
                  ? parseInt($scope.AssetsSet.AssetSubtypeId, 10)
                  : 1,
              });
              $scope.populatePropertyTypeCall(
                optionsService,
                $scope.AssetsSet.ZoningId,
              );

              $scope.AssetsSet.MortgageLiability = $scope.AssetsSet.MortgageLiability.map(
                (obj) => {
                  obj.MortgagePriorityId = obj.MortgagePriorityId
                    ? parseInt(obj.MortgagePriorityId, 10)
                    : null;
                  obj.CreditorId = obj.CreditorId
                    ? String(obj.CreditorId)
                    : null;
                  return {
                    ...obj,
                    ...liabilityDatesMapper(obj),
                  };
                },
              );

              _.map($scope.AssetsSet.RentalIncome, (obj) => {
                obj.IncomeSubTypeId = parseInt(obj.IncomeSubTypeId, 10) || null;
                return obj;
              });

              // country code map
              // find the ofx object first
              const ofx = _.find($scope.OFXSupportCurrency, (ofxObject) => {
                return (
                  _.trim(ofxObject.Value) ===
                  _.trim($scope.AssetsSet.CurrencyCode)
                );
              });

              if (ofx) {
                // find the country so we could get the country code
                const country = _.find($scope.countryListGet, (c) => {
                  return _.trim(c.Name) === _.trim(ofx.Name);
                });
                if (country) {
                  $scope.AssetsSet.XECountryCode = country.XECountryCode;
                } else {
                  $scope.AssetsSet.XECountryCode = '';
                }
              }

              // Get clients of particular asset
              assignOwners($scope);
              if (financeService.hasSubType($scope)) {
                $scope.getSubAssetType();
              }
              $scope.AssetsModal('edit', isRealEstate);
              $scope.checkIfInvestment();
            });
        },
        isOtherTicked(dataArray) {
          let bool = false;
          dataArray.forEach((val) => {
            if (val.BorrowerID > -1 && val.ticked) {
              bool = true;
            }
          });
          return bool;
        },
        onItemSelect(data) {
          $scope.ownersList = getOwnersListOnItemSelect({
            ownersList: $scope.ownersList,
          })(data);
        },
        LenderListGet(LoanAppLenderId) {
          loanApplicantAssetInfoService
            .lenderListGet(LoanAppLenderId, lenderPlace)
            .then((lenderListResponse) => {
              $scope.listLender = lenderListResponse.data;
            });
        },
        resetAssetModel(type, famId) {
          let familyId = famId;
          if (type !== 'edit') {
            familyId = $scope.familyId;
          }

          $scope.AssetsSet = $scope.getNewAssetModel(false, familyId);
        },
        assetsInit(isFull) {
          $scope.financialsSharedService.listAssets = [
            {
              FamilyId: '',
              overview: {
                FinancialInfo: [],
              },
            },
          ];

          $scope.resetAssetModel();
          $scope.AssetInfoGet(isFull);
          $scope.AssetClientGet(
            $scope.financialsSharedService.selectedFamily.FamilyId ||
              $state.params.familyId,
          );

          getOptionsListUtil(isFull);
          if (isFull) {
            $scope.LenderListGet();
          }
        },
        changeAsset() {
          financeService.changeAssetType($scope);
        },
        getSubAssetType() {
          financeService.getSubAssetType($scope);
        },
        toggleSearchCreditor(toShow) {
          $scope.showCreditorResults = toShow;
        },
        addAsset(isModal, modalType) {
          const callBack = $scope.assetsInit(false);
          addAssetUtil(isModal, modalType, callBack);
        },
        AssetsModal(type, isRealEstate) {
          const templateString = isRealEstate
            ? '/assets/views/loanApplication/clientAssess/modal/real_estate.add.html'
            : '/assets/views/loanApplication/clientAssess/modal/asset.add.html';
          const modalInstance = $uibModal.open({
            templateUrl: templateString,
            controller: 'LoanappAssetModalCtrl',
            size: 'md',
            resolve: {
              listLender: () => {
                return $scope.listLender;
              },
              ownersList() {
                return $scope.ownersList;
              },
              modalType() {
                return type;
              },
              isRealEstate() {
                return isRealEstate;
              },
              familyId: () => {
                return $scope.familyId;
              },
              loanAppId: () => {
                return $scope.loanAppId;
              },
              assetsSet: () => {
                return $scope.AssetsSet;
              },
              optionsObj: () => {
                return getOptionsObj({ $scope });
              },
              addAssetCallback: () => {
                return function () {
                  $scope.assetsInit(false);
                  if (isRealEstate) {
                    return;
                  }
                  $scope.displayAssetsForm = false;
                };
              },
            },
            backdrop: 'static',
            keyboard: false,
            windowClass: 'asset-modal',
          });

          modalInstance.result.then(
            () => {
              $scope.getAssetModelData();
            },
            () => {
              runReqProcess();
            },
          );
        },
        selectAsset(familyId, assetId, isRealEstate) {
          $scope.AssetDetailsGet(familyId, assetId, isRealEstate);
        },
        selectAssetTemp(asset) {
          angular.extend($scope.AssetsSet, asset);
          $scope.AssetsSet.Type = _.filter($scope.assetTypes, (o) => {
            return o.Desc === $scope.AssetsSet.Type;
          })[0];
          $scope.AssetsModal('edit');
        },
        AssetInfoDeleteTemp(asset) {
          _.remove($scope.listAssets[0].overview.FinancialInfo, (obj) => {
            return _.isEqual(obj, asset);
          });
        },
        changeCurrencyCode(code, XECountryCode, CurrencyCodeId) {
          $scope.AssetsSet.CurrencyCode = code;
          $scope.AssetsSet.XECountryCode = XECountryCode;
          $scope.AssetsSet.CurrencyCodeId = CurrencyCodeId.toString();
        },
        checkIfInvestment() {
          $scope.isInvestment = checkIfInvestment(
            $scope.AssetsSet.PrimaryPurposeId,
          );
        },
      });
      // Watch for changes in selected family
      $scope.$watch('financialsSharedService.selectedFamily.FamilyId', (nv) => {
        $scope.AssetClientGet(nv).then(() => {
          if (!currentUserService.isNZ) {
            $scope.ownersList = initSelectedOwnersUtil({
              ownersList: $scope.ownersList,
              selectedFamilyId: parseToInt10(nv),
            });
          }
        });
        if ($scope.callerApp !== 'insurance') {
          financialsSharedService.updateAsset(nv);
        } else {
          $scope.financialsSharedService.updateAsset(nv);
        }
      });

      // caller
      $scope.assetsInit(true);

      $scope.$watch(
        'loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS',
        (nv) => {
          if (nv && _.size(nv) > 0 && nv.RefreshAsset) {
            $scope.assetsInit(true);
            nv.RefreshAsset = false;
          }
        },
      );

      $scope.$on('$destroy', () => {
        financialsSharedService.clearAssetInfo();
      });
      const init = () => {
        getOptionsOnInit({
          $scope,
          contactModelService,
          optionsService,
          configService,
        });
      };
      init();
    },
  );
