import { dueDateTypeValue } from 'Common/constants/dueDateTypes';

export const modifyDueDateTypes = (dueDateTypes) => {
  if (!dueDateTypes) {
    return [];
  }
  return dueDateTypes.filter(
    (dueDateType) =>
      dueDateType.Value !== dueDateTypeValue.RELATIVE_TO_TASK_DUE,
  );
};

export const saveTemplateTaskUtil = (dependencies) => {
  const { scope, toaster, proceedSave } = dependencies;
  if (!scope || !toaster || !proceedSave) {
    return;
  }

  if (!scope.taskSet.Title) {
    toaster.pop('error', 'Error', 'Please create a valid task');
  } else if (
    scope.taskSet.DueDateOptionID === dueDateTypeValue.RELATIVE_TO_TASK_DUE &&
    !scope.taskSet.RelativeTaskSettingsID
  ) {
    toaster.pop('error', 'Error', 'Specify relative task before saving');
  } else {
    proceedSave();
  }
};
