import { ADDRESS_TYPE } from 'Common/constants/addressType';
import { toastError } from 'Common/utilities/alert';

export const initClientAddress = ({ $scope, configService }) => {
  $scope.clientAddress = {
    home: {},
    postal: {
      isSameAsHome: true,
    },
    homeForm: {},
    postalForm: {},
    homeLabel: 'Current Address',
    postalLabel: '',
  };
  $scope.businessAddress = {
    home: {},
    homeForm: {},
    homeLabel: 'Office Address',
  };
  $scope.isAddressOverhaulEnabled = configService.feature.addressOverhaul;
};

export const checkAddressValidity = ($scope) => {
  if (!$scope.isAddressOverhaulEnabled) {
    return true;
  }
  $scope.clientAddress.homeForm.$setSubmitted();
  $scope.clientAddress.postalForm.$setSubmitted();
  return (
    $scope.clientAddress.homeForm.$valid &&
    $scope.clientAddress.postalForm.$valid
  );
};

export const checkBusinessAddressValidity = ($scope) => {
  if (!$scope.isAddressOverhaulEnabled) {
    return true;
  }
  $scope.businessAddress.homeForm.$setSubmitted();
  return $scope.businessAddress.homeForm.$valid;
};

export const saveClientAddress = ({
  $scope,
  generalService,
  contactAddress,
}) => {
  const homeAddress =
    contactAddress.home && contactAddress.home.formatted_address
      ? contactAddress.home
      : '';
  const tempPostalAddress =
    contactAddress.postal && contactAddress.postal.formatted_address
      ? contactAddress.postal
      : '';
  const postalAddress =
    contactAddress.postal && contactAddress.postal.isSameAsHome
      ? homeAddress
      : tempPostalAddress;
  $scope.tempAddressObj = {
    homeAddress,
    postalAddress,
  };
  const homeSaveRequest = homeAddress
    ? generalService.addressDetailsSet(homeAddress)
    : Promise.resolve({ data: 0 });
  const postalSaveRequest = postalAddress
    ? generalService.addressDetailsSet(postalAddress)
    : Promise.resolve({ data: 0 });
  return Promise.all([homeSaveRequest, postalSaveRequest]);
};

export const formatClientAddress = ({
  addressLookupService,
  contactAddress,
}) => {
  const isValidHomeAddress =
    contactAddress.home && contactAddress.home.displayDetails;
  const isValidPostalAddress =
    contactAddress.postal && contactAddress.postal.displayDetails;

  const formatHomeAddress = isValidHomeAddress
    ? addressLookupService.convertToAddressFormat(contactAddress.home)
    : Promise.resolve({});
  const formatPostalAddress = isValidPostalAddress
    ? addressLookupService.convertToAddressFormat(contactAddress.postal)
    : Promise.resolve({
        isSameAsHome:
          (contactAddress.postal && contactAddress.postal.isSameAsHome) ||
          false,
      });

  return Promise.all([formatHomeAddress, formatPostalAddress]);
};

export const processClientAddress = ({
  $scope,
  generalService,
  addressLookupService,
  contactAddress,
}) => {
  const invalidAddress =
    !contactAddress ||
    (!contactAddress.home.displayDetails &&
      !(contactAddress.postal && contactAddress.postal.displayDetails));
  if (invalidAddress) {
    return Promise.resolve([]);
  }
  return formatClientAddress({ addressLookupService, contactAddress })
    .then(([homeFormattedAddress, postalFormattedAddress]) => {
      contactAddress.home = homeFormattedAddress;
      if (contactAddress.postal) {
        contactAddress.postal = postalFormattedAddress;
      }
      return saveClientAddress({ $scope, generalService, contactAddress });
    })
    .then(([home, postal]) => {
      const homeAddress = {
        AddressID: (home && home.data) || 0,
        Type: 'Home',
        geoCoded:
          ($scope.tempAddressObj.homeAddress &&
            $scope.tempAddressObj.homeAddress.geoCoded) ||
          false,
        IsMailing: false,
      };
      const postalAddress = {
        AddressID: (postal && postal.data) || 0,
        Type: 'Postal',
        TypeId: ADDRESS_TYPE.POSTAL,
        geoCoded:
          ($scope.tempAddressObj.postalAddress &&
            $scope.tempAddressObj.postalAddress.geoCoded) ||
          false,
        IsMailing: true,
      };
      return [homeAddress, postalAddress];
    });
};

export const isAddressInvalid = ($scope, isValid) => {
  if (isValid) {
    return false;
  }
  toastError('Invalid Form - Please fill up the form correctly');
  $scope.canSave = true;
  $scope.prevStep();
  return true;
};
